import style from "./style.scss";

function Brustwirbelkorper12VdLiegend1529(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 315.45 180.93">

<g id="BG">
  <path id="BG_white" data-name="BG white" class="Brustwirbelsaule_ap1529_cls4" d="M36.99,179.25c6.85-2.92,13.74-6.89,20.67-11.57l17.65-16.04c13.98-15.64,31.01-23.56,51.4-22.97l-4.14,20.35c-1.53,4.93-3.42,8.78,0,9.05,4.32-1.38,8.64-1.54,12.95-.5-2.12,6.54-1.37,12.27,3.19,16.96-8.57.67-16.21,1.98-21.82,4.71,35.57-3.39,69.3-3.59,100.84,0-2.44-2.21-7.36-3.67-13.41-4.78,4.96-.5,9.94-4.28,14.94-11.17,5.05,1.74,10.91,2.79,17.42,3.29,4.32-.29,2.25-7.92-1.35-17.3-2.68-5.37-3.17-9.48-2.39-12.87,15.17,2.09,34.53,20.35,54.03,39.18l22.52-19.59c-25.65-23.72-50.07-44.1-72.81-59.89,1.45-5.79-.13-8.24-4.75-7.33l-7.3,2.05c-2.14-5.82-2.07-11.97-1.27-18.22,2.91.79,5.5,1.25,7.39.99,1.57-.22,2.76-1.79,2.66-3.37-.13-2.23-.86-4.51-2.23-7.35-.76-1.58-1.6-3.13-2.66-4.53-1.74-2.31.06-3.22,3.4-2.87l7.19.06c6.06.75,9.69-.23,10.52-3.23,4.2-11.49,3.95-18.34-2.07-19.19-12.4-2.88-17.48-6.33-14.99-10.35.4-4.22-.23-7.77-1.98-10.62-1.13-1.84-3.12-2.99-5.28-3.09-4.6-.2-10.6.95-16.52,2.02l-42.38,2.74c-2.3-2.17-4.44-3.78-6.33-4.55-1.43-.58-3.07-.14-4.1,1.01l-2.78,3.09c-1.03-5.83-2.38-9.97-4.11-12.06-.72-.87-1.93-1.18-2.97-.77-2.48.99-3.85,3.54-4.67,6.87-.41,3.93-2.55,4.81-5.93,3.46l-9.66.2c1.25,2.29,1.08,4.39,0,6.38-1.14,1.11-3.42.61-5.81-.05-5.02-.76-9.09.84-12.53,3.98-5.54,4.01-8.7,8.36-8.48,13.17.25,2.16.67,4.04,1.26,5.67,1.6,4.43,5.75,7.44,10.44,7.85h0c2.79.24,5.6.15,8.37-.28l3.48-.54c2.85-.79,4.74.67,5.85,3.96.86,2.79-.29,5.39-2.09,7.92-2.17,6.42-3.04,11.82,1.32,13.15,3.07,3.93,3.93,8.67,2.83,14.11-5.94,1.53-5.43,5.86-1.46,11.69-3.81-4.37-9.98-4.93-17.39-3.46-15.11,5.94-30.89,17.59-47.09,32.76C41.66,148.1,20.15,165.4.07,179.25h36.92Z"/>
</g>
<g id="BWK12ap">
  <g id="BWK12Parts">
    <path id="BWK12Part29" class="Brustwirbelsaule_ap1529_cls6" d="M125.39,71.25c.48.58,1.75,1.76,10.48,1.37,5.74-.26,10.16-.99,12.3-1.37,8.62-1.5,9.01-2.16,15.49-3.19,5.96-.95,10.62-1.19,14.13-1.37,5.56-.27,5.71.03,15.95,0,13.82-.04,14.25-.58,17.32.46,4.58,1.55,5.52,3.41,12.3,5.47,3.53,1.07,7.93,2.36,9.57.46.91-1.06.61-2.7,0-5.92-1.02-5.41-5.42-9.13-4.54-10.94.87-1.8,6.15.05,9.1-.46,5.62-.96,7.06.89,9.57-.46,2.75-1.48,3.45-4.99,4.56-10.48.97-4.82,1.5-7.45,0-9.57-1.21-1.72-2.67-1.55-9.11-3.65-8.82-2.86-9.71-4.35-10.03-5.01-1.14-2.43.9-3.67.46-8.2-.12-1.22-.62-6.38-4.1-8.66-.87-.57-2.44-1.3-11.85,0-4.8.66-4.97.93-8.2,1.37-5.77.77-7.22.17-15.49.46-3.58.12-6.64.38-12.76.91-6.63.57-14.13,1.37-14.13,1.37-3.05-3.2-6.54-5.96-9.11-5.01-2.44.9-3.14,4.78-4.1,4.56-.82-.19-.28-2.98-1.37-6.84-.55-1.94-1.68-5.93-4.1-6.38-1.97-.36-4.05,1.75-5.01,3.19-2.21,3.28-.46,5.77-2.28,7.29-1.71,1.43-3.92-.19-10.02,0-1.99.06-3.59.29-4.56.46,1.3,3.9.79,5.58,0,6.38-2.01,2.04-6.07-1.47-11.85,0-3.2.81-5.22,2.71-8.66,5.92-3.32,3.1-5.1,4.77-5.92,7.75-1.23,4.44.63,8.43,1.37,10.03.76,1.63,1.79,3.84,4.1,5.47.99.69,2.95,1.8,10.03,1.82,6.49.02,8.96-2.62,11.39-.91,2.03,1.43,2.23,4.36,2.28,5.01.21,2.94-1.4,4.15-2.73,7.75-.58,1.57-2.95,7.95-.46,10.94,0,0-.02-.02-.02-.02Z"/>
    <path id="BWK12Part28" class="cls-5" d="M120.38,157.38c1.13,1.45,3.64.26,9.57,0,7.49-.33,7.68,1.4,13.67.91,6.69-.55,6.46-2.69,13.22-3.19,6.05-.44,6.52,1.26,12.3.46,4.34-.6,4.76-1.66,10.48-2.73,2.14-.4,5.83-1.1,10.03-.91,5.47.25,9.35,1.89,15.49,4.56,7.55,3.28,7.4,4.41,14.13,6.84,4.07,1.47,6.66,1.89,11.85,2.73,5.28.86,6.22.54,6.84,0,2.66-2.34.11-9.52-1.37-13.67-1.7-4.77-2.51-4.67-3.65-8.66-.73-2.55-.7-3.69-1.37-11.85-.82-10.05-.93-9.36-.91-11.39.04-3.94.09-8.2,1.82-13.22,1.17-3.38,1.92-3.68,3.19-7.75,1.3-4.15,2.46-7.84.91-9.57-1.57-1.75-5.06-.52-9.57.46-6.03,1.31-12.07,1.33-24.15,1.37-8.96.03-7.52-.84-18.23-.91-1.29,0-7.11.15-18.68.46-7.34.2-10.6.28-15.49,0-5.82-.34-8.74-.51-11.39-1.37-5.49-1.78-7.92-4.44-10.48-3.19-1.81.88-2.71,3.24-2.73,5.01-.03,2.61,1.83,3.5,3.19,6.38,1.24,2.63,1,5.59.46,11.39-.41,4.35-.79,5.2-1.82,11.85-1.18,7.61-.83,7.49-1.82,13.22-1.07,6.18-1.61,9.27-2.73,12.76-2.02,6.24-3.9,8.53-2.73,10.03l-.03-.02h0Z"/>
    <path id="BWK12Part27" class="cls-5" d="M165.04,141.88c1.56-4.6,2.11-4.22,2.73-7.29.64-3.16.28-4.71.46-12.3.13-5.69.37-6.85,1.37-7.75,1.84-1.65,5.54-1.73,7.29,0,1.98,1.95,0,4.74,0,12.3,0,1,.45,4.92,1.37,12.76.75,6.45.84,6.86.91,8.66.07,1.86.14,6.13-.91,11.39-.75,3.75-1.16,5.65-2.73,6.84-2.1,1.58-5.16,1.43-7.29.46-4.02-1.85-5.01-6.81-5.47-9.11-1.15-5.79.59-10.96,2.28-15.95h0Z"/>
    <path id="BWK12Part26" class="Brustwirbelsaule_ap1529_cls6" d="M246,115c.11-56.55,29.52-100.49,69.42-111.65"/>
    <path id="BWK12Part25" class="Brustwirbelsaule_ap1529_cls6" d="M286.97,175.6c-23.48-20.24-33.17-34.46-55.04-39.18"/>
    <path id="BWK12Part24" class="Brustwirbelsaule_ap1529_cls6" d="M309.49,156.01c-24.78-23.11-56.38-50.09-72.81-59.89"/>
    <path id="BWK12Part23" class="Brustwirbelsaule_ap1529_cls6" d="M231.93,99.5c17.69,7.43,13.97,29.67,5.97,38.54"/>
    <path id="BWK12Part22" class="Brustwirbelsaule_ap1529_cls6" d="M237.23,93.54c-37.26-2.36-3.78,54.45-5.3,42.88"/>
    <path id="BWK12Part21" class="Brustwirbelsaule_ap1529_cls6" d="M230.44,56.16c-10.77-.97-8.72,11.93-10.26,16"/>
    <path id="BWK12Part20" class="Brustwirbelsaule_ap1529_cls6" d="M221.73,69.88c0-3.27-.69,48.05-20.06-5.92"/>
    <path id="BWK12Part19" class="Brustwirbelsaule_ap1529_cls6" d="M138.67,62.14c0,1.01-4.39,62.15,25.45,2"/>
    <path id="BWK12Part18" class="cls-5" d="M155.22,91.47c-12.71,17.6,29.87,64.51,38.52-.89"/>
    <path id="BWK12Part17" class="Brustwirbelsaule_ap1529_cls6" d="M184,59.17c9.75,21.65-21.61,59.98-10.69,4.96"/>
    <path id="BWK12Part16" class="cls-5" d="M210.19,83.43c0,3.95-8.22,0-16.44,7.15"/>
    <path id="BWK12Part15" class="cls-5" d="M210.19,96.12c-7.43,11.15-3.67,33.49,3.6,36.65"/>
    <path id="BWK12Part14" class="cls-5" d="M224.63,90.84c9.38,28.77,1.26,45.57-10.84,41.93"/>
    <path id="BWK12Part13" class="Brustwirbelsaule_ap1529_cls6" d="M178.43,162.39c14.15-3.13,20.41,28.05,40.83.91"/>
    <path id="BWK12Part12" class="Brustwirbelsaule_ap1529_cls6" d="M232.94,143.71c-13.29,1.49-13.27,18.94-13.67,19.59"/>
    <path id="BWK12Part11" class="Brustwirbelsaule_ap1529_cls6" d="M162.39,162.39c-2.4,1.99-11.08,10.04-21.58,13.22"/>
    <path id="BWK12Part10" class="Brustwirbelsaule_ap1529_cls6" d="M135.52,157.58c-3.93,9.01,2.37,18.02,5.29,18.02"/>
    <path id="BWK12Part9" class="Brustwirbelsaule_ap1529_cls6" d="M127.65,134.59c2.84-1.07,5.26,9.67,7.87,22.99"/>
    <path id="BWK12Part8" class="cls-5" d="M148.02,93.47c0,20.46-6.95,37.02-15.53,37.02"/>
    <path id="BWK12Part7" class="Brustwirbelsaule_ap1529_cls6" d="M127.65,128.67c-36.44-9.9.62-30.53,1.38-30.53"/>
    <path id="BWK12Part6" class="Brustwirbelsaule_ap1529_cls6" d="M.07,179.25c61.52-41.27,105.86-102.51,128.97-81.11"/>
    <path id="BWK12Part5" class="Brustwirbelsaule_ap1529_cls6" d="M36.99,179.25c42.38-16.86,40.55-50.58,90.67-50.58"/>
    <path id="BWK12Part4" class="cls-5" d="M127.65,128.67c-11.83-13.67.62-30.53,1.38-30.53"/>
    <path id="BWK12Part3" class="cls-5" d="M155.22,91.47c0,3.68,0-27.34-26.19,6.66"/>
    <path id="BWK12Part2" class="Brustwirbelsaule_ap1529_cls6" d="M116.89,179.25c7.91-6.84,92.75-9.96,100.84,0"/>
    <path id="BWK12Part1" class="Brustwirbelsaule_ap1529_cls6" d="M129.04,98.14c-2.18,30.83-10.46,16.69-6.16,20.64"/>
  </g>
</g>
<g id="angrenzende_BWK_Highlights" data-name="angrenzende BWK Highlights">
  <path id="angrenzende_BWK_Highlight2" data-name="angrenzende BWK Highlight2" class="BWK1529Fill" style= {{
            fill: props.colors.BWK1529Fill,
            stroke: props.colors.BWK1529Fill,
            opacity:props.colors.BWK1529Fill,
          }} d="M125.39,71.36c.48.58,1.75,1.76,10.48,1.37,5.74-.26,10.16-.99,12.3-1.37,8.62-1.5,9.01-2.16,15.49-3.19,5.96-.95,10.62-1.19,14.13-1.37,5.56-.27,5.71.03,15.95,0,13.82-.04,14.25-.58,17.32.46,4.58,1.55,5.52,3.41,12.3,5.47,3.53,1.07,7.93,2.36,9.57.46.91-1.06.61-2.7,0-5.92-1.02-5.41-5.42-9.13-4.54-10.94.87-1.8,6.15.05,9.1-.46,5.62-.96,7.06.89,9.57-.46,2.75-1.48,3.45-4.99,4.56-10.48.97-4.82,1.5-7.45,0-9.57-1.21-1.72-2.67-1.55-9.11-3.65-8.82-2.86-9.71-4.35-10.03-5.01-1.14-2.43.9-3.67.46-8.2-.12-1.22-.62-6.38-4.1-8.66-.87-.57-2.44-1.3-11.85,0-4.8.66-4.97.93-8.2,1.37-5.77.77-7.22.17-15.49.46-3.58.12-6.64.38-12.76.91-6.63.57-14.13,1.37-14.13,1.37-3.05-3.2-6.54-5.96-9.11-5.01-2.44.9-3.14,4.78-4.1,4.56-.82-.19-.28-2.98-1.37-6.84-.55-1.94-1.68-5.93-4.1-6.38-1.97-.36-4.05,1.75-5.01,3.19-2.21,3.28-.46,5.77-2.28,7.29-1.71,1.43-3.92-.19-10.02,0-1.99.06-3.59.29-4.56.46,1.3,3.9.79,5.58,0,6.38-2.01,2.04-6.07-1.47-11.85,0-3.2.81-5.22,2.71-8.66,5.92-3.32,3.1-5.1,4.77-5.92,7.75-1.23,4.44.63,8.43,1.37,10.03.76,1.63,1.79,3.84,4.1,5.47.99.69,2.95,1.8,10.03,1.82,6.49.02,8.96-2.62,11.39-.91,2.03,1.43,2.23,4.36,2.28,5.01.21,2.94-1.4,4.15-2.73,7.75-.58,1.57-2.95,7.95-.46,10.94,0,0-.02-.02-.02-.02Z"/>
  <path id="angrenzende_BWK_Highlight1" data-name="angrenzende BWK Highlight1" class="BWK1529Fill" style= {{
            fill: props.colors.BWK1529Fill,
            stroke: props.colors.BWK1529Fill,
            opacity:props.colors.BWK1529Fill,
          }} d="M116.89,179.36c7.91-6.84,92.75-9.96,100.84,0"/>
</g>
<g id="BWK12_highlights" data-name="BWK12 highlights">
  <path id="BWK12_highlight1" data-name="BWK12 highlight1" class="Wirbelkorper_Querfortsatze1529Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1529Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1529Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1529Fill,
          }} d="M120.38,157.38c1.13,1.45,3.64.26,9.57,0,7.49-.33,7.68,1.4,13.67.91,6.69-.55,6.46-2.69,13.22-3.19,6.05-.44,6.52,1.26,12.3.46,4.34-.6,4.76-1.66,10.48-2.73,2.14-.4,5.83-1.1,10.03-.91,5.47.25,9.35,1.89,15.49,4.56,7.55,3.28,7.4,4.41,14.13,6.84,4.07,1.47,6.66,1.89,11.85,2.73,5.28.86,6.22.54,6.84,0,2.66-2.34.11-9.52-1.37-13.67-1.7-4.77-2.51-4.67-3.65-8.66-.73-2.55-.7-3.69-1.37-11.85-.82-10.05-.93-9.36-.91-11.39.04-3.94.09-8.2,1.82-13.22,1.17-3.38,1.92-3.68,3.19-7.75,1.3-4.15,2.46-7.84.91-9.57-1.57-1.75-5.06-.52-9.57.46-6.03,1.31-12.07,1.33-24.15,1.37-8.96.03-7.52-.84-18.23-.91-1.29,0-7.11.15-18.68.46-7.34.2-10.6.28-15.49,0-5.82-.34-8.74-.51-11.39-1.37-5.49-1.78-7.92-4.44-10.48-3.19-1.81.88-2.71,3.24-2.73,5.01-.03,2.61,1.83,3.5,3.19,6.38,1.24,2.63,1,5.59.46,11.39-.41,4.35-.79,5.2-1.82,11.85-1.18,7.61-.83,7.49-1.82,13.22-1.07,6.18-1.61,9.27-2.73,12.76-2.02,6.24-3.9,8.53-2.73,10.03l-.03-.02h0Z"/>
</g>
<g id="grund_u_Deckplatten_Highlights" data-name="grund u Deckplatten Highlights">
  <path id="grund_u_Deckplatten_Highlight4" data-name="grund u Deckplatten Highlight4" class="Grund_Deckplatten1529Fill" style= {{
            fill: props.colors.Grund_Deckplatten1529Fill,
            stroke: props.colors.Grund_Deckplatten1529Fill,
            opacity:props.colors.Grund_Deckplatten1529Fill,
          }} d="M231.15,69.49c-4.85,1.6-8.94-.79-13.33-2.62-3.5-1.46-6.98-2.34-10.75-2.72-8.55-.85-17.37-.76-25.96-.61-8.57.15-17.08.93-25.5,2.61-8.23,1.64-21.15,6.26-29.02,1.7-3.35-1.94-6.37,3.24-3.03,5.18,7.04,4.08,16.7,2.27,24.27.84,10.5-1.98,20.63-4,31.36-4.29,9.82-.27,20.73-.92,30.42.93,8.03,1.54,14.61,7.56,23.13,4.75,3.65-1.2,2.09-7-1.6-5.79Z"/>
  <path id="grund_u_Deckplatten_Highlight3" data-name="grund u Deckplatten Highlight3" class="Grund_Deckplatten1529Fill" style= {{
            fill: props.colors.Grund_Deckplatten1529Fill,
            stroke: props.colors.Grund_Deckplatten1529Fill,
            opacity:props.colors.Grund_Deckplatten1529Fill,
          }} d="M238.19,89.35c-1.28-2.62-3.92-2.62-6.51-2.38-4.6.42-9.13,1.4-13.69,2.14-10.81,1.74-21.39.18-32.25-.1-10.07-.26-20.12.44-30.18.24-5.31-.11-10.59-.61-15.71-2.08-4.53-1.3-8.3-2.7-13.04-1.88-3.8.66-2.19,6.44,1.6,5.79,4.72-.82,9.76,2.09,14.35,2.96,3.91.74,7.9,1.08,11.87,1.2,9.1.27,18.21-.4,27.32-.29,8.88.11,17.83,1.44,26.69,1.18,3.99-.12,7.94-.72,11.87-1.38,2.15-.36,4.28-.77,6.43-1.11,1.57-.26,3.15-.48,4.74-.66.94.12,1.52.14,1.76.05,1.99,2.37,6.33-.46,4.76-3.67Z"/>
  <path id="grund_u_Deckplatten_Highlight2" data-name="grund u Deckplatten Highlight2" class="Grund_Deckplatten1529Fill" style= {{
            fill: props.colors.Grund_Deckplatten1529Fill,
            stroke: props.colors.Grund_Deckplatten1529Fill,
            opacity:props.colors.Grund_Deckplatten1529Fill,
          }} d="M234.73,163.13c-1.47,1.81-11.4-1.58-13.54-2.21-5-1.45-8.95-3.9-13.25-6.73-7.4-4.86-15.7-4.92-24.2-3.7-5.01.72-9.97,1.71-15.06,1.62-5.08-.09-9.4-.51-14.32,1.02-10.78,3.35-23.12,1.54-34.31,1.26-3.86-.1-3.86,5.9,0,6,5.94.15,11.88.29,17.82.4,5.3.1,10.48.44,15.62-1.07,2.92-.86,5.54-1.83,8.63-1.93,2.78-.09,5.55.38,8.33.33,5.44-.1,10.78-1.35,16.17-1.99,5.5-.65,11.23-.74,16.23,1.98,4.13,2.24,7.59,5.19,12,6.97,4.7,1.91,9.67,2.93,14.65,3.8,3.34.59,7.06,1.48,9.47-1.5,2.41-2.97-1.81-7.24-4.24-4.24Z"/>
  <path id="grund_u_Deckplatten_Highlight1" data-name="grund u Deckplatten Highlight1" class="Grund_Deckplatten1529Fill" style= {{
            fill: props.colors.Grund_Deckplatten1529Fill,
            stroke: props.colors.Grund_Deckplatten1529Fill,
            opacity:props.colors.Grund_Deckplatten1529Fill,
          }} d="M217.73,174.88c-7.54-2.66-15.89-3.14-23.81-3.66-8.73-.57-17.49-.64-26.24-.43-8.77.21-17.53.71-26.28,1.26-7.85.5-15.91.57-23.48,2.94-3.67,1.15-2.1,6.95,1.6,5.79,7.64-2.4,15.89-2.36,23.83-2.85,8.11-.51,16.22-.95,24.34-1.14,8.1-.19,16.21-.16,24.3.31,8.06.46,16.49.86,24.15,3.57,3.65,1.29,5.22-4.51,1.6-5.79Z"/>
</g>
</svg>
  );
}

export default Brustwirbelkorper12VdLiegend1529;
