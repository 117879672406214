import {
  ADD_IMPORTING_JOB,
  REMOVE_IMPORTING_JOB,
  UPDATE_IMPORTING_JOB,
} from "../actionTypes";

const initialState = {
  importingJobs: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_IMPORTING_JOB: {
      let copyList = state.importingJobs.slice();
      const { content } = action.payload;
      copyList.push(content);
      copyList = [...new Set(copyList)];
      return {
        ...state,
        importingJobs: copyList,
      };
    }
    case REMOVE_IMPORTING_JOB: {
      let copyList = state.importingJobs.slice();
      const { content } = action.payload;
      copyList = copyList.filter((value) => value.workID !== content.workID);
      copyList = [...new Set(copyList)];
      return {
        ...state,
        importingJobs: copyList,
      };
    }
    case UPDATE_IMPORTING_JOB: {
      let copyList = state.importingJobs.slice();
      const { content } = action.payload;
      let jobidx = copyList.findIndex(
        (value) => value.workID === content.workID
      );
      copyList[jobidx].status = content.status;
      copyList = [...new Set(copyList)];
      return {
        ...state,
        importingJobs: copyList,
      };
    }

    default:
      return state;
  }
}
