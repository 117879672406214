import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";
const HandRight = map.HandRight;

function FrontView({ selected, bpartSetter }) {
  return (
    <svg
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 449.22 758.03"
    >
      <g id="Ebene_1-2" data-name="Ebene 1">
        <g id="Hand">
          <path
            id="hand_silohuette"
            data-name="hand silohuette"
            class="rhnd-cls-2"
            d="M131.74,755.48c-12.28-33.88-13.65-74.79-13.54-116.67-2.18-11.17-6.47-19.51-12.61-25.38-11.39,.2-49.76-114.7-68.34-165.15-6.31-50.24-10.09-97.98-21.61-121.15-2.01-9.52-3.47-18.22-1.37-21.63-12.41-13.63-13.12-16.09-13.77-36.48,3.64-11.29,13.67-14.95,27.08-14.58,28.29,24.79,50.77,47.22,47.92,59.38,14.9,21.71,16.87,52.5,28.12,77.08,14.77,13.83,15.13-94.45,16.67-192.71,2.27-36.53,6.42-80.55,11.46-128.12,4.35-38.94,46.45-45.65,52.56,.52,1.69,14.2,1.91,30.88,1.61,48.44,3.13,13.25,4.03,30.44,4.17,48.96,1.68,24.75,1.97,48.18,2.08,69.79,3.69,4.85,2.34,28.32,2.51,54.1,6.22,8.36,11.06,3.4,16.07,0,.17-41.25,.87-79.89,4.34-105.14,5.59-39.34,9.74-79.63,12.5-120.83,7.57-8.33,11.23-39.85,13.88-47.18,9.56-28.27,43.12-20.95,46.54,3.43,1.3,18.42-1.51,55.49-4.17,102.08,.85,48.32,.8,94.57-5.21,127.08-2.22,17.76-2.38,31.71-2.59,41.78,4.63,5.2,9.31,6.62,14.05,3.01,9.43-59.79,20.93-113.45,32.73-156.16-2.35-10.54,4.71-31.27,14.64-55.12,9.36-24.41,43.73-22.98,42.71,5.49-.19,30.42-1.2,58.51-5.71,76.62-5.47,40.33-6.07,82.3-15.62,102.08-7.36,21.31-12.48,42.24-13.54,62.5,3.79,8.45,8.28,14.78,14.58,15.62,6.01-10.09,12.29-29.26,18.75-54.17,9.95-20.12,19.04-42.6,26.16-65.99,8.78-47.94,33.32-58.58,43.93-30.92-1.2,16.3-2.12,31.47-1.02,46.37-4.16,25.44-21.16,100.6-26.85,106.37-8.06,15.86-11.39,33.76-16.07,50.03-4.43,77.58-26.08,142.41-58.45,199.33-15.17,11.18-26.16,63.04-15.62,78.12,4.65,16.09,5.13,33.24-1.04,52.08,1.76,10.01,1.66,19.71,0,29.17"
          />
          <g id="fore_arm_hand_joints" data-name="fore arm hand joints">
            <path
              id="fore_arm_hand_joints_r"
              data-name="fore arm hand joints r"
              class="rhnd-cls-2"
              d="M266.16,752.85l2.14-15.86c-3.51-12.85-8.7-23.13-16.17-29.9-.65-14.81,3.93-25.47,14.03-31.73,7.04-.04,17.3,2.79,27.76,5.8,3.01-8.17,4.84-10.09,6.41-10.68,7.03,.97,11.48,5.19,10.07,16.78-5.13,11.34-8.51,24.24-10.07,38.75l-.61,26.85"
            />
            <path
              id="fore_arm_hand_joints_l"
              data-name="fore arm hand joints l"
              class="rhnd-cls-2"
              d="M158.77,754.68c1.39-29.15-4.53-47.08-14.03-59.49-.03-17.23,1.51-31.71,7.63-38.14,8.76-2.32,9.76,4.58,33.56,12.51,20.16-2.16,40.84-2.86,61.93-2.44,6.14,6,9.77,13.16,3.36,25.02-13.06,18.3-16.25,40.16-18,62.54"
            />
          </g>
          <g id="wrist_bones" data-name="wrist bones">
            <path
              id="wrist_bones_8"
              data-name="wrist bones 8"
              class="rhnd-cls-2"
              d="M285.38,627.77h0c6.29-6.19,12.32-12.02,14.34-14.95,2.62-4,2.28-11.52,1.53-19.53,8.72,3.64,15.33,9.03,6.71,27.15-9.39,15.47-16.33,13.04-22.58,7.32h0Z"
            />
            <path
              id="wrist_bones_7"
              data-name="wrist bones 7"
              class="rhnd-cls-2"
              d="M206.67,655.53h0c4.08-4.59,4.64-13.11,5.19-21.66,4.54-9.43,13.91-16.47,36.51-11.39,6.51,2.14,11.39,6.47,16.88,16.88-8.77,9.18-17.75,17.15-27.15,22.58-12.04,2.36-31.73-3.61-38.14,1.53-4.15,3.33,5.11-7.12,6.71-7.93h0Z"
            />
            <path
              id="wrist_bones_6"
              data-name="wrist bones 6"
              class="rhnd-cls-2"
              d="M168.84,596.96c-13.41-8.1-13.69-19.94-6.71-33.86,12.48-17.41,22.61-17.43,30.81-3.36,6.27,2.52,11.26,2.7,13.73-1.83-3.97,10.37-4.84,23.11-12.81,30.81,.1,4.93-12.45,6.59-25.02,8.24h0Z"
            />
            <path
              id="wrist_bones_5"
              data-name="wrist bones 5"
              class="rhnd-cls-2"
              d="M199.04,664.07h0c-20.62,1.04-37.51-3.98-42.1-28.98-16.5-3.65-20.36-12.1-1.22-29.29-.1-2.96,9.5-6.37,19.33-9.12,5.87-1.64,11.79-3.37,17.89-4.61-4.59,18.09-8.42,35.84,18.92,39.66,1.1,12.21-2.89,23.07-12.81,32.34h0Z"
            />
            <path
              id="wrist_bones_4"
              data-name="wrist bones 4"
              class="rhnd-cls-2"
              d="M243.48,620.35h0c-11.9-16.22-12.32-39.06,15.36-66.41,5.12-6.35,48.43-2.2,47.29,22.88-.2,12.71-52.58,44.54-62.64,43.53h-.01Z"
            />
            <path
              id="wrist_bones_3"
              data-name="wrist bones 3"
              class="rhnd-cls-2"
              d="M266.47,640.89h0c-2.56-9.36-16.53-16.72-15.25-21.36,19.83-6.51,35.63-27.24,50.03-26.24,7.93,16.07-9.01,24.91-15.91,33.51-8.7,10.42-13.99,16.93-18.87,14.08h0Z"
            />
            <path
              id="wrist_bones_2"
              data-name="wrist bones 2"
              class="rhnd-cls-2"
              d="M169.76,597.57h0c-17.8,6.81-32.54,17.39-51.25,6.1-3.87-13.47-3.13-24.01,4.88-29.9,11.83-.96,16.14-15.71,14.03-19.22,0,0,6.19-7.33,9.76-2.44,3.04,6.83,7.5,11.35,14.95,10.98-5.1,11.16-9.85,22.82,7.63,34.47h0Z"
            />
            <path
              id="wrist_bones_1"
              data-name="wrist bones 1"
              class="rhnd-cls-2"
              d="M211.86,631.74h0c-23.96-2.76-24.42-19.45-18.92-39.66l.92-3.36c5.17-3.89,9.22-15.73,12.81-30.81,6.13-6.49,12.55-10.76,19.22-13.12l14.64,6.41c3.73-.68,6.11-3.47,7.63-7.63,2.39,5.69,5.72,9.37,9.97,11.08-24.61,23.24-27.79,45.03-14.64,65.69-13.02,.61-21.78,.36-31.63,11.39h0Z"
            />
          </g>
          <g id="thumb_bones" data-name="thumb bones">
            <path
              id="thumb_bones_1"
              data-name="thumb bones 1"
              class="rhnd-cls-2"
              d="M60.53,329.4h0c-5.39,.94-10.67,4.96-15.86,11.29-6.13,2.99-10.67,3.23-13.73,.92-.1-8.6-.89-15.27-.61-23.19-13.02-15.92-11.77-30.22-13.42-44.85,7.91-2.03,14.92-.96,19.53,8.54,1.18,13.2,4.71,24.51,15.56,29.9,10.15,3.93,8.85,10.79,8.54,17.39h-.01Z"
            />
            <path
              id="thumb_bones_2"
              data-name="thumb bones 2"
              class="rhnd-cls-2"
              d="M89.82,423.06c.08,7.04-3.33,11.38-8.54,14.34-8.67-2.64-15.04,3.15-20.44,12.51-12.19-2.85-12.78-10.4-9.46-19.53,1.62-12.74-2.45-31.14-8.24-51.25-5.02-12.62-8.71-24.21-9.76-37.22,1.43,3.34,6.23,1.14,11.29-1.22,4.85-5.61,9.91-10.32,15.86-11.29,9.55,7.61,16.42,15.66,6.41,26.54,2.15,18.85,5.07,36.35,10.37,49.73,2.35,4.9,11.2,11.36,12.51,17.39h0Z"
            />
          </g>
          <g id="ringer_finger_bones" data-name="ringer finger bones">
            <path
              id="ringer_finger_bones_1"
              data-name="ringer finger bones 1"
              class="rhnd-cls-2"
              d="M352.2,140.85h0c-11.96,1.85-19.67,.45-18.31-7.93,7.97-6.71,9.15-.31,15.25-25.32-9.78-3.44-3.66-22.58,8.24-23.19,9.04-1.66,14.34,2.39,12.51,17.39-5.26,1.09-8.62,3.83-8.24,4.88l-1.22,23.19c15.25,8.54,5.49,16.47-8.24,10.98h0Z"
            />
            <path
              id="ringer_finger_bones_2"
              data-name="ringer finger bones 2"
              class="rhnd-cls-2"
              d="M336.94,140.24c-3.34,6.41-3.36,10.37-2.14,16.17-1.26,13.65-4.74,28.14-10.07,43.32-.21,8.97-4.94,16.68-10.68,24.1,.58,3.27,2.25,6.01,15.56,3.05,17.44,1.67,29.18-.09,22.58-12.81-2.75-11.88-1.8-24.56,1.83-37.83,5.45-5.81,8.2-11.61,8.54-17.39l.31-14.64c-4.58-2.14-7.41-.21-10.68-3.36-5.48,2.08-10.63,2.26-15.25-.61h0Z"
            />
            <path
              id="ringer_finger_bones_3"
              data-name="ringer finger bones 3"
              class="rhnd-cls-2"
              d="M334.5,228.11h0l12.51-.92c5.8,4.27,6.1,28.07-3.66,28.68l-2.14,31.73c-1.54,20.3-4.93,37.92-11.29,51.25,2.92,10.76,5.36,20.68,3.36,22.88-1.73,6.59-4.05,10.05-7.32,8.54-9.74-7.47-20.6-8.17-32.64-1.83-7.08,1.03-10.84-3.94-10.07-17.08,2.05-3.44,4.65-5.59,7.93-6.1,.61-14.03,15.56-35.08,21.36-75.97-.1-5.87,.4-12.17,1.53-18.92-3.4-5.74-3.55-16.78,1.22-22.88l19.22,.61h0Z"
            />
          </g>
          <g id="middlefinger_bones" data-name="middlefinger bones">
            <path
              id="middlefinger_bones_1"
              data-name="middlefinger bones 1"
              class="rhnd-cls-2"
              d="M276.84,69.77h0c-.05,4.08-1.99,8.67-4.27,10.68-9.54,8.39-25.32,5.19-31.42-1.22-2.34-2.45-2.03-6.42-2.14-10.37,10.81,1.39,13.3-6.11,15.56-28.98-6.92,2.03-6.33-3.36,6.41-22.27,2.18-2.09,5.31-2.06,10.37,1.22,2.44,16.47,10.77,25.94-1.53,27.15-1.2,14.91,.05,24.99,7.02,23.8h0Z"
            />
            <path
              id="middlefinger_bones_2"
              data-name="middlefinger bones 2"
              class="rhnd-cls-2"
              d="M242.05,80.75h0c9.24,7.51,19.48,6.21,30.2,.61,7.42,5.48-1.1,23.58-7.63,46.98l-.92,33.56c7.63,7.02,4.06,15.91,.61,20.14-12.11,10.32-23.58,13.44-33.56-.31-5.89-.92-1.02-11.16,4.27-24.71l8.24-36.92c4.88-6.41-6.41-25.63-1.22-39.36h0Z"
            />
            <path
              id="middlefinger_bones_3"
              data-name="middlefinger bones 3"
              class="rhnd-cls-2"
              d="M228.64,190.28h0c-2.83,11.07-1.7,20.56,3.66,28.37l-.31,48.51c-1.65,20.24-6.71,39.18-16.17,56.44,.05,11.32,3.4,15.49,9.76,13.12,12.52-7,22.88-8.33,30.51-2.44,5.84,4.6,10.06,4.95,12.51,.61,2.91-7.45-1.7-18.03-7.93-29.29l2.14-86.95c6.85-14.56,6.26-25.51-.31-33.56-8.06,7.23-26.34,1.67-33.86,5.19h0Z"
            />
          </g>
          <g id="index_finger_bones" data-name="index finger bones">
            <path
              id="index_finger_bones_1"
              data-name="index finger bones 1"
              class="rhnd-cls-2"
              d="M165.18,103.02c3.51,3.45,6.74,6.27,7.63,3.66,2.11,2.53,3.09,4.74,.92,6.1-2.64,5.06-7.52,7.65-12.81,9.76-7.73,.82-14.91,.25-20.75-3.66-2.58-2.56-.26-5.79,5.19-9.46,2.44-18.31,5.8-20.14,6.1-30.81-3.78-3.27-5.52-6.76-6.1-10.37,4.74-9.34,9.58-15.9,14.64-15.56,5.02,1.62,8.83,5.88,11.29,13.12,1.27,11.08-.73,15.77-3.97,18-2.23,5.37-2.85,11.84-2.14,19.22h0Z"
            />
            <path
              id="index_finger_bones_2"
              data-name="index finger bones 2"
              class="rhnd-cls-2"
              d="M175.25,201.87c-5.31-.93-11.99,2.34-18.92,6.41-9.4,2.28-17.69,2.86-20.14-5.49,3.45-6.6,8.29-14.11,9.15-32.95,1.01-7.11,.67-15.59-.31-24.71-6.09-10.61-3.71-17.14,.31-22.88l21.36-.61c4.26,3.72,8.29,7.66,1.83,21.36,1.16,6.81,2.4,13.54-.31,24.41,1.74,9.81,4.44,18.18,8.54,24.41,1.04,4.59,1.66,8.84-1.53,10.07l.02-.02Z"
            />
            <path
              id="index_finger_bones_3"
              data-name="index finger bones 3"
              class="rhnd-cls-2"
              d="M141.99,346.79h0c-9.51-4.93-6.97-21.66,1.22-43.93l-.31-52.78c-6.83-12.69-6.67-26.07-1.22-39.97l14.64-1.83c6.67-3.67,12.68-5.94,17.69-6.1,4.15,13.81,3.69,22.71-1.53,26.54l.31,50.95c1.06,16.75,3.3,32.07,9.46,42.71,.69,9.84-1.19,16.58-6.71,18.92-9.95-3.52-20.75-3.38-33.56,5.49h0Z"
            />
          </g>
          <g id="small_finger_bones" data-name="small finger bones">
            <path
              id="small_finger_bones_1"
              data-name="small finger bones 1"
              class="rhnd-cls-2"
              d="M441.89,238.79h0c-8.81,8.19-26.85,8.24-28.68,0,4.69-6.13,8.73-13.2,10.37-23.8-5.56-3.78,1.19-9.59,9.46-21.05,5.14,.85,8.3,2.72,7.93,6.41-3.54,7.58-5.06,18.36-6.1,29.9,4.66,2.5,6.94,5.36,7.02,8.54h0Z"
            />
            <path
              id="small_finger_bones_2"
              data-name="small finger bones 2"
              class="rhnd-cls-2"
              d="M414.13,243.06h0c7.81,1.92,15.1,3.06,20.44,1.22,1.93,4.01,.46,7.28-4.58,9.76-5.62,13.78-4.27,43.02-7.93,47.59-6.9,3.49-13.18,1.51-19.53,0,.8-5.38-6.12-3.73-9.76-3.05,5.11-12.37,10.38-24.35,16.47-34.17-1.22-10.67-.36-18.73,4.88-21.36h.01Z"
            />
            <path
              id="small_finger_bones_3"
              data-name="small finger bones 3"
              class="rhnd-cls-2"
              d="M373.25,407.5h0c-3.99-5.36-9.39-10.93-14-7.39-1.87,1.44-5.54,.52-7.96-.85-1.17-13.6,32.95-39.36,39.66-74.75-1.2-6.96-1.74-13.41-.61-18.61,2.28-3.95,4.89-7.13,8.24-8.54,1.26,.3,2.9-.79,6.24,5.28,1.81,3.29,5.97-1.31,12.98,.82,1.58,5.86,4.39,11.25-4.58,21.05-11.29,24.71-23.37,61.43-26.54,61.63,.85,9.56-1.39,23.24-3.36,24.41-1.92,2.03-11.51,1.78-10.07-3.05h0Z"
            />
          </g>
          <g id="middle_hand_bones" data-name="middle hand bones">
            <path
              id="middle_hand_bones_9"
              data-name="middle hand bones 9"
              class="rhnd-cls-2"
              d="M137.41,554.85c1.54,14.18-6.25,16.55-14.03,18.92-23.19,.61-27.63-7.62-31.42-23.8l-11.9-46.07c-8.39-8.43-15.15-26.2-21.97-43.63,.61-18,17.75-27.88,29.9-19.53,6.19,4.2,11.86,8.67,16.47,13.73,.86,2.94,.7,6.71-2.75,13.12,4.09,26.21,12.86,46.33,28.07,61.02,7.05-1.68,12.48,1.8,17.08,7.93-.26,2.3-.7,8.34,.31,15.56-1.38-1.98-4.65-3.27-9.76,2.75h0Z"
            />
            <path
              id="middle_hand_bones_8"
              data-name="middle hand bones 8"
              class="rhnd-cls-2"
              d="M290.84,552.72h.03c-1.12-1.41-32.47-2.8-32.03,1.22,.41,3.78-11.48-8.01-10.68-10.37l-.31-10.98c2.15-7.71,.32-8.51-2.14-8.24,3.42-7.52,8.35-12.23,14.34-14.95,8.04-21.2,16.3-42.43,18-53.39,2-15.6,3.85-30.18,4.88-39.36l.61-26.24c2.13-7.99,5.42-15.29,9.76-21.97,13.36-5.86,24.27-5.34,32.64,1.83,3.12,6.5,4.85,13.08,3.66,19.83-.34,9.11-5.79,25.47-16.17,48.81-6.05,15.42-12.46,33.51-19.53,56.44-2.26,10.67-2.37,21.48-1.53,32.34,.88,8.84,.57,17.25-1.53,25.02h0Z"
            />
            <path
              id="middle_hand_bones_7"
              data-name="middle hand bones 7"
              class="rhnd-cls-2"
              d="M283.35,390.51c19.19-19.02,34.05-21.42,46.37-.41"
            />
            <path
              id="middle_hand_bones_6"
              data-name="middle hand bones 6"
              class="rhnd-cls-2"
              d="M240.52,551.19h0c-4.36-2.26-9.31-4.38-14.64-6.41-5.19,1.46-11.25,5.01-19.22,13.12-6.85-11.34-8.55-23.98-6.71-37.53,2.52-7.16,6.94-13.07,12.81-18,3.23-29.22,6.85-57.33,7.93-88.17-1.64-8.37-1.61-16.81,0-25.32-4.47-.99-7.69-6.11-10.07-14.03,6.41-15.25-1.53-21.97,14.95-38.14l30.51-2.44c5.35,6.26,9.64,13.4,12.2,21.97,2.79,15.95-13.42,64.68-25.63,106.47-3.58,21.55-2.69,39.98,3.66,54.61l-.61,7.02c4.25-.82,2.14,13.42,2.44,19.22,.31,4.52-2.65,6.77-7.63,7.63h0Z"
            />
            <path
              id="middle_hand_bones_5"
              data-name="middle hand bones 5"
              class="rhnd-cls-2"
              d="M206.67,557.9c-3.65,3.5-8.24,4.05-13.73,1.83-8.42-12.4-18-16.29-30.81,3.36-13.84,.07-16.04-14.04-15.25-26.54,4.48-3.92,7.48-7.97,8.24-12.2-3.28-21.81-4.47-47.51-3.36-77.49-2.42-33.61-5.96-53.5-9.76-70.17-4.14,2.58-7.07,1.34-8.24-5.49,2.01-10.33,3.75-21.45,8.24-24.41,11.5-8.07,22.68-9.73,33.56-5.49,3.62,5.02,9.2,10.98,5.49,12.51,9.37,1.06,6.84,16.22,.92,35.39l-.31,110.75c8.34,6.66,18.53,24.07,17.69,25.02,.01,18.29,2.36,25.86,7.32,32.95v-.02Z"
            />
            <path
              id="middle_hand_bones_4"
              data-name="middle hand bones 4"
              class="rhnd-cls-2"
              d="M311.03,569.5h-.02c-8.6-.05-14.13-9.12-20.14-16.78l1.53-25.02c23.9-4,35.56-45.48,47.29-91.83-1.64-8.12-1.15-15.22,3.97-20.14,1.85-7.32,4.44-13.59,10.68-14.64,10.31,.24,18.44,4.11,20.75,17.69,4.93-3.05,8.55-3.08,8.24,7.93-.43,3.34-.38,6.93,0,10.68-9.74,9.26-17.63,23.77-23.49,41.19-5.93,18.86-17.37,35.77-29.9,53.69,1.2,10.47,.86,19.97-1.22,28.37-5.14,7.3-10.93,10.9-17.69,8.85h0Z"
            />
            <path
              id="middle_hand_bones_3"
              data-name="middle hand bones 3"
              class="rhnd-cls-2"
              d="M343.5,416.42c9.69-4.96,19.53,3.89,30.74,7.32,2.53-1.1,6.23-3.18,7.55-5.97"
            />
            <path
              id="middle_hand_bones_2"
              data-name="middle hand bones 2"
              class="rhnd-cls-2"
              d="M210.87,374.7c6.39-3.8,11.52-17.77,19.19-28.93,9.59-1.19,14.03,4.9,19.93,5.69,1.95-4.63,8.47-2.47,14.11-5.06"
            />
            <path
              id="middle_hand_bones_1"
              data-name="middle hand bones 1"
              class="rhnd-cls-2"
              d="M134.06,371.4c7.73-6.51,8.45-16.38,15.05-17.9,4.28-1.12,13.72,.89,24.41,3.66,1.1-2.74,4.69-3.48,8.95-3.66"
            />
          </g>
        </g>
      </g>
      <g id="finger_1overlay" data-name="finger 1overlay">
        <path
          id="finger_1_overlay_1"
          data-name="finger 1 overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandRight.id, HandRight.children.finger1),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(
            selected,
            HandRight.children.finger1
          )}`}
          d="M16.91,273.57c7.45-2.82,14.09-.75,19.53,8.54,2.38,16.43,7.06,27.55,15.56,29.9,7.14,2.18,10.03,7.94,8.54,17.39,12.24,9.06,14.88,17.92,6.4,26.54,1.61,17.45,4.85,34.13,10.37,49.73,6.03,6.88,11.65,13.52,12.51,17.39,.51,7.8-3.1,11.9-8.54,14.34-8.93-3.09-14.88,4.03-20.44,12.51-11.64-2.09-11.06-9.63-10.2-17.29,3.01-24.09-10.34-57.7-17.26-90.71l-2.44-.3c-.33-6.98-.51-14.83-.61-23.19-8.88-8.19-12.27-24.79-13.42-44.85h0Z"
        />
      </g>
      <g id="finger_2_overlay" data-name="finger 2 overlay">
        <path
          id="finger_2_overlay_1"
          data-name="finger 2 overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandRight.id, HandRight.children.finger2),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(
            selected,
            HandRight.children.finger2
          )}`}
          d="M151.46,78.61l-6.1,30.81c-7,5.19-9.09,9.73-.02,12.83-4.53,6.73-5.91,14.1-.31,22.88,3.15,20.59-.86,39.57-8.84,57.66l5.49,7.32c-4.23,14.35-5.74,28.1,1.22,39.97l.31,52.78c-6.3,17.44-11.65,34.43-1.23,43.93,12.51-6.4,24.45-10.78,33.58-5.49,4.27-1.81,7.37-6.23,6.69-18.92-9.19-19.17-12.08-51.14-9.77-93.66,6.38-6.77,4.9-16.32,1.53-26.54,4.98-.92,4.47-5.26,2.75-10.36l-8.54-24.41c2.8-6.82,2.46-15.18,.31-24.41,4.44-8.69,3.09-15.58-1.83-21.36l-2.18-.68,9.21-8.18c2.27-1.53,1.04-3.74-.92-6.1-1.9,2.05-4.68-.34-7.63-3.66-1.04-4.69-.07-11.35,2.14-19.22,4.1-4.48,5.69-10.7,3.37-19.62-6.2-15.86-14.4-16.41-25.33,4.06,.83,5.38,2.79,8.96,6.1,10.37Z"
        />
      </g>
      <g id="finger_3_overlay" data-name="finger 3 overlay">
        <path
          id="finger_3_overlay_1"
          data-name="finger 3 overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandRight.id, HandRight.children.finger3),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(
            selected,
            HandRight.children.finger3
          )}`}
          d="M260.97,17.61c-9.7,14.96-15.08,25.62-6.41,22.27-.65,18.36-4.05,31.47-15.56,28.98-.44,6.61,.52,10.68,3.04,11.88-2.2,7.54-1.92,15.19,.61,22.95,1.87,6.04,2.53,11.66,.61,16.41l-9.33,39.69c-5.77,12.55-7.94,21.1-3.18,21.94l6.73,7.25-8.84,1.3c-2.13,10.86-1.88,20.72,3.66,28.37,3.91,45.24-2.86,78.84-16.48,104.95,1.3,12.96,5.74,16.17,12.25,12.91,10.8-5.57,20.89-8.81,28.02-2.24,17.02,10.25,14.9-3.96,4.58-28.67l2.14-86.95c7.25-13.67,5.6-24.33-.31-33.56,9.07-9.12,7.08-16.47,1.21-23.19l.92-33.56c9.26-25.76,12.5-42.6,7.94-47.89l4.27-10.67c-6.82,1.83-7.57-9.56-7.02-23.8,9.93,1.25,7.05-11.5,1.53-27.15-3.12-3.42-6.62-3.37-10.37-1.22h-.01Z"
        />
      </g>
      <g id="finger_4_overlay" data-name="finger 4 overlay">
        <path
          id="finger_4_overlay_1"
          data-name="finger 4 overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandRight.id, HandRight.children.finger4),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(
            selected,
            HandRight.children.finger4
          )}`}
          d="M283.25,351.36c-1.3,10.13,1.04,16.79,10.05,17.08,12.98-5.46,24.3-6.07,32.66,1.83,3.61,.56,6.83-.01,7.32-8.54,1.77-6.15-.19-14.21-3.36-22.88,8.72-18.25,12.42-47.62,13.43-82.98,10.4-7.83,9.08-17.87,3.66-28.68,5.43-.87,8.18-4.28,5.18-13.12-3.03-10.32-1.41-23.57,1.83-37.83,8.24-6.07,9.58-18.89,8.85-33.73,8.8-2.12,5.62-6.85-2.44-12.64l1.22-23.19,8.24-4.88c1.75-15.37-3.7-19.09-12.51-17.39-12.36,5.4-16.59,12.77-8.24,23.19l-6.45,18.93-8.8,6.39c-1.21,5.45,.33,7.18,3.05,7.32-3.07,4.54-4.42,9.7-2.14,16.17-2.16,17.08-5.5,31.55-10.07,43.32-.66,9.75-4.81,17.44-10.68,24.1l1.24,3.66c-4.22,10.19-4.98,18.06-1.22,22.88l-1.53,18.92c-4.4,24.99-11.97,50.37-21.36,75.97-3.09,.27-5.65,2.64-7.93,6.1h0Z"
        />
      </g>
      <g id="finger_5_overlay" data-name="finger 5 overlay">
        <path
          id="finger_5_overlay_1"
          data-name="finger 5 overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandRight.id, HandRight.children.finger5),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(
            selected,
            HandRight.children.finger5
          )}`}
          d="M351.29,399.26c9.89,2.18,18.94,4.96,22.96,11.29,3.24,1.47,6.3,1.73,9.07,0,3.22-6.76,3.79-15.26,3.36-24.41l26.54-61.63c6.68-5.75,8.22-12.8,4.58-21.05l5.4-4.55c2.46-26.9,4.65-37.32,6.79-44.87,4.76-1.74,6.45-4.92,4.58-9.76l7.32-5.49c-1.01-4.29-3.3-7.2-7.02-8.54-1.33-10.3,.82-20.25,6.1-29.9,.54-5.57-2.99-6.75-7.93-6.41-10.14,11.95-14.98,20.16-9.46,21.05-1.9,10.97-5.75,18.15-10.37,23.8l.92,4.27c-6.29,5.05-6.29,12.89-4.89,21.35l-16.47,34.17,4.84-.75c-3.1,2.34-5.79,4.9-7.27,8.07-1.4,5.62-1.17,11.83,.61,18.61-6.81,23.46-18.05,42.42-32.24,58.38-5.75,6.9-10.01,13.14-7.42,16.37h0Z"
        />
      </g>
      <g id="wrist">
        <path
          id="wrist_overlay"
          data-name="wrist overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandRight.id, HandRight.children.wrist),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(selected, HandRight.children.wrist)}`}
          d="M118.51,603.67c12.57,10.58,54.15-.77,37.21,2.13-8.16,1.4-14.26,16.8-11.5,23.1,4.51,10.31,49.27,7.33,67.64,2.83l33.85-14.33c13.71,1.64,30.43-11.17,47.17-24.11,13.74-9.37,14.9-17.8,11.3-25.88l-13.34-14.7-32,1.23-10.68-10.37c-2.36,9.51-11.67,6.53-22.27,1.22l-16.13,9.89c-2.29,3.28-5.88,7.67-16.82,5.06-9.82-17.34-20.24-10.01-30.81,3.36l-11.52-5.2c-4.05-8.34-8.5-8.47-13.19-3.35-.18,10.6-5.49,16.43-14.04,19.22-7.99,6.93-9.04,17.17-4.87,29.9h0Z"
        />
      </g>
      <path
        id="middlehand"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, HandRight.id, HandRight.children.middleHand),
          });
        }}
        class={`lhnd-cls-4 ${isSelected(
          selected,
          HandRight.children.middleHand
        )}`}
        d="M375.22,416.65c-4.2-10.78-11.82-16.1-22.5-16.5-4.43,3.86-7.89,8.59-8.63,15.75-4.7,3.55-6.01,9.88-3.75,19.12-11.88,46.88-25.32,87.17-46.88,93-4.7-22.28,4.6-55.86,22.5-96.37,7.37-15.43,12.19-30.03,13.5-43.5,.99-5.15-.3-11.56-3-18.75-12.01-6.63-23.01-7-33-1.13-5.42,8.46-8.52,16.08-9.38,22.87-1.08,45.09-8.39,85.03-24,118.12-6.21,3.49-11.04,8.29-13.88,15l.75-7.5c-6.87-15.25-7.91-33.48-4.31-54.07,14.37-44.75,23.63-81.45,25.68-106.81l-12.37-22.5c-8.63-3.64-18.47-3.82-30.75,3-6.08,7.7-10.44,15.35-10.5,22.87l-4.13,16.12c3.35,8.68,6.73,12.64,10.12,12.75-2.05,9.73-2.76,18.51-.38,25.12l-7.5,88.5c-5.69,5.32-10.86,11.21-13.5,19.87-7.2-10.82-13.27-18.72-17.62-22.13v-111.38c7.42-19.52,6.49-30.3,.38-35.62l-6.75-12c-14.55-3.17-25.37-.35-33.75,6.37-9.08,22.65-8.92,32.05,0,28.88,7.94,31.63,10.99,62.64,9,93,.52,20.92,1.62,40.55,4.87,55.5-1.46,4.53-3.55,8.68-8.25,11.25-6.04-5.11-12.05-8.39-18-6.38-16.34-14.77-24.35-36.54-28.12-61.88,8.86-11.75-1.13-21.01-18.75-29.25-13.35-.54-22.18,5.41-23.25,22.5,4.66,16.15,12.09,30.87,21.37,44.63,7.87,35.25,12.28,73.06,41.25,69,11.66-.05,15.83-7.5,15.38-19.5,3.59-5.08,7.09-5.97,10.5-3,1.89,5.98,6.9,9.41,13.87,11.25,13.97-19.27,23.95-17.23,31.5-2.25,7.12,.87,11.57-.04,13.5-2.62,5.26-7.65,12-11.69,19.5-13.87,2.78,1.71,7.74,4.11,14.62,7.13,4.16-.61,6.46-3.21,7.88-6.75,3.03,4.91,6.37,8.5,10.5,8.63,7.28-4.81,19.52-3.42,31.88-1.87,4.78,9.83,11.71,15.6,20.62,17.63,8.14,1.61,13.19-3.23,17.63-9.37,2.85-10.43,2.89-19.23,1.13-27,15.18-21.18,26.36-42.68,33.38-64.5,5.22-12.43,11.77-22.86,19.5-31.5,1.09-15.81-1.68-22.08-7.87-19.88Z"
      />
      <path
        id="hand_joint"
        data-name="hand joint"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, HandRight.id, HandRight.children.handJoint),
          });
        }}
        class={`lhnd-cls-4 ${isSelected(
          selected,
          HandRight.children.handJoint
        )}`}
        d="M301.35,592.9c-8.88,.94-29.73,13.95-49.5,25.87-37.47,13.83-72.5,24.06-95.25,16.12,2.9,11.42,8.53,20.03,16.87,25.88l-.75,2.62c-7.91-4.24-15.62-8.16-19.87-6.38-6.48,8.74-9.02,21.33-7.88,37.5,38.05,3.64,74.31,2.15,108-6.75,16.31,3.71,36.16,2.5,57.75-1.12-.22-9.85-3.55-15.73-10.87-16.5-2.8,2.63-5.05,5.7-5.63,10.12l-27-4.88c-4.86,1.51-8.78,4.31-10.5,10.12l-2.63,.37c2.1-7.19-.64-13.44-6.37-19.13h-12c-4.15-1.34-2.02-3.22,1.88-5.25,10.49-4.29,19.19-12.8,27.38-22.5,2.47,6.5,9.59,1.39,19.88-11.62,8.26,8.75,16.38,5.08,24.38-9.37,5.12-13.11,2.51-21.49-7.87-25.13Z"
      />
      <path
        id="hand_joint_wth_lower_arm"
        data-name="hand joint wth lower arm"
        onClick={() => {
          bpartSetter({
            id: idToSet(
              selected,
              HandRight.id,
              HandRight.children.handJointWLowerArm
            ),
          });
        }}
        class={`lhnd-cls-3 ${isSelected(
          selected,
          HandRight.children.handJointWLowerArm
        )}`}
        d="M310.35,687.78c-5.83,10.77-8.98,22.88-9.38,36.37l-1.13,28.87h-33.75s1.88-14.63,1.88-14.63c-2.45-12.45-7.83-22.99-15.75-31.87-1.04-5.3-.22-10.08,1.5-14.62h-2.25c-11.42,17.17-18.47,36.91-17.25,61.5l-75.38,1.5c1.98-17.52-1.73-37.37-13.12-60.37,38.21,6.01,75.79,2.73,106.88-6.37l3,.75c19.72,4.1,38.59,2.11,54.75-1.12Z"
      />
    </svg>
  );
}

export default FrontView;
