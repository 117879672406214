import style from "./style.scss";

function RechtesBeckenAlaNachJudetVdOblLiegend1209(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 498.45 722.53"
    >
      <g id="BG">
        <path
          id="Bg"
          class="becken_ala_right1209_cls2"
          d="M116.75,670.41c-4.8-18.3-6.5-15.2-10-30-4-17-8.4-36-3.3-56.6,1.7-7,4.3-13,2.2-21.1-2.2-8.4-6.9-9.9-7.8-17.8-1.1-10.2,5.5-18.5,6.7-20,2.6-3.3,4.5-4.2,23.3-13.3,22.5-10.9,23.8-11.6,27.8-12.2,6.8-1,10.4-.2,13.3-3.3,4-4.5-.2-9.5,3.3-17.8,3.5-8.3,10.1-8.8,10-14.4-.1-4.8-5.1-5.1-7.8-12.2-2.9-7.6,1.4-10.7-1.1-17.8-1.5-4.3-5.5-7.4-13.3-13.3-11.9-9-16.1-8.8-18.9-14.4-3-6-.3-10.3,0-17.8.5-16.3-10.8-28.7-16.7-35.5-8.5-9.9-17.1-14.7-26.7-20-13.7-7.6-27.2-15.1-44.4-14.4-12.2.5-16.2,4.6-22.2,1.1-5.7-3.4-5.7-9.2-12.2-28.9-5.9-17.6-6.7-15.2-8.9-24.4-3.4-14.6-.7-16.8-3.3-36.7-2.5-19.4-5.5-20.6-4.4-31.1.9-8.3,3.1-11.2,8.9-26.7,5.8-15.7,7.7-24,10-31.1,12.2-37.2,46.3-69.8,48.9-72.2,23.4-22,46-32.2,63.3-40,14.4-6.5,38.5-17.1,72.2-21.1,10.9-1.3,44.8-4.4,86.6,6.7,34.6,9.2,58.5,24,67.7,30,26.3,17.3,42.4,35.9,54.4,50,14.9,17.5,23.7,31.7,26.7,36.7,3.9,6.6,2.7,5.3,22.2,51.1,1.5,3.6,4.9,11.5,3.3,21.1-.5,3.3-1.2,7.5-4.4,11.1-1.4,1.6-4.1,3.9-21.1,7.8-9.1,2.1-11.2,2.1-14.4,4.4-1.8,1.3-6.5,5.1-7.8,11.1-1.3,5.9,1.9,9,4.4,16.7,2.6,7.8,5.4,23.5-1.3,29.9-4,3.8-8.2,1.3-18.7,3.4-9,1.8-14.9,5.4-24,11-11.4,7-27.4,16.9-39.3,36.7-4.2,6.9-13.6,23.1-13.3,45.5.2,17.9,6.5,30.8,12.2,42.2,5.3,10.7,9.1,14.4,12.2,16.7,6.8,5,10.7,3.6,22.2,7.8,9.3,3.5,19,6.9,25.5,15.6,6.7,8.8,10.3,23.3,4.6,32-3,4.6-6.2,3.9-14.6,12.4-3.2,3.2-6.7,6.7-8.9,11.1-5.6,11.4-2,23.8-1.1,26.7,1.9,6.5,5.4,10.2,38.9,36.7,12.6,9.9,14,10.9,16.7,12.2,17.1,8.4,26.3,2.5,34.4,9.7,11.9,10.6,8.5,37.9,0,41.3-6,2.4-14.4-7.3-17.8-4.4-2.5,2.2,1.7,7.8-1.1,15.6-1.1,3.1-2.8,5-6.7,8.9-10.8,10.9-16.2,16.4-20,18.9-5.7,3.8-6.9,3.2-22.2,10-11.1,4.9-17.8,8.4-21.1,10-23.8,11.4-87.4,25.9-100,5.6-4.7-7.5,2.1-12.9-4.4-25.5-4.4-8.4-8.8-8.5-17.8-22.2-4.7-7.1-5.4-9.2-7.8-10-10.7-3.7-13.7,15-16.7,28.9-4.6,21.4,3.7,28.7-2.2,36.7-4,5.5-11.1,6.3-56.6,3.3-37.8-2.5-43.2-3.7-47.8-8.9-6.8-7.7-2.9-14-9.8-41.5Z"
        />
      </g>
      <g id="underlays">
        <path
          id="Pfannerande"
          class="Pfannerande1209Fill"
          style={{
            fill: props.colors.Pfannerande1209Fill,
            stroke: props.colors.Pfannerande1209Fill,
          }}
          d="M322.75,371.01c4.08,5.48.02,8.79,6,30,4.4,15.62,9.34,32.34,23,40,6.12,3.43,6.8,1.01,18,6,11.9,5.29,19.48,11.74,23,15,8.48,7.86,13.61,12.62,14,20,.48,9.15-6.63,16.54-9,19-6.07,6.31-8.66,4.19-13,9-7.26,8.05-1.53,15.62-7,37-2.56,10-4.37,10.5-5,18-.93,11,2.31,17.64-1,20-2.24,1.6-4.82-.91-13-3-14.19-3.62-18.22-1.67-22-5-6.94-6.12-3.01-20.88-2-25,1.58-6.46,3.22-6.81,4-12,1.22-8.1-2.04-12-5-22-5.29-17.87-.19-23.44-7-39-2.33-5.32-4.6-8.51-7-11-5.38-5.6-8.51-4.73-13-9-7.95-7.56-3.39-15.25-10-21-3.42-2.98-6.04-2.13-24-3-11.64-.57-5.17-.66-28-2"
        />
        <path
          id="hipjointspace"
          class="Hipjointspace1209Fill"
          style={{
            fill: props.colors.Hipjointspace1209Fill,
            stroke: props.colors.Hipjointspace1209Fill,
          }}
          d="M333.75,578.01c1.79-3.07,0-4.92,0-12,.01-9.12,3-11.49,4-20,.58-5-.22-6.11-2-19-2.14-15.52-1.62-17.54-4-35-1.82-13.4-2.47-16.38-5-20-4.82-6.88-9.11-5.5-16-13-8.35-9.09-5.35-14.25-12-20-3.79-3.28-6.73-3.25-30-5-24.82-1.87-26.64-2.29-34-2-13.28.52-22.62,2.48-25,3-13.74,2.99-31.28,6.79-33,16-.9,4.81,2.64,10.76,7,12,5.97,1.7,11.72-5.92,18-11,20.25-16.39,55.51-14.03,78-2,13.81,7.39,22.25,18.13,26,23,3.7,4.8,9.7,13.6,14,26,2.29,6.63,8.76,26.1,3,49-1.79,7.14-3.71,14.76-10,22-8.03,9.25-17.72,11.55-17,14,1,3.4,20.04.17,21,0,13.78-2.39,16.09-4.44,17-6Z"
        />
        <path
          id="Darmbeinschlaufel"
          class="Darmbeinschaufel1209Fill"
          style={{
            fill: props.colors.Darmbeinschaufel1209Fill,
            stroke: props.colors.Darmbeinschaufel1209Fill,
          }}
          d="M468.75,214.01c7.07-8.21,4.18-20.82,3-26-2.35-10.28-6.67-14.98-12-24-11.31-19.14-7.15-23.92-16-41-2.65-5.11-5.69-9.85-33-38-20.85-21.5-31.28-32.25-36-36-23.56-18.73-46.09-27.63-60-33-11.3-4.36-28.09-10.74-51-14-37.05-5.28-66.33.96-89,6-15.19,3.38-31.18,6.93-51,16-9.52,4.35-42.97,20.52-74,56-13.52,15.46-35.25,40.31-45,79C-1.77,184.87-.33,206.61,1.75,238.01c.6,9.08,1.47,25.22,7,44,1.08,3.66,3.05,8.77,7,19,7.39,19.15,10.17,23.67,14,24,2.36.21,4.78-1.21,6-3,3.25-4.76-1.74-12.56-6-22-3.04-6.73-6.64-16.37-9-29-3.58-13.02-7.39-32.01-7-55,.89-52.32,22.94-90.51,34-107,21.31-31.77,46.1-48.93,58-57,43.48-29.47,85.13-35.55,97-37,40.22-4.9,71.91,2.84,86,7,32.36,9.56,54.41,24.63,65,32,15.73,10.94,26.49,21.3,48,42,14.7,14.14,22.19,21.41,29,31,5.68,7.99,11.79,22.33,24,51,4.58,10.76,7.64,18.47,5,27-2.64,8.53-9.45,12.9-8,15,1.55,2.25,11.49.4,17-6Z"
        />
      </g>
      <g id="Becken">
        <path
          id="Becken17"
          class="becken_ala_right1209_cls6"
          d="M125.95,715.71c-1.2-6.9-3.2-17.4-6.7-30-5.2-18.5-8.3-22.1-11.7-37.5-3.3-14.8-3.9-26.9-4.2-34.2-.8-17.4-1.2-26.1,0-35.8,1.1-9.3,2.9-15.9-.8-23.3-2.4-4.7-5.1-6-5.8-10.8-1-6.7,3.3-12.5,5.8-15.8,4.9-6.6,10.4-9.5,20.8-15,4.1-2.1,13.5-6.8,26.7-10.8,8.3-2.5,13.1-3.9,20-4.2,5.3-.2,12.7-.4,20.8,3.3,3.7,1.7,10.5,4.8,14.2,11.7,3,5.6.9,7.8,2.5,19.2.9,5.9,2.8,12,6.7,24.2,3.1,9.6,4.7,14.5,6.7,18.3,4.6,8.6,7,8.5,11.7,17.5,3.6,6.9,5.3,12.7,6.7,17.5,2,6.7,1.7,8.2,4.2,20.8,2.6,13.3,3.9,20,5.8,23.3,1.7,2.9,1.7,2.1,15,17.5,6.8,7.9,7.5,9,8.3,10.8,2,4.8,1.7,7.8,3.3,19.2.7,4.3,1.3,7.8,1.7,10"
        />
        <path
          id="Becken16"
          class="becken_ala_right1209_cls6"
          d="M169.45,540.71c-27.5,32.5-41.3,118.3-35.9,152.6"
        />
        <path
          id="Becken15"
          class="becken_ala_right1209_cls6"
          d="M202.05,518.41c-16.2,19.5-47.9,30-59.4,15.8"
        />
        <path
          id="Becken14"
          class="becken_ala_right1209_cls6"
          d="M165.15,515.91h0c0-42.8,36.2-81.9,80.4-77.5,42,4.2,76.4,42.5,79.7,73.3,7.3,66.8-31,81.7-79.7,81.7-44.4,0-80.4-34.7-80.4-77.5h0Z"
        />
        <path
          id="Becken13"
          class="becken_ala_right1209_cls6"
          d="M245.65,641.91c0-39.3,16-65.2,32-71"
        />
        <path
          id="Becken12"
          class="becken_ala_right1209_cls6"
          d="M230.15,715.81c0-37.4,7.6-67.6,17-67.6"
        />
        <path
          id="Becken11"
          class="becken_ala_right1209_cls6"
          d="M379.25,706.61c-9.6-6.7-17.1-12.8-22.5-17.5-8.8-7.7-17.2-15-25.8-26.7-5-6.8-7.7-10.5-10-16.7-2.6-7.1-1.8-9.8-5-23.3-2-8.1-2.9-12.1-5-15.8-4.7-8.3-10.5-10.3-15.8-15-7.6-6.9-7-13.5-13.3-39.2-6-24.8-9-37.2-15.8-47.5-7.2-10.9-15.7-18-24.2-25-9.7-8-17.4-12.4-29.2-19.2-12-6.9-18.2-10.3-25-10.8-6.2-.5-9.6.9-12.5-1.7-5-4.5.8-12.6-4.2-20-3-4.4-5.8-2.8-15-9.2-5.8-4-9.9-6.8-12.5-11.7-3-5.6-1.8-9.4-2.5-16.7-1-10.4-4.9-17.9-8.3-24.2-8.3-15.7-21.6-23.8-33.3-30.8-9.1-5.5-19.3-11.7-34-13.9-18.9-2.9-26.1,3.7-34.3-2.7-5-4-3.3-7.2-11.7-29.2-5.8-15-7.2-15-9.2-23.3-2.1-8.6-.9-9.9-2.5-27.5-1.6-18.1-3.1-19-3.3-29.2-.3-12.6,1.6-22.2,3.3-30,1.2-5.6,5.5-23.3,18.3-48.3,6.7-12.8,19.2-36.8,44.2-60.8,22.5-21.8,44.1-32.9,56.6-39.2,11.8-6,34.1-17,65.8-22.5,7.9-1.3,35-5.6,70-.8,32,4.4,55,14.1,62.5,17.5,3.9,1.7,21.7,9.8,42.5,25,6,4.4,23.6,17.7,42.5,39.2,7.4,8.5,18.9,21.7,30,41.7,10.3,18.6,4.8,15,17.5,40,4.3,8.6,9.2,17.4,8.3,29.2-.4,4.5-.8,9.2-4.2,13.3-5.9,7.1-13.9,3.4-28.3,10-6,2.7-13.8,6.3-15.8,13.3-1.3,4.8,1.1,7.2,4.2,17.5,3.6,12,6.9,22.7,2.5,28.3-3.8,4.7-10,2-21.7,4.2-11.5,2.2-18.8,7.2-30,15-7.2,5.1-21.7,15.3-33.3,34.2-6.5,10.6-15.9,25.9-15,46.7.7,15.9,7,27.4,12.5,37.5,5.4,9.9,9.6,17.4,18.3,22.5,6.8,4,8.8,2.2,20.8,7.5,8.1,3.6,14.8,6.6,20.8,13.3,3.4,3.8,9,10,9.2,19.2,0,1.2.1,9.7-5.8,16.7-5.5,6.5-10.5,5-15.8,10-5.4,5.1-5.5,11.6-6.7,26.7-2.8,33.5-4.5,28.2-4.2,40.8.3,8.9.8,23,6.7,40,4.4,12.7,8.7,25,20.8,35,2.6,2.2,17.2,14.2,35,11.7,3.9-.6,8.7,1.8,11.9-4,3.6-6.6,1.9-18.9-2.7-26.9-8-14.1-18.3-23.7-25.8-28.3"
        />
        <path
          id="Becken10"
          class="becken_ala_right1209_cls6"
          d="M339.45,330.11c-67.9,92.6,35.7,267.9,126.6,270.7"
        />
        <path
          id="Becken9"
          class="becken_ala_right1209_cls6"
          d="M466.75,644.21c-2.8-1.7-7.4-4-13.3-5-8.8-1.5-11.9,1.2-19.2-.8-8-2.2-13-7.7-15-10-6.5-7.2-4.4-10.9-10-18.3-2.9-4-8.9-7.4-20.8-14.2-10.8-6.3-16.3-9.4-23.3-11.7-11.3-3.8-20-5-20-5-6.6-1-7.6-.4-9.2-1.7-5.9-4.6.7-16.7,1.5-35.7.1-2.4-1.8-15.5-5.8-41.7-3.2-20.9-3.8-24.6-7.5-29.2-6.1-7.8-11.4-6.6-18.3-16.7-4.4-6.5-4.6-10.5-10-14.2-3.8-2.6-7.5-3.3-13.3-4.2-7.7-1.2-12.8-.8-18.3-.8-14,.1-13.3-2.3-26.7-2.5-15.1-.3-25.9,2.4-29.2,3.3s-11.1,3.1-20,8.3c-7.6,4.4-11.5,6.7-11.7,10-.2,5.1,8.5,6.9,24.2,17.5,17.5,11.8,17,16.5,28.3,20,5.4,1.6,9.9,1.9,16.7,5.8,3.1,1.7,8.2,4.7,11.7,10,4.4,6.6,3.4,12.4,4.2,20,1.5,13.2,7.5,22.1,17.5,36.7,8.6,12.7,13.5,19.6,22.5,21.7,6.7,1.6,30.3-6.7,34.2-8.3"
        />
        <path
          id="Becken8"
          class="becken_ala_right1209_cls5"
          d="M469.15,33.61c-1.6,3.2-5.6,10.1-13.3,12.5-5.5,1.7-10.4.3-17.5-1.7-10.2-2.9-11.7-5.8-20-6.7-3.9-.4-10.4-1.1-13.3,2.5-1.7,2.2-1,4.3-.8,11.7.4,13.1-1.7,13.6,0,17.5,1.7,3.9,6.7,6.5,16.7,11.7,8.5,4.4,12.8,6.6,19.2,8.3,10.3,2.8,13.7,1.1,18.3,5,4.6,3.9,6,9.8,6.7,12.5,1.3,5.6-.2,6.9.8,15.8.7,6.4,1,9.6,3.3,12.5,3.8,4.9,10.5,5.7,13.3,5.8"
        />
        <path
          id="Becken7"
          class="becken_ala_right1209_cls5"
          d="M424.25,283.41c19.6,1.7,50,35.1,58.3,77.5"
        />
        <path
          id="Becken6"
          class="becken_ala_right1209_cls5"
          d="M466.05,265.11c0-7.3,8.9-13.3,19.9-13.3"
        />
        <path
          id="Becken5"
          class="becken_ala_right1209_cls5"
          d="M456.65,235.11c-1.6-4.9,19.3-20.8,29.2-20.8"
        />
        <path
          id="Becken4"
          class="becken_ala_right1209_cls5"
          d="M476.05,273.51h22.4"
        />
        <path
          id="Becken3"
          class="becken_ala_right1209_cls5"
          d="M475.95,292.61c0-3.2,2.9-5.8,6.6-5.8"
        />
        <path
          id="Becken2"
          class="becken_ala_right1209_cls5"
          d="M388.35,96.31c.8,2.6,2,7.2.8,12.5-1.2,5.5-4.4,9-8.3,13.3-2.1,2.3-5.8,6.3-11.7,10-4.9,3-7.1,3.1-8.3,5.8-1.7,3.9,1.7,6.3,0,10.8-1.6,4.3-5.6,4.3-5.8,7.5-.2,3.1,3.6,3.8,5,8.3,1.1,3.7-.7,5.8-1.7,11.7-.9,5.4-.3,8.6,0,13.3.7,11.5-2.1,14.4-3.3,29.2-1.1,12.8,0,22.7.8,30,1.6,14,1.8,18.7,5,23.3,1.5,2.1,1.7,1.7,8.1,9.3,6.1,7.3,10,11.8,9.4,12.4,0,0,4.7,2.7,5.1,1.9.6-1.1,4.2-2.4,4.2-2.4,1.7-2.3-10.4-10-19.3-26.9-2.2-4.3-6.2-12.8-8-24.1-.3-1.9-.8-5.6-.3-22.6.5-14.8.8-25,2.5-39.2.7-6.1,1.6-11.3,3.3-21.7,2.2-13,3-16,4.2-19.2,3.8-10.3,10-17.9,10.8-17.5.7.4-2.9,6.7-1.7,7.5,1.4,1,10.3-5.5,13.3-15,.7-2.1,1.1-6.7,1.7-15.8.7-9.7.5-10.7,1.7-11.7,2-1.7,5.4-1.1,26.7,8.3,5.8,2.6,11.7,5,17.5,7.5,8.9,3.9,11.7,5.1,15.8,5,6.8-.2,12-4,15-6.7"
        />
        <path
          id="Becken1"
          class="becken_ala_right1209_cls5"
          d="M466.75,214.31c-1.2,1.9-4.2,6.3-10,9.2-4.1,2.1-6,1-13.3,2.5-8,1.6-14.6,3-16.7,7.5-.4.9-.7,2.1,1.7,12.5,1.7,7.3,2.2,8.2,3.3,13.3,1.8,8.1,2.7,12.3,1.7,16.7-1.1,4.9-7.1,5.2-9.2,7.5"
        />
      </g>
    </svg>
  );
}

export default RechtesBeckenAlaNachJudetVdOblLiegend1209;
