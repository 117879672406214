import style from "./style.scss";

function LinkesHandgelenkDpOblSitzend1361(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.22 305.22">
      <g id="Backrounds">
        <path
          id="backround2"
          class="handgelenk_links_dp_obl1361_cls5"
          d="M120.85,305.2c0-19.84-.1-35.52-.18-45.4,0,0-.29-35.07,2.43-66.94.19-2.24.59-6.34,1.75-11.53,1.05-4.68,2.31-8.44,3.26-10.98,1.95-7.75.33-14.36-2.73-20.51,1.04-1.84.9-5.33,0-9.87-1.14-3.37-1.66-6.51-1.56-9.42-.8-9.39-4.88-14.3-11.41-15.87,7.63-3.22,9.59-7.68,7.37-13.07l1.2-.83c2.63.63,4.91.71,5.47-1.97l10.63,1.97,7.89-6.17,4.19-8.87c3.35-1.57,3.43-4.4,1.13-8.14,3.19,1.64,6.38,1.03,9.57-2.04,6.19-1.46,10.89-7.8,15.04-15.95,14.65-18.86,28.59-36.23,39.19-46.48,7.2-4.09,10.17-7.84,9.37-11.28,0-.58,0-1.11.03-1.6h-36.32c-.34,1.94-1.28,3.9-2.87,5.88-8.81,13.75-17.35,23.22-25.7,29.42-5.25,3.16-10.34,5.18-15.16,5.39-3.79.02-6.55,1.28-8.29,3.77-.72,2.09-2.92,3.47-6.03,4.39-4.77,1.61-6.13,4.27-4.25,7.93l1.57,1.69c-2.9.33-5.62.97-8.06,2.12.4-2.85.14-6.56-.67-10.94-3.38-16.65-3.15-33.13-.39-49.49L4.67.51c2.03,8.33,4.22,17.05,6.49,25.99-.04,14.33,2.03,24.3,9.9,22.18-2.82,1.2-4.58,3.27-3.48,7.75-.12,2.63-.84,5.27-2.14,7.9-.52,3.77.1,7.61,2.14,11.54-.43,4.87.32,9.63,2.96,14.2l-4.34,5.25c-5.91,1.98-7.5,5.45-5.85,10.03,1.64,13.59,4.76,23.29,10.79,25.36,5.12,2.61,9.76,1.76,14.24-.27-3.05,5.76-.5,11.14,9.02,16.02-2.04.81-3.67,2-4.26,4.15l-4.95-1.53c.23-1.82-8.5-.3-7.9,1.53-4.73.81-9.07-2.58-13.22-8.2-4.67-3.75-7.84-2.1-10.48,1.44-1.41,3.02-1.37,8.16,0,15.26-3.97,3.59-3.96,9.41-1.06,16.86.79,6.33,1.57,12.65,2.36,18.98,7.52,60.22,7.91,91.73,8.07,99.93.09,4.65.11,8.36.12,10.31,35.92,0,71.85,0,107.77.01Z"
        />
        <path
          id="backround1"
          class="handgelenk_links_dp_obl1361_cls5"
          d="M41.32,305.2c.16-12.2,1.61-33.19-.97-60.29.26-26.26,1.68-48.66,3.19-65.89,10.92,29.61,15.06,53.99,16.81,70.89.99,9.56.19,6.82,2,31,.73,9.71,1.41,17.56,1.98,24.3"
        />
      </g>
      <g id="Handjoint_d.p.obl" data-name="Handjoint d.p.obl">
        <g id="Overview">
          <path
            id="radius3"
            class="handgelenk_links_dp_obl1361_cls6"
            d="M120.91,305.19c-1.68-55.01.42-130.74,7.23-134.84,2.2-4.66.9-11.77-2.73-20.51,1.11-2.96.92-6.31,0-9.87-1.19-3.49-1.64-6.59-1.56-9.42-.89-10.7-5.78-14.66-12.49-15.58-4.88-.88-9.56-.02-13.6,6.31l-12.76,11.04c-5.03,4.13-12.8,5.33-20.96,6.12l-7.59,5.39c-8.02.72-14.66,2.27-16.32,6.76-2.33,3.59-3.18,7.98-2.21,13.37-.6,5.87.26,11.24,5.62,15.04,9.21,17.18,18.46,77.9,21.8,126.19"
          />
          <line
            id="radius2"
            class="handgelenk_links_dp_obl1361_cls6"
            x1="123.86"
            y1="162.75"
            x2="125.41"
            y2="149.84"
          />
          <path
            id="radius1"
            class="handgelenk_links_dp_obl1361_cls6"
            d="M49.01,158.04c4.32-1.14,8.53-2.25,11.54-3.04,13.77-2.53,24.67-5.97,30.08-11.16,3.2-3.56,6.95-6.69,11.3-9.34"
          />
          <path
            id="ulnar2"
            class="handgelenk_links_dp_obl1361_cls6"
            d="M42.35,305.19c-1.56-38.88-1.73-78.11-.75-119.3,0,0,5.78-20.26,5.43-27.39-.64-6.88-5.21-8.92-11.24-9.17-3.68-1.7-6.32-.78-8.51,1.27-6.8.97-10.56-2.76-13.22-8.2-4.14-3.88-7.41-1.89-10.48,1.44-1.26,3.91-1.57,8.71,0,15.26-3.87,3.06-3.94,8.88-1.06,16.86,5.52,42.72,10.03,85.99,10.67,129.23"
          />
          <path
            id="ulnar1"
            class="handgelenk_links_dp_obl1361_cls6"
            d="M5.26,175.51c.56-5.6.06-11.07-1.67-16.41.94-3.81,2.99-5.4,7.14-2.81,3.62.77,6.45.42,8.19-1.44,3.78-1.59,8.82-2.68,14.44-3.53"
          />
          <g id="Carpalbones">
            <path
              id="carpalbone10"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M126.47,98.8l10.63,1.97,6.23-4.56c3.42-2.12,4.71-9.57,6.23-10.94,4.13-2.92,3.38-5.94-1.94-9.07l-13.97-8.72c-5.29-3.66-10.07-5.42-14.01-4.08l-7.89,7.93c-7.09,3.42-11.15,6.77-9.42,10,2.01,3.1,5.81,5.33,10.48,7.14,4.87,3.34,8.02,7.29,8.2,12.3,1.87.66,3.7.08,5.47-1.97h0Z"
            />
            <path
              id="carpalbone9"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M65.11,75.26c-.29-6.27,1.66-8.81,5.71-7.88,1.09.25,2.2.46,3.31.44,5.98-.08,11.56-1.09,16.8-2.89,5.03-2.82,9.47-3.16,12.76,1.52,1.99,4.56,2.63,8.97,1.97,13.22.88,6.15.88,10.49.17,13.39-.78,3.21-4.16,5.03-7.31,4.05-19.2-6-32.18-12.99-33.42-21.84h.01s0-.01,0-.01Z"
            />
            <path
              id="carpalbone8"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M113.59,114.23c1.12-.49,2.11-1.01,2.98-1.56,5.36-3.41,5.3-11.29.07-14.88l-11.76-8.08c-2.92-2.01-6.21-3.44-9.68-4.16-8.68-1.79-12.36.2-12.86,4.61-.73,6.23-3.6,10.32-9.18,11.7-8.01,2.19-13.42,6.48-14.13,14.58-.07,10.55.33,19.96,2.73,24.53.83,1.57,2.42,2.58,4.19,2.75,5.21.49,11.18-.27,18.06-2.54,1.13-.37,2.19-.96,3.13-1.7l3.97-3.12c1.63-1.28,2.98-2.89,3.97-4.72,1.87-3.47,4.17-6.1,7.05-7.55,1.81-.92,3.21-2.48,4.17-4.27,1.37-2.56,2.97-4.44,5.07-4.83.77-.14,1.51-.43,2.22-.74v-.02h0Z"
            />
            <path
              id="carpalbone7"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M16.2,95.31c3.24-5.06,6.75-8.39,11.09-6.38,3.06-.32,6.83,3.57,10.94,9.42,8.06,7.5,11.32,14.36,9.27,20.51-1.92,6.67-5.32,11.37-12.11,11.57l-2.37,1.22c-2.82,1.45-6.13,1.62-9.07.43-8.23-3.32-11.46-10.19-12.61-18.54l-1.06-8.81c-1.97-3.71-.04-6.86,5.92-9.42Z"
            />
            <path
              id="carpalbone6"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M67.57,55.63c-1.43-.02-2.79-.05-3.9-.07-1.77-.03-3.5-.41-5.13-1.09-6.12-2.56-12.39-3.61-18.8-3.34-5.44,1.13-10.68,1.24-15.65,0-6.34-1.79-7.75.62-6.52,5.3-.16,2.87-.92,5.48-2.14,7.9-.71,3.24-.17,7.02,2.14,11.54v4.52c0,5.34,2.41,10.47,6.71,13.64,1.34.99,2.85,1.92,4.51,2.8,1.58,5.06,4.32,9.59,10.16,12.76,5.43,6.41,12.87,10.29,23.25,10.48,8.79-1.81,15.17-5.05,16.86-11.09,2.45-5.78.56-13.84-2.43-22.48,4.94-6.84,7.79-14.06,9.06-21.57,1.23-7.06-1.64-9.65-7.39-9.11,0,0-5.83-.1-10.75-.19h.02Z"
            />
            <path
              id="carpalbone5"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M21.22,99.42c.66-6.24,3.37-11.12,10.01-13.37,2.14-1.82,4.67-3.18,7.86-3.75,2.24-.4,4.39-1.17,6.4-2.23,3.44-1.82,6.88-1.18,10.32,1.94,3.35,3.87,4.34,8.86,2.16,15.37l-5.21,9.7c-2.76,5.15-7.8,8.78-13.6,9.48-2.52.3-5.05.2-7.59-.32-2.54-.52-5.06-1.97-6.62-4.17-1.02-1.44-1.88-3.12-2.61-4.98-.97-2.44-1.39-5.07-1.11-7.68h-.01Z"
            />
            <path
              id="carpalbone4"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M64.35,47.01c4.01.57,5.27,2.33,3.88,5.25-.79,1.66-1.49,3.36-1.91,5.15l-1.97,8.43c-1.66,2.91-3.84,2.88-6.08,2.58.11,4.76-.71,9.18-3.14,13-2.32,6.21-2.47,15.03-1.88,24.73l-2.31,3.68c-4.04.97-7.19,1.07-9.14,0-2.18-.36-3.32-3.74-3.88-8.8v-28.51c-1.92-3.85-3.1-7.38-3.31-10.48-.27-3.96,2.04-7.65,5.59-9.42l7.89-3.95c5.26-1.38,7.3-4.02,16.25-1.67h.01Z"
            />
            <path
              id="carpalbone3"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M155.19,78.34c-2.37-4.62-7.92-9.12-15.65-13.56l-12.47-5.4c-2.87-.48-5.75.03-8.65,1.45-5.86.38-8.99,3.92-8.49,11.64"
            />
            <path
              id="carpalbone2"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M82.22,91.02c1.65,6.44,2.66,12.53,1.16,17.2-2.7,8.75-7.46,15-16.6,15.95-6.69.99-13.35-.58-20.01-3.1"
            />
            <path
              id="carpalbone1"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M75.53,134.32c-.69,2.47-1.17,5-1.71,7.51-.89,4.16-4.19,6.99-11.9,7.4-10.66.66-18.56-2.06-24.4-7.29-.67-.6-1.28-1.28-1.77-2.03-3.05-4.7-1.25-9.62,3.22-14.67,1.9-1.9,4.1-3.09,6.52-3.78,2.26-.64,4.48-1.41,6.57-2.47,5.91-2.99,11.87-4.52,17.91-3.16,3.17.13,5.55,1.68,6.75,5.33.93,2.84.85,5.91.04,8.78l-1.23,4.39h0Z"
            />
          </g>
          <g id="Fingers">
            <path
              id="partoffinger18"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M223.36.1c-.09.58-.06,1.18.14,1.74,1.23,3.5-2.81,7.31-9.37,11.28-8.78,8.02-23.74,26.82-39.19,46.48-5.74,10.92-10.7,15.8-15.04,15.95-3.34,4.01-6.97,3.01-10.63,1.67-3.02-1.89-8.93-5.57-15.61-9.74-1.28-.8-2.29-1.94-3.03-3.26-1.2-2.16-3.22-4.6-5.71-7.21-1.24-1.3-1.43-3.34-.34-4.77h0c1.01-1.34,2.93-2.58,5.94-3.71,1.95-.73,3.68-2.02,4.69-3.84,1.12-2.03,3.47-3.48,8.29-3.77,16.36-1.3,30.19-16.48,42.84-38.13.44-.89.8-1.78,1.02-2.67"
            />
            <path
              id="partoffinger17"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M117.36.41c-1.13,6.19-1.84,12.46-2.04,18.75-.33,10.67.39,20.95,2.44,30.74,2.2,11.32.06,18.34-6,21.45-.63.33-1.24.71-1.82,1.13-5.15,3.66-10.16,1.59-15.05-4.95-3.78-5.17-7.91-5.36-12.15-3.95-3.84.94-6.74,1.2-9,1-3.47-.31-5.85-3.77-4.93-7.13h0c1.35-4.92,5.54-10.9,11.95-17.73,7.24-9.05,12.27-22.65,15.71-39.49"
            />
            <path
              id="partoffinger16"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M77.04.33c-.05,5.16.04,10.36.27,15.62.13,3.05,1.04,6,2.6,8.62,2.09,3.53,3.42,6.73,2.44,8.96-.41.94-.57,1.97-.25,2.94,3.09,9.57,4.78,17.22,1.24,17.67-3.19-2.02-7.09-2.19-11.32-1.52-7.56.77-14.79-.5-21.65-4.1"
            />
            <path
              id="partoffinger15"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M44.6,37.89c-4.26-.69-3.32-5.09.54-11.54,3.02-5.04,5.45-10.44,6.84-16.15.81-3.35,1.27-6.64,1.33-9.97"
            />
            <path
              id="partoffinger14"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M68.61,44.27c1.31-.24,2.58-1.46,3.79-3.95,2.06-2.46,4.13-3.66,6.23-3.65,1.59-.05,2.79-1.23,3.72-3.15"
            />
            <path
              id="partoffinger13"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M46.36.33c.93,5.62,2.33,10.48,4.29,14.43,3.97,6.34,6.37,12.61,6.41,18.78-.18,2.44.24,4.35,1.67,5.37-2.73,5.99-6.09,9.85-10.63,9.78l-23.7-3.34h0c-4.03.64-4.83-2.74-3.34-8.97C25.82,26.39,29.41,15.51,28.2.99"
            />
            <path
              id="partoffinger12"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M5.34,1.46c2.26,8.71,4.37,17,5.84,24.14-.5,15.55,1.93,25.04,9.87,23.09,8.24,2.68,14.88,3.81,16.41,0h0c6.62-2.02,9.67-5.54,8.2-10.95l-14.43-24.15c-2.09-4.49-3.92-8.91-5.5-13.26"
            />
            <path
              id="partoffinger11"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M193.55.76c-1.41,3.21-3.24,6.13-5.56,8.72-5.4,8.57-11.73,16.59-18.99,24.04-5.89,6.57-12.16,9-18.68,8.61"
            />
            <path
              id="partoffinger10"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M169.92,60.37c10.17-20.98,23.32-37.72,39.8-49.67"
            />
            <path
              id="partoffinger9"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M86.68,37.13c7-5.26,12.76-15.6,16.41-34.63l.26-2.17"
            />
            <path
              id="partoffinger8"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M111.9,41.39c-2.04-12.82-2.5-26.38-.64-41.06"
            />
            <path
              id="partoffinger7"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M73.92,29.84c-.1-5.69-2.11-14.17-5.15-24.15-.09-1.76-.14-3.54-.15-5.36"
            />
            <path
              id="partoffinger6"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M59.5.33c-.15.84-.31,1.67-.48,2.47-1.13,7.89-4.83,16.1-10.32,24.54"
            />
            <path
              id="partoffinger5"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M42,.33c1.16,4.28,2.66,8.35,4.54,12.2"
            />
            <path
              id="partoffinger4"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M33.06,10.7l-.72-10.37"
            />
            <path
              id="partoffinger3"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M16.2,40.02c-1.2-3.52-1.8-7.12-1.71-10.81.11-4.63-.22-9.26-1.31-13.76L9.53.33"
            />
            <path
              id="partoffinger2"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M19.58.33c1.52,4.24,3.31,8.32,5.43,12.19,2.97,5.84,7.06,10.26,11.7,13.97"
            />
            <path
              id="partoffinger1"
              class="handgelenk_links_dp_obl1361_cls6"
              d="M132.53,57.18c2.68,2.11,4.54,4.98,5.03,8.96l2.28,6.23c1.71,3.64,4.32,4.86,7.78,3.84"
            />
          </g>
        </g>
        <g id="Highlights">
          <path
            id="Radiushighlight"
            class="Radius1361Fill"
            style={{
              fill: props.colors.Radius1361Fill,
              stroke: props.colors.Radius1361Fill,
            }}
            d="M120.85,305.2c-.24-13.98-.54-38.29-.18-63.1.02-1.32.21-13.39,1.01-29.12,1.11-21.69,1.55-14.33,1.82-22.85,0,0,.1-3.22,2.02-13.85.31-1.7.6-2.94,1.42-4.25.52-.83.94-1.2,1.42-2.22.41-.87.67-1.81.81-3.13.5-4.6-.81-8.9-.81-8.9-1-3.3-1.51-4.95-2.22-5.97-.25-.35-.87-1.17-.81-2.22.05-.81.48-1.22.81-1.92.28-.6.53-1.49.2-3.84-.53-3.85-1.74-5.39-2.33-8.7-.44-2.48.04-2.7-.2-5.26-.17-1.81-.66-7.07-4.15-10.82-3.94-4.25-10.99-5.78-15.88-3.54-1.81.83-1.7,1.43-6.02,5.79-3.67,3.69-6.18,5.65-11.07,9.69-2.68,2.22-3.57,2.86-4.85,3.54-2.78,1.47-5.42,2.03-7.79,2.53-1.65.35-3.39.72-5.76.91-2.34.19-2.94.01-4.25.5-1.38.51-2.31,1.28-4.04,2.74-1.77,1.48-2.2,2.19-3.55,2.65-1.17.4-2.15.31-2.62.28-2.36-.14-8.1.37-11.73,3.54-3.13,2.73-4.26,7.06-4.35,9.61-.03.96,0,3.82,0,3.94.02,1.49.05,2.28.1,4.45.1,4.04.07,4.21.1,4.65.14,2.05,1.22,5,5.59,8.72.33.66.83,1.67,1.39,2.91,0,0,2.01,4.49,3.44,9.51,5.21,18.35,8.49,40.35,8.49,40.35,2.18,14.63,3.27,21.94,4.55,32.66,1.8,15.08,3.04,28.76,3.93,40.74,18.5,0,37.01,0,55.51.01Z"
          />
          <path
            id="Ulnahighlight"
            class="Ulna1361Fill"
            style={{
              fill: props.colors.Ulna1361Fill,
              stroke: props.colors.Ulna1361Fill,
            }}
            d="M42.35,305.19c-.69-21.76-1.09-44.04-1.16-66.83-.06-17.8.09-35.3.41-52.47.58-1.69,7.4-21.87,5.05-29.74-.68-2.26-2.02-3.74-2.02-3.74-2.9-3.18-7.42-3.28-9.1-3.24-1.09-.58-2.78-1.26-4.55-.81-1.74.44-2.19,1.6-3.69,2.25-2.81,1.22-6.2-.73-7.44-1.44-5.49-3.16-5.22-7.95-8.7-8.8-1.57-.38-4.02.01-7.79,3.74-1.53,8.05-1.13,11.34-.3,12.74.16.28.79,1.24.53,2.26-.26.98-1.16,1.24-1.94,2.09-.97,1.05-1.11,2.3-1.31,4.04-.52,4.51,1.8,9.83,2.2,10.72,2.84,20.6,5.28,42.35,7.13,65.18,1.81,22.31,2.89,43.69,3.42,64.04,9.76,0,19.51,0,29.27,0Z"
          />
          <path
            id="Carpalbonehighlight"
            class="HWK1361Fill"
            style={{
              fill: props.colors.HWK1361Fill,
              stroke: props.colors.HWK1361Fill,
            }}
            d="M11.24,113.67l1.14,5.79,1.61,4.22,2.68,4.29,3.81,3.25,3.28,1.55,3.69.86h2.27s4.85-2.03,4.85-2.03l.73-.38-1.08,3.38.15,2.75.93,2.43,1.24,1.95,3.79,3.1,6.42,3.34,3.78,1.07,6.78.84,6.69-.22,4.04-.95,2.78-1.39,2.59-3.35,7.33-1.25,4.94-1.74,6.1-4.73,2.13-2.32,2.46-3.98,2.79-3.32,2.24-1.61,2.29-1.37,2.15-2.34,1.83-3.16,1.89-2.08,2.43-.69,4.54-2.16,2.03-1.8,1.78-3.42v-4.77s1.83-1.65,1.83-1.65l1.39-.17,1.88-1.06.79-.77h1.24s9.42,1.71,9.42,1.71l7.4-5.35,1.38-1.88,3.45-7.77,2.56-2.63.67-1.81-1.49-3.1,3.59.14h.71s-1.5-2.37-1.5-2.37l-4.18-4.36-7.36-5.27-3.29-1.85-10.89-4.72-1.59-.49-2.53-.03-3.17.65-2.27.93-3.26.65-2.69,1.38-1.67,2.76-.92,3.53v1.89s.04,1.21.04,1.21l-3.57,1.87-.55.09-.95-4.65-1.16-3.11-2.25-2.33-2.49-1.26h-2.98s-3.52,1.29-3.52,1.29l-.59.29-2.76-1.48-2.42-.12-.71.04v-2.52s-.84-2.09-.84-2.09l-1.65-1.66-1.88-.68c-1.87-1.04-4.73-2.67-8.17-4.78-6.91-4.23-6.92-4.72-8.94-5.27-5.07-1.4-11.17-.04-15.17,2.02-1.28.66-3.13,1.79-6.07,2.43-2.07.45-3.85.46-5.06.4l-3.62,1.17-3.85.12-4.04-.35-2.76-.57-2.62-.55h-1.74s-1.62.6-1.62.6l-.63,1.37-.15,1.63.3,1.9.06,1.13-.71,3.66-1.39,3.5-.29,3.03.5,3.54,1.83,4.78.18,6.46.97,4.28,1.91,3.65-2.05,2.06-2.23,3.12-4.68,2.64-2.04,3.16.74,3.68c.34,2.73.67,5.45,1.01,8.18Z"
          />
          <path
            id="Distalulnahighlight"
            class="Handgelenk1361Fill"
            style={{
              fill: props.colors.Handgelenk1361Fill,
              stroke: props.colors.Handgelenk1361Fill,
            }}
            d="M1.14,126.11c.39-.66,1.39-1.67,5.46-1.82,8.08-.3,13.16,3.33,25.08,9.3,11.58,5.8,15.27,6.63,19.08,6.63,1.67,0,3.1-.16,9.8-1.59,11.64-2.49,17.46-3.74,19.66-4.63,14.83-6.04,14.41-17.35,25.89-19.42,3.12-.56,7.39-.49,12.34-3.44,2.73-1.63,4.43-3.44,5.46-2.83,1.69,1-.47,7.28-.61,7.69-.92,2.62-2.13,6.09-5.26,8.09-3.67,2.35-5.62-.1-10.72,1.62-4.05,1.36-4.56,3.49-10.52,8.09-3.33,2.57-5.9,4.01-9.91,6.27-12.65,7.11-27.32,9.62-31.55,10.31-18.76,3.08-31.04.48-34.58-1.62-2.4-1.42-5.06-3.24-5.06-3.24s-2.52-1.73-4.45-3.24c-4.51-3.53-12.28-12.47-10.11-16.18Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default LinkesHandgelenkDpOblSitzend1361;
