import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useStudies } from "../../../Utilities/FetchHooks/Studies/StudiesHooks";
import { ClipLoader } from "react-spinners";
import { getHHMM_DDMMYYY } from "../../../Utilities/DateTimeUtils/DateTimeUtils";
import ExamDoseCol from "./ExamDoseCol";

import "./res/ExportStatsModal.scss";
import { useMemo } from "react";
import JsPDF from "jspdf";
import { CSVLink } from "react-csv";
import { useEffect } from "react";

const getSerieDoseInfo = (serie, modality = undefined) => {
  if (modality === "CT")
    return {
      aMas: undefined,
      aKv: undefined,
      sDose: undefined,
    };

  let items = 0,
    masSum = 0,
    kvSum = 0,
    doseSum = 0;

  serie.image_collection.forEach((image) => {
    if (image.mas_kv_collection.length > 0) {
      //we want to skip images that doesnt have dose data since they can be some kind of report or finding
      if (
        image.mas_kv_collection[0].mas !== 0 ||
        image.mas_kv_collection[0].kv !== 0 ||
        image.mas_kv_collection[0].dosis !== 0
      ) {
        items += 1;
        if (image.mas_kv_collection[0].mas >= 0) {
          masSum += image.mas_kv_collection[0].mas;
        }
        if (image.mas_kv_collection[0].kv >= 0) {
          kvSum += image.mas_kv_collection[0].kv;
        }
        if (image.mas_kv_collection[0].dosis >= 0) {
          doseSum += image.mas_kv_collection[0].dosis;
        }
      }
    }
  });
  return {
    aMas: Math.round((masSum / items) * 100) / 100,
    aKv: Math.round((kvSum / items) * 100) / 100,
    sDose: doseSum,
  };
};

const getStudyDoseInfo = (study) => {
  let studyMas = 0,
    studyKv = 0,
    studyDose = 0,
    items = 0;

  study?.serie_collection?.forEach((serie) => {
    let serieDose = getSerieDoseInfo(serie);
    //skipping series that doesnt contain dose info
    if (Object.values(serieDose).findIndex((value) => value > 0) > -1) {
      items += 1;
      studyMas += serieDose.aMas;
      studyKv += serieDose.aKv;
      studyDose += serieDose.sDose;
    }
  });
  return {
    aMas: Math.round((studyMas / items) * 100) / 100,
    aKv: Math.round((studyKv / items) * 100) / 100,
    sDose: studyDose,
  };
};

function SerieRow({ serie }) {
  const selectedModality = useSelector(
    (state) => state.xmStats.selectedModality
  );
  const { aMas, aKv, sDose } = useMemo(
    () => getSerieDoseInfo(serie, selectedModality),
    [serie, selectedModality]
  );

  return (
    <div className="serie-export-row">
      <div>{serie.seriesdescription}</div>
      <div>[ {serie.imgcount} ]</div>
      {selectedModality !== "CT" && (
        <>
          <div>AVG: {aMas} mAs</div>
          <div>AVG: {aKv} kV</div>
          <div>SUM: {sDose} uGy</div>
        </>
      )}
    </div>
  );
}

function ImageRow({ image }) {
  return (
    <div className="image-export-row">
      <div>{getHHMM_DDMMYYY(image?.mas_kv_collection[0]?.zeitstempel)}</div>
      <div>{image?.imgdetails_collection[0]?.imagedescription}</div>
      <div>{image?.mas_kv_collection[0]?.mas} mAs</div>
      <div>{image?.mas_kv_collection[0]?.kV} kV</div>
      <div>{image?.mas_kv_collection[0]?.dosis} uGy</div>
    </div>
  );
}

function ExportBody({ studies, selectedModality }) {
  return (
    <div className="d-flex flex-column">
      <div className="py-2">
        <h4>Examination Statisics report for {selectedModality}</h4>
      </div>
      {selectedModality !== "CT" ? (
        studies?.data?.studies?.map((study) => (
          <div
            className="mb-3 border rounded p-2 bg-light"
            key={`studiesExport${study.id}`}
          >
            <div className="exam-export-row">
              <div>{getHHMM_DDMMYYY(study.studydate)}</div>
              <div>{study.modality}</div>
              <div>{study.studydescription}</div>
              <div>{study?.patientsname?.replace("^", " ")}</div>
              <ExamDoseCol studyId={study.id} />
            </div>
            {study?.serie_collection.map((serie) => (
              <>
                <SerieRow key={`serieExport${serie.id}`} serie={serie} />
                {selectedModality !== "CT" &&
                  serie?.image_collection.map((image) => (
                    <ImageRow
                      key={`imageExport${image.image_id}`}
                      image={image}
                    />
                  ))}
              </>
            ))}
          </div>
        ))
      ) : (
        <>
          <div className="mb-3 border rounded p-2 bg-light">
            <div className="exam-export-row-ct header">
              <div>Study Date</div>
              <div>Patient Id</div>
              <div>Patient</div>
              <div>Bio Sex</div>
              <div>Birth Date</div>
              <div>Exam Description</div>
              <div>CTDI</div>
              <div>AVG CTDI</div>
              <div>Patient Height</div>
              <div>Patient Weight</div>
              <div>BMI</div>
            </div>
          </div>
          {studies?.data?.studies?.map((study) => (
            <div
              className="mb-3 border rounded p-2 bg-light"
              key={`studiesExport${study.id}`}
            >
              <div className="exam-export-row-ct">
                <div>{getHHMM_DDMMYYY(study.studydate)}</div>
                <div>{study?.patient?.patientid}</div>
                <div>
                  {study.patient.patientsvorname} {study.patient.patientsname}
                </div>
                <div>{study.patient.patientssex}</div>
                <div>{study.patient.gebdat}</div>
                <div>{study.studydescription}</div>
                <div>{study.myg}</div>
                <div>{study.avgmyg}</div>
                <div>{study.patientssize}</div>
                <div>{study.patientsweight}</div>
                <div>
                  {study.patientssize && study.patientsweight
                    ? Math.round(
                        ((study.patientsweight /
                          Math.pow(study.patientssize / 100, 2)) *
                          100) /
                          100
                      )
                    : "No data"}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default function ExportStatsModal({ shown, onHide }) {
  const filter = useSelector((state) => state.xmStats.filterObj);
  const selectedModality = useSelector(
    (state) => state.xmStats.selectedModality
  );
  const studies = useStudies({
    filter,
    xmask:
      selectedModality !== "CT"
        ? "studies{*}"
        : "studies{serie_collection{bodypartexamined, imgcount, seriesdatetime, seriesdescription}, id,patient, studydate, modality, studydescription, patientsname, myg, avgmyg, patientssize, patientsweight}",
  });

  const csvData = useMemo(() => {
    if (
      !studies?.data?.studies ||
      studies.status !== "success" ||
      studies?.data?.studies?.length <= 0
    ) {
      return [];
    }
    const dxHeader = [
      "Study Date",
      "Patient Id",
      "Patient",
      "Bio Gender",
      "Birth Date",
      "Study Description",
      "uGy",
      "mAs",
      "kV",
    ];
    const ctHeader = [
      "Study Date",
      "Patient Id",
      "Patient",
      "Bio Gender",
      "Birth Date",
      "Study Description",
      "Avg. CDTI",
      "Sum CDTI",
      "Patient Height",
      "Patient Weight",
      "BMI",
    ];

    let rows = [];

    if (selectedModality === "CT") {
      rows.push(ctHeader);
    } else {
      rows.push(dxHeader);
    }

    studies.data.studies.forEach((study) => {
      if (selectedModality !== "CT") {
        let studyDoseInfo = getStudyDoseInfo(study);
        rows.push([
          getHHMM_DDMMYYY(study.studydate),
          study.patient.patientid,
          `${study.patient.patientsvorname} ${study.patient.patientsname}`,
          study.patient.patientssex ? study.patient.patientssex : "O",
          study.patient.gebdat ? study.patient.gebdat : "Unknown",
          study.studydescription ? study.studydescription : "No description",
          studyDoseInfo.sDose ? studyDoseInfo.sDose : "",
          studyDoseInfo.aMas ? studyDoseInfo.aMas : "",
          studyDoseInfo.aKv ? studyDoseInfo.aKv : "",
        ]);
      } else {
        rows.push([
          getHHMM_DDMMYYY(study.studydate),
          study.patient.patientid,
          `${study.patient.patientsvorname} ${study.patient.patientsname}`,
          study.patient.patientssex ? study.patient.patientssex : "O",
          study.patient.gebdat ? study.patient.gebdat : "Unknown",
          study.studydescription ? study.studydescription : "No description",
          study.myg ? study.myg : "No cdti",
          study.avgmyg ? study.avgmyg : "No cdti",
          study.patientssize
            ? study.patientssize
            : "No patient height provided",
          study.patientsweight
            ? study.patientsweight
            : "No patient weight provided",
          study.patientssize && study.patientsweight
            ? Math.round(
                ((study.patientsweight /
                  Math.pow(study.patientssize / 100, 2)) *
                  100) /
                  100
              )
            : "No data",
        ]);
      }
    });

    return rows;
  }, [studies, selectedModality]);

  const generatePDF = () => {
    const report = new JsPDF("p", "px", [
      selectedModality === "CT" ? 1120 : 1080,
      1520,
    ]);
    const source = window.document.getElementById("exportBody").innerHTML;

    report.html(source, {
      x: 0,
      y: 0,
      width: 1080,
      windowWidth: 1080,
      margin: [60, 30, 60, 30],
      autoPaging: "text",
      callback: (pdf) => {
        pdf.save(
          `${selectedModality} stats report from ${getHHMM_DDMMYYY()}.pdf`
        );
      },
    });
  };

  useEffect(() => {
    studies.refetch();
  }, [shown]);

  return (
    <Modal show={shown} onHide={onHide} size="xl">
      <Modal.Header closeButton className="p-2">
        {studies.status === "loading" ? (
          <div className="d-flex">
            <ClipLoader size="25px" />
            <h5>Loading statistics please wait ...</h5>
          </div>
        ) : (
          <h5>Download Report</h5>
        )}
      </Modal.Header>
      <Modal.Body
        id="exportBody"
        style={{
          height: "80vh",
          overflowY: "auto",
        }}
      >
        <div className="p-2 d-flex justify-content-center">
          <ExportBody selectedModality={selectedModality} studies={studies} />
        </div>
      </Modal.Body>
      <Modal.Footer className="px-2 py-1 d-flex">
        <Button size="sm" variant="danger" onClick={onHide}>
          <i className="fas fa-times fa-sm mr-2" />
          Reject
        </Button>
        <CSVLink
          data={csvData}
          filename={`${selectedModality} stats report from ${getHHMM_DDMMYYY()}.csv`}
        >
          <Button
            size="sm"
            disabled={csvData.length === 0 || studies.isRefetching}
          >
            {studies.isRefetching ? (
              <div>
                <ClipLoader size="10" />
                Loading..
              </div>
            ) : (
              <div>
                <i className="far fa-file-excel fa-sm mr-2" />
                CSV
              </div>
            )}
          </Button>
        </CSVLink>
        <Button
          size="sm"
          onClick={generatePDF}
          disabled={csvData.length === 0 || studies.isRefetching}
        >
          {studies.isRefetching ? (
            <div>
              <ClipLoader size="10" />
              Loading..
            </div>
          ) : (
            <div>
              <i className="far fa-file-pdf fa-sm mr-2" />
              PDF
            </div>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
