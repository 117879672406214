import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";
const FootLeft = map.FootLeft;

function TopView({ bpartSetter, selected }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 446.5 797">
      <g id="foot">
        <g id="outline">
          <path
            id="outline_1"
            data-name="outline 1"
            class="lfoot-cls-1"
            d="M359.18,46.25c-25.52-23.77-48.35-23.89-68.25,1.87-5.82,34.48,.34,63.93,12.75,90.75,0,13.8-3.19,17.03-12.38,.37-.13-10.35-2.13-20.83-6.75-31.5-5.98-13.52-7.85-34.26-9-56.25-13.65-38.11-37.22-34.32-47.62-10.13-2.25,9.74-2.44,19.34-1.5,28.87l-2.25-8.25c-9.88-10.49-21.21-15.45-38.25,1.5-4.43,9.46-5.7,23.45-5.63,39.37l-1.87-6c-10.9-4.1-21.93-2.48-33.38,18.37-1.81,17.11-1.39,34.51,.75,52.13l-1.87-10.5c-2.96-8.66-9.96-7.87-21.75,4.12-16.66,23.74-18.05,54.82-10.13,90.37-12.31,50.7-9.1,109.51,7.87,175.5,6.9,24.83,9.03,47.95,7.5,69.75,2.91,10.13,8.49,23.1,8.25,21.75-1.32,1.89,8.13,34.39,8.62,32.63,2.29,37.85,3.16,86.03,7.5,134.63,.22,50.88,14.52,75.15,36.37,85.13,27.46,21.61,53.02,16.78,78.75,14.25,29.68-14.01,43.04-47.45,58.5-78.37,11.69-21.79,19.06-47.93,24.38-76.12l21.38-94.5c4.54-14.2,6.5-33.68,7.5-55.12l1.13-82.12c-1.79-24.94-.85-51.56,2.25-79.5,11.49-43.93,15.54-81.29,10.5-110.62-4.86-22.7-5.41-46.54-2.62-71.25,2.33-20.47-3.66-35.29-12.75-48l-18-43.13Z"
          />
        </g>
        <g id="toes">
          <g id="big_toe" data-name="big  toe">
            <path
              id="big_toe_5"
              data-name="big toe  5"
              class="lfoot-cls-1"
              d="M362.18,94.63c-4.03-8.66-7.34-9.27-10.12-4.13-7.46-8.39-15.02-16.7-13.5-31.5,2.6-2.78,2.72-5.72-2.62-9-5.88-6.36-12.33-10.05-20.25-6.75-6.16-.53-11.83-.52-9.75,8.25-6.08,2.94-6.9,6.9-3.75,11.62,10.72,5.27,17.13,12.77,9,27.75-2.46,7.03-1.19,12.07,2.62,15.75l20.62,2.25,25.5-1.12c4.61-.99,5.56-5.2,2.25-13.12Z"
            />
            <path
              id="big_toe_4"
              data-name="big toe  4"
              class="lfoot-cls-1"
              d="M371.93,195.5c6.79,16.52,1.54,23.1-14.25,21l-28.87-6c-13.5,6.31-15.85-1.91-10.12-20.63,6.88-19.49,12.53-39.22-.37-62.63-8.44-14.86-4.27-20.7,9.75-19.5l21.37,1.5c13.35-4.88,18.18,2.1,18.75,15-7.48,25.73-4.06,48.99,3.75,71.25Z"
            />
            <path
              id="big_toe_3"
              data-name="big toe  3"
              class="lfoot-cls-1"
              d="M326.93,183.13c13.75-14.4,8.34-34.91,.38-56.25"
            />
            <path
              id="big_toe_3-2"
              data-name="big toe  3"
              class="lfoot-cls-1"
              d="M353.93,89.38c1.75,6.5-1,11-8.25,13.5-13.36,1.25-25.91,1.8-32.25-3"
            />
            <line
              id="big_toe_2"
              data-name="big toe  2"
              class="lfoot-cls-1"
              x1="315.68"
              y1="43.25"
              x2="316.8"
              y2="50.38"
            />
            <path
              id="big_toe_1"
              data-name="big toe 1"
              class="lfoot-cls-1"
              d="M326.55,43.25c1.21,3.49,.73,6.23-2.25,7.87-3.24,1.74-5.66,1.54-6.37-2.63l-.75-5.25"
            />
          </g>
          <g id="second_toe" data-name="second toe">
            <path
              id="second_toe_6"
              data-name="second toe 6"
              class="lfoot-cls-1"
              d="M258.68,39.88c-7.57-8.72-13.21-8.85-16.5,1.5-12.06,7.1-9.81,9.99-.75,10.87,2.25,3.58,2.21,7.09-1.13,10.5-4.11,3.85-3.33,7.2,1.5,10.13h22.5c4.46-3.14,7.09-6.22,.75-9-4.76-2.63-6.15-6.01-2.62-10.5,4.35-3.79,2.73-8.33-3.75-13.5Z"
            />
            <path
              id="second_toe_5"
              data-name="second toe 5"
              class="lfoot-cls-1"
              d="M269.55,86.75c.54-8.58-.05-15.27-8.25-9.38-3.67-1.58-6.61-1.26-12-.75-13.12-3.39-12.33,2.09-2.25,13.5,3.16,8.88,4.02,16.21,2.25,21.75-5.52,7.74-3.14,10.29,3.37,10.13l15.75-2.62c7.67,1.7,11.87-.17,6.38-12-5.02-4.48-7.51-10.81-5.25-20.63Z"
            />
            <path
              id="second_toe_4"
              data-name="second toe 4"
              class="lfoot-cls-1"
              d="M277.05,136.63c1.41-10.43-1.31-16.6-11.25-15.38-2.5,2.5-5,2.5-7.5,0-10.87,1.2-16.14,5.76-10.5,16.87,10.65,24.08,9,40.41,3,54-5.8,14.15-3.53,16.87,4.87,10.88,7.74-10.2,15.08-10.75,22.13-3.75,15.09,5.72,16.15,1.34,5.63-11.25-6.62-9.4-10.03-26.18-6.37-51.38Z"
            />
            <path
              id="second_toe_3"
              data-name="second toe 3"
              class="lfoot-cls-1"
              d="M267.68,130.25c-5.45,21.16-8.59,41.24-3.38,57.37"
            />
            <path
              id="second_toe_2"
              data-name="second toe 2"
              class="lfoot-cls-1"
              d="M258.68,110c4.21-8.62-4.24-17.27-9-27"
            />
            <path
              id="second_toe_1"
              data-name="second toe 1"
              class="lfoot-cls-1"
              d="M248.55,66.12c2.89-7.25-.56-15.76-6.37-24.75"
            />
          </g>
          <g id="third_toe" data-name="third toe">
            <path
              id="third_toe_4"
              data-name="third toe 4"
              class="lfoot-cls-1"
              d="M209.55,81.87c5.81-4.17,7.38-7.77-.37-10.13-4.65-3.77-9.21-4.83-13.5,1.12-5.47,3.31-7,6.76-1.13,10.5,1.58,4.17,1.56,7.36-.38,9.37-7.56,8.55-6.65,12.16,.75,12,5.29-1.58,10.69-1.38,16.13-.75,6.91-5.13,6.77-9.31,1.13-12.75-1.58-1.79-2.48-4.86-2.62-9.38Z"
            />
            <path
              id="third_toe_3"
              data-name="third toe 3"
              class="lfoot-cls-1"
              d="M218.93,113c.82-6.21,.21-11.09-7.87-9-4.3-.64-8.33-.98-9,2.25-9.95-5.6-9.11,1.55-6.75,10.5,4.8,7.12,5.08,10.48,4.5,13.13-5.62,5.74-3.71,8.05,4.5,7.5,2.35-2.81,5.95-2.19,10.12,0,5.68-1.32,8.05-5.53,5.25-14.25-1.22-2.01-1.51-5.33-.75-10.13Z"
            />
            <path
              id="third_toe_2"
              data-name="third toe 2"
              class="lfoot-cls-1"
              d="M221.18,147.5c3.01-7.96-.02-11.9-10.5-10.88l-4.13,.75c-11.86-2.07-15.59,1.97-9.38,13.5,13.3,21.55,13.92,38.55,11.25,54.38-8.38,15.13-6.24,19.49,5.25,14.25,4.23-7.81,9.09-10.36,15-4.12,7.77,13.43,12.64,2.28,6.75-11.62-10.62-10.52-14.38-30.66-14.25-56.25Z"
            />
            <path
              id="third_toe_1"
              data-name="third toe 1"
              class="lfoot-cls-1"
              d="M211.43,162.87c-.15,16.25,.91,29.89,3.37,40.5"
            />
          </g>
          <g id="fourth_toe" data-name="fourth toe">
            <path
              id="fourth_toe_4"
              data-name="fourth toe 4"
              class="lfoot-cls-1"
              d="M171.3,116.37c-4.05-7.91-8.7-7.86-13.87-.75-7.27,5.53-6.97,9.02,.75,10.5,1.37,4.34,1.43,7.82,.75,10.88-9.81,.61-7.13,3.92-1.5,7.87,5.05-2.36,10.5-2.41,16.13-1.5,3.59-2.27,3.41-5.69,0-10.12-2.76-3.44-2.24-6.65,0-9.75,4.71-1.14,2.62-3.82-2.25-7.13Z"
            />
            <path
              id="fourth_toe_3"
              data-name="fourth toe 3"
              class="lfoot-cls-1"
              d="M172.8,145.25c-4.42-2.57-10.27-1.65-16.88,1.12-3,2.5-3,5,0,7.5,.82,3.65,1.03,6.72,.37,9-2.74,4.99-1.51,7.54,1.5,9,7.38-2.13,12.16-3.11,15.38-3.37,6.13-1.09,5.56-4.78,2.63-9.37l-1.5-4.88c2.13-4.21,2.73-7.72-1.5-9Z"
            />
            <path
              id="fourth_toe_2"
              data-name="fourth toe 2"
              class="lfoot-cls-1"
              d="M177.3,183.87c2.77-9.19,2.39-15.11-6-12.75-3.81,1.1-7.4,.29-9,1.87-8.27,1.44-11.08,6.78-6.75,17.25,14.31,14.47,10.87,30.75,9,46.88-3.88,17.1-1.33,19.79,5.25,13.5,4.16-5.08,9.02-5.33,14.25-3,8.18,5.89,8.28,.82,6-7.5-7.03-17.47-11.65-36.05-12.75-56.25Z"
            />
            <path
              id="fourth_toe_1"
              data-name="fourth toe 1"
              class="lfoot-cls-1"
              d="M172.05,231.5c1.77-10.88-1.18-24.18-5.62-38.25"
            />
          </g>
          <g id="little_toe" data-name="little toe">
            <path
              id="little_toe_4"
              data-name="little toe 4"
              class="lfoot-cls-1"
              d="M137.55,180.5c3.04-3.39,2.09-7.52-.75-12-5.64-6.63-10.01-7.18-12.38,1.87-5.4,6.42-5.6,10.87,0,13.12-4.15,11.55-3.74,16.22-.38,15.75l14.63-1.12c5.96-2.93,4.92-6.41,.75-10.12-4.15-2.4-3.69-4.93-1.87-7.5Z"
            />
            <path
              id="little_toe_3"
              data-name="little toe 3"
              class="lfoot-cls-1"
              d="M141.3,201.87c-4.96-3.52-11.14-4.06-18.75-1.13,1.16,6.62,1.5,11.16,1.12,13.88-7.2,4.19-5.8,8.64-3,13.12,7.22,1.32,15.32,.71,23.63-.37,3.57-3.37,5.45-6.68,0-9.75-2.29-4.65-3.46-9.82-3-15.75Z"
            />
            <path
              id="little_toe_2"
              data-name="little toe 2"
              class="lfoot-cls-1"
              d="M145.8,237.5c.55-8.06-1.23-11.03-6-7.5l-10.88,1.12c-5.94-3.21-8.49-1.12-6.38,8.25,8.7,14.04,12,27.8,9,41.25-1.29,10.44,1.14,13.17,5.62,11.63,1.38-5.17,6.01-6.49,13.13-4.87,7.78,3.22,9.64,.93,4.13-8.25-5.89-10.34-8.27-24.8-8.62-41.63Z"
            />
            <path
              id="little_toe_1"
              data-name="little toe 1"
              class="lfoot-cls-1"
              d="M138.3,278.75c1.81-14.18,1.05-27.36-5.62-38.25"
            />
          </g>
        </g>
        <g id="metatarsus">
          <path
            id="metatarsus_16"
            data-name="metatarsus 16"
            class="lfoot-cls-1"
            d="M376.43,254.75c5.12-20.43,5.98-36.49-10.12-35.25-11.22-1.35-26.39-11.81-48.37-3.75-4.41,.14-7.71,3.88-10.13,10.5-17.45,4.49-18.16,13.28-1.13,26.62,14.6,44.23,8.67,79.67-16.5,106.87-12.61,4.31-13.85,13.49,1.12,29.63,19.57-6.13,31.87,.61,43.87,7.88,16.72,9.26,20.91,.65,19.13-16.5-1-43.18,7.25-85.06,22.13-126Z"
          />
          <path
            id="metatarsus_15"
            data-name="metatarsus 15"
            class="lfoot-cls-1"
            d="M284.18,207.5c-10.95-11.03-21.83-11.58-32.62-1.13-4.1,3.66-4.98,8.19-3,13.5-7.35,7.35-5.1,17.1,2.25,28.13,8.71,40.65,1.91,88.57-4.13,136.12-6.39,4.82-9.56,11.57-6.38,22.12-3.19,15.49,2.28,23.79,11.62,28.88,4.57-15.3,17.8-18.31,33.38-18-1.82-10.34,.84-18.3,5.63-25.12-1.89-3.49-7.42-9.63-10.87-18.75-4.74-21.83-3.91-70.91-3.37-118.5,13.78-12.79,13.32-21.31,10.88-29.25-2-6.28-2.85-12.19-3.37-18Z"
          />
          <path
            id="metatarsus_14"
            data-name="metatarsus 14"
            class="lfoot-cls-1"
            d="M232.43,222.87c-5.88-9.7-11.61-14.41-16.87-3.38-8.41,4.84-14.68,12.18-11.25,30.75,7.6,9.97,10.57,22.36,9.75,36.75,1.65,24.82-2.76,63.01-7.88,102.75-9.23,26.62-4.59,38.11,5.62,43.5,3.41-18.7,13.82-17.55,25.13-13.87,2.99-8.7,1.37-20.85-2.25-34.5-4.15-21.28-2.28-77.8-1.12-130.13,7.33-2.85,5.95-14.49-1.12-31.88Z"
          />
          <path
            id="metatarsus_13"
            data-name="metatarsus 13"
            class="lfoot-cls-1"
            d="M194.18,272.37c3.8-7.39-.07-14.91-8.25-22.5-7.59-5.33-13.78-3.24-18.75,5.25l-2.25,16.5c-1.66,9.5,2.21,15.95,8.62,21,9.2,12.9,8.4,40.97,7.12,69.75l-6.38,58.12c-3.65,8.65-5.69,17.6-1.13,27.75,1.3,9.15,3.97,14.7,7.5,18,4.4-13.41,15.26-20.15,30.38-22.5-8.56-18.07-11.56-39.14-10.12-62.63,2.76-36.26-.13-72.51-6.75-108.75Z"
          />
          <path
            id="metatarsus_12"
            data-name="metatarsus 12"
            class="lfoot-cls-1"
            d="M157.8,304.25c.07-18.06-12.09-20.35-19.87-10.13-6.97-1.34-6.57,4.08-2.63,12.75-6.05,5.82-9.49,12.11,3.75,21.38,9.27,16.78,11.63,36.31,10.12,57.38l-5.63,70.87c-8.61,28.49-1.57,43.51,14.62,50.63,5.53,8.71,9.63,9.22,11.63-2.25-1.38-9.84,3.46-22.83,10.13-36.75-6.5-13.04-10.92-25.86-7.87-37.88l-.75-50.25-9.37-58.5c3.92-4.94,2.36-10.72-4.12-17.25Z"
          />
          <path
            id="metatarsus_11"
            data-name="metatarsus 11"
            class="lfoot-cls-1"
            d="M370.43,221c4.67,1.08,4.67,2.33,0,3.75-15.17,6.51-31.25,4.47-48-3.75-2.5-.58-4-2.33-4.5-5.25"
          />
          <path
            id="metatarsus_10"
            data-name="metatarsus 10"
            class="lfoot-cls-1"
            d="M282.3,208.63c-7.27,5.34-14.72,9.29-23.25,5.25l-9.75-4.88"
          />
          <path
            id="metatarsus_9"
            data-name="metatarsus 9"
            class="lfoot-cls-1"
            d="M233.55,230.37c-4.1-1.36-7.07,1.11-9,7.12-6.16,2.17-9.8,.71-11.62-3.38-4.13-.76-6.41-2.78-6.37-6.38"
          />
          <path
            id="metatarsus_8"
            data-name="metatarsus 8"
            class="lfoot-cls-1"
            d="M191.55,257c-4.65-.25-8.19,2.02-10.13,7.87-5.54,3.39-10.3,2.69-14.62-.38"
          />
          <path
            id="metatarsus_7"
            data-name="metatarsus 7"
            class="lfoot-cls-1"
            d="M154.8,306.12c-9.34,2.27-15.78,.74-15.37-9.75"
          />
          <path
            id="metatarsus_6"
            data-name="metatarsus 6"
            class="lfoot-cls-1"
            d="M326.18,247.25c9.06,40.57-4.22,83.16-20.25,126"
          />
          <path
            id="metatarsus_5"
            data-name="metatarsus 5"
            class="lfoot-cls-1"
            d="M185.55,431.75c5.71-27.8,7.18-61.97,6.75-99-.44-16.76-6.66-35.91-15.75-56.25"
          />
          <path
            id="metatarsus_4"
            data-name="metatarsus 4"
            class="lfoot-cls-1"
            d="M161.93,494.75c-15.17-4.87-16-21.23-9-43.88,12.03-49.17,2.42-84.99-4.5-122.63"
          />
          <path
            id="metatarsus_3"
            data-name="metatarsus 3"
            class="lfoot-cls-1"
            d="M258.68,368.75c2.99-37.52,4.01-77.39,3.38-119.25"
          />
          <path
            id="metatarsus_2"
            data-name="metatarsus 2"
            class="lfoot-cls-1"
            d="M214.8,383.37c.59-29.3,2.63-56.92,6.75-82.12,2.58-12.44-.49-26.86-4.5-41.63"
          />
          <polyline
            id="metatarsus_1"
            data-name="metatarsus 1"
            class="lfoot-cls-1"
            points="257.55 412.63 245.18 395.75 246.68 384.12"
          />
        </g>
        <g id="tarsus">
          <path
            id="tarsus_5"
            data-name="tarsus 5"
            class="lfoot-cls-1"
            d="M329.93,477.88c18.8,9.75,22.92-4.19,20.63-28.5,.66-24.47-5.45-40.64-15.37-52.13-28.08-11.52-51.75-16.74-46.88,18.75,15.13,17.2,24.37,34.91,21.75,53.63l19.87,8.25Z"
          />
          <path
            id="tarsus_4"
            data-name="tarsus 4"
            class="lfoot-cls-1"
            d="M304.05,470.38c6.68-12.49,2.64-29.32-14.25-51.38-18.44-3.29-30.5,.95-34.88,14.25-2.43,15.56,2.37,30.75,10.87,45.75,9.77-10.24,23.01-11.91,38.25-8.62Z"
          />
          <path
            id="tarsus_3"
            data-name="tarsus 3"
            class="lfoot-cls-1"
            d="M261.3,479.38c-7.73-15-12.74-30.68-14.25-47.25-17.49-18.62-28.77-16.69-35.25,1.12-1.81,28.74,.54,51.52,12.37,60.75,5.13,5.19,11.22,7.68,18,8.25,1.8-15.08,10.25-19.32,19.13-22.87Z"
          />
          <path
            id="tarsus_2"
            data-name="tarsus 2"
            class="lfoot-cls-1"
            d="M195.68,540.12c38.58,15.07,50.65,3.64,60.75-9.75-8.71-8.9-13.08-17.26-13.5-25.13-27.44-7.52-35.08-26.8-31.87-52.5-13.67-3.6-24.69,1.67-30.38,24.75-8.77,9.7-8.75,20.79-6.38,32.25,6.15,11.63,13.38,21.59,21.37,30.38Z"
          />
          <path
            id="tarsus_1"
            data-name="tarsus 1"
            class="lfoot-cls-1"
            d="M347.55,546.88c10.38-4.3,13.71-14.39,7.13-32.62-2.19-19.15-7.9-32.46-20.62-34.13-27.77-12.78-50.77-14.54-66.38,.75-30.06,7.37-24.28,26.39-8.25,48.75,31.63-27.21,60.57-15.09,88.12,17.25Z"
          />
        </g>
        <g id="ankle_bone" data-name="ankle bone">
          <path
            id="ankle_bone_3"
            data-name="ankle bone 3"
            class="lfoot-cls-1"
            d="M346.8,576.13c3.11-25.15-11.82-47.26-34.5-56.63-26.71-5.48-48.45-4.01-49.13,27,2.01,14.91-.08,25.36-7.5,30-11.39,6.39-21.64,12.17-29.25,16.5-33.33,10.44-37.45,29.3-18.75,54.75,6.99,11.9,15.9,21.87,26.25,30.38,10.56,36.93,24.46,36.87,39.75,21.38,32.12,6.25,46-3.99,41.63-30.75,2.15-18.53,7.27-36.42,13.88-54-1.73-18.67,4.64-31.17,17.63-38.62Z"
          />
          <path
            id="ankle_bone_2"
            data-name="ankle bone 2"
            class="lfoot-cls-1"
            d="M246.68,581.75c7.9,3.07,19.71,3.53,33,3,19.01-10.02,30.05-3.55,35.62,14.25,3.21,9.19,2.13,16.28-3.75,21-4.17,18.27-6.52,35.25-7.13,51,1.56,16.94-5.1,24.43-23.25,18.75-20.16-2.13-38.05-8.19-51.37-22.13-7.78-3.78-10.97-11.26-12.75-19.88-16.82-14.05-19.81-28.64-6.37-43.88l1.5-4.5"
          />
          <path
            id="ankle_bone_1"
            data-name="ankle bone 1"
            class="lfoot-cls-1"
            d="M334.8,536.75c-35.95-17.08-59.62-15.27-70.13,6.75"
          />
        </g>
        <g id="heel_bone" data-name="heel bone">
          <path
            id="heel_bone_2"
            data-name="heel bone 2"
            class="lfoot-cls-1"
            d="M247.8,769.02c19.26,.93,28.31-6.96,24.38-26.07,16.43-15.9,17.7-29.49,6.38-41.18-19.9,12.97-37.9,17.4-46.12-22.29-49.9-47.1-48.04-75.21-8.62-88.4,23.89-17.18,26.3-10.97,33.75-21.16,5.95-8.14,3.59-32.97,9.75-44.58-17.99,16.51-35.93,33.1-71.63,17-7.32,8.54-7.55,19.46-.38,32.87-15.91,26.3-19.98,59.53-12.75,99.36-6.97,6.13-8.53,14.05-1.13,24.93-10.01,15.75-13.66,29.04-2.63,36.64,12.64,24.37,36.54,34.16,69,32.87Z"
          />
          <path
            id="heel_bone_1"
            data-name="heel bone 1"
            class="lfoot-cls-1"
            d="M259.8,761.38c-3.06-4.94-7.79-7.76-14.62-7.88-36.34,4.41-53.26-6.64-64.12-22.5-4.85-5.5-7.27-5-7.88,0"
          />
        </g>
      </g>
      <g id="big_toe_overlay" data-name="big toe overlay">
        <path
          id="big_toe_overlay_1"
          data-name="big toe overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.bigToe),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.bigToe
          )}`}
          d="M357.68,216.5l-28.87-6c-13.13,6.38-15.89-2.98-12-17.25,6.62-19.39,13.37-38.81,6.08-55.8-8.22-17.76-9.72-28.08-3.49-29.86l-5.59-.97c-4.18-5.33-5.15-10.58-2.62-15.75,5.89-13.61,3.8-21.25-3.83-24.75-8.29-4.35-9.71-9.16-1.42-14.63-1.57-5.78,2.17-8.23,9.75-8.25,7.23-2.24,14.02-.34,20.25,6.75,5.02,2.63,5.06,5.71,2.62,9-.78,13.35,4.52,23.42,13.5,31.5,7.18-7.19,9.36,.66,7.87,17.25,7.38,4.24,10.6,11.11,6.7,22.5-5,25.81-1.95,46.8,5.3,65.25,5.79,17.24,1.03,24.23-14.25,21"
        />
      </g>
      <g id="secound_toe_overlay" data-name="secound toe overlay">
        <path
          id="secound_toe_overlay_1"
          data-name="secound toe overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.secondToe),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.secondToe
          )}`}
          d="M257.55,39.13c-5.55-8.06-10.71-7.86-15.38,2.25-7.21,3.8-12.35,12.84-.75,10.87,3.77-.64,.18,13.88-1.13,10.5s-3.76,7.35,1.5,10.13c-2.72,3.77-3.57,8.19,3.71,15.47,4.79,8.88,5.49,16.56,3.79,23.53-3.44,3.79-4.92,7.32,.37,9.95-4.36,5.08-5.09,10.5-1.88,16.3,8.83,15.89,9.83,33.89,3,54-6.34,13.03-3.2,14.87,4.87,10.88,6.89-9.81,14.13-10.54,21.63-4.23,15.26,4.27,17.46,.77,6.12-10.77-8.07-14.11-10.16-33.31-6.12-57.75-3.49-13.29-7.42-13.32-11.5-9l6.94-1.34c3.35-.5,7.1-.44,2.06-12.54-5.29-4.45-7.31-11.14-5.25-20.63,1.82-8.49,.37-13.15-8.25-9.38l3-4.5c5.2-4.05,6.62-7.91-2.07-11.25-2.28-1.79-2.54-4.41,.19-8.25,4.55-4.56,2.22-9.34-4.87-14.25Z"
        />
      </g>
      <g id="third_toe_overlay" data-name="third toe overlay">
        <path
          id="third_toe_overlay_1"
          data-name="third toe overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.thirdToe),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.thirdToe
          )}`}
          d="M209.18,71.75c-4.97-3.86-9.6-4.65-13.5,1.12-7.21,5.7-7.27,9.1-1.13,10.5,2.11,4.55,3.05,8.35-.38,9.37-7.15,6.53-6.61,10.43,.75,12-2.21,2.58-2.84,6.12,.37,12,4.23,6,6.76,11.61,2.39,15.6l.96,4.74c-5.88,2.97-5.82,9.43,2.45,20.72,7.89,16.49,10.84,32.37,7.33,47.44-6.72,14.15-7.28,20.2,2.99,12.02,7.29-6.33,13.15-4.73,18.45,0,10.69,9.38,9.31-.57,5.57-13.52-12.4-14.91-14.82-34.83-14.25-56.25,2.88-4.02,2.82-7.8-4.2-11.04,4.51-4.24,5.09-8.7,2.7-13.34,1.58-14.52-.44-22.45-8.62-19.13,5.15-6.88,4.63-10.64,1.13-12.75-1.94-3.06-2.77-6.19-2.62-9.38,4-3.86,5.71-7.45-.37-10.13Z"
        />
      </g>
      <g id="fourth_toe_overlay" data-name="fourth toe overlay">
        <path
          id="fourth_toe_overlay_1"
          data-name="fourth toe overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.fourthToe),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.fourthToe
          )}`}
          d="M171.3,116.37c-4.7-7.59-9.33-8.1-13.87-.75-6.25,3.94-8.16,7.59,.75,10.5l.75,10.88c-8.09,1.06-8.87,3.63-1.5,7.87-2.85,2.39-3.56,5.34-1.5,9l.37,9c-1.89,3.47-2.7,6.73,1.5,9-2.68,4.95-4.12,10.66-2.25,18.37,11.83,12.02,13.04,28.38,9,46.88-4.31,15.3-1.5,17.92,5.25,13.5,4.45-4.26,9.24-4.82,14.25-3,7.73,6.04,8.89,1.87,4.87-10.37-11.14-20.93-12.24-38.75-10.99-55.84,1.77-10.19-.49-12.43-5.36-9.54,6.45-3.33,7.2-9.14,1.73-17.62,3.81-6.17,.81-9.33-4.8-11.35,8.72,.03,8.08-4.06,4.05-9.65-4.31-3.03-3.79-6.31,0-9.75,4.16-2.11,2.5-4.53-2.25-7.13Z"
        />
      </g>
      <g id="little_toe_overlay" data-name="little toe overlay">
        <path
          id="little_toe_overlay_1"
          data-name="little toe overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.littleToe),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.littleToe
          )}`}
          d="M136.8,168.5c-7.03-6.05-11.61-6.47-12.38,1.87-5.19,6.55-5.82,11.19,0,13.12-4.44,10.73-4.85,16.8,1.22,15.37l-3.09,1.88c.85,4.84,1.41,9.33,1.22,12.92-5.49,5.02-7.41,9.78-3.09,14.08l5.18,2.12c-3.52-.79-4.91,1.72-3.49,8.63,8.29,15.05,12.25,29.27,9.19,42.12-1.84,7.92,.19,11.62,5.62,11.63,.97-5.69,6.78-5.61,13.13-4.87,9.74,3.31,8.42-.73,4.13-8.25-6.96-11.81-8.06-28.08-8.54-44.83,.16-4.93-1.88-6.35-6.09-4.3l4.5-2.62c3.29-3.59,4.19-6.93,0-9.75-2.96-4.98-3.88-10.24-3-15.75l-6.93-3.42c9.55-.95,10.24-4.82,4.45-10.83-3.67-3.09-3.13-6.42,.32-9.92,.78-2.44-.04-5.52-2.35-9.2Z"
        />
      </g>
      <g id="metatarsus_overlay" data-name="metatarsus overlay">
        <path
          id="metatarsus_overlay_1"
          data-name="metatarsus overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.metatarsus),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.metatarsus
          )}`}
          d="M379.27,240.5c2-14.67-2.08-23.74-17.88-22.02-17.15-4.47-33.57-7.98-43.45-2.73-4.95,.8-8.89,3.33-10.13,10.5-5.89-7.75-14.15-13.7-23.62-18.75-12.66-9.92-24.61-12.74-34.88,1.5-3.91,1.36-7.54,3.9-10.82,7.88-.98,4.91-3.38,6.66-8.51,2.26-3.3-6.34-8.01-8.43-16.3,.36-1.81,2.62-4.34,2.79-7.13,2.11-6.83,5.29-12.39,12.87-15.23,25.34-.29,2.7-1.3,4.57-5.4,2.92-9.01-5.65-15.26-3.9-18.75,5.25-8.52,8-12.77,16-12.75,24,5.25,9.61,3.41,11.88-4.13,8.25-7.9-1.37-13.22-.27-12.38,6.75-5.31-1.5-7.59,.93-2.63,12.75-7.59,5.07-8.49,11.7,3.75,21.38,10.91,23.92,12.56,49.1,4.5,128.25-6.51,21-6.33,39.48,14.05,50.36,4.4,8.73,8.54,9.97,12.2-1.98-1.72-9.74,2.4-22.36,10.13-36.75,1.9-16.99,14.28-23.01,31.13-24.37l.75-10.5c2.67-16.35,12.07-18.86,25.13-13.87l1.11-4.56c1.61,10.35,6.13,17.26,13.89,20.31,5.37-15.48,18.08-18.87,33.38-18-1.94-9.42,.66-17.6,5.63-25.12l.38-2.62c12.39-5.83,27.42-1.69,43.87,7.88,13.65,8.2,20.96,4.46,19.13-16.5-.65-48.09,9.36-94.59,24.96-140.25Z"
        />
      </g>
      <g id="tarsus_overlay" data-name="tarsus overlay">
        <path
          id="tarsus_overlay_1"
          data-name="tarsus overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.tarsus),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.tarsus
          )}`}
          d="M335.18,397.25c-30.24-12.58-51.72-13.88-46.88,18.75l1.5,3c-20.88-2.31-32.84,2.19-34.88,14.25l-3,1.87-4.87-3c-17.75-15.28-30.94-18.65-35.25,1.12l-.75,19.5c-12.15-4.37-22.81,.54-30.38,24.75-6.7,6.93-9.28,17.31-6.38,32.25l21.37,30.38c27.92,12.91,47.06,7.32,60.75-9.75,34.08-29.08,63.41-13.86,91.12,16.5,11.2-5.73,13.62-16.58,7.13-32.62-3.84-21.86-9.73-31.42-16.92-33.24,11.62,1.51,17.03-14.41,13.37-36.89-2.04-27.76-10.17-41.58-15.95-46.88Z"
        />
      </g>
      <g id="ankle_bone_overlay" data-name="ankle bone overlay">
        <path
          id="ankle_bone_overlay_1"
          data-name="ankle bone overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.ankleBone),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.ankleBone
          )}`}
          d="M315.3,668.75c3.07,27.32-10.44,37.93-41.63,30.75-20.03,19.8-31.96,7.41-39.75-21.38-62.73-56.66-38.36-72.75-10.12-87.04,10.92-7.6,19.54-12.41,25.85-14.4,12.29-.18,12.55-27.87,17.65-51.33,24.7-17.44,46.26-4.58,67.5,11.4,9.61,13.37,13.94,26.51,12,39.38-13.52,8.64-18.71,21.9-17.63,38.62l-13.88,54Z"
        />
      </g>
      <g id="heel_bone_overlay" data-name="heel bone overlay">
        <path
          id="heel_bone_overlay_1"
          data-name="heel bone overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.heelBone),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.heelBone
          )}`}
          d="M278.55,701.78c9.79,10.46,11.98,23.01-6.38,41.18,.59,18.26-5.23,32.02-39.53,25.8-15.35-1.5-30.72-3.14-48.87-24.53-14.15-12.01-16.2-26.64-2.35-44.71-6.14-10.5-5.73-18.8,1.13-24.93-7.62-33.84-4.22-67.01,12.75-99.36-7.95-13.74-6.11-24.1,.38-32.87,32.72,15.07,53.4,1.91,71.63-17-2.95,25.05-5.4,50.72-25.35,54.67-5.68,2.73-11.72,6.39-18.15,11.06-28.04,13.27-51.54,28.92,8.62,88.4,7.1,39.58,24.89,37.63,46.12,22.29Z"
        />
      </g>
    </svg>
  );
}

export default TopView;
