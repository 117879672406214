import React, { useMemo, useEffect, useState } from "react";
import { MakeGetObject } from "../../Utilities/AuthHeader";
import Table from "../Tables/backendTable";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web/lib";

export default function FacilitiesTable(props) {
  const { keycloak } = useKeycloak();
  const { t, i18n } = useTranslation(["facilitieslist", "common"]);
  const columns = useMemo(() => [
    {
      Header: t("common:name"),
      accessor: "name",
    },
    // {
    //   Header: "Type",
    //   accessor: "institutionsbezeichnung",
    // },
    {
      Header: t("common:zip"),
      accessor: "plz",
    },
    {
      Header: t("common:city"),
      accessor: "ort",
    },
    {
      Header: t("common:street"),
      accessor: "strasse",
    },
  ]);

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  const noDataInfo = [
    {
      name: t("noData.name"),
      institutionsbezeichnung: t("noData.institutionsbezeichnung"),
      plz: t("noData.plz"),
      ort: t("noData.ort"),
      strasse: t("noData.strasse"),
    },
  ];

  const loadData = React.useCallback(
    ({ pageSize, pageIndex, sortBy }, filerValue) => {
      //apply global filter to request only if search phrase is set
      const buildFilter = () => {
        if (filerValue !== "") {
          //setPageIndex to 0 here only if filtering is active to see 1st page before loading data
          setPageIndex(0);
          const searchValue = `%${filerValue}%`;
          const filter = {
            or: [
              { field: "name", op: "like", value: searchValue },
              {
                field: "institutionsbezeichnung",
                op: "like",
                value: searchValue,
              },
              { field: "plz", op: "like", value: searchValue },
              { field: "ort", op: "like", value: searchValue },
              { field: "strasse", op: "like", value: searchValue },
            ],
          };
          return `&search=${encodeURIComponent(JSON.stringify(filter))}`;
        } else {
          return "";
        }
      };

      //returns sorting query
      const buildSorting = () => {
        if (sortBy.length > 0) {
          setPageIndex(0);
          return `&sort=${encodeURIComponent(
            JSON.stringify({
              field: sortBy[0].id,
              direction: sortBy[0].desc === true ? "desc" : "asc",
            })
          )}`;
        } else {
          return "";
        }
      };

      //builds url for request with pagination filters and sorting
      const buildURL = () => {
        return `${window.conf.SERVER_CONFIG.BASE_URL}/pmed/facilities?page_size=${pageSize}&page_number=${
          pageIndex + 1
        }${buildFilter()}${buildSorting()}`;
      };

      const fetchId = ++fetchIdRef.current;
      //set loading state in table
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        setPageIndex(pageIndex);
        fetch(buildURL(), MakeGetObject(keycloak))
          .then((response) => response.json())
          .then((rdata) => {
            if (
              rdata.FacilitiesList.facilities == undefined ||
              rdata.FacilitiesList.facilities.length === 0
            ) {
              //no data in response so give table no data info
              setTableData(noDataInfo);
              //jump to first page
              setPageCount(1);
              setItemCount(0);
              //loading finished disable loading state
              setLoading(false);
            } else {
              //provide data to table
              setTableData(rdata.FacilitiesList.facilities);
              //set pageCount
              setPageCount(
                Math.ceil(rdata.FacilitiesList.total_count / pageSize)
              );
              setItemCount(rdata.FacilitiesList.total_count);
              //loading finished disable loading state
              setLoading(false);
            }
          });
      }
    },
    []
  );

  return (
    <div className="card card-shadow">
      {props?.headerLess === false && (
        <div className="card-header">
          <h4>{t("header")}</h4>
        </div>
      )}
      <div className="table-container">
        <Table
          columns={columns}
          data={tableData}
          fetchData={loadData}
          loading={loading}
          pageCount={pageCount}
          controlledPageIndex={pageIndex}
          idprefix="fac"
          itemCount={itemCount}
          rowClickHandler={props.rowClick}
          selectedids={props.selectedIds}
          idfield="id"
        />
      </div>
    </div>
  );
}
