import { useKeycloak } from "@react-keycloak/web";
import { useUser } from "../FetchHooks/Pmed/PmedHooks";

function useLoggedUser(loadDb = true) {
  const { keycloak } = useKeycloak();
  const user = useUser({
    userId: keycloak?.idTokenParsed?.pmed_mid,
    enabled: keycloak?.idTokenParsed?.pmed_mid > 0 && loadDb,
    xmask: "*",
  });

  return {
    tokenData: {
      roles: keycloak?.realmAccess?.roles,
      authenticated: keycloak?.authenticated,
      realm: keycloak?.realm,
      clientId: keycloak?.clientId,
      email: keycloak?.idTokenParsed?.email,
      emailVerified: keycloak?.idTokenParsed?.email_verified,
      familyName: keycloak?.idTokenParsed?.family_name,
      givenName: keycloak?.idTokenParsed?.given_name,
      name: keycloak?.idTokenParsed?.name,
      pmedDepartmentId: keycloak?.idTokenParsed?.pmed_department_id,
      pmedMid: keycloak?.idTokenParsed?.pmed_mid,
      userName: keycloak?.idTokenParsed?.preferred_username,
    },
    dbData: user.data,
  };
}

export default useLoggedUser;
