import style from "./style.scss";

export default function RechterOberarmMitEllenbogenLatSitzend2230(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362.89 767.27">
      <g id="Upperarmlat">
        <g id="Backround">
          <path
            id="backround"
            class="oberarm_mit_ellenbogen_lat_right2230_cls5"
            d="M7.54,4.19c2.97-1.8.71-.69,14.02,0,4.22.22,14.41.15,30.26,0,31.65-.29,41.82,1.59,44.84,6,2.34,3.41-13.89,263.56-7.16,280.11-1.29,11.07-1.67,22.9-1.71,24.26-.02.64-.07,1.95-.09,3.55-.13,10.24.66,8.9.93,26.41.26,16.9-.31,28.89-.57,35.78-.74,19.41-2.02,52.94,2,90,2.02,18.62,3.41,20.03,6,45,2.72,26.19,4.07,39.29,4,42-.26,9.6-1.32,17.47,3,27,1.44,3.18,4.67,10.3,12,15,6.81,4.37,9.55,1.77,24,7,7.54,2.73,11.39,4.17,15,8,5.05,5.35,5.87,11.27,8,11,1.78-.22,1.67-4.41,5-12,2.29-5.21,3.43-7.82,6-10,3.99-3.39,8.78-3.61,17-4,6.2-.29,9.3-.44,12,1,5.79,3.08,4.47,8.47,10,13,1.06.86,4.27,3.26,17,4,11.16.65,19.79-.54,23-1,19.71-2.82,24.95-4.25,37-6,15.68-2.27,23.52-3.41,34-3,13.91.54,17.14,2.99,35,4l-6,58c-14.92-.68-39.72-2.36-74,0-5.74.39-35.93,2.47-36,6-.03,1.56,8.31,3.39,25,7,10.81,2.34,16.22,3.51,25,5,16.47,2.8,30.5,1.83,38,5,10.48,4.42,20.74,1.65,30.36,2.09-.33,10.9-1.25,21.8-1.36,32.72-.13,12.25-.58,26.19,3.83,37.84.01.03-5.6.03-5.59.05-22.83.02-27.73-.95-83.24.29-43.91.98-62.84,2.18-100,1-46.44-1.47-39.38-4.06-60-3-3.01.15-36.77,1.94-66-5-4.07-.97-11.4-2.9-15-9-3.83-6.49.37-11.21,0-23-.38-12.06-4.92-11.74-9-32-3.67-18.22-.89-22.91,1-25,3.21-3.57,7.44-3.65,8-7,.62-3.74-4.33-5.58-7-12-1.85-4.44-1.31-7.99-1-13,.53-8.66-.88-17.33-1-26-.26-18.71-3.76-70.78-8-121-4.59-54.37-5.69-54.83-9-100-6.31-86.1,0-52.55-3-76C.49,292.18,7.38,10.18,7.54,4.18h0Z"
          />
        </g>
        <g id="Outlines">
          <path
            id="outline3"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M351.3,669.28c-33.23-1.51-49.76-4.97-74,0-13.32,2.73-20.83,6.34-36,6-11.16-.24-18.04-2.43-26.76,2.02-5.19,2.65-13.66,9-15,10-6.51,4.88-8.44,7.07-12,7-3.67-.07-5.66-2.48-12-8-11.87-10.33-11.97-8.02-15-12-8.53-11.22-5.74-26.88-5-31,.69-3.85,2.38-8.58,5.76-18.02,1.11-3.09,2.61-7.11,5-12,2.73-5.59,4.1-8.38,6-10,3.99-3.39,8.78-3.61,17-4,6.2-.29,9.3-.44,12,1,5.79,3.08,4.47,8.47,10,13,1.06.86,4.27,3.26,17,4,11.16.65,19.79-.54,23-1,19.71-2.82,24.95-4.25,37-6,15.68-2.27,23.52-3.41,34-3,13.91.54,17.14,2.99,35,4"
          />
          <path
            id="outline2"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M357.3,765.01c-58.79,1.93-6.21-1.47-84,.27-43.91.98-62.84,2.18-100,1-46.44-1.47-39.38-4.06-60-3-3.01.15-36.77,1.94-66-5-4.07-.97-11.4-2.9-15-9-3.83-6.49.37-11.21,0-23-.38-12.06-4.92-11.74-9-32-3.14-15.62-1.54-21.22,1-25,2.66-3.96,6.52-6.17,8-7,2.02-1.13,9.03-5.05,15.24-2.98,6.13,2.04,5.9,8.06,12,15,6.67,7.59,15.34,9.93,23,12,7.8,2.11,21.76,5.88,35-1,8.9-4.63,12.97-11.73,20-24,5.45-9.51,10.14-17.7,9-28-.89-8.12-4.82-12.27-3-14,2.98-2.83,16.23,5.69,25,13,9.33,7.78,9.07,10.42,19,19,4.79,4.14,11.88,10.18,22,15,7.36,3.5,7.1,1.97,31.76,8.98,12.54,3.57,15.84,5.02,25,7,10.81,2.34,16.22,3.51,25,5,16.47,2.8,29.89,4.24,38,5,26.22,2.47,8.9,1.89,28,2.02"
          />
          <path
            id="outline1"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M97.54,4.19c-5.9,27.53-10.38,306.86-10.51,310.35-.07,1.79-.09,3.55-.09,3.55-.13,10.24.66,8.9.93,26.41.26,16.9-.31,28.89-.57,35.78-.74,19.41-2.02,52.94,2,90,2.02,18.62,3.41,20.03,6,45,2.72,26.19,4.07,39.29,4,42-.26,9.6-1.32,17.47,3,27,1.44,3.18,4.67,10.3,12,15,6.81,4.37,9.55,1.77,24,7,9.62,3.48,13.21,6.37,15,8,1.9,1.72,5.53,5.43,8,11,5.08,11.48,1.59,22.69.24,27.02-4.17,13.39-13.21,21.08-19,26-12.33,10.48-24.79,14.21-31,16-10.33,2.97-34.13,9.82-55-2-5.36-3.04-14.5-8.21-19-18-1.59-3.47-2-6.31-5.24-12.02-3.24-5.72-5.15-7.55-7-12-1.85-4.44-1.31-7.99-1-13,.53-8.66-.88-17.33-1-26-.26-18.71-3.76-70.78-8-121-4.59-54.37-5.69-54.83-9-100-2.58-35.11-1.88-34.42-1.75-42.17.15-8.32-.03-17.81-1.25-33.83-1.47-19.36-2.41-21.12-2.8-33.98C3.04,273.74,7.42,8.17,7.54,4.19"
          />
        </g>
        <g id="Overview">
          <path
            id="partofancon4"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M88.54,540.3c-1.78,11.12-.96,20.18,0,26,1.07,6.48,2.28,13.41,7,21,.79,1.28,4.61,7.28,12,13,10.02,7.75,14.43,5.57,21,11,11.48,9.5,11.18,27.13,11,31-.15,3.13-.95,20.36-15,31-14.24,10.79-31.29,6.63-38,5-7.43-1.81-20.59-5.02-27-17-5.41-10.12-4.28-23.24,1-32,3.01-5,5.6-5.63,18-21,2.89-3.58,5.48-6.93,5-11-.51-4.34-4.2-7.01-8-10,0,0-10.38-8.16-19-17-7.1-7.28-9.73-15.85-15-33-2.01-6.56-4.4-15.72-6-27"
          />
          <path
            id="partofancon3"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M30.54,488.33c-4.96,0-4.49-360.95-4.49-478.14"
          />
          <path
            id="partofancon2"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M82.77,537.3c-6.21,0-20.24-370.11-8.24-527.11"
          />
          <path
            id="partofancon1"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M51.82,655.22c-8.97-5.99-15.84-14.91-25.77-26.92"
          />
          <path
            id="radius4"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M166.3,613.28c6.62-4.98,14.64,26.51-2.33,63.02"
          />
          <path
            id="radius3"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M163.97,676.3c4.6,0,30.57-36.51,8.33-73.02"
          />
          <path
            id="ulna3"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M291.54,696.3c-42,9-78,2.29-78-3.54"
          />
          <line
            id="ulna2"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            x1="357.3"
            y1="745.09"
            x2="201.54"
            y2="744.3"
          />
          <line
            id="ulna1"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            x1="228.54"
            y1="703.3"
            x2="357.3"
            y2="704.18"
          />
          <path
            id="radius2"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M348.54,618.3c-18.33-6.99-32.55-7.11-42-6-3.45.4-15.35,3.61-39,10-16.16,4.37-25.07,6.86-35,3-5.9-2.29-16.45-3.03-19-5.8"
          />
          <path
            id="radius1"
            class="oberarm_mit_ellenbogen_lat_right2230_cls6"
            d="M336.54,658.3c-27.57,1.08-39.97,1.43-58,4-20.41,2.91-36.83,7.07-47.57,10.15"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="anconhighlight"
          class="Ellenbogen2230Fill"
          style={{
            fill: props.colors.Ellenbogen2230Fill,
            stroke: props.colors.Ellenbogen2230Fill,
          }}
          d="M208.68,611.93c9.53,44.26-50.56,83.69-68.04,95.16-3.59,2.35-81.68,52.62-111.37,24.38-12.01-11.42-11.09-30.91-10.6-41.35,1.95-41.49,34.15-68.2,50.4-81.67,18.68-15.49,66.26-54.95,107.21-35.43,1.17.56,26.89,13.28,32.4,38.91Z"
        />
        <path
          id="radialheadhighlight"
          class="RadiusHead2230Fill"
          style={{
            fill: props.colors.RadiusHead2230Fill,
            stroke: props.colors.RadiusHead2230Fill,
          }}
          d="M211.08,647.06c1.5,7.99-1.54,8.77-.4,16.89.81,5.76,2.58,7.13,1.52,10.92-1.01,3.61-3.71,6.25-3.99,6.52-1.1,1.05-.96.57-4.3,2.98-1.15.83-1.45,1.09-6.57,5.06-1.7,1.31-2.11,1.63-2.83,2.12-3.34,2.28-4.82,2.56-5.26,2.63-.5.08-1.84.29-3.39-.15-2.1-.59-3.36-1.99-4.1-2.78-.16-.17-2.85-2.46-8.24-7.03-2.55-2.16-4.37-3.73-7.08-5.61-2.46-1.71-3-1.85-4.15-2.83-1.23-1.05-3.48-3.33-5.31-8.93-3.33-10.18-1.57-20.22-.95-23.6.79-4.26,1.83-7.36,3.65-12.8.56-1.68,1.11-3.22,1.64-4.63,1.46-3.88,2.75-6.83,3.35-8.18,1.85-4.15,2.92-6.55,4.85-9.61,1.8-2.86,2.7-4.28,4.15-5.46,2.64-2.14,5.69-2.73,10.62-3.03,9.99-.6,14.52-.8,17.5.69.59.29,1.43.71,2.32,1.53,1.39,1.27,1.55,2.24,3.54,6,1.35,2.56,1.9,3.28,2.43,3.88,1.23,1.38,1.89,1.52,2.53,2.19,2.38,2.49-.32,7.34-2.12,18.17-.56,3.34-.68,5.15-.64,6.94.08,3.63.77,5.53,1.25,8.12h-.02Z"
        />
        <path
          id="humeroulnarjointhighlight"
          class="humeroulnarenGelenk2230Fill"
          style={{
            fill: props.colors.humeroulnarenGelenk2230Fill,
            stroke: props.colors.humeroulnarenGelenk2230Fill,
          }}
          d="M25.36,648.27c1.15-1.44,4.94-.6,6.54.19.19.09.69.36,1.47.72,1.09.5,1.88.79,2.5,1.05,3.41,1.46,5.52,3.79,6.65,4.85,5.02,4.73,20.26,12.4,33.07,17.39,11.34,4.42,17.01,6.63,25.21,6.78,3.58.07,10,.12,17.39-2.7,19.19-7.33,26.86-27.21,27.25-28.29,2.83-7.68,2.4-13.06,5.06-13.55,3.26-.6,8.73,6.62,9.65,14.13,1.51,12.29-9.44,22.79-14.73,27.86-6.16,5.91-11.94,8.98-17.12,11.73-15.96,8.47-30.16,10.21-34.52,10.65-7.82.79-18.98,1.79-31.55-2.83-8.56-3.14-13.7-7.41-14.97-8.49-2.13-1.82-6.28-5.42-9.57-11.33-3.21-5.75-1.84-6.94-5.58-13.33-1.67-2.85-2.8-4.2-4.2-6.52-.29-.48-.43-.72-.54-.92-1.08-1.94-3.25-5.83-2.01-7.39Z"
        />
        <path
          id="brachiumhighlight"
          class="Oberarm2230Fill"
          style={{
            fill: props.colors.Oberarm2230Fill,
            stroke: props.colors.Oberarm2230Fill,
          }}
          d="M5.7,100.17c-.83,47.02-1.12,41.1-1.82,81.51,0,0-.51,29.48-1.84,72.6-.04,1.42-.13,3.93-.28,7.16-.25,5.26-.58,9.93-.84,13.34-.38,4.97-.42,4.37-.47,5.8-.24,6.22.61,10.95.89,12.61,1.58,9.35,1.58,18.9,2.49,28.34,1.83,19.1,1.13,36.7.98,46.4-.44,28.18,3.09,36.17,11.62,134.79,1.54,17.83,4.66,54.87,5.47,77.14.35,9.48.29,16.69,1.27,28.43.33,3.96.91,10.01,1.08,18.2.12,5.68.18,8.52.11,9.97-.24,5.47-.82,7.18.02,10.66.73,2.99,2.11,5.63,2.68,6.69.88,1.66,1.12,1.78,2.85,4.5,2.16,3.39,4.29,6.76,5.93,11.06.81,2.12,1.2,3.66,1.35,4.18,1.9,6.68,13.31,17.79,29.1,22.85,16.32,5.24,30.46,1.82,40.47-.61,12.1-2.93,21.36-7.59,27.24-11.06,2.02-1.26,30.99-19.87,30.07-45.71-.12-3.45-.43-12.13-6.34-19.96-5.65-7.48-13.75-10.64-17.93-12.27-5.42-2.11-10.5-4.09-16.45-4.58-1.06-.09-3.19-.21-5.8-1.21-3.49-1.34-5.72-3.43-7.21-4.85-1.19-1.13-3.86-3.87-6.4-8.76-4.58-8.8-4.93-17.22-5.06-21.1-.15-4.75.45-4.64.34-10.99-.12-6.27-.78-10.8-1.21-13.89-1.32-9.48-.16-3.85-2.97-27.64-.86-7.29-1-8.12-3.24-25.89-3.43-27.29-3.68-29.98-3.88-32.46-.76-9.42-.9-16.32-1.18-30.13-.16-8.04-.38-19.42.07-34.39.31-10.59.52-8.31.82-18.71.58-20.18-.01-34.81-.21-39.34-.32-7.11-.43-6.19-.61-11.43-.43-12.92.17-21.18.61-31.35.36-8.38.02-4.33.61-31.96.34-16.21.63-25.46.94-37.35.89-33.54.6-33.53,1.48-69.3.12-4.82.7-28.35,1.75-59.06,1.63-47.86,1.63-32.28,2-47.32,0,0,.18-7.27,2.04-31.42.33-4.27.65-5.96.81-6.74.41-1.99.61-2.22.5-2.74C96.77.46,83.85.12,80.59.05c-14.09-.29-23.27.67-30.47,1.08C25.73,2.51,9.89-1.67,7.54,4.18c-.11.28-.09.32-.12,4.64-.03,4.13-.15,8.26-.17,12.4-.04,7.82-.35,17.8-.54,24.74-.43,16.12-.63,32.71-1.01,54.2Z"
        />
      </g>
    </svg>
  );
}
