import useWindowSize from "../../../Utilities/Window/windowSize";
import IndicationList from "./IndicationList";
import ExaminationList from "./ExaminationList";
import BodyPartSelector from "./BodyPartSelector";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./stylesheets/ExamPlanner.scss";

export default function ExamPlanner(props) {
  const [visibleTile, setVisibleTile] = useState(undefined);
  const windowSize = useWindowSize();

  const getExPlannerHeight = () => {
    if (windowSize.width < 1200) {
      return "80vh";
    } else {
      return "83vh";
    }
  };

  const getTileStyle = (tileName) => {
    if (visibleTile === undefined) {
      return {
        height: "100%",
        opacity: "1",
        padding: "10px",
        width: "33.33%",
      };
    }
    if (tileName === visibleTile) {
      return {
        height: "100%",
        opacity: "1",
        padding: "10px",
        width: "100%",
      };
    } else {
      return {
        height: "100%",
        opacity: "0",
        padding: "0",
        width: "0",
      };
    }
  };

  useEffect(() => {
    if (windowSize.width > 1200) {
      setVisibleTile(undefined);
    } else {
      if (visibleTile === undefined) {
        setVisibleTile("BodyPartMap");
      }
    }
  }, [windowSize.width]);

  return (
    <div
      className="d-flex flex-column w-100 bg-white align-items-center rounded mt-1"
      name="PatientHeader"
      style={{ height: getExPlannerHeight(), overflowX: "hidden" }}
    >
      {windowSize?.width < 1200 && (
        <div
          className="d-flex w-100 border-bottom py-1 px-1"
          style={{ gap: "5px" }}
        >
          <Button
            size="sm"
            disabled={visibleTile === "BodyPartMap"}
            onClick={() => {
              setVisibleTile("BodyPartMap");
            }}
          >
            Body Parts
          </Button>
          <Button
            size="sm"
            disabled={visibleTile === "Indications"}
            onClick={() => {
              setVisibleTile("Indications");
            }}
          >
            Indications
          </Button>
          <Button
            size="sm"
            disabled={visibleTile === "Examinations"}
            onClick={() => {
              setVisibleTile("Examinations");
            }}
          >
            Examinations
          </Button>
        </div>
      )}
      <div className="d-flex w-100" style={{ height: getExPlannerHeight() }}>
        {/* BodyPart Image Selector */}
        <div
          style={getTileStyle("BodyPartMap")}
          className="d-flex border-right planner-tile align-items-center justify-content-center"
          name="BodyPartMap"
        >
          <BodyPartSelector />
        </div>
        {/* Indications  */}
        <div
          style={getTileStyle("Indications")}
          className="d-flex flex-column border-right planner-tile"
          name="Indications"
        >
          <IndicationList />
        </div>
        {/* Examinations */}
        <div
          style={getTileStyle("Examinations")}
          className="d-flex flex-column planner-tile"
          name="Examinations"
        >
          <ExaminationList />
        </div>
      </div>
    </div>
  );
}
