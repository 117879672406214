import testImage1 from "./1.png";
import testImage2 from "./2.png";

// start 
import drawing_1101 from "./Exam_1101/1101_patpos.svg";
import drawing_1101_1 from "./Exam_1101/1101_bodypos1.svg";
import drawing_1102 from "./Exam_1102/1102_patpos.svg";
import drawing_1102_1 from "./Exam_1102/1102_bodypos1.svg";
import drawing_1201 from "./Exam_1201/1201_patpos.svg";
import drawing_1201_1 from "./Exam_1201/1201_bodypos1.svg";
import drawing_1201_2 from "./Exam_1201/1201_bodypos2.svg";
import drawing_1201_3 from "./Exam_1201/1201_bodypos3.svg";
import drawing_1203 from "./Exam_1203/1203_patpos.svg";
import drawing_1203_1 from "./Exam_1203/1203_bodypos1.svg";
import drawing_1204 from "./Exam_1204/1204_patpos.svg";
import drawing_1204_1 from "./Exam_1204/1204_bodypos1.svg";
import drawing_1204_2 from "./Exam_1204/1204_centry.svg";
import drawing_1205 from "./Exam_1205/1205_patpos.svg";
import drawing_1205_1 from "./Exam_1205/1205_bodypos1.svg";
import drawing_1205_2 from "./Exam_1205/1205_centry.svg";
import drawing_1206 from "./Exam_1206/1206_patpos.svg";
import drawing_1206_1 from "./Exam_1206/1206_bodypos1.svg";
import drawing_1206_2 from "./Exam_1206/1206_centry.svg";
import drawing_1207 from "./Exam_1207/1207_patpos.svg";
import drawing_1207_1 from "./Exam_1207/1207_bodypos1.svg";
import drawing_1207_2 from "./Exam_1207/1207_centry.svg";
import drawing_1208 from "./Exam_1208/1208_patpos.svg";
import drawing_1208_1 from "./Exam_1208/1208_bodypos1.svg";
import drawing_1208_2 from "./Exam_1208/1208_bodypos2.svg";
import drawing_1208_3 from "./Exam_1208/1208_centry.svg";
import drawing_1209 from "./Exam_1209/1209_patpos.svg";
import drawing_1209_1 from "./Exam_1209/1209_bodypos1.svg";
import drawing_1209_2 from "./Exam_1209/1209_bodypos2.svg";
import drawing_1209_3 from "./Exam_1209/1209_centry.svg";
import image_1315_1 from "./Exam_1315/1315_bodypos1_tpl.PNG";
import image_1315_2 from "./Exam_1315/1315_bodypos2_tpl.PNG";
import image_1315_3 from "./Exam_1315/1315_bodypos2_tpl_mit_erkennung.png";
import drawing_1315_1 from "./Exam_1315/1315_bodypos1.svg";
import drawing_1315_2 from "./Exam_1315/1315_bodypos2.svg";
import drawing_1315_3 from "./Exam_1315/1315_centry.svg";
import drawing_1301_1 from "./Exam_1301/1301_bodypos1.svg";
import drawing_1301_2 from "./Exam_1301/1301_centry.svg";
import drawing_1302_1 from "./Exam_1302/1302_bodypos1.svg";
import drawing_1302_2 from "./Exam_1302/1302_centry.svg";
import image_1303_1 from "./Exam_1303/1303_bodypos1_tpl.png";
import drawing_1303_1 from "./Exam_1303/1303_bodypos1.svg";
import drawing_1303_2 from "./Exam_1303/1303_centry.svg";
import drawing_1304_1 from "./Exam_1304/1304_bodypos1.svg";
import drawing_1304_2 from "./Exam_1304/1304_centry.svg";
import image_1305_1 from "./Exam_1305/1305_bodypos1_tpl.png";
import image_1305_2 from "./Exam_1305/1305_bodypos1_tpl_mit_erkennung.png";
import drawing_1305_1 from "./Exam_1305/1305_bodypos1.svg";
import drawing_1305_2 from "./Exam_1305/1305_centry.svg";
import drawing_1306_1 from "./Exam_1306/1306_bodypos1.svg";
import drawing_1306_2 from "./Exam_1306/1306_centry.svg";
import image_1307_1 from "./Exam_1307/1307_bodypos1_tpl.png";
import image_1307_2 from "./Exam_1307/1307_bodypos1_tpl_mit_erkennung.png";
import image_1307_3 from "./Exam_1307/1307_bodypos2_tpl_mit_erkennung.png";
import image_1307_4 from "./Exam_1307/1307_bodypos3_tpl_mit_erkennung.png";
import drawing_1307_1 from "./Exam_1307/1307_left.svg";
import drawing_1308_1 from "./Exam_1308/1308_right.svg";
import image_1309_1 from "./Exam_1309/1309_bodypos1_tpl.png";
import drawing_1309_1 from "./Exam_1309/1309_left.svg";
import drawing_1310_1 from "./Exam_1310/1310_right.svg";
import image_1311_1 from "./Exam_1311/1311_bodypos1_tpl.png";
import drawing_1311_1 from "./Exam_1311/1311_left.svg";
import drawing_1312_1 from "./Exam_1312/1312_right.svg";
import image_1313_1 from "./Exam_1313/1313_bodypos1_tpl.png";
import drawing_1313_1 from "./Exam_1313/1313_left.svg";
import drawing_1314_1 from "./Exam_1314/1314_right.svg";
import drawing_1316_1 from "./Exam_1316/1316_bodypos2.svg";
import drawing_1316_2 from "./Exam_1316/1316_centry.svg";
import image_1317_1 from "./Exam_1317/1317_bodypos1_tpl.png";
import image_1317_2 from "./Exam_1317/1317_bodypos2_tpl.png";
import drawing_1317_1 from "./Exam_1317/1317_bodypos1.svg";
import drawing_1317_2 from "./Exam_1317/1317_bodypos2.svg";
import drawing_1317_3 from "./Exam_1317/1317_centry.svg";
import drawing_1318_1 from "./Exam_1318/1318_bodypos1.svg";
import drawing_1318_2 from "./Exam_1318/1318_bodypos2.svg";
import drawing_1318_3 from "./Exam_1318/1318_centry.svg";
import drawing_1319_1 from "./Exam_1319/1319_bodypos1.svg";
import drawing_1319_2 from "./Exam_1319/1319_centry.svg";
import drawing_1320_1 from "./Exam_1320/1320_bodypos1.svg";
import drawing_1320_2 from "./Exam_1320/1320_centry.svg";
import drawing_1321_1 from "./Exam_1321/1321_bodypos1.svg";
import drawing_1321_2 from "./Exam_1321/1321_bodypos2.svg";
import drawing_1321_3 from "./Exam_1321/1321_centry.svg";
import drawing_1322_2 from "./Exam_1322/1322_bodypos2.svg";
import drawing_1322_3 from "./Exam_1322/1322_centry.svg";
import drawing_1323_1 from "./Exam_1323/1323_bodypos1.svg";
import drawing_1323_2 from "./Exam_1323/1323_centry.svg";
import drawing_1324_1 from "./Exam_1324/1324_bodypos1.svg";
import drawing_1324_2 from "./Exam_1324/1324_centry.svg";
import image_1325_1 from "./Exam_1325/1325_bodypos1_tpl.jpg";
import image_1325_2 from "./Exam_1325/1325_bodypos2_tpl.jpg";
import drawing_1325_1 from "./Exam_1325/1325_bodypos1.svg";
import drawing_1325_2 from "./Exam_1325/1325_bodypos2.svg";
import drawing_1325_3 from "./Exam_1325/1325_centry.svg";
import drawing_1326_1 from "./Exam_1326/1326_bodypos1.svg";
import drawing_1326_2 from "./Exam_1326/1326_bodypos2.svg";
import drawing_1326_3 from "./Exam_1326/1326_centry.svg";
import drawing_1327_1 from "./Exam_1327/1327_bodypos1.svg";
import drawing_1327_2 from "./Exam_1327/1327_centry.svg";
import drawing_1328_1 from "./Exam_1328/1328_bodypos1.svg";
import drawing_1328_2 from "./Exam_1328/1328_centry.svg";
import image_1329_1 from "./Exam_1329/1329_bodypos1_tpl.jpg";
import image_1329_2 from "./Exam_1329/1329_bodypos2_tpl.jpg";
import drawing_1329_1 from "./Exam_1329/1329_bodypos1.svg";
import drawing_1329_2 from "./Exam_1329/1329_bodypos2.svg";
import drawing_1329_3 from "./Exam_1329/1329_centry.svg";
import drawing_1330_1 from "./Exam_1330/1330_bodypos1.svg";
import drawing_1330_2 from "./Exam_1330/1330_bodypos2.svg";
import drawing_1330_3 from "./Exam_1330/1330_centry.svg";
import drawing_1331_1 from "./Exam_1331/1331_bodypos1.svg";
import drawing_1331_2 from "./Exam_1331/1331_bodypos2.svg";
import drawing_1331_3 from "./Exam_1331/1331_centry.svg";
import drawing_1332_1 from "./Exam_1332/1332_bodypos1.svg";
import drawing_1332_2 from "./Exam_1332/1332_bodypos2.svg";
import drawing_1332_3 from "./Exam_1332/1332_centry.svg";
import drawing_1333_1 from "./Exam_1333/1333_bodypos1.svg";
import drawing_1333_2 from "./Exam_1333/1333_bodypos2.svg";
import drawing_1333_3 from "./Exam_1333/1333_centry.svg";
import drawing_1334_1 from "./Exam_1334/1334_bodypos1.svg";
import drawing_1334_2 from "./Exam_1334/1334_bodypos2.svg";
import drawing_1334_3 from "./Exam_1334/1334_centry.svg";
import drawing_1337_1 from "./Exam_1337/1337_bodypos1.svg";
import drawing_1337_2 from "./Exam_1337/1337_bodypos2.svg";
import image_1337_1 from "./Exam_1337/1337_hand_seitenansicht.PNG";
import image_1337_2 from "./Exam_1337/1337_hand.PNG";
import drawing_1338_1 from "./Exam_1338/1338_bodypos1.svg";
import drawing_1338_2 from "./Exam_1338/1338_bodypos2.svg";
import drawing_1339_1 from "./Exam_1339/1339_bodypos1.svg";
import drawing_1339_2 from "./Exam_1339/1339_bodypos2.svg";
import drawing_1339_3 from "./Exam_1339/1339_centry.svg";
import drawing_1340_1 from "./Exam_1340/1340_bodypos1.svg";
import drawing_1340_2 from "./Exam_1340/1340_bodypos2.svg";
import drawing_1340_3 from "./Exam_1340/1340_centry.svg";
import drawing_1345_1 from "./Exam_1345/1345_bodypos1.svg";
import drawing_1345_2 from "./Exam_1345/1345_bodypos2.svg";
import drawing_1345_3 from "./Exam_1345/1345_centry.svg";
import drawing_1346_1 from "./Exam_1346/1346_bodypos1.svg";
import drawing_1346_2 from "./Exam_1346/1346_bodypos2.svg";
import drawing_1346_3 from "./Exam_1346/1346_centry.svg";
import drawing_1347_1 from "./Exam_1347/1347_bodypos1.svg";
import drawing_1347_2 from "./Exam_1347/1347_bodypos2.svg";
import drawing_1347_3 from "./Exam_1347/1347_bodypos3.svg";
import drawing_1347_4 from "./Exam_1347/1347_centry.svg";
import drawing_1348_1 from "./Exam_1348/1348_bodypos1.svg";
import drawing_1348_2 from "./Exam_1348/1348_bodypos2.svg";
import drawing_1348_3 from "./Exam_1348/1348_bodypos3.svg";
import drawing_1348_4 from "./Exam_1348/1348_centry.svg";
import drawing_1349_1 from "./Exam_1349/1349_bodypos1.svg";
import drawing_1349_2 from "./Exam_1349/1349_bodypos2.svg";
import drawing_1349_3 from "./Exam_1349/1349_bodypos3.svg";
import drawing_1349_4 from "./Exam_1349/1349_bodypos4.svg";
import drawing_1349_5 from "./Exam_1349/1349_centry.svg";
import drawing_1350_1 from "./Exam_1350/1350_bodypos1.svg";
import drawing_1350_2 from "./Exam_1350/1350_bodypos2.svg";
import drawing_1350_3 from "./Exam_1350/1350_bodypos3.svg";
import drawing_1350_4 from "./Exam_1350/1350_bodypos4.svg";
import drawing_1350_5 from "./Exam_1350/1350_centry.svg";
import drawing_1351_1 from "./Exam_1351/1351_bodypos1.svg";
import drawing_1351_2 from "./Exam_1351/1351_bodypos2.svg";
import drawing_1352_1 from "./Exam_1352/1352_bodypos1.svg";
import drawing_1352_2 from "./Exam_1352/1352_bodypos2.svg";
import drawing_1355_1 from "./Exam_1355/1355_bodypos1.svg";
import drawing_1355_2 from "./Exam_1355/1355_bodypos2.svg";
import drawing_1355_3 from "./Exam_1355/1355_bodypos3.svg";
import drawing_1355_4 from "./Exam_1355/1355_centry.svg";
import drawing_1356_1 from "./Exam_1356/1356_bodypos1.svg";
import drawing_1356_2 from "./Exam_1356/1356_bodypos2.svg";
import drawing_1356_3 from "./Exam_1356/1356_bodypos3.svg";
import drawing_1356_4 from "./Exam_1356/1356_centry.svg";
import drawing_1357_1 from "./Exam_1357/1357_bodypos1.svg";
import drawing_1357_2 from "./Exam_1357/1357_bodypos2.svg";
import drawing_1357_3 from "./Exam_1357/1357_centry.svg";
import drawing_1358_1 from "./Exam_1358/1358_bodypos1.svg";
import drawing_1358_2 from "./Exam_1358/1358_bodypos2.svg";
import drawing_1358_3 from "./Exam_1358/1358_centry.svg";
import drawing_1361_1 from "./Exam_1361/1361_bodypos1.svg";
import drawing_1361_2 from "./Exam_1361/1361_bodypos2.svg";
import drawing_1361_3 from "./Exam_1361/1361_bodypos3.svg";
import drawing_1361_4 from "./Exam_1361/1361_centry.svg";
import drawing_1362_1 from "./Exam_1362/1362_bodypos1.svg";
import drawing_1362_2 from "./Exam_1362/1362_bodypos2.svg";
import drawing_1362_3 from "./Exam_1362/1362_bodypos3.svg";
import drawing_1362_4 from "./Exam_1362/1362_centry.svg";
import drawing_1363_1 from "./Exam_1363/1363_bodypos1.svg";
import drawing_1363_2 from "./Exam_1363/1363_bodypos2.svg";
import drawing_1364_1 from "./Exam_1364/1364_bodypos1.svg";
import drawing_1364_2 from "./Exam_1364/1364_bodypos2.svg";
import drawing_1369_1 from "./Exam_1369/1369_bodypos1.svg";
import drawing_1369_2 from "./Exam_1369/1369_centry.svg";
import drawing_1370_1 from "./Exam_1370/1370_bodypos1.svg";
import drawing_1370_2 from "./Exam_1370/1370_centry.svg";
import drawing_1371_1 from "./Exam_1371/1371_bodypos1.svg";
import drawing_1371_2 from "./Exam_1371/1371_centry.svg";
import drawing_1372_1 from "./Exam_1372/1372_bodypos1.svg";
import drawing_1372_2 from "./Exam_1372/1372_centry.svg";
import drawing_1373_1 from "./Exam_1373/1373_bodypos1.svg";
import drawing_1373_2 from "./Exam_1373/1373_centry.svg";
import drawing_1374_1 from "./Exam_1374/1374_bodypos1.svg";
import drawing_1374_2 from "./Exam_1374/1374_centry.svg";
import drawing_1375_1 from "./Exam_1375/1375_bodypos1.svg";
import drawing_1375_2 from "./Exam_1375/1375_centry.svg";
import drawing_1376_1 from "./Exam_1376/1376_bodypos1.svg";
import drawing_1376_2 from "./Exam_1376/1376_centry.svg";
import drawing_1377_1 from "./Exam_1377/1377_bodypos1.svg";
import drawing_1377_2 from "./Exam_1377/1377_bodypos2.svg";
import drawing_1377_3 from "./Exam_1377/1377_centry.svg";
import drawing_1378_1 from "./Exam_1378/1378_bodypos1.svg";
import drawing_1378_2 from "./Exam_1378/1378_bodypos2.svg";
import drawing_1378_3 from "./Exam_1378/1378_centry.svg";
import drawing_1401_2 from "./Exam_1401/1401_bodypos2.svg";
import drawing_1401_1 from "./Exam_1401/1401_bodypos1.svg";
import drawing_1401 from "./Exam_1401/1401_patpos.svg";
import drawing_1402 from "./Exam_1402/1402_patpos.svg";
import drawing_1402_1 from "./Exam_1402/1402_bodypos1.svg";
import drawing_1402_2 from "./Exam_1402/1402_bodypos2.svg";
import drawing_1403_1 from "./Exam_1403/1403_bodypos2.svg";
import drawing_1404_1 from "./Exam_1404/1404_bodypos2.svg";
import drawing_1405 from "./Exam_1405/1405_patpos.svg";
import drawing_1405_1 from "./Exam_1405/1405_bodypos1.svg";
import drawing_1405_3 from "./Exam_1405/1405_bodypos3.svg";
import drawing_1406 from "./Exam_1406/1406_patpos.svg";
import drawing_1406_1 from "./Exam_1406/1406_bodypos1.svg";
import drawing_1406_3 from "./Exam_1406/1406_bodypos3.svg";
import drawing_1407_2 from "./Exam_1407/1407_bodypos2.svg";
import drawing_1408_2 from "./Exam_1408/1408_bodypos2.svg";
import drawing_1409 from "./Exam_1409/1409_patpos.svg";
import drawing_1409_1 from "./Exam_1409/1409_bodypos1.svg";
import drawing_1409_2 from "./Exam_1409/1409_bodypos2.svg";
import drawing_1410_2 from "./Exam_1410/1410_bodypos2.svg";
import drawing_1411_2 from "./Exam_1411/1411_bodypos2.svg";
import drawing_1412_2 from "./Exam_1412/1412_bodypos2.svg";
import drawing_1413_2 from "./Exam_1413/1413_bodypos2.svg";
import drawing_1414_2 from "./Exam_1414/1414_bodypos2.svg";
import drawing_1415_2 from "./Exam_1415/1415_bodypos2.svg";
import drawing_1416_2 from "./Exam_1416/1416_bodypos2.svg";
import drawing_1417_2 from "./Exam_1417/1417_bodypos2.svg";
import drawing_1418_2 from "./Exam_1418/1418_bodypos2.svg";
import drawing_1419_2 from "./Exam_1419/1419_bodypos2.svg";
import drawing_1420_2 from "./Exam_1420/1420_bodypos2.svg";
import drawing_1422 from "./Exam_1422/1422_patpos.svg";
import drawing_1427 from "./Exam_1427/1427_patpos.svg";
import drawing_1428 from "./Exam_1428/1428_patpos.svg";
import drawing_1445 from "./Exam_1445/1445_patpos.svg";
import drawing_1445_1 from "./Exam_1445/1445_bodypos1.svg";
import drawing_1446 from "./Exam_1446/1446_patpos.svg";
import drawing_1446_1 from "./Exam_1446/1446_bodypos1.svg";
import drawing_1471 from "./Exam_1471/1471_patpos.svg";
import drawing_1471_2 from "./Exam_1471/1471_bodypos2.svg";
import drawing_1471_3 from "./Exam_1471/1471_centry.svg";
import drawing_1472_3 from "./Exam_1472/1472_centry.svg";
import drawing_1501 from "./Exam_1501/1501_patpos.svg";
import drawing_1501_1 from "./Exam_1501/1501_bodypos1.svg";
import drawing_1501_2 from "./Exam_1501/1501_bodypos2.svg";
import drawing_1501_3 from "./Exam_1501/1501_centry.svg";
import drawing_1502 from "./Exam_1502/1502_patpos.svg";
import drawing_1502_1 from "./Exam_1502/1502_bodypos1.svg";
import drawing_1502_2 from "./Exam_1502/1502_bodypos2.svg";
import drawing_1502_3 from "./Exam_1502/1502_centry.svg";
import drawing_1503 from "./Exam_1503/1503_patpos.svg";
import drawing_1503_1 from "./Exam_1503/1503_bodypos1.svg";
import drawing_1503_2 from "./Exam_1503/1503_bodypos2.svg";
import drawing_1503_3 from "./Exam_1503/1503_centry.svg";
import drawing_1504 from "./Exam_1504/1504_patpos.svg";
import drawing_1504_2 from "./Exam_1504/1504_bodypos2.svg";
import drawing_1504_3 from "./Exam_1504/1504_centry.svg";
import drawing_1505 from "./Exam_1505/1505_patpos.svg";
import drawing_1505_1 from "./Exam_1505/1505_bodypos1.svg";
import drawing_1505_2 from "./Exam_1505/1505_bodypos2.svg";
import drawing_1505_4 from "./Exam_1505/1505_centry.svg";
import drawing_1506 from "./Exam_1506/1506_patpos.svg";
import drawing_1506_1 from "./Exam_1506/1506_bodypos1.svg";
import drawing_1507 from "./Exam_1507/1507_patpos.svg";
import drawing_1507_1 from "./Exam_1507/1507_bodypos1.svg";
import drawing_1507_2 from "./Exam_1507/1507_bodypos2.svg";
import drawing_1507_3 from "./Exam_1507/1507_centry.svg";
import drawing_1508 from "./Exam_1508/1508_patpos.svg";
import drawing_1508_1 from "./Exam_1508/1508_bodypos1.svg";
import drawing_1508_2 from "./Exam_1508/1508_bodypos2.svg";
import drawing_1508_3 from "./Exam_1508/1508_centry.svg";
import drawing_1509_1 from "./Exam_1509/1509_bodypos1.svg";
import drawing_1509_2 from "./Exam_1509/1509_bodypos2.svg";
import drawing_1509_3 from "./Exam_1509/1509_centry.svg";
import drawing_1510_1 from "./Exam_1510/1510_bodypos1.svg";
import drawing_1510_2 from "./Exam_1510/1510_bodypos2.svg";
import drawing_1510_3 from "./Exam_1510/1510_centry.svg";
import drawing_1511_1 from "./Exam_1511/1511_bodypos1.svg";
import drawing_1511_2 from "./Exam_1511/1511_bodypos2.svg";
import drawing_1511_3 from "./Exam_1511/1511_centry.svg";
import drawing_1512_1 from "./Exam_1512/1512_bodypos1.svg";
import drawing_1512_2 from "./Exam_1512/1512_bodypos2.svg";
import drawing_1512_3 from "./Exam_1512/1512_centry.svg";
import drawing_1513_1 from "./Exam_1513/1513_bodypos1.svg";
import drawing_1513_2 from "./Exam_1513/1513_bodypos2.svg";
import drawing_1513_3 from "./Exam_1513/1513_centry.svg";
import drawing_1514_1 from "./Exam_1514/1514_bodypos1.svg";
import drawing_1514_2 from "./Exam_1514/1514_bodypos2.svg";
import drawing_1514_3 from "./Exam_1514/1514_centry.svg";
import drawing_1515_1 from "./Exam_1515/1515_bodypos1.svg";
import drawing_1515_2 from "./Exam_1515/1515_bodypos2.svg";
import drawing_1515_3 from "./Exam_1515/1515_centry.svg";
import drawing_1516_1 from "./Exam_1516/1516_bodypos1.svg";
import drawing_1516_2 from "./Exam_1516/1516_bodypos2.svg";
import drawing_1516_3 from "./Exam_1516/1516_centry.svg";
import drawing_1517_1 from "./Exam_1517/1517_bodypos1.svg";
import drawing_1517_2 from "./Exam_1517/1517_bodypos2.svg";
import drawing_1517_3 from "./Exam_1517/1517_centry.svg";
import drawing_1518_1 from "./Exam_1518/1518_bodypos1.svg";
import drawing_1518_2 from "./Exam_1518/1518_bodypos2.svg";
import drawing_1518_3 from "./Exam_1518/1518_centry.svg";
import drawing_1519_1 from "./Exam_1519/1519_bodypos1.svg";
import drawing_1519_2 from "./Exam_1519/1519_bodypos2.svg";
import drawing_1519_3 from "./Exam_1519/1519_centry.svg";
import drawing_1520_1 from "./Exam_1520/1520_bodypos1.svg";
import drawing_1520_2 from "./Exam_1520/1520_bodypos2.svg";
import drawing_1520_3 from "./Exam_1520/1520_centry.svg";
import drawing_1521_1 from "./Exam_1521/1521_bodypos1.svg";
import drawing_1521_2 from "./Exam_1521/1521_bodypos2.svg";
import drawing_1521_3 from "./Exam_1521/1521_centry.svg";
import drawing_1522_1 from "./Exam_1522/1522_bodypos1.svg";
import drawing_1522_2 from "./Exam_1522/1522_bodypos2.svg";
import drawing_1522_3 from "./Exam_1522/1522_centry.svg";
import drawing_1523_1 from "./Exam_1523/1523_bodypos1.svg";
import drawing_1523_2 from "./Exam_1523/1523_bodypos2.svg";
import drawing_1523_3 from "./Exam_1523/1523_centry.svg";
import drawing_1524_1 from "./Exam_1524/1524_bodypos1.svg";
import drawing_1524_2 from "./Exam_1524/1524_bodypos2.svg";
import drawing_1524_3 from "./Exam_1524/1524_centry.svg";
import drawing_1525_1 from "./Exam_1525/1525_bodypos1.svg";
import drawing_1525_2 from "./Exam_1525/1525_bodypos2.svg";
import drawing_1525_3 from "./Exam_1525/1525_centry.svg";
import drawing_1526_1 from "./Exam_1526/1526_bodypos1.svg";
import drawing_1526_2 from "./Exam_1526/1526_bodypos2.svg";
import drawing_1526_3 from "./Exam_1526/1526_centry.svg";
import drawing_1527_1 from "./Exam_1527/1527_bodypos1.svg";
import drawing_1527_2 from "./Exam_1527/1527_bodypos2.svg";
import drawing_1527_3 from "./Exam_1527/1527_centry.svg";
import drawing_1528_1 from "./Exam_1528/1528_bodypos1.svg";
import drawing_1528_2 from "./Exam_1528/1528_bodypos2.svg";
import drawing_1528_3 from "./Exam_1528/1528_centry.svg";
import drawing_1529_1 from "./Exam_1529/1529_bodypos1.svg";
import drawing_1529_2 from "./Exam_1529/1529_bodypos2.svg";
import drawing_1529_3 from "./Exam_1529/1529_centry.svg";
import drawing_1530_1 from "./Exam_1530/1530_bodypos1.svg";
import drawing_1530_2 from "./Exam_1530/1530_bodypos2.svg";
import drawing_1530_3 from "./Exam_1530/1530_centry.svg";
import drawing_1531_1 from "./Exam_1531/1531_bodypos1.svg";
import drawing_1531_2 from "./Exam_1531/1531_bodypos2.svg";
import drawing_1531_3 from "./Exam_1531/1531_centry.svg";
import drawing_1532 from "./Exam_1532/1532_patpos.svg";
import drawing_1532_1 from "./Exam_1532/1532_bodypos1.svg";
import drawing_1532_2 from "./Exam_1532/1532_bodypos2.svg";
import drawing_1532_3 from "./Exam_1532/1532_centry.svg";
import drawing_1533_1 from "./Exam_1533/1533_bodypos1.svg";
import drawing_1533_2 from "./Exam_1533/1533_bodypos2.svg";
import drawing_1533_3 from "./Exam_1533/1533_centry.svg";
import drawing_1534 from "./Exam_1534/1534_patpos.svg";
import drawing_1534_1 from "./Exam_1534/1534_bodypos1.svg";
import drawing_1534_2 from "./Exam_1534/1534_bodypos2.svg";
import drawing_1534_3 from "./Exam_1534/1534_centry.svg";
import drawing_1536 from "./Exam_1536/1536_patpos.svg";
import drawing_1701 from "./Exam_1701/1701_patpos.svg";
import drawing_1701_1 from "./Exam_1701/1701_bodypos1.svg";
import image_1701_1 from "./Exam_1701/1701_bodypos1_tpl.jpg";
import drawing_1702 from "./Exam_1702/1702_patpos.svg";
import drawing_1702_1 from "./Exam_1702/1702_bodypos1.svg";
import drawing_1702_2 from "./Exam_1702/1702_bodypos2.svg";
import drawing_1702_3 from "./Exam_1702/1702_centry.svg";
import drawing_1703_1 from "./Exam_1703/1703_bodypos1.svg";
import drawing_1704_1 from "./Exam_1704/1704_bodypos1.svg";
import drawing_1704_2 from "./Exam_1704/1704_bodypos2.svg";
import drawing_1704_3 from "./Exam_1704/1704_centry.svg";
import drawing_1705_1 from "./Exam_1705/1705_bodypos1.svg";
import drawing_1705_2 from "./Exam_1705/1705_bodypos2.svg";
import drawing_1705_3 from "./Exam_1705/1705_bodypos3.svg";
import drawing_1705_4 from "./Exam_1705/1705_centry.svg";
import drawing_1706_1 from "./Exam_1706/1706_bodypos1.svg";
import drawing_1706_2 from "./Exam_1706/1706_bodypos2.svg";
import drawing_1706 from "./Exam_1706/1706_patpos.svg";
import drawing_1707 from "./Exam_1707/1707_patpos.svg";
import drawing_1707_1 from "./Exam_1707/1707_bodypos1.svg";
import drawing_1707_2 from "./Exam_1707/1707_bodypos2.svg";
import drawing_1707_3 from "./Exam_1707/1707_centry.svg";
import drawing_1708_1 from "./Exam_1708/1708_bodypos1.svg";
import drawing_1708_2 from "./Exam_1708/1708_bodypos2.svg";
import drawing_1708_3 from "./Exam_1708/1708_centry.svg";
import drawing_1724 from "./Exam_1724/1724_patpos.svg";
import drawing_1724_1 from "./Exam_1724/1724_bodypos1.svg";
import drawing_1801 from "./Exam_1801/1801_patpos.svg";
import drawing_1801_1 from "./Exam_1801/1801_bodypos1.svg";
import drawing_1801_2 from "./Exam_1801/1801_bodypos2.svg";
import drawing_1801_3 from "./Exam_1801/1801_bodypos3.svg";
import drawing_1801_4 from "./Exam_1801/1801_centry.svg";
import drawing_1802_1 from "./Exam_1802/1802_bodypos1.svg";
import drawing_1802_2 from "./Exam_1802/1802_bodypos2.svg";
import drawing_1802_3 from "./Exam_1802/1802_bodypos3.svg";
import drawing_1802_4 from "./Exam_1802/1802_centry.svg";
import drawing_1803 from "./Exam_1803/1803_patpos.svg";
import drawing_1803_1 from "./Exam_1803/1803_bodypos1.svg";
import drawing_1803_2 from "./Exam_1803/1803_bodypos2.svg";
import drawing_1803_3 from "./Exam_1803/1803_bodypos3.svg";
import drawing_1803_4 from "./Exam_1803/1803_centry.svg";
import drawing_1804_1 from "./Exam_1804/1804_bodypos1.svg";
import drawing_1804_2 from "./Exam_1804/1804_bodypos2.svg";
import drawing_1804_3 from "./Exam_1804/1804_bodypos3.svg";
import drawing_1804_4 from "./Exam_1804/1804_centry.svg";
import drawing_1805_1 from "./Exam_1805/1805_bodypos1.svg";
import drawing_1805_2 from "./Exam_1805/1805_bodypos2.svg";
import drawing_1805_3 from "./Exam_1805/1805_bodypos3.svg";
import drawing_1805_4 from "./Exam_1805/1805_bodypos4.svg";
import drawing_1805_5 from "./Exam_1805/1805_centry.svg";
import drawing_1806_1 from "./Exam_1806/1806_bodypos1.svg";
import drawing_1806_2 from "./Exam_1806/1806_bodypos2.svg";
import drawing_1806_3 from "./Exam_1806/1806_bodypos3.svg";
import drawing_1806_4 from "./Exam_1806/1806_bodypos4.svg";
import drawing_1806_5 from "./Exam_1806/1806_centry.svg";
import drawing_1807 from "./Exam_1807/1807_patpos.svg";
import drawing_1807_1 from "./Exam_1807/1807_bodypos1.svg";
import drawing_1807_2 from "./Exam_1807/1807_bodypos2.svg";
import drawing_1807_3 from "./Exam_1807/1807_bodypos3.svg";
import drawing_1807_4 from "./Exam_1807/1807_centry.svg";
import drawing_1808_1 from "./Exam_1808/1808_bodypos1.svg";
import drawing_1808_2 from "./Exam_1808/1808_bodypos2.svg";
import drawing_1808_3 from "./Exam_1808/1808_bodypos3.svg";
import drawing_1808_4 from "./Exam_1808/1808_centry.svg";
import drawing_1809 from "./Exam_1809/1809_patpos.svg";
import drawing_1809_1 from "./Exam_1809/1809_bodypos1.svg";
import drawing_1809_2 from "./Exam_1809/1809_bodypos2.svg";
import drawing_1809_3 from "./Exam_1809/1809_bodypos3.svg";
import drawing_1809_4 from "./Exam_1809/1809_centry.svg";
import drawing_1810_1 from "./Exam_1810/1810_bodypos1.svg";
import drawing_1810_2 from "./Exam_1810/1810_bodypos2.svg";
import drawing_1810_3 from "./Exam_1810/1810_bodypos3.svg";
import drawing_1810_4 from "./Exam_1810/1810_centry.svg";
import drawing_1811_1 from "./Exam_1811/1811_bodypos1.svg";
import drawing_1811_2 from "./Exam_1811/1811_bodypos2.svg";
import drawing_1811_3 from "./Exam_1811/1811_bodypos3.svg";
import drawing_1811_4 from "./Exam_1811/1811_bodypos4.svg";
import drawing_1811_5 from "./Exam_1811/1811_centry.svg";
import drawing_1812_1 from "./Exam_1812/1812_bodypos1.svg";
import drawing_1812_2 from "./Exam_1812/1812_bodypos2.svg";
import drawing_1812_3 from "./Exam_1812/1812_bodypos3.svg";
import drawing_1812_4 from "./Exam_1812/1812_bodypos4.svg";
import drawing_1812_5 from "./Exam_1812/1812_centry.svg";
import drawing_1813_1 from "./Exam_1813/1813_bodypos1.svg";
import drawing_1813_2 from "./Exam_1813/1813_bodypos2.svg";
import drawing_1813_3 from "./Exam_1813/1813_bodypos3.svg";
import drawing_1813_4 from "./Exam_1813/1813_centry.svg";
import drawing_1814_1 from "./Exam_1814/1814_bodypos1.svg";
import drawing_1814_2 from "./Exam_1814/1814_bodypos2.svg";
import drawing_1814_3 from "./Exam_1814/1814_bodypos3.svg";
import drawing_1814_4 from "./Exam_1814/1814_centry.svg";
import drawing_1815_1 from "./Exam_1815/1815_bodypos1.svg";
import drawing_1815_2 from "./Exam_1815/1815_bodypos2.svg";
import drawing_1815_3 from "./Exam_1815/1815_bodypos3.svg";
import drawing_1815_4 from "./Exam_1815/1815_centry.svg";
import drawing_1816_1 from "./Exam_1816/1816_bodypos1.svg";
import drawing_1816_2 from "./Exam_1816/1816_bodypos2.svg";
import drawing_1816_3 from "./Exam_1816/1816_bodypos3.svg";
import drawing_1816_4 from "./Exam_1816/1816_centry.svg";
import drawing_2001_1 from "./Exam_2001/2001_bodypos1.svg";
import drawing_2001_2 from "./Exam_2001/2001_bodypos2.svg";
import drawing_2001_3 from "./Exam_2001/2001_centry.svg";
import drawing_2002_1 from "./Exam_2002/2002_bodypos1.svg";
import drawing_2002_2 from "./Exam_2002/2002_bodypos2.svg";
import drawing_2002_3 from "./Exam_2002/2002_centry.svg";
import drawing_2003_1 from "./Exam_2003/2003_bodypos1.svg";
import drawing_2003_2 from "./Exam_2003/2003_bodypos2.svg";
import drawing_2003_3 from "./Exam_2003/2003_bodypos3.svg";
import drawing_2003_4 from "./Exam_2003/2003_centry.svg";
import drawing_2004_1 from "./Exam_2004/2004_bodypos1.svg";
import drawing_2004_2 from "./Exam_2004/2004_bodypos2.svg";
import drawing_2004_3 from "./Exam_2004/2004_bodypos3.svg";
import drawing_2004_4 from "./Exam_2004/2004_centry.svg";
import drawing_2005_1 from "./Exam_2005/2005_bodypos1.svg";
import drawing_2005_2 from "./Exam_2005/2005_bodypos2.svg";
import drawing_2005_3 from "./Exam_2005/2005_bodypos3.svg";
import drawing_2005_5 from "./Exam_2005/2005_bodypos4.svg";
import drawing_2005_4 from "./Exam_2005/2005_centry.svg";
import drawing_2006_1 from "./Exam_2006/2006_bodypos1.svg";
import drawing_2006_2 from "./Exam_2006/2006_bodypos2.svg";
import drawing_2006_3 from "./Exam_2006/2006_bodypos3.svg";
import drawing_2006_4 from "./Exam_2006/2006_bodypos4.svg";
import drawing_2006_5 from "./Exam_2006/2006_centry.svg";
import drawing_2007_1 from "./Exam_2007/2007_bodypos1.svg";
import drawing_2007_2 from "./Exam_2007/2007_bodypos2.svg";
import drawing_2007_3 from "./Exam_2007/2007_bodypos3.svg";
import drawing_2007_4 from "./Exam_2007/2007_bodypos4.svg";
import drawing_2007_5 from "./Exam_2007/2007_centry.svg";
import drawing_2008_1 from "./Exam_2008/2008_bodypos1.svg";
import drawing_2008_2 from "./Exam_2008/2008_bodypos2.svg";
import drawing_2008_3 from "./Exam_2008/2008_bodypos3.svg";
import drawing_2008_4 from "./Exam_2008/2008_bodypos4.svg";
import drawing_2008_5 from "./Exam_2008/2008_centry.svg";
import drawing_2009_1 from "./Exam_2009/2009_bodypos1.svg";
import drawing_2009_2 from "./Exam_2009/2009_bodypos2.svg";
import drawing_2009_4 from "./Exam_2009/2009_bodypos4.svg";
import drawing_2009_5 from "./Exam_2009/2009_centry.svg";
import drawing_2010_1 from "./Exam_2010/2010_bodypos1.svg";
import drawing_2010_2 from "./Exam_2010/2010_bodypos2.svg";
import drawing_2010_4 from "./Exam_2010/2010_bodypos4.svg";
import drawing_2010_5 from "./Exam_2010/2010_centry.svg";
import drawing_2011 from "./Exam_2011/2011_patpos.svg";
import drawing_2011_1 from "./Exam_2011/2011_bodypos1.svg";
import drawing_2011_2 from "./Exam_2011/2011_bodypos2.svg";
import drawing_2011_3 from "./Exam_2011/2011_bodypos3.svg";
import drawing_2011_4 from "./Exam_2011/2011_centry.svg";
import drawing_2012 from "./Exam_2012/2012_patpos.svg";
import drawing_2012_1 from "./Exam_2012/2012_bodypos1.svg";
import drawing_2012_2 from "./Exam_2012/2012_bodypos2.svg";
import drawing_2012_3 from "./Exam_2012/2012_bodypos3.svg";
import drawing_2012_4 from "./Exam_2012/2012_centry.svg";
import drawing_2013 from "./Exam_2013/2013_patpos.svg";
import drawing_2013_1 from "./Exam_2013/2013_bodypos1.svg";
import drawing_2013_2 from "./Exam_2013/2013_bodypos2.svg";
import drawing_2013_3 from "./Exam_2013/2013_bodypos3.svg";
import drawing_2013_4 from "./Exam_2013/2013_centry.svg";
import drawing_2014 from "./Exam_2014/2014_patpos.svg";
import drawing_2014_1 from "./Exam_2014/2014_bodypos1.svg";
import drawing_2014_2 from "./Exam_2014/2014_bodypos2.svg";
import drawing_2014_3 from "./Exam_2014/2014_centry.svg";
import drawing_2015_1 from "./Exam_2015/2015_bodypos1.svg";
import drawing_2015_2 from "./Exam_2015/2015_bodypos2.svg";
import drawing_2015_3 from "./Exam_2015/2015_bodypos3.svg";
import drawing_2015_4 from "./Exam_2015/2015_centry.svg";
import drawing_2016_1 from "./Exam_2016/2016_bodypos1.svg";
import drawing_2016_2 from "./Exam_2016/2016_bodypos2.svg";
import drawing_2016_3 from "./Exam_2016/2016_bodypos3.svg";
import drawing_2016_4 from "./Exam_2016/2016_centry.svg";
import drawing_2017_1 from "./Exam_2017/2017_bodypos1.svg";
import drawing_2017_2 from "./Exam_2017/2017_bodypos2.svg";
import drawing_2017_3 from "./Exam_2017/2017_centry.svg";
import drawing_2018_1 from "./Exam_2018/2018_bodypos1.svg";
import drawing_2018_2 from "./Exam_2018/2018_bodypos2.svg";
import drawing_2018_3 from "./Exam_2018/2018_centry.svg";
import drawing_2019_1 from "./Exam_2019/2019_bodypos1.svg";
import drawing_2019_2 from "./Exam_2019/2019_centry.svg";
import drawing_2020_1 from "./Exam_2020/2020_bodypos1.svg";
import drawing_2020_2 from "./Exam_2020/2020_centry.svg";
import drawing_2021_1 from "./Exam_2021/2021_bodypos1.svg";
import drawing_2021_2 from "./Exam_2021/2021_centry.svg";
import drawing_2022_1 from "./Exam_2022/2022_bodypos1.svg";
import drawing_2022_2 from "./Exam_2022/2022_centry.svg";
import drawing_2023_1 from "./Exam_2023/2023_bodypos1.svg";
import drawing_2023_2 from "./Exam_2023/2023_bodypos2.svg";
import drawing_2023_3 from "./Exam_2023/2023_centry.svg";
import drawing_2024_1 from "./Exam_2024/2024_bodypos1.svg";
import drawing_2024_2 from "./Exam_2024/2024_bodypos2.svg";
import drawing_2024_3 from "./Exam_2024/2024_centry.svg";
import drawing_2025_1 from "./Exam_2025/2025_bodypos1.svg";
import drawing_2025_2 from "./Exam_2025/2025_bodypos2.svg";
import drawing_2025_3 from "./Exam_2025/2025_centry.svg";
import drawing_2026_1 from "./Exam_2026/2026_bodypos1.svg";
import drawing_2026_2 from "./Exam_2026/2026_bodypos2.svg";
import drawing_2026_3 from "./Exam_2026/2026_centry.svg";
import drawing_2027_1 from "./Exam_2027/2027_bodypos1.svg";
import drawing_2027_2 from "./Exam_2027/2027_bodypos2.svg";
import drawing_2027_3 from "./Exam_2027/2027_centry.svg";
import drawing_2028_1 from "./Exam_2028/2028_bodypos1.svg";
import drawing_2028_2 from "./Exam_2028/2028_bodypos2.svg";
import drawing_2028_3 from "./Exam_2028/2028_centry.svg";
import drawing_2029 from "./Exam_2029/2029_patpos.svg";
import drawing_2029_1 from "./Exam_2029/2029_bodypos1.svg";
import drawing_2029_2 from "./Exam_2029/2029_centry.svg";
import drawing_2032_1 from "./Exam_2032/2032_bodypos1.svg";
import drawing_2032_2 from "./Exam_2032/2032_bodypos2.svg";
import drawing_2032_3 from "./Exam_2032/2032_centry.svg";
import drawing_2032 from "./Exam_2032/2032_patpos.svg";
import drawing_2033_1 from "./Exam_2033/2033_bodypos1.svg";
import drawing_2033_2 from "./Exam_2033/2033_bodypos2.svg";
import drawing_2033_3 from "./Exam_2033/2033_centry.svg";
import drawing_2034 from "./Exam_2034/2034_patpos.svg";
import drawing_2034_1 from "./Exam_2034/2034_bodypos1.svg";
import drawing_2034_2 from "./Exam_2034/2034_bodypos2.svg";
import drawing_2034_3 from "./Exam_2034/2034_centry.svg";
import drawing_2035_1 from "./Exam_2035/2035_bodypos1.svg";
import drawing_2035_2 from "./Exam_2035/2035_bodypos2.svg";
import drawing_2035_3 from "./Exam_2035/2035_centry.svg";
import drawing_2201_1 from "./Exam_2201/2201_bodypos1.svg";
import drawing_2201_2 from "./Exam_2201/2201_centry.svg";
import drawing_2202_1 from "./Exam_2202/2202_bodypos1.svg";
import drawing_2202_2 from "./Exam_2202/2202_centry.svg";
import drawing_2203_1 from "./Exam_2203/2203_bodypos1.svg";
import drawing_2203_2 from "./Exam_2203/2203_centry.svg";
import drawing_2204_1 from "./Exam_2204/2204_bodypos1.svg";
import drawing_2204_2 from "./Exam_2204/2204_centry.svg";
import drawing_2205_1 from "./Exam_2205/2205_bodypos1.svg";
import drawing_2205_2 from "./Exam_2205/2205_centry.svg";
import drawing_2206_1 from "./Exam_2206/2206_bodypos1.svg";
import drawing_2206_2 from "./Exam_2206/2206_centry.svg";
import drawing_2207_1 from "./Exam_2207/2207_bodypos1.svg";
import drawing_2207_2 from "./Exam_2207/2207_bodypos2.svg";
import drawing_2207_3 from "./Exam_2207/2207_centry.svg";
import drawing_2208_1 from "./Exam_2208/2208_bodypos1.svg";
import drawing_2208_2 from "./Exam_2208/2208_bodypos2.svg";
import drawing_2208_3 from "./Exam_2208/2208_centry.svg";
import drawing_2209_1 from "./Exam_2209/2209_bodypos1.svg";
import drawing_2209_2 from "./Exam_2209/2209_bodypos2.svg";
import drawing_2209_3 from "./Exam_2209/2209_centry.svg";
import drawing_2210_1 from "./Exam_2210/2210_bodypos1.svg";
import drawing_2210_2 from "./Exam_2210/2210_bodypos2.svg";
import drawing_2210_3 from "./Exam_2210/2210_centry.svg";
import drawing_2211_1 from "./Exam_2211/2211_bodypos1.svg";
import drawing_2211_2 from "./Exam_2211/2211_bodypos2.svg";
import drawing_2211_3 from "./Exam_2211/2211_centry.svg";
import drawing_2212_1 from "./Exam_2212/2212_bodypos1.svg";
import drawing_2212_2 from "./Exam_2212/2212_bodypos2.svg";
import drawing_2212_3 from "./Exam_2212/2212_centry.svg";
import drawing_2213_1 from "./Exam_2213/2213_bodypos1.svg";
import drawing_2213_2 from "./Exam_2213/2213_bodypos2.svg";
import drawing_2213_3 from "./Exam_2213/2213_centry.svg";
import drawing_2214_1 from "./Exam_2214/2214_bodypos1.svg";
import drawing_2214_2 from "./Exam_2214/2214_bodypos2.svg";
import drawing_2214_3 from "./Exam_2214/2214_centry.svg";
import drawing_2223_1 from "./Exam_2223/2223_bodypos1.svg";
import drawing_2223_2 from "./Exam_2223/2223_bodypos2.svg";
import drawing_2223_3 from "./Exam_2223/2223_centry.svg";
import drawing_2224_1 from "./Exam_2224/2224_bodypos1.svg";
import drawing_2224_2 from "./Exam_2224/2224_bodypos2.svg";
import drawing_2224_3 from "./Exam_2224/2224_centry.svg";
import drawing_2225_1 from "./Exam_2225/2225_bodypos1.svg";
import drawing_2225_2 from "./Exam_2225/2225_bodypos2.svg";
import drawing_2225_3 from "./Exam_2225/2225_bodypos3.svg";
import drawing_2225_4 from "./Exam_2225/2225_centry.svg";
import drawing_2226_1 from "./Exam_2226/2226_bodypos1.svg";
import drawing_2226_2 from "./Exam_2226/2226_bodypos2.svg";
import drawing_2226_3 from "./Exam_2226/2226_bodypos3.svg";
import drawing_2226_4 from "./Exam_2226/2226_centry.svg";
import drawing_2301_1 from "./Exam_2301/2301_bodypos1.svg";
import drawing_2301_2 from "./Exam_2301/2301_bodypos2.svg";
import drawing_2301_3 from "./Exam_2301/2301_bodypos3.svg";
import drawing_2301_4 from "./Exam_2301/2301_centry.svg";
import drawing_2302 from "./Exam_2302/2302_patpos.svg";
import drawing_2302_1 from "./Exam_2302/2302_bodypos1.svg";
import drawing_2302_2 from "./Exam_2302/2302_bodypos2.svg";
import drawing_2302_3 from "./Exam_2302/2302_bodypos3.svg";
import drawing_2302_4 from "./Exam_2302/2302_centry.svg";
import drawing_2307_1 from "./Exam_2307/2307_bodypos1.svg";
import drawing_2307_4 from "./Exam_2307/2307_centry.svg";
import drawing_2308_1 from "./Exam_2308/2308_bodypos1.svg";
import drawing_2308_4 from "./Exam_2308/2308_centry.svg";

// end 

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  testImage1,
  testImage2,
  drawing_1101,
  drawing_1101_1,
  drawing_1102,
  drawing_1102_1,
  drawing_1201,
  drawing_1201_1,
  drawing_1201_2,
  drawing_1201_3,
  drawing_1203,
  drawing_1203_1,
  drawing_1204,
  drawing_1204_1,
  drawing_1204_2,
  drawing_1205,
  drawing_1205_1,
  drawing_1205_2,
  drawing_1206,
  drawing_1206_1,
  drawing_1206_2,
  drawing_1207,
  drawing_1207_1,
  drawing_1207_2,
  drawing_1208,
  drawing_1208_1,
  drawing_1208_2,
  drawing_1208_3,
  drawing_1209,
  drawing_1209_1,
  drawing_1209_2,
  drawing_1209_3,
  image_1315_1,
  image_1315_2,
  image_1315_3,
  drawing_1315_1,
  drawing_1315_2,
  drawing_1315_3,
  drawing_1301_1,
  drawing_1301_2,
  drawing_1302_1,
  drawing_1302_2,
  image_1303_1,
  drawing_1303_1,
  drawing_1303_2,
  drawing_1304_1,
  drawing_1304_2,
  image_1305_1,
  image_1305_2,
  drawing_1305_1,
  drawing_1305_2,
  drawing_1306_1,
  drawing_1306_2,
  image_1307_1,
  image_1307_2,
  image_1307_3,
  image_1307_4,
  drawing_1307_1,
  drawing_1308_1,
  image_1309_1,
  drawing_1309_1,
  drawing_1310_1,
  image_1311_1,
  drawing_1311_1,
  drawing_1312_1,
  image_1313_1,
  drawing_1313_1,
  drawing_1314_1,
  drawing_1316_1,
  drawing_1316_2,
  image_1317_1,
  image_1317_2,
  drawing_1317_1,
  drawing_1317_2,
  drawing_1317_3,
  drawing_1318_1,
  drawing_1318_2,
  drawing_1318_3,
  drawing_1319_1,
  drawing_1319_2,
  drawing_1320_1,
  drawing_1320_2,
  drawing_1321_1,
  drawing_1321_2,
  drawing_1321_3,
  drawing_1322_2,
  drawing_1322_3,
  drawing_1323_1,
  drawing_1323_2,
  drawing_1324_1,
  drawing_1324_2,
  image_1325_1,
  image_1325_2,
  drawing_1325_1,
  drawing_1325_2,
  drawing_1325_3,
  drawing_1326_1,
  drawing_1326_2,
  drawing_1326_3,
  drawing_1327_1,
  drawing_1327_2,
  drawing_1328_1,
  drawing_1328_2,
  image_1329_1,
  image_1329_2,
  drawing_1329_1,
  drawing_1329_2,
  drawing_1329_3,
  drawing_1330_1,
  drawing_1330_2,
  drawing_1330_3,
  drawing_1331_1,
  drawing_1331_2,
  drawing_1331_3,
  drawing_1332_1,
  drawing_1332_2,
  drawing_1332_3,
  drawing_1333_1,
  drawing_1333_2,
  drawing_1333_3,
  drawing_1334_1,
  drawing_1334_2,
  drawing_1334_3,
  drawing_1337_1,
  drawing_1337_2,
  image_1337_1,
  image_1337_2,
  drawing_1338_1,
  drawing_1338_2,
  drawing_1339_1,
  drawing_1339_2,
  drawing_1339_3,
  drawing_1340_1,
  drawing_1340_2,
  drawing_1340_3,
  drawing_1345_1,
  drawing_1345_2,
  drawing_1345_3,
  drawing_1346_1,
  drawing_1346_2,
  drawing_1346_3,
  drawing_1347_1,
  drawing_1347_2,
  drawing_1347_3,
  drawing_1347_4,
  drawing_1348_1,
  drawing_1348_2,
  drawing_1348_3,
  drawing_1348_4,
  drawing_1350_1,
  drawing_1350_2,
  drawing_1350_3,
  drawing_1350_4,
  drawing_1350_5,
  drawing_1349_1,
  drawing_1349_2,
  drawing_1349_3,
  drawing_1349_4,
  drawing_1349_5,
  drawing_1351_1,
  drawing_1351_2,
  drawing_1352_1,
  drawing_1352_2,
  drawing_1355_1,
  drawing_1355_2,
  drawing_1355_3,
  drawing_1355_4,
  drawing_1356_1,
  drawing_1356_2,
  drawing_1356_3,
  drawing_1356_4,
  drawing_1357_1,
  drawing_1357_2,
  drawing_1357_3,
  drawing_1358_1,
  drawing_1358_2,
  drawing_1358_3,
  drawing_1361_1,
  drawing_1361_2,
  drawing_1361_3,
  drawing_1361_4,
  drawing_1362_1,
  drawing_1362_2,
  drawing_1362_3,
  drawing_1362_4,
  drawing_1363_1,
  drawing_1363_2,
  drawing_1364_1,
  drawing_1364_2,
  drawing_1369_1,
  drawing_1369_2,
  drawing_1370_1,
  drawing_1370_2,
  drawing_1371_1,
  drawing_1371_2,
  drawing_1372_1,
  drawing_1372_2,
  drawing_1373_1,
  drawing_1373_2,
  drawing_1374_1,
  drawing_1374_2,
  drawing_1375_1,
  drawing_1375_2,
  drawing_1376_1,
  drawing_1376_2,
  drawing_1377_1,
  drawing_1377_2,
  drawing_1377_3,
  drawing_1378_1,
  drawing_1378_2,
  drawing_1378_3,
  drawing_1401_1,
  drawing_1401_2,
  drawing_1401,
  drawing_1402_1,
  drawing_1402_2,
  drawing_1402,
  drawing_1403_1,
  drawing_1404_1,
  drawing_1405,
  drawing_1405_1,
  drawing_1405_3,
  drawing_1406,
  drawing_1406_1,
  drawing_1406_3,
  drawing_1407_2,
  drawing_1408_2,
  drawing_1409,
  drawing_1409_1,
  drawing_1409_2,
  drawing_1410_2,
  drawing_1411_2,
  drawing_1412_2,
  drawing_1413_2,
  drawing_1414_2,
  drawing_1415_2,
  drawing_1416_2,
  drawing_1417_2,
  drawing_1418_2,
  drawing_1419_2,
  drawing_1420_2,
  drawing_1422,
  drawing_1427,
  drawing_1428,
  drawing_1445,
  drawing_1445_1,
  drawing_1446,
  drawing_1446_1,
  drawing_1471,
  drawing_1471_2,
  drawing_1471_3,
  drawing_1472_3,
  drawing_1501,
  drawing_1501_1,
  drawing_1501_2,
  drawing_1501_3,
  drawing_1502,
  drawing_1502_1,
  drawing_1502_2,
  drawing_1502_3,
  drawing_1503,
  drawing_1503_1,
  drawing_1503_2,
  drawing_1503_3,
  drawing_1504,
  drawing_1504_2,
  drawing_1504_3,
  drawing_1505,
  drawing_1505_1,
  drawing_1505_2,
  drawing_1505_4,
  drawing_1532_1,
  drawing_1532,
  drawing_1532_2,
  drawing_1532_3,
  drawing_1534_1,
  drawing_1536,
  drawing_1506,
  drawing_1506_1,
  drawing_1507,
  drawing_1507_1,
  drawing_1507_2,
  drawing_1507_3,
  drawing_1508,
  drawing_1508_1,
  drawing_1508_2,
  drawing_1508_3,
  drawing_1509_1,
  drawing_1509_2,
  drawing_1509_3,
  drawing_1510_1,
  drawing_1510_2,
  drawing_1510_3,
  drawing_1511_1,
  drawing_1511_2,
  drawing_1511_3,
  drawing_1512_1,
  drawing_1512_2,
  drawing_1512_3,
  drawing_1513_1,
  drawing_1513_2,
  drawing_1513_3,
  drawing_1514_1,
  drawing_1514_2,
  drawing_1514_3,
  drawing_1515_1,
  drawing_1515_2,
  drawing_1515_3,
  drawing_1516_1,
  drawing_1516_2,
  drawing_1516_3,
  drawing_1517_1,
  drawing_1517_2,
  drawing_1517_3,
  drawing_1518_1,
  drawing_1518_2,
  drawing_1518_3,
  drawing_1519_1,
  drawing_1519_2,
  drawing_1519_3,
  drawing_1520_1,
  drawing_1520_2,
  drawing_1520_3,
  drawing_1521_1,
  drawing_1521_2,
  drawing_1521_3,
  drawing_1522_1,
  drawing_1522_2,
  drawing_1522_3,
  drawing_1523_1,
  drawing_1523_2,
  drawing_1523_3,
  drawing_1524_1,
  drawing_1524_2,
  drawing_1524_3,
  drawing_1525_1,
  drawing_1525_2,
  drawing_1525_3,
  drawing_1526_1,
  drawing_1526_2,
  drawing_1526_3,
  drawing_1527_1,
  drawing_1527_2,
  drawing_1527_3,
  drawing_1528_1,
  drawing_1528_2,
  drawing_1528_3,
  drawing_1529_1,
  drawing_1529_2,
  drawing_1529_3,
  drawing_1530_1,
  drawing_1530_2,
  drawing_1530_3,
  drawing_1531_1,
  drawing_1531_2,
  drawing_1531_3,
  drawing_1533_1,
  drawing_1533_2,
  drawing_1533_3,
  drawing_1534,
  drawing_1534_1,
  drawing_1534_2,
  drawing_1534_3,
  drawing_1701_1,
  drawing_1701,
  image_1701_1,
  drawing_1702,
  drawing_1702_1,
  drawing_1702_2,
  drawing_1702_3,
  drawing_1703_1,
  drawing_1704_1,
  drawing_1704_2,
  drawing_1704_3,
  drawing_1705_1,
  drawing_1705_2,
  drawing_1705_3,
  drawing_1705_4,
  drawing_1706_1,
  drawing_1706_2,
  drawing_1706,
  drawing_1707,
  drawing_1707_1,
  drawing_1707_2,
  drawing_1707_3,
  drawing_1708_1,
  drawing_1708_2,
  drawing_1708_3,
  drawing_1724,
  drawing_1724_1,
  drawing_1801,
  drawing_1801_1,
  drawing_1801_2,
  drawing_1801_3,
  drawing_1801_4,
  drawing_1802_1,
  drawing_1802_2,
  drawing_1802_3,
  drawing_1802_4,
  drawing_1803,
  drawing_1803_1,
  drawing_1803_2,
  drawing_1803_3,
  drawing_1803_4,
  drawing_1804_1,
  drawing_1804_2,
  drawing_1804_3,
  drawing_1804_4,
  drawing_1806_1,
  drawing_1806_2,
  drawing_1806_3,
  drawing_1806_4,
  drawing_1806_5,
  drawing_1805_1,
  drawing_1805_2,
  drawing_1805_3,
  drawing_1805_4,
  drawing_1805_5,
  drawing_1807,
  drawing_1807_1,
  drawing_1807_2,
  drawing_1807_3,
  drawing_1807_4,
  drawing_1808_1,
  drawing_1808_2,
  drawing_1808_3,
  drawing_1808_4,
  drawing_1809,
  drawing_1809_1,
  drawing_1809_2,
  drawing_1809_3,
  drawing_1809_4,
  drawing_1810_1,
  drawing_1810_2,
  drawing_1810_3,
  drawing_1810_4,
  drawing_1811_1,
  drawing_1811_2,
  drawing_1811_3,
  drawing_1811_4,
  drawing_1811_5,
  drawing_1812_1,
  drawing_1812_2,
  drawing_1812_3,
  drawing_1812_4,
  drawing_1812_5,
  drawing_1813_1,
  drawing_1813_2,
  drawing_1813_3,
  drawing_1813_4,
  drawing_1814_1,
  drawing_1814_2,
  drawing_1814_3,
  drawing_1814_4,  
  drawing_1815_1,
  drawing_1815_2,
  drawing_1815_3,
  drawing_1815_4, 
  drawing_1816_1,
  drawing_1816_2,
  drawing_1816_3,
  drawing_1816_4,
  drawing_2001_1,
  drawing_2001_2,
  drawing_2001_3,
  drawing_2002_1,
  drawing_2002_2,
  drawing_2002_3,
  drawing_2003_1,
  drawing_2003_2,
  drawing_2003_3,
  drawing_2003_4,
  drawing_2004_1,
  drawing_2004_2,
  drawing_2004_3,
  drawing_2004_4,
  drawing_2005_1,
  drawing_2005_2,
  drawing_2005_3,
  drawing_2005_4,
  drawing_2005_5,
  drawing_2006_1,
  drawing_2006_2,
  drawing_2006_3,
  drawing_2006_4,
  drawing_2006_5,
  drawing_2007_1,
  drawing_2007_2,
  drawing_2007_3,
  drawing_2007_4,
  drawing_2007_5,
  drawing_2008_1,
  drawing_2008_2,
  drawing_2008_3,
  drawing_2008_4,
  drawing_2008_5,
  drawing_2009_1,
  drawing_2009_2,
  drawing_2009_4,
  drawing_2009_5,
  drawing_2010_1,
  drawing_2010_2,
  drawing_2010_4,
  drawing_2010_5,
  drawing_2011,
  drawing_2011_1,
  drawing_2011_2,
  drawing_2011_3,
  drawing_2011_4,
  drawing_2012,
  drawing_2012_1,
  drawing_2012_2,
  drawing_2012_3,
  drawing_2012_4,
  drawing_2013,
  drawing_2013_1,
  drawing_2013_2,
  drawing_2013_3,
  drawing_2013_4,
  drawing_2014,
  drawing_2014_1,
  drawing_2014_2,
  drawing_2014_3,
  drawing_2015_1,
  drawing_2015_2,
  drawing_2015_3,
  drawing_2015_4,
  drawing_2016_1,
  drawing_2016_2,
  drawing_2016_3,
  drawing_2016_4,
  drawing_2017_1,
  drawing_2017_2,
  drawing_2017_3,
  drawing_2018_1,
  drawing_2018_2,
  drawing_2018_3,
  drawing_2019_1,
  drawing_2019_2,
  drawing_2020_1,
  drawing_2020_2,
  drawing_2021_1,
  drawing_2021_2,
  drawing_2022_1,
  drawing_2022_2,
  drawing_2023_1,
  drawing_2023_2,
  drawing_2023_3,
  drawing_2024_1,
  drawing_2024_2,
  drawing_2024_3,
  drawing_2025_1,
  drawing_2025_2,
  drawing_2025_3,
  drawing_2026_1,
  drawing_2026_2,
  drawing_2026_3,
  drawing_2027_1,
  drawing_2027_2,
  drawing_2027_3,
  drawing_2028_1,
  drawing_2028_2,
  drawing_2028_3,
  drawing_2029,
  drawing_2029_1,
  drawing_2029_2,
  drawing_2032,
  drawing_2032_1,
  drawing_2032_2,
  drawing_2032_3,
  drawing_2033_1,
  drawing_2033_2,
  drawing_2033_3,
  drawing_2034,
  drawing_2034_1,
  drawing_2034_2,
  drawing_2034_3,
  drawing_2035_1,
  drawing_2035_2,
  drawing_2035_3,
  drawing_2201_1,
  drawing_2201_2,
  drawing_2202_1,
  drawing_2202_2,
  drawing_2203_1,
  drawing_2203_2,
  drawing_2204_1,
  drawing_2204_2,
  drawing_2205_1,
  drawing_2205_2,
  drawing_2206_1,
  drawing_2206_2,
  drawing_2207_1,
  drawing_2207_2,
  drawing_2207_3,
  drawing_2208_1,
  drawing_2208_2,
  drawing_2208_3,
  drawing_2209_1,
  drawing_2209_2,
  drawing_2209_3,
  drawing_2210_1,
  drawing_2210_2,
  drawing_2210_3,
  drawing_2211_1,
  drawing_2211_2,
  drawing_2211_3,
  drawing_2212_1,
  drawing_2212_2,
  drawing_2212_3,
  drawing_2213_1,
  drawing_2213_2,
  drawing_2213_3,
  drawing_2214_1,
  drawing_2214_2,
  drawing_2214_3,
  drawing_2223_1,
  drawing_2223_2,
  drawing_2223_3,
  drawing_2224_1,
  drawing_2224_2,
  drawing_2224_3,
  drawing_2225_1,
  drawing_2225_2,
  drawing_2225_3,
  drawing_2225_4,
  drawing_2226_1,
  drawing_2226_2,
  drawing_2226_3,
  drawing_2226_4,
  drawing_2301_1,
  drawing_2301_2,
  drawing_2301_3,
  drawing_2301_4,
  drawing_2302,
  drawing_2302_1,
  drawing_2302_2,
  drawing_2302_3,
  drawing_2302_4,
  drawing_2307_1,  
  drawing_2307_4, 
  drawing_2308_4,  
  drawing_2308_1,
};
