import style from "./style.scss";

function LendenwirbelsauleReklinationLatStehend1708(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.45 532.22">

<g id="Anatomy">
  <g id="space">
    <path id="space2" class="lws_reklination1708_cls9" d="M79.75,326.03c-1.94-6.64-2.2-12.29-1.37-17.32l4.33-32.81-4.64-9.8c-.97-6.39.36-11.5,3.27-15.72l9.8-29.39-1.14-7.97c.13-7.5,2.51-13.17,5.92-18l14.13-16.63.91-13.67c2.11-6.28,4.95-10.12,8.28-12.3l45.55-81.69,11.8-22.21,23.68,12.53c1.3,6.07.73,11.64-4.04,16.07l-8.95,20.04c-.68,6.51-3.1,11.36-8.79,13.11l-6.19,19.52c.05,6.7-3.45,12.64-9.4,18.07l-8.43,22.1c1.61,6.55.13,11.34-2.87,15.27l-12.43,28.48c.68,5.23-1.68,9.99-6.57,14.35l-8.43,29.85c1.39,6.29.74,13.11-1.25,20.28l-2.76,28.71c2.15,3.51,3.58,7.95,4.01,13.67l4.78,17.54,4.56,10.03-44.89,14.71c-.93-13.28-3.84-25.58-8.89-36.81h.02Z"/>
    <path id="space1" class="lws_reklination1708_cls9" d="M233.81,413.55c2.51.98,4.62.36,6.3-2.07.51-.74.88-1.56,1.17-2.41,4.04-11.73,3.98-23.13-3.92-33.9-10.94-14.31-24.66-25.1-40.8-32.81l-51.45,6.67c11.39,3.51,23.84,9.41,42.54,19.51,2.87,1.55,5.79,3.02,8.72,4.46,11.9,5.8,21.66,14.65,29.2,26.7l8.25,13.85h-.01Z"/>
  </g>
  <g id="tail">
    <path id="tail7" class="lws_reklination1708_cls9" d="M151.02,332.08c2.02.38,2.34-3.02,4.92-6.5,2.85-1.83,5.96-2.09,9.33-.78,2.65,1.03,5.32,2.01,8.09,2.66l18.98,4.47c7.28,2.25,12.56,6.2,11.81,15.23-2.46,9.63-6.96,15.23-14.09,15.67-1.8.11-3.57-.53-4.98-1.64-3.16-2.49-6.29-4.32-9.38-5.47-4.89-1.81-10.33.71-12.05,5.61l-.04.11-21.07-7.62c.37-4.51,1.61-8.56.24-10.88-.53-.89-.44-2.03.07-2.93l3.9-6.86c.56-.99,1.7-1.43,2.83-1.22l1.44.15Z"/>
    <path id="tail6" class="lws_reklination1708_cls9" d="M194.43,327.17c-4.34,4.62-8.64,3.89-12.91,0-2.61-5.51-6.55-8.5-11.85-8.96-3.6.16-6.49,1.64-8.66,4.48-1.6,1.4-3.31,2.05-5.47,0l-8.96-6c-1.69-7.14-3.26-13.8-4.6-19.44l7.63-9.19,4.11,1.75c2.43-3.49,5.06-4.66,7.9-3.49l12.52,6.53c11.28,4.75,22.25,9.61,23.78,18.08,1.16,7.65-.27,12.81-3.49,16.25h0Z"/>
    <path id="tail5" class="lws_reklination1708_cls9" d="M196.41,288.05c-2.63,5.1-6.58,5.58-11.46,2.82-2.18-1.23-4.04-2.95-5.66-4.87-3.92-4.67-7.79-7.11-11.59-7.14h-8.66l-11.24-13.37c-.56-4.18.43-8.32,1.81-12.46l11.27-33.26-5.3-12.61,21.01-3.04c4.23,5.77,9.12,11.06,15.36,15.34,9.82,5.99,19.15,12.11,19.95,20.66-.16,3.69-.77,6.71-1.8,9.08-2.16,4.98-8.43,6.74-13.02,3.84-2.33-1.47-4.05-3.25-5.13-5.33-5.31-5.94-11.23-9.05-17.82-8.98l-5.52,5.18c-1.33,2.59-1.72,5.01,2.28,6.68,8.87,3.75,17.53,7.61,23.38,13.1,5.43,5.09,7.33,13.03,4.44,19.89-.6,1.44-1.37,2.92-2.31,4.45v.02Z"/>
    <path id="tail4" class="lws_reklination1708_cls9" d="M174.99,185.3c.93-5.31,4.15-9.68,8.66-13.52l10.03-4.41c4.59,8.44,10.12,16.35,17.14,23.44,4.11,4.15,6.55,9.74,6.55,15.59v.16c.44,5.19-.22,9.1-3.31,10.18-1.23.43-2.6.24-3.77-.33-2.42-1.18-4.96-3.41-7.57-6.18-.65-.69-1.39-1.29-2.19-1.8-6.67-4.25-16.02-13.52-25.54-23.13h0Z"/>
    <path id="tail3" class="lws_reklination1708_cls9" d="M187.3,147.47c.73-3.19,1.67-6.28,4.66-8.51.21-1.12.62-1.84,1.29-2.04,1.01-.3,1.76-1.12,1.92-2.16.49-3.22,2.19-6.24,3.68-9.29h11.39l5.47,11,15.04,27.71c3.07,5.5,3.01,8.96,0,10.48-2.2.89-4.63.28-7.18-1.22-2.27-1.33-4.22-3.16-5.77-5.29-4.67-6.38-9.77-7.94-15.14-6.43l-14.28-2.71-1.06-11.54h-.02Z"/>
    <path id="tail2" class="lws_reklination1708_cls9" d="M201.42,111.47c-1.51-2.58-3.26-4.8-5.63-6.12-.5-.28-.5-.99,0-1.26,4.11-2.2,7.09-2.08,9.12-.07h0c1.52,1.36,3.88,1.04,4.99-.67l4.28-6.62,12.3-3.49,2.62,4.92c.79,1.48,1.63,2.92,2.47,4.36,3.77,6.44,6.85,13.39,9.35,20.8.9,2.69,2.22,5.24,4.03,7.42,1.48,1.78,1.94,3.79,1.12,6.09-1.23,3.32-3.8,3.33-7.06,1.63-1.5-.78-2.81-1.89-3.85-3.23-5.83-7.49-12.12-11.21-18.7-12.38l-13.67-3.19-1.37-8.2h0Z"/>
    <path id="tail1" class="lws_reklination1708_cls9" d="M204.64,50.51c-1.44,6.59-.54,12.77,3.93,18.34.41.51.9.94,1.42,1.28l8.56,5.71c.43.29.85.6,1.25.93,6.49,5.41,12.63,12.17,18.23,21.04,6.21,8.91,11.64,14.98,14.41,11.42,3.92-1.62,4.07-6.82-.77-16.75l-12.88-30.86c-.56-4.7-2.59-7.94-6.61-9.19-2-.63-4.09.63-4.89,2.83l-1.04,2.86c-.47,1.29-1.19,2.48-2.19,3.27-1.7,1.34-3.66,1.46-5.8.75-1.87-.62-3.42-2.08-4.55-3.9-2.24-3.6-5.24-6.21-9.08-7.72h.01Z"/>
  </g>
  <g id="body">
    <path id="body7" class="lws_reklination1708_cls9" d="M148.8,330.29c-1.74-2.95-.92-6.46,2.71-10.58,1.31-1.49,1.55-3.68.46-5.34-2.66-4.02-5.82-6.28-9.19-7.86-3.45-3.94-6.89,1.81-10.33,12.15-1.91,2.74-3.28,2.73-4.3.87-.88-1.63-3-2.05-4.53-1.02-3.43,2.29-7.05,3.74-10.83,4.46-3.49.67-6.94,1.49-10.41,2.27-7.03,1.57-14.65.44-22.49-1.72-2.17-.9-2.69,1.26,0,9.42,2.88,5.83,3.64,13.16,2.43,21.87,0,7.22.31,13.86,5.16,12.46l32.81-17.01c.82-4.35,2.86-7.42,6.82-8.49,1.1-.3,2.1-.89,2.9-1.7.5-.51,1-.96,1.52-1.34,1.78-1.34,4.41-.99,5.6.9.65,1.02,1.76,1.76,3.51,2.13.21.02.42.05.61.08,1.27.23,2.55-.28,3.18-1.4l4.29-7.51c.46-.81.52-1.83.05-2.63h.03Z"/>
    <path id="body6" class="lws_reklination1708_cls9" d="M80.09,311.79c-5.22-.26-4.9-5.58-3.19-12.19,1.7-12.04,1.71-21.97-.68-28.94-1.41-5.88-.66-8.05,2.51-6.04,9.3,5.98,19.7,9.11,32.35,6.49,7.98,0,15.08.84,19.71,4.1,3.95,1.03,7.52-1.73,10.82-6.95,2.07-4.43,4.96-6.11,9.46-2.39,9.51,6,16.51,11.54,12.65,15.04-1.95,3-4.67,4.31-7.97,4.33-2.44,1.05-4.31,3.19-5.59,6.44-.64,1.63-1.47,3.2-2.51,4.62-3.52,4.76-6.81,5.24-9.9,2.16-5.85-4.44-10.89-5.87-14.04-.3-.41.72-.71,1.49-.96,2.28l-2.78,8.74c-.58,2.98-3.57,2.78-6.95,2.05-6.22-1.37-12.23-1.34-17.99.3-2.32.66-4.75.85-7.16.71l-7.78-.44h0Z"/>
    <path id="body5" class="lws_reklination1708_cls9" d="M82.03,253.23c-3.56-1.01-3.45-3.85-1.71-7.52,4.2-8.82,6.55-17.58,7.52-26.32-.49-7.6.48-10.81,3.65-7.52,8.32,7.44,17.72,12.51,28.68,14.13,5,.85,10.58.46,16.4-.44.9-.14,1.79.19,2.44.82,1.97,1.89,4.67,2.39,8.01,1.67,2.04-.44,3.85-1.63,5.1-3.31,3.6-4.84,7.38-7.17,11.6-3.19,4.55,2.17,8.29,4.99,9.09,10.22.17,1.1.41,2.19.71,3.27,1.31,4.67.52,7.68-1.58,9.64-1.01.94-2.29,1.54-3.64,1.82-2.47.5-4.89,1.28-7.19,2.58-1.81,1.02-3.25,2.6-4.21,4.45-1.79,3.45-4.43,4.8-7.63,4.75-.97-.02-1.9-.48-2.44-1.29-3-4.46-6.87-7.79-12.14-9.23-4.59-1.36-7.4.7-7.52,7.97-.54,3.38-2.62,5.48-6.98,5.7-12.85,3.42-26.4-8.43-38.14-8.2h-.02Z"/>
    <path id="body4" class="lws_reklination1708_cls9" d="M96.27,200.14c-4.68-3.2-3.17-6.75,1.37-10.48,6.12-6.47,9.74-14.07,10.37-23.01-.18-4.58,1.09-6.1,4.1-3.99,7.17,6.46,14.35,12.22,21.53,13.78,8.28,2.77,15.91,5.77,21.99,9.34,5.63,2.89,10.85,3.77,15.04-.57,2.53-4.29,5.48-4.48,8.77-1.14,2.3,2.43,3.9,4.69,4.4,6.68.33,1.34,1.27,2.42,2.57,2.88,2.47.88,3.77,2.17,4.09,3.8.28,1.42-.36,2.88-1.46,3.83-2.8,2.42-5.92,4.01-9.25,5.12-.76.25-1.5.56-2.22.93-5.62,2.89-11.09,4.15-16.37,3.21-1.43-.26-2.71-1.02-3.81-1.98-3.33-2.95-6.74-3.6-10.2-2.48-2.48.66-3.97,2.7-5.01,5.35-1.39,4.77-4.71,5.56-9.57,3.19-12.67-2.72-24.71-7.83-36.34-14.47h0Z"/>
    <path id="body3" class="lws_reklination1708_cls9" d="M205.91,157.93c-.92-2.34-3.29-3.24-7.33-2.45-1.06.66-2.08,1.17-3.05,1.49-2.46.79-4.97-1.15-4.97-3.73v-6.82c0-2.57-3.54-3.39-4.59-1.04,0,.03-.02.05-.04.08-2.2,4-5.42,6.01-10.14,5.13l-7.29-2.39c-4.35-1.35-6.7-3.51-7.48-6.3-.39-1.4-1.25-2.62-2.33-3.59-4.97-4.42-13.13-7.75-20.94-11.19-5.16-3.69-8.17-.9-9.91,5.7-2.97,7.73-6.06,11.77-9.25,13.34-.81.4-1.56.95-2.11,1.67-1.51,2.01-1.21,4.29.78,6.84.57.73,1.32,1.33,2.14,1.77l20.4,11.01c3.85,2.49,8.24,4.18,13.13,5.16,2.26.45,4.64-.24,6.22-1.92,3.81-4.03,8.31-4.7,13.46-2.22,2.98,1.8,5.13,4.09,6.59,6.81.62,1.16,2.23,1.35,3.11.38,1.17-1.28,2.47-2.01,3.98-1.88,1.02.09,2.01-.4,2.7-1.15,1.93-2.13,3.94-2.92,6.07-1.65,2.65-.47,5.94-3.07,9.76-7.45,1.34-1.53,1.85-3.71,1.11-5.6h-.02Z"/>
    <path id="body2" class="lws_reklination1708_cls9" d="M217.86,119.93c-.85-1.78-3.02-2.46-6.72-1.86-.97.5-1.9.89-2.8,1.13-2.26.6-4.55-.87-4.55-2.83v-5.17c0-1.95-3.25-2.57-4.21-.79,0,.02-.02.04-.03.06-2.01,3.03-4.97,4.56-9.29,3.88l-6.68-1.81c-3.99-1.02-6.14-2.66-6.86-4.77-.36-1.06-1.14-1.99-2.14-2.72-4.56-3.35-12.03-5.87-19.19-8.48-4.73-2.79-7.49-.68-9.08,4.32-2.72,5.86-5.56,8.92-8.47,10.11-.75.3-1.43.72-1.93,1.27-1.38,1.52-1.11,3.25.71,5.18.53.56,1.21,1.01,1.96,1.34l18.7,8.35c3.53,1.88,7.55,3.17,12.03,3.91,2.07.34,4.25-.19,5.7-1.45,3.49-3.05,7.62-3.56,12.33-1.68,2.73,1.36,4.7,3.1,6.04,5.16.57.88,2.04,1.02,2.85.29,1.07-.97,2.26-1.53,3.64-1.43.94.07,1.84-.3,2.47-.88,1.77-1.61,3.61-2.22,5.56-1.25,2.43-.36,5.44-2.33,8.94-5.64,1.22-1.16,1.7-2.81,1.02-4.24h0Z"/>
    <path id="body1" class="lws_reklination1708_cls9" d="M231.82,86.54c-.82-2.04-2.91-2.82-6.49-2.13-.93.58-1.84,1.02-2.7,1.29-2.18.69-4.4-1-4.4-3.25v-5.93c0-2.24-3.14-2.95-4.07-.9,0,.02-.02.05-.03.07-1.95,3.48-4.8,5.23-8.98,4.46l-6.46-2.08c-3.85-1.17-5.93-3.05-6.63-5.48-.35-1.21-1.11-2.28-2.07-3.12-4.4-3.85-11.63-6.74-18.54-9.74-4.57-3.21-7.24-.78-8.78,4.96-2.63,6.72-5.37,10.24-8.19,11.6-.72.35-1.38.83-1.86,1.46-1.34,1.75-1.07,3.73.69,5.95.51.64,1.17,1.16,1.89,1.54l18.07,9.58c3.41,2.16,7.3,3.64,11.62,4.49,2,.39,4.11-.21,5.51-1.67,3.37-3.5,7.36-4.09,11.92-1.93,2.63,1.56,4.54,3.56,5.83,5.93.55,1.01,1.98,1.18,2.76.33,1.03-1.11,2.18-1.75,3.52-1.64.9.08,1.78-.34,2.39-1,1.71-1.85,3.49-2.54,5.37-1.44,2.35-.41,5.26-2.67,8.64-6.48,1.18-1.33,1.64-3.22.98-4.87h.01Z"/>
  </g>
  <g id="ribs-hip">
    <path id="ribs-hip10" class="lws_reklination1708_cls9" d="M170.43,531.92c-2.21-2.91-4.23-5.75-5.94-8.48-7.03-11.22-1.56-26.03,11.06-30.04,1.58-.5,3.26-.97,5.03-1.39,3.32-.8,6.72-1.14,10.12-1.3,7.48-.36,12.75-4.69,16.56-11.67.99-1.81,1.79-3.73,2.48-5.68,5.59-15.59,10.5-26.34,14.57-30.98,3.02-3.45,5.69-7.21,7.45-11.44,3.82-9.16,3.77-16.81-.63-22.75-2.02-2.72-3.87-5.56-5.58-8.49-7.63-13.06-19.09-22.18-33.24-28.52-4.64-2.08-9.17-4.36-13.65-6.77-13.15-7.07-26.53-12.98-40.14-17.79-18-6.39-35.2-.31-51.95,12.76-27.81,20.57-51.96,49.67-74.25,83.16-4.62,6.95-7.59,14.92-8.24,23.24-.59,7.45.57,14.53,3.81,21.2,4.9,6.91,12.79,13.09,23.45,18.57,8.4,4.32,14.75,11.95,17.28,21.05.47,1.7.87,3.48,1.2,5.33"/>
    <path id="ribs-hip9" class="lws_reklination1708_cls9" d="M12.05,491.92c.83-9.66,2.32-18,4.53-24.88,2.69-8.38,7.96-15.7,14.91-21.09,6.41-4.97,13.23-9.35,20.43-13.17,11.64-6.18,21.61-15.24,28.01-26.77,1.25-2.26,2.42-4.63,3.51-7.12,2.31-5.28,5.02-10.38,8.4-15.05,10.51-14.52,24.17-22.63,41.23-23.86,9.58-1.68,19.13-.18,28.66,3.11,6.81,2.35,13.29,5.67,18.98,10.09,8.01,6.22,14.59,14.26,18.39,25.85.77,2.36,1.23,4.8,1.5,7.27.55,5.08,1.67,12.28,8.23,23.44.68,1.15,1.59,2.16,2.73,2.85,1.81,1.11,3.36.97,4.46-1.24.71-1.42,1.54-2.79,2.7-3.87,4.18-3.92,9.29-4.57,15.08-2.75"/>
    <path id="ribs-hip8" class="lws_reklination1708_cls9" d="M212.36,531.92c2.43-13.91.45-25.32-8.51-32.8-5.64-2.57-8.84-5.55-9.33-8.99,6.02-2.21,10.38-5.82,12.75-11.09l5.09-12.72c3.02-8.98,6.8-17.17,11.97-23.94,1.63.59,2.9,2.5,3.53,6.62,3.44,7.24,7.64,13.78,12.76,19.44,5.04,2.81,6.06,6.99,3.32,12.46-.93,1.46-1.9,2.88,1.24,8.51,3.81,4.77,3.48,9.31,0,13.67,1.78,6.35.41,11.43-2.73,15.8.76,2.68-.11,4.7-1.82,6.38-1,2.45-.69,4.66.61,6.67"/>
    <path id="ribs-hip7" class="lws_reklination1708_cls9" d="M225,448.95c-1.14,4.5-1.53,8.88.91,12.76,4.63,4.34,8.75,8.96,9.2,14.61.08,1.03.35,2.03.88,2.92.96,1.62,1.99,3.67,3.1,6.22.42.97.67,2.01.68,3.07.02,5.09,1.96,9.92,5.39,14.54"/>
    <path id="ribs-hip6" class="lws_reklination1708_cls9" d="M236.62,509.22c1.32,4.1,2.95,7.75,5.81,9.65"/>
    <path id="ribs-hip5" class="lws_reklination1708_cls9" d="M224.11,488.79c4.71,6.77,4.46,14.19,0,22.18-1.26,7.19-3.86,14.18-7.8,20.95"/>
    <path id="ribs-hip4" class="lws_reklination1708_cls9" d="M36.04,132.4c.71-1.85,2.25-3.14,4.57-3.88,3.05-.98,6.14-1.83,9.16-2.9,13.64-4.82,27.33-7.6,41.07-8.37,26.43-3.41,59.35-3.55,94.78-2.43,6.11.63,7.69-1.95,5.77-6.99,2.18-3.1,4.65-3.63,7.29-2.73,7.21,2.12,8.9,5.26,6.68,9.11,2.12,6.1.61,10.26-4.56,12.46l-6.97.93c-11.07,1.49-22.21,2.39-33.38,2.84-50.86,2.05-100.16,9.83-118.92,9.66-3.96-.04-6.93-4-5.51-7.69h.02Z"/>
    <path id="ribs-hip3" class="lws_reklination1708_cls9" d="M.31,143.37c12.55-10.03,25.51-19.24,40.41-24.61,29.5-13.04,62.42-23.04,108.9-20.96,4.67.44,9.08.8,13.21,1.05,16.03.99,32.05-2.14,46.5-9.15l.56-.27c1.9-.92,3.34-2.6,3.89-4.64.75-2.79.94-5.41.42-7.81-.46-2.15-2.7-3.42-4.79-2.75l-2.29.73c-8.32,2.65-17.06,3.75-25.79,3.25l-12.09-.69c-5.91-.34-11.81-.79-17.67-1.54-25.06-3.21-48.97.52-71.97,9.67C48.36,94.96,22.41,109.17.31,126.97"/>
    <path id="ribs-hip2" class="lws_reklination1708_cls9" d="M.31,88.69c20.68-14.35,46.31-26.01,74.73-36.15,30.62-11.66,64.57-14.69,100.56-12.46,13.6.7,25.51-.46,35.85-3.34l3.04,20.35c-6.65,5.61-17.7,7.29-32.51,5.62-23.84-2.4-51.33-.32-81.72,5.32C67.4,74.86,34.03,87.73.31,104.7"/>
    <path id="ribs-hip1" class="lws_reklination1708_cls9" d="M192.92,34.83c2.62,8.21,5.73,16.36,10.63,24.27,6.59,8.45,13.1,12.67,19.44,8.2,8.97-7.2,14.16-17.58,13.97-32.47"/>
  </g>
</g>
<g id="Overlays">
  <g id="LWS" class="lws_reklination1708_cls8">
    <path id="LWS10" class="LWS1708Fill" style= {{
            fill: props.colors.LWS1708Fill,
            stroke: props.colors.LWS1708Fill,
            opacity:props.colors.LWS1708Fill,
          }} d="M192.28,332.47l-18.98-4.47c-2.77-.65-5.44-1.63-8.09-2.66-3.37-1.31-6.48-1.05-9.33.78-2.58,3.48-4.36,6.73-6.38,6.35h.02c-.43-.08-.86-.06-1.26.04,0,.78-.19,1.54-.65,2.15-1.63,2.17-3.26,4.33-5.03,6.38,0,0-.01,0-.02.01-.29.8-.28,1.69.16,2.43.83,1.4.7,3.44.39,5.83,1.14.01,2.04.38,2.72.95,2.34.75,4.69,1.53,6.98,2.4,2.9,1.1,5.54,2.79,8.52,3.64,1.01.29,1.76.81,2.27,1.46.83.07,1.53.34,2.1.72,2.49-2.55,6.36-3.56,9.94-2.23,3.09,1.15,6.22,2.98,9.38,5.47,1.41,1.11,3.18,1.75,4.98,1.64,7.13-.44,11.63-6.04,14.09-15.67.75-9.03-4.53-12.98-11.81-15.23Z"/>
    <path id="LWS9" class="LWS1708Fill" style= {{
            fill: props.colors.LWS1708Fill,
            stroke: props.colors.LWS1708Fill,
            opacity:props.colors.LWS1708Fill,
          }} d="M197.86,311.47c-1.53-8.47-12.5-13.33-23.78-18.08l-12.52-6.53c-2.84-1.17-5.47,0-7.9,3.49l-3.1-1.32c.08,1.47-.45,2.97-1.6,3.89-.1,1.28-.66,2.52-1.71,3.29-.22.26-.47.49-.75.69-.49,1.66-1.83,3.03-4.05,3.06.59,2.49,1.22,5.14,1.88,7.93.58.07,1.1.22,1.54.46,2.03.31,3.2,1.82,3.5,3.53.69.53,1.17,1.26,1.43,2.07,1.27,1.4,1.48,3.63.66,5.32-.04.37-.11.74-.23,1.1l4.26,2.85c2.16,2.05,3.87,1.4,5.47,0,2.17-2.84,5.06-4.32,8.66-4.48,5.3.46,9.24,3.45,11.85,8.96,4.27,3.89,8.57,4.62,12.91,0h0c3.22-3.43,4.65-8.59,3.49-16.24Z"/>
    <path id="LWS8" class="LWS1708Fill" style= {{
            fill: props.colors.LWS1708Fill,
            stroke: props.colors.LWS1708Fill,
            opacity:props.colors.LWS1708Fill,
          }} d="M194.21,264.23c-5.85-5.49-14.51-9.35-23.38-13.1-3.06-1.28-3.54-3-3.01-4.89-.4.11-.83.2-1.31.2-.32,0-.61-.04-.89-.09-.09.01-.18.04-.28.05-.52.36-1.16.6-1.9.69-.17.08-.33.16-.5.25-.6.32-1.16.68-1.73,1.04-.03.02-.05.03-.07.04,0,.01,0,.01-.01.03-.12.19-.4.39-.55.55-.59.65-1.34,1.02-2.13,1.18-.25.31-.56.58-.91.81-.26.73-.7,1.38-1.32,1.87-.22,1.73-1.31,3.33-3.27,3.74-.7.61-1.64,1.01-2.84,1.01-.62,0-1.16-.12-1.65-.3-.64,2.58-1.01,5.16-.8,7.75.14-.01.26-.04.4-.04,1.9,0,3.16.97,3.81,2.26.01,0,.02.01.03.02,0,0,.02,0,.03,0,2.06,0,3.37,1.14,3.97,2.61.06.06.13.1.19.16.13-.01.25-.04.39-.04,2.45,0,3.85,1.61,4.23,3.47,3.1.35,4.27,3.46,3.54,5.92h3.39c3.8.03,7.67,2.47,11.59,7.14,1.62,1.92,3.48,3.64,5.66,4.87,4.88,2.76,8.83,2.28,11.46-2.82v-.02c.93-1.53,1.7-3.01,2.3-4.45,2.89-6.86.99-14.8-4.44-19.89Z"/>
    <path id="LWS7" class="LWS1708Fill" style= {{
            fill: props.colors.LWS1708Fill,
            stroke: props.colors.LWS1708Fill,
            opacity:props.colors.LWS1708Fill,
          }} d="M191.89,220c-5.57-3.82-10.06-8.46-13.97-13.51-.33.23-.72.41-1.14.54-.67.63-1.57,1.06-2.72,1.11-.68.66-1.62,1.1-2.83,1.14-.65.43-1.46.7-2.44.7-.52,0-.99-.09-1.41-.22-.57.28-1.23.45-2.01.45-.5,0-.95-.09-1.36-.22-.42.13-.86.22-1.37.22-.98,0-1.78-.27-2.43-.7-1.67-.05-2.85-.86-3.52-1.98l-1.17.17,5.3,12.61-.12.36c.78.16,1.41.53,1.91.99.42.12.79.31,1.12.54,1.47.12,2.52.82,3.17,1.81,1.76.13,2.92,1.12,3.5,2.41,1.56.94,2.24,2.82,2,4.58.79,1.72,1.26,3.58,1.33,5.44.11.62.11,1.26,0,1.88.07.4.1.8.07,1.2l.28-.26c6.59-.07,12.51,3.04,17.82,8.98,1.08,2.08,2.8,3.86,5.13,5.33,4.59,2.9,10.86,1.14,13.02-3.84,1.03-2.37,1.64-5.39,1.8-9.08-.8-8.55-10.13-14.67-19.95-20.66Z"/>
    <path id="LWS6" class="LWS1708Fill" style= {{
            fill: props.colors.LWS1708Fill,
            stroke: props.colors.LWS1708Fill,
            opacity:props.colors.LWS1708Fill,
          }} d="M217.31,207.1v-.16c0-5.85-2.44-11.44-6.55-15.59-6.35-6.42-11.47-13.52-15.79-21.06-.15.01-.28.04-.44.04-.64,0-1.21-.13-1.71-.32-.5.2-1.06.32-1.71.32-.27,0-.51-.04-.75-.07-.53,1.5-1.8,2.71-3.81,2.79-.48.24-1.04.4-1.67.45-.71,1.08-1.88,1.85-3.57,1.85-.28,0-.54-.04-.8-.08-2.05,2.23-3.64,4.64-4.65,7.3.15-.01.28-.04.43-.04.04,0,.08,0,.12.01.04,0,.07-.01.11-.01,2.79,0,4.22,2.09,4.33,4.25.12.19.22.39.31.6,1.51.81,2.25,2.46,2.21,4.11.81.57,1.36,1.41,1.63,2.34.34-.08.7-.14,1.1-.14,3.92,0,5.18,4.11,3.8,6.77,3.92,3.58,7.52,6.57,10.57,8.51.8.51,1.54,1.11,2.19,1.8,2.61,2.77,5.15,5,7.57,6.18,1.17.57,2.54.76,3.77.33,3.09-1.08,3.75-4.99,3.31-10.18Z"/>
    <path id="LWS5" class="LWS1708Fill" style= {{
            fill: props.colors.LWS1708Fill,
            stroke: props.colors.LWS1708Fill,
            opacity:props.colors.LWS1708Fill,
          }} d="M151.91,314.91c-2.66-4.02-5.82-6.28-9.19-7.86-3.45-3.94-6.89,1.81-10.33,12.15-1.91,2.74-3.28,2.73-4.3.87-.88-1.63-3-2.05-4.53-1.02-3.43,2.29-7.05,3.74-10.83,4.46-3.49.67-6.94,1.49-10.41,2.27-7.03,1.57-14.65.44-22.49-1.72-2.17-.9-2.69,1.26,0,9.42,2.88,5.83,3.64,13.16,2.43,21.87,0,2.19.03,4.32.21,6.19,10.59-8.98,25.38-16.32,39.16-16.79.45-.02.86.04,1.24.13,1.06-1.2,2.43-2.09,4.19-2.57,1.1-.3,2.1-.89,2.9-1.7.5-.51,1-.96,1.52-1.34,1.78-1.34,4.41-.99,5.6.9.65,1.02,1.76,1.76,3.51,2.13.21.02.42.05.61.08,1.27.23,2.55-.28,3.18-1.4l4.29-7.51c.46-.81.52-1.83.05-2.63h.03c-1.74-2.96-.92-6.47,2.71-10.59,1.31-1.49,1.55-3.68.46-5.34Z"/>
    <path id="LWS4" class="LWS1708Fill" style= {{
            fill: props.colors.LWS1708Fill,
            stroke: props.colors.LWS1708Fill,
            opacity:props.colors.LWS1708Fill,
          }} d="M80.03,312.33c-5.22-.26-4.9-5.58-3.19-12.19,1.7-12.04,1.71-21.97-.68-28.94-1.41-5.88-.66-8.05,2.51-6.04,9.3,5.98,19.7,9.11,32.35,6.49,7.98,0,15.08.84,19.71,4.1,3.95,1.03,7.52-1.73,10.82-6.95,2.07-4.43,4.96-6.11,9.46-2.39,9.51,6,16.51,11.54,12.65,15.04-1.95,3-4.67,4.31-7.97,4.33-2.44,1.05-4.31,3.19-5.59,6.44-.64,1.63-1.47,3.2-2.51,4.62-3.52,4.76-6.81,5.24-9.9,2.16-5.85-4.44-10.89-5.87-14.04-.3-.41.72-.71,1.49-.96,2.28l-2.78,8.74c-.58,2.98-3.57,2.78-6.95,2.05-6.22-1.37-12.23-1.34-17.99.3-2.32.66-4.75.85-7.16.71l-7.78-.44h0Z"/>
    <path id="LWS3" class="LWS1708Fill" style= {{
            fill: props.colors.LWS1708Fill,
            stroke: props.colors.LWS1708Fill,
            opacity:props.colors.LWS1708Fill,
          }} d="M81.97,253.77c-3.56-1.01-3.45-3.85-1.71-7.52,4.2-8.82,6.55-17.58,7.52-26.32-.49-7.6.48-10.81,3.65-7.52,8.32,7.44,17.72,12.51,28.68,14.13,5,.85,10.58.46,16.4-.44.9-.14,1.79.19,2.44.82,1.97,1.89,4.67,2.39,8.01,1.67,2.04-.44,3.85-1.63,5.1-3.31,3.6-4.84,7.38-7.17,11.6-3.19,4.55,2.17,8.29,4.99,9.09,10.22.17,1.1.41,2.19.71,3.27,1.31,4.67.52,7.68-1.58,9.64-1.01.94-2.29,1.54-3.64,1.82-2.47.5-4.89,1.28-7.19,2.58-1.81,1.02-3.25,2.6-4.21,4.45-1.79,3.45-4.43,4.8-7.63,4.75-.97-.02-1.9-.48-2.44-1.29-3-4.46-6.87-7.79-12.14-9.23-4.59-1.36-7.4.7-7.52,7.97-.54,3.38-2.62,5.48-6.98,5.7-12.85,3.42-26.4-8.43-38.14-8.2h-.02Z"/>
    <path id="LWS2" class="LWS1708Fill" style= {{
            fill: props.colors.LWS1708Fill,
            stroke: props.colors.LWS1708Fill,
            opacity:props.colors.LWS1708Fill,
          }} d="M96.21,200.68c-4.68-3.2-3.17-6.75,1.37-10.48,6.12-6.47,9.74-14.07,10.37-23.01-.18-4.58,1.09-6.1,4.1-3.99,7.17,6.46,14.35,12.22,21.53,13.78,8.28,2.77,15.91,5.77,21.99,9.34,5.63,2.89,10.85,3.77,15.04-.57,2.53-4.29,5.48-4.48,8.77-1.14,2.3,2.43,3.9,4.69,4.4,6.68.33,1.34,1.27,2.42,2.57,2.88,2.47.88,3.77,2.17,4.09,3.8.28,1.42-.36,2.88-1.46,3.83-2.8,2.42-5.92,4.01-9.25,5.12-.76.25-1.5.56-2.22.93-5.62,2.89-11.09,4.15-16.37,3.21-1.43-.26-2.71-1.02-3.81-1.98-3.33-2.95-6.74-3.6-10.2-2.48-2.48.66-3.97,2.7-5.01,5.35-1.39,4.77-4.71,5.56-9.57,3.19-12.67-2.72-24.71-7.83-36.34-14.47h0Z"/>
    <path id="LWS1" class="LWS1708Fill" style= {{
            fill: props.colors.LWS1708Fill,
            stroke: props.colors.LWS1708Fill,
            opacity:props.colors.LWS1708Fill,
          }} d="M205.87,158.47h-.02c-.92-2.34-3.29-3.24-7.33-2.45-1.06.66-2.08,1.17-3.05,1.49-2.46.79-4.97-1.15-4.97-3.73v-6.82c0-2.57-3.54-3.39-4.59-1.04-.01.03-.02.05-.04.08-2.2,4-5.42,6.01-10.14,5.13l-7.29-2.39c-4.35-1.35-6.7-3.51-7.48-6.3-.39-1.4-1.25-2.62-2.33-3.59-3.4-3.02-8.29-5.53-13.54-7.92-.42.12-.86.21-1.36.21-.41,0-.77-.06-1.12-.14-.53.23-1.14.37-1.84.37-.64,0-1.2-.12-1.69-.32-.26.05-.53.09-.82.09-.16,0-.32.02-.48.02-.41.13-.85.2-1.35.2-.3,0-.58-.03-.85-.08-2.3.19-4.59.5-6.9.54-.15,0-.29-.03-.44-.04-.17.5-.33,1.01-.47,1.57-2.97,7.73-6.06,11.77-9.25,13.34-.81.4-1.56.95-2.11,1.67-1.51,2.01-1.21,4.29.78,6.84.57.73,1.32,1.33,2.14,1.77l20.4,11.01c3.85,2.49,8.24,4.18,13.13,5.16,2.26.45,4.64-.24,6.22-1.92,3.81-4.03,8.31-4.7,13.46-2.22,2.98,1.8,5.13,4.09,6.59,6.81.62,1.16,2.23,1.35,3.11.38,1.17-1.28,2.47-2.01,3.98-1.88,1.02.09,2.01-.4,2.7-1.15,1.93-2.13,3.94-2.92,6.07-1.65,2.65-.47,5.94-3.07,9.76-7.45,1.34-1.53,1.85-3.71,1.11-5.6Z"/>
  </g>
  <g id="LWK">
    <path id="LWK5" class="WK1708Fill" style= {{
            fill: props.colors.WK1708Fill,
            stroke: props.colors.WK1708Fill,
            opacity:props.colors.WK1708Fill,
          }} d="M127.96,319.62c-.96-1.4-2.91-1.72-4.35-.75-3.43,2.29-7.05,3.74-10.83,4.46-3.49.67-6.94,1.49-10.41,2.27-7.03,1.57-14.65.44-22.49-1.72-2.17-.9-2.69,1.26,0,9.42,2.88,5.83,3.64,13.16,2.43,21.87,0,2.68.05,5.28.35,7.42,3.23-2.36,6.6-4.44,9.91-6.67,3.02-2.04,5.76-4.47,9.4-5.32.1-.02.2-.04.3-.05,3.23-2.18,6.56-4.18,10.53-4.4,2.89-1.02,5.89-1.67,8.83-1.63.46,0,.87.09,1.26.2,1.06-1.22,2.44-2.11,4.21-2.59,1.1-.3,2.1-.89,2.9-1.7.5-.51,1-.96,1.52-1.34.09-.07.18-.11.28-.17-.98-6.49-2.93-12.79-3.84-19.3Z"/>
    <path id="LWK4" class="WK1708Fill" style= {{
            fill: props.colors.WK1708Fill,
            stroke: props.colors.WK1708Fill,
            opacity:props.colors.WK1708Fill,
          }} d="M123.86,298.28c.57-8.31,1.5-16.58,2.71-24.83-4.28-1.52-9.65-1.98-15.5-1.98-12.65,2.62-23.05-.51-32.35-6.49-3.17-2.01-3.92.16-2.51,6.04,2.39,6.97,2.38,16.9.68,28.94-1.71,6.61-2.03,11.93,3.19,12.19h0s7.78.45,7.78.45c2.41.14,4.84-.05,7.16-.71,5.76-1.64,11.77-1.67,17.99-.3,3.38.73,6.37.93,6.95-2.05l2.78-8.74c.25-.79.55-1.56.96-2.28.05-.09.1-.16.15-.24Z"/>
    <path id="LWK3" class="WK1708Fill" style= {{
            fill: props.colors.WK1708Fill,
            stroke: props.colors.WK1708Fill,
            opacity:props.colors.WK1708Fill,
          }} d="M126.4,255.54c2.93-10.17,8.4-19.38,11.29-29.54-.36-.1-.74-.14-1.12-.08-5.82.9-11.4,1.29-16.4.44-10.96-1.62-20.36-6.69-28.68-14.13-3.17-3.29-4.14-.08-3.65,7.52-.97,8.74-3.32,17.5-7.52,26.32-1.74,3.67-1.85,6.51,1.71,7.52h.02c11.74-.23,25.29,11.62,38.14,8.2,3.29-.17,5.28-1.41,6.29-3.46-.31-.79-.38-1.73-.08-2.78Z"/>
    <path id="LWK2" class="WK1708Fill" style= {{
            fill: props.colors.WK1708Fill,
            stroke: props.colors.WK1708Fill,
            opacity:props.colors.WK1708Fill,
          }} d="M141.44,211.86c5.18-8.38,9.21-17.34,12.78-26.51-5.83-3.23-12.92-5.99-20.58-8.55-7.18-1.56-14.36-7.32-21.53-13.78-3.01-2.11-4.28-.59-4.1,3.99-.63,8.94-4.25,16.54-10.37,23.01-4.54,3.73-6.05,7.28-1.37,10.48h0c11.63,6.63,23.67,11.74,36.34,14.46,3.64,1.78,6.42,1.78,8.17-.34-.11-.86.07-1.8.67-2.76Z"/>
    <path id="LWK1" class="WK1708Fill" style= {{
            fill: props.colors.WK1708Fill,
            stroke: props.colors.WK1708Fill,
            opacity:props.colors.WK1708Fill,
          }} d="M166.31,147.72c-2.96-1.34-4.66-3.19-5.3-5.46-.39-1.4-1.25-2.62-2.33-3.59-3.33-2.97-8.11-5.44-13.24-7.79-5.81.02-11.53,1.23-17.34,1.38-.09.3-.18.6-.26.92-2.97,7.73-6.06,11.77-9.25,13.34-.81.4-1.56.95-2.11,1.67-1.51,2.01-1.21,4.29.78,6.84.57.73,1.32,1.33,2.14,1.77l20.4,11.01c3.85,2.49,8.24,4.18,13.13,5.16,2.1.42,4.29-.16,5.86-1.58,2.77-7.8,5.1-15.75,7.53-23.66Z"/>
  </g>
  <g id="Transition">
    <path id="Transition2" class="LWS_Kreuzbein1708Fill" style= {{
            fill: props.colors.LWS_Kreuzbein1708Fill,
            stroke: props.colors.LWS_Kreuzbein1708Fill,
            opacity:props.colors.LWS_Kreuzbein1708Fill,
          }} d="M68.16,370.3c.35-3.13,4.56-3.88,8.51-7.29,8.73-7.54,8.56-7.78,16.71-12.46,10.64-6.1,20.88-7.16,25.52-7.59,10.3-.96,17.78.73,27.95,3.04,10.95,2.48,9.28,3.47,28.86,9.72,17.16,5.48,25.63,7,26.73,12.46.42,2.06-.24,4.59-1.82,5.77-2.88,2.13-7.82-.95-14.58-3.95-4.98-2.21-12.03-4.1-26.13-7.9-7.55-2.03-12.48-5.25-20.13-6.32-5.51-.77-12.32-2.49-20.05-2.13-10.25.48-16.48,3.46-23.32,7.54-1.93,1.15-8.41,5.3-15.49,11.54-2.81,2.48-5.17,4.8-7.9,4.25-2.76-.55-5.18-3.87-4.86-6.68Z"/>
    <path id="Transition1" class="BWS_LWS1708Fill" style= {{
            fill: props.colors.BWS_LWS1708Fill,
            stroke: props.colors.BWS_LWS1708Fill,
            opacity:props.colors.BWS_LWS1708Fill,
          }} d="M120.98,125.16c2.05-2.03,5.73-1.76,12.63-.27,7.13,1.54,7.08,1.99,16.69,3.41,9.38,1.38,16.36,3.44,21.97,5.11,10.37,3.1,14.43,6.01,20.95,9.87,7.08,4.19,12.84,7.31,14.09,8.55,4.3,4.26,3.2,1.65,8.72,7.44,4.23,4.44,6.33,8.65,6.25,11.68-.05,1.81-1.01,4.32-2.91,5.1-3.5,1.42-7.45-4.21-16.78-11.1-3.23-2.39-7.61-4.86-16.36-9.81-7.21-4.08-12.4-7.01-19.66-10.3-9.93-4.49-14.89-6.74-21.52-8.12-13.58-2.81-21.71.04-24.46-4.87-1.21-2.15-1.08-5.25.38-6.7Z"/>
  </g>
  <g id="Platten">
    <path id="Platten8" class="Grund_Deckplatten1708Fill" style= {{
            fill: props.colors.Grund_Deckplatten1708Fill,
            stroke: props.colors.Grund_Deckplatten1708Fill,
            opacity:props.colors.Grund_Deckplatten1708Fill,
          }} d="M118.51,150.11c1.09.51,2.67,1.24,4.56,2.13,3.47,1.63,5.2,2.45,6.38,3.04,5.16,2.59,4.85,3.08,9.11,5.16,1.04.51.51.21,7.9,3.34,3.48,1.48,5.3,2.26,7.59,3.65,3.03,1.83,5.34,3.25,5.16,4.86-.17,1.64-2.83,2.57-3.34,2.73-2.65.86-5.11-.02-6.68-.61-6.68-2.52-11.54-5.16-11.54-5.16-9.56-5.21-14.34-7.81-14.58-7.9-2.46-.86-4.78-1.43-6.99-3.65-1.61-1.62-3.93-4.91-2.73-6.68,1.16-1.72,4.91-.97,5.16-.91Z"/>
    <path id="Platten7" class="Grund_Deckplatten1708Fill" style= {{
            fill: props.colors.Grund_Deckplatten1708Fill,
            stroke: props.colors.Grund_Deckplatten1708Fill,
            opacity:props.colors.Grund_Deckplatten1708Fill,
          }} d="M109.7,166.21c1.15,1.74,2.42,2.78,4.86,4.56,3.38,2.47,5.08,3.7,7.59,5.16,2.53,1.47,4.52,2.4,8.51,4.25,3.76,1.75,6.31,2.8,9.11,3.95,1.68.69,3.75,1.37,7.9,2.73,2.6.85,4.16,1.32,5.77.61.97-.43,2.42-1.49,2.43-2.73.01-1.86-3.21-3.09-8.81-5.47,0,0-4.65-1.98-14.89-6.68-6.64-3.05-9.96-4.58-11.54-5.77-.94-.71-3.34-2.6-6.99-4.25-1.93-.88-4.65-2.11-5.47-1.22-.96,1.06,1.27,4.48,1.52,4.86Z"/>
    <path id="Platten6" class="Grund_Deckplatten1708Fill" style= {{
            fill: props.colors.Grund_Deckplatten1708Fill,
            stroke: props.colors.Grund_Deckplatten1708Fill,
            opacity:props.colors.Grund_Deckplatten1708Fill,
          }} d="M96.64,195.07c3.15,1.1,4.36,2.52,6.99,4.25,1.01.66,1.36.81,9.72,4.25,10.06,4.14,10.25,4.23,11.24,4.56,5.67,1.88,8.5,2.83,10.33,3.04,2.33.27,4.42.23,6.08,1.82,1.19,1.14,2.16,3.13,1.52,4.25-.77,1.35-3.67,1.14-8.2.3-9.15-1.68-16.23-4.29-20.05-5.77-11.97-4.64-8.26-4.53-14.89-6.68-2.42-.79-5.56-1.66-7.59-4.56-1.2-1.71-2.3-4.52-1.22-5.77,1.21-1.39,4.57-.22,6.08.3Z"/>
    <path id="Platten5" class="Grund_Deckplatten1708Fill" style= {{
            fill: props.colors.Grund_Deckplatten1708Fill,
            stroke: props.colors.Grund_Deckplatten1708Fill,
            opacity:props.colors.Grund_Deckplatten1708Fill,
          }} d="M91.78,209.35c3.14.25,4.93,2.81,6.99,4.86,4.52,4.49,9.91,6.04,14.28,7.29,5.28,1.51,10.17,1.52,13.67,1.52,6.99,0,10.86-1.14,12.15.91.84,1.34.29,3.59-.91,4.86-1.43,1.51-3.52,1.33-13.37,0-8.5-1.15-10.13-1.46-11.85-1.82-4.21-.9-6.32-1.35-9.11-2.43-5.87-2.26-9.66-5.27-12.15-7.9-1.48-1.56-4.18-4.41-3.34-6.08.55-1.09,2.43-1.31,3.65-1.22Z"/>
    <path id="Platten4" class="Grund_Deckplatten1708Fill" style= {{
            fill: props.colors.Grund_Deckplatten1708Fill,
            stroke: props.colors.Grund_Deckplatten1708Fill,
            opacity:props.colors.Grund_Deckplatten1708Fill,
          }} d="M81.75,248.84c1.79.44,4.55,1.15,7.9,2.13,2.5.73,5.55,1.71,11.54,3.65,8.92,2.88,9.81,3.17,11.85,3.34,3.97.34,6.19.53,9.11-.3,2.32-.66,4.02-1.67,4.86-.91.79.71.45,2.65-.3,3.95-2.04,3.52-7.87,3.6-10.94,3.65-4.94.07-8.65-1.04-14.28-2.73-3.76-1.13-3.84-1.42-10.03-3.34-4.96-1.54-7.44-2.31-8.2-2.43-2.63-.41-4.09-.29-5.47-1.52-1.36-1.2-2.6-3.67-1.52-5.16.91-1.26,3.32-1.63,5.47-.3Z"/>
    <path id="Platten3" class="Grund_Deckplatten1708Fill" style= {{
            fill: props.colors.Grund_Deckplatten1708Fill,
            stroke: props.colors.Grund_Deckplatten1708Fill,
            opacity:props.colors.Grund_Deckplatten1708Fill,
          }} d="M78.72,262.51c5.13.33,6.99,2.49,12.46,4.56,5.6,2.12,10.36,2.38,11.54,2.43,3.44.14,3.21-.4,9.72-.61,4.36-.14,7.41.01,10.63.61,3.49.64,6.55,1.24,6.99,3.04.31,1.28-.76,2.97-2.13,3.65-1.8.9-3.46-.28-7.29-1.22,0,0-2.69-.66-6.99-.91-5.5-.32-5.89,1.14-11.24,1.22-5.84.08-10.65-1.59-12.15-2.13-2.99-1.07-3.58-1.75-7.59-3.04-1.91-.61-3.03-.86-4.56-1.82-2.01-1.27-4.47-3.61-3.95-4.86.49-1.17,3.4-.98,4.56-.91Z"/>
    <path id="Platten2" class="Grund_Deckplatten1708Fill" style= {{
            fill: props.colors.Grund_Deckplatten1708Fill,
            stroke: props.colors.Grund_Deckplatten1708Fill,
            opacity:props.colors.Grund_Deckplatten1708Fill,
          }} d="M80.72,306.87c2.41.06,2.99.65,5.47.91,2.22.23,2.8-.14,6.99-.61,4.14-.47,7.4-.54,10.63-.61,4.23-.09,3.31.12,6.38,0,4.62-.18,6.17-.64,8.51.3.77.31,3.43,1.38,3.65,3.34.2,1.75-1.67,3.22-1.82,3.34-2.68,2.06-5.61.13-12.15-.3-3.6-.24-6.56.15-12.46.91-4.66.61-5.65,1.04-9.42,1.22-6.98.32-9-.92-10.03-1.82-1.36-1.2-2.77-3.38-2.13-4.86.86-1.96,4.97-1.86,6.38-1.82Z"/>
    <path id="Platten1" class="Grund_Deckplatten1708Fill" style= {{
            fill: props.colors.Grund_Deckplatten1708Fill,
            stroke: props.colors.Grund_Deckplatten1708Fill,
            opacity:props.colors.Grund_Deckplatten1708Fill,
          }} d="M78.72,326.92c1.99,1.34,4.02,1.26,6.99,1.22,8.47-.13,6.5.26,10.33,0,1.26-.09,5.61-.41,11.24-1.52,6.65-1.31,5.51-1.82,10.03-2.43,2.64-.36,4.56-.39,6.68-1.82,1.67-1.13,3.53-3.15,3.04-4.25-.6-1.34-4.48-.79-5.77-.61-3.54.5-5.04,1.66-8.2,2.73-2.45.83-4.57,1.06-8.81,1.52-4,.43-7.98.86-13.06.3-3.31-.36-5.64-.98-9.72-.61-4.63.43-5.08,1.54-5.16,1.82-.37,1.22,1.22,2.83,2.43,3.65Z"/>
  </g>
  <g id="Hinterkanten">
    <path id="Hinterkanten4" class="Wirbelkanten1708Fill" style= {{
            fill: props.colors.Wirbelkanten1708Fill,
            stroke: props.colors.Wirbelkanten1708Fill,
            opacity:props.colors.Wirbelkanten1708Fill,
          }} d="M163.78,163.78c-.94-.3-2.06.61-4.25,2.43-2.87,2.39-2.79,3.04-4.25,3.65-2.38.98-4.94.24-5.77,0-2.79-.81-3.7-2.33-4.86-1.82-.98.43-1.6,2.08-1.22,3.34.58,1.92,3.63,2.49,9.72,3.65,2.68.51,3.9.47,5.16,0,1.19-.44,2.01-1.21,3.65-2.73,2.06-1.92,3.08-2.88,3.34-4.25.31-1.67-.29-3.86-1.52-4.25Z"/>
    <path id="Hinterkanten3" class="Wirbelkanten1708Fill" style= {{
            fill: props.colors.Wirbelkanten1708Fill,
            stroke: props.colors.Wirbelkanten1708Fill,
            opacity:props.colors.Wirbelkanten1708Fill,
          }} d="M146.16,205.09c-.77-.82-2.25-1.1-3.34-.61-.65.29-1.3.92-3.04,6.08-.86,2.55-.92,3.04-1.52,3.34-.76.39-11.23-3.62-5.77-1.82,0,0-2.63-2.08-4.25-1.52-1.4.48-2.31,3.02-1.52,4.25.53.82.83,2,3.04,2.73,2.62.87,3.18.23,5.47.91,1.13.33,3.31.98,5.16,0,1.03-.55,1-1.09,3.04-4.25,2-3.11,2.62-3.49,3.04-5.16.26-1.06.72-2.86-.3-3.95Z"/>
    <path id="Hinterkanten2" class="Wirbelkanten1708Fill" style= {{
            fill: props.colors.Wirbelkanten1708Fill,
            stroke: props.colors.Wirbelkanten1708Fill,
            opacity:props.colors.Wirbelkanten1708Fill,
          }} d="M128.93,248.46c-.7-.14-1.39.27-1.7.91-.78,1.58-2.3,4.53-2.94,5.84-1.13,2.32-1.42,2.7-1.82,3.04-1.58,1.35-3.8,1.26-4.86,1.22-2.62-.11-3.24-1.13-4.56-.61-1.57.62-2.71,2.89-2.13,4.25.51,1.18,2.46,1.39,6.38,1.82,2.72.3,4.29.46,6.38,0,1.82-.4,2.25-.76,3.34-2.13,1.02-1.27,1.68-2.06,2.43-5.47.8-3.67,3.41-6.67,2.73-7.59-.31-.43-1.53-.95-3.26-1.29Z"/>
    <path id="Hinterkanten1" class="Wirbelkanten1708Fill" style= {{
            fill: props.colors.Wirbelkanten1708Fill,
            stroke: props.colors.Wirbelkanten1708Fill,
            opacity:props.colors.Wirbelkanten1708Fill,
          }} d="M122.77,295.32c-.82.54-1.14,1.33-2.13,5.47-.86,3.62-.98,4.58-1.82,6.08-.76,1.34-1.31,2.33-2.43,2.73-1.17.42-1.94-.17-5.47-1.22-3.58-1.06-4.19-.86-4.56-.61-1.14.77-1.47,2.98-.61,4.25.28.42.77.84,5.47,1.52,2.11.31,1.51.15,5.77.61,2.82.3,3.32.4,3.95,0,.44-.28.95-.8,1.82-4.56.62-2.65.45-2.74.91-4.56.16-.64.31-1.1,1.82-5.47,1.07-3.08,1.31-3.74.91-4.25-.62-.8-2.46-.78-3.65,0Z"/>
  </g>
  <g id="Reklamation">
    <rect id="Reklamation2" class="Inklination1708Fill" style= {{
            fill: props.colors.Inklination1708Fill,
            stroke: props.colors.Inklination1708Fill,
            opacity:props.colors.Inklination1708Fill,
          }} x="49.8" y="6.73" width="192.3" height="11.39"/>
    <polygon id="Reklamation1" class="Inklination1708Fill" style= {{
            fill: props.colors.Inklination1708Fill,
            stroke: props.colors.Inklination1708Fill,
            opacity:props.colors.Inklination1708Fill,
          }} points="241.53 26.46 264.45 13.23 241.54 0 241.53 26.46"/>
  </g>
</g>
</svg>
  );
}

export default LendenwirbelsauleReklinationLatStehend1708;
