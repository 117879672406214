import style from "./style.scss";

function LinkesFersenbeinLatLiegend1447(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 508.15 358.45">

<g id="heel_bone" data-name="heel bone">
  <g id="tarsus">
    <path id="tarsus1" class="Kalkaneus_seitlich_links1447_cls5" d="M508.04,314.48c-31.51-7.11-53.95-15.44-54.55-26.7-2.59-13.45,24.1-28.91,50-44.32"/>
    <path id="tarsus2" class="Kalkaneus_seitlich_links1447_cls5" d="M201.78.28c-15.66,23.1-29.21,45.63-18.18,61.36,8.35,30.3,22.1,37.59,38.64,32.95l51.14-27.27c13.65-8.27,25.03-3.75,35.23,7.39,11.08,12.48,25.89,21.69,44.89,27.27,22.72,10.63,44.58,9.74,65.34-5.68,18.25-14.81,23.19-40.1,23.86-68.75"/>
    <path id="tarsus3" class="Kalkaneus_seitlich_links1447_cls5" d="M498.38,154.26c-11.07-10.03-20.42-27.42-28.41-50.57l-21.02,6.25-34.66,1.7c-2.79,33.75-3.27,65.59,0,94.32-1.14,12.01-4.48,23.32-11.36,33.52l11.36,13.07c-1.49,20.22,11.8,23.18,35.8,13.64l51.7-32.39"/>
    <path id="tarsus4" class="Kalkaneus_seitlich_links1447_cls5" d="M452.36,34.94c-10.08,12.14-23.02,19.81-38.64,23.3-10.71,5.15-12.78,11.07-7.95,17.61,3.42,42.84,20.23,66.03,48.3,72.73l19.39,5.36c4.4,1.22,9.08-.43,11.76-4.12l19.41-26.81"/>
    <path id="tarsus5" class="Kalkaneus_seitlich_links1447_cls5" d="M187.59,67.9c36.28-3.6,65.28,7.12,89.2,27.84,12.49,7.69,18.12-4.89,22.16-22.16,3.77-20.83,10.72-27.35,18.75-28.98"/>
    <path id="tarsus6" class="Kalkaneus_seitlich_links1447_cls5" d="M414.29,111.65c20.42-14.75,32.18-32.69,36.36-53.41l1.14-29.55"/>
    <path id="tarsus7" class="Kalkaneus_seitlich_links1447_cls5" d="M159.74,55.39h0c5.7-.54,9.64,3.02,12.5,9.09,2.11,6.83-2.53,12.13-11.36,16.48-8.02,2.85-13.36-.23-15.34-10.8,3.13-7.99,8.27-12.13,14.2-14.77h0Z"/>
  </g>
  <g id="calcaneus">
    <path id="calcaneus1" class="Kalkaneus_seitlich_links1447_cls5" d="M121.1,332.66h0c23.77,3.69,45.82,4.68,65.34,1.7,20.15-9.73,30.69-22.12,28.41-38.07-3.92-12.35-2.5-21.61,5.11-27.27,57.39-17.61,114.91-22.07,142.05-36.93,10.44-11.03,20.87-18.43,31.25-15.34,10.28,7.78,10.52-2.36,8.52-16.48l4.55-15.34c-7.08-.91-11.75-7.67-10.23-29.55-.3-16.99,7.14-27.56,18.75-34.66l2.84-14.77c13.05-6.18,10.87-15.95,4.55-26.7-7-7.11-16.38-4.22-27.84,7.39l-54.55,21.59c-19.39-9.69-38.18-21.83-56.25-36.93-12.19-2.68-27.66,3.28-45.45,15.34l-28.41-11.93c-20.83-3.19-39.43,7.38-55.11,35.8-23.45,24.64-53.36,42.83-91.48,52.84-19-.55-30.31,5.46-28.41,22.73,7.64,17.92,11.29,34.42,9.09,48.86-4.93,1.58-4.05,5.34-1.7,9.66,3.24,3.07,5.44,11.38,6.82,23.86-4.23,6.94-2.46,12.2,2.84,16.48,23.41,23.76,46.61,41.88,69.32,47.73h0Z"/>
    <path id="calcaneus2" class="Kalkaneus_seitlich_links1447_cls4" d="M394.4,86.64c-13.47,14.26-29.44,24.06-52.27,21.59l-12.5,9.09c-16.39,9.31-27.95,4.86-36.36-8.52-16.73-9.79-34.73-17.72-55.11-22.16-18.13-.33-34.07-2.41-44.32-9.09"/>
    <path id="calcaneus3" class="Kalkaneus_seitlich_links1447_cls6" d="M263.15,108.24c14.36,13.3,28.41,22.23,42.05,25.57,12.55-2.29,26.02-1.48,40.34,2.27,4.76,4.48,9.66,5.12,14.77-1.14-.86-3.57.76-6.41,6.82-7.95,5.45-5.35,6.81-10.85,4.55-16.48"/>
    <path id="calcaneus4" class="Kalkaneus_seitlich_links1447_cls6" d="M156.9,301.98c5.18-11.5,29.71-22.37,63.07-32.95"/>
    <line id="calcaneus5" class="Kalkaneus_seitlich_links1447_cls6" x1="371.11" y1="224.14" x2="375.65" y2="205.96"/>
  </g>
  <path id="outlines" class="Kalkaneus_seitlich_links1447_cls6" d="M109.15,13l-41.27,94.74c-1.81,4.16-4.12,8.08-6.87,11.69l-30.04,39.36c-15.43,20.23-23.67,45.03-23.39,70.47h0c.37,33.95,16.23,65.86,43.08,86.64l3.55,2.75c30.27,23.44,68.01,35.12,106.23,32.89l28.69-1.67c21.2-1.24,42.04-6.07,61.62-14.3l65.1-27.35c15.73-6.61,33.26-7.58,49.62-2.74l106.7,31.53"/>
</g>
<g id="overlays">
  <path id="calcaneus_overlay" data-name="calcaneus overlay" class="Calcaneus1447Fill" style= {{
            fill: props.colors.Calcaneus1447Fill,
            stroke: props.colors.Calcaneus1447Fill,
            opacity:props.colors.Calcaneus1447Fill,
          }} d="M121.1,332.66h0c23.77,3.69,45.82,4.68,65.34,1.7,20.15-9.73,30.69-22.12,28.41-38.07-3.92-12.35-2.5-21.61,5.11-27.27,57.39-17.61,114.91-22.07,142.05-36.93,10.44-11.03,20.87-18.43,31.25-15.34,10.28,7.78,10.52-2.36,8.52-16.48l4.55-15.34c-7.08-.91-11.75-7.67-10.23-29.55-.3-16.99,7.14-27.56,18.75-34.66l2.84-14.77c13.05-6.18,10.87-15.95,4.55-26.7-7-7.11-16.38-4.22-27.84,7.39l-54.55,21.59c-19.39-9.69-38.18-21.83-56.25-36.93-12.19-2.68-27.66,3.28-45.45,15.34l-28.41-11.93c-20.83-3.19-39.43,7.38-55.11,35.8-23.45,24.64-53.36,42.83-91.48,52.84-19-.55-30.31,5.46-28.41,22.73,7.64,17.92,11.29,34.42,9.09,48.86-4.93,1.58-4.05,5.34-1.7,9.66,3.24,3.07,5.44,11.38,6.82,23.86-4.23,6.94-2.46,12.2,2.84,16.48,23.41,23.76,46.61,41.88,69.32,47.73h0Z"/>
  <path id="lower_ankle_joint_overlay" data-name="lower ankle joint overlay" class="USG1447Fill" style= {{
            fill: props.colors.USG1447Fill,
            stroke: props.colors.USG1447Fill,
            opacity:props.colors.USG1447Fill,
          }} d="M185.79,68.64l2.67,8.05c14.54,7.2,31.02,10.7,49.71,9.94,17.2,3.26,35.86,11.26,55.51,22.42,12.22,16.52,27.39,17.43,46.19-.83,23.12,2.12,40.91-6.17,54.55-22.55,8.87-9.35,18.05-13.17,27.84-6.43,8.69,13.58,5.51,21.71-4.55,26.7l-2.84,14.77c-11.3,6.71-18.5,16.99-18.76,33.35-1.26,21.14,2.33,31.01,10.24,30.86l-4.55,15.34c2.07,15.36.59,22.24-5.36,18.47l6.51,20.74c9.9-17.16,12.89-35.27,9.66-54.24l.03-44.11c0-2.61.49-5.2,1.44-7.63l3.89-9.96c.33-.86.61-1.73.85-2.62l1.07-4.04c.91-3.46,2.45-6.71,4.54-9.61l2.71-3.76c3.6-5.01,5.8-10.96,5.93-17.13.11-5.27-1.23-9.63-4.51-12.77-4.03-4.69-9.18-5.73-14.62-5.04s-10.56,2.98-14.92,6.31c-19.67,15.03-40.02,25.04-61.32,28.1-4.04.58-8.15-.3-11.58-2.5l-24.22-15.5c-3.36-2.15-7.18-3.47-11.14-3.86l-38.11-1.82c-24.83-11.62-45.85-13.57-66.88-10.64h.02Z"/>
  <path id="softpart" class="Weichteile1447Fill" style= {{
            fill: props.colors.Weichteile1447Fill,
            stroke: props.colors.Weichteile1447Fill,
            opacity:props.colors.Weichteile1447Fill,
          }} d="M115.15,15c1.51,3.18-2.93,6.91-8,15-5.5,8.78-3.59,9.69-11,27-5.8,13.55-6.69,12.34-13,27-5.34,12.39-4.61,13.05-9,22-.93,1.9-5.41,10.93-13,22-5.71,8.32-9.69,12.7-14,18-7.51,9.24-18.04,22.21-25,38-1.78,4.03-9.47,22.18-9,47,.14,7.45.57,22.5,8,40,8.03,18.9,19.8,30.22,27,37,19.81,18.65,40.3,26.19,51,30,29.53,10.52,54.18,9.64,72,9,15.85-.57,39.07-1.59,66-12,20.81-8.04,22.32-13.38,52-25,26.99-10.57,39.88-11.7,45-12,11.89-.7,21.6,1.2,41,5,27.71,5.43,27.56,8.17,60,16,29.11,7.03,43.46,8.26,45,16,.82,4.15-1.88,11.82-6,11-5-1-11.45-2.43-25-8-3.61-1.48-11.74-3.65-28-8-21.08-5.63-20.65-4.64-31-8-14.65-4.76-15.51-6.74-26-9-4.54-.98-13.94-2.93-25-2-10.52.89-16.89,3.9-31,10-15.23,6.58-30.69,12.62-46,19-22.1,9.21-33.14,13.81-38,15-10.38,2.54-16.1,2.54-54,3-45.15.54-45.8.64-50,0-26.67-4.08-45.7-14.98-56-21-18.15-10.61-36.69-21.46-50-44C-.97,267.38-.25,242.04.15,228c.3-10.37,1.31-35.73,16-61,5.67-9.75,7.3-9.04,22-30,9.18-13.09,17.11-24.41,25-39,15.34-28.39,23.01-42.59,27-62,1.71-8.31,5-14,11-23,3.15-4.72,12.42-1.31,14,2Z"/>
</g>
</svg>
  );
}

export default LinkesFersenbeinLatLiegend1447;
