import { useHistory } from "react-router-dom";
import { EXAM_QA_OVERVIEW } from "../../../../Pages/Consts/ExamHelperRoutes";

import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import CompleteQaSection from "../ExamQa/CompleteQaSection";
import ExamsSection from "../ExamsSection";

function ExamHelperOverviewApp(props) {
  const { push } = useHistory();
  const selectedExam = Number(props.match.params.examId) || null;
  const setSelectedExam = (examId) => {
    push(`${EXAM_QA_OVERVIEW.link}/${examId}`);
  };
  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection
          selectedExam={selectedExam}
          selectedExamSetter={setSelectedExam}
        />
      }
      rightComponent={<CompleteQaSection selectedExamId={selectedExam} />}
    />
  );
}

export default ExamHelperOverviewApp;
