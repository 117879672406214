import style from "./style.scss";

function LinkesOberesSprunggelenkInnenrotationVdOblLiegend1463(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378.93 518.73">

<g id="upper_ankle" data-name="upper ankle">
  <g id="background_layer" data-name="background layer">
    <path id="background" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls2" d="M136.81,14.92l-44.37,157.94c-3.83,13.62-5.32,27.84-3.93,41.91,1.67,16.93,6.94,31.64,16.62,43.63,7.07,8.76,7.43,21.17,1.26,30.57L2.87,446.58c-5.79,8.81-2.49,20.71,7.02,25.28l75.14,36.1c16.36,7.86,34.43,11.52,52.56,10.65l200.35-9.67c22.91-14.59,38.67-33.86,41-61.92v-31.08c.07-37.3-5.43-72.52-20.57-104.11-2.63-5.49-1.91-12.11,2.05-16.73,8-9.33,11.24-24.43,8.87-46.31-.68-6.3-1.22-12.62-1.44-18.95l-7.55-210.26c-.39-10.88-9.36-19.47-20.25-19.4l-185.57,1.27c-8.23.06-15.43,5.55-17.65,13.47Z"/>
  </g>
  <path id="talus" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls4" d="M290.53,233.54c1.5-2.7,2.4-5.7,2.3-8.8-.6-16.4-11.3-23.2-37.3-15.5-10.3,3.5-22.8,5.9-37.9,7.1-28.3-12.9-47.5-8-57.4,14.6l-27.7,56.7c-2,4.1-4.7,7.9-8.1,11-5.1,4.6-7.5,10.3-5.5,18.2,2.9,31.2,10.9,47.9,23.6,51.8,11.9,11.3,27.8,12.9,47.2,6,7.9-6.6,11.2-14.8,11.4-24.2.1-4.1.5-8.2,1.6-12.2,6.1-22.9,15.6-28.5,26.7-26.3,4.2.9,8.7.5,12.7-1,13.3-5,25.4-11.5,36.5-19.2,3.2-2.2,6.9-3.6,10.7-4.1,11.8-1.4,11.7-11.8,1.9-29.5-4.8-8.6-5.2-16.8-.7-24.6h0Z"/>
  <path id="calcaneus" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls4" d="M320.13,305.74c3,4.9,7.5,8.9,12.7,11.3,16.9,7.9,27.4,22.7,31.5,44.5,5.1,21,6.1,39.5-2.5,52.6-1.7,2.6-1.8,6-.3,8.8,9.5,16.8,8.6,28.2-7.1,31.9-5.1,1.2-10.3,1.2-15.5.3-24.8-4.5-49.1-5.4-72.5-1.7-11.5-.2-18-5-15.7-18.1.5-2.6-1.2-5.1-3.8-5.6-16.6-3.6-24.2-12.1-25.6-24.2-.2-1.8-1.1-3.5-2.3-4.9-14.8-16.4-13.6-29.5-.6-40.2,5.1-4.2,6.6-11.4,3.5-17.3-10.2-19.2-1.3-25.3,16.4-25.4,25.4-13.3,50.3-26.1,62.2-27.8,7.4.3,13.8,6.3,19.6,15.8h0Z"/>
  <path id="toe1" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls4" d="M10.33,463.54c6.1-13.8,14.5-27.5,25.1-40.9,12-14.2,22.2-24.2,30.8-30.4l-9,30.4c-8.8,11.1-12.7,27.8-7.5,55.1,5.2,12,7.9,13.9,16,19.2"/>
  <path id="tarsus1" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls4" d="M201.93,393.24c10.1-4.2,15.6-11.6,15.2-23.1-.1-2,.1-3.9.5-5.8,3-13.2.2-24.1-10.1-32-9.9-14.4-20.6-22.7-31.8-27.3-15.3-6.2-32.6-4.4-46.2,4.8-11.3,7.6-21.4,19.2-30.3,34.5-20.2,20.9-28.9,41.3-24.8,61.1,2,22,12.8,28.6,30.4,23.6,35-4.3,64.6-16.3,91-32.7,2-1.2,4-2.3,6.1-3.1h0Z"/>
  <path id="tarsus2" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls4" d="M65.73,497.04c-7.7-4.3-13.4-10.3-16-19.2-4.9-20.6-3-39.2,7.5-55.1,5.4-30.2,20.7-52.1,44.2-67.1,18.2-13.2,23.2-.5,19.9,28.5l-4.9,21c-1.3,14.5.6,27.4,6.8,38.2,8.5,13.5,9.3,27.9,3,39.8-2.2,4-4.7,7.6-7.4,10.6"/>
  <path id="toe2" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls4" d="M93.63,498.84l25.5-64.9,9.9-23.5c2.5-5.9,2.7-12.5.7-18.5-8-23.5-6.8-35,9.7-29.5l34.3,12.8c1.9.7,3.7,1.9,5.1,3.4,10.1,11.1,12.2,21.6,1.9,31-20.4,22.5-36.2,52.9-49.9,87"/>
  <path id="ulnar" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls4" d="M341.43,1.54c-4.2,5.6-2.4,179.3,7.5,235.1,1,5.8,2.9,11.3,5.4,16.7,10,21.7,5.3,35.8-10.6,44.1-18.8,6.5-32.5-1.3-40.5-25.5-6.2-11.3-7.2-24.2-4.7-38,.3-1.9.3-3.8-.2-5.7-2.9-11.3-3.6-22.4-1.1-33.2,4.9-21,13.9-193.5,13.9-193.5"/>
  <path id="radius" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls4" d="M277.33,4.94c3.3,42.9,8.8,83.4,17.6,120.1,1.4,5.9,3,11.8,4.9,17.5,6.7,20.3,4.5,37.6-10,50.7-7.1,6.5-16.1,10.6-25.6,12.3-18.3,3.3-31.7,2-42.4-1.9-27.1-10.4-49.4-9.5-62.4,14.1-1.6,2.8-2.6,5.9-3.4,9.1-5.1,22.5-12.5,33.8-23.4,27.8-2.4-1.3-4.4-3.3-6-5.6-16.9-24.2-20.6-44.7-12.7-62,2.3-5.1,4.6-10.2,6.6-15.4C149.13,95.94,165.33,38.24,168.23.04"/>
  <path id="toe3" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls4" d="M197.83,498.04c6.5-19.9,15.1-38.5,27.2-55,2.8-3.9,4-8.7,3-13.4-1.5-7-6-12.2-15.2-14.5-10-9.4-19.8-10.2-29.4,5.1-2.5,4-4,8.4-4.7,13-3.2,21.2-11.8,44.1-22.9,67.8"/>
  <path id="toe4" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls4" d="M207.63,499.54c5.6-5.4,9.7-13.7,13.1-23.6,3.7-19.4,9.4-32.4,20.2-28.1,15.7,6.8,21.5,14.2,21.4,22.1,1.1,6,.4,11.6-4.9,15.8-4.7,6-7.4,10.6-5.6,12.4"/>
  <path id="toe5" class="oberes_Sprunggelenk_Innenrotation1463_vd_obl_links_cls4" d="M328.73,495.44c-2-8.2-5-16-10.8-22.5s-14.4-9.9-23.1-9.7c-6.8.2-12.9,1.8-18.2,4.8-10,7.7-15.6,17.3-17.2,28.5"/>
</g>
<g id="overlays">
  <path id="upper_ankle_joint_overlay" data-name="upper ankle joint overlay" class="SG1463Fill" style= {{
            fill: props.colors.SG1463Fill,
            stroke: props.colors.SG1463Fill,
            opacity:props.colors.SG1463Fill,
          }} d="M136.86,278.77c-9.72-5.86-11.5-13.51-2.91-23.5,7.12,4.53,13.32-.73,18.6-15.96l5.62-19.04c7.13-17.07,21.14-23.25,39.34-22.77,7.74.48,17.61,3.49,28.25,7.4,14.09,3.2,28.11,3.19,42.06-.14,12.54-2.24,22.82-10.53,31.34-23.46l-1.94,13.72c-2.35,10.74-1.99,22.37,1.43,35.01-1.94,16.01-2.35,28.37,4.57,41.89,8.84,25.42,22.73,32.52,40.5,25.5,2.55,8.8.96,16.08-7.76,20.74-8.79-3.8-14.42-8.81-17.38-14.84-6.53-9.61-13.24-14.42-20.2-13l-4.31-4.05c6.98-3,5.28-12.98-2.85-28.14-5.11-7.91-5.01-16.13-.7-24.6,4.79-13.82,1.07-21.99-8.53-26.26-9.63-1.99-20.16-.36-31.24,3.45-9.6,2.86-20.9,4.55-33.13,5.61-13.46-6.64-25.77-8.86-36.69-5.7-10.43,1.96-18.62,13.42-25.11,29.31l-18.97,38.83Z"/>
  <g id="Outer_ankle_overlay" data-name="Outer ankle overlay">
    <path id="Outer_ankle_overlay_1" data-name="Outer ankle overlay 1" class="OuterAnkles1463Fill" style= {{
            fill: props.colors.OuterAnkles1463Fill,
            stroke: props.colors.OuterAnkles1463Fill,
            opacity:props.colors.OuterAnkles1463Fill,
          }} d="M352.77,249.75c-22.12-3.28-38.75,3.9-49.54,22.19,5.13,14.94,12.68,25.81,25.5,27.87,15.18-.15,25.72-6.15,30.13-19.89,1.92-11.04-1.23-20.82-6.08-30.18Z"/>
    <path id="Outer_ankle_overlay_2" data-name="Outer ankle overlay 2" class="OuterAnkles1463Fill" style= {{
            fill: props.colors.OuterAnkles1463Fill,
            stroke: props.colors.OuterAnkles1463Fill,
            opacity:props.colors.OuterAnkles1463Fill,
          }} d="M158.17,220.28c-13.37-16.72-29.23-24.43-47.16-24.56-2.43,10.77-1.65,21.08,3.25,30.81,9.27,19.67,18.27,34.09,26.31,29.34,7.62-1.12,12.78-17.32,17.6-35.59Z"/>
  </g>
</g>
</svg>
  );
}

export default LinkesOberesSprunggelenkInnenrotationVdOblLiegend1463;
