import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../Redux/mappers/pricelist/pricelistMappers";
import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web/lib/useKeycloak";
import {
  loadFacilties,
  loadPriceListV2,
} from "../../Utilities/PriceLists/PriceListsUtils";
import { NotificationManager } from "react-notifications";
import { ClipLoader } from "react-spinners";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setChangedPrices } from "../../Redux/actions";

import "./styles/PriceListEditor.scss";

const loadingStates = {
  notLoaded: 0,
  loading: 1,
  loaded: 2,
  failed: 3,
  loadingMore: 4,
};

const languageVariantMap = {
  en: "ln_designation_en",
  pl: "ln_designation_pl",
  de: "ln_designation_de",
  EN: "ln_designation_en",
  PL: "ln_designation_pl",
  DE: "ln_designation_de",
};

const currencyMap = {
  PL: "zł",
  pl: "zł",
  DE: "€",
  de: "€",
  EN: "£",
  en: "£",
  base: "$",
};

const capitalize = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

const currentLanguage = window.conf.LANG;
const currency = currencyMap[currentLanguage];
const languageAccessor = languageVariantMap[currentLanguage];

function PriceListEditor(props) {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const facilitiesId = Object.keys(props.pricelists.facility);
  const facilityName = props.pricelists.facility[facilitiesId[0]].name;

  const [facility, setFacility] = useState([]);
  // #region PriceListData
  const [priceListData, setPriceListData] = useState(undefined);
  const [priceListDataLoadingState, setPriceListLoadingState] = useState(
    loadingStates.notLoaded
  );
  const [priceListPageNr, setPriceListPageNr] = useState(1);
  const [priceListPageSize, setPriceListPageSize] = useState(10);
  const [priceListSearchQuery, setPriceListSearchQuery] = useState("");
  // #endregion PriceListData
  const [alteredPrices, setAlteredPrices] = useState(
    props.pricelists.changedPrices
  );

  const loadCompareFacilities = () => {
    loadFacilties(keycloak).then((rdata) => {
      let facilities = [];
      for (let i = 0; i < rdata.FacilitiesList.total_count; i++) {
        if (
          !Object.keys(props.pricelists.facility).find((element) => {
            return parseInt(element) === rdata.FacilitiesList.facilities[i].id;
          })
        )
          facilities.push([
            rdata.FacilitiesList.facilities[i].id,
            rdata.FacilitiesList.facilities[i].name,
          ]);
      }
      setFacility(facilities);
    });
  };

  const loadPriceList = () => {
    setPriceListLoadingState(loadingStates.loading);
    let filter = undefined;
    //skip searching when less then 3 chars just wasteing time => no usable output
    if (priceListSearchQuery.length >= 3) {
      filter = {
        or: [
          {
            model: "jw_languages",
            field: languageAccessor,
            op: "like",
            value: `%${priceListSearchQuery}%`,
          },
          {
            model: "jw_languages",
            field: languageAccessor,
            op: "like",
            value: `%${priceListSearchQuery.toUpperCase()}%`,
          },
          {
            model: "jw_languages",
            field: languageAccessor,
            op: "like",
            value: `%${priceListSearchQuery.toLowerCase()}%`,
          },
          {
            model: "jw_languages",
            field: languageAccessor,
            op: "like",
            value: `%${capitalize(priceListSearchQuery)}%`,
          },
        ],
      };
    }

    loadPriceListV2(
      keycloak,
      facilitiesId[0],
      priceListPageSize,
      priceListPageNr,
      filter
    )
      .then((data) => {
        setPriceListData(data);
        setPriceListLoadingState(loadingStates.loaded);
      })
      .catch((reason) => {
        NotificationManager.error("Failed to load Price List");
        console.warn("Failed to load Price List", reason);
        setPriceListData(undefined);
        setPriceListLoadingState(loadingStates.failed);
      });
  };

  useEffect(() => {
    loadCompareFacilities();
    loadPriceList();
  }, []);

  useEffect(() => {
    priceListSearchQuery.length === 0 && loadPriceList();
  }, [priceListSearchQuery]);

  useEffect(() => {
    loadPriceList();
  }, [priceListPageSize, priceListPageNr]);

  useEffect(() => {
    dispatch(setChangedPrices(alteredPrices));
  }, [alteredPrices]);

  return (
    <div className="d-flex flex-column" style={{ height: "88vh" }}>
      <div className="d-flex mb-2" style={{ gap: "10px" }}>
        <div className="w-50">
          <h5>Search Procedure</h5>
          <div className="d-flex">
            <InputGroup className="">
              <FormControl
                className="py-0"
                placeholder="i.e. spine"
                onChange={(e) => {
                  setPriceListSearchQuery(e.target.value.trim());
                }}
              />
            </InputGroup>
            <Button onClick={loadPriceList}>
              <i className="fas fa-search fa-sm" />
            </Button>
          </div>
        </div>

        <div className="d-flex flex-column w-50">
          <h5>Add Facility Price List To Compare</h5>
          <div className="d-flex">
            <select id="facilityChoice" className="form-control">
              {facility.map((f) => (
                <option key={f[0]} value={f[0]}>
                  {f[1]}
                </option>
              ))}
            </select>
            <Button>
              <i className="fas fa-plus fa-sm" />
            </Button>
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-column"
        style={{ height: "75vh", overflow: "auto" }}
      >
        {priceListDataLoadingState === loadingStates.loading && (
          <ClipLoader size={100} />
        )}
        {priceListDataLoadingState === loadingStates.failed && (
          <h5>Failed to load Price List. Contact Support.</h5>
        )}
        {priceListDataLoadingState === loadingStates.loaded &&
          priceListData?.procedures?.map((procedure, procKey) => (
            <div
              className="d-flex border rounded w-100 mb-1"
              key={`procedure_key${procKey}`}
            >
              <div
                className="d-flex flex-column border-right p-2"
                style={{ width: "10%" }}
              >
                <div className="small text-muted">Procedure ID</div>
                <div>{procedure.id}</div>
              </div>
              <div
                className="d-flex flex-column border-right p-2"
                style={{ width: "40%" }}
              >
                <div className="small text-muted">Procedure description</div>
                <div>{procedure.jw_languages[languageAccessor]}</div>
              </div>

              <div
                className="d-flex flex-column border-right p-2 bg-light"
                style={{ width: "35%" }}
              >
                <div className="small text-muted">Default Price</div>

                <PriceInput
                  jwProcId={procedure.id}
                  procedureId={procedure.defPrice.id}
                  procedureAID={procedure.defPrice.a_id}
                  procedureFac="Default"
                  procedureName={procedure.jw_languages[languageAccessor]}
                  procedurePrice={procedure.defPrice.price}
                  alteredPrices={alteredPrices}
                  setAlteredPrices={setAlteredPrices}
                />
              </div>
              <div
                className="d-flex flex-column border-right p-2"
                style={{ width: "35%" }}
              >
                <div className="small text-muted">{facilityName} Price</div>
                <PriceInput
                  skipValidation={true}
                  jwProcId={procedure.id}
                  procedureId={procedure.price.id}
                  procedureAID={procedure.price.a_id}
                  procedureFac={facilityName}
                  procedureName={procedure.jw_languages[languageAccessor]}
                  procedurePrice={procedure.price.price}
                  alteredPrices={alteredPrices}
                  setAlteredPrices={setAlteredPrices}
                />
              </div>
            </div>
          ))}
      </div>
      <div className="d-flex justify-content-between mt-2">
        <div>
          <Button
            disabled={priceListPageNr === 1}
            onClick={() => {
              setPriceListPageNr(1);
            }}
          >
            <i className="fas fa-angle-double-left fa-sm" />
          </Button>
          <Button
            disabled={priceListPageNr === 1}
            onClick={() => {
              setPriceListPageNr(priceListPageNr - 1);
            }}
          >
            <i className="fas fa-angle-left fa-sm" />
          </Button>
        </div>
        <div className="border rounded p-2 d-flex justify-content-center align-items-center">
          {priceListPageNr} /{" "}
          {Math.floor(priceListData?.total_count / priceListPageSize)}
          <div className="d-flex justify-content-center align-items-center ml-2 border-left">
            <input
              type="number"
              value={priceListPageSize}
              className="ml-2 page-size-input"
              onChange={(e) => {
                setPriceListPageSize(e.target.value);
              }}
            ></input>
            <div className="ml-1">Items</div>
          </div>
        </div>
        <div>
          <Button
            disabled={
              Math.floor(priceListData?.total_count / priceListPageSize) ===
              priceListPageNr
            }
            onClick={() => {
              setPriceListPageNr(priceListPageNr + 1);
            }}
          >
            <i className="fas fa-angle-right fa-sm" />
          </Button>
          <Button
            onClick={() => {
              setPriceListPageNr(
                Math.floor(priceListData?.total_count / priceListPageSize)
              );
            }}
            disabled={
              Math.floor(priceListData?.total_count / priceListPageSize) ===
              priceListPageNr
            }
          >
            <i className="fas fa-angle-double-right fa-sm" />
          </Button>
        </div>
      </div>
    </div>
  );
}

function PriceInput({
  skipValidation = false,
  jwProcId,
  procedureId,
  procedureName,
  procedurePrice,
  procedureAID,
  procedureFac,
  alteredPrices,
  setAlteredPrices,
}) {
  const [value, setValue] = useState(
    Object.keys(alteredPrices).includes(procedureId.toString())
      ? alteredPrices[procedureId].price
      : procedurePrice
  );

  const setValueAndUpdateAltered = (e) => {
    setValue(e.target.value);
    let alteredPricesCopy = { ...alteredPrices };

    if (Object.keys(alteredPricesCopy).includes(procedureId.toString())) {
      alteredPricesCopy[procedureId].price = e.target.value;
      if (
        e.target.value == procedurePrice ||
        e.target.value == procedurePrice.toString()
      ) {
        delete alteredPricesCopy[procedureId];
      }
      setAlteredPrices(alteredPricesCopy);
      return;
    }

    if (
      e.target.value != procedurePrice ||
      e.target.value != procedurePrice.toString()
    ) {
      let alterPriceObj = {
        id: procedureId,
        jwProcId,
        description: procedureName,
        oriPrice: procedurePrice,
        price: e.target.value,
        a_id: procedureAID,
        facName: procedureFac,
      };
      alteredPricesCopy[procedureId] = alterPriceObj;
      setAlteredPrices(alteredPricesCopy);
    }
  };

  return (
    <InputGroup>
      <FormControl
        onChange={setValueAndUpdateAltered}
        type={"number"}
        value={value}
        isInvalid={!skipValidation && value == 0}
        isValid={
          value != 0 &&
          Object.keys(alteredPrices).includes(procedureId.toString())
        }
        className="py-0"
        aria-label={procedureId}
        aria-describedby={procedureId}
      />
      <InputGroup.Append>
        <InputGroup.Text id={procedureId}>{currency}</InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceListEditor);
