import AddPatientModal from "./AddPatientModal";
import { Button } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

function AddPatientButton() {
  const [modalShow, setModalShow] = React.useState(false);
  const { t } = useTranslation(["patientjournal", "common"]);
  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        <i className="fas fa-plus ml-1"></i>
        {t("addPatient.buttonText")}
      </Button>
      <AddPatientModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
export default AddPatientButton;
