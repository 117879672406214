import style from "./style.scss";

function LinkeFusswurzelLatLiegend1455(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.64 213.74">
 
 <g id="foot">
   <g id="BG">
     <path id="Background_1" data-name="Background 1" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M80.83,2.33c-6.9,20.7-10,36.5-3.6,49.3,1.4,5.7,9.5,10.2,18.6,14.4,21.4,15.8,36,17.8,41.9,2.2l7.5-8.8c13-15.7,15.5-35.5,10.3-58.4"/>
     <path id="Background_2" data-name="Background 2" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M79.83,59.03c-1.5,2-4,4.1-7.1,6.3-3.2,2.8-5.4,6.7-6.8,11.4.6,2.9-1.9,6.2-6,9.8h0c-7.3,8-7.6,14.3,2.6,18.3,6.8,3.3,12.8,4.9,17.6,4.5,3.5-1,6.4-2.8,8.6-5.6,1.2-1.6,3.6-1.5,4.7.1,9.8,14.1,19.1,15.8,28.1,8.8,11,14.1,21.2,21.2,30.2,18.9,7,.1,13-2.1,17.9-6.4,7-7,11-13.8,11.5-20.6.7-10.8-2-17.7-8.7-20.3-6.8-.9-13-3.4-18.2-7.9l-15.3-11.6"/>
     <path id="Background_3" data-name="Background 3" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M139.23,64.83c-12.9-10.1-25.3-14.7-36.9-13.1l-22.4,7.3"/>
     <path id="Background_4" data-name="Background 4" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M1.83,150.63c-3.4-16.8,1.7-26,17.4-25.8l40.7-18.9c1.4-.7,2.7-1.6,3.6-2.9,8.8-11,17.9-11.3,27.2-4.4.9.6,1.9.9,3,.9,11.1-.9,20.1,4.5,27.9,13.2,14.3-6.1,25.6-4.8,32.2,7.8,6.7,4.4,2.8,9.7-8.4,15.7l-.5,24.4c0,2.1-1.6,3.8-3.6,4.1-1.7.3-3.8.3-6.1.3-2.8,9.5-27.8,16-58.8,21.8-12.2,2.3-19.1,6.7-21.4,12.8-.7,2-2.4,3.5-4.5,4.2-17.9,5.6-33,3-43-13.3-1.8-3-2.9-6.3-3.2-9.8l-2-25.9c0-1.5-.2-2.9-.5-4.2h0Z"/>
     <path id="Background_5" data-name="Background 5" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M150.03,160.43c-4.8,9.5-.9,15.6,13.3,17.5,15.8.3,27.5-3.1,33.7-11.3,3.2-8.9,4.1-17.4-5-24.6-17.4-8.1-31.8-10.6-41.5-5l-.5,23.4h0Z"/>
     <path id="Background_6" data-name="Background 6" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M176.63,158.43c6.5,1.8,8.9-.3,8.8-5.1,18.1-13.2,26.7-29.5,25-48.9-2.1.3-3.6-.4-3.7-3.2-5.7-1.4-10.3-3.4-12.3-7.2-3.2-.1-6.3-.8-9.6-2.8-.7-.4-1.5.2-1.3,1,2.9,10.4.9,18.2-3.7,26.6-3.9,6.5-10.9,12.2-19.8,16.2-4.5,4.5-4.5,9.7-.7,15.6,3.8,4.6,9.4,7.3,17.3,7.8h0Z"/>
     <path id="Background_7" data-name="Background 7" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M195.73,147.33l.9,16.1c6.8,3.3,13.3,5.3,19.2,5.2,9.4,6.2,18,9.8,24.7,7.6,4.8-3.1,7.5-9.5,8.2-18.7,11.9-19.2,21.2-36.9,11.1-43.6l-13.4-7.1-28.9-2.2c-2.4-.2-4.4,2-4,4.4,2.7,16.6-2.9,29.5-17.8,38.3h0Z"/>
     <path id="Background_9" data-name="Background 9" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M301.64,134.42c-5.81-2.76-11.47-5.68-16.9-8.79-11.6-10.3-17.7-10.5-18.3-.6-.1,2-.5,4-1.1,5.9-2.9,10.1-7.4,20.5-14,31.2-4.3,7.1-4.4,11.4-.2,12.7,1.7.5,3.6.3,5.3-.4,14.32-6.12,28.9-9.58,43.75-7.79"/>
     <path id="Background_10" data-name="Background 10" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M141.43,64.03c-4.7-6-9.9-11.4-17.5-14.1-11.3-4.6-21.8-5.5-31.2-1.3-5.5,3.1-10.7,3.6-15.7,1.7"/>
     <path id="Background_11" data-name="Background 11" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M115.43,2.63c-2.9,10.9-1.5,22.2,2.7,33.9,3.2,4,1,9.1-6.1,15.3-3.1,7.5-8.2,11-15.9,9.5-1.3-.3-2.5-1-3.4-1.9l-11-11.4c-6.9-6.6-9.3-13.5-2.7-21.4,8.5-8.6,14.8-17.4,16.6-26.2"/>
     <path id="Background_12" data-name="Background 12" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M211.13,127.93c11.8.4,19.6,4.5,22,13.7-.3,6.9-5.6,12-14.3,15.8-7,4.2-14.6,4.3-22.8.7"/>
     <path id="Background_13" data-name="Background 13" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M191.13,127.93c-.6-11.7,6.8-16.4,17.8-17.9l23.5-2.7c12.7,1.5,13.9,8,10,16.8-7.2,10.3-15.8,19.1-25.7,26.4-16,4.4-24.7-2.9-25.6-22.6h0Z"/>
     <path id="Background_14" data-name="Background 14" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M301.34,141.84c-16.49-7.02-32.01-15.3-45.11-26.71-8.7-5.3-9.8,4.1-10.1,15l54.12,34.83"/>
     <path id="Background_15" data-name="Background 15" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M300.65,156.51c-15.51-6.21-30.55-13.71-44.82-23.28-10.5-12.5-15.4-10.3-16.2,2.6l-2.6,26,62.51,22.53"/>
     <path id="Background_16" data-name="Background 16" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M300.02,170.32c-2.89-.8-5.79-1.59-8.69-2.39l-53.2-20c-9.9,1.6-8.9,11.8-5.8,23.9l67.09,18.86"/>
     <path id="Background_17" data-name="Background 17" class="Linke_Fusswurzel1455_lat_liegend_cls2" d="M299.56,183.59c-23.61-9.56-55.2-16.1-87.83-22.26-16.2-.4-26.5,6.9-33,19.4-8.9,6.8-9,12.2,4,15.6,11.5,2.1,24.1-.2,37-4.1,29.3-1.2,55.2,1.9,72.2,16.2,1.13,3.53,3.86,5.08,7.79,5.14"/>
   </g>
   <path id="radius_1" data-name="radius 1" class="linke_fusswurzel1455_lat_liegend_cls1" d="M80.5,2c-6.9,20.7-10,36.5-3.6,49.3,1.4,5.7,9.5,10.2,18.6,14.4,21.4,15.8,36,17.8,41.9,2.2l7.5-8.8c13-15.7,15.5-35.5,10.3-58.4"/>
   <path id="talus_1" data-name="talus 1" class="linke_fusswurzel1455_lat_liegend_cls1" d="M79.5,58.7c-1.5,2-4,4.1-7.1,6.3-3.2,2.8-5.4,6.7-6.8,11.4.6,2.9-1.9,6.2-6,9.8h0c-7.3,8-7.6,14.3,2.6,18.3,6.8,3.3,12.8,4.9,17.6,4.5,3.5-1,6.4-2.8,8.6-5.6,1.2-1.6,3.6-1.5,4.7.1,9.8,14.1,19.1,15.8,28.1,8.8,11,14.1,21.2,21.2,30.2,18.9,7,.1,13-2.1,17.9-6.4,7-7,11-13.8,11.5-20.6.7-10.8-2-17.7-8.7-20.3-6.8-.9-13-3.4-18.2-7.9l-15.3-11.6"/>
   <path id="calcaneus" class="linke_fusswurzel1455_lat_liegend_cls1" d="M1.5,150.3c-3.4-16.8,1.7-26,17.4-25.8l40.7-18.9c1.4-.7,2.7-1.6,3.6-2.9,8.8-11,17.9-11.3,27.2-4.4.9.6,1.9.9,3,.9,11.1-.9,20.1,4.5,27.9,13.2,14.3-6.1,25.6-4.8,32.2,7.8,6.7,4.4,2.8,9.7-8.4,15.7l-.5,24.4c0,2.1-1.6,3.8-3.6,4.1-1.7.3-3.8.3-6.1.3-2.8,9.5-27.8,16-58.8,21.8-12.2,2.3-19.1,6.7-21.4,12.8-.7,2-2.4,3.5-4.5,4.2-17.9,5.6-33,3-43-13.3-1.8-3-2.9-6.3-3.2-9.8l-2-25.9c0-1.5-.2-2.9-.5-4.2h0Z"/>
   <path id="tarsus1" class="linke_fusswurzel1455_lat_liegend_cls1" d="M149.7,160.1c-4.8,9.5-.9,15.6,13.3,17.5,15.8.3,27.5-3.1,33.7-11.3,3.2-8.9,4.1-17.4-5-24.6-17.4-8.1-31.8-10.6-41.5-5l-.5,23.4h0Z"/>
   <path id="tarsus2" class="linke_fusswurzel1455_lat_liegend_cls1" d="M176.3,158.1c6.5,1.8,8.9-.3,8.8-5.1,18.1-13.2,26.7-29.5,25-48.9-2.1.3-3.6-.4-3.7-3.2-5.7-1.4-10.3-3.4-12.3-7.2-3.2-.1-6.3-.8-9.6-2.8-.7-.4-1.5.2-1.3,1,2.9,10.4.9,18.2-3.7,26.6-3.9,6.5-10.9,12.2-19.8,16.2-4.5,4.5-4.5,9.7-.7,15.6,3.8,4.6,9.4,7.3,17.3,7.8h0Z"/>
   <path id="tarsus3" class="linke_fusswurzel1455_lat_liegend_cls1" d="M195.4,147l.9,16.1c6.8,3.3,13.3,5.3,19.2,5.2,9.4,6.2,18,9.8,24.7,7.6,4.8-3.1,7.5-9.5,8.2-18.7,11.9-19.2,21.2-36.9,11.1-43.6l-13.4-7.1-28.9-2.2c-2.4-.2-4.4,2-4,4.4,2.7,16.6-2.9,29.5-17.8,38.3h0Z"/>
   <path id="toe5" class="linke_fusswurzel1455_lat_liegend_cls1" d="M301.3,134.09c-5.81-2.76-11.47-5.68-16.9-8.79-11.6-10.3-17.7-10.5-18.3-.6-.1,2-.5,4-1.1,5.9-2.9,10.1-7.4,20.5-14,31.2-4.3,7.1-4.4,11.4-.2,12.7,1.7.5,3.6.3,5.3-.4,14.32-6.12,28.9-9.58,43.75-7.79"/>
   <path id="radius_2" data-name="radius 2" class="linke_fusswurzel1455_lat_liegend_cls1" d="M141.1,63.7c-4.7-6-9.9-11.4-17.5-14.1-11.3-4.6-21.8-5.5-31.2-1.3-5.5,3.1-10.7,3.6-15.7,1.7"/>
   <path id="ulnar" class="linke_fusswurzel1455_lat_liegend_cls1" d="M115.1,2.3c-2.9,10.9-1.5,22.2,2.7,33.9,3.2,4,1,9.1-6.1,15.3-3.1,7.5-8.2,11-15.9,9.5-1.3-.3-2.5-1-3.4-1.9l-11-11.4c-6.9-6.6-9.3-13.5-2.7-21.4,8.5-8.6,14.8-17.4,16.6-26.2"/>
   <path id="tarsus5" class="linke_fusswurzel1455_lat_liegend_cls1" d="M210.8,127.6c11.8.4,19.6,4.5,22,13.7-.3,6.9-5.6,12-14.3,15.8-7,4.2-14.6,4.3-22.8.7"/>
   <path id="tarsus4" class="linke_fusswurzel1455_lat_liegend_cls1" d="M190.8,127.6c-.6-11.7,6.8-16.4,17.8-17.9l23.5-2.7c12.7,1.5,13.9,8,10,16.8-7.2,10.3-15.8,19.1-25.7,26.4-16,4.4-24.7-2.9-25.6-22.6h0Z"/>
   <path id="toe3" class="linke_fusswurzel1455_lat_liegend_cls1" d="M301.01,141.51c-16.49-7.02-32.01-15.3-45.11-26.71-8.7-5.3-9.8,4.1-10.1,15l54.12,34.83"/>
   <path id="toe4" class="linke_fusswurzel1455_lat_liegend_cls1" d="M300.32,156.18c-15.51-6.21-30.55-13.71-44.82-23.28-10.5-12.5-15.4-10.3-16.2,2.6l-2.6,26,62.51,22.53"/>
   <path id="toe2" class="linke_fusswurzel1455_lat_liegend_cls1" d="M299.69,169.99c-2.89-.8-5.79-1.59-8.69-2.39l-53.2-20c-9.9,1.6-8.9,11.8-5.8,23.9l67.09,18.86"/>
   <path id="toe1" class="linke_fusswurzel1455_lat_liegend_cls1" d="M299.23,183.26c-23.61-9.56-55.2-16.1-87.83-22.26-16.2-.4-26.5,6.9-33,19.4-8.9,6.8-9,12.2,4,15.6,11.5,2.1,24.1-.2,37-4.1,29.3-1.2,55.2,1.9,72.2,16.2,1.13,3.53,3.86,5.08,7.79,5.14"/>
   <path id="talus_2" data-name="talus 2" class="linke_fusswurzel1455_lat_liegend_cls1" d="M138.9,64.5c-12.9-10.1-25.3-14.7-36.9-13.1l-22.4,7.3"/>
 </g>
 <g id="overlays">
   <path id="calcaneus_overlay" data-name="calcaneus overlay" class="calcaneus1455fill" style= {{
             fill: props.colors.calcaneus1455fill,
             stroke: props.colors.calcaneus1455fill,
             opacity:props.colors.calcaneus1455fill,
           }} d="M1.83,150.18c-3.4-16.8,1.7-26,17.4-25.8l40.7-18.9c1.4-.7,2.7-1.6,3.6-2.9,8.8-11,17.9-11.3,27.2-4.4.9.6,1.9.9,3,.9,11.1-.9,20.1,4.5,27.9,13.2,14.3-6.1,25.6-4.8,32.2,7.8,6.7,4.4,2.8,9.7-8.4,15.7l-.5,24.4c0,2.1-1.6,3.8-3.6,4.1-1.7.3-3.8.3-6.1.3-2.8,9.5-27.8,16-58.8,21.8-12.2,2.3-19.1,6.7-21.4,12.8-.7,2-2.4,3.5-4.5,4.2-17.9,5.6-33,3-43-13.3-1.8-3-2.9-6.3-3.2-9.8l-2-25.9c0-1.5-.2-2.8-.5-4.2h0Z"/>
   <path id="talus_overlay" data-name="talus overlay" class="talus1455fill" style= {{
             fill: props.colors.talus1455fill,
             stroke: props.colors.talus1455fill,
             opacity:props.colors.talus1455fill,
           }} d="M139.23,64.78c-12.9-10.1-25.3-14.7-36.9-13.1l-22.4,7.3-.56.18c-1.53,1.82-3.84,3.73-6.63,5.72-3.2,2.8-5.4,6.6-6.8,11.4.6,2.9-1.9,6.2-6,9.8-7.3,8-7.6,14.3,2.6,18.3,6.8,3.3,12.8,4.9,17.6,4.5,3.5-1,6.4-2.8,8.6-5.6,1.2-1.6,3.6-1.5,4.7.1,9.8,14.1,19.1,15.8,28.1,8.8,11,14.1,21.2,21.2,30.2,18.9,7,.1,13-2.1,17.9-6.4,7-7,11-13.8,11.5-20.6.7-10.8-2-17.7-8.7-20.3-6.8-.9-13-3.4-18.2-7.9l-15.3-11.6"/>
 </g>
</svg>
  );
}

export default LinkeFusswurzelLatLiegend1455;
