import style from "./style.scss";

function RechteFusswurzelLatLiegend1456(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.64 213.74">
  
  <g id="foot">
    <g id="BG">
      <path id="Background_1" data-name="Background 1" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M146.11,1.03c-5.2,22.9-2.7,42.7,10.3,58.4l7.5,8.8c5.9,15.6,20.5,13.6,41.9-2.2,9.1-4.2,17.2-8.7,18.6-14.4,6.4-12.8,3.3-28.6-3.6-49.3"/>
      <path id="Background_2" data-name="Background 2" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M162.71,64.73l-15.3,11.6c-5.2,4.5-11.4,7-18.2,7.9-6.7,2.6-9.4,9.5-8.7,20.3.5,6.8,4.5,13.6,11.5,20.6,4.9,4.3,10.9,6.5,17.9,6.4,9,2.3,19.2-4.8,30.2-18.9,9,7,18.3,5.3,28.1-8.8,1.1-1.6,3.5-1.7,4.7-.1,2.2,2.8,5.1,4.6,8.6,5.6,4.8.4,10.8-1.2,17.6-4.5,10.2-4,9.9-10.3,2.6-18.3h0c-4.1-3.6-6.6-6.9-6-9.8-1.4-4.7-3.6-8.6-6.8-11.4-3.1-2.2-5.6-4.3-7.1-6.3"/>
      <path id="Background_3" data-name="Background 3" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M221.71,59.03l-22.4-7.3c-11.6-1.6-24,3-36.9,13.1"/>
      <path id="Background_4" data-name="Background 4" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M299.81,150.63h0c-.3,1.3-.5,2.7-.5,4.2l-2,25.9c-.3,3.5-1.4,6.8-3.2,9.8-10,16.3-25.1,18.9-43,13.3-2.1-.7-3.8-2.2-4.5-4.2-2.3-6.1-9.2-10.5-21.4-12.8-31-5.8-56-12.3-58.8-21.8-2.3,0-4.4,0-6.1-.3-2-.3-3.6-2-3.6-4.1l-.5-24.4c-11.2-6-15.1-11.3-8.4-15.7,6.6-12.6,17.9-13.9,32.2-7.8,7.8-8.7,16.8-14.1,27.9-13.2,1.1,0,2.1-.3,3-.9,9.3-6.9,18.4-6.6,27.2,4.4.9,1.3,2.2,2.2,3.6,2.9l40.7,18.9c15.7-.2,20.8,9,17.4,25.8h0Z"/>
      <path id="Background_5" data-name="Background 5" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M151.61,160.43l-.5-23.4c-9.7-5.6-24.1-3.1-41.5,5-9.1,7.2-8.2,15.7-5,24.6,6.2,8.2,17.9,11.6,33.7,11.3,14.2-1.9,18.1-8,13.3-17.5h0Z"/>
      <path id="Background_6" data-name="Background 6" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M125.01,158.43h0c7.9-.5,13.5-3.2,17.3-7.8,3.8-5.9,3.8-11.1-.7-15.6-8.9-4-15.9-9.7-19.8-16.2-4.6-8.4-6.6-16.2-3.7-26.6.2-.8-.6-1.4-1.3-1-3.3,2-6.4,2.7-9.6,2.8-2,3.8-6.6,5.8-12.3,7.2-.1,2.8-1.6,3.5-3.7,3.2-1.7,19.4,6.9,35.7,25,48.9-.1,4.8,2.3,6.9,8.8,5.1h0Z"/>
      <path id="Background_7" data-name="Background 7" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M105.91,147.33h0c-14.9-8.8-20.5-21.7-17.8-38.3.4-2.4-1.6-4.6-4-4.4l-28.9,2.2-13.4,7.1c-10.1,6.7-.8,24.4,11.1,43.6.7,9.2,3.4,15.6,8.2,18.7,6.7,2.2,15.3-1.4,24.7-7.6,5.9.1,12.4-1.9,19.2-5.2l.9-16.1h0Z"/>
      <path id="Background_9" data-name="Background 9" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M1.45,166.64c14.85-1.79,29.43,1.67,43.75,7.79,1.7.7,3.6.9,5.3.4,4.2-1.3,4.1-5.6-.2-12.7-6.6-10.7-11.1-21.1-14-31.2-.6-1.9-1-3.9-1.1-5.9-.6-9.9-6.7-9.7-18.3.6-5.43,3.11-11.09,6.03-16.9,8.79"/>
      <path id="Background_10" data-name="Background 10" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M224.61,50.33c-5,1.9-10.2,1.4-15.7-1.7-9.4-4.2-19.9-3.3-31.2,1.3-7.6,2.7-12.8,8.1-17.5,14.1"/>
      <path id="Background_11" data-name="Background 11" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M206.01.43c1.8,8.8,8.1,17.6,16.6,26.2,6.6,7.9,4.2,14.8-2.7,21.4l-11,11.4c-.9.9-2.1,1.6-3.4,1.9-7.7,1.5-12.8-2-15.9-9.5-7.1-6.2-9.3-11.3-6.1-15.3,4.2-11.7,5.6-23,2.7-33.9"/>
      <path id="Background_12" data-name="Background 12" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M105.61,158.13c-8.2,3.6-15.8,3.5-22.8-.7-8.7-3.8-14-8.9-14.3-15.8,2.4-9.2,10.2-13.3,22-13.7"/>
      <path id="Background_13" data-name="Background 13" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M110.51,127.93h0c-.9,19.7-9.6,27-25.6,22.6-9.9-7.3-18.5-16.1-25.7-26.4-3.9-8.8-2.7-15.3,10-16.8l23.5,2.7c11,1.5,18.4,6.2,17.8,17.9h0Z"/>
      <path id="Background_14" data-name="Background 14" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M1.39,164.96l54.12-34.83c-.3-10.9-1.4-20.3-10.1-15-13.1,11.41-28.62,19.69-45.11,26.71"/>
      <path id="Background_15" data-name="Background 15" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M2.1,184.36l62.51-22.53-2.6-26c-.8-12.9-5.7-15.1-16.2-2.6-14.27,9.57-29.31,17.07-44.82,23.28"/>
      <path id="Background_16" data-name="Background 16" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M2.22,190.69l67.09-18.86c3.1-12.1,4.1-22.3-5.8-23.9l-53.2,20c-2.9.8-5.8,1.59-8.69,2.39"/>
      <path id="Background_17" data-name="Background 17" class="Rechte_Fusswurzel1456_lat_liegend_cls2" d="M1.92,213.57c3.93-.06,6.66-1.61,7.79-5.14,17-14.3,42.9-17.4,72.2-16.2,12.9,3.9,25.5,6.2,37,4.1,13-3.4,12.9-8.8,4-15.6-6.5-12.5-16.8-19.8-33-19.4-32.63,6.16-64.22,12.7-87.83,22.26"/>
    </g>
    <path id="radius_1" data-name="radius 1" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M146.44.7c-5.2,22.9-2.7,42.7,10.3,58.4l7.5,8.8c5.9,15.6,20.5,13.6,41.9-2.2,9.1-4.2,17.2-8.7,18.6-14.4,6.4-12.8,3.3-28.6-3.6-49.3"/>
    <path id="talus_1" data-name="talus 1" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M163.04,64.4l-15.3,11.6c-5.2,4.5-11.4,7-18.2,7.9-6.7,2.6-9.4,9.5-8.7,20.3.5,6.8,4.5,13.6,11.5,20.6,4.9,4.3,10.9,6.5,17.9,6.4,9,2.3,19.2-4.8,30.2-18.9,9,7,18.3,5.3,28.1-8.8,1.1-1.6,3.5-1.7,4.7-.1,2.2,2.8,5.1,4.6,8.6,5.6,4.8.4,10.8-1.2,17.6-4.5,10.2-4,9.9-10.3,2.6-18.3h0c-4.1-3.6-6.6-6.9-6-9.8-1.4-4.7-3.6-8.6-6.8-11.4-3.1-2.2-5.6-4.3-7.1-6.3"/>
    <path id="calcaneus" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M300.14,150.3h0c-.3,1.3-.5,2.7-.5,4.2l-2,25.9c-.3,3.5-1.4,6.8-3.2,9.8-10,16.3-25.1,18.9-43,13.3-2.1-.7-3.8-2.2-4.5-4.2-2.3-6.1-9.2-10.5-21.4-12.8-31-5.8-56-12.3-58.8-21.8-2.3,0-4.4,0-6.1-.3-2-.3-3.6-2-3.6-4.1l-.5-24.4c-11.2-6-15.1-11.3-8.4-15.7,6.6-12.6,17.9-13.9,32.2-7.8,7.8-8.7,16.8-14.1,27.9-13.2,1.1,0,2.1-.3,3-.9,9.3-6.9,18.4-6.6,27.2,4.4.9,1.3,2.2,2.2,3.6,2.9l40.7,18.9c15.7-.2,20.8,9,17.4,25.8h0Z"/>
    <path id="tarsus1" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M151.94,160.1l-.5-23.4c-9.7-5.6-24.1-3.1-41.5,5-9.1,7.2-8.2,15.7-5,24.6,6.2,8.2,17.9,11.6,33.7,11.3,14.2-1.9,18.1-8,13.3-17.5h0Z"/>
    <path id="tarsus2" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M125.34,158.1h0c7.9-.5,13.5-3.2,17.3-7.8,3.8-5.9,3.8-11.1-.7-15.6-8.9-4-15.9-9.7-19.8-16.2-4.6-8.4-6.6-16.2-3.7-26.6.2-.8-.6-1.4-1.3-1-3.3,2-6.4,2.7-9.6,2.8-2,3.8-6.6,5.8-12.3,7.2-.1,2.8-1.6,3.5-3.7,3.2-1.7,19.4,6.9,35.7,25,48.9-.1,4.8,2.3,6.9,8.8,5.1h0Z"/>
    <path id="tarsus3" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M106.24,147h0c-14.9-8.8-20.5-21.7-17.8-38.3.4-2.4-1.6-4.6-4-4.4l-28.9,2.2-13.4,7.1c-10.1,6.7-.8,24.4,11.1,43.6.7,9.2,3.4,15.6,8.2,18.7,6.7,2.2,15.3-1.4,24.7-7.6,5.9.1,12.4-1.9,19.2-5.2l.9-16.1h0Z"/>
    <path id="toe5" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M1.79,166.31c14.85-1.79,29.43,1.67,43.75,7.79,1.7.7,3.6.9,5.3.4,4.2-1.3,4.1-5.6-.2-12.7-6.6-10.7-11.1-21.1-14-31.2-.6-1.9-1-3.9-1.1-5.9-.6-9.9-6.7-9.7-18.3.6-5.43,3.11-11.09,6.03-16.9,8.79"/>
    <path id="radius_2" data-name="radius 2" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M224.94,50c-5,1.9-10.2,1.4-15.7-1.7-9.4-4.2-19.9-3.3-31.2,1.3-7.6,2.7-12.8,8.1-17.5,14.1"/>
    <path id="ulnar" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M206.34.1c1.8,8.8,8.1,17.6,16.6,26.2,6.6,7.9,4.2,14.8-2.7,21.4l-11,11.4c-.9.9-2.1,1.6-3.4,1.9-7.7,1.5-12.8-2-15.9-9.5-7.1-6.2-9.3-11.3-6.1-15.3,4.2-11.7,5.6-23,2.7-33.9"/>
    <path id="tarsus5" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M105.94,157.8c-8.2,3.6-15.8,3.5-22.8-.7-8.7-3.8-14-8.9-14.3-15.8,2.4-9.2,10.2-13.3,22-13.7"/>
    <path id="tarsus4" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M110.84,127.6c-.9,19.7-9.6,27-25.6,22.6-9.9-7.3-18.5-16.1-25.7-26.4-3.9-8.8-2.7-15.3,10-16.8l23.5,2.7c11,1.5,18.4,6.2,17.8,17.9h0Z"/>
    <path id="toe3" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M1.72,164.63l54.12-34.83c-.3-10.9-1.4-20.3-10.1-15-13.1,11.41-28.62,19.69-45.11,26.71"/>
    <path id="toe4" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M2.43,184.03l62.51-22.53-2.6-26c-.8-12.9-5.7-15.1-16.2-2.6-14.27,9.57-29.31,17.07-44.82,23.28"/>
    <path id="toe2" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M2.55,190.36l67.09-18.86c3.1-12.1,4.1-22.3-5.8-23.9l-53.2,20c-2.9.8-5.8,1.59-8.69,2.39"/>
    <path id="toe1" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M2.25,213.24c3.93-.06,6.66-1.61,7.79-5.14,17-14.3,42.9-17.4,72.2-16.2,12.9,3.9,25.5,6.2,37,4.1,13-3.4,12.9-8.8,4-15.6-6.5-12.5-16.8-19.8-33-19.4-32.63,6.16-64.22,12.7-87.83,22.26"/>
    <path id="talus_2" data-name="talus 2" class="Rechte_Fusswurzel1456_lat_liegend_cls1" d="M222.04,58.7l-22.4-7.3c-11.6-1.6-24,3-36.9,13.1"/>
  </g>
  <g id="overlays">
    <path id="calcaneus_overlay" data-name="calcaneus overlay" class="Calcaneus1456Fill" style= {{
              fill: props.colors.Calcaneus1456Fill,
              stroke: props.colors.Calcaneus1456Fill,
              opacity:props.colors.Calcaneus1456Fill,
            }} d="M299.81,150.18h0c-.3,1.4-.5,2.7-.5,4.2l-2,25.9c-.3,3.5-1.4,6.8-3.2,9.8-10,16.3-25.1,18.9-43,13.3-2.1-.7-3.8-2.2-4.5-4.2-2.3-6.1-9.2-10.5-21.4-12.8-31-5.8-56-12.3-58.8-21.8-2.3,0-4.4,0-6.1-.3-2-.3-3.6-2-3.6-4.1l-.5-24.4c-11.2-6-15.1-11.3-8.4-15.7,6.6-12.6,17.9-13.9,32.2-7.8,7.8-8.7,16.8-14.1,27.9-13.2,1.1,0,2.1-.3,3-.9,9.3-6.9,18.4-6.6,27.2,4.4.9,1.3,2.2,2.2,3.6,2.9l40.7,18.9c15.7-.2,20.8,9,17.4,25.8h0Z"/>
    <path id="talus_overlay" data-name="talus overlay" class="talus1456Fill" style= {{
              fill: props.colors.talus1456Fill,
              stroke: props.colors.talus1456Fill,
              opacity:props.colors.talus1456Fill,
            }} d="M162.71,64.28l-15.3,11.6c-5.2,4.5-11.4,7-18.2,7.9-6.7,2.6-9.4,9.5-8.7,20.3.5,6.8,4.5,13.6,11.5,20.6,4.9,4.3,10.9,6.5,17.9,6.4,9,2.3,19.2-4.8,30.2-18.9,9,7,18.3,5.3,28.1-8.8,1.1-1.6,3.5-1.7,4.7-.1,2.2,2.8,5.1,4.6,8.6,5.6,4.8.4,10.8-1.2,17.6-4.5,10.2-4,9.9-10.3,2.6-18.3-4.1-3.6-6.6-6.9-6-9.8-1.4-4.8-3.6-8.6-6.8-11.4-2.8-1.99-5.11-3.89-6.63-5.72l-.56-.18-22.4-7.3c-11.6-1.6-24,3-36.9,13.1"/>
  </g>
</svg>
  );
}

export default RechteFusswurzelLatLiegend1456;
