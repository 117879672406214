import style from "./style.scss";

export default function LinkeHufteVdOblLiegend2404(props) {
  return (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 498.95 353.88">

  <g id="Ebene_3" data-name="Ebene 3">
    <path id="BG" class="Linke_Hufte_vd_obl_liegend2404_cls6" d="M165.98,352.72h0l-79-3.5h-31.6c-25.5-3-46.1-10.1-55-27,10.4-6.8,15.7-17.7,18.5-30.8,2.1-12.1,1.7-31,1.2-50.1-.7-6.8-6.8-8.7-16.3-7.4l1.1-105.3v-34.6s0-15.2,0-15.2l2.6-29.5v-13.4s.9-29.5.9-29.5L361.28.32l-22.5,27.6c-3.2,3.3-5.5,7.2-6.5,12l-11,26.8-6.9,13.5-3.6,11.7c-1.9,9.4-5.7,15.8-13,16.6-8.2,1.7-12.6,4.9-14.5,9.2l15.8,9,8.2,8.4c12.3,2.4,24.2,9.1,36,17.4,20.8-7.1,35.4.1,42.8,23.4,5,1.6,9.1,4.7,11.9,9.7,11.2,5.4,19.6,14.7,25.2,27.6,22.6,26.7,47.9,48.2,75.6,65.3v72.5s-97.8,1.7-97.8,1.7c-10.8-6.3-30.3-9.7-35.4-19.3-3.6-6.7-5.7-14.7-6.9-23.5-23.9-15-46.7-35.4-68.3-62.4-8.9,5.9-17.8,11-26.4,14.4-21,3.4-40.1-1.4-57.1-16.1-.7,3.8-2.1,7.1-5.5,8.9-3.2,2.4-6.7,2.2-10.6,0l-3.2,7.2c3.9,6,6.1,13,4.5,22.2-4.4,24.2-12.7,47.2-26.1,68.6h0Z"/>
  </g>
  <g id="Ebene_2" data-name="Ebene 2">
    <path id="partoffemur6" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M498.68,278.42c-29-18.6-54.8-39.8-75.6-65.3-5-12.7-13.8-21.5-25.2-27.6-2.3-4.2-6.5-7.3-11.9-9.7-9.1-26.2-25.2-29.8-44.7-22.8-8.6-4.8-16.6-3.1-22.2-1.8-22.5,3.1-43.6,16.1-62.9,40.7-1.3,7.7,0,15.3,4,22.9,1.1,10,7.3,15,16.7,16.9,21.6,24.9,57.5,66.2,81.7,78.1,1,10.8,3.7,19.9,7.2,24,.8,1,1.5,2,2.2,3,4.3,6.5,21.3,9.2,32.8,15.9"/>
    <path id="partoffemur5" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M341.28,153.12c-8,3.6-15.5,3.7-22.2-1.8l-20-24.6c-17.8-11.8-37.4-15.9-59.5-10-24.7,5-41.3,20.8-52.9,43.1-9.2,22.4-6.4,46.6,6.4,72.3,22.7,26,46.4,35,70.9,29.8,27.5-11.6,39.1-24,46-36.5"/>
    <path id="partoffemur4" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M385.88,184.72c-14.8-7.1-29.3-17.8-43.4-32-11.4-9.3-23.7-15.3-36.9-17.9"/>
    <path id="partoffemur3" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M401.48,227.12c-8-8.3-16.3-15.6-22.8-22.2-5.5-5.5-9.7-12.1-12.4-19.4-4.9-13.1-12.8-22.7-25-27.3-13.5-2.9-25.5-.5-33.4,11.9-1.1,12.3,2.7,24.5,11.5,36.5"/>
    <path id="partoffemur2" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M258.68,254.22c15-9.2,31-13,49-3.9l18.1,13"/>
    <path id="partoffemur1" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M365.68,333.82c1.1-3.9,1.7-7.6,1.6-11.2-.1-4.2-2.3-8.1-5.8-10.6l-3-2.1c-8.4-14.7-17.5-28.5-28.1-40.7-5.7-6.6-10.5-14-14-21.9-7.4-16.7-6.1-27.7,4.8-32.2"/>
    <path id="partofpelvis14" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M166.08,352.62c12.7-18.6,20.8-40.4,25.5-64.7,1.7-9.2,1.2-18.1-3.9-26.1l2.4-7.7c9.2,5.5,14.4,1.5,16.9-8.4-1.7-2.8-1.2-5.7,1.1-8.5.8-1,1.2-2.4,1-3.7-2.8-17.7,0-30.2,6.4-39.4l13.2-14c1.9-1.9,3.2-4.3,3.9-6.9,4.4-16.1,11.5-27,20.1-34.9,1.6-1.5,3.6-2.5,5.7-2.8,8.9-1.4,16.8-8.1,24.3-17.2,4.2-5.3,9.8-8.3,16.1-10.2,3.6-1,6.5-3.5,8.3-6.8,2.1-3.9,3.4-7.9,4.1-12.1.6-3.5,1.8-6.7,3.5-9.8,7-12.8,12.9-26.1,17.6-39.6,1.6-4.5,3.9-8.8,7-12.5L361.28.32"/>
    <path id="partofjoint4" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M178.48,296.12c2.3-13.4,4.7-26.3,9-34.2l2.4-7.7c4.2-1.7,5.6-5.9,5.6-11.5.4-3.1,3.3-7.1,7.3-11.5,3.1-7.3,6.4-14.5,11.5-20.2,1.8-2,4-3.5,6.6-4.2,3.8-1,8.5-13.5,8.5-20.9-7-9.6-10-19.8,0-32.3,8.7-12.5,17.5-17.9,26.2-17.2,4.4-15.8,14.9-27,30.8-34,.9-.4,1.9-.6,2.9-.6h1.7c4.4,0,8.5-2.6,10-6.7.6-1.5,1-3.3,1.1-5.3.1-2.1.9-4.2,2.5-5.6,3.7-3.4,5.3-7.8,5.7-12.8"/>
    <path id="partofjoint3" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M194.08,126.82c24.3-24.8,58.9-31.5,93.5-24.8"/>
    <path id="partofjoint2" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M174.68,248.82c5.5,4,10.9,7,15.3,5.4"/>
    <path id="partofjoint1" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M207.68,103.52c-32.8,15.1-51,39.9-51.9,76.2.7,40,10.8,56.1,22.8,67.3"/>
    <path id="partofpelvis13" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M102.68,330.22c2.9-5,7.5-7.4,13.4-7.8-9.6-1-18.5-3.3-22-15.6-1.4-6-1.7-11.2-.9-15.5.4-1.9,1.4-3.7,2.8-5,4.6-4.3,8.5-9.7,11.9-15.9,1.6-4.4,5-7.9,9.9-10.5,2.6-1.4,5-3,7.2-4.9,7-6.2,14.9-12.1,23.5-17.9,2-1.4,4.5-2,6.9-1.6,4.6.7,8.4-.4,10.6-4.7"/>
    <path id="partofpelvis12" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M156.28,190.42c-3.5-12.3-5.8-25.6-1.4-44.8-12.1,23.9-24.1,46.4-35.7,60.8-14.2,16.9-31.9,27.1-56.2,25.4-16.2-.3-28.9,5.6-33.3,26.1-2,7.5-2.7,18.2,2.1,43.9,1.5,4.2,3.5,8.1,6.5,11.4-.9,4.2-.4,8.4,4,12.5,6.2,4.8,10.4,12.9,13.1,23.5"/>
    <path id="partofpelvis11" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M167.78,79.62c-4,25.9-8,50.9-12.9,66.1"/>
    <path id="partofpelvis10" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M107.48,265.42c-26.6,19.3-47.4,42.1-65.6,50.6"/>
    <path id="partofpelvis9" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M86.98,349.22c16.9-22.8,30.9-49.9,35.2-92.1.4-7.3-.9-15.2-3.1-23.5-.5-1.8-.6-3.7-.3-5.6.6-4.5.8-8.6.6-12.3-.7-10.7-7.6-20.1-17.6-24.2l-8.4-3.5c-5-2.1-7.6-7.4-6.4-12.6,1.8-7.6,4.9-12.6,8.8-15.6,24.9-20.7,43.2-46.9,55-78.3,10.7-27.8,9.7-48.8-2.7-63.2-13.1-11.6-26.6-12.9-40.5-5.5-2.5,1.3-5.5,1.5-8.1.4-5-2.1-10.7-2.7-17.3-1.9-1.6.2-2.8-1.3-2.4-2.8l.8-3"/>
    <path id="partofpelvis8" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M158.78,75.22c2.6-15.9,2-27.2-.7-35.4"/>
    <path id="partofpelvis7" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M.28,322.22c13.1-8.9,19.3-23.8,19.8-43.8v-38.2c0-1.7-.9-3.2-2.4-4-4-2.1-8.6-2.8-13.8-2.4"/>
    <path id="partofpelvis6" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M73.88,6.62v3.9c0,1.3-.7,2.6-1.9,3.3-2,1.2-3.9,3-5.5,6-1.3,3.4-3.6,6.7-6.6,9.9-2.2,2.3-3.7,5-4.8,7.9-1.8,5-3,10.9-3.1,18.5-.1,5.1-.3,10.2-.9,15.3-1.2,10-3.2,19-6.2,26.3-3.7,8.8-10.5,16-19.1,20.3l-20.8,10.5"/>
    <ellipse id="partofpelvis5" class="Linke_Hufte_vd_obl_liegend2404_cls8" cx="215.98" cy="84.16" rx="7.6" ry="6.2" transform="translate(-15.69 76.52) rotate(-19.4)"/>
    <path id="partofpelvis4" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M4.98,78.72c5.9-3,9.4-2.5,10.6,1.4.8,2.5.3,5.1-1.1,7.3-1.8,3-4.8,5.3-9.5,6.5"/>
    <path id="partofpelvis3" class="Linke_Hufte_vd_obl_liegend2404_cls9" d="M7.48,49.22c4.4-2.1,6.4-5.3,7.3-8.9.6-2.2-.3-4.6-2.4-5.6-1.6-.8-3.2-.4-4.8,1.2"/>
    <path id="partofpelvis2" class="Linke_Hufte_vd_obl_liegend2404_cls7" d="M31.88,236.82h0c.4,8.5,4.4,13.2,12.7,13.4h.7c10.3,1.1,18.2-5.4,24.8-15.9,1-1.6,1.9-3.2,2.6-5,3.8-9.9,3-20.1-1.2-30.5-1.7-4-1.5-7.2,1.1-9.6.9-.9,2-1.4,3.1-1.8l34.8-12.1c16.6-7.5,21.7-15.4,22.1-23.5.1-2-.1-4-.6-5.9-3.1-13.2-10.1-21.5-20.1-26-1-.4-1.9-.7-2.9-1-12.5-3.7-17.8-9.9-16.8-18.4.1-1.2.4-2.3.8-3.4,2.1-6.4,2-12.3-.3-17.7-1.2-2.9-3.1-5.4-5.3-7.5l-15.6-15c-1.5-1.5-2.7-3.1-3.6-5-1.2-2.6-1.2-4.8-.3-6.5.8-1.6,2.7-2.4,4.5-2,2.9.6,5.3.3,7.1-1.4,1.4-1.3,2-3.3,2-5.2-.2-4.3-1.9-9.8-4.7-16.3-4.3-9.8-15.5-14.9-25.6-11.4-.1.1-.2.1-.3.1-5.1.3-8.3,2.8-9.8,7.4-.5,1.6-1.5,3.1-2.9,4-5.7,3.7-8.4,8.7-7.8,14.9.2,1.9.1,3.8-.2,5.7-1.5,10.2,0,17.7,4,22.8,1.5,1.8,2.7,3.8,3.7,5.9,1.9,4.2,5,11.3,7.2,16.2,1.7,4,4.4,7.5,7.8,10.2,8.3,6.8,17.3,12.6,26.9,17.8,1.6.9,3.1,1.9,4.4,3.2,2.3,2.3,3.2,4.4.8,6.1-.8.6-1.7,1.2-2.4,1.9-6.1,5.8-9.6,13.8-10.1,24.6,0,1.4-.4,2.8-1.3,3.9-1.5,2-3.5,2.3-5.9.8-2.6-1.6-4.3-4.2-4.9-7.2-1.6-7.6-4.5-11.8-8.6-12.6-.7-.1-1.5,0-2.2,0-8.9,1.2-15.2,5.7-18.6,13.8-.6,1.5-1.3,3-1.9,4.5-2.5,5.8-2.2,10.8,1.1,15,6,9.8,13.6,14,21.6,17.1.8.3,1.6.7,2.3,1.2,2.6,1.9,3,4,.7,6.4-1.1,1.3-2.7,2.2-4.3,2.8-7.7,2.7-13.5,8.1-16.2,18-2,7.3-2.7,14.2-2.2,20.7.1.8.1,1.5.1,2.3l-.3.2Z"/>
    <path id="partofpelvis1" class="Linke_Hufte_vd_obl_liegend2404_cls7" d="M132.38,69.72h0c1-2.6,1.4-5.4,1.4-8.3-.1-2.6.6-5.2,1.8-7.5,2.7-5.3,2.4-10.5.2-15.6-2.2-6.4-4.6-12-7.3-15.7s-8-4.6-11.9-2l-5.8,3.9c-.9.6-1.9,1-3,1.2-3.6.6-6.4,1.9-7.4,5.3-1.2,4,4.6,8.9,5.2,16,1.1,10.4,5.2,19.3,15.7,25.5,2,.8,3,1.2,5.5,1.2s4.8-1.6,5.7-3.9l-.1-.1Z"/>
  </g>
  <path id="femurhighlight" class="Oberschenkel2404Fill" style={{
            fill: props.colors.Oberschenkel2404Fill,
            stroke: props.colors.Oberschenkel2404Fill,
          }} d="M400.78,352.92l97.8-1.6v-72.5c-29-18.9-54.8-40-75.6-64.8-4.7-11.4-11.9-21.4-25.3-28.2-2-4.1-5.6-7.4-12-9.4-7-18.1-16.8-32.3-43.2-23.6-11.4-11.2-25.9-15.5-36.9-17.9-2.6-.6-2.9-6-6.9-8.2-5.3-2.9-10.7-5.5-16-8.3-33.1-9.7-68-3.1-92.4,34.8-13,26.2-12.9,40.5,2.6,78.7,2.2,5.5,8.2,9.1,13.6,13.3,14.4,14.1,32.8,19,54,17.5,10.5-3.4,20.4-8.8,29.9-15.3,22.4,25.4,43.5,47.3,68.2,62.4.7,11,3.7,19.2,8.4,25.2,3.2,7.9,21.4,12,33.8,17.7v.2Z"/>
  <path id="Pfahne" class="hipjoint2404Fill" style={{
            fill: props.colors.hipjoint2404Fill,
            stroke: props.colors.hipjoint2404Fill,
          }} d="M285.18,102.52h0c4.51,1.24,6.35,5.33,5.84,9.04-.04,0-.07,0-.11,0-2.73,0-4.29,1.79-4.71,3.85-.16.17-.28.35-.41.54-.5.32-.91.73-1.24,1.2-.62.28-1.14.68-1.55,1.16-.26-.02-.45-.09-.72-.09-82.2-24.3-132,69.9-77.8,125.2,1.53,1.61,2.38,3.55,2.15,5.81-1.3.78-2.04,2.16-2.22,3.62-.43.48-.77,1.04-.98,1.66-.21.14-.4.29-.57.46-1.47.14-2.56.84-3.27,1.81-.82.03-1.53.23-2.13.54-.74-.53-1.69-.86-2.84-.86-.04,0-.07,0-.1,0-.75-.56-1.72-.91-2.91-.92-.68-.38-1.49-.61-2.46-.61-.11,0-.21,0-.31.02-.54-.2-1.14-.32-1.82-.32-.53,0-1.02.07-1.46.2-57.97-50.02-34.42-188.72,99.63-152.32Z"/>
  <path id="femoralheadhighlight" class="Femoralhead2404Fill" style={{
            fill: props.colors.Femoralhead2404Fill,
            stroke: props.colors.Femoralhead2404Fill,
          }} d="M321.88,153.12c3.3,37.7-8.1,69.8-34.2,96.1l-19.1,10.5c-18.9,5.5-38.8,7.3-66.5-18.8-43.8-40.5-15.5-129,56.2-126.1,12.5-.5,23,.4,39,11.2,9.1,6.1,14.4,18.2,24.7,27.1h-.1Z"/>
  <path id="trochantormajorhighlight" class="TrochanterMajor2404Fill" style={{
            fill: props.colors.TrochanterMajor2404Fill,
            stroke: props.colors.TrochanterMajor2404Fill,
          }} d="M368.22,152.91c14.33,6.57,17.47,21.49,17.76,23,1.61.35,4.04,1.11,6.36,2.91,2.76,2.15,3.34,4.21,5.64,6.79,1.51,1.68,4.04,3.92,8.34,5.64-3.99,3.48-8.42,8.16-12.24,14.36-2.01,3.26-2.79,5.22-5.38,10.16-2.38,4.53-5.53,10.13-9.6,16.44-9.86,12.32-16.22,23.3-20.23,31.13-7.44,14.52-14.64,33.51-19.96,32.54-.64-.12-1.74-.92-3.95-2.52-3.27-2.37-5.47-4.54-6.05-5.12-20.12-20.05-28.33-29.42-28.33-29.42-11.23-12.83-18.28-20.9-23.7-27.1-2.41-.41-6.46-1.47-10.23-4.57-1.2-.98-3.04-2.52-4.42-5.13-1.28-2.42-.83-3.24-2.05-7.21-1.41-4.58-2.25-4.23-3.18-7.9-.45-1.78-.07-1.13-.85-8.56-.41-3.91-.61-4.97.04-6.44.74-1.7,1.81-2.12,3.74-3.98,2.61-2.51,3.64-4.62,4.79-6.32,1.84-2.72,5.63-6.84,14.31-12.88,5.08-4.35,15.47-12.07,30.82-15.92,15.05-3.77,27.62-2,34.14-.61,2.1-.79,12.76-4.56,24.24.7Z"/>
  <path id="trochanorminorhighlight" class="TrochanterMinor2404Fill" style={{
            fill: props.colors.TrochanterMinor2404Fill,
            stroke: props.colors.TrochanterMinor2404Fill,
          }} d="M359.88,310.12c-.6.1-1.1-.2-1.1,1.2-.01,18.9,15.2,32.2,15.2,30.2,0,0,20.1,8.6,25.7,10.5.6.2-1.2,4.4,1.5-.5,2.7-4.9-3.4-15.3-10.9-26.7-1.3-2-2.9-3.8-4.7-5.3-8.7-7.2-17.2-10.3-25.6-9.4h-.1Z"/>
  <path id="femoralneckhighlight" class="Schenkelhals2404Fill" style={{
            fill: props.colors.Schenkelhals2404Fill,
            stroke: props.colors.Schenkelhals2404Fill,
          }} d="M309.38,138.92c.7-1.6-7.2-5.1,4.1-1.9,11.3,3.2,20.5,8.7,28.7,15.5h0c8.6,8.2,16.7,15.3,24.2,20.6.6.5.8,1.4.3,2l-64,83.7c-.5.6-1.5.7-2.1,0l-23.5-26.8c-.1-.2-.3-.4-.5-.4l-6-2.3c-1.6-.6-2.3-2.3-1.6-3.8l40.3-86.8.1.2Z"/>
</svg>
  );
}
