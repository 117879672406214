import style from "./style.scss";

function Brustwirbelkorper8VdLiegend1521(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 304.97 182.1">

<g id="BG">
  <path id="BG_white" data-name="BG white" class="Brustwirbelsaule8_ap1521_cls4" d="M18.75,89.12L.07,73.17c13.64-6.43,33.68-11.31,59.19-14.87,22.66-2.04,42.11-1.74,55.69,2.88.09-3.03,2.21-3.39,5.59-2.13,2.76-.81,1.75-3.03-1.06-5.92-4.14-2.57-5.41-5.49-5.1-8.61,4.91-7.01,7.92-14.33,5.39-22.59-3.35-4.88-4.63-9.66-3.51-14.32.95-3.73,2.97-6.23,8.21-5.06,3.21.12,6.79,1.28,10.48,2.73,3.5,1.29,4.81.46,9.13-.2,4.88-.93,10.01-1.02,15.33-.48l20.57-.14L196.05.12c3.5-.08,6.02,1.28,5.91,6.53-.18,3.22-6.63,7.86-6.07,12.63,0,.87-.39,2.56-.39,2.56,0,0-.75,5.7,3.18,9.93,0,0,2.96,5.05,3.89,7.83.84.99,1.6,2.09,1.44,4.32,5.18-1.21,10.08.17,14.57,5.54,34.17.73,69.82,3.03,74.95,9.59l11.39,30.08c-21.55-5.33-44.28-8.96-67.83-11.4.7,8.26-1.1,13.76-7.05,14.72-4.26,1.51-11.45-.54-16.92.94-4.71.06-6.77,2.71-8.04,6.11,9.81-2.84,18.17-1.76,25.15,3,6.9,4.9,15.08,8.88,25.83,11.01,24.52-.24,37.47,3.38,39.29,6.45-31.23.55-59.67,7.5-86,19.28l-3.78-3.09c-2.99,8.51-4.85,16.02-3.19,22.7,1.63,10.4,13.67,19.97,7.79,20.65l-36.95-2.87-14.13,1.82c-6.44.91-13.05,1.11-19.82.59-4.27.32-9.11,1.13-14.43,2.34-6,1.65-9.38-.68-10.74-4.8-.96-2.9-.54-6.07.86-8.78,4.91-9.51,7.31-18.08,3.72-23.94-4.31-6.39-6.78-18.39-3.79-21.02-34.31-1.97-70.98.14-109.36,5.18l30.34-11.85c18.52-7.87,36.33-12.3,53.04-9.23,8.69,2.02,16.23,5.2,22.33,9.81,7.97,5.57,10.02,3.4,5.93-6.2-4.35-2.63-10.62-9.3-17.32-16.86-6.05-3.03-12.96-4.37-20.33-4.78-8.75-3.67-12.58-8.77-9.92-15.74-21.62,3.47-41.98,8.23-50.87,15.96h.02Z"/>
</g>
<g id="BWK8ap">
  <g id="BWK8_Parts" data-name="BWK8 Parts">
    <path id="BWK8_Part25" data-name="BWK8 Part25" class="Brustwirbelsaule8_ap1521_cls6" d="M119.46,53.12c-.81-.5-4.45-2.74-5.01-6.38-.54-3.49,2.35-4.49,4.56-10.48.69-1.87,2.52-6.85,1.37-12.3-1.01-4.77-3.2-4.7-4.1-9.57-.65-3.55-.56-9.36,2.73-11.39.51-.32,1.62-.86,5.47-.46,5.07.53,7.97,1.97,10.48,2.73,4.97,1.51,5.87-.4,16.41-.91,6.51-.32,5.22.46,16.41.46,8.65,0,13.05-.03,16.41-1.37,1.87-.74,4.26-1.96,8.2-2.73,3.2-.63,5.88-1.11,7.75.46,2.12,1.79,1.86,5.11,1.82,5.47-.3,3.23-2.56,3.79-4.56,7.75-.38.75-2.33,4.76-1.82,9.57.31,2.9,1.42,4.97,3.65,9.11,2.35,4.38,3.17,4.52,4.1,7.29.54,1.61,2.22,6.62,0,9.11-1.44,1.62-4.17,1.4-9.57.91-5.01-.46-10.03-.9-15.04-1.37-6.7-.63-11.61-.18-18.68.46-8.01.72-12.32,1.11-18.23,2.73-4.5,1.24-8.47,2.79-14.58,2.73-3.3-.03-5.61-.51-7.75-1.82h-.02Z"/>
    <path id="BWK8_Part24" data-name="BWK8 Part24" class="Brustwirbelsaule8_ap1521_cls5" d="M117.18,110.53c1.73,3.17,7.78,2.65,20.96,1.82,13.66-.86,20.49-1.29,24.61-1.37,16.45-.3,24.67-.45,30.08.91,4.36,1.09,8.98,2.76,11.39.46,2.56-2.45,1.26-8.08.91-9.57-.82-3.55-1.96-3.71-4.1-8.66-2.61-6.02-3.09-10.77-3.19-11.85-.33-3.62-.53-5.89.46-8.66,1.4-3.93,3.59-4.33,5.01-8.66.26-.78,1.87-5.9,0-7.29-1.33-.99-3.04.94-8.2,1.82-3.06.52-3.56.03-7.29.46-3.99.45-6.73,1.39-8.2,1.82-5.46,1.6-8.32.63-26.89.46-9.04-.09-13.56-.13-15.49,0-6.02.4-8.28,1.03-12.3,0-5.16-1.32-7.01-3.77-8.66-2.73-2.35,1.48-1.19,8.08.46,12.3,1.28,3.28,2.07,3.1,3.65,6.84.98,2.32,2.75,6.52,2.28,11.39-.3,3.04-1.15,3.13-3.19,9.11-2.17,6.39-3.26,9.59-2.28,11.39h-.02Z"/>
    <path id="BWK8_Part23" data-name="BWK8 Part23" class="Brustwirbelsaule8_ap1521_cls5" d="M77.54,63.6c-1.45.8-5.34,3.02-7.29,7.75-.42,1.03-2.52,6.11,0,10.94,1.88,3.6,5.3,5.07,7.29,5.92,3.58,1.53,4.62.66,10.94,1.82,4.79.88,8.05,1.48,11.39,3.65,2.08,1.35,2.26,2.06,7.29,7.75,8.22,9.3,9.45,9.33,10.03,9.11,1.63-.62.44-4.21,2.28-11.39,1.49-5.81,2.78-5.46,3.19-9.11.3-2.64-.25-3.95-4.55-15.5-2.91-7.81-3.34-9.03-5.02-10.02-2.49-1.48-4.11-.32-10.48-.91-5.14-.48-4.88-1.3-9.57-1.82-4.6-.51-10.15-1.12-15.49,1.82h-.01Z"/>
    <path id="BWK8_Part22" data-name="BWK8 Part22" class="Brustwirbelsaule8_ap1521_cls5" d="M204.02,99.76c1.62.07,1.61-3.31,5.01-5.36,1.72-1.03,4.12-1.1,8.86-1.18,6.05-.11,6.05.45,8.66,0,2.46-.42,6.75-1.16,9.11-4.56,1.61-2.32,1.5-4.83,1.37-8.66-.13-3.61-.22-6.25-1.97-8.8-2.54-3.7-6.85-4.72-9.42-5.33-1.57-.37-2.1-.31-10.03-.46-8.02-.15-8.04-.22-8.66,0-5.91,2.08-9.29,10.37-9.11,16.86.05,1.69.51,3.49,1.45,7.09,1.24,4.79,2.67,10.3,4.73,10.39h0Z"/>
    <path id="BWK8_Part21" data-name="BWK8 Part21" class="Brustwirbelsaule8_ap1521_cls5" d="M129.49,81.82c-2.19-.65-2.42-5.41-2.73-11.85-.35-7.19-.48-10.81,1.82-12.3,1.71-1.11,4.47-.92,5.92.46,1.6,1.52.59,3.5.46,10.03-.13,6.6.83,8.48-.91,10.94-.98,1.38-2.98,3.2-4.56,2.73h0Z"/>
    <path id="BWK8_Part20" data-name="BWK8 Part20" class="Brustwirbelsaule8_ap1521_cls5" d="M187.81,81.37c2.45.3,4.93-3.28,5.92-5.92.28-.75,1.17-3.36-.46-10.48-1.31-5.72-2.46-10.78-4.56-10.94-2.48-.19-5.16,6.54-5.92,11.39-.31,1.99-.53,5.1.46,8.66.64,2.32,1.93,6.96,4.56,7.29Z"/>
    <path id="BWK8_Part19" data-name="BWK8 Part19" class="Brustwirbelsaule8_ap1521_cls6" d="M114.9,122.84c1.78-1.24,3.91.84,10.03,2.28,4.45,1.05,7.76,1,13.22.91,6.86-.11,7.32-1.01,15.95-1.82,9.84-.93,10.56.11,21.87-.91,5.73-.52,8.6-.78,12.3-1.37,9.32-1.48,12.78-3.07,15.49-.91,2.33,1.85,2.54,5.22,2.73,8.2.32,4.98-1.25,7.08-2.73,13.22-1.52,6.27-2.66,10.96-1.37,16.41,1.3,5.49,4.94,11.21,5.92,12.76,2.54,3.99,4.26,5.52,3.65,6.84-.8,1.72-4.79,1.32-12.76.46-17.04-1.85-20.61-2.41-25.97-2.28-5.46.14-3.52.76-14.13,1.82-17.18,1.73-16.76-.46-26.89,1.37-8,1.44-12.18,3.52-15.49.91-2.84-2.23-3.15-6.4-3.19-7.29-.19-3.97,1.9-5.54,4.1-10.94,1.39-3.4,3.38-8.3,2.73-13.67-.66-5.45-3.36-5.76-5.47-13.22-.61-2.15-3.02-10.66,0-12.76h.01Z"/>
    <path id="BWK8_Part18" data-name="BWK8 Part18" class="Brustwirbelsaule8_ap1521_cls6" d="M129.49,146.99c-3.62-4.65-4.36-8.9-4.56-10.94-.2-2.1.02-3.68.46-6.84.73-5.29,1.1-7.94,3.19-10.03,1.45-1.44,4.3-3.21,6.84-2.28,2.66.97,3.48,4.35,3.65,5.01.92,3.76-1.3,4.84-3.19,12.76-.98,4.12-.9,6-2.73,8.66-1.21,1.76-2.62,2.93-3.65,3.65h0Z"/>
    <path id="BWK8_Part17" data-name="BWK8 Part17" class="Brustwirbelsaule8_ap1521_cls6" d="M191,147.9c-2.78.17-5.25-8.78-5.47-9.57-1.68-6.24-.61-11.08,0-13.67.71-3.03,1.41-5.99,3.19-6.38,2.16-.47,4.76,3.09,5.92,5.47,1.96,4,1.47,7.86.91,11.85-.35,2.46-1.7,12.13-4.56,12.3h0Z"/>
    <path id="BWK8_Part16" data-name="BWK8 Part16" class="Brustwirbelsaule8_ap1521_cls6" d="M158.4,54.94c6.4-10.97-3-34.36-10.69-37"/>
    <path id="BWK8_Part15" data-name="BWK8 Part15" class="Brustwirbelsaule8_ap1521_cls6" d="M160.36,54.02c4.53-22.44,8.62-43.92,15.18-38.73"/>
    <path id="BWK8_Part14" data-name="BWK8 Part14" class="Brustwirbelsaule8_ap1521_cls5" d="M164.09,128.02c3.99-28.08,11.44-54.85,15.9-58.59"/>
    <path id="BWK8_Part13" data-name="BWK8 Part13" class="Brustwirbelsaule8_ap1521_cls5" d="M156.8,131.82c.13,1.32-5.68-57.89-12.71-58.66"/>
    <path id="BWK8_Part12" data-name="BWK8 Part12" class="Brustwirbelsaule8_ap1521_cls6" d="M304.93,89.12c-3.63-1.25-32.85-7.9-67.83-11.39"/>
    <path id="BWK8_Part11" data-name="BWK8 Part11" class="Brustwirbelsaule8_ap1521_cls6" d="M237.1,77.72c0,3.57-35.68,7.73-39.53,0"/>
    <path id="BWK8_Part10" data-name="BWK8 Part10" class="Brustwirbelsaule8_ap1521_cls6" d="M201.78,46.35c-7.09,3.72-5.61,24.21-4.21,31.38"/>
    <path id="BWK8_Part9" data-name="BWK8 Part9" class="Brustwirbelsaule8_ap1521_cls6" d="M201.78,46.35c1.43-3.8,8.62-5.67,16.81,3.1"/>
    <path id="BWK8_Part8" data-name="BWK8 Part8" class="Brustwirbelsaule8_ap1521_cls6" d="M293.54,59.04c.02-4.28-30.02-9.69-74.94-9.6"/>
    <path id="BWK8_Part7" data-name="BWK8 Part7" class="Brustwirbelsaule8_ap1521_cls6" d="M209.36,139.24c-12.54-6.63-8.46-32.13-5.34-39.48"/>
    <path id="BWK8_Part6" data-name="BWK8 Part6" class="Brustwirbelsaule8_ap1521_cls6" d="M204.02,99.76c26.77-6.85,23.42,8.15,52.05,13.75"/>
    <path id="BWK8_Part5" data-name="BWK8 Part5" class="Brustwirbelsaule8_ap1521_cls6" d="M295.36,119.96c0-3.56-17.57-6.45-39.29-6.45"/>
    <path id="BWK8_Part4" data-name="BWK8 Part4" class="Brustwirbelsaule8_ap1521_cls6" d="M111.26,116.74c-35.66-24.01-64.46-3.28-105.72,11.28"/>
    <path id="BWK8_Part3" data-name="BWK8 Part3" class="Brustwirbelsaule8_ap1521_cls6" d="M.07,73.17c13.92-9.75,104-27.31,120.47-7.88"/>
    <path id="BWK8_Part2" data-name="BWK8 Part2" class="Brustwirbelsaule8_ap1521_cls6" d="M18.75,89.12c8.43-9.94,71.18-21.13,72.46-15.95"/>
    <path id="BWK8_Part1" data-name="BWK8 Part1" class="Brustwirbelsaule8_ap1521_cls6" d="M120.53,65.28c4.07,13.66-6.28,23.51-29.33,7.88"/>
  </g>
</g>
<g id="angrenzende_BWK_Highlights" data-name="angrenzende BWK Highlights">
  <path id="angrenzende_BWK_Highlight2" data-name="angrenzende BWK Highlight2" class="BWK1521Fill" style= {{
            fill: props.colors.BWK1521Fill,
            stroke: props.colors.BWK1521Fill,
            opacity:props.colors.BWK1521Fill,
          }} d="M119.46,53.09c-.81-.5-4.45-2.74-5.01-6.38-.54-3.49,2.35-4.49,4.56-10.48.69-1.87,2.52-6.85,1.37-12.3-1.01-4.77-3.2-4.7-4.1-9.57-.65-3.55-.56-9.36,2.73-11.39.51-.32,1.62-.86,5.47-.46,5.07.53,7.97,1.97,10.48,2.73,4.97,1.51,5.87-.4,16.41-.91,6.51-.32,5.22.46,16.41.46,8.65,0,13.05-.03,16.41-1.37,1.87-.74,4.26-1.96,8.2-2.73,3.2-.63,5.88-1.11,7.75.46,2.12,1.79,1.86,5.11,1.82,5.47-.3,3.23-2.56,3.79-4.56,7.75-.38.75-2.33,4.76-1.82,9.57.31,2.9,1.42,4.97,3.65,9.11,2.35,4.38,3.17,4.52,4.1,7.29.54,1.61,2.22,6.62,0,9.11-1.44,1.62-4.17,1.4-9.57.91-5.01-.46-10.03-.9-15.04-1.37-6.7-.63-11.61-.18-18.68.46-8.01.72-12.32,1.11-18.23,2.73-4.5,1.24-8.47,2.79-14.58,2.73-3.3-.03-5.61-.51-7.75-1.82h-.02Z"/>
  <path id="angrenzende_BWK_Highlight1" data-name="angrenzende BWK Highlight1" class="BWK1521Fill" style= {{
            fill: props.colors.BWK1521Fill,
            stroke: props.colors.BWK1521Fill,
            opacity:props.colors.BWK1521Fill,
          }} d="M114.9,122.81c1.78-1.24,3.91.84,10.03,2.28,4.45,1.05,7.76,1,13.22.91,6.86-.11,7.32-1.01,15.95-1.82,9.84-.93,10.56.11,21.87-.91,5.73-.52,8.6-.78,12.3-1.37,9.32-1.48,12.78-3.07,15.49-.91,2.33,1.85,2.54,5.22,2.73,8.2.32,4.98-1.25,7.08-2.73,13.22-1.52,6.27-2.66,10.96-1.37,16.41,1.3,5.49,4.94,11.21,5.92,12.76,2.54,3.99,4.26,5.52,3.65,6.84-.8,1.72-4.79,1.32-12.76.46-17.04-1.85-20.61-2.41-25.97-2.28-5.46.14-3.52.76-14.13,1.82-17.18,1.73-16.76-.46-26.89,1.37-8,1.44-12.18,3.52-15.49.91-2.84-2.23-3.15-6.4-3.19-7.29-.19-3.97,1.9-5.54,4.1-10.94,1.39-3.4,3.38-8.3,2.73-13.67-.66-5.45-3.36-5.76-5.47-13.22-.61-2.15-3.02-10.66,0-12.76h.01Z"/>
</g>
<g id="BWK8ap_Highlights" data-name="BWK8ap Highlights">
  <path id="BWK8ap_Highlight3" data-name="BWK8ap Highlight3" class="Wirbelkorper_Querfortsatze1521Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1521Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1521Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1521Fill,
          }} d="M116.62,110.53c1.73,3.17,7.78,2.65,20.96,1.82,13.66-.86,20.49-1.29,24.61-1.37,16.45-.3,24.67-.45,30.08.91,4.36,1.09,8.98,2.76,11.39.46,2.56-2.45,1.26-8.08.91-9.57-.82-3.55-1.96-3.71-4.1-8.66-2.61-6.02-3.09-10.77-3.19-11.85-.33-3.62-.53-5.89.46-8.66,1.4-3.93,3.59-4.33,5.01-8.66.26-.78,1.87-5.9,0-7.29-1.33-.99-3.04.94-8.2,1.82-3.06.52-3.56.03-7.29.46-3.99.45-6.73,1.39-8.2,1.82-5.46,1.6-8.32.63-26.89.46-9.04-.09-13.56-.13-15.49,0-6.02.4-8.28,1.03-12.3,0-5.16-1.32-7.01-3.77-8.66-2.73-2.35,1.48-1.19,8.08.46,12.3,1.28,3.28,2.07,3.1,3.65,6.84.98,2.32,2.75,6.52,2.28,11.39-.3,3.04-1.15,3.13-3.19,9.11-2.17,6.39-3.26,9.59-2.28,11.39h-.02Z"/>
  <path id="BWK8ap_Highlight2" data-name="BWK8ap Highlight2" class="Wirbelkorper_Querfortsatze1521Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1521Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1521Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1521Fill,
          }} d="M76.98,63.6c-1.45.8-5.34,3.02-7.29,7.75-.42,1.03-2.52,6.11,0,10.94,1.88,3.6,5.3,5.07,7.29,5.92,3.58,1.53,4.62.66,10.94,1.82,4.79.88,8.05,1.48,11.39,3.65,2.08,1.35,2.26,2.06,7.29,7.75,8.22,9.3,9.45,9.33,10.03,9.11,1.63-.62.44-4.21,2.28-11.39,1.49-5.81,2.78-5.46,3.19-9.11.3-2.64-.25-3.95-4.55-15.5-2.91-7.81-3.34-9.03-5.02-10.02-2.49-1.48-4.11-.32-10.48-.91-5.14-.48-4.88-1.3-9.57-1.82-4.6-.51-10.15-1.12-15.49,1.82h-.01Z"/>
  <path id="BWK8ap_Highlight1" data-name="BWK8ap Highlight1" class="Wirbelkorper_Querfortsatze1521Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1521Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1521Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1521Fill,
          }} d="M203.46,99.76c1.62.07,1.61-3.31,5.01-5.36,1.72-1.03,4.12-1.1,8.86-1.18,6.05-.11,6.05.45,8.66,0,2.46-.42,6.75-1.16,9.11-4.56,1.61-2.32,1.5-4.83,1.37-8.66-.13-3.61-.22-6.25-1.97-8.8-2.54-3.7-6.85-4.72-9.42-5.33-1.57-.37-2.1-.31-10.03-.46-8.02-.15-8.04-.22-8.66,0-5.91,2.08-9.29,10.37-9.11,16.86.05,1.69.51,3.49,1.45,7.09,1.24,4.79,2.67,10.3,4.73,10.39h0Z"/>
</g>
<g id="Grund_u_Deckplatten_Highlights" data-name="Grund u Deckplatten Highlights">
  <path id="Grund_u_Deckplatten_Highlight4" data-name="Grund u Deckplatten Highlight4" class="Grund_Deckplatten1521Fill" style= {{
            fill: props.colors.Grund_Deckplatten1521Fill,
            stroke: props.colors.Grund_Deckplatten1521Fill,
            opacity:props.colors.Grund_Deckplatten1521Fill,
          }} d="M201.06,47.12c-3.17.89-7.1-.31-10.32-.65-3.45-.37-6.9-.69-10.37-.79-6.97-.21-14.04.2-21,.68-6.58.45-13.1,1.3-19.52,2.86-6.62,1.62-16.19,4.7-21.12-2.05-2.25-3.09-7.46-.1-5.18,3.03,9.59,13.15,27.27,4.05,40.1,2.65,8.48-.93,17.23-1.37,25.76-1.2,4.42.09,8.81.55,13.2,1.03,3.34.36,6.74,1.16,10.03.23,3.71-1.05,2.13-6.84-1.6-5.79Z"/>
  <path id="Grund_u_Deckplatten_Highlight3" data-name="Grund u Deckplatten Highlight3" class="Grund_Deckplatten1521Fill" style= {{
            fill: props.colors.Grund_Deckplatten1521Fill,
            stroke: props.colors.Grund_Deckplatten1521Fill,
            opacity:props.colors.Grund_Deckplatten1521Fill,
          }} d="M201.03,55.66c-3.11,1.66-6.62.83-9.99,1.01-2.69.14-5.3.79-7.89,1.46-7.6,1.96-14.57,1.97-22.36,1.71-7-.24-14-.22-20.99.07-3.61.15-7.34.62-10.93.04-3.67-.59-7.17-2.13-10.55-3.61-3.5-1.53-6.56,3.64-3.03,5.18,6.56,2.87,12.63,4.85,19.86,4.61,8.57-.28,17.08-.58,25.65-.29,7.94.27,15.34.38,23.07-1.68,4.01-1.07,7.58-1.42,11.68-1.44,3.11-.02,5.74-.38,8.52-1.87,3.41-1.82.38-7-3.03-5.18Z"/>
  <path id="Grund_u_Deckplatten_Highlight2" data-name="Grund u Deckplatten Highlight2" class="Grund_Deckplatten1521Fill" style= {{
            fill: props.colors.Grund_Deckplatten1521Fill,
            stroke: props.colors.Grund_Deckplatten1521Fill,
            opacity:props.colors.Grund_Deckplatten1521Fill,
          }} d="M203.15,110.17c-13.11-3.37-26.55-2.78-39.95-2.13-14.28.69-29.34,2.78-43.51.2-3.78-.69-5.39,5.09-1.6,5.79,27.54,5.01,56.08-5.11,83.46,1.93,3.74.96,5.34-4.82,1.6-5.79Z"/>
  <path id="Grund_u_Deckplatten_Highlight1" data-name="Grund u Deckplatten Highlight1" class="Grund_Deckplatten1521Fill" style= {{
            fill: props.colors.Grund_Deckplatten1521Fill,
            stroke: props.colors.Grund_Deckplatten1521Fill,
            opacity:props.colors.Grund_Deckplatten1521Fill,
          }} d="M204.62,118.91c-6.94-1.78-14.57.62-21.56,1.27-7.48.7-14.97.6-22.46,1.12-7.08.49-13.95,2.3-21.08,2.47-7.93.19-15.7-.98-23.31-3.21-3.71-1.09-5.3,4.7-1.6,5.79,14.15,4.16,27.35,3.45,41.73,1.41,7.52-1.06,15.2-.68,22.77-1.25,3.94-.29,7.87-.71,11.79-1.22,4.02-.53,8.1-1.63,12.12-.6,3.74.96,5.34-4.83,1.6-5.79Z"/>
</g>
</svg>
  );
}

export default Brustwirbelkorper8VdLiegend1521;
