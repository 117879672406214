import style from "./style.scss";

function Rechter2FingerDpSitzend1316(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 183.9 474.2"
    >
      <g id="secondFinger">
        <g id="background">
          <path
            id="background_white"
            data-name="background white"
            class="rechter_finger2_dp_sitzend1316_cls8"
            d="M0,470.6l6.1-9.4c11.6-1.8,46.2-49.8,30.8-139.7-4.1-49.8-8.5-97.4-7.9-140.9,2.1-59.3,3.8-122.9,9.7-147.6C44,12.5,55.9,1.2,75.5,1c17.7-2.2,29.6,3,31.8,23.7l3.3,98.1c1,30.1,4.1,59.9,10,89.6,4.5,28,6.1,46.5,6,83.3,1.1,21.8,14.6,29.5,23.7,23.6,10.6-6.6,12.8-36.8,16.2-76.2l17.4,230.7L0,470.6H0Z"
          />
        </g>
        <g id="secondFinger1">
          <path
            id="fingerpart14"
            class="rechter_finger2_dp_sitzend1316_cls2"
            d="M66.6,470.6c0-7,1.5-12.3,3.3-23.6,4.2-17.3,12.8-25.6,24.6-27.3,5.6-1,11-1.6,16.1-1,8,.9,14.8,6.3,17.6,13.9,5,13.2,6.6,24.3,6.3,39.7"
          />
          <path
            id="fingerpart15"
            class="rechter_finger2_dp_sitzend1316_cls2"
            d="M77.8,414.1c-9.3,8.1-15.3,6.5-19.4,0-9.2-7.1-7-16.4,0-26.7,8.8-22.6,6.5-57.8.2-97.7-.1-.7-.1-1.4,0-2.1.6-9.8-.5-20-4-30.9-.5-1.4-1.4-2.7-2.5-3.7-2.3-2-3.2-5.4-2.7-10.3.2-1.6.2-3.2,0-4.8-1.9-16.1,1.2-20.7,6.1-21.5l11.1,1.1c2.9.3,5.9-.1,8.6-1.3,8.6-3.5,16.5-5.1,22.5-2.5,1.3.6,2.4,1.7,3.1,3,2.2,4.5,3.1,9.1,2.5,13.6-.2,1.7.3,3.4,1.3,4.8,2.3,2.9,3.1,6.1.9,9.5-2.3,6.1-2.3,12.9-.5,20.2.3,1.1.5,2.3.6,3.4.4,6.8.9,15.3,1.4,25.3,1.9,41.1,8.8,73.9,24.6,92.4,4.2,8.1,3.9,15.2,0,21.6.1,8.3-3.3,8.8-7.9,6.7-17-5.7-32.5-6.5-45.9,0h0Z"
          />
          <path
            id="fingerpart13"
            class="rechter_finger2_dp_sitzend1316_cls2"
            d="M43.4,194.7h0c5-9.2,7.3-20.9,7.9-34.1.1-2.9.6-5.9,1.2-8.8,3.7-16.2,2.8-28.2-3.1-35.5-1.7-2.1-2.3-4.8-2-7.4.4-3.5.9-6.2,1.8-8,.6-1,1-2.2,1.1-3.4.7-7.6,3.1-10.1,13.2-8.1,4,1.3,8.1.6,12.6-.6,8.1-4,13.4-1,12.7,6.7-.2,1.9.4,3.7,1.5,5.2,3.8,4.8,4,9.1,1.1,15.3-1,2-1.3,4.3-1,6.5.2,19.7,3.4,40.5,9,62.1.6,2.1,1.5,4.2,3.1,5.8,3.3,3.6,2.9,8.3-.3,13.8-.9,1.6-2.6,2.4-4.4,2.3-6.6-.5-15.3,2-24.5,5.1-1.3.4-2.6.4-3.9,0-7.5-2.5-14.6-3.9-20.8-3.6-4.1-.9-5.6-4.4-6.2-8.9-.2-1.6,0-3.1.8-4.5h0Z"
          />
          <path
            id="fingerpart12"
            class="rechter_finger2_dp_sitzend1316_cls2"
            d="M50.2,83h0c-3.2-1.8-4.1-4.3-3.1-7.3,7.7-8.4,12.6-17.7,12.6-29.7,0-1-.2-2.1-.7-3-1.2-2.3-1.3-6-.9-10.2-.5-5.6,3.2-10.7,9.5-15.5,6.2-4.4,16.5-2.3,18.3.5,3.8,9.4,3.8,17.4,0,23.9-1.5-.7-5.4,18.8,4.2,27.5,3.3,2.9,3.8,5.7.5,8.5-.5,4.9-4.4,6.2-9.9,5.9-5.1,2.5-10.2,3-15.4,1.7-9.6-1.9-11.9,2.5-15-2.3h0Z"
          />
          <path
            id="fingerpart11"
            class="rechter_finger2_dp_sitzend1316_cls4"
            d="M81.9,436c2.3,9.9.8,16.3-3.5,20-3.9,4.5-6.8,9.1-6.3,14.5"
          />
          <path
            id="fingerpart10"
            class="rechter_finger2_dp_sitzend1316_cls4"
            d="M73.9,417.1c1.4.5,3.2.9,5.2,1.2,8.3,1.4,16.8.5,24.7-2.4,4.8-1.8,10-2.9,15.7-3.2"
          />
          <path
            id="fingerpart9"
            class="rechter_finger2_dp_sitzend1316_cls4"
            d="M133.7,455.1c5.6,2.2,10.1,6.8,11.8,18.6"
          />
          <path
            id="fingerpart8"
            class="rechter_finger2_dp_sitzend1316_cls4"
            d="M60.2,389c4.5-4,8.1-13.5,10.5-31.2l4.1-45.2c-2-14.1-2.7-26.5.2-34.8.4-1.1.6-2.2.6-3.3.2-14-2.3-22.4-7.7-24.9"
          />
          <path
            id="fingerpart7"
            class="rechter_finger2_dp_sitzend1316_cls4"
            d="M118.3,368.5c-8.2-13.2-14.6-25.8-15.6-36.8-1.2-7.4-3.4-14.3-6.4-20.8-.3-.8-.6-1.6-.6-2.5-.4-11.8-1.7-21.6-4.8-27.7-1.4-2.8-2-5.8-2-8.9,0-8.3-.8-13.9-2.2-17.4"
          />
          <path
            id="fingerpart6"
            class="rechter_finger2_dp_sitzend1316_cls4"
            d="M50,239.3c3.2-.2,4.7-5.5,4.6-15.8"
          />
          <path
            id="fingerpart5"
            class="rechter_finger2_dp_sitzend1316_cls4"
            d="M103.2,231.4c-3.1.1-5.3-3.8-6.7-10.4"
          />
          <path
            id="fingerpart4"
            class="rechter_finger2_dp_sitzend1316_cls4"
            d="M50.6,203c5.1-1.2,10.4-.5,15.9,2,1.5.7,3.2,1.1,4.9,1.2,2.3,0,4.6-.5,6.6-1.9,4.2-2.5,10.4-3.3,17.4-3.6"
          />
          <path
            id="fingerpart3"
            class="rechter_finger2_dp_sitzend1316_cls4"
            d="M46.7,201.7c.9-5.2,2.4-9.8,4.6-13.6,4.4-9.9,8.3-21.1,11.6-33.6.6-6.4,2.7-13.4,5.6-20.9,1.6-11.3.4-19.9-3.9-25.5"
          />
          <path
            id="fingerpart2"
            class="rechter_finger2_dp_sitzend1316_cls4"
            d="M81.3,104.6c-.9,2-1.5,4.1-1.9,6.3,1,7.9,1.4,15.6,1.1,22.9-.1,2.9,0,5.8.6,8.6,3.1,18.1,8.4,35.5,16.8,51.9"
          />
          <path
            id="fingerpart1"
            class="rechter_finger2_dp_sitzend1316_cls4"
            d="M52.4,77c-.4-2.3,1.4-4.9,4.9-7.7,4.4-5.9,7.8-12.1,9.1-19.2.4-1.9.5-3.8.6-5.7.1-6.9,1.4-11.3,4.9-11.3,4-.6,5.8,2.3,5.3,9-.3,3.9-.4,7.8-.2,11.7.6,10,2.9,15,7.2,14.6"
          />
          <g id="outline">
            <path
              id="outlinepart2"
              class="rechter_finger2_dp_sitzend1316_cls1"
              d="M6.1,461.2c8.5,0,15.7-18.6,18.5-24.5,7-14.7,13.5-30.3,16.3-46.6,3-17.5-.5-34.3-2.3-51.7-1.9-18.2-3.4-36.3-4.8-54.5-2.5-33.2-5-66.6-4.8-99.9,0-1.2,0-2.3,0-3.5,2.1-59.3,3.8-122.9,9.7-147.6C44,12.5,55.9,1.2,75.5,1c17.7-2.2,29.6,3,31.8,23.7l3.3,98.1c1,30.1,4.1,59.9,10,89.6,4.5,28,6,36.7,6.2,72.9,1.1,21.9,7.6,38.1,18.6,35.9,10.3-1.2,15-13.9,20-66.2"
            />
            <path
              id="outlinepart1"
              class="rechter_finger2_dp_sitzend1316_cls4"
              d="M166,249.1c.2-2,.4-4,.5-6"
            />
          </g>
          <path
            id="secondFingerpart2"
            class="rechter_finger2_dp_sitzend1316_cls3"
            d="M176.3,359.1c-2.4,16.3-5.9,30.9-11,42.5-.9,2-2.3,3.8-4,5.1l-2.5,1.9c-1.3,1-2.1,2.5-2.3,4.1-1.1,8.6-1.5,16.9-.3,24.5.3,2,1.5,3.7,3,4.9,1.9,1.5,3.2,3.3,3.8,5.4.6,1.9,2.3,3.2,4.2,3.5,5.1.8,10.4-.7,15.7-3.9"
          />
          <path
            id="secondFingerpart1"
            class="rechter_finger2_dp_sitzend1316_cls3"
            d="M182,455.1c-10.4,3.4-17.7,9.9-22.6,18.6"
          />
        </g>
      </g>
      <g id="highlights">
        <path
          id="highlights2"
          class="fullfinger1316fill"
          style={{
            fill: props.colors.fullfinger1316fill,
            stroke: props.colors.fullfinger1316fill,
          }}
          d="M43.4,194.7h0c5-9.2,7.3-20.9,7.9-34.1.1-2.9.6-5.9,1.2-8.8,3.7-16.2,2.8-28.2-3.1-35.5-1.7-2.1-2.3-4.8-2-7.4.4-3.5.9-6.2,1.8-8,.6-1,1-2.2,1.1-3.4.7-7.6,3.1-10.1,13.2-8.1,4,1.3,8.1.6,12.6-.6,8.1-4,13.4-1,12.7,6.7-.2,1.9.4,3.7,1.5,5.2,3.8,4.8,4,9.1,1.1,15.3-1,2-1.3,4.3-1,6.5.2,19.7,3.4,40.5,9,62.1.6,2.1,1.5,4.2,3.1,5.8,3.3,3.6,2.9,8.3-.3,13.8-.9,1.6-2.6,2.4-4.4,2.3-6.6-.5-15.3,2-24.5,5.1-1.3.4-2.6.4-3.9,0-7.5-2.5-14.6-3.9-20.8-3.6-4.1-.9-5.6-4.4-6.2-8.9-.2-1.6,0-3.1.8-4.5h0Z"
        />
        <path
          id="highlights1"
          class="fullfinger1316fill"
          style={{
            fill: props.colors.fullfinger1316fill,
            stroke: props.colors.fullfinger1316fill,
          }}
          d="M77.8,414.8c-9.3,8.1-15.3,6.5-19.4,0-9.2-7.1-7-16.4,0-26.7,8.8-22.6,6.5-57.8.2-97.7-.1-.7-.1-1.4,0-2.1.6-9.8-.5-20-4-30.9-.5-1.4-1.4-2.7-2.5-3.7-2.3-2-3.2-5.4-2.7-10.3.2-1.6.2-3.2,0-4.8-1.9-16.1,1.2-20.7,6.1-21.5l11.1,1.1c2.9.3,5.9-.1,8.6-1.3,8.6-3.5,16.5-5.1,22.5-2.5,1.3.6,2.4,1.7,3.1,3,2.2,4.5,3.1,9.1,2.5,13.6-.2,1.7.3,3.4,1.3,4.8,2.3,2.9,3.1,6.1.9,9.5-2.3,6.1-2.3,12.9-.5,20.2.3,1.1.5,2.3.6,3.4.4,6.8.9,15.3,1.4,25.3,1.9,41.1,8.8,73.9,24.6,92.4,4.2,8.1,3.9,15.2,0,21.6.1,8.3-3.3,8.8-7.9,6.7-17-5.7-32.5-6.5-45.9,0h0Z"
        />
        <path
          id="highlights5"
          class="fullfinger1316fill"
          style={{
            fill: props.colors.fullfinger1316fill,
            stroke: props.colors.fullfinger1316fill,
          }}
          d="M50.2,83h0c-3.2-1.8-4.1-4.3-3.1-7.3,7.7-8.4,12.6-17.7,12.6-29.7,0-1-.2-2.1-.7-3-1.2-2.3-1.3-6-.9-10.2-.5-5.6,3.2-10.7,9.5-15.5,6.2-4.4,16.5-2.3,18.3.5,3.8,9.4,3.8,17.4,0,23.9-1.5-.7-5.4,18.8,4.2,27.5,3.3,2.9,3.8,5.7.5,8.5-.5,4.9-4.4,6.2-9.9,5.9-5.1,2.5-10.2,3-15.4,1.7-9.6-1.9-11.9,2.5-15-2.3h0Z"
        />
        <path
          id="highlights3"
          class="endglied1316fill"
          style={{
            fill: props.colors.endglied1316fill,
            stroke: props.colors.endglied1316fill,
          }}
          d="M50.2,83h0c-3.2-1.8-4.1-4.3-3.1-7.3,7.7-8.4,12.6-17.7,12.6-29.7,0-1-.2-2.1-.7-3-1.2-2.3-1.3-6-.9-10.2-.5-5.6,3.2-10.7,9.5-15.5,6.2-4.4,16.5-2.3,18.3.5,3.8,9.4,3.8,17.4,0,23.9-1.5-.7-5.4,18.8,4.2,27.5,3.3,2.9,3.8,5.7.5,8.5-.5,4.9-4.4,6.2-9.9,5.9-5.1,2.5-10.2,3-15.4,1.7-9.6-1.9-11.9,2.5-15-2.3h0Z"
        />
        <ellipse
          id="highlights4"
          class="grundgelenk1316fill"
          style={{
            fill: props.colors.grundgelenk1316fill,
            stroke: props.colors.grundgelenk1316fill,
          }}
          cx="95.5"
          cy="420.3"
          rx="44.8"
          ry="16.2"
          transform="translate(-64 19.9) rotate(-8.9)"
        />
      </g>
    </svg>
  );
}

export default Rechter2FingerDpSitzend1316;
