import atom from "./atom.svg";
import bone from "./bone.svg";
import crosshair from "./crosshair.svg";
import diameter from "./diameter.svg";
import expand from "./expand.svg";
import hearthRibs from "./hearth-ribs.svg";
import measureBand from "./measure-band.svg";
import megaphone from "./megaphone.svg";
import meterColors from "./meter-colors.svg";
import meterRed from "./meter-red.svg";
import patientsMix from "./patients-mix.svg";
import radioactiveAlt from "./radioactive-alt.svg";
import radioactiveBlk from "./radioactive-blk.svg";
import radioactiveGr from "./radioactive-gr.svg";
import radioactiveY from "./radioactive-y.svg";
import ruller from "./ruller.svg";
import scaleAlt from "./scale-alt.svg";
import scaleBlack from "./scale-black.svg";
import scaleFeet from "./scale-feet.svg";
import scaleFilled from "./scale-filled.svg";
import scale from "./scale.svg";
import targetFail from "./target-fail.svg";
import targetSucces from "./target-success.svg";
import thunderGr from "./thunder-gr.svg";
import time from "./time.svg";
import toLittleAlt from "./to-little-alt.svg";
import toLittle from "./to-little.svg";
import toMuch from "./to-much.svg";
import singleChamber from "./single-chamber.svg";
import dualChamber from "./dual-chamber.svg";
import focusLarge from "./focus-large.svg";
import focusSmall from "./focus-small.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  atom,
  bone,
  crosshair,
  diameter,
  expand,
  hearthRibs,
  measureBand,
  megaphone,
  meterColors,
  meterRed,
  patientsMix,
  radioactiveAlt,
  radioactiveBlk,
  radioactiveGr,
  radioactiveY,
  ruller,
  scaleAlt,
  scaleBlack,
  scaleFeet,
  scaleFilled,
  scale,
  targetFail,
  targetSucces,
  thunderGr,
  time,
  toLittleAlt,
  toLittle,
  toMuch,
  singleChamber,
  dualChamber,
  focusLarge,
  focusSmall,
};
