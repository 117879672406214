import style from "./style.scss";

function LinkesSesambeinTangLiegend(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 281.03 471.16">
      <g id="sesame_bone" data-name="sesame bone">
        <path
          id="sesame_bone_1"
          data-name="sesame bone 1"
          class="a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_4"
          style={{
            fill: props.colors
              .a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_4,
            stroke:
              props.colors.a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_4,
          }}
          d="M278.37,381.79c-.35-14.59-5.31-26.16-18.93-32.02-3.9-1.68-7.69-3.61-11.12-6.1-32.16-23.3-47.41-60.32-50.7-107.03-2.82-26.95,5.07-55.21,18.36-84.12,1.67-3.64,4.4-6.7,7.82-8.78,20.8-12.61,19.78-23.28,8.43-33.03-35.86-30.14-71.14-43.19-106.38-54.79-17.85-8.1-30.62-1.98-40.05,13.46-16.84,16.08-35.33,23.39-55.75,20.51-16.8,1.59-22.04,16.61-19.23,41.01l4.17,21.47c-15.26,7.74-17.87,23.94-8.65,48.06,8.18,15.45,18.97,29.09,32.59,40.77,3.22,2.76,6.08,5.93,8.38,9.5,33.54,52.2,55.09,128.46,68.94,220.17"
        />
        <path
          id="sesame_bone_2"
          data-name="sesame bone 2"
          class="a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_3"
          style={{
            fill: props.colors
              .a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_3,
            stroke:
              props.colors.a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_3,
          }}
          d="M185.13,141.15c-31.05-11.38-62.02-19.32-92.75-16.04-4.97.53-9.97.62-14.96.51-24.55-.55-45.35,8.39-62.43,26.75,3.89,9.54,7.39,23.95,10.55,42.43,1.14,6.69,4.09,12.89,8.24,18.26,8.16,10.58,12.73,28.5,18.77,46.16-6.37,16.45-10.61,31.98-7.24,44.23,3.7,18.25,25.35,39.52,49.22,61.17"
        />
        <path
          id="sesame_bone_3"
          data-name="sesame bone 3"
          class="a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_4"
          style={{
            fill: props.colors
              .a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_4,
            stroke:
              props.colors.a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_4,
          }}
          d="M159.82,7.85c9.01-9.34,20.47-9.82,35.57,2.88,15.79,10.91,24.41,21.52,23.07,31.72-1.23,15.77-5.94,22.78-12.96,23.93-1.9.31-3.83-.11-5.61-.86-5.27-2.22-11.41-1.35-17.96.97-9.35,4.28-14.38-2.27-17.98-12.39-.43-1.21-1-2.38-1.66-3.49-4.06-6.78-5.61-11.92-4.59-15.35.89-2.97,1.28-6.07.68-9.11-1.67-8.46-1.25-14.63,1.44-18.3h0Z"
        />
        <path
          id="sesame_bone_4"
          data-name="sesame bone 4"
          class="a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_4"
          style={{
            fill: props.colors
              .a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_4,
            stroke:
              props.colors.a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_4,
          }}
          d="M24.23,51.57c-6.22-5.8-4.72-12.19,2.93-19.05,9.96-14.71,23.08-19.22,39.09-14.42,8.95.99,16.69,3.99,21.47,11.86,2.28,6.82.38,11.63-4.47,15.02-2.51,1.75-4.64,3.99-5.98,6.74-5.88,12.11-17.1,16.26-31.21,16.11-6.93.81-11.73-1.7-14.63-7.15-1.83-3.44-4.35-6.44-7.21-9.1h.01Z"
        />
        <path
          id="sesame_bone_5"
          data-name="sesame bone 5"
          class="a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_3"
          style={{
            fill: props.colors
              .a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_3,
            stroke:
              props.colors.a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_3,
          }}
          d="M66.23,470.87c33.61-47.84,71.86-83.55,115.44-105.34,30.73-15.41,63.7-28.35,99.22-38.48"
        />
        <path
          id="sesame_bone_6"
          data-name="sesame bone 6"
          class="a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_3"
          style={{
            fill: props.colors
              .a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_3,
            stroke:
              props.colors.a1425_Linkes_SesambeinGroßzehe_tang_liegend_cls_3,
          }}
          d="M82.39,325.28c-19.39,8.1-34.17,17.16-39.73,28.1-3.44,6.78-2.05,15.06,3.28,20.48,4.23,4.3,9.84,6.07,16.69,5.57,1.04-.08,1.58,1.21.79,1.9-2.69,2.35-4.96,4.67-6.6,6.95-3.29,4.55-1.92,10.93,2.79,14,13.89,9.04,27.36,17.82,40.45,26.34"
        />
      </g>
      <g id="overlays">
        <g id="sesame_bone_overlay" data-name="sesame bone overlay">
          <path
            id="sesame_bone_overlay_1"
            data-name="sesame bone overlay 1"
            class="a1425_sesambeineFill"
            style={{
              fill: props.colors.a1425_sesambeineFill,
              stroke: props.colors.a1425_sesambeineFill,
            }}
            d="M159.82,7.77c9.01-9.34,20.47-9.82,35.57,2.88,15.79,10.91,24.41,21.52,23.07,31.72-1.23,15.77-5.94,22.78-12.96,23.93-1.9.31-3.83-.11-5.61-.86-5.27-2.22-11.41-1.35-17.96.97-9.35,4.28-14.38-2.27-17.98-12.39-.43-1.21-1-2.38-1.66-3.49-4.06-6.78-5.61-11.92-4.59-15.35.89-2.97,1.28-6.07.68-9.11-1.67-8.46-1.25-14.63,1.44-18.3h0Z"
          />
          <path
            id="sesame_bone_overlay_2"
            data-name="sesame bone overlay 2"
            class="a1425_sesambeineFill"
            style={{
              fill: props.colors.a1425_sesambeineFill,
              stroke: props.colors.a1425_sesambeineFill,
            }}
            d="M24.23,51.49c-6.22-5.8-4.72-12.19,2.93-19.05,9.96-14.71,23.08-19.22,39.09-14.42,8.95.99,16.69,3.99,21.47,11.86,2.28,6.82.38,11.63-4.47,15.02-2.51,1.75-4.64,3.99-5.98,6.74-5.88,12.11-17.1,16.26-31.21,16.11-6.93.81-11.73-1.7-14.63-7.15-1.83-3.44-4.35-6.44-7.21-9.1h.01Z"
          />
        </g>
        <path
          id="metatarsal_head_overlay"
          data-name="metatarsal head overlay"
          class="a1425_MittelfußköpfchenFill"
          style={{
            fill: props.colors.a1425_MittelfußköpfchenFill,
            stroke: props.colors.a1425_MittelfußköpfchenFill,
          }}
          d="M30.05,89.89c22.67,2.33,41.01-5.04,55.75-20.51,9.92-17.24,23.93-19.27,40.05-13.46,27.93,9.36,54.18,20.35,77.7,33.97,38.18,21.64,50.94,36.53,15.05,58.43-6.6,9.97-11.9,26.32-17,43.61-52.96,18.38-112.07,17.58-176.55,0-13.96-55.13-26.35-96.8,5.01-102.03Z"
        />
      </g>
    </svg>
  );
}

export default LinkesSesambeinTangLiegend;
