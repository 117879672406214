import { Modal } from "react-bootstrap";
import IndicationList from "../IndicationsList/IndicationsList";

function IndicationsListModal({
  shown = false,
  onHide,
  dark = false,
  selectedIndications = [],
  selectIndiation,
}) {
  return (
    <Modal show={shown} onHide={onHide}>
      <Modal.Header
        closeButton
        className={`p-2 ${dark && "bg-dark text-white"}`}
      >
        <h5 className="p-0 m-0">Select Indication</h5>
      </Modal.Header>
      <Modal.Body className={`${dark && "bg-dark text-white"}`}>
        <IndicationList
          selectedIndications={selectedIndications}
          selectIndication={selectIndiation}
        />
      </Modal.Body>
    </Modal>
  );
}

export default IndicationsListModal;
