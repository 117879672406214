import style from "./style.scss";

export default function RechtesSchultergelenkYAufnahmeDvOblStehend2012(props) {
  return (
    <svg viewBox="0 0 249.4 449.4">
      <g id="y-shoulder">
        <g id="background_layer">
          <path
            id="background"
            class="Rechtes_Schultergelenk_YAufnahme_dv_obl_stehend_überlagernd2012_st0"
            d="M202.8,435.6c6.1-9.8-1.3-19.2-8-49c-2.1-9.5-3.8-29-7-68c-4.9-58.7-1.5-47.4-5-77
			c-2.6-21.5-0.8-43.4-2-65c-0.2-3.9-0.8-13.6,5-22c3.5-5.1,5.8-4.4,13-11c5.7-5.2,15.1-13.7,18-26c2-8.6-0.7-12.4,3-16
			c3.4-3.3,6.9-1.4,16-5c5.1-2,8.7-3.5,11-7c4.1-6.2,1.2-14.6,0-18c-2.7-7.9-7.7-12.8-10-15c-25.3-24.3-51-35-51-35
			c-15.2-6.4-22.9-9.5-31-9c-8.3,0.6-16.2,3.6-22-1c-4-3.2-2.4-6.3-6-9c-6.8-5.1-14.8,4.1-31,3c-10.1-0.7-12.9-4.6-19-2
			s-4.8,7.1-11,12c-7.9,6.1-13.9,2-31,7c-9.2,2.7-13.8,4-17,8c-7.8,9.8-4.1,29,6,38c2.4,2.2,2.2,1,17,9c13.7,7.4,15.7,9.4,17,12
			c3.3,6.9-13.1,7-7.1,13c12.3,12.4,20,10,33.1,34c6.4,11.8,12.7,19.6,18,30c12.4,24.2,18.5,44.2,20,49c7.3,24.1,9.4,43.1,11,58
			c3.1,29.3-0.1,29.9,3,70c2.5,32.3,4.4,29.4,5,51c0.9,29.6-2.6,37.5,4,45C157.4,453.9,193,451.4,202.8,435.6z"
          />
        </g>
        <g id="upper_arm_with_shoulder">
          <path
            id="upper_arm_with_shoulder_1"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            d="M23.8,68.6c2.4,2.2,2.2,1,17,9c13.7,7.4,15.7,9.4,17,12c3.3,6.9-18.5,6-1,19
			c12.9,9.6,15.5,12.8,27,28c8.1,10.7,12.7,19.6,18,30c12.4,24.2,18.5,44.2,20,49c7.3,24.1,9.4,43.1,11,58c3.1,29.3-0.1,29.9,3,70
			c2.5,32.3,4.4,29.4,5,51c0.9,29.6-2.6,37.5,4,45"
          />
          <path
            id="upper_arm_with_shoulder_2"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            d="M202.8,435.6c6.1-9.8-1.3-19.2-8-49c-2.1-9.5-3.8-29-7-68
			c-4.9-58.7-1.5-47.4-5-77c-2.6-21.5-0.8-43.4-2-65c-0.2-3.9-0.8-13.6,5-22c3.5-5.1,5.8-4.4,13-11c5.7-5.2,15.1-13.7,18-26
			c2-8.6-0.7-12.4,3-16c3.4-3.3,6.9-1.4,16-5c5.1-2,8.7-3.5,11-7c4.1-6.2,1.2-14.6,0-18c-2.7-7.9-7.7-12.8-10-15
			c-25.3-24.3-51-35-51-35c-15.2-6.4-22.9-9.5-31-9c-8.3,0.6-16.2,3.6-22-1c-4-3.2-2.4-6.3-6-9c-6.8-5.1-14.8,4.1-31,3
			c-10.1-0.7-12.9-4.6-19-2s-4.8,7.1-11,12c-7.9,6.1-13.9,2.1-31,7c-9.2,2.7-13.8,4-17,8"
          />
          <line
            id="upper_arm_with_shoulder_3"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st2"
            x1="181.3"
            y1="225.6"
            x2="181.5"
            y2="223.1"
          />
          <line
            id="upper_arm_with_shoulder_4"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st3"
            x1="166.2"
            y1="416.6"
            x2="180.9"
            y2="230.5"
          />
          <line
            id="upper_arm_with_shoulder_5"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st2"
            x1="165.8"
            y1="421.6"
            x2="166"
            y2="419.1"
          />
          <line
            id="upper_arm_with_shoulder_6"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st4"
            x1="160.8"
            y1="413.6"
            x2="133.3"
            y2="279.1"
          />
          <line
            id="upper_arm_with_shoulder_7"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            x1="126.8"
            y1="122.6"
            x2="23.8"
            y2="68.6"
          />
          <line
            id="upper_arm_with_shoulder_8"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            x1="133.3"
            y1="117.6"
            x2="173.7"
            y2="87.6"
          />
          <line
            id="upper_arm_with_shoulder_9"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            x1="168.8"
            y1="82.6"
            x2="189.8"
            y2="68.6"
          />
          <path
            id="upper_arm_with_shoulder_10"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            d="M131.3,40.6c0.8,0-11.5-14.5,1.5-29"
          />
          <path
            id="upper_arm_with_shoulder_11"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            d="M48.8,50.6c23.8-11.2,58.5-13.6,71-10"
          />
          <path
            id="upper_arm_with_shoulder_12"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            d="M144.8,40.6c24.9,0,45,12.5,45,28"
          />
          <path
            id="upper_arm_with_shoulder_13"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            d="M184.8,68.6c-9.8-9.5-24.8-12.3-31.3-9"
          />
          <path
            id="upper_arm_with_shoulder_14"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            d="M95.8,102.6c3.4-13.9,17.8-36.6,44-43"
          />
          <path
            id="upper_arm_with_shoulder_15"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st5"
            d="M117.8,203.5c9-44.9-28-65.9-22-100.9"
          />
          <path
            id="upper_arm_with_shoulder_16"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            d="M64.8,81.1c10.5-12.5,39-5.6,39-12.5"
          />
          <line
            id="upper_arm_with_shoulder_17"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            x1="84.3"
            y1="13.6"
            x2="65.8"
            y2="15.6"
          />
          <line
            id="upper_arm_with_shoulder_18"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st1"
            x1="54.8"
            y1="57.6"
            x2="23.8"
            y2="61.6"
          />
        </g>
        <g id="ribs">
          <path
            id="ribs_1"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st2"
            d="M7.8,230.6c46.6-26.1,59.9-75.5,5.8-96"
          />
          <path
            id="ribs_2"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st2"
            d="M10.7,254.1c47.1-7,99.5-82.5,0-151.5"
          />
          <path
            id="ribs_3"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st2"
            d="M0.8,346.4c66-39.5,97.4-126.4,12.8-155.8"
          />
          <path
            id="ribs_4"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st2"
            d="M0.8,378.6c75.8-34.9,152.2-168.4,6.4-219"
          />
          <path
            id="ribs_5"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st2"
            d="M7.8,435.6c81-58.8,121.5-169,2.9-181.5"
          />
          <path
            id="ribs_6"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st2"
            d="M34.8,449.1c61.4-42.7,125.2-184.8,0-218.5"
          />
          <path
            id="ribs_7"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st2"
            d="M101.6,435.6c6.4-5.6,20.7-57.1-0.5-79"
          />
          <path
            id="ribs_8"
            class="rechtes_schultergelenk_yaufnahme_dv_obl_stehend_überlagernd2012_st2"
            d="M79.1,435.6c8-2.3,18.7-26.2,5.5-45"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="ribs_overlay"
          class="rips2012Fill"
          style={{
            fill: props.colors.rips2012Fill,
            stroke: props.colors.rips2012Fill,
          }}
          d="M10.7,102.6c34.6,24.4,63.5,60.7,55,91.5c18.5,17.3,25,40.9,23.4,68.6
		c16.9,22.1,23.4,51.6,11.9,93.9c12.1,12.6,18.1,46.4,0.5,79H79.1c12.5-12.4,14.1-27.4,5.5-45c-10.2,21-27,40.4-49.8,58.5l-27-13.5
		c50.1-39.5,90.5-92.5,73.1-141.5c-17.1,36.2-42,65.9-80.1,84.5v-32.2c31-17.2,50.9-42.7,61.5-75.3c-15.3-9.7-32.5-15.4-51.6-17
		l-2.9-23.5c12.2-6.5,22.5-15.8,31.5-27.1c-9.1-6.2-17.6-10.5-25.7-12.9l-6.4-31c16,5.7,30.1,12.2,41.3,20.3
		c2.7-15.2-7.2-34-34.9-45.3L10.7,102.6L10.7,102.6z"
        />
        <path
          id="upper_arm_shaft_overlay"
          class="upperarm2012Fill"
          style={{
            fill: props.colors.upperarm2012Fill,
            stroke: props.colors.upperarm2012Fill,
          }}
          d="M202.8,435.6l-57.9,4c-6-2.4-5.5-19-3.9-38.2l-6.6-86
		c0.9-42.6-5.5-79.1-16.6-111.9c4.8-18.2,1.1-32.8-5.5-46.1c-12.3-21.6-19.2-41-16.5-56.2c12-27.7,27.4-37.8,44-41.7
		c20.2-2.9,34.2,1.1,45,9c20.2,10.3,27.9,22.5,33.5,35.2c0.4,18.8-8.3,32.5-27.1,45.7c-12.1,4.4-11.5,33.2-9.7,73.7
		c2.6,60.2,6.4,116,13,161.9C197.3,405.4,209.8,424.8,202.8,435.6L202.8,435.6z"
        />
        <path
          id="scapula_overlay_00000033346662842114268300000016787342287500697229_"
          class="scapula2012Fill"
          style={{
            fill: props.colors.scapula2012Fill,
            stroke: props.colors.scapula2012Fill,
          }}
          d="M214.5,125.5
		c-10.3,11.5-17.7,18.9-23.4,23.9c-2,1.7-5.8,5-8.1,10.5c-0.4,1-1.7,4.2-2.2,11c-0.6,7.9,0.3,14,0.4,14.6c1,6.6,0.6,19.4-0.2,44.9
		c-0.9,27.4-3.7,50.3-4.8,60.1c-9.4,81.5-6.8,130.6-12.2,130.9c-6,0.4-14.9-58.2-22.7-109c-2.4-16.2-5.2-35.6-11.4-63
		c-4.5-19.4-8.9-35.3-12.1-45.9c-9.2-28.2-22-54.8-42.5-77.9c-18.5-17-33.5-25.9-21.6-30c4.9-1.4,4.8-5.2,3-9.8L23.8,68.6l-6-38l0,0
		c11.8-7.5,22.4-10.3,32.6-10.8c7.6,0.7,13.4-1,17-5.6C72.8,4.8,78.8-1,86.3,3.8c6.8,1.8,14.6,1.9,23.3,0.3
		C120.9-1,127.7,0,129.8,7.3c2.6,7.9,13.5,8.3,27,6.3c31.1,4.8,59.6,22.8,87,51.6c11.2,19.8,4.3,30.4-17,33.8c-0.8,0-3.3,0-5.7,1.6
		c-1.5,1.1-2.4,2.3-2.8,3.1"
        />
      </g>
    </svg>
  );
}
