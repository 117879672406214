import style from "./style.scss";

export default function RechterEllenbogenVdSitzend2212(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 374.59 928.09">
      <g id="Backround">
        <path
          id="backround1"
          class="elbow_vd_right2212_cls4"
          d="M.86,901.87c-2.67-7.17,1.27-11.08,6.76-32.16,6.01-23.1,4.64-31.3,8.27-55.34,1.07-7.08,2.38-14.3,17.77-69.72,15.76-56.73,18.88-67.04,24.61-92.96,2.86-12.93,7.62-35.53,5.47-64.25-2.14-28.68-6.41-37.01-9.57-41.01-8.32-10.52-18.18-12.06-19.88-22.26-1.44-8.64,4.01-17.24,7.57-22.86,7.25-11.44,14.03-13.06,13.67-19.14-.32-5.4-5.76-5.75-13.67-15.04-8.27-9.71-11.43-20.04-13.67-27.34-1.16-3.78-3.34-11.06-3.83-20.6-.64-12.54,2.34-15.37,5.19-34.09,3.42-22.44.28-25.92,5.47-34.18,5.7-9.07,12.01-8.92,20.51-16.41,7.99-7.04,10.76-14.53,19.14-45.11,12.22-44.59,11.74-48.38,19.14-71.09,3.22-9.86,5.2-14.95,10.94-32.81,9.14-28.42,10.3-34.75,10.94-39.65,1.89-14.45-.97-14.38-1.37-39.65-.48-30.51,3.65-32.9,1.37-54.68-2.32-22.16-7.14-24.82-4.1-31.44,11.38-24.83,89-29.61,110.73,2.73,4.83,7.18,4.39,12.46,6.84,38.28,0,0,3.13,32.95,9.57,73.82,7.06,44.79,21.62,75.77,34.18,105.27,10.04,23.6,20.06,42.58,38.28,56.05,3.99,2.95,8.67,5.86,19.14,13.67,18.92,14.12,28.39,21.18,32.81,27.34,10.88,15.15,14.4,37.01,8.2,56.05-1.96,6.03-7.44,22.84-21.87,30.08-5.77,2.89-9.68,2.6-21.87,8.2-9.18,4.21-13.77,6.32-16.41,9.57-7.07,8.71-.79,15.32-8.2,28.71-3.6,6.49-7.04,8.47-6.84,13.67.21,5.33,3.98,7.04,6.84,12.3,5.89,10.83,2.28,26.35-4.1,35.54-3.47,5-5.01,4.17-20.51,16.41-13.79,10.89-15.58,11.72-17.77,15.04-2.8,4.23-3.87,7.99-6.84,24.61-2.27,12.74-3.41,19.11-4.1,24.61-3.02,24.09-6.45,27.45-10.94,56.05-1.78,11.3-4.96,31.88-6.84,58.78-1.48,21.19-.67,25.12-2.73,42.38-3.09,25.83-5.8,24.61-6.84,41.01-1.38,21.83,3.32,25.81,4.1,57.42.51,20.52.77,30.78-2.73,36.91-16.1,28.22-81.34,31.14-95.7,8.2-3.78-6.03-1.01-9.02,0-31.44,1.72-38.29-5.79-42.56-2.73-75.19,1.36-14.46,2.93-14.68,5.47-38.28,2.47-22.92,4.19-38.9,1.37-39.65-4.58-1.21-22.93,37.37-31.44,80.66-6.84,34.79-10.26,52.19-9.57,76.56.42,14.66,1.93,20.93-2.73,27.34-15.79,21.72-78.64,12.87-87.49-10.94"
        />
      </g>
      <g id="Anconvd">
        <g id="Overview">
          <path
            id="outline4"
            class="elbow_vd_right2212_cls1"
            d="M167.72,349.55c6.43.54,16.49,1.03,28.71,0,22.26-1.88,27.16-6.73,45.11-8.2,13.05-1.07,31.62-2.6,49.22,8.2,3.74,2.3,22.01,14.09,25.97,34.18,1.3,6.61-.71,5.17-1.37,27.34-.56,18.78.65,27.62-4.1,30.08-4.64,2.4-9.5-4.1-15.04-1.37-4.87,2.41-5.91,9.79-6.84,16.41-1.47,10.47,1.13,12.3,0,21.87-.79,6.74-1.82,15.39-8.2,20.51-7.34,5.88-17.7,3.83-23.24,2.73-9.58-1.9-16.08-6.34-24.61-12.3-15.82-11.05-18.71-18.01-27.34-17.77-4.52.13-4.72,2.06-13.67,5.47-9.49,3.62-17.03,4.4-27.34,5.47-17.72,1.84-17.84-.8-31.44,1.37-9.89,1.58-9.87,2.98-23.24,5.47-10.6,1.97-16.92,3.14-24.61,2.73-6.18-.33-18.28-1.96-30.08-9.57-18.26-11.79-24.38-30.58-28.22-42.38-3.38-10.35-3.81-20.11-3.83-20.6-.64-12.54,2.34-15.37,5.19-34.09,3.42-22.44.28-25.92,5.47-34.18,5.7-9.07,12.01-8.92,20.51-16.41,7.99-7.04,10.76-14.53,19.14-45.11,12.22-44.59,11.74-48.38,19.14-71.09,3.22-9.86,5.2-14.95,10.94-32.81,9.14-28.42,10.3-34.75,10.94-39.65,1.89-14.45-.97-14.38-1.37-39.65-.48-30.51,3.65-32.9,1.37-54.68-2.16-20.64-6.49-24.36-4.62-30.12"
          />
          <path
            id="outline3"
            class="elbow_vd_right2212_cls1"
            d="M221.52,22.81c4.83,7.18,4.39,12.46,6.84,38.28,0,0,3.13,32.95,9.57,73.82,7.06,44.79,21.62,75.77,34.18,105.27,10.04,23.6,20.06,42.58,38.28,56.05,3.99,2.95,8.67,5.86,19.14,13.67,18.92,14.12,28.39,21.18,32.81,27.34,10.88,15.15,14.4,37.01,8.2,56.05-1.96,6.03-7.44,22.84-21.87,30.08-5.77,2.89-9.68,2.6-21.87,8.2-9.18,4.21-13.77,6.32-16.41,9.57-7.07,8.71-.79,15.32-8.2,28.71-3.6,6.49-7.04,8.47-6.84,13.67.21,5.33,3.98,7.04,6.84,12.3,5.89,10.83,2.28,26.35-4.1,35.54-3.47,5-5.01,4.17-20.51,16.41-13.79,10.89-15.58,11.72-17.77,15.04-2.8,4.23-3.87,7.99-6.84,24.61-2.27,12.74-3.41,19.11-4.1,24.61-3.02,24.09-6.45,27.45-10.94,56.05-1.78,11.3-4.96,31.88-6.84,58.78-1.48,21.19-.67,25.12-2.73,42.38-3.09,25.83-5.8,24.61-6.84,41.01-1.38,21.83,3.32,25.81,4.1,57.42.26,10.61.46,18.48.11,24.45"
          />
          <path
            id="outline2"
            class="elbow_vd_right2212_cls1"
            d="M126.49,892.14c.26-3,.52-6.55.71-10.8,1.72-38.29-5.79-42.56-2.73-75.19,1.36-14.46,2.93-14.68,5.47-38.28,2.47-22.92,4.19-38.9,1.37-39.65-4.58-1.21-22.93,37.37-31.44,80.66-6.84,34.79-10.26,52.19-9.57,76.56.08,2.88.21,5.43.32,7.73"
          />
          <path
            id="outline1"
            class="elbow_vd_right2212_cls1"
            d="M1.42,892.63c1.08-4.42,2.81-11.41,5.42-22.95,2.1-9.29,3.14-13.94,3.5-16.06,2.18-13.05,1.8-19.59,4.77-39.28,1.07-7.08,2.38-14.3,17.77-69.72,15.76-56.73,18.88-67.04,24.61-92.96,2.86-12.93,7.62-35.53,5.47-64.25-2.14-28.68-6.41-37.01-9.57-41.01-8.32-10.52-18.18-12.06-19.88-22.26-1.44-8.64,4.01-17.24,7.57-22.86"
          />
          <path
            id="partofancon9"
            class="elbow_vd_right2212_cls1"
            d="M41.89,501.29c8.01-3.92,14.78-5.09,19.14-5.47,8.44-.75,15.46.76,24.61,2.73,11.72,2.52,11.97,3.98,17.77,4.1,10.19.2,11.59-4.72,21.87-5.47,2.51-.18,15.6-1.13,23.24,6.84,2.58,2.7,3.19,5.01,6.33,24.23,3.46,21.23,3.64,24.07,1.87,27.72-4.1,8.42-11.13,5.77-17.77,13.67-12.25,14.58-4.41,42.79,1.37,56.05,3.39,7.78,5.94,10.12,9.57,20.51,2.36,6.75,5.32,15.01,5.47,25.97.19,14.22-4.24,25.19-6.84,31.44-.82,1.97-10.93,25.99-16.41,24.61-1.58-.4-2.92-2.95-2.73-51.95.04-11.39.15-21.67.09-39.61-.02-6.89-.05-8.71-.09-16.44-.17-30.21-.25-45.31,0-47.85,2.35-23.57,11.3-41.63,16.41-51.95,3.02-6.1,2.28-3.7,17.77-31.44,11.79-21.12,13.99-25.68,15.04-32.81,1.02-6.93-.22-8.26-4.1-32.81-1.25-7.93-3.99-25.38-5.47-42.38-1.18-13.44-1.37-24.42-1.37-31.44"
          />
          <line
            id="ulnar7"
            class="elbow_vd_right2212_cls1"
            x1="144.42"
            y1="912.78"
            x2="149.89"
            y2="754.2"
          />
          <line
            id="radius6"
            class="elbow_vd_right2212_cls1"
            x1="69.23"
            y1="654.4"
            x2="14.54"
            y2="908.68"
          />
          <path
            id="radius5"
            class="elbow_vd_right2212_cls1"
            d="M69.22,654.4c5.56-3.27,16.48-46.53-7.59-86.01"
          />
          <path
            id="radius4"
            class="elbow_vd_right2212_cls1"
            d="M119.87,728.22c-21.55,10.12-32.25,87.47-50.64,164.05"
          />
          <path
            id="ulnar6"
            class="elbow_vd_right2212_cls1"
            d="M255.15,558.71c-20.7-3.75-53.37,158-56.05,355.44"
          />
          <path
            id="partofancon8"
            class="elbow_vd_right2212_cls1"
            d="M41.89,389.19c-12.76,10.31-12.36,41.95,13.67,64.25"
          />
          <path
            id="partofancon7"
            class="elbow_vd_right2212_cls1"
            d="M69.23,357.74c17.38,0,15.72-20.5,31.44-41.01"
          />
          <path
            id="partofancon6"
            class="elbow_vd_right2212_cls1"
            d="M122.55,367.31c15.47-5.23,40.56-50.31,56.39-88.86"
          />
          <path
            id="partofancon5"
            class="elbow_vd_right2212_cls1"
            d="M122.54,231.96c16.09-28.9,35.94-183.5,9.57-205.06"
          />
          <path
            id="ulnar5"
            class="elbow_vd_right2212_cls1"
            d="M255.16,367.31c-15.5,0-28.03,14.67-28.03,32.81"
          />
          <path
            id="partofancon4"
            class="elbow_vd_right2212_cls1"
            d="M199.11,28.28c2.97,38.93,7.88,71.43,12.3,95.7,10.91,59.88,22.46,89.46,30.08,106.63,13.15,29.63,24.26,43.97,35.54,54.68,8.24,7.82,19.28,18.3,36.91,25.97,20.79,9.04,31.43,5.78,39.63,16.43,7.07,9.18,8.44,23.65,2.75,35.52-6.87,14.34-21.01,18.52-23.24,19.14"
          />
          <path
            id="partofancon3"
            class="elbow_vd_right2212_cls1"
            d="M185.44,311.27c-4.92,2.22-19.31,9.49-27.34,25.97-1,2.05-13.4,28.39,0,45.11,13.47,16.8,42.59,10.9,57.42,4.1,5.99-2.75,24.23-11.01,28.71-30.08,2.25-9.62,1.72-24.07-6.84-32.81-6.48-6.6-12.6-4.9-20.51-12.3-6.89-6.45-9.67-14.7-10.94-20.51"
          />
          <path
            id="partofancon2"
            class="elbow_vd_right2212_cls1"
            d="M147.15,482.69c24.96-40.59,24.31-102.75-12.46-124.95"
          />
          <path
            id="radius3"
            class="elbow_vd_right2212_cls1"
            d="M148.52,504.01c-63.57,23.24-106.63-1.22-106.63-2.73"
          />
          <path
            id="ulnar4"
            class="elbow_vd_right2212_cls1"
            d="M302.99,495.81c-1.65,3.77-5.88,12.03-15.04,17.77-10.39,6.52-20.87,5.87-27.34,5.47-3.61-.22-14.86-1.05-25.97-8.2-8.67-5.58-7.67-8.84-15.04-12.3-9.91-4.66-19.91-2.61-25.97-1.37-7.98,1.63-19.55,4-23.24,12.3-2.54,5.71,1.46,7.87,0,19.14-1.19,9.21-4.29,11.03-4.1,17.77.2,6.86,3.61,12.47,6.84,16.41"
          />
          <path
            id="ulnar3"
            class="elbow_vd_right2212_cls1"
            d="M271.56,538.91c17.38,0,31.44-19.27,31.44-43.09"
          />
          <path
            id="ulnar2"
            class="elbow_vd_right2212_cls1"
            d="M264.53,558.64c4.02-33.22,15.1-84.74,38.47-88.8"
          />
          <line
            id="ulnar1"
            class="elbow_vd_right2212_cls1"
            x1="289.33"
            y1="456.17"
            x2="287.28"
            y2="421.57"
          />
          <line
            id="partofancon1"
            class="elbow_vd_right2212_cls1"
            x1="29.58"
            y1="385.08"
            x2="55.56"
            y2="367.31"
          />
          <line
            id="radius2"
            class="elbow_vd_right2212_cls1"
            x1="122.54"
            y1="714.55"
            x2="116.48"
            y2="688.58"
          />
          <line
            id="radius1"
            class="elbow_vd_right2212_cls1"
            x1="110.41"
            y1="662.6"
            x2="129.33"
            y2="611.4"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="radialheadhighlight"
          class="radiushead2212fill"
          style={{
            fill: props.colors.RadiusHead2212Fill,
            stroke: props.colors.RadiusHead2212Fill,
          }}
          d="M157.63,551.18c-3.38,6.93-17.2,6.79-21.57,6.74-5.29-.05-9.02-.89-11.33-1.35-13.55-2.66-17.63.23-49.85-.26-3.67-.06-15.88-.36-19.25-6.14-.36-.62-1.02-1.98-2.36-3.84-2.21-3.07-4.61-5.16-6.34-6.47-1.82-1.12-4.34-2.87-7-5.39-2.41-2.29-3.71-3.52-4.73-5.33-4.43-7.83.82-18.41,2.02-20.83.92-1.85,1.67-3.37,3.24-5.06,2.81-3.03,6.6-4.81,13.75-6.07,6.14-1.08,14.35-2.53,24.83-.24,9.94,2.17,18.56,5.1,18.56,5.1,2.47.84,5.31,1.88,9,1.41,1.16-.15,2.28-.43,8.49-2.83,6.07-2.35,5.53-2.28,6.67-2.63,6.34-1.95,12.1-.68,14.32-.16,3.23.75,8.17,1.9,12.01,5.83,2.83,2.9,4.05,8.84,6.37,20.6.25,1.25.99,6.23,2.49,16.2,1.25,8.35,1.36,9.31.67,10.72Z"
        />
        <path
          id="jointlinehighlight"
          class="gelenkspalte2212fill"
          style={{
            fill: props.colors.Gelenkspalte2212Fill,
            stroke: props.colors.Gelenkspalte2212Fill,
          }}
          d="M301.5,470.05c.71.77-8.42,7.31-7.03,14.07.68,3.29,4.04,7.56,4.64,8.32,1.89,2.4,3.35,3.5,3.25,5.34-.09,1.59-1.29,2.52-3.3,4.41-4.93,4.64-3.6,4.62-7.55,8.09-1.56,1.37-4.72,4.14-8.9,6.2-6.29,3.1-11.96,3.14-17.8,3.17-10.11.06-17.65-2.49-20.39-3.51-7.16-2.66-11.9-6.09-12.93-6.85-5.89-4.34-5.11-5.83-9.36-8.76-8.65-5.95-19.35-4.96-24.09-4.52-3.92.36-7.57,1.38-14.89,3.4-9.39,2.6-9.42,3.44-14.32,4.21-9.04,1.42-16.39-.29-18.29-.76-5.52-1.38-5.05-2.44-12.13-4.25-8.36-2.13-12.62-1.58-13.75-1.42-2.39.35-4.41,1-7.85,2.11-4.83,1.56-5.93,2.39-8.94,2.94-3,.55-5.51.38-8.49,0-10.15-1.31-13.02-3.49-22.85-5.26-8.99-1.62-14.3-1.18-15.98-1.01-2.74.28-5.39.55-8.7,1.62-.53.17-2.61.86-6.07,2.02-3.3,1.11-3.55,1.2-4.04,1.13-3.21-.44-6.28-6.39-5.67-11.65.74-6.42,7.1-12.96,12.74-11.73,3.25.7,5.18,3.74,5.93,5.12,11.89,7.57,22.05,9.29,28.04,9.71,9.44.65,17.95-1.28,26.24-3.17,11.46-2.61,13.58-4.69,23.11-5.46,4.62-.37,4.15.12,16.97-.19,8.11-.19,12.27-.3,17.28-.89,5.44-.64,10.42-1.22,16.72-3.24,3.32-1.06,6.82-2.46,12.13-4.58,6.93-2.77,8.58-3.67,11.6-3.51,3.81.21,6.54,1.96,9.71,4.04,5.61,3.69,6.81,6.54,11.6,11.06,5.11,4.82,9.89,7.37,13.75,9.44,5.31,2.83,11.92,6.28,21.04,6.98,5.69.44,8.54.66,11.56-.84,7.5-3.73,5.44-12.1,14.33-22.59,5.74-6.78,12.14-9.82,12.69-9.22Z"
        />
      </g>
    </svg>
  );
}
