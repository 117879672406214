import React, { useState, useMemo, useEffect } from "react";
import { Button, Table, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../Redux/mappers/pricelist/pricelistMappers";
import { useTranslation } from "react-i18next";
import PricesTable from "./Components/PricelistTable";
import EditableCell from "./Components/EditableCell";
import {
  loadFacilties,
  loadPricelist,
  loadPrices,
  strArrToInt,
} from "../../Utilities/PriceLists/PriceListsUtils";

const variant_map = {
  en: "ln_designation_en",
  pl: "ln_designation_pl",
  de: "ln_designation_de",
  EN: "ln_designation_en",
  PL: "ln_designation_pl",
  DE: "ln_designation_de",
};

function ChangePrices(props) {
  const { t, i18n } = useTranslation(["priceList", "common"]);
  const [facility, setFacility] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [foreignFacPrices, setForeignFacPrices] = useState([]);
  const [currentFacPrices, setCurrentFacPrices] = useState([]);
  const [defaultCells, setDefaultCells] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const fetchIdRef = React.useRef(0);
  const noDataInfo = [
    {
      entry: 0,
      procedure: "Data available",
      price: <input type="number" defaultValue="0" />,
    },
  ];

  const header = useMemo(() => [
    {
      Header: t("common:entry"),
      accessor: "entry",
    },
    {
      Header: t("common:procedure"),
      accessor: variant_map[window.conf.LANG],
    },
    {
      Header: t("price"),
      accessor: "price",
      Cell: EditableCell,
    },
    {
      Header: "Defautl Price",
      accessor: "defprice",
      Cell: EditableCell,
    },
  ]);

  const getPricesFromDB = (facId) => {
    if (!props.pricelists.entirePriceInfoLoaded) {
      loadPricelist(
        props.keycloak,
        Object.keys(props.pricelists.facility),
        0,
        0,
        "",
        ""
      ).then((rdata) => {
        setCurrentFacPrices(rdata);
      });
    }
    loadPrices(props.keycloak, facId).then((rdata) => {
      setForeignFacPrices(rdata);
    });
  };

  const getFacilityNames = (facilities) => {
    let result = "";
    for (const [id, name] of Object.entries(facilities)) {
      result += name.name + ", ";
    }
    result = result.slice(0, -2);
    return result;
  };

  // load all the prices from a different facility
  useEffect(() => {
    if (
      foreignFacPrices.length != 0 &&
      currentFacPrices.pricelist != undefined
    ) {
      let tempPriceChanges = {};
      let curPriceList = currentFacPrices.pricelist;
      for (let i = 0; i < curPriceList.length; i++) {
        for (let j = 0; j < foreignFacPrices.length; j++) {
          if (
            foreignFacPrices[j].jw_procedure_id ==
            curPriceList[i].jw_procedure_id
          ) {
            tempPriceChanges[curPriceList[i].jw_procedure_id] =
              foreignFacPrices[j].price;
          }
        }
        props.addPriceInfo({
          id: curPriceList[i].jw_procedure_id,
          name: curPriceList[i][variant_map[i18n.language]],
          price: curPriceList[i].price,
          facility: curPriceList[i].a_id,
          entirePriceInfo: true,
        });
      }
      let copy = [...tableData];
      addCurrentPrices(copy, tempPriceChanges, props.pricelists.defaultPrices);
      setTableData(copy);
      props.replaceNewPrices(tempPriceChanges);
    }
  }, [foreignFacPrices, currentFacPrices]);

  const addCurrentPrices = (pricelist, updateData, defaultPrices) => {
    let defaultCells = [];
    for (let i = 0; i < pricelist.length; i++) {
      if (updateData.hasOwnProperty(pricelist[i].jw_procedure_id)) {
        pricelist[i].price = updateData[pricelist[i].jw_procedure_id];
      } else if (pricelist[i].price == 0) {
        for (let elem of defaultPrices) {
          if (elem.jw_procedure_id == pricelist[i].jw_procedure_id) {
            defaultCells.push(elem.jw_procedure_id);
            pricelist[i].price = elem.price;
            break;
          }
        }
      }
    }
    setDefaultCells(defaultCells);
  };

  //load all the facilities once
  useEffect(() => {
    loadFacilties(props.keycloak).then((rdata) => {
      let facilities = [];
      for (let i = 0; i < rdata.FacilitiesList.total_count; i++) {
        if (
          !Object.keys(props.pricelists.facility).find((element) => {
            return parseInt(element) === rdata.FacilitiesList.facilities[i].id;
          })
        )
          facilities.push([
            rdata.FacilitiesList.facilities[i].id,
            rdata.FacilitiesList.facilities[i].name,
          ]);
      }
      setFacility(facilities);
    });
  }, []);

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [tableData]);

  const addNewPrice = (rowIndex, columnId, value, rowId) => {
    setSkipPageReset(true);
    props.addNewPrice({
      id: rowId,
      newPrice: value,
    });
    setTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const loadData = React.useCallback(
    ({ pageSize, pageIndex, sortBy }, filerValue) => {
      //apply global filter to request only if search phrase is set
      const buildFilter = () => {
        const filter = {
          or: [
            {
              field: "a_id",
              op: "in",
              value: strArrToInt(Object.keys(props.pricelists.facility)),
            },
          ],
        };
        if (filerValue !== "") {
          //setPageIndex to 0 here only if filtering is active to see 1st page before loading data
          setPageIndex(0);
          const searchValue = `%${filerValue}%`;
          filter.or.push({
            field: variant_map[i18n.language],
            op: "like",
            value: searchValue,
          });
        }
        return `&search=${encodeURIComponent(JSON.stringify(filter))}`;
      };

      // returns sorting query
      const buildSorting = () => {
        if (sortBy.length > 0) {
          setPageIndex(0);
          return `&sort=${encodeURIComponent(
            JSON.stringify({
              field: sortBy[0].id,
              direction: sortBy[0].desc === true ? "desc" : "asc",
            })
          )}`;
        } else {
          return "";
        }
      };

      const getPriceInfo = (pricelist) => {
        for (let i = 0; i < pricelist.length; i++) {
          props.addPriceInfo({
            id: pricelist[i].jw_procedure_id,
            name: pricelist[i][variant_map[i18n.language]],
            price: pricelist[i].price,
            facility: pricelist[i].a_id,
            entirePriceInfo: false,
          });
        }
      };

      const setRowNumbering = (pricelist, currIndex) => {
        let index = currIndex * 10 + 1;
        for (let i = 0; i < pricelist.length; i++) {
          pricelist[i].entry = index++;
          if (isNaN(pricelist[i].price))
            pricelist[i].price = pricelist[i].price[0];
        }
      };

      const fetchId = ++fetchIdRef.current;
      //set loading state in table
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        setPageIndex(pageIndex);
        loadPricelist(
          props.keycloak,
          Object.keys(props.pricelists.facility),
          pageSize,
          pageIndex + 1,
          buildFilter(),
          buildSorting()
        ).then((rdata) => {
          if (rdata.pricelist === null) {
            //no data in response so give table no data info
            setTableData(noDataInfo);
            //jump to first page
            setPageCount(1);
            setItemCount(0);
            //loading finished disable loading state
            setLoading(false);
          } else {
            //fill props with price information on all procedures in rdata
            getPriceInfo(rdata.pricelist);
            //add Prices to the current pricelist
            addCurrentPrices(
              rdata.pricelist,
              props.pricelists.newPrices,
              props.pricelists.defaultPrices
            );
            //set the numbering of the rows
            setRowNumbering(rdata.pricelist, pageIndex);
            //provide data to table
            setTableData(rdata.pricelist);
            //set pageCount
            setPageCount(Math.ceil(rdata.total_count / pageSize));
            setItemCount(rdata.total_count);
            //loading finished disable loading state
            setLoading(false);
          }
        });
      }
    }
  );

  return (
    <Row>
      <Table>
        <tbody>
          <tr>
            <th>
              <select id="facilityChoice" className="form-control">
                {facility.map((f) => (
                  <option key={f[0]} value={f[0]}>
                    {f[1]}
                  </option>
                ))}
              </select>
            </th>
            <th>
              <Button
                onClick={() => {
                  getPricesFromDB(
                    document.getElementById("facilityChoice").value
                  );
                }}
              >
                {t("changePricesView.loadFacility")}
              </Button>
            </th>
          </tr>
        </tbody>
      </Table>
      <PricesTable
        columns={header}
        data={tableData}
        fetchData={loadData}
        loading={loading}
        pageCount={pageCount}
        controlledPageIndex={pageIndex}
        idprefix="pr"
        skipPageReset={skipPageReset}
        itemCount={itemCount}
        idfield="jw_procedure_id"
        addNewPrice={addNewPrice}
        getCellProps={(cellInfo) => ({
          style: {
            backgroundColor:
              defaultCells.find(
                (element) => element === cellInfo.row.original.jw_procedure_id
              ) > 0
                ? cellInfo.column.id === "price"
                  ? "red"
                  : null
                : null,
          },
        })}
      />
    </Row>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePrices);
