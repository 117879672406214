import Controller from "../Common/Controller";

import FrontView from "./Views/FrontView";

import "./styles/Controller.scss";

function SpineController() {
  const availableViews = [FrontView];
  return <Controller availableViews={availableViews} />;
}

export default SpineController;
