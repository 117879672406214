import style from "./style.scss";

function RechtesKniegelenkTEPvdLiegendLinks2318(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326.78 625.33">
      <g id="underlay">
        <ellipse
          id="joint_gap_underlay"
          data-name="joint gap underlay"
          class="Gelenkspalt2318Fill"
          style={{
            fill: props.colors.Gelenkspalt2318Fill,
            stroke: props.colors.Gelenkspalt2318Fill,
          }}
          cx="168.7"
          cy="285.54"
          rx="62.64"
          ry="142.2"
          transform="translate(-133.75 421.6) rotate(-83.65)"
        />
      </g>
      <g id="TEP_Knee" data-name="TEP Knee">
        <g id="background_layer" data-name="background layer">
          <path
            id="background_lower_leg"
            data-name="background lower leg"
            class="Kniegelenk_vd_tep_right2318_cls2"
            d="M243.91,16.04h0c1.68,3.09.55,3.55,3,14,3.32,14.2,7.96,24.32,12,33,7.79,16.75,17.23,38.99,38.19,57.71,6.34,5.67,12.5,9.93,18.53,19.5,2.69,4.28,9.95,16.13,9.62,31.72-.05,2.47-.3,6.62-6.2,23.72-11.45,33.18-17.34,37.45-15.14,49.35,1.07,5.79,3.41,9.84,3.12,18.38-.14,4.18-.3,10.9-4.29,16.67-6.64,9.58-19.4,10.93-22.8,11.29-17.31,1.82-31.5-7.85-41.03-14.34-16.46-11.22-18.08-19.11-34.33-25.28-9.85-3.74-16.47-3.58-19.22-3.39-11.73.81-19.91,6.91-23.93,9.67-28.26,19.5-63.73,21.8-71.53,22.11-14.64.59-31.5,1.28-42-10.11-12.38-13.41-9.18-36.25-8.32-42.38,1.5-10.73,4.02-10.54,7.32-26.62,3.29-15.99,1.16-18.13,4-33,2.99-15.64,8.01-27.21,11-34,7.92-17.97,11.71-17.97,17.41-31.84,1.09-2.65,1.95-5.06,2.67-7.48,5.02-16.84,5.92-35.68,5.92-35.68,1.3-30.12,4.66-34.9,8-52,2.68-13.75,21.86-3.36,73-2,42.13,1.12,67.5-2.81,75,11h0Z"
          />
          <path
            id="background_upper_leg"
            data-name="background upper leg"
            class="Kniegelenk_vd_tep_right2318_cls2"
            d="M176.92,624.04c28.1-1.79,49.56-3.39,54-15,1.79-4.68-.78-6.82-2-19-1.34-13.46,1.76-17.34,2-32,.23-13.65-2-12.48-2-25,0-14.95,3.18-19.15,7-42,3.77-22.56.81-20.3,4-32,5.47-20.03,21-42,21-42,21.77-31.06,20.24-25.98,26-36,15.19-26.4,16-47,16-47,.43-9.57-1.08-16.08-3-21-2.66-6.82-5.73-9.68-6.58-10.43-4.71-4.18-10.2-5.08-13.42-5.57-25.78-3.9-41-5-41-5-8.79-.64-17.7-1.34-27-5-7.67-3.02-10.13-5.89-11-7-3.29-4.22-2.94-7.86-6-9-.78-.29-1.94-.28-10,3-5.92,2.41-6.81,3.92-10,4-3.72.09-4.61-1.9-10-4-3.41-1.33-9.59-3.74-15-2-5.18,1.67-4.58,5.53-12,12-.78.68-6.9,6-14,8-8.56,2.41-12.76-1.64-27-5-12.01-2.84-23.48-5.55-35-1-10.1,3.99-15.67,11.77-18,15-1.34,1.86-10.83,15.42-9,33,1.34,12.87,8.07,21.47,5,24-2.37,1.95-6.78-3.09-12-1-1.15.46-5.16,2.36-9,15-3.37,11.1-1.55,13.87-4,23-2.89,10.8-6.7,11.66-10,21-1.49,4.21-3.77,12.69-1,30,3.19,19.93,9.5,29.07,20,54,4.09,9.71,15.1,36.07,21,62,5.12,22.49,7.68,33.74,7,39-.27,2.06-.81,5.24,1,8,5.63,8.61,29.7,6.44,33-2,1.65-4.22-3.3-6.43-6-17-2.34-9.16.17-12.24,0-26-.18-14.68-3.06-13.36-3-26,.02-5.16.2-17.45,6-31,4.55-10.63,7.19-9.45,9-17,1.92-7.99-.54-11.34-3-30-1.25-9.47-2.83-21.49-1-22,2.08-.58,8.11,13.84,12,25,4.55,13.07,8.21,27.55,12,57,4,31.07,6,46.6,6,63,0,21.65-2.2,27.47,3,34,7.84,9.85,24,9.04,56,7h0Z"
          />
        </g>
        <g id="knee">
          <path
            id="tibia_outlines"
            data-name="tibia outlines"
            class="Kniegelenk_vd_tep_right2318_cls5"
            d="M230.92,611.04c-1.33-14.55-2.4-33.24-2-55,.22-12.09.62-30.35,4-54,3.41-23.83,5.42-37.85,13-56,8.27-19.79,19.15-34.86,23-40,9.73-12.98,11.6-11.94,18-22,13.87-21.79,14.86-41.99,15-46,.42-11.98.88-25.54-8-34-4.13-3.93-12.81-5.67-30-9-12.76-2.47-19.64-3.8-30-5-17.15-1.98-22.33-1.12-28-6-6.35-5.47-5.56-11.49-11-13-6.64-1.84-9.63,6.63-19,7-10.62.42-14.61-10.15-23-8-4.08,1.05-4.4,3.87-11,10-5.32,4.94-12.42,9.9-18,12-11.93,4.5-17.01-3.99-38-6-9.1-.87-25.63-2.45-37,7-1.9,1.58-9.19,8.07-12,25-6.33,38.18,19.07,75.52,33,96,12.78,18.79,20.4,24.78,28,44,4.77,12.05,6.92,22.72,10,38,2.82,13.98,4.87,27.64,7,48,1.94,18.52,3.97,42.9,5,72"
          />
          <path
            id="femur_outlines"
            data-name="femur outlines"
            class="Kniegelenk_vd_tep_right2318_cls5"
            d="M243.92,16.04c3.65,21.38,10.08,37.02,15,47,7.76,15.73,27.37,49.02,50,70,2.15,1.99,7.12,6.47,11,14,6.54,12.7,5.46,25.35,5,30-.89,9.09-2.99,10.13-11,32-6.52,17.79-9.77,26.68-10,33-.59,16.63,6.75,22.54,2,33-4.16,9.17-13.61,13.02-16,14-18.55,7.57-38.89-4.4-45-8-12.7-7.48-11.92-12.02-31-24-10.34-6.49-16.33-8.77-23-9-8.48-.29-14.54,2.86-21,6-16.96,8.24-35.79,15.92-49,20-10.08,3.11-23.52,7.13-41,6-12.2-.79-23.68-1.53-32-10-10.3-10.49-9.49-26.44-9-36,.72-14.06,4.7-14.15,8-33,3.56-20.34-.39-24.21,4-41,1.9-7.27,4.93-13.51,11-26,13.19-27.14,18.79-30.36,23-46,2.4-8.9,1.32-10.61,3-29,1.15-12.63,3.35-30.44,8-52"
          />
          <path
            id="fibula_outlines"
            data-name="fibula outlines"
            class="Kniegelenk_vd_tep_right2318_cls5"
            d="M51.92,609.04c-2.13-20.9-5.6-38.79-9-53-5.1-21.3-8.56-35.72-17-54-8.67-18.78-15.51-26.24-21-47-4.41-16.67-3.46-24.92-3-28,1.89-12.62,6.65-12.62,11-30,2.82-11.26,2.13-16.5,7-27,3.18-6.86,5.4-11.48,10-13,1.54-.51,5.84-1.5,24,11,10.33,7.11,15.5,10.67,20,16,8.27,9.8,11.22,19.78,13,26,5.49,19.23-.9,21.73,3,50,1.7,12.31,3.73,17.79,2,28-1.58,9.3-4.74,13.41-8,21-8.01,18.63-7.05,35.93-6,55,.56,10.12,2.22,24,7,40"
          />
          <path
            id="tibia_8"
            data-name="tibia 8"
            class="Kniegelenk_vd_tep_right2318_cls5"
            d="M205.92,284.04c.3,17.67,37.08,29.36,82,22"
          />
          <path
            id="tibia_6"
            data-name="tibia 6"
            class="Kniegelenk_vd_tep_right2318_cls5"
            d="M123.92,292.04c-.42,15.62-36.69,23.37-80.74,7.89"
          />
          <path
            id="fibula_4"
            data-name="fibula 4"
            class="Kniegelenk_vd_tep_right2318_cls7"
            d="M81.18,516.43c0-31.17,3.91-56.39,8.74-56.39"
          />
          <path
            id="fibula_3"
            data-name="fibula 3"
            class="Kniegelenk_vd_tep_right2318_cls7"
            d="M12.92,420.04c11.17,27.82,8.47,49.35,7,49"
          />
          <path
            id="fibula_2"
            data-name="fibula 2"
            class="Kniegelenk_vd_tep_right2318_cls7"
            d="M81.18,516.43c-20,31.34-12.06,77.81-7.26,84.61"
          />
          <path
            id="fibula_1"
            data-name="fibula 1"
            class="Kniegelenk_vd_tep_right2318_cls7"
            d="M38.67,529.04c15.21,40.41,25.54,76.66,27.25,80"
          />
          <path
            id="tibia_5"
            data-name="tibia 5"
            class="Kniegelenk_vd_tep_right2318_cls7"
            d="M216.92,616.04c0-93.96,12.97-170,29-170"
          />
          <path
            id="tibia_4"
            data-name="tibia 4"
            class="Kniegelenk_vd_tep_right2318_cls7"
            d="M49.41,380.3c8.76-22.03-.24-48.41-13.41-53.29"
          />
          <path
            id="tibia_3"
            data-name="tibia 3"
            class="Kniegelenk_vd_tep_right2318_cls7"
            d="M212.92,353.65c8.45-6.63,33.09-4.91,60,0"
          />
          <path
            id="tibia_2"
            data-name="tibia 2"
            class="Kniegelenk_vd_tep_right2318_cls7"
            d="M65.92,345.04c19.63,8.61,118.5-18.03,118.5-4"
          />
          <path
            id="tibia_1"
            data-name="tibia 1"
            class="Kniegelenk_vd_tep_right2318_cls7"
            d="M128.92,296.16c23.06,20.88,72.23-18.12,77-12.12"
          />
          <polyline
            id="fermur_2"
            data-name="fermur 2"
            class="Kniegelenk_vd_tep_right2318_cls7"
            points="156.92 191.04 169.01 187.04 184.42 182.04 205.92 186.54 224.92 182.04 224.92 191.04 224.92 213.21 231.42 227.04 221.92 241.48 219.68 260.84"
          />
          <path
            id="fermur_1"
            data-name="fermur 1"
            class="Kniegelenk_vd_tep_right2318_cls7"
            d="M235.92,7.04c0,50.49,19.68,91.35,44,91.35"
          />
        </g>
        <g id="TEP">
          <path
            id="TEP_lower_leg"
            data-name="TEP lower leg"
            class="Kniegelenk_vd_tep_right2318_cls6"
            d="M16.46,290c-4.83,4.23-6.25,12.93-3.06,18.37,2.08,3.57,7.03,4.98,16.84,7.66,7.64,2.08,15.19,4.1,26.03,4.59,12.31.56,12.07-1.61,16.84,0,9.29,3.13,8.69,10.84,21.44,24.5,8.99,9.63,11.17,7.8,18.37,16.84,2.61,3.28,8.82,11.22,12.25,22.97,3.1,10.61.21,11.6,3.06,26.03,1.16,5.85,3.57,15.51,9.19,26.03,4.61,8.63,6.96,13.09,12.25,15.31,7.76,3.26,17.21.39,22.97-4.59,5.99-5.18,6.49-11.35,10.72-35.22,3.33-18.79,5-28.19,6.12-30.62,4.39-9.49,8.33-10.13,33.69-33.69,18.41-17.1,18.03-18.33,22.97-19.9,13.75-4.36,21.43,3.74,41.34-1.53,10.48-2.77,13.74-6.44,15.31-9.19,2.9-5.08,2.89-12.15,0-16.84-4.88-7.9-17.03-7.54-36.75-7.66-52.22-.31-43.75-3.28-68.9-1.53-26.37,1.83-30.01,4.7-47.47,3.06-13.11-1.22-12.7-2.99-35.22-6.12-18.33-2.54-29.37-4.08-44.4-3.06-14.72.99-14.79,3.19-27.56,3.06-14.36-.15-20.86-2.99-26.03,1.53h0Z"
          />
          <path
            id="TEP_upper_leg"
            data-name="TEP upper leg"
            class="Kniegelenk_vd_tep_right2318_cls6"
            d="M107,65.62c-9.87,3.58-14.3,14.27-19.64,27.19-3.08,7.45-7.69,18.84-9.06,34.74-1.48,17.18,2.24,23.04-3.02,33.23-4.55,8.83-8.55,6.75-13.59,15.1-6.45,10.68-1.14,16.15-3.02,48.33-1.22,21-1.51,24.17-1.51,24.17-1.22,13.54-2.14,16.65,0,21.15,4.35,9.14,15.69,12.14,21.15,13.59,1.88.5,11.2,2.83,22.66,0,5.36-1.32,9.11-3.4,16.61-7.55,11.14-6.16,16.7-9.23,19.64-13.59,6.03-8.93,5.08-20.45,4.53-27.19-.9-10.94-4.2-15.55-1.51-18.12,2.89-2.75,7.87,1.42,21.15,3.02,9.26,1.11,17.38.35,21.15,0,13.25-1.24,16.67-4.03,19.64-1.51,3.3,2.8,1.42,8.21,0,22.66-1.55,15.78-2.32,23.67,0,28.7,5.66,12.26,22.01,15.94,31.72,18.12,4.87,1.1,15.59,3.38,28.7,0,6.88-1.77,15.49-3.98,21.15-10.57,9.07-10.55,1.15-21.31,0-58.91-.68-22.3,1.76-29.5-4.53-40.78-1.95-3.48-9.35-16.75-21.15-18.12-8.22-.96-13.29,4.49-18.12,1.51-4.36-2.68-3.76-9.27-4.53-16.61-1.33-12.66-7.43-23.04-19.64-43.8-8.66-14.72-13.15-22.15-21.15-25.68-6.48-2.86-8.12-.7-24.17-1.51-9.62-.49-21.01-2.34-43.8-6.04-14.01-2.28-15.94-2.85-19.64-1.51l-.02-.02Z"
          />
        </g>
      </g>
      <g id="overlay" class="Kniegelenk_vd_tep_right2318_cls4">
        <g id="TEP_overlays" data-name="TEP overlays">
          <path
            id="TEP_lower_leg_overlay"
            data-name="TEP lower leg overlay"
            class="TEP2318Fill"
            style={{
              fill: props.colors.TEP2318Fill,
              stroke: props.colors.TEP2318Fill,
            }}
            d="M16.46,290c-4.83,4.23-6.25,12.93-3.06,18.37,2.08,3.57,7.03,4.98,16.84,7.66,7.64,2.08,15.19,4.1,26.03,4.59,12.31.56,12.07-1.61,16.84,0,9.29,3.13,8.69,10.84,21.44,24.5,8.99,9.63,11.17,7.8,18.37,16.84,2.61,3.28,8.82,11.22,12.25,22.97,3.1,10.61.21,11.6,3.06,26.03,1.16,5.85,3.57,15.51,9.19,26.03,4.61,8.63,6.96,13.09,12.25,15.31,7.76,3.26,17.21.39,22.97-4.59,5.99-5.18,6.49-11.35,10.72-35.22,3.33-18.79,5-28.19,6.12-30.62,4.39-9.49,8.33-10.13,33.69-33.69,18.41-17.1,18.03-18.33,22.97-19.9,13.75-4.36,21.43,3.74,41.34-1.53,10.48-2.77,13.74-6.44,15.31-9.19,2.9-5.08,2.89-12.15,0-16.84-4.88-7.9-17.03-7.54-36.75-7.66-52.22-.31-43.75-3.28-68.9-1.53-26.37,1.83-30.01,4.7-47.47,3.06-13.11-1.22-12.7-2.99-35.22-6.12-18.33-2.54-29.37-4.08-44.4-3.06-14.72.99-14.79,3.19-27.56,3.06-14.36-.15-20.86-2.99-26.03,1.53h0Z"
          />
          <path
            id="TEP_upper_leg_overlay"
            data-name="TEP upper leg overlay"
            class="TEP2318Fill"
            style={{
              fill: props.colors.TEP2318Fill,
              stroke: props.colors.TEP2318Fill,
            }}
            d="M107,65.62c-9.87,3.58-14.3,14.27-19.64,27.19-3.08,7.45-7.69,18.84-9.06,34.74-1.48,17.18,2.24,23.04-3.02,33.23-4.55,8.83-8.55,6.75-13.59,15.1-6.45,10.68-1.14,16.15-3.02,48.33-1.22,21-1.51,24.17-1.51,24.17-1.22,13.54-2.14,16.65,0,21.15,4.35,9.14,15.69,12.14,21.15,13.59,1.88.5,11.2,2.83,22.66,0,5.36-1.32,9.11-3.4,16.61-7.55,11.14-6.16,16.7-9.23,19.64-13.59,6.03-8.93,5.08-20.45,4.53-27.19-.9-10.94-4.2-15.55-1.51-18.12,2.89-2.75,7.87,1.42,21.15,3.02,9.26,1.11,17.38.35,21.15,0,13.25-1.24,16.67-4.03,19.64-1.51,3.3,2.8,1.42,8.21,0,22.66-1.55,15.78-2.32,23.67,0,28.7,5.66,12.26,22.01,15.94,31.72,18.12,4.87,1.1,15.59,3.38,28.7,0,6.88-1.77,15.49-3.98,21.15-10.57,9.07-10.55,1.15-21.31,0-58.91-.68-22.3,1.76-29.5-4.53-40.78-1.95-3.48-9.35-16.75-21.15-18.12-8.22-.96-13.29,4.49-18.12,1.51-4.36-2.68-3.76-9.27-4.53-16.61-1.33-12.66-7.43-23.04-19.64-43.8-8.66-14.72-13.15-22.15-21.15-25.68-6.48-2.86-8.12-.7-24.17-1.51-9.62-.49-21.01-2.34-43.8-6.04-14.01-2.28-15.94-2.85-19.64-1.51l-.02-.02Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default RechtesKniegelenkTEPvdLiegendLinks2318;
