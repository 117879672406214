import style from "./style.scss";

function LinkerFussLatStehend1445(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.54 213.81">
 
 <g id="foot_overview" data-name="foot overview">
   <path id="foot_1" data-name="foot 1" class="linker_fuss_lat_stehend1445_cls4" d="M1.75,150.95c-3.4-16.8,1.7-26,17.4-25.8l40.7-18.9c1.4-.7,2.7-1.6,3.6-2.9,8.8-11,17.9-11.3,27.2-4.4.9.6,1.9.9,3,.9,11.1-.9,20.1,4.5,27.9,13.2,14.3-6.1,25.6-4.8,32.2,7.8,6.7,4.4,2.8,9.7-8.4,15.7l-.5,24.4c0,2.1-1.6,3.8-3.6,4.1-1.7.3-3.8.3-6.1.3-2.8,9.5-27.8,16-58.8,21.8-12.2,2.3-19.1,6.7-21.4,12.8-.7,2-2.4,3.5-4.5,4.2-17.9,5.6-33,3-43-13.3-1.8-3-2.9-6.3-3.2-9.8l-2-25.9c0-1.5-.2-2.8-.5-4.2h0Z"/>
   <path id="foot_2" data-name="foot 2" class="linker_fuss_lat_stehend1445_cls4" d="M79.75,58.35c-1.5,2-4,4.1-7.1,6.3-3.2,2.8-5.4,6.6-6.8,11.4.6,2.9-1.9,6.2-6,9.8h0c-7.3,8-7.6,14.3,2.6,18.3,6.8,3.3,12.8,4.9,17.6,4.5,3.5-1,6.4-2.8,8.6-5.6,1.2-1.6,3.6-1.5,4.7.1,9.8,14.1,19.1,15.8,28.1,8.8,11,14.1,21.2,21.2,30.2,18.9,7,.1,13-2.1,17.9-6.4,7-7,11-13.8,11.5-20.6.7-10.8-2-17.7-8.7-20.3-6.8-.9-13-3.4-18.2-7.9l-15.3-11.6"/>
   <path id="foot_3" data-name="foot 3" class="linker_fuss_lat_stehend1445_cls3" d="M80.75,2.05c-6.9,20.7-10,36.5-3.6,49.3,1.4,5.7,9.5,10.2,18.6,14.4,21.4,15.8,36,17.8,41.9,2.2l7.5-8.8c13-15.7,15.5-35.5,10.3-58.4"/>
   <path id="foot_4" data-name="foot 4" class="linker_fuss_lat_stehend1445_cls2" d="M79.75,58.75c-1.5,2-4,4.1-7.1,6.3-3.2,2.8-5.4,6.7-6.8,11.4.6,2.9-1.9,6.2-6,9.8h0c-7.3,8-7.6,14.3,2.6,18.3,6.8,3.3,12.8,4.9,17.6,4.5,3.5-1,6.4-2.8,8.6-5.6,1.2-1.6,3.6-1.5,4.7.1,9.8,14.1,19.1,15.8,28.1,8.8,11,14.1,21.2,21.2,30.2,18.9,7,.1,13-2.1,17.9-6.4,7-7,11-13.8,11.5-20.6.7-10.8-2-17.7-8.7-20.3-6.8-.9-13-3.4-18.2-7.9l-15.3-11.6"/>
   <path id="foot_5" data-name="foot 5" class="linker_fuss_lat_stehend1445_cls2" d="M139.15,64.55c-12.9-10.1-25.3-14.7-36.9-13.1l-22.4,7.3"/>
   <path id="foot_6" data-name="foot 6" class="linker_fuss_lat_stehend1445_cls2" d="M1.75,150.35c-3.4-16.8,1.7-26,17.4-25.8l40.7-18.9c1.4-.7,2.7-1.6,3.6-2.9,8.8-11,17.9-11.3,27.2-4.4.9.6,1.9.9,3,.9,11.1-.9,20.1,4.5,27.9,13.2,14.3-6.1,25.6-4.8,32.2,7.8,6.7,4.4,2.8,9.7-8.4,15.7l-.5,24.4c0,2.1-1.6,3.8-3.6,4.1-1.7.3-3.8.3-6.1.3-2.8,9.5-27.8,16-58.8,21.8-12.2,2.3-19.1,6.7-21.4,12.8-.7,2-2.4,3.5-4.5,4.2-17.9,5.6-33,3-43-13.3-1.8-3-2.9-6.3-3.2-9.8l-2-25.9c0-1.5-.2-2.9-.5-4.2h0Z"/>
   <path id="foot_7" data-name="foot 7" class="linker_fuss_lat_stehend1445_cls3" d="M149.95,160.15c-4.8,9.5-.9,15.6,13.3,17.5,15.8.3,27.5-3.1,33.7-11.3,3.2-8.9,4.1-17.4-5-24.6-17.4-8.1-31.8-10.6-41.5-5l-.5,23.4Z"/>
   <path id="foot_8" data-name="foot 8" class="linker_fuss_lat_stehend1445_cls3" d="M176.55,158.15c6.5,1.8,8.9-.3,8.8-5.1,18.1-13.2,26.7-29.5,25-48.9-2.1.3-3.6-.4-3.7-3.2-5.7-1.4-10.3-3.4-12.3-7.2-3.2-.1-6.3-.8-9.6-2.8-.7-.4-1.5.2-1.3,1,2.9,10.4.9,18.2-3.7,26.6-3.9,6.5-10.9,12.2-19.8,16.2-4.5,4.5-4.5,9.7-.7,15.6,3.8,4.6,9.4,7.3,17.3,7.8h0Z"/>
   <path id="foot_9" data-name="foot 9" class="linker_fuss_lat_stehend1445_cls3" d="M195.65,147.05l.9,16.1c6.8,3.3,13.3,5.3,19.2,5.2,9.4,6.2,18,9.8,24.7,7.6,4.8-3.1,7.5-9.5,8.2-18.7,11.9-19.2,21.2-36.9,11.1-43.6l-13.4-7.1-28.9-2.2c-2.4-.2-4.4,2-4,4.4,2.7,16.6-2.9,29.5-17.8,38.3h0Z"/>
   <path id="foot_10" data-name="foot 10" class="linker_fuss_lat_stehend1445_cls3" d="M251.25,161.85c-4.3,7.1-4.4,11.4-.2,12.7,1.7.5,3.6.3,5.3-.4,15.2-6.5,30.7-10,46.5-7.4,20.4,1.9,35.6,7,45.2,15.6,1.5,1.3,3.3,2.4,5.2,2.9,13.8,3.5,24,2.1,26.9-9.4,1.7-7.6-.8-14-9.8-18.6-.8-1.5-2.7-1.8-5.5-1-1.4.3-2.9.3-4.3-.1-27.4-8.2-53.4-17.9-75.9-30.8-11.6-10.3-17.7-10.5-18.3-.6-.1,2-.5,4-1.1,5.9-2.9,10.1-7.4,20.5-14,31.2h0Z"/>
   <path id="foot_11" data-name="foot 11" class="linker_fuss_lat_stehend1445_cls3" d="M380.55,186.45c-2.6,4.8-.4,7.1,5.9,7.1,3,0,6-.8,8.7-2.1,12.9-6.4,27.8-7.9,41.7-3.3,2.3.8,4.3,2.1,6,3.8,3.2,3.1,6.1,1.1,8.7-5.9.8-2.1.8-4.4-.2-6.4-1.9-4.1-5.3-5.8-10.8-4.1-12-1.4-25.9-5.3-41-10.9-8.3-3.4-15.1-5.3-14,.8,1.1,9.7-1.4,16-5,21h0Z"/>
   <path id="foot_12" data-name="foot 12" class="linker_fuss_lat_stehend1445_cls2" d="M380.85,191.95c3.7-3.3,6.6-7.1,7.8-12.1,1.1-6.8.2-12.3-2.4-16.8"/>
   <path id="foot_13" data-name="foot 13" class="linker_fuss_lat_stehend1445_cls3" d="M452.65,190.25c-5.6,6.5-3.1,8.3,4.2,7.3l24.6-3.6c3.4-.2,4.8-1.2,4.2-3-.6-1.8-2.4-2.8-4.3-2.7-8.1.4-16.1-1.3-23.9-6.2-.9-.5-2,.1-2,1.1,0,3.6-1.1,5.8-2.8,7.1Z"/>
   <path id="foot_14" data-name="foot 14" class="linker_fuss_lat_stehend1445_cls1" d="M141.35,63.75c-4.7-6-9.9-11.4-17.5-14.1-11.3-4.6-21.8-5.5-31.2-1.3-5.5,3.1-10.7,3.6-15.7,1.7"/>
   <path id="foot_15" data-name="foot 15" class="linker_fuss_lat_stehend1445_cls3" d="M115.35,2.35c-2.9,10.9-1.5,22.2,2.7,33.9,3.2,4,1,9.1-6.1,15.3-3.1,7.5-8.2,11-15.9,9.5-1.3-.3-2.5-1-3.4-1.9l-11-11.4c-6.9-6.6-9.3-13.5-2.7-21.4,8.5-8.6,14.8-17.4,16.6-26.2"/>
   <g id="foot_transperent_parts" data-name="foot transperent parts" class="linker_fuss_lat_stehend1445_cls6">
     <path id="foot_transperent_parts1" data-name="foot transperent parts1" class="linker_fuss_lat_stehend1445_cls5" d="M211.05,127.65c11.8.4,19.6,4.5,22,13.7-.3,6.9-5.6,12-14.3,15.8-7,4.2-14.6,4.3-22.8.7"/>
     <path id="foot_transperent_parts2" data-name="foot transperent parts2" class="linker_fuss_lat_stehend1445_cls5" d="M191.05,127.65c-.6-11.7,6.8-16.4,17.8-17.9l23.5-2.7c12.7,1.5,13.9,8,10,16.8-7.2,10.3-15.8,19.1-25.7,26.4-16,4.4-24.7-2.9-25.6-22.6h0Z"/>
     <path id="foot_transperent_parts3" data-name="foot transperent parts3" class="linker_fuss_lat_stehend1445_cls5" d="M246.05,129.85c.3-10.9,1.4-20.3,10.1-15,14.8,12.9,32.7,21.8,51.6,29.4l54.4,22c15,6.3,18.2,15.1,14.3,25.3-2.7,6.6-8.7,10.4-19.3,9.8"/>
     <path id="foot_transperent_parts4" data-name="foot transperent parts4" class="linker_fuss_lat_stehend1445_cls5" d="M341.25,199.15c-.1,7.1,5.4,8.4,14.1,6.5,13.5-3.8,23.2-8.5,17.3-16.5-3.9-8.1-28.7-17.4-55.2-26.7-21.5-7.3-42.3-16.5-61.7-29.5-10.5-12.5-15.4-10.3-16.2,2.6l-2.6,26"/>
     <path id="foot_transperent_parts5" data-name="foot transperent parts5" class="linker_fuss_lat_stehend1445_cls5" d="M232.25,171.55c-3.1-12.1-4.1-22.3,5.8-23.9l53.2,20c30.2,8.3,61.1,16.5,58.8,25.7-1.8,14.4-10.6,18.6-27.4,11l-8.6-9.8"/>
     <path id="foot_transperent_parts6" data-name="foot transperent parts6" class="linker_fuss_lat_stehend1445_cls5" d="M182.65,196.05c-13-3.4-12.9-8.8-4-15.6,6.5-12.5,16.8-19.8,33-19.4,41.8,7.9,81.9,16.4,105.7,31.1,4.7,8.2,2.2,13.5-3.5,17.6-11.5,4.7-19.8,5.3-22-1.6-17-14.3-42.9-17.4-72.2-16.2-12.9,3.9-25.5,6.2-37,4.1h0Z"/>
     <path id="foot_transperent_parts7" data-name="foot transperent parts7" class="linker_fuss_lat_stehend1445_cls5" d="M330.45,183.75c-10-1.7-12.5,4.3-12.7,12.9,4,9.9,9.1,11.5,15.2,6.4,6.6-4.8,14.9-7.1,24.5-7.2,5.8-1.5,8.3-4.2,6.2-8.6-1.1-2.3-3.4-3.7-6-3.7l-27.2.2h0Z"/>
     <path id="foot_transperent_parts8" data-name="foot transperent parts8" class="linker_fuss_lat_stehend1445_cls5" d="M394.25,180.35c-16.2-1.7-20.6,1.3-16.3,7.7-1.7,10.1.2,13.9,6.9,9.6,12.8-5.5,26-8.2,40-6.4,6-1.2,8.6-3.2,7.9-5.9-.5-1.8-2.2-3.1-4.1-3.2l-34.4-1.8h0Z"/>
     <path id="foot_transperent_parts9" data-name="foot transperent parts9" class="linker_fuss_lat_stehend1445_cls5" d="M434.55,189.85c-4,1.8.4,3.5,6.1,5.1,4.9,4.2,8.7,5.3,10.1.4-.8-1.9-1.7-3.9-4.5-6.8-5.6-4.7-10-7.2-10.8-2.6l-.9,3.9h0Z"/>
     <path id="foot_transperent_parts10" data-name="foot transperent parts10" class="linker_fuss_lat_stehend1445_cls5" d="M395.85,199.45l19.4.3c.2-.1.3-.1.5-.2,7.4-2.7,4.9-13.8-3-12.9-1.4.1-2.9.4-4.5.8-3.2.8-6.5,1.5-9.8,1.9-5.5.8-7.7,2.4-8.5,4.3h0c.2,3.2,2.7,5.7,5.9,5.8h0Z"/>
     <path id="foot_transperent_parts12" data-name="foot transperent parts12" class="linker_fuss_lat_stehend1445_cls5" d="M427.45,195.25c-4.1-5.8-6.5-7.1-5.9,0-7.9,6.4-3.6,8.3,1.3,10.1,12,1.8,16.9.9,13.4-3.1l-8.8-7h0Z"/>
     <path id="foot_transperent_parts13" data-name="foot transperent parts13" class="linker_fuss_lat_stehend1445_cls5" d="M466.95,202.75c-.7,1.9-2.9,2.8-6.1,2.9-5.7-.7-10-2.7-12.9-5.9,3.6-.8,4.4-3.1,3.1-6.3,4.9-.7,9.8,1.2,14.8,5.5,1.1.9,1.6,2.5,1.1,3.8h0Z"/>
   </g>
 </g>
 <g id="overlays">
   <path id="from_calcaneus_to_phalangen_overlay" data-name="from calcaneus to phalangen overlay" class="fullfoot1445fill" style= {{
             fill: props.colors.fullfoot1445fill,
             stroke: props.colors.fullfoot1445fill,
             opacity:props.colors.fullfoot1445fill,
           }} d="M65.85,76.05c2.3-8.14,7.81-11.64,13.9-17.7l17.52-5.16c10.08-2.34,18.46-2.29,24.18,1.51,12.18,5.21,23.79,13.25,35.03,23.18,4.94,3.21,10.21,5.28,15.87,6.07l11.1,8c.86-2.18,4.95.74,10.32,1.77,2.96,3.7,7.26,6.11,12.88,7.23.66,1.79,1.78,3.01,3.7,3.2l7.1.2,28.9,2.2c8.97,4.75,18.77,8.48,17.64,14.56h2.96c2.31-5.27,7.1-4.76,15.22,3.53,25.65,13.31,51.75,23.96,78.37,31.51,3.84,1.22,8.67-2.42,9.8,1.1,11.13,6.53,12.69,14.68,7.35,24.02l6.4-1.73,1.44-14.09c-.36-3.29.82-5.25,5.72-3.97,18.49,6.97,36.28,13.12,49.28,13.73,7.17-1.03,11.63,1.04,11.58,8.31-.69,3.34-1.85,6.03-3.47,8.09l2.39,1.84c-.3-2.75,3.38-3.81,3.97-7.2-.54-4.74,1.05-5.87,4.95-3.09,7.61,3.75,14.66,5.8,23.21,5.01,3.27,1.72,3.39,3.52,1.02,5.38l-20.21,3.91c2.76,1.59,3.45,3.38,2.95,5.29-5.7,5.46-12.18,2.79-19-3.34-7.52-6.34-18.25-5.9-13.54-11.22.1-.11-.03-.27-.16-.2-3.48,1.88-6.73,3.17-9.41,2.92,1.77,4.07,6.31,8,12.51,11.84,2.04,4.67-5.33,4.15-17.19,1.17l-4.92-4.17c-14.97.57-25.46-.2-24.91-4.27-12.44,7.56-15.19,3.42-12.39-7.43-2.85,7.17-6.43,11.59-10.71,13.36-7.54,3.69-14.71,5.24-21.57,5.03-5.85,2.5-12.77,2.08-20.59-.79l-5.36-4.24c-.87,5.06-5.02,8.95-14.03,11.09-9.18,1.4-12.76-.69-13.83-4.35-19.98-14.03-44.64-18.19-72.2-16.2-34.88,10.08-59.14,0-41-11.5l2.51-4.53c-24.68,5.61-36.11-2.37-31.21-15.77l-5.1.2c-.94,3.89-4.51,5-9.7,4.4-7,12.03-32.78,17.76-61.74,22.42-8.72,1.76-15.55,5.36-18.46,12.78-3.96,6.88-26.16,8.59-40.78-.2-8.44-7.59-11.64-19.39-11.07-34.21l-1.49-13.33c-2.52-16.88,1.01-28.08,17.54-27.05l43.3-20.6c-9.63-3.88-10.2-9.89-3.59-17.57,4.41-2.66,7.27-6.05,6.99-10.93Z"/>
   <g id="phalangen_overlay" data-name="phalangen overlay">
     <path id="phalangen_overlay_1" data-name="phalangen overlay 1" class="phalangen1445fill" style= {{
             fill: props.colors.phalangen1445fill,
             stroke: props.colors.phalangen1445fill,
             opacity:props.colors.phalangen1445fill,
           }} d="M380.55,186.11c-2.6,4.8-.4,7.1,5.9,7.1,3,0,6-.8,8.7-2.1,12.9-6.4,27.8-7.9,41.7-3.3,2.3.8,4.3,2.1,6,3.8,3.2,3.1,6.1,1.1,8.7-5.9.8-2.1.8-4.4-.2-6.4-1.9-4.1-5.3-5.8-10.8-4.1-12-1.4-25.9-5.3-41-10.9-8.3-3.4-15.1-5.3-14,.8,1.1,9.7-1.4,16-5,21h0Z"/>
     <path id="phalangen_overlay2" data-name="phalangen overlay2" class="phalangen1445fill" style= {{
             fill: props.colors.phalangen1445fill,
             stroke: props.colors.phalangen1445fill,
             opacity:props.colors.phalangen1445fill,
           }} d="M380.85,191.61c3.7-3.3,6.6-7.1,7.8-12.1,1.1-6.8.2-12.3-2.4-16.8"/>
     <path id="phalangen_overlay3" data-name="phalangen overlay3" class="phalangen1445fill" style= {{
             fill: props.colors.phalangen1445fill,
             stroke: props.colors.phalangen1445fill,
             opacity:props.colors.phalangen1445fill,
           }} d="M452.65,189.91c-5.6,6.5-3.1,8.3,4.2,7.3l24.6-3.6c3.4-.2,4.8-1.2,4.2-3-.6-1.8-2.4-2.8-4.3-2.7-8.1.4-16.1-1.3-23.9-6.2-.9-.5-2,.1-2,1.1,0,3.6-1.1,5.8-2.8,7.1Z"/>
     <path id="phalangen_overlay4" data-name="phalangen overlay4" class="phalangen1445fill" style= {{
             fill: props.colors.phalangen1445fill,
             stroke: props.colors.phalangen1445fill,
             opacity:props.colors.phalangen1445fill,
           }} d="M394.25,180.01c-16.2-1.7-20.6,1.3-16.3,7.7-1.7,10.1.2,13.9,6.9,9.6,12.8-5.5,26-8.2,40-6.4,6-1.2,8.6-3.2,7.9-5.9-.5-1.8-2.2-3.1-4.1-3.2l-34.4-1.8h0Z"/>
     <path id="phalangen_overlay5" data-name="phalangen overlay5" class="phalangen1445fill" style= {{
             fill: props.colors.phalangen1445fill,
             stroke: props.colors.phalangen1445fill,
             opacity:props.colors.phalangen1445fill,
           }} d="M434.55,189.51c-4,1.8.4,3.5,6.1,5.1,4.9,4.2,8.7,5.3,10.1.4-.8-1.9-1.7-3.9-4.5-6.8-5.6-4.7-10-7.2-10.8-2.6l-.9,3.9h0Z"/>
     <path id="phalangen_overlay6" data-name="phalangen overlay6" class="phalangen1445fill" style= {{
             fill: props.colors.phalangen1445fill,
             stroke: props.colors.phalangen1445fill,
             opacity:props.colors.phalangen1445fill,
           }} d="M395.85,199.11l19.4.3c.2-.1.3-.1.5-.2,7.4-2.7,4.9-13.8-3-12.9-1.4.1-2.9.4-4.5.8-3.2.8-6.5,1.5-9.8,1.9-5.5.8-7.7,2.4-8.5,4.3h0c.2,3.2,2.7,5.7,5.9,5.8h0Z"/>
     <path id="phalangen_overlay7" data-name="phalangen overlay7" class="phalangen1445fill" style= {{
             fill: props.colors.phalangen1445fill,
             stroke: props.colors.phalangen1445fill,
             opacity:props.colors.phalangen1445fill,
           }} d="M427.45,194.91c-4.1-5.8-6.5-7.1-5.9,0-7.9,6.4-3.6,8.3,1.3,10.1,12,1.8,16.9.9,13.4-3.1l-8.8-7h0Z"/>
     <path id="phalangen_overlay8" data-name="phalangen overlay8" class="phalangen1445fill" style= {{
             fill: props.colors.phalangen1445fill,
             stroke: props.colors.phalangen1445fill,
             opacity:props.colors.phalangen1445fill,
           }} d="M466.95,202.41c-.7,1.9-2.9,2.8-6.1,2.9-5.7-.7-10-2.7-12.9-5.9,3.6-.8,4.4-3.1,3.1-6.3,4.9-.7,9.8,1.2,14.8,5.5,1.1.9,1.6,2.5,1.1,3.8h0Z"/>
   </g>
   <path id="heel_overlay" data-name="heel overlay" class="ferse1445fill" style= {{
             fill: props.colors.ferse1445fill,
             stroke: props.colors.ferse1445fill,
             opacity:props.colors.ferse1445fill,
           }} d="M1.18,147.65c3.81,22.06,8.69,41.83,20.34,47.02,11.27,4.7,24,3.81,38.28-3.06l-6.06,9.81c-13.6,5.85-26.25,7.56-36.77,0-13.71-6.22-13.43-30.21-15.78-53.76Z"/>
   <path id="foot_arch_overlay" data-name="foot arch overlay" class="FootArch1445fill" style= {{
             fill: props.colors.FootArch1445fill,
             stroke: props.colors.FootArch1445fill,
             opacity:props.colors.FootArch1445fill,
           }} d="M71.08,188.36c29.92-5.76,56.86-12.19,64.07-23.01,6.07-2.74,11.81.74,17.28,9.03,6.24,3.49,16.28,3.59,28.73,1.54l-8.79,11.25c-2.3,5.39,5.52,8.32,19.88,9.66l27.4-4.89c31.43-2.87,53.96,4.25,72.2,16.2-26.33-6.99-50.91-10.84-71.52-7.61-5.04.79-10.13,1.27-15.23,1.63l-10.03.72c-4.61.33-9.25-.31-13.6-1.85-16.89-6-32.96-10.87-47.85-14.1-7.58-1.73-37.12.19-62.54,1.43Z"/>
   <path id="talus_roll_overlay" data-name="talus roll overlay" class="trochleatali1445fill" style= {{
             fill: props.colors.trochleatali1445fill,
             stroke: props.colors.trochleatali1445fill,
             opacity:props.colors.trochleatali1445fill,
           }} d="M71.08,66.2l6.78-13c7.28-8.72,19.01-12.55,29.33-10.51,14.95,3.54,29.47,12.01,32.95,22.34-9.99-6.53-20.12-12.26-30.99-13.81-15,1.63-28.22,5.85-38.07,14.97Z"/>
   <path id="upper_ankle_joint_overlay" data-name="upper ankle joint overlay" class="beidesg1445fill" style= {{
             fill: props.colors.beidesg1445fill,
             stroke: props.colors.beidesg1445fill,
             opacity:props.colors.beidesg1445fill,
           }} d="M154.33,43.32c-5.55,9.67-11.11,18.08-16.68,24.63-6.34,14.95-20.85,13.1-41.9-2.2-7.77-5.15-14.83-9.3-16.86-6.37l-8.93,8.57,4.06.5c9.72,1.19,18.69,5.56,26.01,12.07,4.27,3.8,9.1,6.29,14.29,7.61,6.47,1.64,13.32-.35,17.92-5.19l11.1-11.69c4.07-3.4,7.59-14.67,10.98-27.93Z"/>
   <path id="lower_ankle_joint_overlay" data-name="lower ankle joint overlay" class="beidesg1445fill" style= {{
             fill: props.colors.beidesg1445fill,
             stroke: props.colors.beidesg1445fill,
             opacity:props.colors.beidesg1445fill,
           }} d="M172.35,83.95l12.4,7c1.28,20.37-5.31,35.55-22.88,43.49-4.44-.6-8.2.27-11.42,2.31l-.5,23.4c-1.57,3.29-2.57,6.33-1.95,8.66l-7.86-3.61c3.47-1.25,4.56-3.62,4.74-6.41l.47-22.84-11.49-9.67c-.99-.83.03-2.38,1.19-1.82,8.13,3.94,14.56,6.79,16.61,6.79,9.23-.92,17.46-4.97,24.18-13.78,8.49-15.66,6.2-26.31-3.48-33.52"/>
 </g>
</svg>
  );
}

export default LinkerFussLatStehend1445;
