import style from "./style.scss";

export default function RechteRippenDvOblLiegend1806(props) {
  return (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.83 784.15">

    <g id="backgrounds">
      <path class="rechte_rippen_dv_obl_liegend_links1806_cls7" d="M296.58,30.37c-32.6,14-32.1,42.2-64.4,37.4-67.5-10.1-115.8-46.8-171.3-25.2-21.1,8.2-50,24.5-53.6,69.9-10.3,130.3,19.3,201.7-3.7,471.5-8.8,103.3-3.8,180.2,42.8,196.4,14.5,5,44.7,4.2,108.6,2.4,67.5-1.9,99.9-16.4,124.7-32.9,84.5-56.4,92-180.2,94.5-286,.8-31.6,0-74,0-74-1-60.6-3.5-44.4-6.7-123.3-.5-13.2.2-32.8,11.2-64,9.2-26,16.5-50.5,23.6-84.1,5.3-25.3,3.7-35.6,2.7-40.4-.7-3.8-6.2-34.4-32.7-47.6-18.2-9.1-50.1-11-75.7-.1Z"/>
    </g>
    <g id="Highlightedrips" class="rechte_rippen_dv_obl_liegend_links1806_cls4">
      <path id="rip12l" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M248.78,693.47c-1.9,1.4-6.3-1.6-13.7-6.6-4-2.7-7.9-5.2-12.4-9.7-5.3-5.3-5.5-7.2-12.4-16.3-4.4-5.9-7.9-9.8-14.3-16.9-5.9-6.6-10.3-11.1-14.3-14.9-5.3-5.1-8-7.7-11-9.1-4.2-2-7-2.1-11-5.2-3-2.5-4.7-5-5.2-5.9-2.4-4-2.7-7.7-3.2-13.6-.5-6.5-.7-9.9,1.3-13,.3-.5,2.6-4.1,7.1-5.2,1.1-.3,4.7-1.2,7.1.7,2.2,1.7,1.6,4.4,2.6,7.8,1.4,4.6,4.3,6.7,8.5,11,6.4,6.5,4.1,6.1,14.3,18.2,4.2,5.2,7.9,8.9,14.9,16.3,8.6,9,12.8,13.4,17.6,17.6,7.6,6.6,9,6.6,12.4,11,4.3,5.5,6.1,10.4,8.5,16.9,2.8,7.9,5.5,15.2,3.2,16.9h0Z"/>
      <path id="rip11lhighlight" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M257.78,738.77c6.3-6.4,14.4-16,20.8-29.3,2.1-4.4,6.2-13.8,8.5-26.6s1.6-22.6.7-33.7c-.9-10.1-1.6-20.5-5.9-33.7-2.1-6.5-6.3-17.6-14.3-29.8-7.7-11.6-15.3-18.9-22.7-25.9-5.9-5.6-14.4-13.6-27.3-21.5-5.8-3.5-14.6-8.8-27.3-13-4.6-1.5-1.1-.1-24.7-5.2-9.1-2-13-3-15.6-.7-2.9,2.6-2.5,7.9-1.9,14.3.6,7.2,2.2,7.4,2.6,13.6.5,8.8-2.5,11.5,0,15.6,2,3.2,6.1,5.5,9.1,4.6,2.4-.7,2.7-2.9,5.8-6.5.5-.6,4.9-5.7,10.4-7.8,4.8-1.8,8.9-.8,15.6.7,10.1,2.3,17.2,6.1,18.8,7.1,12.3,6.7,19.8,15.1,26,22,7.5,8.5,11.7,15.2,18.2,25.4,9.1,14.2,13.5,21.2,16.3,30.5,3.5,11.7,3.3,21.3,3.2,31.2-.2,10.7-.3,20.6-4.6,32.5-2.3,6.6-7.3,17.6-18.2,28.6"/>
      <path id="rip10lhighlight" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M224.68,710.37c-8,2.8-14.8,3.1-18.2,9.2-1.9,3.4-2.7,8.8,0,11.7.9.9,2.9,2.6,9.7,1.9,9.3-1,16.6-5.4,23.4-10.4,6.6-4.9,12.5-10,20.2-17.6,5.8-5.7,12.9-12.9,21.5-23.4,7.5-9.4,15.2-18.9,22.7-33.2,7.7-14.8,10.9-26.4,11.7-29.3,3.2-11.7,5.8-21.4,3.9-33.7-1-7-3.9-13-9.7-25.4-4.2-9.1-8-17.1-14.9-26.6-8.2-11.3-16.2-18.4-18.2-20.1-6.9-6-13-9.6-25.4-16.9-5-2.9-14.9-8.6-28-14.9-12.9-6.2-10.6-4.2-23.4-10.4-9-4.4-12.7-6.6-19.5-7.8-5.6-1-13.2-2.4-18.2,1.9-2.6,2.3-3.5,6.1-5.2,13.7-1.9,8.5-2.9,12.7-.7,15.6,2.7,3.5,8,3.7,12.4,3.9,3.5.2,6.7-.5,13-1.9,10.3-2.3,12.5-3.6,16.9-2.6,2.7.6,2.3,1.3,7.1,3.2,5.9,2.2,9.6,2.4,13,3.2,3.4.8,5.9,1.9,16.9,9.7,11.7,8.3,17.5,12.4,22.1,16.3,2.5,2,4.5,3.8,21.5,22,16.9,18,19.8,21.4,22.7,27.9,3.5,7.7,4.6,14.6,5.2,18.2.8,5.1,2.2,14.2,0,25.4-2.1,10.8-6.4,18-10.4,24.7-6,10.1-12.1,16.6-16.9,21.5-3.3,3.5-5.8,5.7-22.7,19.5-19.1,15.6-28.7,23.4-32.5,24.7h0Z"/>
      <path id="rip9lhighlight" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M197.98,703.77c22.3-13.8,39.5-27.2,52-37.7,3.6-3.2,14.4-12.4,27.3-25.4,6.9-6.8,12.9-13,20.2-22,5.1-6.3,10.3-12.7,15.6-22,4.2-7.3,9.8-17.1,13-31.2.8-3.7,3.8-17.6,1.3-35.7-2.3-17-8.3-29.4-11.7-35.7-2.8-5.2-7.8-14-16.9-22.7-5.5-5.1-8-6.2-29.9-18.8-15.2-8.9-21.5-12.7-34.4-19.5-14.8-7.8-19.5-9.5-25.4-10.4-3.2-.4-10-.1-23.4.7-13,.8-16.9,1.5-20.2,4.6-1,1-5.8,5.6-4.6,11.7,1,4.9,5.5,8.5,9.7,9.1,1.2.2,1.9.1,10.4-2.6,7.9-2.5,7.6-2.5,8.5-2.6,6-1,11.2.7,14.9,1.9,6.1,2.1,5.5,3.4,10.4,4.6,6.1,1.6,8.4-.2,13.7.7,6,.9,9.9,4.2,13.7,7.1,13.8,10.7,12.1,6.3,31.2,20.8,16.5,12.5,24.7,18.8,31.2,27.9,3.3,4.4,9.3,13,12.4,25.9,1.2,4.6,3.6,16.6.7,31.2-2.6,13-8,21.1-18.2,36.4-2.5,3.7-10.7,15.5-24.1,29.8-3.7,3.9-12.4,12.9-28.6,25.9-12.9,10.4-31.1,23.9-54.6,37.7"/>
      <path id="rip8lhighlight" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M195.48,644.17c5.3,2.3,12.5-4.7,21.5-11.7,11.3-8.9,7.9-4.3,25.4-16.9,11.7-8.5,25.6-18.5,41-34.4,13.6-14,22.3-26.1,27.3-33.2,13.4-18.9,20.1-28.3,24.7-42.9,3.2-10,8.5-27.9,2.6-48.8-.8-3-4.5-15.5-14.9-27.9-6.2-7.4-14.3-12.9-30.5-23.4-14-9.1-21-13.7-31.2-18.8-11.9-6-7.6-2.5-42.2-16.3-11.4-4.5-17.9-7.2-26.6-5.8-3.7.7-3,1.3-14.9,4.6-9.7,2.7-12.6,2.9-14.3,5.8-2.2,3.8-.9,9.4,2,13,2.6,3.2,6.4,4.6,9.7,4.6,4.2,0,5.6-2.4,10.4-3.9,2.1-.7,5.4-1.4,14.3,0,12.5,1.9,22.2,5.7,24.1,6.5,16.8,6.8,25.3,10.1,36.4,16.3,19,10.4,31.4,20.6,39.7,27.3,14.1,11.5,21.3,17.5,24.7,26.6,4.6,12,2.2,23.1.7,30.5-1.1,5.1-4,16.4-19.5,38.3-8,11.3-18.2,26-35.8,41-13.7,11.6-15.4,9.5-42.2,29.8-16.9,12.8-16.9,14.2-23.4,16.9-6.2,2.6-17.7,6.5-18.8,11.7"/>
      <path id="rip7lhighlight" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M177.88,598.67c9.4-3.6,20.6-3.8,36.4-12.4,3.3-1.7,12.6-6.7,41-25.9,25.3-17,38-25.6,43.6-31.2,12.6-12.4,19.3-23.8,31.9-45.4,10.6-18,15.8-26.9,17.6-37.1,4.1-23-4.7-42-8.5-50-2-4.2-12-24.7-34.4-39.6-3.9-2.6-12.4-6.3-29.3-13.7-34.8-15.2-33.5-11.2-44.9-18.2-1.1-.7-7.7-4.7-16.9-6.5-8.4-1.6-12.2-.1-13.7.7-4.4,2.1-3.5,4.4-7.8,7.1-7.4,4.7-13.8.2-17.6,4.6-2.4,2.8-2.2,7.4-.7,10.4,2.6,5.2,9.7,5.8,11,5.8,5.1.2,6.8-2.4,13.7-3.9,3.9-.9,6.7-.8,11.7-.7,4.4.1,14.9.7,29.3,5.2,3.3,1.1,10.6,3.5,20.8,8.5,8.4,4.1,21.8,10.7,35.8,22.7,14.6,12.5,29,24.8,33.2,44.9,2.9,13.6-.5,25.4-2,31.2-3.3,12-8.6,19.9-16.5,31.8-3.5,5.2-11,15.8-22.5,27.8-5.9,6.2-17.8,17.7-35.1,29.3-11.1,7.5-19.8,11.6-33.2,18.2-11.8,5.7-32.2,15.1-51.9,22.3"/>
      <path id="rip6lhighlight" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M170.78,546.87c27.6-5,46.4-11.2,57.8-15.6,9.6-3.7,11.9-5.3,31.2-13,4.9-2,10.9-4.4,18.8-8.5,2.6-1.4,8.2-4.4,14.3-8.5,12.7-8.6,20.6-17.7,29.9-28.6,6.8-8,10.4-12.2,14.3-19,1.3-2.3,5.7-10.1,9.1-21.9,2.6-8.8,8.7-30.5,2.6-57.1-3.1-13.5-8.2-23.1-11.7-29.8-3.3-6.2-10.4-18.5-22.7-31.2-3.1-3.1-16.7-17-39-28.6-6.2-3.2-21.8-10.7-42.9-14.9-8.1-1.6-14.9-1.9-28.6-2.6-13.3-.7-18-.2-22.1,3.2-.6.5-7.3,6.1-6.5,13.7.1.9.6,5,3.9,7.8,4.2,3.3,9.9,2.1,13,1.3,5.6-1.3,7.6-3.8,11-5.2,7.6-3,15,.2,26,4.6,30.4,12.1,25.3,7.7,37.1,13.7,7.8,4,22.1,11.3,37.1,25.4,11.6,11,21.9,20.6,28,37.1,7.7,20.8,3.8,39.3,2,47.4-4.7,21.1-15.5,34.6-24.7,46.1-15.7,19.7-32.2,30.3-37.1,33.2-7.5,4.6-13.3,7.1-41.7,17.6-13.1,4.9-33.5,8.5-53.9,15.6"/>
      <path id="rip5lhighlight" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M170.98,502.97c11.7-.4,29.1-2.1,48.8-9.1,7.8-2.8,13.2-5.5,23.4-10.4,25-12.2,41.7-23.7,46.1-26.6,14-9.6,21.1-14.5,26-20.1,15.8-17.8,19.5-38.4,22.7-56.5,1.4-8.4,3.8-22.2,1.9-40.3-.6-5.1-2.2-17.5-7.8-32.5-2-5.5-6.1-16-14.3-27.9-10.2-14.7-20.9-22.9-35.1-33.7-15.3-11.7-22.5-14-25.4-14.9-4.9-1.4-5.3-.9-19.5-3.9-16.4-3.6-16-4.3-21.5-4.6-10.6-.5-10.4,2.6-23.4,2.6-9.3,0-12.9-1.6-16.3,1.3-3.3,2.7-4.9,8.3-3.2,13,1.4,3.9,4.7,5.9,5.8,6.5,4,2.1,7.8,1.4,12.4.7,6.2-1,12.5-1,18.8-2,0,0,13.9-2.2,31.2,1.3,14.9,3,24.8,10.5,37.1,20.1,13.9,10.8,27.3,21.3,37.1,40.3,13.8,26.9,10.9,52.5,9.7,63-1.3,11-4.7,35.8-23.4,56.5-7,7.8-13.8,12.2-27.3,20.8-23.4,15-43.6,22-49.4,24-18.5,6.2-35.6,9.2-50,10.4"/>
      <path id="rip4lhighlight" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M173.58,444.47c15.8-1.3,29.2-3.8,39.7-6.5,20.7-5.3,35.4-12,39-13.7,4.3-2.1,13.6-6.6,24.7-13.7,8.1-5.3,15.1-9.7,22.7-16.9,5.1-4.9,15-14.3,22.1-29.3,6.7-14.1,7.8-26,9.1-41,.6-7.8,1.7-19.9-.7-35.7-.5-2.8-2.4-15.4-8.5-31.2-3.2-8.3-7.8-20.1-17.6-33.2-3.1-4.1-11.5-15-25.4-25.4-10.5-7.9-9.9-4.9-26.6-16.3-9.1-6.2-12.7-9.4-19.5-10.4-8-1.1-8.4,2.5-18.8,2.6-13.2.2-17-5.6-26.6-2.6-1.8.6-6.3,2.3-9.7,6.5-1.6,2-5.5,6.7-3.9,11.7,1,3.4,4.1,5.7,7.1,6.5,3.5.8,5.1-1,11-2.6,3.6-.9,7.8-1,16.3-1.3,6-.2,9.1-.3,13,0,2.5.2,12.2,1,22.7,5.2,7,2.9,13.1,6.7,23.4,15.6,16.1,14.1,25.4,22,34.4,36.4,5.4,8.7,14.4,23.2,16.9,44.2,2,16.3-.9,28.9-2.6,36.4-2.1,9.2-5.8,24.1-16.9,39-10.1,13.5-21.3,19.9-38.3,29.8-12.3,7.2-25.1,14.4-43.6,18.2-14.7,2.9-27.1,2.8-35.8,1.9"/>
      <path id="rip3lhighlight" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M203.18,327.87c19.3-6.9,33.6-12,41.7-14.9,18.9-6.9,23.9-8.6,30.5-13.7,14.2-11.1,19.7-25.6,21.5-30.5,5.8-15.9,4.2-29.3,3.2-38.3-1-8.8-2.9-23.9-13.6-39-8.8-12.5-19.2-18.9-28.6-24.7-7.1-4.4-16.7-10.1-30.5-13-12.4-2.5-24.5-1.9-25.4-1.9-7.2.4-14.4.1-21.5.7-5.8.4-9.2.9-11-1.3-2.2-2.8-.6-8.2,2-11.7,2.2-2.9,5-4.3,17.6-7.8,9.8-2.8,14.7-4.2,16.9-4.2,2.9-.1,7,.2,23.4,6.7,9,3.5,13.4,5.3,18.2,7.8,7.8,3.9,18.5,9.5,28.6,20.1,7,7.3,14.3,15,16.9,27.3,2.8,13.5-1.7,24.7-4.6,31.8-5.3,12.9-13.2,20.9-18.2,25.9s-9.5,9.5-16.9,13c-5.6,2.7-9,3-16.3,6.5-5.7,2.8-12.7,4.4-15.6,6.5"/>
      <path id="rip2lhighlight" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M208.28,367.47c9.8-3.6,18-7.2,24.3-10,19.6-8.6,33.2-17.9,38.3-21.5,12.3-8.5,19.2-13.4,26-22,16-20.3,17.5-43.3,18.2-53.3,1.2-18.7-2.5-33.1-4.6-41-2.6-9.8-6.2-18.4-13.6-35.7-1.5-3.3-1.5-3.2-12.4-27.9-3.2-7.4-4.2-11.6-7.8-17.6-2.8-4.7-6.1-8.4-12.7-15.8-9.7-10.8-15.1-15.2-17.8-17.3-6-4.7-9.2-6-10.4-6.5-5.8-2.2-7.3-1-13.7-3.2-6.4-2.3-6.2-4-13-6.5-3.6-1.3-8-2.9-13.7-2.6-4.5.2-7.7,1.5-11.3,2.9-6.9,2.6-10.4,4-11.4,6.9-1.9,5.2,4.2,13.2,11,15.6,1.7.6,3,1.1,8.5.7,5.6-.5,11.3-.1,16.9-.7,17.4-1.6,39.6,19.5,41,20.8,13.7,13.3,18.9,27.8,20.2,31.8,2.3,6.9,6.9,21.6,2,39-1.4,4.8-4.1,14.1-12.4,22.7-10.3,10.7-25.3,17-30.1,17.9"/>
      <path id="rip1lhighlight" class="ribs1806Fill" style={{
              fill: props.colors.ribs1806Fill,
              stroke: props.colors.ribs1806Fill,
            }} d="M187.08,171.17c2.5-1.4,9.2-8.1,13-11,5.9-4.7,11.3-9,14.9-16.3,4.5-9.4,3.6-18.4,3.2-21.5-.5-4.1-1.7-13.7-9.1-20.8-7.4-7-17.9-8.7-20.2-9.1-5.4-.9-10-.5-15.6-3.9-2-1.2-4.8-3-5.2-5.9-.5-4.4,5.2-8.2,6.5-9.1,3.3-2.1,5.3-1.8,14.9-3.2,10.7-1.5,10.8-2.4,13.7-1.9,5.8.9,10,4.8,14.3,9.7,8.9,10.2,16.6,19.1,21.5,34.4,2.3,7.2,8.7,28.4,0,52.7-2.6,7.1-9.1,25.2-28,36.4-12.7,7.5-24.9,8.5-31.2,8.5"/>
      <path class="soft1806Fill" style={{
              fill: props.colors.soft1806Fill,
              stroke: props.colors.soft1806Fill,
            }} d="M366.68,776.97c3.37-.04,5.59-36.78,7-60,1.45-24.03,1.33-37.24,2-60,.76-25.93,1.85-40.73,4-75,6.04-96.25,5.05-100.65,10-174,4.53-67.12,6.79-100.68,10-127,11.1-91,16.64-136.49,20-149,0,0,14.66-54.64,2-96-2.42-7.9-6.31-16-14-21-5.55-3.61-10.53-3.95-31-4-37.03-.09-37.48.98-44-1-14.64-4.45-21.99-12.81-28-9-3.42,2.17-4.74,7.22-4,11,1.84,9.41,16.78,12.99,21,14,16.71,4,21.73-3.17,44-3,11.21.08,26.19.19,35,10,5.97,6.65,6.69,15.07,7,20,2.6,40.82-6.38,88.55-10,111-.31,1.89-2.2,17.93-6,50-2.32,19.56-8.3,70.14-12,109-1.97,20.7-4.34,63.41-9,148-5.35,97.12-4.34,86.63-6,110-.03.41-2.7,37.97-5,87-.83,17.66-1.05,24.92-1,34,.19,36.09,4.52,75.04,8,75Z"/>
    </g>
    <g id="basic_drawing" data-name="basic drawing">
      <path id="rip12l-2" data-name="rip12l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M248.78,693.77c-1.9,1.4-6.3-1.6-13.7-6.6-4-2.7-7.9-5.2-12.4-9.7-5.3-5.3-5.5-7.2-12.4-16.3-4.4-5.9-7.9-9.8-14.3-16.9-5.9-6.6-10.3-11.1-14.3-14.9-5.3-5.1-8-7.7-11-9.1-4.2-2-7-2.1-11-5.2-3-2.5-4.7-5-5.2-5.9-2.4-4-2.7-7.7-3.2-13.6-.5-6.5-.7-9.9,1.3-13,.3-.5,2.6-4.1,7.1-5.2,1.1-.3,4.7-1.2,7.1.7,2.2,1.7,1.6,4.4,2.6,7.8,1.4,4.6,4.3,6.7,8.5,11,6.4,6.5,4.1,6.1,14.3,18.2,4.2,5.2,7.9,8.9,14.9,16.3,8.6,9,12.8,13.4,17.6,17.6,7.6,6.6,9,6.6,12.4,11,4.3,5.5,6.1,10.4,8.5,16.9,2.8,7.9,5.5,15.2,3.2,16.9h0Z"/>
      <path id="vertebralbody7" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M97.98,110.37c1.1-4.5,2-10.5,0-16.9-2.9-9.6-10.3-14.6-9.1-16.3,1.3-1.8,9.7,2.3,13,3.9,13.4,6.5,13.3,9.9,22.1,13,10.8,3.7,21.5,3.2,21.5,3.2,4.7-.2,7.5-1,11,.7,4.7,2.2,7.6,7.1,8.5,11.7,1.4,7.6-2.8,15.3-8.5,18.8-3.7,2.3-7.4,2.4-22.1.7-7.4-.8-15.4-1.9-24.1-3.2"/>
      <path id="rip11l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M257.88,739.07c6.3-6.4,14.4-16,20.8-29.3,2.1-4.4,6.2-13.8,8.5-26.6s1.6-22.6.7-33.7c-.9-10.1-1.6-20.5-5.9-33.7-2.1-6.5-6.3-17.6-14.3-29.8-7.7-11.6-15.3-18.9-22.7-25.9-5.9-5.6-14.4-13.6-27.3-21.5-5.8-3.5-14.6-8.8-27.3-13-4.6-1.5-1.1-.1-24.7-5.2-9.1-2-13-3-15.6-.7-2.9,2.6-2.5,7.9-1.9,14.3.6,7.2,2.2,7.4,2.6,13.6.5,8.8-2.5,11.5,0,15.6,2,3.2,6.1,5.5,9.1,4.6,2.4-.7,2.7-2.9,5.8-6.5.5-.6,4.9-5.7,10.4-7.8,4.8-1.8,8.9-.8,15.6.7,10.1,2.3,17.2,6.1,18.8,7.1,12.3,6.7,19.8,15.1,26,22,7.5,8.5,11.7,15.2,18.2,25.4,9.1,14.2,13.5,21.2,16.3,30.5,3.5,11.7,3.3,21.3,3.2,31.2-.2,10.7-.3,20.6-4.6,32.5-2.3,6.6-7.3,17.6-18.2,28.6"/>
      <path id="rip10l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M224.68,710.67c-7.9,2.8-14.8,3.1-18.2,9.2-1.9,3.4-2.7,8.8,0,11.7.9.9,2.9,2.6,9.7,1.9,9.3-1,16.6-5.4,23.4-10.4,6.6-4.9,12.5-10,20.2-17.6,5.8-5.7,12.9-12.9,21.5-23.4,7.5-9.4,15.2-18.9,22.7-33.2,7.7-14.8,10.9-26.4,11.7-29.3,3.2-11.7,5.8-21.4,3.9-33.7-1-7-3.9-13-9.7-25.4-4.2-9.1-8-17.1-14.9-26.6-8.2-11.3-16.2-18.4-18.2-20.1-6.9-6-13-9.6-25.4-16.9-5-2.9-14.9-8.6-28-14.9-12.9-6.2-10.6-4.2-23.4-10.4-9-4.4-12.7-6.6-19.5-7.8-5.6-1-13.2-2.4-18.2,1.9-2.6,2.3-3.5,6.1-5.2,13.7-1.9,8.5-2.9,12.7-.7,15.6,2.7,3.5,8,3.7,12.4,3.9,3.5.2,6.7-.5,13-1.9,10.3-2.3,12.5-3.6,16.9-2.6,2.7.6,2.3,1.3,7.1,3.2,5.9,2.2,9.6,2.4,13,3.2,3.4.8,5.9,1.9,16.9,9.7,11.7,8.3,17.5,12.4,22.1,16.3,2.5,2,4.5,3.8,21.5,22,16.9,18,19.8,21.4,22.7,27.9,3.5,7.7,4.6,14.6,5.2,18.2.8,5.1,2.2,14.2,0,25.4-2.1,10.8-6.4,18-10.4,24.7-6,10.1-12.1,16.6-16.9,21.5-3.3,3.5-5.8,5.7-22.7,19.5-19.1,15.6-28.7,23.4-32.5,24.7h0Z"/>
      <path id="rip9l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M197.98,703.97c22.3-13.8,39.5-27.2,52-37.7,3.6-3.2,14.4-12.4,27.3-25.4,6.9-6.8,12.9-13,20.2-22,5.1-6.3,10.3-12.7,15.6-22,4.2-7.3,9.8-17.1,13-31.2.8-3.7,3.8-17.6,1.3-35.7-2.3-17-8.3-29.4-11.7-35.7-2.8-5.2-7.8-14-16.9-22.7-5.5-5.1-8-6.2-29.9-18.8-15.2-8.9-21.5-12.7-34.4-19.5-14.8-7.8-19.5-9.5-25.4-10.4-3.2-.4-10-.1-23.4.7-13,.8-16.9,1.5-20.2,4.6-1,1-5.8,5.6-4.6,11.7,1,4.9,5.5,8.5,9.7,9.1,1.2.2,1.9.1,10.4-2.6,7.9-2.5,7.6-2.5,8.5-2.6,6-1,11.2.7,14.9,1.9,6.1,2.1,5.5,3.4,10.4,4.6,6.1,1.6,8.4-.2,13.7.7,6,.9,9.9,4.2,13.7,7.1,13.8,10.7,12.1,6.3,31.2,20.8,16.5,12.5,24.7,18.8,31.2,27.9,3.3,4.4,9.3,13,12.4,25.9,1.2,4.6,3.6,16.6.7,31.2-2.6,13-8,21.1-18.2,36.4-2.5,3.7-10.7,15.5-24.1,29.8-3.7,3.9-12.4,12.9-28.6,25.9-12.9,10.5-31.1,24-54.6,37.7"/>
      <path id="rip8l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M195.58,644.47c5.3,2.3,12.5-4.7,21.5-11.7,11.3-8.9,7.9-4.3,25.4-16.9,11.7-8.5,25.6-18.5,41-34.4,13.6-14,22.3-26.1,27.3-33.2,13.4-18.9,20.1-28.3,24.7-42.9,3.2-10,8.5-27.9,2.6-48.8-.8-3-4.5-15.5-14.9-27.9-6.2-7.4-14.3-12.9-30.5-23.4-14-9.1-21-13.7-31.2-18.8-11.9-6-7.6-2.5-42.2-16.3-11.4-4.5-17.9-7.2-26.6-5.8-3.7.7-3,1.3-14.9,4.6-9.7,2.7-12.6,2.9-14.3,5.8-2.2,3.8-.9,9.4,2,13,2.6,3.2,6.4,4.6,9.7,4.6,4.2,0,5.6-2.4,10.4-3.9,2.1-.7,5.4-1.4,14.3,0,12.5,1.9,22.2,5.7,24.1,6.5,16.8,6.8,25.3,10.1,36.4,16.3,19,10.4,31.4,20.6,39.7,27.3,14.1,11.5,21.3,17.5,24.7,26.6,4.6,12,2.2,23.1.7,30.5-1.1,5.1-4,16.4-19.5,38.3-8,11.3-18.2,26-35.8,41-13.7,11.6-15.4,9.5-42.2,29.8-16.9,12.8-16.9,14.2-23.4,16.9-6.2,2.6-17.7,6.5-18.8,11.7"/>
      <path id="rip7l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M177.88,598.97c9.4-3.6,20.6-3.8,36.4-12.4,3.3-1.7,12.6-6.7,41-25.9,25.3-17,38-25.6,43.6-31.2,12.6-12.4,19.3-23.8,31.9-45.4,10.6-18,15.8-26.9,17.6-37.1,4.1-23-4.7-42-8.5-50-2-4.2-12-24.7-34.4-39.6-3.9-2.6-12.4-6.3-29.3-13.7-34.8-15.2-33.5-11.2-44.9-18.2-1.1-.7-7.7-4.7-16.9-6.5-8.4-1.6-12.2-.1-13.7.7-4.4,2.1-3.5,4.4-7.8,7.1-7.4,4.7-13.8.2-17.6,4.6-2.4,2.8-2.2,7.4-.7,10.4,2.6,5.2,9.7,5.8,11,5.8,5.1.2,6.8-2.4,13.7-3.9,3.9-.9,6.7-.8,11.7-.7,4.4.1,14.9.7,29.3,5.2,3.3,1.1,10.6,3.5,20.8,8.5,8.4,4.1,21.8,10.7,35.8,22.7,14.6,12.5,29,24.8,33.2,44.9,2.9,13.6-.5,25.4-2,31.2-3.3,12-8.6,19.9-16.5,31.8-3.5,5.2-11,15.8-22.5,27.8-5.9,6.2-17.8,17.7-35.1,29.3-11.1,7.5-19.8,11.6-33.2,18.2-11.8,5.7-32.2,15.1-51.9,22.3"/>
      <path id="rip6l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M170.88,547.37c27.6-5,46.4-11.2,57.8-15.6,9.6-3.7,11.9-5.3,31.2-13,4.9-2,10.9-4.4,18.8-8.5,2.6-1.4,8.2-4.4,14.3-8.5,12.7-8.6,20.6-17.7,29.9-28.6,6.8-8,10.4-12.2,14.3-19,1.3-2.3,5.7-10.1,9.1-21.9,2.6-8.8,8.7-30.5,2.6-57.1-3.2-13.6-8.2-23.2-11.7-30-3.3-6.2-10.4-18.5-22.7-31.2-3.1-3.1-16.7-17-39-28.6-6.2-3.2-21.8-10.7-42.9-14.9-8.1-1.6-14.9-1.9-28.6-2.6-13.3-.7-18-.2-22.1,3.2-.6.5-7.3,6.1-6.5,13.7.1.9.6,5,3.9,7.8,4.2,3.3,9.9,2.1,13,1.3,5.6-1.3,7.6-3.8,11-5.2,7.6-3,15,.2,26,4.6,30.4,12.1,25.3,7.7,37.1,13.7,7.8,4,22.1,11.3,37.1,25.4,11.6,11,21.9,20.6,28,37.1,7.7,20.8,3.8,39.3,2,47.4-4.7,21.1-15.5,34.6-24.7,46.1-15.7,19.7-32.2,30.3-37.1,33.2-7.5,4.6-13.3,7.1-41.7,17.6-13.1,4.9-33.5,8.5-53.9,15.6"/>
      <path id="rip5l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M170.98,503.27c11.7-.4,29.1-2.1,48.8-9.1,7.8-2.8,13.2-5.5,23.4-10.4,25-12.2,41.7-23.7,46.1-26.6,14-9.6,21.1-14.5,26-20.1,15.8-17.8,19.5-38.4,22.7-56.5,1.4-8.4,3.8-22.2,1.9-40.3-.6-5.1-2.2-17.5-7.8-32.5-2-5.5-6.1-16-14.3-27.9-10.2-14.7-20.9-22.9-35.1-33.7-15.3-11.7-22.5-14-25.4-14.9-4.9-1.4-5.3-.9-19.5-3.9-16.4-3.6-16-4.3-21.5-4.6-10.6-.5-10.4,2.6-23.4,2.6-9.3,0-12.9-1.6-16.3,1.3-3.3,2.7-4.9,8.3-3.2,13,1.4,3.9,4.7,5.9,5.8,6.5,4,2.1,7.8,1.4,12.4.7,6.2-1,12.5-1,18.8-2,0,0,13.9-2.2,31.2,1.3,14.9,3,24.8,10.5,37.1,20.1,13.9,10.8,27.3,21.3,37.1,40.3,13.8,26.9,10.9,52.5,9.7,63-1.3,11-4.7,35.8-23.4,56.5-7,7.8-13.8,12.2-27.3,20.8-23.4,15-43.6,22-49.4,24-18.5,6.2-35.6,9.2-50,10.4"/>
      <path id="rip4l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M173.58,444.77c15.8-1.3,29.2-3.8,39.7-6.5,20.7-5.3,35.4-12,39-13.7,4.3-2.1,13.6-6.6,24.7-13.7,8.1-5.3,15.1-9.7,22.7-16.9,5.1-4.9,15-14.3,22.1-29.3,6.7-14.1,7.8-26,9.1-41,.6-7.8,1.7-19.9-.7-35.7-.5-2.8-2.4-15.4-8.5-31.2-3.2-8.3-7.8-20.1-17.6-33.2-3.1-4.1-11.5-15-25.4-25.4-10.5-7.9-9.9-4.9-26.6-16.3-9.1-6.2-12.7-9.4-19.5-10.4-8-1.1-8.4,2.5-18.8,2.6-13.2.2-17-5.6-26.6-2.6-1.8.6-6.3,2.3-9.7,6.5-1.6,2-5.5,6.7-3.9,11.7,1,3.4,4.1,5.7,7.1,6.5,3.5.8,5.1-1,11-2.6,3.6-.9,7.8-1,16.3-1.3,6-.2,9.1-.3,13,0,2.5.2,12.2,1,22.7,5.2,7,2.9,13.1,6.7,23.4,15.6,16.1,14.1,25.4,22,34.4,36.4,5.4,8.7,14.4,23.2,16.9,44.2,2,16.3-.9,28.9-2.6,36.4-2.1,9.2-5.8,24.1-16.9,39-10.1,13.5-21.3,19.9-38.3,29.8-12.3,7.2-25.1,14.4-43.6,18.2-14.7,2.9-27.1,2.8-35.8,1.9"/>
      <path id="partofrip3l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M221.48,273.37c-8.9.9-23.7,1.7-32.5,2.6"/>
      <path id="rip3l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M203.18,328.17c19.3-6.9,33.6-12,41.7-14.9,18.9-6.9,23.9-8.6,30.5-13.7,14.2-11.1,19.7-25.6,21.5-30.5,5.8-15.9,4.2-29.3,3.2-38.3-1-8.8-2.9-23.9-13.6-39-8.8-12.5-19.2-18.9-28.6-24.7-7.1-4.4-16.7-10.1-30.5-13-12.4-2.5-24.5-1.9-25.4-1.9-7.2.4-14.4.1-21.5.7-5.8.4-9.2.9-11-1.3-2.2-2.8-.6-8.2,2-11.7,2.2-2.9,5-4.3,17.6-7.8,9.8-2.8,14.7-4.2,16.9-4.2,2.9-.1,7,.2,23.4,6.7,9,3.5,13.4,5.3,18.2,7.8,7.8,3.9,18.5,9.5,28.6,20.1,7,7.3,14.3,15,16.9,27.3,2.8,13.5-1.7,24.7-4.6,31.8-5.3,12.9-13.2,20.9-18.2,25.9s-9.5,9.5-16.9,13c-5.6,2.7-9,3-16.3,6.5-5.7,2.8-12.7,4.4-15.6,6.5"/>
      <path id="rip2l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M208.28,367.77c9.8-3.6,18-7.2,24.3-10,19.6-8.6,33.2-17.9,38.3-21.5,12.3-8.5,19.2-13.4,26-22,16-20.3,17.5-43.3,18.2-53.3,1.2-18.7-2.5-33.1-4.6-41-2.6-9.8-6.2-18.4-13.6-35.7-1.5-3.3-1.5-3.2-12.4-27.9-3.2-7.4-4.2-11.6-7.8-17.6-2.8-4.7-6.1-8.4-12.7-15.8-9.7-10.8-15.1-15.2-17.8-17.3-6-4.7-9.2-6-10.4-6.5-5.8-2.2-7.3-1-13.7-3.2-6.4-2.3-6.2-4-13-6.5-3.6-1.3-8-2.9-13.7-2.6-4.5.2-7.7,1.5-11.3,2.9-6.9,2.6-10.4,4-11.4,6.9-1.9,5.2,4.2,13.2,11,15.6,1.7.6,3,1.1,8.5.7,5.6-.5,11.3-.1,16.9-.7,17.4-1.6,39.6,19.5,41,20.8,13.7,13.3,18.9,27.8,20.2,31.8,2.3,6.9,6.9,21.6,2,39-1.4,4.8-4.1,14.1-12.4,22.7-10.3,10.7-25.3,17-30.1,17.9"/>
      <path id="rip1l" class="rechte_rippen_dv_obl_liegend_links1806_cls1" d="M187.18,171.47c2.5-1.4,9.2-8.1,13-11,5.9-4.7,11.3-9,14.9-16.3,4.5-9.4,3.6-18.4,3.2-21.5-.5-4.1-1.7-13.7-9.1-20.8-7.4-7-17.9-8.7-20.2-9.1-5.4-.9-10-.5-15.6-3.9-2-1.2-4.8-3-5.2-5.9-.5-4.4,5.2-8.2,6.5-9.1,3.3-2.1,5.3-1.8,14.9-3.2,10.7-1.5,10.8-2.4,13.7-1.9,5.8.9,10,4.8,14.3,9.7,8.9,10.2,16.6,19.1,21.5,34.4,2.3,7.2,8.7,28.4,0,52.7-2.6,7.1-9.1,25.2-28,36.4-12.7,7.5-24.9,8.5-31.2,8.5"/>
      <path id="partofscapula8" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M348.98,188.97c1.5,5.6,1.9,9.7,2.4,21.6.8,14.3,1.2,21.6,1.3,26.6.2,15.6,1.2,16.2,3.9,55.9,2.3,33,1.6,32.9,3.9,74,.4,6.7.2,13.4.7,20.1,1.3,17,4,26-.7,29.3-2.8,2.1-6.7.8-7.1.7-7.3-2.4-9.3-14.5-10.4-22-5.3-36.1-1.9-53.3-5.9-81.2-1.2-8.3-2.6-14.5-3.9-27.3-1.1-10.9-1.3-13.3-2.6-20.8-1.8-10.4-3-11.6-4-20.1-1-7.7-.2-7.8-1.2-18.3-1-10.5-1.7-10.7-2.3-18.8-.9-12,.8-11.4-.3-19.5-.6-4.8-1.7-13.2-7.1-21.5-1.7-2.5-3.4-4.5-5.9-9.1-2.8-5.1-4-8.8-4.6-12.4-.6-3.8-1-6.6.7-9.1,1.2-1.7,3.4-3.3,5.9-3.2,2.7.1,4.4,2.1,7.8,6.5,7.3,9.1,8.5,11,8.5,11,11.6,17.8,10.7,15.8,12.4,18.8,3.6,6.5,6.4,11.6,8.5,18.8h0Z"/>
      <path id="vertebralbody6" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M97.98,116.17c-1.2,1.9,3,4.5,4.2,10.8.4,2.4,0,2.9-1,10.7-.8,7.4-1.3,11.2-1,15.9.3,5.2.5,8.6,3,11.4,2.5,2.8,6.1,3.1,11.7,3.9,9.4,1.1,14.1-.7,24.1-2,18.6-2.3,24.3,1.3,27.3-3.2,2.1-3.2,1-8.6-1.3-19.5-1-4.9-1.6-7.4-2.6-8.5-5-5.3-13.3,1.1-28-2-5.5-1.1-11.4-2.3-16.3-7.1-3.9-3.7-4.8-7.3-9.7-9.7-4-2-9.3-2.5-10.4-.7h0Z"/>
      <path id="clavicula" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M374.88,61.07c-3.4,4-10.3.9-21.3,1.3-7.3.2-13.3,2.3-25.4,6.5-13.9,4.8-19.7,8.4-32.5,14.3-22.8,10.5-22.3,7.2-42.2,16.3-4.1,1.8-3.3,1.6-41,22.7-20.4,11.4-25,14-33.2,19.5-9.3,6.4-16.7,11.3-25.4,19.5-14.4,13.4-22.1,22-22.1,22-6.7,7.5-10.4,12.4-16.9,13-6.2.6-11.4-3.1-12.4-3.9-5.3-3.8-7.1-8.9-8.5-13-1.9-5.6-1.9-10.3-1.9-14.9,0-3.7,0-5.5.8-6.7,2.5-4.4,8.5-3.8,17.5-6.3,1.9-.6,6.1-2.4,14.3-5.9,23.5-10.2,37.3-16,47.5-20.8,14.7-6.9,10.8-6.1,68.9-39,14.8-8.4,37.9-21.3,54.6-26.6,6.2-2,12.4-4.6,18.8-6.5,8.6-2.5,12.1-2.9,16.3-6.5,3.2-2.7,3.8-4.7,7.8-7.1,4.1-2.4,8.2-3,10.3-3.2,6.3-.5,11.1,1.7,14.3,3.2,4.7,2.2,9.3,4.4,11.7,9.7.1.4,3.5,8.2,0,12.4h0Z"/>
      <path id="partofscapula7" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M338.58,144.37c-7.3-5.6-13.7-8-18.2-9.1-6.2-1.7-10.3-1.3-16.9-4.6-4-2-6.5-4.3-9.1-6.5-7.6-6.7-7-9.4-14.3-16.3-6.4-6-8.5-5.5-10.4-9.7-2.2-5-2.4-12.6,2-16.3,4.5-4,12-1.7,14.9-.7,4.5,1.4,9.5,5.5,19.5,13.6,4.4,3.6,6.5,5.5,10.4,6.5,4.7,1.2,6.6-.3,11,0,8.4.6,14.4,7.2,19.5,13,4.9,5.4,9.8,10.9,10.4,18.8.4,5.9-1.9,9.6-5.9,18.2-2.6,5.8-8.5,18.6-11,28.6-2.1,8.5-.8,18.9,1.9,39.6,1.6,12.4,2.7,16.4,5.2,32.5,2.2,14.1,3.7,25.6,4.6,33.2"/>
      <line id="partofscapula6" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="322.98" y1="203.87" x2="301.18" y2="167.47"/>
      <line id="partofscapula5" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="312.08" y1="162.17" x2="319.68" y2="153.27"/>
      <line id="partofscapula4" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="326.68" y1="245.57" x2="308.38" y2="216.17"/>
      <line id="partofscapula3" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="290.78" y1="170.57" x2="290.48" y2="157.67"/>
      <path id="partofscapula2" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M290.38,157.67c10.8-3.8,21.7,2,21.7,4.5"/>
      <path id="partofscapula1" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M350.28,196.47c3.7-4.5,12.9-16,16.1-25.4.9-2.7,1.5-4.8,1.8-6.3,1.4-6.3.7-11.2,2.8-24.9,3.4-22.6,7.1-23.8,5.8-35.7-.9-7.3-2.6-9.2-3.9-21.5-1.1-9.5-2.2-19.5,1.9-21.5,1.7-.8,3.9.3,8.5,2.6,2.7,1.4,8.1,4.1,11.7,10.4,1.1,2,3.2,6.3,3.2,19.5,0,9.8-1.1,17.7-1.9,22-3,17.4-8.1,29.9-9.1,32.5-5.1,12.5-6.2,10.3-14.3,27.9-6.6,14.2-10.3,25.8-11.7,30.5-3.1,10.1-6.4,23.8-8.1,40.4"/>
      <path id="upperarm1" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M350.28,30.37c.2,3.4,1,8.2,3.9,13,3.1,4.9,6.7,6.8,10.4,9.7,9.6,7.4,13.2,16.7,20.2,35.1,2.1,5.3,3.5,10.2,1.3,14.3-1.5,3-3.4,2.6-5.8,5.9-3.5,4.8-1.6,8.4-3.9,14.9-2.2,6-6.2,9.6-8.5,11.7-6.8,6.1-14.2,7.7-17.6,8.5-11.3,2.3-20.2-.7-24.1-2-5.5-1.9-15.3-5.2-21.5-14.9-4.7-7.3-5-14.8-5.2-18.8-.2-6.5,1.1-8.7,3.9-21.5,2.6-12,3.8-18,3.9-24.7.1-6-.9-15-5.8-25.9"/>
      <path id="vertebralbody5" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M152.58,752.17c-2.8,2.7-6.8.6-17.4,0-16.4-.9-24.6-1.3-29.9-.7-10.8,1.2-12.6,3.7-27.3,5.2-11.4,1.1-14.6.1-16.3-1.9-2.1-2.5-.9-5.5,1.3-16.9,2.4-12.6,3.6-18.9,3.5-26.6-.1-11.2-2.7-13.9-.3-16.3,4.5-4.7,14.3,5.4,34.4,4.8,8.8-.3,11.1-2.3,21.4-1.6,5.4.4,8.6,1.2,14.4.4,7.8-1.1,9.1-3.5,11.6-2.4,5.2,2.4.9,14.1,3.7,35.2,1.6,12.2,4.2,17.6.9,20.8h0Z"/>
      <line id="partofspine53" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="150.88" y1="688.57" x2="154.18" y2="628.67"/>
      <line id="partofspine52" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="65.48" y1="690.47" x2="69.38" y2="637.47"/>
      <line id="partofspine51" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="72.98" y1="601.17" x2="73.88" y2="582.57"/>
      <line id="partofspine50" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="60.48" y1="782.77" x2="60.48" y2="762.57"/>
      <line id="partofspine49" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="152.58" y1="782.77" x2="147.58" y2="762.57"/>
      <path id="partofspine48" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M60.48,762.57c49.4-5.4,87.1,0,87.1,0"/>
      <path id="partofspine47" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M77.78,756.67c37.7,3.5,64.2-1.7,64.2-3.9"/>
      <path id="partofspine46" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M150.98,688.57c-43.9-2.8-84.1,3.4-78,5.2"/>
      <path id="partofspine45" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M83.48,691.17c26.3,0,47.7,3.5,47.7,7.8"/>
      <path id="partofspine44" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M69.38,637.37c46.9,0,84.8-3.9,84.8-8.7"/>
      <path id="partofspine43" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M146.18,628.67c-56.6-6.5-76.8,0-76.8,0"/>
      <path id="connectiontorip21" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M152.48,706.77c1.6,0,41.1,13.9,2.7,39.6"/>
      <path id="connectiontorip20" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M170.78,713.87c-4.6,0,78.9,12.7-8.3,32.5"/>
      <path id="connectiontorip19" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M164.68,682.57c38.9-1.9,34.6-18.6,12.6-23.9"/>
      <path id="connectiontorip18" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M150.88,688.57c2.4,0,58.2-20.9,4.3-41.8"/>
      <path id="rip12r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M21.98,690.87c100.7-84.4,62.7-127.4-9.1-22.4"/>
      <path id="rip11r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M21.48,620.17c98.6-61.2,95.4-140.9-4.1-25.9"/>
      <path id="partofspine42" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M79.48,625.87c36.8,0,66.7-4.2,66.7-9.4"/>
      <path id="partofspine41" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M146.18,616.27c-34.7,0-62.9,2.1-62.9,4.8"/>
      <path id="partofspine40" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M77.88,581.97c42.2,4.6,74.7-1.7,74.7-3.9"/>
      <path id="partofspine39" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M147.58,574.07c-43.6-2.5-68.1,2.2-68.1,4.8"/>
      <path id="partofspine38" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M86.88,566.37c30.5,0,55.1-1.3,55.1-3"/>
      <path id="partofspine37" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M141.98,563.37c-29.5-7.8-51.8-6.7-51.8-9.3"/>
      <path id="partofspine36" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M79.48,528.77c31.2,3.9,62.5-2.1,62.5-4.6"/>
      <path id="partofspine35" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M79.48,521.57c24.6-3.3,62.5,1.2,62.5,2.6"/>
      <line id="partofspine34" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="73.38" y1="609.87" x2="73.38" y2="618.77"/>
      <path id="rip10r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M15.48,539.87c12.4-8.3,21.3-15.9,27.3-21.5,5.4-5,9.5-9.3,18.2-16.9,10.8-9.3,10.2-7.7,13-11,2.4-2.9,4.2-5.9,7.8-6.5,2.1-.4,5.1,0,6.5,1.9,1.4,1.6.6,3,.9,7.8.1,3,.4,2.5,1.1,7.8.8,5.7,1,8.7,0,9.7-1.7,1.7-4.5-.1-9.1,0-3.9.1-7,1.4-13,5.2-15.5,9.9-27.3,21.5-27.3,21.5-5.5,5.2-12.5,12.6-20.2,22"/>
      <path id="rip9r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M24.78,471.07c4.1-3.1,10.1-7.7,17.6-13,17.8-12.9,17.4-11.5,22.7-16.3,3.3-3,8.9-8.5,17.6-14.3,3.5-2.3,6.8-4.2,9.7-3.2,2.8,1.1,4,4.1,5.8,8.5,1.8,4.4,2.8,6.8,1.9,9.7-1.2,3.9-5.1,5.8-7.8,7.1-4.3,2.1-6.7,1.7-10.4,2.6-3.6.8-6,2.3-16.3,11-8.9,7.4-11.1,9.7-16,13.9-4,3.3-10,8.1-17.9,13.4"/>
      <path id="rip8r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M28.48,407.07c3.1-2,7.9-5.2,13.7-9.1,8.9-6.1,10.9-7.7,21.5-14.9,18.2-12.5,14.7-9.3,17.6-11.7,3.8-3.1,6.7-6,11.7-7.1,2.1-.5,7.4-1.7,11,1.3,4.5,3.8,3,11.3,2.6,13-.1.5-1.9,8.5-9.1,12.4-4.5,2.4-7.9,1.5-12.4,1.9-6.1.6-10.4,3.2-24.1,13.7-9.1,6.9-18.4,14.5-28,22.7"/>
      <path id="rip7r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M29.08,350.07c2.3-1.6,5.9-4.2,10.4-7.1,8.5-5.6,10.4-6.6,18.2-11.7,13.1-8.5,13.5-9.8,20.2-13,7.2-3.6,12.3-4.9,17.6-4.6,3,.2,7.5.5,10.4,3.9,2.2,2.6,3.6,6.9,1.9,9.7-1.4,2.2-4.3,2.3-9.1,3.2,0,0-.1,0-16.3,4.6-8.6,2.4-13.4,7.1-22.1,14.3-7.5,6.2-18.5,14.7-33.2,24.7"/>
      <path id="rip6r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M19.98,307.77c3.7-2.4,9.1-6.1,15.6-11,7.9-6.1,9.3-7.9,18.8-15.6,15.6-12.6,21.8-15.8,24.1-16.9,3.7-1.8,10.6-4.8,19.5-5.2,4.8-.2,9.1-.3,11.7,2.6,4.7,5.3.5,17.8-5.2,19.5-3,1-4.8-1.8-9.7-1.3-4.3.5-7.3,3.1-9.1,4.6-7.1,5.8-7.9,1.5-24.1,14.3-6.9,5.5-17.3,13.5-31.2,24"/>
      <path id="rip5r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M17.48,252.57c10.5-6.5,19-11.9,24.7-15.6,14.1-9.2,21.3-13.9,22.7-14.9,7.7-5.6,10.2-8.1,16.3-10.4,2.4-1,9.5-3.2,18.2-2,4.5.7,8.4,1.3,11,4.6,2.8,3.6,3.3,9.3.7,13-2.2,3.2-5.9,3.7-11.7,4.6-7.9,1.3-10.1-.4-15.6.7-4.9,1-7.7,3.2-13,7.1-9.8,7.1,10.8-8.6-23.4,15.6-7.4,5.3-16,11.4-25.4,18.2"/>
      <path id="rip4r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M20.08,211.17c11.8-9.6,24-19.2,37.1-29.3,0,0,5.8-4.5,24.7-13.7,5.1-2.5,10.4-4.9,14.9-2.6,3.1,1.7,4.5,4.9,5.2,6.5.3.7,3.3,8.1,0,12.4-2.1,2.5-5.2,2.2-11,3.2,0,0-4.1.6-17.6,5.2-9.3,3.1-15.4,7.5-25.4,14.9-6,4.4-13.5,10.4-21.5,18.8"/>
      <path id="rip3r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M19.98,166.37c15-9.1,25.5-18,32.5-24.7,7-6.6,12-10.1,22.1-16.9,5.2-3.6,10.6-6.9,16.9-5.9.9.2,7,1.2,8.5,5.2.9,2.4-.6,4-2.6,9.7-3,8.8-1.5,11.6-3.9,13-3.7,2.3-8.2-4.2-16.3-5.2-5.3-.6-10.1,1.5-24.7,12.4-7,5.3-16.2,12.6-26.6,22"/>
      <path id="rip2r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M29.08,120.07c6.4-3.8,11.3-7.3,14.3-9.7,12-9.3,20.2-17.6,20.2-17.6,6.6-6.7,9.3-10.3,13-9.7,3.9.6,6.5,5.4,7.1,6.5,2.4,4.5.6,6.6,3.2,10.4,2.7,4,6,3.6,6.5,6.5.6,3-2.4,7.1-5.8,7.8-4.1.8-5.9-3.9-13-5.9-1.3-.4-6.7-1.7-11.7,0-6.5,2.3-8.2,8.7-13,15.6-3.5,5.2-9.3,11.8-19.5,17.6"/>
      <path id="rip1r" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M31.68,114.97c2.7-3.6,7.1-8.7,13-14.3,4.7-4.5,7.1-6.8,10.4-8.5,10.3-5.3,22.1-3.4,22.1-3.2.1.1-5.7.6-5.8,0-.1-.8,10.7-1.1,13-6.5,1.7-4.3-2.3-9.9-4.6-13-2.7-3.9-3.9-3.5-9.1-9.1-5.7-6.1-6.3-8.5-8.5-8.5-3.3.1-5.5,5.4-8.5,12.4-3.5,8-7.4,15.9-11,24-3.3,7.5-9.7,17.6-23.4,27.9"/>
      <path id="partofrip9" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M17.38,173.37c27.9,12.3,39.2-20.9,13-31.4"/>
      <path id="vertebralbody4" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M158.48,85.67c-1.3,2.3-4.4,2.8-8.7,3.2-9.5,1-15,.2-22.1,0-11.7-.3-14.7,1.4-23.4-.7-8.1-2-9.3-4.4-9.7-5.2-1.8-3.9,2.5-7,1.9-14.3-.4-5.7-3.2-7.5-1.9-9.7,1.3-2,4.3-1.9,16.3-1.3,13.1.7,12.4.7,13.7.7,10.8-.3,12.6-2.9,20.2-1.9,3.6.5,10.3,1.3,12.4,5.8,1.7,3.6-1.4,5.6-.7,12.4.7,6.2,3.4,8.3,2,11h0Z"/>
      <path id="connectiontorip17" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M164.78,614.37s-7-7.9,0-15.5"/>
      <path id="connectiontorip16" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M152.68,621.07c3.9,0,39.6-4,7.1-41.7"/>
      <path id="connectiontorip15" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M146.18,559.87c.8,0-12.5-16.4-2.5-32.8"/>
      <path id="connectiontorip14" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M155.18,559.97c53.3,18.1,30.4-45.5-7.5-29.9"/>
      <path id="partofspine33" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M146.18,616.47c3.5,0,6.4-4.4,6.4-9.9"/>
      <path id="vertebralbody3" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M95.48,510.07c9.6-1,17.8-1,24.1-.7,7,.3,10.5.4,14.3,1.3,6.8,1.5,9.4,3.3,14.3,2.6,1.5-.3,8-1.1,9.7-5.2,1.5-3.6-2.2-5.8-5.2-16.3-1.4-5-2.3-8.4-1.3-12.4,1.2-4.8,4-6.3,3.2-7.8-1.3-2.4-8.8,1.2-20.2,1.3-8.2.1-9.8-1.6-20.8-2.6-8.2-.7-14.9-.4-19.5,0"/>
      <path id="partofspine32" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M90.98,476.17c26.5,0,48-1.6,48-3.5"/>
      <path id="partofspine31" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M84.88,518.27c29.8,0,54.1-2.9,54.1-6.4"/>
      <line id="partofspine30" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="84.78" y1="483.77" x2="89.18" y2="466.27"/>
      <path id="partofspine29" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M91.48,457.67c19,13.8,49.2,11.7,53.8,5.1"/>
      <path id="partofspine28" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M100.18,412.37c23.7,0,42.8,4.6,42.8,10.2"/>
      <path id="vertebralbody2" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M160.38,425.77c2.8,4.1-.9,7.1-1.8,22.7-.9,12,.9,15.8-2,18.2-3.2,2.5-7.2-.5-21.5-4.3-10.2-2.6-18.7-4.9-29.3-4.8-7.9.1-13.4,1.9-14.3,0-.8-1.9,4.4-4.2,8.5-11.4.9-1.7,6.4-11.3,3.1-19.5-1.9-4.8-5.9-6.8-5.1-8.1.7-1.5,5.8.4,11,1.3,8.2,1.3,10.1.6,23.4,1.3,17.2.9,25.7,1.4,28,4.6h0Z"/>
      <line id="partofspine27" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="100.28" y1="407.77" x2="100.28" y2="382.57"/>
      <line id="partofspine26" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="126.58" y1="414.57" x2="163.18" y2="413.37"/>
      <line id="partofspine25" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="163.18" y1="413.37" x2="167.18" y2="389.87"/>
      <line id="partofspine24" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="163.68" y1="376.77" x2="106.68" y2="370.77"/>
      <line id="partofspine23" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="103.68" y1="360.47" x2="167.38" y2="365.97"/>
      <line id="partofspine22" class="rechte_rippen_dv_obl_liegend_links1806_cls3" x1="108.48" y1="322.97" x2="168.98" y2="327.07"/>
      <path id="partofspine21" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M167.18,365.97c3.1.6,5.3-16.4,1.8-38.9"/>
      <path id="connectiontorip13" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M103.48,335.17c15.2,9.3,22.1-1.1,16-24.7"/>
      <path id="connectiontorip12" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M100.28,355.97c2.1,1.3,3.9-6.4,3.2-20.8"/>
      <path id="connectiontorip11" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M193.98,494.07c-2.4,0-14.6-6.3-4.3-20.8"/>
      <path id="connectiontorip10" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M200.08,472.37c-.7,0,13.6,18-1.3,22.7"/>
      <path id="connectiontorip9" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M188.98,449.47c23.2,3.1,31.6-9,20.2-26.6"/>
      <path id="partofspine20" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M170.78,267.87c-2,0-3.6-18.5-3.6-41.3"/>
      <path id="partofspine19" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M108.48,230.87c40.2,7.5,56.7-3.6,56.7-8.2"/>
      <path id="partofspine18" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M110.78,267.97h60"/>
      <path id="partofspine17" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M109.58,261.67c-.5,0-1.1-13.8-1.1-30.8"/>
      <path id="partofspine16" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M112.48,219.47c31.2,3.2,52.7,1.4,52.7,3.2"/>
      <path id="partofspine15" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M170.78,214.97c-3.1,0-5.6-17.4-5.6-39"/>
      <path id="partofspine14" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M112.48,219.47c32.2,0,58.3-2.1,58.3-4.6"/>
      <path id="partofspine13" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M110.38,214.37c-3.8,0-6.8-17.1-6.8-38.2"/>
      <path id="partofspine12" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M103.58,176.07c31.9,0,57.8,0,57.8-.1"/>
      <path id="partofspine11" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M101.88,159.07c32.6,0,58.9,5.1,58.9,11.5"/>
      <path id="connectiontorip8" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M170.18,378.67c6.1,4.7,11.3,6.1,14.9,6.5,4.1.4,5.6-.4,14.3-.7,7.9-.2,11.9-.3,14.3,1.3,4.8,3.2,7.1,10.9,4.6,16.9-2.7,6.5-9.7,8.5-11.7,9.1-7.9,2.2-11.7-1.6-21.5-1.3-4.2.2-10,1.1-16.9,4.6"/>
      <path id="connectiontorip7" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M105.08,382.57c19.7,14.3,31.8-19.5,4-16.6"/>
      <path id="connectiontorip6" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M190.28,331.97c2-.3,5-.8,8.5-1.3,12.9-1.9,15.9-1.7,18.8-.7,2.3.7,5.7,1.9,7.8,5.2,3.4,5.3,1.5,13.8-3.2,16.9-1.8,1.2-3.5,1.3-14.9,0-12.7-1.4-15.2-2-19.5-1.3-5.6.9-9.8,3.4-12.4,5.2"/>
      <path id="partofspine10" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M105.68,317.67c27.4,0,49.5,3.3,49.5,7.4"/>
      <path id="partofspine9" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M167.18,226.67c6.3,4.4,12.9,28.1,3.6,41.3"/>
      <path id="vertebralbody1" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M109.08,305.77c1.6-5.2,2.8-11.3,2.6-18.2-.1-5.1-.9-7.3.7-9.1,2.4-2.9,7.2-.8,20.8,0,7.3.5,10.9.7,14.3,0,7.3-1.3,9.6-4.2,14.9-3.2,5.3.9,8.6,4.7,9.1,5.2,3.2,3.7,2.6,6.9,3.9,21.5,1,11.6,1.8,13.7,0,15.6-2.2,2.3-5.5,1.4-18.2,0,0,0-8.3-.9-21.5-1.9-5-.4-12.4-.8-21.5-.7"/>
      <path id="partofspine8" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M110.68,267.97c26.6,0,48.1,3.2,48.1,7.3"/>
      <path id="partofrip8" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M110.78,268.07c10.8-8.9,31.9,26.2-6.3,13.2"/>
      <path id="partofrip7" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M214.18,288.37c-4.7,0-8.4-6.2-8.4-13.7"/>
      <path id="partofrip6" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M192.18,294.27c22,5.8,51.3-3.3,29.3-20.8"/>
      <path id="partofspine5" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M172.98,210.37c-4.3,0,2.4-17.7-7.8-34.4"/>
      <path id="partofspine4" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M103.58,176.07c3.3,0,6.1,14.9,6.1,33.3"/>
      <path id="partofspine3" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M92.18,68.67c1.2,0-40-12.4,2.2-16.7"/>
      <path id="partofspine2" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M100.28,72.77c5.8,0,10.7-6.6,10.7-15"/>
      <path id="partofspine1" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M105.78,103.57c-2.1,0,9.7-6.2-3.9-22.5"/>
      <path id="partofrip5" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M179.78,91.57c5.5,0,9.9-9.3,9.9-20.8"/>
      <path id="connectiontorip5" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M160.78,97.37c8.7,0,15.6-3.6,15.6-8"/>
      <path id="connectiontorip4" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M155.18,70.77c10.8,0,19.5,1.4,19.5,3.2"/>
      <path id="partofrip4" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M196.88,152.07c-.7,0,30.3-12.5-1.3-18.3"/>
      <path id="partofrip3" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M196.78,164.17c13.9-6.5,32.5,5.1,20.1,27.9"/>
      <path id="partofrip2" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M201.18,226.77c4.9,0,0,11,8.8,14.7"/>
      <path id="partofrip1" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M216.28,222.87c9.8-8.5,13,11.2,9.8,18.6"/>
      <path id="connectiontorip3" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M172.98,202.57c6.5,0,11.7-3.1,11.7-7.1"/>
      <path id="connectiontorip2" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M179.78,134.67c-7,0-12.6-5.3-12.6-11.9"/>
      <path id="connectiontorip1" class="rechte_rippen_dv_obl_liegend_links1806_cls3" d="M164.98,109.67c8.1,0,14.8.3,14.8.6"/>
      <path id="soft" class="rechte_rippen_dv_obl_liegend_links1806_cls2" d="M314.68,11.97c15,12,51.98,3.39,63,5,13.65,1.99,23.26,3.39,30,9,10.66,8.86,10.37,25.08,8,59-2.69,38.43-4.04,57.65-6,69-7,40.46-11.23,81.35-17,122-4.1,28.93-6.74,69.62-12,151-6.55,101.23-3.96,91.62-10,185-1.03,15.89-3.35,50.92-4,98-.29,21.19-.16,38.59,0,50"/>
    </g>
  </svg>
  );
}
