import {
  addFacilityID,
  addNewPrice,
  replaceNewPrices,
  addPriceInfo,
  addDefaultPrices,
  emptyNewPrices,
} from "../../actions";

export const mapStateToProps = (state) => ({ pricelists: state.pricelists });
export const mapDispatchToProps = {
  addFacilityID,
  addNewPrice,
  replaceNewPrices,
  addPriceInfo,
  addDefaultPrices,
  emptyNewPrices,
};
