import style from "./style.scss";

function RechtesHandgelenkLatSitzend1360(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 229.19 500.9">
      <g id="Backround">
        <path
          id="backround3"
          class="Handgelenk_lat_rechts1360_cls6"
          d="M152.92,499.81l.02.04,53.39,1.05c-.31-29.65,12.4-93.24,21.94-136.16,2.25-16.29-3.81-22.98-12.22-26.97l-8.36-2.23-.95-4.94c1.79-1.09,3.63-3.58,5.52-7.26,4.21-6.72,4.3-14.66,2.73-23.1-2.01-5.39-5.04-8.91-9.04-10.65v-3c3.17-4.83,3.27-10.64,2.44-16.74,2.35-5.72,2.7-11.56,1.21-17.5l6.48-12.42v-11.1c4.75,1.86,6.29-2.65,4.93-12.88l-2.89-15.69-5.87-34.01c.51-35.14-3.94-74.58-11.09-116.35l.68-11.24c-.54-2.7-1.83-6.01-3.42-9.57.8-2.51.82-5.17,0-7.99L194.01.07l-11.54,3.06-8.81-1.22h-20.36s-17.62.91-17.62.91c-.17,7.71-.15,15.28,4.61,19.39,3.46,2.68,7.16,1.74,11.02-1.77l4.89-2.24c-.93,2.67-1.17,5.34-.71,8-7.05,2.02-11.59,6.69-12.67,15.05.98,2.68.71,5.77-1.2,9.42-2.98,4.11-2.09,9.25,6.94,16.53-4.72,4.35-5.68,10.43-4.79,17.35,9.07,23.02,14.82,47.09,17.46,72.15.57,12.01-4.41,24.68-12.51,39.56-2.5,6.17-2.75,12.24-.51,18.22l-5.91-1.59c-5.51-10.52-12.7-16.91-26.82-19.41-17.99-15.98-33.42-31.51-40.06-45.5-2.89-7.92-5.86-14.75-9.3-15.59-3.23-1.21-5.62-3.71-7.04-7.66l-2.23-1.97c1.68-4.46,1.78-8.98-.57-13.61-20.2-17.33-30.9-37.9-34.87-60.76,1.81-3.28.74-7.09-2.8-11.35-.56-3.32-2.47-5.24-6.18-5.29,6.59-8.64,5.27-14.72-2.1-18.82l-3.32-3.73-2.43,28.86L.48,85.6c3.96,15.64,6.51,31.61,7.09,48.05,1.07,4.01,3.07,6.73,7.23,6.48,3.14-.26,7.65-3.4,12.13-6.48-3.35,4.57-6.46,8.8-9.33,12.71-2.45,5.17-.47,10.2,6.31,15.08-1.95,7.41,1.77,14.39,15.18,20.61,17.9,14.78,33.77,30.08,44.36,46.78,3.24,2.84,5.17,6.36,5.65,10.63-.54,2.83-.5,5.33.88,7.09,4.68,4.39,9.47,6.69,14.42,6.28,1.85-.17,3.64.64,5.35,2.37-3.77,6.57-5.82,12.7-4.06,17.81,2.52,6.31,6.52,10.8,14.12,10.92,1.18,4.57,2.4,8.86,4.22,9.07-1.42,3.5-1.82,7.1,0,10.93,4.8,5.78,12.06,6.71,21.7,9.81-.21,5.75.75,11.1,5.18,15.23l7.09,6.54c-3.08.07-4.44,2.44-5.47,5.26-6.82-.62-10.8.6-9.49,5.25-6.3.38-9.06,2.87-4.73,9.57-2.3,5.99-1.63,12.78,3.29,20.72.38,5.94,3.05,13.18,6.68,20.96,5.26,5.17,8,13.01,7.35,24.46l-2.71,78.08h0Z"
        />
        <ellipse
          id="backround2"
          class="Handgelenk_lat_rechts1360_cls6"
          cx="138.87"
          cy="75.44"
          rx="4.78"
          ry="3.36"
          transform="translate(10.49 167.6) rotate(-64.19)"
        />
        <ellipse
          id="backround1"
          class="Handgelenk_lat_rechts1360_cls6"
          cx="134.92"
          cy="39.41"
          rx="3.95"
          ry="4.56"
        />
      </g>
      <g id="Handlat">
        <g id="Overview">
          <path
            id="radius3"
            class="Handgelenk_lat_rechts1360_cls3"
            d="M206.31,500.86c1.09-27.04,6.77-62.52,13.67-100.24l7.29-31.29c3.75-14.04.08-24.61-11.24-31.59-8.75-3.4-17.36-2.92-25.82,1.82-15.03,4.45-24.48,1.91-31.29-3.95-3.21-.54-5.39,1.07-6.38,5.16-1.6,4.61-4.74,6.55-9.11,6.38-6.98.2-8.99,2.93-4.86,8.81-2.38,6.51-.35,13.38,3.04,20.35.72,7,3.22,13.98,6.68,20.96,5.12,6.5,7.81,15.46,7.29,27.65l-2.43,75.94"
          />
          <path
            id="radius1"
            class="Handgelenk_lat_rechts1360_cls3"
            d="M216.04,337.74c-7.62.89-14.03,3.85-19.58,8.29"
          />
          <path
            id="ulnar2"
            class="Handgelenk_lat_rechts1360_cls3"
            d="M207.85,479.95c-2.83-28.08-1.03-63.56,3.93-104.08,2.51-10.91,2.75-19.24,0-24.15-4.04-6.91-10.26-6.95-17.01-5.32-10,5.04-23.08,5.34-38.43,2.13-5.31-.29-8.68,3.39-10.48,10.33-1.88,8.85-2.62,16.86.76,21.87,5.11,6.94,8.07,14.82,10.07,23.11,1.53,6.35,2.25,12.86,2.37,19.39l1.38,76.58"
          />
          <path
            id="radius2"
            class="Handgelenk_lat_rechts1360_cls3"
            d="M152.92,339.49c-8.17.33-11.53,2.8-9.5,7.67-2.76,2.49-4.31,5.45-4.86,8.81"
          />
          <path
            id="ulnar1"
            class="Handgelenk_lat_rechts1360_cls3"
            d="M208.35,347.72c.76-16.57-3.59-22.2-11.15-20.92-7.49,2.15-9.42,20.92-14.64,23.28"
          />
          <g id="Fingers">
            <path
              id="partoffinger23"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M133.58,248.88h0c6.32-4.78,10-10.84,11.77-17.82,2.75-7.5,1.06-14.94-6.78-22.28-4.67-7.84-11.43-12.15-19.64-14-2.13-.48-4.11-1.46-5.7-2.95-16.56-15.48-28.34-28.29-35.2-38.36-1.01-1.48-1.8-3.1-2.37-4.8-2.06-6.15-4.3-11.6-7.06-14.93-.62-.75-1.52-1.19-2.48-1.37-3.22-.6-4.85-2.64-5.94-5.16-1.17-4.46-4.66-6.08-10.48-4.86-13.17.55-21.06,6.44-25.37,15.95l-5.01,5.77c-3.31,3.66-5.04,7.99,3.07,16.57.91.96,1.42,2.23,1.45,3.55.15,6.56,2.99,12.46,12.92,16.65,1.75.74,3.38,1.75,4.85,2.96,16.07,13.22,30.66,28.12,42.57,46.09,3.59,3.98,5.54,8.53,5.15,13.87-.1,1.44.42,2.87,1.54,3.8,4.39,3.66,8.97,6.37,14.3,5.11,1.78.13,3.28.59,4.07,1.86.34.56.81,1.03,1.43,1.23,7.72,2.44,15.36.26,22.9-6.88h0Z"
            />
            <path
              id="partoffinger22"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M54.41,173.08c4.56,15.64,31.44,42.68,36,61.82"
            />
            <path
              id="partoffinger21"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M136.43,210.6c-5.17-6.15-11.27-9.37-17.84-11.09-5.33-.57-10.43-3.71-15.42-8.05l-35.85-33.27"
            />
            <ellipse
              id="partoffinger20"
              class="Handgelenk_lat_rechts1360_cls4"
              cx="25.31"
              cy="145.52"
              rx="7.21"
              ry="4.94"
              transform="translate(-55.19 21.68) rotate(-23.16)"
            />
            <path
              id="partoffinger19"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M23.25,146.34c-5.33,4.79,2.86,9.58.71,14.37-.24.53.02,1.17.58,1.32,3.14.86,6.19,4.4,9.21,8.77"
            />
            <path
              id="partoffinger18"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M.48,85.59c3.97,14.07,6.62,29.29,6.96,46.5.04,2.08.65,4.15,1.94,5.77,2.75,3.44,6.75,3.09,12.03-1.08,6.46-4.34,13.61-6.71,21.64-6.59,2.29.03,4.59-.34,6.75-1.13,5.62-2.07,8.08-6.24,8.1-12.03.46-2.14.27-4.17-.56-6.09-.59-1.35-1.54-2.51-2.63-3.5-21.03-19.14-29.86-38.42-33.14-57.76-.1-.6-.06-1.22.15-1.8.98-2.7.94-5.11-.93-7.01-.64-.65-1.18-1.4-1.5-2.25-2.23-5.95-5.03-6.7-8.02-5.59-2.59,2.08-5.02,3.91-6.68,5.01"
            />
            <path
              id="partoffinger17"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M37.1,92.12c-6.68-4.73-12.76-11.76-18.23-21.18-1.23-2.13-2.81-4.05-4.71-5.62-3.34-2.76-4.57-7.76-5.01-13.61"
            />
            <path
              id="partoffinger16"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M5.89,111.61c2.18-15.32-4.65-33.16-1-51.24"
            />
            <path
              id="partoffinger15"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M51.83,118.1c-14.36-1.17-24.6,5.75-31.39,19.42"
            />
            <path
              id="partoffinger14"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M50.62,113.53c-12.15.88-22.26,5.74-30.18,14.89"
            />
            <path
              id="partoffinger13"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M7.01,9.19c1.07,1.78,2.4,3.4,4.64,4.52,1.76.87,3.25,2.22,4.05,4.01,2.12,4.74.81,9.83-4.43,15.31"
            />
            <path
              id="partoffinger12"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M175.48,224.27h0s21.87-4.86,21.87-4.86c7.97.06,14.25,3.12,18.72,9.4,4.5,1.86,6.43-1.99,4.83-13.66l-8.04-42.99c-.85-4.55-1.84-9.07-2.96-13.56-5.1-20.48-7.88-58.82-10.83-95.81-.11-1.39-.35-2.77-.68-4.13-1.06-4.37-.67-8.48-.12-12.57,1.52-9.25-6.94-16.16-20.51-21.87-5.66-3.06-11.76-.5-18.23,6.68-9.03,7.34-10.24,14.26-4.56,20.81,10.42,21.72,18.65,43.57,23.7,65.62,3.46,36.41.29,65.84-9.85,87.94-1.54,3.34-2.42,6.98-2.29,10.66.22,6.17,2.93,9.27,8.94,8.34h0Z"
            />
            <path
              id="partoffinger11"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M159.54,221.96c.85-3.15-.22-7.1-1.98-11.35-2.86-7.11.69-14.19,4.56-21.26,5.59-7.24,7.38-26.13,8.35-47.55,1.81-19.57-2.38-38.02-10.18-55.8-5.33-9.22-7.94-17.38-8.05-24.56,1.21-7.16,4.03-13.21,9.42-17.47,8.85-5.58,18.14-5.35,27.95,2.13,8.12,5.95,9.29,25.36,9.42,46.78l3.49,77.92c-.44,9.94.86,19.3,4.25,27.95,3.3,9.55,1.29,16.8-4.07,22.62-3.32,5.55-11.62,6.59-22.87,4.96"
            />
            <path
              id="partoffinger10"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M171.58,224.22c-3.9,3.18-8.99,3.46-14.48,2.8-9.01,2.82-7.16-6.86-9.72-16.43-2.52-7.84,2.14-17.47,7.75-27.34,7.04-13.9,7.48-29.11,3.95-45.11-3.72-17.58-8.02-33.76-13.33-47.52-1.7-4.42-2.41-9.2-1.7-13.89.78-5.2,2.99-9.3,7.47-11.65,7.84-3.59,15.71-5.12,23.65-1.97,9.7,1.41,10.14,21.7,10.34,49.37l1.66,37.06c1.68,20.83,4.76,40.78,13.52,57.11,1.98,5.35,1.68,9.9-3.34,12.76"
            />
            <path
              id="partoffinger9"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M212.23,169c.01-41.46-3.33-81.33-11.09-119.12l.68-11.24c-2.16-10.19-8.47-15.82-18.83-17.01-8.56-1.28-16.76-.25-24.69,2.58-9.37,3.83-13.89,9.78-15.49,17.01,1.08,4.27.31,8.14-1.97,11.7-2.67,5.19,4.59,12.36,15.19,20.2,12.14,16.34,18.91,36.7,19.75,61.52,2.54,17.99-.69,34.19-5.73,49.75-4.27,4.55-4.91,11.67-5.21,19.59l-2.13,7.22c.11,8.51,1.86,13.96,7.34,12.46"
            />
            <path
              id="partoffinger8"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M205.92,220.88c7.65.76,10.88-7.88,12.18-20.63"
            />
            <path
              id="partoffinger7"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M182.47,3.11c-1.48,12.18-2.17,23.48-.76,32.51,1.23,6.97.15,13.49-5.44,19.14-6.88,5.09-14.84,7.46-24.03,6.68-8.78-.33-9.4-5.14-7.14-11.54l3.27-7.11c1.75-3.8,3.77-7.47,6.12-10.93,6.48-9.54,9.94-19.54,10.66-29.97"
            />
            <path
              id="partoffinger6"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M166.65,2.8c-3.11,4.12-6.07,8.44-8.8,13.07-1.83,3.11-2.77,6.72-2.37,10.31.85,7.52,6.54,12.08,16.65,13.91,8.27.26,15.67.09,19.43-1.86.81-.42,1.42-1.14,1.77-1.98,2.41-5.72,6.95-6.45,5.08-15.16l-4.25-19.2"
            />
            <path
              id="partoffinger5"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M194.01.05c-.62,6.68-1.87,11.56-4.25,13.22-.11,5.4-2.47,7.4-5.92,7.76l-18.36,1.15c-5.5.16-9.15-1.44-10.96-4.8-4.94-3.21-3.21-9.29-1.22-15.49"
            />
            <path
              id="partoffinger4"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M173.64,1.89c2.62,7.58,1.75,12.82-5.16,13.97-6.76.06-12.31,1.83-17.18,4.56-6.07,4.61-10.01,4.5-12.28.68-3.26-4.16-3.57-10.99-3.34-18.3"
            />
            <ellipse
              id="partoffinger2"
              class="Handgelenk_lat_rechts1360_cls3"
              cx="134.92"
              cy="39.41"
              rx="3.95"
              ry="4.56"
            />
            <ellipse
              id="partoffinger1"
              class="Handgelenk_lat_rechts1360_cls1"
              cx="138.87"
              cy="75.44"
              rx="4.78"
              ry="3.36"
              transform="translate(10.49 167.6) rotate(-64.19)"
            />
            <path
              id="partoffinger3"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M188.86,17.76c-10.88-1.71-21.77-1.11-32.66,1.79"
            />
          </g>
          <g id="Carpalbones">
            <path
              id="carpalbone9"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M199.04,335.51c14.07-6.86,17.74-17.55,16.94-29.87-.21-3.13-.98-6.22-2.52-8.95-3.07-5.44-7.45-7.41-12-8.93-11.65-1.34-19.78-1.12-25.1-2.71-3.04-.9-5.47-3.2-6.74-6.1l-4.11-9.38c-.93-2.12-2.95-3.6-5.26-3.75-6.8-.44-13.17,1.71-19.25,5.69-9.72,6.82-14.74,14.61-17.42,22.88-.62,1.91-.9,3.94-.64,5.93.64,4.9,3.98,8.11,10.04,9.6,3.29.8,6.58,1.62,9.81,2.65l.43.14c2.74.88,5.22,2.39,7.25,4.42,3.85,3.85,10.36,10.36,14.05,14.05,2.33,2.33,5.08,4.2,8.15,5.37,4.14,1.58,8.3,2.21,12.48,2.13,6.68-.13,12.94-3.25,17.16-8.43,5.62-6.91,9.26-15.56,8.72-27.9-.04-2.96-1.41-4.19-3.8-4.08-.98.05-1.93.35-2.82.76-5.91,2.75-11.48,4.61-16.46,5-8.83,1.6-16.06-1.15-22.65-5.66-1.84-1.25-3.34-2.9-4.72-4.64-1.58-2-3.74-1.93-6.22-.76-3.17,1.5-5.52,4.32-6.7,7.62-1.38,3.85-1.9,8.31-1.9,13.16,0,6.62,1.14,12.39,5.69,15.63,1.87,1.33,3.73,2.7,5.4,4.28l2.08,1.96c2.16-2.32,3.32-9.23,7.48-17.5.87-1.74,2.06-3.34,3.61-4.53,2.14-1.65,4.6-2.42,7.43-2.11,3.63.4,6.78,2.65,8.66,5.78,4.83,8.02,10.09,14.92,16.71,18.35"
            />
            <path
              id="carpalbone8"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M157.4,268.33h0l7.75,21.68c.94,2.63,2.5,5.01,4.62,6.82,7.21,6.15,15.49,6.41,24.69,1.58,5.39-3.02,9.27-7.89,12.49-13.58,1.79-3.16,2.25-6.86,1.46-10.4-1.34-6-1.42-12.27-.73-18.7l6.25-11.09c1.4-2.49,2.14-5.3,2.14-8.15v-2.77c0-3.17-1.47-6.17-3.99-8.1-3.47-2.66-7.38-4.1-11.62-4.62-2.62-.32-5.3.1-7.69,1.22-6.7,3.15-15.35,4.85-24.62,6.08-1.52.2-3.04.29-4.57.33-3.84.09-7.08,1.83-9.52,5.82-7.37,8.12-4.02,20.24,3.34,33.87h0Z"
            />
            <path
              id="carpalbone7"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M168.15,228.29v.02l-12.26-9.04c-6.97-6.13-14.53-7.48-22.48-5.62-6.64,2.9-10.28,8.81-11.43,17.21-.53,3.85.25,7.8,2.31,11.1.53.86,1.1,1.66,1.71,2.41,3.31,4.05,8.73,5.61,13.81,4.37,4.43-1.09,9.31-1.12,14.86.44,11.17,6.97,5.96,42.06,10.61,49.17,4.24,8.02,11.21,12.75,23.58,10.98,5.86-1.67,10.27-4.81,12.81-9.82.81-1.59,1.31-3.31,1.65-5.06l4.49-23.27c2.72-6.28,3.51-12.57,1.77-18.85-.69-12.15-2.35-21-6.31-21.99l-6.38-1.03c-4.59-2.61-8.79-1.17-12.91,1.03-4.07,3.03-9.71,1.25-15.84-2.05h.01Z"
            />
            <path
              id="carpalbone6"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M189.75,263.01h0c-7.27-4.92-14.77-5.61-22.48-2.73-5.4,2.12-8.16,7.51-8.2,16.25,0,11,2.18,20.82,7.09,29.16,5.73,7.67,12.43,12.7,20.83,13.02,8.84-1.42,14.49-5.09,15.83-11.81.97-4.83,1.87-9.37,2.76-13.82v-10.3c-6.92-4.74-11.8-11.78-15.82-19.78h0Z"
            />
            <path
              id="carpalbone5"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M106.26,262.27h0c-1.43,4.12-1.53,8.66.06,12.73.94,2.4,2.37,4.44,4.42,5.99,1.65,1.24,3.63,1.97,5.66,2.36,12.36,2.37,24.27,1.48,35.52-4.1,13.37-6.35,23.87-15.66,31.07-28.39,2.14-4.16,2.8-7.27,2.4-9.6-.51-2.92-3.23-4.86-6.19-4.92-5.96-.12-12.18-2.73-18.47-6-6.56-2.56-12.54-2.02-17.77,2.43-3.35,5.18-10.55,9.61-18.83,13.84-2.18,1.11-4.41,2.14-6.75,2.84-5.37,1.61-8.84,6.26-11.11,12.82h0Z"
            />
            <path
              id="carpalbone4"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M95.58,241.73c2.66,2.04,5.76,3.5,9.66,3.92,3.22.35,6.48-.16,9.53-1.27,7.46-2.72,14.66-6.3,21.19-12.04,1.43-1.25,2.63-2.75,3.5-4.44,3.17-6.16,2.81-12.54-.9-19.12"
            />
            <path
              id="carpalbone3"
              class="Handgelenk_lat_rechts1360_cls3"
              d="M216.07,228.81c1.03-2.75,1.08-5.5.16-8.25-1.14-3.4-4.75-5.38-8.18-4.35-1.99.6-4.07,1.86-6.22,3.58"
            />
            <path
              id="carpalbone2"
              class="Handgelenk_lat_rechts1360_cls2"
              d="M119.81,283.92v-.02c2.29,7.64,4.76,13.98,7.53,18.11,2.39,3.56,6.7,5.3,10.88,4.39l11.71-2.55c5.41-1.68,7.84-6.01,7.41-12.85-.1-1.69-.21-3.38-.25-5.08-.19-8.69-2.1-16.09-7.16-21.1-5.83-5.92-12.61-5.48-19.87-1.82-8.78,4.41-12.34,11.31-10.24,20.92h-.01Z"
            />
            <path
              id="carpalbone1"
              class="Handgelenk_lat_rechts1360_cls2"
              d="M200.26,261.28l9.17-18.68c.8-1.64,1.34-3.4,1.57-5.21.79-6.29-.12-11.36-5.06-13.49-6.35-2.63-14.23-1.35-23.36,3.12-5.2,3.38-8.22,9.04-7.39,18.73,1.11,7.02,2.26,14,5.23,18.95,1.39,2.33,3.84,3.85,6.53,4.16,5.52.65,10.12-1.38,13.29-7.58h.02Z"
            />
          </g>
        </g>
        <g id="Highlights">
          <path
            id="radiushighlight"
            class="Radius1360Fill"
            style={{
              fill: props.colors.Radius1360Fill,
              stroke: props.colors.Radius1360Fill,
            }}
            d="M206.31,500.86c1.09-27.04,6.77-62.52,13.67-100.24l7.29-31.29c3.75-14.04.08-24.61-11.24-31.59-8.75-3.4-17.36-2.92-25.82,1.82-15.03,4.45-24.48,1.91-31.29-3.95-3.21-.54-5.39,1.07-6.38,5.16-1.6,4.61-4.74,6.55-9.11,6.38-6.98.2-8.99,2.93-4.86,8.81-2.38,6.51-.35,13.38,3.04,20.35.72,7,3.22,13.98,6.68,20.96,5.12,6.5,7.81,15.46,7.29,27.65l-2.43,75.94"
          />
          <path
            id="ulnarhighlight"
            class="Ulna1360Fill"
            style={{
              fill: props.colors.Ulna1360Fill,
              stroke: props.colors.Ulna1360Fill,
            }}
            d="M206.31,500.86l1.54-20.91c-2.83-28.08-1.03-63.56,3.93-104.08,2.51-10.91,2.75-19.24,0-24.15-4.04-6.91-10.26-6.95-17.01-5.32-10,5.04-23.08,5.34-38.43,2.13-5.31-.29-8.68,3.39-10.48,10.33-1.88,8.85-2.62,16.86.76,21.87,5.11,6.94,8.07,14.82,10.07,23.11,1.53,6.35,2.25,12.86,2.37,19.39l1.38,76.58"
          />
        </g>
      </g>
    </svg>
  );
}

export default RechtesHandgelenkLatSitzend1360;
