import style from "./style.scss";

function MittelhandLinksDp(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 386.96 488.24">

<g id="hand">
  <g id="fore_arm_hand_joints" data-name="fore arm hand joints">
    <path id="fore_arm_hand_joints_r" data-name="fore arm hand joints r" class="mittelhand_links_dp1331_cls4" d="M114.06,486.05l-.63-27.78c-1.53-13.92-4.62-26.42-9.33-37.56-.8-1.9-1.32-3.91-1.36-5.97-.2-9.36,4.14-12.99,10.69-13.91,1.35.51,2.86,1.93,5.09,7.09.97,2.25,3.46,3.43,5.82,2.76,7.98-2.26,15.6-4.19,21.61-4.7,1.84-.15,3.69.34,5.17,1.45,7.07,5.33,11.07,13.12,12.05,23.26.42,4.33-.83,8.7-3.62,12.03-4.26,5.09-7.69,11.47-10.4,18.96-1.75,4.85-2.25,10.07-1.57,15.18l1.24,9.28"/>
    <path id="fore_arm_hand_joints_l" data-name="fore arm hand joints l" class="mittelhand_links_dp1331_cls4" d="M183.03,488.09c-1.89-23.23-5.18-45.91-18.68-64.82-5.68-10.41-3.8-17.36.8-23.02,1.51-1.86,3.81-2.9,6.21-2.94,19.38-.31,38.44.34,57.07,2.21,2.37.24,4.76-.05,7-.86,11.65-4.23,17.32-8.04,21.49-10.24,5.11-2.7,11.53-.44,13.67,4.92,2.56,6.44,3.62,15.54,3.92,25.98.12,4.17-.97,8.29-3.18,11.83-7.86,12.61-12.49,30.17-11.24,56.86"/>
  </g>
  <g id="wrist_bones" data-name="wrist bones">
    <path id="wrist_bones_8" data-name="wrist bones 8" class="mittelhand_links_dp1331_cls4" d="M128.96,356.57h0c-6.51,5.89-13.65,8.4-23.38-7.61-8.87-18.75-2.04-24.32,6.98-28.09-.78,8.32-1.18,16.09,1.57,20.25,2.04,2.98,8.32,9.03,14.83,15.46h0Z"/>
    <path id="wrist_bones_7" data-name="wrist bones 7" class="mittelhand_links_dp1331_cls4" d="M210.5,385.31h-.08c1.72.86,11.3,11.69,6.98,8.24-6.17-5-24.46.12-37.01-1.21-1.52-.16-2.98-.65-4.28-1.45-9.06-5.6-17.74-13.4-26.27-22.3,5.73-10.83,10.75-15.3,17.5-17.5,23.38-5.26,33.11,2.04,37.82,11.77.55,8.86,1.1,17.65,5.34,22.44h0Z"/>
    <path id="wrist_bones_6" data-name="wrist bones 6" class="mittelhand_links_dp1331_cls4" d="M249.66,324.64h0v.08c-13.03-1.72-25.98-3.45-25.9-8.55-8.24-8.01-9.18-21.19-13.26-31.94,2.51,4.71,7.69,4.47,14.2,1.88,8.56-14.6,18.99-14.6,31.94,3.45,7.22,14.44,6.91,26.68-6.98,35.08h0Z"/>
    <path id="wrist_bones_5" data-name="wrist bones 5" class="mittelhand_links_dp1331_cls4" d="M214.89,392.26h0c-8.56-9.2-11.94-19.84-10.92-31.63,28.56-4.07,24.61-22.76,19.78-41.84,6.33,1.36,12.58,3.11,18.67,4.87,10.29,2.87,20.26,6.47,20.18,9.58,20.01,18.12,15.98,27.07-1.27,30.9-4.57,25.02-20.61,31.08-40.56,30.71-2.23-.04-4.37-.96-5.89-2.59h0Z"/>
    <path id="wrist_bones_4" data-name="wrist bones 4" class="mittelhand_links_dp1331_cls4" d="M172.36,348.89c-10.44,1.02-64.66-31.94-64.9-45.12-1.17-25.98,43.63-30.29,48.97-23.7,28.72,28.33,28.25,52.03,15.93,68.82h0Z"/>
    <path id="wrist_bones_3" data-name="wrist bones 3" class="mittelhand_links_dp1331_cls4" d="M146.82,370.15h0c-5.02,2.9-10.52-3.77-19.54-14.6-7.14-8.86-24.72-18.04-16.48-34.68,14.91-1.1,31.23,20.4,51.79,27.15,1.34,4.79-13.1,12.4-15.77,22.13h0Z"/>
    <path id="wrist_bones_2" data-name="wrist bones 2" class="mittelhand_links_dp1331_cls4" d="M248.71,325.28h.08c18.13-12.09,13.19-24.17,7.93-35.71,7.69.39,12.32-4.32,15.46-11.38,3.69-5.1,10.12,2.51,10.12,2.51-2.19,3.69,2.28,18.91,14.52,19.93,8.24,6.12,9.02,17.03,5.02,31-19.39,11.69-34.69.7-53.13-6.36h0Z"/>
    <path id="wrist_bones_1" data-name="wrist bones 1" class="mittelhand_links_dp1331_cls4" d="M205,360.58c-10.2-11.38-19.22-11.14-32.72-11.77,13.65-21.43,10.35-43.95-15.15-68.04,4.47-1.73,7.85-5.57,10.36-11.46,1.57,4.32,4.08,7.22,7.93,7.93l15.15-6.67c6.9,2.44,13.57,6.83,19.93,13.58,3.69,15.62,7.92,27.94,13.26,31.94l.94,3.45c5.65,20.95,5.18,38.29-19.62,41.12h0l-.08-.08h0Z"/>
  </g>
  <g id="thumb_bones" data-name="thumb bones">
    <path id="thumb_bones_2" data-name="thumb bones 2" class="mittelhand_links_dp1331_cls4" d="M352.66,56.82c-3.05,4.65-3.66,9.7.5,15.53.98,1.37,1.42,3.07,1.23,4.75-2.2,19.36-5.25,37.31-10.7,51.01-2.43,5.1-11.62,11.77-12.95,18.05-.08,7.3,3.46,11.77,8.87,14.83v.08h-.08c8.95-2.74,15.62,3.3,21.19,12.95,12.64-2.99,13.26-10.75,9.81-20.25-1.65-13.19,2.51-32.26,8.55-53.13,2.59-6.56,4.84-12.83,6.59-19.15"/>
  </g>
  <g id="ringer_finger_bones" data-name="ringer finger bones">
    <path id="ringer_finger_bones_3" data-name="ringer finger bones 3" class="mittelhand_links_dp1331_cls4" d="M107.35,15.72c6.07,20.82,13.32,34.53,15.15,44.74.37,2.01,1.73,3.66,3.53,4.64.03.02.06.03.09.05,3.37,1.88,5.21,5.65,5.13,9.51-.21,10.48-3.98,14.46-10.53,13.48-12.48-6.6-23.7-5.89-33.82,1.88-3.45,1.57-5.81-2.04-7.61-8.87-1.94-2.09,0-10.89,2.69-20.91.47-1.76.34-3.63-.38-5.3-4.16-9.62-6.99-21.14-8.81-34.09"/>
  </g>
  <g id="middlefinger_bones" data-name="middlefinger bones">
    <path id="middlefinger_bones_3" data-name="middlefinger bones 3" class="mittelhand_links_dp1331_cls4" d="M154.94,5.47l.45,18.5c-6.51,11.69-11.22,22.68-8.24,30.37,2.51,4.47,6.91,4.16,12.95-.63,7.92-6.12,18.67-4.71,31.62,2.51,6.59,2.43,10.12-1.89,10.12-13.58-6.34-11.67-10.79-24.07-13.59-37.1"/>
  </g>
  <g id="index_finger_bones" data-name="index finger bones">
    <path id="index_finger_bones_3" data-name="index finger bones 3" class="mittelhand_links_dp1331_cls4" d="M276.22,10.44l-.05,9.57c8.48,23.07,11.15,40.41,1.26,45.51h0v-.08c-13.26-9.18-24.48-9.26-34.76-5.65-5.72-2.43-7.69-9.42-6.98-19.62,4.44-7.73,6.93-17.8,8.4-29.09"/>
  </g>
  <g id="small_finger_bones" data-name="small finger bones">
    <path id="small_finger_bones_3" data-name="small finger bones 3" class="mittelhand_links_dp1331_cls6" d="M20.42,46.96c6.16,32.37,34.33,56.88,40.11,71.91,1.4,3.65-1.6,7.68-5.47,7.1-.67-.1-1.27-.32-1.76-.69-4.79-3.61-10.36,2.12-14.52,7.69h0l-.08-.08c1.49,5.02-8.48,5.26-10.44,3.14-1.7-.99-3.57-11.01-3.64-20.05-.02-2.87-.81-5.66-2.16-8.19-4.47-8.36-13.04-32.78-21.7-53.08"/>
  </g>
  <g id="middle_hand_bones" data-name="middle hand bones">
    <path id="middle_hand_bones_9" data-name="middle hand bones 9" class="mittelhand_links_dp1331_cls4" d="M296.76,300.65v-.08c-8.09-2.51-16.09-4.95-14.52-19.62-5.33-6.2-8.71-4.87-10.12-2.83,1.02-7.46.55-13.74.31-16.09,4.78-6.36,10.43-9.97,17.73-8.24,14.98-14.46,23.96-34.14,28.43-59.52.39-2.21.09-4.49-.81-6.55-1.09-2.52-1.63-4.58-1.79-6.35-.21-2.36.65-4.69,2.32-6.37,4.34-4.39,9.51-8.43,15.09-12.17,7.58-5.17,16.97-3.59,23.45,2.57s8.29,15.8,5.02,24.12c-6.23,15.86-12.57,31.09-20.24,38.76l-12.32,47.71c-3.93,16.71-8.56,25.27-32.57,24.64h0s.02.02.02.02Z"/>
    <path id="middle_hand_bones_8" data-name="middle hand bones 8" class="mittelhand_links_dp1331_cls4" d="M121.13,259.82h0c.06-2.27.22-4.57.46-6.9.84-11,.79-21.84-1.38-32.61-.12-.6-.28-1.2-.46-1.79-7.26-23.36-13.77-41.77-19.97-57.58-10.46-23.51-16.01-40.19-16.67-49.75-.03-.52-.09-1.05-.17-1.57-.67-4.53-.08-8.99,1.33-13.4,1.63-5.1,5.47-9.25,10.51-11.02,5.65-1.99,12.1-1.86,19.33.32,4.34,1.31,7.92,4.39,10.05,8.39,2.4,4.51,4.38,9.28,5.9,14.29.46,1.53.72,3.11.76,4.71l.56,24.22c.02.49.05.97.1,1.46,1.04,9.52,2.95,24.24,4.94,40,1.64,10.39,8.91,29.59,16.49,49.57,1.29,3.38,3.65,6.21,6.62,8.27.08.05.15.11.23.16,4.84,3.44,7.59,9.23,7.03,15.14-.1,1,.03,2.4.47,4.34.31,1.37.44,2.79.4,4.2l-.25,9.04c.79,2.43-11.45,14.67-11.06,10.75.25-2.23-8.95-2.85-17.73-2.73-9.74.14-17.79-7.78-17.5-17.52h.01Z"/>
    <path id="middle_hand_bones_7" data-name="middle hand bones 7" class="mittelhand_links_dp1331_cls4" d="M82.97,110.41c12.8-21.82,28.18-19.31,48.03.39"/>
    <path id="middle_hand_bones_6" data-name="middle hand bones 6" class="mittelhand_links_dp1331_cls4" d="M175.5,277.25h0v.08c-5.18-.95-8.24-3.22-7.93-7.93.25-4.86-1.12-15.38.63-18.84,1.01-1.99,1.62-4.16,1.43-6.38l-.17-2.01c6.52-15.15,7.46-34.29,3.77-56.58-12.63-43.23-29.42-93.69-26.52-110.25,2.66-8.87,7.06-16.25,12.63-22.76,9.16-5.01,19.85-3.55,31.62,2.51,16.26,15.96,9.48,23.05,14.64,37.39.51,1.42.56,2.98.06,4.4-1.75,4.95-3.84,8.64-6.45,10.65-1.83,1.41-2.83,3.62-2.54,5.92.74,5.85.77,11.67.02,17.48-.41,3.17-.65,6.36-.5,9.55,1.3,28.47,4.5,54.83,7.56,82,.29,2.52,1.41,4.89,3.22,6.67,4.7,4.6,8.33,9.88,10.51,16.16,1.89,13.96.08,27.07-6.98,38.84-8.32-8.4-14.59-12.09-19.93-13.58-5.5,2.12-10.6,4.32-15.15,6.67h.08,0Z"/>
    <path id="middle_hand_bones_5" data-name="middle hand bones 5" class="mittelhand_links_dp1331_cls4" d="M210.42,284.21h0v.08c5.18-7.38,7.61-15.23,7.61-34.14-.79-.85,7.84-15.75,15.89-23.68,1.56-1.53,2.46-3.6,2.46-5.78l-.3-111.16c-6.12-19.86-8.79-35.55.94-36.65-3.85-1.57,1.88-7.77,5.65-12.95,11.3-4.39,22.83-2.67,34.76,5.65,4.63,3.06,6.43,14.6,8.55,25.27-.84,4.75-2.47,6.87-4.76,6.94-2.41.06-4.47,1.75-4.98,4.11-3.47,16.21-6.68,35.98-8.93,67.26,1.07,28.22.2,52.76-2.56,73.95-.82,6.33,1.22,12.74,5.77,17.21.6.59,1.23,1.18,1.89,1.76.87,12.95-1.41,27.47-15.77,27.47-13.26-20.32-23.23-16.32-31.94-3.45-5.65,2.28-10.43,1.73-14.2-1.88h-.08,0Z"/>
    <path id="middle_hand_bones_4" data-name="middle hand bones 4" class="mittelhand_links_dp1331_cls4" d="M102.36,296.47v.02h.08c-7.06,2.12-13.02-1.65-18.36-9.18-2.12-8.71-2.52-18.6-1.26-29.43-13.03-18.6-24.88-36.1-31-55.64-5.62-16.8-13.08-31.01-22.25-40.62-1.29-1.35-1.95-3.17-1.84-5.03.16-2.84.11-5.54-.24-8.11-.2-7.53,1.35-10.08,3.89-10.05,2.73.03,5.26-1.55,6.23-4.1,3.55-9.38,10.94-12.26,19.94-12.46,6.43,1.1,9.18,7.54,11.06,15.15,5.26,5.1,5.81,12.47,4.08,20.87,11.37,44.9,22.67,85.34,44.29,93.81,2.86,1.12,4.82,3.75,5.01,6.81l.9,14.78c.22,3.6-.86,7.17-3.05,10.05-5.02,6.62-10.18,12.89-17.48,12.89v.24s0,0,0,0Z"/>
    <path id="middle_hand_bones_3" data-name="middle hand bones 3" class="mittelhand_links_dp1331_cls5" d="M28.99,139.05c1.42,2.9,5.26,5.1,7.85,6.2,11.61-3.61,21.82-12.71,31.86-7.61"/>
    <path id="middle_hand_bones_2" data-name="middle hand bones 2" class="mittelhand_links_dp1331_cls4" d="M151.01,65.13c4.66,2.14,9.91,1.15,12.87,3.14,1.97,1.33,4.48,1.52,6.6.47,2.97-1.47,6.03-3.44,10.13-4.14,3.23-.56,6.46,1.02,8.18,3.82,6.58,10.7,11.45,22.57,17.31,26.06"/>
    <path id="middle_hand_bones_1" data-name="middle hand bones 1" class="mittelhand_links_dp1331_cls4" d="M235.53,72.5c3.11.11,5.88.54,7.64,1.79,1.54,1.09,3.45,1.51,5.28,1.06,9.53-2.34,17.7-3.9,21.6-2.85,6.83,1.57,7.62,11.77,15.62,18.52"/>
  </g>
  <g id="wiechteilen">
    <path class="mittelhand_links_dp1331_cls3" d="M386.15,175.43c-3.22,35.76-10.52,64.19-16.85,84-13.25,41.43-32.44,76.92-46,102-14.07,26.03-21.87,37.06-27,59-5.77,24.65-4.26,46.32-2,61"/>
    <path class="mittelhand_links_dp1331_cls3" d="M298.3,38.43c1.45,15.24,3.44,35.33,6,59,2.77,25.64,3.96,34.65,7,35,2.81.32,6.1-6.86,22-54,4.23-12.53,7.67-22.91,10-30"/>
    <path class="mittelhand_links_dp1331_cls3" d="M.3,182.43c17.88,24.53,30.35,47.48,39,66,17.62,37.7,24.82,68.59,29,87,4.26,18.75,6.63,29.48,7,45,.49,20.47-2.99,29.21-4,53-.5,11.83-.41,27.83,2,47"/>
    <path class="mittelhand_links_dp1331_cls3" d="M233.29,5.43c-1.7,9.81-4.06,23.15-7,39-4.87,26.2-6.84,35.06-9,35-2.05-.06-2.89-8.15-5-18-2.43-11.36-5.75-37.84-14.82-55.96"/>
    <path class="mittelhand_links_dp1331_cls3" d="M51.3,11.43c1.1,3.99,2.84,10.29,5,18,9.48,33.83,12.39,42.48,15,57,1.26,7.04,6.06,33.76,2,35-3.3,1.01-9.9-15.66-28-47-6.95-12.04-24.98-41.7-29-48"/>
    <path class="mittelhand_links_dp1331_cls3" d="M138.3,13.43c0,3.82.02,9.35,0,16-.12,42.6-1.05,46.92-2,47-1.02.1-1.68-4.78-7.88-22.91-.28-.84-.9-2.59-2.12-6.09-2.41-6.88-6.07-17.29-11-31"/>
  </g>
</g>
<g id="overlays">
  <g id="middle_hand_bones_overlay" data-name="middle hand bones overlay" class="mittelhand_links_dp1331_cls1">
    <path id="middle_hand_bones_9_overlay" data-name="middle hand bones 9 overlay" class="MHKFill" style={{
            fill: props.colors.MHKFill,
            stroke: props.colors.MHKFill,
          }} d="M296.93,300.19l.02.02v-.08c-8.09-2.51-16.09-4.95-14.52-19.62-5.33-6.2-8.71-4.87-10.12-2.83,1.02-7.46.55-13.74.31-16.09,4.78-6.36,10.43-9.97,17.73-8.24,14.98-14.46,23.96-34.14,28.43-59.52.39-2.21.09-4.49-.81-6.55-1.09-2.52-1.63-4.58-1.79-6.35-.21-2.36.65-4.69,2.32-6.37,4.34-4.39,9.51-8.43,15.09-12.17,7.58-5.17,16.97-3.59,23.45,2.57s8.29,15.8,5.02,24.12c-6.23,15.86-12.57,31.09-20.24,38.76l-12.32,47.71c-3.93,16.71-8.56,25.27-32.57,24.64h0Z"/>
    <path id="middle_hand_bones_8_overlay" data-name="middle hand bones 8 overlay" class="MHKFill" style={{
            fill: props.colors.MHKFill,
            stroke: props.colors.MHKFill,
          }} d="M121.32,259.38h0c.06-2.27.22-4.57.46-6.9.84-11,.79-21.84-1.38-32.61-.12-.6-.28-1.2-.46-1.79-7.26-23.36-13.77-41.77-19.97-57.58-10.46-23.51-16.01-40.19-16.67-49.75-.03-.52-.09-1.05-.17-1.57-.67-4.53-.08-8.99,1.33-13.4,1.63-5.1,5.47-9.25,10.51-11.02,5.65-1.99,12.1-1.86,19.33.32,4.34,1.31,7.92,4.39,10.05,8.39,2.4,4.51,4.38,9.28,5.9,14.29.46,1.53.72,3.11.76,4.71l.56,24.22c.02.49.05.97.1,1.46,1.04,9.52,2.95,24.24,4.94,40,1.64,10.39,8.91,29.59,16.49,49.57,1.29,3.38,3.65,6.21,6.62,8.27.08.05.15.11.23.16,4.84,3.44,7.59,9.23,7.03,15.14-.1,1,.03,2.4.47,4.34.31,1.37.44,2.79.4,4.2l-.25,9.04c.79,2.43-11.45,14.67-11.06,10.75.25-2.23-8.95-2.85-17.73-2.73-9.74.14-17.79-7.78-17.5-17.52h.01Z"/>
    <path id="middle_hand_bones_7_overlay" data-name="middle hand bones 7 overlay" class="MHKFill" style={{
            fill: props.colors.MHKFill,
            stroke: props.colors.MHKFill,
          }} d="M83.16,109.97c12.8-21.82,28.18-19.31,48.03.39"/>
    <path id="middle_hand_bones_6_overlay" data-name="middle hand bones 6 overlay" class="MHKFill" style={{
            fill: props.colors.MHKFill,
            stroke: props.colors.MHKFill,
          }} d="M175.69,276.81h0v.08c-5.18-.95-8.24-3.22-7.93-7.93.25-4.86-1.12-15.38.63-18.84,1.01-1.99,1.62-4.16,1.43-6.38l-.17-2.01c6.52-15.15,7.46-34.29,3.77-56.58-12.63-43.23-29.42-93.69-26.52-110.25,2.66-8.87,7.06-16.25,12.63-22.76,9.16-5.01,19.85-3.55,31.62,2.51,16.26,15.96,9.48,23.05,14.64,37.39.51,1.42.56,2.98.06,4.4-1.75,4.95-3.84,8.64-6.45,10.65-1.83,1.41-2.83,3.62-2.54,5.92.74,5.85.77,11.67.02,17.48-.41,3.17-.65,6.36-.5,9.55,1.3,28.47,4.5,54.83,7.56,82,.29,2.52,1.41,4.89,3.22,6.67,4.7,4.6,8.33,9.88,10.51,16.16,1.89,13.96.08,27.07-6.98,38.84-8.32-8.4-14.59-12.09-19.93-13.58-5.5,2.12-10.6,4.32-15.15,6.67h.08,0Z"/>
    <path id="middle_hand_bones_5_overlay" data-name="middle hand bones 5 overlay" class="MHKFill" style={{
            fill: props.colors.MHKFill,
            stroke: props.colors.MHKFill,
          }} d="M210.61,283.77h0v.08c5.18-7.38,7.61-15.23,7.61-34.14-.79-.85,7.84-15.75,15.89-23.68,1.56-1.53,2.46-3.6,2.46-5.78l-.3-111.16c-6.12-19.86-8.79-35.55.94-36.65-3.85-1.57,1.88-7.77,5.65-12.95,11.3-4.39,22.83-2.67,34.76,5.65,4.63,3.06,6.43,14.6,8.55,25.27-.84,4.75-2.47,6.87-4.76,6.94-2.41.06-4.47,1.75-4.98,4.11-3.47,16.21-6.68,35.98-8.93,67.26,1.07,28.22.2,52.76-2.56,73.95-.82,6.33,1.22,12.74,5.77,17.21.6.59,1.23,1.18,1.89,1.76.87,12.95-1.41,27.47-15.77,27.47-13.26-20.32-23.23-16.32-31.94-3.45-5.65,2.28-10.43,1.73-14.2-1.88h-.08,0Z"/>
    <path id="middle_hand_bones_4_overlay" data-name="middle hand bones 4 overlay" class="MHKFill" style={{
            fill: props.colors.MHKFill,
            stroke: props.colors.MHKFill,
          }} d="M102.55,296.03v.02s.08,0,.08,0c-7.06,2.12-13.02-1.65-18.36-9.18-2.12-8.71-2.52-18.6-1.26-29.43-13.03-18.6-24.88-36.1-31-55.64-5.62-16.8-13.08-31.01-22.25-40.62-1.29-1.35-1.95-3.17-1.84-5.03.16-2.84.11-5.54-.24-8.11-.2-7.53,1.35-10.08,3.89-10.05,2.73.03,5.26-1.55,6.23-4.1,3.55-9.38,10.94-12.26,19.94-12.46,6.43,1.1,9.18,7.54,11.06,15.15,5.26,5.1,5.81,12.47,4.08,20.87,11.37,44.9,22.67,85.34,44.29,93.81,2.86,1.12,4.82,3.75,5.01,6.81l.9,14.78c.22,3.6-.86,7.17-3.05,10.05-5.02,6.62-10.18,12.89-17.48,12.89v.24s0,0,0,0Z"/>
    <path id="middle_hand_bones_3_overlay" data-name="middle hand bones 3 overlay" class="MHKFill" style={{
            fill: props.colors.MHKFill,
            stroke: props.colors.MHKFill,
          }} d="M29.18,138.61c1.42,2.9,5.26,5.1,7.85,6.2,11.61-3.61,21.82-12.71,31.86-7.61"/>
    <path id="middle_hand_bones_2_overlay" data-name="middle hand bones 2 overlay" class="MHKFill" style={{
            fill: props.colors.MHKFill,
            stroke: props.colors.MHKFill,
          }} d="M151.2,64.69c4.66,2.14,9.91,1.15,12.87,3.14,1.97,1.33,4.48,1.52,6.6.47,2.97-1.47,6.03-3.44,10.13-4.14,3.23-.56,6.46,1.02,8.18,3.82,6.58,10.7,11.45,22.57,17.31,26.06"/>
    <path id="middle_hand_bones_1_overlay" data-name="middle hand bones 1 overlay" class="MHKFill" style={{
            fill: props.colors.MHKFill,
            stroke: props.colors.MHKFill,
          }} d="M235.72,72.06c3.11.11,5.88.54,7.64,1.79,1.54,1.09,3.45,1.51,5.28,1.06,9.53-2.34,17.7-3.9,21.6-2.85,6.83,1.57,7.62,11.77,15.62,18.52"/>
  </g>
  <g id="skeletal_soft_tissue_overlay" data-name="skeletal soft tissue overlay">
    <path id="skeletal_soft_tissue_1" data-name="skeletal soft tissue 1" class="gelenknahen_KnochenFill" style={{
            fill: props.colors.gelenknahen_KnochenFill,
            stroke: props.colors.gelenknahen_KnochenFill,
          }} d="M308.95,297.42l-.03.02c-19.42.45-26.71-6.65-23.21-20.46.16-.62.02-1.29-.41-1.74-4.15-4.4-8.21-5.56-12.18-2.9-.19.13-.36.31-.5.5-7.7,11.24-12.68,15.52-14.45,11.59-3.19-7.37-7.47-12.79-13.65-14.86-.11-.04-.23-.07-.35-.09-7.52-1.19-14.98,3.36-22.41,9.78l-.09.09c-5.83,5.85-8.89,3.83-11.18-.38-.05-.09-.11-.18-.18-.27-5.74-7.27-12.08-12-19.11-13.79-.21-.05-.44-.07-.66-.05-6.09.68-11.07,3.62-15.09,8.44-.36.43-.88.67-1.42.65-2.15-.08-3.95-.93-5.14-3.19-.67-1.27-2.4-1.33-3.1-.07-1.78,3.25-4.13,5.55-8.03,5.27-15.32-.55-26.85-4.16-33.35-11.82-.15-.17-.32-.32-.52-.43-2.05-1.06-3.29-.37-4.17,1.13-.17.29-.23.63-.21.97.56,11.41-1.94,20.24-9.75,24.55-.1.06-.21.1-.31.14-9.47,3.39-17.07,2.27-21.62-6.19-.57-1.06-2.03-1.31-2.79-.39-.27.32-.48.72-.64,1.2-.53,1.6-.05,3.38,1.05,4.64,6.07,6.94,12.46,9.96,22.96,6.85.16-.05.32-.12.46-.21l16.5-10.51c10.94-6.27,22.52-5.4,34.38-1.33.53.18,1.11.1,1.56-.23l5.76-4.15c.5-.36,1.13-.41,1.7-.18,5.76,2.31,13.19.58,21.34-2.87.41-.17.87-.19,1.28-.03,6.22,2.4,13.5,7.93,21.16,14.61.07.06.15.12.23.17,3.87,2.52,8.06,2.35,12.46.35.3-.13.55-.36.74-.64,4.13-6.07,8.82-7.98,13.95-6.74,3.33.11,9.11,5.06,14.86,9.96.15.13.33.24.51.31,4.93,1.97,8.91.99,12.33-1.69,2.31-1.37,4.14-3.18,5.73-5.19.93-1.18,2.74-.76,3.08.72.82,3.63,2.84,6.6,5.54,9.19.05.05.11.1.17.15,4.12,3.32,9.04,4.29,14.53,3.64.08,0,.16-.01.24,0,4.61.24,8.75-.08,12.45-.91,2.1-.47,1.73-3.66-.42-3.61h0Z"/>
    <path id="skeletal_soft_tissue_2" data-name="skeletal soft tissue 2" class="gelenknahen_KnochenFill" style={{
            fill: props.colors.gelenknahen_KnochenFill,
            stroke: props.colors.gelenknahen_KnochenFill,
          }} d="M362.5,173.56h0c3.32-.41,6.75-3.16,7.3-4.34.53-.47.41-1.36-.17-1.76-10.63-7.41-23.75-15.52-37.06-18.32-.8-.17-1.49.6-1.26,1.38,1.41,4.76,4.27,8.02,7.28,9.86,9.93-2.16,20.75,3.78,23.91,13.19h0Z"/>
    <path id="skeletal_soft_tissue_3" data-name="skeletal soft tissue 3" class="gelenknahen_KnochenFill" style={{
            fill: props.colors.gelenknahen_KnochenFill,
            stroke: props.colors.gelenknahen_KnochenFill,
          }} d="M279.24,64.33h0c2.06-1.49,3.08-3.17,3.54-4.97.36-1.41-.38-2.89-1.72-3.48-14.45-6.39-28.18-7.71-41.53-6.24-2.06.23-3.28,2.39-2.47,4.29.93,2.18,2.44,3.88,4.61,5.01.7.37,1.51.44,2.28.23,11.03-3.02,21.66-1.1,31.94,5.26,1.03.64,2.37.6,3.35-.11h0Z"/>
    <path id="skeletal_soft_tissue_4" data-name="skeletal soft tissue 4" class="gelenknahen_KnochenFill" style={{
            fill: props.colors.gelenknahen_KnochenFill,
            stroke: props.colors.gelenknahen_KnochenFill,
          }} d="M194.11,56.69h0c4.3.03,6.09-2.96,6.81-7.25.25-1.47-.59-2.9-1.97-3.46-15.83-6.5-32.52-7.31-50.07-2.45-1.28.35-2.2,1.49-2.29,2.82-.56,8.3,1.45,12.97,8.76,10.16.65-.25,1.21-.7,1.58-1.29l1.13-1.81c.3-.49.73-.89,1.25-1.15,9.17-4.6,19.76-4.67,33.08,3.91.51.33,1.11.51,1.71.52h0Z"/>
    <path id="skeletal_soft_tissue_5" data-name="skeletal soft tissue 5" class="gelenknahen_KnochenFill" style={{
            fill: props.colors.gelenknahen_KnochenFill,
            stroke: props.colors.gelenknahen_KnochenFill,
          }} d="M121.21,88.16h0c5.88.2,8.04-2.89,9.22-6.84.38-1.26-.4-2.56-1.67-2.89-15.16-3.92-30.99-2.65-47.26,2.06-1.25.36-1.94,1.7-1.54,2.94,1.55,4.85,3.58,7.71,6.55,6.73.27-.09.5-.25.71-.44,6.89-6.14,17.44-9.39,33.07-1.81.28.14.61.24.92.24h0Z"/>
    <path id="skeletal_soft_tissue_6" data-name="skeletal soft tissue 6" class="gelenknahen_KnochenFill" style={{
            fill: props.colors.gelenknahen_KnochenFill,
            stroke: props.colors.gelenknahen_KnochenFill,
          }} d="M37.01,134.46c4.43-9.37,12.21-13.54,23.6-12.08.54-2.3-.2-4.62-1.58-6.95-.45-.75-1.35-1.14-2.2-.93-10.5,2.59-20.26,8.31-29.43,16.6-.68.61-.98,1.56-.75,2.44.64,2.51,2.95,3.93,7.19,4.09,1.63-.58,2.66-1.67,3.17-3.17h0Z"/>
  </g>
  <g id="Softparts_overlays" class="mittelhand_links_dp1331_cls7">
    <path id="Softparts_overlays6" class="weichteilenFill" style={{
            fill: props.colors.weichteilenFill,
            stroke: props.colors.weichteilenFill,
          }} d="M76.04,479.98c1.42-6.02.37-11.02,0-12.99-.44-2.32-.29-10.89,0-28.03.05-2.92.19-10.47.68-19.14,1.13-19.63,2.94-24.52,3.42-34.86.24-5.12.2-12.59-4.78-36.91-4.96-24.22-9.14-44.61-19.14-71.77-2.37-6.44-10.2-27.2-23.92-52.63-4.21-7.8-24.05-44.58-29.39-42.38-2.2.91-1.74,8.37,0,13.67,1.91,5.83,5.37,9.07,8.2,12.3,2.71,3.09,6.14,9.58,12.99,22.56,14.91,28.24,24.61,54.68,24.61,54.68,12.85,35.02,17.39,61.77,18.46,68.35,2.49,15.36,2.7,23.89,2.73,27.34.14,12.64-1.4,14.86-2.73,36.23-.21,3.35-.96,15.94-.68,32.13,0,.46.21,10.29,2.05,23.24,2.06,14.5,3.64,15.02,4.1,15.04,1.75.07,3.29-6.3,3.42-6.84Z"/>
    <path id="Softparts_overlays5" class="weichteilenFill" style={{
            fill: props.colors.weichteilenFill,
            stroke: props.colors.weichteilenFill,
          }} d="M298.19,478.62c1.23-7.88.24-14.14,0-15.72-.98-6.56-.2-13.26,1.37-26.66,1.25-10.69,4.08-19.98,10.25-34.86,8.94-21.55,13.96-28.46,28.71-57.42,9.94-19.51,15.29-31.35,24.61-51.95,2.76-6.1,7.45-16.58,12.3-30.76,2.41-7.04,4.76-13.97,6.84-23.24,1.24-5.55,3.14-15.32,4.1-31.44,1.35-22.59.15-45.76-1.37-45.8-1.3-.03-1.33,16.94-6.15,43.75-.58,3.22-3.68,20.18-9.57,40.33-9.08,31.03-20.12,53.65-30.08,73.82-8.92,18.07-20.73,39.79-27.34,51.95-10.98,20.19-14.13,25.21-17.77,36.23-6.1,18.43-7.32,34.73-8.2,46.48-.6,8.05.34,3.49,0,17.09-.13,5.2-.35,9.06,2.05,12.99.2.33,3.35,5.39,6.15,4.78,2.64-.57,3.52-5.88,4.1-9.57Z"/>
    <path id="Softparts_overlays4" class="weichteilenFill" style={{
            fill: props.colors.weichteilenFill,
            stroke: props.colors.weichteilenFill,
          }} d="M346.73,51.4c-1.82,6.87-2.74,10.31-4.1,12.99-4.61,9.05-6.81,19.15-10.25,28.71-2.96,8.2-7.08,16.08-8.89,24.61-.58,2.75-1.94,9.79-6.84,17.09-3.78,5.63-5.71,5.52-6.15,5.47-2.79-.34-4.36-5.7-6.15-13.67-3.88-17.25-4.78-27.34-4.78-27.34-3.3-36.85-1.73-33.19-3.42-41.01-.66-3.05-1.63-13.55-2.73-22.56-.61-4.95-1.15-9.33.68-10.25,1.59-.8,4.5,1.24,6.15,3.42,2.69,3.54,2.12,7.57,2.05,12.99-.07,5.49,1.04,10.93,1.37,16.41.92,15.15,2.47,27.39,3.42,34.86,1.61,12.69,1.74,10.15,2.73,19.14.96,8.73.94,12.1,2.05,12.3,1.63.3,4.52-8,10.25-24.61,9.11-26.41,13.67-39.62,13.67-39.65,1.24-7.65,1.82-16.56,6.15-17.77,1.53-.43,3.67.06,4.78,1.37,1.43,1.67.85,4.31,0,7.52Z"/>
    <path id="Softparts_overlays3" class="weichteilenFill" style={{
            fill: props.colors.weichteilenFill,
            stroke: props.colors.weichteilenFill,
          }} d="M238.04,6.29c.98,2.55-.56,5.27-1.37,6.84-.09.17-1.65,7.4-4.78,21.87-2.18,10.06-3.72,18.42-4.1,20.51-.1.54-1.51,8.26-3.42,17.77,0,0-.44,2.18-2.73,10.94-1.71,6.53-2.09,7.42-2.73,7.52-2.91.43-7.58-16.21-8.2-18.46-3.3-11.9-2.31-13.93-5.47-30.08-3.22-16.48-4.83-24.72-8.2-30.08-2.73-4.34-6.24-8.3-4.78-10.94,1.25-2.26,5.77-2.86,8.89-1.37,3.24,1.56,4.54,5.19,6.15,12.3,1.97,8.66,2.55,14.3,3.42,20.51,1.59,11.41,1.92,8.92,4.78,26.66,1.17,7.25,1.42,9.55,2.05,9.57,2.27.07,5.75-29.68,6.84-38.96,2.12-18.16,2.56-27.67,6.84-28.71,2.39-.58,5.84,1.52,6.84,4.1Z"/>
    <path id="Softparts_overlays2" class="weichteilenFill" style={{
            fill: props.colors.weichteilenFill,
            stroke: props.colors.weichteilenFill,
          }} d="M143.71,12.44c1.52,4.42.6,8.14,0,11.62,0,.02-.23,6.16-.68,18.46-.58,15.84.09,15.4-.68,21.19-.89,6.62-3.28,18.45-6.15,18.46-1.27,0-2.09-2.31-5.47-11.62-4.23-11.67-4.3-11.63-5.47-15.04-5.14-14.95-7.7-22.42-10.94-30.08-3.08-7.3-5.3-11.59-3.42-13.67,1.49-1.65,5.11-1.4,7.52,0,3.3,1.92,3.62,5.56,6.15,15.04.41,1.55-2.44-9.18,4.1,13.67,3.84,13.4,5.04,17.86,5.47,17.77.81-.16-3.45-15.98-.68-36.23.48-3.53,2.11-15.46,5.47-15.72,1.97-.15,3.96,3.77,4.78,6.15Z"/>
    <path id="Softparts_overlays1" class="weichteilenFill" style={{
            fill: props.colors.weichteilenFill,
            stroke: props.colors.weichteilenFill,
          }} d="M55.54,10.39c4.16,3.14,4.22,8.83,4.78,14.35,1.13,11.05,6,21.4,8.89,32.13,5.78,21.51,6.39,22.56,7.52,29.39,2.1,12.76,0,9.2,2.05,22.56,1.11,7.25,2.46,12.99-.68,15.72-1.98,1.72-4.81,1.45-5.47,1.37-6.58-.82-9.93-9.38-16.41-21.87-.59-1.13-4.26-7.59-11.62-20.51-14.6-25.63-21.89-38.44-27.34-45.8-3.84-5.18-9.03-11.69-6.84-15.72,1.09-2,3.89-3.2,6.15-2.73,4.05.84,5.33,6.83,8.2,13.67.35.83.16.33,11.62,19.14,15.56,25.54,23.34,38.3,25.97,43.06,6.29,11.38,8.7,16.74,9.57,16.41,1.14-.44-2.07-10.24-10.94-41.7-11.33-40.18-9.42-36.04-10.94-39.65-4.08-9.71-8.45-17.27-5.47-20.51,2.16-2.35,7.62-1.82,10.94.68Z"/>
  </g>
</g>
</svg>
  );
}

export default MittelhandLinksDp;
