import style from "./style.scss";

function Brustwirbelkorper10VdLiegend1525(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.26 228.22">

<g id="BG">
  <path id="BG_white" data-name="BG white" class="Brustwirbelsaule10_ap1525_cls4" d="M19.19,167.96l13.22-44.66,33.98-31.63c16.11-14.05,32.96-26.36,52.86-31.43,17.2-2.44,30.92.13,40.22,9.06,1.78,1.48,3.79,1.23,5.93,0,.38-4.51,1.88-4.28,3.55-3.34,3.89-.58,4.59-1.97,3.46-3.82-3.02-.78-4.71-2.74-5.53-5.46-.87-2.9-.35-6.02,1.09-8.68,4.43-8.18,6.69-15.79,4.44-22.23-3.62-5.31-5.76-11.62-6.14-19.09.33-2.65,1.21-4.56,4.18-3.77,6.03,2.37,12.28,3.73,18.92,3.31,4.27.17,11.25-.81,18.55-1.93,11.78-.21,23.1-.76,33.2-2.19,6.88-1.52,12.78-2.35,15.49-.91,2.44,3,3.13,6.69,2.72,10.82l-4.12,16.89c-.84,5.28-.78,11.1,4.12,19.83l5.38,8.43c3.28-2.73,6.59-5.15,10.1-5.41,3.28,3.17,7.53,5.96,13.86,7.92,20.85,6.73,40.87,11.35,59.55,12.59v24.64c-8.72-3.67-17.36-6.31-25.92-7.82-8.18-2.4-18.22-3.04-29.22-2.76-.67,7.3-2.81,13.34-7.29,17.4-4.76,4.17-10.18,6.16-16.29,5.93-5.18-.62-6.01,1.37-3.9,5.11,1.92,8.2,1.16,13.02-2.96,13.59-1.61,3.41-1.79,6.59.87,9.33,3.37.47,4.92,3.07,5.83,6.41,1.84-2.63,6.51-6.42,11.43-10.3,9.31,8.44,19.57,10.12,30.48,8.66-15.85,2.14-30.01,5.17-41.75,9.45-1.38,2.27-.52,4.4,4.33,6.28l11.24,3.74c4.32,1.6,5.38,4.45,4.08,8.22-1.57,9.09-3.87,15.02-7.72,14.27l-7.04-.03c-8-.93-10.17-.75-8.77,2.46,1.68,3.34,3.74,6.21,4.21,8.94,1.08,6.01-.61,7.09-2.53,6.88-10.48-1.66-14-5.02-19.34-6.89-5.28-.85-16.37-.74-27.85-.56l-18.63,1.33c-10.34,2.45-20.13,4.27-28.71,4.7-6.23.28-10.62.5-11.54-4.36-.22-3.98,1.49-8.4,3.88-12.98.77-3.88-.32-6.02-1.89-7.72-1.58-1.12-4.14-.58-7.11.66-5.26.51-10.16.26-14.31-1.57-3.53-3.27-5.24-7.03-5.89-11.08C101.14,177.06,52.3,201.22.06,228.11c13.83-7.78,27.16-16.93,39.88-27.75l41.81-36.38c15.84-13.94,32.58-25.39,50.5-33.58l16.62-4.83c5.4,5.43,9.43,11.82,9.76,20.82,2.06.97,3.73.4,5.02-1.61l-.91-5.07c1.67-.81,3.39-1,5.17-.47,2.16-4.38,1.88-9.62-1.52-15.94l-12.76-7.75c-4.05-5.92-10.87-6.94-19.14-6.38-5.74-.21-9.78-1.79-10.05-6.38.18-9.58,3.17-17.04,10.24-21.4-8.87-.46-20.24,2.97-35.4,12.31-17.71,10.97-35.34,27.18-52.95,46l-27.15,28.26h.01Z"/>
</g>
<g id="BWK10ap">
  <g id="BWK10apParts">
    <path id="BWK10Part30" class="Brustwirbelsaule10_ap1525_cls6" d="M167.75,3c1.78-1.24,3.91.84,10.03,2.28,4.45,1.05,7.76,1,13.22.91,6.86-.11,7.32-1.01,15.95-1.82,9.84-.93,10.56.11,21.87-.91,5.73-.52,8.6-.78,12.3-1.37,9.32-1.48,12.78-3.07,15.49-.91,2.33,1.85,2.54,5.22,2.73,8.2.32,4.98-1.25,7.08-2.73,13.22-1.52,6.27-2.66,10.96-1.37,16.41,1.3,5.49,4.94,11.21,5.92,12.76,2.54,3.99,4.26,5.52,3.65,6.84-.8,1.72-4.79,1.32-12.76.46-17.04-1.85-20.61-2.41-25.97-2.28-5.46.14-3.52.76-14.13,1.82-17.18,1.73-16.76-.46-26.89,1.37-8,1.44-12.18,3.52-15.49.91-2.84-2.23-3.15-6.4-3.19-7.29-.19-3.97,1.9-5.54,4.1-10.94,1.39-3.4,3.38-8.3,2.73-13.67-.66-5.45-3.36-5.76-5.47-13.22-.61-2.15-3.02-10.66,0-12.76h.01Z"/>
    <path id="BWK10Part29" class="Brustwirbelsaule10_ap1525_cls5" d="M166.38,123.3c5.49,2.03,9.01.78,17.32,0,10.19-.96,7.97.64,26.89.46,19.56-.19,20.33-1.88,27.8,0,2.67.67,6.64,1.51,14.58,3.19,7.51,1.59,10.11,2.05,11.85.46,2.93-2.7,1.55-9.61,1.37-10.48-.75-3.55-2.22-5.06-1.37-6.38.99-1.52,3.73-.7,7.29-.91,7.59-.45,12.96-5.27,13.67-5.92,5.34-4.93,6.32-11.24,6.84-14.58.47-3.03,1.02-6.58-.91-8.2-1.45-1.22-3.21-.42-8.66,0-1.71.13-5.81.44-10.03,0-5.68-.59-9.5-.98-11.39-3.65-2.67-3.77,1.11-8.39-1.37-10.94-1.88-1.93-5.15-.42-11.85.46-7.14.93-6.92-.33-18.68,0-8.86.25-8.13.94-17.77,1.37-12.27.54-10.82-.69-20.51,0-9.79.7-12.66,2.06-17.77,0-3.29-1.33-5.66-3.32-7.29-2.28-2.21,1.41.36,6.21-2.28,9.11-1.57,1.73-3.61,1.26-9.57,1.82-2.69.25-7.98.75-12.76,2.28-3.36,1.07-8.31,2.66-12.3,7.29-.81.94-4.85,5.8-5.01,12.76-.06,2.36-.14,6.03,2.28,8.2.56.5,1.67,1.3,7.75,1.82,6.19.53,6.85-.14,9.57.46,3.34.74,4.39,2.2,9.57,5.92,4.72,3.39,8.71,6.25,12.76,7.75h-.02Z"/>
    <path id="BWK10Part28" class="Brustwirbelsaule10_ap1525_cls5" d="M183.7,94.13c2.79.13,5.4-5.29,6.38-9.11,1.06-4.17-.24-4.79,0-12.76.19-6.18,1.04-8.16-.46-9.57-1.85-1.74-5.75-1.17-8.2.46-3.86,2.56-4.24,7.79-4.56,12.3-.18,2.55-.15,6.97,1.82,11.85,1.18,2.91,2.72,6.73,5.01,6.84h0Z"/>
    <path id="BWK10Part27" class="Brustwirbelsaule10_ap1525_cls5" d="M245.68,95.96c2.76.32,5.59-2.8,6.84-5.47,1.53-3.28.34-5.18-.91-14.13-1.28-9.19-.69-11.95-3.19-13.67-2.1-1.44-5.35-1.45-7.29,0-1.07.8-1.89,2.14-2.28,9.57-.35,6.69-.53,10.04.46,13.67.8,2.96,2.61,9.59,6.38,10.03h-.01Z"/>
    <path id="BWK10Part26" class="Brustwirbelsaule10_ap1525_cls6" d="M162.28,199.86c.48.58,1.75,1.76,10.48,1.37,5.74-.26,10.16-.99,12.3-1.37,8.62-1.5,9.01-2.16,15.49-3.19,5.96-.95,10.62-1.19,14.13-1.37,5.56-.27,5.71.03,15.95,0,13.82-.04,14.25-.58,17.32.46,4.58,1.55,5.52,3.41,12.3,5.47,3.53,1.07,7.93,2.36,9.57.46.91-1.06.61-2.7,0-5.92-1.02-5.41-5.42-9.13-4.54-10.94.87-1.8,6.15.05,9.1-.46,5.62-.96,7.06.89,9.57-.46,2.75-1.48,3.45-4.99,4.56-10.48.97-4.82,1.5-7.45,0-9.57-1.21-1.72-2.67-1.55-9.11-3.65-8.82-2.86-9.71-4.35-10.03-5.01-1.14-2.43.9-3.67.46-8.2-.12-1.22-.62-6.38-4.1-8.66-.87-.57-2.44-1.3-11.85,0-4.8.66-4.97.93-8.2,1.37-5.77.77-7.22.17-15.49.46-3.58.12-6.64.38-12.76.91-6.63.57-14.13,1.37-14.13,1.37-3.05-3.2-6.54-5.96-9.11-5.01-2.44.9-3.14,4.78-4.1,4.56-.82-.19-.28-2.98-1.37-6.84-.55-1.94-1.68-5.93-4.1-6.38-1.97-.36-4.05,1.75-5.01,3.19-2.21,3.28-.46,5.77-2.28,7.29-1.71,1.43-3.92-.19-10.02,0-1.99.06-3.59.29-4.56.46,1.3,3.9.79,5.58,0,6.38-2.01,2.04-6.07-1.47-11.85,0-3.2.81-5.22,2.71-8.66,5.92-3.32,3.1-5.1,4.77-5.92,7.75-1.23,4.44.63,8.43,1.37,10.03.76,1.63,1.79,3.84,4.1,5.47.99.69,2.95,1.8,10.03,1.82,6.49.02,8.96-2.62,11.39-.91,2.03,1.43,2.23,4.36,2.28,5.01.21,2.94-1.4,4.15-2.73,7.75-.58,1.57-2.95,7.95-.46,10.94l-.02-.02h0Z"/>
    <path id="BWK10Part25" class="Brustwirbelsaule10_ap1525_cls6" d="M348.21,96.9c-20.27-8.82-44.51-11.93-73.41-10.05"/>
    <path id="BWK10Part24" class="Brustwirbelsaule10_ap1525_cls6" d="M254.63,86.84s-1.45,11.85,20.17,0"/>
    <path id="BWK10Part23" class="Brustwirbelsaule10_ap1525_cls6" d="M274.8,51.75c2.6,5.36,32.64,16.68,73.41,20.51"/>
    <path id="BWK10Part22" class="Brustwirbelsaule10_ap1525_cls6" d="M254.63,86.84c0-19.39,10.87-35.09,20.17-35.09"/>
    <path id="BWK10Part21" class="Brustwirbelsaule10_ap1525_cls6" d="M280.76,133.78c4.95,3.75,14.32,11.09,30.48,8.66"/>
    <path id="BWK10Part20" class="Brustwirbelsaule10_ap1525_cls6" d="M280.76,133.78c-3.64,3.66-11.05,7.85-11.27,11.01"/>
    <path id="BWK10Part19" class="Brustwirbelsaule10_ap1525_cls5" d="M261.62,79.03c-6.62,11.11-3,28.47,3.31,35.73"/>
    <path id="BWK10Part18" class="Brustwirbelsaule10_ap1525_cls6" d="M203.3,142.44c-.33-.85-1.17,52.71,35.26-2.33"/>
    <path id="BWK10Part17" class="Brustwirbelsaule10_ap1525_cls6" d="M177.27,139.25c-9.6,2.91-4.79,69.72,12.76,2.73"/>
    <path id="BWK10Part16" class="Brustwirbelsaule10_ap1525_cls6" d="M247.95,171.15c-5.72-.59-8.6-10.52-9.11-12.3-.79-2.71-1.9-8.19,0-14.58,1.19-4.01,2.97-10.01,7.75-11.39,5.28-1.53,11.14,3.47,13.67,7.75,1.97,3.34,2.11,6.59,2.28,10.48.17,3.94.32,7.55-1.82,11.39-2.28,4.08-5.83,6.05-7.29,6.84-2.32,1.25-3.72,2-5.47,1.82h-.01Z"/>
    <path id="BWK10Part15" class="Brustwirbelsaule10_ap1525_cls6" d="M162.74,139.7c5.05,25.29,1.22,41.47,2.73,41.47"/>
    <path id="BWK10Part14" class="Brustwirbelsaule10_ap1525_cls6" d="M216.94,132.59c-1.45,36.9-6.24,36.07-11.37,12.2"/>
    <path id="BWK10Part13" class="Brustwirbelsaule10_ap1525_cls6" d="M166.38,123.3c6.17,10.86,1.01,22.78-3.65,22.78"/>
    <path id="BWK10Part12" class="Brustwirbelsaule10_ap1525_cls6" d="M145.42,187.78c-31.63-40.97-59.12-61.63-133.97-62.2"/>
    <path id="BWK10Part11" class="Brustwirbelsaule10_ap1525_cls6" d="M148.88,125.58c14.42,14.4,9.85,32.39,5.66,34.86"/>
    <path id="BWK10Part10" class="Brustwirbelsaule10_ap1525_cls6" d="M148.88,125.58C97.62,134.36,52.46,200.77.06,228.11"/>
    <path id="BWK10Part9" class="Brustwirbelsaule10_ap1525_cls5" d="M164.11,75c9.14,31.69,3.1,50.68,0,48.3"/>
    <path id="BWK10Part8" class="Brustwirbelsaule10_ap1525_cls5" d="M203.29,78.79c9.38,12.81,5.38,38.58,12.04,38.58"/>
    <path id="BWK10Part7" class="Brustwirbelsaule10_ap1525_cls5" d="M224.89,75c-9.29,16.61,0,42.38-9.57,42.38"/>
    <path id="BWK10Part6" class="Brustwirbelsaule10_ap1525_cls6" d="M159.47,69.3c-42.18-32.98-91.06,20.18-127.06,54"/>
    <path id="BWK10Part5" class="Brustwirbelsaule10_ap1525_cls6" d="M159.47,86.84c-6.03,6.85-13.07-1.31-23.57-5.45"/>
    <path id="BWK10Part4" class="Brustwirbelsaule10_ap1525_cls6" d="M134.03,81.39c-46.48,2.73-95.83,70.35-114.84,86.57"/>
    <path id="BWK10Part3" class="Brustwirbelsaule10_ap1525_cls5" d="M154.95,104.16c0-1.51,6.93-2.73,15.5-2.73"/>
    <path id="BWK10Part2" class="Brustwirbelsaule10_ap1525_cls6" d="M195.09,12c18.29,17.3,13.1,69.34,18.28,60.26"/>
    <path id="BWK10Part1" class="Brustwirbelsaule10_ap1525_cls6" d="M232.84,11.98c-14.44,4.83-7.23,55.67-19.47,60.28"/>
  </g>
</g>
<g id="angrenzende_BWK10_Highlights" data-name="angrenzende BWK10 Highlights">
  <path id="angrenzende_BWK10_Highlight2" data-name="angrenzende BWK10 Highlight2" class="BWK1525Fill" style= {{
            fill: props.colors.BWK1525Fill,
            stroke: props.colors.BWK1525Fill,
            opacity:props.colors.BWK1525Fill,
          }} d="M167.75,3.04c1.78-1.24,3.91.84,10.03,2.28,4.45,1.05,7.76,1,13.22.91,6.86-.11,7.32-1.01,15.95-1.82,9.84-.93,10.56.11,21.87-.91,5.73-.52,8.6-.78,12.3-1.37,9.32-1.48,12.78-3.07,15.49-.91,2.33,1.85,2.54,5.22,2.73,8.2.32,4.98-1.25,7.08-2.73,13.22-1.52,6.27-2.66,10.96-1.37,16.41,1.3,5.49,4.94,11.21,5.92,12.76,2.54,3.99,4.26,5.52,3.65,6.84-.8,1.72-4.79,1.32-12.76.46-17.04-1.85-20.61-2.41-25.97-2.28-5.46.14-3.52.76-14.13,1.82-17.18,1.73-16.76-.46-26.89,1.37-8,1.44-12.18,3.52-15.49.91-2.84-2.23-3.15-6.4-3.19-7.29-.19-3.97,1.9-5.54,4.1-10.94,1.39-3.4,3.38-8.3,2.73-13.67-.66-5.45-3.36-5.76-5.47-13.22-.61-2.15-3.02-10.66,0-12.76h.01Z"/>
  <path id="angrenzende_BWK10_Highlight1" data-name="angrenzende BWK10 Highlight1" class="BWK1525Fill" style= {{
            fill: props.colors.BWK1525Fill,
            stroke: props.colors.BWK1525Fill,
            opacity:props.colors.BWK1525Fill,
          }} d="M162.28,199.9c.48.58,1.75,1.76,10.48,1.37,5.74-.26,10.16-.99,12.3-1.37,8.62-1.5,9.01-2.16,15.49-3.19,5.96-.95,10.62-1.19,14.13-1.37,5.56-.27,5.71.03,15.95,0,13.82-.04,14.25-.58,17.32.46,4.58,1.55,5.52,3.41,12.3,5.47,3.53,1.07,7.93,2.36,9.57.46.91-1.06.61-2.7,0-5.92-1.02-5.41-5.42-9.13-4.54-10.94.87-1.8,6.15.05,9.1-.46,5.62-.96,7.06.89,9.57-.46,2.75-1.48,3.45-4.99,4.56-10.48.97-4.82,1.5-7.45,0-9.57-1.21-1.72-2.67-1.55-9.11-3.65-8.82-2.86-9.71-4.35-10.03-5.01-1.14-2.43.9-3.67.46-8.2-.12-1.22-.62-6.38-4.1-8.66-.87-.57-2.44-1.3-11.85,0-4.8.66-4.97.93-8.2,1.37-5.77.77-7.22.17-15.49.46-3.58.12-6.64.38-12.76.91-6.63.57-14.13,1.37-14.13,1.37-3.05-3.2-6.54-5.96-9.11-5.01-2.44.9-3.14,4.78-4.1,4.56-.82-.19-.28-2.98-1.37-6.84-.55-1.94-1.68-5.93-4.1-6.38-1.97-.36-4.05,1.75-5.01,3.19-2.21,3.28-.46,5.77-2.28,7.29-1.71,1.43-3.92-.19-10.02,0-1.99.06-3.59.29-4.56.46,1.3,3.9.79,5.58,0,6.38-2.01,2.04-6.07-1.47-11.85,0-3.2.81-5.22,2.71-8.66,5.92-3.32,3.1-5.1,4.77-5.92,7.75-1.23,4.44.63,8.43,1.37,10.03.76,1.63,1.79,3.84,4.1,5.47.99.69,2.95,1.8,10.03,1.82,6.49.02,8.96-2.62,11.39-.91,2.03,1.43,2.23,4.36,2.28,5.01.21,2.94-1.4,4.15-2.73,7.75-.58,1.57-2.95,7.95-.46,10.94l-.02-.02h0Z"/>
</g>
<g id="BWK10_Highlights" data-name="BWK10 Highlights">
  <path id="BWK10_Highlight1" data-name="BWK10 Highlight1" class="Wirbelkorper_Querfortsatze1525Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1525Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1525Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1525Fill,
          }} d="M166.93,123.3c5.49,2.03,9.01.78,17.32,0,10.19-.96,7.97.64,26.89.46,19.56-.19,20.33-1.88,27.8,0,2.67.67,6.64,1.51,14.58,3.19,7.51,1.59,10.11,2.05,11.85.46,2.93-2.7,1.55-9.61,1.37-10.48-.75-3.55-2.22-5.06-1.37-6.38.99-1.52,3.73-.7,7.29-.91,7.59-.45,12.96-5.27,13.67-5.92,5.34-4.93,6.32-11.24,6.84-14.58.47-3.03,1.02-6.58-.91-8.2-1.45-1.22-3.21-.42-8.66,0-1.71.13-5.81.44-10.03,0-5.68-.59-9.5-.98-11.39-3.65-2.67-3.77,1.11-8.39-1.37-10.94-1.88-1.93-5.15-.42-11.85.46-7.14.93-6.92-.33-18.68,0-8.86.25-8.13.94-17.77,1.37-12.27.54-10.82-.69-20.51,0-9.79.7-12.66,2.06-17.77,0-3.29-1.33-5.66-3.32-7.29-2.28-2.21,1.41.36,6.21-2.28,9.11-1.57,1.73-3.61,1.26-9.57,1.82-2.69.25-7.98.75-12.76,2.28-3.36,1.07-8.31,2.66-12.3,7.29-.81.94-4.85,5.8-5.01,12.76-.06,2.36-.14,6.03,2.28,8.2.56.5,1.67,1.3,7.75,1.82,6.19.53,6.85-.14,9.57.46,3.34.74,4.39,2.2,9.57,5.92,4.72,3.39,8.71,6.25,12.76,7.75h-.02Z"/>
</g>
<g id="Grund_u_Deckplatten_highlights" data-name="Grund u Deckplatten highlights">
  <path id="Grund_u_Deckplatten_highlight4" data-name="Grund u Deckplatten highlight4" class="Grund_Deckplatten1525Fill" style= {{
            fill: props.colors.Grund_Deckplatten1525Fill,
            stroke: props.colors.Grund_Deckplatten1525Fill,
            opacity:props.colors.Grund_Deckplatten1525Fill,
          }} d="M263.05,56.65c-8.09-.9-16.17-1.84-24.26-2.7-8.17-.87-15.66.86-23.78,1.33-8,.46-16.04.08-24.03.86-3.32.32-6.62.83-9.87,1.58-3.79.88-8.29,2.73-11.33-.71-2.57-2.9-6.8,1.36-4.24,4.24,4.69,5.29,11.13,3.66,17.17,2.25,8.79-2.05,17.79-1.8,26.75-2.02,8.46-.21,16.98-2.43,25.4-1.92,9.4.57,18.84,2.04,28.2,3.09,3.83.43,3.8-5.58,0-6Z"/>
  <path id="Grund_u_Deckplatten_highlight3" data-name="Grund u Deckplatten highlight3" class="Grund_Deckplatten1525Fill" style= {{
            fill: props.colors.Grund_Deckplatten1525Fill,
            stroke: props.colors.Grund_Deckplatten1525Fill,
            opacity:props.colors.Grund_Deckplatten1525Fill,
          }} d="M258.59,63.57c-15.18.64-30.35,1.3-45.53,1.93-7.45.31-14.89.59-22.34.75-7.72.17-15.06.24-22.41-2.48-3.63-1.34-5.19,4.45-1.6,5.79,13.77,5.1,30.04,2.6,44.4,2.02,15.82-.64,31.65-1.33,47.47-2.01,3.85-.16,3.87-6.16,0-6Z"/>
  <path id="Grund_u_Deckplatten_highlight2" data-name="Grund u Deckplatten highlight2" class="Grund_Deckplatten1525Fill" style= {{
            fill: props.colors.Grund_Deckplatten1525Fill,
            stroke: props.colors.Grund_Deckplatten1525Fill,
            opacity:props.colors.Grund_Deckplatten1525Fill,
          }} d="M262.99,124.86c-4.11.88-10.05-1.69-14.08-2.55-3.58-.76-7.17-1.49-10.8-2.01-7.33-1.05-14.75.19-22.1.47-7.92.31-15.72-.72-23.62-.72-8.54,0-17.03,2.03-25.55.6-3.78-.64-5.4,5.14-1.6,5.79,8.52,1.44,16.73-.11,25.26-.35,8.51-.24,16.97,1.02,25.5.69,8.7-.34,16.92-1.43,25.56.18,4.47.84,8.91,1.81,13.33,2.88,3.36.81,6.25,1.54,9.68.81,3.77-.81,2.18-6.6-1.6-5.79Z"/>
  <path id="Grund_u_Deckplatten_highlight1" data-name="Grund u Deckplatten highlight1" class="Grund_Deckplatten1525Fill" style= {{
            fill: props.colors.Grund_Deckplatten1525Fill,
            stroke: props.colors.Grund_Deckplatten1525Fill,
            opacity:props.colors.Grund_Deckplatten1525Fill,
          }} d="M265.88,135.38c-8.5-.62-17.32.8-25.8,1.42-8.49.63-16.98,1.28-25.48,1.88-16.94,1.18-33.85-1.15-50.7-2.72-3.84-.36-3.82,5.64,0,6,16.85,1.57,33.76,3.9,50.7,2.72,8.49-.59,16.98-1.25,25.48-1.88,8.48-.62,17.3-2.04,25.8-1.42,3.85.28,3.83-5.72,0-6Z"/>
</g>
</svg>
  );
}

export default Brustwirbelkorper10VdLiegend1525;
