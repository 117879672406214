import style from "./style.scss";

export default function ThoraxInspirationLatStehend2105(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 510.73 671.3"
    >
      <g id="BG">
        <path
          id="bg"
          class="Thorax_Inspiration_lat_stehend2105_cls11"
          d="M381.76,663.22c7.7.5,13.1-1.1,17-2,30.6-6.9,52.7,10.5,61,1,3.4-3.9,1.6-9.1.3-18.9-1.3-10.7.7-25.8,4.7-56,5.2-39.1,17.2-86.4,22.9-112.2,7.2-32.8,9.9-64.6,15.3-128,5.5-64.3.5-43.9,6.7-165,1.3-24.9,2.8-52.8-4.7-89.2-6.2-29.4-9.2-44.2-19.2-57.7C452.96-9.08,376.56-1.68,304.76,5.22c-66.8,6.4-92.9,22.8-108,36-7.9,6.9-15.2,16.1-29.7,34.4-6.9,8.7-27.5,35.1-54.4,80.1-18.1,30.2-27.1,45.4-36.9,67.6-15.2,34.5-21.7,61.6-31.9,104.9-7.8,32.9-12.1,57.7-17,86.2-7.2,40.8-11.3,72.9-14.1,94.8-3.4,26.5-5.2,43.9-6.2,54-3.8,39-1.7,32-3.5,41-2.3,11.4-4.8,17.9-1.4,21,5.6,5.2,22.1-3.8,27.9-7,12.1-6.6,19.7-14.1,20.1-14.5,22.3-22,160.7-61,233-.5,28.3,23.7,43.9,33.1,75,52,3.7,2.2,12.3,7.2,24.1,8h0Z"
        />
      </g>
      <g id="underlays">
        <g id="Sternum" class="Thorax_Inspiration_lat_stehend2105_cls14">
          <path
            id="Sternum3"
            class="Sternum2105Fill"
            style={{
              fill: props.colors.Sternum2105Fill,
              stroke: props.colors.Sternum2105Fill,
            }}
            d="M124.64,163.08c.7-5,4.9-5.8,14-14,3.3-3,14.8-13.6,22-29,4.6-9.8,2.5-10.8,7-18,3-4.8,11-17.5,23.7-17.4,6.3.1,13.3,3.3,17.3,9.4,5,7.6,3.5,17.1,0,23-2.3,3.7-7.9,6.9-19,13s-11.1,5.2-15,8-7.1,6.1-17,21c-11.9,17.8-12.9,21.5-17,22-7.5.8-17.1-9.8-16-18Z"
          />
          <path
            id="Sternum2"
            class="Sternum2105Fill"
            style={{
              fill: props.colors.Sternum2105Fill,
              stroke: props.colors.Sternum2105Fill,
            }}
            d="M47.64,380.08c-5-10.4,5.9-18.7,15-45,7.1-20.5,2.7-22.1,11-46,.9-2.7,5.3-12.8,14-33,7.4-17.2,9.7-22.2,15-35,6.2-15,9.3-22.4,10-25,3.1-11.4,2.9-17.3,7-19,4.9-2,13.1,2.8,15,9,1.2,4-.6,7.2-4,14-1.5,3-8.4,17-12,27-4.5,12.3-2.5,14.3-7,26-3.4,8.8-4.7,8.1-10,19-6.7,13.7-5.5,16.3-15,40-3.2,8-3.9,9.1-6,15-4.2,11.9-6.4,17.9-7,25-1.2,12.9,2.9,16.7-.6,27.3-1.3,3.8-3,9.1-7.4,10.7-5.9,2.1-14.7-3.2-18-10Z"
          />
          <path
            id="Sternum1"
            class="Sternum2105Fill"
            style={{
              fill: props.colors.Sternum2105Fill,
              stroke: props.colors.Sternum2105Fill,
            }}
            d="M47.64,388.08c2.7-1.8,10.9,2.5,13,9,1.3,4.1-.9,5.5-2,14-1.2,9.6,1.2,11.6-1,18-1.7,4.7-5.4,10.5-8,10-3.6-.7-4.6-13.5-4-22,.5-7.4,2-8.4,2-16,0-8.3-1.8-11.8,0-13Z"
          />
        </g>
        <g id="Spine" class="Thorax_Inspiration_lat_stehend2105_cls13">
          <path
            id="Spine11"
            class="Spine2105Fill"
            style={{
              fill: props.colors.Spine2105Fill,
              stroke: props.colors.Spine2105Fill,
            }}
            d="M279.87,595.56c1.9-3.2,4.3-7.9,5-14,1.2-10.3-3-17.1,1-21,1.8-1.8,5-2.5,7.8-3.2,2.7-.7,4.9-.7,6.2-.8,7.9-.2,11.8-.4,17,0,10.5.8,15.8,1.2,21,3,4.9,1.7,14,5,15,11,.5,3.3-1.9,4.6-4,11-1.7,5.2-1.2,7.6-1.8,14.7-1.3,15.6-5.5,23-3.2,24.3,1.7.9,4.2-3.2,13-8,5.6-3.1,8.5-4.7,12-4,6.8,1.3,10.2,9.9,11,12,2.5,6.9-.4,8.9,2,16,.5,1.6,3.2,8.9,10,13,5.3,3.2,6.9.7,18,3,10.1,2.1,12.4,5,21,5,4.5,0,7.4,0,10-2,5.9-4.6,5.7-16.5,1-24-2.4-3.9-6-5.9-13-10-8.4-4.8-11.1-4.6-21-10-6.2-3.4-9.3-5.1-12-8-6.5-7.1-7.7-15.7-8-18-1-7.5,1.5-8.7,1-18-.4-8.3-2.8-17-5-17s-1.8,7.9-8,16c0,0-3.4,4.4-9,8-15.5,9.9-32.76,6.03-47.66,1.73-5.5-1.6-18.94-7.03-28.34-11.53"
          />
          <path
            id="Spine10"
            class="Spine2105Fill"
            style={{
              fill: props.colors.Spine2105Fill,
              stroke: props.colors.Spine2105Fill,
            }}
            d="M440.97,599.06c2-.6,3.6-3.2,6.9-8.5,3.2-5.1,4.8-7.7,5-10,.5-6.8-6.6-12.3-10-15-10.4-8.2-15.3-4.3-22-11-5.8-5.8-2.6-9.3-10-20-5.9-8.5-8.2-6.9-11-13-3.2-7.2-.5-10.5-3-24-.9-4.6-2.4-12.8-5-13-2.3-.1-2.9,6.2-9,14-3.2,4.1-7.2,9.1-12,9-5-.1-6-5.4-13-10-3.9-2.6-5.2-2-22-5-17.1-3.1-21.2-4.7-27-2-1.6.8-5.8,2.7-8,7-2.4,4.7.1,7.3-1,16-.8,6.6-3.1,12-4,14-3,7-5.2,7.7-5,11,.2,3.5,2.8,6.3,11,12,10.8,7.5,16.2,11.2,22,13,1.7.5,25.4,7.5,34-3,4.2-5.1.6-9.3,6-18,2-3.2,6.4-10.3,14-11,6.3-.6,12.1,3.5,15,8,1.6,2.5,2.4,5.3,2,18-.5,17.1-2.3,24.2,1,26,2.2,1.2,3.7-1.5,9-2,6.5-.6,11.9,2.9,21,9s11.5,9.6,15.1,8.5Z"
          />
          <path
            id="Spine9"
            class="Spine2105Fill"
            style={{
              fill: props.colors.Spine2105Fill,
              stroke: props.colors.Spine2105Fill,
            }}
            d="M448.87,547.56c5.9-.6,9.5-8,10-9,3.5-7.2.7-14.3,0-16-1.9-4.7-4.9-7.4-9-11-8-7.1-10.9-5.6-15-11-2.2-2.9-3.5-6.9-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-1.5-2-6.5-9-8-18-1.8-10.9,3-16.8,0-19-3-2.3-11.2,1.7-16,7-4.9,5.3-3.6,8.9-9,14-1.2,1.1-5.1,4.7-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.1,3.5,3,5.1,10,12,5.1,4.9,10,10,15,15,14.3,14.3,16.3,18.5,21,18Z"
          />
          <path
            id="Spine8"
            class="Spine2105Fill"
            style={{
              fill: props.colors.Spine2105Fill,
              stroke: props.colors.Spine2105Fill,
            }}
            d="M457.17,490.76c5.9-.6,9.5-8,10-9,3.5-7.2.7-14.3,0-16-1.9-4.7-4.9-7.4-9-11-8-7.1-10.8-5.6-15-11-2.2-2.9-3.5-6.9-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-1.5-2-6.5-9-8-18-1.8-10.9,3-16.8,0-19-3-2.3-11.2,1.7-16,7-4.9,5.3-3.6,8.9-9,14-1.2,1.1-5.1,4.7-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.1,3.5,3,5.1,10,12,5.1,4.9,10,10,15,15,14.3,14.3,16.3,18.5,21,18Z"
          />
          <path
            id="Spine7"
            class="Spine2105Fill"
            style={{
              fill: props.colors.Spine2105Fill,
              stroke: props.colors.Spine2105Fill,
            }}
            d="M467.87,435.56c4.4-1.2,6.3-8.9,7-12,.5-2.1,1.6-6.8,0-12-2-6.4-5.8-6.2-11-14-.8-1.2-2.8-6-6.8-15.6-3.4-8.2-5.2-12.3-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-2.8-3.8-4.9-8.2-8-18-3.8-12-3.5-18.8-5.7-19s-2.5,6.5-9.5,13.6c-2.7,2.8-5.3,4.5-9.8,7.4-5.4,3.5-8.1,5.4-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.6,5,4.5,6,10,12,7.6,8.4,5.7,11,13.8,22.6,5.3,7.5,13,18.6,19,17Z"
          />
          <path
            id="Spine6"
            class="Spine2105Fill"
            style={{
              fill: props.colors.Spine2105Fill,
              stroke: props.colors.Spine2105Fill,
            }}
            d="M468.87,383.56c5.4.6,12.2-6.7,13-14,.3-2.5,0-6-6-15-6.6-10-10.7-12.2-16-19-6.4-8.1-9-15.5-13-27-4.4-12.5-3.1-14.4-8-30-4.5-14.3-8.2-25.6-14-26-3.5-.2-6.9,3.5-12,9-3.9,4.2-5.9,7.8-11,10-2,.8-4,1.3-4.6,1.4-4.5,1-8,.7-9.4.6-23.1-1.9-15.4-.4-29-2-10.3-1.2-12.7-1.9-15,0-3.1,2.6-1.9,6.5-3,16-1.7,15.3-6.2,17-4,22,2.3,5.2,10.9,7.1,28,11,1,.2,8.7,1.9,19,2,1.3,0,6-.4,12-2,5.6-1.5,8.5-2.3,10-4,4.3-4.8-.4-11,4-17,2.2-3,6.1-5.3,10-5,4.2.3,6.9,3.5,9,6,4.2,5.1,5.3,10.1,8,21,2.5,10,3.7,15,4,16,3.6,10.8,6.8,11.4,16,28,7.2,13,8,17.5,12,18Z"
          />
          <path
            id="Spine5"
            class="Spine2105Fill"
            style={{
              fill: props.colors.Spine2105Fill,
              stroke: props.colors.Spine2105Fill,
            }}
            d="M397.87,265.56c3.4-3.2-.2-8.5,3-17,.9-2.2,2.7-7.2,7-9,5.5-2.3,12,2,15,4,7.4,5,10.5,11,22,34,5.5,11.1,7.3,14.9,11,21,8.3,14,13,21.6,18,21,5.4-.6,10.1-10.7,9-19-.2-1.6-1-5.7-9-15-10.1-11.7-13.3-10.2-19-18-7.3-9.9-3.2-13.8-11-25-7.1-10.3-10.8-7.6-15-16-5.2-10.5-.9-17.8-8-26-2.3-2.6-6.4-6.1-9-5-2.9,1.3-1,7.3-4,14-3.4,7.5-10.8,11-13,12-7.4,3.5-15,2.7-30,1-11.6-1.3-16.4-3-19,0-2.4,2.7-.5,6.3,0,13,1.4,17.7-9.1,26.2-5,31,3.1,3.6,8.5-1.9,26-1,7,.4,14,.5,21,1,5.7.4,8.1.7,10-1Z"
          />
          <path
            id="Spine4"
            class="Spine2105Fill"
            style={{
              fill: props.colors.Spine2105Fill,
              stroke: props.colors.Spine2105Fill,
            }}
            d="M447.87,200.56c4,9.6,9.1,14.9,13,18,8.2,6.5,14.6,6,18,13,2.2,4.5,2.2,10.3,0,15-.8,1.6-3.4,7.3-8,8-6.3,1-12.5-7.7-19-17-3.2-4.6-5.5-8.7-10-17-5.9-10.9-8.9-16.3-11-21-3.7-8.2-4.2-11.1-8-14-.7-.5-7.6-5.7-14-3-6.2,2.6-7.6,10.9-8,13-1.3,7.6,2.1,10.7,0,15-2.1,4.4-7.8,5.8-14,7-27.9,5.6-41.8,8.4-46,2-.4-.6-.6-5.1-1-14-.6-12.6-.5-13.3-1-16-1.7-8.3-4.4-10.2-3-13,1.5-3,5.7-2.8,17-4,16.4-1.8,10.7-2.6,30-5,6-.8,8.4-.9,11-3,4.1-3.4,3.2-7.2,8-14,1.9-2.7,6.2-8.7,10-8,2.7.5,3.2,4,7,10,2.9,4.6,5.9,7.9,8,10"
          />
          <path
            id="Spine3"
            class="Spine2105Fill"
            style={{
              fill: props.colors.Spine2105Fill,
              stroke: props.colors.Spine2105Fill,
            }}
            d="M409.87,103.56c-5.6-12.1-8.5-14.4-10-14-1.9.4-1.4,4.8-4,12-.8,2.2-3.6,9.9-9,15-4.9,4.6-10.5,5.5-19,7-11.9,2-16.8.2-31,3-7.7,1.5-10.1,2.8-11,5-1.5,3.8,3.6,6.5,6,15,2.8,9.7-1.5,14.5,2,18,.9.9,2.9,2.2,18,0,16.2-2.4,17-4.4,25-4,7.1.3,9.4,2,12,0,4-3.1.7-9,5-17,1.1-2.1,4.1-7.6,10-9,6.6-1.5,12.2,3.3,13,4"
          />
          <path
            id="Spine2"
            class="Spine2105Fill"
            style={{
              fill: props.colors.Spine2105Fill,
              stroke: props.colors.Spine2105Fill,
            }}
            d="M386.87,55.56c-1,3.5-3.3,9.6-9,14-2.2,1.7-5,3.2-12,5-9.6,2.5-14.3,2.4-25,4-20.2,3-21.6,6-22,7-1.3,3.6,3.3,5.4,5,13,2.5,10.8-4.2,18.2-1,21,1.9,1.7,4.8-.3,14-3,5.9-1.7,15-4.4,25-5,11-.6,13.5,1.9,18-1,4.3-2.8,4.1-6.7,9-14,3.8-5.8,8.1-9.6,11-12"
          />
          <path
            id="Spine1"
            class="Spine2105Fill"
            style={{
              fill: props.colors.Spine2105Fill,
              stroke: props.colors.Spine2105Fill,
            }}
            d="M393.87,46.56c-4.7-2.6-8.9-3.6-12-2-3.4,1.8-3.3,5.5-7,12-.9,1.6-4.6,8-10,12-5.8,4.2-9.8,2.9-21,5-18.3,3.4-25.5,10.4-29,7-2.8-2.7,1.2-7.7,0-17-1-7.6-5-13.4-8-17"
          />
        </g>
        <ellipse
          id="Lungenspitze"
          class="Lungenspitze2105Fill"
          style={{
            fill: props.colors.Lungenspitze2105Fill,
            stroke: props.colors.Lungenspitze2105Fill,
          }}
          cx="354"
          cy="36.87"
          rx="44.92"
          ry="17.58"
        />
        <path
          id="winkel"
          class="Zwerchfellrippenwinkel2105Fill"
          style={{
            fill: props.colors.Zwerchfellrippenwinkel2105Fill,
            stroke: props.colors.Zwerchfellrippenwinkel2105Fill,
          }}
          d="M351.23,616.61c-.18,1.5,1.38,4.72,3.84,6.27.1.06.19.12.28.17.93.52,1.67,1.31,2.05,2.3.47,1.23,1.27,3.02,2.42,4.54.76,1,1.51,1.52,2.34,3.12.39.75.58,1.36.65,1.5,1.02,2.14,2.74,4.69,8.73,13.34,13.67,19.75,8.24,11.58,9.38,13.28,5.03,7.53,6.22,10.14,8.59,10.16,2.31.01,4.14-2.6,7.81-7.81,2.33-3.31,3.95-6.31,5.47-9.38,2.86-5.77,2.77-6.6,5.47-12.5,3.92-8.58,5.43-9.75,7.81-16.41,2.02-5.65,3.03-8.48,2.34-10.16-2.61-6.38-18.09-5.47-41.41-3.91-21.95,1.48-25.57,3.69-25.78,5.47Z"
        />
        <g id="WKhinterkanten">
          <path
            id="WKhinterkanten10"
            class="WKhinterkanten2105Fill"
            style={{
              fill: props.colors.WKhinterkanten2105Fill,
              stroke: props.colors.WKhinterkanten2105Fill,
            }}
            d="M375.28,56.35c-.46-.52-1.28-.47-1.68.09l-2.26,3.16-3.04,4.56-3.65,3.04-6.38,2.43-4.56,1.52,3.04,1.22,2.62-.2c.88-.07,1.75-.24,2.59-.52l3.29-1.1,3.95-3.04,3.95-3.95,2.36-3.54c.76-1.13.65-2.63-.25-3.65h0Z"
          />
          <path
            id="WKhinterkanten9"
            class="WKhinterkanten2105Fill"
            style={{
              fill: props.colors.WKhinterkanten2105Fill,
              stroke: props.colors.WKhinterkanten2105Fill,
            }}
            d="M386.84,96.66c-.71,1.72-1.42,3.44-2.13,5.16l-2.73,4.86-3.34,2.73h-6.38l-6.68.3-2.73.3c-.17,1.49.94,2.83,2.44,2.95l1.21.09h13.06l3.04-1.22,2.43-3.04,3.65-6.08.85-1.91c.86-.88.98-2.25.29-3.24-.65-.93-1.87-1.32-2.96-.92Z"
          />
          <path
            id="WKhinterkanten8"
            class="WKhinterkanten2105Fill"
            style={{
              fill: props.colors.WKhinterkanten2105Fill,
              stroke: props.colors.WKhinterkanten2105Fill,
            }}
            d="M395.18,147.06l-.75,3.37-.3,4.56-1.22,4.25-1.82,2.43-3.34,1.52-3.34-.3-3.34-1.22h-4.25l-2.73-.3.3-3.04,4.86.61,5.16.3,3.34.3,2.43-3.65.3-6.08,1.73-5.44c.2-.64.95-.92,1.52-.57l.08.05c1.1.66,1.65,1.95,1.37,3.2Z"
          />
          <polygon
            id="WKhinterkanten7"
            class="WKhinterkanten2105Fill"
            style={{
              fill: props.colors.WKhinterkanten2105Fill,
              stroke: props.colors.WKhinterkanten2105Fill,
            }}
            points="399.6 198.44 400.21 204.21 400.81 207.85 398.99 210.89 395.95 213.02 392.31 213.93 389.27 215.45 389.88 217.58 392.61 217.58 395.95 216.36 399.29 214.84 402.33 212.41 404.46 209.07 403.55 204.51 403.24 199.96 402.03 196.61 399.6 198.44"
          />
          <path
            id="WKhinterkanten6"
            class="WKhinterkanten2105Fill"
            style={{
              fill: props.colors.WKhinterkanten2105Fill,
              stroke: props.colors.WKhinterkanten2105Fill,
            }}
            d="M398.79,253.9l.2,2.56v4.25l-1.22,2.43-2.73,1.52-4.56-.3h-3.34l-2.73-.3v2.73c3.65,1.52,7.59,1.22,7.59,1.22h4.56l3.34-1.82,2.73-3.04v-9.11l-2.65-1.02c-.6-.23-1.24.24-1.19.89Z"
          />
          <path
            id="WKhinterkanten5"
            class="WKhinterkanten2105Fill"
            style={{
              fill: props.colors.WKhinterkanten2105Fill,
              stroke: props.colors.WKhinterkanten2105Fill,
            }}
            d="M406.66,305.49l-.07.79.3,4.25-1.82,3.65-3.95,2.43-6.08,1.52-4.25,1.22h-1.28c-1.44,0-2.58,1.22-2.48,2.66h0c.06.9.81,1.59,1.71,1.59h2.05l4.56-.61,3.95-.61,4.86-2.13,3.95-2.13,2.13-2.73.61-3.04.3-3.95-.21-3.43c-.05-.84-.68-1.52-1.51-1.65h0c-1.37-.21-2.64.78-2.76,2.16Z"
          />
          <path
            id="WKhinterkanten4"
            class="WKhinterkanten2105Fill"
            style={{
              fill: props.colors.WKhinterkanten2105Fill,
              stroke: props.colors.WKhinterkanten2105Fill,
            }}
            d="M400.21,359.14l-3.34,7.29-2.73,4.56-4.25,4.56-5.16,3.65-4.86,2.13h-4.61c-1.21,0-2.26.84-2.53,2.02h0c-.24,1.04.47,2.06,1.52,2.2l2.59.35,6.08-.61,3.78-1.11c.91-.27,1.75-.75,2.45-1.4l3.8-3.57,6.68-7.9,3.34-5.47,2.13-6.38.65-2.81c.15-.66-.2-1.32-.83-1.56h0c-1.56-.6-3.31.21-3.87,1.79l-.81,2.28Z"
          />
          <path
            id="WKhinterkanten3"
            class="WKhinterkanten2105Fill"
            style={{
              fill: props.colors.WKhinterkanten2105Fill,
              stroke: props.colors.WKhinterkanten2105Fill,
            }}
            d="M385.62,422.64l-2.73,5.47-3.65,6.08-4.25,3.34-3.95,3.34-5.16,2.43h-.67c-1.74,0-3.25,1.2-3.64,2.9h0c-.12.54.28,1.05.84,1.05h3.78l4.2-.6c1.42-.2,2.74-.83,3.78-1.81l2.95-2.75,4.26-2.84c.8-.53,1.47-1.22,1.99-2.02l1.95-3.03,3.34-6.38,2.03-4.82c.24-.56.06-1.21-.42-1.58h0c-1.58-1.22-3.88-.6-4.65,1.24h0Z"
          />
          <path
            id="WKhinterkanten2"
            class="WKhinterkanten2105Fill"
            style={{
              fill: props.colors.WKhinterkanten2105Fill,
              stroke: props.colors.WKhinterkanten2105Fill,
            }}
            d="M377.12,480.36l-3.04,5.77-5.16,6.08-3.95,4.56-4.25,2.43-6.08.61h-1.56c-1.22,0-2.27.85-2.52,2.04h0c-.26,1.2.55,2.37,1.76,2.56l1.71.26,3.95-.61,6.08-1.52,5.39-3.34c.65-.4,1.23-.92,1.71-1.52l4.13-5.17,3.95-5.16,3.04-7.29,1.14-3.87c.21-.71-.19-1.47-.91-1.69h0c-1.51-.46-3.12.3-3.72,1.76l-1.68,4.1Z"
          />
          <path
            id="WKhinterkanten1"
            class="WKhinterkanten2105Fill"
            style={{
              fill: props.colors.WKhinterkanten2105Fill,
              stroke: props.colors.WKhinterkanten2105Fill,
            }}
            d="M363.14,541.42l-2.13,5.16-1.22,4.25-.91,5.16-2.43,2.43-2.73,2.73-5.16,1.82-4.56.91h-2.71c-1.22,0-2.24.93-2.35,2.14v.09c-.06.62.37,1.18.99,1.28l2.85.44,4.25-.3,5.77-.91,3.24-1.29c.87-.35,1.65-.88,2.29-1.56l2.54-2.7c.88-.94,1.48-2.1,1.72-3.37l1.15-5.97,1.82-6.38,2.13-4.56,2.37-2.73c.93-1.07,1.01-2.64.2-3.81l-.03-.04c-.34-.49-1.08-.48-1.41.02l-1.74,2.61-3.95,4.56Z"
          />
        </g>
        <path
          id="retrokardialen"
          class="retrokardialen_Lunge2105Fill"
          style={{
            fill: props.colors.retrokardialen_Lunge2105Fill,
            stroke: props.colors.retrokardialen_Lunge2105Fill,
          }}
          d="M227.11,422.49c16.86-38.98,26.21-79.14,69.69-53.64,20.33,11.92,25.51,48.54,8.66,87.52-16.86,38.98-48.06,63-69.69,53.64-21.64-9.36-25.51-48.54-8.66-87.52Z"
        />
      </g>
      <g id="Thorax">
        <path
          id="Thorax64"
          class="Thorax_Inspiration_lat_stehend2105_cls3"
          d="M272.76,223.22c3.7,6,18,30.4,21,60h0c3.2,32.2,3.6,28.9,4.4,35.6,2.4,21.8-3.5,40.7-4.4,43.4-1.7,5.4-4,11-7.7,23.4-.2.8-2.4,8-4.3,15.6-4.8,19.1-4.9,27.5-8,43-3.5,17.6-8.1,30.5-13,44s-9.8,25-14,34"
        />
        <path
          id="Thorax63"
          class="Thorax_Inspiration_lat_stehend2105_cls1"
          d="M319.76,187.22c-8.6-12.9-15.2-18.9-19-22-9.3-7.6-18-11.4-22-13-3.4-1.4-7.9-3.1-14-4-2.5-.4-9.9-1.3-22,1-15.3,2.9-26.2,8.8-32,12-4.5,2.5-15.4,8.8-27,20-6.9,6.6-12.9,12.5-18,22-5.2,9.8-3.1,11.6-9,26-1.6,3.8-6,14.2-14,26-5.5,8.1-6.6,8-17,22-5.6,7.5-9.8,13.6-12,17-7.9,11.8-13.4,21.2-15,24-6.6,11.5-11.3,19.6-16,31-2.4,5.8-7.5,19-11,37-3.6,18.3-5.3,27.4-3,39,.5,2.7,5.7,13.2,16,34,7.3,14.8,9.9,19.7,16,24,7.1,5,14.5,6.2,20,7,10.6,1.6,18.7,0,29-2,8.8-1.7,15.7-3.1,24-7,12.2-5.7,19.5-13.3,26-20,4.7-4.9,14.7-16,23-33,3-6.2,8.6-17.9,11-34,.5-3,1.3-9.5,0-35-1.1-21.3-1.6-32-3-39-4.1-21.1-9.1-20.8-16-49-2.1-8.6-4.8-21.4-3-37,1.4-11.9,4.3-16.8,7-20,6.2-7.3,14.7-9.2,18-10,3.1-.7,11.6-2.6,20,1,2.7,1.2,7.3,3.8,16,18"
        />
        <path
          id="Thorax62"
          class="Thorax_Inspiration_lat_stehend2105_cls3"
          d="M291.76,559.22c3.6-8.4,8.5-20,14-34,7-17.8,11.5-29.2,16-44,4.1-13.3,6.7-25.5,12-50,3.5-16.4,3.2-15.9,10-50,7.5-37.5,8.2-39.2,9-48,1.9-21.4.2-38-1-49-1.3-12.1-3.4-30.3-11-53-8-24-17.6-38.9-21-44"
        />
        <path
          id="Thorax61"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M398.76,661.22c1.8-3.6,4.2-8.7,7-15,4.5-10.2,6.7-15.3,8-20,3.7-13.7-.4-16.2,3-33,1.3-6.6,1.6-4.5,5-18,3.4-13.6,3.3-16.4,6-23,3.7-9,5.5-7.6,10-17,3.3-6.9,8.3-17.5,7-30-.8-7.5-3.2-10.1-2-17,.9-5.1,3-7.9,6-13,3.4-5.8,8.7-14.8,12-26,2.4-8.3,5-17.2,3-28-1.4-7.6-3.8-9.5-4-17-.1-5.4,1.1-7.8,4-18,4.2-14.9,6.3-22.4,6-29-.3-5.8-1.7-11.2-4-20-1.9-7.3-3.2-10.4-3-16,.3-5.7,1.9-7.1,3-13,1.2-6.3.5-10.8-1-21-1.3-9-2.5-18-4-27-2.1-12.2-1.3-10.8-5-40-3.1-24.3-4.6-36.4-7-48-2-9.6-1.9-7.4-9-35-5.3-20.6-8.1-32.8-14-54-5.8-20.7-7.6-25.4-11-31-2.6-4.4-10.3-16.7-24-25-32.1-19.5-72.6-1.7-81,2-13.1,5.7-25.5,14.5-29,17-8.9,6.3-15.3,12.1-22,18-12.8,11.4-19.2,17.1-24,23-6.1,7.5-7.6,11.2-13,13-8.9,3-18-2.8-20-4-.9-.6-10.6-6.9-13-19-.7-3.6-1.8-8.7,1-13,2.7-4.1,6.9-4.2,15-7,8-2.8,13.2-5.9,23-12,4.5-2.8,10.8-7,18-13"
        />
        <path
          id="Thorax60"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M280.76,595.22c1.9-3.2,4.3-7.9,5-14,1.2-10.3-3-17.1,1-21,1.8-1.8,5-2.5,7.8-3.2,2.7-.7,4.9-.7,6.2-.8,7.9-.2,11.8-.4,17,0,10.5.8,15.8,1.2,21,3,4.9,1.7,14,5,15,11,.5,3.3-1.9,4.6-4,11-1.7,5.2-1.2,7.6-1.8,14.7-1.3,15.6-5.5,23-3.2,24.3,1.7.9,4.2-3.2,13-8,5.6-3.1,8.5-4.7,12-4,6.8,1.3,10.2,9.9,11,12,2.5,6.9-.4,8.9,2,16,.5,1.6,3.2,8.9,10,13,5.3,3.2,6.9.7,18,3,10.1,2.1,12.4,5,21,5,4.5,0,7.4,0,10-2,5.9-4.6,5.7-16.5,1-24-2.4-3.9-6-5.9-13-10-8.4-4.8-11.1-4.6-21-10-6.2-3.4-9.3-5.1-12-8-6.5-7.1-7.7-15.7-8-18-1-7.5,1.5-8.7,1-18-.4-8.3-2.8-17-5-17s-1.8,7.9-8,16c0,0-3.4,4.4-9,8-15.5,9.9-38.1,3.3-53-1-5.5-1.6-13.6-4.3-23-8.8"
        />
        <path
          id="Thorax59"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M441.86,598.72c2-.6,3.6-3.2,6.9-8.5,3.2-5.1,4.8-7.7,5-10,.5-6.8-6.6-12.3-10-15-10.4-8.2-15.3-4.3-22-11-5.8-5.8-2.6-9.3-10-20-5.9-8.5-8.2-6.9-11-13-3.2-7.2-.5-10.5-3-24-.9-4.6-2.4-12.8-5-13-2.3-.1-2.9,6.2-9,14-3.2,4.1-7.2,9.1-12,9-5-.1-6-5.4-13-10-3.9-2.6-5.2-2-22-5-17.1-3.1-21.2-4.7-27-2-1.6.8-5.8,2.7-8,7-2.4,4.7.1,7.3-1,16-.8,6.6-3.1,12-4,14-3,7-5.2,7.7-5,11,.2,3.5,2.8,6.3,11,12,10.8,7.5,16.2,11.2,22,13,1.7.5,25.4,7.5,34-3,4.2-5.1.6-9.3,6-18,2-3.2,6.4-10.3,14-11,6.3-.6,12.1,3.5,15,8,1.6,2.5,2.4,5.3,2,18-.5,17.1-2.3,24.2,1,26,2.2,1.2,3.7-1.5,9-2,6.5-.6,11.9,2.9,21,9s11.5,9.6,15.1,8.5Z"
        />
        <path
          id="Thorax58"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M449.76,547.22c5.9-.6,9.5-8,10-9,3.5-7.2.7-14.3,0-16-1.9-4.7-4.9-7.4-9-11-8-7.1-10.9-5.6-15-11-2.2-2.9-3.5-6.9-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-1.5-2-6.5-9-8-18-1.8-10.9,3-16.8,0-19-3-2.3-11.2,1.7-16,7-4.9,5.3-3.6,8.9-9,14-1.2,1.1-5.1,4.7-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.1,3.5,3,5.1,10,12,5.1,4.9,10,10,15,15,14.3,14.3,16.3,18.5,21,18Z"
        />
        <path
          id="Thorax57"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M458.06,490.42c5.9-.6,9.5-8,10-9,3.5-7.2.7-14.3,0-16-1.9-4.7-4.9-7.4-9-11-8-7.1-10.8-5.6-15-11-2.2-2.9-3.5-6.9-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-1.5-2-6.5-9-8-18-1.8-10.9,3-16.8,0-19-3-2.3-11.2,1.7-16,7-4.9,5.3-3.6,8.9-9,14-1.2,1.1-5.1,4.7-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.1,3.5,3,5.1,10,12,5.1,4.9,10,10,15,15,14.3,14.3,16.3,18.5,21,18Z"
        />
        <path
          id="Thorax56"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M468.76,435.22c4.4-1.2,6.3-8.9,7-12,.5-2.1,1.6-6.8,0-12-2-6.4-5.8-6.2-11-14-.8-1.2-2.8-6-6.8-15.6-3.4-8.2-5.2-12.3-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-2.8-3.8-4.9-8.2-8-18-3.8-12-3.5-18.8-5.7-19s-2.5,6.5-9.5,13.6c-2.7,2.8-5.3,4.5-9.8,7.4-5.4,3.5-8.1,5.4-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.6,5,4.5,6,10,12,7.6,8.4,5.7,11,13.8,22.6,5.3,7.5,13,18.6,19,17Z"
        />
        <path
          id="Thorax55"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M469.76,383.22c5.4.6,12.2-6.7,13-14,.3-2.5,0-6-6-15-6.6-10-10.7-12.2-16-19-6.4-8.1-9-15.5-13-27-4.4-12.5-3.1-14.4-8-30-4.5-14.3-8.2-25.6-14-26-3.5-.2-6.9,3.5-12,9-3.9,4.2-5.9,7.8-11,10-2,.8-4,1.3-4.6,1.4-4.5,1-8,.7-9.4.6-23.1-1.9-15.4-.4-29-2-10.3-1.2-12.7-1.9-15,0-3.1,2.6-1.9,6.5-3,16-1.7,15.3-6.2,17-4,22,2.3,5.2,10.9,7.1,28,11,1,.2,8.7,1.9,19,2,1.3,0,6-.4,12-2,5.6-1.5,8.5-2.3,10-4,4.3-4.8-.4-11,4-17,2.2-3,6.1-5.3,10-5,4.2.3,6.9,3.5,9,6,4.2,5.1,5.3,10.1,8,21,2.5,10,3.7,15,4,16,3.6,10.8,6.8,11.4,16,28,7.2,13,8,17.5,12,18Z"
        />
        <path
          id="Thorax54"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M398.76,265.22c3.4-3.2-.2-8.5,3-17,.9-2.2,2.7-7.2,7-9,5.5-2.3,12,2,15,4,7.4,5,10.5,11,22,34,5.5,11.1,7.3,14.9,11,21,8.3,14,13,21.6,18,21,5.4-.6,10.1-10.7,9-19-.2-1.6-1-5.7-9-15-10.1-11.7-13.3-10.2-19-18-7.3-9.9-3.2-13.8-11-25-7.1-10.3-10.8-7.6-15-16-5.2-10.5-.9-17.8-8-26-2.3-2.6-6.4-6.1-9-5-2.9,1.3-1,7.3-4,14-3.4,7.5-10.8,11-13,12-7.4,3.5-15,2.7-30,1-11.6-1.3-16.4-3-19,0-2.4,2.7-.5,6.3,0,13,1.4,17.7-9.1,26.2-5,31,3.1,3.6,8.5-1.9,26-1,7,.4,14,.5,21,1,5.7.4,8.1.7,10-1Z"
        />
        <path
          id="Thorax53"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M448.76,200.22c4,9.6,9.1,14.9,13,18,8.2,6.5,14.6,6,18,13,2.2,4.5,2.2,10.3,0,15-.8,1.6-3.4,7.3-8,8-6.3,1-12.5-7.7-19-17-3.2-4.6-5.5-8.7-10-17-5.9-10.9-8.9-16.3-11-21-3.7-8.2-4.2-11.1-8-14-.7-.5-7.6-5.7-14-3-6.2,2.6-7.6,10.9-8,13-1.3,7.6,2.1,10.7,0,15-2.1,4.4-7.8,5.8-14,7-27.9,5.6-41.8,8.4-46,2-.4-.6-.6-5.1-1-14-.6-12.6-.5-13.3-1-16-1.7-8.3-4.4-10.2-3-13,1.5-3,5.7-2.8,17-4,16.4-1.8,10.7-2.6,30-5,6-.8,8.4-.9,11-3,4.1-3.4,3.2-7.2,8-14,1.9-2.7,6.2-8.7,10-8,2.7.5,3.2,4,7,10,2.9,4.6,5.9,7.9,8,10"
        />
        <path
          id="Thorax52"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M410.76,103.22c-5.6-12.1-8.5-14.4-10-14-1.9.4-1.4,4.8-4,12-.8,2.2-3.6,9.9-9,15-4.9,4.6-10.5,5.5-19,7-11.9,2-16.8.2-31,3-7.7,1.5-10.1,2.8-11,5-1.5,3.8,3.6,6.5,6,15,2.8,9.7-1.5,14.5,2,18,.9.9,2.9,2.2,18,0,16.2-2.4,17-4.4,25-4,7.1.3,9.4,2,12,0,4-3.1.7-9,5-17,1.1-2.1,4.1-7.6,10-9,6.6-1.5,12.2,3.3,13,4"
        />
        <path
          id="Thorax51"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M387.76,55.22c-1,3.5-3.3,9.6-9,14-2.2,1.7-5,3.2-12,5-9.6,2.5-14.3,2.4-25,4-20.2,3-21.6,6-22,7-1.3,3.6,3.3,5.4,5,13,2.5,10.8-4.2,18.2-1,21,1.9,1.7,4.8-.3,14-3,5.9-1.7,15-4.4,25-5,11-.6,13.5,1.9,18-1,4.3-2.8,4.1-6.7,9-14,3.8-5.8,8.1-9.6,11-12"
        />
        <path
          id="Thorax50"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M394.76,46.22c-4.7-2.6-8.9-3.6-12-2-3.4,1.8-3.3,5.5-7,12-.9,1.6-4.6,8-10,12-5.8,4.2-9.8,2.9-21,5-18.3,3.4-25.5,10.4-29,7-2.8-2.7,1.2-7.7,0-17-1-7.6-5-13.4-8-17"
        />
        <path
          id="Thorax49"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M60.76,435.22c-.7,3.5-1.4,8.3-2,14-1.3,13.7.3,15.4,1,16,3.9,3.3,12.1-.6,15-2,7-3.3,9.5-7.3,15-12,7.1-6.2,12-7.4,21-12,13.6-6.9,20.7-13.5,33-23,29.7-23.1,44.2-28.5,80-55,30.4-22.5,24-21.6,65-54,37.2-29.5,55.9-44.2,78-58,25.9-16.1,45.7-25.4,65-50,8.3-10.5,13.7-20.2,17-27"
        />
        <path
          id="Thorax48"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M31.76,536.22c-.2,2.8-.5,7.3-1,13-1,11.2-1.8,14.8,0,16,2.8,1.9,9.6-3.3,13-6,11.6-9,24.1-17.1,35-27,9.5-8.6,25.4-23.1,45-31,21.8-8.8,41.7-7,51-6,9.1.9,28.6,4,80,38,34.8,23,54.7,36.2,78,61,23.7,25.3,39.2,50.3,49,69"
        />
        <path
          id="Thorax47"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M402.76,618.22c-1.3,6.8-3.2,14.9-6,15-3.3.1-5.9-11.5-9-22,0,0-2.8-9.6-21-49-13.4-29-42.2-48.2-90-80-37.4-24.9-58.2-38.4-91-45-21.2-4.3-39.5-4-52-3"
        />
        <path
          id="Thorax46"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M91.76,298.22c7.9-.8,19.3-2.1,33-4,22.4-3.1,33.7-4.7,44-8,19-6.1,17.7-10.4,38-18,11.5-4.3,27.4-10.3,48-10,11.9.2,25.6,3.5,53,10,6.7,1.6,12.2,3,16,4"
        />
        <path
          id="Thorax45"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M124.76,163.22c.7-5,4.9-5.8,14-14,3.3-3,14.8-13.6,22-29,4.6-9.8,2.5-10.8,7-18,3-4.8,11-17.5,23.7-17.4,6.3.1,13.3,3.3,17.3,9.4,5,7.6,3.5,17.1,0,23-2.3,3.7-7.9,6.9-19,13s-11.1,5.2-15,8-7.1,6.1-17,21c-11.9,17.8-12.9,21.5-17,22-7.5.8-17.1-9.8-16-18Z"
        />
        <path
          id="Thorax44"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M47.76,380.22c-5-10.4,5.9-18.7,15-45,7.1-20.5,2.7-22.1,11-46,.9-2.7,5.3-12.8,14-33,7.4-17.2,9.7-22.2,15-35,6.2-15,9.3-22.4,10-25,3.1-11.4,2.9-17.3,7-19,4.9-2,13.1,2.8,15,9,1.2,4-.6,7.2-4,14-1.5,3-8.4,17-12,27-4.5,12.3-2.5,14.3-7,26-3.4,8.8-4.7,8.1-10,19-6.7,13.7-5.5,16.3-15,40-3.2,8-3.9,9.1-6,15-4.2,11.9-6.4,17.9-7,25-1.2,12.9,2.9,16.7-.6,27.3-1.3,3.8-3,9.1-7.4,10.7-5.9,2.1-14.7-3.2-18-10Z"
        />
        <path
          id="Thorax43"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M47.76,388.22c2.7-1.8,10.9,2.5,13,9,1.3,4.1-.9,5.5-2,14-1.2,9.6,1.2,11.6-1,18-1.7,4.7-5.4,10.5-8,10-3.6-.7-4.6-13.5-4-22,.5-7.4,2-8.4,2-16,0-8.3-1.8-11.8,0-13Z"
        />
        <line
          id="Thorax42"
          class="Thorax_Inspiration_lat_stehend2105_cls4"
          x1="231.96"
          y1="403.32"
          x2="262.76"
          y2="403.22"
        />
        <line
          id="Thorax41"
          class="Thorax_Inspiration_lat_stehend2105_cls4"
          x1="315.06"
          y1="406.82"
          x2="280.76"
          y2="405.32"
        />
        <path
          id="Thorax40"
          class="Thorax_Inspiration_lat_stehend2105_cls5"
          d="M1.76,625.22c.6-10.6,1.6-25,3-42,2.7-31.7,5.7-55.4,8-74,2.1-16.8,5.8-46,12-83,5.7-34.3,10.3-62,19-98,11.5-47.6,19.3-80,39-120,8.8-17.9,17.4-32,32-56,12.9-21.2,25.3-41.5,44-66,14.7-19.2,28.1-34.4,38-45"
        />
        <path
          id="Thorax39"
          class="Thorax_Inspiration_lat_stehend2105_cls5"
          d="M485.76,35.22c4.8,10.2,11.1,25.2,16,44,7.3,28.1,8,50.7,8,80,0,45.6-1.6,80.5-3,110-3,64-4.6,96.4-6,111-2,19.8-5.7,57.2-15,105-9.8,50.7-16.8,66.6-22,110-3.4,28.3-4,51.7-4,67"
        />
        <path
          id="Thorax38"
          class="Thorax_Inspiration_lat_stehend2105_cls5"
          d="M465.76,123.22c6.3,12.5,12.4,27.5,17,45,2.4,9.3,3.9,21.2,7,45,2.5,19.6,2.7,26.1-1,33-3.9,7.2-9.9,10.8-19,16-14.7,8.5-21.8,8.2-40,15-7.5,2.8-17,7.5-36,17-18.5,9.2-26.7,14-40.2,15-7.7.6-15.5,1.1-20.8-4-9.5-9.2-2.5-29-1.8-31,3.3-9,7.1-10.5,12.8-20,5.6-9.5,6.5-15.9,10.6-33.5,10.2-43.5,12.6-36.9,13.3-50.8.8-15.1-2-24.2,1.1-46.8.7-5.1,1.5-9.2,2-11.9"
        />
        <path
          id="Thorax37"
          class="Thorax_Inspiration_lat_stehend2105_cls5"
          d="M366.76,87.22c1.2,26.5-1.4,47.6-4,62-3.9,21.2-6.1,18.4-10,41-3.7,21.6-3,31.9-12,41-4.6,4.6-9,6.3-12,13-3.6,8.1-1.9,16.1-1,20,.8,3.8,3.5,15.1,14,23,2.6,2,10.7,8,21,7,2.3-.2,9.1-1.2,23-15,6-6,14.2-15.1,22-28"
        />
        <path
          id="Thorax36"
          class="Thorax_Inspiration_lat_stehend2105_cls5"
          d="M285.76,28.22c.1,10.7.2,27,0,47-.3,26.1-.7,27.6-1,53-.6,46.6.9,46.8-1,64-2.5,23.3-5.1,41.6-5.1,41.6-1.5,10.4-2.6,17.1-2.6,27.2,0,4.6.1,15.1,2.8,27.3,2.1,9.3,7,25.5,19.9,43.1"
        />
        <path
          id="Thorax35"
          class="Thorax_Inspiration_lat_stehend2105_cls5"
          d="M245.76,32.22c.2,5.7.5,14.4,1,25,1.4,30.6,2.3,38.9,3,56,.5,11.7.3,26.4,0,56-.4,35.9-1,42,0,60,.7,12.4.7,4.1,4,40,1.1,12.4,1.6,18.6,5,27,2.5,6.2,4.6,8.8,10,19,4.6,8.7,7.9,16.1,10,21"
        />
        <path
          id="Thorax34"
          class="Thorax_Inspiration_lat_stehend2105_cls5"
          d="M253.76,269.22c-.8,9.4-1.8,19.1-3,29-1.5,12.1-3.1,23.8-5,35"
        />
        <path
          id="Thorax33"
          class="Thorax_Inspiration_lat_stehend2105_cls5"
          d="M281.76,240.22c-.1,5.8-.4,13.1-1.2,21.6-.7,6.5-1.9,18.5-5.7,33.3-3.9,15.1-6.3,17.2-9.4,31.2-2.3,10.1-3.2,18.5-3.6,23.8"
        />
        <path
          id="Thorax32"
          class="Thorax_Inspiration_lat_stehend2105_cls5"
          d="M90.76,552.42c5.3,5.8,15.7,3.9,22,3,9.1-1.3,26.4.2,61,3,29.9,2.5,38.8,6.8,56,6,8.2-.4,13.9-1.7,24,0,7,1.2,11.3,3,13,1,2.9-3.5-4.9-14.5-6-16-9.3-13.1-21.5-19.7-29.9-24-25.7-13.2-40.3-20.9-56.1-23-15.2-2.1-34.1-4.7-49.4,5.4-7.9,5.2-9.6,10.2-22.6,17.6-6.7,3.8-11.6,5.5-14,11-2.2,5-1.7,12,2,16Z"
        />
        <path
          id="Thorax31"
          class="Thorax_Inspiration_lat_stehend2105_cls4"
          d="M470.76,167.22c2,9.6,6,15.5,9,19,7.8,9,15.9,9.3,19,18,1.6,4.5.9,8.5,0,14-1.4,8.6-4.9,15.1-7.3,18.9"
        />
        <path
          id="Thorax30"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M307.76,480.92c0,6.1,39-7.7,59.4,14.3"
        />
        <path
          id="Thorax29"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M315.06,420.42c17.9-2,57.5-1.5,63,18"
        />
        <path
          id="Thorax28"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M306.66,495.22c0,7.7,27,14,60.4,14"
        />
        <path
          id="Thorax27"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M337.16,325.72c0,3.8,45.6,15.5,57.8,6.9"
        />
        <path
          id="Thorax26"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M340.76,172.22s19.4,9,51.6,0"
        />
        <path
          id="Thorax25"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M388.96,378.02c0-9.1-17.5-11.2-52.6-11.2"
        />
        <path
          id="Thorax24"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M384.06,393.52c0-9.5-24.6-17.1-55-17.1"
        />
        <path
          id="Thorax23"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M378.06,449.32c0-11.2-24.3-16.5-54.3-16.5"
        />
        <path
          id="Thorax22"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M358.76,560.22c0-9.6-26.9-17.3-60.1-17.3"
        />
        <path
          id="Thorax21"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M363.46,515.22c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <path
          id="Thorax20"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M376.26,451.62c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <path
          id="Thorax19"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M385.26,398.32c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <path
          id="Thorax18"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M395.86,337.62c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <path
          id="Thorax17"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M399.36,281.82c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <path
          id="Thorax16"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M398.36,225.42c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <line
          id="Thorax15"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          x1="392.36"
          y1="176.72"
          x2="394.76"
          y2="202.22"
        />
        <line
          id="Thorax14"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          x1="385.26"
          y1="119.82"
          x2="389.76"
          y2="160.22"
        />
        <line
          id="Thorax13"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          x1="376.26"
          y1="81.12"
          x2="380.76"
          y2="110.22"
        />
        <line
          id="Thorax12"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          x1="315.06"
          y1="74.82"
          x2="367.16"
          y2="62.32"
        />
        <line
          id="Thorax11"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          x1="323.76"
          y1="87.52"
          x2="376.26"
          y2="76.22"
        />
        <path
          id="Thorax10"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M341.76,219.22c0-5,26.8-9,60-9"
        />
        <path
          id="Thorax9"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M297.76,29.22c-7.6,9.4-15,15.5-20.2,19.4-18.8,13.7-31.4,13.3-37.8,26.6-2.8,5.8-2.4,10-7.4,15-4.4,4.4-12.1,8.4-17.6,6-3.4-1.5-2.6-3.9-8-11-5.6-7.3-8.2-7.1-10-12-1.6-4.4-1.5-10.4,1.7-14,2.5-2.8,5.8-2.8,10.6-3.6,0,0,10.5-1.7,25.7-9.4,4.6-2.3,11-6.2,18-13"
        />
        <path
          id="Thorax8"
          class="Thorax_Inspiration_lat_stehend2105_cls2"
          d="M161.26,154.02c5.8-4.6,10.8-7.7,14.5-9.8,7.8-4.4,11-6,11-6,6.4-3.1,7.6-3.1,9-5,2.9-4.1-.5-7.5,2-13,2-4.3,4.6-3.4,8-8,.4-.5,6.9-9.6,3-18-3.2-6.9-11.3-8.5-12.8-8.8-8.3-1.7-15,2.6-17.3,4.1-3.1,2-7.1,5.4-13.6,18.2-5.2,10.2-6,14.6-8.8,20.9-11.3,25.4-37.1,41.4-37.5,41.7h0l-2.3,10.9"
        />
        <polyline
          id="Thorax7"
          class="Thorax_Inspiration_lat_stehend2105_cls4"
          points="49.76 603.72 40.76 614.22 29.66 618.22 25.76 611.02 19.76 603.72 23.76 598.92 29.66 590.22"
        />
        <path
          id="Thorax6"
          class="Thorax_Inspiration_lat_stehend2105_cls4"
          d="M14.76,604.22v-19l-9-13.3,4.5-12.7,4.5-16.3,5-16.7v-35.7l9.9-9.6v-42.4c5.6-1.6,11.3-3.2,16.9-4.9-11.9-1.9-14.6-4.4-14.8-6.4-.3-2.3,2.7-3.2,4-8,1.1-3.8-.5-4.6,0-10,.3-3,.7-7.6,4-11,2.6-2.7,5.8-3.5,7.6-3.8"
        />
        <path
          id="Thorax5"
          class="Thorax_Inspiration_lat_stehend2105_cls4"
          d="M77.76,510.22c-2.3,1.3-4.7,2.7-7,4h0c-2.4,7.5-7.8,11.8-12,11-4.4-.8-6.7-7.2-7-8-1.6-4.5-.1-6.3-1-11-1.2-5.8-4.3-6.5-7-12-5-10.1-1.6-22-1-22s1.2,9,1,9c-.3,0-4.2-11.7-2-24,1.4-7.8,4.9-13.8,8-18"
        />
        <path
          id="Thorax4"
          class="Thorax_Inspiration_lat_stehend2105_cls4"
          d="M80.76,542.22c-2.4.7-6,1.8-10,4-7.1,3.9-8.7,7.4-12,7-3.5-.5-5.6-4.9-9-12-2.9-6.2-4.3-11.6-5-15-1.5-7.3-.5-8-2-16-.6-3-.7-3-4-15-3.7-13.6-3.9-15.1-4-17-.2-5.9,1.1-5.8,1-14-.1-6.5-1-8.4,0-12,1.3-4.8,4.6-8,7-10"
        />
        <path
          id="Thorax3"
          class="Thorax_Inspiration_lat_stehend2105_cls4"
          d="M31.76,487.22c.9,2.9,1.7,7.1,1,12-.1.7-.3,2.3-3,10-3.2,9.4-3.6,9.3-4,12-.6,4.2.3,4.4,0,12-.2,4.7-.3,7-1,10-1.4,5.8-3.6,7.1-6,13-.2.4-2.6,6.4-3,13-.6,9.2,2.9,16.9,6,22"
        />
        <path
          id="Thorax2"
          class="Thorax_Inspiration_lat_stehend2105_cls4"
          d="M97.26,234.32c-2.8,2-5.7,4-8.5,5.9h0c.4.8,3.5,8.5-1,16-3.6,6.1-5.8,9.3-7,9.6"
        />
        <path
          id="Thorax1"
          class="Thorax_Inspiration_lat_stehend2105_cls4"
          d="M72.06,294.32c-2.4,2.6-5.8,6.9-8.3,12.9-1.2,2.9-2.7,6.4-3,11-.7,9.4,4.1,12,2,17-2.3,5.5-8.8,4.3-11,10-1.5,4,.8,6.7,1.6,11.8.8,4.9.1,12-6.3,21.5"
        />
      </g>
    </svg>
  );
}
