import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getDDMMYYY_HHMM } from "../../Utilities/DateTimeUtils/DateTimeUtils";
import ClipLoader from "react-spinners/ClipLoader";
import "./stylesheets/SelectedExamsDetails.scss";
import { useStudies } from "../../Utilities/FetchHooks/Studies/StudiesHooks";

function SelectedExamsDetails({ hidden = true }) {
  const [shrinked, setShrinked] = useState(hidden);

  const selectedEaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  const exIds = useMemo(() => {
    let exIds = [];
    selectedEaminations.forEach((exam) => {
      exIds.push(exam.id);
    });
    return exIds;
  }, [selectedEaminations]);

  const serIds = useMemo(() => {
    let serIds = [];
    selectedEaminations.forEach((exam) => {
      exam.series.forEach((serie) => {
        serIds.push(serie.id);
      });
    });
    return serIds;
  }, [selectedEaminations]);

  const filter = useMemo(() => {
    let filterObj = {
      field: "id",
      op: exIds.length > 1 ? "in" : "==",
      value: exIds.length === 1 ? exIds[0] : exIds,
    };
    return filterObj;
  }, [exIds]);

  const std = useStudies({
    filter,
    xmask:
      "total_count,studies{id,patient,dapatid,patientsname,accessionnumber,patientssize,patientsweight,studydate,modality,studydescription,studyinstanceuid,myg,avgmyg,serie_collection{id,seriesdescription}}",
  });

  return (
    <div className="mb-3">
      <div
        className="py-1 mt-1 mb-2 border rounded d-flex justify-content-center align-items-center expandbtn"
        onClick={() => setShrinked(!shrinked)}
      >
        <h5>Selected Examinations</h5>
        {shrinked ? (
          <i className="ml-3 fas fa-chevron-down" />
        ) : (
          <i className="ml-3 fas fa-chevron-up" />
        )}
      </div>
      <div className={`scrollable-selected-list ${shrinked && "shrinked"}`}>
        {std.status !== "success" ? (
          <ClipLoader />
        ) : (
          std?.data?.studies != undefined &&
          std.data?.studies?.map((exam, exKey) => (
            <div
              key={`selectedExKey${exKey}`}
              className="mb-2 border rounded px-2 d-flex flex-column"
            >
              <h6>{exam.patientsname}</h6>
              <div className="d-flex justify-items-center align-items-center ml-2">
                <div className="mx-1 px-1 border-right">
                  Exam Descripion:{" "}
                  <span className="font-weight-bold">
                    {exam.studydescription}
                  </span>
                </div>
                <div className="px-1 border-right">
                  Modality:{" "}
                  <span className="font-weight-bold">{exam.modality}</span>
                </div>
                <div className="px-1 ">
                  From Date:{" "}
                  <span className="font-weight-bold">
                    {getDDMMYYY_HHMM(exam.studydate)}
                  </span>
                </div>
              </div>
              <h6 className="mt-1">Selected Series</h6>
              {exam.serie_collection.map(
                (serie) =>
                  serIds.includes(serie.id) && (
                    <div
                      key={`selSerKey${serie.id}`}
                      className="d-flex flex-column align-items-center justify-items-start"
                    >
                      <div>
                        <span className="font-weight-bold">
                          - {serie.seriesdescription}
                        </span>
                      </div>
                    </div>
                  )
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default SelectedExamsDetails;
