import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const Chest = map.Chest;

function BackView({ bpartSetter, selected }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 552.11 455.4">
      <g id="Ebene_1" data-name="Ebene 1">
        <g id="thorax_back" data-name="thorax back">
          <g id="Outline">
            <path
              id="outline_l"
              data-name="outline l"
              class="stroke clear-fill"
              d="M218.8,.09c-1.64,9.18-3.07,16.11-4.25,20.25-15.88,14.94-51.21,28.63-86.62,42.3-15.91,9.25-30.9,14.74-44.35,13.92l-38.52,19.88c-17.42,7.93-31.62,24.64-39.38,58.9C.34,168.05-.27,186.42,1.18,207.26c9.35,14.6,12.67,30.72,13.12,46.71l2.25,41.25"
            />
            <path
              id="outline_r"
              data-name="outline r"
              class="stroke clear-fill"
              d="M335.68,4.59l3.38,18.75c37.19,29.58,83.32,43.81,114.75,53.22,19.65,1.64,33.9,9.25,45.75,19.53,20.63,5.06,33.95,19.84,40.88,43.12,11.25,22.71,12.92,46.86,9.75,71.74-9.51,17.66-13.47,32.18-10.88,43.01l-1.12,43.12"
            />
          </g>
          <g id="shoulder_L" data-name="shoulder L">
            <path
              id="shoulder_plate_2_L"
              data-name="shoulder plate 2 L"
              class="stroke clear-fill"
              d="M185.3,102.09c3.93,4.62,5.12,13.55,4.88,24.76,7.1,10.84,11.97,22.3,6.38,36.74-2.04,13.2-7.53,26.99-15.75,41.25-.21,.99-4.72,21.78-5.25,24.25-1.27,15.85-2.52,31.33-3.75,46.62-12.41,1.56-27.49-10.39-43.5-27l-6.75-19.62-6-25c-8.66-8.11-14.11-16.68-12.38-26.25-1.03-7.12-5.73-12.94-11.62-18.33-9.87-1.68-8.73-10.4-2.25-22.54l10.5-16.12c-4.49-7.76-13.8-10.56-27-9.38-19.99,2.43-23.93-2.07-21.38-9.38,6.21-6.68,13.94-12.51,29.62-13.88l8.62-5.62c6.7,.14,13.03-.72,18.75-3.22,2.42-6.28,28.2-.88,55.12,5.1,9.62,1.77,19.88,4.51,29.62,9-.83,5.86-2.91,9.04-7.88,8.62h.02Z"
            />
            <path
              id="upper_arm_L"
              data-name="upper arm L"
              class="stroke clear-fill"
              d="M76.18,304.97l1.88-79.88,3.38-39.65c-1.82-11.63,1.88-20.09,10.12-25.93-6.83-3.03-9.56-8.21-4.75-17.4,.94-5.83,7.99-10.69,9.08-15.25-2.8-5.27-8.01-7.98-14.46-9.39-7.18-1.69-13.68,.86-19.88,5.25-5.07-.93-9.85-1.81-14.25-2.62-11.49,8.92-7.67,19.91-2.25,31.12,6.53,9.06,7.94,20.14,7.88,31.8,3.18,17.07,.18,40.53-4.5,65.69-3.8,13.52-3.11,27.84-1.12,42.38"
            />
            <path
              id="shoulder_joint_3_L"
              data-name="shoulder joint 3 L"
              class="stroke clear-fill"
              d="M81.42,91.21c1.92,3.05,4.22,4.97,7.06,5.35,1.28,.17,2.67,.03,4.19-.48,18.98,5.59,37.55,13.39,55.88,22.5,11.48,8.39,22.17,15.07,31.5,18.75,3.35,2.64,6.32,2.36,9,0"
            />
            <path
              id="shoulder_joint_2_L"
              data-name="shoulder joint 2 L"
              class="stroke clear-fill"
              d="M61.52,112.09c10.92-1.44,21.67-2.96,27.4-7,4.28,2.83,10.99,7.27,23.25,15.38,31.64,1.4,50.46,15.03,61.12,30.75,3.03,8.12,5.74,15.4,8.25,22.12"
            />
            <path
              id="shoulder_joint_1_L"
              data-name="shoulder joint 1 L"
              class="stroke clear-fill"
              d="M77.1,88.68c8.45,.91,12.77,3.38,12.95,7.41"
            />
            <path
              id="shoulder_plate_2_L-2"
              data-name="shoulder plate 2 L-2"
              class="stroke clear-fill"
              d="M121.54,103.21c3.59-4.31,9.67-.93,17.64,8.25,10.39,1.37,18.36,.22,23.25-4.12,6.49-5.47,13.87-8.03,22.88-5.25"
            />
            <path
              id="collarbone_1_L"
              data-name="collarbone 1 L"
              class="stroke clear-fill"
              d="M97.92,96.09c14.16-3.63,28.53-4.94,43.12-3.71,14.44-.04,26.22,3.44,36.75,8.57"
            />
            <path
              id="shoulder_plate_1_L"
              data-name="shoulder plate 1 L"
              class="stroke clear-fill"
              d="M105.42,134.71c1.88-4.62,0-9.25-5.62-13.88"
            />
          </g>
          <g id="shoulder_R" data-name="shoulder R">
            <path
              id="shoulder_plate_4_R"
              data-name="shoulder plate 4 R"
              class="stroke clear-fill"
              d="M387.8,106.48c-16.16-8.99-23.67-3.85-23.62,13.61l-3.75,15.75c-5.87,9.46-9.72,19.82-2.25,35.25,5.44,13.64,10.53,26.38,14.81,37.12,4.51,16.99,7.11,31.94,6.19,43.12-2.87,25.3-.14,41.49,28.88,15,10.6-6.01,18.07-13.85,18.38-25.88,7.02-8.09,11.38-19.93,12.75-36,6.01-7.84,11.4-17.23,14.25-33,3.05-6.51,6.15-10.69,9.38-9.38,7.79-3.07,8.34-9.54,3-18.75-5.37-1.48-7.22-11.62-12-21,3.92-5.18,12.16-8.99,33.75-8.62,16.52-.32,25.54-3.49,6.75-17.27-7.12-5.23-17.02-6.54-22.88-7.77-5.14,2.65-6.93,6.03-7.5,9.66-11.9,.99-21.89,3.78-30.38,8.14-3.74-3.58-8.61-2.3-14.62,3.86-8.47,7.91-18.92,6.29-31.12-3.86l-.02,.02h0Z"
            />
            <path
              id="shoulder_plate_3_R"
              data-name="shoulder plate 3 R"
              class="stroke clear-fill"
              d="M372.05,176.21c6.47-25.38,19.03-45.53,50.62-49.36,15.11-.71,29.14-5.22,40.12-20.37,10.61,4.86,20.15,7.33,28.47,7.1"
            />
            <path
              id="shoulder_plate_2_R"
              data-name="shoulder plate 2 R"
              class="stroke clear-fill"
              d="M365.3,139.21c7.68,2.11,14.32,.51,19.5-6.38l26.94-18.2c8.62-3.3,16.55-6.31,21.81-8.16"
            />
            <path
              id="shoulder_plate_1_R"
              data-name="shoulder plate 1 R"
              class="stroke clear-fill"
              d="M449.3,139.21c-3.5-5.62-2-11.25,4.5-16.88"
            />
            <path
              id="upperarm_2_R"
              data-name="upperarm 2 R"
              class="stroke clear-fill"
              d="M477.05,309.09c3.1-23.64,2.55-48.98-1.88-76.12-4.14-12.34-3.88-30.72-3-49.95,0-11.99-4.91-17.74-11.83-20.91"
            />
            <path
              id="upperarm_1_R"
              data-name="upperarm 1 R"
              class="stroke clear-fill"
              d="M504.8,295.21c3.23-21.39,2.83-42.65-2.63-63.75-3.11-14.52-3.47-32.1-2.62-51l11.25-37.5c1.82-7.6,.97-14.67-5.62-20.62-7.29,5.3-14.67,4.42-22.12-2.25-10.64-2.64-19,1.58-26.52,8.29"
            />
            <path
              id="collarbone_R"
              data-name="collarbone R"
              class="stroke clear-fill"
              d="M469.68,89.69c-5.83-4.45-11.27-6.92-15.88-5.22-6.56-1.65-13.32-3.35-20.25-5.1-28.41,.32-50.38,8.31-72,16.72,3.95,2.41,7.63,4.64,10.5,6.39,2.85-1.68,4.84-1.52,7.3,.36,27.32-9.29,53.35-12.07,77.17-3.61"
            />
            <path
              id="shoulder_Joint_1_R"
              data-name="shoulder Joint 1 R"
              class="stroke clear-fill"
              d="M463.92,98.34c3.23,3.27,6.89,.63,10.12-4.12"
            />
          </g>
          <g id="thoracic_spine" data-name="thoracic spine">
            <path
              id="thoracic_vertebrae_12"
              data-name="thoracic vertebrae 12"
              class="stroke clear-fill"
              d="M233.68,68.71c-3.59,2.53-3.41,5.68,0,9.38,4.62,3.48,9.25,5.03,13.88,3.38l7.5,6.75c4.9,1.06,9.66,.97,14.25,0l3.38,5.25c2.54,3.44,5.04,3.56,7.5,0l3.75-5.25c3.72,2.25,8.4,1.91,13.12,1.5,3.33-1.23,6.7-2.48,10.12-3.75l9.38-.38c7.45-4.58,6.3-10.5,2.25-16.88-3.85-.99-10.01,.28-17.62,3-4.65-10.3-9.75-7.63-15-.75-4.61,1.62-11.11,1.5-19.5-.38l-6.38-5.62c-7.32-5.06-8.51-1.08-8.62,4.5-5,2.68-11.36,1.36-18-.75h-.01Z"
            />
            <path
              id="thoracic_vertebrae_11"
              data-name="thoracic vertebrae 11"
              class="stroke clear-fill"
              d="M251.72,85.21c-5.77-1-11.54-1-17.29,0-3.9,4.78-5.11,8.42,0,9.38,4.52,1.17,8.96,1.97,13.12,1.5,1.78,6.29,4.14,11.44,8.62,12.37,5.16,2.9,10.17,3.1,15,0,3.55,8.52,6.86,10.48,9.75,0,6.28,3.87,12.37,5.13,18,0l8.62-9.38h11.62c4.2-3.17,3.33-6.89,0-10.88-5.79-.5-11.7-.47-17.68-.15"
            />
            <path
              id="thoracic_vertebrae_10"
              data-name="thoracic vertebrae 10"
              class="stroke clear-fill"
              d="M271.18,108.47c-8.62,2.69-19.37,2.86-30.38,0-3,3.25-4,6.5-3,9.75l11.62,1.88,9.75,12.75h11.62c2.86,12.8,6.14,18.93,10.88,2.25,5.44,1.06,10.54,.82,15-1.88,3.18,.33,4.02-2.56,3.38-7.5l13.88-5.62c2.55-2.2,1.84-5.49,0-10.12-4.07-.41-9.02-.9-15-1.5"
            />
            <path
              id="thoracic_vertebrae_9"
              data-name="thoracic vertebrae 9"
              class="stroke clear-fill"
              d="M265.92,132.84c-5.55,5.67-13.57,5.33-23.62,0-5.4,3.6-6.6,7.61,0,12.4,5.05,.37,9.83,1.42,13.5,5.23l2.62,11.62c3.55,2.94,7.18,5.13,11.25,3,5.13,17,9.75,17,13.88,0h13.5c4.25-4.71,4.32-10.21,2.25-16.12h11.62c5.76-5.11,5.24-10.51,0-16.12-5.84,3.74-10.39,3.18-14.25,.38"
            />
            <path
              id="thoracic_vertebrae_8"
              data-name="thoracic vertebrae 8"
              class="stroke clear-fill"
              d="M258.42,162.09c-2.22-.36-5.39-.86-9.38-1.5-10.98-3.58-11.86,2.12-9,11.25h9c4.78,3.86,6.98,7.52,5.25,10.88,2.06,1.22,4.08,2.37,6.01,3.36,4.24,2.16,8.05,3.5,10.87,3.02,3.46,18.49,6.68,27.58,9,0l18.75-3.38c2.45-.42,1.49-4.62,0-10.88,3.95,1.68,8,.32,12,0,1.46-12.03-2.64-16-11.95-12.42"
            />
            <path
              id="thoracic_vertebrae_7"
              data-name="thoracic vertebrae 7"
              class="stroke clear-fill"
              d="M260.31,186.07c-3.26-1.59-5.46-.05-7.13,3.02-13.71-6.63-16.97-3.32-12.75,7.12l9.75,3.38v6.94c3.25,4.48,6.21,7.82,8.88,9.99,1.51,1.23,2.93,2.09,4.25,2.57l9,2.25c2.34,22.35,5.31,23.65,9,0,5.65,1.59,10.24,1.12,13.5-1.88,1.54-4.89,3.05-9.65,4.5-14.25,2.76,.21,5.99-.59,9.75-5.62,4.06-5.94,1.98-9.57-2.25-12.38l-9.76-1.16"
            />
            <path
              id="thoracic_vertebrae_6"
              data-name="thoracic vertebrae 6"
              class="stroke clear-fill"
              d="M263.3,219.09c-2.29,3.81-4.37,7.28-6,10-12.96-3.17-16.55-.22-9.75,9.5,9.89,2.13,14.87,7.05,13.88,15.37,3,3,6.3,1.51,9.38,2.25,5.67,30.93,8.42,28.03,9,0,6.04,1.48,11.58,.68,16.5-3,2.97-3.26,2.87-7.65,1.88-12.38,1.78-.41,5.49-1.27,9.75-2.25,2.62-9.74-.19-12.67-7.88-9.5-2.33-3.44-3.96-6.61-5.25-9.62"
            />
            <path
              id="thoracic_vertebrae_5"
              data-name="thoracic vertebrae 5"
              class="stroke clear-fill"
              d="M261.42,253.97c.08,7.06-1.48,7.95-3.75,6.12-19.1,1.65-16.24,6.59-6,12.62,5.14,4.85,7.35,9.1,3,12,4.64,5.84,10.04,6.44,15.75,4.88,3.88,23.68,7.36,32.35,9.75,0,4.56,.39,15.75-4.12,15.75-4.12,3.05-3.3,3.45-6.86,1.88-10.62,9.91-1.61,10.15-5.99,3.75-12.25-4.64,.83-7.34,.23-7.12-2.5l-.99-5.09"
            />
            <path
              id="thoracic_vertebrae_4"
              data-name="thoracic vertebrae 4"
              class="stroke clear-fill"
              d="M258.42,288.25v7.34c-12.27-2.57-14.77,8.72,0,15.14-2.75,5.94-2.78,10.56,0,10.74,2.34,4.14,7.72,4.59,13.88,4.12,2.31,21.51,5.59,20.98,9.75,0,3.94-1.23,7.92-2.47,12-3.75,3.27-4.17,2.73-8.87,0-13.88,16.59-4.09,14.97-9.04,0-14.62l-1.79-6.51"
            />
            <path
              id="thoracic_vertebrae_3"
              data-name="thoracic vertebrae 3"
              class="stroke clear-fill"
              d="M260.47,323.74l-2.8,2.23c-14.62,3.27-10.84,6.15-5.62,9,7.24,4.05,10.85,7.81,5.62,10.88,1.96,6.8,5.53,9.31,12,4.12,3.47,26.66,6.86,28.34,10.12,0,5.16,2.42,10.17,2.58,15,0v-12c9.47,3.78,15.1,2.93,9-12-6.68,1.13-10.07,.45-10.5-1.88l.75-2.25"
            />
            <path
              id="thoracic_vertebrae_2"
              data-name="thoracic vertebrae 2"
              class="stroke clear-fill"
              d="M259.4,349.97c.75,4.43-.97,5.48-4.72,3.75-7.87-2.23-9.64,.84-6.4,8.25,9.12,3.78,11.52,10.09,8.65,18.38,2.65,3.68,6.85,5,12.75,3.75,3.56,8.24,6.83,8.73,9.75,0,9.89,2.4,14.21,1.02,15.75-2.25,3.15-4.62,2.85-8.9,0-12.94,4.66-2.19,7.37-4.23,8.58-6.15,.31-.5-.83-6.66-4.46-9.04-3.78,1.24-5.05-.27-4.5-3.75"
            />
            <path
              id="thoracic_vertebrae_1"
              data-name="thoracic vertebrae 1"
              class="stroke clear-fill"
              d="M256.92,380.34c-7.32,4.41-10.17,8.54-3,12,4.81,1.28,7.07,4.26,4.5,10.5-7,4.78-4.47,7.56,2.62,9.38h8.62c1.37,8.1,5.48,9.5,9,0,3.85-1.05,7.32-.95,10.5,0,6.77,5.71,8.48,2.58,6-7.88-.68-6.52,1.79-9.65,6.75-10.12,1.95-9.17-2.08-11.16-6.75-12.38"
            />
          </g>
          <g id="ribs_L" data-name="ribs L">
            <g id="rib_1_L" data-name="rib 1 L">
              <path
                id="rib_1_L-2"
                data-name="rib 1 L"
                class="stroke clear-fill"
                d="M196.99,98.18c.13-10.1,7.09-21.58,27.31-28.72,0,0,6.41-8.04,6.75-1.5,.29,5.64-.14,.33,2.63,.75"
              />
              <path
                id="rib_1_L-3"
                data-name="rib 1 L"
                class="stroke clear-fill"
                d="M210.61,93.42c4.67-6.31,11.87-11.62,23.05-15.33"
              />
              <path
                id="rib_1_L-4"
                data-name="rib 1 L"
                class="stroke clear-fill"
                d="M210.8,99.19c3.77,6.89,7.38,10.74,14.38,12.92"
              />
              <path
                id="rib_1_L-5"
                data-name="rib 1 L"
                class="stroke clear-fill"
                d="M198.98,106.48c1.59,4.59,4.36,8.18,9.32,9.8"
              />
              <path
                id="rib_1_L-6"
                data-name="rib 1 L"
                class="stroke clear-fill"
                d="M214.32,122.77c9.49,4.91,18.67,8.87,26.95,10.07"
              />
            </g>
            <g id="rib_2_L" data-name="rib 2 L">
              <path
                id="rib_2_L-2"
                data-name="rib 2 L"
                class="stroke clear-fill"
                d="M189.93,116.94c7.01-7.96,14.28-18.23,24.65-18.21,7.38,.01,15.07,.24,19.84-4.14-1.96-.23-3.68-1.48-5.25-3.38-4.65-.97-11.42,.49-19.12,3,.18-.26,.37-.53,.57-.79"
              />
              <path
                id="rib_2_L-3"
                data-name="rib 2 L"
                class="stroke clear-fill"
                d="M189.93,100.96c5.04-1.03,12.83-5.33,20.12-6.74"
              />
              <path
                id="rib_2_L-4"
                data-name="rib 2 L"
                class="stroke clear-fill"
                d="M198.75,129.1c1.61,3.56,5.23,7.31,10.18,9.5"
              />
              <path
                id="rib_2_L-5"
                data-name="rib 2 L"
                class="stroke clear-fill"
                d="M226.98,145.24c10.11,5.01,20.04,8.28,29.73,9.26"
              />
              <path
                id="rib_2_L-6"
                data-name="rib 2 L"
                class="stroke clear-fill"
                d="M202.58,153.31c10.62,6.06,20.92,11.49,28.45,11.5"
              />
            </g>
            <g id="rib_3_L" data-name="rib 3 L">
              <path
                id="rib_3_L-2"
                data-name="rib 3 L"
                class="stroke clear-fill"
                d="M189.93,122.06c10.54-4.13,21.23-6.87,32.07-8.3,6.18-3.26,11.78-4.5,16.7-3.38-1.23,3.17-1.7,6.27-1.27,9.28-3.68,1.33-12.43,1.9-21.61,2.39-7.51,1.81-14.93,5.09-22.2,10.49"
              />
              <line
                id="rib_3_L-3"
                data-name="rib 3 L"
                class="stroke clear-fill"
                x1="197.11"
                y1="164.81"
                x2="206.39"
                y2="171.56"
              />
              <path
                id="rib_3_L-4"
                data-name="rib 3 L"
                class="stroke clear-fill"
                d="M227.79,177.87c7.43,4.12,16.47,7.12,26.7,9.28"
              />
              <path
                id="rib_3_L-5"
                data-name="rib 3 L"
                class="stroke clear-fill"
                d="M200.77,184.07c9.33,7.16,19.36,11.27,30.26,11.53"
              />
            </g>
            <g id="rib_4_L" data-name="rib 4 L">
              <path
                id="rib_4_L-2"
                data-name="rib 4 L"
                class="stroke clear-fill"
                d="M197.98,143.32c6.13-3.46,13.88-6.18,24.16-7.76,6.47-.22,11.96,0,16.51,.67-.84,1.4-2.14,1.21,.04,6.5-5.74,1.78-13.01,3.47-19.9,2.51-7.4,2.86-14.1,6.44-20.18,10.68"
              />
              <path
                id="rib_4_L-3"
                data-name="rib 4 L"
                class="stroke clear-fill"
                d="M183.74,199.54c1.58,3.45,4.33,5.63,8.23,6.55"
              />
              <path
                id="rib_4_L-4"
                data-name="rib 4 L"
                class="stroke clear-fill"
                d="M204.95,215.09c18.76,6.83,37.57,9.59,56.43,7.2"
              />
              <path
                id="rib_4_L-5"
                data-name="rib 4 L"
                class="stroke clear-fill"
                d="M190.49,224.82c9.16,5.05,18.28,8.61,27.35,9.26"
              />
            </g>
            <g id="rib_5_L" data-name="rib 5 L">
              <path
                id="rib_5_L-2"
                data-name="rib 5 L"
                class="stroke clear-fill"
                d="M194.2,174.24c12.05-1.51,24.3-2.87,31.03-8.45,4.55-1.18,9.05-1.37,13.46-.15,.3,2.68,.73,4.84,1.35,6.2-.43,6.4-7.34,6.13-14.11,6-9.38,1.46-17.72,3.58-25.17,6.23-5.96,1.04-10.06,2.96-11.72,6.05"
              />
              <path
                id="rib_5_L-3"
                data-name="rib 5 L"
                class="stroke clear-fill"
                d="M174.39,243.53c2.31,3,4.62,4.35,6.92,4.05"
              />
              <path
                id="rib_5_L-4"
                data-name="rib 5 L"
                class="stroke clear-fill"
                d="M197.11,255.21c17.59,5.27,35.96,5.13,54.98,.44"
              />
              <path
                id="rib_5_L-5"
                data-name="rib 5 L"
                class="stroke clear-fill"
                d="M175.55,262.54c6.88,5.68,14.69,8.82,23.43,9.43"
              />
              <path
                id="rib_5_L-6"
                data-name="rib 5 L"
                class="stroke clear-fill"
                d="M211.46,276.89c14.33-.51,27.39-2.01,36.82-6.33"
              />
            </g>
            <g id="rib_6_L" data-name="rib 6 L">
              <path
                id="rib_6_L-2"
                data-name="rib 6 L"
                class="stroke clear-fill"
                d="M178.98,213.24c8.05-5.94,18.33-10.27,27.27-9.47,11.13-1.78,21.22-4.43,28.55-9.42h4.92l.7,1.88,3.75,1.3c1.69-.05,2.12,.84,1.59,2.45-2.2,7.27-6.82,8.73-12.94,6.57-14.82,1.34-27.44,6.21-37.12,15.79-5.72,3.21-12.63,5.27-20.16,6.77"
              />
              <path
                id="rib_6_L-3"
                data-name="rib 6 L"
                class="stroke clear-fill"
                d="M125.3,239.98c-6.92,1.5-10.37,6.42-11.15,13.98-1,10.56-.53,19.36,3.09,24.37,2-15.8,6.16-26.85,16.57-24.07"
              />
              <line
                id="rib_6_L-4"
                data-name="rib 6 L"
                class="stroke clear-fill"
                x1="117.34"
                y1="277.57"
                x2="118.81"
                y2="279.84"
              />
              <line
                id="rib_6_L-5"
                data-name="rib 6 L"
                class="stroke clear-fill"
                x1="125.53"
                y1="256.64"
                x2="133.12"
                y2="269.15"
              />
              <path
                id="rib_6_L-6"
                data-name="rib 6 L"
                class="stroke clear-fill"
                d="M126.23,286.83c4.45,4.68,9.83,9.37,14.66,10.36"
              />
              <path
                id="rib_6_L-7"
                data-name="rib 6 L"
                class="stroke clear-fill"
                d="M139.46,278.34c3.99,4.26,11.21,7.43,18.12,9.1"
              />
              <path
                id="rib_6_L-8"
                data-name="rib 6 L"
                class="stroke clear-fill"
                d="M151.65,304.64c7.91,4.73,18.32,8.14,25.35,8.23"
              />
              <path
                id="rib_6_L-9"
                data-name="rib 6 L"
                class="stroke clear-fill"
                d="M172.8,294.18c5.98,4.77,22.51,6.84,32.19,7.88"
              />
              <path
                id="rib_6_L-10"
                data-name="rib 6 L"
                class="stroke clear-fill"
                d="M230.52,294.56c9.11-3.72,17-11.75,21.15-21.85"
              />
              <path
                id="rib_6_L-11"
                data-name="rib 6 L"
                class="stroke clear-fill"
                d="M202.03,315.98c5.95,2.95,18.04,1.58,31.22-.56"
              />
            </g>
            <g id="rib_7_L" data-name="rib 7 L">
              <path
                id="rib_7_L-2"
                data-name="rib 7 L"
                class="stroke clear-fill"
                d="M172.8,263.28c4.2-.98,8.37-2.64,12.5-5.24,5.71-.09,11.02-1.33,14.48-6.33,10.43-3.61,20.42-9.68,29.39-13.12,6.59-.32,13.21-.62,16.81-2.44-1.92-1.61-2.46-3.87-1.04-7.06-15.22,2.34-29.55,4.89-39.11,8.56-13.09,.67-32.75,15.19-32.24,15.74"
              />
              <path
                id="rib_7_L-3"
                data-name="rib 7 L"
                class="stroke clear-fill"
                d="M145.23,264.5c-15.48,4.34-28.7,13.43-31.09,26.31-1.26,8.83-.4,13.87,1.83,16.44,3.59-8.87,7.25-17.12,11.39-21.38,8.56-5.86,17.55-10.78,26.79-15.11"
              />
              <path
                id="rib_7_L-4"
                data-name="rib 7 L"
                class="stroke clear-fill"
                d="M121.64,312.52c-2.27,.36-3.97-1.86-5.67-5.27"
              />
              <path
                id="rib_7_L-5"
                data-name="rib 7 L"
                class="stroke clear-fill"
                d="M119.5,298.93c1.98,4.86,5.41,7.21,7.86,8.32"
              />
              <path
                id="rib_7_L-6"
                data-name="rib 7 L"
                class="stroke clear-fill"
                d="M126.23,322.58c5.01,6.17,10.12,9.96,15.34,11.4"
              />
              <path
                id="rib_7_L-7"
                data-name="rib 7 L"
                class="stroke clear-fill"
                d="M135.99,314.18c3.55,6.01,9.33,8.79,18.16,10.61"
              />
              <path
                id="rib_7_L-8"
                data-name="rib 7 L"
                class="stroke clear-fill"
                d="M155.49,340.46c5.39,4.83,11.62,7.68,18.7,8.53"
              />
              <path
                id="rib_7_L-9"
                data-name="rib 7 L"
                class="stroke clear-fill"
                d="M173.27,329.63c23.92,1.9,45.7-.22,60.83-14.78"
              />
              <path
                id="rib_7_L-10"
                data-name="rib 7 L"
                class="stroke clear-fill"
                d="M206.96,346.92c8.82-1,18.26-1.75,21.94-4.78,.94-.6,1.72-.99,2.34-1.13"
              />
            </g>
            <g id="rib_8_L" data-name="rib 8 L">
              <path
                id="rib_8_L-2"
                data-name="rib 8 L"
                class="stroke clear-fill"
                d="M258.42,260.09l.7-1.34c-.97-3.49-3.55-4.11-7.03-3.09-8.89,4.06-17.16,6.71-24.05,6.19-6.22,2.93-17.06,7.14-29.07,10.12-10.54,.41-22.67,6.13-35.2,13.22-11.84,3.63-22.72,10.65-32.77,20.67-5.1,1.83-11.19,8.16-17.44,15.19-5.8,9.4-4.59,16.96-1.55,24.05,1.55,5.85,4.57,10.78,9.98,14.2"
              />
              <path
                id="rib_8_L-3"
                data-name="rib 8 L"
                class="clear-fill"
                d="M244.18,266.74c-10.46,1.46-21.45,5.19-32.72,10.15-1.83,3.31-4.84,5.74-8.44,7.73-5.7-.06-12.08,1.48-18.84,3.94l-38.25,18.91c-9.43,5.84-17.98,12.3-24.33,20.32-2.2,4.98-2.62,10.54-1.97,16.45,1.22,4.31,4.09,5.78,7.17,6.75"
              />
              <path
                id="rib_8_L-4"
                data-name="rib 8 L"
                class="stroke clear-fill"
                d="M141.57,371.95c-3.53,.17-8.12-2.97-13.05-7.22"
              />
              <path
                id="rib_8_L-5"
                data-name="rib 8 L"
                class="stroke clear-fill"
                d="M153.66,361.26c-5.67-.42-11.34-2.63-17.01-6.6"
              />
              <path
                id="rib_8_L-6"
                data-name="rib 8 L"
                class="stroke clear-fill"
                d="M231.24,341.01c1.86-.42,2.22,1.36,.75,5.91-2.99,8.32-8.21,14.54-15.89,18.42-11.68,3.88-26.5,2.57-42.47-.61"
              />
              <path
                id="rib_8_L-7"
                data-name="rib 8 L"
                class="stroke clear-fill"
                d="M155.49,378.09c12.06,2.14,24.06,3.38,36,3.7"
              />
            </g>
            <g id="rib_9_L" data-name="rib 9 L">
              <path
                id="rib_9_L-2"
                data-name="rib 9 L"
                class="stroke clear-fill"
                d="M258.42,292.01c-3.05-3.84-7.91-3.91-14.25-.92l-27.66,7.03-43.03,16.06c-30.08,14.37-39.89,25.65-47.26,37.8-8.91,10.27-7.16,25.55-4.59,36.14,.88,5.69,2.78,9.53,5.72,11.46-3.49-13.93-3.69-25.89,1.15-34.85,1.06-1.96,2.36-3.77,3.91-5.44,6.8-8.11,15.15-14.66,24.89-19.83l34.03-18.56c5.45-1.83,10.21-4.34,12.8-9,7.72-7.21,18.31-9.91,30.66-9.84h13.48c-.23-3.31,1.45-6.54,10.15-9.42"
              />
              <path
                id="rib_9_L-3"
                data-name="rib 9 L"
                class="stroke clear-fill"
                d="M140.44,405.44c3.94,2.18,8.29,3.13,13.22,2.35"
              />
              <path
                id="rib_9_L-4"
                data-name="rib 9 L"
                class="stroke clear-fill"
                d="M144.35,393.46c4.17,2.59,12.13,2.75,20.49,2.64"
              />
              <path
                id="rib_9_L-5"
                data-name="rib 9 L"
                class="stroke clear-fill"
                d="M124.82,380.34c1.79,3.96,3.88,6.84,6.48,7.78"
              />
              <line
                id="rib_9_L-6"
                data-name="rib 9 L"
                class="stroke clear-fill"
                x1="127.36"
                y1="399.58"
                x2="129.89"
                y2="401.9"
              />
            </g>
            <g id="rib_10_L" data-name="rib 10 L">
              <path
                id="rib_10_L-2"
                data-name="rib 10 L"
                class="stroke clear-fill"
                d="M249.77,326.39c1.61-1.54,2.03-7.69-3.04-6.6-5.22,5.47-19.67,8.75-32.75,12.36-12.52,6.54-24.46,12.3-33.33,14.06-10.8,4.19-21.51,10.58-32.14,18.51-7.03,5.41-12.35,13.34-16.23,23.39-5.75,20.35-.55,31.7,9.28,39.23-1.88-9.14-2.62-18.14,0-26.72,5.12-16.79,17.39-27.39,32.06-35.91l13.92-7.4c7.78-.73,14.32-4.05,19.41-10.41,14.13-9.9,27.34-16.63,38.11-14.91,4.73-3.11,4-4.94,4.71-5.62v.02h0Z"
              />
              <line
                id="rib_10_L-3"
                data-name="rib 10 L"
                class="stroke clear-fill"
                x1="141.57"
                y1="427.36"
                x2="145.66"
                y2="430.68"
              />
              <path
                id="rib_10_L-4"
                data-name="rib 10 L"
                class="stroke clear-fill"
                d="M155.49,432c2.31,1.91,4.49,1.47,6.6,0,11-8.95,20.59-18.1,26.9-27.7,7.55-9.49,16.03-17.1,25.03-23.66"
              />
              <path
                id="rib_10_L-5"
                data-name="rib 10 L"
                class="stroke clear-fill"
                d="M139.87,413.62c2.94,3.64,5.89,5.38,8.83,5.2"
              />
            </g>
            <g id="rib_11_L" data-name="rib 11 L">
              <path
                id="rib_11_L-2"
                data-name="rib 11 L"
                class="clear-fill"
                d="M252.52,353.21c1.18-5.92-.68-8.02-5.79-6.01-3.69,2.75-7.25,6.33-10.67,10.76-4.59,5-11.87,8.97-19.97,12.64-8.54,2.76-26.98,12.76-45,22.45-16.14,5.9-22.63,21.52-25.94,40.36,.71,7.28,3.58,12.05,7.38,15.75-1.05-4.21-2.55-9.45,1.12-17.3,5.9-11.1,12.34-21.26,20.53-28.41,12.95-6.02,24.64-12.18,33.05-18.7l36.14-21.94,4.03-2.25"
              />
            </g>
            <g id="rib_12_L" data-name="rib 12 L">
              <path
                id="rib_12_L-2"
                data-name="rib 12 L"
                class="clear-fill"
                d="M254.67,381.79c-1.3-4.95-3.43-6.19-6.41-3.7-3.3,3.39-5.95,7.23-8.26,11.3-4.89-.27-10.18,1.78-15.75,5.39l-18.14,11.84c-14.46,7.61-28.02,22.6-34.17,35.65-1.62,14.57,.44,15.27,4.92,6.89,3.26-5.51,6.49-10.25,9.7-14.2,8.87-4.99,16.51-10.72,22.08-17.72,9.73-5.09,18.43-11.12,25.45-18.7,5.47-2.42,10.86-5.02,15.68-9"
              />
            </g>
          </g>
          <g id="ribs_R" data-name="ribs R">
            <g id="rib_1_R" data-name="rib 1 R">
              <path
                id="rib_1_R-2"
                data-name="rib 1 R"
                class="clear-fill"
                d="M320.17,71.08c2.28-3.3,4.95-5.01,8.71-2.32,13.91,7.13,23.57,15.53,28.12,25.45"
              />
              <path
                id="rib_1_R-3"
                data-name="rib 1 R"
                class="clear-fill"
                d="M322.01,79.37c12.85,2.04,21.33,5.87,22.48,12.74"
              />
              <path
                id="rib_1_R-4"
                data-name="rib 1 R"
                class="stroke clear-fill"
                d="M343.01,101.04c.6,6.42-1.21,10.07-4.99,11.46"
              />
              <path
                id="rib_1_R-5"
                data-name="rib 1 R"
                class="stroke clear-fill"
                d="M331.28,120c-4.4,3.44-10.88,4.49-18.43,4.31l-6.27-1.24"
              />
              <path
                id="rib_1_R-6"
                data-name="rib 1 R"
                class="stroke clear-fill"
                d="M348.2,124.95c-4.82,5.14-12.11,8.48-21.43,10.33l-12.65,2.12"
              />
              <path
                id="rib_1_R-7"
                data-name="rib 1 R"
                class="stroke clear-fill"
                d="M358.84,106.77c1.04,5.22,0,8.76-3.13,10.63"
              />
            </g>
            <g id="rib_2_R" data-name="rib 2 R">
              <path
                id="rib_2_R-2"
                data-name="rib 2 R"
                class="clear-fill"
                d="M361.55,96.09c-11.45-3.98-25.36-5.47-40.69-5.51,.96,1.71,1.52,4.01,1.14,7.71,6.35,2.48,13.99,3.17,22.49,2.67,9.16,3.19,16.8,6.42,20.67,9.77"
              />
              <path
                id="rib_2_R-3"
                data-name="rib 2 R"
                class="stroke clear-fill"
                d="M300.61,155.34l34.17-1.22c4.67-.53,8.34-1.78,9.54-4.86"
              />
              <path
                id="rib_2_R-4"
                data-name="rib 2 R"
                class="stroke clear-fill"
                d="M309.77,163.79c6.06-.55,20.26,.96,33.74,2.29,5.57,.59,10.05,.35,11.97-1.82"
              />
            </g>
            <g id="rib_3_R" data-name="rib 3 R">
              <path
                id="rib_3_R-2"
                data-name="rib 3 R"
                class="clear-fill"
                d="M364,120.82l-9.25-3.82-27.84-6.26c-7.02-2.97-11.59-3.39-11.86,.64,.42,4.29,.75,8.81,3.42,7.92,9.5-.72,20.19,1.59,31.92,6.56l10.91,6.31"
              />
              <path
                id="rib_3_R-3"
                data-name="rib 3 R"
                class="stroke clear-fill"
                d="M306.8,187.21c21.59,3.33,39.48,2.58,51.07-5.09"
              />
              <path
                id="rib_3_R-4"
                data-name="rib 3 R"
                class="stroke clear-fill"
                d="M311.21,192.09l30.32,6.75c11.17,.73,21.14,.66,26.77-2.36"
              />
            </g>
            <g id="rib_4_R" data-name="rib 4 R">
              <path
                id="rib_4_R-2"
                data-name="rib 4 R"
                class="stroke clear-fill"
                d="M357,141.35c-6.36-3.2-17.09-5.11-29.04-6.64"
              />
              <path
                id="rib_4_R-3"
                data-name="rib 4 R"
                class="stroke clear-fill"
                d="M314.12,145.24c11.38,.16,23.02,.19,28.36,3.26l11.56,4.78"
              />
              <path
                id="rib_4_R-4"
                data-name="rib 4 R"
                class="stroke clear-fill"
                d="M295.97,222.04c24.81,5.06,49.41,9.95,62.16,5.11l8.65-6.77"
              />
              <path
                id="rib_4_R-5"
                data-name="rib 4 R"
                class="stroke clear-fill"
                d="M363.19,240.47c6.4-.28,11.97-1.6,15.31-5.76"
              />
            </g>
            <g id="rib_5_R" data-name="rib 5 R">
              <path
                id="rib_5_R-2"
                data-name="rib 5 R"
                class="stroke clear-fill"
                d="M311.24,170.43c1.3,2.21,3.71,3.7,7.37,4.36,7.51-1.2,14.44-1.43,20.39,0,9.03,1.33,17.03,4.81,24.44,9.51"
              />
              <path
                id="rib_5_R-3"
                data-name="rib 5 R"
                class="stroke clear-fill"
                d="M357.87,170.43c-2.1-2.33-7.94-3.44-14.36-4.36"
              />
              <path
                id="rib_5_R-4"
                data-name="rib 5 R"
                class="stroke clear-fill"
                d="M345.89,265.21c11.78,1.67,22.28,.75,30.3-5.12"
              />
              <line
                id="rib_5_R-5"
                data-name="rib 5 R"
                class="stroke clear-fill"
                x1="376.19"
                y1="278.28"
                x2="380.89"
                y2="277.35"
              />
              <path
                id="rib_5_R-6"
                data-name="rib 5 R"
                class="stroke clear-fill"
                d="M304.1,273.15c16.03,5.04,31.07,8.83,41.51,6.77"
              />
              <path
                id="rib_5_R-7"
                data-name="rib 5 R"
                class="stroke clear-fill"
                d="M430.04,235.6c1.31,4.94,2.39,8.63,2.62,10.77,5.19-7.74,5.81-14.36,1.88-19.88"
              />
            </g>
            <g id="rib_6_R" data-name="rib 6 R">
              <path
                id="rib_6_R-2"
                data-name="rib 6 R"
                class="stroke clear-fill"
                d="M306.8,202.18c7.85,5.18,18.45,5.88,29.95,5.08,14.93,4.47,28.32,11.31,40.56,19.89"
              />
              <path
                id="rib_6_R-3"
                data-name="rib 6 R"
                class="stroke clear-fill"
                d="M341.53,198.84c13.62,2.77,25.72,5.56,31.07,8.42"
              />
              <path
                id="rib_6_R-4"
                data-name="rib 6 R"
                class="stroke clear-fill"
                d="M426.42,240.47c5.96,4.96,10.46,10.17,12.7,15.75,1.62,6.48,.35,15.71-1.59,25.57-1.57-6.5-3.89-12.18-6.91-17.09-2.42-3.93-5.29-7.37-8.58-10.35"
              />
              <path
                id="rib_6_R_7_-2"
                data-name="rib 6 R 7 -2"
                class="stroke clear-fill"
                d="M426.26,270.82c1.98-1.15,3.46-3.62,4.7-6.73"
              />
              <path
                id="rib_6_R-5"
                data-name="rib 6 R"
                class="stroke clear-fill"
                d="M301.15,274.08c4.43,4.95,8.77,9.56,12.83,12.98,4.21,5.58,11.15,9.29,18.48,12.74"
              />
              <line
                id="rib_6_R-6"
                data-name="rib 6 R"
                class="stroke clear-fill"
                x1="357.57"
                y1="305.16"
                x2="385.79"
                y2="295.31"
              />
              <line
                id="rib_6_R-7"
                data-name="rib 6 R"
                class="stroke clear-fill"
                x1="382.4"
                y1="313.4"
                x2="406.54"
                y2="304.68"
              />
              <path
                id="rib_6_R-8"
                data-name="rib 6 R"
                class="stroke clear-fill"
                d="M404.53,289.4c5.75-1.95,10.8-4.36,12.59-8.89"
              />
              <path
                id="rib_6_R-9"
                data-name="rib 6 R"
                class="stroke clear-fill"
                d="M419.3,297.09c3.66-1.25,6.69-3.78,9.31-6.86"
              />
              <path
                id="rib_6_R-10"
                data-name="rib 6 R"
                class="stroke clear-fill"
                d="M313.27,314.18l.69,.05c16.79,5.71,32.48,7.67,46.28,3.16"
              />
            </g>
            <g id="rib_7_R" data-name="rib 7 R">
              <path
                id="rib_7_R-2"
                data-name="rib 7 R"
                class="stroke clear-fill"
                d="M308.27,229.62c28.6,5,56.3,10.06,70.23,16.04"
              />
              <path
                id="rib_7_R-3"
                data-name="rib 7 R"
                class="stroke clear-fill"
                d="M308.88,237.58c1.44,2.56,3.26,4.48,6.07,4.71,14.73,1.09,29.23,2.33,38.25,7.03,8.71,4.99,17.08,8.78,25.17,11.53"
              />
              <path
                id="rib_7_R-4"
                data-name="rib 7 R"
                class="stroke clear-fill"
                d="M414.73,261.93c10.12,7.55,20.04,15.09,24.82,22.69,2.39,8.02,2.58,17.29,.98,27.56-5.05-14.01-12.31-25.7-24.47-32.26-3.67-2.78-8.28-5.72-13.55-8.78"
              />
              <path
                id="rib_7_R-5"
                data-name="rib 7 R"
                class="stroke clear-fill"
                d="M383.96,332.5c-9.8,3.65-20.98,3.15-33.29-.77-10.68-3.05-19.89-6.7-27.56-10.97l-7.59-6.02"
              />
              <path
                id="rib_7_R-6"
                data-name="rib 7 R"
                class="stroke clear-fill"
                d="M430.96,305.65c2.42-1.64,3.96-3.27,4.61-4.91"
              />
              <line
                id="rib_7_R-7"
                data-name="rib 7 R"
                class="stroke clear-fill"
                x1="439.87"
                y1="314.18"
                x2="440.53"
                y2="312.18"
              />
              <path
                id="rib_7_R-8"
                data-name="rib 7 R"
                class="stroke clear-fill"
                d="M416.03,339.21c5.96-2.36,11.27-5.28,14-10.38"
              />
              <path
                id="rib_7_R-9"
                data-name="rib 7 R"
                class="stroke clear-fill"
                d="M402.81,327.01c7.97-3.42,15.6-6.19,17.44-9.62"
              />
              <path
                id="rib_7_R-10"
                data-name="rib 7 R"
                class="stroke clear-fill"
                d="M401.62,344.11c-4.99,3.91-12.49,5.94-21.5,8.17"
              />
            </g>
            <g id="rib_8_R" data-name="rib 8 R">
              <path
                id="rib_8_R-2"
                data-name="rib 8 R"
                class="stroke clear-fill"
                d="M293.9,257.39c19.09,1.5,36.91,3.61,49.73,8.11,22.86,8.97,43.85,17.21,60.9,23.91,15.15,8.52,30.25,17.24,35.33,24.78,2.96,9.54,3.02,19.61,1.7,29.93-10.68-14.81-21.62-28.56-33.52-38.39-9.22-6.56-22.68-11.04-37.69-14.77-9.31-3.58-17.62-7.5-24.75-11.03-14.11-7.85-28.36-8.68-42.45-6.64"
              />
              <path
                id="rib_8_R-3"
                data-name="rib 8 R"
                class="stroke clear-fill"
                d="M435.57,335.96c-1.59,8.68-3.57,12.52-8.97,14.77"
              />
              <path
                id="rib_8_R-4"
                data-name="rib 8 R"
                class="stroke clear-fill"
                d="M322.95,338.97c-1.45,7.83,1.5,14.7,10.85,20.18,17.21,9.35,33.3,14.37,46.97,12.09l3.19-.96"
              />
              <line
                id="rib_8_R-5"
                data-name="rib 8 R"
                class="stroke clear-fill"
                x1="402.52"
                y1="366.6"
                x2="414.61"
                y2="359.22"
              />
              <path
                id="rib_8_R-6"
                data-name="rib 8 R"
                class="stroke clear-fill"
                d="M414.61,379.39c.67,1.65,10.3-4.19,10.32-6.03"
              />
              <path
                id="rib_8_R-7"
                data-name="rib 8 R"
                class="stroke clear-fill"
                d="M399.09,383.93c-5.96,6.44-16.9,3.17-25.07,4.05"
              />
              <path
                id="rib_8_R-8"
                data-name="rib 8 R"
                class="stroke clear-fill"
                d="M441.57,345.34c-.62,8.02-2.41,12.58-6.66,17.78"
              />
            </g>
            <g id="rib_9_R" data-name="rib 9 R">
              <path
                id="rib_9_R-2"
                data-name="rib 9 R"
                class="stroke clear-fill"
                d="M293.43,291.09c3.43-4.21,7.95-4.79,14.07,0,3.96,1.35,7.07,2.73,7.88,5.06,6.88,2.55,34.53,5.79,56.67,12.09,14.8,7.14,28.49,16.34,41.34,27.14,11.16,12.42,22.1,24.79,24.05,34.82,2.22,10.23-1.8,19.84-6.61,29.37,1.25-12.18-2.93-23.36-10.97-33.82-2.97-6.77-12.19-16.64-21.23-26.44-5.68-4.55-15.01-7.99-24.47-11.39-4.89-1.41-9.46-5.51-13.92-10.55-8.72-4.21-21.88-7.21-35.44-11.67-4.94,.42-9.42,.28-12.52-1.55l-5.95-3.42"
              />
              <path
                id="rib_9_R-3"
                data-name="rib 9 R"
                class="stroke clear-fill"
                d="M410.94,399.65c-4.85,4.66-10.34,7.64-16.81,8.01"
              />
              <path
                id="rib_9_R-4"
                data-name="rib 9 R"
                class="stroke clear-fill"
                d="M416.03,409.07c-2.79,5.11-7.29,8.06-13.51,8.86"
              />
              <path
                id="rib_9_R-5"
                data-name="rib 9 R"
                class="stroke clear-fill"
                d="M423.03,390.98c3.51-.93,5.49-3.43,6.39-7.05"
              />
              <path
                id="rib_9_R-6"
                data-name="rib 9 R"
                class="stroke clear-fill"
                d="M427.82,402.47c.81,.42,1.61,0,2.42-1.27"
              />
            </g>
            <g id="rib_10_R" data-name="rib 10 R">
              <path
                id="rib_10_R-2"
                data-name="rib 10 R"
                class="stroke clear-fill"
                d="M295.13,321.84l18.14,4.55c15.98,4.34,30.72,8.82,42.33,13.64,17.62,8.51,33.76,16.64,43.17,23.06,13.18,12.06,23.27,23.95,28.27,35.58,3.05,20.2-2.97,32.06-13.78,39.52,7.62-14.55,6.42-29.08-4.64-43.59-4.87-7.25-17.17-17.84-29.39-28.41-15.29-5.6-28.51-11.34-34.88-17.58-7.78-4.87-20.62-9.39-33.19-13.92l-5.27-.67"
              />
              <path
                id="rib_10_R-3"
                data-name="rib 10 R"
                class="stroke clear-fill"
                d="M418.1,420.33c-2.45,5.02-7.17,5.85-11.46,4.43"
              />
              <path
                id="rib_10_R-4"
                data-name="rib 10 R"
                class="stroke clear-fill"
                d="M400.77,439.21c-7.07,.89-14.58-4.96-22.25-13.26-5.86-5.92-11.08-12.87-15.61-20.95-2.28-4.79-5.02-10.53-7.79-16.34"
              />
              <path
                id="rib_10_R-5"
                data-name="rib 10 R"
                class="stroke clear-fill"
                d="M412.73,438.83c-1.03,.7-2.17,1.01-3.45,.76"
              />
            </g>
            <g id="rib_11_R" data-name="rib 11 R">
              <path
                id="rib_11_R-2"
                data-name="rib 11 R"
                class="stroke clear-fill"
                d="M294.8,349.97c1.88-3.33,4.68-4.98,9.05-3.75,3.22,4.78,6.84,7.88,10.83,9.42l43.73,21.38c21.16,12.14,37.14,28.48,48.23,48.8,4.47,10.49,3.11,19.42-2.25,27.28-.29-13.81-11.59-27.9-25.03-42.05-21.85-21.91-43.81-39.93-62.72-45.7l-9-3.09-3.94-1.12"
              />
            </g>
            <g id="rib_12_R" data-name="rib 12 R">
              <path
                id="rib_12_R-2"
                data-name="rib 12 R"
                class="stroke clear-fill"
                d="M299.21,382.99c-1.26-7.1,1.14-8.69,6.69-5.53,6.97,7.13,13.75,13.5,19.47,15.72,10.85,7.67,19.6,16.38,31.36,22.92,10.76,5.18,18.41,14.95,24.61,26.86,2.32,4.88,2.18,8.66,0,11.53-9.49-6.77-17.5-16.85-25.73-26.44-7.27-4.3-21.31-17-34.31-28.41-4.34-4.2-9.51-7.22-15.39-9.14-1.3,.68-2.55,1.12-3.46-.42"
              />
            </g>
          </g>
        </g>
        <g id="thoracic_spine_overlay" data-name="thoracic spine overlay">
          <path
            id="thoracic_spine_overlay_1"
            data-name="thoracic spine overlay 1"
            onClick={() => {
              bpartSetter({
                id: idToSet(selected, Chest.id, Chest.children.thoracicSpine),
              });
            }}
            class={`stroke zone ${isSelected(
              selected,
              Chest.children.thoracicSpine
            )}`}
            d="M233.05,67.48c5.91,3.84,12.19,3.86,18.75,1.12,2.34-9.37,8.34-5.78,15.38,1.5,6.11,3.79,12.54,3.14,19.12,.38,7.25-7.62,12.5-7.5,15.75,.38l17.25-3.38c4.16,5.19,4.89,10.97-1.88,18.38h-10.88l-1.5,1.87,13.5,.75c3.31,2.93,2.64,6.57,.38,10.5l-11.25-.38-8.25,9.75,14.25,2.25,.75,8.62-13.12,5.62-3,8.63c5.27,2.65,9.83,3.81,11.62-.75,6.05,3.87,7.45,8.97,1.12,16.12l-11.62-.75,.38,13.5h8.25c3.1,2.55,4,7.28,3,13.88l-11.62-.38,.38,10.88,9,2.25c4.75,6.71,2.74,12.46-8.62,16.88l-4.88,13.87,4.88,10.12,6.38-1.12c2.59,2.04,2.97,5.75,1.5,10.88l-9,1.5c.79,7.86-.7,13.16-5.25,15l1.12,6,6.38,1.5c3.95,3.13,6.46,6.43,2.62,10.5l-6.75,1.88c2.83,4.25,2.57,7.41-1.12,9.38l-4.12,2.25,2.25,6c11.98,4.96,15.65,10.12,.75,15.75,1.88,5.61,2.85,10.77-1.12,13.5-.8,3.79,1.58,5.52,9.38,3.75,2.57,6.92,3.12,11.63,.75,13.12l-9-.38-.75,11.25,5.62,3.75,4.12,9.38-7.88,6.75c1.76,4.7,2.46,8.87-.38,11.25,4.06,3.47,7.3,7.23,5.62,12.75-7.1,3.86-9.07,8.67-4.88,14.62-.2,4.61-1.4,7.56-7.12,3l-10.12,.75c-3.23,8.08-6.45,10.13-9.38,0h-10.5c-4.08-1.73-6.08-4.19-1.5-9,3.68-6.86,1.17-10.01-4.5-11.25-5.09-2.07-4.08-5.91,3.38-11.62,2.84-9.35-.87-15.29-8.62-19.12-2.1-6.59,.66-8.63,6.38-7.88,3.93,3.07,5.12,1.45,4.88-2.62l-1.88-4.88c4.88-4.65,.31-8.06-6-11.25-6.68-3.08-4.33-6.08,6.38-9l3.38-1.13-3.75-4.12,1.5-10.12c-11.42-7.31-13.92-12.84-1.5-15.38l1.5-6.38-4.12-3.37c3.97-3.8,3.07-7.92-3-12.38-13.13-6.81-9.37-10.9,6-13.12,3.64,1.02,5-.84,3.75-6,1.37-9.88-5.11-13.7-13.88-15.75-5.3-5.96-4.64-9.43,2.62-10.12l7.12,1.87,6.38-10.88-13.5-11.25-.38-8.62-7.88-1.87c-5.23-8.24-4.65-12.38,4.5-10.5l7.12,3,5.25-4.12-4.5-2.25-4.5-11.25h-10.5c-1.27-10.62,.8-16.02,9.38-11.25l9.75,1.87-2.62-12.38-13.5-4.87c-5.51-4.2-5.63-8.33,.38-12.38,8.92,4.65,16.46,4.35,22.88,0l-8.25-.75-7.5-12-11.25-1.87c-2.28-2.77-1.39-6.17,2.25-10.12l19.12,3c-7.43-2.29-11.72-8.01-13.5-16.5-4.11,2.5-9.92,.64-16.12-2.25l3.38-7.5c6.25-1.14,12.26-1.55,17.25,1.12l-4.5-5.25c-4.72,2.21-9.02,.37-13.12-3.38-3.39-3.27-3.49-6.52-.38-9.75h-.04Z"
          />
        </g>
        <g id="shoulder_plate_R_overlay" data-name="shoulder plate R overlay">
          <path
            id="shoulder_plate_R_overlay_1_"
            data-name="shoulder plate R overlay (1)"
            onClick={() => {
              bpartSetter({
                id: idToSet(selected, Chest.id, Chest.children.shoulderBladeR),
              });
            }}
            class={`stroke zone ${isSelected(
              selected,
              Chest.children.shoulderBladeR
            )}`}
            d="M387.92,106.85c-13.1-8.05-20.87-5.71-22.88,7.88l-4.5,21.38c-8.62,12.97-6.32,24.97-1.5,36.75,20.96,41.21,21.58,65.35,19.5,90.38-.52,15.65,4.47,21.42,18.38,11.25l10.88-8.62c12.08-4.96,16.88-13.94,18.38-24.75,7.74-11.35,10.96-23.87,12.75-36.75,8.28-11.2,12.17-21.86,13.88-32.25,2.19-6.87,5.59-10.55,10.12-11.25,8.7-1.47,5.91-9.81,3.38-18-7.42-.51-9.95-11.24-13.12-20.62-12.11,1.13-18.49-4.48-19.12-16.88-6.63-2.37-12.4-.09-17.25,7.12-9.84,6.06-19.38,.95-28.88-5.62l-.02-.02Z"
          />
        </g>
        <g id="shoulder_joint_overlay_R" data-name="shoulder joint overlay R">
          <path
            id="shoulder_joint_overlay_R_1"
            data-name="shoulder joint overlay R 1"
            onClick={() => {
              bpartSetter({
                id: idToSet(selected, Chest.id, Chest.children.shoulderJointR),
              });
            }}
            class={`stroke zone ${isSelected(
              selected,
              Chest.children.shoulderJointR
            )}`}
            d="M435.55,105.73c.4,10.09,5.09,16.47,18,15.75,3.9,8.86,7.71,16.8,11.25,21.75,11.21,19.17,25.96,20.76,44.62,3,2.38-10.07,1.9-18.5-3-24.38-4.53-2.86-4.2-7.7-1.5-13.5-7.02-10.88-17.29-18.28-33-19.88-4.86,2.46-7.83,5.63-8.25,9.75l-28.12,7.5h0Z"
          />
        </g>
        <g
          id="upperarm_with_shoulder_joint_overlay_R"
          data-name="upperarm with shoulder joint overlay R"
        >
          <path
            id="upperarm_with_shoulder_joint_R_1"
            data-name="upperarm with shoulder joint R 1"
            onClick={() => {
              bpartSetter({
                id: idToSet(
                  selected,
                  Chest.id,
                  Chest.children.upperArmWithShoulderR
                ),
              });
            }}
            class={`stroke zone ${isSelected(
              selected,
              Chest.children.upperArmWithShoulderR
            )}`}
            d="M461.8,162.35c5.63,.07,8.03-3.67,7.5-10.88,12.88,13.27,26.3,10.15,40.12-5.25l-9.75,33c-1.9,27.2-1.4,51.58,6,67.88,1.09,17.96,1.31,34.63-.75,48l-28.12,14.25c3.89-25.42,3.9-51.13-1.88-77.25-2.95-14.48-3.25-35.51-3.38-57-.82-6.65-4.64-10.34-9.75-12.75h.01Z"
          />
        </g>
        <g id="shoulder_plate_overlay_L" data-name="shoulder plate overlay L">
          <path
            id="shoulder_plate_overlay_L_1"
            data-name="shoulder plate overlay L 1"
            onClick={() => {
              bpartSetter({
                id: idToSet(selected, Chest.id, Chest.children.shoulderBladeL),
              });
            }}
            class={`stroke zone ${isSelected(
              selected,
              Chest.children.shoulderBladeL
            )}`}
            d="M171.55,275.23c-.53-27.33,2.37-50.94,9-70.5,20.16-39.72,20.71-63.38,9.75-77.62,.85-33.11-13.05-28.58-29.62-17.25l-21,1.5c-7.25-8.12-13.4-11.51-18-8.25l-21.75,17.62c-18.71,24.05-18.63,34.9-7.88,38.25,6.65,5.77,11.45,11.53,10.5,17.25-.18,9,3.38,18.41,13.12,28.5l12.75,43.88c13.37,16.07,27.42,27.22,43.12,26.62h0Z"
          />
        </g>
        <g id="shoulder_joint_overlay_L" data-name="shoulder joint overlay L">
          <path
            id="shoulder_joint_overlay_L_1"
            data-name="shoulder joint overlay L 1"
            onClick={() => {
              bpartSetter({
                id: idToSet(selected, Chest.id, Chest.children.shoulderJointL),
              });
            }}
            class={`stroke zone ${isSelected(
              selected,
              Chest.children.shoulderJointL
            )}`}
            d="M118.3,103.1c-7.98-.77-15.69-3.52-23.25-7.5-6.89-5.43-13.64-7.42-20.62-7.12-12.25,4.2-19.57,9.01-23.25,14.25-.32,4.12,1.71,6.64,5.62,7.88-1.13,4.23-4.6,6.61-9,8.25-8.4,5.22-10.7,12.04-7.5,20.25l4.5,14.62c15.8-7.11,30.46-11.22,39.75-1.12-1.52-7.75,2.54-16.86,10.88-27l22.88-22.5h0Z"
          />
        </g>
        <g
          id="upperarm_wth_shoulder_joint_L_overlay"
          data-name="upperarm wth shoulder joint L overlay"
        >
          <path
            id="upperarm_with_shoulder_joints_overlay_L_1"
            data-name="upperarm with shoulder joints overlay L 1"
            onClick={() => {
              bpartSetter({
                id: idToSet(
                  selected,
                  Chest.id,
                  Chest.children.upperArmWithShoulderL
                ),
              });
            }}
            class={`stroke zone ${isSelected(
              selected,
              Chest.children.upperArmWithShoulderL
            )}`}
            d="M47.05,153.73c3.78,8.09,6.81,16.8,5.25,29.25,4.62,22.19,1.38,47.63-5.62,74.62-1.23,8.87-.85,20.57,.38,33.75l28.5,12.38c.18-45.69,1.42-87.94,6-119.25-3.22-8.88,.83-17.06,9.75-24.75-9.83-13.22-23.73-17.15-44.25-6h0Z"
          />
        </g>
        <g id="upperribs_L_overlay" data-name="upperribs L overlay">
          <path
            id="upperribs_L_overlay_1"
            data-name="upperribs L overlay 1"
            onClick={() => {
              bpartSetter({
                id: idToSet(selected, Chest.id, Chest.children.upperRibsL),
              });
            }}
            class={`stroke zone ${isSelected(
              selected,
              Chest.children.upperRibsL
            )}`}
            d="M231.55,69.35c-.19-5.33-2.98-4.67-7.5,0-16.53,3.67-24.94,13.49-27,28.12l-10.5,5.25,3.75,13.5v6.38l4.12,8.62,3.75,10.88,.75,13.5-4.12,17.25-5.25,16.13c-5.64,9.8-9.88,18.33-9.75,22.88l-3.38,16.87-3.31,33.68-1.19,12.07c-10.9,6.08-30.02-10.94-43.5-25.88-1.71-3.44-2.71-6.8-3.38-10.12-13.77,8.59-14.55,22.17-7.88,38.62-4.72,15.68-5.68,25.86-1.5,28.5,1-2.83,5.45-10.43,11.25-19.88,9.74-6.8,18.7-12.1,26.62-15.38,4.8,4.31,10.61,6.45,17.62,6l3.75-13.5,10.88-5.25c5.35-.15,10.32-1.14,13.5-6,10.55-3.1,20.34-7.21,28.5-13.5,6.65,.75,12.46-.12,17.62-2.25-1.81-2.57-1.41-5.2,.75-7.88,4.05-.47,7.81-.42,10.5,1.5l6.38-10.88c-5.19-2.79-9.47-7.06-13.12-12.38l-.38-7.13-9.75-3.75c-1.1-6.26-1.87-12.14,5.25-9l8.62,1.87,4.88-2.62-4.5-3.37c1.48-3.66,.53-6.99-4.5-9.75l-9.38-1.88c-2.79-8.66-1.53-13.46,8.62-9.75l9.38,1.5-1.5-11.62c-3.3-4.39-8.38-5.7-14.25-5.62-6-4.25-6-8.5,0-12.75,9.77,4.58,17.07,4.32,22.5,.38l-6.75-1.88-9-10.87c-4.79-.26-9.13-.84-11.25-3-1.53-1.73-.09-5.24,3-9.75,5.13,1.91,11.35,2.68,18,3l-6.38-4.5-5.62-9.38-13.12-1.87c-3.48-.98-2.69-4.7,.75-10.12l16.12,1.5-3-5.62c-9.58,3.55-15.7-1.64-16.12-10.88h.02Z"
          />
        </g>
      </g>
      <g id="upperribs_R_overlay" data-name="upperribs R overlay">
        <path
          id="upperribs_overlay_R_1"
          data-name="upperribs overlay R 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.upperRibsR),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.upperRibsR
          )}`}
          d="M320.28,71.64c.66-3.42,3.2-4.51,7.31-3.66,12.67,6.54,22.53,14.88,28.69,25.59l14.62,7.88c-2.74,3.46-4.88,6.47-5.62,8.44-.95,3.64-1.48,7.12-1.41,10.41l-3.38,15.75c-9.17,12.52-7.77,23.66-2.53,34.31,5.74,13.85,11.38,27.49,14.34,36.28,4.58,13,6.73,25.85,6.19,38.53-3.04,32.68,2.75,47.39,28.69,21.09,14.22-6.37,18.37-15.79,19.41-26.16l8.16-13.78c3.46,5.66,4.17,11.94-1.69,19.69,2.76,2.96,5.25,6.29,6.75,10.97,.77,8.16,.03,15.69-1.97,22.78,4.2,12.61,5.28,23.56,2.81,32.62-2.1-8.07-6.44-15.84-12.94-23.34-6.32-7.21-15.34-12.99-24.75-18.56-17.9,17.33-25.19,11.58-25.03-10.41-7.49-.13-15.11-4.25-22.78-10.12-10.5-5.38-25.83-7.03-42.19-7.88l-5.34-3.66c2.72-8.64,.36-12-7.03-10.12l-5.62-8.44,3.94-15.19c15.27-4.2,14.99-10.39,8.16-17.44l-7.88-1.41c2.45-2.86,2.12-6.8-.28-11.53l11.81-.28c2.11-12.79-3.6-14.15-11.25-12.66,2.98-3.16,2.27-7.63,0-12.66l12.38-.28c5.45-6.65,3.62-11.86-.56-16.59-4.82,4.53-9.39,4.76-13.78,1.69,3.05-.91,4.14-3.5,2.81-8.16l14.62-6.75-1.12-9-13.78-2.53,6.75-6.75,12.94-1.69c3.71-2.09,3.11-5.85-.28-10.69l-14.91-.56,3.09-2.25,9,.28c6.39-3.85,6.96-8.54,3.66-13.78Z"
        />
      </g>
      <g id="underribs_overlay_R" data-name="underribs overlay R">
        <path
          id="underribs_overlay_R_1"
          data-name="underribs overlay R 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.lowerRibsR),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.lowerRibsR
          )}`}
          d="M293.84,256.98c18.45,.73,35.61,3.4,50.62,9.28,24.59,9.08,49.36,18.22,60.47,22.5,14.74,8.6,28.66,17.18,34.88,25.59,3.57,17.66,4.26,34.39-4.5,48.09,3.72,9.94,3.53,21.8-4.22,37.41l-3.38,2.53c.4,12.18-.1,24.3-13.78,35.72l-4.5,1.69-4.78,13.5-4.5-14.34c-13.3-.86-27.89-15.84-44.16-49.22-14.61-14.16-31.87-23.54-51.47-28.69l-9,7.88c4.06,5.66,2.72,9.57-.28,12.94l3.94,1.69c-.77-5.02,.69-7.79,6.47-6.19,6.47,6.97,13.02,12.54,19.69,16.03,12.68,10.56,25.03,19.93,36.28,25.31,9.73,7.9,16.67,17.66,21.38,28.97l-1.69,6.75-25.59-25.88-39.94-33.75-9.28-4.5-3.66-.56-1.41-4.5c-2.81-2.54-6.88-2.16-5.06-5.62,3-3.38,2.25-7.12-2.25-11.25l9.28-5.06c1.89-2.51,.6-5.84-3.38-9.84-4.39,1.41-6,.1-4.5-4.22l-.56-11.53c10.81,5.54,14.7,2.31,9.56-12.38l-10.41-3.09c3.68-4.16,3.02-9.07,.28-14.34,16.89-4.35,13.22-9.44-.56-14.91l-1.41-6.75,4.22-1.41c3.09-2.61,3.32-6.22,1.12-10.69,10.3-.5,10.49-4.87,3.94-11.81l-7.03-.56-.84-4.78Z"
        />
      </g>
      <g id="underribs_overlay_L" data-name="underribs overlay L">
        <path
          id="underribs_overlay_L_1"
          data-name="underribs overlay L 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.lowerRibsL),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.lowerRibsL
          )}`}
          d="M257.84,260.08c1.93-4.38,.8-6.42-5.91-4.22-6.44,4.27-14.79,5.44-23.91,5.34-8.69,5.61-17.97,8.99-27.84,10.12-11.66,.98-23.39,6.34-35.16,13.78-10.02,1.57-22.05,10.74-34.31,20.81-28.13,21.95-24.1,38-9,52.03-3.16,17.69-2.5,32.89,6.75,42.47,1.86,12.63,5.84,21.83,12.66,26.44l3.66,2.81c.24,9.73,2.63,16.44,7.88,19.13-2.38-5.61-2.22-11.47,1.97-17.72,2.48,11.58,26.75-14.17,55.97-48.38l36.28-21.38c8.52,3.05,13.33,8.38,10.69,18.28l-3.09,1.69c-3.03-6.29-7.73-4.41-14.62,8.16-25.34,7.19-54.37,28-68.06,53.16-.58,5.79-.65,10.39,.84,11.25,2.52-1.68,8.1-9.71,14.06-18.56,8.69-4.67,16.31-10.31,21.94-17.72,10.51-4.61,19.25-10.65,25.31-18.84l16.31-9.56c-1.82-2.67,2.93-7.71,8.16-12.94,.9-7.07-3.95-11.05-9.56-14.62-3.92-6.38-2.77-9.7,7.88-7.31,2.12,.61,3.05-.99,3.09-4.22l-1.97-4.5c5.06-4.15-1.03-7.99-7.59-11.81-5.34-2.08-1.01-4.96,7.31-8.16,3.03-1.27,3.5-2.83,.56-4.78-2.88-1.1-1.64-5.48,.28-10.41-14.39-10.15-12.76-14.5,.56-14.62l-.56-8.44-3.66-3.38c4.68-5.13-.45-9.79-7.88-14.34-7.83-5.67-1.02-8.18,10.97-9.56Z"
        />
      </g>
      <g id="collarbone_overlay_R" data-name="collarbone overlay R">
        <path
          id="collarbone_overlay_R_1"
          data-name="collarbone overlay R 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.collarboneR),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.collarboneR
          )}`}
          d="M362.18,95.83l10.12,5.91c1.76-1.94,4.9-1.21,8.72,.84,26.34-8.61,51.16-10.22,74.25-3.94l8.44-.28c.43-3.49,2.3-6.46,6.19-8.72-7.16-4.71-12.53-6.39-16.31-5.34l-19.69-5.34c-23.81,.93-47.73,7.26-71.72,16.88Z"
        />
      </g>
      <g id="collarbone_overlay_L" data-name="collarbone overlay L">
        <path
          id="collarbone_overlay_L_1"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.collarboneL),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.collarboneL
          )}`}
          data-name="collarbone overlay L 1"
          d="M193.15,92.73c.14,4.24-2.09,7.39-7.03,9.28l-9.56-1.69c-25.19-10.46-52.03-10.41-79.88-3.94l-3.66-1.13-3.09,.56c.88-3.98-3.11-6.07-9.28-7.31l8.44-5.91c7.66,.95,14.15-.17,19.12-3.94,1.26-6.23,31.41-.8,84.94,14.06Z"
        />
      </g>
    </svg>
  );
}

export default BackView;
