import style from "./style.scss";

export default function RechterEllenbogenLatSitzend2214(props) {
  return (
    <svg
      id="Anconlat"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 610.35 504.95"
    >
      <g id="Backround">
        <path
          id="backround1"
          class="elbow_lat_right2214_cls3"
          d="M599.19,406.98c-15.01,1.07-20.83,1.11-23,1-10.51-.52,16.48,1.29-35,0-45.09-1.13-71.69-2.66-101-3-55.61-.64-46.99,3.87-88,2-14.92-.68-39.72-2.36-74,0-5.74.39-35.93,2.47-36,6-.03,1.56,8.31,3.39,25,7,10.81,2.34,16.22,3.51,25,5,16.47,2.8,29.89,4.24,38,5,26.22,2.47,49.9,2.87,69,3,32.23.22,74.74.52,123-2,20.64-1.08,41.59,2.24,62-1,10.61-1.68,19.37-3.93,24,1,5.8,6.17-1.88,16.14-2,42-.07,14.01,2.16,17.1-1,21-6.29,7.76-21.01,2.72-44,1-6.7-.5-6.09-.12-48,2-11.41.58-72.72,3.68-113,5-58.79,1.93-48.21-.74-126,1-43.91.98-62.84,2.18-100,1-46.44-1.47-39.38-4.06-60-3-3.01.15-36.77,1.94-66-5-4.07-.97-11.4-2.9-15-9-3.83-6.49.37-11.21,0-23-.38-12.06-4.92-11.74-9-32-3.67-18.22-.89-22.91,1-25,3.21-3.57,7.44-3.65,8-7,.62-3.74-4.33-5.58-7-12-1.85-4.44-1.31-7.99-1-13,.53-8.66-.88-17.33-1-26-.26-18.71-3.76-70.78-8-121-4.59-54.37-5.69-54.83-9-100-6.31-86.1.01-52.55-3-76C1.3,29.47-3.46,6.82,6.19.98c2.97-1.8,5.13-.69,18.44,0,4.22.22,12.15.15,28,0,31.65-.29,37.54-2.41,40.56,2,2.34,3.41-1.19,4.66-3.56,25-1.29,11.07-1.67,22.9-1.71,24.26-.02.64-.07,1.95-.09,3.55-.13,10.24.66,8.9.93,26.41.26,16.9-.31,28.89-.57,35.78-.74,19.41-2.02,52.94,2,90,2.02,18.62,3.41,20.03,6,45,2.72,26.19,4.07,39.29,4,42-.26,9.6-1.32,17.47,3,27,1.44,3.18,4.67,10.3,12,15,6.81,4.37,9.55,1.77,24,7,7.54,2.73,11.39,4.17,15,8,5.05,5.35,5.87,11.27,8,11,1.78-.22,1.67-4.41,5-12,2.29-5.21,3.43-7.82,6-10,3.99-3.39,8.78-3.61,17-4,6.2-.29,9.3-.44,12,1,5.79,3.08,4.47,8.47,10,13,1.06.86,4.27,3.26,17,4,11.16.65,19.79-.54,23-1,19.71-2.82,24.95-4.25,37-6,15.68-2.27,23.52-3.41,34-3,13.91.54,17.14,2.99,35,4,8.49.48,11.78.15,24,0,13.99-.17,26.66.22,52,1,24.1.74,36.15,1.11,48,2,33.61,2.51,29.67,4.8,49,5,23.09.24,32.72.49,52,1,9.85.26,14.86.46,19,4"
        />
      </g>
      <g id="Overview">
        <path
          id="outline3"
          class="elbow_lat_right2214_cls6"
          d="M595.73,408.22c-3.74,1.15-8.47.32-20.3-.27-10.51-.52,16.48,1.29-35,0-45.09-1.13-71.69-2.66-101-3-55.61-.64-46.99,3.87-88,2-33.23-1.51-49.76-4.97-74,0-13.32,2.73-20.83,6.34-36,6-11.16-.24-18.04-2.43-26.76,2.02-5.19,2.65-13.66,9-15,10-6.51,4.88-8.44,7.07-12,7-3.67-.07-5.66-2.48-12-8-11.87-10.33-11.97-8.02-15-12-8.53-11.22-5.74-26.88-5-31,.69-3.85,2.38-8.58,5.76-18.02,1.11-3.09,2.61-7.11,5-12,2.73-5.59,4.1-8.38,6-10,3.99-3.39,8.78-3.61,17-4,6.2-.29,9.3-.44,12,1,5.79,3.08,4.47,8.47,10,13,1.06.86,4.27,3.26,17,4,11.16.65,19.79-.54,23-1,19.71-2.82,24.95-4.25,37-6,15.68-2.27,23.52-3.41,34-3,13.91.54,17.14,2.99,35,4,8.49.48,11.78.15,24,0,13.99-.17,26.66.22,52,1,24.1.74,36.15,1.11,48,2,33.61,2.51,29.67,4.8,49,5,23.09.24,32.72.49,52,1,5.62.15,9.66.28,12.86,1.02"
        />
        <path
          id="outline2"
          class="elbow_lat_right2214_cls6"
          d="M596,497.98c-7.94.89-19.81-1.85-35.57-3.02-6.7-.5-6.09-.12-48,2-11.41.58-72.72,3.68-113,5-58.79,1.93-48.21-.74-126,1-43.91.98-62.84,2.18-100,1-46.44-1.47-39.38-4.06-60-3-3.01.15-36.77,1.94-66-5-4.07-.97-11.4-2.9-15-9-3.83-6.49.37-11.21,0-23-.38-12.06-4.92-11.74-9-32-3.14-15.62-1.54-21.22,1-25,2.66-3.96,6.52-6.17,8-7,2.02-1.13,9.03-5.05,15.24-2.98,6.13,2.04,5.9,8.06,12,15,6.67,7.59,15.34,9.93,23,12,7.8,2.11,21.76,5.88,35-1,8.9-4.63,12.97-11.73,20-24,5.45-9.51,10.14-17.7,9-28-.89-8.12-4.82-12.27-3-14,2.98-2.83,16.23,5.69,25,13,9.33,7.78,9.07,10.42,19,19,4.79,4.14,11.88,10.18,22,15,7.36,3.5,7.1,1.97,31.76,8.98,12.54,3.57,15.84,5.02,25,7,10.81,2.34,16.22,3.51,25,5,16.47,2.8,29.89,4.24,38,5,26.22,2.47,49.9,2.87,69,3,32.23.22,74.74.52,123-2,20.64-1.08,41.59,2.24,62-1,4.31-.68,8.32-1.46,11.86-1.81"
        />
        <path
          id="outline1"
          class="elbow_lat_right2214_cls6"
          d="M96.63.97c-5.9,27.53-9.34,47.76-9.47,51.25-.07,1.79-.09,3.55-.09,3.55-.13,10.24.66,8.9.93,26.41.26,16.9-.31,28.89-.57,35.78-.74,19.41-2.02,52.94,2,90,2.02,18.62,3.41,20.03,6,45,2.72,26.19,4.07,39.29,4,42-.26,9.6-1.32,17.47,3,27,1.44,3.18,4.67,10.3,12,15,6.81,4.37,9.55,1.77,24,7,9.62,3.48,13.21,6.37,15,8,1.9,1.72,5.53,5.43,8,11,5.08,11.48,1.59,22.69.24,27.02-4.17,13.39-13.21,21.08-19,26-12.33,10.48-24.79,14.21-31,16-10.33,2.97-34.13,9.82-55-2-5.36-3.04-14.5-8.21-19-18-1.59-3.47-2-6.31-5.24-12.02-3.24-5.72-5.15-7.55-7-12-1.85-4.44-1.31-7.99-1-13,.53-8.66-.88-17.33-1-26-.26-18.71-3.76-70.78-8-121-4.59-54.37-5.69-54.83-9-100-2.58-35.11-1.88-34.42-1.75-42.17.15-8.32-.03-17.81-1.25-33.83C1.96,32.6,1.02,30.84.63,17.98c-.22-7.13-.12-13.02,0-17"
        />
        <path
          id="partofancon4"
          class="elbow_lat_right2214_cls6"
          d="M88.67,277.98c-1.78,11.12-.96,20.18,0,26,1.07,6.48,2.28,13.41,7,21,.79,1.28,4.61,7.28,12,13,10.02,7.75,14.43,5.57,21,11,11.48,9.5,11.18,27.13,11,31-.15,3.13-.95,20.36-15,31-14.24,10.79-31.29,6.63-38,5-7.43-1.81-20.59-5.02-27-17-5.41-10.12-4.28-23.24,1-32,3.01-5,5.6-5.63,18-21,2.89-3.58,5.48-6.93,5-11-.51-4.34-4.2-7.01-8-10,0,0-10.38-8.16-19-17-7.1-7.28-9.73-15.85-15-33-2.01-6.56-4.4-15.72-6-27"
        />
        <path
          id="partofancon3"
          class="elbow_lat_right2214_cls6"
          d="M30.67,226.01c-4.96,0-8.98-94.84-8.98-212.03"
        />
        <path
          id="partofancon2"
          class="elbow_lat_right2214_cls6"
          d="M82.9,274.98c-6.21,0-24.49-115.02-12.49-272.02"
        />
        <path
          id="partofancon1"
          class="elbow_lat_right2214_cls6"
          d="M51.95,392.9c-8.97-5.99-15.84-14.91-25.77-26.92"
        />
        <path
          id="radius5"
          class="elbow_lat_right2214_cls6"
          d="M166.43,350.96c6.62-4.98,14.64,26.51-2.33,63.02"
        />
        <path
          id="radius4"
          class="elbow_lat_right2214_cls6"
          d="M164.1,413.98c4.6,0,30.57-36.51,8.33-73.02"
        />
        <path
          id="ulnar3"
          class="elbow_lat_right2214_cls6"
          d="M291.67,433.98c-42,9-78,2.29-78-3.54"
        />
        <line
          id="ulnar2"
          class="elbow_lat_right2214_cls6"
          x1="595.67"
          y1="483.98"
          x2="201.67"
          y2="481.98"
        />
        <line
          id="ulnar1"
          class="elbow_lat_right2214_cls6"
          x1="228.67"
          y1="440.98"
          x2="608.83"
          y2="443.57"
        />
        <path
          id="radius3"
          class="elbow_lat_right2214_cls6"
          d="M348.67,355.98c-18.33-6.99-32.55-7.11-42-6-3.45.4-15.35,3.61-39,10-16.16,4.37-25.07,6.86-35,3-5.9-2.29-16.45-3.03-19-5.8"
        />
        <path
          id="radius2"
          class="elbow_lat_right2214_cls6"
          d="M595.67,371.98c-7.26-.57-18.79-1.39-33-2-32.58-1.41-42.64-.15-69-2-23.25-1.63-18.61-2.82-39-4-28.09-1.62-38.47.56-61-2-15.84-1.8-28.4-4.88-36-7"
        />
        <path
          id="radius1"
          class="elbow_lat_right2214_cls6"
          d="M600.67,395.98c-3.25-.27-8.1-.65-14-1-38-2.26-62.08.07-84,1-44.57,1.88-37.32-3.84-96-3-16.61.24-7.29.55-70,3-27.57,1.08-39.97,1.43-58,4-20.41,2.91-36.83,7.07-47.57,10.15"
        />
      </g>
      <g id="Highlights">
        <path
          id="forearmparietalhighlight"
          class="unterarm2214fill"
          style={{
            fill: props.colors.Unterarm2214Fill,
            stroke: props.colors.Unterarm2214fill,
          }}
          d="M596,497.98c-1.42.07-3.58.13-6.21.07-6.42-.16-9.49-.95-17.78-2.04-6.58-.87-9.95-1.31-14.56-1.42-6.53-.15-11.37.48-12.72.64-7.85.93-43.32,2.47-114.26,5.55-85.88,3.73-119.05.67-180.23,2.91,0,0-24.65.9-56.76.76-11.36-.05-18.8-.31-25.62-.56-6.71-.24-10.49-.38-15.91-.81-5.72-.45-7.63-.77-15.39-1.4-4.46-.36-8.7-.71-12.79-.89-9.13-.39-11.32.32-21.17.49-11.99.2-21.3-.65-28.45-1.3-3.24-.3-7.55-.87-16.18-2.02-10.15-1.35-13.53-1.97-17.49-4.25-2.87-1.65-6.2-3.58-8.26-7.34-4.08-7.45,2.08-12.55,0-25.08-.37-2.24-.33-.64-4.18-13.08-2.35-7.59-3.54-11.47-4.51-15.84-2.77-12.46-1.79-17.23-1.37-18.94.45-1.87,1.14-4.72,3.19-7.69,4.9-7.12,15.25-11.54,22.65-8.83,6.1,2.23,5.73,7.67,12.13,14.76,9.37,10.38,22.68,12.58,26.09,13.15,18.53,3.07,37.15-6.4,47.93-19.74,7.13-8.82,16.97-26.94,11.6-39.45-1.04-2.42-3.65-6.97-2.07-8.65,1.77-1.89,7.87.85,8.88,1.3,5.41,2.43,6.29,5.21,8.36,4.74,2.63-.6,2.2-5.32,6.14-13.51,2.26-4.7,3.64-7.58,6.74-9.71,3.14-2.16,6.87-2.31,14.35-2.62,6.71-.28,10.06-.41,12.41.7,6.05,2.85,4.87,8.24,11.07,12.91,4.05,3.05,8.18,3.47,15.78,4.25,9.06.93,16.08,0,21.05-.69,14.62-2.03,25.27-3.97,30.11-4.81,20.32-3.53,30.48-5.29,43.49-4.82,13.02.47,13.84,2.55,30.13,4.04,15.06,1.38,21.78.28,39.44-.2,3.86-.11,4.2-.07,71.4,2.43,16.76.62,25.97.97,39.02,2.83,8.02,1.14,9.63,1.74,16.79,2.43,6.11.59,9.79.61,32.76.98,14.57.23,13.97.23,14.16.23,9.3-.03,14.41-.41,22.44.47,3.07.34,5.55.75,7.11,1.03.24,46.34.47,92.67.71,139.01Z"
        />
        <path
          id="radialheadhighlight"
          class="radiushead2214fill"
          style={{
            fill: props.colors.RadiusHead2214Fill,
            stroke: props.colors.RadiusHead2214Fill,
          }}
          d="M210.39,384.98c-.48-2.59-1.17-4.49-1.25-8.12-.04-1.79.08-3.6.64-6.94,1.8-10.83,4.5-15.68,2.12-18.17-.64-.67-1.3-.81-2.53-2.19-.53-.6-1.08-1.32-2.43-3.88-1.99-3.76-2.15-4.73-3.54-6-.89-.82-1.73-1.24-2.32-1.53-2.98-1.49-7.51-1.29-17.5-.69-4.93.3-7.98.89-10.62,3.03-1.45,1.18-2.35,2.6-4.15,5.46-1.93,3.06-3,5.46-4.85,9.61-.6,1.35-1.89,4.3-3.35,8.18-.53,1.41-1.08,2.95-1.64,4.63-1.82,5.44-2.86,8.54-3.65,12.8-.62,3.38-2.38,13.42.95,23.6,1.83,5.6,4.08,7.88,5.31,8.93,1.15.98,1.69,1.12,4.15,2.83,2.71,1.88,4.53,3.45,7.08,5.61,5.39,4.57,8.08,6.86,8.24,7.03.74.79,2,2.19,4.1,2.78,1.55.44,2.89.23,3.39.15.44-.07,1.92-.35,5.26-2.63.72-.49,1.13-.81,2.83-2.12,5.12-3.97,5.42-4.23,6.57-5.06,3.34-2.41,3.2-1.93,4.3-2.98.28-.27,2.98-2.91,3.99-6.52,1.06-3.79-.71-5.16-1.52-10.92-1.14-8.12,1.9-8.9.4-16.89Z"
        />
        <path
          id="condyleshighlight"
          class="oberarmcondylen2214fill"
          style={{
            fill: props.colors.Oberarmcondylen2214Fill,
            stroke: props.colors.Oberarmcondylen2214Fill,
          }}
          d="M105.75,433.93c5.76-1.22,18.14-3.95,31.25-12.94,5.7-3.91,13.21-9.18,19.31-19.01,2.21-3.56,8.26-13.57,7.99-25.99-.04-2.02-.2-7.14-2.53-13.15-.72-1.86-1.77-4.52-3.94-7.38-1.38-1.82-4.1-4.9-9.71-7.89-1.47-.78-3.85-1.94-20.93-5.97-7.25-1.71-11.04-2.49-14.76-5.87-1.79-1.62-2.08-2.5-3.64-3.24-4.61-2.17-9.8,1.92-16.48,5.36-13.24,6.81-15.55,2.43-31.43,10.14-4.68,2.27-6.46,3.62-12.01,5.83-5.72,2.28-7.8,2.43-13.49,5.06-9.43,4.37-10.55,7.11-10.82,8.6-.22,1.23.13,1.7-.1,4.85-.22,3.1-.74,5.16-.91,6.07-.8,4.24,1.15,8.37,4.65,15.57,2.43,5,2.83,4.75,7.18,12.74,4.27,7.86,3.79,7.93,5.76,10.72,4.47,6.34,9.71,9.9,11.63,11.12,5.86,3.73,11.13,4.95,15.67,5.97,6.63,1.47,11.76,1.46,17.19,1.42,3.95-.03,11.16-.13,20.12-2.02Z"
        />
        <path
          id="humeroulnarjointhighlight"
          class="humeroulnarengelenke2214fill"
          style={{
            fill: props.colors.humeroulnarenGelenke2214Fill,
            stroke: props.colors.humeroulnarenGelenke2214Fill,
          }}
          d="M24.22,381.84c-1.33,1.19,1.08,6.03,2.33,8.53.6,1.19,1.08,2.07,1.69,3.07,1.4,2.32,2.53,3.67,4.2,6.52,3.74,6.39,2.37,7.58,5.58,13.33,3.29,5.91,7.44,9.51,9.57,11.33,1.27,1.08,6.41,5.35,14.97,8.49,12.57,4.62,23.73,3.62,31.55,2.83,4.36-.44,18.56-2.18,34.52-10.65,4.77-2.53,10.69-5.73,17.12-11.73,4.65-4.34,14.39-13.44,17.53-28.04.52-2.45,2.21-10.73-.4-19.15-1.05-3.38-2.53-5.91-3.64-7.82-.89-1.53-2.41-3.01-5.26-6.07-.79-.85-2.12-2.24-4.18-3.57-1.43-.92-2.21-1.41-3.24-1.35-2.46.14-3.99,3.32-4.25,3.84-1.76,3.65-.43,7.44-.07,8.49,1,2.86,2.22,3.32,3.51,5.73,2,3.74,1.66,7.49,1.35,10.85-.77,8.44-8.44,29.36-28.58,36.94-7.46,2.81-13.99,2.76-17.39,2.7-8.2-.15-13.87-2.36-25.21-6.78-12.81-4.99-28.05-12.66-33.07-17.39-1.13-1.06-3.24-3.39-6.65-4.85-.86-.37-2.08-.8-3.97-1.77-1.88-.97-2.83-1.45-3.5-1.97-1.07-.83-1.62-1.53-2.76-1.72-.49-.08-1.26-.21-1.72.2Z"
        />
      </g>
    </svg>
  );
}
