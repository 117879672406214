import style from "./style.scss";

export default function LinkesKniegelenkNachRosenbergDvStehendLinks2333(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 379.18 717.75">
      <g id="underlay">
        <ellipse
          class="Gelenkspalt2333Fill"
          style={{
            fill: props.colors.Gelenkspalt2333Fill,
            stroke: props.colors.Gelenkspalt2333Fill,
            opacity: props.colors.Gelenkspalt2333Fill,
          }}
          cx="188.89"
          cy="349.21"
          rx="170.01"
          ry="52.07"
          transform="translate(-38.9 24.04) rotate(-6.6)"
        />
      </g>
      <g id="knee_tunnel" data-name="knee tunnel">
        <g id="background_layer" data-name="background layer">
          <path
            id="background_upper_leg"
            data-name="background upper leg"
            class="kniegelenk_nach_rosenberg_dv_left2333_cls3"
            d="M281.89,15.9c2.6,17-1.9,16.5,3.6,37.4,3.9,14.7,11.6,34.2,29.2,72,13.1,28.3,19.7,42.4,22.8,47.4,5.3,8.6,8.9,13.1,10.9,21.9,4.3,18.1-2.5,35.5-3.6,38.3-5,12.5-10.6,15.7-11.3,27.3-.2,3,.1,3.9,2.2,15.6,5.2,29.7,5,34.5,2.7,39.2-3.4,7.1-9.4,10.7-12.8,12.8-12.6,7.6-26,5.6-31.9,4.6-13.9-2.3-12.6-7-29.2-11.9-19.2-5.7-32.5-2.7-35.5-10-1.6-3.9,2.8-5.5.5-10.9-2.1-4.8-6.9-2.8-11.4-11-3.6-6.5-6.7-11.5-8.2-16.4-4.1-13.2,1.1-19.2-2.4-20.7-2.5-1.1-5.2.4-13.1,3.3-4.5,1.7-8.8,1.4-17.3.9s-9.9-2.5-13.7-4.6c-6.4-3.5-9.8-6.5-13.7-6.4-4,.1-6.9,5.3-9.4,9.8-2.8,5-4.9,10.8-6.1,18.4-1.8,11.4-3.4,20.7,1.8,28.2,3.5,5,7.3,5.5,9.1,10.9,1.9,5.8-.5,11.5-1.8,14.6-1.5,3.5-6.4,13.3-24.6,23.7-3.3,1.9-18,10.3-40.1,13.7-10.6,1.6-20.4,3-31.9-.9-5.6-1.9-14.5-4.9-18.2-12.8-3.2-6.6.6-9.9.9-23.7.2-11.7-2.3-21-4.6-29.2-4-14.4-6.5-13.9-8.2-24.6-1.8-11.5.9-13.8-1.8-22.8-3-9.8-7.5-11.7-10.9-19.1-3.7-8.1-3.4-17.2,1.8-35.5,12.1-42.5,33.5-73.4,36.5-77.5,10.3-14.6,13.1-15.5,24.6-32.8,13.2-19.8,20.5-35.2,22.8-40.1,11.8-25.4,7.3-28.1,13.7-35.5,12.5-14.6,32.4-8.6,93-6.4,56.3,2.1,82.9-4.5,85.6,12.8h0Z"
          />
          <path
            id="background_lower_leg"
            data-name="background lower leg"
            class="kniegelenk_nach_rosenberg_dv_left2333_cls3"
            d="M363.99,705.8c2.6-3.7,1.2-6.3-1.3-29.6-1.8-16.4-2.2-25.4-2.3-28.8-.8-27.3,1.3-48.5,4.6-73.8,4.2-32.8,9.1-58.3,9.1-58.3,2.2-11.5,3.2-21.2,3.6-28.2.9-14.3,1.3-21.4-.9-29.2-3.2-11-7.5-12-9.1-22.8-.5-3.4-.5-5.9.9-20,2.5-25.2,2.8-22.8,2.7-26.4-.5-16.4-6.9-27.5-10.9-34.6-2.2-4-5.4-8.6-10.9-11.9-2.7-1.6-6.2-3.6-10-2.7-5.1,1.2-5.1,6.7-10.9,10.9-4,2.9-7.8,3.5-15.5,4.6-3,.4-11.9,1.5-24.6,0-2.6-.3-5-.7-7.3-1.1-2.4-.5-8.6-1.7-16.4-4.3-8.3-2.8-12.5-4.2-15.5-6.4-5.8-4.2-5-6.8-13.1-14.2-5.1-4.6-7.8-7.1-11.5-6.8-5.9.5-9.5,7.9-10.1,9.1,2.8-5.9,3.1-7.3,2.8-7.5-.5-.3-3.8,3.8-7.3,8.4-2.2,2.8-3.4,4.6-6,6.6-1.9,1.5-3.5,2.3-3.9,2.5-1.8.9-5.5,2.8-9.3,2.4-2.7-.3-4.3-1.8-8.2-5.2-5.9-5.2-9.3-8.2-11.2-9.8-5.4-4.5-12.6-10.4-17.1-8.5-3.9,1.6-2.5,7.5-5.5,15-4.1,10.2-13.1,15.1-24.6,21.5-6.7,3.7-18.1,8.9-33.7,10.9-22.5,1.7-37.6,9-45.6,13.7-10.7,6.3-13.9,11.1-15.5,14.6-2.5,5.3-2,9.3-1.8,23.7.3,23.2-.8,20.9,0,25.5,2.4,13.6,9.9,22.9,18.2,32.8,11.4,13.7,20.1,19.2,28.3,26.4,13.3,11.8,20.7,25.8,35.5,53.8,6.3,11.9,29.6,55.8,35.5,94.1,1.9,12.4,4.6,24.7,6.4,37.1,2.1,13.8,1.5,18.2,3.3,20.1.4.4.9.6,1.3.8,15.8,8.5,67.4,7.3,67.4,7.3,11.6-.3,23.1-1.5,34.6-.9,16.3.9,27.8,3.3,33.5-3.5,2.3-2.7,2.7-5.8,3-8.3,1.9-16.7,2.8-25.1.9-41-.7-6.1.1-20.2,1.8-48.3,1.6-25.5,3.1-50,6.4-50.1,1.8-.1,3.2,6.9,7.3,26.4,12.6,60.3,12.4,58.4,12.8,61.1,4.7,37.3-.8,49.5,9.1,56.5,9.2,6.6,25.4,4.2,31-3.6h0Z"
          />
        </g>
        <path
          id="tibia_outlines"
          data-name="tibia outlines"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls5"
          d="M295.59,704.6c.3-11,1-32.5,1.8-60.2,1.2-40,1.3-46.4,2.7-58.3,2-17,6-40.8,15.5-67.4,7.2-20.3,11.2-31.1,21.9-41,5.9-5.5,10.6-7.7,16.4-15.5,3.2-4.3,8-11.9,11.9-29.2,3.5-15.4,7.3-32.5,2.7-53.8-3-14-12.1-40.5-24.6-41-6.6-.3-8.7,6.8-20,12.8-8,4.2-12.3,2.8-31.9,5.5-19.7,2.7-16.8,3.8-25.6,4.3-20.6,1.3-38.6-4.7-56.4-10.7-10.5-3.5-11.6-4.6-19.1-6.4-5.8-1.3-19.7-4.4-34.6-1.8-11.9,2.1-12.3,5.6-31,13.7-11.3,4.9-22.7,8.1-45.6,14.6-10.9,3.1-22.3,6.1-35.5,13.7-6.4,3.7-11.5,7.4-14.6,13.7-1.7,3.5-2.3,6.8-2.7,17.3-.5,12.9-.7,19.3,0,24.6,2.6,18.9,13.6,32.2,21.9,41.9,10.7,12.7,16.2,14.3,27.2,24.5,16.2,15.1,23.8,29.9,35.5,52.9,14.3,27.9,20.9,50.6,28.2,76.6,5.3,18.8,11.6,44.1,16.4,74.7"
        />
        <path
          id="femur_outlines"
          data-name="femur outlines"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls5"
          d="M280.99,17.4c3.8,34.8,12.1,60.8,19.1,78.4,19.7,49.3,31.9,69.3,31.9,69.3,7.5,12.3,13.7,20.6,15.5,34.6,2.6,20.4-5.4,40.5-6.4,42.8-4,9.9-5.7,8.8-7.3,15.5-3,12.9,2.6,18.6,3.6,38.3.6,12.2.9,18.3-2.7,23.7-7.9,11.6-27,11.1-34.6,10.9-11.8-.3-11.7-3.3-39.2-10.9-20.8-5.8-30.9-6.9-32.8-13.7-1.1-3.6,1.3-5,3.6-13.7,1.5-5.7,4.7-17.5.9-27.3-3-7.9-10.2-12.2-24.6-21-9.8-5.9-14.7-8.9-21-10-19.1-3.5-34.6,8.6-36.5,10-17.1,13.6-18.1,33.8-18.2,37.4-.2,4.4-.5,11,2.7,18.2,3.9,8.7,9.2,9.9,10.9,15.5,3.4,11.2-12,25.2-16.4,29.2-16.3,14.8-73.4,20.8-72,21-10.8-.9-22.4-2-27.3-10-3.3-5.3-.7-9-.9-27.3-.1-12.8-.2-19.5-2.7-28.2-2.7-9.4-5.4-10.9-8.2-21.9-2.1-8.4-1.4-10.7-2.7-18.2-3.2-18.6-11.8-21.8-13.7-35.5-1.6-12,.9-21.1,4.6-33.7,3.5-12.2,9.3-27.3,25.5-54.7,3.5-5.9,13.3-20.7,32.8-50.1,13.9-21,20-30,26.4-44.7,5.7-12.8,9-24,10.9-31.9"
        />
        <path
          id="fibula_outlines"
          data-name="fibula outlines"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls5"
          d="M364.89,704.6c-2-14.1-4.1-34.2-4.6-58.3-.2-12.1-.2-40.2,4.6-76.6,3.4-25.8,6.7-36.7,10.9-73.8,2.6-22.5,3.2-34.6-2.7-48.3-6-14-19.3-30.5-31.9-29.2-2.4.2-2.3.9-18.2,10.9-13.3,8.4-15.1,9.1-19.1,12.8-2.2,2-8.8,8-12.8,16.4-4.1,8.6-4.2,17-.9,38.3,3,19.5,6.4,34.3,8.2,41.9,6.2,26.4,8,31.4,11.9,48.3,3.9,17.1,6,29.3,9.1,47.4,3.4,19.9,7.4,45.4,10.9,75.7"
        />
        <path
          id="femur_1"
          data-name="femur 1"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls5"
          d="M208.09,244.3c0,30.4,9.6,55,21.4,55"
        />
        <path
          id="femur_2"
          data-name="femur 2"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls5"
          d="M337.49,230.7c-4.8,13.7-1.6,27.3-3.6,27.3"
        />
        <path
          id="fibula_1"
          data-name="fibula 1"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls4"
          d="M295.59,469.5c0,3.5,34.6,22.8,69.3,6.4"
        />
        <path
          id="femur_3"
          data-name="femur 3"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls4"
          d="M37.69,238.9c0-4.5,8.6-8.2,19.1-8.2"
        />
        <path
          id="femur_4"
          data-name="femur 4"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls4"
          d="M239.09,249.8c0-15.1,11.8-27.3,26.4-27.3"
        />
        <path
          id="femur_5"
          data-name="femur 5"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls4"
          d="M287.89,226.1c0,2.5,14.1,4.6,31.5,4.6"
        />
        <path
          id="femur_6"
          data-name="femur 6"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls4"
          d="M86.89,230.7c26.8-5.1,43.6,22,37.1,49.2"
        />
        <path
          id="patella"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls6"
          d="M116.99,196.9c-.1-39.8,31.9-72.9,65.6-72.9s74.7,8.5,74.7,48.3-50.4,93.9-82.5,83.4c-33.6-10.9-57.7-28.2-57.8-58.8h0Z"
        />
        <path
          id="femur_7"
          data-name="femur 7"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls5"
          d="M15.59,194.9c-1.8,10.3-3.1,23.1-2.3,37.8.4,8.1,1.3,20.6,2.6,27.1"
        />
        <polyline
          id="tibia_1"
          data-name="tibia 1"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls4"
          points="64.09 454 81.39 454 94.19 448.5 106.19 451.2 125.39 443.9 144.29 438.5 157.09 433.9 174.89 433.9 182.59 439.4 194.49 438.5 203.09 433.9 210.49 425.7 225.39 424.8 269.19 424.8 280.99 424.8 303.59 424.8 330.19 439.4"
        />
        <path
          id="tibia_2"
          data-name="tibia 2"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls7"
          d="M182.59,524.1c-6.4-3.6-20.1-15.5-20.1-15.5l-21.9-32.8-10.9-21.9v-29.2s8.2-20,8.2-20l5.5-13,18.2,8.5,13.2-14.6,12.3,6.1,23.3-6.1,24.1,6.1,4.6,15.8,13.2,5.5-13.2,26.4,8.2,15.5-12.8,7.3,4.6,16.4-9.6,14.6-10.7,13.7"
        />
        <polyline
          id="tibia_3"
          data-name="tibia 3"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls7"
          points="257.29 442.1 271.89 449.4 271.89 469.5 271.89 489.5 264.59 509.6 252.29 519.6"
        />
        <polyline
          id="tibia_4"
          data-name="tibia 4"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls7"
          points="210.49 412.9 225.39 409.3 235.49 412.9 246.39 424.8 246.39 424.8"
        />
        <path
          id="tibia_5"
          data-name="tibia 5"
          class="kniegelenk_nach_rosenberg_dv_left2333_cls5"
          d="M299.99,355.7c-16.8.9-28.5-3.1-35.4-6.2-9-4.1-16.2-7.5-23.3-14.8-5.5-5.6-9.6-12.2-16.7-12.6-3.6-.2-6.6,1.3-7.2,1.6-5.4,2.8-5.5,7.1-10.1,12-.8.8-8.4,8.9-16.4,7.9-3-.4-6.9-3.8-14.5-10.7-4.8-4.3-7.5-7.1-12.8-10-4-2.2-6.7-3.7-9.1-2.7-3.9,1.6-3.1,8.3-5.5,15-3.7,10-9.4,18.9-23.5,36.1-6.5,8-9.9,12-14.8,15.5-3.6,2.6-12.8,9-26.4,10-21.3,1.6-36.7-10.2-40.1-13"
        />
      </g>
      <g id="overlay">
        <path
          id="patella_overlay"
          data-name="patella overlay"
          class="Patella2333Fill"
          style={{
            fill: props.colors.Patella2333Fill,
            stroke: props.colors.Patella2333Fill,
            opacity: props.colors.Patella2333Fill,
          }}
          d="M116.99,196.9c-.1-39.8,31.9-72.9,65.6-72.9s74.7,8.5,74.7,48.3-50.4,93.9-82.5,83.4c-33.6-10.9-57.7-28.2-57.8-58.8h0Z"
        />
      </g>
    </svg>
  );
}
