import { MakeGetObject, MakePutObject } from "../AuthHeader";

export async function loadExaminations(
  keycloak,
  filter = undefined,
  pageSize = undefined,
  pageNr = undefined,
  mask = undefined
) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/studies${
    pageNr &&
    pageSize &&
    `?page_size=${pageSize}&page_number=${pageNr}${
      filter ? `&search=${JSON.stringify(filter)}` : ""
    }`
  }`;
  return fetch(url, MakeGetObject(keycloak, mask)).then((data) => data.json());
}

export async function loadExaminationsDet(
  keycloak,
  filter = undefined,
  pageSize = undefined,
  pageNr = undefined
) {
  const url = new URL(
    `${window.conf.SERVER_CONFIG.BASE_URL}/ris/studies`
  );

  if (filter) {
    url.searchParams.append("search", JSON.stringify(filter));
  }
  if (pageNr && pageSize) {
    url.searchParams.append("page_size", pageSize);
    url.searchParams.append("page_nr", pageNr);
  }

  return fetch(url.href, MakeGetObject(keycloak, "studies{*}")).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      throw new Error("Failed to fetch");
    }
  });
}

export async function loadExaminationSeries(
  keycloak,
  std_id,
  searchObj = undefined,
  xmask = undefined
) {
  const getSearching = () => {
    if (searchObj !== undefined) {
      return `&search=${encodeURIComponent(JSON.stringify(searchObj))}`;
    } else {
      return "";
    }
  };
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/studies/${std_id}/series${getSearching()}`;
  return fetch(url, MakeGetObject(keycloak, xmask)).then((data) => data.json());
}

export async function loadExamination(keycloak, exam_id, xmask = undefined) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/studies/${exam_id}`;
  return fetch(url, MakeGetObject(keycloak, xmask)).then((data) => data.json());
}

export async function loadExamImagePreview(keycloak, url) {
  return fetch(url, MakeGetObject(keycloak))
    .then((data) => data.blob())
    .catch((reason) => {
      throw new Error(reason);
    });
}

export async function loadImageSurfaceDose(keycloak, url, args = "") {
  const getParams = () => {
    if (args !== "") {
      return `?${new URLSearchParams(args)}`;
    } else {
      return "";
    }
  };
  return fetch(`${url}${getParams()}`, MakeGetObject(keycloak))
    .then((data) => data.json())
    .catch((reason) => {
      throw new Error(reason);
    });
}

export async function loadExamCollectiveDose(keycloak, stdId) {
  let url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/studies/${stdId}/dosecollective`;
  return fetch(url, MakeGetObject(keycloak))
    .then((data) => data.json())
    .catch((reason) => {
      throw new Error(reason);
    });
}

export async function updateStudyEntry(keycloak, studyId, updateObj) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/studies/${studyId}`;

  return fetch(url, MakePutObject(keycloak, updateObj)).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      throw new Error(resp.status);
    }
  });
}

export async function updateStudyWorklistIndications(
  keycloak,
  examId,
  updateObj
) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/studies/${examId}/worklist/indications`;
  return fetch(url, MakePutObject(keycloak, updateObj)).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      throw new Error(resp.status);
    }
  });
}
