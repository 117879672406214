import style from "./style.scss";

function Lendenwirbelkorper4LatLiegend1716(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 351.47 309.92">

<g id="Anatomy">
  <path id="LWK21" class="Lendenwirbelkorper4_lat1716_cls2" d="M32.31.17c-.7,2-3.5,11.1-4,17.4-.3,3.5.4,6.1.7,7.3.5,1.8,1.1,3.2,1.3,3.5,3,5.8,18.2,5.6,39.5,6.9,3.2.2,5.5.4,8.3.6,19.8,1.7,31,2.7,45.8,7.5,11,3.5,20,7.8,28,3.4,4.2-2.3,8.2-7,8.9-12.3.8-5.7-2.6-8.4-4.1-14.4-1.3-5-2.1-4.5.5-12.2"/>
  <path id="LWK20" class="Lendenwirbelkorper4_lat1716_cls3" d="M6.31,183.67c.2-5.3,29.6-9.2,53.3-8.9,28.9.4,61.7,7.3,61.5,13-.2,5.6-32.5,10.2-60.2,8.9-25.2-1.2-54.8-7.7-54.6-13h0Z"/>
  <path id="LWK19" class="Lendenwirbelkorper4_lat1716_cls3" d="M13.21,68.77c11.2-9,22.3,2.7,49.9,3.4,40.3,1.1,69.3-7.1,65.6-6.2s14-3.8,17.1,0c2.8,3.4-.7,9.5-6.1,26.7,0,0-3.1,9.7-5.5,19.1-5.2,20.5-4.9,37.8-4.8,41,.5,21.8,6.1,31.4,1.4,34.9s-9.3-2-21.3-6.5c-14.3-5.4-26-6.3-38.9-7.2-13.9-1-30.1-2.1-49.3,3.5-11,3.2-14.4,6.2-17.7,4-7.5-5,4.3-24.8,6.8-58.8,2.5-33-6.7-46.3,2.8-53.9h0Z"/>
  <path id="LWK18" class="Lendenwirbelkorper4_lat1716_cls2" d="M140.21,306.87c0-1.8-.2-3.7-.6-5.4-1.3-6.8-4.7-9.9-7.5-14.4-6.1-9.9-6.1-20.5-6.1-33.5.1-8.8.1-13.8,2.1-20.5,2.1-7.1,5.1-11.1,3.4-16.4-.1-.4-2-6.2-7-7.8-5.2-1.6-8.3,3.1-20,8.3-1.2.5-9.7,4.2-20.2,6-6.2,1-9.8.8-17.1.9-13.7.2-24.9,1.7-47.2,4.8-4.9.7-11.4,1.6-15.7,6.8-2.5,3-3.4,6.4-3.8,8.6.7,3.4,1.7,8.2,3.1,14.1,1.2,5,2.3,8.8,4.1,15,5.9,20.5,8.95,30.13,10.55,36.43"/>
  <path id="LWK16" class="Lendenwirbelkorper4_lat1716_cls8" d="M15.21,299.27c.2.7.3,1.3.5,1.9"/>
  <path id="LWK15" class="Lendenwirbelkorper4_lat1716_cls6" d="M115.71,31.17c-25.9-2.6-48.3-2.7-48.3-2.7-6.7,0-10.1.1-15.5-.9-13.5-2.5-21.6-8.6-23.6-6.2-1.2,1.5.6,5.1,2,7.1,3.9,5.6,10.7,6.7,20.7,8.2,6,.9,13.5,2,22.8.9,2.8-.3,6.88-1.64,10.9-.9,5.11.94,9.3.7,11.3,1.1,3.3.6,4.9,1.2,9.7,2.6,0,0,3.3,1,13.7,3.7,10.1,2.6,31.2,6.7,32.6,3,.2-.7,0-1.9-3.4-4.8-9.7-8.2-21.6-9.9-32.9-11.1h0Z"/>
  <path id="LWK13" class="Lendenwirbelkorper4_lat1716_cls2" d="M351.01,54.97c-1.7-3.8-3.6-6.6-4.7-8.2-2.6-3.6-9.2-11.9-33.7-23.7-9.8-4.7-5.6-1.9-32.8-13.2-7.87-3.27-16.06-5.61-24.14-8.25-2.12-.69-4.37-.91-6.6-.69-6.03.62-12.01,1.11-18.06,1.53-.2,2.1-.2,4.7,0,8.2,1,21.3,3.6,33.7,3.6,33.7,3.7,18,4.8,15.8,5.5,24.2.7,9.3-.5,14.7,3.2,17.8,3.4,2.8,7.5.7,14.6.9,6.4.2,10.9,2.1,23.2,8.7,30.9,16.4,34.2,20.9,45.1,21.4,4.2.2,14.2.8,20.1-5.6"/>
  <path id="LWK12" class="Lendenwirbelkorper4_lat1716_cls4" d="M132.11,151.97c3.6,3.6,9.7-1.7,19.6-.5,12.7,1.6,20.4,12.6,21.4,14.1,1.7,2.5,5.7,8.5,5,15.9-.5,5.3-3.1,7.5-5,11.9-4.3,9.6-1.1,19.7,1.8,28.7,5.1,15.9,17.3,34.7,24.5,32.7,1.5-.4,2.6-1.7,7.5-15.7,4.7-13.4,7.1-20.1,7.6-23.4,2.3-15-1.4-27.3-3.5-34.1-2.7-8.7-3.7-7.4-8.2-19.1-1.5-4-3.3-9.2-9.7-35.9-4.1-17.3-6.2-26.1-6-29.4,1-15.3,8.9-25.9,4.6-29.6-2.2-1.9-6-.7-6.8-.5-5.9,1.8-6.8,8.1-12,15.2-6,8.2-16.2,15.3-20.3,13-2.7-1.5-2.7-7-2.7-15.4,0-7.2,1.1-12.3-.1-12.8-.9-.3-2.5,2.1-3.2,3.2-7.7,12.3-11.8,35.4-14,47.7,0,0-.6,2.8-2,24.3-.2,5.2-.3,7.9,1.5,9.7h0Z"/>
  <path id="LWK11" class="Lendenwirbelkorper4_lat1716_cls2" d="M173.21,4.07c7.6,1.4,13.8,11.4,15.4,14,5.9,9.6,6.7,20.2,8.2,41.5.4,6.3.8,11.7.6,19.1-.4,12.1-2.1,14.3-1.5,21.4.2,1.9,1.9,20.7,10.5,23.7,10.2,3.6,31.9-14.7,35.1-35.5.6-3.9-2-18.1-7.3-46.4-1.5-8-2.4-12.4-2.3-18.8,0-4.8.5-8.8.1-16.8-.1-1.5-.1-2.7-.2-3.8"/>
  <path id="LWK8" class="Lendenwirbelkorper4_lat1716_cls5" d="M157.21,7.86c-1.78,6.3-1.68,8.07-1,8.4,1.8.9,11.37-10.94,19.27-12.24"/>
  <path id="LWK7" class="Lendenwirbelkorper4_lat1716_cls4" d="M221.41,123.67c8.3-.5,9.9,2.6,21.4,2,7.7-.4,9.3-1.9,16-1.5,5.5.3,9.4,1.6,16.4,4.1,16.8,6,25.2,9,28.2,10.5,8.2,4.1,14,8.2,25.5,16.4,11.6,8.2,13.1,10.4,14.1,12.3,2.5,4.7,2.6,11.4,2.7,24.6.1,10.3-.6,13.6-1.4,15.9-1.5,4.8-3.2,10.6-8.2,13.5-5.3,3-8.4-.6-16.4,2.5-4.7,1.8-4.6,3.5-9.6,5.5-5.6,2.2-10.5,2-14.1,1.8-2.4-.1-14.1-.9-26.4-9.6-10.1-7.1-8.3-10.7-15.5-13.2-10.2-3.6-22.9.6-23.7.9-6.1,2.1-9.1,4.7-11.9,3.2-3.1-1.7-3-7.1-3.2-11.4-.1-2.6-2.2-11.4-6.4-29.2-5.9-25.2-14.8-29.5-10.9-37.4,4.9-9.5,21.8-10.8,23.4-10.9h0Z"/>
  <path id="LWK6" class="Lendenwirbelkorper4_lat1716_cls5" d="M222.91,307.27c-1.9-5.4-4.8-11.5-8.7-19.9-2.2-4.8-6.8-14.4-14.6-26-11-16.4-16-18.4-21.4-31.4-3.7-8.9-5.5-13.4-6.1-18-.8-6.5,0-11.9-3.5-14.4-1.8-1.3-4.4-1.5-6.4-.9-4.1,1.3-5.4,5.9-10,17.8-5.8,15.1-7.5,17.7-10.5,19.1-4.8,2.3-8-1.1-11.4,1.4-3.3,2.4-3.1,7.3-2.2,20.2,1.5,23.7,1.9,27.1,4.6,28.2,4.9,2,9.8-7.3,15.9-5.5,5.6,1.6,7,10.1,10.5,24.1.5,1.8.9,3.6,1.3,5.3"/>
  <path id="LWK4" class="Lendenwirbelkorper4_lat1716_cls7" d="M160.41,307.17h6.5"/>
  <path id="LWK3" class="Lendenwirbelkorper4_lat1716_cls7" d="M162.21,239.47c23.7,15.5.4,31,.9,31"/>
  <path id="LWK2" class="Lendenwirbelkorper4_lat1716_cls7" d="M183.11,245.37c2.6,17.7,9.8,33.9,18.4,49.4,2,3.6,4,7.2,6,10.8"/>
  <path id="LWK1" class="Lendenwirbelkorper4_lat1716_cls1" d="M171.31,117.47c27.4,20.9.7,35.9,1.5,35.9"/>
</g>
<g id="Overlays">
  <path id="Highlight5" class="WK1716Fill" style= {{
            fill: props.colors.WK1716Fill,
            stroke: props.colors.WK1716Fill,
          }} d="M6.9,182.78c.2-5.3,29.6-9.2,53.3-8.9,28.9.4,61.7,7.3,61.5,13-.2,5.6-32.5,10.2-60.2,8.9-25.2-1.2-54.8-7.7-54.6-13h0Z"/>
  <path id="Highlight4" class="WK1716Fill" style= {{
            fill: props.colors.WK1716Fill,
            stroke: props.colors.WK1716Fill,
          }} d="M13.8,67.88c11.2-9,22.3,2.7,49.9,3.4,40.3,1.1,69.3-7.1,65.6-6.2s14-3.8,17.1,0c2.8,3.4-.7,9.5-6.1,26.7,0,0-3.1,9.7-5.5,19.1-5.2,20.5-4.9,37.8-4.8,41,.5,21.8,6.1,31.4,1.4,34.9s-9.3-2-21.3-6.5c-14.3-5.4-26-6.3-38.9-7.2-13.9-1-30.1-2.1-49.3,3.5-11,3.2-14.4,6.2-17.7,4-7.5-5,4.3-24.8,6.8-58.8,2.5-33-6.7-46.3,2.8-53.9h0Z"/>
  <path id="Highlight3" class="Dornfortsatz1716Fill" style= {{
            fill: props.colors.Dornfortsatz1716Fill,
            stroke: props.colors.Dornfortsatz1716Fill,
          }} d="M222.17,123.36c8.3-.5,9.9,2.6,21.4,2,7.7-.4,9.3-1.9,16-1.5,5.5.3,9.4,1.6,16.4,4.1,16.8,6,25.2,9,28.2,10.5,8.2,4.1,14,8.2,25.5,16.4,11.6,8.2,13.1,10.4,14.1,12.3,2.5,4.7,2.6,11.4,2.7,24.6.1,10.3-.6,13.6-1.4,15.9-1.5,4.8-3.2,10.6-8.2,13.5-5.3,3-8.4-.6-16.4,2.5-4.7,1.8-4.6,3.5-9.6,5.5-5.6,2.2-10.5,2-14.1,1.8-2.4-.1-14.1-.9-26.4-9.6-10.1-7.1-8.3-10.7-15.5-13.2-10.2-3.6-22.9.6-23.7.9-6.1,2.1-9.1,4.7-11.9,3.2-3.1-1.7-3-7.1-3.2-11.4-.1-2.6-2.2-11.4-6.4-29.2-5.9-25.2-14.8-29.5-10.9-37.4,4.9-9.5,21.8-10.8,23.4-10.9h0Z"/>
  <path id="Highlight2" class="Querfortsatz1716Fill" style= {{
            fill: props.colors.Querfortsatz1716Fill,
            stroke: props.colors.Querfortsatz1716Fill,
          }} d="M170.74,117.67c27.4,20.9.7,35.9,1.5,35.9"/>
  <path id="Highlight1" class="Angrenzende_WK1716Fill" style= {{
            fill: props.colors.Angrenzende_WK1716Fill,
            stroke: props.colors.Angrenzende_WK1716Fill,
          }} d="M32,.03c-.7,2-3.5,11.1-4,17.4-.3,3.5.4,6.1.7,7.3.5,1.8,1.1,3.2,1.3,3.5,3,5.8,18,8.68,39.3,9.98,6.38.91,8.59-2.48,11.39-2.28,19.8,1.7,26.21,3.86,41.01,8.66,11,3.5,30.53,5.92,29.89,2.04,4.2-2.3,8.2-7,8.9-12.3.8-5.7-2.6-8.4-4.1-14.4-1.3-5-2.1-4.5.5-12.2"/>
  <path id="Highlight" class="Angrenzende_WK1716Fill" style= {{
            fill: props.colors.Angrenzende_WK1716Fill,
            stroke: props.colors.Angrenzende_WK1716Fill,
          }} d="M140.98,307.19c0-1.8-.2-3.7-.6-5.4-1.3-6.8-4.7-9.9-7.5-14.4-6.1-9.9-6.1-20.5-6.1-33.5.1-8.8.1-13.8,2.1-20.5,2.1-7.1,5.1-11.1,3.4-16.4-.1-.4-2-6.2-7-7.8-5.2-1.6-8.3,3.1-20,8.3-1.2.5-9.7,4.2-20.2,6-6.2,1-9.8.8-17.1.9-13.7.2-24.9,1.7-47.2,4.8-4.9.7-11.4,1.6-15.7,6.8-2.5,3-3.4,6.4-3.8,8.6.7,3.4,1.7,8.2,3.1,14.1,1.2,5,2.3,8.8,4.1,15,5.9,20.5,8.18,29.81,9.78,36.11"/>
</g>
</svg>
  );
}

export default Lendenwirbelkorper4LatLiegend1716;
