import { MakeGetObject } from "../AuthHeader";

export async function loadFacilityInfo(facility_id, keycloak) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/pmed/facilities/${facility_id}`;
  return fetch(url, MakeGetObject(keycloak)).then((data) => data.json());
}

export async function loadRefferingPhysicianInfo(ref_doc_id, keycloak) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/pmed/refphysicians/${ref_doc_id}`;
  return fetch(url, MakeGetObject(keycloak)).then((data) => data.json());
}
