import "../styles/LeftSideView.scss";
import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const Human = map.Human;

function LeftSideView({ bpartSetter, selected }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 154.63 757.13">
      <g id="men_side" data-name="men side">
        <g id="outlines">
          <path
            id="outlines_2"
            data-name="outlines 2"
            class="lhuman-cls-1"
            d="M30.97,193.28c.1-1.3,.3-2.6,.6-3.9,5.9-22.1,17.1-38.4,32.1-50.4,1.7-9.6,1.8-17.9,0-24.6-2.1-7.1-4.4-11.7-6.9-14.4-7.1,.8-14.8-.3-23.1-3.2,1.3-5,1.3-9.6-.2-13.7l1.1-2.4c-.2-.1-1-.3-1.9-2.6,1.2-1.6,1.3-4.1,0-7.5-2.3,0-4.7-2.3-7.1-5.4,3.9-3.3,7-6.9,7.3-11.1,1.1-7.5,.9-15.9,0-24.8,1.3-12.3,9.9-19.7,21.2-25.4,11.8-4.3,23.1-4.7,33.9,0,8.8,2.9,15.7,10.1,21.6,17.3,4.2,10.3,5.8,20.1,3.8,29.2-1.1,6.4-3.1,11.2-6.6,13.3-2,12-2.1,23.7,.4,34.9,15,13.1,26.9,27,34.7,41.8,4.4,10,6.5,20.1,4.5,30.4,2.5,10.1,0,25.2-3.2,40.9,1.4,14.6,2.8,29.1,.4,37.9,4.9,15.1,3.2,28.5-.2,41.4-5.8,32.4-14.2,63.8-25.3,93.9,3.5,8.1,7.9,16.3,2.1,26.8-1.5,8.8-1.3,15.3,.8,19.3,3.8,1,6.1,1.4,5.6,3.8-.4,2.1-1.6,3.4-3.4,3.6h-3.4c-.7,1.5-.9,2.4-.9,2.4l-5.4,57.2c-.7,13.2,1.1,25.4,6.4,36.2,23.5,29.8,37.9,64.3,21,114.6-2.4,29.4-.3,45.8-.4,52.9,2.2,7.5,5.3,15.6,10.3,24.8,6.7,15.4,3.1,23.2-8.4,25.3l-41.8,2.1-49.9,2.1h-15.4s-5.8-2.4-5.8-2.4c-2.7-2.5-3.4-4.7-.2-6,16.7-2,31.5-7.8,44.5-17.5,11.2-6.6,19.8-15.7,26.6-26.6,.4-7.1,.6-14.5,0-23.8,1.4-16.5-1.6-33.5-4.1-50.4l-9.2-51.9c-2.7-10.6-5.5-20.5-9.9-24.2-2.8-1.9-19.6-38.6-21-53.1-7.4-10.5-13.3-30-19.1-50.2-4.8-10.9-5-32.3-4.1-56.1-5.5-5.3-8.5-23-11.1-43.5-5.5-22.4-6-44,0-64.3,2.3-7.4,3.2-15.2,2.6-23.4,0-6.8-2.6-20.6,1.1-38.5,.6-4.4,2.4-8.8,6-13.2-1.1-6.2-1.2-12-.6-17.6h0Z"
          />
          <path
            id="outlines_1"
            data-name="outlines 1"
            class="lhuman-cls-1"
            d="M36.47,448.38l4.5,56.8c2.2,20.6,7.9,39.1,20,53.7,4.9,35.5,9.6,74.4,15.2,88.9,2.6,16.7,4.1,32.3,0,43-8,13.6-20,22.6-35.7,28.4-10,3-21.4,7.7-35.2,9.3-6.7,3.5-6,5.8,0,7.3l19.4,1.4H62.57"
          />
        </g>
        <g id="toes">
          <path
            id="toes_4"
            data-name="toes 4"
            class="lhuman-cls-1"
            d="M33.17,754.88c-3.4-1,1.4-3.9,13.3-8.4"
          />
          <path
            id="toes_3"
            data-name="toes 3"
            class="lhuman-cls-1"
            d="M40.97,755.88c-6.6,.1-1.5-2.7,9.2-7.6"
          />
          <path
            id="toes_2"
            data-name="toes 2"
            class="lhuman-cls-1"
            d="M47.97,755.48c-4.7,.9-1.6-1.8,7.5-6"
          />
          <path
            id="toes_1"
            data-name="toes 1"
            class="lhuman-cls-1"
            d="M54.07,755.78c-3.3,0-4.3,0,5-4.6"
          />
        </g>
        <g id="ankle">
          <path
            id="ankle_2"
            data-name="ankle 2"
            class="lhuman-cls-1"
            d="M118.17,721.88c6.2,2.5,11.7,3,16.1,0,3.1-6.3,1.9-12.2,0-18"
          />
          <path
            id="anke_1"
            data-name="anke 1"
            class="lhuman-cls-1"
            d="M81.47,705.18c3.1,8.8,6.9,10.4,11,9.2"
          />
        </g>
        <g id="knee">
          <path
            id="knee_4"
            data-name="knee 4"
            class="lhuman-cls-1"
            d="M63.97,550.08c2.4-1.9,4-5,3.6-11.4"
          />
          <path
            id="knee_3"
            data-name="knee 3"
            class="lhuman-cls-1"
            d="M59.67,488.48c5.9,2.5,8.9,6.3,8.6,11.6"
          />
          <path
            id="knee_2"
            data-name="knee 2"
            class="lhuman-cls-1"
            d="M87.67,555.98c3.9-1.6,5.2-4.8,3.9-9.4"
          />
          <path
            id="knee_1"
            data-name="knee 1"
            class="lhuman-cls-1"
            d="M43.97,505.08c4.8,.4,8,3.2,9.8,8.4"
          />
        </g>
        <g id="arm">
          <path
            id="arm_1"
            data-name="arm 1"
            class="lhuman-cls-1"
            d="M111.87,427.28c-3.3,1.1,3.7,11.7,7.9,11.1-4,5.5-10.5,1.6-9.8,2.9,2.3,4.3-6.9,4.4-6.8,1.8,0-2-4.4,5.2-7.9,.9l-1.7-14.8v11.8c.5,6.1,.5,10.8,1.5,13.9,.9,3.7-1.5,2.9-4.7,0-1.4-.6-1.9-5-4.5-14.6-.5-7,.3-16.1,0-27.2,5.2-5.7,10.1-12.3,12.8-24l1.1-18c2.2-22.4,3.8-44.5,2.4-64.3-1-13.2,.4-28.6,3.8-45.9-10.4-20.4-15.5-46.1-15.4-77.1-4.9-.7-8-5.7-9.4-15"
          />
          <path
            id="finger_2"
            data-name="finger 2"
            class="lhuman-cls-1"
            d="M104.87,429.78c-.9,2.8,4,12.5,5,11.6"
          />
          <path
            id="finger_1"
            data-name="finger 1"
            class="lhuman-cls-1"
            d="M100.37,431.78c-.9,3.6,2.7,11.3,2.7,11.3"
          />
          <path
            id="shoulder"
            class="lhuman-cls-1"
            d="M81.47,148.88c1.9-12.1,9-20.2,25.9-22.8,8.4,.5,15.4,1.7,19.9,4.2"
          />
        </g>
        <g id="leg">
          <path
            id="leg_2"
            data-name="leg 2"
            class="lhuman-cls-1"
            d="M35.07,371.38c-4.4-22.5-.6-39.2,13.2-48.9"
          />
          <path
            id="leg_1"
            data-name="leg 1"
            class="lhuman-cls-1"
            d="M59.77,315.98c9.4-4.5,18.8-3.8,28.1,2"
          />
        </g>
        <g id="rib">
          <path
            id="rib_3"
            data-name="rib 3"
            class="lhuman-cls-1"
            d="M77.57,205.88c-7.9,14.7-16.7,25.4-26.6,30.7"
          />
          <path
            id="rib_2"
            data-name="rib 2"
            class="lhuman-cls-1"
            d="M81.07,227.58c-5.1,11.3-12.6,17.3-21.4,20.6"
          />
          <path
            id="rib_1"
            data-name="rib 1"
            class="lhuman-cls-1"
            d="M80.07,248.28c-2.2,5-5.5,7.8-9.6,9"
          />
        </g>
        <g id="tummy">
          <path
            id="tummy_1"
            data-name="tummy 1"
            class="lhuman-cls-1"
            d="M24.37,231.68c10.3,8.2,17.2,17.9,17.6,30.4,1.8,12-2,28.7-6.2,45.8-2.3,15.7-7.5,28.5-14.6,39.3"
          />
        </g>
        <g id="chest">
          <path
            id="chest_2"
            data-name="chest 2"
            class="lhuman-cls-1"
            d="M31.57,210.78c5.7,.9,11.9,.8,19.7-3,9.9-3,15.8-7.7,19.1-13.3"
          />
          <path
            id="chest_1"
            data-name="chest 1"
            class="lhuman-cls-1"
            d="M71.77,139.58c-2.5,.2-6.9-.1-12,2.7"
          />
        </g>
        <g id="face">
          <path
            id="face_2"
            data-name="face 2"
            class="lhuman-cls-1"
            d="M91.87,46.18c3.3-2.6,8.5-5.1,10.7,0,1.3,4.6,1.4,10.1-2.2,15.5-4.6,5.4-9.4,8.2-14.6,6.8-3.3,7.2-7.3,13.9-12.5,19.4-5,5.8-10.5,9.5-16.6,12.1"
          />
          <path
            id="face_1"
            data-name="face 1"
            class="lhuman-cls-1"
            d="M34.57,80.58c4.3,.4,8.6,0,12.8-1.2"
          />
        </g>
      </g>
      <g id="head">
        <path
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Human.id, Human.children.head),
            });
          }}
          class={`lhuman-cls-2 ${isSelected(selected, Human.children.head)}`}
          id="head_overlay"
          data-name="head overlay"
          d="M109.57,21.18C94.11,1.93,75.75-3.65,54.67,3.66c-12.48,6.05-20.81,14.07-21.8,25.61,1.16,10.65,1.1,20.19-.82,28.07-.28,3.87-6.73,7.19-6.48,7.83,1.45,3.79,7.33,5.5,7.1,5.4,.99,1.88,1.08,4.33,0,7.5,.36,1.41,.98,2.28,1.9,2.5l-1.1,2.5c1.35,5.27,1.51,9.89,.2,13.7,2.6,.33,12.96,4.78,23.16,3.13,5.5-.89,11.31,32.57,7.44,35.24-19.32,13.33,65.37-50.54,36.2-35.17,9.84-2.77,3.75,.28,3.9-11.26,.2-15.83,3.38-34.2-1.8-42.54-1.16-3.88-4.85-3.68-10.7,0,4.65-3.04,8.57-4.42,10.3-.78,2.11,4.89,1.42,9.97-1.12,15.18l3.67,6.43c10.89-9.7,11.81-28.57,4.85-45.83Z"
        />
      </g>
      <g id="thorax">
        <path
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Human.id, Human.children.mainBody),
            });
          }}
          class={`lhuman-cls-2 ${isSelected(
            selected,
            Human.children.mainBody
          )}`}
          id="thorax_overlay"
          data-name="thorax overlay"
          d="M107.17,98.58l-43.5,40.4c-27.37,25.41-35.69,49.13-32.1,71.8-3.73,4.75-6.69,11.01-7.36,21.82-.1,18.52-.19,37.31-.23,44.06l78.97,3.38,2.87-18.4c-10.58-21.65-15.95-47.38-15.25-77.86-4.82-1.14-8.47-4.97-9.4-15l.3-19.9c2.15-12.55,10.78-20.15,25.9-22.8,6.99,.04,13.62,1.45,19.9,4.2l11.18,4.1c-9.55-14-19.87-26.18-31.28-35.8Z"
        />
      </g>
      <g id="arm_overlay" data-name="arm overlay">
        <path
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Human.id, Human.children.leftArm),
            });
          }}
          class={`lhuman-cls-2 ${isSelected(selected, Human.children.leftArm)}`}
          id="arm_overlay_1"
          data-name="arm overlay 1"
          d="M139.02,135.31l-12.81-5.48c-22.49-7.74-37.41-1.66-44.63,18.49l-.36,20.95c1.47,9.48,4.62,14.24,9.35,14.51,.65,28.99,5.14,55.17,15.4,77.1-3.75,19.65-5.24,39.18-3.3,58.51l-3.92,68.33c-2.91,11.05-6.88,20.11-12.88,25.36v27.2s4.5,14.6,4.5,14.6c4.55,3.8,5.67,3.03,4.47-.82-2.25-6.81-1.82-15.79-1.27-24.88l1.7,14.8c1.93,2.56,4.43,1.17,7.02-.9,4.78,3.01,6.95,1.88,7.58-1.7,4.54,.43,7.36-.54,9.29-2.26,5.4,.05,7.14-2.31,7.32-5.69-1.71-1.46-3.56-2.24-5.52-2.45-2.51-4.7-2.82-11.71-.8-19.3,3.51-6.72,2.99-15.56-2.1-26.8,11.12-30.36,19.59-61.65,25.3-93.9,3.88-12.86,4.05-26.64,.2-41.4,2.09-8.95,1.41-22.49-.4-37.9,3.3-15.04,5.08-29.12,3.2-40.9,2.42-11.76-.81-23.6-7.35-35.47Z"
        />
      </g>
      <g id="hip">
        <path
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Human.id, Human.children.pelvis),
            });
          }}
          class={`lhuman-cls-2 ${isSelected(selected, Human.children.pelvis)}`}
          id="hip_overlay"
          data-name="hip overlay"
          d="M102.96,280.03l-78.97-3.38c-7.78,26.3-8.38,49.67-2.82,70.52,2.34,20.91,6.19,36.73,11.8,46.6l.36-22.4c-1.99-23.1,3.08-39.31,14.94-48.9l13.79-7.48c8.07-3.74,16.73-2.21,25.81,2.98l14.09,13.93,1-51.87Z"
        />
      </g>
      <g id="leg_overlay" data-name="leg overlay">
        <path
          id="leg_overlay_1"
          data-name="leg overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Human.id, Human.children.leftLeg),
            });
          }}
          class={`lhuman-cls-2 ${isSelected(selected, Human.children.leftLeg)}`}
          d="M101.96,331.91l-14.09-13.93c-8.47-5.65-17.77-6.66-28.1-2l-11.5,6.5c-13.03,9.1-15.42,27.08-13.2,48.9l-2.1,22.4c-1.57,18.94-.92,36.63,2.81,52.58,6.22,21.31,12.78,40.65,20.39,53.72,1.76,14.23,10.85,33.31,21,53.1,3.61,3.89,6.47,10.47,8.67,19.49,10.56,47.48,16.32,91.7,14.53,130.81-12.47,17.91-28.38,31.21-48.65,38.68-7,2.76-14.51,4.51-22.45,5.42-3.52,1.42-2.02,3.65,.2,6,1.96,.81,3.78,1.57,5.32,2.2,2.47,1.38,7.03,.83,11.68,.2l95.9-4.2c15.14-3.03,12.38-13.9,8.4-25.3-4.5-8.34-7.96-16.61-10.3-24.8-.96-19-1.13-37.01,.4-52.9,12.43-41.76,7.93-80.41-21-114.6-10.61-22.02-7.56-56.46-.69-94.38-3.23,2.66-6.32,3.4-9.21,1.48,1.21,3.8-.55,4.9-6.9,1.8-3.53,3.65-6.34,4.71-7.8,.9l-.84-7.35,.27,16.82c1.68,5.6,.5,6.63-4.84,1-1.65-2.81-2.97-7.61-3.99-14.17l-.03-27.79c6.67-6.29,10.86-14.64,12.91-24.76l3.2-55.81Z"
        />
      </g>
      <g id="leg_background_overlay" data-name="leg background overlay">
        <path
          id="leg_background_overlay_1"
          data-name="leg background overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Human.id, Human.children.rightLeg),
            });
          }}
          class={`lhuman-cls-2 ${isSelected(
            selected,
            Human.children.rightLeg
          )}`}
          d="M36.51,448.81c2.01,25.24,3.94,49.07,5.24,62.54,2.54,20.55,9.91,35.23,19.22,47.54,4.35,36.25,8.89,70.72,15.2,88.9,2.71,17.89,4,33.92,0,43-15.81,21.5-39.01,34.56-70.9,37.7-5.81,3.08-6.9,5.63,0,7.3l56.54,1.4c16.69-8.71,28.27-20.78,38.56-33.7,3.23-29.94-3.63-62.1-9.03-95.18-3.93-24.08-7.5-44.12-14.8-55.8-10.16-15.51-16.88-33.02-20.38-52.43-8.98-10.7-14.72-29.93-19.67-51.27Z"
        />
      </g>
    </svg>
  );
}

export default LeftSideView;
