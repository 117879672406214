import { MakePostObject } from "../AuthHeader";
import { REST_URL, MakeUrl } from "../FetchHooks/Helpers/FetchWrapper";

export default async function CopyImage({
  keycloak,
  mId,
  stdId,
  serId,
  imgId,
  patNr,
  destStdUid,
  destSerUid,
  destSerNo,
  destPatNr = null,
  tagsToReplace = "",
  srcVetPat = 0,
  destVetPat = 0,
}) {
  const url = MakeUrl(`${REST_URL}/pmed/protokol`);
  if (destPatNr === null) {
    destPatNr = patNr;
  }
  let postObj = {
    befehl: "imagecopy",
    patnr: patNr,
    destpatnr: destPatNr,
    mid: mId,
    stdid: stdId,
    serieid: serId,
    imgid: imgId,
    deststudyuid: destStdUid,
    destserieuid: destSerUid,
    destserieno: destSerNo,
    text: tagsToReplace,
    pmvetiddest: destVetPat,
    pmvetidsrc: srcVetPat,
  };

  const result = await fetch(url.href, MakePostObject(keycloak, postObj));
  if (result.ok && result.status === 200) {
    const resultData = await result.json();
    return resultData;
  } else {
    throw new Error(
      `Unable to copy image ${result.status}: ${result.statusText}`
    );
  }
}
