import { Button } from "react-bootstrap";
import styles from "./ImageSwitchingTab.module.scss";
import ContentWrapper from "../ContentWrapper/ContentWrapper";

function ImageSwitchingTab({
  title = "",
  image = <></>,
  description = "",
  leftButton = null,
  rightButton = null,
  imageAction = null,
  customActionText = (
    <>
      Show Complete <i className="fas fa ml-2 fas fa-tasks"></i>
    </>
  ),
}) {
  const noButtons =
    (leftButton === null && rightButton === null) ||
    (leftButton.disabled && rightButton.disabled);
  return (
    <ContentWrapper>
      <div className={styles.center}>{title}</div>
      {image && (
        <div className={styles.imageContainer}>
          {!noButtons && leftButton !== null && (
            <Button disabled={leftButton.disabled} onClick={leftButton.onClick}>
              {leftButton.content}
            </Button>
          )}
          {imageAction && (
            <div onClick={imageAction} className={styles.imageAction}>
              {customActionText}
            </div>
          )}
          {image}
          {!noButtons && rightButton !== null && (
            <Button
              disabled={rightButton.disabled}
              onClick={rightButton.onClick}
            >
              {rightButton.content}
            </Button>
          )}
        </div>
      )}
      <div className={`${styles.description} ${styles.center}`}>
        {description}
      </div>
    </ContentWrapper>
  );
}

export default ImageSwitchingTab;
