import { MakeGetObject } from "../AuthHeader";

export async function loadDeletedExaminations(
  keycloak,
  pageSize = undefined,
  pageNr = undefined
) {
  const deletedFilter = {
    field: "deltag",
    op: "==",
    value: "t",
  };
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/studies${
    pageNr &&
    pageSize &&
    `?page_size=${pageSize}&page_number=${pageNr}&search=${JSON.stringify(
      deletedFilter
    )}`
  }`;
  return fetch(url, MakeGetObject(keycloak)).then((data) => data.json());
}
