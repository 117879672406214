import style from "./style.scss";

export default function LinkerUnterarmMitEllenbogenVdSitzend2208(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 430.06 1208.74">
      <g id="Backround">
        <path
          id="backround1"
          class="unterarm_vd_mit_ellenbogen_rechts2207_cls4"
          d="M54.28,840.84L1.03,1158.03s-.01.06,0,.09c8.91,23.78,62.15,35.55,77.93,13.84,4.66-6.41,68.11-343.91,67.69-358.57-.69-24.37,2.73-41.77,9.57-76.56,8.51-43.29,26.86-81.87,31.44-80.66,2.82.75,1.1,16.73-1.37,39.65-2.54,23.6-4.11,23.82-5.47,38.28-3.06,32.63-4.64,39.57-6.36,77.85v.02c-5.45,34.43-61.04,361.96-57.26,367.99,14.36,22.94,85.25,43.22,101.35,15,3.5-6.13,63.95-378.82,63.44-399.34-.79-31.72.89-25.27,2.29-46.69,1.04-16.4-2.64-25.91.45-51.74,2.06-17.26,1.25-21.19,2.73-42.38,1.88-26.9,5.06-47.48,6.84-58.78,4.49-28.6,7.92-31.96,10.94-56.05.69-5.5,1.83-11.87,4.1-24.61,2.97-16.62,4.04-20.38,6.84-24.61,2.19-3.32,3.98-4.15,17.77-15.04,15.5-12.24,17.04-11.41,20.51-16.41,6.38-9.19,9.99-24.71,4.1-35.54-2.86-5.26-6.63-6.97-6.84-12.3-.2-5.2,3.24-7.18,6.84-13.67,7.41-13.39,1.13-20,8.2-28.71,2.64-3.25,7.23-5.36,16.41-9.57,12.19-5.6,16.1-5.31,21.87-8.2,14.43-7.24,19.91-24.05,21.87-30.08,6.2-19.04,2.68-40.9-8.2-56.05-4.42-6.16-13.89-13.22-32.81-27.34-10.47-7.81-15.15-10.72-19.14-13.67-18.22-13.47-28.24-32.45-38.28-56.05-12.56-29.5-27.12-60.48-34.18-105.27-6.44-40.87-7.75-56.9-7.75-56.9-.78-8.13-2.45.37-3.82-5.82-.02-.1-.12-.16-.21-.14-36.91,8.98-73.53.78-111.29,5.02-.08.01-.15.08-.15.16-.66,6.78-1.42,14.87-1.2,28.97.4,25.27,3.26,25.2,1.37,39.65-.64,4.9-1.8,11.23-10.94,39.65-5.74,17.86-7.72,22.95-10.94,32.81-7.4,22.71-6.92,26.5-19.14,71.09-8.38,30.58-11.15,38.07-19.14,45.11-8.5,7.49-14.81,7.34-20.51,16.41-5.19,8.26-2.05,11.74-5.47,34.18-2.85,18.72-5.83,21.55-5.19,34.09.49,9.54,2.67,16.82,3.83,20.6,2.24,7.3,5.4,17.63,13.67,27.34,7.91,9.29,13.35,9.64,13.67,15.04.36,6.08-6.42,7.7-13.67,19.14-3.56,5.62-9.01,14.22-7.57,22.86,1.7,10.2,11.56,11.74,19.88,22.26,3.16,4,7.43,12.33,9.57,41.01,2.15,28.72-2.61,51.32-5.47,64.25-5.73,25.92-8.85,36.23-24.61,92.96-15.39,55.42-16.7,62.64-17.77,69.72-3.63,24.04-2.26,32.24-8.27,55.34-5.48,21.04-11.54,35.92-8.91,43.1v.08s.01,0,.01,0Z"
        />
      </g>
      <g id="Forearmvd">
        <g id="Outlines">
          <path
            id="outline4"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M223.2,277.5c21.81,3.72,43.81-3.28,65.81-7.28,17-3,33-4,49,3,17,8,33,23,35,43-3,16,0,32-3,49-2,8-11,2-16,2-9,1-7,8-8,13-3,14,1,27-4,40-2,5-7,9-13,10-22,3-40-11-55-26-5-4-12-7-18-4-18,10-39,11-59,11-19,0-36,9-55,9-7-1-14-2-21-5-2-1-5-2-7-3-13-8-22-20-28-34-3-9-7-17-7-27-1-15,4-28,6-43,0-6,1-12,1-18,0-7,3-13,8-16,9-7,19-12,24-22,9-21,14-42,19-65,5-17,8-33,14-50,7-18,12-36,18-54,4-16,2.45-54.88,1.45-71.26"
          />
          <path
            id="outline3"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M286.53,5.96s.44,16.03,6.88,56.9c7.06,44.79,21.62,75.77,34.18,105.27,10.04,23.6,20.06,42.58,38.28,56.05,3.99,2.95,8.67,5.86,19.14,13.67,18.92,14.12,28.39,21.18,32.81,27.34,10.88,15.15,14.4,37.01,8.2,56.05-1.96,6.03-7.44,22.84-21.87,30.08-5.77,2.89-9.68,2.6-21.87,8.2-9.18,4.21-13.77,6.32-16.41,9.57-7.07,8.71-.79,15.32-8.2,28.71-3.6,6.49-7.04,8.47-6.84,13.67.21,5.33,3.98,7.04,6.84,12.3,5.89,10.83,2.28,26.35-4.1,35.54-3.47,5-5.01,4.17-20.51,16.41-13.79,10.89-15.58,11.72-17.77,15.04-2.8,4.23-3.87,7.99-6.84,24.61-2.27,12.74-3.41,19.11-4.1,24.61-3.02,24.09-6.45,27.45-10.94,56.05-1.78,11.3-4.96,31.88-6.84,58.78-1.48,21.19-.67,25.12-2.73,42.38-3.09,25.83-.12,26.37-1.16,42.77,5.68,24.76-2.29,32.59-1.58,55.66.51,20.52-59.07,393.21-62.57,399.34"
          />
          <path
            id="outline2"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M117.18,1179.96c-3.78-6.03,51.21-332.35,57.27-368-1.08-33.4,2.43-45.23,5.49-77.86,1.36-14.46,2.93-14.68,5.47-38.28,2.47-22.92,4.19-38.9,1.37-39.65-4.58-1.21-22.93,37.37-31.44,80.66-6.84,34.79-10.26,52.19-9.57,76.56.42,14.69-62.16,352.15-66.82,358.57"
          />
          <path
            id="outline1"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M1.01,1158.08c-.75-2.93,54.85-331.9,61.3-360.45,2.1-9.29,3.14-13.94,3.5-16.06,2.18-13.05,1.8-19.59,4.77-39.28,1.07-7.08,2.38-14.3,17.77-69.72,15.76-56.73,18.88-67.04,24.61-92.96,2.86-12.93,7.62-35.53,5.47-64.25-2.14-28.68-6.41-37.01-9.57-41.01-8.32-10.52-18.18-12.06-19.88-22.26-1.44-8.64,4.01-17.24,7.57-22.86"
          />
        </g>
        <g id="Overview">
          <path
            id="partofancon9"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M97.44,429.24c8.01-3.92,14.78-5.09,19.14-5.47,8.44-.75,15.46.76,24.61,2.73,11.72,2.52,11.97,3.98,17.77,4.1,10.19.2,11.59-4.72,21.87-5.47,2.51-.18,15.6-1.13,23.24,6.84,2.58,2.7,3.19,5.01,6.33,24.23,3.46,21.23,3.64,24.07,1.87,27.72-4.1,8.42-11.13,5.77-17.77,13.67-12.25,14.58-4.41,42.79,1.37,56.05,3.39,7.78,5.94,10.12,9.57,20.51,2.36,6.75,5.32,15.01,5.47,25.97.19,14.22-4.24,25.19-6.84,31.44-.82,1.97-10.93,25.99-16.41,24.61-1.58-.4-2.92-2.95-2.73-51.95.04-11.39.15-21.67.09-39.61-.02-6.89-.05-8.71-.09-16.44-.17-30.21-.25-45.31,0-47.85,2.35-23.57,11.3-41.63,16.41-51.95,3.02-6.1,2.28-3.7,17.77-31.44,11.79-21.12,13.99-25.68,15.04-32.81,1.02-6.93-.22-8.26-4.1-32.81-1.25-7.93-3.99-25.38-5.47-42.38-1.18-13.44-1.37-24.42-1.37-31.44"
          />
          <line
            id="ulnar2"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            x1="143.45"
            y1="1186.65"
            x2="205.44"
            y2="682.15"
          />
          <line
            id="radius6"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            x1="124.78"
            y1="582.35"
            x2="22.67"
            y2="1158.08"
          />
          <path
            id="radius5"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M124.77,582.35c5.56-3.27,16.48-46.53-7.59-86.01"
          />
          <path
            id="radius4"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M175.42,656.17c-21.55,10.12-101.48,432.21-119.87,508.79"
          />
          <path
            id="ulnar1"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M310.7,486.66c-20.7-3.75-83.69,514.3-110.73,708.3"
          />
          <path
            id="partofancon8"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M97.44,317.14c-12.76,10.31-12.36,41.95,13.67,64.25"
          />
          <path
            id="partofancon7"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M124.78,285.69c17.38,0,15.72-20.5,31.44-41.01"
          />
          <path
            id="partofancon6"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M178.1,295.26c15.47-5.23,40.56-50.31,56.39-88.86"
          />
          <path
            id="partofancon5"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M178.09,159.91c16.09-28.9,43.29-126.39,16.92-147.95"
          />
          <path
            id="ulnar7"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M310.71,295.26c-15.5,0-28.03,14.67-28.03,32.81"
          />
          <path
            id="partofancon4"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M261.01,5.96c2.97,38.93,1.53,21.7,5.95,45.97,10.91,59.88,22.46,89.46,30.08,106.63,13.15,29.63,24.26,43.97,35.54,54.68,8.24,7.82,19.28,18.3,36.91,25.97,20.79,9.04,31.43,5.78,39.63,16.43,7.07,9.18,8.44,23.65,2.75,35.52-6.87,14.34-21.01,18.52-23.24,19.14"
          />
          <path
            id="partofancon3"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M240.99,239.22c-4.92,2.22-19.31,9.49-27.34,25.97-1,2.05-13.4,28.39,0,45.11,13.47,16.8,42.59,10.9,57.42,4.1,5.99-2.75,24.23-11.01,28.71-30.08,2.25-9.62,1.72-24.07-6.84-32.81-6.48-6.6-12.6-4.9-20.51-12.3-6.89-6.45-9.67-14.7-10.94-20.51"
          />
          <path
            id="partofancon2"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M202.7,410.64c24.96-40.59,24.31-102.75-12.46-124.95"
          />
          <path
            id="radius3"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M204.07,431.96c-63.57,23.24-106.63-1.22-106.63-2.73"
          />
          <path
            id="ulnar6"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M358.54,423.76c-1.65,3.77-5.88,12.03-15.04,17.77-10.39,6.52-20.87,5.87-27.34,5.47-3.61-.22-14.86-1.05-25.97-8.2-8.67-5.58-7.67-8.84-15.04-12.3-9.91-4.66-19.91-2.61-25.97-1.37-7.98,1.63-19.55,4-23.24,12.3-2.54,5.71,1.46,7.87,0,19.14-1.19,9.21-4.29,11.03-4.1,17.77.2,6.86,3.61,12.47,6.84,16.41"
          />
          <path
            id="ulnar5"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M327.11,466.86c17.38,0,31.44-19.27,31.44-43.09"
          />
          <path
            id="ulnar4"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            d="M320.08,486.59c4.02-33.22,15.1-84.74,38.47-88.8"
          />
          <line
            id="ulnar3"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            x1="344.88"
            y1="384.12"
            x2="342.83"
            y2="349.52"
          />
          <line
            id="partofancon1"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            x1="85.13"
            y1="313.03"
            x2="111.11"
            y2="295.26"
          />
          <line
            id="radius2"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            x1="178.09"
            y1="642.5"
            x2="172.03"
            y2="616.53"
          />
          <line
            id="radius1"
            class="unterarm_vd_mit_ellenbogen_rechts2207_cls1"
            x1="165.96"
            y1="590.55"
            x2="184.88"
            y2="539.35"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="jointlinehighlight"
          class="gelenkspalte2208fill"
          style={{
            fill: props.colors.Gelenkspalte2208Fill,
            stroke: props.colors.Gelenkspalte2208Fill,
          }}
          d="M357.67,397.8c.71.77-8.42,7.31-7.03,14.07.68,3.29,4.04,7.56,4.64,8.32,1.89,2.4,3.35,3.5,3.25,5.34-.09,1.59-1.29,2.52-3.3,4.41-4.93,4.64-3.6,4.62-7.55,8.09-1.56,1.37-4.72,4.14-8.9,6.2-6.29,3.1-11.96,3.14-17.8,3.17-10.11.06-17.65-2.49-20.39-3.51-7.16-2.66-11.9-6.09-12.93-6.85-5.89-4.34-5.11-5.83-9.36-8.76-8.65-5.95-19.35-4.96-24.09-4.52-3.92.36-7.57,1.38-14.89,3.4-9.39,2.6-9.42,3.44-14.32,4.21-9.04,1.42-16.39-.29-18.29-.76-5.52-1.38-5.05-2.44-12.13-4.25-8.36-2.13-12.62-1.58-13.75-1.42-2.39.35-4.41,1-7.85,2.11-4.83,1.56-5.93,2.39-8.94,2.94-3,.55-5.51.38-8.49,0-10.15-1.31-13.02-3.49-22.85-5.26-8.99-1.62-14.3-1.18-15.98-1.01-2.74.28-5.39.55-8.7,1.62-.53.17-2.61.86-6.07,2.02-3.3,1.11-3.55,1.2-4.04,1.13-3.21-.44-6.28-6.39-5.67-11.65.74-6.42,7.1-12.96,12.74-11.73,3.25.7,5.18,3.74,5.93,5.12,11.89,7.57,22.05,9.29,28.04,9.71,9.44.65,17.95-1.28,26.24-3.17,11.46-2.61,13.58-4.69,23.11-5.46,4.62-.37,4.15.12,16.97-.19,8.11-.19,12.27-.3,17.28-.89,5.44-.64,10.42-1.22,16.72-3.24,3.32-1.06,6.82-2.46,12.13-4.58,6.93-2.77,8.58-3.67,11.6-3.51,3.81.21,6.54,1.96,9.71,4.04,5.61,3.69,6.81,6.54,11.6,11.06,5.11,4.82,9.89,7.37,13.75,9.44,5.31,2.83,11.92,6.28,21.04,6.98,5.69.44,8.54.66,11.56-.84,7.5-3.73,5.44-12.1,14.33-22.59,5.74-6.78,12.14-9.82,12.69-9.22Z"
        />
        <path
          id="radialheadhighlight"
          class="radiushead2208fill"
          style={{
            fill: props.colors.RadiusHead2208Fill,
            stroke: props.colors.RadiusHead2208Fill,
          }}
          d="M212.84,478.75c-3.38,6.93-17.2,6.79-21.57,6.74-5.29-.05-9.02-.89-11.33-1.35-13.55-2.66-17.63.23-49.85-.26-3.67-.06-15.88-.36-19.25-6.14-.36-.62-1.02-1.98-2.36-3.84-2.21-3.07-4.61-5.16-6.34-6.47-1.82-1.12-4.34-2.87-7-5.39-2.41-2.29-3.71-3.52-4.73-5.33-4.43-7.83.82-18.41,2.02-20.83.92-1.85,1.67-3.37,3.24-5.06,2.81-3.03,6.6-4.81,13.75-6.07,6.14-1.08,14.35-2.53,24.83-.24,9.94,2.17,18.56,5.1,18.56,5.1,2.47.84,5.31,1.88,9,1.41,1.16-.15,2.28-.43,8.49-2.83,6.07-2.35,5.53-2.28,6.67-2.63,6.34-1.95,12.1-.68,14.32-.16,3.23.75,8.17,1.9,12.01,5.83,2.83,2.9,4.05,8.84,6.37,20.6.25,1.25.99,6.23,2.49,16.2,1.25,8.35,1.36,9.31.67,10.72Z"
        />
      </g>
    </svg>
  );
}
