import style from "./style.scss";

function OberesSprunggelenkLatLinks(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 378.7 486.2"
    >
      <g id="upper_ankle">
        <g id="background_layer">
          <g id="background">
            <path
              id="background_1"
              class="oberes_sprunggelenk_lat_links_st0"
              d="M353.3,277.1c-30.5-4.8-56.6-11.2-55.9-26.9c0.1-3.4-1.1-6.6-3.1-9.3
				c-18-23.9-39.7-39.3-67.8-39.6c-33.4,3.5-58.9,13.9-68.2,38.6c-5.1,12-8.1,23.8-3.4,34.7c-4.9,9-1.6,13.6,10.2,13.6
				c26-16,43.6-12.1,56.2,3.4c-3.2,21.4,1.1,27.2,11.9,19.3c13.6,9.9,22.8,22.2,29.5,35.8l46.7,9c4,0.8,8.2,0,11.6-2.3
				c27.7-18.6,38.9-42.3,38-69.5C359.2,280.5,356.7,277.6,353.3,277.1L353.3,277.1L353.3,277.1z"
            />
            <path
              id="background_2"
              class="oberes_sprunggelenk_lat_links_st0"
              d="M232.2,1.8c-0.6,84.7,3.1,155.4,19.9,183.5c13,19.8,13.4,41.6-1.7,65.9
				c-7.3,13.2-14.7,21.9-22.2,23.3c-5.6,9.8-11.9,10.1-18.8-0.6c-26.1-13-33.6-32.4-34.7-54c8.7-59.5,11.3-134.3,11.9-214.2"
            />
            <path
              id="background3"
              class="oberes_sprunggelenk_lat_links_st0"
              d="M353.8,455.8c-21.7,8.3-42.7,10.4-62.5,2.3c-11.3-14.9-12.5-28.6-1.1-40.9
				c8.5-10.7,11.6-25.2,11.4-42c6-7.4,25.1-6.5,55.1,1.1"
            />
            <path
              id="background_4"
              class="oberes_sprunggelenk_lat_links_st0"
              d="M371.4,376.8c-19.1,16.5-37.8,14-57.4-2.8c-7.1-4-7.3-8.6-4-13.6
				c40.1-14.7,55.5-39,54-69.9c5.8-2,10-0.2,13.6,3.4"
            />
            <path
              id="background_5"
              class="oberes_sprunggelenk_lat_links_st0"
              d="M377.7,380.2c-7.7,10.3-19.1,14.4-34.1,12.5c-9.1,0.8-13,5-8.5,14.8
				c-3.5,15.7,14.8,30,39.8,43.8"
            />
            <path
              id="background_6"
              class="oberes_sprunggelenk_lat_links_st0"
              d="M368,429.1c-8-0.2-15.5,0.8-21.6,4.5c-15.9-3-22.8,1.8-21.6,13.6
				c-4.9,10.5,1,19.9,17,28.4c11,7.4,23.6,9.5,36.9,9.7"
            />
          </g>
        </g>
        <g id="upper_ankle_joint">
          <g id="heel">
            <path
              id="heel_1"
              class="oberes_sprunggelenk_lat_links_st1"
              d="M27.1,290.9c4.1-34.3,15.4-31.3,28.4-19.3c18.7,8.7,39,7.9,60.2,0.6c44.4-12.1,76,0.1,100,26.7
				c12.7,21.8,26.2,38.7,43.2,35.2c18.5-9.3,30.7-10.5,37.5-4.5c24.2,1.6,28.5,8.5,9.1,21.6c-9.5,8-13.7,17.5-9.7,29
				c-0.7,26.7-8.2,38.6-22.7,35.2c-9.5-7.1-19.8-2.3-30.7,11.9c-19.1,1.3-38.4,0.4-57.9-4c-14.3-5.5-27.8-8.8-38.6-4
				c-16.8,4.1-32.6,11.9-47.2,24.4C60.6,441.4,36.2,415,26.5,363C22.2,344.6,23.2,319.7,27.1,290.9L27.1,290.9L27.1,290.9z"
            />
            <path
              id="heel_2"
              class="oberes_sprunggelenk_lat_links_st1"
              d="M119.2,415.3c13.3-1.8,26-1.7,38.1,1.1"
            />
          </g>
          <g id="tarsus">
            <path
              id="tarsus_1"
              class="oberes_sprunggelenk_lat_links_st2"
              d="M353.3,277c-30.5-4.8-56.6-11.2-55.9-26.9c0.1-3.4-1.1-6.6-3.1-9.3
				c-18-23.9-39.7-39.3-67.8-39.6c-33.4,3.5-58.9,13.9-68.2,38.6c-5.1,12-8.1,23.8-3.4,34.7c-4.9,9-1.6,13.6,10.2,13.6
				c26-16,43.6-12.1,56.2,3.4c-3.2,21.4,1.1,27.2,11.9,19.3c13.6,9.9,22.8,22.2,29.5,35.8l46.7,9c4,0.8,8.2,0,11.6-2.3
				c27.7-18.6,38.9-42.3,38-69.5C359.2,280.4,356.7,277.5,353.3,277L353.3,277L353.3,277z"
            />
            <path
              id="tarsus_2"
              class="oberes_sprunggelenk_lat_links_st2"
              d="M353.8,455.7c-21.7,8.3-42.7,10.4-62.5,2.3c-11.3-14.9-12.5-28.6-1.1-40.9
				c8.5-10.7,11.6-25.2,11.4-42c6-7.4,25.1-6.5,55.1,1.1"
            />
            <path
              id="tarsus_3"
              class="oberes_sprunggelenk_lat_links_st2"
              d="M371.4,376.7c-19.1,16.5-37.8,14-57.4-2.8c-7.1-4-7.3-8.6-4-13.6c40.1-14.7,55.5-39,54-69.9
				c5.8-2,10-0.2,13.6,3.4"
            />
            <path
              id="tarsus_4"
              class="oberes_sprunggelenk_lat_links_st2"
              d="M377.7,380.1c-7.7,10.3-19.1,14.4-34.1,12.5c-9.1,0.8-13,5-8.5,14.8
				c-3.5,15.7,14.8,30,39.8,43.8"
            />
            <path
              id="tarsus_5"
              class="oberes_sprunggelenk_lat_links_st2"
              d="M368,429c-8-0.2-15.5,0.8-21.6,4.5c-15.9-3-22.8,1.8-21.6,13.6c-4.9,10.5,1,19.9,17,28.4
				c11,7.4,23.6,9.5,36.9,9.7"
            />
            <path
              id="tarsus_6"
              class="oberes_sprunggelenk_lat_links_st2"
              d="M275.4,285.8c-12.9,10.6-26.8,19.1-42,25"
            />
          </g>
          <path
            id="shin"
            class="oberes_sprunggelenk_lat_links_st1"
            d="M284.5,0c-1.7,45.1-1.2,89.7,13.6,131.2c9.5,23.2,9.7,46-20.5,67.6c-1.3,7.6-3.7,10.9-8.5,5.7
			c-33-13.7-64.7-12-94.9,4.6c-14.6,3.2-19.6-11.7-19.9-35.8c8.2-28.5,16.8-52.7,25.6-74.4c9.4-34.8,16-67.9,17.6-98.3"
          />
          <path
            id="fibula"
            class="oberes_sprunggelenk_lat_links_st2"
            d="M232.2,1.7c-0.6,84.7,3.1,155.4,19.9,183.5c13,19.8,13.4,41.6-1.7,65.9
			c-7.3,13.2-14.7,21.9-22.2,23.3c-5.6,9.8-11.9,10.1-18.8-0.6c-26.1-13-33.6-32.4-34.7-54c8.7-59.5,11.3-134.3,11.9-214.2"
          />
          <path
            id="outline"
            class="oberes_sprunggelenk_lat_links_st3"
            d="M98.4,54.1C80,126.5,60.3,194.7,33.6,240.5c-2.5,4.3-5.3,8.3-8.2,12.4
			c-15.4,21.5-23.8,51.8-25,91.5c2.4,49,22.3,82.5,54.2,105.3c15.6,11.2,34.3,17.2,53.5,17.6c31.9,0.7,61.7-6.3,90.3-18.3"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="shin_overlay"
          class="tibiaFill"
          style={{
            fill: props.colors.tibiaFill,
            stroke: props.colors.tibiaFill,
            opacity: props.colors.tibiaFill,
          }}
          d="M284.5,0c-1.7,45.1-1.2,89.7,13.6,131.2c9.5,23.2,9.7,46-20.5,67.6
		c-1.3,7.6-3.7,10.9-8.5,5.7c-33-13.7-64.7-12-94.9,4.6c-14.6,3.2-19.6-11.7-19.9-35.8c8.2-28.5,16.8-52.7,25.6-74.4
		c9.4-34.8,16-67.9,17.6-98.3"
        />
        <path
          id="fibula_overlay"
          class="fibulaFill"
          style={{
            fill: props.colors.fibulaFill,
            stroke: props.colors.fibulaFill,
            opacity: props.colors.fibulaFill,
          }}
          d="M232.2,1.7c-0.6,84.7,3.1,155.4,19.9,183.5c13,19.8,13.4,41.6-1.7,65.9
		c-7.3,13.2-14.7,21.9-22.2,23.3c-5.6,9.8-11.9,10.1-18.8-0.6c-26.1-13-33.6-32.4-34.7-54c8.7-59.5,11.3-134.3,11.9-214.2"
        />
        <path
          class="TalusrolleFill"
          style={{
            fill: props.colors.TalusrolleFill,
            stroke: props.colors.TalusrolleFill,
            opacity: props.colors.TalusrolleFill,
          }}
          d="M296.1,243.9c-20.9-27-43.5-44.2-69.6-42.6c-24.1,2.4-44.9,8.6-58.5,22.8c-5.2,4.7-8.2,10-9.7,15.8
		c19.4-15.4,40.9-25.2,66.1-25c26.9-1.9,50.4,14.1,73,35.3L296.1,243.9z"
        />
      </g>
    </svg>
  );
}

export default OberesSprunggelenkLatLinks;
