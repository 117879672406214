import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const Chest = map.Chest;

function FrontView({ bpartSetter, selected }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.78 293.11">
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="rip_1_r" data-name="rip 1 r">
          <path
            id="rip_1_r-2"
            data-name="rip 1 r"
            class="stroke clear-fill"
            d="M121.23,43.51c-.62-.51-1.28-.95-2.34-.74-2.87,.14-6.85,4.72-9.42,8.18-4.29,2.11-7.03,5.4-8.72,9.51,2.48,1.18,5.63,1.39,9.14,1.05,.06-2.02,1.08-4.2,3.8-6.66,2.35-2.45,5.51-3.55,9.52-3.23,3.79-.29,5.81,.8,9.09,2.14,1.97-1.26,1.74-3.45,.19-6.18"
          />
          <path
            id="rip_1_r-3"
            data-name="rip 1 r"
            class="stroke clear-fill"
            d="M98.39,70.23c-.22,3.56,.76,6.47,3.49,8.45,4.71,2.87,9.53,3.94,14.48,2.95-.99-1.29,.11-4.83,2.25-8.58-3.31,.84-6.16,0-8.54-2.53"
          />
          <path
            id="rip_1_r-4"
            data-name="rip 1 r"
            class="stroke clear-fill"
            d="M119.73,72.91l5.77,1.41c1.28,2.78,.16,7.4,0,7.67-2.54,1.3-5.33,1.12-8.27,0-.81-3.91-.45-7.19,2.5-9.08Z"
          />
        </g>
        <g id="rip_2_r" data-name="rip 2 r">
          <path
            id="rip_2_r-2"
            data-name="rip 2 r"
            class="stroke clear-fill"
            d="M132.25,59.03c-.69-.09-2.11-1.1-4.45-2.47-3.64-2.08-7.36-2.63-10.97-2.45-1-.04-1.96,.07-2.84,.44"
          />
          <path
            id="rip_2_r-3"
            data-name="rip 2 r"
            class="stroke clear-fill"
            d="M118.61,60.72c-.4-.32-3.28-1.13-3.94-1.38-1.24-.56-2.75,.12-4.61,.88"
          />
          <path
            id="rip_2_r-4"
            data-name="rip 2 r"
            class="stroke clear-fill"
            d="M102.59,56.96c-2.58,.34-5.7,1.27-7.39,3.52"
          />
          <path
            id="rip_2_r-5"
            data-name="rip 2 r"
            class="stroke clear-fill"
            d="M87.81,68.27l.53-.7c-5.32,5.57-8.33,11.19-8.69,16.88,.04,6.02,3.44,9.09,8.69,10.55,9.54,5.23,19.17,8.09,28.88,8.72-.46-2.69,0-5.08,1.38-7.17-9.41-2.06-18.79-4.12-22.78-7.03-4.38-2.72-7.01-6.09-7.45-10.27,.53-4.22,2.84-7.15,6.05-9.42"
          />
          <path
            id="rip2_r"
            data-name="rip2 r"
            class="stroke clear-fill"
            d="M132.95,103.96c-.22,.24-.45,.48-.7,.73-4.38-1.26-8.62-1.37-12.66,0-3.52-2.94-2.8-5.85-.55-8.75,4.9,1.55,9.85,1.83,14.87,.17,1.68,2.62,1.35,5.23-.97,7.85h0Z"
          />
        </g>
        <g id="rip_3_r" data-name="rip 3 r">
          <path
            id="rip_3_r-2"
            data-name="rip 3 r"
            class="stroke"
            d="M99.13,75.28c-4.63,1.13-7.98,2.49-10.76,3.95"
          />
          <path
            id="rip_3_r-3"
            data-name="rip 3 r"
            class="stroke"
            d="M103.7,79.17c-4.57,.83-8.88,2.85-12.98,5.87"
          />
          <path
            id="rip_3_r-4"
            data-name="rip 3 r"
            class="stroke clear-fill"
            d="M79.66,86.66c-5.11,2.81-7.06,7.57-6.89,13.65,1.23,5.94,4.7,10.62,10.12,14.19,9.47,4.96,18.82,8.85,27.94,10.81,3.19-1.16,4.23-3.68,3.51-7.31-12.45-3.55-23.88-7.74-31.63-14.3-5.37-5.88-5.58-10.59-2.08-14.46"
          />
          <path
            id="rip_3_r-5"
            data-name="rip 3 r"
            class="stroke clear-fill"
            d="M132.25,116.65c2.1,2.38,1.79,5.01,0,7.8-5.39,1.13-13.14,1.12-21.42,.86,2.72-1.66,4.51-4.05,4.5-7.88,5.12-.73,10.69-1.03,16.92-.78h0Z"
          />
        </g>
        <g id="rip_4_r" data-name="rip 4 r">
          <path
            id="rip_4_r-2"
            data-name="rip 4 r"
            class="stroke clear-fill"
            d="M130.38,85.03c-10.99-.44-22.05,1.32-33.17,5.33"
          />
          <path
            id="rip_4_r-3"
            data-name="rip 4 r"
            class="stroke clear-fill"
            d="M133.92,93.24c-8.25-2.64-15.32-1.59-21.62,1.89"
          />
          <path
            id="rip_4_r-4"
            data-name="rip 4 r"
            class="stroke clear-fill"
            d="M98.36,99.1c-7.23,1.23-12.74,2.72-15.65,4.61"
          />
          <path
            id="rip_4_r-5"
            data-name="rip 4 r"
            class="stroke clear-fill"
            d="M87.53,94.73c-2.15,.28-5.3,.57-7.87,3.2"
          />
          <path
            id="rip_4_r-6"
            data-name="rip 4 r"
            class="stroke clear-fill"
            d="M72.75,101.31c-5.85,8.52-3.17,15.92,.95,23.14,10.95,9.19,21.89,14.5,32.81,15.3-1.17,3.71-1.5,6.66-1.12,9-11.18-2.11-22.08-7.11-32.64-15.56-4.46-5.4-7.85-11.2-8.05-18.68-.99-6.48,3.07-11.25,8.05-13.19h0Z"
          />
          <line
            id="rip"
            class="stroke clear-fill"
            x1="76.14"
            y1="108.13"
            x2="69.47"
            y2="113.13"
          />
          <path
            id="rip_4_r-7"
            data-name="rip 4 r"
            class="stroke clear-fill"
            d="M106.52,139.75c7.64,.29,14.57-1.73,22.88-5.06,1.29,2.37,2.37,5.25,.99,8.62-7.61,3.27-16.05,4.87-24.99,5.44-.3-2.24-.13-5.2,1.12-9Z"
          />
        </g>
        <g id="rip_5_r" data-name="rip 5 r">
          <path
            id="rip_5_r-2"
            data-name="rip 5 r"
            class="stroke clear-fill"
            d="M95.2,109.94c8.83-3.48,22.87-4.55,38.51-4.89,.69,2.85-.48,4.38-1.26,6.18l-.2,.96c-10.35-.06-20.04,.32-25.73,3.43"
          />
          <path
            id="rip_5_r-3"
            data-name="rip 5 r"
            class="stroke clear-fill"
            d="M82.89,114.5c-3.48,.41-7.59,2.38-11.82,4.67"
          />
          <path
            id="rip_5_r-4"
            data-name="rip 5 r"
            class="stroke clear-fill"
            d="M90.53,118.27c-5.1,1.31-10.31,3.73-15.61,7.19"
          />
          <path
            id="rp_5_r"
            data-name="rp 5 r"
            class="stroke clear-fill"
            d="M66.65,123.53c-5.75,10.77-4.19,18.54,2.82,24.09,8.54,9.22,19.05,15.59,32.17,18.19-2.52,3.62-3.67,6.59-2.51,8.44-13.74-2.31-24.8-9.37-33.49-20.62-10.33-8.84-8.22-16.54-5.62-24.19,1.74-3.61,3.99-5.42,6.63-5.91Z"
          />
          <path
            id="rip_5_r-5"
            data-name="rip 5 r"
            class="stroke clear-fill"
            d="M63.16,136.37c2.18-4.45,4.28-7.13,6.3-7.6"
          />
        </g>
        <g id="rip_6_r" data-name="rip 6 r">
          <path
            id="rip_6_r-2"
            data-name="rip 6 r"
            class="stroke clear-fill"
            d="M90.72,135.52c12.66-3.68,25.62-6.12,39.24-5.95"
          />
          <path
            id="rip_6_r-3"
            data-name="rip 6 r"
            class="stroke clear-fill"
            d="M108.48,124.45c-6.79-.56-15.06,3.58-23.45,8.09"
          />
          <path
            id="rip_6_r-4"
            data-name="rip 6 r"
            class="stroke clear-fill"
            d="M76.98,136.37c-3.5,1.65-6.99,4.44-10.49,8.36"
          />
          <path
            id="rip_6_r-5"
            data-name="rip 6 r"
            class="stroke clear-fill"
            d="M82.06,139.7c-4.44,2.4-7.89,5.82-10.32,10.26"
          />
          <path
            id="rip_6_r-6"
            data-name="rip 6 r"
            class="stroke clear-fill"
            d="M61.16,148.89c-5.24,9.41-6.21,17.62-1.52,24.23,7.69,12.65,19.11,18.33,32.25,20.81-1.07-2.83-.59-5.58,1.76-8.25-9.51-1.75-20.13-8.12-25.39-12.56-6.17-4.82-7.49-13.8-7.11-24.23h.01Z"
          />
          <path
            id="rip_6_r-7"
            data-name="rip 6 r"
            class="stroke clear-fill"
            d="M62.19,162.83c1.53-3.64,2.97-6.49,4.3-8.13"
          />
        </g>
        <g id="rip_7_r" data-name="rip 7 r">
          <path
            id="rip_7_r-2"
            data-name="rip 7 r"
            class="stroke clear-fill"
            d="M62.66,164.55c2.47-3.4,5.04-5.14,7.68-5.49"
          />
          <path
            id="rip_7_r-3"
            data-name="rip 7 r"
            class="stroke clear-fill"
            d="M66.31,170.13c2.06-3.48,4.88-5.7,8.06-7.29"
          />
          <path
            id="rip_7_r-4"
            data-name="rip 7 r"
            class="stroke clear-fill"
            d="M76.98,154.57c6.06-3.18,13.44-5.44,21.38-7.32"
          />
          <path
            id="rip_7_r-5"
            data-name="rip 7 r"
            class="stroke clear-fill"
            d="M85.03,159.06c15.32-5.81,29.61-9.02,44.93-10.08"
          />
          <path
            id="rip_7_r-6"
            data-name="rip 7 r"
            class="stroke clear-fill"
            d="M60.97,175.19c-2.39,6.9-2.4,13.06,3.37,17.46,7.37,7.7,14.24,13.24,20.05,14.23"
          />
          <path
            id="rip_7_r-7"
            data-name="rip 7 r"
            class="stroke clear-fill"
            d="M82.71,215.5c-8.47,1.46-17.11-3.15-26.09-20.81-4.19-6.34-3-14.98,1.19-24.89"
          />
        </g>
        <g id="rip_8_r" data-name="rip 8 r">
          <path
            id="rip_8_r-2"
            data-name="rip 8 r"
            class="stroke clear-fill"
            d="M85.03,169.8c-4.41,1.1-8.51,3.27-12.23,6.72"
          />
          <path
            id="rip_8_r-3"
            data-name="rip 8 r"
            class="stroke clear-fill"
            d="M80.91,181.21c5.28-3.79,10.56-6.26,15.84-7.41"
          />
          <path
            id="rip_8_r-4"
            data-name="rip 8 r"
            class="stroke clear-fill"
            d="M57.28,195.96c-3.31,5.78-4.36,11.6-3.27,17.48,4.16,13.9,11.86,17.84,19.69,21.38"
          />
          <path
            id="rip_8_r-5"
            data-name="rip 8 r"
            class="stroke clear-fill"
            d="M59.46,199.86c-1.94,4.13-2.25,7.85,0,10.95,4.67,7.06,10.4,11.59,16.68,14.81"
          />
          <path
            id="rip_8_r-6"
            data-name="rip 8 r"
            class="stroke clear-fill"
            d="M66.49,181.77c-3.05,1.2-5.01,2.3-6.1,5.95"
          />
          <path
            id="rip_8_r-7"
            data-name="rip 8 r"
            class="stroke clear-fill"
            d="M72.81,186.77c-3.85,1.96-6.67,3.92-8.47,5.88"
          />
          <path
            id="rip_8_r-8"
            data-name="rip 8 r"
            class="stroke clear-fill"
            d="M121.18,165.82c3.86-1.16,7.45-1.89,10.27-1.43"
          />
          <path
            id="rip_8_r-9"
            data-name="rip 8 r"
            class="stroke clear-fill"
            d="M95.88,164.38c7.5-3.49,14.64-5.49,21.27-5.32"
          />
        </g>
        <g id="rip_9_r" data-name="rip 9 r">
          <path
            id="rip_9_r-2"
            data-name="rip 9 r"
            class="stroke clear-fill"
            d="M85.03,192.28c-3.92,.57-8.73,2.7-13.11,7.58"
          />
          <path
            id="rip_9_r-3"
            data-name="rip 9 r"
            class="stroke clear-fill"
            d="M121.18,181.21c3.53-1.14,6.65-1.97,9.25-2.41"
          />
          <path
            id="rip_9_r-4"
            data-name="rip 9 r"
            class="stroke clear-fill"
            d="M114.96,189.16c2.11,.47,4.29-.02,6.52-1.41"
          />
          <line
            id="rip_9_r-5"
            data-name="rip 9 r"
            class="stroke clear-fill"
            x1="64.55"
            y1="217.16"
            x2="68.57"
            y2="212.16"
          />
          <line
            id="rip_9_r-6"
            data-name="rip 9 r"
            class="stroke clear-fill"
            x1="60.39"
            y1="212.16"
            x2="64.55"
            y2="207.17"
          />
          <path
            id="rip_9_r-7"
            data-name="rip 9 r"
            class="stroke clear-fill"
            d="M57.88,222.38c-1.7-3.97-6.14,6.74,0,21.81,4.11,5.93,8.59,7.84,13.18,8.62-1.04-2.59-.9-5.43,0-8.44-4.83-1.86-9.18-4.98-12.37-11.06,1.54-7.47,.69-9.95-.82-10.93h.01Z"
          />
          <path
            id="rip_9_r-8"
            data-name="rip 9 r"
            class="stroke clear-fill"
            d="M94.9,195.79c-6.84,2.24-12.75,4.96-16.43,8.8"
          />
        </g>
        <g id="rip_10_r" data-name="rip 10 r">
          <line
            id="rip_10_r-2"
            data-name="rip 10 r"
            class="stroke clear-fill"
            x1="84.17"
            y1="225.63"
            x2="95.51"
            y2="216.24"
          />
          <path
            id="rip_10_r-3"
            data-name="rip 10 r"
            class="stroke clear-fill"
            d="M63.65,239.88c-.03-2.31,2.17-4.54,4.92-7.57"
          />
          <path
            id="rip_10_r-4"
            data-name="rip 10 r"
            class="stroke clear-fill"
            d="M69.47,243.7c2.28-2.66,4.1-5.68,5.67-8.89"
          />
          <path
            id="rip_10_r-5"
            data-name="rip 10 r"
            class="stroke clear-fill"
            d="M63.44,249.88c-1.99,11.09,.59,16.49,6.03,18.31"
          />
          <path
            id="rip_10_r-6"
            data-name="rip 10 r"
            class="stroke clear-fill"
            d="M67.01,251.74c-.82,3.94,0,6.93,2.45,8.95"
          />
          <path
            id="rip_10_r-7"
            data-name="rip 10 r"
            class="stroke clear-fill"
            d="M120.23,203.77c3.87-2.32,7.5-3.48,10.72-2.62,1.32-3.29,1.01-5.94-.52-8.12-1.23,.47-2.57,.98-4.11,1.57l-6.09,9.16h0Z"
          />
          <path
            id="rip_10_r_11_"
            data-name="rip 10 r (11)"
            class="stroke clear-fill"
            d="M74.37,224.67c1.63-3.8,5.07-6.48,8.35-9.18"
          />
        </g>
        <g id="rip_11_r" data-name="rip 11 r">
          <path
            id="rip_11_r-2"
            data-name="rip 11 r"
            class="stroke clear-fill"
            d="M106.52,222.63c7.66-2.05,15.05-4.75,21.75-9.06,2.03,2.47,2.2,5.16,1.12,7.98-11.3,3.69-21.08,8.09-29.25,13.26-.14-4.1,1.94-8.17,6.38-12.19h0Z"
          />
          <path
            id="rip_11_r-3"
            data-name="rip 11 r"
            class="stroke clear-fill"
            d="M71.92,267.8c-.98,5,.79,7.91,3.21,10.32,.7-4.44,1.4-8.88,2.1-13.31l-5.31,2.99Z"
          />
          <path
            id="rip_11_r_3_"
            data-name="rip 11 r (3)"
            class="stroke clear-fill"
            d="M82.06,242.3c1.46-3.52,3.61-6.18,6.27-7.68"
          />
        </g>
        <g id="rip_12_r" data-name="rip 12 r">
          <path
            id="rip_12_r-2"
            data-name="rip 12 r"
            class="stroke clear-fill"
            d="M130.58,229.77c-10.39,2.78-21.16,10.24-32.13,20.11-12.86,13.42-13.22,20.72-11.76,28.25,3.44-11.69,11-20.69,20.76-28.25,5.95-5.55,12.73-9.75,21.11-11.32l2.12-2.47c.06-1.15,.05-2.97-.1-6.32Z"
          />
        </g>
        <path
          id="collarbone_r"
          data-name="collarbone r"
          class="stroke clear-fill"
          d="M133.42,67.42c1.25-2.38,.2-4.7-3.75-6.93l-19.61,1.36-11.17-.89-11.51-1.82-11.39-1.97-11.77-.6-13.17,.41-8.25-1.21c-2.02,1-3.29,2.46-2.81,4.97-1.28,1.5-.65,2.85,3.94,3.89l16.45-1.12c6.03-.61,12.32-.36,18.84,.7l9.11,3.37,6.08,2.25c5.94,.66,11.57,1.15,15.64,.7,6.18,.23,12.48,2.39,12.48,2.39l2.95,1.41c3.45-.17,6.41-1.62,7.92-6.89l.02-.02Z"
        />
        <path
          id="costal_cartilage_r"
          data-name="costal cartilage r"
          class="stroke clear-fill"
          d="M130.52,151.19c2.75-.05,1.86,3.44,0,7.88-4.93,1.38-9.34,6.38-13.67,12-1.46,3.65-.33,4.34,1.75,3.94,5.45-1.47,9.78-4.34,12.85-8.81,2.92,.29,2.95,1.94,2.26,3.94l-16.48,14.81-4.93,9.19c2.93,.67,6.02-2.04,9.18-6.38l7.35-5.44,6.39-11.06c2.62-1.75,3.8,.06,3.92,4.5-14.01,19.9-26.69,40.9-32.62,46.88-3.6,3.28-6.23,7.33-7.38,12.56-3.5,3.97-11.27,9.36-14.13,17.32-3.88,10.81-10.32,15.57-15.54,15.68-1.66-1.38-1.55-3.95,0-7.5,4.86-1.16,7.53-4.53,8.74-9.38-2.25,1.87-4.64,2.23-7.13,1.5-2.3-1.25-1.98-4.28,0-8.44,5.52,.21,10.76-1.14,15.57-4.88,2.52-3.35,3.3-5.17,1.69-4.88-5.48,1.49-10.32,1.46-14.62,.19-2.01-3.7-1.19-6.76,2.44-9.19,7.61,.87,14.22-.4,19.05-5.44,.73-3.54,.72-6.12-2.37-4.69-2.91,1.12-6.39,.88-10.12,0-2.15-2.17-1.28-5.13,1.69-8.62,3.9,.92,7.56,.36,10.81-2.81,3.97-4.4,4.05-7.12,1.75-8.81-1.95,.8-3.77,1-5.06-1.31-1.36-2.42-.65-5.19,1.76-8.25,4.59,.48,8.58-.41,11.61-3.56,2.14-2.94,1.41-4.78-.96-6l-5.17-1.88c-.82-3.03,.34-5.8,2.51-8.44,4.62-.04,8.99-1,12.81-3.94,7.4-5.45,14.56-10.67,16.07-10.69h-.02Z"
        />
        <g id="rib_1_L" data-name="rib 1 L">
          <path
            id="rib_1_L-2"
            data-name="rib 1 L"
            class="stroke clear-fill"
            d="M157.14,73.75c1.57,.17,6.53-1.09,9.19,0,1.94,.79,1.92,3.79,2.06,4.78-.99,1.42-2,2.27-3.02,2.6-2.42-.35-5.1-.62-6.75,.07-2.69-2.53-2.62-4.74-1.48-7.45h0Z"
          />
          <path
            id="rib_1_L-3"
            data-name="rib 1 L"
            class="stroke clear-fill"
            d="M160.04,44.05c3.64-1.16,5.66-.79,7.04,.17,5.91,6.63,12.48,10.33,15.66,12.74,1.27,1.2,1.99,2.45,2.27,3.76-.9,1.31-6.19,1.34-11.57,1.35-1.12-5.11-4.05-8.41-8.79-9.88-1.81-1.71-4.24-2.27-7.5-1.33l-5.98,2.92c.49-2.09,.7-4.06,.53-5.84"
          />
          <path
            id="rib_1_L-4"
            data-name="rib 1 L"
            class="stroke clear-fill"
            d="M185,68.72c-1.77,4.87-4.18,8.93-8.6,10.45-2.96,.28-5.71,.21-8.02-.64l-2.06-5.62c2.76-.8,4.72-1.81,4.75-3.29l13.93-.89h0Z"
          />
        </g>
        <g id="rib_2_L" data-name="rib 2 L">
          <path
            id="rib_2_L-2"
            data-name="rib 2 L"
            class="stroke clear-fill"
            d="M151.37,97.59c1.95-1.03,4.9-1.12,8.16-.91,2.93-.62,5.43-1.42,7.38-2.46,2.29,3.01,3.36,5.37,.77,8.02-4.94-.21-10.53,.88-15.26,2.04-1.6-1.03-1.97-3.24-1.05-6.68h0Z"
          />
          <path
            id="rib_2_L-3"
            data-name="rib 2 L"
            class="stroke clear-fill"
            d="M196.52,67.56c3.77,1.63,5.91,5.88,7.13,11.61,.43,5.55-3.34,9.48-9.1,12.65-6.28,4.82-15.98,7.91-26.86,10.41,2.2-2.84,2.39-5.64,.4-8.4,11.31-2.96,22.83-5.87,25.88-10.94,1.2-2.6,2.03-5.15,2.4-7.61,.44-2.92,.22-5.73-.8-8.4"
          />
        </g>
        <g id="rib_3_L" data-name="rib 3 L">
          <path
            id="rib_3_L-2"
            data-name="rib 3 L"
            class="stroke clear-fill"
            d="M207.56,91.82c-1.56-2.29-3.76-4.07-6.85-5.16"
          />
          <path
            id="rib_3_L-3"
            data-name="rib 3 L"
            class="stroke clear-fill"
            d="M177.32,79.17c2.76,.28,5.64-.23,8.72,1.29,2.12,1.19,4.34,2.1,6.65,2.78"
          />
          <path
            id="rib_3_L-4"
            data-name="rib 3 L"
            class="stroke clear-fill"
            d="M203.88,80.57c1.06,.71,4.8,8.22,3.18,12.94-3.25,6.13-5.34,8.73-6.89,9.7-3.87,2.63-8.36,4.71-13.36,6.33-4.83,2.27-10.01,3.84-15.68,4.5-.69,.1-1.23,.51-1.7,1.05,2.13,2.29,2.83,4.71,2.12,7.24,1.95,.67,4.21,.38,6.68-.49,6.31-1.22,12.06-2.67,15.82-4.92,5.55-2.98,10.45-6.39,13.99-10.69,2.29-2.97,4.28-6.04,4.71-9.7-.26-2.47-.46-5.28-1.34-7.47-2.2-5.31-4.74-6.53-7.54-8.5h.01Z"
          />
          <path
            id="rib_3_L-5"
            data-name="rib 3 L"
            class="stroke clear-fill"
            d="M152.36,114.6c2.78,1.21,5.75,1.85,9,1.62,2.77,0,5.48-.65,8.69-1.71,.19,.72,.78,2.3,1.53,4.22,.26,1.25-.15,2.74-.8,4.31-2.62,.93-5.35,1.01-8.16,.42-4.1-1.18-7.45-1.5-10.27-1.2-1.91-1.81-2.03-4.32,0-7.66h0Z"
          />
          <path
            id="rib_3_L-6"
            data-name="rib 3 L"
            class="stroke clear-fill"
            d="M189.15,68.72c3.42,1.27,5.25,4.34,6.42,8.16-3.69-1.74-7.88-3.23-12.84-4.32"
          />
        </g>
        <g id="rib_4_L" data-name="rib 4 L">
          <path
            id="rib_4_L-2"
            data-name="rib 4 L"
            class="stroke clear-fill"
            d="M153.91,86.66c7.43-.85,14.35-.9,20.45,.31,3.94,.29,7.39,.81,10.64,1.43"
          />
          <path
            id="rib_4_L-3"
            data-name="rib 4 L"
            class="stroke clear-fill"
            d="M151.23,93.83c4.77-1.36,9.47-2.09,14.06-2.01,2.15,.27,3.66,.61,4.76,1"
          />
          <path
            id="rib_4_L-4"
            data-name="rib 4 L"
            class="stroke clear-fill"
            d="M196.52,90.91c3.8,1,6.94,2.55,9.42,4.67"
          />
          <path
            id="rib_4_L-5"
            data-name="rib 4 L"
            class="stroke clear-fill"
            d="M186.42,96.11c5.74,1.1,10.82,3.03,15.09,5.97"
          />
          <path
            id="rib_4_L-6"
            data-name="rib 4 L"
            class="stroke clear-fill"
            d="M208.94,105.04c2.92,1.17,5.43,3.35,7.68,6.18"
          />
          <path
            id="rib_4_L-7"
            data-name="rib 4 L"
            class="stroke clear-fill"
            d="M212.14,99.24c2.63,1.65,4.83,3.48,5.75,5.8,.42,2.45,.08,4.43-1.27,5.76"
          />
          <path
            id="rib_4_L-8"
            data-name="rib 4 L"
            class="stroke clear-fill"
            d="M215.85,102.08c2.13,2.71,4.36,4.95,5.2,8.72,.9,3.14,.53,6.79-.98,10.9-4.57,6.83-10.56,12.02-17.51,16.1-5.47,3.43-10.81,6.3-15.89,8.16-3.55,.74-6.99,1.03-10.27,.77,1.36-2.14,2.02-4.5,0-7.73,.62-1.19,1.38-1.71,2.25-1.69,7.35-.3,14.13-3.28,18.77-5.77,5.9-3.56,11.18-7.14,14.34-10.76,3.1-3.46,4.7-7,5.46-10.75"
          />
          <path
            id="rib_4_L-9"
            data-name="rib 4 L"
            class="stroke clear-fill"
            d="M176.41,139c-5.55,.24-18-2.78-22.4-3.9-.99-.25-1.58-.41-1.58-.41,0,0-2.32,4.53,.91,7.03,8.26,3.5,16.06,5.51,23.07,5.02,1.65-2.91,1.72-5.5,0-7.73h0Z"
          />
        </g>
        <g id="rib_5_L" data-name="rib 5 L">
          <path
            id="rib_5_L-2"
            data-name="rib 5 L"
            class="stroke clear-fill"
            d="M152.22,105.81c5.03-1.1,9.77-1.5,14.2-1.12,6.83,1.31,13.27,2.29,18.58,2.32,2.3,.35,3.28,1.52,4.93,1.43l-11.48,4.19c-2.44-1.48-5.79-2.41-10.13-2.74-2.73-.19-5.49-.09-8.28,.28-3.38,.35-6.37,1.26-8.8,2.95-.48-.92-.69-1.95,.35-3.52-.74-1.81-.28-2.93,.63-3.8h0Z"
          />
          <path
            id="rib_5_L-3"
            data-name="rib 5 L"
            class="stroke clear-fill"
            d="M203.65,110.62c4.2,1.06,8.02,2.88,11.45,5.45"
          />
          <path
            id="rib_5_L-4"
            data-name="rib 5 L"
            class="stroke clear-fill"
            d="M194.55,116.65c6.1,1.64,11.21,3.83,14.83,6.88"
          />
          <path
            id="rib_5_L-5"
            data-name="rib 5 L"
            class="stroke clear-fill"
            d="M220.07,121.7c3.27,2.87,5.4,5.54,5.32,7.86-.8,2.04-1.7,4.34-2.66,6.81-.89-3.93-3.55-6.66-7.63-8.42l4.97-6.24h0Z"
          />
          <path
            id="rib_5_L-6"
            data-name="rib 5 L"
            class="stroke clear-fill"
            d="M185.45,171.41c13.99-1.8,29.75-12.11,39.94-26.68,2.36-6.4,1.83-11.72-.74-16.26l-3.11,10.15c-6.39,11.06-21.08,18.64-37.21,25.62,1.66,2.16,2.28,4.51,1.12,7.17Z"
          />
        </g>
        <g id="rib_6_L" data-name="rib 6 L">
          <path
            id="rib_6_L-2"
            data-name="rib 6 L"
            class="stroke clear-fill"
            d="M192.76,180.06c14.6-3.22,27.85-9.3,36.38-25.5,1.15,.95,1.35,4.61,0,12.75-5.85,13.71-21.49,17.27-35.62,22.4,2.94-3.28,2.83-6.5-.75-9.65h-.01Z"
          />
          <path
            id="rib_6_L-3"
            data-name="rib 6 L"
            class="stroke clear-fill"
            d="M152.22,130.75c11.72-3.49,23.93-4.19,37.71,4.19"
          />
          <path
            id="rib_6_L-4"
            data-name="rib 6 L"
            class="stroke clear-fill"
            d="M177.32,122.7c7.53,1.23,15.06,3.67,22.59,7.32"
          />
          <path
            id="rib_6_L-5"
            data-name="rib 6 L"
            class="stroke clear-fill"
            d="M207.56,133.56c2.96,1.03,6.94,3.75,11.92,8.16"
          />
          <path
            id="rib_6_L-6"
            data-name="rib 6 L"
            class="stroke clear-fill"
            d="M199.91,139.43c6.88,2.41,11.8,5,14.36,7.82"
          />
          <path
            id="rib_6_L-7"
            data-name="rib 6 L"
            class="stroke clear-fill"
            d="M224.48,146.01c1.66,1.18,3.21,4.02,4.66,8.56"
          />
          <path
            id="rib_6_L-8"
            data-name="rib 6 L"
            class="stroke clear-fill"
            d="M219.47,152.07c3.7,1.71,5.68,4.57,5.92,8.58"
          />
        </g>
        <g id="rib_7_L" data-name="rib 7 L">
          <path
            id="rib_7_L-2"
            data-name="rib 7 L"
            class="stroke clear-fill"
            d="M152.67,147.72c15.74-.32,31.8,2.92,45.46,10.06"
          />
          <path
            id="rib_7_L-3"
            data-name="rib 7 L"
            class="stroke clear-fill"
            d="M188.61,145.21c6.34,1.07,12.5,3.59,18.47,7.69"
          />
          <path
            id="rib_7_L-4"
            data-name="rib 7 L"
            class="stroke clear-fill"
            d="M215.1,156.41c3.72,1.43,6.42,3.72,8.63,6.42"
          />
          <path
            id="rib_7_L-5"
            data-name="rib 7 L"
            class="stroke clear-fill"
            d="M198.97,202.19c12.95-4.21,23.83-11.41,31.11-23.81-.02-4.92,.17-7.42-.94-11.06,2.33,7.28,4.68,14.58,2.62,19.46-7.17,14.83-18.11,22.01-31.5,24.23,1.29-3.12,.57-6.03-1.29-8.81h0Z"
          />
          <line
            id="rib_7_L-6"
            data-name="rib 7 L"
            class="stroke clear-fill"
            x1="223.88"
            y1="175.1"
            x2="227.91"
            y2="181.77"
          />
        </g>
        <g id="rib_8_L" data-name="rib 8 L">
          <path
            id="rib_8_L-2"
            data-name="rib 8 L"
            class="stroke clear-fill"
            d="M169.44,159.06c7.32,.86,13.36,2.11,18.11,3.77"
          />
          <path
            id="rib_8_L-3"
            data-name="rib 8 L"
            class="stroke clear-fill"
            d="M207.56,224.28c11.19-7.21,22.43-14.39,24.96-28.21,1.75,3.71,1.03,8.82-1.22,17.5-9.99,14.51-15.83,16.94-21.92,17.5,.93-2.12,.51-4.36-1.82-6.79h0Z"
          />
          <path
            id="rib_8_L-4"
            data-name="rib 8 L"
            class="stroke clear-fill"
            d="M198.97,167.31c4.56,1.82,8.32,4.03,10.67,6.94"
          />
          <path
            id="rib_8_L-5"
            data-name="rib 8 L"
            class="stroke clear-fill"
            d="M187.55,171.41c4.2,1.82,7.09,3.87,10.3,7.38"
          />
          <path
            id="rib_8_L-6"
            data-name="rib 8 L"
            class="stroke clear-fill"
            d="M218.06,179.69c3.39,1.8,5.83,3.6,7.33,5.4"
          />
          <path
            id="rib_8_L-7"
            data-name="rib 8 L"
            class="stroke clear-fill"
            d="M208.39,184.41c4.65,2.5,8.76,5.05,9.67,7.86"
          />
          <path
            id="rib_8_L-8"
            data-name="rib 8 L"
            class="stroke clear-fill"
            d="M230.08,189.99c1.85,3.14,3.18,5.73,2.44,6.08"
          />
          <path
            id="rib_8_L-9"
            data-name="rib 8 L"
            class="stroke clear-fill"
            d="M225.39,196.88c1.99,1.85,3.36,4.42,4.12,7.71"
          />
          <path
            id="rib_8_L-10"
            data-name="rib 8 L"
            class="stroke clear-fill"
            d="M152.81,162.83c3.71-.36,7,.46,11.13,1.41"
          />
        </g>
        <g id="rib_9_L" data-name="rib 9 L">
          <path
            id="rib_9_L-2"
            data-name="rib 9 L"
            class="stroke clear-fill"
            d="M195.06,189.16c6.73,1.03,10.48,2.57,17.07,7.61"
          />
          <path
            id="rib_9_L-3"
            data-name="rib 9 L"
            class="stroke clear-fill"
            d="M184.33,191.96c8.35,3.22,15.91,6.39,17.63,9.19"
          />
          <path
            id="rib_9_L-4"
            data-name="rib 9 L"
            class="stroke clear-fill"
            d="M212.78,207.17c4.42,2.49,7.03,4.99,7.83,7.49"
          />
          <path
            id="rib_9_L-5"
            data-name="rib 9 L"
            class="stroke clear-fill"
            d="M220.25,202.11c2.12,1.72,4.12,3.85,5.65,7.73"
          />
          <path
            id="rib_9_L-6"
            data-name="rib 9 L"
            class="stroke clear-fill"
            d="M156.61,178.44c3.31-.58,4.93-.21,6.19,.36"
          />
          <path
            id="rib_9_L-7"
            data-name="rib 9 L"
            class="stroke clear-fill"
            d="M165.08,185.82c1.14,0,2.75,.35,3.88,.35"
          />
          <path
            id="rib_9_L-8"
            data-name="rib 9 L"
            class="stroke clear-fill"
            d="M215.1,239.26c8.78-3.1,10.93-9.67,10.29-17.71l2.52-3.32c1.89,7,2.64,13.86-.46,20.24-1.27,4.93-5.49,7.9-10.69,10.22,2.21-3.26,1.88-6.41-1.67-9.43h.01Z"
          />
        </g>
        <g id="rib_10_L" data-name="rib 10 L">
          <path
            id="rib_10_L-2"
            data-name="rib 10 L"
            class="stroke clear-fill"
            d="M152.81,193.03c3.81-.37,8.46,2.03,8.46,2.03,0,0,4.29,4.66,5.64,7.5-4.4-1.83-9.07-2.47-14.1-1.42-1.67-2-1.51-4.78,0-8.12h0Z"
          />
          <line
            id="rib_10_L-3"
            data-name="rib 10 L"
            class="stroke clear-fill"
            x1="198.13"
            y1="211.74"
            x2="211.76"
            y2="221.55"
          />
          <line
            id="rib_10_L-4"
            data-name="rib 10 L"
            class="stroke clear-fill"
            x1="188.68"
            y1="216.24"
            x2="204.3"
            y2="223.75"
          />
          <path
            id="rib_10_L-5"
            data-name="rib 10 L"
            class="stroke clear-fill"
            d="M212.78,230.49c2.95,4.2,4.93,7.15,5.28,7.98"
          />
          <path
            id="rib_10_L-6"
            data-name="rib 10 L"
            class="stroke clear-fill"
            d="M219.12,227.36c1.97,2.64,3.37,4.74,3.95,6.09"
          />
          <path
            id="rib_10_L-7"
            data-name="rib 10 L"
            class="stroke clear-fill"
            d="M222.33,245.56c.07,4.5-.62,8.36-4.27,11.66,.2-1.06,.57,1.85,1.06,7.59,6.02-5.87,8.36-13.34,6.78-22.51"
          />
        </g>
        <g id="rib_11_L" data-name="rib 11 L">
          <path
            id="rib_11_L-2"
            data-name="rib 11 L"
            class="stroke clear-fill"
            d="M156.61,212.74c-2.89,2.94-3.47,5.87-1.72,8.8,11.33,1.84,21.58,6.69,30.75,14.54l-4.66-13.66c-9.46-1.36-17.28-5.01-24.37-9.68h0Z"
          />
          <path
            id="rib_11_L-3"
            data-name="rib 11 L"
            class="stroke clear-fill"
            d="M204.3,260.26c2.52,5.17,2.83,9.19,1.96,12.61,3.49-2.66,5.78-5.61,5.87-9.09l-7.84-3.52h.01Z"
          />
          <path
            id="rib_11_L-4"
            data-name="rib 11 L"
            class="stroke clear-fill"
            d="M197.26,232.3c1.39,.66,2.87,2.29,4.7,7.57"
          />
          <path
            id="rib_L_11"
            data-name="rib L 11"
            class="stroke clear-fill"
            d="M207.39,249.88c2.58,2.36,3.97,4.8,4.03,7.34"
          />
        </g>
        <g id="rib_12_L" data-name="rib 12 L">
          <path
            id="rib_12_L_1_"
            data-name="rib 12 L (1)"
            class="stroke clear-fill"
            d="M153.84,229.75c10.51,2.98,19,9.52,26.17,18.38,6.89,6.16,12.79,11.67,15.05,14.81,2.7,7.78,1.24,11.9-1.17,15.19,.32-7.08-5.78-15.86-14.44-25.31-7.07-6.75-14.98-12.01-23.81-15.63l-1.8-7.43h0Z"
          />
        </g>
        <g id="sternum">
          <path
            id="sternum_16"
            data-name="sternum 16"
            class="stroke clear-fill"
            d="M151.23,68.83c.14,2.88,2.46,3.88,5.91,4.92"
          />
          <path
            id="sternum_15"
            data-name="sternum 15"
            class="stroke clear-fill"
            d="M157.9,80.46c-1.75,3.59-3.35,5.79-4.84,7-1.48,3.22-1.79,8.03-2.74,8.48,.78,.28,.72,1.7,.56,3.3"
          />
          <path
            id="sternum_14"
            data-name="sternum 14"
            class="stroke clear-fill"
            d="M135.34,95.13l-.37-1.04c-.39-2.47-.96-4.16-1.74-4.87-1.29-2.63-3.06-4.75-5.34-6.33-1.44-1.14-2.31-1.1-2.18-1.69"
          />
          <path
            id="sternum_13"
            data-name="sternum 13"
            class="stroke clear-fill"
            d="M150.88,95.2c-1.67,1.31-4.78,1.76-8.54,1.83-4.04,.21-6.91-.47-7.37-2.94"
          />
          <line
            id="sternum_12"
            data-name="sternum 12"
            class="stroke clear-fill"
            x1="133.92"
            y1="96.11"
            x2="135.71"
            y2="95.57"
          />
          <path
            id="sternum_11"
            data-name="sternum 11"
            class="stroke clear-fill"
            d="M132.25,115.62l-1.73,.95c.65-.75,2.97-7.79,2.41-7.73"
          />
          <path
            id="sternum_10"
            data-name="sternum 10"
            class="stroke clear-fill"
            d="M130.52,124.45c1.43,1.67,1.25,3.38-.57,5.12,.85,1.85,.93,3.59-.57,5.12"
          />
          <path
            id="sternum_9"
            data-name="sternum 9"
            class="stroke clear-fill"
            d="M130.38,143.31c-1.61,3.12-1.04,5.59,.14,7.88"
          />
          <path
            id="sternum_8"
            data-name="sternum 8"
            class="stroke clear-fill"
            d="M130.52,159.06c-.27,2.13,.17,4.58,.93,7.12"
          />
          <line
            id="sternum_7"
            data-name="sternum 7"
            class="stroke clear-fill"
            x1="133.71"
            y1="170.13"
            x2="135.22"
            y2="171.25"
          />
          <path
            id="sternum_6"
            data-name="sternum 6"
            class="stroke clear-fill"
            d="M147.2,174.25c-.94,0-2.33,1.67-3.37,.94-1.56-.31-3,0-4.69-.94"
          />
          <path
            id="sternum_5"
            data-name="sternum 5"
            class="stroke clear-fill"
            d="M153.91,121.7c-3.4,5.47-2.74,9.75,.09,13.39"
          />
          <path
            id="sternum_4"
            data-name="sternum 4"
            class="stroke clear-fill"
            d="M154.89,141.72c-2.81,4.66-2.52,7.65-.78,9.28"
          />
          <path
            id="sternum_3"
            data-name="sternum 3"
            class="stroke clear-fill"
            d="M140.83,175.37c-4.58,6.37-5.81,13.25-5.06,20.42,1.4,5.71,3.63,8.51,6.56,8.8,2.41-.82,4.49-2.09,4.87-5.78,1.92-7.23,2.68-7.14,3.64-7.78l-1.95-9.26-3.02-6.96-5.05,.56h.01Z"
          />
          <line
            id="sternum_2"
            data-name="sternum 2"
            class="stroke clear-fill"
            x1="151.61"
            y1="170.25"
            x2="150.32"
            y2="171.25"
          />
          <path
            id="sternum_1"
            data-name="sternum 1"
            class="stroke clear-fill"
            d="M151.49,165.45c2.66-2.23,3.05-5.89,1.32-9.09"
          />
        </g>
        <g id="thoracic_vertebrae" data-name="thoracic vertebrae">
          <path
            id="thoracic_vertebrae_12"
            data-name="thoracic vertebrae 12"
            class="stroke clear-fill"
            d="M124.4,41.87c.99-.07,2.44-.02,2.69-.45,.67-1.15,5.04-1.85,3.98-1.61,1.79-.22,3.04,.73,3.99,2.78,.4,.87,.03,.95-.98,.84-1.09-.24-1.81,.17-1.93,1.65,.91,.69,1.11,1.55,.08,2.7-.53-.31-.86-.76-.96-1.4h-4.08c-2.44-.22-4.54-1.05-6.05-2.95-.59-.72-.48-1.28,.84-1.55,0,0,1.22,.07,2.42,0Z"
          />
          <path
            id="thoracic_vertebrae_11"
            data-name="thoracic vertebrae 11"
            class="stroke clear-fill"
            d="M158.11,41.22c-.83-.08-2.04-.03-2.25-.5-.56-1.28-4.21-2.05-3.32-1.79-1.5-.25-2.54,.81-3.34,3.09-.34,.97-.02,1.06,.82,.94,.91-.27,1.51,.19,1.62,1.84-.76,.77-.93,1.72-.07,2.99,.44-.34,.72-.85,.8-1.55h3.41c2.04-.25,3.79-1.16,5.05-3.28,.49-.8,.4-1.42-.71-1.72,0,0-1.02,.08-2.02-.02h.01Z"
          />
          <path
            id="thoracic_vertebrae_10"
            data-name="thoracic vertebrae 10"
            class="stroke clear-fill"
            d="M134.87,43.5c2.14,.63,4.46,.86,6.98,.64,2.67-.18,4.85-.59,6.38-1.14l.84-.28"
          />
          <path
            id="thoracic_vertebrae_9"
            data-name="thoracic vertebrae 9"
            class="stroke clear-fill"
            d="M133.09,54.91v2.67l-.84,4.08c1.32,2.3,2.09,4.58,.84,6.77,2.11-.61,4.58,.33,7.17,1.79,2.75,.26,5.12-.55,7.03-2.66,2.5-.42,3.66,.11,3.94,1.27l.98-1.27v-5.9c.51-2.9,.11-4.61-1.55-4.7,1.01-.41,1.57-1.08,1.55-2.06-.41-.91-.89-1.1-1.41-.83-2.19,1.52-5.2,2.28-8.84,2.48-3.15-.22-6.15-.61-8.88-1.65h.01Z"
          />
          <path
            id="thoracic_vertebrae_8"
            data-name="thoracic vertebrae 8"
            class="stroke clear-fill"
            d="M135.22,191.96l-4.79,1.07c1.83,3.34,1.66,7.3,.52,11.56,3.03-1.17,5.75-1.42,8.19-.82"
          />
          <path
            id="thoracic_vertebrae_7"
            data-name="thoracic vertebrae 7"
            class="stroke clear-fill"
            d="M145.72,202.56c2.58-.97,4.67-.29,6.5,1.2-.97-4.78-.84-8.39,.59-10.74l-1.97-2"
          />
          <path
            id="thoracic_vertebrae_6"
            data-name="thoracic vertebrae 6"
            class="stroke clear-fill"
            d="M130.52,189.16c-1.26,1.85-1.29,2.79-.09,2.8,1.9-.72,3.59-1.37,5.13-1.4"
          />
          <path
            id="thoracic_vertebrae_5"
            data-name="thoracic vertebrae 5"
            class="stroke clear-fill"
            d="M150.88,189.99h1.93c.96-.84,1.79-1.82,0-4.73"
          />
          <path
            id="thoracic_vertebrae_4"
            data-name="thoracic vertebrae 4"
            class="stroke clear-fill"
            d="M133,204.38c.67,.53,1.25,1.16,0,3.48,7.13-.24,14.8-.38,17.89-.99-1.39-.86-.93-2.07,0-3.36"
          />
          <path
            id="thoracic_vertebrae_3"
            data-name="thoracic vertebrae 3"
            class="stroke clear-fill"
            d="M129.95,209.69c-3.1,2.85-.22,6.55,1.07,11.86h1.97c6.15-.88,12.81-.77,19.81,0,1.78-5.21,1.14-9.69,0-13.69-9.34-.53-18.16-.72-22.86,1.83h.01Z"
          />
          <path
            id="thoracic_vertebrae_2"
            data-name="thoracic vertebrae 2"
            class="stroke clear-fill"
            d="M133.02,224.42c1.21-.96,1.2-1.91-.03-2.87h17.02c-1,.96-1,1.91,0,2.87h-16.99Z"
          />
          <path
            id="thoracic_vertebrae_1"
            data-name="thoracic vertebrae 1"
            class="stroke clear-fill"
            d="M129.96,226.56c.98,4.02,1.36,8.3,0,13.31,8.23-.51,16.79-1.19,21.89,0,2.66,.9,4.02,.13,3.79-2.7-1.84-1.09-2.19-3.85-1.8-7.43,1.11-1.95,1.31-3.81-1.62-5.33-9.42,1.21-19.67-1.56-22.26,2.15Z"
          />
        </g>
        <path
          id="collarbone_L"
          data-name="collarbone L"
          class="stroke clear-fill"
          d="M152.22,63.44c1.27-2.46,2.73-3.47,4.39-2.95l15.91,1.63c10.01-.53,19.51-1.22,25.62-2.99l37.2-2.17c3.81,.77,4.16,3.08,2.06,6.48-6.56,1.04-14.9,.96-24.61,0-8.88,.45-15.8,2.5-21.69,5.39-7.31-.26-15.22-.14-24.02,1.4-5.2,3.14-8.71,3.55-11.41,2.68-3.56-1.04-4.59-3.74-3.45-7.88v-1.59h0Z"
        />
        <g id="Outline">
          <path
            id="neck_outline_L"
            data-name="neck outline L"
            class="stroke clear-fill"
            d="M177.32,8.87c-1.8,9.31,0,18.62,5.41,27.94"
          />
          <path
            id="neck_outline_R"
            data-name="neck outline R"
            class="stroke clear-fill"
            d="M107.49,.06c1.63,12.44,0,23.25-4.9,32.44"
          />
          <path
            id="arm_outlinr_L"
            data-name="arm outlinr L"
            class="stroke clear-fill"
            d="M180.58,29.87c10.55,4.38,20.12,8.25,23.55,9,12.32,9.17,24.28,16.58,34.54,15.75,9.6,5.86,3.18,1.06,3.04,2.34,9.64,2.34,16.37,7.27,20.7,14.33,6.34,12.35,10.7,22.52,11.74,29.02,.77,7.33,1.1,15.56-1.31,29.25l2.44,29.49"
          />
          <path
            id="arm_outline_R"
            data-name="arm outline R"
            class="stroke clear-fill"
            d="M105.35,25.56c-11.32,4.58-21.58,8.63-25.26,9.41-13.22,9.59-26.04,17.34-37.05,16.47-10.3,6.13-3.41,1.11-3.26,2.44-10.35,2.44-17.56,7.6-22.2,14.98-6.8,12.91-11.48,23.55-12.59,30.35-.83,7.67-1.18,16.27,1.41,30.59L.49,165.79"
          />
          <path
            id="inner_outline_L"
            data-name="inner outline L"
            class="stroke clear-fill"
            d="M235.74,133.56c-.69,10.16-.23,18.22,2.1,22.85,1.59,5.31,2.27,8.93,1.6,10.07"
          />
          <path
            id="inner_outline_R"
            data-name="inner outline R"
            class="stroke clear-fill"
            d="M44.64,129.04c2.83,9.16,3.32,17.78,0,25.53-.73,9.39-3.12,14.8-4.74,18.59"
          />
        </g>
        <g id="arm_bone_L" data-name="arm bone L">
          <path
            id="arm_bone_L-2"
            data-name="arm bone L"
            class="stroke clear-fill"
            d="M230.08,76.1c1.53-2.07,2.95-2.71,4.12,0,1.69,1.15,2.49,2.23,1.88,3.21-.61,2.88-1.66,3.55-3,2.78-3.27,5.39-3.44,9.1-.53,11.14,1,3.33,3.34,5.85,8.59,8.07,1.59-.17,2.93,.41,3.38,3.73,.89,6.3,1.74,12.3,1.88,13.22,3.36,18.11,3.63,43.34,3.39,45.67"
          />
          <path
            id="arm_bone_L-3"
            data-name="arm bone L"
            class="stroke clear-fill"
            d="M268.52,161.81c-3.48-20.89-13-57.28-10.5-72.56,1.8-1.63,2.57-4.05,1.31-8.04-2.04-.75-4.03-.75-6-.74-2.2-1.96-5.11-3.27-9.56-3.2-3.52-.18-6.09,1.09-8.03,3.31"
          />
        </g>
        <g id="armbone_R" data-name="armbone R">
          <path
            id="arm_bone_R"
            data-name="arm bone R"
            class="stroke clear-fill"
            d="M49.7,77.9c-1.53-2.07-3.84-3.41-5.01-.71-1.69,1.15-2.49,2.23-1.88,3.21,.61,2.88,1.66,3.55,3,2.78,3.27,5.39,3.44,9.1,.53,11.14-1,3.33-3.34,5.85-8.59,8.07-1.59-.17-2.93,.41-3.38,3.73-.89,6.3-1.74,12.3-1.88,13.22-3.36,18.11-5.69,44.79-5.45,47.12"
          />
          <path
            id="arm_bone_R-2"
            data-name="arm bone R"
            class="stroke clear-fill"
            d="M10.89,167.31c3.48-20.89,12.48-61.7,9.99-76.98-1.8-1.63-2.57-4.05-1.31-8.04,2.04-.75,4.03-.75,6-.74,2.2-1.96,5.11-3.27,9.56-3.2,3.52-.18,6.09,1.09,8.03,3.31"
          />
        </g>
        <g id="shoulder_L" data-name="shoulder L">
          <path
            id="shoulder_L-2"
            data-name="shoulder L"
            class="stroke clear-fill"
            d="M198.97,65.13c3.28,1.06,6.25,2.47,8.11,5.09l2.29,4.09c1.34,2.29,2.62,2.49,3.85,0-.48-1.98,.2-3.82,2.54-5.48,2.63-.44,4.85-.15,6.67,.89,4.46,4.76,6.97,7.45,7.65,8.18,1.06,4.28,.16,6.99-3.18,7.73-3.78,.34-7.1-2.55-10.27-6.46"
          />
          <path
            id="shoulder_L-3"
            data-name="shoulder L"
            class="stroke clear-fill"
            d="M235.75,63.67l1.82,1.36c.69,2.02,.01,3.26-2.06,3.69-3.87-.71-7.45,2.4-10.12,2.57"
          />
          <path
            id="shoulder_L-4"
            data-name="shoulder L"
            class="stroke clear-fill"
            d="M225.9,85.66s1.82,3.67,.91,4.19-1.42-.61-1.42-.61c-2.65,4.98-1.91,8.93,4.69,11.07,3.38,2.22,6.5,2.55,9.36,1"
          />
          <path
            id="shoulder_L-5"
            data-name="shoulder L"
            class="stroke clear-fill"
            d="M224.48,125.46c3.97-1.67,6.14-5.51,6.02-12.11,.9-3.88,2.99-6.19,5.95-7.36,2.91-.1,4.87-1.51,5.57-4.67"
          />
          <path
            id="shoulder_L-6"
            data-name="shoulder L"
            class="stroke clear-fill"
            d="M239.44,60.72c2.36,3.18,5.15,2.75,5.45,1.4,3.17-.12,3.93,3.41,3.91,8.11,1.58,2.72,1,5.22,0,7.67"
          />
        </g>
        <g id="shoulder_R" data-name="shoulder R">
          <path
            id="shoulder_R-2"
            data-name="shoulder R"
            class="stroke clear-fill"
            d="M43.14,64.76l-1.82,1.36c-.69,2.02-.01,3.26,2.06,3.69,3.87-.71,9.06,2.4,11.74,2.57"
          />
          <path
            id="shoulder_R-3"
            data-name="shoulder R"
            class="stroke clear-fill"
            d="M50.83,88.16s.35,2.26,1.26,2.78,1.42-.61,1.42-.61c2.65,4.98,2.52,10.62-4.08,12.76-3.38,2.22-7.79,.41-10.65-1.15"
          />
          <path
            id="shoulder_R-4"
            data-name="shoulder R"
            class="stroke clear-fill"
            d="M59.28,131.68c-3.97-1.67-11.01-10.65-10.89-17.25-.9-3.88-2.99-6.19-5.95-7.36-2.91-.1-4.87-1.51-5.57-4.67"
          />
          <path
            id="shoulder_R-5"
            data-name="shoulder R"
            class="stroke clear-fill"
            d="M39.45,61.81c-2.36,3.18-6.47,1.66-6.77,.31-3.17-.12-5.22,3.59-5.2,8.28-1.58,2.72,1.61,6.13,2.61,8.59"
          />
          <path
            id="shoulder_R-6"
            data-name="shoulder R"
            class="stroke clear-fill"
            d="M64.05,79.23c-3.34,5.68-7.06,9.52-11.67,8.93-2.41,.24-4.22-.9-4.3-6.06,.1-1.88,1.48-3.88,4.02-6,1.79-2.82,3.84-5.47,7.19-7.28,4.78,.18,7.28,2.22,8.28,5.48-.63,2.56,.32,3.39,3.51,1.79,1.15-1.64,2.11-3.41,2.26-5.7,2.88-2.26,6.02-3.87,9.38-4.93"
          />
        </g>
        <path
          id="costal_cartilage"
          data-name="costal cartilage"
          class="stroke clear-fill"
          d="M153.34,150.91c-1.47,2.58-1.12,5.31,.57,8.15,.6,.16,12.31,5.28,14.83,9.38,1.33,2.49,.63,3.36-1.83,2.81-3.94-.19-10.25-3.68-13.06-5.35-.89-.53-1.43-.88-1.43-.88,0,0-2.16,2.6-2.11,4.77,8.17,2.48,15.27,11.9,22.38,21.24-9.05-5.59-16.56-12.15-22.38-19.78-1.24-.4-1.98,.35-2.37,1.9-1.31,2.89-1.01,5.76,.94,8.61,8.33,7.36,16.38,14.66,18.02,20.8,9.12,8.47,15.31,19.7,18.73,33.53,7.15,16.87,17.43,26.66,31.12,28.72,3.5-.51,2.75-3.36,0-7.31-4.11,.7-7.4-.42-9.75-3.56-4.36-3.76-4.77-5.4,.38-4.06,3.4,.39,6.58,.16,9.38-1.19,3.42-4.29-.4-9.4-1.67-9.43,0,0-9.94,2.48-16.13,0-3.97-6.1-4.15-7.99-1.71-6.95,4.18,.41,8.22,.06,12.11-1.24,.91-3.57,0-6-2.73-7.32-5.64,.79-11.33-1.94-17.06-6.59-5.01-4.99-4.5-6.66,1.52-4.99,3.45,.39,6.5,0,9.17-1.16,1.51-2.66,.45-5.69-1.29-8.81-5.37,1.51-10.97,.14-16.61-2-4.47-3.07-4.52-5.73,.66-7.91l10.5-2.57c3.36-4.42,2.25-7.35-.75-9.65-4.49,2.1-8.65,1.35-12.56-1.5-3.58-1.99-4.51-3.85,.19-5.41l5.06-1.74c1.13-2.12,.73-4.51-1.12-7.17-5.06,1.69-10.01-.67-14.89-5.18-6.8-4.57-12.71-7.99-16.1-8.15h-.01Z"
        />
      </g>
      <g id="collarbone_r_overlay" data-name="collarbone  r overlay">
        <path
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.collarboneR),
            });
          }}
          class={`zone ${isSelected(selected, Chest.children.collarboneR)}`}
          id="collarbone_R_1_overlay"
          data-name="collarbone R (1) overlay"
          d="M39.9,59.42c.68-2.42,1.96-3.75,4.15-3.46,5.71,.95,11.64,1.41,18.14,.6,7.76-.12,16.69,.53,27.42,3.37,10.63,3.81,25.73,1.67,27.25,.79,.91-.53,12.52,.26,12.52-.79,2.94,1.83,4.74,4.14,4.02,7.51-1.16,4.29-3.65,6.53-7.7,6.31-2.15-2.78-10.29-4.38-29.82-3.76-3.97-2.32-10.23-4.12-16.82-5.85-13.54-1.97-26.85-.96-35.13,.49-2.94-.55-5.14-1.49-4.52-3.91l.49-1.3Z"
        />
      </g>
      <g id="collarbone_L_overlay" data-name="collarbone L overlay">
        <path
          id="Collarbone_L_1_overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.collarboneL),
            });
          }}
          class={`zone ${isSelected(selected, Chest.children.collarboneL)}`}
          data-name="Collarbone L (1) overlay"
          d="M155.09,60.49c11.03,.77,22.12,2.25,29.91,.79l13.14-2.14c14.13-.72,28.09-1.45,37.6-2.58,2.84,.75,4.14,2.54,1.66,6.89-6.81,1.15-15.7,.82-25.26,0-7.58,.27-14.69,1.62-20.81,5.28-7.12-.46-15.15,.19-23.64,1.41-8,4.64-13.19,4.07-16.07-.82,.23-1.25,.46-2.6,.6-5.87,.71-1.56,1.7-2.48,2.87-2.96Z"
        />
      </g>
      <g id="sternum_overlay" data-name="sternum overlay">
        <path
          id="sternum_1_overlay"
          data-name="sternum (1) overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.sternum),
            });
          }}
          class={`zone ${isSelected(selected, Chest.children.sternum)}`}
          d="M125.71,81.2c.8-2.02,.76-4.5,.35-7.18,3.53,.18,6.3-1.6,7.86-5.75,1.89-.18,4.05,.65,6.34,1.95,2.79,.24,5.17-.58,7.03-2.66,2.27-.25,3.85-.02,3.94,1.27,1.61,2.42,3.45,4.36,5.91,4.92-1.21,3.1-1.07,5.41,.76,6.71-.83,3.28-2.68,5.34-4.84,7l-2.18,7.74,.49,2.39c-.85,2.78-.69,5.1,1.05,6.69-1.07,2.22-1.65,4.16-.83,5.32-1.67,1.11-1.62,2.29-.35,3.52l1.11,1.48c-2.02,3.39-1.91,5.89,0,7.66-1.27,5.08-1.08,9.39,1.03,12.68-2.24,2.16-2.44,4.42-.04,6.78-.76,4.43-.93,7.95,.5,9-1.98,2.69-1.84,5.48,.07,8.34,.81,2.3,.63,4.36-1.49,5.96-1.23,1.24-2.04,2.76-2.11,4.77-1.89,.6-3.99,2.03-3.11,4.46-.52,2.86,2.04,9.1,3.64,16.77-1.62,.75-3,4.42-4.22,10.13-.74,2.55-3.27,3.05-5.68,3.44-8.15-8.37-7.21-17.1-.9-28.07-1.12-4.6-2.5-7.65-4.82-5.27-1.72-6.12-2.53-5.17-3.44-5.01l-1.26-7.18c1.97-3.7,2.29-6.51,.43-8.15-2.27-2.21-1.92-5.39,.09-9.19,.53-3.91-.27-5.88-1.63-7.03,2.1-1.5,1.92-3.25,.56-5.12,2.17-.23,2.38-2.13,1.49-4.97,3.1-4.06,2.78-6.83,.8-8.98,1.99-7.64,1.8-10.61,.7-11.66,2.18-3.65,2.7-6.27,1.52-7.82l.88-1.01c-1.3-6.74-5.06-10.79-9.63-13.93Z"
        />
      </g>
      <g id="shoulder_joint_overlay_R" data-name="shoulder joint overlay R">
        <path
          id="shoulder_joint_1_overlay_R"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.shoulderJointR),
            });
          }}
          class={`zone ${isSelected(selected, Chest.children.shoulderJointR)}`}
          data-name="shoulder joint (1) overlay R"
          d="M27.49,69.96c.64-4.37,1.75-7.97,5.19-7.83,1.7,1.88,3.68,2.32,6.1,.43,.87,1.08,2.29,1.84,4.36,2.21l-2.08,1.28c0,2.05,.56,3.53,2.32,3.77,3.8-.12,7.63,1.06,11.46,2.57,1.12-1.29,2.55-2.5,4.45-3.56,4.08,.02,7.44,1.03,8.28,5.48-.52,2.61,.46,3.44,3.51,1.79,1.53-1.35,2.26-3.27,2.26-5.7,2.95-2.12,5.78-3.76,8.49-4.93l5.7,2.11c-4.98,6.26-8.14,12.47-7.77,18.58-4.84,3.4-7.22,8.14-6.96,14.31-5.55,2.55-8.55,6.89-8.25,13.55-.59,4.1,.05,6.98,1.76,8.81-2.95,.73-5.43,3.72-7.51,8.63-5.93-5.15-10.41-10.69-10.52-17.44-.75-3.4-2.74-5.68-5.84-6.94-2.77-.13-4.71-1.37-5.46-4.25l2.43-1.19c2.53-.78,4.89-2.83,6.93-7.31,2.75-3.04,2.74-6.61-.32-10.78-1.74,.12-3.07-.98-4.07-3.05-3.08-2.48-7.1-2.84-11.86-1.5-1.56-1.78-2.79-3.71-3.07-6.08l.47-2.96Z"
        />
      </g>
      <g id="shoulder_joint_overlay_L" data-name="shoulder joint overlay L">
        <path
          id="shoulder_joint_1_overlay_L"
          data-name="shoulder joint (1) overlay L"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.shoulderJointL),
            });
          }}
          class={`zone ${isSelected(selected, Chest.children.shoulderJointL)}`}
          d="M196.52,66.88l4.19-1.75c2.96,1.56,5.72,3.7,7.81,7.67,1.86,2.73,3.57,3.62,5,1.22-.75-2.26,.24-3.9,2.24-5.19,2.1-.32,4.08-.27,5.84,.48,1.54,1.91,3.03,2.64,4.46,1.88,2.68-1.24,5.69-2.3,9.39-2.97,1.77,.17,2.81-.52,2.12-3.19l-.87-1.49c.21,1.1,1.08,.3,2.74-2.82,1.75,2.5,3.57,2.83,5.45,1.4,2.38-.67,3.72,1.91,3.91,8.11,1.42,2.54,1.4,5.09,0,7.67-4.9-1.58-9.07-.85-12.53,2.12-.9,1.96-1.93,2.92-3.19,2.07-2.59,4.24-3.79,10-.33,11.74,1.16,4.26,2.94,5.53,6.69,6.69l2.58,.8c-.67,3.27-2.76,4.48-5.57,4.67-2.8,.6-4.88,3.17-5.95,8.52-.83,5.96-2.48,10.31-6.02,10.95l-4.41-3.75c2.43-6.66,2.02-12.11-1.52-16.22-1.51-2.27-3.45-4.39-5.77-6.4,.46-9.02-2.76-14.87-8.47-18.54-.93-5.39-3.38-10.01-7.79-13.68Z"
        />
      </g>
      <g
        id="upperarm_with_shoulder_joint_R"
        data-name="upperarm with shoulder joint R"
      >
        <path
          id="upperarm_with_shoulder_joint_1_R"
          data-name="upperarm with shoulder joint (1) R"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.upperArmWithShoulderR),
            });
          }}
          class={`zone ${isSelected(
            selected,
            Chest.children.upperArmWithShoulderR
          )}`}
          d="M19.57,82.29c2.19-1.1,4.19-1.32,6-.74,3.28-3.51,10.58-4.2,17.24-1.15,.2,2.1,1.03,3.28,3,2.78,3.07,3.45,2.91,7.19,.53,11.14-1.32,3.97-4.4,6.49-8.62,8.08-1.64-.46-2.71,.93-3.35,3.72-3.56,23.31-6.72,45.27-7.34,58.92l-16.14,2.27c3.7-17.15,6.92-38.46,9.96-61.33l.03-15.65c-1.95-1.1-2.22-3.96-1.31-8.04Z"
        />
      </g>
      <g
        id="upperarm_with_shoulder_joint_L"
        data-name="upperarm with shoulder joint L"
      >
        <path
          id="upperarm_with_shoulder_joint_1_L"
          data-name="upperarm with shoulder joint (1) L"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.upperArmWithShoulderL),
            });
          }}
          class={`zone ${isSelected(
            selected,
            Chest.children.upperArmWithShoulderL
          )}`}
          d="M253.58,80.47c1.82-.61,3.78,.18,5.75,.74,1.4,3.74,.31,5.65-1.31,8.04-1.39,9.46,1.1,24.1,3.96,39.22l6.54,32.63-18.66,2.82c1.36-22.09-4.03-48.17-5.85-60.94-.71-1.89-1.71-2.2-2.87-1.68-4.57-1.58-6.74-4.72-8.59-8.07-3.56-2.49-1.95-6.78,.7-11.44,1.33,1.51,2.24,.47,2.83-2.48,5.71-3.15,11.46-2.71,17.25,1.16"
        />
      </g>
      {/* <g id="thorax_complete_overlay" data-name="thorax complete overlay">
        <path
          id="Thorax_compete_overlay_1_"
          data-name="Thorax compete overlay (1)"
          class="stroke zone"
          d="M98.36,278.13c9.09-8.59,18.34-17.32,27.96-26.39,7.79-8.31,20.08-9.43,32.95-1.86l30.67,28.25c3.42,3.44,3.94,6.33,0,8.38-32.97,8.72-64.36,7.49-91.57,0-3.74-.69-2.88-3.97,0-8.38Z"
        />
      </g> */}
      <g id="thorax_text" data-name="thorax text">
        <text
          id="thorax_text_1_"
          data-name="thorax text (1)"
          class="svg-text"
          transform="translate(125.22 266.57) scale(1.04 1)"
        >
          <tspan class="svg-text" x="0" y="0">
            T
          </tspan>
          <tspan x="6.27" y="0">
            ho
          </tspan>
          <tspan class="cls-11" x="20.4" y="0">
            r
          </tspan>
          <tspan class="cls-1" x="24.52" y="0">
            ax
          </tspan>
          <tspan class="cls-9" x="-7.83" y="15.36">
            C
          </tspan>
          <tspan x="-.54" y="15.36">
            omple
          </tspan>
          <tspan class="cls-10" x="33.87" y="15.36">
            t
          </tspan>
          <tspan x="38.03" y="15.36">
            e
          </tspan>
        </text>
      </g>
      <g id="himithorax_R_overlay" data-name="himithorax R overlay">
        <path
          id="himithorax_R_1_overlay"
          data-name="himithorax R (1) overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.himithoraxR),
            });
          }}
          class={`zone ${isSelected(selected, Chest.children.himithoraxR)}`}
          d="M87.81,68.27c1.64,.53,3.14,1.09,5.94,1.32,1.46,.93,3.01,1.07,4.64,.64,4.45,1.19,8.65,1.49,12.44,.34,1.01,1.95,3.69,2.84,7.4,3.18,.74-.95,3.3-.8,7.88,.58,.4,2.92,.3,5.24-.4,6.87,5.63,3.31,8.46,8.57,9.63,13.93,.16,4.41-.39,7.75-2.4,8.83-.25,4.16-.47,7.96-.69,11.66,1.68,3.26,2.3,6.32,0,8.83-.79,1.77-1.56,3.49-2.29,5.12,1.02,2.7,.57,4.19-.56,5.12,1.6,2.46,2.11,5.27,.98,8.62-1.33,3.22-1.28,5.85,.16,7.87,2.39-.6-.53,7.42,.91,15,1.81,.16,3.27,.69,2.26,3.96l1.51,1.11c1.67-.98,3.01-.01,3.92,3.56-1.79,5.21-5.36,10.08-9.19,14.9l-.57,2.25,1.56,1.91c1.15,4.44,1.08,7.96,0,10.72l2.05-.21c1.43,1.39,1.02,2.48,0,3.48l-3.61,1.98-1.12,3.73c1.56,2.35,2.42,5.04,2.75,7.98-9.01,1.28-20.22,7.01-31.88,13.65-7.56,5.82-12.44,12.56-14.75,20.19l-7.16,9.42c-1.9,6.04-2.87,11.01-1.54,13.31-2.55-.5-3.84-3.88-3.77-10.32l-3.35,.04c-5.57-2.06-6.56-8.78-5.14-17.96l-5.75-6.18c-4.03-9.84-3.1-16.36-1.07-22.15-4.88-8.29-3.27-16.89,.66-25.59-5.87-5.94-4.03-15.42,.53-26.16-2.76-5.81-.6-13.09,3.35-20.91-4.41-4.11-4.09-11-1.14-19.45,1.87-4.15,4.16-5.59,6.63-5.91-4.94-15.79-.03-19.74,6.1-22.21,0-7.13,2.55-11.77,6.91-14.66,.05-7.22,2.88-13.3,8.15-18.39Z"
        />
      </g>
      <g id="himithorax_overlay_L" data-name="himithorax overlay L">
        <path
          id="himithorax_overlay_1_L"
          onClick={() => {
            bpartSetter({
              id: idToSet(Chest.id, Chest.children.himithoraxL),
            });
          }}
          class={`zone ${isSelected(selected, Chest.children.himithoraxL)}`}
          data-name="himithorax overlay (1) L"
          d="M157.39,74.41l8.72-1.12c2.36-.34,4.47-1.02,5.06-3.66l13.64-1.27c2.33,.52,4.4,.61,6.05,0,2.09-.17,3.72-.62,4.5-1.55,4.37,2.28,7.24,7.29,9.28,13.78,5.52,4.73,8.79,9.88,8.16,15.75l-.42,2.25,6.19,7.45c3.18,3.38,3.24,8.71,1.69,15.05,2.73,2.5,4.77,5.1,4.78,8.02,2.76,3.59,1.44,10.39-.55,16.9l4.91,8.13c1.12,3.72,1.26,8.11-.28,13.64,2.2,5.11,3.62,10.68,3.52,17.16l-2.25,4.92,2.67,5.91c1.25,4.23,.46,10.45-1.27,17.58-1.44,2.09-2.68,3.62-3.66,4.36,2.57,6.82,1.63,13.85-.56,20.95l-1.41,3.8c1.17,10.45-1.5,17.38-6.61,22.08l-6.89-.7c-.75,3.89-2.88,6.6-6.05,8.44,1.06-3.65-.05-7.31-1.97-10.97-8.07-4.8-14.18-15.29-20.25-25.88-8.63-7.56-18.53-12.11-29.39-14.34-.14-4.84-1.01-9.2-1.97-13.5l-2.25-.84c-1.01-.76-.87-2.1,0-3.8,0,0,1.92-.76,.98-.84-1.66-.14,.33-9.25,1.12-9.7l-2.11-1.83-1.27-7.45c-1.9-2.45-2.8-5.29-2.53-8.58,.48-2.76,1.8-3.77,3.8-3.38-.88-2.12-.05-4.22,1.41-6.33,1.15-1.37,1.68-3.32,1.55-5.91-1.21-2.51-1.59-5.22-.42-8.3-.94-2.54-.39-5.45,.98-8.58-2.97-1.21-3.08-4.13-1.83-7.88-1.47-3.98-1.36-7.87,.56-11.67-2.68-1.03-2.75-4.01-.7-8.58-1.99-.78-1.7-2.65-.84-4.78-.69-1.39-.22-2.46,.84-3.38-1.59-2.08-1.86-4.83-.98-8.16l-.42-1.97c.64-2.25,1.5-4.73,1.83-7.59,1.63-1.6,3.4-4.04,5.34-7.59-1.82-1.8-2.34-4.11-.7-6.05Z"
        />
      </g>
    </svg>
  );
}

export default FrontView;
