import { useContext, useEffect, useState } from "react";
import { useExaminationsInfinite } from "../../../Utilities/FetchHooks/Ris/RisHooks";
import { ClipLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import BaseSearch from "../../../Blueprints/SearchBars/BaseSearch/BaseSearch";

import styles from "./ExamSection.module.scss";
import { ExamPlannerExaminationImage } from "../../ExamPlannerV3/Components/ExamPlannerImage";
import useWindowSize from "../../../Utilities/Window/windowSize";
import { ExamHelperContext } from "../../../Pages/ExamHelper";

const PAGE_SIZE = 20;
const LABEL_ACCESSOR = "designationde";
const ABBR = "abbrde";

export default function ExamsSection({ selectedExam, selectedExamSetter }) {
  const [examsFilter, setExamsFilter] = useState(undefined);
  const { data, isLoading, isError, refetch, fetchNextPage, hasNextPage } =
    useExaminationsInfinite({
      pageSize: PAGE_SIZE,
      filter: examsFilter,
      sort: { field: "designationde", direction: "asc" },
    });
  const [exams, setExams] = useState([]);
  const { width } = useWindowSize();
  const { examListShrinked, setExamListShrinked } =
    useContext(ExamHelperContext);

  useEffect(() => {
    if (width < 1000 && !examListShrinked) {
      setExamListShrinked(true);
    }
  }, [width]);

  useEffect(() => {
    if (!data) return;
    if (!data.pages) return;
    let exams = [];
    data.pages.forEach((page) => {
      if (page?.examinations) {
        exams = exams.concat(page.examinations);
      }
    });
    setExams(exams);
  }, [data]);

  return (
    <div
      className={`${styles.ExamSection} ${styles.border} ${
        examListShrinked ? styles.closed : ""
      }`}
    >
      {!examListShrinked ? (
        <>
          <div className="border-bottom d-flex mb-2 p-1">
            {width > 768 && <h1 className="text-white">Exams</h1>}
            <BaseSearch
              field={[LABEL_ACCESSOR, ABBR]}
              filterSetter={setExamsFilter}
              className={`${width > 768 && "ml-auto"}`}
            />
            <div className="my-2" title="Shrink window">
              <Button onClick={() => setExamListShrinked(!examListShrinked)}>
                <i className="fas fa-chevron-left" />
              </Button>
            </div>
          </div>
          {isLoading && <ClipLoader size={50} />}
          {isError && (
            <>
              <h3>Unable to load examinations</h3>
              <Button onClick={refetch}>Reload</Button>
            </>
          )}
          <div className={styles.ExamListWrapper}>
            {!isLoading &&
              exams.map((exam) => (
                <div
                  onClick={() =>
                    selectedExamSetter(
                      exam.id === selectedExam ? null : exam.id
                    )
                  }
                  className={`${styles.ExamRow} ${
                    exam.id === selectedExam && styles.selected
                  }`}
                  key={`${exam.id}_${exam[LABEL_ACCESSOR]}`}
                >
                  <ExamPlannerExaminationImage
                    examId={exam?.id}
                    style={{
                      width: "50px",
                      maxHeight: "50px",
                      marginRight: "10px",
                    }}
                  />
                  {exam[LABEL_ACCESSOR]}
                </div>
              ))}
            {!isLoading && exams.length === 0 && (
              <>
                <h3>Unable to load examinations</h3>
                <Button onClick={refetch}>Reload</Button>
              </>
            )}
            <Button disabled={!hasNextPage} onClick={fetchNextPage}>
              Load Next
            </Button>
          </div>
        </>
      ) : (
        <div className={styles.shrinkedHeader}>
          <Button onClick={() => setExamListShrinked(!examListShrinked)}>
            <i className="fas fa-chevron-right" />
          </Button>
          <div className={styles.headerText}>Exam List</div>
        </div>
      )}
    </div>
  );
}
