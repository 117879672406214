import style from "./style.scss";

function RechtesKahnbeinDaumenReinDpSitzend1350(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 435 302">
      <g id="background">
        <path
          id="background_white"
          data-name="background white"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls2"
          d="M79.2,60.4c.4.2.7.6.7,1.1v16.4c0,.4-.1.7-.3,1.1.1.3.2.7.4,1.4,0,.2,1.2,25.4,1.2,25.6.4.4,2.1,9.8,2.2,10.2,1.9,8.5,3.2,14.4,7.5,22,.1.2.1.6,0,.9.1.1.2.3.3.4,2,3.8,4.2,7.4,6.3,11.1,0,0,.1,0,.1,0,.2.3.2.4.2.4,1,1.8,2.1,3.6,3.1,5.5,1,1.4,1.8,2.9,2.5,4.4,6.4,7.1,13,14.2,19.8,21.3,2,2.4,10.7,9.1,18.4,15.3,0,0,.1.1.2.1,7.5,4,10.7,12.2,12.9,21.3,3.4,12.2,5.4,30.6,6.8,51.1,0,1,.9,1.7,1.8,1.6l135.6-6.1c1.1,0,1.8-1,1.6-2-1.5-8.5.3-18.3-.3-26.5-1.2-33-6.5-66.5.4-73.6.2-.2.3-.3.4-.5,12-27,17.2-67.2,20.7-110.8,0-.2,0-.5.2-.7,0,0,0,0,0,0,.6-1.2-.2-2.6-1.5-2.6-38.6-.1-77.2-.5-115.8-1-24-.3-47.9-.6-71.9-.9-.2.2-.6.5-.9.5h-10.9c-.3,0-.5,0-.7,0-7-.2-14.1-.4-21.1-1-.1,0-.3.1-.5.1h-17.8c-.8,4.3-1.3,8.9-1.6,13.6h0Z"
        />
      </g>
      <g id="handparts">
        <g id="outlines">
          <path
            id="outlinepart7"
            class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
            d="M300.1,250.8c.2,1.7.5,13.3.9,14.9"
          />
          <path
            id="outlinepart6"
            class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls3"
            d="M322.3,51.2c-.6,1.8-1.6,3.8-1.6,5.8-2,33-5,66-16,97.9,0,0,0,0,0,.1-3,6.9-6.9,14.9-7.9,22.8,0,0,0,.2,0,.3,0,24,5,48.9,3.4,72.7"
          />
          <path
            id="outlinepart5"
            class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls3"
            d="M160.9,262.1c-3.2-20.1-2.2-41.1-12.2-59.1-14-11-31.6-25.3-43.3-39.7"
          />
          <path
            id="outlinepart4"
            class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls3"
            d="M108.2,165.6c.2,4.1-15.8-22-17.4-26.6"
          />
          <path
            id="outlinepart3"
            class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls3"
            d="M91.2,139.5c-18.1-29.9-17.9-75.3-15.5-91"
          />
          <path
            id="outlinepart2"
            class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls3"
            d="M81.6,48.3"
          />
          <path
            id="outlinepart1"
            class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
            d="M161.6,271.9c-.1-1.7-1.1-12-1.3-13.7"
          />
        </g>
        <path
          id="bonepart25"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M147.1,47.4c.3.4.5.9.7,1.4l15.4,36.2c6.7,18.7,8.8,35.9,1.4,49.8-.8,2.7-.5,5.3.6,7.9,4.4,9.8,9.5,13.9,15.2,11.7.9-.4,1.7-1.1,2.3-1.9h0c2.4-3.8,4.9-6.9,7.6-7.9,5.7-2,10.7-1.4,14.9,2.1,1.2,1,3,1,4.3,0,2.6-2,3.3-3.7,2.1-4.9-6.3-6.8-10.9-14.8-13.3-24.3-3.4-1.4-6.3-3.2-8-5.8-10.1-22.3-15.6-43.5-17.6-63.9"
        />
        <path
          id="bonepart24"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M201.1,48c1.1,2.3,2,4.9,2.7,7.7,4.4,15.6,6.5,33.1,2.3,55.9-4.3,7.2-4.4,14.1,1.2,20.5,3.1,6.7,9,7.9,16.6,5.9h0c6.1-1.3,10.6-3.8,14.1-7.1,1.7-1.6,2.3-4,1.8-6.3-1.4-5.9-3.3-11.4-5.7-16.6-.4-.8-1-1.4-1.7-1.9-2.9-1.9-5.1-6.3-6.9-12.1-1.7-16.5-3-32.2-3.4-45.9"
        />
        <path
          id="bonepart23"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M209.7,129.5l.5-4.1c.1-1.2-.2-2.4-1-3.3h0c-1.2-1.6-1.7-3.6-1.2-5.5.3-1.2.9-2.3,1.6-3.4.6-.9,1-1.9,1.1-3l.9-9.3"
        />
        <path
          id="bonepart22"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M230.2,104.1c1.5,4.2,2.6,8.4,2,12.1"
        />
        <path
          id="bonepart21"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M238.2,48.5c.6,1.8,1.2,3.5,1.9,5.3,1.1,17.5,1.6,33.8,0,46.4-4.1,7.1-6.4,16.5-6.3,29.4h0c.5,6.2,3.1,9.7,9.5,8.4l22.9-7.7c4.5-1.8,4.3-5.4,1.4-9.9-2.5-6.2-5.5-10.2-8.7-12.3-1.4-.9-2.2-2.6-2.2-4.3v-32.9c-.1,0,2.5-22.1,2.5-22.1"
        />
        <path
          id="bonepart20"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M257.1,106c.5,2.8,1.1,5.8,2.9,7.2"
        />
        <path
          id="bonepart19"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M280,48.8c.4,6.5.2,12.8-.9,18.8-5.1,18.1-10.3,35.4-17.5,44.1-2.9,3-3.6,6.3-2.7,9.8-5,6-6.4,11.7-2.4,16.7,2.4,3.4,8.7.8,13.3,4.6h0s.6-.4.6-.4c6.3,1.3,11.3,0,12.6-8,3.3-3.3,3.9-8.8,3.9-14.8l-1.4-8.2c2-24.6,16.4-48,17.8-62.5"
        />
        <path
          id="bonepart18"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M173.2,149.4h0c1.5.4,2.8,1.3,3.8,2.4l6.2,7.2c2.5,3.1,4,7.7,4.1,14.4-3.4,4.2-6,8.3-7.3,12.4-.4,1.2-1.5,1.9-2.7,1.9-3.5,0-6.6.4-9,2.1-3.4.1-6.2-.7-7.9-3.4-.4-.6-.6-1.3-.6-2-.3-3.3-4.3-6.4-2.7-9.2,5.8-4,6.9-10.4,6.5-17.7.1-6.7,2.9-9.9,9.7-7.9h0Z"
        />
        <path
          id="bonepart17"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M164.1,163.8c1.6,6.5,3.7,10.8,6.7,10.4,5.6,1.2,7.7-1.1,8.3-4.9.7-3.4-1.6-6.9,0-10.7"
        />
        <path
          id="bonepart16"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M204.7,149.5h0c.7.7.9,1.7.7,2.6-.7,2.9-.8,7.4-.8,12.2,0,1.1-.8,2.1-1.9,2.3-3.9.7-7.8,3.1-11.7,6.6-.9.8-2.3,1-3.4.3-2-1.3-3.6-4.1-4.4-9-.7-6.9.9-12.9,8.1-17.2,5.4-2.6,9.7-1.6,13.3,2.1h0Z"
        />
        <path
          id="bonepart15"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M215.5,181.8h0c3.9,2.4,6.9,6.5,9.1,12.1,1.4,2.9,2.6,6.1,3.6,9.4.4,1.3.5,2.7.3,4-.4,3-2.2,5.3-5.5,6.6-1.9.8-4,1.1-6,1-8.7-.3-17.3-3.4-25.5-12.1-1.1-1.1-2.2-2.2-3.5-3.1-3.5-2.5-4.7-5.3-2.8-8.4.6-1,.8-2.2.5-3.3-1-2.9,1.3-6.5,6-10.6.5-.4,1-.9,1.4-1.4,3.6-4.3,8.7-5.9,15.2-5.2,2.2,0,3.7,2.3,4.4,6.8.3,1.7,1.3,3.2,2.8,4.2h0Z"
        />
        <path
          id="bonepart14"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M207.7,170.7v6.2c.7,3.3,2.5,4.5,5.5,3.7.2,0,.3.2.2.3-2.3,2.8-2.8,5.6.8,8.8,3.2,4.1,7.2,5.9,11.5,6.8"
        />
        <path
          id="bonepart13"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M247.5,172.6h0l-4.4-14.3c-.4-1.3-.5-2.6-.4-4l.6-7.4c1.9-11.1.7-10.3,0-11.6-2.5-2.3-7.1-1.7-14.9,3.7-1.3.9-2.8,1.3-4.3,1.3-5.1-.2-9.1,1.2-10.2,6.5-.2,1-.8,1.9-1.7,2.6-2.6,2.2-4.3,4.8-4.3,8.2,0,2.5,1.5,4.7,3.8,5.8l.6.3c1.9.9,3.3,2.8,3.2,4.9,0,1.2-.4,2.3-1.4,3.2-2.8,2.2-2.5,5.3-1.1,8.7.2,3.5,1.7,6.5,5.5,8.4,7,3.1,15,2.8,23.5.7,3.4-.4,5.3-3,6.1-7.2.6-3.3.3-6.7-.6-9.8h0Z"
        />
        <path
          id="bonepart12"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M276,135.4h0c-1.3-1.6-3.4-2.4-5.5-2.1l-25.4,4c-3.6,1.3-5.5,6.8-4.2,20,.2,7.5,1.1,14.5,2.9,20.7.1.4.3.8.5,1.2,2.2,4.7,5.2,6.5,9.2,4,1.1-.7,1.9-1.8,2.4-3,3.6-9.4,9.5-15.1,16.4-19.1,1.1-.6,1.8-1.8,2.1-3,.9-4.7,4.9-8.8,4.9-16.7-.7-2-1.9-4-3.4-5.9h0Z"
        />
        <path
          id="bonepart11"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M263,151.1c-3.9,1.9-3,5.9-5.9,11.6-2.9,1.7-5.4,0-7.7-3.3-1.5-2.2-2.4-4.7-2.6-7.4l-.4-4.5c-.1-1.3-.5-2.6-1.1-3.8-.8-1.7-.7-3.1.9-4.2,2.5-2.3,4.9-1.7,7.2,1.4,3.5,2.5,7,3.2,10.6,2.9"
        />
        <path
          id="bonepart10"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M283.3,190.5h0c-1,5.1-3.2,9.9-8.2,13.9-2.5,1.8-5.5,2.3-9,2-2.9-.3-5.5-2.1-6.7-4.8-1.5-3.2-3.3-5.3-5.4-6.3-3.5-3.1-4.3-5.2-2.3-6.1,5.9-2.2,8.5-6.9,10.8-12,2.2-6.8,6.4-9.5,11.7-9.8,5.7.3,9.3,2.8,11.4,7.2,2.3,3.8,2.7,7.4.2,10.5-1.3,1.6-2.1,3.5-2.5,5.5h0Z"
        />
        <path
          id="bonepart9"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M280.4,189.7c0,2-.9,3.9-2.5,5.2-2.8,2.3-5.8,3.1-9.1,2.7-4.7-.5-8.9-3.2-11.5-7.1-2-3.1-3.5-6.9-4.7-11.1,0-3.6.9-5.4,2.4-6.4.9-.6,2.1-.8,3.2-.7,5.6.5,10.8,2.5,15.7,5.6,2.4,1.5,4.3,3.6,5.4,6.2.7,1.7,1,3.5,1,5.5,0,0,0,0,0,0Z"
        />
        <path
          id="bonepart8"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M231.9,217.7h0l9.2,1.3c3.8.2,8-1.1,12.5-3.9,4.9-2.4,4.3-5.6.4-9.1-.6-3.1-1.5-5.9-2.7-8.1-1.3-2.4-3.9-3.9-6.7-3.9s-6.2.7-8.9,2.6l-7,.4c-1.7.1-3.1,1.4-3.2,3.1-.5,4.3-.1,8.1,1.6,11.1-.2,4.3.9,7.2,4.8,6.6h0Z"
        />
        <path
          id="bonepart7"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M228.7,196.9c.6-1.3,1.8-2.5,3.6-3.7,3.1-2,7-2.7,10.5-1.4,1.7.7,3.4,1.7,5.1,2.9"
        />
        <path
          id="bonepart6"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M233.8,269.3c6.7-8.2,11.1-16.3,13.6-24.5,3.1-15.4-.2-21.2-6.8-22.1l-36.7-7.2c-6.7-2.7-14.7-8.9-23.3-16.9-1.5-1.4-3.9-1.4-5.4,0-4,3.8-4.8,9.9-3.4,17.6,3.8,8.8-2.7,16-.8,33.2.1,6.6,2.3,13.5,5,20.4"
        />
        <path
          id="bonepart5"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M257.1,267.7c0-6.9-1.1-13.1-3.2-18.7-.7-1.9-1.2-4-1.2-6v-8.5c0-2.5,1.4-4.9,3.8-5.9,3-1.3,7-1.6,11.7-1.4,2.8.1,5.4-1.4,6.6-3.8,3.6-6.8,5.1-12.5,9.2-10.4,3.4,1,5.2,5.7,5.7,13.7,1.7,5.1.5,12.3-2.8,21l-.3,19.1"
        />
        <path
          id="bonepart4"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M257.1,228.3c11.1,2.6,19.6,8.4,26,5.4,6.6-1.2,9.5,1.3,9.9,6.4,1.5,6.4-1.8,15.4-5.5,24.4"
        />
        <path
          id="bonepart3"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M203.9,215.5c9.5,7.4,33.9,6.6,36.7,12,4.4,7.4,2.4,16.5-1.5,26"
        />
        <path
          id="bonepart2"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M168.7,169.1h0s-11.5,2.6-11.5,2.6c-1.2,5.1-4.8,8.1-12.4,7.4-5.2.9-9.6-2.7-13.4-9.9-9-21.4-19.8-40.8-33.3-57.1-4.3-4.1-5.9-7.8-4.2-11,3.2-10.8,7.7-17.3,14.3-17.5,6.2-2.3,12-1.2,17.6,2.1,4.9,2.2,5.4,7.7,2.1,16.3-1.6,4.2-1.7,8.9.1,13.1,5.1,11.6,14.7,20.9,27.1,28.7,5.4,3.3,10.1,6.9,12.6,11.2,4.6,5.2,5.6,9.9,1.1,14.1h0Z"
        />
        <path
          id="bonepart1"
          class="Rechtes_Kahnbein_Daumen_rein_dp1350_cls4"
          d="M104.4,45.6c-2.2,9.7-3,19.2-10.7,31.7-.4,2-2,4.8-.3,7.2,1,.2.9,1.2,1.9,1.4,10.1-2.2,17.9,2.3,27.3,4.1,3.8.7,5.3-1.1,6-3.2s.6-4.3,0-6.4c-3.2-10.2-6.8-20.4-5.1-30.7"
        />
      </g>
      <g id="kahnbeinhighlight">
        <path
          class="Kahnbein1350Fill"
          style={{
            fill: props.colors.Kahnbein1350Fill,
            stroke: props.colors.Kahnbein1350Fill,
          }}
          d="M215.2,181.8h0c3.9,2.4,6.9,6.5,9.1,12.1,1.4,2.9,2.6,6.1,3.6,9.4.4,1.3.5,2.7.3,4-.4,3-2.2,5.3-5.5,6.6-1.9.8-4,1.1-6,1-8.7-.3-17.3-3.4-25.5-12.1-1.1-1.1-2.2-2.2-3.5-3.1-3.5-2.5-4.7-5.3-2.8-8.4.6-1,.8-2.2.5-3.3-1-2.9,1.3-6.5,6-10.6.5-.4,1-.9,1.4-1.4,3.6-4.3,8.7-5.9,15.2-5.2,2.2,0,3.7,2.3,4.4,6.8.3,1.7,1.3,3.2,2.8,4.2h0Z"
        />
      </g>
    </svg>
  );
}

export default RechtesKahnbeinDaumenReinDpSitzend1350;
