import style from "./style.scss";

function LinkesOberesSprunggelenkStressaufnahmeVdLiegend1465(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 423.03 474.85">
 
 <g id="upper_ankle" data-name="upper ankle">
   <g id="background_layer" data-name="background layer">
     <path id="background" class="oberes_Sprunggelenk1465_Stressaufnahme_vd_links_cls3" d="M110.31,23.52C84.18,139.3,50.7,243.48,6.79,331.19c-14.14,28.24-5.64,62.75,20.48,80.52,5.92,4.02,12.5,7.57,19.82,10.59,11.45,4.72,17.29,17.5,13.36,29.24l-1.4,4.18c-3.11,9.27,3.79,18.86,13.57,18.86h215.23c23.58,0,46.65-6.88,66.35-19.84,1.71-1.12,3.4-2.27,5.08-3.45,34.23-23.98,51.07-65.92,43.15-106.96l-18.75-97.14c-3.37-17.49-4.36-35.37-2.81-53.11,5.41-61.89,19.61-118.36,40.88-170.47C426.35,12.33,418,0,405.82,0L139.53.17c-14,0-26.14,9.7-29.22,23.35Z"/>
   </g>
   <path id="ulnar" class="oberes_Sprunggelenk1465_Stressaufnahme_vd_links_cls5" d="M151.5,8.08c-27.4,125.1-61.1,229.4-105.1,299.4-14,20.8-24,40.3-25.4,57-1.6,19,13.5,35.3,32.5,35.7,17.8.4,34.3-4.6,48.5-19.4,25.4-28,47.8-67.7,68.2-115.9,10.8-38.6,17.2-73.8,13.6-101.1,1.9-73.8,12.8-118.2,26.2-154.6"/>
   <path id="radius1" class="oberes_Sprunggelenk1465_Stressaufnahme_vd_links_cls5" d="M263.5.18c-20.8,66.9-48.1,127.7-86.4,177.8-50.8,49.1-62,98.8-35.8,148.9,18.4,15.7,42.7,22,74.4,16.5,24.8-3.4,48.1-2.2,65.9,17,11.5,14.2,17.4,35.4,17.6,63.6,6.9,12,15.9,16,29,5.1,29.8-4.3,48-25.5,43.2-80.1-23.8-114.4-11.9-228.8,22.7-343.2"/>
   <path id="tasus" class="oberes_Sprunggelenk1465_Stressaufnahme_vd_links_cls5" d="M103.8,474.58c11.5-18.3,14.4-38.5,10.2-60.2-4-27.6,2.1-47.5,22.7-56.2,40.5-6.7,79.4-9.8,115.9-8,13.6,2.2,22.2,10.8,23.9,27.8l8,19.3c12.6,8,13,33.2,11.4,61.4"/>
   <path id="radius2" class="oberes_Sprunggelenk1465_Stressaufnahme_vd_links_cls5" d="M251.6,110.48c-15.6,57-47.2,105.2-65.4,139.6-9.6,22.3-17,43.8,15.3,52.8,13.7,1.4,22.9,4.4,26.2,9.7,2.6,4.2,5.3,8.3,8.9,11.8,11.2,10.9,23.7,14.9,37,15.8,7.9.6,15.2,4.6,19.8,11.1,4.8,7,8.7,16.3,12.1,27.2"/>
 </g>
 <g id="overlay">
   <path id="tibia_overlay" data-name="tibia overlay" class="Tibia1465Fill" style= {{
             fill: props.colors.Tibia1465Fill,
             stroke: props.colors.Tibia1465Fill,
             opacity:props.colors.Tibia1465Fill,
           }} d="M263,.13c-20.8,66.9-48.1,127.7-86.4,177.8-50.8,49.1-62,98.8-35.8,148.9,18.4,15.7,42.7,22,74.4,16.5,24.8-3.4,48.1-2.2,65.9,17,11.5,14.2,17.4,35.4,17.6,63.6,6.9,12,15.9,16,29,5.1,29.8-4.3,48-25.5,43.2-80.1-23.8-114.4-11.9-228.8,22.7-343.2"/>
   <path id="talus_overlay" data-name="talus overlay" class="Talus1465Fill" style= {{
             fill: props.colors.Talus1465Fill,
             stroke: props.colors.Talus1465Fill,
             opacity:props.colors.Talus1465Fill,
           }} d="M103.8,474.58c11.5-18.3,14.4-38.5,10.2-60.2-4-27.6,2.1-47.5,22.7-56.2,40.5-6.7,79.4-9.8,115.9-8,13.6,2.2,22.2,10.8,23.9,27.8l8,19.3c12.6,8,13,33.2,11.4,61.4"/>
   <path id="Opening_angle_overlay" data-name="Opening angle overlay" class="OpeningAngle1465Fill" style= {{
             fill: props.colors.OpeningAngle1465Fill,
             stroke: props.colors.OpeningAngle1465Fill,
             opacity:props.colors.OpeningAngle1465Fill,
           }} d="M114,414.38c-21.35,1.35-39.16-4.44-55-14.18,40.22-3.96,55.71-31.3,81.8-73.38,17.15,15.85,41.71,21.67,74.4,16.5,37.53-5.03,61.37,4.44,72.62,27.28,9.41,15.18,11.9,33.47,10.88,53.32l-2.13,16.37c.78-18.82-1.8-34.5-12.07-43.02l-8-19.3c-1.19-17.94-11.24-24.54-23.9-27.8-39.01-1.61-77.65.96-115.9,8-21.29,10.24-27.19,30-22.7,56.2Z"/>
 </g>
</svg>
  );
}

export default LinkesOberesSprunggelenkStressaufnahmeVdLiegend1465;
