import style from "./style.scss";

export default function BeideKnieVdStehend2363(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 713.59 692.39"
    >
      <g id="underlay">
        <g id="joint_underlay" data-name="joint underlay">
          <ellipse
            id="joint_gap_underlay"
            data-name="joint gap underlay"
            class="Gelenkspalt2363Fill"
            style={{
              fill: props.colors.Gelenkspalt2363Fill,
              stroke: props.colors.Gelenkspalt2363Fill,
            }}
            cx="531.45"
            cy="315.1"
            rx="134.98"
            ry="50.62"
            transform="translate(-9.71 16.98) rotate(-1.81)"
          />
          <ellipse
            id="right_joint_gap_underlay"
            data-name="right joint gap underlay"
            class="Gelenkspalt2363Fill"
            style={{
              fill: props.colors.Gelenkspalt2363Fill,
              stroke: props.colors.Gelenkspalt2363Fill,
            }}
            cx="185.68"
            cy="304.91"
            rx="50.62"
            ry="134.98"
            transform="translate(-124.61 481.46) rotate(-88.3)"
          />
        </g>
      </g>
      <path
        id="left_unterschenkel"
        data-name="left unterschenkel"
        class="beide_Knie_vd_stehend2363_cls4"
        d="M465.25,675.2c-1.9-5,.8-7.3,2.2-20.4,1.4-14.5-1.9-18.7-2.2-34.4-.2-14.7,2.2-13.4,2.2-26.9,0-16.1-3.4-20.6-7.5-45.2-4.1-24.3-.9-21.8-4.3-34.4-5.9-21.6-22.6-45.2-22.6-45.2-23.4-33.4-21.8-28-28-38.7-16.3-28.4-17.2-50.6-17.2-50.6-.5-10.3,1.2-17.3,3.2-22.6,2.9-7.3,6.2-10.4,7.1-11.2,5.1-4.5,11-5.5,14.4-6,27.7-4.2,44.1-5.4,44.1-5.4,9.5-.7,19-1.4,29.1-5.4,8.3-3.2,10.9-6.3,11.8-7.5,3.5-4.5,3.2-8.5,6.5-9.7.8-.3,2.1-.3,10.8,3.2,6.4,2.6,7.3,4.2,10.8,4.3,4,.1,5-2,10.8-4.3,3.7-1.4,10.3-4,16.1-2.2,5.6,1.8,4.9,6,12.9,12.9.8.7,7.4,6.5,15.1,8.6,9.2,2.6,13.7-1.8,29.1-5.4,12.9-3.1,25.3-6,37.7-1.1,10.9,4.3,16.9,12.7,19.4,16.1,1.4,2,11.7,16.6,9.7,35.5-1.4,13.8-8.7,23.1-5.4,25.8,2.6,2.1,7.3-3.3,12.9-1.1,1.2.5,5.6,2.5,9.7,16.1,3.6,11.9,1.7,14.9,4.3,24.8,3.1,11.6,7.2,12.5,10.8,22.6,1.6,4.5,4.1,13.7,1.1,32.3-3.4,21.4-10.2,31.3-21.5,58.1-4.4,10.4-16.2,38.8-22.6,66.7-5.5,24.2-8.3,36.3-7.5,42,.3,2.2.9,5.6-1.1,8.6-6.1,9.3-32,6.9-35.5-2.2-1.8-4.5,3.6-6.9,6.5-18.3,2.5-9.9-.2-13.2,0-28,.2-15.8,3.3-14.4,3.2-28,0-5.6-.2-18.8-6.5-33.4-4.9-11.4-7.7-10.2-9.7-18.3-2.1-8.6.6-12.2,3.2-32.3,1.3-10.2,3-23.1,1.1-23.7-2.2-.6-8.7,14.9-12.9,26.9-4.9,14.1-8.8,29.6-12.9,61.3-4.3,33.4-6.5,50.1-6.5,67.8,0,23.3,2.4,29.6-3.2,36.6-8.4,10.6-25.8,9.7-60.3,7.5-30.5-1.6-53.6-3.3-58.4-15.8Z"
      />
      <g id="Femur">
        <path
          id="femur_20"
          data-name="femur 20"
          class="beide_Knie_vd_stehend2363_cls4"
          d="M671.15,245.2c-1.6-11.5-4.3-11.3-7.9-28.6-3.5-17.2-1.2-19.5-4.3-35.5-3.2-16.8-8.6-29.3-11.8-36.6-8.5-19.3-12.6-19.3-18.7-34.3-1.2-2.9-2.1-5.4-2.9-8-5.4-18.1-6.4-38.4-6.4-38.4-1.3-30-4.5-36.6-7.8-52h-154.8c-2.2,1.5-4,3.4-5.3,5.7h0c-1.8,3.3-.6,3.8-3.2,15.1-3.6,15.2-8.6,26.1-12.9,35.5-8.4,18-18.5,42-41.1,62.1-6.8,6.1-13.5,10.7-19.9,21-2.9,4.6-10.7,17.4-10.4,34.1.1,2.7.3,7.1,6.7,25.5,12.3,35.7,18.7,40.3,16.3,53.1-1.2,6.2-3.7,10.6-3.4,19.8.2,4.5.3,11.7,4.6,17.9,7.1,10.3,20.9,11.8,24.5,12.1,18.6,2,33.9-8.4,44.2-15.4,17.7-12.1,19.5-20.6,36.9-27.2,10.6-4,17.7-3.9,20.7-3.6,12.6.9,21.4,7.4,25.8,10.4,30.4,21,68.6,23.5,77,23.8,15.8.6,33.9,1.4,45.2-10.9,13.3-14.4,9.8-39,8.9-45.6Z"
        />
        <path
          id="femur_19"
          data-name="femur 19"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M451.25,17.5c-11.4,50-37.2,95.2-74.8,130.7-2.2,2.2-3.2,5.4-5.4,7.5-6.5,10.8-9.7,21.5-7.5,34.4,3.2,17.2,10.8,31.2,18.3,46.3,4.3,9.7,7.5,20.4,5.4,31.2-2.2,7.5-4.3,14-4.3,20.4,2.2,25.8,33.4,31.2,53.8,22.6,2.2-1.1,4.3-2.2,7.5-3.2,2.2-1.1,5.4-2.2,7.5-4.3,15.1-12.9,29.1-26.9,47.3-34.4,5.4-2.2,11.8-2.2,17.2,0,39.8,15.1,79.6,37.7,124.8,32.3,16.1-2.2,29.1-14,31.2-30.1,1.1-8.6,1.1-17.2-2.2-25.8v-4.3c-5.4-18.3-9.7-37.7-8.6-57-6.5-29.1-24.8-53.8-36.6-80.7-1.1-4.3-4.3-8.6-4.3-12.9-2.2-24.8-3.2-49.5-8.6-74.3"
        />
        <path
          id="femur_18"
          data-name="femur 18"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M522.25,229.5c-8.1.8-9.1-.8-39.8-6.5-23.9-4.4-24.9-3.8-30.1-6.5-15.3-7.9-22.6-21.9-26.9-30.1-5.5-10.6-12.2-23.5-9.7-39.8,3.7-23.7,24.4-36.9,29.1-39.8,5.4-3.4,5.1-2.1,30.1-11.8,27.1-10.5,27.3-12.1,34.4-12.9,21.5-2.6,39.1,9.2,43,11.8,22.5,15.1,28.6,38.1,30.1,44.1,1.6,6.6,5,24-2.2,44.1-2,5.7-7.8,21.1-23.7,33.4-15.4,12-31.3,13.7-34.3,14Z"
        />
        <path
          id="femur_17"
          data-name="femur 17"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M663.25,216.6c18.3-18.8,0-14,0-37.7"
        />
        <path
          id="femur_16"
          data-name="femur 16"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M642.85,192.9c0,1.8-16.4-11.8-51.7,3.2"
        />
        <path
          id="femur_15"
          data-name="femur 15"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M442.65,186.4c-2.9-.3-31.3-.3-60.3,1.1"
        />
        <path
          id="femur_14"
          data-name="femur 14"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M459.85,11.8c-.4,18.5-3.1,37.3-9.4,54.8-4.5,12.4-11,25.5-21.9,33.6-4.7,3.5-10.2,5.8-16.1,5.8"
        />
        <path
          id="femur_13"
          data-name="femur 13"
          class="beide_Knie_vd_stehend2363_cls4"
          d="M349.85,174.2c.4-16.8-7.5-29.5-10.4-34.1-6.5-10.3-13.1-14.9-19.9-21-22.6-20.1-32.7-44.1-41.1-62.1-4.3-9.3-9.3-20.2-12.9-35.5-2.6-11.2-1.4-11.7-3.2-15.1h0c-.9-1.7-2.1-3.1-3.6-4.4-28.4-.4-56.7-1.2-85-2-23.9.9-47.8,1.7-71.8,2-3.2,14.4-6.2,21.6-7.5,50.7,0,0-1,20.3-6.4,38.4-.8,2.6-1.7,5.2-2.9,8-6.1,14.9-10.2,14.9-18.7,34.3-3.2,7.3-8.6,19.8-11.8,36.6-3.1,16-.8,18.3-4.3,35.5-3.6,17.3-6.3,17.1-7.9,28.6-.9,6.6-4.4,31.2,9,45.6,11.3,12.3,29.4,11.5,45.2,10.9,8.4-.3,46.6-2.8,77-23.8,4.3-3,13.1-9.5,25.8-10.4,3-.2,10.1-.4,20.7,3.6,17.5,6.6,19.2,15.1,36.9,27.2,10.3,7,25.5,17.4,44.2,15.4,3.7-.4,17.4-1.8,24.5-12.1,4.3-6.2,4.5-13.4,4.6-17.9.3-9.2-2.2-13.5-3.4-19.8-2.4-12.8,4-17.4,16.3-53.1,6.2-18.4,6.5-22.8,6.6-25.5Z"
        />
        <path
          id="femur_12"
          data-name="femur 12"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M101.75,4c-5.1,24.8-5.1,50.6-10.5,76.4-3.2,22.6-19.4,38.7-29.1,59.2-1.1,2.2,0,3.2-1.1,5.4-5.4,8.6-6.5,18.3-8.6,28-1,9.7-1,20.4-1,30.1-1.1,10.8-7.5,20.4-9.7,32.3s-3.2,23.7,3.2,34.4c1.1,2.2,1.1,5.4,3.2,7.5,24.8,23.7,61.3,14,90.4,4.3,22.6-7.5,43-23.7,66.7-24.8,5.4-1.1,11.8,0,17.2,3.2,9.7,7.5,19.4,14,28,22.6,14,11.8,30.1,21.5,49.5,20.4,8.6-1.1,16.1-4.3,23.7-9.7,10.8-9.7,5.4-25.8,3.2-38.7-2.2-11.8,2.2-21.5,7.5-32.3,10.8-24.8,25.8-53.8,7.5-79.6-2.2-2.2-3.2-4.3-5.4-7.5-11.8-14-25.8-24.8-36.6-39.8-1.1-2.2-1.1-4.3-2.2-6.5-19.4-24.8-29.1-52.7-35.6-82.6"
        />
        <path
          id="femur_11"
          data-name="femur 11"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M156.85,204.4c-15.9-12.2-21.7-27.7-23.7-33.4-7.2-20.1-3.8-37.6-2.2-44.1,1.5-6,7.6-29.1,30.1-44.1,3.9-2.6,21.5-14.4,43-11.8,7.1.8,7.4,2.4,34.4,12.9,25,9.7,24.7,8.4,30.1,11.8,4.6,2.9,25.3,16.1,29.1,39.8,2.6,16.3-4.2,29.2-9.7,39.8-4.3,8.2-11.6,22.3-26.9,30.1-5.2,2.7-6.3,2-30.1,6.5-30.7,5.7-31.7,7.2-39.8,6.5-2.8-.3-18.8-2-34.3-14h0Z"
        />
        <path
          id="femur_10"
          data-name="femur 10"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M50.25,167.8c0,23.7-18.3,18.8,0,37.7"
        />
        <path
          id="femur_9"
          data-name="femur 9"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M122.35,185c-35.2-15.1-51.7-1.4-51.7-3.2"
        />
        <path
          id="femur_8"
          data-name="femur 8"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M331.15,176.4c-28.9-1.4-57.4-1.4-60.3-1.1"
        />
        <polyline
          id="femur_7"
          data-name="femur 7"
          class="beide_Knie_vd_stehend2363_cls5"
          points="236.15 269.8 238.65 249 248.85 233.5 241.85 218.6 241.85 194.7 241.85 185 221.35 189.9 198.25 185 181.65 190.4 168.65 194.7"
        />
        <path
          id="femur_6"
          data-name="femur 6"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M301.05,95c-10.7-.6-19.3-7.1-25.7-15.7-16.1-22.6-20.4-48.4-21.4-74.1"
        />
        <path
          id="femur_5"
          data-name="femur 5"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M663.25,213.8c-22.9,35.2-10.6,56.1-20.4,56"
        />
        <path
          id="femur_4"
          data-name="femur 4"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M550.25,226.8c-1.1,38.4,11,61.3,24.8,60.8"
        />
        <polyline
          id="femur_3"
          data-name="femur 3"
          class="beide_Knie_vd_stehend2363_cls5"
          points="544.85 203 531.85 198.7 515.35 193.3 492.15 198.1 471.75 193.3 471.75 203 471.75 226.8 464.75 241.7 474.95 257.3 477.35 278.1"
        />
        <path
          id="femur_2"
          data-name="femur 2"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M70.75,258.6c-9.8.2,2.5-20.7-20.4-56"
        />
        <path
          id="femur_1"
          data-name="femur 1"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M138.55,276.6c13.8.4,25.8-22.5,24.8-60.8"
        />
      </g>
      <g id="left_fibula" data-name="left fibula">
        <path
          class="beide_Knie_vd_stehend2363_cls5"
          d="M626.35,575.6c0-33.5-4.2-60.7-9.4-60.7"
        />
        <path
          class="beide_Knie_vd_stehend2363_cls5"
          d="M699.85,471.8c-12,29.9-9.1,53.1-7.5,52.7"
        />
        <path
          class="beide_Knie_vd_stehend2363_cls5"
          d="M626.35,575.6c21.5,33.7,13,83.7,7.8,91"
        />
        <path
          class="beide_Knie_vd_stehend2363_cls5"
          d="M672.15,589.1c-16.4,43.5-27.5,82.5-29.3,86.1"
        />
        <path
          id="left_fibula-2"
          data-name="left fibula"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M657.85,675.2c2.3-22.5,6-41.7,9.7-57,5.5-22.9,9.2-38.4,18.3-58.1,9.3-20.2,16.7-28.2,22.6-50.6,4.7-17.9,3.7-26.8,3.2-30.1-2-13.6-7.2-13.6-11.8-32.3-3-12.1-2.3-17.8-7.5-29.1-3.4-7.4-5.8-12.4-10.8-14-1.7-.5-6.3-1.6-25.8,11.8-11.1,7.7-16.7,11.5-21.5,17.2-8.9,10.5-12.1,21.3-14,28-5.9,20.7,1,23.4-3.2,53.8-1.8,13.2-4,19.1-2.2,30.1,1.7,10,5.1,14.4,8.6,22.6,8.6,20,7.6,38.7,6.5,59.2-.6,10.9-2.4,25.8-7.5,43"
        />
      </g>
      <g id="left_Tibia" data-name="left Tibia">
        <path
          id="l_tibia_7"
          data-name="l tibia 7"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M480.35,682.7c0-101.1-14-182.9-31.2-182.9"
        />
        <path
          id="l_tibia_6"
          data-name="l tibia 6"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M660.55,429.1c-9.4-23.7.3-52.1,14.4-57.3"
        />
        <path
          id="l_tibia_5"
          data-name="l tibia 5"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M484.65,400.4c-9.1-7.1-35.6-5.3-64.6,0"
        />
        <path
          id="l_tibia_4"
          data-name="l tibia 4"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M642.85,391.1c-21.1,9.3-127.5-19.4-127.5-4.3"
        />
        <path
          id="l_tibia_3"
          data-name="l tibia 3"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M575.05,338.5c-24.8,22.5-77.7-19.5-82.9-13"
        />
        <path
          id="left_tibia"
          data-name="left tibia"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M465.25,677.4c1.4-15.7,2.6-35.8,2.2-59.2-.2-13-.7-32.7-4.3-58.1-3.7-25.6-5.8-40.7-14-60.3-8.9-21.3-20.6-37.5-24.8-43-10.5-14-12.5-12.8-19.4-23.7-14.9-23.4-16-45.2-16.1-49.5-.5-12.9-.9-27.5,8.6-36.6,4.4-4.2,13.8-6.1,32.3-9.7,13.7-2.7,21.1-4.1,32.3-5.4,18.5-2.1,24-1.2,30.1-6.5,6.8-5.9,6-12.4,11.8-14,7.1-2,10.4,7.1,20.4,7.5,11.4.5,15.7-10.9,24.8-8.6,4.4,1.1,4.7,4.2,11.8,10.8,5.7,5.3,13.4,10.7,19.4,12.9,12.8,4.8,18.3-4.3,40.9-6.5,9.8-.9,27.6-2.6,39.8,7.5,2,1.7,9.9,8.7,12.9,26.9,6.8,41.1-20.5,81.3-35.5,103.3-13.8,20.2-22,26.7-30.1,47.3-5.1,13-7.4,24.4-10.8,40.9-3,15-5.2,29.7-7.5,51.7-2.1,19.9-4.3,46.2-5.4,77.5"
        />
        <path
          id="l_tibia_2"
          data-name="l tibia 2"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M580.45,334.1c.5,16.8,39.5,25.1,86.9,8.5"
        />
        <path
          id="l_tibia_1"
          data-name="l tibia 1"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M492.15,325.5c-.3,19-39.9,31.6-88.2,23.7"
        />
      </g>
      <path
        id="right_unterschenkel"
        data-name="right unterschenkel"
        class="beide_Knie_vd_stehend2363_cls4"
        d="M190.15,680.3c-34.4,2.2-51.8,3.1-60.3-7.5-5.6-7-3.2-13.3-3.2-36.6,0-17.6-2.2-34.4-6.5-67.8-4.1-31.7-8-47.3-12.9-61.3-4.2-12-10.7-27.5-12.9-26.9-2,.5-.3,13.5,1.1,23.7,2.6,20.1,5.3,23.7,3.2,32.3-1.9,8.1-4.8,6.9-9.7,18.3-6.2,14.6-6.4,27.8-6.5,33.4-.1,13.6,3,12.2,3.2,28,.2,14.8-2.5,18.1,0,28,2.9,11.4,8.2,13.8,6.5,18.3-3.6,9.1-29.5,11.4-35.5,2.2-1.9-3-1.4-6.4-1.1-8.6.7-5.7-2-17.8-7.5-42-6.3-27.9-18.2-56.3-22.6-66.7-11.3-26.8-18.1-36.7-21.5-58.1-3-18.6-.5-27.8,1.1-32.3,3.6-10.1,7.7-11,10.8-22.6,2.6-9.8.7-12.8,4.3-24.8,4.1-13.6,8.4-15.6,9.7-16.1,5.6-2.2,10.4,3.2,12.9,1.1,3.3-2.7-3.9-12-5.4-25.8-2-18.9,8.2-33.5,9.7-35.5,2.5-3.5,8.5-11.8,19.4-16.1,12.4-4.9,24.7-2,37.7,1.1,15.3,3.6,19.8,8,29.1,5.4,7.6-2.2,14.2-7.9,15.1-8.6,8-7,7.3-11.1,12.9-12.9,5.8-1.9,12.5.7,16.1,2.2,5.8,2.3,6.8,4.4,10.8,4.3,3.4-.1,4.4-1.7,10.8-4.3,8.7-3.5,9.9-3.5,10.8-3.2,3.3,1.2,2.9,5.1,6.5,9.7.9,1.2,3.6,4.3,11.8,7.5,10,3.9,19.6,4.7,29.1,5.4,0,0,16.4,1.2,44.1,5.4,3.5.5,9.4,1.5,14.4,6,.9.8,4.2,3.9,7.1,11.2,2.1,5.3,3.7,12.3,3.2,22.6,0,0-.9,22.2-17.2,50.6-6.2,10.8-4.6,5.3-28,38.7,0,0-16.7,23.6-22.6,45.2-3.4,12.6-.2,10.2-4.3,34.4-4.1,24.6-7.5,29.1-7.5,45.2,0,13.5,2.4,12.2,2.2,26.9-.3,15.8-3.6,20-2.2,34.4,1.3,13.1,4.1,15.4,2.2,20.4-5,12.1-28.1,13.8-58.4,15.8h0Z"
      />
      <g id="right_Fibula" data-name="right Fibula">
        <path
          id="r_fibula_4"
          data-name="r fibula 4"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M96.55,503.8c-5.2,0-9.4,27.1-9.4,60.7"
        />
        <path
          id="r_fibula_3"
          data-name="r fibula 3"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M21.25,513.5c1.6.4,4.5-22.8-7.5-52.7"
        />
        <path
          id="r_fibula2"
          data-name="r fibula2"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M79.35,655.5c-5.2-7.3-13.7-57.3,7.8-91"
        />
        <path
          id="r_fibula_1"
          data-name="r fibula 1"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M70.75,664.1c-1.8-3.6-13-42.6-29.3-86.1"
        />
        <path
          id="right_fibula"
          data-name="right fibula"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M91.15,658.8c-5.1-17.2-6.9-32.2-7.5-43-1.1-20.5-2.2-39.1,6.5-59.2,3.5-8.2,6.9-12.6,8.6-22.6,1.9-11-.3-16.9-2.2-30.1-4.2-30.4,2.7-33.1-3.2-53.8-1.9-6.7-5.1-17.4-14-28-4.8-5.7-10.4-9.6-21.5-17.2-19.5-13.5-24.2-12.4-25.8-11.8-5,1.6-7.3,6.6-10.8,14-5.2,11.3-4.5,16.9-7.5,29.1-4.7,18.7-9.8,18.7-11.8,32.3-.5,3.3-1.5,12.2,3.2,30.1,5.9,22.3,13.3,30.4,22.6,50.6,9.1,19.7,12.8,35.2,18.3,58.1,3.7,15.3,7.4,34.5,9.7,57"
        />
      </g>
      <g id="right_Tibia" data-name="right Tibia">
        <path
          id="r_tibia_7"
          data-name="r tibia 7"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M264.45,488.7c-17.2,0-31.2,81.8-31.2,182.9"
        />
        <path
          id="r_tibia_6"
          data-name="r tibia 6"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M38.55,360.6c14.2,5.3,23.9,33.6,14.4,57.3"
        />
        <path
          id="r_tibia_5"
          data-name="r tibia 5"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M293.45,389.3c-29-5.3-55.5-7.1-64.6,0"
        />
        <path
          id="r_tibia_4"
          data-name="r tibia 4"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M198.25,375.7c0-15.1-106.4,13.6-127.5,4.3"
        />
        <path
          id="right_tibia_3"
          data-name="right tibia 3"
          class="beide_Knie_vd_stehend2363_cls5"
          d="M221.35,314.4c-5.1-6.5-58,35.5-82.9,13"
        />
        <path
          id="right_tibia"
          data-name="right tibia"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M128.85,671.7c-1.1-31.3-3.3-57.5-5.4-77.5-2.3-21.9-4.5-36.6-7.5-51.7-3.3-16.4-5.6-27.9-10.8-40.9-8.2-20.7-16.4-27.1-30.1-47.3-15-22-42.3-62.2-35.5-103.3,3-18.2,10.9-25.2,12.9-26.9,12.2-10.2,30-8.5,39.8-7.5,22.6,2.2,28.1,11.3,40.9,6.5,6-2.3,13.6-7.6,19.4-12.9,7.1-6.6,7.4-9.6,11.8-10.8,9-2.3,13.3,9.1,24.8,8.6,10.1-.4,13.3-9.5,20.4-7.5,5.9,1.6,5,8.1,11.8,14,6.1,5.3,11.7,4.3,30.1,6.5,11.1,1.3,18.6,2.7,32.3,5.4,18.5,3.6,27.8,5.5,32.3,9.7,9.6,9.1,9.1,23.7,8.6,36.6-.2,4.3-1.2,26.1-16.1,49.5-6.9,10.8-8.9,9.7-19.4,23.7-4.1,5.5-15.9,21.7-24.8,43-8.2,19.5-10.3,34.6-14,60.3-3.6,25.4-4.1,45.1-4.3,58.1-.4,23.4.7,43.5,2.2,59.2"
        />
        <path
          id="r_tibia_2"
          data-name="r tibia 2"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M46.25,331.5c47.4,16.7,86.4,8.3,86.9-8.5"
        />
        <path
          id="r_tibia_1"
          data-name="r tibia 1"
          class="beide_Knie_vd_stehend2363_cls6"
          d="M309.65,338.1c-48.3,7.9-87.9-4.7-88.2-23.7"
        />
      </g>
      <g id="overlays">
        <g id="Patella_overlay" data-name="Patella overlay">
          <path
            class="Patella2363Fill"
            style={{
              fill: props.colors.Patella2363Fill,
              stroke: props.colors.Patella2363Fill,
            }}
            d="M157.2,204.27c-15.9-12.2-21.7-27.7-23.7-33.4-7.2-20.1-3.8-37.6-2.2-44.1,1.5-6,7.6-29.1,30.1-44.1,3.9-2.6,21.5-14.4,43-11.8,7.1.8,7.4,2.4,34.4,12.9,25,9.7,24.7,8.4,30.1,11.8,4.6,2.9,25.3,16.1,29.1,39.8,2.6,16.3-4.2,29.2-9.7,39.8-4.3,8.2-11.6,22.3-26.9,30.1-5.2,2.7-6.3,2-30.1,6.5-30.7,5.7-31.7,7.2-39.8,6.5-2.8-.3-18.8-2-34.3-14h0Z"
          />
          <path
            id="left_Patella"
            data-name="left Patella"
            class="Patella2363Fill"
            style={{
              fill: props.colors.Patella2363Fill,
              stroke: props.colors.Patella2363Fill,
            }}
            d="M522.35,229.25c-8.1.8-9.1-.8-39.8-6.5-23.9-4.4-24.9-3.8-30.1-6.5-15.3-7.9-22.6-21.9-26.9-30.1-5.5-10.6-12.2-23.5-9.7-39.8,3.7-23.7,24.4-36.9,29.1-39.8,5.4-3.4,5.1-2.1,30.1-11.8,27.1-10.5,27.3-12.1,34.4-12.9,21.5-2.6,39.1,9.2,43,11.8,22.5,15.1,28.6,38.1,30.1,44.1,1.6,6.6,5,24-2.2,44.1-2,5.7-7.8,21.1-23.7,33.4-15.4,12-31.3,13.7-34.3,14Z"
          />
        </g>
        <g id="Femur_condyles_overlay" data-name="Femur condyles overlay">
          <g
            id="right_Femur_condyles_overlay"
            data-name="right Femur condyles overlay"
          >
            <path
              id="Femur_condyles_1"
              data-name="Femur condyles 1"
              class="Femurcondylen2363Fill"
              style={{
                fill: props.colors.Femurcondylen2363Fill,
                stroke: props.colors.Femurcondylen2363Fill,
              }}
              d="M236.18,268.33h0c15.99,13.22,32.04,27.52,49.58,32.16,18.95,2.18,34.59-.99,42.75-16.33,4.12-7.83-2.97-19.67-2.14-34.89,1.1-10.42,4.02-20.6,9.08-30.48-37.94-20.32-67.12-14.03-93.73,0l6.97,14.62-10.18,15.27-2.31,19.66h0Z"
            />
            <path
              id="Femur_condyles_2"
              data-name="Femur condyles 2"
              class="Femurcondylen2363Fill"
              style={{
                fill: props.colors.Femurcondylen2363Fill,
                stroke: props.colors.Femurcondylen2363Fill,
              }}
              d="M138.84,279.56h0c-8.21,4.1-21.53,7.77-41.8,9.98-30.17.73-50.3-7.42-54.17-30.16-2.37-30.14,6.07-35.77,7.85-50.73.21-1.82.94-3.54,2.15-4.93l18.44-21.07c17.12-6.55,34.27-4.58,51.44,3.17.21.04.42.07.63.12,24.74,4.49,41.65,26.28,38.63,51.96-3.02,25.68-10.37,37.61-23.19,41.67h.01Z"
            />
          </g>
          <g
            id="Left_Femur_condyles_overlay"
            data-name="Left Femur condyles overlay"
          >
            <path
              id="Femur_condyles_1-2"
              data-name="Femur condyles 1"
              class="Femurcondylen2363Fill"
              style={{
                fill: props.colors.Femurcondylen2363Fill,
                stroke: props.colors.Femurcondylen2363Fill,
              }}
              d="M478.27,279.8l-2.3-19.98-10.14-15.51,6.93-14.86c-26.5-14.26-55.55-20.65-93.32,0,5.04,10.04,7.94,20.38,9.04,30.98.82,15.46-6.23,27.49-2.13,35.46,8.12,15.59,23.7,18.81,42.56,16.6,17.47-4.72,33.45-19.24,49.37-32.68h0Z"
            />
            <path
              id="Femur_condyles_2-2"
              data-name="Femur condyles 2"
              class="Femurcondylen2363Fill"
              style={{
                fill: props.colors.Femurcondylen2363Fill,
                stroke: props.colors.Femurcondylen2363Fill,
              }}
              d="M575.19,291.22c-12.76-4.13-20.08-16.26-23.09-42.35s13.83-48.24,38.46-52.81c.21-.04.42-.08.63-.12,17.09-7.88,34.16-9.88,51.21-3.22l18.36,21.41c1.21,1.41,1.93,3.16,2.14,5.01,1.78,15.2,10.18,20.93,7.82,51.56-3.85,23.11-23.9,31.39-53.93,30.65-20.19-2.25-33.45-5.97-41.62-10.14h.01Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
