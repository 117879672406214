import moment from "moment";
import { capitalize } from "../../Utilities/stringHelpers/stringHelpers";

import {
  SET_EXAM_ARCHIVE_FILTER,
  CLEAR_EXAM_ARCHIVE_FILTER,
  ADD_REMOVE_EXAM_ARCHIVE_MODALITY,
  SET_EXAM_ARCHIVE_FROM_DATE,
  SET_EXAM_ARCHIVE_TO_DATE,
  SET_EXAM_ARCHIVE_PATIENT_SEARCH,
} from "../actionTypes";

const initialState = {
  filter: null,
  modalities: [],
  patientSearch: "",
  fromDate: moment()
    .subtract(window.conf.EXAM_ARCHIVE.defaultDaysBack, "days")
    .format("YYYY-MM-DD"),
  toDate: moment().format("YYYY-MM-DD"),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_EXAM_ARCHIVE_FILTER: {
      // const { content } = action.payload;
      let filtersToApply = [];

      if (state.modalities.length > 0) {
        if (state.modalities.length > 1) {
          if (state.modalities.includes("xray")) {
            let vals = state.modalities.filter((mod) => mod !== "xray");
            filtersToApply.push({
              field: "modality",
              op: "in",
              value: [...vals, "cr", "dx"],
            });
          } else {
            filtersToApply.push({
              field: "modality",
              op: "in",
              value: [...state.modalities],
            });
          }
        } else {
          if (state.modalities.includes("xray")) {
            filtersToApply.push({
              field: "modality",
              op: "in",
              value: ["cr", "dx"],
            });
          } else {
            filtersToApply.push({
              field: "modality",
              op: "eq",
              value: state.modalities[0],
            });
          }
        }
      }

      if (state.patientSearch !== "") {
        filtersToApply.push(
          {
            field: "patientsname",
            op: "like",
            value: `%${state.patientSearch}%`,
          },
          {
            field: "patientsname",
            op: "like",
            value: `%${state.patientSearch.toLowerCase()}%`,
          },
          {
            field: "patientsname",
            op: "like",
            value: `%${capitalize(state.patientSearch)}%`,
          },
          {
            field: "patientsname",
            op: "like",
            value: `%${state.patientSearch.toUpperCase()}%`,
          }
        );
      }

      if (state.fromDate) {
        filtersToApply.push({
          field: "studydate",
          op: ">=",
          value: moment(state.fromDate).format("YYYY-MM-DD"),
        });
      }

      if (state.toDate) {
        filtersToApply.push({
          field: "studydate",
          op: "<=",
          value: moment(state.toDate).format("YYYY-MM-DD"),
        });
      }

      if (filtersToApply.length === 0) {
        return { ...state, filter: initialState.filter };
      }
      if (filtersToApply.length === 1) {
        return { ...state, filter: filtersToApply[0] };
      }
      if (filtersToApply.length > 1) {
        return { ...state, filter: { and: [...filtersToApply] } };
      }
      break;
    }

    case CLEAR_EXAM_ARCHIVE_FILTER: {
      return initialState;
    }
    case ADD_REMOVE_EXAM_ARCHIVE_MODALITY: {
      const { content } = action.payload;
      let modalitiesCp = [...state.modalities];

      if (modalitiesCp.includes(content)) {
        modalitiesCp = modalitiesCp.filter((modality) => modality !== content);
      } else {
        modalitiesCp = [...modalitiesCp, content];
      }
      return { ...state, modalities: modalitiesCp };
    }

    case SET_EXAM_ARCHIVE_FROM_DATE: {
      const { content } = action.payload;
      return { ...state, fromDate: content };
    }

    case SET_EXAM_ARCHIVE_TO_DATE: {
      const { content } = action.payload;
      return { ...state, toDate: content };
    }

    case SET_EXAM_ARCHIVE_PATIENT_SEARCH: {
      const { content } = action.payload;
      return { ...state, patientSearch: content };
    }

    default:
      return state;
  }
}
