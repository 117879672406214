import { Button, Row, Container, Col } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useDispatch } from "react-redux";
import { setActiveTheme } from "../../Redux/actions";
import "./stylesheets/ThemeSelector.scss";

export const availableThemes = [
  {
    name: "Classic",
    theme: "classic",
    colors: ["#ff880d", "#cec9c9", "#b4b3b3"],
  },
  {
    name: "Dark",
    theme: "dark",
    colors: ["#0a0a0a", "#7a7c7e", "#333332"],
  },
  {
    name: "Blue",
    theme: "blue",
    colors: ["#07070c", "#4c4c77", "#2a2c3a"],
  },
];

export default function ThemeSelector(props) {
  const dispatch = useDispatch();
  const setTheme = (themeName) => {
    dispatch(setActiveTheme(themeName));
  };

  return (
    <OverlayTrigger
      trigger="focus"
      placement="top"
      overlay={
        <Popover id="popover-basic" className="theme-popover">
          <Popover.Title as="h3">Select Theme</Popover.Title>
          <Popover.Content>
            <Container>
              {availableThemes.map((theme, key) => (
                <Row
                  key={`themekey${key}`}
                  className="border-bottom py-1 theme-row"
                  onClick={() => setTheme(theme.theme)}
                >
                  <Col md="5">{theme.name}</Col>
                  <Col style={{ backgroundColor: theme.colors[0] }}></Col>
                  <Col style={{ backgroundColor: theme.colors[1] }}></Col>
                  <Col style={{ backgroundColor: theme.colors[2] }}></Col>
                </Row>
              ))}
            </Container>
          </Popover.Content>
        </Popover>
      }
    >
      <Button
        size="xs"
        variant="secondary"
        className={`theme-selector ${props.isToggled && "toggled"}`}
      >
        Select Theme
      </Button>
    </OverlayTrigger>
  );
}
