import style from "./style.scss";

function LinkerUnterarmMitHandgelenkLatSitzend2205(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148.32 495.13">
      <g id="Backround">
        <path
          id="backround1"
          class="unterarm_mit_handgelenk_lat_links2205_cls3"
          d="M88.57,495.02c1.86-8.81,3.65-22.29,1.21-38.28-1.26-8.3-2.44-9.21-5.46-23.06-1.21-5.54-2.82-13.63-6.47-41.66-1.65-12.68-2.94-22.51-4.45-36.61-3.3-30.85-4.72-54.59-7.48-100.92-1.14-19.13-.94-17.87-1.75-30.2-1.38-20.96-1.96-24.57-1.89-34.31.12-15.49.18-23.38,3.64-33.64,3.51-10.42,6.17-10.4,8.76-21.03,1.85-7.58,1.84-13.11,6.61-18.74,2.1-2.48,3.54-3.1,5.26-6.2,2.82-5.08,1.07-7.24,3.51-12.13,2.7-5.43,5.56-4.24,7.69-9.3,2.3-5.49-.39-8.52,2.43-11.46,2.43-2.54,5.53-1.42,7.65-4.37,1.45-2.02,1.25-4.29,1.25-5.74.02-9.05,8.05-17.3,14.83-24.27,3.29-3.38,5.98-6.15,10.31-9.07,1.35-.91,8.81-5.87,12.23-10.43.64-.85,1.11-1.65,1.44-2.61.6-1.74.42-3.02.35-6.06-.09-3.93.16-3.75,0-4.45-1.26-5.56-14.84-7.39-24.07-8.63-4.44-.6-8.09-.88-10.39-1.03-2.42.8-5.89,2.24-9.41,4.93-2.82,2.16-3.7,3.6-7.7,8.24-3.78,4.37-7.34,8.46-12.13,12.4-5.36,4.41-8.21,6.68-11.32,5.95-2.74-.64-4.28-3.72-7.4-9.8-1.86-3.63-3.31-6.46-4.06-10.57-1.18-6.42.65-8.77-1.35-10.65-.8-.76-1.66-.91-11.06-.86-3.91.02-4.08.05-7.15.06-5.6.01-8.39.02-10.92-.13-3.14-.19-3.92-.39-6.34-.33-2.35.06-4.14.32-6.07.6-4.16.6-6.24.9-6.93,1.49-2.84,2.43,1.29,9.41,2.48,16.44,2.82,16.78-7.22,23.16-5.04,39.72.58,4.45,2.18,10.84,6.93,18.26,1.65,3.72,3.53,9.41,3.08,16.2-.74,11.12-6.98,13.83-6.44,23.78.43,7.82,4.4,8.23,4.7,17.72.18,5.46-1.11,6.07-1.62,14.29-.42,6.82.15,10.65.54,16.45.1,1.46.17,2.81.2,4.19.27,10.33-.81,22.23-.81,22.23-1.6,15.13-6.52,82.84-8.29,127.21-.85,21.36-.71,30.7-2.32,53.01-1.04,14.48-1.67,18.39-2.94,37.6,0,0-1.85,27.91-1.62,45.3,0,.27.02,1.27,0,2.63-.05,4.73-.4,8.22-.46,8.88-.28,3.07-.38,10.68.17,28.88,4,.03,8.01.06,12.03.08,10.29.05,20.54.06,30.73.03,1.7.01,4.26.02,7.38,0,2.29-.01,3.8-.03,4.76-.05,5.46-.06,9.85.05,12.23.09,4.07.07,10.55.12,20.93-.05Z"
        />
      </g>
      <g id="Forearmlat">
        <g id="Forearmoverview">
          <path
            id="radius4"
            class="cls-7"
            d="M84.22,494.86c.3-1.78.65-3.6,1.06-5.45,1.97-13.02,1.4-25.84-1.65-38.47-1.76-7.28-3.27-14.62-4.45-22.01-6.25-39.28-10.83-80.9-14.37-123.98-3.06-52.83-5.94-103.43-6.86-130.4.29-16.57,3.02-27.09,7.95-32.15,5.34-7.95,6.21-16.71-3.13-27.33-3.78-9.15-9.39-8.35-15.89-2.65-10.58,2.12-18.63,6.56-22.88,14.45-1.48,3.68-1.15,7.5,2.29,11.56,6.41,36.9,12.27,71.86,14.57,94.28l3.01,59.6,1.81,47.2,2.65,41.06,5.06,82.72.54,28.27c.02,1.1.03,2.2.02,3.3"
          />
          <path
            id="radius3"
            class="cls-7"
            d="M23.6,128.03c4.96,4.72,10.44,8.09,17.62,7.03,15.45-4.07,26.2-10.19,21.55-19.99"
          />
          <path
            id="radius2"
            class="cls-7"
            d="M65.05,123.98c1.17,5.02-1.38,9.34-6.18,13.24-7.31,7.37-10.71,16.46-10.31,27.21"
          />
          <path
            id="ulnar2"
            class="cls-7"
            d="M.5,494.92l.14-6.48c.09-4.12.29-8.23.62-12.33l7.69-98.03,9.56-167.35,4.34-60.65c-2.02-12.13-1.81-19.39.49-22.06,1.18-6.59,4.27-7.85,8.27-6.57l1.86,3.32.31,3.07c1.82-2.55,5.38-4.19,9.14-1.91,4.41-.13,7.94,1.28,9.59,6,2.26,3.65,1.89,7.5.02,11.47-4.11,5.61-7.07,14.36-9.2,25.38-2.36,12.2-3.09,24.65-2.52,37.07,1.02,22.08-.56,61.76-.62,63.53l-1.75,57.35-2.44,39.18c-1.89,17.67-3.61,34.55-3.97,45.35-.35,16.22-1.23,33.52,2.24,48.18,1.79,8.58,3.81,16.29,6.48,21.56.61,2.02,1.08,4.08,1.41,6.16v.03c.43,2.67,1.07,5.22,1.95,7.7"
          />
          <path
            id="radius1"
            class="cls-7"
            d="M41.7,144.21c-5.18,1.11-9.39-4.09-14.73-1.84"
          />
          <path
            id="ulnar1"
            class="cls-7"
            d="M31.6,121.44c-2.03,3.23-3.05,6.53-1.31,9.97,2.41,3.08,4.03,6.57,4.88,10.46"
          />
          <path
            id="carpalbone8"
            class="cls-7"
            d="M31.39,126.42c4.42-2.29,4.96-5.86,8.19-11.23,1.3-2.15,3.52-3.66,6.02-3.85,1.79-.14,3.34.39,4.71,1.45,1.02.79,1.8,1.84,2.38,2.99,2.05,4.07,3.01,7.65,3.95,9.87.41.96,1.66,1.21,2.43.49h0c1.12-1.05,2.35-1.96,3.6-2.85,3.03-2.16,3.8-6.01,3.8-10.42,0-3.23-.35-6.21-1.27-8.78-.79-2.2-2.35-4.08-4.47-5.08-1.65-.78-3.09-.82-4.14.5-.92,1.16-1.92,2.26-3.15,3.1-4.39,3.01-9.22,4.84-15.1,3.78-3.32-.26-7.03-1.5-10.98-3.33-.59-.27-1.23-.48-1.88-.51-1.6-.07-2.51.75-2.54,2.72-.36,8.22,2.07,13.99,5.82,18.6,2.81,3.45,6.99,5.54,11.44,5.62,3.68.07,7.34-.69,10.98-2.63.63-.33,1.19-.78,1.69-1.28,1.99-1.99,7.45-7.45,10.45-10.45,1.35-1.35,3.01-2.36,4.83-2.94l.29-.09c2.15-.69,4.35-1.23,6.54-1.77,4.04-.99,6.26-3.13,6.69-6.4.17-1.33-.02-2.68-.43-3.95-1.79-5.52-5.13-10.71-11.62-15.25-4.05-2.65-8.3-4.09-12.83-3.79-1.54.1-2.89,1.09-3.51,2.5l-2.74,6.25c-.85,1.93-2.47,3.47-4.49,4.07-3.55,1.06-8.97.91-16.73,1.81-3.03,1.01-5.95,2.33-8,5.95-1.03,1.82-1.54,3.88-1.68,5.97-.53,8.22,2.35,14.36,11.73,18.94"
          />
          <path
            id="carpalbone7"
            class="cls-7"
            d="M58.7,82.6c4.91-9.08,7.14-17.17,2.23-22.58-1.62-2.66-3.79-3.82-6.35-3.88-1.02-.02-2.04-.09-3.04-.22-6.18-.82-11.95-1.95-16.41-4.06-1.59-.75-3.38-1.03-5.13-.81-2.83.35-5.43,1.31-7.75,3.08-1.68,1.28-2.66,3.29-2.66,5.4v1.84c0,1.9.49,3.77,1.43,5.43l4.17,7.4c.46,4.29.41,8.47-.49,12.47-.53,2.36-.22,4.83.97,6.94,2.14,3.79,4.74,7.04,8.33,9.05,6.13,3.22,11.65,3.04,16.46-1.05,1.42-1.21,2.45-2.79,3.08-4.55l5.17-14.46h0Z"
          />
          <path
            id="carpalbone6"
            class="cls-7"
            d="M51.54,55.91c-4.09,2.2-7.85,3.38-10.56,1.37-2.75-1.47-5.55-2.43-8.61-.68l-4.25.68c-2.64.66-3.74,6.56-4.21,14.66-1.16,4.19-.63,8.38,1.18,12.57l3,15.51c.22,1.16.56,2.31,1.1,3.37,1.69,3.34,4.64,5.43,8.54,6.55,8.25,1.18,12.89-1.98,15.72-7.32,3.1-4.74-.38-28.13,7.08-32.78,3.7-1.04,6.95-1.02,9.91-.3,3.39.83,7-.21,9.21-2.92.41-.5.79-1.04,1.14-1.61,1.37-2.2,1.9-4.83,1.54-7.4-.77-5.6-3.2-9.54-7.62-11.48-5.3-1.24-10.34-.34-14.99,3.75l-8.18,6.02h0Z"
          />
          <path
            id="carpalbone5"
            class="cls-7"
            d="M37.13,79.05c-2.68,5.33-5.93,10.03-10.55,13.19v6.86c.59,2.96,1.19,5.99,1.84,9.22.9,4.48,4.66,6.93,10.55,7.87,5.6-.21,10.07-3.56,13.89-8.68,3.28-5.56,4.73-12.1,4.73-19.44-.03-5.83-1.87-9.42-5.47-10.84-5.14-1.92-10.14-1.46-14.99,1.82Z"
          />
          <path
            id="carpalbone4"
            class="cls-7"
            d="M92.79,78.56c-1.52-4.37-3.83-7.47-7.41-8.54-1.56-.47-3.05-1.15-4.5-1.89-5.52-2.82-10.32-5.77-12.56-9.23-3.49-2.96-7.47-3.33-11.85-1.62-4.19,2.18-8.34,3.92-12.32,4-1.97.04-3.79,1.33-4.12,3.28-.27,1.56.18,3.62,1.6,6.4,4.8,8.49,11.8,14.69,20.71,18.93,7.5,3.72,15.44,4.31,23.68,2.73,1.35-.26,2.67-.74,3.77-1.57,1.37-1.03,2.32-2.39,2.95-4,1.06-2.71,1-5.74.04-8.49h.01Z"
          />
          <path
            id="metacarpalbone12"
            class="cls-7"
            d="M113.19,2.82c-.38,1.13-.9,2.21-1.58,3.2-4.57,6.71-12.43,15.26-23.47,25.57-1.06.99-2.38,1.65-3.8,1.97-5.48,1.23-9.98,4.11-13.09,9.34-5.23,4.89-6.35,9.85-4.52,14.86,1.18,4.66,3.63,8.7,7.85,11.88,5.03,4.76,10.12,6.21,15.27,4.59.41-.13.73-.45.95-.82.53-.85,1.53-1.15,2.71-1.24,3.56.84,6.6-.97,9.53-3.41.74-.62,1.1-1.57,1.03-2.54-.26-3.56,1.04-6.59,3.43-9.25,7.94-11.98,17.67-21.92,28.38-30.73.98-.81,2.06-1.48,3.23-1.97,6.62-2.79,8.51-6.73,8.61-11.1"
          />
          <path
            id="metacarpalbone11"
            class="cls-7"
            d="M71.26,42.9c-2.48,4.39-2.71,8.64-.6,12.75.58,1.13,1.38,2.13,2.33,2.96,4.35,3.82,9.15,6.21,14.13,8.03,2.03.74,4.21,1.08,6.35.85,2.6-.28,4.67-1.25,6.44-2.61"
          />
          <path
            id="metacarpalbone10"
            class="cls-7"
            d="M103.36,60.32c3.04-12.76,20.96-30.79,24-41.22"
          />
          <path
            id="metacarpalbone9"
            class="cls-7"
            d="M118.75,9.17l-23.9,22.18c-3.32,2.9-6.72,4.99-10.28,5.37-4.38,1.15-8.44,3.3-11.89,7.39"
          />
          <path
            id="metacarpalbone8"
            class="cls-7"
            d="M25.44.73c-.53,3.26-1.11,6.19-1.74,8.72-.75,3-1.4,6.01-1.97,9.04l-5.36,28.66c-1.07,7.78.22,10.34,3.22,9.11,2.98-4.19,7.17-6.23,12.48-6.27l14.58,3.24c4,.62,5.82-1.45,5.96-5.56.09-2.45-.5-4.88-1.52-7.11-4.86-10.59-7.32-23.72-7.46-39.28"
          />
          <path
            id="metacarpalbone7"
            class="cls-7"
            d="M50.14,1.28c.68,13.01,1.96,24.18,5.42,28.66,2.58,4.71,4.95,9.43,3.04,14.18-1.17,2.83-1.89,5.47-1.32,7.56"
          />
          <path
            id="metacarpalbone6"
            class="cls-7"
            d="M43.74,54.6c-7.5,1.09-13.04.39-15.25-3.31-3.57-3.88-4.91-8.72-2.71-15.08,2.26-5.77,3.12-12,2.84-18.63L29.41.03"
          />
          <path
            id="metacarpalbone5"
            class="cls-7"
            d="M56.61,1.28c-1.15,8.67-.28,16.93,3.6,24.61,3.74,6.58,6.85,13,5.16,18.23-1.7,6.38-.48,12.83-6.48,10.95-3.66.44-7.05.25-9.65-1.87"
          />
          <path
            id="metacarpalbone4"
            class="cls-7"
            d="M32.07,49.99c-3.34-1.91-3.55-4.94-2.23-8.51,5.84-10.89,7.9-24.19,9.01-38.08l.12-2.67"
          />
          <path
            id="metacarpalbone3"
            class="cls-7"
            d="M22.38.73c-.17,5.18-.24,10.4-.24,15.65"
          />
          <path
            id="metacarpalbone2"
            class="cls-7"
            d="M50.26,52.82c3.66,1,4.82-2.63,4.89-8.3l-1.42-4.81c-.2-5.28-.63-10.03-3.47-13.06-2.62-8.08-4.5-16.41-4.41-25.37"
          />
          <path
            id="metacarpalbone1"
            class="cls-7"
            d="M18.23,37.21c.86,8.5,3.02,14.26,8.12,13.75"
          />
          <path
            id="carpalbone3"
            class="cls-7"
            d="M29.08,50.24c-1.43-1.14-2.82-1.99-4.15-2.39-2.29-.69-4.69.64-5.45,2.9-.61,1.83-.58,3.67.11,5.5"
          />
          <path
            id="carpalbone2"
            class="unterarm_mit_handgelenk_lat_links2205_cls6"
            d="M83.75,93c1.4-6.4-.98-11.01-6.82-13.95-4.84-2.44-9.36-2.73-13.25,1.22-3.38,3.34-4.65,8.27-4.77,14.07-.02,1.13-.1,2.26-.17,3.39-.29,4.56,1.33,7.44,4.94,8.57l7.8,1.7c2.79.61,5.66-.55,7.25-2.92,1.85-2.75,3.49-6.98,5.02-12.07h0Z"
          />
          <path
            id="carpalbone1"
            class="unterarm_mit_handgelenk_lat_links2205_cls5"
            d="M30.14,77.9c2.11,4.13,5.18,5.48,8.86,5.05,1.79-.21,3.43-1.22,4.36-2.77,1.98-3.3,2.75-7.95,3.49-12.63.56-6.46-1.46-10.23-4.93-12.49-6.09-2.98-11.34-3.83-15.58-2.08-3.29,1.42-3.9,4.8-3.37,9,.15,1.21.51,2.38,1.05,3.47,0,0,6.12,12.45,6.12,12.45Z"
          />
        </g>
      </g>
      <g id="Highlight">
        <path
          id="carpushighlight"
          class="Handgelenk2204Fill"
          style={{
            fill: props.colors.Handgelenk2204Fill,
            stroke: props.colors.Handgelenk2204Fill,
          }}
          d="M70.98,125.46c-4.49.78-7.03-11.52-14.97-12.34-2.25-.23-4.12.55-6.67,1.62-6.1,2.55-6.63,6.09-11.15,6.57-3,.32-4.58-1.05-6.61.31-1.23.82-1.03,1.57-2.83,3.64-1.64,1.89-3.02,3.47-4.88,3.5-2.13.03-4.38-1.97-4.77-4.14-.35-1.96,1.01-3.61,3.74-6.84,2.67-3.15,4.23-5,6.9-6.29,3.8-1.83,4.97-.18,9.67-1.36,6.81-1.7,6.85-5.78,12.33-6.43,5.14-.6,9.55,2.46,11.14,3.56,3.62,2.51,2.49,3.61,8.09,8.49,1.94,1.69,3.24,2.58,3.44,4.25.25,2.14-1.38,5.1-3.44,5.46Z"
        />
        <path
          id="radiushighlight"
          class="Radius2205Fill"
          style={{
            fill: props.colors.Radius2205Fill,
            stroke: props.colors.Radius2205Fill,
          }}
          d="M53.92,495.02c-.02-3.6-.06-6.55-.09-8.55-.07-4.65-.14-6.61-.23-11.98-.08-5.18-.05-5.52-.09-7.99-.12-6.3-.47-10.18-.96-17.03,0,0-.38-6.06-1.14-18.16-.4-6.29-.36-5.88-1.51-25.02-.83-13.73-1.24-20.68-1.53-25.08-.46-7.14-.59-8.76-1.42-21.49-.64-9.82-.96-14.73-1.1-17.39-.45-8.3-.35-8.97-1.04-25.12-.38-8.97-.4-8.63-.75-17-.41-10.01-.39-10.78-.69-17.48-.36-8.03-.44-8.01-.92-17.91-.54-11.1-.45-11.37-.87-18.6-.39-6.83-.65-11.28-1.29-17.4-.44-4.23-.97-8.39-1.38-11.63-.51-3.99-.97-7.35-1.31-9.81-4.82-29.8-6.71-41.39-7.48-46.04-.31-1.88-.79-4.75-1.48-9.03-.99-6.1-1.49-9.15-1.49-9.16-.11-1.07-.37-2.11-.52-3.17-.03-.25-.09-.63-.27-1.11-.34-.86-.86-1.37-1.09-1.61-.91-.95-1.31-2.09-1.72-3.22-.68-1.91-.43-3.64-.3-4.55.53-3.69,2.84-6.14,4.48-7.89,2.73-2.91,5.55-4.37,7.21-5.22,3.31-1.69,6.81-2.67,8.55-3.16,2.75-.77,3-.62,4.28-1.25,2.83-1.4,3.24-2.97,5.83-3.58.57-.13,2.02-.46,3.65.1,1.4.48,2.24,1.37,2.88,2.07,1.5,1.62,1.46,2.63,2.91,4.84.97,1.47,1.53,1.87,2.46,3,4.01,4.9,4.38,10.92,4.4,11.28.03.6.14,3.42-.96,6.8-.26.81-1.09,3.16-2.87,5.67-.97,1.37-1.38,1.59-2.32,2.95-1.3,1.89-2.02,3.65-2.43,4.85-1.89,6.72-2.67,12.4-3.02,16.52-.42,4.97-.33,9.06-.22,14.29.11,4.99.37,9.16.61,12.2.37,7.79.86,18.06,1.48,30.13.63,12.32,1.19,22.54,2.29,42.47,1.41,25.56,1.83,32.57,2.13,36.97.05.76.1,1.39.16,2.26,1.01,13.89,2.12,23.92,2.9,31.01,2.13,19.53,1.66,16.73,3.71,35.33.63,5.75,1.87,16.84,3.84,31.15,0,0,0,0,0,0,.01.08,1.6,11.81,4.43,29.52.23,1.42.59,3.7,1.17,6.68,1.39,7.23,2.61,11.45,3.37,14.63,2.31,9.68,2.27,17.95,2.22,21.91-.06,5.05-.55,11.93-2.43,20.01h-30.07Z"
        />
        <path
          id="ulnarhighlight"
          class="Ulna2205Fill"
          style={{
            fill: props.colors.Ulna2205Fill,
            stroke: props.colors.Ulna2205Fill,
          }}
          d="M44.25,495.02c-.88-2.32-1.39-4.27-1.69-5.65-.78-3.61-.62-5.38-1.92-8.53-.32-.78-.5-1.09-1.08-2.49-.28-.69-.96-2.39-1.71-4.68-.66-2.01-1.05-3.52-1.43-4.96-.47-1.78-1.03-4.1-1.58-6.84-1.91-9.17-2.66-16.78-2.97-22.31-.3-5.34-.28-10.52-.27-14.63.02-6.4.21-11.13.27-12.47.23-5.41.58-10.09.92-13.83.46-5.8.92-10.6,1.26-14.01.9-8.8,1.41-11.89,1.97-18.74.36-4.4.36-5.75.76-13.55.89-17.22,1.25-17.72,1.75-29.26.28-6.49.18-6.5.61-21.51.28-9.89.56-17.9.74-22.99.57-12.6.79-21.7.88-27.84.02-1.73.05-4.28.16-12.2.15-11.33.18-11.73.18-14.09,0-6.64-.16-6.69-.32-17.26-.2-12.73-.01-16.38.08-18.03.3-5.25.83-9.54,1.24-12.39.46-3.88,1.52-11.26,3.79-18.83,1.59-5.3,3.12-8.48,4.32-10.67.8-1.46,1.55-2.64,2.1-3.46.69-1.11,1.92-3.48,1.83-6.57-.08-2.79-1.2-4.88-1.83-6.04-.6-1.12-1.23-1.98-1.71-2.57-.79-.73-2.43-2.02-4.84-2.51-1.04-.21-1.97-.22-2.7-.16-1.75-.87-4.04-1.61-5.99-.72-1.57.72-2.1,2.12-2.93,1.9-1.32-.36-.65-4.1-2.94-5.55-.56-.36-1.14-.47-1.53-.51-1.77-.11-2.89.51-3.32.78-1.45.93-2.53,2.73-2.69,4.39-.05.53-.03,1.28-.42,2.29-.27.7-.6,1.17-.67,1.28-1.84,2.74-1.01,11.53-1.01,11.53.22,2.34.61,5.35,1.35,8.83-2.12,26.64-3.59,48.34-4.53,62.95-.97,15.04-1.3,21.34-5.58,96.82-1.44,25.41-1.85,32.6-2.56,43.96-1.57,25.18-3.04,45.86-4.08,59.79-1.84,18.48-3.36,37.53-4.48,57.12-.47,8.13-.85,16.18-1.17,24.13,7.03.05,14.09.09,21.19.11,7.55.02,15.07.02,22.55,0Z"
        />
      </g>
    </svg>
  );
}

export default LinkerUnterarmMitHandgelenkLatSitzend2205;
