import style from "./style.scss";

function LumbosacralerUbergangVdStehend1724(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276.27 297.7">

<g id="BG">
  <path id="BG4" class="Lumbosacraler_Ubergang_vd_stehend1724_cls3" d="M140.65,1.1c10.3,4.1,21.9,4.6,24.7.4.2-.3.3-.6.4-.9-8.3.1-16.7.3-25.1.5Z"/>
  <path id="BG3" class="Lumbosacraler_Ubergang_vd_stehend1724_cls3" d="M103.15,53.5c2.1-5.6,6.9-15.3,8.2-16.4,10-8.9,27.2-9.4,36.9-2.7,3.7,2.5,10.5-.3,15.9,0,12.3.7,9.39,6.42,11.89,8.42,3.6,2.9-2.09,12.28-6.69,18.18-13,17.2-71.7,7.5-66.2-7.5h0Z"/>
  <path id="BG2" class="Lumbosacraler_Ubergang_vd_stehend1724_cls3" d="M6.75,263.3c2.28-2.2,6-8.9,7-9.9,6.6-6.9,10.32-9.84,12.8-13.3.1-2.1-2.37-1.36-3.17-5.66-.8-4-1.68-7.22-.53-10.34-.4-2.8-.5-2.1-1-3.9-1.4-5.4-.6-13.5-.1-24.4.5-11.5,1.4-13.7,2-15,1.9-3.9,3.96-6.28,7.5-8.9,5.5-6.5,8.2-12.9,10.9-19.8,4.6-11.6,3.28-17.89,6.98-18.59,2.2-.4,6.89,1.39,8.89,3.19,8.53,4.4,14.36,9.69,20.96,15.49,21.6,19.1,91.62,6.5,107.32,0,8.55-4.48,17.16-6.29,17.16-6.29,2.5-1.4,5-2.6,7.5-4,4.5-2.5,5.9-3.5,8.2-3.4,2-.44,4.01.66,4.51,1.16,7.52,1.19,5.79,7.14,7.29,7.14,2,0,2.3-5.5,3.5-5.3.6.1.5,1.8,1.8,5.1.3.6,2.5,1.6,2.9,2.4,1.3,2.4.5,2.8,2.5,5.8,1.9,2.9.7,1.8,4.8,9.6,1.1,2.1,1.4,3.3,1.4,4.8,0,1.4,1.4,2.41,1.1,4.41-.2,1.7.4.09.4,2.59,0,2.7-2.1,5.3-2.4,6.6-.7,2.8-2.49,6.22-3.69,8.12-3.4,5.3-2.41,9.58-.91,17.98,1.1,6.1,2.5,7.6,2.1,12.3-.2,1.9-.6,6.2-3.4,10.2-1.4,2.1-2.5,2.7-4.8,6.1-1.8,2.8-1.65,4.43-1.65,5.83,0,3.9,2.63,4.2,7.75,8.89,7.9,14,10.66,28.23,8.66,34.63-26.04,20.59-246.08,10.48-244.25-23.55h0Z"/>
  <path id="BG1" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M96.75,105.9c.8-7.3,24.6-10.2,30.8-10.9,9-1.1,18.7-2.2,30.8.7,12.1,2.9,28.1,10.1,27.3,15-.2,1-1.3,3.3-33.5,6.8-27,3-39,2.8-49.2-4.1-2.6-1.6-6.5-4.4-6.2-7.5h0Z"/>
</g>
<g id="Anatomy">
  <path id="Anatomy96" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M103.85,1.6c1.1,3,3.2,6.4,3.8,11.6,1.6,13.3-8.1,22.6-4.9,25.5,1.5,1.3,4.2,0,8.2-1.2,7.4-2.3,14.8-2.4,29.5-2.4,12.3-.1,18.5-.1,26.1,1.8,9.8,2.4,15.4,6.1,18.5,3.3,2.6-2.3,1.6-7.7.9-10.9-2-9.8-7.7-11-7.6-17.3.1-5.3,4.2-8.2,5.8-11.4"/>
  <path id="Anatomy95" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M65.65,2c.2.6.5,1.2.8,1.7,2.3,3.8,7.4,4.6,15.8,5.8,7.5,1,7.5,0,13.1,1.2,5.9,1.3,10.7,1.3,12-.1,1.5-1.4-1.9-3.4-3.2-7.2-.2-.7-.4-1.3-.6-1.8"/>
  <path id="Anatomy94" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M130.45,1.3c5.6,5.4,17.2,7.9,27.2-.5"/>
  <path id="Anatomy93" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M183.85.5c-.3.5-.6,1.3-1.1,2.5-4.3,9.7-4.3,10.1-4.1,10.5h0c1.4,2.8,9-.9,22.7-1,11.2-.1,15.9,2.3,19.4-1.2,2.3-2.3,3.2-6.2,2.2-8.9"/>
  <path id="Anatomy92" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M170.75.6c-1.4,1.1-2.7,2.5-3.5,4.1-1.4,2.6-2.4,7.1,0,9.7h0c.2.2,1.9,2.1,3.9,1.7,2.7-.4,3.9-4.4,4.9-7.5.7-2.2,1.8-5.8,1.5-8.1-2.3,0-4.5,0-6.8.1Z"/>
  <path id="Anatomy91" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M122.65,1.4c-2.6,0-5.3.1-7.9.1-.2,1-.4,2.2-.6,3.8-1,7.3-1.5,8.2-.6,9.1h0c1.4,1.5,4.7,1.1,6.7-.3,1.6-1.2,2-2.8,2.7-5.8.7-2.7,1.3-5.4,0-6.7-.1-.1-.2-.2-.3-.2Z"/>
  <path id="Anatomy90" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M108.15,14.6c9.4,0,5,10.4,9.6,10.4"/>
  <path id="Anatomy89" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M111.05,37.4c-3.4-3.3,1.7-9,6.7-12.5"/>
  <path id="Anatomy88" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M177.05,30c-3.8-3-5-5.7-6.7-6.8"/>
  <path id="Anatomy87" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M178.55,13.5c-5.8,2.6-9.5,8.2-8.3,9.7"/>
  <path id="Anatomy86" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M172.15,38.7c6.3-6.1,2.3-8.8,4.8-8.8"/>
  <path id="Anatomy85" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M141.45,10.1c-1,.9-1,2.4-.9,3.7.3,5.9,1.6,6.9,1.5,9.7-.1,5.5-5.2,7.1-4.2,11.6.3,1.4,1.3,3.3,2.7,3.6,2.9.6,7.4-5,8-10.8.1-1.3-.1-1.1-.7-6.5-1-8.1-.7-10.7-2.5-11.7-1.2-.7-3-.4-3.9.4h0Z"/>
  <path id="Anatomy84" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M67.65,59c.1-2.1,3.7-3.8,6.4-4.2,4.2-.8,5,1.5,12.5,2.4,5,.6,7.6.9,10.6-.3,1.6-.6,2.6-1.4,3.7-.9,1.6.7,1.6,3.2,2.4,7.3,1.4,6.8,3.3,9,2.4,9.7-1.5,1.3-5.9-5.9-14.6-7.6-6.2-1.2-8.2,1.6-14.9-.3-3.5-1-8.7-3.5-8.5-6.1h0Z"/>
  <path id="Anatomy83" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M180.95,73c.6,1.2,4.2-.1,10-.9,9.2-1.2,10.7.7,16.4-1.2,4.1-1.4,9.9-4.6,9.7-7.9-.1-1.7-1.8-2.9-2.4-3.3-4-2.8-7.8-.1-15.8-.3-7.7-.2-11.8-3-13.4-.9-1,1.3.5,2.7,0,6.1-.8,4.9-5.2,7-4.5,8.4h0Z"/>
  <path id="Anatomy82" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M96.15,106.1c-2.9-3.1,9.1-12.7,9.7-29.2.5-13.1-6.7-19.7-3.3-23.1,4.1-4.3,14.7,6.7,37.1,8.5,15.5,1.3,27.6-1.2,27.6-1.2,10.2-2.1,16.1-5.2,18.2-2.7,2.4,2.8-4,8.6-5.8,18.8-2.8,16.5,9.3,28,5.8,31.3-3.6,3.3-14.1-10.2-34.5-13-14.8-2-29.4,2.5-32.9,3.5-13,4.1-19.7,9.4-21.9,7.1h0Z"/>
  <path id="Anatomy81" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M118.65,108.5c-4.3-4-.7-12.2-.3-13.1.9-1.9,2.7-3.8,6.4-7.6,4.7-4.8,7-7.2,8.5-6.7,2.6,1,1.2,8.8,3.6,9.4,1.4.4,3.3-1.9,4.2-3,2.7-3.3,1.9-5.4,3.6-6.4,2.6-1.4,6.9,1.9,10.9,4.9,3.6,2.7,5.5,4.1,6.7,6.7,1.7,3.6,1.9,8.4-.3,12.2-4.8,8.3-19.2,8.1-22.8,3.3-1.6-2.1-1.2-5.4-3.6-6.4-1.1-.5-2.6-.3-3.6.3-2.6,1.5-1.4,5-3.6,7-2.2,2.1-7.1,1.8-9.7-.6h0Z"/>
  <path id="Anatomy80" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M134.45,76.9c.1-5.2-.1-5.2,0-7,.2-3.8.5-10.5,4.2-12.2,1.9-.9,4.7-.3,6.1,1.2,1.5,1.6.9,3.7.3,10-.7,7.6,0,6.8-.6,9.4-1.4,6.3-5.4,12.7-7.6,12.2-1.9-.4-2.3-6-2.5-8.1-.1-1.2,0-1.7.1-5.5h0Z"/>
  <path id="Anatomy79" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M105.85,110.4c10.8,4.9,20.2,6.8,26.4,7.6,5.9.7,9.6.6,14.9.3,13.2-.6,15.1-3.2,15.8-4.2,1.4-2.4,1.2-6.1-.3-7-1.9-1.2-4.3,2.9-10.3,4.2-5.2,1.2-11.7.1-13.1-3-.8-1.7.5-3-.6-4.6-1.2-1.9-4.6-2.7-6.7-1.5-2.6,1.5-1.1,4.9-3.6,7-2.4,1.9-6.9,1.3-9.7-.6-3.3-2.2-1.6-9-2.8-8.7-1,.2-2.7,5.9-4.5,8.1-1.3,1.6-3.5,2.2-5.5,2.4h0Z"/>
  <path id="Anatomy78" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M96.15,106.1c0,2.8,5.1,5,11.5,5"/>
  <path id="Anatomy77" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M174.85,75.4c-2.8.9-6.4-2.9-7.9-6.1-.8-1.8-1.7-5.1-.3-6.4,1-.9,2.1.2,4.9-.3,3.4-.6,4.5-2.7,5.8-2.1,1.4.7,1.1,4.1.9,6.1-.4,3.6-.8,8-3.4,8.8h0Z"/>
  <path id="Anatomy76" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M111.05,61.1c-.5.1-.3,2-.6,5.2-.4,4.7-1.2,6.1-.2,7.1,1.2,1.2,3.4.6,3.8.5,3.9-1.1,6.2-6.7,4.9-8.2-.9-1.1-3,.5-5.2-.9-2-1.3-2.1-3.8-2.7-3.7Z"/>
  <path id="Anatomy75" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M172.75,40.2c-1.6.6,0,5.3-1.5,12.8-1.3,6.1-3.6,8.7-2.5,9.8,1.3,1.3,6.3-.6,8.9-4.2,3.3-4.5,2-10.7-.3-14.4-1-1.6-3.3-4.5-4.6-4Z"/>
  <path id="Anatomy74" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M110.45,39c-1,1.3-.8,4.4-.3,10.6.2,2.3.6,4.8,1.5,9.7.5,3,.9,4.4,2.1,5.5,1.3,1.1,3.6,1.7,5.2.9,2.9-1.4,1.8-6.3,3-11.5,2.7-11.8,14.3-16.5,12.8-19.1-.6-1-2.2-.3-14,1.2-7.1.9-9,.9-10.3,2.7h0Z"/>
  <path id="Anatomy73" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M105.25,39.2c0,1.1,33.9,6.2,75.2,2"/>
  <path id="Anatomy72" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M108.65,40.1c-2.8,7.2-4.47,15.71-9.17,15.71"/>
  <path id="Anatomy71" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M112.55,41.2c0,13.6,2.8,24.5,6.4,24.5"/>
  <path id="Anatomy70" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M146.05,34.6c15.8,5,19.1,19.9,17.9,26.6"/>
  <path id="Anatomy69" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M177.55,58.5c2-6.8-29.5-11.5-73.4-5.7"/>
  <path id="Anatomy68" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M83.45,144.1c-2.1,7.2-5.3,8.3-4.6,12.5.5,2.7,2.9,7.9,21.9,13.7,14.8,4.5,36.9,8.4,64.4,2.7,17.7-3.7,21.4-8,22.8-11.2,2.2-5.3-3.1-8.3-2.7-18.8.3-7.5,2.8-8.6,3.3-18.2.3-4.9.7-12.8-2.9-14.6-2.7-1.3-4.3,2.3-11.7,3.1-4.9.6-6.7-.8-10.9.8-2.8,1.1-2.5,1.8-5.5,3-1.4.5-3.1,1-10.3,1.3-4.6.2-7,.2-10.6,0-5.1-.3-8.9-1-11.8-1.6-5.8-1.1-8.7-1.7-12.2-3.3-2.5-1.1-2.8-1.6-4.9-2.3-3.9-1.2-5.4-.3-8.6-1.7-2.8-1.2-2.7-2.4-4.4-2.7-3.5-.8-8.3,3.3-10.6,7.6-5.3,9.4,3.2,16.1-.7,29.7h0Z"/>
  <path id="Anatomy67" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M135.65,129.8c-2.3-.1-3.8-5.2-4.6-7.9-1.1-3.8-1-6.6-.9-12.2.1-5.3.6-6.8,1.8-7.6,1-.7,2.5-.8,3.6-.3,1.9.9,2,3.4,3,9.7,1.4,8.5,2.3,10,1.2,13.1-.5,2-2.2,5.3-4.1,5.2h0Z"/>
  <path id="Anatomy66" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M108.95,146.2c-3.4-3.5.1-12.8,5.2-17.9,2.9-2.9,9.1-7.4,14-5.2,2.9,1.4,5.4,5.2,4.6,7.6-.3,1-.9.9-1.8,2.4-1.3,2.1-1,3.4-1.8,5.5-.8,1.9-2,3.1-3.3,4.2-4.4,3.9-13.6,6.9-16.9,3.4h0Z"/>
  <path id="Anatomy65" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M142.35,124.6c1.7-.4,3.5,0,14,8.5,6.2,5,7.1,6.1,7.3,7.9.5,4.9-5.8,8.6-6.1,8.8-6.7,3.9-16.7,1.8-18.2-2.4-1.2-3.2,3.2-5.8,1.8-10.3-1-3.3-3.9-3.7-3.9-6.4-.1-2.5,2.3-5.4,5.1-6.1h0Z"/>
  <path id="Anatomy64" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M188.85,110.7c1.9-1.7,4.1,0,10.6,0,5.7,0,6-1.3,14.6-2.4,8.1-1,12.1-1.5,14.5.7,3,2.8,2.8,8.4,1.3,12-1.7,4.2-5.2,6.1-7.9,7.6-4.4,2.4-8.5,2.9-12.8,3.3-5.4.6-5.4-.2-8.5.3-9.4,1.6-13.8,9.9-15.5,8.8-1.5-1,2.9-6.7,3.3-16.4.4-7.8-2.1-11.8.4-13.9h0Z"/>
  <path id="Anatomy63" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" d="M50.85,111c1.5-1.2,3.1-1.4,4-1.5,2.3-.3,4.1.3,5.2.6,5.9,2,6.9,3.6,10.3,4.6,5.9,1.7,8.2-1.5,10.9.6,1.6,1.2,1.5,2.9,2.5,13,.8,8.1,1.2,9.3.2,10.1-1.8,1.4-4.6-1.5-16.7-6.4-4.8-1.9-6.7-2.4-9.4-4.6-2.7-2.1-2.9-3.3-6.1-5.2-2.1-1.2-3.3-1.6-4-3-.7-1.8.1-3.7.6-4.9.5-.8,1.1-2.2,2.5-3.3h0Z"/>
  <path id="Anatomy62" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M95.75,151.9c0-1.3,7.5-2.4,16.9-2.4"/>
  <path id="Anatomy61" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M96.15,124.3c2,1.7,10.4-.6,17.7-8.6"/>
  <path id="Anatomy60" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M84.05,138.3c0,1.6,10.5,2.9,23.6,2.9"/>
  <path id="Anatomy59" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M185.15,142.9c0,.5-8.6.9-19.3.9"/>
  <path id="Anatomy58" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M161.45,151.9h16.9"/>
  <path id="Anatomy57" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M165.95,126.2c-.2,6.3,6.3,13.9,17.1-3.6"/>
  <path id="Anatomy56" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M132.75,157.7c-2.6,6.8,0,13.7,0,13.7"/>
  <path id="Anatomy55" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M137.15,156.2c0,8.4-2,15.2-4.4,15.2"/>
  <path id="Anatomy54" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M42.05,152.6c2.4-.3,3,5.9,9.1,11.9,5.2,5,8.3,4,10.9,8.5,2.9,4.9,1.8,10.3,1.5,11.5-1.8,8.6-9.3,12.7-13.4,14.9-3.6,1.9-6.4,3.5-8.8,2.4-2.8-1.3-2.4-4.8-4.9-14-3-11.1-5.3-12.2-4.2-16.7.9-3.6,2.5-3.3,5.8-10.6,2.5-5.4,2.6-7.7,4-7.9h0Z"/>
  <path id="Anatomy53" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M229.55,146.8c-.7,0-1.9.2-6.4,4.2-5.4,4.9-8.9,8.1-12.1,13.7-3.4,5.9-7.8,13.5-5.2,20.4,1.3,3.4,2.7,2.4,11.2,10.9,6.1,6.1,8.3,9.6,10.9,8.9,2-.5,2.8-3.3,3.4-5.2,2.1-6.6-1.7-9.1.3-14.3,1-2.7,4-7,8.2-8.2,1.2-.4,2.2-.4,3-1.2,1.8-1.9.8-5.6,0-8.2-2.3-7.4-6.6-21.1-13.3-21h0Z"/>
  <path id="Anatomy52" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M29.65,238.6c1.4.9,3-.6,7.3-1.8,6-1.7,7.3,0,9.7-1.8,2.5-1.9,2.9-5.1,3.5-11.2.5-4.9.8-7.5-.8-9.4-2-2.5-4.7-1.2-7-3.9-1.6-1.9-1.1-3.4-2.1-7.3-1.1-4.1-2.8-6.3-4-8.5-5.5-10.4-1.8-23-4-23.4-1.2-.2-5.7,2.3-9.1,10.3-2.6,6.1-1.2,10.6-1.8,13.7-.9,4.4-.7,8.5-.3,16.7.3,6.5.5,9.8,1.8,12.1,1.9,3.5,4,4.2,5.2,8.2,1,3.3.2,5.3,1.6,6.3h0Z"/>
  <path id="Anatomy51" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M217.05,237c-18.4-.2-28.7,20.4-25.8,42.5"/>
  <path id="Anatomy50" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M217.05,237c29.29,6.01,36.69,35.26,33.95,49.85"/>
  <path id="Anatomy49" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M227.95,204.9c-15,4.2-14.2,23.2-12.1,30.6"/>
  <path id="Anatomy48" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M84.05,171.4c4.9,8.9,21.3,12.3,24.6,38.9"/>
  <path id="Anatomy47" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M72.75,214.3c3-4.7,15.9-13.1,33.1-14.3"/>
  <path id="Anatomy46" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M107.55,200c0,1.2,32.2,2.2,72,2.2"/>
  <path id="Anatomy45" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M179.55,202.2c6.6,6.4,19.5,15.1,25.5,22.7"/>
  <path id="Anatomy44" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M169.95,171.8c0-.4,7.1,9.3,24.1-.7"/>
  <path id="Anatomy43" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M98.15,169.4c0,.9-6.9,1.7-15.4,1.7"/>
  <path id="Anatomy42" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M127.75,177.4v15"/>
  <path id="Anatomy41" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M132.75,181.1c0,6.2-2.2,11.2-5,11.2"/>
  <path id="Anatomy40" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M105.05,224.9c-9-4.8-20,2.9-30.1,10.6"/>
  <path id="Anatomy39" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M105.85,241.3c1-6.3-9.3-3.3-23.1,5.8"/>
  <path id="Anatomy38" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M107.55,254.4c-3-5.3-14.6-3.3-24.8,8.2"/>
  <path id="Anatomy37" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M151.05,254.4c3.3-3.9,10.4-5.3,17.4,5.9"/>
  <path id="Anatomy36" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M175.55,244.2c-9.5-3.6-22.9-11.5-25.1-3.3"/>
  <path id="Anatomy35" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M179.55,230.2c-17.6-11.1-27.6-4.6-28.5-2.8"/>
  <path id="Anatomy34" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M153.85,210.3c5.9-1.3,31.7,8.7,57.4,23.1"/>
  <path id="Anatomy33" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M51.85,233.4c6.1-5.3,40.1-27.2,60.8-23.1"/>
  <path id="Anatomy32" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M130.15,214.3c1.1-1.5-4.2,6.3-10,7.6"/>
  <path id="Anatomy31" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M120.15,221.8c-7,7.2-7.6,28-10.4,32.5"/>
  <path id="Anatomy30" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M132.75,221.8c10-1.1,12.3,26,15,38.4"/>
  <path id="Anatomy29" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M29.65,238.6c-11.2,6.9-20,19.8-23.4,26.4"/>
  <path id="Anatomy28" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M.15,122.5c19.1-5.8,32.4,14.1,41.9,30.1"/>
  <path id="Anatomy27" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M230.95,146.8c2.6-1.9,15.9-25.8,45.1-40.7"/>
  <path id="Anatomy26" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M186.85,153.2c-4.2-1,12-5.5,33.2-15.7"/>
  <path id="Anatomy25" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M220.15,137.5c5.9,1.7,10.5,6.6,10.8,9.3"/>
  <path id="Anatomy24" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M50.65,132.6c10.4,3.1,19.8,14.8,29.2,19.4"/>
  <path id="Anatomy23" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M50.65,132.6c-6.3-2.3-5.4,16-8.5,19.2"/>
  <path id="Anatomy22" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M222.55,234c1.2,2.7,4.7,4.6,7.6,4.2,5.6-.7,8.2-9.6,8.8-11.9,1-3.9.5-9.1-.6-19.4-.6-6.1-1-7.6-.6-10.6.6-4.9,3.6-4.7,5.1-10.6.8-3.3,1.1-9.4.1-9.7-1.1-.4-5.1,5.4-6.7,12.1-2,8.2,1,11.2-2.1,17.6-1.6,3.2-4.1,6.1-4.9,7-3.8,4.4-5.6,4.6-6.7,7.6-.2.5-.1.6-.3,8.5-.1,3.7-.1,4.3.3,5.2h0Z"/>
  <path id="Anatomy21" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M234.95,244.4c0-6.1,2.3-11,5.2-11"/>
  <path id="Anatomy20" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M241.35,207.1c5.3,14.5.8,26.3-1.2,26.3"/>
  <path id="Anatomy19" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M241.35,207.1v-11.7"/>
  <path id="Anatomy18" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M241.35,195.4c1-3.4,5.9-11.8,8.2-19.5"/>
  <path id="Anatomy17" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M234.75,143.5c7,7,12.7,23.4,14.9,32.5"/>
  <line id="Anatomy16" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" x1="105.95" y1="283.5" x2="108.65" y2="210.3"/>
  <line id="Anatomy15" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" x1="150.45" y1="286.6" x2="151.05" y2="227.4"/>
  <path id="Anatomy14" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M158.85,203.9c-5.4,7.1-7.2,19.5-7.8,23.4"/>
  <line id="Anatomy13" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" x1="125.25" y1="204.9" x2="125.25" y2="219.2"/>
  <line id="Anatomy12" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" x1="164.45" y1="229.5" x2="181.95" y2="244.4"/>
  <line id="Anatomy11" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" x1="158.85" y1="255.6" x2="181.95" y2="244.4"/>
  <path id="Anatomy10" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M26.05,241c-6.7-11.6-1.3-20.8-4.2-20.8"/>
  <path id="Anatomy9" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M48.95,231.4c0-9.2-5-17.9-9.6-17.9"/>
  <path id="Anatomy8" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M31.05,171.4c-3.9,10.8-7.8,22.9-9.7,23.7"/>
  <path id="Anatomy7" class="Lumbosacraler_Ubergang_vd_stehend1724_cls5" d="M39.35,213.5c0-10.4-3.2-22.7-4.6-22.7"/>
  <ellipse id="Anatomy6" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" cx="72.65" cy="198.2" rx="2.2" ry="5.8"/>
  <ellipse id="Anatomy5" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" cx="72.65" cy="248.4" rx="2.2" ry="5.9"/>
  <ellipse id="Anatomy4" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" cx="72.75" cy="278.3" rx="3" ry="5.3"/>
  <ellipse id="Anatomy3" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" cx="194.75" cy="200" rx="2.4" ry="4.9"/>
  <ellipse id="Anatomy2" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" cx="187.95" cy="246.4" rx="2.8" ry="5.4"/>
  <ellipse id="Anatomy1" class="Lumbosacraler_Ubergang_vd_stehend1724_cls4" cx="183.95" cy="281.5" rx="2.8" ry="6.3"/>
</g>
<g id="Overlays" class="Lumbosacraler_Ubergang_vd_stehend1724_cls6">
  <path id="Overlays18" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M103.94,1.2c1.1,3,3.2,6.4,3.8,11.6,1.6,13.3-8.1,22.6-4.9,25.5,1.5,1.3,4.2,0,8.2-1.2,7.4-2.3,14.8-2.4,29.5-2.4,12.3-.1,18.5-.1,26.1,1.8,9.8,2.4,15.4,6.1,18.5,3.3,2.6-2.3,1.6-7.7.9-10.9-2-9.8-7.7-11-7.6-17.3.1-5.3,4.2-8.2,5.8-11.4"/>
  <path id="Overlays17" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M65.74,1.6c.2.6.5,1.2.8,1.7,2.3,3.8,7.4,4.6,15.8,5.8,7.5,1,7.5,0,13.1,1.2,5.9,1.3,10.7,1.3,12-.1,1.5-1.4-1.9-3.4-3.2-7.2-.2-.7-.4-1.3-.6-1.8"/>
  <path id="Overlays16" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M183.94.1c-.3.5-.6,1.3-1.1,2.5-4.3,9.7-4.3,10.1-4.1,10.5h0c1.4,2.8,9-.9,22.7-1,11.2-.1,15.9,2.3,19.4-1.2,2.3-2.3,3.2-6.2,2.2-8.9"/>
  <path id="Overlays15" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M67.74,58.6c.1-2.1,3.7-3.8,6.4-4.2,4.2-.8,5,1.5,12.5,2.4,5,.6,7.6.9,10.6-.3,1.6-.6,2.6-1.4,3.7-.9,1.6.7,1.6,3.2,2.4,7.3,1.4,6.8,3.3,9,2.4,9.7-1.5,1.3-5.9-5.9-14.6-7.6-6.2-1.2-8.2,1.6-14.9-.3-3.5-1-8.7-3.5-8.5-6.1h0Z"/>
  <path id="Overlays14" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M181.04,72.6c.6,1.2,4.2-.1,10-.9,9.2-1.2,10.7.7,16.4-1.2,4.1-1.4,9.9-4.6,9.7-7.9-.1-1.7-1.8-2.9-2.4-3.3-4-2.8-7.8-.1-15.8-.3-7.7-.2-11.8-3-13.4-.9-1,1.3.5,2.7,0,6.1-.8,4.9-5.2,7-4.5,8.4h0Z"/>
  <path id="Overlays13" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M96.24,105.7c-2.9-3.1,9.1-12.7,9.7-29.2.5-13.1-6.7-19.7-3.3-23.1,4.1-4.3,14.7,6.7,37.1,8.5,15.5,1.3,27.6-1.2,27.6-1.2,10.2-2.1,16.1-5.2,18.2-2.7,2.4,2.8-4,8.6-5.8,18.8-2.8,16.5,9.3,28,5.8,31.3-3.6,3.3-14.1-10.2-34.5-13-14.8-2-29.4,2.5-32.9,3.5-13,4.1-19.7,9.4-21.9,7.1h0Z"/>
  <path id="Overlays12" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M83.54,143.7c-2.1,7.2-5.3,8.3-4.6,12.5.5,2.7,2.9,7.9,21.9,13.7,14.8,4.5,36.9,8.4,64.4,2.7,17.7-3.7,21.4-8,22.8-11.2,2.2-5.3-3.1-8.3-2.7-18.8.3-7.5,2.8-8.6,3.3-18.2.3-4.9.7-12.8-2.9-14.6-2.7-1.3-4.3,2.3-11.7,3.1-4.9.6-6.7-.8-10.9.8-2.8,1.1-2.5,1.8-5.5,3-1.4.5-3.1,1-10.3,1.3-4.6.2-7,.2-10.6,0-5.1-.3-8.9-1-11.8-1.6-5.8-1.1-8.7-1.7-12.2-3.3-2.5-1.1-2.8-1.6-4.9-2.3-3.9-1.2-5.4-.3-8.6-1.7-2.8-1.2-2.7-2.4-4.4-2.7-3.5-.8-8.3,3.3-10.6,7.6-5.3,9.4,3.2,16.1-.7,29.7h0Z"/>
  <path id="Overlays11" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M188.94,110.3c1.9-1.7,4.1,0,10.6,0,5.7,0,6-1.3,14.6-2.4,8.1-1,12.1-1.5,14.5.7,3,2.8,2.8,8.4,1.3,12-1.7,4.2-5.2,6.1-7.9,7.6-4.4,2.4-8.5,2.9-12.8,3.3-5.4.6-5.4-.2-8.5.3-9.4,1.6-13.8,9.9-15.5,8.8-1.5-1,2.9-6.7,3.3-16.4.4-7.8-2.1-11.8.4-13.9h0Z"/>
  <path id="Overlays10" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M50.94,110.6c1.5-1.2,3.1-1.4,4-1.5,2.3-.3,4.1.3,5.2.6,5.9,2,6.9,3.6,10.3,4.6,5.9,1.7,8.2-1.5,10.9.6,1.6,1.2,1.5,2.9,2.5,13,.8,8.1,1.2,9.3.2,10.1-1.8,1.4-4.6-1.5-16.7-6.4-4.8-1.9-6.7-2.4-9.4-4.6-2.7-2.1-2.9-3.3-6.1-5.2-2.1-1.2-3.3-1.6-4-3-.7-1.8.1-3.7.6-4.9.5-.8,1.1-2.2,2.5-3.3h0Z"/>
  <path id="Overlays9" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M103.06,53.72c.61-1.52,1.84-4.58,3.48-8.62,1.81-4.45,2.76-6.7,4.72-7.78.74-.41,2.04-.65,4.63-1.11,3.68-.66,6.38-.91,6.38-.91,10.52-.99,25.89-.68,25.89-.68,4.87.1,8.03.25,11.93.91,2.98.5,2.3.61,6.46,1.37,4.2.77,5.57.78,6.98,2.05.83.74.77,1.11,2.42,4.1,2.12,3.84,2.76,4.22,3.28,5.47.84,2.03.94,5.34-2.51,10.94-4.75,1.02-11.2,2.15-18.91,2.73-7.03.54-11.72.89-18.16.11-9.1-1.11-17.61-3.99-21.03-5.35-2.03-.81-4.15-1.4-6.15-2.28-2.36-1.04-3.8-1.87-5.92-1.82-1.51.04-2.73.51-3.48.88Z"/>
  <path id="Overlays8" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M6.66,263.52c2.28-2.2,6-8.9,7-9.9,6.6-6.9,10.32-9.84,12.8-13.3.1-2.1-2.37-1.36-3.17-5.66-.8-4-1.68-7.22-.53-10.34-.4-2.8-.5-2.1-1-3.9-1.4-5.4-.6-13.5-.1-24.4.5-11.5,1.4-13.7,2-15,1.9-3.9,3.96-6.28,7.5-8.9,5.39-6.76,8.79-13.47,10.9-19.8,3.4-10.21,3.12-18.44,7.29-19.73,2.51-.77,5.58,1.29,8.57,4.32,1.39.62,3.66,1.71,6.24,3.42,4.26,2.83,4.13,3.88,8.66,7.06,3.67,2.59,5.18,2.9,6.06,5.01.91,2.18-.03,3.45.32,5.47.77,4.45,6.89,7.02,10.03,8.2,20.57,7.75,39.65,8.89,39.65,8.89s35.72,2.12,51.72-6.38c1.08-.57,6.76-3.59,7.75-8.2.19-.89.51-2.38,0-4.1-.7-2.36-2.32-3.01-2.05-4.1.28-1.15,2.25-1.15,5.7-2.28,2.87-.94,2.76-1.36,7.52-3.42,1.65-.72,4.05-1.7,7.06-2.73,1.42-.52,2.85-1.07,4.29-1.64,2.83-1.11,5.56-2.25,8.2-3.4,2-.44,4.01.66,4.51,1.16,7.52,1.19,5.79,7.14,7.29,7.14,2,0,2.3-5.5,3.5-5.3.6.1.5,1.8,1.8,5.1.3.6,2.5,1.6,2.9,2.4,1.3,2.4.5,2.8,2.5,5.8,1.9,2.9.7,1.8,4.8,9.6,1.1,2.1,1.4,3.3,1.4,4.8,0,1.4,1.4,2.41,1.1,4.41-.2,1.7.4.09.4,2.59,0,2.7-2.1,5.3-2.4,6.6-.7,2.8-2.49,6.22-3.69,8.12-3.4,5.3-2.41,9.58-.91,17.98,1.1,6.1,2.5,7.6,2.1,12.3-.2,1.9-.6,6.2-3.4,10.2-1.4,2.1-2.5,2.7-4.8,6.1-1.8,2.8-1.65,4.43-1.65,5.83,0,3.9,2.63,4.2,7.75,8.89,7.9,14,10.66,28.23,8.66,34.63-26.04,20.59-246.08,10.48-244.25-23.55Z"/>
  <path id="Overlays7" class="LWS_Sacrum1724Fill" style= {{
            fill: props.colors.LWS_Sacrum1724Fill,
            stroke: props.colors.LWS_Sacrum1724Fill,
            opacity:props.colors.LWS_Sacrum1724Fill,
          }} d="M96.66,106.12c.29-.48,1.52-.05,2.82.04,3.35.23,5.96-1.89,7.52-2.96,4.63-3.19,10.91-4.71,17.77-6.38,6.42-1.56,11.46-1.69,16.41-1.82,5.7-.15,10-.23,15.49,1.14,3.9.97,6.56,2.22,10.03,3.87,11.44,5.45,18.12,11.76,18.86,10.91.24-.28-.19-1.27-.86-1.57-1.14-.51-2.4,1.21-5.01,2.51-1.91.95-3.51,1.15-5.74,1.44-4.19.54-4.28-.56-7.25-.07-3.91.64-4.42,2.66-9.15,3.87-1.91.49-3.38.56-5.49.62-9.36.27-15.57.45-25.46-.62-8.4-.91-10.46-1.74-11.62-2.28-3.62-1.67-3.98-2.98-7.33-3.72-3.73-.83-5.83.22-8.5-1.6-1.54-1.05-2.85-2.77-2.49-3.38Z"/>
  <ellipse id="Overlays6" class="Foramina1724Fill" style= {{
            fill: props.colors.Foramina1724Fill,
            stroke: props.colors.Foramina1724Fill,
            opacity:props.colors.Foramina1724Fill,
          }} cx="73.08" cy="198.47" rx="2.2" ry="5.8"/>
  <ellipse id="Overlays5" class="Foramina1724Fill" style= {{
            fill: props.colors.Foramina1724Fill,
            stroke: props.colors.Foramina1724Fill,
            opacity:props.colors.Foramina1724Fill,
          }} cx="73.08" cy="248.67" rx="2.2" ry="5.9"/>
  <ellipse id="Overlays4" class="Foramina1724Fill" style= {{
            fill: props.colors.Foramina1724Fill,
            stroke: props.colors.Foramina1724Fill,
            opacity:props.colors.Foramina1724Fill,
          }} cx="73.18" cy="278.57" rx="3" ry="5.3"/>
  <ellipse id="Overlays3" class="Foramina1724Fill" style= {{
            fill: props.colors.Foramina1724Fill,
            stroke: props.colors.Foramina1724Fill,
            opacity:props.colors.Foramina1724Fill,
          }} cx="195.18" cy="200.27" rx="2.4" ry="4.9"/>
  <ellipse id="Overlays2" class="Foramina1724Fill" style= {{
            fill: props.colors.Foramina1724Fill,
            stroke: props.colors.Foramina1724Fill,
            opacity:props.colors.Foramina1724Fill,
          }} cx="188.38" cy="246.67" rx="2.8" ry="5.4"/>
  <ellipse id="Overlays1" class="Foramina1724Fill" style= {{
            fill: props.colors.Foramina1724Fill,
            stroke: props.colors.Foramina1724Fill,
            opacity:props.colors.Foramina1724Fill,
          }} cx="184.38" cy="281.77" rx="2.8" ry="6.3"/>
</g>
</svg>
  );
}

export default LumbosacralerUbergangVdStehend1724;
