import style from "./style.scss";

function HalswirbelsauleInklinationLatStehend1609(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368.77 475.71">

<g id="BG">
  <polygon id="BG_white" data-name="BG white" class="Halswirbelsaule_Inklination_lat1609_cls6" points="26.61 26.09 0 79.3 21.66 258.29 81.91 360.52 117.91 379.31 183 287.22 187.82 346.83 180.3 391.34 170.96 461.68 198.72 475.71 229.95 459.13 253.4 465.91 290.09 456.52 310.96 461.68 324 448.69 319.3 425.76 295.34 375.13 301 337.56 303.65 321.45 270.68 261.91 257.22 234.42 268.17 222.26 242.77 198.78 246.98 186.3 225.43 164.35 238.45 151.83 241.72 142.27 257.74 114.2 253.4 74.09 232.17 40.7 200.06 19.83 158.61 .5 112.29 .5 68.87 6.78 26.61 26.09"/>
</g>
<g id="Knochenmarkkanal">
  <g id="Knochenmarkkanal_Parts" data-name="Knochenmarkkanal Parts">
    <path id="Knochenmarkkanal_Part7" data-name="Knochenmarkkanal Part7" class="Halswirbelsaule_Inklination_lat1609_cls3" d="M189.76,217.31l7.23-2.41c.96,7.23,2.41,13.98,6.75,17.84-2.41,3.86-6.27,6.27-11.09,5.79,1.45-8.68-.96-14.95-2.89-21.21h0Z"/>
    <path id="Knochenmarkkanal_Part6" data-name="Knochenmarkkanal Part6" class="Halswirbelsaule_Inklination_lat1609_cls3" d="M210.96,264.07c-1.93,2.89-3.86,4.82-5.3,4.82-1.45-8.68-2.89-14.95-3.86-20.25l5.3-.96c1.45,6.75,2.41,13.5,3.86,16.39h0Z"/>
    <path id="Knochenmarkkanal_Part5" data-name="Knochenmarkkanal Part5" class="Halswirbelsaule_Inklination_lat1609_cls3" d="M205.67,282.87c2.89-1.93,5.3-3.37,7.23-.96v25.07c-2.41,3.86-4.82,5.79-7.23,4.34v-28.44h0Z"/>
    <path id="Knochenmarkkanal_Part4" data-name="Knochenmarkkanal Part4" class="Halswirbelsaule_Inklination_lat1609_cls3" d="M202.77,329.15c1.45,0,2.41-1.45,2.41-4.34.48-1.93.96-3.37,1.93-3.37l4.34,22.18c.48.48-2.89,4.34-3.37,2.89-.96-1.45-5.3-19.77-5.3-17.36h-.01Z"/>
    <path id="Knochenmarkkanal_Part3" data-name="Knochenmarkkanal Part3" class="Halswirbelsaule_Inklination_lat1609_cls3" d="M200.36,472.34c-3.86,0,2.89-11.09,2.41-12.53,4.82-9.16,8.68-18.32,9.16-23.14.96-6.27,1.93-12.05.48-15.43-.48-4.34-.96-8.68-1.93-13.02,1.93-2.41,4.82-2.89,7.71-2.41,2.41,5.3,2.89,11.57,2.41,17.84-.96,3.37-1.45,6.27-2.41,10.12,0,6.75-3.86,17.36-9.16,28.93l-8.68,9.64h0Z"/>
    <path id="Knochenmarkkanal_Part2" data-name="Knochenmarkkanal Part2" class="Halswirbelsaule_Inklination_lat1609_cls3" d="M210.48,370.13c2.41-2.89,5.3-3.37,8.68-2.41,1.45,7.71,3.37,14.95,6.75,20.25-3.37,1.93-6.75,3.37-10.12,3.37-.48-5.79-2.89-13.5-5.3-21.21h-.01Z"/>
    <path id="Knochenmarkkanal_Part1" data-name="Knochenmarkkanal Part1" class="Halswirbelsaule_Inklination_lat1609_cls3" d="M196.02,202.84c-4.82,2.41-7.23,2.41-11.09.48.96-4.34,1.45-8.2,3.86-8.2.48-1.93,10.61.48,18.8-1.45,4.34-3.86,8.2-8.2,10.12-15.43,4.82-1.93,7.23-.96,7.23,2.41-4.82,9.16-6.75,20.73-27.96,19.28l-.96,2.89h0v.02h0Z"/>
  </g>
</g>
<g id="halswirbelsäule_Inkl" data-name="halswirbelsäule Inkl">
  <g id="HWSparts2">
    <g id="cervical_spine" data-name="cervical spine">
      <g id="Inbetween_vertebraes" data-name="Inbetween vertebraes">
        <path id="Inbetween_vertebrae_5" data-name="Inbetween vertebrae 5" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M199.88,419.79c-1.93,1.45-1.45,5.3,1.45,11.09,2.89-.96,5.79-.96,8.2,0,3.37-1.93,6.27-.96,9.16,1.45,3.86-6.27,11.09-7.71,19.28-7.23.96-2.89-4.34-2.41.48-5.79-11.57-.48-23.62.48-36.64,1.93l-1.93-1.45h0Z"/>
        <path id="Inbetween_vertebrae_4" data-name="Inbetween vertebrae 4" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M197.47,316.62c-2.89-8.68-3.37-16.39-1.45-22.66,9.16,1.93,13.5-4.82,23.14-9.64,3.86-.48,13.5,4.82,18.32,2.89,2.41-.96.48-5.3,6.27-10.61,3.86,2.89,7.23,5.79,8.2,9.64-1.93.48-3.37,1.45-4.34,4.34-.48,3.86-.48,8.2,0,12.53l-11.57-1.45-12.05,1.93c-10.61,5.3-21.69,16.39-26.52,13.02h0Z"/>
        <path id="Inbetween_vertebrae3" data-name="Inbetween vertebrae3" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M184.46,266.96c-3.86-4.34-5.79-9.16-2.89-13.02,3.86,3.86,8.68,1.45,14.46-5.3,4.34,2.89,8.68,3.86,10.61-4.34.96,1.45,1.93,1.93,2.41,1.93,1.45,1.93,1.45,3.86-.48,6.75-9.16.48-16.87,5.79-24.11,13.98h0Z"/>
        <path id="Inbetween_vertebrae_2" data-name="Inbetween vertebrae 2" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M202.29,352.29c-2.89-6.27-2.89-11.09-.48-14.95,5.3,6.27,13.02-.48,26.52-2.89,4.82-1.93,13.5-2.89,22.18-3.37l2.89.48c-2.41,3.37-3.86,6.75-4.34,11.09-17.36-4.82-27-1.45-32.3,5.79,0,5.3-6.75,5.3-14.46,3.86h-.01Z"/>
        <path id="Inbetween_vertebrae_1" data-name="Inbetween vertebrae 1" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M201.33,399.06l-7.23-13.02c5.3.48,10.61.48,13.5-.96,3.37-2.89,8.68-3.86,13.98-4.34,5.3-5.3,15.43-11.09,27.48-8.68l2.41.96c4.34,3.86,4.34,7.71.96,11.09-9.64,2.41-19.77,7.23-31.34,7.71-8.2,2.41-15.43,2.41-19.77,7.23h0Z"/>
      </g>
      <g id="cervical_spine_vertebraes" data-name="cervical spine vertebraes">
        <path id="cervical_spine_vertebrae_1" data-name="cervical spine vertebrae 1" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M193.13,225.98c-7.23,7.23-14.95,7.23-22.66-5.79-7.23.96-11.57.48-6.75-9.16-.96-10.61,4.82-16.87,17.84-17.84,3.86-7.23,11.09-10.12,24.11-4.82l11.09,2.89c6.27-3.86,13.02-7.23,18.32-5.3,6.75.96,10.12,10.61,1.45,11.57-3.86,2.89-9.64,4.34-15.43,4.34-1.45,3.86-5.79,6.75-13.02,8.2,1.45,3.37,1.45,7.23-.48,11.57-5.3,1.93-10.12,3.86-14.46,4.34h-.01Z"/>
        <path id="cervical_spine_vertebrae_2" data-name="cervical spine vertebrae 2" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M249.54,219.72c4.82-1.93,8.68-1.93,11.57,1.45,2.41,9.16-4.82,9.64-14.95,8.2-9.64,7.23-18.32,9.64-21.21,7.71,0,5.79-4.82,6.75-13.02,4.82,2.89-5.3.48-6.27-1.45-6.27-2.41.48-3.37,2.41-.48,6.27-.48,2.41-.96,2.41-3.37.96-.48,6.75-4.34,7.23-9.64,6.27-5.79,8.2-8.68,9.16-11.57,8.68-4.34-.96-4.82-4.82-4.34-10.12-2.89-4.34-3.37-8.68-2.41-13.02,5.79-2.41,14.95-6.75,22.18-10.61.96-.96,1.93-.96,3.37-.96,2.41,0,3.86.48,5.3,2.41,17.84-8.68,30.85-10.12,40.01-5.79h.01Z"/>
        <path id="cervical_spine_vertebrae_3" data-name="cervical spine vertebrae 3" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M236.52,279.98c0,1.93.48,3.37,1.93,4.82-4.82,1.45-10.12,1.45-15.43,0-.48-1.45,0-2.89,1.45-3.86-1.93-2.41-4.34-2.89-6.75-3.37,1.45,1.93,2.41,3.37,1.93,4.82-.96.96-2.41,1.45-3.86,1.45-4.34,2.41-8.68,5.3-13.02,8.2-5.79,2.41-10.61,4.34-13.02,2.89-.96,0-1.45-1.93-1.45-9.16-3.86-4.82-4.82-11.09-5.3-13.5,9.16-12.05,16.87-16.87,24.59-15.91,1.45.96,2.41,2.41,2.41,3.86.48,1.93,3.86,2.41,5.3.48,2.89-2.41-3.37-6.27-5.3-6.27-.96,0,.48-3.86.96-4.82,6.27,4.82,12.53,3.86,12.53-2.41,1.45,3.86,3.37,7.23,2.89,10.12,6.75-2.41,13.5-2.41,17.36,2.41h.48c5.3-3.37,10.61.96,16.87,3.37,2.89,0,5.3.48,7.23,2.41,1.93,2.41,3.37,7.23,1.45,11.57-1.45.96-5.3,0-9.64-.96-1.93,1.45-4.34,2.41-7.71,3.37-3.37-1.45-6.75-1.93-9.64-1.93-1.45,2.89-3.86,3.37-6.27,2.41h0Z"/>
        <path id="cervical_spine_vertebrae_4" data-name="cervical spine vertebrae 4" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M251.95,324.33c3.37,2.41,8.68,1.93,13.02,3.86,1.93,2.89,7.71,3.86,20.73,2.41,2.41,2.89,5.79,4.82,11.57,4.34,3.37-1.45,4.34-4.34,3.37-8.68-2.89-2.89-5.79-2.89-8.2-.48-4.34-2.41-8.2-3.37-11.09-2.41-10.61-8.68-12.05-13.02-16.39-12.05-2.89-.48-5.79,0-8.2.96-2.89-2.41-6.75-2.41-10.61-1.93l-.96-4.82c-5.3.96-11.09-.48-17.36-2.41l.96,5.3c-10.12-.96-19.77,1.93-28.93,8.2-1.93.96-3.86,1.45-6.27,1.45-1.45,2.89-.48,5.3,3.86,5.3,1.45,3.86,1.93,8.2.96,13.02-.96,2.89,2.89,5.3,6.75,5.3,3.37,0,6.27-4.82,13.02-5.79l14.46-4.82c4.82-2.41,13.02,2.41,20.25-1.93l-.96-4.82h.02Z"/>
        <path id="cervical_spine_vertebrae_5" data-name="cervical spine vertebrae 5" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M295.34,368.69l-3.37-.48c.48-2.41,0-4.34-.96-6.27-1.93-1.45-4.34-1.93-6.75-1.45-3.37-3.37-7.71-4.82-13.02-5.3-6.27-6.27-15.43-9.16-27-9.16.96-2.41-.48-4.34-4.34-5.79-4.34,2.89-9.64,2.89-15.91,1.93-1.45.96-1.93,2.89-1.93,7.71-13.5,0-20.25,3.37-22.66,9.16.48,1.93-.48,3.37-4.34,4.34-4.34.96-4.34,2.89-4.82,7.71,0,12.53-1.45,21.69,13.98,15.43,2.41-2.41,5.79-3.86,9.64-4.34,10.61-3.37,13.02-7.23,16.87-8.2v-3.37c-3.37-2.41.96,2.89,4.34.48,5.3,0,2.41-5.79.48-1.93l1.93.96c3.86,3.37,9.64,4.34,18.8.96.96-1.45.48-3.37-.48-5.3,5.3-.96,9.16-1.45,12.05-.48,3.37,4.34,9.16,6.27,16.39,6.75,6.75,1.93,10.12,0,11.09-3.37h0Z"/>
        <path id="cervical_spine_vertebrae_6" data-name="cervical spine vertebrae 6" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M253.88,395.68c12.53-6.27,13.98,9.16,33.75,15.43,9.16-.48,17.36,0,19.77,2.41-1.45,2.41-3.37,3.86-6.27,3.86-4.34-.48-7.71,0-9.64,2.89-9.64-.96-18.8-1.93-22.66-4.82-1.93,3.37-6.75,3.37-14.46,0-2.89-2.41-9.16,2.41-8.68,2.41,1.93,0-2.89-5.3-8.68-6.75,2.41-3.86-.48,2.89-1.93.96-2.89-1.45-5.3-8.2-7.23-5.79,4.34,4.82,5.3,8.2,1.93,12.05l-15.43-.48-12.05.48-12.05.96c-5.79-4.34-6.27-9.64-2.89-16.39-.96-.48,29.41-6.27,38.57-9.64,3.37-1.45,3.86-.96.96-4.82,0,0,12.53.48,22.18-.48.96,1.45,1.45,3.37-1.45,6.27,2.41-.48,4.34,0,6.27,1.45h0Z"/>
        <path id="cervical_spine_vertebrae_7" data-name="cervical spine vertebrae 7" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M296.79,441l7.71-4.34c4.82,3.37,8.2,7.71,9.16,12.53.96,3.86-6.27,5.79-16.87,6.75-.96-2.89-3.37-4.34-6.75-5.3-15.43,2.41-29.89.96-35.68,2.89-2.41,2.89-5.3,5.3-8.68,5.79-3.37-.48-7.23-2.89-11.09-5.79,2.41-4.82.96-8.68-5.79-12.05-3.86,1.93-3.86,5.79-3.86,11.09-.96,2.89-2.41,4.34-5.79,5.3-5.3.48-10.61.48-15.91-1.45-8.68,5.3-14.46,6.27-19.77,4.34-1.45,1.93-8.68-5.3-6.75-7.23,1.45-1.45,3.86-15.91,2.41-17.36-.48-3.86,0-6.75,1.93-7.71s4.34-.96,8.2,0c3.86-1.93,6.75-.48,9.16,1.93,2.89-6.27,10.61-7.71,19.77-7.71,3.86,1.45,5.79,3.86,5.3,6.27,2.41,0,3.37-.96,4.34-1.93-3.86-8.2-.96-3.86,2.41-8.2,4.34,2.41,4.82-5.79,3.86-2.41,3.86-4.82,5.79-3.37,8.2-3.37l.96,8.68c1.45,4.34,4.82,5.3,9.16,3.86,1.93-3.37,6.27-2.41,14.46,3.86,4.34,5.3,15.91,9.16,29.89,11.57h.02Z"/>
        <path id="cervical_spine_vertebrae_line_7" data-name="cervical spine vertebrae line 7" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M206.63,410.63c4.82-3.37,10.61-5.3,17.36-5.79,4.82-.48,6.75-2.89,8.2-5.79.96-1.45,2.41-1.45,3.86-1.45"/>
        <path id="cervical_spine_vertebrae_line_6" data-name="cervical spine vertebrae line 6" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M184.94,240.45c.96,1.45,2.41,1.93,4.34,1.93.96.96,1.45,1.93,1.45,3.37-.48,1.45,2.41,1.93,4.82,2.41"/>
        <path id="cervical_spine_vertebrae_line_5" data-name="cervical spine vertebrae line 5" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M184.94,215.86c-2.41.96-4.82,1.93-7.23,2.89-2.41,0-2.41-1.45-1.45-3.86,2.89-2.41,2.89-5.3,1.45-9.16"/>
        <path id="cervical_spine_vertebrae_line_4" data-name="cervical spine vertebrae line 4" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M198.92,268.41c-2.41,3.37-2.41,6.27-.48,8.68-.48,7.71.48,11.57,4.82,9.64,1.45-1.45,1.93-3.37,1.93-5.3,1.93.48,4.82-.48,7.23-1.45"/>
        <path id="cervical_spine_vertebrae_line_3" data-name="cervical spine vertebrae line 3" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M209.04,329.64c-1.93,2.89-3.86,4.34-5.79,4.34-2.41-1.45-3.37-2.89-2.41-4.34.96-.96-.48-1.93-1.45-2.89-3.86,0-4.82-.96-4.82-2.89l.96-2.41"/>
        <polyline id="cervical_spine_vertebrae_line_2" data-name="cervical spine vertebrae line 2" class="Halswirbelsaule_Inklination_lat1609_cls2" points="199.4 370.61 203.74 373.51 214.83 367.72"/>
        <path id="cervical_spine_vertebrae_line_1" data-name="cervical spine vertebrae line 1" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M221.58,438.59c5.3-.48,8.68-3.37,9.16-9.64,1.45-3.37,3.86-2.89,6.27-2.89.48,1.45,1.93,3.37-.48,4.34l.96,3.86c1.93,2.89.48,4.82-4.34,5.79"/>
      </g>
      <g id="tube_lines" data-name="tube lines">
        <path id="tube_line_7" data-name="tube line 7" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M190.24,217.31l7.23-2.41c.96,7.23,2.41,13.98,6.75,17.84-2.41,3.86-6.27,6.27-11.09,5.79,1.45-8.68-.96-14.95-2.89-21.21h0Z"/>
        <path id="tube_line_6" data-name="tube line 6" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M211.45,264.07c-1.93,2.89-3.86,4.82-5.3,4.82-1.45-8.68-2.89-14.95-3.86-20.25l5.3-.96c1.45,6.75,2.41,13.5,3.86,16.39h0Z"/>
        <path id="tube_line_5" data-name="tube line 5" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M206.15,282.87c2.89-1.93,5.3-3.37,7.23-.96v25.07c-2.41,3.86-4.82,5.79-7.23,4.34v-28.44h0Z"/>
        <path id="tube_line_4" data-name="tube line 4" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M203.26,329.15c1.45,0,2.41-1.45,2.41-4.34.48-1.93.96-3.37,1.93-3.37l4.34,22.18c.48.48-2.89,4.34-3.37,2.89-.96-1.45-5.3-19.77-5.3-17.36h-.01Z"/>
        <path id="tube_line_3" data-name="tube line 3" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M200.85,472.34c-3.86,0,2.89-11.09,2.41-12.53,4.82-9.16,8.68-18.32,9.16-23.14.96-6.27,1.93-12.05.48-15.43-.48-4.34-.96-8.68-1.93-13.02,1.93-2.41,4.82-2.89,7.71-2.41,2.41,5.3,2.89,11.57,2.41,17.84-.96,3.37-1.45,6.27-2.41,10.12,0,6.75-3.86,17.36-9.16,28.93l-8.68,9.64h0Z"/>
        <path id="tube_line_2" data-name="tube line 2" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M210.97,370.13c2.41-2.89,5.3-3.37,8.68-2.41,1.45,7.71,3.37,14.95,6.75,20.25-3.37,1.93-6.75,3.37-10.12,3.37-.48-5.79-2.89-13.5-5.3-21.21h-.01Z"/>
        <path id="tube_line_1" data-name="tube line 1" class="Halswirbelsaule_Inklination_lat1609_cls2" d="M196.51,202.84c-4.82,2.41-7.23,2.41-11.09.48.96-4.34,1.45-8.2,3.86-8.2.48-1.93,10.61.48,18.8-1.45,4.34-3.86,8.2-8.2,10.12-15.43,4.82-1.93,7.23-.96,7.23,2.41-4.82,9.16-6.75,20.73-27.96,19.28l-.96,2.89h0v.02h0Z"/>
      </g>
    </g>
  </g>
  <g id="HWSParts">
    <path id="outlines_1" data-name="outlines 1" class="Halswirbelsaule_Inklination_lat1609_cls6" d="M21.66,40.3c10.69-9.31,23.07-16.37,36.59-21.6,16.02-9.36,31.51-14.31,47.82-15.94l17.7-2.26c29.69,1.38,56.94,7.37,79.11,25.49,21.11,12.42,38.58,27.14,45.45,47.28,3.6,6.91,4.08,16.55,3.18,26.81,2.31,18.18-4.74,30.03-14.38,40.21-.57,3.93-1.63,7.92-3.26,10.99l-11.17,9.36c-2.78,6.09-7.86,8.54-14.53,9.7.63,6.24-3.74,11.03-9.59,15.5-1.48,9.35-5.99,12.71-14.2,8.21l-8.93-6.86c2.11,6.57,2.69,12.33,1.73,17.27-.86,5.9-1.34,10.79-1.82,15.69-3.26-3.55-5.09-7.24-3.6-11.75,1.05-3.98.67-7.82-1.16-11.51-1.39-4.22-3.84-4.46-6.19-3.74-3.31.82-7.77-.19-11.8-1.72,3.94,15.11,6.82,29.36,7.21,38.04.96,9.59,5.81,19.28,12.58,28.78,9.17,14.1,8.26,24.37.73,31.42-11.22,8.88-20.28,19.96-27.23,32.77-6.37,13.72-18.6,27.06-32.8,40.12-13.81,7.2-19.72,1.49-21.93-10.88-.63-6.24-3.51-10.79-9.74-15.01-5.08-78-26.24-170.29-59.79-296.34l.02-.03h0Z"/>
    <g id="outlines">
      <path id="outlines_1-2" data-name="outlines 1-2" class="Halswirbelsaule_Inklination_lat1609_cls1" d="M21.66,40.3c10.69-9.31,23.07-16.37,36.59-21.6,16.02-9.36,31.51-14.31,47.82-15.94l17.7-2.26c29.69,1.38,56.94,7.37,79.11,25.49,21.11,12.42,38.58,27.14,45.45,47.28,3.6,6.91,4.08,16.55,3.18,26.81,2.31,18.18-4.74,30.03-14.38,40.21-.57,3.93-1.63,7.92-3.26,10.99l-11.17,9.36c-2.78,6.09-7.86,8.54-14.53,9.7.63,6.24-3.74,11.03-9.59,15.5-1.48,9.35-5.99,12.71-14.2,8.21l-8.93-6.86c2.11,6.57,2.69,12.33,1.73,17.27-.86,5.9-1.34,10.79-1.82,15.69-3.26-3.55-5.09-7.24-3.6-11.75,1.05-3.98.67-7.82-1.16-11.51-1.39-4.22-3.84-4.46-6.19-3.74-3.31.82-7.77-.19-11.8-1.72,3.94,15.11,6.82,29.36,7.21,38.04.96,9.59,5.81,19.28,12.58,28.78,9.17,14.1,8.26,24.37.73,31.42-11.22,8.88-20.28,19.96-27.23,32.77-6.37,13.72-18.6,27.06-32.8,40.12-13.81,7.2-19.72,1.49-21.93-10.88-.63-6.24-3.51-10.79-9.74-15.01"/>
    </g>
    <g id="skull">
      <path id="skull_1" data-name="skull 1" class="Halswirbelsaule_Inklination_lat1609_cls1" d="M207.38,114.2c1.1,1.34,1.73,2.73,1.92,4.65-1.77,1.63-1.1,3.5.58,5.76.19,1.92,1.3,3.26,2.78,3.6,2.06,1.25,4.03,1.53,6.91,1.24l-5.42,3.94c-2.06,3.6.53,5.28,3.55,6.43-.72,2.49-1.92,5.04-4.65,6.77,2.78,3.6,2.11,6.57,0,9.69-5.9,3.98-7.96,7.58-5.66,11.23l.77,2.83"/>
    </g>
    <g id="jaw_joint" data-name="jaw joint">
      <path id="jaw_joint_3" data-name="jaw joint 3" class="Halswirbelsaule_Inklination_lat1609_cls1" d="M112.29,209.51c4.99-3.41,9.74-4.37,13.38-1.83,5.66-1.54,9.83-3.41,12.95-6.15-2.11-1.73-3.74-3.5-4.46-5.85-.72-12.04,3.59-17.32,14-14.97,4.08,2.01,5.9.86,3.79-5.71-2.07-6.09.48-9.74,9.59-10.65,4.37.05,8.01,2.59,10.8,6.18,3.46,5.47,5.38,10.12,3.84,14.15-7.1-3.16-12.33-2.15-17.51-.66-2.4.24-4.46-1.01-6.14-3.26"/>
      <path id="jaw_joint_2" data-name="jaw joint 2" class="Halswirbelsaule_Inklination_lat1609_cls1" d="M114.89,230.57c9.07,3.45,16.02.33,20.38-9.31,4.07-7.68,5.85-14.15,2.92-19.19"/>
      <path id="jaw_joint_1" data-name="jaw joint 1" class="Halswirbelsaule_Inklination_lat1609_cls1" d="M152.63,191.41c.53-4.41-1.25-7.63-4.42-10.22"/>
    </g>
  </g>
</g>
<g id="Intervertebralraum_Highlights" data-name="Intervertebralraum Highlights">
  <path id="Intervertebralraum_Highlight5" data-name="Intervertebralraum Highlight5" class="Intervertebralraume1609Fill" style= {{
            fill: props.colors.Intervertebralraume1609Fill,
            stroke: props.colors.Intervertebralraume1609Fill,
            opacity:props.colors.Intervertebralraume1609Fill,
          }} d="M199.95,419.79c-1.93,1.45-2.73,2.5.17,8.29.64-.52.12-2.33,9.71-4.63,7.56-.77,8.07-1.82,10.96.59,4.57,2.8.13,8.02,7.24,3.26-3-6.09-2.85-4.07,1.55-8.12-11.57-.48-14.68.6-27.7,2.05l-1.93-1.45h0Z"/>
  <path id="Intervertebralraum_Highlight4" data-name="Intervertebralraum Highlight4" class="Intervertebralraume1609Fill" style= {{
            fill: props.colors.Intervertebralraume1609Fill,
            stroke: props.colors.Intervertebralraume1609Fill,
            opacity:props.colors.Intervertebralraume1609Fill,
          }} d="M197.54,316.62c-2.89-8.68-3.37-16.39-1.45-22.66,9.16,1.93,13.5-4.82,23.14-9.64,3.86-.48,13.5,4.82,18.32,2.89,2.41-.96,1.49-7.66,6.47-10,1.86.61,7.03,5.18,8,9.03-1.93.48-3.37,1.45-4.34,4.34-.48,3.86-.48,8.2,0,12.53l-11.57-1.45-12.05,1.93c-8.08,5.5-8.85,5.24-20.57,10.82-5.23,2.49-5.3,3.42-5.95,2.2h0Z"/>
  <path id="Intervertebralraum_Highlight3" data-name="Intervertebralraum Highlight3" class="Intervertebralraume1609Fill" style= {{
            fill: props.colors.Intervertebralraume1609Fill,
            stroke: props.colors.Intervertebralraume1609Fill,
            opacity:props.colors.Intervertebralraume1609Fill,
          }} d="M184.53,266.96c-3.86-4.34-6.36-8.43-3.46-12.29,4.08,6.38,10.06,3.23,15.66-5.63,5.92.98,8.6.55,9.87-5.76.28,2.15,2.05,2.96,2.53,2.96,1.45,1.93,1.45,3.86-.48,6.75-9.16.48-16.87,5.79-24.11,13.98h0Z"/>
  <path id="Intervertebralraum_Highlight2" data-name="Intervertebralraum Highlight2" class="Intervertebralraume1609Fill" style= {{
            fill: props.colors.Intervertebralraume1609Fill,
            stroke: props.colors.Intervertebralraume1609Fill,
            opacity:props.colors.Intervertebralraume1609Fill,
          }} d="M202.36,352.29c-2.89-6.27-2.3-6.18-2-11.11,10.28,2.9,1.15-1.04,28.04-6.73,5.65-2.62,13.5-2.89,22.18-3.37l2.89.48c-2.41,3.37-3.86,6.75-4.34,11.09-17.36-4.82-29.25-1.06-32.53,7.1-12.45,2.86-13.69,3.8-14.23,2.55h-.01Z"/>
  <path id="Intervertebralraum_Highlight1" data-name="Intervertebralraum Highlight1" class="Intervertebralraume1609Fill" style= {{
            fill: props.colors.Intervertebralraume1609Fill,
            stroke: props.colors.Intervertebralraume1609Fill,
            opacity:props.colors.Intervertebralraume1609Fill,
          }} d="M201.73,399.06l-5.84-10.52c5.3.48,8.66-3.02,11.55-4.46,3.1-2.02,6.23-1.68,14.14-5.11,5.79-3.51,11.06-6.59,17.67-7.4,6.04,2.46,8.78,1.56,11.52,1.01h0l1.09.43c4.34,3.86,4.34,7.71.96,11.09-5.03,1.26-9.38,2.93-14.46,4.43-2.16.64-8.78-.03-11.48-.1-.93-.02,2.1,2.13,1.69,2.43-1.78,1.29-4.51.7-7.09.95-8.2,2.41-15.43,2.41-19.77,7.23h0l.02.02Z"/>
</g>
<g id="halswirbelkörper4_Highlights" data-name="halswirbelkörper4 Highlights">
  <path id="halswirbelkörper4_Highlight1" data-name="halswirbelkörper4 Highlight1" class="HWK_4th_1609Fill" style= {{
            fill: props.colors.HWK_4th_1609Fill,
            stroke: props.colors.HWK_4th_1609Fill,
            opacity:props.colors.HWK_4th_1609Fill,
          }} d="M252.18,324.76c3.37,2.41-1.71-13.7-5.8-13.98l-.96-4.82c-5.3.96-11.09-.48-17.36-2.41l.96,5.3c-10.12-.96-19.77,1.93-28.93,8.2-1.93.96-3.86,1.45-6.27,1.45-1.45,2.89.95,6.76,2.77,7.67,1.45,3.86,3.02,5.83,2.05,10.65-.96,2.89,2.89,5.3,6.75,5.3,3.37,0,6.27-4.82,13.02-5.79l14.46-4.82c4.82-2.41,13.02,2.41,20.25-1.93l-.96-4.82h.02Z"/>
</g>
<g id="halswirbelkörper_Highlights" data-name="halswirbelkörper Highlights">
  <path id="halswirbelkörper_Highlight7" data-name="halswirbelkörper Highlight7" class="Alle_HWK_1609Fill" style= {{
            fill: props.colors.Alle_HWK_1609Fill,
            stroke: props.colors.Alle_HWK_1609Fill,
            opacity:props.colors.Alle_HWK_1609Fill,
          }} d="M192.87,225.88c-7.23,7.23-14.95,7.23-22.66-5.79-7.23.96-11.57.48-6.75-9.16-.96-10.61,4.82-16.87,17.84-17.84-1.7-1.54,2.02,10.29,26.33,6.36l8.9-4.06c-.05-1.34,7.83,7.93,4.31,6.38-1.45,3.86-5.79,6.75-13.02,8.2,1.45,3.37,1.45,7.23-.48,11.57-5.3,1.93-10.12,3.86-14.46,4.34h-.01,0Z"/>
  <path id="halswirbelkörper_Highlight6" data-name="halswirbelkörper Highlight6" class="Alle_HWK_1609Fill" style= {{
            fill: props.colors.Alle_HWK_1609Fill,
            stroke: props.colors.Alle_HWK_1609Fill,
            opacity:props.colors.Alle_HWK_1609Fill,
          }} d="M224.69,236.98c0,5.79-4.82,6.75-13.02,4.82,2.89-5.3.48-6.27-1.45-6.27-2.41.48-3.37,2.41-.48,6.27-.48,2.41-.96,2.41-3.37.96-.48,6.75-4.34,7.23-9.64,6.27-5.79,8.2-8.68,9.16-11.57,8.68-4.34-.96-4.82-4.82-4.34-10.12-2.89-4.34-3.37-8.68-2.41-13.02,5.79-2.41,14.95-6.75,22.18-10.61.96-.96,1.93-.96,3.37-.96,2.41,0,3.86.48,5.3,2.41,3.07-1.4,8.66-4.85,9.78-4.02,6.02,4.44,5.59,16.42,5.65,15.59h0Z"/>
  <path id="halswirbelkörper_Highlight5" data-name="halswirbelkörper Highlight5" class="Alle_HWK_1609Fill" style= {{
            fill: props.colors.Alle_HWK_1609Fill,
            stroke: props.colors.Alle_HWK_1609Fill,
            opacity:props.colors.Alle_HWK_1609Fill,
          }} d="M236.26,279.88c0,1.93.48,3.37,1.93,4.82-4.82,1.45-10.12,1.45-15.43,0-.48-1.45,0-2.89,1.45-3.86-1.93-2.41-4.34-2.89-6.75-3.37,1.45,1.93,2.41,3.37,1.93,4.82-.96.96-2.41,1.45-3.86,1.45-4.34,2.41-8.68,5.3-13.02,8.2-5.79,2.41-10.61,4.34-13.02,2.89-.96,0-1.45-1.93-1.45-9.16-3.86-4.82-4.82-11.09-5.3-13.5,9.16-12.05,16.87-16.87,24.59-15.91,1.45.96,2.41,2.41,2.41,3.86.48,1.93,3.86,2.41,5.3.48,2.89-2.41-3.37-6.27-5.3-6.27-.96,0,.48-3.86.96-4.82,6.27,4.82,12.53,3.86,12.53-2.41,1.45,3.86,3.37,7.23,2.89,10.12,6.75-2.41,12.54,23.61,10.13,22.65h0Z"/>
  <path id="halswirbelkörper_Highlight4" data-name="halswirbelkörper Highlight4" class="Alle_HWK_1609Fill" style= {{
            fill: props.colors.Alle_HWK_1609Fill,
            stroke: props.colors.Alle_HWK_1609Fill,
            opacity:props.colors.Alle_HWK_1609Fill,
          }} d="M251.37,324.03c3.18,2.27-1.16-11.91-5.09-13.78-.49-.23-.82-.72-.92-1.26l-.41-2.06c-.18-.93-1.04-1.56-1.98-1.48-4.01.36-8.29-.49-12.84-1.78-1.33-.38-2.59.76-2.34,2.11h0c.21,1.16-.71,2.22-1.89,2.18-9.24-.34-18.08,2.54-26.51,8.27-.08.05-.16.1-.24.14-1.53.75-3.07,1.19-4.85,1.34-.92.07-1.67.81-1.71,1.74-.12,2.4,1.38,5.14,2.59,5.77.38.2.64.56.8.95,1.36,3.36,2.75,5.45,1.85,9.92-.96,2.89,2.89,5.3,6.75,5.3,3.35,0,6.23-4.75,12.88-5.77.09-.01.19-.04.28-.07l14.33-4.78c4.53-2.27,12.05,1.86,18.94-1.24.78-.35,1.2-1.21,1.04-2.05l-.69-3.45h.02Z"/>
  <path id="halswirbelkörper_Highlight3" data-name="halswirbelkörper Highlight3" class="Alle_HWK_1609Fill" style= {{
            fill: props.colors.Alle_HWK_1609Fill,
            stroke: props.colors.Alle_HWK_1609Fill,
            opacity:props.colors.Alle_HWK_1609Fill,
          }} d="M243.98,345.93c.96-2.41-.48-4.34-4.34-5.79-4.34,2.89-9.64,2.89-15.91,1.93-1.45.96-1.93,2.89-1.93,7.71-13.5,0-20.25,3.37-22.66,9.16.48,1.93-.48,3.37-4.34,4.34-4.34.96-4.34,2.89-4.82,7.71,0,12.53-1.45,21.69,13.98,15.43,2.41-2.41,5.79-3.86,9.64-4.34,10.61-3.37,13.02-7.23,16.87-8.2v-3.37c-3.37-2.41.96,2.89,4.34.48,5.3,0,2.41-5.79.48-1.93l1.93.96c3.86,3.37,9.64,4.34,18.8.96.96-1.45.48-3.37-.48-5.3.63-1.79-.63-15.91-2.75-18.85-1.07-1.48-6.84-.9-8.81-.9h0Z"/>
  <path id="halswirbelkörper_Highlight2" data-name="halswirbelkörper Highlight2" class="Alle_HWK_1609Fill" style= {{
            fill: props.colors.Alle_HWK_1609Fill,
            stroke: props.colors.Alle_HWK_1609Fill,
            opacity:props.colors.Alle_HWK_1609Fill,
          }} d="M253.62,395.58c5.09-4,.31,25.55.49,19.77-2.89-2.41-9.16,2.41-8.68,2.41,1.93,0-2.89-5.3-8.68-6.75,2.41-3.86-.48,2.89-1.93.96-2.89-1.45-5.3-8.2-7.23-5.79,4.34,4.82,5.3,8.2,1.93,12.05l-15.43-.48-12.05.48-12.05.96c-5.79-4.34-6.27-9.64-2.89-16.39-.96-.48,29.41-6.27,38.57-9.64,3.37-1.45,3.86-.96.96-4.82,0,0,12.53.48,22.18-.48.96,1.45,1.45,3.37-1.45,6.27,2.41-.48,4.34,0,6.27,1.45h0Z"/>
  <path id="halswirbelkörper_Highlight1" data-name="halswirbelkörper Highlight1" class="Alle_HWK_1609Fill" style= {{
            fill: props.colors.Alle_HWK_1609Fill,
            stroke: props.colors.Alle_HWK_1609Fill,
            opacity:props.colors.Alle_HWK_1609Fill,
          }} d="M254.1,453.43c-2.41,2.89-5.3,5.3-8.68,5.79-3.37-.48-7.23-2.89-11.09-5.79,2.41-4.82.96-8.68-5.79-12.05-3.86,1.93-3.86,5.79-3.86,11.09-.96,2.89-2.41,4.34-5.79,5.3-5.3.48-10.61.48-15.91-1.45-8.68,5.3-14.46,6.27-19.77,4.34-1.45,1.93-8.68-5.3-6.75-7.23,1.45-1.45,3.86-15.91,2.41-17.36-.48-3.86,0-6.75,1.93-7.71s4.34-.96,8.2,0c3.86-1.93,6.75-.48,9.16,1.93,2.89-6.27,10.61-7.71,19.77-7.71,3.86,1.45,5.79,3.86,5.3,6.27,2.41,0,3.37-.96,4.34-1.93-3.86-8.2-.96-3.86,2.41-8.2,4.34,2.41,4.82-5.79,3.86-2.41,3.86-4.82,5.79-3.37,8.2-3.37l.96,8.68c1.45,4.34,4.82,5.3,9.16,3.86.57-2.45,4.34,28.32,1.94,27.95h0Z"/>
</g>
<g id="Hinterkante_Wirbelkörper_highlights" data-name="Hinterkante Wirbelkörper highlights">
  <path id="Hinterkante_Wirbelkörper_highlight8" data-name="Hinterkante Wirbelkörper highlight8" class="Hinterkanten1609Fill" style= {{
            fill: props.colors.Hinterkanten1609Fill,
            stroke: props.colors.Hinterkanten1609Fill,
            opacity:props.colors.Hinterkanten1609Fill,
          }} d="M252.95,325.43c-.05-.12-.34-.55-.34-.69,0,.08-.01.15-.04.23.01-.09.02-.17.03-.25h0v-.02.03c.08.2.1.24.05.11-.02-.06-.04-.11-.05-.17.04-.43,0-.84-.24-1.24-.24-.41-.72-.81-1.2-.92-.97-.22-2.27.29-2.46,1.4-.13.76-.12,1.49.17,2.21.1.24.21.47.32.7-.11.02-.22.04-.33.06-.24.04-.48.08-.72.11-.01,0-.52.06-.21.03.3-.03-.16.02-.21.03-.53.06-1.07.12-1.6.15-1.07.07-2.14.07-3.21-.02-1.05-.09-2.05.98-2,2,.05,1.16.88,1.9,2,2,2.51.22,5.03-.09,7.5-.52,1.63-.29,2.93-1.32,3.05-3.07.05-.75-.2-1.47-.51-2.15h0Z"/>
  <path id="Hinterkante_Wirbelkörper_highlight7" data-name="Hinterkante Wirbelkörper highlight7" class="Hinterkanten1609Fill" style= {{
            fill: props.colors.Hinterkanten1609Fill,
            stroke: props.colors.Hinterkanten1609Fill,
            opacity:props.colors.Hinterkanten1609Fill,
          }} d="M209.56,212.25c-.28-1.01-1.43-1.73-2.46-1.4s-1.69,1.38-1.4,2.46c.07.26.14.52.19.79,0,.02.02.12.03.2,0,.09.02.23.03.23.04.51.04,1.03,0,1.54,0,.08,0,.17-.02.26,0,.02-.01.04-.02.09-.05.27-.1.53-.17.79s-.15.52-.24.78c0,.02-.03.08-.05.15-.02.05-.11.27-.12.27-.1.22-.22.44-.34.65-.07.12-.14.23-.21.35-.01.02-.04.06-.08.09h-.01c-.14.06-.27.13-.41.2-.24.12-.49.23-.73.35-.27.13-.54.26-.82.38-.03,0-.05.02-.07.03,0,0-.02,0-.04,0-.14.04-.28.11-.43.15-.11.04-.23.07-.34.1,0,0-.12.03-.22.05-.08,0-.26.03-.29.03-.11,0-.61-.07-.17,0-1.03-.18-2.2.27-2.46,1.4-.22.97.29,2.27,1.4,2.46,1.83.31,3.4-.2,5.05-.97.62-.29,1.29-.55,1.85-.93.73-.5,1.22-1.28,1.63-2.05,1.35-2.6,1.7-5.65.92-8.48v.02Z"/>
  <path id="Hinterkante_Wirbelkörper_highlight6" data-name="Hinterkante Wirbelkörper highlight6" class="Hinterkanten1609Fill" style= {{
            fill: props.colors.Hinterkanten1609Fill,
            stroke: props.colors.Hinterkanten1609Fill,
            opacity:props.colors.Hinterkanten1609Fill,
          }} d="M225.51,236.05c-.41-.97-1.91-1.25-2.74-.72-.89.57-1.11,1.46-.85,2.37v.02c-.04.07-.07.13-.11.2,0,0-.01.02-.02.03-.06.07-.12.14-.19.21-.15.16-.31.32-.47.48-.08.08-.17.16-.25.24-.02,0-.02.02-.03.03-.08.05-.16.1-.24.14h-.01c-.17.04-.34.08-.5.12-.08,0-.41.03-.47.03-2.1.06-4.24-.18-6.32-.44-1.04-.13-2.04,1.01-2,2,.05,1.19.88,1.86,2,2,1.34.16,2.69.29,4.04.37,1.33.08,2.69.17,4-.13,1.33-.31,2.39-1.19,3.27-2.2.52-.6.96-1.24,1.18-2.01.28-.96.12-1.84-.27-2.74h-.02Z"/>
  <path id="Hinterkante_Wirbelkörper_highlight5" data-name="Hinterkante Wirbelkörper highlight5" class="Hinterkanten1609Fill" style= {{
            fill: props.colors.Hinterkanten1609Fill,
            stroke: props.colors.Hinterkanten1609Fill,
            opacity:props.colors.Hinterkanten1609Fill,
          }} d="M237.34,281.41c-.06-.18-.1-.36-.15-.54,0-.03,0-.05-.01-.07-.01-.17-.02-.34-.02-.51,0-.09.07-.63,0-.21.16-1.03-.26-2.2-1.4-2.46-.96-.22-2.28.29-2.46,1.4-.17,1.07-.15,2.07.1,3.07-.35.02-.71.03-1.06.04-2.62.02-5.23-.4-7.82-.73-1.04-.14-2.04,1.02-2,2,.05,1.2.88,1.85,2,2,2.94.38,5.91.88,8.89.7.78-.05,1.64-.09,2.4-.3,1.08-.29,1.81-1.15,1.95-2.24.11-.79-.19-1.42-.43-2.14h0Z"/>
  <path id="Hinterkante_Wirbelkörper_highlight3" data-name="Hinterkante Wirbelkörper highlight3" class="Hinterkanten1609Fill" style= {{
            fill: props.colors.Hinterkanten1609Fill,
            stroke: props.colors.Hinterkanten1609Fill,
            opacity:props.colors.Hinterkanten1609Fill,
          }} d="M228.79,440.61c-.5-.85-1.87-1.37-2.74-.72-1.79,1.35-2.9,3.16-3.46,5.33-.5,1.92-.42,3.87-.71,5.82,0,.02-.08.41-.08.4-.05.24-.12.49-.2.72-.04.1-.27.51-.39.65-.15.18-.54.42-.77.5-.21.07-.42.13-.64.18.01,0-.39.07-.41.08-1.02.14-2.07.05-3.1.09-1.41.05-2.7.29-3.98.87-.98.44-1.22,1.87-.72,2.74.59,1.01,1.75,1.16,2.74.72.86-.39,1.89-.33,2.87-.34,1.16,0,2.3-.03,3.44-.25,2.33-.46,4.11-2.02,4.84-4.27.32-1,.41-2.08.48-3.12.05-.81.12-1.98.21-2.46.09-.49.18-.98.32-1.46.06-.2.12-.41.19-.61,0-.01.12-.31.14-.37.17-.38.38-.76.63-1.1.03-.03.24-.29.25-.29.05-.06.57-.52.37-.37.86-.65,1.31-1.73.72-2.74Z"/>
  <path id="Hinterkante_Wirbelkörper_highlight2" data-name="Hinterkante Wirbelkörper highlight2" class="Hinterkanten1609Fill" style= {{
            fill: props.colors.Hinterkanten1609Fill,
            stroke: props.colors.Hinterkanten1609Fill,
            opacity:props.colors.Hinterkanten1609Fill,
          }} d="M232.36,410.26c-.6-1.62-1.78-2.8-3.08-3.88-.8-.67-2.05-.84-2.83,0-.68.74-.86,2.11,0,2.83.48.4.96.8,1.39,1.25.1.1.19.21.29.32l.03.03h0c.09.14.18.27.26.41.01.02.05.09.08.16.03.09.12.34.13.39.03.12.06.24.08.36v.65c0,.19-.03.37-.04.55v.1c-.06.34-.12.68-.2,1.02,0,0-.01.03-.01.05-.45-.02-.9-.02-1.34-.04-1.49-.06-2.97-.12-4.46-.18-1.05-.04-2.05.95-2,2,.05,1.12.88,1.95,2,2,1.72.07,3.43.14,5.15.21.99.04,2.04.19,2.94-.34.91-.53,1.33-1.49,1.54-2.48.38-1.76.7-3.68.06-5.43v.02Z"/>
  <path id="Hinterkante_Wirbelkörper_highlight1" data-name="Hinterkante Wirbelkörper highlight1" class="Hinterkanten1609Fill" style= {{
            fill: props.colors.Hinterkanten1609Fill,
            stroke: props.colors.Hinterkanten1609Fill,
            opacity:props.colors.Hinterkanten1609Fill,
          }} d="M231.63,372.08c-.05-.73-.11-1.45-.16-2.18-.04-.54-.2-1.02-.59-1.41-.35-.35-.92-.61-1.41-.59-1.02.05-2.08.88-2,2,.04.51.08,1.02.12,1.54.02.29.05.59.06.88-.88.34-1.76.65-2.62,1.04-.93.41-1.83.88-2.7,1.41-.9.54-1.31,1.82-.72,2.74.58.9,1.78,1.29,2.74.72.92-.55,1.88-1.03,2.86-1.46.51-.21,1.03-.41,1.56-.59.84-.29,1.64-.66,2.2-1.38.61-.79.76-1.71.68-2.7l-.02-.02Z"/>
</g>
<g id="Reklination_Highlight" data-name="Reklination Highlight">
  <path id="Reklination_Highlight2" data-name="Reklination Highlight2" class="Inklination1609Fill" style= {{
            fill: props.colors.Inklination1609Fill,
            stroke: props.colors.Inklination1609Fill,
            opacity:props.colors.Inklination1609Fill,
          }} d="M286.55,160.18l-8.34,8.38,12.9,13.05c.76.78,1.49,1.57,2.2,2.39l8.8,10.21c.98,1.13,1.87,2.33,2.68,3.6l10.49,16.4c1,1.58,1.88,3.23,2.63,4.96l12.41,28.85c.63,1.48,1.17,3,1.59,4.54l9.01,32.48c.39,1.4.7,2.81.94,4.24l9.06,53.9c.23,1.36.39,2.74.48,4.12l4.69,69.41c.07,1.02.08,2.04.02,3.06l-1.02,18.72c-.16,3.07,2.15,5.72,5.22,5.94l3.62.27c1.73.13,3.25-1.13,3.43-2.86l1.08-10.09c.19-1.76.28-3.52.29-5.29l.03-17.49c0-.6-.03-1.19-.07-1.78l-5.12-67.55c-.06-.74-.14-1.48-.27-2.21l-8.97-52.94c-.15-.87-.36-1.72-.62-2.56l-11.76-37.19c-.41-1.31-.88-2.59-1.41-3.85l-14.61-34.94c-1.49-3.57-3.48-6.9-5.92-9.91l-16.69-20.58-16.77-15.24h-.01Z"/>
  <polygon id="Reklination_Highlight1" data-name="Reklination Highlight1" class="Inklination1609Fill" style= {{
            fill: props.colors.Inklination1609Fill,
            stroke: props.colors.Inklination1609Fill,
            opacity:props.colors.Inklination1609Fill,
          }} points="278 183.24 268.73 153.91 300.22 158.29 278 183.24"/>
</g>
</svg>
  );
}

export default HalswirbelsauleInklinationLatStehend1609;
