import React, { useMemo, useEffect, useState } from "react";
import { MakeGetObject } from "../../Utilities/AuthHeader";
import Table from "../Tables/backendTable";
import { Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web/lib";

export default function PhysiciansTable({
  headerless = false,
  facility_id = 0,
  setfilter = () => {},
  rowClick,
  selectedIds = [],
}) {
  const { t } = useTranslation(["physicianslist, common"]);
  const columns = useMemo(() => [
    // {
    //   Header: "Select",
    //   accessor: "id",
    //   Cell: ({ row }) => (
    //     <input
    //       type="checkbox"
    //       checked={selectedPhysicians.indexOf(row.original.id.toString()) > -1}
    //       value={row.original.id.toString()}
    //       onChange={selectHandler}
    //     ></input>
    //   ),
    // },
    {
      Header: t("common:lastName"),
      accessor: "name",
    },

    {
      Header: t("common:firstName"),
      accessor: "vorname",
    },
    {
      Header: t("common:title"),
      accessor: "titel",
    },
  ]);
  const { keycloak } = useKeycloak();

  const [isFacSelected, setIsFacSelected] = useState(false);
  const [selected, setSelected] = useState(facility_id);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  const [checked, setChecked] = useState(true);
  const noDataInfo = [
    {
      id: 0,
      name: t("physicianslist:noData.name"),
      vorname: t("physicianslist:noData.surname"),
      titel: t("physicianslist:noData.title"),
    },
  ];

  const [selectedPhysicians, selectedPhysiciansSetter] = useState([]);
  const [availablePhysicians, setAvailablePhysicians] = useState([]);

  const loadData = React.useCallback(
    ({ pageSize, pageIndex, sortBy }, filerValue) => {
      //apply global filter to request only if search phrase is set
      const buildFilter = () => {
        if (filerValue !== "") {
          //setPageIndex to 0 here only if filtering is active to see 1st page before loading data
          setPageIndex(0);
          const searchValue = `%${filerValue}%`;
          const filter = {
            or: [
              { field: "name", op: "like", value: searchValue },
              { field: "vorname", op: "like", value: searchValue },
              { field: "titel", op: "like", value: searchValue },
            ],
          };
          return `&search=${encodeURIComponent(JSON.stringify(filter))}`;
        } else {
          return "";
        }
      };

      //returns sorting query
      const buildSorting = () => {
        if (sortBy.length > 0) {
          setPageIndex(0);
          return `&sort=${encodeURIComponent(
            JSON.stringify({
              field: sortBy[0].id,
              direction: sortBy[0].desc === true ? "desc" : "asc",
            })
          )}`;
        } else {
          return "";
        }
      };

      //builds url for request with pagination filters and sorting
      const buildURL = () => {
        return `${
          window.conf.SERVER_CONFIG.BASE_URL
        }/pmed/facilities/${facility_id}/refphysicians?page_size=${pageSize}&page_number=${
          pageIndex + 1
        }${buildFilter()}${buildSorting()}`;
      };

      if (selected != null) {
        const fetchId = ++fetchIdRef.current;
        //set loading state in table
        setLoading(true);
        if (fetchId === fetchIdRef.current) {
          setPageIndex(pageIndex);
          fetch(buildURL(), MakeGetObject(keycloak))
            .then((response) => response.json())
            .then((rdata) => {
              if (
                rdata.total_count === null ||
                rdata.length === 0 ||
                rdata.physicians.length === 0
              ) {
                //no data in response so give table no data info
                setTableData(noDataInfo);
                //jump to first page
                setPageCount(1);
                setItemCount(0);
                //loading finished disable loading state
                setLoading(false);
              } else {
                //provide data to table
                setTableData(rdata.physicians);
                //set pageCount
                setPageCount(Math.ceil(rdata.total_count / pageSize));
                setItemCount(rdata.total_count);
                let selected = [...selectedPhysicians];
                rdata.physicians.forEach((phys) => {
                  selected = [...selected, phys.id.toString()];
                });
                selectedPhysiciansSetter(selected);
                setAvailablePhysicians(selected);
                //loading finished disable loading state
                setLoading(false);
              }
            });
        }
      }
    },
    []
  );

  useEffect(() => {
    if (facility_id != null) {
      setIsFacSelected(true);
    }
  }, []);

  useEffect(() => {
    if (facility_id != null) {
      setSelected(facility_id);
      setIsFacSelected(true);
      setCounter(counter + 1);
    }
  }, [facility_id]);

  const selectHandler = (e) => {
    if (e.target.checked) {
      selectedPhysiciansSetter([...selectedPhysicians, e.target.value]);
    } else {
      let index = selectedPhysicians.indexOf(e.target.value);
      if (index > -1) {
        let selected = [...selectedPhysicians];
        selected.splice(index, 1);
        selectedPhysiciansSetter(selected);
      }
    }
  };

  const toggleSelection = () => {
    checked
      ? selectedPhysiciansSetter([])
      : selectedPhysiciansSetter(availablePhysicians);
    setChecked(!checked);
  };

  useEffect(() => {
    setfilter(selectedPhysicians);
  }, [selectHandler, selectedPhysicians]);

  const conditionalRender = () => {
    if (isFacSelected) {
      return (
        <div className="card card-shadow">
          {headerless === false && (
            <div className="card-header">
              <h4>{t("header")}</h4>
            </div>
          )}
          <div className="table-container">
            <Table
              key={counter}
              messFlag={counter}
              columns={columns}
              data={tableData}
              fetchData={loadData}
              loading={loading}
              pageCount={pageCount}
              controlledPageIndex={pageIndex}
              rowClickHandler={rowClick}
              idprefix="fac"
              itemCount={itemCount}
              idfield="id"
              selectedids={selectedIds}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <h5 className="text-muted">{t("generator.noFacility")}</h5>
        </div>
      );
    }
  };

  return (
    <div className="card card-shadow">
      {!headerless && (
        <div className="card-header">
          <Row className="mx-0 my-0 px-0 py-0">
            <h4>{t("refferingPhysicians")}</h4>
            {isFacSelected && (
              <Button
                className="btn-secondary ml-auto"
                onClick={toggleSelection}
              >
                {" "}
                {checked ? "Uncheck all" : "Check all"}
              </Button>
            )}
          </Row>
        </div>
      )}
      <div className="card-body">{conditionalRender()}</div>
    </div>
  );
}
