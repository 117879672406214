import style from "./style.scss";

function LinkeFusswurzelDplSitzend1451(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 235.35 320.7">

<g id="foot">
  <g id="metatarsus">
    <path id="metatarsus_1" data-name="metatarsus 1" class="fußwurzel1451_dp_links_cls4" d="M231.61,9.55c-.9,12.3-1.3,24.6-1,37.1,2,19.7-2.5,29.8-20.5,19-12.9-8.4-26.1-16.1-47.1-9.1-16-18.6-14.7-29.2-1.2-34.2,5.8-6.8,10.7-14,14.5-21.6"/>
    <path id="metatarsus_2" data-name="metatarsus 2" class="fußwurzel1451_dp_links_cls4" d="M147.81,6.45c.6,12.9,1.6,23.8,3.2,31.6,3.7,10.5,9.6,17.6,11.6,21.6-5.1,7.9-8,17.1-6,29-16.7-.4-30.9,3.1-35.8,20.7-10-5.9-15.9-15.5-12.4-33.3-3.4-12.2,0-19.9,6.8-25.5,1.7-14.1,3.4-28.3,4.9-42.3"/>
    <path id="metatarsus_3" data-name="metatarsus 3" class="fußwurzel1451_dp_links_cls4" d="M99.61,11.75c.3,17,1,30.9,2.6,39.7,3.9,15.8,5.7,29.8,2.4,39.8-12.2-4.2-23.2-5.5-26.9,16-11-6.2-15.9-19.5-6-50.2,1.6-13.6,3.2-27.1,4.6-40.1"/>
    <path id="metatarsus_4" data-name="metatarsus 4" class="fußwurzel1451_dp_links_cls4" d="M67.21,22.35c-.1,8.2-.5,16.5-1.1,24.7-1.5,27.1,1.6,51.5,10.8,72.3-16.2,2.7-27.8,10.5-32.5,26-3.8-3.8-6.7-10.2-8-20.7-5-11.7-2.7-22,1.2-32l6.8-67.1"/>
    <path id="metatarsus_5" data-name="metatarsus 5" class="fußwurzel1451_dp_links_cls4" d="M31.61,27.55l2.7,18.2.8,58c-3.2,13.9,1.5,28.7,8.5,43.7-7.1,16.1-12.3,31.1-10.8,42.4-2.1,13.2-6.5,12.7-12.4,2.6-17.4-8.2-24.9-25.5-15.7-58.4l6-81.8c.4-6,.5-12,.4-17.8"/>
  </g>
  <g id="tarsus">
    <path id="tarsus_5" data-name="tarsus 5" class="fußwurzel1451_dp_links_cls4" d="M204.41,158.75h0l-21.4-9.6c2.8-21.5-7.1-42-23.3-61.8-5.1-40.9,20.2-34.9,50.3-21.6,10.6,13.2,17.2,31.9,16.5,60.1,2.5,28.1-1.9,44.2-22.1,32.9h0Z"/>
    <path id="tarsus_4" data-name="tarsus 4" class="fußwurzel1451_dp_links_cls4" d="M176.71,150.05c-16.3-3.8-30.6-1.9-41,9.9-9.1-17.3-14.2-34.9-11.6-52.8,4.7-15.3,17.7-20.2,37.4-16.4,18,25.5,22.3,44.9,15.2,59.3h0Z"/>
    <path id="tarsus_3" data-name="tarsus 3" class="fußwurzel1451_dp_links_cls4" d="M130.91,160.45h0c-9.5,4.1-18.6,9-20.5,26.4-7.3-.7-13.8-3.6-19.3-9.6-12.7-10.6-15.2-36.9-13.2-70.1,6.9-20.5,19-22.7,37.8-1.3,1.5,19.2,6.9,37.3,15.2,54.6h0Z"/>
    <path id="tarsus_2" data-name="tarsus 2" class="fußwurzel1451_dp_links_cls4" d="M60.51,230.55h0c-8.6-10.2-16.4-21.6-23-35.1-2.5-13.2-2.5-26,6.8-37.2,6-26.7,17.8-32.7,32.5-28.6-3.4,29.7,4.8,51.9,34.2,60.6.4,9.1,5.1,18.8,14.5,29-10.7,15.5-23.6,28.7-65,11.3h0Z"/>
    <path id="tarsus_1" data-name="tarsus 1" class="fußwurzel1451_dp_links_cls4" d="M223.41,238.35c-29.6-37.3-60.6-51.3-94.4-19.9-17.2-25.8-23.3-47.8,8.8-56.2,16.7-17.7,41.4-15.7,71.1-.9,13.6,1.9,19.7,17.3,22.1,39.4,7,21,3.4,32.6-7.6,37.6h0Z"/>
  </g>
  <g id="ankle_bone" data-name="ankle bone">
    <path id="ankle_bone_3" data-name="ankle bone 3" class="fußwurzel1451_dp_links_cls4" d="M203.21,317.95c.1-.4.3-.9.4-1.3-1.9-21.5,5-36,18.9-44.5v-.1h-.1c3.3-29-12.7-54.5-37-65.3-28.7-6.3-51.9-4.6-52.6,31.2,2.2,17.2,0,29.3-8,34.6-12.2,7.4-23.2,14.1-31.4,19-19.6,6.6-29.8,16.2-31.6,28.3"/>
    <path id="ankle_bone_2" data-name="ankle bone 2" class="fußwurzel1451_dp_links_cls4" d="M188.51,317.95c2.8-5.1,2.8-11.6.3-19.6-6-20.5-17.8-27.9-38.2-16.4-14.2.6-26.9.1-35.3-3.4l-37.3,20.2-1.6,5.2c-4.4,5.4-7.2,10.7-8.4,15.9"/>
    <path id="ankle_bone_1" data-name="ankle bone 1" class="fußwurzel1451_dp_links_cls4" d="M134.51,234.35c11.3-25.3,36.6-27.5,75.2-7.8"/>
  </g>
  <path id="calcaneus" class="fußwurzel1451_dp_links_cls4" d="M59.71,319.85c1.7-13.7,12.5-23.8,31-30.4,25.6-19.8,28.2-12.6,36.1-24.4,6.4-9.4,3.9-38,10.5-51.5-19.3,19-38.5,38.2-76.8,19.7-7.8,9.8-8.1,22.4-.5,37.9-8.1,14.5-13.4,30.7-15.8,48.8"/>
</g>
<g id="overlays">
  <path id="calcaneus_overlay" data-name="calcaneus overlay" class="calcaneus1451fill" style= {{
            fill: props.colors.calcaneus1451fill,
            stroke: props.colors.calcaneus1451fill,
            opacity:props.colors.calcaneus1451fill,
          }} d="M59.71,319.85c1.7-13.7,12.5-23.8,31-30.4,25.6-19.8,28.2-12.6,36.1-24.4,6.4-9.4,3.9-38,10.5-51.5-19.3,19-38.5,38.2-76.8,19.7-7.8,9.8-8.1,22.4-.5,37.9-8.1,14.5-13.4,30.7-15.8,48.8"/>
  <path id="talus_overlay" data-name="talus overlay" class="talus1451fill" style= {{
            fill: props.colors.talus1451fill,
            stroke: props.colors.talus1451fill,
            opacity:props.colors.talus1451fill,
          }} d="M61.81,319.85c1.84-9.88,8.07-17.47,18.99-22.61,29.83-17.82,58.08-20.55,52.43-55.4-.78-9.05.82-18.09,4.07-27.13,8.57-9.14,18.93-12.22,30.97-9.57,12.02-.95,22.85,1.94,31.84,10.74,16.19,15.36,24.92,33.58,22.3,56.17-13.88,7.9-20.35,23.14-19.2,45.9l-141.4,1.9Z"/>
  <path id="Carpus_overlay" data-name="Carpus overlay" class="fw1451fill" style= {{
            fill: props.colors.fw1451fill,
            stroke: props.colors.fw1451fill,
            opacity:props.colors.fw1451fill,
          }} d="M37.51,195.45c-3.91-14.22-.7-26.34,6.8-37.2,4.99-23.05,15.83-32.57,32.5-28.6l.9-22.4c4.8-12.36,10.72-18.61,18.23-16.17,7.29,2.32,15.78,9.16,24.87,18.27,5.63-15.13,16.66-22.71,35.16-19.83l4.51,1.04-.77-3.21c-1.12-13.82,1.12-23.45,6.65-28.97,8.89-5.09,24.79-.83,43.65,7.37,13.02,15.79,16.92,37.18,16.59,61.16,2.24,26.58-2.65,35.95-11.62,35.48,9.23,7.5,14.79,20.31,16.26,39.08,6.48,19.77,3.44,31.72-7.84,36.88-12.08-16.82-25.51-28.8-40.79-34.13-22.68-8.27-38.75,1.34-53.61,14.23l-3.5.8c-9.74,15.63-20.42,18.88-31.28,19.55-14.44-1.7-25.71-4.44-33.72-8.25-9.51-11.29-17.12-23-23-35.1Z"/>
</g>
</svg>
  );
}

export default LinkeFusswurzelDplSitzend1451;
