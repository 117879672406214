import "../styles/BackView.scss";
import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const Human = map.Human;

function BackSideView({ selected, bpartSetter }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 356 830.8"
    >
      <g id="man_back_view">
        <path
          id="Silohuette"
          class="st0"
          d="M184.5,434.7c-1.5,32.9-2.4,64.6,6.8,80.3c1.4,34.5,5.7,60.2,18,84
		c-9.2,37.3-10.2,71.5,6.8,99c-0.7,24.6-1.5,50-1.9,64.5c-5.3,11.2-5.4,19.9-4.5,25.5c-4,5.1-5.2,12.1-3,21.4
		c5.7,8.2,12.8,11.7,22.1,7.9c3.4-0.2,10.8-2,24.4-6.4c5.8,0.5,13-2.2,21-6.8c3.8-2.5,6.8-5.3-0.8-10.1c-8-0.7-15.5-2.5-21-10.5
		c-5-12.9-7.1-27.2-4.5-43.9c2.5-23.2,6.4-45,12.4-64.9c4.5-25.8,3.8-50.5-5.2-73.5c-2.8-17-1.8-39.4,2.2-65.6
		c6.6-25.8,11.8-52,8.6-80.6c-1.8-21.7-5.2-49.9-11.2-72.4c-4.2-8.8-5.4-32.2-6-59.2c-3.5-13.3-4.1-27.3-1.1-42.4
		c5.8-9,11.2-23,15.8-45c6.4,23.6,13.6,42.4,22.5,51.4c0.9,27.6,7.3,54.4,24.8,79.9c4.5,16.7,8.7,33.3,5.2,46.5
		c-6.6,4.9-11.5,10.6-13.9,17.6l-2.6,13.9l-3,18.8c2,4.4,4.7,4.3,8.2-1.9l3.4-16.9l5.6,1.9c4.1,6.4,4.6,14.9,0.8,25.9l-7.9,11.2
		c-1.6,5.4-1.1,8.4,1.9,8.6l7.9-6.8c0.4-2.5,5.2-4,3.8-6.8c2.1,0.6,3.7-0.2,4.5-3c3.3,0.6,5.6-1.6,6.8-6.4
		c3.9-2.1,7.3-6.5,10.5-12.8c-2.7-9.3-2.3-19.5,0-30.2c0.8-6.4-0.1-16.3-2.2-28.7c-5.8-17.8-5.7-51.8-5.2-86.6
		c-2.4-13.4-7.1-24.6-13.5-34.1c-5.9-34.7-12.5-54.2-19.5-66.4c0.4-29-11.1-50.5-46.5-57.4c-19.2-7-34.3-16.5-41.6-30.8
		c-3.8-11.5-4.5-26.1,1.1-41.6c6.4-8.9,6.1-21.5,2.6-36c-1.1-10.5-6.6-16.9-15.4-20.2c-7.1-5.8-18.3-6.8-30.4-6.8
		c-29,4.5-33.3,17.1-32.9,31.2c-1.5,15.6-2.1,26.9-1.6,32.6c1.8,13.4,3.9,25,7.5,30.8c1.7,7,3.5,13.2,7.1,13.1c4.4,1.2,4.6,3.4,3,6
		c-10.6,11.7-31.8,17.2-54.7,21.7c-32.5,7.9-38.8,34.4-38.4,62.4c-7,12.1-13.6,31.7-19.5,66.4c-6.4,9.5-11.1,20.7-13.5,34.1
		c0.4,34.8,0.5,68.8-5.2,86.6c-2.1,12.4-3,22.2-2.2,28.7c2.3,10.7,2.7,20.9,0,30.2c3.2,6.2,6.6,10.7,10.5,12.8
		c1.2,4.8,3.4,7,6.8,6.4c0.8,2.8,2.4,3.6,4.5,3c0.2,4.9,4,5.7,3.8,6.8l7.9,6.8c3-0.2,3.5-3.2,1.9-8.6l-7.9-11.2
		c-3.9-11-3.4-19.5,0.8-25.9l5.6-1.9l3.4,16.9c3.6,6.2,6.2,6.3,8.2,1.9l-3-18.8l-2.6-13.9c-2.4-7-7.3-12.7-13.9-17.6
		c-3.4-13.2,0.7-29.8,5.2-46.5C69.5,346.8,76,320,76.9,292.4c8.9-9,16.1-27.8,22.5-51.4c4.6,22,9.9,36,15.8,45
		c3,15,2.3,29.1-1.1,42.4c-0.6,27.1-1.8,50.5-6,59.2c-6,22.5-9.4,50.7-11.2,72.4c-3.2,28.6,2,54.8,8.6,80.6
		c4,26.3,5.1,48.6,2.2,65.6c-9,23-9.8,47.7-5.2,73.5c6,19.9,9.9,41.7,12.4,64.9c2.6,16.6,0.5,31-4.5,43.9c-5.5,8-13,9.8-21,10.5
		c-7.5,4.9-4.6,7.6-0.8,10.1c8,4.5,15.2,7.3,21,6.8c13.6,4.4,21,6.2,24.4,6.4c9.3,3.9,16.4,0.4,22.1-7.9c2.2-9.2,1-16.3-3-21.4
		c0.9-5.6,0.8-14.3-4.5-25.5c-0.4-14.5-1.2-39.9-1.9-64.5c16.9-27.5,16-61.7,6.8-99c12.3-23.8,16.6-49.5,18-84
		c9.1-15.7,8.3-47.4,6.8-80.3l3.5-15.1L184.5,434.7L184.5,434.7z"
        />
        <path
          id="jawline"
          class="st1"
          d="M149.2,124.3c1.4-0.4,3-1.3,6.1-8c0-5.5,0-13.9,0-22.9"
        />
        <path
          id="ear"
          class="st1"
          d="M145.6,91.6c2.4,1.1,4.6,0.3,6.7-3.4c3.3-7.2,4-12.6,3-16.5c0-5.6-2-8.5-6.1-8.6
		c-3,0.6-4.2,2.3-4,4.9"
        />
        <g id="spine">
          <path id="Spine_5" class="st1" d="M181.5,224.7c2.3,6,0,10,0,12" />
          <path
            id="Spine_4"
            class="st1"
            d="M181.5,247.2c1.4,4.4,1.4,8.8,0,13.1"
          />
          <path
            id="Spine_3"
            class="st1"
            d="M181.5,270.4c1.4,4.9,1.4,9.8,0,14.6"
          />
          <path id="Spine_2" class="st1" d="M181.5,296.3c1.4,5,1.4,10,0,15" />
          <path id="Spine_1" class="st1" d="M181.5,325.2c1.4,4,1.4,8,0,12" />
        </g>
        <g id="Shoulder_x2F_arm_lines">
          <path
            id="arm_line_l"
            class="st1"
            d="M105.8,201.8c1.4,13.1,0.3,26-6.5,38.4"
          />
          <path
            id="arm_line_r"
            class="st1"
            d="M263.3,201.8c-2.4,9.9-2.5,20.8,0.4,33.4"
          />
          <path
            id="shoulder_blade_l_2"
            class="st1"
            d="M178.9,207.8c-2.7,34.7-0.7,69.7-22.5,72.4c-20.1,3.8-37.9,0.2-51.2-17"
          />
          <path
            id="shoulder_blade_r_2"
            class="st1"
            d="M186.8,207.3c0.8,31.2,1.6,66.3,35.6,72.4c14.9,6.3,25-1,32.9-14.2"
          />
          <path
            id="shoulder_blade_l_1"
            class="st1"
            d="M155.3,171.8c7.8,15.2,9,27.6,0.4,36"
          />
          <path
            id="shoulder_blade_r_1"
            class="st1"
            d="M214.9,171.8c-8.5,9-6.5,21.7,0,36"
          />
        </g>
        <g id="ribs">
          <path
            id="rib_6"
            class="st1"
            d="M119.5,290.6c6.8,4.4,14.4,5.8,22.6,5.7"
          />
          <path
            id="rib_5"
            class="st1"
            d="M121.5,303.8c5.1,2.2,10.1,3,15.2,2.2"
          />
          <path
            id="rib_4"
            class="st1"
            d="M121.5,311.3c3.1,1.8,6.2,2.4,9.3,1.8"
          />
          <path
            id="rib_3"
            class="st1"
            d="M221.9,294.8c6.8,1.4,13.3,0.3,19.4-4.2"
          />
          <path
            id="rib_2"
            class="st1"
            d="M224.7,303.8c7.4,0.9,13.5,0.7,15.2-2.8"
          />
          <path
            id="rib_1"
            class="st1"
            d="M231.6,313.1c2.8,0.9,5.5,0.3,8.3-1.8"
          />
        </g>
        <g id="inner_hands_left">
          <path
            id="fingers_r_5"
            class="st1"
            d="M54.7,449.4c-2.2,0.2-10.3-0.7-13.3-8.6c-1.9-5.6-1.8-9.6,0-12.4"
          />
          <path
            id="fingers_r_4"
            class="st1"
            d="M42.2,442.7c2.3,4.2,2.5,8.1-2.3,11.3"
          />
          <path
            id="fingers_r_3"
            class="st1"
            d="M35.7,468.3c-1.5,3.3-1.6,7.1,2.5,12.2"
          />
          <path
            id="fingers_r_2"
            class="st1"
            d="M39.9,470.9c-0.9,4.1,0,8.4,2.8,12.7"
          />
          <path
            id="fingers_r_1"
            class="st1"
            d="M32,463.8c-2.4,2.5-2.6,6-0.6,10.3"
          />
        </g>
        <g id="inner_hand_right">
          <path
            id="fingers_l_5"
            class="st1"
            d="M309.8,444.9c2.9-0.4,8.3-1.2,11-4.1c0.5-0.5,0.9-1.1,1.2-1.8c1-3.5,1.5-7.6,1.1-12.8"
          />
          <path
            id="fingers_l_4"
            class="st1"
            d="M320.9,448.3c-1.9-1.4-1.5-4.5,1.1-9.3"
          />
          <path
            id="fingers_l__3"
            class="st1"
            d="M332.5,458.7c1.1-1.1-0.9,9.7-0.6,10.3"
          />
          <path
            id="fingers_l_2"
            class="st1"
            d="M325.4,463.8c2.1,4.4,2.7,8.4-0.6,11.7"
          />
          <path
            id="fingers_l_1"
            class="st1"
            d="M320.9,468.3c2.9,3.4,1.9,6.8-0.5,10.2"
          />
        </g>
        <path
          id="elbow_r_2"
          class="st1"
          d="M306,263c5.6,4.6,8.9,9.6,10.6,14.7"
        />
        <path id="elbow_r_1" class="st1" d="M315.3,290.6c0.9,4.6,0,7.6-2.8,9" />
        <path
          id="elbow_l_2"
          class="st1"
          d="M50.9,276.5c2.1-7.5,6-11.8,11.2-13.5"
        />
        <path
          id="elbow_l_1"
          class="st1"
          d="M43.8,293c-0.6,6.9,0,10.7,1.8,11.3"
        />
        <g id="inner_leg_lines_l">
          <path
            id="inner_legs_lines_l_4"
            class="st1"
            d="M138.6,578c5.1,1.3,5.9,7.9,2.8,12.4"
          />
          <path
            id="inner_legs_lines_l_3"
            class="st1"
            d="M117.5,600.8c0.7,4.2,6.2,8.5,10.6,4.3"
          />
          <path
            id="inner_legs_lines_l_2"
            class="st1"
            d="M138.6,760c-2.1,17.5,0,27,6.2,28.4"
          />
          <path
            id="inner_legs_lines_l_1"
            class="st1"
            d="M128.1,763.4c2.6,11.5,2.6,20.2-2.4,23.3"
          />
        </g>
        <g id="inner_leg_lines_r">
          <path
            id="inner_leg_lines_r_4"
            class="st1"
            d="M224.7,575.5c-4.9,2.7-7.1,7.1-6.8,13.2"
          />
          <path
            id="inner_leg_lines_r_3"
            class="st1"
            d="M244.9,593.2c-1.1,5.3-4.1,7.1-9,5.3"
          />
          <path
            id="inner_leg_lines_r_2"
            class="st1"
            d="M235.9,741.7c-4.5,27-0.8,36.9,5.9,40.5"
          />
          <path
            id="inner_leg_lines_r_1"
            class="st1"
            d="M224.7,745.6c1.1,16.5,0,26.2-3.4,29.4"
          />
        </g>
        <g id="butt">
          <path
            id="butt_3"
            class="st1"
            d="M109.8,382.5c-3.1,19.5-3.7,38,9.1,45.4c19,19.7,41.5,10,62.5-4.1l0,0c-2.1-12.1-2.3-20.5,0-35.1
			"
          />
          <path
            id="butt_2"
            class="st2"
            d="M253.5,379.4c4.5,18.8,7.6,35.6-1.8,46.9c-17.1,15.5-34.5,23-68.7,0l-1.8-3.5"
          />
          <path
            id="butt_1"
            class="st1"
            d="M192.1,378.4c-5.4,0.3-9.4,2.9-10.6,10.4c0-8.6-5.4-12.3-13.9-15.5"
          />
        </g>
      </g>
      <g id="Selection">
        <path
          id="leg_left"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Human.id, Human.children.leftLeg),
            });
          }}
          class={`st3 ${isSelected(selected, Human.children.leftLeg)}`}
          d="M105.8,395.1c0,0-7.3,43.2-9.1,64.9c-3.2,28.6,2,54.8,8.6,80.6c4,26.3,5.1,48.6,2.2,65.6
		c-9,23-9.8,47.7-5.2,73.5c6,19.9,9.9,41.7,12.4,64.9c2.6,16.6,0.5,31-4.5,43.9c-5.5,8-13,9.8-21,10.5c-7.5,4.9-4.6,7.6-0.8,10.1
		c8,4.5,15.2,7.3,21,6.8c13.6,4.4,21,6.2,24.4,6.4c9.3,3.9,16.4,0.4,22.1-7.9c2.2-9.2,1-16.3-3-21.4c0.9-5.6,0.8-14.3-4.5-25.5
		c-0.4-14.5-1.2-39.9-1.9-64.5c16.9-27.5,16-61.7,6.8-99c12.3-23.8,16.6-49.5,18-84c9.1-15.7,8.3-47.4,6.8-80.3l3.5-15.1
		C140.7,454,111.6,435.5,105.8,395.1z"
        />
        <path
          id="leg_right"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Human.id, Human.children.rightLeg),
            });
          }}
          class={`st3 ${isSelected(selected, Human.children.rightLeg)}`}
          d="M181.6,424.6l2.9,10.1c-1.5,32.9-2.3,64.6,6.8,80.3c1.4,34.5,5.7,60.2,18,84
		c-9.2,37.3-10.1,71.5,6.8,99c-0.7,24.6-1.5,50-1.9,64.5c-5.3,11.2-5.4,19.9-4.5,25.5c-4,5.1-5.2,12.2-3,21.4
		c5.7,8.3,12.8,11.8,22.1,7.9c3.4-0.2,10.8-2,24.4-6.4c5.8,0.5,13-2.3,21-6.8c3.8-2.5,6.7-5.2-0.8-10.1c-8-0.7-15.5-2.5-21-10.5
		c-5-12.9-7.1-27.3-4.5-43.9c2.5-23.2,6.4-45,12.4-64.9c4.6-25.8,3.8-50.5-5.2-73.5c-2.9-17-1.8-39.3,2.2-65.6
		c6.6-25.8,11.8-52,8.6-80.6c-1.8-21.7-7-55.3-7-55.3C253.1,440.1,222.6,454,181.6,424.6z"
        />
        <path
          id="arm_left"
          onClick={() => {
            bpartSetter({ id: Human.children.leftArm });
          }}
          class={`st3 ${isSelected(selected, Human.children.leftArm)}`}
          d="M99.5,153.7c-32.5,7.9-38.8,34.4-38.4,62.4c-7,12.1-13.6,31.7-19.5,66.4
		c-6.4,9.5-11.1,20.7-13.5,34.1c0.4,34.8,0.5,68.8-5.2,86.6c-2.1,12.4-3,22.2-2.2,28.7c2.3,10.7,2.7,20.9,0,30.2
		c3.2,6.2,6.6,10.7,10.5,12.8c1.2,4.8,3.4,7,6.8,6.4c0.8,2.8,2.4,3.6,4.5,3c0.2,4.9,4,5.7,3.8,6.8l7.9,6.8c3-0.2,3.5-3.2,1.9-8.6
		l-7.9-11.2c-3.9-11-3.4-19.5,0.8-25.9l5.6-1.9l3.4,16.9c3.6,6.2,6.2,6.3,8.2,1.9l-3-18.8l-2.6-13.9c-2.4-7-7.3-12.7-13.9-17.6
		c-3.4-13.2,0.7-29.8,5.2-46.5c17.4-25.5,23.9-52.3,24.8-79.9c8.9-9,16.1-27.8,22.5-51.4C109.4,224.1,106.4,187.4,99.5,153.7z"
        />
        <path
          id="head"
          onClick={() => {
            bpartSetter({ id: Human.children.head });
          }}
          class={`st3 ${isSelected(selected, Human.children.head)}`}
          d="M213.4,122.9c-3.8-11.5-4.5-26.1,1.1-41.6c6.4-8.9,6.1-21.5,2.6-36c-1.1-10.5-6.6-16.9-15.4-20.2
		c-7.1-5.8-18.3-6.8-30.4-6.8c-29,4.5-33.3,17.1-32.9,31.2c-1.5,15.6-2.1,26.9-1.6,32.6c1.8,13.4,3.9,25,7.5,30.8
		c1.7,7,3.5,13.2,7.1,13.1c4.4,1.2,4.6,3.4,3,6L213.4,122.9z"
        />
        <path
          id="arm_right"
          onClick={() => {
            bpartSetter({ id: Human.children.rightArm });
          }}
          class={`st3 ${isSelected(selected, Human.children.rightArm)}`}
          d="M263.4,236c6.4,23.6,13.6,42.4,22.5,51.4c0.9,27.6,7.3,54.4,24.8,79.9
		c4.5,16.7,8.7,33.3,5.2,46.5c-6.6,4.9-11.5,10.6-13.9,17.6l-2.6,13.9l-3,18.8c2,4.4,4.7,4.3,8.2-1.9l3.4-16.9l5.6,1.9
		c4.1,6.4,4.6,14.9,0.8,25.9l-7.9,11.2c-1.6,5.4-1.1,8.4,1.9,8.6l7.9-6.8c0.4-2.5,5.2-4,3.8-6.8c2.1,0.6,3.7-0.2,4.5-3
		c3.3,0.6,5.6-1.6,6.8-6.4c3.9-2.1,7.3-6.5,10.5-12.8c-2.7-9.3-2.3-19.5,0-30.2c0.8-6.4-0.1-16.3-2.2-28.7
		c-5.8-17.8-5.7-51.8-5.2-86.6c-2.4-13.4-7.1-24.6-13.5-34.1c-5.9-34.7-12.5-54.2-19.5-66.4c0.4-29-11.1-50.5-46.5-57.4
		c-0.6,5.6,0.5,16.4,8.3,48.1C260.8,216.4,261,229.3,263.4,236z"
        />
        <path
          id="Torso"
          onClick={() => {
            bpartSetter({ id: Human.children.mainBody });
          }}
          class={`st3 ${isSelected(selected, Human.children.mainBody)}`}
          d="M99.7,153.7c26.1-4.2,44.1-11.5,54.7-21.7l59-9.1c4.9,11.2,19.2,21.4,41.6,30.8
		c0.3,11,2.5,25.9,8.3,48.1c-2.2,10.5-2.6,21.7,0.1,34.2c-4.4,21.3-9.8,35.4-15.8,45c-2.2,14.3-3,28.5,1.1,42.4l2,40.3l-138.7,0.3
		l2-35.6c3.5-9.5,3.6-24,1.1-42.4c-7.3-10.8-11.9-27.5-15.9-45.8c4.6-5.6,6.6-18.2,6.5-38.4L99.7,153.7z"
        />
        <path
          id="Pelivs"
          onClick={() => {
            bpartSetter({ id: Human.children.pelvis });
          }}
          class={`st3 ${isSelected(selected, Human.children.pelvis)}`}
          d="M112.1,363.9c0-0.1-0.1,11.8-2.9,21.6c-2.3,4.3-2.8,9.5-3.2,10.7c3,15.2,3.3,18.7,12.1,30
		c13.8,17.4,34.4,17.6,63.3-2.4c20.3,17.1,41.9,25.7,67.9,4.6c8-4.6,12.3-15.6,4.2-49c-1.3-4.4-2.2-9.9-2.8-15.8L112.1,363.9z"
        />
        <path
          id="Spine"
          class={`st3 ${isSelected(selected, Human.children.spine)}`}
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Human.id, Human.children.spine),
            });
          }}
          d="M161.8,435.5c5.3-1.5,11.3-4.4,19.6-11.8c6,5.3,12.6,9.9,20.5,13.3c-3.2-70.7-6.5-141.3,0-176.8
		c-7.4-31.2-4.4-82,0-135.1c-11.9,0.4-21.9,2.2-31.6,4.3c0.7,45.5,3.3,93.1-3.6,130.8C172.7,294.3,165.8,369.6,161.8,435.5z"
        />
      </g>
    </svg>
  );
}

export default BackSideView;
