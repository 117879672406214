import style from "./style.scss";

function RechteHandwurzelLatSitzend1354(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.71 348.34">
      <g id="background">
        <path
          id="background_white"
          data-name="background white"
          class="Rechte_Handwurzel_lat_sitzend1354_cls4"
          d="M7.47,8.92c-1.9,7.4,1.8,14.4,15.2,20.6,17.9,14.8,33.8,30.1,44.4,46.8,3.2,2.8,5.1,6.3,5.6,10.6-.5,2.8-.5,5.3.9,7.1,4.7,4.4,9.5,6.7,14.4,6.3,1.9-.1,3.7.7,5.4,2.4-3.8,6.6-5.9,12.7-4.1,17.8,2.5,6.3,6.5,10.8,14.1,10.9,1.2,4.6,2.4,8.9,4.2,9.1-1.4,3.5-1.8,7.1,0,10.9,4.8,5.8,12.1,6.7,21.7,9.8-.2,5.7.8,11.1,5.2,15.2l7.1,6.5c-3.1.1-4.5,2.5-5.5,5.3-6.8-.7-10.8.5-9.5,5.2-6.3.4-9,2.9-4.7,9.6-2.3,6-1.6,12.8,3.3,20.7.4,6,3.1,13.2,6.7,21,5.2,5.2,8,13,7.3,24.5l-2.7,78.1h0l53.4,1c-.3-29.7,12.4-93.3,21.9-136.2,2.3-16.3-3.8-23-12.2-27l-8.4-2.2-1-4.9c1.8-1.1,3.6-3.6,5.5-7.3,4.2-6.7,4.3-14.7,2.7-23.1-2-5.4-5-9-9-10.7v-3c3.1-4.8,3.2-10.6,2.4-16.7,2.3-5.7,2.7-11.6,1.2-17.5l6.5-12.4v-11.1c4.7,1.8,6.3-2.7,4.9-12.9l-2.9-15.7-5.9-34c.1-3.5,0-7.2,0-10.8h-2.8c-15.5.1-31,.1-46.6-.9-.6,0-1.2-.1-1.8-.1.1.8.2,1.5.3,2.3.6,12-4.4,24.7-12.6,39.6-2.5,6.1-2.7,12.2-.5,18.2l-5.9-1.6c-5.5-10.5-12.7-16.9-26.8-19.4C82.97,26.72,69.07,12.92,61.47.22,41.07.32,20.77.52.37.32c.8,2.9,3.1,5.8,7.1,8.6Z"
        />
      </g>
      <g id="full_Handwurzel" data-name="full Handwurzel">
        <g id="Boneparts">
          <path
            id="boneparts26"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M189.77,348.32c1.1-27,6.8-62.5,13.7-100.2l7.3-31.3c3.8-14.1.1-24.6-11.2-31.6-8.7-3.4-17.3-2.9-25.8,1.8-15,4.4-24.5,1.9-31.3-4-3.2-.5-5.4,1.1-6.4,5.2-1.6,4.6-4.7,6.6-9.1,6.4-7,.2-9,2.9-4.9,8.8-2.4,6.6-.4,13.4,3,20.4.7,7,3.2,14,6.7,21,5.1,6.5,7.8,15.4,7.3,27.6l-2.4,75.9"
          />
          <path
            id="boneparts25"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M191.17,327.32c-2.9-28.1-1.1-63.6,3.9-104.1,2.5-10.9,2.8-19.2,0-24.1-4-6.9-10.2-6.9-17-5.3-10,5-23.1,5.3-38.4,2.1-5.3-.3-8.7,3.4-10.5,10.3-1.8,8.9-2.6,16.9.8,21.9,5.1,6.9,8.1,14.8,10.1,23.1,1.6,6.4,2.3,12.9,2.4,19.4l1.4,76.6"
          />
          <path
            id="boneparts24"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M136.47,186.92c-8.2.4-11.5,2.8-9.5,7.7-2.8,2.5-4.3,5.4-4.9,8.8"
          />
          <path
            id="boneparts23"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M191.77,195.12c.8-16.5-3.5-22.2-11.1-20.9-7.4,2.2-9.4,20.9-14.6,23.3"
          />
          <path
            id="boneparts22"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M182.37,183.02c14-6.9,17.7-17.6,16.9-29.9-.2-3.2-1-6.3-2.5-9-3.1-5.4-7.4-7.4-12-8.9-11.6-1.3-19.8-1.1-25.1-2.7-3-.9-5.4-3.2-6.7-6.1l-4.1-9.4c-1-2.2-3-3.7-5.3-3.8-6.8-.4-13.1,1.7-19.2,5.7-9.7,6.8-14.7,14.6-17.4,22.9-.6,1.9-.9,3.9-.6,5.9.6,4.9,3.9,8.1,10,9.6,3.3.7,6.6,1.6,9.8,2.6l.4.1c2.7.9,5.2,2.4,7.2,4.4,3.9,3.8,10.4,10.3,14.1,14,2.4,2.4,5.1,4.2,8.2,5.4,4.2,1.6,8.3,2.2,12.5,2.1,6.7-.1,13-3.2,17.2-8.4,5.6-6.9,9.2-15.6,8.7-27.9,0-3-1.4-4.2-3.8-4.1-1,.1-1.9.4-2.8.8-6,2.8-11.5,4.6-16.5,5-8.9,1.6-16.1-1.2-22.7-5.7-1.8-1.2-3.3-2.9-4.7-4.6-1.6-2-3.7-2-6.2-.8-3.2,1.5-5.5,4.3-6.7,7.6-1.4,3.9-1.9,8.3-1.9,13.2,0,6.6,1.1,12.4,5.7,15.6,1.9,1.3,3.7,2.7,5.4,4.3l2.1,2c2.2-2.3,3.3-9.2,7.5-17.5.9-1.7,2-3.3,3.6-4.5,2.1-1.6,4.6-2.4,7.4-2.1,3.7.4,6.8,2.7,8.7,5.8,4.8,8.1,10.1,15,16.7,18.4"
          />
          <path
            id="boneparts21"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M140.87,115.72l7.8,21.7c.9,2.6,2.5,5,4.6,6.8,7.2,6.2,15.5,6.4,24.7,1.6,5.4-3,9.3-7.9,12.5-13.6,1.8-3.2,2.3-6.9,1.5-10.4-1.3-6-1.4-12.3-.7-18.7l6.2-11.1c1.4-2.4,2.1-5.2,2.1-8.1v-2.8c0-3.2-1.5-6.2-4-8.1-3.5-2.6-7.4-4.1-11.6-4.6-2.6-.3-5.3.1-7.7,1.2-6.7,3.2-15.3,4.9-24.6,6.1-1.5.2-3.1.3-4.6.3-3.8.1-7.1,1.8-9.5,5.8-7.4,8.1-4.1,20.3,3.3,33.9h0Z"
          />
          <path
            id="boneparts20"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M151.57,75.72l-12.3-9c-7-6.1-14.6-7.5-22.5-5.6-6.6,2.9-10.3,8.8-11.4,17.2-.5,3.9.2,7.8,2.3,11.1.5.8,1.1,1.6,1.7,2.4,3.3,4.1,8.7,5.6,13.8,4.4,4.5-1.1,9.3-1.2,14.9.4,11.2,7,5.9,42.1,10.6,49.2,4.3,8,11.2,12.8,23.6,11,5.8-1.7,10.3-4.8,12.8-9.8.8-1.6,1.3-3.3,1.6-5.1l4.5-23.3c2.8-6.2,3.5-12.5,1.8-18.8-.7-12.2-2.3-21-6.3-22l-6.4-1c-4.6-2.6-8.8-1.2-12.9,1-4,3-9.7,1.2-15.8-2.1h0Z"
          />
          <path
            id="boneparts19"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M173.27,110.42c-7.3-4.9-14.8-5.6-22.5-2.8-5.4,2.2-8.2,7.6-8.2,16.3,0,11,2.2,20.9,7.1,29.2,5.7,7.6,12.4,12.7,20.8,13,8.8-1.4,14.5-5.1,15.8-11.8,1-4.8,1.9-9.3,2.8-13.8v-10.3c-6.9-4.8-11.8-11.8-15.8-19.8h0Z"
          />
          <path
            id="boneparts18"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M89.77,109.72c-1.5,4.1-1.6,8.7.1,12.8.9,2.4,2.3,4.5,4.4,6,1.7,1.3,3.7,2,5.7,2.4,12.3,2.4,24.3,1.5,35.5-4.1,13.4-6.4,23.9-15.7,31.1-28.4,2.1-4.2,2.8-7.3,2.4-9.6-.5-2.9-3.2-4.8-6.2-4.9-6-.1-12.2-2.7-18.5-6-6.6-2.6-12.6-2-17.8,2.4-3.3,5.1-10.5,9.6-18.8,13.8-2.2,1.1-4.5,2.1-6.8,2.8-5.4,1.6-8.8,6.2-11.1,12.8h0Z"
          />
          <path
            id="boneparts17"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M.47.22c.8,2.2,2.4,4.7,5.3,7.8,1,1,1.5,2.3,1.5,3.6.1,6.5,3,12.4,12.9,16.6,1.7.8,3.3,1.8,4.8,3,16.1,13.2,30.7,28.1,42.6,46.1,3.6,4,5.6,8.6,5.2,13.9-.1,1.4.4,2.9,1.5,3.8,4.4,3.6,9,6.4,14.3,5.1,1.8.2,3.3.6,4.1,1.9.3.5.8,1,1.4,1.2,7.7,2.4,15.4.2,22.9-6.9h0c6.3-4.8,10-10.8,11.8-17.8,2.7-7.5,1-15-6.8-22.3-4.6-7.8-11.4-12.1-19.6-14-2.1-.4-4.1-1.4-5.7-2.9C80.07,23.82,68.37,11.02,61.47.92c-.1-.2-.3-.5-.4-.7"
          />
          <path
            id="boneparts16"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M79.07,89.12c2.7,2,5.8,3.5,9.7,3.9,3.2.3,6.4-.2,9.5-1.3,7.5-2.7,14.7-6.3,21.2-12,1.4-1.2,2.6-2.7,3.5-4.4,3.2-6.1,2.8-12.5-.9-19.1"
          />
          <path
            id="boneparts15"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M37.87,20.52c4.6,15.6,31.4,42.7,36,61.8"
          />
          <path
            id="boneparts14"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M119.87,58.12c-5.1-6.2-11.2-9.4-17.8-11.1-5.3-.6-10.4-3.8-15.4-8.1L50.77,5.62"
          />
          <path
            id="boneparts13"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M5.87,2.92c.6,3.7,2.2,6.1,5,8.6,2.3,2,5.2,3.7,6.4,6.7"
          />
          <path
            id="boneparts12"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M163.27,2.92c-.9,19.5-4.6,36.2-10.9,50-1.5,3.4-2.4,7-2.3,10.7.2,6.1,2.9,9.2,8.9,8.3h0l21.9-4.9c7.9.1,14.2,3.1,18.7,9.4,4.5,1.8,6.4-2,4.8-13.7l-8-43c-.9-4.6-1.9-9.1-3-13.6-.2-1-.5-2.1-.7-3.2"
          />
          <path
            id="boneparts11"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M143.07,69.42c1.7-6.5-3.6-12.2-2.9-18.7.7-6,4.1-11,6.9-16.2,4.8-8.8,5.2-20.5,6-30.3"
          />
          <path
            id="boneparts10"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M185.37,4.92c.3,16.4,1.3,31.4,6.3,46.4,2,8-1,15-7,19-6,5-14,6-21.4,3.5"
          />
          <path
            id="boneparts9"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M155.07,71.62c-6.4,3.7-13.4,3.7-20.4,1.7-4-9-6-19-3-28,6-13,13-26,13.1-41"
          />
          <path
            id="boneparts8"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M171.37,5.32c1.3,16,4.3,32,12.3,46,3,5,3,12-2.8,15.5"
          />
          <path
            id="boneparts7"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M195.77,16.52c0-3.7,0-7.3-.1-11"
          />
          <path
            id="boneparts6"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M159.67,3.92c0,7.4-2,14.4-4,21.4-6,13-9,26-9,40,1,3,3,7,6.9,5.8"
          />
          <path
            id="boneparts5"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M189.37,68.32c7.7.8,10.9-7.9,12.2-20.6"
          />
          <path
            id="boneparts4"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M199.47,76.22c1.1-2.7,1.1-5.4.2-8.2-1.2-3.5-4.8-5.4-8.2-4.4-2,.6-4,1.9-6.2,3.6"
          />
          <path
            id="boneparts3"
            class="Rechte_Handwurzel_lat_sitzend1354_cls5"
            d="M103.27,131.32c2.3,7.7,4.8,14,7.5,18.1,2.4,3.6,6.7,5.3,10.9,4.4l11.7-2.5c5.4-1.7,7.8-6.1,7.4-12.9,0-1.7-.2-3.4-.2-5.1-.2-8.7-2.1-16.1-7.2-21.1-5.9-5.9-12.6-5.5-19.9-1.8-8.7,4.4-12.3,11.3-10.2,20.9h0Z"
          />
          <path
            id="boneparts2"
            class="Rechte_Handwurzel_lat_sitzend1354_cls5"
            d="M183.67,108.72l9.3-18.6c.8-1.6,1.4-3.4,1.6-5.2.7-6.3-.2-11.4-5.1-13.5-6.4-2.7-14.3-1.4-23.4,3.1-5.2,3.3-8.2,9-7.4,18.7,1.1,7,2.2,13.9,5.2,18.9,1.4,2.4,3.8,3.9,6.5,4.2,5.5.6,10.1-1.4,13.3-7.6h0Z"
          />
          <path
            id="boneparts1"
            class="Rechte_Handwurzel_lat_sitzend1354_cls6"
            d="M199.57,185.22c-7.6.9-14.1,3.9-19.6,8.3"
          />
        </g>
      </g>
      <g id="handwurzel_highlights" data-name="handwurzel highlights">
        <g id="Handwurzel_Highligts" data-name="Handwurzel Highligts">
          <path
            id="hwhighlihgt7"
            class="HWK1354Fill"
            style={{
              fill: props.colors.HWK1354Fill,
              stroke: props.colors.HWK1354Fill,
            }}
            d="M182.72,183.22c14-6.9,17.35-17.8,16.55-30.1-.2-3.2-1-6.3-2.5-9-3.1-5.4-7.4-7.4-12-8.9-11.6-1.3-19.8-1.1-25.1-2.7-3-.9-5.4-3.2-6.7-6.1l-4.1-9.4c-1-2.2-3-3.7-5.3-3.8-6.8-.4-13.1,1.7-19.2,5.7-9.7,6.8-14.7,14.6-17.4,22.9-.6,1.9-.9,3.9-.6,5.9.6,4.9,3.9,8.1,10,9.6,3.3.7,6.6,1.6,9.8,2.6l.4.1c2.7.9,5.2,2.4,7.2,4.4,3.9,3.8,4.9,14.8,8.6,18.5,2.4,2.4,7.34,4.31,10.44,5.51,3.21.91,10.88,1.2,16.26-.22,6.7-.93,12.49-5.49,16.69-10.69,5.6-6.9,9.2-15.6,8.7-27.9,0-3-1.4-4.2-3.8-4.1-1,.1-1.9.4-2.8.8-6,2.8-11.5,4.6-16.5,5-8.9,1.6-16.1-1.2-22.7-5.7-1.8-1.2-3.3-2.9-4.7-4.6-1.6-2-3.7-2-6.2-.8-3.2,1.5-5.5,4.3-6.7,7.6-1.4,3.9-1.9,8.3-1.9,13.2,0,6.6,1.1,12.4,5.7,15.6,1.9,1.3,3.7,2.7,5.4,4.3l2.1,2c2.2-2.3,3.3-9.2,7.5-17.5.9-1.7,2-3.3,3.6-4.5,2.1-1.6,4.6-2.4,7.4-2.1,3.7.4,6.8,2.7,8.7,5.8,4.8,8.1,2.8,16.03,9.4,19.43"
          />
          <path
            id="hwhighlihgt6"
            class="HWK1354Fill"
            style={{
              fill: props.colors.HWK1354Fill,
              stroke: props.colors.HWK1354Fill,
            }}
            d="M140.36,115.72l7.8,21.7c.9,2.6,2.5,5,4.6,6.8,7.2,6.2,15.5,6.4,24.7,1.6,5.4-3,9.3-7.9,12.5-13.6,1.8-3.2,2.3-6.9,1.5-10.4-1.3-6-1.4-12.3-.7-18.7l6.2-11.1c1.4-2.4,2.1-5.2,2.1-8.1v-2.8c0-3.2-1.5-6.2-4-8.1-3.5-2.6-7.4-4.1-11.6-4.6-2.6-.3-5.3.1-7.7,1.2-6.7,3.2-15.3,4.9-24.6,6.1-1.5.2-3.1.3-4.6.3-3.8.1-7.1,1.8-9.5,5.8-7.4,8.1-4.1,20.3,3.3,33.9h0Z"
          />
          <path
            id="hwhighlihgt5"
            class="HWK1354Fill"
            style={{
              fill: props.colors.HWK1354Fill,
              stroke: props.colors.HWK1354Fill,
            }}
            d="M151.06,75.72l-12.3-9c-7-6.1-14.6-7.5-22.5-5.6-6.6,2.9-10.3,8.8-11.4,17.2-.5,3.9.2,7.8,2.3,11.1.5.8,1.1,1.6,1.7,2.4,3.3,4.1,8.7,5.6,13.8,4.4,4.5-1.1,9.3-1.2,14.9.4,11.2,7,5.9,42.1,10.6,49.2,4.3,8,11.2,12.8,23.6,11,5.8-1.7,10.3-4.8,12.8-9.8.8-1.6,1.3-3.3,1.6-5.1l4.5-23.3c2.8-6.2,3.5-12.5,1.8-18.8-.7-12.2-2.3-21-6.3-22l-6.4-1c-4.6-2.6-8.8-1.2-12.9,1-4,3-9.7,1.2-15.8-2.1h0Z"
          />
          <path
            id="hwhighlihgt4"
            class="HWK1354Fill"
            style={{
              fill: props.colors.HWK1354Fill,
              stroke: props.colors.HWK1354Fill,
            }}
            d="M172.76,110.42c-7.3-4.9-14.8-5.6-22.5-2.8-5.4,2.2-8.2,7.6-8.2,16.3,0,11,2.2,20.9,7.1,29.2,5.7,7.6,12.4,12.7,20.8,13,8.8-1.4,14.5-5.1,15.8-11.8,1-4.8,1.9-9.3,2.8-13.8v-10.3c-6.9-4.8-11.8-11.8-15.8-19.8h0Z"
          />
          <path
            id="hwhighlihgt3"
            class="HWK1354Fill"
            style={{
              fill: props.colors.HWK1354Fill,
              stroke: props.colors.HWK1354Fill,
            }}
            d="M89.26,109.72c-1.5,4.1-1.6,8.7.1,12.8.9,2.4,2.3,4.5,4.4,6,1.7,1.3,3.7,2,5.7,2.4,12.3,2.4,24.3,1.5,35.5-4.1,13.4-6.4,23.9-15.7,31.1-28.4,2.1-4.2,2.8-7.3,2.4-9.6-.5-2.9-3.2-4.8-6.2-4.9-6-.1-12.2-2.7-18.5-6-6.6-2.6-12.6-2-17.8,2.4-3.3,5.1-10.5,9.6-18.8,13.8-2.2,1.1-4.5,2.1-6.8,2.8-5.4,1.6-8.8,6.2-11.1,12.8h0Z"
          />
          <path
            id="hwhighlihgt2"
            class="HWK1354Fill"
            style={{
              fill: props.colors.HWK1354Fill,
              stroke: props.colors.HWK1354Fill,
            }}
            d="M102.76,131.32c2.3,7.7,4.8,14,7.5,18.1,2.4,3.6,6.7,5.3,10.9,4.4l11.7-2.5c5.4-1.7,7.8-6.1,7.4-12.9,0-1.7-.2-3.4-.2-5.1-.2-8.7-2.1-16.1-7.2-21.1-5.9-5.9-12.6-5.5-19.9-1.8-8.7,4.4-12.3,11.3-10.2,20.9h0Z"
          />
          <path
            id="hwhighlihgt1"
            class="HWK1354Fill"
            style={{
              fill: props.colors.HWK1354Fill,
              stroke: props.colors.HWK1354Fill,
            }}
            d="M183.16,108.72l9.3-18.6c.8-1.6,1.4-3.4,1.6-5.2.7-6.3-.2-11.4-5.1-13.5-6.4-2.7-14.3-1.4-23.4,3.1-5.2,3.3-8.2,9-7.4,18.7,1.1,7,2.2,13.9,5.2,18.9,1.4,2.4,3.8,3.9,6.5,4.2,5.5.6,10.1-1.4,13.3-7.6h0Z"
          />
        </g>
        <g id="radius_Highlights" data-name="radius Highlights">
          <path
            id="boneparts26-2"
            data-name="boneparts26"
            class="Radius1354Fill"
            style={{
              fill: props.colors.Radius1354Fill,
              stroke: props.colors.Radius1354Fill,
            }}
            d="M190,348.32c1.1-27,6.8-62.5,13.7-100.2l7.3-31.3c3.8-14.1.1-24.6-11.2-31.6-8.7-3.4-17.3-2.9-25.8,1.8-15,4.4-24.5,1.9-31.3-4-3.2-.5-5.4,1.1-6.4,5.2-1.6,4.6-4.7,6.6-9.1,6.4-7,.2-9,2.9-4.9,8.8-2.4,6.6-.4,13.4,3,20.4.7,7,3.2,14,6.7,21,5.1,6.5,7.8,15.4,7.3,27.6l-2.4,75.9"
          />
          <path
            id="boneparts24-2"
            data-name="boneparts24"
            class="Radius1354Fill"
            style={{
              fill: props.colors.Radius1354Fill,
              stroke: props.colors.Radius1354Fill,
            }}
            d="M137.12,186.92c-3.7-1.82-14.05,3.53-9.92,7.7,10.63.34,13.4-15.23,0,0"
          />
        </g>
        <path
          id="ulna_Highlight"
          data-name="ulna Highlight"
          class="Ulna1354Fill"
          style={{
            fill: props.colors.Ulna1354Fill,
            stroke: props.colors.Ulna1354Fill,
          }}
          d="M189.59,347.76c2.46-25.07-2.09-84.39,5.71-124.54,2.5-10.9,2.8-19.2,0-24.1-4-6.9-10.2-6.9-17-5.3-10,5-23.1,5.3-38.4,2.1-5.3-.3-8.7,3.4-10.5,10.3-1.8,8.9-2.6,16.9.8,21.9,5.1,6.9,8.1,14.8,10.1,23.1,1.6,6.4,2.3,12.9,2.4,19.4l1.42,77.7"
        />
      </g>
    </svg>
  );
}

export default RechteHandwurzelLatSitzend1354;
