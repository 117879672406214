import style from "./style.scss";

export default function LinkerOberschenkelMitKnieVdLiegend2343(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 904.7 1088.2"
    >
      <g id="fullknee">
        <g id="underlay">
          <ellipse
            id="joint_gap"
            data-name="joint gap"
            class="Gelenkspalt2343Fill"
            style={{
              fill: props.colors.Gelenkspalt2343Fill,
              stroke: props.colors.Gelenkspalt2343Fill,
            }}
            cx="423.6"
            cy="824.5"
            rx="138.8"
            ry="55.5"
            transform="translate(-20.4 10.8) rotate(-1.4)"
          />
        </g>
        <g id="knee_with_upper_leg" data-name="knee with upper leg">
          <path
            id="background_lower_leg"
            data-name="background lower leg"
            class="Oberschenkel_mit_Knie_vd_left2343_cls7"
            d="M358,1042.2c3.8,22.8,7,27.1,7,42s0,2.7,0,3.8c17,0,34.1.2,51.1.2s42.7-.5,63.9-1.3c.6-4.8,1.3-10,2-15.7,3.8-29.4,7.5-43.9,12-57,3.9-11.2,9.9-25.6,12-25,1.8.5.2,12.5-1,22-2.5,18.7-4.9,22-3,30,1.8,7.6,4.5,6.4,9,17,4.6,10.8,5.7,20.8,5.9,27,2.1,0,4.1-.2,6.2-.3,11.4-.6,22.9-.9,34.3-.8,5-14.6,10.1-26.9,12.6-32.9,10.5-24.9,16.8-34.1,20-54,2.8-17.3.5-25.8-1-30-3.3-9.3-7.1-10.2-10-21-2.5-9.1-.6-11.9-4-23-3.8-12.6-7.8-14.5-9-15-5.2-2.1-9.6,3-12,1-3.1-2.5,3.7-11.1,5-24,1.8-17.6-7.7-31.1-9-33-2.3-3.2-7.9-11-18-15-11.5-4.5-23-1.8-35,1-14.2,3.4-18.4,7.4-27,5-7.1-2-13.2-7.3-14-8-7.4-6.5-6.8-10.3-12-12-5.4-1.7-11.6.7-15,2-5.4,2.1-6.3,4.1-10,4-3.2,0-4.1-1.6-10-4-8.1-3.3-9.2-3.3-10-3-3.1,1.1-2.7,4.8-6,9-.9,1.1-3.3,4-11,7-9.3,3.7-18.2,4.4-27,5,0,0-15.2,1.1-41,5-3.2.5-8.7,1.4-13.4,5.6-.9.8-3.9,3.6-6.6,10.4-1.9,4.9-3.4,11.4-3,21,0,0,.8,20.6,16,47,5.8,10,4.2,4.9,26,36,0,0,15.5,22,21,42,3.2,11.7.3,9.4,4.1,32h0Z"
          />
          <path
            id="tibia_outines"
            data-name="tibia outines"
            class="Oberschenkel_mit_Knie_vd_left2343_cls12"
            d="M480.2,1084.2c2.8-25.3,5.8-49.3,14.8-73.3,8-20,23-35,35-54,21-34,43-79,15-113-18-13-40-8-59-1-18,6-31-9-43-21s-6-2-9,0c-6,5-13,9-20,7-4-2-8-6-13-7-3,0-7,2-8,7,0,1-1,1-2,2,0,1,0,2-1,3-6,5-14,6-21,7-24,3-48,4-69,14-12,12-9,31-6,46,2,21,15,39,30,54s3,5,5,7,2,5,4,8l3,3c21,35,26,74,28.2,113.4"
          />
          <path
            id="fibula_outlines"
            data-name="fibula outlines"
            class="Oberschenkel_mit_Knie_vd_left2343_cls12"
            d="M515.9,1082.9c-1.9-7.1-1.9-14.1-4.9-21.1-5-11-11-21-9-33,3-20,2-39,3-59,2-31,30-53,57-62,9,2,11,11,14,18,4,15,7,30,14,45,7,17,0,36-7,52-9,20-20,38-25.6,59"
          />
          <path
            id="tibia_1"
            data-name="tibia 1"
            class="Oberschenkel_mit_Knie_vd_left2343_cls12"
            d="M305.9,857.2c44.9,7.4,81.7-4.3,82-22"
          />
          <path
            id="tibia_2"
            data-name="tibia 2"
            class="Oberschenkel_mit_Knie_vd_left2343_cls12"
            d="M550.7,851.1c-44,15.5-80.3,7.7-80.7-7.9"
          />
          <path
            id="fibula_1"
            data-name="fibula 1"
            class="Oberschenkel_mit_Knie_vd_left2343_cls11"
            d="M504,1011.2c4.8,0,8.7,25.2,8.7,56.4"
          />
          <path
            id="fibula_2"
            data-name="fibula 2"
            class="Oberschenkel_mit_Knie_vd_left2343_cls11"
            d="M574,1020.2c-1.5.3-4.2-21.2,7-49"
          />
          <path
            id="fibula_3"
            data-name="fibula 3"
            class="Oberschenkel_mit_Knie_vd_left2343_cls11"
            d="M519.5,1081.3c-1.8-4.8-4-9.4-6.8-13.7"
          />
          <path
            id="tibia_3"
            data-name="tibia 3"
            class="Oberschenkel_mit_Knie_vd_left2343_cls11"
            d="M347.9,997.2c6.6,0,10.2,10.4,12,15.2,4.9,13.2,7.4,27.4,9.6,41.2,1.6,10.7,2.9,21.5,3.9,32.3"
          />
          <path
            id="tibia_4"
            data-name="tibia 4"
            class="Oberschenkel_mit_Knie_vd_left2343_cls11"
            d="M557.9,878.1c-13.2,4.9-22.2,31.3-13.4,53.3"
          />
          <path
            id="tibia_5"
            data-name="tibia 5"
            class="Oberschenkel_mit_Knie_vd_left2343_cls11"
            d="M320.9,904.8c26.9-4.9,51.6-6.6,60,0"
          />
          <path
            id="tibia_6"
            data-name="tibia 6"
            class="Oberschenkel_mit_Knie_vd_left2343_cls11"
            d="M409.4,892.2c0-14,98.9,12.6,118.5,4"
          />
          <path
            id="tibia_7"
            data-name="tibia 7"
            class="Oberschenkel_mit_Knie_vd_left2343_cls11"
            d="M387.9,835.2c4.8-6,53.9,33,77,12.1"
          />
          <g id="upper_leg" data-name="upper leg">
            <path
              id="upper_leg_background"
              data-name="upper leg background"
              class="Oberschenkel_mit_Knie_vd_left2343_cls7"
              d="M518,393.5c2.4-29.3,8.8-105.2,19.6-189.6,1.8-14.1,5.9-43.8,14-103.2,5.1-37.6,7.7-56.4,8.3-59,5.6-26.5,9.4-31.7,5.8-39.9h0c-3.3.2-6.7.2-10,0-17.9-.1-35.9-.7-53.8-1.3C489.4.5,476.9.4,464.4,0c-1.8,6.1-2,14.7-3.7,31-2.5,24-8.4,47.6-11.9,71.5-13.8,95.6-29.4,178-29.4,178-9.2,48.8-21.3,112.2-43.8,193.8h0c-8.4,30.7-16.2,56.2-22,74.6-1.7,3.1-.5,3.5-3,14-3.3,14.2-8,24.3-12,33-7.8,16.8-17.2,39-38.2,57.7-6.3,5.7-12.5,9.9-18.5,19.5-2.7,4.3-10,16.1-9.6,31.7,0,2.5.3,6.6,6.2,23.7,11.5,33.2,17.3,37.4,15.1,49.3-1.1,5.8-3.4,9.8-3.1,18.4.1,4.2.3,10.9,4.3,16.7,6.6,9.6,19.4,10.9,22.8,11.3,17.3,1.8,31.5-7.9,41-14.3,16.5-11.2,18.1-19.1,34.3-25.3,9.8-3.7,16.5-3.6,19.2-3.4,11.7.8,19.9,6.9,23.9,9.7,28.3,19.5,63.7,21.8,71.5,22.1,14.6.6,31.5,1.3,42-10.1,12.4-13.4,9.2-36.2,8.3-42.4-1.5-10.7-4-10.5-7.3-26.6-3.3-16-1.2-18.1-4-33-3-15.6-8-27.2-11-34-7.9-18-11.7-18-17.4-31.8-1.1-2.6-2-5.1-2.7-7.5-5-16.8-5.9-35.7-5.9-35.7-.6-13.3-1.5-21.7-2.8-28.3-2.1-11.9-4.3-19.7-5.2-23.7-5.5-22.5,3.1-43.8,7.7-59.2,4.7-14.3,2.7-13.9,8.8-87.2h0Z"
            />
            <path
              id="femur_outlines"
              data-name="femur outlines"
              class="Oberschenkel_mit_Knie_vd_left2343_cls12"
              d="M501.8,540c4.6,21.6,6.9,39.4,8,52,1.7,18.4.6,20.1,3,29,4.2,15.6,9.8,18.9,23,46,6.1,12.5,9.1,18.7,11,26,4.4,16.8.4,20.7,4,41,3.3,18.8,7.3,18.9,8,33,.5,9.6,1.3,25.5-9,36-8.3,8.5-19.8,9.2-32,10-17.5,1.1-30.9-2.9-41-6-13.2-4.1-32-11.8-49-20-6.5-3.1-12.5-6.3-21-6-6.7.2-12.7,2.5-23,9-19.1,12-18.3,16.5-31,24-6.1,3.6-26.4,15.6-45,8-2.4-1-11.8-4.8-16-14-4.8-10.5,2.6-16.4,2-33-.2-6.3-3.5-15.2-10-33-8-21.9-10.1-22.9-11-32-.5-4.7-1.5-17.3,5-30,3.9-7.5,8.8-12,11-14,22.6-21,42.2-54.3,50-70,4.9-10,11.3-25.6,15-47"
            />
            <path
              id="patella"
              class="Oberschenkel_mit_Knie_vd_left2343_cls12"
              d="M451.8,733c14.8-11.4,20.1-25.7,22-31,6.7-18.7,3.5-34.9,2-41-1.4-5.6-7.1-27-28-41-3.7-2.5-20-13.4-40-11-6.6.8-6.9,2.2-32,12-23.3,9-22.9,7.8-28,11-4.3,2.7-23.5,14.9-27,37-2.4,15.1,3.9,27.2,9,37,4,7.6,10.8,20.7,25,28,4.8,2.5,5.8,1.9,28,6,28.5,5.3,29.5,6.7,37,6,2.7-.3,17.5-1.9,32-13Z"
            />
            <path
              id="femur_1"
              data-name="femur 1"
              class="Oberschenkel_mit_Knie_vd_left2343_cls11"
              d="M531.8,786c9.1.2-2.3-19.3,19-52"
            />
            <path
              id="femur_2"
              data-name="femur 2"
              class="Oberschenkel_mit_Knie_vd_left2343_cls11"
              d="M550.8,699c0,22,17,17.5,0,35"
            />
            <path
              id="femur_3"
              data-name="femur 3"
              class="Oberschenkel_mit_Knie_vd_left2343_cls11"
              d="M483.8,715c32.7-14,48-1.3,48-3"
            />
            <path
              id="femur_4"
              data-name="femur 4"
              class="Oberschenkel_mit_Knie_vd_left2343_cls11"
              d="M468.8,802.7c-12.8.4-24-20.9-23-56.5"
            />
            <path
              id="femur_5"
              data-name="femur 5"
              class="Oberschenkel_mit_Knie_vd_left2343_cls11"
              d="M289.8,707c26.9-1.3,53.3-1.3,56-1"
            />
            <polyline
              id="femur_6"
              data-name="femur 6"
              class="Oberschenkel_mit_Knie_vd_left2343_cls11"
              points="378 793.8 375.8 774.4 366.3 760 372.8 746.1 372.8 724 372.8 715 391.8 719.5 413.3 715 428.7 720 440.8 724"
            />
            <path
              id="femur_7"
              data-name="femur 7"
              class="Oberschenkel_mit_Knie_vd_left2343_cls11"
              d="M317.8,631.3c24.3,0,44-40.9,44-91.3"
            />
            <path
              id="upper_leg_1"
              data-name="upper leg 1"
              class="Oberschenkel_mit_Knie_vd_left2343_cls12"
              d="M466.3,3c-2.1,8.6-4.8,17.3-5.3,26.2-.4,8.5-2.8,16.5-4.6,24.7-1.9,8.6-2.1,17.7-3.7,26.3-1.7,9-3.6,17.9-5.1,26.8-6,36.2-11.4,72.5-17.1,108.8-5.6,35.7-11.3,71.4-17.9,107-3.3,18-6.8,35.8-11.8,53.5-4.9,17.6-9.5,35.3-14,53-9.1,35.6-18.1,71.4-28.9,106.5-1.4,4.4-2.7,8.8-4.2,13.1"
            />
            <path
              id="upper_leg_2"
              data-name="upper leg 2"
              class="Oberschenkel_mit_Knie_vd_left2343_cls12"
              d="M565.5,3.8c-16.5,117.1-34.5,232.1-46.5,351.1-4,32-3,64-5,96-2,23-8,45-12,68-1,7,1,14-.5,20.9"
            />
            <polyline
              id="upper_leg_3"
              data-name="upper leg 3"
              class="Oberschenkel_mit_Knie_vd_left2343_cls10"
              points="550.8 10.8 505.4 391.5 500.3 431.1 509.5 480.7"
            />
            <polyline
              id="upper_leg_4"
              data-name="upper leg 4"
              class="Oberschenkel_mit_Knie_vd_left2343_cls10"
              points="474.8 10.8 404.1 393.9 381.6 466.7 372.4 522"
            />
          </g>
        </g>
        <g id="overlays">
          <path
            id="patella_overlays"
            data-name="patella overlays"
            class="Patella2343Fill"
            style={{
              fill: props.colors.Patella2343Fill,
              stroke: props.colors.Patella2343Fill,
            }}
            d="M451.8,733c14.8-11.4,20.1-25.7,22-31,6.7-18.7,3.5-34.9,2-41-1.4-5.6-7.1-27-28-41-3.7-2.5-20-13.4-40-11-6.6.8-6.9,2.2-32,12-23.3,9-22.9,7.8-28,11-4.3,2.7-23.5,14.9-27,37-2.4,15.1,3.9,27.2,9,37,4,7.6,10.8,20.7,25,28,4.8,2.5,5.8,1.9,28,6,28.5,5.3,29.5,6.7,37,6,2.7-.3,17.5-1.9,32-13Z"
          />
          <g id="femurcondylen">
            <path
              id="femurcondylen_1"
              data-name="femurcondylen 1"
              class="Femurkondylen2343Fill"
              style={{
                fill: props.colors.Femurkondylen2343Fill,
                stroke: props.colors.Femurkondylen2343Fill,
              }}
              d="M464.9,802.1c-12.5-6.4-18.3-24.2-19-50.5-.1-3.6,1-7.2,3.1-10.2,8.4-11.9,18.4-21.2,31.4-25.5,2.3-.8,4.6-1.6,6.9-2.6,13.6-5.7,28.3-6.9,44.5-1.4,11.1,6.7,17.6,18.1,21.3,32.2,6.2,17.3,7.9,32.3,4.2,44.5-2.7,9.1-9.4,17.7-18.2,21.1-20.6,7.9-45.9,3.3-74.1-7.6h0Z"
            />
            <path
              id="femurcondylen_2"
              data-name="femurcondylen 2"
              class="Femurkondylen2343Fill"
              style={{
                fill: props.colors.Femurkondylen2343Fill,
                stroke: props.colors.Femurkondylen2343Fill,
              }}
              d="M289.8,756c7.9-14,19-22.2,33.4-24.8,12.3-2.2,25.1,1,34,9.7,5,4.9,8.3,11,9.1,19l9.5,14.4,2.2,19.4-16.2,13.8c-18.8,14.1-37.9,20.8-57.5,12.8-13.4-7.9-15-20.1-11.3-34.4,2.5-9.2,1.7-19.2-3.2-30h0Z"
            />
          </g>
          <path
            id="upper_leg_shaft_overlay"
            data-name="upper leg shaft overlay"
            class="Oberschenkelschaft2343Fill"
            style={{
              fill: props.colors.Oberschenkelschaft2343Fill,
              stroke: props.colors.Oberschenkelschaft2343Fill,
            }}
            d="M466.3,3l99.2.8c-29,185.8-46.6,328.8-49.4,416.6-2.2,30.9-8.2,75.1-14.3,119.6l-143.9-4.2C405.6,387.3,433,199.6,466.3,3Z"
          />
        </g>
      </g>
      <g id="fullkneehighlight">
        <path
          id="fullkneehighlight1"
          class="Kniegelenk2343Fill"
          style={{
            fill: props.colors.Kniegelenk2343Fill,
            stroke: props.colors.Kniegelenk2343Fill,
          }}
          d="M627.1,770.1c0,84.3-105.4,132.6-194.3,132.6s-212.7-54.3-212.7-138.6,114.7-178.4,203.5-178.4,203.5,100.1,203.5,184.4Z"
        />
      </g>
      <g id="weichteile">
        <path
          id="weichteile2"
          class="Oberschenkel_mit_Knie_vd_left2343_cls9"
          d="M142.8,0c16,144.8,5.8,369.6,53.3,596.5-9.5,67.6-2.3,168.4,42.3,249.6-22.2,120.5.9,165.6,9.4,240.3"
        />
        <path
          id="weichteile1"
          class="Oberschenkel_mit_Knie_vd_left2343_cls9"
          d="M764.6,0c-51.9,285.9-146.6,452.7-160,544.6-9.7,51.8-7.4,139.7,21,202.9,23.2,45.1,22.5,68.6,24.2,77,15.6,78.4-19.6,204.4-24.5,271.5"
        />
      </g>
      <g id="Weichteilhighlight">
        <path
          id="Weichteilhighlight2"
          class="Weichteilmantel2343Fill"
          style={{
            fill: props.colors.Weichteilmantel2343Fill,
            stroke: props.colors.Weichteilmantel2343Fill,
          }}
          d="M247.8,1088.2c-8.5-74.7-31.6-121.7-9.4-242.2-44.6-81.2-51.8-181.9-42.3-249.6C148.6,369.6,158.8,144.8,142.8,0h-7.2c16,144.8,5.8,369.6,53.3,596.5-9.5,67.6-2.3,168.4,42.3,249.6-22.2,120.5.9,167.3,9.3,242l7.3.2Z"
        />
        <path
          id="Weichteilhighlight1"
          class="Weichteilmantel2343Fill"
          style={{
            fill: props.colors.Weichteilmantel2343Fill,
            stroke: props.colors.Weichteilmantel2343Fill,
          }}
          d="M634.6,1086.3c4.8-67.1,35.4-185.6,22.1-265.9-1.4-8.5-.3-24.6-23.5-69.8-28.4-63.1-29.5-155.7-19.8-207.5C626.9,451.2,719.6,285.9,771.5,0h-6.9c-51.9,285.9-146.6,452.7-160,544.6-9.7,51.8-7.4,139.7,21,202.9,23.2,45.1,22.5,68.6,24.2,77,15.6,78.4-19,196.6-23.8,263.7l8.7-1.9Z"
        />
      </g>
    </svg>
  );
}
