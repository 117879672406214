import style from "./style.scss";

export default function LinkerEllenbogenLatSitzend2213(props) {
  return (
    <svg
      id="Anconlat"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 610.35 504.95"
    >
      <g id="Backround">
        <path
          id="backround1"
          class="elbow_lat_left2213_cls3"
          d="M8.16,361.98c4.14-3.54,9.15-3.74,19-4,19.28-.51,28.91-.76,52-1,19.33-.2,15.39-2.49,49-5,11.85-.89,23.9-1.26,48-2,25.34-.78,38.01-1.17,52-1,12.22.15,15.51.48,24,0,17.86-1.01,21.09-3.46,35-4,10.48-.41,18.32.73,34,3,12.05,1.75,17.29,3.18,37,6,3.21.46,11.84,1.65,23,1,12.73-.74,15.94-3.14,17-4,5.53-4.53,4.21-9.92,10-13,2.7-1.44,5.8-1.29,12-1,8.22.39,13.01.61,17,4,2.57,2.18,3.71,4.79,6,10,3.33,7.59,3.22,11.78,5,12,2.13.27,2.95-5.65,8-11,3.61-3.83,7.46-5.27,15-8,14.45-5.23,17.19-2.63,24-7,7.33-4.7,10.56-11.82,12-15,4.32-9.53,3.26-17.4,3-27-.07-2.71,1.28-15.81,4-42,2.59-24.97,3.98-26.38,6-45,4.02-37.06,2.74-70.59,2-90-.26-6.89-.83-18.88-.57-35.78.27-17.51,1.06-16.17.93-26.41-.02-1.6-.07-2.91-.09-3.55-.04-1.36-.42-13.19-1.71-24.26-2.37-20.34-5.9-21.59-3.56-25,3.02-4.41,8.91-2.29,40.56-2,15.85.15,23.78.22,28,0,13.31-.69,15.47-1.8,18.44,0,9.65,5.84,4.89,28.49,2,51-3.01,23.45,3.31-10.1-3,76-3.31,45.17-4.41,45.63-9,100-4.24,50.22-7.74,102.29-8,121-.12,8.67-1.53,17.34-1,26,.31,5.01.85,8.56-1,13-2.67,6.42-7.62,8.26-7,12,.56,3.35,4.79,3.43,8,7,1.89,2.09,4.67,6.78,1,25-4.08,20.26-8.62,19.94-9,32-.37,11.79,3.83,16.51,0,23-3.6,6.1-10.93,8.03-15,9-29.23,6.94-62.99,5.15-66,5-20.62-1.06-13.56,1.53-60,3-37.16,1.18-56.09-.02-100-1-77.79-1.74-67.21.93-126-1-40.28-1.32-101.59-4.42-113-5-41.91-2.12-41.3-2.5-48-2-22.99,1.72-37.71,6.76-44-1-3.16-3.9-.93-6.99-1-21-.12-25.86-7.8-35.83-2-42,4.63-4.93,13.39-2.68,24-1,20.41,3.24,41.36-.08,62,1,48.26,2.52,90.77,2.22,123,2,19.1-.13,42.78-.53,69-3,8.11-.76,21.53-2.2,38-5,8.78-1.49,14.19-2.66,25-5,16.69-3.61,25.03-5.44,25-7-.07-3.53-30.26-5.61-36-6-34.28-2.36-59.08-.68-74,0-41.01,1.87-32.39-2.64-88-2-29.31.34-55.91,1.87-101,3-51.48,1.29-24.49-.52-35,0-2.17.11-7.99.07-23-1"
        />
      </g>
      <g id="Overview">
        <path
          id="outline3"
          class="elbow_lat_left2213_cls6"
          d="M15.06,358.97c3.2-.74,7.25-.87,12.86-1.02,19.28-.51,28.91-.76,52-1,19.33-.2,15.39-2.49,49-5,11.85-.89,23.9-1.26,48-2,25.34-.78,38.01-1.17,52-1,12.22.15,15.51.48,24,0,17.86-1.01,21.09-3.46,35-4,10.48-.41,18.32.73,34,3,12.05,1.75,17.29,3.18,37,6,3.21.46,11.84,1.65,23,1,12.73-.74,15.94-3.14,17-4,5.53-4.53,4.21-9.92,10-13,2.7-1.44,5.8-1.29,12-1,8.22.39,13.01.61,17,4,1.9,1.62,3.27,4.41,6,10,2.39,4.89,3.89,8.91,5,12,3.38,9.44,5.07,14.17,5.76,18.02.74,4.12,3.53,19.78-5,31-3.03,3.98-3.13,1.67-15,12-6.34,5.52-8.33,7.93-12,8-3.56.07-5.49-2.12-12-7-1.34-1-9.81-7.35-15-10-8.72-4.45-15.6-2.26-26.76-2.02-15.17.34-22.68-3.27-36-6-24.24-4.97-40.77-1.51-74,0-41.01,1.87-32.39-2.64-88-2-29.31.34-55.91,1.87-101,3-51.48,1.29-24.49-.52-35,0-11.83.58-16.56,1.42-20.3.27"
        />
        <path
          id="outline2"
          class="elbow_lat_left2213_cls6"
          d="M15.06,428.15c3.55.35,7.55,1.13,11.86,1.81,20.41,3.24,41.36-.08,62,1,48.26,2.52,90.77,2.22,123,2,19.1-.13,42.78-.53,69-3,8.11-.76,21.53-2.2,38-5,8.78-1.49,14.19-2.66,25-5,9.16-1.98,12.46-3.43,25-7,24.66-7.01,24.4-5.48,31.76-8.98,10.12-4.82,17.21-10.86,22-15,9.93-8.58,9.67-11.22,19-19,8.77-7.31,22.02-15.83,25-13,1.82,1.73-2.11,5.88-3,14-1.14,10.3,3.55,18.49,9,28,7.03,12.27,11.1,19.37,20,24,13.24,6.88,27.2,3.11,35,1,7.66-2.07,16.33-4.41,23-12,6.1-6.94,5.87-12.96,12-15,6.21-2.07,13.22,1.85,15.24,2.98,1.48.83,5.34,3.04,8,7,2.54,3.78,4.14,9.38,1,25-4.08,20.26-8.62,19.94-9,32-.37,11.79,3.83,16.51,0,23-3.6,6.1-10.93,8.03-15,9-29.23,6.94-62.99,5.15-66,5-20.62-1.06-13.56,1.53-60,3-37.16,1.18-56.09-.02-100-1-77.79-1.74-67.21.93-126-1-40.28-1.32-101.59-4.42-113-5-41.91-2.12-41.3-2.5-48-2-15.76,1.18-27.63,3.92-35.57,3.02"
        />
        <path
          id="outline1"
          class="elbow_lat_left2213_cls6"
          d="M609.72.98c.12,3.98.22,9.87,0,17-.39,12.86-1.33,14.62-2.8,33.98-1.22,16.02-1.4,25.51-1.25,33.83.13,7.75.83,7.06-1.75,42.17-3.31,45.17-4.41,45.63-9,100-4.24,50.22-7.74,102.29-8,121-.12,8.67-1.53,17.34-1,26,.31,5.01.85,8.56-1,13-1.85,4.45-3.76,6.28-7,12-3.24,5.71-3.65,8.55-5.24,12.02-4.5,9.79-13.64,14.96-19,18-20.87,11.82-44.67,4.97-55,2-6.21-1.79-18.67-5.52-31-16-5.79-4.92-14.83-12.61-19-26-1.35-4.33-4.84-15.54.24-27.02,2.47-5.57,6.1-9.28,8-11,1.79-1.63,5.38-4.52,15-8,14.45-5.23,17.19-2.63,24-7,7.33-4.7,10.56-11.82,12-15,4.32-9.53,3.26-17.4,3-27-.07-2.71,1.28-15.81,4-42,2.59-24.97,3.98-26.38,6-45,4.02-37.06,2.74-70.59,2-90-.26-6.89-.83-18.88-.57-35.78.27-17.51,1.06-16.17.93-26.41,0,0-.02-1.76-.09-3.55-.13-3.49-3.57-23.72-9.47-51.25"
        />
        <path
          id="partofancon4"
          class="elbow_lat_left2213_cls6"
          d="M574.68,247.98c-1.6,11.28-3.99,20.44-6,27-5.27,17.15-7.9,25.72-15,33-8.62,8.84-19,17-19,17-3.8,2.99-7.49,5.66-8,10-.48,4.07,2.11,7.42,5,11,12.4,15.37,14.99,16,18,21,5.28,8.76,6.41,21.88,1,32-6.41,11.98-19.57,15.19-27,17-6.71,1.63-23.76,5.79-38-5-14.05-10.64-14.85-27.87-15-31-.18-3.87-.48-21.5,11-31,6.57-5.43,10.98-3.25,21-11,7.39-5.72,11.21-11.72,12-13,4.72-7.59,5.93-14.52,7-21,.96-5.82,1.78-14.88,0-26"
        />
        <path
          id="partofancon3"
          class="elbow_lat_left2213_cls6"
          d="M588.66,13.98c0,117.19-4.02,212.03-8.98,212.03"
        />
        <path
          id="partofancon2"
          class="elbow_lat_left2213_cls6"
          d="M539.94,2.96c12,157-6.28,272.02-12.49,272.02"
        />
        <path
          id="partofancon1"
          class="elbow_lat_left2213_cls6"
          d="M584.17,365.98c-9.93,12.01-16.8,20.93-25.77,26.92"
        />
        <path
          id="radius5"
          class="elbow_lat_left2213_cls6"
          d="M446.25,413.98c-16.97-36.51-8.95-68-2.33-63.02"
        />
        <path
          id="radius4"
          class="elbow_lat_left2213_cls6"
          d="M437.92,340.96c-22.24,36.51,3.73,73.02,8.33,73.02"
        />
        <path
          id="ulnar3"
          class="elbow_lat_left2213_cls6"
          d="M396.68,430.44c0,5.83-36,12.54-78,3.54"
        />
        <line
          id="ulnar2"
          class="elbow_lat_left2213_cls6"
          x1="408.68"
          y1="481.98"
          x2="14.68"
          y2="483.98"
        />
        <line
          id="ulnar1"
          class="elbow_lat_left2213_cls6"
          x1="1.52"
          y1="443.57"
          x2="381.68"
          y2="440.98"
        />
        <path
          id="radius3"
          class="elbow_lat_left2213_cls6"
          d="M396.68,357.18c-2.55,2.77-13.1,3.51-19,5.8-9.93,3.86-18.84,1.37-35-3-23.65-6.39-35.55-9.6-39-10-9.45-1.11-23.67-.99-42,6"
        />
        <path
          id="radius2"
          class="elbow_lat_left2213_cls6"
          d="M252.68,354.98c-7.6,2.12-20.16,5.2-36,7-22.53,2.56-32.91.38-61,2-20.39,1.18-15.75,2.37-39,4-26.36,1.85-36.42.59-69,2-14.21.61-25.74,1.43-33,2"
        />
        <path
          id="radius1"
          class="elbow_lat_left2213_cls6"
          d="M379.25,410.13c-10.74-3.08-27.16-7.24-47.57-10.15-18.03-2.57-30.43-2.92-58-4-62.71-2.45-53.39-2.76-70-3-58.68-.84-51.43,4.88-96,3-21.92-.93-46-3.26-84-1-5.9.35-10.75.73-14,1"
        />
      </g>
      <g id="Highlights">
        <path
          id="forearmparietalhighlight"
          class="unterarm2213fill"
          style={{
            fill: props.colors.Unterarm2213Fill,
            stroke: props.colors.Unterarm2213Fill,
          }}
          d="M14.35,497.98c1.42.07,3.58.13,6.21.07,6.42-.16,9.49-.95,17.78-2.04,6.58-.87,9.95-1.31,14.56-1.42,6.53-.15,11.37.48,12.72.64,7.85.93,43.32,2.47,114.26,5.55,85.88,3.73,119.05.67,180.23,2.91,0,0,24.65.9,56.76.76,11.36-.05,18.8-.31,25.62-.56,6.71-.24,10.49-.38,15.91-.81,5.72-.45,7.63-.77,15.39-1.4,4.46-.36,8.7-.71,12.79-.89,9.13-.39,11.32.32,21.17.49,11.99.2,21.3-.65,28.45-1.3,3.24-.3,7.55-.87,16.18-2.02,10.15-1.35,13.53-1.97,17.49-4.25,2.87-1.65,6.2-3.58,8.26-7.34,4.08-7.45-2.08-12.55,0-25.08.37-2.24.33-.64,4.18-13.08,2.35-7.59,3.54-11.47,4.51-15.84,2.77-12.46,1.79-17.23,1.37-18.94-.45-1.87-1.14-4.72-3.19-7.69-4.9-7.12-15.25-11.54-22.65-8.83-6.1,2.23-5.73,7.67-12.13,14.76-9.37,10.38-22.68,12.58-26.09,13.15-18.53,3.07-37.15-6.4-47.93-19.74-7.13-8.82-16.97-26.94-11.6-39.45,1.04-2.42,3.65-6.97,2.07-8.65-1.77-1.89-7.87.85-8.88,1.3-5.41,2.43-6.29,5.21-8.36,4.74-2.63-.6-2.2-5.32-6.14-13.51-2.26-4.7-3.64-7.58-6.74-9.71-3.14-2.16-6.87-2.31-14.35-2.62-6.71-.28-10.06-.41-12.41.7-6.05,2.85-4.87,8.24-11.07,12.91-4.05,3.05-8.18,3.47-15.78,4.25-9.06.93-16.08,0-21.05-.69-14.62-2.03-25.27-3.97-30.11-4.81-20.32-3.53-30.48-5.29-43.49-4.82-13.02.47-13.84,2.55-30.13,4.04-15.06,1.38-21.78.28-39.44-.2-3.86-.11-4.2-.07-71.4,2.43-16.76.62-25.97.97-39.02,2.83-8.02,1.14-9.63,1.74-16.79,2.43-6.11.59-9.79.61-32.76.98-14.57.23-13.97.23-14.16.23-9.3-.03-14.41-.41-22.44.47-3.07.34-5.55.75-7.11,1.03-.24,46.34-.47,92.67-.71,139.01Z"
        />
        <path
          id="radialheadhighlight"
          class="radiushead2213fill"
          style={{
            fill: props.colors.RadiusHead2213Fill,
            stroke: props.colors.RadiusHead2213Fill,
          }}
          d="M399.96,384.98c.48-2.59,1.17-4.49,1.25-8.12.04-1.79-.08-3.6-.64-6.94-1.8-10.83-4.5-15.68-2.12-18.17.64-.67,1.3-.81,2.53-2.19.53-.6,1.08-1.32,2.43-3.88,1.99-3.76,2.15-4.73,3.54-6,.89-.82,1.73-1.24,2.32-1.53,2.98-1.49,7.51-1.29,17.5-.69,4.93.3,7.98.89,10.62,3.03,1.45,1.18,2.35,2.6,4.15,5.46,1.93,3.06,3,5.46,4.85,9.61.6,1.35,1.89,4.3,3.35,8.18.53,1.41,1.08,2.95,1.64,4.63,1.82,5.44,2.86,8.54,3.65,12.8.62,3.38,2.38,13.42-.95,23.6-1.83,5.6-4.08,7.88-5.31,8.93-1.15.98-1.69,1.12-4.15,2.83-2.71,1.88-4.53,3.45-7.08,5.61-5.39,4.57-8.08,6.86-8.24,7.03-.74.79-2,2.19-4.1,2.78-1.55.44-2.89.23-3.39.15-.44-.07-1.92-.35-5.26-2.63-.72-.49-1.13-.81-2.83-2.12-5.12-3.97-5.42-4.23-6.57-5.06-3.34-2.41-3.2-1.93-4.3-2.98-.28-.27-2.98-2.91-3.99-6.52-1.06-3.79.71-5.16,1.52-10.92,1.14-8.12-1.9-8.9-.4-16.89Z"
        />
        <path
          id="condyleshighlight"
          class="oberarmcondylen2213fill"
          style={{
            fill: props.colors.Oberarmcondylen2213Fill,
            stroke: props.colors.Oberarmcondylen2213Fill,
          }}
          d="M504.6,433.93c-5.76-1.22-18.14-3.95-31.25-12.94-5.7-3.91-13.21-9.18-19.31-19.01-2.21-3.56-8.26-13.57-7.99-25.99.04-2.02.2-7.14,2.53-13.15.72-1.86,1.77-4.52,3.94-7.38,1.38-1.82,4.1-4.9,9.71-7.89,1.47-.78,3.85-1.94,20.93-5.97,7.25-1.71,11.04-2.49,14.76-5.87,1.79-1.62,2.08-2.5,3.64-3.24,4.61-2.17,9.8,1.92,16.48,5.36,13.24,6.81,15.55,2.43,31.43,10.14,4.68,2.27,6.46,3.62,12.01,5.83,5.72,2.28,7.8,2.43,13.49,5.06,9.43,4.37,10.55,7.11,10.82,8.6.22,1.23-.13,1.7.1,4.85.22,3.1.74,5.16.91,6.07.8,4.24-1.15,8.37-4.65,15.57-2.43,5-2.83,4.75-7.18,12.74-4.27,7.86-3.79,7.93-5.76,10.72-4.47,6.34-9.71,9.9-11.63,11.12-5.86,3.73-11.13,4.95-15.67,5.97-6.63,1.47-11.76,1.46-17.19,1.42-3.95-.03-11.16-.13-20.12-2.02Z"
        />
        <path
          id="humeroulnarjointhighlight"
          class="humeroulnarengelenke2213fill"
          style={{
            fill: props.colors.humeroulnarenGelenke2213Fill,
            stroke: props.colors.humeroulnarenGelenke2213Fill,
          }}
          d="M586.13,381.84c1.33,1.19-1.08,6.03-2.33,8.53-.6,1.19-1.08,2.07-1.69,3.07-1.4,2.32-2.53,3.67-4.2,6.52-3.74,6.39-2.37,7.58-5.58,13.33-3.29,5.91-7.44,9.51-9.57,11.33-1.27,1.08-6.41,5.35-14.97,8.49-12.57,4.62-23.73,3.62-31.55,2.83-4.36-.44-18.56-2.18-34.52-10.65-4.77-2.53-10.69-5.73-17.12-11.73-4.65-4.34-14.39-13.44-17.53-28.04-.52-2.45-2.21-10.73.4-19.15,1.05-3.38,2.53-5.91,3.64-7.82.89-1.53,2.41-3.01,5.26-6.07.79-.85,2.12-2.24,4.18-3.57,1.43-.92,2.21-1.41,3.24-1.35,2.46.14,3.99,3.32,4.25,3.84,1.76,3.65.43,7.44.07,8.49-1,2.86-2.22,3.32-3.51,5.73-2,3.74-1.66,7.49-1.35,10.85.77,8.44,8.44,29.36,28.58,36.94,7.46,2.81,13.99,2.76,17.39,2.7,8.2-.15,13.87-2.36,25.21-6.78,12.81-4.99,28.05-12.66,33.07-17.39,1.13-1.06,3.24-3.39,6.65-4.85.86-.37,2.08-.8,3.97-1.77,1.88-.97,2.83-1.45,3.5-1.97,1.07-.83,1.62-1.53,2.76-1.72.49-.08,1.26-.21,1.72.2Z"
        />
      </g>
    </svg>
  );
}
