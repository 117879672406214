import style from "./style.scss";

export default function LinkerUnterschenkelMitOberemSprunggelenkVdStehend2303(
  props
) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 173.11 680.24">
      <g id="background">
        <path
          id="tibia_background"
          data-name="tibia background"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls4"
          d="M59.12,1.44c-6.1,88-4.9,158.6-2.5,207.9.7,14.3,6.8,75.1.6,137-2.4,24.1-6.8,50.7-8.4,60.4-5.4,32.6-8.2,49.1-13.1,59.8-5.1,11.1-9.2,16.9-27.4,51.3-3.8,7.2-9.3,17.9-6.6,30.1,1.7,8,7.1,17.1,12.2,16.8,3.7-.2,3.5-5.2,7.8-13.1,5-9.2,7.4-11.9,10.8-15.1,3.8-3.4,8.1-3.3,21-3.2,27.8.2,34.7-.2,36.1,3.6,1.4,3.8,1.1,4.3,2.6,6.2,1.7,2.1,5.3,3.3,10.4-2.4,8.4-9.2,3.3-26,1.6-32-5.6-20.4-8-63.6-12.8-149.9-.7-13-.7-28-.7-58,0-15.1.2-61.7,3.5-128.1,3-59.7,5.7-79.7,7.3-141.1.3-13.1.5-23.8.6-30.9"
        />
        <path
          id="fibula_background"
          data-name="fibula background"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls4"
          d="M172.12.1c-2.4,24-6.2,62.5-10.7,110-12.5,131.9-10.5,120.6-15.9,171.3-5.2,48.7-9,97.5-14.2,146.2-3.1,29.3-6.4,58-1,86,3,15.5,2.8,31.8,7.6,46.9.6,2,3.6,11.3,2.3,23-.2,1.5-1.9,8.4-6.8,9.1-2.4.3-3.6-1.6-9.1-6.9-7.7-7.3-10-7.1-14.1-11.8-3.1-3.5-2.6,1.9-7.3-14.9-4.1-14.8-5.3-16.4-5.3-21.9,0-7.1,2-11.5,3.8-17,4.1-12.4,2.7-16.3,5.6-36.1,3-20.8,5.3-23.3,8.2-40.5,3.8-22,3.6-38.1,3.6-52.6,0,0-.2-27.8,4.7-105.1,4.3-67.6,9.9-57.6,15.4-132.2,2.2-30,2.3-45.4,6.7-107.1,1.4-19.3,2.6-35.3,3.4-45.9"
        />
        <path
          id="talus_background"
          data-name="talus background"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls4"
          d="M121.72,634.7c.7-11.5,1.8-20.7,2.7-27,1.2-8.1,2-11.8-.2-16-1.8-3.4-4-4-14.1-10.8-7.5-5.1-11.3-7.6-12.3-9-6.8-9.1.4-17.7-5.6-24.5-2.8-3.2-9.7-4.1-23.6-6-7.5-1-7.6-.4-15.3-1.6-7.2-1.1-9.3-1-12.9-.2-1.2.2-6.2,4.5-11.8,11.3-6,7.3-5.2,8-9.7,21.1-5.1,14.8-9.3,17.8-12.6,32.2-.9,4.1-1.8,9-.8,15,.8,5.3,2.7,9.4,4.1,12,1.24,3.16,3.31,6.22,6.29,9.15,7.85-2.41,14.42-7.36,19.61-15.05,14.12-9.64,30.45-7.32,48.19,2.59,6.93,5.61,15.79,6.44,18.75-2.01.39-1.1,1.7-1.56,2.71-.96l16.55,9.79Z"
        />
        <path
          id="foot_background"
          data-name="foot background"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls4"
          d="M5.62,678.77l1.7-6.67c-3.77-4.11-6.87-8.79-6.31-16.56.37-5,3.46-9.4,7.71-13.54,9.53-1.14,17.71-3.71,23.7-8.61,4.53-8.22,11.68-12.14,20.28-13.7,12.82-.38,24.78,4.3,36.08,12.91,8.18,1.72,12.91-.88,14.34-7.61,8.89,3.95,18.9,10.81,29.2,18.4,9.19,12.62,18.75,24.57,28.85,35.6l-155.54-.23Z"
        />
      </g>
      <g
        id="upper_ankle_joint_with_lower_leg"
        data-name="upper ankle joint with lower leg"
      >
        <path
          id="tibia_outlines"
          data-name="tibia outlines"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls8"
          d="M59.12,1.5c-6.1,88-4.9,158.6-2.5,207.9.7,14.3,6.8,75.1.6,137-2.4,24.1-6.8,50.7-8.4,60.4-5.4,32.6-8.2,49.1-13.1,59.8-5.1,11.1-9.2,16.9-27.4,51.3-3.8,7.2-9.3,17.9-6.6,30.1,1.7,8,7.1,17.1,12.2,16.8,3.7-.2,3.5-5.2,7.8-13.1,5-9.2,7.4-11.9,10.8-15.1,3.8-3.4,8.1-3.3,21-3.2,27.8.2,34.7-.2,36.1,3.6,1.4,3.8,1.1,4.3,2.6,6.2,1.7,2.1,5.3,3.3,10.4-2.4,8.4-9.2,3.3-26,1.6-32-5.6-20.4-8-63.6-12.8-149.9-.7-13-.7-28-.7-58,0-15.1.2-61.7,3.5-128.1,3-59.7,5.7-79.7,7.3-141.1.3-13.1.5-23.8.6-30.9"
        />
        <path
          id="fibula_outlines"
          data-name="fibula outlines"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls8"
          d="M172.12.1c-2.4,24-6.2,62.5-10.7,110-12.5,131.9-10.5,120.6-15.9,171.3-5.2,48.7-9,97.5-14.2,146.2-3.1,29.3-6.4,58-1,86,3,15.5,2.8,31.8,7.6,46.9.6,2,3.6,11.3,2.3,23-.2,1.5-1.9,8.4-6.8,9.1-2.4.3-3.6-1.6-9.1-6.9-7.7-7.3-10-7.1-14.1-11.8-3.1-3.5-2.6,1.9-7.3-14.9-4.1-14.8-5.3-16.4-5.3-21.9,0-7.1,2-11.5,3.8-17,4.1-12.4,2.7-16.3,5.6-36.1,3-20.8,5.3-23.3,8.2-40.5,3.8-22,3.6-38.1,3.6-52.6,0,0-.2-27.8,4.7-105.1,4.3-67.6,9.9-57.6,15.4-132.2,2.2-30,2.3-45.4,6.7-107.1,1.4-19.3,2.6-35.3,3.4-45.9"
        />
        <path
          id="talus_outlines"
          data-name="talus outlines"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls8"
          d="M121.72,634.7c.7-11.5,1.8-20.7,2.7-27,1.2-8.1,2-11.8-.2-16-1.8-3.4-4-4-14.1-10.8-7.5-5.1-11.3-7.6-12.3-9-6.8-9.1.4-17.7-5.6-24.5-2.8-3.2-9.7-4.1-23.6-6-7.5-1-7.6-.4-15.3-1.6-7.2-1.1-9.3-1-12.9-.2-1.2.2-6.2,4.5-11.8,11.3-6,7.3-5.2,8-9.7,21.1-5.1,14.8-9.3,17.8-12.6,32.2-.9,4.1-1.8,9-.8,15,.8,5.3,2.7,9.4,4.1,12"
        />
        <path
          id="tibia_1"
          data-name="tibia 1"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls9"
          d="M29.42,519.8c.1,5-9,.1-17.9,9.2"
        />
        <path
          id="talus_1"
          data-name="talus 1"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls9"
          d="M34.82,554.7c-4.8,18.6-5.8,37.8-18.5,38"
        />
        <path
          id="talus_2"
          data-name="talus 2"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls9"
          d="M86.52,572.6c-21.4,14-17.9,31.5-25.3,38.8"
        />
        <path
          id="tibia_2"
          data-name="tibia 2"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls9"
          d="M85.82,519.1c-25.9-13.7-27.3,14.3-46.6-6.4"
        />
        <path
          id="fibula_1"
          data-name="fibula 1"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls9"
          d="M108.72,546.8c4.9,3.8,13.8,8.2,16.4,11.8"
        />
        <path
          id="fibula_2"
          data-name="fibula 2"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls9"
          d="M108.82,560.6c-7-5.3,16.3-2,18.6,11.5"
        />
        <polyline
          id="tibia_3"
          data-name="tibia 3"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls9"
          points="94.12 1.1 92.02 78 81.82 183.1 83.12 288.1 84.32 394.1 97.12 454"
        />
        <polyline
          id="tibia_4"
          data-name="tibia 4"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls9"
          points="66.82 1.4 63.52 115.3 58.62 189.3 64.82 271.3 60.82 365.4 58.62 395.4 53.42 427.5 33.82 475.7"
        />
        <path
          id="foot"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls6"
          d="M12.42,637.4c4.6,4.3,11.9,1.3,19.9-3.6,13.3-21.3,32.3-16.7,53.5-2.3"
        />
        <path
          id="foot-2"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls6"
          d="M85.72,631.5c6.4,3,7.1,5.9,2.6,8.3-6.2,1.7-9.6-.1-12.4,3-13.2-6.9-23.7-4.2-31.4,8.3-4-1.4-7.9-1.2-11.6,1.4-10.1,6-18.9,12.6-24.7,20.6-9.7-9.3-9.7-19.6.5-31.1h0c9.5.2,15.8-2.6,23.6-8.2"
        />
        <path
          id="toe_3"
          data-name="toe 3"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls6"
          d="M103.12,657.9c-10.3-3.5-18.7-8.9-25.5-15.9,6.5.6,11.2-.4,13.2-3.9.8-4.3,3.3-5,7.4-2.2,7.7,5.2,15.8,10.1,24.6,14.2,9.8,7.8,11.4,12.9,3.1,14.9-5.9-4.2-13-6.6-22.8-7.1Z"
        />
        <path
          id="toe_2"
          data-name="toe 2"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls6"
          d="M79.02,660.2c-7-5.9-14.9-4.1-23.2,1.9-1.3-6.5-4.4-11-11.3-11.1,6.6-12,16.6-16,31.4-8.3l17.2,12.7c-5.8.4-10.4,2.1-14.1,4.8h0Z"
        />
        <path
          id="toe_4"
          data-name="toe 4"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls7"
          d="M132.63,680.03c-1.14-1.7-2.18-3.66-3.11-5.93-1.4-7.6,3-8.6,9.9-3.9,4.25,2.74,8.03,5.59,11.31,8.59"
        />
        <path
          id="toe_3-2"
          data-name="toe 3-2"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls7"
          d="M82.27,678.5c-1.04-6.27-1.46-11.81-1.55-16.7,9.7-10.8,26.6-4.5,27.6,13,.77,1.88,1.68,3.61,2.7,5.23"
        />
        <path
          id="lower_leg_1"
          data-name="lower leg 1"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls6"
          d="M120.22,633.5c-6-2.5-11.9-5-17.1-9.8"
        />
        <path
          id="ankle_overlay_1"
          data-name="ankle overlay 1"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls6"
          d="M103.12,623.9c-3,8.4-7.7,11.2-14,8.5"
        />
        <path
          id="ankle_overlay_1-2"
          data-name="ankle overlay 1-2"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls6"
          d="M89.12,632.3c-22.9-15.7-44.3-17.8-63,3.2-9.7,8.2-12.9,8-20.5-16.5"
        />
        <path
          id="foot_overlay_1"
          data-name="foot overlay 1"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls6"
          d="M32.42,629.4c18.1-20.6,42.4-6.9,65.8,6.5l-7.4,2.2,13.9-13.4"
        />
        <path
          id="foot_overlay_1-2"
          data-name="foot overlay 1-2"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls7"
          d="M5.62,678.77c.46-2.13,1.04-4.37,1.7-6.67-9.3-13.7-6.9-22.8,1.3-29.9,11.5.2,21.9-9.2,26.9-16.9"
        />
        <path
          id="foot_overlay_1-2-2"
          data-name="foot overlay 1-2"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls7"
          d="M104.72,624.7l27.6,18.7c10.06,13.75,19.63,25.66,28.56,35.24"
        />
        <line
          id="toe_1"
          data-name="toe 1"
          class="Linker_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2303_cls7"
          x1="55.82"
          y1="662.1"
          x2="50.32"
          y2="677.11"
        />
      </g>
      <g id="overlays">
        <path
          id="tibia_overlays"
          data-name="tibia overlays"
          class="Tibia2303Fill"
          style={{
            fill: props.colors.Tibia2303Fill,
            stroke: props.colors.Tibia2303Fill,
          }}
          d="M59.12,1.44c-6.1,88-4.9,158.6-2.5,207.9.7,14.3,6.8,75.1.6,137-2.4,24.1-6.8,50.7-8.4,60.4-5.4,32.6-8.2,49.1-13.1,59.8-5.1,11.1-9.2,16.9-27.4,51.3-3.8,7.2-9.3,17.9-6.6,30.1,1.7,8,7.09,17.09,12.2,16.8,3.7-.2,3.5-5.2,7.8-13.1,5-9.2,7.4-11.9,10.8-15.1,3.8-3.4,8.1-3.3,21-3.2,27.8.2,34.7-.2,36.1,3.6,1.4,3.8,1.1,4.3,2.6,6.2,1.7,2.1,5.3,3.3,10.4-2.4,8.4-9.2,3.3-26,1.6-32-5.6-20.4-8-63.6-12.8-149.9-.7-13-.7-28-.7-58,0-15.1.2-61.7,3.5-128.1,3-59.7,5.7-79.7,7.3-141.1.3-13.1.5-23.8.6-30.9"
        />
        <path
          id="fibula_overlay"
          data-name="fibula overlay"
          class="Fibula2303Fill"
          style={{
            fill: props.colors.Fibula2303Fill,
            stroke: props.colors.Fibula2303Fill,
          }}
          d="M172.12.1c-2.4,24-6.2,62.5-10.7,110-12.5,131.9-10.5,120.6-15.9,171.3-5.2,48.7-9,97.5-14.2,146.2-3.1,29.3-6.4,58-1,86,3,15.5,2.8,31.8,7.6,46.9.6,2,3.6,11.3,2.3,23-.2,1.5-1.9,8.4-6.8,9.1-2.4.3-3.6-1.6-9.1-6.9-7.7-7.3-10-7.1-14.1-11.8-3.1-3.5-2.6,1.9-7.3-14.9-4.1-14.8-5.3-16.4-5.3-21.9,0-7.1,2-11.5,3.8-17,4.1-12.4,2.7-16.3,5.6-36.1,3-20.8,5.3-23.3,8.2-40.5,3.8-22,3.6-38.1,3.6-52.6,0,0-.2-27.8,4.7-105.1,4.3-67.6,9.9-57.6,15.4-132.2,2.2-30,2.3-45.4,6.7-107.1,1.4-19.3,2.6-35.3,3.4-45.9"
        />
        <path
          id="talus_overlay"
          data-name="talus overlay"
          class="Talus2303Fill"
          style={{
            fill: props.colors.Talus2303Fill,
            stroke: props.colors.Talus2303Fill,
          }}
          d="M121.72,634.7c.7-11.5,1.8-20.7,2.7-27,1.2-8.1,2-11.8-.2-16-1.8-3.4-4-4-14.1-10.8-7.5-5.1-11.3-7.6-12.3-9-6.8-9.1.4-17.7-5.6-24.5-2.8-3.2-9.7-4.1-23.6-6-7.5-1-7.6-.4-15.3-1.6-7.2-1.1-9.3-1-12.9-.2-1.2.2-6.2,4.5-11.8,11.3-6,7.3-5.2,8-9.7,21.1-5.1,14.8-9.3,17.8-12.6,32.2-.9,4.1-1.8,9-.8,15,.8,5.3,2.7,9.4,4.1,12,1.24,3.16,3.31,6.22,6.29,9.15,7.85-2.41,14.42-7.36,19.61-15.05,14.12-9.64,30.45-7.32,48.19,2.59,6.93,5.61,15.79,6.44,18.75-2.01.39-1.1,1.7-1.56,2.71-.96l16.55,9.79Z"
        />
        <path
          id="upper_ankle_joint_overlay"
          data-name="upper ankle joint overlay"
          class="OSG2303Fill"
          style={{
            fill: props.colors.OSG2303Fill,
            stroke: props.colors.OSG2303Fill,
          }}
          d="M5.08,558.19c5.46,12.08,8.89,21,6.44,28.65,3.92-4.57,8.02-15.75,12.16-28.65,4.41-8.2,9.5-15.22,16.74-18.6,3.75-1.38,9.39-1.38,17.36.29,2.63.55,5.3.9,7.97,1.11,10.48.82,20.05,2.36,26.46,6.4,3.74.41,2.3,8.3,2.85,15.76.29,3.89,2.1,7.47,5.05,10.02,8.38,7.23,22.47,13.89,24.11,18.51,3.61,2.14,6.8,2.83,9.2.9l-23.2-18.7c-5.5-1.3-10.5-30.53-12.6-29.31-4.44,2.32-6.82-1.96-8.5-8.48-4.11-4.01-20.14-3.27-35.6-2.76-12.55-1.66-21.77.59-25.96,8.72-7.09,10.63-10.09,22.03-13.64,22.68-3.4.34-6.31-2.02-8.84-6.55Z"
        />
      </g>
    </svg>
  );
}
