function RightSideView() {
  return (
    <svg
      id="Ebene_2"
      data-name="Ebene 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 154.62 756.48"
      className="human-front"
    >
      <path
        class="front-pth"
        d="M123.66,193.28c.6,5.6,.5,11.4-.6,17.6,3.6,4.4,5.4,8.8,6,13.2,3.7,17.9,1.1,31.7,1.1,38.5-.6,8.2,.3,16,2.6,23.4,6,20.3,5.5,41.9,0,64.3-2.6,20.5-5.6,38.2-11.1,43.5,.9,23.8,.7,45.2-4.1,56.1-5.8,20.2-11.7,39.7-19.1,50.2-1.4,14.5-18.2,51.2-21,53.1-4.4,3.7-7.2,13.6-9.9,24.2l-9.2,51.9c-2.5,16.9-5.5,33.9-4.1,50.4-.6,9.3-.4,16.7,0,23.8,6.8,10.9,15.4,20,26.6,26.6,13,9.7,27.8,15.5,44.5,17.5,3.2,1.3,2.5,3.5-.2,6l-5.8,2.4h-15.4l-49.9-2.1-41.8-2.1c-11.5-2.1-15.1-9.9-8.4-25.3,5-9.2,8.1-17.3,10.3-24.8-.1-7.1,2-23.5-.4-52.9-16.9-50.3-2.5-84.8,21-114.6,5.3-10.8,7.1-23,6.4-36.2l-5.4-57.2s-.2-.9-.9-2.4h-3.4c-1.8-.2-3-1.5-3.4-3.6-.5-2.4,1.8-2.8,5.6-3.8,2.1-4,2.3-10.5,.8-19.3-5.8-10.5-1.4-18.7,2.1-26.8-11.1-30.1-19.5-61.5-25.3-93.9-3.4-12.9-5.1-26.3-.2-41.4-2.4-8.8-1-23.3,.4-37.9-3.2-15.7-5.7-30.8-3.2-40.9-2-10.3,.1-20.4,4.5-30.4,7.8-14.8,19.7-28.7,34.7-41.8,2.5-11.2,2.4-22.9,.4-34.9-3.5-2.1-5.5-6.9-6.6-13.3-2-9.1-.4-18.9,3.8-29.2,5.9-7.2,12.8-14.4,21.6-17.3,10.8-4.7,22.1-4.3,33.9,0,11.3,5.7,19.9,13.1,21.2,25.4-.9,8.9-1.1,17.3,0,24.8,.3,4.2,3.4,7.8,7.3,11.1-2.4,3.1-4.8,5.4-7.1,5.4-1.3,3.4-1.2,5.9,0,7.5-.9,2.3-1.7,2.5-1.9,2.6l1.1,2.4c-1.5,4.1-1.5,8.7-.2,13.7-8.3,2.9-16,4-23.1,3.2-2.5,2.7-4.8,7.3-6.9,14.4-1.8,6.7-1.7,15,0,24.6,15,12,26.2,28.3,32.1,50.4,.3,1.3,.5,2.6,.6,3.9h0Z"
      />
      <path
        class="front-pth"
        d="M73.46,168.78c-1.4,9.3-4.5,14.3-9.4,15,.1,31-5,56.7-15.4,77.1,3.4,17.3,4.8,32.7,3.8,45.9-1.4,19.8,.2,41.9,2.4,64.3l1.1,18c2.7,11.7,7.6,18.3,12.8,24-.3,11.1,.5,20.2,0,27.2-2.6,9.6-3.1,14-4.5,14.6-3.2,2.9-5.6,3.7-4.7,0,1-3.1,1-7.8,1.5-13.9v-11.8l-1.7,14.8c-3.5,4.3-7.9-2.9-7.9-.9,.1,2.6-9.1,2.5-6.8-1.8,.7-1.3-5.8,2.6-9.8-2.9,4.2,.6,11.2-10,7.9-11.1"
      />
      <path class="front-pth" d="M44.76,441.38c1,.9,5.9-8.8,5-11.6" />
      <path class="front-pth" d="M51.56,443.08s3.6-7.7,2.7-11.3" />
      <path
        class="front-pth"
        d="M92.06,737.18h37.9l19.4-1.4c6-1.5,6.7-3.8,0-7.3-13.8-1.6-25.2-6.3-35.2-9.3-15.7-5.8-27.7-14.8-35.7-28.4-4.1-10.7-2.6-26.3,0-43,5.6-14.5,10.3-53.4,15.2-88.9,12.1-14.6,17.8-33.1,20-53.7l4.5-56.8"
      />
      <path
        class="front-pth"
        d="M84.26,194.48c3.3,5.6,9.2,10.3,19.1,13.3,7.8,3.8,14,3.9,19.7,3"
      />
      <path
        class="front-pth"
        d="M133.46,347.18c-7.1-10.8-12.3-23.6-14.6-39.3-4.2-17.1-8-33.8-6.2-45.8,.4-12.5,7.3-22.2,17.6-30.4"
      />
      <path class="front-pth" d="M87.1,538.68c-.45,6.4,1.2,9.5,3.6,11.4" />
      <path class="front-pth" d="M86.36,500.08c-.3-5.3,2.7-9.1,8.6-11.6" />
      <path class="front-pth" d="M63.04,546.58c-1.3,4.6,0,7.8,3.9,9.4" />
      <path class="front-pth" d="M100.86,513.48c1.8-5.2,5-8,9.8-8.4" />
      <path
        class="front-pth"
        d="M20.37,703.88c-1.9,5.8-3.1,11.7,0,18,4.4,3,9.9,2.5,16.1,0"
      />
      <path class="front-pth" d="M62.17,714.38c4.1,1.2,7.9-.4,11-9.2" />
      <path class="front-pth" d="M106.36,322.48c13.8,9.7,17.6,26.4,13.2,48.9" />
      <path class="front-pth" d="M66.76,317.98c9.3-5.8,18.7-6.5,28.1-2" />
      <path
        class="front-pth"
        d="M97.96,99.98c-6.1-2.6-11.6-6.3-16.6-12.1-5.2-5.5-9.2-12.2-12.5-19.4-5.2,1.4-10-1.4-14.6-6.8-3.6-5.4-3.5-10.9-2.2-15.5,2.2-5.1,7.4-2.6,10.7,0"
      />
      <path class="front-pth" d="M107.26,79.38c4.2,1.2,8.5,1.6,12.8,1.2" />
      <path class="front-pth" d="M108.17,746.48c11.9,4.5,16.7,7.4,13.3,8.4" />
      <path class="front-pth" d="M104.47,748.28c10.7,4.9,15.8,7.7,9.2,7.6" />
      <path class="front-pth" d="M99.17,749.48c9.1,4.2,12.2,6.9,7.5,6" />
      <path class="front-pth" d="M95.57,751.18c9.3,4.6,8.3,4.6,5,4.6" />
      <path
        class="front-pth"
        d="M27.36,130.28c4.5-2.5,11.5-3.7,19.9-4.2,16.9,2.6,24,10.7,25.9,22.8"
      />
      <path class="front-pth" d="M94.86,142.28c-5.1-2.8-9.5-2.5-12-2.7" />
      <path class="front-pth" d="M103.66,236.58c-9.9-5.3-18.7-16-26.6-30.7" />
      <path class="front-pth" d="M94.96,248.18c-8.8-3.3-16.3-9.3-21.4-20.6" />
      <path class="front-pth" d="M84.16,257.28c-4.1-1.2-7.4-4-9.6-9" />
    </svg>
  );
}

export default RightSideView;
