import {
  getWorklists,
  getWorklist,
  getWorklistStudies,
  getWorklistStudiesList,
} from "../Helpers/FetchFunctions/ris/WorklistFetches";
import usePmedQuery, { usePmedInfiniteQuery } from "../Helpers/UsePmedQuery";

export function useWorklists({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["worklists", pageNr, pageSize, filter, sort, xmask],
    getWorklists,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useWorklist({
  worklistId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["worklist", worklistId, xmask],
    getWorklist,
    {
      worklistId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useWorklistStudies({
  worklistId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["worklistStudies", worklistId, xmask],
    getWorklistStudies,
    {
      worklistId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useWorklistStudiesList({
  ignoreEmptyImageMasKv = false,
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    [
      "worklistStudiesList",
      pageNr,
      pageSize,
      filter,
      sort,
      xmask,
      ignoreEmptyImageMasKv,
    ],
    getWorklistStudiesList,
    { pageSize, pageNr, filter, sort, xmask, ignoreEmptyImageMasKv },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useInfiniteWorklistStudiesList({
  ignoreEmptyImageMasKv = false,
  pageSize = 10,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
}) {
  const query = usePmedInfiniteQuery(
    [
      "worklistStudiesList",
      1,
      pageSize,
      filter,
      sort,
      xmask,
      ignoreEmptyImageMasKv,
    ],
    getWorklistStudiesList,
    { pageSize, pageNr: 1, filter, sort, xmask, ignoreEmptyImageMasKv },
    "studies",
    pageSize,
    enabled
  );
  return query;
}
