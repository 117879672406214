import { Container, Tab, Row, Col, Nav } from "react-bootstrap";
import React, { useState } from "react";
import RemovedExaminations from "../Components/RemovedExaminations/RemovedExaminations";
import WorkInProgress from "../Components/WorkInProgress/WorkInProgress";
import "./stylesheets/AdminPage.scss";

const AdminPageItems = [
  {
    Title: "Restore Examinations",
    SubTitle: "Restore deleted images.",
    Component: RemovedExaminations,
  },
  {
    Title: "Create User",
    SubTitle: "Add user, assign to group",
    Component: WorkInProgress,
  },
  {
    Title: "Delete User",
    SubTitle: "Delete user",
    Component: WorkInProgress,
  },
  {
    Title: "Menage Users",
    SubTitle: "Assign user to group",
    Component: WorkInProgress,
  },
];

function AdminPage() {
  const [selectedItem, setSelectedItem] = useState(AdminPageItems[0]);
  const SubComponent = selectedItem.Component;
  return (
    <Container fluid className="bg-white">
      <Row className="d-flex" style={{ height: "100vh" }}>
        <Col xs={3} className="d-flex flex-column px-0 border-right">
          {AdminPageItems.map((item, key) => (
            <div
              onClick={() => setSelectedItem(item)}
              className={`py-2 px-2 border-bottom category-tab ${
                selectedItem === item && "selected-item"
              }`}
              key={`adminpageitemskey${key}`}
            >
              <h5>{item.Title}</h5>
              <div className="small text-muted">{item.SubTitle}</div>
            </div>
          ))}
        </Col>
        <Col xs={9} className="bg-white px-0">
          <SubComponent />
        </Col>
      </Row>
    </Container>
  );
}

export default AdminPage;
