import {
  SET_EXAMPLANNER_PATIENT_ID,
  CLEAR_EXAMPLANNER_PATIENT_ID,
  SET_EXAMPLANNER_DEVICE_ID,
  CLEAR_EXAMPLANNER_DEVICE_ID,
  SET_EXAMPLANNER_FACILITY_ID,
  CLEAR_EXAMPLANNER_FACILITY_ID,
  SET_EXAMPLANNER_RADIOLOGIST_ID,
  CLEAR_EXAMPLANNER_RADIOLOGIST_ID,
  ADD_EXAMPLANNER_INDICIATION,
  REMOVE_EXAMPLANNER_INDICATION,
  CLEAR_EXAMPLANNER_INDICATION,
  ADD_EXAMPLANNER_EXAMINATION,
  REMOVE_EXAMPLANNER_EXAMINATION,
  CLEAR_EXAMPLANNER_EXAMINATION,
  SELECT_EXAMPLANNER_BODYPART,
  CLEAR_EXAMPLANNER_BODYPART,
  SELECT_EXAMPLANNER_DEVICE_TYPE,
  CLEAR_EXAMPLANNER_VIEW_CONTROLL,
  CLEAR_EXAMPLANNER_RIS_DATA,
  CLEAR_EXAMPLANNER_STATE,
  CLEAR_EXAMPLANNER_FACILITY_DOC_ID,
  SET_EXAMPLANNER_FACILITY_DOC_ID,
  CLEAR_EXAMPLANNER_BODYPART_HISTORY,
  ADD_EXAMPLANNER_BODYPART_TO_HISTORY,
  SELECT_PREVIOUS_BODY_PART,
} from "../actionTypes";

const initialState = {
  viewControll: {
    bodyPartHistory: [undefined],
    prevSelectedBodyPart: {},
    selectedBodyPart: {},
    selectedDeviceType: 2, //2-roentgen
  },
  risData: {
    patientId: null,
    deviceId: null,
    facilityId: null,
    facilityDocId: null,
    radiologistId: null,
    indications: [],
    examinations: [],
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_EXAMPLANNER_PATIENT_ID: {
      const { content } = action.payload;
      return {
        ...state,
        risData: { ...state.risData, patientId: content },
      };
    }
    case CLEAR_EXAMPLANNER_PATIENT_ID: {
      return {
        ...state,
        risData: { ...state.risData, patientId: null },
      };
    }
    case SET_EXAMPLANNER_DEVICE_ID: {
      const { content } = action.payload;
      return {
        ...state,
        risData: { ...state.risData, deviceId: content },
      };
    }
    case CLEAR_EXAMPLANNER_DEVICE_ID: {
      return {
        ...state,
        risData: { ...state.risData, deviceId: null },
      };
    }
    case SET_EXAMPLANNER_FACILITY_ID: {
      const { content } = action.payload;
      return {
        ...state,
        risData: { ...state.risData, facilityId: content },
      };
    }
    case CLEAR_EXAMPLANNER_FACILITY_ID: {
      return {
        ...state,
        risData: { ...state.risData, facilityId: null },
      };
    }
    case SET_EXAMPLANNER_FACILITY_DOC_ID: {
      const { content } = action.payload;
      return {
        ...state,
        risData: { ...state.risData, facilityDocId: content },
      };
    }
    case CLEAR_EXAMPLANNER_FACILITY_DOC_ID: {
      return {
        ...state,
        risData: { ...state.risData, facilityDocId: null },
      };
    }
    case SET_EXAMPLANNER_RADIOLOGIST_ID: {
      const { content } = action.payload;
      return {
        ...state,
        risData: { ...state.risData, radiologistId: content },
      };
    }
    case CLEAR_EXAMPLANNER_RADIOLOGIST_ID: {
      return {
        ...state,
        risData: { ...state.risData, radiologistId: null },
      };
    }

    case SELECT_EXAMPLANNER_BODYPART: {
      const { content } = action.payload;
      return {
        ...state,
        viewControll: {
          ...state.viewControll,
          prevSelectedBodyPart: state.viewControll.selectedBodyPart,
          selectedBodyPart: content,
        },
      };
    }
    case CLEAR_EXAMPLANNER_BODYPART: {
      return {
        ...state,
        viewControll: {
          ...state.viewControll,
          selectedBodyPart: {},
          prevSelectedBodyPart: {},
        },
      };
    }
    case SELECT_EXAMPLANNER_DEVICE_TYPE: {
      const { content } = action.payload;
      return {
        ...state,
        viewControll: { ...state.viewControll, selectedDeviceType: content },
      };
    }
    case CLEAR_EXAMPLANNER_VIEW_CONTROLL: {
      return {
        ...state,
        viewControll: initialState.viewControll,
      };
    }
    case CLEAR_EXAMPLANNER_RIS_DATA: {
      return {
        ...state,
        risData: initialState.risData,
      };
    }
    case CLEAR_EXAMPLANNER_STATE: {
      return {
        ...initialState,
      };
    }
    //arrays states
    case ADD_EXAMPLANNER_INDICIATION: {
      const { content } = action.payload;
      let indicationsCopy = [...state.risData.indications];
      indicationsCopy.push(content);
      return {
        ...state,
        risData: { ...state.risData, indications: indicationsCopy },
      };
    }
    case REMOVE_EXAMPLANNER_INDICATION: {
      const { content } = action.payload;
      let indicationsCopy = [...state.risData.indications];
      let idx = indicationsCopy.findIndex(
        (indication) => indication.id === content.id
      );
      indicationsCopy.splice(idx, 1);
      return {
        ...state,
        risData: { ...state.risData, indications: indicationsCopy },
      };
    }
    case CLEAR_EXAMPLANNER_INDICATION: {
      return {
        ...state,
        risData: { ...state.risData, indications: [] },
      };
    }

    case ADD_EXAMPLANNER_EXAMINATION: {
      const { content } = action.payload;
      let examinationsCopy = [...state.risData.examinations];
      examinationsCopy.push(content);
      return {
        ...state,
        risData: { ...state.risData, examinations: examinationsCopy },
      };
    }
    case REMOVE_EXAMPLANNER_EXAMINATION: {
      const { content } = action.payload;
      let examinationsCopy = [...state.risData.examinations];
      let idx = examinationsCopy.findIndex((ex) => ex.id === content.id);
      examinationsCopy.splice(idx, 1);
      if (examinationsCopy.length === 0) {
        return {
          viewControll: {
            ...state.viewControll,
            selectedBodyPart: {},
            prevSelectedBodyPart: {},
          },
          risData: { ...state.risData, examinations: examinationsCopy },
        };
      } else {
        return {
          ...state,
          risData: { ...state.risData, examinations: examinationsCopy },
        };
      }
    }
    case CLEAR_EXAMPLANNER_EXAMINATION: {
      return {
        viewControll: {
          ...state.viewControll,
          selectedBodyPart: {},
          prevSelectedBodyPart: {},
        },
        risData: { ...state.risData, examinations: [] },
      };
    }

    case ADD_EXAMPLANNER_BODYPART_TO_HISTORY: {
      const { content } = action.payload;
      let bpartHistory = [...state.viewControll.bodyPartHistory];
      if (!bpartHistory.includes(content.id)) {
        bpartHistory.push(content.id);
      }
      return {
        ...state,
        viewControll: {
          ...state.viewControll,
          bodyPartHistory: bpartHistory,
        },
      };
    }

    case CLEAR_EXAMPLANNER_BODYPART_HISTORY: {
      return {
        ...state,
        viewControll: {
          ...state.viewControll,
          bodyPartHistory: [undefined],
        },
      };
    }

    case SELECT_PREVIOUS_BODY_PART: {
      let bpartHistory = [...state.viewControll.bodyPartHistory];
      bpartHistory.splice(bpartHistory.length - 1, 1);
      if (bpartHistory.length === 1) {
        return {
          ...state,
          viewControll: {
            ...state.viewControll,
            prevSelectedBodyPart: state.viewControll.selectedBodyPart,
            selectedBodyPart: {},
            bodyPartHistory: bpartHistory,
          },
        };
      }
      return {
        ...state,
        viewControll: {
          ...state.viewControll,
          prevSelectedBodyPart: state.viewControll.selectedBodyPart,
          selectedBodyPart: {
            id: bpartHistory[bpartHistory.length - 1],
          },
          bodyPartHistory: bpartHistory,
        },
      };
    }

    default:
      return state;
  }
}
