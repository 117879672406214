import style from "./style.scss";

function Linker4FingerLatSitzend1325(props) {
  return (
<svg id="rechterer4fingerlat" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 232.4 489.2">

<g id="Highlights">
  <g id="white_Background" data-name="white Background">
    <path class="Linker_Finger4_lat_sitzend1325_cls6" d="M.3,487.9l24.6,1.1,85.2-8.2,17.6,1.4c7.7.5,16.1-6.3,25-18.9,24.2-37.6,51.1-62.1,79.3-80.1l-2.5-19.8,1.1-19.8.2-29.8-37.1,25.5c8.9-33,11.3-77.1,10-127.9,6.6-32.3,7.9-61.8,3.8-88.2l-5.8-77.9c-4.6-24.3-14.9-40.8-34.6-44.5-8.8-.7-16.1.7-21.5,4.8-3.3,2.5-5.3,6.5-5.4,10.7-1.9,47.6-6.8,90.4-16.2,126.3-11.2,38.5-20.8,76.5-22.3,111.9-9,14.8-20.2,58.8-32.3,114.5-6.3,24.2-17.6,45-31.5,64.1-3.4,4.6-7.3,8.9-11.5,12.9-12,11.2-20.4,25.4-26.1,41.9H.3Z"/>
  </g>
  <g id="finger">
    <path id="basephalanx" class="FullFinger1325Fill" style= {{
            fill: props.colors.FullFinger1325Fill,
            stroke: props.colors.FullFinger1325Fill,
            opacity:props.colors.FullFinger1325Fill,
          }} d="M135.2,369c-1.9,19.5-.9,42,1.4,65.5-1.8,18.6-14.3,20.7-32,14.8-7.2-4.4-14.8-8.7-23.7-8.2-1.9.1-3.8-.2-5.4-1.1-10.3-5.8-10.3-16.9-3.5-31.6,5.3-14.9,11.6-32.5,14.6-41.1,9.7-34.5,20.1-66.3,31.6-94.8-.3-22.4,5.2-32.6,14.9-33.4,14.4-2.9,26.2,1,36.5,8.8,10,12.5,8.6,22.4,0,30.7-10.5,6.6-16.3,15-19.8,24.6-8.5,18.9-13.2,41-14.6,65.8h0Z"/>
    <path id="middlephalanx" class="FullFinger1325Fill" style= {{
            fill: props.colors.FullFinger1325Fill,
            stroke: props.colors.FullFinger1325Fill,
            opacity:props.colors.FullFinger1325Fill,
          }} d="M118.5,235c.5,5.4,2.2,8,6.4,5.2,11.3-6.2,22.9-6,34.6,0,6.8.2,11-5.4,11.5-19.5-5.9-6.1-8.1-14.2-8.5-23.1,1-17.9.3-29.5-1.8-35.9,1.6-14.2,5.1-25.8,10.3-34.9,9.5-5.9,12-12.1,7.3-18.5-5.6-9.5-12.5-10.8-20.1-9.1-8.3,3.8-12.1,10.1-11.9,18.5-10.5,22-17.6,61.8-25.5,98.7-6.3,9.8-8.6,16.9-2.3,18.6h0Z"/>
    <path id="endphalanx" class="FullFinger1325Fill" style= {{
            fill: props.colors.FullFinger1325Fill,
            stroke: props.colors.FullFinger1325Fill,
            opacity:props.colors.FullFinger1325Fill,
          }} d="M154.9,94.5c6.7-3,13-3.1,19,0,3.6,4,5.3.7,5.9-6.8,4.4-4.3,2.4-10.3-2.3-16.9-5.6-8.2-9.1-17.7-11.5-27.8,4.1-7.7,1-14.8-4.2-21.8-.9-1.1-2.1-2-3.4-2.5-4-1.4-7-.8-9.3,1.6-.9.9-1.4,2.2-1.4,3.5v57.3c-2.5,4.7-4.1,9.3-2.4,13.4,1.8,5.3,4.8,5.6,9.6,0h0Z"/>
  </g>
  <ellipse id="groundjointhighlight" class="Grundgelenk1325Fill" style= {{
            fill: props.colors.Grundgelenk1325Fill,
            stroke: props.colors.Grundgelenk1325Fill,
            opacity:props.colors.Grundgelenk1325Fill,
          }} cx="94.3" cy="453.9" rx="15.2" ry="61.3" transform="translate(-354.6 337.9) rotate(-63.8)"/>
  <ellipse id="basejointhighlight" class="Gelenkspalte1325Fill" style= {{
            fill: props.colors.Gelenkspalte1325Fill,
            stroke: props.colors.Gelenkspalte1325Fill,
            opacity:props.colors.Gelenkspalte1325Fill,
          }} cx="143" cy="241.2" rx="35.4" ry="8.6"/>
  <ellipse id="middlejointhighlight" class="Gelenkspalte1325Fill" style= {{
            fill: props.colors.Gelenkspalte1325Fill,
            stroke: props.colors.Gelenkspalte1325Fill,
            opacity:props.colors.Gelenkspalte1325Fill,
          }} cx="162.9" cy="96.1" rx="20.9" ry="6.8"/>
  <ellipse id="basejointhighlight-2" data-name="basejointhighlight" class="Gelenkspalte1325Fill" style= {{
            fill: props.colors.Gelenkspalte1325Fill,
            stroke: props.colors.Gelenkspalte1325Fill,
            opacity:props.colors.Gelenkspalte1325Fill,
          }} cx="94.3" cy="448.5" rx="8.4" ry="35.4" transform="translate(-352.2 345.1) rotate(-65.1)"/>
  <path id="fingertiphighlight" class="Fingerkuppe1325Fill" style= {{
            fill: props.colors.Fingerkuppe1325Fill,
            stroke: props.colors.Fingerkuppe1325Fill,
            opacity:props.colors.Fingerkuppe1325Fill,
          }} d="M167.1,36.7c-7.7-11.6-16.5-16.9-20-11.7.1-2.5,1-5.3,3.4-6.4,1.4-.9,3.5-1,6.6-.3,6,1.5,9.9,12.8,10,18.4h0Z"/>
</g>
<g id="rechterer4fingerlat1">
  <line id="basephalanx5" class="Linker_Finger4_lat_sitzend1325_cls2" x1="114.2" y1="443" x2="80.9" y2="433.2"/>
  <path id="basephalanx4" class="Linker_Finger4_lat_sitzend1325_cls2" d="M111.4,433.2c-9.1-4.4-18.2-6.7-27.3-6.8"/>
  <path id="basephalanx3" class="Linker_Finger4_lat_sitzend1325_cls2" d="M124.7,424.1c-7.4-16.6-8.9-34.9-6.7-54.1,5.8-38.6,15-68.3,27.3-89.3"/>
  <path id="basephalanx2" class="Linker_Finger4_lat_sitzend1325_cls2" d="M71.3,408.1c13.1-8,22.8-21.5,29-40.5,11.6-37.5,19.6-70.6,22.3-97.2"/>
  <path id="basephalanx1" class="Linker_Finger4_lat_sitzend1325_cls2" d="M147.1,288.2c.4-5.3,2.5-8.5,6.9-10.5,8.1-3.5,11.1-9.7,8.4-19.1-4-13.1-12.6-16.6-22.9-16.4-11.3,1-15.4,7.2-16.7,15.5"/>
  <path id="basephalanx-2" data-name="basephalanx" class="Linker_Finger4_lat_sitzend1325_cls2" d="M134.5,368.9c-1.9,19.5-.9,42,1.4,65.5-1.8,18.6-14.3,20.7-32,14.8-7.2-4.4-14.8-8.7-23.7-8.2-1.9.1-3.8-.2-5.4-1.1-10.3-5.8-10.3-16.9-3.5-31.6,5.3-14.9,11.6-32.5,14.6-41.1,9.7-34.5,20.1-66.3,31.6-94.8-.3-22.4,5.2-32.6,14.9-33.4,14.4-2.9,26.2,1,36.5,8.8,10,12.5,8.6,22.4,0,30.7-10.5,6.6-16.3,15-19.8,24.6-8.4,18.9-13.1,41-14.6,65.8h0Z"/>
  <path id="middlephalanx5" class="Linker_Finger4_lat_sitzend1325_cls2" d="M155.1,227c-8.3.3-17.6-.1-27.3-.9"/>
  <path id="middlephalanx4" class="Linker_Finger4_lat_sitzend1325_cls2" d="M160.7,232.3c-9.4-2.4-19.8-1.8-30.8.8"/>
  <path id="middlephalanx3" class="Linker_Finger4_lat_sitzend1325_cls2" d="M150.6,109.6s-2.2,19.8-3.6,27.5c-8.7,52.4-17.3,74.6-25.9,81.9"/>
  <path id="middlephalanx2" class="Linker_Finger4_lat_sitzend1325_cls2" d="M160.7,216.1c-8.7-14.7-13.2-31.4-10.6-51.8,1.6-16.7,6-31,14.3-42.1,7.3-2.9,10.9-7.2,11.6-12.6"/>
  <path id="middlephalanx1" class="Linker_Finger4_lat_sitzend1325_cls2" d="M160.1,161.7c-3,10-3.6,21.8-2.7,34.6"/>
  <path id="middlephalanx-2" data-name="middlephalanx" class="Linker_Finger4_lat_sitzend1325_cls2" d="M117.8,234.9c.5,5.4,2.2,8,6.4,5.2,11.3-6.2,22.9-6,34.6,0,6.8.2,11-5.4,11.5-19.5-5.9-6.1-8.1-14.2-8.5-23.1,1-17.9.3-29.5-1.8-35.9,1.6-14.2,5.1-25.8,10.3-34.9,9.5-5.9,12-12.1,7.3-18.5-5.6-9.5-12.5-10.8-20.1-9.1-8.3,3.8-12.1,10.1-11.9,18.5-10.5,22-17.6,61.8-25.5,98.7-6.2,9.8-8.5,16.9-2.3,18.6h0Z"/>
  <path id="endphalanx4" class="Linker_Finger4_lat_sitzend1325_cls2" d="M172.4,90.1c-5.8-2.4-11.6-2.4-17.3,0"/>
  <path id="endphalanx3" class="Linker_Finger4_lat_sitzend1325_cls2" d="M151.2,26c2.1,7.8.2,16,1.5,22.5,1.8,22.8-1.2,30.1-5.7,32.5"/>
  <line id="endphalanx1" class="Linker_Finger4_lat_sitzend1325_cls2" x1="165.4" y1="42.9" x2="161.9" y2="34.4"/>
  <path id="endphalanx2" class="Linker_Finger4_lat_sitzend1325_cls2" d="M175.9,79.3c-12.6-11.8-17.6-26.9-17.6-44.2"/>
  <path id="endphalanx-2" data-name="endphalanx" class="Linker_Finger4_lat_sitzend1325_cls2" d="M154.3,94.4c6.7-3,13-3.1,19,0,3.6,4,5.3.7,5.9-6.8,4.4-4.3,2.4-10.3-2.3-16.9-5.6-8.2-9.1-17.7-11.5-27.8,4.1-7.7,1-14.8-4.2-21.8-.9-1.1-2.1-2-3.4-2.5-4-1.4-7-.8-9.3,1.6-.9.9-1.4,2.2-1.4,3.5v57.3c-2.5,4.7-4.1,9.3-2.4,13.4,1.7,5.3,4.8,5.6,9.6,0h0Z"/>
  <g id="softpart">
    <path id="softpart3" class="Linker_Finger4_lat_sitzend1325_cls2" d="M184.4,364.6c-.9,1.8-1.8,3.6-2.8,5.3"/>
    <path id="softpart2" class="Linker_Finger4_lat_sitzend1325_cls1" d="M4.7,476.9c5.4-11.9,12.5-22.3,21.6-31,4.2-4,8-8.2,11.5-12.9,13.9-19,25.3-39.9,31.5-64.1,12.1-55.8,23.3-99.7,32.3-114.5,1.5-35.4,11.1-73.4,22.3-111.9,9.4-35.8,14.3-78.7,16.2-126.3.2-4.2,2.1-8.1,5.4-10.7C150.8,1.4,158.2,0,167,.7c19.7,3.7,30,20.2,34.6,44.5l5.8,77.9c4.1,26.5,2.8,55.9-3.8,88.3,1.5,58.6-2,108.4-14.6,142.4"/>
    <path id="softpart1" class="Linker_Finger4_lat_sitzend1325_cls2" d="M.3,488c.7-1.9,1.4-3.8,2.1-5.6"/>
  </g>
  <path id="nextfinger2" class="Linker_Finger4_lat_sitzend1325_cls2" d="M152.7,463.4c.7-15.5,5.3-28.7,14.8-38.9,12.9-14.3,36.8-32.8,62-51.7"/>
  <path id="nextfinger1" class="Linker_Finger4_lat_sitzend1325_cls2" d="M119.1,414.4c11,1.4,22.1,1.1,33.6-4.6,17.7-7.5,46.7-26.4,76.8-46.5"/>
  <path id="nextfinger" class="Linker_Finger4_lat_sitzend1325_cls2" d="M231.9,383.2c-32.5,20.1-60.2,48.5-82.9,85.9-13.6,15.8-25.4,17.9-34.9,3.1-1.1-1.8-1.8-3.8-2.1-5.9-1.1-9-5.6-16.8-13.2-23.5-1.4-1.2-2.3-2.8-2.9-4.5-2.8-9.5-1.5-17.6,6.5-23.2l48.8-22.9,79.3-48.6"/>
  <g id="softpart_next_finger" data-name="softpart next finger">
    <path id="softpart_next_finger3" data-name="softpart next finger3" class="Linker_Finger4_lat_sitzend1325_cls2" d="M147.8,375.5c-1.5,1.3-3,2.6-4.5,4"/>
    <path id="softpart_next_finger2" data-name="softpart next finger2" class="Linker_Finger4_lat_sitzend1325_cls1" d="M221.2,320c-22.9,15.3-44.8,31.3-65.2,48.4"/>
    <path id="softpart_next_finger1" data-name="softpart next finger1" class="Linker_Finger4_lat_sitzend1325_cls2" d="M230.8,313.8c-1.7,1.1-3.4,2.2-5,3.3"/>
  </g>
  <path id="middlehand" class="Linker_Finger4_lat_sitzend1325_cls2" d="M110.1,480.8c-.8-15-8.4-24.8-19.8-31.7-18.9-7.4-36.2-2.2-52.4,11.6-13.2,11.3-15.9,20.4-13,28.3"/>
</g>
</svg>
  );
}

export default Linker4FingerLatSitzend1325;
