import style from "./style.scss";

function RechtesDaumenGrundGelenkLatSitzend1310(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 181 321.9"
    >
      <g id="Thumbbasejoint">
        <g id="background">
          <path
            id="background1_white"
            data-name="background1 white"
            class="rechtes_daumengrundgelenk_lat_sitzend1310_cls5"
            d="M41.1,515.6l2.1-33.5c-5.7-31.1-4.6-56.9,3.1-77.6,0-17.2,3.2-29.3,14.1-29.6l18.6,22.8,3.5-.5c-12-13.3-19-24.8-12.3-31.8l.9-5.2h-6.1c-5.1,2.3-21.6,1.9-25.7-9.6,1.4-5.5-5.8-9.9,1.6-14.4,5.1-1.8,8.2-6.2,9.4-13.1-7.1-2.4-10.8-9.2-13.7-17.1-4.5-15.1-7.9-30.6-10.7-46.3l-8.9-62.5c-4.4-14.1-5.8-25.2-4.9-34.1l11.8-60.5c0-14.9,1.6-26.9,5.4-34.7l7.9-33.6c-.8-19.7.7-28.7,10.2-29.7,8.9-1.7,16,1.9,20.5,12.4,7.4,12.4,8.9,28.8,8.3,46.7-.7,6.2-3.4,12.4-6.1,15.6,0,0,3.8,22.4,3.4,35.1-1.2,9.8,1.2,17.9,7.4,24.3,9.5-5.1,19.5-8.1,30-8.4,19.7-46.9,36.3-70,52.9-72.9l1.2,9.7c1.6,10.1,2.1,21.5,2,33.5v6.4s3.1,33.4,3.1,33.4c-4.1,1.1-6.8,5.2-8.5,11.8-1.6,6.6-3.5,13.1-5.9,19.5-14.4,38.3-24.4,74.2-16,108.3,12.8-12.9,24.4-30.6,35.4-51.7l.7,20.6-13.5,65.6-16.3,61.7c14.2,19.9,16.2,32.1,8.2,38.1-4.2,2.8-8.5,4.1-13,4.3,4.1,10.1,1.9,20.3-3.5,30.6-17.8,16.4-29.5,41.1-34.7,67.2l-62.1-.9h0Z"
          />
        </g>
        <g id="Thumbbasejoint1">
          <g id="Thumbbase">
            <path
              id="fingerparts25"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
              d="M46.4,295.7h0c-7.5.3-10.4-8-10.9-21l-6-39.3c-4-13.9-6.4-29.5-7.8-46.2-2.5-8.2-8-16.3-2.1-23.9,2.9-3.3,6.9-5.9,12-8,3.2-1.3,6.8-1.7,10.2-1.1,5.9,1.2,10.5,4.6,12.9,12.3,3.7,5.7,3.1,10-1.9,13-1.4,4.8-1.6,9.4-.4,14,1.4,11.1,3.9,24.8,7,39.4,4.8,11.4,11.7,21.2,23.5,27.6l4.7,15.2c1.8,4.4.8,7.3-5.4,7-8.2-.9-15.9,2.5-23.1,10.3-3.5,4.3-6.9,5.3-10.1,1.8-1,.2-2.1-.3-2.5-1.3h0Z"
            />
            <path
              id="fingerparts24"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
              d="M22.4,149.4h0c-1.9-2-1.5-4.4.5-7.1.4-.5.7-1.1.9-1.8,4.4-15.1,6-31.7,6.3-49.2,0-1.1-.2-2.2-.6-3.2-1.9-5.2.2-9.8,6.8-13.7,3.5-4.3,8.4-5.2,14.2-3.9,4.6,2,7.4,5.1,7.8,9.5.4,3.7-.9,6.4-3.3,8.4-1.7,1.4-3,3.3-3.5,5.5-1.9,7.8-1.1,17.1,2.1,27.7l3,8.6c.9,2.7,2.3,5.2,3.8,7.6,3,4.6,3,9.2-.2,14-2.1,4.4-4.7,5.2-7.6,4.3-1.8-.6-3.7-.9-5.7-.8-6.2.3-11.6.9-15.4,2.3-2.7.9-5.7-.3-6.9-2.9-.3-.7-.6-1.5-.8-2.3-.2-1-.7-2-1.4-2.7h0Z"
            />
            <path
              id="fingerparts23"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
              d="M35.4,69.9h0c-1.8-2.9-1-5.2,1.2-7.2,4.5-10.2,5.4-21.1,5.4-32.3.4-2.8,1.1-5.3,2-7.7.3-.8.4-1.6.3-2.4l-.7-5.1c0-.2,0-.4.2-.6,2.5-2.5,5.1-2.8,7.7,1,4.2,4.7,5,10.4,4.1,16.7-.2,1,0,2.1.5,3,3.5,6.9,4.4,13.9,1.6,20.8,1.9-.2,3.4,1.5,3.9,6.8,0,3.8-1.3,5.7-3.1,6.2.1,3.3-1.4,5.2-4.3,6-6.6-4.4-12.9-6.2-18.6-5.2h0Z"
            />
            <path
              id="fingerparts22"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
              d="M49.8,168h0c-2.8-3.5-2.2-5.9,0-7.9,3-1.9,6.7-2.1,10.9-.7,1.9.6,3.2,2.4,3.1,4.4-.1,2.5-1.1,5.1-1.1,5.1,0,0-4.6,4.1-8.6,4.1-1.7-.4-3.1-2.2-4.3-4.9h0Z"
            />
            <path
              id="fingerparts21"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
              d="M72.9,165.7h0c-.9,5.3-3.9,6.6-7.9,6-3.4-2.1-3.7-4.7-3.5-7.4-.4-3.1.4-5.2,4.3-4.4.9.2,1.9.3,2.8.5,2.2.4,4,1.7,4.3,5.3h0Z"
            />
            <path
              id="fingerparts20"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
              d="M166.9,107c-8.3-4.7-16.9-5.8-25.8-2.7-11.1,4.7-17.9,11.2-16.9,21,2.2,3.1,2.1,7.3-1.5,13.2-4.1,30-8.5,57.8-14.2,77.5-5.5,24.7-12.8,41.4-22.3,47.8-3.9,4.9-5.2,9.8-3.7,14.6-3.5,7.9-5.3,14.9-2.7,19.6,3.3,2.9,7,2.3,11.1,0,6-1.2,10.9.5,14,7.6l3.5,4.7"
            />
            <path
              id="fingerparts19"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
              d="M165.6,69.9c-2.8,3.3-6.4,5.1-10.7,5.2-3.7-.6-5.9,1.4-7.2,5.1-2.6,6-2.6,11.5,3.1,15.9,4.1-.7,9.9,1.5,16.1,4.5"
            />
            <path
              id="fingerparts18"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
              d="M170,140.4c-3.4,1.1-6.1,3.9-7.8,9.5-9.6,28.4-18.2,55.6-24.1,79.7-2.8,12.4-2.8,28.9,1.9,52.5-1,5.7-1.4,11,.6,15,.6,4.9.6,9.5-1.2,13.2"
            />
            <path
              id="fingerparts17"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
              d="M175.1,229.4c-9.5,17.2-20.6,33.9-33,50.3-7.8,7.6-12.5,15.1-13.6,22.5l-4.1,8"
            />
            <path
              id="fingerparts16"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
              d="M154.1,105.7h0c.8,5.5-1.7,7.4-6.6,6.8-4.6-2-6.3-5.5-6.2-9.9,1.3-3.9,3.5-5.1,6.4-4.3,3.9,2.2,6.5,4.6,6.4,7.4h0Z"
            />
            <path
              id="fingerparts15"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M144.4,284c3-4,7.7-7.9,13.6-11.9,7.6-6.4,13.7-14.6,18.5-24.5"
            />
            <path
              id="fingerparts14"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M22.6,179.1c1.9,1.6,3.4,4,3.9,8.4,6.2,26,9.4,52.2,10.7,78.4"
            />
            <path
              id="fingerparts13"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M37.1,177.9c5.4,14.3,10,29.7,12.4,47.4,2.8,17.4,10.7,30.5,27.4,36.1"
            />
            <path
              id="fingerparts12"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M46,186c-.1-4.2,1.2-7.5,3.4-10.3"
            />
            <path
              id="fingerparts11"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M33.5,87.5c3.7,3.4,4,11.8,2,23.6-1.1,10-3.5,17.6-6.6,24.1"
            />
            <path
              id="fingerparts10"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M46.5,89c-3.8,13.8-2,27.1,6.2,39.7"
            />
            <path
              id="fingerparts9"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M50.3,89c1.3-5.5,1.3-10.2-2.6-12.8-3.4-2-7.2-2.5-11.3-1.9-2.9,1.3-3.9,3.8-3.6,7.3"
            />
            <path
              id="fingerparts8"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M37.9,63.6c7.2-7.8,10-22.4,8.9-43.2"
            />
            <path
              id="fingerparts7"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M51.4,21.4c.3,1.3.1,2.4-.5,3.3-.4.6-.7,1.3-.8,2-.4,4.5,0,8.9,2.2,13.1.3.6.6,1.3.7,2,.4,2.4.3,6.1.3,9.8s.1,1,.3,1.4c.3.9.4,2.2.2,4.1-.1,1.5,0,3,.5,4.2.5,1,.8,2.1.8,3.3,0,.9.2,1.8.3,2.5-5-4.3-10.4-4.7-16.1-1.9"
            />
            <path
              id="fingerparts6"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M57.5,56.1c-2.2,0,.8,6.1-.4,7.5-.4,2.3,0,4.1,1.1,5.5"
            />
            <path
              id="fingerparts5"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M29.3,153.5c12.6-3,19.4-2.4,21,1.5"
            />
            <path
              id="fingerparts4"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M89.6,275c2.8-5.8,7.5-12.5,13.5-19.7,7.5-7.6,12.4-18.7,15.1-32.8,4.9-17.6,9.8-35.5,12.1-71.3.2-2.5-.1-5-.7-7.4-1.6-6.3-2-11.3.9-12.6,1.7-1,3.1-2.8,4-6.1.4-2.7,2.5-4.3,5-5.6"
            />
            <path
              id="fingerparts3"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M125.5,128.3c1.6-2,3.7-1.4,5.9-5.2.3-.6.6-1.2.7-1.9.4-3.3,8.1-7.2,7.3-13.3"
            />
            <path
              id="fingerparts2"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M169.1,126.1c-3.1,1.3-5.7,4.7-7.7,10.8l-5,17c-9,20.1-17,39-21.7,53.4-8.7,22.4-9.9,49.9-4.8,81.7"
            />
            <path
              id="fingerparts1"
              class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
              d="M169.1,133.4c-1.8,0-3.6,2.3-5.4,7"
            />
            <g id="outlines3">
              <path
                id="outlinepart13"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
                d="M36.7,306c-.4-1.2-.8-2.4-1.1-3.7"
              />
              <path
                id="outlinepart12"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls1"
                d="M33.6,294.8c-.3-1.3-.6-2.7-.9-4.1-10.1-35.7-8.1-58.4-18.6-104.7-2.5-10.7-3.6-21-1.2-30,6.4-23.1,10.9-47.3,11.7-73.6.1-4.3,1.1-8.6,2.9-12.6,5.8-12.5,9.5-30,10.2-54.6,0-2.4.9-4.8,2.5-6.6,3.1-3.5,8.1-4.7,15.2-3.7,7.2.5,13,8,17.4,22.4,4.2,19.1,5.5,35,1.3,45.4"
              />
              <path
                id="outlinepart11"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
                d="M72.4,76.2c-.7,1.1-1.5,2.1-2.3,3,.4,1.1.8,2.4,1.1,3.7"
              />
              <path
                id="outlinepart10"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls1"
                d="M72.6,90.5c.8,6.6.9,14.4.3,23.3-.2,7.5.8,13.8,3.3,18.7"
              />
              <path
                id="outlinepart9"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
                d="M78.2,135.8c.8,1,1.6,2,2.6,2.9,1.1-.7,2.2-1.3,3.3-1.9"
              />
              <path
                id="outlinepart8"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls1"
                d="M91.4,133.6c3.7-1.3,7.6-2.2,11.6-2.7"
              />
              <path
                id="outlinepart7"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
                d="M106.9,130.4c1.3,0,2.5-.1,3.8-.2.5-1.2,1.1-2.3,1.6-3.5"
              />
              <path
                id="outlinepart6"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls1"
                d="M115.6,119.7c8.4-18.2,16.9-34.7,25.7-47.8,2.9-3.3,8.8-7,15.7-10.9"
              />
              <path
                id="outlinepart5"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
                d="M160.3,59.2c1.1-.6,2.2-1.2,3.4-1.8"
              />
            </g>
            <g id="outlines2">
              <path
                id="outlinepart4"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
                d="M80.8,138.7c-1.1.6-2.2,1.2-3.4,1.9"
              />
              <path
                id="outlinepart3"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
                d="M73.2,143.3c-1,.7-2.1,1.5-3.1,2.3"
              />
            </g>
            <g id="outlines1">
              <path
                id="outlinepart2"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
                d="M84.5,149.9c-.2-1.2-.5-2.4-1-3.7"
              />
              <path
                id="outlinepart1"
                class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
                d="M82.6,143.8c-.5-1.1-1.1-2.2-1.8-3.4"
              />
            </g>
          </g>
          <path
            id="Bonepart6"
            class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
            d="M39.3,348.6h0c.6,9.9,9.8,12.8,22.1,12.9,1.9,0,3.6-.9,4.7-2.5,5.3-7.7,12.4-13.1,21.1-16.4,3.5-2,2.5-5.6-.5-9.9-1.4-2.1-3.1-4-5.1-5.7-3.3-2.9-6-7.5-8.3-13.1-1-2.4-2.4-4.6-4.3-6.3-3.6-3.1-8.1-3.9-14.4.8-3.7,4.1-4.3,14.7-4.3,14.7,0,0-1.5,4-2.4,5.8-1.5,3.1-4.1,5.4-7,7.2s-4,4.4-2.7,7.8c.6,1.5,1,3.1,1.1,4.7h0Z"
          />
          <path
            id="Bonepart5"
            class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
            d="M105.8,365.3h0c4.7,11.6,13.5,15,24.6,13.8,12,2.3,17.2-3.1,18.8-12.8l11.7-48c3.2-3.1.7-7.5-5.6-12.8-5.9-2.7-11.3-2.5-16,2.3-9.8-2-18,0-22.9,11.2-7,3.5-10.6,8.1-7.6,15,2.8,5.1,4.7,9.9.6,12.5-1.8,1.1-3,3-3.1,5.1l-.5,13.7h0Z"
          />
          <path
            id="Bonepart4"
            class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
            d="M96.4,341.6h0c6.4,1.9,8.2-2.5,7.6-10,5.5-14.7,14.2-18.2,1-22.3l-9.9-1.7c-5.4-3.2-10.5-1.3-15.3,4.1-6.8,5.7-8.1,11.1-2.8,16.4l3,8.7c1.6,4.6,8.1,5.6,16.6,4.9h0Z"
          />
          <path
            id="Bonepart3"
            class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
            d="M109.2,319.9c-11-8.2-22.1-11.4-33-2.8"
          />
          <path
            id="Bonepart2"
            class="rechtes_daumengrundgelenk_lat_sitzend1310_cls2"
            d="M136,316.2c-2.8.2-5.3-.5-7.6-2.1-.5-.4-1.2-.7-1.8-.7-3.1,0-6.6,2.5-10.3,5.7"
          />
          <path
            id="Bonepart1"
            class="rechtes_daumengrundgelenk_lat_sitzend1310_cls3"
            d="M160.3,320.7c-7,6.3-11.4,19-10.2,41.7"
          />
        </g>
      </g>
      <g id="highlights">
        <ellipse
          id="highlightsparts"
          class="daumengrundgelenk1310fill"
          style={{
            fill: props.colors.daumengrundgelenk1310fill,
            stroke: props.colors.daumengrundgelenk1310fill,
          }}
          cx="40"
          cy="156.8"
          rx="27.1"
          ry="8.8"
        />
      </g>
    </svg>
  );
}

export default RechtesDaumenGrundGelenkLatSitzend1310;
