import style from "./style.scss";

export default function RechtesSchlusselbeinTangStehend2018(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 411.58 290.07">
      <g id="collarbon_recording" data-name="collarbon recording">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls1"
            d="M398.24,284.42c-8.81,9.75-25.46,4.69-62,1,0,0-18.55-1.88-51-3-65.62-2.27-82.58,9.48-123-1-5.12-1.32-9.14-2.62-31.88-8.84-23.28-6.37-34.92-9.55-46.12-12.16-28.14-6.57-42.2-9.85-54.97-8.96-15.39,1.08-25.03.96-25.03.96-6.39-4.63-3.92-12.95-3-56,.56-26.29-.22-29.91,2-41,1.54-7.7,3.39-13.31,3.78-23.02.46-11.54-1.64-16.3-2.78-25.98-3.68-31.32,11.4-40.1,7.66-69.78-.32-2.51-1.09-7.68.34-14.22C15.1,9.29,24.99-.97,28.24.42c3.17,1.36-1.74,20.4-1.74,20.4.79,1.24,9.39,8.3,14.74,10.6,4.33,1.86,5.95.45,11,2,5.2,1.6,12.31,5.8,12,10-.26,3.52-5.67,6.05-9,7-5.72,1.63-8.7-.45-21-2-7.98-1-11.97-1.5-12.69-.02-1.26,2.59,6.28,11.65,15.69,13.02,8.67,1.27,11.71-5.04,19-3,4.69,1.31,10,4,14,13,3.78,8.49,12.55,9.72,13.81,10.49,0,0,7.05,4.3,15.18,7.17,14.95,5.28,29.73,2.62,50.02,2.34,6.15-.09.48.22,48.22,3.41,28.53,1.9,31.51,1.86,38.58,4.24,11.78,3.97,15.27,8.06,31.29,12.26,8.55,2.24,13.55,3.55,20.91,4.08,12.76.92,18.08-1.54,21,2,3.56,4.32,1.12,14.64-5,19-2.12,1.5-5.75,3.07-24.06-.79-16.91-3.56-19.71-6.67-29.94-7.21-12.74-.67-15.14,5.92-24.58,1.6-19.06-8.74-21.14-12.01-43.42-16.6-5.26-1.08-1.26.32-34-1-15.2-.62-25.33-1.3-26.31,1.89-.5,1.62,2.31,2.52,6.41,8.69,8.91,13.39,5.78,9.55,8.91,13.39,4.03,4.96,9.41,7.04,19.99,11.02,11.14,4.18,13.87,3.71,32,9,6.18,1.8,12.31,3.79,18.48,5.62,4.94,1.47,7.27,2.13,9.87,4.3,4.48,3.73,4.96,7.9,7.67,8.17,1.58.16,3.93-1,7.23-7.02,0,0,1.1-11.03,6.76-16.06,5.13-4.56,13.31-3.57,18-3,8.6,1.05,10.6,4.4,17.35,3.78,5.27-.49,8.28-4.24,11.65-4.78,13.98-2.24,27.38-11.62,43.34-15.15,12.12-2.68,18.32-3.96,25.05-1.04,6.5,2.82,13.32,9.16,15.15,17.29,1.78,7.93-2.84,10.19-.54,15.91,2.4,5.97,8.22,5.45,24,15,10.4,6.29,12.58,9.36,13.51,10.84,3.69,5.86,3.76,12.03,3.54,18.09-.7,19.06-4.16,30.03-7.05,47.07-3.98,23.45-1.18,24.77-5,29h-.02Z"
          />
        </g>
        <path
          id="upper_arm"
          data-name="upper arm"
          class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
          d="M336.26,285.42c-1.42-7.15-3.38-12.9-5-17-2.95-7.44-5.73-14.48-12-21-6.38-6.64-8.73-4.8-13-10-7.98-9.73-5.92-23.72-5-30,.72-4.89,2.73-18.54,14-29,6.51-6.04,13.34-8.03,27-12,12.16-3.54,19.8-5.64,27-2,3.63,1.83,3.69,3.38,11,8,6.17,3.9,8.01,3.99,13,7,4.17,2.52,11.27,6.8,15,13,2.61,4.34,3.11,8.83,1,26-1.95,15.86-4.34,28.11-6,37-1.23,6.6-3.03,16.59-5,29"
        />
        <g id="shoulder_plate" data-name="shoulder plate">
          <path
            id="shoulder_plate_1"
            data-name="shoulder plate 1"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M131.26,286.42c-.34-4.03-.77-9.89-1-17-.25-7.51-.37-11.26,0-15,1.11-11.29,4.64-12.81,6-22,.8-5.4-.47-11.6-3-24-2.96-14.5-4.76-15.89-6-27-.22-1.97-.48-6.65-1-16-.52-9.35-.54-11.76,0-15,.92-5.54,1.99-11.93,6-14,5.21-2.69,10.44,4.45,25,11,8.17,3.68,6.31,1.33,32,9,14.45,4.32,21.68,6.48,25,8,9.47,4.34,10.38,12.84,14,11,5.46-2.78,4.08-18.06,11-24,5.81-4.99,15.53-3.4,18-3,6.56,1.07,7.41,3.58,13,4,5.95.44,9.73-2.05,16-5,8.04-3.79,4.81-1.1,27-9,20.82-7.41,20.93-8.81,27-9,3.86-.12,17.32-.55,25,9,5.38,6.69,5.11,14.79,5,17-.56,11.59-8.1,18.61-17,29-4.48,5.23,3.45-3.58-32,46-10.2,14.27-15.27,21.16-24,34-5.13,7.54-9.26,13.81-12,18"
          />
          <path
            id="shoulder_plate_2"
            data-name="shoulder plate 2"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M257.26,212.42c-3.25-3.78-6.86-9.07-9-16-1.52-4.93-1.26-7.31-3-11-1.87-3.99-5.76-8.84-14.55-12.74"
          />
          <path
            id="shoulder_plate_3"
            data-name="shoulder plate 3"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M305.26,180.42c-3.63-4.94-7.36-7.55-10-9-2.41-1.33-5.31-2.24-11-4-8.63-2.68-9.78-2.45-12-4-2.43-1.7-5.42-5.01-6.91-12.2"
          />
          <path
            id="shoulder_plate_4"
            data-name="shoulder plate 4"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M309.63,246.66c-14.88-7.71-31.38-43.65-4.37-60.24"
          />
          <path
            id="shoulder_plate_5"
            data-name="shoulder plate 5"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M370.26,155.42c-1.32-3.83-3.67-8.53-8-10-3.24-1.1-4.62.61-11,1-1.91.12-2.65.02-16-2-6.93-1.05-10.41-1.6-11-3-1.26-3,3.82-7.78,5.34-9.15"
          />
        </g>
        <path
          id="collarbone"
          class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
          d="M304.26,136.42c-6.08,4.31-14.72,1.88-32-3-11.08-3.13-14.29-5.3-22-5-8.1.31-8.87,2.88-16,3-8.63.15-12.53-3.53-25-9-3.47-1.52-14.93-6.49-27-9-9.11-1.89-17.53-1.62-34-1-13.18.5-22.54,1.48-37,3-2.46.26-17.5,1.86-37,5-14.39,2.32-16.9,3.17-19,4-13.41,5.33-20.63,14.31-24,12-1.12-.77-1-2.22-1-7-.03-15.93-1.54-17.88,0-27,1.29-7.68,3.02-10.18,5.14-11.56,2.14-1.38,4.38-1.43,5.86-1.44,8.64-.07,12.96-.1,21,1,8.25,1.13,12.27-.15,22-1,10.65-.93,16.86-.35,65,2,20.35.99,40.63,3.4,61,4,5.97.18,16.91.41,30,5,7.31,2.56,6.9,3.54,16,7,7.84,2.98,18.46,7.01,32,8,13.03.95,18.11-1.69,21,2,3.49,4.45,1.08,14.69-5,19Z"
        />
        <g id="ribs">
          <path
            id="ribs_1"
            data-name="ribs 1"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M162.26,281.42c-21.87-6.21-47.71-13.26-78-21-18.51-4.73-37.46-9.4-63-9-7.08.11-12.95.59-17,1"
          />
          <path
            id="ribs_2"
            data-name="ribs 2"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M195.26,280.42c-21.98-13.07-41.33-21.99-56-28-15.69-6.43-25.13-10.24-39-13-10.09-2.01-12.95-1.56-33-5-13.93-2.39-16.21-3.24-27-5-8.6-1.4-20.13-2.98-34-4"
          />
          <path
            id="ribs_3"
            data-name="ribs 3"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M203.26,279.42c.53-3.41.72-8.49-2-13-3.01-4.99-8.7-5.94-11-8-14.89-13.37-13.56-11.64-28-17-12.24-4.55-18.83-7.3-49-15-43.38-11.08-39.69-11.29-54-14-12.85-2.43-30.89-5.12-53-6"
          />
          <path
            id="ribs_4"
            data-name="ribs 4"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M224.26,278.42c-1.81-6.69-5.58-16.79-14-26-3.3-3.6-8.28-8.13-25-17-16.88-8.95-30.61-14.31-35-16-19.43-7.47-34.51-11.75-53-17-23.18-6.58-29.82-7.48-43-12-3.86-1.32-9.2-3.27-17-5-6.2-1.37-9.1-1.53-15-3-6.57-1.63-11.73-3.62-15-5"
          />
          <path
            id="ribs_5"
            data-name="ribs 5"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M203.83,246.66c1.26-.97,5.39-4.4,6.43-10.24.89-4.99-1.05-8.9-2-11-3.45-7.57-10.34-11.11-14-13-11.4-5.88-22.27-12.82-34-18-22.21-9.81-17.17-10.52-44-23-22.37-10.41-16.81-5.7-40-16-7.22-3.21-12.82-5.91-21-6-8.61-.09-10.59,2.81-17,1-7.66-2.17-9.19-7.55-17-9-4.49-.83-8.44.12-11,1"
          />
          <path
            id="ribs_6"
            data-name="ribs 6"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M194.26,240.42c.91-2.73,3.49-8.45,0-10-5.62-2.5-9.03-6.6-14-8-11.05-3.11-20.57-10.2-31-15-26.07-11.99-21.68-10.49-30-14-14.81-6.25-12.94-4.34-38-14-10.92-4.21-22.63-6.57-33-12-3.2-1.68-7.83-4.32-14-4-4.96.26-7.17,2.25-13,3-5.36.69-9.92-.15-13-1"
          />
          <path
            id="ribs_7"
            data-name="ribs 7"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M132.26,136.42c6.03,2.98,20.36,10.38,32,20,1.36,1.13,6.2,5.2,10,12,2.19,3.91,4.04,7.34,3,11-1.11,3.89-4.86,5.84-9,8-2.79,1.46-7.68,3.53-14.49,4.03"
          />
          <path
            id="ribs_8"
            data-name="ribs 8"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M56.26,206.42c11.39,1.2,80.18-35.38,86-37,4.17-1.16,10.83-3.03,11-6,.15-2.69-5.11-4.32-14-10-5.32-3.4-9.36-6.6-11.9-8.73"
          />
          <path
            id="ribs_9"
            data-name="ribs 9"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M10.26,142.42c-.54-.87-5.46-9-2-15,1.98-3.43,5.97-4.89,9-6,6.83-2.5,11.28-1.16,12-3,.43-1.09-.48-3.25-7-8h0c-5.44.28-7.13.28-7.13.28-2.96,0-4.43-.02-4.87-.28-3.64-2.14,2-15.95,6-33,1.64-7,4.77-20.37,6-36,.63-8.03.4-12.08,3-18,2.07-4.72,4.64-7.65,5-13,.28-4.15-.91-7.65-2-10"
          />
          <path
            id="ribs_10"
            data-name="ribs 10"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M22.26,75.42c-1.45,4.38,1.16,10.51,5,12,3.92,1.51,6.09-2.97,13-4,4.86-.72,10.64,1.84,22,7,4.18,1.9,2.86,1.48,14,7,12.66,6.28,13.97,6.63,16,9,4.2,4.92,7.14,13.45,4,19-2.71,4.79-7.49,2.99-14,9-3.78,3.49-9.02,10.42-7,15,1.71,3.87,7.88,4.4,15,5,6.67.56,17.63,1.49,29-5,5.45-3.11,11.6-6.63,13-13,1.49-6.8-3.27-12.98-10.31-22.11-5.69-7.38-11.29-12.17-15.69-15.89-7.34-6.21-8.16-5.63-19-14-11.54-8.92-11.5-10.26-17-13-1.93-.96-9.91-4.76-21-5-2.2-.05-23.86-.51-27,9Z"
          />
          <path
            id="ribs_11"
            data-name="ribs 11"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M130.44,147.18c-12.45-4.8-21.86-9.94-28.18-13.76-10.92-6.6-12.81-9.4-22.59-13.85-8.23-3.74-15.78-5.8-24.41-8.15-13.18-3.59-12.66-2.21-20-5-6.98-2.65-10.33-4.99-15-4-4.72,1-7.96,4.81-10,8"
          />
          <path
            id="ribs_12"
            data-name="ribs 12"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M14.62,83.98c9.85-4.96,17.99-6.28,23.64-6.56,7.47-.36,12.56,1,20-2,1.38-.56,10.75-4.34,10.5-9.07-.21-3.93-6.99-7.15-12.5-7.93-8.72-1.23-11.49,4.06-19,3-9.61-1.37-16.85-11.67-15.56-14.07.53-.99,3.53-.05,15.56,2.07,10.91,1.92,14.17,2.17,18,1,.57-.17,8.86-2.78,9-7,.13-3.83-6.55-6.88-9-8-6.41-2.93-8.7-1.17-14-4-5.07-2.71-5.01-5.42-9-8-3.5-2.27-9.44-4-20-1"
          />
          <path
            id="ribs_13"
            data-name="ribs 13"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M10.26,158.26c-1.11,0,20.04-3.96-4-7.04"
          />
          <path
            id="ribs_14"
            data-name="ribs 14"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M6.26,201.91s23.31-10.46,0-15.49"
          />
          <path
            id="ribs_15"
            data-name="ribs 15"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M4.26,242.92s25.31-2.5,0-10.5"
          />
          <path
            id="ribs_16"
            data-name="ribs 16"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M68.77,223.97c9.81-2.85,81.91-30.75,91.72-33.6"
          />
          <path
            id="ribs_17"
            data-name="ribs 17"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M175.26,279.42c4.52-3.92,24.05-28.84,28.57-32.76"
          />
          <path
            id="ribs_18"
            data-name="ribs 18"
            class="Rechtes_Schlüsselbein_tang_stehend2018_cls3"
            d="M160.49,278.42c4.4-4.99,30.68-36.92,35.08-41.91"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="collarbone_overlay"
          data-name="collarbone overlay"
          class="clavicula2018Fill"
          style={{
            fill: props.colors.clavicula2018Fill,
            stroke: props.colors.clavicula2018Fill,
          }}
          d="M304.26,136.42c-6.08,4.31-14.72,1.88-32-3-11.08-3.13-14.29-5.3-22-5-8.1.31-8.87,2.88-16,3-8.63.15-12.53-3.53-25-9-3.47-1.52-14.93-6.49-27-9-9.11-1.89-17.53-1.62-34-1-13.18.5-22.54,1.48-37,3-2.46.26-17.5,1.86-37,5-14.39,2.32-16.9,3.17-19,4-13.41,5.33-20.63,14.31-24,12-1.12-.77-1-2.22-1-7-.03-15.93-1.54-17.88,0-27,1.29-7.68,3.02-10.18,5.14-11.56,2.14-1.38,4.38-1.43,5.86-1.44,8.64-.07,12.96-.1,21,1,8.25,1.13,12.27-.15,22-1,10.65-.93,16.86-.35,65,2,20.35.99,40.63,3.4,61,4,5.97.18,16.91.41,30,5,7.31,2.56,6.9,3.54,16,7,7.84,2.98,18.46,7.01,32,8,13.03.95,18.11-1.69,21,2,3.49,4.45,1.08,14.69-5,19Z"
        />
      </g>
    </svg>
  );
}
