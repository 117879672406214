import style from "./style.scss";

function Brustwirbelkorper2LatLiegend1510(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257.51 204.62">

<g id="BG">
  <path id="BG_white" data-name="BG white" class="BWSkorper2_lat1510_cls7" d="M.48,59.46c21.49,28.29,7.67,37.61,22.58,39.33,9.53-.97,19.31-6.27,29.19-13.52,24.51-13.12,21.31-5.16,27.97-11.03-.13-.09,1.5-2.37.39-7.96-1.67-8.36,12.11-17.82,18.82-3.45l3.86-1.62c.18-.08.36.09.3.28-.81,2.48-4.61,14.17-6.13,21.1-4.96-1.53-9.6-2.5-13.17-1.65-20.05,11.3-26.82,12.18-41.71,17.86-9.63,2.98-14.95,7.21-15.31,12.9-1.26,11.6,8.13,27.58,14.04,35.1,2.07,2.64,5.54,1.86,8.01,1.07,2.89-.93,19.31-9.47,19.47-9.55l16.56-6.75c18.26-6.4,34.85-11.65,36.1-18,.93-6.99-9.57-9.6-.47-29.11.7,5.85,3.92,8.75,9.53,8.86,14.83.16,3.74,5.75,6.97,12.63.13.3.02.65-.3.69-4.24.55-7.52,3.75-7.72,8.38,1.17,9.68,5.87,2.89,6.88,15.15-9.05,25.53-10.23-1.16-13.88.85-4.87-.97-10.35,7.5-36,11-18.42,3.96-32.91,9.1-35.41,17.85-1.17,6.89,2.4,13.96,8.77,21.16,14.33,9.28,4.55,19.97,7.64,21.99,3.98,2.22,9.09,1.53,14.91-.95l35.99-9.63c10.01-2.4,25.52-2.4,26.1-6.43,3-8.24-1.18-6.8-.14-14.71,1.57-4.87,8.88-7.61,14.66-3.6,2.86,6.62,5.7,12.58,8.48,16.31,6.24,6.69,12.56,10.17,19,9,4.74-10.37,16.21-21.43,16-25,.08-2.69-6.73-4.82-17.06-8.54-13.48-7.88-19.1-13.87-18.94-18.46-.55-1.19,11.71-.41,22.57-3.85,2.51,2.79,18.94-2.62,25.43.41,6.49,2.05,6.88,8.31,14,11,5.8,1.23,15.12-.94,23.21,3.7,3.98,2.28,5.84,1.11,5.3-.6-8.89-28.15-1.12-26.34-7.71-30.72-13.96,6.08-25.49-3.34-35.78-8.15-16.01-3.9-25.3-14.82-29.82-17.69-4.58-4.13-13.45-2-29.45-6.09-11.83-1.41-19.8-8.36-23.39-21.61,4.58,1.39,11.31.13,11.31.13,2.5,6.78,5.83,8.75,9.78,7.25,11.13-4.63,11.52-4.89,30.55.76,13.16,3.49,24,3.68,31.02-1.55,12.82-8.02,5.91-20.93-8.06-24.12l-32.54,2.35c-3.63-.62-6.83-3-9.69-6.69-6.15-9.57-19.97-7.89-21.06-1.22-4.42-4.71-10.89-10.02-13.38-10.27-9.25-1.81-10.18.34-15.36,6.41.06-5.31-11.74-3.69-12.93-5.91,5.26-8.61,10.34-2.94,9.94-20.53-5.34-14.71-13.69-18.29-25.94-7.47-6.13,8.43-4.37,26.98-12.39,25.35h-15.56s-16.47,4.01-16.47,4.01l-12.89,6.94L2.88,53.91C1.49,54.71,0,56.78.48,59.46Z"/>
</g>
<g id="fullBWK2_lat" data-name="fullBWK2 lat">
  <path id="BWKBonepart7" class="BWSkorper2_lat1510_cls3" d="M18.46,97.59c-5.39-3.11-2.5-9.62-8-22C5.24,63.84-.98,61.56.46,56.59c1.15-3.99,5.6-4.02,19-10,15.84-7.08,15.22-9.54,25-12,8.7-2.19,13.34-3.35,20-3,4.89.26,8.23,1.2,11-1,2.2-1.74,1.99-3.84,4-11,1.96-6.99,2.94-10.48,5-13,2.93-3.58,8.87-7.54,15-6,5.36,1.35,8,6.18,9,8,.23.42,4.55,8.59,1,16-3,6.27-9.64,7.84-9,10,.75,2.55,9.95.16,12,4,1.19,2.23-1.3,4.18-4,12-2.55,7.37-5.72,11.45-7.8,12.24-3.37,1.27-2.42-7.02-9.2-7.24-3.7-.12-7.91,1.81-10,5-3.34,5.09,1.04,9.86-2,14-2.32,3.16-5.61,1.38-14,4-4.79,1.5-8.86,4-17,9-8.44,5.19-8.59,6.21-13,8-4.93,2-12.02,4.88-17,2Z"/>
  <path id="BWKBonepart6" class="BWSkorper2_lat1510_cls1" d="M42.46,147.59c-2.52-1.29-4.68-5.19-9-13-3.01-5.43-5.36-9.79-6-16-.37-3.59-.75-7.36,1-11,2.04-4.24,8.06-6.55,20-11,24.55-9.14,24.55-9.04,26-10,4.53-3,7.78-6.13,13-6,3.86.09,10,2,10,2,1.31-6.02,2.76-11.09,4-15,.98-3.1,3.72-11.36,8.9-21.31,4.65-8.93,6.85-10.71,9.1-11.69,4.2-1.82,8.55-.82,11,0,0,0,5.99,3.81,10,8,3.19,3.32,7.02,7.32,7,13-.02,5.79-3.88,9.87-4.55,10.45-1.17,1.01-2.45,1.55-2.45,1.55,0,0-.22.09-.44.17-1.95.7-8.86-.79-11.56-.17-.87.2-2.82.97-7,14-3.11,9.7-4.67,14.55-4,19,1.09,7.26,5.02,8.75,4,13-1.14,4.75-7.02,6.98-15,10-18.7,7.09-28.05,10.63-29,11-21.95,8.55-27.94,16.61-35,13Z"/>
  <circle id="BWKBonepart5" class="BWSkorper2_lat1510_cls4" cx="129.61" cy="54.21" r="8.62"/>
  <path id="BWKBonepart4" class="BWSkorper2_lat1510_cls3" d="M162.46,71.59c6.15-1.02,7.16,1.31,20,4,11.69,2.45,18.65,3.91,26,1,1.19-.47,11.51-4.55,12-12,.41-6.24-6.28-11.9-12-14-3.47-1.27-5.18-.72-23,1-10.83,1.05-12.57,1.07-15,0-6.75-2.98-7.75-8.25-12-11-5.58-3.61-15.03-1.34-16.2,2.04-1.09,3.13,5.33,5.98,5.2,12.96-.06,3.4-1.4,5.96-1.4,5.96-1.34,2.56-2.67,2.76-3.16,4.5-.79,2.82,1.21,7.58,4.55,8.55,5.37,1.56,8.32-1.89,15-3h0Z"/>
  <path id="BWKBonepart3" class="BWSkorper2_lat1510_cls1" d="M256.46,153.59c1.8-1.56-1.25-5.61-3-15-1.97-10.53.13-14.72-3-17-3.2-2.33-6.25,1.43-14,1-5.68-.32-7-2.5-19-8-11.33-5.19-10.79-3.53-17-7-10.6-5.92-9.64-9.34-18-13-5.74-2.52-6.3-.95-21-4-10.48-2.17-15.72-3.26-19.01-5.62-9.35-6.73-10.03-17.85-13.99-17.38-2.11.25-3.31,3.58-4.6,7.18-1.13,3.14-4.13,11.5-.4,15.82,4.11,4.76,13.42,1.16,15,5,.81,1.98-1.58,3.12-1.45,7.19.08,2.69,1.29,4.72,3.7,8.66,2.81,4.6,4.22,6.91,5.75,7.15,4.15.66,5.11-8.75,13-13,10.11-5.45,24.11,1.62,28.23,4.85.83.65,3.01,2.52,5.3,5.11,1.42,1.61,2.31,2.62,3.17,4.28.61,1.18,1.66,3.24,1.69,6.05,0,.29,0,1.94-.47,3.45-1.67,5.26-8.26,6.81-8.35,7.86-.31,3.31,17.86-2.01,25.43.41,7.01,2.24,6.25,8.19,14,11,5.15,1.87,6.16-.53,14,1,8.74,1.7,12.18,5.58,14,4h0Z"/>
  <path id="BWKBonepart2" class="BWSkorper2_lat1510_cls3" d="M67.46,203.04c-2.29-2.78,2-6.47,0-13-1.7-5.55-5.6-5.52-11-13-3.24-4.5-6.88-9.54-6-15,1.14-7.04,9.21-11.09,15-14,4.09-2.05,9.78-3.39,21-6,18.72-4.35,19.19-3.47,24-6,5.82-3.06,8.88-6.32,12-5,4.17,1.77,3.04,9.41,6,10,2.81.56,7.63-5.6,8-12,.38-6.56-7.39-8.22-7-14,.27-4.04,3.96-8.16,7-8,4.72.24,5.56,10.71,10,11,4.23.27,4.93-9.13,13-13,5.33-2.56,10.82-1.24,16,0,4.44,1.06,14.1,3.38,19,12,.88,1.55,4.77,8.4,2,14-2.26,4.56-7.76,5.94-16,8-8.91,2.23-13.09.04-14,2-1.56,3.36,8.2,13.3,20,19,9.13,4.41,15.15,4.19,16,8,.71,3.18-3.19,4.66-9,14-6.22,8.26-5.66,10.51-7,11-5.4,2.32-14.02-3.04-19-9-6.95-8.32-5.18-15.83-11-18-3.65-1.36-8.65,0-11,3-3.96,5.06,2.19,11.5-1,17-2.69,4.64-8.94,3.3-24,6-4.01.72-11.67,2.81-27,7-17.14,4.68-23.57,6.96-26,4Z"/>
  <ellipse id="BWKBonepart1" class="BWSkorper2_lat1510_cls5" cx="172.89" cy="121.65" rx="15.29" ry="9.94"/>
</g>
<g id="WirbelkörperHighlights">
  <path id="WirbelkörperHighlight3" class="Wirbelkorper_Dornfortsatze1510Fill" style= {{
            fill: props.colors.Wirbelkorper_Dornfortsatze1510Fill,
            stroke: props.colors.Wirbelkorper_Dornfortsatze1510Fill,
          }} d="M42.52,147.59c-2.52-1.29-4.68-5.19-9-13-3.01-5.43-5.36-9.79-6-16-.37-3.59-.75-7.36,1-11,2.04-4.24,8.06-6.55,20-11,24.55-9.14,24.55-9.04,26-10,4.53-3,7.78-6.13,13-6,3.86.09,10,2,10,2,1.31-6.02,2.76-11.09,4-15,.98-3.1,3.72-11.36,8.9-21.31,4.65-8.93,6.85-10.71,9.1-11.69,4.2-1.82,8.55-.82,11,0,0,0,5.99,3.81,10,8,3.19,3.32,7.02,7.32,7,13-.02,5.79-3.88,9.87-4.55,10.45-1.17,1.01-2.45,1.55-2.45,1.55,0,0-.22.09-.44.17-1.95.7-8.86-.79-11.56-.17-.87.2-2.82.97-7,14-3.11,9.7-4.67,14.55-4,19,1.09,7.26,5.02,8.75,4,13-1.14,4.75-7.02,6.98-15,10-18.7,7.09-28.05,10.63-29,11-21.95,8.55-27.94,16.61-35,13Z"/>
  <circle id="WirbelkörperHighlight2" class="BWSkorper2_lat1510_cls6" cx="129.67" cy="54.21" r="8.62"/>
  <path id="WirbelkörperHighlight1" class="Wirbelkorper_Dornfortsatze1510Fill" style= {{
            fill: props.colors.Wirbelkorper_Dornfortsatze1510Fill,
            stroke: props.colors.Wirbelkorper_Dornfortsatze1510Fill,
          }} d="M256.52,153.59c1.8-1.56-1.25-5.61-3-15-1.97-10.53.13-14.72-3-17-3.2-2.33-6.25,1.43-14,1-5.68-.32-7-2.5-19-8-11.33-5.19-10.79-3.53-17-7-10.6-5.92-9.64-9.34-18-13-5.74-2.52-6.3-.95-21-4-10.48-2.17-15.72-3.26-19.01-5.62-9.35-6.73-10.03-17.85-13.99-17.38-2.11.25-3.31,3.58-4.6,7.18-1.13,3.14-4.13,11.5-.4,15.82,4.11,4.76,13.42,1.16,15,5,.81,1.98-1.58,3.12-1.45,7.19.08,2.69,1.29,4.72,3.7,8.66,2.81,4.6,4.22,6.91,5.75,7.15,4.15.66,5.11-8.75,13-13,10.11-5.45,24.11,1.62,28.23,4.85.83.65,3.01,2.52,5.3,5.11,1.42,1.61,2.31,2.62,3.17,4.28.61,1.18,1.66,3.24,1.69,6.05,0,.29,0,1.94-.47,3.45-1.67,5.26-8.26,6.81-8.35,7.86-.31,3.31,17.86-2.01,25.43.41,7.01,2.24,6.25,8.19,14,11,5.15,1.87,6.16-.53,14,1,8.74,1.7,12.18,5.58,14,4h0Z"/>
</g>
<g id="angrenzende_BWK2_Highlights" data-name="angrenzende BWK2 Highlights">
  <path id="angrenzende_BWK2_Highlight4" data-name="angrenzende BWK2 Highlight4" class="BWK1510Fill" style= {{
            fill: props.colors.BWK1510Fill,
            stroke: props.colors.BWK1510Fill,
          }} d="M18.46,97.63c-5.39-3.11-2.5-9.62-8-22C5.24,63.88-.98,61.6.46,56.63c1.15-3.99,5.6-4.02,19-10,15.84-7.08,15.22-9.54,25-12,8.7-2.19,13.34-3.35,20-3,4.89.26,8.23,1.2,11-1,2.2-1.74,1.99-3.84,4-11,1.96-6.99,2.94-10.48,5-13,2.93-3.58,8.87-7.54,15-6,5.36,1.35,8,6.18,9,8,.23.42,4.55,8.59,1,16-3,6.27-9.64,7.84-9,10,.75,2.55,9.95.16,12,4,1.19,2.23-1.3,4.18-4,12-2.55,7.37-5.72,11.45-7.8,12.24-3.37,1.27-2.42-7.02-9.2-7.24-3.7-.12-7.91,1.81-10,5-3.34,5.09,1.04,9.86-2,14-2.32,3.16-5.61,1.38-14,4-4.79,1.5-8.86,4-17,9-8.44,5.19-8.59,6.21-13,8-4.93,2-12.02,4.88-17,2Z"/>
  <path id="angrenzende_BWK2_Highlight3" data-name="angrenzende BWK2 Highlight3" class="BWK1510Fill" style= {{
            fill: props.colors.BWK1510Fill,
            stroke: props.colors.BWK1510Fill,
          }} d="M162.46,71.63c6.15-1.02,7.16,1.31,20,4,11.69,2.45,18.65,3.91,26,1,1.19-.47,11.51-4.55,12-12,.41-6.24-6.28-11.9-12-14-3.47-1.27-5.18-.72-23,1-10.83,1.05-12.57,1.07-15,0-6.75-2.98-7.75-8.25-12-11-5.58-3.61-15.03-1.34-16.2,2.04-1.09,3.13,5.33,5.98,5.2,12.96-.06,3.4-1.4,5.96-1.4,5.96-1.34,2.56-2.67,2.76-3.16,4.5-.79,2.82,1.21,7.58,4.55,8.55,5.37,1.56,8.32-1.89,15-3h0Z"/>
  <path id="angrenzende_BWK2_Highlight2" data-name="angrenzende BWK2 Highlight2" class="BWK1510Fill" style= {{
            fill: props.colors.BWK1510Fill,
            stroke: props.colors.BWK1510Fill,
          }} d="M67.46,203.08c-2.29-2.78,2-6.47,0-13-1.7-5.55-5.6-5.52-11-13-3.24-4.5-6.88-9.54-6-15,1.14-7.04,9.21-11.09,15-14,4.09-2.05,9.78-3.39,21-6,18.72-4.35,19.19-3.47,24-6,5.82-3.06,8.88-6.32,12-5,4.17,1.77,3.04,9.41,6,10,2.81.56,7.63-5.6,8-12,.38-6.56-7.39-8.22-7-14,.27-4.04,3.96-8.16,7-8,4.72.24,5.56,10.71,10,11,4.23.27,4.93-9.13,13-13,5.33-2.56,10.82-1.24,16,0,4.44,1.06,14.1,3.38,19,12,.88,1.55,4.77,8.4,2,14-2.26,4.56-7.76,5.94-16,8-8.91,2.23-13.09.04-14,2-1.56,3.36,8.2,13.3,20,19,9.13,4.41,15.15,4.19,16,8,.71,3.18-3.19,4.66-9,14-6.22,8.26-5.66,10.51-7,11-5.4,2.32-14.02-3.04-19-9-6.95-8.32-5.18-15.83-11-18-3.65-1.36-8.65,0-11,3-3.96,5.06,2.19,11.5-1,17-2.69,4.64-8.94,3.3-24,6-4.01.72-11.67,2.81-27,7-17.14,4.68-23.57,6.96-26,4Z"/>
  <ellipse id="angrenzende_BWK2_Highlight1" data-name="angrenzende BWK2 Highlight1" class="BWSkorper2_lat1510_cls2" cx="172.89" cy="121.69" rx="15.29" ry="9.94"/>
</g>
<g id="Crund_u._Deckplatten_Highlights" data-name="Crund u. Deckplatten Highlights">
  <path id="Crund_u._Deckplatten_Highlight4" data-name="Crund u. Deckplatten Highlight4" class="Grund_Deckplatten1510Fill" style= {{
            fill: props.colors.Grund_Deckplatten1510Fill,
            stroke: props.colors.Grund_Deckplatten1510Fill,
          }} d="M116.77,113.7c-2.95,4.72-10.11,7.52-15.07,9-6.54,1.95-14.44,3.98-20.74,6.65-11.98,5.08-22.24,14.55-35.8,14.48-6.44-.03-7.89,6.68-1.44,6.71,16.03.08,28.03-10.17,42.3-15.68,7.28-2.82,14.95-4.48,22.32-7.01,6.17-2.12,12.19-4.51,15.79-10.26,3.42-5.47-3.95-9.34-7.36-3.89Z"/>
  <path id="Crund_u._Deckplatten_Highlights3" data-name="Crund u. Deckplatten Highlights3" class="Grund_Deckplatten1510Fill" style= {{
            fill: props.colors.Grund_Deckplatten1510Fill,
            stroke: props.colors.Grund_Deckplatten1510Fill,
          }} d="M118.23,129.76c-4.96,4.46-12.39,5.81-18.71,7.28-6.52,1.51-13.11,2.7-19.58,4.41-11.36,3.01-25.52,8.15-30.92,19.54-2.74,5.79,2.77,9.01,5.53,3.19,5.37-11.34,21.71-15.77,32.75-18.2,11.68-2.56,25.17-1.68,34.4-9.98,4.8-4.31,1.3-10.54-3.48-6.25Z"/>
  <path id="Crund_u._Deckplatten_Highlight2" data-name="Crund u. Deckplatten Highlight2" class="Grund_Deckplatten1510Fill" style= {{
            fill: props.colors.Grund_Deckplatten1510Fill,
            stroke: props.colors.Grund_Deckplatten1510Fill,
          }} d="M100.38,58.48c-1.82-1.54-3.46-5.7-6.37-6.02-3.71-.4-7.04-.03-10.24,1.9-3.14,1.9-5.7,4.88-7.03,8.29-1.4,3.58.45,9.86-3.6,11.21-4.14,1.38-8.5.83-12.72,2.27-4.55,1.55-9.15,5.82-12.91,8.63-8.11,6.05-17.14,9.75-27.2,9.46-6.44-.19-6.24,6.01.2,6.2,8.95.26,15.23-2.53,23.28-6.46,4.17-2.03,5.94-3.74,9.52-6.65,4.35-3.54,11.25-5.95,16.7-6.65,3.9-.5,8.38.6,11.15-2.44,2.78-3.04,2.65-8.73,3.33-12.56.58-3.29,1.75-6.49,5.61-6.26,3.39.2,5.22,6.23,9.46,5.02,5.29-1.51,5.17-4.97.83-5.92Z"/>
  <path id="Crund_u._Deckplatten_Highlight1" data-name="Crund u. Deckplatten Highlight1" class="Grund_Deckplatten1510Fill" style= {{
            fill: props.colors.Grund_Deckplatten1510Fill,
            stroke: props.colors.Grund_Deckplatten1510Fill,
          }} d="M98,81.5c-6.97-3.64-12.24-3.84-18.82-.13-6.53,3.69-14.23,7.42-21.43,9.42-12.69,3.52-27.58,7.28-32.93,20.96-2.35,6,3.55,7.11,5.87,1.17,1.98-5.06,7.41-8.83,12.07-10.58,5.27-1.98,11.49-3.69,16.9-5.21,5.01-1.41,18.73-8.82,14.27-6.12-.5.31,12.63-8.57,18.39-5.75,5.78,2.82,11.39-.78,5.68-3.77Z"/>
</g>
</svg>
  );
}

export default Brustwirbelkorper2LatLiegend1510;
