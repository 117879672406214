import { Button, Modal } from "react-bootstrap";

function ConfirmActionModal({
  shown = false,
  onHide,
  variant = "dark",
  yesHandler,
  noHandler,
  actionText,
}) {
  return (
    <Modal show={shown} onHide={onHide} size="sm">
      <Modal.Header
        closeButton
        className={`p-2 align-items-center ${
          variant === "dark" ? "bg-dark text-white" : ""
        }`}
      >
        <h5 className="m-0 p-0">Confirm</h5>
      </Modal.Header>
      <Modal.Body
        className={`text-center align-items-center ${
          variant === "dark" ? "bg-dark text-white" : ""
        }`}
      >
        <h5>{`${actionText ? actionText : "Are you sure?"}`}</h5>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ gap: "20px" }}
        >
          <Button onClick={noHandler} variant="danger">
            <i className="fas fa-times mr-2" />
            No
          </Button>
          <Button onClick={yesHandler} variant="success">
            <i className="fas fa-check mr-2" />
            Yes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmActionModal;
