import Controller from "../Common/Controller";

import FrontView from "./views/FrontView";
import SideView from "./views/SideView";

import "./styles/Controller.scss";

function LegRightController() {
  const availableViews = [FrontView, SideView]; //back view doesnt add any usability for now disabled
  return <Controller availableViews={availableViews} />;
}

export default LegRightController;
