import style from "./style.scss";

function Linker4FingerDpSitzend1323(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190.51 474.24">
      <g id="_4th_finger_left" data-name="4th finger left">
        <g id="Background_layer" data-name="Background layer">
          <path
            id="Background_layer_1"
            data-name="Background layer 1"
            class="Linker_Finger4_dp_sitzend1323_cls-7"
            d="M.86,309.93c9.74,23.55,19.64,41.39,30.08,38.58,12.31.08,17.38-8.76,14.28-27.65-6.62-49.65-10.34-96.84-9.72-140.35,2.11-59.34,3.75-122.87,9.72-147.6C50.58,12.49,62.42,1.21,82.1.97c17.74-2.21,29.61,3.02,31.77,23.7l3.34,98.13c.97,30.07,4.14,59.94,10.03,89.58,4.51,28.02,7.64,57.77,5.47,94.22,1.12,21.85,5.74,35.11,16.71,32.81,11.08-1.27,18.61-36.94,23.7-96.3l17.39,230.66-187.52-5.92L.86,309.93Z"
          />
        </g>
        <g id="middle_hand_bone" data-name="middle hand bone">
          <path
            id="middle_hand_bone_1"
            data-name="middle hand bone 1"
            class="Linker_Finger4_dp_sitzend1323_cls-9"
            d="M73.16,470.56c-.03-7.03,1.49-12.33,3.34-23.62,4.17-17.29,12.75-25.63,24.61-27.33,5.58-.97,11.05-1.61,16.13-1.01,8,.94,14.81,6.34,17.63,13.89,4.95,13.23,6.61,24.25,6.34,39.71"
          />
          <path
            id="middle_hand_bone_2"
            data-name="middle hand bone 2"
            class="cls-8"
            d="M88.43,436.04c2.28,9.88.85,16.33-3.53,20.05-3.92,4.47-6.79,9.15-6.27,14.47"
          />
          <path
            id="middle_hand_bone_3"
            data-name="middle hand bone 3"
            class="cls-8"
            d="M140.24,455.11c5.56,2.18,10.14,6.77,11.76,18.64"
          />
        </g>
        <g id="fingerbone_1" data-name="fingerbone 1">
          <path
            id="finger_bone_1.1"
            data-name="finger bone 1.1"
            class="Linker_Finger4_dp_sitzend1323_cls-9"
            d="M84.4,414.13c-9.32,8.06-15.26,6.53-19.44,0-9.22-7.09-6.99-16.44,0-26.73,8.85-22.57,6.52-57.84.17-97.67-.11-.71-.14-1.42-.09-2.14.64-9.78-.46-20.02-4.03-30.92-.47-1.44-1.36-2.69-2.51-3.68-2.32-1.98-3.23-5.41-2.69-10.32.18-1.61.2-3.23,0-4.83-1.93-16.06,1.24-20.67,6.1-21.49l11.11,1.07c2.94.28,5.91-.15,8.64-1.26,8.62-3.51,16.46-5.14,22.5-2.48,1.35.59,2.43,1.68,3.07,3,2.19,4.51,3.05,9.05,2.54,13.61-.19,1.7.29,3.43,1.34,4.78,2.31,2.94,3.14,6.06.91,9.53-2.34,6.12-2.35,12.89-.47,20.18.29,1.13.5,2.28.57,3.44.38,6.77.86,15.31,1.42,25.29,1.86,41.07,8.78,73.94,24.61,92.35,4.24,8.11,3.86,15.22,0,21.57.14,8.25-3.27,8.8-7.9,6.68-16.99-5.67-32.52-6.48-45.87,0l.02.02Z"
          />
          <path
            id="fingerbone_1.2"
            data-name="fingerbone 1.2"
            class="cls-8"
            d="M80.45,417.11c1.44.49,3.16.9,5.16,1.23,8.29,1.36,16.83.54,24.72-2.35,4.84-1.77,10.02-2.93,15.68-3.18"
          />
          <path
            id="fingerbone_1.3"
            data-name="fingerbone 1.3"
            class="cls-8"
            d="M66.78,388.99c4.47-4,8.11-13.48,10.48-31.22l4.1-45.23c-2.04-14.05-2.69-26.48.16-34.82.36-1.05.55-2.15.57-3.26.19-14.02-2.32-22.43-7.67-24.91"
          />
          <path
            id="fingerbone_1.4"
            data-name="fingerbone 1.4"
            class="cls-8"
            d="M124.88,368.49c-8.24-13.17-14.58-25.77-15.61-36.8-1.23-7.35-3.43-14.27-6.41-20.82-.35-.77-.55-1.61-.58-2.46-.39-11.79-1.7-21.63-4.78-27.69-1.4-2.75-2.04-5.83-2.04-8.91,0-8.32-.8-13.94-2.25-17.36"
          />
          <path
            id="fingerbone_1.5"
            data-name="fingerbone 1.5"
            class="cls-8"
            d="M56.53,239.3c3.19-.19,4.71-5.47,4.56-15.84"
          />
          <path
            id="fingerbone_1.6"
            data-name="fingerbone 1.6"
            class="cls-8"
            d="M109.76,231.38c-3.14.14-5.28-3.76-6.75-10.42"
          />
        </g>
        <g id="fingerbone_2" data-name="fingerbone 2">
          <path
            id="fingerbone_2.1"
            data-name="fingerbone 2.1"
            class="Linker_Finger4_dp_sitzend1323_cls-9"
            d="M49.91,194.76c4.99-9.27,7.29-20.94,7.91-34.12.14-2.95.57-5.88,1.23-8.77,3.73-16.24,2.79-28.17-3.09-35.54-1.66-2.08-2.3-4.81-1.98-7.45.43-3.49.87-6.23,1.84-8.02.57-1.05.97-2.18,1.07-3.38.69-7.57,3.14-10.09,13.22-8.12,3.97,1.29,8.1.57,12.61-.57,8.13-4.01,13.39-1,12.75,6.67-.16,1.88.36,3.74,1.54,5.22,3.79,4.77,4.01,9.12,1.05,15.28-.97,2.02-1.34,4.29-1,6.5.16,19.69,3.42,40.48,9.04,62.11.56,2.15,1.55,4.19,3.06,5.81,3.29,3.56,2.88,8.27-.26,13.84-.88,1.56-2.59,2.45-4.38,2.33-6.63-.46-15.33,1.98-24.54,5.13-1.26.43-2.62.43-3.88,0-7.54-2.49-14.58-3.87-20.84-3.55-4.12-.94-5.63-4.43-6.17-8.87-.19-1.56.09-3.15.84-4.53l-.02.03Z"
          />
          <path
            id="fingerbone_2.2"
            data-name="fingerbone 2.2"
            class="cls-8"
            d="M57.21,202.96c5.12-1.24,10.44-.52,15.93,1.99,1.54.71,3.21,1.14,4.9,1.2,2.35.09,4.57-.46,6.63-1.88,4.16-2.47,10.42-3.32,17.43-3.59"
          />
          <path
            id="fingerbone_2.3"
            data-name="fingerbone 2.3"
            class="cls-8"
            d="M53.28,201.7c.93-5.21,2.39-9.82,4.62-13.56,4.42-9.85,8.29-21.08,11.62-33.61.55-6.38,2.67-13.44,5.58-20.85,1.59-11.27.42-19.9-3.87-25.52"
          />
          <path
            id="fingerbone_2.4"
            data-name="fingerbone 2.4"
            class="cls-8"
            d="M87.9,104.64c-.89,1.96-1.48,4.06-1.86,6.27.97,7.9,1.4,15.55,1.13,22.89-.11,2.9.09,5.79.58,8.65,3.12,18.09,8.44,35.47,16.85,51.86"
          />
        </g>
        <g id="fingerbone_3" data-name="fingerbone 3">
          <path
            id="fingerbone_3.1"
            data-name="fingerbone 3.1"
            class="Linker_Finger4_dp_sitzend1323_cls-9"
            d="M56.76,82.99c-3.21-1.81-4.1-4.27-3.08-7.29,7.66-8.41,12.62-17.66,12.59-29.72,0-1.04-.2-2.08-.7-3-1.22-2.28-1.28-6.01-.95-10.23-.48-5.59,3.23-10.69,9.46-15.49,6.16-4.43,16.52-2.34,18.34.46,3.77,9.38,3.82,17.38,0,23.92-1.54-.69-5.44,18.84,4.22,27.46,3.26,2.86,3.84,5.71.46,8.54-.52,4.87-4.4,6.24-9.91,5.92-5.07,2.47-10.2,3.04-15.38,1.71-9.57-1.94-11.91,2.5-15.04-2.28h-.01Z"
          />
          <path
            id="fingerbone_3.2"
            data-name="fingerbone 3.2"
            class="cls-8"
            d="M58.92,76.96c-.37-2.31,1.41-4.91,4.9-7.75,4.37-5.88,7.81-12.14,9.14-19.23.35-1.88.52-3.79.55-5.7.12-6.86,1.44-11.26,4.89-11.3,3.99-.58,5.82,2.32,5.3,9.02-.3,3.9-.4,7.83-.15,11.74.62,9.98,2.94,15.02,7.15,14.56"
          />
        </g>
        <g id="outline">
          <path
            id="outline_1"
            data-name="outline 1"
            class="cls-8"
            d="M.86,309.93c.78,1.88,1.56,3.73,2.34,5.53"
          />
          <path
            id="outline_2"
            data-name="outline 2"
            class="Linker_Finger4_dp_sitzend1323_cls-10"
            d="M8.17,326.24c7.39,14.85,14.92,24.38,22.76,22.27,12.31.08,17.38-8.76,14.28-27.65-6.62-49.65-10.34-96.84-9.72-140.35,2.11-59.34,3.75-122.87,9.72-147.6C50.58,12.49,62.42,1.21,82.1.97c17.74-2.21,29.61,3.02,31.77,23.7l3.34,98.13c.97,30.07,4.14,59.94,10.03,89.58,4.51,28.02,7.64,57.77,5.47,94.22,1.12,21.85,5.74,35.11,16.71,32.81,10.32-1.18,17.56-32.21,22.61-84.42"
          />
          <path
            id="outline_3"
            data-name="outline 3"
            class="cls-8"
            d="M172.58,249.07c.18-1.96.35-3.96.53-5.98"
          />
        </g>
        <g id="next_right_finger" data-name="next right finger">
          <path
            id="next_right_finger_1"
            data-name="next right finger 1"
            class="Linker_Finger4_dp_sitzend1323_cls-3"
            d="M182.83,359.15c-2.44,16.29-5.85,30.88-10.99,42.45-.89,2.01-2.29,3.76-4.02,5.12l-2.46,1.92c-1.29,1.01-2.12,2.49-2.33,4.11-1.11,8.6-1.47,16.87-.28,24.47.31,1.96,1.46,3.66,3.03,4.89,1.9,1.48,3.2,3.26,3.84,5.37.58,1.88,2.27,3.22,4.22,3.52,5.15.8,10.38-.74,15.67-3.91"
          />
          <path
            id="next_right_finger_2"
            data-name="next right finger 2"
            class="Linker_Finger4_dp_sitzend1323_cls-3"
            d="M188.6,455.11c-10.42,3.41-17.71,9.87-22.63,18.64"
          />
        </g>
        <g id="next_left_finger" data-name="next left finger">
          <path
            id="next_left_finger_1"
            data-name="next left finger 1"
            class="Linker_Finger4_dp_sitzend1323_cls-3"
            d="M37.77,469.88l-1.06-5.45c3.85-7.66,2.5-12.91-2.58-16.43-3.29-23.94-14.7-33.22-31.14-33.42"
          />
          <path
            id="next_left_finger_2"
            data-name="next left finger 2"
            class="Linker_Finger4_dp_sitzend1323_cls-3"
            d="M.86,362.49c4.44,7.38,9.1,13.81,14.43,17.38,2.71,1.81,4.87,4.34,5.95,7.41,1.41,4.02,1.94,8.89,1.5,14.71.82,9.09-3.9,9.73-10.94,6.84-2.93-1.15-6.18-.87-9.57,0"
          />
          <path
            id="next_left_finger_3"
            data-name="next left finger 3"
            class="Linker_Finger4_dp_sitzend1323_cls-5"
            d="M22.88,427.73c2.38,4.19,4.35,8.17,4.07,11.09-.11,1.14.15,2.28.69,3.29,1.42,2.67,3.38,4.82,6.48,5.9"
          />
        </g>
      </g>
      <g id="Overlays">
        <g
          id="Overlay_Finger_Complete"
          data-name="Overlay Finger Complete"
          class="Linker_Finger4_dp_sitzend1323_cls-1"
        >
          <g id="overlay_fingerbone_1" data-name="overlay fingerbone 1">
            <path
              id="finger_bone_1.1-2"
              data-name="finger bone 1.1"
              class="FullFinger1323Fill"
              style={{
                fill: props.colors.FullFinger1323Fill,
                stroke: props.colors.FullFinger1323Fill,
                opacity: props.colors.FullFinger1323Fill,
              }}
              d="M84.54,414.13c-9.32,8.06-15.26,6.53-19.44,0-9.22-7.09-6.99-16.44,0-26.73,8.85-22.57,6.52-57.84.17-97.67-.11-.71-.14-1.42-.09-2.14.64-9.78-.46-20.02-4.03-30.92-.47-1.44-1.36-2.69-2.51-3.68-2.32-1.98-3.23-5.41-2.69-10.32.18-1.61.2-3.23,0-4.83-1.93-16.06,1.24-20.67,6.1-21.49l11.11,1.07c2.94.28,5.91-.15,8.64-1.26,8.62-3.51,16.46-5.14,22.5-2.48,1.35.59,2.43,1.68,3.07,3,2.19,4.51,3.05,9.05,2.54,13.61-.19,1.7.29,3.43,1.34,4.78,2.31,2.94,3.14,6.06.91,9.53-2.34,6.12-2.35,12.89-.47,20.18.29,1.13.5,2.28.57,3.44.38,6.77.86,15.31,1.42,25.29,1.86,41.07,8.78,73.94,24.61,92.35,4.24,8.11,3.86,15.22,0,21.57.14,8.25-3.27,8.8-7.9,6.68-16.99-5.67-32.52-6.48-45.87,0l.02.02Z"
            />
          </g>
          <g id="overlay_fingerbone_2" data-name="overlay fingerbone 2">
            <path
              id="fingerbone_2.1-2"
              data-name="fingerbone 2.1"
              class="FullFinger1323Fill"
              style={{
                fill: props.colors.FullFinger1323Fill,
                stroke: props.colors.FullFinger1323Fill,
                opacity: props.colors.FullFinger1323Fill,
              }}
              d="M50.05,194.76c4.99-9.27,7.29-20.94,7.91-34.12.14-2.95.57-5.88,1.23-8.77,3.73-16.24,2.79-28.17-3.09-35.54-1.66-2.08-2.3-4.81-1.98-7.45.43-3.49.87-6.23,1.84-8.02.57-1.05.97-2.18,1.07-3.38.69-7.57,3.14-10.09,13.22-8.12,3.97,1.29,8.1.57,12.61-.57,8.13-4.01,13.39-1,12.75,6.67-.16,1.88.36,3.74,1.54,5.22,3.79,4.77,4.01,9.12,1.05,15.28-.97,2.02-1.34,4.29-1,6.5.16,19.69,3.42,40.48,9.04,62.11.56,2.15,1.55,4.19,3.06,5.81,3.29,3.56,2.88,8.27-.26,13.84-.88,1.56-2.59,2.45-4.38,2.33-6.63-.46-15.33,1.98-24.54,5.13-1.26.43-2.62.43-3.88,0-7.54-2.49-14.58-3.87-20.84-3.55-4.12-.94-5.63-4.43-6.17-8.87-.19-1.56.09-3.15.84-4.53l-.02.03Z"
            />
          </g>
          <g id="overlay_fingerbone_3" data-name="overlay fingerbone 3">
            <path
              id="fingerbone_3.1-2"
              data-name="fingerbone 3.1"
              class="FullFinger1323Fill"
              style={{
                fill: props.colors.FullFinger1323Fill,
                stroke: props.colors.FullFinger1323Fill,
                opacity: props.colors.FullFinger1323Fill,
              }}
              d="M56.9,82.99c-3.21-1.81-4.1-4.27-3.08-7.29,7.66-8.41,12.62-17.66,12.59-29.72,0-1.04-.2-2.08-.7-3-1.22-2.28-1.28-6.01-.95-10.23-.48-5.59,3.23-10.69,9.46-15.49,6.16-4.43,16.52-2.34,18.34.46,3.77,9.38,3.82,17.38,0,23.92-1.54-.69-5.44,18.84,4.22,27.46,3.26,2.86,3.84,5.71.46,8.54-.52,4.87-4.4,6.24-9.91,5.92-5.07,2.47-10.2,3.04-15.38,1.71-9.57-1.94-11.91,2.5-15.04-2.28h-.01Z"
            />
          </g>
        </g>
        <ellipse
          id="Overlay_finger_base_joint"
          data-name="Overlay finger base joint"
          class="Grundgelenk1323Fill"
          style={{
            fill: props.colors.Grundgelenk1323Fill,
            stroke: props.colors.Grundgelenk1323Fill,
            opacity: props.colors.Grundgelenk1323Fill,
          }}
          cx="102.38"
          cy="417.84"
          rx="37.86"
          ry="24.39"
        />
        <path
          id="Overlay_fingertip"
          data-name="Overlay fingertip"
          class="Fingerkuppe1323Fill"
          style={{
            fill: props.colors.Fingerkuppe1323Fill,
            stroke: props.colors.Fingerkuppe1323Fill,
            opacity: props.colors.Fingerkuppe1323Fill,
          }}
          d="M64.53,40.73c3.86-8.42,7.96-15.46,13.29-15.27,6.89-.03,12.13,4.37,14.6,16.17,4.11-6.44,3.39-14.68,0-23.92-4.33-3.9-10.34-3.9-17.63-.92-9.21,5.6-11.87,13.89-10.26,23.94Z"
        />
      </g>
    </svg>
  );
}

export default Linker4FingerDpSitzend1323;
