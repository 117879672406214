import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const Skull = map.Skull;

function FrontView({ selected, bpartSetter }) {
  return (
    <svg
      id="Ebene_3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 520.59 675.59"
    >
      <g id="head">
        <g id="outlines">
          <path
            id="outlines_5"
            class="fhead-cls-3"
            d="M209.85,21.82c58.73-31.76,119.35-29.14,182.25,15.38,28.31,12.23,53.01,25.21,73.88,39,27.7,41.25,35.07,85.44,33.75,130.88,4.03,45.94,1.16,88.66-11.62,126.75,6.26,.71,9.01,4.42,6.38,12.75-6.52,26.82-15.57,50.83-28.88,70.12,3.64,20.52-1.32,36.73-15.38,48.38-6.68,5.36-13.6,2.45-20.62-4.5-4.07,18.13-4.54,37.96-1.12,59.62,9.74,39.15,1.18,70.73-26.25,94.5-27.19,46.77-52.79,52.33-78.38,57.38l-59.25,3-20.25-2.25c-45.31-1.91-76.18-39.84-105.75-81-18.84-16.8-24.2-43.8-13.12-83.25l3-27.38c-11.33,9.9-19.55,5.92-24.75-11.62-11.62-7.66-13.43-21.08-10.12-37.5l-18.38-39.38c-8.52-13.92-7.66-24.27,3.38-30.75l1.5-6.75c-14.31-18.92-16.94-52.16-12.75-93.75-4.7-44.26-.81-81.45,6-116.25,2.48-7.46,5.18-14.62,8.11-21.43,13.11-30.43,31.09-53.93,57.14-65.94,25.1-14.89,48.88-26.96,71.25-36h-.02Z"
          />
          <path
            id="outlines_4"
            class="fhead-cls-3"
            d="M397.72,555.82c4.03,19.2,5.98,38.75,4.5,58.88"
          />
          <path
            id="outlines_3"
            class="fhead-cls-3"
            d="M413.47,434.32c-2.48,41.55-6.95,80.86-21.75,115.12"
          />
          <path
            id="outlines2"
            class="fhead-cls-3"
            d="M143.5,455.44c4.09,38.84,14.11,72.68,31.48,100.37"
          />
          <path
            id="outlines_1"
            class="fhead-cls-3"
            d="M140.13,593.94c-3.94-15.68-4.13-31.03,0-46"
          />
        </g>
        <g id="nose">
          <path
            id="nose_9"
            class="fhead-cls-3"
            d="M261.6,276.44l-1.88,46.5c7.5-11.05,14.51-15.83,20.62-8.77,7.58-5.16,14.16-2.71,19.56,8.77l.69-46.5"
          />
          <path id="nose_8" class="fhead-cls-3" d="M280.35,262.19v51.98" />
          <path
            id="nose_7"
            class="fhead-cls-3"
            d="M276.22,317.32l-1.85,59.25c3.35,1.72,6.45,2.28,8.98,0l1.87-59.25c-3.16-3.96-6.18-4.43-9,0Z"
          />
          <path
            id="nose_6"
            class="fhead-cls-3"
            d="M264.75,340.56c-2.39,2.33-6.48,1.4-11.03,10.59,2.38,11.32,6.03,19.29,11.03,23.65,4.29-11.41,4.29-22.82,0-34.24Z"
          />
          <path
            id="nose_5"
            class="fhead-cls-3"
            d="M293.47,342.82c-4.21,6.12-4.44,16.77-3.38,28.9,4.75-4.28,9.5-10.53,14.25-18.78-1.63-6.17-5.44-9.29-10.88-10.12h.01Z"
          />
          <path
            id="nose_4"
            class="fhead-cls-3"
            d="M249.44,393.29c.36-6.28,2.28-9.56,6-9.75,6.21,4.26,8.44,7.27,9,9.75-2.21,5.22-1.79,11.22,0,17.62-7-.78-12-6.66-15-17.62Z"
          />
          <path
            id="nose_3"
            class="fhead-cls-3"
            d="M311.85,392.69c-.36-6.28-2.28-9.56-6-9.75-6.21,4.26-8.44,7.27-9,9.75,2.21,5.22,1.79,11.22,0,17.62,7-.78,12-6.66,15-17.62Z"
          />
          <path
            id="nose_2"
            class="fhead-cls-3"
            d="M274.37,381.06c2.99-4.61,5.99-3.99,8.98,1.88,.99,9.21,.33,19.27-3,30.75-1.16,4.8-3.58,2.31-5.97,0-2.27-9.52-1.8-20.67,0-32.62h-.01Z"
          />
          <path
            id="nose_1"
            class="fhead-cls-3"
            d="M259.72,322.94c1.46,7.3,.75,14.11-4.5,19.88-5.13,12.78-10.42,25.48-5.78,42.75-3.64,4.65-6.93,9.43-9.22,14.58-2.82,24.42,4.16,32.66,19.31,27.42,6.92-.64,11.53-4.13,13.31-11.13h7.51c3.32,9.1,8.45,13.72,16.5,11.13,12.38-1.61,24.34-4.08,22.12-36-1.89-4.24-5.02-9.03-9.38-14.34,2.93-12.95-1.19-24.14-9-34.41-.28-6.55-.51-13.18-.69-19.88"
          />
        </g>
        <g id="temporal_bone_l">
          <path
            id="temporal_bone_l_2"
            class="fhead-cls-3"
            d="M433.6,296c1.42-2.66,2.82-5.35,4.2-8.07,2.4,1.68,6.16,.6,12.04-4.74l3.75,7.12c-2.38,13.11-1.11,25.15,2.23,36.57,9.44,25.57,19.17,32.69,29.26,15.93l3-9-3,9c-9.64,13.99-19.04,10.82-28.12-12.88-5.94-14.32-4.9-32.82-1.5-52.75"
          />
          <path
            id="temporal_bone_l_1"
            class="fhead-cls-3"
            d="M467.47,237.82c13.17-29.38,24.79-45.28,32.54-27.4"
          />
        </g>
        <g id="temporal_bone_r">
          <path
            id="temporal_bone_r_2"
            class="fhead-cls-3"
            d="M78.6,361.94c5.32,16.45,12.52,18.72,22.88-2.62,7.4-17.67,7.11-40.39,4.12-64.88"
          />
          <path
            id="temporal_bone_r_1"
            class="fhead-cls-3"
            d="M65.39,231.46c6.31-15.1,15.11-11.61,26.41,10.49"
          />
        </g>
        <g id="frontal_bone">
          <path
            id="frontal_bone_1"
            class="fhead-cls-3"
            d="M81.46,123.76c3.76,39.7,11.76,73.1,22.26,102.81,18.13,22.93,13.88,42.55,0,60.75l1.88,7.12c6.73-5.32,12.87-4.59,18.38,2.62,6.08-26.26,20.01-42.14,44.62-43.88,27.2-3.56,50.06-1.24,61.5,16.5l13.12,13.5c7.45,1.75,13.56-.55,18.38-6.75,.73-12.7,6.94-17.5,18.75-14.25,12.39-2.4,20.31,.85,20.25,14.25,6.42,7.74,12.66,8.43,18.75,2.62,8.13-16.19,22.57-25.22,40.12-30.75,13.96-1.59,24.84-1.13,31.88,1.88,25.52,.87,35.58,15.75,40.12,35.62,8.01,3.69,14.37,3.4,18.38-2.62v-17.25c-11.36-18.81-8.77-34.36,6.75-46.88,6.2-34.4,9.65-66.22,3.75-89.25-4.13-26.76-2.92-45.6,6.94-51.63"
          />
        </g>
        <g id="sphenoid_r">
          <path
            id="sphenoid_1_r"
            class="fhead-cls-3"
            d="M92.47,236.69c2.25-8.62,8.33-11.43,9.93-13.94l8.79,15.45c7.71,16.84,4.74,29.48-1.85,40.7l-7.51,11.41c-8.3-17.04-12.44-34.77-9.36-53.62h0Z"
          />
        </g>
        <g id="sphenoid_l">
          <path
            id="sphenoid_l_1"
            class="fhead-cls-3"
            d="M456.6,219.06c5.38,2.58,10.3,10.27,10.69,21.38,1.23,13.8-3.95,25.67-11.81,36.75l-5.62-11.25c-8.67-16.77-11.87-33.7,6.75-46.88h-.01Z"
          />
        </g>
        <g id="zygomatic_bone_r">
          <path
            id="zygomatic_bone_2_r"
            class="fhead-cls-3"
            d="M188.56,369.9c6.89,3.99,6.54,9.65-1.85,17.17l-45.11,11.62c-5.08,4.34-9.64,13.55-13.88,25.88-5.77,19.98-17.87,20.22-34.12,7.5"
          />
          <path
            id="zygomatic_bone_1_r"
            class="fhead-cls-1"
            d="M123.97,297.06c16.6,36.27,37.29,62.6,64.59,72.83"
          />
        </g>
        <g id="zygomatic_bone_l">
          <path
            id="zygomatic_bone_l_2"
            class="fhead-cls-3"
            d="M465.6,416.69c-10.2,15.43-26.22,18.97-44.62,17.62,8.24-13.03,2.86-22.66-10.12-30.38-6.67-3.67-6.44-8.06-4.88-13.12-8.19-2.99-14.18-3.89-19.5-6-4.79-6.46-4.21-12.82,0-19.09"
          />
          <path
            id="zygomatic_bone_l_1"
            class="fhead-cls-1"
            d="M386.47,365.72c17.31-19.97,33-43.24,47.13-69.72"
          />
        </g>
        <g id="eye">
          <path
            id="eye_r_2"
            class="fhead-cls-3"
            d="M243.22,283.19c4.34,1.46,5.62,4.74,5.25,9,3.73,24.19-3.19,43.98-22.88,58.5-16.3,16.76-39.51,22.71-67.88,20.62-22.77-4.84-33.84-19.29-31.88-44.43l-1.88-29.82"
          />
          <path
            id="eye_r_1"
            class="fhead-cls-2"
            d="M206.8,280.09l27.78,31.96c1.86,20.2-3.09,25.67-13.27,18.64-1.48-15.05-8.95-30.5-17.22-42.24l-23.93-9.82c7.64-.04,21.75-1.89,26.64,1.46h0Z"
          />
        </g>
        <g id="eye_l">
          <path
            id="eye_l_2"
            class="fhead-cls-3"
            d="M311.85,283.19c-2.79,8.92-3.21,17.37,0,25.12,8.36,25.55,21.25,46.55,47.62,54,32.25,5.45,62.24,8.28,66-19.12,8.64-16.6,9.94-34.42,7.5-52.88l-1.5-4.5"
          />
          <path
            id="eye_l_1"
            class="fhead-cls-2"
            d="M362.15,277.74l-30.15,29.73c-3.4,19.99,1.1,25.84,11.8,19.6,2.63-14.9,11.27-29.72,20.42-40.79l24.61-7.95c-7.62-.63-21.54-3.56-26.67-.59h-.01Z"
          />
        </g>
        <g id="mouth">
          <path
            id="mouth_5"
            class="fhead-cls-3"
            d="M119.99,438.15c-3.03,12.58,2.22,18.37,15.98,17.17,9.38,.42,15.11-3.31,19.5-8.58,16.71-3.69,28.61-1.02,27,19.46-1.1,9.64-2.11,18.43-3,26.25,4.04-.01,6.48,1.95,7.23,6,3.66-1.45,6.13-.86,6.69,3,4.57-1.1,8.69-.49,11.95,3.38,4.95-1.34,9.31-1.41,11.62,3,4.06-2.04,7.82-2.5,10.5,2.62,5.19-3.58,10.05-4.42,14.25,0,4.89-2.75,9.47-2.94,13.5,1.5,7.92-4.11,15.06-4.01,21,2.62,8.96-9.2,15.51-9.06,20.62-3.38,3.97-5.46,8.7-7.45,15-2.25,4.99-6.85,10.12-8.47,15.38-5.62,4.64-2.77,8.93-3.84,12.38-.75,3.09-3.21,6.11-4.52,9-3,4.4-3.42,8.21-4.97,11.25-4.12,2.8-3.86,5.86-5.58,9.38-3.38,2.63-3.37,5.15-5.37,7.5-5.25v-37.88l24.38-14.36,19.88-.26"
          />
          <line
            id="mouth_4"
            class="fhead-cls-3"
            x1="128.47"
            y1="481.19"
            x2="131.46"
            y2="455.44"
          />
          <line
            id="mouth_3"
            class="fhead-cls-3"
            x1="434.07"
            y1="434.58"
            x2="429.6"
            y2="460.56"
          />
          <path
            id="mouth_2"
            class="fhead-cls-3"
            d="M155.47,446.73c-2.62,23.96,2.5,40.95,15.38,50.96,4.23-.5,7.48-1.89,8.62-5.25"
          />
          <path
            id="mouth_1"
            class="fhead-cls-3"
            d="M413.47,434.32c-5.77,22.88-14.35,42.72-27.38,57.75-5.64,1.72-8.35-.59-9.38-5.25"
          />
        </g>
        <g id="teeth">
          <path
            id="teeth_37"
            class="fhead-cls-3"
            d="M170.85,497.69c0,7.12,2.16,10.82,2.62,13.12,2.5,6.59,6,17.25,6,20.62s4.12,3.75,4.12,3.75c3.25,6.59,7.46,9.11,12.38,8.62,1.81,4.39,5.34,5.17,9.38,4.88,3.75,5.02,7.97,7.19,13.12,3.75,2.56,3.43,5.06,4.5,7.5,3.38,2.31,4.19,5.58,6.02,9.75,5.62,5.04,6.03,9.7,8.26,13.72,3.75,5.38,2.74,10.12,3.63,13.28,0,5.15,6.26,10.93,6.07,17.08,2.25,4.98,1.87,7.85-.07,9.76-3.75,7.39,4.27,13.6,6.47,17.41,1.5,6.39,3.18,12.08,1.85,16.88-5.25,4.68,1.13,8.45-.45,11.25-4.88,4.88,1.6,8.94,.57,11.62-4.88,4.62,.53,8.97-1.87,13.12-6.38,3.43,.72,5.7-.73,7.12-3.75l4.86-6c4.33,1.59,6.65-2.97,7.52-6.38v-16.88l6.75-18.75"
          />
          <path
            id="teeth_36"
            class="fhead-cls-3"
            d="M171.87,505.63l3.1,2.19c3,8.75,6,11,9,6.75,2.55,6.7,5.73,8.26,9.42,5.62,4.02,4.82,7.84,5.46,11.58,4.12,3.2,5.57,6.86,6.41,10.88,3.75,4.02,5.21,7.89,8.85,10.88,3.03,4.73,5.36,9.02,6.45,12.89,3.34,7.91,4.29,13.51,4.48,17.33,1.5,6.49,5.81,12.34,5.75,17.43-1.5,8.76,5.38,16.32,6.35,20.97-3.34,8.71,7.16,14.81,6.19,18.75-1.53,6.56,3.24,11.83,2.67,15-4.12,4.53,3.64,8.02,2.98,10.5-1.88,3.61,2.7,6.07,1.39,7.5-3.53,4.23,3.16,8.99,.46,13.88-3.59,6.15,2.66,9.78,.03,10.86-7.12,3.21,1.23,6.35,.14,9.38-3.69"
          />
          <path
            id="teeth_35"
            class="fhead-cls-3"
            d="M186.71,498.44c-3.23,4.62-4.15,10-2.73,16.12"
          />
          <path
            id="teeth_34"
            class="fhead-cls-3"
            d="M177.88,494.98c-2.89,4.3-3.88,8.58-2.91,12.84"
          />
          <path
            id="teeth_33"
            class="fhead-cls-3"
            d="M193.39,501.44c-2.15,7.07-2.98,13.64,0,18.75"
          />
          <path
            id="teeth_32"
            class="fhead-cls-3"
            d="M205.35,504.82c-2.91,6.42-2.97,12.92-.38,19.5"
          />
          <path
            id="teeth_31"
            class="fhead-cls-3"
            d="M216.97,507.82c-2.62,6.75-3,13.5-1.12,20.25"
          />
          <line
            id="teeth_30"
            class="fhead-cls-3"
            x1="227.47"
            y1="510.44"
            x2="226.72"
            y2="531.1"
          />
          <path
            id="teeth_29"
            class="fhead-cls-3"
            d="M241.72,510.44c-1.81,8.82-2.8,17.03-2.11,24"
          />
          <path
            id="teeth_28"
            class="fhead-cls-3"
            d="M255.22,511.94c-.37,6.08-.54,13.94,1.72,24"
          />
          <path
            id="teeth_27"
            class="fhead-cls-3"
            d="M276.22,514.56c1.04,6.62,.43,13.25-1.85,19.88"
          />
          <path
            id="teeth_26"
            class="fhead-cls-3"
            d="M296.85,511.19c.62,7.16,.34,13.9-1.5,19.91"
          />
          <path
            id="teeth_25"
            class="fhead-cls-3"
            d="M311.85,508.94c2.55,6.1,3.52,12.88,2.25,20.62"
          />
          <path
            id="teeth_23"
            class="fhead-cls-3"
            d="M327.22,503.32c3.82,7.21,4.52,14.58,1.88,22.12"
          />
          <path
            id="teeth_22"
            class="fhead-cls-3"
            d="M339.6,502.56c2.5,7,2.5,14,0,21"
          />
          <path
            id="teeth_21"
            class="fhead-cls-3"
            d="M348.6,499.56c1.4,8.29,1.28,15.41-1.5,20.47"
          />
          <path
            id="teeth_20"
            class="fhead-cls-3"
            d="M359.85,495.44c2.72,6.19,3.73,12.97,1.12,21"
          />
          <path
            id="teeth_19"
            class="fhead-cls-3"
            d="M369.22,492.06c3.45,5.55,4.7,11.27,2.61,17.25"
          />
          <path
            id="teeth_18"
            class="fhead-cls-3"
            d="M177.88,520.03c5.51-2.1,7.8-.38,8.83,2.83,4.72-1.99,9.44-3.02,13.02,5.2,3.07-2.5,6.48-1.76,10.12,3.03,4.6-2.03,8.4-2.34,9.38,3.34l7.88,1.5c3.24-1.39,6.49-.01,9.75,4.17,3.48-2.37,7.87-1.75,12.59,0,5.94-1.09,10.79,.37,15,3.33,4.72-4.34,9.35-4.54,13.87,0,3.9-4.04,8.78-4.4,14.04-3.33,.84-3.14,8.25-4.17,17.25,0,4.29-5.97,8.38-6.36,12.38-4.17,4.5-3.25,8.79-4.12,12.75-1.5,4.1-3.91,7.91-6.42,10.88-4.88,3.95-4.25,8.74-5.5,14.25-4.12,.13-3,1.76-4,4.88-3,3.63-4.5,6-6,7.11-4.5,2.85-5.12,5.36-7.5,7.52-7.12"
          />
          <path
            id="teeth_17"
            class="fhead-cls-3"
            d="M183.6,535.19c.7-4.56,1.66-8.77,3.11-12.32"
          />
          <path
            id="teeth_16"
            class="fhead-cls-3"
            d="M195.97,543.82c-1.14-8.55,.38-13.37,3.75-15.75"
          />
          <path
            id="teeth_15"
            class="fhead-cls-3"
            d="M205.35,548.69c-1.5-6.07,.09-11.92,4.5-17.59"
          />
          <path
            id="teeth_14"
            class="fhead-cls-3"
            d="M218.47,552.44c-2.25-6-2-12,.75-18"
          />
          <path
            id="teeth_13"
            class="fhead-cls-3"
            d="M225.97,555.82c-1.87-8.09-1.51-14.72,1.12-19.88"
          />
          <path
            id="teeth_12"
            class="fhead-cls-3"
            d="M235.72,561.44c-1.88-8.89-1.5-16,1.12-21.33"
          />
          <path
            id="teeth_11"
            class="fhead-cls-3"
            d="M249.44,565.19c-2.79-9.36-2.79-17.72,0-25.08"
          />
          <path
            id="teeth_10"
            class="fhead-cls-3"
            d="M262.72,565.19c.57-9.47,1.15-16.72,1.72-21.75"
          />
          <line
            id="teeth_9"
            class="fhead-cls-3"
            x1="279.8"
            y1="567.44"
            x2="278.31"
            y2="543.44"
          />
          <path
            id="teeth_8"
            class="fhead-cls-3"
            d="M289.56,563.69c3.89-9.15,4.11-16.7,2.78-23.58"
          />
          <path
            id="teeth_7"
            class="fhead-cls-3"
            d="M306.97,565.19c2.82-7.29,3.29-15.75,2.62-25.08"
          />
          <path
            id="teeth_6"
            class="fhead-cls-3"
            d="M323.85,559.94c2.19-8.7,1.34-16.64-1.88-24"
          />
          <path
            id="teeth_5"
            class="fhead-cls-3"
            d="M335.1,555.06c2.12-6.88,2-13.75-.38-20.62"
          />
          <path
            id="teeth_4"
            class="fhead-cls-3"
            d="M346.72,550.19c2.49-6.77,2.03-13.65-1.12-20.62"
          />
          <path
            id="teeth_3"
            class="fhead-cls-3"
            d="M359.85,543.82c1.93-5.49,1.59-11.73,0-18.38"
          />
          <path
            id="teeth_2"
            class="fhead-cls-3"
            d="M366.97,540.06c.65-4.54-.55-10.91-2.25-17.62"
          />
          <path
            id="teeth_1"
            class="fhead-cls-3"
            d="M371.83,534.06c2.22-3.84,.14-8.71,0-16.12"
          />
        </g>
      </g>
      <g id="skull_overlay">
        <path
          id="skull_overlay_1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Skull.id, Skull.children.brainSkull),
            });
          }}
          class={`fhead-cls-4 ${isSelected(
            selected,
            Skull.children.brainSkull
          )}`}
          d="M485.08,342.81c14.75-45.34,20.24-85.5,14.94-132.41-.32-50.36-7.07-96.9-32.72-132.23-18.04-14.39-45.82-27.78-75.19-41-58.37-34.55-117.95-54.13-182.25-15.38l-71.25,36c-24.74,14.3-43.19,37.09-57.14,65.94-10.28,25.13-16.44,59.29-16.07,107.7v.03s1.97,29.99,1.97,29.99c-2.67,37.29-3.13,72.65,12.75,93.75l-1.52,6.74c6.51,32.86,36.62,1.24,27-67.5,6.53-5.97,12.72-6.15,18.38,2.62,8.14-28.98,23.56-45.62,49.48-44.45,21.37-.5,43.19-1.88,55.36,15.22l17.95,17.59c9.86-3.56,10.96-4.68,14.84-8.98,2.09-13.88,8.17-19,18.75-14.25,11.55-1.67,20.56,.05,20.25,14.25,4.36,6.94,10.01,9.71,18.75,2.62,11.89-16.93,25.39-28.71,41.9-30.94,9.63-1.2,19.68-.41,30.1,2.06,20.17,2.22,36.01,10.6,40.12,35.62,1.34,.36,2.14,9.85,2.12,10.2-.1,1.6,3.23-7.88,4.15-7.83,4.32,.23,8.33-.31,12.11-4.99,.27,2.98,2.96,4.12,3.73,7.13-6.95,34.42,16.44,81.1,31.49,52.5Z"
        />
      </g>
      <g id="nasal_bone">
        <path
          id="nasal_bone_overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Skull.id, Skull.children.nasalBone),
            });
          }}
          class={`fhead-cls-4 ${isSelected(
            selected,
            Skull.children.nasalBone
          )}`}
          d="M261.6,276.44c.5-13.3,7.45-17.01,18.75-14.25,11.84-1.66,20.73,.39,20.25,14.25l-.69,46.5,.69,19.88c6.85,9.52,12.14,19.83,9,34.41,3.28,3.75,6.43,8.42,9.38,14.34,1.43,26.12-6.47,37.28-22.12,36-5.56,3.81-11.05-.99-16.5-11.13h-7.51c-.22,5.88-5.95,8.92-13.31,11.13-16.29,4.91-20.52-7.37-19.31-27.42l9.22-14.58c-1.49-13.36,0-26.51,4.46-39.45,7.35-7.65,9.26-15.38,5.82-23.18l1.88-46.5h-.01Z"
        />
      </g>
      <g id="orbita_r">
        <path
          id="orbita_r_overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Skull.id, Skull.children.orbita),
            });
          }}
          class={`fhead-cls-4 ${isSelected(selected, Skull.children.orbita)}`}
          d="M123.97,297.06c4.3-26.19,19.86-41.88,49.48-44.45,21.71-2.89,41.94-1.28,56.65,17.07l13.12,13.5c4.18,1.81,5.85,4.99,5.25,9,3.15,21.74-.99,41.96-22.88,58.5-20.87,19.2-44.35,23.49-69.33,19.21-20.37-3.88-32.33-16.35-30.42-43.01l-1.88-29.82h.01Z"
        />
      </g>
      <g id="orbita_l">
        <path
          id="otbita_l_overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Skull.id, Skull.children.orbita),
            });
          }}
          class={`fhead-cls-4 ${isSelected(selected, Skull.children.orbita)}`}
          d="M425.47,343.19c-3.61,26.45-31.3,24.26-60.75,19.99-27.96-3.74-44.27-26.92-54.5-60.23-1.44-5.85-.58-12.56,1.63-19.76,4.67-.5,7.71-2.25,9.39-6.75,11.06-15.43,23.7-25.16,38.23-28.12,11.17-1.68,21.86-1.32,31.88,1.88,21.59,1.82,37.38,12.31,41.62,40.12,3.33,15.76,.89,33.37-7.5,52.88h0Z"
        />
      </g>
      <g id="jaw">
        <path
          id="jaw_overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Skull.id, Skull.children.jaw),
            });
          }}
          class={`fhead-cls-4 ${isSelected(selected, Skull.children.jaw)}`}
          d="M402.6,434.3c-4.06,1.18-17.65,9.58-25.88,14.62-63.26-16.55-131.06-13.43-204.85-3.05-4.84-.42-9.86,.29-16.4,.84l-35.48-8.56c-7.6,6.23-16.69,1.79-26.39-6.08-2.55,14.09-2.86,27.59,10.14,37.51,4.79,15.29,11.92,22.83,24.73,11.61l-3.71,30c-11.69,49.62-.28,67.01,13.84,80.62,29.5,44.04,62.28,78.37,105.75,81l18.38,2.04,61.12-2.79c38.85-7.11,64.19-26.97,78.38-57.38,30.81-30.25,35.36-60.35,27.21-90.38-4.45-22.89-4.8-44.28,.16-63.74,8.89,8.83,15.51,9.42,20.63,4.52,12.86-10.58,18.83-26.1,15.37-48.39-9.77,15.75-25.65,19.67-44.62,17.63l-18.38-.02Z"
        />
      </g>
      <path
        id="zygomatic_arch_left"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, Skull.id, Skull.children.zygomaticArch),
          });
        }}
        class={`fhead-cls-4 ${isSelected(
          selected,
          Skull.children.zygomaticArch
        )}`}
        d="M186.71,387.07c8.16-7.51,8.84-13.24,1.85-17.17-37.24,8.43-65.72-5.3-62.72-43.02l-1.87-29.82c-5.99-8.05-12.14-7.44-18.37-2.62,3.29,23.49,3.26,45.65-4.12,64.88-11.82,24.07-17.81,15.88-22.88,2.62-12.89,7.55-9.73,17.53-3.54,30.84,4.77,10.25,10.49,23.65,18.54,39.29,9.03,6.66,18.03,12.71,26.39,6.08,8.04-5.11,9.26-27.79,21.61-39.46l45.11-11.62Z"
      />
      <path
        id="zygomatic_arch_right"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, Skull.id, Skull.children.zygomaticArch),
          });
        }}
        class={`fhead-cls-4 ${isSelected(
          selected,
          Skull.children.zygomaticArch
        )}`}
        d="M405.98,390.81l-19.5-6c-4.25-5.27-4.55-11.55,0-19.09,19.55,.88,36.23-1.91,38.99-22.53,8.73-15.3,9.35-31.18,8.13-47.19l4.2-8.07c5.63,1.69,8.95-1.33,12.06-4.75,1.46,3.41,2.74,5.94,3.73,7.13-5.93,39.41,17.74,78.95,31.49,52.5l3.03-8.98c7.75,.9,8.36,6.16,6.38,12.75-5.65,24.63-15.28,48.19-28.89,70.11-9.02,12.91-23.08,19.76-44.62,17.63,9.23-15.51,.28-23.26-10.12-30.39-6.56-2.79-6.78-7.62-4.88-13.12Z"
      />
      <path
        id="NNH_right"
        // onClick={() => {
        //   bpartSetter({
        //     id: idToSet(selected, Skull.id, Skull.children.nasalBone),
        //   });
        // }}
        class={`fhead-cls-4 `}
        d="M276.5,416.57l-2.44,20.34c1.28-1.67,73.61,.43,102.66,12.02l24.38-14.34,19.88-.26c7.25-11.94,2.85-21.89-10.12-30.39-5.4-1.9-6.65-6.52-4.88-13.12l-19.5-6c-3.99-6.11-6.19-12.34,0-19.09-47.79,1.07-65.77-27.17-76.26-62.77-1.52-8.88-1.16-15.67,1.63-19.76-3.96,.1-7.76-2.91-11.25-6.75v66.38c8.11,9.73,12.23,21.01,9,34.41,3.54,3.07,6.62,8.03,9.38,14.34,3.09,25.47-5.35,36.1-22.12,36-8.75,2.7-13.87-1.39-16.5-11.13l-3.86,.12Z"
      />
      <path
        id="NNH_left"
        // onClick={() => {
        //   bpartSetter({
        //     id: idToSet(selected, Skull.id, Skull.children.nasalBone),
        //   });
        // }}
        class={`fhead-cls-4 `}
        d="M259.73,322.94l1.87-46.5c-6.76,7.99-12.6,7.63-18.38,6.74,0,0,6.52,4.66,5.25,9,3.6,40.29-12.24,48.76-22.88,58.5-10.99,11.37-23.63,16.72-37.03,19.22,7.43,4.47,6.33,10.27-1.85,17.17l-45.11,11.62-21.61,39.46,35.48,8.56c51.79-5.94,95.8-10.2,118.59-9.81l2.44-20.34-3.65-.12c-2.26,7.93-7.11,10.85-13.31,11.13-19.27,6.3-22.22-10.08-19.31-27.42l9.22-14.58c-4.46-15.86,.64-29.31,5.77-42.75,5.87-5.52,6.3-12.42,4.51-19.88Z"
      />
    </svg>
  );
}

export default FrontView;
