import style from "./style.scss";

export default function RechtesSchulterblattVdStehend2024(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 415.17 554.82">
      <g id="shoulder_plate" data-name="shoulder plate">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls2"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls2,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls2,
            }}
            d="M129.24,497.7c2.65,2.35,7.59,6.73,15.04,7.52,12.1,1.29,20.98-7.93,22.56-9.57,1.75-1.82,1.66-2.18,8.2-13.67,5.15-9.06,8.38-14.73,12.3-21.19,6.13-10.09,6.99-10.61,8.2-14.35,2.29-7.06,1.5-12.15,1.37-18.46-.13-6.12,1.28-13.88,4.1-29.39,1.9-10.43,4.07-18.65,17.09-60.15,13.29-42.39,10.4-31.82,14.35-45.11,8.04-27.02,8.17-30.92,13.67-41.01,3.04-5.58,7.57-12.78,15.72-28.03,6.48-12.13,8.11-15.99,7.52-21.19-.52-4.6-2.67-9.35,0-12.99,1.98-2.68,5.02-2.63,5.47-4.78.62-2.95-4.42-6.28-6.15-7.52-8.48-6.09-11.83-15.6-15.72-26.66-5.9-16.76-3.08-31.85-1.37-41.01,1.22-6.52,2.31-12.36,6.15-19.14,6.39-11.27,15.35-16.28,14.35-17.77-.87-1.3-8.66.97-15.04,6.15-6.79,5.51-6.9,10.15-10.94,10.94-5.32,1.04-7.7-7.64-16.41-12.3-8.27-4.43-23.07-12.34-39.65-6.84-17.29,5.74-24.09,21.94-24.61,23.24-2.14,5.34-4.16,16.25-.68,27.34,1.85,5.91,4.71,9.93,2.73,12.99-1.69,2.61-5.75,2.74-12.3,2.73-13.58-.02-20.37-.03-24.61-.68-27.76-4.25-41.78-6.11-52.63-11.62-10.46-5.3-21.56-9.26-32.13-14.35-4.21-2.03-7.69-3.77-11.62-2.73-1.66.43-8.73,2.78-13.67,23.92-7.29,31.17.68,57.8,2.05,62.2,5.27,16.9,13.57,30.69,15.72,34.18,7.23,11.74,11.99,15.88,17.09,28.03,3.67,8.74,3.1,11.1,6.84,25.29,4,15.15,5.44,15.45,15.04,43.75,6.05,17.85,9.08,26.78,11.62,36.91,3.77,15.08,3.42,18.45,8.2,36.23,3.69,13.73,7.52,25.29,7.52,25.29,5.49,16.55,6.99,17.66,8.89,26.66,2.72,12.85.31,13.68,3.42,24.61,2.03,7.15,4.59,15.73,12.3,22.56l.02-.03Z"
          />
        </g>
        <path
          id="collarbone"
          class="Rechtes_Schulterblatt_vd_stehend2024_cls5"
          style={{
            fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls5,
            stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls5,
          }}
          d="M2.13,113.78c16.63-8.07,30.52-15.1,41.01-20.51,20.65-10.65,25.92-13.87,30.76-17.09,8.02-5.34,7.7-5.91,25.29-18.46,15.23-10.87,20.17-13.8,26.66-16.41,5.38-2.17,10.19-3.27,19.82-5.47,18.4-4.21,19.89-2.83,33.49-6.15,15.23-3.72,13.72-5.54,30.76-9.57,8.98-2.13,12.91-2.45,20.51-5.47,9.65-3.83,16.41-7.52,16.41-7.52,8-4.37,9.33-6.2,11.62-5.47,5.66,1.81,8.86,16.67,2.73,24.61-2.42,3.13-5.66,4.37-8.89,5.47-14.6,4.97-26.66,6.19-31.44,6.84-3.06.42-4.62.8-36.91,12.99-21.51,8.13-32.27,12.19-34.18,12.99-15,6.28-36.31,17.29-60.84,37.59C59.53,119.24,30.14,136.33.75,153.42"
        />
        <g id="upper_arm" data-name="upper arm">
          <path
            id="upper_arm_1"
            data-name="upper arm 1"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls5"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls5,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls5,
            }}
            d="M414.28,229.99c-9.26-6.75-17.5-11.93-23.92-15.72-5.62-3.31-11.82-6.94-20.51-10.94-5.1-2.35-12.72-5.82-23.24-8.89-8.06-2.35-14.66-4.28-23.92-4.78-5.64-.31-13.93-.76-23.92,2.05-9.96,2.79-11.73,6.06-16.41,4.78s-5.55-5.27-13.67-17.77c-8.28-12.75-8.89-10.92-12.3-17.77-1.36-2.73-5.85-12.24-6.84-25.29-.42-5.62.17-10.81,1.37-21.19.98-8.53,1.51-12.88,3.42-17.09,2.38-5.24,5.42-8.48,10.94-14.35,4.99-5.32,7.71-8.21,12.3-10.94,4.27-2.54,5.52-2.17,17.77-6.15,13.81-4.49,13.35-5.32,17.09-5.47,6.87-.27,11.81,2.42,21.19,7.52,4.15,2.25,11.74,6.46,19.82,14.35,1.54,1.5,8.45,8.37,15.04,19.82,6.88,11.94,5.33,14.84,12.99,28.71,3.62,6.56,6.46,11.61,11.62,17.09,7.31,7.75,15.22,12.02,20.51,14.35"
          />
          <path
            id="upper_arm_2"
            data-name="upper arm 2"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M393.79,211.52c-65.62-58.1-98.43-45.8-101.85-58.1"
          />
        </g>
        <g id="scapula">
          <path
            id="scapula_1"
            data-name="scapula 1"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls5"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls5,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls5,
            }}
            d="M214.68,151.37c1.32-7.14,3.7-16.42,8.2-26.66,5.48-12.49,11.63-20.76,12.99-22.56,2.73-3.62,9.66-12.67,20.51-20.51,13.47-9.73,22.59-10.06,56.73-25.29,3.7-1.65,10.3-4.7,14.35-11.62,1.37-2.36,4.75-9.09,2.73-17.09-1.88-7.43-7.3-11.47-10.25-13.67-6.94-5.17-14.28-6.17-19.14-6.84-8.15-1.11-8.97.72-17.77-.68-8.67-1.38-10.95-3.64-12.99-2.05-4.37,3.4,4.53,14.97-.68,24.61-1.42,2.64-5.52,4.98-13.67,9.57-12.1,6.82-21.72,10.97-23.24,11.62-2.97,1.28-9.65,4.08-23.24,8.89-25.21,8.92-25.51,7.49-33.49,11.62-10.89,5.63-17.88,12.19-25.29,19.14-8.58,8.05-8.27,9.55-23.92,26.66-7.88,8.62-12.63,13.81-19.82,20.51-9.84,9.18-18.07,15.41-23.92,19.82-6.78,5.11-15.48,11.27-25.97,17.77"
          />
          <path
            id="scapula_2"
            data-name="scapula 2"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls4"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls4,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls4,
            }}
            d="M192.84,115.16c6.28-5.05,11.56-6.08,15.04-6.15,2.42-.05,5.26.65,10.94,2.05,9.87,2.43,10.92,3.63,15.04,3.42,2.31-.12,7.39-.37,10.94-4.1,5.15-5.41,3.03-13.86,2.73-15.04-1.54-6.1-5.7-9.65-8.89-12.3-7.48-6.21-15.36-7.94-23.24-9.57-11.91-2.47-17.86-3.7-25.29-1.37-13.26,4.16-19.72,15.92-21.87,19.82-2.26,4.11-7.69,13.97-5.47,25.29,1.93,9.86,8.3,13.89,6.15,17.77-.58,1.04-2.33,3.08-18.46,2.73-9.49-.2-15.68-1.06-25.29-2.05-18.6-1.91-16.43-.88-22.56-2.05-11.98-2.29-21.04-6.4-28.03-9.57-2.54-1.15-2.97-1.43-22.56-10.94-8.42-4.09-12.69-6.12-15.72-5.47-8.56,1.84-12.67,14.11-15.04,21.19-2.9,8.64-3.14,15.62-3.42,25.97-.23,8.64-.47,17.26,1.37,27.34,1.36,7.46,3.65,14.63,14.35,35.54,13.58,26.54,15.69,26.34,21.87,40.33,5.95,13.47,4.99,15.91,12.99,42.38,9.42,31.15,10.96,28.46,18.46,54,9.24,31.44,5.91,32.15,15.04,59.47,4.56,13.65,7.53,19.88,10.25,33.49,1.79,8.93,2.22,14.81,5.47,26.66,4.22,15.38,7.58,20.39,11.62,23.92,5.06,4.43,10.73,6.12,14.35,6.84"
          />
          <path
            id="scapula_3"
            data-name="scapula 3"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls4"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls4,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls4,
            }}
            d="M140.21,504.75c2.01,0,11.77,1.13,15.04,0,2.89-1,8.5-3.56,15.72-13.67,4.45-6.22,5.1-9.14,9.57-17.77,6.42-12.41,8.96-13.87,12.3-20.51,6.09-12.1,4.02-19.9,5.47-36.91.94-11.1,4.73-24.49,12.3-51.27,6.72-23.79,7.21-20.93,14.35-45.8,8.71-30.36,7.68-33.6,14.35-49.9,5.65-13.82,9.14-22.34,15.72-33.49,8.13-13.78,15.73-22.68,13.67-33.49-.54-2.82-1.84-6.31,0-9.57,2.33-4.14,6.98-3.01,12.3-7.52.46-.38,5.49-4.77,6.84-11.62,1.25-6.34-1.3-11.56-4.1-17.09-3.91-7.71-6.97-9.82-12.99-17.77-8.02-10.59-5.63-10.89-13.67-21.87-11.34-15.48-19.07-18.94-21.19-19.82-4.89-2.03-9.34-2.58-12.3-2.73"
          />
          <path
            id="scapula_4"
            data-name="scapula 4"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls4"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls4,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls4,
            }}
            d="M268.71,190.6c-20.27-5.5-49.07-61.18-25.09-90.78"
          />
          <path
            id="scapula_5"
            data-name="scapula 5"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M56.8,174.61c131.24-8.88,133.95-34.48,185.36-85.37"
          />
          <path
            id="scapula_6"
            data-name="scapula 6"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M197.61,110.72c9.16,0,20.51-12.39,20.51-27.7"
          />
        </g>
        <g id="ribs">
          <path
            id="ribs_1"
            data-name="ribs 1"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M17.68,480.15c64.73,11.63,132.47,46.06,156.01,74.51"
          />
          <path
            id="ribs_2"
            data-name="ribs 2"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M22.62,456.23c83.91,19.97,166.2,62.45,184.56,94.33"
          />
          <path
            id="ribs_3"
            data-name="ribs 3"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M19.21,401.55c94.56,8.8,186.84,76.9,215.32,149.01"
          />
          <path
            id="ribs_4"
            data-name="ribs 4"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M25.36,376.25c108.16,15.16,213.9,93.87,233.09,178.41"
          />
          <path
            id="ribs_5"
            data-name="ribs 5"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M25.36,329.77c124.56,27.46,223.88,121.19,222.84,224.89"
          />
          <path
            id="ribs_6"
            data-name="ribs 6"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M19.89,302.43c136.69,33.3,245.67,136.57,244.62,252.23"
          />
          <path
            id="ribs_7"
            data-name="ribs 7"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M11.69,258.69c161.95,30.01,266.56,169.83,207.8,295.97"
          />
          <path
            id="ribs_8"
            data-name="ribs 8"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M17.68,240.23c160.02,24.81,262.06,152.87,225.73,314.43"
          />
          <path
            id="ribs_9"
            data-name="ribs 9"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M4.85,198.54c169.61,39.5,310.07,207.74,147.65,301.44"
          />
          <path
            id="ribs_10"
            data-name="ribs 10"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M8.28,176.66c153.27,17.35,307.65,192.23,195.49,333.57"
          />
          <path
            id="ribs_11"
            data-name="ribs 11"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M19.89,145.21c90.39,31.46,278.38,147.18,122.31,231.05"
          />
          <path
            id="ribs_12"
            data-name="ribs 12"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M4.85,122.66c160.64,56.73,292.69,167.59,168.84,293.24"
          />
          <path
            id="ribs_13"
            data-name="ribs 13"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M.75,74.13c141.45,76.64,267.32,131.99,48.53,255.65"
          />
          <path
            id="ribs_14"
            data-name="ribs 14"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M4.85,93.96c137.59,46.49,197.24,118.25,28.03,187.29"
          />
          <path
            id="ribs_15"
            data-name="ribs 15"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M.75,74.13c85.55,20.19,136.3,84.82,26.31,130.36"
          />
          <path
            id="ribs_16"
            data-name="ribs 16"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M3.14,175.22c25.07-8.49,56.96-63.34,3.42-97.75"
          />
          <path
            id="ribs_17"
            data-name="ribs 17"
            class="Rechtes_Schulterblatt_vd_stehend2024_cls6"
            style={{
              fill: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
              stroke: props.colors.Rechtes_Schulterblatt_vd_stehend2024_cls6,
            }}
            d="M74.2,110.71c-3.67-10.89-38.5-51.07-71.06-72.81"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="scapula_overlay"
          data-name="scapula overlay"
          class="Scapula2024Fill"
          style={{
            fill: props.colors.Scapula2024Fill,
            stroke: props.colors.Scapula2024Fill,
          }}
          d="M282.27,153.42l-32.85-46.28c-.15-12.64-4.44-22.43-16.73-26.72-.91-.68-1.9-1.33-2.99-1.91-8.27-4.43-23.07-12.34-39.65-6.84-17.29,5.74-24.09,21.94-24.61,23.24-2.14,5.34-4.16,16.25-.68,27.34,1.85,5.91,4.71,9.93,2.73,12.99-1.69,2.61-5.75,2.74-12.3,2.73-13.58-.02-20.37-.03-24.61-.68-27.76-4.25-41.78-6.11-52.63-11.62-10.46-5.3-21.56-9.26-32.13-14.35-4.21-2.03-7.69-3.77-11.62-2.73-1.66.43-8.73,2.78-13.67,23.92-7.29,31.17.68,57.8,2.05,62.2,5.27,16.9,13.57,30.69,15.72,34.18,7.23,11.74,11.99,15.88,17.09,28.03,3.67,8.74,3.1,11.1,6.84,25.29,4,15.15,5.44,15.45,15.04,43.75,6.05,17.85,9.08,26.78,11.62,36.91,3.77,15.08,3.42,18.45,8.2,36.23,3.69,13.73,7.52,25.29,7.52,25.29,5.49,16.55,6.99,17.66,8.89,26.66,2.72,12.85.31,13.68,3.42,24.61,2.03,7.15,4.59,15.73,12.3,22.56l.02-.03c2.65,2.35,7.59,6.73,15.04,7.52,12.1,1.29,20.98-7.93,22.56-9.57,1.75-1.82,1.66-2.18,8.2-13.67,5.15-9.06,8.38-14.73,12.3-21.19,6.13-10.09,6.99-10.61,8.2-14.35,2.29-7.06,1.5-12.15,1.37-18.46-.13-6.12,1.28-13.88,4.1-29.39,1.9-10.43,4.07-18.65,17.09-60.15,13.29-42.39,10.4-31.82,14.35-45.11,8.04-27.02,8.17-30.92,13.67-41.01,3.04-5.58,7.57-12.78,15.72-28.03,6.48-12.13,8.11-15.99,7.52-21.19-.52-4.6-2.67-9.35,0-12.99,1.98-2.68,5.02-2.63,5.47-4.78,17.56-5.46,14.3-18.29,7.44-32.4Z"
        />
        <g id="rib_overlay" data-name="rib overlay">
          <path
            id="rib_overlay_1"
            data-name="rib overlay 1"
            class="ribs2024Fill"
            style={{
              fill: props.colors.ribs2024Fill,
              stroke: props.colors.ribs2024Fill,
            }}
            d="M21.58,127.14c45.24,20.69,87.53,41.05,114.38,59.7-6.03-22.98-23.97-41.58-48.95-57.59l22.98,6.1c40.74,28.4,59.16,54.03,57.54,77.18,41.21,38.35,58.49,75.64,53.65,111.93l-6.98,26.5c-4.36,17.52-11.84,32.84-23.1,45.5l9.11,8.2-3.3,23.8c-29.19-34.59-66.66-60.33-110.55-79.21l-12.16-30.96c33.08,15.5,61.53,31.46,83.17,48.09,5.89-2.16,14.42-9.83,22.78-17.13-17.96-20.21-39.99-39.54-71.99-56.7l-34.63,23.24-13.62-46.52-9.16-22.74c63.95,17.01,109.59,48.08,143.07,86.61l4.57-11.42c-12.77-19.11-31.71-38.65-56.48-58.62l-15.59,14.7c-14.35-8.66-29.17-16-44.59-21.7,10.56-4.98,19.59-10.47,25.97-16.86-27.48-15.14-54.45-27.99-79.69-34.47l-9.32-28.12c73.85,15.66,134.46,52.09,181.52,113.17-2.51-16.26-13.13-35.64-36.31-59.84-2.76,6.5-5.4,12.17-7.44,13.81l-31.23-23.82c3.79-2.47,6.58-7.97,8.99-14.64l-55.23-34c-13.11,13.15-31.81,25.38-50.98,33.43-6.33-14.98-10.4-27.65-10.51-38.92,16.13-14.84,19.85-34.67,16.7-57.27l49.93,19.58c7.41,6.89,6.94,17.97,3.06,30.88l-65.62-31.9Z"
          />
          <path
            id="rib_overlay_2"
            data-name="rib overlay 2"
            class="ribs2024Fill"
            style={{
              fill: props.colors.ribs2024Fill,
              stroke: props.colors.ribs2024Fill,
            }}
            d="M191.32,452.05c-28.29-23.99-60.17-43.02-95.63-57.12l10.19,30.91c27.98,12.93,52.22,29.48,73.61,48.8l11.83-22.6Z"
          />
        </g>
      </g>
    </svg>
  );
}
