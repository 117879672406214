import style from "./style.scss";

export default function LinkeSulcusUlnarisSXsitzend2217(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 479.78 497.58">
      <g id="Backround">
        <path
          id="backround"
          class="Linke_Sulcus_ulnaris_ax_sitzend2217_cls3"
          d="M6.67,57.75c2.21-4.64,9.02-18.54,23.19-26.97,21.57-12.82,39.68-.79,71.19.54,45.48,1.92,45.28-21.53,110.02-29.12,21.12-2.48,49.18-5.44,79.82,7.01,18.39,7.47,13.05,10.48,40.45,25.89,31.84,17.91,58.75,24.93,101.39,49.08,5.47,3.1,10.75,6.22,15.65,12.28,12.05,14.92,11.34,34.65,10.27,56.21-1.42,28.7-7.48,36.87-.57,49.61,5.81,10.71,12.58,9.52,18.03,19.85,7.06,13.37,2.95,29.1-4.17,48.66-12.77,35.1-21.1,43.68-31.66,68.48-20.5,48.17-24.81,99.24-24.81,99.24-2.37,28.05.45,42.83-10.79,52.31-11.53,9.74-27.88,5.58-48.54,2.16-16.93-2.81-110.15-17.28-168.27-1.62-15.54,4.19-27.26,4.78-50.7,5.93-15.95.79-22.63.08-26.97-4.31-10.25-10.4-.89-34.91,1.62-42.07,18.74-53.47,10.2-153.42-21.57-213.57C61.09,182.11,14.84,165.19,2.9,111.68c-2.2-9.83-6.77-31.74,3.78-53.93Z"
        />
      </g>
      <g id="Sulcusulnarisoverview">
        <path
          id="upperarmoutline"
          class="Linke_Sulcus_ulnaris_ax_sitzend2217_cls4"
          d="M309.14,463.43c-.06-26.79,3.71-53.5,13.07-80.11,8.9-53.2,24.73-112.87,43.18-175,17.99,6.43,32.69,3.66,45.45-4.55,17.97-1.56,27.15-9.51,26.7-24.43,10.78-38.23,10.43-68.58-12.5-82.95l-80.11-37.5c-9.87-13.46-23.92-11.3-43.75,12.5-20.62,20.9-49.49,19.22-83.52,3.41-43.13-35.21-74.06-50.34-85.23-32.95-12.09,20.99-25.67,24.78-40.34,15.91-21.4-11.98-42.05-14.04-60.8,9.09-14.28,22.92-17.07,45.84-5.11,68.75,46.72,42.65,80.5,91.15,97.73,147.16,15.67,57.68,10.12,126.09-7.39,200.57"
        />
        <path
          id="olecranonoutline"
          class="Linke_Sulcus_ulnaris_ax_sitzend2217_cls4"
          d="M179.03,479.34l-3.41-246.59c-4.49-27.66-16.64-53.59-34.59-78.2-5.49-7.53-8.9-16.44-9.56-25.74-1.46-20.45,4.6-45.18,14.06-71.85,1.98-5.57,5.11-10.69,9.27-14.88,24.47-24.67,57.77-32.48,98.66-25.81,40.62.46,57.45,13.51,47.16,40.91-1.19,13.15,4.25,25.55,13.64,37.5,21.08,21.24,22.94,47.67,13.07,77.27-10.05,19.15-16.22,38.44-15.91,57.95-16.54,86.46-26.6,168.5-26.7,243.75"
        />
        <path
          id="olecranon2"
          class="Linke_Sulcus_ulnaris_ax_sitzend2217_cls4"
          d="M172.78,78.2c-5.07,32.43-6.09,63.14,1.14,90.34,4.99,22.33,6.83,43.06.57,59.66"
        />
        <path
          id="olecranon1"
          class="Linke_Sulcus_ulnaris_ax_sitzend2217_cls4"
          d="M300.62,57.18c-16.42,13.07-35.41,20.22-61.36,11.36l-81.25-28.98"
        />
        <path
          id="upperarm1"
          class="Linke_Sulcus_ulnaris_ax_sitzend2217_cls4"
          d="M365.39,208.32c11.38-15.88,33.45-32.03,59.66-48.3"
        />
        <path
          id="forearmoutline"
          class="Linke_Sulcus_ulnaris_ax_sitzend2217_cls4"
          d="M392.66,482.75l13.07-85.23c11.24-25.59,17.37-48.92,18.18-69.89,33.94-46.9,49.4-83.94,34.09-104.55-12.83-6.05-25.69-8.79-38.64-.57-26.16,2.59-53.01,2.38-80.68-1.14-24.3-7.96-37.33,1.49-33.52,36.93,16.19,63.5,23.28,139.47,26.14,221.59"
        />
        <path
          id="forearm3"
          class="Linke_Sulcus_ulnaris_ax_sitzend2217_cls4"
          d="M380.73,468.54c6.72-58.3,17.8-108.99,36.36-146.59"
        />
        <path
          id="forearm2"
          class="Linke_Sulcus_ulnaris_ax_sitzend2217_cls4"
          d="M346.07,456.04c-1.81-70.09-7.32-128.13-18.18-168.75"
        />
        <path
          id="forearm1"
          class="Linke_Sulcus_ulnaris_ax_sitzend2217_cls5"
          d="M458,223.09c-21.16,18.11-46.65,27.03-77.84,23.86-23-1.85-43.28-9.86-59.66-26.7"
        />
      </g>
      <g id="Highlights">
        <path
          id="epicondylehighlight2"
          class="Epicondylen2217Fill"
          style={{
            fill: props.colors.Epicondylen2217Fill,
            stroke: props.colors.Epicondylen2217Fill,
          }}
          d="M44.14,152.95c-2.24-.84-2.5-2.58-10.91-10.8-2.95-2.88-5.44-5.13-7.05-6.56-2.6-4.78-6.9-14.18-7.45-26.81-.54-12.34,2.75-21.89,4.87-26.87,1.39-4.37,4.67-12.65,12.34-20.26,6.01-5.95,12.27-9.08,16.25-10.69,4.38-1.28,10.97-2.53,18.67-1.44,5.52.78,10.86,2.65,14.06,4.36,4.74,2.54,6.06,3.29,6.06,3.29,1.13.65,2.03,1.19,3.42,1.87,2.45,1.21,3.54,1.46,4.06,1.77,4.63,2.76-2.8,20.66-2.8,20.66-10.86,26.17-16.29,39.25-19.15,43.42-2.35,3.43-21.93,31.97-32.37,28.06Z"
        />
        <path
          id="epicondylehighlight1"
          class="Epicondylen2217Fill"
          style={{
            fill: props.colors.Epicondylen2217Fill,
            stroke: props.colors.Epicondylen2217Fill,
          }}
          d="M400.92,113.3c-14.35,31.12-19.27,45.87-19.27,45.87-12.49,37.39-13.46,48.54-9.23,51.23,1.21.77,3.43.95,7.88,1.32,5.69.48,10.03-.17,11.46-.4,1.12-.19,4.45-.78,8.49-2.29,1.9-.71,6.51-2.43,8.97-4.25.21-.15.79-.6,1.69-.94,1.57-.61,2.94-.46,3.24-.44,2.21.15,13.16-1.71,19.05-9.05,4.22-5.26,4.5-11.67,4.35-15.01,1.45-4.89,3.17-11.49,4.59-19.39,1.41-7.84,2.51-14.2,2.34-22.78-.24-11.62-2.65-18.71-3.22-20.32-1.02-2.9-2.38-6.58-5.41-10.69-1.01-1.37-4.6-6.02-10.86-9.8-3.82-2.3-5.69-3.5-7.4-3.56-6.88-.24-12.53,11.54-16.66,20.5Z"
        />
        <path
          id="sulcusnerviulnarishighlight"
          class="Rinne2217Fill"
          style={{
            fill: props.colors.Rinne2217Fill,
            stroke: props.colors.Rinne2217Fill,
          }}
          d="M111.08,66.08c-14.01,1.63-26.91-11.12-26.07-13.24.04-.11.19-.47,1.21-.81,5.44-1.83,9.36,5.61,17.08,6.67,6.73.92,12.87-3.48,14.47-4.64,5.86-4.2,7.74-9.75,10.91-9.11,1.47.3,2.69,1.81,3.03,3.24,1.39,5.71-8.99,16.54-20.65,17.9Z"
        />
      </g>
    </svg>
  );
}
