import style from "./style.scss";

function RechtesDaumenGrundGelenkDpSitzend(props) {
  return (
    <svg
      id="Thumb"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 244 412.3"
    >
      <g id="Background">
        <g id="whitebackground">
          <path
            id="whitebackground-2"
            class="rechtes_daumengrundgelenk_dp_sitzend1308_cls2"
            d="M1,377.7l15.3-124c39-9.5,48.5-50.4,46.8-103.2-.3-9.4-1-18.7-2.2-28-2.4-18.2-1.2-34.6,3.9-49.1,2.7-7.4,5.3-14.9,7.4-22.6,6.6-24.2,16.6-38.8,30-43.7,21.1-10.1,37.3-7.5,50.9,2.4,21.2,27.2,29.5,64,24,111.2-9.5,42.8-5.6,88.5,6.2,135.9,2.1,8.3,6,16,11.2,22.8,19.9,26.1,35.2,75.7,49.2,132.4"
          />
        </g>
      </g>
      <g id="Thumb1">
        <g id="thumbbones">
          <g id="Daumen">
            <path
              id="middlehandbone3"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls3"
              d="M69.7,388.3l7.8-45.7c.4-2.6,1.1-5.2,1.8-7.8,3.6-13,1.7-23.2-2.7-31.5-1.6-3-2.8-6.3-3.3-9.7-1.6-10.4,1.5-20.6,7.2-30.7.5-.9.8-2,.8-3v-1.2c0-1.5.8-2.8,2.2-3.3,17.8-6.9,38.1-3.9,59.7,4.6,6.7,5.6,10.3,19.6,10.3,42.8-.5,4.7-2.4,9.1-6.6,12.7-2.3,2-3.9,4.7-4.6,7.7-4.9,22.3-8.5,46.9-11.2,73"
            />
            <path
              id="Basalphalanx4"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls3"
              d="M148.2,253.2h0c-9-2.7-31.8-.3-30.3-.6,1.5-.3-20.4,4.2-20.4,4.2-8.1-.6-14.5-4.7-17.3-15.6-.3-1.3-.7-2.6-1.1-3.8-1.5-4.5-.2-8.2,2.4-11.6,3-3.8,5.5-8,7.3-12.5,5.7-14.4,8.5-29.8,8.9-45.9,0-4.5-.7-9-2.3-13.2l-4.6-11.8c-1.3-3.3-1.7-6.8-1.1-10.3,2.1-13.6,5.9-21.6,12.6-19.5,12.2,5.8,27.5,6.9,44.8,5.3,2.3-.2,4.6,1.3,5,3.6.8,4.5-1.1,11.7-4.8,20.8-1.7,4-3.1,8.2-4,12.5-5,22.9-4,44.9,7.8,65.3l6.2,15.5c1.4,3.7,2.3,7.3,2,10.5-.5,5.3-5.9,8.7-11.1,7.1h0Z"
            />
            <path
              id="Thumbendphalanx4"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls3"
              d="M145.5,58.9h0c-1,20.5,2.2,34.9,10.5,41.7,1.9,1.5,2.8,4,2.2,6.3-1.1,4.7-4.4,8-11.1,9.1-19,2.5-36.2.5-51-7.4-6.8-4.1-9.5-9-8.3-14.8.3-1.2,1.3-2.2,2.5-2.5,3.1-.7,6.1-2.7,9-6,4.4-6.3,6.7-13.5,8.2-21.1,1.4-4.6.4-8.3-2-11.4-2-2.7-3.1-6.1-2.8-9.5,0-2.5.8-5.1,1.9-7.7,3.9-10.9,10.2-13.3,17.5-12.3,1.9.2,3.9.3,5.9.1,6.3-.6,11.4,2.5,16.1,6.8.9.9,1.5,1.9,1.7,3.1.4,2,1.1,3.7,2.4,5,4.4,6.4,4.4,12.2-1.3,17.6-.8.8-1.4,1.9-1.4,3h0Z"
            />
            <path
              id="middlehandbone2"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls5"
              d="M122.4,394.7c-1.6-27.8-.5-54,4.9-77.8.9-8.3,5.1-11.6,10.8-12.8,5.5-2,7.5-5.5,8.1-9.6"
            />
            <path
              id="middlehandbone1"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls5"
              d="M85.5,382.7c5.3-25.3,7-46.9,5.4-65-.4-4.3-2.1-8.3-4.7-11.7-3.7-4.8-5.4-9.6-5.2-14.4,2.6-14.7,2.7-24.9,0-30.5"
            />
            <path
              id="Basalphalanx3"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls5"
              d="M143.7,252.1c-8.9-7.1-25.4-9.8-52.2-6.2"
            />
            <path
              id="Basalphalanx2"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls5"
              d="M150.6,236.9c-14.4-19.2-21.9-48.7-19.3-92.8"
            />
            <path
              id="Basalphalanx1"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls5"
              d="M87.5,229.7c9.1-17.1,15.4-34.6,18.7-52.6,3.1-10.3,2.6-22.8.8-36"
            />
            <path
              id="Thumbendphalanx3"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls5"
              d="M95.9,104.2c3.9-2.1,19.3,8,25.9,6.2,10.8-1.7,19.5-2,25,0"
            />
            <path
              id="Thumbendphalanx2"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls5"
              d="M132.2,32c5.3,5.8,7.6,12.8,6.1,21.3-1.3,14.3.9,25.5,5.4,34.6"
            />
            <path
              id="Thumbendphalanx1"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls5"
              d="M102.6,86c8-7.8,13.4-18.2,15.7-31.8-.9-12,2.4-19,8.3-22.8"
            />
            <path
              id="sasamoid2"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls5"
              d="M106.3,275.3c-7.9,9.4-14.9,15.8-18,9.3-.8-2.9-2-4.6-3.9-4.9-3.5-7.7-.9-11.3,3.1-14,7.1-5.9,12.6-5.9,17.4-3.3,4.4,2.8,6.3,6.5,1.4,12.9h0Z"
            />
            <path
              id="sasamoid1"
              class="rechtes_daumengrundgelenk_dp_sitzend1308_cls5"
              d="M127.8,264h0c7-3.3,12.9-4.9,17.5-4.3,3,.3,5.7,2,7.2,4.6,2.1,3.5,1.2,7.3-3.7,11.6-9.6,7.2-18.1,10.6-24.2,3.6-4.5-5.6-4.3-10.9,3.2-15.5h0Z"
            />
            <g id="Skinline">
              <path
                id="Skinline3"
                class="rechtes_daumengrundgelenk_dp_sitzend1308_cls3"
                d="M242.5,406.2c.5,1.9,1,3.8,1.5,5.8"
              />
              <path
                id="Skinline2"
                class="rechtes_daumengrundgelenk_dp_sitzend1308_cls4"
                d="M28.1,250.2c29.5-14.3,36.9-52,35.4-99.2-.3-9.4-1-18.7-2.2-28-2.4-18.2-1.2-34.6,3.9-49.1,2.7-7.4,5.3-14.9,7.4-22.6,6.6-24.2,16.6-38.8,30-43.7,21.1-10.1,37.3-7.5,50.9,2.4,21.2,27.2,29.5,64.1,24,111.2-9.5,42.8-5.6,88.5,6.2,135.9,2.1,8.3,6,16,11.2,22.8,17.8,23.3,31.9,65.5,44.7,114.7"
              />
              <path
                id="Skinline1"
                class="rechtes_daumengrundgelenk_dp_sitzend1308_cls3"
                d="M22.5,252.4"
              />
            </g>
            <g id="Nailline">
              <path
                id="Nailline2"
                class="rechtes_daumengrundgelenk_dp_sitzend1308_cls3"
                d="M164.1,38c0-2-.3-3.9-.6-6"
              />
              <path
                id="Nailline1"
                class="rechtes_daumengrundgelenk_dp_sitzend1308_cls4"
                d="M92.1,27.7c-1,11.5,1.6,20.4,7.7,26.9,4.6,5,11,7.9,17.8,8.7l18.3,2.1c17.9,1.7,25.7-5,27.8-16.5"
              />
            </g>
          </g>
        </g>
        <g id="Highlights">
          <ellipse
            id="basejointhighlight"
            class="daumengrundgelenk1308fill"
            style={{
              fill: props.colors.daumengrundgelenk1308fill,
              stroke: props.colors.daumengrundgelenk1308fill,
            }}
            cx="117.6"
            cy="254.1"
            rx="42.5"
            ry="11.6"
          />
        </g>
      </g>
    </svg>
  );
}

export default RechtesDaumenGrundGelenkDpSitzend;
