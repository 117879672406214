import style from "./style.scss";

function IliosakralgelenkeVdLiegend1204(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 423 392">

<g id="Becken_cut" data-name="Becken cut">
  <g id="Beckenparts">
    <path id="BG" class="Iliosakralgelenke_vd_liegend1204_cls3" d="M23.7,348.3c-15.6,0,357.6,2.7,368.3,2.7.4-72.5,4.2-225.2,4.2-246.3-9.2,0-25.5,8.3-31,8.3-48.6,15.1-52.1,38.2-59.3,41.3-2.4,1-10.5-6.4-25.3,7.4-5,.3-11.3-7.4-10.1-12.6,1.5-6.2,13.6-8.6,21.4-10.1,7.4-1.5,8.8-.5,12.6-2.5,8-4.3,12.4-14.8,10.1-17.6-1.5-1.8-4.4.6-13.8,1.3-8.6.5-9.2-1.1-15.1,0-7.7,1.5-10.9,5.3-12.6,3.8-2.4-2.1,4.3-9.9,1.3-15.1-2.9-4.9-11.6-2.8-12.6-6.3-.8-2.5,3.8-4.7,10.1-11.3,8.9-9.4,7.7-13.5,12.6-15.1,8.3-2.8,14.7,8,21.4,5,4.9-2.3,7.5-10.7,5-16.3-2.8-6.3-11.6-7.4-30.2-10.1-40.2-5.8-30.6-5.3-41.5-6.3-34.7-3.1-51.9-4.8-70.4-1.4,0,0-22.4,4.1-37.7,3.9-8.7,0-13.1-.3-15-1-1,0-1.9-.6-2.8,0-2.9,1.5-2.5,12.2,2.5,16.3,3.9,3.1,7.7,0,17.6,0,.4,0,25.5-1.1,30.2,8.8.9,1.9-5.5,14.1-5,13.8,1.5,3.9,14.3,3,15.1,6.3.8,3.4-11.4,9.1-18.9,12.6-6.5,3-9.8,4.5-12.6,5-14,2.3-21.9-7.8-27.7-3.8-3.8,2.5-4.5,9.4-2.5,13.8.6,1.4,3,5.9,21.4,10.1,16.2,3.8,22.9,2.1,25.1,6.3,3.4,6.2-2,18.1-7.5,18.9-4.5.6-6.8-6.3-15.1-8.8-8.3-2.5-11.4,1-12.6,1.3-3.9,1-8.3-10.3-15.1-17.6-4.1-4.5-10.1-9.2-22.6-17.6-19.5-13-29.2-19.5-39-21.4-6.8-1.3-10.3-.8-26.2,2.2"/>
    <g id="Beckenboneparts2">
      <path id="Beckenbonepart43" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M123.5,150.5c-7-1.5-8.9-9.3-15.1-17.6-3.6-4.9-10.1-9.2-22.6-17.6-19.5-13-29.2-19.5-39-21.4-6-1.2-13.5-.2-26.4,2.2"/>
      <path id="Beckenbonepart42" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M396.3,104.7c-13.3,1.7-23.8,5.6-33.7,9.3-20.9,7.8-31.4,11.7-39,21.4-3.5,4.5-8.8,8.8-17.6,18.9-15.8,18.1-21,27.2-22.6,29.9-3.8,6.8-6.8,12.1-8.8,20.1-2,8.2-5.5,22.1,1.3,32.7,6.4,10.2,15.7,6.8,23.9,18.9,3.6,5.4,4.8,10.4,6.3,17.6,2.5,11.7,2.1,21.9,1.3,28.9"/>
      <path id="Beckenbonepart41" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M307.1,302.3c9.7-2.3,7,6,21.4,5.3,10.8-.5,14.2-2.4,16.3,0,2.9,3.4-.6,10.7-5,20.1"/>
      <path id="Beckenbonepart40" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M276.6,347.8c1.5-2.3,3-4.7,4.2-7.6,5.4-12.8,1.3-19.7,7.5-26.4,3.6-3.9,13.1-10.3,18.9-11.6"/>
      <path id="Beckenboneparts39" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M87.6,347c-2.5-5.4-5-11.4-7.1-18-4.7-14.5-3.9-19.9-1.3-22.6,3.3-3.4,7.5-1.1,22.6-3.8,1.6-.3,5.5-2.1,11.1-2.4,2.3-.1,2.1.1,2.8-.1,4.9-1.9.5-15.6,5-31.7,1.5-5.4,3.3-11.6,8.8-16.3,6-5.3,10.4-3.4,15.1-7.5,6.8-6,5.9-17.1,5-28.9-.6-8.4-1.8-23.6-11.3-40.2-8.2-14.2-17.5-20.9-15.1-24.9,1.8-2.9,8.6-2.4,12.6-1.3,8.9,2.4,10.7,9.4,15.1,8.8,5.5-.8,11.2-12.8,7.5-18.9-2.6-4.4-8.4-2.4-25.1-6.3-18.4-4.1-20.7-8.7-21.4-10.1-2-4.4-1.3-11.3,2.5-13.8,5.8-4,13.7,6,27.7,3.8,2.8-.5,6-2,12.6-5,7.4-3.5,19.6-9.2,18.9-12.6s-13-1.1-15.1-6.3c-1.8-4.5,6.4-8.8,5-13.8-1.8-6.4-17.9-9.4-30.2-8.8-9.7.5-13.8,3.1-17.6,0-5-4.1-5.4-14.8-2.5-16.3.9-.4,1.8,0,2.6.3,2,.8,6.3.9,15,1,13.2.3,25.5-1.9,37.7-3.9h0c9.8-1.6,18.5-2,29.2-1.8,18.6.4,29.8,2.3,41.2,3.1.8,0,2.8.3,5.5.6,2.8.4,4.9.9,5.4,1,6,1.3,20.6,3,30.6,4.8,18.5,3.1,27.7,4.7,30.2,10.1,2.5,5.5-.1,14.1-5,16.3-6.7,3-13.1-7.8-21.4-5-4.9,1.6-3.6,5.7-12.6,15.1-6.3,6.7-10.8,8.8-10.1,11.3,1,3.5,9.7,1.4,12.6,6.3,3,5.2-3.6,13.1-1.3,15.1,1.6,1.4,4.9-2.3,12.6-3.8,5.9-1.1,6.5.5,15.1,0,9.4-.6,12.3-3,13.8-1.3,2.4,2.8-2,13.2-10.1,17.6-3.8,2-5.2,1-12.6,2.5-7.8,1.5-19.9,3.9-21.4,10.1-1.3,5.2,5,12.8,10.1,12.6,4.4-.3,5.2-6.4,11.3-8.8,4.8-1.9,9.8-.5,13.8,1.3"/>
      <path id="Beckenboneparts38" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M116,300.2c2.9,1.4,15.6,4.5,18.9,8.6,2.9,3.4,3.4,5.9,6.3,11.3,4.8,9.2,6.3,8,8.8,13.8,2.8,6.3,2.4,10.7,2.5,14.3"/>
    </g>
    <g id="Beckenboneparts">
      <path id="Beckenboneparts37" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M305.9,154.3c-.1,4.4.4,10.4,3.8,16.1,3,5,6.7,6.7,12.6,11.3,5.2,4,13.6,10.6,17.6,17.6,4.3,7.3,2.8,16.6,0,35.2-2.1,14.1-4.9,22.4-10.1,37.7-4,11.9-6.3,16.6-11.3,20.1-6.2,4.3-7.9,13.7-11.3,10.1-2.3-2.4-8.6-13.8-7.5-20.1.9-6.2,1.5-15.1,0-26.4"/>
      <path id="Beckenboneparts36" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M123.2,150.4c-.1,9.6-3.1,15.2-5.9,18.6-3.4,4.1-6,4.5-12.6,10.1-5.2,4.4-9.9,8.4-12.6,13.8-1.9,3.9-2.4,7.5,1.3,31.4,2.6,17.9,3.3,17.9,3.8,25.1,1.1,14.5-.9,15.8,1.3,22.6,3.9,12.8,15.5,23.6,20.1,21.6,1.8-.8,13.5-9.1,1.5-21.1"/>
      <path id="Beckenboneparts35" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M290.8,185.4c3.5-7.9,8.3-12.6,11.3-15.1,3.9-3.3,8.8-7.3,13.8-6.3,4.1.9,5.7,4.4,10.1,10.1,8.4,10.6,12.8,9.9,17.6,16.3,6.5,8.7,5.5,19.1,3.8,36.5-1.6,16.1-4.4,14.6-10.1,41.5-3.5,16.8-4,25-8.8,26.4s-5.9,2.6-10.1-2.5"/>
      <path id="Beckenboneparts34" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M128.6,175.3c-1-.9-13-11.4-25.1-7.5-6,1.9-9.8,6.7-12.6,10.1-1.6,2-5.8,7.3-7.5,15.1-1.3,5.8-.3,8.9,2.5,23.9,2.1,11.4,3.1,17.1,3.8,22.6,1.4,12.4.6,16,2.5,26.4,1.6,8.9,2.5,12.3,3.8,15.1,3,6.5,7,11.1,10.1,13.8"/>
      <path id="Beckenboneparts33" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M331.8,347.9c2.8-6.5,5.3-13.2,7.6-20.2h0c-5.8,10.4-10.3,12.4-13.5,12.3-5.3-.3-7.4-6.8-12.6-6.3-4.3.4-8.3,5.3-8.8,10.1-.1,1.6.2,2.8.7,4"/>
      <path id="Beckenboneparts32" class="Iliosakralgelenke_vd_liegend1204_cls1" d="M305.2,347.8c0,.1.1.2.2.3"/>
      <path id="Beckenboneparts31" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M117.4,348.3c-.8-.7-1.7-1.3-2.5-1.8-4-2.4-6.9-1.8-13.8-2.5-3.9-.5-9.4-1.4-16-3.6-.1,0-.3.1-.3.3,1,2.3,2.1,4.5,3.2,6.8"/>
      <path id="Beckenboneparts30" class="Iliosakralgelenke_vd_liegend1204_cls1" d="M88,347.5c0,.1.1.2.2.3"/>
      <path id="Beckenboneparts29" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M80.3,347.8c-.9-3.9-1.5-7.3-1.9-10.3"/>
      <path id="Beckenboneparts28" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M167.9,347.8c-.3-1-.4-2-.4-3,0-6.1,5-11.1,11.1-11.1s11.1,5,11.1,11.1-.2,2.2-.5,3.2"/>
      <path id="Beckenboneparts27" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M237.1,347.7c-.2-.9-.4-1.9-.4-2.9,0-6.1,5-11.1,11.1-11.1s11.1,5,11.1,11.1-.2,2.2-.5,3.2"/>
      <ellipse id="Beckenboneparts26" class="Iliosakralgelenke_vd_liegend1204_cls2" cx="259.2" cy="301" rx="11.4" ry="6.3"/>
      <ellipse id="Beckenboneparts25" class="Iliosakralgelenke_vd_liegend1204_cls2" cx="168.8" cy="296.8" rx="14.1" ry="5.2"/>
      <ellipse id="Beckenboneparts24" class="Iliosakralgelenke_vd_liegend1204_cls2" cx="159" cy="235.6" rx="8.6" ry="13.5"/>
      <ellipse id="Beckenboneparts23" class="Iliosakralgelenke_vd_liegend1204_cls2" cx="153.7" cy="208" rx="12.6" ry="8.8"/>
      <ellipse id="Beckenboneparts22" class="Iliosakralgelenke_vd_liegend1204_cls2" cx="270.1" cy="215" rx="14.3" ry="7"/>
      <path id="Beckenboneparts21" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M259.2,294.7c0,4.1,2.6-10.1,31.6,7.5"/>
      <path id="Beckenboneparts20" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M154.7,296.8c0,5.9-6.8,0-13.6,10.6"/>
      <path id="Beckenboneparts19" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M189.6,284.7s-11.1-26.4-57.6,0"/>
      <path id="Beckenboneparts18" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M236.7,291.5c0-3.8,11.1-26.9,54.1-6.8"/>
      <path id="Beckenboneparts17" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M266.9,252c-7.8-6.8-12.2-14.6-10.1-18.9,3.6-7.3,25.7-4.3,26.4,0,.5,2.5-6.2,7.4-39,17.6"/>
      <path id="Beckenboneparts16" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M166.3,208c0,3.9,4.7,14.2,23.3,0"/>
      <path id="Beckenboneparts15" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M159,249c0,4.4,9.8,8,19.9-2"/>
      <path id="Beckenboneparts14" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M236.7,199.2c0,8.8,8.6,15.8,19.1,15.8"/>
      <path id="Beckenboneparts13" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M166.3,50.7c1.8,4.4,1.4,10.7.5,17.6-.3,2.3-1.9,6.7-1.9,6.7l-1.1,5c-.8,3.1,1.4,6.3,4.5,6.8,2.6.5,6,.6,9.2-.4,2.5-.8,5-1.3,7.7-1.3h62.7c4.7,3.5,9.2,6.5,13.1,7.8,5.7,1.9,11.2-3.1,9.9-9.1-.5-2.4-1.6-4.7-3.1-7-1.8-2.6-2.6-5.8-2.6-9.1v-15.1"/>
      <path id="Beckenboneparts12" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M280.3,80c-21.1-4-32.3-2.5-32.6,5,.5,9.3,3.1,16.8,14.1,17.6-8-3.4-12.1,1-14.1,9.6-1.9,16-1,27,5.7,27.2,6.5-.5,13.7-4.1,19.2-17.2"/>
      <path id="Beckenboneparts11" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M163.7,74.6c9.7-3.1,15.7-.9,16.7,9.6.3,4.9-1.6,8.6-6.5,10.9-.3,3.8.1,7.3,3.6,9.9,1.8,1.4,2.9,3.5,2.9,5.7v21.8c.5,7.4-3.8,9.2-11.4,6.9-7.2-2.5-11.2-10.3-12.8-21.8-2.3-4.5-5.7-6-9.6-6.2"/>
      <path id="Beckenboneparts10" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M209.3,45.4l.8,16.1c.1,1.4.5,2.9,1.4,4,.8,1,1.6,1.8,2.9,2.4,1.9.9,4,1.1,5.9.5,2.3-.8,3.8-2.4,4.1-5.2v-16"/>
      <path id="Beckenboneparts9" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M173.8,95.1c32.3,2.3,64.6.8,96.8-1.9"/>
      <path id="Beckenboneparts8" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M159,123.7c-2.4-8.2-1.4-13.8,7.3-13.7,32.1,3,64.5,3.8,97.3,2.5,6.5,0,9.7,4.7,9.6,14.1.8,6.9-.3,12.1-2.5,16.1l-.4,23.9c0,2.6-3,4-5.2,2.5-7.4-5.7-16.1-8.4-26.2-7.9l-58.7-1.4c-3.9-.1-8.2,1.3-12.7,3.6-2.6,1.4-5.9-.4-6.2-3.4l-2.3-36.3h0Z"/>
      <path id="Beckenboneparts7" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M215.3,125.4c-.8,0-1.6,0-2.4,0-7,1.4-9.1-3.1-7.7-11.4,3-22.3,6.2-41.2,10.4-40.7,4.4,1.4,6.9,17.7,8.8,39.9.2,10.1-3.5,12.7-9.1,12.1h0Z"/>
      <path id="Beckenboneparts6" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M193.8,125.6c3.4-4.5,7-8.2,11.2-10.2"/>
      <path id="Beckenboneparts5" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M235.1,125.6c-3.5-5.7-7-9.8-10.6-12.6"/>
      <path id="Beckenboneparts4" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M167.7,163.5c3.6,3.8,12.4,6.4,28.4,7.2l54.7,2.6c5.7,0,10.6-1.3,14.3-4.1"/>
      <path id="Beckenboneparts3" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M254.6,145.1c5.5-4.3,12.3-4.5,20.5-.9"/>
      <path id="Beckenbonepart2" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M178,168.4c-4.3,3.8-9.3,5.9-15.6,5.4-2.6-.3-5.2-1.1-7-2.9-3.4-3.3-3.9-7.9-1.6-14,2.4-4.9,5.4-9.1,11.2-10.1,2.3-.4,4.7-.1,6.9.5,8.6,2.5,10.7,8,11.1,14.5.8,5.3,3.1,8.6,7,9.8,4.8,1.6,10.1-.6,12.4-5,1.4-2.4,2.8-5.5,4.1-9.1,1.6-4.3,2-9.1,1.3-13.6-1.6-9.2-.5-14.3,3.3-15.6,2.3-.8,4.7.5,5.8,2.6.9,1.8,1.8,4.1,2.5,7,.6,2.1,1.6,4.3,3.1,5.9,3.1,3.6,4.8,7.7,4.5,12.1-.1,4,1.4,7.8,4.5,10.3.8.5,1.5,1,2.1,1.5,3.8,2.1,8.6,0,9.7-4.1,1.9-6.7,7.2-10.1,17.1-9.3h8.3"/>
      <path id="Beckenboneparts1" class="Iliosakralgelenke_vd_liegend1204_cls2" d="M185.7,169.8c4.4-6.8,10.9-11.6,21.4-12.4,1.5-.1,3-.1,4.5.1,4.7.8,7.7,3.5,8.6,8.7.6,3.6,2.9,6.9,6,8.7,2,1.1,4.4,1.8,6.9,1.6,9.3.4,13.5-9.3,16.7-20.6"/>
    </g>
  </g>
</g>
<g id="ISG_Gelenke_Highlights" data-name="ISG Gelenke Highlights">
  <path id="ISG_Gelenke_Highlight2" data-name="ISG Gelenke Highlight2" class="ISG1204Fill"   style={{
          fill: props.colors.ISG1204Fill,
          stroke: props.colors.ISG1204Fill,
        }} d="M104.2,179.8c10.4-6.4,18.6-12.8,19.1-29.3-1.3-.1-8.1-3.1-9.1-8.6s5.4,14-17.8,30c-5.3,3.7-4.7,4.4-8.3,10.1-9.9,15.3-3.3,25.2-1,41.4s4.3,32.3,6.3,49c1.1,9.3,11.7,21.5,14.7,28.4.3.8,2.4.2,7.7-.7,4.2-.7,2.6-5.9,1.4-6.3-5-1.7-12.4-6.3-14-12.1-.9-3.2-4.2-6.4-4.6-9.8-.4-3.5-.6-9.2-.7-12.8-.5-15.8-4.7-28-5.6-43.2-.4-6.4-2.4-14.2-1-20.5,1.4-6.6,7.5-12.4,13-15.8h0Z"/>
  <path id="ISG_Gelenke_Highlight1" data-name="ISG Gelenke Highlight1" class="ISG1204Fill"   style={{
          fill: props.colors.ISG1204Fill,
          stroke: props.colors.ISG1204Fill,
        }} d="M349.1,205.4c-.5-7.5-.9-11.2-6.4-16.3-4.6-4.3-10-8.1-14.9-13s-17.1-25.2-13.3-31.1c.7-1.1-8.2,7.8-8.5,12-.3,6.6,3.2,14.1,8.5,18.7,2.4,2.1,4.5,4,7.1,5.9,2.7,2,5.6,4.4,8.5,6.2,13.6,8.9,14,23.8,11,38.8-2.9,14.8-4.7,28.3-10.3,42.1-1.4,3.4-2.9,8.1-4.1,11.5-1,2.7-2.4,5-4.1,7.4-1.7,2.3-2.6,4.3-5,5.4-2.7,1.2-3.6,4.7-6,7.8-1,1.3,6.4,4.9,12.8,6.4,1.5.4,4.1-6.1,4.5-7.2,1.6-4.1,2.4-7,3.5-9.7,2.6-6.3,5.1-22.8,6.7-29.5,3.7-15.4,6.9-17.4,8.5-33,.7-7.3,2.2-15.1,1.7-22.5h0Z"/>
</g>
</svg>
  );
}

export default IliosakralgelenkeVdLiegend1204;
