import Controller from "../Common/Controller";
import SideView from "./views/SideView";

import "./styles/Controller.scss";

function FaceSkullController() {
  const availableViews = [SideView];
  return <Controller availableViews={availableViews} />;
}

export default FaceSkullController;
