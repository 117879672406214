import { Switch, Link, matchPath } from "react-router-dom";
import { Dropdown, Button } from "react-bootstrap";
import { useState } from "react";
import PrivateRoute from "../../Utilities/PrivateRoute";
import styles from "./TabSubMenu.module.scss";
import React from "react";
import useWindowSize from "../../Utilities/Window/windowSize";

function SingleLabel({ id, path, link, label }) {
  const isMatching = matchPath(window.location.pathname, {
    path,
    exact: true,
  });
  return (
    <Link
      key={`submenuItem_${id}`}
      to={link ? link : path}
      className={styles.Link}
    >
      <div
        className={`${styles.navElement} ${!!isMatching && styles.selected}`}
      >
        {label}
      </div>
    </Link>
  );
}

function DropdownMenu({ id, label, subMenuItems }) {
  const isMatching = matchPath(window.location.pathname, {
    path: subMenuItems.map((child) => child.path),
    exact: true,
  });
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      key={`submenuDropdownToggle_${id}`}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className={`${styles.navElement} ${!!isMatching && styles.selected}`}
    >
      {children}
    </div>
  ));

  return (
    <Dropdown drop="bottom" key={`submenuDropdown_${id}`}>
      <Dropdown.Toggle as={CustomToggle}>{label}</Dropdown.Toggle>
      <Dropdown.Menu>
        {subMenuItems.map(({ link, label, id }) => (
          <Dropdown.Item>
            <Link key={`${id}_dropdown_item`} to={link} className={styles.Link}>
              {label}
            </Link>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function TopMenu({ subMenuItems }) {
  const { width } = useWindowSize();
  const [menuExpanded, setMenuExpanded] = useState(false);
  return (
    <div className={`${styles.subNav} ${menuExpanded && styles.expanded}`}>
      {width <= 786 && (
        <Button
          onClick={() => setMenuExpanded((prev) => !prev)}
          variant="secondary"
        >
          Menu{" "}
          <i className={`fas fa-chevron-${menuExpanded ? "up" : "down"}`} />
        </Button>
      )}
      {subMenuItems.map(({ path, link, label, subMenuItems, id }) => {
        if (!!!subMenuItems) {
          return SingleLabel({ id, path, link, label });
        } else {
          return DropdownMenu({ id, label, subMenuItems });
        }
      })}
    </div>
  );
}

function TabSubMenu({ subMenuItems }) {
  return (
    <>
      <TopMenu subMenuItems={subMenuItems} />
      <Switch>
        {subMenuItems.map(
          ({ path, roles, component, subMenuItems: childItems }) =>
            !!childItems ? (
              childItems.map(({ path, roles, component }) => (
                <PrivateRoute
                  key={`sub_route_${path}`}
                  roles={roles}
                  path={path}
                  component={component}
                />
              ))
            ) : (
              <PrivateRoute
                key={`sub_route_${path}`}
                roles={roles}
                path={path}
                component={component}
              />
            )
        )}
      </Switch>
    </>
  );
}

export default TabSubMenu;
