import style from "./style.scss";

export default function ThoraxHerzfernaufnahmeInspirationPaStehend2107(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 578.8 610.8"
    >
      <g id="BG">
        <path
          id="bg"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls4"
          d="M475.9,578.9c7-3,9.5-12.3,9.5-12.3,5.7-8.8,6.2-14.8,5.5-18.7-.4-2.1-1.6-6-.7-11,.5-2.6,1.4-5.5,1.4-5.5.7-2.2,1.4-3.7,2.2-6.2,0,0,.6-1.9,1.3-4.5,4.6-16.8,3.7-38.8,3.7-38.8-.5-13.5-1.8-11-1.9-21.2,0-9.4,2.1-8.4,2.9-29.8.3-7.5,0-14-2.3-21.3-1.5-4.5-2-3.7-2.7-6.7-1.9-8.6,2.4-13.1,2.6-24.4,0-2,0-4.2-.7-8.6-2-13.8-5.4-18-5.7-32-.2-9.6,1.3-9.5.9-19-.5-11-2.6-12.2-5.2-28.2-2.8-17-.8-17.4-3.8-26.5-3-9.2-5.8-10.9-8.1-20.3-2.2-9.1,0-9.5-1.7-19.1-1-5.7-3.3-19.7-13.3-30.9-3.4-3.9-6-5.3-8.4-10.2-2.9-5.8-1.7-10.2-3.5-15.1-3.3-9.3-8-15.6-12-20.8-13.1-17.1-19.2-26.1-27.7-33.2-4.1-3.4-12.1-10.1-24-15.9-7.6-3.7-8.6-3-15.5-6.7-10.6-5.8-15-14-27.9-17.2-5.4-1.3,2.8-4.6-3.1-8.7-16.5-11.5-24.6-17.6-26-18.1-14.1-5.4-15.5-.2-47.9-3.2-16.4-1.5-20.4-3.3-25.1.2-5.4,4-8,10.8-16.9,23.1-5.3,7.3-9.6,5.1-17.1,11.9-8.6,7.9-8.1,11.5-15.2,17.1-8,6.3-10.5,3.1-20.8,8.9-4.7,2.6-12.2,10.4-27,26-7.8,8.2-5.9,7.5-11.8,12.3-13.8,11.4-14.1,11-18.1,16.5-7.3,9.9-3.9,13.3-10.9,30.2-3.6,8.7-6.3,8-10.7,17.6-2.7,6.1-6.9,15.6-5.9,26.8.2,2.6.5,3.2.5,5.5-.2,7.1-3.4,10.5-7,18-1.4,3.1-4.9,10.6-6,20-.8,7.3.7,7.4-.2,13.9-1.3,8.4-3.8,8.2-6.6,18.7-2.1,7.9-4.1,15.6-.5,21.4.9,1.4,2,2.6,2.3,4.9.5,3.9-2,6.9-3.6,9.7-1.9,3.3-2.4,7.6-3.4,16.3-1.6,14.1,2,15.9,1,30-.7,10.1-2.6,8.8-3.5,20.2-1.1,13.2,1.3,15.4-.5,24.8-2,10.1-5,8.7-8.1,20-1.9,6.8-5.2,19-.5,31,2.4,6.3,5.3,8,5.6,14,.3,6.7-2.9,10.6-4.7,15.1,0,0-6.6,12.5,6.7,32.9,24,37,392.1,39,420,27h0Z"
        />
      </g>
      <g id="underlays">
        <g id="Lungen">
          <path
            id="Lungs2"
            class="Lung_lobes2107Fill"
            style={{
              fill: props.colors.Lung_lobes2107Fill,
              stroke: props.colors.Lung_lobes2107Fill,
            }}
            d="M244.9,439.6s-5.6-.5-27,.7c-7.3.4-12.2,1.7-21.6,4-2.6.7-13.9,3.7-29.1,10.1-7.2,3-20.1,8.6-35.1,18.9-10.9,7.5-21.9,15-32.4,28.4-9.9,12.5-16.1,20.4-21,33.1-2.8,7.2-4.4,14.2-8.8,14.9-4.1.6-8.9-4.5-10.8-9.5-2-5.3-.4-9.7.7-15.5,1.6-8.4.4-10,.7-25.7.3-18.8,2.5-43.9,4.1-62.2,6.8-79.1,5.2-68.3,6.8-81.1,4.6-38.1,10.3-66,15.5-91.9,8.1-39.9,12.5-53.9,15.5-62.8,7.6-22.1,13.1-37.4,26.4-54.7,17.5-22.8,37.3-34.3,43.9-37.8,16.1-8.7,29.4-11.5,32.4-12.2,17.6-3.6,31.5-2,35.8-1.3,20.2,3,36.9,11.6,43.9,21.6.5.7,4,6.2,2.7,7.4-1.3,1.3-6.7-2.5-16.2-8.1,0,0-2.4-1.4-18.2-10.1-17.6-9.7-43.3-2.5-52,0-7.2,2-31.6,9.8-56.1,36.5-26.2,28.6-33.8,58.8-48,117.6-4.7,19.7-12.5,55.1-17.6,100-5.9,51.5-4.1,78.3-8.8,116.9-2,16.4-1.7,32.9-3.4,49.3-.3,3.2-1.3,11.9,0,12.2,1.2.3,3-6.9,8.1-16.9,5.2-10.2,10.9-17.3,14.9-22.3,2-2.5,6.3-7.8,12.2-13.5,12.9-12.7,25-20,37.2-27,18.1-10.5,32.6-16.2,37.8-18.2,5.3-2,17-6.3,32.4-9.5,20.4-4.2,29.4-2.9,35.8,1.4,2.2,1.5,6.8,5.1,6.1,6.8-.6,1.4-4.3.9-6.8.7h0Z"
          />
          <path
            id="Lungs1"
            class="Lung_lobes2107Fill"
            style={{
              fill: props.colors.Lung_lobes2107Fill,
              stroke: props.colors.Lung_lobes2107Fill,
            }}
            d="M354.4,451.1c-.8,1-.7,2.4-.7,2.7.4,4,6.4,7.5,14.9,8.8h0c3.9,1,7.9,2.3,12.2,4,5.5,2.2,5.3,2.7,12.8,6.1,6.4,2.9,7.1,2.8,11.9,5.2,4.5,2.2,6.6,3.7,10.4,5.6,12.3,6.3,11.7,4.7,18.2,8.8,4.6,2.9,13.8,8.8,21.6,19.6,4.7,6.5,7.3,12.5,9.5,17.6,3.9,9.2,5.9,13.7,7.4,18.9,1.8,6.1,2,8.7,4,9.5,3.7,1.3,9.6-4.6,12.8-10.1,4-6.9,3.9-13.4,4-19.6.4-20.5,2.6-15.4,2.7-33.8.1-30.2-5.8-40.9-8.8-78.4-1.1-14.1-.3-12.5-1.4-33.1-.4-7.9-2.2-40.9-8.8-86.5-4.2-28.9-8.4-57.8-18.9-94.6-5-17.4-10.8-35.3-23.6-54.1-8.1-11.9-16.4-20.4-20.3-24.3-8.6-8.8-17-17.2-30.4-24.3-11.4-6.1-20.7-8.3-23-8.8-7.8-1.8-18.1-4.1-30.4-.7-8.1,2.2-13.5,6-17.6,8.8-2.2,1.5-12.1,8.4-15.5,16.2-1.7,4-1.9,9-2.7,11.2,0,0,0,.2,0,.3.3,1,3.8.8,4.7.7,4.4-.6,6.2-8.7,8.8-13.5,6.2-11.7,22.3-14.7,27-15.5,14.1-2.6,25.1,3,39.2,10.1,19.4,9.8,31.7,22.1,35.8,26.3,15.7,16.3,26.2,35.1,35.8,61.5,3.9,10.7,10.2,28.9,23,120.9,6.5,47,9.8,70.7,10.1,85.8.6,26.8-1.8,36.4,2.7,58.1,2.9,14.2,6.6,23.3,5.4,38.5-.6,8-2,8.8-4.7,29.7-.9,6.7-1.4,11.4-2.7,11.5-1.9.2-2.8-9.4-7.4-21-2.2-5.5-8.3-20.6-21.6-31.1-3.8-3-10.9-6-25-12.2-14.2-6.2-13.6-5-25-10.1-10-4.5-15.7-7.7-27.7-12.2-9.8-3.6-16.7-5.5-18.9-2.7h0Z"
          />
        </g>
        <g id="Lungenspitze">
          <path
            id="Lungenspitze2"
            class="Lungenspitze2107Fill"
            style={{
              fill: props.colors.Lungenspitze2107Fill,
              stroke: props.colors.Lungenspitze2107Fill,
            }}
            d="M285.8,129.9c-3.3,8-25.4,13.2-44.9,5.1s-29.2-26.3-25.9-34.3,21.8-7.9,41.4.1c19.6,8.1,32.7,21.1,29.4,29.1h0Z"
          />
          <path
            id="Lungenspitze1"
            class="Lungenspitze2107Fill"
            style={{
              fill: props.colors.Lungenspitze2107Fill,
              stroke: props.colors.Lungenspitze2107Fill,
            }}
            d="M298.3,124.8c3.3,8,21.7,9.6,41.3,1.5,19.5-8.1,32.4-20.2,29.2-28.2,0,0-21.4-10.4-41-2.4-19.5,8.1-32.7,21.1-29.4,29.1Z"
          />
        </g>
        <g id="Zwerchfelle">
          <path
            id="Zwerchfelle2"
            class="Zwerchfelle2107Fill"
            style={{
              fill: props.colors.Zwerchfelle2107Fill,
              stroke: props.colors.Zwerchfelle2107Fill,
            }}
            d="M244.9,431.5c-6.4-4.2-15.4-5.6-35.8-1.4-15.5,3.2-27.1,7.4-32.4,9.5-5.3,2-19.8,7.8-37.8,18.2-12.1,7-24.3,14.3-37.2,27-5.8,5.7-10.1,11-12.2,13.5-4,5-9.6,12.1-14.9,22.3-4,7.8-5.9,13.8-7.2,16,0,1.7,0,3.3,0,4.7,0,2.5,0,5.1,0,7.6.6.1,1.2.1,1.7,0,4.4-.7,6-7.6,8.8-14.9,4.9-12.7,11.1-20.6,21-33.1,10.5-13.4,21.5-20.9,32.4-28.4,15-10.3,28-15.9,35.1-18.9,15.2-6.5,26.4-9.5,29.1-10.1,9.4-2.4,14.3-3.6,21.6-4,21.4-1.2,27-.7,27-.7h0c2.4.2,6.2.7,6.8-.7.7-1.7-3.9-5.3-6.1-6.8Z"
          />
          <path
            id="Zwerchfelle1"
            class="Zwerchfelle2107Fill"
            style={{
              fill: props.colors.Zwerchfelle2107Fill,
              stroke: props.colors.Zwerchfelle2107Fill,
            }}
            d="M475.4,556.2c1.6-9.4,1.1-19.2.5-28.9-.7-2.3-1.5-4.7-2.6-7.3-2.2-5.5-8.3-20.6-21.6-31.1-3.8-3-10.9-6-25-12.2-14.2-6.2-13.6-5-25-10.1-10-4.5-15.7-7.7-27.7-12.2-9.8-3.6-16.7-5.4-18.9-2.7-.8,1-.7,2.4-.7,2.7.4,4,6.3,7.5,14.9,8.8,3.9,1,7.9,2.3,12.2,4,5.5,2.2,5.3,2.7,12.8,6.1,6.4,2.9,7.1,2.8,11.9,5.2,4.5,2.2,6.6,3.7,10.4,5.7,12.3,6.3,11.7,4.7,18.2,8.8,4.6,2.9,13.8,8.8,21.6,19.6,4.7,6.5,7.3,12.5,9.5,17.6,3.9,9.2,5.9,13.7,7.4,18.9,1,3.3,1.5,5.6,2.1,7.2Z"
          />
        </g>
        <path
          id="Herz"
          class="Herz2107Fill"
          style={{
            fill: props.colors.Herz2107Fill,
            stroke: props.colors.Herz2107Fill,
          }}
          d="M284.6,199c1.2-1.9,8-12.3,16-19,6-5.1,10.1-5.9,12.7-6,.1,0,.3,0,.3,0,8.2-.1,13.6,6.5,14,7,4,5,4,10.8,4,15,0,11.4-5.2,13-4,21,1.1,7.3,5.7,7,9,17,1.4,4.2,1.2,6.2,2,17,.4,5,1.2,16.2,2,21,1.4,8.6,5.3,14.5,21,33,24.5,28.8,25.1,26.3,30,35,4.4,7.8,13.3,24.1,10,44-.8,4.5-3.7,22.2-18,31-5.1,3.1-9.8,3.8-19,5-5.1.7-19.4,2.2-45-2-15.6-2.5-23.4-3.8-33-8-7.4-3.2-19.6-8.5-30-21-12.6-15.1-14.9-31.6-16-40-.7-5.4-2.8-23.3,5-42,5-12,12-22,12-22,4-5.9,7.3-11,10-15,8.6-12.9,9.4-14.3,10.3-17.1,3.1-9.8,1.7-20.9,1.5-22.2-.3-2.4-1-5.8-.7-10.7.2-4,1-7.3,1.3-8.7.8-3.3,1.7-5.8,2-6.7.9-2.3,1.8-4.2,2.6-5.8Z"
        />
      </g>
      <g id="Thorax">
        <path
          id="Thorax144"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M353.9,452.9c5.6,1.4,13.6,3.7,23,7,28.5,10,26.8,14.7,52,24,15.8,5.9,24,14,30,17,3.5,1.7-.4,5.3,1,11,2,8.2,6.5,10.5,11,18,7.4,12.3,5.4,22.8,8,23,2.5.2,6.2-9.4,9-24,6.9-36.2,1.5-66.5,0-75-5.6-32.8,1.9-8.3-8-102-7-66.4-10.7-100.2-21-135-10.8-36.4-22.1-58.2-37-77-15.9-19.9-32.1-31.7-48-39-12.6-5.8-24.8-11.2-40-8-15.8,3.4-30.7,15.2-36,30-3.2,8.9-3.1,19.1-3,24,.1,8.9,1.5,12.5,1,22-.5,8.2-1.9,12-3,18-2.8,15.2-.4,27.8,1,35,1.8,9.2,6,31.5,24,47,5.5,4.7,13.1,11.2,23,10,9.3-1.2,15.1-8.5,17-11"
        />
        <path
          id="Thorax143"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls12"
          d="M284.9,199.9c5.4-9.9,11.8-15.8,16-19,7.6-5.8,11.8-6,13-6,8.2-.1,13.6,6.5,14,7,4,5,4,10.8,4,15,0,11.4-5.2,13-4,21,1.1,7.3,5.7,7,9,17,1.4,4.2,1.2,6.2,2,17,.4,5,1.2,16.2,2,21,1.4,8.6,5.3,14.5,21,33,24.5,28.8,25.1,26.3,30,35,4.4,7.8,13.3,24.1,10,44-.8,4.5-3.7,22.2-18,31-5.1,3.1-9.8,3.8-19,5-5.1.7-19.4,2.2-45-2-15.6-2.5-23.4-3.8-33-8-7.4-3.2-19.6-8.5-30-21-12.6-15.1-14.9-31.6-16-40-.7-5.4-2.8-23.3,5-42,5-12,12-22,12-22,4.9-7,7.7-9.1,10-15,3.1-7.8,2.4-14.3,2-21-.8-12.6.2-25.3,0-38-.5-33.5,1.7-32.1,0-43-1.5-9.6-5.3-23.6-16-40"
        />
        <path
          id="Thorax142"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls12"
          d="M366.9,216.9c-1.5,4.7-4.5,11.7-11,17-5.2,4.2-9.9,5-30,8-27.8,4.1-31.5,4.5-38,4-15.1-1.1-22-5.1-25-7-4-2.6-10.5-6.8-12-14-1.3-5.9,2.3-7.1,3-18,.5-7.4-1.1-9.3,0-16,.9-5.3,2.6-9.4,4-12"
        />
        <path
          id="Thorax141"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls12"
          d="M240.9,179.9c-2,4.7-4.5,11.5-6,20-1.5,8.7-2.5,14.2-1,21,2.6,11.9,10.2,15.5,8,21-1.6,4-5.7,2.7-12,8-8,6.7-10.3,16.2-11,19-2.3,9.2.9,11.4-1,20-1.6,7.4-5.2,11.3-11,19,0,0-9.7,12.9-21,32-4.7,7.9-10.6,19.8-15,36"
        />
        <path
          id="Thorax140"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls12"
          d="M365.9,281.9c-4.8,6.5-12.2,14.3-21,14-5.5-.2-7.3-3.4-14-5-8.4-2-13.8,1-27,5-12.5,3.8-23.7,7.2-35,7-6.9-.1-17.2-1.6-30-9"
        />
        <path
          id="Thorax139"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls12"
          d="M355.9,239.9c3.3,2,7.8,5,13,9,6.2,4.8,10.5,8.8,16,14,3.7,3.5,8.5,8.2,14,14"
        />
        <path
          id="Thorax138"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          d="M337.9,159.9c-15.8,7.6-24.2,17.7-28,23-7.3,10.2-10.9,21.5-13,28-1.4,4.5-2.4,8.3-3,11"
        />
        <path
          id="Thorax137"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          d="M256.9,172.9c7.1,6.4,21.5,16.2,28,27"
        />
        <path
          id="Thorax136"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M222.9,307.9c2.5-2.6,6.3-6.4,11-11,8.9-8.7,11.5-10.8,19-18,11.5-11,17.9-17.1,25-26,5.5-6.9,9.5-13,12-17"
        />
        <line
          id="Thorax135"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="289.9"
          y1="235.9"
          x2="308.8"
          y2="259.1"
        />
        <path
          id="Thorax134"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls12"
          d="M211.9,364.9c.5-8.9.8-16,1-21,.2-6.7.3-9.5,1-14,1.2-7.6,3.3-13.2,4-15,5-12.8,9.2-11.8,11-20,2.1-9.9-3.6-13.6,0-21,2.6-5.5,7.9-8,12-10,9.3-4.5,18.4-3.8,23-3"
        />
        <line
          id="Thorax133"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="366.9"
          y1="260.3"
          x2="391.5"
          y2="281.9"
        />
        <line
          id="Thorax132"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="368.9"
          y1="271.1"
          x2="395.5"
          y2="308.5"
        />
        <line
          id="Thorax131"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="371.9"
          y1="221.9"
          x2="365.9"
          y2="235.9"
        />
        <line
          id="Thorax130"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="192.9"
          y1="342.9"
          x2="177.9"
          y2="375.9"
        />
        <line
          id="Thorax129"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="192.9"
          y1="342.9"
          x2="185.4"
          y2="375.9"
        />
        <line
          id="Thorax128"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="222.9"
          y1="318.9"
          x2="240.1"
          y2="322.4"
        />
        <line
          id="Thorax127"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="227.8"
          y1="302.9"
          x2="238.9"
          y2="314.1"
        />
        <line
          id="Thorax126"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="217.9"
          y1="342.9"
          x2="233.4"
          y2="336.9"
        />
        <path
          id="Thorax125"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M233.4,342.9c-5.5-1.6-10.1,10.7-16.5,28"
        />
        <path
          id="Thorax124"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M277.9,350.9c-21.8-24-49-14.5-52.8,38"
        />
        <line
          id="Thorax123"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="251.5"
          y1="388.9"
          x2="242.3"
          y2="405.9"
        />
        <line
          id="Thorax122"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="373.7"
          y1="292.4"
          x2="361.9"
          y2="305.9"
        />
        <line
          id="Thorax121"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="379.2"
          y1="302.9"
          x2="365.9"
          y2="312.4"
        />
        <path
          id="Thorax120"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls8"
          d="M299.3,203.7c0,30.2,41.6,75.2,62.6,50.2"
        />
        <path
          id="Thorax119"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls11"
          d="M312.9,271.9c-3.4,9.1-7.8,21-13,35-5.5,15-8.1,22-11,32-4.1,14.1-6.1,21.3-6,30,.1,7.3.3,18.9,8,29,5.8,7.6,13.1,10.7,21,14,16.9,7.1,31.5,5.8,39,5,10.8-1.1,16.1-1.7,20-6,6-6.7,3.8-17.3,1-31-2.7-13-6.9-22.9-13-37-6.4-14.7-8-16.1-11-25-4.2-12.4-5.5-23-6-30"
        />
        <path
          id="Thorax118"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls11"
          d="M370.9,323.9c-1,3.1-2.6,7.7-5,13-4.8,10.5-8.2,17.8-15,23-5.9,4.5-12.3,5.3-25,7-10.8,1.4-19.8,1.4-26,1"
        />
        <path
          id="Thorax117"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          d="M377.4,322.4c0-1-2.7-10,25.2-1.8"
        />
        <path
          id="Thorax116"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls11"
          d="M284.9,199.9c-2.4,3.5-5.3,9-6,16-.7,6.4.9,10.5,3,18,3.4,12.1,5.2,24.7,8,37,1.6,7,3.5,15.2,5,22"
        />
        <path
          id="Thorax115"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls11"
          d="M270.1,261.9c2.7,1.3,6.4,2.8,10.8,4,1.7.5,7.8,2,16,2,3.2,0,7.3-.2,12-1"
        />
        <path
          id="Thorax114"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M286.8,356.8c0,12.1-2.9,25.1,2.7,25.1s12.9-15.9,12.9-28-2-15.5-7.5-15.5-8.1,6.3-8.1,18.4h0Z"
        />
        <ellipse
          id="Thorax113"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          cx="315.3"
          cy="320.2"
          rx="15.4"
          ry="7.6"
        />
        <path
          id="Thorax112"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          d="M353.6,344c2.2-8.6-7.2-16-16.1-16.1-7.5-.1-8.1,6.6-16.1,16.1-5.8,6.8,7.2,16.1,16.1,16.1s14.1-8.3,16.1-16.1Z"
        />
        <ellipse
          id="Thorax111"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          cx="324.6"
          cy="302.2"
          rx="20.6"
          ry="6.3"
        />
        <line
          id="Thorax110"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          x1="294.9"
          y1="338.4"
          x2="289.5"
          y2="381.9"
        />
        <line
          id="Thorax109"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          x1="345.2"
          y1="329.7"
          x2="324.6"
          y2="359.4"
        />
        <line
          id="Thorax108"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          x1="299.9"
          y1="320.2"
          x2="330.6"
          y2="320.2"
        />
        <line
          id="Thorax107"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          x1="304"
          y1="302.2"
          x2="345.2"
          y2="302.2"
        />
        <path
          id="Thorax106"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M243.4,473.2c-2.1-.7-5.6-1.4-9,0-6,2.4-6,8.9-13,16-2.2,2.2-2.7,2.1-12,8-6.8,4.3-14.3,9.1-18,12-5.1,4-12.8,12.3-17,30"
        />
        <path
          id="Thorax105"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M238.4,497.2c-6.3,1.7-15.6,5-25,12-15.5,11.5-22.3,26.1-25,33"
        />
        <path
          id="Thorax104"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M309.3,478.3c3.1-.7,7.7-1.1,12.1,1,4.7,2.2,4.9,5.4,11,11,2.8,2.5,3.4,2.6,13,9,0,0,6,4.1,13,9,11.2,7.9,21.9,23.4,29,35"
        />
        <path
          id="Thorax103"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M310.4,498.2c5.6,3.8,10.5,5.8,14,7,8,2.7,11.1,1.6,16,5,4,2.8,3.9,4.8,9,11,1.4,1.7,3.3,3.8,14,13,4.7,4,10.8,9.1,18,15"
        />
        <line
          id="Thorax102"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="243.4"
          y1="468.2"
          x2="238.4"
          y2="505.8"
        />
        <line
          id="Thorax101"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="231.2"
          y1="518.9"
          x2="222.2"
          y2="538.4"
        />
        <line
          id="Thorax100"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="310.4"
          y1="498.2"
          x2="316.4"
          y2="530.3"
        />
        <line
          id="Thorax99"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="304.9"
          y1="472.9"
          x2="241.9"
          y2="466.9"
        />
        <path
          id="Thorax98"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M239.4,449.9c-4.8-.4-11.9-.4-20,2-10.6,3.1-17,8.7-27,17-10.2,8.5-20.7,16.7-31,25-22.6,18.2-25.1,18.2-37,29-10.4,9.4-19.3,19.2-27,29"
        />
        <path
          id="Thorax97"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M217.9,435c-3.6,4.8-9.4,11.7-18,17.9-7.4,5.4-13.4,7.8-18,10-10.1,4.8-18.7,11.5-36,25-29.5,23.1-58,51.9-66,60"
        />
        <path
          id="Thorax96"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M246.8,452.7c0,7.8-2.2,14.2-4.9,14.2"
        />
        <path
          id="Thorax95"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M299,457.3c6.9,1.8,16.5,4.4,28,8,17.4,5.5,28.7,9,39,14,25,12,39.8,30.2,51,44,8.5,10.5,19.7,26.4,29,48"
        />
        <path
          id="Thorax94"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M307,432.9c5.1-.2,12.6,0,20.9,3,10.9,3.9,14.9,9.4,26,17,10.3,7.1,11.4,5.5,32,16,18.1,9.3,27.2,13.9,36,21,3.4,2.7,18.5,15.2,31,37,6.9,12,10.7,23,13,31"
        />
        <path
          id="Thorax93"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M420.1,462.1c-.9,2.3-4.1,3.3-30,4-36.8,1-41.6-.7-43-4-2.6-6,7-15,8-16,14.8-14,37.7-7.4,39-7,14.6,4.5,28.2,17.1,26,23Z"
        />
        <path
          id="Thorax92"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M308.3,378.7c8.8,1,20.3,2.8,33.6,6.2,24.2,6.2,41.4,14.3,45,16,25.4,12.2,42.1,26.4,52,35,14.3,12.4,34.1,32.2,52.4,61"
        />
        <path
          id="Thorax91"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M317.9,393.9c3,3.5,7.6,7.8,14,9,7.5,1.5,10.8-2.6,18-1,7,1.5,8.5,6.3,16,12,8.9,6.8,16.9,7.9,24,10,16.8,4.9,29.2,15.5,45,29,0,0,37.2,31.6,53,76,1.8,5.2,4,12.2,3,21-1.6,14.5-10.4,24.5-15,29"
        />
        <path
          id="Thorax90"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M318.9,348.9c15.1,4.8,35.8,12,60,22,24.6,10.2,38.9,16.1,56,27,13.5,8.6,32.1,22.4,51.2,43.9"
        />
        <path
          id="Thorax89"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M320.9,330.9c5,4.6,13,10.7,24,14,8.5,2.6,13.6,1.9,22,3,10.4,1.4,19.1,5.2,36,13,17.3,7.9,31,14.2,48,26,26.3,18.2,32.2,25.3,34.4,28.9,3,5.1,5.7,11.3,8.6,27.1,4.1,22,7.5,40.3,4,64-4.7,32.4-19.4,56-29,69"
        />
        <path
          id="Thorax88"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M318.6,287.8c7.9.7,18.3,2,30.3,4.1,24.7,4.5,42.7,10.5,47,12,25.8,8.8,42,14.4,59,29,3.5,3,15.8,13.9,26.3,32,15.3,26.1,17.3,50.2,17.7,56,2,30-8,52.9-18,76-5.1,11.8-10.2,21.1-14,27.3"
        />
        <path
          id="Thorax87"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M314.8,319c3.1-4.3,8.4-10.1,16.4-12.5,7.9-2.4,14.4,0,28.7,4.3,29.8,9.2,23.7,6.1,34,10,22.9,8.6,46,17.2,67,38,6,5.9,14.6,15.6,22.6,29.9"
        />
        <path
          id="Thorax86"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M338.9,251.9c9.4-1.5,4.5-1.6,19-1,7.4.3,23.7,1.5,42,7,18,5.4,51.6,15.5,75,47.8,20.4,28.2,22.1,58.3,22,70.2-.2,28.3-10.7,49.4-22,72-8.8,17.5-18.1,31-25,40"
        />
        <path
          id="Thorax85"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M339.9,263.8c10.1-.2,8.9.2,25,3.1,13.5,2.4,40.4,7.4,69,26,20.5,13.3,34.7,29,44.2,41.8"
        />
        <path
          id="Thorax84"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M328.8,212.9c10-1.1,2.8-.2,19.1,0,10.4.1,20.2.2,32,2,10.8,1.6,34.2,3,57,20,9.5,7.1,22.7,17,39,37,18.3,22.5,16,48.4,16,54,0,10.3-1.6,17.5-5,32-5.4,23.3-9.1,39-21,54-5.1,6.4-15.1,17-33,25"
        />
        <path
          id="Thorax83"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M337,226.9c4.9-.9-8.9,1.7,0,0,9.9-1.9,9.7-1.8,10.9-2,16-2.6,30.6.9,39,3,29.7,7.3,49,23.9,57,31,10.3,9.1,17.9,15.7,24,27,21.7,39.7-1.5,86.3-5,93-4.8,9.3-14.7,25.3-34,40"
        />
        <path
          id="Thorax82"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M333.9,193.7c7.2-2,3.1-1,15-1.8,25.6-1.8,45.1,3.6,50,5,18.4,5.4,51.6,15,66.4,45.5,17,35-2.4,72.7-7.4,82.5-8.7,17-20.1,28.3-28,35"
        />
        <path
          id="Thorax81"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M324.6,186.4c7.5-2.7,28.2-11.3,42.3-11.5,27.4-.4,47.3,10.5,57,16,9.3,5.3,35.5,20.6,51,53,5.1,10.6,16.3,34.3,10,62-4.3,18.8-15.2,32.8-24,44-8.1,10.3-16,17.9-22,23"
        />
        <path
          id="Thorax80"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M320.9,165.9c3.5-.8,8.6-2,15-3,8.3-1.3,10.1-1.1,16-2,14.5-2.3,16.1-5.8,24-6,9.1-.2,15.9,4.2,22,8,13.5,8.5,30.4,18.2,53.2,30.5"
        />
        <path
          id="Thorax79"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M317.9,148.9c13.5,2.1,24,1.2,31,0,12.6-2.1,17.1-5.6,28-5,6.9.4,12.4,2.6,23,7,10.1,4.2,21.3,8.8,34,18,6.7,4.9,17.4,12.7,26,25,15.4,22.2,21.2,55.4,9,83-5.6,12.6-13.3,20.3-25,32-16,16-32,26-43,32"
        />
        <path
          id="Thorax78"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M482.9,87.9c-11.5,2.3-20.8,3.9-27,5-14.1,2.4-17.2,2.6-23,5-7.5,3.1-7.4,5-20,13-9.9,6.2-16,10.1-25,13-3.7,1.2-13,4.1-25,4-8.8,0-12-1.7-20,0-6.3,1.3-11.7,3.9-14,5-5.9,2.9-5.7,3.8-12,7-9.1,4.6-11.2,3.6-14,7-3,3.7-4.5,9.9-2,15,2.6,5.4,8.8,8.2,14,8,7.5-.3,9.2-6.6,21-12,4.6-2.1,7.7-2.7,13-4,3.4-.8,25.5-6.4,45-15,12.2-5.4,22.1-11.5,31-17,12-7.4,14-9.6,21-13,8.6-4.1,20-7.6,35-8"
        />
        <path
          id="Thorax77"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M472.2,406c-8.8,35.8-21,53.8-34.3,63.9"
        />
        <line
          id="Thorax76"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="486.1"
          y1="446.9"
          x2="455.1"
          y2="513.9"
        />
        <path
          id="Thorax75"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M463,237.9c-10.2,41.4-25.1,64-69.1,82.9"
        />
        <path
          id="Thorax74"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M448.1,185.4c-10.1,36.5-19.6,52.5-48.1,72.5"
        />
        <path
          id="Thorax73"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M456.5,209c-8.4,33.3-23.8,42.9-47.6,59.9"
        />
        <path
          id="Thorax72"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M362.9,164.9c17.1-13.4,20.9-24.3,21-32,0-5-1.5-10.1-1-22,.5-13.8,2.9-16.1,4-17,5.7-4.5,16-.1,21,2,8.1,3.4,10.5,6.8,20,14,11.1,8.5,19,14.4,29,17,5.6,1.4,12.4,9,26,4.3"
        />
        <path
          id="Thorax71"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M433,155.6c-6.8,15.3-12.7,28-17.1,37.3-5.2,10.9-6.9,14.2-10,18-10,12.3-14.6,15-24.3,17"
        />
        <path
          id="Thorax70"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M373.9,100.9c60.2,31.5,35,104-5,104"
        />
        <path
          id="Thorax69"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M340.5,91.9c42.5,28.5,3.8,57,8.4,57"
        />
        <path
          id="Thorax68"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M248.4,436.4c-3.4-1.9-8.5-4.3-15-5-10.9-1.2-18.9,4.4-24,5-21.8,2.5-28.5,5-45,13-8.5,4.1-36.5,15.1-60,40-27.3,28.9-32,57.5-38,56-4.9-1.3-3.8-21.2-1-61,3.7-52.1,5.6-78.2,7-91,6.2-55.4,9.4-83.1,17-122,15.8-80.4,30-103.2,36-112,6.7-10,23.1-32.1,51-49,18.2-11,30.6-13.4,34-14,4.6-.8,26.5-4.2,50,7,9.3,4.4,21,10,26,22,1.2,3,1.8,9.3,3,22,1.8,19.3.7,23.1,0,25-2.9,8.3-7.3,8.6-11,17-3.7,8.3-2.9,15.9-2,24,1.9,16.9,4.1,35.5,7,56"
        />
        <line
          id="Thorax67"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls12"
          x1="281.4"
          y1="254.4"
          x2="212.4"
          y2="335.4"
        />
        <line
          id="Thorax66"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls12"
          x1="280.9"
          y1="250.7"
          x2="340.4"
          y2="314.4"
        />
        <path
          id="Thorax75-2"
          data-name="Thorax75"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls12"
          d="M296,172.3c13.6-13.9,4.2,110.2,9.5,110.2"
        />
        <path
          id="Thorax64"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M271.1,108.9c14.4,89.2-23.7,168.5-50.6,178.5"
        />
        <path
          id="Thorax63"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls9"
          d="M305.4,287.4c1.2,4.3,2.2,8.9,3,14,2.4,15.8,1,29.6-1,40"
        />
        <line
          id="Thorax62"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          x1="264.4"
          y1="108.9"
          x2="266.1"
          y2="211.8"
        />
        <path
          id="Thorax61"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls6"
          d="M257.4,220.4c-1.8,2.3-4.3,6-6,11-1.9,5.6-1.3,8.4-3,13-1.2,3.2-3.6,7.6-9,12"
        />
        <path
          id="Thorax60"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M324.9,121.9c4.2-.6,10.5-1.8,18-4,8.9-2.6,11.1-4.4,16.1-5.1,7.6-1.1,13.5,1.4,23.8,5.9,13.4,6,25.2,11.5,35,16.3"
        />
        <path
          id="Thorax59"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M315.9,130.9c4.9,2.1,12.4,4.3,21,3,8.9-1.4,9.6-5.2,18-6.5,8.1-1.2,10.6,1.7,29,5.5,11.1,2.3,11.4,1.4,16.9,2.8,8.3,2.1,19.7,7,32.2,19.9"
        />
        <path
          id="Thorax58"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M319.9,106.9c5.8-2,12.9-3.9,21-5,15.6-2.1,28.9-.3,37.9,1.8"
        />
        <line
          id="Thorax57"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="365.9"
          y1="288.9"
          x2="391.5"
          y2="340.9"
        />
        <line
          id="Thorax56"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="381.6"
          y1="296.4"
          x2="401.5"
          y2="330.9"
        />
        <path
          id="Thorax55"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M369.9,229.9c-.4,3-1.3,7.1-4,11-3.7,5.5-8.1,6.6-8,10,0,3,3.6,3.2,7,8,4.1,5.8,2,9.8,5,15,1.8,3,5.4,6.8,14,9"
        />
        <path
          id="Thorax54"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M432.7,143.8c10.9,12.1,21.4,31.2,18.5,49.6"
        />
        <path
          id="Thorax53"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M350.5,91.9c17.7-4.2,58.4,11.7,82.2,51.9"
        />
        <path
          id="Thorax52"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M357.6,52.9c0,35,29.7,35,66.4,35"
        />
        <path
          id="Thorax51"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls10"
          d="M362.9,418.9c14,7,21,9,47.4,18"
        />
        <path
          id="Thorax50"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls10"
          d="M496.9,401.9c-11.6,13.9-32.4,36.1-72.5,36.1"
        />
        <path
          id="Thorax49"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M485.9,124.9c-48-23.9-20,24,5.2,18.9"
        />
        <path
          id="Thorax48"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M192.9,399.9c-8.9,6.4-22.8,16.3-40,28-17.4,11.8-29.1,19.3-46,32-30.4,22.8-34.7,30.2-37,35-8.2,17-7.5,36.4-7.4,37.9.2,4.9.8,9,1.4,11.9"
        />
        <path
          id="Thorax47"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M211.9,382.9c-20.5,8.5-37.3,17-50,24-15,8.2-28.5,15.6-45,28-9.2,6.9-22.1,17.4-36,32-20.1,21.1-25.5,33.4-27,37-7.2,17.1-5.6,27.8-5,31,1.4,7.7,4.6,13.5,7,17"
        />
        <path
          id="Thorax46"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M230.9,335.9c-21.6,4.3-38.8,10.1-51,15-11.5,4.6-19.9,8.9-36,17-7.5,3.8-21.9,11.3-40,22-25.9,15.4-30.6,20.5-33.8,24.7-5.2,6.9-8.4,13.8-13.2,24.3-5.8,12.8-9.3,23.4-10,26-9,31.7,37.6,88.2,40,91"
        />
        <path
          id="Thorax45"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M170.9,374.9c-13.9,3.2-25,7.5-33,11-9.1,4-24.5,10.9-41,25-10.8,9.2-17.8,17.8-21,22-10.8,14-18.2,23.7-20,37-2,15.4,4.8,27.5,18,51,8.4,14.8,16.8,26.3,23,34"
        />
        <path
          id="Thorax44"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M234.9,290.9c-8,.2-19.1,1.1-32,4-13.5,3.1-23.1,7-35,12-14.9,6.2-25.6,11.9-37,18-10.3,5.5-17.3,9.6-28,16-15.8,9.4-23.8,14.1-26.8,17.5-9,10-11.7,21.2-15.2,35.5-1.2,5.2-4.2,17.7-4,32,.1,7.1,1.2,27.4,28,74,8.3,14.4,20.4,33.6,37,55"
        />
        <path
          id="Thorax43"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M238.6,305.5c-6.5-.8-16.2-1.5-27.7.4-6.2,1-14.5,3-36,13-16.6,7.8-18.2,9.7-42,22-23.9,12.4-26.7,12.7-35,19-8.2,6.2-18.4,13.9-25,25.8-15.6,28.1-1.8,62.4,3,74.2,1.6,4.1,5.3,12.6,31,48,13.7,18.9,25.6,34.3,34,45"
        />
        <path
          id="Thorax42"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M229.9,245.9c-6.7.7-16.5,2.1-28,5-6.3,1.6-15.1,4.1-50,19-28,11.9-33.3,15-38,18-14.6,9.3-23.5,18.4-26,21-6.8,7.1-15,15.6-21,29-1.9,4.2-8.5,20-7,40,1.9,26.1,16.4,44,35,67,12.2,15.1,23.9,26,32,33"
        />
        <path
          id="Thorax41"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M227.9,262.9c-2.4,1.6,4.9-1.3,0,0-6.9,1.9-12.4,1.2-15,1-6.8-.5-14.5,2-30,7-25.2,8.1-44.2,18-48,20-18.5,9.7-27.8,14.6-38,25-6,6.1-14.1,14.3-19.1,27.8-8.8,23.7-1.1,45.4,1.1,51.2,4.3,11.6,11.6,20.2,26,37,12.4,14.5,23.6,25.5,31.6,33"
        />
        <path
          id="Thorax40"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M141.9,404.9c-16.1-10.7-27.6-21.8-35-30-7.5-8.3-15.4-17.1-22-31-5.7-12-11.5-24.1-9-38,2-11.1,10.5-19,27-35,9.5-9.2,16.5-16,28-22,11.9-6.2,11.8-2.9,30-11,17.5-7.8,21.1-12.4,31-12,4.5.2,8.8,1.4,16,0,6.8-1.3,7.5-3.2,12-3,7.9.4,9.6,6.6,16,6,5.2-.5,10.5-5.3,10-9-.5-4.1-7.8-6.1-11-7-6.4-1.8-11.8-1.1-13-1-27.3,3.3-25.4.9-38,4-18.1,4.4-17.7,8.3-49,20-17.8,6.7-17.7,5.4-24,9-17.4,10.1-26.1,25.2-32,35-6.4,10.8-14.5,24.2-15,42-.8,26.3,15.4,46.1,30,64,13.4,16.4,27.3,27.4,37,34"
        />
        <path
          id="Thorax39"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M253.9,179.9h-27c-5.9,0-18.6,0-28,1-8.1.8-16.7,3.5-34,9-16.6,5.2-25.1,8-35,13-11.4,5.8-19,11.5-21,13-9,6.8-17.7,13.5-24,25-2.3,4.2-3.9,8.8-7,18-3,8.9-5.4,17.1-6,20-6.5,28.6,28.7,66.4,33,71,6.9,7.4,18.2,18.1,35,28"
        />
        <path
          id="Thorax38"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M254.9,192.9c-4,1-9.5,2.2-16,3-2.9.4-10.7,1.3-22,1-15.8-.4-17-2.5-26-2-6.8.4-12.1,1.9-25,7-25.2,10-37.8,15.1-49,23-16.1,11.4-25.3,17.9-30,30-10.5,27.1,11.4,58,22,73,10.8,15.3,22.6,25.6,31,32"
        />
        <path
          id="Thorax37"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M61.9,85.9h.2s13.5.2,25.8,1c19.4,1.2,29.2,1.8,40,5,6.7,2,7.3,2.9,26,12,26.4,12.8,39.6,19.2,54,24,6.1,2,33,11,33,11,15.5,5.2,21.6,7.2,23.7,12.9,2.4,6.6-1.2,16.1-7.7,19.1-5.8,2.7-12.2-.6-17-3-7-3.6-7.8-6.7-14-11-7.4-5.1-11.6-4.3-23-8-8.1-2.6-14.4-6.1-27-13-16.9-9.3-17.9-12-31-19-14.9-8-27.4-11.9-31-13-11.1-3.4-28.9-7.2-52-6"
        />
        <path
          id="Thorax36"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M169.9,327.9c-8.2-7.8-16.5-16.1-25-25-17.9-18.8-24.7-27-27-30-9.6-12.3-14.6-18.6-18-28-3.1-8.4-7.7-20.8-3-33,1.2-3.1,2.3-4.4,25-24,17.2-14.8,20.8-17.7,27-18,4.8-.2,8.4,1.2,17,0,1.7-.2,6.3-1,12-3,8-2.8,9.7-5.1,15-7,5.6-2,10.1-1.7,19-1,17,1.3,21.4,5.7,34,4,3.6-.5,11.2-1.5,12-5,.9-3.8-6.5-8.7-11-11-9.1-4.6-18-4.4-31-3-17.8,1.9-29.1,3.1-47,8-15.8,4.4-23.7,6.6-34,12-9.3,4.9-25.9,14-38,33-3.8,6-10.2,16.3-12,31-3.7,30.9,16.2,54.5,20,59,7.8,9.2,10.3,8.1,23,21,12.4,12.6,20.8,24.6,26,33"
        />
        <path
          id="Thorax35"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M168.4,263c-5.5-3.5-12.3-8.5-19.4-15.1-9.8-9-15.7-17.1-17-19-4.9-6.8-10.5-14.5-14-26-2.4-7.7-4.6-14.9-3-23.5,3.9-21.4,28-32.7,34-35.5,1.6-.7,1.1-.4,27-8,27.2-7.9,28.3-8.4,32-8,14.3,1.7,17.6,11.5,33,11,5.5-.2,13.4-1.7,14-5,.7-3.7-7.8-8.3-11-10-2.1-1.1-5.9-3-14-5-3.2-.8-10.6-2.6-18-3-10.5-.5-17.1,2.1-37,9-27,9.3-24,7.6-27.7,9.3-8.2,3.9-36.7,17.2-44.3,44.7-6.5,23.5,5.7,44.5,13,57,14.6,25.1,35.9,37.6,45.9,42.7"
        />
        <path
          id="Thorax34"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M190.9,194.9c-3.9-4.5-10.2-11.1-19-18-11.7-9.2-16.1-9.7-19-15-6.4-11.4,1.6-31.2,13.2-43.5,25-26.5,70-21.2,69.8-26.5-.1-2.2-8-3.6-12-4-8.9-.9-15.7,1.6-29,7-15.9,6.5-23.9,9.7-26,11-7.5,4.6-19.2,11.7-27,26-2.8,5.1-9.4,17.3-7,32,2,12.2,9.2,19.8,23,34,10.1,10.4,19.7,17.9,27,23"
        />
        <path
          id="Thorax33"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M246.9,184.9c-1.5,3.5-3.2,8.7-3,15,.2,5.5,1.5,5.9,2,12,.7,9.1-2.2,11.6-1,19,.7,4.4,2.4,8,4.1,10.7"
        />
        <path
          id="Thorax32"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M230.8,291c-6.6,11.1-12.2,20.8-16.9,28.8-8.2,14.1-10.2,17.8-11.9,23.2-4,12.5-3.3,23.9-2.1,31.8"
        />
        <path
          id="Thorax31"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M221.9,373.9c-4.1,3.1-10.4,8.9-15,18-8.3,16.4-5.2,32.1-4,37"
        />
        <path
          id="Thorax30"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M226.9,378.9c-2.8,3.4-6.5,8.7-9,16-4.4,12.8-2.2,24-1,29"
        />
        <path
          id="Thorax29"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M211.9,331.9c0,17.7,9.4,32,21,32"
        />
        <path
          id="Thorax28"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M221.9,71.9c-4.2,1.1-10.5,3.4-17,8-17.8,12.7-21.6,32.6-22.3,37.7-.8,6.2-2.8,17.2,2.7,30.4,3.4,8.2,8.3,13.7,11.6,16.9"
        />
        <path
          id="Thorax27"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M211.6,96.8c-18.7,25.4,1.9,47.1,4.3,47.1"
        />
        <path
          id="Thorax26"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M253.9,115.8c0,1.4-28.8-34.9-87.8,2.5"
        />
        <path
          id="Thorax25"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M193.8,47.9c0,22.1-20.8,48-66.1,40"
        />
        <path
          id="Thorax24"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls10"
          d="M71.1,402.9c5.6,5.2,13.8,12.1,24.9,18.5,8.7,5,19.6,11.2,34.9,13.5,7.3,1.1,29.9,4.2,51-9,7-4.4,11.9-9.4,15-13"
        />
        <line
          id="Thorax23"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="101.1"
          y1="131.2"
          x2="130.1"
          y2="144.2"
        />
        <path
          id="Thorax22"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M79.9,123.8c22.3-22.2,29-13,17,17.6"
        />
        <path
          id="Thorax21"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M56.9,106.9c16.8,0,73.2,37.3,88,10"
        />
        <path
          id="Thorax20"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M61.9,122.9c6.7.2,10.7,2.3,13,4,1.5,1.1,2.5,2.2,9,11,5.8,7.9,6,8.3,7,9,3.3,2.2,9.6,3.2,23-4"
        />
        <path
          id="Thorax19"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M304.9,68.6c79-8.7,3.1,17.3,7,17.3"
        />
        <path
          id="Thorax18"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M252.9,71.9c-60-19-17,14,0,14"
        />
        <path
          id="Thorax17"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M237.5,81.1c-21.6,0-5.5,15.7-12.3,15.7"
        />
        <path
          id="Thorax16"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M311.9,47.9c4,18.1,7.4,19.6-17,18.1"
        />
        <path
          id="Thorax15"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M333.9,92.9c0-.5-8.6-40,35-1"
        />
        <path
          id="Thorax14"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M302.9,92.9c2.1-2.5,6.2-6.6,12.6-8.9.7-.3,3.7-1.3,7.9-1.6,6.2-.5,14.3.6,14.5,2.5.2,2-8.2,3.2-20,9-7.1,3.5-12.5,7.3-16,10"
        />
        <path
          id="Thorax13"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M238.9,44.9c-2.4,13.1-.9,17.1,1,18,2.4,1.2,5.2-2.4,11-3,5.2-.5,9.8,1.8,13,4"
        />
        <circle
          id="Thorax12"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          cx="279.9"
          cy="70.1"
          r="6.2"
        />
        <line
          id="Thorax11"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="263.9"
          y1="93.1"
          x2="295.5"
          y2="92.9"
        />
        <path
          id="Thorax10"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M260.9,83.9c2.8,1.5,6.8,3,11,2,5.4-1.3,7.1-5.6,11-5,3.3.6,3.5,3.9,8,6,3,1.4,5.9,1.3,8,1"
        />
        <path
          id="Thorax9"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M271.9,120.4c0-1.2,13.5-19.1,27-2.2"
        />
        <path
          id="Thorax8"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M265.3,148.5c0-.9,13.6-10.8,29.6-1.6"
        />
        <path
          id="Thorax7"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M261.9,161.9h34.1"
        />
        <path
          id="Thorax6"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          d="M267.9,179.9c0,2.2,8.2,4,18.2,4"
        />
        <line
          id="Thorax5"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls7"
          x1="271.9"
          y1="120.4"
          x2="297.9"
          y2="122.9"
        />
        <path
          id="Thorax4"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls8"
          d="M306.9,439.9c-4.6-1.9-11.5-4-19.7-3.3-6.4.5-9.2,2.3-15.4,3.3-5.5.9-13.5,1.2-24-2"
        />
        <line
          id="Thorax3"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls8"
          x1="249.9"
          y1="418.9"
          x2="301.9"
          y2="422.9"
        />
        <line
          id="Thorax2"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls8"
          x1="246.8"
          y1="120.4"
          x2="258"
          y2="152.9"
        />
        <line
          id="Thorax1"
          class="Thorax_Herzfernaufnahme_Inspiration_pa_stehend2107_cls8"
          x1="241.9"
          y1="418.9"
          x2="232.9"
          y2="432.9"
        />
      </g>
    </svg>
  );
}
