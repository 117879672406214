import style from "./style.scss";

export default function RechtesSchulterblattLatStehend2026(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.1 755.82">
      <g id="schoulder_plate" data-name="schoulder plate">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="scapula_lat_right2026_cls4"
            style={{
              fill: props.colors.scapula_lat_right2026_cls4,
              stroke: props.colors.scapula_lat_right2026_cls4,
            }}
            d="M110.29,713.69c4.06,5.35,11.33,5.47,11.85,5.47,7.31.01,11.85-5.31,12.76-6.38,1.05-1.23,4.81-5.98,4.56-19.14-.23-11.86-3.53-20.65-3.65-20.96-3.97-10.59-5.68-33.18-9.11-78.38-2.87-37.94-4.57-60.35-1.82-86.58,1.79-16.98,4.56-31.9,4.56-31.9,3.33-17.93,4.95-19.46,7.29-33.72,2.12-12.9.89-12.19,3.65-40.1,1.8-18.21,2.7-27.31,4.56-36.46,3.15-15.51,5.01-16.51,5.47-26.43.44-9.6-1.02-14.91,1.82-23.7,1.48-4.6,3.09-6.89,7.29-13.67,11.43-18.46,17.14-27.68,20.05-37.37,1.13-3.78,1.66-10.12,2.73-22.78.42-5.04,1.31-16.69,1.82-43.75.45-24.26-.1-23.83.91-28.25,1.91-8.36,6.13-16.82,14.58-33.72,4.39-8.8,6.64-13.26,9.11-16.41,8.21-10.46,18.12-15.48,23.7-18.23,16.37-8.08,32.81-10.94,32.81-10.94,6.32-1.1,12.24-1.57,16.41-6.38,2.27-2.62,3.03-5.69,4.56-11.85,2.34-9.45,3.54-14.28.91-18.23-2.59-3.88-7.53-6.42-10.94-5.47-7.33,2.05-13.23.52-19.14,1.82-13.31,2.93-24.63,1.44-35.54,0-10.5-1.39-13.89-4.1-23.7-3.65-3.67.16-8.69.44-14.58,2.73-9.43,3.67-12.96,9.34-14.58,8.2-2.15-1.5.78-13.68,8.2-22.78,2.62-3.22,5.36-5.45,6.38-10.03.9-4.04,0-7.82-.91-10.03-3.94-9.5-16.64-15.71-25.52-12.76-5.12,1.7-4.2,4.85-12.76,12.76-8.23,7.6-11.7,7.1-20.05,14.58-5.98,5.36-7.76,9.5-11.85,13.67-12.63,12.88-20.74,15.1-37.37,26.43-18.38,12.52-12.75,12.72-35.54,29.16-11.69,8.44-17.67,13.31-29.16,23.7-5.34,4.83-8.46,7.96-9.11,12.76-1.15,8.52,6.1,18.07,12.81,23.31.63.49,1.08.8,1.78,1.3,8.71,6.19,11.44,4.09,21.87,10.03,2.87,1.63,14.75,8.67,23.7,21.87,8.5,12.53,10.34,24.69,11.85,34.63,2.51,16.58-.69,20.48,1.82,41.01,1.31,10.69,3.05,16.79,4.56,23.7,2.46,11.26,3.79,22.32,3.65,73.82-.08,28.12-.12,43.13-.91,65.62-1.28,36.35-2.69,39.47-1.82,58.33.71,15.48,1.71,14.7,5.47,53.77,2.9,30.12,2.35,30.93,4.56,49.22,2.57,21.24,3.93,32.49,7.29,48.3,2.54,11.98,6.22,26.48,8.2,41.92.58,4.56,1.44,9.09,1.82,13.67.9,10.99-.17,14.41,2.73,18.23l-.03.04Z"
          />
        </g>
        <g id="scapula">
          <path
            id="scapula_1"
            data-name="scapula 1"
            class="scapula_lat_right2026_cls1"
            style={{
              fill: props.colors.scapula_lat_right2026_cls1,
              stroke: props.colors.scapula_lat_right2026_cls1,
            }}
            d="M39.21,133.3c20.37-5.01,33.83-13.7,41.01-19.14,5.56-4.21,12.88-9.76,20.05-19.14,5.82-7.61,6.1-10.64,11.85-15.49,6.48-5.48,9.45-4.44,19.14-10.94,7.9-5.3,13.78-9.25,13.67-14.58-.12-5.49-6.53-10.15-11.85-11.85-5.3-1.69-11.06-.92-22.78,4.56-14.69,6.88-24.82,14.66-28.25,17.32-25.47,19.79-53.9,43.97-38.28,31.9,3.63-2.81-17.33,13.41-30.08,22.78-5.9,4.33-10.55,7.71-11.85,13.67-1.95,8.91,5.01,17.3,7.29,20.05,7.23,8.72,14.02,9.44,24.61,15.49,0,0,17.74,10.14,29.16,27.34,9.17,13.81,9.81,27.34,10.94,44.66,4.4,67.23,5.51,26.85,7.29,83.85.68,21.7,1.25,51,.91,92.05-.5,61.32-2.34,65.38,0,92.05,1.02,11.63,1.15,8.36,7.29,62.89,1.85,16.42,3.04,27.42,5.47,43.75,1.51,10.14,1.43,8.44,5.47,33.72,2.04,12.77,4.94,25.43,6.38,38.28,2.08,18.52,1.29,26.18,7.29,30.08,4.48,2.9,11.42,2.87,16.41,0,1.31-.75,4.72-2.99,7.29-9.11,4.24-10.11,1.46-19.49-.91-30.99-2.23-10.81-2.99-21.88-4.56-32.81-1.39-9.69-2.47-22.39-4.56-47.39-2.35-28.08-3.53-42.12-3.65-49.22-.12-6.67-.28-26.71,2.73-52.86,2.45-21.35,4.24-22.58,9.11-51.95,5.3-32,3.22-32.58,9.11-70.18,4.99-31.84,6.06-29.49,6.38-40.1.22-7.15-.09-13.94,3.51-22.4,3.15-7.38,6.56-9.66,11.99-17.71,0,0,6.38-9.56,11.85-24.61,4.08-11.21,3.06-16.39,3.65-48.3.71-38.68,2.49-46.41,4.56-52.86,3.18-9.9,7.03-16.62,13.67-28.25,10-17.49,15.4-26.94,26.43-33.72,4.06-2.49,11.21-4.7,25.52-9.11,17.6-5.44,22.49-5.45,28.25-10.94,6.03-5.75,9.31-14.63,8.2-22.78-.31-2.23-1.11-8.09-5.47-10.94-4-2.61-8.57-1.12-14.58,0-8.24,1.54-15.21,1.16-29.16.91-9.33-.16-17.43-1.38-26.43-2.73-13.83-2.07-14.35-3.15-19.14-2.73-12.59,1.12-21.44,9.71-25.52,13.67-10.07,9.78-13.6,20.17-19.14,36.46-4.95,14.56-8.47,24.91-8.2,39.19.25,13.18,3.51,18.36,0,28.25-3.56,10.02-8.92,10.38-12.76,19.14-4.24,9.69-1.06,16.97,3.65,38.28,5.58,25.25,8.37,37.87,7.29,51.04-1.03,12.56-4.52,21.61-10.94,38.28-10.36,26.9-16.6,28.84-17.32,43.75-.68,14.2,4.77,16.6,3.65,33.72-.67,10.24-2.96,14.43-7.29,29.16-1.13,3.82-7.64,26.21-10.94,50.13-2.82,20.38-.77,23.52-3.65,48.3-1.78,15.25-4.13,27.66-5.89,35.91"
          />
          <path
            id="scapula_2"
            data-name="scapula 2"
            class="scapula_lat_right2026_cls1"
            style={{
              fill: props.colors.scapula_lat_right2026_cls1,
              stroke: props.colors.scapula_lat_right2026_cls1,
            }}
            d="M115.75,214.43C69.04,177.88,23.3,137.42,13.67,123.29"
          />
          <path
            id="scapula_3"
            data-name="scapula 3"
            class="scapula_lat_right2026_cls1"
            style={{
              fill: props.colors.scapula_lat_right2026_cls1,
              stroke: props.colors.scapula_lat_right2026_cls1,
            }}
            d="M51.04,115.08c62.43,84.76,126.21,22.99,117.57-55.59"
          />
          <path
            id="scapula_4"
            data-name="scapula 4"
            class="scapula_lat_right2026_cls1"
            style={{
              fill: props.colors.scapula_lat_right2026_cls1,
              stroke: props.colors.scapula_lat_right2026_cls1,
            }}
            d="M43.75,140.61c46.48,37.37,111.19-76.56,139.44-117.57"
          />
          <path
            id="scapula_5"
            data-name="scapula 5"
            class="scapula_lat_right2026_cls1"
            style={{
              fill: props.colors.scapula_lat_right2026_cls1,
              stroke: props.colors.scapula_lat_right2026_cls1,
            }}
            d="M157.67,1.16c-113.73,92.29,29.09,27.11,25.52,11.85"
          />
          <path
            id="scapula_6"
            data-name="scapula 6"
            class="scapula_lat_right2026_cls1"
            style={{
              fill: props.colors.scapula_lat_right2026_cls1,
              stroke: props.colors.scapula_lat_right2026_cls1,
            }}
            d="M108.82,155.83c15.13-20.69,47.03-67.3,72.97-132.79"
          />
        </g>
        <g id="ribs">
          <path
            id="ribs_1"
            data-name="ribs 1"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M246.07,754.71c-10.77-17.45.33-46.95,33.74-78.95"
          />
          <path
            id="ribs_2"
            data-name="ribs 2"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M289.13,615.27c-37.45,13.23-75.29,93.62-43.05,139.44"
          />
          <path
            id="ribs_3"
            data-name="ribs 3"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M293.47,666.3c-26.57,10.86-41.71,51.57-21.87,82.03"
          />
          <path
            id="ribs_4"
            data-name="ribs 4"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M289.13,464.88c-35.42-8.13-139.16,67.99-43.05,188.66"
          />
          <path
            id="ribs_5"
            data-name="ribs 5"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M289.13,501.34c-36.78,6.11-77.92,87.12-59.46,130.33"
          />
          <path
            id="ribs_6"
            data-name="ribs 6"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M240.43,556.83c21.24,21.71,45.16,43.23,53.04,49.32"
          />
          <path
            id="ribs_7"
            data-name="ribs 7"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M233.9,566.51c10.77,19.09,24.02,46.53,37.7,56.96"
          />
          <path
            id="ribs_8"
            data-name="ribs 8"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M282.54,364.64c-54.12,16.2-112.96,106.85-67.62,152.94"
          />
          <path
            id="ribs_9"
            data-name="ribs 9"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M278,323.62c-86.36,24.17-116.97,123.32-63.08,193.96"
          />
          <path
            id="ribs_10"
            data-name="ribs 10"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M293.47,189.64c-68.45,5.34-147.08,112.12-94.72,200.82"
          />
          <path
            id="ribs_11"
            data-name="ribs 11"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M289.12,230.66c-57.6,26.37-102.28,79.94-104.11,122.13"
          />
          <path
            id="ribs_12"
            data-name="ribs 12"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M289.13,114c-53.03,18.89-97.26,80.81-105.87,133.06"
          />
          <path
            id="ribs_13"
            data-name="ribs 13"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M252.75,100.33c-56.91,13.65-92.92,105.25-63.92,185.35"
          />
          <path
            id="ribs_14"
            data-name="ribs 14"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M278,32.89c-56.33,19.13-105.65,85.52-100.28,163.14"
          />
          <path
            id="ribs_15"
            data-name="ribs 15"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M247.71,12.84c-48.71,21.66-99.76,134.86-69.99,183.19"
          />
          <path
            id="ribs_16"
            data-name="ribs 16"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M210.76,21.04c-15.2,0-27.5,29.69-27.5,66.39"
          />
          <line
            id="ribs_17"
            data-name="ribs 17"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="294.74"
            y1="521.15"
            x2="281.27"
            y2="508.87"
          />
          <line
            id="ribs_18"
            data-name="ribs 18"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="266.16"
            y1="513.9"
            x2="293.47"
            y2="546"
          />
          <line
            id="ribs_19"
            data-name="ribs 19"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="252.75"
            y1="474.7"
            x2="213.23"
            y2="431.16"
          />
          <line
            id="ribs_20"
            data-name="ribs 20"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="289.34"
            y1="459.26"
            x2="238.52"
            y2="391.67"
          />
          <line
            id="ribs_21"
            data-name="ribs 21"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="279.81"
            y1="393.8"
            x2="263.93"
            y2="372.78"
          />
          <line
            id="ribs_22"
            data-name="ribs 22"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="282.53"
            y1="441.11"
            x2="243.19"
            y2="387.42"
          />
          <path
            id="ribs_23"
            data-name="ribs 23"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M240.43,483.74c-16.4,0-27.2-17.67-27.2-52.58"
          />
          <path
            id="ribs_24"
            data-name="ribs 24"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M243.19,338.02c-8.52-15.4-22.45-28.28-26.21-52.34"
          />
          <line
            id="ribs_25"
            data-name="ribs 25"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="276.84"
            y1="291.87"
            x2="240.43"
            y2="261.82"
          />
          <line
            id="ribs_26"
            data-name="ribs 26"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="257.12"
            y1="330.94"
            x2="216.98"
            y2="285.68"
          />
          <line
            id="ribs_27"
            data-name="ribs 27"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="282.53"
            y1="278.96"
            x2="252.75"
            y2="251.68"
          />
          <line
            id="ribs_28"
            data-name="ribs 28"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="203.96"
            y1="192.34"
            x2="225.74"
            y2="222.85"
          />
          <line
            id="ribs_29"
            data-name="ribs 29"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="266.95"
            y1="196.22"
            x2="237.05"
            y2="148.98"
          />
          <line
            id="ribs_30"
            data-name="ribs 30"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            x1="221.36"
            y1="227.81"
            x2="198.01"
            y2="203.68"
          />
          <path
            id="ribs_31"
            data-name="ribs 31"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M206.3,245.6s-8.29-14.52-8.29-41.92"
          />
          <path
            id="ribs_32"
            data-name="ribs 32"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M219.88,358.55c-7.51,0-15.92-16.68-15.92-57.12"
          />
          <path
            id="ribs_33"
            data-name="ribs 33"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M226.83,112.8c-.95,0-1.72-20.43-1.72-45.69"
          />
          <path
            id="ribs_34"
            data-name="ribs 34"
            class="scapula_lat_right2026_cls3"
            style={{
              fill: props.colors.scapula_lat_right2026_cls3,
              stroke: props.colors.scapula_lat_right2026_cls3,
            }}
            d="M188.12,153.82c2.2-11.23,11.11-44.44,23.8-70.29"
          />
        </g>
        <g id="upper_arm" data-name="upper arm">
          <path
            id="aupper_arm_1"
            data-name="aupper arm 1"
            class="scapula_lat_right2026_cls2"
            style={{
              fill: props.colors.scapula_lat_right2026_cls2,
              stroke: props.colors.scapula_lat_right2026_cls2,
            }}
            d="M292.58,250.9c-2.77,2.95-6.4,7.2-10.03,12.76-4.73,7.24-9.76,17.19-13.67,33.72-3.67,15.51-4.36,28.47-4.56,44.66-.35,28.97,1.1,51.45,1.82,61.97,1.46,21.39,3.21,41.92,6.38,73.82,3.36,33.84,3.82,35.21,8.2,77.47,6.85,66.15,5.55,61.29,8.2,79.29,3.79,25.82,6.89,38.77,9.11,65.62,1.42,17.16,1.76,31.38,1.82,41.01"
          />
          <path
            id="upper_arm_2"
            data-name="upper arm 2"
            class="scapula_lat_right2026_cls2"
            style={{
              fill: props.colors.scapula_lat_right2026_cls2,
              stroke: props.colors.scapula_lat_right2026_cls2,
            }}
            d="M294.39,128.79c-3.92-6.79-11.47-17.65-24.61-26.43-14.1-9.42-27.45-11.64-34.63-12.76-12.89-2.01-22.69-.79-35.54.91-15.79,2.08-27.82,3.67-40.1,9.11-26.12,11.57-45.85,37.24-52.86,64.71-5.79,22.66-1.53,41.11,1.82,55.59,4.11,17.8,7.28,31.51,19.14,41.01,7.47,5.99,12.48,5.58,28.25,16.41,7.46,5.12,12.16,8.35,17.32,13.67,13.49,13.91,18.39,30.02,21.87,41.92,4.1,14,5.1,25.12,5.47,29.16,2.62,28.87,6.59,57.63,8.2,86.58,2.35,42.12,6.29,74.42,9.11,97.52,6.62,54.23,11,64.59,15.49,113.01,2.33,25.07,4.56,55.59,4.56,55.59,1.22,16.73,2.12,30.79,2.73,41.01"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="scapula_overlay"
          data-name="scapula overlay"
          class="Scapula2026Fill"
          style={{
            fill: props.colors.Scapula2026Fill,
            stroke: props.colors.Scapula2026Fill,
          }}
          d="M110.29,713.69c4.06,5.35,11.33,5.47,11.85,5.47,7.31.01,11.85-5.31,12.76-6.38,1.05-1.23,4.81-5.98,4.56-19.14-.23-11.86-3.53-20.65-3.65-20.96-3.97-10.59-5.68-33.18-9.11-78.38-2.87-37.94-4.57-60.35-1.82-86.58,1.79-16.98,4.56-31.9,4.56-31.9,3.33-17.93,4.95-19.46,7.29-33.72,2.12-12.9.89-12.19,3.65-40.1,1.8-18.21,2.7-27.31,4.56-36.46,3.15-15.51,5.01-16.51,5.47-26.43.44-9.6-1.02-14.91,1.82-23.7,1.48-4.6,3.09-6.89,7.29-13.67,11.43-18.46,17.14-27.68,20.05-37.37,1.13-3.78,1.66-10.12,2.73-22.78.42-5.04,1.31-16.69,1.82-43.75.45-24.26-.1-23.83.91-28.25,1.91-8.36,6.13-16.82,14.58-33.72,4.39-8.8,6.64-13.26,9.11-16.41,8.21-10.46,18.12-15.48,23.7-18.23,16.37-8.08,32.81-10.94,32.81-10.94,6.32-1.1,12.24-1.57,16.41-6.38,2.27-2.62,3.03-5.69,4.56-11.85,2.34-9.45,3.54-14.28.91-18.23-2.59-3.88-7.53-6.42-10.94-5.47-7.33,2.05-13.23.52-19.14,1.82-13.31,2.93-24.63,1.44-35.54,0-10.5-1.39-13.89-4.1-23.7-3.65-3.67.16-8.69.44-14.58,2.73-9.43,3.67-12.96,9.34-14.58,8.2-2.15-1.5.78-13.68,8.2-22.78,2.62-3.22,5.36-5.45,6.38-10.03.9-4.04,0-7.82-.91-10.03-3.94-9.5-16.64-15.71-25.52-12.76-5.12,1.7-4.2,4.85-12.76,12.76-8.23,7.6-11.7,7.1-20.05,14.58-5.98,5.36-7.76,9.5-11.85,13.67-12.63,12.88-20.74,15.1-37.37,26.43-18.38,12.52-12.75,12.72-35.54,29.16-11.69,8.44-17.67,13.31-29.16,23.7-5.34,4.83-8.46,7.96-9.11,12.76-1.15,8.52,6.1,18.07,12.81,23.31.63.49,1.08.8,1.78,1.3,8.71,6.19,11.44,4.09,21.87,10.03,2.87,1.63,14.75,8.67,23.7,21.87,8.5,12.53,10.34,24.69,11.85,34.63,2.51,16.58-.69,20.48,1.82,41.01,1.31,10.69,3.05,16.79,4.56,23.7,2.46,11.26,3.79,22.32,3.65,73.82-.08,28.12-.12,43.13-.91,65.62-1.28,36.35-2.69,39.47-1.82,58.33.71,15.48,1.71,14.7,5.47,53.77,2.9,30.12,2.35,30.93,4.56,49.22,2.57,21.24,3.93,32.49,7.29,48.3,2.54,11.98,6.22,26.48,8.2,41.92.58,4.56,1.44,9.09,1.82,13.67.9,10.99-.17,14.41,2.73,18.23l-.03.04Z"
        />
        <path
          id="ribs_overlay"
          data-name="ribs overlay"
          class="ribs2026Fill"
          style={{
            fill: props.colors.ribs2026Fill,
            stroke: props.colors.ribs2026Fill,
          }}
          d="M271.6,748.33l-25.53,6.38c-16.13-23.67-16.33-57.27.01-101.17-33.06-42.87-51.99-87.27-31.16-135.96-20.93-26.83-30.27-58.29-25.46-95.4,1.46-10.49,4.61-21.07,9.29-31.72-17.83-29.75-22.77-64.56-9.29-106.6-10.92-23.26-12.32-54.38-10.88-87.83-13.03-12.4-15.05-42.75,4.68-108.6,1.59-39.53,9.5-65.25,28.66-66.39l35.79-8.2,30.29,20.05c-20.53,7.54-38.79,17.8-52.89,33.01l1.72,46.9c8.63-6.06,17.06-10.48,25.17-12.47l37.13,13.67c-21.07,7.23-38.68,18.94-52.94,35.01l30.75,47.21c10.57-3.95,19.55-6.29,26.52-6.58l-4.35,41.02-6.59,48.3-29.78-27.28c-6.16,4.36-29.95-33.27-48.79-60.28l-5.95,12.28c11.34,11.91,32.53,30.52,23.27,26.58,23.47-29.4,72.19-4.79,18.68,31.21,0,0,18.83,17.42,36.88,30.4l1.16,31.75-20.88,7.32-40.14-45.26c2.73,17.01,12.79,34.55,26.21,52.34l12.24-5.82,27.11,32.44-18.61,8.14,15.88,21.02,2.72,47.31-39.34-53.69-4.67,4.25,50.82,67.59-.21,5.62c-11.7-1.65-23.86,1.95-36.38,9.82l-38.74-42.68c-3.45,22.36,9.51,55.31,27.59,50.71l9.27-6.86,38.46,25.46-9.53,3.06-13.65,9.5,27.31,31.16v61.09s-55.89-51.18-55.89-51.18l-3.68,11.54c14.07,28.25,26.9,48.81,37.7,56.96l17.53-8.2,4.34,51.03c-30.86,18.72-40.72,54.21-21.87,82.03Z"
        />
      </g>
    </svg>
  );
}
