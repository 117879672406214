import { Button, Modal, Row, Col } from "react-bootstrap";
import pmViewImage from "./Images/pmView.bmp";
import pmed from "./Images/pmed.png";
import "./stylesheets/ViewerSelectModal.scss";

export default function ViewerSelectModal({
  shown,
  onHide,
  localViewerCallback,
  webViewerCallback,
  headerless = false,
}) {
  return (
    <Modal show={shown} onHide={onHide} size="sm">
      {!headerless && (
        <Modal.Header className="bg-light py-2" closeButton>
          <h5>Select Viewer</h5>
        </Modal.Header>
      )}
      <Modal.Body className="text-center">
        <Row>
          <Col
            onClick={localViewerCallback}
            className="d-flex flex-column border rounded mx-2 py-2 button-col"
          >
            <img
              className="my-1 align-self-center"
              src={pmViewImage}
              style={{ width: "48px" }}
              alt="pmView viewer icon"
            />
            <h5>pmView</h5>
          </Col>
          <Col
            onClick={webViewerCallback}
            className="d-flex flex-column border rounded mx-2 py-2 button-col"
          >
            <img
              className="my-1 align-self-center"
              alt="webViewer icon"
              src={pmed}
              style={{ width: "48px" }}
            />
            <h5>webViewer</h5>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
