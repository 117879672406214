function RechteSchoulderFocus() {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 566.6 518.1"
    >
      <g id="Ebene_1_00000062168923372842817050000014552537912866504591_">
        <g>
          <g>
            <path
              class="st0"
              d="M46.3,47.7l28.9-10.9c2.8-1.1,5.8-1.6,8.8-1.6h85.7c0.5,0,0.8,0.6,0.4,1l-4.9,4.8c-0.5,0.5-0.2,1.3,0.5,1.3
				l246.2,3.4c1.1,0,1.7-1.3,0.9-2.1l-3.7-3.9c-0.5-0.5-0.1-1.3,0.5-1.3l106.8,0.5c2.9,0,5.7,1.3,7.5,3.5L537.4,58
				c1.1,1.2,0.2,3.2-1.4,3.2c-175.7,4.1-346,2.5-489.4-11.8C45.6,49.4,45.4,48,46.3,47.7L46.3,47.7z"
            />
            <g>
              <path
                class="st1"
                d="M49.2,48.1L530.9,56c5.3,0.1,9.4,4.5,9.1,9.8L519.7,427c-0.3,4.9-4.3,8.7-9.2,8.8l-444,2.2
					c-5,0-9.1-3.9-9.3-8.8L39.8,57.8C39.6,52.4,43.9,48,49.2,48.1L49.2,48.1z"
              />
              <rect
                x="239.3"
                y="284.9"
                class="st2"
                width="84.9"
                height="126.5"
              />
              <rect x="124.4" y="110" class="st2" width="84.9" height="126.5" />
              <rect
                x="363.8"
                y="110.5"
                class="st2"
                width="84.9"
                height="126.5"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            class="st3"
            d="M473.2,157.2c6.9-9,12.2-18.5,13.5-29.2c1.2-9.6,2.6-17.8,5.9-17c1.7,0.2,2.2,4.5,2.6,9.7v-17.4
			c0-1.3,0.2-2.7,0.6-4c0.8-2.5,2-3.9,3.5-4c1.3-0.1,2.5,0.8,3,2l0.6,1.6c1.5-6.4,3.5-11.2,6.8-10.5c1,0.2,1.7,1.2,1.7,2.3v6
			l-0.9,27.2c0,0.4-0.1,0.7-0.2,1.1l-9.1,33.3c-3.8,18.6-7.5,36.8-12.5,42.4c-2.6,3.5-3.6,7.3-3.8,11.1l-6.5,48.8
			c-2.1,22.8-7,44.8-14.7,65.9c-2.6,7.2-5.9,14.1-9.3,20.9c-7.9,15.4-5.5,26.9-45.4,28c-28.5,2.5-58.4,2.2-89.5-0.5
			c-0.6,0-1.2-0.1-1.8-0.1c-8.9-0.2-11.5-7.4-22.4-5.6c-4.4,1-8.3,2.3-11.6,3.8c-6.4,3-10.2,9.8-9.4,16.8c0.4,3.5,1.2,7.4,2.3,11.8
			c9.2,28.6,7.8,51.3-10.9,64.2c-2.2,10.2-4.2,20.1-6,29.6l-134.6-9.4l-5.9-76.9c-6.5,28-14.6,53-26.1,72.5l-54.1-34
			c7.7-26.9,17.4-50.2,28.1-71.5c5-32.2,21.7-47.1,48.5-47.2c6.2-1.8,12.7-3.6,15.3-6.4c6.2-6,21.1-12,41.3-17l0.5-4.6l-7.8-0.8
			c-19.4-17.3-28.7-36.6-27.2-67.3c-4.6-16.9-6.1-30.9-5.3-42.8c2.7-30.5,18.3-48.6,43-57.9c28-10.1,52.7-5.9,74,14.1
			c20.3,22.1,24.9,48.8,14,80c3.5,17.8-10.8,29.2-28.7,39.3c-2.8,9.5-5.1,23.3-6.8,42.5c4.1,4.3,13.6,4.9,20,9.8
			c2.4,1.8,5.5,2,8.1,0.6c8.6-5.7,19.1-7.9,31.9-6.3c8.4-0.2,16.1,2.6,23.4,7.3c33.9-3.3,63.3-1.7,89.3,3.7c6,1.8,10.7,0.6,13.4-5.5
			c5.5-31.9,28.8-76.1,35.3-107.2c0.1-0.7,0.3-1.3,0.5-2c1.8-5.4,5.8-8.3,7.3-16.4c-2-14-2.3-26.3,2.1-34.5
			c4.3-6.4,7.4-12.8,9.4-19.1c5.1-9.2,11.5-14.6,19.1-16.7"
          />
          <g>
            <path
              class="st4"
              d="M152.9,216.8c4.5,3.6,7.7,9.3,8.4,18.9l-5.2,19.1c1.2,3.8,4.5,6.9,8.9,9.6"
            />
            <path class="st4" d="M252.7,220c4.9-2.2,8.5,0,10.9,6.6" />
            <path
              class="st4"
              d="M244.3,248.3c2.3-2.9,2.7-6.2,2-9.8c2.3-2.5,4.5-4.5,6.5-5.3s4.2,1,3.8,3.1c-1.6,8.3-6.5,14.2-12.6,19.3
				c-2.5,2.4-4.1,2-5.2,0"
            />
            <path class="st4" d="M214.9,285.2c-9.6,9.5-23.9,14.5-42,16" />
            <path
              class="st4"
              d="M265.7,465.9c-13.9,7.3-27.1,8.5-39,0c-9.2-8-10.7-21.2-10.6-35.4"
            />
            <path
              class="st4"
              d="M131.3,438.5c0.5,12.6,8.7,24.3,19.4,30.5c9.7,5.6,21.6,7.4,32.5,5.2c8.6-1.7,17.5-5.9,22.7-13.2
				c6-8.4,6.3-20.7,6.7-30.6"
            />
            <path
              class="st4"
              d="M115.2,328.9c11.6,0.5,18.8,5.1,21.8,13.8c2.4,8.2,0.8,19.2-1.6,30.7"
            />
            <path
              class="st4"
              d="M160.7,351.2l36.3,9.1c2.2,0.6,3.8,2.6,3.8,4.9v0.3"
            />
            <path
              class="st4"
              d="M220,365.5v-2.7c0-2.6,1.7-5,4.2-5.7l28.5-8.6"
            />
            <path class="st4" d="M226,295.4c-6.7,9-11.7,20.2-13.4,36" />
            <path class="st4" d="M192,303.2c5.7,3.9,8.1,10,8.8,17.2" />
            <line class="st4" x1="228" y1="308.5" x2="229.4" y2="326.9" />
            <line class="st4" x1="172.4" y1="305.8" x2="169.8" y2="317.7" />
          </g>
          <path class="st4" d="M491.9,147.6c0.5-0.9,3.3-26.9,3.3-26.9" />
          <polyline class="st4" points="499,145.8 502.5,127.4 502.8,99 		" />
          <path
            class="st3"
            d="M511.3,94.8c0-1,1.4-3,2.4-3.4c2.6-0.9,3.9,3.6,4.3,5.4c0.6,2.8,0.5,6.3-0.5,9l-7,18.1
			C510.4,124,511.3,94.9,511.3,94.8L511.3,94.8z"
          />
          <path class="st4" d="M256.2,318.9c-1.9,2.2-3.1,4.5-3.4,6.7" />
        </g>
        <g>
          <rect x="229.9" y="310.2" class="st5" width="101.9" height="82.7" />
          <circle class="st5" cx="280.8" cy="351.5" r="11.2" />
          <line class="st5" x1="280.8" y1="335.9" x2="280.8" y2="310.2" />
          <line class="st5" x1="298.3" y1="351.5" x2="331.7" y2="351.5" />
          <line class="st5" x1="280.8" y1="392.4" x2="280.8" y2="369.1" />
          <line class="st5" x1="263.3" y1="351.5" x2="229.9" y2="351.5" />
        </g>
      </g>
      <g id="Ebene_2_00000043425698308522397960000004878812365555646119_">
        <g>
          <g>
            <g>
              <path
                class="st6"
                d="M123.6,471.8c2.8,3.6,6.2,4.4,10.1,2.6c0.5-0.2,1-0.1,1.3,0.3c2.6,3.3,5.6,3.4,8.9,1.4
					c0.6-0.3,1.3-0.2,1.7,0.2c2.3,2.4,4.8,2.9,7.5,1c0.4-0.3,0.9-0.2,1.2,0.1c2.6,2.6,5.4,3,8.4,0.8c0.4-0.3,1-0.3,1.5,0
					c3.3,2.6,6.6,2.2,10-0.5c2.9,1.3,5.4,1.6,7.2-0.1c0.5-0.5,1.3-0.6,2-0.3c2.7,1,5.1,0.9,7-1.3c0.4-0.4,0.9-0.6,1.4-0.4
					c3.5,1.3,6.2,0.3,8.5-2.3c0.3-0.4,0.8-0.5,1.3-0.4c3.4,1,6.4,0.8,7.8-3.4"
              />
              <g>
                <path
                  class="st6"
                  d="M264.1,473.2c-0.7,3.2-3.5,3.1-5.6,1.6c-0.4-0.3-0.9-0.2-1.3,0.1c-2.6,2.8-5.5,2.7-8.6,0.2
						c-2.4,1.5-4.5,2-6.2,0.4c-0.5-0.5-1.2-0.5-1.7-0.2c-2.3,1.2-4.3,1.2-6.1-0.9c-0.3-0.4-0.8-0.5-1.2-0.3
						c-2.9,1.5-5.4,0.7-7.5-1.7c-0.3-0.3-0.8-0.5-1.1-0.3c-2.8,1.2-5.4,1.3-7-2.8"
                />
                <path
                  class="st6"
                  d="M264.1,473.2c-4.2,0.8-8.3,1.4-12.4,1.7c-13.1,0.4-26.7-1.9-38.6-7c-13.2,6.1-27.5,9.4-42.6,10
						c-14.8,0-30.6-2.4-46.9-6.1l-3.1-47c12.4-4.3,23-10,29.8-18.8c1.6-2,4.5-2.4,6.5-0.8l4,3.4c3-1,5.6,0,7.3,4.7
						c3.9-1.2,6.5,0.6,7.9,5.2c4.7,0.1,7.8,1.3,7.7,7c3.2-0.7,5.5,1.2,6,5c3.7,0.2,6.7,1.5,6.8,6.7c5.2,0.6,7.1,3,5.8,7.9
						c5.1,1.5,7.5,4.5,7.3,9l4.3-1.7l2.5,1.5c-0.6-4.5,1.3-7.6,5.6-9.4c-1.6-4.8-0.1-7.3,4.3-8.3c-0.3-5.2,2.2-6.7,5.4-7.1
						c0.1-3.8,1.9-5.9,4.8-5.4c-0.6-5.7,2-7,6-7.5c0.8-4.7,3-6.6,6.4-5.7c1.1-4.8,3.2-6,5.9-5.2l3.2-3.7c1.6-1.8,4.1-1.6,5.6,0.3
						c4.4,5.6,10.1,9.6,16.7,12.7c5.1,21.9,2,40.1-14.6,52.1L264.1,473.2L264.1,473.2z"
                />
                <path
                  class="st6"
                  d="M216.3,453.8c5.1,10.5,13,17,24.6,18.4c8.2,1,16.5-0.4,23.7-5c7.9-5.1,13.5-13.1,16.5-23.2
						c3.8-13-0.9-29.6-0.9-29.6"
                />
                <path class="st6" d="M258.5,400.8c6.3,27.4,8.9,51.7,2.2,68.4" />
                <path class="st6" d="M231.8,469.8c7.4-18.4,17.8-33.9,30.9-47" />
              </g>
              <g>
                <path
                  class="st6"
                  d="M148.7,408.2c-12.5,30.6-25.1,57-37.9,71.8l0.3,5.9c0,0.2,0.2,0.3,0.4,0.1c15.3-18.1,30-47,44.5-81.5"
                />
                <path
                  class="st6"
                  d="M118.9,410.8c-4.2,25.1-14.5,56.2-13.4,69.9c0.1,1.1,0.7,2.1,1.6,2.8l3.5,2.5c0.2,0.2,0.5,0,0.5-0.3
						c-2.5-13.9,4.2-37.8,9.1-65.4"
                />
              </g>
            </g>
            <path
              class="st7"
              d="M209.5,454c-4.9,10.9-13.4,18-26.7,20.3c-9.3,1.6-19.1,0.9-27.7-3.1c-9.6-4.5-17.3-11.8-21.1-21.8
				c-3.6-9.2-3.9-19.3-2.7-29.1"
            />
            <path class="st7" d="M155.9,404.6c-4.7,27.8-5.4,52.3,3.9,68.3" />
            <path class="st7" d="M193,471.2c-10.2-17.7-23.7-32.3-40-44.3" />
          </g>
          <line class="st7" x1="213.7" y1="452.4" x2="213.1" y2="467.8" />
        </g>
      </g>
      <polyline class="st8" points="315,308.7 406.2,318.9 437.8,226.6 " />
      <path
        class="st8"
        d="M406.2,387.7l-78.1-4.6l-38.2-2.3c0,5.1,0,18.3,0,32.5c0,17.3,0,46.9,0,54.6"
      />
      <path class="st8" d="M377.1,315.7c6-18.5,19.6-24.4,37.9-22.5" />
      <path class="st8" d="M328.1,383c-6.7,19.5-19.4,28.8-38.2,27.8" />
      <text transform="matrix(1 0 0 1 318.9694 427.2299)" class="st9 st10 st11">
        90°
      </text>
      <text transform="matrix(1 0 0 1 370.9565 285.1808)" class="st9 st10 st11">
        90°
      </text>
      <line class="st8" x1="487.6" y1="91.3" x2="467.2" y2="169" />
      <path
        class="st12"
        d="M180.8,126c-37,7.9-69,56.9-47.3,107c-4.4,23.1,9,63.5,28.9,68.3"
      />
      <g>
        <g>
          <line class="st12" x1="120.4" y1="245.1" x2="117.5" y2="245.7" />
          <line class="st13" x1="112.2" y1="246.8" x2="72.1" y2="255.3" />
          <line class="st12" x1="69.4" y1="255.9" x2="66.5" y2="256.5" />
          <g>
            <polygon
              class="st14"
              points="71.3,250.3 67.4,256.3 73.4,260.2 69.2,261.1 63.1,257.2 67.1,251.2 			"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <line class="st12" x1="462.3" y1="134.2" x2="459.4" y2="135.1" />
          <line class="st15" x1="453.9" y1="136.9" x2="357" y2="168.7" />
          <line class="st12" x1="354.2" y1="169.6" x2="351.3" y2="170.6" />
          <g>
            <polygon
              class="st14"
              points="355.4,163.9 352.2,170.3 358.6,173.6 354.5,174.9 348.1,171.7 351.3,165.2 			"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RechteSchoulderFocus;
