import style from "./style.scss";

function SymphyseDvLiegend1205(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 362.8 362.8"
    >
      <g id="BG">
        <path
          id="BG2"
          class="Symphyse_dv_liegend1205_cls3"
          d="M2.9,200.5c12.6,22.5,32.5,43.7,59,55.2,11.8,3.9,23,6,32.1,2.7,8.3-4.2,17.9-7.3,29.3-8.6,11.3-1.4,16.1-7.2,15.5-16.7,4.1-9.5,9.4-14.5,15.9-15.3,13.8-4.7,22.9-12.6,26-24.5,3.3-14.7.7-27.5-10.1-37.9-7.2-5.2-14.5-8-22.1-8-18.6,5.5-30.6.4-39.2-10.2l-42.6-37c-5.5,16.8-7.6,37.3-6.5,60.6,13.4.2,29.5,8.2,46.4,18.4,6.9,7.8,11.1,16.7,4,30.1-8.9,9.1-18.9,12.5-30.8,6.9-10.9-9.2-16.5-28-19.6-55.5-1.8-15.9,0-38.4,6.5-60.6h-4.7l-43.8,4.8L2.9,200.5Z"
        />
        <path
          id="BG1"
          class="Symphyse_dv_liegend1205_cls3"
          d="M258,135.6c-8.6,7.7-19.1,12.3-31.6,13.9l-18.7,1.1c-8,2.7-14.1,9.4-15,26.2.1,12.3,3.2,23.7,12.6,33,8.9,5.5,17.3,13.4,24.8,26.5,11.6,14.7,24.9,26.1,43.5,26.3,17.7-.8,40.7-13.8,67.5-35.7,8.6-8.9,14.7-20.8,18.8-35.2l-3.1-26.9-7.4-61.4-39.9.4c.2,21.3-1.1,41.5-4.2,60.6-2.4,16.3-7.3,31.3-17.7,43.1-7.9,9.6-16.8,10.5-26.4,6.6-10.8-5.3-12-13.5-9.8-22.5,4.4-11.4,14.1-17.5,26.4-21.1l27.5-6.1c3.1-20.3,4.5-40.5,4.2-60.6l-51.5,31.8h0Z"
        />
      </g>
      <g id="underlays">
        <ellipse
          id="symphyse_underlay"
          data-name="symphyse underlay"
          class="Symphyse1205Fill"
          style={{
            fill: props.colors.Symphyse1205Fill,
            stroke: props.colors.Symphyse1205Fill,
          }}
          cx="187.4"
          cy="180.2"
          rx="8.8"
          ry="25.6"
        />
        <g id="Obturatum_underlays" data-name="Obturatum underlays">
          <path
            class="Foramen_Obturatum1205Fill"
            style={{
              fill: props.colors.Foramen_Obturatum1205Fill,
              stroke: props.colors.Foramen_Obturatum1205Fill,
            }}
            d="M61.4,162.6c.8-.7,2.1-1,10.8,1.2,8.5,2.2,12.8,3.2,18,5.6,6.9,3.1,11.2,5.1,15.6,9.6,2.6,2.7,7.6,7.9,8.4,16,.2,2,.7,8.1-3.2,14-5,7.5-13.4,8.8-16,9.2-2.3.4-7.9,1.2-13.6-1.6-5.2-2.6-7.6-6.8-10.4-11.6-2.2-3.8-3.3-6.8-7.2-22.4-3.8-15-4.3-18.4-2.4-20Z"
          />
          <path
            class="Foramen_Obturatum1205Fill"
            style={{
              fill: props.colors.Foramen_Obturatum1205Fill,
              stroke: props.colors.Foramen_Obturatum1205Fill,
            }}
            d="M305,164.2c-1.3-1.1-4,.9-9.2,2.4-3.7,1.1-5.2,1-9.2,1.6-5.9.9-12.9,2-19.6,6-2,1.2-8.3,5.1-12.8,12.8-2.2,3.7-4.4,7.4-4,12.4.5,7.4,6.1,11.9,7.2,12.8,5.1,4.1,12,5.6,18,4,5.5-1.4,8.4-4.9,13.2-10.4,6.1-7,8.8-13.7,11.6-20.4,1.8-4.4,0-1,4.4-15.6.7-2.4,1.4-4.7.4-5.6Z"
          />
        </g>
      </g>
      <g id="Anatomy">
        <g id="Foramen_Obturatum" data-name="Foramen Obturatum">
          <path
            id="Foramen_Obturatum5"
            data-name="Foramen Obturatum5"
            class="Symphyse_dv_liegend1205_cls2"
            d="M329.4,162.2c-14.9.6-27,2.5-35.6,4.3-28.9,5.9-35.3,13.4-37,15.7-1.6,2.1-9.1,11.6-5.7,21.4,3,8.6,13.1,13.5,21.4,12.8,11.3-.9,18-12,22.8-19.9,3.2-5.3,9.6-17.3,12.8-54.1.1-1.6.3-3.3.4-5.1"
          />
          <path
            id="Foramen_Obturatum4"
            data-name="Foramen Obturatum4"
            class="Symphyse_dv_liegend1205_cls2"
            d="M59.1,160.8c13.8,1.5,24.2,5.5,30.8,8.5,9.9,4.7,17.9,8.4,22,17.1,1,2.1,5.3,12.1,0,21.4-5.8,10.1-19.9,13.8-29.3,10-3.2-1.3-8.6-4.6-14.7-21.4-11.7-32.6-7.3-64.1-7.3-64.1,1.8-12.7,4.8-21.8,1.5-32"
          />
          <line
            id="Foramen_Obturatum3"
            data-name="Foramen Obturatum3"
            class="Symphyse_dv_liegend1205_cls2"
            x1="66.8"
            y1="100.3"
            x2="63.5"
            y2="111.3"
          />
          <path
            id="Foramen_Obturatum2"
            data-name="Foramen Obturatum2"
            class="Symphyse_dv_liegend1205_cls2"
            d="M308.5,137.1c.6-8.1,1.1-18.4,1-30.5"
          />
          <path
            id="Foramen_Obturatum1"
            data-name="Foramen Obturatum1"
            class="Symphyse_dv_liegend1205_cls2"
            d="M309.5,106.6v-2.8"
          />
        </g>
        <g id="Symphyse">
          <path
            id="Symphyse3"
            class="Symphyse_dv_liegend1205_cls1"
            d="M202.1,117.1h0c0,3-6.7,5.5-14.9,5.5s-14.9-2.4-14.9-5.5"
          />
          <ellipse
            id="Symphyse2"
            class="Symphyse_dv_liegend1205_cls1"
            cx="188.2"
            cy="128.8"
            rx="11.6"
            ry="6.3"
          />
          <circle
            id="Symphyse1"
            class="Symphyse_dv_liegend1205_cls1"
            cx="187.5"
            cy="143.2"
            r="8.1"
          />
        </g>
        <g id="sacrum">
          <path
            id="sacrum7"
            class="Symphyse_dv_liegend1205_cls2"
            d="M344.2,103.8c-5.2,16.5.1,32.7,3.8,42.7,2.1,5.8,4.5,12.4,8.9,18.2"
          />
          <path
            id="sacrum6"
            class="Symphyse_dv_liegend1205_cls2"
            d="M344.2,223.5c3.7-4.4,7-9.4,9.5-15.3,1.8-4.2,4.1-9.9,6.3-16.5"
          />
          <path
            id="sacrum5"
            class="Symphyse_dv_liegend1205_cls2"
            d="M258,135.6c-6.5,5.4-12.1,8.8-19.8,11.3-19,6.3-28.3-.7-37,7.1-11.6,10.3-11.1,36.7,0,51.3,6.4,8.4,12.5,8,22.8,21.4,6.3,8.2,5.8,10.7,11.4,17.1,1,1.1,11.4,12.8,25.6,17.1,20.5,6.1,40-6,55.6-15.7,9.1-5.7,19.5-12.1,27.6-21.7"
          />
          <path
            id="sacrum4"
            class="Symphyse_dv_liegend1205_cls2"
            d="M24.2,228.5c1.8,1.8,3.2,3.1,4.1,3.9,7.8,7.1,38.4,34.6,63,27.1,6.2-1.9,10-5.4,21.9-8,11.6-2.6,15.7-.9,20.6-4.8,7.5-5.9,1.8-12.9,8.8-21.4,8-9.7,19.4-5.6,30.8-18.5,2.7-3,4.2-5.7,4.6-6.6,5.6-10.3,7.7-26.6-1.7-39-7.2-9.5-17.8-12.2-20.5-12.8-11.1-2.6-15.7,1.8-24.9,1.4-4.5-.2-9.5-1.5-21.4-12.4"
          />
          <path
            id="sacrum3"
            class="Symphyse_dv_liegend1205_cls2"
            d="M5.1,203.3c6.8,11.9,14.3,20.4,19.1,25.2"
          />
          <path
            id="sacrum2"
            class="Symphyse_dv_liegend1205_cls2"
            d="M8,167.7c3.6-1.6,5.8-2.9,7.1-4.1,13.6-12.7,8.7-37.2,7.3-44.2-.6-2.8-1.7-8-4.2-14.3"
          />
          <path
            id="sacrum1"
            class="Symphyse_dv_liegend1205_cls2"
            d="M327.1,103.6c-.7,1.8-1.3,5.3-1.9,7.3-4.6,16-3.3,32.6-1.4,32.8,1.7.2-17.2,11.2-2.6-39.9"
          />
        </g>
      </g>
    </svg>
  );
}

export default SymphyseDvLiegend1205;
