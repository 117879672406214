import moment from "moment";
import style from "./ExamReferral.module.scss";

function ExamReferral({ data }) {
  return (
    data ? <div dangerouslySetInnerHTML={{ __html: data.order_html }}>
    </div> : <></>
  );
}

export default ExamReferral;
