import React, { useEffect, useState, useMemo } from "react";
import { MakeGetObject } from "../../Utilities/AuthHeader";
import { Row, Col } from "react-bootstrap";
import Table from "./Components/PricelistTable";
import { useTranslation } from "react-i18next";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../Redux/mappers/pricelist/pricelistMappers";
import { loadPrices } from "../../Utilities/PriceLists/PriceListsUtils";
import { connect } from "react-redux";

function FacilitySelection(props) {
  const { t } = useTranslation(["common"]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  const noDataInfo = [
    {
      name: "No",
      vorname: "Data available",
      ort: "No City",
      strasse: "No Street",
      plz: "00000",
    },
  ];

  const header = useMemo(() => [
    {
      Header: t("name"),
      accessor: "name",
    },
    {
      Header: t("firstName"),
      accessor: "vorname",
    },
    {
      Header: t("city"),
      accessor: "ort",
    },
    {
      Header: t("street"),
      accessor: "strasse",
    },
    {
      Header: t("zip"),
      accessor: "plz",
    },
  ]);

  const loadData = React.useCallback(
    ({ pageSize, pageIndex, sortBy }, filerValue) => {
      //apply global filter to request only if search phrase is set
      const buildFilter = () => {
        if (filerValue !== "") {
          //setPageIndex to 0 here only if filtering is active to see 1st page before loading data
          setPageIndex(0);
          const searchValue = `%${filerValue}%`;
          const filter = {
            or: [
              { field: "name", op: "like", value: searchValue },
              {
                field: "vorname",
                op: "like",
                value: searchValue,
              },
              { field: "plz", op: "like", value: searchValue },
              { field: "ort", op: "like", value: searchValue },
              { field: "strasse", op: "like", value: searchValue },
            ],
          };
          return `&search=${encodeURIComponent(JSON.stringify(filter))}`;
        } else {
          return "";
        }
      };

      // returns sorting query
      const buildSorting = () => {
        if (sortBy.length > 0) {
          setPageIndex(0);
          return `&sort=${encodeURIComponent(
            JSON.stringify({
              field: sortBy[0].id,
              direction: sortBy[0].desc === true ? "desc" : "asc",
            })
          )}`;
        } else {
          return "";
        }
      };

      //builds url for request with pagination filters and sorting
      const buildURL = () => {
        return `${
          window.conf.SERVER_CONFIG.BASE_URL
        }/pmed/facilities?page_size=${pageSize}&page_number=${
          pageIndex + 1
        }${buildFilter()}${buildSorting()}`;
      };

      const fetchId = ++fetchIdRef.current;
      //set loading state in table
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        setPageIndex(pageIndex);
        fetch(buildURL(), MakeGetObject(props.keycloak))
          .then((response) => response.json())
          .then((rdata) => {
            if (rdata?.FacilitiesList?.facilities?.length === 0) {
              //no data in response so give table no data info
              setTableData(noDataInfo);
              //jump to first page
              setPageCount(1);
              setItemCount(0);
              //loading finished disable loading state
              setLoading(false);
            } else {
              //mo need for that since we introduced button

              // rdata.FacilitiesList.facilities.unshift({
              //   id: 0,
              //   instutitionsbezeichnung: " ",
              //   name: "No",
              //   ort: "",
              //   plz: "00000",
              //   strasse: "",
              //   vorname: "Institution",
              // });
              //provide data to table
              setTableData(rdata.FacilitiesList.facilities);
              //set pageCount
              setPageCount(
                Math.ceil(rdata.FacilitiesList.total_count / pageSize)
              );
              setItemCount(rdata.FacilitiesList.total_count);
              //loading finished disable loading state
              setLoading(false);
            }
          });
      }
    }
  );

  useEffect(() => {
    loadPrices(props.keycloak, 0).then((rdata) => {
      props.addDefaultPrices(rdata);
    });
    props.emptyNewPrices();
  }, []);

  return (
    <Row className="mb-4 justify-content-center">
      <Col md={10} sm={12}>
        <Table
          columns={header}
          data={tableData}
          fetchData={loadData}
          loading={loading}
          pageCount={pageCount}
          controlledPageIndex={pageIndex}
          idprefix="fac"
          itemCount={itemCount}
          rowClickHandler={props.rowClick}
          selectedids={props.selectedIds}
          idfield="id"
        />
      </Col>
    </Row>
  );
}
// FacilitySelection.whyDidYouRender = true;

export default connect(mapStateToProps, mapDispatchToProps)(FacilitySelection);
