import style from "./style.scss";

export default function RechteSulcusUlnarisAXsitzend2218(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 479.78 497.58">
      <g id="Backround">
        <path
          id="backround"
          class="Rechte_Sulcus_ulnaris_ax_sitzend2218_cls3"
          d="M473.11,57.75c-2.21-4.64-9.02-18.54-23.19-26.97-21.57-12.82-39.68-.79-71.19.54-45.48,1.92-45.28-21.53-110.02-29.12-21.12-2.48-49.18-5.44-79.82,7.01-18.39,7.47-13.05,10.48-40.45,25.89-31.84,17.91-58.75,24.93-101.39,49.08-5.47,3.1-10.75,6.22-15.65,12.28-12.05,14.92-11.34,34.65-10.27,56.21,1.42,28.7,7.48,36.87.57,49.61-5.81,10.71-12.58,9.52-18.03,19.85-7.06,13.37-2.95,29.1,4.17,48.66,12.77,35.1,21.1,43.68,31.66,68.48,20.5,48.17,24.81,99.24,24.81,99.24,2.37,28.05-.45,42.83,10.79,52.31,11.53,9.74,27.88,5.58,48.54,2.16,16.93-2.81,110.15-17.28,168.27-1.62,15.54,4.19,27.26,4.78,50.7,5.93,15.95.79,22.63.08,26.97-4.31,10.25-10.4.89-34.91-1.62-42.07-18.74-53.47-10.2-153.42,21.57-213.57,29.17-55.24,75.42-72.16,87.37-125.66,2.2-9.83,6.77-31.74-3.78-53.93Z"
        />
      </g>
      <g id="Sulcusulnarisoverview">
        <path
          id="upperarmoutline"
          class="Rechte_Sulcus_ulnaris_ax_sitzend2218_cls4"
          d="M363.26,483.33c-17.51-74.48-23.06-142.89-7.39-200.57,17.23-56.01,51.01-104.51,97.73-147.16,11.96-22.91,9.17-45.83-5.11-68.75-18.75-23.13-39.4-21.07-60.8-9.09-14.67,8.87-28.25,5.08-40.34-15.91-11.17-17.39-42.1-2.26-85.23,32.95-34.03,15.81-62.9,17.49-83.52-3.41-19.83-23.8-33.88-25.96-43.75-12.5l-80.11,37.5c-22.93,14.37-23.28,44.72-12.5,82.95-.45,14.92,8.73,22.87,26.7,24.43,12.76,8.21,27.46,10.98,45.45,4.55,18.45,62.13,34.28,121.8,43.18,175,9.36,26.61,13.13,53.32,13.07,80.11"
        />
        <path
          id="olecranonoutline"
          class="Rechte_Sulcus_ulnaris_ax_sitzend2218_cls4"
          d="M195.06,473.65c-.1-75.25-10.16-157.29-26.7-243.75.31-19.51-5.86-38.8-15.91-57.95-9.87-29.6-8.01-56.03,13.07-77.27,9.39-11.95,14.83-24.35,13.64-37.5-10.29-27.4,6.54-40.45,47.16-40.91,40.89-6.67,74.19,1.14,98.66,25.81,4.16,4.19,7.29,9.31,9.27,14.88,9.46,26.67,15.52,51.4,14.06,71.85-.66,9.3-4.07,18.21-9.56,25.74-17.95,24.61-30.1,50.54-34.59,78.2l-3.41,246.59"
        />
        <path
          id="olecranon2"
          class="Rechte_Sulcus_ulnaris_ax_sitzend2218_cls4"
          d="M305.29,228.2c-6.26-16.6-4.42-37.33.57-59.66,7.23-27.2,6.21-57.91,1.14-90.34"
        />
        <path
          id="olecranon1"
          class="Rechte_Sulcus_ulnaris_ax_sitzend2218_cls4"
          d="M321.77,39.56l-81.25,28.98c-25.95,8.86-44.94,1.71-61.36-11.36"
        />
        <path
          id="upperarm1"
          class="Rechte_Sulcus_ulnaris_ax_sitzend2218_cls4"
          d="M54.73,160.02c26.21,16.27,48.28,32.42,59.66,48.3"
        />
        <path
          id="forearmoutline"
          class="Rechte_Sulcus_ulnaris_ax_sitzend2218_cls4"
          d="M148.48,479.89c2.86-82.12,9.95-158.09,26.14-221.59,3.81-35.44-9.22-44.89-33.52-36.93-27.67,3.52-54.52,3.73-80.68,1.14-12.95-8.22-25.81-5.48-38.64.57-15.31,20.61.15,57.65,34.09,104.55.81,20.97,6.94,44.3,18.18,69.89l13.07,85.23"
        />
        <path
          id="forearm3"
          class="Rechte_Sulcus_ulnaris_ax_sitzend2218_cls4"
          d="M62.69,321.95c18.56,37.6,29.64,88.29,36.36,146.59"
        />
        <path
          id="forearm2"
          class="Rechte_Sulcus_ulnaris_ax_sitzend2218_cls4"
          d="M151.89,287.29c-10.86,40.62-16.37,98.66-18.18,168.75"
        />
        <path
          id="forearm1"
          class="Rechte_Sulcus_ulnaris_ax_sitzend2218_cls5"
          d="M159.28,220.25c-16.38,16.84-36.66,24.85-59.66,26.7-31.19,3.17-56.68-5.75-77.84-23.86"
        />
      </g>
      <g id="Highlights">
        <path
          id="epicondylehighlight2"
          class="Epicondylen2218Fill"
          style={{
            fill: props.colors.Epicondylen2218Fill,
            stroke: props.colors.Epicondylen2218Fill,
          }}
          d="M435.64,152.95c2.24-.84,2.5-2.58,10.91-10.8,2.95-2.88,5.44-5.13,7.05-6.56,2.6-4.78,6.9-14.18,7.45-26.81.54-12.34-2.75-21.89-4.87-26.87-1.39-4.37-4.67-12.65-12.34-20.26-6.01-5.95-12.27-9.08-16.25-10.69-4.38-1.28-10.97-2.53-18.67-1.44-5.52.78-10.86,2.65-14.06,4.36-4.74,2.54-6.06,3.29-6.06,3.29-1.13.65-2.03,1.19-3.42,1.87-2.45,1.21-3.54,1.46-4.06,1.77-4.63,2.76,2.8,20.66,2.8,20.66,10.86,26.17,16.29,39.25,19.15,43.42,2.35,3.43,21.93,31.97,32.37,28.06Z"
        />
        <path
          id="epicondylehighlight1"
          class="Epicondylen2218Fill"
          style={{
            fill: props.colors.Epicondylen2218Fill,
            stroke: props.colors.Epicondylen2218Fill,
          }}
          d="M78.86,113.3c14.35,31.12,19.27,45.87,19.27,45.87,12.49,37.39,13.46,48.54,9.23,51.23-1.21.77-3.43.95-7.88,1.32-5.69.48-10.03-.17-11.46-.4-1.12-.19-4.45-.78-8.49-2.29-1.9-.71-6.51-2.43-8.97-4.25-.21-.15-.79-.6-1.69-.94-1.57-.61-2.94-.46-3.24-.44-2.21.15-13.16-1.71-19.05-9.05-4.22-5.26-4.5-11.67-4.35-15.01-1.45-4.89-3.17-11.49-4.59-19.39-1.41-7.84-2.51-14.2-2.34-22.78.24-11.62,2.65-18.71,3.22-20.32,1.02-2.9,2.38-6.58,5.41-10.69,1.01-1.37,4.6-6.02,10.86-9.8,3.82-2.3,5.69-3.5,7.4-3.56,6.88-.24,12.53,11.54,16.66,20.5Z"
        />
        <path
          id="sulcusnerviulnarishighlight"
          class="Rinne2218Fill"
          style={{
            fill: props.colors.Rinne2218Fill,
            stroke: props.colors.Rinne2218Fill,
          }}
          d="M368.7,66.08c14.01,1.63,26.91-11.12,26.07-13.24-.04-.11-.19-.47-1.21-.81-5.44-1.83-9.36,5.61-17.08,6.67-6.73.92-12.87-3.48-14.47-4.64-5.86-4.2-7.74-9.75-10.91-9.11-1.47.3-2.69,1.81-3.03,3.24-1.39,5.71,8.99,16.54,20.65,17.9Z"
        />
      </g>
    </svg>
  );
}
