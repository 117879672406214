import style from "./style.scss";

function LinkeZeheDplSitzend(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.63 382.12">
      <g id="second_toe" data-name="second toe">
        <path
          id="second_toe_1"
          data-name="second toe 1"
          class="a1407_Linke_2Zehe_dpl_sitzend_cls_2"
          style={{
            fill: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_2,
            stroke: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_2,
          }}
          d="M51.96,24.32h0c9.76,8.55,12.21,14.66,4.88,20.76-4.89,7.32-3.67,12.21,3.66,15.87,9.77,3.66,4.88,9.77-1.22,14.65H26.3c-7.32-4.88-8.54-9.77-2.44-15.87,6.1-4.88,6.1-9.77,3.66-15.87-13.43-1.22-15.87-6.1,1.22-17.09,3.67-15.87,12.21-15.87,23.2-2.44h.02Z"
        />
        <path
          id="second_toe_2"
          data-name="second toe 2"
          class="a1407_Linke_2Zehe_dpl_sitzend_cls_2"
          style={{
            fill: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_2,
            stroke: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_2,
          }}
          d="M67.81,97.61h0c-3.66,15.87,0,25.64,7.33,31.74,7.32,18.31,1.22,21.97-9.77,18.31l-23.2,3.66c-9.76,0-13.43-3.66-4.88-15.87,2.44-8.55,1.22-19.54-3.66-34.19-14.65-18.32-15.87-26.86,3.66-20.76,7.32-1.22,12.21-1.22,17.09,1.22,13.43-7.33,14.65,2.44,13.43,15.87v.02Z"
        />
        <path
          id="second_toe_3"
          data-name="second toe 3"
          class="a1407_Linke_2Zehe_dpl_sitzend_cls_2"
          style={{
            fill: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_2,
            stroke: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_2,
          }}
          d="M78.8,175.73h0c-4.88,39.07,0,65.93,9.77,80.58,14.65,19.53,13.43,26.86-8.55,17.09-9.76-10.99-20.75-9.77-31.74,6.1-12.21,9.77-15.88,4.89-7.33-17.09,8.55-20.75,10.99-46.39-4.88-84.24-8.55-17.09,0-24.42,14.65-26.86,3.66,3.66,7.33,3.66,10.99,0,14.65-1.22,19.53,8.55,17.09,24.42h0Z"
        />
        <path
          id="second_toe_4"
          data-name="second toe 4"
          class="a1407_Linke_2Zehe_dpl_sitzend_cls_1"
          style={{
            fill: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_1,
            stroke: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_1,
          }}
          d="M45.12,380.42c-.92-10.27-2.28-20.32-4.17-30.11-10.99-17.09-13.43-32.96-3.66-43.95-2.45-8.55-1.22-15.88,4.88-20.76,15.88-17.09,31.75-15.87,47.62,1.22h0c1.22,8.55,2.44,18.31,4.88,28.08,3.66,12.21,4.89,25.64-15.87,45.17-.12,7.17-.23,14.37-.34,21.55"
        />
        <path
          id="second_toe_5"
          data-name="second toe 5"
          class="a1407_Linke_2Zehe_dpl_sitzend_cls_1"
          style={{
            fill: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_1,
            stroke: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_1,
          }}
          d="M38.51,289.27l14.65,7.33c12.21,6.1,23.2,0,34.19-8.55"
        />
        <path
          id="outline"
          class="a1407_Linke_2Zehe_dpl_sitzend_cls_3"
          style={{
            fill: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_3,
            stroke: props.colors.a1407_Linke_2Zehe_dpl_sitzend_cls_3,
          }}
          d="M.5,201.26c7-.95,13.1-6.69,16.41-13.05,1.62-3.11,2.04-6.7,1.35-10.15-5.19-25.65-7.03-50.84-5.13-75.54-4.3-25.13-5.31-49.45-2.17-72.77,1.55-11.44,7.67-21.52,16.59-26.49,10.56-5.89,20.67-2.23,30.38,9.81.81,1,1.56,2.09,2.24,3.22,12.32,20.49,17.52,45.2,14.96,74.5,11.96,30.97,17.3,61.83,13,92.59-.76,11.77,4.98,18.59,18,19.92"
        />
      </g>
      <g id="overlays">
        <g id="phalangen_overlays" data-name="phalangen overlays">
          <path
            id="phalangen_overlay_1"
            data-name="phalangen overlay 1"
            class="a1407_phalangenFill"
            style={{
              fill: props.colors.a1407_phalangenFill,
              stroke: props.colors.a1407_phalangenFill,
            }}
            d="M51.96,24.32h0c9.76,8.55,12.21,14.66,4.88,20.76-4.89,7.32-3.67,12.21,3.66,15.87,9.77,3.66,4.88,9.77-1.22,14.65H26.3c-7.32-4.88-8.54-9.77-2.44-15.87,6.1-4.88,6.1-9.77,3.66-15.87-13.43-1.22-15.87-6.1,1.22-17.09,3.67-15.87,12.21-15.87,23.2-2.44h.02Z"
          />
          <path
            id="phalangen_overlay_2"
            data-name="phalangen overlay 2"
            class="a1407_phalangenFill"
            style={{
              fill: props.colors.a1407_phalangenFill,
              stroke: props.colors.a1407_phalangenFill,
            }}
            d="M67.81,97.61h0c-3.66,15.87,0,25.64,7.33,31.74,7.32,18.31,1.22,21.97-9.77,18.31l-23.2,3.66c-9.76,0-13.43-3.66-4.88-15.87,2.44-8.55,1.22-19.54-3.66-34.19-14.65-18.32-15.87-26.86,3.66-20.76,7.32-1.22,12.21-1.22,17.09,1.22,13.43-7.33,14.65,2.44,13.43,15.87v.02Z"
          />
          <path
            id="phalangen_overlay_3"
            data-name="phalangen overlay 3"
            class="a1407_phalangenFill"
            style={{
              fill: props.colors.a1407_phalangenFill,
              stroke: props.colors.a1407_phalangenFill,
            }}
            d="M78.8,175.73h0c-4.88,39.07,0,65.93,9.77,80.58,14.65,19.53,13.43,26.86-8.55,17.09-9.76-10.99-20.75-9.77-31.74,6.1-12.21,9.77-15.88,4.89-7.33-17.09,8.55-20.75,10.99-46.39-4.88-84.24-8.55-17.09,0-24.42,14.65-26.86,3.66,3.66,7.33,3.66,10.99,0,14.65-1.22,19.53,8.55,17.09,24.42h0Z"
          />
        </g>
        <g id="joint_overlays" data-name="joint overlays">
          <ellipse
            id="joint_overlay_1"
            data-name="joint overlay 1"
            class="a1407_gelenkeFill"
            style={{
              fill: props.colors.a1407_gelenkeFill,
              stroke: props.colors.a1407_gelenkeFill,
            }}
            cx="44.08"
            cy="75.3"
            rx="23.74"
            ry="10.43"
          />
          <ellipse
            id="joint_overlay_2"
            data-name="joint overlay 2"
            class="a1407_gelenkeFill"
            style={{
              fill: props.colors.a1407_gelenkeFill,
              stroke: props.colors.a1407_gelenkeFill,
            }}
            cx="56.18"
            cy="151.5"
            rx="27.19"
            ry="9.42"
          />
          <ellipse
            id="joint_overlay_3"
            data-name="joint overlay 3"
            class="a1407_gelenkeFill"
            style={{
              fill: props.colors.a1407_gelenkeFill,
              stroke: props.colors.a1407_gelenkeFill,
            }}
            cx="66.05"
            cy="277.82"
            rx="32.81"
            ry="14.52"
          />
        </g>
      </g>
    </svg>
  );
}

export default LinkeZeheDplSitzend;
