import Controller from "../Common/Controller";
import "./styles/Controller.scss";

import FrontView from "./views/FrontView";

function ArmLeftController() {
  const availableViews = [FrontView];
  return <Controller availableViews={availableViews} />;
}

export default ArmLeftController;
