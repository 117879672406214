import style from "./style.scss";

export default function RechtesHuftlochVdoblLiegend2407(props) {
  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378.83 515.22">

  <path id="BG" class="Rechtes_Huftloch_vd_obl_liegend2407_cls6" d="M360.46,71.22c-1.32,8.35-6.87,7.78-17,25-14.35,24.38-20.51,45.07-26,64-8.1,27.91-12.15,41.87-10,58,2.59,19.39,9.26,18.52,12,39,2.78,20.77-1.97,37.63-8,59-5.15,18.26-7.72,27.39-14,35-6.45,7.81-12.93,11.24-14,20-.68,5.57,1.6,6.99,2,19,.26,7.72-.76,8.48,0,14,1.15,8.33,3.73,11.5,5,17,1.37,5.94.36,10.92-6,28-7.27,19.55-10.91,29.32-13,32-11.86,15.2-31.7,17.63-51,20-26.57,3.26-46.89-1.11-65-5-24.49-5.26-40.22-12.36-66-24-17.81-8.04-23.77-12.15-29-18-5.79-6.48-7.36-11.37-17-21-5.8-5.8-7.17-5.96-13-12-3.02-3.13-9.22-9.63-15-19-8.42-13.66-11.32-25.94-12-29-3.07-13.78-5.95-25.05,0-31,5-5,14,8,40-3,16.15-6.83,29.65-9.23,54-25,36.58-23.69,60.07-49.63,69-60,28.33-32.88,43.14-63.45,48-74,16.56-35.97,26.32-75.94,30-91,4.41-18.06,9.94-32.37,21-61,6.46-16.72,8.55-20.57,13-23"/>
  <g id="underlay" class="Rechtes_Huftloch_vd_obl_liegend2407_cls7">
    <path id="Beckenschaufel_overlay" data-name="Beckenschaufel overlay" class="Beckenschaufel2407Fill" style= {{
              fill: props.colors.Beckenschaufel2407Fill,
              stroke: props.colors.Beckenschaufel2407Fill,
              opacity:props.colors.Beckenschaufel2407Fill,
            }} d="M317.57,259.01c-1.83,1.24-5.85-3.72-12.69-8.21-14.72-9.66-31.12-9.69-40.3-9.7-6.83-.01-16.11,2-26.12,3.73-12.91,4.62-17.22,10.91-24.63,8.96-3.94-1.03-7.1-5.17-13.43-13.43-6.61-8.64-9.91-13.07-9.7-18.66.27-7.07,5.96-12.05,8.21-14.18,5.35-11.81,17.47-29.77,30.6-68.66,7.23-21.41,3.52-16.84,16.42-61.19,14.56-50.07,12.38-32.15,14.93-44.03.79-3.66,2.49-12.33,8.96-20.9,0,0,3.96-5.24,8.96-8.96,20.11-14.95,102.17,17.48,100,44.77-.39,4.87-3.06,4.69-20.16,26.13-9.19,11.52-16.24,20.43-22.39,31.34-3.27,5.8-5.94,11.56-13.43,33.58-22.19,65.28-19.35,75.5-17.16,80.6,3.53,8.22,5.09,12.69,8.21,21.64,4.57,13.13,4.96,16.33,3.73,17.16h-.01Z"/>
    <path id="Foramen_highlight" data-name="Foramen highlight" class="Foramen_obturatum2407Fill" style= {{
              fill: props.colors.Foramen_obturatum2407Fill,
              stroke: props.colors.Foramen_obturatum2407Fill,
              opacity:props.colors.Foramen_obturatum2407Fill,
            }} d="M194.44,383.6c4.02,6.31,6.67,10.48,8.21,16.42.79,3.08,4.06,15.68-2.99,27.61-6.61,11.2-18.05,14.6-21.64,15.67-10.74,3.2-20.27,1.03-31.34-1.49-6.09-1.38-4.15-1.54-17.16-5.22-15.72-4.45-18.22-4.13-23.88-7.46-2.17-1.28-8.9-5.51-14.18-13.43-3.81-5.73-2.6-6.98-7.46-17.91-3.66-8.23-8.21-16.22-10.45-20.15-5.7-9.99-8.1-12.85-6.72-15.67,1.35-2.74,5.39-3.62,12.69-5.22,6.47-1.41,4.65-3.12,8.84-2.76,4.61-2.79,9.27-9.12,16.53-11.42,2.66-.84,4-.24,16.42,2.99,9.93,2.58,14.9,3.88,17.91,4.48,12.69,2.54,19.03,3.82,27.61,3.73,3.48-.03,7.29-.29,10.45,2.24,2.82,2.26,3.11,5.11,4.48,8.96,2.84,7.98,6.05,8.24,12.69,18.66v-.03Z"/>
  </g>
  <g id="Left_Hip" data-name="Left Hip">
    <path id="l_hip_16" data-name="l hip 16" class="Rechtes_Huftloch_vd_obl_liegend2407_cls3" d="M375.79,50.79c-1.32,8.35-23.93,28.21-34.06,45.43-14.35,24.38-20.51,45.07-26,64-8.1,27.91-12.15,41.87-10,58,2.59,19.39,9.26,18.52,12,39,2.78,20.77-1.97,37.63-8,59-5.15,18.26-7.72,27.39-14,35-6.45,7.81-12.93,11.24-14,20-.68,5.57,1.6,6.99,2,19,.26,7.72-.76,8.48,0,14,1.15,8.33,3.73,11.5,5,17,1.37,5.94.36,10.92-6,28-7.27,19.55-10.91,29.32-13,32-11.86,15.2-31.7,17.63-51,20-26.57,3.26-46.89-1.11-65-5-24.49-5.26-40.22-12.36-66-24-17.81-8.04-23.77-12.15-29-18-5.79-6.48-7.36-11.37-17-21-5.8-5.8-7.17-5.96-13-12-3.02-3.13-9.22-9.63-15-19-8.42-13.66-11.32-25.94-12-29"/>
    <path id="l_hip_15" data-name="l hip 15" class="Rechtes_Huftloch_vd_obl_liegend2407_cls3" d="M1.73,342.22c5-5,14,8,40-3,16.15-6.83,29.65-9.23,54-25,36.58-23.69,60.07-49.63,69-60,28.33-32.88,43.14-63.45,48-74,16.56-35.97,26.32-75.94,30-91,4.41-18.06,9.94-32.37,21-61,6.46-16.72,8.55-20.57,13-23"/>
    <path id="l_hip_14" data-name="l hip 14" class="Rechtes_Huftloch_vd_obl_liegend2407_cls3" d="M366.23,345.22c-.48,1.01-4.11,8.4-12,10-5.77,1.17-10.33-1.46-13-3-.56-.32-10.46-6.23-12-17-.69-4.84.84-6.88,2-13,0,0,2.16-11.42-1-25-3.95-16.99-17.16-27.4-23-32-18.86-14.87-39.36-15.87-44-16-7.68-.21-33.64.28-54,21-1.35,1.38-21.91,22.97-20,54,.48,7.76,1.49,24.23,13,34,4.3,3.65,6.11,3.01,23,11,10.46,4.95,17.25,8.16,23,12,11.08,7.4,16.8,16.16,25,29,7.86,12.31,22.62,35.93,29,71,2.57,14.12,3.03,26,3,34"/>
    <path id="l_hip_13" data-name="l hip 13" class="Rechtes_Huftloch_vd_obl_liegend2407_cls3" d="M318.23,262.22c-5.23-5.54-15.91-15.18-32-19-10.17-2.41-18.08-1.45-30,0-15.78,1.92-28.46,3.46-41,12-8.3,5.65-4.6,6.23-23,23-8.17,7.44-11.79,9.96-14,16-.99,2.69-1.3,5.02,0,22,1.62,21.14,2.25,19.37,2,25-.42,9.31-2.11,13.05,0,20,1.29,4.26,3.14,6.86,6,11,10.7,15.48,16.05,23.22,17,26,3.8,11.13.71,25.79-8,35-8.21,8.67-19.03,10.01-27,11-10.66,1.32-18.77-.79-35-5-15.75-4.09-23.77-6.27-31-13-6.81-6.34-11.39-14.85-12-16-3.11-5.86-3.65-9.15-8-17-2.98-5.38-5-7.93-7-11-3.73-5.74-8.25-14.64-11-28"/>
    <polyline id="l_hip_12" data-name="l hip 12" class="Rechtes_Huftloch_vd_obl_liegend2407_cls3" points="4.75 366.54 23.23 370.22 45.23 370.22 75.23 358.22 92.23 351.22 116.23 332.22"/>
    <polyline id="l_hip_11" data-name="l hip 11" class="Rechtes_Huftloch_vd_obl_liegend2407_cls3" points="179.23 355.75 145.23 351.22 116.23 343.18 84.23 343.18"/>
    <polyline id="l_hip_10" data-name="l hip 10" class="Rechtes_Huftloch_vd_obl_liegend2407_cls3" points="75.23 382.22 84.23 365.22 92.23 351.22"/>
    <polyline id="l_hip_9" data-name="l hip 9" class="Rechtes_Huftloch_vd_obl_liegend2407_cls3" points="180.23 341.22 196.23 315.72 202.23 298.22 214.23 281.22 240.23 270.22 271.23 260.22 296.23 249.19"/>
    <path id="l_hip_8" data-name="l hip 8" class="Rechtes_Huftloch_vd_obl_liegend2407_cls3" d="M267.23,387.22c10.5,0,19-7.16,19-16"/>
    <path id="l_hip_7" data-name="l hip 7" class="Rechtes_Huftloch_vd_obl_liegend2407_cls3" d="M286.23,371.22c-2.76,0-5-9.73-5-21.76"/>
    <path id="l_hip_6" data-name="l hip 6" class="Rechtes_Huftloch_vd_obl_liegend2407_cls3" d="M310.23,498.22c6.33-42.31,26.86-55.71,45-32"/>
    <path id="l_hip_5" data-name="l hip 5" class="Rechtes_Huftloch_vd_obl_liegend2407_cls2" d="M253.23,194.22c14.72-17.76,24.69-33.29,31-44,11.66-19.79,13.12-27.07,26-46,9.07-13.32,17.38-22.98,26-33,12.01-13.95,22.83-25.04,31-33"/>
    <path id="l_hip_4" data-name="l hip 4" class="Rechtes_Huftloch_vd_obl_liegend2407_cls2" d="M101.23,8.22c1.28,5.32,3.75,12.34,9,19,6.07,7.7,10,7.49,16,16,3.65,5.18,8.2,11.63,8,20-.16,6.73-3.29,10-1,14,2.68,4.68,9.71,5,10,5,6.35.2,8.64-4.24,16-8,8.15-4.16,18.35-5.38,26-3,4.74,1.48,5.81,3.5,17,15,16.46,16.91,18.28,16.78,30,30,4.1,4.62,8.61,9.98,12,14,9.73,11.55,12.65,15.51,19,20,5.34,3.77,10.63,7.41,17.1,6.44,1.24-.18,5.79-1.02,10.9-6.44,9.28-9.84,10.01-23.78,10.35-32.24,1.19-29.92,1.79-44.88,1.65-55.76-.15-12.08.06-29.94,2-52"/>
    <path id="l_hip_3" data-name="l hip 3" class="Rechtes_Huftloch_vd_obl_liegend2407_cls2" d="M67.23,74.22c16.38-4.19,28.07-2.56,36,0,5.19,1.68,9.67,4.04,12,2,3.2-2.8-.18-11.68-5-24-3.04-7.77-5.77-13.26-10-22-4.34-8.97-7.79-16.29-10-21"/>
    <path id="l_hip_2" data-name="l hip 2" class="Rechtes_Huftloch_vd_obl_liegend2407_cls2" d="M286.23,125.22c-2.88-4.9-6.4-11.3-10-19-7.19-15.36-11.11-28.21-13-35-3.89-13.97-6.96-25-7-40-.04-12.71,2.12-23.1,4-30"/>
    <polyline id="l_hip_1" data-name="l hip 1" class="Rechtes_Huftloch_vd_obl_liegend2407_cls1" points="233.23 489.22 238.23 476.4 238.23 464.22 245.23 454.58 245.23 444.61 245.23 433.22 253.23 433.22 253.23 426.22 261.23 426.22 267.23 418.22"/>
  </g>
</svg>
  );
}
