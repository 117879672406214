export function MakeGetObject(keycloak, xmask = undefined) {
  let object = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${keycloak.token}`,
    },
  };
  if (xmask) {
    object.headers["X-Fields"] = xmask;
  }
  return object;
}

export function MakePostObject(keycloak, body) {
  let object = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${keycloak.token}`,
    },
    body: JSON.stringify(body),
  };
  return object;
}

export function MakePutObject(keycloak, body) {
  let object = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${keycloak.token}`,
    },
    body: JSON.stringify(body),
  };
  return object;
}

export function MakeDeleteObject(keycloak, body = undefined) {
  if (body) {
    return {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
      },
      body: JSON.stringify(body),
    };
  } else {
    return {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
      },
    };
  }
}
