import style from "./style.scss";

export default function RechterUnterarmMitEllenbogenVdSitzend2207(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 430.06 1208.74">
      <g id="Backround">
        <path
          id="backround1"
          class="unterarm_vd_mit_ellenbogen_links2207_cls4"
          d="M375.8,840.84v-.08c2.63-7.18-3.43-22.06-8.91-43.1-6.01-23.1-4.64-31.3-8.27-55.34-1.07-7.08-2.38-14.3-17.77-69.72-15.76-56.73-18.88-67.04-24.61-92.96-2.86-12.93-7.62-35.53-5.47-64.25,2.14-28.68,6.41-37.01,9.57-41.01,8.32-10.52,18.18-12.06,19.88-22.26,1.44-8.64-4.01-17.24-7.57-22.86-7.25-11.44-14.03-13.06-13.67-19.14.32-5.4,5.76-5.75,13.67-15.04,8.27-9.71,11.43-20.04,13.67-27.34,1.16-3.78,3.34-11.06,3.83-20.6.64-12.54-2.34-15.37-5.19-34.09-3.42-22.44-.28-25.92-5.47-34.18-5.7-9.07-12.01-8.92-20.51-16.41-7.99-7.04-10.76-14.53-19.14-45.11-12.22-44.59-11.74-48.38-19.14-71.09-3.22-9.86-5.2-14.95-10.94-32.81-9.14-28.42-10.3-34.75-10.94-39.65-1.89-14.45.97-14.38,1.37-39.65.22-14.1-.54-22.19-1.2-28.97,0-.08-.07-.15-.15-.16C221.08.78,184.46,8.98,147.55,0c-.09-.02-.19.04-.21.14-1.37,6.19-3.04-2.31-3.82,5.82,0,0-1.31,16.03-7.75,56.9-7.06,44.79-21.62,75.77-34.18,105.27-10.04,23.6-20.06,42.58-38.28,56.05-3.99,2.95-8.67,5.86-19.14,13.67-18.92,14.12-28.39,21.18-32.81,27.34-10.88,15.15-14.4,37.01-8.2,56.05,1.96,6.03,7.44,22.84,21.87,30.08,5.77,2.89,9.68,2.6,21.87,8.2,9.18,4.21,13.77,6.32,16.41,9.57,7.07,8.71.79,15.32,8.2,28.71,3.6,6.49,7.04,8.47,6.84,13.67-.21,5.33-3.98,7.04-6.84,12.3-5.89,10.83-2.28,26.35,4.1,35.54,3.47,5,5.01,4.17,20.51,16.41,13.79,10.89,15.58,11.72,17.77,15.04,2.8,4.23,3.87,7.99,6.84,24.61,2.27,12.74,3.41,19.11,4.1,24.61,3.02,24.09,6.45,27.45,10.94,56.05,1.78,11.3,4.96,31.88,6.84,58.78,1.48,21.19.67,25.12,2.73,42.38,3.09,25.83-.59,35.34.45,51.74,1.4,21.42,3.08,14.97,2.29,46.69-.51,20.52,59.94,393.21,63.44,399.34,16.1,28.22,86.99,7.94,101.35-15,3.78-6.03-51.81-333.56-57.26-367.99v-.02c-1.72-38.28-3.3-45.22-6.36-77.85-1.36-14.46-2.93-14.68-5.47-38.28-2.47-22.92-4.19-38.9-1.37-39.65,4.58-1.21,22.93,37.37,31.44,80.66,6.84,34.79,10.26,52.19,9.57,76.56-.42,14.66,63.03,352.16,67.69,358.57,15.78,21.71,69.02,9.94,77.93-13.84.01-.03.01-.06,0-.09l-53.25-317.19h.01Z"
        />
      </g>
      <g id="Forearmvd">
        <g id="Outlines">
          <path
            id="outline4"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M259.61,11.96c-1,16.38-2.55,55.26,1.45,71.26,6,18,11,36,18,54,6,17,9,33,14,50,5,23,10,44,19,65,5,10,15,15,24,22,5,3,8,9,8,16,0,6,1,12,1,18,2,15,7,28,6,43,0,10-4,18-7,27-6,14-15,26-28,34-2,1-5,2-7,3-7,3-14,4-21,5-19,0-36-9-55-9-20,0-41-1-59-11-6-3-13,0-18,4-15,15-33,29-55,26-6-1-11-5-13-10-5-13-1-26-4-40-1-5,1-12-8-13-5,0-14,6-16-2-3-17,0-33-3-49,2-20,18-35,35-43,16-7,32-6,49-3,22,4,44,11,65.81,7.28"
          />
          <path
            id="outline3"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M211.54,1194.96c-3.5-6.13-63.08-378.82-62.57-399.34.71-23.07-7.26-30.9-1.58-55.66-1.04-16.4,1.93-16.94-1.16-42.77-2.06-17.26-1.25-21.19-2.73-42.38-1.88-26.9-5.06-47.48-6.84-58.78-4.49-28.6-7.92-31.96-10.94-56.05-.69-5.5-1.83-11.87-4.1-24.61-2.97-16.62-4.04-20.38-6.84-24.61-2.19-3.32-3.98-4.15-17.77-15.04-15.5-12.24-17.04-11.41-20.51-16.41-6.38-9.19-9.99-24.71-4.1-35.54,2.86-5.26,6.63-6.97,6.84-12.3.2-5.2-3.24-7.18-6.84-13.67-7.41-13.39-1.13-20-8.2-28.71-2.64-3.25-7.23-5.36-16.41-9.57-12.19-5.6-16.1-5.31-21.87-8.2-14.43-7.24-19.91-24.05-21.87-30.08-6.2-19.04-2.68-40.9,8.2-56.05,4.42-6.16,13.89-13.22,32.81-27.34,10.47-7.81,15.15-10.72,19.14-13.67,18.22-13.47,28.24-32.45,38.28-56.05,12.56-29.5,27.12-60.48,34.18-105.27,6.44-40.87,6.88-56.9,6.88-56.9"
          />
          <path
            id="outline2"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M351.12,1171.96c-4.66-6.42-67.24-343.88-66.82-358.57.69-24.37-2.73-41.77-9.57-76.56-8.51-43.29-26.86-81.87-31.44-80.66-2.82.75-1.1,16.73,1.37,39.65,2.54,23.6,4.11,23.82,5.47,38.28,3.06,32.63,6.57,44.46,5.49,77.86,6.06,35.65,61.05,361.97,57.27,368"
          />
          <path
            id="outline1"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M333.52,429.23c3.56,5.62,9.01,14.22,7.57,22.86-1.7,10.2-11.56,11.74-19.88,22.26-3.16,4-7.43,12.33-9.57,41.01-2.15,28.72,2.61,51.32,5.47,64.25,5.73,25.92,8.85,36.23,24.61,92.96,15.39,55.42,16.7,62.64,17.77,69.72,2.97,19.69,2.59,26.23,4.77,39.28.36,2.12,1.4,6.77,3.5,16.06,6.45,28.55,62.05,357.52,61.3,360.45"
          />
        </g>
        <g id="Overview">
          <path
            id="partofancon9"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M206.86,277.49c0,7.02-.19,18-1.37,31.44-1.48,17-4.22,34.45-5.47,42.38-3.88,24.55-5.12,25.88-4.1,32.81,1.05,7.13,3.25,11.69,15.04,32.81,15.49,27.74,14.75,25.34,17.77,31.44,5.11,10.32,14.06,28.38,16.41,51.95.25,2.54.17,17.64,0,47.85-.04,7.73-.07,9.55-.09,16.44-.06,17.94.05,28.22.09,39.61.19,49-1.15,51.55-2.73,51.95-5.48,1.38-15.59-22.64-16.41-24.61-2.6-6.25-7.03-17.22-6.84-31.44.15-10.96,3.11-19.22,5.47-25.97,3.63-10.39,6.18-12.73,9.57-20.51,5.78-13.26,13.62-41.47,1.37-56.05-6.64-7.9-13.67-5.25-17.77-13.67-1.77-3.65-1.59-6.49,1.87-27.72,3.14-19.22,3.75-21.53,6.33-24.23,7.64-7.97,20.73-7.02,23.24-6.84,10.28.75,11.68,5.67,21.87,5.47,5.8-.12,6.05-1.58,17.77-4.1,9.15-1.97,16.17-3.48,24.61-2.73,4.36.38,11.13,1.55,19.14,5.47"
          />
          <line
            id="ulnar2"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            x1="224.63"
            y1="682.15"
            x2="286.62"
            y2="1186.65"
          />
          <line
            id="radius6"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            x1="407.4"
            y1="1158.08"
            x2="305.29"
            y2="582.35"
          />
          <path
            id="radius5"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M312.89,496.34c-24.07,39.48-13.15,82.74-7.59,86.01"
          />
          <path
            id="radius4"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M374.52,1164.96c-18.39-76.58-98.32-498.67-119.87-508.79"
          />
          <path
            id="ulnar1"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M230.1,1194.96c-27.04-194-90.03-712.05-110.73-708.3"
          />
          <path
            id="partofancon8"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M318.96,381.39c26.03-22.3,26.43-53.94,13.67-64.25"
          />
          <path
            id="partofancon7"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M273.85,244.68c15.72,20.51,14.06,41.01,31.44,41.01"
          />
          <path
            id="partofancon6"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M195.58,206.4c15.83,38.55,40.92,83.63,56.39,88.86"
          />
          <path
            id="partofancon5"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M235.06,11.96c-26.37,21.56.83,119.05,16.92,147.95"
          />
          <path
            id="ulnar7"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M147.39,328.07c0-18.14-12.53-32.81-28.03-32.81"
          />
          <path
            id="partofancon4"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M41.44,310.3c-2.23-.62-16.37-4.8-23.24-19.14-5.69-11.87-4.32-26.34,2.75-35.52,8.2-10.65,18.84-7.39,39.63-16.43,17.63-7.67,28.67-18.15,36.91-25.97,11.28-10.71,22.39-25.05,35.54-54.68,7.62-17.17,19.17-46.75,30.08-106.63,4.42-24.27,2.98-7.04,5.95-45.97"
          />
          <path
            id="partofancon3"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M168.58,218.7c-1.27,5.81-4.05,14.06-10.94,20.51-7.91,7.4-14.03,5.7-20.51,12.3-8.56,8.74-9.09,23.19-6.84,32.81,4.48,19.07,22.72,27.33,28.71,30.08,14.83,6.8,43.95,12.7,57.42-4.1,13.4-16.72,1-43.06,0-45.11-8.03-16.48-22.42-23.75-27.34-25.97"
          />
          <path
            id="partofancon2"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M239.83,285.69c-36.77,22.2-37.42,84.36-12.46,124.95"
          />
          <path
            id="radius3"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M332.63,429.23c0,1.51-43.06,25.97-106.63,2.73"
          />
          <path
            id="ulnar6"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M201.39,490.75c3.23-3.94,6.64-9.55,6.84-16.41.19-6.74-2.91-8.56-4.1-17.77-1.46-11.27,2.54-13.43,0-19.14-3.69-8.3-15.26-10.67-23.24-12.3-6.06-1.24-16.06-3.29-25.97,1.37-7.37,3.46-6.37,6.72-15.04,12.3-11.11,7.15-22.36,7.98-25.97,8.2-6.47.4-16.95,1.05-27.34-5.47-9.16-5.74-13.39-14-15.04-17.77"
          />
          <path
            id="ulnar5"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M71.52,423.77c0,23.82,14.06,43.09,31.44,43.09"
          />
          <path
            id="ulnar4"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            d="M71.52,397.79c23.37,4.06,34.45,55.58,38.47,88.8"
          />
          <line
            id="ulnar3"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            x1="87.24"
            y1="349.52"
            x2="85.19"
            y2="384.12"
          />
          <line
            id="partofancon1"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            x1="318.96"
            y1="295.26"
            x2="344.94"
            y2="313.03"
          />
          <line
            id="radius2"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            x1="258.04"
            y1="616.53"
            x2="251.98"
            y2="642.5"
          />
          <line
            id="radius1"
            class="unterarm_vd_mit_ellenbogen_links2207_cls1"
            x1="245.19"
            y1="539.35"
            x2="264.11"
            y2="590.55"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="jointlinehighlight"
          class="gelenkspalte2207fill"
          style={{
            fill: props.colors.Gelenkspalte2207Fill,
            stroke: props.colors.Gelenkspalte2207Fill,
          }}
          d="M72.4,397.8c-.71.77,8.42,7.31,7.03,14.07-.68,3.29-4.04,7.56-4.64,8.32-1.89,2.4-3.35,3.5-3.25,5.34.09,1.59,1.29,2.52,3.3,4.41,4.93,4.64,3.6,4.62,7.55,8.09,1.56,1.37,4.72,4.14,8.9,6.2,6.29,3.1,11.96,3.14,17.8,3.17,10.11.06,17.65-2.49,20.39-3.51,7.16-2.66,11.9-6.09,12.93-6.85,5.89-4.34,5.11-5.83,9.36-8.76,8.65-5.95,19.35-4.96,24.09-4.52,3.92.36,7.57,1.38,14.89,3.4,9.39,2.6,9.42,3.44,14.32,4.21,9.04,1.42,16.39-.29,18.29-.76,5.52-1.38,5.05-2.44,12.13-4.25,8.36-2.13,12.62-1.58,13.75-1.42,2.39.35,4.41,1,7.85,2.11,4.83,1.56,5.93,2.39,8.94,2.94,3,.55,5.51.38,8.49,0,10.15-1.31,13.02-3.49,22.85-5.26,8.99-1.62,14.3-1.18,15.98-1.01,2.74.28,5.39.55,8.7,1.62.53.17,2.61.86,6.07,2.02,3.3,1.11,3.55,1.2,4.04,1.13,3.21-.44,6.28-6.39,5.67-11.65-.74-6.42-7.1-12.96-12.74-11.73-3.25.7-5.18,3.74-5.93,5.12-11.89,7.57-22.05,9.29-28.04,9.71-9.44.65-17.95-1.28-26.24-3.17-11.46-2.61-13.58-4.69-23.11-5.46-4.62-.37-4.15.12-16.97-.19-8.11-.19-12.27-.3-17.28-.89-5.44-.64-10.42-1.22-16.72-3.24-3.32-1.06-6.82-2.46-12.13-4.58-6.93-2.77-8.58-3.67-11.6-3.51-3.81.21-6.54,1.96-9.71,4.04-5.61,3.69-6.81,6.54-11.6,11.06-5.11,4.82-9.89,7.37-13.75,9.44-5.31,2.83-11.92,6.28-21.04,6.98-5.69.44-8.54.66-11.56-.84-7.5-3.73-5.44-12.1-14.33-22.59-5.74-6.78-12.14-9.82-12.69-9.22Z"
        />
        <path
          id="radialheadhighlight"
          class="radiushead2207fill"
          style={{
            fill: props.colors.RadiusHead2207Fill,
            stroke: props.colors.RadiusHead2207Fill,
          }}
          d="M217.23,478.75c3.38,6.93,17.2,6.79,21.57,6.74,5.29-.05,9.02-.89,11.33-1.35,13.55-2.66,17.63.23,49.85-.26,3.67-.06,15.88-.36,19.25-6.14.36-.62,1.02-1.98,2.36-3.84,2.21-3.07,4.61-5.16,6.34-6.47,1.82-1.12,4.34-2.87,7-5.39,2.41-2.29,3.71-3.52,4.73-5.33,4.43-7.83-.82-18.41-2.02-20.83-.92-1.85-1.67-3.37-3.24-5.06-2.81-3.03-6.6-4.81-13.75-6.07-6.14-1.08-14.35-2.53-24.83-.24-9.94,2.17-18.56,5.1-18.56,5.1-2.47.84-5.31,1.88-9,1.41-1.16-.15-2.28-.43-8.49-2.83-6.07-2.35-5.53-2.28-6.67-2.63-6.34-1.95-12.1-.68-14.32-.16-3.23.75-8.17,1.9-12.01,5.83-2.83,2.9-4.05,8.84-6.37,20.6-.25,1.25-.99,6.23-2.49,16.2-1.25,8.35-1.36,9.31-.67,10.72Z"
        />
      </g>
    </svg>
  );
}
