import "./JournalButton.scss";

import { Tooltip, OverlayTrigger } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useState } from "react";

const JournalButton = React.forwardRef(
  (
    { id, label, icon, hint, onClick, look = "button", modal, isActive },
    ref
  ) => {
    const [show, setShow] = useState(false);
    const [modalShown, setModalShown] = useState(false);

    const getControlComponent = () => {
      if (look === "button") {
        return (
          <div
            className="btn btn-primary mx-1"
            ref={ref}
            key={id}
            isActive={isActive}
            onClick={() => {
              if (typeof onClick === "function") {
                onClick();
              } else if (modal) {
                setModalShown(true);
              }
              setShow(!show);
            }}
          >
            <i className={icon}></i>
          </div>
        );
      }

      if (look === "switch") {
        return (
          <div className="toggle-container">
            <BootstrapSwitchButton checked={false} size="xs" />
            <label className="button-label">{label}</label>
          </div>
        );
      }
    };

    const ModalToRender = modal || null;

    return (
      <>
        {ModalToRender !== null && (
          <ModalToRender
            shown={modalShown}
            close={() => {
              setModalShown(false);
            }}
          />
        )}
        <OverlayTrigger
          placement="top"
          show={show}
          isActive={isActive}
          delay={{ show: 800, hide: 200 }}
          overlay={<Tooltip id="button-tooltip">{hint}</Tooltip>}
          onToggle={(show) => setShow(show)}
        >
          {getControlComponent()}
        </OverlayTrigger>
      </>
    );
  }
);

export default JournalButton;
