import style from "./style.scss";

function LinkerOberarmMitEllenbogenLatSitzend2233(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362.89 767.27">
      <g id="Upperarmlat">
        <g id="Backround">
          <path
            id="backround"
            class="oberarm_mit_ellenbogen_lat_left2233_cls5"
            d="M355.35,4.18c.16,6,7.05,288,3.48,310.11-3.01,23.45,3.31-10.1-3,76-3.31,45.17-4.41,45.63-9,100-4.24,50.22-7.74,102.29-8,121-.12,8.67-1.53,17.34-1,26,.31,5.01.85,8.56-1,13-2.67,6.42-7.62,8.26-7,12,.56,3.35,4.79,3.43,8,7,1.89,2.09,4.67,6.78,1,25-4.08,20.26-8.62,19.94-9,32-.37,11.79,3.83,16.51,0,23-3.6,6.1-10.93,8.03-15,9-29.23,6.94-62.99,5.15-66,5-20.62-1.06-13.56,1.53-60,3-37.16,1.18-56.09-.02-100-1-55.51-1.24-60.41-.27-83.24-.29.01-.02-5.6-.02-5.59-.05,4.41-11.65,3.96-25.59,3.83-37.84-.11-10.92-1.03-21.82-1.36-32.72,9.62-.44,19.88,2.33,30.36-2.09,7.5-3.17,21.53-2.2,38-5,8.78-1.49,14.19-2.66,25-5,16.69-3.61,25.03-5.44,25-7-.07-3.53-30.26-5.61-36-6-34.28-2.36-59.08-.68-74,0l-6-58c17.86-1.01,21.09-3.46,35-4,10.48-.41,18.32.73,34,3,12.05,1.75,17.29,3.18,37,6,3.21.46,11.84,1.65,23,1,12.73-.74,15.94-3.14,17-4,5.53-4.53,4.21-9.92,10-13,2.7-1.44,5.8-1.29,12-1,8.22.39,13.01.61,17,4,2.57,2.18,3.71,4.79,6,10,3.33,7.59,3.22,11.78,5,12,2.13.27,2.95-5.65,8-11,3.61-3.83,7.46-5.27,15-8,14.45-5.23,17.19-2.63,24-7,7.33-4.7,10.56-11.82,12-15,4.32-9.53,3.26-17.4,3-27-.07-2.71,1.28-15.81,4-42,2.59-24.97,3.98-26.38,6-45,4.02-37.06,2.74-70.59,2-90-.26-6.89-.83-18.88-.57-35.78.27-17.51,1.06-16.17.93-26.41-.02-1.6-.07-2.91-.09-3.55-.04-1.36-.42-13.19-1.71-24.26,6.73-16.55-9.5-276.7-7.16-280.11,3.02-4.41,13.19-6.29,44.84-6,15.85.15,26.04.22,30.26,0,13.31-.69,11.05-1.8,14.02,0h0Z"
          />
        </g>
        <g id="Outlines">
          <path
            id="outline3"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M5.59,611.28c17.86-1.01,21.09-3.46,35-4,10.48-.41,18.32.73,34,3,12.05,1.75,17.29,3.18,37,6,3.21.46,11.84,1.65,23,1,12.73-.74,15.94-3.14,17-4,5.53-4.53,4.21-9.92,10-13,2.7-1.44,5.8-1.29,12-1,8.22.39,13.01.61,17,4,1.9,1.62,3.27,4.41,6,10,2.39,4.89,3.89,8.91,5,12,3.38,9.44,5.07,14.17,5.76,18.02.74,4.12,3.53,19.78-5,31-3.03,3.98-3.13,1.67-15,12-6.34,5.52-8.33,7.93-12,8-3.56.07-5.49-2.12-12-7-1.34-1-9.81-7.35-15-10-8.72-4.45-15.6-2.26-26.76-2.02-15.17.34-22.68-3.27-36-6-24.24-4.97-40.77-1.51-74,0"
          />
          <path
            id="outline2"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M5.59,694.3c19.1-.13,1.78.45,28-2.02,8.11-.76,21.53-2.2,38-5,8.78-1.49,14.19-2.66,25-5,9.16-1.98,12.46-3.43,25-7,24.66-7.01,24.4-5.48,31.76-8.98,10.12-4.82,17.21-10.86,22-15,9.93-8.58,9.67-11.22,19-19,8.77-7.31,22.02-15.83,25-13,1.82,1.73-2.11,5.88-3,14-1.14,10.3,3.55,18.49,9,28,7.03,12.27,11.1,19.37,20,24,13.24,6.88,27.2,3.11,35,1,7.66-2.07,16.33-4.41,23-12,6.1-6.94,5.87-12.96,12-15,6.21-2.07,13.22,1.85,15.24,2.98,1.48.83,5.34,3.04,8,7,2.54,3.78,4.14,9.38,1,25-4.08,20.26-8.62,19.94-9,32-.37,11.79,3.83,16.51,0,23-3.6,6.1-10.93,8.03-15,9-29.23,6.94-62.99,5.15-66,5-20.62-1.06-13.56,1.53-60,3-37.16,1.18-56.09-.02-100-1-77.79-1.74-25.21,1.66-84-.27"
          />
          <path
            id="outline1"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M355.35,4.19c.12,3.98,4.5,269.55,7.04,276.11-.39,12.86-1.33,14.62-2.8,33.98-1.22,16.02-1.4,25.51-1.25,33.83.13,7.75.83,7.06-1.75,42.17-3.31,45.17-4.41,45.63-9,100-4.24,50.22-7.74,102.29-8,121-.12,8.67-1.53,17.34-1,26,.31,5.01.85,8.56-1,13-1.85,4.45-3.76,6.28-7,12-3.24,5.71-3.65,8.55-5.24,12.02-4.5,9.79-13.64,14.96-19,18-20.87,11.82-44.67,4.97-55,2-6.21-1.79-18.67-5.52-31-16-5.79-4.92-14.83-12.61-19-26-1.35-4.33-4.84-15.54.24-27.02,2.47-5.57,6.1-9.28,8-11,1.79-1.63,5.38-4.52,15-8,14.45-5.23,17.19-2.63,24-7,7.33-4.7,10.56-11.82,12-15,4.32-9.53,3.26-17.4,3-27-.07-2.71,1.28-15.81,4-42,2.59-24.97,3.98-26.38,6-45,4.02-37.06,2.74-70.59,2-90-.26-6.89-.83-18.88-.57-35.78.27-17.51,1.06-16.17.93-26.41,0,0-.02-1.76-.09-3.55-.13-3.49-4.61-282.82-10.51-310.35"
          />
        </g>
        <g id="Overview">
          <path
            id="partofancon4"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M327.35,510.3c-1.6,11.28-3.99,20.44-6,27-5.27,17.15-7.9,25.72-15,33-8.62,8.84-19,17-19,17-3.8,2.99-7.49,5.66-8,10-.48,4.07,2.11,7.42,5,11,12.4,15.37,14.99,16,18,21,5.28,8.76,6.41,21.88,1,32-6.41,11.98-19.57,15.19-27,17-6.71,1.63-23.76,5.79-38-5-14.05-10.64-14.85-27.87-15-31-.18-3.87-.48-21.5,11-31,6.57-5.43,10.98-3.25,21-11,7.39-5.72,11.21-11.72,12-13,4.72-7.59,5.93-14.52,7-21,.96-5.82,1.78-14.88,0-26"
          />
          <path
            id="partofancon3"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M336.84,10.19c0,117.19.47,478.14-4.49,478.14"
          />
          <path
            id="partofancon2"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M288.36,10.19c12,157-2.03,527.11-8.24,527.11"
          />
          <path
            id="partofancon1"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M336.84,628.3c-9.93,12.01-16.8,20.93-25.77,26.92"
          />
          <path
            id="radius4"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M198.92,676.3c-16.97-36.51-8.95-68-2.33-63.02"
          />
          <path
            id="radius3"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M190.59,603.28c-22.24,36.51,3.73,73.02,8.33,73.02"
          />
          <path
            id="ulna3"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M149.35,692.76c0,5.83-36,12.54-78,3.54"
          />
          <line
            id="ulna2"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            x1="161.35"
            y1="744.3"
            x2="5.59"
            y2="745.09"
          />
          <line
            id="ulna1"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            x1="5.59"
            y1="704.18"
            x2="134.35"
            y2="703.3"
          />
          <path
            id="radius2"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M149.35,619.5c-2.55,2.77-13.1,3.51-19,5.8-9.93,3.86-18.84,1.37-35-3-23.65-6.39-35.55-9.6-39-10-9.45-1.11-23.67-.99-42,6"
          />
          <path
            id="radius1"
            class="oberarm_mit_ellenbogen_lat_left2233_cls6"
            d="M131.92,672.45c-10.74-3.08-27.16-7.24-47.57-10.15-18.03-2.57-30.43-2.92-58-4"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="anconhighlight"
          class="Ellenbogen2233Fill"
          style={{
            fill: props.colors.Ellenbogen2233Fill,
            stroke: props.colors.Ellenbogen2233Fill,
          }}
          d="M154.21,611.93c-9.53,44.26,50.56,83.69,68.04,95.16,3.59,2.35,81.68,52.62,111.37,24.38,12.01-11.42,11.09-30.91,10.6-41.35-1.95-41.49-34.15-68.2-50.4-81.67-18.68-15.49-66.26-54.95-107.21-35.43-1.17.56-26.89,13.28-32.4,38.91Z"
        />
        <path
          id="radialheadhighlight"
          class="RadiusHead2233Fill"
          style={{
            fill: props.colors.RadiusHead2233Fill,
            stroke: props.colors.RadiusHead2233Fill,
          }}
          d="M151.8,647.06c.48-2.59,1.17-4.49,1.25-8.12.04-1.79-.08-3.6-.64-6.94-1.8-10.83-4.5-15.68-2.12-18.17.64-.67,1.3-.81,2.53-2.19.53-.6,1.08-1.32,2.43-3.88,1.99-3.76,2.15-4.73,3.54-6,.89-.82,1.73-1.24,2.32-1.53,2.98-1.49,7.51-1.29,17.5-.69,4.93.3,7.98.89,10.62,3.03,1.45,1.18,2.35,2.6,4.15,5.46,1.93,3.06,3,5.46,4.85,9.61.6,1.35,1.89,4.3,3.35,8.18.53,1.41,1.08,2.95,1.64,4.63,1.82,5.44,2.86,8.54,3.65,12.8.62,3.38,2.38,13.42-.95,23.6-1.83,5.6-4.08,7.88-5.31,8.93-1.15.98-1.69,1.12-4.15,2.83-2.71,1.88-4.53,3.45-7.08,5.61-5.39,4.57-8.08,6.86-8.24,7.03-.74.79-2,2.19-4.1,2.78-1.55.44-2.89.23-3.39.15-.44-.07-1.92-.35-5.26-2.63-.72-.49-1.13-.81-2.83-2.12-5.12-3.97-5.42-4.23-6.57-5.06-3.34-2.41-3.2-1.93-4.3-2.98-.28-.27-2.98-2.91-3.99-6.52-1.06-3.79.71-5.16,1.52-10.92,1.14-8.12-1.9-8.9-.4-16.89h-.02Z"
        />
        <path
          id="humeroulnarjointhighlight"
          class="humeroulnarenGelenk2233Fill"
          style={{
            fill: props.colors.humeroulnarenGelenk2233Fill,
            stroke: props.colors.humeroulnarenGelenk2233Fill,
          }}
          d="M335.52,655.66c-.11.2-.25.44-.54.92-1.4,2.32-2.53,3.67-4.2,6.52-3.74,6.39-2.37,7.58-5.58,13.33-3.29,5.91-7.44,9.51-9.57,11.33-1.27,1.08-6.41,5.35-14.97,8.49-12.57,4.62-23.73,3.62-31.55,2.83-4.36-.44-18.56-2.18-34.52-10.65-5.18-2.75-10.96-5.82-17.12-11.73-5.29-5.07-16.24-15.57-14.73-27.86.93-7.51,6.39-14.73,9.65-14.13,2.65.49,2.23,5.87,5.06,13.55.4,1.08,8.06,20.96,27.25,28.29,7.39,2.82,13.81,2.77,17.39,2.7,8.2-.15,13.87-2.36,25.21-6.78,12.81-4.99,28.05-12.66,33.07-17.39,1.13-1.06,3.24-3.39,6.65-4.85.61-.26,1.41-.56,2.5-1.05.79-.36,1.28-.62,1.47-.72,1.6-.79,5.39-1.63,6.54-.19,1.25,1.56-.92,5.45-2.01,7.39Z"
        />
        <path
          id="brachiumhighlight"
          class="Oberarm2233Fill"
          style={{
            fill: props.colors.Oberarm2233Fill,
            stroke: props.colors.Oberarm2233Fill,
          }}
          d="M357.2,100.17c.83,47.02,1.12,41.1,1.82,81.51,0,0,.51,29.48,1.84,72.6.04,1.42.13,3.93.28,7.16.25,5.26.58,9.93.84,13.34.38,4.97.42,4.37.47,5.8.24,6.22-.61,10.95-.89,12.61-1.58,9.35-1.58,18.9-2.49,28.34-1.83,19.1-1.13,36.7-.98,46.4.44,28.18-3.09,36.17-11.62,134.79-1.54,17.83-4.66,54.87-5.47,77.14-.35,9.48-.29,16.69-1.27,28.43-.33,3.96-.91,10.01-1.08,18.2-.12,5.68-.18,8.52-.11,9.97.24,5.47.82,7.18-.02,10.66-.73,2.99-2.11,5.63-2.68,6.69-.88,1.66-1.12,1.78-2.85,4.5-2.16,3.39-4.29,6.76-5.93,11.06-.81,2.12-1.2,3.66-1.35,4.18-1.9,6.68-13.31,17.79-29.1,22.85-16.32,5.24-30.46,1.82-40.47-.61-12.1-2.93-21.36-7.59-27.24-11.06-2.02-1.26-30.99-19.87-30.07-45.71.12-3.45.43-12.13,6.34-19.96,5.65-7.48,13.75-10.64,17.93-12.27,5.42-2.11,10.5-4.09,16.45-4.58,1.06-.09,3.19-.21,5.8-1.21,3.49-1.34,5.72-3.43,7.21-4.85,1.19-1.13,3.86-3.87,6.4-8.76,4.58-8.8,4.93-17.22,5.06-21.1.15-4.75-.45-4.64-.34-10.99.12-6.27.78-10.8,1.21-13.89,1.32-9.48.16-3.85,2.97-27.64.86-7.29,1-8.12,3.24-25.89,3.43-27.29,3.68-29.98,3.88-32.46.76-9.42.9-16.32,1.18-30.13.16-8.04.38-19.42-.07-34.39-.31-10.59-.52-8.31-.82-18.71-.58-20.18.01-34.81.21-39.34.32-7.11.43-6.19.61-11.43.43-12.92-.17-21.18-.61-31.35-.36-8.38-.02-4.33-.61-31.96-.34-16.21-.63-25.46-.94-37.35-.89-33.54-.6-33.53-1.48-69.3-.12-4.82-.7-28.35-1.75-59.06-1.63-47.86-1.63-32.28-2-47.32,0,0-.18-7.27-2.04-31.42-.33-4.27-.65-5.96-.81-6.74-.41-1.99-.61-2.22-.5-2.74.78-3.73,13.7-4.07,16.95-4.14,14.09-.29,23.27.67,30.47,1.08,24.4,1.38,40.23-2.8,42.58,3.05.11.28.09.32.12,4.64.03,4.13.15,8.26.17,12.4.04,7.82.35,17.8.54,24.74.43,16.12.63,32.71,1.01,54.2Z"
        />
      </g>
    </svg>
  );
}

export default LinkerOberarmMitEllenbogenLatSitzend2233;
