import style from "./style.scss";

function LinkesDaumenGrundGelenkLatSitzend1309(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 181 321.9"
    >
      <g id="Thumbbasejoint">
        <g id="background">
          <path
            id="background1_white"
            data-name="background1 white"
            class="linkes_daumengrundgelenk_lat_sitzend1309_cls5"
            d="M146.9,515.6l-62.1.9c-5.1-26-16.9-50.8-34.7-67.2-5.3-10.3-7.6-20.5-3.5-30.6-4.5-.1-8.8-1.5-13-4.3-8-6-6-18.2,8.2-38.1l-16.2-61.7-13.5-65.6.7-20.6c10.9,21.1,22.6,38.8,35.4,51.7,8.4-34-1.6-70-16-108.3-2.4-6.4-4.2-12.9-5.9-19.5-1.6-6.6-4.4-10.7-8.5-11.8l3.1-33.4v-6.4c0-12.1.5-23.4,2-33.5l1.2-9.7c16.6,2.9,33.2,25.9,52.9,72.9,10.5.4,20.5,3.3,30,8.4,6.2-6.4,8.5-14.6,7.4-24.3-.4-12.7,3.4-35.1,3.4-35.1-2.7-3.2-5.5-9.4-6.1-15.6-.6-17.9.9-34.3,8.3-46.7,4.5-10.5,11.6-14.1,20.5-12.4,9.5,1,11,9.9,10.2,29.7l7.9,33.6c3.8,7.8,5.4,19.8,5.4,34.7l11.8,60.5c.9,8.9-.5,20.1-4.9,34.1l-8.9,62.5c-2.9,15.6-6.2,31.1-10.7,46.3-2.9,8-6.6,14.8-13.7,17.1,1.2,6.8,4.3,11.2,9.4,13.1,7.4,4.5.2,8.9,1.6,14.4-4.2,11.5-20.6,11.9-25.8,9.5h-6.1s.9,5.2.9,5.2c6.8,7-.3,18.6-12.3,31.8l3.5.5,18.6-22.8c10.9.3,14.2,12.4,14.1,29.6,7.8,20.7,8.8,46.6,3.1,77.6l2.1,33.5h0Z"
          />
        </g>
        <g id="Thumbbasejoint1">
          <g id="Thumbbase">
            <path
              id="fingerparts25"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
              d="M141.6,295.7h0c-.5.9-1.5,1.4-2.5,1.2-3.2,3.5-6.6,2.5-10.1-1.8-7.2-7.8-14.9-11.2-23.1-10.3-6.3.2-7.2-2.6-5.4-7l4.7-15.2c11.8-6.4,18.7-16.2,23.5-27.6,3.1-14.7,5.6-28.4,7-39.4,1.2-4.6,1-9.2-.4-14-5.1-3-5.7-7.3-1.9-13,2.4-7.7,7.1-11.2,12.9-12.3,3.4-.7,7-.3,10.2,1.1,5.1,2.1,9.1,4.7,12,8,5.8,7.6.4,15.7-2.1,23.9-1.3,16.8-3.7,32.4-7.8,46.2l-6,39.2c-.4,13-3.3,21.3-10.9,21h0Z"
            />
            <path
              id="fingerparts24"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
              d="M165.6,149.4c-.7.8-1.2,1.7-1.4,2.7-.2.8-.4,1.6-.8,2.3-1.2,2.6-4.2,3.9-6.9,2.9-3.9-1.3-9.2-2-15.4-2.3-1.9,0-3.9.2-5.7.8-2.9,1-5.5.1-7.6-4.3-3.2-4.8-3.2-9.4-.2-14,1.5-2.4,2.9-4.9,3.8-7.6l3-8.6c3.2-10.6,4-19.9,2.1-27.7-.5-2.2-1.8-4-3.5-5.5-2.4-2-3.6-4.7-3.3-8.4.4-4.4,3.2-7.5,7.8-9.5,5.8-1.3,10.7-.5,14.2,3.9,6.6,3.9,8.7,8.5,6.8,13.7-.4,1-.6,2.1-.6,3.2.2,17.5,1.9,34.2,6.3,49.2.2.6.5,1.2.9,1.8,2,2.6,2.4,5,.5,7.1h0,0Z"
            />
            <path
              id="fingerparts23"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
              d="M152.6,69.9h0c-5.8-1-12,.8-18.6,5.2-2.9-.8-4.4-2.8-4.3-6-1.8-.6-3-2.4-3.1-6.2.5-5.3,2-7,3.9-6.8-2.8-6.9-1.9-13.8,1.6-20.8.5-.9.6-2,.5-3-.9-6.2,0-12,4.1-16.7,2.6-3.8,5.2-3.6,7.7-1,.2.2.2.4.2.6l-.7,5.1c-.1.8,0,1.6.3,2.4.9,2.3,1.5,4.9,2,7.7,0,11.1.8,22.1,5.4,32.3,2.1,2,3,4.3,1.2,7.2h0Z"
            />
            <path
              id="fingerparts22"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
              d="M138.2,168h0c-1.2,2.6-2.5,4.4-4.3,4.9-4,0-8.6-4.1-8.6-4.1,0,0-1-2.6-1.1-5.1,0-2,1.2-3.8,3.1-4.4,4.2-1.3,7.8-1.1,10.9.7,2.2,2,2.8,4.4,0,7.9h0Z"
            />
            <path
              id="fingerparts21"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
              d="M115.1,165.7h0c.3-3.6,2.1-4.8,4.3-5.3.9-.2,1.9-.3,2.8-.5,3.9-.8,4.7,1.3,4.3,4.4.2,2.7,0,5.3-3.5,7.4-4,.6-7.1-.7-7.9-6h0Z"
            />
            <path
              id="fingerparts20"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
              d="M79.7,310.2l3.5-4.7c3.1-7.1,8-8.8,14-7.6,4,2.3,7.8,2.9,11.1,0,2.6-4.7.8-11.7-2.7-19.6,1.5-4.8.2-9.6-3.7-14.6-9.5-6.4-16.8-23.1-22.3-47.8-5.7-19.8-10.1-47.5-14.2-77.5-3.6-5.9-3.8-10.1-1.5-13.2,1-9.8-5.8-16.3-16.9-21-8.9-3-17.5-1.9-25.8,2.7"
            />
            <path
              id="fingerparts19"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
              d="M21.1,100.6c6.2-3,12-5.2,16.1-4.5,5.7-4.4,5.7-9.9,3.1-15.9-1.3-3.7-3.5-5.6-7.2-5.1-4.3,0-7.9-1.9-10.7-5.2"
            />
            <path
              id="fingerparts18"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
              d="M48.5,310.2c-1.7-3.7-1.8-8.3-1.2-13.2,2-4,1.6-9.3.6-15,4.7-23.5,4.7-40.1,1.9-52.5-5.9-24.1-14.5-51.2-24.1-79.7-1.7-5.6-4.4-8.4-7.8-9.5"
            />
            <path
              id="fingerparts17"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
              d="M63.6,310.2l-4.1-8c-1.1-7.5-5.8-15-13.6-22.5-12.5-16.4-23.5-33.2-33-50.3"
            />
            <path
              id="fingerparts16"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
              d="M33.9,105.7h0c0-2.8,2.5-5.2,6.4-7.4,2.9-.8,5.1.4,6.4,4.3.1,4.4-1.6,7.8-6.2,9.9-4.9.6-7.5-1.3-6.6-6.8h0Z"
            />
            <path
              id="fingerparts15"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M11.6,247.7c4.8,9.9,10.9,18.1,18.5,24.5,5.9,3.9,10.6,7.9,13.6,11.9"
            />
            <path
              id="fingerparts14"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M150.9,265.8c1.3-26.2,4.5-52.4,10.7-78.4.5-4.4,2-6.8,3.9-8.4"
            />
            <path
              id="fingerparts13"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M111,261.5c16.7-5.7,24.6-18.8,27.4-36.1,2.5-17.7,7-33.1,12.4-47.4"
            />
            <path
              id="fingerparts12"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M138.6,175.7c2.1,2.8,3.5,6.1,3.4,10.3"
            />
            <path
              id="fingerparts11"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M159.2,135.2c-3.1-6.5-5.6-14.2-6.6-24.1-2-11.9-1.7-20.2,2-23.6"
            />
            <path
              id="fingerparts10"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M135.2,128.7c8.2-12.6,10-25.9,6.2-39.7"
            />
            <path
              id="fingerparts9"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M155.2,81.7c.4-3.5-.7-6-3.6-7.3-4.1-.6-8-.2-11.3,1.9-3.9,2.5-3.9,7.2-2.6,12.8"
            />
            <path
              id="fingerparts8"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M141.2,20.4c-1.1,20.8,1.7,35.4,8.9,43.2"
            />
            <path
              id="fingerparts7"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M148.6,65.2c-5.7-2.8-11.1-2.4-16.1,1.9.2-.7.3-1.5.3-2.5,0-1.1.3-2.2.8-3.3.6-1.2.6-2.7.5-4.2-.2-1.9,0-3.2.2-4.1.2-.5.3-.9.3-1.4,0-3.7,0-7.4.3-9.8.1-.7.4-1.4.7-2,2.1-4.1,2.6-8.5,2.2-13.1,0-.7-.3-1.5-.8-2-.7-.9-.9-2-.5-3.3"
            />
            <path
              id="fingerparts6"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M129.7,69.1c1.1-1.4,1.5-3.2,1.1-5.5-1.2-1.5,1.8-7.4-.4-7.5"
            />
            <path
              id="fingerparts5"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M137.8,155c1.6-3.9,8.4-4.5,21-1.5"
            />
            <path
              id="fingerparts4"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M48.6,119.4c2.5,1.3,4.5,2.9,5,5.6.8,3.3,2.2,5.1,4,6.1,2.9,1.2,2.5,6.2.9,12.6-.6,2.4-.9,4.9-.7,7.4,2.3,35.8,7.2,53.8,12.1,71.3,2.7,14.1,7.6,25.2,15.1,32.8,6,7.3,10.7,14,13.5,19.7"
            />
            <path
              id="fingerparts3"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M48.6,107.9c-.8,6.2,6.9,10,7.3,13.3,0,.7.4,1.3.7,1.9,2.2,3.8,4.3,3.2,5.9,5.2"
            />
            <path
              id="fingerparts2"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M58,289.1c5.1-31.8,3.9-59.3-4.8-81.7-4.7-14.4-12.7-33.3-21.7-53.4l-5-17c-1.9-6.1-4.6-9.4-7.7-10.8"
            />
            <path
              id="fingerparts1"
              class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
              d="M24.3,140.4c-1.8-4.7-3.6-7-5.4-7"
            />
            <g id="outlines3">
              <path
                id="outlinepart13"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
                d="M152.4,302.3c-.4,1.3-.8,2.5-1.1,3.7"
              />
              <path
                id="outlinepart12"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls1"
                d="M113.8,72.7c-4.2-10.3-3-26.3,1.3-45.4,4.4-14.4,10.2-22,17.4-22.4,7.1-1,12.1.2,15.2,3.7,1.6,1.8,2.4,4.2,2.5,6.6.7,24.5,4.4,42.1,10.2,54.6,1.8,3.9,2.8,8.2,2.9,12.6.9,26.3,5.4,50.5,11.7,73.6,2.5,9,1.3,19.3-1.2,30-10.5,46.4-8.5,69-18.6,104.7-.3,1.5-.6,2.8-.9,4.1"
              />
              <path
                id="outlinepart11"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
                d="M116.8,82.9c.3-1.3.7-2.5,1.1-3.7-.9-.9-1.6-1.9-2.3-3"
              />
              <path
                id="outlinepart10"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls1"
                d="M111.8,132.5c2.5-4.9,3.6-11.2,3.3-18.7-.6-8.9-.5-16.7.3-23.3"
              />
              <path
                id="outlinepart9"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
                d="M103.9,136.8c1.1.6,2.2,1.2,3.3,1.9.9-.9,1.8-1.8,2.6-2.9"
              />
              <path
                id="outlinepart8"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls1"
                d="M85,130.8c4,.5,7.9,1.4,11.6,2.7"
              />
              <path
                id="outlinepart7"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
                d="M75.6,126.7c.5,1.2,1.1,2.3,1.6,3.5,1.3,0,2.6,0,3.8.2"
              />
              <path
                id="outlinepart6"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls1"
                d="M31,61.1c6.9,3.8,12.8,7.6,15.7,10.9,8.8,13,17.3,29.6,25.7,47.8"
              />
              <path
                id="outlinepart5"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
                d="M24.3,57.4c1.1.6,2.3,1.2,3.4,1.8"
              />
            </g>
            <g id="outlines2">
              <path
                id="outlinepart4"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
                d="M110.6,140.5c-1.1-.7-2.2-1.3-3.4-1.9"
              />
              <path
                id="outlinepart3"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
                d="M117.9,145.6c-1-.8-2.1-1.5-3.1-2.3"
              />
            </g>
            <g id="outlines1">
              <path
                id="outlinepart2"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
                d="M104.4,146.1c-.5,1.3-.8,2.5-1,3.7"
              />
              <path
                id="outlinepart1"
                class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
                d="M107.2,140.4c-.7,1.2-1.3,2.3-1.8,3.4"
              />
            </g>
          </g>
          <path
            id="Bonepart6"
            class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
            d="M148.6,348.6h0c.1-1.6.5-3.1,1.1-4.7,1.3-3.3.2-6-2.7-7.8s-5.5-4.2-7-7.2c-.9-1.8-2.4-5.8-2.4-5.8,0,0-.6-10.7-4.2-14.7-6.3-4.6-10.7-3.9-14.4-.8-2,1.7-3.4,3.9-4.3,6.3-2.3,5.6-5,10.2-8.3,13.1-1.9,1.7-3.6,3.6-5.1,5.7-2.9,4.3-4,8-.5,9.9,8.7,3.2,15.8,8.6,21.1,16.4,1.1,1.5,2.8,2.5,4.7,2.5,12.3-.2,21.5-3,22.1-12.9h0Z"
          />
          <path
            id="Bonepart5"
            class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
            d="M82.2,365.3h0l-.5-13.7c0-2.1-1.3-4-3.1-5.1-4.2-2.5-2.3-7.3.6-12.5,3-6.9-.6-11.5-7.6-15-4.9-11.1-13.1-13.2-22.9-11.2-4.7-4.7-10.1-5-16-2.3-6.4,5.3-8.8,9.6-5.6,12.8l11.7,48c1.6,9.6,6.9,15.1,18.8,12.8,11.1,1.2,19.9-2.2,24.6-13.8h0Z"
          />
          <path
            id="Bonepart4"
            class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
            d="M91.6,341.6h0c8.5.7,15-.2,16.6-4.9l3-8.7c5.2-5.3,4-10.7-2.8-16.4-4.9-5.3-9.9-7.2-15.3-4.1l-9.9,1.7c-13.2,4.1-4.5,7.6,1,22.3-.6,7.5,1.2,11.9,7.6,10h0Z"
          />
          <path
            id="Bonepart3"
            class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
            d="M111.8,317.1c-10.9-8.6-22-5.4-33,2.8"
          />
          <path
            id="Bonepart2"
            class="linkes_daumengrundgelenk_lat_sitzend1309_cls2"
            d="M71.7,319.1c-3.7-3.3-7.2-5.7-10.3-5.7-.7,0-1.3.3-1.8.7-2.2,1.6-4.8,2.4-7.6,2.1"
          />
          <path
            id="Bonepart1"
            class="linkes_daumengrundgelenk_lat_sitzend1309_cls3"
            d="M37.9,362.4c1.3-22.7-3.2-35.4-10.2-41.7"
          />
        </g>
      </g>
      <g id="highlights">
        <ellipse
          id="highlightsparts"
          class="daumengrundgelenk1309fill"
          style={{
            fill: props.colors.daumengrundgelenk1309fill,
            stroke: props.colors.daumengrundgelenk1309fill,
          }}
          cx="148"
          cy="156.8"
          rx="27.1"
          ry="8.8"
        />
      </g>
    </svg>
  );
}

export default LinkesDaumenGrundGelenkLatSitzend1309;
