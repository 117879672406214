import { Modal, Button } from "react-bootstrap";

function HelpModal({ shown = false, onHide, dark = false, children }) {
  return (
    <Modal show={shown} onHide={onHide} size="xl">
      <Modal.Header
        className={`p-2 ${
          dark && "bg-dark text-white"
        } d-flex justify-content-center align-items-center`}
      >
        <h4 className="p-0 m-0 mr-2">Help</h4>
        <i className="fas fa-life-ring fa-lg " />
      </Modal.Header>
      <Modal.Body className={`${dark && "bg-dark text-white"}`}>
        {children}
      </Modal.Body>
      <Modal.Footer className={`p-0 ${dark && "bg-dark text-white"}`}>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HelpModal;
