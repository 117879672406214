import examImages from "./static/examPlannerImages";
import indincationImages from "./static/indicationImages";

function ExamPlannerExaminationImage({ examId, width = undefined, style }) {
  return width ? (
    <img
      src={
        examImages[`exmImg${examId}`] === undefined
          ? examImages.defExmImg
          : examImages[`exmImg${examId}`]
      }
      alt={`examination ${examId}`}
      width={width}
      style={style}
    />
  ) : (
    <img
      src={
        examImages[`exmImg${examId}`] === undefined
          ? examImages.defExmImg
          : examImages[`exmImg${examId}`]
      }
      alt={`examination ${examId}`}
      style={style}
    />
  );
}

function ExamPlannerIndicationImage({ indId }) {
  return (
    <img
      src={
        indincationImages[`indImg${indId}`] === undefined
          ? indincationImages.placeholder
          : indincationImages[`indImg${indId}`]
      }
      alt={`indication ${indId}`}
    />
  );
}

export { ExamPlannerExaminationImage, ExamPlannerIndicationImage };
