import style from "./style.scss";

function RechterUnterarmMitHandgelenkLatSitzendLinks2206(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148.32 495.13">
      <g id="Backround">
        <path
          id="backround1"
          class="unterarm_mit_handgelenk_lat_rechts2206_cls3"
          d="M59.75,495.02c-1.86-8.81-3.65-22.29-1.21-38.28,1.26-8.3,2.44-9.21,5.46-23.06,1.21-5.54,2.82-13.63,6.47-41.66,1.65-12.68,2.94-22.51,4.45-36.61,3.3-30.85,4.72-54.59,7.48-100.92,1.14-19.13.94-17.87,1.75-30.2,1.38-20.96,1.96-24.57,1.89-34.31-.12-15.49-.18-23.38-3.64-33.64-3.51-10.42-6.17-10.4-8.76-21.03-1.85-7.58-1.84-13.11-6.61-18.74-2.1-2.48-3.54-3.1-5.26-6.2-2.82-5.08-1.07-7.24-3.51-12.13-2.7-5.43-5.56-4.24-7.69-9.3-2.3-5.49.39-8.52-2.43-11.46-2.43-2.54-5.53-1.42-7.65-4.37-1.45-2.02-1.25-4.29-1.25-5.74-.02-9.05-8.05-17.3-14.83-24.27-3.29-3.38-5.98-6.15-10.31-9.07-1.35-.91-8.81-5.87-12.23-10.43-.64-.85-1.11-1.65-1.44-2.61C-.17,19.23,0,17.95.08,14.9c.09-3.93-.16-3.75,0-4.45C1.35,4.89,14.92,3.07,24.15,1.82c4.44-.6,8.09-.88,10.39-1.03,2.42.8,5.89,2.24,9.41,4.93,2.82,2.16,3.7,3.6,7.7,8.24,3.78,4.37,7.34,8.46,12.13,12.4,5.36,4.41,8.21,6.68,11.32,5.95,2.74-.64,4.28-3.72,7.4-9.8,1.86-3.63,3.31-6.46,4.06-10.57,1.18-6.42-.65-8.77,1.35-10.65.8-.76,1.66-.91,11.06-.86,3.91.02,4.08.05,7.15.06,5.6.01,8.39.02,10.92-.13,3.14-.19,3.92-.39,6.34-.33,2.35.06,4.14.32,6.07.6,4.16.6,6.24.9,6.93,1.49,2.84,2.43-1.29,9.41-2.48,16.44-2.82,16.78,7.22,23.16,5.04,39.72-.58,4.45-2.18,10.84-6.93,18.26-1.65,3.72-3.53,9.41-3.08,16.2.74,11.12,6.98,13.83,6.44,23.78-.43,7.82-4.4,8.23-4.7,17.72-.18,5.46,1.11,6.07,1.62,14.29.42,6.82-.15,10.65-.54,16.45-.1,1.46-.17,2.81-.2,4.19-.27,10.33.81,22.23.81,22.23,1.6,15.13,6.52,82.84,8.29,127.21.85,21.36.71,30.7,2.32,53.01,1.04,14.48,1.67,18.39,2.94,37.6,0,0,1.85,27.91,1.62,45.3,0,.27-.02,1.27,0,2.63.05,4.73.4,8.22.46,8.88.28,3.07.38,10.68-.17,28.88-4,.03-8.01.06-12.03.08-10.29.05-20.54.06-30.73.03-1.7.01-4.26.02-7.38,0-2.29-.01-3.8-.03-4.76-.05-5.46-.06-9.85.05-12.23.09-4.07.07-10.55.12-20.93-.05Z"
        />
      </g>
      <g id="Forearmlat">
        <g id="Forearmoverview">
          <path
            id="radius4"
            class="cls-7"
            d="M94.37,494.86c0-1.1,0-2.2.02-3.3l.54-28.27,5.06-82.72,2.65-41.06,1.81-47.2,3.01-59.6c2.3-22.42,8.16-57.38,14.57-94.28,3.44-4.06,3.77-7.88,2.29-11.56-4.25-7.89-12.3-12.33-22.88-14.45-6.5-5.7-12.11-6.5-15.89,2.65-9.34,10.62-8.47,19.38-3.13,27.33,4.93,5.06,7.66,15.58,7.95,32.15-.92,26.97-3.8,77.57-6.86,130.4-3.54,43.08-8.12,84.7-14.37,123.98-1.18,7.39-2.69,14.73-4.45,22.01-3.05,12.63-3.62,25.45-1.65,38.47.41,1.85.76,3.67,1.06,5.45"
          />
          <path
            id="radius3"
            class="cls-7"
            d="M85.55,115.07c-4.65,9.8,6.1,15.92,21.55,19.99,7.18,1.06,12.66-2.31,17.62-7.03"
          />
          <path
            id="radius2"
            class="cls-7"
            d="M99.76,164.43c.4-10.75-3-19.84-10.31-27.21-4.8-3.9-7.35-8.22-6.18-13.24"
          />
          <path
            id="ulnar2"
            class="cls-7"
            d="M104.21,494.89c.88-2.48,1.52-5.03,1.95-7.7v-.03c.33-2.08.8-4.14,1.41-6.16,2.67-5.27,4.69-12.98,6.48-21.56,3.47-14.66,2.59-31.96,2.24-48.18-.36-10.8-2.08-27.68-3.97-45.35l-2.44-39.18-1.75-57.35c-.06-1.77-1.64-41.45-.62-63.53.57-12.42-.16-24.87-2.52-37.07-2.13-11.02-5.09-19.77-9.2-25.38-1.87-3.97-2.24-7.82.02-11.47,1.65-4.72,5.18-6.13,9.59-6,3.76-2.28,7.32-.64,9.14,1.91l.31-3.07,1.86-3.32c4-1.28,7.09-.02,8.27,6.57,2.3,2.67,2.51,9.93.49,22.06l4.34,60.65,9.56,167.35,7.69,98.03c.33,4.1.53,8.21.62,12.33l.14,6.48"
          />
          <path
            id="radius1"
            class="cls-7"
            d="M121.35,142.37c-5.34-2.25-9.55,2.95-14.73,1.84"
          />
          <path
            id="ulnar1"
            class="cls-7"
            d="M113.15,141.87c.85-3.89,2.47-7.38,4.88-10.46,1.74-3.44.72-6.74-1.31-9.97"
          />
          <path
            id="carpalbone8"
            class="cls-7"
            d="M116.95,126.43c9.38-4.58,12.26-10.72,11.73-18.94-.14-2.09-.65-4.15-1.68-5.97-2.05-3.62-4.97-4.94-8-5.95-7.76-.9-13.18-.75-16.73-1.81-2.02-.6-3.64-2.14-4.49-4.07l-2.74-6.25c-.62-1.41-1.97-2.4-3.51-2.5-4.53-.3-8.78,1.14-12.83,3.79-6.49,4.54-9.83,9.73-11.62,15.25-.41,1.27-.6,2.62-.43,3.95.43,3.27,2.65,5.41,6.69,6.4,2.19.54,4.39,1.08,6.54,1.77l.29.09c1.82.58,3.48,1.59,4.83,2.94,3,3,8.46,8.46,10.45,10.45.5.5,1.06.95,1.69,1.28,3.64,1.94,7.3,2.7,10.98,2.63,4.45-.08,8.63-2.17,11.44-5.62,3.75-4.61,6.18-10.38,5.82-18.6-.03-1.97-.94-2.79-2.54-2.72-.65.03-1.29.24-1.88.51-3.95,1.83-7.66,3.07-10.98,3.33-5.88,1.06-10.71-.77-15.1-3.78-1.23-.84-2.23-1.94-3.15-3.1-1.05-1.32-2.49-1.28-4.14-.5-2.12,1-3.68,2.88-4.47,5.08-.92,2.57-1.27,5.55-1.27,8.78,0,4.41.77,8.26,3.8,10.42,1.25.89,2.48,1.8,3.6,2.85h0c.77.72,2.02.47,2.43-.49.94-2.22,1.9-5.8,3.95-9.87.58-1.15,1.36-2.2,2.38-2.99,1.37-1.06,2.92-1.59,4.71-1.45,2.5.19,4.72,1.7,6.02,3.85,3.23,5.37,3.77,8.94,8.19,11.23"
          />
          <path
            id="carpalbone7"
            class="cls-7"
            d="M89.61,82.6l5.17,14.46c.63,1.76,1.66,3.34,3.08,4.55,4.81,4.09,10.33,4.27,16.46,1.05,3.59-2.01,6.19-5.26,8.33-9.05,1.19-2.11,1.5-4.58.97-6.94-.9-4-.95-8.18-.49-12.47l4.17-7.4c.94-1.66,1.43-3.53,1.43-5.43v-1.84c0-2.11-.98-4.12-2.66-5.4-2.32-1.77-4.92-2.73-7.75-3.08-1.75-.22-3.54.06-5.13.81-4.46,2.11-10.23,3.24-16.41,4.06-1,.13-2.02.2-3.04.22-2.56.06-4.73,1.22-6.35,3.88-4.91,5.41-2.68,13.5,2.23,22.58h0Z"
          />
          <path
            id="carpalbone6"
            class="cls-7"
            d="M96.78,55.9l-8.18-6.02c-4.65-4.09-9.69-4.99-14.99-3.75-4.42,1.94-6.85,5.88-7.62,11.48-.36,2.57.17,5.2,1.54,7.4.35.57.73,1.11,1.14,1.61,2.21,2.71,5.82,3.75,9.21,2.92,2.96-.72,6.21-.74,9.91.3,7.46,4.65,3.98,28.04,7.08,32.78,2.83,5.34,7.47,8.5,15.72,7.32,3.9-1.12,6.85-3.21,8.54-6.55.54-1.06.88-2.21,1.1-3.37l3-15.51c1.81-4.19,2.34-8.38,1.18-12.57-.47-8.1-1.57-14-4.21-14.66l-4.25-.68c-3.06-1.75-5.86-.79-8.61.68-2.71,2.01-6.47.83-10.56-1.37h0Z"
          />
          <path
            id="carpalbone5"
            class="cls-7"
            d="M96.2,77.23c-3.6,1.42-5.44,5.01-5.47,10.84,0,7.34,1.45,13.88,4.73,19.44,3.82,5.12,8.29,8.47,13.89,8.68,5.89-.94,9.65-3.39,10.55-7.87.65-3.23,1.25-6.26,1.84-9.22v-6.86c-4.62-3.16-7.87-7.86-10.55-13.19-4.85-3.28-9.85-3.74-14.99-1.82Z"
          />
          <path
            id="carpalbone4"
            class="cls-7"
            d="M55.54,78.56c-.96,2.75-1.02,5.78.04,8.49.63,1.61,1.58,2.97,2.95,4,1.1.83,2.42,1.31,3.77,1.57,8.24,1.58,16.18.99,23.68-2.73,8.91-4.24,15.91-10.44,20.71-18.93,1.42-2.78,1.87-4.84,1.6-6.4-.33-1.95-2.15-3.24-4.12-3.28-3.98-.08-8.13-1.82-12.32-4-4.38-1.71-8.36-1.34-11.85,1.62-2.24,3.46-7.04,6.41-12.56,9.23-1.45.74-2.94,1.42-4.5,1.89-3.58,1.07-5.89,4.17-7.41,8.54h.01Z"
          />
          <path
            id="metacarpalbone12"
            class="cls-7"
            d="M.6,13.17c.1,4.37,1.99,8.31,8.61,11.1,1.17.49,2.25,1.16,3.23,1.97,10.71,8.81,20.44,18.75,28.38,30.73,2.39,2.66,3.69,5.69,3.43,9.25-.07.97.29,1.92,1.03,2.54,2.93,2.44,5.97,4.25,9.53,3.41,1.18.09,2.18.39,2.71,1.24.22.37.54.69.95.82,5.15,1.62,10.24.17,15.27-4.59,4.22-3.18,6.67-7.22,7.85-11.88,1.83-5.01.71-9.97-4.52-14.86-3.11-5.23-7.61-8.11-13.09-9.34-1.42-.32-2.74-.98-3.8-1.97-11.04-10.31-18.9-18.86-23.47-25.57-.68-.99-1.2-2.07-1.58-3.2"
          />
          <path
            id="metacarpalbone11"
            class="cls-7"
            d="M48.41,64.88c1.77,1.36,3.84,2.33,6.44,2.61,2.14.23,4.32-.11,6.35-.85,4.98-1.82,9.78-4.21,14.13-8.03.95-.83,1.75-1.83,2.33-2.96,2.11-4.11,1.88-8.36-.6-12.75"
          />
          <path
            id="metacarpalbone10"
            class="cls-7"
            d="M20.96,19.1c3.04,10.43,20.96,28.46,24,41.22"
          />
          <path
            id="metacarpalbone9"
            class="cls-7"
            d="M75.64,44.11c-3.45-4.09-7.51-6.24-11.89-7.39-3.56-.38-6.96-2.47-10.28-5.37L29.57,9.17"
          />
          <path
            id="metacarpalbone8"
            class="cls-7"
            d="M104.69,1.28c-.14,15.56-2.6,28.69-7.46,39.28-1.02,2.23-1.61,4.66-1.52,7.11.14,4.11,1.96,6.18,5.96,5.56l14.58-3.24c5.31.04,9.5,2.08,12.48,6.27,3,1.23,4.29-1.33,3.22-9.11l-5.36-28.66c-.57-3.03-1.22-6.04-1.97-9.04-.63-2.53-1.21-5.46-1.74-8.72"
          />
          <path
            id="metacarpalbone7"
            class="cls-7"
            d="M91.04,51.68c.57-2.09-.15-4.73-1.32-7.56-1.91-4.75.46-9.47,3.04-14.18,3.46-4.48,4.74-15.65,5.42-28.66"
          />
          <path
            id="metacarpalbone6"
            class="cls-7"
            d="M118.91.03l.79,17.55c-.28,6.63.58,12.86,2.84,18.63,2.2,6.36.86,11.2-2.71,15.08-2.21,3.7-7.75,4.4-15.25,3.31"
          />
          <path
            id="metacarpalbone5"
            class="cls-7"
            d="M99.08,53.2c-2.6,2.12-5.99,2.31-9.65,1.87-6,1.88-4.78-4.57-6.48-10.95-1.69-5.23,1.42-11.65,5.16-18.23,3.88-7.68,4.75-15.94,3.6-24.61"
          />
          <path
            id="metacarpalbone4"
            class="cls-7"
            d="M109.35.73l.12,2.67c1.11,13.89,3.17,27.19,9.01,38.08,1.32,3.57,1.11,6.6-2.23,8.51"
          />
          <path
            id="metacarpalbone3"
            class="cls-7"
            d="M126.18,16.38c0-5.25-.07-10.47-.24-15.65"
          />
          <path
            id="metacarpalbone2"
            class="cls-7"
            d="M102.47,1.28c.09,8.96-1.79,17.29-4.41,25.37-2.84,3.03-3.27,7.78-3.47,13.06l-1.42,4.81c.07,5.67,1.23,9.3,4.89,8.3"
          />
          <path
            id="metacarpalbone1"
            class="cls-7"
            d="M121.97,50.96c5.1.51,7.26-5.25,8.12-13.75"
          />
          <path
            id="carpalbone3"
            class="cls-7"
            d="M128.73,56.25c.69-1.83.72-3.67.11-5.5-.76-2.26-3.16-3.59-5.45-2.9-1.33.4-2.72,1.25-4.15,2.39"
          />
          <path
            id="carpalbone2"
            class="unterarm_mit_handgelenk_lat_rechts2206_cls6"
            d="M64.57,93.01c1.53,5.09,3.17,9.32,5.02,12.07,1.59,2.37,4.46,3.53,7.25,2.92l7.8-1.7c3.61-1.13,5.23-4.01,4.94-8.57-.07-1.13-.15-2.26-.17-3.39-.12-5.8-1.39-10.73-4.77-14.07-3.89-3.95-8.41-3.66-13.25-1.22-5.84,2.94-8.22,7.55-6.82,13.95h0Z"
          />
          <path
            id="carpalbone1"
            class="unterarm_mit_handgelenk_lat_rechts2206_cls5"
            d="M124.3,65.45c.54-1.09.9-2.26,1.05-3.47.53-4.2-.08-7.58-3.37-9-4.24-1.75-9.49-.9-15.58,2.08-3.47,2.26-5.49,6.03-4.93,12.49.74,4.68,1.51,9.33,3.49,12.63.93,1.55,2.57,2.56,4.36,2.77,3.68.43,6.75-.92,8.86-5.05,0,0,6.12-12.45,6.12-12.45Z"
          />
        </g>
      </g>
      <g id="Highlight">
        <path
          id="carpushighlight"
          class="Handgelenk2206Fill"
          style={{
            fill: props.colors.Handgelenk2206Fill,
            stroke: props.colors.Handgelenk2206Fill,
          }}
          d="M77.34,125.46c4.49.78,7.03-11.52,14.97-12.34,2.25-.23,4.12.55,6.67,1.62,6.1,2.55,6.63,6.09,11.15,6.57,3,.32,4.58-1.05,6.61.31,1.23.82,1.03,1.57,2.83,3.64,1.64,1.89,3.02,3.47,4.88,3.5,2.13.03,4.38-1.97,4.77-4.14.35-1.96-1.01-3.61-3.74-6.84-2.67-3.15-4.23-5-6.9-6.29-3.8-1.83-4.97-.18-9.67-1.36-6.81-1.7-6.85-5.78-12.33-6.43-5.14-.6-9.55,2.46-11.14,3.56-3.62,2.51-2.49,3.61-8.09,8.49-1.94,1.69-3.24,2.58-3.44,4.25-.25,2.14,1.38,5.1,3.44,5.46Z"
        />
        <path
          id="radiushighlight"
          class="Radius2206Fill"
          style={{
            fill: props.colors.Radius2206Fill,
            stroke: props.colors.Radius2206Fill,
          }}
          d="M94.4,495.02c.02-3.6.06-6.55.09-8.55.07-4.65.14-6.61.23-11.98.08-5.18.05-5.52.09-7.99.12-6.3.47-10.18.96-17.03,0,0,.38-6.06,1.14-18.16.4-6.29.36-5.88,1.51-25.02.83-13.73,1.24-20.68,1.53-25.08.46-7.14.59-8.76,1.42-21.49.64-9.82.96-14.73,1.1-17.39.45-8.3.35-8.97,1.04-25.12.38-8.97.4-8.63.75-17,.41-10.01.39-10.78.69-17.48.36-8.03.44-8.01.92-17.91.54-11.1.45-11.37.87-18.6.39-6.83.65-11.28,1.29-17.4.44-4.23.97-8.39,1.38-11.63.51-3.99.97-7.35,1.31-9.81,4.82-29.8,6.71-41.39,7.48-46.04.31-1.88.79-4.75,1.48-9.03.99-6.1,1.49-9.15,1.49-9.16.11-1.07.37-2.11.52-3.17.03-.25.09-.63.27-1.11.34-.86.86-1.37,1.09-1.61.91-.95,1.31-2.09,1.72-3.22.68-1.91.43-3.64.3-4.55-.53-3.69-2.84-6.14-4.48-7.89-2.73-2.91-5.55-4.37-7.21-5.22-3.31-1.69-6.81-2.67-8.55-3.16-2.75-.77-3-.62-4.28-1.25-2.83-1.4-3.24-2.97-5.83-3.58-.57-.13-2.02-.46-3.65.1-1.4.48-2.24,1.37-2.88,2.07-1.5,1.62-1.46,2.63-2.91,4.84-.97,1.47-1.53,1.87-2.46,3-4.01,4.9-4.38,10.92-4.4,11.28-.03.6-.14,3.42.96,6.8.26.81,1.09,3.16,2.87,5.67.97,1.37,1.38,1.59,2.32,2.95,1.3,1.89,2.02,3.65,2.43,4.85,1.89,6.72,2.67,12.4,3.02,16.52.42,4.97.33,9.06.22,14.29-.11,4.99-.37,9.16-.61,12.2-.37,7.79-.86,18.06-1.48,30.13-.63,12.32-1.19,22.54-2.29,42.47-1.41,25.56-1.83,32.57-2.13,36.97-.05.76-.1,1.39-.16,2.26-1.01,13.89-2.12,23.92-2.9,31.01-2.13,19.53-1.66,16.73-3.71,35.33-.63,5.75-1.87,16.84-3.84,31.15,0,0,0,0,0,0-.01.08-1.6,11.81-4.43,29.52-.23,1.42-.59,3.7-1.17,6.68-1.39,7.23-2.61,11.45-3.37,14.63-2.31,9.68-2.27,17.95-2.22,21.91.06,5.05.55,11.93,2.43,20.01,10.02,0,20.04,0,30.07,0Z"
        />
        <path
          id="ulnarhighlight"
          class="Ulna2206Fill"
          style={{
            fill: props.colors.Ulna2206Fill,
            stroke: props.colors.Ulna2206Fill,
          }}
          d="M104.07,495.02c.88-2.32,1.39-4.27,1.69-5.65.78-3.61.62-5.38,1.92-8.53.32-.78.5-1.09,1.08-2.49.28-.69.96-2.39,1.71-4.68.66-2.01,1.05-3.52,1.43-4.96.47-1.78,1.03-4.1,1.58-6.84,1.91-9.17,2.66-16.78,2.97-22.31.3-5.34.28-10.52.27-14.63-.02-6.4-.21-11.13-.27-12.47-.23-5.41-.58-10.09-.92-13.83-.46-5.8-.92-10.6-1.26-14.01-.9-8.8-1.41-11.89-1.97-18.74-.36-4.4-.36-5.75-.76-13.55-.89-17.22-1.25-17.72-1.75-29.26-.28-6.49-.18-6.5-.61-21.51-.28-9.89-.56-17.9-.74-22.99-.57-12.6-.79-21.7-.88-27.84-.02-1.73-.05-4.28-.16-12.2-.15-11.33-.18-11.73-.18-14.09,0-6.64.16-6.69.32-17.26.2-12.73.01-16.38-.08-18.03-.3-5.25-.83-9.54-1.24-12.39-.46-3.88-1.52-11.26-3.79-18.83-1.59-5.3-3.12-8.48-4.32-10.67-.8-1.46-1.55-2.64-2.1-3.46-.69-1.11-1.92-3.48-1.83-6.57.08-2.79,1.2-4.88,1.83-6.04.6-1.12,1.23-1.98,1.71-2.57.79-.73,2.43-2.02,4.84-2.51,1.04-.21,1.97-.22,2.7-.16,1.75-.87,4.04-1.61,5.99-.72,1.57.72,2.1,2.12,2.93,1.9,1.32-.36.65-4.1,2.94-5.55.56-.36,1.14-.47,1.53-.51,1.77-.11,2.89.51,3.32.78,1.45.93,2.53,2.73,2.69,4.39.05.53.03,1.28.42,2.29.27.7.6,1.17.67,1.28,1.84,2.74,1.01,11.53,1.01,11.53-.22,2.34-.61,5.35-1.35,8.83,2.12,26.64,3.59,48.34,4.53,62.95.97,15.04,1.3,21.34,5.58,96.82,1.44,25.41,1.85,32.6,2.56,43.96,1.57,25.18,3.04,45.86,4.08,59.79,1.84,18.48,3.36,37.53,4.48,57.12.47,8.13.85,16.18,1.17,24.13-7.03.05-14.09.09-21.19.11-7.55.02-15.07.02-22.55,0Z"
        />
      </g>
    </svg>
  );
}

export default RechterUnterarmMitHandgelenkLatSitzendLinks2206;
