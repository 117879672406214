import { Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";

import useWindowSize from "../../Utilities/Window/windowSize";
import "./stylesheets/IndicationList.scss";
import { loadIndications } from "../../Utilities/Examinations/ExamsHarmonizer";

import { ExamPlannerIndicationImage } from "../ExamPlannerV3/Components/ExamPlannerImage";

import { LOG_DEBUG } from "../../Utilities/Debug/DebugLevels.js";
import DebugLogger from "../../Utilities/Debug/DebugLogger";
import { useIndications } from "../../Utilities/FetchHooks/Ris/RisHooks";

const capitalize = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

function ExamPlannerIndicationRow({
  indication,
  selectedIndications = [],
  selectIndication,
}) {
  const isSelected =
    selectedIndications.findIndex((i) => i.id === indication.id) > -1;

  return indication.designationde != "" ? (
    <div
      className={`p-2 border rounded d-flex align-items-center justify-content-left exmpl-row-btn ${
        isSelected ? "selected" : ""
      }`}
      onClick={() => {
        selectIndication(indication);
      }}
    >
      <div className="font-weight-bold">{indication.designationde}</div>
      <div className="ml-auto mr-2 p-1 patpos-img-box">
        <ExamPlannerIndicationImage indId={indication.id} />
      </div>
    </div>
  ) : (
    <></>
  );
}

function IndicationList({
  bodyPart = {},
  selectedIndications = [],
  selectIndication,
}) {
  const windowSize = useWindowSize();

  const pageSize = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [indicationsFilter, setIndicationsFilter] = useState("");

  const indicationsQ = useIndications({
    pageSize: pageSize,
    pageNr: currentPage,
    keepPreviousData: true,
    filter: {
      or: [
        {
          field: "designationde",
          op: "like",
          value: `%${indicationsFilter}%`,
        },
        {
          field: "designationde",
          op: "like",
          value: `%${indicationsFilter.toUpperCase()}%`,
        },
        {
          field: "designationde",
          op: "like",
          value: `%${indicationsFilter.toLowerCase()}%`,
        },
        {
          field: "designationde",
          op: "like",
          value: `%${capitalize(indicationsFilter)}%`,
        },
      ],
    },
    sort: { model: "xmreasons", field: "id", direction: "asc" },
  });

  const getListHeight = () => {
    if (windowSize.width < 1200) {
      return "64vh";
    } else {
      return "75vh";
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <Form style={{ flexGrow: 20 }} onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="my-auto" controlId="formIndications">
            <Form.Control
              type="search"
              placeholder="Search Indications"
              value={indicationsFilter}
              onChange={(e) => {
                setCurrentPage(1);
                setIndicationsFilter(e.target.value);
              }}
            />
          </Form.Group>
        </Form>
      </div>
      <div
        name="IndicationsList"
        className="scrollable-list d-flex flex-column"
        style={{ maxHeight: getListHeight() }}
      >
        {indicationsQ.status === "success" &&
          indicationsQ?.data?.indications &&
          indicationsQ.data.indications.length > 0 &&
          indicationsQ.data.indications?.map((indication, key) => (
            <ExamPlannerIndicationRow
              key={`indicationKey${key}`}
              indication={indication}
              selectedIndications={selectedIndications}
              selectIndication={selectIndication}
            ></ExamPlannerIndicationRow>
          ))}
        <div className="d-flex justify-content-between">
          <Button
            disabled={currentPage === 1}
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
          >
            <i className="mr-2 fas fa-chevron-left fa-xs" />
            Previous Page
          </Button>
          <Button
            disabled={pageSize > indicationsQ?.data?.indications?.length}
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
          >
            Next Page
            <i className="ml-2 fas fa-chevron-right fa-xs" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default IndicationList;
