import style from "./style.scss";

export default function RechtesSchultergelenkLatStehend2004(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 494.69 428.34"
    >
      <g id="Ebene_1" data-name="Ebene 1">
        <path
          id="background_2"
          data-name="background 2"
          class="Rechtes_Schultergelenk_lat_stehend2004_cls11"
          d="M427.47,402.79c-15.59,2.87-28.76,5.3-28.76,5.3-7.69-17.38-13.29-35.15-15.57-53.55-4.57-22.98-19.35-46.53-35.82-70.16-16.08-33.64-29.64-60.98-52-74.28,1.12-13.34,10.22-27.57,20.62-42.12,6.78-11.47,6.59-25.92-4.11-44.85-11.6-14.88-9.41-29.72-2.38-34.14l6.64-3.13c-20.92-14.36-42.33-24.02-64.3-28.32-20.97-2-33.42-7.22-35.83-16.23-2.11-7.65,1.64-10.95,8.76-11.73,34.7-4.88,55.5-3.96,57.96,4.6.48,8.08,5.72,13.89-1.42,13.3-9.93,1.38-12.48,3.22-4.66,5.71,54.59,8.09,104.99,16.45,138.78,25.93l14.93-3.66s1.16-.29,2.32-.57c-3.73-1.57-7.44-3.23-10.7-5.42-5.5-3.69-11.75-6.76-18.42-7.61-7.02-.9-13.9-.25-20.52-3.38-3.49-1.65-6.6-4.14-10.3-5.26-4.97-1.51-10.2-.39-15.29-.64-5.38-.27-10.73-2.29-15.99-3.37-5.17-1.06-10.45-1.63-15.73-1.88-3.04-.14-6.09-.17-9.13-.21-3.77-.04-7.55-.09-11.32-.13-8.11-.09-18.8.64-18.8-10.86,0-.81-2.05-4.72,1.13-7.52,3.52-3.09,17.26-7.26,25.78-5.85,7.18,1.19,18.31-1.07,25.44.38,18.29.22,17.79-1.35,36.88,2.32,14.37,2.76,24.98,5.48,33.05,7.55,9.29,2.39,52.03,15.01,64.95,19.06l4.15,341.46s-27.72,5.11-50.34,9.28v-.02Z"
        />
        <path
          id="background_1"
          data-name="background 1"
          class="Rechtes_Schultergelenk_lat_stehend2004_cls11"
          d="M10.02,136.08c5.81-.14,14.83-.44,25.89-1.26,9.11-.67,11.49-1.09,31.35-3.06,23.19-2.3,22.79-2.01,30.25-3.03,7.11-.97,6.7-1.13,30.19-5.24,22.66-3.97,23.26-3.86,27.44-5.17,11.51-3.6,10.26-5.42,27.79-12.34,14.34-5.66,15.98-4.75,24.56-9.5,9.47-5.24,14.21-7.85,17.36-10.4,3.36-2.72,5.65-5.1,9.77-5.79,5.01-.84,9.31,1.46,11.1,2.45,8.37,4.6,6.78,10.6,13.32,13.42,6.05,2.61,8.77-1.95,16.85.64,4.05,1.3,6.56,3.49,11.59,7.86,4.45,3.87,8.42,7.33,11.59,13.02,2.2,3.96,3.31,9.21,5.5,19.71,1.45,6.92,2.17,10.43,2.02,13.19-.29,5.29-1.89,8.18-5.59,17.25-5.03,12.32-4.14,12.04-6.17,16.16-8.92,18.12-27.31,25.95-32.87,28.48-3.05,1.39-6.76,2.58-10.62,3.29-6.83,1.25-13.85-.62-19.2-5.04-.81-.67-1.75-1.44-2.84-2.32-4.33-3.5-5.11-4.01-7.9-6.4-5.59-4.78-5.02-4.92-6.78-5.88-4.64-2.52-6.96-.68-13.31-2.39-5.54-1.5-5.46-3.37-10.62-5.23-5.59-2.01-8.84-.94-17.66-.62-9.46.35-8.4-.79-22.09-1.05-8.17-.16-13.93.14-23.7.65-19.23.99-18.16,1.88-27.53,1.86-13.3-.03-13.68-1.8-28.18-1.99-8.87-.12-11.07.51-25.89,1.26-6.9.35-17.13.76-29.76.82"
        />
        <path
          id="Collarbone"
          class="Rechtes_Schultergelenk_lat_stehend2004_cls1"
          d="M473.86,52.07c-12.92-4.05-55.66-16.67-64.95-19.06-8.07-2.07-18.68-4.79-33.05-7.55-19.09-3.67-18.59-2.09-36.88-2.32-7.13-1.44-18.26.81-25.44-.38-8.52-1.41-22.26,2.76-25.78,5.85-3.18,2.8-1.13,6.71-1.13,7.52,0,11.5,10.69,10.76,18.8,10.86,3.77.04,7.55.09,11.32.13,3.04.03,6.09.07,9.13.21,5.28.25,10.55.82,15.73,1.88,5.26,1.08,10.61,3.11,15.99,3.37,5.09.24,10.31-.87,15.29.64,3.7,1.12,6.81,3.61,10.3,5.26,6.62,3.13,13.5,2.49,20.52,3.38,6.66.85,12.92,3.91,18.42,7.61,5.2,3.49,11.54,5.63,17.29,8.23"
        />
        <g id="shoulder_plate" data-name="shoulder plate">
          <g id="shoulder_plate_1" data-name="shoulder plate 1">
            <path
              class="Rechtes_Schultergelenk_lat_stehend2004_cls8"
              d="M398.85,407.73h0l-1.86.74c-7.14-17.63-11.88-36.18-15.03-54.9-3.62-18.54-13.61-35.13-23.78-50.8-8.44-11.43-15.36-24.07-21.6-36.83-4.41-8.36-8.99-16.66-13.81-24.78-4.78-8.05-9.95-16.01-17.26-21.96-3.71-2.86-7.51-5.96-10.87-9.28l-.37-.35.07-.52c1.25-8.56,4.16-17.01,9.01-24.24,3.02-4.14,6.49-7.81,8.86-12.36,3.31-5.81,6.22-11.93,7.04-18.58.4-3.25.37-6.6-.3-9.77-1.6-6.32-4.89-12.61-7.86-18.45-2.32-4.51-5.94-8.6-7.5-13.61-2.49-8.37-2.57-19.32,5.84-24.42,3.01-2,6.63-3.29,10.24-3.15,5.07-.13,15.86,1.91,18.06-3.8l1.05.28c-.85,3.9-5.42,5.05-8.81,5.44-3.49.4-6.97.24-10.31.22-1.64,0-3.15.21-4.66.76-4.42,1.62-8.61,4.78-9.86,9.51-1.5,6.13-.45,13.35,2.71,18.88,3.66,5.61,7.25,11.79,9.89,18.02,1.41,3.11,2.85,6.32,3.6,9.84.73,3.52.74,7.08.3,10.59-1.17,8.71-5.44,16.62-10.15,23.86-1.92,2.84-4.35,5.31-6.3,8.07-4.72,6.9-7.58,15-8.88,23.25l-.3-.87c1.7,1.63,3.43,3.23,5.21,4.75,3.61,2.98,7.64,5.84,10.79,9.38,4.97,5.19,8.77,11.41,12.46,17.51,7.26,12.22,13.98,24.82,20.29,37.55,4.37,8.37,9.58,16.22,15.04,23.95,5.34,7.91,10.04,16.14,14.49,24.59,4.31,8.5,7.78,17.52,9.6,26.94.92,4.69,1.67,9.34,2.65,13.96,2.88,13.86,7.06,27.43,12.28,40.59h.03Z"
            />
          </g>
          <path
            id="shoulder_plate_2"
            data-name="shoulder plate 2"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls1"
            d="M295.33,209.22c3.02.04,10.59-4.75,15.74-7.54,1.53-.84,8.21-4.67,13.21-16.36,5.18-12.12,4.38-22.9,3.15-37.13-1.71-19.66-6.67-34.31-9.44-41.54-1.86-4.86-5.64-12.11-8.54-17.65"
          />
          <line
            id="shoulder_plate_3"
            data-name="shoulder plate 3"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls1"
            x1="405.46"
            y1="83.36"
            x2="433.15"
            y2="89.91"
          />
          <path
            id="shoulder_plate_4"
            data-name="shoulder plate 4"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls1"
            d="M442.49,72.46c-1.05.35-2.15.66-3.31.95-.63.15-2.55.61-4.75,1.11-13.25,3.04-13.29,2.44-17,3.78-7.42,2.69-7.47,5.19-11.96,5.04-3.56-.12-7.76-1.83-9.44-2.51-2.18-.88-3.28-1.34-4.66-2.22-1.35-.86-2.4-1.75-4.3-2.61-.75-.33-1.52-.62-2.37-.83-.12-.03-.97-.25-1.95-.36-2.18-.25-4.3.12-5.13.25-2.66.4-4.78.25-6.77.11-1.15-.08-2.68-.28-4.35-.35-.98-.04-1.95-.28-2.83-.72-3.82-1.9-6.14-2.36-10.74-3.61-7.05-1.9-14.96-3.59-22-.82-2.8,1.1-6.33,3.04-7.58,6.08-1.18,2.86-.71,6.39.46,9.17,1.59,3.79,4.72,7.62,8.64,7.55,17.62-.32,25.11,4.63,27.72,13.15"
          />
        </g>
        <g id="acromion">
          <g id="acromion_1" data-name="acromion 1">
            <path
              class="Rechtes_Schultergelenk_lat_stehend2004_cls8"
              d="M360.47,130.18h0l-.48.47-23.65-23.88c-7.76-8.07-15.84-15.76-24.97-22.25-23.6-17.49-48.86-24.75-77.45-29.12-5.66-1.16-11.29-3.38-15.36-7.69-3.85-4.29-6.24-12.03-1.39-16.48,2.41-2.02,5.5-2.17,8.37-2.66,8.03-1.24,17.06-1.66,25.2-2.11,5.63-.38,11.24-.18,16.81-.47,2.82-.11,5.9.05,8.64,1.43,4.41,2.43,7.71,4.28,8.24,9.76.34,2.02.86,4.01,1.53,5.95.77,1.6.73,3.67-.74,4.88-.72.63-1.71.93-2.64.86h.21c-1.81.26-6.54.93-8.29,1.18-.29.05-.92.1-1.17.19-.38.12-.51.66-.32.97.1.16.19.26.48.32l2.71.56c23,4.36,48.51,7.37,71.57,11.52,29.28,5.17,58.78,10.77,86.56,21.65l-.17.44c-27.95-10.22-57.43-15.37-86.68-20.35-21.17-3.51-45-6.68-66.16-10.33-2.47-.4-5.81-1.12-8.26-1.62-2.74-.52-2.69-4.63.1-5.06,2.35-.35,6.94-.98,9.34-1.33.05,0,.16,0,.21,0,.93.1,1.79-.67,1.86-1.6.06-.58-.23-1.04-.41-1.64-.7-2.04-1.23-4.13-1.59-6.25-.27-3.72-2.31-5.75-5.47-7.41-2.77-1.98-6.04-2.48-9.52-2.3-11.05.23-22.52.44-33.52,1.46-2.77.25-5.54.59-8.29,1.02-2.73.48-5.63.71-7.45,2.32-3.92,3.77-1.54,10.29,1.66,13.8,3.71,3.87,9.11,5.88,14.32,6.92,28.53,4.27,54.68,12.37,77.94,29.98,16.11,11.52,34.1,32.72,48.2,46.9l.03-.03Z"
            />
          </g>
          <path
            id="acromion_2"
            data-name="acromion 2"
            class="rechtes_schultergelenk_lat_stehend2004_cls7"
            d="M283.71,47.81c-8.7,2.95-40.04.59-40.72-4.49"
          />
        </g>
        <g id="ribs">
          <path
            id="rib_1"
            data-name="rib 1"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls6"
            d="M394.85,336.95c-53.35-62.24,4.67-141.81,84.91-157.9"
          />
          <path
            id="rib_2"
            data-name="rib 2"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls6"
            d="M381.35,362.41c-96.7-101.29,24.75-196.75,98.41-213.3"
          />
          <path
            id="rib_3"
            data-name="rib 3"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls6"
            d="M391.73,397.06c-55.31-62.24,4.84-141.81,88.03-157.9"
          />
          <path
            id="rib_4"
            data-name="rib 4"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls6"
            d="M377.73,422.52c-100.25-101.29,25.67-196.75,102.03-213.3"
          />
          <path
            id="rib_5"
            data-name="rib 5"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls6"
            d="M375.24,428.31c-15.61-54.33,40.69-110.86,113.67-124.14"
          />
          <path
            id="rib_6"
            data-name="rib 6"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls6"
            d="M342.65,426.27c-15.81-76.04,81.83-138.91,146.27-152.05"
          />
          <path
            id="rib_7"
            data-name="rib 7"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls6"
            d="M398.71,427.81c18.52-29.68,54.36-53.59,95.95-61.64"
          />
          <path
            id="rib_8"
            data-name="rib 8"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls6"
            d="M366.89,427.81c24.69-47.13,84.27-82.17,127.77-91.59"
          />
          <path
            id="rib_9"
            data-name="rib 9"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls6"
            d="M398.47,268.35c-48.53-58.35,4.26-132.94,77.26-148.03"
          />
          <path
            id="rib_10"
            data-name="rib 10"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls6"
            d="M386.19,292.22c-87.98-94.96,22.52-184.46,89.54-199.97"
          />
        </g>
        <g id="outlines">
          <g id="outlines_1" data-name="outlines 1">
            <g>
              <line
                class="Rechtes_Schultergelenk_lat_stehend2004_cls4"
                x1="350.25"
                y1="1.45"
                x2="356.24"
                y2="1"
              />
              <path
                class="Rechtes_Schultergelenk_lat_stehend2004_cls5"
                d="M12.12,81.03c53.08-10.87,101.01-28.32,140.53-51.62,6.72-3.96,13.85-7.19,21.29-9.53,13.87-4.37,28.2-7.14,42.72-8.25l121.3-9.24"
              />
              <path
                class="Rechtes_Schultergelenk_lat_stehend2004_cls4"
                d="M.18,83.36c1.98-.36,3.94-.73,5.9-1.11"
              />
            </g>
          </g>
          <g id="outlines_2" data-name="outlines 2">
            <g>
              <path
                class="Rechtes_Schultergelenk_lat_stehend2004_cls4"
                d="M23.5,213.13c-2-.03-4-.06-6-.08"
              />
              <path
                class="Rechtes_Schultergelenk_lat_stehend2004_cls2"
                d="M199.72,227.91c-50.79-8.75-105.85-13.21-164.01-14.55"
              />
              <path
                class="Rechtes_Schultergelenk_lat_stehend2004_cls4"
                d="M211.63,230.06c-1.96-.37-3.93-.73-5.9-1.09"
              />
            </g>
          </g>
        </g>
        <g id="upper_arm" data-name="upper arm">
          <path
            id="upper_arm_1"
            data-name="upper arm 1"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls1"
            d="M10.02,136.08c5.81-.14,14.83-.44,25.89-1.26,9.11-.67,11.49-1.09,31.35-3.06,23.19-2.3,22.79-2.01,30.25-3.03,7.11-.97,6.7-1.13,30.19-5.24,22.66-3.97,23.26-3.86,27.44-5.17,11.51-3.6,10.26-5.42,27.79-12.34,14.34-5.66,15.98-4.75,24.56-9.5,9.47-5.24,14.21-7.85,17.36-10.4,3.36-2.72,5.65-5.1,9.77-5.79,5.01-.84,9.31,1.46,11.1,2.45,8.37,4.6,6.78,10.6,13.32,13.42,6.05,2.61,8.77-1.95,16.85.64,4.05,1.3,6.56,3.49,11.59,7.86,4.45,3.87,8.42,7.33,11.59,13.02,2.2,3.96,3.31,9.21,5.5,19.71,1.45,6.92,2.17,10.43,2.02,13.19-.29,5.29-1.89,8.18-5.59,17.25-5.03,12.32-4.14,12.04-6.17,16.16-8.92,18.12-27.31,25.95-32.87,28.48-3.05,1.39-6.76,2.58-10.62,3.29-6.83,1.25-13.85-.62-19.2-5.04-.81-.67-1.75-1.44-2.84-2.32-4.33-3.5-5.11-4.01-7.9-6.4-5.59-4.78-5.02-4.92-6.78-5.88-4.64-2.52-6.96-.68-13.31-2.39-5.54-1.5-5.46-3.37-10.62-5.23-5.59-2.01-8.84-.94-17.66-.62-9.46.35-8.4-.79-22.09-1.05-8.17-.16-13.93.14-23.7.65-19.23.99-18.16,1.88-27.53,1.86-13.3-.03-13.68-1.8-28.18-1.99-8.87-.12-11.07.51-25.89,1.26-6.9.35-17.13.76-29.76.82"
          />
          <path
            id="upper_arm_2"
            data-name="upper arm 2"
            class="Rechtes_Schultergelenk_lat_stehend2004_cls3"
            d="M242.94,121.96c-10.32-10.27-22.26-7.8-24.34-2.64"
          />
          <path
            id="upper_arm_3"
            data-name="upper arm 3"
            class="rechtes_schultergelenk_lat_stehend2004_cls7"
            d="M222.52,194.45c8.68-9.62,22.19-46.68,35.46-94.43"
          />
        </g>
      </g>
      <g id="Ebene_2" data-name="Ebene 2">
        <path
          id="joint_socket_overlay"
          data-name="joint socket overlay"
          class="jointsocket2004Fill"
          style={{
            fill: props.colors.jointsocket2004Fill,
            stroke: props.colors.jointsocket2004Fill,
          }}
          d="M309.92,89.91c7.7,14.73,12.99,30.19,16.24,46.28,2.62,18.21,2.54,35.4-2.42,50.74-6.42,12.75-16.33,18.72-27.51,22.19-.75-7.39,1.78-15.42,8.66-24.32,9.21-11.32,14.51-23.2,15.59-35.69-.17-7.95-5.33-20.09-13.04-34.36-1.73-3.21-2.75-6.77-2.95-10.41-.45-8.29,1.57-12.78,5.43-14.43h0Z"
        />
        <path
          id="joint_overlay"
          data-name="joint overlay"
          class="jointgap2004Fill"
          style={{
            fill: props.colors.jointgap2004Fill,
            stroke: props.colors.jointgap2004Fill,
          }}
          d="M301.81,140.46c-.71-12.82-4.83-20.37-8.45-24.88-5-6.23-11.12-9.25-12.21-16.9-.15-1.09-.94-6.61,2.35-9.86,4.17-4.13,11.03-4.03,15.96.47,5.38,4.91,5.63,13.62,9.39,23.47,3.26,8.55,3.72,8.45,6.57,15.49,2.76,6.83,5.81,14.05,3.75,25.5-3.71,20.59-15.44,58.17-28.63,57.38-3.16-.19-6.08-2.55-7.29-5.09-3.1-6.5,5.06-14,11.5-28.49,2.63-5.91,5.15-13.37,6.57-22.54.16-4.85.31-9.7.47-14.55Z"
        />
      </g>
    </svg>
  );
}
