import style from "./style.scss";

export default function LinkesSchlusselbeinTangStehend2017(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 411.58 290.07">
      <g id="collarbon_recording" data-name="collarbon recording">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls1"
            d="M13.31,284.42c-3.82-4.23-1.02-5.55-5-29-2.89-17.04-6.35-28.01-7.05-47.07-.22-6.06-.15-12.23,3.54-18.09.93-1.48,3.11-4.55,13.51-10.84,15.78-9.55,21.6-9.03,24-15,2.3-5.72-2.32-7.98-.54-15.91,1.83-8.13,8.65-14.47,15.15-17.29,6.73-2.92,12.93-1.64,25.05,1.04,15.96,3.53,29.36,12.91,43.34,15.15,3.37.54,6.38,4.29,11.65,4.78,6.75.62,8.75-2.73,17.35-3.78,4.69-.57,12.87-1.56,18,3,5.66,5.03,6.76,16.06,6.76,16.06,3.3,6.02,5.65,7.18,7.23,7.02,2.71-.27,3.19-4.44,7.67-8.17,2.6-2.17,4.93-2.83,9.87-4.3,6.17-1.83,12.3-3.82,18.48-5.62,18.13-5.29,20.86-4.82,32-9,10.58-3.98,15.96-6.06,19.99-11.02,3.13-3.84,0,0,8.91-13.39,4.1-6.17,6.91-7.07,6.41-8.69-.98-3.19-11.11-2.51-26.31-1.89-32.74,1.32-28.74-.08-34,1-22.28,4.59-24.36,7.86-43.42,16.6-9.44,4.32-11.84-2.27-24.58-1.6-10.23.54-13.03,3.65-29.94,7.21-18.31,3.86-21.94,2.29-24.06.79-6.12-4.36-8.56-14.68-5-19,2.92-3.54,8.24-1.08,21-2,7.36-.53,12.36-1.84,20.91-4.08,16.02-4.2,19.51-8.29,31.29-12.26,7.07-2.38,10.05-2.34,38.58-4.24,47.74-3.19,42.07-3.5,48.22-3.41,20.29.28,35.07,2.94,50.02-2.34,8.13-2.87,15.18-7.17,15.18-7.17,1.26-.77,10.03-2,13.81-10.49,4-9,9.31-11.69,14-13,7.29-2.04,10.33,4.27,19,3,9.41-1.37,16.95-10.43,15.69-13.02-.72-1.48-4.71-.98-12.69.02-12.3,1.55-15.28,3.63-21,2-3.33-.95-8.74-3.48-9-7-.31-4.2,6.8-8.4,12-10,5.05-1.55,6.67-.14,11-2,5.35-2.3,13.95-9.36,14.74-10.6,0,0-4.91-19.04-1.74-20.4,3.25-1.39,13.14,8.87,16,22,1.43,6.54.66,11.71.34,14.22-3.74,29.68,11.34,38.46,7.66,69.78-1.14,9.68-3.24,14.44-2.78,25.98.39,9.71,2.24,15.32,3.78,23.02,2.22,11.09,1.44,14.71,2,41,.92,43.05,3.39,51.37-3,56,0,0-9.64.12-25.03-.96-12.77-.89-26.83,2.39-54.97,8.96-11.2,2.61-22.84,5.79-46.12,12.16-22.74,6.22-26.76,7.52-31.88,8.84-40.42,10.48-57.38-1.27-123,1-32.45,1.12-51,3-51,3-36.54,3.69-53.19,8.75-62-1h-.02Z"
          />
        </g>
        <path
          id="upper_arm"
          data-name="upper arm"
          class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
          d="M13.31,284.42c-1.97-12.41-3.77-22.4-5-29-1.66-8.89-4.05-21.14-6-37-2.11-17.17-1.61-21.66,1-26,3.73-6.2,10.83-10.48,15-13,4.99-3.01,6.83-3.1,13-7,7.31-4.62,7.37-6.17,11-8,7.2-3.64,14.84-1.54,27,2,13.66,3.97,20.49,5.96,27,12,11.27,10.46,13.28,24.11,14,29,.92,6.28,2.98,20.27-5,30-4.27,5.2-6.62,3.36-13,10-6.27,6.52-9.05,13.56-12,21-1.62,4.1-3.58,9.85-5,17"
        />
        <g id="shoulder_plate" data-name="shoulder plate">
          <path
            id="shoulder_plate_1"
            data-name="shoulder plate 1"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M126.31,282.42c-2.74-4.19-6.87-10.46-12-18-8.73-12.84-13.8-19.73-24-34-35.45-49.58-27.52-40.77-32-46-8.9-10.39-16.44-17.41-17-29-.11-2.21-.38-10.31,5-17,7.68-9.55,21.14-9.12,25-9,6.07.19,6.18,1.59,27,9,22.19,7.9,18.96,5.21,27,9,6.27,2.95,10.05,5.44,16,5,5.59-.42,6.44-2.93,13-4,2.47-.4,12.19-1.99,18,3,6.92,5.94,5.54,21.22,11,24,3.62,1.84,4.53-6.66,14-11,3.32-1.52,10.55-3.68,25-8,25.69-7.67,23.83-5.32,32-9,14.56-6.55,19.79-13.69,25-11,4.01,2.07,5.08,8.46,6,14,.54,3.24.52,5.65,0,15s-.78,14.03-1,16c-1.24,11.11-3.04,12.5-6,27-2.53,12.4-3.8,18.6-3,24,1.36,9.19,4.89,10.71,6,22,.37,3.74.25,7.49,0,15-.23,7.11-.66,12.97-1,17"
          />
          <path
            id="shoulder_plate_2"
            data-name="shoulder plate 2"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M180.86,172.68c-8.79,3.9-12.68,8.75-14.55,12.74-1.74,3.69-1.48,6.07-3,11-2.14,6.93-5.75,12.22-9,16"
          />
          <path
            id="shoulder_plate_3"
            data-name="shoulder plate 3"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M146.22,151.22c-1.49,7.19-4.48,10.5-6.91,12.2-2.22,1.55-3.37,1.32-12,4-5.69,1.76-8.59,2.67-11,4-2.64,1.45-6.37,4.06-10,9"
          />
          <path
            id="shoulder_plate_4"
            data-name="shoulder plate 4"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M106.31,186.42c27.01,16.59,10.51,52.53-4.37,60.24"
          />
          <path
            id="shoulder_plate_5"
            data-name="shoulder plate 5"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M81.97,132.27c1.52,1.37,6.6,6.15,5.34,9.15-.59,1.4-4.07,1.95-11,3-13.35,2.02-14.09,2.12-16,2-6.38-.39-7.76-2.1-11-1-4.33,1.47-6.68,6.17-8,10"
          />
        </g>
        <path
          id="collarbone"
          class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
          d="M102.31,117.42c2.89-3.69,7.97-1.05,21-2,13.54-.99,24.16-5.02,32-8,9.1-3.46,8.69-4.44,16-7,13.09-4.59,24.03-4.82,30-5,20.37-.6,40.65-3.01,61-4,48.14-2.35,54.35-2.93,65-2,9.73.85,13.75,2.13,22,1,8.04-1.1,12.36-1.07,21-1,1.48,0,3.72.06,5.86,1.44,2.12,1.38,3.85,3.88,5.14,11.56,1.54,9.12.03,11.07,0,27,0,4.78.12,6.23-1,7-3.37,2.31-10.59-6.67-24-12-2.1-.83-4.61-1.68-19-4-19.5-3.14-34.54-4.74-37-5-14.46-1.52-23.82-2.5-37-3-16.47-.62-24.89-.89-34,1-12.07,2.51-23.53,7.48-27,9-12.47,5.47-16.37,9.15-25,9-7.13-.12-7.9-2.69-16-3-7.71-.3-10.92,1.87-22,5-17.28,4.88-25.92,7.31-32,3-6.08-4.31-8.49-14.55-5-19Z"
        />
        <g id="ribs">
          <path
            id="ribs_1"
            data-name="ribs 1"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M407.31,252.42c-4.05-.41-9.92-.89-17-1-25.54-.4-44.49,4.27-63,9-30.29,7.74-56.13,14.79-78,21"
          />
          <path
            id="ribs_2"
            data-name="ribs 2"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M405.31,225.42c-13.87,1.02-25.4,2.6-34,4-10.79,1.76-13.07,2.61-27,5-20.05,3.44-22.91,2.99-33,5-13.87,2.76-23.31,6.57-39,13-14.67,6.01-34.02,14.93-56,28"
          />
          <path
            id="ribs_3"
            data-name="ribs 3"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M405.31,206.42c-22.11.88-40.15,3.57-53,6-14.31,2.71-10.62,2.92-54,14-30.17,7.7-36.76,10.45-49,15-14.44,5.36-13.11,3.63-28,17-2.3,2.06-7.99,3.01-11,8-2.72,4.51-2.53,9.59-2,13"
          />
          <path
            id="ribs_4"
            data-name="ribs 4"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M404.31,177.42c-3.27,1.38-8.43,3.37-15,5-5.9,1.47-8.8,1.63-15,3-7.8,1.73-13.14,3.68-17,5-13.18,4.52-19.82,5.42-43,12-18.49,5.25-33.57,9.53-53,17-4.39,1.69-18.12,7.05-35,16-16.72,8.87-21.7,13.4-25,17-8.42,9.21-12.19,19.31-14,26"
          />
          <path
            id="ribs_5"
            data-name="ribs 5"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M401.31,142.42c-2.56-.88-6.51-1.83-11-1-7.81,1.45-9.34,6.83-17,9-6.41,1.81-8.39-1.09-17-1-8.18.09-13.78,2.79-21,6-23.19,10.3-17.63,5.59-40,16-26.83,12.48-21.79,13.19-44,23-11.73,5.18-22.6,12.12-34,18-3.66,1.89-10.55,5.43-14,13-.95,2.1-2.89,6.01-2,11,1.04,5.84,5.17,9.27,6.43,10.24"
          />
          <path
            id="ribs_6"
            data-name="ribs 6"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M403.31,165.42c-3.08.85-7.64,1.69-13,1-5.83-.75-8.04-2.74-13-3-6.17-.32-10.8,2.32-14,4-10.37,5.43-22.08,7.79-33,12-25.06,9.66-23.19,7.75-38,14-8.32,3.51-3.93,2.01-30,14-10.43,4.8-19.95,11.89-31,15-4.97,1.4-8.38,5.5-14,8-3.49,1.55-.91,7.27,0,10"
          />
          <path
            id="ribs_7"
            data-name="ribs 7"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M257.8,191.45c-6.81-.5-11.7-2.57-14.49-4.03-4.14-2.16-7.89-4.11-9-8-1.04-3.66.81-7.09,3-11,3.8-6.8,8.64-10.87,10-12,11.64-9.62,25.97-17.02,32-20"
          />
          <path
            id="ribs_8"
            data-name="ribs 8"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M284.21,144.69c-2.54,2.13-6.58,5.33-11.9,8.73-8.89,5.68-14.15,7.31-14,10,.17,2.97,6.83,4.84,11,6,5.82,1.62,74.61,38.2,86,37"
          />
          <path
            id="ribs_9"
            data-name="ribs 9"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M383.31.42c-1.09,2.35-2.28,5.85-2,10,.36,5.35,2.93,8.28,5,13,2.6,5.92,2.37,9.97,3,18,1.23,15.63,4.36,29,6,36,4,17.05,9.64,30.86,6,33-.44.26-1.91.27-4.87.28,0,0-1.69,0-7.13-.28h0c-6.52,4.75-7.43,6.91-7,8,.72,1.84,5.17.5,12,3,3.03,1.11,7.02,2.57,9,6,3.46,6-1.46,14.13-2,15"
          />
          <path
            id="ribs_10"
            data-name="ribs 10"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M362.31,66.42c-11.09.24-19.07,4.04-21,5-5.5,2.74-5.46,4.08-17,13-10.84,8.37-11.66,7.79-19,14-4.4,3.72-10,8.51-15.69,15.89-7.04,9.13-11.8,15.31-10.31,22.11,1.4,6.37,7.55,9.89,13,13,11.37,6.49,22.33,5.56,29,5,7.12-.6,13.29-1.13,15-5,2.02-4.58-3.22-11.51-7-15-6.51-6.01-11.29-4.21-14-9-3.14-5.55-.2-14.08,4-19,2.03-2.37,3.34-2.72,16-9,11.14-5.52,9.82-5.1,14-7,11.36-5.16,17.14-7.72,22-7,6.91,1.03,9.08,5.51,13,4,3.84-1.49,6.45-7.62,5-12-3.14-9.51-24.8-9.05-27-9Z"
          />
          <path
            id="ribs_11"
            data-name="ribs 11"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M401.31,110.42c-2.04-3.19-5.28-7-10-8-4.67-.99-8.02,1.35-15,4-7.34,2.79-6.82,1.41-20,5-8.63,2.35-16.18,4.41-24.41,8.15-9.78,4.45-11.67,7.25-22.59,13.85-6.32,3.82-15.73,8.96-28.18,13.76"
          />
          <path
            id="ribs_12"
            data-name="ribs 12"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M399.31,22.42c-10.56-3-16.5-1.27-20,1-3.99,2.58-3.93,5.29-9,8-5.3,2.83-7.59,1.07-14,4-2.45,1.12-9.13,4.17-9,8,.14,4.22,8.43,6.83,9,7,3.83,1.17,7.09.92,18-1,12.03-2.12,15.03-3.06,15.56-2.07,1.29,2.4-5.95,12.7-15.56,14.07-7.51,1.06-10.28-4.23-19-3-5.51.78-12.29,4-12.5,7.93-.25,4.73,9.12,8.51,10.5,9.07,7.44,3,12.53,1.64,20,2,5.65.28,13.79,1.6,23.64,6.56"
          />
          <path
            id="ribs_13"
            data-name="ribs 13"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M405.31,151.22c-24.04,3.08-2.89,7.04-4,7.04"
          />
          <path
            id="ribs_14"
            data-name="ribs 14"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M405.31,186.42c-23.31,5.03,0,15.49,0,15.49"
          />
          <path
            id="ribs_15"
            data-name="ribs 15"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M407.31,232.42c-25.31,8,0,10.5,0,10.5"
          />
          <path
            id="ribs_16"
            data-name="ribs 16"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M251.08,190.37c9.81,2.85,81.91,30.75,91.72,33.6"
          />
          <path
            id="ribs_17"
            data-name="ribs 17"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M207.74,246.66c4.52,3.92,24.05,28.84,28.57,32.76"
          />
          <path
            id="ribs_18"
            data-name="ribs 18"
            class="Linkes_Schlüsselbein_tang_stehend2017_cls3"
            d="M216,236.51c4.4,4.99,30.68,36.92,35.08,41.91"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="collarbone_overlay"
          data-name="collarbone overlay"
          class="clavicula2017Fill"
          style={{
            fill: props.colors.clavicula2017Fill,
            stroke: props.colors.clavicula2017Fill,
          }}
          d="M102.31,117.42c2.89-3.69,7.97-1.05,21-2,13.54-.99,24.16-5.02,32-8,9.1-3.46,8.69-4.44,16-7,13.09-4.59,24.03-4.82,30-5,20.37-.6,40.65-3.01,61-4,48.14-2.35,54.35-2.93,65-2,9.73.85,13.75,2.13,22,1,8.04-1.1,12.36-1.07,21-1,1.48,0,3.72.06,5.86,1.44,2.12,1.38,3.85,3.88,5.14,11.56,1.54,9.12.03,11.07,0,27,0,4.78.12,6.23-1,7-3.37,2.31-10.59-6.67-24-12-2.1-.83-4.61-1.68-19-4-19.5-3.14-34.54-4.74-37-5-14.46-1.52-23.82-2.5-37-3-16.47-.62-24.89-.89-34,1-12.07,2.51-23.53,7.48-27,9-12.47,5.47-16.37,9.15-25,9-7.13-.12-7.9-2.69-16-3-7.71-.3-10.92,1.87-22,5-17.28,4.88-25.92,7.31-32,3-6.08-4.31-8.49-14.55-5-19Z"
        />
      </g>
    </svg>
  );
}
