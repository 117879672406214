import style from "./style.scss";

function LinkeMittelhandDpOblSitzend1333(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 395.3 505.23">
      <g id="background_layer" data-name="background layer">
        <path
          id="background"
          class="mittelhand_links_dp_obl1333_cls2"
          d="M224.23,501.31c-3.3,4.3,2.3-105.4,28.9-129.1,80.28-75.93,86.88-154.99,131.84-213.24,5.81-7.53,9.22-16.66,9.81-26.15,1.17-18.83.39-37.22-2.64-55.1-.14-8.26-5.8-15.41-13.81-17.44l-21.61-5.47c-4.1,1.2-6.1,3.2-7.1,6.2-6,16-7,34-8,51-11,18-48.7,33-51,34s-4.8-6.1-6-10c-3-12.6-4.1-27.5-4.1-27.5,0,0-2.9-48.5,3.3-69.6v-.05c.48-1.98-.41-4.03-2.18-5.03l-32.69-18.48c-12.19-6.89-27.28-5.99-38.56,2.3l-13.48,9.91c-2.41,1.77-5.8.05-5.8-2.94h0c0-9.31-6.62-17.3-15.76-19.04L147.84.36c-7.33-1.39-14.84,1.29-19.63,7l-9.03,10.77c-2.77,3.3-8.14,1.54-8.42-2.76h0c-.36-5.67-4.94-10.16-10.62-10.41l-29.88-1.28c-7.4-.32-13.74,5.23-14.41,12.61l-1.7,18.64c-.34,3.7-5.03,5.06-7.29,2.11l-2.49-3.24c-3.61-4.71-9.85-6.55-15.44-4.57l-19.47,6.9C2.82,38.48-1.1,45.35.27,52.27l7.94,40.16,14.42,81.68c5.99,20.37,9.09,66.68,9.7,118.3.25,23.57,6.93,51.11-.6,72-17.3,29.6-13.4,113.6-13.2,133,0,0,0,3-.5,3.4"
        />
      </g>
      <g id="hand_overview" data-name="hand overview">
        <g id="full_hand" data-name="full hand">
          <g id="hand">
            <g id="_5._finger" data-name=" 5. finger">
              <path
                id="_5._finger_2"
                data-name=" 5. finger 2"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M31.91,40.91c1.2,1.9,2.5,3.5,3.6,4.5-.7,5.1,14.2,15.6,18.9,22.7,5.8,4,4.9,13.9-1.2,20.8-1.7-3-26.7,2.8-31.4,10.6-9.5-9.3-14.1-20.7-9.4-36.1-1.4-5.6-2.8-10.9-4.2-15.7"
              />
              <path
                id="_5._finger_1"
                data-name=" 5. finger 1"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M41.91,96.81c-6.3-3.6-11.9,0-17.3,5.5l-1.2-4.3c10.4-8.2,20.3-11,29.8-9-6.3,1.5-10,4.2-11.3,7.8h0Z"
              />
            </g>
            <g id="_4._finger" data-name=" 4. finger">
              <path
                id="_4._finger_3"
                data-name=" 4. finger 3"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M65.11,16.21c.2,9.2.2,18.5,0,27.2l-6.3,19.6c-.3,4,1.6,7.6,7.8,10.2,13.4-4.6,25.6-5.3,35.7,1.2,8.9-4.3,12.6-11.8,11.8-22.4h0c-7-4.3-12.8-14.1-16.3-34.3"
              />
              <path
                id="_4._finger_2"
                data-name=" 4. finger 2"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M102.31,74.41l-28.3,1.5-5.1-3.9c16.2-4.2,26.4-2.5,33.4,2.4h0Z"
              />
            </g>
            <g id="_3._finger" data-name=" 3. finger">
              <path
                id="_3._finger_2"
                data-name=" 3. finger 2"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M135.21,18.51c-3,6.2-6.7,12.7-11,19.4-2.5,4.7-2.4,9,3.5,12.5,21.2-3.9,24.7,7.5,36.5,11.4,12.3,1.2,18.2-6,16.1-23.1-1.8-6.6-2.9-13-3.4-18.9"
              />
              <path
                id="_3._finger_1"
                data-name=" 3. finger 1"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M127.71,50.41c1.3,2.1,2.8,3.8,5.9,4.4,3.9-.3,8.3.4,12.9,2.1,3.7-.6,5.8-.8,6.8-.6-9.2-7.5-17.4-7.3-25.6-5.9h0Z"
              />
            </g>
            <g id="_2._finger" data-name=" 2. finger">
              <path
                id="_2._finger_1"
                data-name=" 2. finger 1"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M267.01,38.71c-5.4,12.4-7.8,23.1-6.3,31.7h0c-.3,3.9-2,7.2-9.8,7.9-22.3,3-38.5-5.7-46.7-26.3,3.4-12,10.4-19,20-22.4,2.5-2.3,4.8-4.6,7-6.8"
              />
            </g>
            <g id="thumb">
              <path
                id="thumb_3"
                data-name="thumb 3"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M383.61,80.01l.3,14.4c6,7,7.3,13,3.9,18-8.4-11.1-17-10.3-25.5-5.5-9.2-6.4-7.4-12.8-2.4-19.2,1.5-2.1,2.8-4.4,3.9-6.7"
              />
              <path
                id="thumb_2"
                data-name="thumb 2"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M387.01,119.51h0c-11.8,4.5-20,5.8-23.9,3.2-4.8,2.1-.4-7.6-.8-15.7,8.2-6.4,16.7-3.2,25.5,5.5,1.8,2.1,1.8,4.4-.8,7h0Z"
              />
              <path
                id="thumb_1"
                data-name="thumb 1"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M379.21,143.41h0c8.7-1.9,13.8-5.8,12.5-12.9l-4.7-11c-2.6,1.5-6.3,3-23.9,5.5-5.2-4.8-3.4-9.1-1.5-13.4-3.3.9-5.6,4.5-4.3,17.7-5.1,10.5-27,19.1-47.5,27.8-6.5,3.9-9.4,8.6-7.8,14.1,1.8,15.1,8.7,26.9,27,31l11.4-3.1c3-3.5,4.1-7.6,5.5-14.9,10.2-18.1,23.7-30.8,33.3-40.8h0Z"
              />
            </g>
            <g id="middle_hand" data-name="middle hand">
              <g>
                <path
                  id="middle_hand_5-2"
                  data-name="middle hand 5-2"
                  class="mittelhand_links_dp_obl1333_cls5"
                  d="M92.51,270.11l-13.8,9.8-20.8-2.8c-9.9-4.2-14.2-18.4-13.4-41.6-10-35.9-19.1-68.2-20.4-90.6l-6.7-9.4c1.4-11.7,2.1-21.5,1.2-26.7,6.7-19.1,39.7-17.9,47.1,1.3.5,7.4-.9,13.8-4.3,18.8l-4.7,13.8c-5.4,23,4.4,53.8,16.1,85.5,12.9,18.5,20.4,33.2,19.7,41.9h0Z"
                />
                <path
                  id="middle_hand_4-2"
                  data-name="middle hand 4-2"
                  class="mittelhand_links_dp_obl1333_cls5"
                  d="M108.51,110.11h0c3.1-8.9,1.2-18.4-1.1-27.8-13.8-8.6-29.3-9.5-47.5.8.8,14.5-4.4,24.9-2.4,31.8,3.1,14.7,5.9,29.7,7.5,46.7l5.9,65.9c-6.6,19-12.9,28.3-11,43.6,8.8,3,17.1,5.2,22.7,3.5,11.4,3.5,21.6,3.6,27-10.2,1-14.5-3.7-24.1-10.6-31.8-8.5-20.9-9.4-62.8-6.3-104.3,4.8-6.1,10-12.1,15.8-18.2h0Z"
                />
                <path
                  id="middle_hand_3-2"
                  data-name="middle hand 3-2"
                  class="mittelhand_links_dp_obl1333_cls5"
                  d="M163.61,83.41h-.1c-6.7-35.7-31.3-32.1-49.8-18-3,8.4-7.1,15.3-11.8,21.2-3.8,3.5-.5,18,4.3,34.9-.3,26.9-1.8,64.4-3.5,103.6l-12.9,26.7c-3,4.8-5.1,12.9-7.1,22.7,5.8,2.2,11.5,2.8,17.3,2.8,6.8,2.7,15.6,4.1,25.1,5.1,6.9-1.7,13.5-2.1,18.8,1.2,3-4.2,1.3-12.7-.4-21.2l-.4-11.8c-4.8-7-7.9-14.8-10.2-23.1,0-37.6,6.2-65.1,9.8-89.8,3.9-28.3,22-40,21.2-43.2l-.3-11.1h0Z"
                />
              </g>
              <path
                id="middle_hand_2"
                data-name="middle hand 2"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M170.51,90.51h0c6.4-4.7,11.8-9.9,12.5-18l15.3-11c17.7-2.6,31.8,4.2,38.4,29.4l-1.2,15.3c-3.3,5.8-10,9.4-16.9,12.9-24.1,43.6-38.8,104.3-30.6,145.9,5.3,24.4,2.1,40.9-14.5,45.1-5.3-.7-12.4-6.2-21.6-16.5-10.7,3.2-20.6,5.1-27.1,2-3.5-13.5,9-24.3,18.1-35.7,24.7-44.7,18.8-89.8,30.3-138.1.1-14.2.5-25-2.7-31.3h0Z"
              />
              <path
                id="middle_hand_1"
                data-name="middle hand 1"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M328.61,215.21h0c-.8-5.8-.6-11.3.8-16.4-3.5-7.7-8.2-13.4-14.1-17.7-13.4-9.8-25.1-3.4-35.7,5.9-1.4,6.3-1.3,13.3,1.2,21.6-7.3,17.7-20.9,35-41.6,52.2l-22.7,4.3c-3.2,2.1-4.4,4.2-4.7,6.3-4.4-.6-9.3,3.5-14.5,9.4.7,3.3,2,6.5,7.1,9,2.1,4.1,5,8.2,12.1,12.1,2.3,6.7,8.5,11.4,23.9,11.8,6.8-4.4,11.9-6.8,14.1-5.5,25.3-38,51.1-79,74.1-93h0Z"
              />
            </g>
            <path
              id="forearm_2-2"
              data-name="forearm 2-2"
              class="mittelhand_links_dp_obl1333_cls6"
              d="M44.31,504.91c-2.1-14.6-4.5-29-7.2-43-6.7-17-8.8-30-1.5-35.3-4.7-17.9.8-28.6,14.5-22.7,2.3,10,6.8,16.5,20.4,9,8.6.8,17.2,1.8,22,7.5,5.4,6.9,2.6,18.3-3.9,31.8v10.2c-.5,14.1-.8,28.2-.7,42.3"
            />
            <path
              id="forearm_1-2"
              data-name="forearm 1-2"
              class="mittelhand_links_dp_obl1333_cls6"
              d="M197.71,492.61c.9-10.1,2.1-20.2,3.4-30.3,2.9-10.9,2.9-21.3,5.1-31.8.3-3.5,0-9-3.9-14.5l-5.1-40c-1.9-5.1-7.4-8.7-14.5-11.8-7.4-6.3-22.4,16.1-34.5,23.1-3.9,5.1-8.4,9.5-27.1,9.4l-14.9,9.4c-8.9,1.6-17.1,4.4-23.5,10.2l-.8,28.4c-.8,2.4,8.6,9.8,12.5,16.5,4.8,14.1,9.1,28.7,12.9,43.9"
            />
            <g id="wrist-2">
              <path
                id="wrist_part_8-2"
                data-name="wrist part 8-2"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M80.11,388.41h0c-10.7,8.5-21.3,6.8-32.4-17.1.6-10.1-3.7-20.2-3.5-23.9,4.7-2,8.2-6,11.4-12.1,10.2-9.2,18.9-4,26.7,9.8,20.6,14.3,14.9,46.2-2.2,43.3h0Z"
              />
              <path
                id="wrist_part_7-2"
                data-name="wrist part 7-2"
                class="mittelhand_links_dp_obl1333_cls6"
                d="M175.21,376.01h0c-5.6.7-8.9,2.9-11.4,5.5-8.9,19.3-25.1,26.3-46.3,24.7-6.7-10.6-5.7-25.5-7.1-41.2,6.9-23.3,29.6-7.3,31-32.6,4.4-12.7,25.1-13.4,55.7,16.1-1.7,7.9-5.3,14.5-16.9,15.7-2.2,4.2-4,8.2-5,11.8h0Z"
              />
              <path
                id="wrist_part_6-2"
                data-name="wrist part 6-2"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M200.71,342.31c7.1.2,13,3.2,16.4,2,7.8-4.1,12.6-10.4,15.3-18,3.5-4.7,4.5-8.8,2.4-12.1-3.6-.1-2.8-5.4-4.5-3.3-3,3.6-10.7.6-13.9-8.8-6.6-5.3-14-7.9-22-8.2-2.7.1-9.8,7.2-17.7,15.7-11.1,4.4-8.4,10.1.4,16.5,14.6,5.5,16.2,12.1,17.3,18.8,2.7,1.3,4.9,1,6.3-2.6h0Z"
              />
              <path
                id="wrist_part_5-2"
                data-name="wrist part 5-2"
                class="mittelhand_links_dp_obl1333_cls6"
                d="M127.01,410.51h0c-18,5.9-34.5,5.5-47.5-8.2-4.1-12.7-3-23.6,18.1-27,10.9-8.1,21.4-10.5,31.3-7.8,11.7,7.4,7.3,23.9-1.9,43h0Z"
              />
              <path
                id="wrist_part_4-2"
                data-name="wrist part 4-2"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M145.01,287.41c-3.5-3.6-15.6-2.3-32.8-2.2-6.6-2.3-12.6-4.3-17.1-5.3l-22,1.2c-6.7,1.2-11.1-1.2-15.3-3.9-6.2,1.3-5.3,9.8-4.3,18.4-7.1,5.9-3.4,11,.8,16.1-1.2,6.5,0,14.2,3,22.9l11.6,8.4c4.2,10.3,11.5,14.2,14.3,17,6.6,6.5,12.8,12.4,15.9,12.5,18.6,3.8,34.1.9,41.6-19.6-.5-7.5-2.6-15-6.5-24.1,15.9-27.9,15.3-36.3,10.8-41.4h0Z"
              />
              <path
                id="wrist_part_3-2"
                data-name="wrist part 3-2"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M98.81,316.81h0l-22.7,8.6c-23.1,13.7-20,27.4-9,41.2,9.4,5.3,18.8,3.6,28.3-.4,9.4-12.3,16.5-24.7,16.1-37.3-2.5-7.7-7.2-10.7-12.7-12.1h0Z"
              />
              <path
                id="wrist_part_2-2"
                data-name="wrist part 2-2"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M101.01,358.01h0c-2.2,2.2-6.1,3.1-15.4,2.2-6.9-8-3-35.1-4.1-54.7-7.4-19.2-.8-23.1,11-28.6,3.4-.6,7.1-1.5,12.1-3.5,5.7-.6,12.1-.1,19.6,2-2.4,9.8-3.5,29.8-15.3,28.6,2,8.6-1,16.8-6.3,24.7l1.2,23.9-2.8,5.4h0Z"
              />
              <path
                id="wrist_part_1-2"
                data-name="wrist part 1-2"
                class="mittelhand_links_dp_obl1333_cls5"
                d="M141.51,332.51h0c-7.9-4.1-15.2-8.7-20.8-14.1-4.1-8.1,0-12.5,1.5-17.3,10.9-.2,21.5-.1,39.6-5.9,11.2-1.8,13.3,4.9,11.8,14.9l-.4,23.1c0,17-12.5,5.1-31.7-.7h0Z"
              />
            </g>
            <path
              id="_Überlagerung2"
              data-name=" Überlagerung2"
              class="mittelhand_links_dp_obl1333_cls5"
              d="M208.81,61.11c5.4,11.7,9.8,14.4,23.7,17.8"
            />
            <path
              id="_Überlagerung1"
              data-name=" Überlagerung1"
              class="mittelhand_links_dp_obl1333_cls5"
              d="M302.01,171.21c0,17.1,12.1,31,27,31"
            />
          </g>
        </g>
        <g id="overlays">
          <g id="outlines_highlights" data-name="outlines highlights">
            <path
              id="outlineshighlights3"
              class="weichteile1333Fill"
              style={{
                fill: props.colors.weichteile1333Fill,
                stroke: props.colors.weichteile1333Fill,
              }}
              d="M252.86,372.07c85-80.4,87.4-164.3,140.2-223.3,1.7,5.9-3.7,10.6-7.1,14.6-13.1,15.1-31.7,62.8-60.6,120.1-17.8,35.2-47,68-55.7,76.4-8,7.7-25.7,26.8-28.8,42.1-10.5,34.4-13.3,80.5-15.6,94.1-5.6,33.1-1.8-97.7,27.6-123.9v-.1Z"
            />
            <path
              id="outlineshighlights2"
              class="weichteile1333Fill"
              style={{
                fill: props.colors.weichteile1333Fill,
                stroke: props.colors.weichteile1333Fill,
              }}
              d="M32.06,292.47c-.6-51.7-3.7-98.1-9.7-118.5,0,0-2.6,3,0,14.6,3,13.3,8.2,155.5,7.9,160.4-1.1,14-12.3,21.4-13.8,51.1-1.4,26.5-4.4,79.4-1,94,2.9,12.8,2.9,3.3,2.9,3.3-.2-19.4-4.1-103.4,13.2-133,7.5-20.8.9-48.2.6-71.7"
            />
            <path
              id="outlineshighlights7"
              class="weichteile1333Fill"
              style={{
                fill: props.colors.weichteile1333Fill,
                stroke: props.colors.weichteile1333Fill,
              }}
              d="M356.46,54.67c-4.1,1.2-6.1,3.2-7.1,6.2-6,16-7,34-8,51-11,18-48.7,33-51,34s-4.8-6.1-6-10c-3-12.6-4.1-27.5-4.1-27.5,0,0-2.9-48.5,3.3-69.6,1.9-7.3,6-1.1,3.2,10.5-5,20.3-4.6,75.9,2.7,88.6,4.8,8.3,47.4-22.5,47.3-27.7-.3-16.7,8.2-72.5,19.7-55.5Z"
            />
            <g>
              <path
                id="outlines3"
                class="mittelhand_links_dp_obl1333_cls4"
                d="M223.66,501.17c-3.3,4.3,2.3-105.4,28.9-129.1,85-80.4,87.4-164.3,140.2-223.3"
              />
              <path
                id="outlines2"
                class="mittelhand_links_dp_obl1333_cls4"
                d="M17.46,500.67c.5-.4.5-3.4.5-3.4-.2-19.4-4.1-103.4,13.2-133,7.5-20.8.9-48.2.6-71.7"
              />
              <path
                id="outlines2-2"
                class="mittelhand_links_dp_obl1333_cls4"
                d="M31.76,292.47c-.6-51.7-3.7-98.1-9.7-118.5"
              />
              <path
                id="outlines7"
                class="mittelhand_links_dp_obl1333_cls4"
                d="M356.16,54.67c-4.1,1.2-6.1,3.2-7.1,6.2-6,16-7,34-8,51-11,18-48.7,33-51,34s-4.8-6.1-6-10c-3-12.6-4.1-27.5-4.1-27.5,0,0-2.9-48.5,3.3-69.6.4-1.7,1-2.7,1.5-3"
              />
            </g>
          </g>
          <g id="MiddlehandbonesHL">
            <g id="Highlights">
              <path
                id="Highlight5"
                class="MHK1333Fill"
                style={{
                  fill: props.colors.MHK1333Fill,
                  stroke: props.colors.MHK1333Fill,
                }}
                d="M92.76,269.87l-13.8,9.8-20.8-2.8c-9.9-4.2-14.2-18.4-13.4-41.6-10-35.9-19.1-68.2-20.4-90.6l-6.7-9.4c1.4-11.7,2.1-21.5,1.2-26.7,6.7-19.1,39.7-17.9,47.1,1.3.5,7.4-.9,13.8-4.3,18.8l-4.7,13.8c-5.4,23,4.4,53.8,16.1,85.5,12.9,18.5,20.4,33.2,19.7,41.9h0Z"
              />
              <path
                id="Highlight4"
                class="MHK1333Fill"
                style={{
                  fill: props.colors.MHK1333Fill,
                  stroke: props.colors.MHK1333Fill,
                }}
                d="M108.76,109.87h0c3.1-8.9,1.2-18.4-1.1-27.8-13.8-8.6-29.3-9.5-47.5.8.8,14.5-4.4,24.9-2.4,31.8,3.1,14.7,5.9,29.7,7.5,46.7l5.9,65.9c-6.6,19-12.9,28.3-11,43.6,8.8,3,17.1,5.2,22.7,3.5,11.4,3.5,21.6,3.6,27-10.2,1-14.5-3.7-24.1-10.6-31.8-8.5-20.9-9.4-62.8-6.3-104.3,4.8-6.1,10-12.1,15.8-18.2h0Z"
              />
              <path
                id="Highlight3"
                class="MHK1333Fill"
                style={{
                  fill: props.colors.MHK1333Fill,
                  stroke: props.colors.MHK1333Fill,
                }}
                d="M163.86,83.17h-.1c-6.7-35.7-31.3-32.1-49.8-18-3,8.4-7.1,15.3-11.8,21.2-3.8,3.5-.5,18,4.3,34.9-.3,26.9-1.8,64.4-3.5,103.6l-12.9,26.7c-3,4.8-5.1,12.9-7.1,22.7,5.8,2.2,11.5,2.8,17.3,2.8,6.8,2.7,15.6,4.1,25.1,5.1,6.9-1.7,13.5-2.1,18.8,1.2,3-4.2,1.3-12.7-.4-21.2l-.4-11.8c-4.8-7-7.9-14.8-10.2-23.1,0-37.6,6.2-65.1,9.8-89.8,3.9-28.3,22-40,21.2-43.2l-.3-11.1h0Z"
              />
              <path
                id="Highlight2"
                class="MHK1333Fill"
                style={{
                  fill: props.colors.MHK1333Fill,
                  stroke: props.colors.MHK1333Fill,
                }}
                d="M170.76,90.27h0c6.4-4.7,11.8-9.9,12.5-18l15.3-11c17.7-2.6,31.8,4.2,38.4,29.4l-1.2,15.3c-3.3,5.8-10,9.4-16.9,12.9-24.1,43.6-38.8,104.3-30.6,145.9,5.3,24.4,2.1,40.9-14.5,45.1-5.3-.7-12.4-6.2-21.6-16.5-10.7,3.2-20.6,5.1-27.1,2-3.5-13.5,9-24.3,18.1-35.7,24.7-44.7,18.8-89.8,30.3-138.1.1-14.2.5-25-2.7-31.3h0Z"
              />
              <path
                id="Highlight1"
                class="MHK1333Fill"
                style={{
                  fill: props.colors.MHK1333Fill,
                  stroke: props.colors.MHK1333Fill,
                }}
                d="M328.86,214.97h0c-.8-5.8-.6-11.3.8-16.4-3.5-7.7-8.2-13.4-14.1-17.7-13.4-9.8-25.1-3.4-35.7,5.9-1.4,6.3-1.3,13.3,1.2,21.6-7.3,17.7-20.9,35-41.6,52.2l-22.7,4.3c-3.2,2.1-4.4,4.2-4.7,6.3-4.4-.6-9.3,3.5-14.5,9.4.7,3.3,2,6.5,7.1,9,2.1,4.1,5,8.2,12.1,12.1,2.3,6.7,8.5,11.4,23.9,11.8,6.8-4.4,11.9-6.8,14.1-5.5,25.3-38,51.1-79,74.1-93h0Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LinkeMittelhandDpOblSitzend1333;
