import PatientInfoTopBar from "./Components/PatientInfoTopBar";
import ExamPlannerController from "./Components/ExamPlannerController";
import ExamPlanner from "./Components/ExamPlanner";

export default function ExamPlannerV3() {
  return (
    <div
      className="d-flex px-2 py-2"
      style={{ height: "100vh", overflowY: "auto" }}
    >
      <div className="d-flex flex-column w-100">
        <PatientInfoTopBar />
        <ExamPlanner />
        <ExamPlannerController />
      </div>
    </div>
  );
}
