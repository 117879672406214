const directionsDE = [
  "Bitte entfernen Sie Haarspangen,Haarnadeln, Kopfschmuck und Zopfgummis mit Metall.",
  "Bitte entfernen Sie Piercings und Zahnersatz aus ihrem Gesicht.",
  "Bitte entfernen Sie Halsketten und anderen Halsschmuck.",
  "Entfernen Sie Ringe, Armbänder,Uhren und anderen Handschmuck.",
  "Bitte ziehen Sie Schuhe und Socken aus und entfernen Sie Fußkettchen und anderen Schmuck.",
  "Bitte legen Sie langärmlige Oberteile ab.",
  "Bitte sehen Sie lange Hosen aus.",
  "Ziehen sie ihr Oberteil aus.",
  "Bitte ziehen Sie die Hose aus.",
];

const directionsPL = [
  "Proszę zdjąć wsówki, spinki do włosów, nakrycia głowy i gumki do warkoczy z metalem.",
  "Proszę usunąć kolczyki i protezy z twarzy.",
  "Proszę zdjąć naszyjniki i inną biżuterię z szyi.",
  "Zdejmij pierścionki, bransoletki, zegarki i inną biżuterię z rąk.",
  "Proszę zdjąć buty i skarpetki oraz zdjąć bransoletki i inną biżuterię.",
  "Proszę zdjąć bluzkę z długim rękawem.",
  "Proszę nosić długie spodnie.",
  "Zdejmij koszulkę.",
  "Proszę zdjąć spodnie.",
];

const directionsMap = {
  de: directionsDE,
  pl: directionsPL,
};

function useExamPreparationDirections(patPrep) {
  if (!patPrep || patPrep.length !== 9) return null;
  const lang = window.conf.LANG;
  const directionsToUse = directionsMap[lang.toLowerCase()];
  const directions = [];
  for (let i = 0; i < patPrep.length; i++) {
    if (patPrep[i] === "1") {
      directions.push(directionsToUse[i]);
    }
  }

  return directions;
}

export default useExamPreparationDirections;
