import "./ExpandableToolMenu.css";

import { Popover, OverlayTrigger, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import React from "react";
import JournalButton from "./JournalButton.js";
import AuthorizedElement from "../../../Utilities/AuthorizedElement.js";

class ExpandableToolMenu extends React.Component {
  static propTypes = {
    /** Button label */
    label: PropTypes.string.isRequired,
    /** Array of buttons to render when expanded */
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string.isRequired,
        icon: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            name: PropTypes.string.isRequired,
          }),
        ]),
      })
    ).isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ]),
    onGroupMenuClick: PropTypes.func,
    activeCommand: PropTypes.string,
    allowed_roles: PropTypes.arrayOf(PropTypes.string),
    modal: PropTypes.any,
  };

  static defaultProps = {
    buttons: [],
    icon: "ellipse-circle",
    label: "More",
  };

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  toolbarMenuOverlay = () => (
    <Popover
      placement="bottom"
      className="pmed-tools-popover"
      id={`${Math.random()}_pmed-tools-popover}`}
    >
      <Popover.Content>{this.getButtons()}</Popover.Content>
    </Popover>
  );

  getButtons = () => {
    const btnsByCategories = {};
    const switchesByCategories = {};
    const categories = [];

    this.props.buttons.forEach((button, index) => {
      let buttonComponent;

      if (!btnsByCategories[button.category]) {
        btnsByCategories[button.category] = [];
      }

      if (!switchesByCategories[button.category]) {
        switchesByCategories[button.category] = [];
      }

      if (!categories.includes(button.category)) {
        categories.push(button.category);
      }

      buttonComponent = (
        <AuthorizedElement roles={button.allowed_roles} key={button.id}>
          <JournalButton
            look={button.look}
            modal={button.modal_to_render || null}
            {...button}
            isActive={button.id === this.props.activeCommand}
          />
        </AuthorizedElement>
      );

      if (button.look === "button") {
        btnsByCategories[button.category].push(buttonComponent);
      }
      if (button.look === "switch") {
        switchesByCategories[button.category].push(buttonComponent);
      }
    });

    return (
      <Container className="pmed-tools-popover-contents">
        {categories.map((category, index) => {
          return (
            <React.Fragment key={`categorykey${index}`}>
              <div key={`${index}_cat`} className="button-category">
                {category}
              </div>
              <div key={`${index}_btn`} className="buttons-row">
                {btnsByCategories[category]}
              </div>
              <div key={`${index}_tog`} className="toggles-row">
                {switchesByCategories[category]}
              </div>
            </React.Fragment>
          );
        })}
      </Container>
    );
  };

  isActive = () => {
    let isActive = false;
    if (this.props.activeCommand) {
      this.props.buttons.forEach((button) => {
        if (this.props.activeCommand === button.id) {
          isActive = true;
        }
      });
    }

    return isActive;
  };

  activeIcon = () => {
    if (this.props.activeCommand) {
      return (
        this.props.buttons.find((btn) => this.props.activeCommand === btn.id)
          .icon || this.props.icon
      );
    }

    return this.props.icon;
  };

  onExpandableToolClick = () => {
    if (this.props.onGroupMenuClick) {
      this.props.onGroupMenuClick();
    }
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  onOverlayHide = () => {
    this.setState({
      isExpanded: false,
    });
  };

  render() {
    return (
      <OverlayTrigger
        delay={{ show: 1000, hide: 200 }}
        key="menu-button"
        trigger="click"
        placement="top"
        rootClose={true}
        handleHide={this.onOverlayHide}
        onClick={this.onExpandableToolClick}
        overlay={this.toolbarMenuOverlay()}
      >
        {({ ref, ...triggerHandler }) => (
          <JournalButton
            {...triggerHandler}
            label={this.props.label}
            icon={this.props.icon}
            hint={this.props.hint}
            key={this.props.id}
            modal={null}
            ref={ref}
          />
        )}
      </OverlayTrigger>
    );
  }
}

export default ExpandableToolMenu;
