import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import PhysiciansTable from "../../ReferringPhysicians/PhysiciansList"
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../../Redux/mappers/reports/reportsMappers'


function SelectRefPhysician(props) {
    const rowClickHandler = (event, row) => {
      props.addRemoveReportSelectedId(row.original.id)
    }
  
    return (
      <Col md={8} className="mx-auto justify-content-center">
        <PhysiciansTable headerless={true} selectedIds={props.reports.repSelectedIds} keycloak={props.keycloak} rowClick={rowClickHandler} setfilter={()=>{}}></PhysiciansTable>
      </Col>
    );
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SelectRefPhysician);