import style from "./style.scss";

function RechteHandLatSitzend1342(props) {
  return (
<svg id="handlat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215.86 476.63">

<g id="fullhand">
  <g id="bones">
    <path id="fingerpart19" class="Hand_lat_rechts1342_cls5" d="M174.46,475.13l-3.2-10.7c-3.6-12.5-2.4-27.4.8-43.5-2.6-11.1-9.7-10.4-13.4-3l-2.3,2.4"/>
    <path id="fingerpart18" class="Hand_lat_rechts1342_cls5" d="M171.56,474.73c-1.9-6.9-3.7-13.6-5.6-30.8l2.7-17.2c-2.4-4.1-3.9-9.2-12.4-6.3-7.9,8.2-17.6,13-27.6,8-2.1-1.9-4.7-4.3-11.1-5.3-3.9,5.2-5.2,11.4-2.8,19-.5,4.9-.3,9.8,6.3,14.9,3.4,4,5.7,8.1,7.2,12.5"/>
  </g>
  <g id="fingers4">
    <path id="fingerpart17" class="Hand_lat_rechts1342_cls5" d="M115.36,336.53h0c3.5-.9,7.1,0,11.1,6.7,0,3-1,6.3-2.4,9.6-3,8.4-4.4,16.2-.3,21.8-.8,9.3-5.7,15.7-18.2,13.7-4.4-1.2-9.7-6.5-14.5-15.4,0-4.6.3-8.9,2.1-11.8,6.3,1.6,13.1-.2,20.2-3.8,4.1-5.1,4.4-12.2,2.1-20.8h-.1Z"/>
    <path id="fingerpart16" class="Hand_lat_rechts1342_cls5" d="M123.06,379.33h0c10.1,0,16.7,3.1,20,9.3.5,10.5,4.1,18.9,13.1,23.5,1.1,4.8-12.8,16.8-28.5,11.1-4.5-1.2-7.9-4-10.9-7.6-4-6.9-3.5-10.9-1.5-14.1-3.4-2.4-6.2-4.9-8.4-7.6-.3-2.3,0-4.2,1.8-5.3,8.3-.8,12.2-4.4,14.2-9.2h0l.2-.1h0Z"/>
    <path id="fingerpart15" class="Hand_lat_rechts1342_cls5" d="M141.26,347.43c1.9,3.2,3.9,5.8,7.3,5.5,2.9,13.8,3.4,25.4-8,28.1l-17.5-1.6.6-4.8c-3.8-6.4-2-14,.3-21.8l1.2-2.8c3.9.5,5.8,1.3,7.7,2,2.2,0,5.1-1.9,8.4-4.5h0v-.1h0Z"/>
    <path id="fingerpart14" class="Hand_lat_rechts1342_cls5" d="M143.16,388.63h0c1.1,11.9,5,20.3,13.3,23.2,1.1.4,2.4,0,3.2-.7,3.9-3.8,5.4-9.1,6-15,0-.7,0-1.4-.4-2l-3.2-5.4c-.4-.7-.5-1.4-.4-2.2.5-2.9.8-5.7.6-8.6.7-3.3.7-6.5.5-9.6,0-.8.2-1.6.7-2.3,1.2-1.8,1.7-3.6,2.8-4.9.9-1.1,1.2-2.4.7-3.7-1.8-3.9-5.2-9.9-7.1-10.2-1.9-.6-3.6,3-4.3.6l-5.2,5.6-1.6,3.3c2,.2,6.2,23.9-7.7,24.5l-6.1-.8c5.9,3.4,8.7,6.2,8.7,8.4h-.1l-.4-.2h0Z"/>
    <path id="fingerpart13" class="Hand_lat_rechts1342_cls5" d="M168.56,372.43h0c1.8,11.5,1.1,20.6-2.8,22.5l-4.4-7.3c1.2-3.3,1.1-11.9,1-20.7,1.8-2.9,1.8-5.9,5.4-7.3,1.6,2.6,3.7,6.4.8,12.9h0v-.1Z"/>
  </g>
  <g id="fingers3">
    <path id="fingerpart12" class="Hand_lat_rechts1342_cls5" d="M123.16,43.33c.2-4.3,1.1-7,2.3-9.1,2.5,0,5,.7,5.7,9.8l1.1,6.7c2.8,5.7,4.5,10.6,3.2,13l-3.1.7-2.6-7.9c-1.2-5.9-3.2-10.5-6.7-13.2,0,0,.1,0,.1,0Z"/>
    <path id="fingerpart11" class="Hand_lat_rechts1342_cls5" d="M154.46,118.63h0c1.5-2.4.9-6.1,0-10-7.8-21.8-17.5-36.6-16.4-37.7l.5-2.2c-1.2-2.7-3.2-4.2-6-4.3l10.6,32c3,5.4,6.4,11,1.5,11.2l5,11.4c2.1-1.2,3.5-1,4.8-.4h0Z"/>
    <path id="fingerpart10" class="Hand_lat_rechts1342_cls5" d="M163.26,201.23c1.8,1.9,4.1,1.6,6.7.3.1,2.5,1.9,4.2,4.6,5.4,2.2-2.5,2.5-6,2.3-10.1-3.1-8.7-4.3-17.6-4.4-26-5.1-22.4-10.8-37.1-10.3-42.1-2.1-4.1-4.6-7.8-7.7-10.2-1.5-.8-3.1-.5-4.8.4l5.3,25.8c3.4,15.6,5.9,30.4,6.6,43.4l-2.5,3.2c3,3,5.8,6.1,4.2,9.8v.1h0Z"/>
    <path id="fingerpart9" class="Hand_lat_rechts1342_cls5" d="M142.26,319.53h0c4.1,10,5.6,19.5-.3,27.2,2.1.5,9.5-3.3,11.6-2,.5,2.3.7,2.8,2.8,3.8,4.8-2.8,2.7-6.4,2.1-11.1l-1.1-7.2c-1.1-7-.6-14.1,1.4-20.9,7.8-25.2,8.9-60.6,12.3-68.7l1.7-8.7c2.5-3.1,4.3-6.4,4.8-9.7,2.2-3,1-8.2-3-15.1-2.5-1.3-5-2.5-4.6-5.4-2.5.9-4.8.9-6.7-.3,3.7,8.3,3.1,15.4-.8,21.5-5.2,38.1-11.4,72.4-20.2,96.8h0v-.2h0Z"/>
  </g>
  <path id="fingerpart8" class="Hand_lat_rechts1342_cls5" d="M125.26,349.93h0c1.2-1.7,1.7-3.9,1.3-6.7l-2.2-3.4c-2.7-4.5,1.2-9.1,5.7-13.6,13.6-25,19.9-86.6,13.5-97.1-4-6.7-7.7-13.3-7.1-17.7,2-6.6,4.3-12,6.9-16.3,2.7.5,5,.3,7.3.2,3.3-1.8,6.1-2.8,7.8-2l8.6,4.9c4.5,3.8,5.9,7,4.2,9.8,3.8,7.7,2.8,14.7-.8,21.5l-20.2,96.8c4.1,9.9,6.7,19.5-1.7,26.9-2.5.2-4.9-2-7.2-5.5-3.9,4.3-6.2,4.6-8.4,4.5-2-1-4.5-1.8-7.7-2h0v-.3h0Z"/>
  <g id="fingers2">
    <path id="fingerpart7" class="Hand_lat_rechts1342_cls5" d="M53.56,180.13h0c-.3,3.8-2.2,8.7-5.4,14.7-2.6,1.9-11.4,6.7-17.6,5.1-3.6-2-5.3-6.4-6.6-11.4,4.5-12.6.5-17.2-3.9-21.4-6.1-7.6-3.6-14,2-20.1,10.3-3.5,15.9-.5,14.4,12,2.2,6.4,6,11.1,11,14.4l6.3,6.7s-.2,0-.2,0Z"/>
    <path id="fingerpart6" class="Hand_lat_rechts1342_cls5" d="M52.36,258.23h0c-.9-17.5,6.7-13.4-7.5-42.2-5.9-3.3-9.8-7.6-11.1-13.2l.4-2.6c5.8-.2,9.9-2.9,14-5.3l5-10.6c3,1.9,5,6.4,5.8,14.2,8.8,18.1,16.4,34.4,18.4,43.3,7.1,4.2,12,8.7,5.6,14.1l-14.7,11.8c-.3,3-.8,5.8-5.9,4.1-7.1.3-9.2-5.8-10-13.7h0v.1Z"/>
    <path id="fingerpart5" class="Hand_lat_rechts1342_cls5" d="M85.96,332.93h0c-6.8-17.2-13.2-33-18.1-42-9.4-1.8-12.2-11.2-10.8-17.1l5.3-1.9c6.9,2.1,4.8-3.3,7.2-5.1,4.7-3.5,9.1-7.1,13.4-10.8,5.3,2.1,8.6,5.8,8.3,12.5-.6,4.8.5,10.4,4.7,17.8,4,15,7.3,26.3,9.9,35.1,2,3.3,4.5,6.3,8.1,8.4,1.9,4.1,2.6,10,2.6,17-.3,5-1.9,9.5-5.4,13.2-9.2,2.6-17.1,3.1-22.5-.5-5-.3-9.1-7.5-3.3-18.4l.4-8.1h0s.2-.1.2-.1Z"/>
  </g>
  <g id="Fingers">
    <path id="fingerpart4" class="Hand_lat_rechts1342_cls5" d="M146.66,194.23h0c-4.8-4.7-3.7-8.4,1.4-11.4,0-13.9-.7-29.9-2.3-48-.1-2.9-1.7-5.9-5.5-9.2-4.9-1.9-6.5-5-1.4-10.6,5.4.3,11.7,1.9,13.7-1,4.9,9.2,7.8,22.7,10.3,37.2,3.9,16.1,6,30.6,6.6,43.4l-2.5,3.2c-3.7-1.9-8.5-3.6-8.3-6l-8.1,3.2-3.9-.9h0v.1h0Z"/>
    <path id="fingerpart3" class="Hand_lat_rechts1342_cls5" d="M127.56,68.93h0c4.1,3.8,7.7,9.3,6.2,32.2-.8,3.3-1.6,7,.4,16.2,1.4.5,3-.7,4.6-2.2,5.1,1.8,9.8,2.1,13.7-1,4-.6,2.8-4.8-1.5-11.2-3.7-7-5.2-12.7-6.1-18.1l-7.1-21.8c-2.2-1.6-4.6-2.5-7.2-1.9-4.6,3.7-5.8,6.4-3,7.8h0Z"/>
    <path id="fingerpart2" class="Hand_lat_rechts1342_cls5" d="M118.56,22.03h0c-2.9,1.5-3.1,10.6-2.2,14.8,1,2.1,3.1,3.1,5.6,3.6-.6,4.7-.3,9.3,1.3,13.5l-1,4.4c-2,4.9-1.4,8.1,3,8.9,1.2-3.7,3-5.8,5.2-6.2,3-.5,5.3.3,7.2,1.9-.9-5.9-3.4-10.8-7.8-14.4-1.2-7.9-2.7-14.7-4.7-20.7-3.2-2.8-5.6-7.8-6.6-5.9h0s0,.1,0,.1Z"/>
    <path id="fingerpart1" class="Hand_lat_rechts1342_cls5" d="M138.86,115.13c3-2,5.9-3.5,8.4-3.4,2.4.5,4.4,1.1,5.3,2.4-4,3-8.8,2.5-13.7,1Z"/>
  </g>
</g>
<g id="Highlights">
  <path id="fingerparthighlight8" class="Finger1342Fill"  style={{
          fill: props.colors.Finger1342Fill,
          stroke: props.colors.Finger1342Fill,
        }} d="M132.26,50.73l-1.1-6.7c-.52-6.73-2.02-8.86-3.78-9.51.65,3.55,2.46,6.84,2.38,10.52,0,.17-.05.32-.11.45.16,1.08.28,2.17.41,3.26.05.18.1.36.15.54.23.04.46.15.64.34.2.2.35.45.49.71.37-.02.76.13.92.47.23.52.5,1.03.78,1.52-.24-.52-.49-1.05-.76-1.59Z"/>
  <path id="fingerparthighlight7" class="Finger1342Fill"  style={{
          fill: props.colors.Finger1342Fill,
          stroke: props.colors.Finger1342Fill,
        }} d="M154.46,108.63c-3.46-9.67-7.27-17.91-10.37-24.24.14.52.29,1.04.45,1.56.04.06.08.1.11.17,1.62,4.51,3.51,8.92,5.22,13.39.76,1.56,1.49,3.14,2.07,4.77,1.34,1.81,2.61,3.83,2.54,6.05-.04,1.2-.32,2.43-.73,3.55-.18.48-.39.96-.65,1.4.25.5.34,1.09.25,1.65.56.16,1.08.5,1.41,1.09,1.08-2.39.53-5.8-.3-9.39Z"/>
  <path id="fingerparthighlight6" class="Finger1342Fill"  style={{
          fill: props.colors.Finger1342Fill,
          stroke: props.colors.Finger1342Fill,
        }} d="M176.86,196.83c-3.1-8.7-4.3-17.6-4.4-26-5.1-22.4-10.8-37.1-10.3-42.1-1.93-3.77-4.21-7.19-6.97-9.59,1.65,4.98,4.2,9.66,4.95,14.91.37,2.57.65,5.15.99,7.73.5,1.55.98,3.1,1.38,4.67,1.23,4.9,2.05,9.93,2.11,14.99.19.24.34.53.39.85.9,5.26,2.6,10.36,3.19,15.68.62,5.55.77,11.07.77,16.65,0,1.49-1.08,2.29-2.23,2.39-.22.06-.46.11-.73.11-.07,0-.13-.02-.2-.02.34.43.54.96.56,1.51,2.38,1.23,4.53,2.83,5.9,5.23.36.62.41,1.31.23,1.93.61.43,1.29.82,2.06,1.16,2.2-2.5,2.5-6,2.3-10.1Z"/>
  <path id="fingerparthighlight5" class="Finger1342Fill"  style={{
          fill: props.colors.Finger1342Fill,
          stroke: props.colors.Finger1342Fill,
        }} d="M53.56,180.13h0c-.3,3.8-2.2,8.7-5.4,14.7-2.6,1.9-11.4,6.7-17.6,5.1-3.6-2-5.3-6.4-6.6-11.4,4.5-12.6.5-17.2-3.9-21.4-6.1-7.6-3.6-14,2-20.1,10.3-3.5,15.9-.5,14.4,12,2.2,6.4,6,11.1,11,14.4l6.3,6.7s-.2,0-.2,0Z"/>
  <path id="fingerparthighlight4" class="Finger1342Fill"  style={{
          fill: props.colors.Finger1342Fill,
          stroke: props.colors.Finger1342Fill,
        }} d="M52.36,258.23h0c-.9-17.5,6.7-13.4-7.5-42.2-5.9-3.3-9.8-7.6-11.1-13.2l.4-2.6c5.8-.2,9.9-2.9,14-5.3l5-10.6c3,1.9,5,6.4,5.8,14.2,8.8,18.1,16.4,34.4,18.4,43.3,7.1,4.2,12,8.7,5.6,14.1l-14.7,11.8c-.3,3-.8,5.8-5.9,4.1-7.1.3-9.2-5.8-10-13.7h0v.1Z"/>
  <path id="fingerparthighlight3" class="Finger1342Fill"  style={{
          fill: props.colors.Finger1342Fill,
          stroke: props.colors.Finger1342Fill,
        }} d="M146.66,194.23h0c-4.8-4.7-3.7-8.4,1.4-11.4,0-13.9-.7-29.9-2.3-48-.1-2.9-1.7-5.9-5.5-9.2-4.9-1.9-6.5-5-1.4-10.6,5.4.3,11.7,1.9,13.7-1,4.9,9.2,7.8,22.7,10.3,37.2,3.9,16.1,6,30.6,6.6,43.4l-2.5,3.2c-3.7-1.9-8.5-3.6-8.3-6l-8.1,3.2-3.9-.9h0v.1h0Z"/>
  <path id="fingerparthighlight2" class="Finger1342Fill"  style={{
          fill: props.colors.Finger1342Fill,
          stroke: props.colors.Finger1342Fill,
        }} d="M127.56,68.93h0c4.1,3.8,7.7,9.3,6.2,32.2-.8,3.3-1.6,7,.4,16.2,1.4.5,3-.7,4.6-2.2,5.1,1.8,9.8,2.1,13.7-1,4-.6,2.8-4.8-1.5-11.2-3.7-7-5.2-12.7-6.1-18.1l-7.1-21.8c-2.2-1.6-4.6-2.5-7.2-1.9-4.6,3.7-5.8,6.4-3,7.8h0Z"/>
  <path id="fingerparthighlight1" class="Finger1342Fill"  style={{
          fill: props.colors.Finger1342Fill,
          stroke: props.colors.Finger1342Fill,
        }} d="M118.56,22.03h0c-2.9,1.5-3.1,10.6-2.2,14.8,1,2.1,3.1,3.1,5.6,3.6-.6,4.7-.3,9.3,1.3,13.5l-1,4.4c-2,4.9-1.4,8.1,3,8.9,1.2-3.7,3-5.8,5.2-6.2,3-.5,5.3.3,7.2,1.9-.9-5.9-3.4-10.8-7.8-14.4-1.2-7.9-2.7-14.7-4.7-20.7-3.2-2.8-5.6-7.8-6.6-5.9h0s0,.1,0,.1Z"/>
</g>
<g id="Highlights2">
  <path id="fingerparthighlight12" class="MHK1342Fill"  style={{
          fill: props.colors.MHK1342Fill,
          stroke: props.colors.MHK1342Fill,
        }} d="M174.56,207.23c-1.55-.8-3.08-1.58-3.94-2.73.02,1.27.08,2.54.14,3.81.49.31.86.83.9,1.58.2,3.89,1.01,7.65.41,11.55-.45,2.92-1.62,5.64-2.7,8.38.02.13.02.28,0,.43-.74,9.56-2.81,18.92-5.07,28.22,0,.04,0,.07-.01.11-.14.67-.3,1.34-.46,2.01-.32,5.71-1.69,11.37-3.47,16.83-.47,3.56-1.32,7-2.43,10.37-.67,3.78-1.5,7.52-2.28,11.29-.1.49-.2.97-.31,1.46.04.16.06.33.06.51-.04,3.93-1.13,7.65-1.89,11.47-.68,3.39-.72,6.91-1.97,10.18-.15.4-.4.75-.7,1.01-.33.93-.67,1.86-1,2.79.15.22.27.47.34.75.83,1,1.19,2.31,1.46,3.61.4.26.72.65.81,1.12.27,1.37.86,2.65,1.3,3.98.32.98.51,1.98.52,2.99.24.34.38.75.38,1.16,0,1.45-.18,2.92-.53,4.33-.07.27-.2.51-.37.72-.01.13-.02.26-.03.39.41,1.73.77,2.24,2.65,3.13,4.8-2.8,2.7-6.4,2.1-11.1l-1.1-7.2c-1.1-7-.6-14.1,1.4-20.9,7.8-25.2,8.9-60.6,12.3-68.7l1.7-8.7c2.5-3.1,4.3-6.4,4.8-9.7,2.2-3,1-8.2-3-15.1Z"/>
  <path id="fingerparthighlight11" class="MHK1342Fill"  style={{
          fill: props.colors.MHK1342Fill,
          stroke: props.colors.MHK1342Fill,
        }} d="M125.26,350.03h0c1.2-1.7,1.7-3.9,1.3-6.7l-2.2-3.4c-2.7-4.5,1.2-9.1,5.7-13.6,13.6-25,19.9-86.6,13.5-97.1-4-6.7-7.7-13.3-7.1-17.7,2-6.6,4.3-12,6.9-16.3,2.7.5,5,.3,7.3.2,3.3-1.8,6.1-2.8,7.8-2l8.6,4.9c4.5,3.8,5.9,7,4.2,9.8,3.8,7.7,2.8,14.7-.8,21.5l-20.2,96.8c4.1,9.9,6.7,19.5-1.7,26.9-2.5.2-4.9-2-7.2-5.5-3.9,4.3-6.2,4.6-8.4,4.5-2-1-4.5-1.8-7.7-2h0v-.3h0Z"/>
  <path id="fingerparthighlight10" class="MHK1342Fill"  style={{
          fill: props.colors.MHK1342Fill,
          stroke: props.colors.MHK1342Fill,
        }} d="M85.96,333.03h0c-6.8-17.2-13.2-33-18.1-42-9.4-1.8-12.2-11.2-10.8-17.1l5.3-1.9c6.9,2.1,4.8-3.3,7.2-5.1,4.7-3.5,9.1-7.1,13.4-10.8,5.3,2.1,8.6,5.8,8.3,12.5-.6,4.8.5,10.4,4.7,17.8,4,15,7.3,26.3,9.9,35.1,2,3.3,4.5,6.3,8.1,8.4,1.9,4.1,2.6,10,2.6,17-.3,5-1.9,9.5-5.4,13.2-9.2,2.6-17.1,3.1-22.5-.5-5-.3-9.1-7.5-3.3-18.4l.4-8.1h0s.2-.1.2-.1Z"/>
</g>
<g id="outlines">
  <path id="outlinepart1" class="Hand_lat_rechts1342_cls4" d="M207.96,476.13c-15-14.3-27.4-120.4-27.7-123.1-3.9-49.1,3.5-83.4,9.4-124.6,7.3-51,1.9-61.3-18.1-109.4-19.3-46.4-31.6-83-40-101.8-8.4-18.8-23-16.1-27.5,7.8-4.7,24.7,24,97.8,22.7,137.6-1.3,40.5,0,25.7-13.2,88.2-.2,1.1-8.3,14.4-11.3,8.8s-.6-1.1-1.1-1.6c-5.2-5.6-12.1-18.9-18.6-37.9-6.8-19.8-6.5-20.6-7.8-24.5-6.2-18.2-12.1-24.9-23.1-39.5-3.8-5-6.6-10.2-10-14.3-21.4-25.3-53.4-14-31.5,38.7,31.3,75.6,35.4,111.7,33.9,128.5-2.8,32,9.2,106.4,33.5,122.9l6.2,5.8,2.8,3c1.9,2,3.3,4.5,4.1,7.1l8.7,28.3"/>
</g>
<g id="outlinehighlight">
  <path id="outlinehighlightpart1" class="weichteile1342Fill"  style={{
          fill: props.colors.weichteile1342Fill,
          stroke: props.colors.weichteile1342Fill,
        }} d="M93.76,474.73c-3.1-7.8-3.9-25.6-14.1-33.9-22.1-17.2-37.8-55.9-40.2-137.2,0-23-2.6-50.3-33.9-128.3-20.8-51.7,22.2-62.2,39.7-36.5,3.1,4.6-6.3-8.3,19.8,27.7,9.98,19.79.29-1.85,13.6,28.7,9.1,23.4,15.9,45.4,19.3,50.6s5,5.5,5.3,6.1c3.3,5.6,6.9-10.2,7.2-11.3,14.6-62.9,9.5-37.2,11-77.9,1.4-40.1-27.1-116.1-21.9-141,5-24.1,23.6-29.1,32.9-10.2s19.9,53.6,41.2,100.3c22.1,48.4,31,57.5,22.9,108.9-6.5,41.5-16.3,89.4-12,138.9.2,2.8,14.8,100.8,31.3,115.2h-8c-15-14.3-27.4-119-27.7-121.8-3.9-49.1,3.5-83.4,9.4-124.6,7.3-51,1.9-61.3-18.1-109.4-19.3-46.4-31.6-83-40-101.8-8.4-18.8-23-16.1-27.5,7.8-4.7,24.7,24,97.8,22.7,137.6-1.3,40.5,0,25.7-13.2,88.2-.2,1.1-8.3,14.4-11.3,8.8s-.6-1.1-1.1-1.6c-5.2-5.6-12.1-18.9-18.6-37.9-6.8-19.8-6.5-20.6-7.8-24.5-6.2-18.2-12.1-24.9-23.1-39.5-3.8-5-6.6-10.2-10-14.3-21.4-25.3-53.4-14-31.5,38.7,31.3,75.6,35.4,111.7,33.9,128.5-2.8,32,9.2,106.4,33.5,122.9l6.2,5.8,2.8,3c1.9,2,3.3,4.5,4.1,7.1l8.3,26.9h-5.2.1Z"/>
</g>
</svg>
  );
}

export default RechteHandLatSitzend1342;
