import style from "./style.scss";

function Brustwirbelsaule75VdOblStehend1505(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 242.54 559.56">

<g id="BG">
  <path id="BG_white" data-name="BG white" class="Brustwirbelsaule75_vd_obl_stehend1505_cls3" d="M75.9,14.68l-54.02,55.5-4.58,43.5c-10.48,1.46-13.39,4.66-15.73,8.17-4.14,10.25,1.2,14.2,1.2,14.2l2.14,15.52-.12,35.71v36.44l1.57,40.45,1.53,65.53,6.16,47.27v29.74l5.66,52.17-8.42,24.46c1.42,3.69,16.82,23.7,30.65,33.71,7.62,6.03,16.44,11.28,25.57,14.87.35,2.62,3.61,6.05,6.7,9.93,2.03,2.55,3.74,5.57,6.34,7.03,1.1.62,1.05.94,2.67,3.64,1.27,2.11,6.59,3.88,6.95,4.01,19.59,7.4,45.68,2.31,46.22-27.08,10.16-6.65,27.95-25.22,34.17-33.39l34.83-26.09,21.14-20.08,7.92-66.4,8.08-57.93-1.7-63.37-8.37-23.93,6.11-34.28-8.12-26.58-9.19-18.85-16.36-39.56,16.06-56.38v-23.06l-43.32-22.35L128.39,0l-52.49,14.68Z"/>
</g>
<g id="Brustwirbelsäule75">
  <g id="Ribsparts2" class="Brustwirbelsaule75_vd_obl_stehend1505_cls5">
    <g id="Ribgroupe2">
      <path id="ribspart30" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M19.31,372.74c11.95-3.13,24.45-4.78,37.02-4.69,13.58.09,28.57,1.29,44.26,3.03l-4.4-19.79h-.01c-5.16-1.3-10.66-2.17-16.45-2.66-12.88-1.1-25.9.04-38.34,2.92-9.11,2.12-17.98,4.33-26.52,6.7"/>
      <path id="ribspart29" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M13.2,344.77c1.35-.3,2.71-.58,4.07-.86,11.72-2.34,23.26-3.86,34.43-3.74,5.9.07,11.8-.09,17.66-.66l36.14-3.52,8.28-17.1h.01l-34.43.92c-2.97.08-5.94.12-8.91.02-12.26-.4-25.78.89-39.94,3.02-7.05,1.06-13.99,2.58-20.73,4.53"/>
    </g>
  </g>
  <g id="Intervertebralgelenke">
    <path id="Intervertebralgelenk11" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M66.14,501.11c-1.51-5.88-.93-11.31,1.17-16.42.83-4.58,2.6-6.57,4.81-7.32l7.15,8.57c14.5,12.44,31.73,15.19,50.32,13.16.88,2.25,1.23,4.64,0,7.51.66,1.3,1.06,2.55.79,3.68l-7.93,11.33c-12.67,4.17-24.56,6.39-34.88,4.82-10.19-5.73-17-14.5-21.42-25.34h-.01Z"/>
    <path id="Intervertebralgelenk10" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M74.43,458.37l-2.44-10.97c-.1-7.91,1.63-13.14,5.12-15.79h2.86l50.48,21.83c1.49,3.63,1.42,7.9,0,12.7l-9.98,11.96-35.52-5-10.53-14.72h0Z"/>
    <path id="Intervertebralgelenk9" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M86.12,406.13c-1.88-5.37-1.15-9.65,1.44-13.15l27.73,17.28,28.02-.89c1.84.79,2.25,2.96,1.38,6.33l-4.24,10.26-34.8-1.61-14.52-9.46-5.01-8.77h0Z"/>
    <path id="Intervertebralgelenk8" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M90.49,372.97c-1.12-4.8-1.66-8.95-1.24-12.14.37-2.51,1.89-3.94,4.18-4.64l57.07,12.2c1.79,3.82,1.45,8.25,0,12.96l-18.95,5.35-33.68-7.5-7.38-6.25v.02h0Z"/>
    <path id="Intervertebralgelenk7" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M93.43,333.89c-4.87-4.9-5.82-9.56-2.28-13.92l9.53-1.07,49.83,7.5c2.32,3.14,2.9,6.8,2.18,10.89-18.23,6.56-38.09,4.86-59.23-3.39h-.03Z"/>
    <path id="Intervertebralgelenk6" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M93.43,295.87c-1.95-5.17-2.97-9.82,1.35-11.42h3.09l20.11-5.35,25.7,5.89c4.45,1.17,8.34,2.57,8.03,5.75v8.88h-45.69l-12.6-3.75h.01Z"/>
    <path id="Intervertebralgelenk5" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M95.49,258.21c-2-3.76-2.75-7.43-2.07-10.99.76-1.79,3.59-2.06,7.06-1.86l43.55-8.21,3.91,1.67c2.42,2.64,3.4,6.23,2.55,11l-15.2,12.93-39.8-4.54h.01-.01Z"/>
    <path id="Intervertebralgelenk4" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M90.49,218.04l-2.4-8.37c-.35-2.54,1.62-5.43,5.1-8.57l42.82-7.32,6.5,1.25c3.25,3.59,4.65,7.21,3.49,10.88l-7.14,6.25-48.37,5.88h0Z"/>
    <path id="Intervertebralgelenk3" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M88.05,171.57c-5.11,1.49-8.86,1.73-8.44-1.86-.56-3.76.37-7.43,1.78-11.07l14.16-7.85,30.29-6.43c6.29,2.44,9.65,8,9.35,17.49l-9.71,6.43-37.43,3.29h0Z"/>
    <path id="Intervertebralgelenk2" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M73.72,123.45c-2.67-2.91-3.23-6.15-2.43-9.62.5-2.21,3.43-6.91,6.18-11.42l22.57-7.85,20.08,2.5c3.53,4.94,5.94,9.68,5.53,13.92l-5.89,7.85-46.05,4.62h.01Z"/>
    <path id="Intervertebralgelenk1" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M66.93,66.19c-2.79-2.46-3.04-6.43-3.03-10.53l18.03-19.99,18.11-4.1,6.5,6.01c-.32,3.44.67,6.25,2.9,8.47,1.26,1.27,2.3,2.79,2.72,4.53.58,2.39.32,4.8-.95,7.21l-6.08,6.43-23.38-6.43-14.81,8.39h-.01Z"/>
  </g>
  <g id="Wirbelkörper">
    <path id="wirbelkörperpart12" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M134.66,528.42c.54,8.37-1.19,16.21-7.5,23.02-9.28,6.72-18.92,8.86-28.91,6.43-10.38-1.42-14.33-4.01-16.06-9.38-5.9-4.19-10.56-8.94-11.78-15.26-2.3-6-2.7-11.9,0-17.67-5.58-5.05-5.6-9.78-4.28-14.46,3.02,4.54,6.97,8.88,12.58,12.85,7.4,10.49,17.1,12.97,32.5,6.96,8.11-1.19,14.71-5.73,20.5-12.04,4.63-9.27,10.36-12.69,16.6-13.39,9.82,2.34,5.38,12.37-2.68,24.36l-10.97,8.57h0,0Z"/>
    <path id="wirbelkörperpart11" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M73.89,483.97c-3.14-3.09-1.88-7.97,0-13.11,2.54-2.91,1.71-6.67,0-10.71-2.16-2.81-2.55-8.03,0-6.43,2.4,5.49,6.8,9.72,13.39,12.58,7.09,4.87,14.57,8.21,23.57,5.63,9.6.7,16.19-2.86,20.88-9.1,5.93-3.31,12.01-3.69,18.21-1.88,4.27.58,6.02,2.75,5.65,6.26-.32,2.97-2.14,5.56-4.68,7.15l-7.92,4.95c-1.12.7-2.33,1.18-3.58,1.59-4.29,1.41-6.85,5.5-7.42,12.72-1.5,5.88-5.32,10.12-12.04,12.32-10.59,2.03-21.62-.58-32.93-6.15-6.84-4.58-12.44-9.51-13.11-15.79v-.02h-.02Z"/>
    <path id="wirbelkörperpart10" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M79.25,437.94c-1.98-4.41-1.03-7.84,2.01-10.58,4.36-6.18,5.89-11.94,4.56-17.27-2.76-3.8-2.86-5.61,0-5.22.66,4.27,4.96,7.1,10.04,9.64,9.42,6.38,20.01,8.76,31.06,9.64,10.16.34,15.63-2.82,17.8-8.44,6.02-4.44,12.87-6.08,20.75-4.28,5.73,1.01,8.79,4.4,9.38,9.98-2.99,5.83-7.2,10.25-12.32,13.58-6.42-1.79-11.85-.2-16.47,4.28-2.08.59-3.13,2.42-3.49,5.08-1.11,7.82-5.35,10.64-10.58,11.91-8.34,1.8-16.36,2.5-23.95,1.88-9.95-.59-17.72-5.32-24.23-12.45l-4.56-7.76h.01-.01Z"/>
    <path id="wirbelkörperpart9" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M89.02,398.44c-.79-1.88-1.3-3.69-1.44-5.46s.58-3.61,1.85-4.92l.26-.26c.95-.99,1.45-2.34,1.37-3.71-.41-6.95-1.32-11.33-2.7-13.34-.78-2.48-.36-3.1,1.61-1.33,3.22,3.95,8.09,6.56,14.72,7.76,14.52,5.64,27.9,7.4,39.75,4.01,2.85-.66,5.12-2,6.54-4.33.76-1.25,2.15-1.97,3.61-1.73,8.16,1.35,17.01,4.84,26.26,9.68,4.44,1.41,8.7,2.26,12.7,2.28,3.46.02,6.66,2.18,7.86,5.41.58,1.54.91,3.32,1.06,5.34.08,1.27-.29,2.57-1.18,3.5-2.66,2.75-7.62,2.96-14.56,1.01-8.34-2.45-11.62-.69-13.25,2.49-.14,2.97-.89,4.38-2.14,4.45-.97.06-1.77-.78-1.89-1.74-1.37-10.67-5.08-15.42-10.43-16.05-4.36-.54-6.78,4.45-8.29,12.04-2.1,6.07-8.51,8.97-17.93,9.64-7.34,1.25-14.53.88-21.57-1.07l-12.44-5.38c-4-1.73-7.39-4.6-9.75-8.27h-.02v-.02Z"/>
    <path id="wirbelkörperpart8" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M95.31,361.9c-4.11-2.67-3.58-5.63-1.07-8.7.71-7.74.14-11.9-.94-14.59-4.09-7.28-3.41-9.21,0-8.03,2.12,2.43,5.69,3.74,12.72,4.69,15.7,3.08,29.03,4,38.99,1.88,4.28.11,6.91-2.24,8.93-5.49,3.98-1.17,8.04,0,12.18,3.49,6.32,4.64,12.96,7.26,19.94,7.63l7.09.38c2.54.14,5.06.83,7.19,2.21,1.96,1.27,3.38,2.97,3.95,5.31.23.95.54,1.88.84,2.81.49,1.56.28,3.05-.56,4.48-1.12,1.91-3.15,3.13-5.34,3.43-4.59.62-9.34.5-14.5-1.63-.83-.35-1.77-.42-2.62-.13-3.26,1.12-4.93,2.98-6.09,5.07-1.73,2.08-3.38,2.9-4.93,1.78-1.02-.73-1.42-2.08-1.15-3.31,1.53-7.09.85-11.48-3.29-11.59-3.49-.38-5.77,1.33-6.96,4.95l-1.07,4.41c-.08,6.49-2.19,9.84-6.56,9.64-20,3.77-38.94,1.06-56.75-8.7h.01-.01Z"/>
    <path id="wirbelkörperpart7" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M97.18,322.68c-4.93-.66-5.42-2.45-3.08-4.95,2.36-3.85,1.94-10.31,0-18.21-3.45-2.45-4-4.69-1.07-6.69,3.32,2.51,9.56,2.5,16.19,2.14,9.65-.88,21.11,0,33.2,1.47,3.97-.34,6.6-1.96,8.27-4.5.85-1.3,2.09-2.3,3.56-2.82,3.95-1.42,8.04-2.25,12.22-2.58,3.56-.29,7.14.41,10.28,2.09,8.53,4.59,18.31,7.02,29.39,7.13,3.2-.34,5.75,2.04,7.5,7.76,1.63,3.91-.36,6.8-5.53,8.83-2.06.81-4.33.83-6.45.23-4.63-1.3-9.53-1.23-14.65.04-6.08,2.57-8.23,6.81-9.24,11.54-.25,1.18-1.21,2.18-2.42,2.26-.71.05-1.41-.18-2.1-.72-1.08-.85-1.59-2.26-1.5-3.63.7-10.95-1.71-15.79-7.09-14.78-3.69,1.77-5.64,4.63-5.22,8.97.26,2.98-.36,5.43-1.66,7.46-2.06,3.21-5.76,4.98-9.58,4.98h-8.25c-1.29,0-2.57.12-3.85.31-6.92,1-13.8.62-20.59-1.59-1.15-.37-2.3-.75-3.47-1.03l-14.86-3.71v.02-.02Z"/>
    <path id="wirbelkörperpart6" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M102.81,284.53c-5.54.69-6.96-1.74-5.22-6.56.76-4.51.64-8.98-.36-13.41-.37-1.66-1.21-3.19-2.45-4.35-2.43-2.27-1.36-3.62,1.62-4.46l20.31-1.97c.29-.02.58-.1.85-.18,8.2-2.74,16.24-2.43,24.22-.54,1.65.38,3.35-.18,4.48-1.44,3.58-4.01,6.85-6.49,9.7-6.84,11.31-4.3,22.46-6.38,33.46-5.89,14.72,2.27,24.84,1.56,26.77,8.44l1.47,9.1c-.25,4.62-4.99,7.27-12.6,8.68-.97.18-1.98.19-2.96-.05-6.88-1.71-12.92-.14-17.92,5.42l-2.68,7.9c-1.13,1.66-2.31,2.28-3.52,2.2-1.56-.11-2.76-1.49-2.91-3.05-.91-9.63-4.4-13.22-9.1-14.01-2.86.12-5.13,1.39-6.41,4.58-.52,1.3-.53,2.76-.14,4.11,2.08,7.22.99,11.96-4.29,13.39l-6.33-.35c-.58-.04-1.14.06-1.69.22-4.31,1.31-10.06,1.39-16.3,1.06-.47-.02-.94-.1-1.39-.2-8.56-1.97-17.42-2.6-26.61-1.79v.02-.03Z"/>
    <path id="wirbelkörperpart5" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M102.27,246.79c-3.95,1.44-5.32-.87-5.76-4.56.14-5.55-1.02-11.2-3.26-16.92-.64-1.62-1.62-3.1-2.84-4.36-2.98-3.06-3.47-5.22.72-5.63,5.44.13,10.69-.7,15.69-2.55,12.08-3.1,22.52-4.72,29.05-2.81,3.79-.36,6.91-1.89,9.44-4.45,1.57-1.59,3.8-2.33,5.96-1.78,5.91,1.49,12.97,1.75,20.47,1.55,6.21-2.6,10.46-5.75,13.38-9.25.95-1.14,2.13-2.07,3.5-2.62,2.63-1.06,5.71-1.9,9.36-2.45,2.01-.3,3.94-.9,5.87-1.55,4.12-1.39,6.73-.22,7.92,3.43l.95,5.56c.7,4.07-.55,8.29-3.55,11.15-.06.06-.13.13-.2.19-.5.47-1.07.88-1.68,1.19-5.08,2.68-15.6,5.61-20.77,6.97-1.83.48-3.73.64-5.6.47-9.51-.85-18.09.69-25.37,5.5-1.3,2.02-1.81,3.89-1.24,5.58.65,1.89.88,3.92.29,5.83-1.2,3.83-2.85,5.71-5.02,5.26-.81-.17-1.6-.31-2.42-.28-2.44.12-7.13.34-7.13.34-12.01,2.82-24.64,4.81-37.74,6.15v.02l-.02.02Z"/>
    <path id="wirbelkörperpart4" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M94.24,204.5c-3.65.34-5.37-1.19-3.88-5.76.07-5.94-.6-11.63-2.27-17-3.31-4.47-3.83-8.81-.94-12.98,1.86-2.14,4.65-2.75,7.63-3.08l27.7-.81c4.36.06,7.74-1.09,10.01-3.61,1.83-2.02,4.04-3.64,6.63-4.48,5.87-1.89,15.69-5.06,20.56-6.63,1.96-.64,3.87-1.41,5.72-2.31l15.23-7.46c2.27-1.11,4.75-1.8,7.27-1.8,4.03,0,7.61,1.65,10.06,7.56,3.64,7.14,1.66,12.63-5.49,16.6l-7.61,3.75c-1.03.52-2.09,1.01-3.19,1.38-6.19,2.13-7.73,6.66-7.15,12.26-.34,3.09-1.42,3.82-2.85,3.51-1.18-.26-2.14-1.13-2.69-2.21-1.72-3.33-3.97-5.01-6.51-5.72-7.32-1.65-10.05,1.42-12.85,4.41-2.52,2.5-3.91,5.11-2.94,7.9,1.33,6.92.72,10.17-1.88,9.64-3.04-2-7.06-2.3-11.78-1.33-4.16.58-8.29,1.97-12.42,3.89-2.86,1.33-6,1.91-9.15,1.66-5.82-.46-11.59.22-17.26,2.61h.05Z"/>
    <path id="wirbelkörperpart3" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M83.8,160.33c-2.78,1.72-3.76-.93-4.01-5.35,1.32-9.47-.54-17.57-5.08-24.5-4.13-4.58-4.36-7.69-1.47-9.64l18.74-6.15c5.47-1.95,12.01-2,19.8.22,4.7,1.33,9.87-.53,12.2-4.83.1-.17.18-.34.26-.5.82-1.65,2.45-2.73,4.28-2.84l17.5-1.02c4.39-.25,8.68-1.63,12.21-4.27,2.7-2.02,5.11-4.59,7.2-7.73,1.25-1.86,2.78-3.55,4.58-4.88,3.67-2.72,7.45-3.5,11.37-2.13,4.01,2.73,5.73,5.82,5.35,9.23.1,4.5-3.44,9.72-8.37,15.23-1.68,1.88-4.01,3.05-6.51,3.27-4.31.38-8.64,2.64-12.98,5.76-.91.66-1.38,1.78-1.19,2.9.64,3.56.25,7.03-1.06,10.46-1.19,3.13-2.62,4.93-4.95,1.47-.96-2.36-2.39-4.86-4.31-7.55-1.42-1.98-3.58-3.44-6.01-3.69-2.62-.28-5.16.66-7.61,2.8-2.01,2.16-3.61,4.53-1.2,8.83,1.13,4.31,1.83,8.35-.13,10.71-2.99,1.94-8.29,2.76-14.99,2.94l-21.68,5.08c-4.01.13-7.99,2.25-11.91,6.15v.02h-.03Z"/>
    <path id="wirbelkörperpart2" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M77.77,105.86c-3.63,3.4-5.84,2.6-6.49-2.81l-4.48-12.04c-5.46-7.62-5.02-14.56-2.94-21.29,1.86-5.72,6.5-9.69,12.18-12.98,9.64-3.26,18.1-2.81,25.1,2.24,1.81,1.31,4.25,1.53,6.26.53,2.55-1.26,4.41-3,5.93-5.02.55-.73,1.25-1.36,2.02-1.86,2.66-1.74,5.7-2.7,9.01-3.11,3.75-.14,7.09.88,9.93,3.4,2.19,1.94,3.41,4.75,3.37,7.67s-.44,5.88-1.13,8.98c-.36,1.65-1,3.22-1.83,4.69-1.25,2.2-2.3,4.25-3.69,6.09-.9,1.2-2.33,1.91-3.83,2-1.85.11-2.34,1.5-2.55,3.17.77,4.25.49,8.26-.67,12.04-.55,3.49-1.5,5.32-3.21,4.01-5.66-4.75-12.7-5.59-21.29-3.88-7.03.77-14.3,3.92-21.68,8.16v.02h0Z"/>
    <path id="wirbelkörperpart1" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M69.43,58.15c-3.68,5.29-6.05,4.53-8.03,1.96l-.55-1.78c-.85-2.78-1.94-5.49-3.46-7.97-1.96-3.19-1.9-6.11-.28-8.81l8.57-13.56c4.56-6.02,11.66-10.34,20.35-13.56,6.27-1.69,12.79-2.56,19.63-2.36,2.37.07,4.64-.79,6.41-2.38,5.41-4.86,11.32-7.67,17.68-8.64.06,0,.11,0,.17,0,5.42.46,7.19,7.56,2.73,10.66l-11.65,8.1c-3,2.49-4.29,5.7-3.93,9.59l1.78,6.25c-.1,2.6-1.31,2.45-3.03,1.07-1.75-5.23-4.1-8.64-7.14-9.82-2.03-.6-3.22.28-3.71,2.37-.18.78-.07,1.6.23,2.33l1.96,4.68c.38.91-.7,1.73-1.47,1.12-3.38-2.72-7.36-3.57-12-2.27-5.37,1.6-11.27,6.73-17.31,12.67l-6.96,10.35h.01,0Z"/>
  </g>
  <g id="Ribparts" class="Brustwirbelsaule75_vd_obl_stehend1505_cls5">
    <g id="Ribsgroupe">
      <path id="ribspart28" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M218.98,61c-15.79,3.8-90.65,13.88-110.86,14.98-19.1,3.71-36.85,8.63-48.88,18.59-17.91,8.26-28.9,15.54-28.39,21.21,2.31,8.05,7.14,11.27,18.41,2.19,17.94-14.41,35.84-25.82,53.62-28.11l52.87-1.47,54.47-4.57"/>
      <path id="ribspart27" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M113.11,26.44c-.52-1.51-.29-3.16.63-4.51,1.54-2.28,3.86-3.68,7.25-3.82l22.54-3.5c1.71-.26,3.44-.4,5.16-.29s3.17.37,4.46.85c2.79,1.05,4.42,3.65,4.33,6.36-.4,10.66-4.34,19.36-9.38,25.88l-17.56-2.14c3.5-3.47,6.57-6.79,8.31-9.64,1.89-3.13.95-7.18-2.42-9.05-1.89-1.05-4.22-1.32-7-.81-1.74.32-3.26,1.26-4.38,2.49-1.78,1.97-3.79,3.37-5.96,4.28-1.81.76-3.95-.11-4.54-1.83l-1.44-4.28h.01-.01Z"/>
      <path id="ribspart26" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M156.69,30.35h12.42c4.76,0,9.51.89,13.75,2.91s7.91,5.01,10.79,8.82c1.72,2.27,2.78,4.93,3.28,7.68h0l-18.57.12c.22-5.02-1.24-3.29-5.52-5.82-5.34-2.03-13-3.51-20.35-4.06,1.33-3.06,3.53-6.03,4.18-9.65h.02Z"/>
      <path id="ribspart25" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M214.44,430.02c-3.15-3.23-6.39-6.21-9.76-8.89-6.19-4.93-14.06-7.2-21.94-6.43-8.53.83-16.23,2.21-22.5,4.52-2.6,1.41-4.09,4.03-4.64,7.67-.02,2.6.18,5.05.81,7.22.99,3.46,4.3,5.69,7.88,5.42,4.29-.31,8.71-2,13.23-4.64,1.09-.64,2.28-1.09,3.53-1.29,5.89-.89,11.45.31,16.78,2.94,1.54.76,3.13,1.43,4.7,2.1,6.19,2.62,12.42,8.62,18.63,14.77,0-.06.02-.12.04-.18,1.78-8.37-.82-17.1-6.78-23.23h0v.02Z"/>
      <path id="ribspart24" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M219.83,68.5l-17.77-2.19c-.68-.08-1.39-.07-2.07.04l-40.68,6.33c-7.85,1.91-17.44-.7-27.83-5.29-1.83-.82-3.83-1.18-5.81-1.11-15.94.56-27.72,2.39-37.12,4.94-3.5.95-7.09,1.47-10.72,1.47h-10.15c-5.86,0-11.59-2.3-15.62-6.66-6.33-6.88-4.29-14.38,3.11-22.26h0c5.54-6.29,12.96-8.13,22.26-5.49.32.1.66.17.99.2l26.42,3.55c36.95,5.54,71.04,9.58,93.54,7.57.83-.07,1.64-.32,2.42-.66,6.54-2.91,13.58-3.51,20.97-2.55"/>
      <path id="ribspart23" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M205.62,390.39c-1.3-.39-2.58-.81-3.86-1.26-18.22-6.37-42.49-5.69-50.4,1.6l9.86-11.09,19.99-10.49c9.17-.34,18.54.32,27.55,2.3"/>
      <path id="ribspart22" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M226.53,442.97c-7.08,10.96-16.81,20.35-27.21,29.36l-14.05,10.95c-3.38,1.73-5.74-.35-7.36-5.2h0c-.95-7.68,3.43-14.44,10.7-20.67,12.74-14.04,26.63-29.08,34.94-39.29"/>
      <path id="ribspart21" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M18.96,149.79l.61-2.36c.24-.91.69-1.77,1.33-2.46,7.3-7.84,17.18-14.92,28.7-21.51,15.52-10.78,33.79-17.24,53.8-20.96,25.97-6.31,54.46-8.75,84.59-8.63l21.01.7-3.29,24.99-31.44-4.39c-9.25-.36-27.57-.35-45.69,1.47-13.71,1.37-27.09,5.12-39.47,11.09-14.44,6.97-27.39,14.59-38.71,22.97l-20.3,14.33c-1.62,1.14-3.64,1.71-5.6,1.35-3.15-.59-4.8-2.97-5.66-6.29-.87-3.32-.75-6.91.13-10.29h-.01,0Z"/>
      <path id="ribspart20" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M213.01,356.97c-3.95-2.18-8.94-3.87-14.85-5.13l-49.59-13.33,13.81-14.09h0l50.6,11.8c3.67.91,7.23,1.84,10.52,3"/>
      <path id="ribspart19" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M233.27,386.79c-8.09,12.26-16.81,23.62-26.45,33.64-30.86,36.3-60.49,71-76.68,88.19-13.44,14.39-28.66,22.72-45.67,25.02-1.43.19-2.73-.87-2.84-2.33l-1.07-14.65c-.11-1.38,1.12-2.45,2.44-2.19,6.19,1.26,12.31.82,18.36-2.02,16.47-10.34,39.81-39,64.77-72.02,22.82-26.02,44.78-51.67,55.25-72.42.55-1.09.53-2.42-.08-3.47-1.75-3-4.56-5.51-8.29-7.57"/>
      <path id="ribspart18" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M153.82,289.35c.75-7.44,7.32-8.32,15.53-7.36l45.26,1.33c6.01.18,11.96,1.96,16.87,5.54,7.32,5.34,10.83,13.1,10.54,23.26-1.63,29.62-21.98,61.62-44.69,93.93-8.03,13.89-25.01,31.01-45.26,49.32-3.18,2.87-6.52,5.56-9.98,8.05-19.33,13.87-41.21,26.18-64.62,37.55-1.25.61-2.74-.13-3.04-1.51l-3.32-14.77c-.26-1.17.26-2.36,1.27-2.94l42.02-24.3c7.54-4.36,14.72-9.36,21.41-15,21.21-17.84,39.94-35.58,52.14-55.42,18.29-28.44,32.68-53.55,35.38-68.71.86-4.82-1.39-9.57-5.53-12.01-4.48-2.63-9.68-3.98-15.36-4.45l-43.36-2.24c-4.51-1.6-6.08-5.12-5.22-10.26h-.04Z"/>
      <path id="ribspart17" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M241.64,296.1c-1.43,2.69-3.15,5.23-5.1,7.57l-48.82,58.73c-3.43,4.12-7.18,7.94-11.24,11.41-9.62,8.2-19.32,15.84-29.13,22.67-6.81,4.74-13.3,9.94-19.46,15.54-27.53,25.01-52.81,44.41-72.77,50.65-1.47.46-3.01-.44-3.37-1.97l-4.76-19.69c-.31-1.27.35-2.6,1.56-3.03,9.15-3.34,35.13-21.77,74.57-52.25,3.07-2.37,6.07-4.86,8.99-7.43,32.85-29.15,61.56-56.75,84.07-82.08,2.29-2.57,4.75-4.96,7.24-7.34,1.21-1.17,2.18-2.3,2.92-3.41,1.3-1.97.79-4.66-1.09-6.07-7.68-5.77-17.02-9.81-27.55-12.58-1.83-.48-3.66-.97-5.47-1.53l-47.94-14.82,24.29-9.12h-.02l29.62,6.71c15.36,3.89,29.12,9.25,39.93,17.28"/>
      <path id="ribspart15" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M20.6,188.86c-1.51-4.42-.61-8.28,3.45-11.42,1.31-1.01,2.61-2.04,3.82-3.16,10.23-9.5,21.09-17.6,32.58-24.31,9.77-5.71,20.37-9.87,31.38-12.56,24.81-6.07,53.81-9.39,84.66-11.49,11.97-1.2,14.18.01,25.11,1.37l7.4,17.08c-2.67,1.98-.61.94-7.4,1.54-34.47,1.98-63.93,6.96-92.33,12.57-22.13,5.42-50.1,21.06-79.92,39.96-1.43.91-3.35.42-4.15-1.06l-4.59-8.51h-.02.01Z"/>
      <path id="ribspart14" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M239.38,262.1c-14.12,19.6-37.09,38.25-60.95,56.8l-116.56,79.23c-6.57,4.68-12.2,5.89-16.81,3.33-1.83-1.02-3.27-2.64-4.31-4.5l-5.78-10.24c-2.62-5.24-1.99-9.58,2.01-12.97,26.18-18,57-36.57,90.33-55.46,43.33-27.3,84-53.88,99.66-73.75,1.05-1.33.82-3.28-.48-4.36-7.8-6.43-18.37-11.35-31-15.14-12.13-2.25-24.29-2.92-36.46-2.19-8.97,2.57-15.08,2.18-16.91-2.6l-.12-5.89h0c-.63-6.67,4.21-10.16,13.68-10.95,13.24,0,26.51,1.53,39.81,4.52,12.94,3.79,26.45,9.66,40.21,16.56"/>
      <path id="ribspart13" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M235.26,221.02c-25.38,12.87-49.36,24.51-70.14,33.34-5.85,2.49-11.56,5.3-17.11,8.43l-106.7,60.16c-1.93.81-3.71,1.27-5.34,1.43-5.52.53-10.44-3.58-11.26-9.18l-.92-6.21c-.79-4.44,1.02-8.81,4.53-13.15,3-3.7,6.78-6.66,10.94-8.91,42.46-22.91,82.82-44.19,110.26-55.59l72.58-32.45c-5.19-2.03-12.84-4.27-21.43-6.59-5.8-1.56-11.71-2.7-17.68-3.37-12.02-1.31-23.27-3.2-33.2-6.07h0c-3.5-1.5-3.92-6.2-1.17-8.44v.02c.61-.5,1.26-.97,1.81-1.55,1.91-2.01,4.08-2.84,6.48-2.48l8.93.49c8.72.48,17.4,1.59,25.96,3.33,14.44,2.94,28.28,6.42,41.38,10.58"/>
      <line id="ribspart12" class="Brustwirbelsaule75_vd_obl_stehend1505_cls2" x1="233.18" y1="194.79" x2="222.07" y2="198.89"/>
      <path id="ribspart11" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M216.4,162.15c-7.33,5.83-12.1,4.99-26.05,8.17l-60.08,11.59c-31.08,6.39-58.26,17.25-81.71,32.34-3.51,2.26-7.07,4.44-10.74,6.42-15.83,8.56-25.11,16.94-25.05,25.05.72,7.2,3.86,10.83,11.91,7.26l58.34-30.54c25.58-12.54,58.81-19.81,96.2-24.24,23.72-3.89,38.27-7.21,48.71-15"/>
      <path id="ribspart10" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M18.25,421.65c7.83,6.11,17.69,11.72,30.57,16.59-.88,5.28.13,12.34,6.54,24.53-9.93.71-21.63-2.91-34.51-9.34,10.7,18.04,27.86,27.51,52.85,26.6-2.51,6.33-.85,13.43,4.5,21.18-6.84,1.25-13.85,1.32-21.06,0,5.52,5.73,14.62,9.71,25.6,12.76-.67,11,.1,18.47,3.39,19.69h.01c-8.69.35-17.39-1.57-26.12-6.69-18.16-9.12-34.09-23.59-47.91-43.07"/>
      <path id="ribspart9" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M18.11,371.25c6.17,2.26,12.47,3.38,18.94,2.94-6.94,5.43.63,22.73,10.64,28.04-11.06,1.09-21.6-.3-31.67-3.87"/>
      <path id="ribspart8" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M9.8,295.42c6.26-.47,13.55-1.32,21.5-2.42-8.71,7.4-8.94,16.79-4.07,27.37-2.54,5.19-7.97,5.52-13.89,5.04l-3.57-.81"/>
      <path id="ribspart7" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M5.61,240.58c2.8-.44,5.55-1.03,8.27-1.75-2.24,8.71-.85,14.4,6.02,15.53.58,3.28.65,6.36-1.92,8.41-2.9,1.22-7.08,1.8-12.08,1.96"/>
      <path id="ribspart6" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M8.71,183.96c4.12-.93,8.23-2.02,12.35-3.26-2.48,8.06,1.63,18.17,8.2,17.72l-19.45,7.46"/>
      <path id="ribspart5" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M5.73,150.26c4.58-1.19,9.31-2.74,14.18-4.63-2.76,7.61-2.04,14.38,2.79,20.17-5.53,3.24-11.14,5.39-16.8,6.8"/>
      <path id="ribspart4" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M18.11,114.25c-3.06.66-5.81,1.24-5.81,1.24-1.08.23-2.15.56-3.15,1.02-5.73,2.61-8.64,6.97-8.14,13.43v.13c.12,6.38,7.21,10.41,13.57,7.97"/>
      <path id="ribspart3" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M14.59,138.04c5.79-2.22,11.56-5.36,17.32-9.16,3.13-2.07,4.24-5.93,2.62-9.12l-2.34-4.65c-1.03-2.04-3.45-3.13-5.83-2.62,0,0-4.37.94-8.24,1.76"/>
      <path id="ribspart2" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M216.46,75.82c-1.61,2.66-3.59,5.18-5.86,7.61-.25.26-.48.53-.7.82l-1.99,2.46c-4.12,5.13-6.75,11.35-7.59,17.93l-1.66,13.04c-.15,1.24-.46,2.45-.89,3.61,0,.02-.02.05-.02.07-1.43,3.79-1.36,7.98.21,11.72l4.72,11.26c2.7,5.69,5.57,11.15,9.51,15.26,1.46,1.51,3.23,2.69,5.16,3.49,3.14,1.29,6.19,2.61,9.17,3.95"/>
      <path id="ribspart1" class="Brustwirbelsaule75_vd_obl_stehend1505_cls1" d="M29.25,64.56c2.8-.41,5.58-1.22,8.31-2.46,4.88-4.36,9.12-6.55,11.23-1.37l7.12,8.76c-8.27,7.38-13.26,15.65-13.95,25.07.39,5.62-6.28,11.59-22.04,17.98"/>
    </g>
  </g>
</g>
<g id="Intervertebralgelenk_Highlights" data-name="Intervertebralgelenk Highlights">
  <path id="Intervertebralgelenkhighlight11" class="Intervertebralgelenke1505Fill" style= {{
            fill: props.colors.Intervertebralgelenke1505Fill,
            stroke: props.colors.Intervertebralgelenke1505Fill,
          }} d="M66.03,499.89c-1.51-5.88-.93-11.31,1.17-16.42.83-4.58,2.47-5.26,4.68-6.01.15.91.82,6.42,2.04,6.54,2.27,18.76,45.82,31.19,55.55,15.96.88,2.25,1.24,2.56.01,5.43.66,1.3,1.06,2.55.79,3.68-2.17,3.21-11.36,9.84-18.8,12.02-12.67,4.17-11.69,3.62-22.01,2.05-1.47-.98-3.82-.49-10.74-9.19-5.1-3.42-9.31-7.55-12.15-12.76l-.54-1.3Z"/>
  <path id="Intervertebralgelenkhighlight10" class="Intervertebralgelenke1505Fill" style= {{
            fill: props.colors.Intervertebralgelenke1505Fill,
            stroke: props.colors.Intervertebralgelenke1505Fill,
          }} d="M73.48,453.41c-.72-1.98-1.26-4.38-1.61-7.23-.1-7.91,2.17-11.92,5.66-14.57.54,5.43,2.81,10.04,6.27,14.08,9.09,12.11,25.29,15.27,47.22,11.22.86,4.38.26,3.76-.69,8.01-5.57,5.39-12.06,7.74-19.49,7.01-6.82,2.57-14.62.95-23.57-5.63-3.91-1.82-7.38-3.97-9.81-6.91-1.36-1.55-2.69-3.5-3.98-5.98Z"/>
  <path id="Intervertebralgelenkhighlight9" class="Intervertebralgelenke1505Fill" style= {{
            fill: props.colors.Intervertebralgelenke1505Fill,
            stroke: props.colors.Intervertebralgelenke1505Fill,
          }} d="M86.01,404.91c-2-3.37-1.17-9.33,1.44-13.15.12,12.62,17.68,17.71,23.78,20.35,8.49,2.68,18.94,2.86,33.05-1.61,1.84.79,1.22,2.15.35,5.52-1.3,3.62-4.56,5.85-8.71,7.43-7.96,1.63-16.27.2-24.65-1.81-5.51-1.35-11.2-3.88-17.12-7.88l-5.04-3.5-2.1-2.07c-.4-.86-.74-1.92-1-3.29Z"/>
  <path id="Intervertebralgelenkhighlight8" class="Intervertebralgelenke1505Fill" style= {{
            fill: props.colors.Intervertebralgelenke1505Fill,
            stroke: props.colors.Intervertebralgelenke1505Fill,
          }} d="M89.55,369.06c-1.12-4.8-.84-6.26-.42-9.45.37-2.51.57-1.39,2.86-2.09,3.72,9.49,37.66,17.71,59.28,13.3.45,4.81.44,2.64.08,5.52-2.45,5.47-12.51,6.45-20.62,6.61-7.11.05-15.88-1.79-26.61-5.87l-6.75-1.96-4.71-2.91-3.12-3.16Z"/>
  <path id="Intervertebralgelenkhighlight7" class="Intervertebralgelenke1505Fill" style= {{
            fill: props.colors.Intervertebralgelenke1505Fill,
            stroke: props.colors.Intervertebralgelenke1505Fill,
          }} d="M90.52,330.25c-3.01-7.65.03-9.41,1.27-10.36,2.66,3.84,14.3,5.24,23.98,7.49,13.67,4.75,16.75.08,35.84.83,1.47,4.43,1.16,2.09,1.06,5.25-2.29,4.32-13.42,6.71-40.03,3.12l-14.8-2.99c-5.26-2.29-4.86-4.06-7.32-3.33Z"/>
  <path id="Intervertebralgelenkhighlight6" class="Intervertebralgelenke1505Fill" style= {{
            fill: props.colors.Intervertebralgelenke1505Fill,
            stroke: props.colors.Intervertebralgelenke1505Fill,
          }} d="M92.48,293.25c-1.27-4.53-1.03-6.6,2.54-8.9h3.62c8.9-.59,17.23-.49,24.65.68,6.06,1.8,13.12,2.68,23.18.78,3.89,1.46,4.84,2.11,5.22,4.92-1.37,3.07-4.66,4.84-9.29,5.7-10.09-1.25-20.45-1.79-31.05-1.72-3.92.65-7.62.67-11.05-.04-2.55.21-5.18-.46-7.83-1.43Z"/>
  <path id="Intervertebralgelenkhighlight5" class="Intervertebralgelenke1505Fill" style= {{
            fill: props.colors.Intervertebralgelenke1505Fill,
            stroke: props.colors.Intervertebralgelenke1505Fill,
          }} d="M94.92,256.02c-2-3.76-1.5-8.8-1.5-8.8,6.11-4.94,1.9,2.01,8.41-.36,14.1-1.54,27.17-3.48,38.2-6.26,3.94-.52,7.21-.57,9.29.22,2.4,3.42.78,6.95.78,6.95,0,0-5.64,5.73-7.04,5.58-8.62-2.67-17.41-2.42-26.35.44l-13.76,1.42-8.03.83Z"/>
  <path id="Intervertebralgelenkhighlight4" class="Intervertebralgelenke1505Fill" style= {{
            fill: props.colors.Intervertebralgelenke1505Fill,
            stroke: props.colors.Intervertebralgelenke1505Fill,
          }} d="M90,215.5l-2.02-7.05c-.35-2.54,2.46-3.9,2.46-3.9,19.08-5.65,22.01.47,35.12-6.38,7.91-2.52,14.55-3.33,18.78-.88,3.11,4.13,2.61,4.14,1.55,7.41-2.05,3.84-5.74,5.03-10.02,5.27-10.48-1.92-21.84.49-33.5,4.34-1.84.4-3.66.78-5.46,1.15-2.57.03-4.78.04-6.9.05Z"/>
  <path id="Intervertebralgelenkhighlight3" class="Intervertebralgelenke1505Fill" style= {{
            fill: props.colors.Intervertebralgelenke1505Fill,
            stroke: props.colors.Intervertebralgelenke1505Fill,
          }} d="M85.26,172.27c-5.11,1.49-6.19-.19-5.77-3.78-.56-3.76-.3-3.99,1.11-7.63,7.28.32,2.2-4.27,15.14-6.71,12.69-3.48,24.24-5.78,34.51-6.66,4.65,4.12,2.94,3.79,4.88,10.99-2.63,0,.6,6.82-19.26,6.72l-18.88.59c-4.3.19-8.26.08-9.84,2.98l-1.89,3.51Z"/>
  <path id="Intervertebralgelenkhighlight2" class="Intervertebralgelenke1505Fill" style= {{
            fill: props.colors.Intervertebralgelenke1505Fill,
            stroke: props.colors.Intervertebralgelenke1505Fill,
          }} d="M72.04,121.07c-2.67-2.91-1.66-5-.86-8.47.49-3.17,2.04-3.68,6.6-6.76,6.82-3.96,13.89-7.18,21.68-8.16,7.46-2.07,14.81-.6,22.04,4.26,2.07.39,3.53,1.46,3.41,6.61-2.94,5.44-7.14,7.38-11.28,6.7-8.35-2.78-16.03-2.9-22.9.14l-18.68,5.67Z"/>
  <path id="Intervertebralgelenkhighlight1" class="Intervertebralgelenke1505Fill" style= {{
            fill: props.colors.Intervertebralgelenke1505Fill,
            stroke: props.colors.Intervertebralgelenke1505Fill,
          }} d="M66.4,64.37c-4.97.04,2.88-4.33,9.97-16.56,5.87-5.67,11.38-10.22,16.17-12.53,2.08-.92,4.27-1.39,6.65-1.04,2.57.93,5.16,1.89,7.22,2.12-.32,3.44.67,6.25,2.9,8.47,1.26,1.27,2.3,2.79,2.72,4.53.58,2.39.32,4.8-.95,7.21-2.36,3.65-5.9,3.8-9.96,2.41-8.01-4.85-16.36-5.78-25.1-2.24-3.11,1.36-6.19,4.04-9.21,8.22h-.01s-.41-.59-.41-.59Z"/>
</g>
</svg>
  );
}

export default Brustwirbelsaule75VdOblStehend1505;
