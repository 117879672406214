import style from "./style.scss";

function RechtesOberesSprunggelenkAussenrotationDvOblLiegend1470(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.8 485.28">
  
  <g id="upper_ankle" data-name="upper ankle">
    <g id="background_layer" data-name="background layer">
      <path id="background1" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls2" d="M138.83.02l-3.4,95.4c1,50.7-1.8,94.4-15.2,119-3.7,11.1-5,21.6-2,31.1,1.9,6.1,8,10,14.3,9.2,12.2-2.6,28.5-4.4,47.5-5.7,11.4-1.5,24.2,3,38.2,13.4,3.9,2.9,9.3,1.8,11.8-2.3,12.3-20.3,8.6-40.3-5.2-60-9.1-12.7-12.4-29.1-14.3-46.4L205.43,1.82"/>
      <path id="background2" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls2" d="M222.23,323.72c.1-2.4.7-4.8,1.8-6.9,8.6-17.8,3.4-34.8-13.5-50.8-14.6-14.6-31.2-16.1-49.2-7.9-3.7,1.7-7.9,2.2-12,1.6-17.8-2.7-31.6,2-45.1,7.1-11,2.8-16.8,14.7-12.7,43.9,4.8,16.1,13.6,26.2,27,29.4,3.4.8,6.9.5,10.2-.6,23.9-8.1,46.3-9.3,65.7,3.9,8.9,6.1,21.4,2.7,25.4-7.3,1.4-3.6,2.2-7.8,2.4-12.4h0Z"/>
      <path id="background3" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls2" d="M107.43,277.52c2.9,1.5,4.9,4.2,5.9,7.4,4.8,16.1,6.4,35.5,4.6,58.7-.5,6.4-6.9,10.9-13.1,9.1-1.9-.5-3.8-1.3-5.9-2.3-10.8-4.3-19.5-9.4-26.1-15.3-2.9-2.6-4.9-6-5.8-9.8-3.7-14.8-4.1-27-1-36.9,2.2-7,7.6-12.6,14.6-15,11-3.8,19.1-.2,26.8,4.1h0Z"/>
      <path id="background4" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls2" d="M21.83,341.52c-.3,4.5.2,9,1.6,13.6,1.1,3.5,4.3,6,8,6,6.6,0,11,2.4,10.6,10.3-.1,2.7,1.2,5.3,3.5,6.7,5.4,3.3,8.3,3.2,9.7,1.1.6-1,.8-2.1.6-3.2-1.2-7.2,2.7-13.7,9.8-19.6,5.4-4.5,12.3-7,19.4-6.7,1.9,0,3.9.2,6,.5,3.1.4,5.5-2.5,4.4-5.4-2-5.3-6.1-11.6-11-18.2-2.2-3-4.8-5.7-7.9-7.8-7.3-5-16-7.3-25.7-7.8-6.2-.3-12.5,1.4-17.4,5.2"/>
      <path id="background5" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls2" d="M35.83,314.42c7.5,6.6,16.4,11.8,27.6,14.4,14.9-.1,18.8-20,15.8-46.4-.3-2.2.4-4.5,1.8-6.2,4.9-6,10.3-13.7,7.1-23.1-5.6-14.1-13.4-17.7-22.1-16.5-4.7.7-9,2.8-12.5,6-4.1,3.8-8.4,7-13.1,9.8"/>
      <path id="background6" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls2" d="M144.73,11.72c-1.5,83.1-6.2,160.1-19.6,220.9l-9.1,17.9c-4.2,8.4-5.8,18-3.9,27.2,1.4,6.9,4.5,13.1,9.6,18.3,8.7,8,17.9,7.4,27.4,2.2,7.8-3.4,14.6-9.2,20.4-17.7,4.3-6.3,6.1-14,5-21.6-2-13.9-6.9-29.8-12.6-46.2-.1-54.1,1.8-129.7,4.1-210.8"/>
      <path id="background7" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls2" d="M226.53,467.52c-12.7,17.8-32,23-60.8,10.1-16.8.9-24.7-6.3-27.8-17.7-13.8-19.6-30.9-33-49.8-43-10-1.3-17.2-8.3-21.9-20.2-11.3-9.8-10.8-20.3-1.7-31.2,10.9-14,28.5-16.8,52.3-9.3,10.5-8.6,24.7-14.2,41.3-17.7,31-.6,50.4,19.3,63.3,50.6,13.7,7.2,22.6,17.6,15.2,38.8-1,19.8-4.3,33.3-10.1,39.6Z"/>
      <path id="background8" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls2" d="M21.03,340.52l2.3-.4c17.3-7.1,15.5,10.6,12.2,30.2,8.4,15.4,4.2,22.2-10.4,22-10.4-3.2-17.8-8.9-24.8-15"/>
    </g>
    <path id="calcaneus" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls1" d="M226.53,467.52c-12.7,17.8-32,23-60.8,10.1-16.8.9-24.7-6.3-27.8-17.7-13.8-19.6-30.9-33-49.8-43-10-1.3-17.2-8.3-21.9-20.2-11.3-9.8-10.8-20.3-1.7-31.2,10.9-14,28.5-16.8,52.3-9.3,10.5-8.6,24.7-14.2,41.3-17.7,31-.6,50.4,19.3,63.3,50.6,13.7,7.2,22.6,17.6,15.2,38.8-1,19.8-4.3,33.3-10.1,39.6Z"/>
    <path id="tarsus1" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls1" d="M21.03,340.52l2.3-.4c17.3-7.1,15.5,10.6,12.2,30.2,8.4,15.4,4.2,22.2-10.4,22-10.4-3.2-17.8-8.9-24.8-15"/>
    <path id="tibia" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls1" d="M138.83.02l-3.4,95.4c1,50.7-1.8,94.4-15.2,119-3.7,11.1-5,21.6-2,31.1,1.9,6.1,8,10,14.3,9.2,12.2-2.6,28.5-4.4,47.5-5.7,11.4-1.5,24.2,3,38.2,13.4,3.9,2.9,9.3,1.8,11.8-2.3,12.3-20.3,8.6-40.3-5.2-60-9.1-12.7-12.4-29.1-14.3-46.4L205.43,1.82"/>
    <path id="talus" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls1" d="M222.23,323.72c.1-2.4.7-4.8,1.8-6.9,8.6-17.8,3.4-34.8-13.5-50.8-14.6-14.6-31.2-16.1-49.2-7.9-3.7,1.7-7.9,2.2-12,1.6-17.8-2.7-31.6,2-45.1,7.1-11,2.8-16.8,14.7-12.7,43.9,4.8,16.1,13.6,26.2,27,29.4,3.4.8,6.9.5,10.2-.6,23.9-8.1,46.3-9.3,65.7,3.9,8.9,6.1,21.4,2.7,25.4-7.3,1.4-3.6,2.2-7.8,2.4-12.4h0Z"/>
    <path id="tarsus2" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls1" d="M107.43,277.52c2.9,1.5,4.9,4.2,5.9,7.4,4.8,16.1,6.4,35.5,4.6,58.7-.5,6.4-6.9,10.9-13.1,9.1-1.9-.5-3.8-1.3-5.9-2.3-10.8-4.3-19.5-9.4-26.1-15.3-2.9-2.6-4.9-6-5.8-9.8-3.7-14.8-4.1-27-1-36.9,2.2-7,7.6-12.6,14.6-15,11-3.8,19.1-.2,26.8,4.1h0Z"/>
    <path id="tarsus3" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls1" d="M21.83,341.52c-.3,4.5.2,9,1.6,13.6,1.1,3.5,4.3,6,8,6,6.6,0,11,2.4,10.6,10.3-.1,2.7,1.2,5.3,3.5,6.7,5.4,3.3,8.3,3.2,9.7,1.1.6-1,.8-2.1.6-3.2-1.2-7.2,2.7-13.7,9.8-19.6,5.4-4.5,12.3-7,19.4-6.7,1.9,0,3.9.2,6,.5,3.1.4,5.5-2.5,4.4-5.4-2-5.3-6.1-11.6-11-18.2-2.2-3-4.8-5.7-7.9-7.8-7.3-5-16-7.3-25.7-7.8-6.2-.3-12.5,1.4-17.4,5.2"/>
    <path id="tarsus4" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls1" d="M35.83,314.42c7.5,6.6,16.4,11.8,27.6,14.4,14.9-.1,18.8-20,15.8-46.4-.3-2.2.4-4.5,1.8-6.2,4.9-6,10.3-13.7,7.1-23.1-5.6-14.1-13.4-17.7-22.1-16.5-4.7.7-9,2.8-12.5,6-4.1,3.8-8.4,7-13.1,9.8"/>
    <path id="fibia" class="oberes_Sprunggelenk1470_Außenrotation_vd_obl_rechts_cls1" d="M144.73,11.72c-1.5,83.1-6.2,160.1-19.6,220.9l-9.1,17.9c-4.2,8.4-5.8,18-3.9,27.2,1.4,6.9,4.5,13.1,9.6,18.3,8.7,8,17.9,7.4,27.4,2.2,7.8-3.4,14.6-9.2,20.4-17.7,4.3-6.3,6.1-14,5-21.6-2-13.9-6.9-29.8-12.6-46.2-.1-54.1,1.8-129.7,4.1-210.8"/>
  </g>
  <g id="overlay">
    <path id="posterior_edge_of_tibia_overlay" data-name="posterior edge of tibia overlay" class="Tibiahinterkante1470Fill" style= {{
              fill: props.colors.Tibiahinterkante1470Fill,
              stroke: props.colors.Tibiahinterkante1470Fill,
              opacity:props.colors.Tibiahinterkante1470Fill,
            }} d="M210.81,156.18c1.95,19.33,6.55,33.68,13.62,43.37,9.7,13.62,14.31,27.19,13.07,40.7-1.35,7.87-3.78,14.56-7.47,19.87-5.3,6.81-11.31,3.79-17.5-1.65-7.31-4.95-15.08-7.99-23.26-9.39,6.53,1.57,12.98,3.05,18.79,3.72,12.6,1.46,23.09-9.64,20.67-22.09-1.69-8.68-6.29-18.27-11.5-28.05-5.45-8.61-9.64-17.8-12.21-27.73s-2.07-20.13,1.12-29.73c3.34-10.06,3.94-4.92,4.68,10.98Z"/>
  </g>
</svg>
  );
}

export default RechtesOberesSprunggelenkAussenrotationDvOblLiegend1470;
