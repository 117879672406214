import "../ExamQA/style/ExamStatistics.scss";
import "./stylesheets/ExamStatistics.scss";

import ExamsSelectionBox from "../ExamQA/Components/ExamsSelectionBox";
import ImageSerieListSelectionBox from "../ExamQA/Components/ImageSerieListSelectionBox";
import ChartBox from "./Components/ChartBox";
import FilteringBox from "../ExamQA/Components/FilteringBox";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetXmStatsState } from "../../Redux/actions";

function ExamStatistics() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetXmStatsState(true));
  }, []);

  return (
    <div className="full-screen">
      <div className="results-area">
        <div className="exams-images-box">
          <ExamsSelectionBox />
          <ImageSerieListSelectionBox />
        </div>
        <div className="chart-area">
          <ChartBox />
        </div>
      </div>
      <FilteringBox />
    </div>
  );
}

export default ExamStatistics;
