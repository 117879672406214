import style from "./style.scss";

function Brustwirbelkorper1VdLiegend1507(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.36 128.27">

<g id="background">
  <path id="background_white" data-name="background white" class="Brustwirbelsaule1_ap1507_cls4" d="M37.46,128.27L.09,104.77l10.95-12.67L.09,90.46c9.27-8.24,20.33-15.71,32.35-22.76,11.61-14.06,25.3-24.48,41.59-30.4,7.39-2.08,15.93-2.46,23.36-1.73-5.13-3.15-12.13-4.19-18.34-4.09-9.97-2.6-13.93-6.38-14.88-11.96.45-9.07,4.3-13.61,9.79-15.95,3.02-1.62,7.4-1.08,13.64,2.4l14.42,3.01c4.29.31,9.39,2.59,14.88,5.79l30.01,14.9c2.82-2.67,5.27-4.37,6.56-2.97.3-2.03-1.48-4.4-10.44-7.18-.29-6.27-2.23-14.48,1.55-19.14,26.66-.77,56.4.25,87.97,2.34-1.73,5.65-4.67,6.87-8.99,4.5-.43-.24-.89.35-.75.82,1.67,5.83,2.19,11.94.16,17.08-3.67,2.61-3.88,5.72-1.43,9.21,2.81-4.84,8.04-7.73,15.99-8.43l22.38-7.36c7.38-2.9,14.46-5.02,20.47-4.52,7.73-.88,14.5-2.3,15.4,1.45l4.69,12.73c2.01,4.91-1.19,9-8.22,12.47,7.08-.04,14.55,2.41,22.33,6.83,13.43,8.56,24.1,21.53,33.68,36.25l-15.02,32.81c-3.5-9.92-7.94-19.12-13.94-27.1-5.16-6.49-11.51-12.54-20.33-17.68-10.94-5.5-23.5-9.21-35.24-8.22-11.39-.2-23.53,3.14-36.25,9.24-3.36,3.21-4.01,6.4-1.82,9.57,11.76-.81,21.57-3.51,29.19-8.31,7.55-4.34,15.81-9.22,18.97-10.19,7.68-.27,13.66,1.52,18.37,4.82-.56,6.43-7.58,11.34-19.77,15.04l-35.37,19.59c-6.51,5.26-12.41,9.72-14.85,9.62,0,2.43,1.1,4.94,3.13,7.51,1.83,3.89-.64,5.69-8.79,4.75-6.64-.23-23.89-10.76-24.1-9.78-10.73-3.32-21.98-3.09-33.75.75-5.93,3.67-8.66,8.23-16.46,6.33-4.8-1.12-3.75-4.93-1.29-9.39l5.43-12.98-1.23-22.5c.33-3.43,1.34-4.04,2.62-3.58l-.85-2.56c-2.77.11-4.88-.72-5.84-3.25-4.92-8.24-12.92-14.18-23.68-18.09-6.17-1.57-12.3-1.43-18.41.49,12.31,5.86,21.63,11.01,26.01,15l6.66,5.87c5.04-.63,9.02,1.96,12.48,6.11l3.59,4.8.05,17.59c-3.45.41-5.99-.83-8.17-2.71-1.72,1.1-4.29.84-7.23,0-8.24-5.55-17.91-10.24-29.3-13.9-20.4-6.63-37.89-5.69-54.33-.36-6.49,10.48-11.29,26.2-11.29,45.2v.03Z"/>
</g>
<g id="_1BrustWirbelKörper" data-name="1BrustWirbelKörper">
  <path id="Brustwirbelkörperpart23" class="Brustwirbelsaule1_ap1507_cls5" d="M66.63,9.94c.58-.96,3.38-5.36,8.66-6.84,4.29-1.2,6.73.51,15.49,3.19,12.08,3.7,12.05,1.88,19.59,5.01,5.1,2.12,4.09,2.53,14.58,7.75,8.04,4,12.3,5.92,12.3,5.92,8.79,3.97,11.37,4.56,13.22,7.75.39.67,2.64,4.72.91,9.11-.18.46-1.82,4.45-5.47,5.47-3.66,1.02-5.88-1.92-12.76-5.01-5.25-2.36-9.6-3.17-14.58-4.1-7.6-1.42-8.15-.35-15.49-1.82-6.92-1.39-6.44-2.34-13.22-3.65-8.02-1.54-9.42-.34-14.58-2.28-3.62-1.35-7.95-2.98-10.03-7.29-2.87-5.98.87-12.4,1.37-13.22h0Z"/>
  <path id="Brustwirbelkörperpart22" class="Brustwirbelsaule1_ap1507_cls5" d="M220.65,35.91c2.15-4.45,5.71-6.62,6.84-7.29,3.3-1.96,5.76-1.94,10.03-2.73,1.52-.28,6.2-1.86,15.49-5.01,15.81-5.35,15.22-5.34,17.77-5.92,8.16-1.85,5.28.05,17.32-1.82,1.89-.29,3.95-.65,5.92.46,1.24.7,2.15,1.81,4.56,8.66,2.79,7.95,2.61,9.4,2.28,10.48-.86,2.83-3.11,4.45-4.56,5.47-4.37,3.07-7.97,2.59-14.58,3.65-7.62,1.21-6.14,2.36-17.77,5.01-3.36.77-8.16,1.86-14.58,2.73-7.2.98-8.95.67-12.76,2.28-4.05,1.71-4.95,3.26-7.75,3.19-3.77-.1-7.06-3.06-8.66-5.92-3.18-5.69-.2-11.86.46-13.22v-.02h-.01Z"/>
  <path id="Brustwirbelkörperpart21" class="Brustwirbelsaule1_ap1507_cls5" d="M142.27,70.09c-1.4-1.53-.92-3.55,0-11.39.62-5.32.94-7.97.91-8.66-.32-8.84-5.9-13.21-3.65-16.41.66-.94,1.03-.4,4.56-2.28,4.66-2.49,5.25-4.09,7.75-4.56,2.81-.53,5.06.93,6.84,1.82,3.33,1.67,5.12,1.4,21.87,1.37,14.99-.03,18.86.18,22.33.46,0,0,5.56.45,14.58,2.73,6.72,1.7,8.55,2.81,9.57,4.56.96,1.64.48,2.61,0,10.03-.41,6.24-.6,9.41,0,11.39,1.1,3.67,2.79,4.51,2.73,7.75,0,.68-.06,3.84-2.28,5.92-2.83,2.67-7.32,1.8-9.57,1.37-4.81-.93-6.57-3.02-10.48-5.01-2.51-1.28-7.48-1.93-17.32-3.19-16.36-2.09-18.85-1.47-20.51-.91-2.74.92-3.83,1.87-10.03,4.1-4.08,1.47-6.59,2.13-9.57,2.28-3.53.18-6.21.31-7.75-1.37h.02,0Z"/>
  <path id="Brustwirbelkörperpart20" class="Brustwirbelsaule1_ap1507_cls5" d="M175.99,44.11c.53-2.73,3.27-5.3,6.38-5.47,3.65-.2,6.02,3,6.84,4.1,2.14,2.89,2.19,5.92,2.28,10.94.07,4.45.12,6.88-1.37,7.75-2.24,1.31-6.37-1.82-8.2-3.65-2.04-2.04-2.89-4.12-4.56-8.2-1.1-2.69-1.64-4.07-1.37-5.47h0Z"/>
  <path id="Brustwirbelkörperpart19" class="Brustwirbelsaule1_ap1507_cls6" d="M183.74,3.56c-.93,0-1.45.8-3.65,3.65-4.2,5.45-4.71,5.67-4.56,6.38.77,3.67,15.71,4.93,16.86,1.82.32-.86-.69-1.29-4.1-6.38-2.61-3.89-3.25-5.47-4.56-5.47h0Z"/>
  <path id="Brustwirbelkörperpart18" class="Brustwirbelsaule1_ap1507_cls6" d="M146.37,100.16c4.87-.26,9.5-4.48,10.94-9.11.4-1.29,1.12-3.62,0-5.47-1.7-2.8-6.43-2.51-9.11-2.73-7.76-.64-11.19-6.64-20.05-14.58,0,0-20.99-18.79-42.84-19.14-2.11-.03-5.22.15-7.29,2.28-2.97,3.04-2.42,8.65-.91,12.3,1.57,3.78,5.32,6.22,12.76,10.94,6.44,4.08,10.15,6.43,15.95,8.2,6.2,1.89,6.14.48,11.85,2.28,4.86,1.53,9.23,4.23,17.77,9.57,7.21,4.5,8.29,5.61,10.94,5.47h0Z"/>
  <path id="Brustwirbelkörperpart17" class="Brustwirbelsaule1_ap1507_cls6" d="M229.76,81.94c3.41.18,4.59-.94,10.03-1.82,5.65-.92,9.84-3.2,18.23-7.75,8.04-4.36,8.87-5.9,15.04-8.2,7.49-2.79,10.94-2.26,13.22-1.37.81.32,5.43,2.24,5.92,5.92.44,3.22-2.54,5.95-4.56,7.75-5.15,4.59-9.4,4.99-16.41,7.75-1.02.4-6.76,3.61-18.23,10.03-9.76,5.46-14.63,8.21-15.95,9.11-8.01,5.5-13.28,10.82-14.84,9.61-1.21-.95,1.52-4.58-.19-6.79-2.05-2.64-7.59.43-10.53-2.35-2.37-2.24-1.4-6.74-1.32-7.3.88-6.7,13.22-14.93,19.59-14.58h0Z"/>
  <path id="Brustwirbelkörperpart16" class="Brustwirbelsaule1_ap1507_cls6" d="M139.08,120.21c-1.73-3.15,2.54-6.18,5.47-15.49.72-2.29,2.53-8.2,2.28-15.49-.29-8.42-3.12-12.7-.91-14.58,1.04-.89,2.75-.84,8.2.91,7.8,2.51,13.35,5.32,14.58,5.92,9.75,4.77,19.87,3.91,28.25,3.19,19.39-1.66,21.87-2.28,21.87-2.28,2.42-.6,5.33-1.46,6.84,0,1.86,1.8-.28,5.3-1.82,12.76-1.71,8.27-3.52,17,.46,23.7,1.04,1.76,2.38,3.21,1.82,4.56-1.11,2.67-8.37,1.95-9.57,1.82-7.16-.78-10.33-3.9-16.41-6.84-11.21-5.41-21.83-5.13-25.06-5.01-7.97.3-13.87.52-19.14,4.56-2.6,1.99-4.43,4.44-8.2,5.01-2.84.43-7.22-.13-8.66-2.73h0Z"/>
  <path id="Brustwirbelkörperpart15" class="Brustwirbelsaule1_ap1507_cls6" d="M144.58.37c-5.24,8.4.16,19.14-1.56,19.14"/>
  <path id="Brustwirbelkörperpart14" class="Brustwirbelsaule1_ap1507_cls6" d="M143.03,19.51c.73-.03,34.66-.74,79.94,5.6"/>
  <path id="Brustwirbelkörperpart13" class="Brustwirbelsaule1_ap1507_cls6" d="M222.96,25.11c3.05-5.64,0-17.45,0-17.45"/>
  <path id="Brustwirbelkörperpart12" class="Brustwirbelsaule1_ap1507_cls6" d="M222.96,7.66c0-2.27,5.75,6.35,11.5-7.29"/>
  <path id="Brustwirbelkörperpart11" class="Brustwirbelsaule1_ap1507_cls6" d="M348.25,83.76c-16.45-24.59-34.02-43.47-57.92-43.08"/>
  <path id="Brustwirbelkörperpart10" class="Brustwirbelsaule1_ap1507_cls6" d="M266.2,46.31c-2.19-4-14.14-6.23-24.14-.83"/>
  <path id="Brustwirbelkörperpart9" class="Brustwirbelsaule1_ap1507_cls6" d="M337.76,83.76c-5.88-13.34-32.79-18.96-100.71,0"/>
  <path id="Brustwirbelkörperpart8" class="Brustwirbelsaule1_ap1507_cls6" d="M333.23,116.57c-17.17-50.58-60.84-64.58-105.72-43.75"/>
  <path id="Brustwirbelkörperpart7" class="Brustwirbelsaule1_ap1507_cls6" d="M237.15,83.76c-27.25,16.42-2.4,19.59-.09,19.59"/>
  <path id="Brustwirbelkörperpart6" class="Brustwirbelsaule1_ap1507_cls6" d="M33.82,93.87c3.11-10.19,48.76-29.07,98.56,3.47"/>
  <path id="Brustwirbelkörperpart5" class="Brustwirbelsaule1_ap1507_cls6" d="M.09,90.46c75.79-63.78,85.22-11.37,131.56-18.91"/>
  <path id="Brustwirbelkörperpart4" class="Brustwirbelsaule1_ap1507_cls6" d="M131.65,71.55c19.41-1.12,21,31.8.72,25.78"/>
  <path id="Brustwirbelkörperpart3" class="Brustwirbelsaule1_ap1507_cls6" d="M37.46,128.27c0-42.89,27.52-77.59,61.52-77.59"/>
  <path id="Brustwirbelkörperpart2" class="Brustwirbelsaule1_ap1507_cls6" d="M.09,104.77C43.38,55.05,60.46,29.11,103.08,36.37"/>
  <path id="Brustwirbelkörperpart1" class="Brustwirbelsaule1_ap1507_cls6" d="M98.98,50.68c3.91-2.48,26.97-6.28,43.29,19.41"/>
</g>
<g id="BWK_Highlights" data-name="BWK Highlights">
  <path id="Querfortsatz2_highlight" data-name="Querfortsatz2 highlight" class="Wirbelkorper_Querfortsatze1507Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1507Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1507Fill,
          }} d="M67.03,9.57c.58-.96,3.38-5.36,8.66-6.84,4.29-1.2,6.73.51,15.49,3.19,12.08,3.7,12.05,1.88,19.59,5.01,5.1,2.12,4.09,2.53,14.58,7.75,8.04,4,12.3,5.92,12.3,5.92,8.79,3.97,11.37,4.56,13.22,7.75.39.67,2.64,4.72.91,9.11-.18.46-1.82,4.45-5.47,5.47-3.66,1.02-5.88-1.92-12.76-5.01-5.25-2.36-9.6-3.17-14.58-4.1-7.6-1.42-8.15-.35-15.49-1.82-6.92-1.39-6.44-2.34-13.22-3.65-8.02-1.54-9.42-.34-14.58-2.28-3.62-1.35-7.95-2.98-10.03-7.29-2.87-5.98.87-12.4,1.37-13.22h0Z"/>
  <path id="Querfortsatz1_highlight" data-name="Querfortsatz1 highlight" class="Wirbelkorper_Querfortsatze1507Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1507Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1507Fill,
          }} d="M221.05,35.54c2.15-4.45,5.71-6.62,6.84-7.29,3.3-1.96,5.76-1.94,10.03-2.73,1.52-.28,6.2-1.86,15.49-5.01,15.81-5.35,15.22-5.34,17.77-5.92,8.16-1.85,5.28.05,17.32-1.82,1.89-.29,3.95-.65,5.92.46,1.24.7,2.15,1.81,4.56,8.66,2.79,7.95,2.61,9.4,2.28,10.48-.86,2.83-3.11,4.45-4.56,5.47-4.37,3.07-7.97,2.59-14.58,3.65-7.62,1.21-6.14,2.36-17.77,5.01-3.36.77-8.16,1.86-14.58,2.73-7.2.98-8.95.67-12.76,2.28-4.05,1.71-4.95,3.26-7.75,3.19-3.77-.1-7.06-3.06-8.66-5.92-3.18-5.69-.2-11.86.46-13.22v-.02h-.01Z"/>
  <path id="Brustwirbel_mit_Querfortsatz_highlight" data-name="Brustwirbel mit Querfortsatz highlight" class="Wirbelkorper_Querfortsatze1507Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1507Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1507Fill,
          }} d="M142.67,69.72c-1.4-1.53-.92-3.55,0-11.39.62-5.32.94-7.97.91-8.66-.32-8.84-5.9-13.21-3.65-16.41.66-.94,1.03-.4,4.56-2.28,4.66-2.49,5.25-4.09,7.75-4.56,2.81-.53,5.06.93,6.84,1.82,3.33,1.67,5.12,1.4,21.87,1.37,14.99-.03,18.86.18,22.33.46,0,0,5.56.45,14.58,2.73,6.72,1.7,8.55,2.81,9.57,4.56.96,1.64.48,2.61,0,10.03-.41,6.24-.6,9.41,0,11.39,1.1,3.67,2.79,4.51,2.73,7.75,0,.68-.06,3.84-2.28,5.92-2.83,2.67-7.32,1.8-9.57,1.37-4.81-.93-6.57-3.02-10.48-5.01-2.51-1.28-7.48-1.93-17.32-3.19-16.36-2.09-18.85-1.47-20.51-.91-2.74.92-3.83,1.87-10.03,4.1-4.08,1.47-6.59,2.13-9.57,2.28-3.53.18-6.21.31-7.75-1.37h.02,0Z"/>
  <path id="Dornfortsatz_highlight" data-name="Dornfortsatz highlight" class="Wirbelkorper_Querfortsatze1507Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1507Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1507Fill,
          }} d="M176.39,43.74c.53-2.73,3.27-5.3,6.38-5.47,3.65-.2,6.02,3,6.84,4.1,2.14,2.89,2.19,5.92,2.28,10.94.07,4.45.12,6.88-1.37,7.75-2.24,1.31-6.37-1.82-8.2-3.65-2.04-2.04-2.89-4.12-4.56-8.2-1.1-2.69-1.64-4.07-1.37-5.47h0Z"/>
  <path id="angrenzender_BWK_highlight" data-name="angrenzender BWK highlight" class="BWK1507Fill" style= {{
            fill: props.colors.BWK1507Fill,
            stroke: props.colors.BWK1507Fill,
          }} d="M139.48,119.84c-1.73-3.15,2.54-6.18,5.47-15.49.72-2.29,2.53-8.2,2.28-15.49-.29-8.42-3.12-12.7-.91-14.58,1.04-.89,2.75-.84,8.2.91,7.8,2.51,13.35,5.32,14.58,5.92,9.75,4.77,19.87,3.91,28.25,3.19,19.39-1.66,21.87-2.28,21.87-2.28,2.42-.6,5.33-1.46,6.84,0,1.86,1.8-.28,5.3-1.82,12.76-1.71,8.27-3.52,17,.46,23.7,1.04,1.76,2.38,3.21,1.82,4.56-1.11,2.67-8.37,1.95-9.57,1.82-7.16-.78-10.33-3.9-16.41-6.84-11.21-5.41-21.83-5.13-25.06-5.01-7.97.3-13.87.52-19.14,4.56-2.6,1.99-4.43,4.44-8.2,5.01-2.84.43-7.22-.13-8.66-2.73h0Z"/>
  <path id="HWS_Übergang_Highlight" data-name="HWS Übergang Highlight" class="BWK1507Fill" style= {{
            fill: props.colors.BWK1507Fill,
            stroke: props.colors.BWK1507Fill,
          }} d="M234.3.12c-5.87,14.34-10.65,5.54-10.94,7.17,0,0,3.05,11.81,0,17.45h0c-45.28-6.34-79.21-5.63-79.94-5.6h0c1.72,0-3.68-10.74,1.56-19.14"/>
</g>
<g id="Grund_und_Deckelplatte_Highlights" data-name="Grund und Deckelplatte Highlights">
  <path id="GrundDeckelplatteHighlight4" class="Grund_Deckplatten1507Fill" style= {{
            fill: props.colors.Grund_Deckplatten1507Fill,
            stroke: props.colors.Grund_Deckplatten1507Fill,
          }} d="M224.49,64.19c-1.2.43-3.99,1.69-5.33,1.16-.97-.38-3.45-1.2-4.36-1.83-1.79-1.24-4.98-2.2-8.71-2.93-6.66-1.31-12-1.97-18.68-2.97-6.47-.97-13.02-1.49-19.37.13-6.61,1.68-14.8,6.73-21.82,5.77-6.29-.86-7.77,7.33-1.41,8.2,7.57,1.04,13.58-2.54,20.65-5.02,7.35-2.57,15.36-3.05,23.04-1.34,3.68.82,9.39,1.39,13.09,2.12,3.42.68,5.03,2.18,8.32,3.37,2.59.94,4.73,2.44,7.5,3.22,3.26.93,6.19.94,9.27-.17,6.01-2.17,3.88-11.92-2.19-9.72Z"/>
  <path id="GrundDeckelplatteHighlight3" class="Grund_Deckplatten1507Fill" style= {{
            fill: props.colors.Grund_Deckplatten1507Fill,
            stroke: props.colors.Grund_Deckplatten1507Fill,
          }} d="M226.78,36.95c-4.57-4.3-12.19-5.07-18.58-6.18-7.86-1.37-16.27-1.29-24.22-.8-8.39.52-15.87.38-24.09-1.44-2.82-.62-5.14-2.32-8.05-1.75-3.61.71-7.67,4.01-10.8,5.77-5.61,3.16-.74,9.24,4.88,6.07,2.15-1.21,5.05-4.45,7.53-4.67,2.94-.26,8.88,2.06,11.84,2.57,6.29,1.08,12.44,1.14,18.82.78,6.09-.34,12.25.12,18.36.39,3.46.16,5.97.56,9.49,1.37,2.57.59,6.56,1.89,9,3.21,3.72,2.02,10.51-.91,5.82-5.33Z"/>
  <path id="GrundDeckelplatteHighlight2" class="Grund_Deckplatten1507Fill" style= {{
            fill: props.colors.Grund_Deckplatten1507Fill,
            stroke: props.colors.Grund_Deckplatten1507Fill,
          }} d="M220.57,15.88c-25.77-3.39-49.5-3.21-73.27-5.94-7.7-.89-3.51,8.68-3.88,9.51,1.42-.76,56.52,2.29,79.7,5.7,1.02-2.86,2.49-8.6-2.55-9.27Z"/>
  <path id="GrundDeckelplatteHighlight1" class="Grund_Deckplatten1507Fill" style= {{
            fill: props.colors.Grund_Deckplatten1507Fill,
            stroke: props.colors.Grund_Deckplatten1507Fill,
          }} d="M222.15,81.67c-15.76,3.49-11.34.85-20.15,2.56-6.5.57-8.79,1.12-13.42.95-7.9-.28-7.13.49-12.65-1.12-8.75-2.55-21.86-9.76-27.33-9.78-1.64,0-2.94-.2-3.49,1.92-.59,2.27,1.1,6.47,4.45,6.79,6.42.62,11.72,4.43,17.8,6.36,5.85,1.85,10.92,3.07,17.03,3.52,17.49,1.28,25.8-4.5,38.63-1.86,2.27.47,6.75-11.04-.87-9.35Z"/>
</g>
</svg>
  );
}

export default Brustwirbelkorper1VdLiegend1507;
