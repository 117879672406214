import style from "./style.scss";

function Brustwirbelkorper4LatLiegend1514(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 250 248.2">

<g id="BG">
  <path id="BG5" class="BWSkorper4_lat1514_cls3" d="M17.3,99.9c-2.3-2.8,2-6.5,0-13-1.7-5.6-5.6-5.5-11-13-3.2-4.5-6.9-9.5-6-15,1.1-7,9.2-11.1,15-14,4.1-2,9.8-3.4,21-6,18.7-4.3,19.2-3.5,24-6,5.8-3.1,8.9-6.3,12-5,4.2,1.8,3,9.4,6,10,2.8.6,7.6-5.6,8-12,.4-6.6-7.4-8.2-7-14,.3-4.6,4.9-8.6,7-8,1.6.5,1,3.2,3.5,6.5.5.6,3.3,4.4,6.5,4.5,5,0,5.2-9.1,13-13,6.3-3.1,13.7-.8,16,0,.5.2,3.9,2.1,10.7,5.9,4.8,2.7,6.6,3.8,8.3,6.1,2.3,3,2.7,6.1,2.8,6.7.1.8.6,4.2-.8,7.3-2.6,5.4-10,6.5-16,8-8.9,2.2-13.1,0-14,2-1.6,3.4,8.2,13.3,20,19,9.1,4.4,15.1,4.2,16,8,.7,3.2-3.2,4.7-9,14-4.7,7.5-4.2,9.8-7,11-5.4,2.3-14-3-19-9-6.9-8.3-5.2-15.8-11-18-3.7-1.4-8.7,0-11,3-4,5.1,2.2,11.5-1,17-2.7,4.6-8.9,3.3-24,6-4,.7-11.7,2.8-27,7-17.1,4.7-23.6,7-26,4Z"/>
  <path id="BG4" class="BWSkorper4_lat1514_cls3" d="M34.3,173.9c-3-3.1-2.6-8.7-2-19,.5-9,2-9.3,1-13-2-7.6-8.5-7.8-11-16-.5-1.5-2.3-8.1,1-12,2.9-3.5,8.2-2.9,14-3,9.2-.2,15.8-2.1,29-6,17.8-5.3,25.6-12.7,30-9,3.2,2.6.8,7.7,5,11,3.6,2.8,9.9,2.8,12,0,2.6-3.4-3.6-8.4-2-17,.8-4.1,3.3-8.9,6-9,2.4-.1,2.9,3.5,7.5,6.5.6.4,6.7,4.3,11.5,2.5,3.8-1.5,4-5.6,7-11,4.2-7.7,13.5-17.2,21-16,4,.7,7,5.8,13,16,7.3,12.5,8.4,16.2,7,19-.6,1.1-2,3.1-4.2,4.2-3.1,1.7-5.8.6-8,2.8-1.2,1.2-1.1,2.1-1.8,3-3.9,5.4-21.7-3.5-24,0-.7,1,0,3.1,10,16,8.5,11,11.9,14.5,10.7,18.1-1.1,3.4-4.9,3.5-6.7,8.9-.7,2.1-.2,2.3-1.3,6.2-1,3.7-1.7,4.5-1.7,6.8,0,3.4,1.4,5.5,1.1,5.7-.6.4-7.4-8.5-15.1-22.7-8.2-15.2-9.1-21-14-22-5.4-1.1-12.7,4.6-15,11.1-3.2,9,4.5,15.2,1,20.9-2.9,4.7-9.5,2.3-24,5-12.5,2.3-12.6,5.1-31,10-21.7,5.8-25.1,2.9-26,2h0Z"/>
  <path id="BG3" class="BWSkorper4_lat1514_cls3" d="M236.1,223.9c10.8,5.2,16.8-1.1,11.2-25.9-.2-1.1-.6-1.8-1.2-2.4-4.2-4.4-9.6-2.3-15.8-5.6-6.4-3.4-3.8-8.4-12.2-14.3-3.6-2.5-3.8-1.5-9.6-5.2-5.6-3.7-7.6-6.1-10.2-5.4-1.8.5-1.3,1.8-4.9,5.6-2.9,3.1-3.8,2.7-7.1,6.4-2,2.3-2.2,3-3.9,4.2-1.7,1.3-3.1,1.8-4.8,3.7-.7.8-1.1,1.4-1.4,2-1.6,4.7,10.2,12.1,18,19,4.9,4.4,7.9.9,15,4,7.2,3.2,11.5,2.1,14,9,2,5.5,1.5-.4,12.8,4.9h0Z"/>
  <path id="BG2" class="BWSkorper4_lat1514_cls3" d="M235.3,133.9c1.4-2.7,0-4.1,0-11,0-8.6,2.3-9.4,1-13-1.3-3.4-5.5-5.7-14-10-6.4-3.3-6.7-2.8-13-6-4.7-2.4-9-5-14-8-8.8-5.3-16.8-7.9-18-7-2.6,1.8,10.6,11.8,7,19-3.4,6.9-13.1,5.4-14,10-1.1,5.7,13,13.3,20,17,6,3.2,5.3,2,13,6,11.7,6,14.1,9.1,20,9,4.3-.1,9.9-1.9,12-6Z"/>
  <path id="BG1" class="BWSkorper4_lat1514_cls3" d="M40.3,240.9c-1-1.7.3-6.8,3-17,2.7-10.1,3.8-11.6,3-15-1.4-6.2-6.2-6.3-8-13-1.3-4.8-.7-11.7,3-14,3.6-2.3,6.8,1.7,15,2,7.6.3,8.4-2.9,19-5,11.3-2.2,11.2,1.3,22-1,9.7-2.1,11.1-5.2,19-5,4.7.1,6.9,1.3,11,0,3.2-1,8.5-3.8,9-8,.6-5.2-6.8-7.7-6-12,.7-3.8,9.9-10.6,13-6,18,26.2,15.6,26.8,14,17-.2-1.2.3-1,2-8,1.6-6.6,7-17,16-18,9.2-1,16.1,8.4,18,11,2,2.7,7,9.5,5,16-.8,2.7-2.2,3.4-12,12-8.6,7.6-9.4,8.6-10,10-2.9,6.6,1,14.3,4,20,9,17.1,22,22.6,20,28-.8,2.2-3,1.4-11,6-7.7,4.5-10.4,8-12,7-2.6-1.6,4-11.1,0-17-3.1-4.6-9.1-1.7-14-7-3.6-3.9-1.7-6.7-5-14-.9-1.9-6.4-14.1-14-14-6,0-11.5,7.9-13,15-2.2,10.7,5.4,16.7,2,22-2.5,4-8.5,3.4-22,4-.7,0-11.1,1-32,3-27.4,2.6-36.3,5.5-39,1Z"/>
</g>
<g id="Underlays">
  <g id="WK_H">
    <path id="WK_H3" class="Wirbelkorper_Dornfortsatze1514Fill" style= {{
            fill: props.colors.Wirbelkorper_Dornfortsatze1514Fill,
            stroke: props.colors.Wirbelkorper_Dornfortsatze1514Fill,
          }} d="M35,173.8c-3-3.1-2.6-8.7-2-19,.5-9,2-9.3,1-13-2-7.6-8.5-7.8-11-16-.5-1.5-2.3-8.1,1-12,2.9-3.5,8.2-2.9,14-3,9.2-.2,15.8-2.1,29-6,17.8-5.3,25.6-12.7,30-9,3.2,2.6.8,7.7,5,11,3.6,2.8,9.9,2.8,12,0,2.6-3.4-3.6-8.4-2-17,.8-4.1,3.3-8.9,6-9,2.4-.1,2.9,3.5,7.5,6.5.6.4,6.7,4.3,11.5,2.5,3.8-1.5,4-5.6,7-11,4.2-7.7,13.5-17.2,21-16,4,.7,7,5.8,13,16,7.3,12.5,8.4,16.2,7,19-.6,1.1-2,3.1-4.2,4.2-3.1,1.7-5.8.6-8,2.8-1.2,1.2-1.1,2.1-1.8,3-3.9,5.4-21.7-3.5-24,0-.7,1,0,3.1,10,16,8.5,11,11.9,14.5,10.7,18.1-1.1,3.4-4.9,3.5-6.7,8.9-.7,2.1-.2,2.3-1.3,6.2-1,3.7-1.7,4.5-1.7,6.8,0,3.4,1.4,5.5,1.1,5.7-.6.4-7.4-8.5-15.1-22.7-8.2-15.2-9.1-21-14-22-5.4-1.1-12.7,4.6-15,11.1-3.2,9,4.5,15.2,1,20.9-2.9,4.7-9.5,2.3-24,5-12.5,2.3-12.6,5.1-31,10-21.7,5.8-25.1,2.9-26,2h0Z"/>
    <path id="WK_H2" class="Wirbelkorper_Dornfortsatze1514Fill" style= {{
            fill: props.colors.Wirbelkorper_Dornfortsatze1514Fill,
            stroke: props.colors.Wirbelkorper_Dornfortsatze1514Fill,
          }} d="M236.8,223.7c10.8,5.2,16.8-1.1,11.2-25.9-.2-1.1-.6-1.8-1.2-2.4-4.2-4.4-9.6-2.3-15.8-5.6-6.4-3.4-3.8-8.4-12.2-14.3-3.6-2.5-3.8-1.5-9.6-5.2-5.6-3.7-7.6-6.1-10.2-5.4-1.8.5-1.3,1.8-4.9,5.6-2.9,3.1-3.8,2.7-7.1,6.4-2,2.3-2.2,3-3.9,4.2-1.7,1.3-3.1,1.8-4.8,3.7-.7.8-1.1,1.4-1.4,2-1.6,4.7,10.2,12.1,18,19,4.9,4.4,7.9.9,15,4,7.2,3.2,11.5,2.1,14,9,2,5.5,1.5-.4,12.8,4.9h0Z"/>
    <ellipse id="WK_H1" class="BWSkorper4_lat1514_cls3" cx="157.6" cy="87.5" rx="10" ry="13.7"/>
  </g>
  <g id="BWK_H">
    <path id="BWK_H4" class="BWK1514Fill" style= {{
            fill: props.colors.BWK1514Fill,
            stroke: props.colors.BWK1514Fill,
          }} d="M17.4,100.1c-2.3-2.8,2-6.5,0-13-1.7-5.6-5.6-5.5-11-13-3.2-4.5-6.9-9.5-6-15,1.1-7,9.2-11.1,15-14,4.1-2,9.8-3.4,21-6,18.7-4.3,19.2-3.5,24-6,5.8-3.1,8.9-6.3,12-5,4.2,1.8,3,9.4,6,10,2.8.6,7.6-5.6,8-12,.4-6.6-7.4-8.2-7-14,.3-4.6,4.9-8.6,7-8,1.6.5,1,3.2,3.5,6.5.5.6,3.3,4.4,6.5,4.5,5,0,5.2-9.1,13-13,6.3-3.1,13.7-.8,16,0,.5.2,3.9,2.1,10.7,5.9,4.8,2.7,6.6,3.8,8.3,6.1,2.3,3,2.7,6.1,2.8,6.7.1.8.6,4.2-.8,7.3-2.6,5.4-10,6.5-16,8-8.9,2.2-13.1,0-14,2-1.6,3.4,8.2,13.3,20,19,9.1,4.4,15.1,4.2,16,8,.7,3.2-3.2,4.7-9,14-4.7,7.5-4.2,9.8-7,11-5.4,2.3-14-3-19-9-6.9-8.3-5.2-15.8-11-18-3.7-1.4-8.7,0-11,3-4,5.1,2.2,11.5-1,17-2.7,4.6-8.9,3.3-24,6-4,.7-11.7,2.8-27,7-17.1,4.7-23.6,7-26,4Z"/>
    <path id="BWK_H3" class="BWK1514Fill" style= {{
            fill: props.colors.BWK1514Fill,
            stroke: props.colors.BWK1514Fill,
          }} d="M235.4,134.1c1.4-2.7,0-4.1,0-11,0-8.6,2.3-9.4,1-13-1.3-3.4-5.5-5.7-14-10-6.4-3.3-6.7-2.8-13-6-4.7-2.4-9-5-14-8-8.8-5.3-16.8-7.9-18-7-2.6,1.8,10.6,11.8,7,19-3.4,6.9-13.1,5.4-14,10-1.1,5.7,13,13.3,20,17,6,3.2,5.3,2,13,6,11.7,6,14.1,9.1,20,9,4.3-.1,9.9-1.9,12-6Z"/>
    <path id="BWK_H2" class="BWK1514Fill" style= {{
            fill: props.colors.BWK1514Fill,
            stroke: props.colors.BWK1514Fill,
          }} d="M40.4,241.1c-1-1.7.3-6.8,3-17,2.7-10.1,3.8-11.6,3-15-1.4-6.2-6.2-6.3-8-13-1.3-4.8-.7-11.7,3-14,3.6-2.3,6.8,1.7,15,2,7.6.3,8.4-2.9,19-5,11.3-2.2,11.2,1.3,22-1,9.7-2.1,11.1-5.2,19-5,4.7.1,6.9,1.3,11,0,3.2-1,8.5-3.8,9-8,.6-5.2-6.8-7.7-6-12,.7-3.8,9.9-10.6,13-6,18,26.2,15.6,26.8,14,17-.2-1.2.3-1,2-8,1.6-6.6,7-17,16-18,9.2-1,16.1,8.4,18,11,2,2.7,7,9.5,5,16-.8,2.7-2.2,3.4-12,12-8.6,7.6-9.4,8.6-10,10-2.9,6.6,1,14.3,4,20,9,17.1,22,22.6,20,28-.8,2.2-3,1.4-11,6-7.7,4.5-10.4,8-12,7-2.6-1.6,4-11.1,0-17-3.1-4.6-9.1-1.7-14-7-3.6-3.9-1.7-6.7-5-14-.9-1.9-6.4-14.1-14-14-6,0-11.5,7.9-13,15-2.2,10.7,5.4,16.7,2,22-2.5,4-8.5,3.4-22,4-.7,0-11.1,1-32,3-27.4,2.6-36.3,5.5-39,1Z"/>
    <circle id="BWK_H1" class="BWK1514Fill" style= {{
            fill: props.colors.BWK1514Fill,
            stroke: props.colors.BWK1514Fill,
          }} cx="179.1" cy="161.3" r="8.8"/>
  </g>
  <g id="Platte">
    <path id="Platte4" class="Grund_Deckplatten1514Fill" style= {{
            fill: props.colors.Grund_Deckplatten1514Fill,
            stroke: props.colors.Grund_Deckplatten1514Fill,
          }} d="M95.4,82.4c0-.3-.1-.5-.2-.8-.4,0-.9,0-1.5.1,0,0-.1,0-.2,0-.1.2-.2.3-.3.5-.7.9-1.9,1.5-3.1,1.8-.2,0-.4,0-.7,0-.8.2-1.7.3-2.5.4-2.2.3-4.6,1.1-7,1.4-1.1.3-2.2.6-3.3.8-9.6,1.7-19.1,3.8-28.5,6.2-9.6,2.4-19.3,4.4-29.1,6.2-.5,0-1,0-1.4-.1-.3.1-.7.3-1,.5-.2.5-.4,1-.5,1.5,0,0,0,0,0,0,0,.1.2.3.2.4.2.2.4.4.5.5.3.2.6.4,1,.6,0,0,.2,0,.2,0,0,0,0,0,0,0,3.8-.3,7.7-.7,11.4-1.5,5-1.1,9.8-2.7,14.7-4.1,5-1.4,10.1-2.2,15.2-3.2,5.3-1,10.6-2,15.8-3.4,2.5-.7,4.9-1.3,7.5-1.6,2.4-.2,4.8-.4,7-1.2.2,0,.4,0,.6-.1,1-.4,2.1-1,3.1-1.9.5-.4,1.2-1,1.8-1.6,0-.5,0-1.1,0-1.6Z"/>
    <path id="Platte3" class="Grund_Deckplatten1514Fill" style= {{
            fill: props.colors.Grund_Deckplatten1514Fill,
            stroke: props.colors.Grund_Deckplatten1514Fill,
          }} d="M23.1,111.4c.7-.8,1.7-1.2,3.8-1.5,3.3-.6,5.9-.6,6.8-.6,4.4,0,4.7,0,6.5,0,.9,0,3.5-.1,8.2-1.1,10.6-2.1,21.3-6.2,22.2-6.5,8.2-3.2,12.3-4.8,13.2-5.3.2-.1,3.9-2.3,8.7-2.9.9-.1,2.6-.3,4.1.6,1.3.7,2.6,2.2,2.3,3-.4.9-2.5.9-3.5.8-2-.2-2.1-.6-3.6-.5-1.1,0-1.5,0-3.3.9-1.9.9-2.5.8-5.2,1.7-2,.7-3.2,1.3-4.4,1.8-3.1,1.4-10.5,4.4-17,6.4-9.8,3-14.6,3.9-20.8,3.8s-7.1.3-11.7.5-3.3,1.2-4.9,1.8c-1.1.5-1.8.8-2.1.5-.6-.5-.2-2.2.8-3.3Z"/>
    <path id="Platte2" class="Grund_Deckplatten1514Fill" style= {{
            fill: props.colors.Grund_Deckplatten1514Fill,
            stroke: props.colors.Grund_Deckplatten1514Fill,
          }} d="M35.7,172.6c1.9-.2,3.2.4,4.4.8,2,.5,3.5,0,6.8-.8,7.8-2,4.9-.8,12.8-2.9,3.3-.9,8.3-2.3,14.9-4.6,7.8-2.7,7.2-3,11.4-4.1,5.4-1.4,9.1-2.4,14-2.3,3.2,0,4.8-.2,7.9-.6,2.5-.4,3.6-.7,4.6-1.5.6-.5,1-1.1,2-1.5.2,0,1.9-.8,2.6-.2.6.6.1,2.5-.8,3.6-.8,1.1-2.3,2.2-7.6,2.9-3.3.4-4.4.3-7.4.5-4,.3-6.9.9-10.5,1.7-4.7,1-8.1,2.1-10.8,3-5.5,1.8-5.9,2.4-11.2,4.3-5.4,1.9-9.4,2.9-14.6,4.3-2.4.6-.3,0-14.4,2-.8.1-3.1.4-5-.9,0,0-1.8-1.4-1.5-2.4.3-.9,2-1.1,2.6-1.2Z"/>
    <path id="Platte1" class="Grund_Deckplatten1514Fill" style= {{
            fill: props.colors.Grund_Deckplatten1514Fill,
            stroke: props.colors.Grund_Deckplatten1514Fill,
          }} d="M40.8,179.9c.6-.3,1.6-.8,5.2.3,2.3.7,2.5,1,4.4,1.7,1.2.4,3.6,1,6.1,1.1,1.4,0,2.7-.2,6.8-1.5,6.3-2,6.3-2.5,9.6-3.3,3.6-.9,6.6-1,8.8-1.1,5-.1,5,.6,9.3.5,1.6,0,6.3-.3,11.7-2.3,4.5-1.7,4.4-2.8,7.6-3.2,3.7-.5,5-.6,8.4.2s2.3-.5,4.6,0c.7.2,2,.4,2.1,1.1,0,.6-.8,1.2-1.5,1.5-1.7.7-3.5,0-4.6-.5-3-1-5.8-.2-11.4,1.4-4.6,1.3-3.7,2.1-7.7,3.2-4.3,1.2-8,1-12.3.9-4.7-.1-4.3-.6-7.3-.5-5.4.2-9.8,1.8-11.1,2.3-3.6,1.4-3.8,2.1-6.8,2.7-2.6.6-4.9.5-6.8.5-3,0-4.5-.1-6.4-.8-1.8-.6-2.2-1.1-3.8-1.2-1.3,0-1.9.2-3.6,0-1-.1-2.1-.3-2.3-.9-.2-.6.5-1.5,1.2-2Z"/>
  </g>
</g>
<g id="BWS">
  <g id="WK">
    <path id="WK3" class="BWSkorper4_lat1514_cls5" d="M34.4,173.8c-3-3.1-2.6-8.7-2-19,.5-9,2-9.3,1-13-2-7.6-8.5-7.8-11-16-.5-1.5-2.3-8.1,1-12,2.9-3.5,8.2-2.9,14-3,9.2-.2,15.8-2.1,29-6,17.8-5.3,25.6-12.7,30-9,3.2,2.6.8,7.7,5,11,3.6,2.8,9.9,2.8,12,0,2.6-3.4-3.6-8.4-2-17,.8-4.1,3.3-8.9,6-9,2.4-.1,2.9,3.5,7.5,6.5.6.4,6.7,4.3,11.5,2.5,3.8-1.5,4-5.6,7-11,4.2-7.7,13.5-17.2,21-16,4,.7,7,5.8,13,16,7.3,12.5,8.4,16.2,7,19-.6,1.1-2,3.1-4.2,4.2-3.1,1.7-5.8.6-8,2.8-1.2,1.2-1.1,2.1-1.8,3-3.9,5.4-21.7-3.5-24,0-.7,1,0,3.1,10,16,8.5,11,11.9,14.5,10.7,18.1-1.1,3.4-4.9,3.5-6.7,8.9-.7,2.1-.2,2.3-1.3,6.2-1,3.7-1.7,4.5-1.7,6.8,0,3.4,1.4,5.5,1.1,5.7-.6.4-7.4-8.5-15.1-22.7-8.2-15.2-9.1-21-14-22-5.4-1.1-12.7,4.6-15,11.1-3.2,9,4.5,15.2,1,20.9-2.9,4.7-9.5,2.3-24,5-12.5,2.3-12.6,5.1-31,10-21.7,5.8-25.1,2.9-26,2h0Z"/>
    <path id="WK2" class="BWSkorper4_lat1514_cls5" d="M236.1,223.8c10.8,5.2,16.8-1.1,11.2-25.9-.2-1.1-.6-1.8-1.2-2.4-4.2-4.4-9.6-2.3-15.8-5.6-6.4-3.4-3.8-8.4-12.2-14.3-3.6-2.5-3.8-1.5-9.6-5.2-5.6-3.7-7.6-6.1-10.2-5.4-1.8.5-1.3,1.8-4.9,5.6-2.9,3.1-3.8,2.7-7.1,6.4-2,2.3-2.2,3-3.9,4.2-1.7,1.3-3.1,1.8-4.8,3.7-.7.8-1.1,1.4-1.4,2-1.6,4.7,10.2,12.1,18,19,4.9,4.4,7.9.9,15,4,7.2,3.2,11.5,2.1,14,9,2,5.5,1.5-.4,12.8,4.9h0Z"/>
    <ellipse id="WK1" class="BWSkorper4_lat1514_cls5" cx="157.4" cy="87.5" rx="10" ry="13.7"/>
  </g>
  <g id="BWK">
    <path id="BWK4" class="BWSkorper4_lat1514_cls6" d="M17.4,99.8c-2.3-2.8,2-6.5,0-13-1.7-5.6-5.6-5.5-11-13-3.2-4.5-6.9-9.5-6-15,1.1-7,9.2-11.1,15-14,4.1-2,9.8-3.4,21-6,18.7-4.3,19.2-3.5,24-6,5.8-3.1,8.9-6.3,12-5,4.2,1.8,3,9.4,6,10,2.8.6,7.6-5.6,8-12,.4-6.6-7.4-8.2-7-14,.3-4.6,4.9-8.6,7-8,1.6.5,1,3.2,3.5,6.5.5.6,3.3,4.4,6.5,4.5,5,0,5.2-9.1,13-13,6.3-3.1,13.7-.8,16,0,.5.2,3.9,2.1,10.7,5.9,4.8,2.7,6.6,3.8,8.3,6.1,2.3,3,2.7,6.1,2.8,6.7.1.8.6,4.2-.8,7.3-2.6,5.4-10,6.5-16,8-8.9,2.2-13.1,0-14,2-1.6,3.4,8.2,13.3,20,19,9.1,4.4,15.1,4.2,16,8,.7,3.2-3.2,4.7-9,14-4.7,7.5-4.2,9.8-7,11-5.4,2.3-14-3-19-9-6.9-8.3-5.2-15.8-11-18-3.7-1.4-8.7,0-11,3-4,5.1,2.2,11.5-1,17-2.7,4.6-8.9,3.3-24,6-4,.7-11.7,2.8-27,7-17.1,4.7-23.6,7-26,4Z"/>
    <path id="BWK3" class="BWSkorper4_lat1514_cls6" d="M235.4,133.8c1.4-2.7,0-4.1,0-11,0-8.6,2.3-9.4,1-13-1.3-3.4-5.5-5.7-14-10-6.4-3.3-6.7-2.8-13-6-4.7-2.4-9-5-14-8-8.8-5.3-16.8-7.9-18-7-2.6,1.8,10.6,11.8,7,19-3.4,6.9-13.1,5.4-14,10-1.1,5.7,13,13.3,20,17,6,3.2,5.3,2,13,6,11.7,6,14.1,9.1,20,9,4.3-.1,9.9-1.9,12-6Z"/>
    <path id="BWK2" class="BWSkorper4_lat1514_cls6" d="M40.4,240.8c-1-1.7.3-6.8,3-17,2.7-10.1,3.8-11.6,3-15-1.4-6.2-6.2-6.3-8-13-1.3-4.8-.7-11.7,3-14,3.6-2.3,6.8,1.7,15,2,7.6.3,8.4-2.9,19-5,11.3-2.2,11.2,1.3,22-1,9.7-2.1,11.1-5.2,19-5,4.7.1,6.9,1.3,11,0,3.2-1,8.5-3.8,9-8,.6-5.2-6.8-7.7-6-12,.7-3.8,9.9-10.6,13-6,18,26.2,15.6,26.8,14,17-.2-1.2.3-1,2-8,1.6-6.6,7-17,16-18,9.2-1,16.1,8.4,18,11,2,2.7,7,9.5,5,16-.8,2.7-2.2,3.4-12,12-8.6,7.6-9.4,8.6-10,10-2.9,6.6,1,14.3,4,20,9,17.1,22,22.6,20,28-.8,2.2-3,1.4-11,6-7.7,4.5-10.4,8-12,7-2.6-1.6,4-11.1,0-17-3.1-4.6-9.1-1.7-14-7-3.6-3.9-1.7-6.7-5-14-.9-1.9-6.4-14.1-14-14-6,0-11.5,7.9-13,15-2.2,10.7,5.4,16.7,2,22-2.5,4-8.5,3.4-22,4-.7,0-11.1,1-32,3-27.4,2.6-36.3,5.5-39,1Z"/>
    <circle id="BWK1" class="BWSkorper4_lat1514_cls6" cx="179.1" cy="161.1" r="8.8"/>
  </g>
</g>
</svg>
  );
}

export default Brustwirbelkorper4LatLiegend1514;
