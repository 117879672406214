import style from "./style.scss";

export default function NasennebenhohlenOnStehend1914(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 406.62 419.8">
      <g
        id="nasal_sinuses_"
        data-name="nasal sinuses

"
      >
        <path
          id="background"
          class="Nasennebenhöhlen_on_stehend1914_cls3"
          d="M.61,209.95C10.73,87.26,91.39,3.19,202.31.1c122.36-3.4,193.37,83.7,203.7,209.85,9.39,114.68-91.09,210.17-203.7,209.85-107.69-.31-211.22-94.34-201.7-209.85h0Z"
        />
        <path
          id="nasal_sinuses_2"
          data-name="nasal sinuses

2"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M137.87,404.43c2.46-4.07,6.53-9.56,12.99-14.35,2.51-1.86,7.7-5.65,15.04-7.52,8.06-2.05,14.55-.74,19.14,0,19.89,3.2,37.75,1.05,46.48,0,17.45-2.1,20.71-5.92,31.44-3.42,9.77,2.27,17.81,7.94,19.14,8.89,5,3.58,8.57,7.37,10.94,10.25"
        />
        <path
          id="nasal_sinuses_3"
          data-name="nasal sinuses

3"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M311.49,388.71c-6.89-4.67-11.43-9.43-14.35-12.99-7.51-9.15-7.56-14.41-15.72-20.51-2.89-2.16-4.94-3.03-9.57-6.84-4.76-3.92-7.37-6.1-8.89-9.57-1.11-2.53-1.42-5.26,0-13.67,1.91-11.31,3.66-13.35,5.47-22.56,2.33-11.86,1.29-18.01,3.42-18.46,2.32-.48,5.79,6.38,7.52,10.94,4.38,11.54,3.28,23.22,2.73,23.24-.93.03-5.13-33.04.68-34.86,2.09-.65,5.33,2.76,6.84,5.47,2.09,3.77,1.14,6.78,1.37,12.3.32,7.66,2.36,6.94,3.42,15.72,1.06,8.75-1,9.25,0,19.14.76,7.54,2.2,9.63.68,11.62-1.58,2.07-11.28-2.05-23.24.68"
        />
        <path
          id="nasal_sinuses_4"
          data-name="nasal sinuses

4"
          class="Nasennebenhöhlen_on_stehend1914_cls2"
          d="M311.49,318.3c5.67-2.09,5.91-17.12,6.15-32.13.15-9.61-.65-11.91,1.37-16.41,2.71-6.02,7.81-10.04,15.04-15.72,8.19-6.44,10.01-6.14,11.62-9.57,3.4-7.24-.06-18.57-6.84-22.56-4.62-2.72-7.24.04-12.3-3.42-4.04-2.76-3.5-5.3-8.89-9.57-3.55-2.81-4.06-1.93-7.52-4.78-5.25-4.33-4.82-6.96-8.2-8.2-4-1.47-5.58,1.85-10.94,1.37-3.08-.28-3.91-1.5-12.99-7.52-9.32-6.18-11.37-6.82-13.67-6.84-.44,0-6.26.05-9.57,4.1-1.71,2.09-1.44,3.65-2.73,10.25-1.72,8.8-2.55,7.86-2.73,11.62-.33,6.86,2.56,7.4,2.73,15.04.16,6.76-2.08,7.92-.68,12.3,1.2,3.78,3.43,4.7,6.84,8.89,3.69,4.53,4.02,7.06,7.52,14.35,3.77,7.87,6.89,14.37,12.3,20.51,8.12,9.2,13.44,8.54,16.41,16.41,1.91,5.07.29,6.89,2.73,11.62,3.04,5.89,10.07,11.83,14.35,10.25h0Z"
        />
        <path
          id="nasal_sinuses_5"
          data-name="nasal sinuses

5"
          class="Nasennebenhöhlen_on_stehend1914_cls2"
          d="M111.9,338.13c6.27.7,13.04-15.7,19.82-32.13,8.95-21.68,6.33-24.24,14.35-38.28,5.47-9.56,8.96-12.34,10.25-20.51,1.06-6.68.52-16.13-4.1-24.61-3.99-7.31-7.01-6.54-10.25-14.35-2.67-6.43-2.1-10.5-6.15-13.67-2.18-1.71-6.1-3.46-8.89-2.05-3.2,1.62-1.59,5.73-5.47,10.94-2.76,3.71-4.39,2.71-8.2,6.84-4.86,5.26-3.5,8.3-6.84,10.94-4.27,3.38-8.53,0-19.14,0-4.51,0-19.23,0-24.61,8.2-.99,1.52-.65,1.77-2.73,14.35-2.02,12.21-2.41,12.44-2.05,14.35,1.11,5.87,5.46,7.18,17.09,17.09,10.15,8.66,15.31,13.13,19.14,20.51,2.85,5.48,1.71,6.25,5.47,17.77,4.94,15.17,7.86,24.11,12.3,24.61h0Z"
        />
        <path
          id="nasal_sinuses_6"
          data-name="nasal sinuses

6"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M245.19,319.67c-5.48-6.25,1.97-15.92,4.78-37.59,1.96-15.13.19-24.78,2.73-25.29.59-.12,2.09.11,8.2,12.99,6.06,12.76,10.07,24.1,12.3,37.59,1.46,8.85,1,11.63-.68,13.67-4.6,5.59-16.21,3.07-17.77,2.73-4.22-.92-7.38-1.6-9.57-4.1h0Z"
        />
        <path
          id="nasal_sinuses_7"
          data-name="nasal sinuses

7"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M228.78,306c-3.05-4.92,2.41-8.21,2.05-21.19-.22-8.02-2.45-12.09,0-19.14.41-1.17,2.41-6.93,4.78-6.84,2.58.1,3.78,7.04,6.84,19.14,5.99,23.74,9.21,28.22,6.15,31.44-4.15,4.37-16.16,2.51-19.82-3.42h0Z"
        />
        <path
          id="nasal_sinuses_8"
          data-name="nasal sinuses

8"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M199.39,306c-3.35-3.86,1.36-7.44,3.42-28.03.01-.11.88-8.83,4.1-19.82,1.48-5.07,2.34-6.78,3.42-6.84,2.74-.15,5.48,10.47,6.84,15.72,1.25,4.86,1.43,6.9,2.73,14.35,3.07,17.49,4.37,19.14,2.73,21.87-3.97,6.62-18.47,8.24-23.24,2.73v.02Z"
        />
        <path
          id="nasal_sinuses_9"
          data-name="nasal sinuses

9"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M175.47,303.26c-3.29-3.55,1.04-8.98,3.42-23.92,1.47-9.24.51-11.52,2.73-21.19,1.15-5,3.43-13.17,4.78-12.99,1.23.17.09,7.07,2.05,17.77,1.33,7.29,2.47,7.41,4.78,17.09,2.77,11.59,4.21,17.98.68,21.87-2.45,2.7-6.39,3.19-8.2,3.42-1.56.19-7.49.93-10.25-2.05h.01Z"
        />
        <path
          id="nasal_sinuses_10"
          data-name="nasal sinuses

10"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M160.43,312.15c-4.3-3.19-1.44-13.99,1.37-24.61,2.05-7.76,2.56-6.65,5.47-17.09,2.19-7.86,2.77-11.59,4.1-11.62,1.74-.05,3.6,6.22,4.1,11.62.58,6.28-.98,8.3-1.37,13.67-.98,13.79,7.91,20.43,4.1,25.29-2.22,2.83-7.27,3.17-10.94,3.42-3.62.24-5.42.36-6.84-.68h0Z"
        />
        <path
          id="nasal_sinuses_11"
          data-name="nasal sinuses

11"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M126.25,275.92c-2.21,2.7-5.21,7.06-6.84,12.99-1.68,6.12-1.03,10.77-.68,15.04,1.92,23.69-5.76,32.74.68,38.96,2.64,2.55,6.09,3.07,12.99,4.1,6.27.94,11.44,1.71,15.72-1.37,5.25-3.78,7.43-12.08,4.78-15.04-1.31-1.47-4.07-1.44-9.57-1.37-6.33.08-7.91,1.07-9.57,0-1.81-1.16-6.39-7.56-4.47-17.42"
        />
        <line
          id="nasal_sinuses_12"
          data-name="nasal sinuses

12"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          x1="132.4"
          y1="275.92"
          x2="124.2"
          y2="305.54"
        />
        <path
          id="nasal_sinuses_13"
          data-name="nasal sinuses

13"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M132.4,275.92c5.36,0,2.73,21.24,2.73,21.24"
        />
        <line
          id="nasal_sinuses_14"
          data-name="nasal sinuses

14"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          x1="140.61"
          y1="265.08"
          x2="135.14"
          y2="285.92"
        />
        <path
          id="nasal_sinuses_15"
          data-name="nasal sinuses

15"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M149.74,261.73c8.33,10.98,2.33,15.98.44,31.97"
        />
        <path
          id="nasal_sinuses_16"
          data-name="nasal sinuses

16"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M165.82,275.35c-.07-30.98-7.06-17.18-15.64,18.35"
        />
        <path
          id="nasal_sinuses_17"
          data-name="nasal sinuses

17"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M364.12,342.91c-7.98-.27-14.5-1.54-19.14-2.73-7.02-1.81-14.4-3.79-21.87-9.57-3.82-2.95-6.49-6-8.2-8.2"
        />
        <path
          id="nasal_sinuses_18"
          data-name="nasal sinuses

18"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M392.15,297.17c-22.77-.98-26.59,16.88-17.09,32.76"
        />
        <path
          id="nasal_sinuses_19"
          data-name="nasal sinuses

19"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M397.62,273.19c-6.07-2.41-10.76-5.37-14.35-8.2-6.43-5.07-7.96-8.62-12.3-8.89-5.04-.31-5.86,4.3-12.99,5.47-5.26.86-5.88-1.48-12.99-1.37-3.07.05-9.2.22-15.04,4.1-5.12,3.4-7.68,8.11-8.89,10.94"
        />
        <path
          id="nasal_sinuses_20"
          data-name="nasal sinuses

20"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M405.82,241.06c-4.97-3.34-11.67-8.95-15.72-17.77-2.72-5.93-2.25-8.97-4.1-20.51-1.14-7.08-3.67-20.6-8.2-33.49-4.07-11.57-4.91-8.86-11.62-25.29-4.89-11.99-8.57-21.15-10.25-33.49-1.62-11.9-.44-19.73-1.37-19.82-1.27-.13-3.21,14.6-7.52,36.23-3.49,17.51-6.73,33.49-12.99,51.95-4.01,11.81-8.09,21.21-10.94,27.34"
        />
        <path
          id="nasal_sinuses_21"
          data-name="nasal sinuses

21"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M370.28,173.39c-19.31,1.75-38.01,6.4-45.8,10.25-8.44,4.18-12.65,7.95-13.67,8.89-6.6,6.04-7.05,9.82-16.41,24.61-5.5,8.7-9.07,14.28-15.04,20.51-5.21,5.44-14.3,9.91-17.77,12.3"
        />
        <path
          id="nasal_sinuses_22"
          data-name="nasal sinuses

22"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M215.11,116.66c.34,6.07,1.8,14.97,7.52,23.24,4.98,7.2,10.67,10.28,20.51,16.41,22.97,14.29,17.49,13.36,33.49,22.56,27.29,15.69,40.5,16.85,44.43,17.09,7.09.44,14.67.79,21.19-4.1,10.13-7.61,10.23-22.3,10.25-25.29.03-4.95-.91-5.57-4.1-18.46-5.49-22.12-3.74-24.39-8.2-32.13-2.05-3.56-6.93-12-16.41-16.41-5.88-2.73-6.88-.83-17.09-4.78-5.39-2.09-8.82-4.06-14.35-4.1-3.48-.02-6.34.73-8.2,1.37"
        />
        <path
          id="nasal_sinuses_23"
          data-name="nasal sinuses

23"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M243.23,173.39c-4.41,41.01,93.99,57.03,103.76,13.5"
        />
        <path
          id="nasal_sinuses_24"
          data-name="nasal sinuses

24"
          class="Nasennebenhöhlen_on_stehend1914_cls2"
          d="M247.24,108.45c5.32-7.03,10.58-9.75,14.35-10.94,5.22-1.64,7.7-.39,14.35-2.73,4.45-1.57,10.48-3.69,11.62-8.2.76-3-1.32-6.1-5.47-12.3-2.32-3.46-4.4-6.57-8.2-9.57-4.98-3.93-6.45-2.56-9.57-5.47-6.05-5.65-2.35-12.53-7.52-17.09-4.22-3.71-11.55-3.43-16.41-1.37-9.85,4.19-12.82,17.17-15.04,16.41-1.74-.6,1.26-8.18-2.73-12.3-3.48-3.59-9.9-2.14-10.25-2.05-8.42,2.02-11.88,12.35-12.3,13.67-2.69,8.43,1.49,11.45-2.05,18.46-2.65,5.24-5.96,5.48-6.15,9.57-.15,3.14,1.77,3.76,4.78,11.62,1.69,4.41,1.46,5.19,2.73,6.84,2.64,3.42,7.03,4.45,10.25,4.78"
        />
        <path
          id="nasal_sinuses_25"
          data-name="nasal sinuses

25"
          class="Nasennebenhöhlen_on_stehend1914_cls2"
          d="M105.74,111.87c-3.37-1.42-8.82-3.11-15.04-2.05-9.8,1.67-14.21,8.93-19.14,6.84-3.66-1.55-4.81-7.06-4.78-10.25.06-7.77,7.17-13.07,8.89-14.35,7.05-5.26,12.93-3.99,14.35-8.2,1.32-3.88-3.07-6.73-1.37-10.25,1.53-3.14,5.47-1.77,9.57-5.47,3.76-3.39,2.14-6.07,5.47-10.25,1.31-1.64,2.28-2.13,15.04-7.52,12.89-5.44,13.39-5.57,15.04-6.84,2.67-2.05,3.98-3.71,6.84-4.1,1.11-.15,2.31-.09,8.89,2.05,6.08,1.98,6.76,2.48,8.2,2.05,3.26-.98,2.95-4.51,6.15-5.47,3.6-1.08,6.52,2.63,8.2,1.37,2.02-1.51-1.62-7.31.68-9.57,1.63-1.6,4.14.64,8.89,0,6-.81,6.89-5.03,10.94-4.78,4.5.28,8.59,5.85,9.57,10.94.7,3.64-.66,7.04-3.42,13.67-8.32,20.02-8.45,20.25-8.89,21.87-2.04,7.52-3.53,13.04-3.42,20.51.05,2.99.35,6.11-1.37,9.57-1.96,3.95-5.49,6.21-8.2,7.52"
        />
        <path
          id="nasal_sinuses_26"
          data-name="nasal sinuses

26"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M161.11,123.49c.59,3.61.88,8.91-1.37,14.35-1.9,4.59-4.6,6.84-10.25,12.99-4.05,4.4-7.67,9.18-11.62,13.67-24.08,27.43-22.92,26.5-23.92,27.34-4.89,4.12-3.04,1.56-41.01,19.82-4.11,1.98-9.08,4.39-14.35,2.73-5.96-1.87-8.88-7.85-11.62-13.67-7.47-15.85-6.58-30.84-6.15-35.54.49-5.41,1.48-15.26,7.52-25.97,4.54-8.06,9.91-13,14.35-17.09,7.11-6.54,11.37-10.47,17.77-12.3,12.62-3.62,24.6,3.45,28.71,6.15"
        />
        <path
          id="nasal_sinuses_27"
          data-name="nasal sinuses

27"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M31.92,108.45c5.12,25.76,11.1,44.69,15.72,57.42,3.18,8.76,6.03,15.43,10.94,30.08,3.14,9.36,7.76,18.32,9.57,28.03.68,3.63,1.73,10.52,6.84,17.09,1.97,2.54,4.01,4.33,5.47,5.47"
        />
        <path
          id="nasal_sinuses_28"
          data-name="nasal sinuses

28"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M138.55,247.21c-2.42-4.72-6.2-11.5-11.62-19.14-7.48-10.54-12.86-18.11-22.56-24.61-11.67-7.82-23.55-10.03-28.71-10.94-10.5-1.85-12.51-.02-24.61-2.73-12.05-2.71-18.71-6.47-20.51-7.52-6.35-3.71-10.84-7.91-13.67-10.94"
        />
        <path
          id="nasal_sinuses_29"
          data-name="nasal sinuses

29"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M27.14,118.71c1.31,3.45,2.89,8.63,3.42,15.04.56,6.79-.27,12.52-3.42,25.29-4.01,16.27-4.81,15.69-7.52,27.34-1.75,7.52-3.91,17-4.78,29.39-.72,10.16.18,11.66-.68,19.14-1.3,11.31-4.78,20.21-7.52,25.97"
        />
        <path
          id="nasal_sinuses_30"
          data-name="nasal sinuses

30"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M14.15,290.7h11.62l6.15-7.34h4.27c2.82,0,5.57-.9,7.84-2.58l4.17-3.07,2.43,1.72c3.37,2.38,7.83,2.5,11.32.3l3.21-2.02,9.82-2.47"
        />
        <path
          id="nasal_sinuses_31"
          data-name="nasal sinuses

31"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M105.47,330.61c-5.67,12.52-27.88,23.3-49,24.55.04-2.53.49-5.69,2.11-8.83,2.35-4.57,5-4.95,6.15-8.89,1.33-4.57-.6-9.73-3.42-12.99-3.91-4.53-9.29-5.13-12.3-5.47-8.69-.97-15.4,3.16-17.77,4.78"
        />
        <path
          id="nasal_sinuses_32"
          data-name="nasal sinuses

32"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M148.12,345.64c-3.95,21.04-20.73,42.11-35.54,50.58"
        />
        <path
          id="nasal_sinuses_34"
          data-name="nasal sinuses

34"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M160.43,312.15c1.37,15.72-3.36,18.46-7.52,18.46"
        />
        <line
          id="nasal_sinuses_35"
          data-name="nasal sinuses

35"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          x1="230.57"
          y1="291.73"
          x2="221.26"
          y2="291.82"
        />
        <line
          id="nasal_sinuses_36"
          data-name="nasal sinuses

36"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          x1="200.89"
          y1="290.73"
          x2="195.77"
          y2="292.21"
        />
        <line
          id="nasal_sinuses_37"
          data-name="nasal sinuses

37"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          x1="202.31"
          y1="230.13"
          x2="195.77"
          y2="292.21"
        />
        <path
          id="nasal_sinuses_38"
          data-name="nasal sinuses

38"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M187.77,126.23c-1.4.1-2.6,1.4-6.15,8.89-2.12,4.47-3.18,6.71-4.78,10.94-3.15,8.32-2.89,9.34-4.78,13.67-2.65,6.05-4.15,6.31-7.52,12.3-6.41,11.4-7.67,22.32-8.2,27.34-.68,6.43-1.02,9.65,0,13.67,2.02,7.97,6.31,9.03,7.52,17.09.82,5.45-.76,7.55,1.37,10.94,2.71,4.32,8.01,5.31,8.89,5.47,8.56,1.59,15.45-5.19,16.41-6.15,2.69-2.73,6.07-7.68,6.84-20.51.85-14.22-2.61-19.53-6.84-43.06-1.81-10.09-3.1-17.41-3.42-27.34-.57-17.59,2.59-31.51,3.42-31.44.75.06-1.43,11.48,2.05,27.34,2.29,10.45,4.08,9.3,5.47,18.46.61,3.98,1.4,11.62,2.73,24.61.3,2.91.61,5.95,2.73,8.89,2.15,2.96,4.27,3.22,4.78,5.47.66,2.92-2.46,4.5-4.78,8.89-3.72,7.02-1.99,14.8-1.18,18.46.79,3.56,1.72,7.76,5.29,11.62,1.1,1.2,6.29,6.81,13.67,6.15,5.32-.47,8.74-3.94,10.25-5.47,6.05-6.13,6.16-14.2,6.15-15.72-.04-6.08-2.57-6.3-4.01-14.75-1.49-8.73,1.04-9.49-.78-16.01-1.22-4.39-3.78-7.94-8.89-15.04-1.95-2.71-3.81-5-7.52-9.57-7.6-9.36-7.46-8.52-9.57-11.62-4.56-6.71-7.85-11.56-7.52-17.77.26-4.83,2.6-8.33.68-10.25-1.16-1.16-2.4-.26-5.47-1.37-4.23-1.52-4.72-4.25-6.84-4.1v-.03Z"
        />
        <path
          id="nasal_sinuses_39"
          data-name="nasal sinuses

39"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M149.49,202.78c-1.2-2.84-2.55-7.19-2.05-12.3.6-6.19,3.56-10.07,6.84-15.04,4.71-7.15-.34-.74,14.35-26.66,7.92-13.98,11.03-18.73,8.89-22.56-2.39-4.27-9.63-4.43-9.57-7.52.02-.91.65-1.4,4.1-4.78,2.12-2.08,3.79-3.77,4.78-4.78"
        />
        <path
          id="nasal_sinuses_40"
          data-name="nasal sinuses

40"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M178.88,117.34c1.42-.85,3.56-2.35,5.47-4.78,3.53-4.52,3.32-8.51,5.47-8.89,1.71-.3,3.43,1.94,6.15,5.47,3.01,3.9,4.66,7.26,4.78,7.52,2.58,5.33,4.26,10.49,6.15,17.77,3.22,12.4,2.23,12.44,4.1,15.72,2.11,3.7,3.63,4.1,12.3,11.62,8.58,7.44,12.93,11.25,15.72,15.72,2.11,3.37,3.77,6.29,4.53,14.52"
        />
        <path
          id="nasal_sinuses_41"
          data-name="nasal sinuses

41"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M283.81,194.58c0,8.69-8.83,11.61-14.69,11.61"
        />
        <path
          id="nasal_sinuses_42"
          data-name="nasal sinuses

42"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M290.99,197.32c-7.52,6.15,9.17,14.35,20.51,14.35"
        />
        <ellipse
          id="nasal_sinuses_43"
          data-name="nasal sinuses

43"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="284.46"
          cy="206.03"
          rx="6.58"
          ry="3.25"
        />
        <path
          id="nasal_sinuses_44"
          data-name="nasal sinuses

44"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M249.26,202.78c-2.33,4.16-.93,6.49-5.7,6.49s-8.63-3.86-8.63-8.63,4.97-11.68,8.63-8.63c13.46,11.22,7.24,8,5.7,10.77h0Z"
        />
        <circle
          id="nasal_sinuses_45"
          data-name="nasal sinuses

45"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="150.18"
          cy="242.43"
          r="7.52"
        />
        <ellipse
          id="nasal_sinuses_46"
          data-name="nasal sinuses

46"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="98.91"
          cy="216.45"
          rx="7.52"
          ry="4.78"
        />
        <ellipse
          id="nasal_sinuses_47"
          data-name="nasal sinuses

47"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="120.78"
          cy="209.95"
          rx="7.53"
          ry="3.76"
        />
        <circle
          id="nasal_sinuses_48"
          data-name="nasal sinuses

48"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="223.19"
          cy="153.77"
          r="4.66"
        />
        <ellipse
          id="nasal_sinuses_49"
          data-name="nasal sinuses

49"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="216.02"
          cy="137.51"
          rx="5.24"
          ry="3.76"
        />
        <circle
          id="nasal_sinuses_50"
          data-name="nasal sinuses

50"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="157.35"
          cy="152.43"
          r="3.32"
        />
        <circle
          id="nasal_sinuses_51"
          data-name="nasal sinuses

51"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="169.44"
          cy="137.36"
          r="3.62"
        />
        <circle
          id="nasal_sinuses_52"
          data-name="nasal sinuses

52"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="156.79"
          cy="165.76"
          r="3.88"
        />
        <circle
          id="nasal_sinuses_53"
          data-name="nasal sinuses

53"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="163.71"
          cy="149.09"
          r="3.04"
        />
        <ellipse
          id="nasal_sinuses_54"
          data-name="nasal sinuses

54"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="213.06"
          cy="146.79"
          rx="2.05"
          ry="3.36"
        />
        <path
          id="nasal_sinuses_55"
          data-name="nasal sinuses

55"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M167.95,145.11c-3.62-4.13,2.8,1.68-.04,1.68s-5.15-.75-5.15-1.68,2.31-1.68,5.15-1.68.65,2.38.04,1.68Z"
        />
        <circle
          id="nasal_sinuses_56"
          data-name="nasal sinuses

56"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          cx="242.93"
          cy="218.1"
          r="3.14"
        />
        <path
          id="nasal_sinuses_57"
          data-name="nasal sinuses

57"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M269.12,212.79c0,19.96-9.42,36.99-10.94,32.37"
        />
        <path
          id="nasal_sinuses_58"
          data-name="nasal sinuses

58"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M251.43,230.06s2.33-12.66,15.63-.7"
        />
        <path
          id="nasal_sinuses_59"
          data-name="nasal sinuses

59"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M207.59,241.75c0,3.4,5.47,23.33,13.67,6.15"
        />
        <path
          id="nasal_sinuses_60"
          data-name="nasal sinuses

60"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M140.65,204.49c-8.09,14.67-49.08,23.98-82.07,9.91"
        />
        <path
          id="nasal_sinuses_61"
          data-name="nasal sinuses

61"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M121.47,203.47c0,.57,10.94,5.81,19.18,1.03"
        />
        <path
          id="nasal_sinuses_62"
          data-name="nasal sinuses

62"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M90.02,83.85c0,3.78,15.45-4.1,22.56,6.83"
        />
        <polyline
          id="nasal_sinuses_63"
          data-name="nasal sinuses

63"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          points="345.44 134.86 352.51 137.51 352.51 130.33 359.34 137.51 359.34 130.33 363.55 137.51"
        />
        <path
          id="nasal_sinuses_64"
          data-name="nasal sinuses

64"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          d="M230.05,50.8c0,1.26,10.35-10.02,19.21,2.28"
        />
        <polyline
          id="nasal_sinuses_65"
          data-name="nasal sinuses

65"
          class="Nasennebenhöhlen_on_stehend1914_cls1"
          points="30.72 147.83 31.92 153.77 36.02 147.83 36.02 153.77 42.05 149.09"
        />
      </g>
      <g id="overlay">
        <g
          id="orbitae_overlay"
          data-name="orbitae

 overlay"
        >
          <path
            id="orbitae_overlay1"
            data-name="orbitae

 overlay1"
            class="orbita1914Fill"
            style={{
              fill: props.colors.orbita1914Fill,
              stroke: props.colors.orbita1914Fill,
            }}
            d="M215.11,116.66c.34,6.07,1.8,14.97,7.52,23.24,4.98,7.2,10.67,10.28,20.51,16.41,22.97,14.29,17.49,13.36,33.49,22.56,27.29,15.69,40.5,16.85,44.43,17.09,7.09.44,14.67.79,21.19-4.1,10.13-7.61,10.23-22.3,10.25-25.29.03-4.95-.91-5.57-4.1-18.46-5.49-22.12-3.74-24.39-8.2-32.13-2.05-3.56-6.93-12-16.41-16.41-5.88-2.73-6.88-.83-17.09-4.78-5.39-2.09-8.82-4.06-14.35-4.1-3.48-.02-6.34.73-8.2,1.37"
          />
          <path
            id="orbitae_overlay2"
            data-name="orbitae

 overlay2"
            class="orbita1914Fill"
            style={{
              fill: props.colors.orbita1914Fill,
              stroke: props.colors.orbita1914Fill,
            }}
            d="M161.37,123.49c.59,3.61.88,8.91-1.37,14.35-1.9,4.59-4.6,6.84-10.25,12.99-4.05,4.4-7.67,9.18-11.62,13.67-24.08,27.43-22.92,26.5-23.92,27.34-4.89,4.12-3.04,1.56-41.01,19.82-4.11,1.98-9.08,4.39-14.35,2.73-5.96-1.87-8.88-7.85-11.62-13.67-7.47-15.85-6.58-30.84-6.15-35.54.49-5.41,1.48-15.26,7.52-25.97,4.54-8.06,9.91-13,14.35-17.09,7.11-6.54,11.37-10.47,17.77-12.3,12.62-3.62,24.6,3.45,28.71,6.15"
          />
        </g>
        <g
          id="Maxillary_sinus_arch_overlay"
          data-name="Maxillary sinus arch

 overlay"
        >
          <path
            id="Maxillary_sinus_arch_overlay1"
            data-name="Maxillary sinus arch

 overlay1"
            class="Oberkieferhöhlenbogen1914Fill"
            style={{
              fill: props.colors.Oberkieferhöhlenbogen1914Fill,
              stroke: props.colors.Oberkieferhöhlenbogen1914Fill,
            }}
            d="M311.49,318.86c5.67-2.09,5.91-17.12,6.15-32.13.15-9.61-.65-11.91,1.37-16.41,2.71-6.02,7.81-10.04,15.04-15.72,8.19-6.44,10.01-6.14,11.62-9.57,3.4-7.24-.06-18.57-6.84-22.56-4.62-2.72-7.24.04-12.3-3.42-4.04-2.76-3.5-5.3-8.89-9.57-3.55-2.81-4.06-1.93-7.52-4.78-5.25-4.33-4.82-6.96-8.2-8.2-4-1.47-5.58,1.85-10.94,1.37-3.08-.28-3.91-1.5-12.99-7.52-9.32-6.18-11.37-6.82-13.67-6.84-.44,0-6.26.05-9.57,4.1-1.71,2.09-1.44,3.65-2.73,10.25-1.72,8.8-2.55,7.86-2.73,11.62-.33,6.86,2.56,7.4,2.73,15.04.16,6.76-2.08,7.92-.68,12.3,1.2,3.78,3.43,4.7,6.84,8.89,3.69,4.53,4.02,7.06,7.52,14.35,3.77,7.87,6.89,14.37,12.3,20.51,8.12,9.2,13.44,8.54,16.41,16.41,1.91,5.07.29,6.89,2.73,11.62,3.04,5.89,10.07,11.83,14.35,10.25h0Z"
          />
          <path
            id="Maxillary_sinus_arch_overlay2"
            data-name="Maxillary sinus arch

 overlay2"
            class="Oberkieferhöhlenbogen1914Fill"
            style={{
              fill: props.colors.Oberkieferhöhlenbogen1914Fill,
              stroke: props.colors.Oberkieferhöhlenbogen1914Fill,
            }}
            d="M111.71,338.12c6.27.7,13.04-15.7,19.82-32.13,8.95-21.68,6.33-24.24,14.35-38.28,5.47-9.56,8.96-12.34,10.25-20.51,1.06-6.68.52-16.13-4.1-24.61-3.99-7.31-7.01-6.54-10.25-14.35-2.67-6.43-2.1-10.5-6.15-13.67-2.18-1.71-6.1-3.46-8.89-2.05-3.2,1.62-1.59,5.73-5.47,10.94-2.76,3.71-4.39,2.71-8.2,6.84-4.86,5.26-3.5,8.3-6.84,10.94-4.27,3.38-8.53,0-19.14,0-4.51,0-19.23,0-24.61,8.2-.99,1.52-.65,1.77-2.73,14.35-2.02,12.21-2.41,12.44-2.05,14.35,1.11,5.87,5.46,7.18,17.09,17.09,10.15,8.66,15.31,13.13,19.14,20.51,2.85,5.48,1.71,6.25,5.47,17.77,4.94,15.17,7.86,24.11,12.3,24.61h0Z"
          />
        </g>
        <rect
          id="Sphenoid_sinus_overlay"
          data-name="Sphenoid sinus

 overlay"
          class="Keilbeinhöhle1914Fill"
          style={{
            fill: props.colors.Keilbeinhöhle1914Fill,
            stroke: props.colors.Keilbeinhöhle1914Fill,
          }}
          x="163.71"
          y="311.63"
          width="86.07"
          height="47.15"
          rx="23.58"
          ry="23.58"
        />
        <g
          id="Rock_bone_upper_edges_overlay"
          data-name="Rock bone upper edges

 overlay"
        >
          <path
            id="Rock_bone_upper_edges_overlay1"
            data-name="Rock bone upper edges

 overlay1"
            class="Felsenbeinoberkante1914Fill"
            style={{
              fill: props.colors.Felsenbeinoberkante1914Fill,
              stroke: props.colors.Felsenbeinoberkante1914Fill,
            }}
            d="M370.28,173.3h0c0-2.05-1.68-3.7-3.73-3.67-19.52.27-35.94,5.02-50.37,11.62-6.84,3.13-12.49,8.41-15.84,15.14-8.86,17.78-21.54,30.26-37.41,44.68-2.16,1.96-2.73,5.14-1.38,7.73h0c1.22,2.35,4.09,3.26,6.48,2.11,17.27-8.32,28.17-21.36,38.8-40.7,4.62-8.41,15.54-18.6,24.34-22.42,10.12-4.39,22.02-7.43,35.02-9.63,2.37-.4,4.1-2.45,4.1-4.85Z"
          />
          <path
            id="Rock_bone_upper_edges_overlay2"
            data-name="Rock bone upper edges

 overlay2"
            class="Felsenbeinoberkante1914Fill"
            style={{
              fill: props.colors.Felsenbeinoberkante1914Fill,
              stroke: props.colors.Felsenbeinoberkante1914Fill,
            }}
            d="M16.9,171.11h0c1.08-.89,2.58-1.03,3.81-.38,13.94,7.45,27.19,12.87,38.64,12.99,6.46.07,12.92.47,19.25,1.77,32.87,6.79,51.13,28.28,61.41,57.81.39,1.11.05,2.36-.84,3.13h0c-2.23,1.94-5.61,1.7-7.54-.54l-30.6-35.38c-7.34-7.46-20.05-10.67-30.42-12.08-26.33-3.58-42.85-8.2-54.26-22.15-1.28-1.57-1.01-3.89.55-5.18Z"
          />
        </g>
      </g>
    </svg>
  );
}
