import style from "./style.scss";

function LinkeGroszeheLatSitzenLinks(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140.01 491.96">

    <g id="big_toe" data-name="big toe">
      <path
        id="toe_2.2"
        data-name="toe 2.2"
        class="a1403_Großzeh_lat_links_cls-3"
        style={{
          fill: props.colors.a1403_Großzeh_lat_links_cls-3,
          stroke: props.colors.a1403_Großzeh_lat_links_cls-3,
        }}
        d="M96.07,426.4c-17.11-2.48-17.7-20.65-12.01-44.77,8.38-41.52,14.04-80.76,15.29-116.3-3.46-39.04-7.33-70.6,19.11-64.43,14.17,6.14,26.45,13.69,19.11,36.04-8.65,11.61-14.19,24.35-12.01,39.86l-1.64,153.97"
      />

      <path
        id="toe_2.2-2"
        data-name="toe 2.2"
        class="a1403_Großzeh_lat_links_cls-3"
        style={{
          fill: props.colors.a1403_Großzeh_lat_links_cls-3,
          stroke: props.colors.a1403_Großzeh_lat_links_cls-3,
        }}
        d="M135.93,202.54c-11.64-7.28-17.91-8.01-22.39-6.55-6.7-3.44-3.88-8.95,2.73-15.29,12.73-7.07,18.17-19.34,15.83-37.13"
      />

      <path
        id="big_toe_1"
        data-name="big toe 1"
        class="a1403_Großzeh_lat_links_cls-2"
        style={{
          fill: props.colors.a1403_Großzeh_lat_links_cls-2,
          stroke: props.colors.a1403_Großzeh_lat_links_cls-2,
        }}
        d="M72.05,54.04c6.29-13.13-1.65-22.7-12.01-31.67-14.5-4.69-15.64,8.77-9.83,31.67-1.23,16.1-5,28.91-11.47,38.22,1.79,6.49,7.39,6.16,14.74,2.73,6.42-3.5,12.63-3.78,18.56,0,9.89,1.75,15.27-1.44,10.92-15.29l-10.92-25.66h.01Z"
      />

      <path
        id="big_toe_2"
        data-name="big toe 2"
        class="a1403_Großzeh_lat_links_cls-2"
        style={{
          fill: props.colors.a1403_Großzeh_lat_links_cls-2,
          stroke: props.colors.a1403_Großzeh_lat_links_cls-2,
        }}
        d="M53.48,101.54c-13.7-5.99-19.63-3.01-17.47,9.28,4.94,29.77,5.76,54.36-1.09,69.34-2.78,17.13-3.23,31.3,9.28,28.94,12.41-7.38,25.61-9.6,39.86-4.91,8.72,3.32,14.14,2.05,13.1-8.19,3.35-10.79-7.17-26.01-20.2-42.04-7.23-11.78-6.37-23.8,1.64-36.04,7.31-17.57-4.47-20.4-25.12-16.38h0Z"
      />

      <path
        id="big_toe_3"
        data-name="big toe 3"
        class="a1403_Großzeh_lat_links_cls-2"
        style={{
          fill: props.colors.a1403_Großzeh_lat_links_cls-2,
          stroke: props.colors.a1403_Großzeh_lat_links_cls-2,
        }}
        d="M36.01,241.86c-7.86-12.41-3.56-23.65,17.47-33.31,9.75-3.52,18.97-4,27.3.55,18.97,16.97,26.96,35.16,13.65,55.69-11.39,28.34-12.93,54.94-1.09,79.17,20.82,51.59,32.52,91.17,10.92,86.81-20.69-7.22-41.26-9.47-61.7-6.55-16.28,6.15-14.08-13.29-11.47-33.31,10.64-48.58,12.42-98.25,4.91-149.06h0Z"
      />

      <path
        id="big_toe_4"
        data-name="big toe 4"
        class="a1403_Großzeh_lat_links_cls-2"
        style={{
          fill: props.colors.a1403_Großzeh_lat_links_cls-2,
          stroke: props.colors.a1403_Großzeh_lat_links_cls-2,
        }}
        d="M110.81,487c8.29-32.81,7.96-57.95-20.75-57.87l-38.22-2.73c-20.81-1.34-26.17,12.77-16.93,41.5l2.18,24.02"
      />

      <path
        id="big_toe_5"
        data-name="big toe 5"
        class="a1403_Großzeh_lat_links_cls-2"
        style={{
          fill: props.colors.a1403_Großzeh_lat_links_cls-2,
          stroke: props.colors.a1403_Großzeh_lat_links_cls-2,
        }}
        d="M86.79,226.57c4.52-6.64,9.98-6.66,16.93,3.82,2.73,8.77,1.44,17.51-3.82,26.21-4.18,5.17-7.85,4.56-10.92-2.73-5.58-9.14-5.58-18.23-2.18-27.3h-.01Z"
      />

      <path
        id="big_toe_6"
        data-name="big toe 6"
        class="a1403_Großzeh_lat_links_cls-2"
        style={{
          fill: props.colors.a1403_Großzeh_lat_links_cls-2,
          stroke: props.colors.a1403_Großzeh_lat_links_cls-2,
        }}
        d="M103.17,256.6c9.29,3.45,15.7.49,18.56-10.37,1.34-9.08-1.77-16.32-10.37-21.29-10.46-2.77-14.03,2.92-15.29,11.47-.25,8.77,1.94,15.64,7.1,20.2h0Z"
      />

      <path
        id="outlines"
        class="a1403_Großzeh_lat_links_cls-1"
        style={{
          fill: props.colors.a1403_Großzeh_lat_links_cls-1,
          stroke: props.colors.a1403_Großzeh_lat_links_cls-1,
        }}
        d="M3.79,206.09c-6.72-39.14-4.74-75.23,13.5-105.65C11.62,62.3,20,31.9,38.5,7.08c12.71-8.93,24.83-9.42,36.24.44,3.2,2.76,5.72,6.25,7.46,10.1,12.91,28.6,18.1,55.68,11.27,80.37,18.99,27.8,18.69,57.84,13.5,88.45"
      />
    </g>

    <g id="overlays">
      <path
        id="big_toe_overlay_1"
        data-name="big toe overlay 1" 
        class="a1403_GroßzehFill"
        style={{
          fill: props.colors.a1403_GroßzehFill,
          stroke: props.colors.a1403_GroßzehFill,
        }}
        d="M72.05,54.04c6.29-13.13-1.65-22.7-12.01-31.67-14.5-4.69-15.64,8.77-9.83,31.67-1.23,16.1-5,28.91-11.47,38.22,1.79,6.49,7.39,6.16,14.74,2.73,6.42-3.5,12.63-3.78,18.56,0,9.89,1.75,15.27-1.44,10.92-15.29l-10.92-25.66h.01Z"
      />

      <path
        id="big_toe_overlay_2"
        data-name="big toe overlay 2"
        class="a1403_GroßzehFill"
        style={{
          fill: props.colors.a1403_GroßzehFill,
          stroke: props.colors.a1403_GroßzehFill,
        }}
        d="M53.48,101.54c-13.7-5.99-19.63-3.01-17.47,9.28,4.94,29.77,5.76,54.36-1.09,69.34-2.78,17.13-3.23,31.3,9.28,28.94,12.41-7.38,25.61-9.6,39.86-4.91,8.72,3.32,14.14,2.05,13.1-8.19,3.35-10.79-7.17-26.01-20.2-42.04-7.23-11.78-6.37-23.8,1.64-36.04,7.31-17.57-4.47-20.4-25.12-16.38h0Z"
      />

      <ellipse
        id="joint_overlay_1"
        data-name="joint overlay 1"
        class="a1403_gelenkeFill"
        style={{
          fill: props.colors.a1403_gelenkeFill,
          stroke: props.colors.a1403_gelenkeFill,
        }}
        cx="59.64"
        cy="94.95"
        rx="30.36"
        ry="15"
      />

      <ellipse
        id="joint_overlay_2"
        data-name="joint overlay 2"
        class="a1403_gelenkeFill"
        style={{
          fill: props.colors.a1403_gelenkeFill,
          stroke: props.colors.a1403_gelenkeFill,
        }}
        cx="65.52"
        cy="210.95"
        rx="43.52"
        ry="18"
      />
    </g>
  </svg>
)
}

export default LinkeGroszeheLatSitzenLinks;
