import style from "./style.scss";

export default function LinkePatellaDeFile30axSitzend2339(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.54 67.84">
      <g id="underlay">
        <ellipse
          id="joint_gap"
          data-name="joint gap"
          class="Femoropatellargelenk2339Fill"
          style={{
            fill: props.colors.Femoropatellargelenk2339Fill,
            stroke: props.colors.Femoropatellargelenk2339Fill,
          }}
          cx="51.71"
          cy="22.91"
          rx="32.92"
          ry="11.07"
          transform="translate(-1.99 5.16) rotate(-5.61)"
        />
      </g>
      <g id="knee">
        <path
          id="patella"
          class="Patella30_left2339_cls3"
          d="M56.28,23.57c-11.81,6.69-16.83.95-18.65-6.01-1.09-2.58-3-4.98-5.73-7.21-7.6-3.39-1.43-9.07,8.52-7.93,13.36-3.3,26.64-2.97,38.53,6.01,4.94,2.48,4.85,9.59-2.98,8.68-6.51.47-13.05,1.87-19.68,6.46h0Z"
        />
        <path
          id="femur"
          class="Patella30_left2339_cls3"
          d="M.5,66.59c1.47-9.77,2.35-10.67,5.26-16.99,7.54-16.32,18.83-29.04,34.2-18.76,9.99,6.27,16.66-.76,23.11-4.11,10.09-5.31,20.12-8.93,23.6,5.39,1.08,6.95,7.34,10.48,10.14,17.3,2.25,2.78,2.79,10.23,3.23,17.92"
        />
      </g>
      <g id="overlay">
        <path
          id="patella_overlay"
          data-name="patella overlay"
          class="Patella2339Fill"
          style={{
            fill: props.colors.Patella2339Fill,
            stroke: props.colors.Patella2339Fill,
          }}
          d="M56.28,23.57c-11.81,6.69-16.83.95-18.65-6.01-1.09-2.58-3-4.98-5.73-7.21-7.6-3.39-1.43-9.07,8.52-7.93,13.36-3.3,26.64-2.97,38.53,6.01,4.94,2.48,4.85,9.59-2.98,8.68-6.51.47-13.05,1.87-19.68,6.46h0Z"
        />
      </g>
    </svg>
  );
}
