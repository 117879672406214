import style from "./style.scss";

function Lendenwirbelkorper1VdLiegend1709(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.3 127.85">

<g id="Backround">
  <path id="backround1" class="Lendenwirbelkorper1_ap1709_cls4" d="M113.16,9.18c8.29,11.4-3.58,25,6.45,39.79,5.94,8.75,12.78,7.95,14.58,15.49,2.63,11.03-10.92,17.21-9.03,27.77,2.31,12.94,24.71,15.29,24.13,22.11-.59,6.99-24.58,10.33-36.54,12-16.34,2.28-20.33,1.25-61.35,1.35-26.19.06-38.3.51-46.25-8.36-1.39-1.55-6.11-6.82-4.99-12.4,2-9.97,20.31-9.4,22.52-18.61,2.24-9.35-15.11-16.29-13.08-26.83,1.48-7.68,11.17-6.61,17.26-16.58,7.74-12.68-2.81-22.8,3.64-33.44C37.58-.18,57.56-.07,74.66.02c18.32.1,31.95.17,38.49,9.17Z"/>
</g>
<g id="Lumbarcorpusvertebravd">
  <g id="Overview">
    <path id="vertebra43" class="Lendenwirbelkorper1_ap1709_cls4" d="M40.7,56.32s-1.5,1.3-4.3,2.4c-3.3,1.4-6.8.8-8.2.6-3.4-.6-4-1.6-6.1-1.2-.3.1-3.5.8-4.3,3.3-.6,2.1.4,4.7,2.1,5.5,1.5.6,8.9.3,8.2.3-2.3.1,3.2-.2,4.3-.6,1.8-.7,2.1-1.4,3.3-1.5,2.3-.2,3.3,1.9,4.6,1.5,2.4-.7,2.7-9.5.4-10.3Z"/>
    <path id="vertebra42" class="Lendenwirbelkorper1_ap1709_cls3" d="M38,9.72c.4,2.3.6,3.8.6,4.9,0,7.7-3.5,11.1-1.8,12.8,1.7,1.8,6.1-1.1,12.8-3,3.1-1,4-.9,24.1-1.6,17.6-.6,20.7-.8,24.3,1.8,4.8,3.5,5.8,8.4,8.2,7.9,2.5-.5,4.5-6.8,3.3-12.1-1-4.6-3.7-5.2-3.9-9.4-.1-1,.1-1.9.3-2.6"/>
    <path id="vertebra41" class="Lendenwirbelkorper1_ap1709_cls3" d="M98.2,24.62c7.5-5.9,5.3-8.7,5.3-8.7"/>
    <path id="vertebra40" class="Lendenwirbelkorper1_ap1709_cls3" d="M38.4,17.92c6.4,1.1,13.5,10.7,14.5,20.5"/>
    <path id="vertebra39" class="Lendenwirbelkorper1_ap1709_cls3" d="M36.8,27.32c0,1.5,23.7,12.5,59.8,5.2"/>
    <path id="vertebra38" class="Lendenwirbelkorper1_ap1709_cls3" d="M106.4,32.52c0-1.5-7.3,1.5-9.8,0"/>
    <path id="vertebra37" class="Lendenwirbelkorper1_ap1709_cls3" d="M98.2,24.62c-2.6,3.7-1.6,17.6-1.6,13.8"/>
    <path id="vertebra36" class="Lendenwirbelkorper1_ap1709_cls1" d="M36.8,39.12c-2.1,3.8,3.7,7.7,4,20,.1,3.3.1,6.6-1.2,10.6-.8,2.3-1.5,3.4-2.4,6.7-1.1,4-1.8,6.4-.6,7.9s3.4,1,8.2,1.2c4.2.2,3.6.5,10.3,1.2,18,1.8,35.9.4,41,0,8.5-.7,13.3-1.5,14.6-4.6.5-1.2.2-2.2-1.3-9.9-.9-4.8-1.4-7.2-1.5-7.6-.7-4.5-1.6-10.2-.5-16.8.8-4.9,2.1-6.5.9-8.2-1.7-2.5-6.4-1.9-10.1-1.5-8,.8-16-.5-24-.1-24.8,1.4-34.8-3.7-37.4,1.1h0Z"/>
    <path id="vertebra35" class="Lendenwirbelkorper1_ap1709_cls3" d="M74.2,27.92c2.4,3,1.1,7.6,3.3,13.4.9,2.4,3.3,2.1,3.9,4.2.8,2.6-.2,6.1-2.7,7.9-1.7,1.2-4.5,2-5.5.9-1.2-1.3,1.4-4.2,0-7.3-.7-1.5-2.4-1.3-2.7-2.7-1-4.3,3.4-16.5,3.7-16.4h0Z"/>
    <path id="vertebra34" class="Lendenwirbelkorper1_ap1709_cls2" d="M18.7,60.02c-1.3,1.6-1.3,4.1-.5,5.6,2.1,3.7,10.1,2.3,10.9,2.1,3.3-.6,3.3-1.4,5.6-1.8,7.8-1.4,12.2,7.5,17.5,5.8,3.9-1.3,6.1-7.6,4.6-9.4-1.1-1.3-3.7.6-5.8-.6-4.6-2.8-.1-17.4-1.8-17.9-1.5-.5-3.1,10.9-11,14.6-7.6,3.5-16.1-2.7-19.5,1.6h0Z"/>
    <path id="vertebra33" class="Lendenwirbelkorper1_ap1709_cls1" d="M108.2,56.52c1.1-.3,1.9,3.2,5.6,4.9,1.5.7,2.1.4,6.2.9,5.7.6,8.5,1,9.1,2.4.7,1.6-1.2,4.3-3.3,5.5-1.5.8-2.3.5-6.4.7-5.9.3-6.8,1.2-8.5.2-1.9-1.2-2.5-3.5-3.1-6.3-.8-3.4-.9-8,.4-8.3Z"/>
    <path id="vertebra32" class="Lendenwirbelkorper1_ap1709_cls2" d="M38.4,85.42c0-1.7,37.7-11.7,72.2-3.1"/>
    <path id="vertebra31" class="Lendenwirbelkorper1_ap1709_cls3" d="M72.9,80.52c-1,1.2.7,2.6.3,5.5-.3,2.5-1.8,2.6-2.7,5.8-.7,2.6-.3,4.8-.2,5.7.3,1.8.9,4.9,2.9,5.5s4.8-1.7,5.9-4.2c1.1-2.4-.1-3.5-.7-9.5-.6-5.7.2-8-1.5-9.1-1.2-.8-3.2-.7-4,.3h0Z"/>
    <path id="vertebra30" class="Lendenwirbelkorper1_ap1709_cls2" d="M96.6,38.42c.9,4.4,5.8,10.4,10.7,9.6"/>
    <path id="vertebra29" class="Lendenwirbelkorper1_ap1709_cls2" d="M57.3,64.02c0,4.2,12.4,7.5,27.7,7.5"/>
    <line id="vertebra28" class="Lendenwirbelkorper1_ap1709_cls2" x1="92.3" y1="64.02" x2="85" y2="71.52"/>
    <path id="vertebra27" class="Lendenwirbelkorper1_ap1709_cls2" d="M54.4,61.92c1.4-6.5,11.4-13.6,19-10.7"/>
    <path id="vertebra26" class="Lendenwirbelkorper1_ap1709_cls2" d="M80,52.22c3.4,2.7,8.2,6.3,12.3,11.8"/>
    <path id="vertebra25" class="Lendenwirbelkorper1_ap1709_cls2" d="M48,61.92c0,5.4,1.9,9.8,4.3,9.8"/>
    <path id="vertebra24" class="Lendenwirbelkorper1_ap1709_cls2" d="M95.1,65.02c1.1-2.5,1.6-4.8,1.8-5.8.4-1.8.6-3.3.6-3.7.3-2.4,0-3,.3-5.5s.4-4,1.2-4.2c.8-.2,2.1.9,2.7,2.1.4.8,1.1,2.5-1.2,9.8-.9,2.7-.7,1.6-2.4,6.6-1.6,4.4-2.1,6.3-3.9,7.3-1,.5-2.3.8-2.7.3-.6-.7,1.7-2.5,3.6-6.9h0Z"/>
    <path id="vertebra23" class="Lendenwirbelkorper1_ap1709_cls2" d="M50.1,72.22c8.8,4,2.5,8.6,5.5,8.6"/>
    <path id="vertebra22" class="Lendenwirbelkorper1_ap1709_cls2" d="M96.6,78.92c0-3.6,5.7-6.5,12.7-6.5"/>
    <path id="vertebra21" class="Lendenwirbelkorper1_ap1709_cls2" d="M52.9,38.42c-1.2,11.7.7,23.5,1.5,23.5"/>
    <path id="vertebra20" class="Lendenwirbelkorper1_ap1709_cls2" d="M49.7,38.42s23.5,8.7,53.8,0"/>
    <path id="vertebra19" class="Lendenwirbelkorper1_ap1709_cls3" d="M111.2,102.92c-1.8.5-2.1,4.8-2.4,9.4-.3,4.3.2,8.7,1.5,9.7,1.5,1.2,2.5-2.2,7.6-3,5.5-.9,10.9-.6,13.1-.4,4.1.3,5.2.9,7.2.1,3.2-1.3,5.7-4.8,4.9-6.7-.6-1.3-2.6-1.2-9.4-1.5-12.8-.6-14.8-1.4-16.4-2.4-3.7-2.4-4.5-5.6-6.1-5.2h0Z"/>
    <path id="vertebra18" class="Lendenwirbelkorper1_ap1709_cls3" d="M9.5,116.62c-3.2-2-4.9-7.3-3-10.3,1.6-2.6,5.3-2.8,7.9-3,4-.3,7,1,7.6,1.2,3.6,1.5,3.3,3.1,5.8,3.7,4.1,1,6.9-2.7,9.1-1.2.7.5,1.1,1.8,1.8,4.6.9,3.4,1.4,5.1.9,5.8-1.4,2-6.7-2-14.9-2.1-8.4-.3-11.5,3.7-15.2,1.3h0Z"/>
    <path id="vertebra17" class="Lendenwirbelkorper1_ap1709_cls3" d="M57.1,110.52c.1-1.6,2.8-1.4,6.1-4.6,3.7-3.5,3.9-7,5.8-7,1.8,0,1.9,3.4,4.2,3.9,3.2.8,5.6-5.4,9.1-4.9,1.5.2,2.7,2.2,5.2,6.1,2.8,4.4,4.2,6.7,3.7,8.5-.9,3-5.2,4.1-8.5,4.9-7.1,1.8-13,0-15.8-.9-1.2-.2-10-3-9.8-6h0Z"/>
    <path id="vertebra16" class="Lendenwirbelkorper1_ap1709_cls3" d="M96.6,124.22h0s-2.2-4.1-2.4-7.9c-.2-2.8.5-7.3,2.7-7.9,1.9-.6,4.4,1.9,5.5,3.9,1.3,2.6.8,5.1.3,7.3-.4,1.7-1,4.7-2.7,5.2-1.3.4-3.4-.6-3.4-.6Z"/>
    <path id="vertebra15" class="Lendenwirbelkorper1_ap1709_cls3" d="M48.9,108.12c-2.1,1.7-2,4.5-1.8,10.3.1,3.6.2,6.8,1.9,7.4,1.1.4,2.3-.5,3.3-1.2.6-.4,3.3-2.4,4.5-6.8.6-2,2.2-7.7-.9-10-1.9-1.4-5.1-1.2-7,.3Z"/>
    <path id="vertebra14" class="Lendenwirbelkorper1_ap1709_cls3" d="M36.5,105.42c-.5,0-.7-3-.9-5.8-.4-5.6.1-6.3.6-6.7.6-.4,1.2-.1,3.7.3,4.1.7,5.6.4,5.8.9.2.9-4.4,1.4-7,5.2-2.1,2.9-1.7,6.1-2.2,6.1h0Z"/>
    <path id="vertebra13" class="Lendenwirbelkorper1_ap1709_cls3" d="M45.9,119.72c-1.3.7-3.7-.9-4.9-2.1-1.1-1.2-1.6-2.5-2.4-6.1-1.2-4.8-1.7-7.2-1.6-8,.6-4.5,5-7.1,8.6-9.3.6-.1,2.5-.3,4,.9,1.9,1.7,1.5,4.9,1.5,5.2-.3,2-1.3,2.5-1,4.2.2,1.4,1.1,1.8.9,2.7-.2,1.3-1.9,1.3-3.1,2.4-3.1,2.6.1,8.8-2,10.1h0Z"/>
    <path id="vertebra12" class="Lendenwirbelkorper1_ap1709_cls3" d="M54.4,80.82c1.7,6.6-2.7,16.9-8.8,13.3"/>
    <line id="vertebra11" class="Lendenwirbelkorper1_ap1709_cls3" x1="51.7" y1="92.72" x2="53.3" y2="106.92"/>
    <line id="vertebra10" class="Lendenwirbelkorper1_ap1709_cls3" x1="79.4" y1="95.62" x2="51.7" y2="92.72"/>
    <line id="vertebra9" class="Lendenwirbelkorper1_ap1709_cls3" x1="79.4" y1="95.62" x2="98.2" y2="96.32"/>
    <path id="vertebra8" class="Lendenwirbelkorper1_ap1709_cls3" d="M96.6,78.92c-1.1,9.8.7,17.4,1.6,17.4"/>
    <line id="vertebra7" class="Lendenwirbelkorper1_ap1709_cls3" x1="98.7" y1="108.22" x2="98.2" y2="96.32"/>
    <path id="vertebra6" class="Lendenwirbelkorper1_ap1709_cls3" d="M101.4,91.32c-.3,11.7-.2,18.9-.5,18.9"/>
    <path id="vertebra5" class="Lendenwirbelkorper1_ap1709_cls3" d="M101.4,91.32c2.9,2.7,8.5,6.3,9.8,11.6"/>
    <path id="vertebra4" class="Lendenwirbelkorper1_ap1709_cls3" d="M100.9,110.32c0,4.2-2.9,7.5-6.5,7.5"/>
    <path id="vertebra3" class="Lendenwirbelkorper1_ap1709_cls3" d="M113.3,98.12c0-1-6.8-1.8-15.1-1.8"/>
    <path id="vertebra2" class="Lendenwirbelkorper1_ap1709_cls3" d="M113.3,98.12c0,2.7-.9,4.9-2.1,4.9"/>
    <path id="vertebra1" class="Lendenwirbelkorper1_ap1709_cls3" d="M50.9,107.12c0,3.3,3.6,6,8,6"/>
  </g>
</g>
<g id="Highlights">
  <path id="adjacentvertebralbodyhighlight2" class="Angrenzende_WK1709Fill" style= {{
            fill: props.colors.Angrenzende_WK1709Fill,
            stroke: props.colors.Angrenzende_WK1709Fill,
          }} d="M43.65,120.69c-1.32-1.1-2.21-2.37-2.66-3.07-.84-1.32-1.26-2.41-1.62-3.59-.14-.44-1.46-4.73-1.77-6.62-.03-.21-.08-.56-.3-.96-.25-.46-.5-.57-.8-1.02-.26-.38-.39-.71-.46-1.1-.6-3.26-.56-6.17-.56-6.17.06-4.12-.09-4.74.51-5.21.49-.38.8-.1,3.59.22,3.67.43,4.62.13,6.13.95.3.16.66.4,1.26.54.22.05,1.51.35,2.83-.25.54-.25,1.14-.53,1.62-1.11.14-.18.32-.44.66-.56.4-.14.79,0,.91.05,1.37.49,2.91.27,4.35.52,2.09.35,3.93.42,11.28,1.15,6.97.7,6.03.72,9,.96,1.41.11,2.98.22,10.21.51,6.03.24,9.04.36,9.4.35,0,0,6.55-.04,14.21.91.4.05,1.43.19,1.87.93.43.72.09,1.63-.48,3.16-.47,1.25-.92,1.55-1.24,1.68-.25.1-.5-.02-.81.15-.34.18-.49.51-.58.7-.87,1.98-1.08,4.2-1.08,4.2-.12,1.52-.21,2.28-.24,3-.18,5.93-.27,8.9,1.13,10.81.2.27.68.87.56,1.55-.17,1.04-1.59,1.58-1.84,1.68-1.64.6-3.26,0-3.52-.08-5.26-1.79-44.81.67-55.82-1.65-2.81-.59-4.58-1.67-5.73-2.63Z"/>
  <path id="adjacentvertebralbodyhighlight1" class="Angrenzende_WK1709Fill" style= {{
            fill: props.colors.Angrenzende_WK1709Fill,
            stroke: props.colors.Angrenzende_WK1709Fill,
          }} d="M105.9,8.42c.15.3-.05.66-.14.87-.61,1.36-.02,3.05.5,4.56.63,1.83,1.26,1.83,2.21,3.7.96,1.89,1.15,3.57,1.29,4.71,0,0,.64,5.42-1.97,9.11-.22.31-.77,1.08-1.58,1.15-.33.03-.55-.07-.79-.15-.98-.32-1.52,0-4.15.35-.91.12-1.37.18-1.87.2-1.44.05-1.66-.18-2.91-.2-.46,0-.7.02-3.76.5-1.33.21-2,.32-2.22.35-10.15,1.7-21.24,1.37-21.24,1.37-3.19-.1-7.68-.37-12.96-1.18-3.76-.57-7.11-1.53-13.83-3.44-.15-.04-2.08-.61-4.28-1.94-.97-.59-1.46-.89-1.69-1.35-.6-1.21.32-2.37,1.18-5.69.16-.61.37-1.42.54-2.51.63-4.04.03-7.37-.14-7.88-.02-.05-.1-.28-.12-.61,0,0-.03-.32.03-.64.21-1.23,63.41-1.64,66.04-1.77.29-.01,1.58-.11,1.86.47Z"/>
  <path id="cvspinousandtransverseprocesshighlight" class="Full_WK1709Fill" style= {{
            fill: props.colors.Full_WK1709Fill,
            stroke: props.colors.Full_WK1709Fill,
          }} d="M36.8,39.12c1.24-2.44,6.22-2.02,12.38-1.69,8.33.45,16.68.38,25.02.59,14.99.39,22.49.59,24,.1.66-.21,4.2-1.41,7.71-.09.83.31,1.77.68,2.39,1.59,1.16,1.71.09,3.65-.77,7.62-1.09,5.05-1.05,9.73-.3,9.91.28.07.4-.53.97-.63,1.16-.19,1.97,2.14,3.78,3.66,1.22,1.02,2.46,1.28,4.96,1.8,3.58.74,5.31.17,9.5,1.23,1.32.33,2.27.67,2.66,1.51.62,1.35-.63,3.12-.94,3.55-.34.48-1.18,1.52-2.87,2.24-1.89.8-2.82.33-5.89.42-4.98.14-6.83,1.5-8.93.08-1.03-.7-1.34-1.54-1.62-1.42-.95.41,4.33,9.48,1.26,13.89-.55.8-1.51,1.62-7.73,2.7-5.73.99-11.52,1.43-22.25,1.52-2.3.02-4.07.03-6.57,0-1.76-.02-8.22-.13-17.19-.81-11.11-.85-8.32-1.23-12.03-1.21-3.64.02-6.47.39-7.73-1.34-.72-.99-.57-2.2-.26-4.62.43-3.34,1.83-6.48,2.33-7.58.33-.75.53-1.3.93-2.39.89-2.46.84-2.84.7-3.1-.52-.98-2.54-.86-3.76-.78-2.47.17-3.47.83-5.87,1.52-2.2.63-5.07,1.45-8.21.71-1.35-.32-3.33-.79-4.27-2.45-1.06-1.88-.59-4.97,1.15-6.45,2.76-2.35,6.54,1.22,15.03.04,1.41-.2,5.88-.86,6.32-2.89.08-.37,0-.62-.21-1.4-.84-3.14.14-.5-1.1-4.9-2.17-7.7-3.36-9.38-2.58-10.9Z"/>
</g>
</svg>
  );
}

export default Lendenwirbelkorper1VdLiegend1709;
