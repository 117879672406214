import style from "./style.scss";

export default function RechterOberschenkelMitHufteVdOblLiegend2352(props) {
  return (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 611.65 353">

    <g id="hip_with_upper_leg" data-name="hip with upper leg">
      <g id="background_layer" data-name="background layer">
        <path id="background_1" data-name="background 1" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls9" d="M.08,265.98c8.34-1.41,20.62-3.19,35.58-4.27,26.51-1.92,39.76-2.88,52.66,1.42,16.68,5.55,14.92,12.03,41.27,24.19,25.58,11.81,33.85,8.75,37,7.12,7.44-3.84,8.2-10.92,12.81-19.92,19.45-37.96,74.71-46.1,81.12-46.96,8.86-1.19,17.3-.79,34.16,0,32.91,1.55,41.01,6.43,44.12,8.54,9.07,6.13,8.67,10.85,17.08,14.23,3.7,1.48,12.37,4.02,31.31-2.85,19.67-7.13,40.72-14.76,51.23-37,12.02-25.44,1.2-50.79-1.42-56.93-1.67-3.9-14.51-32.7-45.54-42.69-18.4-5.92-34.8-2.53-39.85-1.42-4.23.93-13,2.85-22.77,8.54-9.84,5.73-11.02,9.65-21.35,14.23-5.81,2.58-6.08,1.63-17.54,5.74-13.04,4.67-12.5,5.84-18.04,7.07-10.89,2.42-20.47-.22-28.46-2.85-11.72-3.86-15.64-9.02-24.19-8.54-8.97.5-10.96,6.52-21.35,12.81-21.74,13.15-34.75-.06-55.5,12.81-11.52,7.15-10.08,12.82-21.35,17.08-9.39,3.55-14.81,1.27-32.73,0-22.91-1.63-23.82,1.4-45.54,0-9.09-.58-22.33-1.97-38.43-5.69"/>
        <path id="background_2" data-name="background 2" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls9" d="M162.33,265.99c19.19,5.73,31.79,3.36,39.85,0,8.79-3.67,11.11-8.07,21.35-9.96,11.26-2.07,15.44,1.96,25.62-1.42,3.76-1.26,12.69-4.22,15.65-11.39,3.29-8-4.24-13-11.39-34.16-4.07-12.01-7-20.71-7.12-32.73-.1-10.56,2.06-15.68-1.42-19.92-4.65-5.67-15.29-4.76-24.19-2.85"/>
        <path id="background_3" data-name="background 3" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls9" d="M254.83,152.09c75.43-26.99,75.43-5.07,75.43-11.34"/>
        <path id="background_4" data-name="background 4" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls9" d="M276.18,217.61c35.58-15.84,54.08-3.06,54.08-6.83"/>
        <path id="background_5" data-name="background 5" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls9" d="M592.88,291.36c2.78,13.07,8.11,24.03,18.49,30.84-8.89,16.93-29.51,24.02-54.99,27.04h-31.59l-79,3.49v.02c-13.43-21.39-21.69-44.4-26.08-68.62-1.6-9.16.58-16.18,4.51-22.22l-3.21-7.22c-3.86,2.25-7.43,2.43-10.62,0-.55-.28-1.02-.61-1.47-.97-.85-6.95-2.25-13.82-4.32-20.59-4.17-13.62-10.89-26.27-18.51-38.25-17.44-27.44-34.38-56.1-56.82-79.83-.9-.96-2.06-1.14-3.08-.87-2.47-2.56-6.38-4.52-12.18-5.71-7.29-.79-11.09-7.21-13.01-16.64l-3.59-11.69-6.91-13.48-11.04-26.77c-.98-4.79-3.27-8.72-6.55-12L250.46.32l352.87,6.07.91,29.47v13.37l2.58,29.47v49.82l1.06,105.27c-9.51-1.33-15.57.55-16.25,7.44-.48,19.08-.91,38.03,1.25,50.12h0Z"/>
      </g>
      <g id="hip">
        <path id="hip_1" data-name="hip 1" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M445.8,352.72c-12.69-18.59-20.86-40.4-25.52-64.71-1.63-9.24-1.17-18.11,3.95-26.13l-2.43-7.67c-9.26,5.5-14.38,1.5-16.91-8.44,1.67-2.88,1.16-5.72-1.15-8.54-.85-1.03-1.22-2.38-1.02-3.7,2.72-17.7-.07-30.23-6.44-39.4l-13.23-13.98c-1.84-1.94-3.14-4.33-3.85-6.91-4.39-16.1-11.46-27-20.1-34.88-1.6-1.46-3.57-2.47-5.7-2.81-8.89-1.4-16.83-8.11-24.33-17.24-4.23-5.3-9.86-8.28-16.15-10.19-3.56-1.08-6.51-3.59-8.31-6.84-2.12-3.82-3.46-7.84-4.12-12.05-.54-3.44-1.78-6.74-3.45-9.79-7.01-12.81-12.84-26.01-17.59-39.56-1.59-4.55-3.93-8.8-6.98-12.54L250.46.32"/>
        <path id="hip_2" data-name="hip 2" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M433.19,296.06c-2.22-13.42-4.66-26.23-8.96-34.18l-2.43-7.67c-4.18-1.71-5.63-5.92-5.62-11.47-.39-3.14-3.29-7.15-7.29-11.54-3.12-7.34-6.45-14.5-11.51-20.19-1.76-1.98-4.02-3.48-6.57-4.17-3.81-1.03-8.45-13.54-8.5-20.91,7.03-9.61,10-19.89,0-32.35-8.72-12.54-17.44-17.94-26.18-17.2-4.37-15.83-14.84-27.01-30.76-34-.91-.4-1.9-.59-2.89-.59h-1.73c-4.38,0-8.46-2.59-9.98-6.69-.58-1.55-.93-3.32-1.06-5.31-.14-2.12-.96-4.17-2.53-5.61-3.65-3.38-5.29-7.76-5.67-12.77"/>
        <path id="hip_3" data-name="hip 3" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M417.7,126.85c-24.32-24.85-58.94-31.59-93.51-24.85"/>
        <path id="hip_4" data-name="hip 4" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M437.14,248.82c-5.58,3.95-10.97,7.02-15.34,5.4"/>
        <path id="hip_5" data-name="hip 5" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M404.03,103.46c32.82,15.15,51.09,39.94,51.95,76.25-.72,39.99-10.77,56.14-22.78,67.29"/>
        <path id="hip_6" data-name="hip 6" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M508.99,330.09c-2.92-5-7.5-7.4-13.37-7.75,9.59-1.02,18.49-3.34,22.03-15.65,1.42-6.09,1.74-11.29.93-15.55-.37-1.91-1.4-3.64-2.82-4.97-4.61-4.34-8.53-9.74-11.93-15.93-1.58-4.43-4.93-7.89-9.86-10.49-2.56-1.35-4.99-2.94-7.15-4.86-6.98-6.19-14.92-12.12-23.51-17.87-2.02-1.35-4.48-1.92-6.89-1.56-4.64.7-8.39-.41-10.61-4.68"/>
        <path id="hip_7" data-name="hip 7" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M455.52,190.5c3.48-12.31,5.78-25.65,1.36-44.82,12.07,23.9,24.08,46.37,35.7,60.76,14.16,16.84,31.94,27.03,56.2,25.37,16.18-.32,28.83,5.62,33.27,26.13,1.93,7.5,2.68,18.17-2.13,43.9-1.54,4.14-3.5,8.05-6.53,11.39.91,4.2.46,8.37-3.95,12.46-6.2,4.82-10.41,12.91-13.06,23.54"/>
        <path id="hip_8" data-name="hip 8" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M443.98,79.61c3.97,25.92,8.03,50.92,12.91,66.08"/>
        <path id="hip_9" data-name="hip 9" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M504.28,265.38c26.58,19.29,47.39,42.08,65.62,50.58"/>
        <path id="hip_10" data-name="hip 10" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M524.79,349.23c-16.86-22.8-30.89-49.97-35.19-92.14-.39-7.3.92-15.24,3.13-23.53.49-1.83.58-3.74.31-5.62-.64-4.49-.84-8.58-.61-12.28.67-10.75,7.61-20.1,17.57-24.21l8.41-3.47c4.94-2.04,7.63-7.38,6.39-12.57-1.84-7.68-4.87-12.69-8.82-15.65-24.93-20.75-43.16-46.93-54.99-78.32-10.69-27.82-9.65-48.81,2.73-63.19,13.05-11.6,26.56-12.92,40.46-5.53,2.52,1.34,5.5,1.54,8.14.45,4.94-2.05,10.69-2.69,17.28-1.85,1.59.2,2.85-1.31,2.41-2.85l-.85-2.99"/>
        <path id="hip_11" data-name="hip 11" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M452.94,75.2c-2.62-15.89-2-27.14.71-35.39"/>
        <path id="hip_12" data-name="hip 12" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M611.37,322.19c-13.04-8.81-19.25-23.73-19.75-43.75v-38.18c0-1.69.94-3.24,2.44-4.03,4.02-2.11,8.64-2.88,13.82-2.45"/>
        <path id="hip_13" data-name="hip 13" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M538,6.39v3.91c0,1.36.73,2.61,1.9,3.31,2.06,1.24,3.97,3.01,5.54,5.99,1.32,3.42,3.63,6.72,6.61,9.92,2.12,2.28,3.7,5.01,4.76,7.93,1.81,4.99,3.02,10.92,3.13,18.54.08,5.11.29,10.23.89,15.31,1.19,10.07,3.14,19,6.15,26.35,3.62,8.87,10.51,16.03,19.06,20.35l20.78,10.51"/>
        <ellipse id="hip_14" data-name="hip 14" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls2" cx="395.52" cy="83.91" rx="6.15" ry="7.59" transform="translate(185.3 429.29) rotate(-70.65)"/>
        <path id="hip_15" data-name="hip 15" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M606.81,78.7c-5.89-2.99-9.44-2.51-10.63,1.42-.75,2.46-.21,5.12,1.14,7.31,1.83,2.99,4.75,5.3,9.49,6.46"/>
        <path id="hip_16" data-name="hip 16" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls3" d="M604.23,49.23c-4.45-2.11-6.45-5.27-7.3-8.91-.52-2.22.4-4.64,2.45-5.64,1.61-.79,3.23-.4,4.84,1.19"/>
        <path id="hip_17" data-name="hip 17" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls1" d="M579.6,236.63v-.02c-.34,8.55-4.4,13.19-12.68,13.42-.24,0-.48.02-.71.05-10.34,1.09-18.22-5.41-24.82-15.87-1-1.59-1.9-3.26-2.58-5.01-3.87-9.89-2.99-20.09,1.17-30.5,1.79-3.99,1.54-7.23-1.05-9.61-.88-.8-1.95-1.36-3.07-1.75l-34.76-12.1c-16.59-7.44-21.68-15.36-22.11-23.47-.1-1.99.14-3.99.6-5.94,3.07-13.13,10.07-21.41,20.1-25.96.93-.42,1.9-.73,2.88-1.02,12.52-3.67,17.81-9.91,16.8-18.39-.14-1.16-.47-2.28-.84-3.39-2.13-6.41-2.03-12.3.31-17.67,1.23-2.83,3.12-5.32,5.35-7.46l15.63-15.01c1.49-1.43,2.76-3.1,3.62-4.98,1.19-2.62,1.23-4.75.33-6.49-.84-1.62-2.71-2.4-4.49-2.01-2.86.63-5.28.29-7.1-1.41-1.4-1.32-2.06-3.25-1.96-5.18.22-4.29,1.93-9.83,4.73-16.28,4.26-9.84,15.42-14.87,25.56-11.39.1.03.19.07.29.1,5.08.31,8.3,2.84,9.83,7.41.53,1.6,1.52,3.04,2.93,3.96,5.7,3.72,8.41,8.65,7.82,14.9-.18,1.91-.09,3.84.18,5.74,1.49,10.23,0,17.72-4.05,22.82-1.45,1.82-2.71,3.77-3.66,5.9-1.9,4.28-5.02,11.31-7.21,16.24-1.76,3.97-4.44,7.46-7.81,10.21-8.28,6.76-17.35,12.58-26.91,17.81-1.61.88-3.13,1.93-4.45,3.21-2.35,2.29-3.25,4.4-.81,6.06.85.58,1.67,1.22,2.41,1.92,6.1,5.78,9.59,13.87,10.09,24.62.07,1.42.47,2.83,1.35,3.95,1.53,1.95,3.5,2.23,5.93.75,2.58-1.57,4.28-4.26,4.91-7.22,1.62-7.62,4.49-11.88,8.58-12.65.72-.14,1.46-.12,2.19-.02,8.91,1.23,15.18,5.76,18.61,13.84.63,1.49,1.28,2.97,1.93,4.46,2.5,5.75,2.21,10.76-1.07,14.98-6.02,9.8-13.68,13.95-21.63,17.08-.8.32-1.59.68-2.29,1.18-2.65,1.86-2.97,3.98-.71,6.38,1.19,1.27,2.71,2.19,4.35,2.77,7.67,2.72,13.51,8.08,16.18,18.01,2.06,7.32,2.76,14.22,2.23,20.73-.06.77-.1,1.53-.13,2.3h.01Z"/>
        <path id="hip_18" data-name="hip 18" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls1" d="M479.32,69.76h0c-1.03-2.65-1.48-5.42-1.41-8.31.07-2.61-.65-5.19-1.82-7.53-2.68-5.37-2.36-10.55-.2-15.63,2.21-6.4,4.57-12.03,7.29-15.68s8.03-4.6,11.89-1.99l5.83,3.94c.9.61,1.93,1,3.01,1.17,3.54.56,6.41,1.87,7.38,5.26,1.21,3.96-4.56,8.81-5.16,15.95-1.02,10.38-5.16,19.37-15.65,25.52-1.97.79-3.8,1.22-5.49,1.22-2.5,0-4.76-1.59-5.66-3.93h-.01Z"/>
      </g>
      <g id="upper_leg" data-name="upper leg">
        <path id="upper_leg_1" data-name="upper leg 1" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls4" d="M.08,265.98c8.34-1.41,20.62-3.19,35.58-4.27,26.51-1.92,39.76-2.88,52.66,1.42,16.68,5.55,14.92,12.03,41.27,24.19,25.58,11.81,33.85,8.75,37,7.12,7.44-3.84,8.2-10.92,12.81-19.92,19.45-37.96,74.71-46.1,81.12-46.96,8.86-1.19,17.3-.79,34.16,0,32.91,1.55,41.01,6.43,44.12,8.54,9.07,6.13,8.67,10.85,17.08,14.23,3.7,1.48,12.37,4.02,31.31-2.85,19.67-7.13,40.72-14.76,51.23-37,12.02-25.44,1.2-50.79-1.42-56.93-1.67-3.9-14.51-32.7-45.54-42.69-18.4-5.92-34.8-2.53-39.85-1.42-4.23.93-13,2.85-22.77,8.54-9.84,5.73-11.02,9.65-21.35,14.23-5.81,2.58-6.08,1.63-17.54,5.74-13.04,4.67-12.5,5.84-18.04,7.07-10.89,2.42-20.47-.22-28.46-2.85-11.72-3.86-15.64-9.02-24.19-8.54-8.97.5-10.96,6.52-21.35,12.81-21.74,13.15-34.75-.06-55.5,12.81-11.52,7.15-10.08,12.82-21.35,17.08-9.39,3.55-14.81,1.27-32.73,0-22.91-1.63-23.82,1.4-45.54,0-9.09-.58-22.33-1.97-38.43-5.69"/>
        <path id="upper_leg_2" data-name="upper leg 2" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls4" d="M162.33,265.99c19.19,5.73,31.79,3.36,39.85,0,8.79-3.67,11.11-8.07,21.35-9.96,11.26-2.07,15.44,1.96,25.62-1.42,3.76-1.26,12.69-4.22,15.65-11.39,3.29-8-4.24-13-11.39-34.16-4.07-12.01-7-20.71-7.12-32.73-.1-10.56,2.06-15.68-1.42-19.92-4.65-5.67-15.29-4.76-24.19-2.85"/>
        <path id="upper_leg_3" data-name="upper leg 3" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls4" d="M254.83,152.09c75.43-26.99,75.43-5.07,75.43-11.34"/>
        <path id="upper_leg_4" data-name="upper leg 4" class="oberschenkel_mit_hüfte_obl_Lauenstein_rechts2352_cls4" d="M276.18,217.61c35.58-15.84,54.08-3.06,54.08-6.83"/>
      </g>
    </g>
    <g id="overlays">
      <path id="hip_pan_overlay" data-name="hip pan overlay" class="Pfanne2352Fill" style={{
            fill: props.colors.Pfanne2352Fill,
            stroke: props.colors.Pfanne2352Fill,
          }} d="M449.57,147.43c21.43,47.46,1.73,95.28-24.73,107.22-26.45,11.95,18.36-41.85,2.43-91.43-21.57-67.14-144.3-41.62-95.7-64.41,26.28-12.32,96.56,1.16,117.99,48.62h.01Z"/>
      <path id="hip_head_overlay" data-name="hip head overlay" class="Femoralhead2352Fill" style={{
            fill: props.colors.Femoralhead2352Fill,
            stroke: props.colors.Femoralhead2352Fill,
          }} d="M348.13,244.85l-27.44-122.07c4.18-2.93,10.59-6.86,19.02-9.98,7.34-2.72,28.39-10.17,52.35-1.94,17.54,6.02,27.94,17.23,31.96,22.17,19.81,24.36,27.09,51.66,10.36,84.58-3.09,3.84-7.77,9.02-14.32,14.13-13.67,10.69-26.74,14.32-34.49,16.39-12.64,3.37-26.79,7.15-34.17-.14-1.78-1.75-2.73-3.72-3.28-5.18v2.04Z"/>
      <path id="neck_of_the_femur_overlay" data-name="neck of the femur overlay" class="Schenkelhals2352Fill" style={{
            fill: props.colors.Schenkelhals2352Fill,
            stroke: props.colors.Schenkelhals2352Fill,
          }} d="M346.83,237.13l-26.14-114.36c1.48,3.78-16.13,10.96-40.65,18.29-.02,0-.04.01-.06.02-16.07,7.36-22.27,6.32-30.59,2.95,0,0-.37.17-.72.41-3.9,2.7-2.51,13.51-2.39,14.46,1.69,13.16-1.7.55,1.61,27.51,3.45,28.05,9.61,32.71,12.76,39.63.09.19.29,1.27.5,1.27,42.85.15,74.45.45,86.97,17.52l-1.3-7.71Z"/>
      <path id="trochanter_major_overlay" data-name="trochanter major overlay" class="TrochanterMajor2352Fill" style={{
            fill: props.colors.TrochanterMajor2352Fill,
            stroke: props.colors.TrochanterMajor2352Fill,
          }} d="M186.29,152.09c-2.43,17.02-5.49,34.91-9.33,53.56-4.41,21.44-9.38,41.57-14.63,60.33,19.74,6.17,30.53,4.25,36.79,1.16.88-.44,5.15-1.69,10.49-5.14,8.26-5.33,17.45-8.22,28.7-5.69,23.65-3.42,31.61-12.01,24.57-25.57-15.5-29.85-16.27-53.95-16.25-63.03,0-4.61-.14-6.76-.25-7.93-.01-.12-.06-.71-.13-1.53-.09-1.05-.22-2.64-.26-3.24-.03-.46-.19-2.88.15-4.86.69-4.04,3.25-5.03,2.63-6.37-.63-1.37-3.25-.51-8.7-2.16-5.92-1.8-5.17-4.29-10.94-5.84-2.54-.68-9.74-2.1-9.81-2.13,0,0-.04-.02-.08-.02,0,0-.02,0-.03,0-3.92.67-5.83,1.43-5.83,1.43-3.08,1.23-5.5,3.41-10.13,7.57-.87.78-1.57,1.43-2,1.85l-14.96,7.61Z"/>
      <path id="trochanter_minor_overlay" data-name="trochanter minor overlay" class="TrochanterMinor2352Fill" style={{
            fill: props.colors.TrochanterMinor2352Fill,
            stroke: props.colors.TrochanterMinor2352Fill,
          }} d="M95.83,265.38h68.05c4.33,3.1,11.13,3.83,18.44,4.07-7.5,11.19-9.63,26.61-18.44,26.03-16.18,2.89-39.93-9.25-68.05-30.11h0Z"/>
    </g>
  </svg>
  );
}
