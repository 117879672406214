import style from "./style.scss";

function Linke5ZeheDplOblSitzend1423(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.66 309.82">
      <g id="fifth_toe" data-name="fifth toe">
        <path
          id="fifth_toe_1"
          data-name="fifth toe 1"
          class="Linke_5Zehe_dpl_obl_sitzend1423_cls_3"
          d="M72.34,305.14c-.59-2.63-1.18-20.19-1.82-22.82-11.84-8.77-11.18-19.29-4.82-30.47-7.89-16-16.66-19.73-26.3-15.56-22.14-5.7-32.66,12.27-37.7,41.21-2.92,12.12-.46,22.8,7.19,32"
        />
        <path
          id="fifth_toe_2"
          data-name="fifth toe 2"
          class="Linke_5Zehe_dpl_obl_sitzend1423_cls_3"
          d="M19.88,231.47c-18.63-10.96-15.78-27.4,4.82-48.44,12.71-20.38,17.54-44.28,14.91-71.46-9.21-19.73.22-25.65,20.6-23.02,17.32-7.23,34.63-14.91,26.3,8.33-12.71,30.25-21.7,62.91-11.4,106.97,1.97,38.14-7.01,40.55-21.48,26.3-12.06-4.82-23.45-5.26-33.76,1.32h0Z"
        />
        <path
          id="fifth_toe_3"
          data-name="fifth toe 3"
          class="Linke_5Zehe_dpl_obl_sitzend1423_cls_3"
          d="M44.65,81.1c-13.15-.44-15.34-5.7-7.45-15.56-.66-11.62,4.82-17.75,15.56-18.85l22.14,6.58c11.4,2.19,11.18,7.01,5.7,13.15,2.85,9.43-1.75,13.81-13.15,14.03l-22.8.66h0Z"
        />
        <path
          id="fifth_toe_4"
          data-name="fifth toe 4"
          class="Linke_5Zehe_dpl_obl_sitzend1423_cls_3"
          d="M42.24,14.47C47.5.88,53.64-3.73,61.31,4.82c2.63,3.07,3.73,7.23,3.73,11.4s3.07,7.23,8.77,10.08,8.33,9.64,5.04,15.12-9.21,5.92-16.88,4.16c-27.4,2.41-39.45-2.85-28.06-19.73,3.51-1.75,7.23-3.29,8.33-11.4h0Z"
        />
      </g>
      <g id="overlays">
        <g id="Phalangen_overlay" data-name="Phalangen overlay">
          <path
            id="Phalangen_overlay_1"
            data-name="Phalangen overlay 1"
            class="phalangenFill1423"
            style= {{
                fill: props.colors.phalangenFill1423,
                stroke: props.colors.phalangenFill1423,
                opacity:props.colors.phalangenFill1423,
              }}
            d="M19.88,231.47c-18.63-10.96-15.78-27.4,4.82-48.44,12.71-20.38,17.54-44.28,14.91-71.46-9.21-19.73.22-25.65,20.6-23.02,17.32-7.23,34.63-14.91,26.3,8.33-12.71,30.25-21.7,62.91-11.4,106.97,1.97,38.14-7.01,40.55-21.48,26.3-12.06-4.82-23.45-5.26-33.76,1.32h0Z"
          />
          <path
            id="Phalangen_overlay_2"
            data-name="Phalangen overlay 2"
            class="phalangenFill1423"
            style= {{
                fill: props.colors.phalangenFill1423,
                stroke: props.colors.phalangenFill1423,
                opacity:props.colors.phalangenFill1423,
              }}
            d="M44.65,81.1c-13.15-.44-15.34-5.7-7.45-15.56-.66-11.62,4.82-17.75,15.56-18.85l22.14,6.58c11.4,2.19,11.18,7.01,5.7,13.15,2.85,9.43-1.75,13.81-13.15,14.03l-22.8.66h0Z"
          />
          <path
            id="Phalangen_overlay_3"
            data-name="Phalangen overlay 3"
            class="phalangenFill1423"
            style= {{
                fill: props.colors.phalangenFill1423,
                stroke: props.colors.phalangenFill1423,
                opacity:props.colors.phalangenFill1423,
              }}
            d="M42.24,14.47C47.5.88,53.64-3.73,61.31,4.82c2.63,3.07,3.73,7.23,3.73,11.4s3.07,7.23,8.77,10.08,8.33,9.64,5.04,15.12c-3.29,5.48-9.21,5.92-16.88,4.16-27.4,2.41-39.45-2.85-28.06-19.73,3.51-1.75,7.23-3.29,8.33-11.4h0Z"
          />
        </g>
        <g id="joint_overlay" data-name="joint overlay">
          <ellipse
            id="joint_overlay_1"
            data-name="joint overlay 1"
            class="gelenkeFill1423"
            style= {{
                fill: props.colors.gelenkeFill1423,
                stroke: props.colors.gelenkeFill1423,
                opacity:props.colors.gelenkeFill1423,
              }}
            cx="56.74"
            cy="46.26"
            rx="29.62"
            ry="12.3"
          />
          <ellipse
            id="joint_overlay_2"
            data-name="joint overlay 2"
            class="gelenkeFill1423"
            style= {{
                fill: props.colors.gelenkeFill1423,
                stroke: props.colors.gelenkeFill1423,
                opacity:props.colors.gelenkeFill1423,
              }}
            cx="62.14"
            cy="83.17"
            rx="29.52"
            ry="11.85"
          />
          <ellipse
            id="joint_overlay_3"
            data-name="joint overlay 3"
            class="gelenkeFill1423"
            style= {{
                fill: props.colors.gelenkeFill1423,
                stroke: props.colors.gelenkeFill1423,
                opacity:props.colors.gelenkeFill1423,
              }}
            cx="42.8"
            cy="232.64"
            rx="14.58"
            ry="35.36"
            transform="translate(-192.38 257.98) rotate(-85.72)"
          />
        </g>
      </g>
    </svg>
  );
}

export default Linke5ZeheDplOblSitzend1423;
