import style from "./style.scss";

function FusswurzelDplOblRechts1454(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 279.57 359.21">
 
 <g id="foot">
   <path id="background" class="fusswurzel1454_dpl_obl_rechts_cls2" d="M3.5,231.73l63.79-27.68c4.15-1.8,7.17-5.51,8.1-9.94,6.72-32.15,11.51-62.12,10.95-86.09,0-.42,0-.83.03-1.25l4.59-63.87c.06-.86.04-1.72-.05-2.57l-3.63-31.93C86.78,3.93,90.27.02,94.76.02h160.67c6.01,0,11.37,3.84,13.23,9.56,9.44,28.95,13.08,59.64,8.27,92.9-.1.72-.26,1.44-.47,2.14-3.88,12.66-11.65,25.34-23.44,38.04-2.25,2.42-3.52,5.6-3.64,8.9-1.05,28.39,1.01,55.04,10.75,77.38.2.46.43.9.67,1.33,20.7,36.54,20.45,60.78,17.03,83.17-3.87,27.92-21.43,42.51-52.1,44.33-.26.02-.53.04-.79.07-26.91,3.53-60.71-.23-97.21-6.86-3.6-.65-6.81-2.69-8.92-5.68l-12.23-17.33c-3.8-5.38-10.84-7.37-16.89-4.76l-65.63,28.23c-1.75.75-3.73-.33-4.06-2.2L.65,237.01c-.38-2.21.8-4.39,2.85-5.28Z"/>
   <path id="radius_1" data-name="radius 1" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M18.02,345.34c34.13-18.26,70.57-33.99,106.44-47.01"/>
   <path id="radius_2" data-name="radius 2" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M79.76,210.66c-28,13.97-46.85,25.06-79.62,34.8"/>
   <g id="forefoot">
     <path id="forefoot1" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M176.08,148.46h0l10.7,5.6c6.69,5.52,15.13,5.44,24.91.92,15.38.42,21.9-5.51,22.57-15.54-.59-11.37,2.01-18.31,7.53-21.15,6.77-2.34,3.51-6.02-1.83-9.86-6.1-10.61-17.64-19.14-33.86-25.83-7.44-3.35-11.7-2.93-9.36,5.18.08,13.63-6.94,25.83-22.4,36.36-2.51,1.67-4.59,4.01-6.02,6.69-7.44,13.88-7.19,21.57,7.78,17.64h-.02Z"/>
     <path id="forefoot2" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M146.42,97.54h0c2.42.42,4.76-1.01,5.43-3.34l3.34-11.95c4.6-2.76,5.1-6.69,3.43-11.29,5.19-6.44,2.93-11.78-8.94-15.71-8.77-4.52-16.05-5.52-19.73,1.75-2.17,8.11-5.09,15.55-8.94,22.07-3.85,7.44-2.09,12.63,6.77,15.05l18.65,3.43h0Z"/>
     <path id="forefoot3" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M172.34,119.38h0c9.53-3.6,12.54-9.61,9.44-17.89l11.29-23.49c5.93-9.45,3.18-16.9-8.45-22.07-13.71-3.18-19.07,3.51-18.31,17.39-6.69,8.1-12.03,16.47-9.86,25.83-2.09.08-4.42.84-5.35,4.1-.34,1.17,0,2.42.75,3.34,7.61,8.86,14.55,14.3,20.48,12.79h0Z"/>
     <path id="forefoot4" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M253.88,13.46c-2.83,27.7-.6,53.79,10.83,76.9,2.09,4.27,2.25,9.28.25,13.63-5.93,12.79-12.28,12.87-18.97,1.83h0c-6.69-8.94-14.72-15.63-24.66-19.22-1.84-.59-3.34-1.93-4.01-3.76-3.1-8.45-1.09-15.22,5.35-20.56,10.2-9.7,12.8-23.15,11.88-38.29-.14-4.27-.18-8.51-.09-12.76"/>
     <path id="forefoot5" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M221.55,11.19c.33,9.58,1,20.19,2.02,31.9.11,1.2-.12,2.42-.65,3.5-2.14,4.34-2.5,9.05.49,14.44,3.93,14.55,2.34,22.66-9.7,18.48h0l-11.37-4.49c-2.23-.88-4.61-1.15-7-1.09-6.45.17-5.24-8.65.07-22.33.44-1.12.61-2.3.58-3.49-.26-8.47,1.83-16.15,6.15-22.98.51-.81.86-1.7.98-2.65l1.7-13.51"/>
     <path id="forefoot6" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M193.5,8.49c.94,10.38,2.62,20.59,5.09,30.62,4.94,17.46,4.1,28.75-9.36,25.99h0c-15.38-1.58-21.06-8.2-22.73-16.89-1.14-7.19.95-13.43,5.21-19.01.93-1.22,1.59-2.63,1.9-4.14,1.04-5.13,2.01-10.52,2.91-16.13"/>
     <path id="forefoot7" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M163.53,7.41c.77,13.7,2.27,27.72,4.38,42-.08,9.86-4.94,10.28-13.79,2.51h0c-8.02-2.59-15.46-3.68-22.32-3.09-6.03-7.78-2.26-17.47,5.35-27.92,1.86-5.58,3.55-11.2,5.06-16.87"/>
     <path id="forefoot8" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M154.43,141.95c-23.24-10.7-38.53-6.44-43.22,17.81-4.76,7.43-9.78,9.61-15.54-1.43-5.68-10.45-4.76-25.67,1.43-44.64,3.18-14.2,14.38-15.54,28.68-12.2,20.07.83,29.52,8.77,35.78,18.8,8.11,4.68,12.38,9.95,10.79,15.96-5.19,11.2-11.2,12.37-17.89,5.68h-.02Z"/>
     <path id="forefoot9" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M111.65,111.85c-14.48.65-18.4-12.51-15.68-34.35.62-5.05.74-10.16-.07-15.18-.69-4.31-.04-9.1,2.12-14.42,5.78-16,15.32-18.12,27.19-11.89,1.2.62,2.01,1.81,2.18,3.15.85,6.86,3.76,11.76,9.17,14.43,7.61,4.6,7.94,17.64,2.84,37.12-2.01,15.05-9.11,24.41-27.76,21.15h0Z"/>
     <path id="forefoot10" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M138.15.24c6.69,12.29,10.03,25.08,4.68,38.88.91,7.61-5.86,10.53-18.48,10.03h0l-15.38-1.43c-2.35-.22-4.56-1.23-6.29-2.85-8.28-7.77-11.39-15.16-6.26-21.88,1.98-7.86,3.47-15.47,4.46-22.84"/>
   </g>
   <path id="ulnar" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M7.76,280.97c29.27-13.02,59.06-30.7,82.37-54.3,11.55-11.68,25.38-18.37,45.25-11.92,9.85-1.17,17.18,4.04,21.97,15.8,2.07,11.94-1.76,23.41-15.94,34.01-8.34,9-23.48,12.37-44.12,11.2-25.83,6.66-50.32,21.08-82.74,37.69"/>
   <g id="heel">
     <path id="heel1" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M130.3,263.01c-.85,2.35-1.59,4.73-2.24,7.14l-5.2,19.18c-.84,3.09-.24,6.41,1.62,9.02,11.43,16,22.72,28.23,33.86,36.41,4.28,3.14,9.18,5.34,14.42,6.18,16.41,2.66,34.83-4.58,54.37-17.28,1.69-1.1,3.15-2.51,4.33-4.14l20.37-27.92c1.17-1.62,1.96-3.51,2.19-5.5.92-7.69-.21-15.48-3.86-23.36-.81-1.77-1-3.74-.58-5.62,1.79-7.88-3.33-18.64-10.56-30.28-.91-1.46-1.36-3.16-1.36-4.87-.05-16.85-4.52-32.37-12.59-46.78-.63-1.14-.92-2.44-.8-3.72.68-7.14-1.06-14.75-5.94-15.68-4.83.19-10.98,4.12-18.26,5.25-3.05.47-9.44.29-10.61-2.77-1.71-4.52-7.12-7.35-11.33-6.92-5.15.55-11.26,3.71-18.07,8.5-4.07,2.48-8.88,4.72-12.04,6.09-2.44,1.05-2.36,5.99-1.06,8.48.32.61.47,1.24.44,1.92-.05,1.19-.68,2.24-1.56,2.96-3.04,2.49-12.81,10.46-16.59,13.55-1.15.93-2.22,1.97-3.12,3.17-2.95,3.91-4.42,8.16-3.96,12.84.35,3.6,1.99,6.95,4.27,9.77,11.07,13.77,9.43,28.85,3.87,44.35h0Z"/>
     <path id="heel2" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M211.62,233.29c13.95-.86,30.33,13.62,36.04,32,3.97,10.63,4.87,20.06,1.33,27.76"/>
     <path id="heel3" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M146.95,174.44c1.13.46,2.31.93,3.57,1.43,3.39,1.33,6.62,3.15,9.56,5.4l3.63,2.79c1.67,1.28,3.53,2.23,5.5,2.81,6.14,1.84,11.5,4.68,16.04,8.57,6.19,5.4,10.38,12.07,11.77,20.48"/>
     <path id="heel4" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M109.3,260.04c2.63,1.52,5.22,3.11,7.71,4.83,21.03,14.57,43.38,19.37,67.1,14.1,17.85-4.89,25.59-11.25,24.83-18.87.56-7.77-2.36-14.55-8.46-20.42-5.97-4.83-7.33-12.83-9.84-20.56-5.8-8.61-20.39-16.99-42.32-25.18-8.16-2.86-13.42-8.78-11.85-21.97-.68-8.48,13.96-13.2,22.21-14.49,3.58-1.23,3.51-4.02-2.19-10.79-7.35-7.98-30.06-13.59-41.72-13.41-6.06-.97-12.11.26-18.12,4.57-.21,4.98-5.9,9.78-4.37,14.3,1.8,6.39-5.34,12.01-2.92,18.81-5.46,6.93.54,18.92-9.23,30.33-2.52,28.06,8.47,46.76,29.17,58.75h-.02Z"/>
     <path id="heel5" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M148.52,160.27c-4.36,4.12-4.14,11.35-5.93,14.63-.49.89-.65,1.92-.74,2.94-.3,3.45-5.7,6.31-5.9,9.54-.29,3.43-1.78,6.43-6.68,8.19l-5.72,18.47c-1.56,4.8.39,7.59,6.25,8.11,4.43.12,7.6,2.53,8.45,9.13,4.79,2.94,2.52,10.98,5.83,14.84l12.25,25.02"/>
     <path id="heel6" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M143.03,162.7c-.89-7.48-3.35-14.43-7.15-20.91-1.55-2.63-4.38-4.17-7.27-3.99l-18.35,1.16c-4.75.1-8.8-.4-10.2-3.22"/>
     <path id="heel7" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M86.33,218.91c9.74,1.76,18.76,4.22,26.34,8.13,5.92,3.05,10.58,8.45,12.51,15,2.34,7.95,1.41,16.95-2.66,26.96"/>
     <path id="heel8" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M192.29,203.53c-2.2,11.34-11.43,15.2-27.98,11.26-4.22-1.01-8.65-.66-12.47,1.25-3.28,1.64-5.77,4.14-7.52,7.5-.62,1.2-.67,2.67-.19,3.99l17.56,47.68"/>
     <path id="heel9" class="fusswurzel1454_dpl_obl_rechts_cls1" d="M175.36,157.47c8.76,10.7,14.96,12.62,20.7,8.29"/>
   </g>
 </g>
 <g id="overlays">
   <path id="tarsal_bone_overlay" data-name="tarsal bone overlay" class="fwk1454fill" style= {{
             fill: props.colors.fwk1454fill,
             stroke: props.colors.fwk1454fill,
             opacity:props.colors.fwk1454fill,
           }} d="M124.46,298.34c22.8,31.98,43.56,48.75,61.25,42.78,16.94-2.91,31.6-10.12,44.71-20.29,15.26-19.57,24.62-34.04,23.91-39.77-.16-7.36-1.79-13.72-4.54-19.3l.16-8.07c-.17-8.67-3.75-16.85-9.62-24.72-2.2-3.34-2.91-8.81-3.03-15.14-1.09-13.26-5.26-25.47-11.34-37.02-1.34-1.77-1.79-3.55-1.68-5.32,1.11-5.78-.48-11.41-5.26-16.87,10.88-3.26,15.52-7.44,14.88-12.41.07-13.37,2.58-21.58,7.88-23.92,6.29-3.95,3.49-7.03-1.83-9.86-5.52-10.3-16.88-18.03-30.95-24.59l-9.04-3.23-4.41-7.98c1.37-7.85-2.25-13.43-10.94-16.7-4.58-1.52-9.17-1.19-13.79,1.59l-9.59,4.08c-3.52-3.1-8.03-5.22-12.88-7-5.86-2.57-10.42-3.69-12.94-2.52-4.69-3.4-6.86-7.94-8.04-12.92-2.29-4.19-7-5.88-12.86-6.37-8.37.33-13.89,6.09-17,16.42-2.59,6.24-2.41,12.74,0,19.46-3.87,19.51-3.42,32.69,2.54,37.8-6.72,14.7-10.18,29.36-7.26,43.96-.72,7.05-3.9,12.8-3.42,20.52-4.7,6.85-1.06,22.8-9.23,30.33-4.71,34.38,16.06,55.25,48.67,69.85-5.78,12.92-7.8,22.5-4.35,27.21Z"/>
 </g>
</svg>
  );
}

export default FusswurzelDplOblRechts1454;
