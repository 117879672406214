import style from "./style.scss";

export default function RechterUnterschenkelMitOberemSprunggelenkVdStehend2304(
  props
) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 173.11 680.24">
      <g id="background">
        <path
          id="tibia_background"
          data-name="tibia background"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls4"
          d="M71,.74c.1,7.1.3,17.8.6,30.9,1.6,61.4,4.3,81.4,7.3,141.1,3.3,66.4,3.5,113,3.5,128.1,0,30,0,45-.7,58-4.8,86.3-7.2,129.5-12.8,149.9-1.7,6-6.8,22.8,1.6,32,5.1,5.7,8.7,4.5,10.4,2.4,1.5-1.9,1.2-2.4,2.6-6.2,1.4-3.8,8.3-3.4,36.1-3.6,12.9-.1,17.2-.2,21,3.2,3.4,3.2,5.8,5.9,10.8,15.1,4.3,7.9,4.1,12.9,7.8,13.1,5.1.3,10.5-8.8,12.2-16.8,2.7-12.2-2.8-22.9-6.6-30.1-18.2-34.4-22.3-40.2-27.4-51.3-4.9-10.7-7.7-27.2-13.1-59.8-1.6-9.7-6-36.3-8.4-60.4-6.2-61.9-.1-122.7.6-137,2.4-49.3,3.6-119.9-2.5-207.9"
        />
        <path
          id="fibula_background"
          data-name="fibula background"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls4"
          d="M24.1.6c.8,10.6,2,26.6,3.4,45.9,4.4,61.7,4.5,77.1,6.7,107.1,5.5,74.6,11.1,64.6,15.4,132.2,4.9,77.3,4.7,105.1,4.7,105.1,0,14.5-.2,30.6,3.6,52.6,2.9,17.2,5.2,19.7,8.2,40.5,2.9,19.8,1.5,23.7,5.6,36.1,1.8,5.5,3.8,9.9,3.8,17,0,5.5-1.2,7.1-5.3,21.9-4.7,16.8-4.2,11.4-7.3,14.9-4.1,4.7-6.4,4.5-14.1,11.8-5.5,5.3-6.7,7.2-9.1,6.9-4.9-.7-6.6-7.6-6.8-9.1-1.3-11.7,1.7-21,2.3-23,4.8-15.1,4.6-31.4,7.6-46.9,5.4-28,2.1-56.7-1-86-5.2-48.7-9-97.5-14.2-146.2-5.4-50.7-3.4-39.4-15.9-171.3C7.2,62.6,3.4,24.1,1,.1"
        />
        <path
          id="talus_background"
          data-name="talus background"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls4"
          d="M67.95,624.91c1-.59,2.32-.14,2.71.96,2.96,8.45,11.82,7.63,18.75,2.01,17.74-9.91,34.07-12.23,48.19-2.59,5.19,7.69,11.77,12.64,19.61,15.05,2.97-2.93,5.05-5.99,6.29-9.15,1.4-2.6,3.3-6.7,4.1-12,1-6,.1-10.9-.8-15-3.3-14.4-7.5-17.4-12.6-32.2-4.5-13.1-3.7-13.8-9.7-21.1-5.6-6.8-10.6-11.1-11.8-11.3-3.6-.8-5.7-.9-12.9.2-7.7,1.2-7.8.6-15.3,1.6-13.9,1.9-20.8,2.8-23.6,6-6,6.8,1.2,15.4-5.6,24.5-1,1.4-4.8,3.9-12.3,9-10.1,6.8-12.3,7.4-14.1,10.8-2.2,4.2-1.4,7.9-.2,16,.9,6.3,2,15.5,2.7,27l16.55-9.79Z"
        />
        <path
          id="foot_background"
          data-name="foot background"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls4"
          d="M167.49,678.77l-1.7-6.67c3.77-4.11,6.87-8.79,6.31-16.56-.37-5-3.46-9.4-7.71-13.54-9.53-1.14-17.71-3.71-23.7-8.61-4.53-8.22-11.68-12.14-20.28-13.7-12.82-.38-24.78,4.3-36.08,12.91-8.18,1.72-12.91-.88-14.34-7.61-8.89,3.95-18.9,10.81-29.2,18.4-9.19,12.62-18.75,24.57-28.85,35.6l155.54-.23Z"
        />
      </g>
      <g
        id="upper_ankle_joint_with_lower_leg"
        data-name="upper ankle joint with lower leg"
      >
        <path
          id="tibia_outlines"
          data-name="tibia outlines"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls8"
          d="M71,.8c.1,7.1.3,17.8.6,30.9,1.6,61.4,4.3,81.4,7.3,141.1,3.3,66.4,3.5,113,3.5,128.1,0,30,0,45-.7,58-4.8,86.3-7.2,129.5-12.8,149.9-1.7,6-6.8,22.8,1.6,32,5.1,5.7,8.7,4.5,10.4,2.4,1.5-1.9,1.2-2.4,2.6-6.2,1.4-3.8,8.3-3.4,36.1-3.6,12.9-.1,17.2-.2,21,3.2,3.4,3.2,5.8,5.9,10.8,15.1,4.3,7.9,4.1,12.9,7.8,13.1,5.1.3,10.5-8.8,12.2-16.8,2.7-12.2-2.8-22.9-6.6-30.1-18.2-34.4-22.3-40.2-27.4-51.3-4.9-10.7-7.7-27.2-13.1-59.8-1.6-9.7-6-36.3-8.4-60.4-6.2-61.9-.1-122.7.6-137,2.4-49.3,3.6-119.9-2.5-207.9"
        />
        <path
          id="fibula_outlines"
          data-name="fibula outlines"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls8"
          d="M24.1.6c.8,10.6,2,26.6,3.4,45.9,4.4,61.7,4.5,77.1,6.7,107.1,5.5,74.6,11.1,64.6,15.4,132.2,4.9,77.3,4.7,105.1,4.7,105.1,0,14.5-.2,30.6,3.6,52.6,2.9,17.2,5.2,19.7,8.2,40.5,2.9,19.8,1.5,23.7,5.6,36.1,1.8,5.5,3.8,9.9,3.8,17,0,5.5-1.2,7.1-5.3,21.9-4.7,16.8-4.2,11.4-7.3,14.9-4.1,4.7-6.4,4.5-14.1,11.8-5.5,5.3-6.7,7.2-9.1,6.9-4.9-.7-6.6-7.6-6.8-9.1-1.3-11.7,1.7-21,2.3-23,4.8-15.1,4.6-31.4,7.6-46.9,5.4-28,2.1-56.7-1-86-5.2-48.7-9-97.5-14.2-146.2-5.4-50.7-3.4-39.4-15.9-171.3C7.2,62.6,3.4,24.1,1,.1"
        />
        <path
          id="talus_outlines"
          data-name="talus outlines"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls8"
          d="M163.5,631.2c1.4-2.6,3.3-6.7,4.1-12,1-6,.1-10.9-.8-15-3.3-14.4-7.5-17.4-12.6-32.2-4.5-13.1-3.7-13.8-9.7-21.1-5.6-6.8-10.6-11.1-11.8-11.3-3.6-.8-5.7-.9-12.9.2-7.7,1.2-7.8.6-15.3,1.6-13.9,1.9-20.8,2.8-23.6,6-6,6.8,1.2,15.4-5.6,24.5-1,1.4-4.8,3.9-12.3,9-10.1,6.8-12.3,7.4-14.1,10.8-2.2,4.2-1.4,7.9-.2,16,.9,6.3,2,15.5,2.7,27"
        />
        <path
          id="tibia_1"
          data-name="tibia 1"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls9"
          d="M161.6,529c-8.9-9.1-18-4.2-17.9-9.2"
        />
        <path
          id="talus_1"
          data-name="talus 1"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls9"
          d="M156.8,592.7c-12.7-.2-13.7-19.4-18.5-38"
        />
        <path
          id="talus_2"
          data-name="talus 2"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls9"
          d="M111.9,611.4c-7.4-7.3-3.9-24.8-25.3-38.8"
        />
        <path
          id="tibia_2"
          data-name="tibia 2"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls9"
          d="M133.9,512.7c-19.3,20.7-20.7-7.3-46.6,6.4"
        />
        <path
          id="fibula_1"
          data-name="fibula 1"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls9"
          d="M48,558.6c2.6-3.6,11.5-8,16.4-11.8"
        />
        <path
          id="fibula_2"
          data-name="fibula 2"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls9"
          d="M45.7,572.1c2.3-13.5,25.6-16.8,18.6-11.5"
        />
        <polyline
          id="tibia_3"
          data-name="tibia 3"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls9"
          points="76 454 88.8 394.1 90 288.1 91.3 183.1 81.1 78 79 1.1"
        />
        <polyline
          id="tibia_4"
          data-name="tibia 4"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls9"
          points="139.3 475.7 119.7 427.5 114.5 395.4 112.3 365.4 108.3 271.3 114.5 189.3 109.6 115.3 106.3 1.4"
        />
        <path
          id="foot"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls6"
          d="M87.3,631.5c21.2-14.4,40.2-19,53.5,2.3,8,4.9,15.3,7.9,19.9,3.6"
        />
        <path
          id="foot-2"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls6"
          d="M140.8,633.8c7.8,5.6,14.1,8.4,23.6,8.2h0c10.2,11.5,10.2,21.8.5,31.1-5.8-8-14.6-14.6-24.7-20.6-3.7-2.6-7.6-2.8-11.6-1.4-7.7-12.5-18.2-15.2-31.4-8.3-2.8-3.1-6.2-1.3-12.4-3-4.5-2.4-3.8-5.3,2.6-8.3"
        />
        <path
          id="toe_3"
          data-name="toe 3"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls6"
          d="M47.2,665c-8.3-2-6.7-7.1,3.1-14.9,8.8-4.1,16.9-9,24.6-14.2,4.1-2.8,6.6-2.1,7.4,2.2,2,3.5,6.7,4.5,13.2,3.9-6.8,7-15.2,12.4-25.5,15.9-9.8.5-16.9,2.9-22.8,7.1Z"
        />
        <path
          id="toe_2"
          data-name="toe 2"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls6"
          d="M94.1,660.2h0c-3.7-2.7-8.3-4.4-14.1-4.8l17.2-12.7c14.8-7.7,24.8-3.7,31.4,8.3-6.9.1-10,4.6-11.3,11.1-8.3-6-16.2-7.8-23.2-1.9h0Z"
        />
        <path
          id="toe_4"
          data-name="toe 4"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls7"
          d="M22.38,678.78c3.28-2.99,7.06-5.85,11.31-8.59,6.9-4.7,11.3-3.7,9.9,3.9-.93,2.27-1.97,4.23-3.11,5.93"
        />
        <path
          id="toe_3-2"
          data-name="toe 3-2"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls7"
          d="M62.09,680.03c1.02-1.62,1.93-3.35,2.7-5.23,1-17.5,17.9-23.8,27.6-13-.09,4.89-.51,10.43-1.55,16.7"
        />
        <path
          id="lower_leg_1"
          data-name="lower leg 1"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls6"
          d="M70,623.7c-5.2,4.8-11.1,7.3-17.1,9.8"
        />
        <path
          id="ankle_overlay_1"
          data-name="ankle overlay 1"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls6"
          d="M84,632.4c-6.3,2.7-11-.1-14-8.5"
        />
        <path
          id="ankle_overlay_1-2"
          data-name="ankle overlay 1-2"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls6"
          d="M167.5,619c-7.6,24.5-10.8,24.7-20.5,16.5-18.7-21-40.1-18.9-63-3.2"
        />
        <path
          id="foot_overlay_1"
          data-name="foot overlay 1"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls6"
          d="M68.4,624.7l13.9,13.4-7.4-2.2c23.4-13.4,47.7-27.1,65.8-6.5"
        />
        <path
          id="foot_overlay_1-2"
          data-name="foot overlay 1-2"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls7"
          d="M137.6,625.3c5,7.7,15.4,17.1,26.9,16.9,8.2,7.1,10.6,16.2,1.3,29.9.66,2.3,1.24,4.53,1.7,6.67"
        />
        <path
          id="foot_overlay_1-2-2"
          data-name="foot overlay 1-2"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls7"
          d="M12.24,678.64c8.92-9.58,18.49-21.49,28.56-35.24l27.6-18.7"
        />
        <line
          id="toe_1"
          data-name="toe 1"
          class="Rechter_Unterschenkel_mit_oberem_Sprunggelenk_vd_stehend2304_cls7"
          x1="117.3"
          y1="662.1"
          x2="122.8"
          y2="677.11"
        />
      </g>
      <g id="overlays">
        <path
          id="tibia_overlays"
          data-name="tibia overlays"
          class="Tibia2304Fill"
          style={{
            fill: props.colors.Tibia2304Fill,
            stroke: props.colors.Tibia2304Fill,
          }}
          d="M71,.74c.1,7.1.3,17.8.6,30.9,1.6,61.4,4.3,81.4,7.3,141.1,3.3,66.4,3.5,113,3.5,128.1,0,30,0,45-.7,58-4.8,86.3-7.2,129.5-12.8,149.9-1.7,6-6.8,22.8,1.6,32,5.1,5.7,8.7,4.5,10.4,2.4,1.5-1.9,1.2-2.4,2.6-6.2,1.4-3.8,8.3-3.4,36.1-3.6,12.9-.1,17.2-.2,21,3.2,3.4,3.2,5.8,5.9,10.8,15.1,4.3,7.9,4.1,12.9,7.8,13.1,5.11.29,10.5-8.8,12.2-16.8,2.7-12.2-2.8-22.9-6.6-30.1-18.2-34.4-22.3-40.2-27.4-51.3-4.9-10.7-7.7-27.2-13.1-59.8-1.6-9.7-6-36.3-8.4-60.4-6.2-61.9-.1-122.7.6-137,2.4-49.3,3.6-119.9-2.5-207.9"
        />
        <path
          id="fibula_overlay"
          data-name="fibula overlay"
          class="Fibula2304Fill"
          style={{
            fill: props.colors.Fibula2304Fill,
            stroke: props.colors.Fibula2304Fill,
          }}
          d="M24.1.6c.8,10.6,2,26.6,3.4,45.9,4.4,61.7,4.5,77.1,6.7,107.1,5.5,74.6,11.1,64.6,15.4,132.2,4.9,77.3,4.7,105.1,4.7,105.1,0,14.5-.2,30.6,3.6,52.6,2.9,17.2,5.2,19.7,8.2,40.5,2.9,19.8,1.5,23.7,5.6,36.1,1.8,5.5,3.8,9.9,3.8,17,0,5.5-1.2,7.1-5.3,21.9-4.7,16.8-4.2,11.4-7.3,14.9-4.1,4.7-6.4,4.5-14.1,11.8-5.5,5.3-6.7,7.2-9.1,6.9-4.9-.7-6.6-7.6-6.8-9.1-1.3-11.7,1.7-21,2.3-23,4.8-15.1,4.6-31.4,7.6-46.9,5.4-28,2.1-56.7-1-86-5.2-48.7-9-97.5-14.2-146.2-5.4-50.7-3.4-39.4-15.9-171.3C7.2,62.6,3.4,24.1,1,.1"
        />
        <path
          id="talus_overlay"
          data-name="talus overlay"
          class="Talus2304Fill"
          style={{
            fill: props.colors.Talus2304Fill,
            stroke: props.colors.Talus2304Fill,
          }}
          d="M67.95,624.91c1-.59,2.32-.14,2.71.96,2.96,8.45,11.82,7.63,18.75,2.01,17.74-9.91,34.07-12.23,48.19-2.59,5.19,7.69,11.77,12.64,19.61,15.05,2.97-2.93,5.05-5.99,6.29-9.15,1.4-2.6,3.3-6.7,4.1-12,1-6,.1-10.9-.8-15-3.3-14.4-7.5-17.4-12.6-32.2-4.5-13.1-3.7-13.8-9.7-21.1-5.6-6.8-10.6-11.1-11.8-11.3-3.6-.8-5.7-.9-12.9.2-7.7,1.2-7.8.6-15.3,1.6-13.9,1.9-20.8,2.8-23.6,6-6,6.8,1.2,15.4-5.6,24.5-1,1.4-4.8,3.9-12.3,9-10.1,6.8-12.3,7.4-14.1,10.8-2.2,4.2-1.4,7.9-.2,16,.9,6.3,2,15.5,2.7,27l16.55-9.79Z"
        />
        <path
          id="upper_ankle_joint_overlay"
          data-name="upper ankle joint overlay"
          class="OSG2304Fill"
          style={{
            fill: props.colors.OSG2304Fill,
            stroke: props.colors.OSG2304Fill,
          }}
          d="M168.03,558.19c-5.46,12.08-8.89,21-6.44,28.65-3.92-4.57-8.02-15.75-12.16-28.65-4.41-8.2-9.5-15.22-16.74-18.6-3.75-1.38-9.39-1.38-17.36.29-2.63.55-5.3.9-7.97,1.11-10.48.82-20.05,2.36-26.46,6.4-3.74.41-2.3,8.3-2.85,15.76-.29,3.89-2.1,7.47-5.05,10.02-8.38,7.23-22.47,13.89-24.11,18.51-3.61,2.14-6.8,2.83-9.2.9l23.2-18.7c5.5-1.3,10.5-30.53,12.6-29.31,4.44,2.32,6.82-1.96,8.5-8.48,4.11-4.01,20.14-3.27,35.6-2.76,12.55-1.66,21.77.59,25.96,8.72,7.09,10.63,10.09,22.03,13.64,22.68,3.4.34,6.31-2.02,8.84-6.55Z"
        />
      </g>
    </svg>
  );
}
