import style from "./style.scss";

function Rechte5ZeheDplSitzend1420(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.96 492.58">
      <g id="fifth_toe" data-name="fifth toe">
        <path
          id="fifth_toe_1"
          data-name="fifth toe 1"
          class="Zehe5_rechts1420_cls-2"
          d="M52.23,33.88h0c10.99-13.43,19.54-13.43,23.2,2.44,17.09,10.99,14.65,15.87,1.22,17.09-2.44,6.1-2.44,10.99,3.66,15.87,6.11,6.1,4.89,10.99-2.44,15.87h-32.96c-6.1-4.88-10.99-10.99-1.22-14.65,7.32-3.66,8.54-8.54,3.66-15.87-7.33-6.11-4.89-12.21,4.88-20.76h0Z"
        />
        <path
          id="fifth_toe_2"
          data-name="fifth toe 2"
          class="Zehe5_rechts1420_cls-2"
          d="M36.36,107.1h0c-1.22-13.43,0-23.2,13.43-15.87,4.88-2.44,9.76-2.44,17.09-1.22,19.53-6.1,18.31,2.45,3.66,20.76-4.88,14.66-6.1,25.64-3.66,34.19,8.55,12.21,4.89,15.87-4.88,15.87l-23.2-3.66c-10.99,3.67-17.1,0-9.77-18.31,7.33-6.1,10.99-15.87,7.33-31.74h0v-.02Z"
        />
        <path
          id="fifth_toe_3"
          data-name="fifth toe 3"
          class="Zehe5_rechts1420_cls-2"
          d="M25.38,185.26h0c-2.44-15.87,2.44-25.64,17.09-24.42,3.66,3.66,7.33,3.66,10.99,0,14.65,2.44,23.2,9.77,14.65,26.86-15.87,37.85-13.43,63.48-4.88,84.24,8.54,21.97,4.88,26.86-7.33,17.09-10.98-15.87-21.97-17.09-31.74-6.1-21.98,9.77-23.2,2.44-8.55-17.09,9.77-14.65,14.65-41.51,9.77-80.58h0Z"
        />
        <path
          id="fifth_toe_4"
          data-name="fifth toe 4"
          class="Zehe5_rechts1420_cls-1"
          d="M26.02,484.18c.65-34.3,0-74.78-.65-114.57-20.75-19.53-19.53-32.96-15.87-45.17,2.44-9.77,3.66-19.53,4.88-28.08h0c15.88-17.09,31.75-18.31,47.62-1.22,6.1,4.89,7.32,12.21,4.88,20.76,9.77,10.99,7.33,26.86-3.66,43.95-7.8,40.55-6.63,85.58-2.54,132.23"
        />
        <path
          id="fifth_toe_5"
          data-name="fifth toe 5"
          class="Zehe5_rechts1420_cls-1"
          d="M16.82,297.59c10.99,8.55,21.98,14.65,34.19,8.55l14.65-7.33"
        />
        <path
          id="outline"
          class="Zehe5_rechts1420_cls-3"
          d="M93.73,306.34c5.89-49.96,5.68-96.3-7-141.74,4.95-24.77,6.22-48.95,3.36-72.48,3.47-22.94,3.68-45.04-.24-66.06-1.92-10.31-8.37-19.22-17.45-23.41-11.19-5.15-21.55-.94-31.18,11.46-12.37,19.35-17.09,42.45-13.47,69.65-6.61,26.2-9.28,54.71-9.92,84.42-1.35,6.06-6.56,12.28-17.33,18.72"
        />
      </g>
      <g id="overlays">
        <g id="Phalangen_overlay" data-name="Phalangen overlay">
          <path
            id="Phalangen_overlay_1"
            data-name="Phalangen overlay 1"
            class="phalangen1420Fill"
            style={{
              fill: props.colors.phalangen1420Fill,
              stroke: props.colors.phalangen1420Fill,
              opacity: props.colors.phalangen1420Fill,
            }}
            d="M52.51,33.34h0c10.99-13.43,19.54-13.43,23.2,2.44,17.09,10.99,14.65,15.87,1.22,17.09-2.44,6.1-2.44,10.99,3.66,15.87,6.11,6.1,4.89,10.99-2.44,15.87h-32.96c-6.1-4.88-10.99-10.99-1.22-14.65,7.32-3.66,8.54-8.54,3.66-15.87-7.33-6.11-4.89-12.21,4.88-20.76h0Z"
          />
          <path
            id="Phalangen_overlay_2"
            data-name="Phalangen overlay 2"
            class="phalangen1420Fill"
            style={{
              fill: props.colors.phalangen1420Fill,
              stroke: props.colors.phalangen1420Fill,
              opacity: props.colors.phalangen1420Fill,
            }}
            d="M36.64,106.56h0c-1.22-13.43,0-23.2,13.43-15.87,4.88-2.44,9.76-2.44,17.09-1.22,19.53-6.1,18.31,2.45,3.66,20.76-4.88,14.66-6.1,25.64-3.66,34.19,8.55,12.21,4.89,15.87-4.88,15.87l-23.2-3.66c-10.99,3.67-17.1,0-9.77-18.31,7.33-6.1,10.99-15.87,7.33-31.74h0v-.02Z"
          />
          <path
            id="Phalangen_overlay_3"
            data-name="Phalangen overlay 3"
            class="phalangen1420Fill"
            style={{
              fill: props.colors.phalangen1420Fill,
              stroke: props.colors.phalangen1420Fill,
              opacity: props.colors.phalangen1420Fill,
            }}
            d="M25.66,184.72h0c-2.44-15.87,2.44-25.64,17.09-24.42,3.66,3.66,7.33,3.66,10.99,0,14.65,2.44,23.2,9.77,14.65,26.86-15.87,37.85-13.43,63.48-4.88,84.24,8.54,21.97,4.88,26.86-7.33,17.09-10.98-15.87-21.97-17.09-31.74-6.1-21.98,9.77-23.2,2.44-8.55-17.09,9.77-14.65,14.65-41.51,9.77-80.58h0Z"
          />
        </g>
        <g id="joint_overlay" data-name="joint overlay">
          <ellipse
            id="joint_overlay_1"
            data-name="joint overlay 1"
            class="gelenke1420Fill"
            style={{
              fill: props.colors.gelenke1420Fill,
              stroke: props.colors.gelenke1420Fill,
              opacity: props.colors.gelenke1420Fill,
            }}
            cx="61.35"
            cy="84.61"
            rx="25.75"
            ry="11.49"
          />
          <ellipse
            id="joint_overlay_2"
            data-name="joint overlay 2"
            class="gelenke1420Fill"
            style={{
              fill: props.colors.gelenke1420Fill,
              stroke: props.colors.gelenke1420Fill,
              opacity: props.colors.gelenke1420Fill,
            }}
            cx="47.47"
            cy="158.42"
            rx="25.53"
            ry="11.62"
          />
          <ellipse
            id="joint_overlay_3"
            data-name="joint overlay 3"
            class="gelenke1420Fill"
            style={{
              fill: props.colors.gelenke1420Fill,
              stroke: props.colors.gelenke1420Fill,
              opacity: props.colors.gelenke1420Fill,
            }}
            cx="38.1"
            cy="287.38"
            rx="37.6"
            ry="15.72"
          />
        </g>
      </g>
    </svg>
  );
}

export default Rechte5ZeheDplSitzend1420;
