import style from "./style.scss";

export default function RechtesKniegelenkStressaufnahmeLatLiegend2358(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.44 637.87">
      <g id="knee_stress" data-name="knee stress">
        <g id="background_layer" data-name="background layer">
          <path
            id="background_leg"
            data-name="background leg"
            class="kniegelenk_lat_rechts_stress2358_cls2"
            d="M227.18,623.27l-58.34-2.73,16.42-118.48-5.35-66.91c-11.8,15.42-22.18,32.33-31.72,50.13l-37.37,152.2L1.46,618.33c11.94-58.33,20.64-109.27,18.01-134.37l-3.89-59.74c-.82-12.87,5.7-22.03,14.4-30.1,1.47-8.89,5.74-14.45,13.4-15.98,5.13-.62,5.49-5.37,4.55-11.24-4.34-6.17-4.83-13.63-1.73-22.29l10.03-17.33,7.71-13.64c13.46-12.55,29.82-14.65,48.45-8.61,9.99,5.45,18.03,7.21,23.6,4.25-41.25-27.83-49.81-66.14-49.32-107.34,6.34-41.26,24.51-69.44,54.24-84.87l84.74-25.47L419.8,1.33l41,81.11c-80.75,35.68-151.85,75.93-203.84,125.22-16.29,11.99-18.16,40.31-15.4,73.87-6.35,26.42-25.28,36.55-47.49,42.44l17.38,21.13c8.12,7.62,13.25,15.41,10.11,23.71l26.51,2.89c9.25-1.66,12.06,9.54,6.02,38.39-2.1,15.69-8.44,29.83-18.53,42.6-25.29,66.96-17.71,119.16-8.38,170.58h0Z"
          />
          <path
            id="background_patella"
            data-name="background patella"
            class="kniegelenk_lat_rechts_stress2358_cls2"
            d="M94.43,118.89l-36.45,11.03c-11.11,3.93-18.65,10.68-22.24,20.54-9.27,17.78-13.96,38.02-12.55,61.51,1.24,7.03,3.08,13.29,10.16,12.89l37.37-18.47c5.02-2.67,10.47-21.23,15.95-41.15l16.86-30.45c1.86-12.45-.81-18.29-9.1-15.9h0Z"
          />
          <path
            id="background_patella_2"
            data-name="background patella 2"
            class="kniegelenk_lat_rechts_stress2358_cls2"
            d="M47.94,134.39h0c-7.76,5.42-10.92,12.73-15.49,23.7-7.92,19.02-8.91,34.31-9.11,38.28-.51,10.14-1.33,26.3,5.47,29.16,2.81,1.19,4.28-1.03,15.59-6.11,15.1-6.79,20.15-6.29,26.33-13.03,2.65-2.9,4.05-5.53,5.55-8.36,4.38-8.25,3.59-12.18,7.16-23.27,1.03-3.21,2.99-9.19,6.77-16.27,3.83-7.16,5.71-8.32,10.14-16.87,5.14-9.91,5.14-13.24,4.54-15.7-.17-.7-1.52-5.89-5.93-8.25-6.57-3.52-12.97,2.97-33.75,10.37-11.7,4.17-12.79,3.22-17.27,6.35h0Z"
          />
          <path
            id="background_triangle"
            data-name="background triangle"
            class="kniegelenk_lat_rechts_stress2358_cls2"
            d="M284.01,206.39c14.46,8.86,15.71,13.2,23.57,19.8,11.97,16.41,11.97,23.04,11.97,23.04,0,0,3.23-3.12,6.25-8.46,3.86-6.82,9.18-12.72,13.67-19.14,5.78-8.27,13.65-18.69,29.16-39.19,22.14-29.27,26.02-33.83,24.73-35.29-1.39-1.58-5.19,2.39-16.31,9.41-7.12,4.49-6.99,3.71-31.85,16.68-13.88,7.24-20.82,10.86-23.96,12.85-.35.22-9.01,5.7-18.79,11.41-1.12.66-2.33,1.41-4,2.26-9.09,4.66-13.88,4.13-14.46,6.63h.02Z"
          />
        </g>
        <path
          id="fibula"
          class="kniegelenk_lat_rechts_stress2358_cls5"
          d="M168.84,620.54c.93-4.9,2.25-12.14,3.65-20.96,1.55-9.75,2.11-14.53,4.56-32.81,2.86-21.34,2.87-20.61,4.56-33.72,2.33-18.02,3.49-27.03,3.65-30.99.39-9.61-.65-20.08-2.73-41.01-1.96-19.64-2.93-29.46-3.65-34.63-2.06-14.86-3.11-17.6-1.82-23.7,3.15-14.84,14.64-23.59,17.32-25.52,1.99-1.43,9.82-6.83,20.96-8.2,4.47-.55,4.64.14,22.78,1.82,13.05,1.2,14.5.99,16.41,2.73,1.38,1.26,4.18,4.63.91,27.34-2.29,15.93-3.44,23.89-8.2,33.72-4.74,9.79-7.17,9.46-12.76,20.05-5.61,10.62-7.65,19.47-10.03,30.08-3.88,17.31-4.63,30.56-5.47,46.48-.91,17.3-1.58,30.05,0,47.39,1.63,17.98,4.98,33.12,8.2,44.66"
        />
        <path
          id="tibia_1"
          data-name="tibia 1"
          class="kniegelenk_lat_rechts_stress2358_cls5"
          d="M1.46,618.33c4.99-22.3,8.5-41.2,10.94-55.59,2.88-16.97,4.37-28.34,5.47-39.19,2-19.81,1.87-30.07,1.82-32.81-.21-11.81-1.36-22.34-1.82-26.43-1.25-11.19-.39-22.55-1.82-33.72-.26-2.04-.9-6.73,0-12.76.39-2.64,1.08-7.01,3.65-11.62,3.12-5.62,5.18-4.67,9.1-10.26,5.24-7.48,3.22-11.57,8.2-15.49,4.4-3.46,7.05-1.11,9.57-3.91,5.36-5.96-6.13-17.21-1.37-29.81,1.8-4.77,3.73-4.08,8.2-12.76,3.72-7.21,2.9-8.98,5.47-13.67,5.33-9.72,15.31-13.89,19.14-15.49,2.42-1.01,12.77-5.08,25.52-2.73,11.57,2.13,12.05,7.15,22.78,8.2,9.23.91,10.26-2.89,20.96-2.73,9.3.14,16.05,3.06,23.7,6.38,11.6,5.03,20.43,8.86,23.7,17.32,2.15,5.57-.13,7.89,2.73,11.85,4.65,6.44,12.48,2.82,19.14,8.2,8.4,6.79,6.84,21.46,6.38,27.34-1.04,13.24-7.86,22.4-10.94,26.43-6.39,8.35-10.29,8.61-21.87,19.14-9.63,8.76-15.8,16.65-19.14,20.96-3.69,4.78-14.2,18.91-22.78,40.1-2.24,5.52-4.4,11.66-10.94,39.19-6.12,25.76-8.82,39.69-12.76,57.42-2.88,12.95-7.35,32.11-13.67,55.59"
        />
        <path
          id="femur_1"
          data-name="femur 1"
          class="kniegelenk_lat_rechts_stress2358_cls5"
          d="M419.8,1.33c-34.75,17.99-63.33,31.3-82.94,40.1-40.22,18.05-42.35,16.99-72.91,31.9-22.86,11.14-31.77,16.67-51.04,22.78-9.13,2.9-13.66,3.73-41.92,11.85-18.38,5.28-27.61,7.94-30.08,9.11-26.02,12.35-39.02,35.76-42.84,42.84-2.69,4.98-11.87,23-12.76,48.3-1.4,40.05,19.05,67.99,25.52,76.56,13.41,17.75,27.63,26.29,30.99,28.25,4.55,2.65,11.62,6.69,21.87,9.11,3.5.83,16.82,3.7,33.72,0,9.71-2.12,21.87-4.78,31.9-15.49,2.09-2.23,9.32-10.39,11.85-22.78,1.48-7.27.38-11.24,0-20.05,0,0-.64-14.73,2.73-32.81,2.06-11.09,8.94-24.26,48.3-53.77,30.16-22.62,54.95-36.5,56.51-37.37,27.73-15.4,65.43-35.38,112.1-57.42"
        />
        <path
          id="femur_2"
          data-name="femur 2"
          class="kniegelenk_lat_rechts_stress2358_cls5"
          d="M163.69,322.14c-17.28-15.01-35.83-36.45-42.84-47.39-5.49-8.57-12.88-20.27-18.23-37.37-3.53-11.27-9.01-28.79-5.47-51.04,1.48-9.3,4.4-27.61,19.14-42.84,11.45-11.82,24.91-16.5,30.08-18.23,14.15-4.74,20.12-1.39,33.72-6.38,13.22-4.85,26.6-17.83,32.81-22.78"
        />
        <path
          id="patella_1"
          data-name="patella 1"
          class="kniegelenk_lat_rechts_stress2358_cls5"
          d="M28.8,225.53h0c3.33,1.46,5.31-1.51,20.05-8.2,13.73-6.23,16.96-5.9,21.87-10.94,3.85-3.96,5.45-7.86,8.2-14.58,4.89-11.94,2.69-14.63,7.29-25.52,1.78-4.23,4.22-8.29,9.11-16.41,5.34-8.89,8.22-12.55,9.11-19.14.48-3.53,1.24-9.19-1.82-11.85-3.48-3.03-9.46-.03-22.78,4.56-23.61,8.14-25.19,5.75-31.9,10.94-7.34,5.68-10.34,12.32-15.49,23.7-2.79,6.17-8.23,19.76-9.11,38.28-.52,10.87-1.25,26.21,5.47,29.16h0Z"
        />
        <path
          id="tibia_2"
          data-name="tibia 2"
          class="kniegelenk_lat_rechts_stress2358_cls5"
          d="M15.58,424.22c7.31,0,13.22-12.64,13.22-28.26"
        />
        <polyline
          id="tibia_3"
          data-name="tibia 3"
          class="kniegelenk_lat_rechts_stress2358_cls5"
          points="194.07 323.98 216.54 351.3 222.88 363.99 218.37 380.46 209.25 398.69 194.07 410.09 174.62 424.22 160.04 450.64 152.63 475.22"
        />
        <polyline
          id="patella_2"
          data-name="patella 2"
          class="kniegelenk_lat_rechts_stress2358_cls5"
          points="102.62 118.89 88.95 145.32 80.75 162.65 76.19 181.78 70.72 206.39"
        />
        <path
          id="patella_3"
          data-name="patella 3"
          class="kniegelenk_lat_rechts_stress2358_cls4"
          d="M323.18,38.69c-84.16-3.18-145.96,29.6-220.56,80.2"
        />
        <path
          id="tibia_4"
          data-name="tibia 4"
          class="kniegelenk_lat_rechts_stress2358_cls4"
          d="M164.59,334.9c-43.86,0-79.36-11.01-79.36-24.61"
        />
        <path
          id="tibia_5"
          data-name="tibia 5"
          class="kniegelenk_lat_rechts_stress2358_cls4"
          d="M28.79,489.83c18.64-11.02,39.27-79.86,23.7-109.37"
        />
        <path
          id="tibia_6"
          data-name="tibia 6"
          class="kniegelenk_lat_rechts_stress2358_cls4"
          d="M40.64,435.15c-4.03,0-7.29,17.92-7.29,40.07"
        />
        <path
          id="tibia_7"
          data-name="tibia 7"
          class="kniegelenk_lat_rechts_stress2358_cls4"
          d="M47.93,399.6c-4.03,0-7.29,15.9-7.29,35.55"
        />
        <line
          id="triangle_1"
          data-name="triangle 1"
          class="kniegelenk_lat_rechts_stress2358_cls4"
          x1="319.53"
          y1="249.22"
          x2="393.35"
          y2="147.15"
        />
        <line
          id="triangle_2"
          data-name="triangle 2"
          class="kniegelenk_lat_rechts_stress2358_cls4"
          x1="393.35"
          y1="147.15"
          x2="283.99"
          y2="206.39"
        />
        <path
          id="triangle_3"
          data-name="triangle 3"
          class="kniegelenk_lat_rechts_stress2358_cls4"
          d="M319.53,249.23c-3.19-14.67-19.29-33.65-35.54-42.84"
        />
      </g>
      <g id="overlays">
        <ellipse
          id="knee_joint_overlay"
          data-name="knee joint overlay"
          class="Kniegelenk2358Fill"
          style={{
            fill: props.colors.Kniegelenk2358Fill,
            stroke: props.colors.Kniegelenk2358Fill,
          }}
          cx="160.07"
          cy="315.54"
          rx="11.34"
          ry="61.05"
          transform="translate(-188.06 378.74) rotate(-73.39)"
        />
        <ellipse
          id="femur_joint_overlay"
          data-name="femur joint overlay"
          class="Retropatellargelenk2358Fill"
          style={{
            fill: props.colors.Retropatellargelenk2358Fill,
            stroke: props.colors.Retropatellargelenk2358Fill,
          }}
          cx="92.82"
          cy="164.85"
          rx="48.02"
          ry="15.66"
          transform="translate(-91.68 206.68) rotate(-73.39)"
        />
      </g>
    </svg>
  );
}
