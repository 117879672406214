import AbdomenApStehend1101 from "./1101_Abdomen_ap_stehend/AbdomenApStehend1101";
import AbdomenPaStehend1102 from "./1102_Abdomen_pa_stehend/AbdomenPaStehend1102";
import AbdomenInLinksseitenlageApLiegend1103 from "./1103_Abdomen_in_linksseitenlage_ap_liegend/AbdomenInLinksseitenlageApLiegend1103";
import AbdomenInLinksseitenlagePaLiegend1104 from "./1104_Abdomen_in_linksseitenlage_pa_liegend/AbdomenInLinksseitenlagePaLiegend1104";
import BeckenVdLiegend1201 from "./1201_Becken_vd_liegend/BeckenVdLiegend1201";
import BeckenVdStehend1203 from "./1203_Becken_vd_stehend/BeckenVdStehend1203";
import IliosakralgelenkeVdLiegend1204 from "./1204_Iliosakralgelenke_vd_liegend/IliosakralgelenkeVdLiegend1204";
import SymphyseDvLiegend1205 from "./1205_Symphyse_dv_liegend/SymphyseDvLiegend1205";
import LinkesIliosakralgelenkVdOblLiegend1206 from "./1206_Linkes_Iliosakralgelenk_vd_obl_liegend/LinkesIliosakralgelenkVdOblLiegend1206";
import RechtesIliosakralgelenkVdOblLiegend1207 from "./1207_Rechtes_Iliosakralgelenk_vd_obl_liegend/RechtesIliosakralgelenkVdOblLiegend1207";
import LinkesBeckenAlaNachJudetVdOblLiegend1208 from "./1208_Linkes_Becken_ALA_nach_Judet_vd_obl_liegend/LinkesBeckenAlaNachJudetVdOblLiegend1208";
import RechtesBeckenAlaNachJudetVdOblLiegend1209 from "./1209_Rechtes_Becken_ALA_nach_Judet_vd_obl_liegend/RechtesBeckenAlaNachJudetVdOblLiegend1209";
import LinkerDaumenFinger1DpSitzend1301 from "./1301_Linker_Daumen_Finger1_dp_sitzend/LinkerDaumenFinger1DpSitzend1301";
import RechterDaumen1FingerDpSitzend1302 from "./1302_rechte_Daumen_Finger1_dp_sitzend/RechterDaumen1FingerDpSitzend1302";
import LinkerDaumen1FingerPdSitzend1303 from "./1303_Linker_Daumen_Finger1_pd_sitzend/LinkerDaumen1FingerPdSitzend1303";
import RechterDaumen1FingerPdSitzend1304 from "./1304_rechte_Daumen_Finger1_pd_sitzend/RechterDaumen1FingerPdSitzend1304";
import LinkerDaumen1FingerLatSitzend1305 from "./1305_Daumen_lat_links/LinkerDaumen1FingerLatSitzend1305";
import DeumenLatRechts1306 from "./1306_Daumen_lat_rechts/DeumenLatRechts1306";
import LinkesDaumenGrundGelenkDpSitzend1307 from "./1307_Linkes_Daumengrundgelenk_dp_sitzend/LinkesDaumenGrundGelenkDpSitzend1307";
import RechtesDaumenGrundGelenkDpSitzend from "./1308_Rechtes_Daumengrundgelenk_dp_sitzend/RechtesDaumenGrundGelenkDpSitzend";
import LinkesDaumenGrundGelenkLatSitzend1309 from "./1309_Linkes_Daumengrundgelenk_lat_sitzend/LinkesDaumenGrundGelenkLatSitzend1309";
import RechtesDaumenGrundGelenkLatSitzend1310 from "./1310_Rechtes_Daumengrundgelenk_lat_sitzend/RechtesDaumenGrundGelenkLatSitzend1310";
import LinkesDaumenSattelGelenkDpSitzend1311 from "./1311_Daumensattelgelenk_dp_left/LinkesDaumenSattelGelenkDpSitzend1311";
import RechtesDaumenSattelGelenkDpSitzend1312 from "./1312_Daumensattelgelenk_dp_right/RechtesDaumenSattelGelenkDpSitzend1312";
import LinkesDaumenSattelGelenkLatSitzend1313 from "./1313_Daumensattelgelenk_links/LinkesDaumenSattelGelenkLatSitzend1313";
import RechtesDaumenSattelGelenkLatSitzend1314 from "./1314_Daumensattelgelenk_rechts/RechtesDaumenSattelGelenkLatSitzend1314";
import Linker2FingerDpSitzend1315 from "./1315_Linker_Finger2_dp_sitzend/Linker2FingerDpSitzend1315";
import Rechter2FingerDpSitzend1316 from "./1316_Rechter_Finger2_dp_sitzend/Rechter2FingerDpSitzend1316";
import Linker2FingerLatSitzend1317 from "./1317_Linker_Finger2_lat_sitzend/Linker2FingerLatSitzend1317";
import Rechter2FingerLatSitzend1318 from "./1318_Rechter_Finger2_lat_sitzend/Rechter2FingerLatSitzend1318";
import Linker3FingerDpSitzend1319 from "./1319_Linker_Finger3_dp_sitzend/Linker3FingerDpSitzend1319";
import Rechter3FingerDpSitzend1320 from "./1320_Rechter_Finger3_dp_sitzend/Rechter3FingerDpSitzend1320";
import Linker3FingerLatSitzend1321 from "./1321_Linker_Finger3_lat_sitzend/Linker3FingerLatSitzend1321";
import Rechter3FingerLatSitzend1322 from "./1322_Rechter_Finger3_lat_sitzend/Rechter3FingerLatSitzend1322";
import Linker4FingerDpSitzend1323 from "./1323_Linker_Finger4_dp_sitzend/Linker4FingerDpSitzend1323";
import Rechter4FingerDpSitzend1324 from "./1324_Rechter_Finger4_dp_sitzend/Rechter4FingerDpSitzend1324";
import Linker4FingerLatSitzend1325 from "./1325_Linker_Finger4_lat_sitzend/Linker4FingerLatSitzend1325";
import Rechter4FingerLatSitzend1326 from "./1326_Rechter_Finger4_lat_sitzend/Rechter4FingerLatSitzend1326";
import Linker5FingerDpSitzend1327 from "./1327_KleinerFinger_links/Linker5FingerDpSitzend1327";
import Rechter5FingerDpSitzend1328 from "./1328_KleinerFinger_rechts/Rechter5FingerDpSitzend1328";
import Linker5FingerLatSitzend1329 from "./1329_Linker_Finger5_lat_sitzend/Linker5FingerLatSitzend1329";
import Rechter5FingerLatSitzend1330 from "./1330_Rechter_Finger5_lat_sitzend/Rechter5FingerLatSitzend1330";
import MittelhandLinksDp from "./1331_mittelhand_links_dp/MittelhandLinksDp";
import RechteMittelhandDpSitzend1332 from "./1332_mittelhand_rechts_dp/RechteMittelhandDpSitzend1332";
import LinkeMittelhandDpOblSitzend1333 from "./1333_mittelhand_links_dp_obl/LinkeMittelhandDpOblSitzend1333";
import RechteMittelhandDpOblSitzend1334 from "./1334_mittelhand_rechts_dp_obl/RechteMittelhandDpOblSitzend1334";
import LinkeMittelhandLatSitzend1335 from "./1335_mittelhand_links_lat/LinkeMittelhandLatSitzend1335";
import RechteMittelhandLatSitzend1336 from "./1336_mittelhand_rechts_lat/RechteMittelhandLatSitzend1336";
import LinkeHandDpSitzend1337 from "./1337_linkeHand_dp/LinkeHandDpSitzend1337";
import RechteHandDpSitzend1338 from "./1338_rechts_Hand_dp/RechteHandDpSitzend1338";
import LinkeHandDpOblSitzend1339 from "./1339_Linke_Hand_dp_obl_sitzend/LinkeHandDpOblSitzend1339";
import RechteHandDpOblSitzend1340 from "./1340_Rechte_Hand_dp_obl_sitzend/RechteHandDpOblSitzend1340";
import LinkeHandLatSitzend1341 from "./1341_Hand_lat_links/LinkeHandLatSitzend1341";
import RechteHandLatSitzend1342 from "./1342_Hand_lat_rechts/RechteHandLatSitzend1342";
import LinkeHandGespreiztLatSitzend1343 from "./1343_Hand_lat_gespreizt_links/LinkeHandGespreiztLatSitzend1343";
import RechteHandGespreiztLatSitzend1344 from "./1344_Hand_lat_gespreizt/RechteHandGespreiztLatSitzend1344";
import BeideHandeDpSitzend1345 from "./1345_beide_Hande_dp/BeideHandeDpSitzend1345";
import BeideHandeDpOblSitzend1346 from "./1346_beide_Hande_obl/BeideHandeDpOblSitzend1346";
import LinkesKahnbeinDaumenRausDpSitzend1347 from "./1347_Linkes_Kahnbein_Daumen_raus_dp/LinkesKahnbeinDaumenRausDpSitzend1347";
import RechtesKahnbeinDaumenRausDpSitzend1348 from "./1348_Rechtes_Kahnbein_Daumen_raus_dp/RechtesKahnbeinDaumenRausDpSitzend1348";
import LinkesKahnbeinDaumenReinDpSitzend1349 from "./1349_Linkes_Kahnbein_Daumen_rein_dp/LinkesKahnbeinDaumenReinDpSitzend1349";
import RechtesKahnbeinDaumenReinDpSitzend1350 from "./1350_Rechtes_Kahnbein_Daumen_rein_dp/RechtesKahnbeinDaumenReinDpSitzend1350";
import LinkesKahnbeinDpOblSitzend1351 from "./1351_kahnbein_pd_obl_links/LinkesKahnbeinDpOblSitzend1351";
import RechtesKahnbeinDpOblSitzend1352 from "./1352_kahnbein_pd_obl_rechts/RechtesKahnbeinDpOblSitzend1352";
import LinkeHandwurzelLatSitzend1353 from "./1353_Linke_Handwurzel_lat_sitzend/LinkeHandwurzelLatSitzend1353";
import RechteHandwurzelLatSitzend1354 from "./1354_Rechte_Handwurzel_lat_sitzend/RechteHandwurzelLatSitzend1354";
import LinkesOsTrapeziumUndOsTrapezoideumPdSitzend1355 from "./1355_linkes_Ostrapezium_und_Ostrapezoideum_dp/LinkesOsTrapeziumUndOsTrapezoideumPdSitzend1355";
import RechtesOsTrapeziumUndOsTrapezoideumPdSitzend1356 from "./1356_Rechtes_Ostrapezium_und_Ostrapezoideum_dp/RechtesOsTrapeziumUndOsTrapezoideumPdSitzend1356";
import LinkesHandGelenkDpSitzend1357 from "./1357_Handgelenk_dp_links/LinkesHandGelenkDpSitzend1357";
import RechtesHandgelenkDpSitzend1358 from "./1358_Handgelenk_dp_rechts/RechtesHandgelenkDpSitzend1358";
import LinkesHandgelenkLatSitzend1359 from "./1359_Handgelenk_lat_links/LinkesHandgelenkLatSitzend1359";
import RechtesHandgelenkLatSitzend1360 from "./1360_Handgelenk_lat_rechts/RechtesHandgelenkLatSitzend1360";
import LinkesHandgelenkDpOblSitzend1361 from "./1361_handgelenk_links_dp_obl/LinkesHandgelenkDpOblSitzend1361";
import RechtesHandGelenkDpOblSitzend1362 from "./1362_handgelenk_rechts_dp_obl/RechtesHandGelenkDpOblSitzend1362";
import LinkerKarpaltunnelHandAufliegendAxSitzend1363 from "./1363_Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend/LinkerKarpaltunnelHandAufliegendAxSitzend1363";
import RechterKarpaltunnelHandAufliegendAxSitzend1364 from "./1364_Rechter_Karpaltunnel_Hand_aufliegend_ax_sitzend/RechterKarpaltunnelHandAufliegendAxSitzend1364";
import LinkerKarpaltunnelUnterarmAufliegendAxSitzend1365 from "./1365_Linker_Karpaltunnel_Unterarm_aufliegend_ax_sitzend/LinkerKarpaltunnelUnterarmAufliegendAxSitzend1365";
import RechterKarpaltunnelUnterarmAufliegendAxSitzend1366 from "./1366_Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend/RechterKarpaltunnelUnterarmAufliegendAxSitzend1366";
import Linker2FingerDpOblSitzend1369 from "./1369_Linker_2Finger_dp_obl_sitzend/Linker2FingerDpOblSitzend1369";
import Rechter2FingerDpOblSitzend1370 from "./1370_Rechter_2Finger_dp_obl_sitzend/Rechter2FingerDpOblSitzend1370";
import Linker3FingerDpOblSitzend1371 from "./1371_Linker_3Finger_dp_obl_sitzend/Linker3FingerDpOblSitzend1371";
import Rechter3FingerDpOblSitzend1372 from "./1372_Rechter_3Finger_dp_obl_sitzend/Rechter3FingerDpOblSitzend1372";
import Linker4FingerDpOblSitzend1373 from "./1373_Linker_4Finger_dp_obl_sitzend/Linker4FingerDpOblSitzend1373";
import Rechter4FingerDpOblSitzend1374 from "./1374_Rechter_4Finger_dp_obl_sitzend/Rechter4FingerDpOblSitzend1374";
import Linker5FingerDpOblSitzend1375 from "./1375_Linker_Finger5_dp_obl_sitzend/Linker5FingerDpOblSitzend1375";
import Rechter5FingerDpOblSitzend1376 from "./1376_Rechter_Finger5_dp_obl_sitzend/Rechter5FingerDpOblSitzend1376";
import BeideHandgelenkeDpSitzend1377 from "./1377_beide_Handgelenke_dp/BeideHandgelenkeDpSitzend1377";
import BeideHandgelenkeLatSitzend1378 from "./1378_beide_Handgelenke_lat/BeideHandgelenkeLatSitzend1378";
import LinkeGroszeheLinks from "./1401_linke_groszehe_links/LinkeGroszeheLinks";
import RechteGroszeheLinks from "./1402_rechte_groszehe_links/RechteGroszeheLinks";
import LinkeGroszeheLatSitzenLinks from "./1403_linke_groszehe_lat_sitzend_links/LinkeGroszeheLatSitzenLinks";
import RechteGroszeheLatSitzend from "./1404_rechte_groszehe_lat_sitzen_links/RechteGroszeheLatSitzend";
import LinkeGrosszehe1ZeheDplOblSitzend1405 from "./1405_Großzehe_Zehe1_dpl_obl_links/LinkeGrosszehe1ZeheDplOblSitzend1405";
import RechteGrosszehe1ZeheDplOblSitzend1406 from "./1406_Großzehe_Zehe1dpl_obl_rechts/RechteGrosszehe1ZeheDplOblSitzend1406";
import LinkeZeheDplSitzend from "./1407_linke_zehe_dpl_sitzend/LinkeZeheDplSitzend";
import RechteZeheDplZitzend from "./1408_rechte_zehe_dpl_sitzend_links/RechteZeheDplZitzend";
import LinkeZeheDplOblSitzendLinks from "./1409_linke_zehe_dpl_obl_sitzend_links/LinkeZeheDplOblSitzendLinks";
import RechteZeheDplOblSitzend from "./1410_rechte_zehe_dpl_obl_sitzend/RechteZeheDplOblSitzend";
import LinkeZeheDplOblSitzend from "./1411_linke_zehe_dpl_obl_sitzend_links/LinkeZeheDplOblSitzend";
import RechteZecheDplSitzend from "./1412_rechte_zehe_dpl_sitzrnd/RechteZecheDplSitzend";
import LinkesSesambeinTangLiegend from "./1425_linkes_sesambein_tang_liegend/LinkesSesambeinTangLiegend";
import LinkeZeheDplOblSitzend1413 from "./1413_linke_zehe_dpl_obl_sitzend/LinkeZeheDplOblSitzend1413";
import Rechte3ZeheDplOblSitzend1414 from "./1414_Rechte_3Zehe_dpl_obl_sitzend/Rechte3ZeheDplOblSitzend1414";
import Linke4ZecheDplSitzend1415 from "./1415_linke_4zehe_dpl_sitzend_links/Linke4ZecheDplSitzend1415";
import Rechte4ZaheDplSitzendLinks1416 from "./1416_rechte_4zehe_dpl_sitzend_links/Rechte4ZaheDplSitzendLinks1416";
import Linke4zeheDplOblSitzend1417 from "./1417_linke_4zehe_dpl_obl_sitzen_links/Linke4zeheDplOblSitzend1417";
import Rechte4zeheDplOblSitzend1418 from "./1418_rechte_4zehe_dpl_obl_sitzend_links/Rechte4zeheDplOblSitzend1418";
import Linke5zeheDplSitzendLinks1419 from "./1419_linke_5zehe_dpl_sitzend_links/Linke5zeheDplSitzendLinks";
import Rechte5ZeheDplSitzend1420 from "./1420_5Zehe_rechts/Rechte5ZeheDplSitzend1420";
import Linke5ZeheLatSitzend1421 from "./1421_Linke_5Zehe_lat_sitzend/Linke5ZeheLatSitzend1421";
import Rechte5ZeheLatSitzend1422 from "./1422_Rechte_5Zehe_lat_sitzend/Rechte5ZeheLatSitzend1422";
import Linke5ZeheDplOblSitzend1423 from "./1423_Linke_5.Zehe_d.pl.obl_sitzend/Linke5ZeheDplOblSitzend1423";
import Rechte5ZeheDplOblSitzend1424 from "./1424_Rechte_5.Zehe_d.pl.obl_sitzend/Rechte5ZeheDplOblSitzend1424";
import RechtesSesambeinGrossZeheTangLiegend1426 from "./1426_Rechtes_Sesambein(Großzehe)_tang_liegend/RechtesSesambeinGrossZeheTangLiegend1426";
import LinkerVorfussDplLiegend1427 from "./1427_vorfuss_dp_links/LinkerVorfussDplLiegend1427";
import RechterVorfussDplLiegend1428 from "./1428_vorfuss_dp_rechts/RechterVorfussDplLiegend1428";
import LinkerVorfussDplOblLiegend1429 from "./1429_vorfuss_dp_obl_links/LinkerVorfussDplOblLiegend1429";
import RechterVorfussDplOblLiegend1430 from "./1430_vorfuß_d.p.obl_rechts/RechterVorfussDplOblLiegend1430";
import LinkerVorfussLatLiegend1431 from "./1431_vorfuß lat links/LinkerVorfussLatLiegend1431";
import RechterVorfussLatLiegend1432 from "./1432_vorfuss_lat_rechts/RechterVorfussLatLiegend1432";
import LinkerMittelfussDplLiegend1433 from "./1433_mittelfuß_d.p_links/LinkerMittelfussDplLiegend1433";
import RechterMittelfussDplLiegend1434 from "./1434_mittelfuß_d.p_echts/RechterMittelfussDplLiegend1434";
import LinkerMittelfussDplOblLiegend1435 from "./1435_mittelfuß_obl_links/LinkerMittelfussDplOblLiegend1435";
import RechterMittelfussDplOblLiegend1436 from "./1436_mittelfuss_obl_rechts/RechterMittelfussDplOblLiegend1436";
import LinkerMittelfussLatLiegend1437 from "./1437_mittefuss_lat_links/LinkerMittelfussLatLiegend1437";
import RechterMittelfussLatLiegend1438 from "./1438_mittefuss_lat_rechts/RechterMittelfussLatLiegend1438";
import LinkerFussDplLiegend1439 from "./1439_fuss_links_dp/LinkerFussDplLiegend1439";
import RechterFussDplLiegend1440 from "./1440_fuss_rechts_dp/RechterFussDplLiegend1440";
import LinkerFussDplOblLiegend1441 from "./1441_Fuss_dpl_obl_links/LinkerFussDplOblLiegend1441";
import RechterFussDplOblLiegend1442 from "./1442_Fuss_dpl_obl_rechts/RechterFussDplOblLiegend1442";
import LinkerFussLatLiegend1443 from "./1443_Linker_Fuss_lat_liegend/LinkerFussLatLiegend1443";
import RechterFussLatLiegend1444 from "./1444_Rechter_Fuss_lat_liegend/RechterFussLatLiegend1444";
import LinkerFussLatStehend1445 from "./1445_Linker_Fuss_lat_stehend/LinkerFussLatStehend1445";
import RechterFussLatStehend1446 from "./1446_Rechter_Fuss_lat_stehend/RechterFussLatStehend1446";
import LinkesFersenbeinLatLiegend1447 from "./1447_Kalkaneus_seitlich_links/LinkesFersenbeinLatLiegend1447";
import RechtesFersenbeinLatLiegend1448 from "./1448_Kalkaneus_seitlich_rechts/RechtesFersenbeinLatLiegend1448";
import LinkesFersenbeinAxSitzend1449 from "./1449_Kalkaneus_ax_links/LinkesFersenbeinAxSitzend1449";
import RechtesFersenbeinAxSitzend1450 from "./1450_Kalkaneus_ax_rechts/RechtesFersenbeinAxSitzend1450";
import LinkeFusswurzelDplSitzend1451 from "./1451_fusswurzel_dp_links/LinkeFusswurzelDplSitzend1451";
import RechteFusswurzelDplSitzend1452 from "./1452_fusswurzel_dp_rechts/RechteFusswurzelDplSitzend1452";
import LinkeFusswurzelDplOblSitzend1453 from "./1453_Fusswurzel_dpl_obl_links/LinkeFusswurzelDplOblSitzend1453";
import FusswurzelDplOblRechts1454 from "./1454_Fusswurzel_dpl_obl_rechts/FusswurzelDplOblRechts1454";
import LinkeFusswurzelLatLiegend1455 from "./1455_Linke_Fusswurzel_lat_liegend/LinkeFusswurzelLatLiegend1455";
import RechteFusswurzelLatLiegend1456 from "./1456_Rechte_Fusswurzel_lat_liegend/RechteFusswurzelLatLiegend1456";
import LinkesOberesSprunggelenkVdLiegend1457 from "./1457_oberes_sprunkgelenk_vd_links/LinkesOberesSprunggelenkVdLiegend1457";
import RechtesOberesSprunggelenkVdLiegend1458 from "./1458_oberes_sprunkgelenk_vd_rechts/RechtesOberesSprunggelenkVdLiegend1458";
import OberesSprunggelenkLatLinks from "./1459_oberes_sprunggelenk_lat_links/OberesSprunggelenkLatLinks";
import RechtesOberesSprunggelenkLatLiegend1460 from "./1460_oberes_sprunggelenk_lat_rechts/RechtesOberesSprunggelenkLatLiegend1460";
import LinkesOberesSprunggelenkInnenrotationVdOblLiegend1463 from "./1463_oberes_Sprunggelenk_Innenrotation_vd_obl_links/LinkesOberesSprunggelenkInnenrotationVdOblLiegend1463";
import RechtesOberesSprunggelenkInnenrotationDvOblLiegend1464 from "./1464_oberes_Sprunggelenk_Innenrotation_vd_obl_rechts/RechtesOberesSprunggelenkInnenrotationDvOblLiegend1464";
import LinkesOberesSprunggelenkStressaufnahmeVdLiegend1465 from "./1465_oberes_Sprunggelenk_Stressaufnahme_vd_links/LinkesOberesSprunggelenkStressaufnahmeVdLiegend1465";
import RechtesOberesSprunggelenkStressaufnahmeVdLiegend1466 from "./1466_oberes_Sprunggelenk_Stressaufnahme_vd_rechts/RechtesOberesSprunggelenkStressaufnahmeVdLiegend1466";
import LinkesOberesSprunggelenkStressaufnahmeLatLiegend1467 from "./1467_oberes_Sprunggelenk_Stressaufnahme_lat_links/LinkesOberesSprunggelenkStressaufnahmeLatLiegend1467";
import RechtesOberesSprunggelenkStressaufnahmeLatLiegend1468 from "./1468_oberes_Sprunggelenk_Stressaufnahme_lat_rechts/RechtesOberesSprunggelenkStressaufnahmeLatLiegend1468";
import LinkesOberesSprunggelenkAussenrotationVdOblLiegend1469 from "./1469_oberes_Sprunggelenk_Aussenrotation_vd_obl_links/LinkesOberesSprunggelenkAussenrotationVdOblLiegend1469";
import RechtesOberesSprunggelenkAussenrotationDvOblLiegend1470 from "./1470_oberes_Sprunggelenk_Aussenrotation_vd_obl_rechts/RechtesOberesSprunggelenkAussenrotationDvOblLiegend1470";
import BeideFusseDplLiegend1471 from "./1471_beide_fusse_dp/BeideFusseDplLiegend1471";
import BeideVorfusseDplLiegend1472 from "./1472_beide_vorfusse_dp/BeideVorfusseDplLiegend1472";
import BeideFusseDplOblLiegend1473 from "./1473_beide_fusse_dpl_obl/BeideFusseDplOblLiegend1473";
import BeideVorfusseDplOblLiegend1474 from "./1474_beide_vorfusse_obl/BeideVorfusseDplOblLiegend1474";
import BrustwirbelsauleVdStehend1501 from "./1501_Brustwirbelsaule_vd_stehend/BrustwirbelsauleVdStehend1501";
import BrustwirbelsauleVdLiegend1502 from "./1502_Brustwirbelsaule_vd_liegend/BrustwirbelsauleVdLiegend1502";
import BrustwirbelsauleLatStehend1503 from "./1503_Brustwirbelsaule_lat_stehend/BrustwirbelsauleLatStehend1503";
import BrustwirbelsauleLatLiegend1504 from "./1504_Brustwirbelsaule_lat_liegend/BrustwirbelsauleLatLiegend1504";
import Brustwirbelsaule75VdOblStehend1505 from "./1505_Brustwirbelsaule75_vd_obl_stehend/Brustwirbelsaule75VdOblStehend1505";
import Brustwirbelsaule75VdOblLiegend1506 from "./1506_Brustwirbelsaule75_vd_obl_liegend/Brustwirbelsaule75VdOblLiegend1506";
import Brustwirbelkorper1VdLiegend1507 from "./1507_Brustwirbelsaule1_ap/Brustwirbelkorper1VdLiegend1507";
import Brustwirbelkorper1LatLiegend1508 from "./1508_BWSkorper1_lat/Brustwirbelkorper1LatLiegend1508";
import Brustwirbelkorper2VdLiegend1509 from "./1509_Brustwirbelsaule2_ap/Brustwirbelkorper2VdLiegend1509";
import Brustwirbelkorper2LatLiegend1510 from "./1510_BWSkorper2_lat/Brustwirbelkorper2LatLiegend1510";
import Brustwirbelkorper3VdLiegend1511 from "./1511_Brustwirbelsaule_ap3/Brustwirbelkorper3VdLiegend1511";
import Brustwirbelkorper3LatLiegend1512 from "./1512_BWSkorper3_lat/Brustwirbelkorper3LatLiegend1512";
import Brustwirbelkorper4VdLiegend1513 from "./1513_Brustwirbelsule_ap4/Brustwirbelkorper4VdLiegend1513";
import Brustwirbelkorper4LatLiegend1514 from "./1514_BWSkorper4_lat/Brustwirbelkorper4LatLiegend1514";
import Brustwirbelkorper5VdLiegend1515 from "./1515_Brustwirbelsaule_ap5/Brustwirbelkorper5VdLiegend1515";
import Brustwirbelkorper5LatLiegend1516 from "./1516_BWSkorper5_lat/Brustwirbelkorper5LatLiegend1516";
import Brustwirbelkorper6VdLiegend1517 from "./1517_Brustwirbelsaule_ap6/Brustwirbelkorper6VdLiegend1517";
import Brustwirbelkorper6LatLiegend1518 from "./1518_BWSkorper6_lat/Brustwirbelkorper6LatLiegend1518";
import Brustwirbelkorper7VdLiegend1519 from "./1519_Brustwirbelsaule7_ap/Brustwirbelkorper7VdLiegend1519";
import Brustwirbelkorper7LatLiegend1520 from "./1520_BWSkorper7_lat/Brustwirbelkorper7LatLiegend1520";
import Brustwirbelkorper8VdLiegend1521 from "./1521_Brustwirbelsaule8_ap/Brustwirbelkorper8VdLiegend1521";
import Brustwirbelkorper8LatLiegend1522 from "./1522_BWSkorper8_lat/Brustwirbelkorper8LatLiegend1522";
import Brustwirbelkorper9VdLiegend1523 from "./1523_Brustwirbelsaule9_ap/Brustwirbelkorper9VdLiegend1523";
import Brustwirbelkorper9LatLiegend1524 from "./1524_BWSkorper9_lat/Brustwirbelkorper9LatLiegend1524";
import Brustwirbelkorper10VdLiegend1525 from "./1525_Brustwirbelsaule10_ap/Brustwirbelkorper10VdLiegend1525";
import Brustwirbelkorper10LatLiegend1526 from "./1526_BWSkorper10_lat/Brustwirbelkorper10LatLiegend1526";
import Brustwirbelkorper11VdLiegend1527 from "./1527_Brustwirbelsaule11_ap/Brustwirbelkorper11VdLiegend1527";
import Brustwirbelkorper11LatLiegend1528 from "./1528_BWSkorper11_lat/Brustwirbelkorper11LatLiegend1528";
import Brustwirbelkorper12VdLiegend1529 from "./1529_Brustwirbelsaule_ap/Brustwirbelkorper12VdLiegend1529";
import Brustwirbelkorper12LatLiegend1530 from "./1530_BWSkorper12_lat/Brustwirbelkorper12LatLiegend1530";
import ThoracolumbalerUbergangVdLiegend1531 from "./1531_Thoracolumbaler_Ubergang_vd_liegend/ThoracolumbalerUbergangVdLiegend1531";
import ThoracolumbalerUbergangVdStehend1532 from "./1532_Thoracolumbaler_Ubergang_vd_stehend/ThoracolumbalerUbergangVdStehend1532";
import ThoracolumbalerUbergangLatLiegend1533 from "./1533_Thoracolumbaler_Ubergang_lat_liegend/ThoracolumbalerUbergangLatLiegend1533";
import ThoracolumbalerUbergangLatStehend1534 from "./1534_Thoracolumbaler_Ubergang_lat_stehend/ThoracolumbalerUbergangLatStehend1534";
import Brustwirbelsaule45VdOblStehend1535 from "./1535_Brustwirbelsaule45_vd_obl_stehend/Brustwirbelsaule45VdOblStehend1535";
import Brustwirbelsaule45VdOblLiegend1536 from "./1536_Brustwirbelsaule45_vd_obl_liegend/Brustwirbelsaule45VdOblLiegend1536";
import HalswirbelsauleVdStehend1601 from "./1601_Halswirbelsaule_vd_stehend/HalswirbelsauleVdStehend1601";
import HalswirbelsauleVdLiegend1602 from "./1602_Halswirbelsaule_vd_liegend/HalswirbelsauleVdLiegend1602";
import HalswirbelsauleMitBewegtemUnterkieferVdStehend1603 from "./1603_hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund/HalswirbelsauleMitBewegtemUnterkieferVdStehend1603";
import HalswirbelsauleLinksAnliegendLatStehend1604 from "./1604_Halswirbelsaule_links_anliegend_lat_stehend/HalswirbelsauleLinksAnliegendLatStehend1604";
import HalswirbelsauleLinksAnliegendVdOblStehend1606 from "./1606_Halswirbelsaule_links_anliegend_vd_obl_stehend/HalswirbelsauleLinksAnliegendVdOblStehend1606";
import HalswirbelsauleRechtsAnliegendVdOblStehend1607 from "./1607_Halswirbelsaule_rechts_anliegend_vd_obl_stehend/HalswirbelsauleRechtsAnliegendVdOblStehend1607";
import DensVdStehend1608 from "./1608_hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund/DensVdStehend1608";
import HalswirbelsauleInklinationLatStehend1609 from "./1609_Halswirbelsaule_Inklination_lat/HalswirbelsauleInklinationLatStehend1609";
import HalswirbelsauleReklinationLatStehend1610 from "./1610_Halswirbelsaule_Reklination_lat/HalswirbelsauleReklinationLatStehend1610";
import CervicothorakalerUbergangVdOblStehend1611 from "./1611_Cervicothorakaler_Ubergang_vd_obl/CervicothorakalerUbergangVdOblStehend1611";
import HalswirbelsauleLinksAnliegendVdOblLiegend1612 from "./1612_Halswirbelsaule_links_anliegend_vd_obl_liegend/HalswirbelsauleLinksAnliegendVdOblLiegend1612";
import HalswirbelsauleRechtsAnliegendVdOblLiegend1613 from "./1613_Halswirbelsaule_rechts_anliegend_vd_obl_liegend/HalswirbelsauleRechtsAnliegendVdOblLiegend1613";
import HalswirbelsauleRechtsAnliegendLatStehend1614 from "./1614_Halswirbelsaule_rechts_anliegend_lat_stehend/HalswirbelsauleRechtsAnliegendLatStehend1614";
import HalswirbelsauleMitAngestellterKassetteLatLiegend1616 from "./1616_Halswirbelsaule_mit_angestellter_Kassette_lat_liegend/HalswirbelsauleMitAngestellterKassetteLatLiegend1616";
import LendenwirbelsauleVdStehend1701 from "./1701_Lendenwirbelsaule_vd_stehend/LendenwirbelsauleVdStehend1701";
import LendenwirbelsauleVdLiegend1702 from "./1702_Lendenwirbelsaule_vd_liegend/LendenwirbelsauleVdLiegend1702";
import LendenwirbelsauleLatStehend1703 from "./1703_Lendenwirbelsaule_lat_stehend/LendenwirbelsauleLatStehend1703";
import LendenwirbelsauleLatLiegend1704 from "./1704_Lendenwirbelsaule_lat_liegend/LendenwirbelsauleLatLiegend1704";
import LendenwirbelsauleVdOblLiegend1705 from "./1705_Lendenwirbelsaule_vd_obl_liegend/LendenwirbelsauleVdOblLiegend1705";
import LendenwirbelsauleLinksAnliegendVdOblStehend1706 from "./1706_Lendenwirbelsaule_links_anliegend_vd_obl_stehend/LendenwirbelsauleLinksAnliegendVdOblStehend1706";
import LendenwirbelsauleInklinationLatStehend1707 from "./1707_lws_inklination/LendenwirbelsauleInklinationLatStehend1707";
import LendenwirbelsauleReklinationLatStehend1708 from "./1708_lws_reklination/LendenwirbelsauleReklinationLatStehend1708";
import Lendenwirbelkorper1VdLiegend1709 from "./1709_Lendenwirbelkorper1_ap/Lendenwirbelkorper1VdLiegend1709";
import Lendenwirbelkorper1LatLiegend1710 from "./1710_Lendenwirbelkorper1_lat/Lendenwirbelkorper1LatLiegend1710";
import Lendenwirbelkorper2VdLiegend1711 from "./1711_Lendenwirbelsaule2_ap/Lendenwirbelkorper2VdLiegend1711";
import Lendenwirbelkorper2LatLiegend1712 from "./1712_Lendenwirbelkorper2_lat/Lendenwirbelkorper2LatLiegend1712";
import Lendenwirbelkorper3VdLiegend1713 from "./1713_Lendenwirbelkorper3_ap/Lendenwirbelkorper3VdLiegend1713";
import Lendenwirbelkorper3LatLiegend1714 from "./1714_Lendenwirbelkorper3_lat/Lendenwirbelkorper3LatLiegend1714";
import Lendenwirbelkorper4VdLiegend1715 from "./1715_Lendenwirbelsaule4_ap/Lendenwirbelkorper4VdLiegend1715";
import Lendenwirbelkorper4LatLiegend1716 from "./1716_Lendenwirbelkorper4_lat/Lendenwirbelkorper4LatLiegend1716";
import Lendenwirbelkorper5VdLiegend1717 from "./1717_Lendenwirbelkorper5_ap/Lendenwirbelkorper5VdLiegend1717";
import Lendenwirbelkorper5LatLiegend1718 from "./1718_Lendenwirbelkorper5_lat/Lendenwirbelkorper5LatLiegend1718";
import KreuzbeinVdLiegend1719 from "./1719_Kreuzbein_ap/KreuzbeinVdLiegend1719";
import KreuzbeinLatLiegend1720 from "./1720_Kreuzbein_seitlich/KreuzbeinLatLiegend1720";
import SteissbeinVdLiegend1721 from "./1721_Steißbein_ap/SteissbeinVdLiegend1721";
import SteissbeinLatLiegend1722 from "./1722_Steißbein_seitlich/SteissbeinLatLiegend1722";
import LumbosacralerÜbergangVdLiegend1723 from "./1723_Lumbosacraler_Ubergang_vd_liegend/LumbosacralerÜbergangVdLiegend1723";
import LumbosacralerUbergangVdStehend1724 from "./1724_Lumbosacraler_Ubergang_vd_stehend/LumbosacralerUbergangVdStehend1724";
import LumbosacralerUbergangLatLiegend1725 from "./1725_Lumbosacraler_Ubergang_lat_liegend/LumbosacralerUbergangLatLiegend1725";
import LumbosacralerUbergangLatStehend1726 from "./1726_Lumbosacraler_Ubergang_lat_stehend/LumbosacralerUbergangLatStehend1726";
import LendenwirbelsauleRechtsAnliegendVdOblStehend1727 from "./1727_Lendenwirbelsaule_rechts_anliegend_vd_obl_stehend/LendenwirbelsauleRechtsAnliegendVdOblStehend1727";
import LendenwirbelsauleMitAngestellterKassetteLatLiegend1728 from "./1728_Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend/LendenwirbelsauleMitAngestellterKassetteLatLiegend1728";
import LinkeRippenDvLiegend1801 from "./1801_Linke_Rippen_dv_liegend/LinkeRippenDvLiegend1801";
import RechteRippenDvLiegend1802 from "./1802_rechte_Rippen_dv_liegen/RechteRippenDvLiegend1802";
import LinkeRippenVdLiegend1803 from "./1803_linke_Rippen_vd_liegend/LinkeRippenVdLiegend1803";
import RechteRippenVdLiegend1804 from "./1804_rechte_Rippen_vd_liegend/RechteRippenVdLiegend1804";
import SchadelPaSitzend1902 from "./1902_schadel_pa_sitzend_links/SchadelPaSitzend1902";
import SchagelPaliegends1903 from "./1903_schagel_pa_liegend_links/SchagelPaliegends1903";
import LinkesSchulterblattVdStehendLinks2023 from "./2023_linkes_schulterblatt_vd_stehend_links/LinkesSchulterblattVdStehendLinks2023";
import LinkesSternoclaviculargelenkDvOblStehend2030 from "./2030_linkes_sternoclaviculargelenk_dv_obl_stehend_links/LinkesSternoclaviculargelenkDvOblStehend2030";
import ScapulaApLeft from "./2034_scapula_ap_left/ScapulaApLeft";
import LinkerUnterarmMitHandgelenkPdSitzend2201 from "./2201_linker_unterarm_mit_handgelenk_pd_sitzent/LinkerUnterarmMitHandgelenkPdSitzend2201";
import RechterUnterarmMitHandgelenkPdSitzend2202 from "./2202_rechter_unterarm_mit_handgelenk_pd_sitzend/RechterUnterarmMitHandgelenkPdSitzend2202";
import LinkerUnterarmMitHandgelenkDpSitzend2203 from "./2203_linker_unterarm_mit_handgelenk_dp_sitzend/LinkerUnterarmMitHandgelenkDpSitzend2203";
import RechterUnterarmMitHandgelenkDpSitzend2204 from "./2204_rechter_unterarm_mit_handgelenk_dp_sitzend/RechterUnterarmMitHandgelenkDpSitzend2204";
import LinkerUnterarmMitHandgelenkLatSitzend2205 from "./2205_linker_unterarm_mit_handgelenk_lat_sitzend_links/LinkerUnterarmMitHandgelenkLatSitzend2205";
import RechterUnterarmMitHandgelenkLatSitzendLinks2206 from "./2206_rechter_unterarm_mit_handgelenk_lat_sitzend/RechterUnterarmMitHandgelenkLatSitzendLinks2206";
import LinkerUnterarmMitEllenbogenLatSitzend2210 from "./2210_linker_unterarm_mit_ellenbogen_lat_sitzend_links/LinkerUnterarmMitEllenbogenLatSitzend2210";
import LinkerEllenbogenVdSitzend2211 from "./2211_linker_ellenbogen_vd_sitzend_links/LinkerEllenbogenVdSitzend2211";
import RechterEllenbogenVdSitzend2212 from "./2212_rechter_ellenbogen_vd_sitzend_links/RechterEllenbogenVdSitzend2212";
import LinkerEllenbogenLatSitzend2213 from "./2213_linker_ellenbogen_lat_sitzend_links/LinkerEllenbogenLatSitzend2213";
import RechterEllenbogenLatSitzend2214 from "./2214_rechter_ellenbogen_lat_sitzend_links/RechterEllenbogenLatSitzend2214";
import OlekranonLinksSXsitzend2215 from "./2215_olekranon_links_ax_sitzend_links/OlekranonLinksSXsitzend2215";
import OlekranonRechtsAXsitzend2216 from "./2216_olekranon_rechts_ax_sitzend_links/OlekranonRechtsAXsitzend2216";
import LinkeSulcusUlnarisSXsitzend2217 from "./2217_linke_sulcus_sulnaris_ax_sitzend/LinkeSulcusUlnarisSXsitzend2217";
import RechteSulcusUlnarisAXsitzend2218 from "./2218_rechte_sulcus_ulnaris_ax_sitzend/RechteSulcusUlnarisAXsitzend2218";
import LinkesRadiuskopfchenMLsitzend2219 from "./2219_linkes_radiuskopfchen_ml_sitzend_links/LinkesRadiuskopfchenMLsitzend2219";
import RechtesRadiuskopfchenMLsitzend2220 from "./2220_rechtes_radiuskopfchen_ml_sitzend_links/RechtesRadiuskopfchenMLsitzend2220";
import RechtesRadiuskopfchenNachGreenspanLatSitzend2221 from "./2221_rechtes_radiuskopfchen_nach_greenspan_lat_sitzend_links/RechtesRadiuskopfchenNachGreenspanLatSitzend2221";
import LinkesRadiuskopfchenNachGreenspanLatSitzend2222 from "./2222_linkes_radiuskopfchen_nach_greenspan_lat_sitzend_links/LinkesRadiuskopfchenNachGreenspanLatSitzend2222";
import LinkerKronenfortsatzMLsitzend2223 from "./2223_linker_kronenfortsatz_ml_sitzend/LinkerKronenfortsatzMLsitzend2223";
import RechterKronenfortsatzMLsitzend2224 from "./2224_rechter_kronenfortsatz_ml_sitzend/RechterKronenfortsatzMLsitzend2224";
import LinkerOberarmMitSchulterVdStehend2225 from "./2225_linker_oberarm_mit_schulter_vd_stehend_links/LinkerOberarmMitSchulterVdStehend2225";
import RechterOberarmMitSchulterVdStehend2226 from "./2226_rechter_oberarm_mit_schulter_vd_stehend_links/RechterOberarmMitSchulterVdStehend2226";
import LinkerOberarmMitSchulterLatStehend2227 from "./2227_linker_oberarm_mit_schulter_lat_stehend_links/LinkerOberarmMitSchulterLatStehend2227";
import RechterOberarmMitSchulterLatStehend2228 from "./2228_rechter_oberarm_mit_schulter_lat_stehend_links/RechterOberarmMitSchulterLatStehend2228";
import LinkerOberarmMitEllenbogenVdSitzend2229 from "./2229_linker_oberarm_mit_llenbogen_vd_sitzend_links/LinkerOberarmMitEllenbogenVdSitzend2229";
import RechterOberarmMitEllenbogenLatSitzend2230 from "./2230_Rechter Oberarm mit Ellenbogen lat sitzend_links/RechterOberarmMitEllenbogenLatSitzend2230";
import RechterOberarmMitEllenbogenVdSitzend2232 from "./2232_rechter_oberarm_mit_ellenbogen_vd_sitzend_links/RechterOberarmMitEllenbogenVdSitzend2232";
import LinkerOberarmMitEllenbogenLatSitzend2233 from "./2233_linker_oberarm_mit_ellenbogen_lat_sitzend_links/LinkerOberarmMitEllenbogenLatSitzend2233";
import LinkerUnterschenkelMitOberemSprunggelenkVdLiegend2301 from "./2301_linker_unterschenkel_mit_obere_sprunggelenk_vd_liegend/LinkerUnterschenkelMitOberemSprunggelenkVdLiegend2301";
import RechterUnterschenkelMitOberemSprunggelenkVdLiegend2302 from "./2302_rechter_unterschenkel_mit_oberem_sprunggelenk_vd_liegend/RechterUnterschenkelMitOberemSprunggelenkVdLiegend2302";
import LinkerUnterschenkelMitOberemSprunggelenkVdStehend2303 from "./2303_linker_unterschenkel_mit_oberem_sprunggelenk_vd_stehend/LinkerUnterschenkelMitOberemSprunggelenkVdStehend2303";
import RechterUnterschenkelMitOberemSprunggelenkVdStehend2304 from "./2304_rechter_unterschenkel_mit_oberem_sprunggelenk_vd_stehend/RechterUnterschenkelMitOberemSprunggelenkVdStehend2304";
import LinkerUnterschenkelMitOberemSprunggelenkLatLiegend2305 from "./2305_linker_unterschenkel_mit_oberem_sprunggelenk_lat_liegend_links/LinkerUnterschenkelMitOberemSprunggelenkLatLiegend2305";
import RechterUnterschenkeMitOberemSprunggelenkLatLiegend2306 from "./2306_rechter_unterschenkel_mit_oberem_sprunggelenk_lat_liegend_links/RechterUnterschenkeMitOberemSprunggelenkLatLiegend2306";
import LinkerUnterschenkelMitKnieVdLiegend2307 from "./2307_linker_unterschenkel_mit_knie_vd_liegend_links/LinkerUnterschenkelMitKnieVdLiegend2307";
import RechterUnterschenkelMitKnieVdLiegend2308 from "./2308_rechter_unterschenkel_mit_knie_vd_liegend_links/RechterUnterschenkelMitKnieVdLiegend2308";
import LinkerUnterschenkelMitKnieVdStehend2309 from "./2309_linker_unterschenkwl_mit_knie_vd_stehend_links/LinkerUnterschenkelMitKnieVdStehend2309";
import RechterUnterschenkelMitKnieVdStehend2310 from "./2310_rechter_unterschenkel_mit_vd_stehend_links/RechterUnterschenkelMitKnieVdStehend2310";
import LinkerUnterschenkelMitKnieLatLiegend2311 from "./2311_linker_unterschenkel_mit_knie_lat_liegend/LinkerUnterschenkelMitKnieLatLiegend2311";
import RechterUnterschenkelMitKnieLatLiegend2312 from "./2312_rechter_unterschenkel_mit_knie_lat_liegend/RechterUnterschenkelMitKnieLatLiegend2312";
import LinkesKniegelenkVdStehendLinks2315 from "./2315_Linkes_Kniegelenk_Vd_Stehend_Links/LinkesKniegelenkVdStehendLinks2315";
import RechtesKniegelenkVdStehendLinks2316 from "./2316_rechtes_kniegelenk_vd_stehend_links/RechtesKniegelenkVdStehendLinks2316";
import KniegelenkVdTepLeft2317 from "./2317_linkes_kniegelenk_tep_vd_liegend_links/KniegelenkVdTepLeft2317";
import RechtesKniegelenkTEPvdLiegendLinks2318 from "./2318_rechtes_kniegelenk_tep_vd_liegend_links/RechtesKniegelenkTEPvdLiegendLinks2318";
import LinkesKniegelenkTEPlatLiegend2319 from "./2319_linkes_kniegelenk_tep_lat_liegend_links/LinkesKniegelenkTEPlatLiegend2319";
import RechtesKniegelenkTEPlatLiegendLinks2320 from "./2320_rechtes_kniegelenk_tep_lat_liegend_links/RechtesKniegelenkTEPlatLiegendLinks2320";
import LinkesKniegelenkVdLiegendLinks2321 from "./2321_linkes_kniegelenk_vd_liegend_links/LinkesKniegelenkVdLiegendLinks2321";
import RechtesKniegelenkVdLiegendLinks2322 from "./2322_rechtes_kniegelenk_vd_liegend_links/RechtesKniegelenkVdLiegendLinks2322";
import LinkesKniegelenkLatLiegendLinks2323 from "./2323_linkes_kniegelenk_lat_liegend_links/LinkesKniegelenkLatLiegendLinks2323";
import RechtesKniegelenkLatLiegend2324 from "./2324_rechtes_kniegelenk_lat_liegend_links/RechtesKniegelenkLatLiegend2324";
import LinkesKniegelenkLatStehendLinks2325 from "./2325_linkes_kniegelenk_lat_stehend_links/LinkesKniegelenkLatStehendLinks2325";
import RechtesKniegelenkLatStehend2326 from "./2326_rechtes_kniegelenk_lat_stehend_links/RechtesKniegelenkLatStehend2326";
import LinkesKniegelenkInnenrotationVdoblLiegend2327 from "./2327_linkes_kniegelenk-innenrotation_vdobl_liegend/LinkesKniegelenkInnenrotationVdoblLiegend2327";
import RechtesKniegelenkInnenrotationVdoblLiegend2328 from "./2328_rechtes_kniegelenk_innenrotation_vdobl _iegend/RechtesKniegelenkInnenrotationVdoblLiegend2328";
import LinkesKniegelenkAuSenrotationVdoblLiegend2329 from "./2329_linkes_kniegelenk_ausenrotation_vdobl_liegend/LinkesKniegelenkAuSenrotationVdoblLiegend2329";
import RechtesKniegelenkAuSenrotationVdoblLiegend2330 from "./2330_rechtes_kniegelenk_ausenrotation_vdobl_liegend/RechtesKniegelenkAuSenrotationVdoblLiegend2330";
import LinkesKniegelenkNachFrikTunnelaufnahmeVdLiegendLinks2331 from "./2331_linkes_kniegelenk_nach_frik_tunnelaufnahme_vd_liegend_links/LinkesKniegelenkNachFrikTunnelaufnahmeVdLiegendLinks2331";
import RechtesKniegelenkNachFrikVdLiegend2332 from "./2332_rechtes_kniegelenk_nachfrik_tunnelaufnahme_vd_liegend_links/RechtesKniegelenkNachFrikVdLiegend2332";
import LinkesKniegelenkNachRosenbergDvStehendLinks2333 from "./2333_linkes_kniegelenk_nach_rosenberg_dv_stehend_links/LinkesKniegelenkNachRosenbergDvStehendLinks2333";
import RechtesKniegelenkNachRosenbergDvStehend2334 from "./2334_rechtes_kniegelenk_nach_rosenberg_dv_stehend/RechtesKniegelenkNachRosenbergDvStehend2334";
import LinkeKniescheibeDvLiegend2335 from "./2335_linke_kniescheibe_dv_liegend_links/LinkeKniescheibeDvLiegend2335";
import RechteKniescheibeDvLiegend2336 from "./2336_rechte_kniescheibe_dv_liegend_links/RechteKniescheibeDvLiegend2336";
import LinkeKniescheibeAxLiegend2337 from "./2337_linke_kniescheibe_ax_liegend_links/LinkeKniescheibeAxLiegend2337";
import RechteKniescheibeAxLiegend2338 from "./2338_rechte_kniescheibe_ax_liegend_links/RechteKniescheibeAxLiegend2338";
import LinkePatellaDeFile30axSitzend2339 from "./2339_linkepatella_defile_30ax_sitzend_links/LinkePatellaDeFile30axSitzend2339";
import RechtePatellaDeFile30axSitzend2340 from "./2340_rechte_patella_defile30ax_sitzend_links/RechtePatellaDeFile30axSitzend2340";
import LinkeGanzbeinstandaufnahmeVdStehend2341 from "./2341_linke_ganzbeinstandaufnahme_vd_stehend_links/LinkeGanzbeinstandaufnahmeVdStehend2341";
import RechteGanzbeinstandaufnahmeVdStehend2342 from "./2342_rechte_ganzbeinstandaufnahme_vd_stehend_links/RechteGanzbeinstandaufnahmeVdStehend2342";
import LinkerOberschenkelMitKnieVdLiegend2343 from "./2343_linker_oberschenkel_mit_knie_vd_liegend_links/LinkerOberschenkelMitKnieVdLiegend2343";
import RechterOberschenkelMitKnieVdLiegend2344 from "./2344_rechter_oberschenkel_mit_knie_vd_liegend_links/RechterOberschenkelMitKnieVdLiegend2344";
import RechterOberschenkelMitKnieLatLiegend2345 from "./2345_linker_oberschenkel_mit_knie_lat_liegend/RechterOberschenkelMitKnieLatLiegend2345";
import RechterOberschenkelMitKnieLatLiegend2346 from "./2346_rechter_oberschenkel_mit_knie_lat_liegend_links/RechterOberschenkelMitKnieLatLiegend2346";
import LinkerOberschenkelMitHufteVdLiegend2347 from "./2347_linker_oberschenkel_mit _hufte_vd_liegend_links/LinkerOberschenkelMitHufteVdLiegend2347";
import RechterOberschenkelMitHufteVdLiegend2348 from "./2348_rechter_oberschenkel_mit_hufte_vd_liegend_links/RechterOberschenkelMitHufteVdLiegend2348";
import LinkerOberschenkelMitHufteVdStehend2349 from "./2349_linker_oberschenkel_mit_hufte_vd_stehend_links/LinkerOberschenkelMitHufteVdStehend2349";
import RechterOberschenkelMitHufteVdStehend2350 from "./2350_rechter_oberschenkel_mit_hufte_vd_stehend_links/RechterOberschenkelMitHufteVdStehend2350";
import LinkerOberschenkelMitHufteVdOblLiegend2351 from "./2351_linker_oberschenkel_mit_hufte_vd_obl_liegend_links/LinkerOberschenkelMitHufteVdOblLiegend2351";
import RechterOberschenkelMitHufteVdOblLiegend2352 from "./2352_rechter_oberschenkel_mit_hufte_vd_obl_liegend_links/RechterOberschenkelMitHufteVdOblLiegend2352";
import LinkerOberschenkelMitHüfteLatLiegend2353 from "./2353_linker_oberschenkel_mit_hufte_lat_liegend_links/LinkerOberschenkelMitHüfteLatLiegend2353";
import RechterOberschenkelMitHufteLatLiegend2354 from "./2354_rechter_oberschenkel_mit_hufte_lat_liegend_links/RechterOberschenkelMitHufteLatLiegend2354";
import LinkesKniegelenkStressaufnahmeVdLiegend2355 from "./2355_linkes_kniegelenk_stressaufnahme_vd_liegend_links/LinkesKniegelenkStressaufnahmeVdLiegend2355";
import RechtesKniegelenkStressaufnahmeVdLiegend2356 from "./2356_rechtes_kniegelenk_stressaufnahme_vd_liegend_links/RechtesKniegelenkStressaufnahmeVdLiegend2356";
import LinkesKniegelenkStressaufnahmeLatLiegend2357 from "./2357_linkes_kniegelenk_stressaufnahme_lat_liegend_links/LinkesKniegelenkStressaufnahmeLatLiegend2357";
import RechtesKniegelenkStressaufnahmeLatLiegend2358 from "./2358_rechtes_kniegelenk_stressaufnahme_lat_liegend_links/RechtesKniegelenkStressaufnahmeLatLiegend2358";
import LinkePatellaDefile60axSitzend2359 from "./2359_linke_patella_defile_60ax_sitzend_links/LinkePatellaDefile60axSitzend2359";
import RechtePatellaDeFile60axSitzend2360 from "./2360_rechte_patella_de_file60ax_sitzend_links/RechtePatellaDeFile60axSitzend2360";
import LinkePatellaDeFile90axSitzend2361 from "./2361_linke_patella_defile90ax_sitzend_links/LinkePatellaDeFile90axSitzend2361";
import RechtePatellaDeFile90axSitzend2362 from "./2362_rechte_patella_defile90ax_sitzend_links/RechtePatellaDeFile90axSitzend2362";
import BeideKnieVdStehend2363 from "./2363_beide_knie_vd_stehend_links/BeideKnieVdStehend2363";
import LinkeHufteVdLiegend2401 from "./2401_linke_hufte_vd_liegend_links/LinkeHufteVdLiegend2401";
import RechteHufteVdLiegend2402 from "./2402_rechte_hufte_vd_liegend_links/RechteHufteVdLiegend2402";
import HufteUbersichtTEPvdLiegend2403 from "./2403_hufte_ubersicht_tep_vd_liegend_links/HufteUbersichtTEPvdLiegend2403";
import LinkeHufteVdOblLiegend2404 from "./2404_linke_hufte_vd_obl_liegend_links/LinkeHufteVdOblLiegend2404";
import RechteHufteVdOblLiegend2405 from "./2405_rechte_hufte_vd_obl_liegend_links/RechteHufteVdOblLiegend2405";
import LinkesHuftlochVdoblLiegend2406 from "./2406_linkes_huftloch_vdobl_liegend_links/LinkesHuftlochVdoblLiegend2406";
import RechtesHuftlochVdoblLiegend2407 from "./2407_rechtes_huftloch_vdobl_liegend_links/RechtesHuftlochVdoblLiegend2407";
import LinkeHufteAbduktionVdOblLiegend2408 from "./2408_linke_hufte_abduktion_vd_obl_liegend_links/LinkeHufteAbduktionVdOblLiegend2408";
import RechteHufteAbduktionVdOblLiegend2409 from "./2409_rechte_hufte_abduktion_vd_obl_liegend/RechteHufteAbduktionVdOblLiegend2409";
import LinkeHufteAdduktionVdOblLiegend2410 from "./2410_linke_hufte_adduktion_vd_obl_liegend/LinkeHufteAdduktionVdOblLiegend2410";
import RechteHufteAdduktionVdOblLiegend2411 from "./2411_rechte_hufte_adduktion_vd_obl_liegend/RechteHufteAdduktionVdOblLiegend2411";
import LinkesHuftgelenkTEPvdLiegend2412 from "./2412_linkes_huftgelenk_tep_vd_liegend_links/LinkesHuftgelenkTEPvdLiegend2412";
import RechtesHuftgelenkTEPvdLiegend2413 from "./2413_rechtes_huftgelenk_tep_vd_liegend/RechtesHuftgelenkTEPvdLiegend2413";
import LinkesHuftgelenkTEPnachLauensteinVdOblLiegend2414 from "./2414_linkes_huftgelenk_tep_nach_lauenstein_vd_obl_liegend/LinkesHuftgelenkTEPnachLauensteinVdOblLiegend2414";
import RechtesHuftgelenkTEPnachLauensteinVdOblLiegend2415 from "./2415_rechtes_huftgelenk_tep_nach_lauenstein_vd_obl_liegend/RechtesHuftgelenkTEPnachLauensteinVdOblLiegend2415";
import HufteUbersichtVdStehend2416 from "./2416_hufte_ubersicht_vd_stehend_links/HufteUbersichtVdStehend2416";
import HufteUbersichtVdLiegend2417 from "./2417_hufte_ubersicht_vd_liegend_links/HufteUbersichtVdLiegend2417";
import ThoraxBettaufnahmeApLiegend2108 from "./2108_thorax_bettaufnahme_ap_liegend/ThoraxBettaufnahmeApLiegend2108";
import RechterUnterarmMitEllenbogenLatSitzend2209 from "./2209_rechter_unterarm_mit_ellenbogen_lat_sitzend_links/RechterUnterarmMitEllenbogenLatSitzend2209";
import LinkerUnterarmMitEllenbogenVdSitzend2208 from "./2208_linker_unterarm_mit_ellenbogen_vd_sitzend_links/LinkerUnterarmMitEllenbogenVdSitzend2208";
import RechterUnterarmMitEllenbogenVdSitzend2207 from "./2207_rechter_unterarm_mit_ellenbogen_vd_sitzend_links/RechterUnterarmMitEllenbogenVdSitzend2207";
import ThoraxHerzfernaufnahmeInspirationPaStehend2107 from "./2107_thorax_herzfernaufnahme_Inspiration_pa_stehend_links/ThoraxHerzfernaufnahmeInspirationPaStehend2107";
import ThoraxExspirationLatStehend2106 from "./2106_thorax_exspiration_lat_stehend_links/ThoraxExspirationLatStehend2106";
import ThoraxInspirationLatStehend2105 from "./2105_thorax_inspiration_lat_stehend_links/ThoraxInspirationLatStehend2105";
import ThoraxExspirationPaStehend2104 from "./2104_thorax_exspiration_pa_stehend_links/ThoraxExspirationPaStehend2104";
import ThoraxInspirationPaStehend2103 from "./2103_thorax_inspiration_pa_stehend_links/ThoraxInspirationPaStehend2103";
import ThoraxMitRasterExspirationApLiegend2102 from "./2102_thorax_mit_raster_exspiration_ap_liegend_links/ThoraxMitRasterExspirationApLiegend2102";
import ThoraxMitRasterInspirationApLiegend2101 from "./2101_thorax_mit_raster_inspiration_ap_liegend_links/ThoraxMitRasterInspirationApLiegend2101";
import RechtesSchulterblattVdLiegend2035 from "./2035_rechtes_schulterblatt_vd_liegend/RechtesSchulterblattVdLiegend2035";
import RechtesSternoclaviculargelenkDvOblLiegend2033 from "./2033_rechtes_sternoclaviculargelenk_dv_obl_liegend/RechtesSternoclaviculargelenkDvOblLiegend2033";
import LinkesSternoclaviculargelenkDvOblLiegend2032 from "./2032_linkes_sternoclaviculargelenk_dv_obl_liegend/LinkesSternoclaviculargelenkDvOblLiegend2032";
import RechtesSternoclaviculargelenkDvOblStehend2031 from "./2031_rechtes_sternoclaviculargelenk_dv_obl_stehend/RechtesSternoclaviculargelenkDvOblStehend2031";
import BrustbeinLatStehend2029 from "./2029_brustbein_lat_stehend_links/BrustbeinLatStehend2029";
import BrustbeinDvOblLiegend2028 from "./2028_brustbein_dv_obl_liegend/BrustbeinDvOblLiegend2028";
import BrustbeinDvOblStehend2027 from "./2027_brustbein_dv_obl_stehend/BrustbeinDvOblStehend2027";
import RechtesSchulterblattLatStehend2026 from "./2026_Rechtes Schulterblatt lat stehend/RechtesSchulterblattLatStehend2026";
import LinkesSchulterblattLatStehend2025 from "./2025_linkes_schulterblatt_lat_stehend/LinkesSchulterblattLatStehend2025";
import RechtesSchulterblattVdStehend2024 from "./2024_rechtes_schulterblatt_vd_stehend/RechtesSchulterblattVdStehend2024";
import RechtesAcromioklavikulargelenkMitBelastungDvStehend2022 from "./2022_rechtes_acromioklavikulargelenk_mit_belastung_dv_stehend/RechtesAcromioklavikulargelenkMitBelastungDvStehend2022";
import LinkesAcromioklavikulargelenkMitBelastungDvStehend2021 from "./2021_linkes_acromioklavikulargelenk_mit Belastung d.v stehend/LinkesAcromioklavikulargelenkMitBelastungDvStehend2021";
import RechtesAcromioklavikulargelenkDvStehend2020 from "./2020_rechtes_acromioklavikulargelenk_dv_stehend/RechtesAcromioklavikulargelenkDvStehend2020";
import LinkesAcromioklavikulargelenkDvStehend2019 from "./2019_linkes_acromioklavikulargelen_kdv_stehend/LinkesAcromioklavikulargelenkDvStehend2019";
import RechtesSchlusselbeinTangStehend2018 from "./2018_rechtes_schlsselbein_tang_stehend/RechtesSchlusselbeinTangStehend2018";
import LinkesSchlusselbeinTangStehend2017 from "./2017_linkes_schlusselbein_tang_stehend/LinkesSchlusselbeinTangStehend2017";
import RechtesSchlusselbeinDvStehend2016 from "./2016_rechtes_schlusselbein_dv_stehend_links/RechtesSchlusselbeinDvStehend2016";
import LinkesSchlusselbeinDvStehend2015 from "./2015_linkes _schlusselbein_dv_stehend_links/LinkesSchlusselbeinDvStehend2015";
import RechtesSchulterOutletViewDvOblStehend2014 from "./2014_rechtes_schulter_outlet_view_dvobl_stehend/RechtesSchulterOutletViewDvOblStehend2014";
import LinkesSchulterOutletViewDvOblStehend2013 from "./2013_linkes_schulter_outlet_view_dv_obl_stehend/LinkesSchulterOutletViewDvOblStehend2013";
import RechtesSchultergelenkYAufnahmeDvOblStehend2012 from "./2012_rechtes_schultergelenk_y_aufnahme_dv_obl_stehend_links/RechtesSchultergelenkYAufnahmeDvOblStehend2012";
import LinkesSchultergelenkYAufnahmeDvOblStehend2011 from "./2011_linkes_schultergelenk_y_aufnahme_dv_obl_stehend_links/LinkesSchultergelenkYAufnahmeDvOblStehend2011";
import RechtesSchultergelenkSchwedenstatusElevationVdStehend2010 from "./2010_rechtes_schultergelenk_schwedenstatus_elevation_vd_stehend_links/RechtesSchultergelenkSchwedenstatusElevationVdStehend2010";
import LinkesSchultergelenkSchwedenstatusElevationМdStehend2009 from "./2009_linkes_schultergelenk_schwedenstatus_elevation_vd_stehend_links/LinkesSchultergelenkSchwedenstatusElevationМdStehend2009";
import RechtesSchultergelenkSchwedenstatusAusenrotationVdStehend2008 from "./2008_rechtes_schultergelenk_schwedenstatus_außenrotation_vd_stehend_links/RechtesSchultergelenkSchwedenstatusAusenrotationVdStehend2008";
import LinkesSchultergelenkSchwedenstatusAusenrotationVdStehend2007 from "./2007_linkes_schultergelenk_schwedenstatus_ausenrotation_vd_stehend_links/LinkesSchultergelenkSchwedenstatusAusenrotationVdStehend2007";
import RechtesSchultergelenkSchwedenstatusInnenrotationVdStehend2006 from "./2006_rechtes_schultergelenk_schwedenstatus_innenrotation_vd_stehend_links/RechtesSchultergelenkSchwedenstatusInnenrotationVdStehend2006";
import LinkesSchultergelenkSchwedenstatusInnenrotationVdStehend2005 from "./2005_linkes_schultergelenk_schwedenstatus_innenrotation_vd_stehend_links/LinkesSchultergelenkSchwedenstatusInnenrotationVdStehend2005";
import RechtesSchultergelenkLatStehend2004 from "./2004_rechtes_schultergelenk_lat_stehend_links/RechtesSchultergelenkLatStehend2004";
import LinkesSchultergelenkLatStehend2003 from "./2003_linkes_schultergelenk_lat_stehend_links/LinkesSchultergelenkLatStehend2003";
import RechtesSchultergelenkМdІtehend2002 from "./2002_rechtes_schultergelenk_vd_stehend/RechtesSchultergelenkМdІtehend2002";
import LinkesSchultergelenkVdStehend2001 from "./2001_linkes_schultergelenk_vd_stehend/LinkesSchultergelenkVdStehend2001";
import SchadelApSitzend1943 from "./1943_schadel_ap_sitzend_links/SchadelApSitzend1943";
import NasennebenhohlenOnStehend1914 from "./1914_nasennebenhohlen_on_stehend_links/NasennebenhohlenOnStehend1914";
import NasenbeinLatSitzend1913 from "./1913_nasenbein_lat_sitzend/NasenbeinLatSitzend1913";
import GesichtsschadelLatSitzend1912 from "./1912_gesichtsschadel_lat_sitzend_links/GesichtsschadelLatSitzend1912";
import SchadelApLiegend1904 from "./1904_schadel_ap_liegend_links/SchadelApLiegend1904";
import RechteRippen812VdLiegend1816 from "./1816_rechte_rippen_8_12_vd_liegend_links/RechteRippen812VdLiegend1816";
import LinkeRippen812VdLiegend1815 from "./1815_linke_rippen_8_12_vd_liegend_links/LinkeRippen812VdLiegend1815";
import RechteRippen17VdLiegend1814 from "./1814_rechte_rippen_1_7_vd_liegend_links/RechteRippen17VdLiegend1814";
import LinkeRippen17VdLiegend1813 from "./1813_linke_rippen_1_7_vd_liegend_links/LinkeRippen17VdLiegend1813";
import RechteRippenDvOblStehend1812 from "./1812_rechte_rippen_dv_obl_stehend_links/RechteRippenDvOblStehend1812";
import LinkeRippenDvOblStehend1811 from "./1811_linke_rippen_dv_obl_stehend_links/LinkeRippenDvOblStehend1811";
import RechteRippenVdStehend1810 from "./1810_rechte_rippen_vd_stehend_links/RechteRippenVdStehend1810";
import LinkeRippenVdStehend1809 from "./1809_linke_rippen_vd_stehend_links/LinkeRippenVdStehend1809";
import RechteRippenDvStehend1808 from "./1808_rechte_rippen_dv_stehend_links/RechteRippenDvStehend1808";
import LinkeRippenDvStehend1807 from "./1807_linke_rippen_dv_stehend_links/LinkeRippenDvStehend1807";
import RechteRippenDvOblLiegend1806 from "./1806_rechte_rippen_dv_obl_liegend_links/RechteRippenDvOblLiegend1806";
import LinkeRippenDvOblLiegend1805 from "./1805_linke_rippen_dv_obl_liegend_links/LinkeRippenDvOblLiegend1805";


import {
  QaApiResponse1101,
  QaApiResponse1102,
  QaApiResponse1103,
  QaApiResponse1104,
  QaApiResponse1201,
  QaApiResponse1203,
  QaApiResponse1204,
  QaApiResponse1205,
  QaApiResponse1206,
  QaApiResponse1207,
  QaApiResponse1208,
  QaApiResponse1209,
  QaApiResponse1301,
  QaApiResponse1302,
  QaApiResponse1303,
  QaApiResponse1304,
  QaApiResponse1305,
  QaApiResponse1306,
  QaApiResponse1307,
  QaApiResponse1308,
  QaApiResponse1309,
  QaApiResponse1310,
  QaApiResponse1311,
  QaApiResponse1312,
  QaApiResponse1313,
  QaApiResponse1314,
  QaApiResponse1315,
  QaApiResponse1316,
  QaApiResponse1317,
  QaApiResponse1318,
  QaApiResponse1319,
  QaApiResponse1320,
  QaApiResponse1321,
  QaApiResponse1322,
  QaApiResponse1323,
  QaApiResponse1324,
  QaApiResponse1325,
  QaApiResponse1326,
  QaApiResponse1327,
  QaApiResponse1328,
  QaApiResponse1329,
  QaApiResponse1330,
  QaApiResponse1331,
  QaApiResponse1332,
  QaApiResponse1333,
  QaApiResponse1334,
  QaApiResponse1335,
  QaApiResponse1336,
  QaApiResponse1337,
  QaApiResponse1338,
  QaApiResponse1339,
  QaApiResponse1340,
  QaApiResponse1341,
  QaApiResponse1342,
  QaApiResponse1343,
  QaApiResponse1344,
  QaApiResponse1345,
  QaApiResponse1346,
  QaApiResponse1347,
  QaApiResponse1348,
  QaApiResponse1349,
  QaApiResponse1350,
  QaApiResponse1351,
  QaApiResponse1352,
  QaApiResponse1353,
  QaApiResponse1354,
  QaApiResponse1355,
  QaApiResponse1356,
  QaApiResponse1357,
  QaApiResponse1358,
  QaApiResponse1359,
  QaApiResponse1360,
  QaApiResponse1361,
  QaApiResponse1362,
  QaApiResponse1363,
  QaApiResponse1364,
  QaApiResponse1365,
  QaApiResponse1366,
  QaApiResponse1369,
  QaApiResponse1370,
  QaApiResponse1371,
  QaApiResponse1372,
  QaApiResponse1373,
  QaApiResponse1374,
  QaApiResponse1375,
  QaApiResponse1376,
  QaApiResponse1377,
  QaApiResponse1378,
  QaApiResponse1401,
  QaApiResponse1402,
  QaApiResponse1403,
  QaApiResponse1404,
  QaApiResponse1405,
  QaApiResponse1406,
  QaApiResponse1407,
  QaApiResponse1408,
  QaApiResponse1409,
  QaApiResponse1410,
  QaApiResponse1411,
  QaApiResponse1412,
  QaApiResponse1413,
  QaApiResponse1414,
  QaApiResponse1415,
  QaApiResponse1416,
  QaApiResponse1417,
  QaApiResponse1418,
  QaApiResponse1419,
  QaApiResponse1420,
  QaApiResponse1421,
  QaApiResponse1422,
  QaApiResponse1423,
  QaApiResponse1424,
  QaApiResponse1425,
  QaApiResponse1426,
  QaApiResponse1427,
  QaApiResponse1428,
  QaApiResponse1429,
  QaApiResponse1430,
  QaApiResponse1431,
  QaApiResponse1432,
  QaApiResponse1433,
  QaApiResponse1434,
  QaApiResponse1435,
  QaApiResponse1436,
  QaApiResponse1437,
  QaApiResponse1438,
  QaApiResponse1439,
  QaApiResponse1440,
  QaApiResponse1441,
  QaApiResponse1442,
  QaApiResponse1443,
  QaApiResponse1444,
  QaApiResponse1445,
  QaApiResponse1446,
  QaApiResponse1447,
  QaApiResponse1448,
  QaApiResponse1449,
  QaApiResponse1450,
  QaApiResponse1451,
  QaApiResponse1452,
  QaApiResponse1453,
  QaApiResponse1454,
  QaApiResponse1455,
  QaApiResponse1456,
  QaApiResponse1457,
  QaApiResponse1458,
  QaApiResponse1459,
  QaApiResponse1460,
  QaApiResponse1463,
  QaApiResponse1464,
  QaApiResponse1465,
  QaApiResponse1466,
  QaApiResponse1467,
  QaApiResponse1468,
  QaApiResponse1469,
  QaApiResponse1470,
  QaApiResponse1471,
  QaApiResponse1472,
  QaApiResponse1473,
  QaApiResponse1474,
  QaApiResponse1501,
  QaApiResponse1502,
  QaApiResponse1503,
  QaApiResponse1504,
  QaApiResponse1505,
  QaApiResponse1506,
  QaApiResponse1507,
  QaApiResponse1508,
  QaApiResponse1509,
  QaApiResponse1510,
  QaApiResponse1511,
  QaApiResponse1512,
  QaApiResponse1513,
  QaApiResponse1514,
  QaApiResponse1515,
  QaApiResponse1516,
  QaApiResponse1517,
  QaApiResponse1518,
  QaApiResponse1519,
  QaApiResponse1520,
  QaApiResponse1521,
  QaApiResponse1522,
  QaApiResponse1523,
  QaApiResponse1524,
  QaApiResponse1525,
  QaApiResponse1526,
  QaApiResponse1527,
  QaApiResponse1528,
  QaApiResponse1529,
  QaApiResponse1530,
  QaApiResponse1531,
  QaApiResponse1532,
  QaApiResponse1533,
  QaApiResponse1534,
  QaApiResponse1535,
  QaApiResponse1536,
  QaApiResponse1601,
  QaApiResponse1602,
  QaApiResponse1603,
  QaApiResponse1604,
  QaApiResponse1606,
  QaApiResponse1607,
  QaApiResponse1608,
  QaApiResponse1609,
  QaApiResponse1610,
  QaApiResponse1611,
  QaApiResponse1612,
  QaApiResponse1613,
  QaApiResponse1614,
  QaApiResponse1616,
  QaApiResponse1701,
  QaApiResponse1702,
  QaApiResponse1703,
  QaApiResponse1704,
  QaApiResponse1705,
  QaApiResponse1706,
  QaApiResponse1707,
  QaApiResponse1708,
  QaApiResponse1709,
  QaApiResponse1710,
  QaApiResponse1711,
  QaApiResponse1712,
  QaApiResponse1713,
  QaApiResponse1714,
  QaApiResponse1715,
  QaApiResponse1716,
  QaApiResponse1717,
  QaApiResponse1718,
  QaApiResponse1719,
  QaApiResponse1720,
  QaApiResponse1721,
  QaApiResponse1722,
  QaApiResponse1723,
  QaApiResponse1724,
  QaApiResponse1725,
  QaApiResponse1726,
  QaApiResponse1727,
  QaApiResponse1728,
  QaApiResponse1801,
  QaApiResponse1802,
  QaApiResponse1803,
  QaApiResponse1804,
  QaApiResponse1902,
  QaApiResponse1903,
  QaApiResponse2023,
  QaApiResponse2030,
  QaApiResponse2034,
  QaApiResponse2201,
  QaApiResponse2202,
  QaApiResponse2203,
  QaApiResponse2204,
  QaApiResponse2205,
  QaApiResponse2206,
  QaApiResponse2209,
  QaApiResponse2210,
  QaApiResponse2211,
  QaApiResponse2212,
  QaApiResponse2213,
  QaApiResponse2214,
  QaApiResponse2215,
  QaApiResponse2216,
  QaApiResponse2217,
  QaApiResponse2218,
  QaApiResponse2219,
  QaApiResponse2220,
  QaApiResponse2221,
  QaApiResponse2222,
  QaApiResponse2223,
  QaApiResponse2224,
  QaApiResponse2225,
  QaApiResponse2226,
  QaApiResponse2227,
  QaApiResponse2228,
  QaApiResponse2229,
  QaApiResponse2230,
  QaApiResponse2232,
  QaApiResponse2233,
  QaApiResponse2301,
  QaApiResponse2302,
  QaApiResponse2303,
  QaApiResponse2304,
  QaApiResponse2305,
  QaApiResponse2306,
  QaApiResponse2307,
  QaApiResponse2308,
  QaApiResponse2309,
  QaApiResponse2310,
  QaApiResponse2311,
  QaApiResponse2312,
  QaApiResponse2315,
  QaApiResponse2316,
  QaApiResponse2317,
  QaApiResponse2318,
  QaApiResponse2319,
  QaApiResponse2320,
  QaApiResponse2321,
  QaApiResponse2322,
  QaApiResponse2323,
  QaApiResponse2324,
  QaApiResponse2325,
  QaApiResponse2326,
  QaApiResponse2327,
  QaApiResponse2328,
  QaApiResponse2329,
  QaApiResponse2330,
  QaApiResponse2331,
  QaApiResponse2332,
  QaApiResponse2333,
  QaApiResponse2334,
  QaApiResponse2335,
  QaApiResponse2336,
  QaApiResponse2337,
  QaApiResponse2338,
  QaApiResponse2339,
  QaApiResponse2340,
  QaApiResponse2341,
  QaApiResponse2342,
  QaApiResponse2343,
  QaApiResponse2344,
  QaApiResponse2345,
  QaApiResponse2346,
  QaApiResponse2347,
  QaApiResponse2348,
  QaApiResponse2349,
  QaApiResponse2350,
  QaApiResponse2351,
  QaApiResponse2352,
  QaApiResponse2353,
  QaApiResponse2354,
  QaApiResponse2355,
  QaApiResponse2356,
  QaApiResponse2357,
  QaApiResponse2358,
  QaApiResponse2359,
  QaApiResponse2360,
  QaApiResponse2361,
  QaApiResponse2362,
  QaApiResponse2363,
  QaApiResponse2401,
  QaApiResponse2402,
  QaApiResponse2403,
  QaApiResponse2404,
  QaApiResponse2405,
  QaApiResponse2406,
  QaApiResponse2407,
  QaApiResponse2408,
  QaApiResponse2409,
  QaApiResponse2410,
  QaApiResponse2411,
  QaApiResponse2412,
  QaApiResponse2413,
  QaApiResponse2414,
  QaApiResponse2415,
  QaApiResponse2416,
  QaApiResponse2417,
  QaApiResponse2108,
  QaApiResponse2208,
  QaApiResponse2207,
  QaApiResponse2107,
  QaApiResponse2106,
  QaApiResponse2105,
  QaApiResponse2104,
  QaApiResponse2103,
  QaApiResponse2102,
  QaApiResponse2101,
  QaApiResponse2035,
  QaApiResponse2033,
  QaApiResponse2032,
  QaApiResponse2031,
  QaApiResponse2029,
  QaApiResponse2028,
  QaApiResponse2027,
  QaApiResponse2026,
  QaApiResponse2025,
  QaApiResponse2024,
  QaApiResponse2022,
  QaApiResponse2021,
  QaApiResponse2020,
  QaApiResponse2019,
  QaApiResponse2018,
  QaApiResponse2017,
  QaApiResponse2016,
  QaApiResponse2015,
  QaApiResponse2014,
  QaApiResponse2013,
  QaApiResponse2012,
  QaApiResponse2011,
  QaApiResponse2010,
  QaApiResponse2009,
  QaApiResponse2008,
  QaApiResponse2007,
  QaApiResponse2006,
  QaApiResponse2005,
  QaApiResponse2004,
  QaApiResponse2003,
  QaApiResponse2002,
  QaApiResponse2001,
  QaApiResponse1943,
  QaApiResponse1914,
  QaApiResponse1913,
  QaApiResponse1912,
  QaApiResponse1904,
  QaApiResponse1816,
  QaApiResponse1815,
  QaApiResponse1814,
  QaApiResponse1813,
  QaApiResponse1812,
  QaApiResponse1811,
  QaApiResponse1810,
  QaApiResponse1809,
  QaApiResponse1808,
  QaApiResponse1807,
  QaApiResponse1806,
  QaApiResponse1805,
} from "../mocks/ExamHelperApp.api.mock";
import LinkesHandgelenkDpSitzend1357 from "./1357_Handgelenk_dp_links/LinkesHandGelenkDpSitzend1357";

export const qaIdImageMap = {
  1: AbdomenApStehend1101,
  2: AbdomenApStehend1101,
  3: AbdomenApStehend1101,
  4: AbdomenPaStehend1102,
  5: AbdomenPaStehend1102,
  6: AbdomenPaStehend1102,
  8:AbdomenInLinksseitenlageApLiegend1103,
  9:AbdomenInLinksseitenlageApLiegend1103,
  10:AbdomenInLinksseitenlageApLiegend1103,
  11:AbdomenInLinksseitenlagePaLiegend1104,
  12:AbdomenInLinksseitenlagePaLiegend1104,
  13:AbdomenInLinksseitenlagePaLiegend1104,
  14:BeckenVdLiegend1201,
  15:BeckenVdLiegend1201,
  16:BeckenVdLiegend1201,
  17:BeckenVdLiegend1201,
  18:BeckenVdLiegend1201,
  19:BeckenVdLiegend1201,
  20:BeckenVdStehend1203,
  21:BeckenVdStehend1203,
  22:BeckenVdStehend1203,
  23:BeckenVdStehend1203,
  24:BeckenVdStehend1203,
  25:BeckenVdStehend1203,
  26:IliosakralgelenkeVdLiegend1204,
  27:SymphyseDvLiegend1205,
  28:SymphyseDvLiegend1205,
  29:LinkesIliosakralgelenkVdOblLiegend1206,
  31: RechtesIliosakralgelenkVdOblLiegend1207,
  33:LinkesBeckenAlaNachJudetVdOblLiegend1208,
  34:RechtesBeckenAlaNachJudetVdOblLiegend1209,
  35:LinkerDaumenFinger1DpSitzend1301,
  36:RechterDaumen1FingerDpSitzend1302,
  37:LinkerDaumen1FingerPdSitzend1303,
  38:RechterDaumen1FingerPdSitzend1304,
  39:LinkerDaumen1FingerLatSitzend1305,
  40:DeumenLatRechts1306,
  41:LinkesDaumenGrundGelenkDpSitzend1307,
  42:RechtesDaumenGrundGelenkDpSitzend,
  43:LinkesDaumenGrundGelenkLatSitzend1309,
  44:RechtesDaumenGrundGelenkLatSitzend1310,
  45:LinkesDaumenSattelGelenkDpSitzend1311,
  46:RechtesDaumenSattelGelenkDpSitzend1312,
  47:LinkesDaumenSattelGelenkLatSitzend1313,
  48:RechtesDaumenSattelGelenkLatSitzend1314,
  49:Linker2FingerDpSitzend1315,
  50:Rechter2FingerDpSitzend1316,
  51:Linker2FingerLatSitzend1317,
  52:Rechter2FingerLatSitzend1318,
  53:Linker3FingerDpSitzend1319,
  54:Rechter3FingerDpSitzend1320,
  55: Linker3FingerLatSitzend1321,
  56: Linker3FingerLatSitzend1321,
  57: Linker3FingerLatSitzend1321,
  58:Rechter3FingerLatSitzend1322,
  59:Rechter3FingerLatSitzend1322,
  60:Rechter3FingerLatSitzend1322,
  61:Linker4FingerDpSitzend1323,
  62:Rechter4FingerDpSitzend1324,
  63:Linker4FingerLatSitzend1325,
  64:Linker4FingerLatSitzend1325,
  65:Rechter4FingerLatSitzend1326,
  66:Rechter4FingerLatSitzend1326,
  67:Linker5FingerDpSitzend1327,
  68:Linker5FingerDpSitzend1327,
  69:Rechter5FingerDpSitzend1328,
  70:Rechter5FingerDpSitzend1328,
  71:Linker5FingerLatSitzend1329,
  72:Linker5FingerLatSitzend1329,
  73:Rechter5FingerLatSitzend1330,
  74:Rechter5FingerLatSitzend1330,
  75: MittelhandLinksDp,
  76: MittelhandLinksDp,
  77: MittelhandLinksDp,
  78:RechteMittelhandDpSitzend1332,
  79:RechteMittelhandDpSitzend1332,
  80:RechteMittelhandDpSitzend1332,
  81:LinkeMittelhandDpOblSitzend1333,
  82:LinkeMittelhandDpOblSitzend1333,
  83:RechteMittelhandDpOblSitzend1334,
  84:RechteMittelhandDpOblSitzend1334,
  85:LinkeMittelhandLatSitzend1335,
  86:LinkeMittelhandLatSitzend1335,
  87:LinkeMittelhandLatSitzend1335,
  88:RechteMittelhandLatSitzend1336,
  89:RechteMittelhandLatSitzend1336,
  90:RechteMittelhandLatSitzend1336,
  91:LinkeHandDpSitzend1337,
  92:RechteHandDpSitzend1338,
  93:LinkeHandDpOblSitzend1339,
  94:LinkeHandDpOblSitzend1339,
  95:RechteHandDpOblSitzend1340,
  96:RechteHandDpOblSitzend1340,
  97:LinkeHandLatSitzend1341,
  98:LinkeHandLatSitzend1341,
  99:RechteHandLatSitzend1342,
  100:RechteHandLatSitzend1342,
  101:LinkeHandGespreiztLatSitzend1343,
  102:LinkeHandGespreiztLatSitzend1343,
  103:LinkeHandGespreiztLatSitzend1343,
  104:RechteHandGespreiztLatSitzend1344,
  105:RechteHandGespreiztLatSitzend1344,
  106:RechteHandGespreiztLatSitzend1344,
  107:BeideHandeDpSitzend1345,
  108:BeideHandeDpOblSitzend1346,
  109:LinkesKahnbeinDaumenRausDpSitzend1347,
  110:RechtesKahnbeinDaumenRausDpSitzend1348,
  111:LinkesKahnbeinDaumenReinDpSitzend1349,
  112:RechtesKahnbeinDaumenReinDpSitzend1350,
  113:LinkesKahnbeinDpOblSitzend1351,
  114:RechtesKahnbeinDpOblSitzend1352,
  115:LinkeHandwurzelLatSitzend1353,
  116:RechteHandwurzelLatSitzend1354,
  117:LinkesOsTrapeziumUndOsTrapezoideumPdSitzend1355,
  118: RechtesOsTrapeziumUndOsTrapezoideumPdSitzend1356,
  119:LinkesHandGelenkDpSitzend1357,
  120: RechtesHandgelenkDpSitzend1358,
  121:LinkesHandgelenkLatSitzend1359,
  122:RechtesHandgelenkLatSitzend1360,
  123:LinkesHandgelenkDpOblSitzend1361,
  124:RechtesHandGelenkDpOblSitzend1362,
  125:LinkerKarpaltunnelHandAufliegendAxSitzend1363,
  126:RechterKarpaltunnelHandAufliegendAxSitzend1364,
  127:LinkerKarpaltunnelUnterarmAufliegendAxSitzend1365,
  128:LinkerKarpaltunnelUnterarmAufliegendAxSitzend1365,
  129:RechterKarpaltunnelUnterarmAufliegendAxSitzend1366,
  130:RechterKarpaltunnelUnterarmAufliegendAxSitzend1366,
  131:Linker2FingerDpOblSitzend1369,
  132:Rechter2FingerDpOblSitzend1370,
  133:Linker3FingerDpOblSitzend1371,
  134:Rechter3FingerDpOblSitzend1372,
  135:Linker4FingerDpOblSitzend1373,
  136:Rechter4FingerDpOblSitzend1374,
  137:Linker5FingerDpOblSitzend1375,
  138:Rechter5FingerDpOblSitzend1376,
  139:BeideHandgelenkeDpSitzend1377,
  140:BeideHandgelenkeDpSitzend1377,
  141:BeideHandgelenkeLatSitzend1378,
  142:BeideHandgelenkeLatSitzend1378,
  143:BeideHandgelenkeLatSitzend1378,
  144: LinkeGroszeheLinks,
  145: LinkeGroszeheLinks,
  146: LinkeGroszeheLinks,
  147: RechteGroszeheLinks,
  148: RechteGroszeheLinks,
  149: RechteGroszeheLinks,
  150: LinkeGroszeheLatSitzenLinks,
  151: LinkeGroszeheLatSitzenLinks,
  153: RechteGroszeheLatSitzend,
  154: RechteGroszeheLatSitzend,
  156:LinkeGrosszehe1ZeheDplOblSitzend1405,
  157:RechteGrosszehe1ZeheDplOblSitzend1406,
  158: LinkeZeheDplSitzend,
  159: LinkeZeheDplSitzend,
  160: RechteZeheDplZitzend,
  161: RechteZeheDplZitzend,
  162: LinkeZeheDplOblSitzendLinks,
  163: LinkeZeheDplOblSitzendLinks,
  164: RechteZeheDplOblSitzend,
  165: RechteZeheDplOblSitzend,
  166: LinkeZeheDplOblSitzend,
  167: LinkeZeheDplOblSitzend,
  168: RechteZecheDplSitzend,
  169: RechteZecheDplSitzend,
  170: LinkeZeheDplOblSitzend1413,
  171: LinkeZeheDplOblSitzend1413,
  172: LinkeZeheDplOblSitzend1413,
  173:Rechte3ZeheDplOblSitzend1414,
  174:Rechte3ZeheDplOblSitzend1414,
  175:Rechte3ZeheDplOblSitzend1414,
  176: Linke4ZecheDplSitzend1415,
  177: Linke4ZecheDplSitzend1415,
  178: Linke4ZecheDplSitzend1415,
  179: Rechte4ZaheDplSitzendLinks1416,
  180: Rechte4ZaheDplSitzendLinks1416,
  181: Rechte4ZaheDplSitzendLinks1416,
  182: Linke4zeheDplOblSitzend1417,
  183: Linke4zeheDplOblSitzend1417,
  184: Linke4zeheDplOblSitzend1417,
  185: Rechte4zeheDplOblSitzend1418,
  186: Rechte4zeheDplOblSitzend1418,
  187: Rechte4zeheDplOblSitzend1418,
  188: Linke5zeheDplSitzendLinks1419,
  189: Linke5zeheDplSitzendLinks1419,
  190: Linke5zeheDplSitzendLinks1419,
  191:Rechte5ZeheDplSitzend1420,
  192:Rechte5ZeheDplSitzend1420,
  193:Rechte5ZeheDplSitzend1420,
  194:Linke5ZeheLatSitzend1421,
  195:Rechte5ZeheLatSitzend1422,
  196:Linke5ZeheDplOblSitzend1423,
  197:Linke5ZeheDplOblSitzend1423,
  198:Linke5ZeheDplOblSitzend1423,
  199:Rechte5ZeheDplOblSitzend1424,
  200:Rechte5ZeheDplOblSitzend1424,
  201:Rechte5ZeheDplOblSitzend1424,
  202: LinkesSesambeinTangLiegend,
  203:RechtesSesambeinGrossZeheTangLiegend1426,
  204:LinkerVorfussDplLiegend1427,
  205:LinkerVorfussDplLiegend1427,
  206:RechterVorfussDplLiegend1428,
  207:RechterVorfussDplLiegend1428,
  208:LinkerVorfussDplOblLiegend1429,
  209:RechterVorfussDplOblLiegend1430,
  210:LinkerVorfussLatLiegend1431,
  211:RechterVorfussLatLiegend1432,
  212:LinkerMittelfussDplLiegend1433,
  213:RechterMittelfussDplLiegend1434,
  214:LinkerMittelfussDplOblLiegend1435,
  215:RechterMittelfussDplOblLiegend1436,
  216:LinkerMittelfussLatLiegend1437,
  217:LinkerMittelfussLatLiegend1437,
  218:RechterMittelfussLatLiegend1438,
  219:RechterMittelfussLatLiegend1438,
  220:LinkerFussDplLiegend1439,
  221:LinkerFussDplLiegend1439,
  222:LinkerFussDplLiegend1439,
  223:RechterFussDplLiegend1440,
  224:RechterFussDplLiegend1440,
  225:RechterFussDplLiegend1440,
  226:LinkerFussDplOblLiegend1441,
  227:LinkerFussDplOblLiegend1441,
  228:LinkerFussDplOblLiegend1441,
  229:RechterFussDplOblLiegend1442,
  230:RechterFussDplOblLiegend1442,
  231:RechterFussDplOblLiegend1442,
  232:LinkerFussLatLiegend1443,
  233:LinkerFussLatLiegend1443,
  234:LinkerFussLatLiegend1443,
  235:LinkerFussLatLiegend1443,
  236:RechterFussLatLiegend1444,
  237:RechterFussLatLiegend1444,
  238:RechterFussLatLiegend1444,
  239:RechterFussLatLiegend1444,
  240:LinkerFussLatStehend1445,
  241:LinkerFussLatStehend1445,
  242:LinkerFussLatStehend1445,
  243:LinkerFussLatStehend1445,
  244:RechterFussLatStehend1446,
  245:RechterFussLatStehend1446,
  246:RechterFussLatStehend1446,
  247:RechterFussLatStehend1446,
  248:LinkesFersenbeinLatLiegend1447,
  249:LinkesFersenbeinLatLiegend1447,
  250:LinkesFersenbeinLatLiegend1447,
  251:RechtesFersenbeinLatLiegend1448,
  252:RechtesFersenbeinLatLiegend1448,
  253:RechtesFersenbeinLatLiegend1448,
  254:LinkesFersenbeinAxSitzend1449,
  255:RechtesFersenbeinAxSitzend1450,
  256:LinkeFusswurzelDplSitzend1451,
  257:RechteFusswurzelDplSitzend1452,
  258:LinkeFusswurzelDplOblSitzend1453,
  259:FusswurzelDplOblRechts1454,
  260:LinkeFusswurzelLatLiegend1455,
  261:RechteFusswurzelLatLiegend1456,
  262:LinkesOberesSprunggelenkVdLiegend1457,
  263:LinkesOberesSprunggelenkVdLiegend1457,
  264:LinkesOberesSprunggelenkVdLiegend1457,
  265:RechtesOberesSprunggelenkVdLiegend1458,
  266:RechtesOberesSprunggelenkVdLiegend1458,
  267:RechtesOberesSprunggelenkVdLiegend1458,
  268: OberesSprunggelenkLatLinks,
  269: OberesSprunggelenkLatLinks,
  270: OberesSprunggelenkLatLinks,
  271:RechtesOberesSprunggelenkLatLiegend1460,
  272:RechtesOberesSprunggelenkLatLiegend1460,
  273:RechtesOberesSprunggelenkLatLiegend1460,
  276:LinkesOberesSprunggelenkInnenrotationVdOblLiegend1463,
  277:RechtesOberesSprunggelenkInnenrotationDvOblLiegend1464,
  278:LinkesOberesSprunggelenkStressaufnahmeVdLiegend1465,
  281:RechtesOberesSprunggelenkStressaufnahmeVdLiegend1466,
  284:LinkesOberesSprunggelenkStressaufnahmeLatLiegend1467,
  286:LinkesOberesSprunggelenkStressaufnahmeLatLiegend1467,
  287:RechtesOberesSprunggelenkStressaufnahmeLatLiegend1468,
  289:RechtesOberesSprunggelenkStressaufnahmeLatLiegend1468,
  290:LinkesOberesSprunggelenkAussenrotationVdOblLiegend1469,
  291:RechtesOberesSprunggelenkAussenrotationDvOblLiegend1470,
  292:BeideFusseDplLiegend1471,
  293:BeideFusseDplLiegend1471,
  294:BeideFusseDplLiegend1471,
  295:BeideVorfusseDplLiegend1472,
  296:BeideVorfusseDplLiegend1472,
  297:BeideFusseDplOblLiegend1473,
  298:BeideFusseDplOblLiegend1473,
  299:BeideFusseDplOblLiegend1473,
  300:BeideVorfusseDplOblLiegend1474,
  301:BrustwirbelsauleVdStehend1501,
  302:BrustwirbelsauleVdStehend1501,
  303:BrustwirbelsauleVdStehend1501,
  304:BrustwirbelsauleVdStehend1501,
  305:BrustwirbelsauleVdLiegend1502,
  306:BrustwirbelsauleVdLiegend1502,
  307:BrustwirbelsauleVdLiegend1502,
  308:BrustwirbelsauleVdLiegend1502,
  309:BrustwirbelsauleLatStehend1503,
  310:BrustwirbelsauleLatStehend1503,
  311:BrustwirbelsauleLatStehend1503,
  312:BrustwirbelsauleLatStehend1503,
  313:BrustwirbelsauleLatStehend1503,
  314:BrustwirbelsauleLatLiegend1504,
  315:BrustwirbelsauleLatLiegend1504,
  316:BrustwirbelsauleLatLiegend1504,
  317:BrustwirbelsauleLatLiegend1504,
  318:BrustwirbelsauleLatLiegend1504,
  319:Brustwirbelsaule75VdOblStehend1505,
  320:Brustwirbelsaule75VdOblLiegend1506,
  321:Brustwirbelkorper1VdLiegend1507,
  322:Brustwirbelkorper1VdLiegend1507,
  323:Brustwirbelkorper1LatLiegend1508,
  324:Brustwirbelkorper1LatLiegend1508,
  325:Brustwirbelkorper2VdLiegend1509,
  326:Brustwirbelkorper2VdLiegend1509,
  327:Brustwirbelkorper2LatLiegend1510,
  328:Brustwirbelkorper2LatLiegend1510,
  329:Brustwirbelkorper3VdLiegend1511,
  330:Brustwirbelkorper3VdLiegend1511,
  331:Brustwirbelkorper3LatLiegend1512,
  332:Brustwirbelkorper3LatLiegend1512,
  333:Brustwirbelkorper4VdLiegend1513,
  334:Brustwirbelkorper4VdLiegend1513,
  335:Brustwirbelkorper4LatLiegend1514,
  336:Brustwirbelkorper4LatLiegend1514,
  337:Brustwirbelkorper5VdLiegend1515,
  338:Brustwirbelkorper5VdLiegend1515,
  339:Brustwirbelkorper5LatLiegend1516,
  340:Brustwirbelkorper5LatLiegend1516,
  341:Brustwirbelkorper6VdLiegend1517,
  342:Brustwirbelkorper6VdLiegend1517,
  343:Brustwirbelkorper6LatLiegend1518,
  344:Brustwirbelkorper6LatLiegend1518,
  345:Brustwirbelkorper7VdLiegend1519,
  346:Brustwirbelkorper7VdLiegend1519,
  347:Brustwirbelkorper7LatLiegend1520,
  348:Brustwirbelkorper7LatLiegend1520,
  349:Brustwirbelkorper8VdLiegend1521,
  350:Brustwirbelkorper8VdLiegend1521,
  351:Brustwirbelkorper8LatLiegend1522,
  352:Brustwirbelkorper8LatLiegend1522,
  353:Brustwirbelkorper9VdLiegend1523,
  354:Brustwirbelkorper9VdLiegend1523,
  355:Brustwirbelkorper9LatLiegend1524,
  356:Brustwirbelkorper9LatLiegend1524,
  357:Brustwirbelkorper10VdLiegend1525,
  358:Brustwirbelkorper10VdLiegend1525,
  359:Brustwirbelkorper10LatLiegend1526,
  360:Brustwirbelkorper10LatLiegend1526,
  361:Brustwirbelkorper11VdLiegend1527,
  362:Brustwirbelkorper11VdLiegend1527,
  363:Brustwirbelkorper11LatLiegend1528,
  364:Brustwirbelkorper11LatLiegend1528,
  365:Brustwirbelkorper12VdLiegend1529,
  366:Brustwirbelkorper12VdLiegend1529,
  367:Brustwirbelkorper12LatLiegend1530,
  368:Brustwirbelkorper12LatLiegend1530,
  369:ThoracolumbalerUbergangVdLiegend1531,
  370:ThoracolumbalerUbergangVdLiegend1531,
  371:ThoracolumbalerUbergangVdLiegend1531,
  372:ThoracolumbalerUbergangVdStehend1532,
  373:ThoracolumbalerUbergangVdStehend1532,
  374:ThoracolumbalerUbergangVdStehend1532,
  375:ThoracolumbalerUbergangLatLiegend1533,
  376:ThoracolumbalerUbergangLatLiegend1533,
  377:ThoracolumbalerUbergangLatStehend1534,
  378:ThoracolumbalerUbergangLatStehend1534,
  379:Brustwirbelsaule45VdOblStehend1535,
  380:Brustwirbelsaule45VdOblStehend1535,
  381:Brustwirbelsaule45VdOblLiegend1536,
  382:HalswirbelsauleVdStehend1601,
  383:HalswirbelsauleVdStehend1601,
  384:HalswirbelsauleVdStehend1601,
  385:HalswirbelsauleVdStehend1601,
  386:HalswirbelsauleVdStehend1601,
  387:HalswirbelsauleVdLiegend1602,
  388:HalswirbelsauleVdLiegend1602,
  389:HalswirbelsauleMitBewegtemUnterkieferVdStehend1603,
  390:HalswirbelsauleMitBewegtemUnterkieferVdStehend1603,
  391:HalswirbelsauleMitBewegtemUnterkieferVdStehend1603,
  392:HalswirbelsauleLinksAnliegendLatStehend1604,
  393:HalswirbelsauleLinksAnliegendLatStehend1604,
  394:HalswirbelsauleLinksAnliegendLatStehend1604,
  396:HalswirbelsauleLinksAnliegendVdOblStehend1606,
  397:HalswirbelsauleRechtsAnliegendVdOblStehend1607,
  398:DensVdStehend1608,
  399:HalswirbelsauleInklinationLatStehend1609,
  400:HalswirbelsauleInklinationLatStehend1609,
  401:HalswirbelsauleInklinationLatStehend1609,
  402:HalswirbelsauleInklinationLatStehend1609,
  403:HalswirbelsauleReklinationLatStehend1610,
  404:HalswirbelsauleReklinationLatStehend1610,
  405:HalswirbelsauleReklinationLatStehend1610,
  406:CervicothorakalerUbergangVdOblStehend1611,
  407:HalswirbelsauleLinksAnliegendVdOblLiegend1612,
  408:HalswirbelsauleRechtsAnliegendVdOblLiegend1613,
  409:HalswirbelsauleRechtsAnliegendLatStehend1614,
  410:HalswirbelsauleRechtsAnliegendLatStehend1614,
  411:HalswirbelsauleRechtsAnliegendLatStehend1614,
  413:HalswirbelsauleMitAngestellterKassetteLatLiegend1616,
  414:HalswirbelsauleMitAngestellterKassetteLatLiegend1616,
  415:HalswirbelsauleMitAngestellterKassetteLatLiegend1616,
  416:HalswirbelsauleMitAngestellterKassetteLatLiegend1616,
  417:HalswirbelsauleMitAngestellterKassetteLatLiegend1616,
  418:LendenwirbelsauleVdStehend1701,
  419:LendenwirbelsauleVdStehend1701,
  420:LendenwirbelsauleVdStehend1701,
  421:LendenwirbelsauleVdLiegend1702,
  422:LendenwirbelsauleVdLiegend1702,
  423:LendenwirbelsauleVdLiegend1702,
  424:LendenwirbelsauleLatStehend1703,
  425:LendenwirbelsauleLatStehend1703,
  426:LendenwirbelsauleLatStehend1703,
  427:LendenwirbelsauleLatStehend1703,
  428:LendenwirbelsauleLatStehend1703,
  429:LendenwirbelsauleLatStehend1703,
  430:LendenwirbelsauleLatLiegend1704,
  431:LendenwirbelsauleLatLiegend1704,
  908:LendenwirbelsauleLatLiegend1704,
  909:LendenwirbelsauleLatLiegend1704,
  432:LendenwirbelsauleLatLiegend1704,
  433:LendenwirbelsauleLatLiegend1704,
  434:LendenwirbelsauleVdOblLiegend1705,
  435:LendenwirbelsauleVdOblLiegend1705,
  436:LendenwirbelsauleVdOblLiegend1705,
  437:LendenwirbelsauleLinksAnliegendVdOblStehend1706,
  438:LendenwirbelsauleLinksAnliegendVdOblStehend1706,
  439:LendenwirbelsauleInklinationLatStehend1707,
  440:LendenwirbelsauleInklinationLatStehend1707,
  441:LendenwirbelsauleInklinationLatStehend1707,
  442:LendenwirbelsauleInklinationLatStehend1707,
  443:LendenwirbelsauleReklinationLatStehend1708,
  444:LendenwirbelsauleReklinationLatStehend1708,
  445:LendenwirbelsauleReklinationLatStehend1708,
  447:LendenwirbelsauleReklinationLatStehend1708,
  448:Lendenwirbelkorper1VdLiegend1709,
  449:Lendenwirbelkorper1VdLiegend1709,
  450:Lendenwirbelkorper1LatLiegend1710,
  451:Lendenwirbelkorper1LatLiegend1710,
  452:Lendenwirbelkorper1LatLiegend1710,
  453:Lendenwirbelkorper2VdLiegend1711,
  454:Lendenwirbelkorper2VdLiegend1711,
  455:Lendenwirbelkorper2LatLiegend1712,
  456:Lendenwirbelkorper2LatLiegend1712,
  457:Lendenwirbelkorper2LatLiegend1712,
  458:Lendenwirbelkorper3VdLiegend1713,
  459:Lendenwirbelkorper3VdLiegend1713,
  460:Lendenwirbelkorper3LatLiegend1714,
  461:Lendenwirbelkorper3LatLiegend1714,
  462:Lendenwirbelkorper3LatLiegend1714,
  463:Lendenwirbelkorper4VdLiegend1715,
  464:Lendenwirbelkorper4VdLiegend1715,
  465:Lendenwirbelkorper4LatLiegend1716,
  466:Lendenwirbelkorper4LatLiegend1716,
  467:Lendenwirbelkorper4LatLiegend1716,
  468:Lendenwirbelkorper5VdLiegend1717,
  469:Lendenwirbelkorper5VdLiegend1717,
  470:Lendenwirbelkorper5LatLiegend1718,
  471:Lendenwirbelkorper5LatLiegend1718,
  472:Lendenwirbelkorper5LatLiegend1718,
  473:KreuzbeinVdLiegend1719,
  474:KreuzbeinVdLiegend1719,
  475:KreuzbeinLatLiegend1720,
  476:SteissbeinVdLiegend1721,
  477:SteissbeinLatLiegend1722,
  478:SteissbeinLatLiegend1722,
  479:LumbosacralerÜbergangVdLiegend1723,
  480:LumbosacralerÜbergangVdLiegend1723,
  481:LumbosacralerUbergangVdStehend1724,
  482:LumbosacralerUbergangVdStehend1724,
  483:LumbosacralerUbergangLatLiegend1725,
  484:LumbosacralerUbergangLatLiegend1725,
  485:LumbosacralerUbergangLatLiegend1725,
  486:LumbosacralerUbergangLatStehend1726,
  487:LumbosacralerUbergangLatStehend1726,
  488:LumbosacralerUbergangLatStehend1726,
  489:LendenwirbelsauleRechtsAnliegendVdOblStehend1727,
  490:LendenwirbelsauleRechtsAnliegendVdOblStehend1727,
  491:LendenwirbelsauleMitAngestellterKassetteLatLiegend1728,
  492:LinkeRippenDvLiegend1801,
  493:LinkeRippenDvLiegend1801,
  494:RechteRippenDvLiegend1802,
  495:RechteRippenDvLiegend1802,
  496:LinkeRippenVdLiegend1803,
  497:LinkeRippenVdLiegend1803,
  498:RechteRippenVdLiegend1804,
  499:RechteRippenVdLiegend1804,
  524: SchadelPaSitzend1902,
  525: SchadelPaSitzend1902,
  526: SchagelPaliegends1903,
  527: SchagelPaliegends1903,
  528: SchagelPaliegends1903,
  585: LinkesSchulterblattVdStehendLinks2023,
  595: LinkesSternoclaviculargelenkDvOblStehend2030,
  599: ScapulaApLeft,
  631: LinkerUnterarmMitHandgelenkPdSitzend2201,
  632: RechterUnterarmMitHandgelenkPdSitzend2202,
  633: LinkerUnterarmMitHandgelenkDpSitzend2203,
  634: RechterUnterarmMitHandgelenkDpSitzend2204,
  635: LinkerUnterarmMitHandgelenkLatSitzend2205,
  636: LinkerUnterarmMitHandgelenkLatSitzend2205,
  637: RechterUnterarmMitHandgelenkLatSitzendLinks2206,
  638: RechterUnterarmMitHandgelenkLatSitzendLinks2206,
  643: RechterUnterarmMitEllenbogenLatSitzend2209,
  644: RechterUnterarmMitEllenbogenLatSitzend2209,
  645: LinkerUnterarmMitEllenbogenLatSitzend2210,
  646: LinkerUnterarmMitEllenbogenLatSitzend2210,
  647: LinkerEllenbogenVdSitzend2211,
  648: LinkerEllenbogenVdSitzend2211,
  649: RechterEllenbogenVdSitzend2212,
  650: RechterEllenbogenVdSitzend2212,
  651: LinkerEllenbogenLatSitzend2213,
  652: LinkerEllenbogenLatSitzend2213,
  653: LinkerEllenbogenLatSitzend2213,
  654: LinkerEllenbogenLatSitzend2213,
  655: RechterEllenbogenLatSitzend2214,
  656: RechterEllenbogenLatSitzend2214,
  657: RechterEllenbogenLatSitzend2214,
  658: RechterEllenbogenLatSitzend2214,
  659: OlekranonLinksSXsitzend2215,
  660: OlekranonLinksSXsitzend2215,
  661: OlekranonRechtsAXsitzend2216,
  662: OlekranonRechtsAXsitzend2216,
  663: LinkeSulcusUlnarisSXsitzend2217,
  664: RechteSulcusUlnarisAXsitzend2218,
  665: LinkesRadiuskopfchenMLsitzend2219,
  666: LinkesRadiuskopfchenMLsitzend2219,
  667: RechtesRadiuskopfchenMLsitzend2220,
  668: RechtesRadiuskopfchenMLsitzend2220,
  669: RechtesRadiuskopfchenNachGreenspanLatSitzend2221,
  670: RechtesRadiuskopfchenNachGreenspanLatSitzend2221,
  671: LinkesRadiuskopfchenNachGreenspanLatSitzend2222,
  672: LinkesRadiuskopfchenNachGreenspanLatSitzend2222,
  673: LinkerKronenfortsatzMLsitzend2223,
  674: RechterKronenfortsatzMLsitzend2224,
  675: LinkerOberarmMitSchulterVdStehend2225,
  676: LinkerOberarmMitSchulterVdStehend2225,
  677: RechterOberarmMitSchulterVdStehend2226,
  678: RechterOberarmMitSchulterVdStehend2226,
  679: LinkerOberarmMitSchulterLatStehend2227,
  680: LinkerOberarmMitSchulterLatStehend2227,
  681: RechterOberarmMitSchulterLatStehend2228,
  682: RechterOberarmMitSchulterLatStehend2228,
  683: LinkerOberarmMitEllenbogenVdSitzend2229,
  684: LinkerOberarmMitEllenbogenVdSitzend2229,
  685: LinkerOberarmMitEllenbogenVdSitzend2229,
  686: RechterOberarmMitEllenbogenLatSitzend2230,
  687: RechterOberarmMitEllenbogenLatSitzend2230,
  688: RechterOberarmMitEllenbogenLatSitzend2230,
  689: RechterOberarmMitEllenbogenVdSitzend2232,
  690: RechterOberarmMitEllenbogenVdSitzend2232,
  691: RechterOberarmMitEllenbogenVdSitzend2232,
  692: LinkerOberarmMitEllenbogenLatSitzend2233,
  693: LinkerOberarmMitEllenbogenLatSitzend2233,
  694: LinkerOberarmMitEllenbogenLatSitzend2233,
  695: LinkerUnterschenkelMitOberemSprunggelenkVdLiegend2301,
  696: RechterUnterschenkelMitOberemSprunggelenkVdLiegend2302,
  697: LinkerUnterschenkelMitOberemSprunggelenkVdStehend2303,
  698: RechterUnterschenkelMitOberemSprunggelenkVdStehend2304,
  699: LinkerUnterschenkelMitOberemSprunggelenkLatLiegend2305,
  700: LinkerUnterschenkelMitOberemSprunggelenkLatLiegend2305,
  701: RechterUnterschenkeMitOberemSprunggelenkLatLiegend2306,
  702: RechterUnterschenkeMitOberemSprunggelenkLatLiegend2306,
  704: LinkerUnterschenkelMitKnieVdLiegend2307,
  705: LinkerUnterschenkelMitKnieVdLiegend2307,
  706: LinkerUnterschenkelMitKnieVdLiegend2307,
  708: RechterUnterschenkelMitKnieVdLiegend2308,
  709: RechterUnterschenkelMitKnieVdLiegend2308,
  710: RechterUnterschenkelMitKnieVdLiegend2308,
  711: LinkerUnterschenkelMitKnieVdStehend2309,
  712: LinkerUnterschenkelMitKnieVdStehend2309,
  713: RechterUnterschenkelMitKnieVdStehend2310,
  714: RechterUnterschenkelMitKnieVdStehend2310,
  715: LinkerUnterschenkelMitKnieLatLiegend2311,
  716: RechterUnterschenkelMitKnieLatLiegend2312,
  719: LinkesKniegelenkVdStehendLinks2315,
  720: LinkesKniegelenkVdStehendLinks2315,
  721: LinkesKniegelenkVdStehendLinks2315,
  722: RechtesKniegelenkVdStehendLinks2316,
  723: RechtesKniegelenkVdStehendLinks2316,
  724: RechtesKniegelenkVdStehendLinks2316,
  725: KniegelenkVdTepLeft2317,
  726: KniegelenkVdTepLeft2317,
  727: RechtesKniegelenkTEPvdLiegendLinks2318,
  728: RechtesKniegelenkTEPvdLiegendLinks2318,
  729: LinkesKniegelenkTEPlatLiegend2319,
  730: LinkesKniegelenkTEPlatLiegend2319,
  731: LinkesKniegelenkTEPlatLiegend2319,
  732: RechtesKniegelenkTEPlatLiegendLinks2320,
  733: RechtesKniegelenkTEPlatLiegendLinks2320,
  734: RechtesKniegelenkTEPlatLiegendLinks2320,
  735: LinkesKniegelenkVdLiegendLinks2321,
  736: LinkesKniegelenkVdLiegendLinks2321,
  737: LinkesKniegelenkVdLiegendLinks2321,
  738: RechtesKniegelenkVdLiegendLinks2322,
  739: RechtesKniegelenkVdLiegendLinks2322,
  740: RechtesKniegelenkVdLiegendLinks2322,
  741: LinkesKniegelenkLatLiegendLinks2323,
  742: LinkesKniegelenkLatLiegendLinks2323,
  743: LinkesKniegelenkLatLiegendLinks2323,
  744: RechtesKniegelenkLatLiegend2324,
  745: RechtesKniegelenkLatLiegend2324,
  746: RechtesKniegelenkLatLiegend2324,
  747: LinkesKniegelenkLatStehendLinks2325,
  748: LinkesKniegelenkLatStehendLinks2325,
  749: LinkesKniegelenkLatStehendLinks2325,
  750: RechtesKniegelenkLatStehend2326,
  751: RechtesKniegelenkLatStehend2326,
  752: RechtesKniegelenkLatStehend2326,
  753: LinkesKniegelenkInnenrotationVdoblLiegend2327,
  754: RechtesKniegelenkInnenrotationVdoblLiegend2328,
  755: LinkesKniegelenkAuSenrotationVdoblLiegend2329,
  756: RechtesKniegelenkAuSenrotationVdoblLiegend2330,
  757: LinkesKniegelenkNachFrikTunnelaufnahmeVdLiegendLinks2331,
  758: LinkesKniegelenkNachFrikTunnelaufnahmeVdLiegendLinks2331,
  759: LinkesKniegelenkNachFrikTunnelaufnahmeVdLiegendLinks2331,
  760: LinkesKniegelenkNachFrikTunnelaufnahmeVdLiegendLinks2331,
  761: LinkesKniegelenkNachFrikTunnelaufnahmeVdLiegendLinks2331,
  762: RechtesKniegelenkNachFrikVdLiegend2332,
  763: RechtesKniegelenkNachFrikVdLiegend2332,
  764: RechtesKniegelenkNachFrikVdLiegend2332,
  765: RechtesKniegelenkNachFrikVdLiegend2332,
  766: RechtesKniegelenkNachFrikVdLiegend2332,
  767: LinkesKniegelenkNachRosenbergDvStehendLinks2333,
  768: LinkesKniegelenkNachRosenbergDvStehendLinks2333,
  769: RechtesKniegelenkNachRosenbergDvStehend2334,
  770: RechtesKniegelenkNachRosenbergDvStehend2334,
  771: LinkeKniescheibeDvLiegend2335,
  772: LinkeKniescheibeDvLiegend2335,
  773: RechteKniescheibeDvLiegend2336,
  774: RechteKniescheibeDvLiegend2336,
  775: LinkeKniescheibeAxLiegend2337,
  776: LinkeKniescheibeAxLiegend2337,
  777: RechteKniescheibeAxLiegend2338,
  778: RechteKniescheibeAxLiegend2338,
  779: LinkePatellaDeFile30axSitzend2339,
  780: LinkePatellaDeFile30axSitzend2339,
  781: RechtePatellaDeFile30axSitzend2340,
  782: RechtePatellaDeFile30axSitzend2340,
  783: LinkeGanzbeinstandaufnahmeVdStehend2341,
  784: LinkeGanzbeinstandaufnahmeVdStehend2341,
  786: RechteGanzbeinstandaufnahmeVdStehend2342,
  787: RechteGanzbeinstandaufnahmeVdStehend2342,
  789: LinkerOberschenkelMitKnieVdLiegend2343,
  790: LinkerOberschenkelMitKnieVdLiegend2343,
  791: LinkerOberschenkelMitKnieVdLiegend2343,
  792: LinkerOberschenkelMitKnieVdLiegend2343,
  793: RechterOberschenkelMitKnieVdLiegend2344,
  794: RechterOberschenkelMitKnieVdLiegend2344,
  795: RechterOberschenkelMitKnieVdLiegend2344,
  796: RechterOberschenkelMitKnieVdLiegend2344,
  797: RechterOberschenkelMitKnieLatLiegend2345,
  798: RechterOberschenkelMitKnieLatLiegend2345,
  799: RechterOberschenkelMitKnieLatLiegend2345,
  800: RechterOberschenkelMitKnieLatLiegend2346,
  801: RechterOberschenkelMitKnieLatLiegend2346,
  802: RechterOberschenkelMitKnieLatLiegend2346,
  803: LinkerOberschenkelMitHufteVdLiegend2347,
  804: LinkerOberschenkelMitHufteVdLiegend2347,
  805: LinkerOberschenkelMitHufteVdLiegend2347,
  806: LinkerOberschenkelMitHufteVdLiegend2347,
  807: LinkerOberschenkelMitHufteVdLiegend2347,
  808: LinkerOberschenkelMitHufteVdLiegend2347,
  809: LinkerOberschenkelMitHufteVdLiegend2347,
  810: RechterOberschenkelMitHufteVdLiegend2348,
  811: RechterOberschenkelMitHufteVdLiegend2348,
  812: RechterOberschenkelMitHufteVdLiegend2348,
  813: RechterOberschenkelMitHufteVdLiegend2348,
  814: RechterOberschenkelMitHufteVdLiegend2348,
  815: RechterOberschenkelMitHufteVdLiegend2348,
  816: RechterOberschenkelMitHufteVdLiegend2348,
  817: LinkerOberschenkelMitHufteVdStehend2349,
  818: LinkerOberschenkelMitHufteVdStehend2349,
  819: LinkerOberschenkelMitHufteVdStehend2349,
  820: LinkerOberschenkelMitHufteVdStehend2349,
  821: LinkerOberschenkelMitHufteVdStehend2349,
  822: LinkerOberschenkelMitHufteVdStehend2349,
  823: LinkerOberschenkelMitHufteVdStehend2349,
  824: RechterOberschenkelMitHufteVdStehend2350,
  825: RechterOberschenkelMitHufteVdStehend2350,
  826: RechterOberschenkelMitHufteVdStehend2350,
  827: RechterOberschenkelMitHufteVdStehend2350,
  828: RechterOberschenkelMitHufteVdStehend2350,
  829: RechterOberschenkelMitHufteVdStehend2350,
  830: RechterOberschenkelMitHufteVdStehend2350,
  831: LinkerOberschenkelMitHufteVdOblLiegend2351,
  832: LinkerOberschenkelMitHufteVdOblLiegend2351,
  833: RechterOberschenkelMitHufteVdOblLiegend2352,
  834: RechterOberschenkelMitHufteVdOblLiegend2352,
  835: LinkerOberschenkelMitHüfteLatLiegend2353,
  836: LinkerOberschenkelMitHüfteLatLiegend2353,
  837: LinkerOberschenkelMitHüfteLatLiegend2353,
  838: LinkerOberschenkelMitHüfteLatLiegend2353,
  839: LinkerOberschenkelMitHüfteLatLiegend2353,
  840: RechterOberschenkelMitHufteLatLiegend2354,
  841: RechterOberschenkelMitHufteLatLiegend2354,
  842: RechterOberschenkelMitHufteLatLiegend2354,
  843: RechterOberschenkelMitHufteLatLiegend2354,
  844: RechterOberschenkelMitHufteLatLiegend2354,
  845: LinkesKniegelenkStressaufnahmeVdLiegend2355,
  846: LinkesKniegelenkStressaufnahmeVdLiegend2355,
  847: LinkesKniegelenkStressaufnahmeVdLiegend2355,
  848: RechtesKniegelenkStressaufnahmeVdLiegend2356,
  849: RechtesKniegelenkStressaufnahmeVdLiegend2356,
  850: RechtesKniegelenkStressaufnahmeVdLiegend2356,
  851: LinkesKniegelenkStressaufnahmeLatLiegend2357,
  852: LinkesKniegelenkStressaufnahmeLatLiegend2357,
  853: RechtesKniegelenkStressaufnahmeLatLiegend2358,
  854: RechtesKniegelenkStressaufnahmeLatLiegend2358,
  855: LinkePatellaDefile60axSitzend2359,
  856: LinkePatellaDefile60axSitzend2359,
  857: RechtePatellaDeFile60axSitzend2360,
  858: RechtePatellaDeFile60axSitzend2360,
  859: LinkePatellaDeFile90axSitzend2361,
  860: LinkePatellaDeFile90axSitzend2361,
  861: RechtePatellaDeFile90axSitzend2362,
  862: RechtePatellaDeFile90axSitzend2362,
  863: BeideKnieVdStehend2363,
  864: BeideKnieVdStehend2363,
  865: BeideKnieVdStehend2363,
  866: LinkeHufteVdLiegend2401,
  867: LinkeHufteVdLiegend2401,
  868: LinkeHufteVdLiegend2401,
  869: LinkeHufteVdLiegend2401,
  870: RechteHufteVdLiegend2402,
  871: RechteHufteVdLiegend2402,
  872: RechteHufteVdLiegend2402,
  873: RechteHufteVdLiegend2402,
  874: HufteUbersichtTEPvdLiegend2403,
  875: HufteUbersichtTEPvdLiegend2403,
  876: LinkeHufteVdOblLiegend2404,
  877: LinkeHufteVdOblLiegend2404,
  878: LinkeHufteVdOblLiegend2404,
  879: LinkeHufteVdOblLiegend2404,
  880: RechteHufteVdOblLiegend2405,
  881: RechteHufteVdOblLiegend2405,
  882: RechteHufteVdOblLiegend2405,
  883: RechteHufteVdOblLiegend2405,
  884: LinkesHuftlochVdoblLiegend2406,
  885: LinkesHuftlochVdoblLiegend2406,
  886: RechtesHuftlochVdoblLiegend2407,
  887: RechtesHuftlochVdoblLiegend2407,
  888: LinkeHufteAbduktionVdOblLiegend2408,
  889: RechteHufteAbduktionVdOblLiegend2409,
  890: LinkeHufteAdduktionVdOblLiegend2410,
  891: RechteHufteAdduktionVdOblLiegend2411,
  892: LinkesHuftgelenkTEPvdLiegend2412,
  893: LinkesHuftgelenkTEPvdLiegend2412,
  894: LinkesHuftgelenkTEPvdLiegend2412,
  895: RechtesHuftgelenkTEPvdLiegend2413,
  896: RechtesHuftgelenkTEPvdLiegend2413,
  897: RechtesHuftgelenkTEPvdLiegend2413,
  898: LinkesHuftgelenkTEPnachLauensteinVdOblLiegend2414,
  899: RechtesHuftgelenkTEPnachLauensteinVdOblLiegend2415,
  900: HufteUbersichtVdStehend2416,
  901: HufteUbersichtVdStehend2416,
  902: HufteUbersichtVdStehend2416,
  903: HufteUbersichtVdStehend2416,
  904: HufteUbersichtVdLiegend2417,
  905: HufteUbersichtVdLiegend2417,
  906: HufteUbersichtVdLiegend2417,
  907: HufteUbersichtVdLiegend2417,
  627: ThoraxBettaufnahmeApLiegend2108,
  628: ThoraxBettaufnahmeApLiegend2108,
  629: ThoraxBettaufnahmeApLiegend2108,
  630: ThoraxBettaufnahmeApLiegend2108,
  641: LinkerUnterarmMitEllenbogenVdSitzend2208,
  642: LinkerUnterarmMitEllenbogenVdSitzend2208,
  639: RechterUnterarmMitEllenbogenVdSitzend2207,
  640: RechterUnterarmMitEllenbogenVdSitzend2207,
  625: ThoraxHerzfernaufnahmeInspirationPaStehend2107,
  626: ThoraxHerzfernaufnahmeInspirationPaStehend2107,
  622: ThoraxExspirationLatStehend2106,
  623: ThoraxExspirationLatStehend2106,
  624: ThoraxExspirationLatStehend2106,
  618: ThoraxInspirationLatStehend2105,
  619: ThoraxInspirationLatStehend2105,
  620: ThoraxInspirationLatStehend2105,
  621: ThoraxInspirationLatStehend2105,
  614: ThoraxExspirationPaStehend2104,
  615: ThoraxExspirationPaStehend2104,
  616: ThoraxExspirationPaStehend2104,
  617: ThoraxExspirationPaStehend2104,
  610: ThoraxInspirationPaStehend2103,
  611: ThoraxInspirationPaStehend2103,
  612: ThoraxInspirationPaStehend2103,
  613: ThoraxInspirationPaStehend2103,
  606: ThoraxMitRasterExspirationApLiegend2102,
  607: ThoraxMitRasterExspirationApLiegend2102,
  608: ThoraxMitRasterExspirationApLiegend2102,
  609: ThoraxMitRasterExspirationApLiegend2102,
  601: ThoraxMitRasterInspirationApLiegend2101,
  602: ThoraxMitRasterInspirationApLiegend2101,
  603: ThoraxMitRasterInspirationApLiegend2101,
  604: ThoraxMitRasterInspirationApLiegend2101,
  605: ThoraxMitRasterInspirationApLiegend2101,
  600: RechtesSchulterblattVdLiegend2035,
  598: RechtesSternoclaviculargelenkDvOblLiegend2033,
  597: LinkesSternoclaviculargelenkDvOblLiegend2032,
  596: RechtesSternoclaviculargelenkDvOblStehend2031,
  593: BrustbeinLatStehend2029,
  594: BrustbeinLatStehend2029,
  591: BrustbeinDvOblLiegend2028,
  589: BrustbeinDvOblStehend2027,
  588: RechtesSchulterblattLatStehend2026,
  587: LinkesSchulterblattLatStehend2025,
  586: RechtesSchulterblattVdStehend2024,
  584: RechtesAcromioklavikulargelenkMitBelastungDvStehend2022,
  583: LinkesAcromioklavikulargelenkMitBelastungDvStehend2021,
  582: RechtesAcromioklavikulargelenkDvStehend2020,
  581: LinkesAcromioklavikulargelenkDvStehend2019,
  580: RechtesSchlusselbeinTangStehend2018,
  579: LinkesSchlusselbeinTangStehend2017,
  577: RechtesSchlusselbeinDvStehend2016,
  578: RechtesSchlusselbeinDvStehend2016,
  575: LinkesSchlusselbeinDvStehend2015,
  576: LinkesSchlusselbeinDvStehend2015,
  574: RechtesSchulterOutletViewDvOblStehend2014,
  573: LinkesSchulterOutletViewDvOblStehend2013,
  571: RechtesSchultergelenkYAufnahmeDvOblStehend2012,
  572: RechtesSchultergelenkYAufnahmeDvOblStehend2012,
  569: LinkesSchultergelenkYAufnahmeDvOblStehend2011,
  570: LinkesSchultergelenkYAufnahmeDvOblStehend2011,
  566: RechtesSchultergelenkSchwedenstatusElevationVdStehend2010,
  567: RechtesSchultergelenkSchwedenstatusElevationVdStehend2010,
  568: RechtesSchultergelenkSchwedenstatusElevationVdStehend2010,
  563: LinkesSchultergelenkSchwedenstatusElevationМdStehend2009,
  564: LinkesSchultergelenkSchwedenstatusElevationМdStehend2009,
  565: LinkesSchultergelenkSchwedenstatusElevationМdStehend2009,
  559: RechtesSchultergelenkSchwedenstatusAusenrotationVdStehend2008,
  560: RechtesSchultergelenkSchwedenstatusAusenrotationVdStehend2008,
  561: RechtesSchultergelenkSchwedenstatusAusenrotationVdStehend2008,
  562: RechtesSchultergelenkSchwedenstatusAusenrotationVdStehend2008,
  555: LinkesSchultergelenkSchwedenstatusAusenrotationVdStehend2007,
  556: LinkesSchultergelenkSchwedenstatusAusenrotationVdStehend2007,
  557: LinkesSchultergelenkSchwedenstatusAusenrotationVdStehend2007,
  558: LinkesSchultergelenkSchwedenstatusAusenrotationVdStehend2007,
  551: RechtesSchultergelenkSchwedenstatusInnenrotationVdStehend2006,
  552: RechtesSchultergelenkSchwedenstatusInnenrotationVdStehend2006,
  553: RechtesSchultergelenkSchwedenstatusInnenrotationVdStehend2006,
  554: RechtesSchultergelenkSchwedenstatusInnenrotationVdStehend2006,
  547: LinkesSchultergelenkSchwedenstatusInnenrotationVdStehend2005,
  548: LinkesSchultergelenkSchwedenstatusInnenrotationVdStehend2005,
  549: LinkesSchultergelenkSchwedenstatusInnenrotationVdStehend2005,
  550: LinkesSchultergelenkSchwedenstatusInnenrotationVdStehend2005,
  545: RechtesSchultergelenkLatStehend2004,
  546: RechtesSchultergelenkLatStehend2004,
  543: LinkesSchultergelenkLatStehend2003,
  544: LinkesSchultergelenkLatStehend2003,
  542: RechtesSchultergelenkМdІtehend2002,
  541: LinkesSchultergelenkVdStehend2001,
  537: SchadelApSitzend1943,
  538: SchadelApSitzend1943,
  539: SchadelApSitzend1943,
  540: SchadelApSitzend1943,
  910: NasennebenhohlenOnStehend1914,
  911: NasennebenhohlenOnStehend1914,
  912: NasennebenhohlenOnStehend1914,
  536: NasenbeinLatSitzend1913,
  533: GesichtsschadelLatSitzend1912,
  534: GesichtsschadelLatSitzend1912,
  535: GesichtsschadelLatSitzend1912,
  529: SchadelApLiegend1904,
  530: SchadelApLiegend1904,
  531: SchadelApLiegend1904,
  532: SchadelApLiegend1904,
  522: RechteRippen812VdLiegend1816,
  523: RechteRippen812VdLiegend1816,
  520: LinkeRippen812VdLiegend1815,
  521: LinkeRippen812VdLiegend1815,
  518: RechteRippen17VdLiegend1814,
  519: RechteRippen17VdLiegend1814,
  516: LinkeRippen17VdLiegend1813,
  517: LinkeRippen17VdLiegend1813,
  514: RechteRippenDvOblStehend1812,
  515: RechteRippenDvOblStehend1812,
  512: LinkeRippenDvOblStehend1811,
  513: LinkeRippenDvOblStehend1811,
  510: RechteRippenVdStehend1810,
  511: RechteRippenVdStehend1810,
  508: LinkeRippenVdStehend1809,
  509: LinkeRippenVdStehend1809,
  506: RechteRippenDvStehend1808,
  507: RechteRippenDvStehend1808,
  504: LinkeRippenDvStehend1807,
  505: LinkeRippenDvStehend1807,
  502: RechteRippenDvOblLiegend1806,
  503: RechteRippenDvOblLiegend1806,
  500: LinkeRippenDvOblLiegend1805,
  501: LinkeRippenDvOblLiegend1805,
  912:SchadelPaSitzend1902,
};

export const examQaMap = {
  1101:QaApiResponse1101,
  1102:QaApiResponse1102,
  1103:QaApiResponse1103,
  1104:QaApiResponse1104,
  1201:QaApiResponse1201,
  1203:QaApiResponse1203,
  1204:QaApiResponse1204,
  1205:QaApiResponse1205,
  1206:QaApiResponse1206,
  1207:QaApiResponse1207,
  1208:QaApiResponse1208,
  1209:QaApiResponse1209,
  1301:QaApiResponse1301,
  1302:QaApiResponse1302,
  1303:QaApiResponse1303,
  1304:QaApiResponse1304,
  1305:QaApiResponse1305,
  1306:QaApiResponse1306,
  1307:QaApiResponse1307,
  1308:QaApiResponse1308,
  1309:QaApiResponse1309,
  1310:QaApiResponse1310,
  1311:QaApiResponse1311,
  1312:QaApiResponse1312,
  1313:QaApiResponse1313,
  1314:QaApiResponse1314,
  1315:QaApiResponse1315,
  1316:QaApiResponse1316,
  1317:QaApiResponse1317,
  1318:QaApiResponse1318,
  1319:QaApiResponse1319,
  1320:QaApiResponse1320,
  1321:QaApiResponse1321,
  1322:QaApiResponse1322,
  1323:QaApiResponse1323,
  1324:QaApiResponse1324,
  1325:QaApiResponse1325,
  1326:QaApiResponse1326,
  1327:QaApiResponse1327,
  1328:QaApiResponse1328,
  1329:QaApiResponse1329,
  1330:QaApiResponse1330,
  1331: QaApiResponse1331,
  1332:QaApiResponse1332,
  1333:QaApiResponse1333,
  1334:QaApiResponse1334,
  1335:QaApiResponse1335,
  1336:QaApiResponse1336,
  1337:QaApiResponse1337,
  1338:QaApiResponse1338,
  1339:QaApiResponse1339,
  1340:QaApiResponse1340,
  1341:QaApiResponse1341,
  1342:QaApiResponse1342,
  1343:QaApiResponse1343,
  1344:QaApiResponse1344,
  1345:QaApiResponse1345,
  1346:QaApiResponse1346,
  1347:QaApiResponse1347,
  1348:QaApiResponse1348,
  1349:QaApiResponse1349,
  1350:QaApiResponse1350,
  1351:QaApiResponse1351,
  1352:QaApiResponse1352,
  1353:QaApiResponse1353,
  1354:QaApiResponse1354,
  1355:QaApiResponse1355,
  1356:QaApiResponse1356,
  1357:QaApiResponse1357,
  1358:QaApiResponse1358,
  1359:QaApiResponse1359,
  1360:QaApiResponse1360,
  1361:QaApiResponse1361,
  1362:QaApiResponse1362,
  1363:QaApiResponse1363,
  1364:QaApiResponse1364,
  1365:QaApiResponse1365,
  1366:QaApiResponse1366,
  1369:QaApiResponse1369,
  1370:QaApiResponse1370,
  1371:QaApiResponse1371,
  1372:QaApiResponse1372,
  1373:QaApiResponse1373,
  1374:QaApiResponse1374,
  1375:QaApiResponse1375,
  1376:QaApiResponse1376,
  1377:QaApiResponse1377,
  1378:QaApiResponse1378,
  1401: QaApiResponse1401,
  1402: QaApiResponse1402,
  1403: QaApiResponse1403,
  1404: QaApiResponse1404,
  1405:QaApiResponse1405,
  1406:QaApiResponse1406,
  1407: QaApiResponse1407,
  1408: QaApiResponse1408,
  1409: QaApiResponse1409,
  1410: QaApiResponse1410,
  1411: QaApiResponse1411,
  1412: QaApiResponse1412,
  1413: QaApiResponse1413,
  1414:QaApiResponse1414,
  1415: QaApiResponse1415,
  1416: QaApiResponse1416,
  1417: QaApiResponse1417,
  1418: QaApiResponse1418,
  1419: QaApiResponse1419,
  1420:QaApiResponse1420,
  1421:QaApiResponse1421,
  1422:QaApiResponse1422,
  1423:QaApiResponse1423,
  1424:QaApiResponse1424,
  1425: QaApiResponse1425,
  1426:QaApiResponse1426,
  1427:QaApiResponse1427,
  1428:QaApiResponse1428,
  1429:QaApiResponse1429,
  1430:QaApiResponse1430,
  1431:QaApiResponse1431,
  1432:QaApiResponse1432,
  1433:QaApiResponse1433,
  1434:QaApiResponse1434,
  1435:QaApiResponse1435,
  1436:QaApiResponse1436,
  1437:QaApiResponse1437,
  1438:QaApiResponse1438,
  1439:QaApiResponse1439,
  1440:QaApiResponse1440,
  1441:QaApiResponse1441,
  1442:QaApiResponse1442,
  1443:QaApiResponse1443,
  1444:QaApiResponse1444,
  1445:QaApiResponse1445,
  1446:QaApiResponse1446,
  1447:QaApiResponse1447,
  1448:QaApiResponse1448,
  1449:QaApiResponse1449,
  1450:QaApiResponse1450,
  1451:QaApiResponse1451,
  1452:QaApiResponse1452,
  1453:QaApiResponse1453,
  1454:QaApiResponse1454,
  1455:QaApiResponse1455,
  1456:QaApiResponse1456,
  1457:QaApiResponse1457,
  1458:QaApiResponse1458,
  1459: QaApiResponse1459,
  1460: QaApiResponse1460,
  1463: QaApiResponse1463,
  1464: QaApiResponse1464,
  1465: QaApiResponse1465,
  1466: QaApiResponse1466,
  1467: QaApiResponse1467,
  1468: QaApiResponse1468,
  1469: QaApiResponse1469,
  1470: QaApiResponse1470,
  1471: QaApiResponse1471,
  1472: QaApiResponse1472,
  1473: QaApiResponse1473,
  1474: QaApiResponse1474,
  1501: QaApiResponse1501,
  1502: QaApiResponse1502,
  1503: QaApiResponse1503,
  1504: QaApiResponse1504,
  1505: QaApiResponse1505,
  1506: QaApiResponse1506,
  1507: QaApiResponse1507,
  1508: QaApiResponse1508,
  1509: QaApiResponse1509,
  1510: QaApiResponse1510,
  1511: QaApiResponse1511,
  1512: QaApiResponse1512,
  1513: QaApiResponse1513,
  1514: QaApiResponse1514,
  1515: QaApiResponse1515,
  1516: QaApiResponse1516,
  1517: QaApiResponse1517,
  1518: QaApiResponse1518,
  1519: QaApiResponse1519,
  1520: QaApiResponse1520,
  1521: QaApiResponse1521,
  1522: QaApiResponse1522,
  1523: QaApiResponse1523,
  1524: QaApiResponse1524,
  1525: QaApiResponse1525,
  1526: QaApiResponse1526,
  1527: QaApiResponse1527,
  1528: QaApiResponse1528,
  1529: QaApiResponse1529,
  1530: QaApiResponse1530,
  1531: QaApiResponse1531,
  1532: QaApiResponse1532,
  1533: QaApiResponse1533,
  1534: QaApiResponse1534,
  1535: QaApiResponse1535,
  1536: QaApiResponse1536,
  1601: QaApiResponse1601,
  1602: QaApiResponse1602,
  1603: QaApiResponse1603,
  1604: QaApiResponse1604,
  1606: QaApiResponse1606,
  1607: QaApiResponse1607,
  1608: QaApiResponse1608,
  1609: QaApiResponse1609,
  1610: QaApiResponse1610,
  1611: QaApiResponse1611,
  1612: QaApiResponse1612,
  1613: QaApiResponse1613,
  1614: QaApiResponse1614,
  1616: QaApiResponse1616,
  1701: QaApiResponse1701,
  1702: QaApiResponse1702,
  1703: QaApiResponse1703,
  1704: QaApiResponse1704,
  1705: QaApiResponse1705,
  1706: QaApiResponse1706,
  1707: QaApiResponse1707,
  1708: QaApiResponse1708,
  1709: QaApiResponse1709,
  1710: QaApiResponse1710,
  1711: QaApiResponse1711,
  1712: QaApiResponse1712,
  1713: QaApiResponse1713,
  1714: QaApiResponse1714,
  1715: QaApiResponse1715,
  1716: QaApiResponse1716,
  1717: QaApiResponse1717,
  1718: QaApiResponse1718,
  1719: QaApiResponse1719,
  1720: QaApiResponse1720,
  1721: QaApiResponse1721,
  1722: QaApiResponse1722,
  1723: QaApiResponse1723,
  1724: QaApiResponse1724,
  1725: QaApiResponse1725,
  1726: QaApiResponse1726,
  1727: QaApiResponse1727,
  1728: QaApiResponse1728,
  1801: QaApiResponse1801,
  1802: QaApiResponse1802,
  1803: QaApiResponse1803,
  1804: QaApiResponse1804,
  2034: QaApiResponse2034,
  1902: QaApiResponse1902,
  1903: QaApiResponse1903,
  2023: QaApiResponse2023,
  2030: QaApiResponse2030,
  2201: QaApiResponse2201,
  2202: QaApiResponse2202,
  2203: QaApiResponse2203,
  2204: QaApiResponse2204,
  2205: QaApiResponse2205,
  2206: QaApiResponse2206,
  2209: QaApiResponse2209,
  2210: QaApiResponse2210,
  2211: QaApiResponse2211,
  2212: QaApiResponse2212,
  2213: QaApiResponse2213,
  2214: QaApiResponse2214,
  2215: QaApiResponse2215,
  2216: QaApiResponse2216,
  2217: QaApiResponse2217,
  2218: QaApiResponse2218,
  2219: QaApiResponse2219,
  2220: QaApiResponse2220,
  2221: QaApiResponse2221,
  2222: QaApiResponse2222,
  2223: QaApiResponse2223,
  2224: QaApiResponse2224,
  2225: QaApiResponse2225,
  2226: QaApiResponse2226,
  2227: QaApiResponse2227,
  2228: QaApiResponse2228,
  2229: QaApiResponse2229,
  2230: QaApiResponse2230,
  2232: QaApiResponse2232,
  2233: QaApiResponse2233,
  2301: QaApiResponse2301,
  2302: QaApiResponse2302,
  2303: QaApiResponse2303,
  2304: QaApiResponse2304,
  2305: QaApiResponse2305,
  2306: QaApiResponse2306,
  2307: QaApiResponse2307,
  2308: QaApiResponse2308,
  2309: QaApiResponse2309,
  2310: QaApiResponse2310,
  2311: QaApiResponse2311,
  2312: QaApiResponse2312,
  2315: QaApiResponse2315,
  2316: QaApiResponse2316,
  2317: QaApiResponse2317,
  2318: QaApiResponse2318,
  2319: QaApiResponse2319,
  2320: QaApiResponse2320,
  2321: QaApiResponse2321,
  2322: QaApiResponse2322,
  2323: QaApiResponse2323,
  2324: QaApiResponse2324,
  2325: QaApiResponse2325,
  2326: QaApiResponse2326,
  2327: QaApiResponse2327,
  2328: QaApiResponse2328,
  2329: QaApiResponse2329,
  2330: QaApiResponse2330,
  2331: QaApiResponse2331,
  2332: QaApiResponse2332,
  2333: QaApiResponse2333,
  2334: QaApiResponse2334,
  2335: QaApiResponse2335,
  2336: QaApiResponse2336,
  2337: QaApiResponse2337,
  2338: QaApiResponse2338,
  2339: QaApiResponse2339,
  2340: QaApiResponse2340,
  2341: QaApiResponse2341,
  2342: QaApiResponse2342,
  2343: QaApiResponse2343,
  2344: QaApiResponse2344,
  2345: QaApiResponse2345,
  2346: QaApiResponse2346,
  2347: QaApiResponse2347,
  2348: QaApiResponse2348,
  2349: QaApiResponse2349,
  2350: QaApiResponse2350,
  2351: QaApiResponse2351,
  2352: QaApiResponse2352,
  2353: QaApiResponse2353,
  2355: QaApiResponse2355,
  2356: QaApiResponse2356,
  2357: QaApiResponse2357,
  2358: QaApiResponse2358,
  2359: QaApiResponse2359,
  2360: QaApiResponse2360,
  2361: QaApiResponse2361,
  2362: QaApiResponse2362,
  2363: QaApiResponse2363,
  2401: QaApiResponse2401,
  2402: QaApiResponse2402,
  2403: QaApiResponse2403,
  2404: QaApiResponse2404,
  2405: QaApiResponse2405,
  2406: QaApiResponse2406,
  2407: QaApiResponse2407,
  2408: QaApiResponse2408,
  2409: QaApiResponse2409,
  2354: QaApiResponse2354,
  2410: QaApiResponse2410,
  2411: QaApiResponse2411,
  2412: QaApiResponse2412,
  2413: QaApiResponse2413,
  2414: QaApiResponse2414,
  2415: QaApiResponse2415,
  2416: QaApiResponse2416,
  2417: QaApiResponse2417,
  2108: QaApiResponse2108,
  2208: QaApiResponse2208,
  2207: QaApiResponse2207,
  2107: QaApiResponse2107,
  2106: QaApiResponse2106,
  2105: QaApiResponse2105,
  2104: QaApiResponse2104,
  2103: QaApiResponse2103,
  2102: QaApiResponse2102,
  2101: QaApiResponse2101,
  2035: QaApiResponse2035,
  2033: QaApiResponse2033,
  2032: QaApiResponse2032,
  2031: QaApiResponse2031,
  2029: QaApiResponse2029,
  2028: QaApiResponse2028,
  2027: QaApiResponse2027,
  2026: QaApiResponse2026,
  2025: QaApiResponse2025,
  2024: QaApiResponse2024,
  2022: QaApiResponse2022,
  2021: QaApiResponse2021,
  2020: QaApiResponse2020,
  2019: QaApiResponse2019,
  2018: QaApiResponse2018,
  2017: QaApiResponse2017,
  2016: QaApiResponse2016,
  2015: QaApiResponse2015,
  2014: QaApiResponse2014,
  2013: QaApiResponse2013,
  2012: QaApiResponse2012,
  2011: QaApiResponse2011,
  2010: QaApiResponse2010,
  2009: QaApiResponse2009,
  2008: QaApiResponse2008,
  2007: QaApiResponse2007,
  2006: QaApiResponse2006,
  2005: QaApiResponse2005,
  2004: QaApiResponse2004,
  2003: QaApiResponse2003,
  2002: QaApiResponse2002,
  2001: QaApiResponse2001,
  1943: QaApiResponse1943,
  1914: QaApiResponse1914,
  1913: QaApiResponse1913,
  1912: QaApiResponse1912,
  1904: QaApiResponse1904,
  1816: QaApiResponse1816,
  1815: QaApiResponse1815,
  1814: QaApiResponse1814,
  1813: QaApiResponse1813,
  1812: QaApiResponse1812,
  1811: QaApiResponse1811,
  1810: QaApiResponse1810,
  1809: QaApiResponse1809,
  1808: QaApiResponse1808,
  1807: QaApiResponse1807,
  1806: QaApiResponse1806,
  1805: QaApiResponse1805,
};
