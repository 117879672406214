import {
  SELECT_EXAM,
  UNSELECT_EXAM,
  SELECT_EXAM_SERIE,
  UNSELECT_EXAM_SERIE,
  SELECT_EXAM_SERIE_IMAGE,
  UNSELECT_EXAM_SERIE_IMAGE,
  CLEAR_SELECTED_EXAMINATIONS,
} from "../actionTypes";

const initialState = {
  selectedExaminations: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SELECT_EXAM: {
      let copyList = state.selectedExaminations.slice();
      const { content } = action.payload;
      copyList.push(content);
      copyList = [...new Set(copyList)];
      return {
        ...state,
        selectedExaminations: copyList,
      };
    }
    case UNSELECT_EXAM: {
      let copyList = state.selectedExaminations.slice();
      const { content } = action.payload;
      let idx = copyList.findIndex((exam) => exam.id === content);
      copyList.splice(idx, 1);
      return {
        ...state,
        selectedExaminations: copyList,
      };
    }
    case SELECT_EXAM_SERIE: {
      let copyList = state.selectedExaminations.slice();
      const { content } = action.payload;
      let idx = copyList.findIndex((exam) => exam.id === content.id);
      if (idx > -1) {
        copyList[idx].series.push(...content.series);
      } else {
        copyList.push(content);
      }
      copyList = [...new Set(copyList)];
      return {
        ...state,
        selectedExaminations: copyList,
      };
    }
    case UNSELECT_EXAM_SERIE: {
      let copyList = state.selectedExaminations.slice();
      const { content } = action.payload;
      let idx = copyList.findIndex((exam) => exam.id === content.exam_id);
      let serIdx = copyList[idx].series.findIndex(
        (serie) => serie.id === content.serie_id
      );
      copyList[idx].series.splice(serIdx, 1);
      if (copyList[idx].series.length === 0) {
        copyList.splice(idx, 1);
      }
      return {
        ...state,
        selectedExaminations: copyList,
      };
    }
    case SELECT_EXAM_SERIE_IMAGE: {
      let copyList = state.selectedExaminations.slice();
      const { content } = action.payload;
      let idx = copyList.findIndex((exam) => exam.id === content.id);
      if (idx > -1) {
        let serIdx = copyList[idx].series.findIndex(
          (serie) => serie.id === content.series[0].id
        );
        if (serIdx > -1) {
          copyList[idx].series[serIdx].images.push(...content.series[0].images);
        } else {
          copyList[idx].series.push(...content.series);
        }
      } else {
        copyList.push(content);
      }
      copyList = [...new Set(copyList)];
      return {
        ...state,
        selectedExaminations: copyList,
      };
    }
    case UNSELECT_EXAM_SERIE_IMAGE: {
      let copyList = state.selectedExaminations.slice();
      const { content } = action.payload;
      let idx = copyList.findIndex((exam) => exam.id === content.exam_id);
      let serIdx = copyList[idx].series.findIndex(
        (serie) => serie.id === content.serie_id
      );
      let imgIdx = copyList[idx].series[serIdx].images.indexOf(
        content.image_id
      );
      copyList[idx].series[serIdx].images.splice(imgIdx, 1);
      if (copyList[idx].series[serIdx].images.length === 0) {
        copyList[idx].series.splice(serIdx, 1);
      }
      if (copyList[idx].series.length === 0) {
        copyList.splice(idx, 1);
      }
      return {
        ...state,
        selectedExaminations: copyList,
      };
    }
    case CLEAR_SELECTED_EXAMINATIONS: {
      return {
        selectedExaminations: [],
      };
    }

    default:
      return state;
  }
}
