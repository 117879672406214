import style from "./style.scss";

function LinkeMittelhandLatSitzend1335(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 228.7 498.9">

<g id="fullhand">
  <path id="fingerpart12" class="mittelhand_links_lat1335_cls5" d="M228.2,126c-19.2,33.9-8.8,30.9-12.2,56.2h0c-2.3,12.3-6.4,21.5-17.4,20.1-8.1,1.9-8.6-2.5-8.6-7.3l-21.4-20.2c-9.4-9.4-1.1-15.6,10.5-21.3,3.4-10.9,12.9-28.6,24.8-49"/>
  <path id="fingerpart11" class="mittelhand_links_lat1335_cls5" d="M131.9.5c-5.2,16.9-9.4,32.5-12.8,46.7,6.6,6.5,7,12.5-2,17.9h0c0,0-6.2,0-6.2,0l-11.1-7.8c-.6,3.6-8.5,4.5-14.8,6.1l-2.6-5.8c4.1-14.4,9.8-29.8,17.3-46.2"/>
  <path id="fingerpart10" class="mittelhand_links_lat1335_cls5" d="M71.9,27.2c-3.3,12.8-8.3,25.9-16.2,38-1.9,6.1-2.6,11.6-.2,16.1,4.6-1,7.9-2.9,9-6.6,3.4,2.9,6.9,4.2,10.3,1.9-1.1-6.1,4.3-9.8,10-13.3l-2.6-5.8c3.4-11.5,8-23.8,13.4-36.6"/>
  <path id="fingerpart9" class="mittelhand_links_lat1335_cls5" d="M70.4,32.8c-7.2,17.4-12.4,32.1-14.7,32.5-1.9,5.3-2.4,10.6-.2,16.1-8.2,8.2-12.6,15.6-10.1,21.8-2.2.3-4,.6-5.4.8.2-8.6,1.5-16.1,3.9-22.7,10.4-20.1,15.1-33,18.9-41.6"/>
  <g id="forearm">
    <path id="forearmpart2" class="mittelhand_links_lat1335_cls5" d="M27.6,412.2l-3.7-3.7c-6-11.5-17-12.4-20.9,5,5.3,25.1,7.5,48.5,2.2,68.1l-4.7,16.8"/>
    <path id="forearmpart1" class="mittelhand_links_lat1335_cls5" d="M72.7,488.3c2.1-6.8,5.7-13.3,10.9-19.7,10.2-8.1,10.4-15.8,9.5-23.5,3.5-11.9,1.3-21.6-4.9-29.7-9.9,1.8-14,5.6-17.2,8.6-15.6,8.1-31,.8-43.4-11.9-13.3-4.3-15.6,3.7-19.2,10.2l4.6,27c-2.6,27.1-5.3,37.6-8.1,48.4"/>
  </g>
  <g id="middlehandbones">
    <path id="middlehandbones4" class="mittelhand_links_lat1335_cls5" d="M93.1,310.2c-5.1-.9-9.1-.6-12.5.3-3.3-.7-6.8-2-11.1-10-4.8,4.4-9.2,7-13,5.7-10.1-14.2-2.6-28,7.2-41.5l4.5-154.8c-3-11.6-2-22.8,6.6-33-1.5-4.8,1.8-9.2,10-13.3l14.8-4.3c2.8-.5,6.8,2,11.2,5.9,3.4,1,6.8,2.1,11.2,2.3,2.4,7.5,4,16.6,4.6,27.3-.6,6.9-8.7,15.6-17.3,24.4-13.5,13.7-26.3,109.8-14.8,152.9,5.2,8.6,9.6,17,3.8,22.8l-4.6,4.5c-1.6,4.1-1.8,7.7-.5,10.7h0c0,.1,0,.1,0,.1Z"/>
    <path id="middlehandbones3" class="mittelhand_links_lat1335_cls5" d="M63.8,264.6c-4.5-40.3-1.4-94.9,4.5-154.8-3.7-10.8-2-21.8,6.6-33-3.3,1.1-6.8.4-10.3-1.9-.5,4.7-4.8,5.6-9,6.6-8.6,9-12.4,16.5-10.1,21.8-.4,5.3,1.1,10.9,3.8,16.6l-.6,13.8c2.5,15.2-12.1,82.1-4.6,123.9l-11.5,28.1c-2.6,7-4.6,13.7-3.3,18.7,3.5-.7,6.5-2.4,8.1-5.7,3.7-1.2,13.5,7.3,16.9,7.3-6.1-13.9-.4-27.7,9.4-41.5h0s0,0,0,0h0Z"/>
    <path id="middlehandbones2" class="mittelhand_links_lat1335_cls5" d="M31.2,131.7c-1.5-16.9,2.6-24.3,8.9-27.7l5.4-.8c-.5,3.3.6,8.7,3.8,16.6-.3,39.4-12.4,99-5.2,137.8l-11.5,28.1c-3.4,8-5.3,15-3.3,18.7-3.8,0-7.1,1.3-9,5.3-1.3-5.4,1-10.7,5.6-16.1,4.4-.2,3.9-3.8.5-9.4,1.4-4.4,9.5-14.9,6.5-19.6l-1.5-132.9h-.2Z"/>
    <path id="middlehandbones5" class="mittelhand_links_lat1335_cls5" d="M154,294.4l-.4,12.7c7.6,17.8.4,28.5-7.5,28.4-8.9,4.8-21.1,3.1-35.2-2.2-5.1-6.3-6.9-13.6-6.8-21.3.9-10.9,2.8-19.9,6.3-26.1,5.8-2.8,10.1-7.1,13.7-12,5.2-13.4,11.8-30.6,20-53.5,7.6-11,9.9-19.6,9.7-27.1.5-10.5,6.1-15.8,14.6-18.4,6.2,6.5,12.7,12.6,19.5,18.6,3.4,3-.4,11.2,10.6,8.9l8.1,3.7c1.5,9.3-4.2,23.6-19.1,25.2-8.7,13.4-20.8,37.3-33.6,63.1h0Z"/>
    <path id="middlehandbones1" class="mittelhand_links_lat1335_cls5" d="M31.2,131.6l1.5,132.9c2.6,6.7-4.8,13.4-6.5,19.6,3.5,5.6,4.3,9.5-.5,9.4l-4.4-8.1c-6.3-3.8-.9-15.5,4.5-27,.7-41.4-2.3-77.5.1-104-6.1-17.7-2.1-24.8,4.8-28.6l.4,5.8h.1Z"/>
  </g>
  <g id="fingerpart3">
    <path id="fingerpart8" class="mittelhand_links_lat1335_cls5" d="M113,293.1c-6.7,12.2-8.8,23.2-4.4,32.4,9.5,7.9,19.2,13.2,29.4,13.1,1.7,5.1.6,11.8-1.1,18.8-10.5,11.9-20.6,18-27.7,18.2-19.7-1.6-24.9-13-22.7-27.6,8.3-7,9.1-19.3,7.5-33.2-1-5.5-1.5-10.8-.2-15.5,8.4-8.7,14.3-8.9,19.3-6.3h-.1Z"/>
    <path id="fingerpart7" class="mittelhand_links_lat1335_cls5" d="M85.7,355.5c1.4,8.1,6.1,15,18.3,19.3,2.5,2.4,2.2,5.4.8,8.7-4.3,3.3-9.6,6.1-15.6,8.5,1.9,5.6,1.2,12-7.4,21-5.8,4.4-12,7.5-19.3,7.6-25.9,2.9-42.7-20.5-39.3-27.3,15.5-3.7,23.9-15.2,28.6-31,7.3-8.2,18.5-10.5,33.9-6.7h0Z"/>
    <path id="fingerpart6" class="mittelhand_links_lat1335_cls5" d="M69.5,300.4c4,5.2,7.7,9.2,11.1,10,3.3-.4,6.4-.9,12.5-.3l.8,4.7c.6,12.6.6,25-7.5,33.2l-.8,7.6-27.2-3.9c-16.3-8.2-11.5-25.7-2-45.7,4.9,1.8,9-1.5,13-5.7h.1Z"/>
    <path id="fingerpart5" class="mittelhand_links_lat1335_cls5" d="M51.6,362.3c.8-3.4,6-6.6,16.2-9.6l-9.6-1c-21.1-5.9-6-40.5-2.8-40.1l-1.2-5.6-16.9-7.3c-1.9,3.4-4.3,5.5-7.5,5.7-3.9-.3-7.1,1.4-9.7,5.3,3.9,3.5,7.5,7.6,10.2,12.7-1.9,5.2-3.5,10.7-3.5,16.7-1.6,4.9-2.1,10-2.4,15.2l-9.3,9.5c-1.8,10.9-1.3,21,5.1,29.1,15.4.1,25.2-11.5,31.7-30.5h-.3Z"/>
    <path id="fingerpart4" class="mittelhand_links_lat1335_cls5" d="M20.2,309.6c4.9,3.5,8.6,7.6,10.2,12.7-3.4,13.4-6.7,26.5-6,31.9l-4.1,3.5c-5.4-4.2-6.1-13.2.8-30-2.3-2.7-1.4-10.8-.9-18.2h0Z"/>
  </g>
  <path id="fingerpart1" class="mittelhand_links_lat1335_cls3" d="M30.9,125.9c1-10,2.7-19.2,9.2-21.8l1.1-13.4c-9.5,12-16.7,26.3-10.2,35.2h0Z"/>
</g>
<g id="middlehandboneshighlights" class="mittelhand_links_lat1335_cls1">
  <path id="middlehandboneshighlight5" class="MHK1335Fill"  style={{
            fill: props.colors.mhk1335fill,
            stroke: props.colors.mhk1335fill,
          }} d="M121.6,67.5c-4.4-.2-7.8-1.3-11.2-2.3-4.4-3.9-8.4-6.4-11.2-5.9l-14.8,4.3c-8.2,4.1-11.5,8.5-10,13.3-8.6,10.2-9.6,21.4-6.6,33l-4.5,154.8c-9.8,13.5-17.3,27.3-7.2,41.5,3.8,1.3,8.2-1.3,13-5.7,4.3,8,7.8,9.3,11.1,10,3-.8,6.5-1.1,10.8-.6.2-.9,1.6-.3,2.1-1.1-.1-.9-1.2-3.3-1-4.2.1-1.6.6-3.4,1.3-5.3l4.6-4.5c5.8-5.8,1.4-14.2-3.8-22.8-11.5-43.1,1.3-139.2,14.8-152.9,8.6-8.8,16.7-17.5,17.3-24.4-.6-10.7-2.2-19.8-4.6-27.3Z"/>
  <path id="middlehandboneshighlight4" class="MHK1335Fill"  style={{
            fill: props.colors.mhk1335fill,
            stroke: props.colors.mhk1335fill,
          }} d="M63.4,264.6c-4.5-40.3-1.4-94.9,4.5-154.8-3.7-10.8-2-21.8,6.6-33-3.3,1.1-6.8.4-10.3-1.9-.5,4.7-4.8,5.6-9,6.6-8.6,9-12.4,16.5-10.1,21.8-.4,5.3,1.1,10.9,3.8,16.6l-.6,13.8c2.5,15.2-12.1,82.1-4.6,123.9l-11.5,28.1c-2.6,7-4.6,13.7-3.3,18.7,3.5-.7,6.5-2.4,8.1-5.7,3.7-1.2,13.5,7.3,16.9,7.3-6.1-13.9-.4-27.7,9.4-41.5h0Z"/>
  <path id="middlehandboneshighlight3" class="MHK1335Fill"  style={{
            fill: props.colors.mhk1335fill,
            stroke: props.colors.mhk1335fill,
          }} d="M30.9,131.7c-1.5-16.9,2.6-24.3,8.9-27.7l5.4-.8c-.5,3.3.6,8.7,3.8,16.6-.3,39.4-12.4,99-5.2,137.8l-11.5,28.1c-3.4,8-5.3,15-3.3,18.7-3.8,0-7.1,1.3-9,5.3-1.3-5.4,1-10.7,5.6-16.1,4.4-.2,3.9-3.8.5-9.4,1.4-4.4,9.5-14.9,6.5-19.6l-1.5-132.9h-.2Z"/>
  <path id="middlehandboneshighlight2" class="MHK1335Fill"  style={{
            fill: props.colors.mhk1335fill,
            stroke: props.colors.mhk1335fill,
          }} d="M206.2,206.1l-8.1-3.7c-11,2.3-7.2-5.9-10.6-8.9-6.8-6-13.3-12.1-19.5-18.6-8.5,2.6-14.1,7.9-14.6,18.4.2,7.5-2.1,16.1-9.7,27.1-8.2,22.9-14.8,40.1-20,53.5-3.6,4.9-7.9,9.2-13.7,12-.9,1.6-1.7,3.5-2.4,5.5.5.1.9.3,1.2.5.3-.1.6-.2,1-.2s.1,0,.2,0c0,0,0,0,.1,0,3,0,3.2,4.4.6,4.9,0,.4-.2.7-.3,1.1-.2,1.4-.7,2.7-1.7,3.7-.2.3-.4.7-.7,1,0,1-.3,1.9-.7,2.8.2,1.8,0,3.7-.9,5.3.6.9.5,2.2-.1,3.1.3.6.3,1.3.1,1.9.2.6.1,1.4-.2,1.9.2,1,.2,2,0,3,.7,1.1,1.1,2.3,1.2,3.6.3.4.6.8.9,1.2.9.2,1.5.8,1.7,1.6.5.2,1,.5,1.3.9,1.2.4,2.2,1.1,2.8,2,.9.3,1.7.9,2.2,1.6.2.1.4.3.5.5,1.8.2,3.1,1,3.9,2.2.4.2.8.4,1.2.6.7.3,1.3.7,1.8,1.1.7,0,1.3.2,1.9.5.4.1.7.2,1.1.4,1.4,0,2.6.5,3.5,1.3.3,0,.5,0,.8,0,1,0,1.8.2,2.6.5,4.5,0,8.6-.9,12.1-2.8,7.9.1,15.1-10.6,7.5-28.4l.4-12.7h0c12.8-25.8,24.9-49.7,33.6-63.1,14.9-1.6,20.6-15.9,19.1-25.2Z"/>
  <path id="middlehandboneshighlight1" class="MHK1335Fill"  style={{
            fill: props.colors.mhk1335fill,
            stroke: props.colors.mhk1335fill,
          }} d="M30.9,131.6l1.5,132.9c2.6,6.7-4.8,13.4-6.5,19.6,3.5,5.6,4.3,9.5-.5,9.4l-4.4-8.1c-6.3-3.8-.9-15.5,4.5-27,.7-41.4-2.3-77.5.1-104-6.1-17.7-2.1-24.8,4.8-28.6l.4,5.8h0Z"/>
</g>
<g id="outlines">
  <path id="outlinepart3" class="mittelhand_links_lat1335_cls4" d="M164.5-1.7c-8.8,11-5,34.3-13.4,58.4-4.1,11.8-9.2,57.8-19.2,84.5s-8.9,20.1-9.5,26.9c-2,23-1.1,24.8,9.5,22.3,8.6-2,18.2-14.8,28.6-47.1,9-28.1,20.1-33.7,23-43.3,6.6-22,44.7-56.3,44.7-56.3"/>
  <path id="outlinepart2" class="mittelhand_links_lat1335_cls4" d="M228.2,206.5c-2.5,25.3-19.2,77-27.5,103.2s-25.6,61.4-37.8,77.7-25.8,20.1-28.6,32c-2.3,9.9-12.9,54.4-12.9,78.4"/>
  <path id="outlinepart1" class="mittelhand_links_lat1335_cls4" d="M33,95.8c4.3,0-19.2,26.1-16.8,26.9s-6.2-1.8-2.8,18.7c3.9,24,9.1,78.9,1.8,105.2-7.8,28-8,74.2-7,74.6,0,0-7.3,45.1-5.2,54.4s-2.4,17.3-2.4,17.3"/>
</g>
<g id="OutlineHighlights">
  <path id="outlineparthighlight4" class="thumb1335Fill" style={{
          fill: props.colors.thumb1335Fill,
          stroke: props.colors.thumb1335Fill,
        }} d="M228.1,126.4c-19.2,33.9-8.8,30.9-12.2,56.2h0c-2.3,12.3-6.4,21.5-17.4,20.1-8.1,1.9-8.6-2.5-8.6-7.3l-21.4-20.2c-9.4-9.4-1.1-15.6,10.5-21.3,3.4-10.9,12.9-28.6,24.8-49"/>
  <path id="outlineparthighlight3" class="weichteile1335Fill" style={{
          fill: props.colors.weichteile1335fill,
          stroke: props.colors.weichteile1335fill,
        }} d="M228.1,43.8c-2-6.7-37.3,31-49.6,57.1-10.4,15.1-16.9,28.6-20.2,37.7-4.3,12.9-12.3,34.8-16.6,40.2-4.3,5.4-12.2,10.7-15.6,7.7-3.4-3-.9-18.7,0-20.7,6.4-14.4,18-47.9,21.5-73,1.5-10.4,14-55.6,13.2-62.7-1.3-11.1,1.8-28.6,1.8-28.6-8.7,11-3.1,31.5-11.5,55.6-4.1,11.8-9.3,57.7-19.2,84.5-1.6,4.4-2.9,6.6-4,8-.5,3.4-2,6.7-3.5,9.8,0,0,0,0,0,0,0,.5-.1,1-.3,1.4-.1.5-.4,1-.6,1.5-.1.2-.3.4-.5.6-.2,1.6-.3,3.3-.5,5.4-1.9,23-1.1,24.8,9.5,22.3,8.6-2,18.3-14.8,28.6-47.1,9-28.1,20.1-33.7,23-43.3,6.6-21.9,44.7-56.3,44.7-56.3v-.3Z"/>
  <path id="outlineparthighlight2" class="weichteile1335Fill" style={{
          fill: props.colors.weichteile1335fill,
          stroke: props.colors.weichteile1335fill,
        }} d="M121.3,498.2c0-24,10.6-68.5,12.9-78.4,2.8-11.9,16.3-15.7,28.6-32s29.5-51.5,37.8-77.7,25-77.9,27.5-103.2c-9.5,75.3-34.8,147.6-70.1,193.1-13.3,11.8-14.7,11.2-16.4,14-7,11.1-14.2,51.1-20.3,84.1h0Z"/>
  <path id="outlineparthighlight1" class="weichteile1335Fill" style={{
          fill: props.colors.weichteile1335fill,
          stroke: props.colors.weichteile1335fill,
        }} d="M.5,393.4s4.5-8,2.4-17.3,6-59.5,5.2-54.4-.8-46.6,7-74.6c7.4-26.4,2.2-81.3-1.8-105.2-3.4-20.5,2.8-18.7,2.8-18.7-1.8-.3,19.7-27.1,16.8-26.9,0-1.3-29,30-26.1,33.6,5.8,7.3,8,49.4,7.3,86.6-.2,14-2,27-3.5,30.5-2.9,6.8-5.4,76.9-6.5,84.9-1.4,10.6-5.1,57.3-3.7,61.5h0Z"/>
</g>
</svg>
  );
}

export default LinkeMittelhandLatSitzend1335;
