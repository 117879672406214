import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const FaceSkull = map.FaceSkull;

function SideView({ selected, bpartSetter }) {
  return (
    <svg
      id="Ebene_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 520.59 609.74"
    >
      <g id="Ebene_2-2">
        <g id="outlines">
          <path
            id="outlines_1"
            class="face-sskul-cls-4"
            d="M437.83,327.42c-15.01,35.34-29.59,68.38-41.33,86.19-11.68,21.08-17.31,46.62-18.9,75.11-4.18,41.15-19.67,60.41-43.35,63.77-32.05,.52-62.99,9.48-93,25.46-30.71,17.57-69.99,26.31-113.17,31-33.54-5.9-36.06-26.08-22.43-53.69,8.48-13.44,9.41-29.25,2.77-47.38-7.92-13.43-11.39-15.79-5.3-19.65-3.71-6.52-4.81-15.38-4.54-25.46-4.96,4.44-8.35,1.06-10.59-8.07-3.19-10.01-2.86-20.28,0-30.75-2.89-6.33,.87-16.63,5.54-27.48,2.15-14.41-2.3-23.36-8.32-31l-4.79-5.04c17.57-6.38,33.19-13.46,41.33-23.19,2.63-14.95,.05-28.55-5.79-41.33-2.15-3.13-5.43-5.94-9.33-8.58-7.08-.78-7.55-4.41-5.04-9.33,1.47-5.47,1.41-11.09-.51-16.88-7.65-8.66-13.56-17.58-18.15-26.72-5.36-7.08-2.82-13.78,5.54-20.16,20.15-8.62,31.16-25.92,38.31-46.89l4.03-6.56,2.53-4.54"
          />
          <path
            id="outlines_1-2"
            class="face-sskul-cls-2"
            d="M484.43,353.12c-5.67,15.7-12.47,28.81-21.67,36.29-9.17,10.15-17.82,19.75-25.7,28.48-.79-11.99,1.6-21.96,9.83-28.23,7.1-6.28,11.84-14.3,14.37-23.94,3.32-10.18-.09-14.42-5.54-16.88-6.53-2.63-13.22-12.86-17.89-21.43"
          />
          <path
            id="outlines_1-3"
            class="face-sskul-cls-2"
            d="M129.13,146.18c-4.39-22.03-.33-53.21,9.25-76.38,1.8-27.38,7.7-51.01,19.41-69.31"
          />
        </g>
        <g id="skull">
          <path
            id="skull_2"
            class="face-sskul-cls-2"
            d="M274.22,105.12c-13.95-2.89-16.14,16.13-18.15,35.54-12.49-2.14-16.67,9.64-17.39,27.22-4.05-1.29-6.69,3.06-10.58,3.78-4.13,2.11-8.15,1.94-12.1,0-7.42-.12-11.52,4.15-12.85,12.1"
          />
        </g>
        <g id="skull_base">
          <path
            id="skull_base_2"
            class="face-sskul-cls-2"
            d="M498.04,255.57c-18.48-3.02-37.31,.32-56.46,9.07-21.36,8.02-54.79,6.33-87.71,5.04l-21.67-5.04c-14.4-11.88-17.18-26.84-15.62-42.97-8.86-10.63-13.12-24.28-7.06-44.73l5.04-9.07c-3.68-3.24-7.82-6.36-10.08-11.59"
          />
          <path
            id="skull_base_1"
            class="face-sskul-cls-1"
            d="M332.19,264.64l-41.83,5.04c-13.64-4.72-18.33-12.39-16.64-22.18,5.4-31.45-12.86-56.11-35.03-79.64"
          />
        </g>
        <g id="jaw_joint">
          <path
            id="jaw_joint_3"
            class="face-sskul-cls-2"
            d="M290.35,269.7c-1.56,9.89,2.57,20.32,15.82,31.63,15.22-4.42,28.75-5.71,36.67,3.59,13.75,.78,22.84,5.58,25.51,15.87,11.43,5.32,21.78,8.2,30.43,7.18-1.52-6.68-1.26-12.86,.75-18.52,15.71-25.22,30.69-29.46,44.8-8.89,4.37,9.96,9.21,10.89,14.93-5.67,4.8-15.5,14.84-18.33,30.81-6.8,7.53,6.41,9.99,16.33,10.2,27.78-1.26,15.55-4.4,27.76-13.6,33.83-7.51-17.27-18.2-22.57-29.11-27.03-4.69-3.35-6.12-8.7-5.48-15.31"
          />
          <path
            id="jaw_joint_2"
            class="face-sskul-cls-4"
            d="M295.48,316.14c14.26,8.7,22.29,19.71,22.6,33.58,10.96,20.1,26.86,24.44,48.01,12.1,17.5-9.4,29.84-20.27,32.7-33.83"
          />
          <path
            id="jaw_joint_1"
            class="face-sskul-cls-4"
            d="M437.79,327.4c7.49-7.78,8.7-16.95,6.55-26.84"
          />
        </g>
        <g id="nasal_bone">
          <path
            id="nasal_bone_1"
            class="face-sskul-cls-4"
            d="M101.07,261.12c5.22-34.21,18.23-57.19,38.56-69.06,5.75-2.62,8.66-7.73,9.83-14.37l-16.13-21.43"
          />
        </g>
        <g id="orbita">
          <path
            id="orbita_3"
            class="face-sskul-cls-4"
            d="M133.33,156.26l-4.21-10.08c3.97-6.18,9.8-11.33,20.34-13.85,1.96,10.61,11.87,20.62,27.22,30.24,10.98,6.32,20.06,13.28,26.47,21.17l12.85,46.12c-8.17-7.12-16.25-12.42-24.2-15.12-10.26-14.53-21.56-22.07-34.02-21.92-5.15-1.32-7.72-6.68-8.32-15.12l-16.13-21.44Z"
          />
          <path
            id="orbita_2"
            class="face-sskul-cls-4"
            d="M160.23,192.89l-3.45,25.89c-4.58,20.55-5.27,40.04,3.45,56.97,11.06,15.16,23.99,22.82,42.92,6.56,13.52-11.9,17.12-29.79,12.85-52.42"
          />
          <path
            id="orbita_1"
            class="face-sskul-cls-4"
            d="M172.57,288.11c-7.51,2-9.1,7.43-6.84,15.1,24.34,16.21,35.96,36.2,41.97,57.84,8.33,13.19,21.37,13.03,38.94,0,18.24-10.09,34.22-25.84,48.86-44.92,3.64-4.74,7.19-9.69,10.69-14.81"
          />
        </g>
        <g id="jaw">
          <path
            id="jaw_1"
            class="face-sskul-cls-4"
            d="M273.25,341.17c2.55,48.83,3.59,93.73-2.42,120.45-4.22,22.39-16.61,34.37-32.14,42.34"
          />
          <path
            id="teeth_23"
            class="face-sskul-cls-4"
            d="M87.97,423.93c2.16-4.6,4.74-4.81,7.81,0,5.15-8.64,11.24-6.82,17.77,0,6.41-6.49,12.25-6.02,17.39,2.9,12.21-8.05,20.92-8.7,29.11-1.45,6.57-3.29,12.62-3.76,18.15-1.45,10.62-9.62,19.73-8.56,27.97-1.37,8.13-8.79,18.24-13.8,32.52-10.85,10.44-9.06,19.38-11.1,26.47-4.54l10.08-9.84"
          />
          <path
            id="teeth_22"
            class="face-sskul-cls-4"
            d="M103.09,488.21c3.15,5.26,6.77,7.45,11.21,4.79,5.66,7.2,10.91,10.91,14.75,1.9,6.98,6.94,13.24,8.28,18.15-3.33,6.33,7.33,12.8,9.45,19.65-4.97,9.56,6.57,17.72,8.28,21.55-4.92,5.19,5.04,10.07,6.4,13.99-4.15,8.13,3.76,15.22,3.98,20.04-3.4,6.12,2.86,11.53,.03,16.25-8.7,6.06,3.75,11.09,3.83,13.6-4.92,6.88,2.08,11.78,1.28,13.99-3.4,.98-.62,2.9-1.81,6.1-3.83"
          />
          <path
            id="teeth_21"
            class="face-sskul-cls-4"
            d="M95.78,423.93c-.93,15.3-.05,28.31,2.77,38.82,4.79-1.48,8.83-4.33,11.59-9.5,6.76,10.79,13.55,13.36,20.42,2.7,9.03,6.95,20.96,11.84,26.18-.75,8.87,7.9,14.17,10.63,21.82-4.15,8.98,9.77,15.05,8.66,18.15-3.78,6.09,8.15,11.76,12.04,15.87,0,6.1,4.52,12.02,7.41,15.87-6.8,4.74,6.61,9.66,5.23,14.75-2.65,5.27,5.2,10.7,3.43,16.25-4.92,5.71,5.54,10.08,2.65,11.34,0l3.84-3.02"
          />
          <path
            id="teeth_20"
            class="face-sskul-cls-4"
            d="M113.55,423.93c-3.45,10.01-4.53,19.79-3.4,29.32"
          />
          <path
            id="teeth_19"
            class="face-sskul-cls-4"
            d="M130.94,426.83c.12,10.9,.05,20.86-.38,29.11"
          />
          <path
            id="teeth_18"
            class="face-sskul-cls-4"
            d="M160.06,425.38c-4.05,10.44-3.13,20.43-3.29,29.8"
          />
          <path
            id="teeth_17"
            class="face-sskul-cls-4"
            d="M178.19,423.61c.09,10.19,.2,19.64,.38,27.1"
          />
          <path
            id="teeth_16"
            class="face-sskul-cls-4"
            d="M206.17,422.56c4.76,6.49,6.33,15.1,6.43,24.69"
          />
          <path
            id="teeth_15"
            class="face-sskul-cls-4"
            d="M238.69,411.71c4.53,7.61,6.05,16.29,4.53,26.09"
          />
          <path
            id="teeth_14"
            class="face-sskul-cls-4"
            d="M265.14,407.18c2.81,3.04,4.4,13.47,5.67,25.7"
          />
          <line
            id="teeth_13"
            class="face-sskul-cls-4"
            x1="256.97"
            y1="422.56"
            x2="259.48"
            y2="432.88"
          />
          <line
            id="teeth_12"
            class="face-sskul-cls-4"
            x1="226.22"
            y1="433.26"
            x2="227.53"
            y2="443.46"
          />
          <line
            id="teeth_11"
            class="face-sskul-cls-4"
            x1="196.35"
            y1="440.29"
            x2="196.72"
            y2="447.25"
          />
          <path
            id="teeth_10"
            class="face-sskul-cls-4"
            d="M114.31,492.99c-2.47-12.15-3.7-25.57-4.15-39.75"
          />
          <path
            id="teeth_9"
            class="face-sskul-cls-4"
            d="M129.05,494.89c2.2-11.28,2.57-24.4,1.51-38.94"
          />
          <path
            id="teeth_8"
            class="face-sskul-cls-4"
            d="M147.19,491.54c-3.08-9.42-3.51-18.98-1.49-28.64"
          />
          <path
            id="teeth_7"
            class="face-sskul-cls-4"
            d="M166.86,486.57c2.14-8.32,2.02-16.63-.38-24.95"
          />
          <path
            id="teeth_6"
            class="face-sskul-cls-4"
            d="M188.4,481.65c-.67-8.07-.89-16.13-.67-24.2"
          />
          <path
            id="teeth_5"
            class="face-sskul-cls-4"
            d="M202.4,477.5c3.08-8.08,3.02-16.16-.16-24.25"
          />
          <line
            id="teeth_4"
            class="face-sskul-cls-4"
            x1="222.43"
            y1="474.1"
            x2="221.3"
            y2="465.37"
          />
          <path
            id="teeth_3"
            class="face-sskul-cls-4"
            d="M238.69,465.4v-21.63"
          />
          <line
            id="teeth_2"
            class="face-sskul-cls-4"
            x1="252.29"
            y1="460.48"
            x2="251.54"
            y2="455.95"
          />
          <path
            id="teeth_1"
            class="face-sskul-cls-4"
            d="M267.98,455.95c.56-7.02,0-14.02-1.71-21.04"
          />
        </g>
      </g>
      <path
        id="jaw-2"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, FaceSkull.id, FaceSkull.children.jaw),
          });
        }}
        class={`face-sskul-cls-3 ${isSelected(
          selected,
          FaceSkull.children.jaw
        )}`}
        d="M437.84,327.7c7.79-8.32,10.08-17.31,6.73-26.98-13.94-22.55-30.76-14.35-45.04,8.72-2.46,4.51-2.11,11.47-1.34,18.8,1.64,6.01-8.99,21.28-27.51,30.6-20.14,14.55-44.16,13.68-52.01-9.33l-44.29,22.14-180.98,25.09c-5.01,9.51-8.11,18.76-5.24,27.18-5.17,17.66,.45,49.84,10.26,39.11-.48,8.18,1.03,16.72,4.77,25.69-5.79,2.65-1.06,9.66,5.09,19.44,6.41,17.68,3.52,33.01-2.77,47.38-16.91,32.65-5.37,48.51,22.43,53.69,34.06-3.41,68.68-8.39,113.17-31,33.23-16.65,64.19-25.42,93-25.46,22.68-4.36,43.18-19.54,45.47-83.46,2.22-21.78,8.37-37.78,16.78-55.42,13.53-22.11,27.44-52.96,41.46-86.2Z"
      />
      <path
        id="eyesocket"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, FaceSkull.id, FaceSkull.children.orbita),
          });
        }}
        class={`face-sskul-cls-3 ${isSelected(
          selected,
          FaceSkull.children.orbita
        )}`}
        d="M171.86,288.42c-26.25-21.32-18.45-47.57-11.89-95.55,14.39,.8,24.6,9.58,32.4,22.96,9.21,2.88,16.67,7.31,22.55,13.12,13.12,35.27-16.4,73.82-43.06,59.46Z"
      />
      <path
        id="zygomatic_arch"
        onClick={() => {
          bpartSetter({
            id: idToSet(
              selected,
              FaceSkull.id,
              FaceSkull.children.zygomaticArch
            ),
          });
        }}
        class={`face-sskul-cls-3 ${isSelected(
          selected,
          FaceSkull.children.zygomaticArch
        )}`}
        d="M203.54,183.9c2.16-12.76,9.33-12.33,12.46-12.24,5.5,2.75,9.41,1.35,12.1,0,2.71-1.36,6.64-4.16,10.16-3.89,22.93,23.26,40.25,48.23,35.34,80.03-1.21,9.65,4.87,17.23,16.6,22.49-1.74,13.1,6.2,22.54,15.85,31.33-9.99,14.99-20.8,28.62-32.9,40.11-9.96,8.48-19.49,13.59-26.52,19.32-17.36,14.13-32.71,12.59-38.94,0-6.98-26.27-21.68-43.31-41.57-57.86-2.67-5.54-1.4-12.49,5.74-14.76,19.48,11.89,55.36-14.35,44.29-57-2.67-10.87-7.53-29.6-12.61-47.52Z"
      />
      <path
        id="NKK"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, FaceSkull.id, FaceSkull.children.NNH),
          });
        }}
        class={`face-sskul-cls-3 ${isSelected(
          selected,
          FaceSkull.children.NNH
        )}`}
        d="M100.92,260.54c2.65,6.23,2.62,12.29,.82,18.25-3.38,4.96,.03,7.01,4.51,8.61,3.14,2.08,6.35,4.05,9.43,8.82,7.18,14.76,8.41,29.94,5.74,41.62-2.88,3.56-6.3,6.09-10.05,8,28.3,22.76,61.01,30.98,86.12,35.27,9.66-4.56,13.66-11.05,10.2-20.05-7.22-26.52-22.21-44.58-41.57-57.86-2.62-6.54-1.67-11.8,5.74-14.76l-11.89-12.92c-8.77-15.06-8-42.85-2.71-60.49,2.73-15.91,2.5-24.51,2.71-22.14,10.94,1.15,21.77,7.45,32.4,22.96,1.44-1.03,17.55,6.9,23.79,15.58l-12.61-47.52c-12.2-17.48-45.83-26.71-54.44-51.31-9.08,1.02-15.56,5.77-20.09,13.33l4.32,10.33,16.38,21.86c-1.16,5.08-3.24,9.84-9.02,13.74-21.39,11.85-34.45,33.01-39.78,68.69Z"
      />
      <path
        id="nasal_bone-2"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, FaceSkull.id, FaceSkull.children.nasalBone),
          });
        }}
        class={`face-sskul-cls-3 ${isSelected(
          selected,
          FaceSkull.children.nasalBone
        )}`}
        d="M88.61,214.2c19.81-9.03,31.01-26.06,38.16-46.84l6.56-11.1,16.38,21.86c-.71,.65,.95,6.48-9.02,13.74-22.76,11.07-34.24,38.75-39.78,68.69-7.92-7.88-14-16.55-17.99-26.14-5.42-7.04-2.75-13.74,5.69-20.2Z"
      />
    </svg>
  );
}

export default SideView;
