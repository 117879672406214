import style from "./style.scss";

export default function RechtesSchultergelenkSchwedenstatusInnenrotationVdStehend2006(
  props
) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 343.46 442.38">
      <g id="background_layer" data-name="background layer">
        <path
          id="background_1"
          data-name="background 1"
          class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls11"
          d="M64.79,432.86c.03-6.63-.04-16.93-.63-29.58-.49-10.41-.88-13.15-2.51-35.87-1.91-26.54-1.58-26.07-2.51-34.61-.88-8.14-1.08-7.68-5.04-34.61-3.81-25.98-3.67-26.66-5.04-31.47-3.75-13.25-5.87-11.88-13.21-32.1-6.01-16.54-4.93-18.38-10.07-28.32-5.68-10.98-8.52-16.46-11.33-20.14-2.99-3.91-5.64-6.6-6.3-11.33-.79-5.75,1.96-10.57,3.15-12.59,5.52-9.4,12.31-7.4,15.74-14.78,3.17-6.82-1.94-10.06,1.26-19.21,1.6-4.59,4.18-7.38,9.33-12.98,4.56-4.95,8.63-9.37,15.22-12.82,4.59-2.39,10.61-3.49,22.66-5.66,7.94-1.44,11.97-2.15,15.11-1.89,6.03.5,9.28,2.41,19.51,6.92,13.9,6.13,13.61,5.11,18.25,7.55,20.4,10.74,28.76,31.97,31.47,38.4,1.49,3.52,2.73,7.79,3.43,12.22,1.22,7.83-1.14,15.78-6.35,21.75-.79.9-1.69,1.95-2.73,3.17-4.13,4.82-4.73,5.7-7.55,8.81-5.63,6.22-5.77,5.57-6.92,7.55-3.02,5.21-.99,7.92-3.15,15.11-1.89,6.28-4.01,6.13-6.3,11.96-2.47,6.32-1.35,10.06-1.26,20.14.1,10.8-1.16,9.56-1.89,25.17-.43,9.32-.28,15.89,0,27.06.53,21.97,1.57,20.78,1.26,31.47-.45,15.17-2.48,15.55-3.15,32.1-.41,10.12.24,12.65.63,29.58.18,7.88.32,19.57,0,33.99"
        />
        <path
          id="background_2"
          data-name="background 2"
          class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls11"
          d="M247.48,422.15c-7.7-17.38-13.29-35.15-15.57-53.55-4.57-22.99-19.35-46.53-35.82-70.16-16.08-33.64-29.64-60.98-52-74.28,1.12-13.34,10.22-27.57,20.62-42.12,6.78-11.48,6.59-25.93-4.11-44.85-11.6-14.87-9.42-29.71-2.38-34.14l6.64-3.13c-20.92-14.36-42.32-24.03-64.3-28.32-20.97-2-33.42-7.22-35.83-16.23-2.11-7.66,1.64-10.95,8.76-11.73,34.7-4.88,55.5-3.96,57.96,4.6.48,8.08,5.72,13.89-1.42,13.3-9.93,1.38-12.48,3.22-4.66,5.71,54.59,8.09,104.99,16.45,138.78,25.93l14.93-3.66s1.16-.29,2.32-.57c-3.73-1.57-7.44-3.23-10.7-5.42-5.5-3.7-11.76-6.76-18.42-7.61-7.02-.89-13.9-.25-20.52-3.38-3.49-1.65-6.6-4.14-10.3-5.26-4.98-1.51-10.2-.4-15.29-.64-5.38-.26-10.73-2.29-15.99-3.37-5.18-1.06-10.45-1.63-15.73-1.88-3.04-.14-6.09-.18-9.13-.21-3.77-.04-7.55-.09-11.32-.13-8.11-.1-18.8.64-18.8-10.86,0-.81-2.05-4.72,1.13-7.52,3.52-3.09,17.26-7.26,25.78-5.85,7.18,1.19,18.31-1.06,25.44.38,18.29.23,17.79-1.35,36.88,2.32,14.37,2.76,24.98,5.48,33.05,7.55,9.29,2.39,52.03,15.01,64.95,19.06l4.15,341.47s-27.72,5.11-50.34,9.28c-15.59,2.87-28.76,5.3-28.76,5.3Z"
        />
      </g>
      <g id="underlays">
        <path
          id="joint_gap_underlay"
          data-name="joint gap underlay"
          class="Gelenkspalte2006Fill"
          style={{
            fill: props.colors.Gelenkspalte2006Fill,
            stroke: props.colors.Gelenkspalte2006Fill,
          }}
          d="M136.56,217.27h0c1.43,4.01,5.3,7.61,7.52,6.88,2.05-.68,1.45-4.66,3.42-11.33,1.15-3.89,2.62-6.56,5.57-11.94,7.97-14.53,11.67-14.62,14.69-23.05,3.08-8.61,2.06-16.38,1.75-18.41-1-6.5-4.06-12.59-10.17-24.77-2.44-4.84-3.53-6.58-4.47-10.23-1.97-7.69.06-10.93-2.38-13.4-4.35-4.42-17.34-.66-17.72,3.05,2.1,1.1,2.52,2.42,6.94,6.01,5.97,4.84,8.89,9.97,14.08,19.07,3.76,6.59,5.76,10.17,6.96,15.77.63,2.96,1.57,7.4.69,12.71-1.41,8.42-6.65,14.34-15.3,24.11-4.23,4.77-6.09,6.21-8.42,10.42-1.95,3.53-5.19,9.37-3.15,15.11h0Z"
        />
      </g>
      <g id="shoulder_joint" data-name="shoulder joint">
        <path
          id="Collarbone"
          class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls4"
          d="M322.64,66.11c-12.92-4.05-55.66-16.67-64.95-19.06-8.07-2.07-18.68-4.79-33.05-7.55-19.09-3.67-18.59-2.1-36.88-2.32-7.13-1.45-18.26.81-25.44-.38-8.52-1.41-22.26,2.76-25.78,5.85-3.18,2.8-1.13,6.71-1.13,7.52,0,11.5,10.69,10.77,18.8,10.86,3.77.04,7.55.09,11.32.13,3.04.04,6.09.07,9.13.21,5.28.25,10.56.82,15.73,1.88,5.26,1.08,10.61,3.1,15.99,3.37,5.09.25,10.32-.87,15.29.64,3.7,1.12,6.81,3.61,10.3,5.26,6.62,3.13,13.5,2.48,20.52,3.38s12.92,3.92,18.42,7.61c5.2,3.49,11.54,5.63,17.29,8.23"
        />
        <g id="upper_arm" data-name="upper arm">
          <path
            id="upper_arm_1"
            data-name="upper arm 1"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls4"
            d="M64.79,432.86c.03-6.63-.04-16.93-.63-29.58-.49-10.41-.88-13.15-2.51-35.87-1.91-26.54-1.58-26.07-2.51-34.61-.88-8.14-1.08-7.68-5.04-34.61-3.81-25.98-3.67-26.66-5.04-31.47-3.75-13.25-5.87-11.88-13.21-32.1-6.01-16.54-4.93-18.38-10.07-28.32-5.68-10.98-8.52-16.46-11.33-20.14-2.99-3.91-5.64-6.6-6.3-11.33-.79-5.75,1.96-10.57,3.15-12.59,5.52-9.4,12.31-7.4,15.74-14.78,3.17-6.82-1.94-10.06,1.26-19.21,1.6-4.59,4.18-7.38,9.33-12.98,4.56-4.95,8.63-9.37,15.22-12.82,4.59-2.39,10.61-3.49,22.66-5.66,7.94-1.44,11.97-2.15,15.11-1.89,6.03.5,9.28,2.41,19.51,6.92,13.9,6.13,13.61,5.11,18.25,7.55,20.4,10.74,28.76,31.97,31.47,38.4,1.49,3.52,2.73,7.79,3.43,12.22,1.22,7.83-1.14,15.78-6.35,21.75-.79.9-1.69,1.95-2.73,3.17-4.13,4.82-4.73,5.7-7.55,8.81-5.63,6.22-5.77,5.57-6.92,7.55-3.02,5.21-.99,7.92-3.15,15.11-1.89,6.28-4.01,6.13-6.3,11.96-2.47,6.32-1.35,10.06-1.26,20.14.1,10.8-1.16,9.56-1.89,25.17-.43,9.32-.28,15.89,0,27.06.53,21.97,1.57,20.78,1.26,31.47-.45,15.17-2.48,15.55-3.15,32.1-.41,10.12.24,12.65.63,29.58.18,7.88.32,19.57,0,33.99"
          />
          <path
            id="upper_arm_2"
            data-name="upper arm 2"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls7"
            d="M55.98,166.63c-12.04,11.45-9.59,25.16-3.78,27.7"
          />
          <path
            id="upper_arm_3"
            data-name="upper arm 3"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls2"
            d="M138.06,192.21c-10.71-10.21-52.58-26.78-106.65-43.42"
          />
        </g>
        <g id="shoulder_plate" data-name="shoulder plate">
          <g id="shoulder_plate_1" data-name="shoulder plate 1">
            <path
              class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls10"
              d="M187.98,95.11c-1.02,4.02-5.72,5.12-9.24,5.44-5.11.67-10.17-.8-15.02.75-4.48,1.58-8.73,4.8-9.99,9.57-1.52,6.14-.46,13.49,2.71,19.02,3.66,5.64,7.22,11.78,9.86,18.02,7.3,14,4.23,26.68-3.44,39.68-3.13,6.11-8.5,10.68-11.57,16.8-3.14,5.92-5.19,12.41-6.17,19.04l-.31-.91c4.97,5.11,11.19,8.85,16.02,14.11,4.98,5.19,8.8,11.42,12.49,17.51,4.86,8.17,9.45,16.46,13.89,24.87,2.22,4.2,4.29,8.5,6.43,12.68,4.37,8.36,9.58,16.2,15.04,23.93,7.82,11.96,15.25,24.31,20.24,37.75,3.44,8.91,4.65,18.52,6.5,27.8,2.82,13.89,6.88,27.47,11.74,40.79,0,0-1.01.4-1.01.4-3.75-8.73-6.9-17.72-9.51-26.87-2.6-9.11-4.4-18.54-6.09-27.83-1.77-9.2-5.17-18.02-9.41-26.39-4.37-8.3-9.09-16.57-14.35-24.35-2.68-3.86-5.46-7.77-7.98-11.81-5.21-7.93-9.3-16.63-13.63-25.03-4.4-8.36-8.97-16.66-13.77-24.79-3.65-6.04-7.3-12.06-12.16-17.17-4.95-5.35-11.25-9.18-16.3-14.45,1.52-10.42,5.44-20.8,12.43-28.8,2.2-2.58,4.08-5.3,5.72-8.25,3.33-5.82,6.26-11.96,7.09-18.64,1.05-6.75-.74-13.16-3.73-19.23-1.41-3.11-2.81-6.18-4.45-9.12-2.34-4.51-5.94-8.57-7.51-13.57-3.22-11.24-1.91-22.31,10.54-26.63,5.71-2.48,20.47,2.16,22.98-4.81,0,0,1.93.51,1.93.51h0Z"
            />
          </g>
          <path
            id="shoulder_plate_2"
            data-name="shoulder plate 2"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls4"
            d="M144.08,223.26c3.03.04,10.6-4.75,15.74-7.54,1.54-.84,8.21-4.67,13.21-16.36,5.18-12.13,4.38-22.91,3.15-37.13-1.7-19.66-6.67-34.31-9.44-41.54-1.86-4.85-5.63-12.11-8.54-17.65"
          />
          <line
            id="shoulder_plate_3"
            data-name="shoulder plate 3"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls4"
            x1="254.23"
            y1="97.4"
            x2="281.92"
            y2="103.95"
          />
          <path
            id="shoulder_plate_4"
            data-name="shoulder plate 4"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls4"
            d="M208.94,119.66c-2.61-8.52-10.1-13.47-27.72-13.15-3.92.07-7.05-3.76-8.64-7.55-1.17-2.78-1.64-6.31-.46-9.17,1.25-3.04,4.78-4.98,7.58-6.08,7.04-2.77,14.95-1.08,22,.82,4.6,1.25,6.92,1.71,10.74,3.61.88.44,1.85.68,2.83.72,1.67.07,3.2.27,4.35.35,1.99.14,4.11.29,6.77-.11.83-.13,2.95-.5,5.13-.25.98.11,1.83.33,1.95.36.85.21,1.62.5,2.37.83,1.9.86,2.95,1.75,4.3,2.61,1.38.88,2.48,1.34,4.66,2.22,1.68.68,5.88,2.39,9.44,2.51,4.49.15,4.54-2.35,11.96-5.04,3.71-1.34,3.75-.74,17-3.78,2.2-.5,4.12-.96,4.75-1.11,1.16-.29,2.26-.6,3.31-.95"
          />
        </g>
        <g id="acromion">
          <g id="acromion_1" data-name="acromion 1">
            <path
              class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls10"
              d="M208.73,144.72c-8.58-8.48-27.09-27.77-35.61-35.63-27.17-24.41-54.95-34.29-90.45-39.62-8.36-1.59-17.7-6.42-19.27-15.61-.58-2.99.07-6.49,2.52-8.57,2.4-2.02,5.49-2.16,8.37-2.66,5.57-.79,11.18-1.35,16.79-1.63,8.15-.6,17.14-.73,25.22-.94,3.16-.14,6.75.15,9.63,1.98,1.53.96,3.16,1.77,4.49,3.04,2.9,2.62,2.55,6.75,3.67,10.16.29,1.05.62,1.92.98,2.98.44,1.35-.01,2.93-1.1,3.85-.71.63-1.7.94-2.64.86h.21c-1.83.25-6.52.92-8.29,1.18-.28.05-.91.1-1.17.19-.59.23-.54,1.19.16,1.29,1.35.28,4.08.84,5.43,1.11,37.31,6.35,75.83,10.9,112.67,19.68,14.49,3.42,28.9,7.41,42.75,12.93l-.17.44c-13.92-5.22-28.37-8.86-42.86-12.11-37.85-8.49-77.34-12.67-115.49-19.62,0,0-2.75-.57-2.75-.57-.63-.11-1.37-.58-1.72-1.25-.96-1.68.28-3.81,2.19-3.87,2.26-.3,6.88-1.02,9.18-1.28.93.11,1.79-.67,1.86-1.6.06-.58-.22-1.05-.41-1.64-.97-2.62-1.44-5.52-1.96-8.22-.77-3.37-4.13-4.87-6.85-6.47-2.31-1.18-5.03-1.36-7.76-1.27-5.62.27-11.23.06-16.79.41-8.07.45-17.07.75-25.02,2.07-6.06.73-10.39,2.27-9.29,9.19,1.53,8.36,10.21,12.41,17.83,13.85,42.45,6.17,73.63,20.76,102.88,52.61,0,0,23.26,24.26,23.26,24.26,0,0-.48.47-.48.47h0Z"
            />
          </g>
          <path
            id="acromion_2"
            data-name="acromion 2"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls2"
            d="M91.76,57.36c.68,5.08,32.02,7.44,40.72,4.49"
          />
        </g>
        <g id="ribs">
          <path
            id="rib_1"
            data-name="rib 1"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls3"
            d="M243.62,350.99c-53.34-62.24,4.68-141.81,84.91-157.9"
          />
          <path
            id="rib_2"
            data-name="rib 2"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls3"
            d="M230.12,376.45c-96.7-101.29,24.75-196.76,98.41-213.3"
          />
          <path
            id="rib_3"
            data-name="rib 3"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls3"
            d="M240.49,411.1c-55.31-62.24,4.85-141.81,88.03-157.9"
          />
          <path
            id="rib_4"
            data-name="rib 4"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls3"
            d="M226.5,436.56c-100.26-101.29,25.66-196.76,102.03-213.3"
          />
          <path
            id="rib_5"
            data-name="rib 5"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls3"
            d="M224.02,442.35c-15.62-54.33,40.69-110.86,113.67-124.14"
          />
          <path
            id="rib_6"
            data-name="rib 6"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls3"
            d="M191.42,440.32c-15.81-76.04,81.83-138.92,146.27-152.05"
          />
          <path
            id="rib_7"
            data-name="rib 7"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls3"
            d="M247.48,441.85c18.52-29.68,54.36-53.6,95.95-61.64"
          />
          <path
            id="rib_8"
            data-name="rib 8"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls3"
            d="M215.66,441.85c24.69-47.13,84.27-82.16,127.77-91.59"
          />
          <path
            id="rib_9"
            data-name="rib 9"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls3"
            d="M247.25,282.39c-48.54-58.35,4.25-132.94,77.26-148.03"
          />
          <path
            id="rib_10"
            data-name="rib 10"
            class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls3"
            d="M234.97,306.26c-87.98-94.96,22.52-184.46,89.54-199.97"
          />
        </g>
        <g id="outlines">
          <g>
            <path
              class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls1"
              d="M297.57.49c-1.94.42-3.89.84-5.86,1.25"
            />
            <path
              class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls6"
              d="M279.82,4.09c-46.74,8.75-101.87,13.58-152.09,8.77-4.99-.05-9.98.23-14.94.8-20.64,2.4-40.21,6.97-56.82,17.67-13.61,7.69-24.28,20.12-33.2,35.35C7.48,92.75.06,122.69.52,152.92l1.31,86.47,8.51,170.71"
            />
            <line
              class="Schultergelenk_Schwedenstatus_Innenrotation_rechts2006_cls1"
              x1="10.64"
              y1="416.16"
              x2="10.94"
              y2="422.15"
            />
          </g>
        </g>
      </g>
      <g id="overlays">
        <path
          id="cavitas_glenoidalis_overlay"
          data-name="cavitas glenoidalis overlay"
          class="cavitas_glenoidalis2006Fill"
          style={{
            fill: props.colors.cavitas_glenoidalis2006Fill,
            stroke: props.colors.cavitas_glenoidalis2006Fill,
          }}
          d="M145.08,223.26c7.48-3.15,14.14-6.92,19.82-11.42,9.76-10.5,13.83-25.08,11.76-44.03.45-18.23-16.71-71.92-20.14-62.23h0c-6.68,7.73-4.7,19.99,6.93,37.32,3.11,4.65,5.05,10.02,5.35,15.61,1.88,11.6-1.54,23.32-9.7,33.72-7.1,8.99-12.53,18.91-14.01,31.04Z"
        />
        <path
          id="tuberculum_majus_overlay"
          data-name="tuberculum majus overlay"
          class="Tuberculum_majus2006Fill"
          style={{
            fill: props.colors.Tuberculum_majus2006Fill,
            stroke: props.colors.Tuberculum_majus2006Fill,
          }}
          d="M11.3,162.24c-4.71,9.01-4.11,17.59,4.3,25.52l12.52,23.47-1.69-60.92c-5.62,3.77-11.47,7.74-15.13,11.92Z"
        />
        <path
          id="tuberculum_minus_overlay"
          data-name="tuberculum minus overlay"
          class="Tuberculum_minus2006Fill"
          style={{
            fill: props.colors.Tuberculum_minus2006Fill,
            stroke: props.colors.Tuberculum_minus2006Fill,
          }}
          d="M66.08,166.53c2.96,3.03,5.37,7.2,3.7,11.49-.61,1.57-1.23,3.12-1.63,4.75-1.64,6.72-6.68,10.46-13.03,12.37-5.2,1.56-6.96-4.1-7.79-8.37-.63-3.22-.31-6.56.65-9.68,1.44-4.69,4-9.68,7.67-13.01,1.45-1.32,2.83-1.77,4.14-1.65,2.27.2,4.34,2.1,6.3,4.1Z"
        />
        <path
          id="subcromatical_space_overlay"
          data-name="subcromatical space overlay"
          class="subacromialraume2006Fill"
          style={{
            fill: props.colors.subacromialraume2006Fill,
            stroke: props.colors.subacromialraume2006Fill,
          }}
          d="M128.98,80.21c6.2,10.66,4.06,19.43-7.3,26.09-6.3-3.25-15.55-6.84-26.5-10.62-6.95-1.66-12.4.06-20.3,1.23l-11.77,2.25c-9.3-15.35-7.68-27.92,5.28-37.62,6.19,9.14,29.74,5.8,60.59,18.68Z"
        />
        <path
          id="soft_parts_overlay"
          data-name="soft parts overlay"
          class="Weichteile2006Fill"
          style={{
            fill: props.colors.Weichteile2006Fill,
            stroke: props.colors.Weichteile2006Fill,
          }}
          d="M10.94,422.15L1.83,239.39l-1.31-86.47c-.46-30.23,6.96-60.17,22.25-86.25,8.93-15.23,19.6-27.66,33.2-35.35,16.61-10.69,36.18-15.27,56.82-17.67,4.49-.52,9-.79,13.51-.81.95,0,1.89.05,2.83.14,56.36,5.19,118.75-1.65,168.43-12.5v3.58c0,4.44-3.36,8.16-7.77,8.62l-132.81,10.82-44.89,2.05c-26.55,2.03-49.22,13.8-66.3,34.7-15.79,21.59-32.46,48.93-37.79,96.77-5.75,29.97,4.62,150.05,11.8,252.28.41,5.83-3.28,11.16-8.88,12.85h0Z"
        />
      </g>
    </svg>
  );
}
