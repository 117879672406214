import ReactApexChart from "react-apexcharts";
import { useEffect, useMemo, useRef, useState } from "react";
import { getDDMMYYY_HHMM } from "../../Utilities/DateTimeUtils/DateTimeUtils";
import { useMasKvs } from "../../Utilities/FetchHooks/Ris/RisHooks";

const chartColors = {
  red: "#fa3620",
  green: "#32fa20",
  yellow: "#f6fa20",
  blue: "#2074fa",
};

function VerifiedExaminationsCharts({ examination = undefined }) {
  const ref = useRef(undefined);
  const [dataReady, setDataReady] = useState(false);
  const masKvsQ = useMasKvs({
    pageSize: 30,
    pageNr: 1,
    filter: { model: "Exams", op: "eq", field: "id", value: examination?.id },
    sort: { model: "MAS_KV", field: "zeitstempel", direction: "asc" },
    xmask: "{maskv{mas,kv,dosis,zeitstempel}}",
    enabled: examination !== undefined,
  });

  const chartData = useMemo(() => {
    const masSeries = [
      {
        name: "mAs",
        type: "column",
        data: [],
      },
    ];
    const kvSeries = [
      {
        name: "kV",
        type: "column",
        data: [],
      },
    ];
    const dosisSeries = [
      {
        name: "uGy",
        type: "column",
        data: [],
      },
    ];
    const labels = [];

    if (masKvsQ.status !== "success" || !masKvsQ.data?.maskv?.length > 0) {
      return { masSeries, kvSeries, dosisSeries, labels, ready: false };
    }

    masKvsQ.data.maskv.forEach((entry) => {
      labels.push(getDDMMYYY_HHMM(entry.zeitstempel));
      masSeries[0].data.push(entry.mas);
      kvSeries[0].data.push(entry.kv);
      dosisSeries[0].data.push(entry.dosis);
    });

    masSeries.push(
      {
        name: "mAs from",
        type: "line",
        data: Array(masSeries[0].data.length).fill(examination.masfrom),
      },
      {
        name: "mAs till",
        type: "line",
        data: Array(masSeries[0].data.length).fill(examination.mastill),
      }
    );

    kvSeries.push(
      {
        name: "kV from",
        type: "line",
        data: Array(kvSeries[0].data.length).fill(examination.kvfrom),
      },
      {
        name: "kV till",
        type: "line",
        data: Array(kvSeries[0].data.length).fill(examination.kvtill),
      }
    );

    dosisSeries.push({
      name: "Dose target",
      type: "line",
      data: Array(dosisSeries[0].data.length).fill(examination.cgyqm),
    });

    return { masSeries, kvSeries, dosisSeries, labels, ready: true };
  }, [masKvsQ.data]);

  const getMasColors = ({ value }) => {
    let color = chartColors.blue;

    if (value < examination?.masfrom) {
      color = chartColors.yellow;
    }

    if (value >= examination?.masfrom && value <= examination?.mastill) {
      color = chartColors.green;
    }

    if (value > examination?.mastill) {
      color = chartColors.red;
    }
    return color;
  };

  const getKvColors = ({ value }) => {
    let color = chartColors.blue;

    if (value < examination?.kvfrom) {
      color = chartColors.yellow;
    }

    if (value >= examination?.kvfrom && value <= examination?.kvtill) {
      color = chartColors.green;
    }

    if (value > examination?.kvtill) {
      color = chartColors.red;
    }
    return color;
  };

  const getDosisColors = ({ value }) => {
    let color = chartColors.blue;

    if (value <= examination?.cgyqm) {
      color = chartColors.green;
    }

    if (value > examination?.cgyqm) {
      color = chartColors.red;
    }
    return color;
  };

  useEffect(() => {
    if (chartData.ready) {
      setDataReady(true);
    }
  }, [chartData]);

  useEffect(() => {
    ref.current.scrollIntoView();
  }, [examination]);

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ maxHeight: "92vh", overflowY: "auto", scrollBehavior: "smooth" }}
    >
      <div ref={ref} />
      <div className="p-2 rounded bg-light mb-2">
        <h4>Verified Examinations statistics</h4>
      </div>
      {dataReady && (
        <>
          <h5>mAs</h5>
          <ReactApexChart
            height={"450px"}
            width={"800px"}
            series={chartData?.masSeries}
            options={{
              chart: {
                id: "mAs",
              },
              xaxis: {
                categories: chartData.labels,
                labels: {
                  show: true,
                  rotate: -45,
                  rotateAlways: true,
                  hideOverlappingLabels: false,
                  trim: true,
                  minHeight: 40,
                },
              },
              colors: [
                (data) => getMasColors(data),
                chartColors.green,
                chartColors.yellow,
              ],
            }}
            type="line"
            className="bg-light rounded mb-3"
          />
          <h5>kV</h5>
          <ReactApexChart
            height={"450px"}
            width={"800px"}
            series={chartData?.kvSeries}
            options={{
              chart: {
                id: "kV",
              },
              xaxis: {
                categories: chartData.labels,
                labels: {
                  show: true,
                  rotate: -45,
                  rotateAlways: true,
                  hideOverlappingLabels: false,
                  trim: true,
                  minHeight: 40,
                },
              },
              colors: [
                (data) => getKvColors(data),
                chartColors.green,
                chartColors.yellow,
              ],
            }}
            type="line"
            className="bg-light rounded mb-3"
          />
          <h5>Dosis</h5>
          <ReactApexChart
            height={"450px"}
            width={"800px"}
            series={chartData?.dosisSeries}
            options={{
              chart: {
                id: "uGy",
              },
              xaxis: {
                categories: chartData.labels,
                labels: {
                  show: true,
                  rotate: -45,
                  rotateAlways: true,
                  hideOverlappingLabels: false,
                  trim: true,
                  minHeight: 40,
                },
              },
              colors: [(data) => getDosisColors(data), chartColors.blue],
            }}
            type="line"
            className="bg-light rounded mb-3"
          />
        </>
      )}
    </div>
  );
}

export default VerifiedExaminationsCharts;
