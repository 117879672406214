import style from "./style.scss";

export default function RechtesAcromioklavikulargelenkDvStehend2020(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 433.93 286.9">
      <g id="acromial_joint" data-name="acromial joint">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="akromioklavikulargelenk_right2020_cls6"
            d="M201,279.67c-8.8-9.6-16-22-16-22-9.7-16.6-8.6-23.1-18-31-6.3-5.3-11.2-6.2-13-12-2.2-7.3,3.5-12.5,1-16-3.3-4.6-16.9-1.2-26,3-3.4,1.5-15.9,7.5-30,26-10.1,13.3-9.1,19.3-16,25-19.5,16.1-82,6.4-82-23,0-15,5.2-20.4,9-64,2.2-25-1.2-29.7,3-52,3.8-20.6,6.6-35.6,19-46,10-8.4,21.9-10.1,42-13,21.1-3,31.6-4.5,38-4,5,.4,10.3,1.2,15-2,5.9-4.1,4.1-10,9-18,5.8-9.4,17.1-15.4,27-16,5.3-.3,11.2,1.8,23,6,9.9,3.5,12.8,5.8,20,6,6.8.2,8.4-1.7,12,0,4.4,2.1,4.1,5.9,10,17,4.9,9.3,8.5,12.9,7,15-2,2.8-10.4-1.6-12,1-1.4,2.2,3.4,7.9,7,11,1.9,1.6,6.4,5.2,14,7,7.9,1.9,9.4-.4,17,1,6.9,1.2,6.6,3.3,18,8,13.2,5.5,15.3,3.5,21,8,2.7,2.1,5.4,6.4,11,15,5.3,8.2,4.5,10.2,9,16,1.5,2,5,6.4,10.6,10.6,1.3,1,.9.6,9.7,6.4,5,3.2,5.7,3.8,8.1,5.3,4.5,2.9,8.2,5,9.5,5.7,7.5,4.2,14.3,4.5,20,7,17.1,7.7,26.9,12.5,40,19,14.5,7.1,14.7,7.2,15,8,8.7,24.3-200,127.1-231.9,92Z"
          />
        </g>
        <g id="cavicula">
          <path
            id="cavicula_1"
            data-name="cavicula 1"
            class="cls-4"
            d="M427,204.27c-2.9-3.3-7.5-8.5-13-15-11.6-13.8-12.9-16.5-24-30-14.7-17.8-16.2-17.7-22-27-5-7.9-8.8-13.9-12-23-3.2-8.9-2.5-11.5-6-16-5.1-6.5-9.1-4.6-20-12-10.9-7.4-10-11.5-18-14-6.8-2.1-8.2.7-17-2-7.6-2.3-8.4-4.9-14-5-6.1-.1-7.5,3-13,2s-6.2-4.4-10-4c-5,.6-6,6.9-10,7-1.7,0-3.8-1.1-8-8-4.7-7.6-6.8-13.8-11-26-3-8.7-4.5-13.1-4-15,2.2-8.7,14.8-13.7,24-15,6.6-.9,13.7-.2,43,12,16.5,6.9,14.3,6.6,23,10,26.2,10.2,34.3,7.5,57,18,15.6,7.2,19.5,12.1,21,14,4.8,6.4,4.2,10.3,12,22,2.9,4.4,5.8,7.9,9,12,4.7,5.9,8.9,10.6,12,14"
          />
          <path
            id="cavicula_2"
            data-name="cavicula 2"
            class="akromioklavikulargelenk_right2020_cls1"
            d="M273,17.27c11.4,4.5,21,7.7,28,10,16.1,5.2,25.7,7.3,45,14,17.9,6.2,22.1,8.6,26,12,4.3,3.7,8.2,9.5,16,21,10.1,15,9.3,15.6,15,23,5.4,6.9,10.6,12,21,22,5.3,5.1-6.3-4.5,3,3.5"
          />
          <path
            id="cavicula_3"
            data-name="cavicula 3"
            class="akromioklavikulargelenk_right2020_cls1"
            d="M350,93.27c.5-3.4.5-8-2-12-2.1-3.3-4.6-4.2-9-8-2.5-2.1-5.7-5.3-9-10"
          />
          <path
            id="cavicula_4"
            data-name="cavicula 4"
            class="akromioklavikulargelenk_right2020_cls1"
            d="M372,109.27c.7-12.8-8.2-21-22-16"
          />
          <line
            id="cavicula_5"
            data-name="cavicula 5"
            class="akromioklavikulargelenk_right2020_cls1"
            x1="296.7"
            y1="19.77"
            x2="260"
            y2="10.27"
          />
        </g>
        <g id="shoulder_plate" data-name="shoulder plate">
          <path
            id="shoulder_plate_1"
            data-name="shoulder plate 1"
            class="akromioklavikulargelenk_right2020_cls3"
            d="M123.37,50.99c1.24-.37,2.45-.92,3.62-1.72,5.9-4.1,4.1-10,9-18,5.8-9.4,17.1-15.4,27-16,5.3-.3,11.2,1.8,23,6,9.9,3.5,12.8,5.8,20,6,6.8.2,8.4-1.7,12,0,4.4,2.1,4.1,5.9,10,17,4.9,9.3,8.5,12.9,7,15-2,2.8-10.4-1.6-12,1-1.4,2.2,3.4,7.9,7,11,1.9,1.6,6.4,5.2,14,7,7.9,1.9,9.4-.3,17,1,6.9,1.2,6.6,3.3,18,8,13.2,5.4,15.3,3.5,21,8,2.7,2.1,5.4,6.4,11,15,5.7,8.8,8.5,13.1,9,16,1.6,9.2-4.2,12.1-2,22,.4,2,2,8.9,7,11,3.3,1.4,5.9-.2,12-2,9.2-2.7,17.2-3.1,21-3,4.1.1,6.6,1,20,7,17.1,7.7,26.8,12.5,40,19,14.5,7.1,14.7,7.2,15,8"
          />
          <path
            id="shoulder_plate_2"
            data-name="shoulder plate 2"
            class="akromioklavikulargelenk_right2020_cls3"
            d="M261,169.27c-.6-3.6-1.7-8.5-4-14-.9-2.1-5.2-11.9-16-22-9.9-9.2-19.5-13.3-33-19-9.2-3.9-16.1-6.8-26-9-6.6-1.5-9.7-1.5-15-4-7.9-3.7-7.9-6.6-16-11-9.5-5.1-11.9-2.3-18-7-8.8-6.8-10-17.5-10-18-.7-7.9,2.7-13.9,4-16"
          />
          <path
            id="shoulder_plate_3"
            data-name="shoulder plate 3"
            class="akromioklavikulargelenk_right2020_cls3"
            d="M239,76.67c-3.2-.6-11-1.5-19,2.6-3.3,1.7-9.7,4.9-12,12-2.7,8.3,2.2,15.9,3,17,5.1,7.5,14.1,10.1,21,12,8.3,2.3,11.8,1.4,16,5,3.9,3.4,5.4,8.3,6,12"
          />
          <line
            id="shoulder_plate_4"
            data-name="shoulder plate 4"
            class="akromioklavikulargelenk_right2020_cls3"
            x1="433.3"
            y1="204.27"
            x2="239"
            y2="76.67"
          />
          <path
            id="shoulder_plate_5"
            data-name="shoulder plate 5"
            class="akromioklavikulargelenk_right2020_cls3"
            d="M154,215.27c34.4,0,62.2-53.5,57-107"
          />
          <path
            id="shoulder_plate_6"
            data-name="shoulder plate 6"
            class="akromioklavikulargelenk_right2020_cls3"
            d="M154.2,207.37c2.6-5.6,6.2-12.8,10.8-21.1,7-12.6,10.4-18.9,15-25,10.7-14.3,16.2-15,20-26,2.3-6.8.9-8.5,4-14,.9-1.6,6.7-11.5,18-15,6.6-2,16.1,0,21-3.6,3.3-2.5-.9-6.1,0-8.4"
          />
          <line
            id="shoulder_plate_7"
            data-name="shoulder plate 7"
            class="akromioklavikulargelenk_right2020_cls3"
            x1="239"
            y1="76.67"
            x2="207.2"
            y2="51.67"
          />
        </g>
        <g id="upper_arm" data-name="upper arm">
          <path
            id="upper_arm_1"
            data-name="upper arm 1"
            class="akromioklavikulargelenk_right2020_cls3"
            d="M1,230.27c1.2-16.3,5.2-20.4,9-64,2.1-25-1.2-29.7,3-52,3.8-20.6,6.6-35.6,19-46,10-8.4,21.9-10.1,42-13,21.1-3,31.6-4.5,38-4,3.75.3,7.67.82,11.38-.28"
          />
          <path
            id="upper_arm_2"
            data-name="upper arm 2"
            class="akromioklavikulargelenk_right2020_cls2"
            d="M156,189.27c3.5-13.8.9-21.5-2-26-2.7-4.2-4.8-4-11-11-6.1-6.8-6.2-9.3-12-17-2-2.6-6.7-7.5-16-17-12.6-12.9-13.7-13.7-17-18-3.2-4.2-5.5-7.8-10-15-3.8-6.1-6.9-11.3-9-15"
          />
          <path
            id="upper_arm_3"
            data-name="upper arm 3"
            class="akromioklavikulargelenk_right2020_cls2"
            d="M54,179.77c8.2-22.3,21.3-58.6,33-73.1"
          />
          <path
            id="upper_arm_4"
            data-name="upper arm 4"
            class="akromioklavikulargelenk_right2020_cls2"
            d="M92,221.27c8.6-10.1,15.9-18.1,28-24,9.3-4.6,18-6.3,24-7"
          />
          <path
            id="upper_arm_5"
            data-name="upper arm 5"
            class="akromioklavikulargelenk_right2020_cls2"
            d="M16.7,215.27c6.3-22-6.8,30.5-3.7,17,5.3-22.9,5.8-29.3,6-35,.2-8.3-.5-14.1-4-42-.8-6.4-2.1-16.5-3.7-29.2"
          />
          <path
            id="upper_arm_6"
            data-name="upper arm 6"
            class="akromioklavikulargelenk_right2020_cls3"
            d="M155,199.27c4.8-2.4,11.4-6.5,18-13,17.8-17.7,20.5-39.3,21-44,2.6-24.6-8.4-42.5-13-50-13.4-21.8-32.2-31.2-36-33-10.7-5.1-20.4-6.9-27-7.6"
          />
        </g>
      </g>
      <g id="Ebene_2" data-name="Ebene 2">
        <path
          id="cavicula_overlay"
          data-name="cavicula overlay"
          class="Clavicula2020Fill"
          style={{
            fill: props.colors.Clavicula2020Fill,
            stroke: props.colors.Clavicula2020Fill,
          }}
          d="M427,204.27c-2.9-3.3-7.5-8.5-13-15-11.6-13.8-12.9-16.5-24-30-14.7-17.8-16.2-17.7-22-27-5-7.9-8.8-13.9-12-23-3.2-8.9-2.5-11.5-6-16-5.1-6.5-9.1-4.6-20-12-10.9-7.4-10-11.5-18-14-6.8-2.1-8.2.7-17-2-7.6-2.3-8.4-4.9-14-5-6.1-.1-7.5,3-13,2s-6.2-4.4-10-4c-5,.6-6,6.9-10,7-1.7,0-3.8-1.1-8-8-4.7-7.6-6.8-13.8-11-26-3-8.7-4.5-13.1-4-15,2.2-8.7,14.8-13.7,24-15,6.6-.9,13.7-.2,43,12,16.5,6.9,14.3,6.6,23,10,26.2,10.2,34.3,7.5,57,18,15.6,7.2,19.5,12.1,21,14,4.8,6.4,4.2,10.3,12,22,2.9,4.4,5.8,7.9,9,12,4.7,5.9,8.9,10.6,12,14"
        />
        <path
          id="acromion_overlay"
          data-name="acromion overlay"
          class="Acromion2020Fill"
          style={{
            fill: props.colors.Acromion2020Fill,
            stroke: props.colors.Acromion2020Fill,
          }}
          d="M262.53,167.45c-.65.65-1.75.31-1.91-.6-2.66-15.03-11.69-28.99-30.03-41.38-15.67-9.67-31.55-16.89-47.78-20.01-11.27-1.32-20.48-5.16-27.08-12.19-5.14-4-11.94-6.62-19.96-8.22-14.01-10.46-17.52-22.7-6.98-37.32,5.61-7.99,4.99-25.75,30.69-31.75,3.4-.79,6.92-.79,10.31.03,7.03,1.7,15.9,5.05,25.1,8.68,3.36,2.15,10.98,3.02,18.47,2,3.57-.49,7.04,1.34,8.59,4.59l13.04,27.38c-.92,3.07-17.92-2.86-10.09,5.5,24.07,20.74,99.33,69.59,179.61,121.2-89.75-28.05-129.76-30.21-141.98-17.92Z"
        />
      </g>
    </svg>
  );
}
