import style from "./style.scss";

export default function ThoraxMitRasterInspirationApLiegend2101(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 454.75 542.58"
    >
      <g id="BG">
        <path
          id="bg"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls7"
          d="M430.65,536.05c7-3,9.51-12.28,9.51-12.28,5.71-8.79,6.22-14.78,5.49-18.72-.38-2.07-1.56-6.02-.68-11.02.46-2.61,1.38-5.46,1.38-5.46.71-2.2,1.41-3.69,2.24-6.15,0,0,.63-1.88,1.35-4.54,4.56-16.85,3.71-38.83,3.71-38.83-.52-13.5-1.85-10.99-1.88-21.17-.02-9.43,2.08-8.36,2.88-29.83.28-7.48.09-13.96-2.35-21.29-1.5-4.49-1.98-3.71-2.65-6.71-1.92-8.58,2.43-13.09,2.58-24.44.03-2.04-.06-4.23-.7-8.56-2.03-13.78-5.4-17.96-5.71-32.01-.21-9.6,1.33-9.46.91-19-.49-10.95-2.56-12.22-5.21-28.25-2.81-17.05-.75-17.35-3.77-26.48-3.04-9.19-5.81-10.94-8.1-20.27-2.22-9.07-.03-9.48-1.66-19.08-.97-5.69-3.35-19.72-13.34-30.92-3.44-3.85-5.95-5.32-8.39-10.2-2.92-5.83-1.72-10.18-3.48-15.09-3.34-9.34-7.98-15.59-11.99-20.81-13.15-17.13-19.23-26.08-27.7-33.25-4.08-3.45-12.09-10.14-23.97-15.88-7.64-3.7-8.58-2.99-15.46-6.73-10.59-5.76-15.04-14.03-27.9-17.21-5.42-1.34,2.79-4.63-3.1-8.72-16.52-11.49-24.56-17.55-26-18.1-14.13-5.42-15.55-.19-47.88-3.23-16.35-1.54-20.4-3.29-25.12.23-5.39,4.02-7.96,10.82-16.85,23.08-5.27,7.26-9.61,5.06-17.15,11.92-8.64,7.86-8.06,11.49-15.23,17.12-8.02,6.3-10.46,3.12-20.77,8.88-4.71,2.63-12.2,10.45-27,26-7.81,8.21-5.94,7.48-11.79,12.3-13.81,11.39-14.07,11.02-18.13,16.54-7.31,9.94-3.92,13.31-10.9,30.19-3.61,8.73-6.32,7.95-10.7,17.64-2.73,6.06-6.9,15.56-5.95,26.8.22,2.57.53,3.2.46,5.53-.21,7.14-3.44,10.47-7,18-1.45,3.07-4.93,10.57-6,20-.83,7.28.72,7.4-.25,13.94-1.26,8.43-3.83,8.25-6.56,18.69-2.08,7.94-4.08,15.58-.49,21.44.86,1.4,2,2.64,2.31,4.92.54,3.94-1.96,6.91-3.58,9.72-1.92,3.34-2.42,7.65-3.42,16.28-1.64,14.15,2.01,15.93,1,30-.72,10.1-2.57,8.75-3.51,20.23-1.08,13.19,1.33,15.41-.49,24.77-1.96,10.07-4.99,8.74-8.05,20.02-1.86,6.84-5.15,18.97-.49,31,2.43,6.28,5.27,8,5.55,13.98.31,6.66-2.93,10.64-4.65,15.07,0,0-6.58,12.54,6.65,32.93,24,37,392.12,38.95,420,27h-.02Z"
        />
      </g>
      <g
        id="underlays"
        class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls14"
      >
        <g id="Clavicula">
          <path
            class="Claviculae2101Fill"
            style={{
              fill: props.colors.Claviculae2101Fill,
              stroke: props.colors.Claviculae2101Fill,
            }}
            d="M15.53,42.85h.17s13.54.23,25.83,1c19.45,1.22,29.18,1.83,40,5,6.68,1.96,7.3,2.94,26,12,26.4,12.8,39.6,19.19,54,24,6.1,2.04,33,11,33,11,15.48,5.16,21.58,7.19,23.67,12.94,2.38,6.57-1.16,16.07-7.67,19.06-5.79,2.66-12.25-.6-17-3-7.04-3.56-7.8-6.69-14-11-7.41-5.14-11.6-4.33-23-8-8.09-2.6-14.39-6.07-27-13-16.92-9.3-17.95-12-31-19-14.89-7.99-27.43-11.92-31-13-11.14-3.36-28.95-7.25-52-6"
          />
          <path
            class="Claviculae2101Fill"
            style={{
              fill: props.colors.Claviculae2101Fill,
              stroke: props.colors.Claviculae2101Fill,
            }}
            d="M436.57,45.14c-11.52,2.3-20.82,3.95-27,5-14.07,2.39-17.25,2.59-23,5-7.52,3.15-7.4,5.04-20,13-9.88,6.24-16.01,10.11-25,13-3.72,1.19-12.97,4.08-25,4-8.83-.06-12.01-1.68-20,0-6.33,1.33-11.7,3.89-14,5-5.91,2.85-5.67,3.76-12,7-9.08,4.64-11.25,3.63-14,7-3.03,3.71-4.45,9.89-2,15,2.59,5.41,8.82,8.18,14,8,7.51-.26,9.25-6.58,21-12,4.6-2.12,7.66-2.69,13-4,3.44-.84,25.53-6.35,45-15,12.24-5.44,22.09-11.51,31-17,12.02-7.41,14.01-9.65,21-13,8.57-4.1,20-7.64,35-8"
          />
        </g>
        <path
          id="Trachea"
          class="Trachea2101Fill"
          style={{
            fill: props.colors.Trachea2101Fill,
            stroke: props.colors.Trachea2101Fill,
          }}
          d="M224.13,87.5c-2.76,4.54.44,8.7,2.03,20.27,1.09,7.99.28,15.24-1.35,29.73-.85,7.54-2.44,21.18-7.43,38.51-3.09,10.73-6.68,22.91-14.86,37.16-8.41,14.64-12.62,21.96-20.95,25-2.61.95-6.57,1.84-8.78,5.41-2.64,4.26-.83,9.15,0,13.51,3.37,17.66-8.45,31.2-5.41,33.11,2.85,1.79,13.32-10.06,17.57-14.86,11.08-12.54,14.56-19.45,25.68-34.46,20.44-27.6,26.04-27.69,28.38-27.03,2.79.79,3.47,3.39,6.76,8.78,5.07,8.3,7.95,9.48,20.95,22.97,20.34,21.11,22.2,27.48,25.68,26.35,6.45-2.1,8.47-26.84-2.03-35.14-4.52-3.57-9.01-2.23-12.16-6.76-3.1-4.45-1.15-9.22,0-18.92,1.74-14.65-1.51-13.78,0-28.38,1.58-15.35,5.19-16.42,4.05-26.35-.52-4.6-1.74-15.29-9.46-20.95-8.24-6.04-16.51-.94-20.95-6.76-1.83-2.4-1.56-4.77,0-18.92,1.62-14.7,2.39-22.09.68-24.32-5.4-7-23.55-5.91-28.38,2.03Z"
        />
        <g id="Lungenspitze">
          <path
            class="Lungenspitze2101Fill"
            style={{
              fill: props.colors.Lungenspitze2101Fill,
              stroke: props.colors.Lungenspitze2101Fill,
            }}
            d="M237.94,89.12c-3.29,7.99-25.35,13.19-44.9,5.14s-29.19-26.33-25.9-34.32,21.81-7.93,41.36.12c19.55,8.06,32.73,21.07,29.44,29.06Z"
          />
          <path
            class="Lungenspitze2101Fill"
            style={{
              fill: props.colors.Lungenspitze2101Fill,
              stroke: props.colors.Lungenspitze2101Fill,
            }}
            d="M250.44,83.95c3.29,7.99,21.71,9.58,41.26,1.53,19.55-8.06,32.83-22.72,29.54-30.71,0,0-21.81-7.93-41.36.12-19.55,8.06-32.73,21.07-29.44,29.06Z"
          />
        </g>
        <g id="Lungenflügel">
          <path
            class="Lungs2101Fill"
            style={{
              fill: props.colors.Lungs2101Fill,
              stroke: props.colors.Lungs2101Fill,
            }}
            d="M199.8,396.28s-5.63-.54-27.03.68c-7.3.41-12.22,1.66-21.62,4.05-2.62.67-13.88,3.68-29.05,10.14-7.16,3.04-20.1,8.63-35.14,18.92-10.88,7.45-21.89,14.99-32.43,28.38-9.88,12.54-16.07,20.41-20.95,33.11-2.77,7.22-4.42,14.18-8.78,14.86-4.07.64-8.9-4.46-10.81-9.46-2.02-5.31-.45-9.72.68-15.54,1.62-8.41.42-10.01.68-25.68.31-18.84,2.49-43.88,4.05-62.16,6.78-79.05,5.21-68.26,6.76-81.08,4.58-38.06,10.27-66.02,15.54-91.89,8.12-39.87,12.45-53.89,15.54-62.84,7.62-22.05,13.08-37.42,26.35-54.73,17.52-22.85,37.28-34.26,43.92-37.84,16.09-8.68,29.44-11.55,32.43-12.16,17.61-3.61,31.5-1.98,35.81-1.35,20.16,2.96,36.87,11.63,43.92,21.62.48.68,3.99,6.15,2.7,7.43-1.3,1.3-6.73-2.48-16.22-8.11,0,0-2.37-1.41-18.24-10.14-17.58-9.67-43.28-2.47-52.03,0-7.17,2.03-31.62,9.76-56.08,36.49-26.22,28.64-33.85,58.79-47.97,117.57-4.74,19.74-12.45,55.12-17.57,100-5.87,51.5-4.09,78.3-8.78,116.89-1.99,16.36-1.67,32.93-3.38,49.32-.33,3.21-1.31,11.86,0,12.16,1.21.28,2.95-6.86,8.11-16.89,5.22-10.16,10.88-17.32,14.86-22.3,2.03-2.54,6.34-7.77,12.16-13.51,12.87-12.69,25.04-19.99,37.16-27.03,18.07-10.49,32.57-16.23,37.84-18.24,5.33-2.04,16.97-6.27,32.43-9.46,20.43-4.21,29.45-2.88,35.81,1.35,2.2,1.46,6.8,5.08,6.08,6.76-.58,1.35-4.33.91-6.76.68Z"
          />
          <path
            class="Lungs2101Fill"
            style={{
              fill: props.colors.Lungs2101Fill,
              stroke: props.colors.Lungs2101Fill,
            }}
            d="M309.26,407.77c-.83,1.04-.7,2.39-.68,2.7.37,3.97,6.35,7.47,14.86,8.78h0c3.86,1.01,7.91,2.33,12.16,4.05,5.45,2.21,5.27,2.67,12.84,6.08,6.41,2.89,7.06,2.79,11.94,5.16,4.55,2.2,6.58,3.73,10.36,5.65,12.28,6.26,11.69,4.66,18.24,8.78,4.57,2.87,13.79,8.78,21.62,19.59,4.68,6.46,7.26,12.46,9.46,17.57,3.94,9.16,5.91,13.74,7.43,18.92,1.78,6.07,2,8.71,4.05,9.46,3.66,1.32,9.62-4.61,12.84-10.14,3.99-6.86,3.92-13.41,4.05-19.59.43-20.49,2.62-15.36,2.7-33.78.13-30.24-5.78-40.94-8.78-78.38-1.13-14.06-.29-12.46-1.35-33.11-.4-7.85-2.21-40.85-8.78-86.49-4.16-28.89-8.42-57.77-18.92-94.59-4.97-17.44-10.83-35.26-23.65-54.05-8.1-11.88-16.37-20.36-20.27-24.32-8.64-8.78-17.02-17.16-30.41-24.32-11.35-6.07-20.72-8.28-22.97-8.78-7.82-1.76-18.13-4.08-30.41-.68-8.08,2.24-13.51,5.99-17.57,8.78-2.25,1.55-12.15,8.38-15.54,16.22-1.71,3.97-1.9,9.04-2.69,11.17-.01.04-.06.17-.02.32.28.97,3.83.8,4.73.68,4.42-.61,6.24-8.7,8.78-13.51,6.19-11.72,22.32-14.68,27.03-15.54,14.08-2.58,25.12,3.01,39.19,10.14,19.42,9.83,31.71,22.09,35.81,26.35,15.66,16.28,26.2,35.06,35.81,61.49,3.89,10.69,10.17,28.94,22.97,120.95,6.53,46.96,9.81,70.75,10.14,85.81.59,26.84-1.78,36.39,2.7,58.11,2.92,14.16,6.64,23.26,5.41,38.51-.65,8.04-1.95,8.79-4.73,29.73-.88,6.67-1.39,11.37-2.7,11.49-1.89.16-2.81-9.41-7.43-20.95-2.22-5.55-8.26-20.62-21.62-31.08-3.82-2.99-10.88-6.04-25-12.16-14.2-6.16-13.63-5.04-25-10.14-10.04-4.5-15.69-7.71-27.7-12.16-9.8-3.63-16.73-5.45-18.92-2.7Z"
          />
        </g>
        <g id="winkel">
          <path
            class="Zwerchfellwinkel2101Fill"
            style={{
              fill: props.colors.Zwerchfellwinkel2101Fill,
              stroke: props.colors.Zwerchfellwinkel2101Fill,
            }}
            d="M18.05,466.55c-1.77,2.29-.45,3.47,0,20.95.11,4.39,1.04,8.81.5,13.17-.16,1.31-.41,2.74.17,3.05,1.38.74,6.05-5.56,7.43-7.43,3.25-4.39,5.19-7.96,8.11-13.51,7.09-13.48,7.24-14.45,6.76-15.54-2.46-5.57-18.14-6.94-22.97-.68Z"
          />
          <path
            class="Zwerchfellwinkel2101Fill"
            style={{
              fill: props.colors.Zwerchfellwinkel2101Fill,
              stroke: props.colors.Zwerchfellwinkel2101Fill,
            }}
            d="M422.78,477.36c-1.45,4.44,3.46,6.86,6.76,18.92,2.23,8.16,1.67,13.25,3.38,13.51,1.99.31,4.55-6.33,6.76-12.16,5.79-15.3,8.69-22.95,5.41-26.35-4.68-4.85-20.02-.89-22.3,6.08Z"
          />
        </g>
        <g id="Vessels_to_lung_periphery" data-name="Vessels to lung periphery">
          <path
            class="Vessels2101Fill"
            style={{
              fill: props.colors.Vessels2101Fill,
              stroke: props.colors.Vessels2101Fill,
            }}
            d="M277.51,198.31c3.59-1.3,6.31,6.26,14.19,6.76,7.02.44,12.51-5.06,12.84-5.41,6.31-6.51,3.06-13.95,8.11-16.89,4.76-2.77,13.66.32,14.86,4.73,1.57,5.71-10.93,9.45-11.49,19.59-.25,4.55,2.31,8.28,7.43,15.54,7.94,11.26,10.74,10.08,16.22,18.24,1.48,2.2,4.59,10.7,10.81,27.7,1.71,4.66,5.4,14.86,5.41,14.86-4.09,4.16-6.63,4.41-8.11,4.05-4.41-1.07-3.88-8.73-10.81-18.92-.95-1.4-7.44-10.78-11.49-9.46-2.05.67-1.71,3.49-4.73,5.41-3.67,2.33-7.63.35-8.11,1.35-1.24,2.59,26.32,13.12,25.68,24.32-.27,4.76-5.62,9.37-10.14,9.46-4.31.09-6.64-3.97-13.51-10.81-10.09-10.05-11.51-7.71-16.22-13.51-10.56-13.02-9.72-32.46-9.46-37.16.57-10.35,3.08-17.64-.68-20.27-3.38-2.37-7.86,1.81-11.49-.68-5.16-3.54-3.8-17.3.68-18.92Z"
          />
          <path
            class="Vessels2101Fill"
            style={{
              fill: props.colors.Vessels2101Fill,
              stroke: props.colors.Vessels2101Fill,
            }}
            d="M184.94,140.88c-5.24,4.81-1.38,12.59-1.35,32.43.01,10.36.03,20.21-3.38,31.08-4.72,15.07-10.72,15.21-18.24,32.43-5.98,13.69-2.21,13.64-8.78,33.11-3.88,11.51-8.26,24.46-17.57,39.19-7.55,11.94-14,18.17-11.49,22.97,1.8,3.45,7.64,5.03,11.49,3.38,4.31-1.85,3.74-6.71,7.43-14.19,5.68-11.49,12.4-10.87,18.24-21.62,1.89-3.48-2.27-11.14,4.73-18.92,12.16-13.51,2.47-28.43,8.11-35.81,4.4-5.76,5.94-2.93,13.51-10.14,6.74-6.41,6.98-10.04,18.92-32.43,7.04-13.21,8.26-14.38,8.11-18.24-.37-9.49-8.01-11.23-10.14-22.97-1.89-10.45,3.05-15.18-.68-19.59-3.87-4.58-14.01-5.18-18.92-.68Z"
          />
        </g>
      </g>
      <g id="Thorax">
        <path
          id="Thorax123"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M196.65,447.05c-2.13-.66-5.59-1.36-9,0-6.04,2.41-6.02,8.93-13,16-2.18,2.21-2.7,2.09-12,8-6.78,4.31-14.29,9.07-18,12-5.07,4-12.82,12.26-17,30"
        />
        <path
          id="Thorax122"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M191.65,471.05c-6.28,1.67-15.56,5-25,12-15.5,11.49-22.32,26.14-25,33"
        />
        <path
          id="Thorax121"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M262.59,452.1c3.12-.68,7.69-1.13,12.06.95,4.69,2.23,4.9,5.36,11,11,2.76,2.55,3.44,2.58,13,9,0,0,6.05,4.07,13,9,11.18,7.94,21.9,23.39,29,35"
        />
        <path
          id="Thorax120"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M263.65,472.05c5.56,3.78,10.48,5.82,14,7,8.01,2.68,11.08,1.63,16,5,4.03,2.77,3.86,4.78,9,11,1.42,1.72,3.29,3.81,14,13,4.69,4.02,10.76,9.14,18,15"
        />
        <line
          id="Thorax119"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="196.65"
          y1="442.05"
          x2="191.65"
          y2="479.66"
        />
        <line
          id="Thorax118"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="184.4"
          y1="492.73"
          x2="175.4"
          y2="512.23"
        />
        <line
          id="Thorax117"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="263.65"
          y1="472.05"
          x2="269.65"
          y2="504.11"
        />
        <line
          id="Thorax116"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="259.65"
          y1="430.05"
          x2="196.65"
          y2="424.05"
        />
        <path
          id="Thorax115"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M193.65,410.05c-4.76-.38-11.93-.39-20,2-10.56,3.13-16.97,8.68-27,17-10.22,8.47-20.67,16.66-31,25-22.57,18.22-25.1,18.24-37,29-10.36,9.37-19.28,19.16-27,29"
        />
        <path
          id="Thorax114"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M172.69,392.16c-3.61,4.85-9.43,11.65-18.04,17.89-7.39,5.36-13.43,7.85-18,10-10.1,4.75-18.73,11.5-36,25-29.52,23.07-58.04,51.88-66,60"
        />
        <path
          id="Thorax113"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M201.55,409.86c0,7.84-2.19,14.19-4.9,14.19"
        />
        <path
          id="Thorax112"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M261.63,410.05c6.85,1.76,16.54,4.39,28.02,8,17.41,5.48,28.67,9.02,39,14,24.99,12.04,39.75,30.18,51,44,8.55,10.51,19.72,26.41,29,48"
        />
        <path
          id="Thorax111"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M261.72,390.04c5.11-.21,12.64.08,20.93,3.01,10.91,3.86,14.94,9.38,26,17,10.34,7.13,11.41,5.46,32,16,18.12,9.28,27.18,13.91,36,21,3.37,2.71,18.5,15.17,31,37,6.87,11.99,10.74,22.98,13,31"
        />
        <path
          id="Thorax110"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M368.65,455.05c-.88,2.33-4.08,3.32-30,4-36.77.97-41.58-.72-43-4-2.59-5.97,6.99-15.04,8-16,14.78-14,37.7-7.4,39-7,14.63,4.46,28.24,17.06,26,23Z"
        />
        <path
          id="Thorax109"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M263.08,335.85c8.82,1.03,20.34,2.84,33.57,6.2,24.24,6.16,41.37,14.26,45,16,25.4,12.2,42.06,26.4,52,35,14.31,12.38,34.12,32.16,52.36,61.04"
        />
        <path
          id="Thorax108"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M272.65,351.05c2.99,3.54,7.61,7.77,14,9,7.54,1.45,10.81-2.57,18-1,6.95,1.51,8.47,6.26,16,12,8.89,6.77,16.94,7.92,24,10,16.77,4.94,29.16,15.5,45,29,0,0,37.22,31.63,53,76,1.85,5.2,3.95,12.23,3,21-1.57,14.46-10.44,24.52-15,29"
        />
        <path
          id="Thorax107"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M273.65,306.05c15.11,4.83,35.83,11.97,60,22,24.61,10.21,38.9,16.14,56,27,13.52,8.58,32.13,22.36,51.17,43.87"
        />
        <path
          id="Thorax106"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M275.65,288.05c5.01,4.61,12.97,10.68,24,14,8.52,2.57,13.64,1.92,22,3,10.43,1.35,19.09,5.25,36,13,17.26,7.91,31,14.21,48,26,26.27,18.22,32.23,25.3,34.36,28.87,3.02,5.06,5.71,11.3,8.64,27.13,4.08,22.02,7.45,40.26,4,64-4.71,32.38-19.36,56.05-29,69"
        />
        <path
          id="Thorax105"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M277.32,197.88c9.88,3.33,13.33,7.52,14.33,11.17,1.38,5.04-2.05,8.42-4,17,0,0-2.07,9.12,0,21,2.26,12.94,10.51,20.7,19,30,0,0,18.96,20.78,33,44,10.03,16.59,23.58,39.01,16,57-.31.73-.42.92-11,18-.31.51-12.7,20.52-13,21"
        />
        <path
          id="Thorax104"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M273.34,244.92c7.94.73,18.3,1.95,30.31,4.13,24.72,4.49,42.74,10.54,47,12,25.77,8.81,41.97,14.35,59,29,3.55,3.05,15.75,13.91,26.33,31.97,15.28,26.06,17.29,50.23,17.67,56.03,1.96,29.99-7.96,52.86-18,76-5.1,11.75-10.25,21.06-13.98,27.35"
        />
        <path
          id="Thorax103"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M269.53,276.18c3.12-4.32,8.43-10.1,16.4-12.46,7.94-2.35,14.38-.09,28.72,4.33,29.79,9.19,23.68,6.14,34,10,22.9,8.56,46.01,17.19,67,38,5.99,5.93,14.59,15.62,22.56,29.87"
        />
        <path
          id="Thorax102"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M276.38,210.19c9.42-1.54,21.79-2.78,36.27-2.14,7.38.33,23.72,1.52,42,7,17.95,5.39,51.61,15.48,74.98,47.77,20.4,28.19,22.09,58.28,22.02,70.23-.16,28.33-10.7,49.41-22,72-8.76,17.51-18.08,30.96-25,40"
        />
        <path
          id="Thorax101"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M279.65,221.05c10.06-.25,23.89.16,40,3,13.49,2.38,40.38,7.39,69,26,20.5,13.33,34.73,28.98,44.17,41.77"
        />
        <path
          id="Thorax100"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M262.65,172.05c10-1.12,23.7-2.19,40-2,10.35.12,20.23.23,32,2,10.81,1.63,34.24,3,57,20,9.49,7.09,22.7,17,39,37,18.35,22.52,15.97,48.43,16,54,.05,10.33-1.63,17.55-5,32-5.43,23.31-9.1,39.03-21,54-5.11,6.43-15.14,16.99-33,25"
        />
        <path
          id="Thorax99"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M265.65,189.05c4.87-.93,12.14-2.32,21-4,9.85-1.86,14.77-2.8,16-3,16.03-2.65,30.62.94,39,3,29.68,7.3,48.97,23.92,57,31,10.33,9.11,17.85,15.74,24,27,21.7,39.74-1.52,86.27-5,93-4.82,9.31-14.74,25.34-34,40"
        />
        <path
          id="Thorax98"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M274.65,154.05c7.25-2.01,17.14-4.16,29-5,25.64-1.82,45.13,3.57,50,5,18.36,5.39,51.64,15.05,66.43,45.48,17.01,34.99-2.39,72.72-7.43,82.52-8.72,16.97-20.11,28.32-28,35"
        />
        <path
          id="Thorax97"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M288.65,138.05c7.48-2.66,18.9-5.79,33-6,27.42-.4,47.3,10.52,57,16,9.33,5.27,35.54,20.58,51,53,5.07,10.64,16.35,34.29,10,62-4.32,18.84-15.23,32.8-24,44-8.07,10.31-16.04,17.89-22,23"
        />
        <path
          id="Thorax86"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M275.65,123.05c3.47-.84,8.65-1.98,15-3,8.3-1.33,10.07-1.06,16-2,14.55-2.3,16.07-5.79,24-6,9.14-.24,15.9,4.17,22,8,13.49,8.46,30.42,18.21,53.25,30.54"
        />
        <path
          id="Thorax95"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M272.65,106.05c13.53,2.09,24.03,1.17,31,0,12.64-2.12,17.1-5.65,28-5,6.93.41,12.38,2.62,23,7,10.1,4.17,21.34,8.81,34,18,6.71,4.87,17.44,12.66,26,25,15.38,22.18,21.21,55.42,9,83-5.59,12.63-13.31,20.33-25,32-16.01,15.98-31.95,26.02-43,32"
        />
        <path
          id="Thorax94"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M437.65,45.05c-11.52,2.3-20.82,3.95-27,5-14.07,2.39-17.25,2.59-23,5-7.52,3.15-7.4,5.04-20,13-9.88,6.24-16.01,10.11-25,13-3.72,1.19-12.97,4.08-25,4-8.83-.06-12.01-1.68-20,0-6.33,1.33-11.7,3.89-14,5-5.91,2.85-5.67,3.76-12,7-9.08,4.64-11.25,3.63-14,7-3.03,3.71-4.45,9.89-2,15,2.59,5.41,8.82,8.18,14,8,7.51-.26,9.25-6.58,21-12,4.6-2.12,7.66-2.69,13-4,3.44-.84,25.53-6.35,45-15,12.24-5.44,22.09-11.51,31-17,12.02-7.41,14.01-9.65,21-13,8.57-4.1,20-7.64,35-8"
        />
        <path
          id="Thorax93"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M426.93,363.16c-8.75,35.76-21.03,53.8-34.28,63.89"
        />
        <line
          id="Thorax92"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="440.82"
          y1="404.05"
          x2="409.79"
          y2="471.05"
        />
        <path
          id="Thorax91"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M417.72,195.11c-10.25,41.39-25.07,63.96-69.07,82.94"
        />
        <path
          id="Thorax90"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M402.79,142.58c-10.14,36.47-19.61,52.52-48.14,72.47"
        />
        <path
          id="Thorax89"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M411.24,166.18c-8.44,33.33-23.81,42.87-47.62,59.87"
        />
        <path
          id="Thorax88"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M317.65,122.05c17.14-13.41,20.91-24.32,21-32,.06-5.02-1.45-10.15-1-22,.52-13.76,2.91-16.13,4-17,5.66-4.5,15.99-.12,21,2,8.12,3.44,10.54,6.82,20,14,11.14,8.46,19,14.44,29,17,5.58,1.43,12.41,9,26,4.29"
        />
        <path
          id="Thorax87"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M387.76,112.74c-6.77,15.32-12.67,27.98-17.11,37.31-5.19,10.91-6.94,14.25-10,18-10.04,12.31-14.57,14.99-24.29,16.99"
        />
        <path
          id="Thorax86-2"
          data-name="Thorax86"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M328.65,58.05c60.25,31.52,34.97,104-5,104"
        />
        <path
          id="Thorax85"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M295.21,49.08c42.49,28.48,3.78,56.97,8.44,56.97"
        />
        <path
          id="Thorax84"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M279.65,79.05c4.19-.62,10.51-1.76,18-4,8.87-2.65,11.13-4.36,16.15-5.06,7.62-1.06,13.49,1.35,23.83,5.94,13.44,5.96,25.18,11.49,35.01,16.27"
        />
        <path
          id="Thorax83"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M270.65,88.05c4.92,2.07,12.41,4.33,21,3,8.9-1.38,9.62-5.17,18.04-6.46,8.07-1.24,10.65,1.74,28.96,5.46,11.14,2.26,11.36,1.39,16.88,2.76,8.28,2.05,19.72,6.96,32.23,19.92"
        />
        <path
          id="Thorax82"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M274.65,64.05c5.8-2.02,12.85-3.92,21-5,15.63-2.07,28.87-.27,37.94,1.79"
        />
        <path
          id="Thorax81"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M292.65,289.05c5.14.73,11.82,2.6,16,8,2.92,3.77,2.8,6.91,5,12,1.95,4.52,10.47,10.51,18.4,16.39"
        />
        <path
          id="Thorax80"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M303.97,316.45c2.57,3.32,5.95,8.57,7.68,15.6,2.08,8.45.19,12.99,2,22,.84,4.2,2.65,10.16,7,17"
        />
        <path
          id="Thorax79"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M315.65,327.05c5.42,5.41,10.33,9.32,14,12,6.23,4.55,14.96,9.97,15,10h0"
        />
        <path
          id="Thorax78"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M316.65,338.05c3.57,1.16,8.31,3.22,13,7,11.31,9.12,14.22,21.73,15,26"
        />
        <line
          id="Thorax77"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="320.65"
          y1="246.05"
          x2="346.22"
          y2="298.05"
        />
        <line
          id="Thorax76"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="336.36"
          y1="253.55"
          x2="356.24"
          y2="288.05"
        />
        <line
          id="Thorax75"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="315.65"
          y1="236.58"
          x2="330.65"
          y2="246.05"
        />
        <line
          id="Thorax74"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="315.65"
          y1="236.58"
          x2="312.35"
          y2="262.78"
        />
        <path
          id="Thorax73"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M324.65,187.05c-.37,2.99-1.32,7.08-4,11-3.73,5.46-8.09,6.62-8,10,.08,3.03,3.61,3.25,7,8,4.14,5.79,1.97,9.76,5,15,1.76,3.05,5.44,6.79,14,9"
        />
        <path
          id="Thorax72"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M313.66,181.12c.73,3.45,1.28,7.85.99,12.93-.23,4.07-.9,6.89-1.8,10.66-1.29,5.44-1.94,8.16-3.2,8.34-2.58.37-3.51-9.62-9-11-2.77-.69-4.86,1.26-13.23,6.69-4.95,3.21-9.01,5.64-11.48,7.09"
        />
        <path
          id="Thorax71"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M387.43,100.97c10.92,12.09,21.37,31.2,18.47,49.62"
        />
        <path
          id="Thorax70"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M305.27,49.08c17.74-4.2,58.43,11.71,82.16,51.89"
        />
        <path
          id="Thorax69"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M312.31,10.05c0,35,29.7,35,66.41,35"
        />
        <path
          id="Thorax68"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls11"
          d="M317.65,376.05c14,7,21,9,47.44,18"
        />
        <path
          id="Thorax67"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls11"
          d="M451.65,359.05c-11.64,13.87-32.43,36.05-72.5,36.05"
        />
        <path
          id="Thorax66"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M440.65,82.05c-48-23.92-20,24,5.24,18.92"
        />
        <path
          id="Thorax65"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M147.65,357.05c-8.93,6.44-22.75,16.28-40,28-17.41,11.82-29.05,19.27-46,32-30.37,22.8-34.7,30.19-37,35-8.16,17.05-7.47,36.39-7.4,37.93.22,4.88.85,8.96,1.43,11.89"
        />
        <path
          id="Thorax64"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M166.65,340.05c-20.45,8.53-37.27,17.04-50,24-15.03,8.21-28.51,15.65-45,28-9.17,6.87-22.13,17.39-36,32-20.06,21.13-25.47,33.36-27,37-7.18,17.11-5.6,27.78-5,31,1.41,7.66,4.62,13.45,7,17"
        />
        <path
          id="Thorax63"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M185.65,293.05c-21.65,4.27-38.83,10.13-51,15-11.52,4.61-19.9,8.85-36,17-7.46,3.78-21.94,11.26-40,22-25.89,15.4-30.62,20.54-33.8,24.7-5.24,6.85-8.42,13.82-13.2,24.3-5.82,12.76-9.27,23.44-10,26-9.01,31.68,37.64,88.18,40,91"
        />
        <path
          id="Thorax62"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M125.65,332.05c-13.87,3.24-25.02,7.48-33,11-9.15,4.03-24.52,10.94-41,25-10.8,9.21-17.8,17.84-21,22-10.76,13.99-18.25,23.74-20,37-2.03,15.43,4.79,27.54,18,51,8.35,14.83,16.78,26.26,23,34"
        />
        <path
          id="Thorax61"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M189.65,248.05c-7.98.21-19.09,1.07-32,4-13.5,3.06-23.05,7.03-35,12-14.89,6.19-25.65,11.94-37,18-10.34,5.52-17.28,9.64-28,16-15.8,9.37-23.76,14.13-26.84,17.55-8.98,9.97-11.69,21.16-15.16,35.45-1.25,5.16-4.22,17.68-4,32,.11,7.13,1.19,27.43,28,74,8.29,14.4,20.35,33.59,37,55"
        />
        <path
          id="Thorax60"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M193.37,262.66c-6.51-.84-16.24-1.45-27.72.39-6.2.99-14.55,2.96-36,13-16.61,7.78-18.19,9.68-42,22-23.92,12.38-26.68,12.71-35,19-8.23,6.22-18.35,13.87-24.96,25.78-15.6,28.13-1.8,62.4,2.96,74.22,1.65,4.11,5.32,12.58,31,48,13.73,18.94,25.58,34.31,34,45"
        />
        <path
          id="Thorax59"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M184.65,203.05c-6.74.73-16.5,2.11-28,5-6.3,1.58-15.06,4.1-50,19-27.98,11.93-33.27,14.97-38,18-14.56,9.33-23.49,18.4-26,21-6.82,7.08-15.02,15.6-21,29-1.88,4.21-8.47,19.95-7,40,1.92,26.14,16.41,44.04,35,67,12.19,15.06,23.88,26,32,33"
        />
        <path
          id="Thorax58"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M182.65,220.05c-2.41,1.59,4.87-1.32,0,0-6.87,1.86-12.4,1.19-15,1-6.76-.5-14.5,2-30,7-25.25,8.15-44.2,18-48,20-18.52,9.73-27.79,14.6-38,25-5.99,6.09-14.08,14.34-19.06,27.75-8.8,23.69-1.12,45.38,1.06,51.25,4.28,11.56,11.58,20.2,26,37,12.41,14.46,23.64,25.55,31.61,33.01"
        />
        <path
          id="Thorax57"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M96.65,362.05c-16.14-10.7-27.55-21.78-35-30-7.5-8.27-15.42-17.15-22-31-5.68-11.97-11.47-24.14-9-38,1.97-11.08,10.48-19.02,27-35,9.5-9.18,16.49-15.95,28-22,11.89-6.25,11.76-2.88,30-11,17.48-7.78,21.06-12.41,31-12,4.54.19,8.83,1.36,16,0,6.77-1.29,7.52-3.21,12-3,7.93.38,9.64,6.6,16,6,5.23-.49,10.45-5.3,10-9-.49-4.08-7.77-6.1-11-7-6.44-1.79-11.82-1.14-13-1-27.31,3.29-25.36.91-38,4-18.11,4.43-17.72,8.26-49,20-17.78,6.67-17.74,5.37-24,9-17.39,10.08-26.11,25.17-32,35-6.45,10.76-14.48,24.18-15,42-.76,26.34,15.37,46.09,30,64,13.42,16.43,27.31,27.35,37,34"
        />
        <path
          id="Thorax56"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M208.65,137.05h-27c-5.92.01-18.65.05-28,1-8.11.83-16.74,3.55-34,9-16.62,5.25-25.13,7.97-35,13-11.38,5.79-18.96,11.45-21,13-9.02,6.84-17.73,13.45-24,25-2.28,4.2-3.85,8.8-7,18-3.04,8.88-5.35,17.12-6,20-6.47,28.56,28.7,66.37,33,71,6.86,7.38,18.21,18.1,35,28"
        />
        <path
          id="Thorax55"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M209.65,150.05c-4,1-9.46,2.18-16,3-2.93.37-10.74,1.26-22,1-15.75-.36-17.03-2.47-26-2-6.79.35-12.15,1.88-25,7-25.18,10.04-37.78,15.06-49,23-16.08,11.37-25.33,17.92-30,30-10.46,27.09,11.41,58.02,22,73,10.82,15.3,22.64,25.64,31,32"
        />
        <path
          id="Thorax54"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M16.65,43.05h.17s13.54.23,25.83,1c19.45,1.22,29.18,1.83,40,5,6.68,1.96,7.3,2.94,26,12,26.4,12.8,39.6,19.19,54,24,6.1,2.04,33,11,33,11,15.48,5.16,21.58,7.19,23.67,12.94,2.38,6.57-1.16,16.07-7.67,19.06-5.79,2.66-12.25-.6-17-3-7.04-3.56-7.8-6.69-14-11-7.41-5.14-11.6-4.33-23-8-8.09-2.6-14.39-6.07-27-13-16.92-9.3-17.95-12-31-19-14.89-7.99-27.43-11.92-31-13-11.14-3.36-28.95-7.25-52-6"
        />
        <path
          id="Thorax53"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M124.65,285.05c-8.18-7.79-16.54-16.12-25-25-17.87-18.77-24.66-27.02-27-30-9.65-12.31-14.55-18.65-18-28-3.12-8.44-7.67-20.77-3-33,1.17-3.07,2.26-4.37,25-24,17.18-14.82,20.77-17.71,27-18,4.82-.22,8.45,1.24,17,0,1.7-.25,6.28-.99,12-3,7.99-2.81,9.67-5.1,15-7,5.65-2.01,10.1-1.67,19-1,16.97,1.28,21.41,5.67,34,4,3.58-.47,11.18-1.48,12-5,.88-3.78-6.47-8.7-11-11-9.14-4.64-18.05-4.36-31-3-17.83,1.87-29.13,3.06-47,8-15.82,4.37-23.73,6.56-34,12-9.28,4.92-25.88,13.98-38,33-3.8,5.97-10.22,16.28-12,31-3.74,30.89,16.17,54.46,20,59,7.79,9.22,10.29,8.08,23,21,12.4,12.6,20.78,24.63,26,33"
        />
        <path
          id="Thorax52"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M123.1,220.15c-5.48-3.52-12.29-8.45-19.45-15.1-9.75-9.05-15.67-17.15-17-19-4.89-6.78-10.48-14.54-14-26-2.37-7.71-4.58-14.92-3.04-23.46,3.85-21.38,27.96-32.69,34.04-35.54,1.57-.74,1.08-.44,27-8,27.24-7.95,28.33-8.43,32-8,14.34,1.7,17.58,11.5,33,11,5.5-.18,13.37-1.7,14-5,.7-3.67-7.78-8.26-11-10-2.09-1.13-5.94-3-14-5-3.18-.79-10.64-2.64-18-3-10.49-.52-17.14,2.15-37,9-27.04,9.32-24,7.58-27.69,9.31-8.24,3.87-36.68,17.23-44.31,44.69-6.52,23.48,5.72,44.51,13,57,14.64,25.13,35.85,37.61,45.87,42.68"
        />
        <path
          id="Thorax51"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M145.65,152.05c-3.93-4.47-10.25-11.1-19-18-11.71-9.23-16.05-9.7-19-15-6.36-11.43,1.59-31.23,13.21-43.54,25.04-26.5,70.02-21.25,69.79-26.46-.1-2.18-7.98-3.6-12-4-8.9-.88-15.7,1.6-29,7-15.9,6.46-23.85,9.69-26,11-7.5,4.57-19.2,11.7-27,26-2.79,5.12-9.43,17.29-7,32,2.02,12.2,9.23,19.83,23,34,10.07,10.36,19.69,17.87,27,23"
        />
        <path
          id="Thorax50"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M186.65,141.05c2.15,8.13,2.27,14.63,2,19-.35,5.72-1.31,6.91-3,19-1.18,8.41-1.75,12.68-1.23,15.99,1.35,8.46,5.34,10.15,4.23,15.01-.96,4.19-4.3,4.65-11.77,11.07-5.94,5.11-11.55,9.93-14.23,16.93-2.69,7,.28,9.05-1,20-1.16,9.88-4.61,17.05-7,22-6.65,13.8-11.58,13.98-15.32,26.18-1.42,4.64-1.42,6.93-3.68,11.82-3.59,7.74-8.42,12.39-10,14-5.24,5.37-11.87,15.53-17.5,36.68"
        />
        <path
          id="Thorax49"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M201.65,142.05c-1.5,3.49-3.18,8.7-3,15,.16,5.46,1.55,5.91,2,12,.68,9.12-2.17,11.65-1,19,.69,4.37,2.41,7.95,4.15,10.67"
        />
        <path
          id="Thorax48"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M216.65,199.05c-.64,3.48-1.54,9.06-2,16-.78,11.74.49,15.13-.68,23.42-.91,6.43-2.8,11.22-6.53,20.53-5.7,14.2-7.92,15.62-11.79,25.05-6.5,15.87-7.52,29.74-8,37-.49,7.46-1.12,18.3,2,32,3.17,13.91,8.75,24.4,12.9,30.96"
        />
        <path
          id="Thorax47"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M185.56,248.21c-6.56,11.06-12.24,20.79-16.91,28.84-8.16,14.08-10.18,17.8-11.93,23.24-4,12.46-3.34,23.89-2.07,31.76"
        />
        <path
          id="Thorax46"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M144.65,322.05c-2.46,4.67-4.88,10.01-7,16-5.31,14.99-6.81,28.63-7,39"
        />
        <path
          id="Thorax45"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M176.65,331.05c-4.06,3.12-10.4,8.88-15,18-8.27,16.38-5.16,32.13-4,37"
        />
        <path
          id="Thorax45-2"
          data-name="Thorax45"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M181.65,336.05c-2.82,3.38-6.53,8.71-9,16-4.35,12.83-2.25,24.05-1,29"
        />
        <path
          id="Thorax44"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M166.65,289.05c0,17.69,9.39,32,21,32"
        />
        <path
          id="Thorax43"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M176.65,29.05c-4.17,1.12-10.5,3.35-17,8-17.75,12.72-21.64,32.6-22.32,37.71-.83,6.22-2.77,17.19,2.74,30.39,3.41,8.19,8.32,13.74,11.59,16.9"
        />
        <path
          id="Thorax42"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M166.38,53.95c-18.73,25.39,1.91,47.1,4.27,47.1"
        />
        <path
          id="Thorax41"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M208.65,72.99c0,1.4-28.75-34.94-87.79,2.53"
        />
        <path
          id="Thorax40"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M148.49,5.05c0,22.11-20.84,48-66.07,40"
        />
        <path
          id="Thorax39"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls11"
          d="M25.85,360.07c5.55,5.25,13.79,12.13,24.85,18.45,8.74,4.99,19.57,11.18,34.95,13.54,7.33,1.12,29.88,4.25,51-9,7.04-4.41,11.93-9.4,15-13"
        />
        <line
          id="Thorax38"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="55.8"
          y1="88.34"
          x2="84.86"
          y2="101.35"
        />
        <path
          id="Thorax37"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M34.65,80.97c22.3-22.22,28.96-13.02,17,17.55"
        />
        <path
          id="Thorax36"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M11.64,64.05c16.83,0,73.22,37.3,88.01,10"
        />
        <path
          id="Thorax35"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M16.65,80.05c6.7.19,10.7,2.3,13,4,1.53,1.13,2.54,2.24,9,11,5.84,7.92,5.99,8.33,7,9,3.28,2.19,9.59,3.16,23-4"
        />
        <path
          id="Thorax34"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M259.65,25.79c79-8.74,3.13,17.26,7,17.26"
        />
        <path
          id="Thorax33"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M207.65,29.05c-60-19-17,14,0,14"
        />
        <path
          id="Thorax32"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M192.22,38.28c-21.57,0-5.51,15.69-12.32,15.69"
        />
        <path
          id="Thorax31"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M266.65,5.05c4,18.1,7.37,19.55-17,18.1"
        />
        <path
          id="Thorax30"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M288.65,50.05c0-.54-8.64-40,35-.97"
        />
        <path
          id="Thorax29"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M257.65,50.05c2.12-2.5,6.23-6.58,12.62-8.88.74-.27,3.72-1.31,7.89-1.65,6.19-.51,14.28.58,14.49,2.53.21,2.02-8.22,3.16-20,9-7.14,3.54-12.53,7.33-16,10"
        />
        <path
          id="Thorax28"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M193.65,2.05c-2.36,13.15-.94,17.06,1,18,2.41,1.17,5.23-2.45,11-3,5.25-.5,9.82,1.82,13,4"
        />
        <circle
          id="Thorax27"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          cx="234.66"
          cy="27.29"
          r="6.24"
        />
        <line
          id="Thorax26"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="218.65"
          y1="50.22"
          x2="250.2"
          y2="50.08"
        />
        <path
          id="Thorax25"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M215.65,41.05c2.83,1.5,6.81,2.97,11,2,5.42-1.26,7.14-5.65,11-5,3.26.55,3.49,3.94,8,6,2.97,1.35,5.93,1.28,8,1"
        />
        <path
          id="Thorax24"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M226.68,77.57c0-1.24,13.49-19.06,26.97-2.25"
        />
        <path
          id="Thorax23"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M220.06,105.65c0-.88,13.62-10.81,29.59-1.6"
        />
        <path
          id="Thorax22"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M216.65,119.03h34.06"
        />
        <path
          id="Thorax21"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          d="M222.65,137.05c0,2.21,8.16,4,18.25,4"
        />
        <line
          id="Thorax20"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls9"
          x1="226.68"
          y1="77.57"
          x2="252.65"
          y2="80.05"
        />
        <line
          id="Thorax19"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls10"
          x1="209.69"
          y1="300.05"
          x2="261.63"
          y2="304.14"
        />
        <path
          id="Thorax18"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls10"
          d="M261.67,397.07c-4.59-1.88-11.47-3.95-19.66-3.32-6.35.49-9.22,2.27-15.36,3.29-5.51.92-13.55,1.21-24-2"
        />
        <path
          id="Thorax17"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls10"
          d="M262.81,341.1c-14.68-2.8-23.7-1.24-29.35.89-3.3,1.24-7.63,3.51-13.81,3.06-5.44-.4-8.13-2.59-10-1,0,0-1.4,1.19,0,13h0c-2.16,3.67-4.32,7.35-6.47,11.02h0"
        />
        <line
          id="Thorax16"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls10"
          x1="204.59"
          y1="376.05"
          x2="256.65"
          y2="380.05"
        />
        <path
          id="Thorax15"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls10"
          d="M263.08,335.85c-8.57-1.75-15.54-1.96-20.43-1.8-5.4.17-13.64.97-14,1-10.52,1.02-13.3,1.87-17,0-3.12-1.58-6.79-5.03-6.83-8.95-.05-4.02,3.74-5.3,5.83-10.05,1.4-3.18,2.16-8.17-.96-16"
        />
        <line
          id="Thorax14"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls10"
          x1="201.55"
          y1="77.57"
          x2="212.72"
          y2="110.05"
        />
        <line
          id="Thorax13"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls10"
          x1="196.65"
          y1="376.05"
          x2="187.65"
          y2="390.05"
        />
        <path
          id="Thorax12"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls13"
          d="M202.65,395.05c-3.35-1.92-8.49-4.29-15-5-10.86-1.19-18.93,4.42-24,5-21.84,2.48-28.48,5.03-45,13-8.47,4.09-36.48,15.14-60,40-27.33,28.89-31.99,57.55-38,56-4.94-1.28-3.83-21.18-1-61,3.7-52.11,5.55-78.16,7-91,6.25-55.42,9.38-83.13,17-122,15.76-80.39,30.03-103.17,36-112,6.73-9.96,23.09-32.05,51-49,18.18-11.04,30.57-13.41,34-14,4.6-.79,26.54-4.17,50,7,9.32,4.44,20.96,9.98,26,22,1.24,2.96,1.83,9.31,3,22,1.78,19.29.68,23.07,0,25-2.94,8.34-7.29,8.59-11,17-3.66,8.29-2.9,15.92-2,24,1.88,16.86,4.12,35.49,7,56"
        />
        <path
          id="Thorax11"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls13"
          d="M308.65,410.05c5.56,1.43,13.56,3.68,23,7,28.51,10.03,26.84,14.65,52,24,15.82,5.88,24,14,30,17,3.45,1.73-.43,5.27,1,11,2.05,8.25,6.48,10.47,11,18,7.41,12.34,5.36,22.78,8,23,2.55.21,6.21-9.38,9-24,6.9-36.17,1.46-66.48,0-75-5.63-32.75,1.89-8.26-8-102-7-66.36-10.72-100.25-21-135-10.78-36.44-22.07-58.25-37-77-15.85-19.9-32.08-31.67-48-39-12.65-5.83-24.82-11.23-40-8-15.81,3.36-30.67,15.19-36,30-3.19,8.87-3.06,19.07-3,24,.11,8.9,1.55,12.52,1,22-.47,8.16-1.91,11.99-3,18-2.76,15.17-.37,27.8,1,35,1.75,9.24,5.98,31.52,24,47,5.5,4.73,13.09,11.25,23,10,9.28-1.17,15.14-8.47,17-11"
        />
        <line
          id="Thorax10"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls13"
          x1="235.61"
          y1="213.06"
          x2="166.65"
          y2="294.05"
        />
        <line
          id="Thorax9"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls13"
          x1="235.13"
          y1="209.38"
          x2="294.65"
          y2="273.05"
        />
        <path
          id="Thorax8"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls13"
          d="M263.65,472.05c-1.05-16.71-2.21-41.67-2-72,.29-43.09.52-76.06,7-118,4.89-31.65,6.93-59.77,11-116,.56-7.79,1.57-24.23-7-29-6.01-3.34-20.3,4.39-25,7"
        />
        <path
          id="Thorax7"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls13"
          d="M250.2,130.99c13.63-13.94,4.23,110.22,9.45,110.22"
        />
        <path
          id="Thorax6"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls13"
          d="M225.29,67.58c14.36,89.24-23.72,168.47-50.64,178.47"
        />
        <path
          id="Thorax5"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls12"
          d="M222.65,209.05c4.24,7.27,9.74,18.08,14,32,1.73,5.64,4.05,13.35,5,23,1.35,13.67-1.11,20.38-4,39-2.78,17.89-4.89,31.47-4,49,1,19.65,5.18,32.78,7,38,2.94,8.45,6.32,15.21,9,20"
        />
        <line
          id="Thorax4"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls12"
          x1="207.65"
          y1="253.55"
          x2="201.54"
          y2="410.05"
        />
        <path
          id="Thorax3"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls12"
          d="M259.65,246.05c1.19,4.26,2.24,8.93,3,14,2.37,15.81,1.04,29.62-1,40"
        />
        <line
          id="Thorax2"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls8"
          x1="218.65"
          y1="67.58"
          x2="220.31"
          y2="170.47"
        />
        <path
          id="Thorax1"
          class="Thorax_mit_Raster_Inspiration_ap_liegend2101_cls8"
          d="M211.65,179.05c-1.82,2.32-4.31,6-6,11-1.88,5.56-1.28,8.36-3,13-1.18,3.19-3.57,7.56-9,12"
        />
      </g>
    </svg>
  );
}
