import style from "./style.scss";

function RechtesOberesSprunggelenkVdLiegend1458(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.75 489.54">
  
  <g id="upper_ankle" data-name="upper ankle">
    <g id="background">
      <path id="background_1" data-name="background 1" class="oberes_sprunkgelenk1458_vd_rechts_cls4" d="M72.11.02c-6.59,0-11.73,5.69-11.09,12.24,8.02,82.95,4.15,159.55-25.73,222.24-5.99,12.57-8.2,26.66-6.36,40.47,1.63,12.24,4.57,24.6,8.48,37.05,3.29,10.48,2.47,21.82-2.49,31.62l-17.51,34.57c-6.98,13.79-11.53,28.67-13.47,44l-3.73,29.56c-1.92,15.21,9.36,28.9,24.66,29.93l110.35,7.4c18.86,1.27,37.81-.22,56.25-4.4l21.54-4.89c21.97-11.65,26.47-32.05,22.1-56.88-.81-4.63.49-9.41,3.54-12.99,10.28-12.07,15.21-25.06,15.01-38.91-.06-3.64.76-7.26,2.57-10.43,14-24.54,15.39-51.17,8.43-79.18-20.02-84.95-31.23-177.19-36.43-274.41-.21-3.92-3.44-6.99-7.36-6.99H72.11Z"/>
    </g>
    <path id="tarsus1" class="oberes_sprunkgelenk1458_vd_rechts_cls8" d="M172.66,488.22c5.6-9,8.2-17.5,6.2-25.3-20-3.2-42.7-5.7-66.9-7.9-28.4-5.1-41.1,3.4-41.6,22.5"/>
    <path id="tarsus2" class="oberes_sprunkgelenk1458_vd_rechts_cls8" d="M65.36,479.22c1.4-6,3.3-11.7,5.6-16.9,1.7-3.7-.1-8.1-3.9-9.5l-23.5-9c-6.5-2.4-13.6,1.7-14.7,8.5-1.6,9.9-1.9,19.2-.1,27.5"/>
    <path id="tarsus3" class="oberes_sprunkgelenk1458_vd_rechts_cls8" d="M3.46,443.82l12.2,2.7c4.7,1.1,8.1,5.2,8.2,10-1.8,5.3-1.8,11.9.4,19.9"/>
    <path id="ulnar1" class="oberes_sprunkgelenk1458_vd_rechts_cls8" d="M233.56,347.02c-7.9-23.2-20.5-37.8-41.1-38.2"/>
    <path id="tarsus4" class="oberes_sprunkgelenk1458_vd_rechts_cls8" d="M11.36,406.12c7-19.4,19.5-19.4,32.8-16.4.2,0,.4.1.7.1l38.4,6.3c3.6.6,6.9,2.7,9,5.7l17.9,25.5c5.9,8.5.7,20.2-9.6,21.5l-14.1,1.7c-2.6.3-5.1,1.3-7.1,3-13.8,11.6-27,12.5-39.7,3.2-.5-.4-1-.8-1.5-1.3-7.6-7.6-17-13.7-31.3-15"/>
    <path id="talus" class="oberes_sprunkgelenk1458_vd_rechts_cls8" d="M115.36,423.52l1.3-1.8,51.1-57.5c3.9-4.3,14.5-19.9,15.5-27.7l3-23.4c5.2-32.7-18.5-40.6-55.1-37.1-27.7-3.2-42.4,5.8-49.5,21.9-2.3,11.6-6.9,20.9-14.5,27.3-4.8,4-8.1,9.6-8.7,15.8-.4,3.8-1.4,7.1-3.3,9.3-7.4,3.8-6.8,9.8-6.2,15.8-8,6.3-10.5,14.1-7.3,23.6,16.3,34.3,42.6,40.8,73.7,33.8h0Z"/>
    <path id="tarsus5" class="oberes_sprunkgelenk1458_vd_rechts_cls8" d="M145.26,485.32h0c4.3-.1,8.4-2.1,11.4-5.3,12.2-13.4,25.9-19.2,40.9-18.3,20.8-.4,23-20.1,16.9-48.4,16.4-14.7,15.6-39.2,9-66.9-2-17.5-9.9-29.5-27.6-32.6-14-3.9-31.5,2.9-54,24.8-17.7,15.7-33,22.5-45,16.9-14-4.7-19.7,5-19.1,25.9-1.7,6.3,4.3,18.6,21.9,39.9-1.7,10.5-.3,17.8,7.3,19.1,5.6,3.6,9.9,8.1,10.7,15.2-4.7,21.4,5.9,30.2,27.6,29.7h0Z"/>
    <path id="ulnar2" class="oberes_sprunkgelenk1458_vd_rechts_cls8" d="M183.36,6.32c8.2,80.8,13.6,158.7,9,226.1-2.9,31-4.7,60,0,76.5,6.7,22.6,20.5,35.3,41.1,38.2,4.2,15.5,10.9,10.2,19.1-7.9,2.8-16.7-2.6-37.6-11.2-60.2C229.76,204.82,221.86,111.82,217.76.02"/>
    <path id="radius" class="oberes_sprunkgelenk1458_vd_rechts_cls8" d="M88.46,7.82c.2,97.1.1,193.6-37.1,249.3-10.8,16.3-11,33.2-2.2,50.6,8.4,8.9,13.4,5.6,16.3-5.1,6-.6,11.8-9.5,17.4-20.8,3.5-17.8,33.9-19,79.3-10.7,7.9-.6,15.1,1.3,21.9,4.5,24.4-17,21.3-37.1,5.1-58.5-16.1-37.7-26.7-117.2-34.9-214.3"/>
  </g>
  <g id="overlays">
    <path id="upper_ankle_joint_overlay" data-name="upper ankle joint overlay" class="OSG1458Fill" style= {{
              fill: props.colors.OSG1458Fill,
              stroke: props.colors.OSG1458Fill,
              opacity:props.colors.OSG1458Fill,
            }} d="M81.66,297.92c7.85-17.89,24.58-24.91,49.5-21.9,22.19-2.47,39.02-.17,48.37,8.79,7.23,7.02,8.99,16.69,6.73,28.31,2.6-.75,5.96-.25,9.6.7-5.49-1.84-7.16-17.49-6.23-42.55l-5.57,4.35c-7.08-3.62-14.38-5.14-21.9-4.5-44.45-7.63-75.04-6.64-79.3,10.7-5.34,11.15-6.02,16.84-1.2,16.1h0Z"/>
    <path id="fibula_overlay" data-name="fibula overlay" class="distalFibula1458Fill" style= {{
              fill: props.colors.distalFibula1458Fill,
              stroke: props.colors.distalFibula1458Fill,
              opacity:props.colors.distalFibula1458Fill,
            }} d="M243.79,285.64c7.03,19.58,11.4,36.79,8.86,52.94l-5.57,11.09c-6.49,9.85-10.92,8.5-13.53-2.65-9.39-.78-17.98-4.28-25.55-11.33-12.72-12.87-18.87-29.01-18.6-48.35.1-6.77,4.15-12.76,10.38-15.42,11.42-4.89,22.85-4.25,34.26,2.18,4.52,2.55,7.99,6.65,9.75,11.54Z"/>
    <g id="beide_Malleolen" data-name="beide Malleolen">
      <path id="tibialmalleoli_overlay" data-name="tibialmalleoli overlay" class="TibialMalleolus1458Fill" style= {{
              fill: props.colors.TibialMalleolus1458Fill,
              stroke: props.colors.TibialMalleolus1458Fill,
              opacity:props.colors.TibialMalleolus1458Fill,
            }} d="M49.16,307.72c-4.22-7.85-6.19-16.27-6.26-25.18,11.19,1.31,18.3,8.61,22.56,20.08-3.07,10.46-7.95,14.22-16.3,5.1Z"/>
      <path id="fibialmalleoli_overlay" data-name="fibialmalleoli overlay" class="beideMalleolen1458Fill" style= {{
              fill: props.colors.beideMalleolen1458Fill,
              stroke: props.colors.beideMalleolen1458Fill,
              opacity:props.colors.beideMalleolen1458Fill,
            }} d="M250.49,337.63c1.21.81,1.64,2.4.96,3.7l-4.37,8.34c-5.86,9.29-10.49,9.28-13.53-2.65l-3.68-9.4c8.3-3.91,15.09-3.7,20.62,0h0Z"/>
    </g>
    <path id="tibialmalleoli_overlay-2" data-name="tibialmalleoli overlay" class="TibialMalleolus1458Fill" style= {{
              fill: props.colors.TibialMalleolus1458Fill,
              stroke: props.colors.TibialMalleolus1458Fill,
              opacity:props.colors.TibialMalleolus1458Fill,
            }} d="M49.78,308.18c-4.22-7.85-6.19-16.27-6.26-25.18,11.19,1.31,18.3,8.61,22.56,20.08-3.07,10.46-7.95,14.22-16.3,5.1Z"/>
    <path id="talus_overlay" data-name="talus overlay" class="Talus1458Fill" style= {{
              fill: props.colors.Talus1458Fill,
              stroke: props.colors.Talus1458Fill,
              opacity:props.colors.Talus1458Fill,
            }} d="M115.36,423.52l1.3-1.8,51.1-57.5c3.9-4.3,14.5-19.9,15.5-27.7l3-23.4c5.2-32.7-18.5-40.6-55.1-37.1-27.7-3.2-42.4,5.8-49.5,21.9-2.3,11.6-6.9,20.9-14.5,27.3-4.8,4-8.1,9.6-8.7,15.8-.4,3.8-1.4,7.1-3.3,9.3-7.4,3.8-6.8,9.8-6.2,15.8-8,6.3-10.5,14.1-7.3,23.6,16.3,34.3,42.6,40.8,73.7,33.8h0Z"/>
    <path id="clear_joint_view_overlay" data-name="clear joint view overlay" class="Gelenksicht1458Fill" style= {{
              fill: props.colors.Gelenksicht1458Fill,
              stroke: props.colors.Gelenksicht1458Fill,
              opacity:props.colors.Gelenksicht1458Fill,
            }} d="M82.86,281.82c8.2-6.82,9.21,2.09,9.21,2.09-4.08,2.73-7.48,7.65-10.41,14.01-1.66,11.07-6.87,20.76-16.2,28.85-12.31,3.38-15.84-5.66-16.3-19.05,7.76,7.9,13.32,6.72,16.3-5.1,7.26-1.77,12.73-9.87,17.4-20.8h0Z"/>
  </g>
</svg>
  );
}

export default RechtesOberesSprunggelenkVdLiegend1458;
