import style from "./style.scss";

function KniegelenkVdTepLeft2317(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326.78 625.33">
      <g id="underlay">
        <ellipse
          id="joint_gap_underlay"
          data-name="joint gap underlay"
          class="Gelenkspalt2317Fill"
          style={{
            fill: props.colors.Gelenkspalt2317Fill,
            stroke: props.colors.Gelenkspalt2317Fill,
          }}
          cx="158.08"
          cy="285.54"
          rx="142.2"
          ry="62.64"
          transform="translate(-30.62 19.25) rotate(-6.35)"
        />
      </g>
      <g id="TEP_Knee" data-name="TEP Knee">
        <g id="background_layer" data-name="background layer">
          <path
            id="background_lower_leg"
            data-name="background lower leg"
            class="Kniegelenk_vd_tep_left2317_cls2"
            d="M82.87,16.04c7.5-13.81,32.87-9.88,75-11,51.14-1.36,70.32-11.75,73,2,3.34,17.1,6.7,21.88,8,52,0,0,.9,18.84,5.92,35.68.72,2.42,1.58,4.83,2.67,7.48,5.7,13.87,9.49,13.87,17.41,31.84,2.99,6.79,8.01,18.36,11,34,2.84,14.87.71,17.01,4,33,3.3,16.08,5.82,15.89,7.32,26.62.86,6.13,4.06,28.97-8.32,42.38-10.5,11.39-27.36,10.7-42,10.11-7.8-.31-43.27-2.61-71.53-22.11-4.02-2.76-12.2-8.86-23.93-9.67-2.75-.19-9.37-.35-19.22,3.39-16.25,6.17-17.87,14.06-34.33,25.28-9.53,6.49-23.72,16.16-41.03,14.34-3.4-.36-16.16-1.71-22.8-11.29-3.99-5.77-4.15-12.49-4.29-16.67-.29-8.54,2.05-12.59,3.12-18.38,2.2-11.9-3.69-16.17-15.14-49.35-5.9-17.1-6.15-21.25-6.2-23.72-.33-15.59,6.93-27.44,9.62-31.72,6.03-9.57,12.19-13.83,18.53-19.5,20.96-18.72,30.4-40.96,38.19-57.71,4.04-8.68,8.68-18.8,12-33,2.45-10.45,1.32-10.91,3-14h0,0Z"
          />
          <path
            id="background_upper_leg"
            data-name="background upper leg"
            class="Kniegelenk_vd_tep_left2317_cls2"
            d="M149.86,624.04c32,2.04,48.16,2.85,56-7,5.2-6.53,3-12.35,3-34,0-16.4,2-31.93,6-63,3.79-29.45,7.45-43.93,12-57,3.89-11.16,9.92-25.58,12-25,1.83.51.25,12.53-1,22-2.46,18.66-4.92,22.01-3,30,1.81,7.55,4.45,6.37,9,17,5.8,13.55,5.98,25.84,6,31,.06,12.64-2.82,11.32-3,26-.17,13.76,2.34,16.84,0,26-2.7,10.57-7.65,12.78-6,17,3.3,8.44,27.37,10.61,33,2,1.81-2.76,1.27-5.94,1-8-.68-5.26,1.88-16.51,7-39,5.9-25.93,16.91-52.29,21-62,10.5-24.93,16.81-34.07,20-54,2.77-17.31.49-25.79-1-30-3.3-9.34-7.11-10.2-10-21-2.45-9.13-.63-11.9-4-23-3.84-12.64-7.85-14.54-9-15-5.22-2.09-9.63,2.95-12,1-3.07-2.53,3.66-11.13,5-24,1.83-17.58-7.66-31.14-9-33-2.33-3.23-7.9-11.01-18-15-11.52-4.55-22.99-1.84-35,1-14.24,3.36-18.44,7.41-27,5-7.1-2-13.22-7.32-14-8-7.42-6.47-6.82-10.33-12-12-5.41-1.74-11.59.67-15,2-5.39,2.1-6.28,4.09-10,4-3.19-.08-4.08-1.59-10-4-8.06-3.28-9.22-3.29-10-3-3.06,1.14-2.71,4.78-6,9-.87,1.11-3.33,3.98-11,7-9.3,3.66-18.21,4.36-27,5,0,0-15.22,1.1-41,5-3.22.49-8.71,1.39-13.42,5.57-.85.75-3.92,3.61-6.58,10.43-1.92,4.92-3.43,11.43-3,21,0,0,.81,20.6,16,47,5.76,10.02,4.23,4.94,26,36,0,0,15.53,21.97,21,42,3.19,11.7.23,9.44,4,32,3.82,22.85,7,27.05,7,42,0,12.52-2.23,11.35-2,25,.24,14.66,3.34,18.54,2,32-1.22,12.18-3.79,14.32-2,19,4.44,11.61,25.9,13.21,54,15h0Z"
          />
        </g>
        <g id="knee">
          <path
            id="tibia_outlines"
            data-name="tibia outlines"
            class="Kniegelenk_vd_tep_left2317_cls5"
            d="M206.86,616.04c1.03-29.1,3.06-53.48,5-72,2.13-20.36,4.18-34.02,7-48,3.08-15.28,5.23-25.95,10-38,7.6-19.22,15.22-25.21,28-44,13.93-20.48,39.33-57.82,33-96-2.81-16.93-10.1-23.42-12-25-11.37-9.45-27.9-7.87-37-7-20.99,2.01-26.07,10.5-38,6-5.58-2.1-12.68-7.06-18-12-6.6-6.13-6.92-8.95-11-10-8.39-2.15-12.38,8.42-23,8-9.37-.37-12.36-8.84-19-7-5.44,1.51-4.65,7.53-11,13-5.67,4.88-10.85,4.02-28,6-10.36,1.2-17.24,2.53-30,5-17.19,3.33-25.87,5.07-30,9-8.88,8.46-8.42,22.02-8,34,.14,4.01,1.13,24.21,15,46,6.4,10.06,8.27,9.02,18,22,3.85,5.14,14.73,20.21,23,40,7.58,18.15,9.59,32.17,13,56,3.38,23.65,3.78,41.91,4,54,.4,21.76-.67,40.45-2,55"
          />
          <path
            id="femur_outlines"
            data-name="femur outlines"
            class="Kniegelenk_vd_tep_left2317_cls5"
            d="M230.86,7.04c4.65,21.56,6.85,39.37,8,52,1.68,18.39.6,20.1,3,29,4.21,15.64,9.81,18.86,23,46,6.07,12.49,9.1,18.73,11,26,4.39,16.79.44,20.66,4,41,3.3,18.85,7.28,18.94,8,33,.49,9.56,1.3,25.51-9,36-8.32,8.47-19.8,9.21-32,10-17.48,1.13-30.92-2.89-41-6-13.21-4.08-32.04-11.76-49-20-6.46-3.14-12.52-6.29-21-6-6.67.23-12.66,2.51-23,9-19.08,11.98-18.3,16.52-31,24-6.11,3.6-26.45,15.57-45,8-2.39-.98-11.84-4.83-16-14-4.75-10.46,2.59-16.37,2-33-.23-6.32-3.48-15.21-10-33-8.01-21.87-10.11-22.91-11-32-.46-4.65-1.54-17.3,5-30,3.88-7.53,8.85-12.01,11-14,22.63-20.98,42.24-54.27,50-70,4.92-9.98,11.35-25.62,15-47"
          />
          <path
            id="fibula_outlines"
            data-name="fibula outlines"
            class="Kniegelenk_vd_tep_left2317_cls5"
            d="M241.86,604.04c4.78-16,6.44-29.88,7-40,1.05-19.07,2.01-36.37-6-55-3.26-7.59-6.42-11.7-8-21-1.73-10.21.3-15.69,2-28,3.9-28.27-2.49-30.77,3-50,1.78-6.22,4.73-16.2,13-26,4.5-5.33,9.67-8.89,20-16,18.16-12.5,22.46-11.51,24-11,4.6,1.52,6.82,6.14,10,13,4.87,10.5,4.18,15.74,7,27,4.35,17.38,9.11,17.38,11,30,.46,3.08,1.41,11.33-3,28-5.49,20.76-12.33,28.22-21,47-8.44,18.28-11.9,32.7-17,54-3.4,14.21-6.87,32.1-9,53"
          />
          <path
            id="tibia_8"
            data-name="tibia 8"
            class="Kniegelenk_vd_tep_left2317_cls5"
            d="M38.86,306.04c44.92,7.36,81.7-4.33,82-22"
          />
          <path
            id="tibia_6"
            data-name="tibia 6"
            class="Kniegelenk_vd_tep_left2317_cls5"
            d="M283.6,299.93c-44.05,15.48-80.32,7.73-80.74-7.89"
          />
          <path
            id="fibula_4"
            data-name="fibula 4"
            class="Kniegelenk_vd_tep_left2317_cls7"
            d="M236.86,460.04c4.83,0,8.74,25.22,8.74,56.39"
          />
          <path
            id="fibula_3"
            data-name="fibula 3"
            class="Kniegelenk_vd_tep_left2317_cls7"
            d="M306.86,469.04c-1.47.35-4.17-21.18,7-49"
          />
          <path
            id="fibula_2"
            data-name="fibula 2"
            class="Kniegelenk_vd_tep_left2317_cls7"
            d="M252.86,601.04c4.8-6.8,12.74-53.27-7.26-84.61"
          />
          <path
            id="fibula_1"
            data-name="fibula 1"
            class="Kniegelenk_vd_tep_left2317_cls7"
            d="M260.86,609.04c1.71-3.34,12.04-39.59,27.25-80"
          />
          <path
            id="tibia_5"
            data-name="tibia 5"
            class="Kniegelenk_vd_tep_left2317_cls7"
            d="M80.86,446.04c16.03,0,29,76.04,29,170"
          />
          <path
            id="tibia_4"
            data-name="tibia 4"
            class="Kniegelenk_vd_tep_left2317_cls7"
            d="M290.78,327.01c-13.17,4.88-22.17,31.26-13.41,53.29"
          />
          <path
            id="tibia_3"
            data-name="tibia 3"
            class="Kniegelenk_vd_tep_left2317_cls7"
            d="M53.86,353.65c26.91-4.91,51.55-6.63,60,0"
          />
          <path
            id="tibia_2"
            data-name="tibia 2"
            class="Kniegelenk_vd_tep_left2317_cls7"
            d="M142.36,341.04c0-14.03,98.87,12.61,118.5,4"
          />
          <path
            id="tibia_1"
            data-name="tibia 1"
            class="Kniegelenk_vd_tep_left2317_cls7"
            d="M120.86,284.04c4.77-6,53.94,33,77,12.12"
          />
          <polyline
            id="fermur_2"
            data-name="fermur 2"
            class="Kniegelenk_vd_tep_left2317_cls7"
            points="107.1 260.84 104.86 241.48 95.36 227.04 101.86 213.21 101.86 191.04 101.86 182.04 120.86 186.54 142.36 182.04 157.77 187.04 169.86 191.04"
          />
          <path
            id="fermur_1"
            data-name="fermur 1"
            class="Kniegelenk_vd_tep_left2317_cls7"
            d="M46.86,98.39c24.32,0,44-40.86,44-91.35"
          />
        </g>
        <g id="TEP">
          <path
            id="TEP_lower_leg"
            data-name="TEP lower leg"
            class="Kniegelenk_vd_tep_left2317_cls6"
            d="M310.32,290c-5.17-4.52-11.67-1.68-26.03-1.53-12.77.13-12.84-2.07-27.56-3.06-15.03-1.02-26.07.52-44.4,3.06-22.52,3.13-22.11,4.9-35.22,6.12-17.46,1.64-21.1-1.23-47.47-3.06-25.15-1.75-16.68,1.22-68.9,1.53-19.72.12-31.87-.24-36.75,7.66-2.89,4.69-2.9,11.76,0,16.84,1.57,2.75,4.83,6.42,15.31,9.19,19.91,5.27,27.59-2.83,41.34,1.53,4.94,1.57,4.56,2.8,22.97,19.9,25.36,23.56,29.3,24.2,33.69,33.69,1.12,2.43,2.79,11.83,6.12,30.62,4.23,23.87,4.73,30.04,10.72,35.22,5.76,4.98,15.21,7.85,22.97,4.59,5.29-2.22,7.64-6.68,12.25-15.31,5.62-10.52,8.03-20.18,9.19-26.03,2.85-14.43-.04-15.42,3.06-26.03,3.43-11.75,9.64-19.69,12.25-22.97,7.2-9.04,9.38-7.21,18.37-16.84,12.75-13.66,12.15-21.37,21.44-24.5,4.77-1.61,4.53.56,16.84,0,10.84-.49,18.39-2.51,26.03-4.59,9.81-2.68,14.76-4.09,16.84-7.66,3.19-5.44,1.77-14.14-3.06-18.37h0Z"
          />
          <path
            id="TEP_upper_leg"
            data-name="TEP upper leg"
            class="Kniegelenk_vd_tep_left2317_cls6"
            d="M219.76,65.64c-3.7-1.34-5.63-.77-19.64,1.51-22.79,3.7-34.18,5.55-43.8,6.04-16.05.81-17.69-1.35-24.17,1.51-8,3.53-12.49,10.96-21.15,25.68-12.21,20.76-18.31,31.14-19.64,43.8-.77,7.34-.17,13.93-4.53,16.61-4.83,2.98-9.9-2.47-18.12-1.51-11.8,1.37-19.2,14.64-21.15,18.12-6.29,11.28-3.85,18.48-4.53,40.78-1.15,37.6-9.07,48.36,0,58.91,5.66,6.59,14.27,8.8,21.15,10.57,13.11,3.38,23.83,1.1,28.7,0,9.71-2.18,26.06-5.86,31.72-18.12,2.32-5.03,1.55-12.92,0-28.7-1.42-14.45-3.3-19.86,0-22.66,2.97-2.52,6.39.27,19.64,1.51,3.77.35,11.89,1.11,21.15,0,13.28-1.6,18.26-5.77,21.15-3.02,2.69,2.57-.61,7.18-1.51,18.12-.55,6.74-1.5,18.26,4.53,27.19,2.94,4.36,8.5,7.43,19.64,13.59,7.5,4.15,11.25,6.23,16.61,7.55,11.46,2.83,20.78.5,22.66,0,5.46-1.45,16.8-4.45,21.15-13.59,2.14-4.5,1.22-7.61,0-21.15,0,0-.29-3.17-1.51-24.17-1.88-32.18,3.43-37.65-3.02-48.33-5.04-8.35-9.04-6.27-13.59-15.1-5.26-10.19-1.54-16.05-3.02-33.23-1.37-15.9-5.98-27.29-9.06-34.74-5.34-12.92-9.77-23.61-19.64-27.19l-.02.02Z"
          />
        </g>
      </g>
      <g id="overlay" class="Kniegelenk_vd_tep_left2317_cls4">
        <g id="TEP_overlays" data-name="TEP overlays">
          <path
            id="TEP_lower_leg_overlay"
            data-name="TEP lower leg overlay"
            class="TEP2317Fill"
            style={{
              fill: props.colors.TEP2317Fill,
              stroke: props.colors.TEP2317Fill,
            }}
            d="M310.32,290c-5.17-4.52-11.67-1.68-26.03-1.53-12.77.13-12.84-2.07-27.56-3.06-15.03-1.02-26.07.52-44.4,3.06-22.52,3.13-22.11,4.9-35.22,6.12-17.46,1.64-21.1-1.23-47.47-3.06-25.15-1.75-16.68,1.22-68.9,1.53-19.72.12-31.87-.24-36.75,7.66-2.89,4.69-2.9,11.76,0,16.84,1.57,2.75,4.83,6.42,15.31,9.19,19.91,5.27,27.59-2.83,41.34,1.53,4.94,1.57,4.56,2.8,22.97,19.9,25.36,23.56,29.3,24.2,33.69,33.69,1.12,2.43,2.79,11.83,6.12,30.62,4.23,23.87,4.73,30.04,10.72,35.22,5.76,4.98,15.21,7.85,22.97,4.59,5.29-2.22,7.64-6.68,12.25-15.31,5.62-10.52,8.03-20.18,9.19-26.03,2.85-14.43-.04-15.42,3.06-26.03,3.43-11.75,9.64-19.69,12.25-22.97,7.2-9.04,9.38-7.21,18.37-16.84,12.75-13.66,12.15-21.37,21.44-24.5,4.77-1.61,4.53.56,16.84,0,10.84-.49,18.39-2.51,26.03-4.59,9.81-2.68,14.76-4.09,16.84-7.66,3.19-5.44,1.77-14.14-3.06-18.37h0Z"
          />
          <path
            id="TEP_upper_leg_overlay"
            data-name="TEP upper leg overlay"
            class="TEP2317Fill"
            style={{
              fill: props.colors.TEP2317Fill,
              stroke: props.colors.TEP2317Fill,
            }}
            d="M219.76,65.64c-3.7-1.34-5.63-.77-19.64,1.51-22.79,3.7-34.18,5.55-43.8,6.04-16.05.81-17.69-1.35-24.17,1.51-8,3.53-12.49,10.96-21.15,25.68-12.21,20.76-18.31,31.14-19.64,43.8-.77,7.34-.17,13.93-4.53,16.61-4.83,2.98-9.9-2.47-18.12-1.51-11.8,1.37-19.2,14.64-21.15,18.12-6.29,11.28-3.85,18.48-4.53,40.78-1.15,37.6-9.07,48.36,0,58.91,5.66,6.59,14.27,8.8,21.15,10.57,13.11,3.38,23.83,1.1,28.7,0,9.71-2.18,26.06-5.86,31.72-18.12,2.32-5.03,1.55-12.92,0-28.7-1.42-14.45-3.3-19.86,0-22.66,2.97-2.52,6.39.27,19.64,1.51,3.77.35,11.89,1.11,21.15,0,13.28-1.6,18.26-5.77,21.15-3.02,2.69,2.57-.61,7.18-1.51,18.12-.55,6.74-1.5,18.26,4.53,27.19,2.94,4.36,8.5,7.43,19.64,13.59,7.5,4.15,11.25,6.23,16.61,7.55,11.46,2.83,20.78.5,22.66,0,5.46-1.45,16.8-4.45,21.15-13.59,2.14-4.5,1.22-7.61,0-21.15,0,0-.29-3.17-1.51-24.17-1.88-32.18,3.43-37.65-3.02-48.33-5.04-8.35-9.04-6.27-13.59-15.1-5.26-10.19-1.54-16.05-3.02-33.23-1.37-15.9-5.98-27.29-9.06-34.74-5.34-12.92-9.77-23.61-19.64-27.19l-.02.02Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default KniegelenkVdTepLeft2317;
