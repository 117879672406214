import { useTranslation } from "react-i18next";

function AddEmergencyPatientButton() {
  const { t, i18n } = useTranslation(["emergencyjurnal", "common"]);
  return (
    <button className="btn btn-primary">
      {t("emergencyPatient.buttonText")}
      <i className="fas fa-ambulance ml-1"></i>
    </button>
  );
}
export default AddEmergencyPatientButton;
