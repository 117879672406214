import style from "./style.scss";

export default function LinkesKniegelenkStressaufnahmeLatLiegend2357(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.44 637.87">
      <g id="knee_stress" data-name="knee stress">
        <g id="background_layer" data-name="background layer">
          <path
            id="background_leg"
            data-name="background leg"
            class="kniegelenk_lat_links_stress2357_cls2"
            d="M234.26,623.27c9.33-51.42,16.91-103.62-8.38-170.58-10.09-12.77-16.43-26.91-18.53-42.6-6.04-28.85-3.23-40.05,6.02-38.39l26.51-2.89c-3.14-8.3,1.99-16.09,10.11-23.71l17.38-21.13c-22.21-5.89-41.14-16.02-47.49-42.44,2.76-33.56.89-61.88-15.4-73.87C152.49,158.37,81.39,118.12.64,82.44L41.64,1.33l194.15,90.27,84.74,25.47c29.73,15.43,47.9,43.61,54.24,84.87.49,41.2-8.07,79.51-49.32,107.34,5.57,2.96,13.61,1.2,23.6-4.25,18.63-6.04,34.99-3.94,48.45,8.61l7.71,13.64,10.03,17.33c3.1,8.66,2.61,16.12-1.73,22.29-.94,5.87-.58,10.62,4.55,11.24,7.66,1.53,11.93,7.09,13.4,15.98,8.7,8.07,15.22,17.23,14.4,30.1l-3.89,59.74c-2.63,25.1,6.07,76.04,18.01,134.37l-109.36,19.15-37.37-152.2c-9.54-17.8-19.92-34.71-31.72-50.13l-5.35,66.91,16.42,118.48-58.34,2.73h0Z"
          />
          <path
            id="background_patella"
            data-name="background patella"
            class="kniegelenk_lat_links_stress2357_cls2"
            d="M367.01,118.89c-8.29-2.39-10.96,3.45-9.1,15.9l16.86,30.45c5.48,19.92,10.93,38.48,15.95,41.15l37.37,18.47c7.08.4,8.92-5.86,10.16-12.89,1.41-23.49-3.28-43.73-12.55-61.51-3.59-9.86-11.13-16.61-22.24-20.54l-36.45-11.03h0Z"
          />
          <path
            id="background_patella_2"
            data-name="background patella 2"
            class="kniegelenk_lat_links_stress2357_cls2"
            d="M413.5,134.39c-4.48-3.13-5.57-2.18-17.27-6.35-20.78-7.4-27.18-13.89-33.75-10.37-4.41,2.36-5.76,7.55-5.93,8.25-.6,2.46-.6,5.79,4.54,15.7,4.43,8.55,6.31,9.71,10.14,16.87,3.78,7.08,5.74,13.06,6.77,16.27,3.57,11.09,2.78,15.02,7.16,23.27,1.5,2.83,2.9,5.46,5.55,8.36,6.18,6.74,11.23,6.24,26.33,13.03,11.31,5.08,12.78,7.3,15.59,6.11,6.8-2.86,5.98-19.02,5.47-29.16-.2-3.97-1.19-19.26-9.11-38.28-4.57-10.97-7.73-18.28-15.49-23.7h0Z"
          />
          <path
            id="background_triangle"
            data-name="background triangle"
            class="kniegelenk_lat_links_stress2357_cls2"
            d="M177.43,206.39h.02c-.58-2.5-5.37-1.97-14.46-6.63-1.67-.85-2.88-1.6-4-2.26-9.78-5.71-18.44-11.19-18.79-11.41-3.14-1.99-10.08-5.61-23.96-12.85-24.86-12.97-24.73-12.19-31.85-16.68-11.12-7.02-14.92-10.99-16.31-9.41-1.29,1.46,2.59,6.02,24.73,35.29,15.51,20.5,23.38,30.92,29.16,39.19,4.49,6.42,9.81,12.32,13.67,19.14,3.02,5.34,6.25,8.46,6.25,8.46,0,0,0-6.63,11.97-23.04,7.86-6.6,9.11-10.94,23.57-19.8h0Z"
          />
        </g>
        <path
          id="fibula"
          class="kniegelenk_lat_links_stress2357_cls5"
          d="M234.26,623.27c3.22-11.54,6.57-26.68,8.2-44.66,1.58-17.34.91-30.09,0-47.39-.84-15.92-1.59-29.17-5.47-46.48-2.38-10.61-4.42-19.46-10.03-30.08-5.59-10.59-8.02-10.26-12.76-20.05-4.76-9.83-5.91-17.79-8.2-33.72-3.27-22.71-.47-26.08.91-27.34,1.91-1.74,3.36-1.53,16.41-2.73,18.14-1.68,18.31-2.37,22.78-1.82,11.14,1.37,18.97,6.77,20.96,8.2,2.68,1.93,14.17,10.68,17.32,25.52,1.29,6.1.24,8.84-1.82,23.7-.72,5.17-1.69,14.99-3.65,34.63-2.08,20.93-3.12,31.4-2.73,41.01.16,3.96,1.32,12.97,3.65,30.99,1.69,13.11,1.7,12.38,4.56,33.72,2.45,18.28,3.01,23.06,4.56,32.81,1.4,8.82,2.72,16.06,3.65,20.96"
        />
        <path
          id="tibia_1"
          data-name="tibia 1"
          class="kniegelenk_lat_links_stress2357_cls5"
          d="M350.62,637.48c-6.32-23.48-10.79-42.64-13.67-55.59-3.94-17.73-6.64-31.66-12.76-57.42-6.54-27.53-8.7-33.67-10.94-39.19-8.58-21.19-19.09-35.32-22.78-40.1-3.34-4.31-9.51-12.2-19.14-20.96-11.58-10.53-15.48-10.79-21.87-19.14-3.08-4.03-9.9-13.19-10.94-26.43-.46-5.88-2.02-20.55,6.38-27.34,6.66-5.38,14.49-1.76,19.14-8.2,2.86-3.96.58-6.28,2.73-11.85,3.27-8.46,12.1-12.29,23.7-17.32,7.65-3.32,14.4-6.24,23.7-6.38,10.7-.16,11.73,3.64,20.96,2.73,10.73-1.05,11.21-6.07,22.78-8.2,12.75-2.35,23.1,1.72,25.52,2.73,3.83,1.6,13.81,5.77,19.14,15.49,2.57,4.69,1.75,6.46,5.47,13.67,4.47,8.68,6.4,7.99,8.2,12.76,4.76,12.6-6.73,23.85-1.37,29.81,2.52,2.8,5.17.45,9.57,3.91,4.98,3.92,2.96,8.01,8.2,15.49,3.92,5.59,5.98,4.64,9.1,10.26,2.57,4.61,3.26,8.98,3.65,11.62.9,6.03.26,10.72,0,12.76-1.43,11.17-.57,22.53-1.82,33.72-.46,4.09-1.61,14.62-1.82,26.43-.05,2.74-.18,13,1.82,32.81,1.1,10.85,2.59,22.22,5.47,39.19,2.44,14.39,5.95,33.29,10.94,55.59"
        />
        <path
          id="femur_1"
          data-name="femur 1"
          class="kniegelenk_lat_links_stress2357_cls5"
          d="M.64,82.44c46.67,22.04,84.37,42.02,112.1,57.42,1.56.87,26.35,14.75,56.51,37.37,39.36,29.51,46.24,42.68,48.3,53.77,3.37,18.08,2.73,32.81,2.73,32.81-.38,8.81-1.48,12.78,0,20.05,2.53,12.39,9.76,20.55,11.85,22.78,10.03,10.71,22.19,13.37,31.9,15.49,16.9,3.7,30.22.83,33.72,0,10.25-2.42,17.32-6.46,21.87-9.11,3.36-1.96,17.58-10.5,30.99-28.25,6.47-8.57,26.92-36.51,25.52-76.56-.89-25.3-10.07-43.32-12.76-48.3-3.82-7.08-16.82-30.49-42.84-42.84-2.47-1.17-11.7-3.83-30.08-9.11-28.26-8.12-32.79-8.95-41.92-11.85-19.27-6.11-28.18-11.64-51.04-22.78-30.56-14.91-32.69-13.85-72.91-31.9C104.97,32.63,76.39,19.32,41.64,1.33"
        />
        <path
          id="femur_2"
          data-name="femur 2"
          class="kniegelenk_lat_links_stress2357_cls5"
          d="M248.54,96.11c6.21,4.95,19.59,17.93,32.81,22.78,13.6,4.99,19.57,1.64,33.72,6.38,5.17,1.73,18.63,6.41,30.08,18.23,14.74,15.23,17.66,33.54,19.14,42.84,3.54,22.25-1.94,39.77-5.47,51.04-5.35,17.1-12.74,28.8-18.23,37.37-7.01,10.94-25.56,32.38-42.84,47.39"
        />
        <path
          id="patella_1"
          data-name="patella 1"
          class="kniegelenk_lat_links_stress2357_cls5"
          d="M432.64,225.53h0c6.72-2.95,5.99-18.29,5.47-29.16-.88-18.52-6.32-32.11-9.11-38.28-5.15-11.38-8.15-18.02-15.49-23.7-6.71-5.19-8.29-2.8-31.9-10.94-13.32-4.59-19.3-7.59-22.78-4.56-3.06,2.66-2.3,8.32-1.82,11.85.89,6.59,3.77,10.25,9.11,19.14,4.89,8.12,7.33,12.18,9.11,16.41,4.6,10.89,2.4,13.58,7.29,25.52,2.75,6.72,4.35,10.62,8.2,14.58,4.91,5.04,8.14,4.71,21.87,10.94,14.74,6.69,16.72,9.66,20.05,8.2h0Z"
        />
        <path
          id="tibia_2"
          data-name="tibia 2"
          class="kniegelenk_lat_links_stress2357_cls5"
          d="M432.64,395.96c0,15.62,5.91,28.26,13.22,28.26"
        />
        <polyline
          id="tibia_3"
          data-name="tibia 3"
          class="kniegelenk_lat_links_stress2357_cls5"
          points="308.81 475.22 301.4 450.64 286.82 424.22 267.37 410.09 252.19 398.69 243.07 380.46 238.56 363.99 244.9 351.3 267.37 323.98"
        />
        <polyline
          id="patella_2"
          data-name="patella 2"
          class="kniegelenk_lat_links_stress2357_cls5"
          points="390.72 206.39 385.25 181.78 380.69 162.65 372.49 145.32 358.82 118.89"
        />
        <path
          id="patella_3"
          data-name="patella 3"
          class="kniegelenk_lat_links_stress2357_cls4"
          d="M358.82,118.89c-74.6-50.6-136.4-83.38-220.56-80.2"
        />
        <path
          id="tibia_4"
          data-name="tibia 4"
          class="kniegelenk_lat_links_stress2357_cls4"
          d="M376.21,310.29c0,13.6-35.5,24.61-79.36,24.61"
        />
        <path
          id="tibia_5"
          data-name="tibia 5"
          class="kniegelenk_lat_links_stress2357_cls4"
          d="M408.95,380.46c-15.57,29.51,5.06,98.35,23.7,109.37"
        />
        <path
          id="tibia_6"
          data-name="tibia 6"
          class="kniegelenk_lat_links_stress2357_cls4"
          d="M428.09,475.22c0-22.15-3.26-40.07-7.29-40.07"
        />
        <path
          id="tibia_7"
          data-name="tibia 7"
          class="kniegelenk_lat_links_stress2357_cls4"
          d="M420.8,435.15c0-19.65-3.26-35.55-7.29-35.55"
        />
        <line
          id="triangle_1"
          data-name="triangle 1"
          class="kniegelenk_lat_links_stress2357_cls4"
          x1="68.09"
          y1="147.15"
          x2="141.91"
          y2="249.22"
        />
        <line
          id="triangle_2"
          data-name="triangle 2"
          class="kniegelenk_lat_links_stress2357_cls4"
          x1="177.45"
          y1="206.39"
          x2="68.09"
          y2="147.15"
        />
        <path
          id="triangle_3"
          data-name="triangle 3"
          class="kniegelenk_lat_links_stress2357_cls4"
          d="M177.45,206.39c-16.25,9.19-32.35,28.17-35.54,42.84"
        />
      </g>
      <g id="overlays">
        <ellipse
          id="knee_joint_overlay"
          data-name="knee joint overlay"
          class="Kniegelenk2357Fill"
          style={{
            fill: props.colors.Kniegelenk2357Fill,
            stroke: props.colors.Kniegelenk2357Fill,
          }}
          cx="301.38"
          cy="315.54"
          rx="61.05"
          ry="11.34"
          transform="translate(-77.62 99.31) rotate(-16.61)"
        />
        <ellipse
          id="femur_joint_overlay"
          data-name="femur joint overlay"
          class="Retropatellargelenk2357Fill"
          style={{
            fill: props.colors.Retropatellargelenk2357Fill,
            stroke: props.colors.Retropatellargelenk2357Fill,
          }}
          cx="368.62"
          cy="164.85"
          rx="15.66"
          ry="48.02"
          transform="translate(-31.74 112.24) rotate(-16.61)"
        />
      </g>
    </svg>
  );
}
