import style from "./style.scss";

export default function RechtesHuftgelenkTEPvdLiegend2413(props) {
  return (
    <svg
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 216.19 333.24"
    >
      <g id="BG">
        <path
          id="bg"
          class="TEP_Hufte_rechts2413_cls6"
          d="M5.58,106.76c3.29-6.68,7.69-11.8,15.14-12.6l15.78,3.45c6.25-5.28,12.2-9.12,17.4-9.15l7.91,10.5,10.84,7.1,13.55-5.8.68-20.94-4.98-29.63c-.59.86-5.59-3.47-7.73-7.73-2.14-4.26,3.51-7.15,3.87-13.52.16-3.02-.86-4.51-8.7-16.42-1.2-1.82-2.26-3.45-3.22-4.9L145.81,0c.53,3.44,1.75,8.15,4.69,12.97,2.32,3.8,5.85,9.6,10.31,9.51,4.14-.08,5.31-5.17,9.67-4.83,2.69.21,4.99,2.36,6.11,4.47,1.43,2.69.94,5.34.73,6.23-.95,4-3.86,3.89-5.13,7.33-1.46,3.97,1.9,5.56,2.15,12.91.18,5.22-1.47,5.8-.69,9.43.85,3.94,3.4,6.1,7.33,9.89,9.09,8.77,6.43,7.81,12.98,13.67,4.94,4.41,12.29,11.97,22.22,15.46l-25.33,78.37c-10.23-.74-19.77-6.68-27.79-11.69-9.01-5.61-19.77-12.32-25.12-25.12-5.01-12-5.94-13.54-7.73-24.16l-1.73-8.37-19.78,3.33-3.85,11.91c-.65,5.43.59,10.83,3.85,16.18,13.88,21.03,13.71,34.36,1.31,40.94-4.36,32.78-7.05,138.57-4.12,151.75.11.51.19,1,.25,1.47-16.42-1.07-32.84-.54-49.27.13-6.82.02-13.64.14-20.47.15-5.02-.47-10-.72-14.83-.6,6.44-42.51,10.46-82.38,6.59-113.73-2.68-24.05-7.59-45.07-18.34-58.13-11.35-19-12.02-36.44-4.25-52.7Z"
        />
      </g>
      <g id="Underlays">
        <path
          id="Femur"
          class="Schenkel2413Fill"
          style={{
            fill: props.colors.Schenkel2413Fill,
            stroke: props.colors.Schenkel2413Fill,
          }}
          d="M79.89,101.24c-1.95.53-6.24,1.39-11.36,0-1.56-.42-5.95-1.68-9.52-5.49-3.38-3.61-2.46-5.7-4.76-6.59-3.73-1.45-9.75,2.65-13.92,5.49-1.62,1.1-2.62,1.94-4.4,2.2-2.92.43-4.71-1.18-7.69-2.2,0,0-4.87-1.67-10.26-.37-7.48,1.81-11.39,9.6-13.19,13.19-2.49,4.96-3.08,9.17-3.66,13.55-.76,5.77-2.23,16.8,2.56,28.21,2.96,7.06,5.17,6.75,10.26,15.38,1.23,2.09,5.48,9.55,9.16,24.18,6.1,24.27,5.61,48.41,5.13,67.03-.15,5.89-.64,24.79-2.93,41.39-1.06,7.68-1.45,15.47-2.93,23.08-1.38,7.07-2.1,8.42-1.1,10.26,3.34,6.12,16.03-.33,41.39,1.47,10.84.77,21.79-.36,32.6.73,1.97.2,8.4.89,10.26-2.2.74-1.23.41-2.49,0-5.86-.4-3.28-.34-6.61-.73-9.89-1.09-9.05-.54-32.93-.37-39.93.36-14.45.87-24.71,1.83-44.32,1.28-26.05,1.44-22,1.47-27.11.03-5.87,1.71-11.74,1.1-17.58-.17-1.58-.56-4.48,1.1-6.96,1.52-2.27,3.45-2.23,5.49-4.03,2.94-2.6,3.3-6.54,3.66-10.62.78-8.68-2.89-15.32-4.03-17.22-1.27-2.12-1.22-1.38-4.03-5.49-3.68-5.38-5.53-8.14-6.23-11.72-.66-3.4-.17-6.19.37-9.16,1.12-6.23,2.94-7.03,2.93-9.89-.03-8.67-16.9-19.31-24.54-14.65-1.3.79-2.71,2.25-3.66,5.13Z"
        />
        <path
          id="Hipjoint"
          class="Hipjoint2413Fill"
          style={{
            fill: props.colors.Hipjoint2413Fill,
            stroke: props.colors.Hipjoint2413Fill,
          }}
          d="M131.17,116.62c2.55.95,6.7-3.56,8.79-6.96,2.16-3.51,3.02-7.27,3.3-12.45.3-5.67.8-15.04-4.03-26.01-1.85-4.21-5.75-12.77-15.02-19.41-2.51-1.8-8.8-6.2-17.95-7.33,0,0-7.84-.97-17.95,1.83-2.35.65-2.55,1.12-5.42,1.81-1.47.35-2.37.46-2.64,1.12-.38.91.58,2.54,1.83,2.93,1.51.47,2.88-1.03,4.76-2.2,2.64-1.64,5.02-1.83,9.52-2.2,6.27-.51,11.14,1.04,13.55,1.83,10.39,3.41,16.58,10.4,18.68,12.82.97,1.13,8.68,10.26,10.62,23.81.74,5.15.35,8.85,0,10.99-.79,4.87-1.47,9.04-4.76,11.72-2.32,1.89-4.74,2.06-5.13,4.03-.27,1.39.57,3.19,1.83,3.66Z"
        />
        <path
          id="Tminor"
          class="TrochanterMinor2413Fill"
          style={{
            fill: props.colors.TrochanterMinor2413Fill,
            stroke: props.colors.TrochanterMinor2413Fill,
          }}
          d="M109.88,139.32h.05c2.62,3.4,8.99,12.48,8.89,23.45-.03,2.98-.12,7.18-3.03,10.99-2,2.61-4.5,3.97-6.06,4.64-.82-6.08-1.59-12.31-3.87-35.28-.23-2.28,1.77-4.17,4.03-3.8Z"
        />
        <path
          id="Tmajor"
          class="TrochanterMajor2413Fill"
          style={{
            fill: props.colors.TrochanterMajor2413Fill,
            stroke: props.colors.TrochanterMajor2413Fill,
          }}
          d="M.4,128.53c0,19.52,9.43,35.35,21.06,35.35s13.74-17.11,13.74-36.63-2.1-34.07-13.74-34.07S.4,109,.4,128.53Z"
        />
      </g>
      <g id="Hip">
        <path
          id="Hip11"
          class="TEP_Hufte_rechts2413_cls1"
          d="M66.11,7.62c.88,1.34,1.85,2.82,2.92,4.45,7.84,11.91,8.86,13.4,8.7,16.42-.36,6.37-5.43,9.01-3.87,13.52,1.59,4.6,21.51,19.46,21.24,21.99"
        />
        <path
          id="Hip10"
          class="TEP_Hufte_rechts2413_cls1"
          d="M95.13,63.99c4.97,12,11.39,18.5,16.42,22.22,5.59,4.15,9.74,5.06,13.52,10.64,3.5,5.17,3.21,9.24,4.85,17.64,1.94,10,5.51,18.83,7.73,24.16,5.35,12.8,16.11,19.51,25.12,25.12,8.1,5.06,17.75,11.07,28.09,11.71"
        />
        <path
          id="Hip9"
          class="TEP_Hufte_rechts2413_cls1"
          d="M215.89,97.09c-8.19-2.7-12.88-6.95-22.22-15.46-3.77-3.43-18.37-17.07-30.92-39.61-5.53-9.95-1.63-5.38-12.55-28.99-2.51-5.41-4.7-9.95-4.69-12.97"
        />
        <path
          id="Hip8"
          class="TEP_Hufte_rechts2413_cls1"
          d="M167.57,67.9c-.13,10.92.43,19.87.97,26.09,2.18,24.94,6.54,33.14,8.7,36.71,3.28,5.4,7.81,12.93,15.46,13.52,1.4.11,6.17-.17,7.63-.51"
        />
        <path
          id="Hip7"
          class="TEP_Hufte_rechts2413_cls1"
          d="M206.76,122.02c-2.86-2.28-14.61-8.76-28.56-11.62-5.83-1.2-14.07-2.51-24.16-2.9"
        />
        <path
          id="Hip6"
          class="TEP_Hufte_rechts2413_cls1"
          d="M145.38,29.21c32.84,81.16,11.11,65.85,12.55,65.7,1.31-.13,2.14-11.35-.97-22.22-3.08-10.72-8.97-17.49-10.64-19.32-7.2-7.9-15.04-11.04-21.26-13.52-6.44-2.57-17.15-6.72-30.92-4.83-8.94,1.23-15.77,4.48-19.98,6.93"
        />
        <path
          id="Hip5"
          class="TEP_Hufte_rechts2413_cls1"
          d="M167.57,67.86c4.27,0,7.05-.51,7.05-7.99"
        />
        <path
          id="Hip4"
          class="TEP_Hufte_rechts2413_cls1"
          d="M150.5,12.96v.03h0c5.92,18.12,13.85,33.89,24.13,46.92-3.76-4.89.76-8.24-.28-11.33-1.59-4.71-2.63-7.78-1.93-11.6,1.1-6.04,5.23-7.15,4.83-11.6-.34-3.64-3.5-7.42-6.77-7.73-4-.39-5.61,4.68-9.67,4.83-2.35.07-5.82-1.46-10.31-9.51h0Z"
        />
        <path
          id="Hip3"
          class="TEP_Hufte_rechts2413_cls1"
          d="M105.9,330.17c-3.2-41.46-.3-116.22,4.09-151.76,5.79-2.38,9.11-7.45,8.9-16.35-.24-5.61-1.86-11.42-3.81-15.56-3.52-7.51-7.32-7.77-9.31-15.04-1.44-5.31-1.28-11.95,1.1-17.51,2.72-6.37,5.23-12.82,7.02-19.5"
        />
        <path
          id="Hip2"
          class="TEP_Hufte_rechts2413_cls1"
          d="M9.81,99.77c-3.71,5.64-6.95,10.7-8.54,20.35-2.56,15.58,2.01,27.59,2.9,29.85,3.83,9.71,6.9,8.7,12.55,20.21,3.3,6.76,4.53,11.75,5.41,15.68,11.54,37.4,7.47,89.57-.58,145.48"
        />
        <path
          id="Hip1"
          class="TEP_Hufte_rechts2413_cls1"
          d="M60.29,97.95l-3.15-5.31c-.55-.91-.92-1.9-1.4-2.84-2.7-5.41-19.17,7.53-19.11,7.53.08-.01,18.78-5.77,20.59-3.18-2.88,4.02-9.89,4.66-10.59,4.79-3.86.75-5.95-.61-12.67-2.34-7.5-1.94-11.25-2.9-14.61-2.25-6.58,1.26-13.53,7.64-14.27,14.65-.72,6.99,5.27,9.4,7.07,19.4,1.13,6.19-.03,11.68-1.25,15.47"
        />
      </g>
      <g id="TEP">
        <path
          id="TEP2"
          class="TEP_Hufte_rechts2413_cls2"
          d="M135,132.05h0c-6.7-.43-10.7-12.23-4.72-15.25,22.09-11.17,14.15-43.88-1.13-60.69-14.87-12.39-30.01-14.49-45.38-8.05-2.69,1.11-5.87.74-7.94-1.31-1.49-1.49-2.03-3.39-1.12-5.89l14.92-5.13c15.69-1.55,33,4.63,50.78,13,9.16,7.18,12.65,15.75,11.39,25.48.73,8.41,2.54,16.26,6.75,22.94,2.09,3.32,2.96,7.25,2.28,11.1-2.26,12.76-9.43,19.79-25.03,23.78-.19.04-.64.1-.8,0-.06-.03,3.02.19,0,0v.02Z"
        />
        <path
          id="TEP1"
          class="TEP_Hufte_rechts2413_cls2"
          d="M92.31,47.42h.01c-7.83,1.13-14.7,5.86-18.67,12.67-.01.04-.04.09-.07.13-4.16,6.17-2.62,15.84,8.97,24.01,4.02,3.88,1.41,18.42-7.69,18.15-6.35.26-11.29-1.31-14.55-5.01-1.46-.49-2.93.67-2.8,2.19l18.88,207.09c.13,1.49.8,2.9,1.86,3.97,3.09,3.11,6.11,3.16,9.07,1.06,1.65-1.16,2.66-3.03,2.78-5.04l9.36-154.7c-1.49-4.88-1.36-9.82-.91-14.77,3.45-7.23,6.71-16.57,9.88-26.95,0,0,10.8,10.06,16.76,5.52.91-.7,1.8-1.41,2.81-1.95,9.56-5.1,14.81-14.55,11.08-32.99-.04-.25-.12-.52-.19-.77-3.39-11.23-10.54-20.55-22.28-27.59-4.85-2.92-10.28-4.9-15.93-5.3-2.85-.21-5.63-.11-8.37.28h0Z"
        />
      </g>
    </svg>
  );
}
