import style from "./style.scss";

function HalswirbelsauleVdStehend1601(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333.43 798.63">

<g id="Underlays">
  <ellipse id="space6" class="Intervertebral_spaces1601Fill" style= {{
            fill: props.colors.Intervertebral_spaces1601Fill,
            stroke: props.colors.Intervertebral_spaces1601Fill,
            opacity:props.colors.Intervertebral_spaces1601Fill,
          }} cx="155.63" cy="522.34" rx="59.81" ry="23.58"/>
  <ellipse id="space5" class="Intervertebral_spaces1601Fill" style= {{
            fill: props.colors.Intervertebral_spaces1601Fill,
            stroke: props.colors.Intervertebral_spaces1601Fill,
            opacity:props.colors.Intervertebral_spaces1601Fill,
          }} cx="161.44" cy="443.39" rx="54" ry="24.61"/>
  <ellipse id="space4" class="Intervertebral_spaces1601Fill" style= {{
            fill: props.colors.Intervertebral_spaces1601Fill,
            stroke: props.colors.Intervertebral_spaces1601Fill,
            opacity:props.colors.Intervertebral_spaces1601Fill,
          }} cx="167.25" cy="362.05" rx="57.08" ry="23.24"/>
  <ellipse id="space3" class="Intervertebral_spaces1601Fill" style= {{
            fill: props.colors.Intervertebral_spaces1601Fill,
            stroke: props.colors.Intervertebral_spaces1601Fill,
            opacity:props.colors.Intervertebral_spaces1601Fill,
          }} cx="170.67" cy="280.03" rx="50.24" ry="23.24"/>
  <ellipse id="space2" class="Intervertebral_spaces1601Fill" style= {{
            fill: props.colors.Intervertebral_spaces1601Fill,
            stroke: props.colors.Intervertebral_spaces1601Fill,
            opacity:props.colors.Intervertebral_spaces1601Fill,
          }} cx="172.04" cy="192.53" rx="57.76" ry="25.29"/>
  <path id="space1" class="Intervertebral_spaces1601Fill" style= {{
            fill: props.colors.Intervertebral_spaces1601Fill,
            stroke: props.colors.Intervertebral_spaces1601Fill,
            opacity:props.colors.Intervertebral_spaces1601Fill,
          }} d="M220.15,603.13c0,6.8-29.98,17.32-64.71,17.32s-59.85-3.23-59.85-10.03,23.6-5.77,58.33-5.77,66.23-8.31,66.23-1.52Z"/>
</g>
<g id="Anatomy">
  <g id="LWS_anatomy" data-name="LWS anatomy">
    <path id="LWS_anatomy10" data-name="LWS anatomy10" class="Halswirbelsaule_vd_stehend1601_cls5" d="M91.09,795.77c4.97-7.26,6.14-13.4,6.38-17.32.17-2.68-.13-3.81-2.73-17.32-2.96-15.39-2.9-15.6-3.65-17.32-3.61-8.3-7.52-8.92-9.11-16.41-.57-2.65-1.59-7.44.91-11.85,3.07-5.41,10.03-7.75,15.49-7.29,5.9.5,6.18,3.95,13.67,5.47,4.85.98,10.71,3.08,18.23,3.25,6.56.15,6.35-2.09,10.03-1.43,2.17.39,20.33,3.59,37.37,1.82,7.23-.75,22.18-2.3,36.46-11.85,15.28-10.23,21.34-23.98,26.43-21.87,1.72.71,2.38,3.83,3.65,10.03,1.18,5.79,2.87,14.09,1.82,25.52-.6,6.59-1.64,8.94-.91,14.58.55,4.31,2.53,6.02,2.73,10.03.81,15.93,2.55,23.11,1.82,30.08-.39,3.71-1.14,8.73-2.73,14.58"/>
    <path id="LWS_anatomy9" data-name="LWS anatomy9" class="Halswirbelsaule_vd_stehend1601_cls1" d="M55.82,103.32c-3.21-2.54,4.45-11.12,6.15-30.76,1.1-12.73-1.41-17.32,2.73-22.56,3.98-5.04,10.35-5.92,21.87-7.52,13.94-1.93,23.81.19,25.97.68,8.88,2.02,14.78,3.36,19.14,8.89,3.99,5.06,6.15,12.94,3.42,19.82-.66,1.66-3.04,6.89-8.2,8.89-3.8,1.47-7.21.46-9.57,0-6.54-1.28-12.56.74-24.61,4.78-23.13,7.76-33.07,20.8-36.91,17.77h0Z"/>
    <path id="LWS_anatomy8" data-name="LWS anatomy8" class="Halswirbelsaule_vd_stehend1601_cls1" d="M289.59,60.25c.74,2.52-2.03,4.13-4.94,11.09,0,0-1.98,4.75-3.27,12.84-2.77,17.46,6.08,32.76,2.9,34.57-2.37,1.35-6.74-7.39-19.3-14.06-2.54-1.35-3.34-1.47-19.82-6.84-18.72-6.1-19.96-6.38-22.56-8.2-6.1-4.3-15.66-14.3-13.67-24.61,2.25-11.66,18.18-18,29.39-18.46,9.25-.37,9.58,3.5,28.71,7.52,12.88,2.71,21.35,2.06,22.56,6.15Z"/>
    <path id="LWS_anatomy7" data-name="LWS anatomy7" class="Halswirbelsaule_vd_stehend1601_cls1" d="M57.87,112.2c3.14-3.4,8.68-2.24,13.67-2.05,4.27.16,13.55-3.31,32.13-10.25,21.97-8.22,16.79-7.6,27.34-10.94,9.83-3.11,14.51-3.7,18.46-8.2,5.99-6.83,4.96-17.06,4.78-19.14-.86-10.03-6.35-12.58-4.78-19.82,1.27-5.9,5.98-9.42,7.9-10.86,6.6-4.94,16.1-6.47,24.23-2.81,6.9,3.11,10.12,8.75,10.94,10.25,4.53,8.33,2.75,17.08,2.05,20.51-1.48,7.28-3.91,7.77-4.1,12.99-.3,8.13,5.31,14.49,8.2,17.77,7.03,7.96,11.12,6.79,27.34,17.09,12.07,7.66,11.74,9.54,19.14,12.3,9.93,3.71,20.51,4.78,20.51,4.78,7.89.8,13.31.19,16.41,4.1.27.35,2.67,3.46,1.67,6.3-1.63,4.63-10.09,2.29-13.6,7.72-2.38,3.68-1.7,9.69.99,12.63,1.14,1.25,1.94,1.16,3.8,3.04.9.91,4.41,4.45,3.72,6.53-.92,2.77-9.11,2.47-18.46,2.05-12.93-.57-14.59-1.98-20.51-.68-6.72,1.48-11.46,4.8-14.35,6.84-3.25,2.28-8.06,5.66-11.62,11.62-4.99,8.35-2.63,13.56-6.53,15.87-4.06,2.4-7.69-2.6-18.76-5.62-10.3-2.81-11.7.28-32.13-1.37-5-.4-8.02-.84-12.61,0-5.08.93-6.58,2.41-8.58,1.37-3.64-1.9-.78-7.92-5.47-13.67-2.55-3.13-4.32-2.49-6.63-6.13-2.49-3.92-1.69-6.67-3.56-7.4-2.49-.97-4.54,3.65-9.78,4.56-4.33.76-8.14-1.5-10.1-2.66-6.61-3.91-6.14-8.79-11.62-10.25-.25-.07-2.75-.09-7.75-.15-6.92-.08-7.35-.02-7.97.15-5.48,1.53-6.13,6.44-8.89,6.15-3.32-.34-6.83-7.93-5.47-14.35.9-4.25,3.19-4.51,5.47-10.94,1.5-4.23,2.56-7.24,1.37-10.18-1.81-4.45-6.77-3.69-8.51-8.2-1.15-2.97-.35-6.78,1.67-8.96h0Z"/>
    <path id="LWS_anatomy6" data-name="LWS anatomy6" class="Halswirbelsaule_vd_stehend1601_cls1" d="M59.85,223.24c1.89-5.55,7.04-8.09,9.11-9.11,4.79-2.36,9.04-2.42,14.89-2.43,12.18-.02,16.92,2.94,19.75-.06,1.65-1.75.37-3.12,1.82-10.27,1.02-5,2.5-8.51,3.65-11.24,2.63-6.25,5.45-12.93,8.52-12.78,1.16.06,2.2,1.08,5.76,8.84,5.43,11.85,5.98,15.94,10.03,18.53,2.98,1.9,6.06,1.86,8.81,1.82,11.34-.14,22.68.09,34.03,0,21.88-.17,28.99,5.35,35.76-.69,2.54-2.26,4.32-5.51,7.07-10.55,3.37-6.15,3.66-8.86,7.29-14.58,3.39-5.34,7.2-9.37,8.2-8.81,1.01.57-1.85,5.19.3,8.2,1.21,1.7,2.98,1.42,5.16,2.43,5.31,2.45,7.52,9.89,7.9,14.28.78,9.08-5.53,13.57-3.04,17.01,1.42,1.97,3.7.81,11.85,2.43,6.32,1.26,9.85,1.96,12.76,4.86.51.51,5.03,5.16,3.95,11.54-.12.7-.97,5.29-4.86,7.9-2.77,1.85-4.99,1.31-12.46,1.22-8.34-.11-13.43-.13-18.84,1.52-4.41,1.35-7.4,3.31-9.72,4.86-7.73,5.16-8.33,9.04-17.01,16.71-4.09,3.61-6.25,5.52-9.72,7.29-4.87,2.48-9.34,3.17-23.39,3.34-13.04.15-19.74.21-28.56-.91-16.8-2.14-20.13-5.42-21.27-6.68-2.96-3.29-1.51-4.52-6.08-13.97-3.84-7.96-6.31-12.9-11.54-15.49-2.78-1.38-5.6-1.53-11.24-1.82-4.16-.22-5.24.14-13.97.61-9.9.53-12.52.28-15.19-.3-3.96-.88-7.56-1.72-9.42-4.86-2.1-3.54-.67-7.75-.3-8.81v-.03Z"/>
    <path id="LWS_anatomy5" data-name="LWS anatomy5" class="Halswirbelsaule_vd_stehend1601_cls1" d="M99.65,296.46c3.53-3.33-.23-7.92,2.73-17.32,3.42-10.87,12.86-18.75,15.8-17.32,1.78.87-.07,4.57,1.82,11.54.21.78,2.19,7.79,7.29,12.76,2.33,2.28,5.88,4.68,18.23,6.99,9.88,1.85,21.71,3.95,37.67,1.52,9.91-1.51,14.86-2.26,19.44-5.47,12.22-8.54,12.13-23.7,17.92-23.63,4.31.06,7.88,8.53,9.12,12.69,3.04,10.14-1.37,15.7,2.43,19.44,1.93,1.9,6.58,1.8,15.8,1.52,11.14-.34,13.7-1.58,17.01.61,4.34,2.86,5.31,8.52,5.77,11.24.42,2.43,1.44,8.43-2.13,13.06-2.39,3.11-5.69,4.1-9.42,5.16-1.52.43-6.6,1.77-19.14.91-8.41-.58-12.04-1.58-13.67.61-1.36,1.83.31,3.69-.61,7.29-.7,2.76-2.37,4.39-7.29,8.51-7.92,6.63-11.89,9.94-14.58,10.94-3.72,1.37-6.33,1.28-13.37,1.22,0,0-9.03-.09-25.82,0-19.77.1-21.93,1.97-28.56-.91-4.65-2.02-8.28-4.98-13.37-9.11-5.43-4.42-8.18-6.71-10.03-10.63-2.63-5.6-.5-7.89-3.34-11.24-3.27-3.86-9.59-4.93-14.28-3.65-3.78,1.04-3.67,2.83-7.59,4.25-4.24,1.54-10.55,1.69-15.8-1.82-5.17-3.46-8.54-9.93-7.59-16.41.05-.35,1.28-7.95,7.88-11.75,10.37-5.97,21.95,4.37,27.66-1.01h.02Z"/>
    <path id="LWS_anatomy4" data-name="LWS anatomy4" class="Halswirbelsaule_vd_stehend1601_cls1" d="M109.97,347.19c2.74-.27,4.4,4.69,14.28,19.75,4.07,6.21,5.68,8.26,8.51,10.03,4.75,2.98,9.26,2.86,14.89,3.04,20.5.67,19.19,4.16,31.9,3.04,9.9-.87,16.52-1.46,22.48-6.38,5.73-4.73,11.69-14.18,13.67-17.32,3.5-5.54,4.72-8.54,6.99-8.51,3.7.05,7.15,8.1,8.51,13.06,1.74,6.33.76,10.1,3.65,12.15,2,1.42,4.34.93,6.99.61,0,0,8.88-1.08,22.78,1.82,7.31,1.53,13.06,2.73,17.01,7.9,4.27,5.58,4.53,13.26,2.43,18.84-.71,1.88-1.96,5.2-5.16,6.99-3.22,1.79-6.53.98-11.54,0-2.43-.48-11.53-2.26-17.01-2.73-3.12-.27-13.43-1.03-24,4.25-6.88,3.44-11.25,8.13-12.15,9.11-5.44,5.97-5,9.18-9.42,12.46-3.82,2.83-7.96,3.26-16.71,3.34-13.24.13-19.28-.68-27.04-.91-23.05-.67-27.86,4.81-35.85-.61-4.56-3.09-9.1-9.03-11.85-14.58-2.82-5.71-2.19-7.88-5.47-11.54-5.16-5.77-12.61-6.97-14.58-7.29-4.33-.7-8.04-.2-10.33.3-4.54.99-4.93,2.3-8.81,3.34-1.55.42-8.93,2.39-15.49-1.52-1.15-.69-8.05-4.99-7.9-11.85.15-6.64,6.79-10.68,8.2-11.54,4.8-2.92,7.67-1.84,20.05-3.04,11.8-1.14,17.76-1.79,20.66-5.47,2.54-3.23.88-5.3,3.65-14.28,1.89-6.13,3.75-12.17,6.68-12.46h-.02Z"/>
    <path id="LWS_anatomy3" data-name="LWS anatomy3" class="Halswirbelsaule_vd_stehend1601_cls1" d="M103.29,430.74c6.65.07,6.94,15.42,18.84,22.48,4.62,2.74,8.55,2.78,17.92,3.04,47.17,1.28,53.56,3.11,59.24-3.04,1.6-1.73,7.04-8.3,11.85-17.31,2.34-4.39,4.08-8.49,6.96-8.61,1.6-.07,3.33,1.81,6.71,5.57,3.9,4.34,5.86,6.5,6.68,9.42,1.16,4.08-.29,6.13,1.22,7.29,1.73,1.34,4.17-.98,10.33-2.43,3.28-.77,6.54-.82,13.06-.91,5.66-.08,8.49-.12,10.03.61,5.21,2.47,8.51,9.6,7.29,15.8-.95,4.82-4.37,7.75-6.38,9.42-5.89,4.9-10.56,4.02-22.78,7.59-7.55,2.21-11.91,3.49-17.01,6.68-8.48,5.31-13.75,12.65-16.41,16.41-6.05,8.57-5.67,12.04-10.63,14.89-4.29,2.47-8.22,1.95-15.49,1.52,0,0-11.82-.71-29.47-.3-10.43.24-20.87-.1-31.29.3-1.8.07-4.68.19-7.9-1.22-5.79-2.54-9.11-8.51-10.63-11.24-2.77-4.97-2.15-6.56-4.56-8.2-3.62-2.48-6.77-.09-9.42-2.43-3.08-2.72-.16-7.13-2.73-10.94-2.65-3.91-8.91-3.97-17.01-3.95-14.05.04-17.4,3.57-22.48.61-5.23-3.05-7.04-9.89-6.99-14.58.08-7.61,5.1-15.21,11.24-16.71,1.72-.42,1.69.09,7.59.61,9.34.82,9.84-.42,16.72.48,6.7.87,7.87,2.26,10.01,1.04,3.24-1.84,2.07-5.86,5.16-11.85.53-1.02,5.21-10.08,10.33-10.03h0Z"/>
    <path id="LWS_anatomy2" data-name="LWS anatomy2" class="Halswirbelsaule_vd_stehend1601_cls1" d="M94.48,509.12c-4.52-.55-9.89,5.45-11.85,10.94-2.24,6.26.83,9.4-2.13,15.49-.54,1.11-3.08,6.35-7.9,7.59-4.32,1.12-6.42-2.02-13.37-4.25-2.5-.8-14.45-4.65-22.18,1.22-.25.19-5.96,4.66-5.77,11.24.17,5.82,4.85,9.68,8.2,12.46,1.64,1.35,5.45,4.43,11.24,5.77,9.57,2.22,13.22-2.92,20.35-.3,3.22,1.18,5.69,4.23,10.63,10.33,5.54,6.83,9.03,11.14,11.54,17.92,1.53,4.12,2.13,7.65,5.47,9.72.41.26,3.42,2.07,6.68,1.22,2.26-.59,2.98-2.02,4.56-3.04,4.12-2.66,10.14-.03,12.76.91,2.92,1.06,10.96.7,27.04,0,16.27-.71,30.36-2.44,37.67-3.34,16.12-1.98,19.53-3.19,25.22-1.82,1.04.25,3.15.83,5.47,0,3.73-1.34,5.08-5.29,7.9-12.15,3.86-9.4,5.79-14.1,8.2-17.32,1.97-2.63,5.62-7.38,11.85-9.72,4.05-1.52,4.78-.37,10.94-1.52,1.63-.3,9.28-1.82,17.32-6.99,5.95-3.82,10.01-6.43,10.94-11.24,1.2-6.26-3.79-11.63-4.56-12.46-4.89-5.26-11.64-5.93-16.1-6.38-7.37-.74-14.31.69-21.27,2.13-7.67,1.58-11.19,3.06-13.97.91-2.37-1.83-1.29-4.02-4.25-8.51-.56-.85-4.97-7.51-9.72-6.99-2.06.23-3.43,1.88-6.08,5.16-4.51,5.59-4.78,8.49-9.42,14.28-3.17,3.96-4.79,4.78-5.77,5.16-3.53,1.38-6.84.28-9.11-.3-1.14-.29-7.24-.2-19.44,0-14.15.23-21.23.34-27.04.91-9.94.98-11.09,1.9-15.19.91-8.06-1.94-13.42-7.86-16.41-11.24-8.16-9.24-7.84-16.14-12.46-16.71h.01Z"/>
    <path id="LWS_anatomy1" data-name="LWS anatomy1" class="Halswirbelsaule_vd_stehend1601_cls1" d="M81.72,698.38c.13-2.2,3.51-2.27,6.08-5.16,4.64-5.24,3.73-16.47-1.22-23.7-6.32-9.23-17.4-9.26-17.62-15.49-.05-1.54.6-1.87,3.04-6.38,3.56-6.6,5.92-10.96,6.68-15.8,1.17-7.37-2.44-8.97-.91-18.23.53-3.22,1.17-6.78,3.65-7.9,2.58-1.16,5.44,1.1,10.33,3.65,4.8,2.5,10.14,3.83,15.19,5.77,7.05,2.71,13.68,2.89,37.67,2.73,30.35-.2,34.92-.59,40.71-1.52,14.75-2.36,22.13-3.54,26.43-8.81,1.01-1.24,3.82-5.09,7.29-4.86,1.89.13,2.44,1.36,4.86,2.73,3.13,1.78,4.83,1.21,11.85,2.13,6.36.83,9.55,1.25,11.24,2.73,3,2.63,3.48,6.86,3.95,10.94.71,6.21-1.01,7.39.3,10.94.97,2.63,2.4,3.29,8.81,8.51,10.58,8.61,11.46,10.68,11.85,11.85,1.83,5.53-.91,10.95-1.52,12.15-.84,1.67-2.94,5.82-7.29,7.59-5.27,2.15-8.84-1.22-11.85,1.22-2.32,1.88-1.38,4.85-2.43,8.51-.56,1.93-2.39,6.54-15.19,13.06-5.14,2.62-11.45,5.24-31.9,10.33-11.48,2.86-17.22,4.28-20.66,4.56-11.4.91-13.78-1.58-30.38-1.82-10.54-.16-9.17.85-20.35.61-15.04-.33-14.22-2.08-26.13-1.82-3.4.07-7.24.3-12.15-1.22-4.12-1.27-10.51-4.22-10.33-7.29h0Z"/>
  </g>
  <path id="Anatomy96" class="Halswirbelsaule_vd_stehend1601_cls2" d="M90.23,798.33c13.16-17.78.1-63.59-10.03-71.09"/>
  <path id="Anatomy95" class="Halswirbelsaule_vd_stehend1601_cls2" d="M80.2,727.24c-2.1-12.17,8.03-21.83,26.13-17.12"/>
  <path id="Anatomy94" class="Halswirbelsaule_vd_stehend1601_cls2" d="M106.33,710.12c0,3.75,29.14,6.79,65.15,6.79"/>
  <path id="Anatomy93" class="Halswirbelsaule_vd_stehend1601_cls2" d="M240,683.19c-6.33,9.67-34.85,34.4-71.09,33.72"/>
  <path id="Anatomy92" class="Halswirbelsaule_vd_stehend1601_cls2" d="M240,683.19c10.59,25.89,4.86,34.76,4.08,48.92"/>
  <path id="Anatomy91" class="Halswirbelsaule_vd_stehend1601_cls2" d="M244.08,732.11c7.32,18.25,6.4,51.81,2.61,55.28"/>
  <path id="Anatomy90" class="Halswirbelsaule_vd_stehend1601_cls2" d="M195.15,780.54c0,11.66-6.85,15.96-15.91,15.96s-16.41-9.45-16.41-21.11,7.35-21.11,16.41-21.11,15.91,14.61,15.91,26.27h0Z"/>
  <path id="Anatomy89" class="Halswirbelsaule_vd_stehend1601_cls2" d="M190.87,667.61c-2.22,38.37-7.69,17.71-17.17,17.71s-10.88,20.96-17.17-17.71c-1.94-11.92,7.69-21.87,17.17-21.87s17.86,9.81,17.17,21.87Z"/>
  <path id="Anatomy88" class="Halswirbelsaule_vd_stehend1601_cls2" d="M176.2,581.12c-3.28,36.58-3.13,3.05-14.58,10.03-6.55,3.99-10.66,26.59-17.38-10.39-2.16-11.89,7.69-21.87,17.17-21.87s15.88,10.2,14.8,22.23h0Z"/>
  <path id="Anatomy87" class="Halswirbelsaule_vd_stehend1601_cls2" d="M75.65,554.23c-1.86,7.4,0,15.95,6.38,15.95s11.11-10.76,16.71-15.95c6.04-5.59-2.14-22.57-11.54-13.82-7.92,7.37-10.79,10.82-11.54,13.82h0Z"/>
  <path id="Anatomy86" class="Halswirbelsaule_vd_stehend1601_cls2" d="M235.44,546.63c0,9.31-2.18,16.86-4.86,16.86s-4.1-7.58-4.86-16.86c-.73-8.82-1.1-14.63,4.86-16.86,2.51-.94,4.86,7.55,4.86,16.86Z"/>
  <ellipse id="Anatomy85" class="Halswirbelsaule_vd_stehend1601_cls2" cx="93.7" cy="468.62" rx="4.99" ry="14.49"/>
  <path id="Anatomy84" class="Halswirbelsaule_vd_stehend1601_cls2" d="M225.32,452.61c0,15.28,2.26,27.65,5.06,27.65"/>
  <path id="Anatomy83" class="Halswirbelsaule_vd_stehend1601_cls2" d="M234.23,480.25c0-15.28-.95-27.65-2.13-27.65"/>
  <path id="Anatomy82" class="Halswirbelsaule_vd_stehend1601_cls2" d="M227.85,382.13c0,13.77,1.9,24.91,4.25,24.91"/>
  <path id="Anatomy81" class="Halswirbelsaule_vd_stehend1601_cls2" d="M238.48,400.05c0-9.91-1.36-17.92-3.04-17.92"/>
  <path id="Anatomy80" class="Halswirbelsaule_vd_stehend1601_cls2" d="M97.22,380.91c0,11.42,1.61,20.66,3.59,20.66"/>
  <path id="Anatomy79" class="Halswirbelsaule_vd_stehend1601_cls2" d="M104.2,382.13v19.44"/>
  <path id="Anatomy78" class="Halswirbelsaule_vd_stehend1601_cls2" d="M167.39,392.15c-2.19.18-2.02,3.97-6.38,9.11-3.82,4.51-5.83,3.81-7.29,7.29-.32.77-.36,1.13-.61,10.03-.11,4.06-.15,6.09.61,6.38,1.19.45,2.38-3.17,5.77-3.95,1.58-.36,3.2.26,6.38,1.52,5.06,2.01,5.5,3.15,8.81,3.95,2.18.52,5.4,1.3,7.29-.3,2.5-2.11,1.29-7.19.61-10.03-.98-4.1-2.34-5.76-8.2-15.19-4.33-6.98-5.38-8.95-6.99-8.81Z"/>
  <path id="Anatomy77" class="Halswirbelsaule_vd_stehend1601_cls2" d="M165.87,482.08c-.92-.12-1.63,1.71-4.56,7.29-3.16,6.03-3.56,6.24-4.25,8.51-.72,2.36-.88,4.2-1.22,7.9-.33,3.7-.48,5.56.3,6.38,1.49,1.54,3.7-.28,8.81.61,3.45.6,3.97,1.7,5.77,1.22,3.45-.94,4.73-5.86,4.86-6.38.95-3.84-.44-7.15-1.52-9.72-2.16-5.14-4.14-5.07-6.08-10.33-1.21-3.3-1.18-5.34-2.13-5.47h.02Z"/>
  <path id="Anatomy76" class="Halswirbelsaule_vd_stehend1601_cls2" d="M100.8,304.05c-3.74,7.33.34,13.76,5.22,13.97"/>
  <path id="Anatomy75" class="Halswirbelsaule_vd_stehend1601_cls2" d="M110.28,320.15c1.5-7.56-.67-16.33-4.25-18.53"/>
  <path id="Anatomy74" class="Halswirbelsaule_vd_stehend1601_cls2" d="M229.98,306.79c0,10.91,3.8,19.75,8.5,19.75"/>
  <path id="Anatomy73" class="Halswirbelsaule_vd_stehend1601_cls2" d="M242.73,312.56c0,7.72-1.9,13.97-4.25,13.97"/>
  <path id="Anatomy72" class="Halswirbelsaule_vd_stehend1601_cls2" d="M154.33,324.1c-26.67,22.75-13.27,36.25,6.08,23.08"/>
  <path id="Anatomy71" class="Halswirbelsaule_vd_stehend1601_cls2" d="M166.31,318.03c26.95,20.01,43.53,35.21,15.36,32.2"/>
  <path id="Anatomy70" class="Halswirbelsaule_vd_stehend1601_cls2" d="M158.58,242.08c-7.81-1.73-15.33,9.41-18.53,21.87"/>
  <path id="Anatomy69" class="Halswirbelsaule_vd_stehend1601_cls2" d="M169.83,256.66c0,8.06-1.58,14.58-3.52,14.58"/>
  <path id="Anatomy68" class="Halswirbelsaule_vd_stehend1601_cls2" d="M236.96,223.71c-4.35,4.98-1.59,12.59,0,13.5"/>
  <path id="Anatomy67" class="Halswirbelsaule_vd_stehend1601_cls2" d="M102.23,219.06c8.05,2.67,1.77,13.3,3.95,13.3"/>
  <path id="Anatomy66" class="Halswirbelsaule_vd_stehend1601_cls2" d="M111.19,151.24c0-10.41-2.11-18.84-4.71-18.84"/>
  <path id="Anatomy65" class="Halswirbelsaule_vd_stehend1601_cls2" d="M243.65,157.93c-4.22-8.61-.02-14.1,5.55-13.37"/>
  <path id="Anatomy64" class="Halswirbelsaule_vd_stehend1601_cls2" d="M147.04,183.14c0-13.94,5.98-25.22,13.37-25.22"/>
  <path id="Anatomy63" class="Halswirbelsaule_vd_stehend1601_cls2" d="M189.27,183.14c0-12.93-6.25-23.39-13.97-23.39"/>
  <line id="Anatomy62" class="Halswirbelsaule_vd_stehend1601_cls2" x1="158.58" y1="151.24" x2="160.41" y2="157.93"/>
  <line id="Anatomy61" class="Halswirbelsaule_vd_stehend1601_cls2" x1="177.11" y1="151.24" x2="175.29" y2="159.75"/>
  <line id="Anatomy60" class="Halswirbelsaule_vd_stehend1601_cls3" x1="17.62" y1="590.23" x2="77.6" y2="620.9"/>
  <line id="Anatomy59" class="Halswirbelsaule_vd_stehend1601_cls3" x1="3.95" y1="625.47" x2="72" y2="647.65"/>
  <line id="Anatomy58" class="Halswirbelsaule_vd_stehend1601_cls3" x1="308.96" y1="580.25" x2="249.27" y2="613.46"/>
  <line id="Anatomy57" class="Halswirbelsaule_vd_stehend1601_cls3" x1="314.16" y1="620.22" x2="254.66" y2="636.17"/>
  <path id="Anatomy56" class="Halswirbelsaule_vd_stehend1601_cls3" d="M77.6,620.9c46.75,3.18,28.68,34.03-5.6,26.75"/>
  <path id="Anatomy55" class="Halswirbelsaule_vd_stehend1601_cls3" d="M249.27,613.46c-44.82-4.77-29.66,36.58,5.66,23.1"/>
  <path id="Anatomy54" class="Halswirbelsaule_vd_stehend1601_cls3" d="M113.92,713.52c18.05,27.19,1.82,40.94-22.2,36.3"/>
  <path id="Anatomy53" class="Halswirbelsaule_vd_stehend1601_cls3" d="M78.08,760.66c-2.3,8.01-12.56,10.29-20.66-6.39"/>
  <path id="Anatomy52" class="Halswirbelsaule_vd_stehend1601_cls3" d="M235.99,693.78c-33.95,36.16-18.19,59.64,8.08,38.33"/>
  <path id="Anatomy51" class="Halswirbelsaule_vd_stehend1601_cls3" d="M21.87,664.36c60.62,18.84,24.91,30.3,60.62,32.59"/>
  <path id="Anatomy50" class="Halswirbelsaule_vd_stehend1601_cls3" d="M257.92,705.67c21.94-5.15,47.82-15.14,57.42-22.48"/>
  <path id="Anatomy49" class="Halswirbelsaule_vd_stehend1601_cls3" d="M325.06,720.86c-1.73-4.04-21.21,5.46-43.14,25.52"/>
  <line id="Anatomy48" class="Halswirbelsaule_vd_stehend1601_cls3" x1="281.92" y1="746.38" x2="257.92" y2="746.38"/>
  <line id="Anatomy47" class="Halswirbelsaule_vd_stehend1601_cls3" x1="74.96" y1="728.76" x2="78.08" y2="760.66"/>
  <path id="Anatomy46" class="Halswirbelsaule_vd_stehend1601_cls3" d="M260.34,705.09c-10.27-.84-4.08,39.34-2.42,41.29"/>
  <path id="Anatomy45" class="Halswirbelsaule_vd_stehend1601_cls3" d="M29.16,740.21c8.98,1.61,24.62,5.83,28.25,14.05"/>
  <path id="Anatomy44" class="Halswirbelsaule_vd_stehend1601_cls3" d="M31.29,713.52c9.11,3.8,33.92,7.94,43.67,15.24"/>
  <path id="Anatomy43" class="Halswirbelsaule_vd_stehend1601_cls3" d="M24.91,687.44c10.69,8.64,54.13,15.07,63.08,23.51"/>
  <path id="Anatomy42" class="Halswirbelsaule_vd_stehend1601_cls3" d="M76.52,744.71c1.78-.52,10.03.28,16.61,5.35"/>
  <path id="Anatomy41" class="Halswirbelsaule_vd_stehend1601_cls3" d="M76.52,661.21c6.11,14.92,5.09,33.82,5.97,35.73"/>
  <path id="Anatomy39" class="Halswirbelsaule_vd_stehend1601_cls3" d="M317.77,661.21c-33.09-6.88-37.86,35.73-84.65,35.73"/>
  <path id="Anatomy38" class="Halswirbelsaule_vd_stehend1601_cls3" d="M244.08,639.15c-15.46,13.38-7.84,36.2,4.73,42.83"/>
  <path id="Anatomy37" class="Halswirbelsaule_vd_stehend1601_cls3" d="M315.19,648.75c-57.18,2.24-29.76,33.22-66.53,33.22"/>
  <path id="Anatomy36" class="Halswirbelsaule_vd_stehend1601_cls3" d="M26.43,643.39c10.3.34,33.64,2.2,44.09,6.88"/>
  <line id="Anatomy35" class="Halswirbelsaule_vd_stehend1601_cls3" x1="254.93" y1="725.73" x2="244.08" y2="732.11"/>
  <path id="Anatomy34" class="Halswirbelsaule_vd_stehend1601_cls4" d="M17.32,53.42c-.46.08-3.79.71-5.92,3.65-2.29,3.15-1.62,6.8-1.37,8.2.09.47,1.17,5.97,5.92,8.66,2.27,1.28,4.16,1.24,7.75,1.37,12.08.42,11.01,1.59,18.68,1.37,8.51-.25,7.59-1.62,13.67-1.37,5.72.24,9.99,1.6,16.41,3.65,2.62.83,6.19,2.24,13.22,5.01,7.64,3.02,11.46,4.52,14.58,5.92,9.49,4.26,10.76,5.93,15.95,8.2,5.4,2.36,9.34,2.88,25.52,4.1,17.99,1.36,26.98,2.04,35.54,2.28,18.05.5,27.07.76,33.72-.91,2.76-.69,8.11-2.24,15.95-3.19,6.92-.84,7.21-.16,12.3-.91,8.48-1.24,8.91-3.28,20.51-6.38,2.8-.75,9.14-2.33,17.32-3.19,4.37-.46,7.14-.74,10.94-.46,5.96.44,6.62,1.68,14.58,3.19,7.08,1.34,11.84,1.37,15.95,1.37,8.91,0,10.83-1.32,11.85-2.28,2.41-2.27,3.36-5.92,2.73-9.11-1.12-5.71-6.89-7.88-7.75-8.2-3.5-1.32-5.73-.39-11.85.46-6.74.93-13.07,1.81-20.05.46-5-.97-9.63-1.87-14.58-5.01-2.35-1.5-4.26-3.17-7.29-3.19-2.98-.02-4.22,1.57-7.29,2.73-2.59.98-4.63.95-12.76,0-9.79-1.15-16.86-2.28-16.86-2.28-3.71-.59-7.24-1.18-16.41-2.73-13.45-2.28-13.79-2.38-16.41-2.73-4.66-.64-5.28-.58-17.77-1.82-16.58-1.64-16.34-1.83-19.59-1.82-5.1,0-11.83.02-20.05,2.28-8.15,2.24-9.76,4.5-16.86,5.01-3.8.27-6.68-.13-12.3-.91-6.42-.9-11.27-2.18-15.04-3.19-5.88-1.58-8.96-2.4-13.22-4.1-7-2.79-8.6-4.52-12.3-4.1-3.66.42-3.6,2.28-8.66,4.1-2.6.93-6.63,1.09-14.58,1.37-6.08.21-9.18.31-12.3,0-2.93-.29-6.08-.6-10.03-1.82-6.54-2.02-8.06-4.3-11.85-3.65v-.03Z"/>
  <path id="Anatomy33" class="Halswirbelsaule_vd_stehend1601_cls4" d="M119.92,44.97c0,.89,55.37-49.88,118.41,1.61"/>
  <path id="Anatomy32" class="Halswirbelsaule_vd_stehend1601_cls4" d="M74.73,53.42c0-5.04-33.43-9.11-74.73-9.11"/>
  <path id="Anatomy31" class="Halswirbelsaule_vd_stehend1601_cls4" d="M271.59,67.09c0-5.79,17.12-10.48,38.28-10.48"/>
  <path id="Anatomy30" class="Halswirbelsaule_vd_stehend1601_cls4" d="M74.96,247.09c0,14.61-7.12,26.43-15.93,26.43"/>
  <path id="Anatomy29" class="Halswirbelsaule_vd_stehend1601_cls4" d="M47.85,271.24c0-13.35,8.56-24.15,19.15-24.15"/>
  <path id="Anatomy28" class="Halswirbelsaule_vd_stehend1601_cls4" d="M46.03,273.52c0-17.48,9.38-31.62,20.97-31.62"/>
  <path id="Anatomy27" class="Halswirbelsaule_vd_stehend1601_cls4" d="M281.16,268.05c0-14.46-7.68-26.15-17.18-26.15"/>
  <path id="Anatomy26" class="Halswirbelsaule_vd_stehend1601_cls4" d="M252,263.95c1.7,14.59,15.21,19.82,29.16,4.1"/>
  <path id="Anatomy25" class="Halswirbelsaule_vd_stehend1601_cls4" d="M252,254.98c0,7.23,13.04,13.08,29.16,13.08"/>
  <path id="Anatomy24" class="Halswirbelsaule_vd_stehend1601_cls4" d="M82.03,338.23c0,11.71-14,21.19-31.29,21.19"/>
  <path id="Anatomy23" class="Halswirbelsaule_vd_stehend1601_cls4" d="M50.73,350.61c0-17.17,7-31.06,15.65-31.06"/>
  <path id="Anatomy22" class="Halswirbelsaule_vd_stehend1601_cls4" d="M55.68,350.61c0-14.65,6.3-26.51,14.08-26.51"/>
  <path id="Anatomy21" class="Halswirbelsaule_vd_stehend1601_cls4" d="M277.52,363.29c0-16.02-9.17-28.97-20.51-28.97"/>
  <path id="Anatomy20" class="Halswirbelsaule_vd_stehend1601_cls4" d="M252,344.15c0,10.58,11.41,19.14,25.52,19.14"/>
  <path id="Anatomy19" class="Halswirbelsaule_vd_stehend1601_cls4" d="M249.19,356.46c0,3.78,12.67,6.84,28.33,6.84"/>
  <path id="Anatomy18" class="Halswirbelsaule_vd_stehend1601_cls4" d="M252,431.65c0,6.05,14.5,10.94,32.41,10.94"/>
  <path id="Anatomy17" class="Halswirbelsaule_vd_stehend1601_cls4" d="M263.36,419.8c0,12.59,9.42,22.78,21.06,22.78"/>
  <path id="Anatomy16" class="Halswirbelsaule_vd_stehend1601_cls4" d="M284.41,442.58c0-16.79-7.63-30.38-17.07-30.38"/>
  <path id="Anatomy15" class="Halswirbelsaule_vd_stehend1601_cls4" d="M74.96,417.86c-6.73,5.21-15.14,15.17-27.35,16.97"/>
  <path id="Anatomy14" class="Halswirbelsaule_vd_stehend1601_cls4" d="M66.38,412.2c.18,8.86-8.27,20.1-18.77,22.63"/>
  <path id="Anatomy13" class="Halswirbelsaule_vd_stehend1601_cls4" d="M66.38,503.65c-3.89,11.72-22.57,21.08-35.09,15.86"/>
  <path id="Anatomy12" class="Halswirbelsaule_vd_stehend1601_cls4" d="M61.29,498.09c-8.42,6.94-17.83,18.85-30,21.42"/>
  <path id="Anatomy11" class="Halswirbelsaule_vd_stehend1601_cls4" d="M252,503.65c2.58,2.12,17.69,5.8,25.52-1.82"/>
  <path id="Anatomy10" class="Halswirbelsaule_vd_stehend1601_cls4" d="M260.66,483.1c0,10.35,9.51,18.72,21.27,18.72"/>
  <path id="Anatomy9" class="Halswirbelsaule_vd_stehend1601_cls4" d="M279.12,495.9c0-10.83-7.05-19.59-15.76-19.59"/>
  <path id="Anatomy8" class="Halswirbelsaule_vd_stehend1601_cls4" d="M31.29,587.5c0-10.73,6.71-19.41,15-19.41"/>
  <path id="Anatomy7" class="Halswirbelsaule_vd_stehend1601_cls4" d="M284.41,577.79c0-8.99-5.93-16.27-13.27-16.27"/>
  <polyline id="Anatomy6" class="Halswirbelsaule_vd_stehend1601_cls4" points="55.04 154.58 43.29 171.85 43.29 191.95 55.04 206.99 55.04 232.36 41.47 261.66 42.23 283.09 50.73 304.05 42.23 326.53 42.23 344.15 48.26 359.42 47.61 374.23 35.54 394.58 35.54 409.64 42.23 426.35 38.73 442.58 37.37 454.13 42.23 467.19"/>
  <path id="Anatomy5" class="Halswirbelsaule_vd_stehend1601_cls4" d="M42.23,423.52c0-9.11,5.73-16.48,12.82-16.48"/>
  <polyline id="Anatomy4" class="Halswirbelsaule_vd_stehend1601_cls4" points="279.34 159.75 284.41 170.53 290.73 183.14 289.72 198.33 284.41 216.56 281.16 230.47 283.99 247.09 287.54 266.23 285.33 286.74 281.16 301.62 275.88 318.03 281.16 334.32 287.09 356.46 285.33 368.76 284.41 388.81 290.73 415.28 290.73 439.53 284.41 458.08 284.41 482.07 290.73 495.9 288.46 509.08 285.45 528.25 295.29 546.6 298.03 563.5 298.03 577.79"/>
  <path id="Anatomy3" class="Halswirbelsaule_vd_stehend1601_cls4" d="M49.22,481.77l-11.24,14.13-11.54,20.96v21.08l4.86,8.65-1.67,12.28-7.75,11.3s-7.29,15.49-7.29,17.32v5.47"/>
  <line id="Anatomy2" class="Halswirbelsaule_vd_stehend1601_cls4" x1="42.23" y1="467.19" x2="42.23" y2="490.56"/>
  <line id="Anatomy1" class="Halswirbelsaule_vd_stehend1601_cls4" x1="325.06" y1="775.38" x2="279.12" y2="787.39"/>
</g>
<g id="Overlays">
  <g id="HWK">
    <path id="HWK8" class="HWS1601Fill" style= {{
            fill: props.colors.HWS1601Fill,
            stroke: props.colors.HWS1601Fill,
            opacity:props.colors.HWS1601Fill,
          }} d="M55.71,103.34c-3.21-2.54,4.45-11.12,6.15-30.76,1.1-12.73-1.41-17.32,2.73-22.56,3.98-5.04,10.35-5.92,21.87-7.52,13.94-1.93,23.81.19,25.97.68,8.88,2.02,14.78,3.36,19.14,8.89,3.99,5.06,6.15,12.94,3.42,19.82-.66,1.66-3.04,6.89-8.2,8.89-3.8,1.47-7.21.46-9.57,0-6.54-1.28-12.56.74-24.61,4.78-23.13,7.76-33.07,20.8-36.91,17.77h0Z"/>
    <path id="HWK7" class="HWS1601Fill" style= {{
            fill: props.colors.HWS1601Fill,
            stroke: props.colors.HWS1601Fill,
            opacity:props.colors.HWS1601Fill,
          }} d="M289.48,60.27c.74,2.52-2.03,4.13-4.94,11.09,0,0-1.98,4.75-3.27,12.84-2.77,17.46,6.08,32.76,2.9,34.57-2.37,1.35-6.74-7.39-19.3-14.06-2.54-1.35-3.34-1.47-19.82-6.84-18.72-6.1-19.96-6.38-22.56-8.2-6.1-4.3-15.66-14.3-13.67-24.61,2.25-11.66,18.18-18,29.39-18.46,9.25-.37,9.58,3.5,28.71,7.52,12.88,2.71,21.35,2.06,22.56,6.15Z"/>
    <path id="HWK6" class="HWS1601Fill" style= {{
            fill: props.colors.HWS1601Fill,
            stroke: props.colors.HWS1601Fill,
            opacity:props.colors.HWS1601Fill,
          }} d="M222.93,168.66c-.17-.57-.28-1.19-.28-1.88v-62.04c-13.55-8.16-17.55-7.68-24.08-15.07-2.89-3.28-8.5-9.64-8.2-17.77.19-5.22,2.62-5.71,4.1-12.99.7-3.43,2.48-12.18-2.05-20.51-.82-1.5-4.04-7.14-10.94-10.25-8.13-3.66-17.63-2.13-24.23,2.81-1.92,1.44-6.63,4.96-7.9,10.86-1.57,7.24,3.92,9.79,4.78,19.82.18,2.08,1.21,12.31-4.78,19.14-3.95,4.5-8.63,5.09-18.46,8.2-9.5,3.01-6.26,2.81-21.55,8.74.66,24.53,9.9,47.95,13.9,72.03.26,1.59-.04,2.98-.69,4.12.11.19.22.38.34.58,2.31,3.64,4.08,3,6.63,6.13,4.69,5.75,1.83,11.77,5.47,13.67,2,1.04,3.5-.44,8.58-1.37,4.59-.84,7.61-.4,12.61,0,20.43,1.65,21.83-1.44,32.13,1.37,11.07,3.02,14.7,8.02,18.76,5.62,3.9-2.31,1.54-7.52,6.53-15.87,3.49-5.84,8.17-9.2,11.42-11.48-1.19-.95-1.89-2.36-2.1-3.85Z"/>
    <path id="HWK5" class="HWS1601Fill" style= {{
            fill: props.colors.HWS1601Fill,
            stroke: props.colors.HWS1601Fill,
            opacity:props.colors.HWS1601Fill,
          }} d="M247.79,196.84c-.38-4.39-2.59-11.83-7.9-14.28-2.18-1.01-3.95-.73-5.16-2.43-2.15-3.01.71-7.63-.3-8.2-1-.56-4.81,3.47-8.2,8.81-3.63,5.72-3.92,8.43-7.29,14.58-2.75,5.04-4.53,8.29-7.07,10.55-6.77,6.04-13.88.52-35.76.69-11.35.09-22.69-.14-34.03,0-2.75.04-5.83.08-8.81-1.82-4.05-2.59-4.6-6.68-10.03-18.53-3.56-7.76-4.6-8.78-5.76-8.84-3.07-.15-5.89,6.53-8.52,12.78-1.15,2.73-2.63,6.24-3.65,11.24-.62,3.03-.74,5.02-.8,6.45,2.88,14.82,10.35,28.24,15.12,42.49.57,1.14,1.15,2.34,1.78,3.63,4.57,9.45,3.12,10.68,6.08,13.97,1.14,1.26,4.47,4.54,21.27,6.68,8.82,1.12,15.52,1.06,28.56.91,14.05-.17,18.52-.86,23.39-3.34,3.47-1.77,5.63-3.68,9.72-7.29,8.68-7.67,9.28-11.55,17.01-16.71.7-.47,1.47-.97,2.31-1.49,4.43-10.74,11.3-20.45,14.1-31.84.14-.59.35-1.1.6-1.56-1.53-3.37,4.09-7.85,3.35-16.46Z"/>
    <path id="HWK4" class="HWS1601Fill" style= {{
            fill: props.colors.HWS1601Fill,
            stroke: props.colors.HWS1601Fill,
            opacity:props.colors.HWS1601Fill,
          }} d="M230.79,323.57c-.34-8.96-.38-17.94-.38-26.91,0-.53.06-1.02.17-1.48-.9-3.66,1.41-8.83-1.02-16.93-1.24-4.16-4.81-12.63-9.12-12.69-5.79-.07-5.7,15.09-17.92,23.63-4.58,3.21-9.53,3.96-19.44,5.47-15.96,2.43-27.79.33-37.67-1.52-12.35-2.31-15.9-4.71-18.23-6.99-5.1-4.97-7.08-11.98-7.29-12.76-1.89-6.97-.04-10.67-1.82-11.54-2.94-1.43-12.38,6.45-15.8,17.32-2.33,7.4-.51,11.81-1.34,14.98,0,0,0,0,0,0,1.89,10.81,5.89,21.06,7.52,31.9.28.26.54.53.79.81,2.84,3.35.71,5.64,3.34,11.24,1.85,3.92,4.6,6.21,10.03,10.63,5.09,4.13,8.72,7.09,13.37,9.11,6.63,2.88,8.79,1.01,28.56.91,16.79-.09,25.82,0,25.82,0,7.04.06,9.65.15,13.37-1.22,2.69-1,6.66-4.31,14.58-10.94,4.92-4.12,6.59-5.75,7.29-8.51.92-3.6-.75-5.46.61-7.29.66-.88,1.65-1.24,3.14-1.33.5-1.97.99-3.93,1.44-5.9Z"/>
    <path id="HWK3" class="HWS1601Fill" style= {{
            fill: props.colors.HWS1601Fill,
            stroke: props.colors.HWS1601Fill,
            opacity:props.colors.HWS1601Fill,
          }} d="M231.09,363.92c-1.36-4.96-4.81-13.01-8.51-13.06-2.27-.03-3.49,2.97-6.99,8.51-1.98,3.14-7.94,12.59-13.67,17.32-5.96,4.92-12.58,5.51-22.48,6.38-12.71,1.12-11.4-2.37-31.9-3.04-5.63-.18-10.14-.06-14.89-3.04-2.83-1.77-4.44-3.82-8.51-10.03-9.88-15.06-11.54-20.02-14.28-19.75h.02c-2.93.29-4.79,6.33-6.68,12.46-1.56,5.06-1.71,7.92-2.01,10.04.33,13.27,2.15,26.4,4.6,39.42.67.57,1.33,1.2,1.95,1.89,3.28,3.66,2.65,5.83,5.47,11.54,2.75,5.55,7.29,11.49,11.85,14.58,7.99,5.42,12.8-.06,35.85.61,7.76.23,13.8,1.04,27.04.91,8.75-.08,12.89-.51,16.71-3.34,4.42-3.28,3.98-6.49,9.42-12.46.9-.98,5.27-5.67,12.15-9.11.13-.06.25-.11.37-.17,2.9-12.79,4.59-25.83,6.94-38.73-1.44-2.21-1.03-5.69-2.47-10.92Z"/>
    <path id="HWK2" class="HWS1601Fill" style= {{
            fill: props.colors.HWS1601Fill,
            stroke: props.colors.HWS1601Fill,
            opacity:props.colors.HWS1601Fill,
          }} d="M232.6,449.6c-1.51-1.16-.06-3.21-1.22-7.29-.82-2.92-2.78-5.08-6.68-9.42-3.38-3.76-5.11-5.64-6.71-5.57-2.88.12-4.62,4.22-6.96,8.61-4.81,9.01-10.25,15.58-11.85,17.31-5.68,6.15-12.07,4.32-59.24,3.04-9.37-.26-13.3-.3-17.92-3.04-11.9-7.06-12.19-22.41-18.84-22.48h0c-5.12-.04-9.8,9.02-10.33,10.04-2.33,4.52-2.24,7.92-3.44,10.1.54,13.92,2.91,27.74,2.62,41.7-.03,1.28-.39,2.35-.96,3.21.09.09.17.19.27.28,2.65,2.34,5.8-.05,9.42,2.43,2.41,1.64,1.79,3.23,4.56,8.2,1.52,2.73,4.84,8.7,10.63,11.24,3.22,1.41,6.1,1.29,7.9,1.22,10.42-.4,20.86-.06,31.29-.3,17.65-.41,29.47.3,29.47.3,7.27.43,11.2.95,15.49-1.52,4.96-2.85,4.58-6.32,10.63-14.89,2.66-3.76,7.93-11.1,16.41-16.41,1.15-.72,2.27-1.34,3.39-1.9,1.37-11.45,2.2-22.98,2.9-34.5-.3-.05-.57-.16-.83-.36Z"/>
    <path id="HWK1" class="HWS1601Fill" style= {{
            fill: props.colors.HWS1601Fill,
            stroke: props.colors.HWS1601Fill,
            opacity:props.colors.HWS1601Fill,
          }} d="M227.9,524.78c-.73-1.57-.78-3.6-2.9-6.83-.56-.85-4.97-7.51-9.72-6.99-2.06.23-3.43,1.88-6.08,5.16-4.51,5.59-4.78,8.49-9.42,14.28-3.17,3.96-4.79,4.78-5.77,5.16-3.53,1.38-6.84.28-9.11-.3-1.14-.29-7.24-.2-19.44,0-14.15.23-21.23.34-27.04.91-9.94.98-11.09,1.9-15.19.91-8.06-1.94-13.42-7.86-16.41-11.24-8.16-9.24-7.84-16.14-12.46-16.71h.01c-4.52-.54-9.89,5.46-11.85,10.95-2.24,6.26.83,9.4-2.13,15.49-.28.57-1.1,2.25-2.49,3.91-.15,10.83-1.69,21.54-3.19,32.26,1.87,1.72,3.93,4.26,6.87,7.89,5.54,6.83,9.03,11.14,11.54,17.92,1.53,4.12,2.13,7.65,5.47,9.72.41.26,3.42,2.07,6.68,1.22,2.26-.59,2.98-2.02,4.56-3.04,4.12-2.66,10.14-.03,12.76.91,2.92,1.06,10.96.7,27.04,0,16.27-.71,30.36-2.44,37.67-3.34,16.12-1.98,19.53-3.19,25.22-1.82,1.04.25,3.15.83,5.47,0,3.73-1.34,5.08-5.29,7.9-12.15,3.86-9.4,5.79-14.1,8.2-17.32.78-1.05,1.84-2.43,3.21-3.86-4.07-14.09-6.5-28.68-9.41-43.09Z"/>
  </g>
  <g id="BWS">
    <path id="BWS2" class="HWS_BWS1601Fill" style= {{
            fill: props.colors.HWS_BWS1601Fill,
            stroke: props.colors.HWS_BWS1601Fill,
            opacity:props.colors.HWS_BWS1601Fill,
          }} d="M247.78,744.19c-.2-4.01-2.18-5.72-2.73-10.03-.73-5.64.31-7.99.91-14.58,1.05-11.43-.64-19.73-1.82-25.52-.99-4.84-1.61-7.8-2.66-9.2.99,3.47-.86,7.87-5.53,7.87-.02,0-.04,0-.06,0-.88,1.54-2.47,2.66-4.74,2.73-.83.74-1.93,1.24-3.3,1.35,0,0-.02,0-.02,0-.46.3-.97.54-1.55.7-.83.64-1.88,1.06-3.18,1.12-2.61,2.45-5.57,4.94-9.03,7.26-13.89,6.63-28.46,10.88-35.88,11.78-.39.05-.77.06-1.16.04-15.96-.78-34.65-1.41-36.79-1.79-3.68-.66-6.18-.04-12.74-.19-7.52-.17-13.07-1.75-17.92-2.73-5.16-3.34-5.37-3.87-11.27-4.37-5.46-.46-12.42,1.88-15.49,7.29-2.5,4.41-3.01,8.93-.91,11.85,3.97,7.1,5.23,10.04,8.84,18.34.75,1.72.96,0,3.92,15.39,2.6,13.51.8,15.35.63,18.03-.24,3.92.69,9.35-4.28,16.61l155.86-7.3c1.59-5.85,2.34-10.87,2.73-14.58.73-6.97-1.01-14.15-1.82-30.08Z"/>
    <path id="BWS1" class="HWS_BWS1601Fill" style= {{
            fill: props.colors.HWS_BWS1601Fill,
            stroke: props.colors.HWS_BWS1601Fill,
            opacity:props.colors.HWS_BWS1601Fill,
          }} d="M81.64,698.76c.13-2.2,3.51-2.27,6.08-5.16,4.64-5.24,3.73-16.47-1.22-23.7-6.32-9.23-17.4-9.26-17.62-15.49-.05-1.54.6-1.87,3.04-6.38,3.56-6.6,5.92-10.96,6.68-15.8,1.17-7.37-2.44-8.97-.91-18.23.53-3.22,1.17-6.78,3.65-7.9,2.58-1.16,5.44,1.1,10.33,3.65,4.8,2.5,10.14,3.83,15.19,5.77,7.05,2.71,13.68,2.89,37.67,2.73,30.35-.2,34.92-.59,40.71-1.52,14.75-2.36,22.13-3.54,26.43-8.81,1.01-1.24,3.82-5.09,7.29-4.86,1.89.13,2.44,1.36,4.86,2.73,3.13,1.78,4.83,1.21,11.85,2.13,6.36.83,9.55,1.25,11.24,2.73,3,2.63,3.48,6.86,3.95,10.94.71,6.21-1.01,7.39.3,10.94.97,2.63,2.4,3.29,8.81,8.51,10.58,8.61,11.46,10.68,11.85,11.85,1.83,5.53-.91,10.95-1.52,12.15-.84,1.67-2.94,5.82-7.29,7.59-5.27,2.15-8.84-1.22-11.85,1.22-2.32,1.88-1.38,4.85-2.43,8.51-.56,1.93-2.39,6.54-15.19,13.06-5.14,2.62-11.45,5.24-31.9,10.33-11.48,2.86-17.22,4.28-20.66,4.56-11.4.91-13.78-1.58-30.38-1.82-10.54-.16-9.17.85-20.35.61-15.04-.33-14.22-2.08-26.13-1.82-3.4.07-7.24.3-12.15-1.22-4.12-1.27-10.51-4.22-10.33-7.29h0Z"/>
  </g>
  <path id="transition" class="HWS_Skull1601Fill" style= {{
            fill: props.colors.HWS_Skull1601Fill,
            stroke: props.colors.HWS_Skull1601Fill,
            opacity:props.colors.HWS_Skull1601Fill,
          }} d="M305.22,14.58c0,5.03-56.87,5.47-127.59,5.47s-128.51-.43-128.51-5.47S100.52,0,171.24,0s133.97,9.55,133.97,14.58Z"/>
  <g id="Processus_spinosi" data-name="Processus spinosi" class="Halswirbelsaule_vd_stehend1601_cls12">
    <path id="Processus_spinosi6" data-name="Processus spinosi6" class="Processus_spinosi1601Fill" style= {{
            fill: props.colors.Processus_spinosi1601Fill,
            stroke: props.colors.Processus_spinosi1601Fill,
            opacity:props.colors.Processus_spinosi1601Fill,
          }} d="M170.03,257.92c0-9.51-10.01-20.64-17.32-15.49-3.29,2.32-5.38,4.18-7.73,7.2-.22,1.05-.69,2.03-1.41,2.82-.18.57-.44,1.11-.78,1.59-.24,1.01-.69,1.96-1.4,2.72-.14.98-.46,1.92-1.01,2.73-.1.44-.23.86-.42,1.27-.01,1.07-.27,2.14-.78,3.07,2.61,5.59,12.38,10.07,18.99,9.91,12.46-.3,11.85-6.29,11.85-15.8Z"/>
    <path id="Processus_spinosi5" data-name="Processus spinosi5" class="Processus_spinosi1601Fill" style= {{
            fill: props.colors.Processus_spinosi1601Fill,
            stroke: props.colors.Processus_spinosi1601Fill,
            opacity:props.colors.Processus_spinosi1601Fill,
          }} d="M196.69,345.11c-.31-.35-.6-.75-.86-1.22-2.77-5.08-6.98-8.47-11.08-12.31-1.83-.29-3.12-1.33-3.89-2.68-2.66-.27-4.27-2.05-4.85-4.16-.6-.37-1.1-.85-1.49-1.4-1.46-.28-2.57-1.05-3.32-2.07-1.06-.39-4.29-2.41-4.89-3.24-5.02,2-7.68,5.17-12.84,6.83-.62.54-1.38.95-2.3,1.17-.4.53-.82,1.05-1.29,1.56-.56.62-1.15,1.21-1.71,1.84-.27.3-.84,1.34-.22.21-.22.4-.49.77-.71,1.18-.61,1.13-1.56,1.88-2.65,2.34-.35.61-.81,1.16-1.38,1.6-.13.14-.25.3-.4.43-1.96,3.87-3.18,7.53-3.18,10.22,0,11.14,10.99,5.16,27.04,5.16,2.81,0,5.55.21,8.16.5.39-.07.81-.12,1.25-.12.62,0,1.19.09,1.72.23.5-.13,1.03-.23,1.63-.23.63,0,1.2.09,1.73.23.84-.53,1.89-.84,3.14-.84s2.24.3,3.07.8c.44.11.85.26,1.22.46.62-.22,1.31-.34,2.09-.34.15,0,.3.01.44.02.41-.14.83-.25,1.31-.29.89-1.03,2.2-1.73,3.91-1.84.27-.78.42-1.71.42-2.83,0-.42-.03-.82-.07-1.22Z"/>
    <path id="Processus_spinosi4" data-name="Processus spinosi4" class="Processus_spinosi1601Fill" style= {{
            fill: props.colors.Processus_spinosi1601Fill,
            stroke: props.colors.Processus_spinosi1601Fill,
            opacity:props.colors.Processus_spinosi1601Fill,
          }} d="M188.47,175.2c-.59-3.68-1.29-8.11-4.56-11.54-3.68-3.87-10.04-3.27-8.51-6.38,1.15-2.34,1.52-3.93.97-6.13-6.1-.69-12.23-.59-18.36-.55.2.82.24,1.67.12,2.51.72,1.48,1.79,2.7,1.27,3.82-.4.87-1.42.92-2.73,1.11-.6,1.29-1.63,2.35-3.11,2.86-.45.91-1.11,1.7-2.01,2.25-.25.91-.7,1.75-1.34,2.44-.15,1.07-.54,2.09-1.18,2.94-.11,1.36-.6,2.69-1.5,3.69-.06.74-.24,1.46-.54,2.13.43,1.54.31,3.26-.38,4.7.08,1.09-.1,2.21-.54,3.2,3,6.75,15.68,6.87,22.02,6.92,6.5.06,17.53.15,20.35-5.77.98-2.05.65-4.15,0-8.2Z"/>
    <path id="Processus_spinosi3" data-name="Processus spinosi3" class="Processus_spinosi1601Fill" style= {{
            fill: props.colors.Processus_spinosi1601Fill,
            stroke: props.colors.Processus_spinosi1601Fill,
            opacity:props.colors.Processus_spinosi1601Fill,
          }} d="M167.61,392.37c-2.19.18-2.02,3.97-6.38,9.11-3.82,4.51-5.83,3.81-7.29,7.29-.32.77-.36,1.13-.61,10.03-.11,4.06-.15,6.09.61,6.38,1.19.45,2.38-3.17,5.77-3.95,1.58-.36,3.2.26,6.38,1.52,5.06,2.01,5.5,3.15,8.81,3.95,2.18.52,5.4,1.3,7.29-.3,2.5-2.11,1.29-7.19.61-10.03-.98-4.1-2.34-5.76-8.2-15.19-4.33-6.98-5.38-8.95-6.99-8.81Z"/>
    <path id="Processus_spinosi2" data-name="Processus spinosi2" class="Processus_spinosi1601Fill" style= {{
            fill: props.colors.Processus_spinosi1601Fill,
            stroke: props.colors.Processus_spinosi1601Fill,
            opacity:props.colors.Processus_spinosi1601Fill,
          }} d="M166.09,481.62c-.92-.12-1.63,1.71-4.56,7.29-3.16,6.03-3.56,6.24-4.25,8.51-.72,2.36-.88,4.2-1.22,7.9-.33,3.7-.48,5.56.3,6.38,1.49,1.54,3.7-.28,8.81.61,3.45.6,3.97,1.7,5.77,1.22,3.45-.94,4.73-5.86,4.86-6.38.95-3.84-.44-7.15-1.52-9.72-2.16-5.14-4.14-5.07-6.08-10.33-1.21-3.3-1.18-5.34-2.13-5.47h.02Z"/>
    <path id="Processus_spinosi1" data-name="Processus spinosi1" class="Processus_spinosi1601Fill" style= {{
            fill: props.colors.Processus_spinosi1601Fill,
            stroke: props.colors.Processus_spinosi1601Fill,
            opacity:props.colors.Processus_spinosi1601Fill,
          }} d="M176.59,581.36c-3.28,36.58-3.13,3.05-14.58,10.03-6.55,3.99-10.66,26.59-17.38-10.39-2.16-11.89,7.69-21.87,17.17-21.87s15.88,10.2,14.8,22.23h0Z"/>
  </g>
  <g id="Platten">
    <path id="Platten12" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M129.32,265.22c3.83-.94,6.46,1.75,10.99,3.64,2.35.98,6.28,1.26,14.13,1.82,6.23.45,10.77.46,19.59.46,8.87,0,13.33,0,14.98-.45,7.53-2.02,10.5-6.02,15.49-4.56,2.33.69,4.95,2.52,5.01,4.56.09,2.72-4.41,4.8-6.84,5.92-4.21,1.95-8.79,2.39-17.77,3.19-5.98.53-8.24.48-9.11.46-3.99-.13-5.36-.6-9.57-.91-5.05-.37-5.48.13-10.94,0-5.97-.14-10.02-.86-15.49-1.82-5.92-1.04-8.75-1.93-11.39-4.1-1.09-.9-3.55-2.91-3.13-5.02.34-1.74,2.5-2.81,4.04-3.19Z"/>
    <path id="Platten11" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M118.84,281.62c-1.03,3.89,9.31,10.89,19.2,13.93,2.91.9,5.41,1.27,10.42,2.02,10,1.49,17.67,1.54,19.59,1.54,10.28,0,17.56-1.25,22.84-2.19,6.7-1.2,10.21-2.32,13.61-4.82,3.47-2.54,7.46-6.98,6.38-9.11-.69-1.36-3.28-1.48-4.56-1.37-2.06.18-2.9,1.17-5.92,2.73-2.24,1.16-1.92.69-5.47,2.28-5.46,2.45-5.71,3.33-8.2,3.65-2.26.29-2.38-.39-5.92-.46-3.48-.06-5.11.57-7.75.91-3.67.47-4.84-.19-11.39-.91-8.43-.92-8.16,0-15.49-.91-6.74-.83-10.11-1.25-12.7-2.93-4.59-2.98-5.42-6.86-9.17-7.1-2.17-.14-5,.96-5.47,2.73Z"/>
    <path id="Platten10" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M132.56,350.25c3.92.42,3.82,3.8,9.51,4.28,6.6.56,8.85-2.52,12.82-2,4.81.63,9.74.57,19.59.46,4.62-.05,9.24.05,13.86.06,1.09,0,3.12-.02,5.73-.06,11.73-.2,13.07-.69,14.07-1.19,4.17-2.07,5.23-5.58,6.84-5.01,1.41.5,2.32,3.81,1.37,6.38-2.76,7.45-20.82,7.69-25.52,7.75-4.81.06-10.94,0-10.94,0-3.89-.04-5.34-.1-7.94-.01-4.38.14-7.05.52-9.31.74-5.38.53-10.43.05-20.51-.91-8.75-.83-11.13-1.49-13.27-3.46-2.26-2.08-4.16-5.53-3.13-7.02.99-1.43,4.45-.26,6.84,0Z"/>
    <path id="Platten9" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M114.73,358.63c-1.73,5.24,3.4,10.81,7.29,15.04,2.63,2.85,7.86,8.53,16.46,10.73,4.26,1.09,4.45.11,15.49.46,9.88.31,9.23,1.09,18.23,1.37,12.38.38,17.51-.19,19.14-.46,7.97-1.3,11.63-3.42,13.61-4.81,3.46-2.41,11.71-19.68,10.74-21.61-.88-1.74-6.18,3.84-13.47,12.95-.81,1.02-.97.55-5.92,3.19-4.48,2.39-5.22,2.6-5.92,2.73-2.17.41-2.74-.17-6.84-.46-3.76-.26-4.01.17-7.29,0-4.26-.23-4.11-.97-9.06-1.57-2.03-.25-5.02-.3-10.98-.4-3.56-.06-4.51-.04-5.42-.06-5.39-.14-12.95-2.04-18.29-6.17-8.42-6.53-7.76-15.88-12.3-15.95-2.49-.04-4.72,2.74-5.47,5.01Z"/>
    <path id="Platten8" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M127.1,430.91c3.47,1.59,6.37,2.04,9.06,2.46,5.25.81,8.49.19,13.27-.18,1.84-.14,1.25-.04,19.59.46,17.09.46,17.93.38,19.59,0,4.38-1.01,7.74-2.58,14.98-3.46,4.48-.55,7.97-.94,8.66.46.98,1.99-3.87,7.31-9.57,10.03-4.73,2.25-9.23,2.26-18.23,2.28-4.48,0-8.61-.34-10.03-.46-3.88-.33-6.06-.7-7.94-.93-6.12-.75-8.71,0-18.03.47-10.3.53-15.45.79-19.59-.46-1.61-.48-12.47-3.91-13.22-10.48-.21-1.87.35-4.35,1.82-5.01,2-.9,4.1,2.29,9.63,4.83Z"/>
    <path id="Platten7" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M118.8,456.32c1.39,1.99,3.86,2.34,13.22,2.73,7.47.31,11.89.34,15.49.46,9.88.31,9.23,1.09,18.23,1.37,11.89.36,10.09-1.09,19.14-.46,2.26.16,6.96.57,12.76-.91,2.49-.64,5.66-1.48,5.92-3.19.31-1.99-8.23-5.37-11.36-5.19-7.75.46-3.71-.65-10.97.17-1.08.12-.78.06-20.51-.46-5.47-.14-10.93-.51-16.41-.46-9.98.1-12.34.84-16.86-.91-3.66-1.42-5.47-3.2-7.29-2.28-2.38,1.2-3.33,6.29-1.37,9.11Z"/>
    <path id="Platten6" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M122.08,512.48c3.92.42,2.51-.03,8.2.46,6.6.56,10.16,1.31,14.13,1.82,4.81.63,9.74.57,19.59.46,15.26-.18,16.88.13,19.59,0,7.73-.38,11.59-.58,12.25-.73,2.94-.7,5.2-1.65,6.84-.46,1.45,1.06,2.18,3.65,1.37,5.47-1.6,3.6-8.96,3.62-17.77,3.65-6.18.02-6.54-.55-14.13-.46-4.46.06-8.6.31-10.68.44-4.79.3-7.14.57-9.31.74-5.88.46-10.84,0-20.51-.91-17.21-1.63-18.74-3.05-19.2-4.38-.71-2.06.83-4.97,2.79-6.11,1.55-.89,2.93-.42,6.84,0Z"/>
    <path id="Platten5" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M114.79,536.89c1.39,1.99,3.86,2.34,13.22,2.73,7.47.31,11.89.34,15.49.46,9.88.31,9.23,1.09,18.23,1.37,11.89.36,10.09-1.09,19.14-.46,2.26.16,6.96.57,12.76-.91,2.49-.64,5.66-1.48,5.92-3.19.31-1.99-5.53-3.83-8.66-3.65-7.75.46-6.42-2.19-13.67-1.37-1.08.12-.78.06-20.51-.46-5.47-.14-10.93-.51-16.41-.46-9.98.1-12.34.84-16.86-.91-3.66-1.42-5.47-3.2-7.29-2.28-2.38,1.2-3.33,6.29-1.37,9.11Z"/>
    <path id="Platten4" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M119.8,600.69c3.92.42,2.51-.03,8.2.46,6.6.56,10.16,1.31,14.13,1.82,8.79,1.15,15.83.7,19.59.46,2.68-.18,8.03-1.06,18.63-2.82,7.93-1.32,12.45-2.12,19.14-2.73,13.72-1.25,14.81.32,15.04.91.85,2.15-3.9,5.77-4.1,5.92-4.13,3.1-7.95,2.91-15.95,3.65-12.92,1.18-11.7,2.46-20.96,2.73-4.84.14-7.2-.15-14.32.25-5.01.28-5.79.53-9.31.74-6.83.42-8.77-.23-20.51-.91-13.86-.8-17.42-.26-18.74-2.82-1.24-2.39.09-6.29,2.34-7.66,1.38-.84,2.62-.45,6.84,0Z"/>
    <path id="Platten3" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M109.27,618.84c.67,2.72,6.66,3.41,13.22,4.1,7.78.82,13.66.58,16.86.46,16.47-.61,14.33.25,28.25-.46,6.33-.32,12.81-.66,21.42-1.82,16.58-2.23,22.31-4.97,25.52-9.57,1.39-2,2.73-4.96,1.82-5.92-1.05-1.11-4.4,1.21-14.13,5.01-6.52,2.54-8.8,2.98-10.48,3.19-.75.1-.76.07-7.75.46-4.2.23-6.32.35-7.75.46-3.66.27-4.54.45-9.11.91-6.62.66-9.94,1-12.7,1-3.57,0-4.12-.27-10.98-.4-3.41-.06-3.34,0-5.42-.06-2.52-.08-6.86-.22-11.45-1-8.08-1.37-9.49-3.47-12.7-2.19-2.67,1.06-5.1,3.85-4.61,5.84Z"/>
    <path id="Platten2" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M135.7,187.75c3.92.42,2.51-.03,8.2.46,6.6.56,10.16,1.31,14.13,1.82,4.81.63,9.74.57,19.59.46,6.53-.08,13.07.17,19.59,0,1.72-.04,6.05-.17,10.94,1.82,1.91.78,5.9,2.4,5.92,4.56.03,2.36-2.35,6.65-6.38,6.38-6.84-.46-13.49-4.21-24.15-5.47-6.09-.72-4.77.94-17.77,1.37-2.09.07-8.26-.26-20.51-.91-8.86-.47-13.34-.77-15.49-3.65-1.61-2.15-2.2-5.5-.91-6.84.96-.99,2.55-.46,6.84,0Z"/>
    <path id="Platten1" class="Grund_Deckplatten1601Fill" style= {{
            fill: props.colors.Grund_Deckplatten1601Fill,
            stroke: props.colors.Grund_Deckplatten1601Fill,
            opacity:props.colors.Grund_Deckplatten1601Fill,
          }} d="M128.41,209.16c1.39,1.99,3.86,2.34,13.22,2.73,7.47.31,11.89.34,15.49.46,9.88.31,9.23,1.09,18.23,1.37,11.89.36,10.09-1.09,19.14-.46,2.26.16,6.96.57,12.76-.91,2.49-.64,5.66-1.48,5.92-3.19.31-1.99-5.53-3.83-8.66-3.65-7.75.46-6.42-2.19-13.67-1.37-1.08.12-.78.06-20.51-.46-5.47-.14-10.93-.51-16.41-.46-9.98.1-12.34.84-16.86-.91-3.66-1.42-5.47-3.2-7.29-2.28-2.38,1.2-3.33,6.29-1.37,9.11Z"/>
  </g>
</g>
</svg>
  );
}

export default HalswirbelsauleVdStehend1601;
