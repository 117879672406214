import style from "./style.scss";

export default function RechtesSchlusselbeinDvStehend2016(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 597.29 285.29">
      <g id="collarbone_recording" data-name="collarbone recording">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="Schlùsselbein_right2016_cls6"
            d="M326.54,272.05c-10.85,11.61-44.12,3.99-45.93-5.69-.47-2.52,1.65-2.87,4.78-10.25,1.27-3.01,4.62-11.67,4.1-22.56-.19-4.03-.34-7.15-2.05-10.94-3.31-7.35-9.64-10.89-11.29-11.75-5.4-2.82-7.37-1.31-14.68-3.98-4.68-1.71-6.81-3.4-12.3-6.84-2.26-1.42-9.48-5.28-23.92-12.99-14-7.48-17.66-9.23-23.24-8.89-4.6.28-8.18,1.83-10.25,2.73-6.83,2.96-10.53,7.09-15.72,12.3-13.48,13.53-12.65,10.24-17.21,15.9-2.76,3.43-8.76,11.04-12.18,22.38-2.6,8.63-.64,9.25-2.73,12.3-6.68,9.75-23.94-.5-50.58,5.47-16.72,3.75-25.17,11.21-31.09,6.47-3.85-3.08-4.16-9.35-4.45-15.35-.22-4.51.27-9.28,3.42-19.82,3.57-11.96,7.39-20.71,8.2-22.56,7.04-15.99,10.44-24.09,17.31-31.19,14.83-15.33,15.5-15.97,15.5-15.97,4.6-4.39,7.28-6.59,7.52-10.25.36-5.54-4.85-10.12-8.2-12.99-3.78-3.23-8.6-6.21-21.19-10.25-15.49-4.97-16.19-3.04-27.34-7.52-4.07-1.64-10.48-3.92-17.77-8.2-5.39-3.17-8.56-5.07-11.62-8.89-1.4-1.75-6.14-7.66-6.15-15.72-.01-9.09,5.97-15.39,8.89-18.46,13.94-14.68,35-12.6,37.59-12.3,4.07.47,14.49,1.8,22.56,9.57,2.8,2.69,2,3.16,6.15,8.2,2.2,2.67,7.11,8.34,23.24,21.19,8.63,6.88,7.59,5.55,12.3,9.57,9.41,8.03,10.21,10.49,17.09,15.04,5.27,3.49,6.57,2.72,14.35,7.52,3.18,1.96,4.37,2.91,9.57,6.15,9.45,5.87,11.01,6.13,12.3,6.15,5.02.07,4.88-5.19,11.62-4.78,10.62.64,15.03,1.7,19.82,6.15,1.17,1.09,8.37,7.77,7.52,15.72-.48,4.53-3.28,6.63-2.05,10.25,1.31,3.85,5.83,5.37,6.15,5.47,3.11.99,5.1-.1,11.62-1.37,4.87-.95,5.4-.66,9.57-1.37,1.91-.33,7.05-1.28,19.82-6.15,11.99-4.57,17.73-7.17,21.87-10.25,5.2-3.87,7.77-8.93,9.57-11.62,4.66-6.94,4.57-9.25,8.89-14.35,3.67-4.34,6.65-7.86,11.62-8.89,9.16-1.89,16.7,6.39,17.09,6.84.98,1.12,4.58,5.19,4.78,10.94.17,4.94-2.26,8.31-4.1,11.62-2.63,4.74-3.8,11.13-6.15,23.92-1.41,7.67-1.27,11.48-1.37,33.49-.08,16.86-.8,33.74,0,50.58.87,18.48,2.13,22.59-1.24,26.2v.02Z"
          />
        </g>
        <path
          id="collarbone"
          class="Schlùsselbein_right2016_cls3"
          d="M137.14,37.58c3.22-.21,4.79,3.14,9.57,6.84,4.59,3.55,8.7,4.77,15.72,6.84,9.09,2.68,15.62,2.48,25.29,2.73,10.5.27,20.95,1.55,31.44,2.05,35.98,1.73,53.97,2.59,57.42,2.73,37.62,1.49,35.7.97,55.37,2.05,12.62.69,30,1.81,51.27,4.1,24.5,2.64,33.49,4.6,39.65,6.15,8.39,2.11,6.93,2.3,28.71,8.89,28.78,8.71,34.46,9.33,38.96,9.57,11.29.6,17.16.9,25.29-.68,5.12-1,10.74-2.67,18.46-1.37,3.92.66,7.5,1.26,10.25,4.1,6.34,6.57,2.48,19.16,2.05,20.51-2.34,7.3-5.82,7.73-6.84,15.04-.57,4.1.32,5.49-.68,10.25-.88,4.21-2.37,6.93-4.1,10.25-6.81,13.12-4.98,12-8.89,18.46-5.46,9.02-8.52,14.09-14.35,15.72-2.45.68-5.96,0-12.99-1.37-9.47-1.85-14.21-2.77-17.77-5.47-5.11-3.88-4.09-6.88-9.57-10.94-3.91-2.9-7.02-3.29-12.3-4.78-13.9-3.93-12.63-6.95-27.34-11.62-7.62-2.42-8.51-1.78-20.51-5.47-5.05-1.55-8.68-2.83-12.3-4.1-6.57-2.31-9.9-3.49-12.3-4.78-6.68-3.6-5.92-5.37-11.62-8.2-1.49-.73-6.02-1.87-15.04-4.1-7.76-1.92-9.31-2.24-12.3-3.42-4.85-1.91-5-2.75-9.57-4.78-6.56-2.9-7.63-1.78-17.09-4.78-3.92-1.25-7.39-2.65-14.35-5.47-8.62-3.49-8.21-3.67-10.25-4.1-3.84-.8-6.17-.33-18.46,2.05-9.2,1.79-13.88,2.69-17.77,3.42-7.27,1.36-6.46,1.13-15.04,2.73-9.81,1.83-9.89,1.93-11.62,2.05-7.38.49-13.17-1-17.09-2.05-7.35-1.97-7.3-3.27-17.09-6.84-7.6-2.77-7.53-1.95-18.46-5.47-5.89-1.9-10.41-3.59-19.14-6.84-12.73-4.75-12.24-4.92-15.04-5.47-9.89-1.95-13.6.66-17.77-2.73-3.66-2.98-4.24-7.81-4.78-12.3-1.16-9.61,2.76-16.79,4.78-20.51,2.42-4.46,4.71-8.66,8.2-8.89h-.01Z"
        />
        <g id="shoulder_with_upper_arm" data-name="shoulder with upper arm">
          <path
            id="shoulder_with_upper_arm_1"
            data-name="shoulder with upper arm 1"
            class="Schlùsselbein_right2016_cls2"
            d="M219.18,163.37c9.87-1.3,18.03-2.85,23.92-4.1,11.81-2.5,20.31-4.35,30.76-8.89,9.55-4.15,14.32-6.22,18.46-10.94,4.71-5.38,4.35-8.76,11.62-19.14,4.42-6.33,6.71-9.52,10.25-10.94,6.9-2.77,16.41-.24,21.19,6.15,3.13,4.18,3.31,8.77,3.42,11.62.32,8.27-3.22,12.49-6.15,21.19-1.8,5.33-2.34,10.85-3.42,21.87-.94,9.6-.69,13.76-.68,34.86.02,25.67-.34,27.93,0,44.43.18,9.16.46,16.73.68,21.87"
          />
          <path
            id="shoulder_with_upper_arm_2"
            data-name="shoulder with upper arm 2"
            class="Schlùsselbein_right2016_cls2"
            d="M273.86,183.86c-5,.78-12.42,1.22-20.51-1.37-6.97-2.23-10.8-5.54-17.77-10.94-16.61-12.88-12.32-7.65-33.49-23.92-17.76-13.65-14.64-12.62-23.92-19.14-18.73-13.16-21.05-10.1-36.91-21.87-13.66-10.14-12.42-12.77-28.71-24.61-5.27-3.84-9.95-6.87-15.72-12.99-6.7-7.11-6.31-9.3-11.62-13.67-8.86-7.29-18.91-8.56-21.87-8.89-10.7-1.21-18.76,1.9-22.56,3.42-5.48,2.19-12.04,4.81-17.09,11.62-1.22,1.65-8.57,11.91-5.47,23.24,2.58,9.46,10.94,14.15,19.82,19.14,9.37,5.27,12.86,4.75,31.44,10.94,16.55,5.51,24.82,8.27,30.76,12.99,10.52,8.37,14.98,18.89,19.14,28.71,5.16,12.18,10.12,23.87,7.52,37.59-1.46,7.69-4.16,10.08-19.14,34.18-5.89,9.47-10.58,17.33-13.67,22.56"
          />
          <path
            id="shoulder_with_upper_arm_3"
            data-name="shoulder with upper arm 3"
            class="Schlùsselbein_right2016_cls2"
            d="M98.88,108.67c8.26,1.26,13.92,4.14,17.77,6.84,5.8,4.08,6,6.73,14.35,14.35,3.71,3.39,7.49,6.13,15.04,11.62,6.5,4.72,9.08,6.32,17.77,12.3,17.49,12.02,15.11,11.1,20.51,14.35,5.99,3.61,6.04,3.02,26.66,12.99,10.61,5.13,15.92,7.7,21.87,10.94,13.76,7.5,12.12,7.88,19.14,10.94,11.91,5.18,15.93,3.76,24.61,9.57,4.1,2.74,8.31,5.56,10.94,10.94,3.08,6.3,2.15,12.35.68,21.19-.91,5.42-2.75,13.12-6.84,21.87"
          />
          <path
            id="shoulder_with_upper_arm_4"
            data-name="shoulder with upper arm 4"
            class="Schlùsselbein_right2016_cls2"
            d="M172.71,130.57c-1.53,2.47-14.46,17.27-12.3,25.29,1.76,6.54,6.7,10.06,13.67,15.04,6.58,4.7,11.03,7.87,17.77,8.2,11.46.55,19.32-7.61,20.51-8.89,6.88-7.38,7.41-16.07,7.52-18.46.14-3.03.53-11.73-5.47-18.46-5.82-6.53-15.32-8.59-22.56-7.52-9.01,1.33-13.39,7.34-19.14,4.78-3.53-1.56-3.85-4.69-6.84-4.78-3.85-.11-4.97,5.03-10.25,7.52-3.22,1.52-5.4.84-12.99,0-13.6-1.5-20.39-2.25-24.61,0-3.87,2.06-2.99,3.78-10.94,12.3-4.96,5.31-8.69,8.26-10.94,10.25-10.36,9.15-17.03,22.11-23.24,34.18-8.15,15.84-13.87,26.94-15.04,43.06-.66,9.07.34,16.67,1.37,21.87"
          />
          <path
            id="shoulder_with_upper_arm_5"
            data-name="shoulder with upper arm 5"
            class="Schlùsselbein_right2016_cls2"
            d="M191.84,179.08c-1.8,3.45-11.61,11.02-16.41,15.72-4.94,4.84-7.3,5.12-12.3,9.57-7.44,6.63-11.13,13.97-12.99,17.77-4.23,8.64-5.65,16.56-6.15,21.87"
          />
          <path
            id="shoulder_with_upper_arm_6"
            data-name="shoulder with upper arm 6"
            class="Schlùsselbein_right2016_cls1"
            d="M.44,130.54c49.9-92.97,201.65,33.49,45.8,129.19"
          />
          <path
            id="shoulder_with_upper_arm_7"
            data-name="shoulder with upper arm 7"
            class="Schlùsselbein_right2016_cls1"
            d="M.44,125.49c27.85-17.01,39.72-7.6,36.23,0"
          />
          <path
            id="shoulder_with_upper_arm_8"
            data-name="shoulder with upper arm 8"
            class="Schlùsselbein_right2016_cls1"
            d="M23,77.91c-5.54-21.19,73.82,8.2,73.82-8.89"
          />
        </g>
        <g id="spine_with_ribs" data-name="spine with ribs">
          <path
            id="spine_with_ribs_1"
            data-name="spine with ribs 1"
            class="Schlùsselbein_right2016_cls1"
            d="M301.19,269.97c-17.2-9.24-28.33-19.96-34.86-27.34-11.36-12.84-13.65-21.03-14.35-23.92-2.7-11.16-.28-20.43.68-23.92,3.68-13.42,11.89-21.53,17.09-26.66,6.87-6.78,13.23-10.48,21.19-15.04,11.51-6.59,17.59-8.35,28.03-12.99,16.68-7.42,15.07-9.11,58.78-34.18,10.63-6.1,11.31-6.3,19.14-10.94,15.88-9.4,17.14-10.96,23.92-13.67,11.23-4.48,13.2-2.37,23.24-7.52,5.14-2.64,9.51-5.7,15.04-9.57,8.49-5.94,9.58-9.8,14.35-10.25,2.72-.26,6.41,1.88,13.67,6.15,16.48,9.7,17.15,10.17,18.46,11.62,2.51,2.78,10.07,11.2,7.52,20.51-1.13,4.12-4.33,8.45-8.89,10.25-6.37,2.51-10.88-1.7-22.56-4.78-3.91-1.03-15.16-3.99-26.66-2.05-10.5,1.77-11.17,5.94-25.97,12.3-14.85,6.39-15.84,2.9-30.76,8.89-14.99,6.02-26.14,14.41-31.44,18.46-11.85,9.04-12.2,12.12-21.87,17.09-7.47,3.85-9.64,3.23-21.19,8.89-7.66,3.75-12.51,6.18-17.77,10.94-5.69,5.16-11.27,10.21-11.62,17.09-.47,9.27,8.82,16.66,17.09,23.24,8.47,6.73,10.63,5.69,23.24,14.35,8.46,5.8,9.67,7.77,18.46,14.35,5.87,4.39,14.77,10.59,26.66,17.09"
          />
          <path
            id="spine_with_ribs_2"
            data-name="spine with ribs 2"
            class="Schlùsselbein_right2016_cls1"
            d="M466.62,254.96c2.23.1,12.6.57,19.14-6.15,5.11-5.26,1.67-8.51,7.52-17.09,4.49-6.58,8.26-7.23,10.25-13.67.54-1.75,1.92-6.24,0-10.25-2.48-5.17-9.08-6.67-12.3-7.52-11-2.91-20.09-8.77-38.28-20.51-11.37-7.34-18.49-11.93-24.61-21.19-3.58-5.42-6.04-11.82-10.94-24.61-4.63-12.1-4.77-14.97-4.78-16.41-.06-7.63,3.38-13.06,10.25-23.92,11.93-18.85,17.41-22.97,20.51-24.61,2.48-1.31,4.61-2.01,8.89-3.42,10.09-3.32,12.83-2.45,22.56-4.78,11.59-2.77,8.65-4.18,25.97-9.57,5.21-1.62,9.42-2.69,9.57-4.78.19-2.62-6.22-3.11-10.94-9.57-2.71-3.71-1.19-4.38-4.78-10.25-2.84-4.64-4.08-4.72-6.84-9.57-2.66-4.69-2.24-5.93-4.1-7.52-3.8-3.26-9.99-1.89-12.3-1.37-15.58,3.47-32.33,21.48-40.33,30.08-18.58,19.95-27.86,29.92-30.76,32.81-17.5,17.44-21.09,18.44-30.76,30.76-9.3,11.84-15.07,22.5-17.77,34.86-2.91,13.29-.33,19.7.68,21.87,2.88,6.19,7.29,8.5,14.35,13.67,4.74,3.46-.04.38,31.44,29.39,22.61,20.83,19.26,17.07,23.24,21.19,11.49,11.89,17.06,20.13,30.08,27.34,5.06,2.8,9.61,4.53,15.04,4.78h0Z"
          />
          <path
            id="spine_with_ribs_3"
            data-name="spine with ribs 3"
            class="Schlùsselbein_right2016_cls1"
            d="M288.23,267.92c3.76-2.07,9.44-5.18,16.41-8.89,15-7.99,22.68-12.07,31.44-15.72,3.68-1.54,9.52-3.53,21.19-7.52,15.43-5.28,23.15-7.92,33.49-10.94,11.44-3.34,20.23-5.48,31.44-8.2,14.69-3.57,10.92-2.3,23.92-5.47,3.17-.77,15.98-3.92,32.13-5.47,6.25-.6,10.96-.77,12.99,2.05,1.96,2.73.56,7.09-.68,10.94-2.31,7.16-5.12,7.66-6.15,12.99-1.32,6.78,2.55,9.5.68,12.3-2.71,4.05-11.78-.17-26.66-.68-8.3-.28-15.1.95-28.71,3.42-12.83,2.33-22.31,5.13-28.03,6.84-13.24,3.96-22.81,7.87-25.29,8.89-7.71,3.19-14.17,6.32-19.14,8.89"
          />
          <path
            id="spine_with_ribs_4"
            data-name="spine with ribs 4"
            class="Schlùsselbein_right2016_cls1"
            d="M480.99,174.96c-7.95,2.06-28.21,4.69-32.81,5.47-11.47,1.94-13.13,1.31-18.46,3.42-7.97,3.16-8.42,6.23-16.41,8.89-4.9,1.63-6.9,1.2-14.35,2.05-1.12.13-14.09,1.67-25.29,5.47-9.67,3.29-8.55,5.04-24.61,12.99-13.36,6.62-13.94,5.3-24.61,10.94-10.32,5.45-9.82,6.7-24.61,15.72-13.64,8.32-16.48,8.73-27.34,15.72-9.23,5.94-16.22,11.47-20.51,15.04"
          />
          <path
            id="spine_with_ribs_5"
            data-name="spine with ribs 5"
            class="Schlùsselbein_right2016_cls1"
            d="M498.76,180.43c1.09-2.99,2.82-9.09.68-15.72-1.41-4.36-3.93-7.07-8.89-12.3-6.19-6.52-9.28-9.78-13.67-11.62-7.42-3.11-14.87-.79-24.61,2.73-18.53,6.69-27.79,10.03-36.91,15.04-29.46,16.18-30.59,9.04-66.99,26.66-10.17,4.93-25.24,13.99-55.37,32.13-21.78,13.1-35.18,21.73-43.75,27.34-11.11,7.28-20.41,13.64-27.34,18.46"
          />
          <path
            id="spine_with_ribs_6"
            data-name="spine with ribs 6"
            class="Schlùsselbein_right2016_cls1"
            d="M534.29,272.03c-2.76-4.48-9.92-17.11-12.3-18.46-5.76-3.26-9.17-1.46-17.77-4.78-4.28-1.65-7.53-2.91-10.25-6.15-2.64-3.15-3.39-6.93-4.78-14.35-1.26-6.75-1.89-10.12-1.37-12.99,1.06-5.82,5.16-12.98,12.3-15.72,6.87-2.64,13.15.11,19.14,2.73,3.36,1.47,16.24,7.11,21.19,20.51,1.93,5.22,1.74,9.4,1.37,17.77-.16,3.59-.96,16.27-7.52,31.44-2.41,5.57-4.94,9.97-6.84,12.99"
          />
          <path
            id="spine_with_ribs_7"
            data-name="spine with ribs 7"
            class="Schlùsselbein_right2016_cls1"
            d="M591.72,159.24c-3.11.44-7.74.69-12.99-.68-2.27-.59-2.81-1.01-9.57-4.1-13.39-6.12-13.14-5.34-16.41-7.52-4.66-3.11-6.44-5.55-8.2-4.78-1.99.87-2.03,5-2.05,7.52-.05,5.43,1.36,9.11,2.05,11.62,2.04,7.35.48,13.67-2.05,23.92-.97,3.93-2.47,8.09-5.47,16.41-3.94,10.93-5.99,15.12-4.1,17.09,1.81,1.88,4.93-.64,10.94,0,6.77.72,7.55,4.4,15.04,7.52,7.35,3.06,14.1,2.65,23.92,2.05,6-.37,10.94-1.28,14.35-2.05"
          />
          <path
            id="spine_with_ribs_8"
            data-name="spine with ribs 8"
            class="Schlùsselbein_right2016_cls1"
            d="M543.56,157.79c-3-3.19-14.05-10.97-21.55-12.9-4.53-1.16-8.93-.59-17.77,0-6.78.45-8.02,1.06-8.89,2.05-2.8,3.17.62,7.5.68,19.14.04,7.45-1.35,8.13,0,10.25,2.5,3.94,7.83,3.01,23.92,6.84,1.77.43,4.87,1.15,8.2,3.42,4.46,3.06,10.2,6.98,11.09,8.89"
          />
          <path
            id="spine_with_ribs_9"
            data-name="spine with ribs 9"
            class="Schlùsselbein_right2016_cls1"
            d="M512.84,83.37c3.96,3.39,11.92,5.83,14.63,7.52,3.62,2.25,5.47,3.44,8.2,4.1,4.17,1.01,5.82-.06,7.52,1.37,2.99,2.5,2.41,9.6-.68,14.35-2.31,3.54-5.32,3.65-8.89,6.84-2.93,2.62-8.31,7.48-12.99,15.04-2.82,4.54-9.16,15.06-8.89,29.39.1,5.55,1.16,10.05,2.05,12.99"
          />
          <path
            id="spine_with_ribs_10"
            data-name="spine with ribs 10"
            class="Schlùsselbein_right2016_cls1"
            d="M545.15,174.97c3.01,14.83,52.03,0,3.01-26.45"
          />
          <path
            id="spine_with_ribs_11"
            data-name="spine with ribs 11"
            class="Schlùsselbein_right2016_cls1"
            d="M588.3,155.84c-4.5-1.12-10.9-2.87-18.46-5.47-16.51-5.68-25.8-9-27.34-15.72-.62-2.71.75-2.8,4.1-12.99,3.57-10.83,5.35-16.25,5.47-23.24.12-6.63-1.27-10.12,1.37-12.99,2.43-2.65,6.41-2.7,8.2-2.73,7.33-.11,9.92,4.52,17.09,6.15,3.22.73,7.97,1,14.35-1.37"
          />
          <path
            id="spine_with_ribs_12"
            data-name="spine with ribs 12"
            class="Schlùsselbein_right2016_cls1"
            d="M557.49,50.57c-110.01-4.37-84.71-91.59-4.74-25.29"
          />
          <path
            id="spine_with_ribs_13"
            data-name="spine with ribs 13"
            class="Schlùsselbein_right2016_cls1"
            d="M588.99,30.74c-6.59.62-11.58-.32-15.04-1.37-5.31-1.61-7.95-3.79-11.62-2.73-4.61,1.34-6.75,6.6-6.84,6.84-2.67,6.83,3.22,10.53,3.42,23.24.07,4.37-.6,5.41,0,9.57.6,4.14,1.31,9.05,4.78,12.3,3.48,3.26,8.24,3.31,17.77,3.42,5.07.06,9.3-.31,12.3-.68"
          />
          <path
            id="spine_with_ribs_14"
            data-name="spine with ribs 14"
            class="Schlùsselbein_right2016_cls1"
            d="M557.49,46.19c.81,0,55.41,8,1.47-17.63"
          />
          <line
            id="spine_with_ribs_15"
            data-name="spine with ribs 15"
            class="Schlùsselbein_right2016_cls1"
            x1="562.32"
            y1="26.64"
            x2="557.49"
            y2="7.46"
          />
          <path
            id="spine_with_ribs_16"
            data-name="spine with ribs 16"
            class="Schlùsselbein_right2016_cls1"
            d="M550.61,108.66c30.93,11.57,35.14-.38,11.03-25.97"
          />
          <path
            id="spine_with_ribs_17"
            data-name="spine with ribs 17"
            class="Schlùsselbein_right2016_cls1"
            d="M487.13,60.13c5.19-18.69,48.24-4.4,61.03,8.89"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="collarbone_overlay"
          data-name="collarbone overlay"
          class="clavicula2016Fill"
          style={{
            fill: props.colors.clavicula2016Fill,
            stroke: props.colors.clavicula2016Fill,
          }}
          d="M137.14,37.58c3.22-.21,4.79,3.14,9.57,6.84,4.59,3.55,8.7,4.77,15.72,6.84,9.09,2.68,15.62,2.48,25.29,2.73,10.5.27,20.95,1.55,31.44,2.05,35.98,1.73,53.97,2.59,57.42,2.73,37.62,1.49,35.7.97,55.37,2.05,12.62.69,30,1.81,51.27,4.1,24.5,2.64,33.49,4.6,39.65,6.15,8.39,2.11,6.93,2.3,28.71,8.89,28.78,8.71,34.46,9.33,38.96,9.57,11.29.6,17.16.9,25.29-.68,5.12-1,10.74-2.67,18.46-1.37,3.92.66,7.5,1.26,10.25,4.1,6.34,6.57,2.48,19.16,2.05,20.51-2.34,7.3-5.82,7.73-6.84,15.04-.57,4.1.32,5.49-.68,10.25-.88,4.21-2.37,6.93-4.1,10.25-6.81,13.12-4.98,12-8.89,18.46-5.46,9.02-8.52,14.09-14.35,15.72-2.45.68-5.96,0-12.99-1.37-9.47-1.85-14.21-2.77-17.77-5.47-5.11-3.88-4.09-6.88-9.57-10.94-3.91-2.9-7.02-3.29-12.3-4.78-13.9-3.93-12.63-6.95-27.34-11.62-7.62-2.42-8.51-1.78-20.51-5.47-5.05-1.55-8.68-2.83-12.3-4.1-6.57-2.31-9.9-3.49-12.3-4.78-6.68-3.6-5.92-5.37-11.62-8.2-1.49-.73-6.02-1.87-15.04-4.1-7.76-1.92-9.31-2.24-12.3-3.42-4.85-1.91-5-2.75-9.57-4.78-6.56-2.9-7.63-1.78-17.09-4.78-3.92-1.25-7.39-2.65-14.35-5.47-8.62-3.49-8.21-3.67-10.25-4.1-3.84-.8-6.17-.33-18.46,2.05-9.2,1.79-13.88,2.69-17.77,3.42-7.27,1.36-6.46,1.13-15.04,2.73-9.81,1.83-9.89,1.93-11.62,2.05-7.38.49-13.17-1-17.09-2.05-7.35-1.97-7.3-3.27-17.09-6.84-7.6-2.77-7.53-1.95-18.46-5.47-5.89-1.9-10.41-3.59-19.14-6.84-12.73-4.75-12.24-4.92-15.04-5.47-9.89-1.95-13.6.66-17.77-2.73-3.66-2.98-4.24-7.81-4.78-12.3-1.16-9.61,2.76-16.79,4.78-20.51,2.42-4.46,4.71-8.66,8.2-8.89h-.01Z"
        />
        <path
          id="AC_joint_overlay"
          data-name="AC joint overlay"
          class="acjoint2016Fill"
          style={{
            fill: props.colors.acjoint2016Fill,
            stroke: props.colors.acjoint2016Fill,
          }}
          d="M134.64,37.57c-24.76-2.75-45.54-.25-60.61,9.8,10.31,5.39,18.63,12.12,22.79,21.65,5.25,5.61,13.22,11.6,21.89,17.69,5.62-.82,8.75-3.5,10.23-7.43-11.17-5.82-7.98-33.31,5.69-41.71Z"
        />
        <path
          id="SC_joint_overlay"
          data-name="SC joint overlay"
          class="scjoint2016Fill"
          style={{
            fill: props.colors.scjoint2016Fill,
            stroke: props.colors.scjoint2016Fill,
          }}
          d="M544.54,91.58c2.66-2.72,5.43-3.03,8.28-1.3,3.01,1.84,4.69,5.26,4.65,8.79-.2,20.93-2,40.75-9.31,56.76-5.27,12.25-10.26,23.85-14.8,34.39-11.83,2.7-17.38-.54-18.29-8.15,5.93-6.67,11.65-16.34,17.15-28.97,5.34-4.27,8.19-11.77,7.53-25.98,11.35-17.57,10.43-27.95,4.79-35.55Z"
        />
      </g>
    </svg>
  );
}
