import { MakeGetObject, MakePostObject } from "../../AuthHeader";

export const REST_URL = `${window.conf.SERVER_CONFIG.BASE_URL}`;

export function MakeUrl(
  urlBase,
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined
) {
  const url = new URL(urlBase);
  if (pageSize && pageNr) {
    url.searchParams.append("page_size", pageSize);
    url.searchParams.append("page_number", pageNr);
  }
  if (filter) {
    url.searchParams.append("search", JSON.stringify(filter));
  }
  if (sort) {
    url.searchParams.append("sort", JSON.stringify(sort));
  }
  return url;
}

export async function getFetch(url, keycloak, xmask = undefined) {
  return fetch(url.href, MakeGetObject(keycloak, xmask))
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error(response.statusText);
      }
    })
    .catch((reason) => {
      throw new Error(reason);
    });
}

export async function postFetch(url, keycloak, body) {
  let resp = null;
  try {
    resp = await fetch(url.href, MakePostObject(keycloak, body));
  } catch (e) {
    console.log(e);
  }
  if (!resp.ok) {
    return resp.statusText;
  }
  let data = await resp.json();
  return data;
}
