import React from "react";
import "./Card.scss";

export default function Card(props) {
  return (
    <div className="card card-shadow">
      {props.header & !props.headerless ? (
        <div className="card__header card-header">
          <h5>{props.header}</h5>
        </div>
      ) : (
        <></>
      )}

      <div className="card__body">{props.children}</div>
    </div>
  );
}
