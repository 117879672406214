import style from "./style.scss";

function Rechter3FingerDpSitzend1320(props) {
  return (
<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190.46 474.16">

<g id="Ebene_3" data-name="Ebene 3">
  <path id="OUtline" class="Rechter_Finger3_dp_sitzend1320_cls8" d="M187.5,467.87L0,473.77l17.4-230.7c5.1,59.4,12.6,95,23.7,96.3,11,2.3,15.6-10.9,16.7-32.8-2.1-36.4,1-66.2,5.5-94.2,5.9-29.7,9-59.5,10-89.6l3.3-98.1C78.8,3.97,90.7-1.23,108.4.97c19.7.2,31.5,11.5,36.8,31.9,5.9,24.7,7.6,88.3,9.7,147.6.6,43.6-3.1,90.8-9.7,140.4-3.1,18.8,2,27.7,14.3,27.6,10.5,2.8,20.4-15.1,30.1-38.6l-2.1,158Z"/>
</g>
<g id="_3rdfinger" data-name=" 3rdfinger">
  <g id="highlights">
    <path id="middlephalanxH" class="FullFinger1320Fill" style= {{
        fill: props.colors.FullFinger1320Fill,
        stroke: props.colors.FullFinger1320Fill,
        opacity:props.colors.FullFinger1320Fill,
      }} d="M141.15,194.97c.7,1.3,1,2.9.9,4.4-.6,4.5-2.1,8-6.2,8.9-6.2-.3-13.3,1.1-20.8,3.6-1.3.4-2.6.4-3.9,0-9.2-3.1-17.9-5.6-24.5-5.1-1.8.1-3.5-.7-4.4-2.3-3.2-5.5-3.6-10.2-.3-13.8,1.5-1.6,2.5-3.7,3.1-5.8,5.6-21.6,8.8-42.4,9-62.1.3-2.2,0-4.5-1-6.5-2.9-6.2-2.7-10.5,1.1-15.3,1.1-1.5,1.7-3.3,1.5-5.2-.6-7.7,4.7-10.7,12.8-6.7,4.5,1.2,8.6,1.9,12.6.6,10.1-2,12.5.5,13.2,8.1.1,1.2.5,2.3,1.1,3.4.9,1.8,1.4,4.5,1.8,8,.3,2.6-.3,5.3-2,7.4-5.9,7.3-6.8,19.3-3.1,35.5.6,2.9,1.1,5.9,1.2,8.8.6,13.2,2.9,24.8,7.9,34.1h0Z"/>
    <path id="enphalanxH" class="FullFinger1320Fill" style= {{
        fill: props.colors.FullFinger1320Fill,
        stroke: props.colors.FullFinger1320Fill,
        opacity:props.colors.FullFinger1320Fill,
      }} d="M133.2,84.17c-3.1,4.8-5.4.3-15.1,2.3-5.2,1.3-10.3.8-15.4-1.7-5.5.3-9.4-1-9.9-5.9-3.3-2.8-2.8-5.6.5-8.5,9.6-8.7,5.7-28.2,4.2-27.5-3.8-6.5-3.8-14.5,0-23.9,1.8-2.8,12.1-4.9,18.3-.5,6.3,4.8,10,9.9,9.5,15.5.4,4.2.3,7.9-.9,10.2-.5.9-.7,2-.7,3,0,12,5,21.3,12.6,29.7,1,3,.1,5.5-3.1,7.3h0Z"/>
    <path id="basephalanxH" class="FullFinger1320Fill" style= {{
        fill: props.colors.FullFinger1320Fill,
        stroke: props.colors.FullFinger1320Fill,
        opacity:props.colors.FullFinger1320Fill,
      }} d="M104.81,413.69c-13.3-6.5-28.9-5.7-45.8.1-4.6,2.1-8,1.5-7.9-6.7-3.9-6.4-4.2-13.5,0-21.6,15.8-18.5,22.7-51.3,24.6-92.4.5-10,1-18.5,1.4-25.3.1-1.1.3-2.3.6-3.4,1.8-7.3,1.8-14.1-.5-20.2-2.2-3.4-1.4-6.6.9-9.5,1-1.4,1.5-3.1,1.3-4.8-.6-4.5.3-9.1,2.5-13.6.7-1.3,1.8-2.4,3.1-3,6-2.6,13.9-1,22.5,2.5,2.7,1.2,5.7,1.6,8.6,1.3l11.1-1.1c4.9.8,8,5.4,6.1,21.5-.2,1.6-.2,3.2,0,4.8.5,4.9-.4,8.3-2.7,10.3-1.1,1-2,2.3-2.5,3.7-3.5,10.9-4.6,21.1-4,30.9,0,.7,0,1.4-.1,2.1-6.3,39.9-8.6,75.1.2,97.7,7,10.3,9.2,19.6,0,26.7-4.1,6.5-10.1,8.1-19.4,0h0Z"/>
    <ellipse id="basejointhighlight" class="Grundgelenk1320Fill" style= {{
        fill: props.colors.Grundgelenk1320Fill,
        stroke: props.colors.Grundgelenk1320Fill,
        opacity:props.colors.Grundgelenk1320Fill,
      }} cx="87.5" cy="417.57" rx="40.4" ry="10.8"/>
    <path id="fingertiphighlight" class="Fingerkuppe1320Fill" style= {{
        fill: props.colors.Fingerkuppe1320Fill,
        stroke: props.colors.Fingerkuppe1320Fill,
        opacity:props.colors.Fingerkuppe1320Fill,
      }} d="M114.2,15.97c-5-1.7-9.6-1.9-13.8,0-2.6.3-4.9,6-5.6,12.6,7.6-8.2,17.5-8.3,30.4,2.2-1.1-6.5-4.7-11.5-11-14.8Z"/>
  </g>
  <path id="basephalanx" class="Rechter_Finger3_dp_sitzend1320_cls2" d="M106.1,414.07c-13.3-6.5-28.9-5.7-45.8.1-4.6,2.1-8,1.5-7.9-6.7-3.9-6.4-4.2-13.5,0-21.6,15.8-18.5,22.7-51.3,24.6-92.4.5-10,1-18.5,1.4-25.3.1-1.1.3-2.3.6-3.4,1.8-7.3,1.8-14.1-.5-20.2-2.2-3.4-1.4-6.6.9-9.5,1-1.4,1.5-3.1,1.3-4.8-.6-4.5.3-9.1,2.5-13.6.7-1.3,1.8-2.4,3.1-3,6-2.6,13.9-1,22.5,2.5,2.7,1.2,5.7,1.6,8.6,1.3l11.1-1.1c4.9.8,8,5.4,6.1,21.5-.2,1.6-.2,3.2,0,4.8.5,4.9-.4,8.3-2.7,10.3-1.1,1-2,2.3-2.5,3.7-3.5,10.9-4.6,21.1-4,30.9,0,.7,0,1.4-.1,2.1-6.3,39.9-8.6,75.1.2,97.7,7,10.3,9.2,19.6,0,26.7-4.1,6.5-10.1,8.1-19.4,0h0Z"/>
  <path id="middlehand" class="Rechter_Finger3_dp_sitzend1320_cls2" d="M49.4,472.27c-.3-15.5,1.4-26.5,6.3-39.7,2.8-7.6,9.6-13,17.6-13.9,5-.6,10.5,0,16.1,1,11.8,1.7,20.4,10,24.6,27.3,1.8,11.3,3.3,16.6,3.3,23.6"/>
  <path id="middlephalanx" class="Rechter_Finger3_dp_sitzend1320_cls2" d="M140.6,194.77c.7,1.3,1,2.9.9,4.4-.6,4.5-2.1,8-6.2,8.9-6.2-.3-13.3,1.1-20.8,3.6-1.3.4-2.6.4-3.9,0-9.2-3.1-17.9-5.6-24.5-5.1-1.8.1-3.5-.7-4.4-2.3-3.2-5.5-3.6-10.2-.3-13.8,1.5-1.6,2.5-3.7,3.1-5.8,5.6-21.6,8.8-42.4,9-62.1.3-2.2,0-4.5-1-6.5-2.9-6.2-2.7-10.5,1.1-15.3,1.1-1.5,1.7-3.3,1.5-5.2-.6-7.7,4.7-10.7,12.8-6.7,4.5,1.2,8.6,1.9,12.6.6,10.1-2,12.5.5,13.2,8.1.1,1.2.5,2.3,1.1,3.4.9,1.8,1.4,4.5,1.8,8,.3,2.6-.3,5.3-2,7.4-5.9,7.3-6.8,19.3-3.1,35.5.6,2.9,1.1,5.9,1.2,8.8.6,13.2,2.9,24.8,7.9,34.1h0Z"/>
  <path id="enphalanx" class="Rechter_Finger3_dp_sitzend1320_cls2" d="M133.7,82.97c-3.1,4.8-5.4.3-15.1,2.3-5.2,1.3-10.3.8-15.4-1.7-5.5.3-9.4-1-9.9-5.9-3.3-2.8-2.8-5.6.5-8.5,9.6-8.7,5.7-28.2,4.2-27.5-3.8-6.5-3.8-14.5,0-23.9,1.8-2.8,12.1-4.9,18.3-.5,6.3,4.8,10,9.9,9.5,15.5.4,4.2.3,7.9-.9,10.2-.5.9-.7,2-.7,3,0,12,5,21.3,12.6,29.7,1,3,.1,5.5-3.1,7.3h0Z"/>
  <path id="middlehand2" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M111.9,470.47c.5-5.4-2.4-10-6.3-14.5-4.3-3.7-5.8-10.1-3.5-20"/>
  <path id="joint" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M64.5,412.67c5.7.3,10.9,1.4,15.7,3.2,7.9,2.9,16.4,3.8,24.7,2.4,2-.3,3.8-.7,5.2-1.2"/>
  <path id="middlehand1" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M38.5,473.67c1.7-11.8,6.2-16.4,11.8-18.6"/>
  <path id="basephalanx4" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M116,249.57c-5.4,2.5-7.9,10.9-7.7,24.9,0,1.2.2,2.3.6,3.3,2.9,8.3,2.2,20.8.2,34.8l4.1,45.2c2.4,17.7,6,27.2,10.5,31.2"/>
  <path id="basephalanx3" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M97.2,254.37c-1.4,3.5-2.2,9.1-2.2,17.4,0,3.1-.6,6.1-2,8.9-3.1,6.1-4.4,15.9-4.8,27.7,0,.9-.3,1.7-.6,2.5-3,6.5-5.2,13.4-6.4,20.8-1,11-7.4,23.6-15.6,36.8"/>
  <path id="basephalanx2" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M129.4,223.47c-.1,10.3,1.4,15.6,4.6,15.8"/>
  <path id="basephalanx1" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M87.5,220.97c-1.5,6.6-3.7,10.5-6.8,10.4"/>
  <path id="middlephalanx3" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M88.5,200.67c7,.3,13.2,1.1,17.4,3.6,2,1.4,4.3,2,6.6,1.9,1.7-.1,3.4-.5,4.9-1.2,5.5-2.5,10.8-3.2,15.9-2"/>
  <path id="middlephalanx2" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M119.3,108.07c-4.3,5.6-5.5,14.2-3.9,25.5,2.9,7.5,5,14.5,5.6,20.9,3.3,12.5,7.2,23.7,11.6,33.6,2.2,3.8,3.7,8.4,4.6,13.6"/>
  <path id="middlephalanx1" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M86,194.27c8.4-16.4,13.7-33.8,16.8-51.9.5-2.8.7-5.7.6-8.6-.3-7.3.1-15,1.1-22.9-.4-2.2-1-4.3-1.9-6.3"/>
  <path id="endphalanx1" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M99.8,68.27c4.3.4,6.6-4.6,7.2-14.6.2-3.9.1-7.8-.2-11.7-.5-6.7,1.3-9.6,5.3-9,3.4,0,4.8,4.4,4.9,11.3.1,1.9.3,3.8.6,5.7,1.3,7.1,4.7,13.3,9.1,19.2,3.5,2.9,5.3,5.5,4.9,7.8"/>
  <g id="softpart">
    <path id="softpart3" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M187.3,315.37c.7-1.8,1.5-3.6,2.3-5.5"/>
    <path id="softpart2" class="Rechter_Finger3_dp_sitzend1320_cls4" d="M17.4,243.07c.1,2,.3,4,.5,6"/>
    <path id="softpart1" class="Rechter_Finger3_dp_sitzend1320_cls1" d="M18.5,254.97c5,52.2,12.3,83.2,22.6,84.4,11,2.3,15.6-10.9,16.7-32.8-2.1-36.4,1-66.2,5.5-94.2,5.9-29.7,9-59.5,10-89.6l3.3-98.1C78.8,3.97,90.7-1.23,108.4.97c19.7.2,31.5,11.5,36.8,31.9,5.9,24.7,7.6,88.3,9.7,147.6.6,43.6-3.1,90.8-9.7,140.4-3.1,18.8,2,27.7,14.3,27.6,7.9,2.1,15.4-7.4,22.8-22.3"/>
  </g>
  <path id="nextfinger4" class="Rechter_Finger3_dp_sitzend1320_cls5" d="M1.1,447.07c5.3,3.2,10.6,4.7,15.7,3.9,1.9-.3,3.6-1.6,4.2-3.5.6-2.1,1.9-3.9,3.8-5.4,1.5-1.2,2.7-2.9,3-4.9,1.2-7.6.8-15.9-.3-24.5-.2-1.6-1-3.1-2.3-4.1l-2.5-1.9c-1.7-1.3-3.1-3.1-4-5.1-5.1-11.6-8.6-26.2-11-42.5"/>
  <path id="nextfinger3" class="Rechter_Finger3_dp_sitzend1320_cls5" d="M24.5,473.67c-4.9-8.7-12.2-15.2-22.6-18.6"/>
  <path id="nextfinger2" class="Rechter_Finger3_dp_sitzend1320_cls5" d="M187.5,414.57c-16.4.2-27.8,9.5-31.1,33.4-5.1,3.5-6.4,8.7-2.6,16.4l-1.1,5.5"/>
  <path id="nextfinger1" class="Rechter_Finger3_dp_sitzend1320_cls5" d="M188.3,408.77c-3.4-.9-6.7-1.1-9.6,0-7,2.9-11.7,2.3-10.9-6.8-.4-5.8.1-10.7,1.5-14.7,1-3.1,3.2-5.6,5.9-7.4,5.3-3.6,10-10,14.4-17.4"/>
  <path id="nextfinger" class="Rechter_Finger3_dp_sitzend1320_cls3" d="M156.3,447.97c3.1-1.1,5.1-3.2,6.5-5.9.6-1,.8-2.2.7-3.3-.3-2.9,1.7-6.9,4.1-11.1"/>
</g>
</svg>
  );
}

export default Rechter3FingerDpSitzend1320;
