import style from "./style.scss";

function RechtesDaumenSattelGelenkDpSitzend1312(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 569.8 810"
    >
      <g id="thaumbsattle">
        <g id="Thumbbone">
          <path
            id="Thumbboneparts3"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M138.5,334s-2.3-21.2-7-44c-4.2-20.3-9.6-33.6-11-37-5.4-13-8.6-20.7-16-29-9.4-10.5-17.7-12.4-17-17,.7-5.1,10.8-3.4,28-12,15.2-7.6,25.6-18.1,34-14,.3.1.5.2.5.3,1.5,1.1,2.6,4.8,6.5,24.7,2.8,14.4,1.3,17.5,4,28,2.4,9.1,6.6,16.4,15,31,7,12.1,11,19.1,18,28,4.4,5.6,8,9.5,12,17,4.3,8.1,6.7,12.5,6,18-1.1,9.2-10.1,15-21,22-5.2,3.3-12.4,7.9-23,10-8,1.6-16.2,3.2-22-1-5.5-3.9-5.5-10.7-7-25Z"
          />
          <path
            id="Thumbboneparts2"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M153.5,369c4.2-9,28.4-4.7,41-10,15.5-6.6,11.5-27.2,22-29,7-1.2,13.5,5.6,16,8,11.2,10.5,7.8,24.4,13,47,3.2,14,8.5,24.3,19,45,6.8,13.3,5.9,9.3,25,43,17.8,31.3,14.3,27.2,19,34,7.1,10.2,10.8,13.5,11,20,.4,14.2-16.4,24.7-20,27-15.3,9.7-30.9,7.8-37,7-6.2-.8-19.7-2.7-22-10-2-6.5,6.9-10.2,9-20,2.6-12.1-8.2-20.1-23-42-13.7-20.3-22.8-40.5-26-48-8.9-20.6-10.8-32.2-23-40-4.1-2.7-8.7-4.4-14-10-1.6-1.6-13.5-14.5-10-22Z"
          />
          <path
            id="Thumbboneparts1"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M105.5,193c9.1-4,8.2-6.3,18-11,11.4-5.5,16.9-4.4,20-10,2-3.6,2.3-8.8,0-12-2.1-2.9-5.1-2.2-11-5-4.4-2-4.1-3.1-11-8-10.5-7.5-13.1-6.5-16-11-2.7-4.2-.9-5.7-3-14-1.8-7-4.5-17.8-12-20-6-1.8-13.5,2.2-17,8-4.7,7.9-.5,16.9,0,18,3.8,7.8,10.2,8,11,14,.5,4.3-2.6,5.2-5,12-1.8,5.2-1.2,11.5,0,24,1.1,10.8,1.6,16.2,4,18,5.5,4.2,15.4-.1,22-3Z"
          />
        </g>
        <g id="forearm">
          <path
            id="forearmparts4"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M393.5,747c4,3.8,11.1,11.3,18,20,10.3,12.9,11.1,16.8,19,26,10.2,11.8,13.8,11.2,26,23,9.8,9.5,28.5,27.5,25,37-5.2,14.3-58.6,6.3-67,5-27-44.9-54-89.8-80.9-134.7,35.6,3.6,53,17.3,59.9,23.7Z"
          />
          <path
            id="forearmparts3"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M320.5,516c-3.1,7.8.1,11.4-3.1,19-2,4.7-4.8,7.3-8.9,11-3.7,3.4-13,11.8-28,15.1-8.9,2-19.2,4.2-28-1.1-3.7-2.2-5.5-4.8-7.9-4.1-4.3,1.3-7,12-3.1,18.1.7,1.1,2.2,2.7,18,6,7.8,1.6,11.9,2.5,18,3,8.4.8,12.6,1.1,17,0,8.5-2.2,8.2-6.2,16-8,8.8-2,11.3,2.7,18,0,4.2-1.7,6.8-5.5,12-13,1-1.5,2.9-4.4,8-14,5.8-11.1,8.7-16.7,9-20,.2-2,1.1-12.8-7-20-.7-.6-8.9-7.8-18-5-7.9,2.5-11.1,10.7-12,13h0Z"
          />
          <path
            id="forearmparts2"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M309.5,703c2,6.8,6.8,11,9,13,6.2,5.5,12,6.3,21,9,7.2,2.2,14.5,5.4,29,12,12.8,5.8,21.5,9.7,30,16,15.8,11.8,12.9,18.4,33,41,12.8,14.4,13.6,11.2,30,29,17.7,19.1,18.8,25,26,26,16,2.3,40.8-22.5,40-46-.7-21.5-22.2-22.3-53-62-25.5-32.9-24.9-50.5-49-72-28.4-25.3-33-28-33-28-8.2-4.9-13.5-6.8-16-13-2.7-6.8,1.7-9.7,3-30,.3-5.3.2-8.1,2-12,2.6-5.8,6-6.3,8-12,1.8-4.9,1.8-11.5-1-13-2.2-1.2-4.1,1.8-10,3-6.2,1.3-8.6-1.2-15-1-3,0-3.9.6-15,5-19.1,7.5-21.9,7.9-25,8-6.4,0-6.7-1.7-13-1-3.1.3-12.5,1.4-16,8-1.5,2.9-1.3,5.7-1,9,.7,7.2,3.3,11.3,8,21,6.3,13,8.6,16.2,7,20-2.1,4.9-7.2,4.2-11,10-4.9,7.5-3.1,18.8,1,26,4.3,7.6,8.6,6.3,11,13,2.8,8-2.7,11.8,0,21Z"
          />
          <path
            id="forearmparts1"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M277.8,583.1c9.4.7,14.6-2.8,16.7,0,1.5,2-.6,4.3-1,9-.5,5.3,1.5,8,8,21,7.7,15.5,8.7,19.2,7,22-1.2,2-3.8,3.8-6,3.3-4.9-1.1-1.8-12.7-9-20.3-3.1-3.3-8.7-4.8-20-8-9.5-2.7-12.7-2.4-16-6-4.8-5.2-4.1-12.4-4-13,.1-1.2,1-8.5,6-11,4.8-2.5,8,2.3,18.3,3Z"
          />
        </g>
        <g id="fingerboneparts">
          <path
            id="fingerboneparts6"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M320.5,516.1c2,1.1,7.5-12.9,17.8-13.6,6.5-.4,12.7,4.6,15.7,9.6,5.1,8.5,1.9,18.7-.9,27.2-6.8,20.4-19.3,31.4-17.2,33.6,1.9,2,11.5-9.1,27.7-9.8,8.4-.4,9.3,2.5,15,1,12.2-3.1,20.2-19.3,21-33,.4-7.7-1.6-12.2-3-18-4.6-18.7.6-34.2,6-53,2.8-9.8-7.7,26.3,21-92,5.3-21.9,7.3-44.5,14-66,2.7-8.7,6.2-18.5,4-31-2.5-13.9-9.2-15.2-11-29-2-15.8,5.8-20.9,1-32-1.6-3.8-2.7-3.5-15-16-15.1-15.4-14.5-16.8-19-19-11.6-5.8-30.3-3.4-38,7-2,2.7-5.9,9.7,4,45,5.3,19,10.4,31.1,13,38,10.2,27.6,9.2,51.5,8,80,0,0-2,46.6-20,93-5.4,14-8.6,22-16,31-7.5,9.2-13.2,11.8-20,23-7.2,11.6-9.9,23-8.1,24h0Z"
          />
          <path
            id="fingerboneparts5"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M357.6,621c-24.4,0-31.1,41-48.1,82"
          />
          <path
            id="fingerboneparts4"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M376.5,628c-10.4,0-18.9-7-18.9-7"
          />
          <path
            id="fingerboneparts3"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M378.3,608.9c-23.5,0-37.5-18-37.5-37.9"
          />
          <path
            id="fingerboneparts2"
            class="daumensattelgelenk_dp_right1312_cls1"
            d="M379.5,604c-12.4,0-22.4-20.9-22.4-39.4"
          />
          <path
            id="fingerboneparts1"
            class="daumensattelgelenk_dp_right1312_cls3"
            d="M381.1,280c26.6-34,60.4,5,60.4,0"
          />
        </g>
        <path
          id="outlines"
          class="daumensattelgelenk_dp_right1312_cls2"
          d="M331.8,845.1c-1.1-3.8-2.2-11.8-7-24.1-6.7-17.1-14.5-27-22-38-15.2-22.4-11-23.5-28-52-18-30.4-21.5-27.1-38-55-14.1-23.8-11.3-25.6-29-58-18.4-33.6-21.7-32.2-31-53-10-22.3-13.4-40.1-19-69-5.9-30.8-4.9-39.4-11-77-3.3-20.7-5.9-36.1-11-57-3.6-14.8-7.9-32.4-16-54-5-13.4-7-16.2-29-62-19.7-41-22.7-48.2-25-54-9-22.7-12.2-35.7-18-59-6.9-27.7-6.9-36.4-3-45,7-15.6,22.3-21.4,24-22,17.3-6.3,32.8,1.4,38,4,11.1,5.5,17.5,14.1,30,31,6,8.1,12,18.4,24,39,15.4,26.3,18.2,33.1,20,42,2.2,10.9.2,12.4,3,22,3.2,11,7.5,15.3,20,33,19.9,28.1,23.1,37.2,34,39,1.3.2,9,1.5,14-3,5.1-4.6,2.8-11.3,5-22,1.3-6.2,5.2-12.8,13-26,8.2-13.8,12.3-20.7,19-28,13.9-15,24.9-17.6,24-24-.7-5.3-8.5-4.6-20-13-15-10.9-23.1-27.2-26-34-5.2-12.1-4.1-17.8-10-21-5.5-3-9,.8-22,1-8.1,0-14.1-1.2-26-4-16.3-3.8-16.6-5.9-25-7-15.2-1.9-20.7,1.9-32,5-33,9-61.2-35-56-46,1.2-2.5,3.5-2.1,14-6,14.6-5.4,18.3-9.3,29-14,12.5-5.6,22.8-7.1,36-9,16.4-2.4,28.5-2,52-1,30.9,1.3,26.6,3.4,44,3,19.4-.5,27.9-3.2,40,2,5.5,2.4,10.7,5.9,20,16,16,17.3,22.7,30.7,33,47,7.6,12.1,17.4,24.7,37,50,20.5,26.5,30.8,39.7,34,43,9.9,10.1,18.2,16.7,23,30,4,11.1,3.5,20.9,3,27-2.6,33.4-11.6,63-15,75-9.3,32.8-2.7,25.1-16,88-8.1,38.3-10.9,42.7-15,70-4.7,31.1-6.9,47-3,68,4.3,23,13.6,39.4,23,56,2.5,4.5,13.9,24.2,34,47,12.6,14.3,16.7,16.2,44,43,18.9,18.6,28.3,27.8,31.5,32,25.4,32.9,25.6,101.5-10.5,142-61.1,68.4-191.4,18.3-202-17.9Z"
        />
        <path
          id="thaumbsattlepart9"
          class="daumensattelgelenk_dp_right1312_cls4"
          d="M254.8,269c6.1-2.3,15.4-5.6,27-9,10.9-3.2,18.2-4.7,28-7,10-2.3,24.3-5.9,42-11"
        />
        <path
          id="thaumbsattlepart8"
          class="daumensattelgelenk_dp_right1312_cls4"
          d="M335.8,202c-3.2,1.9-7.7,5.1-12,10-3.7,4.2-5.6,7.8-9,14-2.4,4.5-6.4,11.8-12,22"
        />
        <path
          id="thaumbsattlepart7"
          class="daumensattelgelenk_dp_right1312_cls4"
          d="M232.8,275c4.7,5.8,9.5,9.6,13,12,7.2,4.9,13.6,6.9,20,9,8,2.6,14.4,3.6,17,4,7,1,17.4,1.8,30,0"
        />
        <path
          id="thaumbsattlepart6"
          class="daumensattelgelenk_dp_right1312_cls4"
          d="M116.8,95c1.3,3.6,2.3,8.7,0,13-2.2,4.1-6.4,5.2-11,7-10,4-6.8,5.4-33,21-9.8,5.9-12.2,6.8-15,6-6.9-2-9.3-12.6-11-20-1.7-7.2-3.3-19.3,0-35"
        />
        <path
          id="thaumbsattlepart5"
          class="daumensattelgelenk_dp_right1312_cls3"
          d="M121.7,233.6c19.7,49.5,34.1,63.4,31.2,96.3"
        />
        <path
          id="thaumbsattlepart4"
          class="daumensattelgelenk_dp_right1312_cls3"
          d="M143.5,225c17.1,41.3,34.2,81,49.2,81"
        />
        <path
          id="thaumbsattlepart3"
          class="daumensattelgelenk_dp_right1312_cls3"
          d="M166.3,352c0-12.2,15.9-22.1,35.5-22.1"
        />
        <path
          id="thaumbsattlepart2"
          class="daumensattelgelenk_dp_right1312_cls3"
          d="M177.8,386c2.5,3.6,6.7,8.7,13,13,5.6,3.8,8.2,3.9,12,7,6.8,5.6,7.8,13.2,10,21,1.2,4.3,6.5,13.8,17,33,14.2,25.8,16.5,25.5,21,37,3.7,9.5,6.7,21.4,7,36"
        />
        <path
          id="thaumbsattlepart1"
          class="daumensattelgelenk_dp_right1312_cls3"
          d="M218.8,366c1.6,6.4,4.4,16.1,10,27,7.2,14.2,11.2,15.3,19,30,6.5,12.3,4.7,13.3,14,34,5.4,12.1,8.5,18.9,14,27,8,11.8,16.7,19.9,23,25"
        />
      </g>
      <g id="Highlights">
        <path
          id="Highlights1"
          class="daumensattelgelenk1312fill"
          style={{
            fill: props.colors.daumensattelgelenk1312fill,
            stroke: props.colors.daumensattelgelenk1312fill,
          }}
          d="M249.5,531c-7.2,33.7,51.4,27,67.4-12.7,50.1,58-124.3,78.9-67.4,12.7Z"
        />
      </g>
    </svg>
  );
}

export default RechtesDaumenSattelGelenkDpSitzend1312;
