import { Modal, Container, Row, Col } from "react-bootstrap";
import AuthorizedElement from "../../Utilities/AuthorizedElement";
import PatientsTable from "../../Patients/PatientListTable";

function SelectPatientModal({
  shown,
  close,
  amount,
  rowClickHandler = undefined,
  ...props
}) {
  const handleRowClick = (evt, row) => {
    if (rowClickHandler) {
      rowClickHandler(row.original);
    } else {
      console.warn("No click handler assigned");
    }
  };
  return (
    <Modal size={"lg"} className={props?.className} show={shown} onHide={close}>
      <Modal.Header closeButton className="py-2">
        <h5>Select destination Patient</h5>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <AuthorizedElement roles={["pm_admin", "pm_user", "pm_facility"]}>
              <Col xs={12} md={12} xl={12} sm={12} className="h-50">
                <PatientsTable pageSize={amount} rowClick={handleRowClick} />
              </Col>
            </AuthorizedElement>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default SelectPatientModal;
