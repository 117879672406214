import { Row, Col, Card, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { setReportType } from "../../../Redux/actions";
import "./stylesheets/SelectReportType.scss";

export default function SelectReportType({ reportTypes }) {
  const dispatch = useDispatch();
  const selectedReportType = useSelector((state) => state.reports.repType);

  return (
    <Row>
      <Col md={4} sm={6}>
        <Card>
          <ListGroup variant="flush" className="typesList">
            {Object.keys(reportTypes).map((reportTypeKey, key) => (
              <ListGroup.Item
                key={`reportytypeitem${key}`}
                className={`typeSelector ${
                  selectedReportType ===
                    reportTypes[reportTypeKey].reportType && "active"
                }`}
                onClick={() => {
                  dispatch(
                    setReportType(reportTypes[reportTypeKey].reportType)
                  );
                }}
              >
                {reportTypes[reportTypeKey].reportName}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
      </Col>
      <Col md={8} sm={6}>
        {reportTypes[selectedReportType].reportDescription}
      </Col>
    </Row>
  );
}
