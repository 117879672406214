import style from "./style.scss";

export default function LinkesRadiuskopfchenNachGreenspanLatSitzend2222(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 613.55 504.95">
      <g id="Radialheadgreenspanlat">
        <g id="Backround">
          <path
            id="backround"
            class="radiuskopfchen_nach_greenspan_left2222_cls3"
            d="M11.23,406.98c15.1,1.07,20.95,1.11,23.14,1,10.57-.52-16.58,1.29,35.21,0,45.36-1.13,72.12-2.66,101.6-3,55.94-.64,47.27,3.87,88.52,2,15.01-.68,39.96-2.36,74.44,0,5.77.39,36.14,2.47,36.21,6,.03,1.56-8.36,3.39-25.15,7-10.87,2.34-16.32,3.51-25.15,5-16.57,2.8-30.07,4.24-38.23,5-26.38,2.47-50.2,2.87-69.41,3-32.42.22-75.18.52-123.73-2-20.76-1.08-41.84,2.24-62.37-1-10.67-1.68-19.49-3.93-24.14,1-5.83,6.17,1.89,16.14,2.01,42,.07,14.01-2.17,17.1,1.01,21,6.33,7.76,21.13,2.72,44.26,1,6.74-.5,6.13-.12,48.29,2,11.48.58,73.15,3.68,113.67,5,59.14,1.93,48.5-.74,126.75,1,44.17.98,63.21,2.18,100.59,1,46.72-1.47,39.61-4.06,60.36-3,3.03.15,36.99,1.94,66.39-5,4.09-.97,11.47-2.9,15.09-9,3.85-6.49-.37-11.21,0-23,.38-12.06,4.95-11.74,9.05-32,3.69-18.22.9-22.91-1.01-25-3.23-3.57-7.48-3.65-8.05-7-.62-3.74,4.36-5.58,7.04-12,1.86-4.44,1.32-7.99,1.01-13-.53-8.66.89-17.33,1.01-26,.26-18.71,3.78-70.78,8.05-121,4.62-54.37,5.72-54.83,9.05-100,6.35-86.1-.01-52.55,3.02-76,2.91-22.51,7.7-45.16-2.01-51-2.99-1.8-5.16-.69-18.55,0-4.25.22-12.22.15-28.17,0-31.84-.29-37.75-2.41-40.8,2-2.35,3.41,1.2,4.66,3.58,25,1.3,11.07,1.68,22.9,1.72,24.26.02.64.07,1.95.09,3.55.13,10.24-.66,8.9-.94,26.41-.26,16.9.31,28.89.57,35.78.74,19.41,2.03,52.94-2.01,90-2.03,18.62-3.43,20.03-6.04,45-2.74,26.19-4.09,39.29-4.02,42,.26,9.6,1.33,17.47-3.02,27-1.45,3.18-4.7,10.3-12.07,15-6.85,4.37-9.61,1.77-24.14,7-7.58,2.73-11.46,4.17-15.09,8-5.08,5.35-5.9,11.27-8.05,11-1.79-.22-1.68-4.41-5.03-12-2.3-5.21-3.45-7.82-6.04-10-4.01-3.39-8.83-3.61-17.1-4-6.24-.29-9.36-.44-12.07,1-5.82,3.08-4.5,8.47-10.06,13-1.07.86-4.3,3.26-17.1,4-11.23.65-19.91-.54-23.14-1-19.83-2.82-25.1-4.25-37.22-6-15.77-2.27-23.66-3.41-34.2-3-13.99.54-17.24,2.99-35.21,4-8.54.48-11.85.15-24.14,0-14.07-.17-26.82.22-52.31,1-24.24.74-36.36,1.11-48.29,2-33.81,2.51-29.85,4.8-49.29,5-23.23.24-32.91.49-52.31,1-9.91.26-14.95.46-19.11,4"
          />
        </g>
        <g id="Outlines">
          <path
            id="outline3"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M15.35,408.42c3.64.85,8.49.09,19.58-.46,10.51-.52-16.48,1.29,35,0,45.09-1.13,71.69-2.66,101-3,55.61-.64,46.99,3.87,88,2,33.23-1.51,49.76-4.97,74,0,13.32,2.73,20.83,6.34,36,6h0c11.16-.24,18.04-2.43,26.76,2.02,5.19,2.65,13.66,9,15,10,6.51,4.88,8.44,7.07,12,7,3.67-.07,5.66-2.48,12-8,11.87-10.33,11.97-8.02,15-12,8.53-11.22,5.74-26.88,5-31-.69-3.85-2.38-8.58-5.76-18.02-1.11-3.09-2.61-7.11-5-12-2.73-5.59-4.1-8.38-6-10-3.99-3.39-8.78-3.61-17-4-6.2-.29-9.3-.44-12,1-5.79,3.08-4.47,8.47-10,13-1.06.86-4.27,3.26-17,4-11.16.65-19.79-.54-23-1-19.71-2.82-24.95-4.25-37-6-15.68-2.27-23.52-3.41-34-3-13.91.54-17.14,2.99-35,4-8.49.48-11.78.15-24,0-13.99-.17-26.66.22-52,1-24.1.74-36.15,1.11-48,2-33.61,2.51-29.67,4.8-49,5-23.09.24-32.72.49-52,1-5.84.15-9.98.29-13.24,1.11"
          />
          <path
            id="outline2"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M14.68,498.02c7.93.78,19.7-1.9,35.24-3.06,6.7-.5,6.09-.12,48,2,11.41.58,72.72,3.68,113,5,58.79,1.93,48.21-.74,126,1,43.91.98,62.84,2.18,100,1,46.44-1.47,39.38-4.06,60-3,3.01.15,36.77,1.94,66-5,4.07-.97,11.4-2.9,15-9,3.83-6.49-.37-11.21,0-23,.38-12.06,4.92-11.74,9-32,3.14-15.62,1.54-21.22-1-25-2.66-3.96-6.52-6.17-8-7h0c-2.02-1.13-9.03-5.05-15.24-2.98-6.13,2.04-5.9,8.06-12,15-6.67,7.59-15.34,9.93-23,12-7.8,2.11-21.76,5.88-35-1-8.9-4.63-12.97-11.73-20-24-5.45-9.51-10.14-17.7-9-28,.89-8.12,4.82-12.27,3-14-2.98-2.83-16.23,5.69-25,13-9.33,7.78-9.07,10.42-19,19-4.79,4.14-11.88,10.18-22,15-7.36,3.5-7.1,1.97-31.76,8.98-12.54,3.57-15.84,5.02-25,7-10.81,2.34-16.22,3.51-25,5-16.47,2.8-29.89,4.24-38,5-26.22,2.47-49.9,2.87-69,3-32.23.22-74.74.52-123-2-20.64-1.08-41.59,2.24-62-1-4.46-.71-8.6-1.51-12.24-1.85"
          />
          <path
            id="outline1"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M513.72.98c5.9,27.53,9.35,47.76,9.47,51.24.07,1.79.09,3.55.09,3.55.13,10.24-.66,8.9-.93,26.41-.26,16.9.31,28.89.57,35.78.74,19.41,2.02,52.94-2,90-2.02,18.62-3.41,20.03-6,45-2.72,26.19-4.07,39.29-4,42,.26,9.6,1.32,17.47-3,27-1.44,3.18-4.67,10.3-12,15-6.81,4.37-9.55,1.77-24,7-9.62,3.48-13.21,6.37-15,8-1.9,1.72-5.53,5.43-8,11-5.08,11.48-1.59,22.69-.24,27.02,4.17,13.39,13.21,21.08,19,26,12.33,10.48,24.79,14.21,31,16,10.33,2.97,34.13,9.82,55-2,5.36-3.04,14.5-8.21,19-18,1.59-3.47,2-6.31,5.24-12.02,3.24-5.72,5.15-7.55,7-12,1.85-4.44,1.31-7.99,1-13-.53-8.66.88-17.33,1-26,.26-18.71,3.76-70.78,8-121,4.59-54.37,5.69-54.83,9-100,2.58-35.11,1.88-34.42,1.75-42.17-.15-8.32.03-17.81,1.25-33.83,1.47-19.36,2.41-21.12,2.8-33.98.22-7.13.12-13.02,0-17"
          />
        </g>
        <g id="Overview">
          <path
            id="partofancon4"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M521.68,277.98c1.78,11.12.96,20.18,0,26-1.07,6.48-2.28,13.41-7,21-.79,1.28-4.61,7.28-12,13-10.02,7.75-14.43,5.57-21,11-11.48,9.5-11.18,27.13-11,31,.15,3.13.95,20.36,15,31,14.24,10.79,31.29,6.63,38,5,7.43-1.81,20.59-5.02,27-17,5.41-10.12,4.28-23.24-1-32-3.01-5-5.6-5.63-18-21-2.89-3.58-5.48-6.93-5-11,.51-4.34,4.2-7.01,8-10,0,0,10.38-8.16,19-17,7.1-7.28,9.73-15.85,15-33,2.01-6.56,4.4-15.72,6-27"
          />
          <path
            id="partofancon3"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M579.68,226.01c4.96,0,8.98-94.84,8.98-212.03"
          />
          <path
            id="partofancon2"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M527.45,274.98c6.21,0,24.49-115.02,12.49-272.02"
          />
          <path
            id="partofancon1"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M558.4,392.9c8.97-5.99,15.84-14.91,25.77-26.92"
          />
          <path
            id="radius5"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M443.92,350.96c-6.62-4.98-14.64,26.51,2.33,63.02"
          />
          <path
            id="radius4"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M446.25,413.98c-4.6,0-30.57-36.51-8.33-73.02"
          />
          <path
            id="ulna3"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M318.68,433.98c42,9,78,2.29,78-3.54"
          />
          <line
            id="ulna2"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            x1="14.68"
            y1="483.98"
            x2="408.68"
            y2="481.98"
          />
          <line
            id="ulna1"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            x1="381.68"
            y1="440.98"
            x2="1.52"
            y2="443.57"
          />
          <path
            id="radius3"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M261.68,355.98c18.33-6.99,32.55-7.11,42-6,3.45.4,15.35,3.61,39,10,16.16,4.37,25.07,6.86,35,3,5.9-2.29,16.45-3.03,19-5.8"
          />
          <path
            id="radius2"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M14.68,371.98c7.26-.57,18.79-1.39,33-2,32.58-1.41,42.64-.15,69-2,23.25-1.63,18.61-2.82,39-4,28.09-1.62,38.47.56,61-2,15.84-1.8,28.4-4.88,36-7"
          />
          <path
            id="radius1"
            class="radiuskopfchen_nach_greenspan_left2222_cls4"
            d="M9.68,395.98c3.25-.27,8.1-.65,14-1,38-2.26,62.08.07,84,1,44.57,1.88,37.32-3.84,96-3,16.61.24,7.29.55,70,3,27.57,1.08,39.97,1.43,58,4,20.41,2.91,36.83,7.07,47.57,10.15"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="radialheadhighlight"
          class="RadiusHead2222Fill"
          style={{
            fill: props.colors.RadiusHead2222Fill,
            stroke: props.colors.RadiusHead2222Fill,
          }}
          d="M397.34,380.73c.56,4.61-.52,5.6-1.42,13.75-.83,7.56-1.47,13.97,1.01,18,1.33,2.16,5.04,6.07,5.57,6.58.31.3.64.55.64.55.59.44,1.12.59,3.66,2.43,1.15.83,1.45,1.09,6.57,5.06,1.7,1.31,2.11,1.63,2.83,2.12,3.34,2.28,4.82,2.56,5.26,2.63.5.08,1.84.29,3.39-.15,2.1-.59,3.36-1.99,4.1-2.78.16-.17,2.85-2.46,8.24-7.03,2.55-2.16,4.37-3.73,7.08-5.61,2.46-1.71,3-1.85,4.15-2.83,1.23-1.05,3.48-3.33,5.31-8.93,3.33-10.18,1.57-20.22.95-23.6-.79-4.26-1.83-7.36-3.65-12.8-.56-1.68-1.11-3.22-1.64-4.63-1.46-3.88-2.75-6.83-3.35-8.18-1.85-4.15-2.92-6.55-4.85-9.61-1.8-2.86-2.7-4.28-4.15-5.46-2.64-2.14-5.69-2.73-10.62-3.03-9.99-.6-14.52-.8-17.5.69-.59.29-1.43.71-2.32,1.53-1.39,1.27-1.55,2.24-3.54,6-1.35,2.56-1.9,3.28-2.43,3.88-.38.43-.71.74-1,.98-.56.47-.83.72-1.53,1.21-1.05.74-5.77,7.6-3.8,18.91,1,5.72,2.42,5.19,3.03,10.31Z"
        />
        <path
          id="articulatiohumeroulnarishighlight"
          class="Articulati2222Fill"
          style={{
            fill: props.colors.Articulati2222Fill,
            stroke: props.colors.Articulati2222Fill,
          }}
          d="M467.73,345.94c2.07.77,2.04,5.59,2.02,8.09-.04,6.12-1.72,10.41-2.22,12.13-3.16,10.76,8.76,25.39,17.93,32.43,10.86,8.33,22.27,9.4,31.28,10.25,5.44.51,17.23,1.5,30.2-3.24,10.09-3.68,14.29-5.93,14.29-5.93,2.9-1.47,5.26-2.84,9.17-4.04,1.15-.35,9.56-2.94,10.71-.86.97,1.75-3.82,5.11-5.85,10.57-.86,2.32-1.23,4.21-2.87,7.35,0,0-1.31,2.54-2.95,4.7-4.02,5.31-15.76,7.44-15.76,7.44-9.66,1.75-11.23.56-23.26,1.62-12.28,1.08-15.19,2.72-23.87,1.82-2.43-.25-6.79-.86-11.73-2.43-1.41-.45-27.82-9.26-34.99-31.01-1.57-4.77-.39-4.59-2.97-14.83-2.73-10.88-4.79-13.72-3.28-18.67,1.21-3.96,3.75-6.72,7.26-10.52,2.56-2.77,5.08-5.52,6.88-4.85Z"
        />
      </g>
    </svg>
  );
}
