import moment from "moment";

export function getDDMMYYY_HHMM(dateString) {
  if (dateString == null) return "";
  return moment(dateString).format("DD.MM.YYYY HH:mm:ss");
}

export function getDDMMYYY(dateString) {
  if (dateString == null) return "";
  return moment(dateString).format("DD.MM.YYYY");
}

export function getDidomNow(dateString) {
  if (dateString == null) {
    return moment().format("YYYYMMDD");
  }
  return moment(dateString).format("YYYYMMDD");
}

export function getDbDateTimeString(dateString) {
  if (dateString == null) {
    return moment().format("YYYY-MM-DD HH:mm:ss.SSS");
  }
  return moment(dateString).format("YYYY-MM-DD HH:mm:ss.SSS");
}

export function getDbDateString(dateString) {
  if (dateString == null) {
    return moment().format("YYYY-MM-DD");
  }
  return moment(dateString).format("YYYY-MM-DD");
}

export function getHHMM_DDMMYYY(dateString) {
  if (dateString) {
    return moment(dateString).format("HH:mm DD-MM-YYYY");
  } else {
    return moment().format("HH:mm DD-MM-YYYY");
  }
}

export function getDDMMYYY_HHMM_OBJ(dateString) {
  if (!dateString) return undefined;
  let momentObj = moment(dateString);
  return {
    day: momentObj.day(),
    month: momentObj.month(),
    year: momentObj.year(),
    hour: momentObj.hour(),
    minute: momentObj.minute(),
    second: momentObj.second(),
    miliSecond: momentObj.millisecond(),
    date: momentObj.format("DD-MM-YYYY"),
    time: momentObj.format("HH:mm"),
  };
}
