import { Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addExamplannerExamination,
  removeExamplannerExamination,
  clearExamplannerExamination,
  selectExamplannerBodypart,
} from "../../../Redux/actions.js";
import useWindowSize from "../../../Utilities/Window/windowSize";

import leying from "./static/patpositions/leying.svg";
import sitting from "./static/patpositions/sitting.svg";
import standing from "./static/patpositions/standing.svg";

import { ExamPlannerExaminationImage } from "./ExamPlannerImage.jsx";

import { loadExaminations } from "../../../Utilities/Examinations/ExamsHarmonizer";
import { useKeycloak } from "@react-keycloak/web";

import "./stylesheets/IndicationList.scss";

const capitalize = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

function ExamPlannerExamRow({ examination, selectedExaminations }) {
  const dispatch = useDispatch();

  const posMap = {
    1: leying,
    2: standing,
    3: sitting,
  };

  const getPosIcon = (posId) => {
    let icn = posMap[posId];
    if (icn) {
      return icn;
    } else {
      return posMap[1];
    }
  };

  const isSelected =
    selectedExaminations.findIndex((ex) => ex.id === examination.id) > -1;

  return (
    <div
      className={`p-2 border rounded d-flex align-items-center justify-content-left exmpl-row-btn ${
        isSelected ? "selected" : ""
      }`}
      onClick={() => {
        if (!isSelected) {
          dispatch(selectExamplannerBodypart(examination?.anatomy_organs));
          dispatch(addExamplannerExamination(examination));
        } else {
          dispatch(removeExamplannerExamination(examination));
        }
      }}
    >
      <div className="p-1 patpos-img-box" style={{ flexShrink: 1 }}>
        <ExamPlannerExaminationImage examId={examination.id} />
      </div>
      <div className="p-1 patpos-img-box" style={{ flexShrink: 1 }}>
        <img src={getPosIcon(examination.patpos)} alt="patpos" />
      </div>
      <div className="ml-2 font-weight-bold" style={{ flexShrink: 10 }}>
        {examination?.designationde}
      </div>
    </div>
  );
}

function ExaminationList() {
  const { keycloak } = useKeycloak();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  const [examFilter, setExamFilter] = useState("");
  const [examaintionList, setExamaintionList] = useState([]);
  const [hasMoreExaminations, setHasMoreExaminations] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const selectedExaminations = useSelector(
    (state) => state.examPlanner.risData.examinations
  );

  const selectedIndications = useSelector(
    (state) => state.examPlanner.risData.indications
  );

  const selectedBodyPart = useSelector(
    (state) => state.examPlanner.viewControll.selectedBodyPart
  );

  const loadExaminationsFromDb = (
    loadNextPage = false,
    loadFromFirstPage = false
  ) => {
    let page = currentPage;
    let filter = undefined;
    let indid = undefined;

    if (loadNextPage) {
      setCurrentPage(currentPage + 1);
      page = page + 1;
    }

    if (loadFromFirstPage) {
      setCurrentPage(1);
      page = 1;
    }
    if (Object.keys(selectedBodyPart).length > 0) {
      filter = {
        model: "Exams",
        field: "anatomy_id",
        op: "eq",
        value: selectedBodyPart.id,
      };
    }

    if (selectedIndications.length > 0) {
      let ids = selectedIndications.map((a) => a.id);
      indid = { indIds: ids };
    }

    if (examFilter && examFilter.length > 0) {
      if (filter) {
        filter = {
          and: [
            filter,
            {
              or: [
                {
                  field: "designationde",
                  op: "like",
                  value: `%${examFilter}%`,
                },
                {
                  field: "designationde",
                  op: "like",
                  value: `%${examFilter.toUpperCase()}%`,
                },
                {
                  field: "designationde",
                  op: "like",
                  value: `%${examFilter.toLowerCase()}%`,
                },
                {
                  field: "designationde",
                  op: "like",
                  value: `%${capitalize(examFilter)}%`,
                },
              ],
            },
          ],
        };
      } else {
        filter = {
          or: [
            {
              field: "designationde",
              op: "like",
              value: `%${examFilter}%`,
            },
            {
              field: "designationde",
              op: "like",
              value: `%${examFilter.toUpperCase()}%`,
            },
            {
              field: "designationde",
              op: "like",
              value: `%${examFilter.toLowerCase()}%`,
            },
            {
              field: "designationde",
              op: "like",
              value: `%${capitalize(examFilter)}%`,
            },
          ],
        };
      }
    }

    loadExaminations(keycloak, filter, 25, page, indid).then((data) => {
      if (data.examinations && data.examinations.length < 25) {
        setHasMoreExaminations(false);
      } else {
        setHasMoreExaminations(true);
      }

      if (loadNextPage && data?.examinations) {
        let examaintionListCopy = [...examaintionList];
        setExamaintionList([...examaintionListCopy, ...data.examinations]);
      } else {
        setExamaintionList(data?.examinations);
      }
    });
  };

  const getListHeight = () => {
    if (windowSize.width < 1200) {
      return "64vh";
    } else {
      return "75vh";
    }
  };

  useEffect(() => {
    loadExaminationsFromDb();
  }, []);

  useEffect(() => {
    loadExaminationsFromDb(false, true);
  }, [selectedBodyPart, selectedIndications]);

  useEffect(() => {
    loadExaminationsFromDb(false, true);
  }, [examFilter]);

  return (
    <div>
      <div
        className={`d-flex align-items-center w-100 justify-content-between ${
          selectedExaminations.length === 0 ? "mb-3" : "mb-1"
        }`}
        style={{ flexBasis: 1 }}
      >
        <Form
          style={{ flexGrow: 20 }}
          onSubmit={(e) => {
            e.preventDefault();
            loadExaminationsFromDb(false);
          }}
        >
          <Form.Group className="my-auto" controlId="formExamination">
            <Form.Control
              type="search"
              placeholder="Search Examinations"
              value={examFilter}
              onChange={(e) => setExamFilter(e.target.value)}
            />
          </Form.Group>
        </Form>
        <button
          style={{ flexGrow: 1, justifySelf: "end" }}
          className="border-0 p-0 m-0 bg-white text-right"
          disabled={selectedExaminations.length === 0}
        >
          <i
            onClick={() => {
              dispatch(clearExamplannerExamination());
            }}
            className="fa fa-broom p-2 border rounded btn-like-icon ml-auto"
          />
        </button>
      </div>
      {selectedExaminations.length > 0 && (
        <div className="d-flex mb-3" style={{ flexWrap: "wrap" }}>
          {selectedExaminations.map((examination, key) => (
            <div
              className="d-flex border align-items-center p-1 exp-selected-item"
              key={`selexmskey${key}`}
              onClick={() => {
                dispatch(removeExamplannerExamination(examination));
              }}
            >
              <div className="ml-2">{examination?.designationde}</div>
              <i className="fas fa-times fa-xs ml-2" />
            </div>
          ))}
        </div>
      )}
      <div
        name="ExaminationList"
        className="scrollable-list d-flex flex-column"
        style={{ maxHeight: getListHeight() }}
      >
        {examaintionList?.map((examination, key) => (
          <ExamPlannerExamRow
            key={`examinationKey${examination.id}${key}`}
            examination={examination}
            selectedExaminations={selectedExaminations}
          ></ExamPlannerExamRow>
        ))}
        <Button
          onClick={() => loadExaminationsFromDb(true)}
          disabled={!hasMoreExaminations}
        >
          <i className="mr-2 fas fa-sync fa-xs" />
          Load More
        </Button>
      </div>
    </div>
  );
}

export default ExaminationList;
