import style from "./style.scss";

function RechtesSesambeinGrossZeheTangLiegend1426(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 281.03 471.16">
      <g id="sesame_bone" data-name="sesame bone">
        <path
          id="sesame_bone_1"
          data-name="sesame bone 1"
          class="Rechtes_Sesambein_Grosszehe_tang_liegend1426_cls_4"
          d="M164.78,470.87c13.85-91.71,35.4-167.97,68.94-220.17,2.3-3.57,5.16-6.74,8.38-9.5,13.62-11.68,24.41-25.32,32.59-40.77,9.22-24.12,6.61-40.32-8.65-48.06l4.17-21.47c2.81-24.4-2.43-39.42-19.23-41.01-20.42,2.88-38.91-4.43-55.75-20.51-9.43-15.44-22.2-21.56-40.05-13.46-35.24,11.6-70.52,24.65-106.38,54.79-11.35,9.75-12.37,20.42,8.43,33.03,3.42,2.08,6.15,5.14,7.82,8.78,13.29,28.91,21.18,57.17,18.36,84.12-3.29,46.71-18.54,83.73-50.7,107.03-3.43,2.49-7.22,4.42-11.12,6.1-13.62,5.86-18.58,17.43-18.93,32.02"
        />
        <path
          id="sesame_bone_2"
          data-name="sesame bone 2"
          class="Rechtes_Sesambein_Grosszehe_tang_liegend1426_cls_3"
          d="M186.5,364.62c23.87-21.65,45.52-42.92,49.22-61.17,3.37-12.25-.87-27.78-7.24-44.23,6.04-17.66,10.61-35.58,18.77-46.16,4.15-5.37,7.1-11.57,8.24-18.26,3.16-18.48,6.66-32.89,10.55-42.43-17.08-18.36-37.88-27.3-62.43-26.75-4.99.11-9.99.02-14.96-.51-30.73-3.28-61.7,4.66-92.75,16.04"
        />
        <path
          id="sesame_bone_3"
          data-name="sesame bone 3"
          class="Rechtes_Sesambein_Grosszehe_tang_liegend1426_cls_4"
          d="M121.21,7.85c2.69,3.67,3.11,9.84,1.44,18.3-.6,3.04-.21,6.14.68,9.11,1.02,3.43-.53,8.57-4.59,15.35-.66,1.11-1.23,2.28-1.66,3.49-3.6,10.12-8.63,16.67-17.98,12.39-6.55-2.32-12.69-3.19-17.96-.97-1.78.75-3.71,1.17-5.61.86-7.02-1.15-11.73-8.16-12.96-23.93-1.34-10.2,7.28-20.81,23.07-31.72,15.1-12.7,26.56-12.22,35.57-2.88h0Z"
        />
        <path
          id="sesame_bone_4"
          data-name="sesame bone 4"
          class="Rechtes_Sesambein_Grosszehe_tang_liegend1426_cls_4"
          d="M256.81,51.58c-2.86,2.66-5.38,5.66-7.21,9.1-2.9,5.45-7.7,7.96-14.63,7.15-14.11.15-25.33-4-31.21-16.11-1.34-2.75-3.47-4.99-5.98-6.74-4.85-3.39-6.75-8.2-4.47-15.02,4.78-7.87,12.52-10.87,21.47-11.86,16.01-4.8,29.13-.29,39.09,14.42,7.65,6.86,9.15,13.25,2.93,19.05h.01Z"
        />
        <path
          id="sesame_bone_5"
          data-name="sesame bone 5"
          class="Rechtes_Sesambein_Grosszehe_tang_liegend1426_cls_3"
          d="M.14,327.05c35.52,10.13,68.49,23.07,99.22,38.48,43.58,21.79,81.83,57.5,115.44,105.34"
        />
        <path
          id="sesame_bone_6"
          data-name="sesame bone 6"
          class="Rechtes_Sesambein_Grosszehe_tang_liegend1426_cls_3"
          d="M180.97,428.62c13.09-8.52,26.56-17.3,40.45-26.34,4.71-3.07,6.08-9.45,2.79-14-1.64-2.28-3.91-4.6-6.6-6.95-.79-.69-.25-1.98.79-1.9,6.85.5,12.46-1.27,16.69-5.57,5.33-5.42,6.72-13.7,3.28-20.48-5.56-10.94-20.34-20-39.73-28.1"
        />
      </g>
      <g id="overlays">
        <g id="sesame_bone_overlay" data-name="sesame bone overlay">
          <path
            id="sesame_bone_overlay_1"
            data-name="sesame bone overlay 1"
            class="sesambeine1426Fill"
            style= {{
                fill: props.colors.sesambeine1426Fill,
                stroke: props.colors.sesambeine1426Fill,
                opacity:props.colors.sesambeine1426Fill,
              }}
            d="M121.21,7.77c2.69,3.67,3.11,9.84,1.44,18.3-.6,3.04-.21,6.14.68,9.11,1.02,3.43-.53,8.57-4.59,15.35-.66,1.11-1.23,2.28-1.66,3.49-3.6,10.12-8.63,16.67-17.98,12.39-6.55-2.32-12.69-3.19-17.96-.97-1.78.75-3.71,1.17-5.61.86-7.02-1.15-11.73-8.16-12.96-23.93-1.34-10.2,7.28-20.81,23.07-31.72,15.1-12.7,26.56-12.22,35.57-2.88h0Z"
          />
          <path
            id="sesame_bone_overlay_2"
            data-name="sesame bone overlay 2"
            class="sesambeine1426Fill"
            style= {{
                fill: props.colors.sesambeine1426Fill,
                stroke: props.colors.sesambeine1426Fill,
                opacity:props.colors.sesambeine1426Fill,
              }}
            d="M256.81,51.5c-2.86,2.66-5.38,5.66-7.21,9.1-2.9,5.45-7.7,7.96-14.63,7.15-14.11.15-25.33-4-31.21-16.11-1.34-2.75-3.47-4.99-5.98-6.74-4.85-3.39-6.75-8.2-4.47-15.02,4.78-7.87,12.52-10.87,21.47-11.86,16.01-4.8,29.13-.29,39.09,14.42,7.65,6.86,9.15,13.25,2.93,19.05h.01Z"
          />
        </g>
        <path
          id="metatarsal_head_overlay"
          data-name="metatarsal head overlay"
          class="MetatarsalHead1426Fill"
          style= {{
            fill: props.colors.MetatarsalHead1426Fill,
            stroke: props.colors.MetatarsalHead1426Fill,
            opacity:props.colors.MetatarsalHead1426Fill,
          }}
          d="M250.98,89.89c-22.67,2.33-41.01-5.04-55.75-20.51-9.92-17.24-23.93-19.27-40.05-13.46-27.93,9.36-54.18,20.35-77.7,33.97-38.18,21.64-50.94,36.53-15.05,58.43,6.6,9.97,11.9,26.32,17,43.61,52.96,18.38,112.07,17.58,176.55,0,13.96-55.13,26.35-96.8-5.01-102.03Z"
        />
      </g>
    </svg>
  );
}

export default RechtesSesambeinGrossZeheTangLiegend1426;
