import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { capitalize } from "../../Utilities/stringHelpers/stringHelpers";
import { loadAnatomyOrgans } from "../../Utilities/Examinations/anatomy";
import { Button } from "react-bootstrap";
import { useEffect } from "react";

import "./style.scss";

function BodyPartsList({
  selected = undefined,
  selectHandler = undefined,
  itemsPerPage = 10,
  listHeight = "70vh",
}) {
  const { keycloak } = useKeycloak();

  const [searchTerm, setSearchTerm] = useState("");
  const [bodyParts, setBodyParts] = useState([]);
  const [bodyPartsAvailable, setBodyPartsAvailable] = useState(null);
  const [selectedBodyParts, setSelectedBodyParts] = useState([]);
  const pageSize = itemsPerPage;
  const [pageNr, setPageNr] = useState(1);

  const handleSelect = (bpart) => {
    if (selectHandler) {
      selectHandler(bpart);
      return;
    }
    let idx = selectedBodyParts.findIndex((b) => b.id === bpart.id);
    if (idx === -1) {
      setSelectedBodyParts([...selectedBodyParts, bpart]);
    } else {
      let selectedCopy = [...selectedBodyParts];
      selectedCopy.splice(idx, 1);
      setSelectedBodyParts(selectedCopy);
    }
  };

  const loadFromApi = (refresh = false) => {
    let filter = undefined;
    let page = pageNr;
    if (refresh) {
      page = 1;
      setPageNr(1);
    }
    if (searchTerm !== "") {
      filter = {
        or: [
          { field: "designation_de", op: "like", value: `%${searchTerm}%` },
          {
            field: "designation_de",
            op: "like",
            value: `%${searchTerm.toLowerCase()}%`,
          },
          {
            field: "designation_de",
            op: "like",
            value: `%${searchTerm.toUpperCase()}%`,
          },
          {
            field: "designation_de",
            op: "like",
            value: `%${capitalize(searchTerm)}%`,
          },
        ],
      };
    }

    loadAnatomyOrgans(
      keycloak,
      filter,
      undefined,
      undefined,
      pageSize,
      page
    ).then((data) => {
      setBodyParts(data?.anatomy);
      setBodyPartsAvailable(data?.total_count);
    });
  };

  useEffect(loadFromApi, [pageNr]);
  useEffect(() => loadFromApi(true), [searchTerm]);

  return (
    <div className="d-flex flex-column">
      <div
        id="filterbar"
        className="d-flex align-items-center justify-content-between"
      >
        <div className="d-flex flex-column justify-content-center">
          <input
            type="text"
            placeholder="i.e. Hand"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <p className="small text-muted m-0">Search Bodypart</p>
        </div>
        <Button className="p-2" onClick={() => loadFromApi(true)}>
          <i className="fas fa-redo fa-sm" />
        </Button>
      </div>
      <div
        id="bodypartslist"
        className="d-flex flex-column mt-3"
        style={{ maxHeight: listHeight, overflowY: "auto" }}
      >
        {bodyParts?.map((bpart) => (
          <div
            key={`bpartkey${bpart.id}`}
            onClick={() => {
              handleSelect(bpart);
            }}
            className={`d-flex p-2 border rounded mb-1 w-100 bpart-selectable-row ${
              selected
                ? selected.findIndex((b) => b.id === bpart.id) > -1 &&
                  "selected"
                : selectedBodyParts.findIndex((b) => b.id === bpart.id) > -1 &&
                  "selected"
            }`}
          >
            {bpart.designation_de ? bpart.designation_de : "..."}
          </div>
        ))}
      </div>
      <div id="pagination">
        <div className="d-flex justify-content-between mt-2">
          <div>
            <Button
              className="mr-1"
              disabled={pageNr === 1}
              onClick={() => setPageNr(1)}
            >
              <i className="fas fa-angle-double-left fa-sm" />
            </Button>
            <Button
              disabled={pageNr === 1}
              onClick={() => setPageNr(pageNr - 1)}
            >
              <i className="fas fa-angle-left fa-sm" />
            </Button>
          </div>
          <div className="border rounded p-1 bg-secondary text-white">
            {pageNr} / {Math.ceil(bodyPartsAvailable / pageSize)}
          </div>
          <div>
            <Button
              className="mr-1"
              disabled={bodyPartsAvailable < pageSize * pageNr}
              onClick={() => setPageNr(pageNr + 1)}
            >
              <i className="fas fa-angle-right fa-sm" />
            </Button>
            <Button
              disabled={
                bodyPartsAvailable < pageSize * pageNr ||
                pageNr === Math.ceil(bodyPartsAvailable / pageSize)
              }
              onClick={() =>
                setPageNr(Math.ceil(bodyPartsAvailable / pageSize))
              }
            >
              <i className="fas fa-angle-double-right fa-sm" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BodyPartsList;
