import Controller from "../Common/Controller";

import FrontView from "./views/FrontView";

import "./styles/Controller.scss";

// TODO: SVG IS NOT CORRECT NEED TO BE REPLACED

function HandRightController() {
  const availableViews = [FrontView];
  return <Controller availableViews={availableViews} />;
}

export default HandRightController;
