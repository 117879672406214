import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const LegLeft = map.LegLeft;

function SideView({ selected, bpartSetter }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206.06 833.29">
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="foot">
          <path
            id="heel"
            class="lleg-cls-2"
            d="M156.14,762.41c1.06,9.42,7.15,14.68,13.22,19.97,7.57-.89,11.18,1.63,10.12,8.16,2.47,8.46,1.62,13.72-1.41,16.87-1.2,7.44-4.78,9.92-9.84,9.28-6.75,1.01-9.43-3.04-10.12-9.56-7.52-2.54-17.81-.12-28.97-.56-4.45,3.45-8.21,3.77-11.25,.84,.48,8.77-3.7,11.63-12.66,8.44-7.97-.48-15.26-.76-21.94-.84-4,.96-7.55,2.27-10.69,3.94-8.82,1.93-16.81,2.57-22.78,0-2.18,1.33-3.99,1.87-5.34,1.41-3.85,1.27-7.28,2-9.28,.84-4.56,1.45-7.46,.52-9.28-1.97-3.38,.28-3.44,.85-4.78-1.97-2.46,.16-3.4-.85-3.09-2.81-5.06-1.69-5.89-3.66-3.94-4.78,1.1-.54,3.99-.78,7.88-.84l3.09-1.69c1.28-1.34,4.8-1.49,9.28-1.12-.35-.48-.5-1.05,1.41-2.53,1.3-.84,3.36-1.12,6.19-.84,1.9-1.47,4.91-1.95,8.44-1.97,2.78-3.32,7.63-4.97,14.06-5.34,4.08-1.86,12.12-4.78,20.81-7.87,2.5-4.31,8.24-6.61,15.19-8.16,1.19-1.08,3.8-3.01,7.59-5.06,1.7-.78,3.88-1.14,6.47-1.12,3.19-3.17,7.67-3.49,12.09-3.94-1.17-2.5-.92-4.94,1.41-7.31,4.23-3.9,8.45-6.07,12.66-6.75-3.55,6.99-5.63,13.91-2.25,20.53,2.66,7.92,5.72,11.35,10.13-.84l1.97-12.37c1.5,1.88,3.37,1.88,5.62,0Z"
          />
          <path
            id="toe_25"
            data-name="toe 25"
            class="lleg-cls-2"
            d="M127.18,803.76c-.64-4.76-.75-8.55-.56-11.81-1.34-5.58-3.85-7.87-7.87-5.91l-8.16,4.5-3.38,2.81c-3.47,2.79-4.59,4.72-2.25,5.34l11.53,8.44c7.37,4.6,9.79,1.58,10.69-3.37Z"
          />
          <path
            id="ankle"
            class="lleg-cls-2"
            d="M114.52,773.1c1.62,.67,5.92,3.3,8.72,7.88,1.86,.17,3.58,.67,6.47-1.69,5.77-2.01,7-6.99,7.59-12.37"
          />
          <path
            id="toe_24"
            data-name="toe 24"
            class="lleg-cls-2"
            d="M123.24,780.97c-1.81,3.13-4.92,4.89-9.28,5.34-2.4,.03-4.74-1.31-7.03-3.66l-3.09-6.19"
          />
          <path
            id="toe_23"
            data-name="toe 23"
            class="lleg-cls-2"
            d="M106.93,782.66c-3.26,1.79-7.66,4.41-9.84,8.44,1.08,4.69,3.3,6.73,6.75,5.91"
          />
          <path
            id="toe_22"
            data-name="toe 22"
            class="lleg-cls-2"
            d="M97.08,791.1c-2.26,1.14-4.73-1.72-5.91-3.66,1.69-2.57,5.38-5.35,9.28-8.16"
          />
          <path
            id="toe_21"
            data-name="toe 21"
            class="lleg-cls-2"
            d="M91.18,787.44c-3.35,1.74-4.94,1.26-5.91,0"
          />
          <path
            id="toe_20"
            data-name="toe 20"
            class="lleg-cls-2"
            d="M66.71,819.79c-4.03-1.99-3.23-4.25,1.69-6.75,4.54,.64,8.66,.37,12.66-1.12,4.44-4.3,12-5.17,19.69-5.91l9.84-.84c6.57,.41,9.53,5.24,5.91,9.28"
          />
          <path
            id="toe_19"
            data-name="toe 19"
            class="lleg-cls-2"
            d="M65.58,814.44c-7.75-.66-8.86-2.44-1.69-5.63,9.61-.93,19.18-2.82,28.69-5.91l9.56-3.94c3.75,.35,4.04,3.97,3.66,6.19"
          />
          <path
            id="toe_18"
            data-name="toe 18"
            class="lleg-cls-2"
            d="M61.08,809.66c-6.29,.97-7.77-.37-2.25-5.06,2.96-.51,7.43-1.87,13.5-1.69,7.6-4.17,14.88-6.55,22.5-9.84,4.17,2.09,6.43,4.03,4.22,5.63"
          />
          <path
            id="toe_17"
            data-name="toe 17"
            class="lleg-cls-2"
            d="M56.86,806.29c-3.3,.02-3.52-2.75-1.12-5.06l10.97-1.97c4.89-.35,13.28-5.24,21.66-10.12,3.52-.75,4.87,.34,3.94,3.38"
          />
          <path
            id="toe_16"
            data-name="toe 16"
            class="lleg-cls-2"
            d="M55.18,802.07c-3.06,1.23-4.83,.95-3.66-2.81"
          />
          <path
            id="toe_15"
            data-name="toe 15"
            class="lleg-cls-2"
            d="M49.83,818.94c-4.77-1.44-.96-2.88,2.53-3.94,1.88-.27,4.5-1.12,9.84-.28,2.5-.73,3.43,.26,.84,5.06"
          />
          <path
            id="toe_14"
            data-name="toe 14"
            class="lleg-cls-2"
            d="M49.55,815.29c-2.42,1.18-3.04-.14-.56-3.66,.91-1.19,2.75-1.91,5.91-1.97,4.09,.29,4.45,2.1,3.37,4.5"
          />
          <path
            id="toe_13"
            data-name="toe 13"
            class="lleg-cls-2"
            d="M48.99,811.63c-2.58,.83-3.89-1.69-2.25-3.94l5.34-.84c4.08-.82,3.88,.38,1.69,2.53"
          />
          <path
            id="toe_12"
            data-name="toe 12"
            class="lleg-cls-2"
            d="M46.74,807.69c-2.35-.6-4.75-1.47-1.97-3.09l5.91-.84c4.17-1.84,3.09,1.13,1.41,3.09"
          />
          <path
            id="toe_11"
            data-name="toe 11"
            class="lleg-cls-2"
            d="M44.21,805.44c-3.19,.58-4.67,.1-2.25-2.81"
          />
          <path
            id="toe_10"
            data-name="toe 10"
            class="lleg-cls-2"
            d="M41.11,805.72l-5.34,.56c-2.87,2.06-1.83,3.22,1.12,3.94l8.44-1.41-4.22-3.09Z"
          />
          <path
            id="toe_9"
            data-name="toe 9"
            class="lleg-cls-2"
            d="M43.08,819.22c-1.41-.56-2.93-1.43-.56-3.09h4.78s.28,3.09,.28,3.09c-2.3,1.37-3.09,.56-4.5,0Z"
          />
          <path
            id="toe_8"
            data-name="toe 8"
            class="lleg-cls-2"
            d="M46.74,811.07l-5.34,1.69c-3.28,1.84-4.04,3.18,1.12,3.38"
          />
          <path
            id="toe_7"
            data-name="toe 7"
            class="lleg-cls-2"
            d="M41.39,812.76c-.75,.16-1.4,.25-1.97,.29-2.85,.21-3.44-.97-2.53-2.82-4.48,.62-8.46,1.53-11.25,3.09"
          />
          <path
            id="toe_6"
            data-name="toe 6"
            class="lleg-cls-2"
            d="M25.93,819.22l1.69-3.52c.5-1.33,1.68-2.78-3.09-1.13l-3.38,2.67"
          />
          <path
            id="toe_5"
            data-name="toe 5"
            class="lleg-cls-2"
            d="M35.21,821.19c.32-2-.62-3.82-2.67-5.48-1.23-1.53-2.88-1.47-4.92,0"
          />
          <path
            id="toe_4"
            data-name="toe 4"
            class="lleg-cls-2"
            d="M24.52,814.58c1.72-2.35,.73-3.96-3.23-4.92"
          />
          <path
            id="toe_3"
            data-name="toe 3"
            class="lleg-cls-2"
            d="M34.93,807.97c-4.55-.21-9.1,.24-13.64,1.69-1,2.6-1.87,3.73-3.23,4.78"
          />
          <path
            id="toe_2"
            data-name="toe 2"
            class="lleg-cls-2"
            d="M32.53,815.71c.86-1.41,3.44-2.2,6.89-2.66"
          />
          <path
            id="toe_1"
            data-name="toe 1"
            class="lleg-cls-2"
            d="M41.39,815.85c-2.36,1.05-4.78,2.13-5.91,2.53"
          />
        </g>
        <g id="lower_leg" data-name="lower leg">
          <path
            id="lower_leg_6"
            data-name="lower leg 6"
            class="lleg-cls-2"
            d="M158.39,495.22c.36-10.04-2.33-14.59-9.56-10.97-9.01,3.71-17.69,6.62-23.91,3.66l-7.87-3.09c-5.16-.85-6.77,2.25-5.63,8.44l.28,4.5c-3.67,1.19-5.24,4.08-3.94,9.28-7.45,5.09-6.11,10.8,.28,16.88,.15,32.76,.26,65.63,7.03,82.41,7.5,47.57,13.58,93.88,11.53,133.03l-2.25,18.28c-.97,3.14-.34,5.18,5.06,3.94,1.74-3.71,5.88-5.56,11.25-6.47,1.12-38.7,2.39-73.95,4.22-96.19-6.26-34.03-7.15-76.15-5.91-121.5,2.87-2.37,5.07-5.62,5.91-10.69-7.02-9.27-8.53-17.01,1.13-21.66,1-6.85,5.78-9.39,12.38-9.84Z"
          />
          <path
            id="lower_leg_5"
            data-name="lower leg 5"
            class="lleg-cls-2"
            d="M148.83,484.26c.16-2.61-1.21-3.77-3.94-3.66-4.45,2.3-9.27,2.13-14.34,.28-3.93,1.02-7.78,.77-11.53-.84-.17,1.69-.18,3.27,.28,4.5,7.36,5.69,17.84,4.11,29.53-.28Z"
          />
          <path
            id="lower_leg_4"
            data-name="lower leg 4"
            class="lleg-cls-2"
            d="M160.64,504.22c-1.61-12.21-9.16-11.33-14.63,.84-8.01,4.46-8.82,11.52-1.13,21.66,4.91,43.01,3.35,86.56,.56,130.22l-4.78,98.16c-3.65,8.79-5.05,16.08-2.25,20.53,2.8,9.29,5.81,9.55,9,2.25,2.72-5.74,3.41-12.69,1.69-21.09l2.81-24.75c6.57-35.82,8.81-71.63,8.44-107.44-5.16-43.98-5.44-84.38,.28-120.37Z"
          />
          <path
            id="lower_leg_3"
            data-name="lower leg 3"
            class="lleg-cls-2"
            d="M152.49,733.72c3.28,10.76,6.86,21.84,4.22,26.16-1.99,2.31-4.11,2.82-6.47,.28"
          />
          <path
            id="lower_leg_2"
            data-name="lower leg 2"
            class="lleg-cls-2"
            d="M137.86,512.1c3.17-4.18,3.11-7.36-.75-9.38-7.19,2.11-12.06,7.33-15.38,14.62"
          />
          <path
            id="lower_leg_1"
            data-name="lower leg 1"
            class="lleg-cls-2"
            d="M134.49,526.72c-10.42,42.37-.25,103.14,9.37,151.5"
          />
        </g>
        <g id="upper_leg" data-name="upper leg">
          <path
            id="upper_leg_4"
            data-name="upper leg 4"
            class="lleg-cls-2"
            d="M102.14,131.29c.3,1.43-.7,2.19-2.53,2.53-7.72,.46-12.16,3.6-12.09,10.41"
          />
          <path
            id="upper_leg_3"
            data-name="upper leg 3"
            class="lleg-cls-2"
            d="M88.93,123.41c-6.05,1.88-10.09,5.18-9.84,11.53"
          />
          <path
            id="upper_leg_2"
            data-name="upper leg 2"
            class="lleg-cls-2"
            d="M121.27,194.01l1.13-13.78c3.36-31.47-1.84-49.89-17.72-52.03-2.91-11.77-10.64-16.87-25.31-12.38-12.26,10.18-11.64,19.84-4.78,29.25l7.31,6.47c2.71-2.4,6.47-1.12,5.91,2.53s2.81,3.09,2.81,3.09c-1.64,8.67,2.2,19.09,7.03,29.81-4.37,45.21-8.09,88.65-5.91,116.16,2.83,52.17,7.28,99.88,15.47,137.25-1.75,5.31-1.26,10.78,0,16.31-3.04,5.63-3.18,9.82,.56,12.09,2.81,10,11.23,13,22.78,12.09,13.78,4.4,19.98-3.05,23.91-14.06,3.17-8.58,.3-14.02-5.91-17.72-2.35,3.19-5.08,2.07-7.88,.28-22.02-58.29-30.4-109.3-25.59-153.28,.15-35.34,2.15-69.41,6.19-102.09Z"
          />
          <path
            id="knee"
            class="lleg-cls-2"
            d="M99.05,445.16l1.41,29.53c.45,5.2-.67,7.27-4.5,3.94-4.6-2.84-8.86-5.9-7.31-12.66-3.44-5.55-2.06-10.78,3.38-15.75,2.31-5.87,4.66-6.71,7.03-5.06Z"
          />
          <path
            id="upper_leg_1"
            data-name="upper leg 1"
            class="lleg-cls-2"
            d="M135.99,476.1c8.07-3.29,9.5-10.11,7.5-18.75-5-1.95-9.29-6.66-12.75-14.63-6.67,.99-12.09,3.21-15,7.87-5.42,9.59-3.88,17.17,4.12,22.88,4.58,6.03,10.15,5.6,16.12,2.63Z"
          />
        </g>
        <g id="hip">
          <path
            id="hip_12"
            data-name="hip 12"
            class="lleg-cls-2"
            d="M148.55,71.38c.82-8.45-.61-15-7.59-16.88-12.77-21.5-26.7-36.24-43.31-35.44-5.14-.83-9.92-.7-14.06,0-9.65,4.98-18.4,10.28-21.09,17.72-10.07,5.53-20.89,10.56-23.34,21.09-1.6,2.54-2.63,6.01-3.09,10.41-1.03,10.9,3.77,14.26,13.5,11.25,6.9,4.24,10.99,9.88,10.69,17.72-1.12,5,1.47,7.11,5.06,8.44,5.3,3.77,4.32,5.84,1.12,7.31-6.06,3.04-9.32,7.88-7.03,16.31-2.62,5.99-6.48,9.8-12.66,9.56-4.83,3.86-5.01,7.7,1.97,11.53h4.22c1.84,7.02,4.9,12.7,9.56,16.59,7.55,4.51,15.45,11.28,23.62,19.69,3.91,2.86,7.76,3.04,11.53,.28-4.02-11.35-8.7-23.26-7.03-29.81-2.35,.66-3.36-.27-2.81-3.09-.17-3.6-1.37-4.17-5.91-2.53-8.01-4.01-12.8-9.82-11.81-18.84,.37-11.73,6.47-18.27,18.84-19.12,9.91,.5,14.42,6.07,15.75,14.63,6.09-1.2,11.18,2.95,14.91,14.34,5.25,12.98,5.93,25.52,2.81,37.69,.33,12.29,3.18,11.64,7.03,5.91,8.35-8.13,12.93-16.82,3.38-27.56-1.97-.49-3.32-2.47-3.94-6.19,.46-1.16,.74-3.28,.84-6.47,6.54-2.98,6.86-6.61,.28-10.97l-6.75-6.47c-1.24-7.35-4.62-13.92-11.81-19.13,2.28-17.49,9.16-19.28,18-14.34,6.3,9.09,10.7,6.32,13.5-6.47-3.04-8.38-.76-13.88,5.63-17.16Z"
          />
          <path
            id="hip_11"
            data-name="hip 11"
            class="lleg-cls-2"
            d="M150.52,148.44l1.69-21.09-.56-4.5c.34-6.49-1.18-12.68-6.19-18.28-3.72-.26-6.89-1.08-5.34-6.75l2.81-9.28c-3.08-8.04-.28-13.33,5.63-17.16l3.09,5.63c7.59,2.7,11.54,7.94,9,17.72,4.32,9.37,5.89,16.93,.56,19.97l-.56,10.12c1.19-2.99,1.84-3.25,1.69,.56l-2.81,5.06-.84,8.72c-2.29,6.06-5,9.63-8.16,9.28Z"
          />
          <path
            id="hip_10"
            data-name="hip 10"
            class="lleg-cls-2"
            d="M147.71,80.94c5.88,1.8,7.91,4.27,5.06,7.59-1.92,4.5,.6,5.71,3.37,6.75,2.91,5.08,3.37,8.64,1.41,10.69-2.04,1.75-1.84,3.31-.56,4.78"
          />
          <path
            id="hip_9"
            data-name="hip 9"
            class="lleg-cls-2"
            d="M141.52,73.63c-5.69-2.03-9.41-6.29-9-15.19-1.21-8.1-4.55-15.81-11.81-22.78-3.19-.94-6-3-8.44-6.19"
          />
          <path
            id="hip_8"
            data-name="hip 8"
            class="lleg-cls-2"
            d="M102.14,25.54c-11.03-1.28-21.24-.25-26.44,14.91-14.26,4.41-29.47,8.02-26.44,27.28-.62,7.4-4.03,10.85-10.41,10.12"
          />
          <path
            id="hip_7"
            data-name="hip 7"
            class="lleg-cls-2"
            d="M89.77,93.6l-8.72-20.25c-4.75-10.93-4.97-20.38-2.81-28.69"
          />
          <path
            id="hip_6"
            data-name="hip 6"
            class="lleg-cls-2"
            d="M110.3,122.57c-4.45-10-10.43-16.22-20.25-12.94-6.43-1.68-12.33-1.32-16.31,4.5-11.49,9.26-13.02,19.12-5.63,29.53"
          />
          <path
            id="hip_5"
            data-name="hip 5"
            class="lleg-cls-2"
            d="M90.05,169.26c-10.37-.66-17.18-6.83-19.12-20.53-6.64,1.35-12.61,1.88-18,1.69"
          />
          <path
            id="hip_4"
            data-name="hip 4"
            class="lleg-cls-2"
            d="M155.58,144.79c-2.11,.37-3.66,.06-4.5-1.13"
          />
          <path
            id="hip_3"
            data-name="hip 3"
            class="lleg-cls-2"
            d="M157.55,141.69c-4.11,1.15-4.98-.79-5.91-1.97"
          />
          <path
            id="hip_2"
            data-name="hip 2"
            class="lleg-cls-2"
            d="M158.39,137.47c-2.48,.07-4.74-.43-6.47-2.25"
          />
          <path
            id="hip_1"
            data-name="hip 1"
            class="lleg-cls-2"
            d="M160.64,124.82c-3.72,2.52-6.73,1.89-9-1.97"
          />
        </g>
        <g id="outlines">
          <path
            id="outline_2"
            data-name="outline 2"
            class="lleg-cls-2"
            d="M144.33,21.6l5.06,15.19c16.1,13.08,25.03,26.93,28.69,41.34,40.29,72.05,18.96,98.75-9.28,129.94,1.45,15.78,1.78,30.93-1.69,43.87,2.47,42.87,2.78,82.02-5.06,107.16-.56,14.35-1.12,28.73-1.69,37.12,10.58,27.75,18,55.3,22.78,82.69,21.97,52.73,30.1,103.13,8.44,148.5-2.86,32.47-5.95,63.85-12.66,78.47-6,27.55-6.17,53.95,7.59,77.63,1.55,16.7,10.58,35.84-16.88,43.03-9.1-2.19-18.28-3.93-27.84-3.38l-75.09,5.06c-6.15-.09-10.89-1.5-14.16-3-6.64,1.35-12.61,1.58-17.25-.38-5.19,.68-10.03,1.08-12.56-.38-4.4-.96-7.32-2.81-8.62-5.63-3.79-.78-5.96-2.44-6.37-5.06-4.43-2.53-3.5-5.2-2.25-7.88,2.41-1.11,5.78-1.18,9.94-1.12,4.25-.02,8.05-.87,11.25-2.81,8.03-3.95,21.15-8.35,34.31-12.75,10.07-4.99,30.75-17.89,50.63-30.19,4.33-10.76,5.42-26.34,5.81-42.94l-2.06-57.94c-12.9-40.86-18-82.78-19.12-125.25-2.01-9.41-2.62-17.71-1.87-24.94-5.41-9.7-7.33-18.7-6.75-27.19-9.5-12.14-12.03-25.63-11.25-39.75l-30.75-122.81c-8.43-28.9-12.16-72.43-15-118.69-1.08-21.12-1.28-39.73,1.86-48.74l.58-15.19"
          />
          <path
            id="outline_1"
            data-name="outline 1"
            class="lleg-cls-2"
            d="M6.05,46.54c3.8,12.5,5.73,24.76,4.31,36.56,1.89,24.97,9.71,47.03,21.94,66.94"
          />
        </g>
      </g>
      <g id="hip_joint" data-name="hip joint">
        <path
          id="hip_jpint_overlay"
          data-name="hip jpint overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, LegLeft.id, LegLeft.children.hipJoint),
            });
          }}
          class={`lleg-cls-1 ${isSelected(
            selected,
            LegLeft.children.hipJoint
          )}`}
          d="M122.49,185.85c1.5,4.66,3.73,5.23,6.94,.28,8.27-8.41,11.53-17.37,3.38-27.56-2.63-.18-3.82-2.41-3.94-6.19l.84-6.47c6.61-2.93,6.07-6.66,.28-10.97-2.15-1.39-4.39-3.51-6.75-6.47-.14-6.87-4.21-13.22-11.81-19.13-6.36-2.02-12.67-1.89-18.94-.38-10.45-1.37-15.94-.06-16.13,4.12-4.28,2.17-9.77,7.19-16.97,16.22-2.81,6.01-7.27,8.72-12.66,9.56-4.7,3.86-3.94,7.7,1.97,11.53,2.4,.18,7.79,7.64,13.78,16.59,8.9,4.75,16.75,11.37,23.62,19.69,3.78,2.64,7.64,2.08,11.53,.28,6.84-4.57,15.37-4.2,24.84-1.12Z"
        />
      </g>
      <g id="upper_leg_with_hip_joint" data-name="upper leg with hip joint">
        <path
          id="upper_leg_with_hip_joint_overlay"
          data-name="upper leg with hip joint overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                LegLeft.id,
                LegLeft.children.upperLegWithHipJoint
              ),
            });
          }}
          class={`lleg-cls-1 ${isSelected(
            selected,
            LegLeft.children.upperLegWithHipJoint
          )}`}
          d="M121.74,186.22c-8.63-2.8-16.55-2.62-23.63,1.12-6.33,54.96-10.98,107.68-4.13,145.12l19.87,2.25c-1.04-43.29,2.97-95.22,7.88-148.5Z"
        />
      </g>
      <g id="upper_leg_with_knee_joint" data-name="upper leg with knee joint">
        <path
          id="upper_leg_with_knee_joint_overlay"
          data-name="upper leg with knee joint overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                LegLeft.id,
                LegLeft.children.upperLegWithKneeJoint
              ),
            });
          }}
          class={`lleg-cls-1 ${isSelected(
            selected,
            LegLeft.children.upperLegWithKneeJoint
          )}`}
          d="M114.24,336.6l-20.62-2.25c1.34,37.61,5.94,73.41,13.5,105.75l27.38-8.25c-11.28-29.88-17.09-62.02-20.25-95.25Z"
        />
      </g>
      <g id="knee-2" data-name="knee">
        <path
          id="knee_overlay"
          data-name="knee overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, LegLeft.id, LegLeft.children.knee),
            });
          }}
          class={`lleg-cls-1 ${isSelected(selected, LegLeft.children.knee)}`}
          d="M135.61,434.1c-8.06,1.27-17.86,4.17-28.13,7.5-1.55,5.34-1.45,10.32,0,15-2.31,5.74-2.88,10.24,.37,12,3.06,7.5,6.88,10.65,11.25,10.5l-.38,6c-4.74-1.6-6.73,.55-7.13,4.87v7.88s37.12,3,37.12,3c1.78-3.89,4.86-5.96,9.38-6,.24-9.49-2.92-12.58-8.62-10.88-.2-2.01-1.38-3.43-3.75-4.13,8.04-7.3,10.51-15.47,8.25-24.37l-6-7.13c-2.29,3.62-4.67,3.38-7.12,0l-5.25-14.25Z"
        />
      </g>
      <g id="partella">
        <path
          id="partella_overlay"
          data-name="partella overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, LegLeft.id, LegLeft.children.partella),
            });
          }}
          class={`lleg-cls-1 ${isSelected(
            selected,
            LegLeft.children.partella
          )}`}
          d="M100.29,437.54v7.81s1.18,34.59,1.18,34.59l.39,7.44c-1.59,5.13-7.6,2.99-12.94-6.69-8.61-9.63-13.37-22.42-12.55-39.79,7.11-21.78,15.24-19.2,23.93-3.35Z"
        />
      </g>
      <g id="lower_leg_with_knee_joint" data-name="lower leg with knee joint">
        <path
          id="lower_leg_with_knee_joint_overlay"
          data-name="lower leg with knee joint overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                LegLeft.id,
                LegLeft.children.lowerLegWithKneeJoint
              ),
            });
          }}
          class={`lleg-cls-1 ${isSelected(
            selected,
            LegLeft.children.lowerLegWithKneeJoint
          )}`}
          d="M160.74,503.47c-1.73-8.86-5.25-10.17-10.87-2.63l-37.87-2.63c-4.13,.38-4.99,3.59-3.75,8.62-6.88,5.01-6.99,11.01-.38,18-.21,32.23,.62,62.46,7.12,81.75l3.37,23.25c11.63-3.65,25.83-3.26,41.63-.37-6.47-73.68-3.91-89.69,.75-126Z"
        />
      </g>
      <g id="lower_leg_with_ankle" data-name="lower leg with ankle">
        <path
          id="lower_leg_with_ankle_overlay"
          data-name="lower leg with ankle overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                LegLeft.id,
                LegLeft.children.ankleWithLowerLeg
              ),
            });
          }}
          class={`lleg-cls-1 ${isSelected(
            selected,
            LegLeft.children.ankleWithLowerLeg
          )}`}
          d="M160.74,630.22c-14.75-3.13-28.5-3-41.25,.37,5.27,32.92,7.26,67.71,7.5,103.5,6.61,4.2,15.39,2.92,25.13-.75,5.66-26.91,7.91-62.94,8.63-103.12Z"
        />
      </g>
      <g id="upper_ankle" data-name="upper ankle">
        <path
          id="upper_ankle_overlay"
          data-name="upper ankle overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, LegLeft.id, LegLeft.children.ankle),
            });
          }}
          class={`lleg-cls-1 ${isSelected(selected, LegLeft.children.ankle)}`}
          d="M152.86,733.35c-9.19,4.53-17.77,5.93-25.13,1.12l-10.88,1.87-4.88,22.12c-7.21,5.83-8.99,11.57-6.75,17.25-.52,7.88,3.57,9.24,8.25,9.75,4.13,1.62,7.29-.33,9.75-4.87,6.58,1.7,9.99-3,13.13-8.25,6.78,17.3,10.05,10.79,12.75,.38,5.07,7.17,12.09,9.44,19.87,9.75,8.12,2.38,11.49-1.64,12.38-9-7.02-11.35-7.46-26.83-6-43.5-8.7,3.24-16.92,5.62-22.5,3.37Z"
        />
      </g>
      <g id="foot-2" data-name="foot">
        <path
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, LegLeft.id, LegLeft.children.foot),
            });
          }}
          id="foot_overlay"
          data-name="foot overlay"
          class={`lleg-cls-1 ${isSelected(selected, LegLeft.children.foot)}`}
          d="M186.61,783.22c5.19,21.98,4.11,39.58-16.5,43.5-24.36-6.27-58.06-3.85-94.5,1.12-9.95,1-17.53-.16-23.25-3-6.74,2.12-12.1,1.88-16.5,0h-12.75c-3.9-1.03-6.84-2.84-8.62-5.63-3.16-1.27-5.83-2.87-7.87-4.88-3.29-3.8-2.66-6.76-.37-9.38,3.72,.93,9.03,.24,15-1.12,8.39-2,23.48-8.48,38.63-15,10.56-4.04,27.71-15.38,45.38-26.25-2.28,4.42-2.57,9.14-.38,14.25,1.98,12.21,9.7,9.77,18.37,4.88,5.89,.15,10.05-3.15,13.13-8.62,5.19,17.17,9.6,10.91,13.87,.75,5.24,8.94,11.9,9.17,18.75,8.25,6.59,3.01,10.79,.06,13.13-7.5l4.5,8.63Z"
        />
      </g>
    </svg>
  );
}

export default SideView;
