import style from "./style.scss";

function LinkerMittelfussDplLiegend1433(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 271.76 480">

<g id="foot">
  <g id="toes">
    <g id="big_toe" data-name="big toe">
      <path id="big_toe_1" data-name="big toe 1" class="mittelfuss_dp_links1433_cls_4" d="M209.51,4.99c-.93,3.19-1.94,6.36-2.98,9.53-6.07,21.43-3.66,30.8,10.71,23.57l30.62,6.87c16.69,2.41,22.32-5.09,15.09-24.02h.09v-.09h0c-1.61-4.93-3.04-9.91-4.25-14.94"/>
    </g>
    <g id="second_toe" data-name="second toe">
      <path id="second_toe_1" data-name="second toe 1" class="mittelfuss_dp_links1433_cls_3" d="M163.78.5c1.38,4.69,3.16,8.61,5.25,11.78,11.16,14.37,10,19.37-5.98,12.86-7.5-8.04-15.27-7.41-23.48,4.29-8.93,6.87-11.34,3.75-5.18-12.41,2.04-5,3.61-10.32,4.42-16.16"/>
    </g>
    <g id="third_toe" data-name="third toe">
      <path id="third_toe_1" data-name="third toe 1" class="mittelfuss_dp_links1433_cls_3" d="M110.75,19.38c1.96,4.31,4.32,8.01,7.13,11.02,6.25,15.89,1.07,28.66-7.14,13.3-6.25-7.14-11.43-4.2-15.89,4.73-12.14,6.07-14.46,1.07-5.54-16.25.49-3.11.88-6.25,1.11-9.46"/>
    </g>
    <g id="fourth_toe" data-name="fourth toe">
      <path id="fourth_toe_1" data-name="fourth toe 1" class="mittelfuss_dp_links1433_cls_3" d="M62.97,50.61c1.94,7.33,4.25,14.49,6.88,21.49,2.41,9.46,2.32,15.27-6.34,8.57-5.54-2.68-10.71-2.41-15.09,3.39-6.96,7.14-9.64,4.11-5.54-15.45.35-3.3.76-6.6,1.1-9.9"/>
    </g>
    <g id="little_toe" data-name="little toe">
      <path id="little_toe_1" data-name="little toe 1" class="mittelfuss_dp_links1433_cls_3" d="M22.88,68.97c.45,19.19,2.95,35.8,9.2,47.59,5.89,10.53,3.93,13.12-4.37,9.46-7.59-1.87-12.5-.36-13.93,5.54-4.73,1.79-7.32-1.34-5.98-13.3,2.94-14.03.21-28.37-7.3-43"/>
    </g>
  </g>
  <g id="metatarsus">
    <path id="metatarsus_1" data-name="metatarsus 1" class="mittelfuss_dp_links1433_cls_3" d="M267.86,88.7c-15.8,46.78-24.55,94.63-23.48,144.01,1.96,19.55-2.5,29.46-20.27,18.84-12.77-8.3-25.8-15.98-46.6-9.02-15.89-18.39-14.55-28.93-1.16-33.84,26.69-31.07,33.03-71.6,17.5-122.13-18.12-15.27-17.41-25.35,1.16-30.44,2.5-7.5,6.07-11.78,10.71-11.96,23.3-9.2,39.46,2.77,51.33,4.29,17.05-1.43,16.16,16.87,10.71,40.26h.1Z"/>
    <path id="metatarsus_2" data-name="metatarsus 2" class="mittelfuss_dp_links1433_cls_3" d="M169.83,34.87h0c.54,6.61,1.43,13.39,3.57,20.53,2.59,9.02,3.12,18.75-11.52,33.39-.62,54.37-1.43,110.53,3.57,135.43,3.66,10.45,9.55,17.41,11.52,21.43-5.09,7.86-7.95,16.96-5.98,28.75-16.52-.36-30.62,3.04-35.44,20.53-9.91-5.8-15.71-15.36-12.32-33.03-3.39-12.05,0-19.73,6.79-25.27,6.43-54.37,13.66-109.19,4.37-155.61-7.86-12.59-10.18-23.75-2.41-32.14-2.05-6.07-1.16-11.25,3.21-15.45,11.43-11.96,23.03-11.34,34.64,1.25v.18h0Z"/>
    <path id="metatarsus_3" data-name="metatarsus 3" class="mittelfuss_dp_links1433_cls_3" d="M114.84,52.28c7.5,19.82,8.93,33.21,1.16,36.43-1.25,59.82-3.21,124.45,1.16,148.74,3.84,15.62,5.62,29.55,2.41,39.46-12.05-4.2-23.03-5.45-26.69,15.89-10.89-6.16-15.8-19.28-5.98-49.73,5.45-45.35,10.18-89.01,8.39-117.4.89-16.52-2.32-30.62-10.36-42.05-3.66-21.25,3.04-29.64,11.96-35.18,5.62-12.59,11.7-7.23,17.94,3.84h0Z"/>
    <path id="metatarsus_4" data-name="metatarsus 4" class="mittelfuss_dp_links1433_cls_3" d="M74.31,108.79c6.96,41.42,10.09,82.85,7.14,124.27-1.52,26.87,1.61,50.98,10.71,71.6-16.07,2.68-27.59,10.36-32.23,25.71-3.75-3.75-6.61-10.09-7.95-20.53-4.91-11.61-2.68-21.78,1.16-31.69l6.79-66.42c1.34-32.85,2.14-64.99-7.59-79.73-6.87-5.8-10.98-13.12-9.2-24.02l2.41-18.84c5.27-9.64,11.87-12.05,19.91-5.98,8.75,8.66,12.77,17.23,8.75,25.71h0l.09-.09h.01Z"/>
    <path id="metatarsus_5" data-name="metatarsus 5" class="mittelfuss_dp_links1433_cls_3" d="M35.56,145.22h0c6.87,7.5,8.57,14.11,4.37,19.73l10,66.87.8,57.41c-3.21,13.75,1.52,28.39,8.39,43.3-7.05,15.98-12.14,30.8-10.71,42.05-2.05,13.12-6.43,12.59-12.32,2.59-17.23-8.12-24.64-25.27-15.53-57.85l5.98-80.98c1.61-24.11-.89-46.42-10.71-65.62-14.11-10.62-10.45-17.77-4.02-24.46-4.2-9.91-4.64-16.07,2.77-14.55,8.21-11.7,21.07-9.02,21.07,11.61h-.09v-.09h0Z"/>
    <path id="metatarsus_6" data-name="metatarsus 6" class="mittelfuss_dp_links1433_cls_3" d="M205.55,44.15c.54,3.3,2.14,5.36,4.82,5.98,17.77,9.37,34.91,11.7,50.98,4.29,5-1.61,5-3.04,0-4.29"/>
    <path id="metatarsus_7" data-name="metatarsus 7" class="mittelfuss_dp_links1433_cls_3" d="M132.69,36.39l10.36,5.54c9.11,4.64,17.05.09,24.73-5.98"/>
    <path id="metatarsus_8" data-name="metatarsus 8" class="mittelfuss_dp_links1433_cls_3" d="M87.43,57.81c0,4.11,2.41,6.43,6.79,7.32,1.87,4.64,5.8,6.34,12.32,3.84,2.05-6.87,5.18-9.64,9.55-8.12"/>
    <path id="metatarsus_9" data-name="metatarsus 9" class="mittelfuss_dp_links1433_cls_3" d="M45.2,99.86c4.64,3.48,9.64,4.29,15.53.45,2.05-6.7,5.8-9.28,10.71-9.02"/>
    <path id="metatarsus_10" data-name="metatarsus 10" class="mittelfuss_dp_links1433_cls_3" d="M16.1,136.29c-.45,11.96,6.43,13.75,16.34,11.16"/>
  </g>
  <g id="tarsus">
    <path id="tarsus_5" data-name="tarsus 5" class="mittelfuss_dp_links1433_cls_3" d="M218.31,343.77h0l-21.07-9.46c2.77-21.34-7.05-41.6-23.12-61.24-5.09-40.53,20-34.55,49.82-21.43,10.53,13.12,17.05,31.6,16.34,59.55,2.5,27.77-1.87,43.75-21.87,32.59h-.1Z"/>
    <path id="tarsus_4" data-name="tarsus 4" class="mittelfuss_dp_links1433_cls_3" d="M150.28,344.93c-9.02-17.14-14.11-34.55-11.52-52.32,4.64-15.18,17.5-20,37.05-16.25,17.86,25.27,22.14,44.46,15.09,58.74-16.16-3.75-30.27-1.87-40.62,9.82h0Z"/>
    <path id="tarsus_3" data-name="tarsus 3" class="mittelfuss_dp_links1433_cls_3" d="M145.55,345.47c-9.37,4.11-18.39,8.93-20.27,26.16-7.23-.71-13.66-3.57-19.11-9.46-12.59-10.53-15.09-36.6-13.12-69.46,6.87-20.36,18.84-22.5,37.41-1.25,1.52,18.93,6.87,36.87,15.09,54.01h0Z"/>
    <path id="tarsus_2" data-name="tarsus 2" class="mittelfuss_dp_links1433_cls_3" d="M75.74,414.84h0c-8.48-10.09-16.16-21.43-22.68-34.73-2.5-13.12-2.5-25.8,6.79-36.87,5.98-26.43,17.68-32.41,32.23-28.3-3.39,29.37,4.73,51.42,33.84,59.99.45,9.02,5.09,18.57,14.37,28.75-10.71,15.36-23.48,28.39-64.46,11.16,0,0-.09,0-.09,0Z"/>
    <path id="tarsus_1" data-name="tarsus 1" class="mittelfuss_dp_links1433_cls_3" d="M237.15,422.6h0c-29.28-36.96-59.99-50.8-93.56-19.73-17.05-25.53-23.12-47.32,8.75-55.71,16.52-17.5,40.98-15.53,70.44-.89,13.48,1.87,19.55,17.14,21.87,39.01,6.96,20.89,3.39,32.41-7.59,37.32h.09Z"/>
  </g>
  <g id="ankle_bone" data-name="ankle bone">
    <path id="ankle_bone_3" data-name="ankle bone 3" class="mittelfuss_dp_links1433_cls_3" d="M139.57,456.44c7.95-5.27,10.09-17.23,7.95-34.28.71-35.44,23.75-37.14,52.14-30.89,24.02,10.71,39.91,35.98,36.6,64.73h.09v.09h0c-5.86,3.59-10.46,8.22-13.66,13.99"/>
    <path id="ankle_bone_2" data-name="ankle bone 2" class="mittelfuss_dp_links1433_cls_3" d="M108.5,475.28c8.12-4.91,19.02-11.52,31.07-18.84"/>
    <path id="ankle_bone_1" data-name="ankle bone 1" class="mittelfuss_dp_links1433_cls_3" d="M149.12,418.67c11.16-25.09,36.25-27.23,74.46-7.68"/>
  </g>
  <g id="heel_bone" data-name="heel bone">
    <path id="heel_bone_1" data-name="heel bone 1" class="mittelfuss_dp_links1433_cls_3" d="M64.92,479.5c2.68-8.67,6.17-16.83,10.46-24.49-7.59-15.36-7.32-27.85.45-37.59,37.94,18.39,56.96-.62,76.06-19.46-6.52,13.3-4.02,41.69-10.36,50.98-7.86,11.7-10.45,4.55-35.8,24.19"/>
  </g>
</g>
<g id="overlays">
  <g id="metatarsal_bone_overlay" data-name="metatarsal bone overlay" class="mittelfuss_dp_links1433_cls_1">
    <path id="metatarsal_bone_overlay_1" data-name="metatarsal bone overlay 1" class="MFK1433Fill" style= {{
            fill: props.colors.MFK1433Fill,
            stroke: props.colors.MFK1433Fill,
            opacity:props.colors.MFK1433Fill,
          }} d="M268.05,89.11c-15.8,46.78-24.55,94.63-23.48,144.01,1.96,19.55-2.5,29.46-20.27,18.84-12.77-8.3-25.8-15.98-46.6-9.02-15.89-18.39-14.55-28.93-1.16-33.84,26.69-31.07,33.03-71.6,17.5-122.13-18.12-15.27-17.41-25.35,1.16-30.44,2.5-7.5,6.07-11.78,10.71-11.96,23.3-9.2,39.46,2.77,51.33,4.29,17.05-1.43,16.16,16.87,10.71,40.26h.1Z"/>
    <path id="metatarsal_bone_overlay_2" data-name="metatarsal bone overlay 2" class="MFK1433Fill" style= {{
            fill: props.colors.MFK1433Fill,
            stroke: props.colors.MFK1433Fill,
            opacity:props.colors.MFK1433Fill,
          }} d="M170.02,35.28h0c.54,6.61,1.43,13.39,3.57,20.53,2.59,9.02,3.12,18.75-11.52,33.39-.62,54.37-1.43,110.53,3.57,135.43,3.66,10.45,9.55,17.41,11.52,21.43-5.09,7.86-7.95,16.96-5.98,28.75-16.52-.36-30.62,3.04-35.44,20.53-9.91-5.8-15.71-15.36-12.32-33.03-3.39-12.05,0-19.73,6.79-25.27,6.43-54.37,13.66-109.19,4.37-155.61-7.86-12.59-10.18-23.75-2.41-32.14-2.05-6.07-1.16-11.25,3.21-15.45,11.43-11.96,23.03-11.34,34.64,1.25v.18h0Z"/>
    <path id="metatarsal_bone_overlay_3" data-name="metatarsal bone overlay 3" class="MFK1433Fill" style= {{
            fill: props.colors.MFK1433Fill,
            stroke: props.colors.MFK1433Fill,
            opacity:props.colors.MFK1433Fill,
          }} d="M115.03,52.69c7.5,19.82,8.93,33.21,1.16,36.43-1.25,59.82-3.21,124.45,1.16,148.74,3.84,15.62,5.62,29.55,2.41,39.46-12.05-4.2-23.03-5.45-26.69,15.89-10.89-6.16-15.8-19.28-5.98-49.73,5.45-45.35,10.18-89.01,8.39-117.4.89-16.52-2.32-30.62-10.36-42.05-3.66-21.25,3.04-29.64,11.96-35.18,5.62-12.59,11.7-7.23,17.94,3.84h0Z"/>
    <path id="metatarsal_bone_overlay_4" data-name="metatarsal bone overlay 4" class="MFK1433Fill" style= {{
            fill: props.colors.MFK1433Fill,
            stroke: props.colors.MFK1433Fill,
            opacity:props.colors.MFK1433Fill,
          }} d="M74.5,109.2c6.96,41.42,10.09,82.85,7.14,124.27-1.52,26.87,1.61,50.98,10.71,71.6-16.07,2.68-27.59,10.36-32.23,25.71-3.75-3.75-6.61-10.09-7.95-20.53-4.91-11.61-2.68-21.78,1.16-31.69l6.79-66.42c1.34-32.85,2.14-64.99-7.59-79.73-6.87-5.8-10.98-13.12-9.2-24.02l2.41-18.84c5.27-9.64,11.87-12.05,19.91-5.98,8.75,8.66,12.77,17.23,8.75,25.71h0l.09-.09h.01Z"/>
    <path id="metatarsal_bone_overlay_5" data-name="metatarsal bone overlay 5" class="MFK1433Fill" style= {{
            fill: props.colors.MFK1433Fill,
            stroke: props.colors.MFK1433Fill,
            opacity:props.colors.MFK1433Fill,
          }} d="M35.75,145.63h0c6.87,7.5,8.57,14.11,4.37,19.73l10,66.87.8,57.41c-3.21,13.75,1.52,28.39,8.39,43.3-7.05,15.98-12.14,30.8-10.71,42.05-2.05,13.12-6.43,12.59-12.32,2.59-17.23-8.12-24.64-25.27-15.53-57.85l5.98-80.98c1.61-24.11-.89-46.42-10.71-65.62-14.11-10.62-10.45-17.77-4.02-24.46-4.2-9.91-4.64-16.07,2.77-14.55,8.21-11.7,21.07-9.02,21.07,11.61h-.09v-.09h0Z"/>
  </g>
</g>
</svg>
  );
}

export default LinkerMittelfussDplLiegend1433;
