import style from "./style.scss";

function SteissbeinLatLiegend1722(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 336.68 629.72">

<g id="BG">
  <path id="BG12" class="Coccyx_seitlich1722_cls4" d="M62.85,580.62c-.05-2.25,4.37-2.35,6.84-6.84,2.59-4.7,0-8.71,2.05-10.25,2.61-1.96,9.48,2.48,13.67,6.15,6.28,5.52,15.44,17.17,11.45,24.75-1.16,2.21-3.66,4.49-6.51,4.66-4.74.27-5.87-5.61-13.83-10.95-7.3-4.9-13.61-4.83-13.67-7.52h0Z"/>
  <path id="BG11" class="Coccyx_seitlich1722_cls4" d="M34.14,558.74c-2.07,2.93,1.27,10.74,6.84,14.35,3.59,2.33,5.63,1.31,12.37,4.86,3.65,1.92,3.95,2.7,5.4,2.65,5.47-.15,13.27-11.45,9.57-20.51-2.26-5.52-8.54-9.57-13.67-8.89-3.97.53-4.34,3.52-10.25,5.47-5.94,1.96-8.82.02-10.25,2.05v.02Z"/>
  <path id="BG10" class="Coccyx_seitlich1722_cls4" d="M10.22,519.1c-3.6,2.82,5,18.98,7.06,22.86,4.83,9.08,7.25,13.62,12.08,15.42,9.75,3.63,23-4.27,23.92-10.94.21-1.52-.25-2.71-.15-8.73.07-4.22.31-4.22.15-5.62-.5-4.36-3.46-9.98-7.52-10.94-4.21-1-5.85,3.89-11.62,5.47-10.89,2.97-20.5-10.2-23.92-7.52h0Z"/>
  <path id="BG9" class="Coccyx_seitlich1722_cls4" d="M6.95,430.16c3.17-1.22,6.12,6.52,14.58,8.2,2.67.53,5.45.37,6.68.3,6.18-.35,8.22-2.23,9.42-1.22,2.11,1.79-3.32,8.39-6.08,20.35-1.55,6.74-1.67,12.63-1.82,20.66-.13,6.7-.2,10.05.3,12.46,2.41,11.64,10.05,15.4,8.28,22.79-.18.74-1.01,3.87-3.72,5.77-5.2,3.64-13.05-.18-15.8-1.52-2-.97-7.77-3.78-10.94-10.03-2.25-4.44-1.58-7.44-1.82-14.89-.15-4.7-.36-1.68-3.34-24.61-1.17-9-1.99-16.05-.91-22.78.51-3.16,1.42-6.18,2.13-8.51,1.33-4.39,2.02-6.59,3.04-6.99v.02Z"/>
  <path id="BG8" class="Coccyx_seitlich1722_cls4" d="M23.66,343.88c-1.2.25-2.11,1.89-3.95,5.16-.83,1.49-1.93,3.74-2.73,6.68-.37,1.33-.7,2.9-.91,4.86-.62,5.59.15,10.23.3,11.24,1.11,7.27-1.84,9.01-4.19,28.01-1.19,9.66-.72,11.57-2.49,17.56-1.67,5.68-2.93,6.77-2.43,9.11,1.9,9.01,24.99,15.34,30.38,8.81,3.94-4.77-4.33-13.07-1.22-30.68.51-2.86,1.13-4.94,1.92-7.57,2.75-9.15,5.66-13.67,11.45-25.24,8.39-16.78,8.34-19.38,7.29-20.35-1.12-1.04-2.77.33-8.81,1.22-5.74.84-10.2,1.49-14.28-.3-6.15-2.71-7.27-9.16-10.33-8.51h0Z"/>
  <path id="BG7" class="Coccyx_seitlich1722_cls4" d="M52.22,411.93c6.42-.54,10.12-8.17,10.63-13.37.3-3.04-.76-2.55-1.52-9.11-.8-6.92.18-9.13-.91-16.41-.47-3.15-.64-2.65-1.22-6.08-1.64-9.84-.74-16.98-1.22-17.01-.35-.02-.34,3.88-2.3,9.22-.88,2.41-1.19,2.45-2.87,6.42-1.11,2.63-1.78,4.52-3.04,8.06-2.42,6.82-2.47,7.78-3.95,10.33-1.57,2.7-1.97,2.42-3.34,4.86-.96,1.71-3.36,6.01-3.04,10.33.44,5.92,6.1,13.32,12.76,12.76h.02Z"/>
  <path id="BG6" class="Coccyx_seitlich1722_cls4" d="M25.18,340.84c.23-1.39.98-1.66,3.04-5.77,1.19-2.38,1.88-4.16,2.73-6.38,5.29-13.72,7.9-24.3,7.9-24.3,6.03-24.42,1.65-43.65,7.29-45.27,3.89-1.11,5.66,8.11,18.53,13.97,8.05,3.67,12.08,5.51,17.92,6.08,3.12.3,6.39.23,7.29,2.13.97,2.05-1.32,5.43-3.34,7.29-2.75,2.53-4.45,1.75-7.59,4.25-1.93,1.54-2.18,2.54-4.86,6.38-3.24,4.63-4.24,5.13-6.99,8.81-.51.68-5.09,6.87-7.29,12.46-4.97,12.6,3.34,19.19-1.52,26.73-3.98,6.18-13.8,8.34-21.09,6.1-6.48-2-12.75-8.02-12.02-12.48h0Z"/>
  <path id="BG5" class="Coccyx_seitlich1722_cls4" d="M81.08,219.33c-2.02-.88-6.2,4.09-10.94,9.72-4.84,5.76-6.11,8.76-10.94,11.85-2.81,1.8-3.35,1.4-5.5,3.1-2.96,2.34-7.04,7.12-6.96,11.78.06,3.34,2.21,4.32,21.27,17.92,3.28,2.34,9.38,6.72,17.58,7.79,3.28.43,4.78.06,5.81-.8,2.84-2.38.09-6.84,2.43-13.06,2.05-5.44,5.57-5.79,5.16-8.51-.67-4.45-10.11-3.48-14.58-10.63-1.26-2.02-1.55-4.59-2.13-9.72-1.38-12.3.86-18.54-1.22-19.44h.02Z"/>
  <path id="BG4" class="Coccyx_seitlich1722_cls4" d="M115.41,181.05c-2.93-.58-4.89,4.18-11.85,12.46-11.56,13.76-17.41,14-20.35,23.39-.75,2.4-.97,6.16-1.39,13.67-.56,9.97.22,13.42,1.86,16.38.73,1.32,1.99,3.6,4.54,5.41,2.6,1.84,3.7,1.18,7.5,3.53,2.46,1.52,3.1,2.48,4.5,2.33,1.53-.17,2.33-1.49,3.95-3.34,1.79-2.05,4.08-3.61,6.08-5.47,5.36-4.99,15.9-10.95,19.14-12.76,15.78-8.8,23.22-10.88,22.78-13.06-.42-2.14-7.44.23-14.89-3.95-8.31-4.67-12.08-14.65-15.49-23.7-3.58-9.48-3.55-14.32-6.38-14.89h0Z"/>
  <path id="BG3" class="Coccyx_seitlich1722_cls4" d="M29.74,326.57c-3.67,2-7.83.45-8.2.3-2.23-.87-6.25-3.39-8.81-13.67-3.66-14.69-.42-29.51,2.13-39.8,6.32-25.52,10.19-41.16,21.57-61.06,3.24-5.66,7.73-11.98,16.71-24.61,13.98-19.66,25.34-34.03,34.03-44.96,43.96-55.32,49.23-62.19,51.95-60.76,4.05,2.12-.38,21.01-6.38,33.72-2.13,4.5-5.67,10.19-12.76,21.57-3.9,6.26-4.73,7.38-9.72,15.19-13.1,20.49-15.06,24.75-23.7,37.37-6.67,9.74-5.47,7.16-10.63,14.89-4.56,6.82-7.29,11.51-22.18,37.37-4.38,7.61-6.58,11.42-6.86,12.09-4.45,10.53-2.84,15.22-6.21,36.52-1.76,11.13-1.3,4.19-4.56,20.66-1.58,8-2.35,13-6.38,15.19h0Z"/>
  <path id="BG2" class="Coccyx_seitlich1722_cls4" d="M157.33,220.54c11.97-2.72,20.37-10.52,27.04-16.71,4.79-4.45,4.08-4.72,8.2-8.2,8.29-6.99,14.43-8.62,21.27-12.46,7.33-4.11,9.79-7.57,20.35-18.23,4.59-4.63,18.14-18.3,32.51-30.08,22.83-18.72,38.77-31.79,50.73-34.63,1.21-.29,4.54-.98,6.08-3.65,1.86-3.23-.78-6.15-3.95-16.41-2.67-8.64-2.46-11.94-6.08-14.89-1.92-1.56-3.7-2.03-6.08-2.73,0,0-7.87-2.34-19.14-6.99-15.8-6.52-19.9-25.2-25.22-23.39-1.32.45-.87,1.54-6.38,7.86-1.59,1.83-3.06,3.37-3.64,3.99-2.42,2.55-3.93,3.94-5.16,5.16-3.65,3.61-5.85,6.67-7.29,8.51-5.64,7.23-19.44,21.41-25.22,27.34-16.53,16.99-32.41,31.45-45.57,43.44-12.77,11.63-25.42,23.4-38.28,34.94-1.7,1.52-4.47,4.15-10.03,9.42-2.95,2.8-3.1,2.96-3.34,3.34-1.47,2.32-2.72,6.95,4.45,21.87,5.98,12.45,8.98,18.67,14.69,21.57,7.44,3.78,15.17,2.02,20.05.91v.02Z"/>
  <path id="BG1" class="Coccyx_seitlich1722_cls4" d="M183.76,13.35c1.65-1.45,4.29-.5,8.46.28,5.48,1.01,4.38,0,14.32.94,3.74.35,12.21,1.27,21.57,3.34,16.55,3.67,24.83,5.51,26.43,9.72,2.52,6.63-9.62,15.11-33.16,36.07-6.83,6.08-42.54,37.89-63.14,60.84-5.36,5.98-9.46,11.08-17.32,17.32-8.21,6.51-11.71,7.27-17.62,13.97-5.81,6.6-9.19,13.37-11.61,12.9-2.87-.56-2.72-10.03-1.45-16.24.68-3.32,2.08-5.6,4.86-9.72,5.38-7.96,8.88-12.37,10.85-15.12,2.67-3.73,12.48-17.41,13.45-34.09.24-4.05-.08-7.73,2.13-12.46,2.58-5.52,6.72-8.42,9.52-10.53,6.04-4.56,18.66-14.07,25.46-27.06,4.45-8.5,7.58-14.48,6.64-21.96-.35-2.76-1.35-6.49.61-8.2h0Z"/>
</g>
<g id="Highlight">
  <path id="Highlight3" class="Coccyx1722Fill" style= {{
            fill: props.colors.Coccyx1722Fill,
            stroke: props.colors.Coccyx1722Fill,
          }} d="M61.24,581.06c-.05-2.25,4.37-2.35,6.84-6.84,2.59-4.7,0-8.71,2.05-10.25,2.61-1.96,9.48,2.48,13.67,6.15,6.28,5.52,15.44,17.17,11.45,24.75-1.16,2.21-3.66,4.49-6.51,4.66-4.74.27-5.87-5.61-13.83-10.95-7.3-4.9-13.61-4.83-13.67-7.52h0Z"/>
  <path id="Highlight2" class="Coccyx1722Fill" style= {{
            fill: props.colors.Coccyx1722Fill,
            stroke: props.colors.Coccyx1722Fill,
          }} d="M32.53,559.18c-2.07,2.93,1.27,10.74,6.84,14.35,3.59,2.33,5.63,1.31,12.37,4.86,3.65,1.92,3.95,2.7,5.4,2.65,5.47-.15,13.27-11.45,9.57-20.51-2.26-5.52-8.54-9.57-13.67-8.89-3.97.53-4.34,3.52-10.25,5.47-5.94,1.96-8.82.02-10.25,2.05v.02Z"/>
  <path id="Highlight1" class="Coccyx1722Fill" style= {{
            fill: props.colors.Coccyx1722Fill,
            stroke: props.colors.Coccyx1722Fill,
          }} d="M8.61,519.54c-3.6,2.82,5,18.98,7.06,22.86,4.83,9.08,7.25,13.62,12.08,15.42,9.75,3.63,23-4.27,23.92-10.94.21-1.52-.25-2.71-.15-8.73.07-4.22.31-4.22.15-5.62-.5-4.36-3.46-9.98-7.52-10.94-4.21-1-5.85,3.89-11.62,5.47-10.89,2.97-20.5-10.2-23.92-7.52h0Z"/>
</g>
<g id="Anatomy">
  <path id="coccyx24" class="Coccyx_seitlich1722_cls2" d="M63.07,580.61c-.05-2.25,4.37-2.35,6.84-6.84,2.59-4.7,0-8.71,2.05-10.25,2.61-1.96,9.48,2.48,13.67,6.15,6.28,5.52,15.44,17.17,11.45,24.75-1.16,2.21-3.66,4.49-6.51,4.66-4.74.27-5.87-5.61-13.83-10.95-7.3-4.9-13.61-4.83-13.67-7.52h0Z"/>
  <path id="coccyx23" class="Coccyx_seitlich1722_cls2" d="M34.36,558.73c-2.07,2.93,1.27,10.74,6.84,14.35,3.59,2.33,5.63,1.31,12.37,4.86,3.65,1.92,3.95,2.7,5.4,2.65,5.47-.15,13.27-11.45,9.57-20.51-2.26-5.52-8.54-9.57-13.67-8.89-3.97.53-4.34,3.52-10.25,5.47-5.94,1.96-8.82.02-10.25,2.05v.02Z"/>
  <path id="coccyx22" class="Coccyx_seitlich1722_cls2" d="M10.44,519.09c-3.6,2.82,5,18.98,7.06,22.86,4.83,9.08,7.25,13.62,12.08,15.42,9.75,3.63,23-4.27,23.92-10.94.21-1.52-.25-2.71-.15-8.73.07-4.22.31-4.22.15-5.62-.5-4.36-3.46-9.98-7.52-10.94-4.21-1-5.85,3.89-11.62,5.47-10.89,2.97-20.5-10.2-23.92-7.52h0Z"/>
  <path id="coccyx21" class="Coccyx_seitlich1722_cls2" d="M7.17,430.15c3.17-1.22,6.12,6.52,14.58,8.2,2.67.53,5.45.37,6.68.3,6.18-.35,8.22-2.23,9.42-1.22,2.11,1.79-3.32,8.39-6.08,20.35-1.55,6.74-1.67,12.63-1.82,20.66-.13,6.7-.2,10.05.3,12.46,2.41,11.64,10.05,15.4,8.28,22.79-.18.74-1.01,3.87-3.72,5.77-5.2,3.64-13.05-.18-15.8-1.52-2-.97-7.77-3.78-10.94-10.03-2.25-4.44-1.58-7.44-1.82-14.89-.15-4.7-.36-1.68-3.34-24.61-1.17-9-1.99-16.05-.91-22.78.51-3.16,1.42-6.18,2.13-8.51,1.33-4.39,2.02-6.59,3.04-6.99v.02Z"/>
  <path id="coccyx20" class="Coccyx_seitlich1722_cls2" d="M23.88,343.87c-1.2.25-2.11,1.89-3.95,5.16-.83,1.49-1.93,3.74-2.73,6.68-.37,1.33-.7,2.9-.91,4.86-.62,5.59.15,10.23.3,11.24,1.11,7.27-1.84,9.01-4.19,28.01-1.19,9.66-.72,11.57-2.49,17.56-1.67,5.68-2.93,6.77-2.43,9.11,1.9,9.01,24.99,15.34,30.38,8.81,3.94-4.77-4.33-13.07-1.22-30.68.51-2.86,1.13-4.94,1.92-7.57,2.75-9.15,5.66-13.67,11.45-25.24,8.39-16.78,8.34-19.38,7.29-20.35-1.12-1.04-2.77.33-8.81,1.22-5.74.84-10.2,1.49-14.28-.3-6.15-2.71-7.27-9.16-10.33-8.51h0Z"/>
  <path id="coccyx19" class="Coccyx_seitlich1722_cls2" d="M38.56,397.05c.44,5.92,7.2,15.43,13.86,14.87h.02c6.42-.54,10.12-8.17,10.63-13.37.3-3.04-.76-2.55-1.52-9.11-.8-6.92.18-9.13-.91-16.41-.47-3.15-.64-2.65-1.22-6.08-1.64-9.84-.74-16.98-1.22-17.01"/>
  <path id="coccyx18" class="Coccyx_seitlich1722_cls2" d="M25.4,340.83c.23-1.39.98-1.66,3.04-5.77,1.19-2.38,1.88-4.16,2.73-6.38,5.29-13.72,7.9-24.3,7.9-24.3,6.03-24.42,1.65-43.65,7.29-45.27,3.89-1.11,5.66,8.11,18.53,13.97,8.05,3.67,12.08,5.51,17.92,6.08,3.12.3,6.39.23,7.29,2.13.97,2.05-1.32,5.43-3.34,7.29-2.75,2.53-4.45,1.75-7.59,4.25-1.93,1.54-2.18,2.54-4.86,6.38-3.24,4.63-4.24,5.13-6.99,8.81-.51.68-5.09,6.87-7.29,12.46-4.97,12.6,3.34,19.19-1.52,26.73-3.98,6.18-13.8,8.34-21.09,6.1-6.48-2-12.75-8.02-12.02-12.48h0Z"/>
  <path id="coccyx17" class="Coccyx_seitlich1722_cls2" d="M81.3,219.32c-2.02-.88-6.2,4.09-10.94,9.72-4.84,5.76-6.11,8.76-10.94,11.85-2.81,1.8-3.35,1.4-5.5,3.1-2.96,2.34-7.04,7.12-6.96,11.78.06,3.34,2.21,4.32,21.27,17.92,3.28,2.34,9.38,6.72,17.58,7.79,3.28.43,4.78.06,5.81-.8,2.84-2.38.09-6.84,2.43-13.06,2.05-5.44,5.57-5.79,5.16-8.51"/>
  <path id="coccyx16" class="Coccyx_seitlich1722_cls2" d="M115.63,181.04c-2.93-.58-4.89,4.18-11.85,12.46-11.56,13.76-17.41,14-20.35,23.39-.75,2.4-.97,6.16-1.39,13.67-.56,9.97.22,13.42,1.86,16.38.73,1.32,1.99,3.6,4.54,5.41,2.6,1.84,3.7,1.18,7.5,3.53,2.46,1.52,3.1,2.48,4.5,2.33,1.53-.17,2.33-1.49,3.95-3.34,1.79-2.05,4.08-3.61,6.08-5.47,5.36-4.99,15.9-10.95,19.14-12.76,15.78-8.8,23.22-10.88,22.78-13.06-.42-2.14-7.44.23-14.89-3.95-8.31-4.67-12.08-14.65-15.49-23.7-3.58-9.48-3.55-14.32-6.38-14.89h0Z"/>
  <path id="coccyx15" class="Coccyx_seitlich1722_cls2" d="M29.96,326.56c-3.67,2-7.83.45-8.2.3-2.23-.87-6.25-3.39-8.81-13.67-3.66-14.69-.42-29.51,2.13-39.8,6.32-25.52,10.19-41.16,21.57-61.06,3.24-5.66,7.73-11.98,16.71-24.61,13.98-19.66,25.34-34.03,34.03-44.96,43.96-55.32,49.23-62.19,51.95-60.76,4.05,2.12-.38,21.01-6.38,33.72-2.13,4.5-5.67,10.19-12.76,21.57-3.9,6.26-4.73,7.38-9.72,15.19-13.1,20.49-15.06,24.75-23.7,37.37-6.67,9.74-5.47,7.16-10.63,14.89-4.56,6.82-7.29,11.51-22.18,37.37-4.38,7.61-6.58,11.42-6.86,12.09-4.45,10.53-2.84,15.22-6.21,36.52-1.76,11.13-1.3,4.19-4.56,20.66-1.58,8-2.35,13-6.38,15.19h0Z"/>
  <path id="coccyx14" class="Coccyx_seitlich1722_cls1" d="M81.85,187.23c15.45-29.4,29.19-61.78,32.56-71.39"/>
  <path id="coccyx13" class="Coccyx_seitlich1722_cls1" d="M116.23,178.92c1.1-3.69-3.04-5.6-5.16-12.46-1.93-6.24.34-8.37-2.43-12.76-.57-.9-2.3-2.42-5.77-5.47-6.7-5.88-8.4-6.62-9.42-6.99-3.64-1.31-5.36-.24-8.2-.61-12.55-1.63-19.44-27.65-19.75-28.86-2.91-11.41-1.77-19.48-4.56-20.05-1.47-.3-3.35,1.62-7.29,8.51-6.68,11.66-11.21,19.56-13.37,32.2-1.26,7.35-2.99,17.47.61,29.47,3.76,12.54,11.49,20.99,14.58,24.3,9.38,10.05,15.88,10.68,20.05,20.66.88,2.12,1.64,4.8,2.43,7.13.09.14.17.29.23.46.12.23.17.48.19.73.85,2.3,1.76,4.04,2.92,4.14"/>
  <path id="coccyx12" class="Coccyx_seitlich1722_cls1" d="M140.24,79.26c-7.72,2.89-15.86,1.63-18.53,1.22-4.16-.64-7.21-1.75-8.2-2.13-12.92-4.89-19.55-15.85-20.05-16.71-.75-1.29-11.05-19.48-1.82-34.94,5.24-8.78,14.05-12.1,16.71-13.06,10.08-3.64,12.43.94,36.76.91,17.86-.02,22.48-.3,22.48-.3,10.26-.63,12.36-1.44,14.28.3,5.92,5.36-.53,25.27-11.54,37.97-2.96,3.42-2.29,1.74-13.06,12.15-10.92,10.56-12.09,12.74-17.01,14.58h-.02Z"/>
  <path id="coccyx11" class="Coccyx_seitlich1722_cls2" d="M157.55,220.53c11.97-2.72,20.37-10.52,27.04-16.71,4.79-4.45,4.08-4.72,8.2-8.2,8.29-6.99,14.43-8.62,21.27-12.46,7.33-4.11,9.79-7.57,20.35-18.23,4.59-4.63,18.14-18.3,32.51-30.08,22.83-18.72,38.77-31.79,50.73-34.63,1.21-.29,4.54-.98,6.08-3.65,1.86-3.23-.78-6.15-3.95-16.41-2.67-8.64-2.46-11.94-6.08-14.89-1.92-1.56-3.7-2.03-6.08-2.73,0,0-7.87-2.34-19.14-6.99-15.8-6.52-19.9-25.2-25.22-23.39-1.32.45-.87,1.54-6.38,7.86-1.59,1.83-3.06,3.37-3.64,3.99-2.42,2.55-3.93,3.94-5.16,5.16-3.65,3.61-5.85,6.67-7.29,8.51-5.64,7.23-19.44,21.41-25.22,27.34-16.53,16.99-32.41,31.45-45.57,43.44-12.77,11.63-25.42,23.4-38.28,34.94-1.7,1.52-4.47,4.15-10.03,9.42-2.95,2.8-3.1,2.96-3.34,3.34-1.47,2.32-2.72,6.95,4.45,21.87,5.98,12.45,8.98,18.67,14.69,21.57,7.44,3.78,15.17,2.02,20.05.91v.02Z"/>
  <path id="coccyx10" class="Coccyx_seitlich1722_cls1" d="M25.34,325.76c-4-36.99,11.86-65.11,21.76-71.57"/>
  <path id="coccyx9" class="Coccyx_seitlich1722_cls2" d="M58.96,327.29c-8.15-49.23-2.61-85.68-1.12-89.64"/>
  <path id="coccyx8" class="Coccyx_seitlich1722_cls1" d="M100.44,258.2c87.87,56.24,151.64,96.18,150.68,106.14,6.7,8.77-3.02,45.49-21.87,82.03-11.7,22.96-36.85,58.9-58.71,76.3-20.24,6.18-14.8,26.5-11.17,31.24,12.23,11.18,23.74,24.73,22.18,32.2.57,7.69.58,13.51,0,16.11-7.27,11.07-12.09,25.26-17.46,27.02"/>
  <path id="coccyx7" class="Coccyx_seitlich1722_cls1" d="M152.39,223.56c97.61,14.54,163.77,54.75,162.53,58.11,12.47,7.85,21.05,27.79,21.26,49.56"/>
  <path id="coccyx6" class="Coccyx_seitlich1722_cls2" d="M183.98,13.34c1.65-1.45,4.29-.5,8.46.28,5.48,1.01,4.38,0,14.32.94,3.74.35,12.21,1.27,21.57,3.34,16.55,3.67,24.83,5.51,26.43,9.72,2.52,6.63-9.62,15.11-33.16,36.07-6.83,6.08-42.54,37.89-63.14,60.84-5.36,5.98-9.46,11.08-17.32,17.32-8.21,6.51-11.71,7.27-17.62,13.97-5.81,6.6-9.19,13.37-11.61,12.9-2.87-.56-2.72-10.03-1.45-16.24.68-3.32,2.08-5.6,4.86-9.72,5.38-7.96,8.88-12.37,10.85-15.12,2.67-3.73,12.48-17.41,13.45-34.09.24-4.05-.08-7.73,2.13-12.46,2.58-5.52,6.72-8.42,9.52-10.53,6.04-4.56,18.66-14.07,25.46-27.06,4.45-8.5,7.58-14.48,6.64-21.96-.35-2.76-1.35-6.49.61-8.2h0Z"/>
  <path id="coccyx5" class="Coccyx_seitlich1722_cls1" d="M117.23.39c-32.11,25.52-61.37,74.47-56.28,89.3"/>
  <path id="coccyx4" class="Coccyx_seitlich1722_cls1" d="M190.06,2.82c-32.73,14.04-98.75,42-129.11,88.9"/>
  <path id="coccyx3" class="Coccyx_seitlich1722_cls1" d="M254.77,27.62c2.13-.44,9.38-9.73,12.76-23.58"/>
  <path id="coccyx2" class="Coccyx_seitlich1722_cls1" d="M263.27,32.18c7.66-12.34,13.96-24.28,14.58-24.8"/>
  <line id="coccyx1" class="Coccyx_seitlich1722_cls1" x1="254.77" y1="27.62" x2="263.27" y2="32.18"/>
  <line id="coccyx" class="Coccyx_seitlich1722_cls1" x1="111.91" y1="168.72" x2="85.71" y2="191.38"/>
</g>
</svg>
  );
}

export default SteissbeinLatLiegend1722;
