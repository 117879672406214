import style from "./style.scss";

function RechteGroszeheLatSitzend(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140.01 491.96">
      <g id="big_toe" data-name="big toe">
        <path
          id="toe_2.2"
          data-name="toe 2.2"
          class="a1404_Großzeh_lat_rechts_cls_3"
          style={{
            fill: props.colors.a1404_Großzeh_lat_rechts_cls_3,
            stroke: props.colors.a1404_Großzeh_lat_rechts_cls_3,
          }}
          d="M16.09,430.77l-1.64-153.97c2.18-15.51-3.36-28.25-12.01-39.86-7.34-22.35,4.94-29.9,19.11-36.04,26.44-6.17,22.57,25.39,19.11,64.43,1.25,35.54,6.91,74.78,15.29,116.3,5.69,24.12,5.1,42.29-12.01,44.77"
        />
        <path
          id="toe_2.2-2"
          data-name="toe 2.2"
          class="a1404_Großzeh_lat_rechts_cls_3"
          style={{
            fill: props.colors.a1404_Großzeh_lat_rechts_cls_3,
            stroke: props.colors.a1404_Großzeh_lat_rechts_cls_3,
          }}
          d="M7.91,143.57c-2.34,17.79,3.1,30.06,15.83,37.13,6.61,6.34,9.43,11.85,2.73,15.29-4.48-1.46-10.75-.73-22.39,6.55"
        />
        <path
          id="big_toe_1"
          data-name="big toe 1"
          class="a1404_Großzeh_lat_rechts_cls_2"
          style={{
            fill: props.colors.a1404_Großzeh_lat_rechts_cls_2,
            stroke: props.colors.a1404_Großzeh_lat_rechts_cls_2,
          }}
          d="M67.97,54.04l-10.92,25.66c-4.35,13.85,1.03,17.04,10.92,15.29,5.93-3.78,12.14-3.5,18.56,0,7.35,3.43,12.95,3.76,14.74-2.73-6.47-9.31-10.24-22.12-11.47-38.22,5.81-22.9,4.67-36.36-9.83-31.67-10.36,8.97-18.3,18.54-12.01,31.67h.01Z"
        />
        <path
          id="big_toe_2"
          data-name="big toe 2"
          class="a1404_Großzeh_lat_rechts_cls_2"
          style={{
            fill: props.colors.a1404_Großzeh_lat_rechts_cls_2,
            stroke: props.colors.a1404_Großzeh_lat_rechts_cls_2,
          }}
          d="M86.53,101.54c-20.65-4.02-32.43-1.19-25.12,16.38,8.01,12.24,8.87,24.26,1.64,36.04-13.03,16.03-23.55,31.25-20.2,42.04-1.04,10.24,4.38,11.51,13.1,8.19,14.25-4.69,27.45-2.47,39.86,4.91,12.51,2.36,12.06-11.81,9.28-28.94-6.85-14.98-6.03-39.57-1.09-69.34,2.16-12.29-3.77-15.27-17.47-9.28h0Z"
        />
        <path
          id="big_toe_3"
          data-name="big toe 3"
          class="a1404_Großzeh_lat_rechts_cls_2"
          style={{
            fill: props.colors.a1404_Großzeh_lat_rechts_cls_2,
            stroke: props.colors.a1404_Großzeh_lat_rechts_cls_2,
          }}
          d="M104.01,241.85c-7.51,50.81-5.73,100.48,4.91,149.06,2.61,20.02,4.81,39.46-11.47,33.31-20.44-2.92-41.01-.67-61.7,6.55-21.6,4.36-9.9-35.22,10.92-86.81,11.84-24.23,10.3-50.83-1.09-79.17-13.31-20.53-5.32-38.72,13.65-55.69,8.33-4.55,17.55-4.07,27.3-.55,21.03,9.66,25.33,20.9,17.47,33.31h0Z"
        />
        <path
          id="big_toe_4"
          data-name="big toe 4"
          class="a1404_Großzeh_lat_rechts_cls_2"
          style={{
            fill: props.colors.a1404_Großzeh_lat_rechts_cls_2,
            stroke: props.colors.a1404_Großzeh_lat_rechts_cls_2,
          }}
          d="M102.92,491.92l2.18-24.02c9.24-28.73,3.88-42.84-16.93-41.5l-38.22,2.73c-28.71-.08-29.04,25.06-20.75,57.87"
        />
        <path
          id="big_toe_5"
          data-name="big toe 5"
          class="a1404_Großzeh_lat_rechts_cls_2"
          style={{
            fill: props.colors.a1404_Großzeh_lat_rechts_cls_2,
            stroke: props.colors.a1404_Großzeh_lat_rechts_cls_2,
          }}
          d="M53.21,226.57c3.4,9.07,3.4,18.16-2.18,27.3-3.07,7.29-6.74,7.9-10.92,2.73-5.26-8.7-6.55-17.44-3.82-26.21,6.95-10.48,12.41-10.46,16.93-3.82h-.01Z"
        />
        <path
          id="big_toe_6"
          data-name="big toe 6"
          class="a1404_Großzeh_lat_rechts_cls_2"
          style={{
            fill: props.colors.a1404_Großzeh_lat_rechts_cls_2,
            stroke: props.colors.a1404_Großzeh_lat_rechts_cls_2,
          }}
          d="M36.84,256.61c5.16-4.56,7.35-11.43,7.1-20.2-1.26-8.55-4.83-14.24-15.29-11.47-8.6,4.97-11.71,12.21-10.37,21.29,2.86,10.86,9.27,13.82,18.56,10.37h0Z"
        />
        <path
          id="outlines"
          class="a1404_Großzeh_lat_rechts_cls_1"
          style={{
            fill: props.colors.a1404_Großzeh_lat_rechts_cls_1,
            stroke: props.colors.a1404_Großzeh_lat_rechts_cls_1,
          }}
          d="M33.04,186.44c-5.19-30.61-5.49-60.65,13.5-88.45-6.83-24.69-1.64-51.77,11.27-80.37,1.74-3.85,4.26-7.34,7.46-10.1,11.41-9.86,23.53-9.37,36.24-.44,18.5,24.82,26.88,55.22,21.21,93.36,18.24,30.42,20.22,66.51,13.5,105.65"
        />
      </g>
      <g id="overlays">
        <path
          id="big_toe_overlay_1"
          data-name="big toe overlay 1"
          class="a1404_GroßzehFill"
          style={{
            fill: props.colors.a1404_GroßzehFill,
            stroke: props.colors.a1404_GroßzehFill,
          }}
          d="M67.97,54.04l-10.92,25.66c-4.35,13.85,1.03,17.04,10.92,15.29,5.93-3.78,12.14-3.5,18.56,0,7.35,3.43,12.95,3.76,14.74-2.73-6.47-9.31-10.24-22.12-11.47-38.22,5.81-22.9,4.67-36.36-9.83-31.67-10.36,8.97-18.3,18.54-12.01,31.67h.01Z"
        />
        <path
          id="big_toe_overlay_2"
          data-name="big toe overlay 2"
          class="a1404_GroßzehFill"
          style={{
            fill: props.colors.a1404_GroßzehFill,
            stroke: props.colors.a1404_GroßzehFill,
          }}
          d="M86.53,101.54c-20.65-4.02-32.43-1.19-25.12,16.38,8.01,12.24,8.87,24.26,1.64,36.04-13.03,16.03-23.55,31.25-20.2,42.04-1.04,10.24,4.38,11.51,13.1,8.19,14.25-4.69,27.45-2.47,39.86,4.91,12.51,2.36,12.06-11.81,9.28-28.94-6.85-14.98-6.03-39.57-1.09-69.34,2.16-12.29-3.77-15.27-17.47-9.28h0Z"
        />
        <ellipse
          id="joint_overlay_1"
          data-name="joint overlay 1"
          class="a1404_gelenkeFill"
          style={{
            fill: props.colors.a1404_gelenkeFill,
            stroke: props.colors.a1404_gelenkeFill,
          }}
          cx="80.38"
          cy="94.95"
          rx="30.36"
          ry="15"
        />
        <ellipse
          id="joint_overlay_2"
          data-name="joint overlay 2"
          class="a1404_gelenkeFill"
          style={{
            fill: props.colors.a1404_gelenkeFill,
            stroke: props.colors.a1404_gelenkeFill,
          }}
          cx="74.49"
          cy="210.95"
          rx="43.52"
          ry="18"
        />
      </g>
    </svg>
  );
}

export default RechteGroszeheLatSitzend;
