import style from "./style.scss";

function RechterMittelfussLatLiegend1438(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538.4 321.5">

<g id="Metatarsal_lat" data-name="Metatarsal lat">
  <g id="Metatarsal">
    <path id="Metatarsal_1" data-name="Metatarsal 1" class="mittefuss_lat_rechts1438_cls5" d="M470.2,50.3l-8.8,6c-9.1,7.2-19.6,10.9-31.3,12-11.5,3.9-16.7,15.4-16.3,33.7.4,11.5,6.7,23.4,18,35.7,8.1,7.6,18,11.7,29.9,12.1,11.4,3.4,24.2-2,38.2-14.7"/>
    <path id="Metatarsal_2" data-name="Metatarsal 2" class="mittefuss_lat_rechts1438_cls5" d="M518.3,229.7c-17.5-6.7-29-14-31-22.2-3.7-.1-7.3-.4-10.2-1-3.5-.7-5.8-3.8-5.8-7.3l1-41.3c-18.5-10.9-24.7-20.2-13.1-27.2,11.4-19.7,29.2-21.9,51.3-12.4"/>
    <path id="Metatarsal_3" data-name="Metatarsal 3" class="mittefuss_lat_rechts1438_cls5" d="M462.5,199l.9-39.8c-16-10.1-40.4-7-70.6,5.4-15.9,11.4-15.1,25.9-10.3,41.2,9.9,14.4,29.4,21,56.2,21.7,24.1-2.2,31.1-12.1,23.8-28.6v.1Z"/>
    <path id="Metatarsal_4" data-name="Metatarsal 4" class="mittefuss_lat_rechts1438_cls5" d="M417.9,193.6h0c13.4-.3,23.1-4.5,29.7-11.8,6.9-9.7,7.3-18.6,0-26.5-14.7-7.4-26.3-17.5-32.3-28.9-7-14.6-9.9-28.1-4.2-45.4.4-1.3-.9-2.5-2.1-1.8-5.7,3.1-11.1,4.1-16.5,4-3.8,6.2-11.7,9.3-21.4,11.2-.4,4.8-2.9,5.9-6.5,5.2-4.4,32.7,8.9,60.8,38.6,84.6-.6,7.9,3.4,11.8,14.5,9.2l.2.2Z"/>
    <path id="Metatarsal_5" data-name="Metatarsal 5" class="mittefuss_lat_rechts1438_cls5" d="M386.2,173.2h0c-24.5-16.1-33.1-38.4-27.2-66.3.9-4-2.3-7.8-6.4-7.7l-49.1,1.5-23.3,11.1c-17.5,10.7-3.1,41.3,15.6,74.7.6,15.7,4.5,26.7,12.5,32.3,11.2,4.3,26-1.2,42.4-11,10,.5,21.1-2.4,32.9-7.4l2.7-27.2h-.1Z"/>
    <path id="Metatarsal_6" data-name="Metatarsal 6" class="mittefuss_lat_rechts1438_cls5" d="M291.1,194.1h0c-10.3-18.6-17.2-36.5-21.4-53.8-.8-3.3-1.4-6.7-1.4-10.1-.3-16.8-10.6-17-31-.4-39.1,20.1-83.7,34.5-130.8,46.4-2.4.6-4.9.5-7.3-.2-4.8-1.5-8.1-1.1-9.5,1.3-15.4,7.1-20.3,17.7-17.9,30.7,4.1,19.7,21.2,22.9,44.8,18,3.4-.7,6.4-2.2,9.1-4.4,16.9-13.9,43-21.4,77.7-23,27-3.2,52.9,3.9,78.2,16,2.8,1.3,5.9,1.8,8.9,1,7.3-2,7.3-9.3.6-21.6v.1Z"/>
    <path id="Metatarsal_7" data-name="Metatarsal 7" class="mittefuss_lat_rechts1438_cls5" d="M23.5,224.5c7.4,2.1,14.7,5,21.6,8.8,4.5,2.5,9.4,4.1,14.5,4.3,10.6.5,14.6-3.2,10.6-11.5h0c-5.7-8.9-9.3-19.7-6.8-36,2.3-10.2-9.3-7.5-23.7-2.4-8.3,2.7-16.4,5.1-24.2,7.1"/>
    <path id="Metatarsal_8" data-name="Metatarsal 8" class="mittefuss_lat_rechts1438_cls1" d="M62.5,185.9c-4.6,7.4-6.6,16.6-5.3,28.2,1.5,8.6,6.2,15.3,12.2,21.1"/>
  </g>
  <g id="transperent_metatarsal" data-name="transperent metatarsal" class="mittefuss_lat_rechts1438_cls6">
    <path id="transperent_metatarsal_1" data-name="transperent metatarsal 1" class="mittefuss_lat_rechts1438_cls2" d="M384.9,191.4c-14.1,5.6-27,4.8-38.5-2.8-14.5-7.1-23-16.1-23-27.8,4.6-15.4,18.1-21.9,38.2-21.6"/>
    <path id="transperent_metatarsal_2" data-name="transperent metatarsal 2" class="mittefuss_lat_rechts1438_cls2" d="M395.5,140.6h0c-3,33.3-18.2,45.1-45.2,36.3-16-13.1-30-28.6-41.4-46.6-6-15.1-3.4-26,18.2-27.6l39.6,6.3c18.6,3.4,30.7,11.9,28.8,31.7v-.1Z"/>
    <path id="transperent_metatarsal_3" data-name="transperent metatarsal 3" class="mittefuss_lat_rechts1438_cls2" d="M108.8,253c-18,.1-27.8-6.7-31.9-18.1-5.9-17.6.2-32.2,26.1-41.8l93.7-33.1c32.6-11.5,63.6-25.1,89.6-45.9,15.1-8.3,16.2,7.8,15.9,26.2"/>
    <path id="transperent_metatarsal_4" data-name="transperent metatarsal 4" class="mittefuss_lat_rechts1438_cls2" d="M315.4,194.6l-2.4-44.2c-.4-21.9-8.6-26-27.3-5.7-33.9,20.6-69.9,34.6-106.8,45.3-45.6,13.9-88.2,27.7-95.4,41.1-10.5,13.1,5.5,21.7,28.1,29.2,14.6,4,24,2.2,24.3-9.9"/>
    <path id="transperent_metatarsal_5" data-name="transperent metatarsal 5" class="mittefuss_lat_rechts1438_cls2" d="M182.4,244.8l-15.3,15.9c-29,11.5-43.6,3.8-45.6-20.7-3.3-15.7,49.7-27.4,101.4-39.1l91.5-29.9c16.6,3.4,14.2,20.7,8,40.9"/>
    <path id="transperent_metatarsal_6" data-name="transperent metatarsal 6" class="mittefuss_lat_rechts1438_cls2" d="M404.6,257.3h0c-19.7,2.6-40.7-2.3-62.3-9.7-49.5-4.3-93.6-1-123.4,21.9-4.3,11.6-18.3,9.9-37.4,1.1-9.3-7.4-13.1-16.6-4.6-30.1,41.4-23.1,109.9-34.5,181.3-44.7,27.5.5,44.4,13.7,54.4,35.3,14.6,12.1,14.3,21.3-8,26.1v.1Z"/>
    <path id="transperent_metatarsal_7" data-name="transperent metatarsal 7" class="mittefuss_lat_rechts1438_cls2" d="M155.2,225.2h0l-46.1-2.3c-4.3-.2-8.4,2-10.4,5.8-3.8,7.2.2,12,9.9,15,16.3.9,30.1,5.4,40.9,14,9.9,9.1,18.7,6.8,26.2-9.7.4-14.5-3.5-25-20.5-22.8h0Z"/>
    <path id="transperent_metatarsal_8" data-name="transperent metatarsal 8" class="mittefuss_lat_rechts1438_cls2" d="M30.3,233.2c11,2.4,21.6,6.3,32,11.4,10.9,7.8,14.5,1.6,12.3-15.7,7.8-10.5.6-15.9-27-14.3h-15.9"/>
    <path id="transperent_metatarsal_9" data-name="transperent metatarsal 9" class="mittefuss_lat_rechts1438_cls2" d="M36,246.5l7.4.2h0c5.3.2,9.8-3.9,10.3-9.2h0c-1.3-3.3-4.8-6.3-14.1-8-1.9-.3-3.7-.7-5.6-1.1"/>
  </g>
  <path id="soft_parts_1" data-name="soft parts 1" class="mittefuss_lat_rechts1438_cls3" d="M427.4,46.7l-42.6,7.2c-23.1,3.9-45.6,10.4-67.3,19.2l-160.6,65.4c-13.4,5.4-27.2,10-41.2,13.6l-91.2,23.5"/>
  <path id="soft_parts_2" data-name="soft parts 2" class="mittefuss_lat_rechts1438_cls3" d="M514.5,274.4c-72,12.9-214.2-19.4-255.4,5.6"/>
</g>
<g id="Ebene_2" data-name="Ebene 2">
  <path id="metatarsal_bone_overlay" data-name="metatarsal bone overlay" class="MFK1438Fill" style= {{
      fill: props.colors.MFK1438Fill,
      stroke: props.colors.MFK1438Fill,
      opacity:props.colors.MFK1438Fill,
    }} d="M102.3,176.5h0c52.8-12.9,99.8-27.8,135.1-46.8,16.8-13.6,28.6-17.4,30.8-2.7l11.7-8c15.2-12.1,22.6-7.6,22.4,12.7,9.1-3.5,12.5,11.9,12.2,39.3,12.5,3.5,15.4,13.9,11,29.7,20.7-5.2,40.2-7.3,55.9.7,12.4,3.8,23.3,12,31.3,29.7,13.3,10.8,15.6,20-6.1,25.7-20.4,3.1-41.5-1.1-63-8.8-49.9-4-95.3-2-124.6,21.5-4.3,10.3-16.5,9.8-33.5,2.8-6.2-2.1-10.7-7.5-13.5-15.7-4.5,3.8-8.9,6.5-13.1,6.3-11,3.7-21.4,4.8-29.7-2.6l-9.8,1.5c-17.5-3.8-31.6-9.7-38.6-19.9l-6.2-13.1c2.4-3.6,3.4-6.8,3.2-9.7-5.2-4-7-11.5-6.3-21.6.4-7.3,6.5-14,18.2-20.2,2.6-2.9,7.1-2.7,12.5-.8h.1Z"/>
  <path id="foot_arch_overlay" data-name="foot arch overlay" class="FootArch1438Fill" style= {{
      fill: props.colors.FootArch1438Fill,
      stroke: props.colors.FootArch1438Fill,
      opacity:props.colors.FootArch1438Fill,
    }} d="M458,223.4h0c3.3-3.6,7.4-6.5,12-8.4l3.6-1.5c3.2-1.4,6.1,2.7,3.7,5.3-11.6,12.7-23,24.8-33.5,33.9-12.2,10.6-28.3,15.5-44.4,13.6l-45.4-5.5c-1.3-.1-2.6-.3-3.9-.3-52.7-2.8-96.1,2-128.5,16-2.3,1-4.7-.8-4.5-3.2h0c.2-1.9,1.2-3.6,2.8-4.6,28.3-18.6,68.3-26.6,123.6-20.7,35,12.2,62,15.1,76.2,3,12-1.6,25-13.2,38.3-27.6h0Z"/>
  <g id="soft_parts_overlay" data-name="soft parts overlay">
    <path id="soft_parts_overlay_2" data-name="soft parts overlay 2" class="Weichteile1438Fill" style= {{
      fill: props.colors.Weichteile1438Fill,
      stroke: props.colors.Weichteile1438Fill,
      opacity:props.colors.Weichteile1438Fill,
    }} d="M265.2,104.5l-129.3,47.7c-5,1.9-10,3.5-15.1,4.8l-93.2,24.5c-1.8.5-3.6-1-3.4-2.9l.3-3.1,98.3-25.4c28.1-8.3,78.8-29.3,131.3-51.3,67.9-30.8,109.4-43,172.2-52.1,0,0,.1.2,0,.2l-161.1,57.6Z"/>
    <path id="soft_parts_overlay_1" data-name="soft parts overlay 1" class="Weichteile1438Fill" style= {{
      fill: props.colors.Weichteile1438Fill,
      stroke: props.colors.Weichteile1438Fill,
      opacity:props.colors.Weichteile1438Fill,
    }} d="M259.1,280h0c53.2-23,196.9,7.2,255.4-5.6l-87.4-3.2c-16.4-.6-32.8-2.1-49.1-4.5-58.9-8.5-97.1-3.1-118.9,13.3h0Z"/>
  </g>
</g>
</svg>
  );
}

export default RechterMittelfussLatLiegend1438;
