import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { useState } from "react";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { loadAnatomyOrgans } from "../../../../Utilities/Examinations/anatomy";
import { useDispatch, useSelector } from "react-redux";
import {
  clearExamplannerBodypart,
  selectExamplannerBodypart,
} from "../../../../Redux/actions";

import "./ListSelector.scss";

function capitalize(str) {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
}

function ListSelector() {
  const dispatch = useDispatch();
  const selectedBodyPart = useSelector(
    (state) => state.examPlanner.viewControll.selectedBodyPart
  );
  const selectedExaminations = useSelector(
    (state) => state.examPlanner.risData.examinations
  );
  const selectedIndications = useSelector(
    (state) => state.examPlanner.risData.indications
  );

  const [anatomy, setAnatomy] = useState(undefined);

  const [searchTerm, setSearchTerm] = useState("");
  const { keycloak } = useKeycloak();

  const setBodyPart = (bPartObj) => {
    if (selectedBodyPart?.id === bPartObj.id) {
      dispatch(clearExamplannerBodypart());
      return;
    }
    dispatch(selectExamplannerBodypart(bPartObj));
  };

  const loadAnatomy = (refresh = false) => {
    let filter = undefined;
    let exid = undefined;
    let indid = undefined;
    if (searchTerm !== "") {
      filter = {
        or: [
          { field: "designation_de", op: "like", value: `%${searchTerm}%` },
          {
            field: "designation_de",
            op: "like",
            value: `%${searchTerm.toLowerCase()}%`,
          },
          {
            field: "designation_de",
            op: "like",
            value: `%${searchTerm.toUpperCase()}%`,
          },
          {
            field: "designation_de",
            op: "like",
            value: `%${capitalize(searchTerm)}%`,
          },
        ],
      };
    }
    if (selectedExaminations.length > 0) {
      let ids = selectedExaminations.map((a) => a.id);
      exid = { examIds: ids };
    }
    if (selectedIndications.length > 0) {
      let ids = selectedIndications.map((a) => a.id);
      indid = { indIds: ids };
    }
    loadAnatomyOrgans(keycloak, filter, exid, indid).then((data) => {
      setAnatomy(data);
    });
  };

  const popover = (
    <Popover id="inidicationsPopover">
      <Popover.Title as="h3">Selected Indications</Popover.Title>
      <Popover.Content className="p-2">
        <ul>
          <li>{selectedBodyPart?.designation_de}</li>
        </ul>
      </Popover.Content>
    </Popover>
  );

  useEffect(() => {
    loadAnatomy(true);
  }, [searchTerm, selectedExaminations, selectedIndications]);

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ width: "100%", height: "100%" }}
    >
      <div className="w-100 d-flex align-items-center justify-content-between mb-3">
        <OverlayTrigger trigger="focus" placement="bottom" overlay={popover}>
          <button
            className="border-0 p-0 m-0 bg-white"
            disabled={selectedBodyPart?.id == undefined}
          >
            <i className="fa fa-check p-2 border rounded btn-like-icon" />
          </button>
        </OverlayTrigger>

        <Form style={{ width: "80%" }}>
          <Form.Group className="my-auto">
            <Form.Control
              type="text"
              placeholder="Search Body Part"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </Form>

        <button
          className="border-0 p-0 m-0 bg-white"
          disabled={selectedBodyPart?.id == undefined}
        >
          <i
            className="fa fa-broom p-2 border rounded btn-like-icon"
            onClick={() => dispatch(clearExamplannerBodypart())}
          />
        </button>
      </div>
      <div className="w-100" style={{ maxHeight: "100%", overflowY: "auto" }}>
        {anatomy?.anatomy?.map((organ, key) => (
          <div
            onClick={() => setBodyPart(organ)}
            key={`organKey${key}`}
            className={`${
              organ.id === selectedBodyPart?.id && "active"
            } p-2 mb-1 border rounded d-flex align-items-center justify-content-left organ-row`}
          >
            <div className="font-weight-bold">{organ.designation_de}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ListSelector;
