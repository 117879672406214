import { useSelector } from "react-redux";

export default function ThemeProvider({ theme, children }) {
  const themeSelected = useSelector((state) => state.themes.activeTheme);

  const themes = {
    classic: {
      "--pmed_app_backgound": "var(--pmed_primary_backgorund_classic)",
      "--pmed_primary": "var(--pmed_primary_classic)",
      "--pmed_secondary": "var(--pmed_secondary_classic)",
      "--pmed_highlight": "var(--pmed_highlight_classic)",
      "--pmed_highlight2": "var(--pmed_highlight2_classic)",
      "--pmed_font": "var(--pmed_font_classic)",
      "--pmed_font2": "var(--pmed_font2_classic)",
      "--pmed_font3": "var(--pmed_font3_classic)",
      "--pmed_font4": "var(--pmed_font4_classic)",
    },
    dark: {
      "--pmed_app_backgound": "var(--pmed_primary_backgorund_dark)",
      "--pmed_primary": "var(--pmed_primary_dark)",
      "--pmed_secondary": "var(--pmed_secondary_dark)",
      "--pmed_highlight": "var(--pmed_highlight_dark)",
      "--pmed_highlight2": "var(--pmed_highlight2_dark)",
      "--pmed_font": "var(--pmed_font_dark)",
      "--pmed_font2": "var(--pmed_font2_dark)",
      "--pmed_font3": "var(--pmed_font3_dark)",
      "--pmed_font4": "var(--pmed_font4_dark)",
    },
    blue: {
      "--pmed_app_backgound": "var(--pmed_primary_backgorund_blue)",
      "--pmed_primary": "var(--pmed_primary_blue)",
      "--pmed_secondary": "var(--pmed_secondary_blue)",
      "--pmed_highlight": "var(--pmed_highlight_blue)",
      "--pmed_highlight2": "var(--pmed_highlight2_blue)",
      "--pmed_font": "var(--pmed_font_blue)",
      "--pmed_font2": "var(--pmed_font2_blue)",
      "--pmed_font3": "var(--pmed_font3_blue)",
      "--pmed_font4": "var(--pmed_font4_blue)",
    },
  };

  const activateTheme = (theme) => {
    for (let prop in theme) {
      document.querySelector(":root").style.setProperty(prop, theme[prop]);
    }
  };
  return activateTheme(themes[themeSelected]) || children;
}
