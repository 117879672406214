// import "./Patients.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EditPatientDialog from "../Components/EditPatientDialog/EditPatientDialog";
import "./PatientRecordHead.css";

function PatientHeader(props) {
  const [isEditDisplayed, setIsEditDisplayed] = useState(false);
  const [icon, setIcon] = useState(null);
  const [age, setAge] = useState(0);
  const { t } = useTranslation(["common"]);

  const iconselector = (sex) => {
    let selected = (
      <i className="fas fa-genderless fa-2x text-white mt-1 mr-2"></i>
    );
    if (sex === "M") {
      selected = <i className="fas fa-mars fa-2x text-white mt-1 mr-2"></i>;
    } else if (sex === "F") {
      selected = <i className="fas fa-venus fa-2x text-white mt-1 mr-2"></i>;
    }
    setIcon(selected);
  };
  const ageCalc = (birthdate) => {
    let today = new Date();
    let birthDate = new Date(birthdate);
    let age_now = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    setAge(age_now);
  };

  useEffect(() => {
    iconselector(props.patient.patientssex);
    ageCalc(props.patient.gebdat);
  }, [props]);

  return (
    <>
      <EditPatientDialog
        patient={props.patient}
        onHide={() => setIsEditDisplayed(false)}
        show={isEditDisplayed}
      />
      <div className="pat-head-box" onClick={() => setIsEditDisplayed(true)}>
        <div id="pat-name" className="row">
          <div className="col-10">
            <h4 className="ml-2 display-8 pt-2">
              {props.patient.patientsname}, {props.patient.patientsvorname}
            </h4>
          </div>
          <div className="col-2 text-right">{icon}</div>
        </div>
        <hr className="my-1 mx-2 bg-white"></hr>
        <div className="row">
          <div id="pat-birthdate" className="col-10">
            <p className="ml-2 text-white">
              {props.patient.gebdat} - {t("yearOldPref")} {age}{" "}
              {t("yearOldSuf")}
              <span className="ml-3">
                {`${
                  window.conf.LANG === "PL" &&
                  `PESEL : ${props.patient.patientid}`
                }`}
              </span>
            </p>
          </div>
          <div id="hidden-edit" className="col-2 text-right">
            <i className="fas fa-edit fa-lg text-white mt-2 mr-2"></i>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientHeader;
