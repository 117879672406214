import style from "./style.scss";

export default function RechterOberschenkelMitHufteVdStehend2350(props) {
  return (
    <svg
      id="OSmitHuefte"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 313.5 362.3"
    >
      <g id="upper_leg_with_hip" data-name="upper leg with hip">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls9"
            d="M68.8,138.7h0c-1,12.4-3.3,14.3-2.6,20.8.7,7.4,4.3,10.8,8.9,18.3,8,13,10.8,24.7,14,38,2.5,10.3,2.3,15.4,3.2,36.9,1.7,39.8,2.9,33,4.3,69.1.6,15.8,3.4,31.4,3.4,47.2,24.7.4,49.4-2.9,74.1-2.8.3-.5.6-1,.9-1.5.5-.9.9-1.7,1.1-2.5.2-3.2.3-6.3.3-9.5-.8-7.6-2.7-21.2-4.3-50.9-1.9-36.3,0-34.9-2.6-43-2-6.2-1.8-12.9-1.6-19.7,4-2.8,9.7-2.2,12.4-6.9,1.9-3.4,3.5-8.2-2.2-22.6-3.4-8.5-7.7-12-8.2-13.9-2.3-9.2-.9-17.7,2-34.5,2.1-12.4,3.2-18.5,5.6-22.7,4-6.9,8.4-13.7,21.4-18.7,15.3-6.2,23.9-12.4,26.7-11.8,3.1,3.5.8,9.4,5.5,20.4,3.9,11.3,11.2,18.3,16.9,23.6,4.3,4.1,14.2,13.3,29.7,17.4,5.3,1.4,11.5,3.1,19.1,1.7,11.9-2.2,19.4-10.4,22.1-13.4,2.4-2.6,4.3-5.4,5.8-8.5-1.7-13.5-1.1-27.2,0-40.8-1.1-9.9-1.1-19.8-.6-29.7.5-28.9-.6-57.9-4.2-86.6-.4-2.9,2.9-4,4.8-2.8,0-1.5,0-3,0-4.4-.2-.3-.3-.7-.5-1-17.1-3-34.6-1.9-51.9-1.4-6.7,1.1-14.1,2.2-22.4,3.3-46.4,5.7-88.4,4.4-89.4,13.1,2,2.3,8.8,9.5,10.7,17.2-.1,5.6-4.3,7.9-3,14,.4,1.8,1.3,6,4.7,8.8,1.5,1.2,2.7,1.5,2.7,2.3,0,1.8-5.2,2.1-9,5-3.1,5.3-3.7,8.3-6,12.5-3.4,9.7-10.3,15.7-17.7,22.2-7.8,6.7-17.8,12.6-17.8,12.6-6.1,7-7.4,6.1-9.2,5.4-2.9.8-5.2.5-6.6.4-1.4,1.1-17.2.7-28,6-8.4,5.8-11.3,15.1-12,18-1.3,5.2,0,5.6-.7,15.6h0Z"
          />
        </g>
        <g id="hip">
          <path
            id="hip_1"
            data-name="hip 1"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls9"
            d="M301.9,24.7h0c5.6,6.7,11.6,11.1,17,15,.2.2.4.3.6.4,0-4.1.3-8.2.7-12.2-.6-10.1-1.2-20.2-.7-30.4,0-.2,0-.4,0-.6h-28.9c1,4,.3,8.8,3.3,15.8,2.1,4.9,4.7,8.1,8,12h0Z"
          />
          <path
            id="hip_2"
            data-name="hip 2"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
            d="M189.8,55.8c4.6,7.1,5.6,15.1,12.6,20.1,6,5,12,7,17,12,1,2,2,3,3,5,5,19,6,39,20,54,14,13,30,24,49,25,9,0,16-6,22.9-11.2"
          />
          <path
            id="hip_3"
            data-name="hip 3"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
            d="M314.7,89.9c-18.2-7-29.2-22-42.2-37-13-16-20-34-27.5-53.2"
          />
          <path
            id="hip_4"
            data-name="hip 4"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
            d="M290.2-2.5c2.2,17.4,13.2,32.4,27.5,41.6"
          />
          <path
            id="hip_5"
            data-name="hip 5"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
            d="M264.8,59.8c0,11.3.4,20.6,1,27,2.3,25.8,6.8,34.3,9,38,3.4,5.6,8.1,13.4,16,14,5.8.4,12.9-3,15-9,2.4-6.9-2.9-13.5-4-15-1.2-1.6-5.8-6.9-26-11-6-1.2-14.6-2.6-25-3"
          />
          <path
            id="hip_6"
            data-name="hip 6"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
            d="M241.8,19.7c34,84,11.5,68.2,13,68,1.4,0,2.2-11.8-1-23-3.2-11.1-9.3-18.1-11-20-7.5-8.2-15.6-11.4-22-14-6.7-2.7-17.7-7-32-5-9.3,1.3-16.3,4.7-20.7,7.2"
          />
          <path
            id="hip_7"
            data-name="hip 7"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
            d="M264.8,59.8c4.4,0,7.3-.6,7.3-8.3"
          />
          <path
            id="hip_8"
            data-name="hip 8"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls11"
            d="M247.1,2.9c6.2,18.8,14.4,35.1,25,48.5-3.9-5,.8-8.5-.3-11.7-1.6-4.9-2.7-8-2-12,1.1-6.2,5.4-7.4,5-12-.4-3.8-3.6-7.7-7-8-4.1-.4-5.8,4.8-10,5-2.5.1-6.1-1.5-10.7-9.8h0Z"
          />
          <path
            id="hip_9"
            data-name="hip 9"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
            d="M311.3,16.5c0,4.8,3.9,8.7,8.7,8.8-.4-5.8-.7-11.7-.7-17.5-4.5.4-8,3.9-8,8.8Z"
          />
          <path
            id="hip_10"
            data-name="hip 10"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
            d="M160.7-1.3c4.7,5.2,7.7,11.2,10.7,17.2,2,5-4,9-3,14,1,11,13,15,20,23,1,1,1,2,1.4,2.9"
          />
        </g>
        <g id="upper_leg" data-name="upper leg">
          <path
            id="upper_leg_1"
            data-name="upper leg 1"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
            d="M86.5,201.1c-3.6-8.7-5.5-13.3-9-19-6.5-10.5-9.2-9.9-11-16-1.8-6.3.9-7.7,2-22,1-13-1-14.1,1-21,.8-2.6,3.6-12.2,12-18,0,0,4.5-3.1,28-6,7.2,1.3,10.9-.3,13-2,1.8-4.2,30.3-15.3,44.3-51.1,4.2-3.4,8.8-2.8,9-5,3.1-2.3,8.9-5.8,17-7.2,12.7-2.2,22.6,2.4,26,4,4,1.9,15.9,7.7,21,21,4.8,12.4.7,23.6-2,31-2.2,6.1-4.9,13.5-12,18.2-6.4,4.3-48.1,13.9-51.3,40.1-1.3,6.2-3,15.4-4.4,26.6-4.2,33.7-2.2,59.1-1,75,2.1,29.6,4.1,66,6,115"
          />
          <path
            id="upper_leg_2"
            data-name="upper leg 2"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
            d="M86.6,201.1c5.5,13.3,11.6,165.2,13.5,168.6"
          />
          <path
            id="upper_leg_3"
            data-name="upper leg 3"
            class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
            d="M168.1,239.1c33-7.4,0-42.3.4-48.2"
          />
          <g id="inner_lines_upper_leg" data-name="inner lines upper leg">
            <path
              id="inner_lines_upper_leg_1"
              data-name="inner lines upper leg 1"
              class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
              d="M75.1,177.8c2.2-8.7,2.6-15.9,2.4-21-.2-5-.8-7.3-3-23-2.1-15.1-2.2-17.7-.4-20.5,3.2-5,10.6-7.5,16.4-6.5,5.9,1,6.8,5,15,7,3.9,1,7.9,1.9,11,0,6.2-3.9,2.7-15.7,6.3-16.8,3-.9,9.2,5.9,10.7,13.8,1.3,6.6-1.7,8.8-2,17-.2,6.5,1.5,11.3,5,21,2.1,5.9,5.6,14,11,23"
            />
            <line
              id="inner_lines_upper_leg_2"
              data-name="inner lines upper leg 2"
              class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls10"
              x1="134.8"
              y1="143.1"
              x2="115.8"
              y2="131.7"
            />
          </g>
        </g>
      </g>
      <g id="OSmitHuefte-2" data-name="OSmitHuefte">
        <path
          class="Hip2350Fill"
          style={{
            fill: props.colors.Hip2350Fill,
            stroke: props.colors.Hip2350Fill,
          }}
          d="M171.1,18.3c-2.8,5.3-4.4,10.2-2.9,14,1.4,4.8,3.9,7.1,6.9,8.5,5.8-5.2,13.4-8,23.3-7.7,21.1,1.2,34,11.2,41.9,26.5,3.7,17.6-1,33.7-15.2,48.2,2.1,11.5,5.2,21.5,9.8,29.4,8,12.2,19.4,22.7,36.5,30,14.9,8.3,27.9,8.5,41.2-4.2.1-72.9-1.4-110.7-.4-162.2-34.4-.4-89.5,0-152-1,4.6,5.1,8.3,10.4,11,18.5ZM267.5,102.1c15.3,2.7,41.8,5.9,37.6,27.7-2,8-15.9,11.7-21.9,5.7-9.2-8.3-14.6-20.8-15.7-33.4Z"
        />
        <path
          id="hip_joint"
          data-name="hip joint"
          class="Hipjoint2350Fill"
          style={{
            fill: props.colors.Hipjoint2350Fill,
            stroke: props.colors.Hipjoint2350Fill,
          }}
          d="M225.6,110c10.4,8.1,54.1-30,12-72.8-16.2-16.5-53.7-17.1-63.7-.4-6.6,11.2,31.9-8.7,53.8,13.7,21.8,22.4-6.6,56-2.2,59.5h0Z"
        />
        <path
          id="upper_leg_overlay"
          data-name="upper leg overlay"
          class="Oberschenkel2350Fill"
          style={{
            fill: props.colors.Oberschenkel2350Fill,
            stroke: props.colors.Oberschenkel2350Fill,
          }}
          d="M175.1,364.7l-75,4.3c-4.1-68.5-8.1-139.4-13.6-167.9-3.6-8.7-5.5-13.3-9-19-6.5-10.5-9.2-9.9-11-16-1.8-6.3.9-7.7,2-22,1-13-1-14.1,1-21,.8-2.6,3.6-12.2,12-18,0,0,4.5-3.1,28-6,7.2,1.3,10.9-.3,13-2,1.8-4.2,30.3-15.3,44.3-51.1,4.2-3.4,8.8-2.8,9-5,3.1-2.3,8.9-5.8,17-7.2,12.7-2.2,22.6,2.4,26,4,4,1.9,15.9,7.7,21,21,4.8,12.4.7,23.6-2,31-2.2,6.1-4.9,13.5-12,18.2-6.4,4.3-48.1,13.9-51.3,40.1-1.3,6.2-3,15.4-4.4,26.6-4.2,33.7-2.2,59.1-1,75,2.1,29.6,4.1,66,6,115h0Z"
        />
        <path
          id="neck_of_the_femur"
          data-name="neck of the femur"
          class="Schenkelhals2350Fill"
          style={{
            fill: props.colors.Schenkelhals2350Fill,
            stroke: props.colors.Schenkelhals2350Fill,
          }}
          d="M126.7,93.5l46.9,55.8c3.4-18,22.6-32.5,46.3-38.2l-53.1-65.1c-7.4,20.2-20.4,34.9-40.1,47.4Z"
        />
        <path
          id="trochanter_major_overlay"
          data-name="trochanter major overlay"
          class="Trochanter_major2350Fill"
          style={{
            fill: props.colors.Trochanter_major2350Fill,
            stroke: props.colors.Trochanter_major2350Fill,
          }}
          d="M109.5,99.1c-11.1.9-20.6,2.8-28,6-5.1,3.2-9.1,9.6-12,19.2-.5,1.7-.7,3.6-.5,5.4,1.9,20.5-5.7,32.2-2.4,36.4l27.5-23.6c9.2-7.9,14.7-19.3,15.1-31.5l.4-12h0Z"
        />
        <path
          id="trochantur_minor_overlay"
          data-name="trochantur minor overlay"
          class="Trochanter_minor2350Fill"
          style={{
            fill: props.colors.Trochanter_minor2350Fill,
            stroke: props.colors.Trochanter_minor2350Fill,
          }}
          d="M168.3,193.4c-.7,15.7-.9,31-.2,45.7,11.8-3.1,16.5-9.2,14.3-18.5-1.3-7.3-6.6-16.7-14.1-27.2h0Z"
        />
      </g>
      <g id="outlines">
        <path
          id="outlinepart2"
          class="Weichteilmantel2350Fill"
          style={{
            fill: props.colors.Weichteilmantel2350Fill,
            stroke: props.colors.Weichteilmantel2350Fill,
          }}
          d="M55.3-1.3c-17.2,53.2-20.7,71.7-21.5,90.2-3.2,115.8,6.4,212.4,13.8,279h3.8c-7.3-66.7-16.9-163.2-13.8-279,.8-18.5,4.3-37,21.5-90.2h-3.8Z"
        />
        <path
          id="outlinepart1"
          class="Weichteilmantel2350Fill"
          style={{
            fill: props.colors.Weichteilmantel2350Fill,
            stroke: props.colors.Weichteilmantel2350Fill,
          }}
          d="M274.8,369c16.7-53.7,31.5-120.7,37.7-161h3.1c-6.2,40.3-21,107.3-37.7,161h-3.1Z"
        />
      </g>
      <g id="outlinehighlights">
        <path
          id="outlinehighlight2"
          class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls12"
          d="M313.1,205c-6.2,40.3-21.1,107.3-37.8,161"
        />
        <path
          id="outlinehighlight1"
          class="Rechter_Oberschenkel_mit_Hufte_vd_stehend2350_cls12"
          d="M59.1-3.3c-17.2,53.2-20.7,71.7-21.5,90.2-3.2,115.8,6.4,212.4,13.8,279"
        />
      </g>
    </svg>
  );
}
