import { useState } from "react";
import DoseModalExtended from "./DoseModalExtended/DoseModalExtended";
import DoseModalBasic from "./DoseModalBasic/DoseModalBasic";
import { Modal } from "react-bootstrap";

export default function DoseModal({
  shown,
  onHide,
  exam_data,
  headerless = false,
}) {
  const [displayPreffered, setDisplayPreffered] = useState(
    window.conf.PATIENT_JOURNAL.DOSE_WINDOW_MODE
  );
  return (
    <Modal show={shown} onHide={onHide} size="xl">
      {!headerless && (
        <Modal.Header
          className="py-2 d-flex justify-content-center align-items-center"
          style={{ gap: "10px" }}
          closeButton
        >
          <h5>Dose Informations</h5>
          <i
            onClick={() => {
              if (displayPreffered === 2) {
                setDisplayPreffered(1);
              } else {
                setDisplayPreffered(2);
              }
            }}
            className={`ml-3 ${
              displayPreffered === 1 ? "far fa-square" : "fas fa-check-square"
            }`}
          />{" "}
          Detailed View
        </Modal.Header>
      )}
      <Modal.Body style={{ height: "90vh" }}>
        {displayPreffered === 1 ? (
          <DoseModalBasic exam_data={exam_data} />
        ) : (
          <DoseModalExtended exam_data={exam_data} />
        )}
      </Modal.Body>
    </Modal>
  );
}
