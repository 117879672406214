import style from "./style.scss";

function RechtesIliosakralgelenkVdOblLiegend1207(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 528.91 719.1"
    >
      <g id="underlay">
        <ellipse
          class="ISG1207Fill"
          style={{
            fill: props.colors.ISG1207Fill,
            stroke: props.colors.ISG1207Fill,
          }}
          cx="220.63"
          cy="280.62"
          rx="33.37"
          ry="116.82"
          transform="translate(-57.64 57.03) rotate(-13.04)"
        />
      </g>
      <g id="BG">
        <path
          id="BG43"
          class="iliosakralgelenk_right1207_cls3"
          d="M318.82,218.76c2.4-2.2-3.3-9.5,1-16.8,3.3-5.6,9.4-5.8,14.7-8.4,17.8-8.7,26.8-44.5,15.9-66.6-5.3-10.9-15.3-18-12.7-22.4,1.7-2.9,6.8-.7,11.5-3.1,11.9-6.2,16.8-40.6-1-56.5-15-13.4-41.5-9.8-44.1-2.6-2.1,5.7,11.2,12.8,8.5,21.4-.9,3-4.5,5.2-11.5,9.4-12.9,7.7-16.5,6.9-20.9,11.5-4.1,4.4-5,10.2-6.7,21.9-2.8,19.2.9,24.7-4.3,33.5-4.9,8.5-10.5,7.1-13,12.7-9.4,21,55.7,72.2,62.6,66Z"
        />
        <path
          id="BG42"
          class="iliosakralgelenk_right1207_cls3"
          d="M337.92,104.66c-8.5-23.9-32.1-33.6-50.8-24.3"
        />
        <path
          id="BG41"
          class="iliosakralgelenk_right1207_cls3"
          d="M337.92,104.56s9.9-13.9,0-37.7"
        />
        <path
          id="BG40"
          class="iliosakralgelenk_right1207_cls3"
          d="M302.32,123.46c19.2,4.1,19.2-9.1,19.2-12.6"
        />
        <path
          id="BG39"
          class="iliosakralgelenk_right1207_cls3"
          d="M309.72,217.76c11.4-55.2-21.6-72.4-55.7-62.2"
        />
        <path
          id="BG38"
          class="iliosakralgelenk_right1207_cls3"
          d="M451.42,664.76c-39.1,22.6-82.7,26.2-82.7,26.2-13.9,1.2-27.1.7-44,8.4-10.8,5-12.1,8.3-23,12.6-3.2,1.2-21.4,8.2-42.9,5.2-24.8-3.5-24.6-16.3-49.2-22-22.2-5.2-25.1,4.5-52.4,1-13.6-1.7-41.6-5.2-53.4-25.1-3.3-5.5-3.9-18-5.2-42.9-.6-11.7-.5-21.2,4.2-31.4,2.5-5.4,5.1-8.5,7.3-11.5,9.8-13.2,13-26.9,16.8-42.9,1.6-6.8,6.8-31.4,0-82.7-3.7-27.9-9-65.6-28.3-109.9-1.1-2.8-13-24.6-36.6-68.1-15-27.8-24.6-45.2-36.6-70.2-15-31.1-22.6-47-24.1-60.7-3.4-30.3,6.6-54.4,13.6-71.2,8.2-19.6,14.3-34.3,30.4-44,16.7-10.1,34.4-8.9,40.8-8.4,6.5.5,21.9,2,35.6,12.6,8.3,6.4,8.2,10.1,19.9,25.1,12.9,16.4,14.7,14,28.3,30.4,13.7,16.4,9.9,16.5,28.3,40.8,11.6,15.3,12,18.7,15.7,25.1,7.3,12.6-6.3,14.4-8.4,32.5-1.3,10.7,1.1,11.6,0,25.1-1.1,13.2-3.8,17.7-5.2,26.2-2.5,15.3,1.9,27.1,10.5,50.3,10,26.9,15,26.5,19.9,47.1,5.7,24.1,1.5,36,0,39.8-3.7,9-7,8.9-10.5,17.8-5.8,14.6-2.2,28.7,1,40.8,6.1,23.7,18.8,39.2,23,44,8.8,10,17.4,15.8,34.6,27.2,19.8,13.2,21.5,11.4,38.7,23,21.9,14.8,36.7,29.4,41.9,34.6,17.1,17.5,13.9,19.2,25.1,28.3,20.2,16.3,43.1,20.7,55.5,23,31.6,5.7,48.5-1.4,51.3,5.2,3.5,8.4-20.2,27.5-39.9,38.7Z"
        />
        <path
          id="BG37"
          class="iliosakralgelenk_right1207_cls3"
          d="M56.82,178.96C21.92,139.76,4.92,48.76,86.12,27.16"
        />
        <path
          id="BG36"
          class="iliosakralgelenk_right1207_cls3"
          d="M169.92,388.36c17.9-86.8-65.9-144.6-113.1-209.4"
        />
        <path
          id="BG35"
          class="iliosakralgelenk_right1207_cls3"
          d="M201.92,485.06c-30.4-31.4-29.5-69.5-32-96.7"
        />
        <path
          id="BG34"
          class="iliosakralgelenk_right1207_cls3"
          d="M230.62,598.86c-38.4-6.8,0-77.5-28.7-113.8"
        />
        <path
          id="BG33"
          class="iliosakralgelenk_right1207_cls3"
          d="M212.22,569.46c-64.2,5.3-66.9-78.2-21.9-98.7"
        />
        <path
          id="BG32"
          class="iliosakralgelenk_right1207_cls3"
          d="M172.32,377.86c-42.5-8.5-77.7-58.1-110.3-96.3"
        />
        <path
          id="BG31"
          class="iliosakralgelenk_right1207_cls3"
          d="M308.72,708.76c-10.3-103.7-219.4-175.4-200.6-77.4"
        />
        <path
          id="BG30"
          class="iliosakralgelenk_right1207_cls3"
          d="M110.22,585.26c15.1-24.8,44.9-34.8,59.7-31.9"
        />
        <path
          id="BG29"
          class="iliosakralgelenk_right1207_cls3"
          d="M528.62,220.66c-1.5-2.4-3.1-4.8-4.9-7.1-8.6-11.1-23.4-24.5-27.2-22-1.9,1.3.3,5.7,1,16.8.5,7.4,1.2,18-3.1,28.3-.4,1-4.3,9.9-12.6,16.8-7.8,6.4-18.5,10.1-45,9.4-30.9-.8-52.8-1.4-77.5-14.7-18.4-9.9-30.3-22.5-38.7-31.4-14.1-14.9-24-30.3-46.1-49.2-4.1-3.5-11.3-9.4-22-11.5-4.7-.9-16.4-2.4-26.2,4.2-18.6,12.5-10.6,18.4-8.4,27.2,2.1,8.5,8.1,12,6.3,17.8-1.3,4.3-5.2,4.6-8.4,8.4-6,7.1-2.8,18.3,0,30.4,7.8,33.9,1.9,30.2,8.4,49.2,7.6,22.1,13.7,21.5,17.8,40.8,2.1,9.4,4.8,23.4-1,37.7-5.3,13-11.5,11.1-17.8,24.1-9.5,19.7-3.3,41.3-1,49.2,3.1,10.6,7.5,18.1,10.5,23,2.2,3.7,9.3,14.9,22,26.2,9.6,8.4,17.8,13,28.3,18.8,12.2,6.8,18.4,10.2,23,10.5,19.4,1.2,35.3-20.6,44-32.5,2.6-3.6,13.6-19.1,19.9-41.9,4-14.4,1.9-16.5,6.3-28.3,2.1-5.9,8.1-21.3,23-34.6,4.3-3.7,16.9-15,35.6-18.8,9.2-1.8,14.3-.7,23-5.2,8.8-4.6,8.1-8.1,16.8-12.6,9.7-5,19.4-5.1,27.2-5.2,14.6-.1,16.9,3.5,22,2.1,1-.3,2-.7,2.9-1.3"
        />
        <path
          id="BG28"
          class="iliosakralgelenk_right1207_cls3"
          d="M349.92,287.86c-47.4,0-78.8,30.8-78.8,64.9"
        />
        <path
          id="BG27"
          class="iliosakralgelenk_right1207_cls3"
          d="M425.32,296.26c-30.3,43.1-93.3,31.6-102.8-4.6"
        />
        <path
          id="BG26"
          class="iliosakralgelenk_right1207_cls3"
          d="M355.22,349.66c-30,0-54.4,2.8-54.4,6.3"
        />
        <path
          id="BG25"
          class="iliosakralgelenk_right1207_cls3"
          d="M355.22,355.96c-26.7,0-48.2,3.2-48.2,7.3"
        />
        <path
          id="BG24"
          class="iliosakralgelenk_right1207_cls3"
          d="M282.92,390.56c21.9,0,39.6-3.8,39.6-8.4"
        />
        <path
          id="BG23"
          class="iliosakralgelenk_right1207_cls3"
          d="M277.72,401.96c24.8,0,44.8-5.1,44.8-11.5"
        />
        <path
          id="BG22"
          class="iliosakralgelenk_right1207_cls3"
          d="M264.22,437.56c20.2,0,36.6-1.4,36.6-3.1"
        />
        <path
          id="BG21"
          class="iliosakralgelenk_right1207_cls3"
          d="M264.12,444.86c20.2,0,38.6.6,38.6-5.2"
        />
        <path
          id="BG20"
          class="iliosakralgelenk_right1207_cls3"
          d="M264.12,501.66c21.3,0,38.6-6.6,38.6-14.8"
        />
        <path
          id="BG19"
          class="iliosakralgelenk_right1207_cls3"
          d="M258.82,459.66c13.6,0,24.6-2.8,24.6-6.3"
        />
        <path
          id="BG18"
          class="iliosakralgelenk_right1207_cls3"
          d="M365.72,259.86c61.3,23.1,130.9,13.3,130.9-16"
        />
        <path
          id="BG17"
          class="iliosakralgelenk_right1207_cls3"
          d="M491.52,344.86c17.9,0,32.3-11,32.3-24.5"
        />
        <path
          id="BG16"
          class="iliosakralgelenk_right1207_cls3"
          d="M455.02,280.56c-13.1,0-23.8,11.7-23.8,17.1s10.6,9.8,23.8,9.8c13.1,0,23.8-4.4,23.8-9.8s-10.7-17.1-23.8-17.1Z"
        />
        <path
          id="BG15"
          class="iliosakralgelenk_right1207_cls3"
          d="M331.12,308.06c-.3,9.4-10.7,16.9-23.9,16.9-13.2,0-19.6,2.1-19.6-7.3s14.3-19.4,22.9-21.6c11.7-3,20.7,10.5,20.6,12h0Z"
        />
        <ellipse
          id="BG14"
          class="iliosakralgelenk_right1207_cls3"
          cx="382.42"
          cy="340.76"
          rx="16.8"
          ry="8.9"
        />
        <path
          id="BG13"
          class="iliosakralgelenk_right1207_cls3"
          d="M275.12,370.66c-17,5.7-34.8,1.7-25.7-10.5,7.8-10.4,15.2-1,25.7-10.5,10.5-9.5,25.7,4.7,25.7,10.5,0,5.7-12.3,5.9-25.7,10.5Z"
        />
        <ellipse
          id="BG12"
          class="iliosakralgelenk_right1207_cls3"
          cx="348.42"
          cy="389.46"
          rx="17.3"
          ry="12.6"
        />
        <ellipse
          id="BG11"
          class="iliosakralgelenk_right1207_cls3"
          cx="245.82"
          cy="401.96"
          rx="15.2"
          ry="11.3"
        />
        <circle
          id="BG10"
          class="iliosakralgelenk_right1207_cls3"
          cx="237.92"
          cy="451.26"
          r="11.5"
        />
        <circle
          id="BG9"
          class="iliosakralgelenk_right1207_cls3"
          cx="322.12"
          cy="436.06"
          r="11.5"
        />
        <ellipse
          id="BG8"
          class="iliosakralgelenk_right1207_cls3"
          cx="317.52"
          cy="486.36"
          rx="10.5"
          ry="7.9"
        />
        <path
          id="BG7"
          class="iliosakralgelenk_right1207_cls3"
          d="M349.82,242.56c23.1,16.7,28.2,13.2,32.5,14.8,25.5,9.8,41.4,5.4,49.2,5.4,23,0,47.4.1,58.6-15.7,7.3-10.3,8.2-26.1,8.4-29.3.8-13.9-3.1-22.7,0-24.1,5.9-2.6,20.2,22.4,25.1,19.9,3.1-1.6,2.7-14.3-4.2-22-7.1-8-17.5-6.7-18.8-12.6-.7-3.2,2.1-4.9,5.2-11.5,2.1-4.5,2.8-8.1,4.2-18.8,3.7-28.9,1.7-27.3,4.2-36.6,2.8-10.9,5.7-13.6,5.2-22-.3-5.6-1.7-6.6-3.1-15.7-.9-6.2-.9-10.7-1-19.9-.1-12.9-.1-19.5,2.1-24.1,3.1-6.5,6.8-7.3,6.3-10.5-1-7-19.8-9.5-27.2-10.5-15.7-2-22.8-.6-42.9,0-24.9.7-37.3,1.1-52.4,0-13.4-1-22.7-2.5-31.4,3.1-2.8,1.7-6.1,4.6-11.5,5.2-6.9.7-12.3-2.8-14.7-4.2-12.4-7.2-17.9-1-48.2-6.3-19-3.3-25.7-7.4-38.7-4.2-3.3.8-12.6,3.1-13.6,8.4-1,5.2,6.3,10.5,10.5,13.6,8.8,6.5,17.7,9.5,31.4,12.6,12.4,2.8,16.2,2.9,34.6,6.3,25.5,4.7,28.6,6.4,31.4,9.4,6.8,7.3,9.7,20.3,6.3,30.4-2.7,8.2-8.4,10.7-7.3,16.8,1.2,6.8,9,7.5,9.4,13.6.5,7.3-10.2,13.3-12.6,14.7-8.9,5.1-17.8,4.5-20.9,4.2-8.3-.9-13.2-4.4-23-10.5-18.9-11.8-28.7-17.9-40.8-22-6.7-2.3-24.6-8.4-29.3-2.1-4.3,5.7,4.4,18.7,5.2,19.9,7,10.3,15.4,13,38.7,24.1,28.8,13.7,35,18.6,37.7,20.9,10,8.5,20.3,17.1,20.9,29.3.7,13.5-11.2,20.4-7.3,30.4,1.6,4.1,12.8,12.8,21.8,19.6Z"
        />
        <path
          id="BG6"
          class="iliosakralgelenk_right1207_cls3"
          d="M517.52,109.86c-92.6,0-167.5-4.2-167.5-9.4"
        />
        <path
          id="BG5"
          class="iliosakralgelenk_right1207_cls3"
          d="M507.62,148.86c-84.2,0-152.4-4.3-152.4-9.6"
        />
        <path
          id="BG4"
          class="iliosakralgelenk_right1207_cls3"
          d="M507.72,161.16c-83.9,0-151.8-5.5-151.8-12.3"
        />
        <path
          id="BG3"
          class="iliosakralgelenk_right1207_cls3"
          d="M496.52,229.96c-64.8,13.9-165.4-8.8-165.4-19.6"
        />
        <path
          id="BG2"
          class="iliosakralgelenk_right1207_cls3"
          d="M517.52,34.96c-86.7,0-156.9-7.7-156.9-17.2"
        />
        <path
          id="BG1"
          class="iliosakralgelenk_right1207_cls3"
          d="M517.52,26.26c-79.4,0-143.6-7.1-143.6-15.9"
        />
      </g>
      <g id="iliac">
        <path
          id="iliac9"
          class="iliosakralgelenk_right1207_cls1"
          d="M451.29,665.46c-39.1,22.6-82.7,26.2-82.7,26.2-13.9,1.2-27.1.7-44,8.4-10.8,5-12.1,8.3-23,12.6-3.2,1.2-21.4,8.2-42.9,5.2-24.8-3.5-24.6-16.3-49.2-22-22.2-5.2-25.1,4.5-52.4,1-13.6-1.7-41.6-5.2-53.4-25.1-3.3-5.5-3.9-18-5.2-42.9-.6-11.7-.5-21.2,4.2-31.4,2.5-5.4,5.1-8.5,7.3-11.5,9.8-13.2,13-26.9,16.8-42.9,1.6-6.8,6.8-31.4,0-82.7-3.7-27.9-9-65.6-28.3-109.9-1.1-2.8-13-24.6-36.6-68.1-15-27.8-24.6-45.2-36.6-70.2-15-31.1-22.6-47-24.1-60.7-3.4-30.3,6.6-54.4,13.6-71.2,8.2-19.6,14.3-34.3,30.4-44,16.7-10.1,34.4-8.9,40.8-8.4,6.5.5,21.9,2,35.6,12.6,8.3,6.4,8.2,10.1,19.9,25.1,12.9,16.4,14.7,14,28.3,30.4,13.7,16.4,9.9,16.5,28.3,40.8,11.6,15.3,12,18.7,15.7,25.1,7.3,12.6-6.3,14.4-8.4,32.5-1.3,10.7,1.1,11.6,0,25.1-1.1,13.2-3.8,17.7-5.2,26.2-2.5,15.3,1.9,27.1,10.5,50.3,10,26.9,15,26.5,19.9,47.1,5.7,24.1,1.5,36,0,39.8-3.7,9-7,8.9-10.5,17.8-5.8,14.6-2.2,28.7,1,40.8,6.1,23.7,18.8,39.2,23,44,8.8,10,17.4,15.8,34.6,27.2,19.8,13.2,21.5,11.4,38.7,23,21.9,14.8,36.7,29.4,41.9,34.6,17.1,17.5,13.9,19.2,25.1,28.3,20.2,16.3,43.1,20.7,55.5,23,31.6,5.7,48.5-1.4,51.3,5.2,3.5,8.4-20.2,27.5-39.9,38.7Z"
        />
        <path
          id="iliac8"
          class="iliosakralgelenk_right1207_cls1"
          d="M56.69,179.66C21.79,140.46,4.79,49.46,85.99,27.86"
        />
        <path
          id="iliac7"
          class="iliosakralgelenk_right1207_cls1"
          d="M169.79,389.06c17.9-86.8-65.9-144.6-113.1-209.4"
        />
        <path
          id="iliac6"
          class="iliosakralgelenk_right1207_cls1"
          d="M201.79,485.76c-30.4-31.4-29.5-69.5-32-96.7"
        />
        <path
          id="iliac5"
          class="iliosakralgelenk_right1207_cls1"
          d="M230.49,599.56c-38.4-6.8,0-77.5-28.7-113.8"
        />
        <path
          id="iliac4"
          class="iliosakralgelenk_right1207_cls1"
          d="M212.09,570.16c-64.2,5.3-66.9-78.2-21.9-98.7"
        />
        <path
          id="iliac3"
          class="iliosakralgelenk_right1207_cls1"
          d="M172.19,378.56c-42.5-8.5-77.7-58.1-110.3-96.3"
        />
        <path
          id="iliac2"
          class="iliosakralgelenk_right1207_cls1"
          d="M308.59,709.46c-10.3-103.7-219.4-175.4-200.6-77.4"
        />
        <path
          id="iliac1"
          class="iliosakralgelenk_right1207_cls1"
          d="M110.09,585.96c15.1-24.8,44.9-34.8,59.7-31.9"
        />
      </g>
      <g id="sacrum">
        <path
          id="sacrum22"
          class="iliosakralgelenk_right1207_cls1"
          d="M528.49,221.36c-1.5-2.4-3.1-4.8-4.9-7.1-8.6-11.1-23.4-24.5-27.2-22-1.9,1.3.3,5.7,1,16.8.5,7.4,1.2,18-3.1,28.3-.4,1-4.3,9.9-12.6,16.8-7.8,6.4-18.5,10.1-45,9.4-30.9-.8-52.8-1.4-77.5-14.7-18.4-9.9-30.3-22.5-38.7-31.4-14.1-14.9-24-30.3-46.1-49.2-4.1-3.5-11.3-9.4-22-11.5-4.7-.9-16.4-2.4-26.2,4.2-18.6,12.5-10.6,18.4-8.4,27.2,2.1,8.5,8.1,12,6.3,17.8-1.3,4.3-5.2,4.6-8.4,8.4-6,7.1-2.8,18.3,0,30.4,7.8,33.9,1.9,30.2,8.4,49.2,7.6,22.1,13.7,21.5,17.8,40.8,2.1,9.4,4.8,23.4-1,37.7-5.3,13-11.5,11.1-17.8,24.1-9.5,19.7-3.3,41.3-1,49.2,3.1,10.6,7.5,18.1,10.5,23,2.2,3.7,9.3,14.9,22,26.2,9.6,8.4,17.8,13,28.3,18.8,12.2,6.8,18.4,10.2,23,10.5,19.4,1.2,35.3-20.6,44-32.5,2.6-3.6,13.6-19.1,19.9-41.9,4-14.4,1.9-16.5,6.3-28.3,2.1-5.9,8.1-21.3,23-34.6,4.3-3.7,16.9-15,35.6-18.8,9.2-1.8,14.3-.7,23-5.2,8.8-4.6,8.1-8.1,16.8-12.6,9.7-5,19.4-5.1,27.2-5.2,14.6-.1,16.9,3.5,22,2.1,1-.3,2-.7,2.9-1.3"
        />
        <path
          id="sacrum21"
          class="iliosakralgelenk_right1207_cls1"
          d="M349.79,288.56c-47.4,0-78.8,30.8-78.8,64.9"
        />
        <path
          id="sacrum20"
          class="iliosakralgelenk_right1207_cls1"
          d="M425.19,296.96c-30.3,43.1-93.3,31.6-102.8-4.6"
        />
        <path
          id="sacrum19"
          class="iliosakralgelenk_right1207_cls1"
          d="M355.09,350.36c-30,0-54.4,2.8-54.4,6.3"
        />
        <path
          id="sacrum18"
          class="iliosakralgelenk_right1207_cls1"
          d="M355.09,356.66c-26.7,0-48.2,3.2-48.2,7.3"
        />
        <path
          id="sacrum17"
          class="iliosakralgelenk_right1207_cls1"
          d="M282.79,391.26c21.9,0,39.6-3.8,39.6-8.4"
        />
        <path
          id="sacrum16"
          class="iliosakralgelenk_right1207_cls1"
          d="M277.59,402.66c24.8,0,44.8-5.1,44.8-11.5"
        />
        <path
          id="sacrum15"
          class="iliosakralgelenk_right1207_cls1"
          d="M264.09,438.26c20.2,0,36.6-1.4,36.6-3.1"
        />
        <path
          id="sacrum14"
          class="iliosakralgelenk_right1207_cls1"
          d="M263.99,445.56c20.2,0,38.6.6,38.6-5.2"
        />
        <path
          id="sacrum13"
          class="iliosakralgelenk_right1207_cls1"
          d="M263.99,502.36c21.3,0,38.6-6.6,38.6-14.8"
        />
        <path
          id="sacrum12"
          class="iliosakralgelenk_right1207_cls1"
          d="M258.69,460.36c13.6,0,24.6-2.8,24.6-6.3"
        />
        <path
          id="sacrum11"
          class="iliosakralgelenk_right1207_cls1"
          d="M365.59,260.56c61.3,23.1,130.9,13.3,130.9-16"
        />
        <path
          id="sacrum10"
          class="iliosakralgelenk_right1207_cls1"
          d="M491.39,345.56c17.9,0,32.3-11,32.3-24.5"
        />
        <path
          id="sacrum9"
          class="iliosakralgelenk_right1207_cls1"
          d="M454.89,281.26c-13.1,0-23.8,11.7-23.8,17.1s10.6,9.8,23.8,9.8,23.8-4.4,23.8-9.8-10.7-17.1-23.8-17.1Z"
        />
        <path
          id="sacrum8"
          class="iliosakralgelenk_right1207_cls1"
          d="M330.99,308.76c-.3,9.4-10.7,16.9-23.9,16.9s-19.6,2.1-19.6-7.3,14.3-19.4,22.9-21.6c11.7-3,20.7,10.5,20.6,12h0Z"
        />
        <ellipse
          id="sacrum7"
          class="iliosakralgelenk_right1207_cls1"
          cx="382.29"
          cy="341.46"
          rx="16.8"
          ry="8.9"
        />
        <path
          id="sacrum6"
          class="iliosakralgelenk_right1207_cls1"
          d="M274.99,371.36c-17,5.7-34.8,1.7-25.7-10.5,7.8-10.4,15.2-1,25.7-10.5s25.7,4.7,25.7,10.5-12.3,5.9-25.7,10.5Z"
        />
        <ellipse
          id="sacrum5"
          class="iliosakralgelenk_right1207_cls1"
          cx="348.29"
          cy="390.16"
          rx="17.3"
          ry="12.6"
        />
        <ellipse
          id="sacrum4"
          class="iliosakralgelenk_right1207_cls1"
          cx="245.69"
          cy="402.66"
          rx="15.2"
          ry="11.3"
        />
        <circle
          id="sacrum3"
          class="iliosakralgelenk_right1207_cls1"
          cx="237.79"
          cy="451.96"
          r="11.5"
        />
        <circle
          id="sacrum2"
          class="iliosakralgelenk_right1207_cls1"
          cx="321.99"
          cy="436.76"
          r="11.5"
        />
        <ellipse
          id="sacrum1"
          class="iliosakralgelenk_right1207_cls1"
          cx="317.39"
          cy="487.06"
          rx="10.5"
          ry="7.9"
        />
      </g>
      <g id="spine">
        <path
          id="spine12"
          class="iliosakralgelenk_right1207_cls1"
          d="M303.99,41.06c-2.1,5.7,11.2,12.8,8.5,21.4-.9,3-4.5,5.2-11.5,9.4-12.9,7.7-16.5,6.9-20.9,11.5-4.1,4.4-5,10.2-6.7,21.9-2.8,19.2.9,24.7-4.3,33.5-4.9,8.5-10.5,7.1-13,12.7-9.4,21,55.7,72.2,62.6,66,2.4-2.2-3.3-9.5,1-16.8,3.3-5.6,9.4-5.8,14.7-8.4,17.8-8.7,26.8-44.5,15.9-66.6-5.3-10.9-15.3-18-12.7-22.4,1.7-2.9,6.8-.7,11.5-3.1"
        />
        <path
          id="spine11"
          class="iliosakralgelenk_right1207_cls1"
          d="M337.79,103.36c-8.5-23.9-32.1-33.6-50.8-24.3"
        />
        <path
          id="spine10"
          class="iliosakralgelenk_right1207_cls1"
          d="M337.79,103.26s9.9-13.9,0-37.7"
        />
        <path
          id="spine9"
          class="iliosakralgelenk_right1207_cls1"
          d="M302.19,122.16c19.2,4.1,19.2-9.1,19.2-12.6"
        />
        <path
          id="spine8"
          class="iliosakralgelenk_right1207_cls1"
          d="M309.59,216.46c11.4-55.2-21.6-72.4-55.7-62.2"
        />
        <path
          id="spine7"
          class="iliosakralgelenk_right1207_cls1"
          d="M523.49,212.26c3.1-1.6,2.7-14.3-4.2-22-7.1-8-17.5-6.7-18.8-12.6-.7-3.2,2.1-4.9,5.2-11.5,2.1-4.5,2.8-8.1,4.2-18.8,3.7-28.9,1.7-27.3,4.2-36.6,2.8-10.9,5.7-13.6,5.2-22-.3-5.6-1.7-6.6-3.1-15.7-.9-6.2-.9-10.7-1-19.9-.1-12.9-.1-19.5,2.1-24.1,3.1-6.5,6.8-7.3,6.3-10.5-1-7-19.8-9.5-27.2-10.5-15.7-2-22.8-.6-42.9,0-24.9.7-37.3,1.1-52.4,0-13.4-1-22.7-2.5-31.4,3.1-2.8,1.7-6.1,4.6-11.5,5.2-6.9.7-12.3-2.8-14.7-4.2-12.4-7.2-17.9-1-48.2-6.3-19-3.3-25.7-7.4-38.7-4.2-3.3.8-12.6,3.1-13.6,8.4-1,5.2,6.3,10.5,10.5,13.6,8.8,6.5,17.7,9.5,31.4,12.6,12.4,2.8,16.2,2.9,34.6,6.3,25.5,4.7,28.6,6.4,31.4,9.4,6.8,7.3,9.7,20.3,6.3,30.4-2.7,8.2-8.4,10.7-7.3,16.8,1.2,6.8,9,7.5,9.4,13.6.5,7.3-10.2,13.3-12.6,14.7-8.9,5.1-17.8,4.5-20.9,4.2-8.3-.9-13.2-4.4-23-10.5-18.9-11.8-28.7-17.9-40.8-22-6.7-2.3-24.6-8.4-29.3-2.1-4.3,5.7,4.4,18.7,5.2,19.9,7,10.3,15.4,13,38.7,24.1,28.8,13.7,35,18.6,37.7,20.9,10,8.5,20.3,17.1,20.9,29.3.7,13.5-11.2,20.4-7.3,30.4"
        />
        <path
          id="spine6"
          class="iliosakralgelenk_right1207_cls1"
          d="M517.39,108.56c-92.6,0-167.5-4.2-167.5-9.4"
        />
        <path
          id="spine5"
          class="iliosakralgelenk_right1207_cls1"
          d="M507.49,147.56c-84.2,0-152.4-4.3-152.4-9.6"
        />
        <path
          id="spine4"
          class="iliosakralgelenk_right1207_cls1"
          d="M507.59,159.86c-83.9,0-151.8-5.5-151.8-12.3"
        />
        <path
          id="spine3"
          class="iliosakralgelenk_right1207_cls1"
          d="M523.62,214.56c-68.14,39.4-192.64,5.3-192.64-5.5"
        />
        <path
          id="spine2"
          class="iliosakralgelenk_right1207_cls1"
          d="M517.39,33.66c-86.7,0-156.9-7.7-156.9-17.2"
        />
        <path
          id="spine1"
          class="iliosakralgelenk_right1207_cls1"
          d="M517.39,24.96c-79.4,0-143.6-7.1-143.6-15.9"
        />
      </g>
    </svg>
  );
}

export default RechtesIliosakralgelenkVdOblLiegend1207;
