import { MakeGetObject } from "../AuthHeader";

export async function loadSystems(
  keycloak,
  pageSize = 10,
  pageNr = 1,
  searchObj = undefined,
  sortObj = undefined
) {
  const getPagination = () => {
    return `?page_size=${pageSize}&page_number=${pageNr}`;
  };
  const getSearching = () => {
    if (searchObj !== undefined) {
      return `&search=${encodeURIComponent(JSON.stringify(searchObj))}`;
    } else {
      return "";
    }
  };
  const getSorting = () => {
    if (sortObj !== undefined) {
      return `&sort=${encodeURIComponent(JSON.stringify(sortObj))}`;
    } else {
      return "";
    }
  };
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/pmed/systems${getPagination()}${getSearching()}${getSorting()}`;
  return fetch(url, MakeGetObject(keycloak)).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      throw new Error(resp.status);
    }
  });
}
