import style from "./style.scss";

export default function BrustbeinDvOblLiegend2028(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 502.74 839.77">
      <g id="sternum_recording" data-name="sternum recording">
        <g id="sternum">
          <path
            id="sternum_1"
            data-name="sternum 1"
            class="Brustbein_dv_obl_liegend2028_cls13"
            style={{
              fill: props.colors.Brustbein_dv_obl_liegend2028_cls13,
              stroke: props.colors.Brustbein_dv_obl_liegend2028_cls13,
            }}
            d="M283.73,294.6c5.36-2.11,2.63-12.9,9.57-16.41,4.27-2.16,7.71.73,10.94-2.05,2.74-2.36,2.33-6.24,2.73-10.94.42-4.93,1.82-11.74,6.15-19.82,4.3-2.38,10.39-5.7,17.77-9.57,15.27-8.01,19.04-9.45,25.29-14.35,6.95-5.45,11.26-10.71,14.27-14.42,9-11.11,12.33-19.98,13.76-24.54,1.71-5.49,3.26-10.67.68-13.67-2.9-3.38-7.7-.14-12.3-3.42-5.55-3.95-1.96-11.08-7.52-18.46-3.85-5.1-6.65-3.14-14.35-8.89-11.04-8.24-9.14-15.15-16.41-17.77-7.59-2.74-10.69,4.42-25.76,5.91-3.62.36-3.81-.02-24.14-2.49-24.1-2.93-23.48-2.36-26.75-3.49-14.22-4.93-18.08-13.02-25.89-10.86-4.34,1.2-3.35,3.75-10.94,8.2-2.6,1.53-9.43,5.54-18.46,6.05-7.98.46-10.71-2.21-13.66.1-3.64,2.85-.46,7.71-3.42,14.35-3.86,8.68-12.33,7.25-16.41,15.04-1.97,3.76-.79,5.61,1.37,21.19,3.06,22.04,1.73,25.73,6.15,31.44,1.29,1.67,1.86,1.94,15.04,10.25,19.47,12.28,21.15,13.54,23.24,16.41,4.25,5.82,7.4,14.46,6.84,23.24-.61,9.41-5.07,12.37-2.73,18.46.98,2.56,1.92,2.44,11.62,11.62,7.09,6.71,7.5,7.64,10.25,8.89,2.02.91,8.93,3.63,15.04.68,5.04-2.43,4.29-6.19,8.2-7.52,7.27-2.47,13.95,9.15,19.82,6.84h.01Z"
          />
          <path
            id="sternum_2"
            data-name="sternum 2"
            class="Brustbein_dv_obl_liegend2028_cls13"
            style={{
              fill: props.colors.Brustbein_dv_obl_liegend2028_cls13,
              stroke: props.colors.Brustbein_dv_obl_liegend2028_cls13,
            }}
            d="M237.93,684.22c3.98,2.05,11.86-3.42,17.77-7.52,5.21-3.61,7.52-6.39,11.62-6.15,2.95.17,4,1.75,8.2,3.42,6.13,2.43,7.84.3,11.62,2.05,6.7,3.1,6.94,11.19,9.57,10.94,2.82-.28,1.45-9.43,7.52-19.14,4.53-7.25,7.64-5.91,10.94-12.3,3.03-5.89,2.7-11.67,2.05-23.24-.63-11.09-2.29-13.25-.68-19.82.84-3.43,2.05-8.34,6.15-12.3,3.35-3.23,5.7-3,7.52-6.15,2.4-4.15-.28-6.97.68-11.62,1.85-8.9,13.69-8.56,16.41-16.41,2.06-5.95-3.76-9-9.57-23.24-3.55-8.7-9.34-22.9-4.1-34.86,3.52-8.03,8.64-7.36,9.57-13.67,1.31-8.94-8.83-11.21-15.06-28.1-3.21-8.71-3.3-15.65-3.39-23.16-.08-6.63.69-9.63,2.27-12.2,2.72-4.42,5.82-4.32,6.62-7.62,1.24-5.13-5.75-7.45-6.15-14.35-.4-6.94,6.4-9.25,5.47-15.04-.79-4.96-6.11-5.29-11.62-10.94-5.65-5.78-6.96-13.43-9.57-28.71-2.27-13.31-3.41-19.97-.68-26.66,2.65-6.49,7.06-10.08,5.47-12.99-1.54-2.8-6.15-.37-12.99-3.42-7.17-3.2-6.59-7.88-12.99-9.57-2.72-.72-5.92-.25-12.3.68-5.12.75-6.06,1.35-10.25,2.05-6.99,1.17-12.58.9-16.41.68-12.09-.67-13.65-3.41-17.77-1.37-5.08,2.51-3.67,7.13-9.57,10.94-6.2,4.01-11.49,1.32-12.99,4.1-2.04,3.79,7.7,9,12.99,20.51,5.01,10.9,5.46,26.18-.67,37.35-4.06,7.4-8.32,8.12-9.58,15.28-1.44,8.22,3.58,10.69,2.05,18.46-1.79,9.07-9.32,9.18-10.94,17.77-1.42,7.53,4.11,8.85,5.47,20.51.26,2.2,1.41,13.8-4.78,24.61-4.23,7.38-7.26,6.32-9.57,12.3-2.81,7.29.77,11.23,2.05,24.61.87,9.06,1.59,16.62-1.37,24.61-4.74,12.78-14.1,13.73-14.35,23.24-.17,6.42,4.07,6.26,6.84,16.41,3.66,13.43-1.91,20.63,3.42,25.97,3.99,4,8.36,1.22,11.62,5.47,4.43,5.77-1.47,13.69,2.73,17.77,2.89,2.8,6.42-.21,12.99,2.05,8.26,2.84,12.29,10.91,12.99,12.3,2.17,4.33,1.97,7.17,2.05,21.87.11,20.37.54,23.48,2.73,24.61h-.03Z"
          />
          <path
            id="sternum_3"
            data-name="sternum 3"
            class="Brustbein_dv_obl_liegend2028_cls13"
            style={{
              fill: props.colors.Brustbein_dv_obl_liegend2028_cls13,
              stroke: props.colors.Brustbein_dv_obl_liegend2028_cls13,
            }}
            d="M240.66,690.37c-1.01,5.15-2.81,12.84-6.15,21.87-2.51,6.79-3.54,7.97-7.52,17.77-5.08,12.52-7.64,18.91-8.2,25.29-.78,8.83.99,16.14,2.05,20.51,2.09,8.61,4.02,9.81,6.15,19.82,1.63,7.66,2.45,11.49,1.37,16.41-1.46,6.61-4.6,7.78-4.78,13.67-.14,4.3,1.31,10.92,5.47,12.3,4.95,1.65,10.73-5.12,11.62-6.15,3.6-4.21,2.93-6.64,6.15-13.67,2.1-4.59,2.95-4.78,10.25-15.72,5.03-7.54,7.55-11.31,9.57-15.04,2.35-4.34,4.04-8.51,8.2-23.92,5.08-18.82,6.53-27.28,10.94-44.43.45-1.76,1.75-6.79,3.42-13.67,1.21-5,2.15-9.06,2.73-11.62"
          />
        </g>
        <g id="collarbone">
          <path
            id="collarbone_1"
            data-name="collarbone 1"
            class="Brustbein_dv_obl_liegend2028_cls13"
            style={{
              fill: props.colors.Brustbein_dv_obl_liegend2028_cls13,
              stroke: props.colors.Brustbein_dv_obl_liegend2028_cls13,
            }}
            d="M442.99,28.7c-4.54,3.27-14.71,11.25-29.39,20.51-10.28,6.48-10.32,5.5-24.61,14.35-10.23,6.34-12.48,8.25-18.46,10.25-8.13,2.73-18.32,4-19.14,4.1-9.18,1.12-11.77.28-14.35,2.73-4.91,4.65-2.67,14.39-2.05,17.09,1.95,8.48,5.67,9.59,14.35,23.24,7.67,12.06,7.5,15.48,12.99,18.46s13.74,3.91,18.46,0c4.1-3.39,2.53-7.94,5.47-14.35,3.57-7.81,10.31-10.71,19.14-15.72,14.09-8,6.06-5.81,35.54-25.29,17.93-11.85,49-34.61,60.84-44.43"
          />
          <path
            id="collarbone_2"
            data-name="collarbone 2"
            class="Brustbein_dv_obl_liegend2028_cls13"
            style={{
              fill: props.colors.Brustbein_dv_obl_liegend2028_cls13,
              stroke: props.colors.Brustbein_dv_obl_liegend2028_cls13,
            }}
            d="M65.68,1.36c5.88,2.76,4.92,6.1,14.35,12.99,8.38,6.12,11.97,5.56,23.92,11.62,12.39,6.28,10.7,7.99,24.61,15.72,10.47,5.82,19.2,9.17,27.34,12.3,10.88,4.18,22.02,7.68,28.03,9.57,7.91,2.49,15.41,6.15,23.24,8.89,5.59,1.96,8.99,3.01,10.25,6.15,1.81,4.5-1.67,10.99-6.15,14.35-3.85,2.89-5.76,1.46-14.35,4.78-5.93,2.3-8.9,3.45-10.94,6.15-3.7,4.91-.22,8.49-3.42,15.72-3.16,7.15-9.61,10.56-11.62,11.62-3.73,1.97-7.31,3.86-10.94,2.73-4.76-1.48-5.45-6.89-10.25-15.04,0,0-4.45-7.54-12.3-15.04-8.66-8.27-13.83-7.08-27.34-14.35-9.89-5.32-6.91-5.85-27.34-20.51-14.85-10.65-16.62-10.51-27.34-19.14-.96-.77-.67-.55-18.46-16.41-12.38-11.03-13.62-12.12-13.67-14.35"
          />
        </g>
        <g id="ribs">
          <g id="rib_1" data-name="rib 1">
            <path
              id="rib_1.1"
              data-name="rib 1.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M.92,163.52c-.2,2.03-.34,4.02-.42,5.99"
            />
            <path
              id="rib_1.2"
              data-name="rib 1.2"
              class="Brustbein_dv_obl_liegend2028_cls8"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls8,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls8,
              }}
              d="M.68,181.91c6.54,85.98,124.09,104.69,188.27,127.48"
            />
            <path
              id="rib_1.3"
              data-name="rib 1.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M194.76,311.52c1.92.73,3.78,1.47,5.57,2.21"
            />
          </g>
          <g id="rib_2" data-name="rib 2">
            <path
              id="rib_2.1"
              data-name="rib 2.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M58.84,90.9c-.43,1.99-.83,3.95-1.18,5.88"
            />
            <path
              id="rib_2.2"
              data-name="rib 2.2"
              class="Brustbein_dv_obl_liegend2028_cls9"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls9,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls9,
              }}
              d="M55.98,108.42c-10.7,101.88,99.47,110.99,151.43,146.72"
            />
            <path
              id="rib_2.3"
              data-name="rib 2.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M212.15,258.61c1.6,1.25,3.14,2.54,4.59,3.86"
            />
          </g>
          <g id="rib_3" data-name="rib 3">
            <path
              id="rib_3.1"
              data-name="rib 3.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M120.6,19.13c-1.13,1.67-2.21,3.35-3.26,5.04"
            />
            <path
              id="rib_3.2"
              data-name="rib 3.2"
              class="Brustbein_dv_obl_liegend2028_cls10"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls10,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls10,
              }}
              d="M111.3,35c-32.04,63.86-6.35,136.44,50.57,165.73"
            />
            <path
              id="rib_3.3"
              data-name="rib 3.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M167.46,203.42c1.82.82,3.66,1.59,5.53,2.32"
            />
          </g>
          <g id="rib_4" data-name="rib 4">
            <path
              id="rib_4.1"
              data-name="rib 4.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M177.09,12.98c-.86,1.83-1.69,3.65-2.49,5.46"
            />
            <path
              id="rib_4.2"
              data-name="rib 4.2"
              class="Brustbein_dv_obl_liegend2028_cls1"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls1,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls1,
              }}
              d="M170.05,29.54c-19.69,51.84-13.64,93.77-6.2,111.54"
            />
            <path
              id="rib_4.3"
              data-name="rib 4.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M166.55,146.42c1.6,2.54,3.13,3.87,4.34,3.85"
            />
          </g>
          <g id="rib_5" data-name="rib 5">
            <path
              id="rib_5.1"
              data-name="rib 5.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M316.54,23.23c1.13,1.68,2.21,3.36,3.24,5.05"
            />
            <path
              id="rib_5.2"
              data-name="rib 5.2"
              class="Brustbein_dv_obl_liegend2028_cls5"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls5,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls5,
              }}
              d="M325.07,37.62c15.96,30.68,18.66,61.31,19.2,77.48"
            />
            <path
              id="rib_5.3"
              data-name="rib 5.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M344.4,120.45c.04,3.1.04,5.18.16,5.99"
            />
          </g>
          <g id="rib_6" data-name="rib 6">
            <path
              id="rib_6.1"
              data-name="rib 6.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M375.32,23.23c1.03,1.74,2.04,3.47,3.01,5.19"
            />
            <path
              id="rib_6.2"
              data-name="rib 6.2"
              class="Brustbein_dv_obl_liegend2028_cls12"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls12,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls12,
              }}
              d="M384.31,39.73c26.93,54.37,22.5,98.6,15.92,117.64"
            />
            <path
              id="rib_6.3"
              data-name="rib 6.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M397.74,163.25c-1.51,2.85-2.94,4.31-3.96,4.21"
            />
          </g>
          <g id="rib_7" data-name="rib 7">
            <path
              id="rib_7.1"
              data-name="rib 7.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M90.97,632.95c1.6-.58,3.49-1.29,5.61-2.13"
            />
            <path
              id="rib_7.2"
              data-name="rib 7.2"
              class="Brustbein_dv_obl_liegend2028_cls14"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls14,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls14,
              }}
              d="M107.34,626.19c8.09-3.69,13.34-6.16,19.17-10.33,11.07-7.92,17.32-17.04,19.14-19.82,8.05-12.31,11.62-23.92,11.62-23.92,2.19-7.13,2.65-12.03,6.84-14.35,5.13-2.85,11.81.43,12.3.68,11.32,5.76,15.56,25.5,10.25,39.65-2.15,5.72-5.48,9.51-10.94,15.72-7.09,8.07-9.57,7.91-17.77,16.41-7.27,7.53-7.37,9.77-15.04,18.46-2.82,3.2-5.59,6.05-8.21,8.56"
            />
            <path
              id="rib_7.3"
              data-name="rib 7.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M130.4,661.2c-1.63,1.44-3.16,2.73-4.57,3.88"
            />
          </g>
          <g id="rib_8" data-name="rib 8">
            <path
              id="rib_8.1"
              data-name="rib 8.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M115.58,680.8c1.5-1.4,2.98-2.74,4.44-4.04"
            />
            <path
              id="rib_8.2"
              data-name="rib 8.2"
              class="Brustbein_dv_obl_liegend2028_cls7"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls7,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls7,
              }}
              d="M129.38,668.79c1.61-1.31,3.16-2.55,4.65-3.71,2.16-1.68,9.27-7.18,19.14-13.67,5.28-3.48,2.91-1.68,19.14-11.62,9.27-5.68,12.07-7.51,17.09-8.89,2.33-.64,6.12-.85,13.67-1.37,13.54-.92,15.17-.23,16.41.68,5.56,4.14,4.26,13.97,4.1,15.04-.97,6.69-4.96,10.94-10.25,16.41-3.03,3.14-9.42,9.13-19.82,14.35-9.95,5-11.65,3.3-19.82,8.2-4.46,2.67-6.11,4.47-15.04,12.3-2.4,2.1-5.47,4.78-9.15,7.91"
            />
            <path
              id="rib_8.3"
              data-name="rib 8.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M144.79,708.4c-1.47,1.23-3.01,2.51-4.61,3.84"
            />
          </g>
          <g id="rib_9" data-name="rib 9">
            <path
              id="rib_9.1"
              data-name="rib 9.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M377.37,764.88c-1.37-1-2.99-2.19-4.82-3.56"
            />
            <path
              id="rib_9.2"
              data-name="rib 9.2"
              class="Brustbein_dv_obl_liegend2028_cls6"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls6,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls6,
              }}
              d="M362.54,753.56c-5.93-4.74-8.2-6.84-16.61-13.97-19.31-16.36-19.43-15.48-23.92-20.51-.46-.52-4.87-6.5-13.67-18.46-4.59-6.23-6.89-9.42-7.52-12.99-1.13-6.46.9-15.71,7.52-19.14,7.11-3.68,15.65,1.36,19.14,3.42,5.24,3.09,5.29,5.13,15.72,15.72,3.15,3.19,6.98,7.07,12.3,11.62,6.92,5.92,9.55,7.15,16.41,12.99.69.59,1.36,1.17,2.01,1.74"
            />
            <path
              id="rib_9.3"
              data-name="rib 9.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M378.58,718.27c1.71,1.63,3.13,3.07,4.26,4.23"
            />
          </g>
          <g id="rib_10" data-name="rib 10">
            <path
              id="rib_10.1"
              data-name="rib 10.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M418.39,605.61c-1.68-1.17-3.33-2.3-4.95-3.39"
            />
            <path
              id="rib_10.2"
              data-name="rib 10.2"
              class="Brustbein_dv_obl_liegend2028_cls11"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls11,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls11,
              }}
              d="M403.54,595.79c-86.02-53.65-75.6,13.9,9.55,40.76"
            />
            <path
              id="rib_10.3"
              data-name="rib 10.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M418.74,638.24c1.9.54,3.83,1.05,5.8,1.55"
            />
          </g>
          <g id="rib_11" data-name="rib 11">
            <path
              id="rib_11.1"
              data-name="rib 11.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M418.39,522.9c-1.75-1.07-3.46-2.1-5.14-3.1"
            />
            <path
              id="rib_11.2"
              data-name="rib 11.2"
              class="Brustbein_dv_obl_liegend2028_cls3"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls3,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls3,
              }}
              d="M403,513.9c-84.19-46.68-75.18,4.03-15.34,32.93"
            />
            <path
              id="rib_11.3"
              data-name="rib 11.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M393.02,549.3c1.81.79,3.65,1.56,5.54,2.31"
            />
          </g>
          <g id="rib_12" data-name="rib 12">
            <path
              id="rib_12.1"
              data-name="rib 12.1"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M408.47,670.67c-1.65-1.18-3.29-2.34-4.89-3.47"
            />
            <path
              id="rib_12.2"
              data-name="rib 12.2"
              class="Brustbein_dv_obl_liegend2028_cls4"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls4,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls4,
              }}
              d="M393.86,660.44c-101.88-69.71-98.18-31.1-12.95,35.65"
            />
            <path
              id="rib_12.3"
              data-name="rib 12.3"
              class="Brustbein_dv_obl_liegend2028_cls2"
              style={{
                fill: props.colors.Brustbein_dv_obl_liegend2028_cls2,
                stroke: props.colors.Brustbein_dv_obl_liegend2028_cls2,
              }}
              d="M385.59,699.72c1.57,1.2,3.16,2.42,4.77,3.64"
            />
          </g>
        </g>
      </g>
      <g id="overlays">
        <g
          id="sternum_overlay"
          data-name="sternum overlay"
          class="Brustbein_dv_obl_liegend2028_cls15"
          style={{
            fill: props.colors.Brustbein_dv_obl_liegend2028_cls15,
            stroke: props.colors.Brustbein_dv_obl_liegend2028_cls15,
          }}
        >
          <path
            id="sternum_overlay_1"
            data-name="sternum overlay 1"
            class="Brustbein2028Fill"
            style={{
              fill: props.colors.Brustbein2028Fill,
              stroke: props.colors.Brustbein2028Fill,
            }}
            d="M283.73,294.02c5.36-2.11,2.63-12.9,9.57-16.41,4.27-2.16,7.71.73,10.94-2.05,2.74-2.36,2.33-6.24,2.73-10.94.42-4.93,1.82-11.74,6.15-19.82,4.3-2.38,10.39-5.7,17.77-9.57,15.27-8.01,19.04-9.45,25.29-14.35,6.95-5.45,11.26-10.71,14.27-14.42,9-11.11,12.33-19.98,13.76-24.54,1.71-5.49,3.26-10.67.68-13.67-2.9-3.38-7.7-.14-12.3-3.42-5.55-3.95-1.96-11.08-7.52-18.46-3.85-5.1-6.65-3.14-14.35-8.89-11.04-8.24-9.14-15.15-16.41-17.77-7.59-2.74-10.69,4.42-25.76,5.91-3.62.36-3.81-.02-24.14-2.49-24.1-2.93-23.48-2.36-26.75-3.49-14.22-4.93-18.08-13.02-25.89-10.86-4.34,1.2-3.35,3.75-10.94,8.2-2.6,1.53-9.43,5.54-18.46,6.05-7.98.46-10.71-2.21-13.66.1-3.64,2.85-.46,7.71-3.42,14.35-3.86,8.68-12.33,7.25-16.41,15.04-1.97,3.76-.79,5.61,1.37,21.19,3.06,22.04,1.73,25.73,6.15,31.44,1.29,1.67,1.86,1.94,15.04,10.25,19.47,12.28,21.15,13.54,23.24,16.41,4.25,5.82,7.4,14.46,6.84,23.24-.61,9.41-5.07,12.37-2.73,18.46.98,2.56,1.92,2.44,11.62,11.62,7.09,6.71,7.5,7.64,10.25,8.89,2.02.91,8.93,3.63,15.04.68,5.04-2.43,4.29-6.19,8.2-7.52,7.27-2.47,13.95,9.15,19.82,6.84h.01Z"
          />
          <path
            id="sternum_overlay_2"
            data-name="sternum overlay 2"
            class="Brustbein2028Fill"
            style={{
              fill: props.colors.Brustbein2028Fill,
              stroke: props.colors.Brustbein2028Fill,
            }}
            d="M237.93,683.64c3.98,2.05,11.86-3.42,17.77-7.52,5.21-3.61,7.52-6.39,11.62-6.15,2.95.17,4,1.75,8.2,3.42,6.13,2.43,7.84.3,11.62,2.05,6.7,3.1,6.94,11.19,9.57,10.94,2.82-.28,1.45-9.43,7.52-19.14,4.53-7.25,7.64-5.91,10.94-12.3,3.03-5.89,2.7-11.67,2.05-23.24-.63-11.09-2.29-13.25-.68-19.82.84-3.43,2.05-8.34,6.15-12.3,3.35-3.23,5.7-3,7.52-6.15,2.4-4.15-.28-6.97.68-11.62,1.85-8.9,13.69-8.56,16.41-16.41,2.06-5.95-3.76-9-9.57-23.24-3.55-8.7-9.34-22.9-4.1-34.86,3.52-8.03,8.64-7.36,9.57-13.67,1.31-8.94-8.83-11.21-15.06-28.1-3.21-8.71-3.3-15.65-3.39-23.16-.08-6.63.69-9.63,2.27-12.2,2.72-4.42,5.82-4.32,6.62-7.62,1.24-5.13-5.75-7.45-6.15-14.35-.4-6.94,6.4-9.25,5.47-15.04-.79-4.96-6.11-5.29-11.62-10.94-5.65-5.78-6.96-13.43-9.57-28.71-2.27-13.31-3.41-19.97-.68-26.66,2.65-6.49,7.06-10.08,5.47-12.99-1.54-2.8-6.15-.37-12.99-3.42-7.17-3.2-6.59-7.88-12.99-9.57-2.72-.72-5.92-.25-12.3.68-5.12.75-6.06,1.35-10.25,2.05-6.99,1.17-12.58.9-16.41.68-12.09-.67-13.65-3.41-17.77-1.37-5.08,2.51-3.67,7.13-9.57,10.94-6.2,4.01-11.49,1.32-12.99,4.1-2.04,3.79,7.7,9,12.99,20.51,5.01,10.9,5.46,26.18-.67,37.35-4.06,7.4-8.32,8.12-9.58,15.28-1.44,8.22,3.58,10.69,2.05,18.46-1.79,9.07-9.32,9.18-10.94,17.77-1.42,7.53,4.11,8.85,5.47,20.51.26,2.2,1.41,13.8-4.78,24.61-4.23,7.38-7.26,6.32-9.57,12.3-2.81,7.29.77,11.23,2.05,24.61.87,9.06,1.59,16.62-1.37,24.61-4.74,12.78-14.1,13.73-14.35,23.24-.17,6.42,4.07,6.26,6.84,16.41,3.66,13.43-1.91,20.63,3.42,25.97,3.99,4,8.36,1.22,11.62,5.47,4.43,5.77-1.47,13.69,2.73,17.77,2.89,2.8,6.42-.21,12.99,2.05,8.26,2.84,12.29,10.91,12.99,12.3,2.17,4.33,1.97,7.17,2.05,21.87.11,20.37.54,23.48,2.73,24.61h-.03Z"
          />
          <path
            id="sternum_overlay_3"
            data-name="sternum overlay 3"
            class="Brustbein2028Fill"
            style={{
              fill: props.colors.Brustbein2028Fill,
              stroke: props.colors.Brustbein2028Fill,
            }}
            d="M246.34,668.93c-1.01,5.15-8.49,33.69-11.83,42.72-2.51,6.79-3.54,7.97-7.52,17.77-5.08,12.52-7.64,18.91-8.2,25.29-.78,8.83.99,16.14,2.05,20.51,2.09,8.61,4.02,9.81,6.15,19.82,1.63,7.66,2.45,11.49,1.37,16.41-1.46,6.61-4.6,7.78-4.78,13.67-.14,4.3,1.31,10.92,5.47,12.3,4.95,1.65,10.73-5.12,11.62-6.15,3.6-4.21,2.93-6.64,6.15-13.67,2.1-4.59,2.95-4.78,10.25-15.72,5.03-7.54,7.55-11.31,9.57-15.04,2.35-4.34,4.04-8.51,8.2-23.92,5.08-18.82,6.53-27.28,10.94-44.43.45-1.76,1.75-6.79,3.42-13.67,1.21-5,5.47-29.91,6.05-32.47"
          />
        </g>
      </g>
    </svg>
  );
}
