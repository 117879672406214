import { useState } from "react";
import { Modal, Row, Col, Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { DeleteExam } from "../../Utilities/ClientServices/ClientServices";
import { NotificationManager } from "react-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import "./stylesheets/ExportExaminationToDirectoryModal.scss";

export default function ExportSelectedExaminationsModal({
  shown,
  headerless = false,
  close,
}) {
  const date = new Date();
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [inp, setInp] = useState(false);
  const [anonymized, setAnonymized] = useState(false);
  const [protectedZipPackage, setProtectedZipPackage] = useState(false);
  const [fileName, setFileName] = useState(
    "ExamExport_from_dd/mm/yyy_hh:ss.zip"
  );
  const [zipPw, setZipPw] = useState("");
  const [withViewer, setWithViewer] = useState(true);
  const { keycloak } = useKeycloak();

  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  const send = () => {
    setInp(true);
    let data = {
      version: window.conf.CLIENT_SERVICES.API_VERSION,
      selected_exams: selectedExaminations,
    };
    DeleteExam(keycloak, data)
      .then((data) => {
        setInp(false);
        NotificationManager.success("Deleting examination success");
        close();
      })
      .catch((reason) => {
        setInp(false);
        NotificationManager.error("Deleting examination failed");
        console.warn(reason);
      });
  };

  return (
    <Modal
      className="top-modal"
      show={shown}
      onHide={close}
      size={"md"}
      onClick={(e) => e.stopPropagation()}
    >
      {!headerless && (
        <Modal.Header className="py-2 bg-light">
          <h4>Export Examinations</h4>
          {inp && (
            <ClipLoader color="#a1a1a1" loading={true} css={true} size={30} />
          )}
        </Modal.Header>
      )}
      <Modal.Body className="text-center ">
        {selectedExaminations.length === 0 ? (
          <Container>
            <h5>Select Examination first</h5>
          </Container>
        ) : (
          <Container>
            <Row className="pb-2">
              <Col>
                <h5>Select Options</h5>
              </Col>
            </Row>
            <Row className="text-center py-2">
              <Col>
                <input
                  id="package-name"
                  placeholder={fileName}
                  type="text"
                  title="Name of the file"
                  onChange={(e) => {
                    setZipPw(e.target.value);
                  }}
                ></input>
              </Col>
            </Row>
            <Row className="text-left border-bottom border-top py-2">
              <Col sm={1}>
                <i
                  onClick={() => setAnonymized(!anonymized)}
                  className={
                    anonymized
                      ? "fas fa-check-square button-like-icon fa-lg"
                      : "far fa-square fa-lg button-like-icon "
                  }
                />
              </Col>
              <Col>Anonymize patient data</Col>
            </Row>
            <Row className="text-left border-bottom py-2">
              <Col sm={1}>
                <i
                  onClick={() => setProtectedZipPackage(!protectedZipPackage)}
                  className={
                    protectedZipPackage
                      ? "fas fa-check-square button-like-icon fa-lg"
                      : "far fa-square fa-lg button-like-icon "
                  }
                />
              </Col>
              <Col sm={11}>Password protected zip package</Col>
              {protectedZipPackage && (
                <Col>
                  <input
                    id="zip-pw"
                    className={zipPw.length === 0 ? "mt-1 invalid" : "mt-1"}
                    placeholder="Password"
                    type="password"
                    title="Type password if you want to protect zip with password"
                    onChange={(e) => {
                      setZipPw(e.target.value);
                    }}
                  ></input>
                </Col>
              )}
            </Row>
            <Row className="text-left border-bottom  py-2">
              <Col sm={1}>
                <i
                  onClick={() => setWithViewer(!withViewer)}
                  className={
                    withViewer
                      ? "fas fa-check-square button-like-icon fa-lg"
                      : "far fa-square fa-lg button-like-icon "
                  }
                />
              </Col>
              <Col>Include Viewer</Col>
            </Row>
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer className="px-0 py-0 bg-light">
        <Button className="mr-auto" onClick={close}>
          Close
        </Button>
        <Button className="mr-right" onClick={() => {}}>
          Export
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
