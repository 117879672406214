import { useHistory } from "react-router-dom";
import ExamsSection from "../ExamsSection";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import styles from "./ExamGuide.module.scss";
import { useEffect, useMemo, useState } from "react";
import Controller from "./images/Schultergelenk_Elevation/Controller";
import {
  EXAM_GUIDE_NO_WORDS,
  EXAM_GUIDE_STEP_BY_STEP,
} from "../../../../Pages/Consts/ExamHelperRoutes";
import { useExamination } from "../../../../Utilities/FetchHooks/Ris/RisHooks";
import { ExamGuideData } from "./mocks/ExamGuide.mock";
import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import ExamHelperContentTab from "../../../../Blueprints/ExamHelper/ImageSwitchingTab/ImageSwitchingTab";
import useWindowSize from "../../../../Utilities/Window/windowSize";

function ExamGuideStepByStep({ selectedExam }) {
  const { data: examData } = useExamination({
    examId: selectedExam,
    enabled: selectedExam != null,
  });
  const data = selectedExam ? ExamGuideData[selectedExam] : undefined;
  const stepCount = data && data.steps.length;
  const [step, setStep] = useState(0);
  const stepData = data && data.steps[step];
  const [displayMode, setDisplayMode] = useState("drawing");
  const { width } = useWindowSize();

  useEffect(() => {
    setStep(0);
  }, [selectedExam]);

  const { singleHeight, multiHeight } = useMemo(() => {
    let sizing = { singleHeight: "600px", multiHeight: "300px" };
    if (width < 1200) {
      sizing = { singleHeight: "480px", multiHeight: "240px" };
    }
    if (width < 768) {
      sizing = { singleHeight: "220px", multiHeight: "110px" };
    }
    return sizing;
  }, [width]);

  const title = !selectedExam
    ? "No exam in selection"
    : examData
    ? examData.designationde
    : "Loading ...";

  const label = stepData ? stepData.label : "No data";
  const ModeSelector = () => (
    <ButtonGroup toggle style={{ position: "absolute", top: 0, right: 0 }}>
      <ToggleButton
        type="radio"
        variant="primary"
        name="radio"
        value={"image"}
        checked={displayMode === "image"}
        onChange={(e) => setDisplayMode(e.currentTarget.value)}
      >
        Image
      </ToggleButton>
      <ToggleButton
        type="radio"
        variant="primary"
        name="radio"
        value={"drawing"}
        checked={displayMode === "drawing"}
        onChange={(e) => setDisplayMode(e.currentTarget.value)}
      >
        Drawing
      </ToggleButton>
    </ButtonGroup>
  );

  const TitleBar = ({ title }) => (
    <div className={styles.Heading}>
      <h3>{title}</h3>
      <ModeSelector />
    </div>
  );

  const leftButton = {
    disabled: step === 0,
    content: <i className="fas fa-chevron-left" />,
    onClick: () => setStep((prev) => prev - 1),
  };

  const rightButton = {
    disabled: step === stepCount - 1,
    content: <i className="fas fa-chevron-right" />,
    onClick: () => setStep((prev) => prev + 1),
  };

  const content = (
    <>
      {stepData !== undefined ? (
        <>
          {Array.isArray(stepData[displayMode]) ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
              }}
            >
              {stepData[displayMode].map((img, idx) => (
                <img
                  key={idx}
                  style={{ height: multiHeight, maxWidth: "400px" }}
                  src={img}
                  alt="body position"
                />
              ))}
            </div>
          ) : (
            <img
              style={{ height: singleHeight, maxWidth: "800px" }}
              src={stepData[displayMode]}
              alt="body position"
            />
          )}
        </>
      ) : (
        <div className="d-flex text-white w-100 justify-content-center align-items-center">
          <h5>No data</h5>
        </div>
      )}
    </>
  );

  return (
    <ExamHelperContentTab
      title={<TitleBar title={title} />}
      image={content}
      description={<h3>{label}</h3>}
      leftButton={leftButton}
      rightButton={rightButton}
      // imageAction={ModeSelector}
    />
  );
}

function ExamGuideNoWords({ selectedExam }) {
  return (
    <div
      className="border rounded"
      style={{
        display: "grid",
        gridTemplateRows: "1fr 5fr",
        margin: "10px",
        padding: "10px",
        justifyItems: "center",
        alignItems: "center",
        backgroundColor: "#707070",
        height: "90vh",
      }}
    >
      <div className="border rounded p-2 text-center text-white">
        <h2>Linkes Schultergelenk Schwedenstatus Elevation v.d stehend</h2>
      </div>
      <div className={`${styles.ImageContainer} border rounded p-2`}>
        <Controller />
      </div>
    </div>
  );
}

export function ExamGuideSS(props) {
  const { push } = useHistory();
  const selectedExam = Number(props.match.params.examId) || null;
  const setSelectedExam = (examId) => {
    push(`${EXAM_GUIDE_STEP_BY_STEP.link}/${examId}`);
  };
  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection
          selectedExam={selectedExam}
          selectedExamSetter={setSelectedExam}
        />
      }
      rightComponent={<ExamGuideStepByStep selectedExam={selectedExam} />}
    />
  );
}

export function ExamGuideNW(props) {
  const { push } = useHistory();
  const selectedExam = Number(props.match.params.examId) || null;
  const setSelectedExam = (examId) => {
    push(`${EXAM_GUIDE_NO_WORDS.link}/${examId}`);
  };
  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection
          selectedExam={selectedExam}
          selectedExamSetter={setSelectedExam}
        />
      }
      rightComponent={<ExamGuideNoWords selectedExam={selectedExam} />}
    />
  );
}
