import {
  SET_QA_SELECTED_EXAM,
  RESET_QA_SELECTED_EXAM,
  SET_QA_SELECTED_SERIE,
  RESET_QA_SELECTED_SERIE,
  SET_QA_SELECTED_IMAGE,
  RESET_QA_SELECTED_IMAGE,
  SET_QA_CHANGES,
  RESET_QA_CHANGES,
  SAVE_QA_CHANGES,
  RESET_REFETCH_FLAG,
} from "../actionTypes";

const initialState = {
  selectedModality: "Xray",
  selectedExam: undefined,
  nextSelectedExam: undefined,
  selectedSerie: undefined,
  nextSelectedSerie: undefined,
  selectedImage: undefined,
  nextSelectedImage: undefined,
  changes: undefined,
  unsavedCount: 0,
  refetch: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_QA_SELECTED_EXAM: {
      const { content, refetch } = action.payload;
      if (state.changes !== undefined) {
        return {
          ...state,
          nextSelectedExam: content,
          unsavedCount: state.unsavedCount + 1,
          refetch: refetch,
        };
      }
      return {
        ...state,
        selectedExam: content,
        refetch: refetch,
      };
    }
    case RESET_QA_SELECTED_EXAM: {
      if (state.changes !== undefined) {
        return {
          ...state,
          unsavedCount: state.unsavedCount + 1,
        };
      }
      return {
        ...state,
        selectedExam: initialState.selectedExam,
      };
    }
    case SET_QA_SELECTED_SERIE: {
      const { content, refetch } = action.payload;
      if (state.changes !== undefined) {
        return {
          ...state,
          unsavedCount: state.unsavedCount + 1,
          nextSelectedSerie: content,
          refetch: refetch,
        };
      }
      return {
        ...state,
        selectedSerie: content,
        refetch: refetch,
      };
    }
    case RESET_QA_SELECTED_SERIE: {
      if (state.changes !== undefined) {
        return {
          ...state,
          unsavedCount: state.unsavedCount + 1,
        };
      }
      return {
        ...state,
        selectedSerie: initialState.selectedExam,
      };
    }
    case SET_QA_SELECTED_IMAGE: {
      const { content, refetch } = action.payload;
      if (state.changes !== undefined) {
        return {
          ...state,
          nextSelectedImage: content,
          unsavedCount: state.unsavedCount + 1,
          refetch: refetch,
        };
      }
      return {
        ...state,
        selectedImage: content,
        refetch: refetch,
      };
    }
    case RESET_QA_SELECTED_IMAGE: {
      if (state.changes !== undefined) {
        return {
          ...state,
          unsavedCount: state.unsavedCount + 1,
        };
      }
      return {
        ...state,
        selectedImage: initialState.selectedImage,
      };
    }
    case SET_QA_CHANGES: {
      const { content } = action.payload;
      return {
        ...state,
        changes: content,
      };
    }
    case RESET_QA_CHANGES: {
      if (
        window.conf.EXAMS_STATS.SWITCH_EXAM_MODE === 2 &&
        state.nextSelectedExam &&
        state.nextSelectedSerie &&
        state.nextSelectedImage
      ) {
        return {
          ...state,
          changes: initialState.changes,
          selectedImage: state.nextSelectedImage,
          selectedSerie: state.nextSelectedSerie,
          selectedExam: state.nextSelectedExam,
          nextSelectedExam: initialState.nextSelectedExam,
          nextSelectedSerie: initialState.nextSelectedSerie,
          nextSelectedImage: initialState.nextSelectedImage,
          refetch: true,
          unsavedCount: initialState.unsavedCount,
        };
      }
      return {
        ...state,
        changes: initialState.changes,
      };
    }
    case SAVE_QA_CHANGES: {
      if (
        window.conf.EXAMS_STATS.SWITCH_EXAM_MODE === 2 &&
        state.nextSelectedExam &&
        state.nextSelectedSerie &&
        state.nextSelectedImage
      ) {
        return {
          ...state,
          changes: initialState.changes,
          selectedImage: state.nextSelectedImage,
          selectedSerie: state.nextSelectedSerie,
          selectedExam: state.nextSelectedExam,
          nextSelectedExam: initialState.nextSelectedExam,
          nextSelectedSerie: initialState.nextSelectedSerie,
          nextSelectedImage: initialState.nextSelectedImage,
          refetch: true,
          unsavedCount: initialState.unsavedCount,
        };
      }
      //Moves temp changes to selected image/exam and sets refetch flag
      let selectedImage = { ...state.selectedImage };
      let selectedExam = { ...state.selectedExam };

      selectedImage.dose_qa_status = 1;

      if (state?.changes?.maskv) {
        Object.keys(state.changes.maskv).forEach((key) => {
          if (key === "substantiation_id") {
            selectedImage.mas_kv_collection[0].substantiation.id =
              state.changes.maskv[key];
          }
          selectedImage.mas_kv_collection[0][key] = state.changes.maskv[key];
        });
      }

      if (state?.changes?.study) {
        Object.keys(state.changes.study).forEach((key) => {
          selectedExam[key] = state.changes.study[key];
        });
      }

      return {
        ...state,
        changes: initialState.changes,
        selectedImage,
        selectedExam,
        refetch: true,
        unsavedCount: initialState.unsavedCount,
      };
    }
    case RESET_REFETCH_FLAG: {
      return {
        ...state,
        refetch: false,
      };
    }
    default: {
      return { ...state };
    }
  }
}
