const konSOP = "1.2.276.0.76.3.1.173."; // pergamon uid

export function getUnixTimeStamp(timeStamp = null) {
  if (timeStamp === null) {
    timeStamp = new Date();
  }
  return Math.floor(timeStamp.getTime() / 1000);
}

export default function CreateSOP({
  sopBase = konSOP,
  wId = 0,
  sysId = 0,
  uTyp = 0,
  ownUaid = 777,
}) {
  let now = new Date();
  if (wId <= 0) {
    return `${sopBase}${ownUaid}.${uTyp}.${sysId}.${wId}.${getUnixTimeStamp(
      now
    )}+${now.getMilliseconds()}`;
  }
  return `${sopBase}${ownUaid}.${uTyp}.${sysId}.${wId}.${now.getSeconds()}+${now.getMilliseconds()}`;
}
