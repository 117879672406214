import style from "./style.scss";

function Lendenwirbelkorper3LatLiegend1714(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.07 324.2">

<g id="Anatomy">
  <path id="LWK11" class="Lendenwirbelkorper3_lat1714_cls5" d="M82.4.11c-.4.8-7.39,14.86-7.89,15.86-3.9,7.4-5.2,11-5.5,14.4-.9,13.2,16,17,18.5,15,9.2-7.3,15.3-1.7,31.4-1.4,13.3.3,23.8,4.1,31.4,6.8,15.7,5.7,19.8,12.6,30.8,11.6,2.2-.2,10.8-1,14.4-6.8,3.1-5.2,3.55-19.46,3.55-19.46,0,0,.91-5.47,3.95-16.04,1.9-4.9,2.6-7.8,3.1-10"/>
  <path id="LWK10" class="Lendenwirbelkorper3_lat1714_cls1" d="M144.51,83.07s23.2-2.1,27.9-1.2c2.3.4,4.8-.3,6.4,0,3.6.8,5.6,5.3,4.9,7.3-1.6,4.2-5,11.7-6.6,14.2-9.1,14.6-12.3,32.2-17.8,48.5-4.3,12.9-6.4,16.9-4.8,23.2,1.5,6,4.9,8.7,4.1,14.4-.7,5.4-4.7,10-8.9,12.3-8,4.4-17,.1-28-3.4-14.8-4.8-26-5.8-45.8-7.5-2.8-.2-5.1-.4-8.3-.6-21.3-1.3-36.5-1.1-39.5-6.9-.2-.3-.8-1.7-1.3-3.5-.3-1.2-1-3.8-.7-7.4.5-7.1,4.1-17.8,4.1-17.8,2.7-6.9,4.1-13.7,6.8-27.3h0c1.5-7.6,4.2-18.4,7.5-43.8.3-2.6,1.1-8.5,5.5-11.6,5.5-3.9,13.3-1,17.8,0,32.7,7.4,29.3,4.6,51.9,9.6,8.5.5,30,1,24.8,1.5"/>
  <path id="LWK9" class="Lendenwirbelkorper3_lat1714_cls5" d="M128.51,324.17c-.6-4.5-1.3-9.9-1.4-16.4-.1-3.2-.4-20.5,4.8-41,2.4-9.4,5.5-19.1,5.5-19.1,5.4-17.2,8.9-23.3,6.1-26.7-3.1-3.8-20.8,1-17.1,0s-25.3,7.2-65.6,6.1c-27.6-.7-38.7-12.4-49.9-3.4-9.4,7.5-.2,20.8-2.8,54-1.4,18.5-5.5,32.8-7.7,42.8"/>
  <path id="LWK8" class="Lendenwirbelkorper3_lat1714_cls7" d="M113.41,186.07c-25.9-2.6-48.3-2.7-48.3-2.7-6.7,0-10.1.1-15.5-.9-13.5-2.5-21.6-8.6-23.6-6.2-1.2,1.5.6,5.1,2,7.1,3.9,5.6,10.7,6.7,20.7,8.2,6,.9,13.5,2.1,22.8.9,2.8-.4,6-.9,10.9-.9,5.2,0,9.3.7,11.3,1.1,3.3.6,4.9,1.2,9.7,2.6,0,0,3.3,1,13.7,3.6,10.1,2.6,31.2,6.7,32.6,3,.2-.7,0-1.9-3.4-4.8-9.7-8.2-21.5-9.9-32.9-11h0Z"/>
  <path id="LWK7" class="Lendenwirbelkorper3_lat1714_cls4" d="M357.91,58.17c-4.3-3.5-7.4-5.7-11.1-7.5-8.4-3.9-10.3-1.1-20.5-3.7-16.4-4.1-31.5-16.2-39-22.1-4.1-3.3-5.5-4.8-7.5-4.3-2.5.6-6.3,4.4-5.8,48.7.2,39.2,3.2,44.1,6.8,45.6,7,2.9,11.7-9.3,27.8-10.5,16.2-1.2,32.1,9.5,32.8,10,7.8,5.4,9.6,9.8,15,9.6"/>
  <path id="LWK6" class="Lendenwirbelkorper3_lat1714_cls2" d="M231.01,152.27c-2.5,2-2.6,5.7-2.3,13.2,1,21.3,3.6,33.7,3.6,33.7,3.7,18,4.8,15.8,5.5,24.1.7,9.3-.5,14.7,3.2,17.8,3.4,2.8,7.5.7,14.6.9,6.4.2,10.9,2.1,23.2,8.7,30.9,16.4,34.2,20.9,45.1,21.4,4.2.2,14.2.8,20.1-5.6,1.5-1.7,3.4-4.4,5.9-20,1.2-7.4,1.9-13.7,2.3-18.1.1-2.6.1-6.5-.9-10.9-1.8-8.1-5.5-13.4-7.3-15.9-2.6-3.6-9.2-11.9-33.7-23.7-9.8-4.7-5.6-1.9-32.8-13.2-9.8-4.1-20.1-6.7-30.1-10.3-7.3-2.7-13-4.8-16.4-2.1h0Z"/>
  <path id="LWK5" class="Lendenwirbelkorper3_lat1714_cls4" d="M201.91,320.97c-.4-1.1-.9-2.3-1.5-3.7-1.5-4-3.3-9.2-9.7-35.9-4.1-17.4-6.2-26.1-6-29.4,1-15.3,8.9-25.9,4.6-29.6-2.2-1.9-6-.7-6.8-.5-5.9,1.8-6.8,8.1-12,15.2-6,8.2-16.2,15.3-20.3,13-2.7-1.5-2.7-7-2.7-15.4,0-7.2,1.1-12.4-.1-12.8-.9-.3-2.5,2.1-3.2,3.2-7.7,12.3-11.8,35.4-14,47.7,0,0-.6,2.8-2,24.3-.2,5.2-.3,7.9,1.5,9.7,3.6,3.6,9.7-1.7,19.6-.5,12.7,1.6,20.4,12.6,21.4,14.1.4.6.9,1.4,1.5,2.3"/>
  <path id="LWK4" class="Lendenwirbelkorper3_lat1714_cls2" d="M153.91,170.97c1.9.9,6.2-11.6,14.6-12.3,8.6-.7,16,11.3,17.8,14.1,5.9,9.6,6.7,20.2,8.2,41.5.4,6.3.8,11.7.6,19.1-.4,12.1-2.1,14.3-1.5,21.4.2,1.9,1.9,20.7,10.5,23.7,10.2,3.5,31.9-14.7,35.1-35.5.6-3.9-2-18.1-7.3-46.4-1.5-8-2.4-12.4-2.3-18.8,0-4.8.5-8.8.1-16.8-.3-6.6-.9-8.7-1.4-10-1.9-5.7-4.6-7.2-6.8-11.4-3-5.6-2.5-11-1.8-17.8,1.8-19.3,12-30.1,8.2-33.7-1.5-1.4-4.2-.9-6.2-.5-1.2.2-4.8,1-9.7,6.4-5.2,5.8-3.7,7.7-8.3,12.2-1.4,1.4-5.1,5-10.8,6.1-1.9.4-4.9,1-7.9-.3-4.2-1.9-4.3-5.9-6.3-6.2-4.1-.6-9.2,15.2-14.1,28.7-11.3,30.5-11.9,36-10.7,36.5h0Z"/>
  <path id="LWK3" class="Lendenwirbelkorper3_lat1714_cls4" d="M338.61,319.57c-1.8-1.8-5.1-4.6-12-9.5-11.5-8.2-17.3-12.3-25.5-16.4-3-1.5-11.4-4.5-28.2-10.5-7-2.5-10.9-3.8-16.4-4.1-6.7-.4-8.3,1.1-16,1.5-11.5.5-13.1-2.5-21.4-2-1.5.1-18.5,1.3-23.4,11-3.6,7.2,3.5,11.4,9.3,31.2"/>
  <path id="LWK2" class="Lendenwirbelkorper3_lat1714_cls4" d="M206.41,9.97c-.6,1.6-1.2,3.4-1.9,5.5-6.1,19.1-6.6,33.2-5.9,33.4.7.1,1.1-13.2,9-16.9,6.5-3,15.6,1.8,20.6,6.4,2.1,1.9,10.6,10.1,11.4,31,1.2,33.5-19.6,44.9-12.3,64.2,3.9,10.2,14.3,19.2,24.1,18.7,10.1-.5,17.2-10.9,21-16.4,2.5-3.6,6.2-9.3,6.4-16.9.1-4.9-1.4-7.1-2.9-13-1.2-4.8-1.5-10.6-1.9-22,0,0-.7-18.9.3-42.5.4-9.9.8-11.9,1.3-14.1,1.3-5.4,2.8-7.4,2.3-11.4-.3-2.3-1.1-3.8-2.1-5.4"/>
  <path id="LWK1" class="Lendenwirbelkorper3_lat1714_cls6" d="M169.01,272.27c27.4,20.9.7,35.9,1.5,35.9"/>
  <path id="LWK" class="Lendenwirbelkorper3_lat1714_cls3" d="M195.01,130.47c34.2,24.7-1.6,31.9-3.6,31.9"/>
</g>
<g id="Overlays">
  <path id="Highlight4" class="WK1714Fill" style= {{
            fill: props.colors.WK1714Fill,
            stroke: props.colors.WK1714Fill,
          }} d="M144.29,84.23s23.2-2.1,27.9-1.2c2.3.4,4.8-.3,6.4,0,3.6.8,5.6,5.3,4.9,7.3-1.6,4.2-5,11.7-6.6,14.2-9.1,14.6-12.3,32.2-17.8,48.5-4.3,12.9-6.4,16.9-4.8,23.2,1.5,6,4.9,8.7,4.1,14.4-.7,5.4-4.7,10-8.9,12.3-6.94.43-17,.1-28-3.4-14.8-4.8-19.28-6.26-39.08-7.96-2.8-.2-16.86,1.5-20.06,1.3-20.05-3.19-31.45-2.54-34.45-8.34-.2-.3-.8-1.7-1.3-3.5-.3-1.2-1-3.8-.7-7.4.5-7.1,4.1-17.8,4.1-17.8,2.7-6.9,4.1-13.7,6.8-27.3h0c1.5-7.6,4.2-18.4,7.5-43.8.3-2.6,1.1-8.5,5.5-11.6,5.5-3.9,13.3-1,17.8,0,32.7,7.4,29.3,4.6,51.9,9.6,8.5.5,30,1,24.8,1.5"/>
  <path id="Highlight3" class="Dornfortsatz1714Fill" style= {{
            fill: props.colors.Dornfortsatz1714Fill,
            stroke: props.colors.Dornfortsatz1714Fill,
          }} d="M230.78,152.9c-2.5,2-2.6,5.7-2.3,13.2,1,21.3,3.6,33.7,3.6,33.7,3.7,18,4.8,15.8,5.5,24.1.7,9.3-.5,14.7,3.2,17.8,3.4,2.8,7.5.7,14.6.9,6.4.2,10.9,2.1,23.2,8.7,30.9,16.4,34.2,20.9,45.1,21.4,4.2.2,14.2.8,20.1-5.6,1.5-1.7,3.4-4.4,5.9-20,1.2-7.4,1.9-13.7,2.3-18.1.1-2.6.1-6.5-.9-10.9-1.8-8.1-5.5-13.4-7.3-15.9-2.6-3.6-9.2-11.9-33.7-23.7-9.8-4.7-5.6-1.9-32.8-13.2-9.8-4.1-20.1-6.7-30.1-10.3-7.3-2.7-13-4.8-16.4-2.1h0Z"/>
  <path id="Highlight2" class="Querfortsatz1714Fill" style= {{
            fill: props.colors.Querfortsatz1714Fill,
            stroke: props.colors.Querfortsatz1714Fill,
          }} d="M194.69,130.85c34.2,24.7-1.6,31.9-3.6,31.9"/>
  <path id="Highlight1" class="Angrenzende_WK1714Fill" style= {{
            fill: props.colors.Angrenzende_WK1714Fill,
            stroke: props.colors.Angrenzende_WK1714Fill,
          }} d="M128.1,324.16c-.6-4.5-1.3-9.9-1.4-16.4-.1-3.2-.4-20.5,4.8-41,2.4-9.4,5.5-19.1,5.5-19.1,5.4-17.2,8.9-23.3,6.1-26.7-3.1-3.8-20.8,1-17.1,0s-25.3,7.2-65.6,6.1c-27.6-.7-38.7-12.4-49.9-3.4-9.4,7.5-.2,20.8-2.8,54-1.4,18.5-5.5,32.8-7.7,42.8"/>
  <path id="Highlight" class="Angrenzende_WK1714Fill" style= {{
            fill: props.colors.Angrenzende_WK1714Fill,
            stroke: props.colors.Angrenzende_WK1714Fill,
          }} d="M82.4.11c-.4.8-7.54,14.73-8.04,15.73-3.9,7.4-5.2,11-5.5,14.4-.9,13.2,16,17,18.5,15,9.2-7.3,15.3-1.7,31.4-1.4,13.3.3,23.8,4.1,31.4,6.8,15.7,5.7,19.8,12.6,30.8,11.6,2.2-.2,10.8-1,14.4-6.8,3.1-5.2,3.55-19.46,3.55-19.46,0,0,.91-5.47,3.95-16.04,1.9-4.9,2.6-7.8,3.1-10"/>
</g>
</svg>
  );
}

export default Lendenwirbelkorper3LatLiegend1714;
