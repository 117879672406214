import { Container, Modal, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { loadResources } from "../../Utilities/Resources/Resources";
import { useKeycloak } from "@react-keycloak/web";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch } from "react-redux";
import {
  selectExamplannerDeviceType,
  setExamplannerDeviceId,
} from "../../Redux/actions";

//images imports
import CR from "./Images/CR.png";
import CT from "./Images/CT.png";
import DX from "./Images/DX.png";
import MG from "./Images/MG.png";
import MR from "./Images/MR.png";
import US from "./Images/US.png";
import Xray from "./Images/Xray.png";

//style import
import "./style/ResourcesModalitiesModal.scss";

function ResourcesModalitiesModal({ shown, close }) {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const [resourcesData, setResourcesData] = useState(undefined);

  const loadData = () => {
    if (resourcesData !== undefined) {
      return;
    }
    loadResources(keycloak, 100, 1)
      .then((data) => {
        setResourcesData(data);
      })
      .catch((err) => {
        console.warn("Unable to load resources", err);
        NotificationManager.warning("Unable to load resources");
      });
  };

  const getImage = (utypObj) => {
    switch (utypObj.utyp) {
      case 2:
        return Xray;
      case 7:
        return US;
      case 1:
        return CT;
      case 3:
        return MG;
      case 6:
        return MR;
      default:
        return Xray;
    }
  };

  const selectDevice = (deviceObj) => {
    dispatch(selectExamplannerDeviceType(deviceObj?.utypen?.utyp));
    dispatch(setExamplannerDeviceId(deviceObj.id));
    close();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Modal show={shown} onHide={close} size="md">
      <Modal.Header closeButton className="p-2">
        <h5>Select Modality</h5>
      </Modal.Header>
      <Modal.Body>
        <div
          className="d-flex flex-column pr-1"
          style={{ gap: "5px", maxHeight: "80vh", overflowY: "auto" }}
        >
          {resourcesData?.resources.map((resource) => (
            <div
              onClick={() => selectDevice(resource)}
              className="d-flex border rounded align-items-center tile-clickable"
              key={`resourcekey${resource.id}`}
            >
              <img src={getImage(resource.utypen)} alt="Resource" />
              <div className="d-flex flex-column">
                <p className="tex-muted small">{resource.erzwmod}</p>
                <h5>{resource.beschreibung}</h5>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ResourcesModalitiesModal;
