import {
  getStudies,
  getStudy,
  getStudyDose,
  getStudyFindings,
  getStudySerieImageContour,
  getStudySerieImagePreview,
  getStudySeries,
  getStudyWorklist,
  getStudyWorklistInfo,
} from "../Helpers/FetchFunctions/ris/StudiesFetches";
import usePmedQuery from "../Helpers/UsePmedQuery";

export function useStudies({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  keepPreviousData = false,
  enabled = true,
}) {
  const query = usePmedQuery(
    ["studies", pageNr, pageSize, filter, sort, xmask],
    getStudies,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useStudy({
  studyId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["study", studyId, xmask],
    getStudy,
    {
      studyId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useStudySeries({
  studyId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["studySeries", studyId, xmask],
    getStudySeries,
    {
      studyId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useStudyDose({
  studyId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["studyDose", studyId, xmask],
    getStudyDose,
    {
      studyId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useStudyFindings({
  studyId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["studyFindings", studyId, xmask],
    getStudyFindings,
    {
      studyId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useStudyWorklistInfo({
  studyId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["studyWorklistInfo", studyId, xmask],
    getStudyWorklistInfo,
    {
      studyId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useStudyWorklist({
  studyId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["studyWorklist", studyId, xmask],
    getStudyWorklist,
    {
      studyId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useStudySerieImagePreview({
  imageId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["studySerieImagePreview", imageId, xmask],
    getStudySerieImagePreview,
    {
      imageId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useStudySerieImageContour({
  imageId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["studySerieImageContour", imageId, xmask],
    getStudySerieImageContour,
    {
      imageId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}
