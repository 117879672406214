import style from "./style.scss";

export default function RechtesRadiuskopfchenNachGreenspanLatSitzend2221(
  props
) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 613.55 504.95">
      <g id="Radialheadgreenspanlat">
        <g id="Backround">
          <path
            id="backround"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls3"
            d="M605.33,361.98c-4.16-3.54-9.2-3.74-19.11-4-19.39-.51-29.08-.76-52.31-1-19.44-.2-15.48-2.49-49.29-5-11.92-.89-24.04-1.26-48.29-2-25.49-.78-38.24-1.17-52.31-1-12.29.15-15.6.48-24.14,0-17.97-1.01-21.22-3.46-35.21-4-10.54-.41-18.43.73-34.2,3-12.12,1.75-17.39,3.18-37.22,6-3.23.46-11.91,1.65-23.14,1-12.81-.74-16.03-3.14-17.1-4-5.56-4.53-4.24-9.92-10.06-13-2.72-1.44-5.83-1.29-12.07-1-8.27.39-13.09.61-17.1,4-2.59,2.18-3.73,4.79-6.04,10-3.35,7.59-3.24,11.78-5.03,12-2.14.27-2.97-5.65-8.05-11-3.63-3.83-7.5-5.27-15.09-8-14.54-5.23-17.29-2.63-24.14-7-7.37-4.7-10.62-11.82-12.07-15-4.35-9.53-3.28-17.4-3.02-27,.07-2.71-1.29-15.81-4.02-42-2.61-24.97-4-26.38-6.04-45-4.04-37.06-2.76-70.59-2.01-90,.26-6.89.83-18.88.57-35.78-.27-17.51-1.07-16.17-.94-26.41.02-1.6.07-2.91.09-3.55.04-1.36.42-13.19,1.72-24.26,2.38-20.34,5.94-21.59,3.58-25-3.05-4.41-8.96-2.29-40.8-2-15.94.15-23.92.22-28.17,0C10.95.29,8.78-.82,5.79.98-3.92,6.82.87,29.47,3.78,51.98c3.03,23.45-3.33-10.1,3.02,76,3.33,45.17,4.44,45.63,9.05,100,4.27,50.22,7.79,102.29,8.05,121,.12,8.67,1.54,17.34,1.01,26-.31,5.01-.86,8.56,1.01,13,2.69,6.42,7.67,8.26,7.04,12-.56,3.35-4.82,3.43-8.05,7-1.9,2.09-4.7,6.78-1.01,25,4.1,20.26,8.67,19.94,9.05,32,.37,11.79-3.85,16.51,0,23,3.62,6.1,10.99,8.03,15.09,9,29.4,6.94,63.36,5.15,66.39,5,20.74-1.06,13.64,1.53,60.36,3,37.38,1.18,56.42-.02,100.59-1,78.25-1.74,67.61.93,126.75-1,40.52-1.32,102.19-4.42,113.67-5,42.16-2.12,41.55-2.5,48.29-2,23.13,1.72,37.93,6.76,44.26-1,3.18-3.9.94-6.99,1.01-21,.12-25.86,7.85-35.83,2.01-42-4.66-4.93-13.47-2.68-24.14-1-20.53,3.24-41.61-.08-62.37,1-48.55,2.52-91.31,2.22-123.73,2-19.21-.13-43.03-.53-69.41-3-8.16-.76-21.66-2.2-38.23-5-8.83-1.49-14.27-2.66-25.15-5-16.79-3.61-25.18-5.44-25.15-7,.07-3.53,30.44-5.61,36.21-6,34.48-2.36,59.43-.68,74.44,0,41.25,1.87,32.58-2.64,88.52-2,29.48.34,56.24,1.87,101.6,3,51.79,1.29,24.64-.52,35.21,0,2.18.11,8.04.07,23.14-1"
          />
        </g>
        <g id="Outlines">
          <path
            id="outline3"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M598.86,359.06c-3.26-.82-7.4-.95-13.24-1.11-19.28-.51-28.91-.76-52-1-19.33-.2-15.39-2.49-49-5-11.85-.89-23.9-1.26-48-2-25.34-.78-38.01-1.17-52-1-12.22.15-15.51.48-24,0-17.86-1.01-21.09-3.46-35-4-10.48-.41-18.32.73-34,3-12.05,1.75-17.29,3.18-37,6-3.21.46-11.84,1.65-23,1-12.73-.74-15.94-3.14-17-4-5.53-4.53-4.21-9.92-10-13-2.7-1.44-5.8-1.29-12-1-8.22.39-13.01.61-17,4-1.9,1.62-3.27,4.41-6,10-2.39,4.89-3.89,8.91-5,12-3.38,9.44-5.07,14.17-5.76,18.02-.74,4.12-3.53,19.78,5,31,3.03,3.98,3.13,1.67,15,12,6.34,5.52,8.33,7.93,12,8,3.56.07,5.49-2.12,12-7,1.34-1,9.81-7.35,15-10,8.72-4.45,15.6-2.26,26.76-2.02h0c15.17.34,22.68-3.27,36-6,24.24-4.97,40.77-1.51,74,0,41.01,1.87,32.39-2.64,88-2,29.31.34,55.91,1.87,101,3,51.48,1.29,24.49-.52,35,0,11.09.55,15.94,1.31,19.58.46"
          />
          <path
            id="outline2"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M598.86,428.11c-3.64.33-7.78,1.14-12.24,1.85-20.41,3.24-41.36-.08-62,1-48.26,2.52-90.77,2.22-123,2-19.1-.13-42.78-.53-69-3-8.11-.76-21.53-2.2-38-5-8.78-1.49-14.19-2.66-25-5-9.16-1.98-12.46-3.43-25-7-24.66-7.01-24.4-5.48-31.76-8.98-10.12-4.82-17.21-10.86-22-15-9.93-8.58-9.67-11.22-19-19-8.77-7.31-22.02-15.83-25-13-1.82,1.73,2.11,5.88,3,14,1.14,10.3-3.55,18.49-9,28-7.03,12.27-11.1,19.37-20,24-13.24,6.88-27.2,3.11-35,1-7.66-2.07-16.33-4.41-23-12-6.1-6.94-5.87-12.96-12-15-6.21-2.07-13.22,1.85-15.24,2.98h0c-1.48.83-5.34,3.04-8,7-2.54,3.78-4.14,9.38-1,25,4.08,20.26,8.62,19.94,9,32,.37,11.79-3.83,16.51,0,23,3.6,6.1,10.93,8.03,15,9,29.23,6.94,62.99,5.15,66,5,20.62-1.06,13.56,1.53,60,3,37.16,1.18,56.09-.02,100-1,77.79-1.74,67.21.93,126-1,40.28-1.32,101.59-4.42,113-5,41.91-2.12,41.3-2.5,48-2,15.54,1.16,27.31,3.84,35.24,3.06"
          />
          <path
            id="outline1"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M3.82.98c-.12,3.98-.22,9.87,0,17,.39,12.86,1.33,14.62,2.8,33.98,1.22,16.02,1.4,25.51,1.25,33.83-.13,7.75-.83,7.06,1.75,42.17,3.31,45.17,4.41,45.63,9,100,4.24,50.22,7.74,102.29,8,121,.12,8.67,1.53,17.34,1,26-.31,5.01-.85,8.56,1,13,1.85,4.45,3.76,6.28,7,12,3.24,5.71,3.65,8.55,5.24,12.02,4.5,9.79,13.64,14.96,19,18,20.87,11.82,44.67,4.97,55,2,6.21-1.79,18.67-5.52,31-16,5.79-4.92,14.83-12.61,19-26,1.35-4.33,4.84-15.54-.24-27.02-2.47-5.57-6.1-9.28-8-11-1.79-1.63-5.38-4.52-15-8-14.45-5.23-17.19-2.63-24-7-7.33-4.7-10.56-11.82-12-15-4.32-9.53-3.26-17.4-3-27,.07-2.71-1.28-15.81-4-42-2.59-24.97-3.98-26.38-6-45-4.02-37.06-2.74-70.59-2-90,.26-6.89.83-18.88.57-35.78-.27-17.51-1.06-16.17-.93-26.41,0,0,.02-1.76.09-3.55.12-3.48,3.57-23.71,9.47-51.24"
          />
        </g>
        <g id="Overview">
          <path
            id="partofancon4"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M38.86,247.98c1.6,11.28,3.99,20.44,6,27,5.27,17.15,7.9,25.72,15,33,8.62,8.84,19,17,19,17,3.8,2.99,7.49,5.66,8,10,.48,4.07-2.11,7.42-5,11-12.4,15.37-14.99,16-18,21-5.28,8.76-6.41,21.88-1,32,6.41,11.98,19.57,15.19,27,17,6.71,1.63,23.76,5.79,38-5,14.05-10.64,14.85-27.87,15-31,.18-3.87.48-21.5-11-31-6.57-5.43-10.98-3.25-21-11-7.39-5.72-11.21-11.72-12-13-4.72-7.59-5.93-14.52-7-21-.96-5.82-1.78-14.88,0-26"
          />
          <path
            id="partofancon3"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M24.88,13.98c0,117.19,4.02,212.03,8.98,212.03"
          />
          <path
            id="partofancon2"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M73.6,2.96c-12,157,6.28,272.02,12.49,272.02"
          />
          <path
            id="partofancon1"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M29.37,365.98c9.93,12.01,16.8,20.93,25.77,26.92"
          />
          <path
            id="radius5"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M167.29,413.98c16.97-36.51,8.95-68,2.33-63.02"
          />
          <path
            id="radius4"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M175.62,340.96c22.24,36.51-3.73,73.02-8.33,73.02"
          />
          <path
            id="ulna3"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M216.86,430.44c0,5.83,36,12.54,78,3.54"
          />
          <line
            id="ulna2"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            x1="204.86"
            y1="481.98"
            x2="598.86"
            y2="483.98"
          />
          <line
            id="ulna1"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            x1="612.02"
            y1="443.57"
            x2="231.86"
            y2="440.98"
          />
          <path
            id="radius3"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M216.86,357.18c2.55,2.77,13.1,3.51,19,5.8,9.93,3.86,18.84,1.37,35-3,23.65-6.39,35.55-9.6,39-10,9.45-1.11,23.67-.99,42,6"
          />
          <path
            id="radius2"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M360.86,354.98c7.6,2.12,20.16,5.2,36,7,22.53,2.56,32.91.38,61,2,20.39,1.18,15.75,2.37,39,4,26.36,1.85,36.42.59,69,2,14.21.61,25.74,1.43,33,2"
          />
          <path
            id="radius1"
            class="radiuskopfchen_nach_greenspan_right2221_cls4_nach_greenspan_right2221_cls4"
            d="M234.29,410.13c10.74-3.08,27.16-7.24,47.57-10.15,18.03-2.57,30.43-2.92,58-4,62.71-2.45,53.39-2.76,70-3,58.68-.84,51.43,4.88,96,3,21.92-.93,46-3.26,84-1,5.9.35,10.75.73,14,1"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="radialheadhighlight"
          class="RadiusHead2221Fill"
          style={{
            fill: props.colors.RadiusHead2221Fill,
            stroke: props.colors.RadiusHead2221Fill,
          }}
          d="M219.24,370.41c1.97-11.3-2.75-18.17-3.8-18.91-.7-.49-.97-.74-1.53-1.21-.29-.24-.62-.55-1-.98-.53-.6-1.08-1.32-2.43-3.88-1.99-3.76-2.15-4.73-3.54-6-.89-.82-1.73-1.24-2.32-1.53-2.98-1.49-7.51-1.29-17.5-.69-4.93.3-7.98.89-10.62,3.03-1.45,1.18-2.35,2.6-4.15,5.46-1.93,3.06-3,5.46-4.85,9.61-.6,1.35-1.89,4.3-3.35,8.18-.53,1.41-1.08,2.95-1.64,4.63-1.82,5.44-2.86,8.54-3.65,12.8-.62,3.38-2.38,13.42.95,23.6,1.83,5.6,4.08,7.88,5.31,8.93,1.15.98,1.69,1.12,4.15,2.83,2.71,1.88,4.53,3.45,7.08,5.61,5.39,4.57,8.08,6.86,8.24,7.03.74.79,2,2.19,4.1,2.78,1.55.44,2.89.23,3.39.15.44-.07,1.92-.35,5.26-2.63.72-.49,1.13-.81,2.83-2.12,5.12-3.97,5.42-4.23,6.57-5.06,2.55-1.84,3.07-2,3.66-2.43,0,0,.33-.25.64-.55.53-.51,4.25-4.43,5.57-6.58,2.48-4.03,1.84-10.44,1.01-18-.89-8.16-1.97-9.15-1.42-13.75.62-5.13,2.04-4.59,3.03-10.31Z"
        />
        <path
          id="articulatiohumeroulnarishighlight"
          class="Articulati2221Fill"
          style={{
            fill: props.colors.Articulati2221Fill,
            stroke: props.colors.Articulati2221Fill,
          }}
          d="M145.82,345.94c-2.07.77-2.04,5.59-2.02,8.09.04,6.12,1.72,10.41,2.22,12.13,3.16,10.76-8.76,25.39-17.93,32.43-10.86,8.33-22.27,9.4-31.28,10.25-5.44.51-17.23,1.5-30.2-3.24-10.09-3.68-14.29-5.93-14.29-5.93-2.9-1.47-5.26-2.84-9.17-4.04-1.15-.35-9.56-2.94-10.71-.86-.97,1.75,3.82,5.11,5.85,10.57.86,2.32,1.23,4.21,2.87,7.35,0,0,1.31,2.54,2.95,4.7,4.02,5.31,15.76,7.44,15.76,7.44,9.66,1.75,11.23.56,23.26,1.62,12.28,1.08,15.19,2.72,23.87,1.82,2.43-.25,6.79-.86,11.73-2.43,1.41-.45,27.82-9.26,34.99-31.01,1.57-4.77.39-4.59,2.97-14.83,2.73-10.88,4.79-13.72,3.28-18.67-1.21-3.96-3.75-6.72-7.26-10.52-2.56-2.77-5.08-5.52-6.88-4.85Z"
        />
      </g>
    </svg>
  );
}
