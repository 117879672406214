import style from "./style.scss";

function KreuzbeinLatLiegend1720(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 449.7 639.4">

<g id="BG">
  <path id="BG2" class="Kreuzbein_seitlich1720_cls4" d="M212,629.9c2.4-6-2.6-9.5-.9-20,1.2-7.9,5.1-13.4,7.3-16.4,8.4-11.7,15.4-9.2,22.8-19.1.6-.8,9.6-13.2,6.4-26.4-1.9-8-6.8-9.9-14.6-20-7.5-9.7-10.9-18-15.5-29.2-7.4-17.9-9.4-32.8-10.9-44.7-2-15.5-4.9-39.2,1.8-68.4,5.5-23.8,13.7-35.6,16.4-39.2,5.8-7.9,11.8-16,22.8-20,19-7,38.5,3.6,41.9,5.5,1,.5,6.1,4.1,16.2,11.1,14,9.7,17.9,12.8,23.9,12.5,6.8-.3,7.8-4.4,13.7-3.6,6.5.8,10.8,6.5,15.5,12.8,9,12.1,11.1,22.3,12.8,21.9,1.4-.4-1.1-7.8,0-21,.6-6.8,1.6-9.2,0-12.8-2.1-4.5-5.8-5-10.9-9.1-6.6-5.3-5.7-8.6-11.9-16.4-1.4-1.7-9.5-11.9-22.8-17.3-3.7-1.5-4-1.1-22.8-4.6-14.3-2.6-21.6-4-24.6-5.5-10.7-5.3-9.6-11.2-21-20-13.4-10.5-28-12.4-31-12.8-4.4-.5-20.2-2.1-36.5,7.3-15.7,9.1-22.2,23-24.6,28.2-4.6,10.2-4.9,22.6-5.5,47.4-.5,21.3,1.3,31,1.8,33.7,1.8,9.4,3.5,12,6.4,23.7,2.9,12,2.5,14.6,5.5,32.8,4.7,28.8,7,43.2,11.9,54.7,2.9,6.8,5.8,11.6,6.4,20.1.3,5-.4,8.4,1.8,12.8,2.6,5,5.7,4.9,9.1,9.1,5.9,7.3,5.3,18.4,2.7,25.5-.9,2.5-1.7,3.5-11.9,15.5-12.8,15-14.1,16.3-15.5,20-1.9,5.2-1.9,10.4-1.8,21,0,11,.1,16.5,2.7,19.1"/>
  <path id="BG1" class="Kreuzbein_seitlich1720_cls4" d="M100,162.4c2.7-13.4,7.3-23,10.9-29.2,4.3-7.3,10.5-14.2,22.8-27.3,11.2-12,16.8-18,21-21,3.2-2.3,4.9-3.1,33.7-16.4,16.6-7.7,17.6-8.1,20.1-10,10.8-8.5,14.6-18.4,20.1-17.3,3.8.8,3.4,5.9,9.1,8.2,3.6,1.5,8.5,1.4,11.9-.9,5.1-3.4,4.4-10.2,6.4-21,2.9-16.4,3.4-20.9,7.3-23.7,2.8-2,6.7-2.9,10-1.8,1.8.6,4.4,2.1,8.2,10,4.2,8.9,2.9,11.2,5.5,16.4,5,10.1,16.5,14.4,21.9,16.4,9.1,3.4,10.4.9,19.1,4.6,4.5,1.9,12.4,5.3,18.2,13.7,1.6,2.2,7.1,10.4,6.4,21-.5,7.5-3.6,8.1-6.4,18.2-.5,1.8-.7,2.7-1,3.9-1.8,8.7-1,16.8,0,20.7,2.7,9.7,12.9,14.4,19.1,18.2,18.8,11.7,28.6,27.3,42.8,50.1,10.3,16.5,14.6,29.7,22.8,54.7,9.6,29.5,13.6,52.9,14.6,59.2,3,18.4,3.2,43,3.6,92,0,0,.2,20.9-4.6,47.4,0,0-1.8,9.9-10.9,29.2-3,6.2-5.3,8.9-8.2,10-7.3,3-15.1-3.4-17.8-.4-1.8,2,1.4,5.4.5,10.4-1,5.2-5.6,6.8-10.9,11.9-5.7,5.4-10.5,12.1-20,25.5-5.2,7.3-7.8,10.9-9.1,13.7-4.4,9.1-3.7,13.6-9.1,23.7-4.6,8.6-7.8,10.3-9.1,10.9-.4.2-8.2,3.7-14.6-.9-6.6-4.7-5.5-14-5.5-14.6,1-8.2,7.5-9.3,15-19.4,1.3-1.7,10-13.8,11.5-29.8.9-10.1-1.9-14.2.9-25.5,1.1-4.4,2.4-7.5,4.6-12.8,5.4-13,8.8-16.1,11.9-23.7,3.2-8.1,3.4-14.5,3.6-26.4.4-17.3-2.4-19.5-3.6-39.2-.7-10.1-.4-21.9,0-45.6.3-16.7.6-18.8-.9-23.7-4.2-13-14.9-23.9-20-29.2-6.1-6.2-8.3-7-10.9-11.9-4.6-8.3-1.6-12.8-3.6-22.8-3.8-18.7-19.3-29.9-21-31-6.7-4.6-14-7.1-20-9.1-5.1-1.7-7-1.9-13.7-3.6-11.6-3-20.1-6.1-28.2-9.1-13.5-4.9-15.3-6.6-26.4-10.9-3.3-1.3-9.9-3.8-42.8-12.8-20.9-5.7-31.4-8.5-41.9-10.9-16.8-3.8-31.4-6.4-42.8-8.2h0Z"/>
</g>
<path id="Kreuzbein_Highligh" data-name="Kreuzbein Highligh" class="Sacrum1720Fill" style= {{
            fill: props.colors.Sacrum1720Fill,
            stroke: props.colors.Sacrum1720Fill,
          }} d="M358.6,480.9c.9-2,2.2-5,3.9-8.5,5.1-10.6,6.1-10.6,7.9-15.2,3.2-8.1,3.4-14.5,3.6-26.4.4-17.3-2.4-19.5-3.6-39.2-.7-10.1-.4-21.9,0-45.6.3-16.7.6-18.8-.9-23.7-4.2-13-14.9-23.9-20-29.2-6.1-6.2-8.3-7-10.9-11.9-4.6-8.3-1.6-12.8-3.6-22.8-3.8-18.7-19.3-29.9-21-31-6.7-4.6-14-7.1-20-9.1-5.1-1.7-7-1.9-13.7-3.6-11.6-3-20.1-6.1-28.2-9.1-13.5-4.9-15.3-6.6-26.4-10.9-3.3-1.3-9.9-3.8-42.8-12.8-20.9-5.7-31.4-8.5-41.9-10.9-16.8-3.8-31.4-6.4-42.8-8.2h0c2.7-13.4,7.3-23,10.9-29.2,4.3-7.3,10.5-14.1,22.8-27.3,11.2-12,16.8-18,21-21,3.2-2.3,4.9-3.1,33.7-16.4,16.6-7.7,17.6-8.1,20.1-10,10.8-8.5,14.6-18.4,20.1-17.3,3.8.8,3.4,5.9,9.1,8.2,3.6,1.5,8.5,1.4,11.9-.9,5.1-3.4,4.4-10.2,6.4-21,2.9-16.4,3.4-20.9,7.3-23.7,2.8-2,6.7-2.9,10-1.8,1.8.6,4.4,2.1,8.2,10,4.2,8.9,2.9,11.2,5.5,16.4,5,10.1,16.5,14.4,21.9,16.4,9.1,3.4,10.4.9,19.1,4.6,4.5,1.9,12.4,5.3,18.2,13.7,1.6,2.2,7.1,10.4,6.4,21-.5,7.5-3.6,8.1-6.4,18.2-.5,1.8-.7,2.7-1,4-1.8,8.6-1,16.8,0,20.7,2.7,9.7,12.9,14.4,19.1,18.2,18.8,11.7,28.6,27.3,42.8,50.1,10.3,16.5,14.6,29.7,22.8,54.7,9.6,29.5,13.6,52.9,14.6,59.2,3,18.4,3.2,43,3.6,92,0,0,.2,20.9-4.6,47.4,0,0-1.8,9.9-10.9,29.2-3,6.2-5.3,8.9-8.2,10-7.3,3-15.1-3.4-17.8-.4-1.8,2,1.4,5.4.5,10.4-11-.2-19.6-2-25.4-3.5-6.7-1.8-10.6-3.6-14.1-6.4-3.3-2.6-5.5-5.4-6.9-7.4Z"/>
<g id="Kreuzbein">
  <path id="Kreuzbein18" class="Kreuzbein_seitlich1720_cls2" d="M237.7,49.4c6.6,7.7,19.6,24.8,32.8,38.3,22.1,22.5,33.4,30.6,48.3,44.7,17.2,16.2,42.6,40.1,61.1,75.7,12.4,23.8,17.1,44.4,26.4,85.7,4.6,20.2,8.1,35.5,10,57.4.9,10,3.3,42.5-2.7,84.8-2.6,18.4-3.9,40.5-6.8,51.6"/>
  <path id="Kreuzbein17" class="Kreuzbein_seitlich1720_cls2" d="M353.4,286.3c13.7-17.8,40.5-44.9,71.6-51.5"/>
  <path id="Kreuzbein16" class="Kreuzbein_seitlich1720_cls2" d="M360.7,480.4c0,9.6,20.8,17.3,46.5,17.3"/>
  <path id="Kreuzbein15" class="Kreuzbein_seitlich1720_cls2" d="M286,209.8c16.9-34.4,41.4-66,56.5-70.2"/>
  <path id="Kreuzbein14" class="Kreuzbein_seitlich1720_cls2" d="M286,209.8c18.7-11.6,51-59.2,56.5-70.2"/>
  <path id="Kreuzbein13" class="Kreuzbein_seitlich1720_cls3" d="M274.1,18.4s4.4,20,7.3,25.5,17.3,32.8,17.3,32.8c0,0,11.2,21.9,15.5,29.2s31,21,31,21"/>
  <path id="Kreuzbein12" class="Kreuzbein_seitlich1720_cls3" d="M286,33.9c19.1,30.1,35.1,55.6,41.6,60.2l17.5,12.2"/>
  <path id="Kreuzbein11" class="Kreuzbein_seitlich1720_cls3" d="M244.9,29.3c0-11.1.8-20,1.8-20"/>
  <line id="Kreuzbein10" class="Kreuzbein_seitlich1720_cls3" x1="244.9" y1="29.3" x2="228.5" y2="41.2"/>
  <path id="Kreuzbein9" class="Kreuzbein_seitlich1720_cls2" d="M49.9,458.6c36-13.1,83,17.1,88.4,99.3"/>
  <path id="Kreuzbein8" class="Kreuzbein_seitlich1720_cls2" d="M139.2,624.4c30.9-100.2-14-180.9-90.7-176.2"/>
  <path id="Kreuzbein7" class="Kreuzbein_seitlich1720_cls1" d="M57.2,19.3c16,19.6,18.4,34.5,17.3,44.7-1,9.7-5.3,17.3-.9,25.5,5.1,9.5,17.6,11.6,19.1,11.8,9.4,1.4,16.8-2.3,24.6-6.4,11.9-6.2,13.9-10.7,28.2-21.9,8.1-6.3,16.7-13,29.2-19.1,22.9-11.3,33.7-15.5,33.7-15.5,4.6-1.8,11.7-4.4,14.6-10.9,1.9-4.4,1.1-8.7.9-10-1.3-6.9-5.9-10.8-7.3-11.9"/>
  <path id="Kreuzbein6" class="Kreuzbein_seitlich1720_cls3" d="M.7,98.6C51.8,71.9,116.7,21.8,196.6,9.3"/>
  <path id="Kreuzbein5" class="Kreuzbein_seitlich1720_cls3" d="M100,98.6c37.5-37.4,70.8-68.8,113.9-69.3"/>
  <path id="Kreuzbein4" class="Kreuzbein_seitlich1720_cls1" d="M323.3,618.1c4.5-1.4,7.3-4.3,10.9-8.2,4-4.2,3.4-5,7.3-9.1,5.2-5.5,8.6-6.3,8.2-8.2-.3-1.6-5.5-7.1-15.5-10-6.7-1.9-4.8,3-6.4,3.7-3.6,1.6-2,6.2-6.4,11.8-3.5,4.5-6.2,3.5-9.1,8.2-.5.9-4,6.5-1.8,10,2.2,3.5,9,3,12.8,1.8h0Z"/>
  <path id="Kreuzbein3" class="Kreuzbein_seitlich1720_cls1" d="M292.3,632.7c.3-1,3.2-10.2,11.9-12.8,4.5-1.3,11.6-1.2,13.7,2.7,2.1,3.8-.7,10.9-8.2,15.5"/>
  <path id="Kreuzbein2" class="Kreuzbein_seitlich1720_cls3" d="M210.8,629.3c2.4-6-2.6-9.5-.9-20,1.2-7.9,5.1-13.4,7.3-16.4,8.4-11.7,15.4-9.2,22.8-19.1.6-.8,9.6-13.2,6.4-26.4-1.9-8-6.8-9.9-14.6-20-7.5-9.7-10.9-18-15.5-29.2-7.4-17.9-9.4-32.8-10.9-44.7-2-15.5-4.9-39.2,1.8-68.4,5.5-23.8,13.7-35.6,16.4-39.2,5.8-7.9,11.8-16,22.8-20,19-7,38.5,3.6,41.9,5.5,1,.5,6.1,4.1,16.2,11.1,14,9.7,17.9,12.8,23.9,12.5,6.8-.3,7.8-4.4,13.7-3.6,6.5.8,10.8,6.5,15.5,12.8,9,12.1,11.1,22.3,12.8,21.9,1.4-.4-1.1-7.8,0-21,.6-6.8,1.6-9.2,0-12.8-2.1-4.5-5.8-5-10.9-9.1-6.6-5.3-5.7-8.6-11.9-16.4-1.4-1.7-9.5-11.9-22.8-17.3-3.7-1.5-4-1.1-22.8-4.6-14.3-2.6-21.6-4-24.6-5.5-10.7-5.3-9.6-11.2-21-20-13.4-10.5-28-12.4-31-12.8-4.4-.5-20.2-2.1-36.5,7.3-15.7,9.1-22.2,23-24.6,28.2-4.6,10.2-4.9,22.6-5.5,47.4-.5,21.3,1.3,31,1.8,33.7,1.8,9.4,3.5,12,6.4,23.7,2.9,12,2.5,14.6,5.5,32.8,4.7,28.8,7,43.2,11.9,54.7,2.9,6.8,5.8,11.6,6.4,20.1.3,5-.4,8.4,1.8,12.8,2.6,5,5.7,4.9,9.1,9.1,5.9,7.3,5.3,18.4,2.7,25.5-.9,2.5-1.7,3.5-11.9,15.5-12.8,15-14.1,16.3-15.5,20-1.9,5.2-1.9,10.4-1.8,21,0,11,.1,16.5,2.7,19.1"/>
  <path id="Kreuzbein1" class="Kreuzbein_seitlich1720_cls3" d="M98.9,161.8c2.7-13.4,7.3-23,10.9-29.2,4.3-7.3,10.5-14.2,22.8-27.3,11.2-12,16.8-18,21-21,3.2-2.3,4.9-3.1,33.7-16.4,16.6-7.7,17.6-8.1,20.1-10,10.8-8.5,14.6-18.4,20.1-17.3,3.8.8,3.4,5.9,9.1,8.2,3.6,1.5,8.5,1.4,11.9-.9,5.1-3.4,4.4-10.2,6.4-21,2.9-16.4,3.4-20.9,7.3-23.7,2.8-2,6.7-2.9,10-1.8,1.8.6,4.4,2.1,8.2,10,4.2,8.9,2.9,11.2,5.5,16.4,5,10.1,16.5,14.4,21.9,16.4,9.1,3.4,10.4.9,19.1,4.6,4.5,1.9,12.4,5.3,18.2,13.7,1.6,2.2,7.1,10.4,6.4,21-.5,7.5-3.6,8.1-6.4,18.2-.5,1.8-.7,2.7-1,3.9-1.8,8.7-1,16.8,0,20.7,2.7,9.7,12.9,14.4,19.1,18.2,18.8,11.7,28.6,27.3,42.8,50.1,10.3,16.5,14.6,29.7,22.8,54.7,9.6,29.5,13.6,52.9,14.6,59.2,3,18.4,3.2,43,3.6,92,0,0,.2,20.9-4.6,47.4,0,0-1.8,9.9-10.9,29.2-3,6.2-5.3,8.9-8.2,10-7.3,3-15.1-3.4-17.8-.4-1.8,2,1.4,5.4.5,10.4-1,5.2-5.6,6.8-10.9,11.9-5.7,5.4-10.5,12.1-20,25.5-5.2,7.3-7.8,10.9-9.1,13.7-4.4,9.1-3.7,13.6-9.1,23.7-4.6,8.6-7.8,10.3-9.1,10.9-.4.2-8.2,3.7-14.6-.9-6.6-4.7-5.5-14-5.5-14.6,1-8.2,7.5-9.3,15-19.4,1.3-1.7,10-13.8,11.5-29.8.9-10.1-1.9-14.2.9-25.5,1.1-4.4,2.4-7.5,4.6-12.8,5.4-13,8.8-16.1,11.9-23.7,3.2-8.1,3.4-14.5,3.6-26.4.4-17.3-2.4-19.5-3.6-39.2-.7-10.1-.4-21.9,0-45.6.3-16.7.6-18.8-.9-23.7-4.2-13-14.9-23.9-20-29.2-6.1-6.2-8.3-7-10.9-11.9-4.6-8.3-1.6-12.8-3.6-22.8-3.8-18.7-19.3-29.9-21-31-6.7-4.6-14-7.1-20-9.1-5.1-1.7-7-1.9-13.7-3.6-11.6-3-20.1-6.1-28.2-9.1-13.5-4.9-15.3-6.6-26.4-10.9-3.3-1.3-9.9-3.8-42.8-12.8-20.9-5.7-31.4-8.5-41.9-10.9-16.8-3.8-31.4-6.4-42.8-8.2h0Z"/>
</g>
</svg>
  );
}

export default KreuzbeinLatLiegend1720;
