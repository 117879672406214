import XrayIcon from "../Components/PmedIcons/Xray";

export default function SandBox(props) {
  return (
    <div className="d-flex " style={{ maxHeight: "50vh" }}>
      <XrayIcon variant="dark" />
      <XrayIcon variant="light" />
      <XrayIcon variant="danger" />
      <XrayIcon variant="warning" />
      <XrayIcon variant="success" />
    </div>
  );
}
