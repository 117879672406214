import Controller from "../Common/Controller";

import FrontView from "./views/FrontView";
import SideView from "./views/SideView";

import "./styles/Controller.scss";

function SkullController() {
  const availableViews = [FrontView, SideView];
  return <Controller availableViews={availableViews} />;
}

export default SkullController;
