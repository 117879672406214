import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import  usePageVisibility  from "../Utilities/Hooks/usePageVisibility"


function PMEDWebIntegrator () {
    const history = useHistory();
    const isPageVisible = usePageVisibility();
    const timerIdRef = useRef(null);
    const [isPollingEnabled, setIsPollingEnabled] = useState(true);

    useEffect(() => {
      const pollingCallback = () => {
        // Your polling logic here
        getCurrentBrowserFingerPrint().then((fingerprint) => {
          fetch(`${window.conf.SERVER_CONFIG.BASE_URL}/ris/integration/web/${window.conf.INTEGRATION}/exammgr/${fingerprint}`)
          .then(resp => resp.json()
          .then(response_json => {
            try {
              if (response_json) {
                const query_params = new URLSearchParams(response_json).toString();
                if (query_params !== "") {
                  history.push(`/ExamPlanner?${query_params}`);
                }  
              }
            }
            catch (error) {
              console.error(error);
              setIsPollingEnabled(false);
              console.log('Polling failed. Stopped polling.');
            }
          }))
        })
  
      };
  
      const startPolling = () => {
        // pollingCallback(); // To immediately start fetching data
        // Polling every 30 seconds
        timerIdRef.current = setInterval(pollingCallback, 1000);
      };
  
      const stopPolling = () => {
        clearInterval(timerIdRef.current);
      };
  
      if (isPageVisible && isPollingEnabled) {
        startPolling();
      } else {
        stopPolling();
      }
  
      return () => {
        stopPolling();
      };
    }, [isPageVisible, isPollingEnabled]);
      
      return <></>
  }


export default PMEDWebIntegrator;