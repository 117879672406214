import { useRef } from "react";
import { capitalize } from "../../../Utilities/stringHelpers/stringHelpers";
import { Button } from "react-bootstrap";

function BaseSearch({ field, filterSetter, className = "" }) {
  const searchRef = useRef(null);
  const updateFilter = () => {
    let filter = undefined;
    if (searchRef.current && searchRef.current.value !== "")
      if (Array.isArray(field)) {
        const filers = [];
        field.forEach((f) => {
          filers.push({
            field: f,
            op: "like",
            value: `%${searchRef.current.value}%`,
          });
          filers.push({
            field: f,
            op: "like",
            value: `%${searchRef.current.value.toLowerCase()}%`,
          });
          filers.push({
            field: f,
            op: "like",
            value: `%${searchRef.current.value.toUpperCase()}%`,
          });
          filers.push({
            field: f,
            op: "like",
            value: `%${capitalize(searchRef.current.value)}%`,
          });
        });
        filter = {
          or: filers,
        };
      } else {
        filter = {
          or: [
            {
              field: field,
              op: "like",
              value: `%${searchRef.current.value}%`,
            },
            {
              field: field,
              op: "like",
              value: `%${searchRef.current.value.toLowerCase()}%`,
            },
            {
              field: field,
              op: "like",
              value: `%${searchRef.current.value.toUpperCase()}%`,
            },
            {
              field: field,
              op: "like",
              value: `%${capitalize(searchRef.current.value)}%`,
            },
          ],
        };
      }
    filterSetter(filter);
  };

  return (
    <div className={`d-flex ${className}`}>
      <input
        className="my-2 mr-1 border rounded"
        type="search"
        ref={searchRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updateFilter();
          }
        }}
        onChange={(e) => {
          if (e.target.value === "") {
            updateFilter();
          }
        }}
      />
      <Button className="my-2 mr-2" onClick={updateFilter}>
        <i className="fas fa-search" />
      </Button>
    </div>
  );
}

export default BaseSearch;
