import {
  Container,
  Col,
  Row,
  ButtonGroup,
  Button,
  Badge,
  Modal,
} from "react-bootstrap";
import HumanBody from "../Components/Human/humanModel";
import AuthorizedElement from "../Utilities/AuthorizedElement";
import { useState } from "react";
import PatientSearchBox from "../Patients/SearchBox";
import "../Components/Human/stylesheets/ExamWizard.css";

import ExamRow from "../Components/ExamPlanner/Components/ExamRow";
// import { HumanMap } from "../Components/ExamPlanner/Mockups/ExamPlannerMockdata";

export default function ExamWizard(props) {
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [isReasonsSelected, setIsReasonsSelected] = useState(true);
  const [selectedExaminations, setSelectedExaminations] = useState({});
  const [isSummaryShown, setIsSummaryShown] = useState(false);

  const humanMap = {};
  const clearSelected = () => {
    setSelectedAreas([]);
  };

  let CompToRender = HumanBody;
  try {
    CompToRender = humanMap[selectedAreas[0]]["component"];
  } catch {
    CompToRender = HumanBody;
  }

  const togleReasonsExams = (val) => {
    setIsReasonsSelected(val);
  };

  const addRemoveExamination = (examination) => {
    if (
      Object.keys(selectedExaminations).includes(Object.keys(examination)[0])
    ) {
      let newSelectedExaminations = selectedExaminations;
      delete newSelectedExaminations[Object.keys(examination)[0]];
      setSelectedExaminations({ ...newSelectedExaminations });
    } else {
      let newSelectedExaminations = selectedExaminations;
      newSelectedExaminations[Object.keys(examination)[0]] =
        examination[Object.keys(examination)[0]];
      setSelectedExaminations({ ...newSelectedExaminations });
    }
  };

  return (
    <>
      <Modal show={isSummaryShown} onHide={() => setIsSummaryShown(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Order Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Patient Name</h5>
          <p>You're going to order</p>
          <Container className="border-bottom">
            {Object.keys(selectedExaminations).map((exam) => (
              <Row className="my-1 border-top py-1">
                <Col>{exam}</Col>
                <Col className="text-right">
                  <Button
                    size="sm"
                    onClick={() => {
                      let dict = {};
                      dict[exam] = "";
                      addRemoveExamination(dict);
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </Button>
                </Col>
              </Row>
            ))}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsSummaryShown(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              alert("Examintaion ordered");
              setIsSummaryShown(false);
            }}
          >
            Send Order
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid className="mx-0 px-0 my-0 py-0 card-container">
        <Row className="justify-content-center card-row">
          <AuthorizedElement roles={["pm_admin", "pm_user", "pm_facility"]}>
            <Col xs={12} md={6} xl={4} sm={12} className="pat-rec-col">
              <PatientSearchBox />
            </Col>
          </AuthorizedElement>
          <AuthorizedElement roles={["pm_admin", "pm_user"]}>
            <Col className="pat-rec-col col4-5">
              <div className="card card-shadow">
                <div className="card-body">
                  {CompToRender != HumanBody && (
                    <Button onClick={clearSelected}>
                      <i className="fas fa-undo-alt" />
                    </Button>
                  )}
                  <CompToRender
                    className="body-part shown"
                    selectedSetter={setSelectedAreas}
                    selected={selectedAreas}
                  />
                </div>
              </div>
            </Col>
            <Col className="pat-rec-col" md={3}>
              <div className="card card-shadow">
                <div className="card-header text-center">
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      active={isReasonsSelected}
                      onClick={() => togleReasonsExams(true)}
                    >
                      Reasons
                    </Button>
                    <Button
                      active={!isReasonsSelected}
                      onClick={() => togleReasonsExams(false)}
                    >
                      Exams
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="card-body">
                  {isReasonsSelected ? (
                    <Container fluid>
                      {selectedAreas.length > 0 &&
                        (selectedAreas.length < 2
                          ? Object.keys(humanMap[selectedAreas[0]].reasons)
                              .length > 0 &&
                            Object.keys(humanMap[selectedAreas[0]].reasons).map(
                              (reason) => (
                                <ExamRow
                                  addRemoveExam={addRemoveExamination}
                                  selectedExams={selectedExaminations}
                                  reason={reason}
                                  exams={
                                    humanMap[selectedAreas[0]].reasons[reason]
                                  }
                                />
                              )
                            )
                          : selectedAreas.map(
                              (area, i) =>
                                i > 0 &&
                                Object.keys(
                                  humanMap[selectedAreas[0]]["parts"][
                                    selectedAreas[i]
                                  ].reasons
                                ).length > 0 &&
                                Object.keys(
                                  humanMap[selectedAreas[0]]["parts"][
                                    selectedAreas[i]
                                  ].reasons
                                ).map((reason) => (
                                  <ExamRow
                                    addRemoveExam={addRemoveExamination}
                                    selectedExams={selectedExaminations}
                                    reason={reason}
                                    exams={
                                      humanMap[selectedAreas[0]]["parts"][
                                        selectedAreas[i]
                                      ].reasons[reason]
                                    }
                                  />
                                ))
                            ))}
                    </Container>
                  ) : (
                    <Container fluid className="border-bottom">
                      {selectedAreas.length > 0 &&
                        selectedAreas.map((area, i) =>
                          i == 0
                            ? selectedAreas.length == 1 &&
                              Object.entries(
                                humanMap[selectedAreas[0]]["exams"]
                              ).map(([exam, code]) => (
                                <Row
                                  className={
                                    Object.keys(selectedExaminations).includes(
                                      exam
                                    )
                                      ? "py-1 px-1 exam-row border-top active"
                                      : "py-1 px-1 exam-row border-top"
                                  }
                                  onClick={() => {
                                    let dict = {};
                                    dict[exam] = code;
                                    addRemoveExamination(dict);
                                  }}
                                >
                                  {exam}
                                </Row>
                              ))
                            : Object.entries(
                                humanMap[selectedAreas[0]]["parts"][
                                  selectedAreas[i]
                                ]["exams"]
                              ).map(([exam, code]) => (
                                <Row
                                  className={
                                    Object.keys(selectedExaminations).includes(
                                      exam
                                    )
                                      ? "py-1 px-1 exam-row border-top active"
                                      : "py-1 px-1 exam-row border-top"
                                  }
                                  onClick={() => {
                                    let dict = {};
                                    dict[exam] = code;
                                    addRemoveExamination(dict);
                                  }}
                                >
                                  {exam}
                                </Row>
                              ))
                        )}
                    </Container>
                  )}
                </div>
                <div className="card-footer">
                  <Row>
                    <Col md={8}>
                      Selected Examinations
                      <Badge pill bg="secondary">
                        {Object.keys(selectedExaminations).length}
                      </Badge>
                    </Col>
                    <Col className="text-right">
                      <Button
                        size="sm"
                        onClick={() => {
                          setIsSummaryShown(true);
                        }}
                      >
                        <i className="fas fa-save fa-sm"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </AuthorizedElement>
        </Row>
      </Container>
    </>
  );
}
