import {
  useExaminations,
  useMasKvs,
} from "../../Utilities/FetchHooks/Ris/RisHooks";
import { getDDMMYYY_HHMM } from "../../Utilities/DateTimeUtils/DateTimeUtils";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

function BodyPartCharts({ selectedBodyPart, dateRange }) {
  const examsQ = useExaminations({
    filter: { field: "anatomy_id", value: selectedBodyPart?.id, op: "eq" },
    enabled: selectedBodyPart !== undefined,
  });

  const filter = useMemo(() => {
    if (!examsQ.data?.examinations || examsQ.data.examinations.length === 0) {
      return undefined;
    }
    return {
      and: [
        {
          field: "examid",
          op: "in",
          value: examsQ.data.examinations.map((exam) => exam.id),
        },
        {
          field: "zeitstempel",
          op: ">=",
          value: getDDMMYYY_HHMM(dateRange.startDate),
        },
        {
          field: "zeitstempel",
          op: "<=",
          value: getDDMMYYY_HHMM(dateRange.endDate),
        },
      ],
    };
  }, [examsQ.data, dateRange]);

  const masKvsQ = useMasKvs({
    filter,
    enabled: filter !== undefined,
    //entries must be sorted by examid to prepare chart data properly
    sort: {
      field: "examid",
      direction: "asc",
    },
  });

  const { countChart, doseChart, averagesChart, sumsChart } = useMemo(() => {
    if (!masKvsQ.data?.maskv || masKvsQ.data?.maskv.length === 0) {
      return {};
    }
    let studies = {};
    let entryId = 0;
    masKvsQ.data.maskv.forEach((entry) => {
      if (entryId !== entry.exams.id) {
        //switch to next entry
        studies[entry.exams.id] = {
          name: entry.exams.designationde,
          count: 0,
          summas: 0,
          sumkv: 0,
          sumdose: 0,
          avgmas: 0,
          avgkv: 0,
          avgdose: 0,
        };
        entryId = entry.exams.id;
      }
      studies[entryId].count += 1;
      studies[entryId].summas += entry.mas;
      studies[entryId].sumkv += entry.kv;
      studies[entryId].sumdose += parseFloat(entry.dosis);
    });

    Object.keys(studies).forEach((study) => {
      studies[study].avgmas = roundToTwo(
        studies[study].summas / studies[study].count
      );
      studies[study].avgkv = roundToTwo(
        studies[study].sumkv / studies[study].count
      );
      studies[study].avgdose = roundToTwo(
        studies[study].sumdose / studies[study].count
      );
    });
    //end of organizing data next prepare data for charts
    let countChart = { series: [], labels: [] };
    let doseChart = { series: [], labels: [] };
    let averagesChart = {
      series: [
        { name: "average mAs", data: [] },
        { name: "average kV", data: [] },
        { name: "average uGy", data: [] },
      ],
      xaxis: {
        categories: [],
      },
    };
    let sumsChart = {
      series: [
        { name: "sum mAs", data: [] },
        { name: "sum kV", data: [] },
        { name: "sum uGy", data: [] },
      ],
      xaxis: {
        categories: [],
      },
    };

    let totalCount = Object.keys(studies)
      .map((key) => studies[key].count)
      .reduce((p, n) => p + n);

    let totalDose = Object.keys(studies)
      .map((key) => studies[key].sumdose)
      .reduce((p, n) => p + n);

    Object.keys(studies).forEach((study) => {
      countChart.series.push(roundToTwo(studies[study].count / totalCount));
      countChart.labels.push(
        `${studies[study].name} = ${studies[study].count}`
      );
      doseChart.series.push(roundToTwo(studies[study].sumdose / totalDose));
      doseChart.labels.push(
        `${studies[study].name} = ${studies[study].sumdose}uGy`
      );

      //average data
      averagesChart.xaxis.categories.push(studies[study].name);
      averagesChart.series[0].data.push(studies[study].avgmas);
      averagesChart.series[1].data.push(studies[study].avgkv);
      averagesChart.series[2].data.push(studies[study].avgdose);

      //sums data
      sumsChart.xaxis.categories.push(studies[study].name);
      sumsChart.series[0].data.push(studies[study].summas);
      sumsChart.series[1].data.push(studies[study].sumkv);
      sumsChart.series[2].data.push(studies[study].sumdose);
    });

    return { countChart, doseChart, averagesChart, sumsChart };
  }, [masKvsQ.data]);

  return (
    <div
      className="d-flex justify-content-center"
      style={{
        gap: ".5rem",
        flexWrap: "wrap",
        overflowY: "auto",
        maxHeight: "88vh",
      }}
    >
      {countChart?.series && countChart.labels && (
        <ReactApexChart
          height={"350"}
          width={"550"}
          series={countChart?.series}
          options={{
            labels: countChart?.labels,
            title: { text: "Examinations number share" },
          }}
          type="pie"
          className="bg-light border rounded"
        />
      )}
      {doseChart?.series && doseChart.labels && (
        <ReactApexChart
          width={"550"}
          height={"350"}
          series={doseChart?.series}
          options={{
            labels: doseChart?.labels,
            title: { text: "Examinations dose share" },
          }}
          type="pie"
          className="bg-light border rounded"
        />
      )}
      {averagesChart?.series && (
        <ReactApexChart
          width={"1112"}
          height={"350"}
          series={averagesChart?.series}
          options={{
            title: { text: "Examinations averages" },
            xaxis: averagesChart.xaxis,
          }}
          type="bar"
          className="bg-light border rounded"
        />
      )}
      {sumsChart?.series && (
        <ReactApexChart
          width={"1112"}
          height={"350"}
          series={sumsChart?.series}
          options={{
            title: { text: "Examinations sums" },
            xaxis: sumsChart.xaxis,
          }}
          type="bar"
          className="bg-light border rounded"
        />
      )}
    </div>
  );
}

export default BodyPartCharts;
