import { Row, Col, Button } from "react-bootstrap";
import { useState } from "react";

export default function ExamRow({
  reason,
  exams,
  addRemoveExam,
  selectedExams,
}) {
  const [isHidden, setIsHidden] = useState(true);
  return (
    <Row className="my-1">
      <Col md={8}>
        <h5>{reason}</h5>
      </Col>
      <Col md={4} className="text-right">
        <Button
          size="xs"
          onClick={() => {
            setIsHidden(!isHidden);
          }}
        >
          <i
            className={
              isHidden ? "fas fa-chevron-down fa-xs" : "fas fa-chevron-up fa-xs"
            }
          ></i>
        </Button>
      </Col>
      <Col
        md={12}
        className={
          isHidden
            ? "my-1 border-bottom exam-col hidden"
            : "my-1 border-bottom exam-col shown"
        }
      >
        {Object.entries(exams).map(([exam, code], i) => (
          <Row
            key={i+exam+"ex_row"}
            className={
              Object.keys(selectedExams).includes(exam)
                ? "py-1 px-1 exam-row border-top active"
                : "py-1 px-1 exam-row border-top"
            }
            onClick={() => {
              let dict = {};
              dict[exam] = code;
              addRemoveExam(dict);
            }}
          >
            {exam}
          </Row>
        ))}
      </Col>
    </Row>
  );
}
