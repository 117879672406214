import style from "./style.scss";

function RechtesHandGelenkDpOblSitzend1362(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.22 305.22">
      <g id="Backrounds">
        <path
          id="backround2"
          class="handgelenk_rechts_dp_obl1362_cls5"
          d="M211.14,305.19c0-1.95.03-5.66.12-10.31.16-8.2.55-39.71,8.07-99.93.79-6.33,1.57-12.65,2.36-18.98,2.9-7.45,2.91-13.27-1.06-16.86,1.37-7.1,1.41-12.24,0-15.26-2.64-3.54-5.81-5.19-10.48-1.44-4.15,5.62-8.49,9.01-13.22,8.2.6-1.83-8.13-3.35-7.9-1.53l-4.95,1.53c-.59-2.15-2.22-3.34-4.26-4.15,9.52-4.88,12.07-10.26,9.02-16.02,4.48,2.03,9.12,2.88,14.24.27,6.03-2.07,9.15-11.77,10.79-25.36,1.65-4.58.06-8.05-5.85-10.03l-4.34-5.25c2.64-4.57,3.39-9.33,2.96-14.2,2.04-3.93,2.66-7.77,2.14-11.54-1.3-2.63-2.02-5.27-2.14-7.9,1.1-4.48-.66-6.55-3.48-7.75,7.87,2.12,9.94-7.85,9.9-22.18,2.27-8.94,4.46-17.66,6.49-25.99l-112.65-.1c2.76,16.36,2.99,32.84-.39,49.49-.81,4.38-1.07,8.09-.67,10.94-2.44-1.15-5.16-1.79-8.06-2.12l1.57-1.69c1.88-3.66.52-6.32-4.25-7.93-3.11-.92-5.31-2.3-6.03-4.39-1.74-2.49-4.5-3.75-8.29-3.77-4.82-.21-9.91-2.23-15.16-5.39-8.35-6.2-16.89-15.67-25.7-29.42-1.59-1.98-2.53-3.94-2.87-5.88H.73c.03.49.04,1.02.03,1.6C-.04,5.29,2.93,9.04,10.13,13.13c10.6,10.25,24.54,27.62,39.19,46.48,4.15,8.15,8.85,14.49,15.04,15.95,3.19,3.07,6.38,3.68,9.57,2.04-2.3,3.74-2.22,6.57,1.13,8.14l4.19,8.87,7.89,6.17,10.63-1.97c.56,2.68,2.84,2.6,5.47,1.97l1.2.83c-2.22,5.39-.26,9.85,7.37,13.07-6.53,1.57-10.61,6.48-11.41,15.87.1,2.91-.42,6.05-1.56,9.42-.9,4.54-1.04,8.03,0,9.87-3.06,6.15-4.68,12.76-2.73,20.51.95,2.53,2.21,6.3,3.26,10.98,1.16,5.18,1.56,9.29,1.75,11.53,2.72,31.88,2.43,66.94,2.43,66.94-.08,9.89-.18,25.56-.18,45.4,35.92,0,71.85,0,107.77-.01Z"
        />
        <path
          id="backround1"
          class="handgelenk_rechts_dp_obl1362_cls5"
          d="M159.89,305.21c.57-6.74,1.25-14.59,1.98-24.3,1.81-24.18,1.01-21.44,2-31,1.75-16.9,5.89-41.28,16.81-70.89,1.51,17.23,2.93,39.63,3.19,65.89-2.58,27.1-1.13,48.09-.97,60.29"
        />
      </g>
      <g id="Handjoint_d.p.obl" data-name="Handjoint d.p.obl">
        <g id="Overview">
          <path
            id="radius3"
            class="handgelenk_rechts_dp_obl1362_cls6"
            d="M158.88,305.19c3.34-48.29,12.59-109.01,21.8-126.19,5.36-3.8,6.22-9.17,5.62-15.04.97-5.39.12-9.78-2.21-13.37-1.66-4.49-8.3-6.04-16.32-6.76l-7.59-5.39c-8.16-.79-15.93-1.99-20.96-6.12l-12.76-11.04c-4.04-6.33-8.72-7.19-13.6-6.31-6.71.92-11.6,4.88-12.49,15.58.08,2.83-.37,5.93-1.56,9.42-.92,3.56-1.11,6.91,0,9.87-3.63,8.74-4.93,15.85-2.73,20.51,6.81,4.1,8.91,79.83,7.23,134.84"
          />
          <line
            id="radius2"
            class="handgelenk_rechts_dp_obl1362_cls6"
            x1="98.81"
            y1="149.84"
            x2="100.36"
            y2="162.75"
          />
          <path
            id="radius1"
            class="handgelenk_rechts_dp_obl1362_cls6"
            d="M122.29,134.5c4.35,2.65,8.1,5.78,11.3,9.34,5.41,5.19,16.31,8.63,30.08,11.16,3.01.79,7.22,1.9,11.54,3.04"
          />
          <path
            id="ulnar2"
            class="handgelenk_rechts_dp_obl1362_cls6"
            d="M211.03,305.19c.64-43.24,5.15-86.51,10.67-129.23,2.88-7.98,2.81-13.8-1.06-16.86,1.57-6.55,1.26-11.35,0-15.26-3.07-3.33-6.34-5.32-10.48-1.44-2.66,5.44-6.42,9.17-13.22,8.2-2.19-2.05-4.83-2.97-8.51-1.27-6.03.25-10.6,2.29-11.24,9.17-.35,7.13,5.43,27.39,5.43,27.39.98,41.19.81,80.42-.75,119.3"
          />
          <path
            id="ulnar1"
            class="handgelenk_rechts_dp_obl1362_cls6"
            d="M190.86,151.32c5.62.85,10.66,1.94,14.44,3.53,1.74,1.86,4.57,2.21,8.19,1.44,4.15-2.59,6.2-1,7.14,2.81-1.73,5.34-2.23,10.81-1.67,16.41"
          />
          <g id="Carpalbones">
            <path
              id="carpalbone10"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M97.74,98.8h0c1.77,2.05,3.6,2.63,5.47,1.97.18-5.01,3.33-8.96,8.2-12.3,4.67-1.81,8.47-4.04,10.48-7.14,1.73-3.23-2.33-6.58-9.42-10l-7.89-7.93c-3.94-1.34-8.72.42-14.01,4.08l-13.97,8.72c-5.32,3.13-6.07,6.15-1.94,9.07,1.52,1.37,2.81,8.82,6.23,10.94l6.23,4.56,10.63-1.97h0Z"
            />
            <path
              id="carpalbone9"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M159.11,75.27h.01c-1.24,8.85-14.22,15.84-33.42,21.84-3.15.98-6.53-.84-7.31-4.05-.71-2.9-.71-7.24.17-13.39-.66-4.25-.02-8.66,1.97-13.22,3.29-4.68,7.73-4.34,12.76-1.52,5.24,1.8,10.82,2.81,16.8,2.89,1.11.02,2.22-.19,3.31-.44,4.05-.93,6,1.61,5.71,7.88h0Z"
            />
            <path
              id="carpalbone8"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M110.63,114.23h0v.02c.71.31,1.45.6,2.22.74,2.1.39,3.7,2.27,5.07,4.83.96,1.79,2.36,3.35,4.17,4.27,2.88,1.45,5.18,4.08,7.05,7.55.99,1.83,2.34,3.44,3.97,4.72l3.97,3.12c.94.74,2,1.33,3.13,1.7,6.88,2.27,12.85,3.03,18.06,2.54,1.77-.17,3.36-1.18,4.19-2.75,2.4-4.57,2.8-13.98,2.73-24.53-.71-8.1-6.12-12.39-14.13-14.58-5.58-1.38-8.45-5.47-9.18-11.7-.5-4.41-4.18-6.4-12.86-4.61-3.47.72-6.76,2.15-9.68,4.16l-11.76,8.08c-5.23,3.59-5.29,11.47.07,14.88.87.55,1.86,1.07,2.98,1.56h0Z"
            />
            <path
              id="carpalbone7"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M213.94,104.73l-1.06,8.81c-1.15,8.35-4.38,15.22-12.61,18.54-2.94,1.19-6.25,1.02-9.07-.43l-2.37-1.22c-6.79-.2-10.19-4.9-12.11-11.57-2.05-6.15,1.21-13.01,9.27-20.51,4.11-5.85,7.88-9.74,10.94-9.42,4.34-2.01,7.85,1.32,11.09,6.38,5.96,2.56,7.89,5.71,5.92,9.42Z"
            />
            <path
              id="carpalbone6"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M156.65,55.63h.02c-4.92.09-10.75.19-10.75.19-5.75-.54-8.62,2.05-7.39,9.11,1.27,7.51,4.12,14.73,9.06,21.57-2.99,8.64-4.88,16.7-2.43,22.48,1.69,6.04,8.07,9.28,16.86,11.09,10.38-.19,17.82-4.07,23.25-10.48,5.84-3.17,8.58-7.7,10.16-12.76,1.66-.88,3.17-1.81,4.51-2.8,4.3-3.17,6.71-8.3,6.71-13.64v-4.52c2.31-4.52,2.85-8.3,2.14-11.54-1.22-2.42-1.98-5.03-2.14-7.9,1.23-4.68-.18-7.09-6.52-5.3-4.97,1.24-10.21,1.13-15.65,0-6.41-.27-12.68.78-18.8,3.34-1.63.68-3.36,1.06-5.13,1.09-1.11.02-2.47.05-3.9.07h0Z"
            />
            <path
              id="carpalbone5"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M202.99,99.41c.28,2.61-.14,5.24-1.11,7.68-.73,1.86-1.59,3.54-2.61,4.98-1.56,2.2-4.08,3.65-6.62,4.17-2.54.52-5.07.62-7.59.32-5.8-.7-10.84-4.33-13.6-9.48l-5.21-9.7c-2.18-6.51-1.19-11.5,2.16-15.37,3.44-3.12,6.88-3.76,10.32-1.94,2.01,1.06,4.16,1.83,6.4,2.23,3.19.57,5.72,1.93,7.86,3.75,6.64,2.25,9.35,7.13,10.01,13.37h-.01Z"
            />
            <path
              id="carpalbone4"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M159.88,47h0c8.95-2.35,10.99.29,16.25,1.67l7.89,3.95c3.55,1.77,5.86,5.46,5.59,9.42-.21,3.1-1.39,6.63-3.31,10.48v28.51c-.56,5.06-1.7,8.44-3.88,8.8-1.95,1.07-5.1.97-9.14,0l-2.31-3.68c.59-9.7.44-18.52-1.88-24.73-2.43-3.82-3.25-8.24-3.14-13-2.24.3-4.42.33-6.08-2.58l-1.97-8.43c-.42-1.79-1.12-3.49-1.91-5.15-1.39-2.92-.13-4.68,3.88-5.25h.01Z"
            />
            <path
              id="carpalbone3"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M114.29,72.47c.5-7.72-2.63-11.26-8.49-11.64-2.9-1.42-5.78-1.93-8.65-1.45l-12.47,5.4c-7.73,4.44-13.28,8.94-15.65,13.56"
            />
            <path
              id="carpalbone2"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M177.45,121.07c-6.66,2.52-13.32,4.09-20.01,3.1-9.14-.95-13.9-7.2-16.6-15.95-1.5-4.67-.49-10.76,1.16-17.2"
            />
            <path
              id="carpalbone1"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M148.69,134.33h0l-1.23-4.39c-.81-2.87-.89-5.94.04-8.78,1.2-3.65,3.58-5.2,6.75-5.33,6.04-1.36,12,.17,17.91,3.16,2.09,1.06,4.31,1.83,6.57,2.47,2.42.69,4.62,1.88,6.52,3.78,4.47,5.05,6.27,9.97,3.22,14.67-.49.75-1.1,1.43-1.77,2.03-5.84,5.23-13.74,7.95-24.4,7.29-7.71-.41-11.01-3.24-11.9-7.4-.54-2.51-1.02-5.04-1.71-7.51h0Z"
            />
          </g>
          <g id="Fingers">
            <path
              id="partoffinger18"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M36.86.12c.22.89.58,1.78,1.02,2.67,12.65,21.65,26.48,36.83,42.84,38.13,4.82.29,7.17,1.74,8.29,3.77,1.01,1.82,2.74,3.11,4.69,3.84,3.01,1.13,4.93,2.37,5.94,3.71h0c1.09,1.43.9,3.47-.34,4.77-2.49,2.61-4.51,5.05-5.71,7.21-.74,1.32-1.75,2.46-3.03,3.26-6.68,4.17-12.59,7.85-15.61,9.74-3.66,1.34-7.29,2.34-10.63-1.67-4.34-.15-9.3-5.03-15.04-15.95C33.83,39.94,18.87,21.14,10.09,13.12,3.53,9.15-.51,5.34.72,1.84c.2-.56.23-1.16.14-1.74"
            />
            <path
              id="partoffinger17"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M127.75.23c3.44,16.84,8.47,30.44,15.71,39.49,6.41,6.83,10.6,12.81,11.95,17.73h0c.92,3.36-1.46,6.82-4.93,7.13-2.26.2-5.16-.06-9-1-4.24-1.41-8.37-1.22-12.15,3.95-4.89,6.54-9.9,8.61-15.05,4.95-.58-.42-1.19-.8-1.82-1.13-6.06-3.11-8.2-10.13-6-21.45,2.05-9.79,2.77-20.07,2.44-30.74-.2-6.29-.91-12.56-2.04-18.75"
            />
            <path
              id="partoffinger16"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M173.85,48.52c-6.86,3.6-14.09,4.87-21.65,4.1-4.23-.67-8.13-.5-11.32,1.52-3.54-.45-1.85-8.1,1.24-17.67.32-.97.16-2-.25-2.94-.98-2.23.35-5.43,2.44-8.96,1.56-2.62,2.47-5.57,2.6-8.62.23-5.26.32-10.46.27-15.62"
            />
            <path
              id="partoffinger15"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M170.91.23c.06,3.33.52,6.62,1.33,9.97,1.39,5.71,3.82,11.11,6.84,16.15,3.86,6.45,4.8,10.85.54,11.54"
            />
            <path
              id="partoffinger14"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M141.87,33.52c.93,1.92,2.13,3.1,3.72,3.15,2.1,0,4.17,1.19,6.23,3.65,1.21,2.49,2.48,3.71,3.79,3.95"
            />
            <path
              id="partoffinger13"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M196.02.99c-1.21,14.52,2.38,25.4,7.14,35.39,1.49,6.23.69,9.61-3.34,8.97h0l-23.7,3.34c-4.54.07-7.9-3.79-10.63-9.78,1.43-1.02,1.85-2.93,1.67-5.37.04-6.17,2.44-12.44,6.41-18.78,1.96-3.95,3.36-8.81,4.29-14.43"
            />
            <path
              id="partoffinger12"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M198.49.33c-1.58,4.35-3.41,8.77-5.5,13.26l-14.43,24.15c-1.47,5.41,1.58,8.93,8.2,10.95h0c1.53,3.81,8.17,2.68,16.41,0,7.94,1.95,10.37-7.54,9.87-23.09,1.47-7.14,3.58-15.43,5.84-24.14"
            />
            <path
              id="partoffinger11"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M73.9,42.13c-6.52.39-12.79-2.04-18.68-8.61-7.26-7.45-13.59-15.47-18.99-24.04-2.32-2.59-4.15-5.51-5.56-8.72"
            />
            <path
              id="partoffinger10"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M14.5,10.7c16.48,11.95,29.63,28.69,39.8,49.67"
            />
            <path
              id="partoffinger9"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M120.87.33l.26,2.17c3.65,19.03,9.41,29.37,16.41,34.63"
            />
            <path
              id="partoffinger8"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M112.96.33c1.86,14.68,1.4,28.24-.64,41.06"
            />
            <path
              id="partoffinger7"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M155.6.33c-.01,1.82-.06,3.6-.15,5.36-3.04,9.98-5.05,18.46-5.15,24.15"
            />
            <path
              id="partoffinger6"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M175.52,27.34c-5.49-8.44-9.19-16.65-10.32-24.54-.17-.8-.33-1.63-.48-2.47"
            />
            <path
              id="partoffinger5"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M177.68,12.53c1.88-3.85,3.38-7.92,4.54-12.2"
            />
            <path
              id="partoffinger4"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M191.88.33l-.72,10.37"
            />
            <path
              id="partoffinger3"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M214.69.33l-3.65,15.12c-1.09,4.5-1.42,9.13-1.31,13.76.09,3.69-.51,7.29-1.71,10.81"
            />
            <path
              id="partoffinger2"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M187.51,26.49c4.64-3.71,8.73-8.13,11.7-13.97,2.12-3.87,3.91-7.95,5.43-12.19"
            />
            <path
              id="partoffinger1"
              class="handgelenk_rechts_dp_obl1362_cls6"
              d="M76.6,76.21c3.46,1.02,6.07-.2,7.78-3.84l2.28-6.23c.49-3.98,2.35-6.85,5.03-8.96"
            />
          </g>
        </g>
        <g id="Highlights">
          <path
            id="Radiushighlight"
            class="Radius1362Fill"
            style={{
              fill: props.colors.Radius1362Fill,
              stroke: props.colors.Radius1362Fill,
            }}
            d="M158.88,305.19c.88-11.98,2.13-25.66,3.93-40.74,1.28-10.72,2.37-18.03,4.55-32.66,0,0,3.28-22,8.49-40.35,1.42-5.01,3.44-9.51,3.44-9.51.56-1.25,1.06-2.25,1.39-2.91,4.36-3.72,5.45-6.66,5.59-8.72.03-.44,0-.61.1-4.65.05-2.17.08-2.96.1-4.45,0-.13.03-2.99,0-3.94-.09-2.54-1.22-6.87-4.35-9.61-3.63-3.17-9.37-3.68-11.73-3.54-.47.03-1.45.12-2.62-.28-1.35-.46-1.78-1.17-3.55-2.65-1.74-1.46-2.66-2.23-4.04-2.74-1.31-.48-1.91-.31-4.25-.5-2.38-.19-4.11-.56-5.76-.91-2.36-.5-5-1.06-7.79-2.53-1.28-.68-2.17-1.32-4.85-3.54-4.88-4.04-7.4-6-11.07-9.69-4.33-4.35-4.22-4.95-6.02-5.79-4.88-2.24-11.93-.71-15.88,3.54-3.48,3.75-3.98,9.01-4.15,10.82-.24,2.56.24,2.78-.2,5.26-.59,3.3-1.79,4.84-2.33,8.7-.33,2.35-.08,3.24.2,3.84.33.7.76,1.11.81,1.92.07,1.05-.56,1.87-.81,2.22-.72,1.01-1.22,2.66-2.22,5.97,0,0-1.31,4.3-.81,8.9.14,1.32.4,2.26.81,3.13.48,1.02.9,1.4,1.42,2.22.81,1.31,1.11,2.55,1.42,4.25,1.92,10.64,2.02,13.85,2.02,13.85.27,8.52.71,1.17,1.82,22.85.8,15.73.99,27.81,1.01,29.12.36,24.81.06,49.12-.18,63.1,18.5,0,37.01,0,55.51-.01Z"
          />
          <path
            id="Ulnahighlight"
            class="Ulna1362Fill"
            style={{
              fill: props.colors.Ulna1362Fill,
              stroke: props.colors.Ulna1362Fill,
            }}
            d="M211.14,305.19c.53-20.35,1.61-41.73,3.42-64.04,1.85-22.83,4.29-44.59,7.13-65.18.4-.89,2.72-6.22,2.2-10.72-.2-1.74-.35-2.99-1.31-4.04-.78-.85-1.68-1.11-1.94-2.09-.27-1.01.36-1.98.53-2.26.83-1.4,1.23-4.69-.3-12.74-3.76-3.73-6.22-4.12-7.79-3.74-3.48.84-3.21,5.64-8.7,8.8-1.24.71-4.63,2.66-7.44,1.44-1.49-.65-1.95-1.81-3.69-2.25-1.78-.45-3.47.23-4.55.81-1.68-.05-6.21.05-9.1,3.24,0,0-1.35,1.48-2.02,3.74-2.35,7.88,4.47,28.05,5.05,29.74.33,17.17.47,34.67.41,52.47-.07,22.79-.48,45.07-1.16,66.83,9.76,0,19.51,0,29.27,0Z"
          />
          <path
            id="Carpalbonehighlight"
            class="HWK1362Fill"
            style={{
              fill: props.colors.HWK1362Fill,
              stroke: props.colors.HWK1362Fill,
            }}
            d="M213.99,105.49l.74-3.68-2.04-3.16-4.68-2.64-2.23-3.12-2.05-2.06,1.91-3.65.97-4.28.18-6.46,1.83-4.78.5-3.54-.29-3.03-1.39-3.5-.71-3.66.06-1.13.3-1.9-.15-1.63-.63-1.37-1.62-.6h-1.74s-2.62.55-2.62.55l-2.76.57-4.04.35-3.85-.12-3.62-1.17c-1.2.06-2.98.04-5.06-.4-2.94-.63-4.79-1.77-6.07-2.43-4-2.06-10.1-3.42-15.17-2.02-2.02.56-2.03,1.04-8.94,5.27-3.44,2.11-6.3,3.74-8.17,4.78l-1.88.68-1.65,1.66-.84,2.09v2.52l-.71-.04-2.42.12-2.76,1.48-.59-.29-3.52-1.29h-2.98l-2.49,1.26-2.25,2.33-1.16,3.11-.95,4.65-.55-.09-3.57-1.87.04-1.21v-1.89s-.92-3.53-.92-3.53l-1.67-2.76-2.69-1.38-3.26-.65-2.27-.93-3.17-.65-2.53.03-1.59.49-10.89,4.72-3.29,1.85-7.36,5.27-4.18,4.36-1.5,2.37h.71l3.59-.14-1.49,3.1.67,1.81,2.56,2.63,3.45,7.77,1.38,1.88,7.4,5.35,9.42-1.71h1.24l.79.77,1.88,1.06,1.39.17,1.83,1.65v4.77l1.78,3.42,2.03,1.8,4.54,2.16,2.43.69,1.89,2.08,1.83,3.16,2.15,2.34,2.29,1.37,2.24,1.61,2.79,3.32,2.46,3.98,2.13,2.32,6.1,4.73,4.94,1.74,7.33,1.25,2.59,3.35,2.78,1.39,4.04.95,6.69.22,6.78-.84,3.78-1.07,6.42-3.34,3.79-3.1,1.24-1.95.93-2.43.15-2.75-1.08-3.38.73.38,4.85,2.03h2.27l3.69-.86,3.28-1.55,3.81-3.25,2.68-4.29,1.61-4.22,1.14-5.79c.34-2.73.67-5.45,1.01-8.18Z"
          />
          <path
            id="Distalulnahighlight"
            class="Handgelenk1362Fill"
            style={{
              fill: props.colors.Handgelenk1362Fill,
              stroke: props.colors.Handgelenk1362Fill,
            }}
            d="M212.96,142.29c-1.93,1.51-4.45,3.24-4.45,3.24s-2.66,1.82-5.06,3.24c-3.55,2.1-15.82,4.69-34.58,1.62-4.23-.69-18.9-3.2-31.55-10.31-4.01-2.26-6.58-3.7-9.91-6.27-5.95-4.6-6.47-6.73-10.52-8.09-5.1-1.72-7.05.73-10.72-1.62-3.13-2-4.34-5.47-5.26-8.09-.14-.4-2.3-6.68-.61-7.69,1.03-.61,2.73,1.2,5.46,2.83,4.94,2.95,9.22,2.88,12.34,3.44,11.48,2.06,11.06,13.38,25.89,19.42,2.2.9,8.02,2.14,19.66,4.63,6.7,1.43,8.13,1.59,9.8,1.59,3.81,0,7.5-.82,19.08-6.63,11.92-5.97,17-9.6,25.08-9.3,4.07.15,5.08,1.16,5.46,1.82,2.16,3.71-5.61,12.65-10.11,16.18Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default RechtesHandGelenkDpOblSitzend1362;
