import style from "./style.scss";

function LinkerVorfussLatLiegend1431(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 442.42 171.88">
      <g id="fore_foot" data-name="fore foot">
        <g id="background_layer" data-name="background layer">
          <path
            id="background_1"
            data-name="background 1"
            class="vorfuss_lat_links1431_cls_3"
            d="M1.6,113.73c29.5-1.4,49.6-14,55-44.2,4.2-27.9-6.7-50.2-40-64.3C13.8.73,8.2-.17,0,1.83"
          />
          <path
            id="background_2"
            data-name="background 2"
            class="vorfuss_lat_links1431_cls_3"
            d="M60.9,107.83c-7.9,18,.5,25.5,23.2,23.9,10.8-.8,21.2-4.4,30.6-9.9,44.9-26.3,98.1-35.5,149.1-22.5,8.4,2.2,16,6.4,22.5,12,12.2,10.5,22.1,2.3,29.7-23.4,2.3-7.6,1.6-16-2.2-22.9-8-14.3-20.6-19.6-39.9-12-43.7-2-94.5-12.3-150.3-28.6-30.9-10.1-55.9-15.1-50.3,6.4,6.4,34.6-.7,57.8-12.4,77h0Z"
          />
          <path
            id="background_3"
            data-name="background 3"
            class="vorfuss_lat_links1431_cls_3"
            d="M.4,43.63c.5,40.3,1.4,80.6,2.7,120.9,23.9-5.3,37.7-18.6,44-37.7,10.6-35.1-.4-64.1-46.7-83.2Z"
          />
          <path
            id="background_4"
            data-name="background 4"
            class="vorfuss_lat_links1431_cls_3"
            d="M3.3,171.43c31-14.4,47.7-30.8,29.6-52.3-4.7-8.2-15.9-16.3-31.7-24.3.5,25.6,1.2,51.1,2.1,76.6"
          />
          <path
            id="background_5"
            data-name="background 5"
            class="vorfuss_lat_links1431_cls_3"
            d="M108.4,82.03c-58.8-1.8-74,10-56.6,32-3.6,36.9,4.4,50,27.2,32.7,44.6-23.2,91.6-36.4,142.3-33.5,21.1-5.8,30-13.6,26.8-23.4-2.1-6.5-8.6-10.5-15.4-10.3l-124.3,2.5h0Z"
          />
          <path
            id="background_6"
            data-name="background 6"
            class="vorfuss_lat_links1431_cls_3"
            d="M256.2,106.13c-14,7.7,2.3,12.5,23.4,16.7,18.7,13.9,32.5,17,36.6-1.1-3.3-6.7-7.2-13.7-18-23.5-21.3-15.6-37.8-23.3-39.6-6.7l-2.4,14.6h0Z"
          />
          <path
            id="background_7"
            data-name="background 7"
            class="vorfuss_lat_links1431_cls_3"
            d="M119.2,150.43l70.1-4c.6-.2,1.1-.5,1.7-.7,26.1-11.7,14-50.9-14.2-45.8-4.9.9-10.2,2.3-15.9,4.2-11.4,3.8-23,6.9-34.8,9.5-19.6,4.3-27,10.6-29.5,17.9h0c1.3,11.2,11.2,19.5,22.6,18.9h0Z"
          />
          <path
            id="background_8"
            data-name="background 8"
            class="vorfuss_lat_links1431_cls_3"
            d="M232,127.23c-16.2-20-25.2-23.9-21.3,1.4-26.6,25-10.8,31,7.1,36,43.6,3.4,61-1,47.4-14.6l-33.2-22.8h0Z"
          />
          <path
            id="background_9"
            data-name="background 9"
            class="vorfuss_lat_links1431_cls_3"
            d="M376.1,144.13c-2,7-9.6,10.8-21.3,12.1-20.9-.9-36.7-7.1-48-18,12.6-3.9,15-12.2,9.6-23.6,17.5-3.8,35.7,1.7,54.6,16,4.2,3.1,6.5,8.4,5.1,13.5h0Z"
          />
        </g>
        <path
          id="fore_foot_5"
          data-name="fore foot 5"
          class="vorfuss_lat_links1431_cls_5"
          d="M2.2,113.73c17.4-1.1,34.4-6.1,45.4-20.1,10-13,12-29,10-45C53.6,29.63,39.6,14.63,21.6,7.63c-2-1-4-3-6-5C12.6,1.63,9.6-.37,6.2.93"
        />
        <path
          id="fore_foot_7"
          data-name="fore foot 7"
          class="vorfuss_lat_links1431_cls_5"
          d="M3.6,164.53c23.9-5.3,37.7-18.6,44-37.7,10.5-34.7-.1-63.4-45-82.5-.1,1.1-.8,1.8-1.6,2.2"
        />
        <path
          id="fore_foot_10"
          data-name="fore foot 10"
          class="vorfuss_lat_links1431_cls_5"
          d="M3.9,171.43c31-14.4,47.7-30.8,29.6-52.3-4.7-8.2-15.9-16.3-31.7-24.3"
        />
        <path
          id="fore_foot_1"
          data-name="fore foot 1"
          class="vorfuss_lat_links1431_cls_6"
          d="M63.7,127.33c12.4-12.7,22.1-27.2,24.8-45.7,2-24.7-2.6-44.3-12.9-59.8"
        />
        <path
          id="fore_foot_2"
          data-name="fore foot 2"
          class="vorfuss_lat_links1431_cls_7"
          d="M321.7,102.83c-18.7,24.7-9.1,30.6,17.2,25.3l87.7-19.5c12.2-1.5,17.1-5.5,14.3-12-2.7-6.2-9.5-9.5-16.1-8.6-29,3.5-58.2-.6-87.6-16.3-3.2-1.7-7.1.8-6.9,4.4.7,13.1-2.5,21.5-8.6,26.7h0Z"
        />
        <path
          id="fore_foot_6"
          data-name="fore foot 6"
          class="vorfuss_lat_links1431_cls_6"
          d="M61.5,107.83c-7.9,18,.5,25.5,23.2,23.9,10.8-.8,21.2-4.4,30.6-9.9,44.9-26.3,98.1-35.5,149.1-22.5,8.4,2.2,16,6.4,22.5,12,12.2,10.5,22.1,2.3,29.7-23.4,2.3-7.6,1.6-16-2.2-22.9-8-14.3-20.6-19.6-39.9-12-43.7-2-94.5-12.3-150.3-28.6-30.9-10.1-55.9-15.1-50.3,6.4,6.3,34.6-.7,57.8-12.4,77h0Z"
        />
        <path
          id="fore_foot_11"
          data-name="fore foot 11"
          class="vorfuss_lat_links1431_cls_5"
          d="M108.9,82.03c-58.8-1.8-74,10-56.6,32-3.6,36.9,4.4,50,27.2,32.7,44.6-23.2,91.6-36.4,142.3-33.5,21.1-5.8,30-13.6,26.8-23.4-2.1-6.5-8.6-10.5-15.4-10.3l-124.3,2.5h0Z"
        />
        <path
          id="fore_foot_12"
          data-name="fore foot 12"
          class="vorfuss_lat_links1431_cls_5"
          d="M256.7,106.13c-14,7.7,2.3,12.5,23.4,16.7,18.7,13.9,32.5,17,36.6-1.1-3.3-6.7-7.2-13.7-18-23.5-21.3-15.6-37.8-23.3-39.6-6.7l-2.4,14.6h0Z"
        />
        <path
          id="fore_foot_13"
          data-name="fore foot 13"
          class="vorfuss_lat_links1431_cls_5"
          d="M119.7,150.33l70.1-4c.6-.2,1.1-.5,1.7-.7,26.1-11.7,14-50.9-14.2-45.8-4.9.9-10.2,2.3-15.9,4.2-11.4,3.8-23,6.9-34.8,9.5-19.6,4.3-27,10.6-29.5,17.9h0c1.4,11.3,11.3,19.6,22.6,18.9h0Z"
        />
        <path
          id="fore_foot_14"
          data-name="fore foot 14"
          class="vorfuss_lat_links1431_cls_5"
          d="M232.6,127.23c-16.2-20-25.2-23.9-21.3,1.4-26.6,25-10.8,31,7.1,36,43.6,3.4,61-1,47.4-14.6l-33.2-22.8h0Z"
        />
        <path
          id="fore_foot_15"
          data-name="fore foot 15"
          class="vorfuss_lat_links1431_cls_5"
          d="M376.7,144.13c-2,7-9.6,10.8-21.3,12.1-20.9-.9-36.7-7.1-48-18,12.6-3.9,15-12.2,9.6-23.6,17.5-3.8,35.7,1.7,54.6,16,4.1,3.1,6.5,8.4,5.1,13.5h0Z"
        />
      </g>
      <g id="overlay">
        <g
          id="Phalangen_overlay"
          data-name="Phalangen overlay"
          class="vorfuss_lat_links1431_cls_1"
        >
          <path
            id="Phalangen_overlay_1"
            data-name="Phalangen overlay 1"
            class="phalangen1431Fill"
            style={{
              fill: props.colors.phalangen1431Fill,
              stroke: props.colors.phalangen1431Fill,
              opacity: props.colors.phalangen1431Fill,
            }}
            d="M322.2,102.83c-18.7,24.7-9.1,30.6,17.2,25.3l87.7-19.5c12.2-1.5,17.1-5.5,14.3-12-2.7-6.2-9.5-9.5-16.1-8.6-29,3.5-58.2-.6-87.6-16.3-3.2-1.7-7.1.8-6.9,4.4.7,13.1-2.5,21.5-8.6,26.7h0Z"
          />
          <path
            id="Phalangen_overlay_2"
            data-name="Phalangen overlay 2"
            class="phalangen1431Fill"
            style={{
              fill: props.colors.phalangen1431Fill,
              stroke: props.colors.phalangen1431Fill,
              opacity: props.colors.phalangen1431Fill,
            }}
            d="M62,107.83c-7.9,18,.5,25.5,23.2,23.9,10.8-.8,21.2-4.4,30.6-9.9,44.9-26.3,98.1-35.5,149.1-22.5,8.4,2.2,16,6.4,22.5,12,12.2,10.5,22.1,2.3,29.7-23.4,2.3-7.6,1.6-16-2.2-22.9-8-14.3-20.6-19.6-39.9-12-43.7-2-94.5-12.3-150.3-28.6-30.9-10.1-55.9-15.1-50.3,6.4,6.3,34.6-.7,57.8-12.4,77h0Z"
          />
          <path
            id="Phalangen_overlay_3"
            data-name="Phalangen overlay 3"
            class="phalangen1431Fill"
            style={{
              fill: props.colors.phalangen1431Fill,
              stroke: props.colors.phalangen1431Fill,
              opacity: props.colors.phalangen1431Fill,
            }}
            d="M109.4,82.03c-58.8-1.8-74,10-56.6,32-3.6,36.9,4.4,50,27.2,32.7,44.6-23.2,91.6-36.4,142.3-33.5,21.1-5.8,30-13.6,26.8-23.4-2.1-6.5-8.6-10.5-15.4-10.3l-124.3,2.5h0Z"
          />
          <path
            id="Phalangen_overlay_4"
            data-name="Phalangen overlay 4"
            class="phalangen1431Fill"
            style={{
              fill: props.colors.phalangen1431Fill,
              stroke: props.colors.phalangen1431Fill,
              opacity: props.colors.phalangen1431Fill,
            }}
            d="M257.2,106.13c-14,7.7,2.3,12.5,23.4,16.7,18.7,13.9,32.5,17,36.6-1.1-3.3-6.7-7.2-13.7-18-23.5-21.3-15.6-37.8-23.3-39.6-6.7l-2.4,14.6h0Z"
          />
          <path
            id="Phalangen_overlay_5"
            data-name="Phalangen overlay 5"
            class="phalangen1431Fill"
            style={{
              fill: props.colors.phalangen1431Fill,
              stroke: props.colors.phalangen1431Fill,
              opacity: props.colors.phalangen1431Fill,
            }}
            d="M120.2,150.33l70.1-4c.6-.2,1.1-.5,1.7-.7,26.1-11.7,14-50.9-14.2-45.8-4.9.9-10.2,2.3-15.9,4.2-11.4,3.8-23,6.9-34.8,9.5-19.6,4.3-27,10.6-29.5,17.9h0c1.4,11.3,11.3,19.6,22.6,18.9h0Z"
          />
          <path
            id="Phalangen_overlay_6"
            data-name="Phalangen overlay 6"
            class="phalangen1431Fill"
            style={{
              fill: props.colors.phalangen1431Fill,
              stroke: props.colors.phalangen1431Fill,
              opacity: props.colors.phalangen1431Fill,
            }}
            d="M233.1,127.23c-16.2-20-25.2-23.9-21.3,1.4-26.6,25-10.8,31,7.1,36,43.6,3.4,61-1,47.4-14.6l-33.2-22.8h0Z"
          />
          <path
            id="Phalangen_overlay_7"
            data-name="Phalangen overlay 7"
            class="phalangen1431Fill"
            style={{
              fill: props.colors.phalangen1431Fill,
              stroke: props.colors.phalangen1431Fill,
              opacity: props.colors.phalangen1431Fill,
            }}
            d="M377.2,144.13c-2,7-9.6,10.8-21.3,12.1-20.9-.9-36.7-7.1-48-18,12.6-3.9,15-12.2,9.6-23.6,17.5-3.8,35.7,1.7,54.6,16,4.1,3.1,6.5,8.4,5.1,13.5h0Z"
          />
        </g>
        <g
          id="metatarsalia_overlay"
          data-name="metatarsalia overlay"
          class="vorfuss_lat_links1431_cls_1"
        >
          <path
            id="metatarsalia_overlay_1"
            data-name="metatarsalia overlay 1"
            class="vorfuss_lat_links1431_cls_4"
            d="M2.2,113.73c17.4-1.1,34.4-6.1,45.4-20.1,10-13,12-29,10-45C53.6,29.63,39.6,14.63,21.6,7.63c-2-1-4-3-6-5C12.6,1.63,9.6-.37,6.2.93"
          />
          <path
            id="metatarsalia_overlay_2"
            data-name="metatarsalia overlay 2"
            class="vorfuss_lat_links1431_cls_4"
            d="M3.6,164.53c23.9-5.3,37.7-18.6,44-37.7,10.5-34.7-.1-63.4-45-82.5-.1,1.1-.8,1.8-1.6,2.2"
          />
          <path
            id="metatarsalia_overlay_3"
            data-name="metatarsalia overlay 3"
            class="vorfuss_lat_links1431_cls_4"
            d="M3.9,171.43c31-14.4,47.7-30.8,29.6-52.3-4.7-8.2-15.9-16.3-31.7-24.3"
          />
        </g>
      </g>
    </svg>
  );
}

export default LinkerVorfussLatLiegend1431;
