import { Modal } from "react-bootstrap";
import PhysiciansTable from "../ReferringPhysicians/PhysiciansList";
import { useDispatch, useSelector } from "react-redux";
import {
  setExamplannerFacilityDocId,
  clearExamplannerFacilityDocId,
} from "../../Redux/actions";

function RefferingPhysicianSelector({
  shown = false,
  closeHandler = undefined,
}) {
  const dispatch = useDispatch();
  const selectedFacility = useSelector(
    (state) => state.examPlanner.risData.facilityId
  );
  const selectedFacilityDoc = useSelector(
    (state) => state.examPlanner.risData.facilityDocId
  );

  const clickRow = (i, row) => {
    if (row.original.id === selectedFacilityDoc) {
      dispatch(clearExamplannerFacilityDocId());
      return;
    }
    dispatch(setExamplannerFacilityDocId(row.original.id));
    closeHandler();
  };

  const getSelected = () => {
    if (selectedFacilityDoc) {
      return [selectedFacilityDoc];
    } else return [];
  };
  return (
    <Modal show={shown} onHide={closeHandler} size="lg">
      <Modal.Header closeButton className="p-2">
        <h5>Select Refferig Doctor</h5>
      </Modal.Header>
      <Modal.Body>
        <PhysiciansTable
          headerless={true}
          facility_id={selectedFacility}
          rowClick={clickRow}
          selectedIds={getSelected()}
        />
      </Modal.Body>
    </Modal>
  );
}

export default RefferingPhysicianSelector;
