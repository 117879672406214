import { useState, useEffect } from "react";
import human from "./human.png";
import ImageMapper from "react-image-mapper";
import { Container } from "react-bootstrap";
import "./stylesheets/human.css";

const availableAreas = [
  {
    name: "Head",
    shape: "poly",
    preFillColor: "",
    coords: [
      215, 48, 226, 37, 243, 33, 255, 38, 263, 48, 267, 64, 265, 83, 262, 98,
      257, 111, 244, 123, 226, 120, 217, 102, 212, 83, 212, 64,
    ],
  },
  {
    name: "Spine",
    shape: "rect",
    preFillColor: "",
    coords: [230, 118, 249, 357],
  },
  {
    name: "Chest",
    shape: "poly",
    preFillColor: "",
    coords: [
      176, 143, 186, 141, 193, 137, 201, 134, 212, 127, 218, 134, 225, 138, 233,
      140, 242, 141, 250, 138, 259, 135, 264, 126, 270, 130, 276, 135, 284, 137,
      291, 140, 299, 144, 305, 147, 302, 155, 301, 164, 299, 172, 302, 187, 305,
      195, 307, 200, 302, 204, 299, 213, 298, 221, 296, 230, 294, 239, 293, 249,
      291, 258, 288, 267, 287, 273, 278, 270, 267, 270, 256, 270, 245, 270, 233,
      269, 222, 269, 212, 269, 202, 269, 193, 269, 189, 269, 186, 259, 184, 249,
      182, 238, 181, 227, 178, 216, 175, 206, 169, 197, 172, 187, 175, 178, 178,
      169, 178, 158,
    ],
  },
  {
    name: "Abdomen",
    shape: "poly",
    preFillColor: "",
    coords: [
      190, 270, 286, 272, 288, 288, 289, 302, 291, 314, 296, 329, 302, 343, 304,
      354, 307, 364, 310, 374, 311, 385, 296, 387, 283, 389, 271, 390, 257, 391,
      247, 391, 240, 375, 233, 388, 223, 390, 206, 388, 190, 387, 178, 386, 168,
      383, 172, 362, 177, 341, 181, 325, 186, 307, 187, 295, 189, 283,
    ],
  },
  {
    name: "RLegUp",
    shape: "poly",
    preFillColor: "",
    coords: [
      168, 385, 176, 388, 189, 388, 203, 389, 217, 390, 228, 391, 229, 402, 226,
      415, 224, 425, 220, 436, 217, 447, 213, 457, 203, 457, 193, 454, 183, 452,
      174, 454, 165, 458, 163, 442, 164, 419, 165, 404, 166, 393,
    ],
  },
  {
    name: "RLegMid",
    shape: "poly",
    preFillColor: "",
    coords: [
      164, 459, 174, 455, 183, 453, 190, 454, 198, 456, 207, 458, 213, 458, 210,
      468, 207, 482, 204, 492, 200, 502, 197, 512, 195, 522, 195, 532, 195, 542,
      194, 551, 183, 550, 170, 548, 157, 548, 152, 548, 154, 532, 157, 519, 160,
      507, 163, 496, 164, 483, 164, 470,
    ],
  },
  {
    name: "RLegDown",
    shape: "poly",
    preFillColor: "",
    coords: [
      152, 550, 162, 549, 169, 548, 176, 550, 186, 551, 194, 552, 195, 558, 194,
      565, 193, 574, 191, 581, 189, 588, 187, 596, 184, 602, 183, 609, 181, 618,
      178, 625, 176, 633, 175, 641, 174, 652, 154, 652, 155, 643, 156, 633, 156,
      622, 155, 607, 154, 597, 153, 584, 152, 572, 152, 561,
    ],
  },
  {
    name: "RFoot",
    shape: "poly",
    preFillColor: "",
    coords: [
      153, 653, 174, 653, 174, 659, 175, 666, 175, 672, 178, 678, 181, 684, 179,
      690, 172, 692, 166, 689, 160, 690, 154, 689, 148, 688, 149, 680, 152, 674,
      153, 666, 153, 659,
    ],
  },
  {
    name: "LLegUp",
    shape: "poly",
    preFillColor: "",
    coords: [
      247, 391, 258, 391, 270, 391, 280, 389, 291, 388, 302, 387, 309, 385, 310,
      391, 312, 398, 313, 406, 314, 414, 314, 421, 315, 429, 315, 437, 315, 444,
      315, 453, 307, 451, 298, 450, 291, 448, 284, 450, 278, 453, 271, 456, 266,
      460, 263, 448, 259, 438, 256, 429, 253, 418, 250, 405, 249, 398,
    ],
  },
  {
    name: "LLegMid",
    shape: "poly",
    preFillColor: "",
    coords: [
      266, 460, 271, 457, 278, 454, 284, 451, 290, 449, 298, 450, 305, 452, 312,
      452, 313, 459, 314, 471, 314, 478, 315, 484, 315, 490, 315, 497, 316, 504,
      319, 511, 320, 518, 322, 524, 324, 531, 325, 537, 326, 545, 326, 551, 326,
      557, 316, 557, 308, 556, 300, 556, 293, 556, 284, 556, 282, 546, 282, 536,
      282, 527, 280, 517, 279, 507, 276, 498, 273, 489, 269, 479, 267, 469,
    ],
  },
  {
    name: "LLegDown",
    shape: "poly",
    preFillColor: "",
    coords: [
      282, 557, 291, 557, 299, 557, 307, 557, 315, 558, 327, 558, 325, 565, 325,
      574, 325, 582, 324, 590, 324, 597, 324, 605, 324, 613, 324, 621, 324, 631,
      324, 639, 324, 647, 324, 654, 305, 654, 304, 642, 301, 630, 298, 621, 296,
      610, 291, 597, 288, 585, 285, 571,
    ],
  },
  {
    name: "LFoot",
    shape: "poly",
    preFillColor: "",
    coords: [
      305, 654, 325, 654, 326, 659, 327, 665, 327, 671, 329, 677, 331, 683, 330,
      689, 324, 689, 318, 688, 312, 689, 305, 691, 299, 687, 299, 679, 304, 672,
      304, 662,
    ],
  },
  {
    name: "RArm",
    shape: "poly",
    preFillColor: "",
    coords: [
      176, 145, 178, 151, 178, 157, 178, 163, 177, 171, 175, 176, 173, 183, 171,
      188, 169, 198, 163, 202, 157, 207, 152, 212, 147, 217, 142, 222, 138, 227,
      134, 232, 129, 237, 123, 242, 118, 247, 113, 252, 109, 257, 103, 262, 98,
      267, 92, 270, 86, 274, 79, 279, 74, 283, 67, 287, 61, 283, 53, 280, 58,
      271, 66, 261, 73, 254, 80, 246, 87, 238, 94, 231, 100, 225, 107, 217, 115,
      208, 121, 201, 127, 193, 133, 186, 138, 179, 143, 172, 147, 165, 152, 158,
      159, 153, 167, 149,
    ],
  },
  {
    name: "LArm",
    shape: "poly",
    preFillColor: "",
    coords: [
      307, 147, 305, 153, 303, 159, 302, 165, 300, 171, 301, 177, 302, 183, 304,
      188, 306, 193, 308, 198, 313, 199, 316, 203, 320, 206, 324, 209, 328, 213,
      332, 217, 336, 222, 340, 226, 344, 231, 349, 236, 353, 240, 358, 244, 362,
      249, 366, 253, 370, 258, 375, 262, 380, 265, 385, 268, 389, 271, 396, 277,
      401, 280, 406, 283, 412, 287, 417, 283, 423, 281, 428, 281, 419, 270, 414,
      262, 406, 252, 398, 243, 392, 237, 387, 232, 378, 224, 372, 217, 365, 208,
      359, 202, 354, 195, 350, 189, 344, 182, 339, 176, 333, 169, 330, 162, 324,
      157, 318, 152, 312, 148,
    ],
  },
  {
    name: "RHand",
    shape: "poly",
    preFillColor: "",
    coords: [
      52, 280, 58, 282, 64, 286, 67, 291, 70, 296, 70, 306, 65, 302, 60, 298,
      55, 301, 49, 308, 47, 314, 47, 320, 41, 318, 38, 328, 35, 334, 26, 339,
      26, 333, 28, 326, 30, 318, 33, 310, 38, 302, 42, 295, 48, 287,
    ],
  },
  {
    name: "LHand",
    shape: "poly",
    preFillColor: "",
    coords: [
      413, 287, 420, 283, 428, 281, 432, 287, 437, 294, 442, 301, 445, 309, 446,
      317, 449, 324, 451, 332, 451, 340, 442, 335, 439, 323, 436, 313, 432, 324,
      429, 310, 423, 301, 417, 298, 413, 303, 406, 306, 406, 300, 409, 292,
    ],
  },
  {
    name: "Neck",
    shape: "poly",
    preFillColor: "",
    coords: [
      219, 109, 222, 115, 226, 120, 233, 122, 241, 123, 247, 122, 252, 118, 257,
      112, 260, 107, 260, 116, 261, 121, 264, 126, 263, 132, 257, 137, 250, 138,
      242, 140, 234, 139, 227, 137, 220, 134, 215, 129, 212, 127, 216, 121, 217,
      115,
    ],
  },
];

export default function HumanBody({ regionSetter, selectedSetter, selected }) {
  const [areas, setAreas] = useState(availableAreas);
  const [MAP, setMAP] = useState({ name: "human", areas: areas });
  const [counter, setCounter] = useState(0);

  const ClickHandler = (area) => {
    if (selected && selectedSetter) {
      let index = selected.indexOf(area.name);
      if (index > -1) {
        selected.splice(index, 1);
        selectedSetter([area.name]);
      } else {
        selectedSetter([area.name]);
      }
    }
    //copy array to get new reference
    let arr = [...areas];
    //check if selected area is already in maps
    let selectedIndex = arr.findIndex((ar) => ar.name === area.name + "!");
    if (selectedIndex > -1) {
      //area in maps so remove it
      arr.splice(selectedIndex, 1);
    } else {
      //area not in maps so add it
      arr.push({
        name: area.name + "!",
        shape: area.shape,
        coords: area.coords,
        preFillColor: "rgba(10, 150, 90, 0.3)",
      });
    }
    //set state with new array
    setAreas(arr);
    regionSetter(area.name);
  };

  useEffect(() => {
    //selectedSetter([]);
  }, []);

  useEffect(() => {
    //update couter to tell react to rerender ImageMapper
    setCounter(counter + 1);
    setMAP({ name: "human", areas: areas });
  }, [areas]);

  return (
    <Container fluid className="centered-human mx-0">
      <ImageMapper key={counter} src={human} map={MAP} onClick={ClickHandler} />
    </Container>
  );
}
