import PeselPwHorizontal from "../Components/PeselPasswordInputs/PeselPwHorizontal";
import ExamReferralContent from "../Components/ExamReferral/ExamReferral";
import { useP1Order } from "../Utilities/FetchHooks/Integration/IntegrationHooks.jsx";
import { useState } from "react";


function useReferralData() {
  const [orderKey, setOrderKey] = useState(null)
  const [orderCode, setOrderCode] = useState(null)
  const [orderPatID, setOrderPatID] = useState(null)
  const p1_order = useP1Order({orderKey, orderCode, orderPatID})

  const submitData = (data) => {
    setOrderKey(data.orderKey)
    setOrderCode(data.orderCode)
    setOrderPatID(data.orderPatID)
  };

  return {
    order: p1_order,
    loading: false,
    error: null,
    fetchData: submitData,
  };
}

function ExamReferral() {
  const { order, loading, error, fetchData } = useReferralData();
    
  return (
    <div
      className="m-2"
      style={{ display: "flex", flexDirection: "column", gap: "15px" }}
    >
      <PeselPwHorizontal submit={fetchData} />
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {order ? <ExamReferralContent data={order.data} /> : <div>No data</div>}
    </div>
  );
}

export default ExamReferral;
