import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useSubstantiations } from "../../../Utilities/FetchHooks/Ris/RisHooks";

export default function SubstantiationsModal({
  substantiationModalShown,
  setSubstantiationModalShown,
  setSubstantiationSelected,
  substantiationSelected,
  dark = false,
}) {
  const { t } = useTranslation(["examStatistics", "common"]);
  const substantiationsQuery = useSubstantiations({});
  const substantiations = substantiationsQuery.data;
  const language = window.conf.LANG;

  return (
    <Modal
      show={substantiationModalShown}
      onHide={() => {
        setSubstantiationModalShown(false);
      }}
    >
      <Modal.Header
        closeButton
        className={`py-2 ${dark && "bg-dark text-white"}`}
      >
        <h5>{t("substantiations.header")}</h5>
      </Modal.Header>
      <Modal.Body
        className={`d-flex flex-column ${dark && "bg-dark text-white"}`}
        style={{
          height: "40vh",
          overflowY: "auto",
        }}
      >
        {substantiations?.substantiations?.map((sub, key) => (
          <div
            onClick={() => {
              setSubstantiationSelected(sub);
            }}
            className={`d-flex border rounded p-2 mb-1 subs-element ${
              substantiationSelected === sub && "selected"
            }`}
            key={`substantiationskey${key}`}
          >
            <div>{sub[`description_${language.toLowerCase()}`]}</div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
}
