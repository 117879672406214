import style from "./style.scss";

function Brustwirbelkorper7VdLiegend1519(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 298.22 169.93">

<g id="BG">
  <path id="BG_white" data-name="BG white" class="Brustwirbelsaule7_ap1519_cls4" d="M10.55,88.34c6.25-7.13,19.38-11.61,38.09-13.94l25.45-1.81c-.71,4.41.69,7.65,5.11,9.19,7.92,2.66,15.8,4.15,23.64,4.38,4.85-.13,9.14,1.62,12.53,6.45,1.72.72,2.98-.19,3.96-2.17-.54,2.99-1.82,5.89-3.96,8.69-1.83,4.7-.05,8.45,6.03,11.06-.35,1.71-2.2,3.55-5.12,5.47l-1.11,1.78c-9.5-4.51-22.89-5.07-37.15-4.75-35.62,3.3-61.3,8.92-77.95,16.64,28.43-3,56.19-5.67,70.18-1.82-2.69,5.65-1.04,10.4,3.19,14.63,3.75,3.13,6.95,3.58,10.01,3.39,8.72.09,15.38,2.87,20.23,8.04,4.84,5.94,9.56,11.34,13.52,13.12,2.03,2.73,6.76,2.84,12.12,2.35l46.22-2.08c8.22-.5,16.41.45,24.58,2.72,6.09-.21,6.02-6.05,3.9-13.76,2.53-5.14,5.99-7.83,10.98-6.46,4.17-.27,7.43-.16,10.35.09,5.31-.19,8.98-2.37,11.16-6.35,2.98-13.01-1.63-19.17-10.87-21.16l67.9-6.83c-5.58-4.62-19.28-7.05-37.47-8.26l-37.48-1.33c-4.98-5.25-9.79-7-14.47-6.28-.26-3.09-2.17-6.51-4.89-10.09,4.24,2.54,7.55,1.14,10.33-2.42,2.69-1.53,5.84-1.61,9.44-.25,11.73,1.38,15.35-5.09,15.31-15.12,27.26,1.58,49.9,4.83,61.04,12.24l2.73-19.53c-3.95-5.69-15.21-7.62-27.56-8.97-17.1-1.43-33.96-1.82-50.57-1.14-6.64-.51-11.92.33-19.35,3.19-1.23-2.9-2.01-4.42-.48-6.76,2.18-3.9,1.83-9.13,0-15.11-6.92-13.44-4.98-19.48,0-24.52,3.12-3.92,6.05-7.66,5.47-8.2-3.11-1.06-11.72,1.53-19.6,3.64-5.08,1.54-11.43,1.92-18.27,1.84.5-3.53,1.3-6.54,3.02-8.03l-23.03,1.54c2.86.24,4.85,1.67,5.35,5.18l-11.87.32c-5.97-.52-12.17-2.49-18.46-5.04-5.17-2.4-6.75-.88-6.17,6.62-.01,3.9,1.72,8.35,4.19,13.02,1.21,4.21-.06,8.33-2.27,12.42l-3.12,5.53c-.43,4.28,2,7.62,7.36,9.98.7,3.14.15,6.1-1.33,8.9-2.59-.24-4.27,1.75-5.21,5.58-1.75-1.67-3.4-2.32-4.93-1.74v-3.57c-15.14-6.61-32.19-9.16-50.96-8.07-18.65,2.17-35.1,7.8-49.75,16.27v21.29Z"/>
</g>
<g id="BWK7ap">
  <g id="BWK7_Parts" data-name="BWK7 Parts">
    <path id="BWK7_Part27" data-name="BWK7 Part27" class="Brustwirbelsaule7_ap1519_cls6" d="M118.09,1.3c1.29-.79,2.6.2,8.66,2.28,0,0,4.04,1.39,9.11,2.73,5.88,1.56,7.49.51,19.14.91,9.63.34,8.74,1.07,15.04.91,4.11-.1,9.36-.23,15.95-1.82,2.47-.6,5.2-1.42,10.94-2.73,6.61-1.52,8.23-1.63,8.66-.91.63,1.06-1.97,2.94-5.47,8.2-2.95,4.43-4.42,6.64-4.56,9.11-.15,2.68,1.05,5.9,3.45,12.34.22.6.38,1,.66,1.78,1.11,3.16,2.04,5.78,2.28,8.2.16,1.59.62,6.21-1.82,8.2-2.15,1.76-5.05.27-10.48-.46-5.34-.71-6.13.25-14.13.46-8.82.23-8.61-.92-20.51-1.37-9.93-.37-14.89-.56-20.05,0-6.8.74-10.58,1.95-14.58-.46-.21-.12-4.89-3.03-5.01-7.29-.06-1.94.88-2.48,2.73-6.38,1.65-3.47,3.26-6.87,3.19-10.48-.06-3.43-1.57-3.66-3.19-8.66-1.51-4.68-2.89-12.81,0-14.58v.02h0Z"/>
    <path id="BWK7_Part26" data-name="BWK7 Part26" class="Brustwirbelsaule7_ap1519_cls5" d="M119.46,109.3c-.81-.5-4.45-2.74-5.01-6.38-.54-3.49,2.35-4.49,4.56-10.48.69-1.87,2.52-6.85,1.37-12.3-1.01-4.77-3.2-4.7-4.1-9.57-.65-3.55-.56-9.36,2.73-11.39.51-.32,1.62-.86,5.47-.46,5.07.53,7.97,1.97,10.48,2.73,4.97,1.51,5.87-.4,16.41-.91,6.51-.32,5.22.46,16.41.46,8.65,0,13.05-.03,16.41-1.37,1.87-.74,4.26-1.96,8.2-2.73,3.2-.63,5.88-1.11,7.75.46,2.12,1.79,1.86,5.11,1.82,5.47-.3,3.23-2.56,3.79-4.56,7.75-.38.75-2.33,4.76-1.82,9.57.31,2.9,1.42,4.97,3.65,9.11,2.35,4.38,3.17,4.52,4.1,7.29.54,1.61,2.22,6.62,0,9.11-1.44,1.62-4.17,1.4-9.57.91-5.01-.46-10.03-.9-15.04-1.37-6.7-.63-11.61-.18-18.68.46-8.01.72-12.32,1.11-18.23,2.73-4.5,1.24-8.47,2.79-14.58,2.73-3.3-.03-5.61-.51-7.75-1.82h-.02Z"/>
    <path id="BWK7_Part25" data-name="BWK7 Part25" class="Brustwirbelsaule7_ap1519_cls5" d="M223.36,62.82c1.28.46,4.91,1.81,7.75,5.47,1.22,1.58,3.51,4.52,3.19,8.66-.29,3.84-2.65,6.3-3.19,6.84-2.47,2.45-5.4,2.96-6.84,3.19-5.21.83-6.49-1.84-10.94-1.37-5.85.62-6.87,5.57-10.48,5.01-4.34-.67-7.94-8.61-7.04-15.44.69-5.21,4.09-10.37,8.86-12.36,1.94-.81,4.33-.84,9.11-.91,4.5-.06,6.75-.1,9.57.91h0Z"/>
    <path id="BWK7_Part24" data-name="BWK7 Part24" class="Brustwirbelsaule7_ap1519_cls5" d="M74.35,77.4c-1.1-3.48,1.5-7.84,4.56-10.03,2.9-2.07,4.49-.97,13.22-2.28,5.59-.84,6.91-1.58,13.22-2.28,7.05-.78,8.33-.17,9.11.46,2.28,1.8,1.07,4.17,3.12,10.98,1.68,5.57,3.09,6.01,3.17,9.53.09,4-1.62,8.75-4.01,9.11-1.97.3-2.55-2.67-6.38-5.01-2.66-1.63-4.16-1.28-13.22-2.28-5.95-.65-8.98-1-11.85-1.82-5.23-1.5-9.81-2.81-10.94-6.38Z"/>
    <path id="BWK7_Part23" data-name="BWK7 Part23" class="Brustwirbelsaule7_ap1519_cls5" d="M132.22,81.04c2.4-.61,2.97-5.32,3.65-10.94.92-7.56,1.42-11.77-1.37-14.58-2.17-2.19-6.1-3.29-8.2-1.82-1.07.74-1.42,1.97-1.37,10.48.06,9.4.5,11.32,1.82,13.22.2.29,3.04,4.26,5.47,3.65h0Z"/>
    <path id="BWK7_Part22" data-name="BWK7 Part22" class="Brustwirbelsaule7_ap1519_cls5" d="M185.08,78.77c2.49.65,5.13-1.53,6.38-3.19,3.37-4.47.9-10.72.46-11.85-1.44-3.65-5.14-9.03-8.2-8.2-2.91.79-3.44,7.41-3.65,10.03-.33,4.15.55,6.79.91,7.75.82,2.19,1.82,4.87,4.1,5.47h0Z"/>
    <path id="BWK7_Part21" data-name="BWK7 Part21" class="Brustwirbelsaule7_ap1519_cls6" d="M117.18,166.72c1.73,3.17,7.78,2.65,20.96,1.82,13.66-.86,20.49-1.29,24.61-1.37,16.45-.3,24.67-.45,30.08.91,4.36,1.09,8.98,2.76,11.39.46,2.56-2.45,1.26-8.08.91-9.57-.82-3.55-1.96-3.71-4.1-8.66-2.61-6.02-3.09-10.77-3.19-11.85-.33-3.62-.53-5.89.46-8.66,1.4-3.93,3.59-4.33,5.01-8.66.26-.78,1.87-5.9,0-7.29-1.33-.99-3.04.94-8.2,1.82-3.06.52-3.56.03-7.29.46-3.99.45-6.73,1.39-8.2,1.82-5.46,1.6-8.32.63-26.89.46-9.04-.09-13.56-.13-15.49,0-6.02.4-8.28,1.03-12.3,0-5.16-1.32-7.01-3.77-8.66-2.73-2.35,1.48-1.19,8.08.46,12.3,1.28,3.28,2.07,3.1,3.65,6.84.98,2.32,2.75,6.52,2.28,11.39-.3,3.04-1.15,3.13-3.19,9.11-2.17,6.39-3.26,9.59-2.28,11.39h-.02Z"/>
    <path id="BWK7_Part20" data-name="BWK7 Part20" class="Brustwirbelsaule7_ap1519_cls6" d="M77.54,119.78c-1.45.8-5.34,3.02-7.29,7.75-.42,1.03-2.52,6.11,0,10.94,1.88,3.6,5.3,5.07,7.29,5.92,3.58,1.53,4.62.66,10.94,1.82,4.79.88,8.05,1.48,11.39,3.65,2.08,1.35,2.26,2.06,7.29,7.75,8.22,9.3,9.45,9.33,10.03,9.11,1.63-.62.44-4.21,2.28-11.39,1.49-5.81,2.78-5.46,3.19-9.11.3-2.64-.25-3.95-4.55-15.5-2.91-7.81-3.34-9.03-5.02-10.02-2.49-1.48-4.11-.32-10.48-.91-5.14-.48-4.88-1.3-9.57-1.82-4.6-.51-10.15-1.12-15.49,1.82h-.01Z"/>
    <path id="BWK7_Part19" data-name="BWK7 Part19" class="Brustwirbelsaule7_ap1519_cls6" d="M204.02,155.94c1.62.07,1.61-3.31,5.01-5.36,1.72-1.03,4.12-1.1,8.86-1.18,6.05-.11,6.05.45,8.66,0,2.46-.42,6.75-1.16,9.11-4.56,1.61-2.32,1.5-4.83,1.37-8.66-.13-3.61-.22-6.25-1.97-8.8-2.54-3.7-6.85-4.72-9.42-5.33-1.57-.37-2.1-.31-10.03-.46-8.02-.15-8.04-.22-8.66,0-5.91,2.08-9.29,10.37-9.11,16.86.05,1.69.51,3.49,1.45,7.09,1.24,4.79,2.67,10.3,4.73,10.39h0Z"/>
    <path id="BWK7_Part18" data-name="BWK7 Part18" class="Brustwirbelsaule7_ap1519_cls6" d="M129.49,138.01c-2.19-.65-2.42-5.41-2.73-11.85-.35-7.19-.48-10.81,1.82-12.3,1.71-1.11,4.47-.92,5.92.46,1.6,1.52.59,3.5.46,10.03-.13,6.6.83,8.48-.91,10.94-.98,1.38-2.98,3.2-4.56,2.73h0Z"/>
    <path id="BWK7_Part17" data-name="BWK7 Part17" class="Brustwirbelsaule7_ap1519_cls6" d="M187.81,137.55c2.45.3,4.93-3.28,5.92-5.92.28-.75,1.17-3.36-.46-10.48-1.31-5.72-2.46-10.78-4.56-10.94-2.48-.19-5.16,6.54-5.92,11.39-.31,1.99-.53,5.1.46,8.66.64,2.32,1.93,6.96,4.56,7.29Z"/>
    <path id="BWK7_Part16" data-name="BWK7 Part16" class="Brustwirbelsaule7_ap1519_cls6" d="M295.36,83.71c-4-4.44-30.28-10.5-61.03-12.23"/>
    <path id="BWK7_Part15" data-name="BWK7 Part15" class="Brustwirbelsaule7_ap1519_cls6" d="M213.33,85.6c-.34-3.75,9.03-9.86,20.99-14.13"/>
    <path id="BWK7_Part14" data-name="BWK7 Part14" class="Brustwirbelsaule7_ap1519_cls6" d="M298.09,64.18c-1.1-7.19-38.11-12.44-79.5-10.04"/>
    <path id="BWK7_Part13" data-name="BWK7 Part13" class="Brustwirbelsaule7_ap1519_cls6" d="M218.6,54.14c2.77,0-9.19-2.02-18.48,4.31"/>
    <path id="BWK7_Part12" data-name="BWK7 Part12" class="Brustwirbelsaule7_ap1519_cls5" d="M158.4,111.12c6.4-10.97-3-34.36-10.69-37"/>
    <path id="BWK7_Part11" data-name="BWK7 Part11" class="Brustwirbelsaule7_ap1519_cls5" d="M160.36,110.21c4.53-22.44,8.62-43.92,15.18-38.73"/>
    <path id="BWK7_Part10" data-name="BWK7 Part10" class="Brustwirbelsaule7_ap1519_cls6" d="M160.52,53.04c5.53-26.73,6.56-52.02,10.22-52.92"/>
    <path id="BWK7_Part9" data-name="BWK7 Part9" class="Brustwirbelsaule7_ap1519_cls6" d="M158.4,51.34c0-28.4-3.61-49.68-10.69-49.68"/>
    <path id="BWK7_Part8" data-name="BWK7 Part8" class="Brustwirbelsaule7_ap1519_cls6" d="M201.78,102.53c-7.09,3.72-5.61,24.21-4.21,31.38"/>
    <path id="BWK7_Part7" data-name="BWK7 Part7" class="Brustwirbelsaule7_ap1519_cls6" d="M201.78,102.53c1.43-3.8,8.62-5.67,16.81,3.1"/>
    <path id="BWK7_Part6" data-name="BWK7 Part6" class="Brustwirbelsaule7_ap1519_cls6" d="M293.54,115.22c.02-4.28-30.02-9.69-74.94-9.6"/>
    <path id="BWK7_Part5" data-name="BWK7 Part5" class="Brustwirbelsaule7_ap1519_cls6" d="M.07,129.35c13.92-9.75,104-27.31,120.47-7.88"/>
    <path id="BWK7_Part4" data-name="BWK7 Part4" class="Brustwirbelsaule7_ap1519_cls6" d="M120.53,121.46c4.07,13.66-6.28,23.51-29.33,7.88"/>
    <path id="BWK7_Part3" data-name="BWK7 Part3" class="Brustwirbelsaule7_ap1519_cls6" d="M10.55,88.34c4.72-8.83,40.75-19.96,99.3-14.21"/>
    <path id="BWK7_Part2" data-name="BWK7 Part2" class="Brustwirbelsaule7_ap1519_cls6" d="M111.26,58.87c-23.53-12.75-70.71-10.66-100.71,8.2"/>
    <path id="BWK7_Part1" data-name="BWK7 Part1" class="Brustwirbelsaule7_ap1519_cls6" d="M111.26,58.87c.96,12.49-.16,17.26-1.41,15.26"/>
  </g>
</g>
<g id="angrenzende_BWK7_highlights" data-name="angrenzende BWK7 highlights">
  <path id="angrenzende_BWK7_highlight4" data-name="angrenzende BWK7 highlight4" class="BWK1519Fill" style= {{
            fill: props.colors.BWK1519Fill,
            stroke: props.colors.BWK1519Fill,
            opacity:props.colors.BWK1519Fill,
          }} d="M118.08,1.54c1.29-.79,2.6.2,8.66,2.28,0,0,4.04,1.39,9.11,2.73,5.88,1.56,7.49.51,19.14.91,9.63.34,8.74,1.07,15.04.91,4.11-.1,9.36-.23,15.95-1.82,2.47-.6,5.2-1.42,10.94-2.73,6.61-1.52,8.23-1.63,8.66-.91.63,1.06-1.97,2.94-5.47,8.2-2.95,4.43-4.42,6.64-4.56,9.11-.15,2.68,1.05,5.9,3.45,12.34.22.6.38,1,.66,1.78,1.11,3.16,2.04,5.78,2.28,8.2.16,1.59.62,6.21-1.82,8.2-2.15,1.76-5.05.27-10.48-.46-5.34-.71-6.13.25-14.13.46-8.82.23-8.61-.92-20.51-1.37-9.93-.37-14.89-.56-20.05,0-6.8.74-10.58,1.95-14.58-.46-.21-.12-4.89-3.03-5.01-7.29-.06-1.94.88-2.48,2.73-6.38,1.65-3.47,3.26-6.87,3.19-10.48-.06-3.43-1.57-3.66-3.19-8.66-1.51-4.68-2.89-12.81,0-14.58v.02h0Z"/>
  <path id="angrenzende_BWK7_highlight3" data-name="angrenzende BWK7 highlight3" class="BWK1519Fill" style= {{
            fill: props.colors.BWK1519Fill,
            stroke: props.colors.BWK1519Fill,
            opacity:props.colors.BWK1519Fill,
          }} d="M117.17,166.96c1.73,3.17,7.78,2.65,20.96,1.82,13.66-.86,20.49-1.29,24.61-1.37,16.45-.3,24.67-.45,30.08.91,4.36,1.09,8.98,2.76,11.39.46,2.56-2.45,1.26-8.08.91-9.57-.82-3.55-1.96-3.71-4.1-8.66-2.61-6.02-3.09-10.77-3.19-11.85-.33-3.62-.53-5.89.46-8.66,1.4-3.93,3.59-4.33,5.01-8.66.26-.78,1.87-5.9,0-7.29-1.33-.99-3.04.94-8.2,1.82-3.06.52-3.56.03-7.29.46-3.99.45-6.73,1.39-8.2,1.82-5.46,1.6-8.32.63-26.89.46-9.04-.09-13.56-.13-15.49,0-6.02.4-8.28,1.03-12.3,0-5.16-1.32-7.01-3.77-8.66-2.73-2.35,1.48-1.19,8.08.46,12.3,1.28,3.28,2.07,3.1,3.65,6.84.98,2.32,2.75,6.52,2.28,11.39-.3,3.04-1.15,3.13-3.19,9.11-2.17,6.39-3.26,9.59-2.28,11.39h-.02Z"/>
  <path id="angrenzende_BWK7_highlight2" data-name="angrenzende BWK7 highlight2" class="BWK1519Fill" style= {{
            fill: props.colors.BWK1519Fill,
            stroke: props.colors.BWK1519Fill,
            opacity:props.colors.BWK1519Fill,
          }} d="M77.53,120.02c-1.45.8-5.34,3.02-7.29,7.75-.42,1.03-2.52,6.11,0,10.94,1.88,3.6,5.3,5.07,7.29,5.92,3.58,1.53,4.62.66,10.94,1.82,4.79.88,8.05,1.48,11.39,3.65,2.08,1.35,2.26,2.06,7.29,7.75,8.22,9.3,9.45,9.33,10.03,9.11,1.63-.62.44-4.21,2.28-11.39,1.49-5.81,2.78-5.46,3.19-9.11.3-2.64-.25-3.95-4.55-15.5-2.91-7.81-3.34-9.03-5.02-10.02-2.49-1.48-4.11-.32-10.48-.91-5.14-.48-4.88-1.3-9.57-1.82-4.6-.51-10.15-1.12-15.49,1.82h-.01Z"/>
  <path id="angrenzende_BWK7_highlight1" data-name="angrenzende BWK7 highlight1" class="BWK1519Fill" style= {{
            fill: props.colors.BWK1519Fill,
            stroke: props.colors.BWK1519Fill,
            opacity:props.colors.BWK1519Fill,
          }} d="M204.01,156.18c1.62.07,1.61-3.31,5.01-5.36,1.72-1.03,4.12-1.1,8.86-1.18,6.05-.11,6.05.45,8.66,0,2.46-.42,6.75-1.16,9.11-4.56,1.61-2.32,1.5-4.83,1.37-8.66-.13-3.61-.22-6.25-1.97-8.8-2.54-3.7-6.85-4.72-9.42-5.33-1.57-.37-2.1-.31-10.03-.46-8.02-.15-8.04-.22-8.66,0-5.91,2.08-9.29,10.37-9.11,16.86.05,1.69.51,3.49,1.45,7.09,1.24,4.79,2.67,10.3,4.73,10.39h0Z"/>
</g>
<g id="BWK7_highlights" data-name="BWK7 highlights">
  <path id="BWK7_Highlight3" data-name="BWK7 Highlight3" class="Wirbelkorper_Querfortsatze1519Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1519Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1519Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1519Fill,
          }} d="M119.37,109.3c-.81-.5-4.45-2.74-5.01-6.38-.54-3.49,2.35-4.49,4.56-10.48.69-1.87,2.52-6.85,1.37-12.3-1.01-4.77-3.2-4.7-4.1-9.57-.65-3.55-.56-9.36,2.73-11.39.51-.32,1.62-.86,5.47-.46,5.07.53,7.97,1.97,10.48,2.73,4.97,1.51,5.87-.4,16.41-.91,6.51-.32,5.22.46,16.41.46,8.65,0,13.05-.03,16.41-1.37,1.87-.74,4.26-1.96,8.2-2.73,3.2-.63,5.88-1.11,7.75.46,2.12,1.79,1.86,5.11,1.82,5.47-.3,3.23-2.56,3.79-4.56,7.75-.38.75-2.33,4.76-1.82,9.57.31,2.9,1.42,4.97,3.65,9.11,2.35,4.38,3.17,4.52,4.1,7.29.54,1.61,2.22,6.62,0,9.11-1.44,1.62-4.17,1.4-9.57.91-5.01-.46-10.03-.9-15.04-1.37-6.7-.63-11.61-.18-18.68.46-8.01.72-12.32,1.11-18.23,2.73-4.5,1.24-8.47,2.79-14.58,2.73-3.3-.03-5.61-.51-7.75-1.82h-.02Z"/>
  <path id="BWK7_Highlight2" data-name="BWK7 Highlight2" class="Wirbelkorper_Querfortsatze1519Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1519Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1519Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1519Fill,
          }} d="M223.27,62.82c1.28.46,4.91,1.81,7.75,5.47,1.22,1.58,3.51,4.52,3.19,8.66-.29,3.84-2.65,6.3-3.19,6.84-2.47,2.45-5.4,2.96-6.84,3.19-5.21.83-6.49-1.84-10.94-1.37-5.85.62-6.87,5.57-10.48,5.01-4.34-.67-7.94-8.61-7.04-15.44.69-5.21,4.09-10.37,8.86-12.36,1.94-.81,4.33-.84,9.11-.91,4.5-.06,6.75-.1,9.57.91h0Z"/>
  <path id="BWK7_Highlight1" data-name="BWK7 Highlight1" class="Wirbelkorper_Querfortsatze1519Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1519Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1519Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1519Fill,
          }} d="M74.26,77.4c-1.1-3.48,1.5-7.84,4.56-10.03,2.9-2.07,4.49-.97,13.22-2.28,5.59-.84,6.91-1.58,13.22-2.28,7.05-.78,8.33-.17,9.11.46,2.28,1.8,1.07,4.17,3.12,10.98,1.68,5.57,3.09,6.01,3.17,9.53.09,4-1.62,8.75-4.01,9.11-1.97.3-2.55-2.67-6.38-5.01-2.66-1.63-4.16-1.28-13.22-2.28-5.95-.65-8.98-1-11.85-1.82-5.23-1.5-9.81-2.81-10.94-6.38Z"/>
</g>
<g id="Grund_u_Deckplatten_Highlights" data-name="Grund u Deckplatten Highlights">
  <path id="Grund_u_Deckplatten_Highlight4" data-name="Grund u Deckplatten Highlight4" class="Grund_Deckplatten1519Fill" style= {{
            fill: props.colors.Grund_Deckplatten1519Fill,
            stroke: props.colors.Grund_Deckplatten1519Fill,
            opacity:props.colors.Grund_Deckplatten1519Fill,
          }} d="M199.05,47.7c-13.29-2.43-26.76,1.23-40.2-.44-6.6-.82-13.24-1.94-19.91-1.33-5.66.52-17.03,4.59-19.88-2.9-1.36-3.57-7.16-2.02-5.79,1.6,1.87,4.91,5.91,7.82,11.1,8.56,3.71.53,7.29-.21,10.95-.76,4.24-.63,8.37-.8,12.66-.46,7.9.63,15.67,2.31,23.63,2.05,8.64-.28,17.23-2.12,25.84-.54,3.77.69,5.39-5.09,1.6-5.79Z"/>
  <path id="Grund_u_Deckplatten_Highlight3" data-name="Grund u Deckplatten Highlight3" class="Grund_Deckplatten1519Fill" style= {{
            fill: props.colors.Grund_Deckplatten1519Fill,
            stroke: props.colors.Grund_Deckplatten1519Fill,
            opacity:props.colors.Grund_Deckplatten1519Fill,
          }} d="M198.89,55.5c-6.8-.66-12.89,1.89-19.49,2.97-7.59,1.23-15.21-.81-22.82-.6-8.06.23-15.45,2.03-23.51.32-5.72-1.21-10.66-2.78-16.22-.14-3.49,1.65-.45,6.83,3.03,5.18,5.57-2.64,13.81,1.59,19.77,1.87,6.91.32,13.68-1.46,20.6-1.19,6.37.25,12.66,1.46,19.04.6,6.64-.89,12.81-3.67,19.61-3.01,3.84.37,3.81-5.63,0-6Z"/>
  <path id="Grund_u_Deckplatten_Highlight2" data-name="Grund u Deckplatten Highlight2" class="Grund_Deckplatten1519Fill" style= {{
            fill: props.colors.Grund_Deckplatten1519Fill,
            stroke: props.colors.Grund_Deckplatten1519Fill,
            opacity:props.colors.Grund_Deckplatten1519Fill,
          }} d="M201.82,102.75c-7.79,1.62-16.16.68-24.07.52-7.19-.14-14.48-.65-21.67-.23-11.95.69-28.67,10.21-38.67-.25-2.67-2.8-6.91,1.45-4.24,4.24,10.65,11.14,25.6,4.63,38.4,2.51,17.05-2.83,34.86,2.53,51.84-1.01,3.78-.79,2.18-6.57-1.6-5.79Z"/>
  <path id="Grund_u_Deckplatten_Highlight1" data-name="Grund u Deckplatten Highlight1" class="Grund_Deckplatten1519Fill" style= {{
            fill: props.colors.Grund_Deckplatten1519Fill,
            stroke: props.colors.Grund_Deckplatten1519Fill,
            opacity:props.colors.Grund_Deckplatten1519Fill,
          }} d="M201.41,112.94c-6.16,1.73-12.88,1.11-19.2,2.56-7.22,1.66-14.84,1.18-22.2,1.25-7.3.07-14.61.14-21.91.21-4.07.04-8.52.57-12.52-.34-3.05-.69-5.55-2.54-8.68-2.98-3.78-.52-5.41,5.26-1.6,5.79,3.37.47,6.23,2.49,9.58,3.13,3.36.65,6.83.46,10.22.42,8.3-.08,16.6-.16,24.9-.24,7.04-.07,14.31.36,21.27-.91,3.71-.67,7.18-1.74,10.98-1.95,3.7-.21,7.15-.16,10.74-1.17,3.71-1.04,2.13-6.83-1.6-5.79Z"/>
</g>
</svg>
  );
}

export default Brustwirbelkorper7VdLiegend1519;
