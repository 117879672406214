import style from "./style.scss";

function LinkerMittelfussLatLiegend1437(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538.4 321.5">

<g id="Metatarsal_lat" data-name="Metatarsal lat">
  <g id="Metatarsal">
    <path id="Metatarsal_1" data-name="Metatarsal 1" class="mittefuss_lat_links1437_cls5" d="M33.9,135.1c14,12.7,26.8,18.1,38.2,14.7,11.9-.4,21.8-4.5,29.9-12.1,11.3-12.3,17.6-24.2,18-35.7.4-18.3-4.8-29.8-16.3-33.7-11.7-1.1-22.2-4.8-31.3-12l-8.8-6"/>
    <path id="Metatarsal_2" data-name="Metatarsal 2" class="mittefuss_lat_links1437_cls5" d="M23.3,118.3c22.1-9.5,39.9-7.3,51.3,12.4,11.6,7,5.4,16.3-13.1,27.2l1,41.3c0,3.5-2.3,6.6-5.8,7.3-2.9.6-6.5.9-10.2,1-2,8.2-13.5,15.5-31,22.2"/>
    <path id="Metatarsal_3" data-name="Metatarsal 3" class="mittefuss_lat_links1437_cls5" d="M71.3,198.9c-7.3,16.5-.3,26.4,23.8,28.6,26.8-.7,46.3-7.3,56.2-21.7,4.8-15.3,5.6-29.8-10.3-41.2-30.2-12.4-54.6-15.5-70.6-5.4l.9,39.8Z"/>
    <path id="Metatarsal_4" data-name="Metatarsal 4" class="mittefuss_lat_links1437_cls5" d="M116.1,193.4c11.1,2.6,15.1-1.3,14.5-9.2,29.7-23.8,43-51.9,38.6-84.6-3.6.7-6.1-.4-6.5-5.2-9.7-1.9-17.6-5-21.4-11.2-5.4.1-10.8-.9-16.5-4-1.2-.7-2.5.5-2.1,1.8,5.7,17.3,2.8,30.8-4.2,45.4-6,11.4-17.6,21.5-32.3,28.9-7.3,7.9-6.9,16.8,0,26.5,6.6,7.3,16.3,11.5,29.7,11.8h0s0,0,0,0h0Z"/>
    <path id="Metatarsal_5" data-name="Metatarsal 5" class="mittefuss_lat_links1437_cls5" d="M147.5,173.2l2.7,27.2c11.8,5,22.9,7.9,32.9,7.4,16.4,9.8,31.2,15.3,42.4,11,8-5.6,11.9-16.6,12.5-32.3,18.7-33.4,33.1-64,15.6-74.7l-23.3-11.1-49.1-1.5c-4.1-.1-7.3,3.7-6.4,7.7,5.9,27.9-2.7,50.2-27.2,66.3h0s0,0,0,0Z"/>
    <path id="Metatarsal_6" data-name="Metatarsal 6" class="mittefuss_lat_links1437_cls5" d="M242.7,194c-6.7,12.3-6.7,19.6.6,21.6,3,.8,6.1.3,8.9-1,25.3-12.1,51.2-19.2,78.2-16,34.7,1.6,60.8,9.1,77.7,23,2.7,2.2,5.7,3.7,9.1,4.4,23.6,4.9,40.7,1.7,44.8-18,2.4-13-2.5-23.6-17.9-30.7-1.4-2.4-4.7-2.8-9.5-1.3-2.4.7-4.9.8-7.3.2-47.1-11.9-91.7-26.3-130.8-46.4-20.4-16.6-30.7-16.4-31,.4,0,3.4-.6,6.8-1.4,10.1-4.2,17.3-11.1,35.2-21.4,53.8h0s0,0,0,0Z"/>
    <path id="Metatarsal_7" data-name="Metatarsal 7" class="mittefuss_lat_links1437_cls5" d="M518.3,194.8c-7.8-2-15.9-4.4-24.2-7.1-14.4-5.1-26-7.8-23.7,2.4,2.5,16.3-1.1,27.1-6.8,36h0c-4,8.3,0,12,10.6,11.5,5.1-.2,10-1.8,14.5-4.3,6.9-3.8,14.2-6.7,21.6-8.8"/>
    <path id="Metatarsal_8" data-name="Metatarsal 8" class="mittefuss_lat_links1437_cls1" d="M464.4,235.2c6-5.8,10.7-12.5,12.2-21.1,1.3-11.6-.7-20.8-5.3-28.2"/>
  </g>
  <g id="transperent_metatarsal" data-name="transperent metatarsal" class="mittefuss_lat_links1437_cls6">
    <path id="transperent_metatarsal_1" data-name="transperent metatarsal 1" class="mittefuss_lat_links1437_cls2" d="M172.2,139.2c20.1-.3,33.6,6.2,38.2,21.6,0,11.7-8.5,20.7-23,27.8-11.5,7.6-24.4,8.4-38.5,2.8"/>
    <path id="transperent_metatarsal_2" data-name="transperent metatarsal 2" class="mittefuss_lat_links1437_cls2" d="M138.3,140.7c-1.9-19.8,10.2-28.3,28.8-31.7l39.6-6.3c21.6,1.6,24.2,12.5,18.2,27.6-11.4,18-25.4,33.5-41.4,46.6-27,8.8-42.2-3-45.2-36.3h0s0,0,0,0Z"/>
    <path id="transperent_metatarsal_3" data-name="transperent metatarsal 3" class="mittefuss_lat_links1437_cls2" d="M231.6,140.3c-.3-18.4.8-34.5,15.9-26.2,26,20.8,57,34.4,89.6,45.9l93.7,33.1c25.9,9.6,32,24.2,26.1,41.8-4.1,11.4-13.9,18.2-31.9,18.1"/>
    <path id="transperent_metatarsal_4" data-name="transperent metatarsal 4" class="mittefuss_lat_links1437_cls2" d="M397.9,250.4c.3,12.1,9.7,13.9,24.3,9.9,22.6-7.5,38.6-16.1,28.1-29.2-7.2-13.4-49.8-27.2-95.4-41.1-36.9-10.7-72.9-24.7-106.8-45.3-18.7-20.3-26.9-16.2-27.3,5.7l-2.4,44.2"/>
    <path id="transperent_metatarsal_5" data-name="transperent metatarsal 5" class="mittefuss_lat_links1437_cls2" d="M211.4,211.9c-6.2-20.2-8.6-37.5,8-40.9l91.5,29.9c51.7,11.7,104.7,23.4,101.4,39.1-2,24.5-16.6,32.2-45.6,20.7l-15.3-15.9"/>
    <path id="transperent_metatarsal_6" data-name="transperent metatarsal 6" class="mittefuss_lat_links1437_cls2" d="M129.2,257.2c-22.3-4.8-22.6-14-8-26.1,10-21.6,26.9-34.8,54.4-35.3,71.4,10.2,139.9,21.6,181.3,44.7,8.5,13.5,4.7,22.7-4.6,30.1-19.1,8.8-33.1,10.5-37.4-1.1-29.8-22.9-73.9-26.2-123.4-21.9-21.6,7.4-42.6,12.3-62.3,9.7h0Z"/>
    <path id="transperent_metatarsal_7" data-name="transperent metatarsal 7" class="mittefuss_lat_links1437_cls2" d="M378.6,225.2c-17-2.2-20.9,8.3-20.5,22.8,7.5,16.5,16.3,18.8,26.2,9.7,10.8-8.6,24.6-13.1,40.9-14,9.7-3,13.7-7.8,9.9-15-2-3.8-6.1-6-10.4-5.8l-46.1,2.3h0s0,0,0,0h0Z"/>
    <path id="transperent_metatarsal_8" data-name="transperent metatarsal 8" class="mittefuss_lat_links1437_cls2" d="M502.1,214.6h-15.9c0,0,0,0,0,0-27.6-1.6-34.8,3.8-27,14.3-2.2,17.3,1.4,23.5,12.3,15.7,10.4-5.1,21-9,32-11.4"/>
    <path id="transperent_metatarsal_9" data-name="transperent metatarsal 9" class="mittefuss_lat_links1437_cls2" d="M499.8,228.4c-1.9.4-3.7.8-5.6,1.1-9.3,1.7-12.8,4.7-14.1,8h0c.5,5.3,5,9.4,10.3,9.2h0s7.4-.2,7.4-.2"/>
  </g>
  <path id="soft_parts_1" data-name="soft parts 1" class="mittefuss_lat_links1437_cls3" d="M509.3,175.6l-91.2-23.5c-14-3.6-27.8-8.2-41.2-13.6l-160.6-65.4c-21.7-8.8-44.2-15.3-67.3-19.2l-42.6-7.2"/>
  <path id="soft_parts_2" data-name="soft parts 2" class="mittefuss_lat_links1437_cls3" d="M274.7,280c-41.2-25-183.4,7.3-255.4-5.6"/>
</g>
<g id="Ebene_2" data-name="Ebene 2">
  <path id="metatarsal_bone_overlay" data-name="metatarsal bone overlay" class="MFK1437Fill" style= {{
          fill: props.colors.MFK1437Fill,
          stroke: props.colors.MFK1437Fill,
          opacity:props.colors.MFK1437Fill,
        }} d="M431.6,176.5c5.4-1.9,9.9-2.1,12.5.8,11.7,6.2,17.8,12.9,18.2,20.2.7,10.1-1.1,17.6-6.3,21.6-.2,2.9.8,6.1,3.2,9.7l-6.2,13.1c-7,10.2-21.1,16.1-38.6,19.9l-9.8-1.5c-8.3,7.4-18.7,6.3-29.7,2.6-4.2.2-8.6-2.5-13.1-6.3-2.8,8.2-7.3,13.6-13.5,15.7-17,7-29.2,7.5-33.5-2.8-29.3-23.5-74.7-25.5-124.6-21.5-21.5,7.7-42.6,11.9-63,8.8-21.7-5.7-19.4-14.9-6.1-25.7,8-17.7,18.9-25.9,31.3-29.7,15.7-8,35.2-5.9,55.9-.7-4.4-15.8-1.5-26.2,11-29.7-.3-27.4,3.1-42.8,12.2-39.3-.2-20.3,7.2-24.8,22.4-12.7l11.7,8c2.2-14.7,14-10.9,30.8,2.7,35.3,19,82.3,33.9,135.1,46.8h0Z"/>
  <path id="foot_arch_overlay" data-name="foot arch overlay" class="FootArch1437Fill" style= {{
          fill: props.colors.FootArch1437Fill,
          stroke: props.colors.FootArch1437Fill,
          opacity:props.colors.FootArch1437Fill,
        }} d="M75.8,223.4c13.3,14.4,26.3,26,38.3,27.6,14.2,12.1,41.2,9.2,76.2-3,55.3-5.9,95.3,2.1,123.6,20.7,1.6,1,2.6,2.7,2.8,4.6h0c.2,2.4-2.2,4.2-4.5,3.2-32.4-14-75.8-18.8-128.5-16-1.3,0-2.6.2-3.9.3l-45.4,5.5c-16.1,1.9-32.2-3-44.4-13.6-10.5-9.1-21.9-21.2-33.5-33.9-2.4-2.6.5-6.7,3.7-5.3l3.6,1.5c4.6,1.9,8.7,4.8,12,8.4h0Z"/>
  <g id="soft_parts_overlay" data-name="soft parts overlay">
    <path id="soft_parts_overlay_2" data-name="soft parts overlay 2" class="Weichteile1437Fill" style= {{
          fill: props.colors.Weichteile1437Fill,
          stroke: props.colors.Weichteile1437Fill,
          opacity:props.colors.Weichteile1437Fill,
        }} d="M107.5,46.9c-.1,0,0-.2,0-.2,62.8,9.1,104.3,21.3,172.2,52.1,52.5,22,103.2,43,131.3,51.3l98.3,25.4.3,3.1c.2,1.9-1.6,3.4-3.4,2.9l-93.2-24.5c-5.1-1.3-10.1-2.9-15.1-4.8l-129.3-47.7L107.5,46.9Z"/>
    <path id="soft_parts_overlay_1" data-name="soft parts overlay 1" class="Weichteile1437Fill" style= {{
          fill: props.colors.Weichteile1437Fill,
          stroke: props.colors.Weichteile1437Fill,
          opacity:props.colors.Weichteile1437Fill,
        }} d="M274.7,280c-21.8-16.4-60-21.8-118.9-13.3-16.3,2.4-32.7,3.9-49.1,4.5l-87.4,3.2c58.5,12.8,202.2-17.4,255.4,5.6h0Z"/>
  </g>
</g>
</svg>
  );
}

export default LinkerMittelfussLatLiegend1437;
