import {
  getP1Order,
} from "../Helpers/FetchFunctions/ris/IntegrationFetches";
import usePmedQuery from "../Helpers/UsePmedQuery";

export function useP1Order({
  orderKey,
  orderCode,
  orderPatID,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["order_p1", orderKey, orderCode, orderPatID],
    getP1Order,
    {
      orderKey,
      orderCode,
      orderPatID
    },
    keepPreviousData,
    enabled
  );
  return query;
}
