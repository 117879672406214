import style from "./style.scss";

export default function LinkerOberschenkelMitHufteVdOblLiegend2351(props) {
  return (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 611.65 353">

    <g id="hip_with_upper_leg" data-name="hip with upper leg">
      <g id="background_layer" data-name="background layer">
        <path id="background_1" data-name="background 1" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls9" d="M607.29,170.64c-16.1,3.72-29.34,5.11-38.43,5.69-21.72,1.4-22.63-1.63-45.54,0-17.92,1.27-23.34,3.55-32.73,0-11.27-4.26-9.83-9.93-21.35-17.08-20.75-12.87-33.76.34-55.5-12.81-10.39-6.29-12.38-12.31-21.35-12.81-8.55-.48-12.47,4.68-24.19,8.54-7.99,2.63-17.57,5.27-28.46,2.85-5.54-1.23-5-2.4-18.04-7.07-11.46-4.11-11.73-3.16-17.54-5.74-10.33-4.58-11.51-8.5-21.35-14.23-9.77-5.69-18.54-7.61-22.77-8.54-5.05-1.11-21.45-4.5-39.85,1.42-31.03,9.99-43.87,38.79-45.54,42.69-2.62,6.14-13.44,31.49-1.42,56.93,10.51,22.24,31.56,29.87,51.23,37,18.94,6.87,27.61,4.33,31.31,2.85,8.41-3.38,8.01-8.1,17.08-14.23,3.11-2.11,11.21-6.99,44.12-8.54,16.86-.79,25.3-1.19,34.16,0,6.41.86,61.67,9,81.12,46.96,4.61,9,5.37,16.08,12.81,19.92,3.15,1.63,11.42,4.69,37-7.12,26.35-12.16,24.59-18.64,41.27-24.19,12.9-4.3,26.15-3.34,52.66-1.42,14.96,1.08,27.24,2.86,35.58,4.27"/>
        <path id="background_2" data-name="background 2" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls9" d="M390.97,153.56c-8.9-1.91-19.54-2.82-24.19,2.85-3.48,4.24-1.32,9.36-1.42,19.92-.12,12.02-3.05,20.72-7.12,32.73-7.15,21.16-14.68,26.16-11.39,34.16,2.96,7.17,11.89,10.13,15.65,11.39,10.18,3.38,14.36-.65,25.62,1.42,10.24,1.89,12.56,6.29,21.35,9.96,8.06,3.36,20.66,5.73,39.85,0"/>
        <path id="background_3" data-name="background 3" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls9" d="M281.39,140.75c0,6.27,0-15.65,75.43,11.34"/>
        <path id="background_4" data-name="background 4" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls9" d="M281.39,210.78c0,3.77,18.5-9.01,54.08,6.83"/>
        <path id="background_5" data-name="background 5" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls9" d="M18.77,291.35h0c2.16-12.09,1.73-31.04,1.25-50.12-.68-6.89-6.74-8.77-16.25-7.44l1.06-105.27v-49.82s2.58-29.47,2.58-29.47v-13.37s.91-29.47.91-29.47L361.19.32l-22.45,27.57c-3.28,3.28-5.57,7.21-6.55,12l-11.04,26.77-6.91,13.48-3.59,11.69c-1.92,9.43-5.72,15.85-13.01,16.64-5.8,1.19-9.71,3.15-12.18,5.71-1.02-.27-2.18-.09-3.08.87-22.44,23.73-39.38,52.39-56.82,79.83-7.62,11.98-14.34,24.63-18.51,38.25-2.07,6.77-3.47,13.64-4.32,20.59-.45.36-.92.69-1.47.97-3.19,2.43-6.76,2.25-10.62,0l-3.21,7.22c3.93,6.04,6.11,13.06,4.51,22.22-4.39,24.22-12.65,47.23-26.08,68.62v-.02l-79-3.49h-31.59c-25.48-3.02-46.1-10.11-54.99-27.04,10.38-6.81,15.71-17.77,18.49-30.84h0Z"/>
      </g>
      <g id="hip">
        <path id="hip_1" data-name="hip 1" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M361.19.32l-22.01,27.02c-3.05,3.74-5.39,7.99-6.98,12.54-4.75,13.55-10.58,26.75-17.59,39.56-1.67,3.05-2.91,6.35-3.45,9.79-.66,4.21-2,8.23-4.12,12.05-1.8,3.25-4.75,5.76-8.31,6.84-6.29,1.91-11.92,4.89-16.15,10.19-7.5,9.13-15.44,15.84-24.33,17.24-2.13.34-4.1,1.35-5.7,2.81-8.64,7.88-15.71,18.78-20.1,34.88-.71,2.58-2.01,4.97-3.85,6.91l-13.23,13.98c-6.37,9.17-9.16,21.7-6.44,39.4.2,1.32-.17,2.67-1.02,3.7-2.31,2.82-2.82,5.66-1.15,8.54-2.53,9.94-7.65,13.94-16.91,8.44l-2.43,7.67c5.12,8.02,5.58,16.89,3.95,26.13-4.66,24.31-12.83,46.12-25.52,64.71"/>
        <path id="hip_2" data-name="hip 2" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M310.14,71.41c-.38,5.01-2.02,9.39-5.67,12.77-1.57,1.44-2.39,3.49-2.53,5.61-.13,1.99-.48,3.76-1.06,5.31-1.52,4.1-5.6,6.69-9.98,6.69h-1.73c-.99,0-1.98.19-2.89.59-15.92,6.99-26.39,18.17-30.76,34-8.74-.74-17.46,4.66-26.18,17.2-10,12.46-7.03,22.74,0,32.35-.05,7.37-4.69,19.88-8.5,20.91-2.55.69-4.81,2.19-6.57,4.17-5.06,5.69-8.39,12.85-11.51,20.19-4,4.39-6.9,8.4-7.29,11.54.01,5.55-1.44,9.76-5.62,11.47l-2.43,7.67c-4.3,7.95-6.74,20.76-8.96,34.18"/>
        <path id="hip_3" data-name="hip 3" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M287.46,102c-34.57-6.74-69.19,0-93.51,24.85"/>
        <path id="hip_4" data-name="hip 4" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M189.85,254.22c-4.37,1.62-9.76-1.45-15.34-5.4"/>
        <path id="hip_5" data-name="hip 5" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M178.45,247c-12.01-11.15-22.06-27.3-22.78-67.29.86-36.31,19.13-61.1,51.95-76.25"/>
        <path id="hip_6" data-name="hip 6" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M165.84,230.78c-2.22,4.27-5.97,5.38-10.61,4.68-2.41-.36-4.87.21-6.89,1.56-8.59,5.75-16.53,11.68-23.51,17.87-2.16,1.92-4.59,3.51-7.15,4.86-4.93,2.6-8.28,6.06-9.86,10.49-3.4,6.19-7.32,11.59-11.93,15.93-1.42,1.33-2.45,3.06-2.82,4.97-.81,4.26-.49,9.46.93,15.55,3.54,12.31,12.44,14.63,22.03,15.65-5.87.35-10.45,2.75-13.37,7.75"/>
        <path id="hip_7" data-name="hip 7" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M55.27,349.23c-2.65-10.63-6.86-18.72-13.06-23.54-4.41-4.09-4.86-8.26-3.95-12.46-3.03-3.34-4.99-7.25-6.53-11.39-4.81-25.73-4.06-36.4-2.13-43.9,4.44-20.51,17.09-26.45,33.27-26.13,24.26,1.66,42.04-8.53,56.2-25.37,11.62-14.39,23.63-36.86,35.7-60.76-4.42,19.17-2.12,32.51,1.36,44.82"/>
        <path id="hip_8" data-name="hip 8" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M154.76,145.69c4.88-15.16,8.94-40.16,12.91-66.08"/>
        <path id="hip_9" data-name="hip 9" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M41.75,315.96c18.23-8.5,39.04-31.29,65.62-50.58"/>
        <path id="hip_10" data-name="hip 10" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M80.49,5.48l-.85,2.99c-.44,1.54.82,3.05,2.41,2.85,6.59-.84,12.34-.2,17.28,1.85,2.64,1.09,5.62.89,8.14-.45,13.9-7.39,27.41-6.07,40.46,5.53,12.38,14.38,13.42,35.37,2.73,63.19-11.83,31.39-30.06,57.57-54.99,78.32-3.95,2.96-6.98,7.97-8.82,15.65-1.24,5.19,1.45,10.53,6.39,12.57l8.41,3.47c9.96,4.11,16.9,13.46,17.57,24.21.23,3.7.03,7.79-.61,12.28-.27,1.88-.18,3.79.31,5.62,2.21,8.29,3.52,16.23,3.13,23.53-4.3,42.17-18.33,69.34-35.19,92.14"/>
        <path id="hip_11" data-name="hip 11" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M158,39.81c2.71,8.25,3.33,19.5.71,35.39"/>
        <path id="hip_12" data-name="hip 12" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M3.77,233.78c5.18-.43,9.8.34,13.82,2.45,1.5.79,2.44,2.34,2.44,4.03v38.18c-.5,20.02-6.71,34.94-19.75,43.75"/>
        <path id="hip_13" data-name="hip 13" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M4.83,128.51l20.78-10.51c8.55-4.32,15.44-11.48,19.06-20.35,3.01-7.35,4.96-16.28,6.15-26.35.6-5.08.81-10.2.89-15.31.11-7.62,1.32-13.55,3.13-18.54,1.06-2.92,2.64-5.65,4.76-7.93,2.98-3.2,5.29-6.5,6.61-9.92,1.57-2.98,3.48-4.75,5.54-5.99,1.17-.7,1.9-1.95,1.9-3.31v-3.91"/>
        <ellipse id="hip_14" data-name="hip 14" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls2" cx="216.13" cy="83.91" rx="7.59" ry="6.15" transform="translate(-15.6 76.35) rotate(-19.35)"/>
        <path id="hip_15" data-name="hip 15" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M4.84,93.89c4.74-1.16,7.66-3.47,9.49-6.46,1.35-2.19,1.89-4.85,1.14-7.31-1.19-3.93-4.74-4.41-10.63-1.42"/>
        <path id="hip_16" data-name="hip 16" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls3" d="M7.43,35.87c1.61-1.59,3.23-1.98,4.84-1.19,2.05,1,2.97,3.42,2.45,5.64-.85,3.64-2.85,6.8-7.3,8.91"/>
        <path id="hip_17" data-name="hip 17" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls1" d="M32.06,236.63h0c-.03-.77-.07-1.53-.13-2.3-.53-6.51.17-13.41,2.23-20.73,2.67-9.93,8.51-15.29,16.18-18.01,1.64-.58,3.16-1.5,4.35-2.77,2.26-2.4,1.94-4.52-.71-6.38-.7-.5-1.49-.86-2.29-1.18-7.95-3.13-15.61-7.28-21.63-17.08-3.28-4.22-3.57-9.23-1.07-14.98.65-1.49,1.3-2.97,1.93-4.46,3.43-8.08,9.7-12.61,18.61-13.84.73-.1,1.47-.12,2.19.02,4.09.77,6.96,5.03,8.58,12.65.63,2.96,2.33,5.65,4.91,7.22,2.43,1.48,4.4,1.2,5.93-.75.88-1.12,1.28-2.53,1.35-3.95.5-10.75,3.99-18.84,10.09-24.62.74-.7,1.56-1.34,2.41-1.92,2.44-1.66,1.54-3.77-.81-6.06-1.32-1.28-2.84-2.33-4.45-3.21-9.56-5.23-18.63-11.05-26.91-17.81-3.37-2.75-6.05-6.24-7.81-10.21-2.19-4.93-5.31-11.96-7.21-16.24-.95-2.13-2.21-4.08-3.66-5.9-4.05-5.1-5.54-12.59-4.05-22.82.27-1.9.36-3.83.18-5.74-.59-6.25,2.12-11.18,7.82-14.9,1.41-.92,2.4-2.36,2.93-3.96,1.53-4.57,4.75-7.1,9.83-7.41.1-.03.19-.07.29-.1,10.14-3.48,21.3,1.55,25.56,11.39,2.8,6.45,4.51,11.99,4.73,16.28.1,1.93-.56,3.86-1.96,5.18-1.82,1.7-4.24,2.04-7.1,1.41-1.78-.39-3.65.39-4.49,2.01-.9,1.74-.86,3.87.33,6.49.86,1.88,2.13,3.55,3.62,4.98l15.63,15.01c2.23,2.14,4.12,4.63,5.35,7.46,2.34,5.37,2.44,11.26.31,17.67-.37,1.11-.7,2.23-.84,3.39-1.01,8.48,4.28,14.72,16.8,18.39.98.29,1.95.6,2.88,1.02,10.03,4.55,17.03,12.83,20.1,25.96.46,1.95.7,3.95.6,5.94-.43,8.11-5.52,16.03-22.11,23.47l-34.76,12.1c-1.12.39-2.19.95-3.07,1.75-2.59,2.38-2.84,5.62-1.05,9.61,4.16,10.41,5.04,20.61,1.17,30.5-.68,1.75-1.58,3.42-2.58,5.01-6.6,10.46-14.48,16.96-24.82,15.87-.23-.03-.47-.05-.71-.05-8.28-.23-12.34-4.87-12.68-13.42v.02h.01Z"/>
        <path id="hip_18" data-name="hip 18" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls1" d="M132.32,69.75h0c-.9,2.34-3.16,3.93-5.66,3.93-1.69,0-3.52-.43-5.49-1.22-10.49-6.15-14.63-15.14-15.65-25.52-.6-7.14-6.37-11.99-5.16-15.95.97-3.39,3.84-4.7,7.38-5.26,1.08-.17,2.11-.56,3.01-1.17l5.83-3.94c3.86-2.61,9.17-1.66,11.89,1.99s5.08,9.28,7.29,15.68c2.16,5.08,2.48,10.26-.2,15.63-1.17,2.34-1.89,4.92-1.82,7.53.07,2.89-.38,5.66-1.41,8.31h-.01Z"/>
      </g>
      <g id="upper_leg" data-name="upper leg">
        <path id="upper_leg_1" data-name="upper leg 1" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls4" d="M607.29,170.64c-16.1,3.72-29.34,5.11-38.43,5.69-21.72,1.4-22.63-1.63-45.54,0-17.92,1.27-23.34,3.55-32.73,0-11.27-4.26-9.83-9.93-21.35-17.08-20.75-12.87-33.76.34-55.5-12.81-10.39-6.29-12.38-12.31-21.35-12.81-8.55-.48-12.47,4.68-24.19,8.54-7.99,2.63-17.57,5.27-28.46,2.85-5.54-1.23-5-2.4-18.04-7.07-11.46-4.11-11.73-3.16-17.54-5.74-10.33-4.58-11.51-8.5-21.35-14.23-9.77-5.69-18.54-7.61-22.77-8.54-5.05-1.11-21.45-4.5-39.85,1.42-31.03,9.99-43.87,38.79-45.54,42.69-2.62,6.14-13.44,31.49-1.42,56.93,10.51,22.24,31.56,29.87,51.23,37,18.94,6.87,27.61,4.33,31.31,2.85,8.41-3.38,8.01-8.1,17.08-14.23,3.11-2.11,11.21-6.99,44.12-8.54,16.86-.79,25.3-1.19,34.16,0,6.41.86,61.67,9,81.12,46.96,4.61,9,5.37,16.08,12.81,19.92,3.15,1.63,11.42,4.69,37-7.12,26.35-12.16,24.59-18.64,41.27-24.19,12.9-4.3,26.15-3.34,52.66-1.42,14.96,1.08,27.24,2.86,35.58,4.27"/>
        <path id="upper_leg_2" data-name="upper leg 2" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls4" d="M390.97,153.56c-8.9-1.91-19.54-2.82-24.19,2.85-3.48,4.24-1.32,9.36-1.42,19.92-.12,12.02-3.05,20.72-7.12,32.73-7.15,21.16-14.68,26.16-11.39,34.16,2.96,7.17,11.89,10.13,15.65,11.39,10.18,3.38,14.36-.65,25.62,1.42,10.24,1.89,12.56,6.29,21.35,9.96,8.06,3.36,20.66,5.73,39.85,0"/>
        <path id="upper_leg_3" data-name="upper leg 3" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls4" d="M281.39,140.75c0,6.27,0-15.65,75.43,11.34"/>
        <path id="upper_leg_4" data-name="upper leg 4" class="oberschenkel_mit_hüfte_obl_Lauenstein_links2351_cls4" d="M281.39,210.78c0,3.77,18.5-9.01,54.08,6.83"/>
      </g>
    </g>
    <g id="overlays">
      <path id="hip_pan_overlay" data-name="hip pan overlay" class="Pfanne2351Fill"  style={{
            fill: props.colors.Pfanne2351Fill,
            stroke: props.colors.Pfanne2351Fill,
          }} d="M162.09,147.43c21.43-47.46,91.71-60.94,117.99-48.62,48.6,22.79-74.13-2.73-95.7,64.41-15.93,49.58,28.88,103.38,2.43,91.43-26.46-11.94-46.16-59.76-24.73-107.22h.01Z"/>
      <path id="hip_head_overlay" data-name="hip head overlay" class="Femoralhead2351Fill"  style={{
            fill: props.colors.Femoralhead2351Fill,
            stroke: props.colors.Femoralhead2351Fill,
          }} d="M263.52,242.81c-.54,1.45-1.5,3.43-3.28,5.18-7.38,7.28-21.53,3.51-34.17.14-7.75-2.07-20.82-5.7-34.49-16.39-6.54-5.12-11.23-10.29-14.32-14.13-16.73-32.92-9.45-60.22,10.36-84.58,4.02-4.94,14.42-16.15,31.96-22.17,23.97-8.22,45.02-.77,52.35,1.94,8.43,3.12,14.83,7.05,19.02,9.98l-27.44,122.07v-2.04Z"/>
      <path id="neck_of_the_femur_overlay" data-name="neck of the femur overlay" class="Schenkelhals2351Fill"  style={{
            fill: props.colors.Schenkelhals2351Fill,
            stroke: props.colors.Schenkelhals2351Fill,
          }} d="M263.52,244.85c12.52-17.07,44.12-17.37,86.97-17.52.21,0,.42-1.08.5-1.27,3.15-6.93,9.31-11.59,12.76-39.63,3.32-26.96-.07-14.36,1.61-27.51.12-.95,1.52-11.76-2.39-14.46-.35-.24-.72-.41-.72-.41-8.32,3.37-14.52,4.41-30.59-2.95-.02,0-.04-.02-.06-.02-24.51-7.33-42.13-14.51-40.65-18.29l-26.14,114.36-1.3,7.71Z"/>
      <path id="trochanter_major_overlay" data-name="trochanter major overlay" class="TrochanterMajor2351Fill"  style={{
            fill: props.colors.TrochanterMajor2351Fill,
            stroke: props.colors.TrochanterMajor2351Fill,
          }} d="M410.4,144.48c-.44-.41-1.14-1.07-2-1.85-4.62-4.16-7.05-6.35-10.13-7.57,0,0-1.91-.76-5.83-1.43-.02,0-.03,0-.03,0-.05,0-.08.01-.08.02-.07.03-7.27,1.44-9.81,2.13-5.77,1.56-5.02,4.05-10.94,5.84-5.46,1.66-8.07.79-8.7,2.16-.62,1.34,1.94,2.34,2.63,6.37.34,1.98.18,4.4.15,4.86-.04.6-.17,2.18-.26,3.24-.07.82-.12,1.41-.13,1.53-.11,1.17-.26,3.32-.25,7.93.02,9.08-.75,33.18-16.25,63.03-7.04,13.56.92,22.15,24.57,25.57,11.25-2.53,20.44.36,28.7,5.69,5.34,3.44,9.61,4.7,10.49,5.14,6.26,3.1,17.05,5.01,36.79-1.16-5.25-18.76-10.21-38.9-14.63-60.33-3.84-18.65-6.9-36.54-9.33-53.56l-14.96-7.61Z"/>
      <path id="trochanter_minor_overlay" data-name="trochanter minor overlay" class="TrochanterMinor2351Fill"  style={{
            fill: props.colors.TrochanterMinor2351Fill,
            stroke: props.colors.TrochanterMinor2351Fill,
          }} d="M515.82,265.37c-28.12,20.86-51.87,33-68.05,30.11-8.81.58-10.94-14.84-18.44-26.03,7.31-.24,14.11-.97,18.44-4.07h68.05s0-.01,0-.01Z"/>
    </g>
  </svg>
  );
}
