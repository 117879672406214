import style from "./style.scss";

function BeideVorfusseDplLiegend1472(props) {
  return (
<svg id="both_feet" data-name="both feet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 516.59 488.86">
  
  <g id="foot_overview_right" data-name="foot overview right">
    <g id="foot">
      <g id="toes">
        <g id="big_toe" data-name="big toe">
          <path id="big_toe_1" data-name="big toe 1" class="beide_vorfusse1472_dp_cls2" d="M300.39,60.64v-.15c3.54-8.19,6.42-8.78,8.86-3.91,6.57-7.9,13.22-15.81,11.89-29.76-2.29-2.66-2.36-5.39,2.29-8.49,5.16-5.98,10.85-9.52,17.79-6.35,5.39-.51,10.41-.51,8.56,7.83,5.31,2.8,6.05,6.5,3.32,11-9.38,4.95-15.06,12.03-7.9,26.21,2.14,6.64,1.03,11.44-2.29,14.91l-18.09,2.14-22.37-1.03c-4.06-.96-4.87-4.94-1.99-12.4h0s-.07,0-.07,0Z"/>
          <path id="big_toe_2" data-name="big toe 2" class="beide_vorfusse1472_dp_cls2" d="M291.73,155.75v.07h.07c-5.98,15.65-1.33,21.85,12.48,19.86l25.33-5.69c11.89,5.98,13.88-1.77,8.86-19.49-6.05-18.46-11-37.07.37-59.22,7.39-14.03,3.77-19.57-8.56-18.46l-18.75,1.4c-11.74-4.57-15.95,2-16.47,14.18,6.57,24.29,3.55,46.3-3.32,67.34h0Z"/>
        </g>
        <g id="second_toe" data-name="second toe">
          <path id="second_toe_1" data-name="second toe 1" class="beide_vorfusse1472_dp_cls1" d="M391.21,8.73h.07c6.64-8.27,11.59-8.35,14.47,1.4,10.55,6.72,8.63,9.45.66,10.26-1.99,3.39-1.92,6.72.96,9.89,3.62,3.62,2.95,6.79-1.33,9.6h-19.79c-3.91-2.95-6.2-5.91-.66-8.49,4.21-2.51,5.39-5.68,2.29-9.89-3.84-3.61-2.37-7.9,3.32-12.77,0,0,.01,0,.01,0Z"/>
          <path id="second_toe_2" data-name="second toe 2" class="beide_vorfusse1472_dp_cls1" d="M381.67,52.96v.07h0c-.44-8.12,0-14.4,7.24-8.86,3.25-1.48,5.83-1.18,10.56-.74,11.51-3.18,10.85,1.99,1.99,12.77-2.8,8.42-3.54,15.36-1.99,20.53,4.88,7.31,2.74,9.75-2.95,9.6l-13.81-2.51c-6.72,1.63-10.41-.15-5.61-11.37,4.43-4.21,6.57-10.19,4.58-19.49,0,0-.01,0-.01,0Z"/>
          <path id="second_toe_3" data-name="second toe 3" class="beide_vorfusse1472_dp_cls1" d="M375.11,100.07v.07h0c-1.26-9.9,1.18-15.73,9.89-14.55,2.21,2.36,4.35,2.36,6.57,0,9.53,1.11,14.18,5.47,9.23,15.95-9.38,22.74-7.9,38.17-2.66,51.02,5.1,13.36,3.1,15.95-4.28,10.26-6.79-9.67-13.22-10.19-19.42-3.54-13.22,5.39-14.18,1.26-4.95-10.63,5.83-8.86,8.78-24.73,5.61-48.58h0Z"/>
        </g>
        <g id="third_toe" data-name="third toe">
          <path id="third_toe_1" data-name="third toe 1" class="beide_vorfusse1472_dp_cls1" d="M434.46,48.4v.07h-.07c-5.09-3.91-6.5-7.31.37-9.6,4.06-3.55,8.12-4.58,11.89,1.03,4.8,3.1,6.13,6.42.96,9.89-1.4,3.91-1.4,6.94.37,8.86,6.65,8.05,5.84,11.52-.66,11.37-4.66-1.48-9.38-1.33-14.18-.74-6.05-4.88-5.98-8.79-.96-12.04,1.4-1.7,2.14-4.58,2.29-8.86h0v.02h0Z"/>
          <path id="third_toe_3" data-name="third toe 3" class="beide_vorfusse1472_dp_cls1" d="M426.13,77.85h0c-.74-5.91-.15-10.48,6.94-8.49,3.77-.59,7.31-.96,7.9,2.14,8.72-5.32,7.98,1.47,5.91,9.89-4.21,6.71-4.43,9.89-3.99,12.4,4.94,5.39,3.25,7.61-3.99,7.09-2.07-2.66-5.24-2.07-8.86,0-5.02-1.26-7.09-5.24-4.58-13.44,1.1-1.92,1.32-5.02.66-9.6h.01Z"/>
          <path id="third_toe_2" data-name="third toe 2" class="beide_vorfusse1472_dp_cls1" d="M424.27,110.47h-.07c-2.66-7.53,0-11.22,9.23-10.26l3.62.74c10.41-2,13.66,1.84,8.27,12.77-11.66,20.38-12.25,36.48-9.89,51.39,7.38,14.33,5.46,18.46-4.58,13.44-3.69-7.38-7.97-9.82-13.14-3.91-6.8,12.7-11.08,2.14-5.91-11,9.31-9.97,12.63-29.02,12.48-53.16h0,0Z"/>
        </g>
        <g id="fourth_toe" data-name="fourth toe">
          <path id="fourth_toe_1" data-name="fourth toe 1" class="beide_vorfusse1472_dp_cls1" d="M467.99,80.96v.07h0c3.54-7.46,7.6-7.46,12.18-.74,6.35,5.24,6.13,8.49-.66,9.89-1.18,4.13-1.25,7.38-.66,10.26,8.64.59,6.28,3.69,1.33,7.46-4.43-2.21-9.23-2.29-14.18-1.4-3.17-2.14-3.03-5.39,0-9.6,2.44-3.25,1.99-6.28,0-9.23-4.14-1.11-2.29-3.62,1.99-6.72h0Z"/>
          <path id="fourth_toe_3" data-name="fourth toe 3" class="beide_vorfusse1472_dp_cls1" d="M466.66,108.34c3.91-2.44,9.01-1.55,14.84,1.03,2.66,2.36,2.66,4.73,0,7.09-.74,3.47-.89,6.35-.37,8.49,2.44,4.72,1.33,7.16-1.33,8.49-6.5-1.99-10.7-2.95-13.51-3.17-5.39-1.03-4.87-4.5-2.29-8.86l1.33-4.58c-1.84-3.99-2.36-7.31,1.33-8.49h0Z"/>
          <path id="fourth_toe_2" data-name="fourth toe 2" class="beide_vorfusse1472_dp_cls1" d="M462.75,144.97v-.07h-.07c-2.44-8.72-2.07-14.26,5.24-12.04,3.32,1.03,6.5.29,7.9,1.77,7.24,1.33,9.75,6.43,5.91,16.32-12.55,13.66-9.52,29.09-7.9,44.3,3.39,16.17,1.18,18.68-4.58,12.77-3.62-4.8-7.9-5.03-12.48-2.81-7.16,5.54-7.23.74-5.24-7.09,6.2-16.54,10.26-34.11,11.22-53.16h0Z"/>
        </g>
        <g id="little_toe" data-name="little toe">
          <path id="little_toe_1" data-name="little toe 1" class="beide_vorfusse1472_dp_cls1" d="M497.61,141.65h0c-2.66-3.17-1.85-7.09.66-11.37,4.94-6.28,8.78-6.79,10.85,1.77,4.73,6.05,4.95,10.26,0,12.4,3.62,10.92,3.25,15.35.37,14.91l-12.85-1.03c-5.24-2.81-4.35-6.06-.66-9.6,3.61-2.29,3.24-4.65,1.62-7.09h.01Z"/>
          <path id="little_toe_3" data-name="little toe 3" class="beide_vorfusse1472_dp_cls1" d="M494.41,161.87h-.07c4.36-3.32,9.75-3.84,16.47-1.03-1.03,6.27-1.33,10.56-.96,13.14,6.35,3.98,5.1,8.19,2.66,12.4-6.35,1.25-13.44.66-20.75-.37-3.1-3.18-4.8-6.35,0-9.23,2-4.43,3.03-9.3,2.66-14.91h0Z"/>
          <path id="little_toe_2" data-name="little toe 2" class="beide_vorfusse1472_dp_cls1" d="M490.37,195.54h0c-.52-7.61,1.11-10.41,5.24-7.09l9.52,1.03c5.24-3.02,7.46-1.03,5.61,7.83-7.6,13.3-10.56,26.29-7.9,38.99,1.1,9.89-1.04,12.48-4.95,11-1.18-4.88-5.24-6.13-11.52-4.58-6.87,3.02-8.49.88-3.62-7.83,5.17-9.74,7.24-23.48,7.61-39.35h0Z"/>
        </g>
      </g>
      <g id="metatarsus">
        <path id="metatarsus_1" data-name="metatarsus 1" class="beide_vorfusse1472_dp_cls1" d="M287.76,211.86h.07c-4.5-19.35-5.24-34.48,8.86-33.3,9.82-1.25,23.19-11.15,42.46-3.54,3.84.14,6.79,3.69,8.86,9.89,15.36,4.21,15.95,12.55.96,25.18-12.85,41.79-7.61,75.32,14.47,101.01,11.08,4.06,12.18,12.77-.96,27.98-17.2-5.76-27.98.59-38.54,7.46-14.69,8.79-18.38.59-16.76-15.58.88-40.83-6.35-80.41-19.42-119.1h0Z"/>
        <path id="metatarsus_2" data-name="metatarsus 2" class="beide_vorfusse1472_dp_cls1" d="M368.83,167.32v-.15c9.6-10.41,19.2-10.92,28.65-1.03,3.62,3.47,4.36,7.75,2.66,12.77,6.43,6.94,4.51,16.17-1.99,26.58-7.68,38.4-1.7,83.73,3.62,128.7,5.61,4.58,8.42,10.93,5.61,20.9,2.81,14.62-1.99,22.52-10.19,27.32-3.98-14.47-15.65-17.28-29.31-16.98,1.62-9.75-.74-17.28-4.95-23.78,1.62-3.32,6.49-9.08,9.52-17.72,4.13-20.6,3.47-67.04,2.95-112.01-12.1-12.1-11.66-20.15-9.52-27.61,1.77-5.9,2.51-11.52,2.95-16.98h0Z"/>
        <path id="metatarsus_3" data-name="metatarsus 3" class="beide_vorfusse1472_dp_cls1" d="M414.32,181.72h0c5.17-9.15,10.19-13.58,14.84-3.17,7.38,4.58,12.92,11.52,9.89,29.09-6.64,9.45-9.3,21.12-8.56,34.78-1.48,23.48,2.44,59.58,6.94,97.09,8.12,25.18,4.06,36.04-4.95,41.13-3.03-17.64-12.11-16.61-22.08-13.14-2.66-8.2-1.18-19.72,1.99-32.64,3.62-20.08,1.99-73.54.96-123.01-6.42-2.65-5.24-13.73.96-30.12h0Z"/>
        <path id="metatarsus_4" data-name="metatarsus 4" class="beide_vorfusse1472_dp_cls1" d="M447.83,228.47l.07.07h0c-3.32-7.01,0-14.1,7.24-21.26,6.65-5.02,12.11-3.02,16.47,4.95l1.99,15.58c1.47,9.01-1.92,15.06-7.61,19.86-8.05,12.19-7.39,38.77-6.28,65.94l5.61,54.93c3.18,8.19,5.02,16.61.96,26.21-1.11,8.64-3.47,13.88-6.57,16.98-3.83-12.7-13.36-19.04-26.65-21.26,7.53-17.06,10.12-37,8.86-59.22-2.43-34.26.15-68.52,5.91-102.78h0Z"/>
        <path id="metatarsus_5" data-name="metatarsus 5" class="beide_vorfusse1472_dp_cls1" d="M479.87,258.59v.07h-.07c0-17.06,10.64-19.27,17.43-9.6,6.13-1.25,5.76,3.84,2.29,12.04,5.32,5.54,8.35,11.44-3.32,20.23-8.12,15.88-10.19,34.33-8.86,54.27l4.95,66.97c7.53,26.95,1.4,41.13-12.85,47.85-4.87,8.27-8.49,8.71-10.19-2.14,1.18-9.31-3.03-21.56-8.86-34.78,5.68-12.33,9.6-24.44,6.94-35.81l.66-47.48,8.27-55.3c-3.47-4.65-2.07-10.12,3.62-16.32h0Z"/>
        <path id="metatarsus_6" data-name="metatarsus 6" class="beide_vorfusse1472_dp_cls1" d="M293.14,179.96c-4.13,1.03-4.13,2.21,0,3.54,13.29,6.13,27.47,4.21,42.16-3.54,2.22-.52,3.55-2.22,3.99-4.95"/>
        <path id="metatarsus_7" data-name="metatarsus 7" class="beide_vorfusse1472_dp_cls1" d="M370.53,168.22c6.35,5.02,12.92,8.79,20.45,4.95l8.56-4.58"/>
        <path id="metatarsus_8" data-name="metatarsus 8" class="beide_vorfusse1472_dp_cls1" d="M413.28,188.81c3.62-1.25,6.2,1.03,7.9,6.72,5.39,2.07,8.64.67,10.19-3.17,3.62-.73,5.61-2.65,5.61-6.05"/>
        <path id="metatarsus_9" data-name="metatarsus 9" class="beide_vorfusse1472_dp_cls1" d="M450.19,214c4.06-.22,7.16,1.92,8.86,7.46,4.88,3.17,9.01,2.51,12.85-.37"/>
        <path id="metatarsus_10" data-name="metatarsus 10" class="beide_vorfusse1472_dp_cls1" d="M482.46,260.44c8.19,2.14,13.88.66,13.51-9.23"/>
      </g>
      <g id="tarsus">
        <path id="tarsus_5" data-name="tarsus 5" class="beide_vorfusse1472_dp_cls1" d="M328.74,422.8h-.07c-16.54,9.23-20.16-3.99-18.09-26.95-.59-23.11,4.8-38.4,13.51-49.25,24.66-10.85,45.41-15.8,41.2,17.72-13.29,16.24-21.41,33-19.12,50.65l-17.43,7.83h0Z"/>
        <path id="tarsus_4" data-name="tarsus 4" class="beide_vorfusse1472_dp_cls1" d="M385,423.75c-8.57-9.67-20.24-11.22-33.6-8.12-5.83-11.81-2.29-27.68,12.48-48.58,16.17-3.1,26.8.89,30.64,13.44,2.15,14.7-2.06,29.09-9.52,43.27h0Z"/>
        <path id="tarsus_3" data-name="tarsus 3" class="beide_vorfusse1472_dp_cls1" d="M388.9,424.21h0c6.79-14.18,11.22-29.02,12.48-44.67,15.36-17.58,25.25-15.8,30.94,1.03,1.63,27.17-.44,48.73-10.85,57.44-4.5,4.88-9.82,7.24-15.8,7.83-1.55-14.25-9.01-18.23-16.76-21.63h0Z"/>
        <path id="tarsus_2" data-name="tarsus 2" class="beide_vorfusse1472_dp_cls1" d="M446.65,481.59h-.07c-33.89,14.25-44.45,3.47-53.31-9.23,7.68-8.42,11.52-16.32,11.89-23.78,24.07-7.09,30.79-25.33,27.98-49.62,12.03-3.39,21.7,1.55,26.65,23.41,7.68,9.15,7.68,19.64,5.61,30.49-5.39,11-11.74,20.38-18.75,28.72h0Z"/>
        <path id="tarsus_1" data-name="tarsus 1" class="beide_vorfusse1472_dp_cls1" d="M313.16,488h.07c-9.09-4.06-12.04-13.58-6.28-30.86,1.92-18.09,6.94-30.72,18.09-32.27,24.37-12.11,44.6-13.73,58.26.74,26.36,6.94,21.34,24.95,7.24,46.07-27.76-25.69-53.16-14.25-77.38,16.32h0Z"/>
      </g>
    </g>
    <g id="overlays">
      <g id="Phalangen_overlay" data-name="Phalangen overlay" class="beide_vorfusse1472_dp_cls3">
        <g id="Phalangen_overlay_1" data-name="Phalangen overlay 1">
          <path id="Phalangen_overlay_1.1" data-name="Phalangen overlay 1.1" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M300.24,61.01v-.15c3.54-8.19,6.42-8.78,8.86-3.91,6.57-7.9,13.22-15.81,11.89-29.76-2.29-2.66-2.36-5.39,2.29-8.49,5.16-5.98,10.85-9.52,17.79-6.35,5.39-.51,10.41-.51,8.56,7.83,5.31,2.8,6.05,6.5,3.32,11-9.38,4.95-15.06,12.03-7.9,26.21,2.14,6.64,1.03,11.44-2.29,14.91l-18.09,2.14-22.37-1.03c-4.06-.96-4.87-4.94-1.99-12.4h0s-.07,0-.07,0Z"/>
          <path id="Phalangen_overlay_1.2" data-name="Phalangen overlay 1.2" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M291.58,156.12v.07h.07c-5.98,15.65-1.33,21.85,12.48,19.86l25.33-5.69c11.89,5.98,13.88-1.77,8.86-19.49-6.05-18.46-11-37.07.37-59.22,7.39-14.03,3.77-19.57-8.56-18.46l-18.75,1.4c-11.74-4.57-15.95,2-16.47,14.18,6.57,24.29,3.55,46.3-3.32,67.34h0Z"/>
        </g>
        <g id="Phalangen_overlay_2" data-name="Phalangen overlay 2">
          <path id="Phalangen_overlay_2.1" data-name="Phalangen overlay 2.1" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M391.06,9.1h.07c6.64-8.27,11.59-8.35,14.47,1.4,10.55,6.72,8.63,9.45.66,10.26-1.99,3.39-1.92,6.72.96,9.89,3.62,3.62,2.95,6.79-1.33,9.6h-19.79c-3.91-2.95-6.2-5.91-.66-8.49,4.21-2.51,5.39-5.68,2.29-9.89-3.84-3.61-2.37-7.9,3.32-12.77,0,0,.01,0,.01,0Z"/>
          <path id="Phalangen_overlay_2.2" data-name="Phalangen overlay 2.2" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M381.52,53.33v.07h0c-.44-8.12,0-14.4,7.24-8.86,3.25-1.48,5.83-1.18,10.56-.74,11.51-3.18,10.85,1.99,1.99,12.77-2.8,8.42-3.54,15.36-1.99,20.53,4.88,7.31,2.74,9.75-2.95,9.6l-13.81-2.51c-6.72,1.63-10.41-.15-5.61-11.37,4.43-4.21,6.57-10.19,4.58-19.49,0,0-.01,0-.01,0Z"/>
          <path id="Phalangen_overlay_2.3" data-name="Phalangen overlay 2.3" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M374.96,100.44v.07h0c-1.26-9.9,1.18-15.73,9.89-14.55,2.21,2.36,4.35,2.36,6.57,0,9.53,1.11,14.18,5.47,9.23,15.95-9.38,22.74-7.9,38.17-2.66,51.02,5.1,13.36,3.1,15.95-4.28,10.26-6.79-9.67-13.22-10.19-19.42-3.54-13.22,5.39-14.18,1.26-4.95-10.63,5.83-8.86,8.78-24.73,5.61-48.58h.01Z"/>
        </g>
        <g id="Phalangen_overlay_3" data-name="Phalangen overlay 3">
          <path id="Phalangen_overlay_3.1" data-name="Phalangen overlay 3.1" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M434.31,48.77v.07h-.07c-5.09-3.91-6.5-7.31.37-9.6,4.06-3.55,8.12-4.58,11.89,1.03,4.8,3.1,6.13,6.42.96,9.89-1.4,3.91-1.4,6.94.37,8.86,6.65,8.05,5.84,11.52-.66,11.37-4.66-1.48-9.38-1.33-14.18-.74-6.05-4.88-5.98-8.79-.96-12.04,1.4-1.7,2.14-4.58,2.29-8.86h0v.02h0Z"/>
          <path id="Phalangen_overlay_3.2" data-name="Phalangen overlay 3.2" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M425.98,78.22h0c-.74-5.91-.15-10.48,6.94-8.49,3.77-.59,7.31-.96,7.9,2.14,8.72-5.32,7.98,1.47,5.91,9.89-4.21,6.71-4.43,9.89-3.99,12.4,4.94,5.39,3.25,7.61-3.99,7.09-2.07-2.66-5.24-2.07-8.86,0-5.02-1.26-7.09-5.24-4.58-13.44,1.1-1.92,1.32-5.02.66-9.6h.01Z"/>
          <path id="Phalangen_overlay_3.3" data-name="Phalangen overlay 3.3" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M424.12,110.84h-.07c-2.66-7.53,0-11.22,9.23-10.26l3.62.74c10.41-2,13.66,1.84,8.27,12.77-11.66,20.38-12.25,36.48-9.89,51.39,7.38,14.33,5.46,18.46-4.58,13.44-3.69-7.38-7.97-9.82-13.14-3.91-6.8,12.7-11.08,2.14-5.91-11,9.31-9.97,12.63-29.02,12.48-53.16h0,0Z"/>
        </g>
        <g id="Phalangen_overlay_4" data-name="Phalangen overlay 4">
          <path id="Phalangen_overlay_4.1" data-name="Phalangen overlay 4.1" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M467.84,81.33v.07h0c3.54-7.46,7.6-7.46,12.18-.74,6.35,5.24,6.13,8.49-.66,9.89-1.18,4.13-1.25,7.38-.66,10.26,8.64.59,6.28,3.69,1.33,7.46-4.43-2.21-9.23-2.29-14.18-1.4-3.17-2.14-3.03-5.39,0-9.6,2.44-3.25,1.99-6.28,0-9.23-4.14-1.11-2.29-3.62,1.99-6.72h0Z"/>
          <path id="Phalangen_overlay_4.2" data-name="Phalangen overlay 4.2" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M466.51,108.71c3.91-2.44,9.01-1.55,14.84,1.03,2.66,2.36,2.66,4.73,0,7.09-.74,3.47-.89,6.35-.37,8.49,2.44,4.72,1.33,7.16-1.33,8.49-6.5-1.99-10.7-2.95-13.51-3.17-5.39-1.03-4.87-4.5-2.29-8.86l1.33-4.58c-1.84-3.99-2.36-7.31,1.33-8.49h0Z"/>
          <path id="Phalangen_overlay_4.3" data-name="Phalangen overlay 4.3" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M462.6,145.34v-.07h-.07c-2.44-8.72-2.07-14.26,5.24-12.04,3.32,1.03,6.5.29,7.9,1.77,7.24,1.33,9.75,6.43,5.91,16.32-12.55,13.66-9.52,29.09-7.9,44.3,3.39,16.17,1.18,18.68-4.58,12.77-3.62-4.8-7.9-5.03-12.48-2.81-7.16,5.54-7.23.74-5.24-7.09,6.2-16.54,10.26-34.11,11.22-53.16h0Z"/>
        </g>
        <g id="Phalangen_overlay_5" data-name="Phalangen overlay 5">
          <path id="Phalangen_overlay_5.1" data-name="Phalangen overlay 5.1" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M497.46,142.02h0c-2.66-3.17-1.85-7.09.66-11.37,4.94-6.28,8.78-6.79,10.85,1.77,4.73,6.05,4.95,10.26,0,12.4,3.62,10.92,3.25,15.35.37,14.91l-12.85-1.03c-5.24-2.81-4.35-6.06-.66-9.6,3.61-2.29,3.24-4.65,1.62-7.09h.01Z"/>
          <path id="Phalangen_overlay_5.2" data-name="Phalangen overlay 5.2" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M494.26,162.24h-.07c4.36-3.32,9.75-3.84,16.47-1.03-1.03,6.27-1.33,10.56-.96,13.14,6.35,3.98,5.1,8.19,2.66,12.4-6.35,1.25-13.44.66-20.75-.37-3.1-3.18-4.8-6.35,0-9.23,2-4.43,3.03-9.3,2.66-14.91h0Z"/>
          <path id="Phalangen_overlay_5.3" data-name="Phalangen overlay 5.3" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M490.22,195.91h0c-.52-7.61,1.11-10.41,5.24-7.09l9.52,1.03c5.24-3.02,7.46-1.03,5.61,7.83-7.6,13.3-10.56,26.29-7.9,38.99,1.1,9.89-1.04,12.48-4.95,11-1.18-4.88-5.24-6.13-11.52-4.58-6.87,3.02-8.49.88-3.62-7.83,5.17-9.74,7.24-23.48,7.61-39.35h0Z"/>
        </g>
      </g>
      <g id="joint_overlay" data-name="joint overlay">
        <ellipse id="joint_overlay_1" data-name="joint overlay 1" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="320.13" cy="73.78" rx="24.6" ry="9.21"/>
        <ellipse id="joint_overlay_2" data-name="joint overlay 2" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="315.57" cy="173" rx="29.16" ry="10.18" transform="translate(-12.42 24.89) rotate(-4.43)"/>
        <ellipse id="joint_overlay_3" data-name="joint overlay 3" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="382.09" cy="163.09" rx="23.39" ry="9.08"/>
        <ellipse id="joint_overlay_4" data-name="joint overlay 4" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="387.8" cy="87.97" rx="7.72" ry="20.16" transform="translate(269.74 467.69) rotate(-85.51)"/>
        <ellipse id="joint_overlay_5" data-name="joint overlay 5" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="395.03" cy="42.26" rx="6.31" ry="18.38" transform="translate(321.98 432.77) rotate(-85.51)"/>
        <ellipse id="joint_overlay_6" data-name="joint overlay 6" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="438.34" cy="70.39" rx="6.53" ry="15.5" transform="translate(313.05 496.31) rotate(-82.73)"/>
        <ellipse id="joint_overlay_7" data-name="joint overlay 7" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="435.34" cy="101.9" rx="6.52" ry="15.17" transform="translate(299.66 527.93) rotate(-85.51)"/>
        <ellipse id="joint_overlay_8" data-name="joint overlay 8" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="425.79" cy="179.52" rx="8.04" ry="18.99" transform="translate(213.49 589.95) rotate(-85.51)"/>
        <ellipse id="joint_overlay_9" data-name="joint overlay 9" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="473.23" cy="107" rx="13.37" ry="5.09"/>
        <ellipse id="joint_overlay_10" data-name="joint overlay 10" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="471.9" cy="132.75" rx="5.15" ry="15.01" transform="translate(302.62 592.81) rotate(-85.51)"/>
        <ellipse id="joint_overlay_11" data-name="joint overlay 11" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="461.97" cy="207.82" rx="6.42" ry="14.57" transform="translate(218.62 652.09) rotate(-85.51)"/>
        <ellipse id="joint_overlay_12" data-name="joint overlay 12" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="502.93" cy="157.49" rx="12.08" ry="5.6"/>
        <ellipse id="joint_overlay_13" data-name="joint overlay 13" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="501.48" cy="187.46" rx="15.11" ry="6.69"/>
        <ellipse id="joint_overlay_14" data-name="joint overlay 14" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="491.62" cy="246.38" rx="6.38" ry="15.65" transform="translate(207.51 717.2) rotate(-85.51)"/>
      </g>
    </g>
  </g>
  <g id="foot_overview_left" data-name="foot overview left">
    <g id="foot_left" data-name="foot left">
      <g id="toes_left" data-name="toes left">
        <g id="big_toe_left" data-name="big toe left">
          <path id="big_toe_1left" data-name="big toe 1left" class="beide_vorfusse1472_dp_cls2" d="M216.13,58.48h0c2.88,7.46,2.07,11.44-1.99,12.4l-22.37,1.03-18.09-2.14c-3.32-3.47-4.43-8.27-2.29-14.91,7.16-14.18,1.48-21.26-7.9-26.21-2.73-4.5-1.99-8.2,3.32-11-1.85-8.34,3.17-8.34,8.56-7.83,6.94-3.17,12.63.37,17.79,6.35,4.65,3.1,4.58,5.83,2.29,8.49-1.33,13.95,5.32,21.86,11.89,29.76,2.44-4.87,5.32-4.28,8.86,3.91v.15s-.07,0-.07,0Z"/>
          <path id="big_toe_2left" data-name="big toe 2left" class="beide_vorfusse1472_dp_cls2" d="M224.85,153.58h0c-6.87-21.04-9.89-43.05-3.32-67.34-.52-12.18-4.73-18.75-16.47-14.18l-18.75-1.4c-12.33-1.11-15.95,4.43-8.56,18.46,11.37,22.15,6.42,40.76.37,59.22-5.02,17.72-3.03,25.47,8.86,19.49l25.33,5.69c13.81,1.99,18.46-4.21,12.48-19.86h.07s0-.07,0-.07h0Z"/>
        </g>
        <g id="second_toe_left" data-name="second toe left">
          <path id="second_toe_1left" data-name="second toe 1left" class="beide_vorfusse1472_dp_cls1" d="M125.39,6.57c5.69,4.87,7.16,9.16,3.32,12.77-3.1,4.21-1.92,7.38,2.29,9.89,5.54,2.58,3.25,5.54-.66,8.49h-19.79c-4.28-2.81-4.95-5.98-1.33-9.6,2.88-3.17,2.95-6.5.96-9.89-7.97-.81-9.89-3.54.66-10.26,2.88-9.75,7.83-9.67,14.47-1.4h.07s.01,0,.01,0Z"/>
          <path id="second_toe_2left" data-name="second toe 2left" class="beide_vorfusse1472_dp_cls1" d="M134.91,50.8c-1.99,9.3.15,15.28,4.58,19.49,4.8,11.22,1.11,13-5.61,11.37l-13.81,2.51c-5.69.15-7.83-2.29-2.95-9.6,1.55-5.17.81-12.11-1.99-20.53-8.86-10.78-9.52-15.95,1.99-12.77,4.73-.44,7.31-.74,10.56.74,7.24-5.54,7.68.74,7.24,8.86h0v-.07s-.01,0-.01,0Z"/>
          <path id="second_toe_3left" data-name="second toe 3left" class="beide_vorfusse1472_dp_cls1" d="M141.49,97.91h0c-3.17,23.85-.22,39.72,5.61,48.58,9.23,11.89,8.27,16.02-4.95,10.63-6.2-6.65-12.63-6.13-19.42,3.54-7.38,5.69-9.38,3.1-4.28-10.26,5.24-12.85,6.72-28.28-2.66-51.02-4.95-10.48-.3-14.84,9.23-15.95,2.22,2.36,4.36,2.36,6.57,0,8.71-1.18,11.15,4.65,9.89,14.55h0v-.07h0Z"/>
        </g>
        <g id="third_toe_left" data-name="third toe left">
          <path id="third_toe_1_left" data-name="third toe 1 left" class="beide_vorfusse1472_dp_cls1" d="M82.12,46.24v-.02h0c.15,4.28.89,7.16,2.29,8.86,5.02,3.25,5.09,7.16-.96,12.04-4.8-.59-9.52-.74-14.18.74-6.5.15-7.31-3.32-.66-11.37,1.77-1.92,1.77-4.95.37-8.86-5.17-3.47-3.84-6.79.96-9.89,3.77-5.61,7.83-4.58,11.89-1.03,6.87,2.29,5.46,5.69.37,9.6h-.07v-.07s0,0,0,0Z"/>
          <path id="third_toe_3_left" data-name="third toe 3 left" class="beide_vorfusse1472_dp_cls1" d="M90.47,75.68h0c-.66,4.58-.44,7.68.66,9.6,2.51,8.2.44,12.18-4.58,13.44-3.62-2.07-6.79-2.66-8.86,0-7.24.52-8.93-1.7-3.99-7.09.44-2.51.22-5.69-3.99-12.4-2.07-8.42-2.81-15.21,5.91-9.89.59-3.1,4.13-2.73,7.9-2.14,7.09-1.99,7.68,2.58,6.94,8.49h.01Z"/>
          <path id="third_toe_2_left" data-name="third toe 2 left" class="beide_vorfusse1472_dp_cls1" d="M92.32,108.32h0c-.15,24.14,3.17,43.19,12.48,53.16,5.17,13.14.89,23.7-5.91,11-5.17-5.91-9.45-3.47-13.14,3.91-10.04,5.02-11.96.89-4.58-13.44,2.36-14.91,1.77-31.01-9.89-51.39-5.39-10.93-2.14-14.77,8.27-12.77l3.62-.74c9.23-.96,11.89,2.73,9.23,10.26h-.07s0,0,0,0Z"/>
        </g>
        <g id="fourth_toe_left" data-name="fourth toe left">
          <path id="fourth_toe_1_left" data-name="fourth toe 1 left" class="beide_vorfusse1472_dp_cls1" d="M48.6,78.79h0c4.28,3.1,6.13,5.61,1.99,6.72-1.99,2.95-2.44,5.98,0,9.23,3.03,4.21,3.17,7.46,0,9.6-4.95-.89-9.75-.81-14.18,1.4-4.95-3.77-7.31-6.87,1.33-7.46.59-2.88.52-6.13-.66-10.26-6.79-1.4-7.01-4.65-.66-9.89,4.58-6.72,8.64-6.72,12.18.74h0v-.07h0Z"/>
          <path id="fourth_toe_3_left" data-name="fourth toe 3 left" class="beide_vorfusse1472_dp_cls1" d="M49.93,106.18h0c3.69,1.18,3.17,4.5,1.33,8.49l1.33,4.58c2.58,4.36,3.1,7.83-2.29,8.86-2.81.22-7.01,1.18-13.51,3.17-2.66-1.33-3.77-3.77-1.33-8.49.52-2.14.37-5.02-.37-8.49-2.66-2.36-2.66-4.73,0-7.09,5.83-2.58,10.93-3.47,14.84-1.03h0Z"/>
          <path id="fourth_toe_2_left" data-name="fourth toe 2 left" class="beide_vorfusse1472_dp_cls1" d="M53.84,142.8h0c.96,19.05,5.02,36.62,11.22,53.16,1.99,7.83,1.92,12.63-5.24,7.09-4.58-2.22-8.86-1.99-12.48,2.81-5.76,5.91-7.97,3.4-4.58-12.77,1.62-15.21,4.65-30.64-7.9-44.3-3.84-9.89-1.33-14.99,5.91-16.32,1.4-1.48,4.58-.74,7.9-1.77,7.31-2.22,7.68,3.32,5.24,12.04h-.07s0,.07,0,.07h0Z"/>
        </g>
        <g id="little_toe_left" data-name="little toe left">
          <path id="little_toe_1left" data-name="little toe 1left" class="beide_vorfusse1472_dp_cls1" d="M18.98,139.48h0c-1.62,2.44-1.99,4.8,1.62,7.09,3.69,3.54,4.58,6.79-.66,9.6l-12.85,1.03c-2.88.44-3.25-3.99.37-14.91-4.95-2.14-4.73-6.35,0-12.4,2.07-8.56,5.91-8.05,10.85-1.77,2.51,4.28,3.32,8.2.66,11.37h0Z"/>
          <path id="little_toe_3left" data-name="little toe 3left" class="beide_vorfusse1472_dp_cls1" d="M22.17,159.71c-.37,5.61.66,10.48,2.66,14.91,4.8,2.88,3.1,6.05,0,9.23-7.31,1.03-14.4,1.62-20.75.37-2.44-4.21-3.69-8.42,2.66-12.4.37-2.58.07-6.87-.96-13.14,6.72-2.81,12.11-2.29,16.47,1.03h-.07s0,0,0,0Z"/>
          <path id="little_toe_2left" data-name="little toe 2left" class="beide_vorfusse1472_dp_cls1" d="M26.23,193.38h0c.37,15.87,2.44,29.61,7.61,39.35,4.87,8.71,3.25,10.85-3.62,7.83-6.28-1.55-10.34-.3-11.52,4.58-3.91,1.48-6.05-1.11-4.95-11,2.66-12.7-.3-25.69-7.9-38.99-1.85-8.86.37-10.85,5.61-7.83l9.52-1.03c4.13-3.32,5.76-.52,5.24,7.09h0Z"/>
        </g>
      </g>
      <g id="metatarsus_left" data-name="metatarsus left">
        <path id="metatarsus_1left" data-name="metatarsus 1left" class="beide_vorfusse1472_dp_cls1" d="M228.83,209.7h0c-13.07,38.69-20.3,78.27-19.42,119.1,1.62,16.17-2.07,24.37-16.76,15.58-10.56-6.87-21.34-13.22-38.54-7.46-13.14-15.21-12.04-23.92-.96-27.98,22.08-25.69,27.32-59.22,14.47-101.01-14.99-12.63-14.4-20.97.96-25.18,2.07-6.2,5.02-9.75,8.86-9.89,19.27-7.61,32.64,2.29,42.46,3.54,14.1-1.18,13.36,13.95,8.86,33.3h.07Z"/>
        <path id="metatarsus_2left" data-name="metatarsus 2left" class="beide_vorfusse1472_dp_cls1" d="M147.76,165.17h0c.44,5.46,1.18,11.08,2.95,16.98,2.14,7.46,2.58,15.51-9.52,27.61-.52,44.97-1.18,91.41,2.95,112.01,3.03,8.64,7.9,14.4,9.52,17.72-4.21,6.5-6.57,14.03-4.95,23.78-13.66-.3-25.33,2.51-29.31,16.98-8.2-4.8-13-12.7-10.19-27.32-2.81-9.97,0-16.32,5.61-20.9,5.32-44.97,11.3-90.3,3.62-128.7-6.5-10.41-8.42-19.64-1.99-26.58-1.7-5.02-.96-9.3,2.66-12.77,9.45-9.89,19.05-9.38,28.65,1.03v.15s0,.01,0,.01Z"/>
        <path id="metatarsus_3left" data-name="metatarsus 3left" class="beide_vorfusse1472_dp_cls1" d="M102.28,179.57h0c6.2,16.39,7.38,27.47.96,30.12-1.03,49.47-2.66,102.93.96,123.01,3.17,12.92,4.65,24.44,1.99,32.64-9.97-3.47-19.05-4.5-22.08,13.14-9.01-5.09-13.07-15.95-4.95-41.13,4.5-37.51,8.42-73.61,6.94-97.09.74-13.66-1.92-25.33-8.56-34.78-3.03-17.57,2.51-24.51,9.89-29.09,4.65-10.41,9.67-5.98,14.84,3.17h0Z"/>
        <path id="metatarsus_4left" data-name="metatarsus 4left" class="beide_vorfusse1472_dp_cls1" d="M68.76,226.31h0c5.76,34.26,8.34,68.52,5.91,102.78-1.26,22.22,1.33,42.16,8.86,59.22-13.29,2.22-22.82,8.56-26.65,21.26-3.1-3.1-5.46-8.34-6.57-16.98-4.06-9.6-2.22-18.02.96-26.21l5.61-54.93c1.11-27.17,1.77-53.75-6.28-65.94-5.69-4.8-9.08-10.85-7.61-19.86l1.99-15.58c4.36-7.97,9.82-9.97,16.47-4.95,7.24,7.16,10.56,14.25,7.24,21.26h0l.07-.07h0Z"/>
        <path id="metatarsus_5left" data-name="metatarsus 5left" class="beide_vorfusse1472_dp_cls1" d="M36.71,256.43h0c5.69,6.2,7.09,11.67,3.62,16.32l8.27,55.3.66,47.48c-2.66,11.37,1.26,23.48,6.94,35.81-5.83,13.22-10.04,25.47-8.86,34.78-1.7,10.85-5.32,10.41-10.19,2.14-14.25-6.72-20.38-20.9-12.85-47.85l4.95-66.97c1.33-19.94-.74-38.39-8.86-54.27-11.67-8.79-8.64-14.69-3.32-20.23-3.47-8.2-3.84-13.29,2.29-12.04,6.79-9.67,17.43-7.46,17.43,9.6h-.07v-.07s0,0,0,0Z"/>
        <path id="metatarsus_6left" data-name="metatarsus 6left" class="beide_vorfusse1472_dp_cls1" d="M177.3,172.85c.44,2.73,1.77,4.43,3.99,4.95,14.69,7.75,28.87,9.67,42.16,3.54,4.13-1.33,4.13-2.51,0-3.54"/>
        <path id="metatarsus_7left" data-name="metatarsus 7left" class="beide_vorfusse1472_dp_cls1" d="M117.05,166.43l8.56,4.58c7.53,3.84,14.1.07,20.45-4.95"/>
        <path id="metatarsus_8left" data-name="metatarsus 8left" class="beide_vorfusse1472_dp_cls1" d="M79.61,184.15c0,3.4,1.99,5.32,5.61,6.05,1.55,3.84,4.8,5.24,10.19,3.17,1.7-5.69,4.28-7.97,7.9-6.72"/>
        <path id="metatarsus_9left" data-name="metatarsus 9left" class="beide_vorfusse1472_dp_cls1" d="M44.69,218.93c3.84,2.88,7.97,3.54,12.85.37,1.7-5.54,4.8-7.68,8.86-7.46"/>
        <path id="metatarsus_10left" data-name="metatarsus 10left" class="beide_vorfusse1472_dp_cls1" d="M20.62,249.05c-.37,9.89,5.32,11.37,13.51,9.23"/>
      </g>
      <g id="tarsus_left" data-name="tarsus left">
        <path id="tarsus_5left" data-name="tarsus 5left" class="beide_vorfusse1472_dp_cls1" d="M187.85,420.64h0l-17.43-7.83c2.29-17.65-5.83-34.41-19.12-50.65-4.21-33.52,16.54-28.57,41.2-17.72,8.71,10.85,14.1,26.14,13.51,49.25,2.07,22.96-1.55,36.18-18.09,26.95h-.07Z"/>
        <path id="tarsus_4left" data-name="tarsus 4left" class="beide_vorfusse1472_dp_cls1" d="M131.59,421.6h0c-7.46-14.18-11.67-28.57-9.52-43.27,3.84-12.55,14.47-16.54,30.64-13.44,14.77,20.9,18.31,36.77,12.48,48.58-13.36-3.1-25.03-1.55-33.6,8.12h0Z"/>
        <path id="tarsus_3left" data-name="tarsus 3left" class="beide_vorfusse1472_dp_cls1" d="M127.68,422.05h0c-7.75,3.4-15.21,7.38-16.76,21.63-5.98-.59-11.3-2.95-15.8-7.83-10.41-8.71-12.48-30.27-10.85-57.44,5.69-16.83,15.58-18.61,30.94-1.03,1.26,15.65,5.69,30.49,12.48,44.67h0Z"/>
        <path id="tarsus_2left" data-name="tarsus 2left" class="beide_vorfusse1472_dp_cls1" d="M69.94,479.42h0c-7.01-8.34-13.36-17.72-18.75-28.72-2.07-10.85-2.07-21.34,5.61-30.49,4.95-21.86,14.62-26.8,26.65-23.41-2.81,24.29,3.91,42.53,27.98,49.62.37,7.46,4.21,15.36,11.89,23.78-8.86,12.7-19.42,23.48-53.31,9.23h-.07,0Z"/>
        <path id="tarsus_1left" data-name="tarsus 1left" class="beide_vorfusse1472_dp_cls1" d="M203.43,485.84c-24.22-30.57-49.62-42.01-77.38-16.32-14.1-21.12-19.12-39.13,7.24-46.07,13.66-14.47,33.89-12.85,58.26-.74,11.15,1.55,16.17,14.18,18.09,32.27,5.76,17.28,2.81,26.8-6.28,30.86h.07,0Z"/>
      </g>
    </g>
    <g id="overlays_left" data-name="overlays left">
      <g id="Phalangen_overlay_left" data-name="Phalangen overlay left" class="beide_vorfusse1472_dp_cls3">
        <g id="Phalangen_overlay_1left" data-name="Phalangen overlay 1left">
          <path id="Phalangen_overlay_1.1left" data-name="Phalangen overlay 1.1left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M216.28,58.85h0c2.88,7.46,2.07,11.44-1.99,12.4l-22.37,1.03-18.09-2.14c-3.32-3.47-4.43-8.27-2.29-14.91,7.16-14.18,1.48-21.26-7.9-26.21-2.73-4.5-1.99-8.2,3.32-11-1.85-8.34,3.17-8.34,8.56-7.83,6.94-3.17,12.63.37,17.79,6.35,4.65,3.1,4.58,5.83,2.29,8.49-1.33,13.95,5.32,21.86,11.89,29.76,2.44-4.87,5.32-4.28,8.86,3.91v.15s-.07,0-.07,0Z"/>
          <path id="Phalangen_overlay_1.2left" data-name="Phalangen overlay 1.2left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M225,153.95h0c-6.87-21.04-9.89-43.05-3.32-67.34-.52-12.18-4.73-18.75-16.47-14.18l-18.75-1.4c-12.33-1.11-15.95,4.43-8.56,18.46,11.37,22.15,6.42,40.76.37,59.22-5.02,17.72-3.03,25.47,8.86,19.49l25.33,5.69c13.81,1.99,18.46-4.21,12.48-19.86h.07s0-.07,0-.07h0Z"/>
        </g>
        <g id="Phalangen_overlay_2left" data-name="Phalangen overlay 2left">
          <path id="Phalangen_overlay_2.1left" data-name="Phalangen overlay 2.1left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M125.54,6.94c5.69,4.87,7.16,9.16,3.32,12.77-3.1,4.21-1.92,7.38,2.29,9.89,5.54,2.58,3.25,5.54-.66,8.49h-19.79c-4.28-2.81-4.95-5.98-1.33-9.6,2.88-3.17,2.95-6.5.96-9.89-7.97-.81-9.89-3.54.66-10.26,2.88-9.75,7.83-9.67,14.47-1.4h.07s.01,0,.01,0Z"/>
          <path id="Phalangen_overlay_2.2left" data-name="Phalangen overlay 2.2left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M135.06,51.17c-1.99,9.3.15,15.28,4.58,19.49,4.8,11.22,1.11,13-5.61,11.37l-13.81,2.51c-5.69.15-7.83-2.29-2.95-9.6,1.55-5.17.81-12.11-1.99-20.53-8.86-10.78-9.52-15.95,1.99-12.77,4.73-.44,7.31-.74,10.56.74,7.24-5.54,7.68.74,7.24,8.86h0v-.07s-.01,0-.01,0Z"/>
          <path id="Phalangen_overlay_2.3left" data-name="Phalangen overlay 2.3left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M141.64,98.28h0c-3.17,23.85-.22,39.72,5.61,48.58,9.23,11.89,8.27,16.02-4.95,10.63-6.2-6.65-12.63-6.13-19.42,3.54-7.38,5.69-9.38,3.1-4.28-10.26,5.24-12.85,6.72-28.28-2.66-51.02-4.95-10.48-.3-14.84,9.23-15.95,2.22,2.36,4.36,2.36,6.57,0,8.71-1.18,11.15,4.65,9.89,14.55h0v-.07h.01Z"/>
        </g>
        <g id="Phalangen_overlay_3left" data-name="Phalangen overlay 3left">
          <path id="Phalangen_overlay_3.1left" data-name="Phalangen overlay 3.1left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M82.27,46.61v-.02h0c.15,4.28.89,7.16,2.29,8.86,5.02,3.25,5.09,7.16-.96,12.04-4.8-.59-9.52-.74-14.18.74-6.5.15-7.31-3.32-.66-11.37,1.77-1.92,1.77-4.95.37-8.86-5.17-3.47-3.84-6.79.96-9.89,3.77-5.61,7.83-4.58,11.89-1.03,6.87,2.29,5.46,5.69.37,9.6h-.07v-.07s0,0,0,0Z"/>
          <path id="Phalangen_overlay_3.2left" data-name="Phalangen overlay 3.2left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M90.62,76.05h0c-.66,4.58-.44,7.68.66,9.6,2.51,8.2.44,12.18-4.58,13.44-3.62-2.07-6.79-2.66-8.86,0-7.24.52-8.93-1.7-3.99-7.09.44-2.51.22-5.69-3.99-12.4-2.07-8.42-2.81-15.21,5.91-9.89.59-3.1,4.13-2.73,7.9-2.14,7.09-1.99,7.68,2.58,6.94,8.49h.01Z"/>
          <path id="Phalangen_overlay_3.3left" data-name="Phalangen overlay 3.3left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M92.47,108.69h0c-.15,24.14,3.17,43.19,12.48,53.16,5.17,13.14.89,23.7-5.91,11-5.17-5.91-9.45-3.47-13.14,3.91-10.04,5.02-11.96.89-4.58-13.44,2.36-14.91,1.77-31.01-9.89-51.39-5.39-10.93-2.14-14.77,8.27-12.77l3.62-.74c9.23-.96,11.89,2.73,9.23,10.26h-.07s0,0,0,0Z"/>
        </g>
        <g id="Phalangen_overlay_4left" data-name="Phalangen overlay 4left">
          <path id="Phalangen_overlay_4.1left" data-name="Phalangen overlay 4.1left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M48.75,79.16h0c4.28,3.1,6.13,5.61,1.99,6.72-1.99,2.95-2.44,5.98,0,9.23,3.03,4.21,3.17,7.46,0,9.6-4.95-.89-9.75-.81-14.18,1.4-4.95-3.77-7.31-6.87,1.33-7.46.59-2.88.52-6.13-.66-10.26-6.79-1.4-7.01-4.65-.66-9.89,4.58-6.72,8.64-6.72,12.18.74h0v-.07h0Z"/>
          <path id="Phalangen_overlay_4.2left" data-name="Phalangen overlay 4.2left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M50.08,106.55h0c3.69,1.18,3.17,4.5,1.33,8.49l1.33,4.58c2.58,4.36,3.1,7.83-2.29,8.86-2.81.22-7.01,1.18-13.51,3.17-2.66-1.33-3.77-3.77-1.33-8.49.52-2.14.37-5.02-.37-8.49-2.66-2.36-2.66-4.73,0-7.09,5.83-2.58,10.93-3.47,14.84-1.03h0Z"/>
          <path id="Phalangen_overlay_4.3left" data-name="Phalangen overlay 4.3left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M53.99,143.17h0c.96,19.05,5.02,36.62,11.22,53.16,1.99,7.83,1.92,12.63-5.24,7.09-4.58-2.22-8.86-1.99-12.48,2.81-5.76,5.91-7.97,3.4-4.58-12.77,1.62-15.21,4.65-30.64-7.9-44.3-3.84-9.89-1.33-14.99,5.91-16.32,1.4-1.48,4.58-.74,7.9-1.77,7.31-2.22,7.68,3.32,5.24,12.04h-.07s0,.07,0,.07h0Z"/>
        </g>
        <g id="Phalangen_overlay_5left" data-name="Phalangen overlay 5left">
          <path id="Phalangen_overlay_5.1left" data-name="Phalangen overlay 5.1left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M19.13,139.85h0c-1.62,2.44-1.99,4.8,1.62,7.09,3.69,3.54,4.58,6.79-.66,9.6l-12.85,1.03c-2.88.44-3.25-3.99.37-14.91-4.95-2.14-4.73-6.35,0-12.4,2.07-8.56,5.91-8.05,10.85-1.77,2.51,4.28,3.32,8.2.66,11.37h0Z"/>
          <path id="Phalangen_overlay_5.2left" data-name="Phalangen overlay 5.2left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M22.32,160.08c-.37,5.61.66,10.48,2.66,14.91,4.8,2.88,3.1,6.05,0,9.23-7.31,1.03-14.4,1.62-20.75.37-2.44-4.21-3.69-8.42,2.66-12.4.37-2.58.07-6.87-.96-13.14,6.72-2.81,12.11-2.29,16.47,1.03h-.08Z"/>
          <path id="Phalangen_overlay_5.3left" data-name="Phalangen overlay 5.3left" class="Phalangen1472Fill" style= {{
              fill: props.colors.Phalangen1472Fill,
              stroke: props.colors.Phalangen1472Fill,
              opacity:props.colors.Phalangen1472Fill,
            }} d="M26.38,193.75h0c.37,15.87,2.44,29.61,7.61,39.35,4.87,8.71,3.25,10.85-3.62,7.83-6.28-1.55-10.34-.3-11.52,4.58-3.91,1.48-6.05-1.11-4.95-11,2.66-12.7-.3-25.69-7.9-38.99-1.85-8.86.37-10.85,5.61-7.83l9.52-1.03c4.13-3.32,5.76-.52,5.24,7.09h0Z"/>
        </g>
      </g>
      <g id="joint_overlay_left" data-name="joint overlay left">
        <ellipse id="joint_overlay_1left" data-name="joint overlay 1left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="196.46" cy="71.62" rx="24.6" ry="9.21"/>
        <ellipse id="joint_overlay_2left" data-name="joint overlay 2left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="201.02" cy="170.83" rx="10.18" ry="29.16" transform="translate(15.17 358.05) rotate(-85.57)"/>
        <ellipse id="joint_overlay_3left" data-name="joint overlay 3left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="134.5" cy="160.93" rx="23.39" ry="9.08"/>
        <ellipse id="joint_overlay_4left" data-name="joint overlay 4left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="128.79" cy="85.8" rx="20.16" ry="7.72" transform="translate(-6.32 10.35) rotate(-4.49)"/>
        <ellipse id="joint_overlay_5left" data-name="joint overlay 5left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="121.55" cy="40.1" rx="18.38" ry="6.31" transform="translate(-2.77 9.64) rotate(-4.49)"/>
        <ellipse id="joint_overlay_6left" data-name="joint overlay 6left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="78.24" cy="68.23" rx="15.5" ry="6.53" transform="translate(-8.01 10.45) rotate(-7.27)"/>
        <ellipse id="joint_overlay_7left" data-name="joint overlay 7left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="81.25" cy="99.74" rx="15.17" ry="6.52" transform="translate(-7.56 6.67) rotate(-4.49)"/>
        <ellipse id="joint_overlay_8left" data-name="joint overlay 8left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="90.8" cy="177.36" rx="18.99" ry="8.04" transform="translate(-13.61 7.65) rotate(-4.49)"/>
        <ellipse id="joint_overlay_9left" data-name="joint overlay 9left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="43.36" cy="104.84" rx="13.37" ry="5.09"/>
        <ellipse id="joint_overlay_10left" data-name="joint overlay 10left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="44.69" cy="130.58" rx="15.01" ry="5.15" transform="translate(-10.09 3.9) rotate(-4.49)"/>
        <ellipse id="joint_overlay_11left" data-name="joint overlay 11left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="54.62" cy="205.65" rx="14.57" ry="6.42" transform="translate(-15.93 4.91) rotate(-4.49)"/>
        <ellipse id="joint_overlay_12left" data-name="joint overlay 12left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="13.66" cy="155.33" rx="12.08" ry="5.6"/>
        <ellipse id="joint_overlay_13left" data-name="joint overlay 13left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="15.11" cy="185.3" rx="15.11" ry="6.69"/>
        <ellipse id="joint_overlay_14left" data-name="joint overlay 14left" class="Gelenke1472Fill" style= {{
              fill: props.colors.Gelenke1472Fill,
              stroke: props.colors.Gelenke1472Fill,
              opacity:props.colors.Gelenke1472Fill,
            }} cx="24.97" cy="244.21" rx="15.65" ry="6.38" transform="translate(-19.04 2.7) rotate(-4.49)"/>
      </g>
    </g>
  </g>
</svg>
  );
}

export default BeideVorfusseDplLiegend1472;
