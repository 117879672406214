import exhale from "./Images/exhale.svg";
import inhale from "./Images/inhale.svg";
import exposition from "./Images/exposition.png";
import breathnormally from "./Images/breathnormally.jpeg";
import holdstill from "./Images/holdstill.jpeg";

const EXHALE = {
  image: exhale,
  cmdDE:
    "Bitte atmen Sie jetzt einmal tief aus und halten dann kurz die Luft an!",
  cmdPL: "Proszę teraz wykonać głęboki wydech i na chwilę zatrzymać oddech!",
  cmdEN: "Please exhale deeply and hold your breath for a moment!",
};

const INHALE = {
  image: inhale,
  cmdDE:
    "Bitte atmen Sie jetzt einmal tief ein und halten dann kurz die Luft an!",
  cmdPL: "Proszę teraz wykonać głęboki wdech i na chwilę zatrzymać oddech!",
  cmdEN: "Please inhale deeply and hold your breath for a moment!",
};

const HOLD_STILL = {
  image: holdstill,
  cmdDE: "Bitte bewegen Sie sich keinen Moment",
  cmdPL: "Proszę nie ruszać się przez chwilę",
  cmdEN: "Please hold still for a moment",
};

const BREATHING_ALLOWED = {
  image: breathnormally,
  cmdDE: "Sie können wieder normal atmen",
  cmdPL: "Możesz znów oddychać normalnie",
  cmdEN: "You can breathe normally again",
};

const RELAX = {
  image: breathnormally,
  cmdDE: "Jetz bitte wieder entspannt stehen/liegen",
  cmdPL: "Możesz już się poruszać",
  cmdEN: "You can move again",
};

const EXPOSITION = {
  image: exposition,
  cmdDE: "Belichtung",
  cmdPL: "Ekspozycja",
  cmdEN: "Exposition",
};

const PAT_CMDS = {
  0: [HOLD_STILL, EXPOSITION, RELAX],
  1: [INHALE, EXPOSITION, BREATHING_ALLOWED],
  2: [EXHALE, EXPOSITION, BREATHING_ALLOWED],
};

function useCommandSteps(patCmd) {
  if (
    patCmd === null ||
    patCmd === undefined ||
    !Object.keys(PAT_CMDS).includes(patCmd.toString())
  )
    return [];
  return PAT_CMDS[patCmd];
}

export default useCommandSteps;
