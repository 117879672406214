import style from "./style.scss";

function RechterVorfussDplOblLiegend1430(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216.99 493.87">
      <g id="fore_foot" data-name="fore foot">
        <g id="background_layer" data-name="background layer">
          <path
            id="background_1"
            data-name="background 1"
            class="vorfuss_dp_obl_rechts1430_cls_3"
            d="M150.24,389.53h0l-18.5-7.3c-11.9,3.6-10.5-8.4-2.6-28.1-.8-11.6,2-22,8.2-31.1l12.8-101.2c-4.8-7.2-5.4-12.8.8-16.1-.6-15.9,4.6-21.5,16.5-15.4,7.1,1,9,6.6,6.8,15.8,2.2,5.3,1.8,10.5-2.2,15.4-11.5,17.1-16.3,54.8-9.8,126.8-3.4,5.7-4.4,11.9-.4,19.1,4.7,17.4,2.8,27.1-11.6,22.1h0Z"
          />
          <path
            id="background_2"
            data-name="background 2"
            class="vorfuss_dp_obl_rechts1430_cls_3"
            d="M121.04,372.32c-18.4-2-25.3-9.8-27.2-20.2-1.5-9.5,1.7-17.6,7.9-24.8,7.4-34.9,11.5-80,14.6-127.9-4.3-7.5-2.5-14.8,3.4-22.1-4.5-6.5-1-9.5,9-10.1,13.7-1.9,14.7,6.2,10.5,18.4,4.8,6.2,2.7,12.2-2.2,18-.7,4.1-2.4,8.5-5.2,13.1-8.8,43.4-9.3,85.1.4,124.5,5.9,20.9,4.9,34.4-11.2,31.1,0,0-.1,0,0,0Z"
          />
          <path
            id="background_3"
            data-name="background 3"
            class="vorfuss_dp_obl_rechts1430_cls_3"
            d="M26.64,485.93c-5.7,8.9-11.7,11.5-18.6-1.7-6.8-12.5-5.7-30.7,1.7-53.4,3.8-17,17.2-18.6,34.3-14.6,24,1,35.3,10.5,42.8,22.5,9.7,5.6,14.8,11.9,12.9,19.1-6.2,13.4-13.4,14.8-21.4,6.8-27.8-12.8-46.1-7.7-51.7,21.3Z"
          />
          <path
            id="background_4"
            data-name="background 4"
            class="vorfuss_dp_obl_rechts1430_cls_3"
            d="M10.24,375.73c-3.1-6.7-3.1-14.5.6-23.6,7.3-20.2,19.6-21.9,34.9-12.9.6,9.5,4.1,16.2,11.2,19.7,9.1,5.5,9.5,21.1,3.4,44.4-2.4,18-10.9,29.2-33.2,25.3-19.8.9-23.1-19.8-16.9-52.9Z"
          />
          <path
            id="background_5"
            data-name="background 5"
            class="vorfuss_dp_obl_rechts1430_cls_3"
            d="M42.34,353.63h0l-22.5-2.1c-12.4-10.4-17.7-20.3-10.9-29.2,9.5-37.6,9.5-70.5-.4-98.6-7.8-11.5-10.5-23.2-7.1-35.2-1.2-5.3,1.2-10.1,6.8-14.2,9.3-9.1,21.9-11.5,37.5-7.5,4.9,1.8,6.6,5,6,9,6.9,11.5,9,23.9,2.2,37.9-8.2,23.5-6.2,50.8,4.9,81.4,8,14.7,12,30,5.6,46.5,1.1,9.1-7,12.6-22.1,12h0Z"
          />
          <path
            id="background_6"
            data-name="background 6"
            class="vorfuss_dp_obl_rechts1430_cls_3"
            d="M69.74,411.53h0c2.9.5,5.7-1.2,6.5-4l4-14.3c5.5-3.3,6.1-8,4.1-13.5,6.2-7.7,3.5-14.1-10.7-18.8-10.5-5.4-19.2-6.6-23.6,2.1-2.6,9.7-6.1,18.6-10.7,26.4-4.6,8.9-2.5,15.1,8.1,18l22.3,4.1h0Z"
          />
        </g>
        <g id="tarsus">
          <path
            id="tarsus_1"
            data-name="tarsus 1"
            class="vorfuss_dp_obl_rechts1430_cls_5"
            d="M105.24,472.42h0l12.8,6.7c8,6.6,18.1,6.5,29.8,1.1,18.4.5,26.2-6.6,27-18.6-.7-13.6,2.4-21.9,9-25.3,8.1-2.8,4.2-7.2-2.2-11.8-7.3-12.7-21.1-22.9-40.5-30.9-8.9-4-14-3.5-11.2,6.2.1,16.3-8.3,30.9-26.8,43.5-3,2-5.5,4.8-7.2,8-8.9,16.6-8.6,25.8,9.3,21.1h0Z"
          />
          <path
            id="tarsus_2"
            data-name="tarsus 2"
            class="vorfuss_dp_obl_rechts1430_cls_4"
            d="M69.74,411.53h0c2.9.5,5.7-1.2,6.5-4l4-14.3c5.5-3.3,6.1-8,4.1-13.5,6.2-7.7,3.5-14.1-10.7-18.8-10.5-5.4-19.2-6.6-23.6,2.1-2.6,9.7-6.1,18.6-10.7,26.4-4.6,8.9-2.5,15.1,8.1,18l22.3,4.1h0Z"
          />
          <path
            id="tarsus_3"
            data-name="tarsus 3"
            class="vorfuss_dp_obl_rechts1430_cls_5"
            d="M100.74,437.63h0c11.4-4.3,15-11.5,11.3-21.4l13.5-28.1c7.1-11.3,3.8-20.2-10.1-26.4-16.4-3.8-22.8,4.2-21.9,20.8-8,9.7-14.4,19.7-11.8,30.9-2.5.1-5.3,1-6.4,4.9-.4,1.4,0,2.9.9,4,9.1,10.6,17.4,17.1,24.5,15.3h0Z"
          />
          <path
            id="tarsus_4"
            data-name="tarsus 4"
            class="vorfuss_dp_obl_rechts1430_cls_4"
            d="M27.64,485.93c-5.7,8.9-11.7,11.5-18.6-1.7-6.8-12.5-5.7-30.7,1.7-53.4,3.8-17,17.2-18.6,34.3-14.6,24,1,35.3,10.5,42.8,22.5,9.7,5.6,14.8,11.9,12.9,19.1-6.2,13.4-13.4,14.8-21.4,6.8-27.8-12.8-46.1-7.7-51.7,21.3Z"
          />
          <path
            id="tarsus_5"
            data-name="tarsus 5"
            class="vorfuss_dp_obl_rechts1430_cls_4"
            d="M11.24,375.73c-3.1-6.7-3.1-14.5.6-23.6,7.3-20.2,19.6-21.9,34.9-12.9.6,9.5,4.1,16.2,11.2,19.7,9.1,5.5,9.5,21.1,3.4,44.4-2.4,18-10.9,29.2-33.2,25.3-19.8.9-23.1-19.8-16.9-52.9Z"
          />
        </g>
        <g id="metatarsus">
          <path
            id="metatarsus_1"
            data-name="metatarsus 1"
            class="vorfuss_dp_obl_rechts1430_cls_5"
            d="M188.84,421.42h0c-8-10.7-17.6-18.7-29.5-23-2.2-.7-4-2.3-4.8-4.5-3.7-10.1-1.3-18.2,6.4-24.6,12.2-11.6,15.3-27.7,14.2-45.8-1-29.4,2.6-58.4,9.4-87,5.4-4,5.1-8.8,2.2-13.9,3.6-7.3,7.6-9,12-7.1,10.1-2.6,14.9,5.6,17.2,18.8,1.7,7.1-.6,13.1-6.8,18-13.7,54.8-19.4,107.1,2.1,150.6,2.5,5.1,2.7,11.1.3,16.3-7.1,15.3-14.7,15.4-22.7,2.2h0Z"
          />
          <path
            id="metatarsus_2"
            data-name="metatarsus 2"
            class="vorfuss_dp_obl_rechts1430_cls_4"
            d="M150.24,389.92h0l-18.5-7.3c-11.9,3.6-10.5-8.4-2.6-28.1-.8-11.6,2-22,8.2-31.1l12.8-101.2c-4.8-7.2-5.4-12.8.8-16.1-.6-15.9,4.6-21.5,16.5-15.4,7.1,1,9,6.6,6.8,15.8,2.2,5.3,1.8,10.5-2.2,15.4-11.5,17.1-16.3,54.8-9.8,126.8-3.4,5.7-4.4,11.9-.4,19.1,4.7,17.4,2.8,27.1-11.6,22.1h0Z"
          />
          <path
            id="metatarsus_3"
            data-name="metatarsus 3"
            class="vorfuss_dp_obl_rechts1430_cls_4"
            d="M120.94,372.72c-18.4-1.9-25.2-9.8-27.2-20.2-1.5-9.5,1.7-17.6,7.9-24.8,7.4-34.9,11.5-80,14.6-127.9-4.3-7.5-2.5-14.8,3.4-22.1-4.5-6.5-1-9.5,9-10.1,13.7-1.9,14.7,6.2,10.5,18.4,4.8,6.2,2.7,12.2-2.2,18-.7,4.1-2.4,8.5-5.2,13.1-8.8,43.4-9.3,85.1.4,124.5,5.9,20.9,4.9,34.4-11.2,31.1h0Z"
          />
          <path
            id="metatarsus_4"
            data-name="metatarsus 4"
            class="vorfuss_dp_obl_rechts1430_cls_5"
            d="M78.94,356.92h0c-9.6-3.1-18.5-4.4-26.7-3.7-7.2-9.3-2.7-20.9,6.4-33.4,8.9-26.7,14.5-54.2,16.5-82.5,1.9-13.1,3-25.6,3.4-37.5-3.8-9.1-4.3-18.5.4-28.1-4-10.8,3.3-13.2,15.7-12,14.4-4,15.1,4.7,9.8,19.1,6.2,7.3,5.3,13.1-4.1,17.2-13.7,47.3-13.3,100.9-4.9,157.9-.1,11.8-5.9,12.3-16.5,3h0Z"
          />
          <path
            id="metatarsus_5"
            data-name="metatarsus 5"
            class="vorfuss_dp_obl_rechts1430_cls_4"
            d="M43.34,353.63h0l-22.5-2.1c-12.4-10.4-17.7-20.3-10.9-29.2,9.5-37.6,9.5-70.5-.4-98.6-7.8-11.5-10.5-23.2-7.1-35.2-1.2-5.3,1.2-10.1,6.8-14.2,9.3-9.1,21.9-11.5,37.5-7.5,4.9,1.8,6.6,5,6,9,6.9,11.5,9,23.9,2.2,37.9-8.2,23.5-6.2,50.8,4.9,81.4,8,14.7,12,30,5.6,46.5,1.1,9.1-7,12.6-22.1,12h0Z"
          />
          <path
            id="metatarsus_6"
            data-name="metatarsus 6"
            class="vorfuss_dp_obl_rechts1430_cls_5"
            d="M69.24,209.22h0c-6.3,7.6-11.6,5.4-16.2-3.8-3.8-8.3-4.2-15.4,0-21,6.4-5.2,12.5-2.2,18.4,6,4.6,10.4,4.4,17.1-2.2,18.8h0Z"
          />
        </g>
        <g id="toes">
          <g id="big_toe" data-name="big toe">
            <path
              id="big_toe_1"
              data-name="big toe 1"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M46.74,161.92h0c-7.3-2.6-16.8-1.9-28.5,1.8-12.6,8.1-18.7,5.6-12-18,6.7-18.6,6.8-38.3,3.8-58.5-3-12.8-2.9-21.3,5.2-18.8,8.3,1.7,16.3,1.2,24-2.2,15.4-.3,17.9,8.3,9.8,24.4-9,20.8-5.3,38.5,5.6,54.4,3.2,10.8,5.7,20.9-7.9,16.9h0Z"
            />
            <path
              id="big_toe_2"
              data-name="big toe 2"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M45.64,60.72h0l-18.3,4.5c-10-.7-15.7-5.2-15.4-15-1.8-9,.2-13.4,8.2-10.5,7.6-11.8,9.6-19.5,10.9-26.6-.8-7.3,2.8-11.4,10.7-12.5,3.9-.5,7.9.5,10.9,3,5.7,4.8,6.9,11.3,2,20-5.1,7.5-4.5,15,1.9,22.5,7.1,12.3,5.9,18.8-10.9,14.6h0Z"
            />
          </g>
          <g id="second_toe" data-name="second toe">
            <path
              id="second_toe_1"
              data-name="second toe 1"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M101.14,152.92c-4.7-2.7-10.1-1.9-16.2,2.2-9.2,6.2-13.7,4.2-8.2-15,3.5-18.2.5-35.6-7.5-52.5,0-10.4,2.1-14.1,7.1-8.2,3.9,2.1,7.5,1.9,10.9,0,7.1-3,9,.8,6.4,10.5-3.5,20.9-.3,36.5,10.5,46.1,6.3,10.9,8.3,18.7-3,16.9h0Z"
            />
            <path
              id="second_toe_2"
              data-name="second toe 2"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M83.14,75.32c-9.1,1.6-13-.9-9.8-9,3.8-6.1,5.4-13.8,6-22.1-5.1-6.2,1.6-7.9,12.4-7.9,8.6.8,9.4,5.5,7.1,11.6-3.4,5.3-3.5,10.8-.8,16.5,2.5,8.3,3.1,14.6-7.1,9.8,0,0-7.9,1.1-7.8,1.1Z"
            />
            <path
              id="second_toe_3"
              data-name="second toe 3"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M99.24,32.22h0l-15,.4c-10.9.5-8.3-5,1.1-13.5-3.4-5.8-4.4-10.2,2.7-9.8h1.4s6.4-.4,6.4-.4c3.7-.2,7.3,1.1,9.7,3.9,3.6,4.1,1.6,8.2-2.2,12.3,3.7,8.4.4,8.5-4.1,7.1h0Z"
            />
          </g>
          <g id="third_toe" data-name="third toe">
            <path
              id="third_toe_1"
              data-name="third toe 1"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M134.44,162.72c-3.9-1.9-8.4-1.9-14.2,1.5-7.8,5.5-10,.4-7.5-13.5,7.7-7.5,4.4-26.5-1.9-48.8-3.9-15.2,4.6-13.5,13.9-10.5,11.8.3,12,7,7.1,16.5-4.9,11.8-2.9,24.7,7.5,39,7.1,13.7,7,20.4-4.9,15.8h0Z"
            />
            <path
              id="third_toe_2"
              data-name="third toe 2"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M128.84,89.92h0l-8.2-1.8c-6.2,1.5-9.4,0-7.1-7.1,2.1-6.2,3-11.8,3-16.9-2.7-7.3,2.2-9.7,11.6-9.4,13,3.2,13.5,12,8.6,23.2,2.5,9.1,1.5,14.7-7.9,12h0Z"
            />
            <path
              id="third_toe_3"
              data-name="third toe 3"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M141.64,41.92h0c3.9,8.6,5.3,14.9-2.3,13.2l-15.8-3.8c-10.4.3-4.6-5,1.9-10.5,1.6-8.9,4-14.7,9.4-9.4,12.5-.6,12.5,3.8,6.8,10.5h0Z"
            />
          </g>
          <g id="fourth_toe" data-name="fourth toe">
            <path
              id="fourth_toe_1"
              data-name="fourth toe 1"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M168.94,185.22h0c-7-6.1-13.8-5.6-20.2,4.1-4.1-4.5-4.1-10.7.8-18.8,1.4-12.2,1.5-23.9-3.4-33.8l-3.8-13.1c-2.7-9.8.4-13.6,15-5.2,8.8-3.1,11.5-.6,7.5,7.9-6.6,14.2-3.1,29.7,10.9,46.5,4.1,10.2,2.5,14.9-6.8,12.4h0Z"
            />
            <path
              id="fourth_toe_2"
              data-name="fourth toe 2"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M164.44,112.82l-10.9,1.6c-8-.5-10-5.1-6.4-13.5-1.2-6.1,3.1-8.5,13.9-6.8,7.5,1.6,9,5.6,6.8,11.2,3.5,5.4,4.4,9.3-3.4,7.5h0Z"
            />
            <path
              id="fourth_toe_3"
              data-name="fourth toe 3"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M169.84,77.52c.3,4,2.2,4.8,3.9,5.9,5.5,8.6-.9,10.7-15.1,8.6-3.9.6-7.2.2-8.6-2.6-1.4-2.8.2-6,3.2-7.2s4.6-2.6,4.8-4.6c.1-2,.8-4,2.3-5.4,4.1-3.9,7.2-1.5,9.5,5.3h0Z"
            />
          </g>
          <g id="fifth_toe" data-name="fifth toe">
            <path
              id="fifth_toe_1"
              data-name="fifth toe 1"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M207.64,213.32h0c-4.7-3-9.9-2.8-15.4-.6-6.6,6.5-10.7,5.4-9.8-12,4.7-20.1.6-35-5.2-48.8-3.8-10.6,4.1-7.1,12-3.8,9.3-1.2,13.6,1.5,9.4,10.5-1.2,12.4,1,23.3,6.8,32.6,9.4,9.6,10.7,17.1,2.2,22.1h0Z"
            />
            <path
              id="fifth_toe_2"
              data-name="fifth toe 2"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M196.34,144.72h0l-10.4-.3c-5.2-.1-7.3-2.1-6-6.4-2.5-2.8-2.6-5,2.6-6l10.1-3c4.9.5,7.4,3.3,7.1,8.6,3.6,4.5,2.6,6.9-3.4,7.1h0Z"
            />
            <path
              id="fifth_toe_3"
              data-name="fifth toe 3"
              class="vorfuss_dp_obl_rechts1430_cls_5"
              d="M197.44,114.32c.5,3.7,2.2,4.4,3.8,5.2,5.2,7.7-.3,10.1-12.8,9-3.5.8-6.2.6-7.7-1.9s-.3-5.6,2.3-6.9,4-2.7,4-4.6.5-3.8,1.7-5.2c3.5-3.9,6.3-1.8,8.7,4.4h0Z"
            />
          </g>
        </g>
      </g>
      <g id="overlay">
        <g
          id="Phalangen_overlay"
          data-name="Phalangen overlay"
          class="vorfuss_dp_obl_rechts1430_cls_1"
        >
          <g id="Phalangen_overlay_1" data-name="Phalangen overlay 1">
            <path
              id="Phalangen_overlay_1.1"
              data-name="Phalangen overlay 1.1"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M46.73,162.44h0c-7.3-2.6-16.8-1.9-28.5,1.8-12.6,8.1-18.7,5.6-12-18,6.7-18.6,6.8-38.3,3.8-58.5-3-12.8-2.9-21.3,5.2-18.8,8.3,1.7,16.3,1.2,24-2.2,15.4-.3,17.9,8.3,9.8,24.4-9,20.8-5.3,38.5,5.6,54.4,3.2,10.8,5.7,20.9-7.9,16.9h0Z"
            />
            <path
              id="Phalangen_overlay_1.2"
              data-name="Phalangen overlay 1.2"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M45.63,61.24h0l-18.3,4.5c-10-.7-15.7-5.2-15.4-15-1.8-9,.2-13.4,8.2-10.5,7.6-11.8,9.6-19.5,10.9-26.6-.8-7.3,2.8-11.4,10.7-12.5,3.9-.5,7.9.5,10.9,3,5.7,4.8,6.9,11.3,2,20-5.1,7.5-4.5,15,1.9,22.5,7.1,12.3,5.9,18.8-10.9,14.6h0Z"
            />
          </g>
          <g id="Phalangen_overlay_2" data-name="Phalangen overlay 2">
            <path
              id="Phalangen_overlay_2.1"
              data-name="Phalangen overlay 2.1"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M101.13,153.44c-4.7-2.7-10.1-1.9-16.2,2.2-9.2,6.2-13.7,4.2-8.2-15,3.5-18.2.5-35.6-7.5-52.5,0-10.4,2.1-14.1,7.1-8.2,3.9,2.1,7.5,1.9,10.9,0,7.1-3,9,.8,6.4,10.5-3.5,20.9-.3,36.5,10.5,46.1,6.3,10.9,8.3,18.7-3,16.9h0Z"
            />
            <path
              id="Phalangen_overlay_2.2"
              data-name="Phalangen overlay 2.2"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M83.13,75.84c-9.1,1.6-13-.9-9.8-9,3.8-6.1,5.4-13.8,6-22.1-5.1-6.2,1.6-7.9,12.4-7.9,8.6.8,9.4,5.5,7.1,11.6-3.4,5.3-3.5,10.8-.8,16.5,2.5,8.3,3.1,14.6-7.1,9.8,0,0-7.9,1.1-7.8,1.1Z"
            />
            <path
              id="Phalangen_overlay_2.3"
              data-name="Phalangen overlay 2.3"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M99.23,32.74h0l-15,.4c-10.9.5-8.3-5,1.1-13.5-3.4-5.8-4.4-10.2,2.7-9.8h1.4s6.4-.4,6.4-.4c3.7-.2,7.3,1.1,9.7,3.9,3.6,4.1,1.6,8.2-2.2,12.3,3.7,8.4.4,8.5-4.1,7.1h0Z"
            />
          </g>
          <g id="Phalangen_overlay_3" data-name="Phalangen overlay 3">
            <path
              id="Phalangen_overlay_3.1"
              data-name="Phalangen overlay 3.1"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M134.43,163.24c-3.9-1.9-8.4-1.9-14.2,1.5-7.8,5.5-10,.4-7.5-13.5,7.7-7.5,4.4-26.5-1.9-48.8-3.9-15.2,4.6-13.5,13.9-10.5,11.8.3,12,7,7.1,16.5-4.9,11.8-2.9,24.7,7.5,39,7.1,13.7,7,20.4-4.9,15.8h0Z"
            />
            <path
              id="Phalangen_overlay_3.2"
              data-name="Phalangen overlay 3.2"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M128.83,90.44h0l-8.2-1.8c-6.2,1.5-9.4,0-7.1-7.1,2.1-6.2,3-11.8,3-16.9-2.7-7.3,2.2-9.7,11.6-9.4,13,3.2,13.5,12,8.6,23.2,2.5,9.1,1.5,14.7-7.9,12h0Z"
            />
            <path
              id="Phalangen_overlay_3.3"
              data-name="Phalangen overlay 3.3"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M141.63,42.44h0c3.9,8.6,5.3,14.9-2.3,13.2l-15.8-3.8c-10.4.3-4.6-5,1.9-10.5,1.6-8.9,4-14.7,9.4-9.4,12.5-.6,12.5,3.8,6.8,10.5h0Z"
            />
          </g>
          <g id="Phalangen_overlay_4" data-name="Phalangen overlay 4">
            <path
              id="Phalangen_overlay_4.1"
              data-name="Phalangen overlay 4.1"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M168.93,185.74h0c-7-6.1-13.8-5.6-20.2,4.1-4.1-4.5-4.1-10.7.8-18.8,1.4-12.2,1.5-23.9-3.4-33.8l-3.8-13.1c-2.7-9.8.4-13.6,15-5.2,8.8-3.1,11.5-.6,7.5,7.9-6.6,14.2-3.1,29.7,10.9,46.5,4.1,10.2,2.5,14.9-6.8,12.4h0Z"
            />
            <path
              id="Phalangen_overlay_4.2"
              data-name="Phalangen overlay 4.2"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M164.43,113.34l-10.9,1.6c-8-.5-10-5.1-6.4-13.5-1.2-6.1,3.1-8.5,13.9-6.8,7.5,1.6,9,5.6,6.8,11.2,3.5,5.4,4.4,9.3-3.4,7.5h0Z"
            />
            <path
              id="Phalangen_overlay_4.3"
              data-name="Phalangen overlay 4.3"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M169.83,78.04c.3,4,2.2,4.8,3.9,5.9,5.5,8.6-.9,10.7-15.1,8.6-3.9.6-7.2.2-8.6-2.6s.2-6,3.2-7.2,4.6-2.6,4.8-4.6c.1-2,.8-4,2.3-5.4,4.1-3.9,7.2-1.5,9.5,5.3h0Z"
            />
          </g>
          <g id="Phalangen_overlay_5" data-name="Phalangen overlay 5">
            <path
              id="Phalangen_overlay_5.1"
              data-name="Phalangen overlay 5.1"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M207.63,213.84h0c-4.7-3-9.9-2.8-15.4-.6-6.6,6.5-10.7,5.4-9.8-12,4.7-20.1.6-35-5.2-48.8-3.8-10.6,4.1-7.1,12-3.8,9.3-1.2,13.6,1.5,9.4,10.5-1.2,12.4,1,23.3,6.8,32.6,9.4,9.6,10.7,17.1,2.2,22.1h0Z"
            />
            <path
              id="Phalangen_overlay_5.2"
              data-name="Phalangen overlay 5.2"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M196.33,145.24h0l-10.4-.3c-5.2-.1-7.3-2.1-6-6.4-2.5-2.8-2.6-5,2.6-6l10.1-3c4.9.5,7.4,3.3,7.1,8.6,3.6,4.5,2.6,6.9-3.4,7.1h0Z"
            />
            <path
              id="Phalangen_overlay_5.3"
              data-name="Phalangen overlay 5.3"
              class="phalangen1430Fill"
              style={{
                fill: props.colors.phalangen1430Fill,
                stroke: props.colors.phalangen1430Fill,
                opacity: props.colors.phalangen1430Fill,
              }}
              d="M197.43,114.84c.5,3.7,2.2,4.4,3.8,5.2,5.2,7.7-.3,10.1-12.8,9-3.5.8-6.2.6-7.7-1.9s-.3-5.6,2.3-6.9,4-2.7,4-4.6.5-3.8,1.7-5.2c3.5-3.9,6.3-1.8,8.7,4.4h0Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RechterVorfussDplOblLiegend1430;
