import { useEffect, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { useTranslation } from "react-i18next";
import React from "react";

function GlobalFilter({ value, valueSetter, itemCount }) {
  const { t } = useTranslation(["common"]);
  return (
    <span>
      {" "}
      <input
        type="search"
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          valueSetter(e.target.value);
        }}
        placeholder={t("searchIn") + itemCount + " " + t("records")}
      />
    </span>
  );
}

const defaultPropGetter = () => ({});

export default function Table({
  columns,
  data,
  idfield,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  controlledPageIndex,
  rowClickHandler,
  itemCount,
  idprefix = "row",
  aFilter = [],
  selectedids = [],
  addNewPrice,
  getCellProps = defaultPropGetter,
}) {
  const [filterValue, setFilterValue] = useState("");
  const { t } = useTranslation(["common"]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: controlledPageIndex },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      pageCount: controlledPageCount,
      addNewPrice,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy }, filterValue, aFilter);
  }, [pageIndex, pageSize, filterValue, sortBy]);

  return (
    <div className="table-responsive">
      <table>
        <tbody>
          <tr>
            <th>{t("showAmount")}</th>
            <th>{t("search")}</th>
          </tr>
          <tr>
            <th>
              <select
                className="form-control"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                style={{ width: "120px", height: "38px" }}
              >
                {[5, 8, 21, 55].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {t("showAmount")} {pageSize}
                  </option>
                ))}
              </select>
            </th>
            <th>
              <GlobalFilter
                value={filterValue}
                valueSetter={setFilterValue}
                itemCount={itemCount}
              />
            </th>
          </tr>
        </tbody>
      </table>
      <React.StrictMode>
        <table className="table-pm table table-sm" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fas fa-sort-down ml-1"></i>
                        ) : (
                          <i className="fas fa-sort-up ml-1"></i>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="border" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              let row_id = i;
              if (idfield) {
                if (row.original[idfield]) {
                  row_id = row.original[idfield];
                }
              }
              return (
                <tr
                  className={
                    selectedids.hasOwnProperty(row_id)
                      ? `my-auto row-selected`
                      : "my-auto"
                  }
                  {...row.getRowProps()}
                  id={row_id}
                  key={idprefix + row_id}
                  onClick={(e) => {
                    if (typeof rowClickHandler !== "undefined") {
                      rowClickHandler(row);
                    }
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                            style: cell.column.style,
                          },
                          getCellProps(cell),
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr>
              {loading ? (
                // Use our custom loading state to show a loading indicator
                <td colSpan="10000">{t("loading")}</td>
              ) : (
                <td colSpan="10000">
                  {t("showRange")} {page.length * pageIndex + 1}-
                  {page.length * (pageIndex + 1)} {t("of")} {itemCount}{" "}
                  {t("results")}
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </React.StrictMode>
      <ul className="pagination pl-3 justify-content-center">
        <li
          className="page-item"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <a className="page-link">{"<<"}</a>
        </li>
        <li
          className="page-item"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <a className="page-link">{"<"}</a>
        </li>
        <li className="page-item">
          <a className="page-link">
            <span>
              {pageIndex + 1} {t("of")} {controlledPageCount}
            </span>
          </a>
        </li>
        <li
          className="page-item"
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage}
        >
          <a className="page-link">{">"}</a>
        </li>
        <li
          className="page-item"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <a className="page-link">{">>"}</a>
        </li>
      </ul>
    </div>
  );
}
