import React from "react";
import { Row, Col } from "react-bootstrap";
import PatientsTable from "../../../Patients/PatientListTable"

import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../Redux/mappers/reports/reportsMappers";

import "./stylesheets/SelectPatient.css"

function SelectPatient (props) {
  const rowClickHandler = (event, row) => {
    props.addRemoveReportSelectedId(row.original.id);
  };

  return (
    <Row className="select-patient-report">
      <Col md={12} xl={12} >
        <PatientsTable
          // headerLess={true}
          keycloak={props.keycloak}
          rowClick={rowClickHandler}
          selectedIds={props.reports.repSelectedIds}
        />
    </Col>
  </Row>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPatient);