import style from "./style.scss";

function RechterVorfussDplLiegend1428(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 232.15 486.69">
      <g id="foot">
        <g id="toes">
          <g id="big_toe" data-name="big toe">
            <path
              id="big_toe_1"
              data-name="big toe 1"
              class="vorfuss_dp_rechts1428_cls_2"
              d="M15.95,58.48v-.15c3.54-8.19,6.42-8.78,8.86-3.91,6.57-7.9,13.22-15.81,11.89-29.76-2.29-2.66-2.36-5.39,2.29-8.49,5.16-5.98,10.85-9.52,17.79-6.35,5.39-.51,10.41-.51,8.56,7.83,5.31,2.8,6.05,6.5,3.32,11-9.38,4.95-15.06,12.03-7.9,26.21,2.14,6.64,1.03,11.44-2.29,14.91l-18.09,2.14-22.37-1.03c-4.06-.96-4.87-4.94-1.99-12.4h0s-.07,0-.07,0Z"
            />
            <path
              id="big_toe_2"
              data-name="big toe 2"
              class="vorfuss_dp_rechts1428_cls_2"
              d="M7.29,153.59v.07s.07,0,.07,0c-5.98,15.65-1.33,21.85,12.48,19.86l25.33-5.69c11.89,5.98,13.88-1.77,8.86-19.49-6.05-18.46-11-37.07.37-59.22,7.39-14.03,3.77-19.57-8.56-18.46l-18.75,1.4c-11.74-4.57-15.95,2-16.47,14.18,6.57,24.29,3.55,46.3-3.32,67.34h0Z"
            />
          </g>
          <g id="second_toe" data-name="second toe">
            <path
              id="second_toe_1"
              data-name="second toe 1"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M106.77,6.57h.07c6.64-8.27,11.59-8.35,14.47,1.4,10.55,6.72,8.63,9.45.66,10.26-1.99,3.39-1.92,6.72.96,9.89,3.62,3.62,2.95,6.79-1.33,9.6h-19.79c-3.91-2.95-6.2-5.91-.66-8.49,4.21-2.51,5.39-5.68,2.29-9.89-3.84-3.61-2.37-7.9,3.32-12.77h.01Z"
            />
            <path
              id="second_toe_2"
              data-name="second toe 2"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M97.23,50.8v.07h0c-.44-8.12,0-14.4,7.24-8.86,3.25-1.48,5.83-1.18,10.56-.74,11.51-3.18,10.85,1.99,1.99,12.77-2.8,8.42-3.54,15.36-1.99,20.53,4.88,7.31,2.74,9.75-2.95,9.6l-13.81-2.51c-6.72,1.63-10.41-.15-5.61-11.37,4.43-4.21,6.57-10.19,4.58-19.49h-.01Z"
            />
            <path
              id="second_toe_3"
              data-name="second toe 3"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M90.67,97.91v.07h0c-1.26-9.9,1.18-15.73,9.89-14.55,2.21,2.36,4.35,2.36,6.57,0,9.53,1.11,14.18,5.47,9.23,15.95-9.38,22.74-7.9,38.17-2.66,51.02,5.1,13.36,3.1,15.95-4.28,10.26-6.79-9.67-13.22-10.19-19.42-3.54-13.22,5.39-14.18,1.26-4.95-10.63,5.83-8.86,8.78-24.73,5.61-48.58h0Z"
            />
          </g>
          <g id="third_toe" data-name="third toe">
            <path
              id="third_toe_1"
              data-name="third toe 1"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M150.02,46.24v.07h-.07c-5.09-3.91-6.5-7.31.37-9.6,4.06-3.55,8.12-4.58,11.89,1.03,4.8,3.1,6.13,6.42.96,9.89-1.4,3.91-1.4,6.94.37,8.86,6.65,8.05,5.84,11.52-.66,11.37-4.66-1.48-9.38-1.33-14.18-.74-6.05-4.88-5.98-8.79-.96-12.04,1.4-1.7,2.14-4.58,2.29-8.86h0v.02Z"
            />
            <path
              id="third_toe_3"
              data-name="third toe 3"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M141.69,75.69h0c-.74-5.91-.15-10.48,6.94-8.49,3.77-.59,7.31-.96,7.9,2.14,8.72-5.32,7.98,1.47,5.91,9.89-4.21,6.71-4.43,9.89-3.99,12.4,4.94,5.39,3.25,7.61-3.99,7.09-2.07-2.66-5.24-2.07-8.86,0-5.02-1.26-7.09-5.24-4.58-13.44,1.1-1.92,1.32-5.02.66-9.6h0Z"
            />
            <path
              id="third_toe_2"
              data-name="third toe 2"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M139.83,108.31h-.07c-2.66-7.53,0-11.22,9.23-10.26l3.62.74c10.41-2,13.66,1.84,8.27,12.77-11.66,20.38-12.25,36.48-9.89,51.39,7.38,14.33,5.46,18.46-4.58,13.44-3.69-7.38-7.97-9.82-13.14-3.91-6.8,12.7-11.08,2.14-5.91-11,9.31-9.97,12.63-29.02,12.48-53.16h-.01Z"
            />
          </g>
          <g id="fourth_toe" data-name="fourth toe">
            <path
              id="fourth_toe_1"
              data-name="fourth toe 1"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M183.55,78.8v.07h0c3.54-7.46,7.6-7.46,12.18-.74,6.35,5.24,6.13,8.49-.66,9.89-1.18,4.13-1.25,7.38-.66,10.26,8.64.59,6.28,3.69,1.33,7.46-4.43-2.21-9.23-2.29-14.18-1.4-3.17-2.14-3.03-5.39,0-9.6,2.44-3.25,1.99-6.28,0-9.23-4.14-1.11-2.29-3.62,1.99-6.72h0Z"
            />
            <path
              id="fourth_toe_3"
              data-name="fourth toe 3"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M182.22,106.18c3.91-2.44,9.01-1.55,14.84,1.03,2.66,2.36,2.66,4.73,0,7.09-.74,3.47-.89,6.35-.37,8.49,2.44,4.72,1.33,7.16-1.33,8.49-6.5-1.99-10.7-2.95-13.51-3.17-5.39-1.03-4.87-4.5-2.29-8.86l1.33-4.58c-1.84-3.99-2.36-7.31,1.33-8.49h0Z"
            />
            <path
              id="fourth_toe_2"
              data-name="fourth toe 2"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M178.31,142.81v-.07s-.07,0-.07,0c-2.44-8.72-2.07-14.26,5.24-12.04,3.32,1.03,6.5.29,7.9,1.77,7.24,1.33,9.75,6.43,5.91,16.32-12.55,13.66-9.52,29.09-7.9,44.3,3.39,16.17,1.18,18.68-4.58,12.77-3.62-4.8-7.9-5.03-12.48-2.81-7.16,5.54-7.23.74-5.24-7.09,6.2-16.54,10.26-34.11,11.22-53.16h0Z"
            />
          </g>
          <g id="little_toe" data-name="little toe">
            <path
              id="little_toe_1"
              data-name="little toe 1"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M213.17,139.49h0c-2.66-3.17-1.85-7.09.66-11.37,4.94-6.28,8.78-6.79,10.85,1.77,4.73,6.05,4.95,10.26,0,12.4,3.62,10.92,3.25,15.35.37,14.91l-12.85-1.03c-5.24-2.81-4.35-6.06-.66-9.6,3.61-2.29,3.24-4.65,1.62-7.09h.01Z"
            />
            <path
              id="little_toe_3"
              data-name="little toe 3"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M209.97,159.71h-.07c4.36-3.32,9.75-3.84,16.47-1.03-1.03,6.27-1.33,10.56-.96,13.14,6.35,3.98,5.1,8.19,2.66,12.4-6.35,1.25-13.44.66-20.75-.37-3.1-3.18-4.8-6.35,0-9.23,2-4.43,3.03-9.3,2.66-14.91h-.01Z"
            />
            <path
              id="little_toe_2"
              data-name="little toe 2"
              class="vorfuss_dp_rechts1428_cls_1"
              d="M205.93,193.38h0c-.52-7.61,1.11-10.41,5.24-7.09l9.52,1.03c5.24-3.02,7.46-1.03,5.61,7.83-7.6,13.3-10.56,26.29-7.9,38.99,1.1,9.89-1.04,12.48-4.95,11-1.18-4.88-5.24-6.13-11.52-4.58-6.87,3.02-8.49.88-3.62-7.83,5.17-9.74,7.24-23.48,7.61-39.35h0Z"
            />
          </g>
        </g>
        <g id="metatarsus">
          <path
            id="metatarsus_1"
            data-name="metatarsus 1"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M3.32,209.7h.07c-4.5-19.35-5.24-34.48,8.86-33.3,9.82-1.25,23.19-11.15,42.46-3.54,3.84.14,6.79,3.69,8.86,9.89,15.36,4.21,15.95,12.55.96,25.18-12.85,41.79-7.61,75.32,14.47,101.01,11.08,4.06,12.18,12.77-.96,27.98-17.2-5.76-27.98.59-38.54,7.46-14.69,8.79-18.38.59-16.76-15.58.88-40.83-6.35-80.41-19.42-119.1h0Z"
          />
          <path
            id="metatarsus_2"
            data-name="metatarsus 2"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M84.39,165.16v-.15c9.6-10.41,19.2-10.92,28.65-1.03,3.62,3.47,4.36,7.75,2.66,12.77,6.43,6.94,4.51,16.17-1.99,26.58-7.68,38.4-1.7,83.73,3.62,128.7,5.61,4.58,8.42,10.93,5.61,20.9,2.81,14.62-1.99,22.52-10.19,27.32-3.98-14.47-15.65-17.28-29.31-16.98,1.62-9.75-.74-17.28-4.95-23.78,1.62-3.32,6.49-9.08,9.52-17.72,4.13-20.6,3.47-67.04,2.95-112.01-12.1-12.1-11.66-20.15-9.52-27.61,1.77-5.9,2.51-11.52,2.95-16.98h0Z"
          />
          <path
            id="metatarsus_3"
            data-name="metatarsus 3"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M129.88,179.56h0c5.17-9.15,10.19-13.58,14.84-3.17,7.38,4.58,12.92,11.52,9.89,29.09-6.64,9.45-9.3,21.12-8.56,34.78-1.48,23.48,2.44,59.58,6.94,97.09,8.12,25.18,4.06,36.04-4.95,41.13-3.03-17.64-12.11-16.61-22.08-13.14-2.66-8.2-1.18-19.72,1.99-32.64,3.62-20.08,1.99-73.54.96-123.01-6.42-2.65-5.24-13.73.96-30.12h0Z"
          />
          <path
            id="metatarsus_4"
            data-name="metatarsus 4"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M163.39,226.31l.07.07h0c-3.32-7.01,0-14.1,7.24-21.26,6.65-5.02,12.11-3.02,16.47,4.95l1.99,15.58c1.47,9.01-1.92,15.06-7.61,19.86-8.05,12.19-7.39,38.77-6.28,65.94l5.61,54.93c3.18,8.19,5.02,16.61.96,26.21-1.11,8.64-3.47,13.88-6.57,16.98-3.83-12.7-13.36-19.04-26.65-21.26,7.53-17.06,10.12-37,8.86-59.22-2.43-34.26.15-68.52,5.91-102.78h0Z"
          />
          <path
            id="metatarsus_5"
            data-name="metatarsus 5"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M195.43,256.43v.07h-.07c0-17.06,10.64-19.27,17.43-9.6,6.13-1.25,5.76,3.84,2.29,12.04,5.32,5.54,8.35,11.44-3.32,20.23-8.12,15.88-10.19,34.33-8.86,54.27l4.95,66.97c7.53,26.95,1.4,41.13-12.85,47.85-4.87,8.27-8.49,8.71-10.19-2.14,1.18-9.31-3.03-21.56-8.86-34.78,5.68-12.33,9.6-24.44,6.94-35.81l.66-47.48,8.27-55.3c-3.47-4.65-2.07-10.12,3.62-16.32h0Z"
          />
          <path
            id="metatarsus_6"
            data-name="metatarsus 6"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M8.7,177.8c-4.13,1.03-4.13,2.21,0,3.54,13.29,6.13,27.47,4.21,42.16-3.54,2.22-.52,3.55-2.22,3.99-4.95"
          />
          <path
            id="metatarsus_7"
            data-name="metatarsus 7"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M86.09,166.06c6.35,5.02,12.92,8.79,20.45,4.95l8.56-4.58"
          />
          <path
            id="metatarsus_8"
            data-name="metatarsus 8"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M128.84,186.65c3.62-1.25,6.2,1.03,7.9,6.72,5.39,2.07,8.64.67,10.19-3.17,3.62-.73,5.61-2.65,5.61-6.05"
          />
          <path
            id="metatarsus_9"
            data-name="metatarsus 9"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M165.75,211.84c4.06-.22,7.16,1.92,8.86,7.46,4.88,3.17,9.01,2.51,12.85-.37"
          />
          <path
            id="metatarsus_10"
            data-name="metatarsus 10"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M198.02,258.28c8.19,2.14,13.88.66,13.51-9.23"
          />
        </g>
        <g id="tarsus">
          <path
            id="tarsus_5"
            data-name="tarsus 5"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M44.3,420.64h-.07c-16.54,9.23-20.16-3.99-18.09-26.95-.59-23.11,4.8-38.4,13.51-49.25,24.66-10.85,45.41-15.8,41.2,17.72-13.29,16.24-21.41,33-19.12,50.65l-17.43,7.83h0Z"
          />
          <path
            id="tarsus_4"
            data-name="tarsus 4"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M100.56,421.59c-8.57-9.67-20.24-11.22-33.6-8.12-5.83-11.81-2.29-27.68,12.48-48.58,16.17-3.1,26.8.89,30.64,13.44,2.15,14.7-2.06,29.09-9.52,43.27h0Z"
          />
          <path
            id="tarsus_3"
            data-name="tarsus 3"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M104.46,422.05h0c6.79-14.18,11.22-29.02,12.48-44.67,15.36-17.58,25.25-15.8,30.94,1.03,1.63,27.17-.44,48.73-10.85,57.44-4.5,4.88-9.82,7.24-15.8,7.83-1.55-14.25-9.01-18.23-16.76-21.63h0Z"
          />
          <path
            id="tarsus_2"
            data-name="tarsus 2"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M162.21,479.43h-.07c-33.89,14.25-44.45,3.47-53.31-9.23,7.68-8.42,11.52-16.32,11.89-23.78,24.07-7.09,30.79-25.33,27.98-49.62,12.03-3.39,21.7,1.55,26.65,23.41,7.68,9.15,7.68,19.64,5.61,30.49-5.39,11-11.74,20.38-18.75,28.72h0Z"
          />
          <path
            id="tarsus_1"
            data-name="tarsus 1"
            class="vorfuss_dp_rechts1428_cls_1"
            d="M28.72,485.84h.07c-9.09-4.06-12.04-13.58-6.28-30.86,1.92-18.09,6.94-30.72,18.09-32.27,24.37-12.11,44.6-13.73,58.26.74,26.36,6.94,21.34,24.95,7.24,46.07-27.76-25.69-53.16-14.25-77.38,16.32h0Z"
          />
        </g>
      </g>
      <g id="overlays">
        <g
          id="Phalangen_overlay"
          data-name="Phalangen overlay"
          class="vorfuss_dp_rechts1428_cls_4"
        >
          <g id="Phalangen_overlay_1" data-name="Phalangen overlay 1">
            <path
              id="Phalangen_overlay_1.1"
              data-name="Phalangen overlay 1.1"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M15.8,58.85v-.15c3.54-8.19,6.42-8.78,8.86-3.91,6.57-7.9,13.22-15.81,11.89-29.76-2.29-2.66-2.36-5.39,2.29-8.49,5.16-5.98,10.85-9.52,17.79-6.35,5.39-.51,10.41-.51,8.56,7.83,5.31,2.8,6.05,6.5,3.32,11-9.38,4.95-15.06,12.03-7.9,26.21,2.14,6.64,1.03,11.44-2.29,14.91l-18.09,2.14-22.37-1.03c-4.06-.96-4.87-4.94-1.99-12.4h0s-.07,0-.07,0Z"
            />
            <path
              id="Phalangen_overlay_1.2"
              data-name="Phalangen overlay 1.2"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M7.14,153.96v.07s.07,0,.07,0c-5.98,15.65-1.33,21.85,12.48,19.86l25.33-5.69c11.89,5.98,13.88-1.77,8.86-19.49-6.05-18.46-11-37.07.37-59.22,7.39-14.03,3.77-19.57-8.56-18.46l-18.75,1.4c-11.74-4.57-15.95,2-16.47,14.18,6.57,24.29,3.55,46.3-3.32,67.34h0Z"
            />
          </g>
          <g id="Phalangen_overlay_2" data-name="Phalangen overlay 2">
            <path
              id="Phalangen_overlay_2.1"
              data-name="Phalangen overlay 2.1"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M106.62,6.94h.07c6.64-8.27,11.59-8.35,14.47,1.4,10.55,6.72,8.63,9.45.66,10.26-1.99,3.39-1.92,6.72.96,9.89,3.62,3.62,2.95,6.79-1.33,9.6h-19.79c-3.91-2.95-6.2-5.91-.66-8.49,4.21-2.51,5.39-5.68,2.29-9.89-3.84-3.61-2.37-7.9,3.32-12.77h.01Z"
            />
            <path
              id="Phalangen_overlay_2.2"
              data-name="Phalangen overlay 2.2"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M97.08,51.17v.07h0c-.44-8.12,0-14.4,7.24-8.86,3.25-1.48,5.83-1.18,10.56-.74,11.51-3.18,10.85,1.99,1.99,12.77-2.8,8.42-3.54,15.36-1.99,20.53,4.88,7.31,2.74,9.75-2.95,9.6l-13.81-2.51c-6.72,1.63-10.41-.15-5.61-11.37,4.43-4.21,6.57-10.19,4.58-19.49h-.01Z"
            />
            <path
              id="Phalangen_overlay_2.3"
              data-name="Phalangen overlay 2.3"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M90.52,98.28v.07h0c-1.26-9.9,1.18-15.73,9.89-14.55,2.21,2.36,4.35,2.36,6.57,0,9.53,1.11,14.18,5.47,9.23,15.95-9.38,22.74-7.9,38.17-2.66,51.02,5.1,13.36,3.1,15.95-4.28,10.26-6.79-9.67-13.22-10.19-19.42-3.54-13.22,5.39-14.18,1.26-4.95-10.63,5.83-8.86,8.78-24.73,5.61-48.58h0Z"
            />
          </g>
          <g id="Phalangen_overlay_3" data-name="Phalangen overlay 3">
            <path
              id="Phalangen_overlay_3.1"
              data-name="Phalangen overlay 3.1"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M149.87,46.61v.07h-.07c-5.09-3.91-6.5-7.31.37-9.6,4.06-3.55,8.12-4.58,11.89,1.03,4.8,3.1,6.13,6.42.96,9.89-1.4,3.91-1.4,6.94.37,8.86,6.65,8.05,5.84,11.52-.66,11.37-4.66-1.48-9.38-1.33-14.18-.74-6.05-4.88-5.98-8.79-.96-12.04,1.4-1.7,2.14-4.58,2.29-8.86h0v.02Z"
            />
            <path
              id="Phalangen_overlay_3.2"
              data-name="Phalangen overlay 3.2"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M141.54,76.06h0c-.74-5.91-.15-10.48,6.94-8.49,3.77-.59,7.31-.96,7.9,2.14,8.72-5.32,7.98,1.47,5.91,9.89-4.21,6.71-4.43,9.89-3.99,12.4,4.94,5.39,3.25,7.61-3.99,7.09-2.07-2.66-5.24-2.07-8.86,0-5.02-1.26-7.09-5.24-4.58-13.44,1.1-1.92,1.32-5.02.66-9.6h0Z"
            />
            <path
              id="Phalangen_overlay_3.3"
              data-name="Phalangen overlay 3.3"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M139.68,108.68h-.07c-2.66-7.53,0-11.22,9.23-10.26l3.62.74c10.41-2,13.66,1.84,8.27,12.77-11.66,20.38-12.25,36.48-9.89,51.39,7.38,14.33,5.46,18.46-4.58,13.44-3.69-7.38-7.97-9.82-13.14-3.91-6.8,12.7-11.08,2.14-5.91-11,9.31-9.97,12.63-29.02,12.48-53.16h-.01Z"
            />
          </g>
          <g id="Phalangen_overlay_4" data-name="Phalangen overlay 4">
            <path
              id="Phalangen_overlay_4.1"
              data-name="Phalangen overlay 4.1"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M183.4,79.17v.07h0c3.54-7.46,7.6-7.46,12.18-.74,6.35,5.24,6.13,8.49-.66,9.89-1.18,4.13-1.25,7.38-.66,10.26,8.64.59,6.28,3.69,1.33,7.46-4.43-2.21-9.23-2.29-14.18-1.4-3.17-2.14-3.03-5.39,0-9.6,2.44-3.25,1.99-6.28,0-9.23-4.14-1.11-2.29-3.62,1.99-6.72h0Z"
            />
            <path
              id="Phalangen_overlay_4.2"
              data-name="Phalangen overlay 4.2"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M182.07,106.55c3.91-2.44,9.01-1.55,14.84,1.03,2.66,2.36,2.66,4.73,0,7.09-.74,3.47-.89,6.35-.37,8.49,2.44,4.72,1.33,7.16-1.33,8.49-6.5-1.99-10.7-2.95-13.51-3.17-5.39-1.03-4.87-4.5-2.29-8.86l1.33-4.58c-1.84-3.99-2.36-7.31,1.33-8.49h0Z"
            />
            <path
              id="Phalangen_overlay_4.3"
              data-name="Phalangen overlay 4.3"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M178.16,143.18v-.07s-.07,0-.07,0c-2.44-8.72-2.07-14.26,5.24-12.04,3.32,1.03,6.5.29,7.9,1.77,7.24,1.33,9.75,6.43,5.91,16.32-12.55,13.66-9.52,29.09-7.9,44.3,3.39,16.17,1.18,18.68-4.58,12.77-3.62-4.8-7.9-5.03-12.48-2.81-7.16,5.54-7.23.74-5.24-7.09,6.2-16.54,10.26-34.11,11.22-53.16h0Z"
            />
          </g>
          <g id="Phalangen_overlay_5" data-name="Phalangen overlay 5">
            <path
              id="Phalangen_overlay_5.1"
              data-name="Phalangen overlay 5.1"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M213.02,139.86h0c-2.66-3.17-1.85-7.09.66-11.37,4.94-6.28,8.78-6.79,10.85,1.77,4.73,6.05,4.95,10.26,0,12.4,3.62,10.92,3.25,15.35.37,14.91l-12.85-1.03c-5.24-2.81-4.35-6.06-.66-9.6,3.61-2.29,3.24-4.65,1.62-7.09h.01Z"
            />
            <path
              id="Phalangen_overlay_5.2"
              data-name="Phalangen overlay 5.2"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M209.82,160.08h-.07c4.36-3.32,9.75-3.84,16.47-1.03-1.03,6.27-1.33,10.56-.96,13.14,6.35,3.98,5.1,8.19,2.66,12.4-6.35,1.25-13.44.66-20.75-.37-3.1-3.18-4.8-6.35,0-9.23,2-4.43,3.03-9.3,2.66-14.91h-.01Z"
            />
            <path
              id="Phalangen_overlay_5.3"
              data-name="Phalangen overlay 5.3"
              class="phalangen1428Fill"
              style={{
                fill: props.colors.phalangen1428Fill,
                stroke: props.colors.phalangen1428Fill,
                opacity: props.colors.phalangen1428Fill,
              }}
              d="M205.78,193.75h0c-.52-7.61,1.11-10.41,5.24-7.09l9.52,1.03c5.24-3.02,7.46-1.03,5.61,7.83-7.6,13.3-10.56,26.29-7.9,38.99,1.1,9.89-1.04,12.48-4.95,11-1.18-4.88-5.24-6.13-11.52-4.58-6.87,3.02-8.49.88-3.62-7.83,5.17-9.74,7.24-23.48,7.61-39.35h0Z"
            />
          </g>
        </g>
        <g id="joint_overlay" data-name="joint overlay">
          <ellipse
            id="joint_overlay_1"
            data-name="joint overlay 1"
            class="gelenke1428Fill"
            style={{
                fill: props.colors.gelenke1428Fill,
                stroke: props.colors.gelenke1428Fill,
                opacity: props.colors.gelenke1428Fill,
              }}
            cx="35.69"
            cy="71.62"
            rx="24.6"
            ry="9.21"
          />
          <ellipse
            id="joint_overlay_2"
            data-name="joint overlay 2"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="31.12"
            cy="170.84"
            rx="29.16"
            ry="10.18"
            transform="translate(-13.09 2.91) rotate(-4.43)"
          />
          <ellipse
            id="joint_overlay_3"
            data-name="joint overlay 3"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="97.65"
            cy="160.93"
            rx="23.39"
            ry="9.08"
          />
          <ellipse
            id="joint_overlay_4"
            data-name="joint overlay 4"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="103.36"
            cy="85.81"
            rx="7.72"
            ry="20.16"
            transform="translate(9.72 182.13) rotate(-85.51)"
          />
          <ellipse
            id="joint_overlay_5"
            data-name="joint overlay 5"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="110.6"
            cy="40.1"
            rx="6.31"
            ry="18.38"
            transform="translate(61.96 147.22) rotate(-85.51)"
          />
          <ellipse
            id="joint_overlay_6"
            data-name="joint overlay 6"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="153.9"
            cy="68.24"
            rx="6.53"
            ry="15.5"
            transform="translate(66.74 212.26) rotate(-82.73)"
          />
          <ellipse
            id="joint_overlay_7"
            data-name="joint overlay 7"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="150.9"
            cy="99.75"
            rx="6.52"
            ry="15.17"
            transform="translate(39.64 242.37) rotate(-85.51)"
          />
          <ellipse
            id="joint_overlay_8"
            data-name="joint overlay 8"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="141.35"
            cy="177.36"
            rx="8.04"
            ry="18.99"
            transform="translate(-46.53 304.39) rotate(-85.51)"
          />
          <ellipse
            id="joint_overlay_9"
            data-name="joint overlay 9"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="188.79"
            cy="104.84"
            rx="13.37"
            ry="5.09"
          />
          <ellipse
            id="joint_overlay_10"
            data-name="joint overlay 10"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="187.46"
            cy="130.58"
            rx="5.15"
            ry="15.01"
            transform="translate(42.61 307.25) rotate(-85.51)"
          />
          <ellipse
            id="joint_overlay_11"
            data-name="joint overlay 11"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="177.53"
            cy="205.65"
            rx="6.42"
            ry="14.57"
            transform="translate(-41.39 366.54) rotate(-85.51)"
          />
          <ellipse
            id="joint_overlay_12"
            data-name="joint overlay 12"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="218.49"
            cy="155.33"
            rx="12.08"
            ry="5.6"
          />
          <ellipse
            id="joint_overlay_13"
            data-name="joint overlay 13"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="217.04"
            cy="185.3"
            rx="15.11"
            ry="6.69"
          />
          <ellipse
            id="joint_overlay_14"
            data-name="joint overlay 14"
            class="gelenke1428Fill"
            style={{
              fill: props.colors.gelenke1428Fill,
              stroke: props.colors.gelenke1428Fill,
              opacity: props.colors.gelenke1428Fill,
            }}
            cx="207.17"
            cy="244.21"
            rx="6.38"
            ry="15.65"
            transform="translate(-52.5 431.63) rotate(-85.51)"
          />
        </g>
      </g>
    </svg>
  );
}

export default RechterVorfussDplLiegend1428;
