import style from "./style.scss";

function RechtesOberesSprunggelenkStressaufnahmeLatLiegend1468(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 381.71 473.56">
 
 <g id="upper_ankle" data-name="upper ankle">
   <g id="background_layer" data-name="background layer">
     <path id="background1" class="oberes_Sprunggelenk1468_Stressaufnahme_lat_rechts_cls3" d="M162.5,11.52l-43.1,166.71c-4.81,18.59-6.71,37.86-5.14,57,2.44,29.83,12.21,53.2,33.26,66.61,4.04,2.58,3.25,8.64-1.31,10.12-30.97,10.05-51.9,33.03-65.27,65.73-2,4.9-5.76,8.89-10.53,11.18l-51.8,24.86c-12.01,5.77-19.51,18.25-18.52,31.54.25,3.28.82,6.55,1.72,9.8,2.43,8.76,10.15,15.02,19.22,15.61,111.58,7.26,222.74-.68,344.83-7.73,7.72-.45,13.82-6.79,13.87-14.53.03-4.09-.75-8.79-2.93-14.58l-12.27-15.92c-6.68-8.67-8.65-20.15-4.98-30.46,6.23-17.48,9.37-38.8,10.43-62.71.23-5.2.91-10.37,1.9-15.49,4.42-22.98,4.26-57.2-2.24-85.96-3.04-13.43-3.89-27.26-2.72-40.97l14.75-171.94c.48-5.59-3.93-10.39-9.54-10.39h-194.77c-7,0-13.11,4.73-14.86,11.5Z"/>
   </g>
   <path id="fibulua" class="oberes_Sprunggelenk1468_Stressaufnahme_lat_rechts_cls5" d="M370.27,1.74c-15.47,84.72-24.21,228.33-23.53,313.24,8.84,17,8.16,37.27-2.69,61.09-9.03,11.53-13.74,26.7-15.85,44.19-18.44,33.04-47.55,32.08-84.05,7.68-24.59-16.81-32.27-43.32-27.86-76.94-4.03-30.74,7.59-48.89,28.91-58.98,32.08-86.55,49.18-184.04,57.92-287.59"/>
   <path id="tibia" class="oberes_Sprunggelenk1468_Stressaufnahme_lat_rechts_cls5" d="M322.14.01c-1.63,73,3.84,141.59,18.83,204.12.58,2.31,1.54,4.61,2.88,6.63,13.83,20.84,23.63,45.34,20.36,82.22-4.8,19.02-15.37,27.09-28.62,30.07-3.75.86-7.59.1-10.85-1.92-46.78-29.59-90.29-36.6-130.25-19.4-11.91,6.34-20.27.77-26.9-10.85-.96-1.73-2.31-3.27-3.84-4.61-14.5-12.01-20.94-30.74-26.03-50.53-6.15-36.12,5.38-73.48,22.38-111.33,17-41.02,23.44-82.13,21.32-123.34"/>
   <path id="talus" class="oberes_Sprunggelenk1468_Stressaufnahme_lat_rechts_cls5" d="M152.99,463.39l-13.64-7.78c-16.04-4.42-23.25-12.01-18.06-24.01-.67-12.87-8.93-23.63-20.75-33.33-14.02,1.34-16.23-9.32-4.9-33.81,29.2-36.31,70.7-54.75,131.02-45.82,32.76,7.68,57.54,27.66,74.25,60.04,26.03,20.94,22.96,38.33,2.21,53.5-17.67,11.14-34.77,3.84-51.29-14.7-16.33,7.2-29.68,17.67-38.23,33.33-13.06,23.73-35.54,21.8-60.61,12.58h0Z"/>
   <path id="calcaneus" class="oberes_Sprunggelenk1468_Stressaufnahme_lat_rechts_cls5" d="M376.89,448.21c-23.63-1.92-42.75-12.1-60.61-24.59-12.87-3.94-26.13-4.13-39.86.48-4.23,1.44-8.07,3.75-11.24,6.72-15.75,14.79-32.08,25.84-49.37,31.51"/>
   <path id="tarsus" class="oberes_Sprunggelenk1468_Stressaufnahme_lat_rechts_cls5" d="M127.44,459.74c-10.76-5.86-15.08-13.16-15.85-21.32-.77-13.74-7.4-23.53-17.39-31.12-6.92-5.19-16.14-6.05-23.82-2.31l-41.78,20.17c-15.47,6.05-23.73,14.02-19.12,25.65,3.07,4.99,4.32,9.8,3.27,14.22"/>
 </g>
 <g id="overlays">
   <path id="talus_joint_surface_overlay" data-name="talus joint surface overlay" class="Talus_Surface1468Fill" style= {{
             fill: props.colors.Talus_Surface1468Fill,
             stroke: props.colors.Talus_Surface1468Fill,
             opacity:props.colors.Talus_Surface1468Fill,
           }} d="M302.57,380.03c-14.19-29.58-38.65-50.74-75.91-61.4-31.76-6.96-61.95-2.89-90.62,11.93-17.01,10.26-30.4,21.58-40.4,33.89l-7.25,19.71c71.33-36.9,142.73-38.28,214.17-4.13Z"/>
   <path id="tibia_joint_surface_overlay" data-name="tibia joint surface overlay" class="Tibia_Surface1468Fill" style= {{
             fill: props.colors.Tibia_Surface1468Fill,
             stroke: props.colors.Tibia_Surface1468Fill,
             opacity:props.colors.Tibia_Surface1468Fill,
           }} d="M146.61,262.63c5.96,11.47,12.31,19.94,19.03,25.52,6,14.6,16.44,19.74,32.95,11.91,39.26-15.98,81.64-6.24,126.15,21.08,17.67,5.62,31.73-8.57,40.11-25.14-68-21.87-141.3-31.74-218.24-33.36Z"/>
   <path id="upper_ankle_joint_overlay" data-name="upper ankle joint overlay" class="OSG1468Fill" style= {{
             fill: props.colors.OSG1468Fill,
             stroke: props.colors.OSG1468Fill,
             opacity:props.colors.OSG1468Fill,
           }} d="M302.57,380.03c35.28-10.77,50.09-33.22,49.08-64.7-11.15,8.39-22.52,12.51-34.7,1.07-28.46-17.27-58.22-25.29-89.39-23.15l-28.98,6.8c-15.41,8.63-26.28,4.35-32.95-11.91-15.05,8.13-21.75,22.65-24.74,40.02,35.94-15.89,71.11-17.45,105.38-2.45,21.3,6.36,39.44,27.33,56.29,54.31Z"/>
 </g>
</svg>
  );
}

export default RechtesOberesSprunggelenkStressaufnahmeLatLiegend1468;
