import ReactApexChart from "react-apexcharts";
import { useMemo, useState } from "react";
import {
  useExamination,
  useMasKvs,
} from "../../../Utilities/FetchHooks/Ris/RisHooks";
import { useSelector } from "react-redux";
import {
  getDDMMYYY_HHMM,
  getDDMMYYY_HHMM_OBJ,
} from "../../../Utilities/DateTimeUtils/DateTimeUtils";

import "../stylesheets/ChartBox.scss";

const chartColors = {
  red: "#fa3620",
  green: "#32fa20",
  yellow: "#f6fa20",
  blue: "#2074fa",
};

function ChartBox() {
  const selectedImage = useSelector((state) => state.xmStats.selectedImage);
  const [selectedTypeIdx, setSelectedTypeIdx] = useState(0);

  const selectedImageHarmoId = selectedImage?.mas_kv_collection[0]?.exams?.id
    ? selectedImage?.mas_kv_collection[0]?.exams?.id
    : 0;

  const harmoExamQ = useExamination({
    examId: selectedImageHarmoId,
    enabled: selectedImageHarmoId !== 0,
  });

  const harmoExam = harmoExamQ.data;

  const maskvQ = useMasKvs({
    pageSize: 20,
    pageNr: 1,
    filter: {
      and: [
        {
          model: "Exams",
          field: "id",
          op: "eq",
          value: selectedImageHarmoId,
        },
        {
          model: "MAS_KV",
          field: "id",
          op: "!=",
          value: selectedImage?.mas_kv_collection[0]?.id,
        },
        {
          model: "MAS_KV",
          field: "zeitstempel",
          op: "<",
          value: getDDMMYYY_HHMM(
            selectedImage?.mas_kv_collection[0]?.zeitstempel
          ),
        },
      ],
    },
    sort: { model: "MAS_KV", field: "zeitstempel", direction: "asc" },
    enabled: selectedImageHarmoId > 0,
  });
  const { data } = maskvQ;

  const getColors = ({ value }) => {
    let color = chartColors.blue;

    if (selectedTypeIdx === 0) {
      if (harmoExam === undefined) {
        color = chartColors.blue;
      }

      if (value < harmoExam?.masfrom) {
        color = chartColors.yellow;
      }

      if (value >= harmoExam?.masfrom && value <= harmoExam?.mastill) {
        color = chartColors.green;
      }

      if (value > harmoExam?.mastill) {
        color = chartColors.red;
      }
    }
    if (selectedTypeIdx === 1) {
      if (harmoExam === undefined) {
        color = chartColors.blue;
      }

      if (value < harmoExam?.kvfrom) {
        color = chartColors.yellow;
      }

      if (value >= harmoExam?.kvfrom && value <= harmoExam?.kvtill) {
        color = chartColors.green;
      }

      if (value > harmoExam?.kvtill) {
        color = chartColors.red;
      }
    }
    if (selectedTypeIdx === 2) {
      if (harmoExam === undefined) {
        color = chartColors.blue;
      }

      if (value <= harmoExam?.cgyqm) {
        color = chartColors.green;
      }
      if (value > harmoExam?.cgyqm) {
        color = chartColors.red;
      }
    }
    return color;
  };

  const chartOptions = useMemo(() => {
    if (!data?.maskv) {
      return {
        chart: {
          id: "examStatsChart",
        },
        xaxis: {
          categories: [],
        },
      };
    }
    let categories = [];
    data.maskv.forEach((entry) => {
      let date = getDDMMYYY_HHMM_OBJ(entry.zeitstempel);
      categories.push(
        `${date.day}.${date.month} ${date.hour}:${date.minute}:${date.second}`
      );
    });
    categories.push("SELECTED");
    return {
      chart: {
        id: "examStatsChart",
      },
      xaxis: {
        categories,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false,
          trim: true,
          minHeight: 40,
        },
      },
      colors: [
        (data) => getColors(data),
        chartColors.green,
        chartColors.yellow,
      ],
    };
  }, [data, selectedImage, selectedTypeIdx, harmoExam]);

  const chartData = useMemo(() => {
    if (!data?.maskv || selectedImageHarmoId === 0) {
      return [
        {
          name: "no data",
          data: [],
        },
      ];
    }

    let chartData = [];
    let types = ["mAs", "kV", "mGy"];
    let columns = [];

    let isHarmoExamValid = harmoExam !== undefined;

    if (selectedTypeIdx === 0) {
      data.maskv.forEach((entry) => {
        columns.push(entry.mas);
      });
      columns.push(selectedImage?.mas_kv_collection[0]?.mas);
      chartData.push({
        name: types[selectedTypeIdx],
        type: "column",
        data: columns,
      });
      if (isHarmoExamValid) {
        chartData.push(
          {
            name: "mAs from",
            type: "line",
            data: Array(columns.length).fill(harmoExam.masfrom),
          },
          {
            name: "mAs till",
            type: "line",
            data: Array(columns.length).fill(harmoExam.mastill),
          }
        );
      }
      return chartData;
    }

    if (selectedTypeIdx === 1) {
      data.maskv.forEach((entry) => {
        columns.push(entry.kv);
      });
      columns.push(selectedImage?.mas_kv_collection[0]?.kv);

      chartData.push({
        name: types[selectedTypeIdx],
        type: "column",
        data: columns,
      });
      if (isHarmoExamValid) {
        chartData.push(
          {
            name: "kV from",
            type: "line",
            data: Array(columns.length).fill(harmoExam.kvfrom),
          },
          {
            name: "kV till",
            type: "line",
            data: Array(columns.length).fill(harmoExam.kvtill),
          }
        );
      }
      return chartData;
    }

    if (selectedTypeIdx === 2) {
      data.maskv.forEach((entry) => {
        columns.push(entry.dosis);
      });
      columns.push(selectedImage?.mas_kv_collection[0]?.dosis);

      chartData.push({
        name: types[selectedTypeIdx],
        type: "column",
        data: columns,
      });
      if (isHarmoExamValid) {
        chartData.push({
          name: "uGy target",
          type: "line",
          data: Array(columns.length).fill(harmoExam.cgyqm),
        });
      }
      return chartData;
    }
  }, [data, selectedImage, selectedTypeIdx, harmoExam]);

  return (
    <div className="d-flex flex-column" style={{ gap: "5px" }}>
      {selectedImage && (
        <>
          <div className="selection-box">
            <div
              className={`selection-item ${
                selectedTypeIdx === 0 && "selected"
              }`}
              onClick={() => setSelectedTypeIdx(0)}
            >
              mAs
            </div>
            <div
              className={`selection-item ${
                selectedTypeIdx === 1 && "selected"
              }`}
              onClick={() => setSelectedTypeIdx(1)}
            >
              kV
            </div>
            <div
              className={`selection-item ${
                selectedTypeIdx === 2 && "selected"
              }`}
              onClick={() => setSelectedTypeIdx(2)}
            >
              mGy
            </div>
          </div>
          <ReactApexChart
            height={"450px"}
            series={chartData}
            options={chartOptions}
            type="line"
            className="bg-light rounded"
          />
        </>
      )}
    </div>
  );
}

export default ChartBox;
