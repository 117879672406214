import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const Chest = map.Chest;

function LeftView({ bpartSetter, selected }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.54 197.21">
      <g id="thorax_laterally_right" data-name="thorax laterally right">
        <g id="costal_cartilage" data-name="costal cartilage">
          <path
            id="costal_cartilage_1_"
            data-name="costal cartilage (1)"
            class="stroke clear-fill"
            d="M13.74,109.54c-2.17,1.94-3.06,4.2-1.69,7.03-.74,.72-1.44,.9-1.93-1.41-1.66-4.69-2.12-3.17-1.73,2.81-.14,3.32,.19,6.27,2.39,7.73-1.17,3.28-2.12,6.5-.66,9-2.05,1.14-3.28,.47-3.84-1.69-3.31-6.66-3.04-3.82-.98,3.8,1.61,4.27,3.21,6.56,4.82,6.89-.64,2.52-.03,4.82,1.93,6.89-2.38,.17-4.35-.61-5.77-2.67-1.06-.91-1.57-.67,0,3.94,2.65,5.16,5.53,7.3,8.58,7.45-.14,2.91,.29,5.11,1.97,5.77-1.42,.61-3.52,.05-6.05-1.27,3.19,4.66,6.64,7.62,10.55,7.59-.93,3.81-.6,6.15,1.12,6.89-3.22-.28-6.7-1.57-10.69-4.78-3.34-6.28-6.85-12.44-8.3-20.11-1.48-6.11-2.23-13.27-1.83-22.08-1.45-3.07-1.55-7.51,0-13.64,.94-3.51,2.78-7.11,6.07-10.83l2.41-8.01,1.89-2.3c-.18,6.26,.19,11.11,1.73,12.99h.01Z"
          />
        </g>
        <g id="sternum">
          <path
            id="sternum_3"
            data-name="sternum 3"
            class="stroke clear-fill"
            d="M54.39,16.03c-1.08,1.93-1.17,3.63,0,5.06-2.96,2.08-3.82,4.27-1.83,6.61-2.37,2.51-5.6,5.94-9.14,9.7-3.22,2.43-4.86,5.09-3.66,8.16-3.45,2.62-6.08,5.82-7.73,9.7l-1.69-.56c-1.92,.64-3.49,1.73-3.38,4.92-.12,1.3,.44,2.11,1.55,2.53-1.1,4.36-2.41,8.24-4.64,9.98,0-1.08-.49-1.67-1.55-1.69-2.49,1.61-4.31,3.73-3.8,7.59,.61,1.47,1.57,2.08,2.81,1.97-2.07,1.9-3.67,4.31-3.8,8.3l-1.12-1.55c-1.53,.36-2.91,1.75-3.94,5.62-.94,1.31-.61,2.57,.28,3.8-.88,.49-1.76,.82-2.63,1.03-1.3,.31-2.58,.32-3.84,.1,2.95-4.97,5.02-9.93,5.48-14.91l3.23-6.61c3.58-4.72,5.48-9.34,6.33-13.92,1.77-3.83,3.57-7.69,6.75-12.94l4.22-9.98c8.59-6.1,12.78-11.77,15.61-20.95,2.33-2.31,4.45-2.61,6.47-1.97h.02Z"
          />
          <line
            id="sternum_2"
            data-name="sternum 2"
            class="stroke clear-fill"
            x1="35.57"
            y1="36.45"
            x2="40.08"
            y2="40.93"
          />
          <path
            id="sternum_"
            data-name="sternum !"
            class="stroke clear-fill"
            d="M6.28,107.83c1.82,.01,3.02-4.06,3.84-10.64l-3.84,.1c-1.32,4.79-1.3,8.29,0,10.55h0Z"
          />
        </g>
        <path
          id="collarbone"
          class="stroke clear-fill"
          d="M93.75,14.19c-2.84-1.64-5.79-1.64-8.86-.28-1.54-.14-2.53-.98-5.2-.42-5.52,1.98-10.71,3.83-12.94,4.64-2.89,1.45-5.92,2.67-9.14,3.52-3.8-.93-5.19-2.27-3.89-4.07,5.94-3.37,11.13-5.39,15.42-5.85,2.8-.41,10.55-2.46,10.55-2.46,0,0,12.54-1.67,14.06,0,4.78,1.8,4.6,3.43,0,4.92h0Z"
        />
        <g id="rib_1" data-name="rib 1">
          <path
            id="rib_1_2_"
            data-name="rib 1 (2)"
            class="stroke clear-fill"
            d="M56.92,27.28c10.13,1.31,19.12-1.97,27.14-9.14,.9-.51,1.12-1.64,.84-3.22-1.25-1.44-2.62-2.1-4.18-1.58-7.67,6.11-15.37,9.35-23.11,8.31-1.93,1.92-2.29,3.8-.7,5.62h0Z"
          />
          <path
            id="rib_1_1_"
            data-name="rib 1 (1)"
            class="stroke clear-fill"
            d="M55.91,27.54l1-.28c-1.41-1.35-1.55-3.11,.7-5.62-1,.06-2.14-.17-3.7-1.29-2.19,2.47-3.44,4.92-1.37,7.34,1.18,.05,2.31,0,3.36-.14h.01Z"
          />
        </g>
        <g id="rib_2" data-name="rib 2">
          <path
            id="rib_2_2_"
            data-name="rib 2 (2)"
            class="stroke clear-fill"
            d="M78.97,29.8c3.51-1.87,4.67-.43,2.53,5.62-5.37,3.55-10.59,6.69-14.62,6.61-4.21,1.05-8.53,2.16-13.65,3.66-4.62,1.02-9.22,2.02-11.24,0-.56-1.93,.81-4.04,3.79-6.28,.31-.24,.64-.47,.99-.71,.66-.01,1.32-.03,1.98-.07,9.63-.53,19.74-3.67,30.23-8.83h0Z"
          />
          <path
            id="rib_2_1_"
            data-name="rib 2 (1)"
            class="stroke clear-fill"
            d="M43.42,37.38c-3.17,1.95-4.43,4.66-3.66,8.16l2.25,.14c.07-3.17,1.87-5.39,4.78-6.99l-3.38-1.3h0Z"
          />
        </g>
        <g id="rib_3" data-name="rib 3">
          <path
            id="rib_3_2_"
            data-name="rib 3 (2)"
            class="stroke clear-fill"
            d="M82.58,45.13c.19,2.99,1.71,4.18,3.57,4.92-4.16,3.74-8.78,6.51-14.06,7.88-15.34,2.54-35.35,7.46-40.64,4.92-2.27-2.52,2.28-6.68,2.67-6.89,16.3,.63,32.43-3.79,48.47-10.83h-.01Z"
          />
          <path
            id="rib_3_1_"
            data-name="rib 3 (1)"
            class="stroke clear-fill"
            d="M30.72,63.55c-.98-2.78-.38-5.38,3.41-7.6l-3.8-1.27c-3,1.38-4.17,3.5-3.02,6.58l3.02,1.58"
          />
        </g>
        <g id="rib_4" data-name="rib 4">
          <path
            id="rib_4_2_"
            data-name="rib 4 (2)"
            class="stroke clear-fill"
            d="M98.95,61.58c-27.97,12.65-58.52,22.19-76.5,19.27-.88-3.74,.24-5.85,2.67-6.89h1.75c30.63-.15,55.09-10.93,67.44-17.37,3.28,1.68,4.86,3.34,4.64,4.99Z"
          />
          <path
            id="rib_4_1_"
            data-name="rib 4 (1)"
            class="stroke clear-fill"
            d="M25.71,73.95c-2.45-.13-3.65,1.98-3.26,6.89l-3.94-2.81c-.49-3.42,.86-5.91,3.8-7.59l3.4,3.52h0Z"
          />
        </g>
        <g id="rib_5" data-name="rib 5">
          <path
            id="rib_5_2_"
            data-name="rib 5 (2)"
            class="stroke clear-fill"
            d="M104.58,67.92c1.95,1.73,3.09,3.56,3.66,5.48-.49,.82-1.73,1.9-3.66,3.23-21.74,7.85-42.62,16.53-58.64,18.42-9.39,2.44-18.36,6.05-29.95,2.4-.81-1.53-.16-3.55,2.43-6.2,28.54,.94,57.29-8.58,86.16-23.34h0Z"
          />
          <path
            id="rib_5_1_"
            data-name="rib 5 (1)"
            class="stroke clear-fill"
            d="M18.41,91.25c-2.18,1.42-3.05,3.46-2.43,6.2-1.17,.12-2.28-.09-3.23-1.28-1.21-4.66,.08-7.76,3.66-9.42,1.25,1.49,2.16,2.99,2.01,4.5h-.01Z"
          />
        </g>
        <g id="rib_6" data-name="rib 6">
          <path
            id="rib_6_1_"
            data-name="rib 6 (1)"
            class="stroke clear-fill"
            d="M110.91,80c1.88,1.37,2.83,3.07,2.95,5.06-13.8,8.08-27.15,16.4-46.55,21.23-11.39,3.15-21.92,6.05-30.09,8.3-8.88,1.76-17.36,2.62-25.17,1.97-1.58-2.4-.57-4.89,2.39-7.45,7.91,1.08,17.46-1.14,27.44-4.22,17.72-1.53,41.78-11.18,69.03-24.89h0Z"
          />
        </g>
        <g id="rib_7" data-name="rib 7">
          <path
            id="rib_7_1_"
            data-name="rib 7 (1)"
            class="stroke clear-fill"
            d="M114.56,91.11c1.31,.12,2,1.78,2.39,4.22-.21,2.3-1.13,3.94-3.09,4.64l-1.35,.67c-10.88,5.69-21.59,11.5-34.79,17.33-17.71,6.18-39,14.53-45.42,13.92-8.38,1.48-17.58,3.4-22.18,2.81-1.45-2.26-1.1-5.32,.66-9,6.99,2.01,18.1-.11,29.81-2.81l44.44-15.05c9.09-3.34,19.18-9.65,29.53-16.73h0Z"
          />
        </g>
        <g id="rib_8" data-name="rib 8">
          <path
            id="rib_8_1_"
            data-name="rib 8 (1)"
            class="stroke clear-fill"
            d="M96.7,116.77c3.3-.39,8.67-3.21,13.36-7.81,3.1-2.7,4.02-6.25,3.8-9l1.41,7.88c-2.51,5.33-5.36,9.68-9.42,10.69-13.21,6.13-20.94,13.73-71.72,28.12l-22.08,3.94c-2.36-2.8-2.54-4.96-1.93-6.89,12.69,.36,24.47-.72,33.85-5.62,21.77-7.61,47.44-15.7,52.73-21.3h0Z"
          />
        </g>
        <g id="rib_9" data-name="rib 9">
          <path
            id="rib_9_1_"
            data-name="rib 9 (1)"
            class="stroke clear-fill"
            d="M64.37,145.95c19.3-6.84,31.01-11.22,40.22-18.7,1.65-1.29,2.21-4.83,2.4-9.11,.85,.49,1.74,2.61,2.66,6.02-.66,5.87-3.09,11.24-12.52,14.62-12.85,6.6-25.72,12.03-38.64,15.19-16.23,5.31-36.03,13.06-41.66,11.11-1.53-1.26-2.01-3.32-1.97-5.77,5.86-.69,12.7-1.42,19.27-3.94,8.18-4.47,18.8-7.23,30.23-9.42h.01Z"
          />
        </g>
        <g id="rib_10" data-name="rib 10">
          <path
            id="rib_10_1_"
            data-name="rib 10 (1)"
            class="stroke clear-fill"
            d="M56.19,163.11c11.63-3.66,24.52-9.44,37.97-16.17,3.54-1.81,4.27-4.09,4.12-9.52,3.55,2.44,5.79,5.36,2.35,10.36-11.49,8.12-22.57,14.22-33.05,17.3-17.1,7.82-33.17,13.92-45.14,13.22-2.15-1.29-1.91-3.94-1.12-6.89,10.5,.54,22.39-3.01,34.88-8.3h0Z"
          />
        </g>
        <g id="rib_11" data-name="rib 11">
          <path
            id="rib_11_1_"
            data-name="rib 11 (1)"
            class="stroke clear-fill"
            d="M79.55,168.31c4.08-2.35,6.71-6.54,8.17-12.2l3.44-2.05c1.76,2.3,2.52,5.11-1.77,10.46-4.8,5.08-9.85,9.97-19.41,11.25-11.61,4.08-26.01,10.06-31.78,10.55-1.09-.77-1.52-1.91-.84-3.66l12.8-3.66,29.39-10.69h0Z"
          />
          <path
            id="rib_11_1_-2"
            data-name="rib 11 (1)"
            class="stroke clear-fill"
            d="M29.34,185.34c1.46,.98,4.13,1.31,8.02,.98v-3.66c-3.64,.39-6.59,1.14-8.02,2.67h0Z"
          />
        </g>
        <g id="rib_12" data-name="rib 12">
          <path
            id="rib_12_2_"
            data-name="rib 12 (2)"
            class="stroke clear-fill"
            d="M51.29,195.46c16.44-6.52,24.02-14.77,29.47-23.44-3.17,1.92-6.1,3.31-8.41,3.34-3.55,5.82-10.38,11.54-21.77,17.14-.23,1.79,.11,2.59,.7,2.95h.01Z"
          />
          <path
            id="rib_12_1_"
            data-name="rib 12 (1)"
            class="stroke clear-fill"
            d="M45.08,196.43c2.07,.33,4.13,0,6.19-.98l-.7-2.95c-1.7-.57-2.74,.17-5.48,3.94h-.01Z"
          />
        </g>
        <g id="thoracic_vertebrae" data-name="thoracic vertebrae">
          <polygon
            id="thoracic_vertebrae_20"
            data-name="thoracic vertebrae 20"
            class="stroke clear-fill"
            points="98.97 60.88 104.58 67.91 95.27 72.48 92.78 71.7 92.78 64.3 92.78 63.55 98.97 60.88"
          />
          <path
            id="thoracic_vertebrae_19"
            data-name="thoracic vertebrae 19"
            class="stroke clear-fill"
            d="M91.12,81.87c3.87-1.32,10.36-4.79,17.11-8.48l2.67,6.61-21.2,10.09c-.47-2.82,0-5.56,1.43-8.21h0Z"
          />
          <path
            id="thoracic_vertebrae_18"
            data-name="thoracic vertebrae 18"
            class="stroke clear-fill"
            d="M87.16,99c4.87-1.05,15.88-7.58,26.71-13.94l.7,6.05c-10.75,7.52-21.15,13.87-30.52,16.73,.19-2.14,1.35-5.21,3.1-8.84h.01Z"
          />
          <path
            id="thoracic_vertebrae_17"
            data-name="thoracic vertebrae 17"
            class="stroke clear-fill"
            d="M81.02,116.79l33.54-16.81c.55,6.38-5.56,13.99-36.57,26.14,.39-2.6,1.29-5.62,3.03-9.34h0Z"
          />
          <path
            id="thoracic_vertebrae_16"
            data-name="thoracic vertebrae 16"
            class="stroke clear-fill"
            d="M76.11,133.71l30.87-15.57c.53,3.38-.15,6.45-2.4,9.11-7.44,6.37-18.94,10.92-31.09,15.17-.01-3.16,.68-6.12,2.62-8.71h0Z"
          />
          <path
            id="thoracic_vertebrae_15"
            data-name="thoracic vertebrae 15"
            class="stroke clear-fill"
            d="M67.54,151.41l30.77-13.07-2.01,7.15c-9.29,5.2-18.23,9.44-26.73,12.4-1.76,.5-3.08,.11-3.8-1.53,.29-2.68,1.69-4.24,1.77-4.96h0Z"
          />
          <path
            id="thoracic_vertebrae_14"
            data-name="thoracic vertebrae 14"
            class="stroke clear-fill"
            d="M87.34,157.45l-6.59,2.39c-1.06,1.74-1.41,3.27,0,4.25l2.75,.86c1.77-2.1,2.96-4.68,3.84-7.5Z"
          />
          <path
            id="thoracic_vertebrae_13"
            data-name="thoracic vertebrae 13"
            class="stroke clear-fill"
            d="M91.59,161.21c.99-.09,2.65,1.39,4.7,3.75,.94,3.22,.9,5.09,0,5.75-2.62-2.64-5.67-3.74-8.95-4.07l4.25-5.43Z"
          />
          <path
            id="thoracic_vertebrae_12"
            data-name="thoracic vertebrae 12"
            class="stroke clear-fill"
            d="M92.56,154.06l3.73-3.3c2.9,.68,3.77,3.61,3.92,7.33-3.35-.74-5.92-2.07-7.65-4.03h0Z"
          />
          <path
            id="thoracic_vertebrae_11"
            data-name="thoracic vertebrae 11"
            class="stroke clear-fill"
            d="M102.35,144c-.21-2.84-1.1-4.77-2.14-6.5,3.13,.69,5.93,1.49,6.77,2.98,.75,2.65,.72,4.36,0,5.2-2.32-1.71-3.99-2.45-4.63-1.69h0Z"
          />
          <path
            id="thoracic_vertebrae_10"
            data-name="thoracic vertebrae 10"
            class="stroke clear-fill"
            d="M109.65,124.16l-1.1,4.79c.56-.56,1.88,.57,3.49,2.38,1.14-.97,1.49-2.49,1.12-4.5-2.24-2.11-2.83-2.15-3.52-2.67h.01Z"
          />
          <path
            id="thoracic_vertebrae_9"
            data-name="thoracic vertebrae 9"
            class="stroke clear-fill"
            d="M93.89,119.08l4.42-2.04c-3.16-2.73-6.21-4.88-8.78-4.57l-5.48,2.62c2.83,1.85,6.04,3.26,9.84,4h0Z"
          />
          <path
            id="thoracic_vertebrae_8"
            data-name="thoracic vertebrae 8"
            class="stroke clear-fill"
            d="M100.86,99.97l-4.57,2.55c-2.24-.98-4.97-2.17-8.05-3.52l4.54-1.8c3.34-.51,5.95,.61,8.09,2.77h-.01Z"
          />
          <path
            id="thoracic_vertebrae_7"
            data-name="thoracic vertebrae 7"
            class="stroke clear-fill"
            d="M105.95,112.47c.95-2.04-.17-4.43-3.59-7.3l5.88-2.03c.62-.27,2.1,1.07,4.15,3.51l-6.44,5.82Z"
          />
          <path
            id="thoracic_vertebrae_6"
            data-name="thoracic vertebrae 6"
            class="stroke clear-fill"
            d="M105.85,118.53l-6.87,4.14c-.33,1.03,2.26,2.2,6.52,3.44,1.38-3.46,1.84-6.24,.35-7.58Z"
          />
          <path
            id="thoracic_vertebrae_5"
            data-name="thoracic vertebrae 5"
            class="stroke clear-fill"
            d="M84.79,151.52c-1.78-1.12-3.91-2.37-6.79-3.91l2.76-1.82c3.59-.15,6.67,1.1,9.48,3.1l-5.45,2.63Z"
          />
          <path
            id="thoracic_vertebrae_4"
            data-name="thoracic vertebrae 4"
            class="stroke clear-fill"
            d="M90.24,134.25c1.5,1.02-.53,2.48-4.59,2.24-2.14-.13-4.91-1.31-8.24-3.43l3.35-1.19c1.21-1.85,5.45-.11,9.48,2.38h0Z"
          />
          <path
            id="thoracic_vertebrae_3"
            data-name="thoracic vertebrae 3"
            class="stroke clear-fill"
            d="M112.4,92.59l-2.75,1.89c-1.03-1.44-3.35-2.39-7.25-2.75l6.15-3.54c2.17,.56,3.3,2.17,3.84,4.4h0Z"
          />
          <path
            id="thoracic_vertebrae_2"
            data-name="thoracic vertebrae 2"
            class="stroke clear-fill"
            d="M114.88,108.65l-2.5,4.45c1.35,1.31,2.8,2.72,4.57,4.45,1.32,.51,1.88-.78,1.41-4.51-1-3.01-2.19-4.13-3.48-4.4h0Z"
          />
          <path
            id="thoracic_vertebrae_1"
            data-name="thoracic vertebrae 1"
            class="stroke clear-fill"
            d="M122.85,103.63c-.6-2.78-2.4-5.54-4.92-8.3-.26-1.21,.05,.17-2.48,3.66,1.08,2.68,2.5,4.56,4.03,6.19,1.35,.51,2.47-.03,3.38-1.55h-.01Z"
          />
        </g>
        <g id="shoulder">
          <path
            id="shoulder_9"
            data-name="shoulder 9"
            class="stroke clear-fill"
            d="M92.76,3.09l-.98,5.09h-5.62c.84-.51,1.93-1.44,.14-3.97-1.05,.85-1.79,1.03-1.97,0-2.07-2.56-.27-3.03,1.27-3.38,.78,.99,1.68,1.49,2.81,1.12l1.27-1.12c1.97-.73,3.25-.38,3.09,2.25h-.01Z"
          />
          <path
            id="shoulder_8"
            data-name="shoulder 8"
            class="stroke clear-fill"
            d="M83.4,27.54l-.8,1.49-1.08,6.39,.28,1.2c-.74,3.76,.45,6.73,3.21,9.07,3.53,3.7,6.09,2.91,7.77-1.97-.47-4.05-.87-7.54-1.14-9.84-1.04-4.5-2.8-7.98-8.25-6.33h0Z"
          />
          <path
            id="shoulder_7"
            data-name="shoulder 7"
            class="stroke clear-fill"
            d="M94.54,32.46c-2.27-3.1-4.88-5.79-9.55-5.91l-3.49,1.69c-1.97,.1-3.61-.39-4.5-2.25-.7-1.72,.13-2.69,2.11-3.09,3.14,1.91,6.19,2.49,9.12,1.12,2.55-2.02,4.16-.26,5.23,3.68,2.01,4.34,4.3,3.23,6.75-.57"
          />
          <path
            id="shoulder_6"
            data-name="shoulder 6"
            class="stroke clear-fill"
            d="M100.86,34.31c.83-3.87-.36-6.81-2.56-9.28-4.41-1.81-8.77-2.99-13.49-7.81l.19-4.06,7.25,.33c1.58,3.07,3.25,5.28,5.01,6.87l-.97-1.94c2.24,.47,2.71-1.29,2.38-4.08,.4-1.4-.58-2.14-1.51-2.9-.73-1.63-.26-2.19,1.14-1.88l2.56,.56,1.53-.56c1.31-1.42,2.54-1.85,3.45,.98l-2.39,4.55-3.24,6.56c13.03,3.29,19.81,9.27,25.46,10.12"
          />
          <path
            id="shoulder_5"
            data-name="shoulder 5"
            class="stroke clear-fill"
            d="M93.92,28.58c1.74-2.8,4.26-3.86,6.95-4.55,8.16,2.56,15.29,8.4,21.99,15.61"
          />
          <path
            id="shoulder_4"
            data-name="shoulder 4"
            class="stroke clear-fill"
            d="M114.57,19.76c.46-1.7-1-3.83-2.18-4.68-.98-1.42-1.9-.97-2.75,1.92-1.82,.49-3.77,1.01-5.82,1.55-1.78,2.17-2.02,3.65,0,4.12l4.42,.24c1.83,2.39,3.7,1.33,5.62-2.56,2.47-2.48,4.73-.83,6.89,2.56,.27,1.98,1.78,4.03,4.36,6.13,6.32,4.02,7.66,11.14,5.91,20.17-2.2,14.12-1.9,28.16,.98,42.13-2.75,12.9-6.42,13.57-10.51,8.79"
          />
          <path
            id="shoulder_3"
            data-name="shoulder 3"
            class="stroke clear-fill"
            d="M94.31,56.59c-2.23-3.24-5.3-5.64-9.31-7.1-1.08-.39-1.97-1.5-2.41-4.36v-2.33"
          />
          <path
            id="shoulder_2"
            data-name="shoulder 2"
            class="stroke clear-fill"
            d="M92.78,2.09c4.81,2.42,8.37,3.07,10.08,1.12l8.05,3.09c.88,1.09,1.37,2.11,0,2.81h-3.52s-1.55,1.41-1.55,1.41c.75,1.33,2.14,1.49,3.8,1.14,.46,.14,2.69,.81,4.92,1.48l6.33,3.29c1.8,1.28,1.57,2.17,0,2.82h-3.94"
          />
          <path
            id="_Pfad_shoulder_1"
            data-name="&amp;lt;Pfad&amp;gt;shoulder 1"
            class="stroke clear-fill"
            d="M79.55,8.52c.87-1.21,.5-2.29-1.55-3.2,.83-2.29,2.63-3.37,5.39-3.23"
          />
        </g>
      </g>
      <g data-name="sternum overlay">
        <path
          id="sternum_overlay_1_"
          data-name="sternum overlay (1)"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Chest.id, Chest.children.sternum),
            });
          }}
          class={`stroke zone ${isSelected(selected, Chest.children.sternum)}`}
          d="M54.03,15.78c-1.96-.49-3.96-.41-6.19,2.25-2.37,8.71-7.58,15.43-15.38,20.34-3.15,9-7.07,17.07-11.53,24.47-1.06,6.5-5.27,14.35-9.09,20.06-1.14,5.46-2.82,10.37-5.34,14.44-1.83,3.75-1.65,7.32-.09,10.78,2.07-6.76,4.28-12.27,5.53-11.53,.63-9.65,3.42-11.72,5.25-9.19-.14-3.22,1.31-5.37,3.47-7.03-7.85-5.58-.13-11.58,3.19-8.34,1.98-1.31,3.4-5.1,4.59-9.84-3.74-2.7-1.62-6.54,3-7.31,2.75-4.74,5.5-8.04,8.25-9.47-1.53-2.99,0-5.86,4.22-8.62,3.1-3.69,6.04-6.95,8.53-9-1.78-2.81-.92-5.14,1.78-7.12-1.35-.81-1.35-2.47-.19-4.88Z"
        />
      </g>
      <g id="12" data-name="collarbone overlay">
        <path
          id="collarbone_overlay_1_"
          data-name="collarbone overlay (1)"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Chest.id, Chest.children.collarboneL),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.collarboneL
          )}`}
          d="M94.53,9.6c3.82,2.26,2.65,3.62-1.13,4.5,.1-1.08-7.26-1.17-8.34-.56-1.16,.65-4.59-.19-4.59-.19-8.16,2.35-16.08,4.81-21.47,8.34-3.98-.69-6.23-1.97-4.5-4.59,4.61-2.35,9.78-4.35,15.66-5.91,9.58-2.15,18.71-3.8,24.38-1.59Z"
        />
      </g>
      <g id="13" data-name="upperribs overlay">
        <path
          id="upperribs_overlay-2"
          data-name="upperribs overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Chest.id, Chest.children.upperRibsL),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.upperRibsL
          )}`}
          d="M78.12,22.44c2.31-1.47,4.46-3.2,6.47-5.16,.6-2.66-.68-4.1-4.03-4.22-7.1,6.18-14.6,9.49-22.69,8.72l-3.56-1.22c-2.94,1.41-3.75,3.83-2.34,7.31l-7.78,9c-4.16,1.91-5.75,4.84-4.69,8.81-2.84,1.93-5.15,5.04-7.22,8.72l-2.06,.28c-3.11,1.27-4.56,3.28-2.81,6.75l.94,.56c-.84,3.75-2.06,7.01-3.84,9.56l-1.97-1.13c-2.45,.76-3.93,3.19-4.13,7.78l2.16,2.16c-1.95,2.07-3.25,4.35-3.09,7.12l-1.03-.66c-3.06,1.12-4.49,4.06-4.22,8.91-.89,5.69-.71,10.35,1.31,13.22-3.25,1.63-3.41,4.32-1.31,7.78-1.81-.42-3.03-1.74-3.37-4.41-1.59,3.99-.97,8.54,1.22,13.5-1.11,3.78-1.35,6.83-.09,8.62,4.77,.96,13.67-.93,22.69-2.91,17.41-2.17,42.49-13.36,67.5-24.47l15-7.87c2.46-4.36,1.9-6.83-.47-8.16l-.56-5.91c-.34-2.81-1.37-4.77-3.75-5.06l-2.06-6.56c-.52-2.54-1.61-4.51-3.66-5.53l-5.25-6.28c-1.03-2.42-2.69-4.3-5.34-5.34-2.32-3.2-5.04-5.45-8.34-6.38-1.8-1.08-3.17-2.53-3.19-5.16-1.3-3.09-1.62-6.18-1.03-9.28,.72-1.68,1-3.63,.75-5.91l.09-1.78c-7.06,.29-6.49-2.25-4.22-5.44Z"
        />
      </g>
      <g id="14" data-name="underribs overlay">
        <path
          id="underribs_overlay-2"
          data-name="underribs overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Chest.id, Chest.children.lowerRibsL),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.lowerRibsL
          )}`}
          d="M114.4,100.63c-3.06,8.66-8.73,14.24-17.63,16.03-11.02,8.3-32.03,14.64-52.5,21.09-11.47,5.18-22.79,7.47-33.94,5.91-1.22,2.01-.74,4.13,1.41,6.38-2.24,1.12-4.3,.44-6.19-2.25-.24,5.64,3.83,8.92,8.62,11.81-.14,1.84,.58,3.69,2.25,5.53l-6.37-1.97c4.3,6.23,7.87,8.41,11.06,8.53-1.06,2.44-1.15,4.55,.47,6.09,9.96,2.53,28.5-5.69,46.41-13.12l19.78-8.06c-1.78,5.56-4.35,9.8-8.34,11.62-16.08,6.05-31.15,11.53-41.44,14.34-3.26,0-6.16,.89-8.72,2.62,2.3,1.5,5.79,1.59,9.75,1.13,5.85-.86,19.81-6.15,32.91-10.97-5.77,6.8-12.25,12.96-21.19,16.87-2.25,.1-4.23,1.49-6,3.94,10.52-.02,26.94-10.33,35.34-22.78,5.26-3.3,9.38-8.2,12.47-14.53,.41-1.51,.13-3.11-.84-4.78,3.49-2.43,6.72-4.81,9.56-7.12,2-5.03-.02-7.37-2.53-9.38,5.95-1.47,9.6-5.81,10.88-13.12-.23-2.23-.81-4.35-2.06-6.28,2.97-1.8,5.57-5.79,8.06-10.41,.23-4.08-.39-5.9-1.22-7.12Z"
        />
      </g>
      <g id="15" data-name="shoulder plate overlay">
        <path
          id="shoulder_plate_overlay_1_"
          data-name="shoulder plate overlay (1)"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Chest.id, Chest.children.shoulderBladeL),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.shoulderBladeL
          )}`}
          d="M120.35,22.72c-2.3-4.4-4.56-5.15-6.75-1.55-1.76,3.14-3.49,3.01-5.2,1.55-2.26-.52-4.13-.75-5.62-.7l-2.95,2.11c-2.84,.91-5.05,2.02-5.63,3.66-1.7-3.62-3.48-6.45-5.77-4.08-3.85,1.8-6.82,1.17-9.14-1.27-3.41,1.16-3.43,2.86-1.13,4.92l4.36,1.41c.8,4.11-.41,5.78-.84,7.31-.79,3.25-.15,5.91,1.12,8.3-.05,2.93,1.12,4.49,2.81,5.48,3.5,.81,6.08,3.15,8.16,6.33,2.92,.85,4.58,2.93,5.77,5.48,1.93,3.58,3.99,4.55,5.2,6.47,1.86,.46,2.86,2.5,3.52,5.2l2.67,6.61c2.85,1.4,3.12,3.39,2.95,5.48l.84,5.06c1.7,1.1,2.36,2.63,2.53,4.36,3.07,1.65,4.69,4.15,5.91,6.89,2.32,1.94,4.37,1.06,6.19-2.39,1.17-2.42,2.06-5.23,2.67-8.44-3.7-10.77-2.66-27.13-.56-44.72,.87-7.48-.7-13.16-5.34-16.59-2.87-1.21-4.83-3.46-5.77-6.89Z"
        />
      </g>
    </svg>
  );
}

export default LeftView;
