import style from "./style.scss";

function LinkesLliosakralgelenkVdOblLiegend1206(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 725.7 725.7"
    >
      <g id="underlay">
        <ellipse
          class="ISG1206Fill"
          style={{
            fill: props.colors.ISG1206Fill,
            stroke: props.colors.ISG1206Fill,
          }}
          cx="411.9"
          cy="282.7"
          rx="116.8"
          ry="33.4"
          transform="translate(43.5 620.1) rotate(-77)"
        />
      </g>
      <g id="BG">
        <path
          id="BG43"
          class="iliosakralgelenk_left1206_cls3"
          d="M376.3,154.8c-2.5-5.6-8.1-4.2-13-12.7-5.2-8.8-1.5-14.3-4.3-33.5-1.7-11.7-2.6-17.5-6.7-21.9-4.4-4.6-8-3.8-20.9-11.5-7-4.2-10.6-6.4-11.5-9.4-2.7-8.6,10.6-15.7,8.5-21.4-2.6-7.2-29.1-10.8-44.1,2.6-17.8,15.9-12.9,50.3-1,56.5,4.7,2.4,9.8.2,11.5,3.1,2.6,4.4-7.4,11.5-12.7,22.4-10.9,22.1-1.9,57.9,15.9,66.6,5.3,2.6,11.4,2.8,14.7,8.4,4.3,7.3-1.4,14.6,1,16.8,6.9,6.2,72-45,62.6-66Z"
        />
        <path
          id="BG42"
          class="iliosakralgelenk_left1206_cls3"
          d="M345.4,82.4c-18.7-9.3-42.3.4-50.8,24.3"
        />
        <path
          id="BG41"
          class="iliosakralgelenk_left1206_cls3"
          d="M294.6,68.9c-9.9,23.8,0,37.7,0,37.7"
        />
        <path
          id="BG40"
          class="iliosakralgelenk_left1206_cls3"
          d="M311,112.9c0,3.5,0,16.7,19.2,12.6"
        />
        <path
          id="BG39"
          class="iliosakralgelenk_left1206_cls3"
          d="M378.5,157.6c-34.1-10.2-67.1,7-55.7,62.2"
        />
        <path
          id="BG38"
          class="iliosakralgelenk_left1206_cls3"
          d="M141.2,628.1c2.8-6.6,19.7.5,51.3-5.2,12.4-2.3,35.3-6.7,55.5-23,11.2-9.1,8-10.8,25.1-28.3,5.2-5.2,20-19.8,41.9-34.6,17.2-11.6,18.9-9.8,38.7-23,17.2-11.4,25.8-17.2,34.6-27.2,4.2-4.8,16.9-20.3,23-44,3.2-12.1,6.8-26.2,1-40.8-3.5-8.9-6.8-8.8-10.5-17.8-1.5-3.8-5.7-15.7,0-39.8,4.9-20.6,9.9-20.2,19.9-47.1,8.6-23.2,13-35,10.5-50.3-1.4-8.5-4.1-13-5.2-26.2-1.1-13.5,1.3-14.4,0-25.1-2.1-18.1-15.7-19.9-8.4-32.5,3.7-6.4,4.1-9.8,15.7-25.1,18.4-24.3,14.6-24.4,28.3-40.8,13.6-16.4,15.4-14,28.3-30.4,11.7-15,11.6-18.7,19.9-25.1,13.7-10.6,29.1-12.1,35.6-12.6,6.4-.5,24.1-1.7,40.8,8.4,16.1,9.7,22.2,24.4,30.4,44,7,16.8,17,40.9,13.6,71.2-1.5,13.7-9.1,29.6-24.1,60.7-12,25-21.6,42.4-36.6,70.2-23.6,43.5-35.5,65.3-36.6,68.1-19.3,44.3-24.6,82-28.3,109.9-6.8,51.3-1.6,75.9,0,82.7,3.8,16,7,29.7,16.8,42.9,2.2,3,4.8,6.1,7.3,11.5,4.7,10.2,4.8,19.7,4.2,31.4-1.3,24.9-1.9,37.4-5.2,42.9-11.8,19.9-39.8,23.4-53.4,25.1-27.3,3.5-30.2-6.2-52.4-1-24.6,5.7-24.4,18.5-49.2,22-21.5,3-39.7-4-42.9-5.2-10.9-4.3-12.2-7.6-23-12.6-16.9-7.7-30.1-7.2-44-8.4,0,0-43.6-3.6-82.7-26.2-19.7-11.2-43.4-30.3-39.9-38.7Z"
        />
        <path
          id="BG37"
          class="iliosakralgelenk_left1206_cls3"
          d="M546.4,29.2c81.2,21.6,64.2,112.6,29.3,151.8"
        />
        <path
          id="BG36"
          class="iliosakralgelenk_left1206_cls3"
          d="M575.7,181c-47.2,64.8-131,122.6-113.1,209.4"
        />
        <path
          id="BG35"
          class="iliosakralgelenk_left1206_cls3"
          d="M462.6,390.4c-2.5,27.2-1.6,65.3-32,96.7"
        />
        <path
          id="BG34"
          class="iliosakralgelenk_left1206_cls3"
          d="M430.6,487.1c-28.7,36.3,9.7,107-28.7,113.8"
        />
        <path
          id="BG33"
          class="iliosakralgelenk_left1206_cls3"
          d="M442.2,472.8c45,20.5,42.3,104-21.9,98.7"
        />
        <path
          id="BG32"
          class="iliosakralgelenk_left1206_cls3"
          d="M570.5,283.6c-32.6,38.2-67.8,87.8-110.3,96.3"
        />
        <path
          id="BG31"
          class="iliosakralgelenk_left1206_cls3"
          d="M524.4,633.4c18.8-98-190.3-26.3-200.6,77.4"
        />
        <path
          id="BG30"
          class="iliosakralgelenk_left1206_cls3"
          d="M462.6,555.4c14.8-2.9,44.6,7.1,59.7,31.9"
        />
        <path
          id="BG29"
          class="iliosakralgelenk_left1206_cls3"
          d="M105.8,347.3c.9.6,1.9,1,2.9,1.3,5.1,1.4,7.4-2.2,22-2.1,7.8.1,17.5.2,27.2,5.2,8.7,4.5,8,8,16.8,12.6,8.7,4.5,13.8,3.4,23,5.2,18.7,3.8,31.3,15.1,35.6,18.8,14.9,13.3,20.9,28.7,23,34.6,4.4,11.8,2.3,13.9,6.3,28.3,6.3,22.8,17.3,38.3,19.9,41.9,8.7,11.9,24.6,33.7,44,32.5,4.6-.3,10.8-3.7,23-10.5,10.5-5.8,18.7-10.4,28.3-18.8,12.7-11.3,19.8-22.5,22-26.2,3-4.9,7.4-12.4,10.5-23,2.3-7.9,8.5-29.5-1-49.2-6.3-13-12.5-11.1-17.8-24.1-5.8-14.3-3.1-28.3-1-37.7,4.1-19.3,10.2-18.7,17.8-40.8,6.5-19,.6-15.3,8.4-49.2,2.8-12.1,6-23.3,0-30.4-3.2-3.8-7.1-4.1-8.4-8.4-1.8-5.8,4.2-9.3,6.3-17.8,2.2-8.8,10.2-14.7-8.4-27.2-9.8-6.6-21.5-5.1-26.2-4.2-10.7,2.1-17.9,8-22,11.5-22.1,18.9-32,34.3-46.1,49.2-8.4,8.9-20.3,21.5-38.7,31.4-24.7,13.3-46.6,13.9-77.5,14.7-26.5.7-37.2-3-45-9.4-8.3-6.9-12.2-15.8-12.6-16.8-4.3-10.3-3.6-20.9-3.1-28.3.7-11.1,2.9-15.5,1-16.8-3.8-2.5-18.6,10.9-27.2,22-1.8,2.3-3.4,4.7-4.9,7.1"
        />
        <path
          id="BG28"
          class="iliosakralgelenk_left1206_cls3"
          d="M361.4,354.8c0-34.1-31.4-64.9-78.8-64.9"
        />
        <path
          id="BG27"
          class="iliosakralgelenk_left1206_cls3"
          d="M310,293.7c-9.5,36.2-72.5,47.7-102.8,4.6"
        />
        <path
          id="BG26"
          class="iliosakralgelenk_left1206_cls3"
          d="M331.7,358c0-3.5-24.4-6.3-54.4-6.3"
        />
        <path
          id="BG25"
          class="iliosakralgelenk_left1206_cls3"
          d="M325.5,365.3c0-4.1-21.5-7.3-48.2-7.3"
        />
        <path
          id="BG24"
          class="iliosakralgelenk_left1206_cls3"
          d="M310,384.2c0,4.6,17.7,8.4,39.6,8.4"
        />
        <path
          id="BG23"
          class="iliosakralgelenk_left1206_cls3"
          d="M310,392.5c0,6.4,20,11.5,44.8,11.5"
        />
        <path
          id="BG22"
          class="iliosakralgelenk_left1206_cls3"
          d="M331.7,436.5c0,1.7,16.4,3.1,36.6,3.1"
        />
        <path
          id="BG21"
          class="iliosakralgelenk_left1206_cls3"
          d="M329.8,441.7c0,5.8,18.4,5.2,38.6,5.2"
        />
        <path
          id="BG20"
          class="iliosakralgelenk_left1206_cls3"
          d="M329.8,488.9c0,8.2,17.3,14.8,38.6,14.8"
        />
        <path
          id="BG19"
          class="iliosakralgelenk_left1206_cls3"
          d="M349.1,455.4c0,3.5,11,6.3,24.6,6.3"
        />
        <path
          id="BG18"
          class="iliosakralgelenk_left1206_cls3"
          d="M135.9,245.9c0,29.3,69.6,39.1,130.9,16"
        />
        <path
          id="BG17"
          class="iliosakralgelenk_left1206_cls3"
          d="M108.7,322.4c0,13.5,14.4,24.5,32.3,24.5"
        />
        <path
          id="BG16"
          class="iliosakralgelenk_left1206_cls3"
          d="M153.7,299.7c0,5.4,10.7,9.8,23.8,9.8s23.8-4.4,23.8-9.8-10.7-17.1-23.8-17.1-23.8,11.7-23.8,17.1Z"
        />
        <path
          id="BG15"
          class="iliosakralgelenk_left1206_cls3"
          d="M301.4,310.1c-.1-1.5,8.9-15,20.6-12,8.6,2.2,22.9,12.2,22.9,21.6s-6.4,7.3-19.6,7.3-23.6-7.5-23.9-16.9h0Z"
        />
        <ellipse
          id="BG14"
          class="iliosakralgelenk_left1206_cls3"
          cx="250.1"
          cy="342.8"
          rx="16.8"
          ry="8.9"
        />
        <path
          id="BG13"
          class="iliosakralgelenk_left1206_cls3"
          d="M331.7,362.2c0-5.8,15.2-20,25.7-10.5s17.9.1,25.7,10.5c9.1,12.2-8.7,16.2-25.7,10.5-13.4-4.6-25.7-4.8-25.7-10.5Z"
        />
        <ellipse
          id="BG12"
          class="iliosakralgelenk_left1206_cls3"
          cx="284.1"
          cy="391.5"
          rx="17.3"
          ry="12.6"
        />
        <ellipse
          id="BG11"
          class="iliosakralgelenk_left1206_cls3"
          cx="386.7"
          cy="404"
          rx="15.2"
          ry="11.3"
        />
        <circle
          id="BG10"
          class="iliosakralgelenk_left1206_cls3"
          cx="394.6"
          cy="453.3"
          r="11.5"
        />
        <circle
          id="BG9"
          class="iliosakralgelenk_left1206_cls3"
          cx="310.4"
          cy="438.1"
          r="11.5"
        />
        <ellipse
          id="BG8"
          class="iliosakralgelenk_left1206_cls3"
          cx="315"
          cy="488.4"
          rx="10.5"
          ry="7.9"
        />
        <path
          id="BG7"
          class="iliosakralgelenk_left1206_cls3"
          d="M304.5,225c3.9-10-8-16.9-7.3-30.4.6-12.2,10.9-20.8,20.9-29.3,2.7-2.3,8.9-7.2,37.7-20.9,23.3-11.1,31.7-13.8,38.7-24.1.8-1.2,9.5-14.2,5.2-19.9-4.7-6.3-22.6-.2-29.3,2.1-12.1,4.1-21.9,10.2-40.8,22-9.8,6.1-14.7,9.6-23,10.5-3.1.3-12,.9-20.9-4.2-2.4-1.4-13.1-7.4-12.6-14.7.4-6.1,8.2-6.8,9.4-13.6,1.1-6.1-4.6-8.6-7.3-16.8-3.4-10.1-.5-23.1,6.3-30.4,2.8-3,5.9-4.7,31.4-9.4,18.4-3.4,22.2-3.5,34.6-6.3,13.7-3.1,22.6-6.1,31.4-12.6,4.2-3.1,11.5-8.4,10.5-13.6-1-5.3-10.3-7.6-13.6-8.4-13-3.2-19.7.9-38.7,4.2-30.3,5.3-35.8-.9-48.2,6.3-2.4,1.4-7.8,4.9-14.7,4.2-5.4-.6-8.7-3.5-11.5-5.2-8.7-5.6-18-4.1-31.4-3.1-15.1,1.1-27.5.7-52.4,0-20.1-.6-27.2-2-42.9,0-7.4,1-26.2,3.5-27.2,10.5-.5,3.2,3.2,4,6.3,10.5,2.2,4.6,2.2,11.2,2.1,24.1,0,9.2,0,13.7-1,19.9-1.4,9.1-2.8,10.1-3.1,15.7-.5,8.4,2.4,11.1,5.2,22,2.5,9.3.5,7.7,4.2,36.6,1.4,10.7,2.1,14.3,4.2,18.8,3.1,6.6,5.9,8.3,5.2,11.5-1.3,5.9-11.7,4.6-18.8,12.6-6.9,7.7-7.3,20.4-4.2,22,4.9,2.5,19.2-22.5,25.1-19.9,3.1,1.4-.8,10.2,0,24.1.2,3.2,1.1,19,8.4,29.3,11.2,15.8,35.6,15.7,58.6,15.7s23.7,4.4,49.2-5.4c4.3-1.6,9.4,1.9,32.5-14.8,9-6.8,20.2-15.5,21.8-19.6Z"
        />
        <path
          id="BG6"
          class="iliosakralgelenk_left1206_cls3"
          d="M282.5,102.5c0,5.2-74.9,9.4-167.5,9.4"
        />
        <path
          id="BG5"
          class="iliosakralgelenk_left1206_cls3"
          d="M277.3,141.3c0,5.3-68.2,9.6-152.4,9.6"
        />
        <path
          id="BG4"
          class="iliosakralgelenk_left1206_cls3"
          d="M276.6,150.9c0,6.8-67.9,12.3-151.8,12.3"
        />
        <path
          id="BG3"
          class="iliosakralgelenk_left1206_cls3"
          d="M301.4,212.4c0,10.8-100.6,33.5-165.4,19.6"
        />
        <path
          id="BG2"
          class="iliosakralgelenk_left1206_cls3"
          d="M271.9,19.8c0,9.5-70.2,17.2-156.9,17.2"
        />
        <path
          id="BG1"
          class="iliosakralgelenk_left1206_cls3"
          d="M258.6,12.4c0,8.8-64.2,15.9-143.6,15.9"
        />
      </g>
      <g id="iliac">
        <path
          id="iliac9"
          class="iliosakralgelenk_left1206_cls1"
          d="M141.3,628.8c2.8-6.6,19.7.5,51.3-5.2,12.4-2.3,35.3-6.7,55.5-23,11.2-9.1,8-10.8,25.1-28.3,5.2-5.2,20-19.8,41.9-34.6,17.2-11.6,18.9-9.8,38.7-23,17.2-11.4,25.8-17.2,34.6-27.2,4.2-4.8,16.9-20.3,23-44,3.2-12.1,6.8-26.2,1-40.8-3.5-8.9-6.8-8.8-10.5-17.8-1.5-3.8-5.7-15.7,0-39.8,4.9-20.6,9.9-20.2,19.9-47.1,8.6-23.2,13-35,10.5-50.3-1.4-8.5-4.1-13-5.2-26.2-1.1-13.5,1.3-14.4,0-25.1-2.1-18.1-15.7-19.9-8.4-32.5,3.7-6.4,4.1-9.8,15.7-25.1,18.4-24.3,14.6-24.4,28.3-40.8,13.6-16.4,15.4-14,28.3-30.4,11.7-15,11.6-18.7,19.9-25.1,13.7-10.6,29.1-12.1,35.6-12.6,6.4-.5,24.1-1.7,40.8,8.4,16.1,9.7,22.2,24.4,30.4,44,7,16.8,17,40.9,13.6,71.2-1.5,13.7-9.1,29.6-24.1,60.7-12,25-21.6,42.4-36.6,70.2-23.6,43.5-35.5,65.3-36.6,68.1-19.3,44.3-24.6,82-28.3,109.9-6.8,51.3-1.6,75.9,0,82.7,3.8,16,7,29.7,16.8,42.9,2.2,3,4.8,6.1,7.3,11.5,4.7,10.2,4.8,19.7,4.2,31.4-1.3,24.9-1.9,37.4-5.2,42.9-11.8,19.9-39.8,23.4-53.4,25.1-27.3,3.5-30.2-6.2-52.4-1-24.6,5.7-24.4,18.5-49.2,22-21.5,3-39.7-4-42.9-5.2-10.9-4.3-12.2-7.6-23-12.6-16.9-7.7-30.1-7.2-44-8.4,0,0-43.6-3.6-82.7-26.2-19.7-11.2-43.4-30.3-39.9-38.7Z"
        />
        <path
          id="iliac8"
          class="iliosakralgelenk_left1206_cls1"
          d="M546.5,29.9c81.2,21.6,64.2,112.6,29.3,151.8"
        />
        <path
          id="iliac7"
          class="iliosakralgelenk_left1206_cls1"
          d="M575.8,181.7c-47.2,64.8-131,122.6-113.1,209.4"
        />
        <path
          id="iliac6"
          class="iliosakralgelenk_left1206_cls1"
          d="M462.7,391.1c-2.5,27.2-1.6,65.3-32,96.7"
        />
        <path
          id="iliac5"
          class="iliosakralgelenk_left1206_cls1"
          d="M430.7,487.8c-28.7,36.3,9.7,107-28.7,113.8"
        />
        <path
          id="iliac4"
          class="iliosakralgelenk_left1206_cls1"
          d="M442.3,473.5c45,20.5,42.3,104-21.9,98.7"
        />
        <path
          id="iliac3"
          class="iliosakralgelenk_left1206_cls1"
          d="M570.6,284.3c-32.6,38.2-67.8,87.8-110.3,96.3"
        />
        <path
          id="iliac2"
          class="iliosakralgelenk_left1206_cls1"
          d="M524.5,634.1c18.8-98-190.3-26.3-200.6,77.4"
        />
        <path
          id="iliac1"
          class="iliosakralgelenk_left1206_cls1"
          d="M462.7,556.1c14.8-2.9,44.6,7.1,59.7,31.9"
        />
      </g>
      <g id="sacrum">
        <path
          id="sacrum22"
          class="iliosakralgelenk_left1206_cls1"
          d="M105.9,348c.9.6,1.9,1,2.9,1.3,5.1,1.4,7.4-2.2,22-2.1,7.8.1,17.5.2,27.2,5.2,8.7,4.5,8,8,16.8,12.6,8.7,4.5,13.8,3.4,23,5.2,18.7,3.8,31.3,15.1,35.6,18.8,14.9,13.3,20.9,28.7,23,34.6,4.4,11.8,2.3,13.9,6.3,28.3,6.3,22.8,17.3,38.3,19.9,41.9,8.7,11.9,24.6,33.7,44,32.5,4.6-.3,10.8-3.7,23-10.5,10.5-5.8,18.7-10.4,28.3-18.8,12.7-11.3,19.8-22.5,22-26.2,3-4.9,7.4-12.4,10.5-23,2.3-7.9,8.5-29.5-1-49.2-6.3-13-12.5-11.1-17.8-24.1-5.8-14.3-3.1-28.3-1-37.7,4.1-19.3,10.2-18.7,17.8-40.8,6.5-19,.6-15.3,8.4-49.2,2.8-12.1,6-23.3,0-30.4-3.2-3.8-7.1-4.1-8.4-8.4-1.8-5.8,4.2-9.3,6.3-17.8,2.2-8.8,10.2-14.7-8.4-27.2-9.8-6.6-21.5-5.1-26.2-4.2-10.7,2.1-17.9,8-22,11.5-22.1,18.9-32,34.3-46.1,49.2-8.4,8.9-20.3,21.5-38.7,31.4-24.7,13.3-46.6,13.9-77.5,14.7-26.5.7-37.2-3-45-9.4-8.3-6.9-12.2-15.8-12.6-16.8-4.3-10.3-3.6-20.9-3.1-28.3.7-11.1,2.9-15.5,1-16.8-3.8-2.5-18.6,10.9-27.2,22-1.8,2.3-3.4,4.7-4.9,7.1"
        />
        <path
          id="sacrum21"
          class="iliosakralgelenk_left1206_cls1"
          d="M361.5,355.5c0-34.1-31.4-64.9-78.8-64.9"
        />
        <path
          id="sacrum20"
          class="iliosakralgelenk_left1206_cls1"
          d="M310.1,294.4c-9.5,36.2-72.5,47.7-102.8,4.6"
        />
        <path
          id="sacrum19"
          class="iliosakralgelenk_left1206_cls1"
          d="M331.8,358.7c0-3.5-24.4-6.3-54.4-6.3"
        />
        <path
          id="sacrum18"
          class="iliosakralgelenk_left1206_cls1"
          d="M325.6,366c0-4.1-21.5-7.3-48.2-7.3"
        />
        <path
          id="sacrum17"
          class="iliosakralgelenk_left1206_cls1"
          d="M310.1,384.9c0,4.6,17.7,8.4,39.6,8.4"
        />
        <path
          id="sacrum16"
          class="iliosakralgelenk_left1206_cls1"
          d="M310.1,393.2c0,6.4,20,11.5,44.8,11.5"
        />
        <path
          id="sacrum15"
          class="iliosakralgelenk_left1206_cls1"
          d="M331.8,437.2c0,1.7,16.4,3.1,36.6,3.1"
        />
        <path
          id="sacrum14"
          class="iliosakralgelenk_left1206_cls1"
          d="M329.9,442.4c0,5.8,18.4,5.2,38.6,5.2"
        />
        <path
          id="sacrum13"
          class="iliosakralgelenk_left1206_cls1"
          d="M329.9,489.6c0,8.2,17.3,14.8,38.6,14.8"
        />
        <path
          id="sacrum12"
          class="iliosakralgelenk_left1206_cls1"
          d="M349.2,456.1c0,3.5,11,6.3,24.6,6.3"
        />
        <path
          id="sacrum11"
          class="iliosakralgelenk_left1206_cls1"
          d="M136,246.6c0,29.3,69.6,39.1,130.9,16"
        />
        <path
          id="sacrum10"
          class="iliosakralgelenk_left1206_cls1"
          d="M108.8,323.1c0,13.5,14.4,24.5,32.3,24.5"
        />
        <path
          id="sacrum9"
          class="iliosakralgelenk_left1206_cls1"
          d="M153.8,300.4c0,5.4,10.7,9.8,23.8,9.8s23.8-4.4,23.8-9.8-10.7-17.1-23.8-17.1-23.8,11.7-23.8,17.1Z"
        />
        <path
          id="sacrum8"
          class="iliosakralgelenk_left1206_cls1"
          d="M301.5,310.8c-.1-1.5,8.9-15,20.6-12,8.6,2.2,22.9,12.2,22.9,21.6s-6.4,7.3-19.6,7.3-23.6-7.5-23.9-16.9h0Z"
        />
        <ellipse
          id="sacrum7"
          class="iliosakralgelenk_left1206_cls1"
          cx="250.2"
          cy="343.5"
          rx="16.8"
          ry="8.9"
        />
        <path
          id="sacrum6"
          class="iliosakralgelenk_left1206_cls1"
          d="M331.8,362.9c0-5.8,15.2-20,25.7-10.5s17.9.1,25.7,10.5c9.1,12.2-8.7,16.2-25.7,10.5-13.4-4.6-25.7-4.8-25.7-10.5Z"
        />
        <ellipse
          id="sacrum5"
          class="iliosakralgelenk_left1206_cls1"
          cx="284.2"
          cy="392.2"
          rx="17.3"
          ry="12.6"
        />
        <ellipse
          id="sacrum4"
          class="iliosakralgelenk_left1206_cls1"
          cx="386.8"
          cy="404.7"
          rx="15.2"
          ry="11.3"
        />
        <circle
          id="sacrum3"
          class="iliosakralgelenk_left1206_cls1"
          cx="394.7"
          cy="454"
          r="11.5"
        />
        <circle
          id="sacrum2"
          class="iliosakralgelenk_left1206_cls1"
          cx="310.5"
          cy="438.8"
          r="11.5"
        />
        <ellipse
          id="sacrum1"
          class="iliosakralgelenk_left1206_cls1"
          cx="315.1"
          cy="489.1"
          rx="10.5"
          ry="7.9"
        />
      </g>
      <g id="spine">
        <path
          id="spine12"
          class="iliosakralgelenk_left1206_cls1"
          d="M283.4,102.2c4.7,2.4,9.8.2,11.5,3.1,2.6,4.4-7.4,11.5-12.7,22.4-10.9,22.1-1.9,57.9,15.9,66.6,5.3,2.6,11.4,2.8,14.7,8.4,4.3,7.3-1.4,14.6,1,16.8,6.9,6.2,72-45,62.6-66-2.5-5.6-8.1-4.2-13-12.7-5.2-8.8-1.5-14.3-4.3-33.5-1.7-11.7-2.6-17.5-6.7-21.9-4.4-4.6-8-3.8-20.9-11.5-7-4.2-10.6-6.4-11.5-9.4-2.7-8.6,10.6-15.7,8.5-21.4"
        />
        <path
          id="spine11"
          class="iliosakralgelenk_left1206_cls1"
          d="M345.5,81.1c-18.7-9.3-42.3.4-50.8,24.3"
        />
        <path
          id="spine10"
          class="iliosakralgelenk_left1206_cls1"
          d="M294.7,67.6c-9.9,23.8,0,37.7,0,37.7"
        />
        <path
          id="spine9"
          class="iliosakralgelenk_left1206_cls1"
          d="M311.1,111.6c0,3.5,0,16.7,19.2,12.6"
        />
        <path
          id="spine8"
          class="iliosakralgelenk_left1206_cls1"
          d="M378.6,156.3c-34.1-10.2-67.1,7-55.7,62.2"
        />
        <path
          id="spine7"
          class="iliosakralgelenk_left1206_cls1"
          d="M304.6,223.7c3.9-10-8-16.9-7.3-30.4.6-12.2,10.9-20.8,20.9-29.3,2.7-2.3,8.9-7.2,37.7-20.9,23.3-11.1,31.7-13.8,38.7-24.1.8-1.2,9.5-14.2,5.2-19.9-4.7-6.3-22.6-.2-29.3,2.1-12.1,4.1-21.9,10.2-40.8,22-9.8,6.1-14.7,9.6-23,10.5-3.1.3-12,.9-20.9-4.2-2.4-1.4-13.1-7.4-12.6-14.7.4-6.1,8.2-6.8,9.4-13.6,1.1-6.1-4.6-8.6-7.3-16.8-3.4-10.1-.5-23.1,6.3-30.4,2.8-3,5.9-4.7,31.4-9.4,18.4-3.4,22.2-3.5,34.6-6.3,13.7-3.1,22.6-6.1,31.4-12.6,4.2-3.1,11.5-8.4,10.5-13.6-1-5.3-10.3-7.6-13.6-8.4-13-3.2-19.7.9-38.7,4.2-30.3,5.3-35.8-.9-48.2,6.3-2.4,1.4-7.8,4.9-14.7,4.2-5.4-.6-8.7-3.5-11.5-5.2-8.7-5.6-18-4.1-31.4-3.1-15.1,1.1-27.5.7-52.4,0-20.1-.6-27.2-2-42.9,0-7.4,1-26.2,3.5-27.2,10.5-.5,3.2,3.2,4,6.3,10.5,2.2,4.6,2.2,11.2,2.1,24.1,0,9.2,0,13.7-1,19.9-1.4,9.1-2.8,10.1-3.1,15.7-.5,8.4,2.4,11.1,5.2,22,2.5,9.3.5,7.7,4.2,36.6,1.4,10.7,2.1,14.3,4.2,18.8,3.1,6.6,5.9,8.3,5.2,11.5-1.3,5.9-11.7,4.6-18.8,12.6-6.9,7.7-7.3,20.4-4.2,22"
        />
        <path
          id="spine6"
          class="iliosakralgelenk_left1206_cls1"
          d="M282.6,101.2c0,5.2-74.9,9.4-167.5,9.4"
        />
        <path
          id="spine5"
          class="iliosakralgelenk_left1206_cls1"
          d="M277.4,140c0,5.3-68.2,9.6-152.4,9.6"
        />
        <path
          id="spine4"
          class="iliosakralgelenk_left1206_cls1"
          d="M276.7,149.6c0,6.8-67.9,12.3-151.8,12.3"
        />
        <path
          id="spine3"
          class="iliosakralgelenk_left1206_cls1"
          d="M301.5,211.1c0,10.8-124.5,44.9-192.6,5.5"
        />
        <path
          id="spine2"
          class="iliosakralgelenk_left1206_cls1"
          d="M272,18.5c0,9.5-70.2,17.2-156.9,17.2"
        />
        <path
          id="spine1"
          class="iliosakralgelenk_left1206_cls1"
          d="M258.7,11.1c0,8.8-64.2,15.9-143.6,15.9"
        />
      </g>
    </svg>
  );
}

export default LinkesLliosakralgelenkVdOblLiegend1206;
