import { MakeGetObject, MakePostObject } from "../AuthHeader";

export async function downloadReport(rep_id, keycloak) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/download/reports/${rep_id}`;
  return fetch(url, MakeGetObject(keycloak)).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error(`server responded with code ${response.status} `);
    }
  });
}

export async function loadReports(
  keycloak,
  pageSize = undefined,
  pageNr = undefined
) {
  let urlApendix = "";
  if (pageSize !== undefined && pageNr !== undefined) {
    urlApendix = `?page_size=${pageSize}&page_number=${pageNr}`;
  }
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/download/reports${urlApendix}`;
  return fetch(url, MakeGetObject(keycloak)).then((data) => data.json());
}

export async function setDownloadedStatus(rep_id, keycloak) {
  let body = {
    action: "status_change",
    status: 2,
  };
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/download/reports/${rep_id}`;
  return fetch(url, MakePostObject(keycloak, body)).then((data) => data.json());
}

export async function deleteReport(rep_id, keycloak) {
  let body = {
    action: "delete_report",
  };
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/download/reports/${rep_id}`;
  return fetch(url, MakePostObject(keycloak, body)).then((data) => data.json());
}
