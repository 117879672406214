import { useDispatch } from "react-redux";
import { usePatient } from "../../Utilities/FetchHooks/Patients/PatientsHooks";
import { removeOpenedPatient } from "../../Redux/actions";

import ClipLoader from "react-spinners/ClipLoader";
import "./stylesheets/PatientName.scss";

export default function PatientName({ patient_id, is_active }) {
  const patientDataQ = usePatient({
    patientId: patient_id,
    xmask: "patientsname, patientsvorname",
  });
  const patientData = patientDataQ.data;
  const dispatch = useDispatch();

  const removePatient = () => {
    dispatch(removeOpenedPatient(patient_id));
  };

  return (
    <>
      {patientDataQ.status === "loading" ? (
        <ClipLoader color="#a1a1a1" loading={true} css={true} size={30} />
      ) : (
        <>
          {patientDataQ.status === "success" ? (
            <>
              {patientData && (
                <>
                  {`${patientData.patientsname} ${patientData.patientsvorname}`}
                  {is_active && (
                    <i
                      onClick={removePatient}
                      className="far fa-window-close close-icon py-1 px-1"
                    ></i>
                  )}
                </>
              )}
            </>
          ) : (
            <>No Data</>
          )}
        </>
      )}
    </>
  );
}
