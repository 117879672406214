import style from "./style.scss";

function RechterKarpaltunnelUnterarmAufliegendAxSitzend1366(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 465.2 492.88">
      <g id="Carpaltunnel">
        <path
          id="partoffinger17"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M30.37,173.25C3.17,212.85-.73,240.45,23.37,253.45c16.5,3.3,32.2-6.7,47.1-30.3l39.2-52.7c14.8-18.8,29.4-30.8,43.9-37.8,19.1-9,10.2-18.6-11.7-28.5-19.3-8.8-39.3-6-59.7,4.7-11.7,6.3-17.5,16.1-21.5,27.1-2.5,16.7-14,28.3-30.3,37.3h0Z"
        />
        <path
          id="carpalbone11"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M132.57,225.55h0c13.6,2.5,25.4-2.7,36-13.6,11.4-14.2,19.5-35.1,21-70,2.7-14.7-2-23.4-14-28.5-15.5-1-12.8-16.4-3.3-37.3,5.9-13.3,6.5-24.1.5-31.7-15-3.6-25.7,11.4-34.1,36.4-2,11.3-7.7,21.5-14.5,31.3-10.6,15-17.5,36.6-22.4,61.6-5.5,23.6-2.3,43.7,30.8,51.8h0Z"
        />
        <path
          id="carpalbone10"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M227.27,225.05h0c18.5-2.1,34.5-7,46.7-16.4,11.4-10.3,11.9-23.4.9-39.7.5-14.7-3.1-28.4-9.3-41.5-4.4-19.7-16.1-25.5-33.6-20.5-25.9,9.5-35,29.2-34.1,55.1-7.4,12-11.3,25.3-7.5,41.5,8,15.8,19.3,25.1,36.9,21.5h0Z"
        />
        <path
          id="carpalbone9"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M246.67,185.95h0c-5.7,16.3,7,32.8,24.5,32.4,23.3-.6,43.5-16.6,60.8-46.5,15.8-17.5,25.2-38.2,27.5-62.5,5.2-17.4-1.3-29.2-22.9-33.6-25.4-.1-42.6,7.2-51.5,21.8-1.1,1.7-1.8,3.8-2,5.9-2.9,25.7-10.7,45.9-22.6,61.4-6.5,7.1-11.4,14.1-13.8,21.1h0Z"
        />
        <path
          id="carpalbone8"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M331.27,55.15c1.3,6.7-2.5,13.8-8.4,21-14.9,12-14.8,27,3.2,45.7,11.7,8.8,17.4,22.8,16.8,42.5,7.1,25.3,15.8,42.4,28.5,40.1,12.1-.9,15.2-17.8,14-42.5-3.7-21.9,4.5-35.9,16.3-47.6,13.3-14.8,16-28.6,4.7-41.1l-34.5-16.3-18.7-23.3c-10.5-7-17.2,2.6-21.9,21.5h0Z"
        />
        <path
          id="partoffinger16"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M89.17,190.55h0l24.2-.8c19.8-6,30-20.3,31.3-42.5,2-19.2-3.5-37.5-17.3-54.6-3.9-15.3-19.7-13.1-37.8-7.5-18-4-25.1,11.5-23.3,42.9,2.7,6.5,3,12.9-1.4,19.1-6.6,21.5-4.5,38.8,24.3,43.4h0Z"
        />
        <path
          id="partoffinger15"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M19.67,182.55h0c-8.5,23-14.8,44.9-8.4,61.1,11.9,14.4,26.4,17.9,47.6-7,13.3-18,21.3-38.6,14-66.7-4.7-19.3-12.3-27.4-21.9-28-14.3,4.3-23.9,19.8-31.3,40.6h0Z"
        />
        <path
          id="partoffinger14"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M34.97,489.55l6.5-93.3c-1.7-37.4,6.1-69.9,25.2-96.6,8.6-7.8,15.3-17.7,18.7-31.3,11.2-26.1,3.4-41.8-26.1-45.7l-27.5-5.6c-24.4.6-31.7,16.9-24.7,46.2,7.9,21.4,9.9,45.5,7,71.9L.07,488.65"
        />
        <path
          id="partoffinger13"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M125.47,488.65c-18.1-55.3-24.4-102.9-14.9-140,16.8-29.2,23.4-56.4,6.5-78.9-15.9-20.7-40.4-17.8-66.2-11.2-30.6,4.5-41.1,25.2-33.6,60.7,9.5,30.9,15.6,59.6,14.9,84,2.5,31.4,7,61,15.9,86.3"
        />
        <path
          id="partoffinger12"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M176.87,492.85c-4.2-48.4-2-91.8,13.1-125,5.6-9.6,4.4-22.1,2.3-35,5.3-16.5,2.7-29.3-11.2-36.7-5.7-3-12.1-4.4-18.6-4.3l-17.1.2c-11.8.2-23.1,5.5-30.2,14.9-8.8,11.6-9.8,27.1-2.2,46.8,8.7,10.6,13.1,23.4,13.5,38.3l-6.5,92.9"
        />
        <path
          id="carpalbone7"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M303.77,186.35c5.7-2.2,11.8-3.6,19.1-3.3,5,1.5,9.9,2.1,14-1.9,3.9-11,1.1-26.1-11.7-47.6-7.2-11.5-16.9-16.8-30.3-12.1-20.3,3.8-16.2,25.1-9.8,48.1.9,15.6,5.1,25,18.7,16.8h0Z"
        />
        <path
          id="partoffinger11"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls7"
          d="M422.57,490.45c.2-16.7,9.4-37.2,22.9-59.7,8.5-23.2,7.5-40.5-8.8-48-1.6-.7-3.1-1.5-4.6-2.4-10.9-6.2-29.7-6.8-52.6-4.4-5.8.6-11.4,2.6-16.2,6-12.2,8.8-15,23.9-2.7,48.8,10.8,20.2,16.1,39.4,15.4,57.4"
        />
        <path
          id="partoffinger10"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls1"
          d="M458.97,70.65c-16.1-8.4-29.8-11.9-41.1-10.3-16.2,3.1-17.3,13.5-4.7,30.3,3,12.5-3.6,29.7-23.3,53.2,7.7,25.9,23.1,42.4,56.5,36.9,3.6,7.6,9.3,13.5,18.7,16.8"
        />
        <path
          id="carpalbone6"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls3"
          d="M240.17,168.15h0l14-28.5c6.1-27.2-5.3-31.8-25.2-25.7l-47.1,14.5c-21.3,9.9-27,26.4-22.9,47.1,1.6,18.1,14.3,26.1,39.2,22.9,22.1,9.5,35.5-1.9,42-30.3h0Z"
        />
        <path
          id="partoffinger7"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls6"
          d="M30.67,173.25C3.47,212.85-.43,240.45,23.67,253.45c16.5,3.3,32.2-6.7,47.1-30.3l39.2-52.7c14.8-18.8,29.4-30.8,43.9-37.8,19.1-9,10.2-18.6-11.7-28.5-19.3-8.8-39.3-6-59.7,4.7-11.7,6.3-17.5,16.1-21.5,27.1-2.5,16.7-14,28.3-30.3,37.3h0Z"
        />
        <path
          id="carpalbone5"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls4"
          d="M132.87,225.55h0c13.6,2.5,25.4-2.7,36-13.6,11.4-14.2,19.5-35.1,21-70,2.7-14.7-2-23.4-14-28.5-15.5-1-12.8-16.4-3.3-37.3,5.9-13.3,6.5-24.1.5-31.7-15-3.6-25.7,11.4-34.1,36.4-2,11.3-7.7,21.5-14.5,31.3-10.6,15-17.5,36.6-22.4,61.6-5.5,23.6-2.3,43.7,30.8,51.8h0Z"
        />
        <path
          id="carpalbone4"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls4"
          d="M227.57,225.05h0c18.5-2.1,34.6-7,46.7-16.4,11.4-10.3,11.9-23.4.9-39.7.5-14.7-3.1-28.4-9.3-41.5-4.4-19.7-16.1-25.5-33.6-20.5-25.9,9.5-35,29.2-34.1,55.1-7.4,12-11.3,25.3-7.5,41.5,8,15.8,19.3,25.1,36.9,21.5h0Z"
        />
        <path
          id="carpalbone3"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls4"
          d="M246.97,185.95h0c-5.7,16.3,7,32.8,24.5,32.4,23.3-.6,43.5-16.6,60.8-46.5,15.8-17.5,25.2-38.2,27.5-62.5,5.2-17.4-1.3-29.2-22.9-33.6-25.4-.1-42.6,7.2-51.5,21.8-1.1,1.7-1.8,3.8-2,5.9-2.9,25.7-10.7,45.9-22.6,61.4-6.5,7.1-11.4,14.1-13.8,21.1h0Z"
        />
        <path
          id="carpalbone2"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls4"
          d="M331.67,55.15h0c1.2,6.7-2.5,13.8-8.4,21-15,12-14.9,27,3.2,45.8,11.7,8.8,17.4,22.8,16.8,42.5,7.1,25.3,15.8,42.4,28.5,40.1,12.1-.9,15.2-17.8,14-42.5-3.7-21.9,4.5-35.9,16.3-47.6,13.3-14.8,16-28.6,4.7-41.1l-34.5-16.4-18.7-23.3c-10.5-7-17.2,2.6-21.9,21.5h0Z"
        />
        <path
          id="partoffinger8"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls5"
          d="M89.47,190.55h0l24.2-.8c19.8-6,30-20.3,31.3-42.5,2-19.2-3.5-37.5-17.3-54.6-3.9-15.3-19.7-13.1-37.8-7.5-18-4-25.1,11.5-23.3,42.9,2.7,6.5,3,12.9-1.4,19.1-6.6,21.5-4.5,38.8,24.3,43.4h0Z"
        />
        <path
          id="partoffinger9"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls6"
          d="M19.97,182.55h0c-8.5,23-14.8,44.9-8.4,61.1,11.9,14.4,26.4,17.9,47.6-7,13.3-18,21.3-38.6,14-66.7-4.7-19.3-12.3-27.4-21.9-28-14.3,4.3-23.9,19.8-31.3,40.6h0Z"
        />
        <path
          id="partoffinger6"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls6"
          d="M35.27,489.55l6.5-93.3c-1.7-37.4,6.1-69.9,25.2-96.6,8.6-7.8,15.3-17.7,18.7-31.3,11.2-26.1,3.4-41.8-26.1-45.7l-27.5-5.6c-24.4.6-31.7,16.9-24.7,46.2,7.9,21.4,9.9,45.5,7,71.9L.37,488.65"
        />
        <path
          id="partoffinger5"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls6"
          d="M125.77,488.65c-18.1-55.3-24.4-102.9-14.9-140,16.8-29.2,23.4-56.4,6.5-78.9-15.9-20.7-40.4-17.8-66.2-11.2-30.6,4.5-41.1,25.2-33.6,60.7,9.5,30.9,15.6,59.6,14.9,84,2.5,31.4,7,61,15.9,86.3"
        />
        <path
          id="partoffinger4"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls6"
          d="M177.17,492.85c-4.2-48.4-2-91.8,13.1-125,5.6-9.6,4.4-22.1,2.3-35,5.3-16.5,2.7-29.3-11.2-36.7-5.7-3-12.1-4.4-18.6-4.3l-17.1.2c-11.8.2-23.1,5.5-30.2,14.9-8.8,11.6-9.8,27.1-2.2,46.8,8.7,10.6,13.1,23.4,13.5,38.3l-6.5,92.9"
        />
        <path
          id="carpalbone1"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls4"
          d="M304.07,186.35c5.7-2.2,11.8-3.6,19.1-3.3,5,1.5,9.9,2.1,14-1.9,3.8-11,1-26.2-11.7-47.6-7.2-11.5-16.9-16.8-30.3-12.1-20.3,3.8-16.2,25.1-9.8,48.1.9,15.6,5.1,25,18.7,16.8h0Z"
        />
        <path
          id="partoffinger3"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls1"
          d="M300.87,490.05l33.1-133.9c7.9-26.1,2.9-46.8-31.3-55.1-23.2-5.9-44.9-3.3-65.8,3.3-23.1,16.5-27.9,42-14.9,76l21.9,109.2"
        />
        <path
          id="partoffinger2"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls1"
          d="M327.87,207.75h0c-16.6,4.2-29.5,13.7-37.4,30.4-4.5,23.6,3.9,42.6,29.9,55.5,16.6,4.8,25.9,33.6,27.5,88.6.5,17.8,9.8,32.8,27.1,45.3,26.6,5.8,47.3.5,56.5-26.1,12.1-11.9,19.9-30.1,18.2-62.5-8-22.3-17.9-27.3-28.3-26.7-1,0-1.9.5-2.5,1.3-15.2,20.3-29.9,12.7-43.9-26.4-3.5-15.8.3-29.3,9.2-41.2,2.4-3.2,4.1-7,4.3-11,.7-12.4-9.8-21-28.9-26.7-12.2-14.5-22.7-14.1-31.7-.5h0Z"
        />
        <path
          id="partoffinger1"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls2"
          d="M422.57,490.45c.2-16.7,9.4-37.2,22.9-59.7,8.5-23.2,7.5-40.5-8.8-48-1.6-.7-3.1-1.5-4.6-2.4-10.9-6.2-29.7-6.8-52.6-4.4-5.8.6-11.4,2.6-16.2,6-12.2,8.8-15,23.9-2.7,48.8,10.8,20.2,16.1,39.4,15.4,57.4"
        />
        <path
          id="ospisiformeoutline"
          class="Rechter_Karpaltunnel_Unterarm_aufliegend_ax_sitzend1366_cls3"
          d="M92.77,68.75c-7.1,5.5-13,6.1-16.8-.9-4.3-6.4-5.1-12.7-1.4-18.7L98.37,7.55c11.6-9.9,23.5-10.8,36.4,13.1,7.9,6.9,14.4,14.1,5.6,24.7-8.5,5.7-13,12.8-13.1,21.5-12.5-4.2-24.1-3.9-34.5,1.9Z"
        />
      </g>
      <g id="Highlights">
        <path
          id="ospisiformehighlight"
          class="Ospisiforme1366Fill"
          style={{
            fill: props.colors.Ospisiforme1366Fill,
            stroke: props.colors.Ospisiforme1366Fill,
          }}
          d="M93.01,68.8c-7.04,5.58-12.93,6.24-16.81-.72-4.37-6.35-5.24-12.64-1.6-18.68L97.96,7.55c11.49-10.02,23.38-11.05,36.54,12.71,7.97,6.82,14.55,13.95,5.86,24.64-8.44,5.79-12.86,12.94-12.87,21.64-12.54-4.07-24.14-3.64-34.48,2.27Z"
        />
        <path
          id="carpaltunnelhighlight"
          class="Karpaltunnel1366Fill"
          style={{
            fill: props.colors.Karpaltunnel1366Fill,
            stroke: props.colors.Karpaltunnel1366Fill,
          }}
          d="M323.19,83.6c-20.47,24.97-46.4,34.08-52.18,36-28.68,9.51-83.71,15.09-102.74-15.78-13.92-22.57-3.4-56.21,8.09-67.96,3.33-3.4,4.83-3.01,5.26-2.83,5.27,2.2-9.86,30.42,1.63,50.14,4.92,8.44,13.11,12.58,20.04,16.09,1.33.67,14.95,7.39,31.72,6.98,32.03-.78,55.7-27.06,65.03-37.42,23.81-26.44,30.77-53.54,38.11-51.56,4.16,1.12,4.91,10.62,5.26,14.97,1.89,23.85-13.06,42.63-20.22,51.37Z"
        />
      </g>
    </svg>
  );
}

export default RechterKarpaltunnelUnterarmAufliegendAxSitzend1366;
