import { useState } from "react";
import ExamList from "../ExamsList/ExamList";
import VerifiedExaminationsCharts from "./VerifiedExaminationsCharts";

import "./styles/VerifiedExaminationsStatistics.scss";

function VerifiedExaminationsStatistics() {
  const [selectedExamination, setSelectedExamination] = useState(undefined);
  return (
    <div className="d-flex p-2" style={{ gap: "10px" }}>
      <div
        className="border rounded p-2 bg-light"
        style={{ flexGrow: 1, height: "94vh", maxWidth: "40%" }}
      >
        <ExamList
          selectHandler={setSelectedExamination}
          selected={selectedExamination}
          pageSize={20}
        />
      </div>
      <div className="border rounded p-2 bg-light" style={{ flexGrow: 3 }}>
        <VerifiedExaminationsCharts examination={selectedExamination} />
      </div>
    </div>
  );
}

export default VerifiedExaminationsStatistics;
