import style from "./style.scss";

export default function RechterUnterschenkelMitKnieVdLiegend2308(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 326.7 1066.45"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          id="background_lower_leg"
          data-name="background lower leg"
          class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls4"
          d="M227.13,639.13h0c-.9,20.9-.1,32.3.2,47.1.3,25.5-1.5,41.1-3.1,64-1.4,19.6-2.3,59.7-4.2,140-1.4,61.5-1.6,88.8-2.3,113-.5,15.1-.8,19.5-.6,30,.4,14.8,1.3,16.1,0,19-9.1,20.1-79.8,19.6-91.8-6-2.3-5-.6-7.1-.3-29,.3-14.6-.4-20.6-1.1-39-1-25.2-1.3-53.5-1.9-99-1.9-144.2-2.2-122.5-2.2-152,0-32.9.5-73.7-1.8-125.9,0,0-.1-16.9-6-63-3.8-29.5-7.5-43.9-12-57-3.9-11.2-9.9-25.6-12-25-1.8.5-.2,12.5,1,22,2.5,18.7,4.9,22,3,30-1.8,7.5-4.5,6.4-9,17-5.8,13.5-6,25.8-6,31,0,3.4.2,5.8.5,7.8.6,4.2,1.4,7,1.7,8.3,1.7,6.8-1,11.8-2.3,18.8-1.6,9,1.1,10.1,3,30,.9,9.2,0,6.1,1,21,.4,7.1,2.3,32.4,2.3,42.1h0c-2.1,31.7,3.7,33.5,10.7,167.9,1.4,27.5,3,52,6,101,1.8,28,2.3,35.3,3.8,45.2,1.3,8.8,1.8,17.8,3.2,26.7,1.7,10.7,2.7,13.5,1,17-4.9,9.9-25.6,13.6-33.1,6-4.8-4.9-1.6-11.7-.9-29,.5-12.6-1-14.3-4-42-1.4-12.6-2.2-25.4-4-51-2.5-35.4-2.6-49.1-4.4-83-4.3-82-9.1-110.3-10-154.8-.1-8-.2-19.6-1.5-36.2-1.2-16.4-2.5-22.6-3.7-39.9-1.5-21.1-.4-21.9-1.4-32-1.7-16.8-8.4-32-21-62-10.5-24.9-16.8-34.1-20-54-2.8-17.3-.5-25.8,1-30,3.3-9.3,7.1-10.2,10-21,2.5-9.1.6-11.9,4-23,3.8-12.6,7.9-14.5,9-15,5.2-2.1,9.6,3,12,1,3.1-2.5-3.7-11.1-5-24-1.8-17.6,7.7-31.1,9-33,2.3-3.2,7.9-11,18-15,11.5-4.5,23-1.8,35,1,14.2,3.4,18.4,7.4,27,5,7.1-2,13.2-7.3,14-8,7.4-6.5,6.8-10.3,12-12,5.4-1.7,11.6.7,15,2,5.4,2.1,6.3,4.1,10,4,3.2-.1,4.1-1.6,10-4,8.1-3.3,9.2-3.3,10-3,3.1,1.1,2.7,4.8,6,9,.9,1.1,3.3,4,11,7,9.3,3.7,18.2,4.4,27,5,0,0,15.2,1.1,41,5,3.2.5,8.7,1.4,13.4,5.6.9.7,3.9,3.6,6.6,10.4,1.9,4.9,3.4,11.4,3,21,0,0-.8,20.6-16,47-5.8,10-4.2,4.9-26,36,0,0-15.5,22-21,42-3.2,11.7-.2,9.4-4,32-3.8,22.8-7,27-7,42,0,12.5,2.2,11.3,2,25-.3,15.4-2,32-2,32-.9,8-1.2,15.7-1.8,30.9h0Z"
        />
        <g id="fibula">
          <path
            id="fibula_outlines"
            data-name="fibula outlines"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls8"
            d="M107.83,1042.13c-4.7-16-29.3-449.8-29.9-459.9-1-19.1-2-36.4,6-55,3.3-7.6,6.4-11.7,8-21,1.7-10.2-.3-15.7-2-28-3.9-28.3,2.5-30.8-3-50-1.8-6.2-4.7-16.2-13-26-4.5-5.3-9.7-8.9-20-16-18.2-12.5-22.5-11.5-24-11-4.6,1.5-6.8,6.1-10,13-4.9,10.5-4.2,15.7-7,27-4.4,17.4-9.1,17.4-11,30-.5,3.1-1.4,11.3,3,28,5.5,20.8,12.3,28.2,21,47,8.4,18.3,11.9,32.7,17,54,3.4,14.2,29.7,453,31.8,473.9"
          />
          <path
            id="fibula_1"
            data-name="fibula 1"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M89.83,478.23c-4.8,0-8.7,25.2-8.7,56.4"
          />
          <path
            id="fibula_2"
            data-name="fibula 2"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M19.93,487.23c1.5.4,4.2-21.2-7-49"
          />
          <path
            id="fibula_3"
            data-name="fibula 3"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M73.83,619.23c-4.8-6.8-12.7-53.3,7.3-84.6"
          />
          <path
            id="fibula_4"
            data-name="fibula 4"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M65.83,627.23c-1.7-3.3-12-39.6-27.2-80"
          />
        </g>
        <g id="tibia">
          <path
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls8"
            d="M125.13,1046.13c-1-29.1-8.3-465.4-10.2-483.9-2.1-20.4-4.2-34-7-48-3.1-15.3-5.2-26-10-38-7.6-19.2-15.2-25.2-28-44-13.9-20.5-39.3-57.8-33-96,2.8-16.9,10.1-23.4,12-25,11.4-9.5,27.9-7.9,37-7,21,2,26.1,10.5,38,6,5.6-2.1,12.7-7.1,18-12,6.6-6.1,6.9-9,11-10,8.4-2.1,12.4,8.4,23,8,9.4-.4,12.4-8.8,19-7,5.4,1.5,4.6,7.5,11,13,5.7,4.9,10.9,4,28,6,10.4,1.2,17.2,2.5,30,5,17.2,3.3,25.9,5.1,30,9,8.9,8.5,8.4,22,8,34-.1,4-1.1,24.2-15,46-6.4,10.1-8.3,9-18,22-3.9,5.1-14.7,20.2-23,40-7.6,18.1-9.6,32.2-13,56-3.4,23.7-3.8,41.9-4,54-.4,21.8-13.3,463.3-12,477.9"
          />
          <path
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls8"
            d="M287.93,324.23c-44.9,7.4-81.7-4.3-82-22"
          />
          <path
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls8"
            d="M43.23,318.13c44,15.5,80.3,7.7,80.7-7.9"
          />
          <path
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M35.93,345.23c13.2,4.9,22.2,31.3,13.4,53.3"
          />
          <path
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M272.93,371.83c-26.9-4.9-51.6-6.6-60,0"
          />
          <path
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M184.43,359.23c0-14-98.9,12.6-118.5,4"
          />
          <path
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M205.93,302.23c-4.8-6-53.9,33-77,12.1"
          />
          <path
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M245.93,464.23c-16,0-29,76-29,170"
          />
        </g>
        <g id="knee">
          <path
            id="background_knee"
            data-name="background knee"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls4"
            d="M243.83,16.03c-7.5-13.8-32.8-9.9-75-11-51.1-1.4-70.3-11.7-73,2-3.3,17.1-6.7,21.9-8,52,0,0-.9,18.8-5.8,35.8-.7,2.4-1.6,4.8-2.7,7.5-5.7,13.8-9.5,13.8-17.4,31.8-3,6.8-8,18.4-11,34-2.8,14.9-.7,17-4,33-3.3,16.1-5.8,15.9-7.3,26.6-.9,6.1-4.1,29,8.3,42.4,10.5,11.4,27.4,10.7,42,10.1,7.8-.3,43.2-2.6,71.5-22.1,4-2.8,12.2-8.9,23.9-9.7,2.7-.2,9.3-.3,19.2,3.4,16.2,6.2,17.8,14.1,34.3,25.3,9.5,6.4,23.7,16.1,41,14.3,3.4-.4,16.2-1.7,22.8-11.3,4-5.8,4.2-12.5,4.3-16.7.3-8.6-2-12.6-3.1-18.4-2.2-12,3.7-16.2,15.1-49.4,5.9-17.1,6.1-21.2,6.2-23.7.3-15.6-6.9-27.4-9.6-31.7-6-9.6-12.2-13.8-18.5-19.5-21-18.7-30.4-40.9-38.2-57.7-4-8.7-8.7-18.8-12-33-2.4-10.5-1.3-10.9-3-14h0Z"
          />
          <path
            id="femur_outlines"
            data-name="femur outlines"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls8"
            d="M95.83,7.03c-4.6,21.6-6.9,39.4-8,52-1.7,18.4-.6,20.1-3,29-4.2,15.6-9.8,18.9-23,46-6.1,12.5-9.1,18.7-11,26-4.4,16.8-.4,20.7-4,41-3.3,18.9-7.3,18.9-8,33-.5,9.6-1.3,25.5,9,36,8.3,8.5,19.8,9.2,32,10,17.5,1.1,30.9-2.9,41-6,13.2-4.1,32-11.8,49-20,6.5-3.1,12.5-6.3,21-6,6.7.2,12.7,2.5,23,9,19.1,12,18.3,16.5,31,24,6.1,3.6,26.4,15.6,45,8,2.4-1,11.8-4.8,16-14,4.8-10.5-2.6-16.4-2-33,.2-6.3,3.5-15.2,10-33,8-21.9,10.1-22.9,11-32,.5-4.6,1.5-17.3-5-30-3.9-7.5-8.8-12-11-14-22.6-21-42.2-54.3-50-70-4.9-10-11.4-25.6-15-47"
          />
          <path
            id="patella"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls8"
            d="M145.83,200.03c-14.8-11.4-20.1-25.7-22-31-6.7-18.7-3.5-34.9-2-41,1.4-5.6,7.1-27,28-41,3.7-2.4,20-13.4,40-11,6.6.8,6.9,2.2,32,12,23.3,9.1,22.9,7.8,28,11,4.3,2.7,23.5,14.9,27,37,2.4,15.1-3.9,27.1-9,37-4,7.6-10.8,20.7-25,28-4.8,2.5-5.8,1.9-28,6-28.5,5.3-29.5,6.7-37,6-2.7-.3-17.5-1.9-32-13Z"
          />
          <path
            id="femur_1"
            data-name="femur 1"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M65.83,253.03c-9.1.2,2.3-19.3-19-52"
          />
          <path
            id="femur_2"
            data-name="femur 2"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M46.83,166.03c0,22-17,17.5,0,35"
          />
          <path
            id="femur_3"
            data-name="femur 3"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M113.83,182.03c-32.7-14-48-1.3-48-3"
          />
          <path
            id="femur_4"
            data-name="femur 4"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M128.83,269.73c12.8.5,24-20.8,23-56.5"
          />
          <path
            id="femur_5"
            data-name="femur 5"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M307.83,174.03c-26.9-1.3-53.3-1.3-56-1"
          />
          <polyline
            id="femur_6"
            data-name="femur 6"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            points="219.63 260.83 221.83 241.43 231.33 227.03 224.83 213.23 224.83 191.03 224.83 182.03 205.83 186.53 184.33 182.03 168.93 187.03 156.83 191.03"
          />
          <path
            id="femur_7"
            data-name="femur 7"
            class="Rechter_UnterschenkelmitKnie_vd_liegend2308_cls7"
            d="M279.83,98.33c-24.3,0-44-40.8-44-91.3"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="knee_overlay"
          data-name="knee overlay"
          class="Gelenkspalt2308Fill"
          style={{
            fill: props.colors.Gelenkspalt2308Fill,
            stroke: props.colors.Gelenkspalt2308Fill,
          }}
          d="M320.83,291c0,48.74-57.94,45-146,45s-150,3.74-150-45c0-48.74,49.54-48.87,137.6-48.87s158.4.13,158.4,48.87Z"
        />
        <path
          id="tibia_overlay"
          data-name="tibia overlay"
          class="Tibia2308Fill"
          style={{
            fill: props.colors.Tibia2308Fill,
            stroke: props.colors.Tibia2308Fill,
          }}
          d="M125.13,1046.13c-1-29.1-8.3-465.4-10.2-483.9-2.1-20.4-4.2-34-7-48-3.1-15.3-5.2-26-10-38-7.6-19.2-15.2-25.2-28-44-13.9-20.5-39.3-57.8-33-96,2.8-16.9,10.1-23.4,12-25,11.4-9.5,27.9-7.9,37-7,21,2,26.1,10.5,38,6,5.6-2.1,12.7-7.1,18-12,6.6-6.1,6.9-9,11-10,8.4-2.1,12.4,8.4,23,8,9.4-.4,12.4-8.8,19-7,5.4,1.5,4.6,7.5,11,13,5.7,4.9,10.9,4,28,6,10.4,1.2,17.2,2.5,30,5,17.2,3.3,25.9,5.1,30,9,8.9,8.5,8.4,22,8,34-.1,4-1.1,24.2-15,46-6.4,10.1-8.3,9-18,22-3.9,5.1-14.7,20.2-23,40-7.6,18.1-9.6,32.2-13,56-3.4,23.7-3.8,41.9-4,54-.4,21.8-13.3,463.3-12,477.9"
        />
        <path
          id="fibula_overlay"
          data-name="fibula overlay"
          class="Fibula2308Fill"
          style={{
            fill: props.colors.Fibula2308Fill,
            stroke: props.colors.Fibula2308Fill,
          }}
          d="M107.83,1042.13c-4.7-16-29.3-449.8-29.9-459.9-1-19.1-2-36.4,6-55,3.3-7.6,6.4-11.7,8-21,1.7-10.2-.3-15.7-2-28-3.9-28.3,2.5-30.8-3-50-1.8-6.2-4.7-16.2-13-26-4.5-5.3-9.7-8.9-20-16-18.2-12.5-22.5-11.5-24-11-4.6,1.5-6.8,6.1-10,13-4.9,10.5-4.2,15.7-7,27-4.4,17.4-9.1,17.4-11,30-.5,3.1-1.4,11.3,3,28,5.5,20.8,12.3,28.2,21,47,8.4,18.3,11.9,32.7,17,54,3.4,14.2,29.7,453,31.8,473.9"
        />
        <path
          id="femur_overlay"
          data-name="femur overlay"
          class="Femur2308Fill"
          style={{
            fill: props.colors.Femur2308Fill,
            stroke: props.colors.Femur2308Fill,
          }}
          d="M96.7,6.79c-4.6,21.6-6.9,39.4-8,52-1.7,18.4-.6,20.1-3,29-4.2,15.6-9.8,18.9-23,46-6.1,12.5-9.1,18.7-11,26-4.4,16.8-.4,20.7-4,41-3.3,18.9-7.3,18.9-8,33-.5,9.6-1.3,25.5,9,36,8.3,8.5,19.8,9.2,32,10,17.5,1.1,30.9-2.9,41-6,13.2-4.1,32-11.8,49-20,6.5-3.1,12.5-6.3,21-6,6.7.2,12.7,2.5,23,9,19.1,12,18.3,16.5,31,24,6.1,3.6,26.4,15.6,45,8,2.4-1,11.8-4.8,16-14,4.8-10.5-2.6-16.4-2-33,.2-6.3,3.5-15.2,10-33,8-21.9,10.1-22.9,11-32,.5-4.6,1.5-17.3-5-30-3.9-7.5-8.8-12-11-14-22.6-21-42.2-54.3-50-70-4.9-10-11.4-25.6-15-47"
        />
        <path
          id="patella_overlay"
          data-name="patella overlay"
          class="Patella2308Fill"
          style={{
            fill: props.colors.Patella2308Fill,
            stroke: props.colors.Patella2308Fill,
          }}
          d="M145.83,200.03c-14.8-11.4-20.1-25.7-22-31-6.7-18.7-3.5-34.9-2-41,1.4-5.6,7.1-27,28-41,3.7-2.4,20-13.4,40-11,6.6.8,6.9,2.2,32,12,23.3,9.1,22.9,7.8,28,11,4.3,2.7,23.5,14.9,27,37,2.4,15.1-3.9,27.1-9,37-4,7.6-10.8,20.7-25,28-4.8,2.5-5.8,1.9-28,6-28.5,5.3-29.5,6.7-37,6-2.7-.3-17.5-1.9-32-13Z"
        />
      </g>
    </svg>
  );
}
