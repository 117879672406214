import style from "./style.scss";

function BeideHandeDpSitzend1345(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 737.8 494.9"
    >
      <g id="bothhands">
        <g id="fullhandleft">
          <g id="outlinesrighthand">
            <path
              id="outlinepart24"
              class="beide_Hande_dp1345_cls3"
              d="M677.1,488.7c-2.2-9,0-19.6-.7-28.4-1.2-33.3-6.6-67.2.7-73.8,12.1-27.1,17.4-67.6,20.8-111.5,3.5-5.9,3.1-20.5,2.3-36.1-1.5-14.5,10.1-45.1,15.9-56.1,2.7-11.3,7.9-27.1,13.8-44,5.7-15.5,9-28.7,6.7-35.7.8-5.7-2-9.4-7.1-11.9-5.1-1.4-9.7-1.8-13.3,0-11.6-2.5-19.3,38.1-36.7,72.6-11.7,29.2-21,61.4-26.5,53.3-5.7-6.8-6.5-26.2,4.9-76.6l6.8-60.7c2.7-26,4.9-51.3-3-56.7-1.8-4.8-5.3-8-11.7-8.4-7.3-1-13.3.4-16.9,6.4-6.1,10.2-9.9,27.1-9.1,56.7l-9.9,46c-.7,31.6-1.8,61.4-9.4,65.9-3.8-10.9-4-32.7.3-67.4-1.3-18.4-.5-41.1,1.1-65.6.9-18.7,1.7-37.1-.6-45-2.5-5.6-7.1-9.2-15.4-9.4-6.1-.4-11.3,1.3-14.4,7.8-4,.9-8.5,22.5-13.1,53.2.3,16.4-.3,32-3,46,.2,27.8-.3,53.6-2.1,74.8-.7,11.9-3.8,20-12.5,18.8-4.2-13.8-6.5-39.6-8.8-65.8-3.2-8.9-4.6-21.4-5.5-34.6.2-29.8-2.8-51-11.2-57.2-3-4.2-7.1-6.7-12.7-6.5-4.9,0-9.5,2.1-13.8,7.3-7.3,9.7-7.9,48.8-4.7,104.2l2.9,15.6c2.8,44.8,3.9,91.1-8.8,120-10-9.9-19.5-27.3-28.9-46.6-8.9-15.7-24.6-30.3-42.7-44.5-9.3-6-17.4-5.1-24.5.2-2.5,1.9-3.5,5.2-2.6,8.1,3.1,9.2,9.6,19.6,16.3,30.1,3.8,15.4,7.9,29,13.4,37.5,14.7,16.7,25.3,44,34,75.8,15.3,19.2,32.9,38.9,52,58.9,2,2.4,10.7,9.2,18.5,15.4,7.6,4,10.8,12.2,13.1,21.4,3.5,12.6,5.5,31.7,6.9,52.8"
            />
            <g id="outlinesright">
              <path
                id="outlinepart23"
                class="beide_Hande_dp1345_cls10"
                d="M677.1,488.7c-.4-1.6-.6-3.2-.8-4.9"
              />
              <path
                id="outlinepart22"
                class="beide_Hande_dp1345_cls15"
                d="M676.2,473.8c.2-4.7.5-9.3.2-13.5-1.2-33.3-6.6-67.2.7-73.8,12.1-27.1,17.4-67.6,20.8-111.5,3.5-5.9,3.1-20.5,2.3-36.1-1.5-14.5,10.1-45.1,15.9-56.1,2.7-11.3,7.9-27.1,13.8-44,5.7-15.5,9-28.7,6.7-35.7.8-5.7-2-9.4-7.1-11.9-5.1-1.4-9.7-1.8-13.3,0-11.6-2.5-19.3,38.1-36.7,72.6-11.7,29.2-21,61.4-26.5,53.3-5.7-6.8-6.5-26.2,4.9-76.6l6.8-60.7c2.7-26,4.9-51.3-3-56.7-1.8-4.8-5.3-8-11.7-8.4-7.3-1-13.3.4-16.9,6.4-6.1,10.2-9.9,27.1-9.1,56.7l-9.9,46c-.5,23.2-1.2,45.4-4.6,57.4"
              />
              <path
                id="outlinepart21"
                class="beide_Hande_dp1345_cls10"
                d="M607.7,185.9c-.9,1.8-1.9,3.1-3.1,3.8-.5-1.4-.9-3-1.3-4.8"
              />
              <path
                id="outlinepart20"
                class="beide_Hande_dp1345_cls9"
                d="M602,175.2c-.9-12.3,0-29.7,2.9-52.9-1.3-18.4-.5-41.1,1.1-65.6.9-18.7,1.7-37.1-.6-45-2.5-5.6-7.1-9.2-15.4-9.4-2.7-.2-5.2,0-7.5,1"
              />
              <path
                id="outlinepart19"
                class="beide_Hande_dp1345_cls10"
                d="M578.4,5.9c-1.1,1.1-2,2.4-2.8,4.1-.9.2-1.9,1.6-2.9,3.9"
              />
              <path
                id="outlinepart18"
                class="beide_Hande_dp1345_cls4"
                d="M569.7,23.6c-2.3,9.4-4.7,23.2-7.2,39.6.3,16.4-.3,32-3,46,.2,27.8-.3,53.5-2.1,74.8-.4,6.4-1.5,11.8-3.7,15.1"
              />
              <path
                id="outlinepart17"
                class="beide_Hande_dp1345_cls10"
                d="M549.8,202.3c-1.4.6-3,.8-4.9.5-.5-1.5-.9-3.1-1.3-4.8"
              />
              <path
                id="outlinepart16"
                class="beide_Hande_dp1345_cls13"
                d="M541.7,188.3c-2.3-14.1-4-32.6-5.6-51.3-3.2-8.9-4.6-21.4-5.5-34.6.2-29.8-2.8-51-11.2-57.2-3-4.2-7.1-6.7-12.7-6.5-4.9,0-9.5,2.1-13.8,7.3-7.3,9.7-7.9,48.8-4.7,104.2l2.9,15.6c2.5,40.2,3.7,81.7-5.3,110.7"
              />
              <path
                id="outlinepart15"
                class="beide_Hande_dp1345_cls10"
                d="M484.2,281.2c-.6,1.6-1.2,3.1-1.9,4.6-1.1-1.1-2.3-2.4-3.4-3.7"
              />
              <path
                id="outlinepart14"
                class="beide_Hande_dp1345_cls11"
                d="M473,274.4c-6.6-9.6-13.1-21.9-19.5-35.1-8.9-15.7-24.6-30.3-42.7-44.5-9.3-6-17.4-5.1-24.5.2-2.5,1.9-3.5,5.2-2.6,8.1,3.1,9.2,9.6,19.6,16.3,30.1,3.8,15.4,7.9,29,13.4,37.5,14.7,16.7,25.3,44,34,75.8,15.3,19.2,32.9,38.9,52,58.9,2,2.4,10.7,9.2,18.5,15.4,7.6,4,10.8,12.2,13.1,21.4,3,10.6,4.8,25.8,6.2,42.9"
              />
              <path
                id="outlinepart13"
                class="beide_Hande_dp1345_cls10"
                d="M537.4,489.9c.1,1.6.2,3.3.3,5"
              />
            </g>
          </g>
          <g id="outlineslefthand">
            <path
              id="outlinepart12"
              class="beide_Hande_dp1345_cls3"
              d="M60.7,487.1c2.2-9,0-19.6.7-28.4,1.2-33.3,6.6-67.2-.7-73.8-12.1-27.1-17.4-67.6-20.9-111.5-3.5-5.9-3.1-20.5-2.3-36.1,1.5-14.5-10.1-45.1-15.9-56.1-2.7-11.3-7.9-27.1-13.8-44-5.7-15.5-9-28.7-6.7-35.7-.8-5.7,2-9.4,7.1-11.9,5.1-1.4,9.7-1.8,13.3,0,11.6-2.5,19.4,38.1,36.7,72.6,11.7,29.2,21,61.4,26.5,53.3,5.7-6.8,6.5-26.2-4.9-76.6l-6.8-60.7c-2.7-26-4.9-51.3,3-56.7,1.8-4.8,5.3-8,11.7-8.4,7.3-1,13.3.4,16.9,6.4,6.1,10.2,9.9,27.1,9.1,56.7l9.9,46c.7,31.6,1.8,61.4,9.4,65.9,3.9-10.9,4-32.7-.3-67.4,1.3-18.4.5-41.1-1.1-65.6-.9-18.7-1.6-37.1.6-45,2.5-5.6,7.1-9.2,15.4-9.4,6.1-.4,11.3,1.3,14.4,7.8,4,.9,8.4,22.5,13.1,53.2-.3,16.4.3,32,3.1,46-.2,27.8.3,53.6,2.1,74.8.7,11.9,3.8,20,12.5,18.8,4.2-13.8,6.6-39.6,8.8-65.8,3.2-8.9,4.6-21.4,5.5-34.6-.2-29.8,2.8-51,11.2-57.2,3-4.2,7.1-6.7,12.7-6.5,4.9,0,9.5,2.1,13.8,7.3,7.3,9.7,7.9,48.8,4.7,104.2l-2.9,15.6c-2.8,44.8-3.9,91.1,8.8,120,10-9.9,19.5-27.3,28.9-46.6,8.9-15.7,24.6-30.3,42.7-44.5,9.3-6,17.4-5.1,24.5.2,2.5,1.9,3.5,5.2,2.6,8.1-3.1,9.2-9.6,19.6-16.3,30.1-3.8,15.4-7.9,29-13.4,37.5-14.7,16.7-25.3,44-34,75.8-15.3,19.2-32.9,38.9-51.9,58.9-2,2.4-10.7,9.2-18.5,15.4-7.6,4-10.8,12.2-13.1,21.4-3.5,12.6-5.5,31.7-6.9,52.8"
            />
            <g id="outlinesleft">
              <path
                id="outlinepart11"
                class="beide_Hande_dp1345_cls10"
                d="M60.7,487.1c.4-1.6.6-3.2.8-4.9"
              />
              <path
                id="outlinepart10"
                class="beide_Hande_dp1345_cls15"
                d="M61.6,472.2c-.2-4.7-.5-9.3-.2-13.5,1.2-33.3,6.6-67.2-.7-73.8-12.1-27.1-17.4-67.6-20.9-111.5-3.5-5.9-3.1-20.5-2.3-36.1,1.5-14.5-10.1-45.1-15.9-56.1-2.7-11.3-7.9-27.1-13.8-44-5.7-15.5-9-28.7-6.7-35.7-.8-5.7,2-9.4,7.1-11.9,5.1-1.4,9.7-1.8,13.3,0,11.6-2.5,19.4,38.1,36.7,72.6,11.7,29.2,21,61.4,26.5,53.3,5.7-6.8,6.5-26.2-4.9-76.6l-6.8-60.7c-2.7-26-4.9-51.3,3-56.7,1.8-4.8,5.3-8,11.7-8.4,7.3-1,13.3.4,16.9,6.4,6.1,10.2,9.9,27.1,9.1,56.7l9.9,46c.5,23.2,1.2,45.4,4.6,57.4"
              />
              <path
                id="outlinepart9"
                class="beide_Hande_dp1345_cls10"
                d="M130.2,184.3c.9,1.8,1.9,3.1,3.1,3.8.5-1.4.9-3,1.3-4.8"
              />
              <path
                id="outlinepart8"
                class="beide_Hande_dp1345_cls9"
                d="M135.8,173.6c.9-12.3,0-29.7-2.9-52.9,1.3-18.4.5-41.1-1.1-65.6-.9-18.7-1.6-37.1.6-45,2.5-5.6,7.1-9.2,15.4-9.4,2.7-.2,5.2,0,7.5,1"
              />
              <path
                id="outlinepart7"
                class="beide_Hande_dp1345_cls10"
                d="M159.4,4.3c1.1,1.1,2,2.4,2.8,4.1.9.2,1.9,1.6,2.9,3.9"
              />
              <path
                id="outlinepart6"
                class="beide_Hande_dp1345_cls4"
                d="M168.1,22c2.3,9.4,4.7,23.2,7.2,39.6-.3,16.4.3,32,3.1,46-.2,27.8.3,53.6,2.1,74.8.4,6.4,1.5,11.8,3.7,15.1"
              />
              <path
                id="outlinepart5"
                class="beide_Hande_dp1345_cls10"
                d="M188,200.7c1.4.6,3,.8,4.9.5.4-1.5.9-3.1,1.3-4.8"
              />
              <path
                id="outlinepart4"
                class="beide_Hande_dp1345_cls13"
                d="M196.2,186.7c2.4-14.1,4-32.6,5.6-51.3,3.2-8.9,4.6-21.4,5.5-34.6-.2-29.8,2.8-51,11.2-57.2,3-4.2,7.1-6.7,12.7-6.5,4.9,0,9.5,2.1,13.8,7.3,7.3,9.7,7.9,48.8,4.7,104.2l-2.9,15.6c-2.5,40.2-3.7,81.7,5.3,110.7"
              />
              <path
                id="outlinepart3"
                class="beide_Hande_dp1345_cls10"
                d="M253.6,279.7c.6,1.6,1.2,3.1,1.9,4.6,1.1-1.1,2.3-2.4,3.4-3.7"
              />
              <path
                id="outlinepart2"
                class="beide_Hande_dp1345_cls11"
                d="M264.8,272.8c6.6-9.6,13.1-21.9,19.5-35.1,8.9-15.7,24.6-30.3,42.7-44.5,9.3-6,17.4-5.1,24.5.2,2.5,1.9,3.5,5.2,2.6,8.1-3.1,9.2-9.6,19.6-16.3,30.1-3.8,15.4-7.9,29-13.4,37.5-14.7,16.7-25.3,44-34,75.8-15.3,19.2-32.9,38.9-51.9,58.9-2,2.4-10.7,9.2-18.5,15.4-7.6,4-10.8,12.2-13.1,21.4-3,10.6-4.8,25.8-6.2,42.9"
              />
              <path
                id="outlinepart1"
                class="beide_Hande_dp1345_cls10"
                d="M200.4,488.3c-.1,1.6-.2,3.3-.3,5"
              />
            </g>
          </g>
          <path
            id="Bonepart77"
            class="beide_Hande_dp1345_cls10"
            d="M193.2,383c-3.4-5.1-1.3-9.6,4.4-13.5,3.4-3.6,8.8-6,14.9-7.9,13.9-4.7,25.4-11.4,33.1-21.5,2.8-3.6,3.8-8.2,3.2-12.7-1.2-9.1.7-14.4,5.9-15.3,6.3-1.9,12.2-1.6,17.6,2.1,6.3,1.7,9.2,9.1,9.7,20.4.8,3.5-1.5,6.7-6.7,9.7-17,12.7-32.1,29-45.9,47.6-5.4,6.1-10.5,8.6-15.4,6.5-7.5-1.1-10.3-4.9-10.2-10.1l-10.6-5.2h0Z"
          />
          <path
            id="Bonepart76"
            class="beide_Hande_dp1345_cls10"
            d="M258.7,300.6c-.1-2.5,1.2-4.9,3.1-7.2,13.4-8.2,23.9-17.2,28.5-27.9,1.1-2.5,1.3-5.4.7-8.1-1.3-6.1.2-10.4,5-12.8,5.7-1.6,9.8,1,12.3,7.6.5,1.4,1.7,2.5,3.1,3,4.4,1.6,5.8,4.1,2.9,8-10.1,15.8-19.4,31.4-22.7,45-1.5,8.2-3.9,11.5-7.2,10.5-5.8-7.1-13-11.7-21.5-13.5-2.3-.5-4.1-2.3-4.2-4.6h0Z"
          />
          <path
            id="Bonepart75"
            class="beide_Hande_dp1345_cls10"
            d="M310.3,250.3c-1-5.1-3.9-8.7-9.2-10.7-1.4-6.1,1.5-8.9,7.3-9.5l14.9-13.2c-1.2-5.4,3.8-9.5,15.5-12.2,3.6-.6,6.5-.2,5,6.7-1.7,6.1-3.8,9-6.3,8.9-7.2,8.9-12.8,19.4-16.4,31.7-.4,1.4-2.1,2-3.3,1.2-2.8-1.9-5.4-3-7.5-3h0Z"
          />
          <path
            id="Bonepart74"
            class="beide_Hande_dp1345_cls10"
            d="M301.1,239.6c-.7,4-.4,7.6,2,9.8,2.2,1.4,4.6,1.9,7.2.9-.4,1.2-.4,2.1,0,2.6,2.6,2.3,5.1,2.2,7.5.3"
          />
          <path
            id="Bonepart73"
            class="beide_Hande_dp1345_cls10"
            d="M260.3,304c-.3,1.5-.1,2.8,1,3.7,3,1.6,6.6,1.4,10.5.7.2,4.4,1.1,8.6,5,11.5,3.3,1.3,5.9.9,7.6-1.1"
          />
          <path
            id="Bonepart72"
            class="beide_Hande_dp1345_cls10"
            d="M257.4,319.8c-1.6-1.2-2.3-3.4-1.6-5.2.8-2,2.9-2.6,6.5-1.5,2.8.9,5,2.1,6,3.9,1.8,3.5.3,5.2-3,5.9-3.5-.7-6.2-1.7-8-3Z"
          />
          <path
            id="Bonepart71"
            class="beide_Hande_dp1345_cls10"
            d="M248.2,305.3c3.4-.4,4.6.8,3.6,3.6-.2.9-.7,1.6-1.4,2.2-1.3,1.1-3.2,1.1-4.5,0-1.1-.9-1.1-1.9-.9-3,1.1-1.9,2.1-3,3.1-3h0Z"
          />
          <path
            id="Bonepart70"
            class="beide_Hande_dp1345_cls10"
            d="M179,373.7c.5.9,1.3,1.5,2.3,1.9,5.7,2.2,10.8-1.9,15.2-11.7,1.1-2.5,1.4-5.1.6-7.9-7.4-13.9-5.3-31.1,1.4-49.8l15.4-36.2c1.1-2.7,3.2-4.8,5.8-6.1h0c2-1,3.4-2.8,3.8-5,2.5-13.8-.2-22.4-6.6-27.6-6.4-4.6-12.1-2.5-17.2,3.1-2.7,0-4.5,1.9-5.6,5l-3.3,3.6c-.6.7-.9,1.6-.9,2.5.8,27.4-4.5,56.3-18.5,87.3-1.7,2.6-4.6,4.4-8,5.8-2.5,9.5-7,17.5-13.3,24.3-1.2,1.3-.5,2.9,2.1,4.9,1.3,1,3,1,4.3,0,4.3-3.6,9.3-4.2,14.9-2.1,2.7,1,5.2,4.1,7.6,7.9h0Z"
          />
          <path
            id="Bonepart69"
            class="beide_Hande_dp1345_cls10"
            d="M199.7,234.6l-1.1,5-1.8,3.5c0,2.6-.7,5-2.7,7.2-.2,3,0,6.1.6,9.5"
          />
          <path
            id="Bonepart68"
            class="beide_Hande_dp1345_cls10"
            d="M218,249.1c-.7,1.7-.5,3.2,1,4.5,2.4,3.4,0,8.5-3.6,13.8"
          />
          <path
            id="Bonepart67"
            class="beide_Hande_dp1345_cls10"
            d="M224.4,233.3c5.4,1.1,8.2-2,8.4-9.5l-3.7-22.2c-.3-1.8-.3-3.7,0-5.5,1.4-8.8,1.9-17.9,1.8-27.1,0-1.5.4-2.9,1-4.2,1.9-3.7,2.9-7.5,3.1-11.5.6-8.5-2.6-10.3-7.6-8.7-3,.6-6,0-9-1.2-3.7-1.8-6.3-1-8,2.1-2,4.8-3.2,9.3-1.8,12.7.4.9.6,1.9.5,2.8l-2.5,24.9c-.7,9.6-4.1,19.1-9,28.5-2.1,7.5-2.7,13.6,2.5,14.7,3.8-3.8,8-5.1,12.6-4.3,5.8,1,9.1,4.3,11.6,8.5h0Z"
          />
          <path
            id="Bonepart66"
            class="beide_Hande_dp1345_cls10"
            d="M200.1,229.1l8.2,2.4c5.7,2,11.1,2.7,16,1.8"
          />
          <path
            id="Bonepart65"
            class="beide_Hande_dp1345_cls10"
            d="M208.1,152.9c2.4-.9,3.7-2.2,4.1-3.8l-1-4.9"
          />
          <path
            id="Bonepart64"
            class="beide_Hande_dp1345_cls10"
            d="M234.4,157.5c-1.3-.6-2.6-3.1-3.2-5.6,0-2.2.6-4.4,2.4-6.4"
          />
          <path
            id="Bonepart63"
            class="beide_Hande_dp1345_cls10"
            d="M212.2,138.7c-3.5-2.3-2.1-5.9.8-10,5.1-5.8,6.9-13.2,5.9-22.1-1.8-3-1.5-6.1,0-9.3-.4-4.8,1-6.3,3.5-5.5,3.9.9,7.5,1.2,10.4,0,4.1-1.6,5.4.7,5.1,5.2,1.9,2.8.7,5.8-2.9,8.9-1,9.9,0,19.2,2.9,28-.2,4.3-2,6.3-5.1,6.5-5.1,3-9.5,2.7-13.3-1-2.5-1.8-5-2.2-7.2-.7h0Z"
          />
          <path
            id="Bonepart62"
            class="beide_Hande_dp1345_cls10"
            d="M234.4,140.2c-.6,1-1.7,2-3,2.8-4.7,2.9-10.7,2.5-15.3-.6-1.7-1.1-2.9-2.3-3.9-3.6"
          />
          <path
            id="Bonepart61"
            class="beide_Hande_dp1345_cls10"
            d="M223.3,88.7c-7.1-.1-7.3-2.8-4.6-6.5,3.8-3.5,4.6-10.6,4.6-18.8-3.8-2.4-2.2-6.4,0-10.6.7-4.6,3.2-6.7,7.9-5.2,3.1,1.6,5,3.9,5.4,7,2.6,5.3,1.5,8.1-1.5,9.8l2.6,19.1c.5,1.4.7,2.8-.3,4-.9,1.1-2.4,1.7-3.8,1.6l-10.1-.4h0Z"
          />
          <line
            id="Bonepart60"
            class="beide_Hande_dp1345_cls10"
            x1="223.3"
            y1="56.4"
            x2="223.3"
            y2="63.4"
          />
          <line
            id="Bonepart59"
            class="beide_Hande_dp1345_cls10"
            x1="234.4"
            y1="58.7"
            x2="235.1"
            y2="64.3"
          />
          <path
            id="Bonepart58"
            class="beide_Hande_dp1345_cls10"
            d="M237.3,87.5c-4.6-1.6-9.3-2.2-13.9-1.6"
          />
          <path
            id="Bonepart57"
            class="beide_Hande_dp1345_cls10"
            d="M137.7,359.5c7.6,1.9,13.6.8,16.6-5.9,5.7-6.5,5.6-13.3,1.2-20.5-4.3-22.9-2.1-40.3,2.3-55.9,1.4-5.1,3.2-9.4,5.6-12.8,2.4-1.2,3.3-3.4,3.3-6.1,3.3-.6,5.4-2.5,6.6-5.4v-17.1c.3-4.9-2.3-8.1-6.4-10.4-7.8-2.8-14.5-1.6-20.3,2.7-.2,2.2-1.8,4.8-4,7.6-3.9,3.6-4.5,10.2-3.1,18.8.4,16.7-1.2,38-3.5,60.9-1.8,5.8-4,10.2-6.9,12.1-.7.5-1.4,1.1-1.7,1.9-2.4,5.2-4.4,10.7-5.7,16.6-.5,2.3.1,4.7,1.8,6.3,3.5,3.3,8,5.9,14.1,7.1h0Z"
          />
          <path
            id="Bonepart56"
            class="beide_Hande_dp1345_cls10"
            d="M150.2,322.3l.9,9.3c.1,1.1.5,2.1,1.1,3,.7,1.1,1.3,2.2,1.6,3.4.5,1.9,0,3.9-1.2,5.4h0c-.8,1-1.1,2.2-1,3.4l.5,4.1"
          />
          <path
            id="Bonepart55"
            class="beide_Hande_dp1345_cls10"
            d="M167.4,229l-.8,7.4c-.1,1,.2,2,.9,2.7,2.8,3,3.8,6.8,1.8,11.9-3.1,3.4-5.4,10.1-6.9,15.1"
          />
          <path
            id="Bonepart54"
            class="beide_Hande_dp1345_cls10"
            d="M148.7,229.9c-.1,1.8-.7,3.6-1.8,5.2-3.9,6.9-4.6,13.4-.6,19.5"
          />
          <path
            id="Bonepart53"
            class="beide_Hande_dp1345_cls10"
            d="M129.5,337.5c-.6-3.7.4-7.8,2-12"
          />
          <path
            id="Bonepart52"
            class="beide_Hande_dp1345_cls10"
            d="M142,223.2c-5.2-4.8-2.9-14.4,1.6-25.5,1.7-7.9,1.6-18,1-28.6-1.7-6.6-2-14.1-1-22.2.6-2.9-.2-6.4-1.4-10-.6-6-1.4-12.2,3.9-13.4l10.3.8c4.9-2.7,8.7-3.4,9.8.6,2.4,2.8,4,6,2.8,10.8-1.8,4.9-2.7,9.6-1.4,13.9-1.1,20.5-1.4,39.2,2.5,49.2,6.4,8.9,9,16.7,5.1,22.8-1.1,1.8-3.3,2.5-5.4,2-8.2-2-16.5-1.8-24.9.4-1.1.3-2.4,0-3.2-.8h0Z"
          />
          <path
            id="Bonepart51"
            class="beide_Hande_dp1345_cls10"
            d="M142.2,136.3c.8-.6,1.5-1.3,2.1-2.1,1.9-2.6,2.1-6.2.3-8.8-.3-.4-.6-.8-1-1.2"
          />
          <path
            id="Bonepart50"
            class="beide_Hande_dp1345_cls10"
            d="M168.7,137c-.9-.7-1.7-1.6-2.6-2.9-.9-1.3-1.4-2.9-1.3-4.5s.7-3.1,2.1-4.1"
          />
          <path
            id="Bonepart49"
            class="beide_Hande_dp1345_cls10"
            d="M147.9,220.4c7.1-2.9,14.2-2.9,21.3,0"
          />
          <path
            id="Bonepart48"
            class="beide_Hande_dp1345_cls10"
            d="M141.1,117.4c-.8-2.8-.1-6.1,1.1-9.6,3-15.5,4.1-28.1,0-32.8-2.1-2.5-1.8-5.4,0-8.5-.4-3.3-.4-6.1,2.7-5.5,3.3.4,6.9-.5,10.6-2.3,6.3-1.3,9.5.6,9.3,6.3.6,3.3-.2,5.6-1.9,7.2-.7,11.4,1.6,22.6,6.4,33.6,2.1,3.7,2.6,7.2.6,10.4-.8,1.3-2.2,2.1-3.7,2.1-2.4,0-4.9.7-7.4,1.8-1.2.6-2.6.7-3.9.4-3.7-1-7-1.3-9.8-.7-1.7.4-3.6-.6-4-2.3h0Z"
          />
          <path
            id="Bonepart47"
            class="beide_Hande_dp1345_cls10"
            d="M143.5,24.8c.4,13.4-.6,22.7-3.5,26.8-.7,1-.7,2.3-.1,3.3,1.1,1.8,2.8,2.5,5.2,2.3l11.7-.7c1.2,0,2.4-.2,3.5-.6,2.7-.8,3.6-2,2.8-3.6-3-.7-5.2-11.1-7.2-24.1"
          />
          <path
            id="Bonepart46"
            class="beide_Hande_dp1345_cls10"
            d="M144,33.2c-3.4.2-5.2-2.3-4.6-8.9,1.2-5.8,3.2-10.6,8.6-11.1,3.8-1,7,.5,9.3,6.9,2.6,6.8,1.8,10.3-.8,12.3"
          />
          <path
            id="Bonepart45"
            class="beide_Hande_dp1345_cls10"
            d="M127.9,350.9c-.5,6.2-3.1,9.7-9.5,8.4l-22.9-7.8c-4.5-1.8-4.3-5.4-1.4-9.9,2.5-6.2,5.5-10.2,8.7-12.3,1.4-.9,2.2-2.6,2.2-4.3v-32.9c0,0-3.8-33.3-3.8-33.3-3.4-3.9-3.2-8.5,0-13.7.2-8.2,3.1-12.8,9.7-12.2,5.9-1.2,10,.1,12.8,3.4,2.3,5.5,3.6,10.4,4.1,14.7-.9,7.8-3.2,15.8-6.3,24-1.1,17.5-1.6,33.8,0,46.4,4.1,7.1,6.4,16.5,6.3,29.4h0Z"
          />
          <path
            id="Bonepart44"
            class="beide_Hande_dp1345_cls10"
            d="M107.6,260.9c-3.1-1.2-4.7-3.7-4-8l.9-6.3c.4-2.9-.7-5.3-2.4-7.5"
          />
          <path
            id="Bonepart43"
            class="beide_Hande_dp1345_cls10"
            d="M121.6,239.6c.7,4.1,2.8,7.9,6.3,11.5"
          />
          <path
            id="Bonepart42"
            class="beide_Hande_dp1345_cls10"
            d="M101.7,334.6c1.8-1.4,2.4-4.4,2.9-7.2"
          />
          <path
            id="Bonepart41"
            class="beide_Hande_dp1345_cls10"
            d="M95.7,230.7c-1.1-1.5-1.5-3.8-1.5-6.6,0-1.1.2-2.2.6-3.3l2.8-7c.4-1,.7-2.1.8-3.2,1.6-12.5-.2-25.1-2.2-37.6-1.1-4.1-1-8.7-.8-13.4,0-1.5-.8-3.2-2.2-5-1.6-6.5-2.3-12.6-1.2-17.7,2.6-2.4,5.6-3,8.8-2,7.3-3,11.8-3.8,12.3-1.1,2.8,1.9,4.2,5.5,4.3,10.3l-1.3,7.8c-.2,1.3-.1,2.6.2,3.9,1.3,4.7,2,9.3,1,13.5.1,19,2,36.3,10.4,47.5,1.8,3.9,2.1,7.7.3,11.1-.8,1.6-2.7,2.4-4.5,2-8-1.9-15.7-1.4-22.8,1.9-1.8.8-4,.4-5.2-1.2h0Z"
          />
          <path
            id="Bonepart40"
            class="beide_Hande_dp1345_cls10"
            d="M113.2,133.8c-.8,2.1-1,4.4,0,7.3,1.4,2.8,2.8,4.1,4.2,3.8"
          />
          <path
            id="Bonepart39"
            class="beide_Hande_dp1345_cls10"
            d="M92.1,137c1.9,2.2,2.7,4.6,2.5,7.2.5,3.8,0,7-1.4,9.7"
          />
          <path
            id="Bonepart38"
            class="beide_Hande_dp1345_cls10"
            d="M99.5,229c6.8-3.3,14.6-3.9,23.5-2.1"
          />
          <path
            id="Bonepart37"
            class="beide_Hande_dp1345_cls10"
            d="M102.9,231.4c2.2,1.7,5.7,1.8,9.6,1.3,3.9-.4,6.6-1.6,8.6-3.2"
          />
          <path
            id="Bonepart36"
            class="beide_Hande_dp1345_cls10"
            d="M93.4,132.2c-4.5,2.4-5.8-1.2-5.9-7.4l1.5-24.6c1.6-6.4-4.4-8.1-4.9-15.6-.6-6.5,0-10.9,4.7-9,4.4.3,8.9-.1,13.5-1.4,3-1.2,3.7.6,3.4,3.7,1.3,2.6,1.5,5.2.7,7.8-2.1,6.1-2.1,11.7,1.4,16.4,2.8,12.2,11.4,25.2,6.4,25.9-3.6-.4-7.3,1.1-11.1,3.9-3.7-.9-6.9-.7-9.6.4h0Z"
          />
          <path
            id="Bonepart35"
            class="beide_Hande_dp1345_cls10"
            d="M83.8,69.9c-4.1-.8-3.3-3.4-1.2-6.5,3.4-2.8,4.7-9.5,4-19.9-3.4,0-3.7-4-2.8-9.2.1-6.3,3.3-8.1,7.7-8.3,5.4.6,7.7,2.5,8.2,5.2,2.7,7.3,2.4,11.5-1,12.3-.3,7.2.8,13.6,4.7,18.6,3.2,2.9,3.9,5.3-.9,7,.2,1.3-1.8,2.1-5.7,2.5-5.5.5-10.1.2-12.9-1.6h0Z"
          />
          <line
            id="Bonepart34"
            class="beide_Hande_dp1345_cls10"
            x1="86.4"
            y1="37"
            x2="86.7"
            y2="43.5"
          />
          <line
            id="Bonepart33"
            class="beide_Hande_dp1345_cls10"
            x1="97.7"
            y1="37"
            x2="98.7"
            y2="43.5"
          />
          <path
            id="Bonepart32"
            class="beide_Hande_dp1345_cls10"
            d="M88,68.9c1.8,1.4,4.1,1.5,6.7.7,3.8-1,6.5-1.2,7.8-.6"
          />
          <path
            id="Bonepart31"
            class="beide_Hande_dp1345_cls10"
            d="M144.6,55c4.3-1.7,9.1-2,14.4-1.5"
          />
          <path
            id="Bonepart30"
            class="beide_Hande_dp1345_cls10"
            d="M92.1,364.2l-.6-.3c-6.3,1.2-11.3,0-12.6-8-3.3-3.3-3.9-8.8-3.9-14.8l1.4-8.2c-2.1-26.4-18.6-51.3-17.9-65.5-1.9-5-2.1-9.8,0-14.4,3.5-3.1,7.8-5.2,13.6-5.5,4.8.6,8.3,4.4,10.6,11.2.2.6.2,1.4.1,2-1.7,10-1.8,19.4-.2,28.2,5.1,18.1,10.3,35.4,17.5,44.1,2.9,3,3.6,6.3,2.7,9.8,5,6,6.4,11.7,2.4,16.7-2.4,3.4-8.7.8-13.3,4.6h0Z"
          />
          <path
            id="Bonepart29"
            class="beide_Hande_dp1345_cls10"
            d="M58.5,266.8c3-3.3,3-7.9,0-13.8"
          />
          <path
            id="Bonepart28"
            class="beide_Hande_dp1345_cls10"
            d="M76.3,249.1c0,6,1.2,11.6,5.9,15.9"
          />
          <path
            id="Bonepart27"
            class="beide_Hande_dp1345_cls10"
            d="M55.1,250.7c-3,1.6-5.3.7-6.5-3.7-.2-.7-.3-1.4-.2-2.1l.9-16.7c0-1.4-.2-2.9-.7-4.2-2.6-6.2-4.1-12.3-4.6-18.4-.2-2.4-.9-4.6-2.2-6.6-3.5-5.2-6.5-10.3-6.8-14.8-.1-1.6.7-3.1,2-3.9s3.3-1.3,5.7-1.7c1.3-.2,2.4-.8,3.3-1.7,2.9-3.2,5.2-2.9,7.1-.2,1.7,3.2,2.6,6.5,2.6,10.1,0,1.7.4,3.4,1.2,5,6,12.7,9.2,26.7,14,34.2,5.4,5.1,8.8,10.1,8,14.8-.4,2.1-2.3,3.4-4.4,3.3-7.2-.3-13.1,1.3-17.6,5.3-.6.5-1.2,1-1.8,1.4h0Z"
          />
          <path
            id="Bonepart26"
            class="beide_Hande_dp1345_cls10"
            d="M55.4,247.1c5-4.7,10.9-6.5,17.9-5.4"
          />
          <path
            id="Bonepart25"
            class="beide_Hande_dp1345_cls10"
            d="M57,249.3c2.5,1.6,5.7,1.4,9.6-.2,4.1-.7,7.2-2.2,7.9-5.2"
          />
          <path
            id="Bonepart24"
            class="beide_Hande_dp1345_cls10"
            d="M38,193c1.8-1.4,2.3-3.5,1.7-6.3-.7-2.3-2.2-3.7-4.4-4.4"
          />
          <path
            id="Bonepart23"
            class="beide_Hande_dp1345_cls10"
            d="M55.4,183.1c-2-.2-3.2-1.4-3.7-3.8-.7-1.3-.7-2.6.3-3.8"
          />
          <path
            id="Bonepart22"
            class="beide_Hande_dp1345_cls10"
            d="M35,176.6c-4.4,2.3-6.8.5-7.2-5-.5-4.8-1.1-9.7-1.7-14.7-.2-1.8-1.1-3.4-2.4-4.5-3-2.5-4.6-5.3-4.4-8.2,0-1.7,1.4-3.1,3.1-3.5,3.5-.8,6.6-2.1,9.1-4,1.1-.9,2.7-1,3.8-.1,1.8,1.3,3.2,3.8,3.9,7.7-1.5,4.1,1.5,10.6,7.8,18.9,3.8,4.3,3.2,7,0,8.8-3.7,2.8-7.6,4.7-11.9,4.7h0Z"
          />
          <path
            id="Bonepart21"
            class="beide_Hande_dp1345_cls10"
            d="M33.9,177.1c-1.5,2.7-3,2.9-4.5-.4"
          />
          <path
            id="Bonepart20"
            class="beide_Hande_dp1345_cls10"
            d="M21.6,137.6c-5.1,3-5.7-3.4-4.9-12.7l-2.3-8.5c-4.4-1.8-5.5-4.8-4.7-8.5.2-.9.2-1.8,0-2.6-.7-2.3.6-4,3.5-5.1,2.7-1.1,5.9-.4,7.8,1.8,1.8,2.1,3,5.2,3.8,9.4l-.4.8c-.5,1.1-.5,2.3-.1,3.3,1.4,4,3.8,7.3,7.3,10,3.6,4.4,3.2,7.5-2.2,8.9-2.8.2-5.4,1.5-7.8,3.2h0Z"
          />
          <line
            id="Bonepart19"
            class="beide_Hande_dp1345_cls10"
            x1="12.6"
            y1="109.9"
            x2="14.6"
            y2="116.9"
          />
          <path
            id="Bonepart18"
            class="beide_Hande_dp1345_cls10"
            d="M212.2,242.8c1.8-2.4,3.2-1.4,4.4,1.2.4,1.2,0,2.4-1.1,3.6-1.6,1.6-3,1.1-4.2-1.7-.3-1.1,0-2.1.8-3.1h0Z"
          />
          <path
            id="Bonepart17"
            class="beide_Hande_dp1345_cls10"
            d="M66.6,257.1c1.4-1.1,2.4-.8,3.1,1.2,1,2.9.4,4-1.5,3.5-1.2.7-1.9-.1-2.4-2l.9-2.6Z"
          />
          <path
            id="Bonepart16"
            class="beide_Hande_dp1345_cls10"
            d="M188.5,370.8c6.8-2,9.6,1.2,9.7,7.9-.4,7.2.7,13.6,6.5,17.7,1.6,2.9-2.4,5.9-2.7,9.2,0,.7-.3,1.4-.6,2-1.7,2.7-4.5,3.6-7.9,3.4-2.4-1.7-5.5-2.1-9-2.1-1.2,0-2.3-.7-2.7-1.9-1.3-4.1-3.9-8.2-7.3-12.4,0-6.6,1.6-11.3,4.1-14.4l6.2-7.2c1-1.1,2.3-2,3.8-2.5h0Z"
          />
          <path
            id="Bonepart15"
            class="beide_Hande_dp1345_cls10"
            d="M182.6,379.9c1.6,3.8-.7,7.3,0,10.7.6,3.9,2.8,6.2,8.3,4.9,3,.5,5-3.9,6.7-10.4"
          />
          <path
            id="Bonepart14"
            class="beide_Hande_dp1345_cls10"
            d="M157,370.9c3.6-3.8,7.9-4.8,13.3-2.1,7.2,4.3,8.9,10.3,8.1,17.2-.8,4.9-2.4,7.7-4.4,9-1,.7-2.4.5-3.4-.3-3.9-3.5-7.8-5.9-11.7-6.6-1.1-.2-1.9-1.2-1.9-2.3,0-4.9-.1-9.3-.8-12.2-.2-.9,0-1.9.7-2.6h0Z"
          />
          <path
            id="Bonepart13"
            class="beide_Hande_dp1345_cls10"
            d="M146.2,403.2c1.5-.9,2.5-2.4,2.8-4.2.8-4.6,2.3-6.8,4.4-6.8,6.5-.6,11.7.9,15.2,5.2.4.5.9,1,1.4,1.4,4.7,4.1,7,7.7,6,10.6-.4,1.1-.1,2.3.5,3.3,1.9,3.1.7,5.9-2.8,8.4-1.3.9-2.4,2-3.5,3.1-8.2,8.8-16.8,11.9-25.5,12.1-2.1,0-4.1-.2-6-1-3.3-1.3-5.1-3.6-5.5-6.6-.2-1.3,0-2.7.3-4,1-3.3,2.1-6.5,3.6-9.4,2.2-5.7,5.2-9.7,9.1-12.1h0Z"
          />
          <path
            id="Bonepart12"
            class="beide_Hande_dp1345_cls10"
            d="M136,417.8c4.3-.9,8.3-2.7,11.5-6.8,3.6-3.1,3.2-6,.8-8.8-.1-.1,0-.4.2-.3,3,.9,4.8-.4,5.5-3.7v-6.2"
          />
          <path
            id="Bonepart11"
            class="beide_Hande_dp1345_cls10"
            d="M114.2,394c-1,3.2-1.3,6.6-.7,9.8.8,4.2,2.7,6.8,6.1,7.2,8.5,2.1,16.5,2.4,23.5-.7,3.8-1.9,5.3-4.9,5.5-8.4,1.5-3.4,1.7-6.5-1.1-8.7-1-1-1.4-2-1.4-3.2,0-2.1,1.3-4,3.2-4.9l.6-.3c2.3-1.1,3.8-3.3,3.8-5.8,0-3.4-1.7-6.1-4.3-8.2-.8-.7-1.4-1.6-1.7-2.6-1.1-5.2-5-6.6-10.2-6.5-1.5,0-3-.4-4.3-1.3-7.9-5.4-12.4-6-14.9-3.7-.7,1.3-1.9.5,0,11.6l.6,7.4c.1,1.4,0,2.7-.4,4l-4.4,14.3h0Z"
          />
          <path
            id="Bonepart10"
            class="beide_Hande_dp1345_cls10"
            d="M85.7,356.8c-1.5,1.9-2.7,3.9-3.4,5.9,0,7.8,4,11.9,4.9,16.7.2,1.3,1,2.4,2.1,3,6.9,4,12.8,9.8,16.4,19.1.5,1.2,1.3,2.3,2.4,3,4,2.5,6.9.7,9.2-4,.2-.4.3-.8.5-1.2,1.8-6.1,2.7-13.1,2.9-20.7,1.4-13.2-.5-18.7-4.2-20l-25.4-4c-2.1-.3-4.2.5-5.5,2.1h0Z"
          />
          <path
            id="Bonepart9"
            class="beide_Hande_dp1345_cls10"
            d="M97.8,365.1c3.6.4,7.1-.4,10.6-2.9,2.3-3,4.7-3.7,7.2-1.4,1.5,1.1,1.7,2.5.9,4.2-.6,1.2-1,2.5-1.1,3.8l-.4,4.5c-.2,2.6-1.1,5.2-2.6,7.4-2.3,3.3-4.8,5-7.8,3.3-2.9-5.7-2-9.6-5.9-11.6"
          />
          <path
            id="Bonepart8"
            class="beide_Hande_dp1345_cls10"
            d="M78.4,411.9c-.4-2-1.2-3.9-2.5-5.5-2.5-3.1-2.1-6.6.2-10.5,2.1-4.4,5.7-6.9,11.4-7.2,5.3.3,9.5,3,11.7,9.8,2.2,5,4.9,9.7,10.8,12,2.1.9,1.2,3-2.3,6.1-2.1,1-3.9,3.1-5.4,6.3-1.2,2.7-3.8,4.5-6.7,4.8-3.5.3-6.6-.2-9-2-4.9-4-7.2-8.7-8.2-13.9h0Z"
          />
          <path
            id="Bonepart7"
            class="beide_Hande_dp1345_cls10"
            d="M81.3,411.1c0-1.9.3-3.7,1-5.5,1-2.6,3-4.7,5.4-6.2,5-3.1,10.2-5.1,15.7-5.6,1.1-.1,2.3.1,3.2.7,1.5,1,2.5,2.8,2.4,6.4-1.2,4.2-2.6,8-4.7,11.1-2.6,3.9-6.8,6.6-11.5,7.1-3.3.4-6.3-.5-9.1-2.7-1.6-1.3-2.4-3.2-2.5-5.2h0Z"
          />
          <path
            id="Bonepart6"
            class="beide_Hande_dp1345_cls10"
            d="M129.8,439c4,.6,5-2.3,4.8-6.6,1.7-3,2.1-6.8,1.6-11.1-.2-1.7-1.6-3-3.2-3.1l-7-.4c-2.7-1.8-5.7-2.6-8.9-2.6s-5.3,1.5-6.7,3.9c-1.2,2.2-2.1,5-2.7,8.1-3.9,3.5-4.5,6.7.4,9.1,4.5,2.8,8.7,4.1,12.5,3.9l9.2-1.2h0Z"
          />
          <path
            id="Bonepart5"
            class="beide_Hande_dp1345_cls10"
            d="M113.8,416c1.7-1.2,3.4-2.2,5.1-2.9,3.5-1.3,7.4-.7,10.5,1.4,1.8,1.2,3,2.4,3.6,3.7"
          />
          <path
            id="Bonepart4"
            class="beide_Hande_dp1345_cls10"
            d="M185.8,491.2c2.7-6.9,4.9-13.8,5-20.4,1.9-17.2-4.6-24.4-.8-33.1,1.5-7.8.6-13.8-3.4-17.6-1.5-1.5-3.9-1.5-5.4,0-8.6,8-16.6,14.2-23.3,16.9l-36.7,7.2c-6.6.9-9.9,6.7-6.8,22.1,2.5,8.2,6.9,16.3,13.6,24.5"
          />
          <path
            id="Bonepart3"
            class="beide_Hande_dp1345_cls10"
            d="M75.2,488l-.3-19.1c-3.3-8.7-4.6-15.9-2.8-21,.5-7.9,2.3-12.6,5.7-13.7,4.2-2.1,5.7,3.5,9.2,10.4,1.3,2.5,3.9,3.9,6.7,3.8,4.6-.2,8.7.1,11.7,1.4,2.3,1,3.8,3.4,3.8,5.9v8.5c0,2.1-.4,4.1-1.2,6-2.2,5.6-3.2,11.8-3.2,18.7"
          />
          <path
            id="Bonepart2"
            class="beide_Hande_dp1345_cls10"
            d="M74.1,485.9c-3.6-9.1-7-18-5.5-24.4.4-5,3.3-7.6,9.9-6.4,6.4,3,14.9-2.8,26-5.5"
          />
          <path
            id="Bonepart1"
            class="beide_Hande_dp1345_cls10"
            d="M122.7,474.8c-3.9-9.5-5.9-18.5-1.5-26,2.8-5.3,27.2-4.6,36.7-12"
          />
          <g id="outlines24">
            <path
              class="beide_Hande_dp1345_cls10"
              d="M255.2,283.8c-1.6.6-3.1,1.2-4.6,1.9"
            />
            <path
              class="beide_Hande_dp1345_cls14"
              d="M243,289.1c-9.2,4.5-17.4,9.6-24.7,15.4"
            />
            <path
              class="beide_Hande_dp1345_cls10"
              d="M215.1,307.1c-1.3,1.1-2.5,2.2-3.8,3.3"
            />
          </g>
          <g id="outlines23">
            <path
              id="outlinepartright37"
              class="beide_Hande_dp1345_cls10"
              d="M60.8,486.9c.4-1.6.6-3.2.8-4.9"
            />
            <path
              id="outlinepartright36"
              class="beide_Hande_dp1345_cls15"
              d="M61.7,472c-.2-4.7-.5-9.3-.2-13.5,1.2-33.3,6.6-67.2-.7-73.8-12.1-27.1-17.4-67.5-20.9-111.5-3.5-5.9-3.1-20.5-2.3-36.1,1.5-14.5-10.1-45.1-15.9-56.1-2.7-11.3-7.9-27.1-13.8-44-5.7-15.5-9-28.7-6.7-35.7-.8-5.7,2-9.4,7.1-11.9,5.1-1.4,9.7-1.8,13.3,0,11.6-2.5,19.4,38.1,36.7,72.6,11.7,29.2,21,61.4,26.5,53.3,5.7-6.8,6.5-26.2-4.9-76.6l-6.8-60.7c-2.7-26-4.9-51.3,3-56.7,1.8-4.8,5.3-8,11.7-8.4,7.3-1,13.3.4,16.9,6.4,6.1,10.2,9.9,27.1,9.1,56.7l9.9,46c.5,23.2,1.2,45.4,4.6,57.4"
            />
            <path
              id="outlinepartright35"
              class="beide_Hande_dp1345_cls10"
              d="M130.2,184.1c.9,1.8,1.9,3.1,3.1,3.8.5-1.4.9-3,1.3-4.8"
            />
            <path
              id="outlinepartright34"
              class="beide_Hande_dp1345_cls9"
              d="M135.9,173.5c.9-12.3,0-29.7-2.9-52.9,1.3-18.4.5-41.1-1.1-65.6-.9-18.7-1.6-37.1.6-45,2.5-5.6,7.1-9.2,15.4-9.4,2.7-.2,5.2,0,7.5,1"
            />
            <path
              id="outlinepartright33"
              class="beide_Hande_dp1345_cls10"
              d="M159.5,4.2c1.1,1.1,2,2.4,2.8,4.1.9.2,1.9,1.6,2.9,3.9"
            />
            <path
              id="outlinepartright32"
              class="beide_Hande_dp1345_cls4"
              d="M168.2,21.9c2.3,9.4,4.7,23.2,7.2,39.6-.3,16.4.3,32,3.1,46-.2,27.8.3,53.6,2.1,74.8.4,6.4,1.5,11.8,3.7,15.1"
            />
            <path
              id="outlinepartright31"
              class="beide_Hande_dp1345_cls10"
              d="M188.1,200.6c1.4.6,3,.8,4.9.5.4-1.5.9-3.1,1.3-4.8"
            />
            <path
              id="outlinepartright30"
              class="beide_Hande_dp1345_cls13"
              d="M196.2,186.5c2.4-14.1,4-32.6,5.6-51.3,3.2-8.9,4.6-21.4,5.5-34.6-.2-29.8,2.8-51,11.2-57.2,3-4.2,7.1-6.7,12.7-6.5,4.9,0,9.5,2.1,13.8,7.3,7.3,9.7,7.9,48.8,4.7,104.2l-2.9,15.6c-2.5,40.2-3.7,81.7,5.3,110.7"
            />
            <path
              id="outlinepartright29"
              class="beide_Hande_dp1345_cls10"
              d="M253.7,279.5c.6,1.6,1.2,3.1,1.9,4.6,1.1-1.1,2.3-2.4,3.4-3.7"
            />
            <path
              id="outlinepartright28"
              class="beide_Hande_dp1345_cls11"
              d="M264.9,272.6c6.6-9.6,13.1-21.9,19.5-35.1,8.9-15.7,24.6-30.3,42.7-44.5,9.3-6,17.4-5.1,24.5.2,2.5,1.9,3.5,5.2,2.6,8.1-3.1,9.2-9.6,19.6-16.3,30.1-3.8,15.4-7.9,29-13.4,37.5-14.7,16.7-25.3,44-34,75.8-15.3,19.2-32.9,38.9-51.9,58.9-2,2.4-10.7,9.2-18.5,15.4-7.6,4-10.8,12.2-13.1,21.4-3,10.6-4.8,25.8-6.2,42.9"
            />
            <path
              id="outlinepartright27"
              class="beide_Hande_dp1345_cls10"
              d="M200.5,488.2c-.1,1.6-.2,3.3-.3,5"
            />
          </g>
          <g id="outlines22">
            <line
              id="outlinepartright26"
              class="beide_Hande_dp1345_cls10"
              x1="255.6"
              y1="297.7"
              x2="255.6"
              y2="292.7"
            />
            <line
              id="outlinepartright25"
              class="beide_Hande_dp1345_cls10"
              x1="255.6"
              y1="289.1"
              x2="255.6"
              y2="284.1"
            />
          </g>
          <g id="outlines21">
            <path
              id="outlinepartright24"
              class="beide_Hande_dp1345_cls10"
              d="M76.3,21.4c0,1.7,0,3.4,0,5"
            />
            <path
              id="outlinepartright23"
              class="beide_Hande_dp1345_cls10"
              d="M78.4,39.2c.7,1.8,1.5,3.2,2.5,4.3"
            />
          </g>
          <g id="outlines20">
            <path
              id="outlinepartright22"
              class="beide_Hande_dp1345_cls10"
              d="M103.6,17.6c.3,1.7.6,3.4.8,4.9"
            />
            <path
              id="outlinepartright21"
              class="beide_Hande_dp1345_cls12"
              d="M104.8,27.5c.1,2.8,0,5.3-.3,7.6"
            />
            <path
              id="outlinepartright20"
              class="beide_Hande_dp1345_cls10"
              d="M104,37.6c-.4,1.7-.9,3.3-1.5,4.8"
            />
          </g>
          <g id="outlines19">
            <path
              id="outlinepartright19"
              class="beide_Hande_dp1345_cls10"
              d="M21.8,89.4c.8,1.5,1.5,3,2.1,4.5"
            />
            <path
              id="outlinepartright18"
              class="beide_Hande_dp1345_cls10"
              d="M26.8,105.8c.2,1.7.2,3.3.2,5"
            />
          </g>
          <g id="outlines18">
            <path
              id="outlinepartright17"
              class="beide_Hande_dp1345_cls10"
              d="M1.3,101.3c.5,1.7,1.1,3.2,1.6,4.7"
            />
            <path
              id="outlinepartright16"
              class="beide_Hande_dp1345_cls10"
              d="M9.1,118.6c1.1,1.5,2.3,2.7,3.5,3.5"
            />
          </g>
          <g id="outlines17">
            <path
              id="outlinepartright15"
              class="beide_Hande_dp1345_cls10"
              d="M134.9,6.1c-.1,1.8-.2,3.4-.3,5"
            />
            <path
              id="outlinepartright14"
              class="beide_Hande_dp1345_cls7"
              d="M134.7,17.4c.2,3.6.8,6.7,1.6,9.2"
            />
            <path
              id="outlinepartright13"
              class="beide_Hande_dp1345_cls10"
              d="M137.6,29.4c.9,1.6,1.9,2.9,3.2,3.8"
            />
          </g>
          <g id="outlines16">
            <path
              id="outlinepartright12"
              class="beide_Hande_dp1345_cls10"
              d="M162.3,8.3c.4,1.7.6,3.4.9,4.9"
            />
            <path
              id="outlinepartright11"
              class="beide_Hande_dp1345_cls5"
              d="M163.7,18.5c.1,2.9-.1,5.6-.6,7.9"
            />
            <path
              id="outlinepartright10"
              class="beide_Hande_dp1345_cls10"
              d="M162.3,28.9c-.6,1.6-1.4,3.1-2.4,4.4"
            />
          </g>
          <g id="outlines15">
            <path
              id="outlinepartright9"
              class="beide_Hande_dp1345_cls10"
              d="M218.5,43.5c-.2,1.7-.5,3.3-.7,5"
            />
            <path
              id="outlinepartright8"
              class="beide_Hande_dp1345_cls6"
              d="M217.2,54.2c-.2,3.1-.2,6.1.3,8.6"
            />
            <path
              id="outlinepartright7"
              class="beide_Hande_dp1345_cls10"
              d="M218.4,65.5c.7,1.5,1.7,2.8,3.1,3.9"
            />
          </g>
          <g id="outlines14">
            <path
              id="outlinepartright6"
              class="beide_Hande_dp1345_cls10"
              d="M244.3,43.5c0,1.8-.2,3.4-.3,5"
            />
            <path
              id="outlinepartright5"
              class="beide_Hande_dp1345_cls8"
              d="M243.5,54.3c-.4,3.4-.9,6.3-1.5,8.6"
            />
            <path
              id="outlinepartright4"
              class="beide_Hande_dp1345_cls10"
              d="M241.1,65.6c-.9,2.1-1.9,3.4-3.1,3.8"
            />
          </g>
          <g id="outlines13">
            <path
              id="outlinepartright3"
              class="beide_Hande_dp1345_cls10"
              d="M349.9,192c-1.8-.1-3.4,0-5,.2"
            />
            <path
              id="outlinepartright2"
              class="beide_Hande_dp1345_cls10"
              d="M334.1,200c-.8,1.3-1.6,2.8-2.2,4.5"
            />
            <line
              id="outlinepartright1"
              class="beide_Hande_dp1345_cls10"
              x1="337.1"
              y1="234.6"
              x2="332.6"
              y2="231.4"
            />
          </g>
        </g>
        <g id="fullhandright">
          <path
            id="bonepartright77"
            class="beide_Hande_dp1345_cls10"
            d="M544.7,384.6c3.4-5.1,1.3-9.6-4.4-13.5-3.4-3.6-8.8-6-14.9-7.9-13.9-4.7-25.4-11.4-33.1-21.5-2.8-3.6-3.8-8.2-3.2-12.7,1.2-9.1-.7-14.4-5.9-15.3-6.2-1.9-12.2-1.6-17.6,2.1-6.3,1.7-9.2,9.1-9.7,20.4-.8,3.5,1.5,6.7,6.7,9.7,17,12.7,32.1,29,45.9,47.6,5.4,6.1,10.5,8.6,15.4,6.5,7.5-1.1,10.3-4.9,10.2-10.1l10.6-5.2h0Z"
          />
          <path
            id="bonepartright76"
            class="beide_Hande_dp1345_cls10"
            d="M479.1,302.2c.1-2.5-1.2-4.9-3.1-7.2-13.4-8.2-23.9-17.2-28.5-27.9-1.1-2.5-1.3-5.4-.7-8.1,1.3-6.1-.2-10.4-5-12.8-5.7-1.6-9.8,1-12.3,7.6-.5,1.4-1.7,2.5-3.1,3-4.4,1.6-5.8,4.1-2.9,8,10.1,15.8,19.4,31.4,22.7,45,1.5,8.2,3.9,11.5,7.2,10.5,5.8-7.1,13-11.7,21.5-13.5,2.3-.5,4.1-2.3,4.2-4.6h0Z"
          />
          <path
            id="bonepartright75"
            class="beide_Hande_dp1345_cls10"
            d="M427.5,251.9c1-5.1,3.9-8.7,9.2-10.7,1.4-6.1-1.5-8.9-7.3-9.5l-14.9-13.2c1.2-5.4-3.8-9.5-15.5-12.2-3.6-.6-6.5-.2-5,6.7,1.7,6.1,3.8,9,6.3,8.9,7.2,8.9,12.8,19.4,16.4,31.7.4,1.4,2.1,2,3.3,1.2,2.8-1.9,5.4-3,7.5-3h0Z"
          />
          <path
            id="bonepartright74"
            class="beide_Hande_dp1345_cls10"
            d="M436.8,241.1c.7,4,.4,7.6-2,9.8-2.2,1.4-4.6,1.9-7.2.9.4,1.2.4,2.1,0,2.6-2.6,2.3-5.1,2.2-7.5.3"
          />
          <path
            id="bonepartright73"
            class="beide_Hande_dp1345_cls10"
            d="M477.6,305.5c.3,1.5.1,2.8-1,3.7-3,1.6-6.6,1.4-10.5.7-.2,4.4-1.1,8.6-5,11.5-3.3,1.3-5.9.9-7.6-1.1"
          />
          <path
            id="bonepartright72"
            class="beide_Hande_dp1345_cls10"
            d="M480.4,321.4c1.6-1.2,2.3-3.4,1.6-5.2-.8-2-2.9-2.6-6.5-1.5-2.8.9-5,2.1-6,3.9-1.8,3.5-.3,5.2,3,5.9,3.5-.7,6.2-1.7,8-3Z"
          />
          <path
            id="bonepartright71"
            class="beide_Hande_dp1345_cls10"
            d="M489.6,306.9c-3.4-.4-4.6.8-3.6,3.6.2.9.7,1.6,1.4,2.2,1.3,1.1,3.2,1.1,4.5,0,1.1-.9,1.1-1.9.9-3-1.1-1.9-2.1-3-3.1-3h0Z"
          />
          <path
            id="bonepartright70"
            class="beide_Hande_dp1345_cls10"
            d="M558.8,375.3c-.5.9-1.3,1.5-2.3,1.9-5.7,2.2-10.8-1.9-15.2-11.7-1.1-2.5-1.4-5.1-.6-7.9,7.4-13.9,5.3-31.1-1.4-49.8l-15.4-36.2c-1.1-2.7-3.2-4.8-5.8-6.1h0c-2-1-3.4-2.8-3.8-5-2.5-13.8.2-22.4,6.5-27.6,6.5-4.6,12-2.5,17.2,3.1,2.7,0,4.5,1.9,5.6,5l3.3,3.6c.6.7.9,1.6.9,2.5-.8,27.4,4.5,56.3,18.5,87.3,1.7,2.6,4.6,4.4,8,5.8,2.5,9.5,7,17.5,13.3,24.3,1.2,1.3.5,2.9-2.1,4.9-1.3,1-3,1-4.3,0-4.3-3.6-9.3-4.2-15-2.1-2.7,1-5.2,4.1-7.6,7.9h0Z"
          />
          <path
            id="bonepartright69"
            class="beide_Hande_dp1345_cls10"
            d="M538.2,236.1l1.1,5,1.8,3.5c0,2.6.7,5,2.7,7.2.2,3,0,6.1-.6,9.5"
          />
          <path
            id="bonepartright68"
            class="beide_Hande_dp1345_cls10"
            d="M519.8,250.7c.7,1.7.5,3.2-1,4.5-2.4,3.4,0,8.5,3.6,13.8"
          />
          <path
            id="bonepartright67"
            class="beide_Hande_dp1345_cls10"
            d="M513.5,234.9c-5.4,1.1-8.2-2-8.4-9.5l3.7-22.2c.3-1.8.3-3.7,0-5.5-1.4-8.8-1.9-17.9-1.8-27.1,0-1.5-.4-2.9-1-4.2-2-3.7-2.9-7.5-3.1-11.5-.6-8.5,2.6-10.3,7.6-8.7,3,.6,6,0,9-1.2,3.7-1.8,6.3-1,8,2.1,2,4.8,3.2,9.3,1.8,12.7-.4.9-.6,1.9-.5,2.8l2.5,24.9c.7,9.6,4.2,19.1,9,28.5,2.1,7.5,2.7,13.6-2.5,14.7-3.8-3.8-8-5.1-12.7-4.3-5.8,1-9.1,4.3-11.6,8.5h0Z"
          />
          <path
            id="bonepartright66"
            class="beide_Hande_dp1345_cls10"
            d="M537.7,230.6l-8.2,2.4c-5.7,2-11.1,2.7-16,1.8"
          />
          <path
            id="bonepartright65"
            class="beide_Hande_dp1345_cls10"
            d="M529.7,154.5c-2.4-.9-3.7-2.2-4.1-3.8l1-4.9"
          />
          <path
            id="bonepartright64"
            class="beide_Hande_dp1345_cls10"
            d="M503.4,159.1c1.3-.6,2.6-3.1,3.2-5.6,0-2.2-.6-4.4-2.4-6.4"
          />
          <path
            id="bonepartright63"
            class="beide_Hande_dp1345_cls10"
            d="M525.6,140.3c3.5-2.3,2.1-5.9-.8-10-5.1-5.8-6.9-13.2-5.9-22.1,1.8-3,1.5-6.1,0-9.3.4-4.8-1-6.3-3.5-5.5-3.9.9-7.5,1.2-10.4,0-4.1-1.6-5.4.7-5.1,5.2-1.9,2.8-.7,5.8,2.9,8.9,1,9.9,0,19.2-2.9,28,.2,4.3,2,6.3,5.1,6.5,5,3,9.5,2.7,13.3-1,2.5-1.8,5-2.2,7.2-.7h0Z"
          />
          <path
            id="bonepartright62"
            class="beide_Hande_dp1345_cls10"
            d="M503.4,141.7c.6,1,1.7,2,3,2.8,4.7,2.9,10.7,2.5,15.3-.6,1.7-1.1,3-2.3,3.9-3.6"
          />
          <path
            id="bonepartright61"
            class="beide_Hande_dp1345_cls10"
            d="M514.5,90.3c7-.1,7.3-2.8,4.6-6.5-3.8-3.5-4.6-10.6-4.6-18.8,3.8-2.4,2.2-6.4,0-10.6-.7-4.6-3.2-6.7-7.9-5.2-3.1,1.6-5,3.9-5.4,7-2.6,5.3-1.5,8.1,1.5,9.8l-2.5,19.1c-.5,1.4-.7,2.8.3,4,.9,1.1,2.4,1.7,3.8,1.6l10.1-.4h0Z"
          />
          <line
            id="bonepartright60"
            class="beide_Hande_dp1345_cls10"
            x1="514.5"
            y1="58"
            x2="514.5"
            y2="65"
          />
          <line
            id="bonepartright59"
            class="beide_Hande_dp1345_cls10"
            x1="503.4"
            y1="60.2"
            x2="502.8"
            y2="65.9"
          />
          <path
            id="bonepartright58"
            class="beide_Hande_dp1345_cls10"
            d="M500.6,89.1c4.6-1.6,9.3-2.2,13.9-1.6"
          />
          <path
            id="bonepartright57"
            class="beide_Hande_dp1345_cls10"
            d="M600.1,361.1c-7.6,1.9-13.6.8-16.6-5.9-5.7-6.5-5.6-13.3-1.2-20.5,4.3-22.9,2.1-40.3-2.3-55.9-1.4-5.1-3.2-9.4-5.6-12.8-2.4-1.2-3.3-3.4-3.3-6.1-3.3-.6-5.5-2.5-6.6-5.4v-17.1c-.3-4.9,2.3-8.1,6.4-10.4,7.8-2.8,14.5-1.6,20.3,2.7.2,2.2,1.8,4.8,4,7.6,3.9,3.6,4.5,10.2,3.1,18.8-.4,16.7,1.2,38,3.5,60.9,1.8,5.8,4,10.2,6.9,12.1.7.5,1.4,1.1,1.7,1.9,2.4,5.2,4.4,10.7,5.7,16.6.5,2.3-.1,4.7-1.8,6.3-3.5,3.3-8,5.9-14.1,7.1h0Z"
          />
          <path
            id="bonepartright56"
            class="beide_Hande_dp1345_cls10"
            d="M587.6,323.9l-.9,9.3c0,1.1-.5,2.1-1.1,3-.7,1.1-1.3,2.2-1.6,3.4-.5,1.9,0,3.9,1.2,5.4h0c.8,1,1.1,2.2,1,3.4l-.5,4.1"
          />
          <path
            id="bonepartright55"
            class="beide_Hande_dp1345_cls10"
            d="M570.4,230.6l.8,7.4c.1,1-.2,2-.9,2.7-2.8,3-3.8,6.8-1.8,11.9,3.1,3.4,5.4,10.1,6.9,15.1"
          />
          <path
            id="bonepartright54"
            class="beide_Hande_dp1345_cls10"
            d="M589.1,231.5c.1,1.8.7,3.6,1.8,5.2,3.9,6.9,4.6,13.4.6,19.5"
          />
          <path
            id="bonepartright53"
            class="beide_Hande_dp1345_cls10"
            d="M608.3,339.1c.6-3.7-.5-7.8-2-12"
          />
          <path
            id="bonepartright52"
            class="beide_Hande_dp1345_cls10"
            d="M595.8,224.8c5.2-4.8,2.9-14.4-1.6-25.5-1.7-7.9-1.6-18-1-28.6,1.7-6.6,2-14.1,1-22.2-.6-2.9.2-6.4,1.4-10,.6-6,1.4-12.2-3.9-13.4l-10.3.8c-4.9-2.7-8.7-3.4-9.8.6-2.4,2.8-4,6-2.8,10.8,1.8,4.9,2.7,9.6,1.4,13.9,1.1,20.5,1.4,39.2-2.5,49.2-6.4,8.9-9,16.7-5.1,22.8,1.1,1.8,3.3,2.5,5.3,2,8.2-2,16.5-1.8,24.9.4,1.1.3,2.3,0,3.2-.8h0Z"
          />
          <path
            id="bonepartright51"
            class="beide_Hande_dp1345_cls10"
            d="M595.6,137.9c-.8-.6-1.5-1.3-2.1-2.1-1.9-2.6-2.1-6.2-.3-8.8.3-.4.6-.8,1-1.2"
          />
          <path
            id="bonepartright50"
            class="beide_Hande_dp1345_cls10"
            d="M569.1,138.6c.9-.7,1.7-1.6,2.6-2.9.9-1.3,1.4-2.9,1.3-4.5s-.7-3.1-2.1-4.1"
          />
          <path
            id="bonepartright49"
            class="beide_Hande_dp1345_cls10"
            d="M589.9,222c-7.1-2.9-14.2-2.9-21.3,0"
          />
          <path
            id="bonepartright48"
            class="beide_Hande_dp1345_cls10"
            d="M596.8,119c.8-2.8,0-6.1-1.1-9.6-3-15.5-4.2-28.1,0-32.8,2.1-2.5,1.8-5.4,0-8.5.4-3.3.3-6.1-2.7-5.5-3.3.4-6.9-.5-10.6-2.3-6.3-1.3-9.5.6-9.3,6.3-.6,3.3.2,5.6,1.9,7.2.7,11.4-1.6,22.6-6.4,33.6-2.2,3.7-2.6,7.2-.6,10.4.8,1.3,2.2,2.1,3.7,2.1,2.4,0,4.9.7,7.4,1.8,1.2.6,2.6.7,3.9.4,3.7-1,7-1.3,9.8-.7,1.7.4,3.5-.6,4-2.3h0Z"
          />
          <path
            id="bonepartright47"
            class="beide_Hande_dp1345_cls10"
            d="M594.3,26.4c-.4,13.4.6,22.7,3.5,26.8.7,1,.7,2.3.1,3.3-1.1,1.8-2.8,2.5-5.2,2.3l-11.7-.7c-1.2,0-2.3-.2-3.5-.6-2.7-.8-3.6-2-2.8-3.6,3-.7,5.2-11.1,7.2-24.1"
          />
          <path
            id="bonepartright46"
            class="beide_Hande_dp1345_cls10"
            d="M593.8,34.8c3.4.2,5.2-2.3,4.6-8.9-1.2-5.8-3.2-10.6-8.6-11.1-3.8-1-7,.5-9.3,6.9-2.6,6.8-1.8,10.3.8,12.3"
          />
          <path
            id="bonepartright45"
            class="beide_Hande_dp1345_cls10"
            d="M609.9,352.5c.5,6.2,3.1,9.7,9.5,8.4l22.9-7.8c4.5-1.8,4.3-5.4,1.4-9.9-2.5-6.2-5.5-10.2-8.7-12.3-1.4-.9-2.2-2.6-2.2-4.3v-32.9c0,0,3.8-33.3,3.8-33.3,3.5-3.9,3.2-8.5,0-13.7-.2-8.2-3-12.8-9.7-12.2-5.9-1.2-10,.1-12.8,3.4-2.3,5.5-3.6,10.4-4.1,14.7.9,7.8,3.2,15.8,6.3,24,1.1,17.5,1.6,33.8,0,46.4-4.1,7.1-6.4,16.5-6.3,29.4h0Z"
          />
          <path
            id="bonepartright44"
            class="beide_Hande_dp1345_cls10"
            d="M630.2,262.5c3.1-1.2,4.7-3.7,4-8l-.9-6.3c-.4-2.9.7-5.3,2.4-7.5"
          />
          <path
            id="bonepartright43"
            class="beide_Hande_dp1345_cls10"
            d="M616.2,241.1c-.7,4.1-2.8,7.9-6.3,11.5"
          />
          <path
            id="bonepartright42"
            class="beide_Hande_dp1345_cls10"
            d="M636.1,336.2c-1.8-1.4-2.4-4.4-2.9-7.2"
          />
          <path
            id="bonepartright41"
            class="beide_Hande_dp1345_cls10"
            d="M642.2,232.3c1.1-1.5,1.5-3.8,1.5-6.6,0-1.1-.2-2.2-.6-3.3l-2.8-7c-.4-1-.7-2.1-.8-3.2-1.5-12.5.2-25.1,2.2-37.6,1.1-4.1,1-8.7.8-13.4,0-1.5.8-3.2,2.2-5,1.6-6.5,2.3-12.6,1.2-17.7-2.6-2.4-5.6-3-8.8-2-7.3-3-11.8-3.8-12.3-1.1-2.8,1.9-4.2,5.5-4.3,10.3l1.3,7.8c.2,1.3.1,2.6-.2,3.9-1.3,4.7-2,9.3-1,13.5-.1,19-2,36.3-10.4,47.5-1.8,3.9-2.1,7.7-.3,11.1.8,1.6,2.7,2.4,4.5,2,8-1.9,15.7-1.4,22.8,1.9,1.8.8,4,.4,5.2-1.2h0Z"
          />
          <path
            id="bonepartright40"
            class="beide_Hande_dp1345_cls10"
            d="M624.6,135.4c.8,2.1,1,4.4,0,7.3-1.4,2.8-2.8,4.1-4.2,3.8"
          />
          <path
            id="bonepartright39"
            class="beide_Hande_dp1345_cls10"
            d="M645.8,138.6c-1.9,2.2-2.7,4.6-2.5,7.2-.5,3.8,0,7,1.5,9.7"
          />
          <path
            id="bonepartright38"
            class="beide_Hande_dp1345_cls10"
            d="M638.3,230.6c-6.8-3.3-14.6-3.9-23.5-2.1"
          />
          <path
            id="bonepartright37"
            class="beide_Hande_dp1345_cls10"
            d="M634.9,232.9c-2.2,1.7-5.7,1.8-9.6,1.3-3.9-.4-6.6-1.6-8.6-3.2"
          />
          <path
            id="bonepartright36"
            class="beide_Hande_dp1345_cls10"
            d="M644.5,133.8c4.5,2.4,5.8-1.2,5.9-7.4l-1.5-24.6c-1.6-6.4,4.4-8.1,4.9-15.6.6-6.5,0-10.9-4.7-9-4.4.3-8.9-.1-13.5-1.4-3-1.2-3.7.6-3.4,3.7-1.3,2.6-1.5,5.2-.7,7.8,2.1,6.1,2.1,11.7-1.4,16.4-2.8,12.2-11.4,25.2-6.4,25.9,3.6-.4,7.3,1.1,11,3.9,3.7-.9,6.9-.7,9.6.4h0Z"
          />
          <path
            id="bonepartright35"
            class="beide_Hande_dp1345_cls10"
            d="M654,71.5c4-.8,3.3-3.4,1.2-6.5-3.4-2.8-4.7-9.5-4-19.9,3.5,0,3.7-4,2.8-9.2-.1-6.3-3.3-8.1-7.7-8.3-5.4.6-7.7,2.5-8.2,5.2-2.7,7.3-2.4,11.5,1,12.3.3,7.2-.8,13.6-4.7,18.6-3.2,2.9-3.9,5.3.9,7-.2,1.3,1.8,2.1,5.7,2.5,5.5.5,10.1.2,12.9-1.6h0Z"
          />
          <line
            id="bonepartright34"
            class="beide_Hande_dp1345_cls10"
            x1="651.4"
            y1="38.6"
            x2="651.2"
            y2="45.1"
          />
          <line
            id="bonepartright33"
            class="beide_Hande_dp1345_cls10"
            x1="640.1"
            y1="38.6"
            x2="639.1"
            y2="45.1"
          />
          <path
            id="bonepartright32"
            class="beide_Hande_dp1345_cls10"
            d="M649.8,70.5c-1.8,1.4-4.1,1.5-6.7.7-3.8-1-6.5-1.2-7.8-.6"
          />
          <path
            id="bonepartright31"
            class="beide_Hande_dp1345_cls10"
            d="M593.2,56.6c-4.3-1.7-9.2-2-14.4-1.5"
          />
          <path
            id="bonepartright30"
            class="beide_Hande_dp1345_cls10"
            d="M645.8,365.8l.5-.3c6.3,1.2,11.3,0,12.6-8,3.3-3.3,3.9-8.8,3.9-14.8l-1.4-8.2c2.1-26.4,18.6-51.3,17.9-65.4,1.9-5,2.1-9.8,0-14.4-3.5-3.1-7.8-5.2-13.6-5.5-4.8.6-8.3,4.4-10.6,11.2-.2.6-.2,1.4-.1,2,1.7,10,1.8,19.4.2,28.2-5,18.1-10.3,35.4-17.5,44.1-2.9,3-3.6,6.3-2.7,9.8-5,6-6.5,11.7-2.4,16.7,2.4,3.4,8.7.8,13.3,4.6h0Z"
          />
          <path
            id="bonepartright29"
            class="beide_Hande_dp1345_cls10"
            d="M679.3,268.4c-3-3.3-3-7.9,0-13.8"
          />
          <path
            id="bonepartright28"
            class="beide_Hande_dp1345_cls10"
            d="M661.5,250.7c0,6-1.2,11.6-5.9,15.9"
          />
          <path
            id="bonepartright27"
            class="beide_Hande_dp1345_cls10"
            d="M682.7,252.3c3,1.6,5.3.7,6.5-3.7.2-.7.3-1.4.2-2.1l-.9-16.7c0-1.4.2-2.9.7-4.2,2.6-6.2,4.1-12.3,4.6-18.4.2-2.4.9-4.6,2.2-6.6,3.5-5.2,6.5-10.3,6.8-14.8.1-1.6-.7-3.1-2-3.9s-3.3-1.3-5.7-1.7c-1.3-.2-2.4-.8-3.3-1.7-2.9-3.2-5.2-2.9-7.1-.2-1.7,3.2-2.6,6.5-2.6,10.1,0,1.7-.4,3.4-1.2,5-6,12.7-9.2,26.7-14,34.2-5.4,5.1-8.8,10.1-8,14.8.4,2.1,2.3,3.4,4.4,3.3,7.2-.3,13.1,1.3,17.6,5.3.6.5,1.2,1,1.8,1.4h0Z"
          />
          <path
            id="bonepartright26"
            class="beide_Hande_dp1345_cls10"
            d="M682.4,248.7c-5-4.7-10.9-6.5-17.9-5.4"
          />
          <path
            id="bonepartright25"
            class="beide_Hande_dp1345_cls10"
            d="M680.9,250.9c-2.5,1.6-5.7,1.4-9.7-.2-4.1-.7-7.2-2.2-7.9-5.2"
          />
          <path
            id="bonepartright24"
            class="beide_Hande_dp1345_cls10"
            d="M699.8,194.6c-1.8-1.4-2.3-3.5-1.7-6.3.7-2.3,2.2-3.7,4.4-4.4"
          />
          <path
            id="bonepartright23"
            class="beide_Hande_dp1345_cls10"
            d="M682.4,184.6c2-.2,3.2-1.4,3.7-3.8.7-1.3.7-2.6-.3-3.8"
          />
          <path
            id="bonepartright22"
            class="beide_Hande_dp1345_cls10"
            d="M702.8,178.1c4.4,2.3,6.8.5,7.2-5,.5-4.8,1.1-9.7,1.7-14.7.2-1.8,1.1-3.4,2.4-4.5,3-2.5,4.5-5.3,4.4-8.2,0-1.7-1.4-3.1-3.1-3.5-3.5-.8-6.6-2.1-9.2-4-1.1-.9-2.7-1-3.8-.1-1.8,1.3-3.2,3.8-3.9,7.7,1.5,4.1-1.5,10.6-7.8,18.9-3.8,4.3-3.2,7,0,8.8,3.7,2.8,7.6,4.7,11.9,4.7h0Z"
          />
          <path
            id="bonepartright21"
            class="beide_Hande_dp1345_cls10"
            d="M703.9,178.6c1.5,2.7,3,2.9,4.5-.4"
          />
          <path
            id="bonepartright20"
            class="beide_Hande_dp1345_cls10"
            d="M716.2,139.2c5.1,3,5.7-3.4,4.9-12.7l2.3-8.5c4.4-1.8,5.5-4.8,4.7-8.5-.2-.9-.2-1.8,0-2.6.7-2.3-.6-4-3.5-5.1-2.7-1.1-5.9-.4-7.8,1.8-1.8,2.1-3,5.2-3.8,9.4l.4.8c.5,1.1.5,2.3.2,3.3-1.3,4-3.8,7.3-7.3,10-3.6,4.4-3.2,7.5,2.2,8.9,2.8.2,5.4,1.5,7.8,3.2h0Z"
          />
          <line
            id="bonepartright19"
            class="beide_Hande_dp1345_cls10"
            x1="725.2"
            y1="111.5"
            x2="723.3"
            y2="118.5"
          />
          <path
            id="bonepartright18"
            class="beide_Hande_dp1345_cls10"
            d="M525.6,244.3c-1.8-2.4-3.2-1.4-4.4,1.2-.4,1.2,0,2.4,1.1,3.6,1.6,1.6,3,1.1,4.2-1.7.3-1.1,0-2.1-.8-3.1h0Z"
          />
          <path
            id="bonepartright17"
            class="beide_Hande_dp1345_cls10"
            d="M671.2,258.7c-1.4-1.1-2.4-.8-3.1,1.2-1,2.9-.4,4,1.5,3.5,1.2.7,1.9-.1,2.4-2l-.9-2.6h0Z"
          />
          <path
            id="bonepartright16"
            class="beide_Hande_dp1345_cls10"
            d="M549.3,372.4c-6.8-2-9.6,1.2-9.7,7.9.4,7.2-.7,13.6-6.5,17.7-1.6,2.9,2.4,5.9,2.7,9.2,0,.7.3,1.4.7,2,1.7,2.7,4.5,3.6,7.9,3.4,2.4-1.7,5.5-2.1,9-2.1,1.2,0,2.3-.7,2.7-1.9,1.3-4.1,3.9-8.2,7.3-12.4,0-6.6-1.5-11.3-4.1-14.4l-6.2-7.2c-1-1.1-2.3-2-3.8-2.5h0Z"
          />
          <path
            id="bonepartright15"
            class="beide_Hande_dp1345_cls10"
            d="M555.2,381.5c-1.6,3.8.7,7.3,0,10.7-.6,3.9-2.8,6.2-8.3,4.9-3,.5-5-3.9-6.7-10.4"
          />
          <path
            id="bonepartright14"
            class="beide_Hande_dp1345_cls10"
            d="M580.8,372.5c-3.6-3.8-7.9-4.8-13.3-2.1-7.2,4.3-8.9,10.3-8.1,17.2.8,4.9,2.3,7.7,4.4,9,1,.7,2.4.5,3.4-.3,3.9-3.5,7.8-5.9,11.7-6.6,1.1-.2,1.9-1.2,1.9-2.3,0-4.9,0-9.3.8-12.2.2-.9,0-1.9-.7-2.6h0Z"
          />
          <path
            id="bonepartright13"
            class="beide_Hande_dp1345_cls10"
            d="M591.6,404.8c-1.5-.9-2.5-2.4-2.8-4.2-.8-4.6-2.3-6.8-4.4-6.8-6.5-.6-11.7.9-15.2,5.2-.4.5-.9,1-1.4,1.4-4.7,4.1-7,7.7-6,10.6.4,1.1.1,2.3-.5,3.3-1.9,3.1-.7,5.9,2.8,8.4,1.3.9,2.4,2,3.5,3.1,8.2,8.8,16.8,11.9,25.5,12.1,2,0,4.1-.2,6-1,3.3-1.3,5.1-3.6,5.5-6.6.2-1.3,0-2.7-.3-4-1-3.3-2.1-6.5-3.6-9.4-2.2-5.7-5.2-9.7-9.1-12.1h0Z"
          />
          <path
            id="bonepartright12"
            class="beide_Hande_dp1345_cls10"
            d="M601.8,419.4c-4.3-.9-8.3-2.7-11.5-6.8-3.6-3.1-3.2-6-.8-8.8.1-.1,0-.4-.2-.3-3,.9-4.8-.4-5.5-3.7v-6.2"
          />
          <path
            id="bonepartright11"
            class="beide_Hande_dp1345_cls10"
            d="M623.7,395.6c1,3.2,1.3,6.6.7,9.8-.8,4.2-2.7,6.8-6.1,7.2-8.5,2.1-16.5,2.4-23.5-.7-3.8-1.9-5.3-4.9-5.5-8.4-1.5-3.4-1.7-6.5,1-8.7,1-1,1.4-2,1.4-3.2,0-2.1-1.3-4-3.2-4.9l-.6-.3c-2.3-1.1-3.8-3.3-3.8-5.8,0-3.4,1.7-6.1,4.3-8.2.8-.7,1.5-1.6,1.7-2.6,1.1-5.2,5-6.6,10.2-6.5,1.5,0,3-.4,4.3-1.3,7.9-5.4,12.4-6,14.9-3.7.7,1.3,1.9.5,0,11.6l-.6,7.4c-.1,1.4,0,2.7.4,4l4.4,14.3h0Z"
          />
          <path
            id="bonepartright10"
            class="beide_Hande_dp1345_cls10"
            d="M652.1,358.4c1.5,1.9,2.7,3.9,3.4,5.9,0,7.8-4,11.9-4.9,16.7-.2,1.3-1,2.4-2.1,3-6.9,4-12.8,9.8-16.4,19.1-.5,1.2-1.3,2.3-2.4,3-4,2.5-6.9.7-9.2-4-.2-.4-.3-.8-.5-1.2-1.8-6.1-2.7-13.1-2.9-20.7-1.4-13.2.5-18.7,4.2-20l25.4-4c2.1-.3,4.2.5,5.5,2.1h0Z"
          />
          <path
            id="bonepartright9"
            class="beide_Hande_dp1345_cls10"
            d="M640,366.7c-3.6.4-7.1-.4-10.6-2.9-2.3-3-4.7-3.7-7.2-1.4-1.5,1.1-1.7,2.5-.9,4.2.6,1.2,1,2.5,1.1,3.8l.4,4.5c.2,2.6,1.1,5.2,2.6,7.4,2.3,3.3,4.8,5,7.8,3.3,2.9-5.7,2-9.6,5.9-11.6"
          />
          <path
            id="bonepartright8"
            class="beide_Hande_dp1345_cls10"
            d="M659.5,413.5c.4-2,1.2-3.9,2.5-5.5,2.5-3.1,2.1-6.6-.2-10.5-2.1-4.4-5.7-6.9-11.4-7.2-5.3.3-9.5,3-11.7,9.8-2.2,5-4.9,9.7-10.8,12-2,.9-1.2,3,2.3,6.1,2.1,1,3.9,3.1,5.4,6.3,1.2,2.7,3.8,4.5,6.7,4.8,3.5.3,6.6-.2,9-2,4.9-4,7.2-8.7,8.2-13.9h0Z"
          />
          <path
            id="bonepartright7"
            class="beide_Hande_dp1345_cls10"
            d="M656.5,412.7c0-1.9-.3-3.7-1-5.5-1-2.6-3-4.7-5.4-6.2-5-3.1-10.2-5.1-15.7-5.6-1.1-.1-2.3.1-3.2.7-1.5,1-2.5,2.8-2.4,6.4,1.2,4.2,2.6,8,4.7,11.1,2.6,3.9,6.8,6.6,11.5,7.1,3.3.4,6.3-.5,9.1-2.7,1.6-1.3,2.5-3.2,2.5-5.2h0Z"
          />
          <path
            id="bonepartright6"
            class="beide_Hande_dp1345_cls10"
            d="M608,440.6c-4,.6-5-2.3-4.8-6.6-1.7-3-2.1-6.8-1.6-11.1.2-1.7,1.5-3,3.2-3.1l7-.4c2.7-1.8,5.7-2.6,8.9-2.6s5.3,1.5,6.7,3.9c1.2,2.2,2.1,5,2.7,8.1,3.9,3.5,4.5,6.7-.4,9.1-4.5,2.8-8.7,4.1-12.5,3.9l-9.2-1.2h0Z"
          />
          <path
            id="bonepartright5"
            class="beide_Hande_dp1345_cls10"
            d="M624.1,417.6c-1.7-1.2-3.4-2.2-5.1-2.9-3.5-1.3-7.4-.7-10.5,1.4-1.8,1.2-3,2.4-3.6,3.7"
          />
          <path
            id="bonepartright4"
            class="beide_Hande_dp1345_cls10"
            d="M552.1,492.8c-2.7-6.9-4.9-13.8-5-20.4-1.9-17.2,4.6-24.4.8-33.1-1.5-7.8-.6-13.8,3.4-17.6,1.5-1.5,3.9-1.5,5.5,0,8.6,8,16.6,14.2,23.3,16.9l36.7,7.2c6.6.9,9.9,6.7,6.8,22.1-2.5,8.2-6.9,16.3-13.6,24.5"
          />
          <path
            id="bonepartright3"
            class="beide_Hande_dp1345_cls10"
            d="M662.7,489.6l.3-19.1c3.2-8.1,4-12.1,2.8-21-1.8-9.2-2.5-12.8-5.7-13.7-4.2-2.1-5.7,3.5-9.2,10.4-1.3,2.5-3.9,3.9-6.7,3.8-4.6-.2-8.7.1-11.7,1.4-2.3,1-3.8,3.4-3.8,5.9v8.5c0,2.1.4,4.1,1.2,6,2.2,5.6,3.2,11.8,3.2,18.7"
          />
          <path
            id="bonepartright2"
            class="beide_Hande_dp1345_cls10"
            d="M663.7,487.5c3.6-9.1,7-18,5.5-24.4-.4-5-3.3-7.6-9.9-6.4-6.4,3-14.9-2.8-26-5.5"
          />
          <path
            id="bonepartright1"
            class="beide_Hande_dp1345_cls10"
            d="M615.2,476.4c3.9-9.5,5.9-18.5,1.5-26-2.8-5.3-27.2-4.6-36.7-12"
          />
          <g id="outlines12">
            <path
              id="outlinepart40"
              class="beide_Hande_dp1345_cls10"
              d="M482.6,285.4c1.6.6,3.1,1.2,4.6,1.9"
            />
            <path
              id="outlinepart39"
              class="beide_Hande_dp1345_cls14"
              d="M494.8,290.7c9.2,4.5,17.4,9.6,24.7,15.4"
            />
            <path
              id="outlinepart38"
              class="beide_Hande_dp1345_cls10"
              d="M522.8,308.7c1.3,1.1,2.5,2.2,3.8,3.3"
            />
          </g>
          <g id="outlines11">
            <path
              id="outlinepart37"
              class="beide_Hande_dp1345_cls10"
              d="M677,488.5c-.4-1.6-.6-3.2-.8-4.9"
            />
            <path
              id="outlinepart36"
              class="beide_Hande_dp1345_cls15"
              d="M676.1,473.6c.2-4.7.5-9.3.2-13.5-1.2-33.3-6.6-67.2.7-73.8,12.1-27.1,17.4-67.5,20.8-111.5,3.5-5.9,3.1-20.5,2.3-36.1-1.5-14.5,10.1-45.1,15.9-56.1,2.7-11.3,7.9-27.1,13.8-44,5.7-15.5,9-28.7,6.7-35.7.8-5.7-2-9.4-7.1-11.9-5.1-1.4-9.7-1.8-13.3,0-11.6-2.5-19.3,38.1-36.7,72.6-11.7,29.2-21,61.4-26.5,53.3-5.7-6.8-6.5-26.2,4.9-76.6l6.8-60.7c2.7-26,4.9-51.3-3-56.7-1.8-4.8-5.3-8-11.7-8.4-7.3-1-13.3.4-16.9,6.4-6.1,10.2-9.9,27.1-9.1,56.7l-9.9,46c-.5,23.2-1.2,45.4-4.6,57.4"
            />
            <path
              id="outlinepart35"
              class="beide_Hande_dp1345_cls10"
              d="M607.6,185.7c-.9,1.8-1.9,3.1-3.1,3.8-.5-1.4-.9-3-1.3-4.8"
            />
            <path
              id="outlinepart34"
              class="beide_Hande_dp1345_cls9"
              d="M601.9,175.1c-.9-12.3,0-29.7,2.9-52.9-1.3-18.4-.5-41.1,1.1-65.6.9-18.7,1.7-37.1-.6-45-2.5-5.6-7.1-9.2-15.4-9.4-2.7-.2-5.2,0-7.5,1"
            />
            <path
              id="outlinepart33"
              class="beide_Hande_dp1345_cls10"
              d="M578.3,5.8c-1.1,1.1-2,2.4-2.8,4.1-.9.2-1.9,1.6-2.9,3.9"
            />
            <path
              id="outlinepart32"
              class="beide_Hande_dp1345_cls4"
              d="M569.6,23.5c-2.3,9.4-4.7,23.2-7.2,39.6.3,16.4-.3,32-3,46,.2,27.8-.3,53.6-2.1,74.8-.4,6.4-1.5,11.8-3.7,15.1"
            />
            <path
              id="outlinepart31"
              class="beide_Hande_dp1345_cls10"
              d="M549.7,202.2c-1.4.6-3,.8-4.9.5-.5-1.5-.9-3.1-1.3-4.8"
            />
            <path
              id="outlinepart30"
              class="beide_Hande_dp1345_cls13"
              d="M541.6,188.1c-2.3-14.1-4-32.6-5.6-51.3-3.2-8.9-4.6-21.4-5.5-34.6.2-29.8-2.8-51-11.2-57.2-3-4.2-7.1-6.7-12.7-6.5-4.9,0-9.5,2.1-13.8,7.3-7.3,9.7-7.9,48.8-4.7,104.2l2.9,15.6c2.5,40.2,3.7,81.7-5.3,110.7"
            />
            <path
              id="outlinepart29"
              class="beide_Hande_dp1345_cls10"
              d="M484.1,281.1c-.6,1.6-1.2,3.1-1.9,4.6-1.1-1.1-2.3-2.4-3.4-3.7"
            />
            <path
              id="outlinepart28"
              class="beide_Hande_dp1345_cls11"
              d="M472.9,274.2c-6.6-9.6-13.1-21.9-19.5-35.1-8.9-15.7-24.6-30.3-42.7-44.5-9.3-6-17.4-5.1-24.5.2-2.5,1.9-3.5,5.2-2.6,8.1,3.1,9.2,9.6,19.6,16.3,30.1,3.8,15.4,7.9,29,13.4,37.5,14.7,16.7,25.3,44,34,75.8,15.3,19.2,32.9,38.9,52,58.9,2,2.4,10.7,9.2,18.5,15.4,7.6,4,10.8,12.2,13.1,21.4,3,10.6,4.8,25.8,6.2,42.9"
            />
            <path
              id="outlinepart27"
              class="beide_Hande_dp1345_cls10"
              d="M537.4,489.8c.1,1.6.2,3.3.3,5"
            />
          </g>
          <g id="outlines10">
            <line
              id="outlinepart26"
              class="beide_Hande_dp1345_cls10"
              x1="482.3"
              y1="299.2"
              x2="482.3"
              y2="294.2"
            />
            <line
              id="outlinepart25"
              class="beide_Hande_dp1345_cls10"
              x1="482.3"
              y1="290.7"
              x2="482.3"
              y2="285.7"
            />
          </g>
          <g id="outlines9">
            <path
              id="outlinepart24-2"
              data-name="outlinepart24"
              class="beide_Hande_dp1345_cls10"
              d="M661.5,23c0,1.7,0,3.4,0,5"
            />
            <path
              id="outlinepart23-2"
              data-name="outlinepart23"
              class="beide_Hande_dp1345_cls10"
              d="M659.4,40.8c-.7,1.8-1.5,3.2-2.5,4.3"
            />
          </g>
          <g id="outlines8">
            <path
              id="outlinepart22-2"
              data-name="outlinepart22"
              class="beide_Hande_dp1345_cls10"
              d="M634.2,19.1c-.3,1.7-.6,3.4-.8,5"
            />
            <path
              id="outlinepart21-2"
              data-name="outlinepart21"
              class="beide_Hande_dp1345_cls12"
              d="M633.1,29.1c-.1,2.8,0,5.3.3,7.6"
            />
            <path
              id="outlinepart20-2"
              data-name="outlinepart20"
              class="beide_Hande_dp1345_cls10"
              d="M633.8,39.2c.4,1.7.9,3.3,1.5,4.8"
            />
          </g>
          <g id="outlines7">
            <path
              id="outlinepart19-2"
              data-name="outlinepart19"
              class="beide_Hande_dp1345_cls10"
              d="M716,91c-.8,1.5-1.5,3-2.1,4.5"
            />
            <path
              id="outlinepart18-2"
              data-name="outlinepart18"
              class="beide_Hande_dp1345_cls10"
              d="M711,107.4c-.2,1.7-.2,3.3-.2,5"
            />
          </g>
          <g id="outlines6">
            <path
              id="outlinepart17-2"
              data-name="outlinepart17"
              class="beide_Hande_dp1345_cls10"
              d="M736.5,102.8c-.5,1.7-1,3.2-1.6,4.7"
            />
            <path
              id="outlinepart16-2"
              data-name="outlinepart16"
              class="beide_Hande_dp1345_cls10"
              d="M728.8,120.2c-1.1,1.5-2.3,2.7-3.5,3.5"
            />
          </g>
          <g id="outlines5">
            <path
              id="outlinepart15-2"
              data-name="outlinepart15"
              class="beide_Hande_dp1345_cls10"
              d="M602.9,7.7c.1,1.7.2,3.4.3,5"
            />
            <path
              id="outlinepart14-2"
              data-name="outlinepart14"
              class="beide_Hande_dp1345_cls7"
              d="M603.1,19c-.2,3.6-.8,6.7-1.6,9.2"
            />
            <path
              id="outlinepart13-2"
              data-name="outlinepart13"
              class="beide_Hande_dp1345_cls10"
              d="M600.3,31c-.9,1.6-1.9,2.9-3.2,3.8"
            />
          </g>
          <g id="outlines4">
            <path
              id="outlinepart12-2"
              data-name="outlinepart12"
              class="beide_Hande_dp1345_cls10"
              d="M575.5,9.9c-.4,1.7-.7,3.4-.9,4.9"
            />
            <path
              id="outlinepart11-2"
              data-name="outlinepart11"
              class="beide_Hande_dp1345_cls5"
              d="M574.1,20.1c0,2.9.1,5.6.6,7.9"
            />
            <path
              id="outlinepart10-2"
              data-name="outlinepart10"
              class="beide_Hande_dp1345_cls10"
              d="M575.5,30.5c.6,1.6,1.4,3.1,2.4,4.4"
            />
          </g>
          <g id="outlines3">
            <path
              id="outlinepart9-2"
              data-name="outlinepart9"
              class="beide_Hande_dp1345_cls10"
              d="M519.3,45.1c.2,1.7.5,3.3.7,5"
            />
            <path
              id="outlinepart8-2"
              data-name="outlinepart8"
              class="beide_Hande_dp1345_cls6"
              d="M520.7,55.8c.2,3.1.2,6.1-.3,8.6"
            />
            <path
              id="outlinepart7-2"
              data-name="outlinepart7"
              class="beide_Hande_dp1345_cls10"
              d="M519.5,67.1c-.7,1.5-1.7,2.8-3.1,3.9"
            />
          </g>
          <g id="outlines2">
            <path
              id="outlinepart6-2"
              data-name="outlinepart6"
              class="beide_Hande_dp1345_cls10"
              d="M493.5,45.1c0,1.8.2,3.4.3,5"
            />
            <path
              id="outlinepart5-2"
              data-name="outlinepart5"
              class="beide_Hande_dp1345_cls8"
              d="M494.3,55.8c.4,3.4.9,6.3,1.5,8.6"
            />
            <path
              id="outlinepart4-2"
              data-name="outlinepart4"
              class="beide_Hande_dp1345_cls10"
              d="M496.7,67.2c.9,2.1,1.9,3.4,3,3.8"
            />
          </g>
          <g id="outlines1">
            <path
              id="outlinepart3-2"
              data-name="outlinepart3"
              class="beide_Hande_dp1345_cls10"
              d="M387.9,193.6c1.8-.1,3.4,0,5,.2"
            />
            <path
              id="outlinepart2-2"
              data-name="outlinepart2"
              class="beide_Hande_dp1345_cls10"
              d="M403.8,201.6c.8,1.3,1.6,2.8,2.2,4.5"
            />
            <line
              id="outlinepart1-2"
              data-name="outlinepart1"
              class="beide_Hande_dp1345_cls10"
              x1="400.7"
              y1="236.1"
              x2="405.2"
              y2="232.9"
            />
          </g>
        </g>
      </g>
      <g id="rightforearmhighlights">
        <path
          id="forearmhighlightpart2"
          class="Unterarm1345Fill"
          style={{
            fill: props.colors.Unterarm1345Fill,
            stroke: props.colors.Unterarm1345Fill,
          }}
          d="M552.5,492.8c-2.7-6.9-4.9-13.8-5-20.4-1.9-17.2,4.6-24.4.8-33.1-1.5-7.8-.6-13.8,3.4-17.6,1.5-1.5,3.9-1.5,5.5,0,8.6,8,16.6,14.2,23.3,16.9l36.7,7.2c6.6.9,9.9,6.7,6.8,22.1-2.5,8.2-6.9,16.3-13.6,24.5"
        />
        <path
          id="forearmhighlightpart1"
          class="Unterarm1345Fill"
          style={{
            fill: props.colors.Unterarm1345Fill,
            stroke: props.colors.Unterarm1345Fill,
          }}
          d="M663.1,489.6c2.8-6.3,5.6-13.2,6.3-20.3.2-14.9-3.9-8.6-2.8-12.7-.5-7.9-2.6-19.8-6-20.8-4.2-2.1-5.7,3.5-9.2,10.4-1.3,2.5-3.9,3.9-6.7,3.8-4.6-.2-8.7.1-11.7,1.4-2.3,1-3.8,3.4-3.8,5.9v8.5c0,2.1.4,4.1,1.2,6,2.2,5.6,3.2,11.8,3.2,18.7"
        />
      </g>
      <g id="leftforearmhighlights">
        <path
          id="forearmhighlightpart4"
          class="Unterarm1345Fill"
          style={{
            fill: props.colors.Unterarm1345Fill,
            stroke: props.colors.Unterarm1345Fill,
          }}
          d="M127.3,490.4c-6.7-8.2-11.1-16.3-13.6-24.5-3.1-15.4.2-21.2,6.8-22.1l36.7-7.2c6.8-2.7,14.7-8.9,23.3-16.9,1.5-1.4,3.9-1.4,5.5,0,4,3.8,4.8,9.9,3.4,17.6-3.8,8.8,2.7,15.9.8,33.1-.1,6.6-2.3,13.5-5,20.4"
        />
        <path
          id="forearmhighlightpart3"
          class="Unterarm1345Fill"
          style={{
            fill: props.colors.Unterarm1345Fill,
            stroke: props.colors.Unterarm1345Fill,
          }}
          d="M104,488.9c0-6.9,1.1-13.1,3.3-18.7.8-1.9,1.2-4,1.2-6v-8.5c0-2.5-1.4-4.9-3.8-5.9-3-1.3-7-1.6-11.7-1.4-2.8.1-5.4-1.4-6.7-3.8-3.6-6.8-5.1-12.5-9.2-10.4-3.4,1-5.5,12.9-6,20.8,1.1,4.1-3-2.3-2.8,12.7.6,7.1,3.5,14,6.3,20.3"
        />
      </g>
      <g id="Highlightsright">
        <path
          id="boneparthighlight28"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M544.2,384.3c3.4-5.1,1.3-9.6-4.4-13.5-3.4-3.6-8.8-6-14.9-7.9-13.9-4.7-25.4-11.4-33.1-21.5-2.8-3.6-3.8-8.2-3.2-12.7,1.2-9.1-.7-14.4-5.9-15.3-6.2-1.9-12.2-1.6-17.6,2.1-6.3,1.7-9.2,9.1-9.7,20.4-.8,3.5,1.5,6.7,6.7,9.7,17,12.7,32.1,29,45.9,47.6,5.4,6.1,10.5,8.6,15.4,6.5,7.5-1.1,10.3-4.9,10.2-10.1l10.6-5.2h0Z"
        />
        <path
          id="boneparthighlight27"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M478.7,302c.1-2.5-1.2-4.9-3.1-7.2-13.4-8.2-23.9-17.2-28.5-27.9-1.1-2.5-1.3-5.4-.7-8.1,1.3-6.1-.2-10.4-5-12.8-5.7-1.6-9.8,1-12.3,7.6-.5,1.4-1.7,2.5-3.1,3-4.4,1.6-5.8,4.1-2.9,8,10.1,15.8,19.4,31.4,22.7,45,1.5,8.2,3.9,11.5,7.2,10.5,5.8-7.1,13-11.7,21.5-13.5,2.3-.5,4.1-2.3,4.2-4.6h0Z"
        />
        <path
          id="boneparthighlight26"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M427.1,251.6c1-5.1,3.9-8.7,9.2-10.7,1.4-6.1-1.5-8.9-7.3-9.5l-14.9-13.2c1.2-5.4-3.8-9.5-15.5-12.2-3.6-.6-6.5-.2-5,6.7,1.7,6.1,3.8,9,6.3,8.9,7.2,8.9,12.8,19.4,16.4,31.7.4,1.4,2.1,2,3.3,1.2,2.8-1.9,5.4-3,7.5-3h0Z"
        />
        <path
          id="boneparthighlight25"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M558.4,375.1c-.5.9-1.3,1.5-2.3,1.9-5.7,2.2-10.8-1.9-15.2-11.7-1.1-2.5-1.4-5.1-.6-7.9,7.4-13.9,5.3-31.1-1.4-49.8l-15.4-36.2c-1.1-2.7-3.2-4.8-5.8-6.1h0c-2-1-3.4-2.8-3.8-5-2.5-13.8.2-22.4,6.5-27.6,6.5-4.6,12-2.5,17.2,3.1,2.7,0,4.5,1.9,5.6,5l3.3,3.6c.6.7.9,1.6.9,2.5-.8,27.4,4.5,56.3,18.5,87.3,1.7,2.6,4.6,4.4,8,5.8,2.5,9.5,7,17.5,13.3,24.3,1.2,1.3.5,2.9-2.1,4.9-1.3,1-3,1-4.3,0-4.3-3.6-9.3-4.2-15-2.1-2.7,1-5.2,4.1-7.6,7.9h0Z"
        />
        <path
          id="boneparthighlight24"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M513.1,234.6c-5.4,1.1-8.2-2-8.4-9.5l3.7-22.2c.3-1.8.3-3.7,0-5.5-1.4-8.8-1.9-17.9-1.8-27.1,0-1.5-.4-2.9-1-4.2-2-3.7-2.9-7.5-3.1-11.5-.6-8.5,2.6-10.3,7.6-8.7,3,.6,6,0,9-1.2,3.7-1.8,6.3-1,8,2.1,2,4.8,3.2,9.3,1.8,12.7-.4.9-.6,1.9-.5,2.8l2.5,24.9c.7,9.6,4.2,19.1,9,28.5,2.1,7.5,2.7,13.6-2.5,14.7-3.8-3.8-8-5.1-12.7-4.3-5.8,1-9.1,4.3-11.6,8.5h0Z"
        />
        <path
          id="boneparthighlight23"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M525.2,140.1c3.5-2.3,2.1-5.9-.8-10-5.1-5.8-6.9-13.2-5.9-22.1,1.8-3,1.5-6.1,0-9.3.4-4.8-1-6.3-3.5-5.5-3.9.9-7.5,1.2-10.4,0-4.1-1.6-5.4.7-5.1,5.2-1.9,2.8-.7,5.8,2.9,8.9,1,9.9,0,19.2-2.9,28,.2,4.3,2,6.3,5.1,6.5,5,3,9.5,2.7,13.3-1,2.5-1.8,5-2.2,7.2-.7h0Z"
        />
        <path
          id="boneparthighlight22"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M514.1,90.1c7-.1,7.3-2.8,4.6-6.5-3.8-3.5-4.6-10.6-4.6-18.8,3.8-2.4,2.2-6.4,0-10.6-.7-4.6-3.2-6.7-7.9-5.2-3.1,1.6-5,3.9-5.4,7-2.6,5.3-1.5,8.1,1.5,9.8l-2.5,19.1c-.5,1.4-.7,2.8.3,4,.9,1.1,2.4,1.7,3.8,1.6l10.1-.4h0Z"
        />
        <path
          id="boneparthighlight21"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M599.7,360.9c-7.6,1.9-13.6.8-16.6-5.9-5.7-6.5-5.6-13.3-1.2-20.5,4.3-22.9,2.1-40.3-2.3-55.9-1.4-5.1-3.2-9.4-5.6-12.8-2.4-1.2-3.3-3.4-3.3-6.1-3.3-.6-5.5-2.5-6.6-5.4v-17.1c-.3-4.9,2.3-8.1,6.4-10.4,7.8-2.8,14.5-1.6,20.3,2.7.2,2.2,1.8,4.8,4,7.6,3.9,3.6,4.5,10.2,3.1,18.8-.4,16.7,1.2,38,3.5,60.9,1.8,5.8,4,10.2,6.9,12.1.7.5,1.4,1.1,1.7,1.9,2.4,5.2,4.4,10.7,5.7,16.6.5,2.3-.1,4.7-1.8,6.3-3.5,3.3-8,5.9-14.1,7.1h0Z"
        />
        <path
          id="boneparthighlight20"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M595.4,224.6c5.2-4.8,2.9-14.4-1.6-25.5-1.7-7.9-1.6-18-1-28.6,1.7-6.6,2-14.1,1-22.2-.6-2.9.2-6.4,1.4-10,.6-6,1.4-12.2-3.9-13.4l-10.3.8c-4.9-2.7-8.7-3.4-9.8.6-2.4,2.8-4,6-2.8,10.8,1.8,4.9,2.7,9.6,1.4,13.9,1.1,20.5,1.4,39.2-2.5,49.2-6.4,8.9-9,16.7-5.1,22.8,1.1,1.8,3.3,2.5,5.3,2,8.2-2,16.5-1.8,24.9.4,1.1.3,2.3,0,3.2-.8h0Z"
        />
        <path
          id="boneparthighlight19"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M596.4,118.7c.8-2.8,0-6.1-1.1-9.6-3-15.5-4.2-28.1,0-32.8,2.1-2.5,1.8-5.4,0-8.5.4-3.3.3-6.1-2.7-5.5-3.3.4-6.9-.5-10.6-2.3-6.3-1.3-9.5.6-9.3,6.3-.6,3.3.2,5.6,1.9,7.2.7,11.4-1.6,22.6-6.4,33.6-2.2,3.7-2.6,7.2-.6,10.4.8,1.3,2.2,2.1,3.7,2.1,2.4,0,4.9.7,7.4,1.8,1.2.6,2.6.7,3.9.4,3.7-1,7-1.3,9.8-.7,1.7.4,3.5-.6,4-2.3h0Z"
        />
        <path
          id="boneparthighlight18"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M593.9,26.2c-.4,13.4.6,22.7,3.5,26.8.7,1,.7,2.3.1,3.3-1.1,1.8-2.8,2.5-5.2,2.3l-11.7-.7c-1.2,0-2.3-.2-3.5-.6-2.7-.8-3.6-2-2.8-3.6,3-.7,5.2-11.1,7.2-24.1"
        />
        <path
          id="boneparthighlight17"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M593.4,34.6c3.4.2,5.2-2.3,4.6-8.9-1.2-5.8-3.2-10.6-8.6-11.1-3.8-1-7,.5-9.3,6.9-2.6,6.8-1.8,10.3.8,12.3"
        />
        <path
          id="boneparthighlight16"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M609.5,352.2c.5,6.2,3.1,9.7,9.5,8.4l22.9-7.8c4.5-1.8,4.3-5.4,1.4-9.9-2.5-6.2-5.5-10.2-8.7-12.3-1.4-.9-2.2-2.6-2.2-4.3v-32.9c0,0,3.8-33.3,3.8-33.3,3.5-3.9,3.2-8.5,0-13.7-.2-8.2-3-12.8-9.7-12.2-5.9-1.2-10,.1-12.8,3.4-2.3,5.5-3.6,10.4-4.1,14.7.9,7.8,3.2,15.8,6.3,24,1.1,17.5,1.6,33.8,0,46.4-4.1,7.1-6.4,16.5-6.3,29.4h0Z"
        />
        <path
          id="boneparthighlight15"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M641.7,232c1.1-1.5,1.5-3.8,1.5-6.6,0-1.1-.2-2.2-.6-3.3l-2.8-7c-.4-1-.7-2.1-.8-3.2-1.5-12.5.2-25.1,2.2-37.6,1.1-4.1,1-8.7.8-13.4,0-1.5.8-3.2,2.2-5,1.6-6.5,2.3-12.6,1.2-17.7-2.6-2.4-5.6-3-8.8-2-7.3-3-11.8-3.8-12.3-1.1-2.8,1.9-4.2,5.5-4.3,10.3l1.3,7.8c.2,1.3.1,2.6-.2,3.9-1.3,4.7-2,9.3-1,13.5-.1,19-2,36.3-10.4,47.5-1.8,3.9-2.1,7.7-.3,11.1.8,1.6,2.7,2.4,4.5,2,8-1.9,15.7-1.4,22.8,1.9,1.8.8,4,.4,5.2-1.2h0Z"
        />
        <path
          id="boneparthighlight14"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M644,133.6c4.5,2.4,5.8-1.2,5.9-7.4l-1.5-24.6c-1.6-6.4,4.4-8.1,4.9-15.6.6-6.5,0-10.9-4.7-9-4.4.3-8.9-.1-13.5-1.4-3-1.2-3.7.6-3.4,3.7-1.3,2.6-1.5,5.2-.7,7.8,2.1,6.1,2.1,11.7-1.4,16.4-2.8,12.2-11.4,25.2-6.4,25.9,3.6-.4,7.3,1.1,11,3.9,3.7-.9,6.9-.7,9.6.4h0Z"
        />
        <path
          id="boneparthighlight13"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M653.6,71.3c4-.8,3.3-3.4,1.2-6.5-3.4-2.8-4.7-9.5-4-19.9,3.5,0,3.7-4,2.8-9.2-.1-6.3-3.3-8.1-7.7-8.3-5.4.6-7.7,2.5-8.2,5.2-2.7,7.3-2.4,11.5,1,12.3.3,7.2-.8,13.6-4.7,18.6-3.2,2.9-3.9,5.3.9,7-.2,1.3,1.8,2.1,5.7,2.5,5.5.5,10.1.2,12.9-1.6h0Z"
        />
        <path
          id="boneparthighlight12"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M645.4,365.5l.5-.3c6.3,1.2,11.3,0,12.6-8,3.3-3.3,3.9-8.8,3.9-14.8l-1.4-8.2c2.1-26.4,18.6-51.3,17.9-65.4,1.9-5,2.1-9.8,0-14.4-3.5-3.1-7.8-5.2-13.6-5.5-4.8.6-8.3,4.4-10.6,11.2-.2.6-.2,1.4-.1,2,1.7,10,1.8,19.4.2,28.2-5,18.1-10.3,35.4-17.5,44.1-2.9,3-3.6,6.3-2.7,9.8-5,6-6.5,11.7-2.4,16.7,2.4,3.4,8.7.8,13.3,4.6h0Z"
        />
        <path
          id="boneparthighlight11"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M682.3,252c3,1.6,5.3.7,6.5-3.7.2-.7.3-1.4.2-2.1l-.9-16.7c0-1.4.2-2.9.7-4.2,2.6-6.2,4.1-12.3,4.6-18.4.2-2.4.9-4.6,2.2-6.6,3.5-5.2,6.5-10.3,6.8-14.8.1-1.6-.7-3.1-2-3.9s-3.3-1.3-5.7-1.7c-1.3-.2-2.4-.8-3.3-1.7-2.9-3.2-5.2-2.9-7.1-.2-1.7,3.2-2.6,6.5-2.6,10.1,0,1.7-.4,3.4-1.2,5-6,12.7-9.2,26.7-14,34.2-5.4,5.1-8.8,10.1-8,14.8.4,2.1,2.3,3.4,4.4,3.3,7.2-.3,13.1,1.3,17.6,5.3.6.5,1.2,1,1.8,1.4h0Z"
        />
        <path
          id="boneparthighlight10"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M702.4,177.9c4.4,2.3,6.8.5,7.2-5,.5-4.8,1.1-9.7,1.7-14.7.2-1.8,1.1-3.4,2.4-4.5,3-2.5,4.5-5.3,4.4-8.2,0-1.7-1.4-3.1-3.1-3.5-3.5-.8-6.6-2.1-9.2-4-1.1-.9-2.7-1-3.8-.1-1.8,1.3-3.2,3.8-3.9,7.7,1.5,4.1-1.5,10.6-7.8,18.9-3.8,4.3-3.2,7,0,8.8,3.7,2.8,7.6,4.7,11.9,4.7h0Z"
        />
        <path
          id="boneparthighlight9"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M715.8,138.9c5.1,3,5.7-3.4,4.9-12.7l2.3-8.5c4.4-1.8,5.5-4.8,4.7-8.5-.2-.9-.2-1.8,0-2.6.7-2.3-.6-4-3.5-5.1-2.7-1.1-5.9-.4-7.8,1.8-1.8,2.1-3,5.2-3.8,9.4l.4.8c.5,1.1.5,2.3.2,3.3-1.3,4-3.8,7.3-7.3,10-3.6,4.4-3.2,7.5,2.2,8.9,2.8.2,5.4,1.5,7.8,3.2h0Z"
        />
        <path
          id="boneparthighlight8"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M548.9,372.1c-6.8-2-9.6,1.2-9.7,7.9.4,7.2-.7,13.6-6.5,17.7-1.6,2.9,2.4,5.9,2.7,9.2,0,.7.3,1.4.7,2,1.7,2.7,4.5,3.6,7.9,3.4,2.4-1.7,5.5-2.1,9-2.1,1.2,0,2.3-.7,2.7-1.9,1.3-4.1,3.9-8.2,7.3-12.4,0-6.6-1.5-11.3-4.1-14.4l-6.2-7.2c-1-1.1-2.3-2-3.8-2.5h0Z"
        />
        <path
          id="boneparthighlight7"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M580.4,372.2c-3.6-3.8-7.9-4.8-13.3-2.1-7.2,4.3-8.9,10.3-8.1,17.2.8,4.9,2.3,7.7,4.4,9,1,.7,2.4.5,3.4-.3,3.9-3.5,7.8-5.9,11.7-6.6,1.1-.2,1.9-1.2,1.9-2.3,0-4.9,0-9.3.8-12.2.2-.9,0-1.9-.7-2.6h0Z"
        />
        <path
          id="boneparthighlight6"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M591.2,404.5c-1.5-.9-2.5-2.4-2.8-4.2-.8-4.6-2.3-6.8-4.4-6.8-6.5-.6-11.7.9-15.2,5.2-.4.5-.9,1-1.4,1.4-4.7,4.1-7,7.7-6,10.6.4,1.1.1,2.3-.5,3.3-1.9,3.1-.7,5.9,2.8,8.4,1.3.9,2.4,2,3.5,3.1,8.2,8.8,16.8,11.9,25.5,12.1,2,0,4.1-.2,6-1,3.3-1.3,5.1-3.6,5.5-6.6.2-1.3,0-2.7-.3-4-1-3.3-2.1-6.5-3.6-9.4-2.2-5.7-5.2-9.7-9.1-12.1h0Z"
        />
        <path
          id="boneparthighlight5"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M623.2,395.4c1,3.2,1.3,6.6.7,9.8-.8,4.2-2.7,6.8-6.1,7.2-8.5,2.1-16.5,2.4-23.5-.7-3.8-1.9-5.3-4.9-5.5-8.4-1.5-3.4-1.7-6.5,1-8.7,1-1,1.4-2,1.4-3.2,0-2.1-1.3-4-3.2-4.9l-.6-.3c-2.3-1.1-3.8-3.3-3.8-5.8,0-3.4,1.7-6.1,4.3-8.2.8-.7,1.5-1.6,1.7-2.6,1.1-5.2,5-6.6,10.2-6.5,1.5,0,3-.4,4.3-1.3,7.9-5.4,12.4-6,14.9-3.7.7,1.3,1.9.5,0,11.6l-.6,7.4c-.1,1.4,0,2.7.4,4l4.4,14.3h0Z"
        />
        <path
          id="boneparthighlight4"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M651.7,358.1c1.5,1.9,2.7,3.9,3.4,5.9,0,7.8-4,11.9-4.9,16.7-.2,1.3-1,2.4-2.1,3-6.9,4-12.8,9.8-16.4,19.1-.5,1.2-1.3,2.3-2.4,3-4,2.5-6.9.7-9.2-4-.2-.4-.3-.8-.5-1.2-1.8-6.1-2.7-13.1-2.9-20.7-1.4-13.2.5-18.7,4.2-20l25.4-4c2.1-.3,4.2.5,5.5,2.1h0Z"
        />
        <path
          id="boneparthighlight3"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M659,413.2c.4-2,1.2-3.9,2.5-5.5,2.5-3.1,2.1-6.6-.2-10.5-2.1-4.4-5.7-6.9-11.4-7.2-5.3.3-9.5,3-11.7,9.8-2.2,5-4.9,9.7-10.8,12-2,.9-1.2,3,2.3,6.1,2.1,1,3.9,3.1,5.4,6.3,1.2,2.7,3.8,4.5,6.7,4.8,3.5.3,6.6-.2,9-2,4.9-4,7.2-8.7,8.2-13.9h0Z"
        />
        <path
          id="boneparthighlight2"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M656.1,412.5c0-1.9-.3-3.7-1-5.5-1-2.6-3-4.7-5.4-6.2-5-3.1-10.2-5.1-15.7-5.6-1.1-.1-2.3.1-3.2.7-1.5,1-2.5,2.8-2.4,6.4,1.2,4.2,2.6,8,4.7,11.1,2.6,3.9,6.8,6.6,11.5,7.1,3.3.4,6.3-.5,9.1-2.7,1.6-1.3,2.5-3.2,2.5-5.2h0Z"
        />
        <path
          id="boneparthighlight1"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M607.6,440.4c-4,.6-5-2.3-4.8-6.6-1.7-3-2.1-6.8-1.6-11.1.2-1.7,1.5-3,3.2-3.1l7-.4c2.7-1.8,5.7-2.6,8.9-2.6s5.3,1.5,6.7,3.9c1.2,2.2,2.1,5,2.7,8.1,3.9,3.5,4.5,6.7-.4,9.1-4.5,2.8-8.7,4.1-12.5,3.9l-9.2-1.2h0Z"
        />
      </g>
      <g id="Highlightsleft">
        <path
          id="bonepartlefthighlight28"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M193.2,382.8l10.6,5.2c0,5.3,2.7,9,10.2,10.1,4.9,2.2,10-.3,15.4-6.5,13.8-18.6,28.9-34.9,45.9-47.6,5.2-3,7.6-6.2,6.7-9.7-.5-11.3-3.4-18.6-9.7-20.4-5.4-3.7-11.4-4.1-17.6-2.1-5.2,1-7.1,6.2-5.9,15.3.6,4.5-.5,9.1-3.2,12.7-7.7,10.1-19.2,16.8-33.1,21.5-6.1,1.9-11.5,4.3-14.9,7.9-5.7,3.9-7.8,8.3-4.4,13.5h0Z"
        />
        <path
          id="bonepartlefthighlight27"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M258.7,300.4c.1,2.3,1.9,4.2,4.2,4.6,8.6,1.8,15.7,6.3,21.5,13.5,3.2,1,5.7-2.3,7.2-10.5,3.2-13.6,12.5-29.2,22.7-45,2.9-3.8,1.5-6.4-2.9-8-1.4-.5-2.6-1.6-3.1-3-2.5-6.6-6.6-9.2-12.3-7.6-4.9,2.3-6.4,6.7-5,12.8.6,2.7.4,5.5-.7,8.1-4.6,10.7-15,19.7-28.5,27.9-1.9,2.3-3.2,4.7-3.1,7.2h0Z"
        />
        <path
          id="bonepartlefthighlight26"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M310.3,250.1c2.1,0,4.7,1.1,7.5,3,1.2.8,2.9.2,3.3-1.2,3.6-12.3,9.2-22.8,16.4-31.7,2.4.1,4.5-2.8,6.3-8.9,1.5-6.9-1.4-7.3-5-6.7-11.7,2.7-16.7,6.7-15.5,12.2l-14.9,13.2c-5.8.6-8.7,3.4-7.3,9.5,5.3,2,8.3,5.6,9.2,10.7h0Z"
        />
        <path
          id="bonepartlefthighlight25"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M179,373.5c-2.4-3.8-4.9-6.9-7.6-7.9-5.7-2-10.7-1.4-15,2.1-1.2,1-3,1-4.3,0-2.6-2-3.3-3.7-2.1-4.9,6.3-6.8,10.9-14.8,13.3-24.3,3.4-1.5,6.3-3.2,8-5.8,14-31,19.3-59.9,18.5-87.3,0-.9.3-1.8.9-2.5l3.3-3.6c1.1-3.2,2.9-4.9,5.6-5,5.2-5.6,10.8-7.7,17.2-3.1,6.3,5.1,9.1,13.8,6.5,27.6-.4,2.2-1.8,4-3.8,5h0c-2.6,1.3-4.7,3.4-5.8,6.1l-15.4,36.2c-6.7,18.7-8.8,35.9-1.4,49.8.8,2.7.5,5.3-.6,7.9-4.5,9.8-9.5,13.9-15.2,11.7-.9-.4-1.7-1.1-2.3-1.9h0Z"
        />
        <path
          id="bonepartlefthighlight24"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M224.4,233.1c-2.4-4.2-5.8-7.6-11.6-8.5-4.7-.8-8.9.5-12.7,4.3-5.2-1-4.6-7.2-2.5-14.7,4.8-9.4,8.3-18.9,9-28.5l2.5-24.9c.1-1-.1-1.9-.5-2.8-1.4-3.4-.2-7.9,1.8-12.7,1.6-3,4.2-3.8,8-2.1,3,1.3,6,1.8,9,1.3,5.1-1.6,8.2.1,7.6,8.7-.3,4-1.2,7.8-3.1,11.5-.7,1.3-1,2.7-1,4.2,0,9.2-.5,18.3-1.8,27.1-.3,1.8-.3,3.7,0,5.5l3.7,22.2c-.1,7.4-3,10.5-8.4,9.5h0Z"
        />
        <path
          id="bonepartlefthighlight23"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M212.2,138.5c2.3-1.5,4.7-1.1,7.2.7,3.8,3.8,8.3,4.1,13.3,1,3.1-.2,4.9-2.2,5.1-6.5-2.9-8.8-3.9-18.2-2.9-28,3.6-3.1,4.8-6.1,2.9-8.9.3-4.5-1-6.8-5.1-5.2-2.9,1.2-6.5.9-10.4,0-2.6-.8-3.9.7-3.5,5.5-1.5,3.2-1.8,6.3,0,9.3,1,8.9-.8,16.3-5.9,22.1-2.8,4.1-4.2,7.8-.8,10h0Z"
        />
        <path
          id="bonepartlefthighlight22"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M223.4,88.5l10.1.4c1.4,0,2.9-.5,3.8-1.6,1-1.3.9-2.6.3-4l-2.5-19.1c3-1.7,4.2-4.5,1.5-9.8-.3-3.1-2.2-5.3-5.4-7-4.7-1.4-7.2.6-7.9,5.2-2.2,4.2-3.8,8.2,0,10.6,0,8.2-.9,15.3-4.6,18.8-2.7,3.7-2.4,6.3,4.6,6.5h0Z"
        />
        <path
          id="bonepartlefthighlight21"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M137.7,359.3c-6.1-1.3-10.6-3.8-14.1-7.1-1.7-1.6-2.3-4-1.8-6.3,1.4-5.9,3.3-11.4,5.7-16.6.4-.8,1-1.4,1.7-1.9,2.9-1.9,5.1-6.3,6.9-12.1,2.4-23,4-44.2,3.5-60.9-1.4-8.6-.8-15.3,3.1-18.8,2.2-2.8,3.8-5.4,4-7.6,5.8-4.3,12.5-5.5,20.3-2.7,4.1,2.2,6.6,5.4,6.4,10.4v17.1c-1.2,2.9-3.3,4.8-6.6,5.4,0,2.7-.9,4.9-3.3,6.1-2.3,3.4-4.2,7.6-5.6,12.8-4.4,15.6-6.5,33.1-2.3,55.9,4.3,7.2,4.4,14.1-1.3,20.5-3,6.7-9,7.9-16.6,5.9h0Z"
        />
        <path
          id="bonepartlefthighlight20"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M142,223c.9.8,2.1,1,3.2.8,8.4-2.1,16.7-2.3,24.9-.4,2,.5,4.2-.3,5.3-2,3.9-6.1,1.3-13.9-5.1-22.8-3.9-10-3.7-28.7-2.5-49.2-1.3-4.3-.4-9,1.4-13.9,1.2-4.8-.4-8-2.8-10.8-1.2-3.9-4.9-3.3-9.8-.6l-10.3-.8c-5.3,1.2-4.6,7.4-3.9,13.4,1.2,3.7,2,7.2,1.4,10-.9,8.2-.6,15.6,1,22.2.5,10.5.7,20.6-1,28.6-4.5,11-6.8,20.6-1.6,25.5h0Z"
        />
        <path
          id="bonepartlefthighlight19"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M141,117.2c.5,1.7,2.3,2.6,4,2.3,2.8-.6,6.1-.3,9.8.7,1.3.3,2.7.2,3.9-.4,2.5-1.2,5-1.9,7.4-1.8,1.5,0,2.9-.8,3.7-2.1,2-3.2,1.5-6.7-.6-10.4-4.8-11-7.1-22.2-6.4-33.6,1.7-1.6,2.5-3.9,1.9-7.2.2-5.6-3-7.6-9.3-6.3-3.7,1.8-7.3,2.7-10.6,2.3-3-.6-3.1,2.2-2.7,5.5-1.8,3.2-2.1,6,0,8.5,4.2,4.7,3,17.3,0,32.8-1.2,3.5-1.9,6.8-1.1,9.6h0Z"
        />
        <path
          id="bonepartlefthighlight18"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M155.9,27.9c2,13,4.1,23.5,7.2,24.1.7,1.6-.2,2.8-2.8,3.6-1.1.3-2.3.5-3.5.6l-11.7.7c-2.4.3-4.1-.5-5.2-2.3-.6-1-.6-2.3.1-3.3,2.8-4.1,3.9-13.3,3.5-26.8"
        />
        <path
          id="bonepartlefthighlight17"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M156.6,32.3c2.5-2,3.4-5.5.8-12.3-2.3-6.5-5.6-8-9.3-6.9-5.4.5-7.4,5.3-8.6,11.1-.6,6.6,1.2,9.1,4.6,8.9"
        />
        <path
          id="bonepartlefthighlight16"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M127.9,350.7c.1-12.9-2.2-22.3-6.3-29.4-1.6-12.6-1.1-28.9,0-46.4,3.1-8.2,5.4-16.2,6.3-24-.5-4.3-1.8-9.2-4.1-14.7-2.8-3.3-6.9-4.6-12.8-3.4-6.6-.6-9.5,4-9.7,12.2-3.2,5.1-3.5,9.7,0,13.7l3.9,33.3v32.9c0,1.7-.9,3.4-2.3,4.3-3.2,2.1-6.2,6.1-8.7,12.3-3,4.5-3.1,8.1,1.4,9.9l22.9,7.8c6.3,1.3,9-2.2,9.5-8.4h0Z"
        />
        <path
          id="bonepartlefthighlight15"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M95.7,230.5c1.2,1.6,3.4,2,5.2,1.2,7.2-3.3,14.8-3.8,22.8-1.9,1.8.4,3.7-.4,4.5-2,1.8-3.4,1.5-7.2-.3-11.1-8.3-11.2-10.2-28.5-10.4-47.5.9-4.2.3-8.8-1-13.5-.4-1.3-.4-2.6-.2-3.9l1.3-7.8c-.2-4.9-1.5-8.4-4.3-10.3-.5-2.6-5-1.9-12.3,1.1-3.3-1-6.2-.4-8.8,2-1.1,5.1-.4,11.2,1.2,17.7,1.4,1.8,2.2,3.5,2.2,5-.2,4.7-.3,9.3.8,13.4,2,12.5,3.7,25.1,2.2,37.6-.1,1.1-.4,2.2-.8,3.2l-2.8,7c-.4,1-.6,2.2-.6,3.3,0,2.8.5,5.1,1.5,6.6h0Z"
        />
        <path
          id="bonepartlefthighlight14"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M93.4,132c2.7-1.1,5.9-1.3,9.6-.4,3.8-2.8,7.5-4.3,11-3.9,5-.7-3.5-13.7-6.4-25.9-3.4-4.7-3.5-10.3-1.4-16.4.8-2.6.6-5.2-.7-7.8.3-3-.3-4.8-3.4-3.7-4.6,1.3-9.1,1.7-13.5,1.4-4.6-1.9-5.3,2.5-4.7,9,.5,7.5,6.5,9.2,4.9,15.6l-1.5,24.6c0,6.2,1.5,9.7,5.9,7.4h0Z"
        />
        <path
          id="bonepartlefthighlight11"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M83.9,69.7c2.8,1.8,7.4,2.1,12.9,1.6,4-.4,5.9-1.2,5.7-2.5,4.8-1.7,4.2-4.1.9-7-3.8-5-5-11.4-4.7-18.6,3.4-.8,3.6-5,1-12.3-.5-2.8-2.8-4.7-8.2-5.2-4.3.2-7.5,2-7.7,8.3-.9,5.2-.7,9.2,2.8,9.2.7,10.5-.6,17.1-4,19.9-2.1,3.1-2.9,5.7,1.2,6.5h0Z"
        />
        <path
          id="bonepartlefthighlight12"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M92,364c4.6-3.8,10.9-1.3,13.3-4.6,4.1-5,2.6-10.6-2.4-16.7.9-3.5.2-6.8-2.7-9.8-7.2-8.7-12.5-26-17.5-44.1-1.6-8.8-1.5-18.3.2-28.2.1-.7,0-1.4-.1-2-2.3-6.8-5.8-10.6-10.6-11.2-5.8.3-10.1,2.3-13.6,5.5-2.1,4.6-1.9,9.4,0,14.4-.7,14.1,15.8,39.1,17.9,65.4l-1.4,8.2c0,6,.6,11.5,3.9,14.8,1.3,8,6.3,9.2,12.6,8l.5.3h0Z"
        />
        <path
          id="bonepartlefthighlight11-2"
          data-name="bonepartlefthighlight11"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M55.1,250.5c.7-.4,1.3-.9,1.8-1.4,4.5-4,10.4-5.7,17.6-5.3,2.1.1,4.1-1.3,4.4-3.3.8-4.6-2.6-9.6-8-14.8-4.7-7.4-8-21.5-14-34.2-.7-1.6-1.2-3.2-1.2-5,0-3.5-1-6.9-2.6-10.1-1.9-2.6-4.2-2.9-7.1.2-.9.9-2,1.5-3.3,1.7-2.4.4-4.3.9-5.7,1.7s-2.1,2.3-2,3.9c.3,4.5,3.3,9.6,6.8,14.8,1.3,2,2,4.2,2.2,6.6.5,6.1,2,12.2,4.6,18.4.6,1.3.8,2.8.7,4.2l-.9,16.7c0,.7,0,1.4.2,2.1,1.2,4.4,3.4,5.4,6.5,3.7h0Z"
        />
        <path
          id="bonepartlefthighlight10"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M35,176.3c4.3,0,8.2-1.9,11.9-4.7,3.2-1.7,3.8-4.4,0-8.8-6.2-8.3-9.2-14.9-7.7-18.9-.8-4-2.1-6.4-3.9-7.7-1.1-.8-2.7-.7-3.8.1-2.6,2-5.7,3.2-9.2,4-1.7.4-3,1.8-3.1,3.5-.1,2.9,1.4,5.6,4.4,8.2,1.4,1.1,2.2,2.8,2.4,4.5.6,5,1.2,9.9,1.7,14.7.4,5.5,2.8,7.3,7.2,5h0Z"
        />
        <path
          id="bonepartlefthighlight9"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M21.6,137.4c2.5-1.7,5-3,7.8-3.2,5.4-1.4,5.8-4.5,2.2-8.9-3.4-2.7-5.9-6-7.3-10-.4-1.1-.3-2.3.2-3.3l.4-.8c-.8-4.1-2-7.3-3.8-9.4-1.9-2.2-5.1-2.9-7.8-1.8-2.9,1.2-4.2,2.8-3.5,5.1.3.8.2,1.8,0,2.6-.8,3.7.3,6.7,4.7,8.5l2.3,8.5c-.8,9.2-.2,15.6,4.9,12.7h0Z"
        />
        <path
          id="bonepartlefthighlight8"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M188.5,370.6c-1.5.4-2.8,1.3-3.8,2.5l-6.3,7.2c-2.5,3.1-4,7.7-4.1,14.4,3.4,4.1,6,8.3,7.3,12.4.4,1.2,1.5,1.9,2.7,1.9,3.5,0,6.6.4,9,2.1,3.4.1,6.2-.7,7.9-3.4.4-.6.6-1.3.7-2,.3-3.3,4.3-6.4,2.7-9.2-5.8-4-6.9-10.5-6.5-17.7-.1-6.7-2.9-9.9-9.7-7.9h0Z"
        />
        <path
          id="bonepartlefthighlight7"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M157,370.7c-.7.7-.9,1.7-.7,2.6.7,3,.8,7.4.8,12.3,0,1.1.8,2.1,1.9,2.3,3.9.7,7.8,3.1,11.7,6.6.9.8,2.3,1,3.4.3,2-1.3,3.6-4.1,4.4-9,.7-6.9-.9-12.9-8.1-17.2-5.4-2.6-9.7-1.6-13.3,2.1h0Z"
        />
        <path
          id="bonepartlefthighlight6"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M146.2,403c-3.9,2.4-6.9,6.5-9.1,12.1-1.4,3-2.6,6.1-3.6,9.4-.4,1.3-.5,2.7-.3,4,.4,3,2.2,5.3,5.5,6.6,1.9.8,4,1.1,6,1,8.7-.3,17.3-3.4,25.5-12.1,1.1-1.1,2.2-2.2,3.5-3.1,3.5-2.5,4.7-5.3,2.8-8.4-.6-1-.8-2.2-.5-3.3,1-2.9-1.3-6.5-6-10.6-.5-.4-1-.9-1.4-1.4-3.5-4.3-8.7-5.9-15.2-5.3-2.2,0-3.7,2.2-4.4,6.8-.3,1.7-1.3,3.2-2.8,4.2h0Z"
        />
        <path
          id="bonepartlefthighlight5"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M114.2,393.8l4.4-14.3c.4-1.3.5-2.6.4-4l-.6-7.4c-1.9-11.1-.7-10.3,0-11.6,2.5-2.3,7.1-1.7,14.9,3.7,1.3.9,2.8,1.3,4.3,1.3,5.1-.2,9.1,1.2,10.2,6.5.2,1,.8,1.9,1.7,2.6,2.6,2.2,4.3,4.8,4.3,8.2,0,2.5-1.5,4.7-3.8,5.8l-.6.3c-1.9.9-3.3,2.8-3.2,4.9,0,1.2.4,2.3,1.4,3.2,2.8,2.2,2.5,5.3,1,8.7-.2,3.5-1.6,6.5-5.5,8.4-7,3.1-15,2.8-23.5.7-3.4-.4-5.3-3-6.1-7.2-.6-3.3-.3-6.6.7-9.8h0Z"
        />
        <path
          id="bonepartlefthighlight4"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M85.7,356.6c1.3-1.6,3.4-2.4,5.5-2.1l25.4,4c3.6,1.3,5.5,6.8,4.2,20-.2,7.5-1.1,14.5-2.9,20.7-.1.4-.3.8-.5,1.2-2.2,4.7-5.2,6.5-9.2,4-1.1-.7-2-1.8-2.4-3-3.6-9.4-9.5-15.1-16.4-19.1-1.1-.6-1.9-1.8-2.1-3-.9-4.7-5-8.8-4.9-16.7.7-2,1.9-4,3.4-5.9h0Z"
        />
        <path
          id="bonepartlefthighlight3"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M78.4,411.7c1,5.1,3.2,9.9,8.2,13.9,2.5,1.8,5.5,2.4,9,2,2.9-.3,5.5-2.1,6.7-4.8,1.5-3.2,3.3-5.2,5.4-6.3,3.5-3.1,4.3-5.2,2.3-6.1-5.9-2.2-8.5-6.9-10.8-12-2.2-6.8-6.4-9.5-11.7-9.8-5.7.3-9.3,2.8-11.4,7.2-2.4,3.8-2.7,7.4-.2,10.5,1.3,1.6,2.1,3.5,2.5,5.5h0Z"
        />
        <path
          id="bonepartlefthighlight2"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M81.3,410.9c0,2,.9,3.9,2.5,5.2,2.8,2.3,5.8,3.1,9.1,2.7,4.7-.5,8.9-3.2,11.5-7.1,2-3.1,3.5-6.9,4.7-11.1,0-3.6-.9-5.4-2.4-6.4-.9-.6-2.1-.8-3.2-.7-5.5.5-10.8,2.5-15.7,5.6-2.4,1.5-4.3,3.6-5.4,6.2-.7,1.7-1,3.5-1,5.5h0Z"
        />
        <path
          id="bonepartlefthighlight1"
          class="Hand1345Fill"
          style={{
            fill: props.colors.Hand1345Fill,
            stroke: props.colors.Hand1345Fill,
          }}
          d="M129.8,438.8l-9.2,1.2c-3.8.2-8-1.1-12.5-3.9-4.9-2.4-4.3-5.6-.4-9.1.6-3.1,1.5-5.9,2.7-8.1,1.3-2.4,3.9-3.9,6.7-3.9s6.2.7,8.9,2.6l7,.4c1.7.1,3,1.4,3.2,3.1.5,4.3.1,8.1-1.6,11.1.2,4.3-.9,7.2-4.8,6.6h0Z"
        />
      </g>
    </svg>
  );
}

export default BeideHandeDpSitzend1345;
