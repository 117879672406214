import style from "./style.scss";

function RechtesKniegelenkInnenrotationVdoblLiegend2328(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308.01 697.61">
      <g id="underlay">
        <ellipse
          id="close_to_the_joint"
          data-name="close to the joint"
          class="gelenknahen2328Fill"
          style={{
            fill: props.colors.gelenknahen2328Fill,
            stroke: props.colors.gelenknahen2328Fill,
          }}
          cx="152.32"
          cy="356.76"
          rx="112.08"
          ry="28.01"
          transform="translate(-43.31 21.79) rotate(-7.17)"
        />
      </g>
      <g id="knee_innenrotation" data-name="knee innenrotation">
        <g id="background_layer" data-name="background layer">
          <path
            id="femur_background"
            data-name="femur background"
            class="knie_innenrotation_right2328_cls7"
            d="M239.11.67c.46,4.39,1.01,11.21,2.02,22.44,3.6,39.89,3.78,33.49,4.35,44.92.89,17.57.61,36.09,2.9,66.65.75,10.1,0,19.08,0,28.98l30.99,113.02c1.17,14.24,1.16,33.22,0,36.22-7.83,20.31-16.65,15.98-23.75,21.73-13.48,10.93-34.52,10.7-47.82,7.24-11.46-2.98-15.56-7.95-25.82-6.82-9.51,1.04-14.02,6.19-22,11.17-10.27,6.4-23.74,8.13-50.71,11.59-26.56,3.41-40.11,5.01-53.61,0-7.73-2.87-18.75-6.96-24.63-17.39-6.34-11.23-11.88-19.85-8.69-52.16,2.8-28.33,10.83-32.24,17.39-40.57,8.36-10.61,17.81-6.54,34.78-17.39,16.4-10.49,24.43-25.09,28.98-33.33,8.66-15.74,7.87-24.58,13.04-62.31,8.82-64.25,14.69-64.41,14.49-97.08-.15-24.16-3.39-29.49.62-37.58"
          />
          <path
            id="patella_background"
            data-name="patella background"
            class="knie_innenrotation_right2328_cls7"
            d="M279.39,276.66c-3.18-38.66-8.96,51.52,15.37-46.37,17.07-38.64,12.5-45.74,8.69-52.16-5.01-8.45-16.11-14-24.63-15.94-10.56-2.4-24.4,5.42-30.43,1.45"
          />
        </g>
        <g id="lower_leg" data-name="lower leg">
          <path
            id="fibula"
            class="knie_innenrotation_right2328_cls5"
            d="M8.67,697c-1.49-6.67.48-16.07,2.07-32.33,1.83-18.68,1.7-33.23,1.45-62.31-.13-15.1-.64-11.69-1.45-40.57-1.22-43.16.19-41.4-1.45-53.61-3.14-23.52-7.86-26.62-8.69-49.27-.44-11.81.39-23.65,7.24-34.78,1.35-2.2,8.72-14.17,15.94-13.04,4.42.7,4.46,5.61,13.04,14.49,7.01,7.26,8.59,5.63,13.04,11.59,5.5,7.38,8.69,17.39,8.69,17.39,0,0,9.36,5.42,13.04,11.59,4.46,7.49,1.16,17.16-2.9,28.98-1.33,3.86-3.01,8.07-10.14,21.73-11.69,22.41-13.15,22.63-15.5,29.34-2.76,7.91-2.29,12.05-1.88,35.86.48,28.22.72,42.35,0,53.61-1.68,26.1-2.88,24.58-2.27,42.24.29,8.39-.78,14.81-.56,19.68"
          />
          <path
            id="tibia_1"
            data-name="tibia 1"
            class="knie_innenrotation_right2328_cls5"
            d="M124.29,690.72c-.5-6.98-.98-17.59-1.95-34.75-2.67-46.86-3.15-38.74-4.35-68.1-1.61-39.11-.2-40.29-2.9-53.61-3.07-15.23-8.33-27.54-18.84-52.16-11.08-25.97-15.79-31.23-21.73-34.78-9.82-5.88-15.75-2.78-24.63-10.14-7.19-5.95-13.01-16.03-13.04-27.53-.05-15.33,10.23-28.72,21.73-33.33,9.43-3.77,12.66,1.17,21.73-2.9,10.88-4.88,10.3-13.84,21.73-26.08,4.18-4.47,15.64-16.74,31.88-18.84,13.2-1.71,24.76,3.91,40.57,11.59,13.46,6.55,11.45,8.08,18.84,10.14,15.21,4.23,21.76-2.87,42.02,0,9.51,1.34,15.84,2.24,20.29,7.24,6.23,7.02,5.25,18.21,0,39.12-6.77,26.96-13.3,41.08-17.39,53.61-6.19,18.98-4.19,26.6-5.8,69.55-2.92,78.1-10.23,72.51-8.69,117.37,1.07,31.25,5.11,48.37,5.55,60.03"
          />
          <path
            id="tibia_2"
            data-name="tibia 2"
            class="knie_innenrotation_right2328_cls2"
            d="M247.61,352.54c-8.34,2.79-19.35,5.85-32.54,7.82-13.65,2.03-26.3,3.91-42.02,1.45-19.25-3.01-21.24-9.11-33.88-7.24-11.78,1.74-21.14,8.69-26.98,13.04-12.36,9.17-12.64,14.58-20.29,17.39-6.29,2.32-16.55,2.53-33.33-8.69"
          />
          <path
            id="tibia_3"
            data-name="tibia 3"
            class="knie_innenrotation_right2328_cls3"
            d="M92.23,360.99c11.26-28.15,38.46-45.11,82.26-20.91"
          />
        </g>
        <g id="femur_and_patella" data-name="femur and patella">
          <path
            id="femur_1"
            data-name="femur 1"
            class="knie_innenrotation_right2328_cls6"
            d="M278.82,243.33c-.54-6.27-2.17-10.45-2.74-11.91-2.79-7.14-6.19-10.07-9.48-14.81-3.33-4.82-5.32-12.56-9.26-28.05-3.44-13.55-3.82-18.69-8.95-24.92"
          />
          <path
            id="patella_1"
            data-name="patella 1"
            class="knie_innenrotation_right2328_cls6"
            d="M248.37,163.65c-1.52-1.85-4.15-4.98-8.69-7.24-7.39-3.68-17.7-3.82-24.63,1.45-6.62,5.04-2.91,10.37-10.14,23.18-6.44,11.41-15.6,18.24-20.29,21.73h0c-4.72,3.52-5.9,3.6-14.49,8.69-17.33,10.25-26,15.38-30.43,21.73-6.35,9.08-5.67,18.78-4.35,37.67,1.37,19.46-4.18,34.75,7.77,43.47,3.96,2.88,16.94,20.05,31.36,24.63l14.49-11.59c9.35-2.47,8.49-12.93,18.84-21.73,5.87-4.98,8.22-3.37,30.43-10.14,18.22-5.55,27.33-8.32,31.88-13.04,11.04-11.43,9.52-29.4,8.69-39.12"
          />
          <path
            id="patella_2"
            data-name="patella 2"
            class="knie_innenrotation_right2328_cls4"
            d="M279.39,277.78c13.5-17.61,9.08-32.16,15.37-46.37,17.07-38.64,12.5-45.74,8.69-52.16-5.01-8.45-16.11-14-24.63-15.94-10.56-2.4-24.4,5.42-30.43,1.45"
          />
          <path
            id="femur_2"
            data-name="femur 2"
            class="knie_innenrotation_right2328_cls2"
            d="M239.12,1.77c.46,4.39,1.01,11.21,2.02,22.44,3.6,39.89,3.78,33.49,4.35,44.92.89,17.57.61,36.09,2.9,66.65.75,10.1,0,19.08,0,28.98"
          />
          <path
            id="femur_3"
            data-name="femur 3"
            class="knie_innenrotation_right2328_cls2"
            d="M279.37,277.79c1.17,14.24,1.16,33.22,0,36.22-7.83,20.31-16.65,15.98-23.75,21.73-13.48,10.93-34.52,10.7-47.82,7.24-11.46-2.98-15.56-7.95-25.82-6.82-9.51,1.04-14.02,6.19-22,11.17-10.27,6.4-23.74,8.13-50.71,11.59-26.56,3.41-40.11,5.01-53.61,0-7.73-2.87-18.75-6.96-24.63-17.39-6.34-11.23-11.88-19.85-8.69-52.16,2.8-28.33,10.83-32.24,17.39-40.57,8.36-10.61,17.81-6.54,34.78-17.39,16.4-10.49,24.43-25.09,28.98-33.33,8.66-15.74,7.87-24.58,13.04-62.31,8.82-64.25,14.69-64.41,14.49-97.08-.15-24.16-3.39-29.49.62-37.58"
          />
          <path
            id="femur_4"
            data-name="femur 4"
            class="knie_innenrotation_right2328_cls2"
            d="M278.82,244.45c-.54-6.27-2.17-10.45-2.74-11.91-2.79-7.14-6.19-10.07-9.48-14.81-3.33-4.82-5.32-12.56-9.26-28.05-3.44-13.55-3.82-18.69-8.95-24.92"
          />
          <path
            id="patella_3"
            data-name="patella 3"
            class="knie_innenrotation_right2328_cls4"
            d="M248.37,164.77c-1.52-1.85-4.15-4.98-8.69-7.24-7.39-3.68-17.7-3.82-24.63,1.45-6.62,5.04-2.91,10.37-10.14,23.18-6.44,11.41-15.6,18.24-20.29,21.73h0c-4.72,3.52-5.9,3.6-14.49,8.69-17.33,10.25-26,15.38-30.43,21.73-6.35,9.08-5.67,18.78-4.35,37.67,1.37,19.46-4.18,34.75,7.77,43.47,3.96,2.88,16.94,20.05,31.36,24.63l14.49-11.59c9.35-2.47,8.49-12.93,18.84-21.73,5.87-4.98,8.22-3.37,30.43-10.14,18.22-5.55,27.33-8.32,31.88-13.04,2.25-2.34,7.94-3.05,9.26-5.8"
          />
          <path
            id="femur_5"
            data-name="femur 5"
            class="knie_innenrotation_right2328_cls2"
            d="M248.39,164.76c42.58,39.85,28.19,99.72,30.99,113.02"
          />
          <path
            id="femur_6"
            data-name="femur 6"
            class="knie_innenrotation_right2328_cls6"
            d="M248.38,163.65c55.06,34.77,27.63,66.39,30.43,79.69"
          />
        </g>
      </g>
      <g id="overlay">
        <path
          id="tibia_head"
          data-name="tibia head"
          class="Tibiakopffraktur2328Fill"
          style={{
            fill: props.colors.Tibiakopffraktur2328Fill,
            stroke: props.colors.Tibiakopffraktur2328Fill,
          }}
          d="M247.61,352.54c-11.25-3.36-24.22-4.02-39.37-1.24-10.95.34-19.86-1.35-25.21-6.56l-10.84-5.5c-8.01,6.43-16.75,11.42-26.76,13.94l-21.09,3.76-32.76,5.16c-4.82,9.74-9.14,14.14-31.57,13.69,8.75,7.81,18.84,12.25,31.88,9.2,10.89-9.07,28.68-25.2,40.36-27.9,11.14-4.67,23.38-2.55,36.31,3.89,25.8,4.39,52.28-.15,79.04-8.46Z"
        />
      </g>
    </svg>
  );
}

export default RechtesKniegelenkInnenrotationVdoblLiegend2328;
