import {
  setReportType,
  addReportSelectedId,
  removeReportSelectedId,
  addRemoveReportSelectedId,
  setReportDateRange,
  addReportFormat,
  removeReportFormat,
  setInitialReportsState,
  setReportIsPackage,
  addRemoveReportVariant,
  toggleConfiguration
} from '../../actions'

export const mapStateToProps = (state) => ({ reports: state.reports });
export const mapDispatchToProps = {
  setReportType,
  addReportSelectedId,
  removeReportSelectedId,
  addRemoveReportSelectedId,
  setReportDateRange,
  addReportFormat,
  removeReportFormat,
  setInitialReportsState,
  setReportIsPackage,
  addRemoveReportVariant,
  toggleConfiguration
};
