import { MakeDeleteObject, MakeGetObject } from "../AuthHeader";
import moment from "moment";

export async function loadPatient(keycloak, pmed_patient_id) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/patients/${pmed_patient_id}`;
  return fetch(url, MakeGetObject(keycloak))
    .then((data) => {
      if (data.ok) {
        return data.json();
      }
      throw new Error(`${data.status}: ${data.statusText}`);
    })
    .catch((reason) => {
      throw new Error(reason);
    });
}

export async function loadPatientStudies(
  keycloak,
  pmed_patient_id,
  newFirst = true
) {
  let sort = {
    field: "studydate",
    direction: "desc",
  };
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/patients/${pmed_patient_id}/studies${
    newFirst ? `?sort=${encodeURIComponent(JSON.stringify(sort))}` : ""
  }`;
  return fetch(url, MakeGetObject(keycloak)).then((data) => data.json());
}

export function DeletePatient(keycloak, patient_id) {
  const url = new URL(
    `${window.conf.SERVER_CONFIG.BASE_URL}/ris/patients/${patient_id}`
  );
  return fetch(url.href, MakeDeleteObject(keycloak)).then((data) =>
    data.json()
  );
}

export function loadImagePreview(keycloak, imgUrl) {
  fetch(imgUrl, MakeGetObject(keycloak))
    .then((response) => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error("Unable to load preview");
      }
    })
    .then((data) => {
      return URL.createObjectURL(data);
    })
    .catch((err) => {
      console.error(err);
      return process.env.PUBLIC_URL + "/pm_logo.png";
    });
}

export function getGenderIcon(genderChar, size = "", additionalClasses = "") {
  var classText = ``;
  if (genderChar === "M") {
    classText = `fas fa-mars ${size}`;
  } else if (genderChar === "F") {
    classText = `fas fa-venus ${size}`;
  } else {
    classText = `fas fa-genderless ${size}`;
  }
  if (additionalClasses.length > 0) {
    classText += ` ${additionalClasses}`;
  }
  return <i className={classText} />;
}

export function GenderIcon({ genderChar, size = "", additionalClasses = "" }) {
  var classText = ``;
  if (genderChar === "M") {
    classText = `fas fa-mars ${size}`;
  } else if (genderChar === "F") {
    classText = `fas fa-venus ${size}`;
  } else {
    classText = `fas fa-genderless ${size}`;
  }
  if (additionalClasses.length > 0) {
    classText += ` ${additionalClasses}`;
  }
  return <i className={classText} />;
}

export function ageCalc(birthDate) {
  let yrs = moment().diff(birthDate, "years");
  if (yrs !== 0) return `${yrs} years old`;
  let mths = moment().diff(birthDate, "months");
  if (mths !== 0) return `${mths} months old`;
  let days = moment().diff(birthDate, "days");
  return `${days} days old`;
}

export function yearsCalc(birthDate) {
  let yrs = moment().diff(birthDate, "years");
  return yrs;
}

export function PeselDecode(pesel) {
  var rok = parseInt(pesel.substring(0, 2), 10);
  var miesiac = parseInt(pesel.substring(2, 4), 10) - 1;
  var dzien = parseInt(pesel.substring(4, 6), 10);
  if (miesiac > 80) {
    rok = rok + 1800;
    miesiac = miesiac - 80;
  } else if (miesiac > 60) {
    rok = rok + 2200;
    miesiac = miesiac - 60;
  } else if (miesiac > 40) {
    rok = rok + 2100;
    miesiac = miesiac - 40;
  } else if (miesiac > 20) {
    rok = rok + 2000;
    miesiac = miesiac - 20;
  } else {
    rok += 1900;
  }
  var urodzony = new Date();
  urodzony.setFullYear(rok, miesiac, dzien);

  var wagi = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
  var suma = 0;

  for (var i = 0; i < wagi.length; i++) {
    suma += parseInt(pesel.substring(i, i + 1), 10) * wagi[i];
  }
  suma = suma % 10;
  var valid = suma === parseInt(pesel.substring(10, 11), 10);

  var plec = "M";
  if (parseInt(pesel.substring(9, 10), 10) % 2 === 1) {
    plec = "M";
  } else {
    plec = "F";
  }

  return {
    valid: valid,
    sex: plec,
    date: urodzony,
    formatedDate: moment(urodzony).format("yyyy-MM-DD"),
  };
}
