import style from "./style.scss";

export default function LinkesSchultergelenkSchwedenstatusElevationМdStehend2009(
  props
) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.68 428.34">
      <g id="Ebene_1" data-name="Ebene 1">
        <path
          id="background_2"
          data-name="background 2"
          class="Linkes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2009_cls3"
          d="M67.22,402.81c-22.62-4.17-50.34-9.28-50.34-9.28L21.03,52.07c12.92-4.05,55.66-16.67,64.95-19.06,8.07-2.07,18.68-4.79,33.05-7.55,19.09-3.67,18.59-2.1,36.88-2.32,7.13-1.45,18.26.81,25.44-.38,8.52-1.41,22.26,2.76,25.78,5.85,3.18,2.8,1.13,6.71,1.13,7.52,0,11.5-10.69,10.77-18.8,10.86-3.77.04-7.55.09-11.32.13-3.04.04-6.09.07-9.13.21-5.28.25-10.56.82-15.73,1.88-5.26,1.08-10.61,3.1-15.99,3.37-5.09.25-10.32-.87-15.29.64-3.7,1.12-6.81,3.61-10.3,5.26-6.62,3.13-13.5,2.48-20.52,3.38-6.67.85-12.92,3.92-18.42,7.61-3.26,2.19-6.97,3.85-10.7,5.42,1.16.28,2.32.57,2.32.57l14.93,3.66c33.79-9.48,84.19-17.84,138.78-25.93,7.82-2.49,5.27-4.33-4.66-5.71-7.14.59-1.9-5.22-1.42-13.3,2.46-8.56,23.26-9.48,57.96-4.6,7.12.78,10.87,4.08,8.76,11.73-2.41,9.01-14.86,14.23-35.83,16.23-21.97,4.3-43.38,13.96-64.3,28.32l6.64,3.13c7.03,4.42,9.22,19.26-2.38,34.14-10.7,18.93-10.89,33.38-4.11,44.85,10.4,14.55,19.5,28.78,20.62,42.12-22.36,13.3-35.92,40.64-52,74.28-16.47,23.63-31.25,47.18-35.82,70.16-2.28,18.4-7.88,36.17-15.57,53.55,0,0-13.17-2.43-28.76-5.3Z"
        />
        <path
          id="Collarbone"
          class="Weichteile2009Fill1"
          d="M55.27,77.7c5.75-2.6,12.09-4.74,17.29-8.23,5.5-3.7,11.76-6.76,18.42-7.61,7.02-.89,13.9-.25,20.52-3.38,3.49-1.65,6.6-4.14,10.3-5.26,4.98-1.51,10.2-.4,15.29-.64,5.38-.26,10.73-2.29,15.99-3.37,5.18-1.06,10.45-1.63,15.73-1.88,3.04-.14,6.09-.18,9.13-.21,3.77-.04,7.55-.09,11.32-.13,8.11-.1,18.8.64,18.8-10.86,0-.81,2.05-4.72-1.13-7.52-3.52-3.09-17.26-7.26-25.78-5.85-7.18,1.19-18.31-1.06-25.44.38-18.29.23-17.79-1.35-36.88,2.32-14.37,2.76-24.98,5.48-33.05,7.55-9.29,2.39-52.03,15.01-64.95,19.06"
        />
        <g id="shoulder_plate" data-name="shoulder plate">
          <g id="shoulder_plate_1" data-name="shoulder plate 1">
            <path
              class="Linkes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2009_cls2"
              d="M95.87,407.74c5.22-13.16,9.4-26.73,12.28-40.59.98-4.62,1.73-9.27,2.65-13.96,1.82-9.42,5.29-18.44,9.6-26.94,4.45-8.45,9.15-16.68,14.49-24.59,5.46-7.73,10.67-15.58,15.04-23.95,6.31-12.73,13.03-25.33,20.29-37.55,3.69-6.1,7.49-12.32,12.46-17.51,3.15-3.54,7.18-6.4,10.79-9.38,1.78-1.52,3.51-3.12,5.21-4.75,0,0-.3.87-.3.87-1.3-8.25-4.16-16.35-8.88-23.25-1.95-2.76-4.38-5.23-6.3-8.07-4.71-7.24-8.98-15.15-10.15-23.86-.44-3.51-.43-7.07.3-10.59.75-3.52,2.19-6.73,3.6-9.84,2.64-6.23,6.23-12.41,9.89-18.02,3.16-5.53,4.21-12.75,2.71-18.88-1.25-4.73-5.44-7.89-9.86-9.51-1.51-.55-3.02-.76-4.66-.76-3.34.02-6.82.18-10.31-.22-3.39-.39-7.96-1.54-8.81-5.44,0,0,1.05-.28,1.05-.28,2.2,5.71,12.99,3.67,18.06,3.8,3.61-.14,7.23,1.15,10.24,3.15,8.41,5.1,8.33,16.05,5.84,24.42-1.56,5.01-5.18,9.1-7.5,13.61-2.97,5.84-6.26,12.13-7.86,18.45-.67,3.17-.7,6.52-.3,9.77.82,6.65,3.73,12.77,7.04,18.58,2.37,4.55,5.84,8.22,8.86,12.36,4.85,7.23,7.76,15.68,9.01,24.24,0,0,.07.52.07.52l-.37.35c-3.36,3.32-7.16,6.42-10.87,9.28-7.31,5.95-12.48,13.91-17.26,21.96-4.82,8.12-9.4,16.42-13.81,24.78-6.24,12.76-13.16,25.4-21.6,36.83-10.17,15.67-20.16,32.26-23.78,50.8-3.15,18.72-7.89,37.27-15.03,54.9,0,0-1.86-.74-1.86-.74h0Z"
            />
          </g>
          <path
            id="shoulder_plate_2"
            data-name="shoulder plate 2"
            class="Weichteile2009Fill1"
            d="M185.24,89c-2.9,5.54-6.68,12.79-8.54,17.65-2.77,7.23-7.73,21.88-9.44,41.54-1.23,14.23-2.03,25.01,3.15,37.13,5,11.69,11.68,15.52,13.21,16.36,5.15,2.79,12.72,7.58,15.74,7.54"
          />
          <line
            id="shoulder_plate_3"
            data-name="shoulder plate 3"
            class="Weichteile2009Fill1"
            x1="61.54"
            y1="89.91"
            x2="89.23"
            y2="83.36"
          />
          <path
            id="shoulder_plate_4"
            data-name="shoulder plate 4"
            class="Weichteile2009Fill1"
            d="M134.52,105.62c2.61-8.52,10.1-13.47,27.72-13.15,3.92.07,7.05-3.76,8.64-7.55,1.17-2.78,1.64-6.31.46-9.17-1.25-3.04-4.78-4.98-7.58-6.08-7.04-2.77-14.95-1.08-22,.82-4.6,1.25-6.92,1.71-10.74,3.61-.88.44-1.85.68-2.83.72-1.67.07-3.2.27-4.35.35-1.99.14-4.11.29-6.77-.11-.83-.13-2.95-.5-5.13-.25-.98.11-1.83.33-1.95.36-.85.21-1.62.5-2.37.83-1.9.86-2.95,1.75-4.3,2.61-1.38.88-2.48,1.34-4.66,2.22-1.68.68-5.88,2.39-9.44,2.51-4.49.15-4.54-2.35-11.96-5.04-3.71-1.34-3.75-.74-17-3.78-2.2-.5-4.12-.96-4.75-1.11-1.16-.29-2.26-.6-3.31-.95"
          />
        </g>
        <g id="acromion">
          <g id="acromion_1" data-name="acromion 1">
            <path
              class="Linkes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2009_cls2"
              d="M134.25,130.21c14.1-14.18,32.09-35.38,48.2-46.9,23.26-17.61,49.41-25.71,77.94-29.98,5.21-1.04,10.61-3.05,14.32-6.92,3.2-3.51,5.58-10.03,1.66-13.8-1.82-1.61-4.72-1.84-7.45-2.32-2.75-.43-5.52-.77-8.29-1.02-11-1.02-22.47-1.23-33.52-1.46-3.48-.18-6.75.32-9.52,2.3-3.16,1.66-5.2,3.69-5.47,7.41-.36,2.12-.89,4.21-1.59,6.25-.18.6-.47,1.06-.41,1.64.07.93.93,1.7,1.86,1.6.05-.01.16,0,.21,0,2.4.35,6.99.98,9.34,1.33,2.79.43,2.84,4.54.1,5.06-2.45.5-5.79,1.22-8.26,1.62-21.16,3.65-44.99,6.82-66.16,10.33-29.25,4.98-58.73,10.13-86.68,20.35,0,0-.17-.44-.17-.44,27.78-10.88,57.28-16.48,86.56-21.65,23.06-4.15,48.57-7.16,71.57-11.52,0,0,2.71-.56,2.71-.56.29-.06.38-.16.48-.32.19-.31.06-.85-.32-.97-.25-.09-.88-.14-1.17-.19-1.75-.25-6.48-.92-8.29-1.18,0,0,.21,0,.21,0-.93.07-1.92-.23-2.64-.86-1.47-1.21-1.51-3.28-.74-4.88.67-1.94,1.19-3.93,1.53-5.95.53-5.48,3.83-7.33,8.24-9.76,2.74-1.38,5.82-1.54,8.64-1.43,5.57.29,11.18.09,16.81.47,8.14.45,17.17.87,25.2,2.11,2.87.49,5.96.64,8.37,2.66,4.85,4.45,2.46,12.19-1.39,16.48-4.07,4.31-9.7,6.53-15.36,7.69-28.59,4.37-53.85,11.63-77.45,29.12-9.13,6.49-17.21,14.18-24.97,22.25,0,0-23.65,23.88-23.65,23.88l-.48-.47h0Z"
            />
          </g>
          <path
            id="acromion_2"
            data-name="acromion 2"
            class="Linkes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2009_cls8"
            d="M251.7,43.32c-.68,5.08-32.02,7.44-40.72,4.49"
          />
        </g>
        <g id="ribs">
          <path
            id="rib_1"
            data-name="rib 1"
            class="linkes_schultergelenk_schwedenstatus_elevation_vd_stehend2009_cls9"
            d="M14.93,179.05c80.24,16.09,138.26,95.66,84.91,157.9"
          />
          <path
            id="rib_2"
            data-name="rib 2"
            class="linkes_schultergelenk_schwedenstatus_elevation_vd_stehend2009_cls9"
            d="M14.93,149.11c73.66,16.55,195.11,112.01,98.41,213.3"
          />
          <path
            id="rib_3"
            data-name="rib 3"
            class="linkes_schultergelenk_schwedenstatus_elevation_vd_stehend2009_cls9"
            d="M14.93,239.16c83.19,16.09,143.34,95.66,88.03,157.9"
          />
          <path
            id="rib_4"
            data-name="rib 4"
            class="linkes_schultergelenk_schwedenstatus_elevation_vd_stehend2009_cls9"
            d="M14.93,209.22c76.36,16.55,202.28,112.01,102.03,213.3"
          />
          <path
            id="rib_5"
            data-name="rib 5"
            class="linkes_schultergelenk_schwedenstatus_elevation_vd_stehend2009_cls9"
            d="M5.78,304.17c72.98,13.28,129.28,69.81,113.67,124.14"
          />
          <path
            id="rib_6"
            data-name="rib 6"
            class="linkes_schultergelenk_schwedenstatus_elevation_vd_stehend2009_cls9"
            d="M5.77,274.22c64.44,13.14,162.08,76.01,146.27,152.05"
          />
          <path
            id="rib_7"
            data-name="rib 7"
            class="linkes_schultergelenk_schwedenstatus_elevation_vd_stehend2009_cls9"
            d="M.03,366.17c41.59,8.05,77.43,31.96,95.95,61.64"
          />
          <path
            id="rib_8"
            data-name="rib 8"
            class="linkes_schultergelenk_schwedenstatus_elevation_vd_stehend2009_cls9"
            d="M.03,336.22c43.5,9.42,103.08,44.46,127.77,91.59"
          />
          <path
            id="rib_9"
            data-name="rib 9"
            class="linkes_schultergelenk_schwedenstatus_elevation_vd_stehend2009_cls9"
            d="M18.96,120.32c73,15.09,125.79,89.68,77.26,148.03"
          />
          <path
            id="rib_10"
            data-name="rib 10"
            class="linkes_schultergelenk_schwedenstatus_elevation_vd_stehend2009_cls9"
            d="M18.96,92.25c67.02,15.51,177.52,105.01,89.54,199.97"
          />
        </g>
        <g id="upper_arm" data-name="upper arm">
          <path
            id="upper_arm_1"
            data-name="upper arm 1"
            class="Weichteile2009Fill2"
            d="M467.91,119.82l-65.29-3.74c-18.68-1.07-37.22-3.92-55.35-8.52l-69.81-17.71c-3.96-2.08-7.78-3.4-11.38-3.46-3.06-.06-6.12-.71-8.76-2.25-3.71-2.17-10.74-2.87-19.65-2.75-17.46-.04-29.19,3.89-31.56,14.25-.59,2.59-1.77,5.02-3.25,7.23l-7.98,11.91c-8.93,18.51-13.88,36.06-8.63,51.17,4.94,11.98,12.51,20.73,23.81,24.86,17.24,11.43,34.41,12.07,51.51,1.21,12.71-14.26,28.1-18.98,44.7-19.35,3.18-.07,6.36-.45,9.5-1.08,15.79-3.16,31.61-7.06,47.83-6.44,17.03.65,34.07,1.71,51.11,2.49,16.1.73,32.2,1.46,48.31,2.19"
          />
          <path
            id="upper_arm_2"
            data-name="upper arm 2"
            class="Weichteile2009Fill2"
            d="M201.79,125.86c-1.26-4.86-.2-9.76,5.24-14.63,2.06-1.85,4.62-3.01,7.29-3.34l5.48-.7c5.96-9.82,14.71-8.1,23.59-5.83,4.96,1.68,10.25,4.35,15.96,8.2,15.64,10.23,41.94,14.46,69.28,20.88"
          />
          <path
            id="upper_arm_3"
            data-name="upper arm 3"
            class="Weichteile2009Fill3"
            d="M261.56,192.02c11.07-14.25,22.41-23.94,39.73-30.92"
          />
        </g>
        <g id="outlines">
          <g id="outlines_1" data-name="outlines 1">
            <g>
              <line
                class="Weichteile2009Fill3"
                x1="138.45"
                y1="1"
                x2="144.44"
                y2="1.45"
              />
              <path
                class="Linkes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2009_cls7"
                d="M156.73,2.39l121.3,9.24c14.52,1.11,28.85,3.88,42.72,8.25,7.44,2.34,14.57,5.57,21.29,9.53,39.52,23.3,87.45,40.75,140.53,51.62"
              />
              <path
                class="Weichteile2009Fill3"
                d="M488.61,82.25c1.96.38,3.92.75,5.9,1.11"
              />
            </g>
          </g>
          <g id="outlines_2" data-name="outlines 2">
            <g>
              <path
                class="Weichteile2009Fill3"
                d="M477.19,213.05c-2,.02-4,.05-6,.08"
              />
              <path
                class="Weichteile2009Fill0"
                d="M458.98,213.36c-58.16,1.34-113.22,5.8-164.01,14.55"
              />
              <path
                class="Weichteile2009Fill3"
                d="M288.96,228.97c-1.97.36-3.94.72-5.9,1.09"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Ebene_2" data-name="Ebene 2">
        <g
          id="soft_parts_overlay"
          data-name="soft parts overlay"
          class="Linkes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2009_cls6"
        >
          <path
            id="soft_parts_overlay_1"
            data-name="soft parts overlay 1"
            class="Weichteile2009Fill"
            style={{
              fill: props.colors.Weichteile2009Fill,
              stroke: props.colors.Weichteile2009Fill,
            }}
            d="M494.5,83.36c-57.83-10.63-110.01-28.91-152.47-53.94-6.72-3.96-13.85-7.19-21.29-9.53-13.86-4.37-28.19-7.14-42.72-8.25L138.45,1l.09.75c.65,5.55,5.18,9.84,10.75,10.21l130.06,8.49c24.64,3.9,46.76,10.61,63.94,22.85,3.76,2.68,7.58,5.27,11.59,7.55,32.45,18.47,75.5,31.17,122.33,41.81,7.28,1.65,14.67-2.3,17.29-9.29h0Z"
          />
          <path
            id="soft_parts_overlay_2"
            data-name="soft parts overlay 2"
            class="Weichteile2009Fill"
            style={{
              fill: props.colors.Weichteile2009Fill,
              stroke: props.colors.Weichteile2009Fill,
            }}
            d="M477.19,213.05c-69.52.83-134.87,5.86-194.13,17.01h0c-1.99-6.84,2.4-13.93,9.43-15.11,54.78-9.25,113.11-12.28,174.1-10.67,5.14.14,9.52,3.75,10.6,8.77h0Z"
          />
        </g>
        <path
          id="joint_socket_overlay"
          data-name="joint socket overlay"
          class="cavitas2009Fill"
          style={{
            fill: props.colors.cavitas2009Fill,
            stroke: props.colors.cavitas2009Fill,
          }}
          d="M184.77,89.91c3.86,1.65,5.88,6.14,5.43,14.43-.2,3.64-1.22,7.2-2.95,10.41-7.71,14.27-12.87,26.41-13.04,34.36,1.08,12.49,6.38,24.37,15.59,35.69,6.88,8.9,9.41,16.93,8.66,24.32-11.18-3.47-21.09-9.44-27.51-22.19-4.96-15.34-5.04-32.53-2.42-50.74,3.25-16.09,8.54-31.55,16.24-46.28Z"
        />
        <path
          id="joint_overlay"
          data-name="joint overlay"
          class="jointgap2009Fill"
          style={{
            fill: props.colors.jointgap2009Fill,
            stroke: props.colors.jointgap2009Fill,
          }}
          d="M202.33,146.97c-5.7,33.72,21.16,55.12-1.01,62.14-12.42,3.93-31.4-16.18-31.4-51.66s16.63-70.82,29.47-70.82,8.86,25.35,2.95,60.34Z"
        />
      </g>
    </svg>
  );
}
