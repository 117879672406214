import style from "./style.scss";

function LinkesHandgelenkLatSitzend1359(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 229.19 500.9">
      <g id="Backround">
        <path
          id="backround3"
          class="Handgelenk_lat_links1359_cls6"
          d="M76.27,499.81l-2.71-78.08c-.65-11.45,2.09-19.29,7.35-24.46,3.63-7.78,6.3-15.02,6.68-20.96,4.92-7.94,5.59-14.73,3.29-20.72,4.33-6.7,1.57-9.19-4.73-9.57,1.31-4.65-2.67-5.87-9.49-5.25-1.03-2.82-2.39-5.19-5.47-5.26l7.09-6.54c4.43-4.13,5.39-9.48,5.18-15.23,9.64-3.1,16.9-4.03,21.7-9.81,1.82-3.83,1.42-7.43,0-10.93,1.82-.21,3.04-4.5,4.22-9.07,7.6-.12,11.6-4.61,14.12-10.92,1.76-5.11-.29-11.24-4.06-17.81,1.71-1.73,3.5-2.54,5.35-2.37,4.95.41,9.74-1.89,14.42-6.28,1.38-1.76,1.42-4.26.88-7.09.48-4.27,2.41-7.79,5.65-10.63,10.59-16.7,26.46-32,44.36-46.78,13.41-6.22,17.13-13.2,15.18-20.61,6.78-4.88,8.76-9.91,6.31-15.08-2.87-3.91-5.98-8.14-9.33-12.71,4.48,3.08,8.99,6.22,12.13,6.48,4.16.25,6.16-2.47,7.23-6.48.58-16.44,3.13-32.41,7.09-48.05l-4.1-47.54-2.43-28.86-3.32,3.73c-7.37,4.1-8.69,10.18-2.1,18.82-3.71.05-5.62,1.97-6.18,5.29-3.54,4.26-4.61,8.07-2.8,11.35-3.97,22.86-14.67,43.43-34.87,60.76-2.35,4.63-2.25,9.15-.57,13.61l-2.23,1.97c-1.42,3.95-3.81,6.45-7.04,7.66-3.44.84-6.41,7.67-9.3,15.59-6.64,13.99-22.07,29.52-40.06,45.5-14.12,2.5-21.31,8.89-26.82,19.41l-5.91,1.59c2.24-5.98,1.99-12.05-.51-18.22-8.1-14.88-13.08-27.55-12.51-39.56,2.64-25.06,8.39-49.13,17.46-72.15.89-6.92-.07-13-4.79-17.35,9.03-7.28,9.92-12.42,6.94-16.53-1.91-3.65-2.18-6.74-1.2-9.42-1.08-8.36-5.62-13.03-12.67-15.05.46-2.66.22-5.33-.71-8l4.89,2.24c3.86,3.51,7.56,4.45,11.02,1.77,4.76-4.11,4.78-11.68,4.61-19.39l-17.62-.91h-20.36s-8.81,1.22-8.81,1.22L35.18.07l-4.41,21.03c-.82,2.82-.8,5.48,0,7.99-1.59,3.56-2.88,6.87-3.42,9.57l.68,11.24c-7.15,41.77-11.6,81.21-11.09,116.35l-5.87,34.01-2.89,15.69c-1.36,10.23.18,14.74,4.93,12.88v11.1l6.48,12.42c-1.49,5.94-1.14,11.78,1.21,17.5-.83,6.1-.73,11.91,2.44,16.74v3c-4,1.74-7.03,5.26-9.04,10.65-1.57,8.44-1.48,16.38,2.73,23.1,1.89,3.68,3.73,6.17,5.52,7.26l-.95,4.94-8.36,2.23c-8.41,3.99-14.47,10.68-12.22,26.97,9.54,42.92,22.25,106.51,21.94,136.16l53.39-1.05.02-.04h0Z"
        />
        <ellipse
          id="backround2"
          class="Handgelenk_lat_links1359_cls6"
          cx="90.32"
          cy="75.44"
          rx="3.36"
          ry="4.78"
          transform="translate(-23.83 46.85) rotate(-25.81)"
        />
        <ellipse
          id="backround1"
          class="Handgelenk_lat_links1359_cls6"
          cx="94.27"
          cy="39.41"
          rx="3.95"
          ry="4.56"
        />
      </g>
      <g id="Handlat">
        <g id="Overview">
          <path
            id="radius3"
            class="Handgelenk_lat_links1359_cls3"
            d="M76.04,500.86l-2.43-75.94c-.52-12.19,2.17-21.15,7.29-27.65,3.46-6.98,5.96-13.96,6.68-20.96,3.39-6.97,5.42-13.84,3.04-20.35,4.13-5.88,2.12-8.61-4.86-8.81-4.37.17-7.51-1.77-9.11-6.38-.99-4.09-3.17-5.7-6.38-5.16-6.81,5.86-16.26,8.4-31.29,3.95-8.46-4.74-17.07-5.22-25.82-1.82-11.32,6.98-14.99,17.55-11.24,31.59l7.29,31.29c6.9,37.72,12.58,73.2,13.67,100.24"
          />
          <path
            id="radius1"
            class="Handgelenk_lat_links1359_cls3"
            d="M32.73,346.03c-5.55-4.44-11.96-7.4-19.58-8.29"
          />
          <path
            id="ulnar2"
            class="Handgelenk_lat_links1359_cls3"
            d="M68.75,499.81l1.38-76.58c.12-6.53.84-13.04,2.37-19.39,2-8.29,4.96-16.17,10.07-23.11,3.38-5.01,2.64-13.02.76-21.87-1.8-6.94-5.17-10.62-10.48-10.33-15.35,3.21-28.43,2.91-38.43-2.13-6.75-1.63-12.97-1.59-17.01,5.32-2.75,4.91-2.51,13.24,0,24.15,4.96,40.52,6.76,76,3.93,104.08"
          />
          <path
            id="radius2"
            class="Handgelenk_lat_links1359_cls3"
            d="M90.63,355.97c-.55-3.36-2.1-6.32-4.86-8.81,2.03-4.87-1.33-7.34-9.5-7.67"
          />
          <path
            id="ulnar1"
            class="Handgelenk_lat_links1359_cls3"
            d="M46.63,350.08c-5.22-2.36-7.15-21.13-14.64-23.28-7.56-1.28-11.91,4.35-11.15,20.92"
          />
          <g id="Fingers">
            <path
              id="partoffinger23"
              class="Handgelenk_lat_links1359_cls3"
              d="M95.62,248.88c7.54,7.14,15.18,9.32,22.9,6.88.62-.2,1.09-.67,1.43-1.23.79-1.27,2.29-1.73,4.07-1.86,5.33,1.26,9.91-1.45,14.3-5.11,1.12-.93,1.64-2.36,1.54-3.8-.39-5.34,1.56-9.89,5.15-13.87,11.91-17.97,26.5-32.87,42.57-46.09,1.47-1.21,3.1-2.22,4.85-2.96,9.93-4.19,12.77-10.09,12.92-16.65.03-1.32.54-2.59,1.45-3.55,8.11-8.58,6.38-12.91,3.07-16.57l-5.01-5.77c-4.31-9.51-12.2-15.4-25.37-15.95-5.82-1.22-9.31.4-10.48,4.86-1.09,2.52-2.72,4.56-5.94,5.16-.96.18-1.86.62-2.48,1.37-2.76,3.33-5,8.78-7.06,14.93-.57,1.7-1.36,3.32-2.37,4.8-6.86,10.07-18.64,22.88-35.2,38.36-1.59,1.49-3.57,2.47-5.7,2.95-8.21,1.85-14.97,6.16-19.64,14-7.84,7.34-9.53,14.78-6.78,22.28,1.77,6.98,5.45,13.04,11.77,17.82h0Z"
            />
            <path
              id="partoffinger22"
              class="Handgelenk_lat_links1359_cls3"
              d="M138.78,234.9c4.56-19.14,31.44-46.18,36-61.82"
            />
            <path
              id="partoffinger21"
              class="Handgelenk_lat_links1359_cls3"
              d="M161.87,158.19l-35.85,33.27c-4.99,4.34-10.09,7.48-15.42,8.05-6.57,1.72-12.67,4.94-17.84,11.09"
            />
            <ellipse
              id="partoffinger20"
              class="Handgelenk_lat_links1359_cls4"
              cx="203.88"
              cy="145.52"
              rx="4.94"
              ry="7.21"
              transform="translate(-10.1 275.73) rotate(-66.84)"
            />
            <path
              id="partoffinger19"
              class="Handgelenk_lat_links1359_cls3"
              d="M195.44,170.8c3.02-4.37,6.07-7.91,9.21-8.77.56-.15.82-.79.58-1.32-2.15-4.79,6.04-9.58.71-14.37"
            />
            <path
              id="partoffinger18"
              class="Handgelenk_lat_links1359_cls3"
              d="M224.6,38.04c-1.66-1.1-4.09-2.93-6.68-5.01-2.99-1.11-5.79-.36-8.02,5.59-.32.85-.86,1.6-1.5,2.25-1.87,1.9-1.91,4.31-.93,7.01.21.58.25,1.2.15,1.8-3.28,19.34-12.11,38.62-33.14,57.76-1.09.99-2.04,2.15-2.63,3.5-.83,1.92-1.02,3.95-.56,6.09.02,5.79,2.48,9.96,8.1,12.03,2.16.79,4.46,1.16,6.75,1.13,8.03-.12,15.18,2.25,21.64,6.59,5.28,4.17,9.28,4.52,12.03,1.08,1.29-1.62,1.9-3.69,1.94-5.77.34-17.21,2.99-32.43,6.96-46.5"
            />
            <path
              id="partoffinger17"
              class="Handgelenk_lat_links1359_cls3"
              d="M220.04,51.71c-.44,5.85-1.67,10.85-5.01,13.61-1.9,1.57-3.48,3.49-4.71,5.62-5.47,9.42-11.55,16.45-18.23,21.18"
            />
            <path
              id="partoffinger16"
              class="Handgelenk_lat_links1359_cls3"
              d="M224.3,60.37c3.65,18.08-3.18,35.92-1,51.24"
            />
            <path
              id="partoffinger15"
              class="Handgelenk_lat_links1359_cls3"
              d="M208.75,137.52c-6.79-13.67-17.03-20.59-31.39-19.42"
            />
            <path
              id="partoffinger14"
              class="Handgelenk_lat_links1359_cls3"
              d="M208.75,128.42c-7.92-9.15-18.03-14.01-30.18-14.89"
            />
            <path
              id="partoffinger13"
              class="Handgelenk_lat_links1359_cls3"
              d="M217.92,33.03c-5.24-5.48-6.55-10.57-4.43-15.31.8-1.79,2.29-3.14,4.05-4.01,2.24-1.12,3.57-2.74,4.64-4.52"
            />
            <path
              id="partoffinger12"
              class="Handgelenk_lat_links1359_cls3"
              d="M53.71,224.27c6.01.93,8.72-2.17,8.94-8.34.13-3.68-.75-7.32-2.29-10.66-10.14-22.1-13.31-51.53-9.85-87.94,5.05-22.05,13.28-43.9,23.7-65.62,5.68-6.55,4.47-13.47-4.56-20.81-6.47-7.18-12.57-9.74-18.23-6.68-13.57,5.71-22.03,12.62-20.51,21.87.55,4.09.94,8.2-.12,12.57-.33,1.36-.57,2.74-.68,4.13-2.95,36.99-5.73,75.33-10.83,95.81-1.12,4.49-2.11,9.01-2.96,13.56l-8.04,42.99c-1.6,11.67.33,15.52,4.83,13.66,4.47-6.28,10.75-9.34,18.72-9.4l21.87,4.86h.01Z"
            />
            <path
              id="partoffinger11"
              class="Handgelenk_lat_links1359_cls3"
              d="M49.36,226.33c-11.25,1.63-19.55.59-22.87-4.96-5.36-5.82-7.37-13.07-4.07-22.62,3.39-8.65,4.69-18.01,4.25-27.95l3.49-77.92c.13-21.42,1.3-40.83,9.42-46.78,9.81-7.48,19.1-7.71,27.95-2.13,5.39,4.26,8.21,10.31,9.42,17.47-.11,7.18-2.72,15.34-8.05,24.56-7.8,17.78-11.99,36.23-10.18,55.8.97,21.42,2.76,40.31,8.35,47.55,3.87,7.07,7.42,14.15,4.56,21.26-1.76,4.25-2.83,8.2-1.98,11.35"
            />
            <path
              id="partoffinger10"
              class="Handgelenk_lat_links1359_cls3"
              d="M31.84,219.41c-5.02-2.86-5.32-7.41-3.34-12.76,8.76-16.33,11.84-36.28,13.52-57.11l1.66-37.06c.2-27.67.64-47.96,10.34-49.37,7.94-3.15,15.81-1.62,23.65,1.97,4.48,2.35,6.69,6.45,7.47,11.65.71,4.69,0,9.47-1.7,13.89-5.31,13.76-9.61,29.94-13.33,47.52-3.53,16-3.09,31.21,3.95,45.11,5.61,9.87,10.27,19.5,7.75,27.34-2.56,9.57-.71,19.25-9.72,16.43-5.49.66-10.58.38-14.48-2.8"
            />
            <path
              id="partoffinger9"
              class="Handgelenk_lat_links1359_cls3"
              d="M59.14,223.66c5.48,1.5,7.23-3.95,7.34-12.46l-2.13-7.22c-.3-7.92-.94-15.04-5.21-19.59-5.04-15.56-8.27-31.76-5.73-49.75.84-24.82,7.61-45.18,19.75-61.52,10.6-7.84,17.86-15.01,15.19-20.2-2.28-3.56-3.05-7.43-1.97-11.7-1.6-7.23-6.12-13.18-15.49-17.01-7.93-2.83-16.13-3.86-24.69-2.58-10.36,1.19-16.67,6.82-18.83,17.01l.68,11.24c-7.76,37.79-11.1,77.66-11.09,119.12"
            />
            <path
              id="partoffinger8"
              class="Handgelenk_lat_links1359_cls3"
              d="M11.09,200.25c1.3,12.75,4.53,21.39,12.18,20.63"
            />
            <path
              id="partoffinger7"
              class="Handgelenk_lat_links1359_cls3"
              d="M64.04,1.89c.72,10.43,4.18,20.43,10.66,29.97,2.35,3.46,4.37,7.13,6.12,10.93l3.27,7.11c2.26,6.4,1.64,11.21-7.14,11.54-9.19.78-17.15-1.59-24.03-6.68-5.59-5.65-6.67-12.17-5.44-19.14,1.41-9.03.72-20.33-.76-32.51"
            />
            <path
              id="partoffinger6"
              class="Handgelenk_lat_links1359_cls3"
              d="M35.03,1.89l-4.25,19.2c-1.87,8.71,2.67,9.44,5.08,15.16.35.84.96,1.56,1.77,1.98,3.76,1.95,11.16,2.12,19.43,1.86,10.11-1.83,15.8-6.39,16.65-13.91.4-3.59-.54-7.2-2.37-10.31-2.73-4.63-5.69-8.95-8.8-13.07"
            />
            <path
              id="partoffinger5"
              class="Handgelenk_lat_links1359_cls3"
              d="M75.89,1.89c1.99,6.2,3.72,12.28-1.22,15.49-1.81,3.36-5.46,4.96-10.96,4.8l-18.36-1.15c-3.45-.36-5.81-2.36-5.92-7.76-2.38-1.66-3.63-6.54-4.25-13.22"
            />
            <path
              id="partoffinger4"
              class="Handgelenk_lat_links1359_cls3"
              d="M93.51,2.8c.23,7.31-.08,14.14-3.34,18.3-2.27,3.82-6.21,3.93-12.28-.68-4.87-2.73-10.42-4.5-17.18-4.56-6.91-1.15-7.78-6.39-5.16-13.97"
            />
            <ellipse
              id="partoffinger2"
              class="Handgelenk_lat_links1359_cls3"
              cx="94.27"
              cy="39.41"
              rx="3.95"
              ry="4.56"
            />
            <ellipse
              id="partoffinger1"
              class="Handgelenk_lat_links1359_cls1"
              cx="90.32"
              cy="75.44"
              rx="3.36"
              ry="4.78"
              transform="translate(-23.83 46.85) rotate(-25.81)"
            />
            <path
              id="partoffinger3"
              class="Handgelenk_lat_links1359_cls3"
              d="M72.99,19.55c-10.89-2.9-21.78-3.5-32.66-1.79"
            />
          </g>
          <g id="Carpalbones">
            <path
              id="carpalbone9"
              class="Handgelenk_lat_links1359_cls3"
              d="M26.37,335.61c6.62-3.43,11.88-10.33,16.71-18.35,1.88-3.13,5.03-5.38,8.66-5.78,2.83-.31,5.29.46,7.43,2.11,1.55,1.19,2.74,2.79,3.61,4.53,4.16,8.27,5.32,15.18,7.48,17.5l2.08-1.96c1.67-1.58,3.53-2.95,5.4-4.28,4.55-3.24,5.69-9.01,5.69-15.63,0-4.85-.52-9.31-1.9-13.16-1.18-3.3-3.53-6.12-6.7-7.62-2.48-1.17-4.64-1.24-6.22.76-1.38,1.74-2.88,3.39-4.72,4.64-6.59,4.51-13.82,7.26-22.65,5.66-4.98-.39-10.55-2.25-16.46-5-.89-.41-1.84-.71-2.82-.76-2.39-.11-3.76,1.12-3.8,4.08-.54,12.34,3.1,20.99,8.72,27.9,4.22,5.18,10.48,8.3,17.16,8.43,4.18.08,8.34-.55,12.48-2.13,3.07-1.17,5.82-3.04,8.15-5.37,3.69-3.69,10.2-10.2,14.05-14.05,2.03-2.03,4.51-3.54,7.25-4.42l.43-.14c3.23-1.03,6.52-1.85,9.81-2.65,6.06-1.49,9.4-4.7,10.04-9.6.26-1.99-.02-4.02-.64-5.93-2.68-8.27-7.7-16.06-17.42-22.88-6.08-3.98-12.45-6.13-19.25-5.69-2.31.15-4.33,1.63-5.26,3.75l-4.11,9.38c-1.27,2.9-3.7,5.2-6.74,6.1-5.32,1.59-13.45,1.37-25.1,2.71-4.55,1.52-8.93,3.49-12,8.93-1.54,2.73-2.31,5.82-2.52,8.95-.8,12.32,2.87,23.01,16.94,29.87"
            />
            <path
              id="carpalbone8"
              class="Handgelenk_lat_links1359_cls3"
              d="M71.79,268.32c7.36-13.63,10.71-25.75,3.34-33.87-2.44-3.99-5.68-5.73-9.52-5.82-1.53-.04-3.05-.13-4.57-.33-9.27-1.23-17.92-2.93-24.62-6.08-2.39-1.12-5.07-1.54-7.69-1.22-4.24.52-8.15,1.96-11.62,4.62-2.52,1.93-3.99,4.93-3.99,8.1v2.77c0,2.85.74,5.66,2.14,8.15l6.25,11.09c.69,6.43.61,12.7-.73,18.7-.79,3.54-.33,7.24,1.46,10.4,3.22,5.69,7.1,10.56,12.49,13.58,9.2,4.83,17.48,4.57,24.69-1.58,2.12-1.81,3.68-4.19,4.62-6.82l7.75-21.68h0Z"
            />
            <path
              id="carpalbone7"
              class="Handgelenk_lat_links1359_cls3"
              d="M61.05,228.29c-6.13,3.3-11.77,5.08-15.84,2.05-4.12-2.2-8.32-3.64-12.91-1.03l-6.38,1.03c-3.96.99-5.62,9.84-6.31,21.99-1.74,6.28-.95,12.57,1.77,18.85l4.49,23.27c.34,1.75.84,3.47,1.65,5.06,2.54,5.01,6.95,8.15,12.81,9.82,12.37,1.77,19.34-2.96,23.58-10.98,4.65-7.11-.56-42.2,10.61-49.17,5.55-1.56,10.43-1.53,14.86-.44,5.08,1.24,10.5-.32,13.81-4.37.61-.75,1.18-1.55,1.71-2.41,2.06-3.3,2.84-7.25,2.31-11.1-1.15-8.4-4.79-14.31-11.43-17.21-7.95-1.86-15.51-.51-22.48,5.62l-12.26,9.04v-.02h.01Z"
            />
            <path
              id="carpalbone6"
              class="Handgelenk_lat_links1359_cls3"
              d="M39.44,263c-4.02,8-8.9,15.04-15.82,19.78v10.3c.89,4.45,1.79,8.99,2.76,13.82,1.34,6.72,6.99,10.39,15.83,11.81,8.4-.32,15.1-5.35,20.83-13.02,4.91-8.34,7.1-18.16,7.09-29.16-.04-8.74-2.8-14.13-8.2-16.25-7.71-2.88-15.21-2.19-22.48,2.73h-.01Z"
            />
            <path
              id="carpalbone5"
              class="Handgelenk_lat_links1359_cls3"
              d="M122.92,262.27c-2.27-6.56-5.74-11.21-11.11-12.82-2.34-.7-4.57-1.73-6.75-2.84-8.28-4.23-15.48-8.66-18.83-13.84-5.23-4.45-11.21-4.99-17.77-2.43-6.29,3.27-12.51,5.88-18.47,6-2.96.06-5.68,2-6.19,4.92-.4,2.33.26,5.44,2.4,9.6,7.2,12.73,17.7,22.04,31.07,28.39,11.25,5.58,23.16,6.47,35.52,4.1,2.03-.39,4.01-1.12,5.66-2.36,2.05-1.55,3.48-3.59,4.42-5.99,1.59-4.07,1.49-8.61.06-12.73h0Z"
            />
            <path
              id="carpalbone4"
              class="Handgelenk_lat_links1359_cls3"
              d="M90.63,208.78c-3.71,6.58-4.07,12.96-.9,19.12.87,1.69,2.07,3.19,3.5,4.44,6.53,5.74,13.73,9.32,21.19,12.04,3.05,1.11,6.31,1.62,9.53,1.27,3.9-.42,7-1.88,9.66-3.92"
            />
            <path
              id="carpalbone3"
              class="Handgelenk_lat_links1359_cls3"
              d="M27.36,219.79c-2.15-1.72-4.23-2.98-6.22-3.58-3.43-1.03-7.04.95-8.18,4.35-.92,2.75-.87,5.5.16,8.25"
            />
            <path
              id="carpalbone2"
              class="Handgelenk_lat_links1359_cls2"
              d="M109.37,283.92c2.1-9.61-1.46-16.51-10.24-20.92-7.26-3.66-14.04-4.1-19.87,1.82-5.06,5.01-6.97,12.41-7.16,21.1-.04,1.7-.15,3.39-.25,5.08-.43,6.84,2,11.17,7.41,12.85l11.71,2.55c4.18.91,8.49-.83,10.88-4.39,2.77-4.13,5.24-10.47,7.53-18.11v.02h-.01Z"
            />
            <path
              id="carpalbone1"
              class="Handgelenk_lat_links1359_cls2"
              d="M28.95,261.28c3.17,6.2,7.77,8.23,13.29,7.58,2.69-.31,5.14-1.83,6.53-4.16,2.97-4.95,4.12-11.93,5.23-18.95.83-9.69-2.19-15.35-7.39-18.73-9.13-4.47-17.01-5.75-23.36-3.12-4.94,2.13-5.85,7.2-5.06,13.49.23,1.81.77,3.57,1.57,5.21l9.17,18.68h.02Z"
            />
          </g>
        </g>
        <g id="Highlights">
          <path
            id="radiushighlight"
            class="Radius1359Fill"
            style={{
              fill: props.colors.Radius1359Fill,
              stroke: props.colors.Radius1359Fill,
            }}
            d="M76.04,500.86l-2.43-75.94c-.52-12.19,2.17-21.15,7.29-27.65,3.46-6.98,5.96-13.96,6.68-20.96,3.39-6.97,5.42-13.84,3.04-20.35,4.13-5.88,2.12-8.61-4.86-8.81-4.37.17-7.51-1.77-9.11-6.38-.99-4.09-3.17-5.7-6.38-5.16-6.81,5.86-16.26,8.4-31.29,3.95-8.46-4.74-17.07-5.22-25.82-1.82-11.32,6.98-14.99,17.55-11.24,31.59l7.29,31.29c6.9,37.72,12.58,73.2,13.67,100.24"
          />
          <path
            id="ulnarhighlight"
            class="Ulna1359Fill"
            style={{
              fill: props.colors.Ulna1359Fill,
              stroke: props.colors.Ulna1359Fill,
            }}
            d="M68.75,499.81l1.38-76.58c.12-6.53.84-13.04,2.37-19.39,2-8.29,4.96-16.17,10.07-23.11,3.38-5.01,2.64-13.02.76-21.87-1.8-6.94-5.17-10.62-10.48-10.33-15.35,3.21-28.43,2.91-38.43-2.13-6.75-1.63-12.97-1.59-17.01,5.32-2.75,4.91-2.51,13.24,0,24.15,4.96,40.52,6.76,76,3.93,104.08l1.54,20.91"
          />
        </g>
      </g>
    </svg>
  );
}

export default LinkesHandgelenkLatSitzend1359;
