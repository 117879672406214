import style from "./style.scss";

function Linke4zeheDplOblSitzend1417(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.86 155.12">

<g id="forth_toe" data-name="forth toe">
  <path id="forth_toe_1" data-name="forth toe 1" class="linke_4zehe_dpl_obl_sitzen_links1417_cls3" d="M30.93,152.03c4.59-7.03,5.08-12.51-1.02-15.76.6-15.9-4.6-21.5-16.5-15.4-7.1,1-9,6.6-6.8,15.8-2.2,5.3-1.8,10.5,2.2,15.4.59.88,1.16,1.82,1.71,2.81"/>
  <path id="forth_toe_2" data-name="forth toe 2" class="linke_4zehe_dpl_obl_sitzen_links1417_cls3" d="M11.91,115.37c-9.3,2.5-10.9-2.2-6.8-12.4,14-16.8,17.5-32.3,10.9-46.5-4-8.5-1.3-11,7.5-7.9,14.6-8.4,17.7-4.6,15,5.2l-3.8,13.1c-4.9,9.9-4.8,21.6-3.4,33.8,4.9,8.1,4.9,14.3.8,18.8-6.4-9.7-13.2-10.2-20.2-4.1h0Z"/>
  <path id="forth_toe_3" data-name="forth toe 3" class="linke_4zehe_dpl_obl_sitzen_links1417_cls3" d="M16.41,42.97c-7.8,1.8-6.9-2.1-3.4-7.5-2.2-5.6-.7-9.6,6.8-11.2,10.8-1.7,15.1.7,13.9,6.8,3.6,8.4,1.6,13-6.4,13.5l-10.9-1.6h0Z"/>
  <path id="forth_toe4" data-name="forth toe4" class="linke_4zehe_dpl_obl_sitzen_links1417_cls3" d="M11.01,7.67C13.31.87,16.41-1.53,20.51,2.37c1.5,1.4,2.2,3.4,2.3,5.4.2,2,1.8,3.4,4.8,4.6s4.6,4.4,3.2,7.2-4.7,3.2-8.6,2.6c-14.2,2.1-20.6,0-15.1-8.6,1.7-1.1,3.6-1.9,3.9-5.9h0Z"/>
</g>
<g id="overlays">
  <g id="Phalangen_overlay" data-name="Phalangen overlay">
    <path id="Phalangen_overlay_1" data-name="Phalangen overlay 1" class="a1417_phalangenFill" style={{
            fill: props.colors.a1417_phalangenFill,
            stroke: props.colors.a1417_phalangenFill,
          }} d="M11.91,115.37c-9.3,2.5-10.9-2.2-6.8-12.4,14-16.8,17.5-32.3,10.9-46.5-4-8.5-1.3-11,7.5-7.9,14.6-8.4,17.7-4.6,15,5.2l-3.8,13.1c-4.9,9.9-4.8,21.6-3.4,33.8,4.9,8.1,4.9,14.3.8,18.8-6.4-9.7-13.2-10.2-20.2-4.1h0Z"/>
    <path id="Phalangen_overlay_2" data-name="Phalangen overlay 2" class="a1417_phalangenFill" style={{
            fill: props.colors.a1417_phalangenFill,
            stroke: props.colors.a1417_phalangenFill,
          }} d="M16.41,42.97c-7.8,1.8-6.9-2.1-3.4-7.5-2.2-5.6-.7-9.6,6.8-11.2,10.8-1.7,15.1.7,13.9,6.8,3.6,8.4,1.6,13-6.4,13.5l-10.9-1.6h0Z"/>
    <path id="Phalangen_overlay_3" data-name="Phalangen overlay 3" class="a1417_phalangenFill" style={{
            fill: props.colors.a1417_phalangenFill,
            stroke: props.colors.a1417_phalangenFill,
          }} d="M11.01,7.67C13.31.87,16.41-1.53,20.51,2.37c1.5,1.4,2.2,3.4,2.3,5.4.2,2,1.8,3.4,4.8,4.6s4.6,4.4,3.2,7.2-4.7,3.2-8.6,2.6c-14.2,2.1-20.6,0-15.1-8.6,1.7-1.1,3.6-1.9,3.9-5.9h0Z"/>
  </g>
  <g id="joint_overlay" data-name="joint overlay">
    <ellipse id="joint_overlay_1" data-name="joint overlay 1" class="a1417_gelenkeFill" style={{
            fill: props.colors.a1417_gelenkeFill,
            stroke: props.colors.a1417_gelenkeFill,
          }} cx="19.85" cy="22.93" rx="15.46" ry="6.58" transform="translate(-3.04 3.04) rotate(-8.13)"/>
    <ellipse id="joint_overlay_2" data-name="joint overlay 2" class="a1417_gelenkeFill" style={{
            fill: props.colors.a1417_gelenkeFill,
            stroke: props.colors.a1417_gelenkeFill,
          }} cx="24.94" cy="45" rx="16.05" ry="6.08" transform="translate(-6.11 3.98) rotate(-8.13)"/>
    <ellipse id="joint_overlay_3" data-name="joint overlay 3" class="a1417_gelenkeFill" style={{
            fill: props.colors.a1417_gelenkeFill,
            stroke: props.colors.a1417_gelenkeFill,
          }} cx="18.68" cy="116.35" rx="18.68" ry="7.9"/>
  </g>
</g>
</svg>
  );
}

export default Linke4zeheDplOblSitzend1417;
