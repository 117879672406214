import style from "./style.scss";

function LinkesOberesSprunggelenkStressaufnahmeLatLiegend1467(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 381.71 473.56">
 
 <g id="upper_ankle" data-name="upper ankle">
   <g id="background_layer" data-name="background layer">
     <path id="background1" class="oberes_Sprunggelenk1467_Stressaufnahme_lat_links_cls3" d="M219.21,11.52l43.1,166.71c4.81,18.59,6.71,37.86,5.14,57-2.44,29.83-12.21,53.2-33.26,66.61-4.04,2.58-3.25,8.64,1.31,10.12,30.97,10.05,51.9,33.03,65.27,65.73,2,4.9,5.76,8.89,10.53,11.18l51.8,24.86c12.01,5.77,19.51,18.25,18.52,31.54-.25,3.28-.82,6.55-1.72,9.8-2.43,8.76-10.15,15.02-19.22,15.61-111.58,7.26-222.74-.68-344.83-7.73-7.72-.45-13.82-6.79-13.87-14.53-.03-4.09.75-8.79,2.93-14.58l12.27-15.92c6.68-8.67,8.65-20.15,4.98-30.46-6.23-17.48-9.37-38.8-10.43-62.71-.23-5.2-.91-10.37-1.9-15.49-4.42-22.98-4.26-57.2,2.24-85.96,3.04-13.43,3.89-27.26,2.72-40.97L.04,10.4C-.44,4.81,3.96.01,9.58.01h194.77c7,0,13.11,4.73,14.86,11.5Z"/>
   </g>
   <path id="fibulua" class="oberes_Sprunggelenk1467_Stressaufnahme_lat_links_cls5" d="M78.58,4.43c8.74,103.55,25.84,201.05,57.92,287.59,21.32,10.09,32.95,28.24,28.91,58.98,4.42,33.62-3.27,60.13-27.86,76.94-36.5,24.4-65.61,25.36-84.05-7.68-2.11-17.48-6.82-32.66-15.85-44.19-10.85-23.82-11.53-44.09-2.69-61.09C35.65,230.07,26.9,86.46,11.44,1.74"/>
   <path id="tibia" class="oberes_Sprunggelenk1467_Stressaufnahme_lat_links_cls5" d="M200.29,1.07c-2.11,41.21,4.32,82.32,21.32,123.34,17,37.85,28.53,75.21,22.38,111.33-5.09,19.79-11.53,38.52-26.03,50.53-1.54,1.34-2.88,2.88-3.84,4.61-6.63,11.62-14.98,17.19-26.9,10.85-39.96-17.19-83.47-10.18-130.25,19.4-3.27,2.02-7.11,2.79-10.85,1.92-13.26-2.98-23.82-11.05-28.62-30.07-3.27-36.89,6.53-61.38,20.36-82.22,1.34-2.02,2.31-4.32,2.88-6.63C55.72,141.6,61.2,73.01,59.56.01"/>
   <path id="talus" class="oberes_Sprunggelenk1467_Stressaufnahme_lat_links_cls5" d="M228.72,463.39c-25.07,9.22-47.55,11.14-60.61-12.58-8.55-15.66-21.9-26.13-38.23-33.33-16.52,18.54-33.62,25.84-51.29,14.7-20.75-15.18-23.82-32.56,2.21-53.5,16.71-32.37,41.5-52.35,74.25-60.04,60.32-8.93,101.82,9.51,131.02,45.82,11.33,24.49,9.13,35.16-4.9,33.81-11.81,9.7-20.08,20.46-20.75,33.33,5.19,12.01-2.02,19.6-18.06,24.01l-13.64,7.78h0Z"/>
   <path id="calcaneus" class="oberes_Sprunggelenk1467_Stressaufnahme_lat_links_cls5" d="M165.9,462.33c-17.29-5.67-33.62-16.71-49.37-31.51-3.17-2.98-7.01-5.28-11.24-6.72-13.74-4.61-26.99-4.42-39.86-.48-17.87,12.49-36.98,22.67-60.61,24.59"/>
   <path id="tarsus" class="oberes_Sprunggelenk1467_Stressaufnahme_lat_links_cls5" d="M368.96,465.02c-1.06-4.42.19-9.22,3.27-14.22,4.61-11.62-3.65-19.6-19.12-25.65l-41.78-20.17c-7.68-3.75-16.91-2.88-23.82,2.31-9.99,7.59-16.62,17.39-17.39,31.12-.77,8.16-5.09,15.47-15.85,21.32"/>
 </g>
 <g id="overlays">
   <path id="talus_joint_surface_overlay" data-name="talus joint surface overlay" class="Talus_Surface1467Fill" style= {{
             fill: props.colors.Talus_Surface1467Fill,
             stroke: props.colors.Talus_Surface1467Fill,
             opacity:props.colors.Talus_Surface1467Fill,
           }} d="M79.14,380.03c14.19-29.58,38.65-50.74,75.91-61.4,31.76-6.96,61.95-2.89,90.62,11.93,17.01,10.26,30.4,21.58,40.4,33.89l7.25,19.71c-71.33-36.9-142.73-38.28-214.17-4.13Z"/>
   <path id="tibia_joint_surface_overlay" data-name="tibia joint surface overlay" class="Tibia_GelenkSurface1467Fill" style= {{
             fill: props.colors.Tibia_GelenkSurface1467Fill,
             stroke: props.colors.Tibia_GelenkSurface1467Fill,
             opacity:props.colors.Tibia_GelenkSurface1467Fill,
           }} d="M235.1,262.63c-5.96,11.47-12.31,19.94-19.03,25.52-6,14.6-16.44,19.74-32.95,11.91-39.26-15.98-81.64-6.24-126.15,21.08-17.67,5.62-31.73-8.57-40.11-25.14,68-21.87,141.3-31.74,218.24-33.36Z"/>
   <path id="upper_ankle_joint_overlay" data-name="upper ankle joint overlay" class="OSG1467Fill" style= {{
             fill: props.colors.OSG1467Fill,
             stroke: props.colors.OSG1467Fill,
             opacity:props.colors.OSG1467Fill,
           }} d="M79.14,380.03c-35.28-10.77-50.09-33.22-49.08-64.7,11.15,8.39,22.52,12.51,34.7,1.07,28.46-17.27,58.22-25.29,89.39-23.15l28.98,6.8c15.41,8.63,26.28,4.35,32.95-11.91,15.05,8.13,21.75,22.65,24.74,40.02-35.94-15.89-71.11-17.45-105.38-2.45-21.3,6.36-39.44,27.33-56.29,54.31Z"/>
 </g>
</svg>
  );
}

export default LinkesOberesSprunggelenkStressaufnahmeLatLiegend1467;
