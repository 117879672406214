import style from "./style.scss";

function RechteHandDpSitzend1338(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 355.1 493.3">

  <g id="fullhand">
    <g id="outlines13">
      <g id="outlines14">
        <path id="outlines16" class="rechts_Hand_dp1338_cls4" d="M155.1,493.3c-1.4-21.2-3.3-40.3-6.9-52.8-2.3-9.2-5.5-17.4-13.1-21.4-7.8-6.2-16.5-13-18.5-15.4-19.1-20-36.6-39.7-51.9-58.9-8.7-31.9-19.3-59.1-34-75.8-5.4-8.4-9.6-22.1-13.4-37.5-6.7-10.5-13.3-20.9-16.3-30.1-1-3,0-6.3,2.6-8.1,7.2-5.3,15.2-6.2,24.5-.2,18.1,14.2,33.9,28.8,42.7,44.5,9.4,19.4,18.9,36.7,28.9,46.6,12.7-28.9,11.5-75.2,8.8-120l-2.9-15.6c-3.2-55.5-2.6-94.5,4.7-104.2,4.3-5.2,8.9-7.2,13.8-7.3,5.6-.2,9.7,2.3,12.7,6.5,8.5,6.1,11.4,27.3,11.2,57.2.9,13.3,2.3,25.7,5.5,34.6,2.3,26.2,4.6,52,8.8,65.8,8.7,1.2,11.7-6.9,12.5-18.8,1.8-21.2,2.3-46.9,2.1-74.8,2.7-14,3.3-29.6,3.1-46,4.6-30.7,9-52.3,13.1-53.2,3.1-6.4,8.3-8.1,14.4-7.7,8.3.2,12.9,3.8,15.4,9.4,2.3,8,1.5,26.4.6,45-1.6,24.4-2.3,47.2-1.1,65.6-4.3,34.8-4.2,56.6-.3,67.4,7.6-4.5,8.7-34.3,9.4-65.9l9.9-46c-.7-29.5,3-46.4,9.1-56.7,3.6-5.9,9.6-7.4,16.9-6.4,6.4.4,9.9,3.6,11.7,8.4,7.9,5.4,5.7,30.6,3,56.7l-6.8,60.7c-11.4,50.4-10.6,69.8-4.9,76.6,5.5,8,14.9-24.2,26.5-53.3,17.4-34.5,25.1-75.1,36.7-72.6,3.6-1.8,8.2-1.4,13.3,0,5.1,2.4,7.9,6.1,7.1,11.9,2.3,7.1-1,20.2-6.7,35.7-5.9,16.9-11.1,32.7-13.8,44-5.8,11-17.4,41.5-15.9,56.1.8,15.7,1.2,30.3-2.3,36.1-3.5,43.9-8.8,84.4-20.9,111.5-7.3,6.6-1.9,40.5-.7,73.8.6,8.8-1.5,19.4.7,28.4"/>
        <g id="outlines15">
          <path id="outlinepart51" class="rechts_Hand_dp1338_cls11" d="M293.6,482.1c.2,1.7.4,3.3.8,4.9"/>
          <path id="outlinepart50" class="rechts_Hand_dp1338_cls16" d="M226.7,179.7c3.4-12,4.1-34.2,4.6-57.4l9.9-46c-.7-29.5,3-46.4,9.1-56.7,3.6-5.9,9.6-7.4,16.9-6.4,6.4.4,9.9,3.6,11.7,8.4,7.9,5.4,5.7,30.6,3,56.7l-6.8,60.7c-11.4,50.4-10.6,69.8-4.9,76.6,5.5,8,14.9-24.2,26.5-53.3,17.4-34.5,25.1-75.1,36.7-72.6,3.6-1.8,8.2-1.4,13.3,0,5.1,2.4,7.9,6.1,7.1,11.9,2.3,7.1-1,20.2-6.7,35.7-5.9,16.9-11.1,32.7-13.8,44-5.8,11-17.4,41.5-15.9,56.1.8,15.7,1.2,30.3-2.3,36.1-3.5,43.9-8.8,84.4-20.9,111.5-7.3,6.6-1.9,40.5-.7,73.8.3,4.2,0,8.9-.2,13.5"/>
          <path id="outlinepart49" class="rechts_Hand_dp1338_cls11" d="M220.6,183.3c.4,1.8.8,3.4,1.3,4.8,1.2-.7,2.2-2,3.1-3.8"/>
          <path id="outlinepart48" class="rechts_Hand_dp1338_cls10" d="M199.8,1.7c2.3-.9,4.8-1.1,7.5-1,8.3.2,12.9,3.8,15.4,9.4,2.3,8,1.5,26.4.6,45-1.6,24.4-2.3,47.2-1.1,65.6-2.9,23.2-3.8,40.7-2.9,52.9"/>
          <path id="outlinepart47" class="rechts_Hand_dp1338_cls11" d="M190,12.4c1-2.3,1.9-3.7,2.9-3.9.8-1.7,1.8-3,2.8-4.1"/>
          <path id="outlinepart46" class="rechts_Hand_dp1338_cls5" d="M170.9,197.5c2.3-3.3,3.3-8.6,3.7-15.1,1.8-21.2,2.3-46.9,2.1-74.8,2.7-14,3.3-29.6,3.1-46,2.5-16.4,4.9-30.2,7.2-39.6"/>
          <path id="outlinepart45" class="rechts_Hand_dp1338_cls11" d="M160.9,196.4c.4,1.7.9,3.4,1.3,4.8,1.9.3,3.5,0,4.9-.5"/>
          <path id="outlinepart44" class="rechts_Hand_dp1338_cls14" d="M103.1,275c9-29,7.8-70.4,5.3-110.7l-2.9-15.6c-3.2-55.5-2.6-94.5,4.7-104.2,4.3-5.2,8.9-7.2,13.8-7.3,5.6-.2,9.7,2.3,12.7,6.5,8.5,6.1,11.4,27.3,11.2,57.2.9,13.3,2.3,25.7,5.5,34.6,1.6,18.7,3.2,37.2,5.6,51.3"/>
          <path id="outlinepart43" class="rechts_Hand_dp1338_cls11" d="M96.3,280.6c1.1,1.3,2.2,2.5,3.4,3.7.7-1.5,1.3-3.1,1.9-4.6"/>
          <path id="outlinepart42" class="rechts_Hand_dp1338_cls12" d="M154.4,483.4c-1.4-17.2-3.2-32.4-6.2-42.9-2.3-9.2-5.5-17.4-13.1-21.4-7.8-6.2-16.5-13-18.5-15.4-19.1-20-36.6-39.7-51.9-58.9-8.7-31.9-19.3-59.1-34-75.8-5.4-8.4-9.6-22.1-13.4-37.5-6.7-10.5-13.3-20.9-16.3-30.1-1-3,0-6.3,2.6-8.1,7.2-5.3,15.2-6.2,24.5-.2,18.1,14.2,33.9,28.8,42.7,44.5,6.4,13.2,12.9,25.5,19.5,35.1"/>
          <path id="outlinepart41" class="rechts_Hand_dp1338_cls11" d="M155.1,493.3c-.1-1.7-.2-3.3-.3-5"/>
        </g>
      </g>
    </g>
    <g id="fullbones">
      <path id="bonebonepart77" class="rechts_Hand_dp1338_cls11" d="M162,383l-10.6,5.2c0,5.3-2.7,9-10.3,10.1-4.9,2.2-10-.3-15.4-6.5-13.8-18.6-28.9-34.9-45.9-47.6-5.2-3-7.6-6.2-6.7-9.7.5-11.3,3.4-18.6,9.7-20.4,5.5-3.7,11.4-4.1,17.6-2.1,5.2,1,7.1,6.2,5.9,15.3-.6,4.5.5,9.1,3.2,12.7,7.7,10.1,19.2,16.8,33.1,21.5,6.1,1.9,11.4,4.3,14.9,7.9,5.7,3.9,7.8,8.3,4.4,13.5h0Z"/>
      <path id="bonebonepart76" class="rechts_Hand_dp1338_cls11" d="M96.5,300.6c-.1,2.3-1.9,4.2-4.2,4.6-8.6,1.8-15.7,6.3-21.5,13.5-3.3,1-5.7-2.3-7.2-10.5-3.2-13.6-12.5-29.2-22.7-45-2.9-3.8-1.5-6.4,2.9-8,1.4-.5,2.6-1.6,3.1-3,2.5-6.6,6.6-9.2,12.3-7.6,4.9,2.3,6.4,6.7,5,12.8-.6,2.7-.4,5.5.7,8.1,4.6,10.7,15,19.8,28.5,27.9,1.9,2.3,3.2,4.7,3.1,7.2h0Z"/>
      <path id="fingertipbonepart75" class="rechts_Hand_dp1338_cls11" d="M44.9,250.3c-2.1,0-4.7,1.1-7.5,3-1.2.8-2.9.2-3.3-1.2-3.6-12.3-9.2-22.8-16.4-31.7-2.4.1-4.5-2.8-6.3-8.9-1.5-6.9,1.4-7.3,5-6.7,11.7,2.7,16.7,6.8,15.5,12.2l14.9,13.2c5.8.6,8.7,3.4,7.3,9.5-5.3,2-8.3,5.6-9.2,10.7h0Z"/>
      <path id="fingertipbonepart74" class="rechts_Hand_dp1338_cls11" d="M37.3,253.2c2.5,1.9,5,1.9,7.5-.3.4-.5.4-1.5,0-2.6,2.6,1,5,.4,7.2-.9,2.5-2.2,2.7-5.8,2-9.8"/>
      <path id="bonepart73" class="rechts_Hand_dp1338_cls11" d="M70.7,318.7c1.7,2.1,4.3,2.4,7.6,1.1,3.9-2.9,4.8-7.1,5-11.5,3.9.7,7.5.9,10.5-.7,1.2-.9,1.4-2.2,1-3.7"/>
      <path id="bonepart72" class="rechts_Hand_dp1338_cls11" d="M89.7,322.8c-3.3-.7-4.8-2.5-3-5.9,1.1-1.8,3.2-3,6-3.9,3.6-1.1,5.7-.5,6.5,1.5.7,1.9,0,4-1.6,5.2-1.8,1.4-4.5,2.3-8,3Z"/>
      <path id="bonepart71" class="rechts_Hand_dp1338_cls11" d="M106.9,305.3c1,0,2,1,3.1,3,.2,1.1.2,2.1-.9,3-1.3,1-3.2,1-4.5,0-.7-.6-1.1-1.3-1.4-2.2-.9-2.8.2-4.1,3.6-3.6h0Z"/>
      <path id="bonebonepart70" class="rechts_Hand_dp1338_cls11" d="M176.1,373.7c2.4-3.8,4.9-6.9,7.6-7.9,5.7-2,10.7-1.4,14.9,2.1,1.2,1,3,1,4.3,0,2.6-2,3.3-3.7,2.1-4.9-6.3-6.8-10.9-14.8-13.3-24.3-3.4-1.5-6.3-3.2-8-5.8-14-31-19.3-59.9-18.5-87.3,0-.9-.3-1.8-.9-2.5l-3.3-3.6c-1.1-3.2-2.9-4.9-5.6-5-5.1-5.6-10.7-7.7-17.2-3.1-6.3,5.1-9.1,13.8-6.6,27.6.4,2.2,1.8,4,3.8,5h0c2.6,1.3,4.7,3.4,5.8,6.1l15.4,36.2c6.7,18.7,8.8,35.9,1.4,49.8-.8,2.7-.5,5.3.6,7.9,4.4,9.8,9.5,13.9,15.2,11.7.9-.4,1.7-1.1,2.3-1.9h0Z"/>
      <path id="bonepart69" class="rechts_Hand_dp1338_cls11" d="M160.6,259.7c.5-3.3.8-6.5.6-9.5-2.1-2.2-2.8-4.6-2.7-7.2l-1.8-3.5-1.1-5"/>
      <path id="bonepart68" class="rechts_Hand_dp1338_cls11" d="M139.7,267.4c-3.5-5.4-6-10.4-3.6-13.8,1.5-1.2,1.7-2.7,1-4.5"/>
      <path id="bonebonepart67" class="rechts_Hand_dp1338_cls11" d="M130.8,233.3c2.4-4.2,5.8-7.6,11.6-8.5,4.7-.8,8.9.5,12.6,4.3,5.2-1,4.6-7.2,2.5-14.7-4.9-9.4-8.3-18.9-9-28.5l-2.5-24.9c0-1,.1-1.9.5-2.8,1.4-3.4.2-7.9-1.8-12.7-1.6-3-4.2-3.8-8-2.1-3,1.3-6,1.8-9,1.2-5.1-1.6-8.2.1-7.6,8.7.3,4,1.2,7.8,3.1,11.5.7,1.3,1,2.7,1,4.2,0,9.2.4,18.3,1.8,27.1.3,1.8.3,3.7,0,5.5l-3.7,22.2c.1,7.4,3,10.5,8.4,9.5h0Z"/>
      <path id="bonepart66" class="rechts_Hand_dp1338_cls11" d="M130.8,233.3c5,.9,10.4.1,16-1.8l8.2-2.4"/>
      <path id="bonepart65" class="rechts_Hand_dp1338_cls11" d="M143.9,144.2l-1,4.9c.4,1.6,1.7,2.9,4.1,3.8"/>
      <path id="bonepart64" class="rechts_Hand_dp1338_cls11" d="M121.5,145.6c1.8,2,2.5,4.1,2.4,6.4-.6,2.5-1.9,4.9-3.2,5.6"/>
      <path id="bonebonepart63" class="rechts_Hand_dp1338_cls11" d="M142.9,138.7c-2.3-1.5-4.7-1.1-7.3.7-3.8,3.8-8.3,4.1-13.3,1-3.1-.2-4.9-2.2-5.1-6.5,2.9-8.8,3.9-18.2,2.9-28-3.6-3.1-4.8-6.1-2.9-8.9-.3-4.5,1-6.8,5.1-5.2,2.8,1.2,6.5.9,10.4,0,2.6-.8,3.9.7,3.5,5.5,1.5,3.2,1.8,6.3,0,9.3-.9,8.9.8,16.3,5.9,22.1,2.8,4.1,4.2,7.8.8,10h0Z"/>
      <path id="bonepart62" class="rechts_Hand_dp1338_cls11" d="M142.9,138.7c-.9,1.3-2.2,2.5-3.9,3.6-4.6,3.1-10.6,3.5-15.3.6-1.4-.8-2.4-1.8-3-2.8"/>
      <path id="fingertipbonepart61" class="rechts_Hand_dp1338_cls11" d="M131.8,88.7l-10.1.4c-1.4,0-2.9-.5-3.8-1.6-1-1.3-.9-2.6-.3-4l2.6-19.1c-3-1.7-4.2-4.5-1.5-9.8.3-3.1,2.2-5.3,5.4-7,4.7-1.4,7.1.6,7.9,5.2,2.2,4.2,3.8,8.2,0,10.6,0,8.2.9,15.3,4.6,18.8,2.7,3.7,2.4,6.3-4.6,6.5h0Z"/>
      <line id="bonepart60" class="rechts_Hand_dp1338_cls11" x1="131.8" y1="63.4" x2="131.8" y2="56.4"/>
      <line id="bonepart59" class="rechts_Hand_dp1338_cls11" x1="120.1" y1="64.3" x2="120.7" y2="58.7"/>
      <path id="bonepart58" class="rechts_Hand_dp1338_cls11" d="M131.8,85.9c-4.6-.6-9.3,0-13.9,1.6"/>
      <path id="bonebonepart57" class="rechts_Hand_dp1338_cls11" d="M217.5,359.5c6.1-1.3,10.6-3.8,14.1-7.1,1.7-1.6,2.3-4,1.8-6.3-1.4-5.9-3.3-11.4-5.7-16.6-.4-.8-1-1.4-1.7-1.9-2.9-1.9-5.1-6.3-6.9-12.1-2.4-23-3.9-44.3-3.5-60.9,1.4-8.6.8-15.3-3.1-18.8-2.2-2.8-3.8-5.4-4-7.6-5.8-4.3-12.4-5.5-20.3-2.7-4.1,2.2-6.6,5.4-6.4,10.4v17.1c1.2,2.9,3.3,4.8,6.6,5.4,0,2.8.9,4.9,3.3,6.1,2.3,3.4,4.2,7.6,5.6,12.8,4.4,15.6,6.5,33.1,2.3,55.9-4.3,7.2-4.4,14.1,1.2,20.5,3.1,6.7,9,7.9,16.6,5.9h0Z"/>
      <path id="bonepart56" class="rechts_Hand_dp1338_cls11" d="M203.1,350.9l.5-4.1c.1-1.2-.2-2.4-1-3.3h0c-1.2-1.6-1.7-3.6-1.2-5.5.3-1.2.9-2.3,1.6-3.4.6-.9,1-1.9,1.1-3l.9-9.3"/>
      <path id="bonepart55" class="rechts_Hand_dp1338_cls11" d="M192.8,266.1c-1.5-5.1-3.8-11.8-6.9-15.1-2-5.1-1-8.9,1.8-11.9.7-.7,1-1.7.9-2.7l-.8-7.4"/>
      <path id="bonepart54" class="rechts_Hand_dp1338_cls11" d="M208.8,254.6c4.1-6,3.4-12.6-.6-19.5-1.2-1.7-1.7-3.4-1.8-5.2"/>
      <path id="bonepart53" class="rechts_Hand_dp1338_cls11" d="M223.7,325.5c1.5,4.2,2.6,8.4,2,12"/>
      <path id="bonebonepart52" class="rechts_Hand_dp1338_cls11" d="M213.1,223.2c-.9.8-2.1,1-3.2.8-8.4-2.1-16.7-2.3-24.9-.4-2,.5-4.2-.3-5.4-2-3.9-6.1-1.3-13.9,5.1-22.8,3.9-10,3.7-28.7,2.5-49.2,1.3-4.3.4-9-1.4-13.9-1.2-4.8.4-8,2.8-10.8,1.2-3.9,4.9-3.3,9.8-.6l10.3-.8c5.3,1.3,4.6,7.4,3.9,13.4-1.1,3.7-2,7.2-1.4,10,.9,8.2.6,15.6-1,22.2-.6,10.5-.7,20.6,1,28.6,4.6,11,6.8,20.6,1.6,25.5h0Z"/>
      <path id="bonepart51" class="rechts_Hand_dp1338_cls11" d="M211.5,124.1c-.4.3-.7.7-1,1.2-1.8,2.7-1.7,6.3.3,8.8.6.8,1.4,1.5,2.1,2.1"/>
      <path id="bonepart50" class="rechts_Hand_dp1338_cls11" d="M188.3,125.5c1.4,1.1,2,2.5,2.1,4.1s-.5,3.2-1.3,4.5c-.8,1.3-1.7,2.2-2.6,2.9"/>
      <path id="bonepart49" class="rechts_Hand_dp1338_cls11" d="M185.9,220.4c7.1-2.9,14.2-2.9,21.3,0"/>
      <path id="bonebonepart48" class="rechts_Hand_dp1338_cls11" d="M214.1,117.4c-.5,1.7-2.3,2.6-4,2.3-2.8-.6-6.1-.3-9.8.7-1.3.3-2.7.2-3.9-.4-2.5-1.2-5-1.9-7.4-1.8-1.5,0-2.9-.8-3.7-2.1-2-3.2-1.5-6.7.6-10.4,4.8-11,7.1-22.2,6.4-33.6-1.7-1.6-2.5-3.9-1.9-7.2-.2-5.6,3-7.6,9.3-6.3,3.7,1.8,7.3,2.7,10.6,2.3,3-.6,3.1,2.2,2.7,5.5,1.8,3.2,2.1,6,0,8.5-4.1,4.7-3,17.3,0,32.8,1.2,3.5,1.9,6.8,1.1,9.6h0Z"/>
      <path id="fingertipbonepart47" class="rechts_Hand_dp1338_cls11" d="M199.2,28.1c-1.9,13-4.1,23.5-7.2,24.1-.7,1.6.2,2.8,2.8,3.6,1.1.3,2.3.5,3.5.6l11.7.7c2.4.3,4.1-.5,5.2-2.3.6-1,.6-2.3-.1-3.3-2.9-4.1-3.9-13.3-3.5-26.8"/>
      <path id="fingertipbonepart46" class="rechts_Hand_dp1338_cls11" d="M198.5,32.5c-2.5-2-3.4-5.5-.8-12.3,2.3-6.5,5.6-8,9.3-6.9,5.4.5,7.4,5.3,8.6,11.1.6,6.6-1.2,9.1-4.6,8.9"/>
      <path id="bonepart45" class="rechts_Hand_dp1338_cls11" d="M227.3,350.9c-.1-12.9,2.2-22.3,6.3-29.4,1.6-12.6,1.1-28.9,0-46.4-3.1-8.2-5.4-16.2-6.3-24,.5-4.3,1.8-9.2,4.1-14.7,2.8-3.3,6.9-4.6,12.8-3.4,6.6-.6,9.5,4,9.7,12.2,3.2,5.1,3.4,9.7,0,13.7l-3.9,33.3v32.9c0,1.7.9,3.4,2.3,4.3,3.2,2.1,6.2,6.1,8.7,12.3,2.9,4.5,3.1,8.1-1.4,9.9l-22.9,7.7c-6.3,1.3-9-2.2-9.5-8.4h0Z"/>
      <path id="bonepart44" class="rechts_Hand_dp1338_cls11" d="M253,239.1c-1.6,2.2-2.8,4.6-2.4,7.5l.9,6.3c.7,4.3-.9,6.8-4,8"/>
      <path id="bonepart43" class="rechts_Hand_dp1338_cls11" d="M227.2,251.1c3.5-3.6,5.6-7.4,6.3-11.5"/>
      <path id="bonepart42" class="rechts_Hand_dp1338_cls11" d="M250.6,327.4c.5,2.9,1.1,5.8,2.9,7.2"/>
      <path id="bonepart41" class="rechts_Hand_dp1338_cls11" d="M259.5,230.7c-1.2,1.6-3.4,2-5.2,1.2-7.2-3.3-14.8-3.8-22.8-1.9-1.8.4-3.7-.4-4.5-2-1.8-3.4-1.5-7.2.3-11.1,8.3-11.2,10.3-28.5,10.4-47.5-.9-4.2-.3-8.8,1-13.5.4-1.3.4-2.6.2-3.9l-1.3-7.8c.2-4.9,1.5-8.4,4.3-10.3.5-2.6,5-1.9,12.3,1.1,3.3-1,6.2-.4,8.8,2,1.1,5.1.4,11.2-1.2,17.7-1.4,1.8-2.2,3.5-2.2,5,.2,4.7.3,9.3-.8,13.4-2,12.5-3.7,25.1-2.2,37.6.1,1.1.4,2.2.8,3.2l2.8,7c.4,1,.6,2.2.6,3.3,0,2.8-.4,5.1-1.5,6.6h0Z"/>
      <path id="bonepart40" class="rechts_Hand_dp1338_cls11" d="M237.7,144.9c1.4.3,2.8-1,4.2-3.8,1-2.9.8-5.2,0-7.3"/>
      <path id="bonepart39" class="rechts_Hand_dp1338_cls11" d="M262.1,153.9c-1.4-2.7-2-5.9-1.4-9.7-.2-2.6.6-5,2.5-7.2"/>
      <path id="bonepart38" class="rechts_Hand_dp1338_cls11" d="M232.1,226.9c8.9-1.9,16.7-1.2,23.5,2.1"/>
      <path id="bonepart37" class="rechts_Hand_dp1338_cls11" d="M234,229.4c2,1.7,4.7,2.9,8.6,3.3,3.9.4,7.4.4,9.6-1.3"/>
      <path id="bonepart36" class="rechts_Hand_dp1338_cls11" d="M261.8,132.2c-2.7-1.1-5.9-1.3-9.6-.4-3.8-2.8-7.5-4.3-11.1-3.9-5-.7,3.5-13.7,6.4-25.9,3.4-4.7,3.5-10.3,1.4-16.4-.8-2.6-.6-5.2.7-7.8-.3-3,.3-4.8,3.4-3.7,4.6,1.3,9.1,1.7,13.5,1.4,4.6-1.9,5.3,2.5,4.7,9-.5,7.5-6.5,9.2-4.9,15.6l1.5,24.6c0,6.2-1.4,9.7-5.9,7.4h0Z"/>
      <path id="fingertipbonepart35" class="rechts_Hand_dp1338_cls11" d="M271.3,69.9c-2.8,1.8-7.4,2.1-12.9,1.6-3.9-.4-5.9-1.2-5.7-2.5-4.8-1.7-4.2-4.1-.9-7,3.8-5,5-11.4,4.7-18.6-3.4-.8-3.6-5-1-12.3.5-2.8,2.8-4.7,8.2-5.2,4.3.2,7.5,2,7.7,8.3.9,5.3.6,9.2-2.8,9.2-.7,10.5.6,17.1,4,19.9,2.1,3.1,2.9,5.7-1.2,6.5h0Z"/>
      <path id="fingertipbonepart20" class="rechts_Hand_dp1338_cls11" d="M333.6,137.6c-2.5-1.7-5-3-7.8-3.2-5.4-1.4-5.8-4.5-2.2-8.9,3.4-2.7,5.9-6,7.3-10,.4-1.1.3-2.3-.1-3.3l-.4-.8c.8-4.1,2-7.3,3.8-9.4,1.9-2.2,5.1-2.9,7.8-1.8,2.9,1.2,4.2,2.8,3.5,5.1-.3.8-.3,1.8,0,2.6.8,3.7-.3,6.7-4.7,8.5l-2.3,8.5c.8,9.2.2,15.6-4.9,12.7h0Z"/>
      <line id="bonepart34" class="rechts_Hand_dp1338_cls11" x1="268.5" y1="43.5" x2="268.7" y2="37"/>
      <line id="bonepart33" class="rechts_Hand_dp1338_cls11" x1="256.4" y1="43.5" x2="257.4" y2="37"/>
      <path id="bonepart32" class="rechts_Hand_dp1338_cls11" d="M252.7,69c1.3-.7,4-.4,7.8.6,2.6.8,4.9.8,6.7-.7"/>
      <path id="bonepart31" class="rechts_Hand_dp1338_cls11" d="M196.1,53.5c5.2-.5,10.1-.2,14.4,1.5"/>
      <path id="bonebonepart30" class="rechts_Hand_dp1338_cls11" d="M263.1,364.2c-4.6-3.8-10.9-1.3-13.3-4.6-4.1-5-2.6-10.6,2.4-16.7-.9-3.5-.2-6.8,2.7-9.8,7.3-8.7,12.5-26,17.5-44.1,1.6-8.8,1.5-18.2-.2-28.3-.1-.7,0-1.4.1-2,2.3-6.8,5.8-10.6,10.6-11.2,5.7.3,10.1,2.3,13.6,5.5,2.1,4.6,1.9,9.4,0,14.4.7,14.1-15.8,39.1-17.9,65.5l1.4,8.2c0,6-.6,11.5-3.9,14.8-1.3,8-6.3,9.2-12.6,8l-.6.3h0Z"/>
      <path id="bonepart29" class="rechts_Hand_dp1338_cls11" d="M296.6,253c-3,5.9-3,10.5,0,13.8"/>
      <path id="bonepart28" class="rechts_Hand_dp1338_cls11" d="M272.9,265.1c4.7-4.3,5.9-9.9,5.9-15.9"/>
      <path id="bonebonepart27" class="rechts_Hand_dp1338_cls11" d="M300,250.7c-.7-.4-1.3-.9-1.8-1.4-4.5-4-10.4-5.7-17.6-5.3-2.1.1-4.1-1.3-4.4-3.3-.8-4.6,2.6-9.6,8-14.8,4.7-7.4,8-21.5,14-34.2.7-1.6,1.2-3.2,1.2-5,0-3.5,1-6.9,2.6-10.1,1.9-2.6,4.2-2.9,7.1.3.9.9,2,1.5,3.3,1.7,2.5.4,4.3.9,5.7,1.7s2.2,2.3,2,3.9c-.3,4.5-3.3,9.6-6.8,14.8-1.3,2-2,4.2-2.2,6.6-.5,6.1-2,12.2-4.6,18.4-.6,1.3-.8,2.8-.7,4.2l.9,16.7c0,.7,0,1.4-.2,2.1-1.2,4.4-3.4,5.4-6.5,3.7h0Z"/>
      <path id="bonepart26" class="rechts_Hand_dp1338_cls11" d="M281.8,241.7c7-1.1,12.9.7,17.9,5.4"/>
      <path id="bonepart25" class="rechts_Hand_dp1338_cls11" d="M280.6,244c.7,3,3.8,4.4,7.9,5.2,3.9,1.5,7.2,1.7,9.6.2"/>
      <path id="bonepart24" class="rechts_Hand_dp1338_cls11" d="M319.8,182.3c-2.1.7-3.6,2.2-4.4,4.4-.6,2.7,0,4.9,1.7,6.3"/>
      <path id="bonepart23" class="rechts_Hand_dp1338_cls11" d="M303,175.6c1,1.2,1,2.4.3,3.8-.4,2.3-1.6,3.6-3.7,3.8"/>
      <path id="bonebonepart22" class="rechts_Hand_dp1338_cls11" d="M320.2,176.6c-4.3,0-8.2-1.9-11.9-4.7-3.2-1.7-3.8-4.4,0-8.8,6.2-8.3,9.2-14.9,7.8-18.9.8-4,2.1-6.4,3.9-7.7,1.1-.8,2.7-.7,3.8.1,2.5,2,5.7,3.2,9.1,4,1.7.4,3,1.8,3.1,3.5.1,2.9-1.4,5.6-4.4,8.2-1.4,1.1-2.2,2.8-2.4,4.5-.6,5-1.2,9.9-1.7,14.7-.4,5.5-2.8,7.3-7.2,5h0Z"/>
      <path id="bonepart21" class="rechts_Hand_dp1338_cls11" d="M325.7,176.6c-1.5,3.3-3,3.1-4.5.4"/>
      <line id="bonepart19" class="rechts_Hand_dp1338_cls11" x1="340.6" y1="116.9" x2="342.5" y2="109.9"/>
      <path id="bonepart18" class="rechts_Hand_dp1338_cls11" d="M142.9,242.7c.8.9,1.1,2,.8,3.1-1.2,2.8-2.6,3.3-4.2,1.7-1.1-1.1-1.4-2.3-1.1-3.6,1.2-2.6,2.6-3.6,4.4-1.2h0Z"/>
      <path id="bonepart17" class="rechts_Hand_dp1338_cls11" d="M289.4,259.7c-.5,1.9-1.3,2.8-2.4,2-1.9.5-2.6-.6-1.5-3.5.7-1.9,1.7-2.3,3.1-1.2l.9,2.6Z"/>
      <path id="bonebonepart16" class="rechts_Hand_dp1338_cls11" d="M166.6,370.8c1.5.4,2.8,1.3,3.8,2.5l6.2,7.2c2.5,3.1,4,7.7,4.1,14.4-3.4,4.1-6,8.3-7.3,12.4-.4,1.2-1.5,1.9-2.7,1.9-3.5,0-6.6.4-9,2.1-3.4.1-6.2-.7-7.9-3.4-.4-.6-.6-1.3-.6-2-.3-3.3-4.3-6.4-2.7-9.2,5.8-4,6.9-10.5,6.5-17.7.1-6.7,2.9-9.9,9.7-7.9h0Z"/>
      <path id="bonepart15" class="rechts_Hand_dp1338_cls11" d="M157.6,385.2c1.6,6.5,3.7,10.8,6.7,10.4,5.5,1.2,7.7-1.1,8.3-4.9.7-3.4-1.6-6.9,0-10.7"/>
      <path id="bonebonepart14" class="rechts_Hand_dp1338_cls11" d="M198.1,370.9c.7.7.9,1.7.7,2.6-.7,3-.8,7.4-.8,12.2,0,1.1-.8,2.1-1.9,2.3-3.9.7-7.8,3.1-11.7,6.6-.9.8-2.3,1-3.4.3-2-1.3-3.6-4.1-4.4-9-.7-6.9.9-12.9,8.1-17.2,5.4-2.6,9.7-1.6,13.3,2.1h0Z"/>
      <path id="bonebonepart13" class="rechts_Hand_dp1338_cls11" d="M209,403.2c3.9,2.4,6.9,6.5,9.1,12.1,1.4,3,2.6,6.1,3.6,9.4.4,1.3.5,2.7.3,4-.4,3-2.2,5.3-5.5,6.6-1.9.8-4,1.1-6,1-8.7-.3-17.3-3.4-25.5-12.1-1.1-1.1-2.2-2.2-3.5-3.1-3.5-2.5-4.7-5.3-2.8-8.4.6-1,.8-2.2.5-3.3-1-2.9,1.3-6.5,6-10.6.5-.4,1-.9,1.4-1.4,3.6-4.3,8.7-5.9,15.2-5.2,2.2,0,3.7,2.3,4.4,6.8.3,1.7,1.3,3.2,2.8,4.2h0Z"/>
      <path id="bonepart12" class="rechts_Hand_dp1338_cls11" d="M201.1,392.1v6.2c.7,3.3,2.5,4.5,5.5,3.7.2,0,.3.2.2.3-2.3,2.8-2.8,5.6.8,8.8,3.2,4.1,7.2,5.9,11.5,6.8"/>
      <path id="bonebonepart11" class="rechts_Hand_dp1338_cls11" d="M241,394l-4.4-14.3c-.4-1.3-.5-2.6-.4-4l.6-7.4c1.9-11.1.7-10.3,0-11.6-2.5-2.3-7.1-1.7-14.9,3.7-1.3.9-2.8,1.3-4.3,1.3-5.1-.2-9.1,1.2-10.2,6.5-.2,1-.8,1.9-1.7,2.6-2.6,2.2-4.3,4.8-4.3,8.2,0,2.5,1.5,4.7,3.8,5.8l.6.3c1.9.9,3.3,2.8,3.2,4.9,0,1.2-.4,2.3-1.4,3.2-2.8,2.2-2.5,5.3-1.1,8.7.2,3.5,1.7,6.5,5.5,8.4,7,3.1,15,2.8,23.5.7,3.4-.4,5.3-3,6.1-7.2.6-3.3.3-6.6-.7-9.8h0Z"/>
      <path id="bonebonepart10" class="rechts_Hand_dp1338_cls11" d="M269.4,356.8c-1.3-1.6-3.4-2.4-5.5-2.1l-25.4,4c-3.6,1.3-5.5,6.8-4.2,20,.2,7.5,1.1,14.5,2.9,20.7.1.4.3.8.5,1.2,2.2,4.7,5.2,6.5,9.2,4,1.1-.7,1.9-1.8,2.4-3,3.6-9.4,9.5-15.1,16.4-19.1,1.1-.6,1.8-1.8,2.1-3,.9-4.7,4.9-8.8,4.9-16.7-.7-2-1.9-4-3.4-5.9h0Z"/>
      <path id="bonepart9" class="rechts_Hand_dp1338_cls11" d="M256.4,372.5c-3.9,2-3,5.9-5.9,11.6-2.9,1.7-5.4,0-7.7-3.3-1.5-2.2-2.4-4.7-2.6-7.4l-.4-4.5c-.1-1.3-.5-2.6-1.1-3.8-.8-1.7-.7-3.1.9-4.2,2.5-2.3,4.9-1.6,7.2,1.4,3.5,2.5,7,3.2,10.6,2.9"/>
      <path id="bonebonepart8" class="rechts_Hand_dp1338_cls11" d="M276.8,411.9c-1,5.1-3.2,9.9-8.2,13.9-2.5,1.8-5.5,2.4-9,2-2.9-.3-5.5-2.1-6.7-4.8-1.5-3.2-3.3-5.3-5.4-6.3-3.5-3.1-4.3-5.2-2.3-6.1,5.9-2.2,8.6-6.9,10.8-12,2.2-6.8,6.4-9.5,11.7-9.8,5.7.3,9.3,2.8,11.4,7.2,2.3,3.8,2.7,7.4.2,10.5-1.3,1.6-2.1,3.5-2.5,5.5h0Z"/>
      <path id="bonebonepart7" class="rechts_Hand_dp1338_cls11" d="M273.9,411.1c0,2-.9,3.9-2.5,5.2-2.8,2.3-5.8,3.1-9.1,2.7-4.7-.5-8.9-3.2-11.5-7.1-2-3.1-3.5-6.9-4.7-11.1,0-3.6.9-5.4,2.4-6.4.9-.6,2.1-.8,3.2-.7,5.5.5,10.8,2.5,15.7,5.6,2.4,1.5,4.3,3.6,5.4,6.2.7,1.7,1,3.5,1,5.5h0Z"/>
      <path id="bonebonepart6" class="rechts_Hand_dp1338_cls11" d="M225.3,439.1l9.2,1.3c3.8.2,8-1.1,12.5-3.9,4.9-2.4,4.3-5.6.4-9.1-.6-3.1-1.5-5.9-2.7-8.1-1.3-2.4-3.9-3.9-6.7-3.9s-6.2.7-8.9,2.6l-7,.4c-1.7.1-3.1,1.4-3.2,3.1-.5,4.3-.1,8.1,1.6,11.1-.2,4.3.9,7.2,4.8,6.6h0Z"/>
      <path id="bonepart5" class="rechts_Hand_dp1338_cls11" d="M222.2,418.2c.6-1.3,1.8-2.5,3.6-3.7,3.1-2,7-2.7,10.5-1.4,1.7.6,3.4,1.6,5.1,2.9"/>
      <path id="bonepart4" class="rechts_Hand_dp1338_cls11" d="M227.2,490.6c6.7-8.2,11.1-16.3,13.6-24.5,3.1-15.4-.2-21.2-6.8-22.1l-36.7-7.2c-6.7-2.7-14.7-8.9-23.3-16.9-1.5-1.4-3.9-1.4-5.4,0-4,3.8-4.8,9.9-3.4,17.6,3.8,8.8-2.7,15.9-.8,33.1.1,6.6,2.3,13.5,5,20.4"/>
      <path id="bonepart3" class="rechts_Hand_dp1338_cls11" d="M250.6,489.1c0-6.9-1.1-13.1-3.2-18.7-.7-1.9-1.2-4-1.2-6v-8.5c0-2.5,1.5-4.9,3.8-5.9,3-1.3,7-1.6,11.7-1.4,2.8.1,5.4-1.4,6.7-3.8,3.6-6.8,5.1-12.5,9.2-10.4,3.4,1,5.2,5.7,5.7,13.7,1.7,5.1.5,12.3-2.8,21l-.3,19.1"/>
      <path id="bonepart2" class="rechts_Hand_dp1338_cls11" d="M250.6,449.6c11.1,2.6,19.7,8.5,26,5.5,6.6-1.2,9.5,1.3,9.9,6.4,1.5,6.4-1.8,15.4-5.5,24.4"/>
      <path id="bonepart1" class="rechts_Hand_dp1338_cls11" d="M197.3,436.9c9.5,7.4,33.9,6.6,36.7,12,4.4,7.4,2.4,16.5-1.5,26"/>
      <g id="outlines12">
        <path id="outlinepart40" class="rechts_Hand_dp1338_cls11" d="M104.6,285.6c-1.5-.6-3.1-1.3-4.6-1.9"/>
        <path id="outlinepart39" class="rechts_Hand_dp1338_cls15" d="M136.9,304.5c-7.3-5.8-15.5-10.9-24.7-15.4"/>
        <path id="outlinepart38" class="rechts_Hand_dp1338_cls11" d="M143.9,310.4c-1.2-1.1-2.5-2.2-3.8-3.3"/>
      </g>
      <g id="outlines11">
        <path id="outlinepart37" class="rechts_Hand_dp1338_cls11" d="M293.6,482c.2,1.7.4,3.3.8,4.9"/>
        <path id="outlinepart36" class="rechts_Hand_dp1338_cls16" d="M226.6,179.5c3.4-12,4.1-34.2,4.6-57.4l9.9-46c-.7-29.5,3-46.4,9.1-56.7,3.6-5.9,9.6-7.4,16.9-6.4,6.4.4,9.9,3.6,11.7,8.4,7.9,5.4,5.7,30.6,3,56.7l-6.8,60.7c-11.4,50.4-10.6,69.8-4.9,76.6,5.5,8,14.9-24.2,26.5-53.3,17.4-34.5,25.1-75.1,36.7-72.6,3.6-1.8,8.2-1.4,13.3,0,5.1,2.4,7.9,6.1,7.1,11.9,2.3,7.1-1,20.2-6.7,35.7-5.9,16.9-11.1,32.7-13.8,44-5.8,11-17.4,41.5-15.9,56.1.8,15.7,1.2,30.3-2.3,36.1-3.5,43.9-8.8,84.4-20.9,111.5-7.3,6.6-1.9,40.5-.7,73.8.3,4.2,0,8.9-.2,13.5"/>
        <path id="outlinepart35" class="rechts_Hand_dp1338_cls11" d="M220.5,183.2c.4,1.8.8,3.4,1.3,4.8,1.2-.7,2.2-2,3.1-3.8"/>
        <path id="outlinepart34" class="rechts_Hand_dp1338_cls10" d="M199.7,1.5c2.3-.9,4.8-1.1,7.5-1,8.3.2,12.9,3.8,15.4,9.4,2.3,8,1.5,26.4.6,45-1.6,24.4-2.3,47.2-1.1,65.6-2.9,23.2-3.8,40.7-2.9,52.9"/>
        <path id="outlinepart33" class="rechts_Hand_dp1338_cls11" d="M189.9,12.2c1-2.3,1.9-3.7,2.9-3.9.8-1.7,1.8-3,2.8-4.1"/>
        <path id="outlinepart32" class="rechts_Hand_dp1338_cls5" d="M170.9,197.4c2.3-3.3,3.3-8.6,3.7-15.1,1.8-21.2,2.3-46.9,2.1-74.8,2.7-14,3.3-29.6,3.1-46,2.5-16.4,4.9-30.2,7.2-39.6"/>
        <path id="outlinepart31" class="rechts_Hand_dp1338_cls11" d="M160.8,196.2c.4,1.7.9,3.4,1.3,4.8,1.9.3,3.5,0,4.9-.5"/>
        <path id="outlinepart30" class="rechts_Hand_dp1338_cls14" d="M103,274.8c9-29,7.8-70.4,5.3-110.7l-2.9-15.6c-3.2-55.5-2.6-94.5,4.7-104.2,4.3-5.2,8.9-7.2,13.8-7.3,5.6-.2,9.7,2.3,12.7,6.5,8.5,6.1,11.4,27.3,11.2,57.2.9,13.3,2.3,25.7,5.5,34.6,1.6,18.7,3.2,37.2,5.6,51.3"/>
        <path id="outlinepart29" class="rechts_Hand_dp1338_cls11" d="M96.2,280.5c1.1,1.3,2.2,2.5,3.4,3.7.7-1.5,1.3-3.1,1.9-4.6"/>
        <path id="outlinepart28" class="rechts_Hand_dp1338_cls12" d="M154.3,483.3c-1.4-17.2-3.2-32.4-6.2-42.9-2.3-9.2-5.5-17.4-13.1-21.4-7.8-6.2-16.5-13-18.5-15.4-19.1-20-36.6-39.7-51.9-58.9-8.7-31.9-19.3-59.1-34-75.8-5.4-8.4-9.6-22.1-13.4-37.5-6.7-10.5-13.3-20.9-16.3-30.1-1-3,0-6.3,2.6-8.1,7.2-5.3,15.2-6.2,24.5-.2,18.1,14.2,33.9,28.8,42.7,44.5,6.4,13.2,12.9,25.5,19.5,35.1"/>
        <path id="outlinepart27" class="rechts_Hand_dp1338_cls11" d="M155,493.2c-.1-1.7-.2-3.3-.3-5"/>
      </g>
      <g id="outlines10">
        <line id="outlinepart26" class="rechts_Hand_dp1338_cls11" x1="99.6" y1="292.7" x2="99.6" y2="297.7"/>
        <line id="outlinepart25" class="rechts_Hand_dp1338_cls11" x1="99.6" y1="284.1" x2="99.6" y2="289.1"/>
      </g>
      <g id="outlines9">
        <path id="outlinepart24" class="rechts_Hand_dp1338_cls11" d="M278.7,26.4c0-1.6,0-3.3,0-5"/>
        <path id="outlinepart23" class="rechts_Hand_dp1338_cls11" d="M274.2,43.5c1-1.1,1.8-2.5,2.5-4.3"/>
      </g>
      <g id="outlines8">
        <path id="outlinepart22" class="rechts_Hand_dp1338_cls11" d="M250.8,22.5c.2-1.6.4-3.2.8-4.9"/>
        <path id="outlinepart21" class="rechts_Hand_dp1338_cls13" d="M250.7,35.1c-.3-2.3-.4-4.8-.3-7.6"/>
        <path id="outlinepart20" class="rechts_Hand_dp1338_cls11" d="M252.7,42.3c-.6-1.4-1.1-3-1.5-4.8"/>
      </g>
      <g id="outlines7">
        <path id="outlinepart19" class="rechts_Hand_dp1338_cls11" d="M331.3,93.9c.6-1.5,1.3-3,2.1-4.5"/>
        <path id="outlinepart18" class="rechts_Hand_dp1338_cls11" d="M328.1,110.8c0-1.7,0-3.3.2-5"/>
      </g>
      <g id="outlines6">
        <path id="outlinepart17" class="rechts_Hand_dp1338_cls11" d="M352.2,106c.5-1.5,1.1-3.1,1.6-4.7"/>
        <path id="outlinepart16" class="rechts_Hand_dp1338_cls11" d="M342.5,122.1c1.2-.8,2.4-2,3.5-3.5"/>
      </g>
      <g id="outlines5">
        <path id="outlinepart15" class="rechts_Hand_dp1338_cls11" d="M220.5,11.1c0-1.6-.1-3.2-.3-5"/>
        <path id="outlinepart14" class="rechts_Hand_dp1338_cls8" d="M218.8,26.6c.8-2.5,1.4-5.5,1.6-9.2"/>
        <path id="outlinepart13" class="rechts_Hand_dp1338_cls11" d="M214.4,33.3c1.2-1,2.3-2.2,3.2-3.8"/>
      </g>
      <g id="outlines4">
        <path id="outlinepart12" class="rechts_Hand_dp1338_cls11" d="M191.9,13.2c.2-1.6.5-3.2.9-4.9"/>
        <path id="outlinepart11" class="rechts_Hand_dp1338_cls6" d="M192.1,26.4c-.5-2.3-.7-4.9-.6-7.9"/>
        <path id="outlinepart10" class="rechts_Hand_dp1338_cls11" d="M195.2,33.3c-1-1.3-1.8-2.7-2.4-4.4"/>
      </g>
      <g id="outlines3">
        <path id="outlinepart9" class="rechts_Hand_dp1338_cls11" d="M137.3,48.5c-.2-1.6-.5-3.3-.7-5"/>
        <path id="outlinepart8" class="rechts_Hand_dp1338_cls7" d="M137.7,62.8c.5-2.5.6-5.5.3-8.6"/>
        <path id="outlinepart7" class="rechts_Hand_dp1338_cls11" d="M133.7,69.4c1.4-1,2.4-2.3,3.1-3.9"/>
      </g>
      <g id="outlines2">
        <path id="outlinepart6" class="rechts_Hand_dp1338_cls11" d="M111.1,48.5c-.1-1.6-.2-3.2-.3-5"/>
        <path id="outlinepart5" class="rechts_Hand_dp1338_cls9" d="M113.1,62.8c-.6-2.3-1.1-5.1-1.5-8.6"/>
        <path id="outlinepart4" class="rechts_Hand_dp1338_cls11" d="M117.1,69.4c-1.2-.4-2.2-1.7-3.1-3.8"/>
      </g>
      <g id="outlines1">
        <path id="outlinepart3" class="rechts_Hand_dp1338_cls11" d="M10.2,192.2c-1.6-.3-3.2-.4-5-.2"/>
        <path id="outlinepart2" class="rechts_Hand_dp1338_cls11" d="M23.3,204.5c-.6-1.7-1.4-3.1-2.2-4.5"/>
      </g>
      <line id="outlinepart1" class="rechts_Hand_dp1338_cls11" x1="22.5" y1="231.4" x2="18" y2="234.6"/>
    </g>
  </g>
  <g id="Highlithts3">
    <path id="wristjoint" class="Handgelenk1338Fill" style= {{
              fill: props.colors.Handgelenk1338Fill,
              stroke: props.colors.Handgelenk1338Fill,
            }} d="M284.3,416.4c-5.3-6.9-43.2,5.5-58.5,8.5-20.1,3.9-47.6-28.3-61.3-27.8-16.4.6-5.4,29.4,15.2,43.5,13.8,9.5,66.8,16.9,87,9.5,21.7-7.9,20.8-29.7,17.7-33.8Z"/>
  </g>
  <g id="Highlights2">
    <path id="boneparthighlight28" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M161.5,382.2l-10.6,5.2c0,5.3-2.7,9-10.3,10.1-4.9,2.2-10-.3-15.4-6.5-13.8-18.6-28.9-34.9-45.9-47.6-5.2-3-7.6-6.2-6.7-9.7.5-11.3,3.4-18.6,9.7-20.4,5.5-3.7,11.4-4.1,17.6-2.1,5.2,1,7.1,6.2,5.9,15.3-.6,4.5.5,9.1,3.2,12.7,7.7,10.1,19.2,16.8,33.1,21.5,6.1,1.9,11.4,4.3,14.9,7.9,5.7,3.9,7.8,8.3,4.4,13.5h0Z"/>
    <path id="boneparthighlight27" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M96,299.8c-.1,2.3-1.9,4.2-4.2,4.6-8.6,1.8-15.7,6.3-21.5,13.5-3.3,1-5.7-2.3-7.2-10.5-3.2-13.6-12.5-29.2-22.7-45-2.9-3.8-1.5-6.4,2.9-8,1.4-.5,2.6-1.6,3.1-3,2.5-6.6,6.6-9.2,12.3-7.6,4.9,2.3,6.4,6.7,5,12.8-.6,2.7-.4,5.5.7,8.1,4.6,10.7,15,19.8,28.5,27.9,1.9,2.3,3.2,4.7,3.1,7.2h0Z"/>
    <path id="boneparthighlight26" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M44.5,249.5c-2.1,0-4.7,1.1-7.5,3-1.2.8-2.9.2-3.3-1.2-3.6-12.3-9.2-22.8-16.4-31.7-2.4.1-4.5-2.8-6.3-8.9-1.5-6.9,1.4-7.3,5-6.7,11.7,2.7,16.7,6.8,15.5,12.2l14.9,13.2c5.8.6,8.7,3.4,7.3,9.5-5.3,2-8.3,5.6-9.2,10.7h0Z"/>
    <path id="boneparthighlight25" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M175.7,372.9c2.4-3.8,4.9-6.9,7.6-7.9,5.7-2,10.7-1.4,14.9,2.1,1.2,1,3,1,4.3,0,2.6-2,3.3-3.7,2.1-4.9-6.3-6.8-10.9-14.8-13.3-24.3-3.4-1.5-6.3-3.2-8-5.8-14-31-19.3-59.9-18.5-87.3,0-.9-.3-1.8-.9-2.5l-3.3-3.6c-1.1-3.2-2.9-4.9-5.6-5-5.1-5.6-10.7-7.7-17.2-3.1-6.3,5.1-9.1,13.8-6.6,27.6.4,2.2,1.8,4,3.8,5h0c2.6,1.3,4.7,3.4,5.8,6.1l15.4,36.2c6.7,18.7,8.8,35.9,1.4,49.8-.8,2.7-.5,5.3.6,7.9,4.4,9.8,9.5,13.9,15.2,11.7.9-.4,1.7-1.1,2.3-1.9h0Z"/>
    <path id="boneparthighlight24" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M130.4,232.5c2.4-4.2,5.8-7.6,11.6-8.5,4.7-.8,8.9.5,12.6,4.3,5.2-1,4.6-7.2,2.5-14.7-4.9-9.4-8.3-18.9-9-28.5l-2.5-24.9c0-1,.1-1.9.5-2.8,1.4-3.4.2-7.9-1.8-12.7-1.6-3-4.2-3.8-8-2.1-3,1.3-6,1.8-9,1.2-5.1-1.6-8.2.1-7.6,8.7.3,4,1.2,7.8,3.1,11.5.7,1.3,1,2.7,1,4.2,0,9.2.4,18.3,1.8,27.1.3,1.8.3,3.7,0,5.5l-3.7,22.2c.1,7.4,3,10.5,8.4,9.5h0Z"/>
    <path id="boneparthighlight23" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M142.5,137.9c-2.3-1.5-4.7-1.1-7.3.7-3.8,3.8-8.3,4.1-13.3,1-3.1-.2-4.9-2.2-5.1-6.5,2.9-8.8,3.9-18.2,2.9-28-3.6-3.1-4.8-6.1-2.9-8.9-.3-4.5,1-6.8,5.1-5.2,2.8,1.2,6.5.9,10.4,0,2.6-.8,3.9.7,3.5,5.5,1.5,3.2,1.8,6.3,0,9.3-.9,8.9.8,16.3,5.9,22.1,2.8,4.1,4.2,7.8.8,10h0Z"/>
    <path id="boneparthighlight22" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M131.4,87.9l-10.1.4c-1.4,0-2.9-.5-3.8-1.6-1-1.3-.9-2.6-.3-4l2.6-19.1c-3-1.7-4.2-4.5-1.5-9.8.3-3.1,2.2-5.3,5.4-7,4.7-1.4,7.1.6,7.9,5.2,2.2,4.2,3.8,8.2,0,10.6,0,8.2.9,15.3,4.6,18.8,2.7,3.7,2.4,6.3-4.6,6.5h0Z"/>
    <path id="boneparthighlight21" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M217.1,358.7c6.1-1.3,10.6-3.8,14.1-7.1,1.7-1.6,2.3-4,1.8-6.3-1.4-5.9-3.3-11.4-5.7-16.6-.4-.8-1-1.4-1.7-1.9-2.9-1.9-5.1-6.3-6.9-12.1-2.4-23-3.9-44.3-3.5-60.9,1.4-8.6.8-15.3-3.1-18.8-2.2-2.8-3.8-5.4-4-7.6-5.8-4.3-12.4-5.5-20.3-2.7-4.1,2.2-6.6,5.4-6.4,10.4v17.1c1.2,2.9,3.3,4.8,6.6,5.4,0,2.8.9,4.9,3.3,6.1,2.3,3.4,4.2,7.6,5.6,12.8,4.4,15.6,6.5,33.1,2.3,55.9-4.3,7.2-4.4,14.1,1.2,20.5,3.1,6.7,9,7.9,16.6,5.9h0Z"/>
    <path id="boneparthighlight20" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M212.7,222.4c-.9.8-2.1,1-3.2.8-8.4-2.1-16.7-2.3-24.9-.4-2,.5-4.2-.3-5.4-2-3.9-6.1-1.3-13.9,5.1-22.8,3.9-10,3.7-28.7,2.5-49.2,1.3-4.3.4-9-1.4-13.9-1.2-4.8.4-8,2.8-10.8,1.2-3.9,4.9-3.3,9.8-.6l10.3-.8c5.3,1.3,4.6,7.4,3.9,13.4-1.1,3.7-2,7.2-1.4,10,.9,8.2.6,15.6-1,22.2-.6,10.5-.7,20.6,1,28.6,4.6,11,6.8,20.6,1.6,25.5h0Z"/>
    <path id="boneparthighlight19" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M213.7,116.6c-.5,1.7-2.3,2.6-4,2.3-2.8-.6-6.1-.3-9.8.7-1.3.3-2.7.2-3.9-.4-2.5-1.2-5-1.9-7.4-1.8-1.5,0-2.9-.8-3.7-2.1-2-3.2-1.5-6.7.6-10.4,4.8-11,7.1-22.2,6.4-33.6-1.7-1.6-2.5-3.9-1.9-7.2-.2-5.6,3-7.6,9.3-6.3,3.7,1.8,7.3,2.7,10.6,2.3,3-.6,3.1,2.2,2.7,5.5,1.8,3.2,2.1,6,0,8.5-4.1,4.7-3,17.3,0,32.8,1.2,3.5,1.9,6.8,1.1,9.6h0Z"/>
    <path id="boneparthighlight17" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M210.9,33.2c3.4.2,5.2-2.3,4.6-8.9-1-4.8-2.5-8.9-6.1-10.4s-1.6-.5-2.5-.6c-3.7-1-7,.5-9.3,6.9-.3.7-.5,1.3-.7,1.9-1.8,5.6-.8,8.6,1.5,10.4l.7-4.4c-1.9,13-4.1,23.5-7.2,24.1-.7,1.6.2,2.8,2.8,3.6,1.1.3,2.3.5,3.5.6l11.7.7c2.4.3,4.1-.5,5.2-2.3.6-1,.6-2.3-.1-3.3-2.2-3.1-3.3-9.3-3.5-18s0-5.6,0-8.8v8.8c1.6,0,3-.8,3.8-2.4,1-2.1.8-5.2,0-8.9"/>
    <path id="boneparthighlight16" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M226.9,350.1c-.1-12.9,2.2-22.3,6.3-29.4,1.6-12.6,1.1-28.9,0-46.4-3.1-8.2-5.4-16.2-6.3-24,.5-4.3,1.8-9.2,4.1-14.7,2.8-3.3,6.9-4.6,12.8-3.4,6.6-.6,9.5,4,9.7,12.2,3.2,5.1,3.4,9.7,0,13.7l-3.9,33.3v32.9c0,1.7.9,3.4,2.3,4.3,3.2,2.1,6.2,6.1,8.7,12.3,2.9,4.5,3.1,8.1-1.4,9.9l-22.9,7.7c-6.3,1.3-9-2.2-9.5-8.4h0Z"/>
    <path id="boneparthighlight15" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M259.1,229.9c-1.2,1.6-3.4,2-5.2,1.2-7.2-3.3-14.8-3.8-22.8-1.9-1.8.4-3.7-.4-4.5-2-1.8-3.4-1.5-7.2.3-11.1,8.3-11.2,10.3-28.5,10.4-47.5-.9-4.2-.3-8.8,1-13.5.4-1.3.4-2.6.2-3.9l-1.3-7.8c.2-4.9,1.5-8.4,4.3-10.3.5-2.6,5-1.9,12.3,1.1,3.3-1,6.2-.4,8.8,2,1.1,5.1.4,11.2-1.2,17.7-1.4,1.8-2.2,3.5-2.2,5,.2,4.7.3,9.3-.8,13.4-2,12.5-3.7,25.1-2.2,37.6.1,1.1.4,2.2.8,3.2l2.8,7c.4,1,.6,2.2.6,3.3,0,2.8-.4,5.1-1.5,6.6h0Z"/>
    <path id="boneparthighlight14" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M261.4,131.4c-2.7-1.1-5.9-1.3-9.6-.4-3.8-2.8-7.5-4.3-11.1-3.9-5-.7,3.5-13.7,6.4-25.9,3.4-4.7,3.5-10.3,1.4-16.4-.8-2.6-.6-5.2.7-7.8-.3-3,.3-4.8,3.4-3.7,4.6,1.3,9.1,1.7,13.5,1.4,4.6-1.9,5.3,2.5,4.7,9-.5,7.5-6.5,9.2-4.9,15.6l1.5,24.6c0,6.2-1.4,9.7-5.9,7.4h0Z"/>
    <path id="boneparthighlight13" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M270.9,69.1c-2.8,1.8-7.4,2.1-12.9,1.6-3.9-.4-5.9-1.2-5.7-2.5-4.8-1.7-4.2-4.1-.9-7,3.8-5,5-11.4,4.7-18.6-3.4-.8-3.6-5-1-12.3.5-2.8,2.8-4.7,8.2-5.2,4.3.2,7.5,2,7.7,8.3.9,5.3.6,9.2-2.8,9.2-.7,10.5.6,17.1,4,19.9,2.1,3.1,2.9,5.7-1.2,6.5h0Z"/>
    <path id="boneparthighlight12" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M333.1,136.8c-2.5-1.7-5-3-7.8-3.2-5.4-1.4-5.8-4.5-2.2-8.9,3.4-2.7,5.9-6,7.3-10,.4-1.1.3-2.3-.1-3.3l-.4-.8c.8-4.1,2-7.3,3.8-9.4,1.9-2.2,5.1-2.9,7.8-1.8,2.9,1.2,4.2,2.8,3.5,5.1-.3.8-.3,1.8,0,2.6.8,3.7-.3,6.7-4.7,8.5l-2.3,8.5c.8,9.2.2,15.6-4.9,12.7h0Z"/>
    <path id="boneparthighlight11" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M262.7,363.4c-4.6-3.8-10.9-1.3-13.3-4.6-4.1-5-2.6-10.6,2.4-16.7-.9-3.5-.2-6.8,2.7-9.8,7.3-8.7,12.5-26,17.5-44.1,1.6-8.8,1.5-18.2-.2-28.3-.1-.7,0-1.4.1-2,2.3-6.8,5.8-10.6,10.6-11.2,5.7.3,10.1,2.3,13.6,5.5,2.1,4.6,1.9,9.4,0,14.4.7,14.1-15.8,39.1-17.9,65.5l1.4,8.2c0,6-.6,11.5-3.9,14.8-1.3,8-6.3,9.2-12.6,8l-.6.3h0Z"/>
    <path id="boneparthighlight10" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M299.6,249.9c-.7-.4-1.3-.9-1.8-1.4-4.5-4-10.4-5.7-17.6-5.3-2.1.1-4.1-1.3-4.4-3.3-.8-4.6,2.6-9.6,8-14.8,4.7-7.4,8-21.5,14-34.2.7-1.6,1.2-3.2,1.2-5,0-3.5,1-6.9,2.6-10.1,1.9-2.6,4.2-2.9,7.1.3.9.9,2,1.5,3.3,1.7,2.5.4,4.3.9,5.7,1.7s2.2,2.3,2,3.9c-.3,4.5-3.3,9.6-6.8,14.8-1.3,2-2,4.2-2.2,6.6-.5,6.1-2,12.2-4.6,18.4-.6,1.3-.8,2.8-.7,4.2l.9,16.7c0,.7,0,1.4-.2,2.1-1.2,4.4-3.4,5.4-6.5,3.7h0Z"/>
    <path id="boneparthighlight9" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M319.7,175.8c-4.3,0-8.2-1.9-11.9-4.7-3.2-1.7-3.8-4.4,0-8.8,6.2-8.3,9.2-14.9,7.8-18.9.8-4,2.1-6.4,3.9-7.7,1.1-.8,2.7-.7,3.8.1,2.5,2,5.7,3.2,9.1,4,1.7.4,3,1.8,3.1,3.5.1,2.9-1.4,5.6-4.4,8.2-1.4,1.1-2.2,2.8-2.4,4.5-.6,5-1.2,9.9-1.7,14.7-.4,5.5-2.8,7.3-7.2,5h0Z"/>
    <path id="boneparthighlight8" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M166.2,370c1.5.4,2.8,1.3,3.8,2.5l6.2,7.2c2.5,3.1,4,7.7,4.1,14.4-3.4,4.1-6,8.3-7.3,12.4-.4,1.2-1.5,1.9-2.7,1.9-3.5,0-6.6.4-9,2.1-3.4.1-6.2-.7-7.9-3.4-.4-.6-.6-1.3-.6-2-.3-3.3-4.3-6.4-2.7-9.2,5.8-4,6.9-10.5,6.5-17.7.1-6.7,2.9-9.9,9.7-7.9h0Z"/>
    <path id="boneparthighlight7" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M197.7,370.1c.7.7.9,1.7.7,2.6-.7,3-.8,7.4-.8,12.2,0,1.1-.8,2.1-1.9,2.3-3.9.7-7.8,3.1-11.7,6.6-.9.8-2.3,1-3.4.3-2-1.3-3.6-4.1-4.4-9-.7-6.9.9-12.9,8.1-17.2,5.4-2.6,9.7-1.6,13.3,2.1h0Z"/>
    <path id="boneparthighlight6" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M208.6,402.4c3.9,2.4,6.9,6.5,9.1,12.1,1.4,3,2.6,6.1,3.6,9.4.4,1.3.5,2.7.3,4-.4,3-2.2,5.3-5.5,6.6-1.9.8-4,1.1-6,1-8.7-.3-17.3-3.4-25.5-12.1-1.1-1.1-2.2-2.2-3.5-3.1-3.5-2.5-4.7-5.3-2.8-8.4.6-1,.8-2.2.5-3.3-1-2.9,1.3-6.5,6-10.6.5-.4,1-.9,1.4-1.4,3.6-4.3,8.7-5.9,15.2-5.2,2.2,0,3.7,2.3,4.4,6.8.3,1.7,1.3,3.2,2.8,4.2h0Z"/>
    <path id="boneparthighlight5" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M240.5,393.2l-4.4-14.3c-.4-1.3-.5-2.6-.4-4l.6-7.4c1.9-11.1.7-10.3,0-11.6-2.5-2.3-7.1-1.7-14.9,3.7-1.3.9-2.8,1.3-4.3,1.3-5.1-.2-9.1,1.2-10.2,6.5-.2,1-.8,1.9-1.7,2.6-2.6,2.2-4.3,4.8-4.3,8.2,0,2.5,1.5,4.7,3.8,5.8l.6.3c1.9.9,3.3,2.8,3.2,4.9,0,1.2-.4,2.3-1.4,3.2-2.8,2.2-2.5,5.3-1.1,8.7.2,3.5,1.7,6.5,5.5,8.4,7,3.1,15,2.8,23.5.7,3.4-.4,5.3-3,6.1-7.2.6-3.3.3-6.6-.7-9.8h0Z"/>
    <path id="boneparthighlight4" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M269,356c-1.3-1.6-3.4-2.4-5.5-2.1l-25.4,4c-3.6,1.3-5.5,6.8-4.2,20,.2,7.5,1.1,14.5,2.9,20.7.1.4.3.8.5,1.2,2.2,4.7,5.2,6.5,9.2,4,1.1-.7,1.9-1.8,2.4-3,3.6-9.4,9.5-15.1,16.4-19.1,1.1-.6,1.8-1.8,2.1-3,.9-4.7,4.9-8.8,4.9-16.7-.7-2-1.9-4-3.4-5.9h0Z"/>
    <path id="boneparthighlight3" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M276.4,411.1c-1,5.1-3.2,9.9-8.2,13.9-2.5,1.8-5.5,2.4-9,2-2.9-.3-5.5-2.1-6.7-4.8-1.5-3.2-3.3-5.3-5.4-6.3-3.5-3.1-4.3-5.2-2.3-6.1,5.9-2.2,8.6-6.9,10.8-12,2.2-6.8,6.4-9.5,11.7-9.8,5.7.3,9.3,2.8,11.4,7.2,2.3,3.8,2.7,7.4.2,10.5-1.3,1.6-2.1,3.5-2.5,5.5h0Z"/>
    <path id="boneparthighlight2" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M273.5,410.3c0,2-.9,3.9-2.5,5.2-2.8,2.3-5.8,3.1-9.1,2.7-4.7-.5-8.9-3.2-11.5-7.1-2-3.1-3.5-6.9-4.7-11.1,0-3.6.9-5.4,2.4-6.4.9-.6,2.1-.8,3.2-.7,5.5.5,10.8,2.5,15.7,5.6,2.4,1.5,4.3,3.6,5.4,6.2.7,1.7,1,3.5,1,5.5h0Z"/>
    <path id="boneparthighlight1" class="FullHand1338Fill" style= {{
              fill: props.colors.FullHand1338Fill,
              stroke: props.colors.FullHand1338Fill,
            }} d="M224.9,438.3l9.2,1.3c3.8.2,8-1.1,12.5-3.9,4.9-2.4,4.3-5.6.4-9.1-.6-3.1-1.5-5.9-2.7-8.1-1.3-2.4-3.9-3.9-6.7-3.9s-6.2.7-8.9,2.6l-7,.4c-1.7.1-3.1,1.4-3.2,3.1-.5,4.3-.1,8.1,1.6,11.1-.2,4.3.9,7.2,4.8,6.6h0Z"/>
  </g>
  <g id="Highlights">
    <path id="Highlightpart5" class="Fingerkuppe1338Fill" style= {{
              fill: props.colors.Fingerkuppe1338Fill,
              stroke: props.colors.Fingerkuppe1338Fill,
            }} d="M44.5,249.9c-2.1,0-4.7,1.1-7.5,3-1.2.8-2.9.2-3.3-1.2-3.6-12.3-9.2-22.8-16.4-31.7-2.4.1-4.5-2.8-6.3-8.9-1.5-6.9,1.4-7.3,5-6.7,11.7,2.7,16.7,6.8,15.5,12.2l14.9,13.2c5.8.6,8.7,3.4,7.3,9.5-5.3,2-8.3,5.6-9.2,10.7h0Z"/>
    <path id="Highlightpart4" class="Fingerkuppe1338Fill" style= {{
              fill: props.colors.Fingerkuppe1338Fill,
              stroke: props.colors.Fingerkuppe1338Fill,
            }} d="M131.5,88.4l-10.1.4c-1.4,0-2.9-.5-3.8-1.6-1-1.3-.9-2.6-.3-4l2.6-19.1c-3-1.7-4.2-4.5-1.5-9.8.3-3.1,2.2-5.3,5.4-7,4.7-1.4,7.1.6,7.9,5.2,2.2,4.2,3.8,8.2,0,10.6,0,8.2.9,15.3,4.6,18.8,2.7,3.7,2.4,6.3-4.6,6.5h0Z"/>
    <path id="Highlightpart3" class="Fingerkuppe1338Fill" style= {{
              fill: props.colors.Fingerkuppe1338Fill,
              stroke: props.colors.Fingerkuppe1338Fill,
            }} d="M271,69.6c-2.8,1.8-7.4,2.1-12.9,1.6-3.9-.4-5.9-1.2-5.7-2.5-4.8-1.7-4.2-4.1-.9-7,3.8-5,5-11.4,4.7-18.6-3.4-.8-3.6-5-1-12.3.5-2.8,2.8-4.7,8.2-5.2,4.3.2,7.5,2,7.7,8.3.9,5.3.6,9.2-2.8,9.2-.7,10.5.6,17.1,4,19.9,2.1,3.1,2.9,5.7-1.2,6.5h0Z"/>
    <path id="Highlightpart2" class="Fingerkuppe1338Fill" style= {{
              fill: props.colors.Fingerkuppe1338Fill,
              stroke: props.colors.Fingerkuppe1338Fill,
            }} d="M333.2,137.2c-2.5-1.7-5-3-7.8-3.2-5.4-1.4-5.8-4.5-2.2-8.9,3.4-2.7,5.9-6,7.3-10,.4-1.1.3-2.3-.1-3.3l-.4-.8c.8-4.1,2-7.3,3.8-9.4,1.9-2.2,5.1-2.9,7.8-1.8,2.9,1.2,4.2,2.8,3.5,5.1-.3.8-.3,1.8,0,2.6.8,3.7-.3,6.7-4.7,8.5l-2.3,8.5c.8,9.2.2,15.6-4.9,12.7h0Z"/>
    <path id="Highlightpart1" class="Fingerkuppe1338Fill" style= {{
              fill: props.colors.Fingerkuppe1338Fill,
              stroke: props.colors.Fingerkuppe1338Fill,
            }} d="M210.8,32.9c3.4.2,5.2-2.3,4.6-8.9-1-4.8-2.5-8.9-6.1-10.4s-1.6-.5-2.5-.6c-3.7-1-7,.5-9.3,6.9-.3.7-.5,1.3-.7,1.9-1.8,5.6-.8,8.6,1.5,10.4l.7-4.4c-1.9,13-4.1,23.5-7.2,24.1-.7,1.6.2,2.8,2.8,3.6,1.1.3,2.3.5,3.5.6l11.7.7c2.4.3,4.1-.5,5.2-2.3.6-1,.6-2.3-.1-3.3-2.2-3.1-3.3-9.3-3.5-18,0-2.7,0-5.6,0-8.8v8.8c1.6,0,3-.8,3.8-2.4,1-2.1.8-5.2,0-8.9"/>
  </g>
</svg>
  );
}

export default RechteHandDpSitzend1338;
