import { MakeGetObject, MakePostObject } from "../AuthHeader";

const baseUrl = `${window.conf.SERVER_CONFIG.BASE_URL}`;

const buildURL = (url) => {
  return baseUrl + url;
};

export const strArrToInt = (values) => {
  let result = [];
  for (let i = 0; i < values.length; i++) {
    result.push(parseInt(values[i]));
  }
  return result;
};

export async function loadFacilties(keycloak) {
  return fetch(buildURL(`/pmed/facilities`), MakeGetObject(keycloak)).then(
    (response) => response.json()
  );
}

export async function loadPricelist(
  keycloak,
  facIds,
  pageSize,
  pageIndex,
  filter,
  sorting
) {
  let address = "";
  if (pageSize > 0 && pageIndex > 0)
    //${facIds}
    address = `/price_lists/pricelist/?page_size=${pageSize}&page_number=${pageIndex}${filter}${sorting}`;
  else address = `/price_lists/pricelist/${filter}${sorting}`;
  return fetch(buildURL(address), MakeGetObject(keycloak)).then((response) =>
    response.json()
  );
}

export async function loadPrices(keycloak, facId) {
  return fetch(
    buildURL(`/price_lists/prices/${facId}`),
    MakeGetObject(keycloak)
  ).then((response) => response.json());
}

export async function savePrices(keycloak, facIds, data) {
  let filter = {
    or: [
      {
        field: "a_id",
        op: "in",
        value: facIds,
      },
    ],
  };
  return fetch(
    buildURL(
      `/price_lists/pricelist/?search=${encodeURIComponent(
        JSON.stringify(filter)
      )}`
    ),
    MakePostObject(keycloak, data)
  );
}

export async function loadPriceListV2(
  keycloak,
  facId,
  pageSize = undefined,
  pageNr = undefined,
  searchObj = undefined
) {
  return fetch(
    `${baseUrl}/price_lists/pricelist/${facId}${
      pageSize && pageNr ? `?page_size=${pageSize}&page_number=${pageNr}` : ""
    }${searchObj ? `&search=${JSON.stringify(searchObj)}` : ""}`,
    MakeGetObject(keycloak)
  ).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(response.statusText);
    }
  });
}
