import { useKeycloak } from "@react-keycloak/web";
import { useQuery, useInfiniteQuery } from "react-query";

export default function usePmedQuery(
  queryKey,
  queryFn,
  queryFnParamsObj,
  keepPreviousData = false,
  enabled = true
) {
  const { keycloak } = useKeycloak();
  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => queryFn(keycloak, queryFnParamsObj),
    keepPreviousData: keepPreviousData,
    enabled: enabled,
  });
  return query;
}

export function usePmedInfiniteQuery(
  queryKey,
  queryFn,
  queryFnParamsObj,
  arrayKey = null,
  pageSize,
  enabled = true
) {
  const { keycloak } = useKeycloak();
  const query = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => queryFn(keycloak, queryFnParamsObj, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!arrayKey && lastPage["total_count"]) {
          const total = lastPage["total_count"];
          const currentCount = pageSize * pages.length;
          if (currentCount < total) {
            return pages.length + 1;
          } else {
            return;
          }
        } else {
          if (lastPage[arrayKey] && lastPage[arrayKey].length >= pageSize) {
            return pages.length + 1;
          }
          return;
        }
      },
      enabled: enabled,
    }
  );
  return query;
}
