import React from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";

function Welcome() {
  const { keycloak, initialized } = useKeycloak();
  return (
    <Container fluid className="mx-0 px-0 my-0 py-0">
      <Row className="mb-4 justify-content-center">
        <Col xs={12} md={6} xl={6} sm={12} className="">
          <div className="card card-shadow">
            <div className="card-body text-center">
              {!keycloak.authenticated && (
                <div>
                  <h2>
                    You need to be logged in to use PergamonMED Web Solutions
                  </h2>
                  <Button onClick={() => keycloak.login()}>Log In</Button>
                </div>
              )}

              {initialized ? (
                keycloak.authenticated && (
                  <div>
                    <h2 className="text-capitalize">
                      {keycloak.tokenParsed.given_name}{" "}
                      {keycloak.tokenParsed.family_name}
                    </h2>
                    <Button onClick={() => keycloak.logout()}>Log Out</Button>
                  </div>
                )
              ) : (
                <h2>keycloak initializing...</h2>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default Welcome;
