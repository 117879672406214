import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { PeselDecode } from "../../Utilities/Patients/PatientUtils";
import { useState } from "react";

import style from "./PeselPwHorizontal.module.scss";

function PeselPwHorizontal({
  submit = (data) => {
    console.log(data);
  },
}) {
  const [mode, setMode] = useState("Key")

  const onOptionChange = e => {
    setMode(e.target.value)
  }

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => submit(data);
  const onError = (errors) => {
    console.log(errors);
    if (errors.Pesel) {
      errors.Pesel.type === "validate" &&
        NotificationManager.error(`Pesel nie jest poprawny`);
      errors.Pesel.type === "required" &&
        NotificationManager.error(`Pesel is wymagany`);
    }
    if (errors.password) NotificationManager.error(`Hasło jest wymagane`);
  };
  const peselValidation = (value) => {
    const decoded = PeselDecode(value);
    return decoded.valid;
  };

  return (
    <div className={style.container}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <input
          type="radio"
          name="submitType"
          value="Key"
          id="key"
          checked={mode === "Key"}
          onChange={onOptionChange}
        />
        <label htmlFor="key">Klucz</label>
        <input
          type="radio"
          name="submitType"
          value="PatIDPass"
          id="patid"
          checked={mode === "PatIDPass"}
          onChange={onOptionChange}
        />
        <label htmlFor="patid">PESEL i hasło</label>
      {mode === "PatIDPass" && <div>
          <label>Pesel</label>
          <input
            type="text"
            placeholder="Pesel"
            {...register("orderPatID", {
              required: mode === "PatIDPass",
              validate: (value) => peselValidation(value),
            })} />
        </div>}
        {mode === "PatIDPass" && <div>
          <label>Hasło</label>
          <input
            type="password"
            placeholder="Hasło"
            {...register("orderCode", { required: mode === "PatIDPass" })}
          />
        </div>}
        {mode === "Key" && <div>
          <label>Klucz</label>
          <input
            type="text"
            placeholder="Klucz"
            {...register("orderKey", { required: mode === "Key" })}
          />
        </div>}
        <input className={style.button} type="submit" />
      </form>
    </div>
  );
}

export default PeselPwHorizontal;
