import style from "./style.scss";

function LinkesKniegelenkLatLiegendLinks2323(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.4 637.92">
      <g id="underlay">
        <ellipse
          id="retropattial_joint_underlay"
          data-name="retropattial joint underlay"
          class="Retropatellargelenk2324Fill"
          style={{
            fill: props.colors.Retropatellargelenk2323Fill,
            stroke: props.colors.Retropatellargelenk2323Fill,
          }}
          cx="367.29"
          cy="171.77"
          rx="30.75"
          ry="61.05"
          transform="translate(-35.41 124.49) rotate(-18.37)"
        />
      </g>
      <g id="knee_lat" data-name="knee lat">
        <g id="background_layer" data-name="background layer">
          <path
            id="background_knee"
            data-name="background knee"
            class="kniegelenk_lat_links_liegend2323_cls2"
            d="M460.04,618.33c-13.8-63.1-23.4-121.8-14.1-160.9l-.1-30.7c1.4-12.4-3-22.3-13.1-29.7-3.6-12.5-8.2-19.9-14.6-18.9-6.8-1.3-5.2-9.1-1.4-18.6,2.4-8.1.1-15-5.8-20.7l-9.1-19.9c-12.5-15.6-29.3-19.9-49.6-15.3-9.6,5.6-18.6,8.7-25.9,5.9,22.7-20,37.8-40.1,43.7-60.3,7.1-25.2,6.1-50.5-.5-75.7-10.1-24.3-26.4-42.8-47.5-56.8l-70.6-19.7-57.7-25.5L41.64,1.33.64,82.43l57,28c55.8,27.9,106.5,61.5,151,102.1,8.2,9.8,12.4,28.7,11.1,60,1.2,30.9,18.2,47,47.7,51.5l-21,26.3-7.9,13.7v21.8c-.6,27.9,4.2,50.3,15.1,66.4,13,17.9,20.7,46,22.3,86,1.5,33.9-.6,65.3-8.1,93.1l58.3-3.5-17.3-120.8,2.6-26.1,7.8,23.2,31.5,133.4,109.3-19.2h0Z"
          />
          <path
            id="background_partella"
            data-name="background partella"
            class="kniegelenk_lat_links_liegend2323_cls2"
            d="M424.54,223.03c13.2,7.6,15.3-6,14.8-24.8-3.3-22.2-8.8-40.1-16.5-53.5-4.2-9.5-13.8-14.3-26.5-16.7-16.9-6.5-32.3-11.8-37.4-9.2-3.1,6-2.9,12.7,0,19.9l16.4,27.5c4.2,20.4,9.7,34.2,16.5,41.1l32.7,15.7h0Z"
          />
        </g>
        <path
          id="fibula"
          class="kniegelenk_lat_links_liegend2323_cls6"
          d="M267.94,631.33c3.2-11.5,6.6-26.7,8.2-44.7,1.6-17.3.9-30.1,0-47.4-.8-15.9-1.6-29.2-5.5-46.5-2.4-10.6-4.4-19.5-10-30.1s-8-10.3-12.8-20c-4.8-9.8-5.9-17.8-8.2-33.7-3.3-22.7-.5-26.1.9-27.3,1.9-1.7,3.4-1.5,16.4-2.7,18.1-1.7,18.3-2.4,22.8-1.8,11.1,1.4,19,6.8,21,8.2,2.7,1.9,14.2,10.7,17.3,25.5,1.3,6.1.2,8.8-1.8,23.7-.7,5.2-1.7,15-3.6,34.6-2.1,20.9-3.1,31.4-2.7,41,.2,4,1.3,13,3.6,31,1.7,13.1,1.7,12.4,4.6,33.7,2.5,18.3,3,23.1,4.6,32.8,1.4,8.8,2.7,16.1,3.6,21"
        />
        <g id="femur">
          <path
            id="femur_1"
            data-name="femur 1"
            class="kniegelenk_lat_links_liegend2323_cls6"
            d="M.64,82.43c46.7,22,84.4,42,112.1,57.4,1.6.9,26.3,14.8,56.5,37.4,39.4,29.5,46.2,42.7,48.3,53.8,3.4,18.1,2.7,32.8,2.7,32.8-.4,8.8-1.5,12.8,0,20,2.5,12.4,9.8,20.5,11.9,22.8,10,10.7,22.2,13.4,31.9,15.5,16.9,3.7,30.2.8,33.7,0,10.2-2.4,17.3-6.5,21.9-9.1,3.4-2,17.6-10.5,31-28.2,6.5-8.6,26.9-36.5,25.5-76.6-.9-25.3-10.1-43.3-12.8-48.3-3.8-7.1-16.8-30.5-42.8-42.8-2.5-1.2-11.7-3.8-30.1-9.1-28.3-8.1-32.8-8.9-41.9-11.8-19.3-6.1-28.2-11.6-51-22.8-30.6-14.9-32.7-13.8-72.9-31.9C105.04,32.63,76.44,19.33,41.64,1.33"
          />
          <path
            id="femur_2"
            data-name="femur 2"
            class="kniegelenk_lat_links_liegend2323_cls6"
            d="M248.54,96.13c6.2,4.9,19.6,17.9,32.8,22.8,13.6,5,19.6,1.6,33.7,6.4,5.2,1.7,18.6,6.4,30.1,18.2,14.7,15.2,17.7,33.5,19.1,42.8,3.5,22.2-1.9,39.8-5.5,51-5.4,17.1-12.7,28.8-18.2,37.4-7,10.9-25.6,32.4-42.8,47.4"
          />
        </g>
        <g id="patella">
          <path
            id="patella_2"
            data-name="patella 2"
            class="kniegelenk_lat_links_liegend2323_cls6"
            d="M432.64,225.53h0c6.7-2.9,6-18.3,5.5-29.2-.9-18.5-6.3-32.1-9.1-38.3-5.1-11.4-8.1-18-15.5-23.7-6.7-5.2-8.3-2.8-31.9-10.9-13.3-4.6-19.3-7.6-22.8-4.6-3.1,2.7-2.3,8.3-1.8,11.9.9,6.6,3.8,10.2,9.1,19.1,4.9,8.1,7.3,12.2,9.1,16.4,4.6,10.9,2.4,13.6,7.3,25.5,2.8,6.7,4.4,10.6,8.2,14.6,4.9,5,8.1,4.7,21.9,10.9,14.7,6.8,16.7,9.8,20,8.3h0Z"
          />
          <polyline
            id="patella_2-2"
            data-name="patella 2"
            class="kniegelenk_lat_links_liegend2323_cls6"
            points="390.74 206.43 385.24 181.83 380.74 162.63 372.54 145.33 358.84 118.93"
          />
        </g>
        <g id="tibia">
          <path
            id="tibia_1"
            data-name="tibia 1"
            class="kniegelenk_lat_links_liegend2323_cls6"
            d="M350.64,637.53c-6.3-23.5-10.8-42.6-13.7-55.6-3.9-17.7-6.6-31.7-12.8-57.4-6.5-27.5-8.7-33.7-10.9-39.2-8.6-21.2-19.1-35.3-22.8-40.1-3.3-4.3-9.5-12.2-19.1-21-11.6-10.5-15.5-10.8-21.9-19.1-3.1-4-9.9-13.2-10.9-26.4-.5-5.9-2-20.5,6.4-27.3,6.7-5.4,14.5-1.8,19.1-8.2,2.9-4,.6-6.3,2.7-11.9,3.3-8.5,12.1-12.3,23.7-17.3,7.6-3.3,14.4-6.2,23.7-6.4,10.7-.2,11.7,3.6,21,2.7,10.7-1,11.2-6.1,22.8-8.2,12.8-2.4,23.1,1.7,25.5,2.7,3.8,1.6,13.8,5.8,19.1,15.5,2.6,4.7,1.8,6.5,5.5,13.7,4.5,8.7,6.4,8,8.2,12.8,4.8,12.6-6.7,23.9-1.4,29.8,2.5,2.8,5.2.5,9.6,3.9,5,3.9,3,8,8.2,15.5,3.9,5.6,6,4.6,9.1,10.3,2.6,4.6,3.3,9,3.6,11.6.9,6,.3,10.7,0,12.8-1.4,11.2-.6,22.5-1.8,33.7-.5,4.1-1.6,14.6-1.8,26.4,0,2.7-.2,13,1.8,32.8,1.1,10.8,2.6,22.2,5.5,39.2,2.4,14.4,6,33.3,10.9,55.6"
          />
          <path
            id="tibia_2"
            data-name="tibia 2"
            class="kniegelenk_lat_links_liegend2323_cls5"
            d="M376.24,310.33c0,13.6-35.5,24.6-79.4,24.6"
          />
          <path
            id="tibia_3"
            data-name="tibia 3"
            class="kniegelenk_lat_links_liegend2323_cls5"
            d="M409.04,380.53c-15.6,29.5,5.1,98.4,23.7,109.4"
          />
          <path
            id="tibia_4"
            data-name="tibia 4"
            class="kniegelenk_lat_links_liegend2323_cls5"
            d="M428.14,475.23c0-22.1-3.3-40.1-7.3-40.1"
          />
          <path
            id="tibia_5"
            data-name="tibia 5"
            class="kniegelenk_lat_links_liegend2323_cls5"
            d="M420.84,435.13c0-19.6-3.3-35.5-7.3-35.5"
          />
          <polyline
            id="tibia_6"
            data-name="tibia 6"
            class="kniegelenk_lat_links_liegend2323_cls6"
            points="308.84 475.23 301.44 450.63 286.84 424.23 267.44 410.13 252.24 398.73 243.14 380.53 238.64 364.03 244.94 351.33 267.44 324.03"
          />
          <path
            id="tibia_7"
            data-name="tibia 7"
            class="kniegelenk_lat_links_liegend2323_cls6"
            d="M432.64,396.03c0,15.6,5.9,28.3,13.2,28.3"
          />
        </g>
      </g>
      <g id="overlay">
        <path
          id="patella_overlay"
          data-name="patella overlay"
          class="patella2324Fill"
          style={{
            fill: props.colors.patella2323Fill,
            stroke: props.colors.patella2323Fill,
          }}
          d="M432.64,225.53h0c6.7-2.9,6-18.3,5.5-29.2-.9-18.5-6.3-32.1-9.1-38.3-5.1-11.4-8.1-18-15.5-23.7-6.7-5.2-8.3-2.8-31.9-10.9-13.3-4.6-19.3-7.6-22.8-4.6-3.1,2.7-2.3,8.3-1.8,11.9.9,6.6,3.8,10.2,9.1,19.1,4.9,8.1,7.3,12.2,9.1,16.4,4.6,10.9,2.4,13.6,7.3,25.5,2.8,6.7,4.4,10.6,8.2,14.6,4.9,5,8.1,4.7,21.9,10.9,14.7,6.8,16.7,9.8,20,8.3h0Z"
        />
        <path
          id="femurcondylen_overlay"
          data-name="femurcondylen overlay"
          class="Femurcondylen2324Fill"
          style={{
            fill: props.colors.Femurcondylen2323Fill,
            stroke: props.colors.Femurcondylen2323Fill,
          }}
          d="M218.71,238.22c28.6-1.96,56.63-3.49,78.13,0,27.24,8.27,44.45,25.01,47.29,53.89-17.64,22.11-41.83,35.46-80.09,30.02-20.02-.35-35.92-10.97-43.64-41.96.41-15-.2-28.93-1.69-41.96Z"
        />
      </g>
    </svg>
  );
}

export default LinkesKniegelenkLatLiegendLinks2323;
