import style from "./style.scss";

function LinkerVorfussDplOblLiegend1429(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216.99 493.87">
      <g id="fore_foot" data-name="fore foot">
        <g id="background_layer" data-name="background layer">
          <path
            id="background_1"
            data-name="background 1"
            class="vorfuss_dp_obl_links1429_cls_3"
            d="M66.74,389.53c-14.4,5-16.3-4.7-11.6-22.1,4-7.2,3-13.4-.4-19.1,6.5-72,1.7-109.7-9.8-126.8-4-4.9-4.4-10.1-2.2-15.4-2.2-9.2-.3-14.8,6.8-15.8,11.9-6.1,17.1-.5,16.5,15.4,6.2,3.3,5.6,8.9.8,16.1l12.8,101.2c6.2,9.1,9,19.5,8.2,31.1,7.9,19.7,9.3,31.7-2.6,28.1l-18.5,7.3h0Z"
          />
          <path
            id="background_2"
            data-name="background 2"
            class="vorfuss_dp_obl_links1429_cls_3"
            d="M95.94,372.32c-16.1,3.3-17.1-10.2-11.2-31.1,9.7-39.4,9.2-81.1.4-124.5-2.8-4.6-4.5-9-5.2-13.1-4.9-5.8-7-11.8-2.2-18-4.2-12.2-3.2-20.3,10.5-18.4,10,.6,13.5,3.6,9,10.1,5.9,7.3,7.7,14.6,3.4,22.1,3.1,47.9,7.2,93,14.6,127.9,6.2,7.2,9.4,15.3,7.9,24.8-1.9,10.4-8.8,18.2-27.2,20.2.1,0,0,0,0,0Z"
          />
          <path
            id="background_3"
            data-name="background 3"
            class="vorfuss_dp_obl_links1429_cls_3"
            d="M138.64,464.63c-8,8-15.2,6.6-21.4-6.8-1.9-7.2,3.2-13.5,12.9-19.1,7.5-12,18.8-21.5,42.8-22.5,17.1-4,30.5-2.4,34.3,14.6,7.4,22.7,8.5,40.9,1.7,53.4-6.9,13.2-12.9,10.6-18.6,1.7-5.6-29-23.9-34.1-51.7-21.3Z"
          />
          <path
            id="background_4"
            data-name="background 4"
            class="vorfuss_dp_obl_links1429_cls_3"
            d="M189.84,428.63c-22.3,3.9-30.8-7.3-33.2-25.3-6.1-23.3-5.7-38.9,3.4-44.4,7.1-3.5,10.6-10.2,11.2-19.7,15.3-9,27.6-7.3,34.9,12.9,3.7,9.1,3.7,16.9.6,23.6,6.2,33.1,2.9,53.8-16.9,52.9Z"
          />
          <path
            id="background_5"
            data-name="background 5"
            class="vorfuss_dp_obl_links1429_cls_3"
            d="M174.64,353.63c-15.1.6-23.2-2.9-22.1-12-6.4-16.5-2.4-31.8,5.6-46.5,11.1-30.6,13.1-57.9,4.9-81.4-6.8-14-4.7-26.4,2.2-37.9-.6-4,1.1-7.2,6-9,15.6-4,28.2-1.6,37.5,7.5,5.6,4.1,8,8.9,6.8,14.2,3.4,12,.7,23.7-7.1,35.2-9.9,28.1-9.9,61-.4,98.6,6.8,8.9,1.5,18.8-10.9,29.2l-22.5,2.1h0Z"
          />
          <path
            id="background_6"
            data-name="background 6"
            class="vorfuss_dp_obl_links1429_cls_3"
            d="M147.24,411.53l22.3-4.1c10.6-2.9,12.7-9.1,8.1-18-4.6-7.8-8.1-16.7-10.7-26.4-4.4-8.7-13.1-7.5-23.6-2.1-14.2,4.7-16.9,11.1-10.7,18.8-2,5.5-1.4,10.2,4.1,13.5l4,14.3c.8,2.8,3.6,4.5,6.5,4h0Z"
          />
        </g>
        <g id="tarsus">
          <path
            id="tarsus_1"
            data-name="tarsus 1"
            class="vorfuss_dp_obl_links1429_cls_5"
            d="M111.74,472.42c17.9,4.7,18.2-4.5,9.3-21.1-1.7-3.2-4.2-6-7.2-8-18.5-12.6-26.9-27.2-26.8-43.5,2.8-9.7-2.3-10.2-11.2-6.2-19.4,8-33.2,18.2-40.5,30.9-6.4,4.6-10.3,9-2.2,11.8,6.6,3.4,9.7,11.7,9,25.3.8,12,8.6,19.1,27,18.6,11.7,5.4,21.8,5.5,29.8-1.1l12.8-6.7h0Z"
          />
          <path
            id="tarsus_2"
            data-name="tarsus 2"
            class="vorfuss_dp_obl_links1429_cls_4"
            d="M147.24,411.53l22.3-4.1c10.6-2.9,12.7-9.1,8.1-18-4.6-7.8-8.1-16.7-10.7-26.4-4.4-8.7-13.1-7.5-23.6-2.1-14.2,4.7-16.9,11.1-10.7,18.8-2,5.5-1.4,10.2,4.1,13.5l4,14.3c.8,2.8,3.6,4.5,6.5,4h0Z"
          />
          <path
            id="tarsus_3"
            data-name="tarsus 3"
            class="vorfuss_dp_obl_links1429_cls_5"
            d="M116.24,437.63c7.1,1.8,15.4-4.7,24.5-15.3.9-1.1,1.3-2.6.9-4-1.1-3.9-3.9-4.8-6.4-4.9,2.6-11.2-3.8-21.2-11.8-30.9.9-16.6-5.5-24.6-21.9-20.8-13.9,6.2-17.2,15.1-10.1,26.4l13.5,28.1c-3.7,9.9-.1,17.1,11.3,21.4h0Z"
          />
          <path
            id="tarsus_4"
            data-name="tarsus 4"
            class="vorfuss_dp_obl_links1429_cls_4"
            d="M137.64,464.63c-8,8-15.2,6.6-21.4-6.8-1.9-7.2,3.2-13.5,12.9-19.1,7.5-12,18.8-21.5,42.8-22.5,17.1-4,30.5-2.4,34.3,14.6,7.4,22.7,8.5,40.9,1.7,53.4-6.9,13.2-12.9,10.6-18.6,1.7-5.6-29-23.9-34.1-51.7-21.3Z"
          />
          <path
            id="tarsus_5"
            data-name="tarsus 5"
            class="vorfuss_dp_obl_links1429_cls_4"
            d="M188.84,428.63c-22.3,3.9-30.8-7.3-33.2-25.3-6.1-23.3-5.7-38.9,3.4-44.4,7.1-3.5,10.6-10.2,11.2-19.7,15.3-9,27.6-7.3,34.9,12.9,3.7,9.1,3.7,16.9.6,23.6,6.2,33.1,2.9,53.8-16.9,52.9Z"
          />
        </g>
        <g id="metatarsus">
          <path
            id="metatarsus_1"
            data-name="metatarsus 1"
            class="vorfuss_dp_obl_links1429_cls_5"
            d="M28.14,421.42c-8,13.2-15.6,13.1-22.7-2.2-2.4-5.2-2.2-11.2.3-16.3,21.5-43.5,15.8-95.8,2.1-150.6-6.2-4.9-8.5-10.9-6.8-18,2.3-13.2,7.1-21.4,17.2-18.8,4.4-1.9,8.4-.2,12,7.1-2.9,5.1-3.2,9.9,2.2,13.9,6.8,28.6,10.4,57.6,9.4,87-1.1,18.1,2,34.2,14.2,45.8,7.7,6.4,10.1,14.5,6.4,24.6-.8,2.2-2.6,3.8-4.8,4.5-11.9,4.3-21.5,12.3-29.5,23h0Z"
          />
          <path
            id="metatarsus_2"
            data-name="metatarsus 2"
            class="vorfuss_dp_obl_links1429_cls_4"
            d="M66.74,389.92c-14.4,5-16.3-4.7-11.6-22.1,4-7.2,3-13.4-.4-19.1,6.5-72,1.7-109.7-9.8-126.8-4-4.9-4.4-10.1-2.2-15.4-2.2-9.2-.3-14.8,6.8-15.8,11.9-6.1,17.1-.5,16.5,15.4,6.2,3.3,5.6,8.9.8,16.1l12.8,101.2c6.2,9.1,9,19.5,8.2,31.1,7.9,19.7,9.3,31.7-2.6,28.1l-18.5,7.3h0Z"
          />
          <path
            id="metatarsus_3"
            data-name="metatarsus 3"
            class="vorfuss_dp_obl_links1429_cls_4"
            d="M96.04,372.72c-16.1,3.3-17.1-10.2-11.2-31.1,9.7-39.4,9.2-81.1.4-124.5-2.8-4.6-4.5-9-5.2-13.1-4.9-5.8-7-11.8-2.2-18-4.2-12.2-3.2-20.3,10.5-18.4,10,.6,13.5,3.6,9,10.1,5.9,7.3,7.7,14.6,3.4,22.1,3.1,47.9,7.2,93,14.6,127.9,6.2,7.2,9.4,15.3,7.9,24.8-2,10.4-8.8,18.3-27.2,20.2h0Z"
          />
          <path
            id="metatarsus_4"
            data-name="metatarsus 4"
            class="vorfuss_dp_obl_links1429_cls_5"
            d="M138.04,356.92c-10.6,9.3-16.4,8.8-16.5-3,8.4-57,8.8-110.6-4.9-157.9-9.4-4.1-10.3-9.9-4.1-17.2-5.3-14.4-4.6-23.1,9.8-19.1,12.4-1.2,19.7,1.2,15.7,12,4.7,9.6,4.2,19,.4,28.1.4,11.9,1.5,24.4,3.4,37.5,2,28.3,7.6,55.8,16.5,82.5,9.1,12.5,13.6,24.1,6.4,33.4-8.2-.7-17.1.6-26.7,3.7h0Z"
          />
          <path
            id="metatarsus_5"
            data-name="metatarsus 5"
            class="vorfuss_dp_obl_links1429_cls_4"
            d="M173.64,353.63c-15.1.6-23.2-2.9-22.1-12-6.4-16.5-2.4-31.8,5.6-46.5,11.1-30.6,13.1-57.9,4.9-81.4-6.8-14-4.7-26.4,2.2-37.9-.6-4,1.1-7.2,6-9,15.6-4,28.2-1.6,37.5,7.5,5.6,4.1,8,8.9,6.8,14.2,3.4,12,.7,23.7-7.1,35.2-9.9,28.1-9.9,61-.4,98.6,6.8,8.9,1.5,18.8-10.9,29.2l-22.5,2.1h0Z"
          />
          <path
            id="metatarsus_6"
            data-name="metatarsus 6"
            class="vorfuss_dp_obl_links1429_cls_5"
            d="M147.74,209.22c-6.6-1.7-6.8-8.4-2.2-18.8,5.9-8.2,12-11.2,18.4-6,4.2,5.6,3.8,12.7,0,21-4.6,9.2-9.9,11.4-16.2,3.8h0Z"
          />
        </g>
        <g id="toes">
          <g id="big_toe" data-name="big toe">
            <path
              id="big_toe_1"
              data-name="big toe 1"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M170.24,161.92c-13.6,4-11.1-6.1-7.9-16.9,10.9-15.9,14.6-33.6,5.6-54.4-8.1-16.1-5.6-24.7,9.8-24.4,7.7,3.4,15.7,3.9,24,2.2,8.1-2.5,8.2,6,5.2,18.8-3,20.2-2.9,39.9,3.8,58.5,6.7,23.6.6,26.1-12,18-11.7-3.7-21.2-4.4-28.5-1.8h0Z"
            />
            <path
              id="big_toe_2"
              data-name="big toe 2"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M171.34,60.72c-16.8,4.2-18-2.3-10.9-14.6,6.4-7.5,7-15,1.9-22.5-4.9-8.7-3.7-15.2,2-20,3-2.5,7-3.5,10.9-3,7.9,1.1,11.5,5.2,10.7,12.5,1.3,7.1,3.3,14.8,10.9,26.6,8-2.9,10,1.5,8.2,10.5.3,9.8-5.4,14.3-15.4,15l-18.3-4.5h0Z"
            />
          </g>
          <g id="second_toe" data-name="second toe">
            <path
              id="second_toe_1"
              data-name="second toe 1"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M115.84,152.92c-11.3,1.8-9.3-6-3-16.9,10.8-9.6,14-25.2,10.5-46.1-2.6-9.7-.7-13.5,6.4-10.5,3.4,1.9,7,2.1,10.9,0,5-5.9,7.1-2.2,7.1,8.2-8,16.9-11,34.3-7.5,52.5,5.5,19.2,1,21.2-8.2,15-6.1-4.1-11.5-4.9-16.2-2.2h0Z"
            />
            <path
              id="second_toe_2"
              data-name="second toe 2"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M126.04,74.22c-10.2,4.8-9.6-1.5-7.1-9.8,2.7-5.7,2.6-11.2-.8-16.5-2.3-6.1-1.5-10.8,7.1-11.6,10.8,0,17.5,1.7,12.4,7.9.6,8.3,2.2,16,6,22.1,3.2,8.1-.7,10.6-9.8,9,.1,0-7.8-1.1-7.8-1.1Z"
            />
            <path
              id="second_toe_3"
              data-name="second toe 3"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M117.74,32.22c-4.5,1.4-7.8,1.3-4.1-7.1-3.8-4.1-5.8-8.2-2.2-12.3,2.4-2.8,6-4.1,9.7-3.9l6.4.4h1.4c7.1-.4,6.1,4,2.7,9.8,9.4,8.5,12,14,1.1,13.5l-15-.4h0Z"
            />
          </g>
          <g id="third_toe" data-name="third toe">
            <path
              id="third_toe_1"
              data-name="third toe 1"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M82.54,162.72c-11.9,4.6-12-2.1-4.9-15.8,10.4-14.3,12.4-27.2,7.5-39-4.9-9.5-4.7-16.2,7.1-16.5,9.3-3,17.8-4.7,13.9,10.5-6.3,22.3-9.6,41.3-1.9,48.8,2.5,13.9.3,19-7.5,13.5-5.8-3.4-10.3-3.4-14.2-1.5h0Z"
            />
            <path
              id="third_toe_2"
              data-name="third toe 2"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M88.14,89.92c-9.4,2.7-10.4-2.9-7.9-12-4.9-11.2-4.4-20,8.6-23.2,9.4-.3,14.3,2.1,11.6,9.4,0,5.1.9,10.7,3,16.9,2.3,7.1-.9,8.6-7.1,7.1l-8.2,1.8h0Z"
            />
            <path
              id="third_toe_3"
              data-name="third toe 3"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M75.34,41.92c-5.7-6.7-5.7-11.1,6.8-10.5,5.4-5.3,7.8.5,9.4,9.4,6.5,5.5,12.3,10.8,1.9,10.5l-15.8,3.8c-7.6,1.7-6.2-4.6-2.3-13.2h0Z"
            />
          </g>
          <g id="fourth_toe" data-name="fourth toe">
            <path
              id="fourth_toe_1"
              data-name="fourth toe 1"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M48.04,185.22c-9.3,2.5-10.9-2.2-6.8-12.4,14-16.8,17.5-32.3,10.9-46.5-4-8.5-1.3-11,7.5-7.9,14.6-8.4,17.7-4.6,15,5.2l-3.8,13.1c-4.9,9.9-4.8,21.6-3.4,33.8,4.9,8.1,4.9,14.3.8,18.8-6.4-9.7-13.2-10.2-20.2-4.1h0Z"
            />
            <path
              id="fourth_toe_2"
              data-name="fourth toe 2"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M52.54,112.82c-7.8,1.8-6.9-2.1-3.4-7.5-2.2-5.6-.7-9.6,6.8-11.2,10.8-1.7,15.1.7,13.9,6.8,3.6,8.4,1.6,13-6.4,13.5l-10.9-1.6h0Z"
            />
            <path
              id="fourth_toe_3"
              data-name="fourth toe 3"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M47.14,77.52c2.3-6.8,5.4-9.2,9.5-5.3,1.5,1.4,2.2,3.4,2.3,5.4.2,2,1.8,3.4,4.8,4.6s4.6,4.4,3.2,7.2-4.7,3.2-8.6,2.6c-14.2,2.1-20.6,0-15.1-8.6,1.7-1.1,3.6-1.9,3.9-5.9h0Z"
            />
          </g>
          <g id="fifth_toe" data-name="fifth toe">
            <path
              id="fifth_toe_1"
              data-name="fifth toe 1"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M9.34,213.32c-8.5-5-7.2-12.5,2.2-22.1,5.8-9.3,8-20.2,6.8-32.6-4.2-9,.1-11.7,9.4-10.5,7.9-3.3,15.8-6.8,12,3.8-5.8,13.8-9.9,28.7-5.2,48.8.9,17.4-3.2,18.5-9.8,12-5.5-2.2-10.7-2.4-15.4.6h0Z"
            />
            <path
              id="fifth_toe_2"
              data-name="fifth toe 2"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M20.64,144.72c-6-.2-7-2.6-3.4-7.1-.3-5.3,2.2-8.1,7.1-8.6l10.1,3c5.2,1,5.1,3.2,2.6,6,1.3,4.3-.8,6.3-6,6.4l-10.4.3h0Z"
            />
            <path
              id="fifth_toe_3"
              data-name="fifth toe 3"
              class="vorfuss_dp_obl_links1429_cls_5"
              d="M19.54,114.32c2.4-6.2,5.2-8.3,8.7-4.4,1.2,1.4,1.7,3.3,1.7,5.2,0,1.9,1.4,3.3,4,4.6s3.8,4.4,2.3,6.9-4.2,2.7-7.7,1.9c-12.5,1.1-18-1.3-12.8-9,1.6-.8,3.3-1.5,3.8-5.2h0Z"
            />
          </g>
        </g>
      </g>
      <g id="overlay">
        <g
          id="Phalangen_overlay"
          data-name="Phalangen overlay"
          class="vorfuss_dp_obl_links1429_cls_1"
        >
          <g id="Phalangen_overlay_1" data-name="Phalangen overlay 1">
            <path
              id="Phalangen_overlay_1.1"
              data-name="Phalangen overlay 1.1"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M170.25,162.44c-13.6,4-11.1-6.1-7.9-16.9,10.9-15.9,14.6-33.6,5.6-54.4-8.1-16.1-5.6-24.7,9.8-24.4,7.7,3.4,15.7,3.9,24,2.2,8.1-2.5,8.2,6,5.2,18.8-3,20.2-2.9,39.9,3.8,58.5,6.7,23.6.6,26.1-12,18-11.7-3.7-21.2-4.4-28.5-1.8h0Z"
            />
            <path
              id="Phalangen_overlay_1.2"
              data-name="Phalangen overlay 1.2"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M171.35,61.24c-16.8,4.2-18-2.3-10.9-14.6,6.4-7.5,7-15,1.9-22.5-4.9-8.7-3.7-15.2,2-20,3-2.5,7-3.5,10.9-3,7.9,1.1,11.5,5.2,10.7,12.5,1.3,7.1,3.3,14.8,10.9,26.6,8-2.9,10,1.5,8.2,10.5.3,9.8-5.4,14.3-15.4,15l-18.3-4.5h0Z"
            />
          </g>
          <g id="Phalangen_overlay_2" data-name="Phalangen overlay 2">
            <path
              id="Phalangen_overlay_2.1"
              data-name="Phalangen overlay 2.1"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M115.85,153.44c-11.3,1.8-9.3-6-3-16.9,10.8-9.6,14-25.2,10.5-46.1-2.6-9.7-.7-13.5,6.4-10.5,3.4,1.9,7,2.1,10.9,0,5-5.9,7.1-2.2,7.1,8.2-8,16.9-11,34.3-7.5,52.5,5.5,19.2,1,21.2-8.2,15-6.1-4.1-11.5-4.9-16.2-2.2h0Z"
            />
            <path
              id="Phalangen_overlay_2.2"
              data-name="Phalangen overlay 2.2"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M126.05,74.74c-10.2,4.8-9.6-1.5-7.1-9.8,2.7-5.7,2.6-11.2-.8-16.5-2.3-6.1-1.5-10.8,7.1-11.6,10.8,0,17.5,1.7,12.4,7.9.6,8.3,2.2,16,6,22.1,3.2,8.1-.7,10.6-9.8,9,.1,0-7.8-1.1-7.8-1.1Z"
            />
            <path
              id="Phalangen_overlay_2.3"
              data-name="Phalangen overlay 2.3"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M117.75,32.74c-4.5,1.4-7.8,1.3-4.1-7.1-3.8-4.1-5.8-8.2-2.2-12.3,2.4-2.8,6-4.1,9.7-3.9l6.4.4h1.4c7.1-.4,6.1,4,2.7,9.8,9.4,8.5,12,14,1.1,13.5l-15-.4h0Z"
            />
          </g>
          <g id="Phalangen_overlay_3" data-name="Phalangen overlay 3">
            <path
              id="Phalangen_overlay_3.1"
              data-name="Phalangen overlay 3.1"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M82.55,163.24c-11.9,4.6-12-2.1-4.9-15.8,10.4-14.3,12.4-27.2,7.5-39-4.9-9.5-4.7-16.2,7.1-16.5,9.3-3,17.8-4.7,13.9,10.5-6.3,22.3-9.6,41.3-1.9,48.8,2.5,13.9.3,19-7.5,13.5-5.8-3.4-10.3-3.4-14.2-1.5h0Z"
            />
            <path
              id="Phalangen_overlay_3.2"
              data-name="Phalangen overlay 3.2"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M88.15,90.44c-9.4,2.7-10.4-2.9-7.9-12-4.9-11.2-4.4-20,8.6-23.2,9.4-.3,14.3,2.1,11.6,9.4,0,5.1.9,10.7,3,16.9,2.3,7.1-.9,8.6-7.1,7.1l-8.2,1.8h0Z"
            />
            <path
              id="Phalangen_overlay_3.3"
              data-name="Phalangen overlay 3.3"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M75.35,42.44c-5.7-6.7-5.7-11.1,6.8-10.5,5.4-5.3,7.8.5,9.4,9.4,6.5,5.5,12.3,10.8,1.9,10.5l-15.8,3.8c-7.6,1.7-6.2-4.6-2.3-13.2h0Z"
            />
          </g>
          <g id="Phalangen_overlay_4" data-name="Phalangen overlay 4">
            <path
              id="Phalangen_overlay_4.1"
              data-name="Phalangen overlay 4.1"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M48.05,185.74c-9.3,2.5-10.9-2.2-6.8-12.4,14-16.8,17.5-32.3,10.9-46.5-4-8.5-1.3-11,7.5-7.9,14.6-8.4,17.7-4.6,15,5.2l-3.8,13.1c-4.9,9.9-4.8,21.6-3.4,33.8,4.9,8.1,4.9,14.3.8,18.8-6.4-9.7-13.2-10.2-20.2-4.1h0Z"
            />
            <path
              id="Phalangen_overlay_4.2"
              data-name="Phalangen overlay 4.2"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M52.55,113.34c-7.8,1.8-6.9-2.1-3.4-7.5-2.2-5.6-.7-9.6,6.8-11.2,10.8-1.7,15.1.7,13.9,6.8,3.6,8.4,1.6,13-6.4,13.5l-10.9-1.6h0Z"
            />
            <path
              id="Phalangen_overlay_4.3"
              data-name="Phalangen overlay 4.3"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M47.15,78.04c2.3-6.8,5.4-9.2,9.5-5.3,1.5,1.4,2.2,3.4,2.3,5.4.2,2,1.8,3.4,4.8,4.6s4.6,4.4,3.2,7.2-4.7,3.2-8.6,2.6c-14.2,2.1-20.6,0-15.1-8.6,1.7-1.1,3.6-1.9,3.9-5.9h0Z"
            />
          </g>
          <g id="Phalangen_overlay_5" data-name="Phalangen overlay 5">
            <path
              id="Phalangen_overlay_5.1"
              data-name="Phalangen overlay 5.1"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M9.35,213.84c-8.5-5-7.2-12.5,2.2-22.1,5.8-9.3,8-20.2,6.8-32.6-4.2-9,.1-11.7,9.4-10.5,7.9-3.3,15.8-6.8,12,3.8-5.8,13.8-9.9,28.7-5.2,48.8.9,17.4-3.2,18.5-9.8,12-5.5-2.2-10.7-2.4-15.4.6h0Z"
            />
            <path
              id="Phalangen_overlay_5.2"
              data-name="Phalangen overlay 5.2"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M20.65,145.24c-6-.2-7-2.6-3.4-7.1-.3-5.3,2.2-8.1,7.1-8.6l10.1,3c5.2,1,5.1,3.2,2.6,6,1.3,4.3-.8,6.3-6,6.4l-10.4.3h0Z"
            />
            <path
              id="Phalangen_overlay_5.3"
              data-name="Phalangen overlay 5.3"
              class="phalangen1429Fill"
              style={{
                fill: props.colors.phalangen1429Fill,
                stroke: props.colors.phalangen1429Fill,
                opacity: props.colors.phalangen1429Fill,
              }}
              d="M19.55,114.84c2.4-6.2,5.2-8.3,8.7-4.4,1.2,1.4,1.7,3.3,1.7,5.2,0,1.9,1.4,3.3,4,4.6s3.8,4.4,2.3,6.9-4.2,2.7-7.7,1.9c-12.5,1.1-18-1.3-12.8-9,1.6-.8,3.3-1.5,3.8-5.2h0Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LinkerVorfussDplOblLiegend1429;
