import Controller from "../Common/Controller";

import "./styles/Controller.scss";

import FrontView from "./views/FrontView";
import RightView from "./views/RightView";
import BackView from "./views/BackView";
import LeftView from "./views/LeftView";

function ChestController() {
  const availableViews = [FrontView, RightView, BackView, LeftView];
  return <Controller availableViews={availableViews} />;
}

export default ChestController;
