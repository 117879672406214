import style from "./style.scss";

export default function RechteRippen17VdLiegend1814(props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 481.32 721">

    <g id="BG">
      <path id="bg" class="Rippen_1_to_7_right1814_cls9" d="M438.39.12h0l-5.99,11,1.01,13-9.23-10.76c-7.66-8.16-15.26-11.98-22.78-10.22l-5.86,4.39c-7.8-.99-17.63,2.87-28.49,9.19l-43.29,40.89c-9.07-10.67-18.91-12.52-29.36-7.42l-6.24,2.37-13.56-1.84c-12.21-.99-26.87-6.1-42.43-12.72-19.56-5.86-38.21-8.42-54.85-3.67-8.47,1.7-15.17.56-20.87-2.21-7.48-4.05-16.03-4.24-25.16-2.35l-27.91.53-65.96,16.04-3,21.99-9.9,168.02c6.68-26.21,20.76-43.01,38.46-55.2-2.64,5.54-.89,11.72,3.48,18.28l9.06,18.9,21.02,38.98c9.66,21.14,21.63,68.31,33.91,118.86-1.26,12.46-.82,25.69.87,39.49-6.63,27.28-10.72,53.02-9.78,75.69-19.12,73.06-16.92,146.98-13.74,220.95l4.7,3,5.36-1.32,11.94,1.31,10,3.99,18.8-15.31,43.19-13.72,39,9.98,51.94-14.9,22.03-.32c18.82,1.87,38.84-2.96,59.77-12.8,28.87-18.54,53.14-22.75,74.24-17.1-17.38,2.99-27.4,9.44-25.54,21.47,6.46,18.04,19.05,21.23,38.21,8.52l-9.7-74.45-.02-27.54-.03-56.28,15.31-53.73-18.36-32.99,9-15,4.99-19c4.06-.81,6.91-5.28,8.99-12.01,3.55-12.09,3.56-19.36-2.01-19l-3.01-20c7.3-2.69,11.64-7.69,13.32-14.74,1.6-6.7.31-13.77-3.17-19.72-1.92-7.03-4.48-12.52-8.5-14.48l5.32-4.06c-3.38-8.24-2.28-24.64-1.16-41.06,0-8.38-3.72-14.69-10.97-19.06l1.38-16.97c6.54-3.75,8.09-12.4,5.33-25.28-.93-7.31-3.36-12.46-7.06-15.74l.73-22.95c6.95-6.35,6.1-14.8,2.66-23.95l-7.01-7.99c-2.61-5.22-3.75-10.47-3.48-15.74l8.7-7.26c.49-5.5-1.51-13.34-5.22-22.8l-5.02-10.2,2.99-8c6.31-5.66,7.38-11.82,2.2-18.56-11.9-12.69-16.8-28.94-19.24-46.43h-.01Z"/>
    </g>
    <g id="underlays">
      <path id="underlays11" class="ribs1814Fill" style={{
              fill: props.colors.ribs1814Fill,
              stroke: props.colors.ribs1814Fill,
            }} d="M242.74,535.34h0c-3.15,1.25-4.61-1.44-18-5.99-9.57-3.25-11.88-2.91-16-5.99-4.79-3.57-6.1-7.32-9.01-11.99-6.33-10.15-12.83-12.75-21.01-19.99-7.37-6.52-12.59-15.35-23.02-32.99-15.16-25.64-22.73-38.46-25.03-51.99-2.13-12.49-.09-23.66,3.97-46,3.78-20.76,6.89-37.88,16.97-58.01,5.71-11.41,14.14-22.28,30.98-44.02,14.01-18.08,20.56-24.39,25.98-29.01,7.51-6.4,8.18-5.61,24.99-18.01,20.54-15.15,20.82-17.28,29.99-22.02,3.33-1.73,6.46-3.08,30.99-10.02,22.22-6.28,33.33-9.42,40.99-11.02,6.4-1.34,23.18-4.64,45-6.03,15.02-.96,22.7-1.39,31,.98,8.49,2.43,10.62,5.12,24.01,8.99,11.07,3.2,12.32,2.14,17,4.99,10.94,6.67,14.38,18.77,15.01,20.99,2.13,7.48,1.28,13.38,1.01,15-.48,2.9-2.03,12.28-5.99,13-4.89.89-6.9-12.67-19.01-22.99-7.82-6.66-15.76-8.48-31.01-11.98-12.93-2.97-25.64-4.15-38-3.98-23.66.32-41.34,5.52-52.99,9.03-19.67,5.94-33.38,13.01-46.99,20.03-13.29,6.85-25.89,13.43-40.99,25.02-7.13,5.48-19.17,15.41-31.98,30.02-7.46,8.51-18.3,21.03-27.98,40.02-7.7,15.12-14.41,28.27-14.97,47.01-.49,16.17,3.83,28.78,6.02,35,2.99,8.5,6.13,17.42,14.01,25.99,5.33,5.8,12.22,9.53,26.01,16.99,15.51,8.39,20.2,9.23,32.01,16.98,9.18,6.02,13.77,9.03,16.01,12.99,4.74,8.36.95,14.02-2.98,42-2.52,17.99-2.29,25.14-6.98,27h0Z"/>
      <path id="underlays10" class="ribs1814Fill" style={{
              fill: props.colors.ribs1814Fill,
              stroke: props.colors.ribs1814Fill,
            }} d="M265.67,388.32h0c-6.02,4.26-18.2-3.29-29.01-9.98-5.76-3.57-12.27-7.6-19.01-14.99-7.12-7.81-6.88-11.02-14.01-17.99-6.6-6.45-9.41-6.24-17.01-11.99-5.36-4.05-15.17-12.63-22.01-25.99-7.24-14.14-7.8-27.08-8.02-34-.13-4.19-.39-14.43,2.99-26,2.33-8.02,7.01-18.71,33.97-46.02,10.26-10.39,20.68-20.95,36.98-34.02,11.25-9.02,27.71-22.09,51.98-35.03,23.88-12.73,42.71-18.62,59.99-24.03,30.45-9.53,43.27-9.74,54-8.03,6.62,1.06,15.02,2.39,24,7.99,11.33,7.07,11.15,13.17,23.01,19.99,11.52,6.63,17.18,4.04,21.01,9.99,5.67,8.83,0,25.11-5.99,26-5.59.83-6.91-12.44-22.01-24.99-12.42-10.32-25.69-13.12-30.01-13.98-7.01-1.39-13.66-1.52-35,2.02-27.41,4.55-41.12,6.83-53.99,12.03-6.28,2.53-4.56,2.34-41.99,24.02-31.61,18.31-32.59,18.3-37.99,23.02-14.37,12.56-14.15,18.48-30.98,31.02-6.37,4.75-10.02,6.6-12.99,12.01-6.38,11.64-1.79,25.52.02,31,3.25,9.83,8.84,16.55,20.02,29.99,5.94,7.15,11.34,13.58,20.01,20.99,10.69,9.14,16.04,13.71,25.01,16.99,9.33,3.41,15.4,2.79,17,6.99,1.59,4.18-3.76,6.59-5.99,16-1.78,7.46.85,9.01,0,21-.65,9.25-.98,13.87-3.99,16h.01Z"/>
      <path id="underlays9" class="ribs1814Fill" style={{
              fill: props.colors.ribs1814Fill,
              stroke: props.colors.ribs1814Fill,
            }} d="M314.62,283.3c-4.41,2.03-7.76-.05-27-7.98-19.25-7.93-22.82-8.7-32.01-13.98-8.69-4.99-13.12-7.6-17.01-12.99-3.46-4.8-4.98-9.87-8.01-20-2.98-9.95-2.24-10.81-5.01-19-2.68-7.95-3.72-8.17-7.01-17-3.8-10.22-5.71-15.33-6.01-22-.55-12.05,4.48-21.29,5.99-24,6.13-10.95,14.59-15.14,26.99-23.02,30.48-19.36,25.34-23.25,49.98-37.03,15.04-8.41,12.1-4.24,39.99-18.02,17.74-8.76,24.37-13.14,37.99-16.02,15.8-3.35,26.6-1.64,30-1.02,3.44.63,12.21,2.51,22,7.99,5.69,3.19,10.46,7.46,20.01,15.99,9.99,8.93,11.85,11.81,13.01,13.99,4.32,8.08,5.31,17.96,3.01,19-1.49.68-4.33-2.54-10.01-8.99-4.57-5.18-6.39-8.23-12.01-13.99-1.59-1.63-2.98-2.95-3.54-3.47-8.99-8.29-23.78-10.12-27.47-10.51-14.68-1.55-26.14,1.55-42,6.02-3.66,1.03-18.51,5.31-36.99,14.02-5.44,2.56-18.94,9.16-34.99,20.02-9.2,6.22-14.52,9.82-20.99,16.01-11.05,10.57-19.53,18.68-21.98,31.01-2.23,11.22,2.52,21.47,12.02,41.99,7.25,15.67,12.37,20.36,16.01,22.99,2.43,1.76,7.95,3.84,19,7.99,20.66,7.77,25.83,7.96,28,7.98,7.94.07,11.6-2.03,13,0,2.06,2.99-5.03,8.75-12.99,23.01-7.77,13.91-6.7,18.59-11.99,21.01h.02Z"/>
      <path id="underlays8" class="ribs1814Fill" style={{
              fill: props.colors.ribs1814Fill,
              stroke: props.colors.ribs1814Fill,
            }} d="M342.51,157.31h0c-8.59,1.37-18.5-1.74-25-7.99-2.86-2.74-4.91-7.16-9.01-15.99-4.36-9.4-6.54-14.1-7.01-19-.35-3.69-.02-7.63,5.99-23,5.55-14.22,8.33-21.33,11.98-27.01,2.77-4.3,4.51-6.11,22.99-24.01,19.29-18.69,22.44-21.61,28.99-25.02,6.38-3.32,20.04-10.43,28-5.02,5.3,3.59,2.87,9.19,10.01,16.99,4.87,5.31,10.55,7.7,16.01,9.99,7.83,3.29,9.62,2.21,20,5.99,8.95,3.26,13.46,4.97,15.01,8.99,1.98,5.14-.76,12.65-4.99,14-4.38,1.4-6.99-4.87-18.01-9.99-5.94-2.76-7.56-2.05-18-4.99-14.15-3.99-15.82-6.63-22-7.99-18.58-4.1-45.54,10.4-51.98,32.03-3.06,10.26-.8,19.77,0,23,.93,3.74,4.02,14.11,13.01,22.99,9.06,8.95,16.55,8.92,18.01,15.99.88,4.26-.8,9.31-3.99,12-3.71,3.13-7.25,1.22-16,3.01-8.41,1.72-7.85,4.03-14,5.01l-.02.02h0Z"/>
      <path id="underlays7" class="ribs1814Fill" style={{
              fill: props.colors.ribs1814Fill,
              stroke: props.colors.ribs1814Fill,
            }} d="M200.56,690.6c8.06-3.75,19.37-8.94,32.99-15.02,26.01-11.6,31.47-13.48,36.99-15.02,14.49-4.05,15.09-1.81,38-6.02,6.75-1.24,7.47-1.58,44.99-11.03,20.51-5.16,28.1-7.03,39.99-11.02,12.42-4.17,22.42-8.27,31.99-17.02,10.54-9.64,13.42-18.36,11-11.46-6.44,18.35-.33,38.76,15.13,50.55,10.15,7.75-3.9-2.79-27.1-1.07-15.6,1.15-24.63,7.03-39.99,14.02-17.58,8-31.77,14.46-49.99,17.03-22.93,3.24-29.96-2.81-52,3.03-3.92,1.04-14.11,3.99-24,7.01-8,2.44-14.56,4.56-19,6.01"/>
      <path id="underlays6" class="ribs1814Fill" style={{
              fill: props.colors.ribs1814Fill,
              stroke: props.colors.ribs1814Fill,
            }} d="M441.46,515.48l12.03,66.99c-7.12-5.6-13.02-6.84-17-6.99-6.87-.26-8.61,1.2-20,2.01-10.84.76-12.42-.84-22,.01-7.86.7-13.56,2.38-19,4.01-31.5,9.47-47.22,14.3-54.99,17.03-19.87,6.98-33.64,13.23-52.99,22.03-26.02,11.83-39.33,17.95-53.98,28.03-18.19,12.52-28.85,23.67-31.98,27.02-.26.29-.52.58-.77.88-1.46.79-2.85,1.73-4.16,2.72-3.46,2.61-6.57,5.61-9.52,8.77-6.35,6.82-11.96,14.27-17.66,21.62-2.54,3.27-5.06,6.56-7.84,9.63h0c-.92.04-1.84.08-2.76.13-5.5-7.2-6.89-13.62-2.47-18.37,10.11-10.85,45.28-71.77,54.15-79.38,11.51-9.89,21.23-15.68,31.99-22.02,27.61-16.27,51.16-24.94,56.98-27.03,37.78-13.6,43.96-7.93,70.99-20.04,6.89-3.08,18.76-8.95,34.99-12.02,7.8-1.47,17-3.01,17-3.01,15.33-2.56,17.31-2.47,21-4.01,4.86-2.03,11.82-6.54,17.99-18.01h0Z"/>
      <path id="underlays5" class="ribs1814Fill" style={{
              fill: props.colors.ribs1814Fill,
              stroke: props.colors.ribs1814Fill,
            }} d="M117.57,717.65c.49-12.14,1.78-27.87,4.97-46,5.7-32.31,14.69-56.36,18.96-67.01,11.11-27.73,19.07-47.57,37.96-68.02,18.27-19.78,37.35-29.93,50.98-37.03,28.02-14.6,52.68-19.62,64.99-22.04,21.01-4.12,47.92-6.69,62-8.03,23.53-2.25,30.96-1.99,41-8.02,6.39-3.85,11.94-9.11,20.99-9.01,7.38.08,11.03,3.64,17,1.99,20.77-5.75,14.25-59.19,13.14,46.42,20.86,15.25-3.16-3.44-48.13-4.41-8.59-.18-16.82.3-30,2.02-20.83,2.73-42.01,5.5-67.99,14.04-11.81,3.89-30.87,12.94-68.98,31.04-14.1,6.7-21.21,10.08-23.99,12.01-5.04,3.49-18.95,13.89-42.96,63.02-3.78,7.75-15.1,31.35-26.96,65.02-4.89,13.89-8.52,25.64-10.98,34.01"/>
      <path id="underlays4" class="ribs1814Fill" style={{
              fill: props.colors.ribs1814Fill,
              stroke: props.colors.ribs1814Fill,
            }} d="M462.59,372.46c-4.84.4-19.39-2.79-28.21-.98-9.61,1.97-16.31,4.18-19,5.01-16.04,4.93-29.82,3.69-50,4.03-30.38.5-53.93,4.21-59,5.03-27.55,4.46-47.39,10.89-59.99,15.03-27.99,9.2-37.18,15.42-41.99,19.02-11.69,8.76-18.75,18.03-23.99,25.01-9.25,12.33-13.95,22.72-22.98,43.01-7.15,16.06-13.88,31.36-19.97,53.01-2.64,9.39-6.6,23.69-8.98,43.01-1.97,16.04-1.97,28.43-1.97,46,0,16.02,4.19,45.88,4.09,51.99-.03-.66-.05-1.32-.05-1.98-.13-10.65,1.78-17.96,6.98-38,13-50.12,12.6-48.75,12.97-50.01,9.33-31.93,13.99-47.89,22.96-67.01,7.75-16.52,15.41-28.95,20.98-38.01,9.5-15.45,14.26-23.17,20.98-30.01,5.43-5.52,15.41-14.1,45.99-25.03,8.31-2.97,33.9-11.74,77.99-18.04,30.57-4.37,46.28-6.51,67-4.04,22.05,2.62,39.55,8.94,51.01,13.97"/>
      <path id="underlays3" class="ribs1814Fill" style={{
              fill: props.colors.ribs1814Fill,
              stroke: props.colors.ribs1814Fill,
            }} d="M241.54,628.59c-26.47-8.42-40.54-21.19-48.02-29.97-3.06-3.6-7.05-9.4-15.01-20.99-12.2-17.76-18.3-26.64-21.02-32.99-3.51-8.18-5.01-20.79-8.03-46-2.89-24.17-2.37-31.41-2.02-35,.69-7.16,1.61-9.15,5.98-29,6.66-30.24,4.95-27.48,6.98-33,6.57-17.86,18.06-29.22,28.98-40.02,3.76-3.72,13.46-12.93,27.99-22.02,3.02-1.89,10.24-6.27,22.99-12.01,6.91-3.11,23.61-10.3,48.99-17.03,28.09-7.46,24.36-3.1,67.99-12.04,14.18-2.91,22.49-4.99,34-3.02,6.48,1.11,10.91,2.99,20,2.99s10.65-1.88,18-1.01c8.88,1.05,8.97,4.09,19,5.99,11.02,2.09,13.31-1.12,19,1.99,6.69,3.65,9.11,11.12,11.01,16.99,1.99,6.15,5.04,15.59,0,24-3.21,5.37-8.28,7.91-10.99,9.01-211.04-94.88-362.26,54.2-354.09,360.2"/>
      <path id="underlays2" class="ribs1814Fill" style={{
              fill: props.colors.ribs1814Fill,
              stroke: props.colors.ribs1814Fill,
            }} d="M188.56,659.64c-10.65-9.76-22.83-22.9-34.02-39.98-10.54-16.08-16.24-29.62-18.02-33.99-8.77-21.52-11.38-39.22-13.03-50.99-2.91-20.7-2.44-36.5-2.03-50,.45-14.95.82-26.95,3.98-43,2.7-13.71,6.46-24.81,13.97-47.01,3.96-11.68,5.93-17.52,8.99-25.01,8.68-21.26,17.86-36.85,22.97-45.01,6.01-9.61,11.23-17.95,19.98-28.01,8.07-9.28,21.21-22.4,39.98-34.02,12.06-7.47,23.04-11.66,44.99-20.03,20-7.63,31.4-11.98,47.99-15.03,13.19-2.43,16.41-1.6,31-5.02,11.46-2.68,15.09-4.51,23-4.01,8.11.51,9.84,2.78,20,2.99,9,.2,9.99-1.53,15,0,7.7,2.35,8.37,7.35,15,7.99,5.71.54,6.82-3.02,12-2.01,4.98.96,4.82,4.42,12.01,8.99,7.01,4.46,9.54,2.67,14,6.99,4.14,4.01,5.45,8.92,6.01,11,.79,2.97.8,5.08,0,15-1.27,15.86-1.89,17.79-2.99,18-1.47.28-2.6-2.78-6-7-3.3-4.1-6.54-6.4-13.01-10.99-4.87-3.46-13.93-9.89-26.01-14.99-18.41-7.77-34.71-8.59-44.01-8.98-25.54-1.06-44.3,4.51-72.99,13.04-18.08,5.37-27.89,8.29-40.99,15.02-21.85,11.24-35.69,24.28-47.98,36.03-5.74,5.49-24.04,23.45-42.97,52.02-19.9,30.03-29.85,55.72-37.96,77.02-5.15,13.51-14.86,40.92-21.96,77.01-4.16,21.13-5.87,37.36-6.97,48-3.27,31.73-3.17,55.63-2.95,85,.11,15.61.57,36.77,2.03,62"/>
      <ellipse id="underlays1" class="ribs1814Fill" style={{
              fill: props.colors.ribs1814Fill,
              stroke: props.colors.ribs1814Fill,
            }} cx="141.35" cy="493.48" rx="62.5" ry="21" transform="translate(-367.69 559.63) rotate(-81.42)"/>
      <path id="softpart" class="soft1814Fill" style={{
              fill: props.colors.soft1814Fill,
              stroke: props.colors.soft1814Fill,
            }} d="M86.32,721c-3.5.06-6.9-37.91-8-53-.99-13.64-.97-20.49-2-61-.9-35.4-.93-30.38-1-37-.58-53.7,4.51-65.71,1-104-2.35-25.7-6.18-37.04-9-44-5.94-14.67-11.39-19.71-25-39-24.45-34.65-37.05-52.51-41-77-1.8-11.14.36-10.26,2-44,1.31-26.96.83-45.8,0-78-.84-32.66,3.86-65.86-2-98-.67-3.66-4.47-23.94,5-42,.75-1.43,5.1-9.72,8-9,3.4.84,3.13,13.58,3,20-.2,9.82-1.5,13.14-2,22-.57,10.11.47,17.27,1,22,1.97,17.77.1,32.47,0,77-.15,67.18,4.05,66.67,0,87-1.03,5.18-3.33,15.4-2,29,1.46,14.88,6.53,26.32,11,35,14.3,27.79,24.08,30.62,39,54,22.97,35.99,26.89,71.72,28,83,.63,6.4.32,6.77,0,36-.32,28.4-.78,69.89,0,112,.49,26.3,1.19,38.83,0,62-.37,7.17-2.42,46.94-6,47Z"/>
    </g>
    <g id="Rippen">
      <path id="Rippen41" class="Rippen_1_to_7_right1814_cls6" d="M242.69,534.22h0c-3.15,1.25-4.61-1.44-18-5.99-9.57-3.25-11.88-2.91-16-5.99-4.79-3.57-6.1-7.32-9.01-11.99-6.33-10.15-12.83-12.75-21.01-19.99-7.37-6.52-12.59-15.35-23.02-32.99-15.16-25.64-22.73-38.46-25.03-51.99-2.13-12.49-.09-23.66,3.97-46,3.78-20.76,6.89-37.88,16.97-58.01,5.71-11.41,14.14-22.28,30.98-44.02,14.01-18.08,20.56-24.39,25.98-29.01,7.51-6.4,8.18-5.61,24.99-18.01,20.54-15.15,20.82-17.28,29.99-22.02,3.33-1.73,6.46-3.08,30.99-10.02,22.22-6.28,33.33-9.42,40.99-11.02,6.4-1.34,23.18-4.64,45-6.03,15.02-.96,22.7-1.39,31,.98,8.49,2.43,10.62,5.12,24.01,8.99,11.07,3.2,12.32,2.14,17,4.99,10.94,6.67,14.38,18.77,15.01,20.99,2.13,7.48,1.28,13.38,1.01,15-.48,2.9-2.03,12.28-5.99,13-4.89.89-6.9-12.67-19.01-22.99-7.82-6.66-15.76-8.48-31.01-11.98-12.93-2.97-25.64-4.15-38-3.98-23.66.32-41.34,5.52-52.99,9.03-19.67,5.94-33.38,13.01-46.99,20.03-13.29,6.85-25.89,13.43-40.99,25.02-7.13,5.48-19.17,15.41-31.98,30.02-7.46,8.51-18.3,21.03-27.98,40.02-7.7,15.12-14.41,28.27-14.97,47.01-.49,16.17,3.83,28.78,6.02,35,2.99,8.5,6.13,17.42,14.01,25.99,5.33,5.8,12.22,9.53,26.01,16.99,15.51,8.39,20.2,9.23,32.01,16.98,9.18,6.02,13.77,9.03,16.01,12.99,4.74,8.36.95,14.02-2.98,42-2.52,17.99-2.29,25.14-6.98,27h0Z"/>
      <path id="Rippen40" class="Rippen_1_to_7_right1814_cls6" d="M265.62,387.2h0c-6.02,4.26-18.2-3.29-29.01-9.98-5.76-3.57-12.27-7.6-19.01-14.99-7.12-7.81-6.88-11.02-14.01-17.99-6.6-6.45-9.41-6.24-17.01-11.99-5.36-4.05-15.17-12.63-22.01-25.99-7.24-14.14-7.8-27.08-8.02-34-.13-4.19-.39-14.43,2.99-26,2.33-8.02,7.01-18.71,33.97-46.02,10.26-10.39,20.68-20.95,36.98-34.02,11.25-9.02,27.71-22.09,51.98-35.03,23.88-12.73,42.71-18.62,59.99-24.03,30.45-9.53,43.27-9.74,54-8.03,6.62,1.06,15.02,2.39,24,7.99,11.33,7.07,11.15,13.17,23.01,19.99,11.52,6.63,17.18,4.04,21.01,9.99,5.67,8.83,0,25.11-5.99,26-5.59.83-6.91-12.44-22.01-24.99-12.42-10.32-25.69-13.12-30.01-13.98-7.01-1.39-13.66-1.52-35,2.02-27.41,4.55-41.12,6.83-53.99,12.03-6.28,2.53-4.56,2.34-41.99,24.02-31.61,18.31-32.59,18.3-37.99,23.02-14.37,12.56-14.15,18.48-30.98,31.02-6.37,4.75-10.02,6.6-12.99,12.01-6.38,11.64-1.79,25.52.02,31,3.25,9.83,8.84,16.55,20.02,29.99,5.94,7.15,11.34,13.58,20.01,20.99,10.69,9.14,16.04,13.71,25.01,16.99,9.33,3.41,15.4,2.79,17,6.99,1.59,4.18-3.76,6.59-5.99,16-1.78,7.46.85,9.01,0,21-.65,9.25-.98,13.87-3.99,16h.01Z"/>
      <path id="Rippen39" class="Rippen_1_to_7_right1814_cls6" d="M314.57,282.18h0c-4.41,2.03-7.76-.05-27-7.98-19.25-7.93-22.82-8.7-32.01-13.98-8.69-4.99-13.12-7.6-17.01-12.99-3.46-4.8-4.98-9.87-8.01-20-2.98-9.95-2.24-10.81-5.01-19-2.68-7.95-3.72-8.17-7.01-17-3.8-10.22-5.71-15.33-6.01-22-.55-12.05,4.48-21.29,5.99-24,6.13-10.95,14.59-15.14,26.99-23.02,30.48-19.36,25.34-23.25,49.98-37.03,15.04-8.41,12.1-4.24,39.99-18.02,17.74-8.76,24.37-13.14,37.99-16.02,15.8-3.35,26.6-1.64,30-1.02,3.44.63,12.21,2.51,22,7.99,5.69,3.19,10.46,7.46,20.01,15.99,9.99,8.93,11.85,11.81,13.01,13.99,4.32,8.08,5.31,17.96,3.01,19-1.49.68-4.33-2.54-10.01-8.99-4.57-5.18-6.39-8.23-12.01-13.99-1.59-1.63-2.98-2.95-3.54-3.47-8.99-8.29-23.78-10.12-27.47-10.51-14.68-1.55-26.14,1.55-42,6.02-3.66,1.03-18.51,5.31-36.99,14.02-5.44,2.56-18.94,9.16-34.99,20.02-9.2,6.22-14.52,9.82-20.99,16.01-11.05,10.57-19.53,18.68-21.98,31.01-2.23,11.22,2.52,21.47,12.02,41.99,7.25,15.67,12.37,20.36,16.01,22.99,2.43,1.76,7.95,3.84,19,7.99,20.66,7.77,25.83,7.96,28,7.98,7.94.07,11.6-2.03,13,0,2.06,2.99-5.03,8.75-12.99,23.01-7.77,13.91-6.7,18.59-11.99,21.01h.02s0,0,0,0Z"/>
      <path id="Rippen38" class="Rippen_1_to_7_right1814_cls1" d="M342.46,156.19h0c-8.59,1.37-18.5-1.74-25-7.99-2.86-2.74-4.91-7.16-9.01-15.99-4.36-9.4-6.54-14.1-7.01-19-.35-3.69-.02-7.63,5.99-23,5.55-14.22,8.33-21.33,11.98-27.01,2.77-4.3,4.51-6.11,22.99-24.01,19.29-18.69,22.44-21.61,28.99-25.02,6.38-3.32,20.04-10.43,28-5.02,5.3,3.59,2.87,9.19,10.01,16.99,4.87,5.31,10.55,7.7,16.01,9.99,7.83,3.29,9.62,2.21,20,5.99,8.95,3.26,13.46,4.97,15.01,8.99,1.98,5.14-.76,12.65-4.99,14-4.38,1.4-6.99-4.87-18.01-9.99-5.94-2.76-7.56-2.05-18-4.99-14.15-3.99-15.82-6.63-22-7.99-18.58-4.1-45.54,10.4-51.98,32.03-3.06,10.26-.8,19.77,0,23,.93,3.74,4.02,14.11,13.01,22.99,9.06,8.95,16.55,8.92,18.01,15.99.88,4.26-.8,9.31-3.99,12-3.71,3.13-7.25,1.22-16,3.01-8.41,1.72-7.85,4.03-14,5.01l-.02.02h0Z"/>
      <path id="Rippen37" class="Rippen_1_to_7_right1814_cls3" d="M405.73,613.14c-72.66-36.63-175.63-24.77-217.99,17.12"/>
      <path id="Rippen36" class="Rippen_1_to_7_right1814_cls3" d="M448.76,655.12c-5.4-1.32-13.42-2.7-23-1.99-15.6,1.15-24.63,7.03-39.99,14.02-17.58,8-31.77,14.46-49.99,17.03-22.93,3.24-29.96-2.81-52,3.03-3.92,1.04-14.11,3.99-24,7.01-8,2.44-14.56,4.56-19,6.01"/>
      <path id="Rippen35" class="Rippen_1_to_7_right1814_cls3" d="M435.72,605.11c-1.92,2.61-4.87,6.24-8.99,10.01-9.57,8.75-19.57,12.85-31.99,17.02-11.89,3.99-19.48,5.86-39.99,11.02-37.52,9.45-38.24,9.79-44.99,11.03-22.91,4.21-23.51,1.97-38,6.02-5.52,1.54-10.98,3.42-36.99,15.02-13.62,6.08-24.93,11.27-32.99,15.02"/>
      <path id="Rippen34" class="Rippen_1_to_7_right1814_cls3" d="M451.71,583.11c-7.12-5.6-13.02-6.84-17-6.99-6.87-.26-8.61,1.2-20,2.01-10.84.76-12.42-.84-22,.01-7.86.7-13.56,2.38-19,4.01-31.5,9.47-47.22,14.3-54.99,17.03-19.87,6.98-33.64,13.23-52.99,22.03-26.02,11.83-39.33,17.95-53.98,28.03-18.19,12.52-28.85,23.67-31.98,27.02-10.09,10.8-17.19,21.12-21.98,29.01"/>
      <path id="Rippen33" class="Rippen_1_to_7_right1814_cls3" d="M439.68,516.12c-6.17,11.47-13.13,15.98-17.99,18.01-3.69,1.54-5.67,1.45-21,4.01,0,0-9.2,1.54-17,3.01-16.23,3.07-28.1,8.94-34.99,12.02-27.03,12.11-33.21,6.44-70.99,20.04-5.82,2.09-29.37,10.76-56.98,27.03-10.76,6.34-20.48,12.13-31.99,22.02-8.87,7.61-15.89,15.16-25.99,26.01-7.22,7.76-13.01,14.38-16.99,19.01"/>
      <path id="Rippen32" class="Rippen_1_to_7_right1814_cls1" d="M442.65,500.11c-9.73-2.66-23.84-5.61-41-5.98-8.59-.18-16.82.3-30,2.02-20.83,2.73-42.01,5.5-67.99,14.04-11.81,3.89-30.87,12.94-68.98,31.04-14.1,6.7-21.21,10.08-23.99,12.01-5.04,3.49-18.95,13.89-42.96,63.02-3.78,7.75-15.1,31.35-26.96,65.02-4.89,13.89-8.52,25.64-10.98,34.01"/>
      <path id="Rippen31" class="Rippen_1_to_7_right1814_cls1" d="M448.63,440.11c-2.23,4.65-5.96,10.34-11.99,12.01-5.97,1.65-9.62-1.91-17-1.99-9.05-.1-14.6,5.16-20.99,9.01-10.04,6.03-17.47,5.77-41,8.02-14.08,1.34-40.99,3.91-62,8.03-12.31,2.42-36.97,7.44-64.99,22.04-13.63,7.1-32.71,17.25-50.98,37.03-18.89,20.45-26.85,40.29-37.96,68.02-4.27,10.65-13.26,34.7-18.96,67.01-3.19,18.13-4.48,33.86-4.97,46"/>
      <path id="Rippen30" class="Rippen_1_to_7_right1814_cls1" d="M457.63,425.11c-21.25-8.4-43.25-14.4-65.25-15.4-38,0-74,8-111,17-19,5-37,11-54,22-8,5-14,11-20,19-16,24-29,47-40,72-1,2,0,3-1,5-1,3-3,5-4,8-12,40-24,77-30,118-3,16-1,32,6.1,45.68"/>
      <path id="Rippen29" class="Rippen_1_to_7_right1814_cls1" d="M130.76,681.28c-.51-8.98-4.03-35.31-4.03-50,0-17.57,0-29.96,1.97-46,2.38-19.32,6.34-33.62,8.98-43.01,6.09-21.65,12.82-36.95,19.97-53.01,9.03-20.29,13.73-30.68,22.98-43.01,5.24-6.98,12.3-16.25,23.99-25.01,4.81-3.6,14-9.82,41.99-19.02,12.6-4.14,32.44-10.57,59.99-15.03,5.07-.82,28.62-4.53,59-5.03,20.18-.34,33.96.9,50-4.03,2.69-.83,9.39-3.04,19-5.01,8.82-1.81,23.37,1.38,28.21.98"/>
      <path id="Rippen28" class="Rippen_1_to_7_right1814_cls1" d="M240.76,628.23c-26.47-8.42-40.54-21.19-48.02-29.97-3.06-3.6-7.05-9.4-15.01-20.99-12.2-17.76-18.3-26.64-21.02-32.99-3.51-8.18-5.01-20.79-8.03-46-2.89-24.17-2.37-31.41-2.02-35,.69-7.16,1.61-9.15,5.98-29,6.66-30.24,4.95-27.48,6.98-33,6.57-17.86,18.06-29.22,28.98-40.02,3.76-3.72,13.46-12.93,27.99-22.02,3.02-1.89,10.24-6.27,22.99-12.01,6.91-3.11,23.61-10.3,48.99-17.03,28.09-7.46,24.36-3.1,67.99-12.04,14.18-2.91,22.49-4.99,34-3.02,6.48,1.11,10.91,2.99,20,2.99s10.65-1.88,18-1.01c8.88,1.05,8.97,4.09,19,5.99,11.02,2.09,13.31-1.12,19,1.99,6.69,3.65,9.11,11.12,11.01,16.99,1.99,6.15,5.04,15.59,0,24-3.21,5.37-8.28,7.91-10.99,9.01"/>
      <path id="Rippen27" class="Rippen_1_to_7_right1814_cls1" d="M112.49,715.3c-8.17-306,143.05-455.08,354.1-360.2"/>
      <path id="Rippen26" class="Rippen_1_to_7_right1814_cls1" d="M188.78,661.28c-10.65-9.76-22.83-22.9-34.02-39.98-10.54-16.08-16.24-29.62-18.02-33.99-8.77-21.52-11.38-39.22-13.03-50.99-2.91-20.7-2.44-36.5-2.03-50,.45-14.95.82-26.95,3.98-43,2.7-13.71,6.46-24.81,13.97-47.01,3.96-11.68,5.93-17.52,8.99-25.01,8.68-21.26,17.86-36.85,22.97-45.01,6.01-9.61,11.23-17.95,19.98-28.01,8.07-9.28,21.21-22.4,39.98-34.02,12.06-7.47,23.04-11.66,44.99-20.03,20-7.63,31.4-11.98,47.99-15.03,13.19-2.43,16.41-1.6,31-5.02,11.46-2.68,15.09-4.51,23-4.01,8.11.51,9.84,2.78,20,2.99,9,.2,9.99-1.53,15,0,7.7,2.35,8.37,7.35,15,7.99,5.71.54,6.82-3.02,12-2.01,4.98.96,4.82,4.42,12.01,8.99,7.01,4.46,9.54,2.67,14,6.99,4.14,4.01,5.45,8.92,6.01,11,.79,2.97.8,5.08,0,15-1.27,15.86-1.89,17.79-2.99,18-1.47.28-2.6-2.78-6-7-3.3-4.1-6.54-6.4-13.01-10.99-4.87-3.46-13.93-9.89-26.01-14.99-18.41-7.77-34.71-8.59-44.01-8.98-25.54-1.06-44.3,4.51-72.99,13.04-18.08,5.37-27.89,8.29-40.99,15.02-21.85,11.24-35.69,24.28-47.98,36.03-5.74,5.49-24.04,23.45-42.97,52.02-19.9,30.03-29.85,55.72-37.96,77.02-5.15,13.51-14.86,40.92-21.96,77.01-4.16,21.13-5.87,37.36-6.97,48-3.27,31.73-3.17,55.63-2.95,85,.11,15.61.57,36.77,2.03,62"/>
      <path id="Rippen25" class="Rippen_1_to_7_right1814_cls3" d="M462.51,210.09c-2.9,2.2-6.83,5.74-9.99,11.01-6.43,10.74-5.58,21.86-4.98,30,2.46,33.6,3.69,50.4,3.03,59-1.62,21.14-8.04,36.57-9.98,41.01-1.99,4.54-5.14,10.96-25.98,39.01-23.17,31.19-23.65,29.14-28.98,39.02-3.33,6.18-12.84,24.93-15.97,51.01-1.39,11.59-.92,19.73.02,36,.94,16.31,2,32.84,8.03,53,3.83,12.8,11.42,31.06,14.99,38.06"/>
      <path id="Rippen24" class="Rippen_1_to_7_right1814_cls4" d="M182.45,114.26c4.62-4.22,11.98-10.31,21.99-16.01,11.13-6.34,13.82-5.52,27.99-12.02,7.02-3.21,22.8-10.45,39.99-23.02,8.09-5.92,12.77-10.3,21.99-13.01,5.01-1.47,13.29-3.79,21,0,4.15,2.03,6.77,5.35,12.01,11.99,5.45,6.9,5.67,9.07,9.01,12.99,6.57,7.7,13.62,8.59,20.01,11.99,21.81,11.61,24.79,46.53,25.03,49.99,1.12,16.46-4.22,30.28-7.98,40-6.56,16.99-10.54,16.32-16.98,31.01-3.77,8.61-4.65,13.97-9.96,67.01-1.09,10.92-2.22,22.35-3.98,39-3.39,32.16-4.07,35.91-4.98,40-2.97,13.42-6.98,23.62-14.98,44.01-6.51,16.59-6.87,15.7-12.98,31.01-9.46,23.69-15.96,44.24-19.97,57.01-4.43,14.08-9.87,27.84-13.98,42.01-3.12,10.74-5.19,19.1-12.99,26.01-7.58,6.72-16.44,8.39-19.99,9.01-4.54.79-15.47,2.71-25-2.99-3.88-2.32-4.37-4.01-15.01-19.99-13.02-19.55-12.78-17.76-16.01-23.99-3.55-6.86-4.99-11.25-10.01-17.99-3.4-4.57-9.34-23.23-11.61-25.33"/>
      <path id="Rippen23" class="Rippen_1_to_7_right1814_cls2" d="M131.16,379.22c-2.1-9.68-11.31-42.07-14.58-54.93-2.06-8.1-4.38-16.4-9.02-32.99-7.03-25.16-8.32-28.78-11.02-33.99-3.34-6.45-4.42-6.82-11.01-18.99-4.48-8.27-7.03-12.99-10.01-19.99-3.18-7.48-3.18-8.96-6.01-14-3.79-6.75-5.81-7.72-7.01-12-1.45-5.18-.13-9.65,1.99-16,6.34-19.05,9.98-27.01,9.98-27.01,9.02-19.75,9.56-18.32,14.98-31.01,7.01-16.41,6.08-18.72,10.99-24.01,5.87-6.32,12.57-8.79,23.99-13.01,7.3-2.69,15.21-5.54,26-6.01,10.09-.43,18.69-.81,26,4.99,5.93,4.7,9.69,12.41,10.01,19.99.58,13.47-10.19,14.75-12.98,33.01-1.33,8.67.47,12.55-2.99,21-2.9,7.09-5.78,8.3-6.99,13-1.35,5.25-.33,13.69,12.02,26.99"/>
      <path id="Rippen22" class="Rippen_1_to_7_right1814_cls4" d="M103.41,30.3c8.72.46,15.9.27,21,0,13.02-.72,15.59-2.19,23-1.01,10.02,1.6,9.96,5.05,19,5.99,8.42.88,11.19-1.84,22-3.01,8.47-.92,14.65-.11,23,.99,19.72,2.59,28.21,8.25,47.01,13.97,17.11,5.2,13.9,1.67,49.01,8.97,25.11,5.22,37.67,7.83,49.01,12.97,14.96,6.78,17.89,11.3,36.01,17.98,12.98,4.78,19.97,7.36,30,6.98,7.66-.28,10.97-2.07,17,0,8.77,3,16.53,11.83,15.01,17.99-1.04,4.22-5.57,3.66-7.99,9-2.4,5.29.81,8.58,1.01,17,.16,6.75-1.58,18.09-6.99,20-4.24,1.5-7.76-3.86-19.01-10.99-9.07-5.75-11.32-5.16-23.01-10.99-17-8.48-15.78-11.52-30.01-18.98-3.22-1.69-2.78-1.27-40.01-13.98-9.98-3.4-20.05-6.52-30.01-9.98-8.96-3.12-13.1-4.68-20-5.99-9.87-1.87-11.12-.5-21-1.99-12.3-1.85-21.44-5.63-27-7.98-11.28-4.76-13.1-7.5-22.01-11.99-8.13-4.1-24.2-6.78-56.01-11.97-13.33-2.17-20.35-3.15-30-1.98-10.5,1.27-10.15,3.39-20,5.01-14.9,2.46-27.2-.5-50-5.97-6.5-1.56-11.74-3.03-15-3.99"/>
      <path id="Rippen21" class="Rippen_1_to_7_right1814_cls2" d="M34.42,68.33c5.91.32,14.31,1.24,24,3.99,9.58,2.72,16.67,4.74,23.01,9.99,12.75,10.57,14.33,27.52,15.02,34.99.38,4.09,2.07,26.45-11.97,45.01-6.44,8.51-8.52,6.11-26.99,24.02-8.23,7.98-13.52,13.16-18.99,21.01-7.78,11.17-11.81,21.8-13.98,29.01"/>
      <path id="Rippen20" class="Rippen_1_to_7_right1814_cls2" d="M69.45,106.32c35.08,8.81,47.45,29.3,48.02,29.97"/>
      <path id="Rippen19" class="Rippen_1_to_7_right1814_cls2" d="M69.5,194.32c19.85-.01,35.89-46.67,35.86-104.33"/>
      <path id="Rippen18" class="Rippen_1_to_7_right1814_cls2" d="M156.4,129.28c-14.34,0-25.95-5.8-25.95-12.99"/>
      <path id="Rippen17" class="Rippen_1_to_7_right1814_cls2" d="M173.47,133.26c88.44-.05,160.01,8.41,160.01,18.91"/>
      <path id="Rippen16" class="Rippen_1_to_7_right1814_cls3" d="M253.47,142.72c-30.77-4.73-54.07-31.62-68.16-49.06"/>
      <path id="Rippen15" class="Rippen_1_to_7_right1814_cls2" d="M104.05,118.25c37.67-13.61,59.92-11.77,53.41-1.98"/>
      <path id="Rippen14" class="Rippen_1_to_7_right1814_cls2" d="M104.04,118.26c-8.71-11.55,4.06-28.67,26.69-39.2"/>
      <path id="Rippen12" class="Rippen_1_to_7_right1814_cls3" d="M438.43,49.12c-3.07-2.3-7.4-5.02-13-6.99-6.31-2.21-8.45-1.44-14-3.99-1.49-.68-6.95-3.32-12.01-8.99-4.03-4.52-7.09-7.95-7.01-13,.08-5.46,3.81-11.18,8.99-13.01,6.83-2.42,13.74,2.78,18,5.99,9.23,6.94,12.27,15.66,14.01,14.99.88-.34,1.21-3.02-1.01-13h0c1.99-3.66,3.99-7.33,5.99-11"/>
      <path id="Rippen11" class="Rippen_1_to_7_right1814_cls3" d="M451.73,610.65c-24.37,7.75-41.56-3.58-31.56-33.02"/>
      <path id="Rippen10" class="Rippen_1_to_7_right1814_cls3" d="M461.43,685.1c-26.86,27.02-64.31-23.32-12.67-29.99"/>
      <path id="Rippen9" class="Rippen_1_to_7_right1814_cls3" d="M451.68,526.83c-51,.03-46.04-72.22,15.31-53.73"/>
      <path id="Rippen8" class="Rippen_1_to_7_right1814_cls3" d="M474.56,332.09c-5.73-2.87-10.75-5.58-15-7.99-9.13-5.17-11.61-7.15-16-6.99-7.07.25-11.97,5.78-13,7.01-6.89,8.22-6.95,22.13-.98,27,5.07,4.14,11.14-.99,18,3.99,4.36,3.17,2.8,5.88,8.01,11,6.19,6.07,7.22,7.99,14.01,8.99,8.71,1.28,2.62,17,2.01,19-.7,2.32-3.3,10.91-8.99,12.01-4.95.96-7.14-4.74-15-7.99-7.32-3.03-19.6-3.94-26,3.01-6.95,7.56-4.75,22.04,3.02,30,5.37,5.51,12.21,6.68,15,6.99"/>
      <path id="Rippen7" class="Rippen_1_to_7_right1814_cls3" d="M473.12,262.11c-3.89-6.46-23.97-24.32-30.6-24-.88.05-7.02.43-11,5.01-7.19,8.29-3.48,25.87,6.02,34,6.52,5.58,11.37,2.95,21.01,8.99,7.54,4.72,12.19,11.11,15.01,15.99"/>
      <line id="Rippen6" class="Rippen_1_to_7_right1814_cls3" x1="427.78" y1="282.84" x2="450.67" y2="288.11"/>
      <path id="Rippen5" class="Rippen_1_to_7_right1814_cls5" d="M467.5,197.1c.47-6.61-23.19-18.33-29.01-19.07-4.81-.61-4.74,2.28-7,7.09h0c-.73,17.56,1.01,21,1.01,21,1.7,3.35,5.5,7.47,15.01,9.99"/>
      <path id="Rippen4" class="Rippen_1_to_7_right1814_cls5" d="M461.15,189.14c.74,0,1.32-21.11,1.32-47.19"/>
      <path id="Rippen3" class="Rippen_1_to_7_right1814_cls5" d="M439.14,152.12c-37.62-34.03-27.38-63.35,18.32-23.01"/>
      <path id="Rippen2" class="Rippen_1_to_7_right1814_cls5" d="M452.44,73.11c-3.92-2.77-9.19-6.75-15.01-11.99-16.23-14.6-20.53-24.31-26.01-22.99-6.5,1.56-7.75,16.98-7.99,20-.45,5.58-1.31,14.18,4.01,20,4.76,5.21,10.86,4.25,19,7.99,5.76,2.64,13.3,7.98,20.01,19.99"/>
      <line id="Rippen1" class="Rippen_1_to_7_right1814_cls5" x1="425.41" y1="36.12" x2="417.21" y2="21.13"/>
      <path id="soft" class="Rippen_1_to_7_right1814_cls2" d="M13.32,48c-12,22-1.07,40.51-1,68,.16,61.19-2.13,86.7-1,139,.26,11.97-8.74,42.2,3,67,1.3,2.75,4.4,8.75,8.26,15.35,7.56,12.93,12.21,18.21,23.02,32.99,0,0,27.47,37.54,35.29,61.52.4,1.23.75,2.46.75,2.46s.76,2.63,1.31,5.34c4.55,22.1,1.72,44.66,1.72,44.66-.26,2.08-.15,34.72.06,100,.1,29.88.56,54.73,1.05,81,.34,18.52.71,34.47,1.03,47"/>
    </g>
  </svg>
  );
}
