import { useState } from "react";
import { Modal, Row, Col, Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import {
  BurnCD,
  BurnExamCD,
} from "../../Utilities/ClientServices/ClientServices";
import { NotificationManager } from "react-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import "./stylesheets/BurnCdModal.scss";
import { useDispatch } from "react-redux";
import { clearSelectedExaminations } from "../../Redux/actions";
import SelectedExamsDetails from "../SelectedExamsDetails/SelectedExamsDetails";
import impCdImg from "./Images/cdInsertTip.svg";

export default function DeleteExaminationModal({
  shown,
  headerless = false,
  close,
}) {
  //const [selectedPatient, setSelectedPatient] = useState([]);
  const [inp, setInp] = useState(false);
  const [isAnon, setIsAnon] = useState(false);
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  const send = () => {
    BurnCD(selectedExaminations.map((exam) => exam.id));
  };

  return (
    <Modal
      className="top-modal"
      show={shown}
      onHide={close}
      size={"md"}
      onClick={(e) => e.stopPropagation()}
    >
      {!headerless && (
        <Modal.Header className="py-2 bg-light">
          <h4>Burn CD</h4>
          {inp && (
            <ClipLoader color="#a1a1a1" loading={true} css={true} size={30} />
          )}
        </Modal.Header>
      )}
      <Modal.Body className="text-center ">
        {selectedExaminations.length === 0 ? (
          <Container>
            <h5>Select Examination first</h5>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col xs={12}>
                <SelectedExamsDetails />
              </Col>
            </Row>
            <Row className="text-center">
              <Col xs={12}>
                <h5>Put CD inside a recorder</h5>
              </Col>
              <Col xs={12}>
                <img style={{ maxWidth: "350px" }} src={impCdImg}></img>
              </Col>
            </Row>
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer className="px-0 py-0 bg-light">
        <Button className="mr-auto" onClick={close}>
          Close
        </Button>
        {/* TODO Anonymization in future */}
        {/* {isAnon ? (
          <i
            className="fas fa-check-square mr-2 exam-button-like fa-lg "
            onClick={(e) => {
              e.stopPropagation();
              setIsAnon(!isAnon);
            }}
          ></i>
        ) : (
          <i
            className="far fa-square fa-lg exam-button-like mr-2 "
            onClick={(e) => {
              e.stopPropagation();
              setIsAnon(!isAnon);
            }}
          ></i>
        )}
        Anonymize */}
        <Button onClick={send} disabled={selectedExaminations.length === 0}>
          Burn CD
          <i className="fas fa-compact-disc ml-2"></i>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
