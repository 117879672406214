import "./style.scss";

function CheckBox({
  selected,
  size = "",
  additionalClassesString = "",
  onClick,
}) {
  const decideClass = () => {
    if (selected) {
      return "far fa-check-square";
    } else {
      return "far fa-square";
    }
  };
  return (
    <i
      onClick={onClick}
      className={`${decideClass()} ${size} ${additionalClassesString} icon-checkbox p-1`}
    />
  );
}

export default CheckBox;
