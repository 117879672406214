import style from "./style.scss";

function LinkesSternoclaviculargelenkDvOblStehend2030(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 618.31 385.34">
      <g id="underlays">
        <ellipse
          id="sternumcavicular_joint_1"
          data-name="sternumcavicular joint 1"
          class="Sternoclaviculagelenke2030Fill"
          style={{
            fill: props.colors.Sternoclaviculagelenke2030Fill,
            stroke: props.colors.Sternoclaviculagelenke2030Fill,
          }}
          cx="410.59"
          cy="101.84"
          rx="51.63"
          ry="70.4"
          transform="translate(18.45 260.52) rotate(-35.96)"
        />
      </g>
      <g id="sternum_recording" data-name="sternum recording">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls5"
            d="M235.85,7.56c2.7.96,6.02,4.97,13,9,6.85,3.96,8.34,3.68,13,7,2.88,2.05,8.19,5.93,12,13,1.78,3.29,4.29,7.97,4,14-.25,5.11-2.27,6.15-3,14-.43,4.63.15,5.64-1,7-2.48,2.95-7.2.68-15,2-2.36.4-3.45.86-11,4-19.91,8.27-19.26,7.92-19.83,8.22-14.8,7.82-16.26,15.84-22.17,14.78-5.36-.97-6.21-7.66-13-19-9.71-16.21-13.63-14.54-25-33-4.82-7.82-5.35-10.1-10-16-7.57-9.61-12.61-11.75-12-15,.84-4.46,11.22-5.21,32-8,37.98-5.1,48.14-5.52,58-2h0Z"
          />
        </g>
        <g id="sternum">
          <path
            id="sternum_1"
            data-name="sternum 1"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls4"
            d="M469.32,185.57c-4.04,5.98-8.19,3.98-22.96,13.07-5.55,3.42-10.68,7.63-20.95,16.07-17.98,14.77-13.75,13.63-22.94,20.07-7.41,5.2-12.04,7.25-13.96,13.04-1.91,5.76,1.18,8.27-.96,13-2.91,6.44-10.55,6.01-18.97,11.06-6.72,4.03-5.36,6.41-16.93,21.05-10.13,12.81-10.92,10.68-28.9,31.09-7.97,9.04-8.6,10.36-11.96,12.04-8.08,4.03-13.12.73-33-.9-19.9-1.63-20.6,1.2-31.01-1.9-11.86-3.53-12.95-7.79-22.02-7.93-9.99-.15-13.35,4.94-21.99,3.07-4.27-.93-5.91-2.7-14.03-8.96-20.46-15.76-23.66-14.94-25.06-19.92-2.49-8.84,7.52-11.51,11.05-29.01.87-4.31,3.82-18.94-3.24-32.02-4.81-8.92-10.18-9.52-11.05-16.97-.23-1.97-.3-5.71,6.94-20.02,6.18-12.23,7.69-12.39,8.95-16.03,7.38-21.37-35.33-43.39-29.26-68.75,1.14-4.75,4.57-12.02,10.01-13.19,4.97-1.07,7.93,3.74,15.02,5.95,8.1,2.52,15.41-.27,24.99-4.08,15.65-6.22,15.85-11.13,32.94-20.1,2.5-1.31,13.92-7.21,29.97-11.09,13.85-3.35,22.61-5.35,31-1.1,7.21,3.65,6.69,8.13,14.03,10.96,2.39.92,7.9,2.54,23.99-2.07,21.09-6.04,24.18-12.42,31.97-10.1,8.87,2.64,13.67,13.52,16.06,18.95,2.98,6.76,1.94,9.01,6.06,19.98,2.91,7.75,4.37,11.63,7.05,14.98,4.86,6.07,8.87,5.76,27.04,13.92,16.01,7.19,16.26,8.94,23.03,9.93,12.25,1.8,15.75-3.3,22-.07,10.04,5.19,14.38,25.21,7.11,35.98h-.02Z"
          />
          <path
            id="sternum_2"
            data-name="sternum 2"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls4"
            d="M318.93,380.03c-1.88-4.95-5.6-12.63-13.06-18.96-2.16-1.84-8.04-6.44-31.04-11.9-5.16-1.23-17.61-4.02-35.02-5.89-15.24-1.64-6.4.43-37-.89-7.4-.32-15.09-.75-18.99,4.06-2.25,2.78-1,4.54-3.97,10.01-1.06,1.95-4.07,7.35-9.97,11.03-5.11,3.19-7.71,2.17-9.98,5.03-2.6,3.28-2,8.17-.96,12"
          />
        </g>
        <g id="collarbone">
          <path
            id="collarbone_1"
            data-name="collarbone 1"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls4"
            d="M566.75,1.26c-6.07,3.89-14.5,8.94-24.96,14.08-9.49,4.66-24.87,12.11-45.95,17.14-19.49,4.65-29.24,6.98-41.98,5.13-23.13-3.35-31.51-16.24-46.03-10.86-.71.26-10.78,4.17-14.96,14.05-3.07,7.27-1.47,14.28.07,21,1.71,7.5,3.32,7.87,8.06,19.98,1.83,4.68,5.32,13.56,8.08,24.98,2.86,11.82,1.96,13.99,5.06,17.98,6.27,8.07,17.46,8.85,19.03,8.94,6.9.38,9.99-2,29.96-12.09,19.47-9.84,18.94-8.79,25.96-13.08,12.09-7.39,12.28-9.66,23.95-17.07,7.54-4.8,15.12-8.29,29.95-15.09,8.8-4.03,20.65-9.29,34.95-15.11"
          />
          <line
            id="collarbone_2"
            data-name="collarbone 2"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls2"
            x1="146.15"
            y1="18.57"
            x2="204.76"
            y2="101.84"
          />
          <path
            id="collarbone_3"
            data-name="collarbone 3"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls2"
            d="M237.34,9.28c14.47,10.96,52.49,16.84,36.49,61.96"
          />
          <path
            id="collarbone_4"
            data-name="collarbone 4"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M156.94,18.53c13.13,14.56,37.09,48.97,47.83,83.31"
          />
          <path
            id="collarbone_5"
            data-name="collarbone 5"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M402.87,39.81s-46.86,44.11,22.29,82.98"
          />
          <path
            id="collarbone_6"
            data-name="collarbone 6"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M424.9,39.74c59.64,19.51,89.66-2.16,144.87-31.49"
          />
          <path
            id="collarbone_7"
            data-name="collarbone 7"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M449.33,128.63c33.66-43.94,99.75-70.36,128.57-81.4"
          />
          <path
            id="collarbone_8"
            data-name="collarbone 8"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls3"
            d="M204.76,101.84c25.88,30.81,60.14,8.68,69.07-30.6"
          />
        </g>
        <g id="ribs">
          <path
            id="rib_1"
            data-name="rib 1"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M27.94,384.94c135.67-102.3,503.32-180.84,573.47-170.78"
          />
          <path
            id="rib_2"
            data-name="rib 2"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M484.68,102.52C274.15,173.89,86.49,240.75,52.55,258.86"
          />
          <path
            id="rib_3"
            data-name="rib 3"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M355.75,1.92C157.89,35.45-3.03,151.38,2.26,167.02"
          />
          <path
            id="rib_4"
            data-name="rib 4"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M120.8,18.65c7.82,9.81,14.39,48.03,35.21,67.89"
          />
          <path
            id="rib_5"
            data-name="rib 5"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M56.83,25.85c9.59,27.57,20.45,127.81,82.06,178.97"
          />
          <path
            id="rib_6"
            data-name="rib 6"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M2.85,355.01c2.14,1.84,5.28,4.57,9.02,7.97,5.56,5.06,6.57,6.26,8.02,5.98,4.09-.8,5.31-12.17,5.94-18.02.91-8.43-.35-9.08.95-15,1.07-4.88,2.02-4.85,5.95-15.02,2.67-6.9,4-10.35,4.95-15.02,1.69-8.3.05-9.2,1.96-13.01,2.86-5.69,7.15-4.92,13.97-11.04,2-1.8,9.32-8.37,10.94-18.03,1.52-9.03-2.64-16.51-4.06-18.99-6.65-11.58-16.82-13.19-33.07-22.9-6.91-4.13-16.56-10.7-27.07-20.92"
          />
          <path
            id="rib_7"
            data-name="rib 7"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M546.34,190.83c18.82-12.26,42.39-19.88,47.42-25.65"
          />
          <path
            id="rib_8"
            data-name="rib 8"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls3"
            d="M537.15,128.35c14.95-11.18,37.56-23.47,46.9-31.15"
          />
          <path
            id="rib_9"
            data-name="rib 9"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M510.63,285.44c44.96-11.01,89.11-30.68,96.86-46.3"
          />
          <path
            id="rib_10"
            data-name="rib 10"
            class="Linkes_Sternoclaviculargelenk_dv_obl_stehend2030_cls1"
            d="M570.89,367.25c25.08-9,46.17-19.73,46.92-26.34"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="sternumcavicular_joint_2"
          data-name="sternumcavicular joint 2"
          class="Sternoclaviculagelenke2030Fill"
          style={{
            fill: props.colors.Sternoclaviculagelenke2030Fill,
            stroke: props.colors.Sternoclaviculagelenke2030Fill,
          }}
          d="M196.36,87.93c-.08,11.57,3.41,20.01,15.47,20.89,18.35-13.89,39-24.85,61.99-32.82,5.83-4.42,3.79-12.44,0-15.8-24.43,12.39-49.77,23.99-77.47,27.72Z"
        />
      </g>
    </svg>
  );
}

export default LinkesSternoclaviculargelenkDvOblStehend2030;
