import CT from "./Images/CT.svg";
import MG from "./Images/MG.svg";
import MRI from "./Images/MRI.svg";
import US from "./Images/US.svg";
import Xray from "./Images/Xray.svg";

function ModalitiesIcons({ modality, ...props }) {
  const getModalityImage = () => {
    let mod = modality.toLowerCase();
    switch (mod) {
      case "ct":
        return CT;
      case "mg":
        return MG;
      case "mr" || "mri":
        return MRI;
      case "us":
        return US;
      default:
        return Xray;
    }
  };

  return (
    <img
      src={getModalityImage()}
      alt={modality}
      className={props.className ? props.className : ""}
      onClick={props.OnClick ? props.onClick : () => {}}
      style={props.style ? props.style : {}}
    />
  );
}

export default ModalitiesIcons;
