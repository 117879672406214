import { useState } from "react";
import { Modal, Row, Col, Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { loadSystems } from "../../Utilities/Systems/SystemsUtils";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { DicomSend } from "../../Utilities/ClientServices/ClientServices";
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationManager } from "react-notifications";
import "./stylesheets/DicomSendModal.scss";
import SelectedExamsDetails from "../SelectedExamsDetails/SelectedExamsDetails";

export default function DicomSendModal({ shown, headerless = false, close }) {
  const [systems, setSystems] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [sendAnonymized, setSendAnonnymized] = useState(false);
  const { keycloak } = useKeycloak();
  const [inProgress, setInProgress] = useState(false);

  const getSystems = () => {
    const filerClause = { field: "aetitle", op: "!=", value: "" };
    loadSystems(keycloak, 20, 1, filerClause)
      .then((data) => {
        setLoading(false);
        setSystems(data);
        setLoadingFailed(false);
      })
      .catch((reason) => {
        setLoadingFailed(true);
        NotificationManager.error("Failed loading systems");
        console.warn(reason);
      });
  };

  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  const send = () => {
    setInProgress(true);
    let queue = [];
    selectedExaminations.forEach((exam) => {
      let data = {
        study_id: exam.id,
        dest_sys_id: selected,
        anonymized: sendAnonymized === true ? "t" : "f",
      };
      queue.push(DicomSend(keycloak, data));
    });
    Promise.all(queue)
      .then((data) => {
        setInProgress(false);
        NotificationManager.success("Dicom send success");
        close();
      })
      .catch((reason) => {
        setInProgress(false);
        NotificationManager.error("Dicom send failed");
        console.warn(reason);
      });
  };

  useEffect(() => {
    setLoading(true);
    getSystems();
  }, [shown]);

  return (
    <Modal
      className="top-modal"
      show={shown}
      onHide={close}
      size={"lg"}
      onClick={(e) => e.stopPropagation()}
    >
      {!headerless && (
        <Modal.Header className="py-2 bg-light">
          <h4>Select destinantion system</h4>
          {inProgress && (
            <ClipLoader color="#a1a1a1" loading={true} css={true} size={30} />
          )}
        </Modal.Header>
      )}
      <Modal.Body className="text-center ">
        {selectedExaminations.length === 0 ? (
          <Container>
            <h5>Select Examination first</h5>
          </Container>
        ) : (
          <Container>
            {loadingFailed ? (
              <h5>Failed to load systems</h5>
            ) : (
              <>
                {loading ? (
                  <ClipLoader
                    color="#a1a1a1"
                    loading={true}
                    css={true}
                    size={30}
                  />
                ) : (
                  <>
                    <Row>
                      <Col xs={12}>
                        <SelectedExamsDetails />
                      </Col>
                    </Row>
                    <Row className="border-top border-left border-right rounded py-2">
                      <Col sm={1} className="border-right font-weight-bold">
                        #
                      </Col>
                      <Col className="border-right font-weight-bold">
                        Description
                      </Col>
                      <Col className="border-right font-weight-bold">Name</Col>
                      <Col className="border-right font-weight-bold">
                        AETitle
                      </Col>
                      <Col className="font-weight-bold">IP adress</Col>
                    </Row>
                    {systems !== undefined &&
                      systems.total_count > 0 &&
                      systems.systeme.map((system, key) => (
                        <Row
                          key={`systemkey${key}`}
                          className="border rounded mb-1 py-2"
                        >
                          <Col sm={1} className="border-right">
                            {selected === system.id ? (
                              <i
                                className="fas fa-check-square exam-button-like fa-lg "
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelected(undefined);
                                }}
                              ></i>
                            ) : (
                              <i
                                className="far fa-square fa-lg exam-button-like "
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelected(system.id);
                                }}
                              ></i>
                            )}
                          </Col>
                          <Col className="border-right">
                            {system.bezeichnung}
                          </Col>
                          <Col className="border-right">
                            {system.computername}
                          </Col>
                          <Col className="border-right">{system.aetitle}</Col>
                          <Col>{system.ip}</Col>
                        </Row>
                      ))}
                  </>
                )}
              </>
            )}
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer className="px-0 py-0 bg-light">
        <Button className="mr-auto" onClick={close}>
          Close
        </Button>
        {sendAnonymized ? (
          <i
            className="fas fa-check-square mr-2 exam-button-like fa-lg "
            onClick={(e) => {
              e.stopPropagation();
              setSendAnonnymized(!sendAnonymized);
            }}
          ></i>
        ) : (
          <i
            className="far fa-square fa-lg exam-button-like mr-2 "
            onClick={(e) => {
              e.stopPropagation();
              setSendAnonnymized(!sendAnonymized);
            }}
          ></i>
        )}
        Send Anonymized
        <Button
          className="ml-4"
          disabled={selected === undefined}
          onClick={send}
        >
          Send <i className="fas fa-paper-plane"></i>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
