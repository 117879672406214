import style from "./style.scss";

function DensVdStehend1608(props) {
  return (
<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 467.6 415.5">

<g id="BG">
  <path id="bg" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls1" d="M29.4,6c32.4,1.1,65.4.3,99.3-3.2,1.9-.2,3.8,0,5.7.6,12.2,4,24.7,1.8,37.3-.8.5-.1,1.1-.2,1.6-.2l21.8-1.7c2.7-.2,5.4.4,7.7,1.7,9.5,5.6,19.4,7.6,29.8,6,3.5-.5,7,.4,9.8,2.5l10.9,8.2c2,1.5,4.4,2.4,6.9,2.6,9.2.6,17.7-2.4,25.3-10.3,2-2.1,4.6-3.5,7.4-3.9l27.6-4.7c1.1-.2,2.2-.2,3.3-.1,32.8,2.8,66.6,2.1,101.3-1.3,5.7-.6,11.2,2.6,13.5,7.9l11,25.1c.8,1.8,1.1,3.6,1.1,5.5-.2,12.1,4.2,23.7,11.8,34.8,1.1,1.7,1.9,3.6,2.1,5.6l2,14.6c.4,2.9-.2,5.8-1.6,8.3-9.2,16.7-14.8,38.4-14.2,68.9,0,.3,0,.7,0,1-1.2,22.2-5.1,42.6-14.4,59.2-1.1,2-1.7,4.2-1.7,6.5-.2,44.1-4.4,77-17.4,85-.6.3-1.1.7-1.6,1.1l-9.5,8.4c-2.2,1.9-3.7,4.5-4.3,7.3-5,24.1-17.9,46.8-34.8,68.9-2.8,3.7-7.4,5.5-12,5.1-79.7-5.4-164.6-5.1-252.8-1.5-4.6.3-9-1.7-11.7-5.5-20.5-28.5-36.8-58.7-48.8-90.7-.8-2.1-2-3.9-3.7-5.4-12.3-10.7-19.7-24.8-21.8-42.6L0,37c-.3-3.7,1-7.3,3.5-10l15.6-16.8c2.6-2.8,6.4-4.4,10.2-4.2h0Z"/>
</g>
<path id="Dens_Highlight" class="Dens1608Fill" style= {{
            fill: props.colors.Dens1608Fill,
            stroke: props.colors.Dens1608Fill,
          }} d="M219.2,274.4c4.6-6,5.5-17.5,5.4-19.3-.7-8.8-4.9-11-3.7-17.3,1-5.2,4.6-8.2,6.1-9.5,5.1-4.3,12.4-5.7,18.7-2.5,5.3,2.7,7.8,7.7,8.4,9,3.5,7.3,2.1,14.9,1.6,17.9-1.1,6.4-3,6.8-3.2,11.4-.2,7.1,1.9,9.7,4.2,12.6"/>
<g id="Spine">
  <path id="Spine18" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" d="M148.3,291.5c-2.5-2.2,3.4-9.7,4.7-26.9.9-11.1-1.1-15.1,2.1-19.7,3.1-4.4,8-5.2,16.9-6.6,10.7-1.7,18.4.2,20,.6,6.8,1.8,11.4,2.9,14.8,7.8,3.1,4.4,4.7,11.3,2.6,17.3-.5,1.5-2.3,6-6.3,7.8-2.9,1.3-5.6.4-7.4,0-5-1.1-9.7.6-19,4.2-17.8,6.8-25.5,18.2-28.4,15.5h0Z"/>
  <path id="Spine17" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" d="M328.4,253.8c.6,2.2-1.6,3.6-3.8,9.7,0,0-1.5,4.2-2.5,11.2-2.1,15.3,4.7,28.7,2.2,30.2-1.8,1.2-5.2-6.5-14.9-12.3-2-1.2-2.6-1.3-15.3-6-14.4-5.3-15.4-5.6-17.4-7.2-4.7-3.8-12.1-12.5-10.5-21.5,1.7-10.2,14-15.8,22.6-16.1,7.1-.3,7.4,3.1,22.1,6.6,9.9,2.4,16.5,1.8,17.4,5.4h0Z"/>
  <path id="Spine16" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" d="M149.9,299.3c2.4-3,6.7-2,10.5-1.8,3.3.1,10.4-2.9,24.8-9,16.9-7.2,12.9-6.6,21.1-9.6,7.6-2.7,11.2-3.2,14.2-7.2,4.6-6,3.8-14.9,3.7-16.8-.7-8.8-4.9-11-3.7-17.3,1-5.2,4.6-8.2,6.1-9.5,5.1-4.3,12.4-5.7,18.7-2.5,5.3,2.7,7.8,7.7,8.4,9,3.5,7.3,2.1,14.9,1.6,17.9-1.1,6.4-3,6.8-3.2,11.4-.2,7.1,4.1,12.7,6.3,15.5,5.4,7,8.6,5.9,21.1,15,9.3,6.7,9,8.4,14.8,10.8,7.6,3.2,15.8,4.2,15.8,4.2,6.1.7,10.3.2,12.6,3.6.2.3,2.1,3,1.3,5.5-1.3,4-7.8,2-10.5,6.8-1.8,3.2-1.3,8.5.8,11,.9,1.1,1.5,1,2.9,2.7.7.8,3.4,3.9,2.9,5.7-.7,2.4-7,2.2-14.2,1.8-10-.5-11.2-1.7-15.8-.6-5.2,1.3-8.8,4.2-11.1,6-2.5,2-6.2,5-9,10.2-3.9,7.3-2,11.9-5,13.9-3.1,2.1-5.9-2.3-14.5-4.9-7.9-2.5-9,.2-24.8-1.2-3.9-.4-6.2-.7-9.7,0-3.9.8-5.1,2.1-6.6,1.2-2.8-1.7-.6-6.9-4.2-12-2-2.7-3.3-2.2-5.1-5.4-1.9-3.4-1.3-5.8-2.7-6.5-1.9-.9-3.5,3.2-7.5,4-3.3.7-6.3-1.3-7.8-2.3-5.1-3.4-4.7-7.7-9-9-.2,0-2.1,0-6-.1-5.3,0-5.7,0-6.1.1-4.2,1.3-4.7,5.6-6.9,5.4-2.6-.3-5.3-6.9-4.2-12.5.7-3.7,2.5-4,4.2-9.6,1.2-3.7,2-6.3,1.1-8.9-1.4-3.9-5.2-3.2-6.6-7.2-.9-2.6-.3-5.9,1.3-7.8h0s0,0,0,0Z"/>
  <path id="Spine15" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" d="M190,409.7c-2.1-1.2-4.3-1.3-8.7-1.6-3.2-.2-4,.1-10.8.5-7.6.5-9.6.2-11.7-.3-3.1-.8-5.8-1.5-7.3-4.2-1.6-3.1-.5-6.8-.2-7.7h0c1.5-4.9,5.4-7.1,7-8,3.7-2.1,7-2.1,11.5-2.1,9.4,0,13,2.6,15.2,0,1.3-1.5.3-2.7,1.4-9,.8-4.4,1.9-7.5,2.8-9.8,2-5.5,4.2-11.3,6.6-11.2.9,0,1.7.9,4.4,7.7,4.2,10.4,4.6,14,7.7,16.2,2.3,1.7,4.7,1.6,6.8,1.6,8.7-.1,17.5,0,26.2,0,16.9-.1,22.3,4.7,27.6-.6,2-2,3.3-4.8,5.5-9.2,2.6-5.4,2.8-7.8,5.6-12.8,2.6-4.7,5.5-8.2,6.3-7.7.8.5-1.4,4.5.2,7.2.9,1.5,2.3,1.2,4,2.1,4.1,2.1,5.8,8.6,6.1,12.5.6,7.9-4.3,11.9-2.3,14.9,1.1,1.7,2.9.7,9.1,2.1,4.9,1.1,7.6,1.7,9.8,4.2.4.5,3.9,4.5,3,10.1,0,.6-.8,4.6-3.8,6.9"/>
  <path id="Spine14" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" d="M287.9,396.8c-3.4,4.4-1.2,11,0,11.8"/>
  <path id="Spine13" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" d="M184.1,392.8c6.2,2.3,1.4,11.6,3,11.6"/>
  <path id="Spine12" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" d="M190.9,333.4c0-9.1-1.6-16.5-3.6-16.5"/>
  <path id="Spine11" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" d="M293,339.3c-3.2-7.5,0-12.3,4.3-11.7"/>
  <path id="Spine10" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" d="M218.6,361.4c0-12.2,4.6-22.1,10.3-22.1"/>
  <path id="Spine9" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" d="M251.1,361.4c0-11.3-4.8-20.5-10.8-20.5"/>
  <line id="Spine8" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" x1="227.5" y1="333.4" x2="228.9" y2="339.3"/>
  <line id="Spine7" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls5" x1="241.8" y1="333.4" x2="240.4" y2="340.9"/>
  <path id="Spine6" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls6" d="M118.6,247.9c-.3,0-2.9.6-4.6,3.2-1.8,2.8-1.2,5.9-1.1,7.2,0,.4.9,5.2,4.6,7.6,1.8,1.1,3.2,1.1,6,1.2,9.3.4,8.5,1.4,14.4,1.2,6.6-.2,5.9-1.4,10.5-1.2,4.4.2,7.7,1.4,12.6,3.2,2,.7,4.8,2,10.2,4.4,5.9,2.6,8.8,4,11.2,5.2,7.3,3.7,8.3,5.2,12.3,7.2,4.2,2.1,7.2,2.5,19.7,3.6,13.9,1.2,20.8,1.8,27.4,2,13.9.4,20.9.7,26-.8,2.1-.6,6.2-2,12.3-2.8,5.3-.7,5.6-.1,9.5-.8,6.5-1.1,6.9-2.9,15.8-5.6,2.2-.7,7-2,13.4-2.8,3.4-.4,5.5-.6,8.4-.4,4.6.4,5.1,1.5,11.2,2.8,5.5,1.2,9.1,1.2,12.3,1.2,6.9,0,8.4-1.1,9.1-2,1.9-2,2.6-5.2,2.1-8-.9-5-5.3-6.9-6-7.2-2.7-1.1-4.4-.3-9.1.4-5.2.8-10.1,1.6-15.5.4-3.9-.9-7.4-1.6-11.2-4.4-1.8-1.3-3.3-2.8-5.6-2.8-2.3,0-3.2,1.4-5.6,2.4-2,.9-3.6.8-9.8,0-7.5-1-13-2-13-2-2.9-.5-5.6-1-12.6-2.4-10.4-2-10.6-2.1-12.6-2.4-3.6-.6-4.1-.5-13.7-1.6-12.8-1.4-12.6-1.6-15.1-1.6-3.9,0-9.1,0-15.4,2-6.3,2-7.5,3.9-13,4.4-2.9.2-5.1-.1-9.5-.8-4.9-.8-8.7-1.9-11.6-2.8-4.5-1.4-6.9-2.1-10.2-3.6-5.4-2.4-6.6-3.9-9.5-3.6-2.8.4-2.8,2-6.7,3.6-2,.8-5.1.9-11.2,1.2-4.7.2-7.1.3-9.5,0-2.3-.2-4.7-.5-7.7-1.6-5-1.8-6.2-3.8-9.1-3.2h0s0,0,0,0h0Z"/>
  <path id="Spine5" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls6" d="M197.7,240.5c0,.8,42.7-43.6,91.3,1.4"/>
  <path id="Spine4" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls6" d="M162.9,247.9c0-4.4-25.8-8-57.6-8"/>
  <path id="Spine3" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls6" d="M314.6,259.8c0-5.1,13.2-9.2,29.5-9.2"/>
  <polyline id="Spine2" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls6" points="147.7 336.4 138.6 351.5 138.6 369 147.7 382.2 147.7 404.4 145.3 410.3"/>
  <polyline id="Spine1" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls6" points="320.5 340.9 324.5 350.3 329.3 361.4 328.5 374.6 324.5 390.6 322 402.8 323.3 411.5"/>
</g>
<g id="Face" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls3">
  <path id="Face50" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M12.1,31.6c12.4-1.1,23.8-.5,26.4,15.2,3.4,10.6,1.2,22.6-3.3,35.2-7.4,21.2-1.9,43.9,13.4,67.8,24,22.8,37.7,46.6,39.2,71.4"/>
  <path id="Face49" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M23.3,5.8c6.4,13.1,14.8,24.5,29.7,30.4,9.4,3.8,19.8,4.4,29.8,2.6,36.6-6.7,58.9-17.2,49-36.4"/>
  <path id="Face48" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M70.4,7l3,49.8c1.9,13.3,7.5,22.2,19.1,24.3,11.6,8.8,20.5,20.4,24.3,37.4,10,18,23.5,23.5,40.1,17.6,30-7.1,50-15.9,39.8-29.8-15.7-11-21.6-24.6-19.8-40.4.4-31.4,1-62.8-4.6-63.5"/>
  <path id="Face47" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M.8,31.2c8.4,16.9,18.2,28.1,31,26.8,23.1-2.5,50-1.2,79,2.1,7.6,1.1,14.8,3.7,21.6,8.8,12.5,9.5,24.6,10.4,36.2,3.9,13.2-6.2,26.6-10.1,40.1-11.5,15.4-1.3,31.4,1.9,47.7,9,15,6.3,30.2,5.3,45.6-2.7,9.4-4.3,19.2-7.2,30.1-5.5,16.1-6.4,34.1-9.6,55-7.9,24.9,4.1,35.3-12.1,37.7-39.5"/>
  <path id="Face46" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M199.5.2c-5.3,13.7-9.6,27.7-10.3,42.9-2.1,14-.5,27.8,6.4,41,4.5,7.1,11.4,12,23.4,12.2,10.9,3.5,16.4-5.1,18.5-21.3,3.4-24.2,3.9-47.1,0-67.8"/>
  <path id="Face45" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M256,21.1l-9.5,45.5c-3.5,17.6.1,30.5,15.6,35.5,12.6,1,22.8-6.6,29.5-27,7.4-25.3,4.5-47-3.3-66.8"/>
  <path id="Face44" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M320.4,10.3c-6.6,10.1-11.2,19.9-10.9,29.5,1.5,14.2-1.7,28.1-6.4,41.9,9.6,17.8,13,36.2,11.2,55.3,6.7,8.9,18.1,9.5,32.5,4.9,10.3-6.1,21-17.9,31.9-31.9,30.6-20.7,45-38.4,42.8-53.2-2.3-10.2-3-24.4-3-40.1"/>
  <path id="Face43" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M323.5,2.4c-2.8,11-1.9,20.2,8.8,24.9,19.5,11.5,38,16.6,55.3,12.5,20.8-7.9,36.5-21,47.1-39.5"/>
  <path id="Face42" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M21.6,56.3c3.3,10.2,11.4,15.9,23.6,17.8,21.7,2.8,37.9,8.1,46.5,17,13.2,13.7,17.5,29.6,15.2,47.2-1.7,10.7-.1,18.4,7.3,20.2"/>
  <path id="Face41" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M464.1,79c-8.1,12.2-18.9,16.2-32.5,11.5-13.8-1.9-27.8,3.9-41.9,16.9-12.4,8-20.4,18.9-19.4,35.7-1.1,15.1-4.7,24.8-12.1,25.8"/>
  <path id="Face40" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M450.8,37c-10-3.8-17.3,1.6-23.4,11.6-3.6,9.9-3.7,21.1,0,34,6.9,9.9,9.7,22,7.3,36.8-.6,23.9-33,66.5-76.6,115.8"/>
  <path id="Face39" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M467.2,99.3c-11.7,21.9-18.3,45.9-16.4,73.2-1.8,28.2-7.1,49.3-16.1,62.6-.5,51.3-5.4,85.3-18.2,89l-12,10.9"/>
  <path id="Face38" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M435.8,120.3c4.6-3.2,8.3-3.6,9.5,3.7,0,15.1-6,33.2-14.3,52.6-19.8,53.1-30.7,95.1-28.6,121.2"/>
  <path id="Face37" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M38.5,151.9c5.8,23.2,7.7,46.2,5.5,69.3,3.6,17.1,7.8,36.4,12.1,57.1"/>
  <path id="Face36" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M406.4,310.1c-2.7,26.2-7.6,49.8-17.9,67.1l-26,38"/>
  <path id="Face35" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M16.3,268.8c3.2,18.2,9.4,34.3,24.3,44.7,16,40.5,33.9,74.9,54.4,100"/>
  <path id="Face34" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M366.6,290.7c-2.7-10-2.2-21.3,0-33.4.2-8.3-2-15.1-7.3-20-1.5-1.4-3.7-2-5.7-1.7l-8.9,1.4c-2.8-2.1-5.9-1.3-9.2,2.3-1.5,1.6-2.3,3.7-2.3,5.9v4.2c-1.9,3.4-2.8,7.1-2.1,11.2.7,9.3,5.6,18.9,15.5,28.6"/>
  <path id="Face33" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M333.2,249.4c4.1-1.1,7.9-.8,10.9,2.7,6.1-5.2,11.9-9.3,16.1-1.4"/>
  <path id="Face32" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M355.4,239.1c1.6,2.4,1.6,4.8,0,7.2"/>
  <path id="Face31" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M344.4,246.3c-2.6-2-4.9-2-6.7,0"/>
  <path id="Face30" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M348.7,327.2c4-24.9,8.3-50.1,0-61.4-8.4-6-16.8-6.9-25.2-2.7-8.4,1.8-11.5,8.5-10.6,18.8"/>
  <path id="Face29" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M318.3,268.8c9.1,3.5,16.7,2,22.8-4.6"/>
  <path id="Face28" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M341.1,323.2c2.4-20.9,2.8-37.7-3.3-40.1l-10-2.1c-1.3,6.4-6.7,10-17.6,9.7"/>
  <path id="Face27" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M312.7,285.9c-9.2-.3-12.8,3.6-10.8,11.9"/>
  <path id="Face26" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M322.1,343.3l-1.7-26.7c1.9-4.9,2.5-9.4,0-13.1,0-5.7-2.2-7.9-7.7-4-4.8,4.4-10.7,2.5-16.8,0-4.9,2.3-2.9,9.5,2.1,18.5,4.8,9.4,4.2,21.1.9,34"/>
  <path id="Face25" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M272.1,362.7l3-27.6c-2.6-6-.3-11.1,7.9-15.2,3-7.9,5.8-9.3,8.5-4.9,2.7,3.7,5.3,5.9,7.6,5.7"/>
  <path id="Face24" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M255.1,365.1c1-12,.8-22-2.1-27.5-1.1-4.6-.3-8.9,3.3-12.8,6-1.5,11.8-2.8,14.2,0-1.6,14.9-2.4,28.8-1.5,40.3"/>
  <path id="Face23" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M245.3,366.4c1-7.9,3-15.8,5.9-23.7,1.5-8,.7-13.3-4.6-13.1-9.9-2.4-11.9,2.3-12.8,8,2.1,10.3,2.9,20.6,1.8,30.9"/>
  <path id="Face22" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M210.8,365.1c5.2-11.1,7.6-21.2,6.7-30.1-.3-7.9.6-13.5,6.4-10.3l10,2.4c4.6,1.5,1.9,8.7-2.1,17-2.2,8.3-4.3,16.1-6.4,23.1"/>
  <path id="Face21" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M193.8,368.5c-.2-24.3-.3-48.5,5.7-48.6h13.3c4.8,4.9,4.5,10,0,15.2-5.5,12.6-8.2,25-8.1,37.4"/>
  <path id="Face20" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M163.7,355.1c3.5-25.1,7.8-45.1,16.4-37.4,2.7,1.8,4.4,1.8,4.2-1.5,1.7-.5,3.7-.7,6.4,0,4.8,6.3,3.1,15.3,0,24.9l-5.5,26.1"/>
  <path id="Face19" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M138.8,354.8l14-13.7c16.1-16.3,21.1-28.2,14.3-35.5-1.6-8.2-6.5-9.9-12.1-10-6-2.7-11.4-3.4-14,4.6-8.4,13.4-14.8,28.1-19.4,44"/>
  <path id="Face18" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M167.6,310.1c-4.9-3.4-8.5-3-11.9-1.8-2.5-5-4.6-3.7-6.4,0-3.6-2.2-5.4-4.9-6.7-7.9"/>
  <path id="Face17" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M154.6,292.1c-.6-6.5-3.5-12.3-8.8-17.2-3.3-2.9-7.8-3.6-14.9,0-17.2,4.3-25.2,26-28.9,55.6-.2,1.6,1.9,2.5,2.9,1.2,4.8-6,9.3-10.3,12.6-8.5,3.8,1.7,4.2,8.9,2.9,19-.1.9,1.1,1.4,1.6.7l31.6-39.5c.9-4,1.2-7.8.9-11.4h0Z"/>
  <path id="Face16" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M134.9,278.2c3.3-2,5.9,0,7.8,6.1,2.1-1.7,4.3-1.5,6.7,0"/>
  <path id="Face15" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M103.5,307.9c24.2-12.5,42.3-25.9,39.2-42.1-4.3-.8-5.7-3.5-3.9-8.2-3.4-4.6-8.3-7.4-14.6-8.4"/>
  <path id="Face14" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M123.3,258.8c5,1.9,8.8,4.4,7.6,8.7,5,.7,7.7,1.8,8.2,3.2"/>
  <path id="Face13" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M87.7,296.5c4.6-6,8.8-13.9,12.5-25.8,3.5-11.8,9.4-19.6,19.1-21.5,5.9-2.3,6.3-7.4,0-15.9-14.6-13.5-25.1-16.2-31.6-8.2l-13.4,35.7"/>
  <path id="Face12" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M355.4,151.9c4,17.9,2.8,34.8-3.3,50.7-5.2,6-10.8,3.7-16.4,0-10.9,4-14.5-.3-15.5-7.6l-1.8-27.9"/>
  <path id="Face11" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M342.7,196.5c-2,15.6-6.1,24.8-16.3,14.6-16.5,12-15.6-7.6-12-32"/>
  <path id="Face10" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M329.7,188c2.9,19.2,4.4,36.1-6.2,33.1-4.5,3.2-7.8-.3-10.8-5.7-14,4.4-7.6-14.3-5.1-28.6"/>
  <path id="Face9" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M310.1,184.7c2.4,14.8,3.8,28.1,1.8,36.9-.9,4.1-5.6,6.1-9.2,4.1-13.9-7.8-15.9-21.3-9.6-38.8"/>
  <path id="Face8" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M286.1,169.3c4.2,3.8,5.4,11.9,5.8,21.2,4.2,16.4,3.8,27.9-4.8,30.2-10.2,3.3-15-2-14.9-15.1,1.7-7.7,1.5-13.7,0-18.8-.9-7.3-.7-12.7.5-16.2.9-2.6,4.6-2.6,5.6,0,1.5,4,2.4,8.7,3,13.8,0,.5.8.5.8,0,.2-6.6,1-12.1,3.1-15,.2-.3.6-.3.9,0h0Z"/>
  <path id="Face7" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M252.5,225.1c-8.6,2.2-9.6-6.3-7.3-19.5l3.6-20.9c-2.1-9.1-.9-17.1,2.7-24.3.3-.6,1.2-.5,1.4.2l3.5,16.7c.2,1,1.6.9,1.7,0l1.6-16.2c.2-2,2.9-2.5,3.7-.6,2.5,5.8,3.3,12.1,2,18.8.6,8.4,2,14.8,4.5,18.7,1.2,1.9,1.8,4.2,1.8,6.5.1,6.8-.7,12.7-3.4,17.1-1.4,2.3-4,3.7-6.7,3.7h-9.2Z"/>
  <path id="Face6" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M236.5,163.2c1.6,6,2.8,16.2,3.7,29.1,3.3,16,5.5,30.3-2.6,29.3h-17.2c-4.8-1.4-5.6-8.5-4.2-18.9,4.6-9.2,7.3-22.2,7.3-40.3,0-.5.7-.6.8-.1l5.8,21.3c.3,1.1,1.8.9,1.9-.1l2.5-20c.1-1.1,1.7-1.2,2-.1h0Z"/>
  <path id="Face5" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M206.8,220.6c-10.3,1-12.1-2.7-12.1-14.3,0-3.4.8-6.9,2.2-10,3.1-7.3,3.8-14.8,2.9-24.6,0-.3.3-.3.4,0l3.9,14.9c.3,1,1.7,1.1,2,0l5.2-15.5c.3-.8,1.5-.6,1.5.2v21.1c6.2,19.9,2.8,27.6-5.9,28.3h0Z"/>
  <path id="Face4" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M184,174.1c6.8-13.9,9.6-5,9.7,18.2,0,13.9-2.5,23.3-9.7,24-8.3,7-13.7,7-14-5.5-3.4-35.3-.9-42.1,5.2-30.4"/>
  <path id="Face3" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M177.4,173.8c3.1,22.7,3.2,39-4.9,38.3l-8.8-2.1c-2,3.4-4.1,6-6.5,7-3.8,1.6-7.9-1.5-7.5-5.6l2.4-28.7"/>
  <path id="Face2" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M163.7,178c1.6,23-2.4,33.7-10.1,35.9-1.7.5-3.4,0-4.9-.9-1.7-1.1-3.3-1.3-4.9-.3-1.9,1.2-4.3,1.8-6.4.9-9.1-3.7-5.1-21.1.1-39.8"/>
  <path id="Face1" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1698_cls4" d="M149.4,168.9c-1.8,24.6-6.9,35-15.3,31.3-9,3.9-16,2.6-20-5.9-5.5-13.2-3.6-27.5,1.2-42.4"/>
</g>
</svg>
  );
}

export default DensVdStehend1608;
