import style from "./style.scss";

export default function LinkerEllenbogenVdSitzend2211(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 374.59 928.09">
      <g id="Backround">
        <path
          id="backround1"
          class="elbow_vd_left2211_cls4"
          d="M373.73,901.84c-8.85,23.81-71.7,32.66-87.49,10.94-4.66-6.41-3.15-12.68-2.73-27.34.69-24.37-2.73-41.77-9.57-76.56-8.51-43.29-26.86-81.87-31.44-80.66-2.82.75-1.1,16.73,1.37,39.65,2.54,23.6,4.11,23.82,5.47,38.28,3.06,32.63-4.45,36.9-2.73,75.19,1.01,22.42,3.78,25.41,0,31.44-14.36,22.94-79.6,20.02-95.7-8.2-3.5-6.13-3.24-16.39-2.73-36.91.78-31.61,5.48-35.59,4.1-57.42-1.04-16.4-3.75-15.18-6.84-41.01-2.06-17.26-1.25-21.19-2.73-42.38-1.88-26.9-5.06-47.48-6.84-58.78-4.49-28.6-7.92-31.96-10.94-56.05-.69-5.5-1.83-11.87-4.1-24.61-2.97-16.62-4.04-20.38-6.84-24.61-2.19-3.32-3.98-4.15-17.77-15.04-15.5-12.24-17.04-11.41-20.51-16.41-6.38-9.19-9.99-24.71-4.1-35.54,2.86-5.26,6.63-6.97,6.84-12.3.2-5.2-3.24-7.18-6.84-13.67-7.41-13.39-1.13-20-8.2-28.71-2.64-3.25-7.23-5.36-16.41-9.57-12.19-5.6-16.1-5.31-21.87-8.2-14.43-7.24-19.91-24.05-21.87-30.08-6.2-19.04-2.68-40.9,8.2-56.05,4.42-6.16,13.89-13.22,32.81-27.34,10.47-7.81,15.15-10.72,19.14-13.67,18.22-13.47,28.24-32.45,38.28-56.05,12.56-29.5,27.12-60.48,34.18-105.27,6.44-40.87,9.57-73.82,9.57-73.82,2.45-25.82,2.01-31.1,6.84-38.28,21.73-32.34,99.35-27.56,110.73-2.73,3.04,6.62-1.78,9.28-4.1,31.44-2.28,21.78,1.85,24.17,1.37,54.68-.4,25.27-3.26,25.2-1.37,39.65.64,4.9,1.8,11.23,10.94,39.65,5.74,17.86,7.72,22.95,10.94,32.81,7.4,22.71,6.92,26.5,19.14,71.09,8.38,30.58,11.15,38.07,19.14,45.11,8.5,7.49,14.81,7.34,20.51,16.41,5.19,8.26,2.05,11.74,5.47,34.18,2.85,18.72,5.83,21.55,5.19,34.09-.49,9.54-2.67,16.82-3.83,20.6-2.24,7.3-5.4,17.63-13.67,27.34-7.91,9.29-13.35,9.64-13.67,15.04-.36,6.08,6.42,7.7,13.67,19.14,3.56,5.62,9.01,14.22,7.57,22.86-1.7,10.2-11.56,11.74-19.88,22.26-3.16,4-7.43,12.33-9.57,41.01-2.15,28.72,2.61,51.32,5.47,64.25,5.73,25.92,8.85,36.23,24.61,92.96,15.39,55.42,16.7,62.64,17.77,69.72,3.63,24.04,2.26,32.24,8.27,55.34,5.49,21.08,9.43,24.99,6.76,32.16"
        />
      </g>
      <g id="Anconvd">
        <g id="Overview">
          <path
            id="outline4"
            class="elbow_vd_left2211_cls1"
            d="M264.32,21.4c1.87,5.76-2.46,9.49-4.62,30.12-2.28,21.78,1.85,24.17,1.37,54.68-.4,25.27-3.26,25.2-1.37,39.65.64,4.9,1.8,11.23,10.94,39.65,5.74,17.86,7.72,22.95,10.94,32.81,7.4,22.71,6.92,26.5,19.14,71.09,8.38,30.58,11.15,38.07,19.14,45.11,8.5,7.49,14.81,7.34,20.51,16.41,5.19,8.26,2.05,11.74,5.47,34.18,2.85,18.72,5.83,21.55,5.19,34.09-.02.49-.45,10.25-3.83,20.6-3.84,11.8-9.96,30.59-28.22,42.38-11.8,7.61-23.9,9.24-30.08,9.57-7.69.41-14.01-.76-24.61-2.73-13.37-2.49-13.35-3.89-23.24-5.47-13.6-2.17-13.72.47-31.44-1.37-10.31-1.07-17.85-1.85-27.34-5.47-8.95-3.41-9.15-5.34-13.67-5.47-8.63-.24-11.52,6.72-27.34,17.77-8.53,5.96-15.03,10.4-24.61,12.3-5.54,1.1-15.9,3.15-23.24-2.73-6.38-5.12-7.41-13.77-8.2-20.51-1.13-9.57,1.47-11.4,0-21.87-.93-6.62-1.97-14-6.84-16.41-5.54-2.73-10.4,3.77-15.04,1.37-4.75-2.46-3.54-11.3-4.1-30.08-.66-22.17-2.67-20.73-1.37-27.34,3.96-20.09,22.23-31.88,25.97-34.18,17.6-10.8,36.17-9.27,49.22-8.2,17.95,1.47,22.85,6.32,45.11,8.2,12.22,1.03,22.28.54,28.71,0"
          />
          <path
            id="outline3"
            class="elbow_vd_left2211_cls1"
            d="M148.86,892.13c-.36-5.97-.16-13.84.11-24.45.78-31.61,5.48-35.59,4.1-57.42-1.04-16.4-3.75-15.18-6.84-41.01-2.06-17.26-1.25-21.19-2.73-42.38-1.88-26.9-5.06-47.48-6.84-58.78-4.49-28.6-7.92-31.96-10.94-56.05-.69-5.5-1.83-11.87-4.1-24.61-2.97-16.62-4.04-20.38-6.84-24.61-2.19-3.32-3.98-4.15-17.77-15.04-15.5-12.24-17.04-11.41-20.51-16.41-6.38-9.19-9.99-24.71-4.1-35.54,2.86-5.26,6.63-6.97,6.84-12.3.2-5.2-3.24-7.18-6.84-13.67-7.41-13.39-1.13-20-8.2-28.71-2.64-3.25-7.23-5.36-16.41-9.57-12.19-5.6-16.1-5.31-21.87-8.2-14.43-7.24-19.91-24.05-21.87-30.08-6.2-19.04-2.68-40.9,8.2-56.05,4.42-6.16,13.89-13.22,32.81-27.34,10.47-7.81,15.15-10.72,19.14-13.67,18.22-13.47,28.24-32.45,38.28-56.05,12.56-29.5,27.12-60.48,34.18-105.27,6.44-40.87,9.57-73.82,9.57-73.82,2.45-25.82,2.01-31.1,6.84-38.28"
          />
          <path
            id="outline2"
            class="elbow_vd_left2211_cls1"
            d="M283.98,893.17c.11-2.29.24-4.85.32-7.73.69-24.37-2.73-41.77-9.57-76.56-8.51-43.29-26.86-81.87-31.44-80.66-2.82.75-1.1,16.73,1.37,39.65,2.54,23.6,4.11,23.82,5.47,38.28,3.06,32.63-4.45,36.9-2.73,75.19.19,4.24.45,7.79.71,10.8"
          />
          <path
            id="outline1"
            class="elbow_vd_left2211_cls1"
            d="M333.52,501.28c3.56,5.62,9.01,14.22,7.57,22.86-1.7,10.2-11.56,11.74-19.88,22.26-3.16,4-7.43,12.33-9.57,41.01-2.15,28.72,2.61,51.32,5.47,64.25,5.73,25.92,8.85,36.23,24.61,92.96,15.39,55.42,16.7,62.64,17.77,69.72,2.97,19.69,2.59,26.23,4.77,39.28.36,2.12,1.4,6.77,3.5,16.06,2.61,11.54,4.33,18.53,5.42,22.95"
          />
          <path
            id="partofancon9"
            class="elbow_vd_left2211_cls1"
            d="M206.94,349.54c0,7.02-.19,18-1.37,31.44-1.48,17-4.22,34.45-5.47,42.38-3.88,24.55-5.12,25.88-4.1,32.81,1.05,7.13,3.25,11.69,15.04,32.81,15.49,27.74,14.75,25.34,17.77,31.44,5.11,10.32,14.06,28.38,16.41,51.95.25,2.54.17,17.64,0,47.85-.04,7.73-.07,9.55-.09,16.44-.06,17.94.05,28.22.09,39.61.19,49-1.15,51.55-2.73,51.95-5.48,1.38-15.59-22.64-16.41-24.61-2.6-6.25-7.03-17.22-6.84-31.44.15-10.96,3.11-19.22,5.47-25.97,3.63-10.39,6.18-12.73,9.57-20.51,5.78-13.26,13.62-41.47,1.37-56.05-6.64-7.9-13.67-5.25-17.77-13.67-1.77-3.65-1.59-6.49,1.87-27.72,3.14-19.22,3.75-21.53,6.33-24.23,7.64-7.97,20.73-7.02,23.24-6.84,10.28.75,11.68,5.67,21.87,5.47,5.8-.12,6.05-1.58,17.77-4.1,9.15-1.97,16.17-3.48,24.61-2.73,4.36.38,11.13,1.55,19.14,5.47"
          />
          <line
            id="ulnar7"
            class="elbow_vd_left2211_cls1"
            x1="224.71"
            y1="754.2"
            x2="230.18"
            y2="912.78"
          />
          <line
            id="radius6"
            class="elbow_vd_left2211_cls1"
            x1="360.06"
            y1="908.68"
            x2="305.37"
            y2="654.4"
          />
          <path
            id="radius5"
            class="elbow_vd_left2211_cls1"
            d="M312.97,568.39c-24.07,39.48-13.15,82.74-7.59,86.01"
          />
          <path
            id="radius4"
            class="elbow_vd_left2211_cls1"
            d="M305.37,892.27c-18.39-76.58-29.09-153.93-50.64-164.05"
          />
          <path
            id="ulnar6"
            class="elbow_vd_left2211_cls1"
            d="M175.5,914.15c-2.68-197.44-35.35-359.19-56.05-355.44"
          />
          <path
            id="partofancon8"
            class="elbow_vd_left2211_cls1"
            d="M319.04,453.44c26.03-22.3,26.43-53.94,13.67-64.25"
          />
          <path
            id="partofancon7"
            class="elbow_vd_left2211_cls1"
            d="M273.93,316.73c15.72,20.51,14.06,41.01,31.44,41.01"
          />
          <path
            id="partofancon6"
            class="elbow_vd_left2211_cls1"
            d="M195.66,278.45c15.83,38.55,40.92,83.63,56.39,88.86"
          />
          <path
            id="partofancon5"
            class="elbow_vd_left2211_cls1"
            d="M242.49,26.9c-26.37,21.56-6.52,176.16,9.57,205.06"
          />
          <path
            id="ulnar5"
            class="elbow_vd_left2211_cls1"
            d="M147.47,400.12c0-18.14-12.53-32.81-28.03-32.81"
          />
          <path
            id="partofancon4"
            class="elbow_vd_left2211_cls1"
            d="M41.52,382.35c-2.23-.62-16.37-4.8-23.24-19.14-5.69-11.87-4.32-26.34,2.75-35.52,8.2-10.65,18.84-7.39,39.63-16.43,17.63-7.67,28.67-18.15,36.91-25.97,11.28-10.71,22.39-25.05,35.54-54.68,7.62-17.17,19.17-46.75,30.08-106.63,4.42-24.27,9.33-56.77,12.3-95.7"
          />
          <path
            id="partofancon3"
            class="elbow_vd_left2211_cls1"
            d="M168.66,290.75c-1.27,5.81-4.05,14.06-10.94,20.51-7.91,7.4-14.03,5.7-20.51,12.3-8.56,8.74-9.09,23.19-6.84,32.81,4.48,19.07,22.72,27.33,28.71,30.08,14.83,6.8,43.95,12.7,57.42-4.1,13.4-16.72,1-43.06,0-45.11-8.03-16.48-22.42-23.75-27.34-25.97"
          />
          <path
            id="partofancon2"
            class="elbow_vd_left2211_cls1"
            d="M239.91,357.74c-36.77,22.2-37.42,84.36-12.46,124.95"
          />
          <path
            id="radius3"
            class="elbow_vd_left2211_cls1"
            d="M332.71,501.28c0,1.51-43.06,25.97-106.63,2.73"
          />
          <path
            id="ulnar4"
            class="elbow_vd_left2211_cls1"
            d="M201.47,562.8c3.23-3.94,6.64-9.55,6.84-16.41.19-6.74-2.91-8.56-4.1-17.77-1.46-11.27,2.54-13.43,0-19.14-3.69-8.3-15.26-10.67-23.24-12.3-6.06-1.24-16.06-3.29-25.97,1.37-7.37,3.46-6.37,6.72-15.04,12.3-11.11,7.15-22.36,7.98-25.97,8.2-6.47.4-16.95,1.05-27.34-5.47-9.16-5.74-13.39-14-15.04-17.77"
          />
          <path
            id="ulnar3"
            class="elbow_vd_left2211_cls1"
            d="M71.6,495.82c0,23.82,14.06,43.09,31.44,43.09"
          />
          <path
            id="ulnar2"
            class="elbow_vd_left2211_cls1"
            d="M71.6,469.84c23.37,4.06,34.45,55.58,38.47,88.8"
          />
          <line
            id="ulnar1"
            class="elbow_vd_left2211_cls1"
            x1="87.32"
            y1="421.57"
            x2="85.27"
            y2="456.17"
          />
          <line
            id="partofancon1"
            class="elbow_vd_left2211_cls1"
            x1="319.04"
            y1="367.31"
            x2="345.02"
            y2="385.08"
          />
          <line
            id="radius2"
            class="elbow_vd_left2211_cls1"
            x1="258.12"
            y1="688.58"
            x2="252.06"
            y2="714.55"
          />
          <line
            id="radius1"
            class="elbow_vd_left2211_cls1"
            x1="245.27"
            y1="611.4"
            x2="264.19"
            y2="662.6"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="radialheadhighlight"
          class="radiushead2211fill"
          style={{
            fill: props.colors.RadiusHead2211Fill,
            stroke: props.colors.RadiusHead2211Fill,
          }}
          d="M216.97,551.18c3.38,6.93,17.2,6.79,21.57,6.74,5.29-.05,9.02-.89,11.33-1.35,13.55-2.66,17.63.23,49.85-.26,3.67-.06,15.88-.36,19.25-6.14.36-.62,1.02-1.98,2.36-3.84,2.21-3.07,4.61-5.16,6.34-6.47,1.82-1.12,4.34-2.87,7-5.39,2.41-2.29,3.71-3.52,4.73-5.33,4.43-7.83-.82-18.41-2.02-20.83-.92-1.85-1.67-3.37-3.24-5.06-2.81-3.03-6.6-4.81-13.75-6.07-6.14-1.08-14.35-2.53-24.83-.24-9.94,2.17-18.56,5.1-18.56,5.1-2.47.84-5.31,1.88-9,1.41-1.16-.15-2.28-.43-8.49-2.83-6.07-2.35-5.53-2.28-6.67-2.63-6.34-1.95-12.1-.68-14.32-.16-3.23.75-8.17,1.9-12.01,5.83-2.83,2.9-4.05,8.84-6.37,20.6-.25,1.25-.99,6.23-2.49,16.2-1.25,8.35-1.36,9.31-.67,10.72Z"
        />
        <path
          id="jointlinehighlight"
          class="gelenkspalte2211fill"
          style={{
            fill: props.colors.Gelenkspalte2211Fill,
            stroke: props.colors.Gelenkspalte2211Fill,
          }}
          d="M73.09,470.05c-.71.77,8.42,7.31,7.03,14.07-.68,3.29-4.04,7.56-4.64,8.32-1.89,2.4-3.35,3.5-3.25,5.34.09,1.59,1.29,2.52,3.3,4.41,4.93,4.64,3.6,4.62,7.55,8.09,1.56,1.37,4.72,4.14,8.9,6.2,6.29,3.1,11.96,3.14,17.8,3.17,10.11.06,17.65-2.49,20.39-3.51,7.16-2.66,11.9-6.09,12.93-6.85,5.89-4.34,5.11-5.83,9.36-8.76,8.65-5.95,19.35-4.96,24.09-4.52,3.92.36,7.57,1.38,14.89,3.4,9.39,2.6,9.42,3.44,14.32,4.21,9.04,1.42,16.39-.29,18.29-.76,5.52-1.38,5.05-2.44,12.13-4.25,8.36-2.13,12.62-1.58,13.75-1.42,2.39.35,4.41,1,7.85,2.11,4.83,1.56,5.93,2.39,8.94,2.94,3,.55,5.51.38,8.49,0,10.15-1.31,13.02-3.49,22.85-5.26,8.99-1.62,14.3-1.18,15.98-1.01,2.74.28,5.39.55,8.7,1.62.53.17,2.61.86,6.07,2.02,3.3,1.11,3.55,1.2,4.04,1.13,3.21-.44,6.28-6.39,5.67-11.65-.74-6.42-7.1-12.96-12.74-11.73-3.25.7-5.18,3.74-5.93,5.12-11.89,7.57-22.05,9.29-28.04,9.71-9.44.65-17.95-1.28-26.24-3.17-11.46-2.61-13.58-4.69-23.11-5.46-4.62-.37-4.15.12-16.97-.19-8.11-.19-12.27-.3-17.28-.89-5.44-.64-10.42-1.22-16.72-3.24-3.32-1.06-6.82-2.46-12.13-4.58-6.93-2.77-8.58-3.67-11.6-3.51-3.81.21-6.54,1.96-9.71,4.04-5.61,3.69-6.81,6.54-11.6,11.06-5.11,4.82-9.89,7.37-13.75,9.44-5.31,2.83-11.92,6.28-21.04,6.98-5.69.44-8.54.66-11.56-.84-7.5-3.73-5.44-12.1-14.33-22.59-5.74-6.78-12.14-9.82-12.69-9.22Z"
        />
      </g>
    </svg>
  );
}
