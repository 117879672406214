import style from "./style.scss";

function LinkerUnterschenkelMitKnieVdStehend2309(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326.7 1066.45">
      <g id="underlay">
        <ellipse
          id="joint_gap_underlay"
          data-name="joint gap underlay"
          class="gelenkspalt2309fill"
          style={{
            fill: props.colors.gelenkspalt2309fill,
            stroke: props.colors.gelenkspalt2309fill,
          }}
          cx="154.87"
          cy="293.53"
          rx="142"
          ry="66.5"
        />
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <path
          id="background_lower_leg"
          data-name="background lower leg"
          class="linker_unterschenkelmitknie_vd_stehend2309_cls5"
          d="M99.57,639.13c-.6-15.2-.9-22.9-1.8-30.9,0,0-1.7-16.6-2-32-.2-13.7,2-12.5,2-25,0-15-3.2-19.2-7-42-3.8-22.6-.8-20.3-4-32-5.5-20-21-42-21-42-21.8-31.1-20.2-26-26-36-15.2-26.4-16-47-16-47-.4-9.6,1.1-16.1,3-21,2.7-6.8,5.7-9.7,6.6-10.4,4.7-4.2,10.2-5.1,13.4-5.6,25.8-3.9,41-5,41-5,8.8-.6,17.7-1.3,27-5,7.7-3,10.1-5.9,11-7,3.3-4.2,2.9-7.9,6-9,.8-.3,1.9-.3,10,3,5.9,2.4,6.8,3.9,10,4,3.7.1,4.6-1.9,10-4,3.4-1.3,9.6-3.7,15-2,5.2,1.7,4.6,5.5,12,12,.8.7,6.9,6,14,8,8.6,2.4,12.8-1.6,27-5,12-2.8,23.5-5.5,35-1,10.1,4,15.7,11.8,18,15,1.3,1.9,10.8,15.4,9,33-1.3,12.9-8.1,21.5-5,24,2.4,2,6.8-3.1,12-1,1.1.5,5.2,2.4,9,15,3.4,11.1,1.5,13.9,4,23,2.9,10.8,6.7,11.7,10,21,1.5,4.2,3.8,12.7,1,30-3.2,19.9-9.5,29.1-20,54-12.6,30-19.3,45.2-21,62-1,10.1.1,10.9-1.4,32-1.2,17.3-2.5,23.5-3.7,39.9-1.3,16.6-1.4,28.2-1.5,36.2-.9,44.5-5.7,72.8-10,154.8-1.8,33.9-1.9,47.6-4.4,83-1.8,25.6-2.6,38.4-4,51-3,27.7-4.5,29.4-4,42,.7,17.3,3.9,24.1-.9,29-7.5,7.6-28.2,3.9-33.1-6-1.7-3.5-.7-6.3,1-17,1.4-8.9,1.9-17.9,3.2-26.7,1.5-9.9,2-17.2,3.8-45.2,3-49,4.6-73.5,6-101,7-134.4,12.8-136.2,10.7-167.9h0c0-9.7,1.9-35,2.3-42.1,1-14.9.1-11.8,1-21,1.9-19.9,4.6-21,3-30-1.3-7-4-12-2.3-18.8.3-1.3,1.1-4.1,1.7-8.3.3-2,.5-4.4.5-7.8,0-5.2-.2-17.5-6-31-4.5-10.6-7.2-9.5-9-17-1.9-8,.5-11.3,3-30,1.2-9.5,2.8-21.5,1-22-2.1-.6-8.1,13.8-12,25-4.5,13.1-8.2,27.5-12,57-5.9,46.1-6,63-6,63-2.3,52.2-1.8,93-1.8,125.9,0,29.5-.3,7.8-2.2,152-.6,45.5-.9,73.8-1.9,99-.7,18.4-1.4,24.4-1.1,39,.3,21.9,2,24-.3,29-12,25.6-82.7,26.1-91.8,6-1.3-2.9-.4-4.2,0-19,.2-10.5-.1-14.9-.6-30-.7-24.2-.9-51.5-2.3-113-1.9-80.3-2.8-120.4-4.2-140-1.6-22.9-3.4-38.5-3.1-64,.3-14.8,1.1-26.2.2-47.1h0Z"
        />
        <g id="fibula">
          <path
            id="fibula_outlines"
            data-name="fibula outlines"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls1"
            d="M251.97,1048.13c2.1-20.9,28.4-459.7,31.8-473.9,5.1-21.3,8.6-35.7,17-54,8.7-18.8,15.5-26.2,21-47,4.4-16.7,3.5-24.9,3-28-1.9-12.6-6.6-12.6-11-30-2.8-11.3-2.1-16.5-7-27-3.2-6.9-5.4-11.5-10-13-1.5-.5-5.8-1.5-24,11-10.3,7.1-15.5,10.7-20,16-8.3,9.8-11.2,19.8-13,26-5.5,19.2.9,21.7-3,50-1.7,12.3-3.7,17.8-2,28,1.6,9.3,4.7,13.4,8,21,8,18.6,7,35.9,6,55-.6,10.1-25.2,443.9-29.9,459.9"
          />
          <path
            id="fibula_1"
            data-name="fibula 1"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M245.57,534.63c0-31.2-3.9-56.4-8.7-56.4"
          />
          <path
            id="fibula_2"
            data-name="fibula 2"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M313.77,438.23c-11.2,27.8-8.5,49.4-7,49"
          />
          <path
            id="fibula_3"
            data-name="fibula 3"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M245.57,534.63c20,31.3,12.1,77.8,7.3,84.6"
          />
          <path
            id="fibula_4"
            data-name="fibula 4"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M288.07,547.23c-15.2,40.4-25.5,76.7-27.2,80"
          />
        </g>
        <g id="tibia">
          <path
            class="linker_unterschenkelmitknie_vd_stehend2309_cls1"
            d="M109.77,1052.13c1.3-14.6-11.6-456.1-12-477.9-.2-12.1-.6-30.3-4-54-3.4-23.8-5.4-37.9-13-56-8.3-19.8-19.1-34.9-23-40-9.7-13-11.6-11.9-18-22-13.9-21.8-14.9-42-15-46-.4-12-.9-25.5,8-34,4.1-3.9,12.8-5.7,30-9,12.8-2.5,19.6-3.8,30-5,17.1-2,22.3-1.1,28-6,6.4-5.5,5.6-11.5,11-13,6.6-1.8,9.6,6.6,19,7,10.6.4,14.6-10.1,23-8,4.1,1,4.4,3.9,11,10,5.3,4.9,12.4,9.9,18,12,11.9,4.5,17-4,38-6,9.1-.9,25.6-2.5,37,7,1.9,1.6,9.2,8.1,12,25,6.3,38.2-19.1,75.5-33,96-12.8,18.8-20.4,24.8-28,44-4.8,12-6.9,22.7-10,38-2.8,14-4.9,27.6-7,48-1.9,18.5-9.2,454.8-10.2,483.9"
          />
          <path
            class="linker_unterschenkelmitknie_vd_stehend2309_cls1"
            d="M120.77,302.23c-.3,17.7-37.1,29.4-82,22"
          />
          <path
            class="linker_unterschenkelmitknie_vd_stehend2309_cls1"
            d="M202.77,310.23c.4,15.6,36.7,23.4,80.7,7.9"
          />
          <path
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M277.37,398.53c-8.8-22,.2-48.4,13.4-53.3"
          />
          <path
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M113.77,371.83c-8.4-6.6-33.1-4.9-60,0"
          />
          <path
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M260.77,363.23c-19.6,8.6-118.5-18-118.5-4"
          />
          <path
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M197.77,314.33c-23.1,20.9-72.2-18.1-77-12.1"
          />
          <path
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M109.77,634.23c0-94-13-170-29-170"
          />
        </g>
        <g id="knee">
          <path
            id="background_knee"
            data-name="background knee"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls5"
            d="M82.87,16.03c-1.7,3.1-.6,3.5-3,14-3.3,14.2-8,24.3-12,33-7.8,16.8-17.2,39-38.2,57.7-6.3,5.7-12.5,9.9-18.5,19.5-2.7,4.3-9.9,16.1-9.6,31.7.1,2.5.3,6.6,6.2,23.7,11.4,33.2,17.3,37.4,15.1,49.4-1.1,5.8-3.4,9.8-3.1,18.4.1,4.2.3,10.9,4.3,16.7,6.6,9.6,19.4,10.9,22.8,11.3,17.3,1.8,31.5-7.9,41-14.3,16.5-11.2,18.1-19.1,34.3-25.3,9.9-3.7,16.5-3.6,19.2-3.4,11.7.8,19.9,6.9,23.9,9.7,28.3,19.5,63.7,21.8,71.5,22.1,14.6.6,31.5,1.3,42-10.1,12.4-13.4,9.2-36.3,8.3-42.4-1.5-10.7-4-10.5-7.3-26.6-3.3-16-1.2-18.1-4-33-3-15.6-8-27.2-11-34-7.9-18-11.7-18-17.4-31.8-1.1-2.7-2-5.1-2.7-7.5-4.9-17-5.8-35.8-5.8-35.8-1.3-30.1-4.7-34.9-8-52-2.7-13.7-21.9-3.4-73-2-42.2,1.1-67.5-2.8-75,11h0Z"
          />
          <path
            id="femur_outlines"
            data-name="femur outlines"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls1"
            d="M82.87,16.03c-3.6,21.4-10.1,37-15,47-7.8,15.7-27.4,49-50,70-2.2,2-7.1,6.5-11,14-6.5,12.7-5.5,25.4-5,30,.9,9.1,3,10.1,11,32,6.5,17.8,9.8,26.7,10,33,.6,16.6-6.8,22.5-2,33,4.2,9.2,13.6,13,16,14,18.6,7.6,38.9-4.4,45-8,12.7-7.5,11.9-12,31-24,10.3-6.5,16.3-8.8,23-9,8.5-.3,14.5,2.9,21,6,17,8.2,35.8,15.9,49,20,10.1,3.1,23.5,7.1,41,6,12.2-.8,23.7-1.5,32-10,10.3-10.5,9.5-26.4,9-36-.7-14.1-4.7-14.1-8-33-3.6-20.3.4-24.2-4-41-1.9-7.3-4.9-13.5-11-26-13.2-27.1-18.8-30.4-23-46-2.4-8.9-1.3-10.6-3-29-1.1-12.6-3.4-30.4-8-52"
          />
          <path
            id="patella"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls1"
            d="M148.87,213.03c-7.5.7-8.5-.7-37-6-22.2-4.1-23.2-3.5-28-6-14.2-7.3-21-20.4-25-28-5.1-9.9-11.4-21.9-9-37,3.5-22.1,22.7-34.3,27-37,5.1-3.2,4.7-1.9,28-11,25.1-9.8,25.4-11.2,32-12,20-2.4,36.3,8.6,40,11,20.9,14,26.6,35.4,28,41,1.5,6.1,4.7,22.3-2,41-1.9,5.3-7.2,19.6-22,31-14.5,11.1-29.3,12.7-32,13Z"
          />
          <path
            id="femur_1"
            data-name="femur 1"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M279.87,201.03c-21.3,32.7-9.9,52.2-19,52"
          />
          <path
            id="femur_2"
            data-name="femur 2"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M279.87,201.03c17-17.5,0-13,0-35"
          />
          <path
            id="femur_3"
            data-name="femur 3"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M260.87,179.03c0,1.7-15.3-11-48,3"
          />
          <path
            id="femur_4"
            data-name="femur 4"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M174.87,213.23c-1,35.7,10.2,57,23,56.5"
          />
          <path
            id="femur_5"
            data-name="femur 5"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M74.87,173.03c-2.7-.3-29.1-.3-56,1"
          />
          <polyline
            id="femur_6"
            data-name="femur 6"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            points="169.87 191.03 157.77 187.03 142.37 182.03 120.87 186.53 101.87 182.03 101.87 191.03 101.87 213.23 95.37 227.03 104.87 241.43 107.07 260.83"
          />
          <path
            id="femur_7"
            data-name="femur 7"
            class="linker_unterschenkelmitknie_vd_stehend2309_cls3"
            d="M90.87,7.03c0,50.5-19.7,91.3-44,91.3"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="patella_overlay"
          data-name="patella overlay"
          class="patella2309fill"
          style={{
            fill: props.colors.patella2309fill,
            stroke: props.colors.patella2309fill,
          }}
          d="M148.87,213.03c-7.5.7-8.5-.7-37-6-22.2-4.1-23.2-3.5-28-6-14.2-7.3-21-20.4-25-28-5.1-9.9-11.4-21.9-9-37,3.5-22.1,22.7-34.3,27-37,5.1-3.2,4.7-1.9,28-11,25.1-9.8,25.4-11.2,32-12,20-2.4,36.3,8.6,40,11,20.9,14,26.6,35.4,28,41,1.5,6.1,4.7,22.3-2,41-1.9,5.3-7.2,19.6-22,31-14.5,11.1-29.3,12.7-32,13Z"
        />
      </g>
    </svg>
  );
}

export default LinkerUnterschenkelMitKnieVdStehend2309;
