import style from "./style.scss";

function RechtesDaumenSattelGelenkLatSitzend1314(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 165.8 512.8"
    >
      <g id="Thumb">
        <g id="Background">
          <path
            id="Background_white"
            data-name="Background white"
            class="daumensattelgelenk_rechts_1314_cls5"
            d="M30,511.5l62.1.9c5.1-26,16.9-50.8,34.7-67.2,5.3-10.3,7.6-20.5,3.5-30.6,4.5-.1,8.8-1.5,13-4.3,8-6,6-18.2-8.2-38.1l16.3-61.7,13.5-65.6-.7-20.6c-10.9,21.1-22.6,38.8-35.4,51.7-8.4-34,1.6-70,16-108.3,2.4-6.4,4.3-12.9,5.9-19.5,1.6-6.6,4.4-10.7,8.5-11.8l-3.1-33.4v-6.4c0-12.1-.5-23.4-2-33.5l-1.2-9.7c-16.6,2.8-33.2,25.9-52.9,72.9-10.5.4-20.5,3.3-30,8.4-6.2-6.4-8.5-14.6-7.4-24.3.4-12.7-3.4-35.1-3.4-35.1,2.7-3.2,5.5-9.4,6.1-15.6.6-17.9-.9-34.3-8.3-46.7C52.2,2.4,45.2-1.2,36.2.5c-9.5,1-11,9.9-10.2,29.7l-7.9,33.6c-3.8,7.8-5.4,19.8-5.4,34.7L1,159c-.9,8.9.5,20.1,4.9,34.1l8.9,62.5c2.9,15.6,6.2,31.1,10.7,46.3,2.9,8,6.6,14.8,13.7,17.1-1.2,6.8-4.3,11.2-9.4,13.1-7.4,4.5-.2,8.9-1.6,14.4,4.2,11.5,20.6,11.9,25.7,9.5h6.1s-.9,5.3-.9,5.3c-6.8,7,.3,18.6,12.3,31.8l-3.5.5-18.6-22.8c-10.9.3-14.2,12.4-14.2,29.6-7.8,20.7-8.8,46.6-3.1,77.6l-2.1,33.5h0Z"
          />
        </g>
        <g id="Thumb1">
          <g id="ThumbandFinger">
            <path
              id="fingerpart25"
              class="daumensattelgelenk_rechts_1314_cls2"
              d="M35.3,291.6h0c-7.5.3-10.4-8-10.9-21l-6-39.3c-4-13.9-6.4-29.5-7.8-46.2-2.5-8.2-8-16.3-2.1-23.9,2.9-3.3,6.9-5.9,12-8,3.2-1.3,6.8-1.7,10.2-1.1,5.9,1.2,10.5,4.6,12.9,12.3,3.7,5.7,3.2,10-1.9,13-1.4,4.8-1.6,9.4-.4,14,1.4,11.1,4,24.8,7,39.4,4.8,11.4,11.7,21.2,23.5,27.6l4.7,15.2c1.8,4.4.8,7.3-5.4,7-8.2-.9-15.9,2.5-23.1,10.3-3.5,4.3-6.9,5.3-10.1,1.8-1,.2-2.1-.3-2.5-1.3h0Z"
            />
            <path
              id="fingerpart24"
              class="daumensattelgelenk_rechts_1314_cls2"
              d="M11.3,145.3h0c-1.9-2-1.5-4.4.5-7.1.4-.5.7-1.1.9-1.8,4.4-15,6-31.7,6.3-49.2,0-1.1-.2-2.2-.6-3.2-1.9-5.2.2-9.8,6.8-13.7,3.5-4.3,8.4-5.2,14.2-3.9,4.6,2,7.4,5.1,7.8,9.5.4,3.7-.9,6.4-3.3,8.4-1.7,1.4-3,3.3-3.5,5.5-1.9,7.8-1.1,17.1,2.1,27.7l3,8.6c.9,2.7,2.3,5.2,3.8,7.6,3,4.5,3,9.2-.2,14-2.1,4.4-4.7,5.2-7.6,4.3-1.8-.6-3.7-.9-5.7-.8-6.2.3-11.6.9-15.4,2.3-2.7.9-5.7-.3-6.9-2.9-.3-.7-.6-1.5-.8-2.3-.2-1-.7-2-1.4-2.7h0Z"
            />
            <path
              id="fingerpart23"
              class="daumensattelgelenk_rechts_1314_cls2"
              d="M24.3,65.9h0c-1.8-2.9-1-5.2,1.2-7.2,4.5-10.2,5.4-21.1,5.4-32.3.4-2.8,1.1-5.3,2-7.7.3-.8.4-1.6.3-2.4l-.7-5.1c0-.2,0-.4.2-.6,2.5-2.5,5.1-2.8,7.7,1,4.2,4.7,5,10.4,4.1,16.7-.1,1,0,2.1.5,3,3.5,6.9,4.4,13.9,1.6,20.8,1.9-.2,3.4,1.5,3.9,6.8,0,3.9-1.3,5.7-3.1,6.2.1,3.2-1.3,5.2-4.3,6-6.6-4.4-12.9-6.2-18.7-5.2h0Z"
            />
            <path
              id="fingerpart22"
              class="daumensattelgelenk_rechts_1314_cls2"
              d="M38.6,164h0c-2.8-3.5-2.2-5.9,0-7.9,3-1.9,6.7-2.1,10.9-.7,1.9.6,3.2,2.4,3.1,4.4-.1,2.5-1.1,5.1-1.1,5.1,0,0-4.6,4.1-8.6,4.1-1.7-.4-3.1-2.2-4.3-4.9h0Z"
            />
            <path
              id="fingerpart21"
              class="daumensattelgelenk_rechts_1314_cls2"
              d="M61.8,161.6h0c-.9,5.3-3.9,6.6-7.9,6-3.4-2.1-3.7-4.7-3.5-7.4-.4-3.1.4-5.2,4.3-4.4.9.2,1.9.3,2.8.5,2.2.4,4,1.7,4.3,5.3h0Z"
            />
            <path
              id="fingerpart20"
              class="daumensattelgelenk_rechts_1314_cls2"
              d="M155.8,103c-8.3-4.7-16.9-5.8-25.8-2.7-11.1,4.7-17.9,11.2-16.9,21,2.2,3.1,2.1,7.3-1.5,13.2-4,30-8.5,57.8-14.2,77.5-5.5,24.7-12.8,41.4-22.3,47.8-3.9,4.9-5.2,9.8-3.7,14.6-3.5,7.9-5.3,14.9-2.7,19.6,3.3,2.9,7,2.3,11.1,0,6-1.2,10.9.5,14,7.6l3.5,4.7"
            />
            <path
              id="fingerpart19"
              class="daumensattelgelenk_rechts_1314_cls2"
              d="M154.4,65.9c-2.8,3.3-6.4,5.1-10.7,5.2-3.7-.6-5.9,1.4-7.2,5.1-2.6,6-2.6,11.5,3.1,15.9,4.1-.8,9.9,1.5,16.1,4.5"
            />
            <path
              id="fingerpart18"
              class="daumensattelgelenk_rechts_1314_cls2"
              d="M158.9,136.4c-3.4,1.1-6.1,3.9-7.8,9.5-9.6,28.4-18.2,55.6-24.1,79.7-2.8,12.4-2.8,28.9,1.9,52.5-1,5.7-1.4,11,.6,15,.6,4.9.6,9.5-1.2,13.2"
            />
            <path
              id="fingerpart17"
              class="daumensattelgelenk_rechts_1314_cls2"
              d="M164,225.3c-9.5,17.2-20.6,33.9-33,50.3-7.8,7.6-12.5,15.1-13.6,22.5l-4.1,8"
            />
            <path
              id="fingerpart16"
              class="daumensattelgelenk_rechts_1314_cls2"
              d="M143,101.6h0c.8,5.5-1.7,7.4-6.6,6.8-4.6-2-6.4-5.5-6.2-9.9,1.3-3.9,3.5-5.1,6.4-4.3,3.9,2.2,6.5,4.6,6.4,7.4h0Z"
            />
            <path
              id="fingerpart15"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M133.3,279.9c3-4,7.7-7.9,13.6-11.9,7.6-6.4,13.7-14.6,18.5-24.5"
            />
            <path
              id="fingerpart14"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M11.4,175c1.9,1.6,3.4,4,3.9,8.4,6.2,26,9.4,52.2,10.7,78.4"
            />
            <path
              id="fingerpart13"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M26,173.9c5.4,14.3,9.9,29.7,12.4,47.4,2.8,17.4,10.7,30.5,27.4,36.1"
            />
            <path
              id="fingerpart12"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M34.9,181.9c-.1-4.2,1.2-7.5,3.3-10.3"
            />
            <path
              id="fingerpart11"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M22.4,83.4c3.7,3.4,4,11.8,2,23.6-1.1,10-3.5,17.6-6.6,24.1"
            />
            <path
              id="fingerpart10"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M35.4,84.9c-3.8,13.8-2,27.1,6.2,39.7"
            />
            <path
              id="fingerpart9"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M39.2,84.9c1.3-5.5,1.3-10.2-2.6-12.8-3.4-2-7.2-2.5-11.3-1.9-2.9,1.3-3.9,3.8-3.6,7.3"
            />
            <path
              id="fingerpart8"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M26.7,59.6c7.2-7.8,10-22.4,8.9-43.2"
            />
            <path
              id="fingerpart7"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M40.3,17.3c.3,1.3.1,2.4-.5,3.3-.4.6-.7,1.3-.8,2-.4,4.5,0,8.9,2.2,13.1.3.6.6,1.3.7,2,.4,2.4.3,6.1.3,9.8,0,.5.1,1,.3,1.4.3.9.4,2.2.2,4.1-.1,1.5,0,3,.5,4.2.5,1,.8,2.1.8,3.3,0,1,.2,1.8.3,2.5-5-4.3-10.4-4.7-16.1-1.9"
            />
            <path
              id="fingerpart6"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M46.4,52c-2.2,0,.8,6.1-.4,7.5-.4,2.3,0,4.1,1.1,5.5"
            />
            <path
              id="fingerpart5"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M18.2,149.4c12.6-3,19.4-2.4,21,1.5"
            />
            <path
              id="fingerpart4"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M78.5,270.9c2.8-5.8,7.5-12.5,13.5-19.7,7.5-7.6,12.4-18.7,15.1-32.8,4.9-17.6,9.8-35.5,12.1-71.3.2-2.5-.1-5-.7-7.4-1.6-6.3-2-11.3.9-12.6,1.7-1,3.1-2.8,4-6.1.4-2.7,2.5-4.3,5-5.6"
            />
            <path
              id="fingerpart3"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M114.4,124.3c1.6-2,3.7-1.5,5.9-5.2.3-.6.6-1.2.7-1.9.4-3.3,8.1-7.2,7.3-13.3"
            />
            <path
              id="fingerpart2"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M158,122.1c-3.1,1.3-5.7,4.7-7.7,10.8l-5,17c-9,20.1-17,39-21.7,53.4-8.7,22.4-9.9,49.9-4.8,81.7"
            />
            <path
              id="fingerpart1"
              class="daumensattelgelenk_rechts_1314_cls3"
              d="M158,129.4c-1.8,0-3.6,2.3-5.4,7"
            />
            <g id="outlines3">
              <path
                class="daumensattelgelenk_rechts_1314_cls3"
                d="M25.6,301.9c-.4-1.2-.8-2.4-1.1-3.7"
              />
              <path
                class="daumensattelgelenk_rechts_1314_cls1"
                d="M22.4,290.7c-.3-1.3-.6-2.7-.9-4.1-10.1-35.7-8.1-58.3-18.6-104.7-2.5-10.7-3.6-21-1.2-30,6.4-23.1,10.9-47.3,11.7-73.6.1-4.3,1.1-8.6,2.9-12.6,5.8-12.5,9.5-30.1,10.2-54.6,0-2.4.9-4.8,2.5-6.6,3.1-3.5,8.1-4.7,15.2-3.7,7.2.5,13,8,17.4,22.4,4.2,19.1,5.5,35,1.3,45.4"
              />
              <path
                class="daumensattelgelenk_rechts_1314_cls3"
                d="M61.3,72.1c-.7,1.1-1.5,2.1-2.3,3,.4,1.1.8,2.4,1.1,3.7"
              />
              <path
                class="daumensattelgelenk_rechts_1314_cls1"
                d="M61.5,86.4c.8,6.6.9,14.4.3,23.3-.2,7.5.8,13.8,3.3,18.7"
              />
              <path
                class="daumensattelgelenk_rechts_1314_cls3"
                d="M67.1,131.8c.8,1,1.6,2,2.6,2.9,1.1-.7,2.2-1.3,3.3-1.9"
              />
              <path
                class="daumensattelgelenk_rechts_1314_cls1"
                d="M80.3,129.5c3.7-1.3,7.5-2.2,11.6-2.7"
              />
              <path
                class="daumensattelgelenk_rechts_1314_cls3"
                d="M95.8,126.3c1.3,0,2.5-.2,3.8-.2.5-1.2,1.1-2.3,1.6-3.5"
              />
              <path
                class="daumensattelgelenk_rechts_1314_cls1"
                d="M104.5,115.6c8.4-18.2,17-34.7,25.7-47.8,2.9-3.3,8.8-7,15.7-10.9"
              />
              <path
                class="daumensattelgelenk_rechts_1314_cls3"
                d="M149.2,55.1c1.1-.6,2.2-1.2,3.4-1.8"
              />
            </g>
            <g id="outlines2">
              <path
                id="outlinespart4"
                class="daumensattelgelenk_rechts_1314_cls3"
                d="M69.7,134.6c-1.1.6-2.2,1.2-3.4,1.9"
              />
              <path
                id="outlinespart3"
                class="daumensattelgelenk_rechts_1314_cls3"
                d="M62.1,139.3c-1,.7-2.1,1.5-3.1,2.3"
              />
            </g>
            <g id="outlines">
              <path
                id="outlinespart2"
                class="daumensattelgelenk_rechts_1314_cls3"
                d="M73.4,145.8c-.2-1.2-.5-2.4-1-3.7"
              />
              <path
                id="outlinespart1"
                class="daumensattelgelenk_rechts_1314_cls3"
                d="M71.5,139.7c-.5-1.1-1.1-2.2-1.8-3.4"
              />
            </g>
          </g>
          <path
            id="bonepart16"
            class="daumensattelgelenk_rechts_1314_cls2"
            d="M29.8,512.6c1.3-11,2.1-22.6,2.3-34.5-3.9-27.2-6.4-54,3.1-77.6-.2-15.3,2.6-27.3,14.1-29.6l18.1,22.3c14.8,0,27.5,3.1,36.2,11.9,12.8,3.7,20.3,6.8,24.2,9.6,6.9,6.1,5.5,16.5.3,29l-18.5,22.4c-7.1,10.3-12.6,27.3-17.6,46.5"
          />
          <path
            id="bonepart15"
            class="daumensattelgelenk_rechts_1314_cls2"
            d="M147.7,397.3h0c-.7-3.4-2.2-7.2-4.4-11.3-3.8-6.2-7.6-11.1-11.5-13.9-3.9-2.8-9-2.6-12.8.3l-9.4,7.4c-5.7,2.8-5.2,10.1-1.5,19.7-.7,9,.8,16.6,15.6,15.4,4.6.1,9.2-.5,13.7-1.6,7.1-1.8,11.8-8.9,10.3-16.1h0Z"
          />
          <path
            id="bonepart14"
            class="daumensattelgelenk_rechts_1314_cls2"
            d="M97.6,404h0c8.6.5,12.7-5,13.6-14.8-.9-11.4-3.5-21.6-9.2-29.7l-7.1-13.9c-2.7-4.9-7.5-6.6-15.9-2.8-9.7,2.3-17.3,7.3-19.8,18.5-4,5.5-3.8,12.5,5.3,22.6,10.4,13.1,21.4,20.1,33.1,20.1h0Z"
          />
          <path
            id="bonepart13"
            class="daumensattelgelenk_rechts_1314_cls2"
            d="M28.2,344.5h0c.6,9.9,9.8,12.8,22.1,12.9,1.9,0,3.6-.9,4.7-2.5,5.3-7.7,12.4-13.1,21.1-16.4,3.5-2,2.5-5.6-.5-9.9-1.4-2.1-3.1-4-5.1-5.7-3.3-2.9-6-7.5-8.3-13.1-1-2.4-2.4-4.6-4.3-6.3-3.6-3.1-8.1-3.9-14.4.8-3.7,4.1-4.3,14.7-4.3,14.7,0,0-1.5,4-2.4,5.8-1.5,3.1-4.1,5.4-7,7.2-2.9,1.8-4,4.4-2.7,7.8.6,1.5,1,3.1,1.1,4.7h0Z"
          />
          <path
            id="bonepart12"
            class="daumensattelgelenk_rechts_1314_cls2"
            d="M94.6,361.3h0c4.7,11.6,13.5,15,24.6,13.8,12,2.3,17.2-3.1,18.8-12.8l11.7-48c3.2-3.2.7-7.5-5.6-12.8-5.9-2.7-11.3-2.5-16,2.3-9.8-2-18,0-22.9,11.2-7,3.5-10.6,8.1-7.6,15,2.8,5.1,4.7,9.9.6,12.5-1.8,1.1-3,3-3.1,5.1l-.5,13.7h0Z"
          />
          <path
            id="bonepart11"
            class="daumensattelgelenk_rechts_1314_cls2"
            d="M85.3,337.5h0c6.4,1.9,8.2-2.5,7.6-10,5.5-14.7,14.2-18.2,1-22.3l-9.9-1.7c-5.4-3.2-10.5-1.3-15.3,4.1-6.8,5.7-8.1,11.1-2.8,16.4l3,8.7c1.6,4.6,8.1,5.6,16.6,4.9h0Z"
          />
          <path
            id="bonepart10"
            class="daumensattelgelenk_rechts_1314_cls2"
            d="M67.4,393.1c8.6,8.8,20.5,16.1,35.3,21.9,20,3,25.9,9.1,22.3,17.4l-4,11.9"
          />
          <path
            id="bonepart9"
            class="daumensattelgelenk_rechts_1314_cls3"
            d="M52.9,382.7c8.6,19.4,24,27.9,43.5,29.7"
          />
          <path
            id="bonepart8"
            class="daumensattelgelenk_rechts_1314_cls3"
            d="M139.2,380c-5.1-2.4-8.6-2.4-12.7,0-.9.5-2,.8-3,.9-5,.2-8.3-1.2-10.5-3.5"
          />
          <path
            id="bonepart7"
            class="daumensattelgelenk_rechts_1314_cls3"
            d="M90.5,341.1c-4.2,11.4-2.4,22.8,10.4,34"
          />
          <path
            id="bonepart6"
            class="daumensattelgelenk_rechts_1314_cls2"
            d="M73.8,339.6c-2.1,12.1-8.4,18-19.8,16.5"
          />
          <path
            id="bonepart5"
            class="daumensattelgelenk_rechts_1314_cls2"
            d="M94.9,353.7c2.4,1.9,4.7,3.8,7.1,5.8"
          />
          <path
            id="bonepart4"
            class="daumensattelgelenk_rechts_1314_cls3"
            d="M98.1,315.9c-11-8.2-22.1-11.4-33-2.8"
          />
          <path
            id="bonepart3"
            class="daumensattelgelenk_rechts_1314_cls2"
            d="M100.2,346.5c2.6-5,1.7-8.1,0-10.8"
          />
          <path
            id="bonepart2"
            class="daumensattelgelenk_rechts_1314_cls2"
            d="M124.9,312.1c-2.8.2-5.3-.5-7.6-2.1-.5-.4-1.2-.7-1.8-.7-3,0-6.6,2.5-10.3,5.7"
          />
          <path
            id="bonepart1"
            class="daumensattelgelenk_rechts_1314_cls3"
            d="M149.2,316.6c-7,6.3-11.5,19-10.2,41.7"
          />
        </g>
      </g>
      <g id="highlights">
        <ellipse
          id="highlights1"
          class="daumensattelgelenk1314fill"
          style={{
            fill: props.colors.daumensattelgelenk1314fill,
            stroke: props.colors.daumensattelgelenk1314fill,
          }}
          cx="54"
          cy="292.7"
          rx="33.4"
          ry="12.3"
          transform="translate(-83.5 28.8) rotate(-17.1)"
        />
      </g>
    </svg>
  );
}

export default RechtesDaumenSattelGelenkLatSitzend1314;
