import style from "./style.scss";

export default function LinkerKronenfortsatzMLsitzend2223(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303.8 440.12">
      <g id="Backround">
        <path
          id="backround"
          class="Kronenfortsatz_left2223_cls2"
          d="M294,412.1c-8.1-18.3-10.9-18.8-12.8-28.5-2.4-12.3,2-12.7,0-31.3-1.3-12-3.4-15.2-1.4-22.8,1.3-5,3.1-7,8.5-17.1,6.6-12.2,8.9-13.3,8.5-17.1-.6-7-8.3-13.7-15.7-14.2-5.7-.5-5.4,3.4-15.7,5.7-2.6.6-4.5.8-6.1.9-3.3,1-6.8,2-9.3,4.3-2,1.8-4.2,1.2-5.5-.4-1.8.9-2.8,1.4-4.2.9-3.7-1.5-5.1-9-3.4-14.6,1.5-5.1,5.5-8,6.8-9,3.2-2.4,6.3-3.1,7.5-3.5,7.9-2.2,14.2-7.1,21.4-12.8,5.9-4.7,14.8-11.9,18.5-22.8,3.4-10,.8-18.4-2.8-31.3-13.2-46.5-5.6-28.8-14.2-54.1-11.2-32.7-12.3-28.6-24.2-62.7-13.4-38.4-15.1-52.8-15.7-62.7-.7-11.7.5-13.6-1.3-18.1-39.4,0-78.8-.1-118.2-.9.1,4.7.7,11.2-1.6,23.3-1.9,10.2-3.2,10.4-7.1,25.6-2.1,8.2-3.2,13.8-4,18.4.2.5.3,1,.3,1.5,0,3.5-.5,6.7-2.2,9.7-1.4,7.3-3.6,14.3-7.4,20.7-.4.7-1,1.1-1.6,1.2-.5.9-1.1,2-1.8,3-1.2,4.2-3,8.2-5.6,11.8-.3.4-.6.7-1,.9-2.3,4.6-5.1,9-7.9,13.4-2,3.6-4,7.2-6.1,10.8-.8,1.3-1.9,1.5-3,1.2-4.7,7-8.9,12.4-13.5,17,0,1.1-.5,2.2-1.8,2.8-.8.4-1.7.8-2.5,1.2-1,.9-1.9,1.6-2.8,2.3,0,.7-.3,1.5-1,2.1-6.8,6-12.5,13.1-20.7,17.4-1.2.6-2.3.3-3-.4-13.6,11.3-16.6,15.1-18.4,20.4-4.5,13.2,1.9,26.5,2.8,28.5,6.8,13.8,17.3,14.6,18.5,25.6.7,6.5-2.9,7.2-4.3,17.1-.4,3.1-3,22,7.1,29.9l.1.1c.7.5,8.8,5.6,32.7,2.8h.2c1.6-.2,3-.4,3.8-.5.4-.1,1.6-.3,3.2-.6,0,0,2.8-.5,5.4-1.2,7.4-1.8,14.5-4.8,14.5-4.8,9.2-3.9,16-8.9,21.4-12.8,10-7.3,12.4-11,14.2-10,3,1.6,4.6,16.3-1.5,24.4-1.5,2-7.9,3.2-21.3,8.4-2.3.9-4.5,1.7-6.6,2.3,0,.9-.5,1.8-1.1,2.4-1.2,1.2-2.8,1.8-4,3-.2.2-.5.4-.7.5-.3,1.5-.3,3.1-.1,4.8.2,1.4-.6,2.5-1.6,3,.2,1.7.6,3.3,1.2,5,.4.5.8,1.1,1.1,1.6,4.1,2.3,8.4,4.3,11.9,5.7,3,1.2,5.7,2.3,8.1,3.2,3.2,0,6.3.2,9.4,1.2,1.8.5,2.2,2.1,1.7,3.5,6.8,3.1,11.5,5.9,17.8,10.9,23.4,18.5,36.8,39.4,47,55.5,7.6,12,10.9,19.4,13.3,24.6.2.5.8.9,1.4.9h104.1c.9-1,1.2-1.4,2.1-2.4-1.2-5.4-4.6-13.1-9.8-24.8Z"
        />
      </g>
      <g id="Processuscoronoideusml">
        <g id="Overview">
          <path
            id="partofancon2"
            class="Kronenfortsatz_left2223_cls4"
            d="M107.4,162c-82.1,55.6,6.4,58.3,0,58.3"
          />
          <path
            id="partofancon1"
            class="Kronenfortsatz_left2223_cls4"
            d="M40.9,261.7c-29.2-19.3-1.9-59.8-4.3-59.8"
          />
          <path
            id="ulna3"
            class="Kronenfortsatz_left2223_cls4"
            d="M240.9,293.1c0,6.3-7.4,5.7-17.6,5.7"
          />
          <path
            id="ulna2"
            class="Kronenfortsatz_left2223_cls3"
            d="M98.9,311c0-12.4,9.9-21.8,24.7-21.8"
          />
          <ellipse
            id="ulna1"
            class="Kronenfortsatz_left2223_cls2"
            cx="117.45"
            cy="310.46"
            rx="24.8"
            ry="13.4"
            transform="translate(-198.48 208.62) rotate(-51.41)"
          />
          <path
            id="outline3"
            class="Kronenfortsatz_left2223_cls4"
            d="M294.3,431.7c-4.1-6.2-4.1-11.9-5.6-17.6-11.4-45.6-25.6-85.5-51.3-123.9-8.5-11.4-8.5-25.6-8.5-38.5-1.4-11.4-1.4-22.8-5.7-32.8-5.7-12.8-14.2-25.6-27.1-31.3-2.8-1.4-7.1-2.8-10-4.3-14.2-5.7-28.5-5.7-42.7-2.8-12.8,1.4-25.6-1.4-38.5,2.8-8.5,2.8-14.2,15.7-5.7,21.4,7.1,5.7,17.1,7.1,18.5,15.7,2.8,18.5-5.7,38.5,1.4,55.5,2.8,5.7,8.5,5.7,14.2,7.1,21.4,4.3,41.3,0,62.7,0,8.5,0,15.7,5.7,22.8,8.5,2.8,1.4,5.7,4.3,4.3,7.1,0,1.4-1.4,2.8-2.8,2.8-11.4,0-21.4-1.4-32.8-4.3-5.7-1.4-11.4-1.4-12.8,5.7-1.4,8.5,1.4,18.5,8.5,21.4,19.9,10,32.8,28.5,29.9,49.9-2.8,15.7-2.8,29.9-2.8,45.6,0,7.1-2.8,12.8-4,19.8"
          />
          <path
            id="outline2"
            class="Kronenfortsatz_left2223_cls4"
            d="M302.5,430.1c-6.6-14.6-16.6-27.4-19.4-43.1-5.7-19.9-2.8-39.9-2.8-59.8,5.7-5.7,7.1-12.8,11.4-19.9l2.8-2.8c5.7-10-2.8-25.6-14.2-22.8-12.8,2.8-25.6,10-38.5,11.4-2.8,0-4.3-2.8-5.7-4.3-1.4-7.1,0-14.2,7.1-18.5,24.2-11.4,55.5-27.1,49.9-58.4-1.4-5.7-2.8-11.4-5.7-17.1-5.7-21.4-8.5-42.7-17.1-62.7-18.5-41.3-34.2-84-36.7-130.3"
          />
          <path
            id="outline1"
            class="Kronenfortsatz_left2223_cls4"
            d="M115.3,4.5c-.3,3.7-.3,9.4-.3,13.7-4.3,21.4-12.8,41.3-14.2,62.7-10,25.6-24.2,48.4-41.3,71.2-15.7,18.5-37,28.5-54.1,45.6-1.4,2.8-2.8,7.1-4.3,10-2.8,15.7,4.3,31.3,18.5,41.3,4.3,4.3,5.7,10,4.3,15.7-4.3,12.8-8.5,27.1,0,38.5,4.3,5.7,12.8,7.1,19.9,7.1,27.6,0,55.2-9.9,73.9-31.4,2.9.8,1.8.5,4.7,1.3,2.3,10.5,1.1,17.7-2.3,22.7-6.2,9.1-21.8,2.8-30.2,12.3-.6.7-6.4,7.2-4.8,13.5,1.8,7.3,12.7,10.9,21.1,12.8,5.6,1.3,10.9,3.9,15.4,7.5,20.7,16.7,44.4,24.7,76.4,90.9"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="processuscoronoideushighlight"
          class="Kronenfortsatz2223Fill"
          style={{
            fill: props.colors.Kronenfortsatz2223Fill,
            stroke: props.colors.Kronenfortsatz2223Fill,
          }}
          d="M117.51,305.54c.36-.55.84-1.16,1.29-1.08,1.05.19,1.02,3.99,1.27,7.35.65,8.94,2.75,8.93,3.44,18.1.37,4.97-.25,4.92.3,9.78.52,4.56,1.3,6.57.19,7.46-1.59,1.29-5.78-.65-8.54-2.18-1.13-.63-2.84-1.5-5-2.32-3.93-1.48-5.73-1.41-9.1-2.22-3.77-.92-9.78-2.37-13.55-7.1-1.8-2.25-2.58-4.62-2.96-6.23.06-1.27.28-3.08.98-5.13,1.33-3.92,3.58-6.28,4.07-6.78,2.28-2.33,4.64-3.39,5.79-3.89,3.4-1.48,6.04-1.59,8.46-1.89,3.22-.39,7.79-1.34,13.35-3.88Z"
        />
      </g>
    </svg>
  );
}
