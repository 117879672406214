import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";
const FootLeft = map.FootLeft;

function SideView({ selected, bpartSetter }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 638.41 363.22">
      <g id="foot">
        <g id="outline">
          <path
            id="outline_1"
            data-name="outline 1"
            class="lfoot-cls-1"
            d="M428.4,16.47c1.43,33.12-2.38,97.42-39.87,152.91-7.1,6.28-14.92,9.46-24,7.12l-27.75,13.5-12.38,2.62c-23.63,8.53-45.13,18.5-60.38,32.62-33.25,14.66-66.17,29.05-85.5,32.25-19.88-.08-36.67,2.12-44.63,10.87-14.67,.27-27.77,.8-37.5,1.87-6.73,1.95-12.32,4.15-16.13,6.75h-28.12c-6.51,3.27-10.16,7.9-2.25,18-.94,13.44,1.02,23.63,13.5,22.13,4.12,12.72,11.89,16.5,22.87,12.38,6.63,10.25,12.67,10.62,18.37,5.63,11.49,8.11,26.27,7.46,42.75,2.25,16.01-1.12,28.82,.25,39.37,3.38,58.3,6.08,121.07,4.82,153.75,3.38,22.69-1.87,48.06-5.78,75-10.87,10.83-8.15,41.29-7.44,78.38-3.75,41.73,3.16,73.87-6.38,91.13-18.37,18.02-16.41,24.37-40.67,18-73.5,1.68-27.57-4.36-44.99-15-56.38-6.69-25.32-13.84-52.37-21.37-80.88-5.04-22.69-5.67-60.25-5.16-87"
          />
        </g>
        <g id="leg">
          <path
            id="leg_3"
            data-name="leg 3"
            class="lfoot-cls-1"
            d="M491.75,14.78l12.28,170.57c-13.13,26.2-26.25,26.2-39.38,0,6.17-45.2,6.54-117.49,6.28-170.57"
          />
          <path
            id="leg_2"
            data-name="leg 2"
            class="lfoot-cls-1"
            d="M468.64,148.39c-9.29,6.06-21.03,9.34-30.79,4.48-12.33-7.49-10.33-19.94-6.56-33,13.18-22.45,13.01-68.73,13.22-103.41"
          />
          <path
            id="leg_1"
            data-name="leg 1"
            class="lfoot-cls-1"
            d="M499.03,119.54l13.26,26.21c8.33,14.96,5.77,19.5-6.63,14.62l-3.84-4.13"
          />
        </g>
        <g id="ankle">
          <path
            id="ankle_bone_2"
            data-name="ankle bone 2"
            class="lfoot-cls-1"
            d="M520.91,194.5c-1.11-2.26-6.35-8.68-17.81-21.39l.93,12.24c-14.35,27.37-27.37,25.04-39.38,0l3.72-33.88c-11.55,5.79-22.3,8.15-31.47,3.75-13.13,8.26-26.92,15.83-24.75,39.65,1.21,8.69,4.38,13.89,9,16.5,6.77,10.43,17.05,18.32,30.37,24,37.42-25.26,62.27-32.72,72-18.75-1.62-3.67-1.06-6.94,1.88-9.37,1.5-8.18-.21-12.16-4.5-12.75Z"
          />
          <path
            id="ankle_bone_1"
            data-name="ankle bone 1"
            class="lfoot-cls-1"
            d="M459.03,193.42c-3.5-7.17-3.5-18.06,0-32.67-17.52,5.31-31.31,12.77-37.5,24.6"
          />
        </g>
        <g id="heel_bone" data-name="heel bone">
          <path
            id="heel_bone_4"
            data-name="heel bone 4"
            class="lfoot-cls-1"
            d="M588.03,268.75c5.06-10.15,4.93-16.42,0-19.12l-3.37-23.63c-6.59-17.9-14.4-24.31-24-13.88-14.22,10.53-27.22,15.1-37.13,4.5-11.77-12.79-38.35-2.52-72,18.75l-10.85-5.54c-11.66-.16-21.99,.96-28.75,5.54,9.3,4.47,13.39,14.6,12.98,29.63-1.95,9.56-1.62,16.17,1.5,19.13,15.76,17.32,36.45,12.7,57.94,4.5,16.45-5.72,27.32-1.05,34.31,10.88,15.16,12.13,28.81,5.54,42-6.75,13.7-1.85,21.48-11.66,27.38-24Z"
          />
          <path
            id="heel_bone_3"
            data-name="heel bone 3"
            class="lfoot-cls-1"
            d="M522.41,249.63c-15.48,4.67-24.11,1.86-20.55-14.25"
          />
          <path
            id="heel_bone_2"
            data-name="heel bone 2"
            class="lfoot-cls-1"
            d="M468.64,247.75c5.12,1.06,8.74,3.98,9.14,10.88-9.75,3.2-19.51,2.5-29.26-2.1"
          />
          <path
            id="heel_bone_1"
            data-name="heel bone 1"
            class="lfoot-cls-1"
            d="M569.28,217.27c3.87,24.39,.42,45.18-18.75,58.23"
          />
        </g>
        <g id="tarsus">
          <path
            id="tarsus_5"
            data-name="tarsus 5"
            class="lfoot-cls-1"
            d="M391.53,193.42c-10.42-3.85-11.3,.54-4.5,11.58,6.83,5.35,9.68,12.4,9.75,20.63-.85,8.21,3.49,10.67,10.87,9.75,10.85-5.78,21.86-7.5,33.03-5.54-7.45-4.38-14.05-10.36-19.53-18.46-6.55-3.87-9.81-12.29-9.23-26.03-6.46,6.27-13.1,10.59-20.4,8.08Z"
          />
          <path
            id="tarsus_4"
            data-name="tarsus 4"
            class="lfoot-cls-1"
            d="M420.03,290.13c5.32,9.46,3.86,13.41-13.88,4.12-6.04-3.53-12-5.49-17.62,0-7.52-8.83-15.14-16.7-23.25-20.25-4.84-1.74-8.24-5.92-10.5-12-7.73-5.62-7.64-9.26,3.38-10.12,8.98-1.14,13.39-8.22,16.88-16.5,3.99-2.6,12.65-2.9,23.33-2.2,1.46,3.29,5.19,3.03,9.29,2.2,16.74,3.78,19.64,19.81,16.09,41.56l-3.72,13.19Z"
          />
          <path
            id="tarsus_3"
            data-name="tarsus 3"
            class="lfoot-cls-1"
            d="M379.91,198.63c-8.72-1.13-16.59,.03-23.63,3.37-9.46,1.13-8.95,7.25,0,17.62,11.44,4.95,17.02,10.24,18.75,15.75,4.74-4.06,17.1-1.83,21.39-2.52,3.3-.53-2.48-9.37,0-11.86-2-8.84-7.49-16.3-16.51-22.37Z"
          />
          <path
            id="tarsus_2"
            data-name="tarsus 2"
            class="lfoot-cls-1"
            d="M352.16,219.25c.09-3.48-1.99-7.76-10.12-14.25-11.63-.64-22.86,.19-31.88,9.37,1.1,5.92,3.09,11.35,8.25,15,3.35-3.55,14.79,1.66,18,0,4.26-2.2,5.87-9.84,15.75-10.12Z"
          />
          <path
            id="tarsus_1"
            data-name="tarsus 1"
            class="lfoot-cls-1"
            d="M368.14,246.83s10.79-14.53-.25-20.68c-11.61-6.52-15.06-4.96-15.74-3.15-6.41-1.45-12.21-.19-15.75,6.37-12.4-.05-19.78,2.71-22.88,7.87,11.52,4.15,21.22,9.12,24.75,16.88,4.11,3.56,8.38,3.03,12.78-.71l17.09-6.58Z"
          />
        </g>
        <g id="metatarsus">
          <path
            id="metatarsus_10"
            data-name="metatarsus 10"
            class="lfoot-cls-1"
            d="M387.23,297.25c13.09,22.96-4.76,23.24-33.2,15.75l-7.87-4.88c-11.94-3.25-27.15-5.36-96.75,11.62-7.16,6.86-14.19,11.93-20.25,3.37-8.62-3.94-11.66-9.2-10.87-15.38,2.36-5.13,9.33-5.94,19.13-4.12,10.06-3.33,33.01-7.6,57.75-12l36.49-5.25c8.02-6.74,16.99-9.88,26.51-10.87,15.05,.15,24.02,8.35,29.08,21.75Z"
          />
          <path
            id="metatarsus_9"
            data-name="metatarsus 9"
            class="lfoot-cls-1"
            d="M372.87,313c-11.02-4.2-17.39-8.67-18.08-13.46"
          />
          <path
            id="metatarsus_8"
            data-name="metatarsus 8"
            class="lfoot-cls-1"
            d="M340.91,301.56c-19.27-1.36-37.25-1.2-50.63,4.37"
          />
          <path
            id="metatarsus_7"
            data-name="metatarsus 7"
            class="lfoot-cls-1"
            d="M358.16,275.5c-.22-10.06-8.28-15.58-23.62-16.88-8.24-2.14-19.13,1.44-34.13,13.87-28.21,11.36-56.96,18.49-86.25,21.37-6.61-5.2-11.67-6.81-13.12,0-6.23,.8-11.58,2.13-6,9.87,2,2.3,5.38,3.35,10.87,2.52l13.55-.33"
          />
          <path
            id="metatarsus_6"
            data-name="metatarsus 6"
            class="lfoot-cls-1"
            d="M334.53,258.63c-.32-10.42-7.97-17.01-31.87-15.11-6.4,.11-11.99,1.23-14.25,6.5-23.7,11.69-46.18,21.6-64.5,25.48-14.06,.59-27.26,1.47-27.38,6.8-4.98,2.55-9.34,4.79-10.88,5.58-9.71-3.64-14.53-2.97-14.63,1.87,1.99,4.5,7.27,6.91,14.25,8.25,2.32,2.17,4.82,1.92,7.44,0"
          />
          <path
            id="metatarsus_5"
            data-name="metatarsus 5"
            class="lfoot-cls-1"
            d="M315.59,243.09c-4.55-10.06-9.01-15.55-13.31-13.38-5.32,.61-11.08,2.82-18,9.26-35.6,15.71-63,26.12-84,32.39-14.49,4.16-27.1,5.51-37.88,4.14-9.59,1.56-14.74,4.59-9,11.25,5.04,0,12.37,1.5,17.62,0"
          />
          <path
            id="metatarsus_4"
            data-name="metatarsus 4"
            class="lfoot-cls-1"
            d="M310.16,232.85c7,2.84,6.9-1.28,3.38-8.74-4.99-4.51-9.71-7.72-12.75-2.69-7.96,2.42-13.89,3.11-17.63,1.95-23.65,23.4-64.66,33.43-106.5,42.82-8.75,4.39-20.22,1.13-29.63,5.17-5.77,4.89-7.23,8.85,0,10.93h4"
          />
          <path
            id="metatarsus_3"
            data-name="metatarsus 3"
            class="lfoot-cls-1"
            d="M321.78,284.12c-24.99,.79-52.59,6.91-81.38,15.41"
          />
          <path
            id="metatarsus_2"
            data-name="metatarsus 2"
            class="lfoot-cls-1"
            d="M303.41,266.19c-24.24,4.87-46.75,10.3-63,17.93"
          />
          <path
            id="metatarsus_1"
            data-name="metatarsus 1"
            class="lfoot-cls-1"
            d="M281.09,247.75c-16.96,4.51-31.76,10.3-42.56,18.44"
          />
        </g>
        <g id="toes">
          <path
            id="toes_13"
            data-name="toes 13"
            class="lfoot-cls-1"
            d="M221.28,322c.59,7.5-4.16,7.5-14.25,0-12.48-1.69-24.52-3.32-36-4.88-4.43,4.44-8.37,6.57-10.5,0-6.87-2.94-5.88-6.62-1.87-10.57,13.61,.21,26.67,0,36.38-2.8-.27,2.87,8.94,2.92,19.5,2.8-1.08,4.53,1.83,9.8,6.75,15.45Z"
          />
          <path
            id="toes_12"
            data-name="toes 12"
            class="lfoot-cls-1"
            d="M176.56,295.2c-23.47,3.98-45.6,7.51-60.28,8.55-3.69,4.3-3.39,7.54,2.62,9.25,12.38,0,24.37-5.07,37.5-4.87l31.5-4.73c3.77-.8-.58-3.68-11.35-8.19Z"
          />
          <path
            id="toes_11"
            data-name="toes 11"
            class="lfoot-cls-1"
            d="M167.66,295.2c8.35-5.37,2.9-7.51-10.5-7.79l-52.5,7.79c-5.51,4.06-6.49,7.89,0,11.35l63-11.35Z"
          />
          <path
            id="toes_10"
            data-name="toes 10"
            class="lfoot-cls-1"
            d="M143.57,280.73l-41.91,3.4c-6.05,5.68-3.97,8.69,0,11.08l46.88-7.24c4.81-2.51,3.71-4.93-4.96-7.24Z"
          />
          <path
            id="toes_9"
            data-name="toes 9"
            class="lfoot-cls-1"
            d="M142.14,274c-9.07,1-20.93,2.3-37.48,4.12-5.56,1.81-5.82,3.84-3,6,0,0,41.25-1.73,39-3.4s1.48-6.73,1.48-6.73Z"
          />
          <path
            id="toes_8"
            data-name="toes 8"
            class="lfoot-cls-1"
            d="M160.53,317.12c1.11,5.83-1.23,7.71-8.98,3.37-7.48,1.39-13.96,2.38-17.65,2.25-4.22,.51-5.73-1.22-2.25-7.06,3.28-3.74,8.1-3.47,13.13-2.69l11.63-4.87c-2.6,3.65-1.04,6.62,4.12,9Z"
          />
          <path
            id="toes_7"
            data-name="toes 7"
            class="lfoot-cls-1"
            d="M116.28,313c-3.56-.82-5.47-2.8-4.12-7.06l-7.5,.61h-15c-2.73,3.56-.27,7.68,8.2,12.45,9.85-1.92,17.05-3.9,18.42-6Z"
          />
          <path
            id="toes_6"
            data-name="toes 6"
            class="lfoot-cls-1"
            d="M97.86,299.54c-2.36,.82-4.56-.45-6.7-2.55-4.62,1.14-8.73,1.53-12.38,1.28-5.72,1.83-6.34,4.71,4.12,9.87,3.39-2.85,10.19-2.35,16.99-1.86-2.31-.97-2.7-3.44-2.04-6.73Z"
          />
          <path
            id="toes_5"
            data-name="toes 5"
            class="lfoot-cls-1"
            d="M94.91,282.3c-1.52,8.59,.13,12.5,2.95,14.69-11.33,.99-23.19,2.03-29.2,2.55-6.15-5.44-9.94-10.22-1.5-11.57,9.71,1.77,18.82-1.26,27.75-5.67Z"
          />
          <path
            id="toes_4"
            data-name="toes 4"
            class="lfoot-cls-1"
            d="M109.53,319c-5.73,4.61-7.29,8.31,6.75,8.63,1.71-4.63,5.01-3.68,8.33-2.63,5.56,2.43,7.09,.88,7.04-2.24-1.87,.86-2.57-1.4-1.93-7.26-1.51,0-3.41-3.03-10.82,0l-9.38,3.5Z"
          />
          <path
            id="toes_3"
            data-name="toes 3"
            class="lfoot-cls-1"
            d="M87.3,308.12c0,3.34,6.25,6.79,6.25,8.13-6.61,2.39-17.78-1.86-21.9-8.13-1.15-2.1,4.06-2.1,15.64,0Z"
          />
          <path
            id="toes_2"
            data-name="toes 2"
            class="lfoot-cls-1"
            d="M59.28,290.92l5.1,7.95c-10.46-3.2-14.55-4.24-12.23-7.03,2.38-.95,4.75-1.22,7.13-.91Z"
          />
        </g>
      </g>
      <g id="lower_leg_ankle_joint" data-name="lower leg ankle joint">
        <path
          id="lower_leg_ankle_joint_overlay"
          data-name="lower leg ankle joint overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                FootLeft.id,
                FootLeft.children.lowerLegAnkleJoint
              ),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.lowerLegAnkleJoint
          )}`}
          d="M561.5,13.38c-.04,1.98-133.1,3.09-133.1,3.09,1.69,21.56-.21,43.87-5.09,66.8h140.6c-3.84-19.58-4.25-43.36-2.41-69.9Z"
        />
      </g>
      <g id="upper_ankle_joint" data-name="upper ankle joint">
        <path
          id="upper_ankle_joint_overlay"
          data-name="upper ankle joint overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.ankleBone),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.ankleBone
          )}`}
          d="M564.22,85.85c-55.93,0-109.89,0-141.43,0-4.03,18.32-8.86,35.38-15.59,49.52l27.17,16.11c6.14,6.14,26.28,4.92,34.26-3.08l-.26,3.08,34.72,21.64-1.28-16.86,3.84,4.13c12.24,4.13,14.47-.01,8.59-10.87l64.2-4.52c-5.62-19.47-10.35-39.19-14.22-59.14Z"
        />
      </g>
      <g id="ankle_bone_overlay" data-name="ankle bone overlay">
        <path
          id="ankle_bone_overlay_1"
          data-name="ankle bone overlay 1"
          // onClick={() => {
          //   bpartSetter({
          //     id: idToSet(selected, FootLeft.id, 328),
          //   });
          // }}
          class={`lfoot-cls-2 `}
          d="M525.41,207.25c1.47-8.16-.2-12.19-4.5-12.75-5.72-8.02-11.72-14.86-17.81-21.39l-34.72-21.64c-10.51,5.31-21.01,8.31-31.47,3.75-15.32,13.35-37.99,23.02-15.38,55.23,4.82,8.92,14.5,17.26,30,24.92,30.25-22.35,55.89-33.84,70.88-20.19-.97-2.94-.55-5.67,3-7.94Z"
        />
      </g>
      <g id="heel_bone_overlay" data-name="heel bone overlay">
        <path
          id="heel_bone_overlay_1"
          data-name="heel bone overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.heelBone),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.heelBone
          )}`}
          d="M584.66,226c-5.45-14.81-11.99-25.25-24-13.88-14.95,10.18-28.42,15.37-37.13,4.5-9.97-10.79-32.21-6.68-72,18.75l-12.49-6.54c-14.38,.65-22.63,3.05-27.11,6.54,13.89,9.57,15.89,24.99,11.22,43.71,15.05,22.35,34.69,18.04,54.64,11.92,20.21-8.98,33.18-4.98,40.87,8.49,14.63,11.68,28.49,6.21,42-6.75,27.92-14.3,36.37-34.74,27.11-45l-3.11-21.75Z"
        />
      </g>
      <g id="tarsus_overlay" data-name="tarsus overlay">
        <path
          id="tarsus_overlay_1"
          data-name="tarsus overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.tarsus),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.tarsus
          )}`}
          d="M420.03,290.13c7.66,11.65,1.5,12.05-16.87,2.53-6.06-3.08-11.01-2.76-14.63,1.6-8.43-11.1-16.24-18.24-23.25-20.25-5.55-1.6-9.26-5.35-10.5-12-3.55-1.62-5.29-3.45-5.4-5.48-3.96,.9-7.7,.38-11.1-2.4-5.88-6.7-14.8-12.02-24.75-16.88l4.88-7.87c-5.51-4.29-7.71-9.43-8.25-15,7.83-8.55,19.32-10,31.88-9.37h7.35c1.97-1.92,4.35-2.71,6.9-3,6.16-3.53,14.49-4.03,23.63-3.37l5.21,3.06c-6.82-12.99-.19-11.84,9.36-7.61,5.72,.88,11.57-3.31,17.45-8.73-1.35,11.46,1.23,20.44,9.23,26.03l16.52,16.57-30.02,7.43c17.52,3.59,21.49,22.01,12.37,54.75Z"
        />
      </g>
      <g id="metatarsus_overlay" data-name="metatarsus overlay">
        <path
          id="metatarsus_overlay_1"
          data-name="metatarsus overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.metatarsus),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(
            selected,
            FootLeft.children.metatarsus
          )}`}
          d="M387.23,297.25c-6.92-14.44-16.2-22.86-29.09-22.09-2.32-9.95-9.25-16.57-24.99-16.83,1.2-7.99-3.47-13.56-17.56-15.24l-5.44-10.24c6.67,2.43,7.04-1.03,4.2-7.49-5.35-5.21-10.49-9.41-13.57-3.94-6.52,2.08-12.59,3.15-17.63,1.95-23.34,21.6-58.57,36.04-106.5,42.82-10.13,1.18-20.53,1.77-29.63,5.17-7.81,6.35-6.33,9.96,4,10.93-.79,1.47,.46,2.96,2.37,4.45h17.62c.21,5.86,5.51,9.36,14.25,11.25,2.13,1.96,4.57,2.14,7.44,0,.24,9.6,9.47,10.03,21.16,7.94h4.29c.24,6.19,3.22,12.01,10.99,17.19,4.45,7.93,11.77,4.57,20.25-3.37,40.93-12.8,72.14-15.6,96.75-11.62l7.87,4.88c31.24,8.81,42.6,3.77,33.2-15.75Z"
        />
      </g>
      <g id="toes_overlay" data-name="toes overlay">
        <path
          id="toes_overlay_1"
          data-name="toes overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, FootLeft.id, FootLeft.children.toes),
            });
          }}
          class={`lfoot-cls-2 ${isSelected(selected, FootLeft.children.toes)}`}
          d="M221.28,322c.59,7.5-4.16,7.5-14.25,0l-36-4.88c-5.38,5.62-8.88,5.62-10.5,0,.88,6.76-2.49,7.34-8.98,3.37-7.16,2.12-13.91,3.17-19.9,2.26-.07,3.43-2.07,4.58-7.04,2.24-3.32-1.05-6.62-2-8.33,2.63-11.34,.63-14.91-1.73-6.75-8.63h-11.67s-4.31-2.74-4.31-2.74c-8.5,1.69-15.77-1.12-21.9-8.13,0-1.61,2.87-2.17,7.24-2.19-4.9-2.29-5.53-4.65-2.58-7.07l-7.67,.67c-7.81-1.65-13.4-3.23-15.85-4.7-1.78-1.68-1.64-3,0-4.03l6.47,.12c4.37-3.06,6.9-3.93,7.88-2.95,9.14,1.8,18.42-1.09,27.75-5.67,14.69-7.7,30.56-8.04,47.23-8.3-1.55,2.7-3.19,5.43-1.48,6.73,7.95-.23,10.16,2.39,7.87,7.24h16.86c7.71,2.08,6.94,4.57,2.26,7.24,2.39-.43,5.21-.49,8.23-.37,11.53,4.36,18.41,8.08,7.31,9.27l11.83-.35c-1.9,5.26,9,4.38,19.29,4.38,.24,5.4,3.26,9.77,6.96,13.88Z"
        />
      </g>
    </svg>
  );
}

export default SideView;
