import "./style/ExamStatistics.scss";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetQaChanges,
  resetRefetchFlag,
  saveQaChanges,
  selectExam,
} from "../../Redux/actions";
import { updateMasKvEntry } from "../../Utilities/Examinations/maskv";
import { getDDMMYYY_HHMM } from "../../Utilities/DateTimeUtils/DateTimeUtils";
import {
  ExamPlannerExaminationImage,
  ExamPlannerIndicationImage,
} from "../ExamPlannerV3/Components/ExamPlannerImage";
import ExamsListModal from "../ExamsListModal/ExamsListModal";

import {
  setQaSelectedExam,
  setQaSelectedImage,
  setQaSelectedSerie,
  setQaChanges,
} from "../../Redux/actions";

import PmIcon from "../PmIcon/PmIcon";
import ReactSpeedometer from "react-d3-speedometer";

import { Button } from "react-bootstrap";
import { yearsCalc } from "../../Utilities/Patients/PatientUtils";
import { useKeycloak } from "@react-keycloak/web";
import { NotificationManager } from "react-notifications";
import { useSubstantiations } from "../../Utilities/FetchHooks/Ris/RisHooks";
import ConfirmActionModal from "../ConfirmActionModal/ConfirmActionModal";
import {
  updateStudyEntry,
  updateStudyWorklistIndications,
} from "../../Utilities/Examinations/ExaminationsUtils";
import SubstantiationsModal from "./Components/SubstantiationsModal";
import IndicationsListModal from "../IndicationsListModal/IndicationsListModal";
import XrayIcon from "../PmedIcons/Xray";
import leying from "./patpositions/leying.svg";
import sitting from "./patpositions/sitting.svg";
import standing from "./patpositions/standing.svg";
import HelpModal from "../HelpModal/HelpModal";
import { ClipLoader } from "react-spinners";
import { useInfiniteWorklistStudiesList } from "../../Utilities/FetchHooks/Worklist/WorklistHooks";
import { updateImageEntry } from "../../Utilities/Examinations/Image";
import UniversalModal from "../UniversalModal/UniversalModal";
import { useStudyWorklist } from "../../Utilities/FetchHooks/Studies/StudiesHooks";

//its used to pick proper lang description
const selector =
  window.conf.LANG === "DE"
    ? "designationde"
    : window.conf.LANG === "PL"
    ? "designationpl"
    : "designationed";

const substantiationSelector =
  window.conf.LANG === "DE"
    ? "description_de"
    : window.conf.LANG === "PL"
    ? "description_pl"
    : "description_en";

const queryMask =
  "total_count,studies{patient{*},patientsweight,patientssize,id,serie_collection{seriesdescription,protocolname,laterality,image_collection{mas_kv_collection{*},imgdetails_collection{*},image_id,dose_qa_status}}}";

function PatientStudyRow({ exam }) {
  const dispatch = useDispatch();
  const selectedExam = useSelector((state) => state.xmQA.selectedExam);
  const selectedImage = useSelector((state) => state.xmQA.selectedImage);

  const [isExpanded, setIsExpanded] = useState(false);
  const isSelected = selectedExam?.id === exam.id;

  useEffect(() => {
    if (!isSelected) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, [isSelected, selectedExam]);

  const getDescription = (serieDes = "", imgDesc = "") => {
    let descOut = serieDes.length > 0 ? serieDes : imgDesc;
    if (serieDes !== imgDesc) {
      descOut = `${serieDes} [${imgDesc}]`;
    }
    return descOut;
  };

  const shouldShow = useMemo(() => {
    let notEmpty = false;
    exam?.serie_collection?.forEach((serie) => {
      !notEmpty &&
        serie?.image_collection.forEach((image) => {
          if (image?.mas_kv_collection.length > 0) {
            notEmpty = true;
          }
        });
    });
    return notEmpty;
  }, [exam]);

  if (shouldShow) {
    return (
      <div className={`exam-qa-exam-row ${isSelected ? "selected" : ""}`}>
        <div className="exam-header" onClick={() => setIsExpanded(!isExpanded)}>
          <i className="fas fa-mars fa-lg" />
          <h5 className="p-0 m-0">
            {exam?.patient?.patientsname} {exam?.patient?.patientsvorname}
          </h5>
          <div className="d-flex align-items-right justify-content-end py-1 px-2">
            <i className="fas fa-ellipsis-v fa-sm" />
          </div>
        </div>
        {isExpanded && (
          <div className="qa-images-box">
            {exam?.serie_collection?.map((serie) =>
              serie?.image_collection?.map(
                (image) =>
                  image?.mas_kv_collection.length > 0 && ( //JS: dirty not dirty idk api stills returns empty mas_kv entries
                    <div
                      className={`${
                        selectedImage?.image_id === image.image_id
                          ? "selected"
                          : ""
                      }`}
                      key={`image-qa-key-${image?.image_id}`}
                      style={{ gap: "5px", position: "relative" }}
                      onClick={() => {
                        dispatch(setQaSelectedExam(exam));
                        dispatch(setQaSelectedSerie(serie));
                        dispatch(setQaSelectedImage(image));
                      }}
                    >
                      <div className="status-check">
                        <i
                          title={`${
                            image.dose_qa_status === 0
                              ? "Image not verified"
                              : ""
                          } ${
                            image.dose_qa_status === 1 ? "Image Verified" : ""
                          } ${
                            image.dose_qa_status === 2 ? "Image Approved" : ""
                          }`}
                          className={`fas fa-check ${
                            image.dose_qa_status === 0 ? "text-danger" : ""
                          } ${
                            image.dose_qa_status === 1 ? "text-warning" : ""
                          } ${
                            image.dose_qa_status === 2 ? "text-success" : ""
                          }`}
                        />
                      </div>
                      <div style={{ maxWidth: "70px" }}>
                        {image?.mas_kv_collection[0]?.exams?.id ? (
                          <ExamPlannerExaminationImage
                            examId={image?.mas_kv_collection[0]?.exams?.id}
                            width="70px"
                            style={{ maxHeight: "70px" }}
                          />
                        ) : (
                          <XrayIcon variant="light" />
                        )}
                      </div>
                      <div className="qa-image-desc-date-box">
                        <h5
                          className="p-0 m-0"
                          style={{ wordBreak: "break-all" }}
                        >
                          {getDescription(
                            serie.seriesdescription,
                            image?.imgdetails_collection[0]?.imagedescription
                          )}
                        </h5>
                        <div className="mr-auto small ml-auto">
                          {getDDMMYYY_HHMM(serie?.seriesdatetime)}
                        </div>
                      </div>
                    </div>
                  )
              )
            )}
          </div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
}

function EiTiGaugeBox() {
  const selectedImage = useSelector((state) => state.xmQA.selectedImage);
  const ei = selectedImage?.mas_kv_collection[0]?.exposureindex;
  const ti = selectedImage?.mas_kv_collection[0]?.targetexposureindex;
  const di = selectedImage?.mas_kv_collection[0]?.deviationindex;
  return (
    <div id="eiti">
      <h5>Abweichungsindex</h5>
      <div id="meter-gauge">
        <div className="d-flex flex-column align-items-center">
          <ReactSpeedometer
            value={di}
            segments={3}
            forceRender={window.conf.DEBUG_MODE}
            segmentColors={["#ffea00", "#0af70e", "#ff0800"]}
            labelFontSize="0px"
            minValue={di < 0 ? di - 10 : di * -1 - 10}
            maxValue={di < 0 ? di * -1 + 10 : di + 10}
            width={150}
            height={75}
            fluidWidth={false}
          />
          <h5>{di ? Math.round((di + Number.EPSILON) * 100) / 100 : "nd"}</h5>
        </div>
        <div
          className="d-flex flex-column"
          style={{
            gap: "5px",
          }}
        >
          <div className="d-flex justify-content-between rounded align-items-center bg-exams-qa-edit">
            <div className="p-1">EI</div>
            <div className="p-1">
              {ei ? Math.round((ei + Number.EPSILON) * 100) / 100 : "nd"}
            </div>
          </div>
          <div className="d-flex justify-content-between rounded align-items-center bg-exams-qa-edit">
            <div className="p-1">TI</div>
            <div className="p-1">
              {ti ? Math.round((ti + Number.EPSILON) * 100) / 100 : "nd"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function IndicationBox() {
  ///FOR REFACTOR !!!
  ///THIS WHOLE COMPONENT IS A MESS BUT NO CLUE ATM HOW TO MAKE IT BETTER DUE
  ///TO SUBOPTIMAL DATA STRUCTURES
  const selectedExam = useSelector((state) => state.xmQA.selectedExam);
  const changes = useSelector((state) => state.xmQA.changes);
  const dispatch = useDispatch();

  const [indicationsModalShown, setIndicationsModalShown] = useState(false);
  const [selectedIndications, setSelectedIndications] = useState([]);

  const [indImgId, setIndImgId] = useState(0);

  const studyWorklist = useStudyWorklist({
    studyId: selectedExam?.id,
    enabled: selectedExam?.id > 0,
  });

  const setValuePushChanges = (valueKey, value) => {
    if (!selectedExam?.id) {
      return;
    }

    let updateObj = {
      id: selectedExam.id,
    };
    updateObj[valueKey] = value;

    if (!changes) {
      dispatch(
        setQaChanges({
          qm: updateObj,
        })
      );
      return;
    }
    if (!changes.qm) {
      let changesCp = { ...changes };
      changesCp["qm"] = updateObj;
      dispatch(setQaChanges(changesCp));
      return;
    }
    let updatedChanges = { ...changes };
    updatedChanges.qm[valueKey] = value;
    dispatch(setQaChanges(updatedChanges));
  };

  const handleIndicationSelection = (indicationObj) => {
    let selectedIndicationsCP = [...selectedIndications];
    let idx = selectedIndicationsCP.findIndex(
      (indication) => indication.id === indicationObj.id
    );
    if (idx < 0) {
      selectedIndicationsCP.push(indicationObj);
    } else {
      selectedIndicationsCP.splice(idx, 1);
    }

    setValuePushChanges("indications", selectedIndicationsCP);
    setSelectedIndications(selectedIndicationsCP);
  };

  const changeImage = (indicationList = null) => {
    let indications = indicationList ? indicationList : selectedIndications;

    if (indications.length === 0) {
      setIndImgId(0);
      return;
    }
    if (indications.length > 0 && indications.length < 2) {
      if (indImgId === 0) {
        setIndImgId(indications[0].id);
      }
      return;
    }
    if (indImgId === 0) {
      setIndImgId(indications[0].id);
      return;
    }
    let idx = indications.findIndex((indication) => indication.id === indImgId);
    if (idx < indications.length - 1) {
      setIndImgId(indications[idx + 1].id);
      return;
    } else {
      setIndImgId(indications[0].id);
      return;
    }
  };

  const getIndications = () => {
    let wqEntries = studyWorklist?.data?.worklistqmauftraege_collection;
    let indications = [];
    if (wqEntries && wqEntries.length > 0) {
      wqEntries.forEach((wqEntry) => {
        let wqIndications = wqEntry.indications;
        if (wqIndications.length > 0) {
          wqIndications.forEach((indication) => {
            indications = indications.concat(indication.indications);
          });
        }
      });
    }
    if (indications.length > 0) {
      setSelectedIndications(indications);
      changeImage(indications);
    }
  };

  useEffect(() => {
    if (!changes) {
      studyWorklist.refetch();
      getIndications();
    }
  }, [changes]);

  useEffect(() => {
    setSelectedIndications([]);
    getIndications();
  }, [selectedExam]);

  useEffect(() => {
    getIndications();
  }, [studyWorklist.data]);

  useEffect(() => {
    const interval = setInterval(changeImage, 2000);
    return () => clearInterval(interval);
  }, [selectedIndications, indImgId]);

  return (
    <div className="selection-box">
      <IndicationsListModal
        shown={indicationsModalShown}
        onHide={() => setIndicationsModalShown(false)}
        dark={true}
        selectIndiation={handleIndicationSelection}
        selectedIndications={selectedIndications}
      />
      <ExamPlannerIndicationImage indId={indImgId} alt="indImg" width="120px" />
      <div>
        <div className="d-flex flex-column">
          <h5>Current Indications:</h5>
          <div>{studyWorklist.isRefetching && "loading"}</div>
          <ul style={{ maxHeight: "80px", overflowY: "auto" }}>
            {selectedIndications.map((indication) => (
              <li key={`selectedindicationkey${indication.id}`}>
                {indication[selector]}
              </li>
            ))}
          </ul>
        </div>
        <button
          className="show-more-btn"
          onClick={() => setIndicationsModalShown(true)}
        >
          Select Indications
        </button>
      </div>
    </div>
  );
}

function SubstantiationBox() {
  const selectedExam = useSelector((state) => state.xmQA.selectedExam);
  const selectedImage = useSelector((state) => state.xmQA.selectedImage);
  const changes = useSelector((state) => state.xmQA.changes);
  const dispatch = useDispatch();

  const selectedSubstantiation =
    changes?.maskv?.substantiation_id > 0
      ? changes?.maskv?.substantiation_id
      : selectedImage?.mas_kv_collection[0]?.substantiation?.id > 0
      ? selectedImage?.mas_kv_collection[0]?.substantiation?.id
      : 0;

  const substantiationSelectedQ = useSubstantiations({
    pageSize: 1,
    pageNr: 1,
    filter: { field: "id", op: "==", value: selectedSubstantiation },
    enabled: selectedSubstantiation > 0,
  });

  const bmi = useMemo(() => {
    const calculateBmi = (w, h) => {
      w = parseFloat(w);
      h = parseFloat(h);
      if (w > 0 && h > 0) {
        return (
          Math.round((w / Math.pow(h / 100, 2) + Number.EPSILON) * 100) / 100
        );
      }
    };
    if (!selectedExam && !changes) {
      return "nd";
    }
    let weight = changes?.study?.patientsweight
      ? changes?.study?.patientsweight
      : selectedExam?.patientsweight;
    let height = changes?.study?.patientssize
      ? changes?.study?.patientssize
      : selectedExam?.patientssize;

    if (weight && height) return calculateBmi(weight, height);
    return "nd";
  }, [selectExam, changes]);

  const [substantiationModalShown, setSubstantiationModalShown] =
    useState(false);

  const setSubstantiationPushChanges = (value) => {
    if (typeof value === "object") {
      setSubstantiationModalShown(false);
      if (selectedSubstantiation === value.id) {
        return;
      }
      value = value.id;
    }
    value = parseInt(value);
    if (!selectedImage?.mas_kv_collection[0]?.id) {
      return;
    }
    let updateObj = {
      id: selectedImage?.mas_kv_collection[0]?.id,
      substantiation_id: value,
    };

    if (!changes) {
      dispatch(
        setQaChanges({
          maskv: updateObj,
        })
      );
      return;
    }
    if (!changes.maskv) {
      let changesCp = { ...changes };
      changesCp["maskv"] = updateObj;
      dispatch(setQaChanges(changesCp));
      return;
    }
    let updatedChanges = { ...changes };
    updatedChanges.maskv["substantiation_id"] = value;
    dispatch(setQaChanges(updatedChanges));
  };

  return (
    <div className="selection-box">
      <SubstantiationsModal
        substantiationModalShown={substantiationModalShown}
        setSubstantiationModalShown={setSubstantiationModalShown}
        substantiationSelected={selectedSubstantiation}
        setSubstantiationSelected={setSubstantiationPushChanges}
        dark={true}
      />
      <div className="d-flex flex-column align-items-center">
        {/* <img src={exampleImage} alt="indImg" width="80px" /> */}
        <i className="fas fa-male fa-4x" />
        <div>BMI={bmi}</div>
      </div>
      <div>
        {substantiationSelectedQ.status === "success" &&
          substantiationSelectedQ.data?.substantiations.length > 0 &&
          substantiationSelectedQ.data?.substantiations.map((sub) => (
            <div className="d-flex flex-column">
              <h5>Current Substantiation</h5>
              <ul>
                <li>{sub[substantiationSelector]}</li>
              </ul>
            </div>
          ))}
        {selectedSubstantiation < 1 && (
          <div className="d-flex flex-column">
            <h5>No Substantiation selected</h5>
          </div>
        )}
        <button
          className="show-more-btn p-2"
          onClick={() => setSubstantiationModalShown(true)}
        >
          Select Substantiation
        </button>
      </div>
    </div>
  );
}

function QaControllBtns() {
  const { keycloak } = useKeycloak();

  const changes = useSelector((state) => state.xmQA.changes);
  const selectedImage = useSelector((state) => state.xmQA.selectedImage);
  const unsavedCount = useSelector((state) => state.xmQA.unsavedCount);
  const dispatch = useDispatch();

  const [confirmResetModalShown, setConfirmResetModalShown] = useState(false);

  const yesResetCallback = () => {
    dispatch(resetQaChanges());
    setConfirmResetModalShown(false);
  };

  const noResetCallback = () => {
    setConfirmResetModalShown(false);
  };

  const [confirmSaveModalShown, setConfirmSaveModalShown] = useState(false);
  useEffect(() => {
    unsavedCount > 0 &&
      confirmSaveModalShown === false &&
      setConfirmSaveModalShown(true);
  }, [unsavedCount]);

  const yesSaveCallback = () => {
    const { maskv, study, qm } = changes;
    let promises = [];
    if (maskv && maskv.id) {
      promises.push(
        updateMasKvEntry(keycloak, maskv.id, maskv)
          .then(() => {
            NotificationManager.success(
              "Dose informations updated successfully"
            );
          })
          .catch(() => {
            NotificationManager.error("Unable to update dose informations");
          })
      );
    }
    if (study && study.id) {
      promises.push(
        updateStudyEntry(keycloak, study.id, study)
          .then(() => {
            NotificationManager.success(
              "Patient informations updated successfully"
            );
          })
          .catch(() => {
            NotificationManager.error("Unable to update patient informations");
          })
      );
    }
    if (qm && qm.id) {
      promises.push(
        updateStudyWorklistIndications(keycloak, qm.id, qm)
          .then(() => {
            NotificationManager.success("Indications updated successfully");
          })
          .catch(() => {
            NotificationManager.error("Unable to update indications");
          })
      );
    }
    //move changes to selected image to clear temp changes and allow user to switch to next image
    //without loosing data then call refetch in backgroud so next time one goes back to same image
    //changes will be present
    Promise.all(promises)
      .then(() => {
        updateImageEntry(keycloak, selectedImage.image_id, {
          dose_qa_status: window.conf.EXAMS_STATS.STAGING_MODE ? 1 : 2,
        }).then(() => {
          NotificationManager.success(
            window.conf.EXAMS_STATS.STAGING_MODE
              ? "Image staged as verified"
              : "Image approved"
          );
        });
        dispatch(saveQaChanges());
      })
      .finally(() => {
        //close modal after all actions done to avoid confusion
        setConfirmSaveModalShown(false);
      });
  };

  const noSaveCallback = () => {
    setConfirmSaveModalShown(false);
    if (window.conf.EXAMS_STATS.SWITCH_EXAM_MODE !== 1) {
      dispatch(resetQaChanges());
    }
  };

  return (
    <div id="controll-container">
      <ConfirmActionModal
        shown={confirmResetModalShown}
        variant="dark"
        onHide={() => setConfirmResetModalShown(false)}
        noHandler={noResetCallback}
        yesHandler={yesResetCallback}
      />
      <ConfirmActionModal
        shown={confirmSaveModalShown}
        variant="dark"
        onHide={() => setConfirmSaveModalShown(false)}
        noHandler={noSaveCallback}
        yesHandler={yesSaveCallback}
        actionText={"You have unsaved changes do you want to save them?"}
      />
      {window.conf.EXAMS_STATS.SWITCH_EXAM_MODE === 1 && (
        <Button
          variant="outline-danger"
          disabled={!changes}
          onClick={() => setConfirmResetModalShown(true)}
        >
          <i className="fas fa-ban mr-2 fa-lg" />
          Reset
        </Button>
      )}
      <Button
        variant="outline-success"
        disabled={!changes}
        onClick={() => setConfirmSaveModalShown(true)}
      >
        <i className="fas fa-save mr-2 fa-lg" />
        Save
      </Button>
    </div>
  );
}

function PatientParametersEditBox() {
  const dispatch = useDispatch();
  const selectedExam = useSelector((state) => state.xmQA.selectedExam);
  const selectedImage = useSelector((state) => state.xmQA.selectedImage);
  const changes = useSelector((state) => state.xmQA.changes);

  const getValue = (selector) => {
    if (!selectedExam) {
      return;
    }
    if (changes?.study && changes?.study[selector] !== undefined) {
      return changes.study[selector];
    }
    if (selectedExam[selector] !== undefined) {
      return selectedExam[selector];
    }
    return 0;
  };

  const getValueMasKv = (selector) => {
    if (changes?.maskv && changes?.maskv[selector] !== undefined) {
      return changes.maskv[selector];
    }
    if (
      selectedImage?.mas_kv_collection.length > 0 &&
      selectedImage?.mas_kv_collection[0][selector] !== undefined
    ) {
      return selectedImage.mas_kv_collection[0][selector];
    }
    return "";
  };

  const setValuePushChanges = (valueKey, value) => {
    if (!selectedExam?.id) {
      return;
    }
    if (isNaN(value)) {
      value = ""; //looks rubbish but sets input to empty value
    }

    let updateObj = {
      id: selectedExam.id,
    };
    updateObj[valueKey] = value;

    if (!changes) {
      dispatch(
        setQaChanges({
          study: updateObj,
        })
      );
      return;
    }
    if (!changes.study) {
      let changesCp = { ...changes };
      changesCp["study"] = updateObj;
      dispatch(setQaChanges(changesCp));
      return;
    }
    let updatedChanges = { ...changes };
    updatedChanges.study[valueKey] = value;
    dispatch(setQaChanges(updatedChanges));
  };

  const setValuePushChangesMasKv = (valueKey, value) => {
    if (isNaN(value)) {
      value = ""; //looks rubbish but sets input to empty value
    }
    if (!selectedImage?.mas_kv_collection[0]?.id) {
      return;
    }
    let updateObj = {
      id: selectedImage?.mas_kv_collection[0]?.id,
    };
    updateObj[valueKey] = value;

    if (!changes) {
      dispatch(
        setQaChanges({
          maskv: updateObj,
        })
      );
      return;
    }
    if (!changes.maskv) {
      let changesCp = { ...changes };
      changesCp["maskv"] = updateObj;
      dispatch(setQaChanges(changesCp));
      return;
    }
    let updatedChanges = { ...changes };
    updatedChanges.maskv[valueKey] = value;
    dispatch(setQaChanges(updatedChanges));
  };

  return (
    <div id="patient-data-edit">
      <div className="edit-row">
        <div>
          <PmIcon iconName="scale" size="md" />
        </div>
        <div className="d-flex flex-column align-items-center">
          <input
            className="exam-qa-dose-input"
            type="number"
            placeholder="kg"
            value={getValue("patientsweight")}
            onChange={(e) => {
              setValuePushChanges("patientsweight", e.target.valueAsNumber);
            }}
          />
          <h6>weight</h6>
        </div>
        <div className="text-center">
          <h4>kg</h4>
        </div>
      </div>
      <div className="edit-row">
        <div>
          <PmIcon iconName="ruller" size="md" />
        </div>
        <div className="d-flex flex-column align-items-center">
          <input
            className="exam-qa-dose-input"
            type="number"
            placeholder="cm"
            value={getValue("patientssize")}
            onChange={(e) => {
              setValuePushChanges("patientssize", e.target.valueAsNumber);
            }}
          />
          <h6>height</h6>
        </div>
        <div className="text-center">
          <h4>cm</h4>
        </div>
      </div>
      <div className="edit-row">
        <div>
          <PmIcon iconName="diameter" size="md" />
        </div>
        <div className="d-flex flex-column align-items-center">
          <input
            className="exam-qa-dose-input"
            type="number"
            placeholder="cm"
            value={getValueMasKv("obj_diameter")}
            onChange={(e) =>
              setValuePushChangesMasKv("obj_diameter", e.target.valueAsNumber)
            }
          />
          <h6>diameter</h6>
        </div>
        <div className="text-center">
          <h4>cm</h4>
        </div>
      </div>
    </div>
  );
}

function QaDoseEdit() {
  const dispatch = useDispatch();
  const selectedImage = useSelector((state) => state.xmQA.selectedImage);
  const changes = useSelector((state) => state.xmQA.changes);

  const setValuePushChanges = (valueKey, value) => {
    if (isNaN(value)) {
      value = ""; //looks rubbish but sets input to empty value
    }
    if (!selectedImage?.mas_kv_collection[0]?.id) {
      return;
    }
    let updateObj = {
      id: selectedImage?.mas_kv_collection[0]?.id,
    };
    updateObj[valueKey] = value;

    if (!changes) {
      dispatch(
        setQaChanges({
          maskv: updateObj,
        })
      );
      return;
    }
    if (!changes.maskv) {
      let changesCp = { ...changes };
      changesCp["maskv"] = updateObj;
      dispatch(setQaChanges(changesCp));
      return;
    }
    let updatedChanges = { ...changes };
    updatedChanges.maskv[valueKey] = value;
    dispatch(setQaChanges(updatedChanges));
  };

  const getValue = (selector) => {
    if (changes?.maskv && changes?.maskv[selector] !== undefined) {
      return changes.maskv[selector];
    }
    if (
      selectedImage?.mas_kv_collection.length > 0 &&
      selectedImage?.mas_kv_collection[0][selector] !== undefined
    ) {
      return selectedImage.mas_kv_collection[0][selector];
    }
    return "";
  };

  const { doseTextColorClass, kvTextColorClass, masTextColorClass } =
    useMemo(() => {
      let colors = window.conf.EXAMS_STATS;
      function getDoseColor() {
        let dose = getValue("dosis");
        if (
          !dose ||
          dose <= 0 ||
          !selectedImage?.mas_kv_collection[0]?.exams?.cgyqm ||
          selectedImage?.mas_kv_collection[0]?.exams?.cgyqm <= 0
        ) {
          return "";
        }

        let factor = dose / selectedImage?.mas_kv_collection[0]?.exams?.cgyqm;
        if (factor <= colors.GREEN_VALUE / 100) {
          return "text-success";
        }
        if (
          factor > colors.GREEN_VALUE / 100 &&
          factor <= colors.H_YELLOW_VALUE / 100
        ) {
          return "text-warning";
        }
        if (factor > colors.H_YELLOW_VALUE / 100) {
          return "text-danger";
        }
      }
      function getKvColor() {
        let kv = getValue("kv");

        let kvfrom = selectedImage?.mas_kv_collection[0]?.exams?.kvfrom;
        let kvtill = selectedImage?.mas_kv_collection[0]?.exams?.kvtill;
        if (
          !kv ||
          kv <= 0 ||
          !kvfrom ||
          kvfrom <= 0 ||
          !kvtill ||
          kvtill <= 0
        ) {
          return "";
        }

        let factorLow = kv / kvfrom;
        let factorHigh = kv / kvtill;

        if (
          factorLow >= colors.GREEN_VALUE / 100 &&
          factorHigh <= colors.GREEN_VALUE / 100
        ) {
          return "text-success";
        }
        if (factorLow < 1) {
          if (
            factorLow < colors.GREEN_VALUE / 100 &&
            factorLow >= colors.L_YELLOW_VALUE / 100
          ) {
            return "text-warning";
          }
          if (factorLow < colors.L_YELLOW_VALUE / 100) {
            return "text-danger";
          }
        }
        if (factorHigh > 1) {
          if (
            factorHigh > colors.GREEN_VALUE / 100 &&
            factorHigh <= colors.H_YELLOW_VALUE / 100
          ) {
            return "text-warning";
          }
          if (factorHigh > colors.H_YELLOW_VALUE / 100) {
            return "text-danger";
          }
        }
      }
      function getMasColor() {
        let mas = getValue("mas");

        let masfrom = selectedImage?.mas_kv_collection[0]?.exams?.masfrom;
        let mastill = selectedImage?.mas_kv_collection[0]?.exams?.mastill;
        if (
          !mas ||
          mas <= 0 ||
          !masfrom ||
          masfrom <= 0 ||
          !mastill ||
          mastill <= 0
        ) {
          return "";
        }

        let factorLow = mas / masfrom;
        let factorHigh = mas / mastill;

        if (
          factorLow >= colors.GREEN_VALUE / 100 &&
          factorHigh <= colors.GREEN_VALUE / 100
        ) {
          return "text-success";
        }
        if (factorLow < 1) {
          if (
            factorLow < colors.GREEN_VALUE / 100 &&
            factorLow >= colors.L_YELLOW_VALUE / 100
          ) {
            return "text-warning";
          }
          if (factorLow < colors.L_YELLOW_VALUE / 100) {
            return "text-danger";
          }
        }
        if (factorHigh > 1) {
          if (
            factorHigh > colors.GREEN_VALUE / 100 &&
            factorHigh <= colors.H_YELLOW_VALUE / 100
          ) {
            return "text-warning";
          }
          if (factorHigh > colors.H_YELLOW_VALUE / 100) {
            return "text-danger";
          }
        }
      }

      return {
        doseTextColorClass: getDoseColor(),
        kvTextColorClass: getKvColor(),
        masTextColorClass: getMasColor(),
      };
    }, [changes, selectedImage]);

  const { targetDose, kvRange, masRange } = useMemo(() => {
    let targetDose =
      selectedImage?.mas_kv_collection[0]?.exams?.cgyqm > 0
        ? selectedImage.mas_kv_collection[0].exams.cgyqm
        : "- -";

    let kvRange = `${
      selectedImage?.mas_kv_collection[0]?.exams?.kvfrom > 0
        ? selectedImage.mas_kv_collection[0].exams.kvfrom
        : "nd"
    } - ${
      selectedImage?.mas_kv_collection[0]?.exams?.kvtill > 0
        ? selectedImage.mas_kv_collection[0].exams.kvtill
        : "nd"
    }`;
    let masRange = `${
      selectedImage?.mas_kv_collection[0]?.exams?.masfrom > 0
        ? selectedImage.mas_kv_collection[0].exams.masfrom
        : "nd"
    } - ${
      selectedImage?.mas_kv_collection[0]?.exams?.mastill > 0
        ? selectedImage.mas_kv_collection[0].exams.mastill
        : "nd"
    }`;

    return { targetDose, kvRange, masRange };
  }, [selectedImage]);

  return (
    <div id="dose-data-edit">
      <div className="edit-row">
        <div>
          <PmIcon iconName="radioactiveGr" size="md" />
        </div>
        <div className="d-flex flex-column align-items-center">
          <input
            className="exam-qa-dose-input"
            type="number"
            placeholder="uGy"
            value={getValue("dosis")}
            onChange={(e) =>
              setValuePushChanges("dosis", e.target.valueAsNumber)
            }
          />
          <h6>{targetDose}</h6>
        </div>
        <div className={`text-center ${doseTextColorClass}`}>
          <h4>uGy</h4>
          <h4>m2</h4>
        </div>
      </div>
      <div className="edit-row">
        <div>
          <PmIcon iconName="thunderGr" size="md" />
        </div>
        <div className="d-flex flex-column align-items-center">
          <input
            className="exam-qa-dose-input"
            type="number"
            placeholder="kV"
            value={getValue("kv")}
            onChange={(e) => setValuePushChanges("kv", e.target.valueAsNumber)}
          />
          <h6>{kvRange}</h6>
        </div>
        <div className={`${kvTextColorClass}`}>
          <h4>kV</h4>
        </div>
      </div>
      <div className="edit-row">
        <div>
          <PmIcon iconName="atom" size="md" />
        </div>
        <div className="d-flex flex-column align-items-center">
          <input
            className="exam-qa-dose-input"
            type="number"
            placeholder="mAs"
            value={getValue("mas")}
            onChange={(e) => setValuePushChanges("mas", e.target.valueAsNumber)}
          />
          <h6>{masRange}</h6>
        </div>
        <div className={`text-center ${masTextColorClass}`}>
          <h4>mAs</h4>
        </div>
      </div>
    </div>
  );
}

function ExamHeader() {
  const { keycloak } = useKeycloak();
  const [harmonizationModalShown, setHarmonizationModalShown] = useState(false);

  const dispatch = useDispatch();

  const selectedExam = useSelector((state) => state.xmQA.selectedExam);
  const selectedSerie = useSelector((state) => state.xmQA.selectedSerie);
  const selectedImage = useSelector((state) => state.xmQA.selectedImage);

  const studyDescription = useMemo(() => {
    if (!selectedExam || !selectedSerie || !selectedImage) {
      return undefined;
    }
    if (
      selectedImage?.mas_kv_collection[0]?.exams &&
      selectedImage?.mas_kv_collection[0]?.exams[selector] != undefined
    ) {
      return selectedImage?.mas_kv_collection[0]?.exams[selector];
    }

    if (selectedSerie?.protocolname.length > 0) {
      if (selectedSerie?.seriesdescription === selectedSerie?.protocolname) {
        return `${selectedSerie.protocolname} / ${selectedSerie.laterality}`;
      } else {
        return `${selectedSerie.protocolname} / ${selectedSerie.seriesdescription} / ${selectedSerie.laterality}`;
      }
    } else {
      return `${selectedSerie.seriesdescription} / ${selectedSerie.laterality}`;
    }
  }, [selectedImage, selectedSerie, selectedExam]);

  const harmonizeImage = (exam) => {
    if (exam.id !== selectedImage?.mas_kv_collection[0]?.exams?.id) {
      updateMasKvEntry(keycloak, selectedImage.mas_kv_collection[0].id, {
        id: selectedImage?.mas_kv_collection[0].id,
        examid: exam.id,
      })
        .then(() => {
          let changedImage = { ...selectedImage };
          Object.assign(changedImage.mas_kv_collection[0].exams, exam);
          dispatch(setQaSelectedImage(changedImage, true)); //updates currently used image to not force reloading
          NotificationManager.success("Image harmonized successfully");
        })
        .catch(() => {
          NotificationManager.error("Unable to harmonize this image");
        });
    }
  };

  const posMap = {
    1: leying,
    2: standing,
    3: sitting,
  };

  const getPosIcon = (posId) => {
    let icn = posMap[posId];
    if (icn) {
      return icn;
    } else {
      return posMap[1];
    }
  };

  return (
    <div id="qa-exam-header">
      <ExamsListModal
        shown={harmonizationModalShown}
        hideHandler={() => setHarmonizationModalShown(false)}
        selectHandler={harmonizeImage}
        dark={true}
      />
      <div>
        <ExamPlannerExaminationImage
          examId={selectedImage?.mas_kv_collection[0]?.exams?.id}
          width="100px"
          style={{ maxHeight: "150px" }}
        />
      </div>
      <div className="text-center">
        <h1
          id="exam-desc"
          onClick={() => {
            setHarmonizationModalShown(!harmonizationModalShown);
          }}
        >
          {studyDescription ? studyDescription : "- -"}
        </h1>
        <h6 className="text-secondary">
          {`${selectedExam?.patient?.patientsname} ${
            selectedExam?.patient?.patientsvorname
          } - ${selectedExam?.patient?.gebdat} (${yearsCalc(
            selectedExam?.patient?.gebdat
          )} years)`}
        </h6>
      </div>
      <div>
        <img
          src={getPosIcon(selectedImage?.mas_kv_collection[0]?.exam?.patpos)}
          alt="patient position"
          width="150px"
        />
      </div>
    </div>
  );
}

function ViewControlls({ isExpanded, shrinkExpand }) {
  const [helpModalShown, setHelpModalShown] = useState(false);
  return (
    <div
      className="d-flex align-items-center p-2"
      style={{
        gap: "10px",
      }}
    >
      <HelpModal
        shown={helpModalShown}
        onHide={() => setHelpModalShown(false)}
        dark={true}
      >
        <div>HELP WILL BE HERE</div>
      </HelpModal>
      <button className="view-controll-btns" onClick={shrinkExpand}>
        <i
          className={`fas fa-${
            isExpanded ? "expand" : "compress"
          }-arrows-alt fa-3x`}
        />
      </button>
      <button
        className="view-controll-btns"
        onClick={() => {
          setHelpModalShown(true);
        }}
      >
        <i className="fas fa-life-ring fa-3x" />
      </button>
    </div>
  );
}

function ExamQA2() {
  const dispatch = useDispatch();
  const refetch = useSelector((state) => state.xmQA.refetch);

  const [qaStatuses, setQaStatuses] = useState([0]);

  const filter = useMemo(() => {
    if (qaStatuses.length > 1) {
      return {
        and: [
          { model: "Study", field: "modality", op: "in", value: ["CR", "DX"] },
          {
            model: "Worklist",
            field: "dose_qa_status",
            op: "in",
            value: qaStatuses,
          },
        ],
      };
    }
    if (qaStatuses.length === 1) {
      return {
        and: [
          { model: "Study", field: "modality", op: "in", value: ["CR", "DX"] },
          {
            model: "Worklist",
            field: "dose_qa_status",
            op: "==",
            value: qaStatuses[0],
          },
        ],
      };
    }
    if (qaStatuses.length === 0) {
      return {
        model: "Study",
        field: "modality",
        op: "in",
        value: ["CR", "DX"],
      };
    }
  }, [qaStatuses]);

  const exams = useInfiniteWorklistStudiesList({
    ignoreEmptyImageMasKv: true,
    pageSize: 10,
    filter: filter,
    xmask: queryMask,
  });

  const [listExpanded, setListExpanded] = useState(true);

  const [filterModalShown, setFilterModalShown] = useState(false);
  const addRemoveFilterId = (id) => {
    let statusesCp = [...qaStatuses];
    if (statusesCp.indexOf(id) > -1) {
      statusesCp.splice(statusesCp.indexOf(id), 1);
      setQaStatuses(statusesCp);
      return;
    }
    statusesCp.push(id);
    setQaStatuses(statusesCp);
  };

  useEffect(() => {
    exams.refetch();
    dispatch(resetRefetchFlag());
  }, [refetch]);

  return (
    <div className={`stats-wrapper ${!listExpanded && "list-hidden"}`}>
      <UniversalModal
        shown={filterModalShown}
        onHide={() => setFilterModalShown(false)}
        headerTxt="Select filters"
        variant="dark"
      >
        <div className="d-flex flex-column py-2">
          <div>
            <input
              type="checkbox"
              checked={qaStatuses.includes(0)}
              onChange={() => addRemoveFilterId(0)}
            />
            <label className="ml-2">
              Show Unverified
              <i className="fas fa-check ml-2 text-danger" />
            </label>
          </div>
          {window.conf.EXAMS_STATS.STAGING_MODE && (
            <div>
              <input
                type="checkbox"
                checked={qaStatuses.includes(1)}
                onChange={() => addRemoveFilterId(1)}
              />
              <label className="ml-2">
                Show Verified
                <i className="fas fa-check ml-2 text-warning" />
              </label>
            </div>
          )}
          <div>
            <input
              type="checkbox"
              checked={qaStatuses.includes(2)}
              onChange={() => addRemoveFilterId(2)}
            />
            <label className="ml-2">
              Show Approved
              <i className="fas fa-check ml-2 text-success" />
            </label>
          </div>
        </div>
      </UniversalModal>
      <div className="select-box">
        <div className="select-box-item text-center">
          <div>Röntgen</div>
        </div>
        <div
          className="select-box-item mb-2 text-center"
          onClick={() => {
            setFilterModalShown(true);
          }}
        >
          <div>
            <i className="fas fa-filter mr-2" />
            Filters
          </div>
        </div>
        {exams.status === "success" &&
          exams.data?.pages[0]?.studies?.length > 0 &&
          exams.data.pages.map(
            (page) =>
              page?.studies.length > 0 &&
              page.studies.map((examination) => (
                <PatientStudyRow
                  exam={examination}
                  key={`examination-qa-row-key-${examination?.id}`}
                />
              ))
          )}

        {(exams.isLoading || exams.isFetchingNextPage) && (
          <div className="d-flex w-100 align-items-center flex-column">
            <ClipLoader size="50px" />
          </div>
        )}
        <div className="d-flex w-100 justify-items-center">
          <Button
            variant="dark"
            className="w-100"
            disabled={!exams.hasNextPage}
            onClick={() => {
              if (exams.hasNextPage && !exams.isFetchingNextPage) {
                exams.fetchNextPage();
              }
            }}
          >
            Load More
          </Button>
        </div>
      </div>
      <div className="qa-box">
        <ExamHeader />
        <div id="qa-exam-editor">
          <QaDoseEdit />
          <PatientParametersEditBox />
          <div id="subs-box-edit">
            <IndicationBox />
            <EiTiGaugeBox />
            <SubstantiationBox />
          </div>
        </div>
        <div id="qa-exam-controll">
          <ViewControlls
            isExpanded={listExpanded}
            shrinkExpand={() => setListExpanded(!listExpanded)}
          />
          <QaControllBtns />
        </div>
      </div>
    </div>
  );
}

export default ExamQA2;
