import style from "./style.scss";

function LendenwirbelsauleMitAngestellterKassetteLatLiegend1728(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496.94 895.78"
    >
      <g id="BG">
        <path
          id="BG20"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M243.9,42.14c-3.98,2.03-6.79,6.13-7.52,10.25-.84,4.77,1.68,7.42,2.73,12.3.48,2.22.99,6.42-4.78,20.51-3.94,9.63-8.63,21.06-19.14,34.86-6.28,8.24-9.72,8.92-10.25,13.67-1.17,10.33,11.65,19.61,15.72,22.56,5.66,4.1,11.34,6.78,45.11,15.04,27.37,6.69,28.58,6.09,34.86,9.57,9.75,5.4,16.24,12.02,21.19,9.57,3.65-1.8,3.73-7.18,4.78-13.67,2.03-12.54,7.05-22.03,17.09-41.01,10.19-19.26,17.77-30.76,17.77-30.76,3.05-4.62,5.93-8.69,6.84-15.04,1.16-8.11-1.06-18.77-7.52-21.87-3.92-1.88-7.56-.09-12.99,1.37,0,0-17.12,3.81-34.86,0-6.72-1.44-4.67-2.14-23.92-8.2-7.59-2.39-8.78-2.55-11.62-4.1-9.09-4.95-8.99-9.84-17.09-13.67-2.71-1.28-9.9-4.69-16.41-1.37h.01Z"
        />
        <path
          id="BG19"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M194.01,169.28c-4.96,2.35-6.7,9.59-7.52,12.99-1.49,6.21-1.37,17.09-1.37,17.09,0,0-1.74,6.59-2.73,9.57-1.91,5.76-2.79,6.91-4.1,10.94-1.35,4.15-1.16,5.17-2.73,13.67-1.78,9.62-2.68,14.43-4.1,17.77-2.39,5.6-3.73,5.32-8.2,13.67-3.94,7.36-5.23,11.01-5.47,14.35-.93,13.18,15.97,17,18.46,15.04,9.24-7.29,15.29-1.72,31.44-1.37,13.3.29,23.76,4.06,31.44,6.84,15.68,5.66,19.76,12.63,30.76,11.62,2.24-.21,10.84-.99,14.35-6.84,3.14-5.24.68-19.14.68-19.14,0,0,2.64-5.36,6.84-16.41,4.47-11.77,2.43-11.92,6.15-19.82,4.07-8.62,6.89-9.27,9.57-17.77,1.48-4.7.84-5.22,2.73-10.94,2.93-8.86,5.18-9.78,4.78-13.67-.64-6.34-7.47-12.43-12.3-11.62-.2.03-.75.14-.76.31-.03.61,6.54,1.6,6.6,1.33.04-.18-2.69-1.5-5.84-1.64-4.38-.19-5.5,2.1-14.06,4.89-.68.22-3.87,1.11-7.15,1.32-.8.05-1.64.03-2.71-.06-5.77-.51-5.62-1.26-16.41-3.42-12.03-2.41-13.22-1.68-18.46-3.42-6.88-2.28-9.66-5.14-17.09-10.25-17.6-12.12-26.55-18-32.81-15.04h0Z"
        />
        <path
          id="BG18"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M125.65,376.39c1.51-7.55,4.24-18.44,7.52-43.75.33-2.56,1.07-8.46,5.47-11.62,5.47-3.93,13.32-1.01,17.77,0,32.73,7.42,29.29,4.63,51.95,9.57,9.98,2.17,20.32,5.92,20.32,5.92,12.21-1.86,14.63-1.76,12.21-1.86-3.08-.13,15.6-4.55,20.25-3.68,2.26.42,4.77-.34,6.38,0,3.59.76,5.63,5.29,4.86,7.29-1.6,4.17-5.01,11.65-6.61,14.2-9.11,14.62-12.26,32.21-17.77,48.53-4.34,12.85-6.36,16.89-4.78,23.24,1.49,6.02,4.87,8.71,4.1,14.35-.73,5.35-4.72,10.02-8.89,12.3-8.04,4.41-17.03.11-28.03-3.42-14.83-4.76-26.02-5.75-45.8-7.52-2.84-.25-5.08-.38-8.34-.58-21.33-1.33-36.52-1.11-39.51-6.94-.17-.32-.81-1.67-1.32-3.5-.33-1.18-.96-3.83-.73-7.44.46-7.13,4.1-17.77,4.1-17.77,2.74-6.87,4.1-13.69,6.84-27.34v.02Z"
        />
        <path
          id="BG17"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M99.68,472.77c11.18-8.99,22.32,2.66,49.9,3.42,40.3,1.11,69.3-7.07,65.62-6.15s14-3.76,17.09,0c2.8,3.41-.7,9.54-6.15,26.66,0,0-3.09,9.7-5.47,19.14-5.16,20.47-4.86,37.78-4.78,41.01.52,21.81,6.15,31.44,1.37,34.86s-9.29-1.96-21.29-6.49c-14.35-5.43-25.96-6.26-38.86-7.18-13.9-.99-30.09-2.15-49.32,3.5-11.05,3.25-14.41,6.19-17.67,4.02-7.53-5.01,4.29-24.8,6.84-58.78,2.48-33.15-6.7-46.42,2.73-54h-.01Z"
        />
        <path
          id="BG16"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M86.98,648.11c.38-2.21,1.3-5.56,3.81-8.55,4.33-5.16,10.81-6.15,15.72-6.84,22.31-3.09,33.47-4.64,47.16-4.78,7.31-.08,10.94.09,17.07-.94,10.51-1.77,18.97-5.48,20.16-6.01,11.7-5.22,14.82-9.95,20-8.32,4.97,1.57,6.93,7.45,7.03,7.76,1.68,5.3-1.26,9.3-3.42,16.41-2.02,6.66-2.03,11.68-2.05,20.51-.02,12.97-.04,23.59,6.15,33.49,2.82,4.5,6.16,7.65,7.52,14.35.77,3.8.8,8.22,0,11.06-3.53,12.3-26.79,8.12-61.51,22.43-12.4,5.11-25.46,8.64-37.59,14.35-5.99,2.82-13.13,6.47-17.77,3.42-3.77-2.48-4.3-8.33-4.78-13.67-.72-7.9,1.07-10.71,1.37-17.77.21-4.93-1.23-10.57-4.1-21.87-1.59-6.26-1.63-5.52-7.52-25.97-1.79-6.2-2.85-9.97-4.1-15.04-1.43-5.77-2.45-10.61-3.13-14v-.02Z"
        />
        <path
          id="BG15"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M419.5,215.59c6.29,3.09,11.87-4.71,28.33-3.7,6.35.39,6.63,1.62,13.67,1.82,7.66.22,15.64.46,22.33-4.1,8.41-5.74,10.2-15.95,11.39-22.78.81-4.64,2.71-15.48-3.19-25.52-2.67-4.55-6.06-7.42-8.69-9.65-2.95-2.51-5.63-4.18-15.47-9.03-18.92-9.33-21.31-9.2-23.05-8.85-6.34,1.27-7.25,6-13.41,6.11-6.82.12-8.97-5.64-12.57-4.75-6.97,1.72-6.57,25.2-6.11,44.85.71,30.65,4.03,34.26,6.76,35.6h0Z"
        />
        <path
          id="BG14"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M368.54,269.76c1.95-.48,3.41,1.04,7.53,4.33,7.45,5.93,22.6,17.99,38.95,22.1,10.22,2.57,12.13-.18,20.51,3.65,5.19,2.37,9.08,5.68,16.86,12.3,6.77,5.76,10.18,8.71,11.39,11.85,1.01,2.61.86,4.19-.91,19.59-1.43,12.42-1.55,13.26-2.28,15.04-2.4,5.86-8.27,14.25-15.49,14.58-5.37.25-7.21-4.15-15.04-9.57-.68-.47-16.59-11.23-32.81-10.03-16.15,1.2-20.8,13.37-27.8,10.48-3.57-1.47-6.58-6.39-6.84-45.57-.29-44.37,3.51-48.17,5.92-48.76h0Z"
        />
        <path
          id="BG13"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M319.78,401.45c-2.54,2.02-2.64,5.73-2.28,13.22,1.03,21.27,3.65,33.72,3.65,33.72,3.69,17.97,4.84,15.78,5.47,24.15.7,9.34-.53,14.73,3.19,17.77,3.43,2.81,7.53.7,14.58.91,6.4.2,10.92,2.14,23.24,8.66,30.92,16.36,34.22,20.9,45.11,21.42,4.17.2,14.23.76,20.09-5.58,1.54-1.67,3.36-4.42,5.87-20.03,1.19-7.42,1.88-13.67,2.3-18.14.13-2.65.07-6.48-.91-10.94-1.77-8.05-5.52-13.45-7.29-15.95-2.56-3.61-9.24-11.89-33.72-23.7-9.78-4.72-5.58-1.88-32.81-13.22-9.8-4.08-20.14-6.74-30.14-10.31-7.28-2.6-12.93-4.7-16.35-1.99h0Z"
        />
        <path
          id="BG12"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M218.61,555.94c3.65,3.6,9.72-1.69,19.59-.46,12.72,1.59,20.38,12.59,21.42,14.13,1.69,2.5,5.73,8.49,5.01,15.95-.51,5.32-3.08,7.53-5.01,11.85-4.29,9.57-1.05,19.7,1.82,28.71,5.08,15.91,17.28,34.66,24.49,32.74,1.54-.41,2.6-1.69,7.54-15.67,4.74-13.42,7.11-20.13,7.61-23.45,2.28-14.99-1.38-27.31-3.47-34.09-2.68-8.69-3.71-7.4-8.2-19.1-1.53-3.98-3.34-9.17-9.71-35.94-4.13-17.35-6.19-26.09-5.97-29.36,1.02-15.3,8.86-25.91,4.56-29.62-2.18-1.88-6.01-.71-6.84-.46-5.94,1.82-6.8,8.11-11.96,15.17-6.02,8.25-16.24,15.26-20.34,12.96-2.7-1.52-2.68-7.02-2.66-15.36.02-7.23,1.13-12.35-.13-12.77-.92-.3-2.49,2.07-3.19,3.19-7.69,12.28-11.75,35.42-13.96,47.7,0,0-.57,2.84-1.99,24.3-.33,5.06-.46,7.77,1.37,9.57h.02Z"
        />
        <path
          id="BG11"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M242.7,420.15c1.91.89,6.17-11.64,14.65-12.31,8.6-.68,15.99,11.26,17.77,14.13,5.94,9.59,6.7,20.22,8.2,41.47.45,6.34.83,11.7.55,19.14-.45,12.09-2.05,14.34-1.47,21.41.16,1.89,1.88,20.71,10.48,23.7,10.22,3.55,31.87-14.66,35.09-35.54.61-3.93-2.04-18.08-7.32-46.4-1.5-8.03-2.36-12.36-2.33-18.84.02-4.75.51-8.82.09-16.79-.34-6.59-.94-8.72-1.37-10.03-1.88-5.66-4.62-7.25-6.84-11.39-2.96-5.55-2.46-10.97-1.82-17.77,1.8-19.33,12.01-30.13,8.2-33.72-1.46-1.37-4.16-.89-6.23-.52-1.18.21-4.8,1.03-9.72,6.45-5.23,5.77-3.68,7.65-8.31,12.25-1.45,1.44-5.11,4.98-10.8,6.13-1.94.39-4.9.99-7.87-.34-4.25-1.92-4.28-5.91-6.29-6.19-4.07-.57-9.18,15.16-14.12,28.71-11.11,30.44-11.75,35.91-10.55,36.46h0Z"
        />
        <path
          id="BG10"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M307.93,527.68c8.33-.52,9.87,2.55,21.36,1.97,7.69-.39,9.31-1.88,16-1.51,5.54.3,9.36,1.6,16.41,4.1,16.83,5.99,25.25,8.99,28.25,10.48,8.18,4.07,13.96,8.18,25.52,16.41,11.58,8.24,13.09,10.39,14.13,12.3,2.55,4.72,2.61,11.35,2.73,24.61.1,10.29-.64,13.58-1.37,15.95-1.47,4.82-3.24,10.61-8.22,13.46-5.3,3.03-8.44-.59-16.38,2.49-4.72,1.83-4.63,3.5-9.57,5.47-5.58,2.22-10.52,2.01-14.13,1.82-2.41-.13-14.1-.93-26.43-9.57-10.06-7.05-8.27-10.71-15.49-13.22-10.23-3.55-22.89.64-23.7.91-6.09,2.08-9.07,4.73-11.85,3.19-3.12-1.73-3-7.07-3.19-11.39-.11-2.58-2.2-11.44-6.38-29.16-5.95-25.25-14.81-29.5-10.94-37.37,4.73-9.6,21.67-10.84,23.24-10.94h.01Z"
        />
        <path
          id="BG9"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M287.42,298.01c.7.15,1.14-13.22,8.98-16.87,6.51-3.03,15.57,1.83,20.64,6.39,2.12,1.91,10.64,10.14,11.39,30.99,1.21,33.52-19.65,44.95-12.3,64.25,3.87,10.16,14.29,19.18,24.15,18.68,10.11-.51,17.2-10.89,20.96-16.41,2.48-3.63,6.23-9.28,6.38-16.86.1-4.89-1.37-7.09-2.89-13.01-1.22-4.79-1.45-10.6-1.86-22.02,0,0-.67-18.9.29-42.48.4-9.85.75-11.95,1.27-14.08,1.3-5.39,2.82-7.41,2.28-11.39-.55-4.07-2.61-5.46-4.78-9.56-2.73-5.15-4.22-11.91-3.42-17.32.86-5.86,8.7-12.48,10.54-16.34.34-.72.59-1.44.59-1.44,0,0,.77-2.27.72-4.55-.06-2.6-2.09-7.35-4.9-8.32-8.04-2.77-16.62,19.84-37.02,23.36-6.86,1.18-9.35-.77-10.03-1.37-5.95-5.27-.09-20.13-1.37-20.51-1.18-.35-5.84,12.26-9.02,20.41-8.66,22.18-10.48,21.89-14.69,35.03-6.12,19.07-6.63,33.26-5.91,33.42h0Z"
        />
        <path
          id="BG8"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M375.83,219.18c1.97-7.09,4.78-6.65,7.75-13.67,4.65-11,4.61-28.38-5.95-38.29-6.32-5.93-14.02-6.94-16.84-7.28-2.44-.29-14.64-1.73-21.42,5.47-2.43,2.58-1.69,3.64-6.84,13.22-2.59,4.82-5.56,10.34-6.38,10.03-1.27-.48,2.46-14.88,7.29-28.25,7.29-20.17,17.99-40.5,25.1-50.73,2.47-3.55,4.83-6.35,7.24-11.71,2.87-6.36,3.26-10.64,5.15-10.8,2.93-.25,3.67,9.95,9.43,11.41,1.85.47,3.42-.17,11.43-4.91,11.43-6.76,13.1-8.34,14.57-7.53,2.73,1.5-.73,8.22.74,22.43.59,5.7.94,9.13,3.06,13.23,2.26,4.38,4.34,5.31,5.35,9.52.46,1.91.51,3.74-.49,10.4-.82,5.4-1.22,8.1-2.22,12.25-1.91,7.94-1.58,15.12-.97,28.31.22,4.71.94,16.25,4.1,30.99,1.61,7.5,1.83,6.43,2.28,9.57,1.68,11.92-.91,31.81-15.04,40.56-10.93,6.77-27.34,5.99-30.99-.46-2.46-4.34,3-7.5,4.1-20.05,1-11.4-2.99-14.56-.46-23.7h.01Z"
        />
        <path
          id="BG7"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M216.79,638.87c3.42-2.48,6.55.85,11.39-1.37,3.02-1.38,4.68-4,10.48-19.14,4.57-11.92,5.92-16.52,10.03-17.77,2.01-.61,4.56-.39,6.38.91,3.53,2.52,2.7,7.89,3.47,14.37.56,4.62,2.4,9.08,6.1,17.98,5.4,13.02,10.4,14.96,21.42,31.44,7.78,11.64,12.35,21.24,14.58,25.97,7.42,15.75,11.14,23.63,11.39,33.72.31,12.31-4.05,33.63-18.23,38.28-11.85,3.89-26.12-6.18-33.27-15.04-6.37-7.9-9.26-19.39-15.04-42.38-3.51-13.95-4.88-22.53-10.48-24.15-6.14-1.77-11.02,7.49-15.95,5.47-2.66-1.09-3.13-4.53-4.56-28.25-.77-12.75-.99-17.68,2.28-20.05h0Z"
        />
        <path
          id="BG6"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M289.7,662.11c1.56-1.28,4.83,2.89,12.3,4.1,8.44,1.37,15.08-2.18,18.68-3.65,12.14-4.92,18.78,2.17,57.87,11.39,23.74,5.6,37.43,6.79,39.63,15,.97,3.63-1.06,5.85.02,13.25,1.25,8.65,4.72,10.44,5.47,15.49,2.18,14.73-21.89,36.03-37.82,32.81-3.66-.74-5.53-2.5-10.94-2.73-8.62-.38-10.5,4.41-17.32,4.1-11.57-.52-14.04-9.97-25.06-10.48-9.17-.43-13.45,7.95-17.32,5.47-2.48-1.6-2.72-7.14-3.19-18.23-.18-4.33,0-4.45-.21-6.86-.76-8.28-3.72-14.4-5.01-17.07-16.59-34.35-18.86-41.16-17.1-42.6h0Z"
        />
        <path
          id="BG5"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M367.17,71.53c-.82,4.65.75,8.4,3.77,15.61,2.68,6.4,4.02,9.6,6.71,10.82,4.8,2.17,10.64-1.84,19.91-8.21,9.18-6.31,16.05-11.03,15.26-15.89-.64-3.95-5.6-3.35-11.02-9.62-6.84-7.92-4.62-15.47-9.11-16.86-3.58-1.11-7.67,2.86-15.04,10.03-6.43,6.25-9.64,9.37-10.48,14.13h0Z"
        />
        <path
          id="BG4"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M418.66,133.05c.57-.1,2.07-.66,4.56-13.22,2.13-10.75,3.2-16.13,2.28-22.33-.27-1.82-2.36-15.94-8.66-16.86-3.92-.57-7.53,4.26-7.75,4.56-2.03,2.78-2.33,5.76-2.28,11.39.09,10.75.13,16.12,2.11,21.92,1.88,5.51,6.36,15.13,9.74,14.54h0Z"
        />
        <path
          id="BG3"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M92.84,587.61c.2-5.3,29.61-9.22,53.32-8.89,28.87.41,61.71,7.29,61.52,12.99-.19,5.61-32.46,10.18-60.15,8.89-25.35-1.18-54.89-7.64-54.68-12.99h0Z"
        />
        <path
          id="BG2"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M202.21,435.18c-25.87-2.62-48.3-2.73-48.3-2.73-6.65-.03-10.06.09-15.49-.91-13.53-2.49-21.58-8.61-23.63-6.2-1.25,1.47.59,5.14,1.99,7.11,3.93,5.56,10.73,6.69,20.73,8.2,6.03.91,13.53,2.05,22.78.91,2.85-.35,5.98-.93,10.94-.91,5.21.02,9.3.68,11.31,1.06,3.35.63,4.94,1.21,9.65,2.59,0,0,3.35.98,13.67,3.65,10.13,2.62,31.22,6.69,32.58,2.96.25-.69-.04-1.94-3.42-4.78-9.65-8.11-21.47-9.79-32.81-10.94h0Z"
        />
        <path
          id="BG1"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls3"
          d="M373.55,227.38c2.39,1.33,1.72,6.61.46,15.95-1.38,10.21-2.08,15.31-3.65,15.49-3.42.4-12.47-18.19-5.47-27.34,2.25-2.94,6.47-5.32,8.66-4.1Z"
        />
      </g>
      <g id="LWS_Highlight">
        <path
          id="LWS_Highlight20"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M241.9,40.96c-3.98,2.03-6.79,6.13-7.52,10.25-.84,4.77,1.68,7.42,2.73,12.3.48,2.22.99,6.42-4.78,20.51-3.94,9.63-8.63,21.06-19.14,34.86-6.28,8.24-9.72,8.92-10.25,13.67-1.17,10.33,11.65,19.61,15.72,22.56,5.66,4.1,11.34,6.78,45.11,15.04,27.37,6.69,28.58,6.09,34.86,9.57,9.75,5.4,16.24,12.02,21.19,9.57,3.65-1.8,3.73-7.18,4.78-13.67,2.03-12.54,7.05-22.03,17.09-41.01,10.19-19.26,17.77-30.76,17.77-30.76,3.05-4.62,5.93-8.69,6.84-15.04,1.16-8.11-1.06-18.77-7.52-21.87-3.92-1.88-7.56-.09-12.99,1.37,0,0-17.12,3.81-34.86,0-6.72-1.44-4.67-2.14-23.92-8.2-7.59-2.39-8.78-2.55-11.62-4.1-9.09-4.95-8.99-9.84-17.09-13.67-2.71-1.28-9.9-4.69-16.41-1.37h.01Z"
        />
        <path
          id="LWS_Highlight19"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M192.01,168.1c-4.96,2.35-6.7,9.59-7.52,12.99-1.49,6.21-1.37,17.09-1.37,17.09,0,0-1.74,6.59-2.73,9.57-1.91,5.76-2.79,6.91-4.1,10.94-1.35,4.15-1.16,5.17-2.73,13.67-1.78,9.62-2.68,14.43-4.1,17.77-2.39,5.6-3.73,5.32-8.2,13.67-3.94,7.36-5.23,11.01-5.47,14.35-.93,13.18,15.97,17,18.46,15.04,9.24-7.29,15.29-1.72,31.44-1.37,13.3.29,23.76,4.06,31.44,6.84,15.68,5.66,19.76,12.63,30.76,11.62,2.24-.21,10.84-.99,14.35-6.84,3.14-5.24.68-19.14.68-19.14,0,0,2.64-5.36,6.84-16.41,4.47-11.77,2.43-11.92,6.15-19.82,4.07-8.62,6.89-9.27,9.57-17.77,1.48-4.7.84-5.22,2.73-10.94,2.93-8.86,5.18-9.78,4.78-13.67-.64-6.34-7.47-12.43-12.3-11.62-.2.03-.75.14-.76.31-.03.61,6.54,1.6,6.6,1.33.04-.18-2.69-1.5-5.84-1.64-4.38-.19-5.5,2.1-14.06,4.89-.68.22-3.87,1.11-7.15,1.32-.8.05-1.64.03-2.71-.06-5.77-.51-5.62-1.26-16.41-3.42-12.03-2.41-13.22-1.68-18.46-3.42-6.88-2.28-9.66-5.14-17.09-10.25-17.6-12.12-26.55-18-32.81-15.04h0Z"
        />
        <path
          id="LWS_Highlight18"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M123.65,375.21c1.51-7.55,4.24-18.44,7.52-43.75.33-2.56,1.07-8.46,5.47-11.62,5.47-3.93,13.32-1.01,17.77,0,32.73,7.42,29.29,4.63,51.95,9.57,9.98,2.17,20.32,5.92,20.32,5.92,12.21-1.86,14.63-1.76,12.21-1.86-3.08-.13,15.6-4.55,20.25-3.68,2.26.42,4.77-.34,6.38,0,3.59.76,5.63,5.29,4.86,7.29-1.6,4.17-5.01,11.65-6.61,14.2-9.11,14.62-12.26,32.21-17.77,48.53-4.34,12.85-6.36,16.89-4.78,23.24,1.49,6.02,4.87,8.71,4.1,14.35-.73,5.35-4.72,10.02-8.89,12.3-8.04,4.41-17.03.11-28.03-3.42-14.83-4.76-26.02-5.75-45.8-7.52-2.84-.25-5.08-.38-8.34-.58-21.33-1.33-36.52-1.11-39.51-6.94-.17-.32-.81-1.67-1.32-3.5-.33-1.18-.96-3.83-.73-7.44.46-7.13,4.1-17.77,4.1-17.77,2.74-6.87,4.1-13.69,6.84-27.34v.02Z"
        />
        <path
          id="LWS_Highlight17"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M97.68,471.59c11.18-8.99,22.32,2.66,49.9,3.42,40.3,1.11,69.3-7.07,65.62-6.15s14-3.76,17.09,0c2.8,3.41-.7,9.54-6.15,26.66,0,0-3.09,9.7-5.47,19.14-5.16,20.47-4.86,37.78-4.78,41.01.52,21.81,6.15,31.44,1.37,34.86s-9.29-1.96-21.29-6.49c-14.35-5.43-25.96-6.26-38.86-7.18-13.9-.99-30.09-2.15-49.32,3.5-11.05,3.25-14.41,6.19-17.67,4.02-7.53-5.01,4.29-24.8,6.84-58.78,2.48-33.15-6.7-46.42,2.73-54h-.01Z"
        />
        <path
          id="LWS_Highlight16"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M84.98,646.93c.38-2.21,1.3-5.56,3.81-8.55,4.33-5.16,10.81-6.15,15.72-6.84,22.31-3.09,33.47-4.64,47.16-4.78,7.31-.08,10.94.09,17.07-.94,10.51-1.77,18.97-5.48,20.16-6.01,11.7-5.22,14.82-9.95,20-8.32,4.97,1.57,6.93,7.45,7.03,7.76,1.68,5.3-1.26,9.3-3.42,16.41-2.02,6.66-2.03,11.68-2.05,20.51-.02,12.97-.04,23.59,6.15,33.49,2.82,4.5,6.16,7.65,7.52,14.35.77,3.8.8,8.22,0,11.06-3.53,12.3-26.79,8.12-61.51,22.43-12.4,5.11-25.46,8.64-37.59,14.35-5.99,2.82-13.13,6.47-17.77,3.42-3.77-2.48-4.3-8.33-4.78-13.67-.72-7.9,1.07-10.71,1.37-17.77.21-4.93-1.23-10.57-4.1-21.87-1.59-6.26-1.63-5.52-7.52-25.97-1.79-6.2-2.85-9.97-4.1-15.04-1.43-5.77-2.45-10.61-3.13-14v-.02Z"
        />
        <path
          id="LWS_Highlight15"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M417.5,214.41c6.29,3.09,11.87-4.71,28.33-3.7,6.35.39,6.63,1.62,13.67,1.82,7.66.22,15.64.46,22.33-4.1,8.41-5.74,10.2-15.95,11.39-22.78.81-4.64,2.71-15.48-3.19-25.52-2.67-4.55-6.06-7.42-8.69-9.65-2.95-2.51-5.63-4.18-15.47-9.03-18.92-9.33-21.31-9.2-23.05-8.85-6.34,1.27-7.25,6-13.41,6.11-6.82.12-8.97-5.64-12.57-4.75-6.97,1.72-6.57,25.2-6.11,44.85.71,30.65,4.03,34.26,6.76,35.6h0Z"
        />
        <path
          id="LWS_Highlight14"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M366.54,268.58c1.95-.48,3.41,1.04,7.53,4.33,7.45,5.93,22.6,17.99,38.95,22.1,10.22,2.57,12.13-.18,20.51,3.65,5.19,2.37,9.08,5.68,16.86,12.3,6.77,5.76,10.18,8.71,11.39,11.85,1.01,2.61.86,4.19-.91,19.59-1.43,12.42-1.55,13.26-2.28,15.04-2.4,5.86-8.27,14.25-15.49,14.58-5.37.25-7.21-4.15-15.04-9.57-.68-.47-16.59-11.23-32.81-10.03-16.15,1.2-20.8,13.37-27.8,10.48-3.57-1.47-6.58-6.39-6.84-45.57-.29-44.37,3.51-48.17,5.92-48.76h0Z"
        />
        <path
          id="LWS_Highlight13"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M317.78,400.27c-2.54,2.02-2.64,5.73-2.28,13.22,1.03,21.27,3.65,33.72,3.65,33.72,3.69,17.97,4.84,15.78,5.47,24.15.7,9.34-.53,14.73,3.19,17.77,3.43,2.81,7.53.7,14.58.91,6.4.2,10.92,2.14,23.24,8.66,30.92,16.36,34.22,20.9,45.11,21.42,4.17.2,14.23.76,20.09-5.58,1.54-1.67,3.36-4.42,5.87-20.03,1.19-7.42,1.88-13.67,2.3-18.14.13-2.65.07-6.48-.91-10.94-1.77-8.05-5.52-13.45-7.29-15.95-2.56-3.61-9.24-11.89-33.72-23.7-9.78-4.72-5.58-1.88-32.81-13.22-9.8-4.08-20.14-6.74-30.14-10.31-7.28-2.6-12.93-4.7-16.35-1.99h0Z"
        />
        <path
          id="LWS_Highlight12"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M216.61,554.76c3.65,3.6,9.72-1.69,19.59-.46,12.72,1.59,20.38,12.59,21.42,14.13,1.69,2.5,5.73,8.49,5.01,15.95-.51,5.32-3.08,7.53-5.01,11.85-4.29,9.57-1.05,19.7,1.82,28.71,5.08,15.91,17.28,34.66,24.49,32.74,1.54-.41,2.6-1.69,7.54-15.67,4.74-13.42,7.11-20.13,7.61-23.45,2.28-14.99-1.38-27.31-3.47-34.09-2.68-8.69-3.71-7.4-8.2-19.1-1.53-3.98-3.34-9.17-9.71-35.94-4.13-17.35-6.19-26.09-5.97-29.36,1.02-15.3,8.86-25.91,4.56-29.62-2.18-1.88-6.01-.71-6.84-.46-5.94,1.82-6.8,8.11-11.96,15.17-6.02,8.25-16.24,15.26-20.34,12.96-2.7-1.52-2.68-7.02-2.66-15.36.02-7.23,1.13-12.35-.13-12.77-.92-.3-2.49,2.07-3.19,3.19-7.69,12.28-11.75,35.42-13.96,47.7,0,0-.57,2.84-1.99,24.3-.33,5.06-.46,7.77,1.37,9.57h.02Z"
        />
        <path
          id="LWS_Highlight11"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M240.7,418.97c1.91.89,6.17-11.64,14.65-12.31,8.6-.68,15.99,11.26,17.77,14.13,5.94,9.59,6.7,20.22,8.2,41.47.45,6.34.83,11.7.55,19.14-.45,12.09-2.05,14.34-1.47,21.41.16,1.89,1.88,20.71,10.48,23.7,10.22,3.55,31.87-14.66,35.09-35.54.61-3.93-2.04-18.08-7.32-46.4-1.5-8.03-2.36-12.36-2.33-18.84.02-4.75.51-8.82.09-16.79-.34-6.59-.94-8.72-1.37-10.03-1.88-5.66-4.62-7.25-6.84-11.39-2.96-5.55-2.46-10.97-1.82-17.77,1.8-19.33,12.01-30.13,8.2-33.72-1.46-1.37-4.16-.89-6.23-.52-1.18.21-4.8,1.03-9.72,6.45-5.23,5.77-3.68,7.65-8.31,12.25-1.45,1.44-5.11,4.98-10.8,6.13-1.94.39-4.9.99-7.87-.34-4.25-1.92-4.28-5.91-6.29-6.19-4.07-.57-9.18,15.16-14.12,28.71-11.11,30.44-11.75,35.91-10.55,36.46h0Z"
        />
        <path
          id="LWS_Highlight10"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M305.93,526.5c8.33-.52,9.87,2.55,21.36,1.97,7.69-.39,9.31-1.88,16-1.51,5.54.3,9.36,1.6,16.41,4.1,16.83,5.99,25.25,8.99,28.25,10.48,8.18,4.07,13.96,8.18,25.52,16.41,11.58,8.24,13.09,10.39,14.13,12.3,2.55,4.72,2.61,11.35,2.73,24.61.1,10.29-.64,13.58-1.37,15.95-1.47,4.82-3.24,10.61-8.22,13.46-5.3,3.03-8.44-.59-16.38,2.49-4.72,1.83-4.63,3.5-9.57,5.47-5.58,2.22-10.52,2.01-14.13,1.82-2.41-.13-14.1-.93-26.43-9.57-10.06-7.05-8.27-10.71-15.49-13.22-10.23-3.55-22.89.64-23.7.91-6.09,2.08-9.07,4.73-11.85,3.19-3.12-1.73-3-7.07-3.19-11.39-.11-2.58-2.2-11.44-6.38-29.16-5.95-25.25-14.81-29.5-10.94-37.37,4.73-9.6,21.67-10.84,23.24-10.94h.01Z"
        />
        <path
          id="LWS_Highlight9"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M285.42,296.83c.7.15,1.14-13.22,8.98-16.87,6.51-3.03,15.57,1.83,20.64,6.39,2.12,1.91,10.64,10.14,11.39,30.99,1.21,33.52-19.65,44.95-12.3,64.25,3.87,10.16,14.29,19.18,24.15,18.68,10.11-.51,17.2-10.89,20.96-16.41,2.48-3.63,6.23-9.28,6.38-16.86.1-4.89-1.37-7.09-2.89-13.01-1.22-4.79-1.45-10.6-1.86-22.02,0,0-.67-18.9.29-42.48.4-9.85.75-11.95,1.27-14.08,1.3-5.39,2.82-7.41,2.28-11.39-.55-4.07-2.61-5.46-4.78-9.56-2.73-5.15-4.22-11.91-3.42-17.32.86-5.86,8.7-12.48,10.54-16.34.34-.72.59-1.44.59-1.44,0,0,.77-2.27.72-4.55-.06-2.6-2.09-7.35-4.9-8.32-8.04-2.77-16.62,19.84-37.02,23.36-6.86,1.18-9.35-.77-10.03-1.37-5.95-5.27-.09-20.13-1.37-20.51-1.18-.35-5.84,12.26-9.02,20.41-8.66,22.18-10.48,21.89-14.69,35.03-6.12,19.07-6.63,33.26-5.91,33.42h0Z"
        />
        <path
          id="LWS_Highlight8"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M373.83,218c1.97-7.09,4.78-6.65,7.75-13.67,4.65-11,4.61-28.38-5.95-38.29-6.32-5.93-14.02-6.94-16.84-7.28-2.44-.29-14.64-1.73-21.42,5.47-2.43,2.58-1.69,3.64-6.84,13.22-2.59,4.82-5.56,10.34-6.38,10.03-1.27-.48,2.46-14.88,7.29-28.25,7.29-20.17,17.99-40.5,25.1-50.73,2.47-3.55,4.83-6.35,7.24-11.71,2.87-6.36,3.26-10.64,5.15-10.8,2.93-.25,3.67,9.95,9.43,11.41,1.85.47,3.42-.17,11.43-4.91,11.43-6.76,13.1-8.34,14.57-7.53,2.73,1.5-.73,8.22.74,22.43.59,5.7.94,9.13,3.06,13.23,2.26,4.38,4.34,5.31,5.35,9.52.46,1.91.51,3.74-.49,10.4-.82,5.4-1.22,8.1-2.22,12.25-1.91,7.94-1.58,15.12-.97,28.31.22,4.71.94,16.25,4.1,30.99,1.61,7.5,1.83,6.43,2.28,9.57,1.68,11.92-.91,31.81-15.04,40.56-10.93,6.77-27.34,5.99-30.99-.46-2.46-4.34,3-7.5,4.1-20.05,1-11.4-2.99-14.56-.46-23.7h.01Z"
        />
        <path
          id="LWS_Highlight7"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M214.79,637.69c3.42-2.48,6.55.85,11.39-1.37,3.02-1.38,4.68-4,10.48-19.14,4.57-11.92,5.92-16.52,10.03-17.77,2.01-.61,4.56-.39,6.38.91,3.53,2.52,2.7,7.89,3.47,14.37.56,4.62,2.4,9.08,6.1,17.98,5.4,13.02,10.4,14.96,21.42,31.44,7.78,11.64,12.35,21.24,14.58,25.97,7.42,15.75,11.14,23.63,11.39,33.72.31,12.31-4.05,33.63-18.23,38.28-11.85,3.89-26.12-6.18-33.27-15.04-6.37-7.9-9.26-19.39-15.04-42.38-3.51-13.95-4.88-22.53-10.48-24.15-6.14-1.77-11.02,7.49-15.95,5.47-2.66-1.09-3.13-4.53-4.56-28.25-.77-12.75-.99-17.68,2.28-20.05h0Z"
        />
        <path
          id="LWS_Highlight6"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M287.7,660.93c1.56-1.28,4.83,2.89,12.3,4.1,8.44,1.37,15.08-2.18,18.68-3.65,12.14-4.92,18.78,2.17,57.87,11.39,23.74,5.6,37.43,6.79,39.63,15,.97,3.63-1.06,5.85.02,13.25,1.25,8.65,4.72,10.44,5.47,15.49,2.18,14.73-21.89,36.03-37.82,32.81-3.66-.74-5.53-2.5-10.94-2.73-8.62-.38-10.5,4.41-17.32,4.1-11.57-.52-14.04-9.97-25.06-10.48-9.17-.43-13.45,7.95-17.32,5.47-2.48-1.6-2.72-7.14-3.19-18.23-.18-4.33,0-4.45-.21-6.86-.76-8.28-3.72-14.4-5.01-17.07-16.59-34.35-18.86-41.16-17.1-42.6h0Z"
        />
        <path
          id="LWS_Highlight5"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M365.17,70.35c-.82,4.65.75,8.4,3.77,15.61,2.68,6.4,4.02,9.6,6.71,10.82,4.8,2.17,10.64-1.84,19.91-8.21,9.18-6.31,16.05-11.03,15.26-15.89-.64-3.95-5.6-3.35-11.02-9.62-6.84-7.92-4.62-15.47-9.11-16.86-3.58-1.11-7.67,2.86-15.04,10.03-6.43,6.25-9.64,9.37-10.48,14.13h0Z"
        />
        <path
          id="LWS_Highlight4"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M416.66,131.87c.57-.1,2.07-.66,4.56-13.22,2.13-10.75,3.2-16.13,2.28-22.33-.27-1.82-2.36-15.94-8.66-16.86-3.92-.57-7.53,4.26-7.75,4.56-2.03,2.78-2.33,5.76-2.28,11.39.09,10.75.13,16.12,2.11,21.92,1.88,5.51,6.36,15.13,9.74,14.54h0Z"
        />
        <path
          id="LWS_Highlight3"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M90.84,586.43c.2-5.3,29.61-9.22,53.32-8.89,28.87.41,61.71,7.29,61.52,12.99-.19,5.61-32.46,10.18-60.15,8.89-25.35-1.18-54.89-7.64-54.68-12.99h0Z"
        />
        <path
          id="LWS_Highlight2"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M200.21,434c-25.87-2.62-48.3-2.73-48.3-2.73-6.65-.03-10.06.09-15.49-.91-13.53-2.49-21.58-8.61-23.63-6.2-1.25,1.47.59,5.14,1.99,7.11,3.93,5.56,10.73,6.69,20.73,8.2,6.03.91,13.53,2.05,22.78.91,2.85-.35,5.98-.93,10.94-.91,5.21.02,9.3.68,11.31,1.06,3.35.63,4.94,1.21,9.65,2.59,0,0,3.35.98,13.67,3.65,10.13,2.62,31.22,6.69,32.58,2.96.25-.69-.04-1.94-3.42-4.78-9.65-8.11-21.47-9.79-32.81-10.94h0Z"
        />
        <path
          id="LWS_Highlight1"
          class="LWK1728Fill"
          style={{
            fill: props.colors.LWK1728Fill,
            stroke: props.colors.LWK1728Fill,
          }}
          d="M371.55,226.2c2.39,1.33,1.72,6.61.46,15.95-1.38,10.21-2.08,15.31-3.65,15.49-3.42.4-12.47-18.19-5.47-27.34,2.25-2.94,6.47-5.32,8.66-4.1Z"
        />
      </g>
      <g id="LWS">
        <path
          id="LWS45"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls2"
          d="M239.61,869.35c14.29-16.61,34.06-27.91,45.7-46.84.48-.79,1.08-1.35,1.73-1.73-4.35-6.37-9.12-14.42-15.19-24.45-34.94-57.76-40.22-60.58-45.11-61.52-7.62-1.46-11.25,2.24-62.89,43.75-24.31,19.54-34.86,27.84-32.81,35.54,2.09,7.88,15.96,9.87,25.97,12.3,21.35,5.19,49.67,18.38,80.66,53.32.92-.96,1.82-1.88,2.73-2.82-2.05-1.7-3.03-4.95-.79-7.55h0Z"
        />
        <path
          id="LWS44"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls2"
          d="M342.94,799.06c-.33-.23-.69-.43-1.07-.6,1.23,2.15,1.02,4.94-1.87,6.28-12.13,5.61-22.04,14.9-34.18,20.51-3.5,1.62-6.74-.88-7.47-3.7-1.19,1-2.4,2.03-3.65,3.1.08.91-.13,1.9-.75,2.91-.26.43-.55.84-.83,1.26,5.12,5.93,10.24,9.52,17.02,11.25,9.61,2.47,22.52,1.23,30.08-6.84,9.37-9.99,9.58-29.44,2.73-34.18h-.01Z"
        />
        <path
          id="LWS43"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M266.65.42c-2.15,7.45-5.05,14.02-6.84,14.58"
        />
        <path
          id="LWS42"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M259.82,15c-5.47,10.94,24.63,8.2,72,21.87"
        />
        <path
          id="LWS41"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M331.82,36.87c10.48,6.65,22.78,27.34,36.07,27.34"
        />
        <path
          id="LWS40"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M365.08,64.22c12.03-1.89,12.53-14.87,25.06-17.32"
        />
        <path
          id="LWS39"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M446.65,117.99c-12.08,26.29-15.98,13.92-28.71,14.58"
        />
        <path
          id="LWS38"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M247.97,643.02c23.7,15.49.41,30.99.91,30.99"
        />
        <path
          id="LWS37"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M268.93,648.87c6.1,42.04,34.18,64.71,32.17,85.69"
        />
        <path
          id="LWS36"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls4"
          d="M243.18,41.66c-3.98,2.03-6.79,6.13-7.52,10.25-.84,4.77,1.68,7.42,2.73,12.3.48,2.22.99,6.42-4.78,20.51-3.94,9.63-8.63,21.06-19.14,34.86-6.28,8.24-9.72,8.92-10.25,13.67-1.17,10.33,11.65,19.61,15.72,22.56,5.66,4.1,11.34,6.78,45.11,15.04,27.37,6.69,28.58,6.09,34.86,9.57,9.75,5.4,16.24,12.02,21.19,9.57,3.65-1.8,3.73-7.18,4.78-13.67,2.03-12.54,7.05-22.03,17.09-41.01,10.19-19.26,17.77-30.76,17.77-30.76,3.05-4.62,5.93-8.69,6.84-15.04,1.16-8.11-1.06-18.77-7.52-21.87-3.92-1.88-7.56-.09-12.99,1.37,0,0-17.12,3.81-34.86,0-6.72-1.44-4.67-2.14-23.92-8.2-7.59-2.39-8.78-2.55-11.62-4.1-9.09-4.95-8.99-9.84-17.09-13.67-2.71-1.28-9.9-4.69-16.41-1.37h.01Z"
        />
        <path
          id="LWS35"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls4"
          d="M193.29,168.8c-4.96,2.35-6.7,9.59-7.52,12.99-1.49,6.21-1.37,17.09-1.37,17.09,0,0-1.74,6.59-2.73,9.57-1.91,5.76-2.79,6.91-4.1,10.94-1.35,4.15-1.16,5.17-2.73,13.67-1.78,9.62-2.68,14.43-4.1,17.77-2.39,5.6-3.73,5.32-8.2,13.67-3.94,7.36-5.23,11.01-5.47,14.35-.93,13.18,15.97,17,18.46,15.04,9.24-7.29,15.29-1.72,31.44-1.37,13.3.29,23.76,4.06,31.44,6.84,15.68,5.66,19.76,12.63,30.76,11.62,2.24-.21,10.84-.99,14.35-6.84,3.14-5.24.68-19.14.68-19.14,0,0,2.64-5.36,6.84-16.41,4.47-11.77,2.43-11.92,6.15-19.82,4.07-8.62,6.89-9.27,9.57-17.77,1.48-4.7.84-5.22,2.73-10.94,2.93-8.86,5.18-9.78,4.78-13.67-.64-6.34-7.47-12.43-12.3-11.62-.2.03-.75.14-.76.31-.03.61,6.54,1.6,6.6,1.33.04-.18-2.69-1.5-5.84-1.64-4.38-.19-5.5,2.1-14.06,4.89-.68.22-3.87,1.11-7.15,1.32-.8.05-1.64.03-2.71-.06-5.77-.51-5.62-1.26-16.41-3.42-12.03-2.41-13.22-1.68-18.46-3.42-6.88-2.28-9.66-5.14-17.09-10.25-17.6-12.12-26.55-18-32.81-15.04h0Z"
        />
        <path
          id="LWS34"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls4"
          d="M124.93,375.91c1.51-7.55,4.24-18.44,7.52-43.75.33-2.56,1.07-8.46,5.47-11.62,5.47-3.93,13.32-1.01,17.77,0,32.73,7.42,29.29,4.63,51.95,9.57,9.98,2.17,20.32,5.92,20.32,5.92,12.21-1.86,14.63-1.76,12.21-1.86-3.08-.13,15.6-4.55,20.25-3.68,2.26.42,4.77-.34,6.38,0,3.59.76,5.63,5.29,4.86,7.29-1.6,4.17-5.01,11.65-6.61,14.2-9.11,14.62-12.26,32.21-17.77,48.53-4.34,12.85-6.36,16.89-4.78,23.24,1.49,6.02,4.87,8.71,4.1,14.35-.73,5.35-4.72,10.02-8.89,12.3-8.04,4.41-17.03.11-28.03-3.42-14.83-4.76-26.02-5.75-45.8-7.52-2.84-.25-5.08-.38-8.34-.58-21.33-1.33-36.52-1.11-39.51-6.94-.17-.32-.81-1.67-1.32-3.5-.33-1.18-.96-3.83-.73-7.44.46-7.13,4.1-17.77,4.1-17.77,2.74-6.87,4.1-13.69,6.84-27.34v.02Z"
        />
        <path
          id="LWS33"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls4"
          d="M98.96,472.29c11.18-8.99,22.32,2.66,49.9,3.42,40.3,1.11,69.3-7.07,65.62-6.15s14-3.76,17.09,0c2.8,3.41-.7,9.54-6.15,26.66,0,0-3.09,9.7-5.47,19.14-5.16,20.47-4.86,37.78-4.78,41.01.52,21.81,6.15,31.44,1.37,34.86s-9.29-1.96-21.29-6.49c-14.35-5.43-25.96-6.26-38.86-7.18-13.9-.99-30.09-2.15-49.32,3.5-11.05,3.25-14.41,6.19-17.67,4.02-7.53-5.01,4.29-24.8,6.84-58.78,2.48-33.15-6.7-46.42,2.73-54h-.01Z"
        />
        <path
          id="LWS32"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M217.89,690.36c2.82,4.5,6.16,7.65,7.52,14.35.77,3.8.8,8.22,0,11.06-3.53,12.3-26.79,8.12-61.51,22.43-12.4,5.11-25.46,8.64-37.59,14.35-5.99,2.82-13.13,6.47-17.77,3.42-3.77-2.48-4.3-8.33-4.78-13.67-.72-7.9,1.07-10.71,1.37-17.77.21-4.93-1.23-10.57-4.1-21.87-1.59-6.26-1.63-5.52-7.52-25.97-1.79-6.2-2.85-9.97-4.1-15.04-1.43-5.77-2.45-10.61-3.13-14v-.02c.37-2.21,1.29-5.56,3.8-8.55,4.33-5.16,10.81-6.15,15.72-6.84,22.31-3.09,33.47-4.64,47.16-4.78,7.31-.08,10.94.09,17.07-.94,10.51-1.77,18.97-5.48,20.16-6.01,11.7-5.22,14.82-9.95,20-8.32,4.97,1.57,6.93,7.45,7.03,7.76,1.68,5.3-1.26,9.3-3.42,16.41-2.02,6.66-2.03,11.68-2.05,20.51-.02,12.97-.04,23.59,6.15,33.49"
        />
        <path
          id="LWS31"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M418.78,215.11c6.29,3.09,11.87-4.71,28.33-3.7,6.35.39,6.63,1.62,13.67,1.82,7.66.22,15.64.46,22.33-4.1,8.41-5.74,10.2-15.95,11.39-22.78.81-4.64,2.71-15.48-3.19-25.52-2.67-4.55-6.06-7.42-8.69-9.65-2.95-2.51-5.63-4.18-15.47-9.03-18.92-9.33-21.31-9.2-23.05-8.85-6.34,1.27-7.25,6-13.41,6.11-6.82.12-8.97-5.64-12.57-4.75-6.97,1.72-6.57,25.2-6.11,44.85.71,30.65,4.03,34.26,6.76,35.6h0Z"
        />
        <path
          id="LWS30"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M367.82,269.28c1.95-.48,3.41,1.04,7.53,4.33,7.45,5.93,22.6,17.99,38.95,22.1,10.22,2.57,12.13-.18,20.51,3.65,5.19,2.37,9.08,5.68,16.86,12.3,6.77,5.76,10.18,8.71,11.39,11.85,1.01,2.61.86,4.19-.91,19.59-1.43,12.42-1.55,13.26-2.28,15.04-2.4,5.86-8.27,14.25-15.49,14.58-5.37.25-7.21-4.15-15.04-9.57-.68-.47-16.59-11.23-32.81-10.03-16.15,1.2-20.8,13.37-27.8,10.48-3.57-1.47-6.58-6.39-6.84-45.57-.29-44.37,3.51-48.17,5.92-48.76h0Z"
        />
        <path
          id="LWS29"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M319.06,400.97c-2.54,2.02-2.64,5.73-2.28,13.22,1.03,21.27,3.65,33.72,3.65,33.72,3.69,17.97,4.84,15.78,5.47,24.15.7,9.34-.53,14.73,3.19,17.77,3.43,2.81,7.53.7,14.58.91,6.4.2,10.92,2.14,23.24,8.66,30.92,16.36,34.22,20.9,45.11,21.42,4.17.2,14.23.76,20.09-5.58,1.54-1.67,3.36-4.42,5.87-20.03,1.19-7.42,1.88-13.67,2.3-18.14.13-2.65.07-6.48-.91-10.94-1.77-8.05-5.52-13.45-7.29-15.95-2.56-3.61-9.24-11.89-33.72-23.7-9.78-4.72-5.58-1.88-32.81-13.22-9.8-4.08-20.14-6.74-30.14-10.31-7.28-2.6-12.93-4.7-16.35-1.99h0Z"
        />
        <path
          id="LWS28"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M217.89,555.46c3.65,3.6,9.72-1.69,19.59-.46,12.72,1.59,20.38,12.59,21.42,14.13,1.69,2.5,5.73,8.49,5.01,15.95-.51,5.32-3.08,7.53-5.01,11.85-4.29,9.57-1.05,19.7,1.82,28.71,5.08,15.91,17.28,34.66,24.49,32.74,1.54-.41,2.6-1.69,7.54-15.67,4.74-13.42,7.11-20.13,7.61-23.45,2.28-14.99-1.38-27.31-3.47-34.09-2.68-8.69-3.71-7.4-8.2-19.1-1.53-3.98-3.34-9.17-9.71-35.94-4.13-17.35-6.19-26.09-5.97-29.36,1.02-15.3,8.86-25.91,4.56-29.62-2.18-1.88-6.01-.71-6.84-.46-5.94,1.82-6.8,8.11-11.96,15.17-6.02,8.25-16.24,15.26-20.34,12.96-2.7-1.52-2.68-7.02-2.66-15.36.02-7.23,1.13-12.35-.13-12.77-.92-.3-2.49,2.07-3.19,3.19-7.69,12.28-11.75,35.42-13.96,47.7,0,0-.57,2.84-1.99,24.3-.33,5.06-.46,7.77,1.37,9.57h.02Z"
        />
        <path
          id="LWS27"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M241.98,419.67c1.91.89,6.17-11.64,14.65-12.31,8.6-.68,15.99,11.26,17.77,14.13,5.94,9.59,6.7,20.22,8.2,41.47.45,6.34.83,11.7.55,19.14-.45,12.09-2.05,14.34-1.47,21.41.16,1.89,1.88,20.71,10.48,23.7,10.22,3.55,31.87-14.66,35.09-35.54.61-3.93-2.04-18.08-7.32-46.4-1.5-8.03-2.36-12.36-2.33-18.84.02-4.75.51-8.82.09-16.79-.34-6.59-.94-8.72-1.37-10.03-1.88-5.66-4.62-7.25-6.84-11.39-2.96-5.55-2.46-10.97-1.82-17.77,1.8-19.33,12.01-30.13,8.2-33.72-1.46-1.37-4.16-.89-6.23-.52-1.18.21-4.8,1.03-9.72,6.45-5.23,5.77-3.68,7.65-8.31,12.25-1.45,1.44-5.11,4.98-10.8,6.13-1.94.39-4.9.99-7.87-.34-4.25-1.92-4.28-5.91-6.29-6.19"
        />
        <path
          id="LWS26"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M283.96,538.14c4.73-9.6,21.67-10.84,23.24-10.94h.01c8.33-.52,9.87,2.55,21.36,1.97,7.69-.39,9.31-1.88,16-1.51,5.54.3,9.36,1.6,16.41,4.1,16.83,5.99,25.25,8.99,28.25,10.48,8.18,4.07,13.96,8.18,25.52,16.41,11.58,8.24,13.09,10.39,14.13,12.3,2.55,4.72,2.61,11.35,2.73,24.61.1,10.29-.64,13.58-1.37,15.95-1.47,4.82-3.24,10.61-8.22,13.46-5.3,3.03-8.44-.59-16.38,2.49-4.72,1.83-4.63,3.5-9.57,5.47-5.58,2.22-10.52,2.01-14.13,1.82-2.41-.13-14.1-.93-26.43-9.57-10.06-7.05-8.27-10.71-15.49-13.22-10.23-3.55-22.89.64-23.7.91-6.09,2.08-13.18,7.93-15.96,6.39"
        />
        <path
          id="LWS25"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M286.7,297.53h0c.7.15,1.14-13.22,8.98-16.87,6.51-3.03,15.57,1.83,20.64,6.39,2.12,1.91,10.64,10.14,11.39,30.99,1.21,33.52-19.65,44.95-12.3,64.25,3.87,10.16,14.29,19.18,24.15,18.68,10.11-.51,17.2-10.89,20.96-16.41,2.48-3.63,6.23-9.28,6.38-16.86.1-4.89-1.37-7.09-2.89-13.01-1.22-4.79-1.45-10.6-1.86-22.02,0,0-.67-18.9.29-42.48.4-9.85.75-11.95,1.27-14.08,1.3-5.39,2.82-7.41,2.28-11.39-.55-4.07-2.61-5.46-4.78-9.56-2.73-5.15-4.22-11.91-3.42-17.32.86-5.86,8.7-12.48,10.54-16.34.34-.72.59-1.44.59-1.44,0,0,.77-2.27.72-4.55-.06-2.6-2.09-7.35-4.9-8.32-8.04-2.77-16.62,19.84-37.02,23.36-6.86,1.18-9.35-.77-10.03-1.37-5.95-5.27-.09-20.13-1.37-20.51"
        />
        <path
          id="LWS24"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M370.21,86.69c2.93-.25,3.67,9.95,9.43,11.41,1.85.47,3.42-.17,11.43-4.91,11.43-6.76,13.1-8.34,14.57-7.53,2.73,1.5-.73,8.22.74,22.43.59,5.7.94,9.13,3.06,13.23,2.26,4.38,4.34,5.31,5.35,9.52.46,1.91.51,3.74-.49,10.4-.82,5.4-1.22,8.1-2.22,12.25-1.91,7.94-1.58,15.12-.97,28.31.22,4.71.94,16.25,4.1,30.99,1.61,7.5,1.83,6.43,2.28,9.57,1.68,11.92-.91,31.81-15.04,40.56-10.93,6.77-27.34,5.99-30.99-.46-2.46-4.34,3-7.5,4.1-20.05,1-11.4-2.99-14.56-.46-23.7h.01c1.97-7.1,4.78-6.66,7.75-13.68,4.65-11,4.61-28.38-5.95-38.29-6.32-5.93-14.02-6.94-16.84-7.28-2.44-.29-14.64-1.73-21.42,5.47-2.43,2.58-1.69,3.64-6.84,13.22-2.59,4.82-5.56,10.34-6.38,10.03"
        />
        <path
          id="LWS23"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M216.07,638.39c3.42-2.48,6.55.85,11.39-1.37,3.02-1.38,4.68-4,10.48-19.14,4.57-11.92,5.92-16.52,10.03-17.77,2.01-.61,4.56-.39,6.38.91,3.53,2.52,2.7,7.89,3.47,14.37.56,4.62,2.4,9.08,6.1,17.98,5.4,13.02,10.4,14.96,21.42,31.44,7.78,11.64,12.35,21.24,14.58,25.97,7.42,15.75,11.14,23.63,11.39,33.72.31,12.31-4.05,33.63-18.23,38.28-11.85,3.89-26.12-6.18-33.27-15.04-6.37-7.9-9.26-19.39-15.04-42.38-3.51-13.95-4.88-22.53-10.48-24.15-6.14-1.77-11.02,7.49-15.95,5.47"
        />
        <path
          id="LWS22"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M288.98,661.63c1.56-1.28,4.83,2.89,12.3,4.1,8.44,1.37,15.08-2.18,18.68-3.65,12.14-4.92,18.78,2.17,57.87,11.39,23.74,5.6,37.43,6.79,39.63,15,.97,3.63-1.06,5.85.02,13.25,1.25,8.65,4.72,10.44,5.47,15.49,2.18,14.73-21.89,36.03-37.82,32.81-3.66-.74-5.53-2.5-10.94-2.73-8.62-.38-10.5,4.41-17.32,4.1-11.57-.52-14.04-9.97-25.06-10.48-9.17-.43-13.45,7.95-17.32,5.47-2.48-1.6-2.72-7.14-3.19-18.23-.18-4.33,0-4.45-.21-6.86-.76-8.28-3.72-14.4-5.01-17.07-16.59-34.35-18.86-41.16-17.1-42.6h0Z"
        />
        <path
          id="LWS21"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M366.45,71.05c-.82,4.65.75,8.4,3.77,15.61,2.68,6.4,4.02,9.6,6.71,10.82,4.8,2.17,10.64-1.84,19.91-8.21,9.18-6.31,16.05-11.03,15.26-15.89-.64-3.95-5.6-3.35-11.02-9.62-6.84-7.92-4.62-15.47-9.11-16.86-3.58-1.11-7.67,2.86-15.04,10.03-6.43,6.25-9.64,9.37-10.48,14.13h0Z"
        />
        <path
          id="LWS20"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M417.94,132.57c.57-.1,2.07-.66,4.56-13.22,2.13-10.75,3.2-16.13,2.28-22.33-.27-1.82-2.36-15.94-8.66-16.86-3.92-.57-7.53,4.26-7.75,4.56-2.03,2.78-2.33,5.76-2.28,11.39.09,10.75.13,16.12,2.11,21.92,1.88,5.51,6.36,15.13,9.74,14.54h0Z"
        />
        <path
          id="LWS19"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls4"
          d="M206.96,591.23c-.19,5.61-32.46,10.18-60.15,8.89-25.35-1.18-54.89-7.64-54.68-12.99"
        />
        <path
          id="LWS18"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls4"
          d="M237.72,450.43c.25-.69-.04-1.94-3.42-4.78-9.65-8.11-21.47-9.79-32.81-10.94h0c-25.87-2.63-48.3-2.74-48.3-2.74-6.65-.03-10.06.09-15.49-.91-13.53-2.49-21.58-8.61-23.63-6.2-1.25,1.47.59,5.14,1.99,7.11"
        />
        <path
          id="LWS17"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M372.83,226.9c2.39,1.33,1.72,6.61.46,15.95-1.38,10.21-2.08,15.31-3.65,15.49-3.42.4-12.47-18.19-5.47-27.34,2.25-2.94,6.47-5.32,8.66-4.1Z"
        />
        <path
          id="LWS16"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M316.32,668.01c11.09-2.19,71.97,4.16,123.04,48.3"
        />
        <path
          id="LWS15"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M112.39,716.32c-40.37,40.22-97.04,120.2-110.95,166.33"
        />
        <path
          id="LWS14"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M239.81,882.65c-17.6-28.55-63.54-53.61-102.57-56.05"
        />
        <path
          id="LWS13"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M137.23,826.59c-6.44-24.15,4.28-38.28,9.57-38.28"
        />
        <path
          id="LWS12"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M146.8,788.32c13.69.96,58.65-51.16,83.39-51.95"
        />
        <path
          id="LWS11"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M230.2,736.37c22.63,39.4,60.23,84.55,79.29,104.84"
        />
        <path
          id="LWS10"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M309.49,841.21c23.29,4.03,43.88-7.73,34.63-38.77"
        />
        <path
          id="LWS9"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M355.79,876.27c0-8.06,21.74,33.72,43.47-14.58"
        />
        <path
          id="LWS8"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M324.07,747.3c43.75,36.46,113.48,71.81,133.97,148.1"
        />
        <path
          id="LWS7"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M257.08,520.93c27.36,20.86.69,35.89,1.54,35.89"
        />
        <path
          id="LWS6"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M283.02,379.1c34.19,24.71-1.61,31.9-3.61,31.9"
        />
        <path
          id="LWS5"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M342.75,258.35c8.05,23.54-.2,29.99-12.76,22.32"
        />
        <path
          id="LWS4"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M393.34,139.86c15.04,20.96-5.1,22.78-11.39,22.78"
        />
        <path
          id="LWS3"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls5"
          d="M216.32,667.64c0-3.42,29.83-6.19,66.7-6.19"
        />
        <path
          id="LWS2"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls4"
          d="M216.32,667.64c-21.3,7.51-85.85,23.95-111.22,56.88"
        />
        <line
          id="LWS1"
          class="Lendenwirbelsaule_mit_angestellter_Kassette_lat_liegend1728_cls4"
          x1="232.55"
          y1="739.53"
          x2="246.86"
          y2="713.63"
        />
      </g>
    </svg>
  );
}

export default LendenwirbelsauleMitAngestellterKassetteLatLiegend1728;
