import style from "./style.scss";

function LinkerDaumen1FingerLatSitzend1305(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 266.5 480"
    >
      <g id="Thumb">
        <g id="Background">
          <path
            id="Background1"
            class="Daumen_lat_links1305_cls8"
            d="M226,476.3c2.1-6.5,4.9-17.4,6.9-27.6,15.8-55.8,12.6-91.2,29.1-163.7,3.9-16.8,5.7-32.8,1.8-46.9-10-36.1-17-73.9-18.4-115.1-.2-6.8-1.8-13.5-4.6-19.6-9.1-19.5-14.8-47-15.9-85.3-.1-3.8-1.4-7.5-3.9-10.4-4.8-5.4-12.7-7.4-23.7-5.8-11.3.7-20.4,12.6-27.2,35.1-8.3,37.1-9.3,66.7,4.4,81.1-4.7,12.6-5.9,31.1-4.4,54.1.5,17.4-3.4,30.6-12.4,38.9-13.5-8.6-29.2-12.8-46.9-13.2-15.6-34.8-31.4-66.9-47.7-91.1-5.9-6.8-20.2-14.8-35.1-22.8,0,0-28.4,397.3-28.1,396.1s226-3.6,226-3.6h0Z"
          />
        </g>
        <path
          id="partoffinger29"
          class="Daumen_lat_links1305_cls2"
          d="M211.5,455.7c11.8.5,16.3-12.5,17-32.8l9.4-61.4c6.3-21.7,10.1-46.1,12.1-72.3,3.9-12.8,12.5-25.5,3.3-37.4-4.6-5.1-10.8-9.2-18.8-12.5-5.1-2.1-10.6-2.7-16-1.6-9.2,1.8-16.4,7.2-20.2,19.3-5.8,8.9-4.9,15.7,3,20.4,2.2,7.4,2.5,14.7.6,21.9-2.1,17.3-6.2,38.7-10.9,61.7-7.5,17.8-18.4,33.2-36.8,43.1l-7.3,23.7c-2.8,6.9-1.3,11.3,8.5,10.9,12.8-1.4,24.9,3.9,36.2,16.1,5.5,6.8,10.8,8.3,15.8,2.9,1.6.3,3.2-.5,3.9-2h0Z"
        />
        <path
          id="partoffinger28"
          class="Daumen_lat_links1305_cls2"
          d="M249,227c3-3.2,2.4-6.9-.7-11.1-.6-.8-1.1-1.8-1.4-2.8-6.8-23.5-9.4-49.6-9.8-77,0-1.7.3-3.4.9-5,2.9-8.1-.3-15.3-10.7-21.4-5.5-6.8-13.2-8.1-22.2-6.1-7.2,3.2-11.5,8-12.1,14.9-.6,5.8,1.4,10,5.1,13.1,2.7,2.2,4.7,5.1,5.5,8.5,2.9,12.2,1.7,26.7-3.3,43.3l-4.7,13.4c-1.4,4.2-3.5,8.1-6,11.8-4.6,7.1-4.7,14.4.3,21.9,3.2,6.9,7.3,8.2,11.9,6.7,2.9-.9,5.9-1.5,8.9-1.3,9.7.4,18.1,1.5,24.1,3.6,4.2,1.4,9-.5,10.8-4.6.5-1.1.9-2.3,1.2-3.6.4-1.6,1.1-3.1,2.2-4.3h0Z"
        />
        <path
          id="partoffinger27"
          class="Daumen_lat_links1305_cls2"
          d="M228.8,102.7c2.9-4.6,1.5-8.2-1.8-11.3-7.1-15.9-8.5-33-8.4-50.4-.7-4.3-1.7-8.3-3.1-12-.5-1.2-.6-2.5-.4-3.8l1.1-8c0-.4,0-.7-.3-1-3.9-4-8-4.3-12.1,1.6-6.6,7.4-7.9,16.3-6.5,26.1.2,1.6,0,3.2-.8,4.7-5.5,10.9-6.8,21.7-2.5,32.5-3-.2-5.3,2.3-6.1,10.6.2,6,2,8.8,4.9,9.7-.2,5.1,2.1,8.2,6.7,9.4,10.4-6.9,20.1-9.7,29.2-8.2h0Z"
        />
        <path
          id="partoffinger26"
          class="Daumen_lat_links1305_cls2"
          d="M206.3,256.1c4.3-5.4,3.4-9.3,0-12.4-4.8-2.9-10.4-3.3-17-1.1-2.9.9-5,3.7-4.9,6.8.2,3.9,1.8,7.9,1.8,7.9,0,0,7.2,6.4,13.5,6.4,2.7-.6,4.9-3.5,6.7-7.6h0Z"
        />
        <path
          id="partoffinger25"
          class="Daumen_lat_links1305_cls2"
          d="M170.1,252.4c1.4,8.2,6.1,10.3,12.4,9.4,5.3-3.2,5.9-7.3,5.5-11.5.6-4.9-.7-8.1-6.8-6.9-1.4.3-2.9.5-4.4.8-3.5.7-6.2,2.7-6.8,8.2h0Z"
        />
        <path
          id="partoffinger24"
          class="Daumen_lat_links1305_cls2"
          d="M23.1,160.7c13-7.3,26.4-9,40.4-4.2,17.4,7.3,28,17.4,26.4,32.8-3.4,4.8-3.3,11.4,2.4,20.7,6.3,47,13.2,90.3,22.2,121.2,8.6,38.6,20,64.7,34.9,74.7,6.1,7.7,8.1,15.3,5.8,22.8,5.5,12.3,8.4,23.3,4.2,30.7-5.1,4.5-11,3.6-17.3,0-9.4-2-17.1.8-21.9,11.9l-5.5,7.3"
        />
        <path
          id="partoffinger23"
          class="Daumen_lat_links1305_cls2"
          d="M25.2,102.7c4.4,5.2,9.9,8,16.7,8.2,5.7-.9,9.2,2.1,11.2,7.9,4.1,9.4,4,17.9-4.9,24.9-6.5-1.2-15.5,2.3-25.2,7"
        />
        <path
          id="partoffinger22"
          class="Daumen_lat_links1305_cls2"
          d="M18.2,212.9c5.3,1.8,9.6,6.2,12.1,14.9,15,44.5,28.4,86.9,37.7,124.6,4.3,19.4,4.3,45.3-3,82,1.6,8.9,2.2,17.2-.9,23.4-.9,7.7-.9,14.9,1.8,20.7"
        />
        <path
          id="partoffinger21"
          class="Daumen_lat_links1305_cls2"
          d="M10.3,352.1c14.9,26.8,32.2,53,51.7,78.7,12.2,11.8,19.6,23.6,21.3,35.2l6.4,12.5"
        />
        <path
          id="partoffinger20"
          class="Daumen_lat_links1305_cls2"
          d="M191.4,478.5c-1.4-6.9-4.5-12.1-9.1-15.8-.7-8.9-4.6-14.4-12.1-16.1-.4-10.6-7.9-16.2-18.5-19.8-8.7,1.5-16.5,5.3-23.1,11.9-9,10.8-14.3,24.5-17.6,39.8"
        />
        <path
          id="partoffinger19"
          class="Daumen_lat_links1305_cls2"
          d="M43.1,158.6c-1.3,8.6,2.7,11.5,10.3,10.6,7.2-3.2,9.9-8.6,9.7-15.4-2-6.1-5.5-8-10-6.8-6.1,3.4-10.1,7.1-10,11.5h0Z"
        />
        <path
          id="partoffinger18"
          class="Daumen_lat_links1305_cls3"
          d="M142.2,447.5c10.9.7,20.6.6,27.9-.9"
        />
        <path
          id="partoffinger17"
          class="Daumen_lat_links1305_cls3"
          d="M179.2,475.1c-1-4.1,0-8.3,3.1-12.5"
        />
        <path
          id="partoffinger16"
          class="Daumen_lat_links1305_cls3"
          d="M120.3,471.2c10.2-3.2,20.1-4.1,29.8-2.3,6.1,0,8.2,3.6,7.6,9.6"
        />
        <path
          id="partoffinger15"
          class="Daumen_lat_links1305_cls3"
          d="M58.3,437.5c-4.7-6.2-12-12.4-21.3-18.5-11.9-10-21.4-22.9-28.9-38.3"
        />
        <path
          id="partoffinger14"
          class="Daumen_lat_links1305_cls3"
          d="M248.8,273.4c-2.9,2.5-5.3,6.2-6.1,13.1-9.7,40.7-14.7,81.6-16.7,122.6"
        />
        <path
          id="partoffinger13"
          class="Daumen_lat_links1305_cls3"
          d="M226,271.6c-8.5,22.4-15.6,46.4-19.4,74.1-4.4,27.2-16.7,47.7-42.9,56.5"
        />
        <path
          id="partoffinger12"
          class="Daumen_lat_links1305_cls3"
          d="M212.1,284.1c.2-6.6-1.9-11.7-5.2-16.1"
        />
        <path
          id="partoffinger11"
          class="Daumen_lat_links1305_cls3"
          d="M231.7,130.1c-5.7,5.3-6.2,18.4-3.1,36.9,1.6,15.6,5.4,27.6,10.4,37.7"
        />
        <path
          id="partoffinger10"
          class="Daumen_lat_links1305_cls3"
          d="M211.3,132.5c5.9,21.6,3.1,42.3-9.7,62.1"
        />
        <path
          id="partoffinger9"
          class="Daumen_lat_links1305_cls3"
          d="M205.4,132.5c-2.1-8.6-2-16,4.1-19.9,5.3-3.2,11.2-3.9,17.7-2.9,4.5,2,6.2,6,5.6,11.4"
        />
        <path
          id="partoffinger8"
          class="Daumen_lat_links1305_cls3"
          d="M224.9,92.9c-11.2-12.2-15.7-35-13.9-67.6"
        />
        <path
          id="partoffinger7"
          class="Daumen_lat_links1305_cls3"
          d="M203.7,26.8c-.5,2.1-.2,3.8.8,5.2.7.9,1.1,2,1.2,3.2.6,7.1-.1,14-3.4,20.4-.5,1-.9,2-1.1,3.1-.6,3.7-.5,9.5-.5,15.3,0,.8-.2,1.5-.4,2.2-.5,1.4-.7,3.4-.4,6.4.2,2.4.1,4.6-.8,6.5-.8,1.6-1.2,3.3-1.3,5.1,0,1.5-.2,2.8-.5,3.9,7.9-6.7,16.3-7.3,25.2-3"
        />
        <path
          id="partoffinger6"
          class="Daumen_lat_links1305_cls3"
          d="M194.1,81.1c3.4.2-1.2,9.5.6,11.8.6,3.6,0,6.4-1.8,8.6"
        />
        <path
          id="partoffinger5"
          class="Daumen_lat_links1305_cls3"
          d="M238.3,233.3c-19.7-4.7-30.3-3.7-32.8,2.4"
        />
        <path
          id="partoffinger4"
          class="Daumen_lat_links1305_cls3"
          d="M144,423.3c-4.4-9-11.8-19.5-21.1-30.8-11.7-11.9-19.4-29.2-23.5-51.3-7.7-27.4-15.3-55.5-18.9-111.5-.2-3.9.2-7.8,1.1-11.5,2.5-9.9,3.1-17.7-1.5-19.6-2.7-1.6-4.9-4.4-6.2-9.6-.7-4.2-3.9-6.8-7.8-8.8"
        />
        <path
          id="partoffinger3"
          class="Daumen_lat_links1305_cls3"
          d="M87.9,194c-2.5-3.1-5.8-2.3-9.3-8.1-.6-.9-1-1.9-1.1-3-.6-5.1-12.7-11.2-11.4-20.8"
        />
        <path
          id="partoffinger2"
          class="Daumen_lat_links1305_cls3"
          d="M19.6,190.6c4.9,2.1,9,7.4,12,16.9l7.8,26.6c14,31.5,26.5,61,33.9,83.5,13.6,35,15.4,78,7.4,127.8"
        />
        <path
          id="partoffinger1"
          class="Daumen_lat_links1305_cls3"
          d="M19.6,202c2.8,0,5.7,3.6,8.5,10.9"
        />
        <g id="Outlines3">
          <path
            id="partofoutline13"
            class="Daumen_lat_links1305_cls3"
            d="M226.7,471.8c.6-1.8,1.2-3.7,1.8-5.7"
          />
          <path
            id="partofoutline12"
            class="Daumen_lat_links1305_cls1"
            d="M231.6,454.3c.5-2,.9-4.2,1.4-6.5,15.8-55.8,12.6-91.2,29.1-163.7,3.9-16.8,5.7-32.8,1.8-46.9-10-36.1-17-73.9-18.4-115.1-.2-6.8-1.8-13.5-4.6-19.6-9.1-19.5-14.8-47-15.9-85.3-.1-3.8-1.4-7.5-3.9-10.4-4.8-5.4-12.7-7.4-23.7-5.8-11.3.7-20.4,12.6-27.2,35.1-6.6,29.8-8.6,54.8-2,71"
          />
          <path
            id="partofoutline11"
            class="Daumen_lat_links1305_cls3"
            d="M170.9,112.5c1.1,1.7,2.3,3.3,3.6,4.8-.7,1.8-1.2,3.7-1.8,5.7"
          />
          <path
            id="partofoutline10"
            class="Daumen_lat_links1305_cls1"
            d="M170.6,134.9c-1.2,10.3-1.3,22.6-.4,36.5.4,11.8-1.3,21.6-5.2,29.3"
          />
          <path
            id="partofoutline9"
            class="Daumen_lat_links1305_cls3"
            d="M161.8,205.8c-1.2,1.6-2.5,3.1-4,4.5-1.7-1.1-3.4-2.1-5.2-3"
          />
          <path
            id="partofoutline8"
            class="Daumen_lat_links1305_cls1"
            d="M141.2,202.2c-5.8-2-11.8-3.4-18.1-4.3"
          />
          <path
            id="partofoutline7"
            class="Daumen_lat_links1305_cls3"
            d="M116.9,197.3c-2-.1-4-.2-6-.3-.8-1.8-1.7-3.6-2.5-5.5"
          />
          <path
            id="partofoutline6"
            class="Daumen_lat_links1305_cls1"
            d="M103.4,180.5c-13.2-28.4-26.5-54.3-40.2-74.7-4.5-5.2-13.8-11-24.5-17"
          />
          <path
            id="partofoutline5"
            class="Daumen_lat_links1305_cls3"
            d="M33.4,85.9c-1.7-.9-3.5-1.9-5.3-2.9"
          />
        </g>
        <g id="Outlines2">
          <path
            id="partofoutline4"
            class="Daumen_lat_links1305_cls3"
            d="M157.8,210.2c1.8.9,3.5,1.8,5.3,2.9"
          />
          <path
            id="partofoutline3"
            class="Daumen_lat_links1305_cls3"
            d="M169.7,217.5c1.6,1.1,3.2,2.3,4.9,3.5"
          />
        </g>
        <g id="Outlines">
          <path
            id="partofoutline2"
            class="Daumen_lat_links1305_cls3"
            d="M151.9,227.7c.3-1.8.8-3.8,1.5-5.8"
          />
          <path
            id="partofoutline1"
            class="Daumen_lat_links1305_cls3"
            d="M154.9,218.2c.8-1.7,1.7-3.5,2.8-5.3"
          />
        </g>
      </g>
      <g id="Highlights">
        <g id="Thumbhighlight">
          <path
            id="Thumbhighlight5"
            class="fullfinger1305Fill"
            style={{
              fill: props.colors.fullfinger1305Fill,
              stroke: props.colors.fullfinger1305Fill,
              opacity: props.colors.fullfinger1305Fill,
            }}
            d="M229.1,102.7c2.9-4.6,1.5-8.2-1.8-11.3-7.1-15.9-8.5-33-8.4-50.4-.7-4.3-1.7-8.3-3.1-12-.5-1.2-.6-2.5-.4-3.8l1.1-8c0-.4,0-.7-.3-1-3.9-4-8-4.3-12.1,1.6-6.6,7.4-7.9,16.3-6.5,26.1.2,1.6,0,3.2-.8,4.7-5.5,10.9-6.8,21.7-2.5,32.5-3-.2-5.3,2.3-6.1,10.6.2,6,2,8.8,4.9,9.7-.2,5.1,2.1,8.2,6.7,9.4,10.4-6.9,20.1-9.7,29.2-8.2h0Z"
          />
          <path
            id="Thumbhighlight4"
            class="fullfinger1305Fill"
            style={{
              fill: props.colors.fullfinger1305Fill,
              stroke: props.colors.fullfinger1305Fill,
              opacity: props.colors.fullfinger1305Fill,
            }}
            d="M249.1,227.5c3-3.2,2.4-6.9-.7-11.1-.6-.8-1.1-1.8-1.4-2.8-6.8-23.5-9.4-49.6-9.8-77,0-1.7.3-3.4.9-5,2.9-8.1-.3-15.3-10.7-21.4-5.5-6.8-13.2-8.1-22.2-6.1-7.2,3.2-11.5,8-12.1,14.9-.6,5.8,1.4,10,5.1,13.1,2.7,2.2,4.7,5.1,5.5,8.5,2.9,12.2,1.7,26.7-3.3,43.3l-4.7,13.4c-1.4,4.2-3.5,8.1-6,11.8-4.6,7.1-4.7,14.4.3,21.9,3.2,6.9,7.3,8.2,11.9,6.7,2.9-.9,5.9-1.5,8.9-1.3,9.7.4,18.1,1.5,24.1,3.6,4.2,1.4,9-.5,10.8-4.6.5-1.1.9-2.3,1.2-3.6.4-1.6,1.1-3.1,2.2-4.3h0Z"
          />
          <path
            id="Thumbhighlight3"
            class="fullfinger1305Fill"
            style={{
              fill: props.colors.fullfinger1305Fill,
              stroke: props.colors.fullfinger1305Fill,
              opacity: props.colors.fullfinger1305Fill,
            }}
            d="M211.5,455.7c11.8.5,16.3-12.5,17-32.8l9.4-61.4c6.3-21.7,10.1-46.1,12.1-72.3,3.9-12.8,12.5-25.5,3.3-37.4-4.6-5.1-10.8-9.2-18.8-12.5-5.1-2.1-10.6-2.7-16-1.6-9.2,1.8-16.4,7.2-20.2,19.3-5.8,8.9-4.9,15.7,3,20.4,2.2,7.4,2.5,14.7.6,21.9-2.1,17.3-6.2,38.7-10.9,61.7-7.5,17.8-18.4,33.2-36.8,43.1l-7.3,23.7c-2.8,6.9-1.3,11.3,8.5,10.9,12.8-1.4,24.9,3.9,36.2,16.1,5.5,6.8,10.8,8.3,15.8,2.9,1.6.3,3.2-.5,3.9-2h0Z"
          />
          <path
            id="Thumbhighlight2"
            class="fullfinger1305Fill"
            style={{
              fill: props.colors.fullfinger1305Fill,
              stroke: props.colors.fullfinger1305Fill,
              opacity: props.colors.fullfinger1305Fill,
            }}
            d="M206.3,256.1c4.3-5.4,3.4-9.3,0-12.4-4.8-2.9-10.4-3.3-17-1.1-2.9.9-5,3.7-4.9,6.8.2,3.9,1.8,7.9,1.8,7.9,0,0,7.2,6.4,13.5,6.4,2.7-.6,4.9-3.5,6.7-7.6h0Z"
          />
          <path
            id="Thumbhighlight1"
            class="fullfinger1305Fill"
            style={{
              fill: props.colors.fullfinger1305Fill,
              stroke: props.colors.fullfinger1305Fill,
              opacity: props.colors.fullfinger1305Fill,
            }}
            d="M170.1,252.4c1.4,8.2,6.1,10.3,12.4,9.4,5.3-3.2,5.9-7.3,5.5-11.5.6-4.9-.7-8.1-6.8-6.9-1.4.3-2.9.5-4.4.8-3.5.7-6.2,2.7-6.8,8.2h0Z"
          />
        </g>
        <ellipse
          id="Thumbbasejoint_Highlight"
          data-name="Thumbbasejoint Highlight"
          class="Daumengrundgelenk1305Fill"
          style={{
            fill: props.colors.Daumengrundgelenk1305Fill,
            stroke: props.colors.Daumengrundgelenk1305Fill,
            opacity: props.colors.Daumengrundgelenk1305Fill,
          }}
          cx="217.7"
          cy="237.2"
          rx="38.6"
          ry="13.1"
          transform="translate(-23.6 24) rotate(-6)"
        />
        <path
          id="Basemiddlehand_Bone_highligt"
          data-name="Basemiddlehand Bone highligt"
          class="erstenMHK1305Fill"
          style={{
            fill: props.colors.erstenMHK1305Fill,
            stroke: props.colors.erstenMHK1305Fill,
            opacity: props.colors.erstenMHK1305Fill,
          }}
          d="M187.6,450.6c-2.2-2.1-13.7-13.6-35.1-12-10.3-.1-9-6.1-1.6-23.3l3.5-11.2c8.6-5.7,22.1-16.6,21.8-17.8,0,0,35,13.5,55.2,18.5-3.1,11,0,41.9-13.4,49.6,0,0-2.8,1.2-5.9,1.9-1.7.3-2.8,2-4.3,2.2-7.8,8.8-20-7.8-20-7.8h0Z"
        />
        <path
          id="Thumbfingertip_Highlight"
          data-name="Thumbfingertip Highlight"
          class="fingertip1305Fill"
          style={{
            fill: props.colors.fingertip1305Fill,
            stroke: props.colors.fingertip1305Fill,
            opacity: props.colors.fingertip1305Fill,
          }}
          d="M215,28.8c-3.6-10.3-12.7-4.2-17.7,2.2,1.9-8.8,7.8-17,11.4-17.7,3.7-.3,6.4,1.2,7.5,5,.4,2.8-.3,6.5-1.2,10.4h0Z"
        />
      </g>
    </svg>
  );
}

export default LinkerDaumen1FingerLatSitzend1305;
