import style from "./style.scss";

function Linker3FingerDpSitzend1319(props) {
  return (
<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190.46 474.16">

<g id="Ebene_3" data-name="Ebene 3">
  <path id="OUtline" class="Linker_Finger3_dp_sitzend1319_cls8" d="M.86,309.87c9.7,23.5,19.6,41.4,30.1,38.6,12.3.1,17.4-8.8,14.3-27.6-6.6-49.6-10.3-96.8-9.7-140.4,2.1-59.3,3.8-122.9,9.7-147.6C50.56,12.47,62.36,1.17,82.06.97c17.7-2.2,29.6,3,31.8,23.7l3.3,98.1c1,30.1,4.1,59.9,10,89.6,4.5,28,7.6,57.8,5.5,94.2,1.1,21.9,5.7,35.1,16.7,32.8,11.1-1.3,18.6-36.9,23.7-96.3l17.4,230.7-187.5-5.9L.86,309.87Z"/>
</g>
<g id="_3rdfinger" data-name=" 3rdfinger">
  <g id="highlights">
    <path id="middlephalanxH" class="FullFinger1319Fill" style={{
          fill: props.colors.FullFinger1319Fill,
          stroke: props.colors.FullFinger1319Fill,
          opacity: props.colors.FullFinger1319Fill,
        }} d="M49.31,194.97h0c5-9.3,7.3-20.9,7.9-34.1.1-2.9.6-5.9,1.2-8.8,3.7-16.2,2.8-28.2-3.1-35.5-1.7-2.1-2.3-4.8-2-7.4.4-3.5.9-6.2,1.8-8,.6-1.1,1-2.2,1.1-3.4.7-7.6,3.1-10.1,13.2-8.1,4,1.3,8.1.6,12.6-.6,8.1-4,13.4-1,12.8,6.7-.2,1.9.4,3.7,1.5,5.2,3.8,4.8,4,9.1,1.1,15.3-1,2-1.3,4.3-1,6.5.2,19.7,3.4,40.5,9,62.1.6,2.1,1.6,4.2,3.1,5.8,3.3,3.6,2.9,8.3-.3,13.8-.9,1.6-2.6,2.4-4.4,2.3-6.6-.5-15.3,2-24.5,5.1-1.3.4-2.6.4-3.9,0-7.5-2.5-14.6-3.9-20.8-3.6-4.1-.9-5.6-4.4-6.2-8.9-.1-1.5.2-3.1.9-4.4h0Z"/>
    <path id="enphalanxH" class="FullFinger1319Fill" style={{
          fill: props.colors.FullFinger1319Fill,
          stroke: props.colors.FullFinger1319Fill,
          opacity: props.colors.FullFinger1319Fill,
        }} d="M57.26,84.17h0c-3.2-1.8-4.1-4.3-3.1-7.3,7.6-8.4,12.6-17.7,12.6-29.7,0-1-.2-2.1-.7-3-1.2-2.3-1.3-6-.9-10.2-.5-5.6,3.2-10.7,9.5-15.5,6.2-4.4,16.5-2.3,18.3.5,3.8,9.4,3.8,17.4,0,23.9-1.5-.7-5.4,18.8,4.2,27.5,3.3,2.9,3.8,5.7.5,8.5-.5,4.9-4.4,6.2-9.9,5.9-5.1,2.5-10.2,3-15.4,1.7-9.7-2-12,2.5-15.1-2.3h0Z"/>
    <path id="basephalanxH" class="FullFinger1319Fill" style={{
          fill: props.colors.FullFinger1319Fill,
          stroke: props.colors.FullFinger1319Fill,
          opacity: props.colors.FullFinger1319Fill,
        }} d="M85.65,413.69c-9.3,8.1-15.3,6.5-19.4,0-9.2-7.1-7-16.4,0-26.7,8.8-22.6,6.5-57.8.2-97.7-.1-.7-.1-1.4-.1-2.1.6-9.8-.5-20-4-30.9-.5-1.4-1.4-2.7-2.5-3.7-2.3-2-3.2-5.4-2.7-10.3.2-1.6.2-3.2,0-4.8-1.9-16.1,1.2-20.7,6.1-21.5l11.1,1.1c2.9.3,5.9-.1,8.6-1.3,8.6-3.5,16.5-5.1,22.5-2.5,1.3.6,2.4,1.7,3.1,3,2.2,4.5,3.1,9.1,2.5,13.6-.2,1.7.3,3.4,1.3,4.8,2.3,2.9,3.1,6.1.9,9.5-2.3,6.1-2.3,12.9-.5,20.2.3,1.1.5,2.3.6,3.4.4,6.8.9,15.3,1.4,25.3,1.9,41.1,8.8,73.9,24.6,92.4,4.2,8.1,3.9,15.2,0,21.6.1,8.2-3.3,8.8-7.9,6.7-16.9-5.8-32.5-6.6-45.8-.1h0Z"/>
    <ellipse id="basejointhighlight" class="Grundgelenk1319Fill" style={{
          fill: props.colors.Grundgelenk1319Fill,
          stroke: props.colors.Grundgelenk1319Fill,
          opacity: props.colors.Grundgelenk1319Fill,
        }} cx="102.96" cy="417.57" rx="40.4" ry="10.8"/>
    <path id="fingertiphighlight" class="Fingerkuppe1319Fill" style={{
          fill: props.colors.Fingerkuppe1319Fill,
          stroke: props.colors.Fingerkuppe1319Fill,
          opacity: props.colors.Fingerkuppe1319Fill,
        }} d="M65.26,30.77c12.9-10.5,22.8-10.4,30.4-2.2-.7-6.6-3-12.3-5.6-12.6-4.2-1.9-8.8-1.7-13.8,0-6.3,3.3-9.9,8.3-11,14.8Z"/>
  </g>
  <path id="basephalanx" class="Linker_Finger3_dp_sitzend1319_cls2" d="M84.36,414.07c-9.3,8.1-15.3,6.5-19.4,0-9.2-7.1-7-16.4,0-26.7,8.8-22.6,6.5-57.8.2-97.7-.1-.7-.1-1.4-.1-2.1.6-9.8-.5-20-4-30.9-.5-1.4-1.4-2.7-2.5-3.7-2.3-2-3.2-5.4-2.7-10.3.2-1.6.2-3.2,0-4.8-1.9-16.1,1.2-20.7,6.1-21.5l11.1,1.1c2.9.3,5.9-.1,8.6-1.3,8.6-3.5,16.5-5.1,22.5-2.5,1.3.6,2.4,1.7,3.1,3,2.2,4.5,3.1,9.1,2.5,13.6-.2,1.7.3,3.4,1.3,4.8,2.3,2.9,3.1,6.1.9,9.5-2.3,6.1-2.3,12.9-.5,20.2.3,1.1.5,2.3.6,3.4.4,6.8.9,15.3,1.4,25.3,1.9,41.1,8.8,73.9,24.6,92.4,4.2,8.1,3.9,15.2,0,21.6.1,8.2-3.3,8.8-7.9,6.7-16.9-5.8-32.5-6.6-45.8-.1h0Z"/>
  <path id="middlehand" class="Linker_Finger3_dp_sitzend1319_cls2" d="M73.16,470.57c0-7,1.5-12.3,3.3-23.6,4.2-17.3,12.8-25.6,24.6-27.3,5.6-1,11.1-1.6,16.1-1,8,.9,14.8,6.3,17.6,13.9,4.9,13.2,6.6,24.2,6.3,39.7"/>
  <path id="middlephalanx" class="Linker_Finger3_dp_sitzend1319_cls2" d="M49.86,194.77h0c5-9.3,7.3-20.9,7.9-34.1.1-2.9.6-5.9,1.2-8.8,3.7-16.2,2.8-28.2-3.1-35.5-1.7-2.1-2.3-4.8-2-7.4.4-3.5.9-6.2,1.8-8,.6-1.1,1-2.2,1.1-3.4.7-7.6,3.1-10.1,13.2-8.1,4,1.3,8.1.6,12.6-.6,8.1-4,13.4-1,12.8,6.7-.2,1.9.4,3.7,1.5,5.2,3.8,4.8,4,9.1,1.1,15.3-1,2-1.3,4.3-1,6.5.2,19.7,3.4,40.5,9,62.1.6,2.1,1.6,4.2,3.1,5.8,3.3,3.6,2.9,8.3-.3,13.8-.9,1.6-2.6,2.4-4.4,2.3-6.6-.5-15.3,2-24.5,5.1-1.3.4-2.6.4-3.9,0-7.5-2.5-14.6-3.9-20.8-3.6-4.1-.9-5.6-4.4-6.2-8.9-.1-1.5.2-3.1.9-4.4h0Z"/>
  <path id="enphalanx" class="Linker_Finger3_dp_sitzend1319_cls2" d="M56.76,82.97h0c-3.2-1.8-4.1-4.3-3.1-7.3,7.6-8.4,12.6-17.7,12.6-29.7,0-1-.2-2.1-.7-3-1.2-2.3-1.3-6-.9-10.2-.5-5.6,3.2-10.7,9.5-15.5,6.2-4.4,16.5-2.3,18.3.5,3.8,9.4,3.8,17.4,0,23.9-1.5-.7-5.4,18.8,4.2,27.5,3.3,2.9,3.8,5.7.5,8.5-.5,4.9-4.4,6.2-9.9,5.9-5.1,2.5-10.2,3-15.4,1.7-9.7-2-12,2.5-15.1-2.3h0Z"/>
  <path id="middlehand2" class="Linker_Finger3_dp_sitzend1319_cls4" d="M88.36,435.97c2.3,9.9.8,16.3-3.5,20-3.9,4.5-6.8,9.1-6.3,14.5"/>
  <path id="joint" class="Linker_Finger3_dp_sitzend1319_cls4" d="M80.36,417.07c1.4.5,3.2.9,5.2,1.2,8.3,1.4,16.8.5,24.7-2.4,4.8-1.8,10-2.9,15.7-3.2"/>
  <path id="middlehand1" class="Linker_Finger3_dp_sitzend1319_cls4" d="M140.16,455.07c5.6,2.2,10.1,6.8,11.8,18.6"/>
  <path id="basephalanx4" class="Linker_Finger3_dp_sitzend1319_cls4" d="M66.76,388.97c4.5-4,8.1-13.5,10.5-31.2l4.1-45.2c-2-14-2.7-26.5.2-34.8.4-1,.6-2.1.6-3.3.2-14-2.3-22.4-7.7-24.9"/>
  <path id="basephalanx3" class="Linker_Finger3_dp_sitzend1319_cls4" d="M124.86,368.47c-8.2-13.2-14.6-25.8-15.6-36.8-1.2-7.4-3.4-14.3-6.4-20.8-.3-.8-.6-1.6-.6-2.5-.4-11.8-1.7-21.6-4.8-27.7-1.4-2.8-2-5.8-2-8.9,0-8.3-.8-13.9-2.2-17.4"/>
  <path id="basephalanx2" class="Linker_Finger3_dp_sitzend1319_cls4" d="M56.46,239.27c3.2-.2,4.7-5.5,4.6-15.8"/>
  <path id="basephalanx1" class="Linker_Finger3_dp_sitzend1319_cls4" d="M109.76,231.37c-3.1.1-5.3-3.8-6.8-10.4"/>
  <path id="middlephalanx3" class="Linker_Finger3_dp_sitzend1319_cls4" d="M57.16,202.97c5.1-1.2,10.4-.5,15.9,2,1.5.7,3.2,1.1,4.9,1.2,2.3.1,4.6-.5,6.6-1.9,4.2-2.5,10.4-3.3,17.4-3.6"/>
  <path id="middlephalanx2" class="Linker_Finger3_dp_sitzend1319_cls4" d="M53.26,201.67c.9-5.2,2.4-9.8,4.6-13.6,4.4-9.9,8.3-21.1,11.6-33.6.6-6.4,2.7-13.4,5.6-20.9,1.6-11.3.4-19.9-3.9-25.5"/>
  <path id="middlephalanx1" class="Linker_Finger3_dp_sitzend1319_cls4" d="M87.86,104.57c-.9,2-1.5,4.1-1.9,6.3,1,7.9,1.4,15.6,1.1,22.9-.1,2.9.1,5.8.6,8.6,3.1,18.1,8.4,35.5,16.8,51.9"/>
  <path id="endphalanx1" class="Linker_Finger3_dp_sitzend1319_cls4" d="M58.86,76.97c-.4-2.3,1.4-4.9,4.9-7.8,4.4-5.9,7.8-12.1,9.1-19.2.3-1.9.5-3.8.6-5.7.1-6.9,1.5-11.3,4.9-11.3,4-.6,5.8,2.3,5.3,9-.3,3.9-.4,7.8-.2,11.7.6,10,2.9,15,7.2,14.6"/>
  <g id="softpart">
    <path id="softpart3" class="Linker_Finger3_dp_sitzend1319_cls4" d="M.86,309.87c.8,1.9,1.6,3.7,2.3,5.5"/>
    <path id="softpart2" class="Linker_Finger3_dp_sitzend1319_cls4" d="M172.56,249.07c.2-2,.4-4,.5-6"/>
    <path id="softpart1" class="Linker_Finger3_dp_sitzend1319_cls1" d="M8.16,326.17c7.4,14.9,14.9,24.4,22.8,22.3,12.3.1,17.4-8.8,14.3-27.6-6.6-49.6-10.3-96.8-9.7-140.4,2.1-59.3,3.8-122.9,9.7-147.6C50.56,12.47,62.36,1.17,82.06.97c17.7-2.2,29.6,3,31.8,23.7l3.3,98.1c1,30.1,4.1,59.9,10,89.6,4.5,28,7.6,57.8,5.5,94.2,1.1,21.9,5.7,35.1,16.7,32.8,10.3-1.2,17.6-32.2,22.6-84.4"/>
  </g>
  <path id="nextfinger4" class="Linker_Finger3_dp_sitzend1319_cls5" d="M182.76,359.07c-2.4,16.3-5.9,30.9-11,42.5-.9,2-2.3,3.8-4,5.1l-2.5,1.9c-1.3,1-2.1,2.5-2.3,4.1-1.1,8.6-1.5,16.9-.3,24.5.3,2,1.5,3.7,3,4.9,1.9,1.5,3.2,3.3,3.8,5.4.6,1.9,2.3,3.2,4.2,3.5,5.1.8,10.4-.7,15.7-3.9"/>
  <path id="nextfinger3" class="Linker_Finger3_dp_sitzend1319_cls5" d="M188.56,455.07c-10.4,3.4-17.7,9.9-22.6,18.6"/>
  <path id="nextfinger2" class="Linker_Finger3_dp_sitzend1319_cls5" d="M37.76,469.87l-1.1-5.5c3.8-7.7,2.5-12.9-2.6-16.4-3.3-23.9-14.7-33.2-31.1-33.4"/>
  <path id="nextfinger1" class="Linker_Finger3_dp_sitzend1319_cls5" d="M.86,362.47c4.4,7.4,9.1,13.8,14.4,17.4,2.7,1.8,4.9,4.3,5.9,7.4,1.4,4,1.9,8.9,1.5,14.7.8,9.1-3.9,9.7-10.9,6.8-2.9-1.1-6.2-.9-9.6,0"/>
  <path id="nextfinger" class="Linker_Finger3_dp_sitzend1319_cls3" d="M22.86,427.67c2.4,4.2,4.4,8.2,4.1,11.1-.1,1.1.1,2.3.7,3.3,1.4,2.7,3.4,4.8,6.5,5.9"/>
</g>
</svg>
  );
}

export default Linker3FingerDpSitzend1319;
