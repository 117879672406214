import style from "./style.scss";

export default function NasenbeinLatSitzend1913(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.85 508.99">
      <g
        id="nasal_bone_"
        data-name="nasal bone
"
      >
        <path
          id="background"
          class="Nasenbein_lat1913_cls6"
          d="M137.94,7.34c-2.17,3.61-1.31,9.39.51,20.81,2.66,16.73,5.87,20.37,4.49,28.19-1.48,8.39-6.87,13.79-11,18,0,0-11.62,11.85-57.27,63.62-15.82,17.94-13.57,17.71-25.73,30.38-15.83,16.48-22.86,20.23-33.37,34.91-9.12,12.74-14.97,21.17-14.88,33.42.12,18,12.97,30.1,14.26,31.27,6.2,5.66,12.64,7.96,22.01,11.32,17.33,6.2,22.95,3.38,28.83,9.43,5.81,5.98,5.82,14.41,5.83,24.76.02,20.25-9.17,35.45-10.69,37.89-5.9,9.49-8.92,9.16-10,15-2.17,11.75,7.81,25.2,18.58,32.22,12.94,8.43,26.24,7.06,26.42,10.78.13,2.68-6.81,3.26-16.26,9.55-5.93,3.94-15.65,10.42-18.58,21.89-1.85,7.26-1.3,17.8,4.83,24.56,5.58,6.15,11.21,4.11,18,11,6.13,6.21,6.95,13.36,8,16,8.41,21.22,142.71,34.08,197-38,10.82-14.36,16.91-30.05,10-177-6.77-144.07-14.99-179.15-39-214-4.53-6.57-38.41-54.49-86-62-5.1-.8-29.58-4.67-36,6h.02Z"
        />
        <g id="outlines">
          <path
            id="outline_1"
            data-name="outline 1"
            class="Nasenbein_lat1913_cls3"
            d="M137.94,7.34c-.53,6.81-.3,12.3,0,16,.47,5.84,1.44,11.32,3,20,1.83,10.2,2.22,10.67,2,13-.86,9.17-8.15,15.47-11,18-17.12,15.2-30.4,34.25-46,51-25.27,27.13-19.28,26.57-37,43-5.37,4.98-17.5,15.76-31,32-4.76,5.72-9.51,11.91-13,21-2.47,6.45-5.6,14.59-4,24,3.17,18.64,22.63,28.81,25,30,2.34,1.18,12.56,4.46,33,11,6.92,2.22,9.69,3.07,12,6,1.33,1.69,4.09,6.08-1,30-3.14,14.76-4.71,22.13-9,29-5.29,8.47-8.88,8.94-10,15-1.79,9.66,5.47,18.49,10,24,8.66,10.53,19.22,14.6,23,16,4.76,1.77,8.97,2.59,12,3"
          />
          <path
            id="outline_2"
            data-name="outline 2"
            class="Nasenbein_lat1913_cls3"
            d="M91.94,492.34c-.87-4.81-2.86-11.16-8-16-6.46-6.08-12.17-4.4-18-11-5.64-6.38-6.01-14.41-6-17,.02-8,3.7-13.6,6-17,7.97-11.78,20.36-15.77,25-17"
          />
        </g>
        <path
          id="skull"
          class="Nasenbein_lat1913_cls2"
          d="M160.94,9.34c-3.74,9.68-3.14,16.58-2,21,2.57,9.91,9.43,13.24,10,24,.3,5.62-1.29,10.07-2,12-2.44,6.65-5.33,7.67-12,17-6.99,9.79-5.52,11.04-10,15-4.79,4.23-7.27,3.5-17,8-2.89,1.34-12.03,5.73-21,13-1.22.99-17.91,13.61-16,17,1.34,2.38,10.94-1.39,12,1,.56,1.26-1.62,3.42-3.38,5.17-4.95,4.92-8.76,5.42-8.62,6.83.2,2.05,8.67,4.79,16,2,5.3-2.02,7.2-5.85,9-5,2.42,1.15-1.31,7.94,2,15,2.64,5.64,6.81,5.12,8,10,1.29,5.32-3.19,7.87-2,13,1.03,4.42,5.16,5.99,9,9,5.72,4.5,5.28,7.91,13,25,8.95,19.82,13.46,23.94,13,34-.32,6.95-2.73,10.46-4,12-.64.77-3.07,3.57-10,6-7.44,2.61-10.63,1.5-21,3-6.66.96-15.71,2.28-16,5-.29,2.74,8.7,3.09,15,10,6.99,7.67,6.42,18.76,6,27-.21,4.02-1.13,18.72-10,33-3.82,6.15-7.39,9.44-9,17-1.13,5.27-.23,7.63-2,13-2.15,6.54-5.12,8.05-8,13-5.59,9.6-6.75,25.06,0,32,5.28,5.42,11.37,1.52,17,8,1.25,1.44,2.83,5.96,6,15,2.43,6.93,2.95,9.11,5,13,2.34,4.43,4.44,6.87,6,9,3.24,4.43,7.06,11.53,9,23"
        />
        <path
          id="mouth1"
          class="Nasenbein_lat1913_cls1"
          d="M107.94,278.34c9.62-1.33,17.57-.76,23,0,11.42,1.61,18.3,2.58,24,8,3.38,3.22,4.23,5.99,8,7,4.26,1.15,7.21-1.31,13-3,4.6-1.35,7.58-1.21,17-1,11,.24,27.43.51,48,1"
        />
        <path
          id="mouth2"
          class="Nasenbein_lat1913_cls1"
          d="M168.98,334.34c0-20.45,43.34-37,96.9-37"
        />
        <g id="teeth">
          <path
            id="teeth1"
            class="Nasenbein_lat1913_cls1"
            d="M107.94,378.34c3.48-4.87,6.49-9.25,9-13,4.96-7.41,7.44-11.11,10.5-16.38,5.35-9.18,6.53-12.88,7.5-12.62,2.06.56-.96,18.14-3,30-2.48,14.41-4.96,25.45-6,30-2.08,9.06-5.01,20.99-9,35"
          />
          <path
            id="teeth2"
            class="Nasenbein_lat1913_cls1"
            d="M108.94,422.34c.7-6.06,2.07-14.4,5-24,1.68-5.5,4.29-12.79,11-26,7.71-15.18,20.76-38.07,23-37,1.2.57-1.81,7.39-8,31-1.73,6.6-1.55,6.28-5,20-2.07,8.23-5.11,20.22-9,35"
          />
          <path
            id="teeth3"
            class="Nasenbein_lat1913_cls1"
            d="M162.94,413.34c-4.85-.41-11.57-.33-19,2-6.26,1.97-9.31,4.37-11,3-1.81-1.47-.18-5.78,5-21,3.75-11,5.62-16.5,6-18,3.09-12.12,1.84-15.91,6-22,3.19-4.67,8.58-9.24,11-8,2.44,1.25,1.3,8.11,0,16-1.19,7.19-2.49,10.51-4,17,0,0-2.19,9.41-3,19-1.66,19.68,3.69,27.97-1,32-1.98,1.7-6.12,2.97-16-1"
          />
          <path
            id="teeth4"
            class="Nasenbein_lat1913_cls1"
            d="M136.94,432.34c-2.29-3.35-4.92-8.43-4-14,.77-4.64,3.58-7.13,6.3-11,3.01-4.29,6.52-11.16,7.7-22"
          />
          <path
            id="teeth5"
            class="Nasenbein_lat1913_cls1"
            d="M193.94,354.34c-.95,8.02-1.07,14.55-1,19,.15,10.22.26,17.43,2,27,1.41,7.77,2.68,10.34,1,14-2.47,5.4-8.76,7.55-13,9-5.15,1.76-11.56,3.95-16,1-6.12-4.07-4.84-15.48-4-23,.83-7.41,2.64-11.93,8-27,3.11-8.74,5.55-15.78,7-20"
          />
          <path
            id="teeth6"
            class="Nasenbein_lat1913_cls1"
            d="M235.94,456.34c-1.13-9.13-.67-16.69,0-22,.97-7.62,1.93-14.55,7-21,1.88-2.39,4.94-6.17,10-7,3.19-.52,8.07-.02,10,3,1.72,2.68.05,5.7-1,11-.77,3.9-1.32,9.61,0,17"
          />
          <path
            id="teeth7"
            class="Nasenbein_lat1913_cls1"
            d="M261.24,425.79c0-6.33,10.6-11.45,23.7-11.45"
          />
          <line
            id="teeth8"
            class="Nasenbein_lat1913_cls1"
            x1="240.94"
            y1="368.34"
            x2="242.94"
            y2="413.34"
          />
          <line
            id="teeth9"
            class="Nasenbein_lat1913_cls1"
            x1="222.94"
            y1="354.34"
            x2="210.77"
            y2="414.34"
          />
          <path
            id="teeth10"
            class="Nasenbein_lat1913_cls1"
            d="M193.56,390.07c3.86-3.48,12.92,1.73,18.87,16.09"
          />
          <path
            id="teeth11"
            class="Nasenbein_lat1913_cls1"
            d="M136.94,432.34c2.91,27,6.75,44.15,13.5,54"
          />
          <path
            id="teeth12"
            class="Nasenbein_lat1913_cls1"
            d="M153.44,425.79c9.66,6.54,11.04,41.7,9.5,55.55"
          />
          <path
            id="teeth13"
            class="Nasenbein_lat1913_cls1"
            d="M168.98,414.34c18.97,5.73,19.13,37.77,18.96,60"
          />
          <path
            id="teeth14"
            class="Nasenbein_lat1913_cls1"
            d="M162.22,449.5c1.38-31.15,16.12-45.36,39.64-17.16"
          />
          <line
            id="teeth15"
            class="Nasenbein_lat1913_cls1"
            x1="206"
            y1="436.13"
            x2="206"
            y2="460.5"
          />
          <path
            id="teeth16"
            class="Nasenbein_lat1913_cls1"
            d="M206,436.13c0,4.54-10.06-21.79-19.32,8.21"
          />
          <path
            id="teeth17"
            class="Nasenbein_lat1913_cls1"
            d="M206,436.13c14.24-19.97,24.94-6.38,18.05,24.37"
          />
        </g>
        <g id="nose_section" data-name="nose section">
          <path
            id="nose_section1"
            data-name="nose section1"
            class="Nasenbein_lat1913_cls1"
            d="M35.94,251.34c-4.96-1.12-9.06-4.07-10-8-1.44-6.01,5.18-11.5,7-13,4.41-3.65,6.46-2.5,11-6,5.63-4.34,5.32-8.31,9-12,6.35-6.37,17.54-4.87,24-4,22.98,3.08,37.86,22.27,42,28"
          />
          <path
            id="nose_section2"
            data-name="nose section2"
            class="Nasenbein_lat1913_cls1"
            d="M59.94,250.34c0,4.45,42.06,27.93,64,7.98"
          />
          <path
            id="nose_section3"
            data-name="nose section3"
            class="Nasenbein_lat1913_cls1"
            d="M34.94,266.24c8.82-14.69,43.92-13.08,62,14.1"
          />
          <path
            id="nose_section4"
            data-name="nose section4"
            class="Nasenbein_lat1913_cls1"
            d="M34.94,273.29c6.15-20.31,32.44-1.7,62,24.05"
          />
          <path
            id="nose_section5"
            data-name="nose section5"
            class="Nasenbein_lat1913_cls1"
            d="M213.94,89.34c5.94.76,16.39,2.91,27,10,5.35,3.58,20.07,13.41,24,32,3.61,17.09-4.15,30.93-7,36-8.34,14.85-21.41,22.53-24,24-12.69,7.21-21.63,6.25-23,12-1.51,6.35,7.74,14.33,12,18,7.91,6.82,11.12,5.51,15,11,4.52,6.4,2.21,11.09,5,20,1.69,5.42,5.34,12.61,14,20"
          />
          <path
            id="nose_section6"
            data-name="nose section6"
            class="Nasenbein_lat1913_cls1"
            d="M117.94,161.34c4.95-4.81,9.02-8.93,12-12,5.7-5.87,8.61-8.89,12-13,2.1-2.55,3.1-3.94,9-12,7.23-9.88,10.84-14.83,11-15,9.92-11.07,25.15-17.12,28-14,1.57,1.72-2.23,4.48-2,11,.33,9.5,8.7,12.03,8,20-.44,5.07-3.97,5.69-5,11-1.34,6.9,3.13,13.55,7,18"
          />
          <path
            id="nose_section7"
            data-name="nose section7"
            class="Nasenbein_lat1913_cls1"
            d="M157.94,94.52c-1.44,8.07-22.44,35.54-55.23,54.05"
          />
          <path
            id="nose_section8"
            data-name="nose section8"
            class="Nasenbein_lat1913_cls1"
            d="M111.94,126.34c4.97-2.41,10.66-5.11,17-8,9.56-4.36,14.45-6.58,19.89-8.49,11.25-3.96,10.93-2.25,18.09-5.32,4.51-1.93,13.6-5.95,21.02-15.19,3.66-4.56,5.78-8.97,7-12"
          />
          <line
            id="nose_section9"
            data-name="nose section9"
            class="Nasenbein_lat1913_cls1"
            x1="187.94"
            y1="89.34"
            x2="153.44"
            y2="103.58"
          />
          <path
            id="nose_section10"
            data-name="nose section10"
            class="Nasenbein_lat1913_cls1"
            d="M171.94,198.34c-.67-9.89-4.02-14.56-7-17-5.46-4.47-10.47-2.09-16-7-1.85-1.64-3.68-4.03-7-16-3.16-11.37-1.8-14.94-5-23-2.15-5.42-5.96-14.44-8-17"
          />
          <polyline
            id="nose_section11"
            data-name="nose section11"
            class="Nasenbein_lat1913_cls1"
            points="168.94 54.34 174.44 61.34 182.04 57.84 182.04 66.34 187.96 66.34 186.67 77.34 194.94 77.34"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="nasal_bone_overlay"
          data-name="nasal bone overlay"
          class="Nasenbein1913Fill"
          style={{
            fill: props.colors.Nasenbein1913Fill,
            stroke: props.colors.Nasenbein1913Fill,
          }}
          d="M142.85,99.44c-10.55,5.37-27.08,11.06-42.26,25.02-5.79,4.57-8.83,8.25-9.53,11.19-.12.5.05,1.04.48,1.34,2.03,1.42,6.49-.75,9.9-.15.9.16,1.33,1.22.84,2-1.85,2.97-6.86,6.73-10.48,9.77-.48.4-.46,1.14.03,1.52,4.96,3.84,12.93,2.79,19.98-1.1,2.34-1.29,4.41-3,6.2-4.98l30.81-34.19c3.79-6.69,2.95-9.68-.16-10.86-1.89-.72-4.01-.46-5.81.46Z"
        />
        <path
          id="Spina_nasalis_anterior_overlay"
          data-name="Spina nasalis anterior overlay"
          class="SpinaNasalisAnterior1913Fill"
          style={{
            fill: props.colors.SpinaNasalisAnterior1913Fill,
            stroke: props.colors.SpinaNasalisAnterior1913Fill,
          }}
          d="M142.99,270.97c-14.95,1.2-27.77,2.95-32.81,5.39-1.67.81-1.82,3.13-.25,4.12,4.6,2.91,15.68,6.97,17.26,14.95,7.73-4.97,12.99-13.12,15.8-24.46Z"
        />
      </g>
    </svg>
  );
}
