import style from "./style.scss";

function LinkesKahnbeinDaumenRausDpSitzend1347(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 227.81 214.48">

<g id="Background">
  <path id="background_white" data-name="background white" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls4" d="M226.9,6.55h.02c-.08-.32-.16-.64-.25-.96,0-.05-.03-.09-.04-.15-.24-.82-.47-1.64-.7-2.46-.06-.21-.12-.42-.18-.63-13.09-.51-26.19-.96-39.29-1.29-3.49-.09-6.97-.16-10.46-.24-1.38,1.68-2.78,3.23-4.18,4.63-.67-1.53-1.3-3.13-1.89-4.76C136.66.05,103.38-.02,70.12.65c-21.72.44-43.49.08-65.22.63-.55,2.25-.94,4.53-1.09,6.93v39.68c0,.59-.28.96-.67,1.13,0,3.42.03,6.84.03,10.27.05,18.26-.06,36.63-2.76,54.73C.29,116.86.16,119.71,0,122.55c1.17,1.98,1.75,4.39,1.73,6.86-.03,5.77-.35,11.54-.65,17.31,1.1,1.95,1.65,4.28,1.65,6.69v55.95s113.65,5.11,113.65,5.11c1.4-21.17,3.33-40.26,6.87-52.83,2.26-9.19,5.49-17.43,13.06-21.4,7.79-6.19,16.51-12.99,18.53-15.39,19.08-20.01,36.61-39.7,51.95-58.94,5.93-21.59,12.7-41.06,21.02-56.57-.37-.88-.66-1.81-.91-2.8h0Z"/>
</g>
<g id="handparts">
  <g id="handboneparts">
    <path id="bonepart29" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M109.49,104.13h0c-3.41-5.15-1.29-9.56,4.41-13.48,3.44-3.6,8.82-5.97,14.89-7.9,13.87-4.69,25.4-11.41,33.08-21.49,2.75-3.61,3.8-8.2,3.22-12.69-1.18-9.11.7-14.36,5.93-15.34,6.25-1.93,12.17-1.59,17.62,2.13,6.33,1.73,9.22,9.08,9.72,20.35.84,3.46-1.54,6.68-6.72,9.68-17,12.7-32.08,28.98-45.91,47.62-5.37,6.14-10.52,8.65-15.38,6.49-7.52-1.12-10.29-4.86-10.25-10.14l-10.59-5.24h-.02s0,.01,0,.01Z"/>
    <path id="bonepart28" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M195.92,1.32c-4.92,4.72-10.98,9.11-17.83,13.27-1.89,2.31-3.21,4.68-3.08,7.19h0c.12,2.32,1.93,4.18,4.2,4.65,8.57,1.77,15.73,6.3,21.55,13.45,3.25.97,5.68-2.31,7.18-10.48,2.02-8.5,6.38-17.73,11.84-27.31"/>
    <path id="bonepart27" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M176.58,25.09c-.34,1.5-.14,2.8,1.04,3.74,2.97,1.57,6.62,1.4,10.48.68.25,4.4,1.07,8.58,5.01,11.51,3.34,1.28,5.89.93,7.63-1.14"/>
    <path id="bonepart26" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M173.72,40.95c-1.57-1.21-2.3-3.36-1.57-5.22.78-2,2.85-2.61,6.49-1.54,2.82.85,4.95,2.07,6.04,3.87,1.8,3.47.34,5.19-2.96,5.92-3.51-.69-6.23-1.68-8-3.03h0Z"/>
    <path id="bonepart25" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M164.52,26.44h0c3.44-.44,4.56.84,3.65,3.65-.23.92-.7,1.64-1.37,2.2-1.27,1.06-3.17,1.12-4.46.09-1.08-.86-1.12-1.89-.89-2.97,1.06-1.92,2.1-3.04,3.08-2.96h-.01Z"/>
    <path id="bonepart24" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M104.1.23c-2.68,17.28-7.92,35.19-16.37,53.89-1.7,2.57-4.57,4.36-7.97,5.81-2.47,9.5-7.04,17.5-13.33,24.27-1.2,1.26-.5,2.9,2.09,4.92,1.26.98,3.04.99,4.26-.04,4.26-3.56,9.26-4.19,14.95-2.15,2.7.98,5.18,4.13,7.61,7.93h0c.54.86,1.32,1.56,2.26,1.93,5.7,2.23,10.75-1.89,15.2-11.67,1.1-2.52,1.36-5.14.57-7.86-7.41-13.92-5.29-31.08,1.37-49.78L126.37.2"/>
    <path id="bonepart23" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M55.38.85c-.63,11.11-1.69,23.21-2.98,35.83-1.81,5.78-3.99,10.17-6.87,12.06-.73.48-1.36,1.1-1.73,1.9-2.4,5.19-4.36,10.68-5.72,16.6-.52,2.27.14,4.69,1.82,6.3,3.48,3.34,7.98,5.86,14.09,7.12h.02c7.6,1.94,13.58.8,16.63-5.92,5.66-6.46,5.56-13.34,1.25-20.51-4.05-21.75-2.27-38.6,1.66-53.64"/>
    <path id="bonepart22" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M66.54,43.41l.87,9.33c.1,1.09.51,2.12,1.12,3.02.74,1.1,1.33,2.23,1.64,3.43.49,1.87-.02,3.86-1.24,5.36l-.08.1c-.75.92-1.1,2.1-.96,3.28l.47,4.08"/>
    <path id="bonepart21" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M45.81,58.68c-.61-3.69.45-7.81,1.97-12.05"/>
    <path id="bonepart20" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M20.11,1.02l1.43,12.27-.1,32.88c0,1.72-.8,3.38-2.24,4.32-3.19,2.1-6.2,6.1-8.7,12.29-2.95,4.54-3.12,8.07,1.37,9.91l22.9,7.75c6.33,1.28,8.96-2.2,9.46-8.43h-.02c.14-12.85-2.22-22.29-6.29-29.38-1.42-11.46-1.18-25.98-.28-41.71"/>
    <path id="bonepart19" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M18.01,55.72c1.77-1.38,2.36-4.37,2.9-7.22"/>
    <path id="bonepart18" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M5.14,85.9c1.74.05,3.66-.42,4.59-2.03-.81.14-1.3.67-1.37,1.49,3.67-2.57,8.46-1.02,12.3-3,3.25-1.67,3.36-5.67,3.25-8.92-.31-9.47-5.29-17.59-10.1-25.44-3.18-5.2-6.5-10.49-8.3-16.36"/>
    <path id="bonepart17" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M104.8,91.9h0c6.77-1.97,9.59,1.2,9.7,7.9-.4,7.21.72,13.63,6.49,17.66,1.62,2.86-2.4,5.9-2.73,9.23-.07.72-.26,1.42-.65,2.03-1.69,2.7-4.49,3.58-7.9,3.44-2.41-1.67-5.54-2.15-9.03-2.06-1.22.03-2.31-.73-2.69-1.89-1.34-4.08-3.94-8.2-7.3-12.35.09-6.65,1.55-11.28,4.08-14.35l6.25-7.16c1.01-1.15,2.32-2.02,3.79-2.45h0Z"/>
    <path id="bonepart16" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M98.9,101.06c1.59,3.76-.68,7.29,0,10.69.63,3.87,2.77,6.16,8.32,4.92,2.97.45,5.03-3.87,6.66-10.36"/>
    <path id="bonepart15" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M73.34,92.03c3.56-3.76,7.91-4.75,13.29-2.14,7.22,4.26,8.87,10.34,8.14,17.2-.79,4.94-2.35,7.68-4.38,9.02-1.04.69-2.44.53-3.36-.31-3.88-3.5-7.79-5.94-11.73-6.6-1.11-.19-1.89-1.2-1.88-2.32.04-4.89-.1-9.3-.79-12.25-.21-.93.06-1.91.72-2.6h0Z"/>
    <path id="bonepart14" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M62.5,124.34h0c1.49-.93,2.51-2.44,2.8-4.17.77-4.56,2.27-6.77,4.43-6.81,6.49-.65,11.67.92,15.22,5.25.42.51.87,1.01,1.38,1.44,4.73,4.1,6.99,7.67,6.04,10.56-.36,1.09-.13,2.3.47,3.27,1.94,3.09.72,5.86-2.79,8.38-1.28.92-2.43,2.01-3.5,3.15-8.2,8.75-16.76,11.85-25.49,12.14-2.05.07-4.12-.22-6.02-.98-3.33-1.34-5.08-3.6-5.52-6.61-.2-1.34-.04-2.72.34-4.03.96-3.34,2.14-6.49,3.57-9.44,2.18-5.66,5.19-9.74,9.06-12.15h.01Z"/>
    <path id="bonepart12" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M52.33,138.96c4.28-.93,8.27-2.71,11.48-6.78,3.59-3.13,3.18-6.01.84-8.78-.13-.15.02-.38.21-.32,2.99.86,4.82-.37,5.5-3.68v-6.18"/>
    <path id="bonepart11" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M30.48,115.17h0c-.97,3.18-1.27,6.56-.65,9.83.79,4.21,2.71,6.76,6.07,7.17,8.5,2.13,16.47,2.41,23.47-.7,3.84-1.89,5.33-4.85,5.49-8.4,1.49-3.41,1.73-6.47-1.05-8.69-.98-.95-1.38-2.05-1.41-3.24-.05-2.12,1.3-4.04,3.22-4.94l.63-.3c2.26-1.06,3.82-3.3,3.8-5.8-.02-3.42-1.69-6.06-4.33-8.23-.82-.68-1.45-1.58-1.66-2.62-1.06-5.25-5.02-6.65-10.16-6.48-1.53.05-3.04-.42-4.3-1.29-7.86-5.4-12.42-6.01-14.94-3.68-.67,1.33-1.9.47,0,11.62l.63,7.42c.11,1.35-.03,2.7-.42,3.99l-4.36,14.33h-.03Z"/>
    <path id="bonepart10" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M3.59,100.51c.73.97.73,1.97,1.73,2.97,10,5,12,16,20,23,2,1,6-1,8-3,5-11,4-23,5-34,0-6-4-11-10-11-7,0-14-2-21-3-1,0-3,1-3.76,1.08"/>
    <path id="bonepart9" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M14.14,86.25c3.57.37,7.11-.38,10.59-2.85,2.31-3.02,4.69-3.66,7.18-1.37,1.54,1.11,1.71,2.54.87,4.22-.59,1.17-.96,2.45-1.08,3.76l-.4,4.48c-.23,2.63-1.13,5.18-2.64,7.35-2.31,3.31-4.82,5.01-7.75,3.32-2.9-5.7-1.97-9.61-5.86-11.56"/>
    <path id="bonepart8" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M1.17,145.34c3.15,4.14,10.15,5.14,14.15,2.14,5-5,9-9,12-15-3-3-9-4-10-9-1-7-7-13-14.12-13.61"/>
    <path id="bonepart7" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M1.5,138.49c2.38,1.5,4.94,2.03,7.65,1.72,4.67-.52,8.9-3.16,11.49-7.08,2.04-3.07,3.48-6.92,4.66-11.11.08-3.56-.9-5.38-2.39-6.36-.94-.61-2.09-.83-3.21-.72-5.55.55-10.78,2.52-15.74,5.65-1.3.82-2.46,1.83-3.42,3"/>
    <path id="bonepart6" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M46.15,160.18h0c3.96.56,4.99-2.31,4.82-6.61,1.69-3.02,2.06-6.8,1.56-11.09-.19-1.69-1.55-3-3.24-3.11l-7.01-.42c-2.73-1.82-5.72-2.56-8.89-2.56-2.77,0-5.33,1.5-6.66,3.93-1.23,2.24-2.11,4.99-2.73,8.13-3.9,3.54-4.51,6.67.39,9.11,4.51,2.83,8.69,4.11,12.53,3.88l9.23-1.25h0Z"/>
    <path id="bonepart5" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M30.08,137.15c1.67-1.2,3.36-2.2,5.1-2.85,3.48-1.31,7.37-.68,10.49,1.36,1.81,1.18,2.99,2.42,3.63,3.72"/>
    <path id="bonepart4" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M102.09,212.36c2.7-6.92,4.87-13.78,5.01-20.39,1.94-17.2-4.56-24.38-.78-33.15,1.47-7.75.61-13.81-3.36-17.63-1.52-1.46-3.91-1.5-5.45-.07-8.59,7.96-16.58,14.2-23.33,16.9l-36.7,7.18c-6.58.93-9.9,6.73-6.82,22.1,2.46,8.17,6.88,16.33,13.56,24.49"/>
    <path id="bonepart3" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M2.96,166.21c1.36,1.27,1.36,2.27,2.36,2.27,6,2,12,0,17,4,3,3,4,8,3,13-1,9-5,16-4.41,24.73"/>
    <path id="bonepart2" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M2.99,176.37c6.2-1.11,11.76-4.34,17.92-5.6"/>
    <path id="bonepart1" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M38.97,195.96c-3.91-9.51-5.88-18.53-1.5-25.97,2.78-5.33,27.24-4.56,36.7-11.96"/>
  </g>
  <g id="outlines">
    <path id="outlinepart8" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M170.84,3.16c.31.77.63,1.53.96,2.29.88-.87,1.73-1.78,2.55-2.7"/>
    <path id="outlinepart7" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls1" d="M225.88,13.01c-6.93,13.74-11.84,28.25-15.93,43.04-4.08,14.78-16.19,25.76-25.99,37.09-10.36,11.98-20.63,24.62-32.62,35.03-8.92,7.75-20.57,13.46-25.43,24.88-6.79,15.94-7.49,34.49-8.83,51.52"/>
    <path id="outlinepart6" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M116.72,209.46c-.12,1.65-.23,3.31-.34,4.99"/>
    <path id="outlinepart5" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M171.54,4.91c-1.57.61-3.12,1.23-4.64,1.88"/>
    <path id="outlinepart4" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls1" d="M159.32,10.23c-9.19,4.48-17.41,9.65-24.72,15.41"/>
    <path id="outlinepart3" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" d="M131.37,28.27c-1.28,1.08-2.53,2.18-3.76,3.3"/>
    <line id="outlinepart2" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" x1="171.87" y1="18.8" x2="171.87" y2="13.8"/>
    <line id="outlinepart1" class="Linkes_Kahnbein_Daumen_raus_dp1347_cls2" x1="171.87" y1="10.28" x2="171.87" y2="5.28"/>
  </g>
</g>
<g id="kahnbeinhighlight">
  <path id="bonepart14-2" class="Kahnbein1347Fill"  style={{
          fill: props.colors.Kahnbein1347Fill,
          stroke: props.colors.Kahnbein1347Fill,
        }} d="M62.5,124.34h0c1.49-.93,2.51-2.44,2.8-4.17.77-4.56,2.27-6.77,4.43-6.81,6.49-.65,11.67.92,15.22,5.25.42.51.87,1.01,1.38,1.44,4.73,4.1,6.99,7.67,6.04,10.56-.36,1.09-.13,2.3.47,3.27,1.94,3.09.72,5.86-2.79,8.38-1.28.92-2.43,2.01-3.5,3.15-8.2,8.75-16.76,11.85-25.49,12.14-2.05.07-4.12-.22-6.02-.98-3.33-1.34-5.08-3.6-5.52-6.61-.2-1.34-.04-2.72.34-4.03.96-3.34,2.14-6.49,3.57-9.44,2.18-5.66,5.19-9.74,9.06-12.15h.01Z"/>
</g>
</svg>
  );
}

export default LinkesKahnbeinDaumenRausDpSitzend1347;
