import style from "./style.scss";

function RechterMittelfussDplLiegend1434(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 271.76 480">
 
 <g id="foot">
   <g id="toes">
     <g id="big_toe" data-name="big toe">
       <path id="big_toe_1" data-name="big toe 1" class="mittelfuss_dp_rechts1434_cls_4" d="M12.97,5.91c-1.21,5.03-2.64,10.01-4.25,14.94h0v.09s.09,0,.09,0c-7.23,18.93-1.6,26.43,15.09,24.02l30.62-6.87c14.37,7.23,16.78-2.14,10.71-23.57-1.04-3.17-2.05-6.34-2.98-9.53"/>
     </g>
     <g id="second_toe" data-name="second toe">
       <path id="second_toe_1" data-name="second toe 1" class="mittelfuss_dp_rechts1434_cls_3" d="M132.95.86c.81,5.84,2.38,11.16,4.42,16.16,6.16,16.16,3.75,19.28-5.18,12.41-8.21-11.7-15.98-12.33-23.48-4.29-15.98,6.51-17.14,1.51-5.98-12.86,2.09-3.17,3.87-7.09,5.25-11.78"/>
     </g>
     <g id="third_toe" data-name="third toe">
       <path id="third_toe_1" data-name="third toe 1" class="mittelfuss_dp_rechts1434_cls_3" d="M181.34,22.72c.23,3.21.62,6.35,1.11,9.46,8.92,17.32,6.6,22.32-5.54,16.25-4.46-8.93-9.64-11.87-15.89-4.73-8.21,15.36-13.39,2.59-7.14-13.3,2.81-3.01,5.17-6.71,7.13-11.02"/>
     </g>
     <g id="fourth_toe" data-name="fourth toe">
       <path id="fourth_toe_1" data-name="fourth toe 1" class="mittelfuss_dp_rechts1434_cls_3" d="M227.78,58.71c.34,3.3.75,6.6,1.1,9.9,4.1,19.56,1.42,22.59-5.54,15.45-4.38-5.8-9.55-6.07-15.09-3.39-8.66,6.7-8.75.89-6.34-8.57,2.63-7,4.94-14.16,6.88-21.49"/>
     </g>
     <g id="little_toe" data-name="little toe">
       <path id="little_toe_1" data-name="little toe 1" class="mittelfuss_dp_rechts1434_cls_3" d="M271.26,75.26c-7.51,14.63-10.24,28.97-7.3,43,1.34,11.96-1.25,15.09-5.98,13.3-1.43-5.9-6.34-7.41-13.93-5.54-8.3,3.66-10.26,1.07-4.37-9.46,6.25-11.79,8.75-28.4,9.2-47.59"/>
     </g>
   </g>
   <g id="metatarsus">
     <path id="metatarsus_1" data-name="metatarsus 1" class="mittelfuss_dp_rechts1434_cls_3" d="M3.91,88.71h.09C-1.45,65.32-2.34,47.02,14.71,48.45c11.87-1.52,28.03-13.49,51.33-4.29,4.64.18,8.21,4.46,10.71,11.96,18.57,5.09,19.28,15.17,1.16,30.44-15.53,50.53-9.19,91.06,17.5,122.13,13.39,4.91,14.73,15.45-1.16,33.84-20.8-6.96-33.83.72-46.6,9.02-17.77,10.62-22.23.71-20.27-18.84,1.07-49.38-7.68-97.23-23.48-144.01h.01Z"/>
     <path id="metatarsus_2" data-name="metatarsus 2" class="mittelfuss_dp_rechts1434_cls_3" d="M101.93,34.86v-.18c11.61-12.59,23.21-13.21,34.64-1.25,4.37,4.2,5.26,9.38,3.21,15.45,7.77,8.39,5.45,19.55-2.41,32.14-9.29,46.42-2.06,101.24,4.37,155.61,6.79,5.54,10.18,13.22,6.79,25.27,3.39,17.67-2.41,27.23-12.32,33.03-4.82-17.49-18.92-20.89-35.44-20.53,1.97-11.79-.89-20.89-5.98-28.75,1.97-4.02,7.86-10.98,11.52-21.43,5-24.9,4.19-81.06,3.57-135.43-14.64-14.64-14.11-24.37-11.52-33.39,2.14-7.14,3.03-13.92,3.57-20.53h0Z"/>
     <path id="metatarsus_3" data-name="metatarsus 3" class="mittelfuss_dp_rechts1434_cls_3" d="M156.93,52.28h0c6.24-11.07,12.32-16.43,17.94-3.84,8.92,5.54,15.62,13.93,11.96,35.18-8.04,11.43-11.25,25.53-10.36,42.05-1.79,28.39,2.94,72.05,8.39,117.4,9.82,30.45,4.91,43.57-5.98,49.73-3.66-21.34-14.64-20.09-26.69-15.89-3.21-9.91-1.43-23.84,2.41-39.46,4.37-24.29,2.41-88.92,1.16-148.74-7.77-3.22-6.34-16.61,1.16-36.43h0Z"/>
     <path id="metatarsus_4" data-name="metatarsus 4" class="mittelfuss_dp_rechts1434_cls_3" d="M197.46,108.78l.09.09h0c-4.02-8.48,0-17.05,8.75-25.71,8.04-6.07,14.64-3.66,19.91,5.98l2.41,18.84c1.78,10.9-2.33,18.22-9.2,24.02-9.73,14.74-8.93,46.88-7.59,79.73l6.79,66.42c3.84,9.91,6.07,20.08,1.16,31.69-1.34,10.44-4.2,16.78-7.95,20.53-4.64-15.35-16.16-23.03-32.23-25.71,9.1-20.62,12.23-44.73,10.71-71.6-2.95-41.42.18-82.85,7.14-124.27h.01Z"/>
     <path id="metatarsus_5" data-name="metatarsus 5" class="mittelfuss_dp_rechts1434_cls_3" d="M236.2,145.23v.09h-.09c0-20.63,12.86-23.31,21.07-11.61,7.41-1.52,6.97,4.64,2.77,14.55,6.43,6.69,10.09,13.84-4.02,24.46-9.82,19.2-12.32,41.51-10.71,65.62l5.98,80.98c9.11,32.58,1.7,49.73-15.53,57.85-5.89,10-10.27,10.53-12.32-2.59,1.43-11.25-3.66-26.07-10.71-42.05,6.87-14.91,11.6-29.55,8.39-43.3l.8-57.41,10-66.87c-4.2-5.62-2.5-12.23,4.37-19.73h0Z"/>
     <path id="metatarsus_6" data-name="metatarsus 6" class="mittelfuss_dp_rechts1434_cls_3" d="M10.41,50.13c-5,1.25-5,2.68,0,4.29,16.07,7.41,33.21,5.08,50.98-4.29,2.68-.62,4.28-2.68,4.82-5.98"/>
     <path id="metatarsus_7" data-name="metatarsus 7" class="mittelfuss_dp_rechts1434_cls_3" d="M103.98,35.95c7.68,6.07,15.62,10.62,24.73,5.98l10.36-5.54"/>
     <path id="metatarsus_8" data-name="metatarsus 8" class="mittelfuss_dp_rechts1434_cls_3" d="M155.67,60.85c4.37-1.52,7.5,1.25,9.55,8.12,6.52,2.5,10.45.8,12.32-3.84,4.38-.89,6.79-3.21,6.79-7.32"/>
     <path id="metatarsus_9" data-name="metatarsus 9" class="mittelfuss_dp_rechts1434_cls_3" d="M200.32,91.29c4.91-.26,8.66,2.32,10.71,9.02,5.89,3.84,10.89,3.03,15.53-.45"/>
     <path id="metatarsus_10" data-name="metatarsus 10" class="mittelfuss_dp_rechts1434_cls_3" d="M239.32,147.45c9.91,2.59,16.79.8,16.34-11.16"/>
   </g>
   <g id="tarsus">
     <path id="tarsus_5" data-name="tarsus 5" class="mittelfuss_dp_rechts1434_cls_3" d="M53.44,343.78h-.09c-20,11.16-24.37-4.82-21.87-32.59-.71-27.95,5.81-46.43,16.34-59.55,29.82-13.12,54.91-19.1,49.82,21.43-16.07,19.64-25.89,39.9-23.12,61.24l-21.07,9.46h-.01Z"/>
     <path id="tarsus_4" data-name="tarsus 4" class="mittelfuss_dp_rechts1434_cls_3" d="M121.48,344.92c-10.35-11.69-24.46-13.57-40.62-9.82-7.05-14.28-2.77-33.47,15.09-58.74,19.55-3.75,32.41,1.07,37.05,16.25,2.59,17.77-2.5,35.18-11.52,52.32h0Z"/>
     <path id="tarsus_3" data-name="tarsus 3" class="mittelfuss_dp_rechts1434_cls_3" d="M126.21,345.47h0c8.22-17.14,13.57-35.08,15.09-54.01,18.57-21.25,30.54-19.11,37.41,1.25,1.97,32.86-.53,58.93-13.12,69.46-5.45,5.89-11.88,8.75-19.11,9.46-1.88-17.23-10.9-22.05-20.27-26.16h0Z"/>
     <path id="tarsus_2" data-name="tarsus 2" class="mittelfuss_dp_rechts1434_cls_3" d="M195.93,414.84c-40.98,17.23-53.75,4.2-64.46-11.16,9.28-10.18,13.92-19.73,14.37-28.75,29.11-8.57,37.23-30.62,33.84-59.99,14.55-4.11,26.25,1.87,32.23,28.3,9.29,11.07,9.29,23.75,6.79,36.87-6.52,13.3-14.2,24.64-22.68,34.73h0s-.09,0-.09,0Z"/>
     <path id="tarsus_1" data-name="tarsus 1" class="mittelfuss_dp_rechts1434_cls_3" d="M34.7,422.6c-10.98-4.91-14.55-16.43-7.59-37.32,2.32-21.87,8.39-37.14,21.87-39.01,29.46-14.64,53.92-16.61,70.44.89,31.87,8.39,25.8,30.18,8.75,55.71-33.57-31.07-64.28-17.23-93.56,19.73h.09Z"/>
   </g>
   <g id="ankle_bone" data-name="ankle bone">
     <path id="ankle_bone_3" data-name="ankle bone 3" class="mittelfuss_dp_rechts1434_cls_3" d="M49.07,470.08c-3.2-5.77-7.8-10.4-13.66-13.99h0v-.09h.09c-3.31-28.75,12.58-54.02,36.6-64.73,28.39-6.25,51.43-4.55,52.14,30.89-2.14,17.05,0,29.01,7.95,34.28"/>
     <path id="ankle_bone_2" data-name="ankle bone 2" class="mittelfuss_dp_rechts1434_cls_3" d="M132.19,456.44c12.05,7.32,22.95,13.93,31.07,18.84"/>
     <path id="ankle_bone_1" data-name="ankle bone 1" class="mittelfuss_dp_rechts1434_cls_3" d="M48.18,410.99c38.21-19.55,63.3-17.41,74.46,7.68"/>
   </g>
   <g id="heel_bone" data-name="heel bone">
     <path id="heel_bone_1" data-name="heel bone 1" class="mittelfuss_dp_rechts1434_cls_3" d="M166.03,473.13c-25.35-19.64-27.94-12.49-35.8-24.19-6.34-9.29-3.84-37.68-10.36-50.98,19.1,18.84,38.12,37.85,76.06,19.46,7.77,9.74,8.04,22.23.45,37.59,4.29,7.66,7.78,15.82,10.46,24.49"/>
   </g>
 </g>
 <g id="overlays">
   <g id="metatarsal_bone_overlay" data-name="metatarsal bone overlay" class="mittelfuss_dp_rechts1434_cls_1">
     <path id="metatarsal_bone_overlay_1" data-name="metatarsal bone overlay 1" class="MFK1434Fill" style= {{
             fill: props.colors.MFK1434Fill,
             stroke: props.colors.MFK1434Fill,
             opacity:props.colors.MFK1434Fill,
           }} d="M3.72,89.12h.09C-1.64,65.73-2.53,47.43,14.52,48.86c11.87-1.52,28.03-13.49,51.33-4.29,4.64.18,8.21,4.46,10.71,11.96,18.57,5.09,19.28,15.17,1.16,30.44-15.53,50.53-9.19,91.06,17.5,122.13,13.39,4.91,14.73,15.45-1.16,33.84-20.8-6.96-33.83.72-46.6,9.02-17.77,10.62-22.23.71-20.27-18.84,1.07-49.38-7.68-97.23-23.48-144.01h.01Z"/>
     <path id="metatarsal_bone_overlay_2" data-name="metatarsal bone overlay 2" class="MFK1434Fill" style= {{
             fill: props.colors.MFK1434Fill,
             stroke: props.colors.MFK1434Fill,
             opacity:props.colors.MFK1434Fill,
           }} d="M101.74,35.27v-.18c11.61-12.59,23.21-13.21,34.64-1.25,4.37,4.2,5.26,9.38,3.21,15.45,7.77,8.39,5.45,19.55-2.41,32.14-9.29,46.42-2.06,101.24,4.37,155.61,6.79,5.54,10.18,13.22,6.79,25.27,3.39,17.67-2.41,27.23-12.32,33.03-4.82-17.49-18.92-20.89-35.44-20.53,1.97-11.79-.89-20.89-5.98-28.75,1.97-4.02,7.86-10.98,11.52-21.43,5-24.9,4.19-81.06,3.57-135.43-14.64-14.64-14.11-24.37-11.52-33.39,2.14-7.14,3.03-13.92,3.57-20.53h0Z"/>
     <path id="metatarsal_bone_overlay_3" data-name="metatarsal bone overlay 3" class="MFK1434Fill" style= {{
             fill: props.colors.MFK1434Fill,
             stroke: props.colors.MFK1434Fill,
             opacity:props.colors.MFK1434Fill,
           }} d="M156.74,52.69h0c6.24-11.07,12.32-16.43,17.94-3.84,8.92,5.54,15.62,13.93,11.96,35.18-8.04,11.43-11.25,25.53-10.36,42.05-1.79,28.39,2.94,72.05,8.39,117.4,9.82,30.45,4.91,43.57-5.98,49.73-3.66-21.34-14.64-20.09-26.69-15.89-3.21-9.91-1.43-23.84,2.41-39.46,4.37-24.29,2.41-88.92,1.16-148.74-7.77-3.22-6.34-16.61,1.16-36.43h0Z"/>
     <path id="metatarsal_bone_overlay_4" data-name="metatarsal bone overlay 4" class="MFK1434Fill" style= {{
             fill: props.colors.MFK1434Fill,
             stroke: props.colors.MFK1434Fill,
             opacity:props.colors.MFK1434Fill,
           }} d="M197.27,109.19l.09.09h0c-4.02-8.48,0-17.05,8.75-25.71,8.04-6.07,14.64-3.66,19.91,5.98l2.41,18.84c1.78,10.9-2.33,18.22-9.2,24.02-9.73,14.74-8.93,46.88-7.59,79.73l6.79,66.42c3.84,9.91,6.07,20.08,1.16,31.69-1.34,10.44-4.2,16.78-7.95,20.53-4.64-15.35-16.16-23.03-32.23-25.71,9.1-20.62,12.23-44.73,10.71-71.6-2.95-41.42.18-82.85,7.14-124.27h.01Z"/>
     <path id="metatarsal_bone_overlay_5" data-name="metatarsal bone overlay 5" class="MFK1434Fill" style= {{
             fill: props.colors.MFK1434Fill,
             stroke: props.colors.MFK1434Fill,
             opacity:props.colors.MFK1434Fill,
           }} d="M236.01,145.64v.09h-.09c0-20.63,12.86-23.31,21.07-11.61,7.41-1.52,6.97,4.64,2.77,14.55,6.43,6.69,10.09,13.84-4.02,24.46-9.82,19.2-12.32,41.51-10.71,65.62l5.98,80.98c9.11,32.58,1.7,49.73-15.53,57.85-5.89,10-10.27,10.53-12.32-2.59,1.43-11.25-3.66-26.07-10.71-42.05,6.87-14.91,11.6-29.55,8.39-43.3l.8-57.41,10-66.87c-4.2-5.62-2.5-12.23,4.37-19.73h0Z"/>
   </g>
 </g>
</svg>
  );
}

export default RechterMittelfussDplLiegend1434;
