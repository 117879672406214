import style from "./style.scss";

function LinkeHandLatSitzend1341(props) {
  return (
    <svg id="handlat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215.86 476.63">

    <g id="fullhand">
      <g id="bones">
        <path id="fingerpart19" class="Hand_lat_links1341_cls5" d="M59.5,420.33l-2.3-2.4c-3.7-7.4-10.8-8.1-13.4,3,3.2,16.1,4.4,31,.8,43.5l-3.2,10.7"/>
        <path id="fingerpart18" class="Hand_lat_links1341_cls5" d="M87.6,469.53c1.5-4.4,3.8-8.5,7.2-12.5,6.6-5.1,6.8-10,6.3-14.9,2.4-7.6,1.1-13.8-2.8-19-6.4,1-9,3.4-11.1,5.3-10,5-19.7.2-27.6-8-8.5-2.9-10,2.2-12.4,6.3l2.7,17.2c-1.9,17.2-3.7,23.9-5.6,30.8"/>
      </g>
      <g id="fingers4">
        <path id="fingerpart17" class="Hand_lat_links1341_cls5" d="M100.5,336.53h-.1c-2.3,8.6-2,15.7,2.1,20.8,7.1,3.6,13.9,5.4,20.2,3.8,1.8,2.9,2.1,7.2,2.1,11.8-4.8,8.9-10.1,14.2-14.5,15.4-12.5,2-17.4-4.4-18.2-13.7,4.1-5.6,2.7-13.4-.3-21.8-1.4-3.3-2.4-6.6-2.4-9.6,4-6.7,7.6-7.6,11.1-6.7h0Z"/>
        <path id="fingerpart16" class="Hand_lat_links1341_cls5" d="M92.8,379.33l.2.1h0c2,4.8,5.9,8.4,14.2,9.2,1.8,1.1,2.1,3,1.8,5.3-2.2,2.7-5,5.2-8.4,7.6,2,3.2,2.5,7.2-1.5,14.1-3,3.6-6.4,6.4-10.9,7.6-15.7,5.7-29.6-6.3-28.5-11.1,9-4.6,12.6-13,13.1-23.5,3.3-6.2,9.9-9.3,20-9.3h0Z"/>
        <path id="fingerpart15" class="Hand_lat_links1341_cls5" d="M74.6,347.43v.1h0c3.3,2.6,6.2,4.5,8.4,4.5,1.9-.7,3.8-1.5,7.7-2l1.2,2.8c2.3,7.8,4.1,15.4.3,21.8l.6,4.8-17.5,1.6c-11.4-2.7-10.9-14.3-8-28.1,3.4.3,5.4-2.3,7.3-5.5h0Z"/>
        <path id="fingerpart14" class="Hand_lat_links1341_cls5" d="M72.7,388.63l-.4.2h-.1c0-2.2,2.8-5,8.7-8.4l-6.1.8c-13.9-.6-9.7-24.3-7.7-24.5l-1.6-3.3-5.2-5.6c-.7,2.4-2.4-1.2-4.3-.6-1.9.3-5.3,6.3-7.1,10.2-.5,1.3-.2,2.6.7,3.7,1.1,1.3,1.6,3.1,2.8,4.9.5.7.7,1.5.7,2.3-.2,3.1-.2,6.3.5,9.6-.2,2.9.1,5.7.6,8.6.1.8,0,1.5-.4,2.2l-3.2,5.4c-.4.6-.4,1.3-.4,2,.6,5.9,2.1,11.2,6,15,.8.7,2.1,1.1,3.2.7,8.3-2.9,12.2-11.3,13.3-23.2h0Z"/>
        <path id="fingerpart13" class="Hand_lat_links1341_cls5" d="M47.3,372.53h0c-2.9-6.5-.8-10.3.8-12.9,3.6,1.4,3.6,4.4,5.4,7.3-.1,8.8-.2,17.4,1,20.7l-4.4,7.3c-3.9-1.9-4.6-11-2.8-22.5h0v.1Z"/>
      </g>
      <g id="fingers3">
        <path id="fingerpart12" class="Hand_lat_links1341_cls5" d="M92.8,43.33c-3.5,2.7-5.5,7.3-6.7,13.2l-2.6,7.9-3.1-.7c-1.3-2.4.4-7.3,3.2-13l1.1-6.7c.7-9.1,3.2-9.8,5.7-9.8,1.2,2.1,2.1,4.8,2.3,9.1,0,0,.1,0,.1,0Z"/>
        <path id="fingerpart11" class="Hand_lat_links1341_cls5" d="M61.4,118.63h0c1.3-.6,2.7-.8,4.8.4l5-11.4c-4.9-.2-1.5-5.8,1.5-11.2l10.6-32c-2.8.1-4.8,1.6-6,4.3l.5,2.2c1.1,1.1-8.6,15.9-16.4,37.7-.9,3.9-1.5,7.6,0,10h0Z"/>
        <path id="fingerpart10" class="Hand_lat_links1341_cls5" d="M52.6,201.23v-.1c-1.6-3.7,1.2-6.8,4.2-9.8l-2.5-3.2c.7-13,3.2-27.8,6.6-43.4l5.3-25.8c-1.7-.9-3.3-1.2-4.8-.4-3.1,2.4-5.6,6.1-7.7,10.2.5,5-5.2,19.7-10.3,42.1-.1,8.4-1.3,17.3-4.4,26-.2,4.1.1,7.6,2.3,10.1,2.7-1.2,4.5-2.9,4.6-5.4,2.6,1.3,4.9,1.6,6.7-.3h0Z"/>
        <path id="fingerpart9" class="Hand_lat_links1341_cls5" d="M73.6,319.53v.2h0c-8.8-24.4-15-58.7-20.2-96.8-3.9-6.1-4.5-13.2-.8-21.5-1.9,1.2-4.2,1.2-6.7.3.4,2.9-2.1,4.1-4.6,5.4-4,6.9-5.2,12.1-3,15.1.5,3.3,2.3,6.6,4.8,9.7l1.7,8.7c3.4,8.1,4.5,43.5,12.3,68.7,2,6.8,2.5,13.9,1.4,20.9l-1.1,7.2c-.6,4.7-2.7,8.3,2.1,11.1,2.1-1,2.3-1.5,2.8-3.8,2.1-1.3,9.5,2.5,11.6,2-5.9-7.7-4.4-17.2-.3-27.2h0Z"/>
      </g>
      <path id="fingerpart8" class="Hand_lat_links1341_cls5" d="M90.6,349.93v.3h0c-3.2.2-5.7,1-7.7,2-2.2.1-4.5-.2-8.4-4.5-2.3,3.5-4.7,5.7-7.2,5.5-8.4-7.4-5.8-17-1.7-26.9l-20.2-96.8c-3.6-6.8-4.6-13.8-.8-21.5-1.7-2.8-.3-6,4.2-9.8l8.6-4.9c1.7-.8,4.5.2,7.8,2,2.3.1,4.6.3,7.3-.2,2.6,4.3,4.9,9.7,6.9,16.3.6,4.4-3.1,11-7.1,17.7-6.4,10.5-.1,72.1,13.5,97.1,4.5,4.5,8.4,9.1,5.7,13.6l-2.2,3.4c-.4,2.8.1,5,1.3,6.7h0Z"/>
      <g id="fingers2">
        <path id="fingerpart7" class="Hand_lat_links1341_cls5" d="M162.1,180.13l6.3-6.7c5-3.3,8.8-8,11-14.4-1.5-12.5,4.1-15.5,14.4-12,5.6,6.1,8.1,12.5,2,20.1-4.4,4.2-8.4,8.8-3.9,21.4-1.3,5-3,9.4-6.6,11.4-6.2,1.6-15-3.2-17.6-5.1-3.2-6-5.1-10.9-5.4-14.7h0s-.2,0-.2,0Z"/>
        <path id="fingerpart6" class="Hand_lat_links1341_cls5" d="M163.5,258.13h0c-.8,7.9-2.9,14-10,13.7-5.1,1.7-5.6-1.1-5.9-4.1l-14.7-11.8c-6.4-5.4-1.5-9.9,5.6-14.1,2-8.9,9.6-25.2,18.4-43.3.8-7.8,2.8-12.3,5.8-14.2l5,10.6c4.1,2.4,8.2,5.1,14,5.3l.4,2.6c-1.3,5.6-5.2,9.9-11.1,13.2-14.2,28.8-6.6,24.7-7.5,42.2h0v-.1Z"/>
        <path id="fingerpart5" class="Hand_lat_links1341_cls5" d="M130.1,333.03h0l.4,8.1c5.8,10.9,1.7,18.1-3.3,18.4-5.4,3.6-13.3,3.1-22.5.5-3.5-3.7-5.1-8.2-5.4-13.2,0-7,.7-12.9,2.6-17,3.6-2.1,6.1-5.1,8.1-8.4,2.6-8.8,5.9-20.1,9.9-35.1,4.2-7.4,5.3-13,4.7-17.8-.3-6.7,3-10.4,8.3-12.5,4.3,3.7,8.7,7.3,13.4,10.8,2.4,1.8.3,7.2,7.2,5.1l5.3,1.9c1.4,5.9-1.4,15.3-10.8,17.1-4.9,9-11.3,24.8-18.1,42h0s.2.1.2.1Z"/>
      </g>
      <g id="Fingers">
        <path id="fingerpart4" class="Hand_lat_links1341_cls5" d="M69.2,194.23v-.1h0l-3.9.9-8.1-3.2c.2,2.4-4.6,4.1-8.3,6l-2.5-3.2c.6-12.8,2.7-27.3,6.6-43.4,2.5-14.5,5.4-28,10.3-37.2,2,2.9,8.3,1.3,13.7,1,5.1,5.6,3.5,8.7-1.4,10.6-3.8,3.3-5.4,6.3-5.5,9.2-1.6,18.1-2.3,34.1-2.3,48,5.1,3,6.2,6.7,1.4,11.4h0Z"/>
        <path id="fingerpart3" class="Hand_lat_links1341_cls5" d="M88.3,68.93h0c2.8-1.4,1.6-4.1-3-7.8-2.6-.6-5,.3-7.2,1.9l-7.1,21.8c-.9,5.4-2.4,11.1-6.1,18.1-4.3,6.4-5.5,10.6-1.5,11.2,3.9,3.1,8.6,2.8,13.7,1,1.6,1.5,3.2,2.7,4.6,2.2,2-9.2,1.2-12.9.4-16.2-1.5-22.9,2.1-28.4,6.2-32.2h0Z"/>
        <path id="fingerpart2" class="Hand_lat_links1341_cls5" d="M97.3,21.93h0c-1-1.9-3.4,3.1-6.6,5.9-2,6-3.5,12.8-4.7,20.7-4.4,3.6-6.9,8.5-7.8,14.4,1.9-1.6,4.2-2.4,7.2-1.9,2.2.4,4,2.5,5.2,6.2,4.4-.8,5-4,3-8.9l-1-4.4c1.6-4.2,1.9-8.8,1.3-13.5,2.5-.5,4.6-1.5,5.6-3.6.9-4.2.7-13.3-2.2-14.8h0s0-.1,0-.1Z"/>
        <path id="fingerpart1" class="Hand_lat_links1341_cls5" d="M63.3,114.13c.9-1.3,2.9-1.9,5.3-2.4,2.5-.1,5.4,1.4,8.4,3.4-4.9,1.5-9.7,2-13.7-1Z"/>
      </g>
    </g>
    <g id="Highlights">
      <path id="fingerparthighlight8" class="Finger1341Fill"  style={{
              fill: props.colors.Finger1341Fill,
              stroke: props.colors.Finger1341Fill,
            }} d="M83.6,50.73l1.1-6.7c.52-6.73,2.02-8.86,3.78-9.51-.65,3.55-2.46,6.84-2.38,10.52,0,.17.05.32.11.45-.16,1.08-.28,2.17-.41,3.26-.05.18-.1.36-.15.54-.23.04-.46.15-.64.34-.2.2-.35.45-.49.71-.37-.02-.76.13-.92.47-.23.52-.5,1.03-.78,1.52.24-.52.49-1.05.76-1.59Z"/>
      <path id="fingerparthighlight7" class="Finger1341Fill"  style={{
              fill: props.colors.Finger1341Fill,
              stroke: props.colors.Finger1341Fill,
            }} d="M61.4,108.63c3.46-9.67,7.27-17.91,10.37-24.24-.14.52-.29,1.04-.45,1.56-.04.06-.08.1-.11.17-1.62,4.51-3.51,8.92-5.22,13.39-.76,1.56-1.49,3.14-2.07,4.77-1.34,1.81-2.61,3.83-2.54,6.05.04,1.2.32,2.43.73,3.55.18.48.39.96.65,1.4-.25.5-.34,1.09-.25,1.65-.56.16-1.08.5-1.41,1.09-1.08-2.39-.53-5.8.3-9.39Z"/>
      <path id="fingerparthighlight6" class="Finger1341Fill"  style={{
              fill: props.colors.Finger1341Fill,
              stroke: props.colors.Finger1341Fill,
            }} d="M39,196.83c3.1-8.7,4.3-17.6,4.4-26,5.1-22.4,10.8-37.1,10.3-42.1,1.93-3.77,4.21-7.19,6.97-9.59-1.65,4.98-4.2,9.66-4.95,14.91-.37,2.57-.65,5.15-.99,7.73-.5,1.55-.98,3.1-1.38,4.67-1.23,4.9-2.05,9.93-2.11,14.99-.19.24-.34.53-.39.85-.9,5.26-2.6,10.36-3.19,15.68-.62,5.55-.77,11.07-.77,16.65,0,1.49,1.08,2.29,2.23,2.39.22.06.46.11.73.11.07,0,.13-.02.2-.02-.34.43-.54.96-.56,1.51-2.38,1.23-4.53,2.83-5.9,5.23-.36.62-.41,1.31-.23,1.93-.61.43-1.29.82-2.06,1.16-2.2-2.5-2.5-6-2.3-10.1Z"/>
      <path id="fingerparthighlight5" class="Finger1341Fill"  style={{
              fill: props.colors.Finger1341Fill,
              stroke: props.colors.Finger1341Fill,
            }} d="M162.1,180.13l6.3-6.7c5-3.3,8.8-8,11-14.4-1.5-12.5,4.1-15.5,14.4-12,5.6,6.1,8.1,12.5,2,20.1-4.4,4.2-8.4,8.8-3.9,21.4-1.3,5-3,9.4-6.6,11.4-6.2,1.6-15-3.2-17.6-5.1-3.2-6-5.1-10.9-5.4-14.7h0s-.2,0-.2,0Z"/>
      <path id="fingerparthighlight4" class="Finger1341Fill"  style={{
              fill: props.colors.Finger1341Fill,
              stroke: props.colors.Finger1341Fill,
            }} d="M163.5,258.13h0c-.8,7.9-2.9,14-10,13.7-5.1,1.7-5.6-1.1-5.9-4.1l-14.7-11.8c-6.4-5.4-1.5-9.9,5.6-14.1,2-8.9,9.6-25.2,18.4-43.3.8-7.8,2.8-12.3,5.8-14.2l5,10.6c4.1,2.4,8.2,5.1,14,5.3l.4,2.6c-1.3,5.6-5.2,9.9-11.1,13.2-14.2,28.8-6.6,24.7-7.5,42.2h0v-.1Z"/>
      <path id="fingerparthighlight3" class="Finger1341Fill"  style={{
              fill: props.colors.Finger1341Fill,
              stroke: props.colors.Finger1341Fill,
            }} d="M69.2,194.23v-.1h0l-3.9.9-8.1-3.2c.2,2.4-4.6,4.1-8.3,6l-2.5-3.2c.6-12.8,2.7-27.3,6.6-43.4,2.5-14.5,5.4-28,10.3-37.2,2,2.9,8.3,1.3,13.7,1,5.1,5.6,3.5,8.7-1.4,10.6-3.8,3.3-5.4,6.3-5.5,9.2-1.6,18.1-2.3,34.1-2.3,48,5.1,3,6.2,6.7,1.4,11.4h0Z"/>
      <path id="fingerparthighlight2" class="Finger1341Fill"  style={{
              fill: props.colors.Finger1341Fill,
              stroke: props.colors.Finger1341Fill,
            }} d="M88.3,68.93h0c2.8-1.4,1.6-4.1-3-7.8-2.6-.6-5,.3-7.2,1.9l-7.1,21.8c-.9,5.4-2.4,11.1-6.1,18.1-4.3,6.4-5.5,10.6-1.5,11.2,3.9,3.1,8.6,2.8,13.7,1,1.6,1.5,3.2,2.7,4.6,2.2,2-9.2,1.2-12.9.4-16.2-1.5-22.9,2.1-28.4,6.2-32.2h0Z"/>
      <path id="fingerparthighlight1" class="Finger1341Fill"  style={{
              fill: props.colors.Finger1341Fill,
              stroke: props.colors.Finger1341Fill,
            }} d="M97.3,21.93h0c-1-1.9-3.4,3.1-6.6,5.9-2,6-3.5,12.8-4.7,20.7-4.4,3.6-6.9,8.5-7.8,14.4,1.9-1.6,4.2-2.4,7.2-1.9,2.2.4,4,2.5,5.2,6.2,4.4-.8,5-4,3-8.9l-1-4.4c1.6-4.2,1.9-8.8,1.3-13.5,2.5-.5,4.6-1.5,5.6-3.6.9-4.2.7-13.3-2.2-14.8h0s0-.1,0-.1Z"/>
    </g>
    <g id="Highlights2">
      <path id="fingerparthighlight12" class="MHK1341Fill"  style={{
              fill: props.colors.MHK1341Fill,
              stroke: props.colors.MHK1341Fill,
            }} d="M41.3,207.23c1.55-.8,3.08-1.58,3.94-2.73-.02,1.27-.08,2.54-.14,3.81-.49.31-.86.83-.9,1.58-.2,3.89-1.01,7.65-.41,11.55.45,2.92,1.62,5.64,2.7,8.38-.02.13-.02.28,0,.43.74,9.56,2.81,18.92,5.07,28.22,0,.04,0,.07.01.11.14.67.3,1.34.46,2.01.32,5.71,1.69,11.37,3.47,16.83.47,3.56,1.32,7,2.43,10.37.67,3.78,1.5,7.52,2.28,11.29.1.49.2.97.31,1.46-.04.16-.06.33-.06.51.04,3.93,1.13,7.65,1.89,11.47.68,3.39.72,6.91,1.97,10.18.15.4.4.75.7,1.01.33.93.67,1.86,1,2.79-.15.22-.27.47-.34.75-.83,1-1.19,2.31-1.46,3.61-.4.26-.72.65-.81,1.12-.27,1.37-.86,2.65-1.3,3.98-.32.98-.51,1.98-.52,2.99-.24.34-.38.75-.38,1.16,0,1.45.18,2.92.53,4.33.07.27.2.51.37.72.01.13.02.26.03.39-.41,1.73-.77,2.24-2.65,3.13-4.8-2.8-2.7-6.4-2.1-11.1l1.1-7.2c1.1-7,.6-14.1-1.4-20.9-7.8-25.2-8.9-60.6-12.3-68.7l-1.7-8.7c-2.5-3.1-4.3-6.4-4.8-9.7-2.2-3-1-8.2,3-15.1Z"/>
      <path id="fingerparthighlight11" class="MHK1341Fill"  style={{
              fill: props.colors.MHK1341Fill,
              stroke: props.colors.MHK1341Fill,
            }} d="M90.6,350.03v.3h0c-3.2.2-5.7,1-7.7,2-2.2.1-4.5-.2-8.4-4.5-2.3,3.5-4.7,5.7-7.2,5.5-8.4-7.4-5.8-17-1.7-26.9l-20.2-96.8c-3.6-6.8-4.6-13.8-.8-21.5-1.7-2.8-.3-6,4.2-9.8l8.6-4.9c1.7-.8,4.5.2,7.8,2,2.3.1,4.6.3,7.3-.2,2.6,4.3,4.9,9.7,6.9,16.3.6,4.4-3.1,11-7.1,17.7-6.4,10.5-.1,72.1,13.5,97.1,4.5,4.5,8.4,9.1,5.7,13.6l-2.2,3.4c-.4,2.8.1,5,1.3,6.7h0Z"/>
      <path id="fingerparthighlight10" class="MHK1341Fill"  style={{
              fill: props.colors.MHK1341Fill,
              stroke: props.colors.MHK1341Fill,
            }} d="M130.1,333.13h0l.4,8.1c5.8,10.9,1.7,18.1-3.3,18.4-5.4,3.6-13.3,3.1-22.5.5-3.5-3.7-5.1-8.2-5.4-13.2,0-7,.7-12.9,2.6-17,3.6-2.1,6.1-5.1,8.1-8.4,2.6-8.8,5.9-20.1,9.9-35.1,4.2-7.4,5.3-13,4.7-17.8-.3-6.7,3-10.4,8.3-12.5,4.3,3.7,8.7,7.3,13.4,10.8,2.4,1.8.3,7.2,7.2,5.1l5.3,1.9c1.4,5.9-1.4,15.3-10.8,17.1-4.9,9-11.3,24.8-18.1,42h0s.2.1.2.1Z"/>
    </g>
    <g id="outlines">
      <path id="outlinepart1" class="Hand_lat_links1341_cls4" d="M116.5,476.13l8.7-28.3c.8-2.6,2.2-5.1,4.1-7.1l2.8-3,6.2-5.8c24.3-16.5,36.3-90.9,33.5-122.9-1.5-16.8,2.6-52.9,33.9-128.5,21.9-52.7-10.1-64-31.5-38.7-3.4,4.1-6.2,9.3-10,14.3-11,14.6-16.9,21.3-23.1,39.5-1.3,3.9-1,4.7-7.8,24.5-6.5,19-13.4,32.3-18.6,37.9-.5.5,1.9-4-1.1,1.6-3,5.6-11.1-7.7-11.3-8.8-13.2-62.5-11.9-47.7-13.2-88.2-1.3-39.8,27.4-112.9,22.7-137.6-4.5-23.9-19.1-26.6-27.5-7.8-8.4,18.8-20.7,55.4-40,101.8-20,48.1-25.4,58.4-18.1,109.4,5.9,41.2,13.3,75.5,9.4,124.6-.3,2.7-12.7,108.8-27.7,123.1"/>
    </g>
    <g id="outlinehighlight">
      <path id="outlinehighlightpart1" class="weichteile1341Fill"  style={{
              fill: props.colors.weichteile1341Fill,
              stroke: props.colors.weichteile1341Fill,
            }} d="M122.1,474.73h.1s-5.2,0-5.2,0l8.3-26.9c.8-2.6,2.2-5.1,4.1-7.1l2.8-3,6.2-5.8c24.3-16.5,36.3-90.9,33.5-122.9-1.5-16.8,2.6-52.9,33.9-128.5,21.9-52.7-10.1-64-31.5-38.7-3.4,4.1-6.2,9.3-10,14.3-11,14.6-16.9,21.3-23.1,39.5-1.3,3.9-1,4.7-7.8,24.5-6.5,19-13.4,32.3-18.6,37.9-.5.5,1.9-4-1.1,1.6-3,5.6-11.1-7.7-11.3-8.8-13.2-62.5-11.9-47.7-13.2-88.2-1.3-39.8,27.4-112.9,22.7-137.6-4.5-23.9-19.1-26.6-27.5-7.8-8.4,18.8-20.7,55.4-40,101.8-20,48.1-25.4,58.4-18.1,109.4,5.9,41.2,13.3,75.5,9.4,124.6-.3,2.8-12.7,107.5-27.7,121.8H0c16.5-14.4,31.1-112.4,31.3-115.2,4.3-49.5-5.5-97.4-12-138.9-8.1-51.4.8-60.5,22.9-108.9,21.3-46.7,31.9-81.4,41.2-100.3,9.3-18.9,27.9-13.9,32.9,10.2,5.2,24.9-23.3,100.9-21.9,141,1.5,40.7-3.6,15,11,77.9.3,1.1,3.9,16.9,7.2,11.3.3-.6,1.9-.9,5.3-6.1s10.2-27.2,19.3-50.6c13.31-30.55,3.62-8.91,13.6-28.7,26.1-36,16.7-23.1,19.8-27.7,17.5-25.7,60.5-15.2,39.7,36.5-31.3,78-33.9,105.3-33.9,128.3-2.4,81.3-18.1,120-40.2,137.2-10.2,8.3-11,26.1-14.1,33.9h0Z"/>
    </g>
  </svg>
  );
}

export default LinkeHandLatSitzend1341;
