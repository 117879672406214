import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { getDDMMYYY_HHMM } from "../../../Utilities/DateTimeUtils/DateTimeUtils";
import {
  loadExamContourPreview,
  loadExaminationSeries,
  loadImageSurfaceDose,
} from "../../../Utilities/Examinations/ExaminationsUtils";
import {
  loadMasKv,
  updateMasKvEntry,
} from "../../../Utilities/Examinations/maskv";
import {
  loadExamination,
  loadExamImagePreview,
} from "../../../Utilities/Examinations/ExaminationsUtils";

import "./DoseModalBasic.scss";

function DoseModalBasic({ exam_data }) {
  const { keycloak } = useKeycloak();

  const [onlyEmptyEntries, setOnlyEmptyEntries] = useState(true);

  const [seriesData, setSeriesData] = useState(undefined);
  const [surfaceDose, setSurfaceDose] = useState(undefined);
  const [surfaceDoseExpanded, setSurfaceDoseExpanded] = useState(false);
  const [surfaceDoseFormValidated, setSurfaceDoseFormValidated] =
    useState(false);
  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      canny_min_val: 10, //0-100
      canny_max_val: 15, //0-100
      dilate_size: 3, //1-15
      morph_iterations: 5, // 1-20
      min_contour_area: 1200, // 1000-10000
    },
  });

  const [loadingState, setLoadingState] = useState(0); //0-not loaded 1-loading 2-loaded 3-failedloading

  const [currentSerie, setCurrentSerie] = useState(undefined);
  const [currentImage, setCurrentImage] = useState(undefined);
  const [imagePreview, setImagePreview] = useState(undefined);
  const [imagePreviewBase, setImagePreviewBase] = useState(undefined);
  const [dosesBefore, setDosesBefore] = useState(undefined);
  const [dosesAfter, setDosesAfter] = useState(undefined);
  const [doseToAssign, setDoseToAssign] = useState(undefined);

  const delta = 5;

  const loadSeriesInfo = () => {
    setLoadingState(1);
    loadExaminationSeries(keycloak, exam_data.id)
      .then((series) => {
        let shouldFinish = false;
        series.series.every((serie, i) => {
          if (shouldFinish) {
            return true;
          }
          setCurrentSerie(i);
          if (serie.image_collection.length > 0) {
            setCurrentImage(0);
            loadUnasignedDoses(serie.seriesdatetime);
            loadPreviewImageByUrl(serie.image_collection[0].preview_url);
            loadSurfaceDose(
              serie.image_collection[0]?.mas_kv_collection[0]?.surface_dose
            );

            shouldFinish = true;
          }
        });
        setLoadingState(2);
        setSeriesData(series.series);
      })
      .catch((error) => {
        setLoadingState(3);
        console.warn(error);
        NotificationManager.error("Unable to load examination data");
      });
  };

  const refreshSeriesInfo = () => {
    loadExaminationSeries(keycloak, exam_data.id)
      .then((series) => {
        setSeriesData(series.series);
      })
      .catch((error) => {
        console.warn(error);
        NotificationManager.error("Unable to refresh examination data");
      });
  };

  const loadPreviewImage = () => {
    if (seriesData) {
      setImagePreview(undefined);
      loadExamImagePreview(
        keycloak,
        seriesData[currentSerie].image_collection[currentImage].preview_url
      )
        .then((data) => setImagePreview(URL.createObjectURL(data)))
        .catch((reason) => {
          setImagePreview(null);
          console.warn("Unable to load image previev: ", reason);
        });
    }
  };

  const loadSurfaceDose = (url, args = "") => {
    loadImageSurfaceDose(keycloak, url, args)
      .then((data) => {
        setSurfaceDose(data);
        if (surfaceDoseExpanded) {
          setImagePreview(`data:image/png;base64,${data.contour_img}`);
        }
      })
      .catch((reason) => {
        setImagePreview(null);
        console.warn("Unable to load image previev: ", reason);
      });
  };

  const loadPreviewImageByUrl = (url) => {
    setImagePreview(undefined);
    loadExamImagePreview(keycloak, url)
      .then((data) => {
        setImagePreview(URL.createObjectURL(data));
        setImagePreviewBase(URL.createObjectURL(data));
      })
      .catch((reason) => {
        setImagePreview(null);
        console.warn("Unable to load image previev: ", reason);
      });
  };

  const nextImage = () => {
    let images = seriesData[currentSerie].image_collection;
    if (images.length - 1 > currentImage) {
      setCurrentImage(currentImage + 1);
      return;
    }
    if (seriesData.length - 1 > currentSerie) {
      if (seriesData[currentSerie + 1].image_collection.length > 0) {
        setCurrentSerie(currentSerie + 1);
        setCurrentImage(0);
        return;
      } else {
        let itr = currentSerie + 1;
        for (itr; itr < currentSerie.length; itr++) {
          if (seriesData[itr].image_collection.length > 0) {
            setCurrentSerie(itr);
            setCurrentImage(0);
            return;
          }
        }
      }
    }
  };

  const prevImage = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
      return;
    }
    if (currentSerie > 0) {
      let itr = currentSerie - 1;
      for (itr; itr >= 0; itr--) {
        if (seriesData[itr].image_collection.length > 0) {
          setCurrentSerie(itr);
          setCurrentImage(seriesData[itr].image_collection.length - 1);
          return;
        }
      }
    } else {
      return;
    }
  };

  const saveAssignedDose = () => {
    let obj = {
      stdid: exam_data.id,
      imageid: seriesData[currentSerie].image_collection[currentImage].image_id,
    };
    updateMasKvEntry(keycloak, doseToAssign.id, obj)
      .then(() => {
        refreshSeriesInfo();
        NotificationManager.success("Dose assigned successfully");
        setDoseToAssign(undefined);
        nextImage();
      })
      .catch((reason) => {
        console.warn("Unable to update maskv entry", reason);
        NotificationManager.error("Unable to update maskv entry");
      });
  };

  const loadUnasignedDosesBefore = (date = undefined) => {
    let filter = {
      and: [
        { field: "imageid", op: "==", value: 0 },
        {
          field: "zeitstempel",
          op: "<=",
          value: date
            ? getDDMMYYY_HHMM(date)
            : getDDMMYYY_HHMM(seriesData[currentSerie]?.seriesdatetime),
        },
      ],
    };
    let sort = { field: "zeitstempel", direction: "asc" };
    loadMasKv(keycloak, delta, 1, filter, sort)
      .then((data) => {
        setDosesBefore(data);
      })
      .catch((reason) => {
        console.warn("Unable to load doses before: ", reason);
      });
  };

  const loadUnasignedDosesAfter = (date = undefined) => {
    let filter = {
      and: [
        { field: "imageid", op: "==", value: 0 },
        {
          field: "zeitstempel",
          op: ">=",
          value: date
            ? getDDMMYYY_HHMM(date)
            : getDDMMYYY_HHMM(seriesData[currentSerie]?.seriesdatetime),
        },
      ],
    };
    let sort = { field: "zeitstempel", direction: "asc" };
    loadMasKv(keycloak, delta, 1, filter, sort)
      .then((data) => {
        setDosesAfter(data);
      })
      .catch((reason) => {
        console.warn("Unable to load doses after: ", reason);
      });
  };

  const loadUnasignedDoses = (date = undefined) => {
    if (date) {
      loadUnasignedDosesAfter(date);
      loadUnasignedDosesBefore(date);
      return;
    }
    if (seriesData) {
      loadUnasignedDosesAfter();
      loadUnasignedDosesBefore();
    }
  };

  const contourToggleOver = () => {
    if (!surfaceDoseExpanded) {
      setImagePreview(`data:image/png;base64,${surfaceDose.contour_img}`);
    }
  };

  const contourToggleLeave = () => {
    if (!surfaceDoseExpanded) {
      setImagePreview(imagePreviewBase);
    }
  };

  const surfaceDoseClick = () => {
    setSurfaceDoseExpanded(!surfaceDoseExpanded);
  };

  const generateNewContourPreview = (data) => {
    setSurfaceDoseFormValidated(true);
    loadSurfaceDose(
      seriesData[currentSerie].image_collection[0]?.mas_kv_collection[0]
        ?.surface_dose,
      data
    );
  };

  useEffect(loadSeriesInfo, []);

  useEffect(loadPreviewImage, [currentImage]);

  useEffect(loadUnasignedDoses, [currentSerie]);

  return (
    <>
      <div className="border rounded d-flex flex-column align-items-center justify-content-center w-100 p-3 mb-3">
        <h3>{exam_data?.patientsname.replace("^", " ")}</h3>
        <h4>
          {exam_data?.modality} | {exam_data?.studydescription} |{" "}
          {getDDMMYYY_HHMM(exam_data?.studydate)}
        </h4>
      </div>
      <div
        className="border rounded p-2 d-flex flex-column w-100"
        style={{ height: "73vh", overflowY: "auto" }}
      >
        <div className="border rounded bg-light mb-2 d-flex align-items-center justify-content-center ">
          <h5 className="p-2">Please choose unasigned dose</h5>
          {/* <i
            onClick={() => {
              setOnlyEmptyEntries(!onlyEmptyEntries);
            }}
            className={`ml-auto mr-1 ${
              onlyEmptyEntries ? "fas fa-check-square" : "far fa-square"
            }`}
          />
          <div className="mr-2">Show Only Unasigned</div> */}
        </div>
        <div className="d-flex mb-2" style={{ height: "50vh", gap: "10px" }}>
          <div
            className="d-flex flex-column"
            style={{ minWidth: "20vw", gap: "5px" }}
          >
            <div className="font-weight-bold border rounded p-2 bg-light w-100 mb-2">
              Unasigned Doses
            </div>
            {dosesBefore?.maskv?.map((dose) => (
              <div
                className={`text-center border rounded p-1 image-desc ${
                  doseToAssign?.id === dose.id && "selected"
                }`}
                onClick={() => setDoseToAssign(dose)}
              >{`${dose.mas} mas | ${dose.kv} kV | ${
                dose.dosis
              } μGy | ${getDDMMYYY_HHMM(dose?.zeitstempel)}`}</div>
            ))}
            <div className="bg-success rounded text-white font-weight-bold text-center p-1">
              ** Examination {getDDMMYYY_HHMM(currentImage?.serieDateTime)} **
            </div>
            {dosesAfter?.maskv?.map((dose) => (
              <div
                className={`text-center border rounded p-1 image-desc ${
                  doseToAssign?.id === dose.id && "selected"
                }`}
                onClick={() => setDoseToAssign(dose)}
              >{`${dose.mas} mas | ${dose.kv} kV | ${
                dose.dosis
              } μGy | ${getDDMMYYY_HHMM(dose.zeitstempel)}`}</div>
            ))}
          </div>

          <div className="d-flex flex-column w-100" style={{ gap: "5px" }}>
            <div
              className="p-2 mb-2 d-flex justify-content-center align-items-center border rounded bg-light"
              style={{ gap: "10px" }}
            >
              <div className="font-weight-bold">
                {seriesData &&
                  seriesData[currentSerie]?.image_collection[currentImage]
                    ?.mas_kv_collection[0]?.mas}{" "}
                mAs
              </div>
              <div className="font-weight-bold">
                {seriesData &&
                  seriesData[currentSerie]?.image_collection[currentImage]
                    ?.mas_kv_collection[0]?.kv}{" "}
                kV
              </div>
              <div className="font-weight-bold">
                {seriesData &&
                  seriesData[currentSerie]?.image_collection[currentImage]
                    ?.mas_kv_collection[0]?.dosis}{" "}
                μGy
              </div>
            </div>

            {surfaceDose && (
              <div
                className={`mb-1 d-flex align-items-center justify-content-center border rounded  hoverable-div ${
                  surfaceDoseExpanded ? "clicked-div" : ""
                }`}
                style={{ gap: "10px" }}
                onMouseOver={contourToggleOver}
                onMouseLeave={contourToggleLeave}
                onClick={surfaceDoseClick}
              >
                <div className="font-weight-bold p-2 text-center ">
                  Estimated Surface Dose:{" "}
                  {(
                    surfaceDose.value *
                    seriesData[currentSerie]?.image_collection[currentImage]
                      ?.mas_kv_collection[0]?.dosis
                  ).toFixed(1)}
                  {" μGy "}
                </div>
                <i
                  className={`ml-auto mr-2 border rounded-circle px-2 py-1  fas ${
                    surfaceDoseExpanded ? "fa-chevron-right" : "fa-chevron-left"
                  } fa-sm`}
                />
              </div>
            )}
            <div
              name="imagebox"
              className="py-4 bg-dark text-white rounded d-flex align-items-center justify-content-center w-100"
            >
              {imagePreview === undefined && (
                <ClipLoader
                  color="#a1a1a1"
                  loading={true}
                  css={true}
                  size={30}
                />
              )}
              {imagePreview === null && "Unable to load preview image"}
              <img src={imagePreview} alt="exam preview" />
            </div>
          </div>

          <div
            className={`d-flex flex-column form-u-dose ${
              surfaceDoseExpanded && "active"
            }`}
          >
            <Form
              noValidate
              validated={surfaceDoseFormValidated}
              onSubmit={handleSubmit(generateNewContourPreview)}
            >
              <Form.Group className="mb-1 border rounded bg-light bg-light">
                <Form.Label className="small text-muted ml-1">
                  {`Value: ${watch("canny_min_val")}`}
                </Form.Label>
                <Form.Control
                  className=""
                  {...register("canny_min_val", { required: true })}
                  type="range"
                  placeholder="Enter min Canny threshold"
                  min={1}
                  max={100}
                />
                <Form.Label className="small text-muted ml-1">
                  Canny min. value
                </Form.Label>
              </Form.Group>

              <Form.Group className="mb-1 border rounded bg-light">
                <Form.Label className="small text-muted ml-1">
                  {`Value: ${watch("canny_max_val")}`}
                </Form.Label>
                <Form.Control
                  {...register("canny_max_val", { required: true })}
                  type="range"
                  placeholder="Enter max Canny threshold"
                  min={1}
                  max={100}
                />
                <Form.Label className="small text-muted ml-1">
                  Canny max. value
                </Form.Label>
              </Form.Group>

              <Form.Group className="mb-1 border rounded bg-light">
                <Form.Label className="small text-muted ml-1">
                  {`Value: ${watch("dilate_size")}`}
                </Form.Label>
                <Form.Control
                  {...register("dilate_size", { required: true })}
                  type="range"
                  placeholder="Enter dilation size"
                  min={1}
                  max={15}
                />
                <Form.Label className="small text-muted ml-1">
                  Dilatation size
                </Form.Label>
              </Form.Group>

              <Form.Group className="mb-1 border rounded bg-light">
                <Form.Label className="small text-muted ml-1">
                  {`Value: ${watch("morph_iterations")}`}
                </Form.Label>
                <Form.Control
                  {...register("morph_iterations", { required: true })}
                  type="range"
                  placeholder="Enter number of morph iterations"
                  min={1}
                  max={20}
                />
                <Form.Label className="small text-muted ml-1">
                  Morph iterations
                </Form.Label>
              </Form.Group>

              <Form.Group className="mb-1 border rounded bg-light">
                <Form.Label className="small text-muted ml-1">
                  {`Value: ${watch("min_contour_area")}`}
                </Form.Label>
                <Form.Control
                  {...register("min_contour_area", { required: true })}
                  type="range"
                  placeholder="Minimum contour area allowed"
                  min={1000}
                  max={10000}
                />
                <Form.Label className="small text-muted ml-1">
                  Minimum contour area
                </Form.Label>
              </Form.Group>

              <Button variant="primary" className="mt-2" type="submit">
                <i className="fas fa-redo fa-sm mr-2" />
                Refresh Contours
              </Button>
            </Form>
          </div>
        </div>
        <div className="mt-auto d-flex" style={{ gap: "5px" }}>
          <Button
            style={{ width: "150px" }}
            disabled={false}
            onClick={prevImage}
          >
            <i className="fas fa-chevron-left mr-1" />
            Previous
          </Button>
          <Button
            className="ml-auto"
            style={{ width: "150px" }}
            variant="secondary"
            disabled={doseToAssign === undefined}
            onClick={() => setDoseToAssign(undefined)}
          >
            <i className="fas fa-ban mr-1" />
            Reject
          </Button>

          <Button
            style={{ width: "150px" }}
            disabled={doseToAssign === undefined}
            onClick={saveAssignedDose}
          >
            <i className="fas fa-save mr-1" /> Save & Next
          </Button>
          <Button
            className="ml-auto"
            style={{ width: "150px" }}
            onClick={nextImage}
          >
            <i className="fas fa-chevron-right mr-1" />
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

export default DoseModalBasic;
