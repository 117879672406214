import { useState } from "react";
import { Button } from "react-bootstrap";
import { getDDMMYYY } from "../../Utilities/DateTimeUtils/DateTimeUtils";
import BodyPartsList from "../BodyPartsList/BodyPartsList";
import DateRangeSelectorModal from "../DateRangeSelectorModal/DateRangeSelectorModal";
import BodyPartExams from "./BodyPartExams";
import BodyPartCharts from "./BodyPartCharts";

import "./stylesheets/BodyPartsStatistics.scss";

function BodyPartsStatistics() {
  const [selectedMode, setSelectedMode] = useState(0);
  const [dateRangeSelectorShown, setDateRangeSelectorShown] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [selectedBodyPart, setSelectedBodyPart] = useState([]);
  const selectBpartHandler = (bpart) => {
    setSelectedBodyPart([bpart]);
  };

  return (
    <>
      <DateRangeSelectorModal
        shown={dateRangeSelectorShown}
        hideHandler={() => {
          setDateRangeSelectorShown(false);
        }}
        dateRange={dateRange}
        dateRangeSetter={setDateRange}
      />
      <div className="bpart-stats-layout">
        <div
          className="border rounded p-2 bg-light"
          style={{ flexGrow: 1, height: "94vh" }}
        >
          <BodyPartsList
            selected={selectedBodyPart}
            selectHandler={selectBpartHandler}
            itemsPerPage={30}
            listHeight="81vh"
          />
        </div>
        <div className="border rounded bg-light p-1">
          <div className="d-flex align-items-center mb-1">
            <div className="mode-switch">
              <div
                onClick={() => setSelectedMode(0)}
                className={`${selectedMode === 0 ? "selected" : ""}`}
              >
                Table
              </div>
              <div
                onClick={() => setSelectedMode(1)}
                className={`${selectedMode === 1 ? "selected" : ""}`}
              >
                Charts
              </div>
            </div>
            <div className="px-2 border rounded py-2">
              From: {getDDMMYYY(dateRange.startDate)} To:{" "}
              {getDDMMYYY(dateRange.endDate)}
            </div>
            <Button
              onClick={() => {
                setDateRangeSelectorShown(true);
              }}
              className="ml-1"
            >
              <i className="fas fa-calendar-alt"></i>
            </Button>
          </div>
          <div>
            {selectedMode === 0 ? (
              <BodyPartExams
                selectedBodyPart={selectedBodyPart[0]}
                dateRange={dateRange}
              />
            ) : (
              <BodyPartCharts
                selectedBodyPart={selectedBodyPart[0]}
                dateRange={dateRange}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BodyPartsStatistics;
