import Controller from "../Common/Controller";

import FrontView from "./views/FrontView";
import BackView from "./views/BackView";

import "./styles/Controller.scss";

function PelvisController() {
  const availableViews = [FrontView, BackView];
  return <Controller availableViews={availableViews} />;
}

export default PelvisController;
