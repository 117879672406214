import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const Chest = map.Chest;

function RightView({ bpartSetter, selected }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.54 197.21">
      <g id="thorax_laterally_right" data-name="thorax laterally right">
        <g id="costal_cartilage" data-name="costal cartilage">
          <path
            id="costal_cartilage_1_"
            data-name="costal cartilage (1)"
            class="stroke clear-fill"
            d="M118.82,109.53c1.54-1.88,1.91-6.73,1.73-12.99l1.89,2.3,2.41,8.01c3.29,3.72,5.13,7.32,6.07,10.83,1.55,6.13,1.45,10.57,0,13.64,.4,8.81-.35,15.97-1.83,22.08-1.45,7.67-4.96,13.83-8.3,20.11-3.99,3.21-7.47,4.5-10.69,4.78,1.72-.74,2.05-3.08,1.12-6.89,3.91,.03,7.36-2.93,10.55-7.59-2.53,1.32-4.63,1.88-6.05,1.27,1.68-.66,2.11-2.86,1.97-5.77,3.05-.15,5.93-2.29,8.58-7.45,1.57-4.61,1.06-4.85,0-3.94-1.42,2.06-3.39,2.84-5.77,2.67,1.96-2.07,2.57-4.37,1.93-6.89,1.61-.33,3.21-2.62,4.82-6.89,2.06-7.62,2.33-10.46-.98-3.8-.56,2.16-1.79,2.83-3.84,1.69,1.46-2.5,.51-5.72-.66-9,2.2-1.46,2.53-4.41,2.39-7.73,.39-5.98-.07-7.5-1.73-2.81-.49,2.31-1.19,2.13-1.93,1.41,1.37-2.83,.48-5.09-1.69-7.03h.01Z"
          />
        </g>
        <g id="sternum">
          <path
            id="sternum_3"
            data-name="sternum 3"
            class="stroke clear-fill"
            d="M78.18,16.02c2.02-.64,4.14-.34,6.47,1.97,2.83,9.18,7.02,14.85,15.61,20.95l4.22,9.98c3.18,5.25,4.98,9.11,6.75,12.94,.85,4.58,2.75,9.2,6.33,13.92l3.23,6.61c.46,4.98,2.53,9.94,5.48,14.91-1.26,.22-2.54,.21-3.84-.1-.87-.21-1.75-.54-2.63-1.03,.89-1.23,1.22-2.49,.28-3.8-1.03-3.87-2.41-5.26-3.94-5.62l-1.12,1.55c-.13-3.99-1.73-6.4-3.8-8.3,1.24,.11,2.2-.5,2.81-1.97,.51-3.86-1.31-5.98-3.8-7.59-1.06,.02-1.55,.61-1.55,1.69-2.23-1.74-3.54-5.62-4.64-9.98,1.11-.42,1.67-1.23,1.55-2.53,.11-3.19-1.46-4.28-3.38-4.92l-1.69,.56c-1.65-3.88-4.28-7.08-7.73-9.7,1.2-3.07-.44-5.73-3.66-8.16-3.54-3.76-6.77-7.19-9.14-9.7,1.99-2.34,1.13-4.53-1.83-6.61,1.17-1.43,1.08-3.13,0-5.06h.02Z"
          />
          <line
            id="sternum_2"
            data-name="sternum 2"
            class="stroke clear-fill"
            x1="92.47"
            y1="40.93"
            x2="96.98"
            y2="36.45"
          />
          <path
            id="sternum_"
            data-name="sternum !"
            class="stroke clear-fill"
            d="M126.27,107.84c1.3-2.26,1.32-5.76,0-10.55l-3.84-.1c.82,6.58,2.02,10.65,3.84,10.64h0Z"
          />
        </g>
        <path
          id="collarbone"
          class="stroke clear-fill"
          d="M38.8,14.19c-4.6-1.49-4.78-3.12,0-4.92,1.52-1.67,14.06,0,14.06,0,0,0,7.75,2.05,10.55,2.46,4.29,.46,9.48,2.48,15.42,5.85,1.3,1.8-.09,3.14-3.89,4.07-3.22-.85-6.25-2.07-9.14-3.52-2.23-.81-7.42-2.66-12.94-4.64-2.67-.56-3.66,.28-5.2,.42-3.07-1.36-6.02-1.36-8.86,.28h0Z"
        />
        <g id="rib_1" data-name="rib 1">
          <path
            id="rib_1_2_"
            data-name="rib 1 (2)"
            class="stroke clear-fill"
            d="M75.64,27.27c1.59-1.82,1.23-3.7-.7-5.62-7.74,1.04-15.44-2.2-23.11-8.31-1.56-.52-2.93,.14-4.18,1.58-.28,1.58-.06,2.71,.84,3.22,8.02,7.17,17.01,10.45,27.14,9.14h0Z"
          />
          <path
            id="rib_1_1_"
            data-name="rib 1 (1)"
            class="stroke clear-fill"
            d="M76.65,27.55c1.05,.15,2.18,.19,3.36,.14,2.07-2.42,.82-4.87-1.37-7.34-1.56,1.12-2.7,1.35-3.7,1.29,2.25,2.51,2.11,4.27,.7,5.62l1,.28h.01Z"
          />
        </g>
        <g id="rib_2" data-name="rib 2">
          <path
            id="rib_2_2_"
            data-name="rib 2 (2)"
            class="stroke clear-fill"
            d="M53.57,29.8c10.49,5.16,20.6,8.3,30.23,8.83,.66,.04,1.32,.06,1.98,.07,.35,.24,.68,.47,.99,.71,2.98,2.24,4.35,4.35,3.79,6.28-2.02,2.02-6.62,1.02-11.24,0-5.12-1.5-9.44-2.61-13.65-3.66-4.03,.08-9.25-3.06-14.62-6.61-2.14-6.05-.98-7.49,2.53-5.62h0Z"
          />
          <path
            id="rib_2_1_"
            data-name="rib 2 (1)"
            class="stroke clear-fill"
            d="M89.14,37.39l-3.38,1.3c2.91,1.6,4.71,3.82,4.78,6.99l2.25-.14c.77-3.5-.49-6.21-3.66-8.16h0Z"
          />
        </g>
        <g id="rib_3" data-name="rib 3">
          <path
            id="rib_3_2_"
            data-name="rib 3 (2)"
            class="stroke clear-fill"
            d="M49.96,45.13c16.04,7.04,32.17,11.46,48.47,10.83,.39,.21,4.94,4.37,2.67,6.89-5.29,2.54-25.3-2.38-40.64-4.92-5.28-1.37-9.9-4.14-14.06-7.88,1.86-.74,3.38-1.93,3.57-4.92h-.01Z"
          />
          <path
            id="rib_3_1_"
            data-name="rib 3 (1)"
            class="stroke clear-fill"
            d="M102.22,62.84l3.02-1.58c1.15-3.08-.02-5.2-3.02-6.58l-3.8,1.27c3.79,2.22,4.39,4.82,3.41,7.6"
          />
        </g>
        <g id="rib_4" data-name="rib 4">
          <path
            id="rib_4_2_"
            data-name="rib 4 (2)"
            class="stroke clear-fill"
            d="M38.24,56.59c12.35,6.44,36.81,17.22,67.44,17.37h1.75c2.43,1.04,3.55,3.15,2.67,6.89-17.98,2.92-48.53-6.62-76.5-19.27-.22-1.65,1.36-3.31,4.64-4.99Z"
          />
          <path
            id="rib_4_1_"
            data-name="rib 4 (1)"
            class="stroke clear-fill"
            d="M106.84,73.96l3.4-3.52c2.94,1.68,4.29,4.17,3.8,7.59l-3.94,2.81c.39-4.91-.81-7.02-3.26-6.89h0Z"
          />
        </g>
        <g id="rib_5" data-name="rib 5">
          <path
            id="rib_5_2_"
            data-name="rib 5 (2)"
            class="stroke clear-fill"
            d="M27.97,67.91c28.87,14.76,57.62,24.28,86.16,23.34,2.59,2.65,3.24,4.67,2.43,6.2-11.59,3.65-20.56,.04-29.95-2.4-16.02-1.89-36.9-10.57-58.64-18.42-1.93-1.33-3.17-2.41-3.66-3.23,.57-1.92,1.71-3.75,3.66-5.48h0Z"
          />
          <path
            id="rib_5_1_"
            data-name="rib 5 (1)"
            class="stroke clear-fill"
            d="M114.13,91.25c-.15-1.51,.76-3.01,2.01-4.5,3.58,1.66,4.87,4.76,3.66,9.42-.95,1.19-2.06,1.4-3.23,1.28,.62-2.74-.25-4.78-2.43-6.2h-.01Z"
          />
        </g>
        <g id="rib_6" data-name="rib 6">
          <path
            id="rib_6_1_"
            data-name="rib 6 (1)"
            class="stroke clear-fill"
            d="M21.64,80c27.25,13.71,51.31,23.36,69.03,24.89,9.98,3.08,19.53,5.3,27.44,4.22,2.96,2.56,3.97,5.05,2.39,7.45-7.81,.65-16.29-.21-25.17-1.97-8.17-2.25-18.7-5.15-30.09-8.3-19.4-4.83-32.75-13.15-46.55-21.23,.12-1.99,1.07-3.69,2.95-5.06h0Z"
          />
        </g>
        <g id="rib_7" data-name="rib 7">
          <path
            id="rib_7_1_"
            data-name="rib 7 (1)"
            class="stroke clear-fill"
            d="M17.99,91.11c10.35,7.08,20.44,13.39,29.53,16.73l44.44,15.05c11.71,2.7,22.82,4.82,29.81,2.81,1.76,3.68,2.11,6.74,.66,9-4.6,.59-13.8-1.33-22.18-2.81-6.42,.61-27.71-7.74-45.42-13.92-13.2-5.83-23.91-11.64-34.79-17.33l-1.35-.67c-1.96-.7-2.88-2.34-3.09-4.64,.39-2.44,1.08-4.1,2.39-4.22h0Z"
          />
        </g>
        <g id="rib_8" data-name="rib 8">
          <path
            id="rib_8_1_"
            data-name="rib 8 (1)"
            class="stroke clear-fill"
            d="M35.85,116.78c5.29,5.6,30.96,13.69,52.73,21.3,9.38,4.9,21.16,5.98,33.85,5.62,.61,1.93,.43,4.09-1.93,6.89l-22.08-3.94c-50.78-14.39-58.51-21.99-71.72-28.12-4.06-1.01-6.91-5.36-9.42-10.69l1.41-7.88c-.22,2.75,.7,6.3,3.8,9,4.69,4.6,10.06,7.42,13.36,7.81h0Z"
          />
        </g>
        <g id="rib_9" data-name="rib 9">
          <path
            id="rib_9_1_"
            data-name="rib 9 (1)"
            class="stroke clear-fill"
            d="M68.19,145.95c11.43,2.19,22.05,4.95,30.23,9.42,6.57,2.52,13.41,3.25,19.27,3.94,.04,2.45-.44,4.51-1.97,5.77-5.63,1.95-25.43-5.8-41.66-11.11-12.92-3.16-25.79-8.59-38.64-15.19-9.43-3.38-11.86-8.75-12.52-14.62,.92-3.41,1.81-5.53,2.66-6.02,.19,4.28,.75,7.82,2.4,9.11,9.21,7.48,20.92,11.86,40.22,18.7h.01Z"
          />
        </g>
        <g id="rib_10" data-name="rib 10">
          <path
            id="rib_10_1_"
            data-name="rib 10 (1)"
            class="stroke clear-fill"
            d="M76.35,163.11c12.49,5.29,24.38,8.84,34.88,8.3,.79,2.95,1.03,5.6-1.12,6.89-11.97,.7-28.04-5.4-45.14-13.22-10.48-3.08-21.56-9.18-33.05-17.3-3.44-5-1.2-7.92,2.35-10.36-.15,5.43,.58,7.71,4.12,9.52,13.45,6.73,26.34,12.51,37.97,16.17h0Z"
          />
        </g>
        <g id="rib_11" data-name="rib 11">
          <path
            id="rib_11_1_"
            data-name="rib 11 (1)"
            class="stroke clear-fill"
            d="M53,168.31l29.39,10.69,12.8,3.66c.68,1.75,.25,2.89-.84,3.66-5.77-.49-20.17-6.47-31.78-10.55-9.56-1.28-14.61-6.17-19.41-11.25-4.29-5.35-3.53-8.16-1.77-10.46l3.44,2.05c1.46,5.66,4.09,9.85,8.17,12.2h0Z"
          />
          <path
            id="rib_11_1_-2"
            data-name="rib 11 (1)"
            class="stroke clear-fill"
            d="M103.21,185.33c-1.43-1.53-4.38-2.28-8.02-2.67v3.66c3.89,.33,6.56,0,8.02-.98h0Z"
          />
        </g>
        <g id="rib_12" data-name="rib 12">
          <path
            id="rib_12_2_"
            data-name="rib 12 (2)"
            class="stroke clear-fill"
            d="M81.27,195.45c.59-.36,.93-1.16,.7-2.95-11.39-5.6-18.22-11.32-21.77-17.14-2.31-.03-5.24-1.42-8.41-3.34,5.45,8.67,13.03,16.92,29.47,23.44h.01Z"
          />
          <path
            id="rib_12_1_"
            data-name="rib 12 (1)"
            class="stroke clear-fill"
            d="M87.46,196.44c-2.74-3.77-3.78-4.51-5.48-3.94l-.7,2.95c2.06,.98,4.12,1.31,6.19,.98h-.01Z"
          />
        </g>
        <g id="thoracic_vertebrae" data-name="thoracic vertebrae">
          <polygon
            id="thoracic_vertebrae_20"
            data-name="thoracic vertebrae 20"
            class="stroke clear-fill"
            points="39.77 63.55 39.77 64.3 39.77 71.7 37.28 72.48 27.97 67.91 33.58 60.88 39.77 63.55"
          />
          <path
            id="thoracic_vertebrae_19"
            data-name="thoracic vertebrae 19"
            class="stroke clear-fill"
            d="M41.42,81.88c1.43,2.65,1.9,5.39,1.43,8.21l-21.2-10.09,2.67-6.61c6.75,3.69,13.24,7.16,17.11,8.48h0Z"
          />
          <path
            id="thoracic_vertebrae_18"
            data-name="thoracic vertebrae 18"
            class="stroke clear-fill"
            d="M45.4,99c1.75,3.63,2.91,6.7,3.1,8.84-9.37-2.86-19.77-9.21-30.52-16.73l.7-6.05c10.83,6.36,21.84,12.89,26.71,13.94h.01Z"
          />
          <path
            id="thoracic_vertebrae_17"
            data-name="thoracic vertebrae 17"
            class="stroke clear-fill"
            d="M51.53,116.78c1.74,3.72,2.64,6.74,3.03,9.34-31.01-12.15-37.12-19.76-36.57-26.14l33.54,16.81h0Z"
          />
          <path
            id="thoracic_vertebrae_16"
            data-name="thoracic vertebrae 16"
            class="stroke clear-fill"
            d="M56.44,133.71c1.94,2.59,2.63,5.55,2.62,8.71-12.15-4.25-23.65-8.8-31.09-15.17-2.25-2.66-2.93-5.73-2.4-9.11l30.87,15.57h0Z"
          />
          <path
            id="thoracic_vertebrae_15"
            data-name="thoracic vertebrae 15"
            class="stroke clear-fill"
            d="M65.01,151.4c.08,.72,1.48,2.28,1.77,4.96-.72,1.64-2.04,2.03-3.8,1.53-8.5-2.96-17.44-7.2-26.73-12.4l-2.01-7.15,30.77,13.07h0Z"
          />
          <path
            id="thoracic_vertebrae_14"
            data-name="thoracic vertebrae 14"
            class="stroke clear-fill"
            d="M49.05,164.95l2.75-.86c1.41-.98,1.06-2.51,0-4.25l-6.59-2.39c.88,2.82,2.07,5.4,3.84,7.5Z"
          />
          <path
            id="thoracic_vertebrae_13"
            data-name="thoracic vertebrae 13"
            class="stroke clear-fill"
            d="M45.21,166.64c-3.28,.33-6.33,1.43-8.95,4.07-.9-.66-.94-2.53,0-5.75,2.05-2.36,3.71-3.84,4.7-3.75l4.25,5.43Z"
          />
          <path
            id="thoracic_vertebrae_12"
            data-name="thoracic vertebrae 12"
            class="stroke clear-fill"
            d="M39.99,154.06c-1.73,1.96-4.3,3.29-7.65,4.03,.15-3.72,1.02-6.65,3.92-7.33l3.73,3.3h0Z"
          />
          <path
            id="thoracic_vertebrae_11"
            data-name="thoracic vertebrae 11"
            class="stroke clear-fill"
            d="M30.2,143.99c-.64-.76-2.31-.02-4.63,1.69-.72-.84-.75-2.55,0-5.2,.84-1.49,3.64-2.29,6.77-2.98-1.04,1.73-1.93,3.66-2.14,6.5h0Z"
          />
          <path
            id="thoracic_vertebrae_10"
            data-name="thoracic vertebrae 10"
            class="stroke clear-fill"
            d="M22.91,124.16c-.69,.52-1.28,.56-3.52,2.67-.37,2.01-.02,3.53,1.12,4.5,1.61-1.81,2.93-2.94,3.49-2.38l-1.1-4.79h.01Z"
          />
          <path
            id="thoracic_vertebrae_9"
            data-name="thoracic vertebrae 9"
            class="stroke clear-fill"
            d="M38.66,119.09c3.8-.74,7.01-2.15,9.84-4l-5.48-2.62c-2.57-.31-5.62,1.84-8.78,4.57l4.42,2.04h0Z"
          />
          <path
            id="thoracic_vertebrae_8"
            data-name="thoracic vertebrae 8"
            class="stroke clear-fill"
            d="M31.68,99.97c2.14-2.16,4.75-3.28,8.09-2.77l4.54,1.8c-3.08,1.35-5.81,2.54-8.05,3.52l-4.57-2.55h-.01Z"
          />
          <path
            id="thoracic_vertebrae_7"
            data-name="thoracic vertebrae 7"
            class="stroke clear-fill"
            d="M20.16,106.65c2.05-2.44,3.53-3.78,4.15-3.51l5.88,2.03c-3.42,2.87-4.54,5.26-3.59,7.3l-6.44-5.82Z"
          />
          <path
            id="thoracic_vertebrae_6"
            data-name="thoracic vertebrae 6"
            class="stroke clear-fill"
            d="M27.05,126.11c4.26-1.24,6.85-2.41,6.52-3.44l-6.87-4.14c-1.49,1.34-1.03,4.12,.35,7.58Z"
          />
          <path
            id="thoracic_vertebrae_5"
            data-name="thoracic vertebrae 5"
            class="stroke clear-fill"
            d="M42.31,148.89c2.81-2,5.89-3.25,9.48-3.1l2.76,1.82c-2.88,1.54-5.01,2.79-6.79,3.91l-5.45-2.63Z"
          />
          <path
            id="thoracic_vertebrae_4"
            data-name="thoracic vertebrae 4"
            class="stroke clear-fill"
            d="M42.31,134.25c4.03-2.49,8.27-4.23,9.48-2.38l3.35,1.19c-3.33,2.12-6.1,3.3-8.24,3.43-4.06,.24-6.09-1.22-4.59-2.24h0Z"
          />
          <path
            id="thoracic_vertebrae_3"
            data-name="thoracic vertebrae 3"
            class="stroke clear-fill"
            d="M20.16,92.59c.54-2.23,1.67-3.84,3.84-4.4l6.15,3.54c-3.9,.36-6.22,1.31-7.25,2.75l-2.75-1.89h0Z"
          />
          <path
            id="thoracic_vertebrae_2"
            data-name="thoracic vertebrae 2"
            class="stroke clear-fill"
            d="M17.67,108.64c-1.29,.27-2.48,1.39-3.48,4.4-.47,3.73,.09,5.02,1.41,4.51,1.77-1.73,3.22-3.14,4.57-4.45l-2.5-4.45h0Z"
          />
          <path
            id="thoracic_vertebrae_1"
            data-name="thoracic vertebrae 1"
            class="stroke clear-fill"
            d="M9.69,103.63c.91,1.52,2.03,2.06,3.38,1.55,1.53-1.63,2.95-3.51,4.03-6.19-2.53-3.49-2.22-4.87-2.48-3.66-2.52,2.76-4.32,5.52-4.92,8.3h-.01Z"
          />
        </g>
        <g id="shoulder">
          <path
            id="shoulder_9"
            data-name="shoulder 9"
            class="stroke clear-fill"
            d="M39.78,3.08c-.16-2.63,1.12-2.98,3.09-2.25l1.27,1.12c1.13,.37,2.03-.13,2.81-1.12,1.54,.35,3.34,.82,1.27,3.38-.18,1.03-.92,.85-1.97,0-1.79,2.53-.7,3.46,.14,3.97h-5.62l-.98-5.09h-.01Z"
          />
          <path
            id="shoulder_8"
            data-name="shoulder 8"
            class="stroke clear-fill"
            d="M49.16,27.55c-5.45-1.65-7.21,1.83-8.25,6.33-.27,2.3-.67,5.79-1.14,9.84,1.68,4.88,4.24,5.67,7.77,1.97,2.76-2.34,3.95-5.31,3.21-9.07l.28-1.2-1.08-6.39-.8-1.49h0Z"
          />
          <path
            id="shoulder_7"
            data-name="shoulder 7"
            class="stroke clear-fill"
            d="M32.34,27.13c2.45,3.8,4.74,4.91,6.75,.57,1.07-3.94,2.68-5.7,5.23-3.68,2.93,1.37,5.98,.79,9.12-1.12,1.98,.4,2.81,1.37,2.11,3.09-.89,1.86-2.53,2.35-4.5,2.25l-3.49-1.69c-4.67,.12-7.28,2.81-9.55,5.91"
          />
          <path
            id="shoulder_6"
            data-name="shoulder 6"
            class="stroke clear-fill"
            d="M6.88,31.77c5.65-.85,12.43-6.83,25.46-10.12l-3.24-6.56-2.39-4.55c.91-2.83,2.14-2.4,3.45-.98l1.53,.56,2.56-.56c1.4-.31,1.87,.25,1.14,1.88-.93,.76-1.91,1.5-1.51,2.9-.33,2.79,.14,4.55,2.38,4.08l-.97,1.94c1.76-1.59,3.43-3.8,5.01-6.87l7.25-.33,.19,4.06c-4.72,4.82-9.08,6-13.49,7.81-2.2,2.47-3.39,5.41-2.56,9.28"
          />
          <path
            id="shoulder_5"
            data-name="shoulder 5"
            class="stroke clear-fill"
            d="M9.69,39.64c6.7-7.21,13.83-13.05,21.99-15.61,2.69,.69,5.21,1.75,6.95,4.55"
          />
          <path
            id="shoulder_4"
            data-name="shoulder 4"
            class="stroke clear-fill"
            d="M11.06,100.13c-4.09,4.78-7.76,4.11-10.51-8.79,2.88-13.97,3.18-28.01,.98-42.13-1.75-9.03-.41-16.15,5.91-20.17,2.58-2.1,4.09-4.15,4.36-6.13,2.16-3.39,4.42-5.04,6.89-2.56,1.92,3.89,3.79,4.95,5.62,2.56l4.42-.24c2.02-.47,1.78-1.95,0-4.12-2.05-.54-4-1.06-5.82-1.55-.85-2.89-1.77-3.34-2.75-1.92-1.18,.85-2.64,2.98-2.18,4.68"
          />
          <path
            id="shoulder_3"
            data-name="shoulder 3"
            class="stroke clear-fill"
            d="M49.96,42.8v2.33c-.44,2.86-1.33,3.97-2.41,4.36-4.01,1.46-7.08,3.86-9.31,7.1"
          />
          <path
            id="shoulder_2"
            data-name="shoulder 2"
            class="stroke clear-fill"
            d="M15.6,19.25h-3.94c-1.57-.65-1.8-1.54,0-2.82l6.33-3.29c2.23-.67,4.46-1.34,4.92-1.48,1.66,.35,3.05,.19,3.8-1.14l-1.55-1.41h-3.52c-1.37-.7-.88-1.72,0-2.81l8.05-3.09c1.71,1.95,5.27,1.3,10.08-1.12"
          />
          <path
            id="_Pfad_shoulder_1"
            data-name="&amp;lt;Pfad&amp;gt;shoulder 1"
            class="stroke clear-fill"
            d="M49.16,2.09c2.76-.14,4.56,.94,5.39,3.23-2.05,.91-2.42,1.99-1.55,3.2"
          />
        </g>
      </g>
      <g id="sternum_overlay" data-name="sternum overlay">
        <path
          id="sternum_overlay_1_"
          data-name="sternum overlay (1)"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Chest.id, Chest.children.sternum),
            });
          }}
          class={`stroke zone ${isSelected(selected, Chest.children.sternum)}`}
          d="M78.52,15.78c1.96-.49,3.96-.41,6.19,2.25,2.37,8.71,7.58,15.43,15.38,20.34,3.15,9,7.07,17.07,11.53,24.47,1.06,6.5,5.27,14.35,9.09,20.06,1.14,5.46,2.82,10.37,5.34,14.44,1.83,3.75,1.65,7.32,.09,10.78-2.07-6.76-4.28-12.27-5.53-11.53-.63-9.65-3.42-11.72-5.25-9.19,.14-3.22-1.31-5.37-3.47-7.03,7.85-5.58,.13-11.58-3.19-8.34-1.98-1.31-3.4-5.1-4.59-9.84,3.74-2.7,1.62-6.54-3-7.31-2.75-4.74-5.5-8.04-8.25-9.47,1.53-2.99,0-5.86-4.22-8.62-3.1-3.69-6.04-6.95-8.53-9,1.78-2.81,.92-5.14-1.78-7.12,1.35-.81,1.35-2.47,.19-4.88Z"
        />
      </g>
      <g id="collarbone_overlay" data-name="collarbone overlay">
        <path
          id="collarbone_overlay_1_"
          data-name="collarbone overlay (1)"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Chest.id, Chest.children.collarboneR),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.collarboneR
          )}`}
          d="M38.02,9.6c-3.82,2.26-2.65,3.62,1.12,4.5-.1-1.08,7.26-1.17,8.34-.56,1.16,.65,4.59-.19,4.59-.19,8.16,2.35,16.08,4.81,21.47,8.34,3.98-.69,6.23-1.97,4.5-4.59-4.61-2.35-9.78-4.35-15.66-5.91-9.58-2.15-18.71-3.8-24.38-1.59Z"
        />
      </g>
      <g id="upperribs_overlay" data-name="upperribs overlay">
        <path
          id="upperribs_overlay-2"
          data-name="upperribs overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Chest.id, Chest.children.upperRibsR),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.upperRibsR
          )}`}
          d="M54.42,22.44c-2.31-1.47-4.46-3.2-6.47-5.16-.6-2.66,.68-4.1,4.03-4.22,7.1,6.18,14.6,9.49,22.69,8.72l3.56-1.22c2.94,1.41,3.75,3.83,2.34,7.31l7.78,9c4.16,1.91,5.75,4.84,4.69,8.81,2.84,1.93,5.15,5.04,7.22,8.72l2.06,.28c3.11,1.27,4.56,3.28,2.81,6.75l-.94,.56c.84,3.75,2.06,7.01,3.84,9.56l1.97-1.12c2.45,.76,3.93,3.19,4.12,7.78l-2.16,2.16c1.95,2.07,3.25,4.35,3.09,7.12l1.03-.66c3.06,1.12,4.49,4.06,4.22,8.91,.89,5.69,.71,10.35-1.31,13.22,3.25,1.63,3.41,4.32,1.31,7.78,1.81-.42,3.03-1.74,3.38-4.41,1.59,3.99,.97,8.54-1.22,13.5,1.11,3.78,1.35,6.83,.09,8.62-4.77,.96-13.67-.93-22.69-2.91-17.41-2.17-42.49-13.36-67.5-24.47l-15-7.87c-2.46-4.36-1.9-6.83,.47-8.16l.56-5.91c.34-2.81,1.37-4.77,3.75-5.06l2.06-6.56c.52-2.54,1.61-4.51,3.66-5.53l5.25-6.28c1.03-2.42,2.69-4.3,5.34-5.34,2.32-3.2,5.04-5.45,8.34-6.38,1.8-1.08,3.17-2.53,3.19-5.16,1.3-3.09,1.62-6.18,1.03-9.28-.72-1.68-1-3.63-.75-5.91l-.09-1.78c7.06,.29,6.49-2.25,4.22-5.44Z"
        />
      </g>
      <g id="underribs_overlay" data-name="underribs overlay">
        <path
          id="underribs_overlay-2"
          data-name="underribs overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Chest.id, Chest.children.lowerRibsR),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.lowerRibsR
          )}`}
          d="M18.14,100.63c3.06,8.66,8.73,14.24,17.62,16.03,11.02,8.3,32.03,14.64,52.5,21.09,11.47,5.18,22.79,7.47,33.94,5.91,1.22,2.01,.74,4.13-1.41,6.38,2.24,1.12,4.3,.44,6.19-2.25,.24,5.64-3.83,8.92-8.62,11.81,.14,1.84-.58,3.69-2.25,5.53l6.38-1.97c-4.3,6.23-7.87,8.41-11.06,8.53,1.06,2.44,1.15,4.55-.47,6.09-9.96,2.53-28.5-5.69-46.41-13.12l-19.78-8.06c1.78,5.56,4.35,9.8,8.34,11.62,16.08,6.05,31.15,11.53,41.44,14.34,3.26,0,6.16,.89,8.72,2.62-2.3,1.5-5.79,1.59-9.75,1.12-5.85-.86-19.81-6.15-32.91-10.97,5.77,6.8,12.25,12.96,21.19,16.88,2.25,.1,4.23,1.49,6,3.94-10.52-.02-26.94-10.33-35.34-22.78-5.26-3.3-9.38-8.2-12.47-14.53-.41-1.51-.13-3.11,.84-4.78-3.49-2.43-6.72-4.81-9.56-7.12-2-5.03,.02-7.37,2.53-9.38-5.95-1.47-9.6-5.81-10.88-13.12,.23-2.23,.81-4.35,2.06-6.28-2.97-1.8-5.57-5.79-8.06-10.41-.23-4.08,.39-5.9,1.22-7.12Z"
        />
      </g>
      <g id="shoulder_plate_overlay" data-name="shoulder plate overlay">
        <path
          id="shoulder_plate_overlay_1_"
          data-name="shoulder plate overlay (1)"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Chest.id, Chest.children.shoulderBladeR),
            });
          }}
          class={`stroke zone ${isSelected(
            selected,
            Chest.children.shoulderBladeR
          )}`}
          d="M12.19,22.72c2.3-4.4,4.56-5.15,6.75-1.55,1.76,3.14,3.49,3.01,5.2,1.55,2.26-.52,4.13-.75,5.62-.7l2.95,2.11c2.84,.91,5.05,2.02,5.62,3.66,1.7-3.62,3.48-6.45,5.77-4.08,3.85,1.8,6.82,1.17,9.14-1.27,3.41,1.16,3.43,2.86,1.12,4.92l-4.36,1.41c-.8,4.11,.41,5.78,.84,7.31,.79,3.25,.15,5.91-1.12,8.3,.05,2.93-1.12,4.49-2.81,5.48-3.5,.81-6.08,3.15-8.16,6.33-2.92,.85-4.58,2.93-5.77,5.48-1.93,3.58-3.99,4.55-5.2,6.47-1.86,.46-2.86,2.5-3.52,5.2l-2.67,6.61c-2.85,1.4-3.12,3.39-2.95,5.48l-.84,5.06c-1.7,1.1-2.36,2.63-2.53,4.36-3.07,1.65-4.69,4.15-5.91,6.89-2.32,1.94-4.37,1.06-6.19-2.39-1.17-2.42-2.06-5.23-2.67-8.44,3.7-10.77,2.66-27.13,.56-44.72-.87-7.48,.7-13.16,5.34-16.59,2.87-1.21,4.83-3.46,5.77-6.89Z"
        />
      </g>
    </svg>
  );
}

export default RightView;
