import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const RibsLeft = map.RibsLeft;

function FrontView({ selected, bpartSetter }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.58 241.38">
      <g id="Himithorax_L" data-name="Himithorax L">
        <g id="rib_1_L" data-name="rib 1 L">
          <path
            id="rib_1_L-2"
            data-name="rib 1 L-2"
            class="lribs-cls-4"
            d="M36.84,35.37c1.57,.17,6.53-1.09,9.19,0,1.94,.79,1.92,3.79,2.06,4.78-.99,1.42-2,2.27-3.02,2.6-2.42-.35-5.1-.62-6.75,.07-2.69-2.53-2.62-4.74-1.48-7.45h0Z"
          />
          <path
            id="rib_1_L-3"
            data-name="rib 1 L-3"
            class="lribs-cls-4"
            d="M39.74,5.67c3.64-1.16,5.66-.79,7.04,.17,5.91,6.63,12.48,10.33,15.66,12.74,1.27,1.2,1.99,2.45,2.27,3.76-.9,1.31-6.19,1.34-11.57,1.35-1.12-5.11-4.05-8.41-8.79-9.88-1.81-1.71-4.24-2.27-7.5-1.33l-5.98,2.92c.37-2.9,.25-7.13,.53-5.84"
          />
          <path
            id="rib_1_L-4"
            data-name="rib 1 L-4"
            class="lribs-cls-4"
            d="M64.7,30.34s-4.18,8.93-8.6,10.45c-2.96,.28-5.71,.21-8.02-.64l-2.06-5.62c2.76-.8,4.72-1.81,4.75-3.29,4.83-.77,9.85-1.99,13.93-.89h0s0,0,0,0Z"
          />
          <path
            id="rib_1_L_4_"
            data-name="rib 1 L (4)"
            class="lribs-cls-4"
            d="M53.14,23.69c.46,2.23-.23,4.72-2.37,7.55"
          />
          <path
            id="rib_1_L_5_"
            data-name="rib 1 L (5)"
            class="lribs-cls-4"
            d="M64.61,21.76c1.23,2.83,1.24,5.69,.09,8.59"
          />
        </g>
        <g id="rib_2_L" data-name="rib 2 L">
          <path
            id="rib_2_L-2"
            data-name="rib 2 L-2"
            class="lribs-cls-4"
            d="M31.07,59.21c1.95-1.03,4.9-1.12,8.16-.91,2.93-.62,5.43-1.42,7.38-2.46,2.29,3.01,3.36,5.37,.77,8.02-4.94-.21-10.53,.88-15.26,2.04-1.6-1.03-1.97-3.24-1.05-6.68h0Z"
          />
          <path
            id="rib_2_L-3"
            data-name="rib 2 L-3"
            class="lribs-cls-4"
            d="M64.71,22.34c2.77,.57,7.11,3.66,11.51,6.84,3.77,1.63,5.91,5.88,7.13,11.61,.43,5.55-3.34,9.48-9.1,12.65-6.28,4.82-15.98,7.91-26.86,10.41,2.2-2.84,2.39-5.64,.4-8.4,11.31-2.96,22.83-5.87,25.88-10.94,1.2-2.6,2.03-5.15,2.4-7.61,.44-2.92,.22-5.73-.8-8.4"
          />
          <path
            id="rib_2_L_3_"
            data-name="rib 2 L (3)"
            class="lribs-cls-4"
            d="M69.3,30.48c-1.36-.91-2.88-1.59-4.59-1.97"
          />
          <path
            id="rib_2_L_4_"
            data-name="rib 2 L (4)"
            class="lribs-cls-4"
            d="M32.08,17.17c7.14-.13,13.84,1.32,20.16,4.22"
          />
          <path
            id="rib_2_L_5_"
            data-name="rib 2 L (5)"
            class="lribs-cls-4"
            d="M32.08,22.51c6.48,.15,14.27,1.16,20.91,3.75"
          />
        </g>
        <g id="rib_3_L" data-name="rib 3 L">
          <path
            id="rib_3_L-2"
            data-name="rib 3 L-2"
            class="lribs-cls-4"
            d="M87.26,53.44c-1.56-2.29-3.76-4.07-6.85-5.16"
          />
          <path
            id="rib_3_L-3"
            data-name="rib 3 L-3"
            class="lribs-cls-3"
            d="M57.02,40.79c2.76,.28,5.64-.23,8.72,1.29,2.12,1.19,4.34,2.1,6.65,2.78"
          />
          <path
            id="rib_3_L-4"
            data-name="rib 3 L-4"
            class="lribs-cls-4"
            d="M83.58,42.19c1.06,.71,4.8,8.22,3.18,12.94-3.25,6.13-5.34,8.73-6.89,9.7-3.87,2.63-8.36,4.71-13.36,6.33-4.83,2.27-10.01,3.84-15.68,4.5-.69,.1-1.23,.51-1.7,1.05,2.13,2.29,2.83,4.71,2.12,7.24,1.95,.67,4.21,.38,6.68-.49,6.31-1.22,12.06-2.67,15.82-4.92,5.55-2.98,10.45-6.39,13.99-10.69,2.29-2.97,4.28-6.04,4.71-9.7-.26-2.47-.46-5.28-1.34-7.47-2.2-5.31-4.74-6.53-7.54-8.5h0Z"
          />
          <path
            id="rib_3_L-5"
            data-name="rib 3 L-5"
            class="lribs-cls-4"
            d="M32.06,76.22c2.78,1.21,5.75,1.85,9,1.62,2.77,0,5.48-.65,8.69-1.71,.19,.72,.78,2.3,1.53,4.22,.26,1.25-.15,2.74-.8,4.31-2.62,.93-5.35,1.01-8.16,.42-4.1-1.18-7.45-1.5-10.27-1.2-1.91-1.81-2.03-4.32,0-7.66h.01Z"
          />
          <path
            id="rib_3_L-6"
            data-name="rib 3 L-6"
            class="lribs-cls-3"
            d="M68.85,30.34c3.59,1.46,5.26,4.35,6.42,8.16-3.69-1.74-7.88-3.23-12.84-4.32"
          />
        </g>
        <g id="rib_4_L" data-name="rib 4 L">
          <path
            id="rib_4_L-2"
            data-name="rib 4 L-2"
            class="lribs-cls-3"
            d="M33.61,48.28c7.43-.85,14.35-.9,20.45,.31,3.94,.29,7.39,.81,10.64,1.43"
          />
          <path
            id="rib_4_L-3"
            data-name="rib 4 L-3"
            class="lribs-cls-3"
            d="M30.93,55.45c4.77-1.36,9.47-2.09,14.06-2.01,2.15,.27,3.66,.61,4.76,1"
          />
          <path
            id="rib_4_L-4"
            data-name="rib 4 L-4"
            class="lribs-cls-3"
            d="M76.22,52.53c3.8,1,6.94,2.55,9.42,4.67"
          />
          <path
            id="rib_4_L-5"
            data-name="rib 4 L-5"
            class="lribs-cls-3"
            d="M66.12,57.73c5.74,1.1,10.82,3.03,15.09,5.97"
          />
          <path
            id="rib_4_L-6"
            data-name="rib 4 L-6"
            class="lribs-cls-4"
            d="M88.64,66.66c2.92,1.17,5.43,3.35,7.68,6.18"
          />
          <path
            id="rib_4_L-7"
            data-name="rib 4 L-7"
            class="lribs-cls-4"
            d="M91.84,60.86c2.63,1.65,4.83,3.48,5.75,5.8,.42,2.45,.08,4.43-1.27,5.76"
          />
          <path
            id="rib_4_L-8"
            data-name="rib 4 L-8"
            class="lribs-cls-4"
            d="M95.55,63.7c2.13,2.71,4.36,4.95,5.2,8.72,.9,3.14,.53,6.79-.98,10.9-4.57,6.83-10.56,12.02-17.51,16.1-5.47,3.43-10.81,6.3-15.89,8.16-3.55,.74-6.99,1.03-10.27,.77,1.36-2.14,2.02-4.5,0-7.73,.62-1.19,1.38-1.71,2.25-1.69,7.35-.3,14.13-3.28,18.77-5.77,5.9-3.56,11.18-7.14,14.34-10.76,3.1-3.46,4.7-7,5.46-10.75"
          />
          <path
            id="rib_4_L-9"
            data-name="rib 4 L-9"
            class="lribs-cls-4"
            d="M56.11,100.62c-5.55,.24-18-2.78-22.4-3.9-.99-.25-1.58-.41-1.58-.41,0,0-2.32,4.53,.91,7.03,8.26,3.5,16.06,5.51,23.07,5.02,1.65-2.91,1.72-5.5,0-7.73h0Z"
          />
        </g>
        <g id="rib_5_L" data-name="rib 5 L">
          <path
            id="rib_5_L-2"
            data-name="rib 5 L-2"
            class="lribs-cls-3"
            d="M31.92,67.43c5.03-1.1,9.77-1.5,14.2-1.12,6.83,1.31,13.27,2.29,18.58,2.32,2.3,.35,3.28,1.52,4.93,1.43l-11.48,4.19c-2.44-1.48-5.79-2.41-10.13-2.74-2.73-.19-5.49-.09-8.28,.28-3.38,.35-6.37,1.26-8.8,2.95-.48-.92-.69-1.95,.35-3.52-.74-1.81-.28-2.93,.63-3.8h0Z"
          />
          <path
            id="rib_5_L-3"
            data-name="rib 5 L-3"
            class="lribs-cls-3"
            d="M83.35,72.24c4.2,1.06,8.02,2.88,11.45,5.45"
          />
          <path
            id="rib_5_L-4"
            data-name="rib 5 L-4"
            class="lribs-cls-3"
            d="M74.25,78.27c6.1,1.64,11.21,3.83,14.83,6.88"
          />
          <path
            id="rib_5_L-5"
            data-name="rib 5 L-5"
            class="lribs-cls-4"
            d="M99.77,83.32c3.27,2.87,5.4,5.54,5.32,7.86-.8,2.04-1.7,4.34-2.66,6.81-.89-3.93-3.55-6.66-7.63-8.42l4.97-6.24h0Z"
          />
          <path
            id="rib_5_L-6"
            data-name="rib 5 L-6"
            class="lribs-cls-4"
            d="M65.15,133.03c13.99-1.8,29.75-12.11,39.94-26.68,2.36-6.4,1.83-11.72-.74-16.26l-3.11,10.15c-6.39,11.06-21.08,18.64-37.21,25.62,1.66,2.16,2.28,4.51,1.12,7.17h0Z"
          />
        </g>
        <g id="rib_6_L" data-name="rib 6 L">
          <path
            id="rib_6_L-2"
            data-name="rib 6 L-2"
            class="lribs-cls-4"
            d="M72.46,141.68c14.6-3.22,27.85-9.3,36.38-25.5,1.15,.95,1.35,4.61,0,12.75-5.85,13.71-21.49,17.27-35.62,22.4,2.94-3.28,2.83-6.5-.75-9.65h-.01Z"
          />
          <path
            id="rib_6_L-3"
            data-name="rib 6 L-3"
            class="lribs-cls-3"
            d="M31.92,92.37c11.72-3.49,23.93-4.19,37.71,4.19"
          />
          <path
            id="rib_6_L-4"
            data-name="rib 6 L-4"
            class="lribs-cls-3"
            d="M57.02,84.32c7.53,1.23,15.06,3.67,22.59,7.32"
          />
          <path
            id="rib_6_L-5"
            data-name="rib 6 L-5"
            class="lribs-cls-3"
            d="M87.26,95.18c2.96,1.03,6.94,3.75,11.92,8.16"
          />
          <path
            id="rib_6_L-6"
            data-name="rib 6 L-6"
            class="lribs-cls-3"
            d="M79.61,101.05c6.88,2.41,11.8,5,14.36,7.82"
          />
          <path
            id="rib_6_L-7"
            data-name="rib 6 L-7"
            class="lribs-cls-4"
            d="M104.18,107.63c1.66,1.18,3.21,4.02,4.66,8.56"
          />
          <path
            id="rib_6_L-8"
            data-name="rib 6 L-8"
            class="lribs-cls-4"
            d="M99.17,113.69c3.7,1.71,5.68,4.57,5.92,8.58"
          />
        </g>
        <g id="rib_7_L" data-name="rib 7 L">
          <path
            id="rib_7_L-2"
            data-name="rib 7 L-2"
            class="lribs-cls-3"
            d="M32.37,109.34c15.74-.32,31.8,2.92,45.46,10.06"
          />
          <path
            id="rib_7_L-3"
            data-name="rib 7 L-3"
            class="lribs-cls-3"
            d="M68.31,106.83c6.34,1.07,12.5,3.59,18.47,7.69"
          />
          <path
            id="rib_7_L-4"
            data-name="rib 7 L-4"
            class="lribs-cls-4"
            d="M94.8,118.03c3.72,1.43,6.42,3.72,8.63,6.42"
          />
          <path
            id="rib_7_L-5"
            data-name="rib 7 L-5"
            class="lribs-cls-4"
            d="M78.67,163.81c12.95-4.21,23.83-11.41,31.11-23.81-.02-4.92,.17-7.42-.94-11.06,2.33,7.28,4.68,14.58,2.62,19.46-7.17,14.83-18.11,22.01-31.5,24.23,1.29-3.12,.57-6.03-1.29-8.81h0Z"
          />
          <line
            id="rib_7_L-6"
            data-name="rib 7 L-6"
            class="lribs-cls-4"
            x1="103.58"
            y1="136.72"
            x2="107.61"
            y2="143.39"
          />
        </g>
        <g id="rib_8_L" data-name="rib 8 L">
          <path
            id="rib_8_L-2"
            data-name="rib 8 L-2"
            class="lribs-cls-3"
            d="M49.14,120.68c7.32,.86,13.36,2.11,18.11,3.77"
          />
          <path
            id="rib_8_L-3"
            data-name="rib 8 L-3"
            class="lribs-cls-4"
            d="M87.26,185.9c11.19-7.21,22.43-14.39,24.96-28.21,1.55,5.55,.9,9.99-1.22,17.5-9.99,14.51-15.83,16.94-21.92,17.5,.93-2.12,.51-4.36-1.82-6.79h0Z"
          />
          <path
            id="rib_8_L-4"
            data-name="rib 8 L-4"
            class="lribs-cls-3"
            d="M78.67,128.93c4.56,1.82,8.32,4.03,10.67,6.94"
          />
          <path
            id="rib_8_L-5"
            data-name="rib 8 L-5"
            class="lribs-cls-3"
            d="M67.25,133.03c4.2,1.82,7.09,3.87,10.3,7.38"
          />
          <path
            id="rib_8_L-6"
            data-name="rib 8 L-6"
            class="lribs-cls-3"
            d="M97.76,141.31c3.39,1.8,5.83,3.6,7.33,5.4"
          />
          <path
            id="rib_8_L-7"
            data-name="rib 8 L-7"
            class="lribs-cls-3"
            d="M88.09,146.03c4.65,2.5,8.76,5.05,9.67,7.86"
          />
          <path
            id="rib_8_L-8"
            data-name="rib 8 L-8"
            class="lribs-cls-4"
            d="M109.78,151.61c1.85,3.14,2.44,6.08,2.44,6.08"
          />
          <path
            id="rib_8_L-9"
            data-name="rib 8 L-9"
            class="lribs-cls-4"
            d="M105.09,158.5c1.99,1.85,3.36,4.42,4.12,7.71"
          />
          <path
            id="rib_8_L-10"
            data-name="rib 8 L-10"
            class="lribs-cls-3"
            d="M32.51,124.45c3.71-.36,7,.46,11.13,1.41"
          />
        </g>
        <g id="rib_9_L" data-name="rib 9 L">
          <path
            id="rib_9_L-2"
            data-name="rib 9 L-2"
            class="lribs-cls-3"
            d="M74.76,150.78c6.73,1.03,10.48,2.57,17.07,7.61"
          />
          <path
            id="rib_9_L-3"
            data-name="rib 9 L-3"
            class="lribs-cls-3"
            d="M64.03,153.58c8.35,3.22,15.91,6.39,17.63,9.19"
          />
          <path
            id="rib_9_L-4"
            data-name="rib 9 L-4"
            class="lribs-cls-3"
            d="M92.48,168.79c4.42,2.49,7.03,4.99,7.83,7.49"
          />
          <path
            id="rib_9_L-5"
            data-name="rib 9 L-5"
            class="lribs-cls-3"
            d="M99.95,163.73c2.12,1.72,4.12,3.85,5.65,7.73"
          />
          <path
            id="rib_9_L-6"
            data-name="rib 9 L-6"
            class="lribs-cls-3"
            d="M36.31,140.06c3.31-.58,4.93-.21,6.19,.36"
          />
          <path
            id="rib_9_L-7"
            data-name="rib 9 L-7"
            class="lribs-cls-3"
            d="M44.78,147.44c1.14,0,2.75,.35,3.88,.35"
          />
          <path
            id="rib_9_L-8"
            data-name="rib 9 L-8"
            class="lribs-cls-4"
            d="M94.8,200.88c8.78-3.1,10.93-9.67,10.29-17.71l2.52-3.32c1.89,7,2.64,13.86-.46,20.24-1.27,4.93-5.49,7.9-10.69,10.22,2.21-3.26,1.88-6.41-1.67-9.43h.01Z"
          />
        </g>
        <g id="rib_10_L" data-name="rib 10 L">
          <path
            id="rib_10_L-2"
            data-name="rib 10 L-2"
            class="lribs-cls-3"
            d="M32.51,154.65c3.81-.37,8.46,2.03,8.46,2.03,0,0,4.29,4.66,5.64,7.5-4.4-1.83-9.07-2.47-14.1-1.42-1.67-2-1.51-4.78,0-8.12h0Z"
          />
          <line
            id="rib_10_L-3"
            data-name="rib 10 L-3"
            class="lribs-cls-3"
            x1="77.83"
            y1="173.36"
            x2="91.46"
            y2="183.17"
          />
          <line
            id="rib_10_L-4"
            data-name="rib 10 L-4"
            class="lribs-cls-3"
            x1="68.38"
            y1="177.86"
            x2="84"
            y2="185.37"
          />
          <path
            id="rib_10_L-5"
            data-name="rib 10 L-5"
            class="lribs-cls-3"
            d="M92.48,192.11c2.95,4.2,4.93,7.15,5.28,7.98"
          />
          <path
            id="rib_10_L-6"
            data-name="rib 10 L-6"
            class="lribs-cls-3"
            d="M98.82,188.98c1.97,2.64,3.37,4.74,3.95,6.09"
          />
          <path
            id="rib_10_L-7"
            data-name="rib 10 L-7"
            class="lribs-cls-4"
            d="M102.03,207.18c.07,4.5-.62,8.36-4.27,11.66,.2-1.06,.57,1.85,1.06,7.59,6.02-5.87,8.36-13.34,6.78-22.51"
          />
        </g>
        <g id="rib_11_L" data-name="rib 11 L">
          <path
            id="rib_11_L-2"
            data-name="rib 11 L-2"
            class="lribs-cls-3"
            d="M36.31,174.36c-2.89,2.94-3.47,5.87-1.72,8.8,11.33,1.84,21.58,6.69,30.75,14.54l-4.66-13.66c-9.46-1.36-17.28-5.01-24.37-9.68h0Z"
          />
          <path
            id="rib_11_L-3"
            data-name="rib 11 L-3"
            class="lribs-cls-3"
            d="M84,221.88c2.52,5.17,2.83,9.19,1.96,12.61,3.49-2.66,5.78-5.61,5.87-9.09l-7.84-3.52h0Z"
          />
          <path
            id="rib_11_L-4"
            data-name="rib 11 L-4"
            class="lribs-cls-3"
            d="M76.96,193.92c1.39,.66,2.87,2.29,4.7,7.57"
          />
          <path
            id="rib_L_11"
            data-name="rib L 11"
            class="lribs-cls-3"
            d="M87.09,211.5c2.58,2.36,3.97,4.8,4.03,7.34"
          />
        </g>
        <g id="rib_12_L" data-name="rib 12 L">
          <path
            id="rib_12_L-2"
            data-name="rib 12 L"
            class="lribs-cls-3"
            d="M33.54,191.37c10.51,2.98,19,9.52,26.17,18.38,6.89,6.16,12.79,11.67,15.05,14.81,2.7,7.78,1.24,11.9-1.17,15.19,.32-7.08-5.78-15.86-14.44-25.31-7.07-6.75-14.98-12.01-23.81-15.63l-1.8-7.43h0Z"
          />
        </g>
        <g id="sternum">
          <path
            id="sternum_16"
            data-name="sternum 16"
            class="lribs-cls-7"
            d="M30.93,30.45c.14,2.88,2.46,3.88,5.91,4.92"
          />
          <path
            id="sternum_15"
            data-name="sternum 15"
            class="lribs-cls-6"
            d="M37.6,42.08c-1.75,3.59-3.35,5.79-4.84,7-1.48,3.22-1.79,8.03-2.74,8.48,.78,.28,.72,1.7,.56,3.3"
          />
          <path
            id="sternum_14"
            data-name="sternum 14"
            class="lribs-cls-4"
            d="M15.04,56.75l-.37-1.04c-.39-2.47-.96-4.16-1.74-4.87-1.29-2.63-3.06-4.75-5.34-6.33-1.44-1.14-2.31-1.1-2.18-1.69"
          />
          <path
            id="sternum_13"
            data-name="sternum 13"
            class="lribs-cls-4"
            d="M30.58,56.82c-1.67,1.31-4.78,1.76-8.54,1.83-4.04,.21-6.91-.47-7.37-2.94"
          />
          <path
            id="sternum_12"
            data-name="sternum 12"
            class="lribs-cls-4"
            d="M10.22,78.19c4.45,.53,5.08-6.39,2.41-7.73,5.07-2.13,3.43-4.71,1.8-6.82,1.99-1.85-.2-4.23-.81-5.91l1.79-.54"
          />
          <path
            id="sternum_10"
            data-name="sternum 10"
            class="lribs-cls-4"
            d="M10.22,86.07c1.43,1.67,1.25,3.38-.57,5.12,.85,1.85,.93,3.59-.57,5.12"
          />
          <path
            id="sternum_9"
            data-name="sternum 9"
            class="lribs-cls-4"
            d="M10.08,104.93c2.4,4.86-1.04,5.59,.14,7.88"
          />
          <path
            class="lribs-cls-4"
            d="M26.9,135.87c-.94,0-2.33,1.67-3.37,.94-1.56-.31-3,0-4.69-.94-.41-2.15-2.51-2.86-3.92-3-.26-.05-.75,.12-1.51-1.12-1.75-.55-2.7-1.71-2.26-3.95,2.16-3.31,.57-5.46-.93-7.12,1.85-2.74,1.49-5.57-.27-8.45"
          />
          <path
            id="sternum_5"
            data-name="sternum 5"
            class="lribs-cls-4"
            d="M33.61,83.32c-3.4,5.47-2.74,9.75,.09,13.39"
          />
          <path
            id="sternum_4"
            data-name="sternum 4"
            class="lribs-cls-5"
            d="M34.59,103.34c-2.81,4.66-2.52,7.65-.78,9.28"
          />
          <path
            id="sternum_3"
            data-name="sternum 3"
            class="lribs-cls-4"
            d="M20.53,136.99c-4.58,6.37-5.81,13.25-5.06,20.42,1.4,5.71,3.63,8.51,6.56,8.8,2.41-.82,4.49-2.09,4.87-5.78,1.92-7.23,2.68-7.14,3.64-7.78l-1.95-9.26-3.02-6.96-5.05,.56h.01Z"
          />
          <line
            id="sternum_2"
            data-name="sternum 2"
            class="lribs-cls-4"
            x1="31.31"
            y1="131.87"
            x2="30.02"
            y2="132.87"
          />
          <path
            id="sternum_1"
            data-name="sternum 1"
            class="lribs-cls-4"
            d="M31.19,127.07c2.66-2.23,3.05-5.89,1.32-9.09"
          />
          <path
            id="sternum_17_"
            data-name="sternum (17)"
            class="lribs-cls-4"
            d="M5.17,43.14c2.92-1.31,3.12-6.13,.75-6.94-1.18-.4,6.06-.7,6.87-6.15"
          />
          <path
            id="sterum_18_"
            data-name="sterum (18)"
            class="lribs-cls-4"
            d="M10.33,78.57c2.08,2.7,2.11,5.42,.19,8.16"
          />
          <path
            id="sternum_19_"
            data-name="sternum (19)"
            class="lribs-cls-4"
            d="M9.3,95.92c1.78,3.71,2.43,7.03,.56,9.47"
          />
        </g>
        <g id="thoracic_vertebrae" data-name="thoracic vertebrae">
          <path
            id="thoracic_vertebrae_12"
            data-name="thoracic vertebrae 12"
            class="lribs-cls-3"
            d="M4.1,3.49c.99-.07,2.44-.02,2.69-.45,.67-1.15,5.04-1.85,3.98-1.61,1.79-.22,3.04,.73,3.99,2.78,.4,.87,.03,.95-.98,.84-1.09-.24-1.81,.17-1.93,1.65,.91,.69,1.11,1.55,.08,2.7-.53-.31-.86-.76-.96-1.4H6.89c-2.44-.22-4.54-1.05-6.05-2.95-.59-.72-.48-1.28,.84-1.55,0,0,1.22,.07,2.42,0h0Z"
          />
          <path
            id="thoracic_vertebrae_11"
            data-name="thoracic vertebrae 11"
            class="lribs-cls-3"
            d="M37.81,2.84c-.83-.08-2.04-.03-2.25-.5-.56-1.28-4.21-2.05-3.32-1.79-1.5-.25-2.54,.81-3.34,3.09-.34,.97-.02,1.06,.82,.94,.91-.27,1.51,.19,1.62,1.84-.76,.77-.93,1.72-.07,2.99,.44-.34,.72-.85,.8-1.55h3.41c2.04-.25,3.79-1.16,5.05-3.28,.49-.8,.4-1.42-.71-1.72,0,0-1.02,.08-2.02-.02h.01Z"
          />
          <path
            id="thoracic_vertebrae_10"
            data-name="thoracic vertebrae 10"
            class="lribs-cls-3"
            d="M14.57,5.12c2.14,.63,4.46,.86,6.98,.64,2.67-.18,4.85-.59,6.38-1.14l.84-.28"
          />
          <path
            id="thoracic_vertebrae_9"
            data-name="thoracic vertebrae 9"
            class="lribs-cls-3"
            d="M12.79,16.53v2.67l-.84,4.08c1.32,2.3,2.09,4.58,.84,6.77,2.11-.61,4.58,.33,7.17,1.79,2.75,.26,5.12-.55,7.03-2.66,2.5-.42,3.66,.11,3.94,1.27l.98-1.27v-5.9c.51-2.9,.11-4.61-1.55-4.7,1.01-.41,1.57-1.08,1.55-2.06-.41-.91-.89-1.1-1.41-.83-2.19,1.52-5.2,2.28-8.84,2.48-3.15-.22-6.15-.61-8.88-1.65h.01Z"
          />
          <path
            id="thoracic_vertebrae_8"
            data-name="thoracic vertebrae 8"
            class="lribs-cls-3"
            d="M14.92,153.58l-4.79,1.07c1.83,3.34,1.66,7.3,.52,11.56,3.03-1.17,5.75-1.42,8.19-.82"
          />
          <path
            id="thoracic_vertebrae_7"
            data-name="thoracic vertebrae 7"
            class="lribs-cls-3"
            d="M25.42,164.18c2.58-.97,4.67-.29,6.5,1.2-.97-4.78-.84-8.39,.59-10.74l-1.97-2"
          />
          <path
            id="thoracic_vertebrae_6"
            data-name="thoracic vertebrae 6"
            class="lribs-cls-3"
            d="M20.52,136.99c-1.35,1.56-10.71,.47-9.91,.55,2.61,.26,.84,13.09-.39,13.24-1.26,1.85-1.29,2.79-.09,2.8,1.9-.72,3.59-1.37,5.13-1.4"
          />
          <path
            id="thoracic_vertebrae_5"
            data-name="thoracic vertebrae 5"
            class="lribs-cls-3"
            d="M30.58,151.61h1.93c.96-.84,1.79-1.82,0-4.73"
          />
          <path
            id="thoracic_vertebrae_4"
            data-name="thoracic vertebrae 4"
            class="lribs-cls-3"
            d="M12.7,166c.67,.53,1.25,1.16,0,3.48,7.13-.24,14.8-.38,17.89-.99-1.39-.86-.93-2.07,0-3.36"
          />
          <path
            id="thoracic_vertebrae_3"
            data-name="thoracic vertebrae 3"
            class="lribs-cls-3"
            d="M9.65,171.31c-3.1,2.85-.22,6.55,1.07,11.86h1.97c6.15-.88,12.81-.77,19.81,0,1.78-5.21,1.14-9.69,0-13.69-9.34-.53-18.16-.72-22.86,1.83h0Z"
          />
          <path
            id="thoracic_vertebrae_2"
            data-name="thoracic vertebrae 2"
            class="lribs-cls-3"
            d="M12.72,186.04c1.21-.96,1.2-1.91-.03-2.87H29.71c-1,.96-1,1.91,0,2.87H12.72Z"
          />
          <path
            id="thoracic_vertebrae_1"
            data-name="thoracic vertebrae 1"
            class="lribs-cls-3"
            d="M9.66,188.18c.98,4.02,1.36,8.3,0,13.31,8.23-.51,16.79-1.19,21.89,0,2.66,.9,4.02,.13,3.79-2.7-1.84-1.09-2.19-3.85-1.8-7.43,1.11-1.95,1.31-3.81-1.62-5.33-9.42,1.21-19.67-1.56-22.26,2.15Z"
          />
          <path
            id="thoracic_vertebrae_13"
            data-name="thoracic vertebrae 13"
            class="lribs-cls-4"
            d="M11.93,9.4c.84,2.7,1.15,5.06,.86,7.13"
          />
        </g>
        <path
          id="costal_cartilage"
          data-name="costal cartilage"
          class="lribs-cls-4"
          d="M33.04,112.53c-1.47,2.58-1.12,5.31,.57,8.15,.6,.16,12.31,5.28,14.83,9.38,1.33,2.49,.63,3.36-1.83,2.81-3.94-.19-10.25-3.68-13.06-5.35-.89-.53-1.43-.88-1.43-.88,0,0-2.16,2.6-2.11,4.77,8.17,2.48,15.27,11.9,22.38,21.24-9.05-5.59-16.56-12.15-22.38-19.78-1.24-.4-1.98,.35-2.37,1.9-1.31,2.89-1.01,5.76,.94,8.61,8.33,7.36,16.38,14.66,18.02,20.8,9.12,8.47,15.31,19.7,18.73,33.53,7.15,16.87,17.43,26.66,31.12,28.72,3.5-.51,2.75-3.36,0-7.31-4.11,.7-7.4-.42-9.75-3.56-4.36-3.76-4.77-5.4,.38-4.06,3.4,.39,6.58,.16,9.38-1.19,3.42-4.29-.4-9.4-1.67-9.43,0,0-9.94,2.48-16.13,0-3.97-6.1-4.15-7.99-1.71-6.95,4.18,.41,8.22,.06,12.11-1.24,.91-3.57,0-6-2.73-7.32-5.64,.79-11.33-1.94-17.06-6.59-5.01-4.99-4.5-6.66,1.52-4.99,3.45,.39,6.5,0,9.17-1.16,1.51-2.66,.45-5.69-1.29-8.81-5.37,1.51-10.97,.14-16.61-2-4.47-3.07-4.52-5.73,.66-7.91l10.5-2.57c3.36-4.42,2.25-7.35-.75-9.65-4.49,2.1-8.65,1.35-12.56-1.5-3.58-1.99-4.51-3.85,.19-5.41l5.06-1.74c1.13-2.12,.73-4.51-1.12-7.17-5.06,1.69-10.01-.67-14.89-5.18-6.8-4.57-12.71-7.99-16.1-8.15h-.01Z"
        />
      </g>
      <g id="UpperRibs_Overlay_L" data-name="UpperRibs Overlay L">
        <path
          id="UpperRibs_Overlay_L_1_"
          data-name="UpperRibs Overlay L (1)"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, RibsLeft.id, RibsLeft.children.upperRibs),
            });
          }}
          class={`lribs-cls-1 ${isSelected(
            selected,
            RibsLeft.children.upperRibs
          )}`}
          d="M39.39,6.39c3.62-2.12,6.75-1.5,9.38,1.88,4.27,4.02,8.77,7.6,13.68,10.32,1.98,3.82,5.22,6.71,10.32,8.24,5.19,1.8,9.03,7.33,11.81,15.75,4.44,2.86,7.05,7.76,7.88,15.58-.45,3.74,1.89,6.31,5.14,8.51,2.66,2.73,3.97,7.25,2.36,15.66,6.77,7.04,6.7,15.39,5.14,24.03,3.59,8.07,4.66,15.56,3.75,22.59,1.9,5.51,3.26,11.24,2.92,17.7-6.66,17.07-18.85,21.65-31.12,26.06-4.81,1.51-9.77,1.4-14.81,.56l-19.31-9.75c-7.76-11.48-16.53-19.7-20.06-25.88l3.56-6.23c-.14-2.35,.89-4.1,2.81-5.4,1.13-1.42-.04-5.89,.22-13.47-.96-1.82-.8-5.46-.22-9.78-1.66-1.12-1.68-3.42-.69-6.45-1.14-3.97-.99-8.38-.06-13.05-2.05-1.89-1.45-4.37-.01-7.04-.96-2.98-1.03-6.63-.17-10.96-1.52-1.79-1.43-4.02-.19-6.56-1.07-3.93,.07-7.52,3.94-10.69l2.68-5.19c-2.42-2.19-3.12-4.67-.24-7.75-2.95-.77-5.2-2.86-6.19-7.31l-.75-12.94,1.88-7.12,6.38-1.31Z"
        />
      </g>
      <g id="UnderRibs_overlay_L" data-name="UnderRibs overlay L">
        <path
          id="UnderRibs_overlay_L_1_"
          data-name="UnderRibs overlay L (1)"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, RibsLeft.id, RibsLeft.children.lowerRibs),
            });
          }}
          class={`lribs-cls-2 ${isSelected(
            selected,
            RibsLeft.children.lowerRibs
          )}`}
          d="M47.26,164.26l18.56,9c4.78,1.01,9.53,1.36,14.14-.63,18.6-3.31,27.19-13.31,31.8-25.99,1.59,6.99,3.23,17.93-.76,28.55l-3.17,5.57c4.01,7.44,2.37,14.96-2.06,22.5,.57,10.71-1.72,18.46-6.94,23.17l-5.24-.48c-2.32,4.43-4.84,7.38-7.61,8.54,1.06-4.22,.39-8.42-1.96-12.61-7.77-4.98-13.52-13.99-18.67-24.17-8.44-7.5-19.08-11.76-30.74-14.55l-3.64-.34c-2.33,.67-2.33,1.67,0,3,2.46,.63,3.76,2.14,2.59,5.54,9.99,4.43,20.17,8.59,26.17,18.39,5.81,3.99,10.52,9.73,15.12,15.64,2.37,6.34,1.34,10.78-1.24,14.36-5.32-23.05-21.54-32.32-38.25-40.96-2.92-1.9-2.28-5.93-1.01-10.34l-4.5-3.37v-2.63c2.09,1.19,3.48-.44,4.12-5.06l-1.45-7.91-2.49-1.09c-.89-1.24-.73-2.55,.38-3.94l2.06,1.69,.06-3.38c-2.34-2.79-1.34-5.46,0-8.12,1.66-1.15,4.59-.36,8,1.18l6.75,8.44Z"
        />
      </g>
    </svg>
  );
}

export default FrontView;
