import { useStudyDose } from "../../../Utilities/FetchHooks/Studies/StudiesHooks";

export default function ExamDoseCol({ studyId }) {
  const dose = useStudyDose({ studyId: studyId });
  return (
    <div className="p-1 break-word">
      {dose.status === "loading" ? (
        <>loading...</>
      ) : dose?.data?.ugy ? (
        `${dose?.data?.ugy} uGy`
      ) : (
        "No Info"
      )}
    </div>
  );
}
