import style from "./style.scss";

function Brustwirbelkorper6VdLiegend1517(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.22 170.9">

<g id="BG">
  <path id="BG_white" data-name="BG white" class="Brustwirbelsaule6_ap_1517_cls4" d="M8.28,90.58c24.22-14,48.61-20.49,73.23-16.72,1.41,1.26,3.91,2.45,8.34,3.5,3.57.57,8.23,9.01,11.29,10.1,1.9,1.35,3.4,3.44,3.75,7.68.83.65,2.52-1.47,4.52-4.56l-4.51,10.46c.89,4.35,3.92,6.64,7.54,8.36,1.82.62,2.68,1.87,2.02,4.1l-.45,4.88c-4.97-.94-7.48,1.72-8.44,6.62-.39-2.76-2.16-3.22-4.43-2.88l-.36-3.59C72.72,105.68,30.24,108.6.07,126.73c20.39-1.57,41.68,2.16,63.54,9.2,1.5,4.3,5.45,6.63,11.2,7.51l16.75,2.31c6.94.17,10.85,2.4,12.83,5.96,2.09,1.71,3.65.36,5-2.18l-2.96,6.89c-2.32,2.13-3.23,4.85-1.51,8.67,3.68,4.55,7.66,6.12,11.84,5.68l11.88-1.95c9.78-2.75,19.96-4.14,30.56-4.22l30.45,1.95c4.58-.44,4.85-4.58,3.22-10.35l-5.05-9.06c.46.65.95,1.2,1.48,1.64,1.96,1.64,4.83,1.39,6.69-.36,1.97-1.86,3.98-2.92,6.03-3.04,2.3-.84,4.97-.4,7.91.94.71.33,1.49.49,2.28.51,4.9.09,8.23-2.78,10.85-6.99,1.8-4.54.45-8.38-2.4-11.91,13.66-6.11,40.1-5.22,66.98-4.1-5.69-6.43-12.42-6.48-19.04-7.78-16.94-3-37.94-3.17-60.46-2.27-6.27-.85-12.15.84-17.82,3.88-1.91-1.25-2.6-3.56-2.21-6.79,5.17-1.3,3.9-9.01.45-18.9,1.36-.04,2.67-.12,3.29-.74,3.93-1.95,7.42-2.37,10.51-1.38,14.5.78,22.56-4.68,27.4-13.24l31.21,2.29c11.46.34,23.91.88,26.67,3.91l4.56-17.16c-23.51-6.31-47.95-11.05-73.15-14.53l-14.5-1.57c-9.03,1.32-14.88,4.11-17.59,13.56l-5.16,1.23c.71-2.95.21-5.74-2.15-8.31-.68-2.9.1-4.09,1.45-4.62,4.78.26,8.38-1.05,9.57-5.51.84-6.31-2.11-12.76-5.91-19.24-1.5-4.01.35-6.86,3.8-10.51,2.74-5.78,2.86-10.82,1.11-14.5-.72-1.51-2.74-1.62-4.32-1.06-1.68.6-3.64,1.8-5.66,3.12-8.11,4.03-17.13,5.25-26.69,4.77l-28.35-1.41c-.97-.05-1.81.06-2.72-.27-3.9-.45-7.61-2.43-11.19-4.21-3.51-1.33-4.15.17-4.23,3.01l2.77,16.84c.1.62.16,1.24.13,1.86-.16,3.41-1.11,7.48-2.54,11.95-1.83,6.88-3.27,13.34-.11,14.68l4.18.91c.75.16,1.27.87,1.19,1.64l-.77,7.82-4.21-1.22c-2.33-4.35-6.52-6.69-11.74-8.46-3.36-1.14-6.43-1.96-10.31-2.27-10.27-.84-20.36.44-30.49,2.4-18.03,3.26-34.09,9.64-48.14,19.21l1.83,19.59-.02.03Z"/>
</g>
<g id="BWK6ap">
  <g id="BWK6_Parts" data-name="BWK6 Parts">
    <path id="BWK6_Part26" data-name="BWK6 Part26" class="Brustwirbelsaule6_ap_1517_cls6" d="M105.34,49.57c-.89-1.45-.3-4.48.91-10.48,2.08-10.32,3.31-10.68,3.19-15.04-.1-3.63-.95-3.45-2.28-12.3-1.12-7.46-.67-8.56,0-9.11,2.05-1.72,6.14,1.56,12.76,3.65,3.89,1.23,4.17.63,19.14,1.37,16.42.81,18.91,1.66,25.97.46,4.11-.7,7.06-1.21,10.94-2.73,7.3-2.87,9.84-6.21,12.3-5.01,2.68,1.3,3.13,6.91,2.28,10.94-1.51,7.12-6.86,8.16-6.48,13.2.22,2.95,2.07,2.74,4.65,9.58,1.4,3.7,1.71,6.02,1.82,7.29.25,2.79.54,6.15-1.37,8.2-1.76,1.89-4.62,1.84-6.38,1.82-7-.05-13.96,1.32-20.96,1.37-10.38.08-8.37.43-18.23.46-4.22,0-9.1-.14-18.68-.46-14.95-.49-18.1-.75-19.59-3.19v-.02h0Z"/>
    <path id="BWK6_Part25" data-name="BWK6 Part25" class="Brustwirbelsaule6_ap_1517_cls5" d="M107.62,60.96c1.29-.79,2.6.2,8.66,2.28,0,0,4.04,1.39,9.11,2.73,5.88,1.56,7.49.51,19.14.91,9.63.34,8.74,1.07,15.04.91,4.11-.1,9.36-.23,15.95-1.82,2.47-.6,5.2-1.42,10.94-2.73,6.61-1.52,8.23-1.63,8.66-.91.63,1.06-1.97,2.94-5.47,8.2-2.95,4.43-4.42,6.64-4.56,9.11-.15,2.68,1.05,5.9,3.45,12.34.22.6.38,1,.66,1.78,1.11,3.16,2.04,5.78,2.28,8.2.16,1.59.62,6.21-1.82,8.2-2.15,1.76-5.05.27-10.48-.46-5.34-.71-6.13.25-14.13.46-8.82.23-8.61-.92-20.51-1.37-9.93-.37-14.89-.56-20.05,0-6.8.74-10.58,1.95-14.58-.46-.21-.12-4.89-3.03-5.01-7.29-.06-1.94.88-2.48,2.73-6.38,1.65-3.47,3.26-6.87,3.19-10.48-.06-3.43-1.57-3.66-3.19-8.66-1.51-4.68-2.89-12.81,0-14.58v.02h0Z"/>
    <path id="BWK6_Part24" data-name="BWK6 Part24" class="Brustwirbelsaule6_ap_1517_cls5" d="M196.94,89.34c3.24-.48,3.79.77,9.11.84.64,0,3.54.03,6.38-.51,1.65-.31,9.51-1.8,14.58-8.66,1.46-1.98,5.59-7.55,3.65-13.22-1.72-5.02-6.9-6.99-7.75-7.29-3.94-1.41-7.59-.5-11.39.46-3.87.97-4.52,1.94-9.3,2.98-4.16.91-4.19.29-6.19,1.12-4.01,1.65-6.18,5.07-8.05,8.01-1.79,2.81-2.73,4.3-2.88,6.58-.08,1.19.13,1.48,1.77,7.79.96,3.68,1.05,4.19,1.67,4.55,1.87,1.08,3.74-1.96,8.4-2.65h0Z"/>
    <path id="BWK6_Part23" data-name="BWK6 Part23" class="Brustwirbelsaule6_ap_1517_cls5" d="M84.38,57.77c-3.36,1.86-4.68,5.08-5.01,5.92-.51,1.29-1.57,3.99-.46,6.84.83,2.12,2.46,3.28,3.65,4.1,2.98,2.06,4.31,1.19,7.29,2.73,3.17,1.64,2.23,2.91,6.84,6.84,3.6,3.07,5.03,3.01,6.38,5.47,1.6,2.9.83,5.17,1.82,5.47,1.43.42,5.07-3.64,5.73-8.54.62-4.54-1.89-5.6-3.71-13.61-1.55-6.78-.29-8.38-2.47-11.12-2.42-3.04-6.18-3.84-9.57-4.56-3.46-.74-6.98-1.49-10.48.46,0,0-.01,0-.01,0Z"/>
    <path id="BWK6_Part22" data-name="BWK6 Part22" class="Brustwirbelsaule6_ap_1517_cls5" d="M173.24,76.45c2.65.2,4.95-4.42,5.92-6.38.61-1.22,4.98-10.01.91-13.67-2.22-2-6.5-2.05-9.11,0-1.98,1.55-2.43,3.85-2.73,5.47-1.18,6.38,1.65,14.32,5.01,14.58Z"/>
    <path id="BWK6_Part21" data-name="BWK6 Part21" class="Brustwirbelsaule6_ap_1517_cls6" d="M108.99,168.96c-.81-.5-4.45-2.74-5.01-6.38-.54-3.49,2.35-4.49,4.56-10.48.69-1.87,2.52-6.85,1.37-12.3-1.01-4.77-3.2-4.7-4.1-9.57-.65-3.55-.56-9.36,2.73-11.39.51-.32,1.62-.86,5.47-.46,5.07.53,7.97,1.97,10.48,2.73,4.97,1.51,5.87-.4,16.41-.91,6.51-.32,5.22.46,16.41.46,8.65,0,13.05-.03,16.41-1.37,1.87-.74,4.26-1.96,8.2-2.73,3.2-.63,5.88-1.11,7.75.46,2.12,1.79,1.86,5.11,1.82,5.47-.3,3.23-2.56,3.79-4.56,7.75-.38.75-2.33,4.76-1.82,9.57.31,2.9,1.42,4.97,3.65,9.11,2.35,4.38,3.17,4.52,4.1,7.29.54,1.61,2.22,6.62,0,9.11-1.44,1.62-4.17,1.4-9.57.91-5.01-.46-10.03-.9-15.04-1.37-6.7-.63-11.61-.18-18.68.46-8.01.72-12.32,1.11-18.23,2.73-4.5,1.24-8.47,2.79-14.58,2.73-3.3-.03-5.61-.51-7.75-1.82h-.02Z"/>
    <path id="BWK6_Part20" data-name="BWK6 Part20" class="Brustwirbelsaule6_ap_1517_cls6" d="M212.89,122.48c1.28.46,4.91,1.81,7.75,5.47,1.22,1.58,3.51,4.52,3.19,8.66-.29,3.84-2.65,6.3-3.19,6.84-2.47,2.45-5.4,2.96-6.84,3.19-5.21.83-6.49-1.84-10.94-1.37-5.85.62-6.87,5.57-10.48,5.01-4.34-.67-7.94-8.61-7.04-15.44.69-5.21,4.09-10.37,8.86-12.36,1.94-.81,4.33-.84,9.11-.91,4.5-.06,6.75-.1,9.57.91h0Z"/>
    <path id="BWK6_Part19" data-name="BWK6 Part19" class="Brustwirbelsaule6_ap_1517_cls6" d="M63.87,137.06c-1.1-3.48,1.5-7.84,4.56-10.03,2.9-2.07,4.49-.97,13.22-2.28,5.59-.84,6.91-1.58,13.22-2.28,7.05-.78,8.33-.17,9.11.46,2.28,1.8,1.07,4.17,3.12,10.98,1.68,5.57,3.09,6.01,3.17,9.53.09,4-1.62,8.75-4.01,9.11-1.97.3-2.55-2.67-6.38-5.01-2.66-1.63-4.16-1.28-13.22-2.28-5.95-.65-8.98-1-11.85-1.82-5.23-1.5-9.81-2.81-10.94-6.38Z"/>
    <path id="BWK6_Part18" data-name="BWK6 Part18" class="Brustwirbelsaule6_ap_1517_cls6" d="M121.75,140.71c2.4-.61,2.97-5.32,3.65-10.94.92-7.56,1.42-11.77-1.37-14.58-2.17-2.19-6.1-3.29-8.2-1.82-1.07.74-1.42,1.97-1.37,10.48.06,9.4.5,11.32,1.82,13.22.2.29,3.04,4.26,5.47,3.65h0Z"/>
    <path id="BWK6_Part17" data-name="BWK6 Part17" class="Brustwirbelsaule6_ap_1517_cls6" d="M174.61,138.43c2.49.65,5.13-1.53,6.38-3.19,3.37-4.47.9-10.72.46-11.85-1.44-3.65-5.14-9.03-8.2-8.2-2.91.79-3.44,7.41-3.65,10.03-.33,4.15.55,6.79.91,7.75.82,2.19,1.82,4.87,4.1,5.47h0Z"/>
    <path id="BWK6_Part16" data-name="BWK6 Part16" class="Brustwirbelsaule6_ap_1517_cls6" d="M292.18,65.67c-9.04-3.04-45.3-12.15-87.65-16.1"/>
    <path id="BWK6_Part15" data-name="BWK6 Part15" class="Brustwirbelsaule6_ap_1517_cls6" d="M187.97,73.07c-4.35-11.92,2.49-22.29,16.55-23.5"/>
    <path id="BWK6_Part14" data-name="BWK6 Part14" class="Brustwirbelsaule6_ap_1517_cls6" d="M287.62,82.83c0-3.43-25.52-3.21-57.88-6.21"/>
    <path id="BWK6_Part13" data-name="BWK6 Part13" class="Brustwirbelsaule6_ap_1517_cls6" d="M229.74,76.63h-25.21"/>
    <path id="BWK6_Part12" data-name="BWK6 Part12" class="Brustwirbelsaule6_ap_1517_cls6" d="M204.53,76.63c-4.67,3.48-12.37,5.68-18.94,5.99"/>
    <path id="BWK6_Part11" data-name="BWK6 Part11" class="Brustwirbelsaule6_ap_1517_cls6" d="M287.62,123.85c-1.1-7.19-38.11-12.44-79.5-10.04"/>
    <path id="BWK6_Part10" data-name="BWK6 Part10" class="Brustwirbelsaule6_ap_1517_cls6" d="M208.12,113.8c2.77.01-9.19-2.02-18.48,4.31"/>
    <path id="BWK6_Part9" data-name="BWK6 Part9" class="Brustwirbelsaule6_ap_1517_cls5" d="M150.04,112.7c5.53-26.73,6.56-52.02,10.22-52.92"/>
    <path id="BWK6_Part8" data-name="BWK6 Part8" class="Brustwirbelsaule6_ap_1517_cls5" d="M147.93,111c0-28.4-3.61-49.68-10.69-49.68"/>
    <path id="BWK6_Part7" data-name="BWK6 Part7" class="Brustwirbelsaule6_ap_1517_cls6" d="M150.04,45.92c0-18.64,1.6-33.72,3.57-33.72"/>
    <path id="BWK6_Part6" data-name="BWK6 Part6" class="Brustwirbelsaule6_ap_1517_cls6" d="M147.94,45.92c-5.51-25.05-6.59-37.17-10.7-33.72"/>
    <path id="BWK6_Part5" data-name="BWK6 Part5" class="Brustwirbelsaule6_ap_1517_cls6" d="M100.78,118.53C77.26,105.78,30.07,107.88.07,126.73"/>
    <path id="BWK6_Part4" data-name="BWK6 Part4" class="Brustwirbelsaule6_ap_1517_cls6" d="M100.78,118.53c.96,12.49-.16,17.26-1.41,15.26"/>
    <path id="BWK6_Part3" data-name="BWK6 Part3" class="Brustwirbelsaule6_ap_1517_cls6" d="M8.28,90.58c22.66-12.29,60.93-29.17,96.64-7.75"/>
    <path id="BWK6_Part2" data-name="BWK6 Part2" class="Brustwirbelsaule6_ap_1517_cls6" d="M6.45,70.99c16.81-15.15,88.44-34.7,101.17-10.03"/>
    <path id="BWK6_Part1" data-name="BWK6 Part1" class="Brustwirbelsaule6_ap_1517_cls5" d="M118.58,47.75c11.81,17.93-12.06,49.09-6.34,4.4"/>
  </g>
</g>
<g id="angrenzende_BWK6_Highlights" data-name="angrenzende BWK6 Highlights">
  <path id="angrenzende_BWK6_Highlight4" data-name="angrenzende BWK6 Highlight4" class="BWK1517Fill" style= {{
            fill: props.colors.BWK1517Fill,
            stroke: props.colors.BWK1517Fill,
            opacity:props.colors.BWK1517Fill,
          }} d="M105.34,49.56c-.89-1.45-.3-4.48.91-10.48,2.08-10.32,3.31-10.68,3.19-15.04-.1-3.63-.95-3.45-2.28-12.3-1.12-7.46-.67-8.56,0-9.11,2.05-1.72,6.14,1.56,12.76,3.65,3.89,1.23,4.17.63,19.14,1.37,16.42.81,18.91,1.66,25.97.46,4.11-.7,7.06-1.21,10.94-2.73,7.3-2.87,9.84-6.21,12.3-5.01,2.68,1.3,3.13,6.91,2.28,10.94-1.51,7.12-6.86,8.16-6.48,13.2.22,2.95,2.07,2.74,4.65,9.58,1.4,3.7,1.71,6.02,1.82,7.29.25,2.79.54,6.15-1.37,8.2-1.76,1.89-4.62,1.84-6.38,1.82-7-.05-13.96,1.32-20.96,1.37-10.38.08-8.37.43-18.23.46-4.22,0-9.1-.14-18.68-.46-14.95-.49-18.1-.75-19.59-3.19v-.02h0Z"/>
  <path id="angrenzende_BWK6_Highlight3" data-name="angrenzende BWK6 Highlight3" class="BWK1517Fill" style= {{
            fill: props.colors.BWK1517Fill,
            stroke: props.colors.BWK1517Fill,
            opacity:props.colors.BWK1517Fill,
          }} d="M108.99,168.95c-.81-.5-4.45-2.74-5.01-6.38-.54-3.49,2.35-4.49,4.56-10.48.69-1.87,2.52-6.85,1.37-12.3-1.01-4.77-3.2-4.7-4.1-9.57-.65-3.55-.56-9.36,2.73-11.39.51-.32,1.62-.86,5.47-.46,5.07.53,7.97,1.97,10.48,2.73,4.97,1.51,5.87-.4,16.41-.91,6.51-.32,5.22.46,16.41.46,8.65,0,13.05-.03,16.41-1.37,1.87-.74,4.26-1.96,8.2-2.73,3.2-.63,5.88-1.11,7.75.46,2.12,1.79,1.86,5.11,1.82,5.47-.3,3.23-2.56,3.79-4.56,7.75-.38.75-2.33,4.76-1.82,9.57.31,2.9,1.42,4.97,3.65,9.11,2.35,4.38,3.17,4.52,4.1,7.29.54,1.61,2.22,6.62,0,9.11-1.44,1.62-4.17,1.4-9.57.91-5.01-.46-10.03-.9-15.04-1.37-6.7-.63-11.61-.18-18.68.46-8.01.72-12.32,1.11-18.23,2.73-4.5,1.24-8.47,2.79-14.58,2.73-3.3-.03-5.61-.51-7.75-1.82h-.02Z"/>
  <path id="angrenzende_BWK6_Highlight2" data-name="angrenzende BWK6 Highlight2" class="BWK1517Fill" style= {{
            fill: props.colors.BWK1517Fill,
            stroke: props.colors.BWK1517Fill,
            opacity:props.colors.BWK1517Fill,
          }} d="M212.89,122.47c1.28.46,4.91,1.81,7.75,5.47,1.22,1.58,3.51,4.52,3.19,8.66-.29,3.84-2.65,6.3-3.19,6.84-2.47,2.45-5.4,2.96-6.84,3.19-5.21.83-6.49-1.84-10.94-1.37-5.85.62-6.87,5.57-10.48,5.01-4.34-.67-7.94-8.61-7.04-15.44.69-5.21,4.09-10.37,8.86-12.36,1.94-.81,4.33-.84,9.11-.91,4.5-.06,6.75-.1,9.57.91h0Z"/>
  <path id="angrenzende_BWK6_Highlight1" data-name="angrenzende BWK6 Highlight1" class="BWK1517Fill" style= {{
            fill: props.colors.BWK1517Fill,
            stroke: props.colors.BWK1517Fill,
            opacity:props.colors.BWK1517Fill,
          }} d="M63.87,137.05c-1.1-3.48,1.5-7.84,4.56-10.03,2.9-2.07,4.49-.97,13.22-2.28,5.59-.84,6.91-1.58,13.22-2.28,7.05-.78,8.33-.17,9.11.46,2.28,1.8,1.07,4.17,3.12,10.98,1.68,5.57,3.09,6.01,3.17,9.53.09,4-1.62,8.75-4.01,9.11-1.97.3-2.55-2.67-6.38-5.01-2.66-1.63-4.16-1.28-13.22-2.28-5.95-.65-8.98-1-11.85-1.82-5.23-1.5-9.81-2.81-10.94-6.38Z"/>
</g>
<g id="BWK6_Highlights" data-name="BWK6 Highlights">
  <path id="BWK6_Highlight3" data-name="BWK6 Highlight3" class="Wirbelkorper_Querfortsatze1517Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1517Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1517Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1517Fill,
          }} d="M107.97,61.32c1.29-.79,2.6.2,8.66,2.28,0,0,4.04,1.39,9.11,2.73,5.88,1.56,7.49.51,19.14.91,9.63.34,8.74,1.07,15.04.91,4.11-.1,9.36-.23,15.95-1.82,2.47-.6,5.2-1.42,10.94-2.73,6.61-1.52,8.23-1.63,8.66-.91.63,1.06-1.97,2.94-5.47,8.2-2.95,4.43-4.42,6.64-4.56,9.11-.15,2.68,1.05,5.9,3.45,12.34.22.6.38,1,.66,1.78,1.11,3.16,2.04,5.78,2.28,8.2.16,1.59.62,6.21-1.82,8.2-2.15,1.76-5.05.27-10.48-.46-5.34-.71-6.13.25-14.13.46-8.82.23-8.61-.92-20.51-1.37-9.93-.37-14.89-.56-20.05,0-6.8.74-10.58,1.95-14.58-.46-.21-.12-4.89-3.03-5.01-7.29-.06-1.94.88-2.48,2.73-6.38,1.65-3.47,3.26-6.87,3.19-10.48-.06-3.43-1.57-3.66-3.19-8.66-1.51-4.68-2.89-12.81,0-14.58v.02h0Z"/>
  <path id="BWK6_Highlight2" data-name="BWK6 Highlight2" class="Wirbelkorper_Querfortsatze1517Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1517Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1517Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1517Fill,
          }} d="M197.29,89.7c3.24-.48,3.79.77,9.11.84.64,0,3.54.03,6.38-.51,1.65-.31,9.51-1.8,14.58-8.66,1.46-1.98,5.59-7.55,3.65-13.22-1.72-5.02-6.9-6.99-7.75-7.29-3.94-1.41-7.59-.5-11.39.46-3.87.97-4.52,1.94-9.3,2.98-4.16.91-4.19.29-6.19,1.12-4.01,1.65-6.18,5.07-8.05,8.01-1.79,2.81-2.73,4.3-2.88,6.58-.08,1.19.13,1.48,1.77,7.79.96,3.68,1.05,4.19,1.67,4.55,1.87,1.08,3.74-1.96,8.4-2.65h0Z"/>
  <path id="BWK6_Highlight1" data-name="BWK6 Highlight1" class="Wirbelkorper_Querfortsatze1517Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1517Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1517Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1517Fill,
          }} d="M84.73,58.13c-3.36,1.86-4.68,5.08-5.01,5.92-.51,1.29-1.57,3.99-.46,6.84.83,2.12,2.46,3.28,3.65,4.1,2.98,2.06,4.31,1.19,7.29,2.73,3.17,1.64,2.23,2.91,6.84,6.84,3.6,3.07,5.03,3.01,6.38,5.47,1.6,2.9.83,5.17,1.82,5.47,1.43.42,5.07-3.64,5.73-8.54.62-4.54-1.89-5.6-3.71-13.61-1.55-6.78-.29-8.38-2.47-11.12-2.42-3.04-6.18-3.84-9.57-4.56-3.46-.74-6.98-1.49-10.48.46,0,0-.01,0-.01,0Z"/>
</g>
<g id="Grund_u_Deckplatten_Highlights" data-name="Grund u Deckplatten Highlights">
  <path id="Grund_u_Deckplatten_Highlight4" data-name="Grund u Deckplatten Highlight4" class="Grund_Deckplatten1517Fill" style= {{
            fill: props.colors.Grund_Deckplatten1517Fill,
            stroke: props.colors.Grund_Deckplatten1517Fill,
            opacity:props.colors.Grund_Deckplatten1517Fill,
          }} d="M186.79,47c-12.98,2.46-26.63,2.04-39.8,2.13-12.82.09-27.16,1.47-39.56-2.25-3.71-1.11-5.29,4.68-1.6,5.79,12.99,3.9,27.75,2.56,41.15,2.47,13.74-.1,27.85.23,41.4-2.34,3.79-.72,2.18-6.5-1.6-5.79Z"/>
  <path id="Grund_u_Deckplatten_Highlight3" data-name="Grund u Deckplatten Highlight3" class="Grund_Deckplatten1517Fill" style= {{
            fill: props.colors.Grund_Deckplatten1517Fill,
            stroke: props.colors.Grund_Deckplatten1517Fill,
            opacity:props.colors.Grund_Deckplatten1517Fill,
          }} d="M193.42,60.51c-6.16-1.34-12.91,2.42-18.9,3.71-8.34,1.79-16.84,1.73-25.32,1.29-8.01-.42-16.03-.88-23.89-2.56-5.77-1.23-12.8-5.15-18.71-3.23-3.66,1.18-2.09,6.98,1.6,5.79,3.2-1.04,7.25,1.16,10.35,1.99,3.42.92,6.9,1.66,10.4,2.22,6.7,1.07,13.49,1.44,20.26,1.8,7.27.38,14.55.47,21.77-.58,3.16-.46,6.28-1.13,9.38-1.94,3.19-.84,8.17-3.41,11.47-2.69,3.76.82,5.37-4.97,1.6-5.79Z"/>
  <path id="Grund_u_Deckplatten_Highlight2" data-name="Grund u Deckplatten Highlight2" class="Grund_Deckplatten1517Fill" style= {{
            fill: props.colors.Grund_Deckplatten1517Fill,
            stroke: props.colors.Grund_Deckplatten1517Fill,
            opacity:props.colors.Grund_Deckplatten1517Fill,
          }} d="M188.2,106.56c-3.21,1.44-6.96-.14-10.25-.47-3.09-.31-6.14.02-9.23.21-7.07.43-14.13.17-21.17-.49-6.71-.64-13.46-1.96-20.19-.97-7.17,1.05-13.31,3.26-19.1-2.6-2.72-2.75-6.96,1.49-4.24,4.24,4.4,4.45,10.11,6.56,16.34,5.79,4.43-.55,8.56-1.88,13.1-1.8,4.11.07,8.19.74,12.27,1.16,8.3.86,16.53,1.06,24.86.54,6.88-.43,14.18,2.48,20.66-.42,3.52-1.57.47-6.75-3.03-5.18Z"/>
  <path id="Grund_u_Deckplatten_Highlight1" data-name="Grund u Deckplatten Highlight1" class="Grund_Deckplatten1517Fill" style= {{
            fill: props.colors.Grund_Deckplatten1517Fill,
            stroke: props.colors.Grund_Deckplatten1517Fill,
            opacity:props.colors.Grund_Deckplatten1517Fill,
          }} d="M188.85,115.27c-7.94.79-15.83,2-23.75,2.95-7.65.93-15.06-.81-22.71-.18-3.73.31-7.45.89-11.2.99-4.12.11-7.79-.86-11.75-1.82-5.23-1.27-10.52-1.43-14.54,2.68-2.7,2.76,1.54,7.01,4.24,4.24,4.36-4.46,13,.48,18.35.85,6.87.47,13.64-1.32,20.5-1.17,3.22.07,6.38.78,9.59.9,3.45.12,6.88-.41,10.3-.84,6.98-.89,13.96-1.91,20.96-2.6,3.81-.38,3.85-6.38,0-6Z"/>
</g>
</svg>
  );
}

export default Brustwirbelkorper6VdLiegend1517;
