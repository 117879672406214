import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeGetObject } from "../../Utilities/AuthHeader";
import ClipLoader from "react-spinners/ClipLoader";

export default function ImagesModal({
  shown,
  onHide,
  imgUrl,
  headerless = true,
}) {
  const [img, setImg] = useState(null);
  const [loading, setLoading] = useState(true);
  const { keycloak } = useKeycloak();

  const loadImage = () => {
    setLoading(true);
    fetch(imgUrl, MakeGetObject(keycloak))
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          setImg(process.env.PUBLIC_URL + "/pm_logo.png");
        }
      })
      .then((data) => {
        setLoading(false);
        setImg(URL.createObjectURL(data));
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(() => {
    shown && loadImage();
  }, [shown]);

  return (
    <Modal show={shown} onHide={onHide}>
      {!headerless && (
        <Modal.Header>
          <h5>Preview</h5>
        </Modal.Header>
      )}
      <Modal.Body className="text-center bg-dark">
        {loading ? (
          <ClipLoader color="#a1a1a1" loading={true} css={true} size={30} />
        ) : (
          <img src={img} alt="Preview" />
        )}
      </Modal.Body>
    </Modal>
  );
}
