import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const Pelvis = map.Pelvis;

function FrontView({ selected, bpartSetter }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 503.48 523.17">
      <g id="Ebene_1" data-name="Ebene 1">
        <g id="pelvis">
          <g id="lumbar_spine" data-name="lumbar spine">
            <path
              id="lumbar_spine_8"
              data-name="lumbar spine 8"
              class="pel-cls-2"
              d="M213.97,30.22v-11.44c1.73-2.81,4.09-4.77,7.69-5.06,24.68,.65,47.99,1.27,63.94,1.69,4.99,1.24,6.57,4.31,8.37,8.15-9.71,3.03-33.34,1.37-56.94,.66-8.5-.61-14.41-3.11-18-7.31"
            />
            <path
              id="lumbar_spine_7"
              data-name="lumbar spine 7"
              class="pel-cls-2"
              d="M229.53,77.09c-15.47-1.43-20.85-7.26-18-16.69,1.32-5.07,1.72-10.16-.94-15.38l-14.81-.56c-4.35-1.79-7.44-5.13-9.94-9.19-.64-5.73,2.65-8.38,9-8.62,6.04,4.11,11.82,7.22,14.25-2.44,.71-3.03,.32-6.5-1.41-10.5,5.14-19.32,13.57-15.53,23.53-.94l1.51,1.23,43.68,1.15,2.06-4.63c3.91-8.21,15.97-18.26,18.56,1.69,3.19,2.91,3.78,6.14,1.5,9.75-2.5,4.5-2.5,8,0,10.5,3.7,.29,7.18-.02,9.94-2.25,4.13-1.18,8.09-1.32,11.81,0,6.06,2.83,9.03,6.9,3.38,14.44-8.15-.68-15.76-1.05-22.31,.75-5.06,5.41-5.84,10.58-2.06,16.31-.75,7.25-4.46,11.84-11.62,13.31-5.81,4.08-34.4,2.46-58.12,2.06h-.01Z"
            />
            <path
              id="lumbar_spine_6"
              data-name="lumbar spine 6"
              class="pel-cls-2"
              d="M211.91,58.8c-7.81,.66-12.47,5.33-12.38,16.04,3.33,3.45,8,5.41,14.44,5.44,27.91,7.14,54.09,8.37,77.44,0,7.69-1.25,13.65-4.65,17.44-10.74-1.69-6.11-5.21-7.94-9.56-7.82"
            />
            <path
              id="lumbar_spine_5"
              data-name="lumbar spine 5"
              class="pel-cls-2"
              d="M199.53,72.45c-13.68-1.25-18.35,2.62-13.77,11.77,12.39,4.67,21.11,10.74,19.95,20.62v16.69c33.26,18.38,64.46,22.38,91.5-2.62v-13.31c1.02-6.39,3.88-9.17,8.06-9.38,10.9,1.91,16.89-2,17.44-12.38,.29-5.63-2.77-8.21-8.62-8.25-4.27,1.49-7.23,.87-9.2-1.32"
            />
            <path
              id="lumbar_spine_4"
              data-name="lumbar spine 4"
              class="pel-cls-2"
              d="M205.72,114.78c-8.1,12.03-14.15,8.58-19.95,3.19-11.43-4.44-13.6-.14-10.23,9.38,8.03,1.67,17.67,8,28.05,16.5,3.06,4.27,5.56,8.75,3.08,15.19-.37,4.32-.21,7.63,.22,10.25,.35,2.09,.88,3.75,1.47,5.13,29.1,21.76,57.66,26.25,85.31,2.25,3.04-7.84,3.77-16.7,2.81-26.25,1.34-8.07,6.46-12.87,12.71-16.69,8.34-1.46,14.21-6.98,16.16-11.25,2.15-9.38-1.27-10.13-6.75-7.69l-21.38,4.12c2.4,1.19,3,5.24,2.25,11.44-33.46,29.95-65.26,28.53-95.62,0-.62-4.39-.23-7.62,1.88-8.81"
            />
            <path
              id="lumbar_spine3"
              data-name="lumbar spine3"
              class="pel-cls-2"
              d="M206.69,167.87c-1.08,8.37-1.54,15.39-1.15,20.6,29.34,25.12,58.66,28.88,87.94,0,.19-1.07,.79-9.27,2.59-20.6"
            />
            <path
              id="lumbar_spine_2"
              data-name="lumbar spine 2"
              class="pel-cls-2"
              d="M193.72,122.47c-1.37-10.75,2.04-17.16,12-17.62"
            />
            <path
              id="lumbar_spine_1"
              data-name="lumbar spine 1"
              class="pel-cls-2"
              d="M297.45,104.36c8.41,.18,13.4,2.54,11.73,12.24"
            />
          </g>
          <g id="christa_iliaca_right" data-name="christa iliaca right">
            <path
              id="christa_iliaca_right_2"
              data-name="christa iliaca right 2"
              class="pel-cls-2"
              d="M171.71,136.49c-3.62-34.28-20-51.86-50.93-50.39-45.15-10.46-80.07,16.01-110.25,59.62C-.22,161.04-2.86,177.85,5.19,196.63c-1.68,18.09,2.77,29.18,18.56,27.28,9.99-.65,19.32,5.36,27,28.12-.04,13.92,.58,26.15,3.09,33.47,4.59,2.05,6.79,13.23,7.88,28.69,14.97-9.96,28.57-12.22,38.25,7.59,17.65-28.11,38.15-32.85,61.68-12.65"
            />
            <path
              id="christa_iliaca_right_1"
              data-name="christa iliaca right 1"
              class="pel-cls-2"
              d="M104.47,90.88c-31.45,3.74-53.62,20.02-68.92,45.62-7,16.77-17.04,32.42-4.77,56.29,1.85,4.93,2.62,10.08,0,15.96-3.22,19.27-11.48,19.35-22.23,9.98"
            />
          </g>
          <g id="christa_iliaca_left" data-name="christa iliaca left">
            <path
              id="christa_iliaca_left_2"
              data-name="christa iliaca left 2"
              class="pel-cls-2"
              d="M325.21,141.11c1.28-27.45,10.93-46.5,34.08-52.01,29.7-7.19,55.7-6.81,78.56,0,21.77,15.17,37.23,39.64,46.69,72.94,4.14,33.31,2.59,63.64-34.5,75.38-15.24,8.92-20.37,24.63-20.25,43.88,4.4,18.88-.24,27.59-16.12,23.62l-29.62,14.81c-6.62-21.15-23.4-28.94-40.12-23.81l-15.19,7.5c25.84-41.39,26.59-59.81,1.5-54.56"
            />
            <path
              id="christa_iliaca_left_1"
              data-name="christa iliaca left 1"
              class="pel-cls-2"
              d="M435.5,98.47c36.01,28.65,41.76,66.48,19.97,103.31-6.69,5.9-11.35,11.68-5.91,16.94-.5,4.05,4.46,8.1,14.97,12.13"
            />
          </g>
          <g id="sacrum">
            <path
              id="sacrum_4"
              data-name="sacrum 4"
              class="pel-cls-2"
              d="M297.45,154.16c8.79-12.12,17.59-21.59,26.39-14.31,1.75,1.45,3.5,3.56,5.25,6.44,.35,9.12,4.05,17.81,14.06,25.69,1.55,15.48,2.99,30.91,0,44.44-1,9.3-6.14,20.49-12.94,32.44-7.06,3.89-11.3,10.24-12.19,19.5-9.95,13.72-18.23,27.77-21.97,42.75-5.32,6.67-11.95,11.61-20.03,14.62-1.06,1.54-2.11,3-3.16,4.38-15.8,20.83-30.24,23.77-42.96,4.25-6.03-6.64-16.63-12.25-29.06-17.44-3.54-8.57-9.34-15.57-16.88-21.38-10.6-16.25-16.95-33.69-17.81-52.69-7.33-9.15-11.3-22.14-13.69-35.81-.56-15.35-.16-30.82,1.69-46.5,4.74-12.31,10.85-22.38,21.19-25.5,10.22,.71,20.94,6.35,32.29,18.16"
            />
            <path
              id="sacrum_3"
              data-name="sacrum 3"
              class="pel-cls-2"
              d="M273.92,328.72c9.37,4.94,9.11,14.13-2.1,21.75,24.27-8.59,45.49-23.68,56.91-47.06"
            />
            <path
              id="sacrum_2"
              data-name="sacrum 2"
              class="pel-cls-2"
              d="M166.16,242.85c-10.96-3.84-19.92-1.47-27.66,4.69-4.45,21.78,5.13,40.72,20.53,58.5,21.95,26.41,44.4,44.67,67.78,47.53-6.39-8.53-5.06-14.85,3.09-19.22"
            />
            <path
              id="sacrum_1"
              data-name="sacrum 1"
              class="pel-cls-2"
              d="M226.81,353.56c17.05,5.5,33.41,4.95,45-3.09"
            />
          </g>
          <g id="upper_leg_right" data-name="upper leg right">
            <path
              id="upper_leg_6_right"
              data-name="upper leg 6 right"
              class="pel-cls-2"
              d="M61.72,314.19c-4.01,7.57-4.07,14.88-.56,21.94-3.67,8.58-12.32,11.93-23.06,13.08-9.21-21.79-19.05-24.24-29.53-6.94-6.81,17.5-6.3,40.88-3.09,66.42,8.72,13.77,15.68,28.9,18.84,46.97l11.53,54"
            />
            <path
              id="upper_leg_5_right"
              data-name="upper leg 5 right"
              class="pel-cls-2"
              d="M88.72,504.88c-3.79-15.98-3.92-30.04,1.69-41.06,5.06-12.07-.12-27.39-15.19-45.84,2.27-21,8.86-35.5,24.75-36"
            />
            <path
              id="upper_leg_4_right"
              data-name="upper leg 4 right"
              class="pel-cls-2"
              d="M30.78,378.31c8.87-5.89,12.12-15.11,7.31-29.11"
            />
            <path
              id="upper_leg_3_right"
              data-name="upper leg 3 right"
              class="pel-cls-2"
              d="M75.22,417.97c-4.31,8.87-3.39,19.83,2.81,32.91"
            />
            <path
              id="upper_leg_2_right"
              data-name="upper leg 2 right"
              class="pel-cls-2"
              d="M61.16,336.13c8.11-4.34,14.56-4.77,16.88,4.5,8.24,3.43,10.86,10.04,9.56,18.84,6.57,6.74,6.97,11.17,2.53,13.78"
            />
            <path
              id="upper_leg_1_right"
              data-name="upper leg 1 right"
              class="pel-cls-2"
              d="M99.97,321.78c8.58,8.38,14.53,21.63,10.12,44.16"
            />
          </g>
          <g id="upper_leg_left" data-name="upper leg left">
            <path
              id="upper_leg_left_9"
              data-name="upper leg left 9"
              class="pel-cls-2"
              d="M426.69,304.28c10.31,6.56,17.25,14.85,14.44,28.19,6.13,12.76,13.47,21.22,26.44,9.8,7.79-6.63,14.13-6.07,18.56,3.98,11.62,10.82,17.47,28.66,16.03,55.33-13.12,14.1-22.76,32.25-29.25,54.07-6.79,16.88-10.71,36.45-13.78,56.81"
            />
            <path
              id="upper_leg_left_8"
              data-name="upper leg left 8"
              class="pel-cls-2"
              d="M377.84,375.5c7.88,4.11,15.3,4.89,21.94,0,9.16,16.39,14.83,35.1,11.25,59.91-13.71,15.27-17.43,31.2-6.19,48.09,5.98,14.47,4.18,26.05-1.97,36"
            />
            <path
              id="upper_leg_left_7"
              data-name="upper leg left 7"
              class="pel-cls-2"
              d="M396.69,368.19c4.54-19.4,15.24-31.37,31.78-36.28"
            />
            <path
              id="upper_leg_left_6"
              data-name="upper leg left 6"
              class="pel-cls-2"
              d="M435.5,330.5c1.88-.66,3.75,0,5.62,1.97"
            />
            <path
              id="upper_leg_left_5"
              data-name="upper leg left 5"
              class="pel-cls-2"
              d="M421.72,350.05c2.4-6.24,6.05-10.12,11.53-10.55"
            />
            <path
              id="upper_leg_left_4"
              data-name="upper leg left 4"
              class="pel-cls-2"
              d="M411.03,435.41c2.33,9.97,1.8,21.4,0,33.47"
            />
            <path
              id="upper_leg_left_3"
              data-name="upper leg left 3"
              class="pel-cls-2"
              d="M460.25,361.16c-2.54,5.07-1.96,8.75,0,11.81"
            />
            <path
              id="upper_leg_left_2"
              data-name="upper leg left 2"
              class="pel-cls-2"
              d="M485.28,372.97c3.19,16.41,1.88,30.38-3.94,41.91"
            />
            <path
              id="upper_leg_left_1"
              data-name="upper leg left 1"
              class="pel-cls-2"
              d="M384.03,319.72c-5.43,3.79-11.53,11.97-14.62,27.75"
            />
          </g>
          <g id="coccyx">
            <path
              id="coccyx_4"
              data-name="coccyx 4"
              class="pel-cls-2"
              d="M110.1,365.94c6.9,12.58,14.76,15.18,24.19,1.69,21.58-10.61,42.72-17.94,61.59-7.88,5.76,8.17,13.45,13.45,28.41,7.88,4.53-6.27,11.67-5.8,19.18-1.38"
            />
            <path
              id="coccyx_3"
              data-name="coccyx 3"
              class="pel-cls-2"
              d="M110.1,365.94c.83,5.68-2.25,14.35-10.12,16.03,8.18,17.51,25.42,35.17,44.72,52.88,24.85,15.7,46.07,19.47,59.34-2.81,10.61-9.14,23.66-16.74,38.33-23.34"
            />
            <path
              id="coccyx_2"
              data-name="coccyx 2"
              class="pel-cls-2"
              d="M145.49,362.47c-2.59,6.27-2.55,12.59,0,18.94,4.2,9.79,11.87,17.85,20.57,25.4,6.56,4.06,13.12,5.22,19.69,.47,8.32-5.04,14.44-12.34,19.69-20.53,.56-7.2-.38-13.42-1.78-19.33"
            />
            <path
              id="coccyx_1"
              data-name="coccyx 1"
              class="pel-cls-2"
              d="M175.64,410.38c-5.24,12.37-5.24,24.75,0,37.12"
            />
          </g>
          <g id="coccyx_also" data-name="coccyx also">
            <path
              id="coccyx_9"
              data-name="coccyx 9"
              class="pel-cls-2"
              d="M368.61,350.47c-3.11,9.15-11.34,12.61-25.92,9-13.13-4.93-24.34-2.53-37.12,2.53,1.09-.05-26.38-1.41-43.3,2.81"
            />
            <path
              id="coccyx_8"
              data-name="coccyx 8"
              class="pel-cls-2"
              d="M369.41,347.47c-.21,1.06-.48,2.06-.8,3"
            />
            <path
              id="coccyx_7"
              data-name="coccyx 7"
              class="pel-cls-2"
              d="M262.27,410.25c6.71,5.05,12.41,13.04,16.86,24.6,27.96,19.73,53.93,27.56,73.97,0,10.23-9.11,17.73-23.75,24.75-39.38v-19.97c-6.11-3.47-8.2-13.39-9.24-25.03"
            />
            <path
              id="coccyx_6"
              data-name="coccyx 6"
              class="pel-cls-2"
              d="M305.56,362l-7.03,21.37c-1.75,5.42-2,10.3,0,14.35,14.35,17.31,27.16,21.78,37.69,7.03,6.31-11.15,4.07-29.64,1.97-47.56"
            />
            <path
              id="coccyx_5"
              data-name="coccyx 5"
              class="pel-cls-2"
              d="M325.21,414.88c4.1,12.91,4.74,25.53,1.47,37.82"
            />
          </g>
          <g id="symphysis">
            <path
              id="symphysis_2"
              data-name="symphysis 2"
              class="pel-cls-2"
              d="M242.36,356.89c2.58,19.26,3.22,37.02,0,51.8,5.76,5.58,12.68,7.18,22.14,0-2.35-12.88-2.61-32.01-2.03-53.63"
            />
            <path
              id="symphysis_1"
              data-name="symphysis 1"
              class="pel-cls-2"
              d="M249.72,361.16c.38,15.96,.1,30.84-1.15,44.16,2.87,2.62,5.75,2.62,8.62,0-3.01-16.1-1.54-30.28,0-44.44-2.5-1.96-4.99-1.89-7.47,.28Z"
            />
          </g>
        </g>
        <g id="lumbar_spine_overlay" data-name="lumbar spine overlay">
          <path
            id="lumbar_spine_overlay_1"
            onClick={() => {
              bpartSetter({
                id: idToSet(selected, Pelvis.id, Pelvis.children.lumbarSpine),
              });
            }}
            class={`pel-cls-1 ${isSelected(
              selected,
              Pelvis.children.lumbarSpine
            )}`}
            data-name="lumbar spine overlay 1"
            d="M207.69,13.72c1.34-12.49,6.7-16.81,19.12-6.76l4.41,5.83,45.19,2.38,4.22-8.21c6.4-7.72,12.28-10.19,16.41,5.26,3.84,1.81,3.64,6.52,0,13.69-.73,6.81,2.52,8.92,11.44,4.31,13.09-1,21.16,1.64,15.19,14.44l-22.31,.75c-5.15,5.31-5.76,10.75-2.06,16.31,4.43-.07,7.58,2.63,9.56,7.82l-3.95,4.73c2.39,2.05,5.26,2.95,9.2,1.32,8.35,1.77,11.35,6.49,6.98,15.28-2.16,5.45-8.48,5.99-15.79,5.34-3.72-1.25-5.94,2.88-7.83,8.14,9.78-.25,13.91,3.67,11.73,12.24l14.48-2.94c6.42,8.83-3.51,14.87-14.48,20.06-12.52,5.42-12.66,14.93-11.73,20.44-.29,9.51-.93,18.28-3.79,22.5l-.19,11.81c-29.8,28.62-59.06,25.43-87.94,0l1.35-19.19-.22-10.25c3.43-9.91-1.85-15.88-9.98-20.57l-21.15-11.12c-3.37-9.62-1.06-13.8,10.23-9.38l7.95,4.5c-.45-11.52,2.96-18.14,12-17.62,.89-11.29-8.86-16.35-19.95-20.62-4.59-9.14,.09-13.01,13.77-11.77,1.48-9.39,5.46-14.2,12.38-13.65,1.89-3.78,1.58-8.33-1.32-13.77l-14.81-.56c-13.17-9.89-12.57-15.55-.94-17.81,11.74,8.01,15.1,2.18,12.84-12.94h-.01Z"
          />
        </g>
        <g id="coccxy_and_sacrum" data-name="coccxy and sacrum">
          <path
            onClick={() => {
              bpartSetter({
                id: idToSet(
                  selected,
                  Pelvis.id,
                  Pelvis.children.coccyxAndSacrum
                ),
              });
            }}
            class={`pel-cls-1 ${isSelected(
              selected,
              Pelvis.children.coccyxAndSacrum
            )}`}
            id="coccxy_and_sacrum_overlay"
            data-name="coccxy and sacrum overlay"
            d="M205.53,188.47l1.15-20.6v-15.66c-9.61-9.55-19.78-15.47-30.59-17.18,2.04,32.08-2.18,68.79-9,107.44,.35,18.17,5.63,35.89,16.88,53.06,7.38,5.95,13.5,12.74,16.88,21.38,11.2,4.7,21.62,9.98,29.06,17.44,15.28,19.17,30.5,16.72,45.64-7.93,8.47-3.57,15.75-8.26,20.51-15.32,6.85-16.86,13.91-31.99,21.55-42.17,1.87-9.77,6.26-16.21,12.61-20.08-5.25-32.98-6.22-66.26-1.5-99.95,.12-.87,.25-1.74,.38-2.61-6.88-12.29-16.35-14.04-31.64,7.88,.62,7.81,.43,15.06-1.39,21.19l-2.59,13.12c-29.22,28.65-58.55,25.45-87.94,0h0Z"
          />
        </g>
        <g id="christa_iliaca_left-2" data-name="christa iliaca left-2">
          <path
            id="christa_iliaca_left_overlay"
            onClick={() => {
              bpartSetter({
                id: idToSet(selected, Pelvis.id, Pelvis.children.christaIliaca),
              });
            }}
            class={`pel-cls-1 ${isSelected(
              selected,
              Pelvis.children.christaIliaca
            )}`}
            data-name="christa iliaca left overlay"
            d="M329.1,146.28c-3.5,37.45-3.38,71.86,1.12,102.56,14.19-3.19,22.91-.32,16.44,19.36l-17.57,34.6c13.3-8.32,24.89-12.15,33.16-7.17l43.41-11.71c14.96-68.83,12.04-134.4,6.91-199.56-14.72-1.75-32.87,.24-53.3,4.74-21.25,7.51-33.14,24.68-30.19,57.19h.02Z"
          />
        </g>
        <g id="christa_iliaca_lright" data-name="christa iliaca lright">
          <path
            id="christa_iliaca_left_right"
            onClick={() => {
              bpartSetter({
                id: idToSet(selected, Pelvis.id, Pelvis.children.christaIliaca),
              });
            }}
            class={`pel-cls-1 ${isSelected(
              selected,
              Pelvis.children.christaIliaca
            )}`}
            data-name="christa iliaca left right"
            d="M138.5,247.53l1.58-.27c6-6.26,14.92-7.32,26.08-4.42,4.35-20.58,15.42-105.7,5.56-106.35-3.31-48.39-47.37-53.69-71.89-52.65-22.4,68.3-24.77,126.48-13.72,195.75,11.96,.08,59.3,17.37,72.93,26.44-16.63-17.32-23.71-36.77-20.53-58.5h-.01Z"
          />
        </g>
        <g id="hip_joint_right" data-name="hip joint right">
          <path
            id="hip_joint_right_overlay"
            onClick={() => {
              bpartSetter({
                id: idToSet(selected, Pelvis.id, Pelvis.children.hipJointR),
              });
            }}
            class={`pel-cls-1 ${isSelected(
              selected,
              Pelvis.children.hipJointR
            )}`}
            data-name="hip joint right overlay"
            d="M61.16,336.13c-3.14-2.89-3.82-9.67,.56-24.47,2.08-11.75-.49-20.04-6.29-25.94,10.78,.63,19.11-1.83,24.38-8.16,23.33,9.36,46.47,18.64,49.14,19.71,11.47-1.15,22.43,2.21,32.69,11.86l22.41,24.02-25.16,24.49-24.62,9.98c-7.7,13.14-15.8,11.31-24.19-1.69,1.18,9.78-2.7,14.63-10.12,16.03-9.55-.51-16.67,6.22-21.58,19.61l-45.34-24.96c7.92-7.56,8.97-17,7.26-27.2,7.57-1.46,15.13-2.93,20.84-13.3l.02,.02Z"
          />
        </g>
        <g id="hip_joint_left" data-name="hip joint left">
          <path
            id="hip_joint_left_overlay"
            onClick={() => {
              bpartSetter({
                id: idToSet(selected, Pelvis.id, Pelvis.children.hipJointL),
              });
            }}
            class={`pel-cls-1 ${isSelected(
              selected,
              Pelvis.children.hipJointL
            )}`}
            data-name="hip joint left overlay"
            d="M329.1,302.8c15.2-7.96,28.46-11.61,37.34-5.53l63.39-19.71c1.55,22.61-2.02,35.28-16.17,27.34l13.03-.63c11.22,8.55,18.26,17.65,14.44,28.19,4.55,12.85,11.44,20.6,27.11,9.24-16.3,18.62-40.03,28.85-68.46,33.79-20.27,8.6-28.9-2.76-31.17-25.03-6.8,11.26-16.94,13.5-30.42,6.72l-29.34-27.86c8.43-7.36,15.13-16.25,20.25-26.53h0Z"
          />
        </g>
        <g id="pelvis_complete_overlay" data-name="pelvis complete overlay">
          <path
            id="pelvis_complete_overlay_1"
            data-name="pelvis complete overlay 1"
            class="pel-cls-1"
            style={{ display: "none" }}
            d="M183.66,458.39c41.34-14.77,80.77-18.44,116.61-1.38,47.87,28.95,54.21,50.47,3.21,61.7-31.45,5.95-72.55,4.34-116.16,.78-54.82-13-45.43-34.83-3.66-61.11h0Z"
          />
        </g>
        <g
          id="upper_leg_with_hip_joint_right"
          data-name="upper leg with hip joint right"
        >
          <path
            onClick={() => {
              bpartSetter({
                id: idToSet(
                  selected,
                  Pelvis.id,
                  Pelvis.children.upperLegWithHipJointR
                ),
              });
            }}
            class={`pel-cls-1 ${isSelected(
              selected,
              Pelvis.children.upperLegWithHipJointR
            )}`}
            id="upper_leg_with_hip_joint_right_overlay"
            data-name="upper leg with hip joint right overlay"
            d="M33.06,376.63c7-6.68,9.67-15.36,5.04-27.42-9.54-21.87-18.77-23.19-27.79-9.63-8.26,15.1-8.49,39.78-4.84,69.11,12.43,18.7,18.93,40.26,22.33,63.32l8.04,37.65,52.88-4.78c-3.43-13.51-4.5-27.12,1.69-41.06,4.87-16.23-2.6-31.28-15.19-45.84l3.17-16.39-45.34-24.96h0Z"
          />
        </g>
        <g
          id="upper_leg_with_hip_joint_left"
          data-name="upper leg with hip joint left"
        >
          <path
            onClick={() => {
              bpartSetter({
                id: idToSet(
                  selected,
                  Pelvis.id,
                  Pelvis.children.upperLegWithHipJointL
                ),
              });
            }}
            class={`pel-cls-1 ${isSelected(
              selected,
              Pelvis.children.upperLegWithHipJointL
            )}`}
            id="upper_leg_with_hip_joint_left_overlay"
            data-name="upper leg with hip joint left overlay"
            d="M401.3,378.31l3.23-1.69c23.1-5.92,43.76-15.97,60.88-31.85,9.6-8.14,17.19-9.84,20.72,1.48,14.02,18.01,18.98,36.48,16.03,55.33-23.94,25.4-33.51,66.89-41.91,109.68l-57.38,8.23c6.54-11.19,8.08-22.45,2.81-33.83-12.24-23.19-7.74-38.7,5.35-50.26,4.69-19.05,1.47-38.08-9.73-57.09h0Z"
          />
        </g>
      </g>
      <g id="Ebene_2" data-name="Ebene 2">
        <path
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Pelvis.id, Pelvis.children.coccyx),
            });
          }}
          class={`pel-cls-1 ${isSelected(selected, Pelvis.children.coccyx)}`}
          d="M229.9,334.36c-7.41,3.66-9.89,9.43-3.09,19.2,0,4.82,4.19,9.05,15.84,12.24,2.67,13.93,2.44,28.25-.28,42.9,6.91,5.93,14.18,7.25,22.13-.01l-2.23-43.88c9.22-2.34,12.84-7.63,12.05-15.25,9.41-10.61,7.44-16.85-.4-20.84-15.73,22.4-30.59,27.8-44.02,5.64Z"
        />
      </g>
    </svg>
  );
}

export default FrontView;
