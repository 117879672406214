import style from "./style.scss";

function Rechter5FingerDpSitzend1328(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.1 474.24">

<g id="highlights">
  <g id="finger">
    <path id="basephalanx" class="FullFinger1328Fill" style= {{
            fill: props.colors.FullFinger1328Fill,
            stroke: props.colors.FullFinger1328Fill,
            opacity:props.colors.FullFinger1328Fill,
          }} d="M105.52,414.93h0l.02-.02c-13.35-6.48-28.88-5.67-45.87,0-4.63,2.12-8.04,1.57-7.9-6.68-3.86-6.35-4.24-13.46,0-21.57,15.83-18.41,22.75-51.28,24.61-92.35.56-9.98,1.04-18.52,1.42-25.29.07-1.16.28-2.31.57-3.44,1.88-7.29,1.87-14.06-.47-20.18-2.23-3.47-1.4-6.59.91-9.53,1.05-1.35,1.53-3.08,1.34-4.78-.51-4.56.35-9.1,2.54-13.61.64-1.32,1.72-2.41,3.07-3,6.04-2.66,13.88-1.03,22.5,2.48,2.73,1.11,5.7,1.54,8.64,1.26l11.11-1.07c4.86.82,8.03,5.43,6.1,21.49-.2,1.6-.18,3.22,0,4.83.54,4.91-.37,8.34-2.69,10.32-1.15.99-2.04,2.24-2.51,3.68-3.57,10.9-4.67,21.14-4.03,30.92.05.72.02,1.43-.09,2.14-6.35,39.83-8.68,75.1.17,97.67,6.99,10.29,9.22,19.64,0,26.73-4.18,6.53-10.12,8.06-19.44,0h0Z"/>
    <path id="middlephalanx" class="FullFinger1328Fill" style= {{
            fill: props.colors.FullFinger1328Fill,
            stroke: props.colors.FullFinger1328Fill,
            opacity:props.colors.FullFinger1328Fill,
          }} d="M140.01,195.56h0l-.02-.03c.75,1.38,1.03,2.97.84,4.53-.54,4.44-2.05,7.93-6.17,8.87-6.26-.32-13.3,1.06-20.84,3.55-1.26.43-2.62.43-3.88,0-9.21-3.15-17.91-5.59-24.54-5.13-1.79.12-3.5-.77-4.38-2.33-3.14-5.57-3.55-10.28-.26-13.84,1.51-1.62,2.5-3.66,3.06-5.81,5.62-21.63,8.88-42.42,9.04-62.11.34-2.21-.03-4.48-1-6.5-2.96-6.16-2.74-10.51,1.05-15.28,1.18-1.48,1.7-3.34,1.54-5.22-.64-7.67,4.62-10.68,12.75-6.67,4.51,1.14,8.64,1.86,12.61.57,10.08-1.97,12.53.55,13.22,8.12.1,1.2.5,2.33,1.07,3.38.97,1.79,1.41,4.53,1.84,8.02.32,2.64-.32,5.37-1.98,7.45-5.88,7.37-6.82,19.3-3.09,35.54.66,2.89,1.09,5.82,1.23,8.77.62,13.18,2.92,24.85,7.91,34.12h0Z"/>
    <path id="endphalanx" class="FullFinger1328Fill" style= {{
            fill: props.colors.FullFinger1328Fill,
            stroke: props.colors.FullFinger1328Fill,
            opacity:props.colors.FullFinger1328Fill,
          }} d="M133.15,83.79s0,0,0,0c-3.13,4.78-5.47.34-15.04,2.28-5.18,1.33-10.31.76-15.38-1.71-5.51.32-9.39-1.05-9.91-5.92-3.38-2.83-2.8-5.68.46-8.54,9.66-8.62,5.76-28.15,4.22-27.46-3.82-6.54-3.77-14.54,0-23.92,1.82-2.8,12.18-4.89,18.34-.46,6.23,4.8,9.94,9.9,9.46,15.49.33,4.22.27,7.95-.95,10.23-.5.92-.7,1.96-.7,3-.03,12.06,4.93,21.31,12.59,29.72,1.02,3.02.13,5.48-3.08,7.29h0Z"/>
  </g>
  <g id="basejointhighlight">
    <ellipse class="Grundgelenk1328Fill" style= {{
            fill: props.colors.Grundgelenk1328Fill,
            stroke: props.colors.Grundgelenk1328Fill,
            opacity:props.colors.Grundgelenk1328Fill,
          }} cx="86.8" cy="414.73" rx="47.99" ry="11.82"/>
  </g>
  <g id="fingerendjointhighlight">
    <ellipse class="Fingerendgelenk1328Fill" style= {{
            fill: props.colors.Fingerendgelenk1328Fill,
            stroke: props.colors.Fingerendgelenk1328Fill,
            opacity:props.colors.Fingerendgelenk1328Fill,
          }} cx="113.89" cy="86.54" rx="27.1" ry="7.58"/>
  </g>
  <path id="fingertiphighlight" class="Fingerkuppe1328Fill" style= {{
            fill: props.colors.Fingerkuppe1328Fill,
            stroke: props.colors.Fingerkuppe1328Fill,
            opacity:props.colors.Fingerkuppe1328Fill,
          }} d="M116.59,17.77c-5.54-3.15-10.18-3.93-13.99-2.6-5.5.46-7.17,6.07-7.79,13.93,8.32-7.56,18.59-6.42,30.63,2.69-1.06-6.04-3.87-10.81-8.85-14.02Z"/>
</g>
<g id="linkerkleiner_Finger" data-name="linkerkleiner Finger">
  <g id="fingerdetails">
    <path id="middlehand2" class="KleinerFinger1328_rechts_cls-2" d="M38.02,473.75c1.62-11.87,6.2-16.46,11.76-18.64"/>
    <path id="middlehand1" class="KleinerFinger1328_rechts_cls-2" d="M111.39,470.56c.52-5.32-2.35-10-6.27-14.47-4.38-3.72-5.81-10.17-3.53-20.05"/>
    <path id="middlehand" class="KleinerFinger1328_rechts_cls-4" d="M48.81,472.2c-.27-15.46,1.39-26.48,6.34-39.71,2.82-7.55,9.63-12.95,17.63-13.89,5.08-.6,10.55.04,16.13,1.01,11.86,1.7,20.44,10.04,24.61,27.33,1.85,11.29,3.37,16.59,3.34,23.62"/>
    <path id="basejoint" class="KleinerFinger1328_rechts_cls-2" d="M64.01,412.81c5.66.25,10.84,1.41,15.68,3.18,7.89,2.89,16.43,3.71,24.72,2.35,2-.33,3.72-.74,5.16-1.23"/>
    <path id="basephalanx-2" data-name="basephalanx" class="KleinerFinger1328_rechts_cls-4" d="M105.62,414.13h0l.02-.02c-13.35-6.48-28.88-5.67-45.87,0-4.63,2.12-8.04,1.57-7.9-6.68-3.86-6.35-4.24-13.46,0-21.57,15.83-18.41,22.75-51.28,24.61-92.35.56-9.98,1.04-18.52,1.42-25.29.07-1.16.28-2.31.57-3.44,1.88-7.29,1.87-14.06-.47-20.18-2.23-3.47-1.4-6.59.91-9.53,1.05-1.35,1.53-3.08,1.34-4.78-.51-4.56.35-9.1,2.54-13.61.64-1.32,1.72-2.41,3.07-3,6.04-2.66,13.88-1.03,22.5,2.48,2.73,1.11,5.7,1.54,8.64,1.26l11.11-1.07c4.86.82,8.03,5.43,6.1,21.49-.2,1.6-.18,3.22,0,4.83.54,4.91-.37,8.34-2.69,10.32-1.15.99-2.04,2.24-2.51,3.68-3.57,10.9-4.67,21.14-4.03,30.92.05.72.02,1.43-.09,2.14-6.35,39.83-8.68,75.1.17,97.67,6.99,10.29,9.22,19.64,0,26.73-4.18,6.53-10.12,8.06-19.44,0h0Z"/>
    <path id="middlephalanx-2" data-name="middlephalanx" class="KleinerFinger1328_rechts_cls-4" d="M140.11,194.76h0l-.02-.03c.75,1.38,1.03,2.97.84,4.53-.54,4.44-2.05,7.93-6.17,8.87-6.26-.32-13.3,1.06-20.84,3.55-1.26.43-2.62.43-3.88,0-9.21-3.15-17.91-5.59-24.54-5.13-1.79.12-3.5-.77-4.38-2.33-3.14-5.57-3.55-10.28-.26-13.84,1.51-1.62,2.5-3.66,3.06-5.81,5.62-21.63,8.88-42.42,9.04-62.11.34-2.21-.03-4.48-1-6.5-2.96-6.16-2.74-10.51,1.05-15.28,1.18-1.48,1.7-3.34,1.54-5.22-.64-7.67,4.62-10.68,12.75-6.67,4.51,1.14,8.64,1.86,12.61.57,10.08-1.97,12.53.55,13.22,8.12.1,1.2.5,2.33,1.07,3.38.97,1.79,1.41,4.53,1.84,8.02.32,2.64-.32,5.37-1.98,7.45-5.88,7.37-6.82,19.3-3.09,35.54.66,2.89,1.09,5.82,1.23,8.77.62,13.18,2.92,24.85,7.91,34.12h0Z"/>
    <path id="basephalanx1" class="KleinerFinger1328_rechts_cls-2" d="M115.6,249.55c-5.35,2.48-7.86,10.89-7.67,24.91.02,1.11.21,2.21.57,3.26,2.85,8.34,2.2,20.77.16,34.82l4.1,45.23c2.37,17.74,6.01,27.22,10.48,31.22"/>
    <path id="basephalanx2" class="KleinerFinger1328_rechts_cls-2" d="M96.81,254.45c-1.45,3.42-2.25,9.04-2.25,17.36,0,3.08-.64,6.16-2.04,8.91-3.08,6.06-4.39,15.9-4.78,27.69-.03.85-.23,1.69-.58,2.46-2.98,6.55-5.18,13.47-6.41,20.82-1.03,11.03-7.37,23.63-15.61,36.8"/>
    <path id="basephalanx3" class="KleinerFinger1328_rechts_cls-2" d="M128.93,223.46c-.15,10.37,1.37,15.65,4.56,15.84"/>
    <path id="basephalanx4" class="KleinerFinger1328_rechts_cls-2" d="M87.01,220.96c-1.47,6.66-3.61,10.56-6.75,10.42"/>
    <path id="middlephalanx1" class="KleinerFinger1328_rechts_cls-2" d="M87.92,200.68c7.01.27,13.27,1.12,17.43,3.59,2.06,1.42,4.28,1.97,6.63,1.88,1.69-.06,3.36-.49,4.9-1.2,5.49-2.51,10.81-3.23,15.93-1.99"/>
    <path id="middlephalanx2" class="KleinerFinger1328_rechts_cls-2" d="M118.79,108.16c-4.29,5.62-5.46,14.25-3.87,25.52,2.91,7.41,5.03,14.47,5.58,20.85,3.33,12.53,7.2,23.76,11.62,33.61,2.23,3.74,3.69,8.35,4.62,13.56"/>
    <path id="middlephalanx3" class="KleinerFinger1328_rechts_cls-2" d="M85.42,194.31c8.41-16.39,13.73-33.77,16.85-51.86.49-2.86.69-5.75.58-8.65-.27-7.34.16-14.99,1.13-22.89-.38-2.21-.97-4.31-1.86-6.27"/>
    <path id="endphalanx1" class="KleinerFinger1328_rechts_cls-2" d="M99.32,68.3c4.21.46,6.53-4.58,7.15-14.56.25-3.91.15-7.84-.15-11.74-.52-6.7,1.31-9.6,5.3-9.02,3.45.04,4.77,4.44,4.89,11.3.03,1.91.2,3.82.55,5.7,1.33,7.09,4.77,13.35,9.14,19.23,3.49,2.84,5.27,5.44,4.9,7.75"/>
    <path id="endphalanx-2" data-name="endphalanx" class="KleinerFinger1328_rechts_cls-4" d="M133.25,82.99s0,0,0,0c-3.13,4.78-5.47.34-15.04,2.28-5.18,1.33-10.31.76-15.38-1.71-5.51.32-9.39-1.05-9.91-5.92-3.38-2.83-2.8-5.68.46-8.54,9.66-8.62,5.76-28.15,4.22-27.46-3.82-6.54-3.77-14.54,0-23.92,1.82-2.8,12.18-4.89,18.34-.46,6.23,4.8,9.94,9.9,9.46,15.49.33,4.22.27,7.95-.95,10.23-.5.92-.7,1.96-.7,3-.03,12.06,4.93,21.31,12.59,29.72,1.02,3.02.13,5.48-3.08,7.29h0Z"/>
    <g id="softpart">
      <path id="softpart2" class="KleinerFinger1328_rechts_cls-1" d="M17.99,254.99c5.05,52.21,12.29,83.24,22.61,84.42,10.97,2.3,15.59-10.96,16.71-32.81-2.17-36.45.96-66.2,5.47-94.22,5.89-29.64,9.06-59.51,10.03-89.58l3.34-98.13C78.31,3.99,90.18-1.24,107.92.97c19.68.24,31.52,11.52,36.89,31.94,5.97,24.73,7.61,88.26,9.72,147.6.62,43.51-3.1,90.7-9.72,140.34-.72,4.37-1,8.2-.85,11.51"/>
      <path id="softpart1" class="KleinerFinger1328_rechts_cls-2" d="M16.91,243.09c.18,2.02.35,4.02.53,5.98"/>
    </g>
    <path id="offpart2" class="KleinerFinger1328_rechts_cls-3" d="M.51,447.09c5.29,3.17,10.52,4.71,15.67,3.91,1.95-.3,3.64-1.64,4.22-3.52.64-2.11,1.94-3.89,3.84-5.37,1.57-1.23,2.72-2.93,3.03-4.89,1.19-7.6.83-15.87-.28-24.47-.21-1.62-1.04-3.1-2.33-4.11l-2.46-1.92c-1.73-1.36-3.13-3.11-4.02-5.12-5.14-11.57-8.55-26.16-10.99-42.45"/>
    <path id="Offpart1" class="KleinerFinger1328_rechts_cls-3" d="M24.05,473.75c-4.92-8.77-12.21-15.23-22.63-18.64"/>
  </g>
</g>
</svg>
  );
}

export default Rechter5FingerDpSitzend1328;
