import style from "./style.scss";

export default function LinkeRippen17VdLiegend1813(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 481.32 721">

<g id="BG">
  <path id="bg" class="Rippen_1_to_7_left1813_cls9" d="M42.92.11c-2.44,17.49-7.34,33.74-19.24,46.43-5.18,6.74-4.11,12.9,2.2,18.56l2.99,8-5.02,10.2c-3.71,9.46-5.71,17.3-5.22,22.8l8.7,7.26c.27,5.27-.87,10.52-3.48,15.74l-7.01,7.99c-3.44,9.15-4.29,17.6,2.66,23.95l.73,22.95c-3.7,3.28-6.13,8.43-7.06,15.74-2.76,12.88-1.21,21.53,5.33,25.28l1.38,16.97c-7.25,4.37-10.97,10.68-10.97,19.06,1.12,16.42,2.22,32.82-1.16,41.06l5.32,4.06c-4.02,1.96-6.58,7.45-8.5,14.48-3.48,5.95-4.77,13.02-3.17,19.72,1.68,7.05,6.02,12.05,13.32,14.74l-3.01,20c-5.57-.36-5.56,6.91-2.01,19,2.08,6.73,4.93,11.2,8.99,12.01l4.99,19,9,15-18.36,32.99,15.31,53.73-.03,56.28-.02,27.54-9.7,74.45c19.16,12.71,31.75,9.52,38.21-8.52,1.86-12.03-8.16-18.48-25.54-21.47,21.1-5.65,45.37-1.44,74.24,17.1,20.93,9.84,40.95,14.67,59.77,12.8l22.03.32,51.94,14.9,39-9.98,43.19,13.72,18.8,15.31,10-3.99,11.94-1.31,5.36,1.32,4.7-3c3.18-73.97,5.38-147.89-13.74-220.95.94-22.67-3.15-48.41-9.78-75.69,1.69-13.8,2.13-27.03.87-39.49,12.28-50.55,24.25-97.72,33.91-118.86l21.02-38.98,9.06-18.9c4.37-6.56,6.12-12.74,3.48-18.28,17.7,12.19,31.78,28.99,38.46,55.2l-9.9-168.02-3-21.99-65.96-16.04-27.91-.53c-9.13-1.89-17.68-1.7-25.16,2.35-5.7,2.77-12.4,3.91-20.87,2.21-16.64-4.75-35.29-2.19-54.85,3.67-15.56,6.62-30.22,11.73-42.43,12.72l-13.56,1.84-6.24-2.37c-10.45-5.1-20.29-3.25-29.36,7.42l-43.29-40.89c-10.86-6.32-20.69-10.18-28.49-9.19l-5.86-4.39c-7.52-1.76-15.12,2.06-22.78,10.22l-9.23,10.76,1.01-13L42.93.12h-.01Z"/>
</g>
<g id="underlays">
  <path id="underlays11" class="ribs1813Fill"  style={{
          fill: props.colors.ribs1813Fill,
          stroke: props.colors.ribs1813Fill,
        }} d="M238.58,535.34h0c-4.69-1.86-4.46-9.01-6.98-27-3.93-27.98-7.72-33.64-2.98-42,2.24-3.96,6.83-6.97,16.01-12.99,11.81-7.75,16.5-8.59,32.01-16.98,13.79-7.46,20.68-11.19,26.01-16.99,7.88-8.57,11.02-17.49,14.01-25.99,2.19-6.22,6.51-18.83,6.02-35-.56-18.74-7.27-31.89-14.97-47.01-9.68-18.99-20.52-31.51-27.98-40.02-12.81-14.61-24.85-24.54-31.98-30.02-15.1-11.59-27.7-18.17-40.99-25.02-13.61-7.02-27.32-14.09-46.99-20.03-11.65-3.51-29.33-8.71-52.99-9.03-12.36-.17-25.07,1.01-38,3.98-15.25,3.5-23.19,5.32-31.01,11.98-12.11,10.32-14.12,23.88-19.01,22.99-3.96-.72-5.51-10.1-5.99-13-.27-1.62-1.12-7.52,1.01-15,.63-2.22,4.07-14.32,15.01-20.99,4.68-2.85,5.93-1.79,17-4.99,13.39-3.87,15.52-6.56,24.01-8.99,8.3-2.37,15.98-1.94,31-.98,21.82,1.39,38.6,4.69,45,6.03,7.66,1.6,18.77,4.74,40.99,11.02,24.53,6.94,27.66,8.29,30.99,10.02,9.17,4.74,9.45,6.87,29.99,22.02,16.81,12.4,17.48,11.61,24.99,18.01,5.42,4.62,11.97,10.93,25.98,29.01,16.84,21.74,25.27,32.61,30.98,44.02,10.08,20.13,13.19,37.25,16.97,58.01,4.06,22.34,6.1,33.51,3.97,46-2.3,13.53-9.87,26.35-25.03,51.99-10.43,17.64-15.65,26.47-23.02,32.99-8.18,7.24-14.68,9.84-21.01,19.99-2.91,4.67-4.22,8.42-9.01,11.99-4.12,3.08-6.43,2.74-16,5.99-13.39,4.55-14.85,7.24-18,5.99h-.01Z"/>
  <path id="underlays10" class="ribs1813Fill"  style={{
          fill: props.colors.ribs1813Fill,
          stroke: props.colors.ribs1813Fill,
        }} d="M215.66,388.33h0c-3.01-2.13-3.34-6.75-3.99-16-.85-11.99,1.78-13.54,0-21-2.23-9.41-7.58-11.82-5.99-16,1.6-4.2,7.67-3.58,17-6.99,8.97-3.28,14.32-7.85,25.01-16.99,8.67-7.41,14.07-13.84,20.01-20.99,11.18-13.44,16.77-20.16,20.02-29.99,1.81-5.48,6.4-19.36.02-31-2.97-5.41-6.62-7.26-12.99-12.01-16.83-12.54-16.61-18.46-30.98-31.02-5.4-4.72-6.38-4.71-37.99-23.02-37.43-21.68-35.71-21.49-41.99-24.02-12.87-5.2-26.58-7.48-53.99-12.03-21.34-3.54-27.99-3.41-35-2.02-4.32.86-17.59,3.66-30.01,13.98-15.1,12.55-16.42,25.82-22.01,24.99-5.99-.89-11.66-17.17-5.99-26,3.83-5.95,9.49-3.36,21.01-9.99,11.86-6.82,11.68-12.92,23.01-19.99,8.98-5.6,17.38-6.93,24-7.99,10.73-1.71,23.55-1.5,54,8.03,17.28,5.41,36.11,11.3,59.99,24.03,24.27,12.94,40.73,26.01,51.98,35.03,16.3,13.07,26.72,23.63,36.98,34.02,26.96,27.31,31.64,38,33.97,46.02,3.38,11.57,3.12,21.81,2.99,26-.22,6.92-.78,19.86-8.02,34-6.84,13.36-16.65,21.94-22.01,25.99-7.6,5.75-10.41,5.54-17.01,11.99-7.13,6.97-6.89,10.18-14.01,17.99-6.74,7.39-13.25,11.42-19.01,14.99-10.81,6.69-22.99,14.24-29.01,9.98h.01Z"/>
  <path id="underlays9" class="ribs1813Fill"  style={{
          fill: props.colors.ribs1813Fill,
          stroke: props.colors.ribs1813Fill,
        }} d="M166.7,283.3h.02c-5.29-2.42-4.22-7.1-11.99-21.01-7.96-14.26-15.05-20.02-12.99-23.01,1.4-2.03,5.06.07,13,0,2.17-.02,7.34-.21,28-7.98,11.05-4.15,16.57-6.23,19-7.99,3.64-2.63,8.76-7.32,16.01-22.99,9.5-20.52,14.25-30.77,12.02-41.99-2.45-12.33-10.93-20.44-21.98-31.01-6.47-6.19-11.79-9.79-20.99-16.01-16.05-10.86-29.55-17.46-34.99-20.02-18.48-8.71-33.33-12.99-36.99-14.02-15.86-4.47-27.32-7.57-42-6.02-3.69.39-18.48,2.22-27.47,10.51-.56.52-1.95,1.84-3.54,3.47-5.62,5.76-7.44,8.81-12.01,13.99-5.68,6.45-8.52,9.67-10.01,8.99-2.3-1.04-1.31-10.92,3.01-19,1.16-2.18,3.02-5.06,13.01-13.99,9.55-8.53,14.32-12.8,20.01-15.99,9.79-5.48,18.56-7.36,22-7.99,3.4-.62,14.2-2.33,30,1.02,13.62,2.88,20.25,7.26,37.99,16.02,27.89,13.78,24.95,9.61,39.99,18.02,24.64,13.78,19.5,17.67,49.98,37.03,12.4,7.88,20.86,12.07,26.99,23.02,1.51,2.71,6.54,11.95,5.99,24-.3,6.67-2.21,11.78-6.01,22-3.29,8.83-4.33,9.05-7.01,17-2.77,8.19-2.03,9.05-5.01,19-3.03,10.13-4.55,15.2-8.01,20-3.89,5.39-8.32,8-17.01,12.99-9.19,5.28-12.76,6.05-32.01,13.98-19.24,7.93-22.59,10.01-27,7.98h0Z"/>
  <path id="underlays8" class="ribs1813Fill"  style={{
          fill: props.colors.ribs1813Fill,
          stroke: props.colors.ribs1813Fill,
        }} d="M138.82,157.31l-.02-.02c-6.15-.98-5.59-3.29-14-5.01-8.75-1.79-12.29.12-16-3.01-3.19-2.69-4.87-7.74-3.99-12,1.46-7.07,8.95-7.04,18.01-15.99,8.99-8.88,12.08-19.25,13.01-22.99.8-3.23,3.06-12.74,0-23-6.44-21.63-33.4-36.13-51.98-32.03-6.18,1.36-7.85,4-22,7.99-10.44,2.94-12.06,2.23-18,4.99-11.02,5.12-13.63,11.39-18.01,9.99-4.23-1.35-6.97-8.86-4.99-14,1.55-4.02,6.06-5.73,15.01-8.99,10.38-3.78,12.17-2.7,20-5.99,5.46-2.29,11.14-4.68,16.01-9.99,7.14-7.8,4.71-13.4,10.01-16.99,7.96-5.41,21.62,1.7,28,5.02,6.55,3.41,9.7,6.33,28.99,25.02,18.48,17.9,20.22,19.71,22.99,24.01,3.65,5.68,6.43,12.79,11.98,27.01,6.01,15.37,6.34,19.31,5.99,23-.47,4.9-2.65,9.6-7.01,19-4.1,8.83-6.15,13.25-9.01,15.99-6.5,6.25-16.41,9.36-25,7.99h0Z"/>
  <path id="underlays7" class="ribs1813Fill"  style={{
          fill: props.colors.ribs1813Fill,
          stroke: props.colors.ribs1813Fill,
        }} d="M241.76,700.59c-4.44-1.45-11-3.57-19-6.01-9.89-3.02-20.08-5.97-24-7.01-22.04-5.84-29.07.21-52-3.03-18.22-2.57-32.41-9.03-49.99-17.03-15.36-6.99-24.39-12.87-39.99-14.02-23.2-1.72-37.25,8.82-27.1,1.07,15.46-11.79,21.57-32.2,15.13-50.55-2.42-6.9.46,1.82,11,11.46,9.57,8.75,19.57,12.85,31.99,17.02,11.89,3.99,19.48,5.86,39.99,11.02,37.52,9.45,38.24,9.79,44.99,11.03,22.91,4.21,23.51,1.97,38,6.02,5.52,1.54,10.98,3.42,36.99,15.02,13.62,6.08,24.93,11.27,32.99,15.02"/>
  <path id="underlays6" class="ribs1813Fill"  style={{
          fill: props.colors.ribs1813Fill,
          stroke: props.colors.ribs1813Fill,
        }} d="M39.86,515.48c6.17,11.47,13.13,15.98,17.99,18.01,3.69,1.54,5.67,1.45,21,4.01,0,0,9.2,1.54,17,3.01,16.23,3.07,28.1,8.94,34.99,12.02,27.03,12.11,33.21,6.44,70.99,20.04,5.82,2.09,29.37,10.76,56.98,27.03,10.76,6.34,20.48,12.13,31.99,22.02,8.87,7.61,44.04,68.53,54.15,79.38,4.42,4.75,3.03,11.17-2.47,18.37-.92-.05-1.84-.09-2.76-.13h0c-2.78-3.07-5.3-6.36-7.84-9.63-5.7-7.35-11.31-14.8-17.66-21.62-2.95-3.16-6.06-6.16-9.52-8.77-1.31-.99-2.7-1.93-4.16-2.72-.25-.3-.51-.59-.77-.88-3.13-3.35-13.79-14.5-31.98-27.02-14.65-10.08-27.96-16.2-53.98-28.03-19.35-8.8-33.12-15.05-52.99-22.03-7.77-2.73-23.49-7.56-54.99-17.03-5.44-1.63-11.14-3.31-19-4.01-9.58-.85-11.16.75-22-.01-11.39-.81-13.13-2.27-20-2.01-3.98.15-9.88,1.39-17,6.99l12.03-66.99h0Z"/>
  <path id="underlays5" class="ribs1813Fill"  style={{
          fill: props.colors.ribs1813Fill,
          stroke: props.colors.ribs1813Fill,
        }} d="M351.75,717.65c-2.46-8.37-6.09-20.12-10.98-34.01-11.86-33.67-23.18-57.27-26.96-65.02-24.01-49.13-37.92-59.53-42.96-63.02-2.78-1.93-9.89-5.31-23.99-12.01-38.11-18.1-57.17-27.15-68.98-31.04-25.98-8.54-47.16-11.31-67.99-14.04-13.18-1.72-21.41-2.2-30-2.02-44.97.97-68.99,19.66-48.13,4.41-1.11-105.61-7.63-52.17,13.14-46.42,5.97,1.65,9.62-1.91,17-1.99,9.05-.1,14.6,5.16,20.99,9.01,10.04,6.03,17.47,5.77,41,8.02,14.08,1.34,40.99,3.91,62,8.03,12.31,2.42,36.97,7.44,64.99,22.04,13.63,7.1,32.71,17.25,50.98,37.03,18.89,20.45,26.85,40.29,37.96,68.02,4.27,10.65,13.26,34.7,18.96,67.01,3.19,18.13,4.48,33.86,4.97,46"/>
  <path id="underlays4" class="ribs1813Fill"  style={{
          fill: props.colors.ribs1813Fill,
          stroke: props.colors.ribs1813Fill,
        }} d="M23.91,423.47c11.46-5.03,28.96-11.35,51.01-13.97,20.72-2.47,36.43-.33,67,4.04,44.09,6.3,69.68,15.07,77.99,18.04,30.58,10.93,40.56,19.51,45.99,25.03,6.72,6.84,11.48,14.56,20.98,30.01,5.57,9.06,13.23,21.49,20.98,38.01,8.97,19.12,13.63,35.08,22.96,67.01.37,1.26-.03-.11,12.97,50.01,5.2,20.04,7.11,27.35,6.98,38,0,.66-.02,1.32-.05,1.98-.1-6.11,4.09-35.97,4.09-51.99,0-17.57,0-29.96-1.97-46-2.38-19.32-6.34-33.62-8.98-43.01-6.09-21.65-12.82-36.95-19.97-53.01-9.03-20.29-13.73-30.68-22.98-43.01-5.24-6.98-12.3-16.25-23.99-25.01-4.81-3.6-14-9.82-41.99-19.02-12.6-4.14-32.44-10.57-59.99-15.03-5.07-.82-28.62-4.53-59-5.03-20.18-.34-33.96.9-50-4.03-2.69-.83-9.39-3.04-19-5.01-8.82-1.81-23.37,1.38-28.21.98"/>
  <path id="underlays3" class="ribs1813Fill"  style={{
          fill: props.colors.ribs1813Fill,
          stroke: props.colors.ribs1813Fill,
        }} d="M368.05,715.66c8.17-306-143.05-455.08-354.09-360.2-2.71-1.1-7.78-3.64-10.99-9.01-5.04-8.41-1.99-17.85,0-24,1.9-5.87,4.32-13.34,11.01-16.99,5.69-3.11,7.98.1,19-1.99,10.03-1.9,10.12-4.94,19-5.99,7.35-.87,8.84,1.01,18,1.01s13.52-1.88,20-2.99c11.51-1.97,19.82.11,34,3.02,43.63,8.94,39.9,4.58,67.99,12.04,25.38,6.73,42.08,13.92,48.99,17.03,12.75,5.74,19.97,10.12,22.99,12.01,14.53,9.09,24.23,18.3,27.99,22.02,10.92,10.8,22.41,22.16,28.98,40.02,2.03,5.52.32,2.76,6.98,33,4.37,19.85,5.29,21.84,5.98,29,.35,3.59.87,10.83-2.02,35-3.02,25.21-4.52,37.82-8.03,46-2.72,6.35-8.82,15.23-21.02,32.99-7.96,11.59-11.95,17.39-15.01,20.99-7.48,8.78-21.55,21.55-48.02,29.97"/>
  <path id="underlays2" class="ribs1813Fill"  style={{
          fill: props.colors.ribs1813Fill,
          stroke: props.colors.ribs1813Fill,
        }} d="M373.75,710.66c1.46-25.23,1.92-46.39,2.03-62,.22-29.37.32-53.27-2.95-85-1.1-10.64-2.81-26.87-6.97-48-7.1-36.09-16.81-63.5-21.96-77.01-8.11-21.3-18.06-46.99-37.96-77.02-18.93-28.57-37.23-46.53-42.97-52.02-12.29-11.75-26.13-24.79-47.98-36.03-13.1-6.73-22.91-9.65-40.99-15.02-28.69-8.53-47.45-14.1-72.99-13.04-9.3.39-25.6,1.21-44.01,8.98-12.08,5.1-21.14,11.53-26.01,14.99-6.47,4.59-9.71,6.89-13.01,10.99-3.4,4.22-4.53,7.28-6,7-1.1-.21-1.72-2.14-2.99-18-.8-9.92-.79-12.03,0-15,.56-2.08,1.87-6.99,6.01-11,4.46-4.32,6.99-2.53,14-6.99,7.19-4.57,7.03-8.03,12.01-8.99,5.18-1.01,6.29,2.55,12,2.01,6.63-.64,7.3-5.64,15-7.99,5.01-1.53,6,.2,15,0,10.16-.21,11.89-2.48,20-2.99,7.91-.5,11.54,1.33,23,4.01,14.59,3.42,17.81,2.59,31,5.02,16.59,3.05,27.99,7.4,47.99,15.03,21.95,8.37,32.93,12.56,44.99,20.03,18.77,11.62,31.91,24.74,39.98,34.02,8.75,10.06,13.97,18.4,19.98,28.01,5.11,8.16,14.29,23.75,22.97,45.01,3.06,7.49,5.03,13.33,8.99,25.01,7.51,22.2,11.27,33.3,13.97,47.01,3.16,16.05,3.53,28.05,3.98,43,.41,13.5.88,29.3-2.03,50-1.65,11.77-4.26,29.47-13.03,50.99-1.78,4.37-7.48,17.91-18.02,33.99-11.19,17.08-23.37,30.22-34.02,39.98"/>
  <ellipse id="underlays1" class="ribs1813Fill"  style={{
          fill: props.colors.ribs1813Fill,
          stroke: props.colors.ribs1813Fill,
        }} cx="339.96" cy="493.48" rx="21" ry="62.5" transform="translate(-69.82 56.24) rotate(-8.58)"/>
  <path id="softpart" class="soft1813Fill"  style={{
          fill: props.colors.soft1813Fill,
          stroke: props.colors.soft1813Fill,
        }} d="M395,721c3.5.06,6.9-37.91,8-53,.99-13.64.97-20.49,2-61,.9-35.4.93-30.38,1-37,.58-53.7-4.51-65.71-1-104,2.35-25.7,6.18-37.04,9-44,5.94-14.67,11.39-19.71,25-39,24.45-34.65,37.05-52.51,41-77,1.8-11.14-.36-10.26-2-44-1.31-26.96-.83-45.8,0-78,.84-32.66-3.86-65.86,2-98,.67-3.66,4.47-23.94-5-42-.75-1.43-5.1-9.72-8-9-3.4.84-3.13,13.58-3,20,.2,9.82,1.5,13.14,2,22,.57,10.11-.47,17.27-1,22-1.97,17.77-.1,32.47,0,77,.15,67.18-4.05,66.67,0,87,1.03,5.18,3.33,15.4,2,29-1.46,14.88-6.53,26.32-11,35-14.3,27.79-24.08,30.62-39,54-22.97,35.99-26.89,71.72-28,83-.63,6.4-.32,6.77,0,36,.32,28.4.78,69.89,0,112-.49,26.3-1.19,38.83,0,62,.37,7.17,2.42,46.94,6,47Z"/>
</g>
<g id="Rippen">
  <path id="Rippen41" class="Rippen_1_to_7_left1813_cls6" d="M238.63,534.22h0c-4.69-1.86-4.46-9.01-6.98-27-3.93-27.98-7.72-33.64-2.98-42,2.24-3.96,6.83-6.97,16.01-12.99,11.81-7.75,16.5-8.59,32.01-16.98,13.79-7.46,20.68-11.19,26.01-16.99,7.88-8.57,11.02-17.49,14.01-25.99,2.19-6.22,6.51-18.83,6.02-35-.56-18.74-7.27-31.89-14.97-47.01-9.68-18.99-20.52-31.51-27.98-40.02-12.81-14.61-24.85-24.54-31.98-30.02-15.1-11.59-27.7-18.17-40.99-25.02-13.61-7.02-27.32-14.09-46.99-20.03-11.65-3.51-29.33-8.71-52.99-9.03-12.36-.17-25.07,1.01-38,3.98-15.25,3.5-23.19,5.32-31.01,11.98-12.11,10.32-14.12,23.88-19.01,22.99-3.96-.72-5.51-10.1-5.99-13-.27-1.62-1.12-7.52,1.01-15,.63-2.22,4.07-14.32,15.01-20.99,4.68-2.85,5.93-1.79,17-4.99,13.39-3.87,15.52-6.56,24.01-8.99,8.3-2.37,15.98-1.94,31-.98,21.82,1.39,38.6,4.69,45,6.03,7.66,1.6,18.77,4.74,40.99,11.02,24.53,6.94,27.66,8.29,30.99,10.02,9.17,4.74,9.45,6.87,29.99,22.02,16.81,12.4,17.48,11.61,24.99,18.01,5.42,4.62,11.97,10.93,25.98,29.01,16.84,21.74,25.27,32.61,30.98,44.02,10.08,20.13,13.19,37.25,16.97,58.01,4.06,22.34,6.1,33.51,3.97,46-2.3,13.53-9.87,26.35-25.03,51.99-10.43,17.64-15.65,26.47-23.02,32.99-8.18,7.24-14.68,9.84-21.01,19.99-2.91,4.67-4.22,8.42-9.01,11.99-4.12,3.08-6.43,2.74-16,5.99-13.39,4.55-14.85,7.24-18,5.99h-.01Z"/>
  <path id="Rippen40" class="Rippen_1_to_7_left1813_cls6" d="M215.71,387.21h0c-3.01-2.13-3.34-6.75-3.99-16-.85-11.99,1.78-13.54,0-21-2.23-9.41-7.58-11.82-5.99-16,1.6-4.2,7.67-3.58,17-6.99,8.97-3.28,14.32-7.85,25.01-16.99,8.67-7.41,14.07-13.84,20.01-20.99,11.18-13.44,16.77-20.16,20.02-29.99,1.81-5.48,6.4-19.36.02-31-2.97-5.41-6.62-7.26-12.99-12.01-16.83-12.54-16.61-18.46-30.98-31.02-5.4-4.72-6.38-4.71-37.99-23.02-37.43-21.68-35.71-21.49-41.99-24.02-12.87-5.2-26.58-7.48-53.99-12.03-21.34-3.54-27.99-3.41-35-2.02-4.32.86-17.59,3.66-30.01,13.98-15.1,12.55-16.42,25.82-22.01,24.99-5.99-.89-11.66-17.17-5.99-26,3.83-5.95,9.49-3.36,21.01-9.99,11.86-6.82,11.68-12.92,23.01-19.99,8.98-5.6,17.38-6.93,24-7.99,10.73-1.71,23.55-1.5,54,8.03,17.28,5.41,36.11,11.3,59.99,24.03,24.27,12.94,40.73,26.01,51.98,35.03,16.3,13.07,26.72,23.63,36.98,34.02,26.96,27.31,31.64,38,33.97,46.02,3.38,11.57,3.12,21.81,2.99,26-.22,6.92-.78,19.86-8.02,34-6.84,13.36-16.65,21.94-22.01,25.99-7.6,5.75-10.41,5.54-17.01,11.99-7.13,6.97-6.89,10.18-14.01,17.99-6.74,7.39-13.25,11.42-19.01,14.99-10.81,6.69-22.99,14.24-29.01,9.98h.01Z"/>
  <path id="Rippen39" class="Rippen_1_to_7_left1813_cls6" d="M166.75,282.18h.02c-5.29-2.42-4.22-7.1-11.99-21.01-7.96-14.26-15.05-20.02-12.99-23.01,1.4-2.03,5.06.07,13,0,2.17-.02,7.34-.21,28-7.98,11.05-4.15,16.57-6.23,19-7.99,3.64-2.63,8.76-7.32,16.01-22.99,9.5-20.52,14.25-30.77,12.02-41.99-2.45-12.33-10.93-20.44-21.98-31.01-6.47-6.19-11.79-9.79-20.99-16.01-16.05-10.86-29.55-17.46-34.99-20.02-18.48-8.71-33.33-12.99-36.99-14.02-15.86-4.47-27.32-7.57-42-6.02-3.69.39-18.48,2.22-27.47,10.51-.56.52-1.95,1.84-3.54,3.47-5.62,5.76-7.44,8.81-12.01,13.99-5.68,6.45-8.52,9.67-10.01,8.99-2.3-1.04-1.31-10.92,3.01-19,1.16-2.18,3.02-5.06,13.01-13.99,9.55-8.53,14.32-12.8,20.01-15.99,9.79-5.48,18.56-7.36,22-7.99,3.4-.62,14.2-2.33,30,1.02,13.62,2.88,20.25,7.26,37.99,16.02,27.89,13.78,24.95,9.61,39.99,18.02,24.64,13.78,19.5,17.67,49.98,37.03,12.4,7.88,20.86,12.07,26.99,23.02,1.51,2.71,6.54,11.95,5.99,24-.3,6.67-2.21,11.78-6.01,22-3.29,8.83-4.33,9.05-7.01,17-2.77,8.19-2.03,9.05-5.01,19-3.03,10.13-4.55,15.2-8.01,20-3.89,5.39-8.32,8-17.01,12.99-9.19,5.28-12.76,6.05-32.01,13.98-19.24,7.93-22.59,10.01-27,7.98h0Z"/>
  <path id="Rippen38" class="Rippen_1_to_7_left1813_cls1" d="M138.87,156.19l-.02-.02c-6.15-.98-5.59-3.29-14-5.01-8.75-1.79-12.29.12-16-3.01-3.19-2.69-4.87-7.74-3.99-12,1.46-7.07,8.95-7.04,18.01-15.99,8.99-8.88,12.08-19.25,13.01-22.99.8-3.23,3.06-12.74,0-23-6.44-21.63-33.4-36.13-51.98-32.03-6.18,1.36-7.85,4-22,7.99-10.44,2.94-12.06,2.23-18,4.99-11.02,5.12-13.63,11.39-18.01,9.99-4.23-1.35-6.97-8.86-4.99-14,1.55-4.02,6.06-5.73,15.01-8.99,10.38-3.78,12.17-2.7,20-5.99,5.46-2.29,11.14-4.68,16.01-9.99,7.14-7.8,4.71-13.4,10.01-16.99,7.96-5.41,21.62,1.7,28,5.02,6.55,3.41,9.7,6.33,28.99,25.02,18.48,17.9,20.22,19.71,22.99,24.01,3.65,5.68,6.43,12.79,11.98,27.01,6.01,15.37,6.34,19.31,5.99,23-.47,4.9-2.65,9.6-7.01,19-4.1,8.83-6.15,13.25-9.01,15.99-6.5,6.25-16.41,9.36-25,7.99h0Z"/>
  <path id="Rippen37" class="Rippen_1_to_7_left1813_cls3" d="M293.58,630.26c-42.36-41.89-145.33-53.75-217.99-17.12"/>
  <path id="Rippen36" class="Rippen_1_to_7_left1813_cls3" d="M240.54,700.23c-4.44-1.45-11-3.57-19-6.01-9.89-3.02-20.08-5.97-24-7.01-22.04-5.84-29.07.21-52-3.03-18.22-2.57-32.41-9.03-49.99-17.03-15.36-6.99-24.39-12.87-39.99-14.02-9.58-.71-17.6.67-23,1.99"/>
  <path id="Rippen35" class="Rippen_1_to_7_left1813_cls3" d="M279.54,690.25c-8.06-3.75-19.37-8.94-32.99-15.02-26.01-11.6-31.47-13.48-36.99-15.02-14.49-4.05-15.09-1.81-38-6.02-6.75-1.24-7.47-1.58-44.99-11.03-20.51-5.16-28.1-7.03-39.99-11.02-12.42-4.17-22.42-8.27-31.99-17.02-4.12-3.77-7.07-7.4-8.99-10.01"/>
  <path id="Rippen34" class="Rippen_1_to_7_left1813_cls3" d="M323.53,705.27c-4.79-7.89-11.89-18.21-21.98-29.01-3.13-3.35-13.79-14.5-31.98-27.02-14.65-10.08-27.96-16.2-53.98-28.03-19.35-8.8-33.12-15.05-52.99-22.03-7.77-2.73-23.49-7.56-54.99-17.03-5.44-1.63-11.14-3.31-19-4.01-9.58-.85-11.16.75-22-.01-11.39-.81-13.13-2.27-20-2.01-3.98.15-9.88,1.39-17,6.99"/>
  <path id="Rippen33" class="Rippen_1_to_7_left1813_cls3" d="M335.56,667.28c-3.98-4.63-9.77-11.25-16.99-19.01-10.1-10.85-17.12-18.4-25.99-26.01-11.51-9.89-21.23-15.68-31.99-22.02-27.61-16.27-51.16-24.94-56.98-27.03-37.78-13.6-43.96-7.93-70.99-20.04-6.89-3.08-18.76-8.95-34.99-12.02-7.8-1.47-17-3.01-17-3.01-15.33-2.56-17.31-2.47-21-4.01-4.86-2.03-11.82-6.54-17.99-18.01"/>
  <path id="Rippen32" class="Rippen_1_to_7_left1813_cls1" d="M351.53,715.29c-2.46-8.37-6.09-20.12-10.98-34.01-11.86-33.67-23.18-57.27-26.96-65.02-24.01-49.13-37.92-59.53-42.96-63.02-2.78-1.93-9.89-5.31-23.99-12.01-38.11-18.1-57.17-27.15-68.98-31.04-25.98-8.54-47.16-11.31-67.99-14.04-13.18-1.72-21.41-2.2-30-2.02-17.16.37-31.27,3.32-41,5.98"/>
  <path id="Rippen31" class="Rippen_1_to_7_left1813_cls1" d="M363.53,715.29c-.49-12.14-1.78-27.87-4.97-46-5.7-32.31-14.69-56.36-18.96-67.01-11.11-27.73-19.07-47.57-37.96-68.02-18.27-19.78-37.35-29.93-50.98-37.03-28.02-14.6-52.68-19.62-64.99-22.04-21.01-4.12-47.92-6.69-62-8.03-23.53-2.25-30.96-1.99-41-8.02-6.39-3.85-11.94-9.11-20.99-9.01-7.38.08-11.03,3.64-17,1.99-6.03-1.67-9.76-7.36-11.99-12.01"/>
  <path id="Rippen30" class="Rippen_1_to_7_left1813_cls1" d="M342.84,716.39c7.1-13.68,9.1-29.68,6.1-45.68-6-41-18-78-30-118-1-3-3-5-4-8-1-2,0-3-1-5-11-25-24-48-40-72-6-8-12-14-20-19-17-11-35-17-54-22-37-9-73-17-111-17-22,1-44,7-65.25,15.4"/>
  <path id="Rippen29" class="Rippen_1_to_7_left1813_cls1" d="M18.51,374.1c4.84.4,19.39-2.79,28.21-.98,9.61,1.97,16.31,4.18,19,5.01,16.04,4.93,29.82,3.69,50,4.03,30.38.5,53.93,4.21,59,5.03,27.55,4.46,47.39,10.89,59.99,15.03,27.99,9.2,37.18,15.42,41.99,19.02,11.69,8.76,18.75,18.03,23.99,25.01,9.25,12.33,13.95,22.72,22.98,43.01,7.15,16.06,13.88,31.36,19.97,53.01,2.64,9.39,6.6,23.69,8.98,43.01,1.97,16.04,1.97,28.43,1.97,46,0,14.69-3.52,41.02-4.03,50"/>
  <path id="Rippen28" class="Rippen_1_to_7_left1813_cls1" d="M14.74,355.1c-2.71-1.1-7.78-3.64-10.99-9.01-5.04-8.41-1.99-17.85,0-24,1.9-5.87,4.32-13.34,11.01-16.99,5.69-3.11,7.98.1,19-1.99,10.03-1.9,10.12-4.94,19-5.99,7.35-.87,8.84,1.01,18,1.01s13.52-1.88,20-2.99c11.51-1.97,19.82.11,34,3.02,43.63,8.94,39.9,4.58,67.99,12.04,25.38,6.73,42.08,13.92,48.99,17.03,12.75,5.74,19.97,10.12,22.99,12.01,14.53,9.09,24.23,18.3,27.99,22.02,10.92,10.8,22.41,22.16,28.98,40.02,2.03,5.52.32,2.76,6.98,33,4.37,19.85,5.29,21.84,5.98,29,.35,3.59.87,10.83-2.02,35-3.02,25.21-4.52,37.82-8.03,46-2.72,6.35-8.82,15.23-21.02,32.99-7.96,11.59-11.95,17.39-15.01,20.99-7.48,8.78-21.55,21.55-48.02,29.97"/>
  <path id="Rippen27" class="Rippen_1_to_7_left1813_cls1" d="M14.73,355.1c211.05-94.88,362.27,54.2,354.1,360.2"/>
  <path id="Rippen26" class="Rippen_1_to_7_left1813_cls1" d="M373.53,712.3c1.46-25.23,1.92-46.39,2.03-62,.22-29.37.32-53.27-2.95-85-1.1-10.64-2.81-26.87-6.97-48-7.1-36.09-16.81-63.5-21.96-77.01-8.11-21.3-18.06-46.99-37.96-77.02-18.93-28.57-37.23-46.53-42.97-52.02-12.29-11.75-26.13-24.79-47.98-36.03-13.1-6.73-22.91-9.65-40.99-15.02-28.69-8.53-47.45-14.1-72.99-13.04-9.3.39-25.6,1.21-44.01,8.98-12.08,5.1-21.14,11.53-26.01,14.99-6.47,4.59-9.71,6.89-13.01,10.99-3.4,4.22-4.53,7.28-6,7-1.1-.21-1.72-2.14-2.99-18-.8-9.92-.79-12.03,0-15,.56-2.08,1.87-6.99,6.01-11,4.46-4.32,6.99-2.53,14-6.99,7.19-4.57,7.03-8.03,12.01-8.99,5.18-1.01,6.29,2.55,12,2.01,6.63-.64,7.3-5.64,15-7.99,5.01-1.53,6,.2,15,0,10.16-.21,11.89-2.48,20-2.99,7.91-.5,11.54,1.33,23,4.01,14.59,3.42,17.81,2.59,31,5.02,16.59,3.05,27.99,7.4,47.99,15.03,21.95,8.37,32.93,12.56,44.99,20.03,18.77,11.62,31.91,24.74,39.98,34.02,8.75,10.06,13.97,18.4,19.98,28.01,5.11,8.16,14.29,23.75,22.97,45.01,3.06,7.49,5.03,13.33,8.99,25.01,7.51,22.2,11.27,33.3,13.97,47.01,3.16,16.05,3.53,28.05,3.98,43,.41,13.5.88,29.3-2.03,50-1.65,11.77-4.26,29.47-13.03,50.99-1.78,4.37-7.48,17.91-18.02,33.99-11.19,17.08-23.37,30.22-34.02,39.98"/>
  <path id="Rippen25" class="Rippen_1_to_7_left1813_cls3" d="M88.62,607.21c3.57-7,11.16-25.26,14.99-38.06,6.03-20.16,7.09-36.69,8.03-53,.94-16.27,1.41-24.41.02-36-3.13-26.08-12.64-44.83-15.97-51.01-5.33-9.88-5.81-7.83-28.98-39.02-20.84-28.05-23.99-34.47-25.98-39.01-1.94-4.44-8.36-19.87-9.98-41.01-.66-8.6.57-25.4,3.03-59,.6-8.14,1.45-19.26-4.98-30-3.16-5.27-7.09-8.81-9.99-11.01"/>
  <path id="Rippen24" class="Rippen_1_to_7_left1813_cls4" d="M316.26,472.95c-2.27,2.1-8.21,20.76-11.61,25.33-5.02,6.74-6.46,11.13-10.01,17.99-3.23,6.23-2.99,4.44-16.01,23.99-10.64,15.98-11.13,17.67-15.01,19.99-9.53,5.7-20.46,3.78-25,2.99-3.55-.62-12.41-2.29-19.99-9.01-7.8-6.91-9.87-15.27-12.99-26.01-4.11-14.17-9.55-27.93-13.98-42.01-4.01-12.77-10.51-33.32-19.97-57.01-6.11-15.31-6.47-14.42-12.98-31.01-8-20.39-12.01-30.59-14.98-44.01-.91-4.09-1.59-7.84-4.98-40-1.76-16.65-2.89-28.08-3.98-39-5.31-53.04-6.19-58.4-9.96-67.01-6.44-14.69-10.42-14.02-16.98-31.01-3.76-9.72-9.1-23.54-7.98-40,.24-3.46,3.22-38.38,25.03-49.99,6.39-3.4,13.44-4.29,20.01-11.99,3.34-3.92,3.56-6.09,9.01-12.99,5.24-6.64,7.86-9.96,12.01-11.99,7.71-3.79,15.99-1.47,21,0,9.22,2.71,13.9,7.09,21.99,13.01,17.19,12.57,32.97,19.81,39.99,23.02,14.17,6.5,16.86,5.68,27.99,12.02,10.01,5.7,17.37,11.79,21.99,16.01"/>
  <path id="Rippen23" class="Rippen_1_to_7_left1813_cls2" d="M305.82,194.26c12.35-13.3,13.37-21.74,12.02-26.99-1.21-4.7-4.09-5.91-6.99-13-3.46-8.45-1.66-12.33-2.99-21-2.79-18.26-13.56-19.54-12.98-33.01.32-7.58,4.08-15.29,10.01-19.99,7.31-5.8,15.91-5.42,26-4.99,10.79.47,18.7,3.32,26,6.01,11.42,4.22,18.12,6.69,23.99,13.01,4.91,5.29,3.98,7.6,10.99,24.01,5.42,12.69,5.96,11.26,14.98,31.01,0,0,3.64,7.96,9.98,27.01,2.12,6.35,3.44,10.82,1.99,16-1.2,4.28-3.22,5.25-7.01,12-2.83,5.04-2.83,6.52-6.01,14-2.98,7-5.53,11.72-10.01,19.99-6.59,12.17-7.67,12.54-11.01,18.99-2.7,5.21-3.99,8.83-11.02,33.99-4.64,16.59-6.96,24.89-9.02,32.99-3.27,12.86-12.48,45.25-14.58,54.93"/>
  <path id="Rippen22" class="Rippen_1_to_7_left1813_cls4" d="M443.9,46.35c-3.26.96-8.5,2.43-15,3.99-22.8,5.47-35.1,8.43-50,5.97-9.85-1.62-9.5-3.74-20-5.01-9.65-1.17-16.67-.19-30,1.98-31.81,5.19-47.88,7.87-56.01,11.97-8.91,4.49-10.73,7.23-22.01,11.99-5.56,2.35-14.7,6.13-27,7.98-9.88,1.49-11.13.12-21,1.99-6.9,1.31-11.04,2.87-20,5.99-9.96,3.46-20.03,6.58-30.01,9.98-37.23,12.71-36.79,12.29-40.01,13.98-14.23,7.46-13.01,10.5-30.01,18.98-11.69,5.83-13.94,5.24-23.01,10.99-11.25,7.13-14.77,12.49-19.01,10.99-5.41-1.91-7.15-13.25-6.99-20,.2-8.42,3.41-11.71,1.01-17-2.42-5.34-6.95-4.78-7.99-9-1.52-6.16,6.24-14.99,15.01-17.99,6.03-2.07,9.34-.28,17,0,10.03.38,17.02-2.2,30-6.98,18.12-6.68,21.05-11.2,36.01-17.98,11.34-5.14,23.9-7.75,49.01-12.97,35.11-7.3,31.9-3.77,49.01-8.97,18.8-5.72,27.29-11.38,47.01-13.97,8.35-1.1,14.53-1.91,23-.99,10.81,1.17,13.58,3.89,22,3.01,9.04-.94,8.98-4.39,19-5.99,7.41-1.18,9.98.29,23,1.01,5.1.27,12.28.46,21,0"/>
  <path id="Rippen21" class="Rippen_1_to_7_left1813_cls2" d="M456.8,236.35c-2.17-7.21-6.2-17.84-13.98-29.01-5.47-7.85-10.76-13.03-18.99-21.01-18.47-17.91-20.55-15.51-26.99-24.02-14.04-18.56-12.35-40.92-11.97-45.01.69-7.47,2.27-24.42,15.02-34.99,6.34-5.25,13.43-7.27,23.01-9.99,9.69-2.75,18.09-3.67,24-3.99"/>
  <path id="Rippen20" class="Rippen_1_to_7_left1813_cls2" d="M363.85,136.29c.57-.67,12.94-21.16,48.02-29.97"/>
  <path id="Rippen19" class="Rippen_1_to_7_left1813_cls2" d="M375.96,89.99c-.03,57.66,16.01,104.32,35.86,104.33"/>
  <path id="Rippen18" class="Rippen_1_to_7_left1813_cls2" d="M350.87,116.29c0,7.19-11.61,12.99-25.95,12.99"/>
  <path id="Rippen17" class="Rippen_1_to_7_left1813_cls2" d="M147.84,152.17c0-10.5,71.57-18.96,160.01-18.91"/>
  <path id="Rippen16" class="Rippen_1_to_7_left1813_cls3" d="M296.01,93.66c-14.09,17.44-37.39,44.33-68.16,49.06"/>
  <path id="Rippen15" class="Rippen_1_to_7_left1813_cls2" d="M323.86,116.27c-6.51-9.79,15.74-11.63,53.41,1.98"/>
  <path id="Rippen14" class="Rippen_1_to_7_left1813_cls2" d="M350.59,79.06c22.63,10.53,35.4,27.65,26.69,39.2"/>
  <path id="Rippen12" class="Rippen_1_to_7_left1813_cls3" d="M42.93.12c2,3.67,4,7.34,5.99,11h0c-2.22,9.98-1.89,12.66-1.01,13,1.74.67,4.78-8.05,14.01-14.99,4.26-3.21,11.17-8.41,18-5.99,5.18,1.83,8.91,7.55,8.99,13.01.08,5.05-2.98,8.48-7.01,13-5.06,5.67-10.52,8.31-12.01,8.99-5.55,2.55-7.69,1.78-14,3.99-5.6,1.97-9.93,4.69-13,6.99"/>
  <path id="Rippen11" class="Rippen_1_to_7_left1813_cls3" d="M61.15,577.63c10,29.44-7.19,40.77-31.56,33.02"/>
  <path id="Rippen10" class="Rippen_1_to_7_left1813_cls3" d="M32.56,655.11c51.64,6.67,14.19,57.01-12.67,29.99"/>
  <path id="Rippen9" class="Rippen_1_to_7_left1813_cls3" d="M14.33,473.1c61.35-18.49,66.31,53.76,15.31,53.73"/>
  <path id="Rippen8" class="Rippen_1_to_7_left1813_cls3" d="M41.68,438.12c2.79-.31,9.63-1.48,15-6.99,7.77-7.96,9.97-22.44,3.02-30-6.4-6.95-18.68-6.04-26-3.01-7.86,3.25-10.05,8.95-15,7.99-5.69-1.1-8.29-9.69-8.99-12.01-.61-2-6.7-17.72,2.01-19,6.79-1,7.82-2.92,14.01-8.99,5.21-5.12,3.65-7.83,8.01-11,6.86-4.98,12.93.15,18-3.99,5.97-4.87,5.91-18.78-.98-27-1.03-1.23-5.93-6.76-13-7.01-4.39-.16-6.87,1.82-16,6.99-4.25,2.41-9.27,5.12-15,7.99"/>
  <path id="Rippen7" class="Rippen_1_to_7_left1813_cls3" d="M7.76,302.1c2.82-4.88,7.47-11.27,15.01-15.99,9.64-6.04,14.49-3.41,21.01-8.99,9.5-8.13,13.21-25.71,6.02-34-3.98-4.58-10.12-4.96-11-5.01-6.63-.32-26.71,17.54-30.6,24"/>
  <line id="Rippen6" class="Rippen_1_to_7_left1813_cls3" x1="30.65" y1="288.11" x2="53.54" y2="282.84"/>
  <path id="Rippen5" class="Rippen_1_to_7_left1813_cls5" d="M33.81,216.11c9.51-2.52,13.31-6.64,15.01-9.99,0,0,1.74-3.44,1.01-21h0c-2.26-4.81-2.19-7.7-7-7.09-5.82.74-29.48,12.46-29.01,19.07"/>
  <path id="Rippen4" class="Rippen_1_to_7_left1813_cls5" d="M18.85,141.95c0,26.08.58,47.19,1.32,47.19"/>
  <path id="Rippen3" class="Rippen_1_to_7_left1813_cls5" d="M23.86,129.11c45.7-40.34,55.94-11.02,18.32,23.01"/>
  <path id="Rippen2" class="Rippen_1_to_7_left1813_cls5" d="M34.87,106.11c6.71-12.01,14.25-17.35,20.01-19.99,8.14-3.74,14.24-2.78,19-7.99,5.32-5.82,4.46-14.42,4.01-20-.24-3.02-1.49-18.44-7.99-20-5.48-1.32-9.78,8.39-26.01,22.99-5.82,5.24-11.09,9.22-15.01,11.99"/>
  <line id="Rippen1" class="Rippen_1_to_7_left1813_cls5" x1="64.11" y1="21.13" x2="55.91" y2="36.12"/>
  <path id="soft" class="Rippen_1_to_7_left1813_cls2" d="M468,48c12,22,1.07,40.51,1,68-.16,61.19,2.13,86.7,1,139-.26,11.97,8.74,42.2-3,67-1.3,2.75-4.4,8.75-8.26,15.35-7.56,12.93-12.21,18.21-23.02,32.99,0,0-27.47,37.54-35.29,61.52-.4,1.23-.75,2.46-.75,2.46s-.76,2.63-1.31,5.34c-4.55,22.1-1.72,44.66-1.72,44.66.26,2.08.15,34.72-.06,100-.1,29.88-.56,54.73-1.05,81-.34,18.52-.71,34.47-1.03,47"/>
</g>
</svg>
  );
}
