import {
  SET_OPENED_PATIENT,
  REMOVE_OPENED_PATIENT,
  CLEAR_OPENED_PATIENT, SET_WEBVIEWER_AVAILABLE,
} from "../actionTypes";

const initialState = {
  openedPatients: [],
  viewerAvailable: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_OPENED_PATIENT: {
      let copyList = state.openedPatients.slice();
      const { content } = action.payload;
      copyList.push(content);
      copyList = [...new Set(copyList)];
      return {
        ...state,
        openedPatients: copyList,
      };
    }
    case REMOVE_OPENED_PATIENT: {
      let copyList = state.openedPatients.slice();
      const { content } = action.payload;
      copyList = copyList.filter((value) => value !== content);
      copyList = [...new Set(copyList)];
      return {
        ...state,
        openedPatients: copyList,
      };
    }
    case CLEAR_OPENED_PATIENT: {
      let copyList = [];
      return {
        ...state,
        openedPatients: copyList,
      };
    }
    case SET_WEBVIEWER_AVAILABLE: {
      return {
        ...state,
        viewerAvailable: action.payload,
      };
    }
    default:
      return state;
  }
}
