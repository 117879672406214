import style from "./style.scss";

function ThoracolumbalerUbergangVdLiegend1531(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 274.77 371.85">

<g id="Junctiothoracolumbalisvd">
  <path id="ribs9" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M20.49,70.63c4.09-3.86,7.36-4.16,15.04-11.39,4.24-4,4.71-4.25,8.81-8.14.21-.2.44-.38.67-.54,9.09-6.22,12.78-9.19,16.5-11.38,5.31-3.11,10.89-5.37,15.72-6.84,3.91-1.19,8.33-.93,13.13-1.12.35-.01.72,0,1.07.07,4.07.66,9.04.37,16.1,4.25,5.34,1.76,8.87,2.68,10.94.91,1.57-1.35,3.9-2.24,3.19-5.01-.93-3.65-.09,4.3,3.21-1.7.07-.13,5.06,2.69,2.72,3.57,2.21.73-.03-.48,0,0,.02.23-.07-3.71,0-3.24,0,0,.1,4.81.46,5.47.99,1.83,10.24,4.09,13.67,0,3.11-3.71-.81-9.94,1.93-11.67,1.6-1.01,4.65.04,5.36,1.65.58,1.29-.71,2.21-.91,5.01-.08,1.13-.24,3.46.91,5.01,2.41,3.24,10.08,2.74,12.3-.46.65-.93.67-1.85,1.82-2.73,1.06-.81,2.64-1.27,4.1-.91,1.41.34,1.55,1.23,3.65,2.73,2.39,1.72,3.59,2.58,4.56,2.28,1.92-.6.84-4.84,3.19-8.2,1.34-1.92,3.4-2.98,5.37-3.57,1.38-.42,2.87-.24,4.14.46.7.39,1.49.82,2.35,1.29,1.89,1.01,4.33,2.32,7.29,3.65.78.35,4.15,1.85,8.66,3.31,4.69,1.52,13.43,3.05,20.62,4.26,5.8.67,9.14.54,11.28,1.09,4.72,1.22,3.32,17.21-1.82,15.95-2.11-.52-3.73-1.82-11.64-3.89-3-.78-4.7-1.95-12.51-2.49-11.37-.79-10.82-1.72-13.67-1.37-7.35.92-8.63,6.79-20.05,16.86-6.6,5.82-11.55,10.18-19.14,12.76-8.16,2.78-15.06,2.04-25.52.91-11.53-1.24-17.29-1.86-20.51-5.47-6.61-7.42.3-17.91-6.84-24.15-3.98-3.48-9.99-3.58-13.67-3.65-4.81-.08-6.31.63-7.53.9-1.03.23-1.83.74-7.97,3.19-4.02,1.6-4.52,1.51-5.24,1.82-.44.19-4.4,2.48-8.43,4.78-6.72,3.84-10.35,7.33-15.27,11.62-9.88,8.62-8.27,7.42-18.91,16.86-4.96,4.4-8.28,6.95-12.3,12.3-3.51,4.67-5.76,6.66-6.53,6.44-2.14-.63-1.61-20.8,9.72-31.5h-.02Z"/>
  <path id="thoracicspine131" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M174.31.78c-1.08,0-2.16-.01-3.24-.02-1.93,6.98.54,17.35,3.46,17.93h-.01c1.36.27,2-1.76,5.47-2.73,2.82-.8,4.24.02,6.84,0,3.76-.03,9.82-1.83,11.72-6.43,1.14-2.77.53-5.96-.94-8.62"/>
  <path id="thoracicspine130" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M197.62.9c-7.77-.03-15.54-.07-23.31-.12"/>
  <path id="thoracicspine129" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M119.56.37c-10.84-.07-21.68-.12-32.52-.12-1.23,2.17-2.43,5.49-1.3,9.28,1.2,4.02,4.5,6.82,7.67,7.81,2.37.73,4.77.47,9.11,0,3.41-.38,2.67.29,4.57.66,3.03.59,5.44,1.15,7.27.7,1.16-.29,2.15-1.31,3.19-3.65h0s-.01-.01-.01-.01c1.87-4.21,2.81-10.32,2.02-14.66Z"/>
  <path id="thoracicspine128" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M124.87,29.74c-5.5.97-8.38,2.36-10.65.61"/>
  <path id="thoracicspine127" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M179.73,74.95c2.02-1.81,1.07-6.44.94-7.03-.52-2.38-1.53-3.39-.94-4.28.68-1.02,2.57-.47,5.01-.61,5.22-.3,8.91-3.53,9.4-3.97,3.67-3.31,4.35-7.54,4.7-9.77.32-2.03.7-4.41-.63-5.5-1-.82-2.21-.28-5.96,0-1.18.09-4,.29-6.9,0-3.91-.4-6.53-.66-7.83-2.45-1.84-2.53.76-5.62-.94-7.33-1.29-1.29-3.54-.28-8.15.31-4.91.62-4.76-.22-12.85,0-6.09.17-5.59.63-12.22.92-8.44.36-7.44-.46-14.11,0-6.73.47-8.71,1.38-12.22,0-2.26-.89-3.89-2.23-5.01-1.53-1.52.95.25,4.16-1.57,6.11-1.08,1.16-2.48.84-6.58,1.22-1.85.17-5.49.5-8.78,1.53-2.31.72-5.72,1.78-8.46,4.89-.56.63-3.34,3.89-3.45,8.55-.04,1.58-.1,4.04,1.57,5.5.39.34,1.15.87,5.33,1.22,4.26.36,4.71-.09,6.58.31,2.3.5,3.02,1.47,6.58,3.97,3.25,2.27,5.99,4.19,8.78,5.2"/>
  <path id="thoracicspine126" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M123.93,52.65c1.92.09,3.71-3.55,4.39-6.11.73-2.8-.17-3.21,0-8.55.13-4.14.72-5.47-.32-6.42-1.27-1.17-3.96-.78-5.64.31-2.66,1.72-2.92,5.22-3.14,8.25-.12,1.71-.1,4.67,1.25,7.94.81,1.95,1.87,4.51,3.45,4.59h.01Z"/>
  <path id="thoracicspine125" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M166.56,53.87c1.9.21,3.85-1.88,4.7-3.67,1.05-2.2.23-3.47-.63-9.47-.88-6.16-.47-8.01-2.19-9.16-1.44-.97-3.68-.97-5.01,0-.74.54-1.3,1.43-1.57,6.42-.24,4.49-.36,6.73.32,9.16.55,1.98,1.8,6.43,4.39,6.72h0Z"/>
  <path id="ribs22" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M237.09,54.5c-13.94-5.91-30.62-8-50.49-6.74"/>
  <path id="ribs35" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M172.72,47.76s-1,7.94,13.87,0"/>
  <path id="rib21" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M186.59,24.23c1.79,3.59,22.45,11.18,50.49,13.75"/>
  <path id="ribs34" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M172.72,47.76c0-13,7.48-23.52,13.87-23.52"/>
  <path id="thoracicspine124" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M177.53,42.52c-4.55,7.45-2.06,19.09,2.28,23.95"/>
  <path id="thoracicspine123" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M110.46,39.81c6.29,21.25,2.13,33.98,0,32.38"/>
  <path id="thoracicspine122" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M137.4,42.36c6.45,8.59,3.7,25.86,8.28,25.86"/>
  <path id="thoracicspine121" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M152.26,39.81c-6.39,11.14,0,28.41-6.58,28.41"/>
  <path id="ribs8" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M111.29,37.98c-29.01-22.11-66.66,11.54-91.42,34.21"/>
  <path id="thoracicspine120" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M111.29,44.1c-4.15,4.59-13.02,2.78-20.24,0"/>
  <path id="ribs7" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M92.95,44.2c-31.97,1.83-69.1,47.06-82.17,57.93"/>
  <path id="thoracicspine119" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M104.15,59.36c0-1.01,4.77-1.83,10.66-1.83"/>
  <path id="thoracicspine132" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M114.65.34c1.34,3.97,2.91,4.41,3.32,7.76.43,3.58-.9,6.84-1.82,9.1-1.47,3.59-2.86,4.64-2.73,7.28.02.59.23,3.37,2.12,4.85,2.21,1.73,4.99.35,10.31-.61,6.74-1.22,6.46.24,17.89-.91,7.06-.7,5.77-1.12,9.4-1.21,3.57-.08,5.94.29,17.28,1.52,5.3.57,7.96.83,8.49-.31.4-.88-.74-1.9-2.43-4.55-.65-1.03-3.08-4.84-3.94-8.49-.86-3.63-.1-6.75.91-10.92.3-1.23.6-2.21.87-3.07"/>
  <path id="thoracicspine118" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M135.46,2.32c5.93,11.27,4.93,24.27,7.93,36.27,0,0,1-1,.95-.61"/>
  <path id="thoracicspine117" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M153.51,2.31c-2.66,6.46-2.78,13.91-3.63,20.76-.51,4.14-.76,13.04-5.53,14.91"/>
  <path id="thoracicspine116" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M87.26.25c-.65,1.15-.98,2.15-1.15,2.69-.37,1.2-1.28,4.28,0,7.88.25.71,1.68,4.5,5.46,6.37,1.54.76,3.85.81,8.49.91,4.72.1,4.85-.25,8.18,0,4,.31,4.47.86,5.76.31,2.8-1.2,4.27-5.41,4.02-8.78-.23-3.07-1.72-3.26-3.11-8.5-.07-.28-.14-.54-.19-.78"/>
  <path id="thoracicspine115" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M174.09.78c-.41,1.64-.82,3.62-1.31,5.97-.65,3.11-.71,4.13-.6,5.31.35,3.54.64,6.69,2.36,6.63,1.21-.05,2.85-1.31,4.97-2.39,1.44-.73,2.25-.27,5.15-.31h0c1.2-.02,4.35-.08,7.58-1.22,2.16-.77,3.86-1.36,5.16-3.03,2.34-3.01,1.42-6.97,1.21-7.88-.27-1.16-.7-2.13-1.17-2.95"/>
  <path id="thoracicspine114" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M111.2,108.58c-.25-2.1,5.04-4.93,10.25-6.15,4.39-1.03,6.52-.34,14.35,0,8.72.38,7.89-.4,15.72,0,3.26.17,7.87.79,17.09,2.05,8.06,1.1,11.5,1.56,11.62,2.73.14,1.38-4.52,1.65-8.2,6.15-2.22,2.71-1.84,4.21-3.42,6.15-3.12,3.83-9.11,3.53-15.04,3.42-22.61-.43-31.97,2.07-34.18-2.73-.99-2.16-1.78-3.8-3.56-6.48-2.36-3.56-5.15-1.01-5.37-2.83,0,0,.74-2.31.74-2.31Z"/>
  <path id="thoracicspine37" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M112.57,151.64c-.24,2.47,6.71,5.1,10.25,6.15,4.93,1.46,8.55,1.4,15.04,1.37,7.29-.03,14.66,1.08,21.87,0,9.9-1.49,19.11.55,19.82-1.37.88-2.35-3.88-6.68-8.2-8.89-2.14-1.09-5.28-2.23-18.46-2.05-11.37.16-10.53,1.02-17.77.68-4.79-.23-7.54-.72-12.3,0-1.95.29-10.01,1.51-10.25,4.1h0Z"/>
  <path id="thoracicspine36" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M142.64,168.04c-.64-1.66,1.41-2.76,3.42-7.52,2.06-4.88,1.81-8.28,2.56-8.28.53,0,.81,1.67,2.23,8.96,1.23,6.3,1.46,7.32.68,8.2-1.92,2.19-7.92,1.12-8.89-1.37h0Z"/>
  <path id="thoracicspine35" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M125.55,263.06c-5.9-2.71-8.13-9.18-11.62-19.82-3.77-11.5-5.82-24.44-3.42-25.29,2.2-.78,6.54,9.21,10.25,8.2,2.74-.75,1.81-6.6,5.47-8.2,2.66-1.17,4.36,1.38,10.25,2.05,4.16.47,4.24-.81,9.57-.68,5.3.13,5.04,1.3,10.25,1.37,2.3.03,2.13-.2,3.17-.08.41.12.84.26,1.28.42,1.8.66,3.28,1.54,4.44,2.39,1.7-1.13,3.86-2.1,6.02-1.57,1.95.48,2.92,1.94,3.55,1.57,1.25-.73-1.56-7.07-.68-7.52.67-.34,2.22,3.63,6.15,5.47,3.41,1.6,6.33.18,6.84,1.37.66,1.57-4.66,3.49-5.47,8.2-.39,2.28.64,3.13,1.37,6.84,0,0,.93,4.76,0,10.25-1.49,8.81-8.55,13.63-12,15.54-.39.22-.93.53-1.67.87-8.83,4.02-15.43-1.3-27.34-2.05-10.39-.65-11.29,3.03-16.41.68h0Z"/>
  <path id="thoracicspine34" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M127.6,276.73c-.42-1.13-1.99-5.4,0-8.2,1.99-2.8,5.83-1.76,12.3-2.73,11.86-1.78,13.37-7.45,20.51-6.15,4.8.87,9.79,4.46,10.94,8.89.85,3.29-1.07,4.58-2.05,11.62-1.05,7.48.71,8.93-.68,10.94-4.96,7.13-35.5.59-41.01-14.35v-.02h-.01Z"/>
  <path id="thoracicspine33" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M106.41,343.03c2.06-5.61,6.95-15.3,8.2-16.41,10.02-8.85,27.2-9.35,36.91-2.73,3.7,2.52,10.47-.31,15.95,0,12.3.68,4.76,5.5,7.29,7.52,3.59,2.86,2.51,13.17-2.05,19.14-13.11,17.18-71.83,7.53-66.3-7.52h0Z"/>
  <path id="ribs6" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M18.08,138.5c3.96-5.52,11.23-11.6,19.29-18.3,2.61-2.17,10.17-6.92,25.29-16.41,13.96-8.76,20.99-13.15,23.24-13.67,2.71-.63,4.55-1.5,9.57-4.78,1.14-8.11,4.94-8.21,4.94-8.21,1-.35,2.04-.32,6.45,2.61,5.15,3.42,3.49,3.19,4.33,4.23,2.91,3.58,3.07,10.41,0,12.99-2.63,2.2-4.46.89-10.78,2.23-4.3.91-6.08,1.15-12.46,4.6-7.34,3.97-8.67,4.31-14.14,7.4-4.25,2.4-5.34,3.21-15.94,13.11-6.15,5.75-6.19,5.41-8.2,7.52-3.31,3.47-3.66,5.92-10.48,14.68-7.01,9-9.21,9.78-10.03,10.61-4.24,4.26-9.91,9.11-12.59,10.86"/>
  <path id="ribs5" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M.46,247.34c1.88-4.37,4.89-10.47,9.57-17.09,4.79-6.77,8.49-11.54,17.77-22.24,6.25-7.21,4.76-6.09,12.53-15.27,9.63-11.39,8.84-9.53,18.46-20.73,6-6.99,11.99-12.72,20.3-23.1,9.93-12.41,14.91-18.66,15.72-21.19.95-2.94,5.47-8.89,5.47-8.89,3.65-1.54,7.76,1.07,8.2,1.37,6.74,4.43,8.06,16.69,3.42,21.19-2.35,2.28-5.14,2.51-10.04,4.19-5.2,1.78-7.34,3.5-13.2,10.16-16.88,19.19-14.29,17.01-18.25,23.11-4.7,7.25-6.02,8.12-11.83,17.9-5.95,10.02-11.31,16.63-14.35,20.51-6.61,8.42-16.22,21.68-28.71,42.38"/>
  <path id="ribs20" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M180.92,129.08c1.59-2.44,4.7-5.07,8.17-5.02,4.3.06,6.97,4.18,10.97,9.12,4.94,6.1,10.83,11.38,16.52,16.79,7.15,6.81,14.05,13.88,21.08,20.81,37.58,33.13,36.59,39.83,35.54,41.7-1.53,2.73-7.3,2.61-10.82,1.62-6.94-1.96-10.63-7.98-15.85-15.43-2.25-3.22-8.09-9.97-22.54-22.41-14.4-12.4-21.6-18.59-24.61-20.51-10.35-6.58-17.99-9.15-19.88-16.77-.24-.95-1.33-5.67,1.42-9.89h0Z"/>
  <path id="ribs19" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls4" d="M260.89,146.85c-7.21-5.9-13.45-10.18-17.77-12.99-3.29-.22-19.55-9.16-30.08-17.77-10.48-8.58-8.52-4.89-14.27-9.05-7.68-5.56-20.34-5.46-23.6-11.09-1.96-3.39-1.06-7.97.27-10.62,1.3-2.58,3.25-3.95,5.05-5.21.67-.47,9.17-6.76,11.67-4.81,1.46,1.14-2.21,4.96-.99,7.97,1.26,3.1,4.1,3.94,10.23,7.4,4.56,2.57,2.81,2.01,8.91,5.59,10.89,6.4,24.82,12.96,28.71,15.04,7.72,4.13,18.52,10.62,30.76,20.51"/>
  <path id="thoracicspine113" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M111.05,72.27c2.27-2.15,6.78,2.11,18.53,4.25,1.47.27,7.94,1.39,16.41.91,6.83-.39,10.37-.59,14.89-2.43,4.91-2,6.67-4.19,11.24-4.25,3.54-.05,7.43,1.19,7.59,2.73.12,1.09-1.7,1.52-3.04,3.34-2.08,2.84-1.3,6.75-.61,9.72,3.22,13.73,6.98,17.03,5.53,19.54-2.49,4.32-15.06-2.85-38.64-2.83-14.2,0-21.29,0-27.34,3.34-1.4.77-5.12,2.98-6.38,1.82-1.11-1.02.56-3.88,1.82-9.42,0,0,.89-3.92,1.22-9.42.63-10.88-3.43-15.21-1.22-17.32v.02h0Z"/>
  <path id="thoracicspine112" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M119.86,83.21c2.77-.75,6.35,2.18,7.29,5.47,1.28,4.46-2.33,9.43-6.38,10.33-.51.11-3.2.65-4.86-.91-2.37-2.23-.74-6.97,0-9.11.89-2.58,1.79-5.19,3.95-5.77h0Z"/>
  <path id="thoracicspine111" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M161.48,82.6c-3.55,2-3.89,7.51-2.98,11.04.8,3.13,3.34,7.66,6.33,7.49,3.17-.18,5.27-5.57,5.47-9.42.15-3.03-.72-8.32-4.31-9.59-2.2-.78-4.28.35-4.5.48h-.01Z"/>
  <path id="thoracicspine110" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M141.12,89.75c-1.82,5.69-.14,11.39-.3,11.39"/>
  <path id="thoracicspine109" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M140.82,101.14s-.24,0,2.28,0"/>
  <path id="thoracicspine108" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M145.38,101.14c2.42-5.69-.13-11.39,1.21-11.39"/>
  <path id="thoracicspine107" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M176.5,88.34c-6.76,1.49-4.02,12.47-4.39,12.8"/>
  <path id="thoracicspine106" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M172.11,101.14s3.65,1.51,7.29,0"/>
  <path id="ribs33" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls7" d="M176.97,83.21c0-4.37,5.84-7.9,13.06-7.9"/>
  <path id="ribs32" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls7" d="M190.03,77.13c0,3.36.95,6.08,2.13,6.08"/>
  <path id="ribs18" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls7" d="M192.16,83.21c0,26.53,33.43,48,74.73,48"/>
  <path id="ribs31" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls7" d="M181.91,101.14c0,3.36,8.39,6.07,18.76,6.07"/>
  <path id="ribs17" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls7" d="M200.67,107.21c0,15.45,18.21,27.95,40.71,27.95"/>
  <path id="ribs16" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M239.83,133.64c4.27,1.04,12.25,6.52,21.06,13.21"/>
  <path id="ribs15" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M239.83,133.64c-12.11-6.11-41-31.43-57.92-32.5"/>
  <path id="ribs30" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M176.97,83.21c-1.01,1.11,6.54-7.62,15.19-7.9"/>
  <path id="ribs29" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M192.16,75.31c-2.13,5.03.19,10.38.08,10.04"/>
  <path id="ribs14" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M192.24,85.35c7.72,5.63,33.67,18.86,57.34,30.97"/>
  <path id="ribs13" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M249.58,116.32c2.93,3.54,14.31,9.61,20.2,15.5"/>
  <path id="ribs4" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M18.08,138.5c12.91-18.65,50.19-40.23,77.47-53.14"/>
  <path id="ribs28" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M95.55,85.36c0-4.55,2.17-8.23,4.86-8.23"/>
  <path id="ribs27" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M100.41,77.13c3.34.6,8.12,3.69,11.69,6.08"/>
  <path id="ribs3" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M16.57,167.97c13.58-9.49,26.72-24.62,30.99-32.81"/>
  <path id="ribs26" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M47.55,135.16c9.4-14.06,34.21-29.42,52.86-35.97"/>
  <path id="ribs25" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M100.41,99.19c0-.1,4.76-.18,10.63-.18"/>
  <path id="thoracicspine105" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M108.86,109.94c7.26,7.21,44.72,13.06,70.54-1.19"/>
  <path id="thoracicspine104" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M114.67,108.75c1.7,3.8,1.52,8.74,3.41,8.74"/>
  <path id="thoracicspine103" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M174.18,107.04c-4.58,5.22-.68,13.22-2.32,10.45"/>
  <path id="thoracicspine32" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M109.83,152.48c-1.66-1.72,1.85-5.08,1.82-12.76,0-1.84-.66-5.23-1.97-11.94-1.5-7.72-1.76-8.01-1.37-8.72,2.56-4.7,16.54,2.68,35.85,2.43,19.06-.24,32.35-7.78,35.85-2.43,1.51,2.31,1.05,6.82.91,8.2-.47,4.63-2.31,5.37-2.13,8.81.22,4.23,2.92,4.81,3.95,9.42,1.18,5.27-.76,11.63-3.34,12.15-2.39.49-3.37-4.43-8.2-7.9-3.63-2.61-6.67-2.41-24.3-1.82-20.3.68-21.14.58-24.3,1.52-6.68,1.97-11.06,4.8-12.76,3.04h0Z"/>
  <path id="thoracicspine102" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M144.46,128.48c.63,2.6,1.15,4.76,3.04,5.47,1.95.73,4.49-.41,5.47-2.13.41-.71.38-1.9.3-4.25-.09-2.84-.14-4.27-.61-4.86-1.61-2.05-6.51-2.1-8.2.3-.98,1.39-.56,3.15,0,5.47Z"/>
  <path id="thoracicspine31" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M133.53,130.3c-.03,1.43.35,1.88.91,4.25,1.32,5.6.07,6.76,1.22,8.51,1.72,2.62,6.12,2.43,13.06,2.13,7.05-.31,10.58-.46,12.15-2.73,1.88-2.72-.98-4.79,0-12.76.39-3.19,1.11-4.97-.02-6.32-1.19-1.42-3.42-1.15-6.36-.97-12.37.73-14.84-1.6-17.92,1.22-1.77,1.61-2.98,4.23-3.04,6.68h0Z"/>
  <path id="thoracicspine101" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M118.08,119.97c2.12,8.77,4.51,16.73,9.33,17.44"/>
  <path id="thoracicspine100" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M127.41,137.41c1.8-3.39,6.22-8.8,9.16-13.79"/>
  <path id="thoracicspine99" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M160.85,123.37c0,3.02,9.05,7.84,16.12,7.84"/>
  <path id="thoracicspine98" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M171.86,117.49c0,6.21,2.92,13.72,5.11,13.72"/>
  <path id="thoracicspine97" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M173.63,144.88c-1.05,1.42-4.2,1.51-6.06.17-1.79-1.29-1.92-3.57-2.14-7.46-.19-3.34-.28-5.01.91-6.08,1.86-1.66,5.77-1,6.99.91.9,1.42-.37,2.47-.3,6.08.07,3.82,1.55,5.12.61,6.38h-.01Z"/>
  <path id="thoracicspine96" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M171.2,149.74c7.51-5.88,7.38-10.15,5.3-8.66"/>
  <path id="thoracicspine95" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M176.5,141.08c-1.51-3.15-.68-3.72-2.08-3.68"/>
  <path id="thoracicspine94" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M174.42,137.41c1.77-4.91,3.58-8.23,2.55-6.2"/>
  <path id="thoracicspine93" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M175.46,145.26c0,.12,4.51.23,7.29.23"/>
  <path id="thoracicspine92" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M119.25,143.06c-2.34-.23-4.49-5.81-3.34-10.33.83-3.26,3.58-6.82,5.77-6.38,1.96.39,2.85,3.87,3.04,5.77.53,5.26-2.94,11.19-5.47,10.94Z"/>
  <path id="thoracicspine91" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M111.43,143.07c6.4,1.13,13.49,10.69,14.5,20.47"/>
  <path id="thoracicspine90" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M108.86,122.18c7.51,1.19,3.15,10.55,7.05,10.55"/>
  <path id="ribs24" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M108.31,119.06c0-.55-3.53-1-7.9-1"/>
  <path id="ribs2" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M100.41,118.06C76.36,161.14,14.96,211.44.46,247.34"/>
  <path id="ribs1" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M14.44,259.72c39.73-49.89,67.86-115.45,96.99-116.65"/>
  <path id="thoracicspine30" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M109.83,152.48c0,1.53,23.69,12.46,59.8,5.2"/>
  <path id="thoracicspine89" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M179.4,159.16c0-.82-7.29,0-9.77-1.49"/>
  <path id="thoracicspine88" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M171.2,149.74c-2.55,3.71-2.29,15.65-2.29,11.85"/>
  <path id="ribs23" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M180.92,127.26c0-1.41,6.27-3.49,10.83-3.49"/>
  <path id="ribs12" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M191.75,123.77c27.66,32.66,70.74,63.59,82.58,86.52"/>
  <path id="ribs11" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M182.74,145.49c15.41,9.4,51.46,35.42,73.82,66.23"/>
  <path id="thoracicspine29" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M109.83,164.32c-2.06,3.81,3.7,7.68,3.95,20.05.07,3.29.13,6.62-1.22,10.63-.77,2.28-1.52,3.41-2.43,6.68-1.11,4.03-1.77,6.41-.61,7.9,1.18,1.51,3.38,1.04,8.2,1.22,4.16.15,3.57.54,10.33,1.22,17.97,1.79,35.94.41,41.01,0,8.52-.69,13.31-1.48,14.58-4.56.49-1.19.24-2.19-1.3-9.92-.95-4.77-1.43-7.16-1.5-7.61-.72-4.53-1.62-10.19-.53-16.8.81-4.94,2.09-6.52.93-8.2-1.7-2.48-6.42-1.86-10.05-1.52-7.97.75-16.02-.52-24.01-.1-24.74,1.3-34.73-3.84-37.36,1.01h.01Z"/>
  <path id="thoracicspine28" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M147.2,153.08c2.49.81,1.13,7.55,3.34,13.37.91,2.39,3.26,2.13,3.95,4.25.84,2.58-.18,6.08-2.73,7.9-1.67,1.19-4.46,1.98-5.47.91-1.25-1.32,1.42-4.24,0-7.29-.71-1.53-2.42-1.34-2.73-2.73-.98-4.31,3.35-16.5,3.65-16.41h0Z"/>
  <path id="thoracicspine87" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M91.77,185.18c-1.28,1.62-1.31,4.09-.47,5.57,2.08,3.65,10.06,2.28,10.94,2.13,3.28-.56,3.29-1.4,5.6-1.81,7.83-1.37,12.21,7.49,17.49,5.76,3.91-1.29,6.05-7.64,4.56-9.42-1.12-1.34-3.69.63-5.77-.61-4.64-2.76-.06-17.37-1.77-17.92-1.47-.48-3.1,10.86-10.99,14.58-7.74,3.65-16.2-2.55-19.58,1.72h-.01Z"/>
  <path id="thoracicspine86" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M181.22,181.64c1.14-.34,1.88,3.16,5.6,4.89,1.53.71,2.06.42,6.24.88,5.68.63,8.52.95,9.11,2.43.66,1.64-1.23,4.29-3.34,5.47-1.52.85-2.33.48-6.42.68-5.95.3-6.82,1.22-8.46.23-1.94-1.17-2.47-3.5-3.11-6.29-.78-3.39-.88-7.92.38-8.29h0Z"/>
  <path id="thoracicspine27" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M111.43,210.6c0-1.74,37.66-11.65,72.22-3.14"/>
  <path id="thoracicspine26" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M145.98,205.64c-.96,1.17.69,2.63.3,5.47-.35,2.54-1.84,2.57-2.73,5.77-.74,2.64-.35,4.84-.19,5.7.33,1.75.94,4.95,2.92,5.54,2.02.6,4.79-1.69,5.88-4.19,1.06-2.42-.11-3.54-.72-9.48-.59-5.74.15-8.02-1.52-9.11-1.21-.8-3.18-.64-3.95.3h0Z"/>
  <path id="thoracicspine85" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M169.63,163.53c.87,4.36,5.8,10.45,10.68,9.6"/>
  <path id="thoracicspine25" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M130.36,189.14c0,4.16,12.39,7.53,27.71,7.53"/>
  <line id="thoracicspine24" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" x1="165.32" y1="189.14" x2="158.07" y2="196.67"/>
  <path id="thoracicspine23" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M127.41,187.06c1.37-6.45,11.38-13.56,18.99-10.73"/>
  <path id="thoracicspine22" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M153.05,177.39c3.4,2.72,8.25,6.26,12.26,11.76"/>
  <path id="thoracicspine84" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M121.04,187.06c0,5.4,1.92,9.77,4.29,9.77"/>
  <path id="thoracicspine83" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M168.16,190.15c1.09-2.5,1.61-4.82,1.82-5.77.41-1.84.57-3.3.61-3.65.26-2.42.04-3.02.3-5.47.27-2.52.44-4.01,1.22-4.25.81-.25,2.07.91,2.73,2.13.41.76,1.1,2.49-1.21,9.82-.86,2.74-.68,1.62-2.44,6.58-1.55,4.37-2.06,6.3-3.95,7.29-.96.5-2.34.81-2.73.3-.59-.76,1.72-2.56,3.65-6.99h0Z"/>
  <path id="thoracicspine82" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M123.18,197.39c8.81,4,2.46,8.62,5.49,8.62"/>
  <path id="thoracicspine81" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M169.63,204.06c0-3.6,5.69-6.52,12.72-6.52"/>
  <path id="thoracicspine80" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M125.93,163.53c-1.16,11.76.66,23.53,1.48,23.53"/>
  <path id="thoracicspine21" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M122.74,163.53s23.54,8.69,53.75,0"/>
  <path id="ribs10" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M184.26,228.12c-1.82.49-2.12,4.84-2.43,9.42-.3,4.34.19,8.65,1.52,9.72,1.46,1.17,2.53-2.21,7.59-3.04,5.54-.91,10.89-.62,13.12-.43,4.15.34,5.21.95,7.24.13,3.16-1.29,5.69-4.85,4.86-6.68-.58-1.28-2.57-1.19-9.42-1.52-12.78-.62-14.77-1.38-16.41-2.43-3.72-2.4-4.45-5.6-6.08-5.16h0Z"/>
  <path id="thoracicspine79" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M82.49,241.79c-3.24-2.02-4.88-7.32-3.04-10.33,1.57-2.56,5.28-2.84,7.9-3.04,3.98-.3,7,.96,7.59,1.22,3.56,1.54,3.27,3.06,5.77,3.65,4.12.96,6.86-2.71,9.11-1.22.71.47,1.08,1.83,1.83,4.55.93,3.39,1.4,5.09.9,5.77-1.44,2-6.69-2-14.89-2.13-8.34-.12-11.38,3.89-15.19,1.52h.02Z"/>
  <path id="thoracicspine20" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M130.19,235.72c.12-1.59,2.77-1.42,6.08-4.56,3.66-3.48,3.9-7.02,5.77-6.99,1.83.03,1.95,3.42,4.25,3.95,3.24.75,5.57-5.38,9.11-4.86,1.47.21,2.72,2.17,5.16,6.08,2.79,4.45,4.18,6.67,3.65,8.51-.87,2.98-5.23,4.06-8.51,4.86-7.14,1.75-13.03-.03-15.8-.91-1.18-.37-9.94-3.15-9.72-6.08h0Z"/>
  <path id="thoracicspine78" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls3" d="M169.38,264.88c-2.4-1.97-2.68-6.39-1.22-9.42.94-1.95,2.76-3.02,6.38-5.16,4-2.36,7.1-4.2,8.81-3.04,1.16.79.53,2.18,2.36,5.11,1.07,1.71,1.56,1.69,2.2,2.79,1.72,2.97,1.07,8.25-1.82,10.33-1.11.8-1.74.55-9.11.3-5.79-.19-6.57-.07-7.59-.91h0Z"/>
  <path id="thoracicspine77" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M169.68,249.39h0s-2.15-4.09-2.43-7.9c-.2-2.8.48-7.26,2.73-7.9,1.94-.55,4.4,1.9,5.47,3.95,1.33,2.56.78,5.08.3,7.29-.37,1.71-.99,4.73-2.74,5.18-1.26.33-3.33-.62-3.33-.62Z"/>
  <path id="thoracicspine76" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M121.98,233.29c-2.07,1.65-1.99,4.54-1.82,10.33.1,3.58.23,6.75,1.86,7.37,1.08.41,2.35-.48,3.34-1.17.57-.4,3.26-2.38,4.52-6.8.57-2.02,2.19-7.71-.91-10.03-1.91-1.43-5.1-1.2-6.99.3Z"/>
  <path id="thoracicspine75" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M109.53,230.55c-.5-.02-.71-2.96-.91-5.77-.4-5.58.1-6.34.61-6.68.62-.42,1.15-.11,3.65.3,4.06.67,5.64.36,5.77.91.22.93-4.42,1.41-6.99,5.16-1.98,2.9-1.6,6.1-2.13,6.08h0Z"/>
  <path id="thoracicspine74" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M118.95,244.83c-1.27.74-3.66-.86-4.86-2.13-1.14-1.2-1.57-2.47-2.42-6.08-1.15-4.83-1.72-7.24-1.61-8.04.59-4.48,4.47-8.81,8.59-9.28.59-.07,2.54-.29,3.95.91,1.93,1.65,1.55,4.89,1.52,5.16-.26,2.03-1.29,2.5-1.03,4.19.22,1.43,1.07,1.77.89,2.72-.25,1.27-1.91,1.31-3.12,2.4-3.01,2.72.18,8.92-1.91,10.14h0Z"/>
  <path id="thoracicspine73" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M127.41,206.01c1.73,6.59-2.69,16.9-8.77,13.3"/>
  <line id="thoracicspine72" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" x1="124.77" y1="217.9" x2="126.48" y2="233.34"/>
  <line id="thoracicspine19" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" x1="152.47" y1="220.74" x2="124.77" y2="217.9"/>
  <line id="thoracicspine18" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" x1="152.47" y1="220.74" x2="171.2" y2="221.44"/>
  <path id="thoracicspine71" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M169.63,204.06c-1.06,9.78.7,17.38,1.57,17.38"/>
  <line id="thoracicspine70" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" x1="171.75" y1="233.34" x2="171.2" y2="221.44"/>
  <path id="thoracicspine69" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M174.46,216.52c-.28,11.7-.23,18.91-.52,18.91"/>
  <path id="thoracicspine68" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M174.46,216.52c2.95,2.65,8.51,6.34,9.8,11.6"/>
  <path id="thoracicspine67" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M173.94,235.43c0,4.16-2.91,7.52-6.5,7.52"/>
  <path id="thoracicspine66" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M186.34,223.23c0-.99-6.77-1.79-15.14-1.79"/>
  <path id="thoracicspine65" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M186.34,223.23c0,2.7-.93,4.89-2.07,4.89"/>
  <path id="thoracicspine64" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M123.97,232.29c0,3.3,3.59,5.97,8.02,5.97"/>
  <path id="thoracicspine63" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M164.21,237.84c0-.17,5.42,1.35,11.24-.3"/>
  <path id="thoracicspine62" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls3" d="M109.15,262.6c-1.25-1.87-.5-4.5-.15-5.75.96-3.39,2.89-3.69,3.49-6.7.63-3.15-1.12-4.73-.3-5.47,1.55-1.41,9.61,2.75,12.76,9.42,1.31,2.77,1.52,5.46,1.67,7.27.17,2.14.24,3.22-.45,3.97-1,1.07-2.7.54-7.59-.3-7.01-1.2-8.42-.94-9.42-2.43h-.01Z"/>
  <path id="thoracicspine17" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls3" d="M146.52,267.77c-8.43-.65-8.08-1.42-11.24-.91-2.75.45-5.24,1.39-6.99,0-1.05-.84-1.72-2.43-1.22-3.34.72-1.31,3.63-.84,6.38-.61,5.63.47,4.46-.58,13.67-.61,9.38-.03,22.2,1.02,22.25,2.58,0,.22-.24.58-2.81,1.37-6.37,1.94-12.59,2.09-20.05,1.52h.01Z"/>
  <path id="thoracicspine16" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M147.12,249.84c-5.98,11.12.31,24.3-2.99,24.3"/>
  <path id="thoracicspine15" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M151.21,250.08c4.42,14.65-.22,28.02,2.11,28.02"/>
  <path id="thoracicspine14" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M116.44,278.1c4.02-1.36,7.29-1.14,10.98-.91,0,0,3.43.21,19.4.91,16.37.73,24.56,1.09,27.65.91.96-.05,3.5-.22,6.38.91,1.16.45,5.9,2.5,7.08,6.23,1.7,5.39-6.01,8.02-6.17,15.34-.14,6.35,5.61,7.47,7.59,17.32.65,3.21,1.73,8.59-.91,10.94-3.09,2.74-8.75-.9-18.53-3.34-7.67-1.91-13.82-1.88-26.13-1.82-14.72.07-22.07.11-29.47,2.43-3.98,1.25-6.71,2.56-8.2,1.22-3.26-2.94,6.45-12.25,4.86-25.52-1.08-9.02-6.51-12.6-3.95-17.92,2.01-4.18,7.29-5.96,9.42-6.68v-.02h0Z"/>
  <path id="thoracicspine13" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M144.13,274.15c0,2.18,1.49,20.35,9.19,3.95"/>
  <path id="thoracicspine12" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M125.86,286.3c-.9-.88,1.15-2.56,1.52-7.59.41-5.51-1.71-8.11,0-10.03.89-1,2.42-1.07,5.47-1.22,3.69-.17,7.8-.37,9.11,1.82,1.03,1.72-.12,4.15-.91,5.77-3.92,8.04-13.42,12.97-15.19,11.24h0Z"/>
  <path id="thoracicspine11" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M154.72,273.84c.93,3.29,2.62,5.42,6.38,9.72,6.35,7.25,7.04,7.46,7.59,7.29.79-.24,1.35-1.27,2.08-11.85.63-9.24.39-11.09-.86-11.85-1.5-.91-2.63.84-7.29,1.22-4.53.36-6.65-1.05-7.9.3-1.12,1.21-.48,3.47,0,5.16h0Z"/>
  <path id="thoracicspine61" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M75.12,282.04c-.73.16-4.6,1.02-6.08,4.25-1.07,2.34-.51,5.11.61,6.99,2.29,3.84,7.39,4.62,15.8,5.77,7.54,1.04,7.53-.03,13.06,1.22,5.93,1.34,10.66,1.29,12.03-.06,1.46-1.44-1.88-3.4-3.22-7.23-.98-2.82-1.48-4.23-2.43-5.16-3.17-3.09-7.14.04-14.58-1.22-7.82-1.32-9.54-5.81-15.19-4.56h0Z"/>
  <path id="thoracicspine60" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M121.3,268.98c-.44-.04-.25,3.14-2.43,5.16-1.5,1.39-3.04,1.25-3.04,1.82,0,1.05,5.16,2.63,6.37,1.07.4-.52.08-1.03-.29-3.81-.37-2.77-.31-4.23-.61-4.25h0Z"/>
  <path id="thoracicspine59" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M174.16,268.98c-.52.13-.55,1.61-.61,4.56-.08,4.54.45,5.21.91,5.47.48.26.75,0,1.82-.03,2.75-.1,4.1,1.4,4.56.94.49-.48-.9-2.3-3.95-7.29-1.63-2.67-2.23-3.78-2.73-3.65h0Z"/>
  <path id="thoracicspine10" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M170.69,271.72c0,3.31-7.64,3.39-14,6.38"/>
  <path id="thoracicspine9" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M130.81,286.64c3.16,8.43,20.54,15.26,33.4.42"/>
  <path id="thoracicspine58" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M181.85,303.07c1.44,2.81,9.02-.86,22.69-.98,11.24-.1,15.94,2.31,19.44-1.22,2.71-2.72,3.5-7.78,1.52-10.33-1.53-1.96-4.58-1.94-10.63-1.82-8.69.17-8.71,1.69-13.97,1.25-6.74-.56-10.1-3.33-12.87-1.18-.64.49-.84.94-2.08,3.75-4.27,9.68-4.32,10.06-4.09,10.52h-.01Z"/>
  <path id="thoracicspine57" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M170.52,303.92c-2.39-2.66-1.37-7.16,0-9.72,2.23-4.19,7.45-7.12,9.42-5.77s.31,6.73-.61,9.72c-.97,3.15-2.18,7.07-4.86,7.51-2.05.33-3.79-1.55-3.95-1.73h0Z"/>
  <path id="thoracicspine56" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M116.74,303.92c-.88-.89-.44-1.79.61-9.11.55-3.83.73-5.75,1.82-6.08.68-.2,1.01.43,3.04,1.22,2.51.97,3.17.46,3.95,1.22,1.34,1.29.67,4,0,6.68-.75,3.01-1.15,4.62-2.73,5.77-1.98,1.44-5.24,1.77-6.68.3,0,0-.01,0,0,0Z"/>
  <path id="thoracicspine55" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M122.2,277.04c0,7.13,0,12.9.01,12.9"/>
  <path id="thoracicspine54" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M111.43,304.13c9.44,0,5.03,10.42,9.61,10.42"/>
  <path id="thoracicspine53" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M114.31,327.01c-3.45-3.29,1.7-9,6.73-12.46"/>
  <path id="thoracicspine52" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M180.27,319.52c-3.8-2.99-4.96-5.72-6.72-6.79"/>
  <path id="thoracicspine51" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M181.85,303.07c-5.78,2.65-9.52,8.22-8.31,9.66"/>
  <path id="thoracicspine50" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M175.45,328.26c6.3-6.12,2.33-8.75,4.82-8.75"/>
  <path id="thoracicspine49" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M174.46,279.01c0,6.36-.41,11.51-.92,11.51"/>
  <path id="thoracicspine48" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M185.3,283.49c0,.7-8.94,1.27-19.98,1.27"/>
  <path id="thoracicspine47" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M131.99,288.84c0,1.13-3.5,2.05-7.82,2.05"/>
  <path id="thoracicspine8" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M144.69,299.67c-1,.91-.98,2.43-.91,3.65.31,5.91,1.58,6.94,1.52,9.72-.13,5.5-5.22,7.08-4.25,11.56.31,1.41,1.27,3.33,2.73,3.63,2.85.59,7.39-5,8.02-10.81.14-1.3-.08-1.07-.73-6.51-.96-8.12-.7-10.67-2.51-11.66-1.2-.66-2.93-.43-3.87.42h0Z"/>
  <path id="thoracicspine46" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M125.86,286.3c0,2.69.14,4.86.3,4.86"/>
  <path id="thoracicspine45" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M108.85,362.56c-1.52,1.26-5.88-5.95-14.58-7.59-6.16-1.16-8.25,1.64-14.89-.3-3.46-1.02-8.67-3.48-8.51-6.08h0c.13-2.09,3.67-3.76,6.38-4.26,4.25-.8,5.03,1.54,12.46,2.43,5.03.61,7.57.91,10.63-.3,1.56-.62,2.56-1.36,3.65-.91"/>
  <path id="thoracicspine44" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M185.2,363.02c1.54.09,4.64-.79,9.02-1.38,9.25-1.23,10.7.68,16.41-1.22,4.13-1.37,9.95-4.56,9.72-7.9-.12-1.71-1.77-2.87-2.43-3.34-4.03-2.85-7.75-.09-15.79-.33-7.67-.23-11.78-2.96-13.37-.88"/>
  <path id="thoracicspine134" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M182.56,371.85c0-1.62.13-3.29.42-5.03,1.76-10.28,8.2-15.99,5.77-18.84-2.12-2.49-8.06.65-18.23,2.73,0,0-12.14,2.48-27.65,1.22-22.4-1.83-32.92-12.79-37.06-8.51-3.31,3.42,3.82,9.99,3.34,23.09-.06,1.6-.23,3.13-.48,4.6"/>
  <path id="thoracicspine133" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M146.85,371.07c.35-1,.65-2.02.87-3.04.58-2.65-.06-1.86.61-9.42.57-6.38,1.16-8.42-.3-10.03-1.41-1.55-4.15-2.08-6.08-1.22-3.73,1.65-4.06,8.31-4.25,12.15-.09,1.74.07,1.81,0,6.99h0c-.04,2.7-.09,3.69-.08,4.54"/>
  <path id="thoracicspine43" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M178.11,364.98c-2.77.92-6.43-2.86-7.9-6.08-.83-1.82-1.71-5.13-.3-6.38,1-.89,2.13.16,4.86-.3,3.4-.58,4.53-2.72,5.77-2.13,1.44.69,1.1,4.14.91,6.08-.35,3.55-.78,7.96-3.34,8.81h0Z"/>
  <path id="thoracicspine42" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M114.31,350.7c-.51.09-.35,1.97-.61,5.16-.38,4.66-1.16,6.07-.17,7.1,1.15,1.19,3.45.6,3.81.5,3.94-1.09,6.15-6.66,4.86-8.2-.9-1.08-2.98.48-5.16-.91-1.99-1.27-2.15-3.75-2.73-3.65Z"/>
  <path id="thoracicspine41" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M175.98,329.74c-1.65.59.03,5.35-1.52,12.76-1.28,6.12-3.64,8.67-2.51,9.75,1.33,1.28,6.29-.63,8.87-4.15,3.29-4.49,2.02-10.7-.28-14.41-.94-1.52-3.18-4.44-4.56-3.95Z"/>
  <path id="thoracicspine4" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M113.7,328.53c-1.02,1.34-.78,4.44-.3,10.63.18,2.26.62,4.75,1.52,9.72.54,3.02.86,4.42,2.13,5.47,1.34,1.11,3.58,1.68,5.16.91,2.86-1.38,1.85-6.26,3.04-11.54,2.66-11.8,14.26-16.48,12.75-19.1-.57-.99-2.23-.33-13.96,1.18-7.14.92-9.01,1-10.33,2.73h0Z"/>
  <path id="thoracicspine3" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M108.47,328.73c0,1.1,33.89,6.17,75.18,1.99"/>
  <path id="thoracicspine40" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M111.9,329.69c-2.82,7.24-3.81,14.47-8.52,14.47"/>
  <path id="thoracicspine39" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls1" d="M115.83,330.72c0,13.56,2.85,24.54,6.38,24.54"/>
  <path id="thoracicspine2" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M149.36,324.14c15.81,4.97,19.11,19.89,17.87,26.56"/>
  <path id="thoracicspine1" class="Thoracolumbaler_Ubergang_vd_liegend1531_cls2" d="M180.82,348.1c2.04-6.78-29.54-11.55-73.4-5.67"/>
</g>
<g id="Highlights">
  <path id="BWK10_LWK3" class="BWK_LWK1531Fill" style= {{
            fill: props.colors.BWK_LWK1531Fill,
            stroke: props.colors.BWK_LWK1531Fill,
            opacity:props.colors.BWK_LWK1531Fill,
          }} d="M189.24,312.49c-2.39-6.62-5.28-7.5-4.85-12.13.45-4.9,3.86-5.82,5.26-11.53,1.22-5.01-.87-6.47-1.57-14-.94-10.12,2.55-10.44,1.77-19.98-.69-8.41-3.49-9.24-3.44-17.6.03-5.45,1.23-6.94,1.82-12.13.96-8.41-1.33-11.97-3.24-22.25-2.93-15.75-1.63-29.37-.98-36.19.92-9.62,1.98-9.72,1.99-16.79.02-10.92-2.5-12.21-3.64-24.67-1.42-15.6,2.35-15.6-.22-26.62-1.54-6.63-3.87-10.8-2.81-17.88.75-4.98,2.24-5.13,3.64-10.11,3.07-10.92-2.79-14.86-3.64-34.18-.21-4.81.04-7.05-.55-10.96-22.55.15-45.11-.09-67.66-.12-1.69,7.08-1.65,12.79-1.62,15.11.12,9.16,2.28,8.6,2.44,17.14.14,7.61-1.56,9.03-2.12,20.21-.29,5.83.16,5.62,0,11.6-.5,18.82-4.96,20.3-3.15,31.02,1.03,6.05,2.98,8.75,2.89,16.17-.04,2.99-.36,3.37-1.08,9.71-.92,8.19-1.5,13.31-1.17,18.39.61,9.3,3.69,9.72,4.68,17.74,1.22,9.83-3.14,11.41-4.85,24-1.68,12.36,2.25,12.83,2.16,32.09-.03,5.5-.06,13.07-1.62,22.92-1.51,9.54-3.4,14.53-2.43,23.19.82,7.35,2.44,6.09,2.97,12.13,1.35,15.47-9.19,24.66-4.58,33.17.68,1.24,2.01,3.08,2.16,5.93.19,3.55-1.62,5.47-2.42,8.28-.06.22-.11.44-.15.66,29.26.07,58.51.82,87.76,1.17-.09-1.34-.2-3.51-.32-7.21-.64-19.39.54-20.87-1.42-26.29Z"/>
  <g id="VertebralBody">
    <path id="vertebralbodyhighlight6" class="WK1531Fill" style= {{
            fill: props.colors.WK1531Fill,
            stroke: props.colors.WK1531Fill,
            opacity:props.colors.WK1531Fill,
          }} d="M106.72,285.51c1.37-4.21,6.01-5.95,8.46-6.96,1.39-.57,3.8-1.36,10.65-1.46,6.38-.09,11.2.49,12.15.61,5.03.6,11.47.72,24.36.96,12.19.23,18.66-.14,23.16,3.99.96.88,2.33,2.31,2.62,4.4.29,2.1-.72,3.52-4.6,11.02-1,1.93-1.5,2.9-1.54,3.09-1.1,4.63,3.67,7.2,6.25,13.95,2.09,5.45,2.83,12.96-.12,14.89-1.36.89-3.41.52-6.27,0-2.46-.44-4.12-1.15-5.31-1.62-2.52-.99-5.75-1.76-12.26-3.16-4.91-1.06-10.89-.95-22.65-.71,0,0-24.53.51-29.33,3.13-.19.1-1.5.84-3.32,1.01-.98.09-2.36.22-3.05-.5-.82-.85-.36-2.54.82-5.86,1.98-5.59,2.38-5.66,3.12-8.19,1.17-4,1.21-7.33,1.21-9.3,0-2.86.01-4.29-.61-6.17-1.03-3.11-2.43-3.41-3.42-6.41-.32-.96-1.27-3.83-.33-6.71h0Z"/>
    <path id="vertebralbodyhighlight5" class="WK1531Fill" style= {{
            fill: props.colors.WK1531Fill,
            stroke: props.colors.WK1531Fill,
            opacity:props.colors.WK1531Fill,
          }} d="M109.23,218.1c.77-.52,1.56-.1,4.79.47,3.09.54,4.63.82,5.95.68,2.56-.27,3.33-1.28,6.26-1.3,1.14,0,1.98.14,2.49.22,6.26,1.04,17.46,1.99,21.03,2.29,4.08.35,7,.44,11,.57,5.68.18,11.36.7,17.05.65,4.79-.04,7.83-.29,8.63,1.35.56,1.15.03,3.24-1.13,4.45-.61.63-1,.61-1.56,1.08-1.54,1.25-1.47,3.61-1.44,6.58.11,8.85-.21,8.1.09,9.33.31,1.24,1.19,4.8,3.78,8.09.7.89,1.44,1.65,2.02,3.1.25.62,1.01,2.59.54,5.07-.17.89-.65,3.44-2.63,4.76-1.75,1.18-2.52-.06-7.76.28-3.08.2-4.75.75-7.15-.21-.85-.34-.96-.53-1.88-1-2.9-1.47-5.68-1.51-7.45-1.55-12.39-.28-15.55-1.6-21.54-.61-2.44.4-4.24,1.01-6.86.51-.73-.14-1.91-.43-3.52-.27-.86.09-2.14.23-3.23,1.11-1.07.86-.86,1.53-1.57,2.14-1.46,1.25-3.71-.4-8.55-1.38-4.24-.86-5.93-.28-7.23-1.88-.99-1.22-.98-2.74-.98-3.33.02-3.94,3.65-4.86,4.13-9.15.02-.14.01-.15-.36-4.2-.52-5.72-.51-5.6-.54-5.93-.61-6-2.11-8.35-3.03-16.05-.4-3.39-.38-5.16.66-5.86h0Z"/>
    <path id="vertebralbodyhighlight4" class="WK1531Fill" style= {{
            fill: props.colors.WK1531Fill,
            stroke: props.colors.WK1531Fill,
            opacity:props.colors.WK1531Fill,
          }} d="M110.35,163.29c1.15-1.24,3.06-1.16,4.47-1.08,6.16.34,9.24.52,9.81.61,1.62.25,4.96.37,11.63.61,5.7.2,9.74.08,15.98-.1,7.41-.22,14.96,1.18,22.25-.2.77-.15,3.21-.67,5.36.62.27.16.92.55,1.39,1.18,1.78,2.39-.52,5.99-1.27,10.9-.39,2.56-.17,4.48.29,8.33.52,4.36,2.24,13.7,3.74,20.63.11.5.39,1.81-.25,2.36-.62.53-1.81.07-2.08-.02-3.31-1.19-24.84-3.62-36.81-2.94-9.26.52-28.19,3.77-31.75,5.56-.34.17-1.87,1-2.99.38-.19-.1-.79-.48-1.14-1.82-.56-2.11.19-4.02.72-5.4.85-2.22,1.56-4.49,2.41-6.71,1.39-3.66,1.36-6.75,1.31-10.72-.05-4.32-.12-10.9-2.73-15.07-.29-.45-1.36-2.1-1.3-4.24.02-.52.06-1.9.97-2.88h0Z"/>
    <path id="vertebralbodyhighlight3" class="WK1531Fill" style= {{
            fill: props.colors.WK1531Fill,
            stroke: props.colors.WK1531Fill,
            opacity:props.colors.WK1531Fill,
          }} d="M108.96,118.02c1.09-.93,4.92-.33,12.56.89,4.19.67,7.24,1.31,7.86,1.43,12.95,2.64,33.44-.67,42.47-3.03,1.25-.33,4.39-1.19,6.64.42,2.07,1.48,2.41,4.35,2.59,6.15.71,6.89-3.17,8.15-2.19,13.17.77,3.95,3.43,4.52,4.19,9.99.18,1.31.25,2.83-.1,4.55-.11.53-1.1,5.46-3.37,5.98-2.39.55-3.92-4.31-7.9-7.53-3.26-2.63-10.48-2.5-24.81-2.13-14.26.37-21.45.58-24.3,1.52-6.18,2.05-10.71,5.12-12.52,3.37-1.36-1.31.07-4.12.91-7.79,3.19-13.92-5.92-23.66-2.02-27h0Z"/>
    <path id="vertebralbodyhighlight2" class="WK1531Fill" style= {{
            fill: props.colors.WK1531Fill,
            stroke: props.colors.WK1531Fill,
            opacity:props.colors.WK1531Fill,
          }} d="M111.05,72.27c2.26-2.3,7.28,1.46,15.91,3.52,3.24.77,23.26,5.18,37.62-2.56,1.97-1.06,6.77-3.93,11.33-2.43,1.39.46,3.75,1.65,3.75,3.09,0,1.06-1.27,1.57-2.15,2.4-4.03,3.83-.31,14.98,1.78,21.21,2.07,6.19,3.15,7.31,2.31,8.58-1.86,2.83-8.72-.28-23.38-2.01-8.22-.97-14.11-.94-21.03-.91-11.49.05-14.96,1-16.89,1.62-6.75,2.18-9.03,5.1-10.72,3.94-3.08-2.1,4.66-11.73,2.56-25.92-.85-5.72-2.79-8.79-1.08-10.53h0Z"/>
    <path id="vertebralbodyhighlight1" class="WK1531Fill" style= {{
            fill: props.colors.WK1531Fill,
            stroke: props.colors.WK1531Fill,
            opacity:props.colors.WK1531Fill,
          }} d="M112.02,33.71c.94-.48,1.81.36,4.16,1.17,3.4,1.17,5.34.7,11.8.46,7.5-.27,6.3.31,12.74,0,7.39-.36,6.92-1.03,12.4-1.08,8-.07,8.43,1.36,13.35.4,4.54-.88,6.68-2.59,8.63-1.35,2.12,1.36,2.04,4.98,2.02,6.47-.17,17.16,6.39,33.3,3.28,34.27-.95.3-1.25-1.31-3.69-2.43-4.84-2.2-9.64,1.39-17.78,3.75-5.48,1.59-9.82,1.85-12.93,2.04,0,0-9.76.6-23.21-2.45-2.77-.63-1.56-.59-5.66-1.66-2.6-.69-3.9-.91-4.46-1.98-.64-1.23.14-2.79.4-3.37.71-1.57.99-3.36-.94-20.09-1.15-9.92-1.71-13.35-.11-14.17v.02Z"/>
  </g>
  <g id="Spinousprocess">
    <path id="spinousprocesshighlight9" class="Dornfortsatz1531Fill" style= {{
            fill: props.colors.Dornfortsatz1531Fill,
            stroke: props.colors.Dornfortsatz1531Fill,
            opacity:props.colors.Dornfortsatz1531Fill,
          }} d="M135.46,2.32c2.56,5.18,5.17,14.9,6.08,23.25.86,7.89.41,12.78,1.98,13.1h-.01c1.15.22,2.54-2.15,3.28-3.4,2.4-4.08,2.92-7.93,3.37-12.94.32-3.59,1.08-12.96,2.43-18.07.18-.69.39-1.35.64-1.96-5.72.58-12.01-.08-17.77.02Z"/>
    <path id="spinousprocesshighlight8" class="Dornfortsatz1531Fill" style= {{
            fill: props.colors.Dornfortsatz1531Fill,
            stroke: props.colors.Dornfortsatz1531Fill,
            opacity:props.colors.Dornfortsatz1531Fill,
          }} d="M146.26,68.17c.94-.12,1.53-.98,1.75-1.31,1.58-2.32,1.02-7.24.99-11.43-.02-2.27-.08-11.52,2.88-14.36.15-.15.59-.54.49-.88-.3-1.03-5.26-.37-7.07-.13-2.05.27-7.86,1.05-7.94,2.53-.02.32.25.43.86,1.21,0,0,.77,1,1.67,3.19.41.99,2.13,5.83,2.53,15.07.06,1.34.13,3.67,1.68,5.17.26.25,1.12,1.08,2.15.95h.01Z"/>
    <path id="spinousprocesshighlight7" class="Dornfortsatz1531Fill" style= {{
            fill: props.colors.Dornfortsatz1531Fill,
            stroke: props.colors.Dornfortsatz1531Fill,
            opacity:props.colors.Dornfortsatz1531Fill,
          }} d="M143.55,101.71c.8-.07,1.35-.51,1.52-.64.82-.66,1.16-1.67,1.41-5.97.23-3.88.04-4.51-.46-4.95-.7-.63-1.75-.65-2.93-.67-.82-.02-1.66-.02-2.19.54-.43.45-.46,1.07-.47,1.35-.34,7.09-.46,8.33.67,9.37.2.18,1.18,1.09,2.46.98h-.01Z"/>
    <path id="spinousprocesshighlight6" class="Dornfortsatz1531Fill" style= {{
            fill: props.colors.Dornfortsatz1531Fill,
            stroke: props.colors.Dornfortsatz1531Fill,
            opacity:props.colors.Dornfortsatz1531Fill,
          }} d="M148.28,134.11c1.42.27,2.89-.35,3.79-1.22,1.41-1.34,1.38-3.19,1.35-5.43-.03-1.87-.07-4.61-1.8-5.6-.59-.34-1.16-.32-2.31-.3-1.43.03-3.82.07-4.95,1.59-.74,1-.62,2.2-.45,3.57.09.79.8,6.7,4.37,7.39Z"/>
    <path id="spinousprocesshighlight5" class="Dornfortsatz1531Fill" style= {{
            fill: props.colors.Dornfortsatz1531Fill,
            stroke: props.colors.Dornfortsatz1531Fill,
            opacity:props.colors.Dornfortsatz1531Fill,
          }} d="M147.39,152.98c-.58,0-1.05,1.47-1.48,2.97-2.42,8.31-3.58,12.5-1.42,14.69.7.71.99.45,1.57,1.2,2,2.56-.75,6.4.75,7.75,1.22,1.09,4.1-.45,4.36-.6,2.57-1.41,3.27-4.14,3.43-4.79.22-.87.49-1.91.15-3.11-.63-2.28-2.57-2.09-4.06-4.55-.93-1.53-1.08-3.1-1.35-5.73-.18-1.77-.06-2.59-.47-4.45-.22-.96-.75-3.36-1.48-3.37h0Z"/>
    <path id="spinousprocesshighlight4" class="Dornfortsatz1531Fill" style= {{
            fill: props.colors.Dornfortsatz1531Fill,
            stroke: props.colors.Dornfortsatz1531Fill,
            opacity:props.colors.Dornfortsatz1531Fill,
          }} d="M147.93,204.83c-.31,0-1.59.04-2.09.87-.48.8.24,1.57.4,3.37.13,1.42-.18,2.52-.32,3.02-.63,2.29-1.83,2.6-2.53,4.68-.41,1.22-.36,2.19-.28,3.63.13,2.41.35,6.62,2.59,7.48,2.3.89,6.08-2,6.82-5.15.22-.93-.04-.99-.48-4.16-.72-5.15-1.08-7.72-.94-9.57.07-.89.2-1.96-.45-2.88-.59-.82-1.66-1.32-2.72-1.29Z"/>
    <path id="spinousprocesshighlight3" class="Dornfortsatz1531Fill" style= {{
            fill: props.colors.Dornfortsatz1531Fill,
            stroke: props.colors.Dornfortsatz1531Fill,
            opacity:props.colors.Dornfortsatz1531Fill,
          }} d="M148.01,284.88c1.83.15,3.43-3.21,3.93-4.25,1.81-3.81,1.69-6.96,1.21-15.67-.58-10.68-.86-14.42-3.13-15.36-.08-.03-1.18-.47-2.27-.04-1.85.73-2.43,3.46-2.8,6.19-1.03,7.65.37,9.01-.2,16.08-.29,3.52-.88,6.21.46,9.63.36.92,1.3,3.3,2.79,3.42h.01Z"/>
    <path id="spinousprocesshighlight2" class="Dornfortsatz1531Fill" style= {{
            fill: props.colors.Dornfortsatz1531Fill,
            stroke: props.colors.Dornfortsatz1531Fill,
            opacity:props.colors.Dornfortsatz1531Fill,
          }} d="M145.44,327.98c3.44-1.07,6.06-6,6.38-10.83.09-1.37-.11-1.14-.72-5.93-1.06-8.38-.69-10.95-2.55-11.97-1.21-.67-2.88-.41-3.8.36-1.43,1.2-1.11,3.73-.64,7.12.54,3.91,1.14,3.64,1.18,5.49.12,5.53-5.22,7.45-4.38,12.2.06.34.51,2.88,2.28,3.59.92.37,1.83.1,2.24-.03h.01Z"/>
    <path id="spinousprocesshighlight1" class="Dornfortsatz1531Fill" style= {{
            fill: props.colors.Dornfortsatz1531Fill,
            stroke: props.colors.Dornfortsatz1531Fill,
            opacity:props.colors.Dornfortsatz1531Fill,
          }} d="M147.57,348.15c-.45-.38-1.19-.77-1.99-.99-.19-.05-2.26-.58-4.05.38-1.63.88-2.29,2.61-2.71,3.94-2.08,6.66-1.52,14.44-1.34,16.82.09,1.15.18,2.17.28,3.1.3-.03.61-.05.92-.05.05,0,.1,0,.15,0,.05,0,.1,0,.15,0,.14,0,.26.01.39.02.71-.2,1.49-.32,2.34-.32.67,0,1.3.07,1.88.2.55-.12,1.13-.2,1.77-.2.54,0,1.05.05,1.53.14.04-.16.1-.32.14-.48,1.1-4.28.74-5.42,1.5-14,.37-4.21.74-7.13-.97-8.56Z"/>
  </g>
  <g id="Platten">
    <path id="baseandupperplateshighlight13" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M111.69,69.58c2.37-1.64,6.49.52,7.89,1.21,7.26,3.59,21.28,7.14,31.25,5.66,10.38-1.54,16.29-8.54,24.83-6.91,1.51.29,5.01.96,5.31,2.66.17.99-.75,2.31-1.72,2.43-.99.12-1.72-1.06-2.33-1.62-2.8-2.57-10.32,1.43-14.26,3.13,0,0-10.41,4.51-22.35,3.44-5.27-.47-13.15-2.31-18.03-4.26-3.02-1.2-6.16-2.96-9.7-2.19-.73.16-1.97.53-2.41-.02-.56-.69.26-2.67,1.52-3.54h0Z"/>
    <path id="baseandupperplateshighlight12" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M188.5,334.64c-1.42,1.13-3.51.79-12.59-2.34-4.49-1.54-6.73-2.32-8.49-3.03-15.06-6.13-49.46,5.15-58.36,3.51-.62-.11-2.52-.46-3.52-1.97-1.09-1.63-.82-4.16.04-4.49.65-.25,1.22.93,2.59,1.53,2.32,1.01,4.95-.51,6.46-1.24,2.89-1.39,7.93-1.59,18.02-2,1.21-.05.48,0,9-.1,11.31-.13,10.4-.19,11.63-.16,9.09.23,16.94.43,24.47,4.31,2.1,1.08,5.79,3.28,8.73,1.85,1.77-.86,2.42-2.64,3.05-2.39.92.36,1.15,4.81-1.01,6.52h-.02Z"/>
    <path id="baseandupperplateshighlight11" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M189.82,264.28c.39,1.07-.62,2.23-.91,2.56-1.25,1.44-3.11,1.84-4.06,1.97-5.83.77-24.8-2.57-34.67-3.05-6.13-.29-14.18.27-25.89,1.08,0,0-2.21.15-11.81-1.86-.91-.19-2.59-.57-3.52-1.97-1.09-1.63-.82-4.16.04-4.49.7-.27,1.61.74,2.59,1.53,2.49,1.99,5.21,2.29,9.46,2.75,6.36.69,11.83-.61,18.88-1.08,3.94-.26,12.25-.82,22.11,1.27,4.82,1.02,9.71,1.66,14.54,2.63.9.18,8.78,1.78,10.4-.93.25-.42.62-1.4,1.35-1.48.59-.07,1.26.47,1.48,1.08h.01Z"/>
    <path id="baseandupperplateshighlight10" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M182.9,207.11c-3.02,5.18-16.94-3.38-35.88-1.47-7.44.75-13.75,3.55-27.94,5.84-1.95.32-6.98,1.06-9.7-1.71-.19-.2-2.13-2.24-1.49-3.55.17-.35.55-.71,1.01-.76,1.13-.13,1.67,1.73,3.1,2.66,1.82,1.19,4.3.35,5.46,0,3.92-1.19,21.58-5.68,32.49-5.93,12.8-.3,21.84,5.09,28.45,1.33.7-.4,3.28-1.98,4.45-1.08,1.03.79.81,3.35.05,4.66h0Z"/>
    <path id="baseandupperplateshighlight9" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M107.98,115.94c2.73-2.22,7.81-.51,10.22.24,0,0,3.09.97,13.62,3.11,11.71,2.38,23.32-.29,30.18-1.87,2.49-.57,4.16-.78,7.51-1.2,10.14-1.27,11.24.03,11.54.74.42,1,0,2.62-.7,3-.69.37-1.62-.44-2.07-.77-2.15-1.61-4.83-1.05-9.86,0-1.32.27-8.69,1.69-10.39,2.13-2.07.54-13.77,3.61-26.32.74-4.99-1.14-10.12-1.48-15.01-3.16-1.24-.43-5.76-2.04-7.59.26-.47.59-.89,1.61-1.5,1.53-.62-.08-1.18-1.26-1.14-2.27.05-1.31,1.09-2.15,1.49-2.48h.02Z"/>
    <path id="baseandupperplateshighlight8" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M182.29,108.27c-1.99,2.54-19.95-1.67-34.04-2.83-17.16-1.42-30.26,1.55-33.03,3.51-.15.1-1.56,1.12-3.57,1.35-.28.03-3.23.34-3.84-1.01-.26-.58-.08-1.43.41-1.77.55-.39,1.27.03,2.11.15,1.92.26,3.54-1.23,4.16-1.68,3.18-2.3,18.42-4.98,36.87-3.34,6.89.61,10.33.92,13.16,1.54,4.64,1.02,14.59,3.74,16.31,1.06.21-.33.56-1.15,1.08-1.15.57,0,1.14.99,1.21,1.89,0,0,.09,1.14-.81,2.29h-.02Z"/>
    <path id="baseandupperplateshighlight7" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M146.28,28.53s-7.38,1.58-14.01,1.59h0c-1.04.37-10.11,3.63-15.27,2.54-.18-.04-2.3-.52-4.31-2.66-.75-.8-1.54-1.66-1.47-2.72.07-1.18,1.18-2.2,1.99-1.92.8.27.82,1.63,1.67,2.59,1.31,1.47,3.38.64,4.06.39,6.97-2.52,14.51-1.22,21.73-2.22,10.5-1.45,15.75-2.14,19.12-.88.45.17,6.73,2.53,10.76,2.29.95-.06,1.91-.04,2.86-.08,3-.11,3.82-.22,4.25.29.63.74.13,2.39-.43,3.2-3.36,4.85-19.61-4.83-30.94-2.4h0Z"/>
    <path id="baseandupperplateshighlight6" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M143.02,33.19c7-.13,7.08.52,14.48.4,6.78-.11,10.17-.16,11.63-.42,3.72-.67,7.1-1.81,8.07.02.35.66.39,1.71.03,2.21-.56.78-1.87-.04-3.51-.35-2.16-.4-3.58.34-5.15.8-3.39,1-5.33.1-12.75.15-7.57.04-7.47.99-14.06.76-5.64-.19-5-.86-8.82-.68-5.5.26-7.84,1.7-12.63,1.3-2.35-.19-3.02-.64-5.18-.43-1.7.17-2.78.59-3.44-.24-.61-.77-.67-2.41-.1-3.26.94-1.42,3.45-.4,5.22.15,1.66.52,6.1.44,14.99.28,6.24-.11,5.22-.57,11.23-.69h-.01Z"/>
    <path id="baseandupperplateshighlight5" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M176.59,155.91c-.16.94-.95,1.81-1.67,1.79-.61-.02-.94-.68-2.18-2.38-.59-.81-1.25-1.56-1.84-2.38-3.3-4.53-35.98-2.69-41.58-2.52-1.41.04-3.25.05-5.66.8-1.96.61-2.61,1.18-7.48,3.34-2.3,1.02-3.73,1.58-5.36.99-.45-.16-1.59-.6-2.11-1.83-.63-1.47-.12-3.51.57-3.68.55-.14.76,1.04,1.91,1.75,1.46.9,3.18.28,5.16-.46,2.12-.8,3.57-1.78,3.59-1.79,3.79-2.57,10.04-2.35,13.05-2.31,11.42.16,27.56-1.61,35.8.97.21.07,2.81.91,5.34,3.37,1.05,1.02,2.76,2.68,2.47,4.34h-.01Z"/>
    <path id="baseandupperplateshighlight4" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M108.31,166.27c-.36-1.26.6-2.64.87-3.03,1.19-1.71,3.08-2.19,3.82-2.35,5.6-1.24,24.89.75,32.67.98,9.17.27,13.18-1.56,26.6-.76,3.96.24,10.11.78,10.41,2.85.12.82-.72,1.6-.92,1.78-2.43,2.27-6.79.35-10.11-.63-1.24-.37-.66-.11-21.54-.03-8.05.03-10.46,0-13.75-.17-4.09-.21-8.73-.6-9.1-.63-7.51-.63-14.84-1.52-16.29,1.51-.25.51-.59,1.65-1.27,1.76-.55.09-1.17-.54-1.38-1.26v-.02Z"/>
    <path id="baseandupperplateshighlight3" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M106.78,219.58s.05-1.14,1.17-2.18c2.84-2.65,19.37.11,27.82,1.04,6.82.75,12.16.72,14.25.68,9.93-.17,20.13-1.67,30.74.1,5.78.96,6.54,2.45,6.67,3.13.16.81-.28,1.86-1.01,2.22-1.01.5-2.27-.5-3.65-1.2-1.57-.8-3.47-.97-7.27-1.3-6.26-.54-14.87.04-23.34.24-4.87.12-8.26.24-15.04-.11-5.02-.26-7.53-.39-9.94-.77-2.54-.4-2.44-.57-6.44-1.27-7.78-1.37-9.6-1.03-10.88-.04-.76.59-1.51,1.55-2.22,1.31-.59-.2-.89-1.13-.86-1.87v.02Z"/>
    <path id="baseandupperplateshighlight2" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M106.84,279.92c.96-4.75,27.25-3.31,30.24-3.13,9.05.53,7.01,1.14,13.45,1.31,11.28.3,21.25-1.49,32.05.88,3.02.66,6.32,1.66,6.65,3.72.16.97-.29,2.23-1.03,2.68-1.02.61-2.27-.59-3.64-1.42-1.44-.87-2.59-.99-7.26-1.51-13.38-1.48-22.94-1.62-22.94-1.62-16.89-.46-39.72-3.56-43.37.63-.23.27-1.28,1.59-2.34,1.39-1.05-.19-2.03-1.87-1.82-2.93h0Z"/>
    <path id="baseandupperplateshighlight1" class="Grund_Deckplatten1531Fill" style= {{
            fill: props.colors.Grund_Deckplatten1531Fill,
            stroke: props.colors.Grund_Deckplatten1531Fill,
            opacity:props.colors.Grund_Deckplatten1531Fill,
          }} d="M104.9,343.53c.31-.65.91-.6,2.81-1.21,1.76-.57,2.67-1.07,3.25-1.29,4-1.51,34.98-5.59,71.91,1.38,1.91.36,5.71,1.13,6.38,3.87.37,1.52-.34,3.29-1.1,3.95-1.05.92-2.32-.82-3.7-2.03-.36-.32-.53-.42-.87-.63-1.23-.77-2.29-.94-2.67-1-37.77-6.41-47.32-5.21-66.44-2.81-2.64.33-4.05.76-5.35,1.96-1.16,1.08-1.59,2.18-2.42,2.08-1.26-.15-2.43-2.98-1.82-4.28h.02Z"/>
  </g>
</g>
</svg>
  );
}

export default ThoracolumbalerUbergangVdLiegend1531;
