import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const AnkleLeft = map.AnkleLeft;

function FrontView({ selected, bpartSetter }) {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 165.3 296.4"
    >
      <g id="ankle">
        <path
          id="Tibia"
          class="lankle-cls-0"
          d="M115.2,4.7c-0.2,35.4,1.8,77.8,6.9,113.5c6.4,24.2,13.9,46.8,24.4,65.1
     c10.6,22.4-5.7,34.2-14.6,35.4c-2.6-1.1-4.3-3-4.5-6.1c-6.2-14.5-16.1-16.6-28.1-11.8c-12.8,2-25.1,3-38.6,0
     c-10.1-11.3-12.8-21.1-13.4-30.5c6.6-18.2,12.6-44.1,18.3-75.2c2.7-25,1.5-60.7,2-90.2"
        />
        <path
          id="Fibula"
          class="lankle-cls-0"
          d="M52.3,4.8c-0.3,29-3.1,91.1-0.9,150.4l-4.1,15c-0.1,5.9,3.2,15.6,8.6,25.4
     c1.6,14.6-9.2,42.6-16.4,41c-2.3,0.1-3.6,1.6-5.9,5c-6.5,6.6-12.9-3.1-14.9-11.2c1.3-24.8,11.3-58.6,12.3-90.6
     c2.4-49.7,5.4-95,5.6-135.1"
        />
        <path
          id="Talus"
          class="lankle-cls-0"
          d="M70.5,262.2c-1.1,1.3-1.4,2.8-0.8,4.5c-13.2,6.3-20-1.1-24.8-13c1.3-4.9,4.7-9.8,9.2-14.8
     c-0.6-7,0.6-14.2,1.3-23.1c1.1-4.6,2.5-8.3,9.1-11.7c8.1-1.1,16.9-3.4,22.9,0.3l11.9-3.7c9.1-1,18.3-2.1,21.6,3.7
     c3.6,15.1,12.9,22.9,14.3,27.1c0.5,4.7-1.2,10-3.8,14.8c1.3,7.5,17.9,13.7,8,22c-2.5,3.4-7.9,7.8-14.5,10.2
     c-18.7,1.1-34.6-0.3-44.3-3.7C81.6,269,81.3,261,70.5,262.2L70.5,262.2z"
        />
        <path
          id="dark_spot"
          class="lankle-cls-1"
          d="M84.1,275.9c-4.3,0.3-7.1,2.5-9.1,5.8l-1.3-5c-2-2.4-3.2-6-4.1-10c0.1-1.8,0.3-3.3,0.8-4.5
     c8.1-0.5,11.7,3.6,10.2,12.6L84.1,275.9L84.1,275.9z"
        />
        <g id="scaphoid">
          <path
            id="scaphoid_2"
            class="lankle-cls-0"
            d="M75,292.2c0.5-12.7,0-17.7,17.8-15c6.3,2.1,19.3,1.7,32.1,1.3c6.4-1.3,17.3-13.9,20.8-11
       c7.1,3.3,7.6,13.4,4.5,24.6"
          />
          <path
            id="scaphoid_1"
            class="lankle-cls-0"
            d="M75,292.2c0-5.9,0.1-13.9-1.3-15.6c-2.2-1.5-2.9-5.6-4.1-10c-14,6.9-19.9-2-24.8-13
       c0.3,2.2,0.6,4.5,2.1,7.3c1.1,5.3,0.8,9.1,0.7,10.6c-2.9,6.7-5.3,10.9-5.3,17.1c0.8,1,0.3,2.3-0.4,3.7"
          />
          <path
            id="scaphoid_inner_line"
            class="lankle-cls-0"
            d="M42.4,288.7c9.9-3,19.1-7.7,31.9-2.2"
          />
        </g>
        <path
          id="talus_inner_line_1"
          class="lankle-cls-0"
          d="M133.9,230.1c-4.8,6.5-9.2,9.2-17.9-1.6c-2.9-7.5-2.8-18.1-2-25.6"
        />
        <path
          id="talus_inner_line_2"
          class="lankle-cls-0"
          d="M115.6,262.6c6.2,5.5,9.5,5.1,16.6-1.7c0.7-3.5,0.1-8.5-3.1-10.2
     c-2.5-1.3-6.7-2.1-9.9-2"
        />
      </g>
      <g id="selections">
        <path
          id="down_ankle_selection"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, AnkleLeft.id, AnkleLeft.children.midAnkle),
            });
          }}
          class={`lankle-cls-2 ${isSelected(
            selected,
            AnkleLeft.children.midAnkle
          )}`}
          d="M127.4,212.6c-0.5,2.8,1.2,4.4,4.5,6.1c18.9-4.5,22-22.8,14.6-35.4l-10.1-20.4H29.1
     c-4,29-10.5,56.1-10.3,67.6c1,7.9,9.1,18.1,14.9,11.2c1.3-2.6,3.2-4.3,5.9-5c8.8,0.7,17.1-24.1,16.4-41c0.7,1.2,2.1,2.8,4.8,5.1
     l3.5,3.2c-4.9,2.9-8.5,6.3-8.8,11.9l70.2,0.3c-2.6-4.5-3.8-9.6-5.3-13.6C122.1,204.2,124.4,206.1,127.4,212.6L127.4,212.6z"
        />
        <path
          id="middle_ankle_selection"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                AnkleLeft.id,
                AnkleLeft.children.lowerAnkle
              ),
            });
          }}
          class={`lankle-cls-2 ${isSelected(
            selected,
            AnkleLeft.children.lowerAnkle
          )}`}
          d="M55.4,216c-1.3,8.5-2.3,16.8-1.2,22.9c-2.1,1.3-10.3,12.5-9.4,14.9
     c0,2.7,0.4,5.2,2.1,7.3c0.7,3.4,0.9,6.9,0.7,10.6c-4.4,6.9-5.6,12.3-5.2,16.8c0.6,1.4,0.7,2.6-0.8,3.5h108.6
     c3.4-15.1,1.8-21-4.6-24.4c-0.8-1.2-3.2-0.5-6.2,0.8c10.2-7.4-6-14.7-8-22c2.7-4.2,4.4-8.7,3.8-14.8c-1.4-3.5-8.2-10.3-9.7-15.4
     C125.6,216.2,55.4,216,55.4,216L55.4,216z"
        />
        <path
          id="upper_ankle_selection"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                AnkleLeft.id,
                AnkleLeft.children.upperAnkle
              ),
            });
          }}
          class={`lankle-cls-2 ${isSelected(
            selected,
            AnkleLeft.children.upperAnkle
          )}`}
          d="M52.3,4.8L36.8,4.8c-1,51-3.6,103.8-7.6,158.3h107.4c-5.9-15.2-10.2-29.2-14-43.9
     c-5.6-36.5-7.4-81-7.3-114.1L67.5,4.9c-0.4,30.5,0.8,61.3-1.9,90.2c-3.4,20.3-8.4,40.4-13.9,59.7C49.9,110,51.1,58.7,52.3,4.8
     L52.3,4.8z"
        />
      </g>
    </svg>
  );
}

export default FrontView;
