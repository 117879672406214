import style from "./style.scss";

function Brustwirbelkorper5VdLiegend1515(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.09 167.75">

<g id="BG">
  <path id="BG_white" data-name="BG white" class="Brustwirbelsaule5_ap1515_cls4" d="M.1,66.9l13.69,14.79c14.56-6.65,29.89-10.46,46.23-10.52,10.16.71,20.31,3.67,30.46,7.85,4.01,2.83,8.29,3.59,12.77,2.69l7.93-.02-3.84,16.4c-1.9,6.22.17,9.39,6.77,9.09l-.9,9.17-3.72-.36c-5.01-8.22-14.75-11.75-28.06-11.74-31.65,1.28-54.72,9.14-72.3,21.21-.16.11-.04.36.14.31,22.91-5.9,46.95-7.15,71.58-6-1.29,4.49-.04,7.76,3.58,9.88l4.14,1.83c2.18-.33,3.99.94,5.62,2.92,2.17,3.16,5.01,5.97,8.98,8.21,1.96,1.56,2.86,4.12,3.21,7.22,1.39.74,2.94-.74,4.58-3.58l-3.34,7.01c-1.25,3.29-.75,6.08,1.88,8.26,3.57,3.25,7.38,4,11.37,2.99,7.33-1.48,17.84-1.17,28.93-.53v2.05l2.13,1.7.71-3.54,8.63.92,14.89-.59,11.85,1.49c3.28-.21,4.84-1.88,5.34-4.45.15-4.14-1.09-9.17-2.92-14.56l3.58-.9c2.84-1.88,5.52-2.57,7.99-1.66,6.7,1.34,14.01,1.12,21.2-3.18,6.08-3.68,8.7-9.36,9.84-15.91-.41-2.29-1.31-4.22-2.46-5.95,16.78-1.08,39.29-.19,63.49,1.28l-29.26-7.28c-17.61-3.42-33.9-6.26-46.75-7.59l-11.64-1.23c-5.48,0-9.83,1.81-13.1,5.31-2.01,1.85-3.66,4.35-4.49,8.25l-5.54.9c.92-3.84-.14-6.82-2.72-9.15l.47-3.38,5.53-.12c5.57-1.19,7.44-4.89,5.75-11l-2.79-8.85c4.58-2.35,8.85-3.38,12.49-1.71,3.16.88,6.2-.82,9.49-2.9,5.09-5.6,7.35-9.44,5.14-11.49l1.41-1.12c27.55-1.24,52.14.59,69.96,9.43.2.1.37-.17.2-.31l-39.25-32.64c-5.6,1.83-12.66,2.74-20.44,3.19-7.33-2.64-15.87-4.67-25.01-6.38-7.73,0-12.16,4.78-13.34,12.06-.81-.16-3.96,1.46-8.07,3.8-1.47-.91-1.93-2.55-.61-5.46,4.29-1.48,7.07-4.57,7.29-10.4l-.22-19.79,1.61-14.66c-.63-6.09-4.21-7.73-8.69-8.03l-22.77,4.45c-7.02,1.59-12.39.95-17.09-.6-10.11-2.92-19.21-3.24-27.33-1.01-4.73,1.06-8.47,2.47-7.16,5.65,1.89,2.49,3.12,5.72,3.48,9.92,1.38,7.18.69,12.91-1.91,17.27-1.72,2.49-2.55,5.52-2.6,9.03.56,2.79,1.23,5.36,2.91,5.95-.94,3.28-2.45,4.85-4.49,4.86-.81-8.26-4.08-9.51-7.61-11.11-5.91-2.49-14.83-2.33-24.57-1.46-11.81,1.27-24.57,4.34-37.84,8.35C19.48,55.83,7.27,60.82.1,66.9Z"/>
</g>
<g id="BWK5">
  <g id="BWK5_Parts" data-name="BWK5 Parts">
    <path id="BWK5_Part28" data-name="BWK5 Part28" class="Brustwirbelsaule5_ap1515_cls6" d="M107.67,49.91c-2.77-2.88-1.16-9.07-.91-10.03.98-3.76,2.43-3.77,3.65-7.75,1.07-3.52.81-6.42.46-10.48-.43-4.89-.66-7.46-2.28-10.48-1.13-2.1-2.3-3.24-1.82-4.56.27-.76.98-1.28,5.01-2.73,2.78-1.01,4.17-1.51,5.47-1.82,8.52-2.05,18.05.09,19.59.46,6.82,1.6,5.52,2.67,10.03,3.19,4.83.56,8.84-.39,16.86-2.28,3.71-.88,5.16-1.39,9.57-2.28,6.24-1.26,8.55-1.28,10.94-.46,1.15.4,4.08,1.42,5.47,4.1,1.05,2.03.58,3.76,0,8.2,0,0-.38,2.91-.91,10.03-.15,1.96-.09,2.2,0,13.22.06,7.21.05,10.86-1.82,13.22-.27.35-1.56,1.91-4.56,3.19-6.2,2.65-11.53.6-18.68-.46-8.85-1.31-7.17.87-20.51,0-13.06-.85-12.71-2.81-20.96-2.28-7.99.51-12.1,2.59-14.58,0,0,0-.02,0-.02,0Z"/>
    <path id="BWK5_Part27" data-name="BWK5 Part27" class="Brustwirbelsaule5_ap1515_cls5" d="M179.67,76.34c-2.31.07-4.08-4.29-4.56-5.47-1.59-3.92-.6-5.4-1.37-10.03-1.23-7.4-4.76-9.66-3.19-12.3,1.21-2.03,4.38-2.5,6.38-2.28.51.06,3.85.42,5.01,2.73,1.16,2.3-1.26,3.74-.91,7.29.39,3.98,3.62,4.33,4.1,8.66.29,2.64-.66,4.79-1.37,6.38-.85,1.93-2.2,4.96-4.1,5.01h0Z"/>
    <path id="BWK5_Part26" data-name="BWK5 Part26" class="Brustwirbelsaule5_ap1515_cls5" d="M193.79,60.4c2.44-2.97,7.94-2.14,10.03-1.82,2.45.37,5.99.91,8.2,3.65,1.42,1.76,1.01,2.83,3.19,5.92,1.75,2.49,2.44,2.38,2.73,3.65.64,2.74-2.04,5.56-4.56,8.2-2.4,2.52-4.07,4.27-6.84,5.01-3.05.82-3.73-.58-8.2-.46-5.79.17-7.34,2.6-9.57,1.37-2.68-1.48-3.52-6.69-2.28-10.48,1.35-4.12,4.39-4.2,5.92-9.11,1.02-3.25.03-4.3,1.37-5.92h.01Z"/>
    <path id="BWK5_Part25" data-name="BWK5 Part25" class="Brustwirbelsaule5_ap1515_cls5" d="M107.21,104.6c-.89-1.45-.3-4.48.91-10.48,2.08-10.32,3.31-10.68,3.19-15.04-.1-3.63-.95-3.45-2.28-12.3-1.12-7.46-.67-8.56,0-9.11,2.05-1.72,6.14,1.56,12.76,3.65,3.89,1.23,4.17.63,19.14,1.37,16.42.81,18.91,1.66,25.97.46,4.11-.7,7.06-1.21,10.94-2.73,7.3-2.87,9.84-6.21,12.3-5.01,2.68,1.3,3.13,6.91,2.28,10.94-1.51,7.12-6.86,8.16-6.48,13.2.22,2.95,2.07,2.74,4.65,9.58,1.4,3.7,1.71,6.02,1.82,7.29.25,2.79.54,6.15-1.37,8.2-1.76,1.89-4.62,1.84-6.38,1.82-7-.05-13.96,1.32-20.96,1.37-10.38.08-8.37.43-18.23.46-4.22.01-9.1-.14-18.68-.46-14.95-.49-18.1-.75-19.59-3.19v-.02h0Z"/>
    <path id="BWK5_Part24" data-name="BWK5 Part24" class="Brustwirbelsaule5_ap1515_cls6" d="M109.49,115.99c1.29-.79,2.6.2,8.66,2.28,0,0,4.04,1.39,9.11,2.73,5.88,1.56,7.49.51,19.14.91,9.63.34,8.74,1.07,15.04.91,4.11-.1,9.36-.23,15.95-1.82,2.47-.6,5.2-1.42,10.94-2.73,6.61-1.52,8.23-1.63,8.66-.91.63,1.06-1.97,2.94-5.47,8.2-2.95,4.43-4.42,6.64-4.56,9.11-.15,2.68,1.05,5.9,3.45,12.34.22.6.38,1,.66,1.78,1.11,3.16,2.04,5.78,2.28,8.2.16,1.59.62,6.21-1.82,8.2-2.15,1.76-5.05.27-10.48-.46-5.34-.71-6.13.25-14.13.46-8.82.23-8.61-.92-20.51-1.37-9.93-.37-14.89-.56-20.05,0-6.8.74-10.58,1.95-14.58-.46-.21-.12-4.89-3.03-5.01-7.29-.06-1.94.88-2.48,2.73-6.38,1.65-3.47,3.26-6.87,3.19-10.48-.06-3.43-1.57-3.66-3.19-8.66-1.51-4.68-2.89-12.81,0-14.58v.02h0Z"/>
    <path id="BWK5_Part23" data-name="BWK5 Part23" class="Brustwirbelsaule5_ap1515_cls6" d="M198.81,144.36c3.24-.48,3.79.77,9.11.84.64,0,3.54.03,6.38-.51,1.65-.31,9.51-1.8,14.58-8.66,1.46-1.98,5.59-7.55,3.65-13.22-1.72-5.02-6.9-6.99-7.75-7.29-3.94-1.41-7.59-.5-11.39.46-3.87.97-4.52,1.94-9.3,2.98-4.16.91-4.19.29-6.19,1.12-4.01,1.65-6.18,5.07-8.05,8.01-1.79,2.81-2.73,4.3-2.88,6.58-.08,1.19.13,1.48,1.77,7.79.96,3.68,1.05,4.19,1.67,4.55,1.87,1.08,3.74-1.96,8.4-2.65h0Z"/>
    <path id="v22" class="Brustwirbelsaule5_ap1515_cls6" d="M86.25,112.8c-3.36,1.86-4.68,5.08-5.01,5.92-.51,1.29-1.57,3.99-.46,6.84.83,2.12,2.46,3.28,3.65,4.1,2.98,2.06,4.31,1.19,7.29,2.73,3.17,1.64,2.23,2.91,6.84,6.84,3.6,3.07,5.03,3.01,6.38,5.47,1.6,2.9.83,5.17,1.82,5.47,1.43.42,5.07-3.64,5.73-8.54.62-4.54-1.89-5.6-3.71-13.61-1.55-6.78-.29-8.38-2.47-11.12-2.42-3.04-6.18-3.84-9.57-4.56-3.46-.74-6.98-1.49-10.48.46,0,0-.01,0,0,0Z"/>
    <path id="BWK5_Part21" data-name="BWK5 Part21" class="Brustwirbelsaule5_ap1515_cls5" d="M175.11,131.48c2.65.2,4.95-4.42,5.92-6.38.61-1.22,4.98-10.01.91-13.67-2.22-2-6.5-2.05-9.11,0-1.98,1.55-2.43,3.85-2.73,5.47-1.18,6.38,1.65,14.32,5.01,14.58Z"/>
    <path id="BWK5_Part20" data-name="BWK5 Part20" class="Brustwirbelsaule5_ap1515_cls6" d="M289.49,79.53c-15.17-8.46-36.63-10.92-71.45-10.03"/>
    <path id="BWK5_Part19" data-name="BWK5 Part19" class="Brustwirbelsaule5_ap1515_cls6" d="M218.04,69.51c-6.94,4.82-18.29,9.6-32.09,10.01"/>
    <path id="BWK5_Part18" data-name="BWK5 Part18" class="Brustwirbelsaule5_ap1515_cls6" d="M190.15,55.38c0-7.05,5.97-12.76,13.34-12.76"/>
    <path id="BWK5_Part17" data-name="BWK5 Part17" class="Brustwirbelsaule5_ap1515_cls6" d="M228.5,49c-3.25-1.99-14.01-5.05-25.01-6.38"/>
    <path id="BWK5_Part16" data-name="BWK5 Part16" class="Brustwirbelsaule5_ap1515_cls6" d="M228.5,49c1.27-.43,10.98-.03,20.44-3.19"/>
    <path id="BWK5_Part15" data-name="BWK5 Part15" class="Brustwirbelsaule5_ap1515_cls6" d="M294.05,120.7c-9.04-3.04-45.3-12.15-87.65-16.1"/>
    <path id="BWK5_Part14" data-name="BWK5 Part14" class="Brustwirbelsaule5_ap1515_cls6" d="M189.84,128.1c-4.35-11.92,2.49-22.29,16.55-23.5"/>
    <path id="BWK5_Part13" data-name="BWK5 Part13" class="Brustwirbelsaule5_ap1515_cls6" d="M151.92,167.73c5.53-26.73,6.56-52.02,10.22-52.92"/>
    <path id="BWK5_Part12" data-name="BWK5 Part12" class="Brustwirbelsaule5_ap1515_cls6" d="M149.8,166.03c0-28.4-3.61-49.68-10.69-49.68"/>
    <path id="BWK5_Part11" data-name="BWK5 Part11" class="Brustwirbelsaule5_ap1515_cls5" d="M151.92,100.95c0-18.64,1.6-33.72,3.57-33.72"/>
    <path id="BWK5_Part10" data-name="BWK5 Part10" class="Brustwirbelsaule5_ap1515_cls5" d="M149.81,100.95c-5.51-25.05-6.59-37.17-10.7-33.72"/>
    <path id="BWK5_Part9" data-name="BWK5 Part9" class="Brustwirbelsaule5_ap1515_cls6" d="M147.72,67.23c6.65-10.41,1.32-35.25-3.26-40.19"/>
    <path id="BWK5_Part8" data-name="BWK5 Part8" class="Brustwirbelsaule5_ap1515_cls6" d="M147.72,67.23c-4.55-18.06-9.1-28.43-15.45-26.89"/>
    <path id="BWK5_Part7" data-name="BWK5 Part7" class="Brustwirbelsaule5_ap1515_cls6" d="M8.33,126.02c16.8-15.14,88.43-34.7,101.16-10.03"/>
    <path id="BWK5_Part6" data-name="BWK5 Part6" class="Brustwirbelsaule5_ap1515_cls6" d="M13.79,81.69c68.57-31.35,81.99,19.32,95.7-7.17"/>
    <path id="BWK5_Part5" data-name="BWK5 Part5" class="Brustwirbelsaule5_ap1515_cls6" d="M.1,66.9c8.06-10.39,106.32-42.18,104.39-11.24"/>
    <path id="BWK5_Part4" data-name="BWK5 Part4" class="Brustwirbelsaule5_ap1515_cls5" d="M120.45,102.78c11.81,17.93-12.06,49.09-6.34,4.4"/>
    <path id="BWK5_Part3" data-name="BWK5 Part3" class="Brustwirbelsaule5_ap1515_cls5" d="M116.32,72.24c-24.02-14.02,9.8-53.06,4.98-5.59"/>
    <path id="BWK5_Part2" data-name="BWK5 Part2" class="Brustwirbelsaule5_ap1515_cls5" d="M87.27,76.35c-4.66-9.69,13.98-18.12,21.12-15.06"/>
    <path id="BWK5_Part1" data-name="BWK5 Part1" class="Brustwirbelsaule5_ap1515_cls5" d="M87.27,76.35c0,2.95,10.69,5.34,23.91,5.34"/>
  </g>
</g>
<g id="BWK5_Highlights" data-name="BWK5 Highlights">
  <path id="BWK5_Highlight3" data-name="BWK5 Highlight3" class="Wirbelkorper_Querfortsatze1515Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1515Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1515Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1515Fill,
          }} d="M193.43,60.4c2.44-2.97,7.94-2.14,10.03-1.82,2.45.37,5.99.91,8.2,3.65,1.42,1.76,1.01,2.83,3.19,5.92,1.75,2.49,2.44,2.38,2.73,3.65.64,2.74-2.04,5.56-4.56,8.2-2.4,2.52-4.07,4.27-6.84,5.01-3.05.82-3.73-.58-8.2-.46-5.79.17-7.34,2.6-9.57,1.37-2.68-1.48-3.52-6.69-2.28-10.48,1.35-4.12,4.39-4.2,5.92-9.11,1.02-3.25.03-4.3,1.37-5.92h.01Z"/>
  <path id="BWK5_Highlight2" data-name="BWK5 Highlight2" class="Wirbelkorper_Querfortsatze1515Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1515Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1515Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1515Fill,
          }} d="M106.85,104.6c-.89-1.45-.3-4.48.91-10.48,2.08-10.32,3.31-10.68,3.19-15.04-.1-3.63-.95-3.45-2.28-12.3-1.12-7.46-.67-8.56,0-9.11,2.05-1.72,6.14,1.56,12.76,3.65,3.89,1.23,4.17.63,19.14,1.37,16.42.81,18.91,1.66,25.97.46,4.11-.7,7.06-1.21,10.94-2.73,7.3-2.87,9.84-6.21,12.3-5.01,2.68,1.3,3.13,6.91,2.28,10.94-1.51,7.12-6.86,8.16-6.48,13.2.22,2.95,2.07,2.74,4.65,9.58,1.4,3.7,1.71,6.02,1.82,7.29.25,2.79.54,6.15-1.37,8.2-1.76,1.89-4.62,1.84-6.38,1.82-7-.05-13.96,1.32-20.96,1.37-10.38.08-8.37.43-18.23.46-4.22.01-9.1-.14-18.68-.46-14.95-.49-18.1-.75-19.59-3.19v-.02h0Z"/>
  <path id="BWK5_Highlight1" data-name="BWK5 Highlight1" class="Wirbelkorper_Querfortsatze1515Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1515Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1515Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1515Fill,
          }} d="M86.91,76.35c0,2.95,10.69,5.34,23.91,5.34,1.54-1.26-1.04-10.52-2.79-20.4-7.14-3.06-25.78,5.37-21.12,15.06"/>
</g>
<g id="angrenzende_BWK_Highlights" data-name="angrenzende BWK Highlights">
  <path id="angrenzende_BWKHighlight4" data-name="angrenzende BWKHighlight4" class="BWK1515Fill" style= {{
            fill: props.colors.BWK1515Fill,
            stroke: props.colors.BWK1515Fill,
            opacity:props.colors.BWK1515Fill,
          }} d="M108.03,50.33c-2.77-2.88-1.16-9.07-.91-10.03.98-3.76,2.43-3.77,3.65-7.75,1.07-3.52.81-6.42.46-10.48-.43-4.89-.66-7.46-2.28-10.48-1.13-2.1-2.3-3.24-1.82-4.56.27-.76.98-1.28,5.01-2.73,2.78-1.01,4.17-1.51,5.47-1.82,8.52-2.05,18.05.09,19.59.46,6.82,1.6,5.52,2.67,10.03,3.19,4.83.56,8.84-.39,16.86-2.28,3.71-.88,5.16-1.39,9.57-2.28,6.24-1.26,8.55-1.28,10.94-.46,1.15.4,4.08,1.42,5.47,4.1,1.05,2.03.58,3.76,0,8.2,0,0-.38,2.91-.91,10.03-.15,1.96-.09,2.2,0,13.22.06,7.21.05,10.86-1.82,13.22-.27.35-1.56,1.91-4.56,3.19-6.2,2.65-11.53.6-18.68-.46-8.85-1.31-7.17.87-20.51,0-13.06-.85-12.71-2.81-20.96-2.28-7.99.51-12.1,2.59-14.58,0,0,0-.02,0-.02,0Z"/>
  <path id="angrenzende_BWKHighlight3" data-name="angrenzende BWKHighlight3" class="BWK1515Fill" style= {{
            fill: props.colors.BWK1515Fill,
            stroke: props.colors.BWK1515Fill,
            opacity:props.colors.BWK1515Fill,
          }} d="M109.85,116.41c1.29-.79,2.6.2,8.66,2.28,0,0,4.04,1.39,9.11,2.73,5.88,1.56,7.49.51,19.14.91,9.63.34,8.74,1.07,15.04.91,4.11-.1,9.36-.23,15.95-1.82,2.47-.6,5.2-1.42,10.94-2.73,6.61-1.52,8.23-1.63,8.66-.91.63,1.06-1.97,2.94-5.47,8.2-2.95,4.43-4.42,6.64-4.56,9.11-.15,2.68,1.05,5.9,3.45,12.34.22.6.38,1,.66,1.78,1.11,3.16,2.04,5.78,2.28,8.2.16,1.59.62,6.21-1.82,8.2-2.15,1.76-5.05.27-10.48-.46-5.34-.71-6.13.25-14.13.46-8.82.23-8.61-.92-20.51-1.37-9.93-.37-14.89-.56-20.05,0-6.8.74-10.58,1.95-14.58-.46-.21-.12-4.89-3.03-5.01-7.29-.06-1.94.88-2.48,2.73-6.38,1.65-3.47,3.26-6.87,3.19-10.48-.06-3.43-1.57-3.66-3.19-8.66-1.51-4.68-2.89-12.81,0-14.58v.02h0Z"/>
  <path id="angrenzende_BWKHighlight2" data-name="angrenzende BWKHighlight2" class="BWK1515Fill" style= {{
            fill: props.colors.BWK1515Fill,
            stroke: props.colors.BWK1515Fill,
            opacity:props.colors.BWK1515Fill,
          }} d="M199.17,144.78c3.24-.48,3.79.77,9.11.84.64,0,3.54.03,6.38-.51,1.65-.31,9.51-1.8,14.58-8.66,1.46-1.98,5.59-7.55,3.65-13.22-1.72-5.02-6.9-6.99-7.75-7.29-3.94-1.41-7.59-.5-11.39.46-3.87.97-4.52,1.94-9.3,2.98-4.16.91-4.19.29-6.19,1.12-4.01,1.65-6.18,5.07-8.05,8.01-1.79,2.81-2.73,4.3-2.88,6.58-.08,1.19.13,1.48,1.77,7.79.96,3.68,1.05,4.19,1.67,4.55,1.87,1.08,3.74-1.96,8.4-2.65h0Z"/>
  <path id="angrenzende_BWKHighlight1" data-name="angrenzende BWKHighlight1" class="BWK1515Fill" style= {{
            fill: props.colors.BWK1515Fill,
            stroke: props.colors.BWK1515Fill,
            opacity:props.colors.BWK1515Fill,
          }} d="M86.61,113.22c-3.36,1.86-4.68,5.08-5.01,5.92-.51,1.29-1.57,3.99-.46,6.84.83,2.12,2.46,3.28,3.65,4.1,2.98,2.06,4.31,1.19,7.29,2.73,3.17,1.64,2.23,2.91,6.84,6.84,3.6,3.07,5.03,3.01,6.38,5.47,1.6,2.9.83,5.17,1.82,5.47,1.43.42,5.07-3.64,5.73-8.54.62-4.54-1.89-5.6-3.71-13.61-1.55-6.78-.29-8.38-2.47-11.12-2.42-3.04-6.18-3.84-9.57-4.56-3.46-.74-6.98-1.49-10.48.46,0,0-.01,0,0,0Z"/>
</g>
<g id="Grund_und_Deckplatten_Highlights" data-name="Grund und Deckplatten Highlights">
  <path id="Grund_und_Deckplatten_Highlight4" data-name="Grund und Deckplatten Highlight4" class="Grund_Deckplatten1515Fill" style= {{
            fill: props.colors.Grund_Deckplatten1515Fill,
            stroke: props.colors.Grund_Deckplatten1515Fill,
            opacity:props.colors.Grund_Deckplatten1515Fill,
          }} d="M184.07,47.84c-2.92,2.9-6.71,2.06-10.36,1.69-3.16-.32-6.24-.48-9.42-.37-6.62.24-13.18,1.13-19.79.11-5.4-.84-10.67-2.39-16.14-2.79-5.73-.42-12.87,2.54-18.21-.04-3.46-1.67-6.51,3.5-3.03,5.18,5.91,2.85,11.39,1.03,17.61.78,7.67-.3,15.02,2.58,22.61,3.23,7.54.64,15.05-.89,22.59-.47,6.68.37,13.11,2.15,18.38-3.09,2.74-2.72-1.5-6.97-4.24-4.24Z"/>
  <path id="Grund_und_Deckplatten_Highlight3" data-name="Grund und Deckplatten Highlight3" class="Grund_Deckplatten1515Fill" style= {{
            fill: props.colors.Grund_Deckplatten1515Fill,
            stroke: props.colors.Grund_Deckplatten1515Fill,
            opacity:props.colors.Grund_Deckplatten1515Fill,
          }} d="M186.65,54.89c-10.04,7.65-24.85,5.6-36.72,5.49-7.12-.06-14.3-.08-21.36-1.11-6.4-.93-12.51-3.55-19.03-3.55-3.86,0-3.87,6,0,6,7.28,0,14.59,3.13,21.89,3.9,7.36.78,14.79.73,22.19.78,11.97.09,25.96,1.36,36.06-6.34,3.03-2.31.05-7.52-3.03-5.18Z"/>
  <path id="Grund_und_Deckplatten_Highlight2" data-name="Grund und Deckplatten Highlight2" class="Grund_Deckplatten1515Fill" style= {{
            fill: props.colors.Grund_Deckplatten1515Fill,
            stroke: props.colors.Grund_Deckplatten1515Fill,
            opacity:props.colors.Grund_Deckplatten1515Fill,
          }} d="M188.16,102.21c-13.17,2.03-26.43,2.8-39.75,2.62-6.34-.09-12.68-.35-19.02-.66-6.82-.33-13.73-.36-20.38-2.1-3.74-.97-5.33,4.81-1.6,5.79,12.98,3.38,27.65,2.78,40.99,2.97,13.87.19,27.64-.72,41.34-2.83,3.8-.59,2.19-6.37-1.6-5.79Z"/>
  <path id="Grund_und_Deckplatten_Highlight1" data-name="Grund und Deckplatten Highlight1" class="Grund_Deckplatten1515Fill" style= {{
            fill: props.colors.Grund_Deckplatten1515Fill,
            stroke: props.colors.Grund_Deckplatten1515Fill,
            opacity:props.colors.Grund_Deckplatten1515Fill,
          }} d="M195.26,115.96c-6.95.6-13.44,3.27-20.34,4.17-7.41.96-14.97.66-22.42.5-7.05-.15-14.15-.39-21.1-1.67-7.04-1.3-13.75-3.77-20.87-4.72-3.82-.51-3.78,5.5,0,6,7.46.99,14.53,3.82,21.98,4.95,6.62,1,13.3,1.29,19.99,1.44,6.96.15,13.98.4,20.92-.3,7.44-.75,14.43-3.71,21.84-4.36,3.82-.33,3.85-6.33,0-6Z"/>
</g>
</svg>
  );
}

export default Brustwirbelkorper5VdLiegend1515;
