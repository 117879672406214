import style from "./style.scss";

function RechtesFersenbeinAxSitzend1450(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.33 478.65">
  
  <g id="calcaneus">
    <path id="calcaneus1" class="kalkaneus_ax_links1450_cls2" d="M70.57,43.9c-4.22,11.69-11.84,21.4-20.71,30.38-7.89,7.7-8.6,18.74-.74,33.75,7.37,10.35,11.21,21.39,10.36,33.37"/>
    <path id="calcaneus2" class="kalkaneus_ax_links1450_cls2" d="M294.45,10.12c9.66,21.5,9.59,42.32-9,61.88.04,17.36-8.81,30.4-21.37,41.63-13.06.85-24.45,5.51-34.87,12.38-30.45,71.16-44.63,137.21-16.87,190.13,21.6,30.86,16.84,71.18-15.75,121.5-19.84,29.42-49.4,38.29-82.12,40.5-38.04-6.96-51.97-45.84-55.13-99-15.01-25.13-16.06-51.13-9-77.62,3.37-37.98.25-73.69-10.13-106.87-4.2-14.51,1.21-33.93,7.87-54,24.2-47.57,14.93-103.99-4.51-134.49"/>
    <path id="calcaneus3" class="kalkaneus_ax_links1450_cls2" d="M224.7,105.78l13.5,20.25c17.71,3.45,27.02-29.29,33.75-73.13,6.82-2.63,9.21-18.3,9-41.63"/>
    <path id="calcaneus4" class="kalkaneus_ax_links1450_cls2" d="M63.82,1.14c-7.88,35.01-20.45,55.08-38.25,58.5C2.87,60.26-.77,33.84.82.02"/>
    <path id="calcaneus5" class="kalkaneus_ax_links1450_cls1" d="M210.45,414.02c.52-20.37-1.21-39.09-7.13-54.75-8.54-21.96-12.86-44.8-14.25-68.25"/>
    <path id="calcaneus6" class="kalkaneus_ax_links1450_cls1" d="M52.94,299.65c14.33,29.79,14.35,55.72,6.38,79.5"/>
  </g>
  <g id="overlays">
    <path id="calcaneus_overlay" data-name="calcaneus overlay" class="calcaneus1450fill" style= {{
              fill: props.colors.calcaneus1450fill,
              stroke: props.colors.calcaneus1450fill,
              opacity:props.colors.calcaneus1450fill,
            }} d="M294.45,10.12c9.66,21.5,9.59,42.32-9,61.88.04,17.36-8.81,30.4-21.37,41.63-13.06.85-24.45,5.51-34.87,12.38-30.45,71.16-44.63,137.21-16.87,190.13,21.6,30.86,16.84,71.18-15.75,121.5-19.84,29.42-49.4,38.29-82.12,40.5-38.04-6.96-51.97-45.84-55.13-99-15.01-25.13-16.06-51.13-9-77.62,3.37-37.98.25-73.69-10.13-106.87-4.2-14.51,1.21-33.93,7.87-54,18.85-37.04,17.39-79.46,6.65-110.81,4.26-8.88,7.19-17.44,9.09-25.75"/>
  </g>
</svg>
  );
}

export default RechtesFersenbeinAxSitzend1450;
