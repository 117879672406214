import {
    SET_OPENED_PATIENT,
  } from "../actionTypes";
  
  const initialState = {
    selectedPatient: null,
  };
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, action) {
    switch (action.type) {
      case SET_OPENED_PATIENT: {
        const { content } = action.payload;
        return {
          ...state,
          selectedPatient: content,
        };
        
      }
      default:
        return state;
    }
  }
  