import style from "./style.scss";

export default function RechtePatellaDeFile30axSitzend2340(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.54 67.84">
      <g id="underlay">
        <ellipse
          id="joint_gap"
          data-name="joint gap"
          class="Femoropatellargelenk2340Fill"
          style={{
            fill: props.colors.Femoropatellargelenk2340Fill,
            stroke: props.colors.Femoropatellargelenk2340Fill,
          }}
          cx="48.83"
          cy="22.91"
          rx="11.07"
          ry="32.92"
          transform="translate(21.26 69.27) rotate(-84.39)"
        />
      </g>
      <g id="knee">
        <path
          id="patella"
          class="Patella30_right2340_cls3"
          d="M44.25,23.57c-6.63-4.59-13.17-5.99-19.68-6.46-7.83.91-7.92-6.2-2.98-8.68C33.48-.55,46.76-.88,60.12,2.42c9.95-1.14,16.12,4.54,8.52,7.93-2.73,2.23-4.64,4.63-5.73,7.21-1.82,6.96-6.84,12.7-18.65,6.01h0Z"
        />
        <path
          id="femur"
          class="Patella30_right2340_cls3"
          d="M.5,67.34c.44-7.69.98-15.14,3.23-17.92,2.8-6.82,9.06-10.35,10.14-17.3,3.48-14.32,13.51-10.7,23.6-5.39,6.45,3.35,13.12,10.38,23.11,4.11,15.37-10.28,26.66,2.44,34.2,18.76,2.91,6.32,3.79,7.22,5.26,16.99"
        />
      </g>
      <g id="overlay">
        <path
          id="patella_overlay"
          data-name="patella overlay"
          class="Patella2340Fill"
          style={{
            fill: props.colors.Patella2340Fill,
            stroke: props.colors.Patella2340Fill,
          }}
          d="M44.25,23.57c-6.63-4.59-13.17-5.99-19.68-6.46-7.83.91-7.92-6.2-2.98-8.68C33.48-.55,46.76-.88,60.12,2.42c9.95-1.14,16.12,4.54,8.52,7.93-2.73,2.23-4.64,4.63-5.73,7.21-1.82,6.96-6.84,12.7-18.65,6.01h0Z"
        />
      </g>
    </svg>
  );
}
