import style from "./style.scss";

function Lendenwirbelkorper1LatLiegend1710(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332.17 294.96">

<g id="Anatomy">
  <path id="Anatomy11" class="Lendenwirbelkorper1_lat1710_cls4" d="M79.32,64.89c-4,2-6.8,6.1-7.5,10.2-.8,4.8,1.7,7.4,2.7,12.3.5,2.2,1,6.4-4.8,20.5-3.9,9.6-8.6,21.1-19.1,34.9-6.3,8.2-9.7,8.9-10.2,13.7-1.2,10.3,11.7,19.6,15.7,22.6,5.7,4.1,11.3,6.8,45.1,15,27.4,6.7,28.6,6.1,34.9,9.6,9.8,5.4,16.2,12,21.2,9.6,3.6-1.8,4.86-6.63,5.96-13.13,4.2-11.58,5.94-22.57,15.94-41.57,10.2-19.3,17.8-30.8,17.8-30.8,3.1-4.6,5.9-8.7,6.8-15,1.2-8.1-1.1-18.8-7.5-21.9-3.9-1.9-7.6-.1-13,1.4,0,0-17.1,3.8-34.9,0-6.7-1.4-4.7-2.1-23.9-8.2-7.6-2.4-8.8-2.6-11.6-4.1-9.1-4.9-9-9.8-17.1-13.7-2.8-1.3-10-4.7-16.5-1.4h0Z"/>
  <path id="Anatomy10" class="Lendenwirbelkorper1_lat1710_cls1" d="M254.92,238.39c6.3,3.1,11.9-4.7,28.3-3.7,6.4.4,6.6,1.6,13.7,1.8,7.7.2,15.6.5,22.3-4.1,8.4-5.7,10.2-15.9,11.4-22.8.8-4.6,2.7-15.5-3.2-25.5-2.7-4.6-6.1-7.4-8.7-9.6-3-2.5-5.6-4.2-15.5-9-18.9-9.3-21.3-9.2-23-8.9-6.3,1.3-7.2,6-13.4,6.1-6.8.1-9-5.6-12.6-4.8-7,1.7-6.6,25.2-6.1,44.9.7,30.6,4.1,34.2,6.8,35.6h0Z"/>
  <path id="Anatomy9" class="Lendenwirbelkorper1_lat1710_cls3" d="M202.62,94.29c-.8,4.7.8,8.4,3.8,15.6,2.7,6.4,4,9.6,6.7,10.8,4.8,2.2,10.6-1.8,19.9-8.2,9.2-6.3,16-11,15.3-15.9-.6-3.9-5.6-3.3-11-9.6-6.8-7.9-4.6-15.5-9.1-16.9-3.6-1.1-7.7,2.9-15,10-6.6,6.3-9.8,9.4-10.6,14.2h0Z"/>
  <path id="Anatomy8" class="Lendenwirbelkorper1_lat1710_cls3" d="M254.02,155.79c.6-.1,2.1-.7,4.6-13.2,2.1-10.8,3.2-16.1,2.3-22.3-.3-1.8-2.4-15.9-8.7-16.9-3.9-.6-7.5,4.3-7.8,4.6-2,2.8-2.3,5.8-2.3,11.4.1,10.8.1,16.1,2.1,21.9,2,5.5,6.5,15.1,9.8,14.5h0Z"/>
  <path id="Anatomy7" class="Lendenwirbelkorper1_lat1710_cls2" d="M282.82,141.19c-12.1,26.3-16,13.9-28.7,14.6"/>
  <path id="Anatomy6" class="Lendenwirbelkorper1_lat1710_cls3" d="M127.59,290.91c4.02-10.84,3.85-10.78,7.44-18.45,4.1-8.6,5.19-9.67,7.89-18.17,1.5-4.7,2.06-5.6,3.96-11.3,2.9-8.9,3.94-9.4,3.54-13.3-.4-4-3.3-7.9-6.5-10,0-.1-.4-.3-1-.5-1.6-.9-3.3-1.3-4.8-1.1-4.4-.2-5.5,2.1-14.1,4.9-.7.2-3.9,1.1-7.1,1.3-.8.1-1.6,0-2.7-.1-5.8-.5-5.6-1.3-16.4-3.4-12-2.4-13.2-1.7-18.5-3.4-6.9-2.3-9.7-5.1-17.1-10.2-17.6-12.2-26.5-18.1-32.8-15.2-5,2.4-6.7,9.6-7.5,13-1.5,6.2-1.4,17.1-1.4,17.1,0,0-1.7,6.6-2.7,9.6-1.9,5.8-2.8,6.9-4.1,10.9-1.4,4.1-1.2,5.2-2.7,13.7-1.8,9.6-2.7,14.4-4.1,17.8-2.01,4.69-3.26,5.26-6.25,10.25"/>
  <path id="Anatomy5" class="Lendenwirbelkorper1_lat1710_cls3" d="M201.12,294.89c.8-2.5,1.4-4.3,1-7-.6-4.1-2.6-5.5-4.8-9.6-2.7-5.1-4.2-11.9-3.4-17.3.9-5.9,8.7-12.5,10.5-16.3l.6-1.4s.8-2.3.7-4.6c-.1-2.6-2.1-7.4-4.9-8.3-8-2.8-16.6,19.8-37,23.4-6.9,1.2-9.4-.8-10-1.4-5.9-5.3-.1-20.1-1.4-20.5-1.2-.4-5.8,12.3-9,20.4-8.7,22.2-10.5,21.9-14.7,35-.41,1.22-.78,2.43-1.14,3.63"/>
  <path id="Anatomy4" class="Lendenwirbelkorper1_lat1710_cls3" d="M208.92,250.09c2.4,1.3,1.7,6.6.5,16-1.4,10.2-2.1,15.3-3.6,15.5-3.4.4-12.5-18.2-5.5-27.3,2.2-3,6.5-5.4,8.6-4.2h0Z"/>
  <path id="Anatomy3" class="Lendenwirbelkorper1_lat1710_cls1" d="M211.22,241.89c2-7.1,4.8-6.6,7.8-13.7,4.6-11,4.6-28.4-5.9-38.3-6.3-5.9-14-6.9-16.8-7.3-2.4-.3-14.6-1.7-21.4,5.5-2.4,2.6-1.7,3.6-6.8,13.2-2.6,4.8-5.6,10.3-6.4,10-1.3-.5,2.5-14.9,7.3-28.2,7.3-20.2,18-40.5,25.1-50.7,2.5-3.6,4.8-6.3,7.2-11.7,2.9-6.4,3.3-10.6,5.1-10.8,2.9-.2,3.7,9.9,9.4,11.4,1.9.5,3.4-.2,11.4-4.9,11.4-6.8,13.1-8.3,14.6-7.5,2.7,1.5-.7,8.2.7,22.4.6,5.7.9,9.1,3.1,13.2,2.3,4.4,4.3,5.3,5.4,9.5.5,1.9.5,3.7-.5,10.4-.8,5.4-1.2,8.1-2.2,12.2-1.9,7.9-1.6,15.1-1,28.3.2,4.7.9,16.2,4.1,31,1.6,7.5,1.8,6.4,2.3,9.6,1.7,11.9-.9,31.8-15,40.6-10.9,6.8-27.3,6-31-.5-2.5-4.3,3-7.5,4.1-20,.9-11.3-3.1-14.5-.6-23.7h0Z"/>
  <path id="Anatomy2" class="Lendenwirbelkorper1_lat1710_cls2" d="M229.52,163.09c15,21-5.1,22.8-11.4,22.8"/>
  <path id="Anatomy1" class="Lendenwirbelkorper1_lat1710_cls1" d="M110.6.41c-2.23,1.57-1.18,4.8-2.28,10.94-.88,4.93-2.58,8.59-4.1,11.85-1.19,2.56-2.86,5.65-5.47,10.48-2.38,4.41-3.16,5.71-2.73,7.29.88,3.23,5.93,4.48,8.2,5.01,23.25,5.47,30.99,6.38,30.99,6.38,16.29,1.98,18.67,3.8,30.53,6.38,7.09,1.55,11.95,9.62,13.22,10.94,3.4,3.53,7.51,8.48,14.58,14.13,2.46,1.96,4.71,3.55,7.75,3.65,1.13.03,3.6-.1,8.66-3.65,4.31-3.03,6.39-5.65,10.48-10.03,5.98-6.39,6.09-5.64,9.57-9.57,5.08-5.73,10.44-11.78,10.94-16.41"/>
</g>
<g id="Highlights">
  <path id="Highlights5" class="WK1710Fill" style= {{
            fill: props.colors.WK1710Fill,
            stroke: props.colors.WK1710Fill,
          }} d="M79.47,65.19c-4,2-6.8,6.1-7.5,10.2-.8,4.8,1.7,7.4,2.7,12.3.5,2.2,1,6.4-4.8,20.5-3.9,9.6-8.6,21.1-19.1,34.9-6.3,8.2-9.7,8.9-10.2,13.7-1.2,10.3,11.7,19.6,15.7,22.6,5.7,4.1,11.3,6.8,45.1,15,27.4,6.7,28.6,6.1,34.9,9.6,9.8,5.4,16.2,12,21.2,9.6,3.6-1.8,4.86-6.63,5.96-13.13,4.2-11.58,5.94-22.57,15.94-41.57,10.2-19.3,17.8-30.8,17.8-30.8,3.1-4.6,5.9-8.7,6.8-15,1.2-8.1-1.1-18.8-7.5-21.9-3.9-1.9-7.6-.1-13,1.4,0,0-17.1,3.8-34.9,0-6.7-1.4-4.7-2.1-23.9-8.2-7.6-2.4-8.8-2.6-11.6-4.1-9.1-4.9-9-9.8-17.1-13.7-2.8-1.3-10-4.7-16.5-1.4h0Z"/>
  <path id="Highlights4" class="Dornfortsatz1710Fill" style= {{
            fill: props.colors.Dornfortsatz1710Fill,
            stroke: props.colors.Dornfortsatz1710Fill,
          }} d="M255.53,238.28c6.3,3.1,11.9-4.7,28.3-3.7,6.4.4,6.6,1.6,13.7,1.8,7.7.2,15.6.5,22.3-4.1,8.4-5.7,10.2-15.9,11.4-22.8.8-4.6,2.7-15.5-3.2-25.5-2.7-4.6-6.1-7.4-8.7-9.6-3-2.5-5.6-4.2-15.5-9-18.9-9.3-21.3-9.2-23-8.9-6.3,1.3-7.2,6-13.4,6.1-6.8.1-9-5.6-12.6-4.8-7,1.7-6.6,25.2-6.1,44.9.7,30.6,4.1,34.2,6.8,35.6h0Z"/>
  <path id="Highlights3" class="Querfortsatz1710Fill" style= {{
            fill: props.colors.Querfortsatz1710Fill,
            stroke: props.colors.Querfortsatz1710Fill,
          }} d="M229.38,162.98c15,21-5.1,22.8-11.4,22.8"/>
  <path id="Highlights2" class="Angrenzende_WK1710Fill" style= {{
            fill: props.colors.Angrenzende_WK1710Fill,
            stroke: props.colors.Angrenzende_WK1710Fill,
          }} d="M149.86,229.44c-.4-4-3.3-7.9-6.5-10,0-.1-.4-.3-1-.5-1.6-.9-3.3-1.3-4.8-1.1-4.4-.2-5.5,2.1-14.1,4.9-.7.2-3.9,1.1-7.1,1.3-.8.1-1.6,0-2.7-.1-5.8-.5-5.6-1.3-16.4-3.4-12-2.4-13.2-1.7-18.5-3.4-6.9-2.3-9.7-5.1-17.1-10.2-17.6-12.2-26.5-18.1-32.8-15.2-5,2.4-6.7,9.6-7.5,13-1.5,6.2-1.4,17.1-1.4,17.1,0,0-1.7,6.6-2.7,9.6-1.9,5.8-2.8,6.9-4.1,10.9-1.4,4.1-1.2,5.2-2.7,13.7-1.8,9.6-2.7,14.4-4.1,17.8-2.03,4.75-3.29,5.27-6.36,10.43,19.77,1.81,39.78,1.57,59.56,2.86,11.58.76,23.09,1.7,34.7,1.77,5.37.03,10.64.03,15.98.7,4.47.56,8.89,1.22,13.39,1.2l3.4-.13c4.02-10.84,3.85-10.78,7.44-18.45,4.1-8.6,5.19-9.67,7.89-18.17,1.5-4.7,2.06-5.6,3.96-11.3,2.9-8.9,3.94-9.4,3.54-13.3Z"/>
  <path id="Highlights1" class="Angrenzende_WK1710Fill" style= {{
            fill: props.colors.Angrenzende_WK1710Fill,
            stroke: props.colors.Angrenzende_WK1710Fill,
          }} d="M110.69.17c-2.23,1.57-1.18,4.8-2.28,10.94-.88,4.93-2.58,8.59-4.1,11.85-1.19,2.56-2.86,5.65-5.47,10.48-2.38,4.41-3.16,5.71-2.73,7.29.88,3.23,5.93,4.48,8.2,5.01,23.25,5.47,30.99,6.38,30.99,6.38,16.29,1.98,18.67,3.8,30.53,6.38,7.09,1.55,11.95,9.62,13.22,10.94,3.4,3.53,7.51,8.48,14.58,14.13,2.46,1.96,4.71,3.55,7.75,3.65,1.13.03,3.6-.1,8.66-3.65,4.31-3.03,6.39-5.65,10.48-10.03,5.98-6.39,6.09-5.64,9.57-9.57,5.08-5.73,10.44-11.78,10.94-16.41"/>
</g>
</svg>
  );
}

export default Lendenwirbelkorper1LatLiegend1710;
