import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getDDMMYYY_HHMM,
  getDidomNow,
} from "../../Utilities/DateTimeUtils/DateTimeUtils";
import ClipLoader from "react-spinners/ClipLoader";
import PreviewImage from "./PreviewImage";

import "./stylesheets/UnhookImagesModal.scss";
import { useStudy } from "../../Utilities/FetchHooks/Studies/StudiesHooks";
import { NotificationManager } from "react-notifications";
import { useKeycloak } from "@react-keycloak/web";
import useLoggedUser from "../../Utilities/Hooks/useLoggedUser";
import CopyImage from "../../Utilities/DcmTools/CopyImage";
import CreateSOP from "../../Utilities/DcmTools/CreateSOP";
import DeleteImage from "../../Utilities/DcmTools/DeleteImage";
import DeleteSerie from "../../Utilities/DcmTools/DeleteSerie";

const XMASK =
  "{id,dapatid,patient,patientsname,accessionnumber,studydate,modality,studydescription,studyinstanceuid,serie_collection{id,seriesinstanceuid,laterality,seriesdescription,seriesnumber,image_collection{image_id,preview_url,imgdetails_collection}}}";

function Image({ image, serie, toTransfer, setToTransfer, inverted = false }) {
  const [imgWidth, setImgWidth] = useState("50px");

  const imageClickHandler = () => {
    let toTransferCp = [...toTransfer];
    let serIdx = toTransferCp.findIndex((ser) => ser.id === serie.id);

    if (serIdx < 0) {
      let serieCp = structuredClone(serie);
      serieCp.image_collection = [image];
      toTransferCp.push(serieCp);
      setToTransfer(toTransferCp);
      return;
    }

    let serieCp = structuredClone(toTransferCp[serIdx]);
    let imgIdx = serieCp.image_collection.findIndex(
      (img) => img.image_id === image.image_id
    );

    if (imgIdx > -1) {
      serieCp.image_collection.splice(imgIdx, 1);

      if (serieCp.image_collection.length === 0) {
        //no more images to transfer we need to delete whole serie from selected to transfer
        toTransferCp.splice(serIdx, 1);
        setToTransfer(toTransferCp);
      } else {
        //overwrite with new image_collction
        toTransferCp[serIdx] = serieCp;
        setToTransfer(toTransferCp);
      }
      return;
    }

    serieCp.image_collection.push(image);
    toTransferCp[serIdx] = serieCp;
    setToTransfer(toTransferCp);
  };

  return (
    <div
      className={`border rounded p-1 mt-2 ${inverted ? "ml-4" : "mr-4"}`}
      style={{
        display: "grid",
        gridTemplateColumns: inverted ? "1fr 4fr 2fr" : "2fr 4fr 1fr",
      }}
    >
      {inverted ? (
        <>
          <Button onClick={imageClickHandler} size="sm" className="mr-auto">
            <i className="fas fa-chevron-left fa-sm" />
          </Button>
          <div>
            {image?.imgdetails_collection[0]?.imagedescription
              ? image?.imgdetails_collection[0]?.imagedescription
              : image.image_id}
          </div>
          <div
            className="ml-auto"
            onClick={() => {
              setImgWidth(imgWidth === "50px" ? "120px" : "50px");
            }}
          >
            <PreviewImage image={image} width={imgWidth} />
          </div>
        </>
      ) : (
        <>
          <div
            onClick={() => {
              setImgWidth(imgWidth === "50px" ? "120px" : "50px");
            }}
          >
            <PreviewImage image={image} width={imgWidth} />
          </div>
          <div>
            {image?.imgdetails_collection[0]?.imagedescription
              ? image?.imgdetails_collection[0]?.imagedescription
              : image.image_id}
          </div>
          <Button onClick={imageClickHandler} className="ml-auto" size="sm">
            <i className="fas fa-chevron-right fa-sm" />
          </Button>
        </>
      )}
    </div>
  );
}

function Serie({ serie, toTransfer, setToTransfer, inverted = false }) {
  const [imgsHidden, setImgsHidden] = useState(
    serie?.image_collection?.length > 5 ? true : false
  );

  const serieClickHandler = () => {
    let idx = toTransfer.findIndex((ser) => ser.id === serie.id);
    if (!inverted) {
      if (idx > -1) {
        let cp = [...toTransfer];
        cp.splice(idx, 1);
        setToTransfer([...cp, serie]);
      } else {
        setToTransfer([...toTransfer, serie]);
      }
    } else {
      let cp = [...toTransfer];
      cp.splice(idx, 1);
      setToTransfer([...cp]);
    }
  };

  return (
    <div
      className="mb-2 d-flex flex-column border rounded p-1"
      style={{
        gap: "2px",
      }}
    >
      <div className="d-flex">
        {inverted ? (
          <>
            <Button size="sm" onClick={serieClickHandler}>
              <i className="fas fa-chevron-left fa-sm" />
            </Button>
            <Button
              className="ml-1"
              variant="light"
              size="sm"
              onClick={() => {
                setImgsHidden(!imgsHidden);
              }}
            >
              <i
                className={`fas fa-chevron-${
                  !imgsHidden ? "up" : "down"
                } fa-sm`}
              />
            </Button>
            {/* <input
              className="p-0 m-0 font-weight-bold ml-auto text-center"
              style={{
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
              }}
              value={`${serie.seriesdescription} ${serie.laterality}`}
            /> */}
            <h6 className="font-weight-bold ml-auto">{`${serie.seriesdescription} ${serie.laterality}`}</h6>
          </>
        ) : (
          <>
            <p className="p-0 m-0 font-weight-bold">
              {`${serie.seriesdescription} ${serie.laterality}`}
            </p>
            <Button
              className="ml-auto mr-1"
              variant="light"
              size="sm"
              onClick={() => {
                setImgsHidden(!imgsHidden);
              }}
            >
              <i
                className={`fas fa-chevron-${
                  !imgsHidden ? "up" : "down"
                } fa-sm`}
              />
            </Button>
            <Button size="sm" onClick={serieClickHandler}>
              <i className="fas fa-chevron-right fa-sm" />
            </Button>
          </>
        )}
      </div>
      {!imgsHidden && !inverted
        ? serie.image_collection
            .filter((image) => {
              let idx = toTransfer.findIndex((ser) => ser.id === serie.id);
              if (idx < 0) {
                return true;
              }
              return (
                toTransfer[idx].image_collection.findIndex(
                  (img) => img.image_id === image.image_id
                ) < 0
              );
            })
            .map((image) => (
              <Image
                key={image.image_id}
                image={image}
                inverted={inverted}
                toTransfer={toTransfer}
                setToTransfer={setToTransfer}
                serie={serie}
              />
            ))
        : !imgsHidden &&
          serie.image_collection.map((image) => (
            <Image
              key={image.image_id}
              image={image}
              inverted={inverted}
              toTransfer={toTransfer}
              setToTransfer={setToTransfer}
              serie={serie}
            />
          ))}
    </div>
  );
}

export default function UnhookImagesModal({
  shown,
  headerless = false,
  close,
}) {
  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  const examQr = useStudy({
    studyId: selectedExaminations[0]?.id,
    enabled: selectedExaminations.length === 1,
    xmask: XMASK,
  });

  const { keycloak } = useKeycloak();
  const userInfo = useLoggedUser(true);
  const [toTransfer, setToTransfer] = useState([]);
  const [destStudyDesc, setDestStudyDesc] = useState("");

  const copyImages = () => {
    if (toTransfer.length === 0) {
      NotificationManager.error("Nothing to copy");
      return;
    }
    let copies = [];
    let deletes = [];
    let suid = CreateSOP({ ownUaid: "77" });

    //Post to protokol to make a copy of images first
    toTransfer.forEach((serie) => {
      let seruid = CreateSOP({ ownUaid: "777" });
      serie.image_collection.forEach((image) => {
        copies.push(
          CopyImage({
            keycloak,
            mId: userInfo.tokenData.pmedMid,
            serId: serie.id,
            destSerUid: seruid,
            destStdUid: suid,
            imgId: image.image_id,
            patNr: examQr.data.patient.patientid,
            destSerNo: serie.seriesnumber,
            tagsToReplace: `0008,1030=${destStudyDesc}$0008,0020=${getDidomNow()}`,
            stdId: examQr.data.id,
          })
        );
      });
    });
    // delete unhooked images / series (only series that all images are unhooked)
    toTransfer.forEach((serie) => {
      serie.image_collection.forEach((image) => {
        deletes.push(DeleteImage(keycloak, image.image_id));
      });
      let oriSerie = examQr.data.serie_collection.find(
        (oriSerie) => oriSerie.id === serie.id
      );
      if (serie.image_collection.length === oriSerie.image_collection.length) {
        deletes.push(DeleteSerie(keycloak, serie.id));
      }
    });

    Promise.all(copies)
      .then(() => {
        NotificationManager.success(
          "Images will be copied it will take some time"
        );
        Promise.all(deletes)
          .then(() => {
            NotificationManager.success(
              "Source images are successfully unhooked"
            );
          })
          .catch(() => {
            throw new Error("Unable to delete source images");
          });
      })
      .catch((err) => {
        NotificationManager.error("Unable to unhook images");
        console.warn(err);
      });
  };

  useEffect(() => {
    if (
      examQr.status === "success" &&
      selectedExaminations?.length === 1 &&
      examQr.data != undefined
    ) {
      setDestStudyDesc(examQr.data.studydescription);
    }
  }, [examQr.data]);

  return (
    <Modal
      className="top-modal"
      show={shown}
      onHide={close}
      size={"xl"}
      onClick={(e) => e.stopPropagation()}
    >
      {!headerless && (
        <Modal.Header className="py-2 px-2 bg-light" closeButton>
          <h5 className="pr-2 m-0">Unhook Images</h5>
          {(examQr.isFetching || examQr.isLoading || examQr.isRefetching) && (
            <ClipLoader color="#a1a1a1" loading={true} css={true} size={20} />
          )}
        </Modal.Header>
      )}
      <Modal.Body className="m-0 p-1">
        {examQr.status === "success" && selectedExaminations?.length === 1 && (
          <div className="mt-2 series-container">
            {/* REGION source  */}
            <div name="sourceData" className="d-flex flex-column">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h5 className="mb-3">
                  Source
                  <i className="pl-2 fas fa-plane-departure" />
                </h5>
                <h5 className="font-weight-bold text-center">
                  {examQr.data.studydescription}
                </h5>
                <div>{getDDMMYYY_HHMM(examQr.data.studydate)}</div>
                <div>{examQr.data.studyinstanceuid}</div>
              </div>
              <hr className="border-top" style={{ width: "80%" }} />
              <div
                className="mt-2 d-flex flex-column px-5"
                style={{ gap: "5px" }}
              >
                {examQr.data.serie_collection
                  .filter((serie) => {
                    let idx = toTransfer.findIndex(
                      (ser) => ser.id === serie.id
                    );
                    if (idx < 0) {
                      return true;
                    }
                    return (
                      serie.image_collection.length !==
                      toTransfer[idx].image_collection.length
                    );
                  })
                  .map((serie) => (
                    <Serie
                      key={serie.id}
                      serie={serie}
                      toTransfer={toTransfer}
                      setToTransfer={setToTransfer}
                    />
                  ))}
              </div>
            </div>
            {/* ENDREGION source */}

            {/* REGION destination */}
            <div name="destinationData" className="d-flex flex-column">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h5 className="mb-3">
                  <i className="fas fa-plane-arrival pr-2" />
                  Destination
                </h5>
                <input
                  type="text"
                  value={destStudyDesc}
                  onChange={(e) => {
                    setDestStudyDesc(e.target.value);
                  }}
                  className="text-center font-weight-bold "
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    fontSize: "20px",
                  }}
                />
                <div>{getDDMMYYY_HHMM(examQr.data.studydate)}</div>
                <div>{examQr.data.studyinstanceuid}</div>
              </div>
              <hr className="border-top" style={{ width: "80%" }} />
              <div
                className="mt-2 d-flex flex-column px-5"
                style={{ gap: "5px" }}
              >
                {toTransfer.map((serie) => (
                  <Serie
                    key={`transfer${serie.id}`}
                    serie={serie}
                    inverted={true}
                    toTransfer={toTransfer}
                    setToTransfer={setToTransfer}
                  />
                ))}
              </div>
            </div>
            {/* ENDREGION destination */}
          </div>
        )}
        {selectedExaminations?.length === 0 && (
          <div className="text-center py-2">
            <h5 className="m-0 p-0">Select Examination First</h5>
          </div>
        )}
        {selectedExaminations?.length > 1 && (
          <div className="text-center py-2">
            <h5 className="m-0 p-0">Select only one examination</h5>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="p-0">
        <Button
          size="sm"
          disabled={toTransfer.length === 0}
          onClick={copyImages}
        >
          Unhook
          <i className="ml-2 fas fa-anchor" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
