import { useSelector, useDispatch } from "react-redux";
import {
  addExamplannerBodyPartToHistory,
  clearExamplannerBodyPartHistory,
} from "../../../../Redux/actions";
import { useEffect } from "react";

import HumanBody from "./Human/HumanBody";
import ChestController from "./Chest/Controller";
import SpineController from "./Spine/Controller";
import LegLeftController from "./LegLeft/Controller";
import LegRightController from "./LegRight/Controller";
import FootLeftController from "./FootLeft/Controller";
import FootRightController from "./FootRight/Controller";
import ArmLeftController from "./ArmLeft/Controller";
import ArmRightController from "./ArmRight/Controller";
import PelvisController from "./Pelvis/Controller";
import HandLeftController from "./HandLeft/Controller";
import HandRightController from "./HandRight/Controller";
import RibsLeftController from "./RibsLeft/Controller";
import RibsRightController from "./RibsRight/Controller";
import SkullController from "./Skull/Controller";
import FaceSkullController from "./FaceSkull/Controller";
import AnkleLeftController from "./AnkleLeft/Controller";
import AnkleRightController from "./AnkleRight/Controller";

import { LOG_INFO } from "../../../../Utilities/Debug/DebugLevels";
import DebugLogger from "../../../../Utilities/Debug/DebugLogger";

function HumanModelViewController() {
  const selectedBodyPart = useSelector(
    (state) => state.examPlanner.viewControll.selectedBodyPart
  );

  const dispatch = useDispatch();

  DebugLogger(LOG_INFO, `Selected bodypart id = ${selectedBodyPart?.id}`);

  const viewMap = {
    0: { view: HumanBody },

    //HEAD / SKULL
    49: { view: SkullController },
    69: { view: SkullController },
    805: { view: SkullController },

    //FACE SKULL
    59: { view: FaceSkullController },
    61: { view: FaceSkullController },
    576: { view: FaceSkullController },
    80: { view: FaceSkullController },
    579: { view: FaceSkullController },
    876: { view: FaceSkullController },

    //CHEST
    8: { view: ChestController },
    159: { view: ChestController },

    //SPINE
    82: { view: SpineController },
    90: { view: SpineController },
    118: { view: SpineController },
    132: { view: SpineController },
    804: { view: SpineController },

    //RIBS RIGHT
    48: { view: RibsRightController },
    652: { view: RibsRightController },
    668: { view: RibsRightController },

    //RIBS LEFT
    47: { view: RibsLeftController },
    651: { view: RibsLeftController },
    667: { view: RibsLeftController },

    //PELVIS
    16: { view: PelvisController },
    38: { view: PelvisController },
    41: { view: PelvisController },

    //LEFT LOWER EXTREMITIES
    22: { view: LegLeftController },
    343: { view: LegLeftController },
    385: { view: LegLeftController },
    383: { view: LegLeftController },
    24: { view: LegLeftController },
    381: { view: LegLeftController },
    353: { view: LegLeftController },
    373: { view: LegLeftController },

    //LEFT FOOT
    403: { view: FootLeftController },
    425: { view: FootLeftController },
    441: { view: FootLeftController },
    443: { view: FootLeftController },
    445: { view: FootLeftController },
    447: { view: FootLeftController },
    409: { view: FootLeftController },
    407: { view: FootLeftController },
    469: { view: FootLeftController },
    423: { view: FootLeftController },

    //LEFT ANKLE
    411: { view: AnkleLeftController },
    525: { view: AnkleLeftController },

    //RIGHT LOWER EXTREMITIES
    23: { view: LegRightController },
    344: { view: LegRightController },
    386: { view: LegRightController },
    384: { view: LegRightController },
    25: { view: LegRightController },
    382: { view: LegRightController },
    354: { view: LegRightController },
    374: { view: LegRightController },
    367: { view: LegRightController },

    //RIGHT FOOT
    404: { view: FootRightController },
    426: { view: FootRightController },
    442: { view: FootRightController },
    444: { view: FootRightController },
    446: { view: FootRightController },
    448: { view: FootRightController },
    410: { view: FootRightController },
    408: { view: FootRightController },
    470: { view: FootRightController },
    424: { view: FootRightController },

    //RIGHT ANKLE
    526: { view: AnkleRightController },
    412: { view: AnkleRightController },

    //LEFT UPPER EXTREMITIES
    12: { view: ArmLeftController },
    157: { view: ArmLeftController },
    151: { view: ArmLeftController },
    143: { view: ArmLeftController },
    198: { view: ArmLeftController },
    207: { view: ArmLeftController },
    229: { view: ArmLeftController },
    256: { view: ArmLeftController },
    257: { view: ArmLeftController },

    //RIGHT UPPER EXTREMITIES
    13: { view: ArmRightController },
    144: { view: ArmRightController },
    152: { view: ArmRightController },
    158: { view: ArmRightController },
    199: { view: ArmRightController },
    208: { view: ArmRightController },
    230: { view: ArmRightController },
    255: { view: ArmRightController },
    258: { view: ArmRightController },

    //LEFT HAND
    259: { view: HandLeftController },
    263: { view: HandLeftController },
    329: { view: HandLeftController },
    335: { view: HandLeftController },
    337: { view: HandLeftController },
    339: { view: HandLeftController },
    341: { view: HandLeftController },
    265: { view: HandLeftController },
    261: { view: HandLeftController },

    //RIGHT HAND
    260: { view: HandRightController },
    342: { view: HandRightController },
    340: { view: HandRightController },
    338: { view: HandRightController },
    336: { view: HandRightController },
    330: { view: HandRightController },
    264: { view: HandRightController },
    266: { view: HandRightController },
    262: { view: HandRightController },
  };

  const getView = () => {
    let id = selectedBodyPart?.id;
    if (id && Object.keys(viewMap).includes(id.toString())) {
      let view = viewMap[id]?.view;
      if (view) {
        return view;
      } else {
        return viewMap[0].view;
      }
    } else {
      return viewMap[0].view;
    }
  };

  const View = getView();

  useEffect(() => {
    if (View === HumanBody) {
      dispatch(clearExamplannerBodyPartHistory());
    } else {
      dispatch(addExamplannerBodyPartToHistory({ id: selectedBodyPart?.id }));
    }
  }, [View]);

  return <View />;
}

export default HumanModelViewController;
