import style from "./style.scss";

export default function RechteHufteAdduktionVdOblLiegend2411(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 270.25 289.1"
    >
      <g id="BG">
        <path
          id="BG4"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls4"
          d="M180.02,0l-12.55,17.23c4.24.31,7.95.09,11.53-2.28,2.5-1.65,4.26-2.64,7.38-1.86,4.58,1.09,12.17,6.19,15.02,9.25,4.95,5.31,1.69,10.78,6,21,2.08,4.94,4.7,8.06,8,12,5.64,6.73,11.59,11.08,17,15,7.09,5.13,7.99,4.72,11,8,4.07,4.43,8.31,16.09,10.34,21.09l16.51-14.7"
        />
        <path
          id="BG3"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls4"
          d="M81.71,63.26c-.39-1.39-.32-2.47.99-14.36.32-2.93.33-2.96.32-3.19-.04-2.19-1.09-5.51-6.31-13.45-.86-1.32-1.66-2.53-2.41-3.66,5.74-.43,11.48-.86,17.22-1.3,21.98-1.69,44.91-3.71,66.72.58.47,1.33,1.23,3.24,2.46,5.39,2.34,4.1,5.12,8.98,9.04,9.5,4.59.61,6.78-5.3,11.64-4.68,2.67.34,4.66,2.46,5.98,4.36,1.26,3.86.73,6.23,0,7.69-1.02,2.03-2.47,2.36-3.53,4.81-.89,2.06-.77,3.9-.64,5.77.33,4.94,2.2,5.41,2.18,9.37-.01,3.16-1.2,3.22-1.22,6.66-.02,3.78,1.39,6.65,1.92,7.69.85,1.68,2.01,3.27,7.2,7.84,3.72,3.27,5.33,4.39,7.55,6.27,2.55,2.16,6.14,5.51,10,10.36,7.94,7.23,7.51,6.86,13.63,9.7-.37,24.23-.77,48.49,2.24,72.58.04.3.1.58.18.86-3.22,2.82-8.21,6.45-14.17,8.23-14.42,4.31-28.06-4.18-39-11-9.33-5.81-20.46-12.75-26-26-5.19-12.43-6.14-14.01-8-25,5.68-33.5,14.34-68.49-50.43-66.91.03.01.14.04.26,0,.09-.03.14-.08.17-.11-5.82-2.85-7.45-6.04-8-8Z"
        />
        <path
          id="BG2"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls4"
          d="M19.42,163.67l-15.46,20.67c-.11.14-.33.03-.29-.15,2.01-8.3,1.32-13.66,1.16-18.58-.16-5.01-.76-7.28-3-23-2.17-15.18-2.27-17.75-.44-20.53,1.38-2.08,3.49-3.73,5.9-4.87l11.62.32.51,46.14h0Z"
        />
        <path
          id="BG1"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls4"
          d="M62.06,289.1c-7.41-25.37-43.92-68.83-48.21-79.24-3.59-8.71-5.46-13.27-9-19-6.47-10.48,11.93-21.88,10.16-28.03-1.82-6.31.87-7.73,2-22,1.03-13.03-1.04-14.06,1-21,.78-2.64-17.57-.2-9.16-5.97,0,0,4.48-3.07,28-6,7.2,1.29,10.39-2.55,13-2,9.4,1.99,29-15.77,30.16-29.03.44-4.95,8.21-3.29,9.03-5.02,3.11-2.26,8.91-5.81,16.95-7.23,12.74-2.25,22.62,2.37,26,4,4.02,1.95,15.88,7.68,21,21,4.76,12.39.7,23.56-2,31-2.23,6.13-6.34,14.41-13.46,19.16-5.93,3.38-14.52,5.09-14.52,5.09-31.09-4.09-39.42,9.25-34.16,33.03l.49,1.68c2.67,9.08,11,15.31,20.47,15.31,8.83-.01,16.39,6.3,17.97,14.98,3.51,19.36,9.25,52.43,10.5,68.37"
        />
      </g>
      <g id="Underlay">
        <path
          id="Hipjoint"
          class="Hipjoint2410Fill"
          style={{
            fill: props.colors.Hipjoint2411Fill,
            stroke: props.colors.Hipjoint2411Fill,
          }}
          d="M81.91,63.61c.07-3.54,13.21-5.95,15.71-6.41,4.07-.75,9-1.62,15.71-1.28,10.67.54,19.28,3.81,25,6.73,4.09,2.09,10.01,5.18,15.71,11.54,1.93,2.15,5.99,6.78,8.65,14.1,2.89,7.96,2.52,14.68,2.24,18.91-.58,8.81-3.21,15.16-4.81,18.91-3.43,8.07-7.34,12.66-10.26,16.03-4.99,5.75-6.68,5.64-7.37,5.45-2.45-.67-3.92-5.52-2.88-9.29,1.01-3.68,3.86-4.35,7.05-8.01,2.51-2.88,3.59-6.62,5.77-14.1,2.84-9.77,4.26-14.65,3.21-20.51-.95-5.26-3.22-8.97-4.81-11.54-1.05-1.7-4.66-7.29-11.54-12.18-1.76-1.25-8.77-6.04-19.23-7.69-7.78-1.23-13.63-.12-17.63.64-6.47,1.23-8.29,2.84-8.97,3.53-1.87,1.86-2.41,3.87-3.85,3.85-1.17-.02-1.56-1.38-4.17-4.49-2.7-3.23-3.54-3.27-3.53-4.17Z"
        />
      </g>
      <g id="Femur">
        <path
          id="Femur5"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          d="M4.36,183.84c-.11.14-.33.03-.29-.15,2.01-8.3,1.32-13.66,1.16-18.58-.16-5.01-.76-7.28-3-23C.06,126.93-.04,124.36,1.79,121.58c1.38-2.08,3.49-3.73,5.9-4.87"
        />
        <path
          id="Femur4"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls2"
          d="M89.71,71.26c1.13,1-.14.64.86,1.64,12.95,9.14,9.07,6.36,12.81,13.19,4.32,16.74,21.32,23.74,32.32,34.74,1.25,5.61,2.49,11.6,3.74,17.5"
        />
        <path
          id="Femur3"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          d="M62.46,288.6c-7.41-25.37-43.92-68.83-48.21-79.24-3.59-8.71-5.46-13.27-9-19-6.47-10.48,11.93-21.88,10.16-28.03-1.82-6.31.87-7.73,2-22,1.03-13.03-1.04-14.06,1-21,.78-2.64-17.57-.2-9.16-5.97,0,0,4.48-3.07,28-6,7.2,1.29,10.39-2.55,13-2,9.4,1.99,29-15.77,30.16-29.03.44-4.95,8.21-3.29,9.03-5.02,3.11-2.26,8.91-5.81,16.95-7.23,12.74-2.25,22.62,2.37,26,4,4.02,1.95,15.88,7.68,21,21,4.76,12.39.7,23.56-2,31-2.23,6.13-6.34,14.41-13.46,19.16-5.93,3.38-14.52,5.09-14.52,5.09-31.09-4.09-39.42,9.25-34.16,33.03l.49,1.68c2.67,9.08,11,15.31,20.47,15.31,8.83-.01,16.39,6.3,17.97,14.98,3.51,19.36,9.25,52.43,10.5,68.37"
        />
        <line
          id="Femur2"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          x1="64.22"
          y1="157.11"
          x2="45.22"
          y2="145.67"
        />
        <path
          id="Femur1"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          d="M7.68,116.71c3.3-1.56,7.18-2.15,10.54-1.6,5.95.98,6.81,4.98,15,7,3.88.96,7.89,1.95,11,0,6.24-3.9,2.76-15.75,6.33-16.83,2.93-.88,9.14,5.89,10.67,13.83,1.26,6.57-1.68,8.76-2,17-.25,6.48,1.5,11.32,5,21,2.15,5.95,5.56,13.99,11,23"
        />
      </g>
      <g id="Hip">
        <path
          id="Hip8"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          d="M159.83,32.11c3.87,11.72,8.87,22.72,14.87,34.72,5,10,10.54,19.26,19,26,13,10.35,17,19,28,25"
        />
        <path
          id="Hip7"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          d="M89.28,71.36c-12.35-7.77-7.68-16.69-6.66-21.71,1-8-3-14-7.05-19.63"
        />
        <path
          id="Hip6"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          d="M160.71,33.27h0c6.12,18.75,17.67,48.54,24.97,48.55-3.9-5.07.78-8.53-.3-11.73-1.65-4.88-2.72-8.04-2-12,1.14-6.24,5.42-7.39,5-12-.35-3.76-3.62-7.67-7-8-4.14-.4-5.8,4.84-10,5-2.43.09-6.02-1.5-10.67-9.83h0Z"
        />
        <path
          id="Hip5"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          d="M167.47,17.23c4.24.31,7.95.09,11.53-2.28,2.5-1.65,4.26-2.64,7.38-1.86,4.58,1.09,12.17,6.19,15.02,9.25,4.95,5.31,1.69,10.78,6,21,2.08,4.94,4.7,8.06,8,12,5.64,6.73,11.59,11.08,17,15,7.09,5.13,7.99,4.72,11,8,4.07,4.43,8.31,16.09,10.34,21.09"
        />
        <path
          id="Hip4"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          d="M178.38,90.08c4.42,0,7.3-.53,7.3-8.27"
        />
        <path
          id="Hip3"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          d="M155.38,50.09c34,84,11.5,68.16,13,68,1.35-.14,2.22-11.75-1-23-3.18-11.1-9.27-18.1-11-20-7.45-8.18-15.57-11.43-22-14-6.66-2.66-17.74-6.96-32-5-9.25,1.27-16.32,4.63-20.67,7.17"
        />
        <path
          id="Hip2"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          d="M178.38,90.09c-.14,11.3.44,20.57,1,27,2.26,25.83,6.77,34.31,9,38,3.39,5.6,8.09,13.38,16,14,5.8.45,12.88-2.98,15-9,2.41-6.85-2.85-13.54-4-15-1.25-1.6-5.75-6.85-26-11-6.03-1.24-14.56-2.6-25-3"
        />
        <path
          id="Hip1"
          class="rechte_Hufte_Adduktion_vd_obl_liegend2411_cls1"
          d="M224.02,194.25c-10.32,9.58-26.32,7.58-38.32,2.58-13-6-27-14-35-27-4-7-7-14-9-21-.75-3.39-1.51-6.93-2.26-10.5"
        />
      </g>
    </svg>
  );
}
