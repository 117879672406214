import style from "./style.scss";

function HalswirbelsauleReklinationLatStehend1610(props) {
  return (
<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 432.48 484.96">

<g id="Anatomy">
  <g id="cervical_spine" data-name="cervical spine">
    <g id="Inbetween_vertebraes" data-name="Inbetween vertebraes">
      <path id="Inbetween_vertebrae_5" data-name="Inbetween vertebrae 5" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M185.08,412.57c-2.48,1.98-1.98,6.94,2.48,14.87,4.46-1.49,7.93-1.49,11.4-.5,4.96-2.97,9.42-1.98,12.89,1.49,5.45-8.92,15.37-11.4,27.26-11.4,1.49-3.47,1.49-5.95.99-7.93-16.36,0-33.71,1.98-52.05,5.45l-2.97-1.98h0Z"/>
      <path id="Inbetween_vertebrae_4" data-name="Inbetween vertebrae 4" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M126.59,271.3c-3.97-6.44-5.45-12.39-4.46-17.84,10.41,0,20.82-2.48,31.23-7.44,3.97-.99,8.92-1.98,14.38-4.46,8.43-.5,10.91-3.97,5.95-9.42,4.46,1.49,8.92,3.47,10.41,6.44-1.98.5-3.47,1.49-4.46,3.97,0,2.97.5,6.44.99,9.91l-12.89.5-12.89,3.47c-10.91,5.95-22.8,16.85-28.25,14.87h-.01Z"/>
      <path id="Inbetween_vertebrae3" data-name="Inbetween vertebrae3" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M126.59,209.34c-3.97-8.92-4.96-16.85.5-22.31,4.46,7.44,11.9,6.44,22.31-2.48,5.45,6.44,10.91,9.91,16.85-2.48.99,2.48,1.98,3.97,2.97,3.97.99,3.47.5,6.94-3.47,10.41-12.39-2.97-25.78,1.98-39.16,12.89h0Z"/>
      <path id="Inbetween_vertebrae_2" data-name="Inbetween vertebrae 2" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M147.41,326.82c-3.97-5.45-5.45-10.41-4.46-14.87,6.44,4.46,10.91-3.97,21.31-9.91,3.47-3.47,10.41-6.44,17.84-9.42l2.48-.5c-.99,3.97-1.49,7.93-.5,12.39-15.86,0-22.8,5.45-25.28,14.38.99,5.45-4.96,6.94-11.4,7.93h.01Z"/>
      <path id="Inbetween_vertebrae_1" data-name="Inbetween vertebrae 1" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M164.76,384.32l-4.96-11.9c4.96.5,2.48,1.49,4.96-.99,2.48-4.46,6.94-5.95,11.9-6.94,5.45-1.49,13.88-7.93,22.31-13.88l2.48.99c4.46,4.96,15.86,11.9,13.38,16.85-8.43,3.97-28.25,4.46-38.17,5.95-6.94,3.97-8.43,2.48-11.9,9.91h0Z"/>
    </g>
    <g id="cervical_spine_vertebraes" data-name="cervical spine vertebraes">
      <path id="cervical_spine_vertebrae_1" data-name="cervical spine vertebrae 1" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M176.66,141.43c-15.37.99-23.79-6.44-18.84-28.25-8.92-5.45-13.38-10.91,1.49-16.85,9.42-12.89,21.81-13.88,37.18-2.97,11.4-4.96,21.81-.99,31.23,17.84l9.42,13.88c10.91,1.98,15.86,13.38,20.32,20.32,20.82,25.78,8.43,38.17-11.9,17.84-9.42-9.91-7.44-20.32-13.88-22.8-6.44,7.93-13.88,9.42-22.31-3.47-1.49,5.45-5.45,9.42-11.9,12.89-8.43-2.48-15.86-4.96-20.82-8.43h0Z"/>
      <path id="cervical_spine_vertebrae_2" data-name="cervical spine vertebrae 2" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M238.12,181.09c7.44.5,25.78,9.42,27.76,18.34-3.47,18.34-26.27,7.93-38.66-4.46-17.35,3.97-30.73,0-33.21-6.44-3.47,10.41-10.91,7.93-20.32-3.47,7.44-6.94,4.96-10.91,1.98-12.39-3.47-1.98-5.95.99-4.96,10.41-2.48,3.97-2.97,3.47-4.96-.99-5.45,11.9-10.91,8.92-16.85,2.48-13.38,9.91-17.84,8.43-20.82,4.46-4.96-5.95-2.97-13.88,1.49-21.81-.5-10.41,12.89-17.35,17.35-23.79,9.42.99,25.78.99,38.17.5,1.49-.5,3.97.5,5.95,2.48,2.97,1.98,7.44,7.44,7.93,11.9,28.75.5,30.24,6.44,39.16,22.8h0v-.02Z"/>
      <path id="cervical_spine_vertebrae_3" data-name="cervical spine vertebrae 3" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M203.42,241.06c-.99,2.48-.5,4.96.99,7.44-8.43.99-16.36,0-24.29-3.97,0-2.48.99-3.97,3.97-4.96-2.48-3.97-5.95-5.45-9.42-6.44,1.98,2.97,2.48,5.95.99,7.93-1.98.99-3.97,1.49-6.44,1.49-7.93,2.97-15.86,5.95-23.79,8.92-9.91,2.48-18.34,3.47-22.31.99-.99-.5-.99-3.47.99-13.88-4.46-7.93.99-22.31,1.49-26.27,18.84-15.86,28.25-15.37,40.15-12.39,1.98,1.49,2.97,3.97,2.48,5.95,0,2.97,4.96,4.46,7.93,1.98,4.96-2.97-2.97-10.41-5.95-10.41-1.49,0,1.98-5.45,2.97-6.94,7.93,8.43,18.34,8.43,20.82,0,1.49,5.95,2.48,11.4.5,15.86,11.9-1.49,22.31,0,26.77,7.93v3.97c9.91-3.47,17.35,7.44,26.77,12.39,4.46.5,10.91,2.48,12.89,5.45,2.48,3.97.99,11.4-5.95,13.88-2.97.99-9.91-2.97-15.86-5.45-3.47,1.49-5.45.5-10.91.99-4.96-2.97-9.91-4.46-14.87-5.45-1.98,2.48-5.45,2.97-9.91.99h-.01Z"/>
      <path id="cervical_spine_vertebrae_4" data-name="cervical spine vertebrae 4" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M201.44,281.21c3.97,3.97,10.91,2.97,16.36,5.95,2.48,4.96,9.91,6.44,25.78,2.48,3.47,4.96,7.44,8.43,14.38,6.94,3.97-2.48,4.96-7.93,3.47-15.37-3.97-4.46-6.94-4.96-9.91-.5-5.45-3.47-10.41-5.95-13.38-2.97-13.88-14.38-15.86-21.31-20.82-19.83-3.47,0-6.94.5-9.91,1.98-3.47-3.97-8.43-3.47-12.89-2.97l-1.98-8.43c-6.44,1.49-13.38.5-21.31-3.47l1.98,9.42c-12.39-.99-23.79,4.46-33.71,15.86-1.98,2.48-9.42,2.48-11.9,2.48-1.49,4.96-1.98,11.4,3.47,10.91,2.48,6.94,4.46,14.87,3.97,22.8-.99,4.96,9.42,6.44,14.38,6.44,3.97,0,7.44-8.43,14.87-10.91l16.85-8.92c5.95-4.46,15.86,3.47,23.79-4.46l-3.47-7.44h-.02Z"/>
      <path id="cervical_spine_vertebrae_5" data-name="cervical spine vertebrae 5" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M275.3,347.64l-3.97-1.49c.5-4.46,0-8.43-1.49-11.4-2.48-2.97-5.45-3.97-8.43-2.97-3.97-6.44-9.91-9.91-15.86-10.91-7.44-11.9-18.84-18.34-33.21-19.33.99-4.46-.99-8.43-5.45-10.91-4.96,4.46-11.9,3.97-19.83,1.98-1.49,1.49-1.98,4.96-1.98,13.88-16.85-1.49-24.78,4.46-27.26,14.87.5,3.47-.5,6.44-4.96,7.44-10.91,0-9.42,3.47-5.95,13.88,6.44,20.82,11.4,33.71,17.35,29.74,2.48-3.97,6.44-6.44,11.9-6.94,12.39-4.96,15.86-11.9,19.83-13.88,0-2.97-.5-5.45-.5-6.44-3.97-4.46-4.46-8.92-.99-13.38,6.44.99,8.43,4.46,6.44,10.91l1.98,1.49c4.96,5.95,11.9,8.92,22.8,3.47.99-2.97.5-5.95-.99-9.91,6.44-1.49,11.4-1.49,14.38.5,4.46,7.93,11.4,12.89,20.32,13.88,10.91,3.97,14.87,1.49,15.86-4.46h0v-.02Z"/>
      <path id="cervical_spine_vertebrae_6" data-name="cervical spine vertebrae 6" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M243.57,371.43c13.88-10.91,15.37,13.38,37.67,20.32,10.41-1.49,19.33-1.98,21.81,1.98-1.49,3.97-3.97,6.44-6.44,6.94-4.46,0-8.43.99-10.91,5.45-10.41-.5-20.82-.99-24.78-4.96-2.48,5.45-7.44,5.95-16.36,1.49-3.47-3.47-10.41,4.96-9.42,4.96,2.48,0-3.47-8.43-9.91-9.42,2.48-6.44.99-9.91-.99-12.39-3.47-1.98-7.44,1.98-9.42,5.95,4.96,6.94,5.95,12.39,2.48,18.84l-17.35.99-13.38,1.49-3.97.5c-7.44-6.94-17.35-13.38-13.38-23.79-7.44-5.95-1.98-11.9,15.37-18.34,9.91.99,18.84,0,27.26-.99,3.47-2.48,3.97-6.94.99-12.89l3.97-5.95c3.97,6.44,10.41,9.91,20.82,7.44,1.49,2.48,1.49,5.45-1.49,10.41,2.97-.5,5.45,0,7.44,1.98h0Z"/>
      <path id="cervical_spine_vertebrae_7" data-name="cervical spine vertebrae 7" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M327.84,435.87l8.92-5.95c5.95,3.97,9.91,8.92,11.4,14.87,1.49,4.96-7.44,7.44-20.32,9.42-1.49-3.47-4.46-5.45-7.93-5.95-18.34,4.46-35.69,3.47-42.63,6.44-2.97,3.97-5.95,7.44-10.41,8.43-4.46-.5-8.92-2.97-13.38-6.44,2.48-5.95.99-11.4-7.44-14.87-4.46,2.48-4.46,7.93-3.97,13.88-.99,3.47-2.97,5.95-6.94,6.94-6.44.99-12.39,1.49-19.33-.5-9.91,7.44-8.43,9.91-14.87,7.93-1.49,2.97-3.97,1.49-7.93-5.45,1.49-1.98-5.45-24.78-6.94-25.78-.99-4.96,0-8.43,2.48-9.91,1.98-1.49,5.45-1.49,9.91-.5,4.96-2.97,7.93-1.49,10.91,1.49,3.47-8.43,12.39-10.41,23.3-10.91,4.46,1.98,6.94,3.97,6.44,7.44,2.48,0,3.97-1.49,4.96-2.97-4.96-9.91-5.95-17.84-2.48-23.79,5.45,2.48,9.91,5.45,9.42,9.42,4.46-6.44,6.94-4.46,9.91-4.46l.99,10.41c1.49,5.45,5.95,5.95,11.4,4.46,1.98-4.46,7.44-3.47,17.35,3.47,6.44,6.94,20.82,10.91,37.18,12.89h0Z"/>
      <path id="cervical_spine_vertebrae_line_7" data-name="cervical spine vertebrae line 7" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M183.1,393.74c6.44-3.97,13.88-5.45,21.31-4.46,5.95.5,8.43-2.97,10.91-6.94.99-1.98,2.97-1.98,4.96-.99"/>
      <path id="cervical_spine_vertebrae_line_6" data-name="cervical spine vertebrae line 6" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M137,167.21c.99,2.97,2.48,3.97,4.96,4.96.99,1.98.99,3.97.99,5.95-.99,1.98,2.97,3.97,5.95,6.44"/>
      <path id="cervical_spine_vertebrae_line_5" data-name="cervical spine vertebrae line 5" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M179.63,132.01h-11.4c-2.97-1.49-2.48-3.47.5-5.95,4.96-1.49,6.94-5.45,6.94-11.9"/>
      <path id="cervical_spine_vertebrae_line_4" data-name="cervical spine vertebrae line 4" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M139.98,216.28c-3.47,4.96-3.47,8.43.5,10.41,0,9.42,2.48,13.88,9.42,10.91,1.98-1.98,2.48-4.46,2.48-7.44,3.47,0,7.44-1.98,11.4-3.97"/>
      <path id="cervical_spine_vertebrae_line_3" data-name="cervical spine vertebrae line 3" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M159.8,289.15c-3.47,4.96-5.95,6.94-8.43,5.95-2.97-2.97-3.47-5.95-1.49-7.93,1.49-1.49,0-3.47-.99-4.96-4.96-.99-5.95-3.47-5.45-5.95l1.98-3.97"/>
      <polyline id="cervical_spine_vertebrae_line_2" data-name="cervical spine vertebrae line 2" class="Halswirbelsaule_Reklination_lat1610_cls8" points="158.32 342.18 163.77 348.13 177.15 337.72"/>
      <path id="cervical_spine_vertebrae_line_1" data-name="cervical spine vertebrae line 1" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M216.81,436.86c7.44-1.49,12.39-4.96,12.39-13.88,1.98-4.46,5.45-4.46,8.43-4.46.99,1.98,2.97,4.46-.5,6.44l1.98,5.45c2.97,3.97.5,6.44-5.95,7.93"/>
    </g>
    <g id="tube_lines" data-name="tube lines">
      <path id="tube_line_7" data-name="tube line 7" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M179.63,132.51h0c-.99,11.9-18.34,21.81-12.89,29.25-4.96,5.45-10.91,7.44-17.84,4.46,4.96-11.9,21.81-25.28,21.31-36.68l1.49,2.48,7.93.5h0Z"/>
      <path id="tube_line_6" data-name="tube line 6" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M164.76,210.83c-3.97,3.47-7.44,5.45-10.41,5.45-.5-11.9-.99-21.31-1.49-28.25h9.42c0,9.42.5,18.34,2.48,22.8h0Z"/>
      <path id="tube_line_5" data-name="tube line 5" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M154.35,232.64c4.46-2.97,8.43-5.45,11.9-2.97l4.46,31.23c-2.97,5.45-6.44,8.43-10.91,6.94l-5.45-35.19h0Z"/>
      <path id="tube_line_4" data-name="tube line 4" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M159.8,289.15c2.97-.5,4.96-2.97,4.96-8.43.99-3.97,1.98-5.95,3.47-5.95l7.44,37.67c.99.99-4.96,7.93-6.94,5.95-.99-1.98-7.93-33.21-8.92-29.25h-.01Z"/>
      <path id="tube_line_3" data-name="tube line 3" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M213.83,484.45c-4.96-1.49-4.46.5-4.46-2.48,9.91-13.38-11.9-36.68-8.92-44.12,3.97-9.91,7.44-19.33,6.44-24.78.99-7.44,1.98-14.38,2.48-21.31,3.47-2.97,6.94-2.97,10.91-1.49.99,9.42-.99,19.33-3.47,30.24-2.48,4.96-4.46,10.41-6.44,15.37-5.95,12.39,10.91,33.71,8.43,47.59l-4.96.99h0Z"/>
      <path id="tube_line_2" data-name="tube line 2" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M172.69,342.18c2.97-4.96,6.44-5.45,10.41-3.47,1.98,14.38,4.46,27.76,8.92,37.67-3.97,3.47-8.43,5.45-11.9,4.96-.99-10.41-3.97-24.78-7.44-39.16h0Z"/>
      <path id="tube_line_1" data-name="tube line 1" class="Halswirbelsaule_Reklination_lat1610_cls8" d="M209.87,118.63c-6.44.5-9.91-.99-12.89-5.45,3.97-4.46,6.44-8.92,8.92-7.44,1.49-1.98,11.9,6.44,23.3,8.92,7.44-1.98,13.88-4.96,19.83-12.39,6.44.5,8.92,2.97,7.44,7.44-10.91,8.43-18.84,20.82-43.62,6.44l-2.97,2.48h-.01Z"/>
    </g>
  </g>
  <g id="Head">
    <path id="outlines_1" data-name="outlines 1" class="Halswirbelsaule_Reklination_lat1610_cls6" d="M425.99.16c.5,1.49.5,2.97.99,4.96l3.97,20.32c.5,33.71-4.46,65.93-24.29,92.69-13.38,24.29-29.25,45.6-52.54,55.52-7.93,4.96-18.84,5.95-31.23,5.95-21.31,4.46-35.69-2.48-48.58-12.39-4.96,0-9.42-.99-12.89-2.48l-11.9-11.4c-7.44-2.48-10.41-8.43-12.39-15.37-7.44,1.49-13.38-2.97-18.84-8.92-11.4-.5-15.37-5.45-10.41-14.87l7.44-10.91c-7.44,2.97-14.38,4.46-19.83,3.47-6.44,0-12.89-.5-18.84-.5,3.97-4.46,8.43-6.44,13.38-5.45,4.46.5,8.92,0,13.38-2.48,4.96-1.98,4.96-4.96,3.97-7.44-1.49-3.97-.5-8.92,1.49-13.88-17.35,5.45-34.2,10.91-44.12,11.9-11.4,2.48-22.31,8.43-32.72,17.35-15.86,11.9-27.76,11.9-37.18,3.97-10.91-12.39-24.78-21.31-40.65-27.26-16.85-5.95-33.21-18.34-49.57-33.21-9.42-14.87-3.47-22.31,10.91-26.27,7.44-.99,13.38-5.95,17.35-14.38.5-1.49.5-2.97.99-3.97,87.74-.63,286.52,3.1,392.09-4.96h.02Z"/>
    <path id="outlines_1-2" data-name="outlines 1-2" class="Halswirbelsaule_Reklination_lat1610_cls1" d="M426.98.16c.5,1.49.5,2.97.99,4.96l3.97,20.32c.5,33.71-4.46,65.93-24.29,92.69-13.38,24.29-29.25,45.6-52.54,55.52-7.93,4.96-18.84,5.95-31.23,5.95-21.31,4.46-35.69-2.48-48.58-12.39-4.96,0-9.42-.99-12.89-2.48l-11.9-11.4c-7.44-2.48-10.41-8.43-12.39-15.37-7.44,1.49-13.38-2.97-18.84-8.92-11.4-.5-15.37-5.45-10.41-14.87l7.44-10.91c-7.44,2.97-14.38,4.46-19.83,3.47-6.44,0-12.89-.5-18.84-.5,3.97-4.46,8.43-6.44,13.38-5.45,4.46.5,8.92,0,13.38-2.48,4.96-1.98,4.96-4.96,3.97-7.44-1.49-3.97-.5-8.92,1.49-13.88-17.35,5.45-34.2,10.91-44.12,11.9-11.4,2.48-22.31,8.43-32.72,17.35-15.86,11.9-27.76,11.9-37.18,3.97-10.91-12.39-24.78-21.31-40.65-27.26-16.85-5.95-33.21-18.34-49.57-33.21-10.41-15.37-3.97-22.8,10.41-26.77,7.44-.99,13.38-5.95,17.35-14.38.5-1.49.5-2.97.99-3.97"/>
    <path id="skull_1" data-name="skull 1" class="Halswirbelsaule_Reklination_lat1610_cls1" d="M304.05,131.52c-1.49,1.49-3.47,2.48-4.96,2.97-1.98-1.49-3.97-.99-6.94.99-2.48,0-3.97,1.49-3.97,3.97-1.49,2.48-1.49,4.96-.99,7.93l-4.96-5.45c-4.46-1.98-5.95.99-7.44,4.96-3.47-.5-5.95-1.98-7.93-4.46-3.97,2.97-7.93,2.97-10.91.99-4.96-6.44-9.42-8.43-13.88-5.45l-3.47.99"/>
    <path id="jaw_joint_1" data-name="jaw joint 1" class="Halswirbelsaule_Reklination_lat1610_cls1" d="M209.37,76.49c5.45.5,8.92-1.98,11.4-6.44"/>
  </g>
</g>
<g id="Overlays">
  <g id="cervical_spine_vertebraes_Highlight" data-name="cervical spine vertebraes Highlight" class="Halswirbelsaule_Reklination_lat1610_cls7">
    <path id="cervical_spine_vertebraes_Highlight8" data-name="cervical spine vertebraes Highlight8" class="Alle_HWK_1610Fill" style= {{
              fill: props.colors.Alle_HWK_1610Fill,
              stroke: props.colors.Alle_HWK_1610Fill,
              opacity:props.colors.Alle_HWK_1610Fill,
            }} d="M219.59,108.04c.11-1.9.24-3.87.26-5.84-.31-1.28-.66-2.56-1.03-3.84-6.94-7.07-14.34-7.95-22.22-4.52-15.37-10.91-27.76-9.92-37.18,2.97-14.87,5.94-10.41,11.4-1.49,16.85-4.95,21.81,3.47,29.24,18.83,28.25,1.33.93,2.83,1.78,4.48,2.59.17-.02.33-.05.52-.05.48,0,.9.08,1.3.2.64-.4,1.41-.65,2.35-.65,1.5,0,2.61.61,3.33,1.51,1.31.29,2.23,1.08,2.75,2.1.43.33.79.73,1.06,1.19,1.63.52,3.3,1.04,5.03,1.55,6.45-3.47,10.41-7.44,11.9-12.89.34.53.68,1.02,1.02,1.5.06-.47.19-.92.38-1.35-.77-.96-1.18-2.23-.88-3.74,1.77-9.07,5.45-17.56,9.59-25.82Z"/>
    <path id="cervical_spine_vertebraes_Highlight7" data-name="cervical spine vertebraes Highlight7" class="Alle_HWK_1610Fill" style= {{
              fill: props.colors.Alle_HWK_1610Fill,
              stroke: props.colors.Alle_HWK_1610Fill,
              opacity:props.colors.Alle_HWK_1610Fill,
            }} d="M180.53,144.62c.06-.02.11-.04.17-.06-11.63.33-25.35.22-33.67-.65-4.46,6.44-17.85,13.38-17.35,23.79-4.46,7.93-6.45,15.86-1.49,21.81,2.98,3.97,7.44,5.45,20.82-4.46,1.3,1.41,2.58,2.65,3.84,3.6.65-.42,1.44-.69,2.41-.69,1.17,0,2.09.38,2.79.96.25.04.48.09.71.16.42-.12.86-.21,1.36-.21s.96.09,1.38.22c1.46-1.27,2.91-3.36,4.36-6.53,1.99,4.46,2.48,4.96,4.96.99-.78-7.46.61-10.86,2.95-10.98,1.16-7.84,2.13-16.07,3.66-23.87.63-3.19.79-4.19,3.1-4.1Z"/>
    <path id="cervical_spine_vertebraes_Highlight6" data-name="cervical spine vertebraes Highlight6" class="Alle_HWK_1610Fill" style= {{
              fill: props.colors.Alle_HWK_1610Fill,
              stroke: props.colors.Alle_HWK_1610Fill,
              opacity:props.colors.Alle_HWK_1610Fill,
            }} d="M177.94,206.59c0-.29.05-.56.1-.82-.06-.02-.13-.03-.19-.06,0,1.01-.44,1.94-1.59,2.62-2.97,2.48-7.93.99-7.93-1.98.49-1.98-.5-4.46-2.48-5.95-11.9-2.98-21.31-3.47-40.15,12.39-.5,3.96-5.95,18.34-1.49,26.27-1.98,10.41-1.98,13.38-.99,13.88,3.97,2.48,12.4,1.49,22.31-.99,7.93-2.97,15.86-5.95,23.79-8.92,2.47,0,4.46-.5,6.44-1.49,1.49-1.98.99-4.96-.99-7.93,2.02.58,4.04,1.33,5.87,2.63-.95-9.89-2.93-19.69-2.71-29.65Z"/>
    <path id="cervical_spine_vertebraes_Highlight5" data-name="cervical spine vertebraes Highlight5" class="Alle_HWK_1610Fill" style= {{
              fill: props.colors.Alle_HWK_1610Fill,
              stroke: props.colors.Alle_HWK_1610Fill,
              opacity:props.colors.Alle_HWK_1610Fill,
            }} d="M200.89,281.16c-1.78-7.8-4.06-15.47-5.92-23.24-.04-.18-.08-.36-.1-.54-.08,0-.16.02-.24.03l-1.98-8.43c-6.44,1.49-13.38.5-21.31-3.47l1.98,9.42c-12.39-.99-23.79,4.46-33.71,15.86-1.98,2.48-9.42,2.48-11.9,2.48-1.49,4.96-1.98,11.4,3.47,10.91,2.48,6.94,4.46,14.87,3.97,22.8-.99,4.96,9.42,6.44,14.38,6.44,3.97,0,7.44-8.43,14.87-10.91l16.85-8.92c5.95-4.46,15.86,3.47,23.79-4.46l-2.54-5.44c-.75-.52-1.34-1.34-1.61-2.52Z"/>
    <path id="cervical_spine_vertebraes_Highlight4" data-name="cervical spine vertebraes Highlight4" class="Alle_HWK_1610Fill" style= {{
              fill: props.colors.Alle_HWK_1610Fill,
              stroke: props.colors.Alle_HWK_1610Fill,
              opacity:props.colors.Alle_HWK_1610Fill,
            }} d="M193.08,334.76c-3.59-9.78-7.11-19.72-7.84-30.19-.03-.49.02-.92.11-1.32-.04-.05-.08-.1-.11-.16-.03,1.17-.04,2.45-.04,3.88-16.85-1.49-24.78,4.46-27.26,14.87.5,3.47-.5,6.44-4.96,7.44-10.91,0-9.42,3.47-5.95,13.88,6.44,20.82,11.4,33.71,17.35,29.74,2.48-3.97,6.44-6.44,11.9-6.94,12.39-4.96,15.86-11.9,19.83-13.88,0-2.97-.5-5.45-.5-6.44-3.23-3.63-4.16-7.26-2.52-10.88Z"/>
    <path id="cervical_spine_vertebraes_Highlight3" data-name="cervical spine vertebraes Highlight3" class="Alle_HWK_1610Fill" style= {{
              fill: props.colors.Alle_HWK_1610Fill,
              stroke: props.colors.Alle_HWK_1610Fill,
              opacity:props.colors.Alle_HWK_1610Fill,
            }} d="M220.5,389.69c-.14-2.06.92-4.21,3.22-4.74-2.96-6.18-6.04-12.3-8.94-18.51-.32,1.86-1.27,3.37-2.82,4.49-8.42.99-17.35,1.98-27.26.99-17.35,6.44-22.81,12.39-15.37,18.34-3.97,10.41,5.94,16.85,13.38,23.79l3.97-.5,13.38-1.49,17.35-.99c3.47-6.45,2.86-10.81,3.31-18.1.11-.23.21-.46.3-.69-.41-.79-.57-1.7-.52-2.59Z"/>
    <path id="cervical_spine_vertebraes_Highlight2" data-name="cervical spine vertebraes Highlight2" class="Alle_HWK_1610Fill" style= {{
              fill: props.colors.Alle_HWK_1610Fill,
              stroke: props.colors.Alle_HWK_1610Fill,
              opacity:props.colors.Alle_HWK_1610Fill,
            }} d="M245.37,442.81c-1.07-5.57-1.93-11.2-3.25-16.72-.78.51-1.73.85-2.9.85.5-3.47-1.98-5.46-6.44-7.44-8.18.38-15.74.91-20.1,5.21-2.14,2.11-5.09,3.25-8.05,2.69-1.7-.32-3.64.07-6.06,1.51-4.46-.99-7.93-.99-9.91.5-2.48,1.48-3.47,4.95-2.48,9.91,1.49,1,8.43,23.8,6.94,25.78,3.96,6.94,6.44,8.42,7.93,5.45,6.44,1.98,4.96-.49,14.87-7.93,6.94,1.99,12.89,1.49,19.33.5,3.97-.99,5.95-3.47,6.94-6.94-.46-5.57-.49-10.71,3.18-13.37Z"/>
    <path id="cervical_spine_vertebraes_Highlight1" data-name="cervical spine vertebraes Highlight1" class="Alle_HWK_1610Fill" style= {{
              fill: props.colors.Alle_HWK_1610Fill,
              stroke: props.colors.Alle_HWK_1610Fill,
              opacity:props.colors.Alle_HWK_1610Fill,
            }} d="M216.92,437.34c7.44-1.49,12.39-4.96,12.39-13.88,1.98-4.46,5.45-4.46,8.43-4.46.99,1.98,2.97,4.46-.5,6.44l1.98,5.45c2.97,3.97.5,6.44-5.95,7.93"/>
  </g>
  <path id="_4ten_HWK_highlight" data-name="4ten HWK highlight" class="HWK4_1610Fill" style= {{
fill: props.colors.HWK4_1610Fill,
stroke: props.colors.HWK4_1610Fill,
opacity: props.colors.HWK4_1610Fill,
}} d="M200.86,280.52c-1.78-7.8-4.06-15.47-5.92-23.24-.04-.18-.08-.36-.1-.54-.08,0-.16.02-.24.03l-1.98-8.43c-6.44,1.49-13.38.5-21.31-3.47l1.98,9.42c-12.39-.99-23.79,4.46-33.71,15.86-1.98,2.48-9.42,2.48-11.9,2.48-1.49,4.96-1.98,11.4,3.47,10.91,2.48,6.94,4.46,14.87,3.97,22.8-.99,4.96,9.42,6.44,14.38,6.44,3.97,0,7.44-8.43,14.87-10.91l16.85-8.92c5.95-4.46,15.86,3.47,23.79-4.46l-2.54-5.44c-.75-.52-1.34-1.34-1.61-2.52Z"/>
  <g id="Reklination_Highlight" data-name="Reklination Highlight">
    <path id="Reklination_Highlight2" data-name="Reklination Highlight2" class="Reklination1610Fill" style= {{
fill: props.colors.Reklination1610Fill,
stroke: props.colors.Reklination1610Fill,
opacity: props.colors.Reklination1610Fill,
}} d="M76.27,163.76l-11.39,19.59-9.71,24.65c-1.42,3.6-2.31,7.38-2.66,11.23l-3.39,37.72c-.12,1.36-.18,2.73-.18,4.1v39.01c0,.88.06,1.76.18,2.63l7.41,53.18c.1.74.24,1.47.41,2.19l15.49,65.95c.14.58.29,1.15.47,1.72l5.3,16.67c.54,1.69,1.16,3.34,1.87,4.96l4.07,9.3c.69,1.59,2.52,2.34,4.13,1.69l3.37-1.35c2.86-1.14,4.27-4.36,3.19-7.24l-6.62-17.54c-.36-.95-.66-1.93-.9-2.92l-16.46-67.59c-.33-1.34-.59-2.7-.78-4.07l-7.61-54.12c-.2-1.44-.33-2.88-.38-4.33l-1.2-33.69c-.06-1.6,0-3.21.15-4.81l3.13-31.25c.19-1.87.53-3.71,1.01-5.52l5.06-18.8c.39-1.45.88-2.87,1.47-4.24l5.31-12.39c.43-.99.89-1.97,1.38-2.94l8.37-16.33-10.48-5.47Z"/>
    <polygon id="Reklination_Highlight1" data-name="Reklination Highlight1" class="Reklination1610Fill" style= {{
fill: props.colors.Reklination1610Fill,
stroke: props.colors.Reklination1610Fill,
opacity: props.colors.Reklination1610Fill,
}} points="66.24 159.2 94.95 145.53 94.95 176.29 66.24 159.2"/>
  </g>
  <g id="Hinterkanten_Highlight" data-name="Hinterkanten Highlight">
    <path id="Hinterkanten_Highlight7" data-name="Hinterkanten Highlight7" class="Hinterkanten_1610Fill" style= {{
fill: props.colors.Hinterkanten_1610Fill,
stroke: props.colors.Hinterkanten_1610Fill,
opacity: props.colors.Hinterkanten_1610Fill,
}} d="M169.23,174.47c-.34.49-.16.77-.23,3.19-.02.86-.08,1.86-.23,2.96-.21,1.52-.31,2.27-.68,2.51-1,.62-2.42-1.42-4.1-.91-1.1.33-1.63,1.55-2.05,2.51-.53,1.22-1.09,3.48-.23,4.1.54.39,1.1-.24,3.42-.68,1.8-.34,1.98-.06,3.19-.46.58-.19,1.93-.63,2.96-1.82,1.03-1.2,1.23-2.56,1.37-3.65.34-2.65-.67-3.19-.23-5.7.29-1.63.86-2.24.46-2.73-.61-.76-2.85-.48-3.65.68Z"/>
    <path id="Hinterkanten_Highlight6" data-name="Hinterkanten Highlight6" class="Hinterkanten_1610Fill" style= {{
fill: props.colors.Hinterkanten_1610Fill,
stroke: props.colors.Hinterkanten_1610Fill,
opacity: props.colors.Hinterkanten_1610Fill,
}} d="M173.33,230.52c-.26.49-.28,1.02.46,4.1.5,2.08.67,2.5.46,3.19-.4,1.29-1.64,1.96-1.82,2.05-1.1.57-1.68.17-4.33.46-2.04.22-2.37.52-2.51.68-.78.9-.56,2.87.46,3.65.8.62,1.57.08,5.01-.46,3.23-.51,3.68-.21,4.78-.91,1.78-1.13,2.38-3,2.51-3.42.45-1.53.18-2.81,0-3.65-.3-1.42-2.71-6.45-1.82-4.33,0,0-.08-2.1-.91-2.51-.62-.3-1.8.25-2.28,1.14Z"/>
    <path id="Hinterkanten_Highlight5" data-name="Hinterkanten Highlight5" class="Hinterkanten_1610Fill" style= {{
fill: props.colors.Hinterkanten_1610Fill,
stroke: props.colors.Hinterkanten_1610Fill,
opacity: props.colors.Hinterkanten_1610Fill,
}} d="M198.39,280.64c-.24.8.45,1.71,1.59,3.19,1.04,1.35,1.55,1.55,1.82,2.51.07.23.45,1.56-.23,2.51-.65.91-1.96,1.01-3.65,1.14-2.09.16-2.9-.23-3.19.23-.42.65.54,2.46,2.05,3.19.55.26,1.15.37,5.24-.46,3.2-.65,3.76-.9,4.33-1.37.34-.28,1.66-1.35,1.82-2.96.12-1.2-.5-1.75-2.73-5.01-2.25-3.28-2.53-4.07-3.65-4.33-1.38-.32-3.11.36-3.42,1.37Z"/>
    <path id="Hinterkanten_Highlight4" data-name="Hinterkanten Highlight4" class="Hinterkanten_1610Fill" style= {{
fill: props.colors.Hinterkanten_1610Fill,
stroke: props.colors.Hinterkanten_1610Fill,
opacity: props.colors.Hinterkanten_1610Fill,
}} d="M190.19,338.06c1.04,2.41,1.01,2.56,1.59,3.65.99,1.84,1.36,1.94,1.82,3.19.31.83.88,2.36.46,4.1-.52,2.13-2.26,3.5-2.73,3.87-1.22.96-1.94.96-2.28,1.82-.39,1.01.01,2.48.91,2.96.85.46,1.93-.15,4.1-1.37,1.42-.8,2.41-1.36,3.42-2.51.67-.76,1.56-1.78,1.82-3.19.16-.87.03-1.63-2.05-5.92-1.82-3.75-2.06-3.87-2.73-5.7-.17-.47-.54-1.51-1.14-2.96-1.08-2.63-1.48-3.12-2.05-3.42-.96-.51-2.46-.49-2.96.23-.42.6.13,1.32,1.82,5.24Z"/>
    <path id="Hinterkanten_Highlight3" data-name="Hinterkanten Highlight3" class="Hinterkanten_1610Fill" style= {{
fill: props.colors.Hinterkanten_1610Fill,
stroke: props.colors.Hinterkanten_1610Fill,
opacity: props.colors.Hinterkanten_1610Fill,
}} d="M240.09,441.73c-.55.99-.16,1.78,0,3.65.13,1.56-.12,1.29-.46,5.7-.34,4.41-.09,4.79-.68,5.92-1.06,2.03-2.96,2.97-3.42,3.19-1.42.68-2.77.76-5.47.91-2.68.15-3.63-.08-4.33.68-.7.76-.83,2.19-.23,2.96.56.72,1.49.55,5.01.46,4.32-.11,4.38.11,5.7-.23,2.25-.59,3.77-1.69,4.56-2.28,1.46-1.08,2.93-2.18,3.65-4.1.44-1.19.37-2.39.23-4.78-.16-2.73-.56-3.07-.46-5.24.07-1.49.12-2.47.68-3.65.59-1.23,1.17-1.48,1.14-2.28-.04-1.13-1.3-2.33-2.73-2.51-1.36-.17-2.64.61-3.19,1.59Z"/>
    <path id="Hinterkanten_Highlight2" data-name="Hinterkanten Highlight2" class="Hinterkanten_1610Fill" style= {{
fill: props.colors.Hinterkanten_1610Fill,
stroke: props.colors.Hinterkanten_1610Fill,
opacity: props.colors.Hinterkanten_1610Fill,
}} d="M207.96,412.8c.65.61,1.4.42,5.01,0,3.99-.47,5.98-.7,6.84-.68,1.86.03,1.3.26,1.82-.23.82-.78,1.39-2.19.91-5.7-.17-1.25-.46-3.4-.46-5.7.01-4.15.99-5.38,0-6.61-1.03-1.27-3.42-1.59-4.56-.68-1.14.91-.96,2.98-.68,6.15.13,1.51.35,2.39.23,4.1-.1,1.4-.16,2.19-.68,2.96-.95,1.41-2.74,1.77-4.1,2.05-2.68.55-3.77-.18-4.56.68-.81.89-.69,2.78.23,3.65Z"/>
    <path id="Hinterkanten_Highlight1" data-name="Hinterkanten Highlight1" class="Hinterkanten_1610Fill" style= {{
fill: props.colors.Hinterkanten_1610Fill,
stroke: props.colors.Hinterkanten_1610Fill,
opacity: props.colors.Hinterkanten_1610Fill,
}} d="M207.05,135.05c-1.37.48-1.07,2.11-3.19,5.47-.98,1.55-1.89,3-3.65,4.1-.64.41-2.91,1.84-5.47,1.37-3.41-.63-4.35-4.09-5.92-3.65-1.34.38-2.12,3.29-1.37,5.47.88,2.55,3.65,3.49,5.47,4.1,1.19.4,3.2,1.08,5.47.46,1.45-.4,2.76-.1,5.47-2.28,4.23-3.4,3.81-4,5.47-5.92,1.61-1.86,3.31-3.82,2.73-5.92-.58-2.14-3.33-3.78-5.01-3.19Z"/>
  </g>
</g>
</svg>
  );
}

export default HalswirbelsauleReklinationLatStehend1610;
