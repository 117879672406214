import TabSubMenu from "../Blueprints/TabSubMenu/TabSubMenu";
import { EXAM_HELPER_ROUTES } from "./Consts/ExamHelperRoutes";
import { createContext, useState } from "react";

export const ExamHelperContext = createContext(null);

function ExamHelper() {
  const [examListShrinked, setExamListShrinked] = useState(false);
  return (
    <ExamHelperContext.Provider
      value={{ examListShrinked, setExamListShrinked }}
    >
      <TabSubMenu subMenuItems={EXAM_HELPER_ROUTES} />;
    </ExamHelperContext.Provider>
  );
}

export default ExamHelper;
