import style from "./style.scss";

export default function LinkesSchlusselbeinDvStehend2015(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 597.29 285.29">
      <g id="collarbone_recording" data-name="collarbone recording">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="Schlùsselbein_left2015_cls6"
            d="M270.74,272.03c-3.37-3.61-2.11-7.72-1.24-26.2.8-16.84.08-33.72,0-50.58-.1-22.01.04-25.82-1.37-33.49-2.35-12.79-3.52-19.18-6.15-23.92-1.84-3.31-4.27-6.68-4.1-11.62.2-5.75,3.8-9.82,4.78-10.94.39-.45,7.93-8.73,17.09-6.84,4.97,1.03,7.95,4.55,11.62,8.89,4.32,5.1,4.23,7.41,8.89,14.35,1.8,2.69,4.37,7.75,9.57,11.62,4.14,3.08,9.88,5.68,21.87,10.25,12.77,4.87,17.91,5.82,19.82,6.15,4.17.71,4.7.42,9.57,1.37,6.52,1.27,8.51,2.36,11.62,1.37.32-.1,4.84-1.62,6.15-5.47,1.23-3.62-1.57-5.72-2.05-10.25-.85-7.95,6.35-14.63,7.52-15.72,4.79-4.45,9.2-5.51,19.82-6.15,6.74-.41,6.6,4.85,11.62,4.78,1.29-.02,2.85-.28,12.3-6.15,5.2-3.24,6.39-4.19,9.57-6.15,7.78-4.8,9.08-4.03,14.35-7.52,6.88-4.55,7.68-7.01,17.09-15.04,4.71-4.02,3.67-2.69,12.3-9.57,16.13-12.85,21.04-18.52,23.24-21.19,4.15-5.04,3.35-5.51,6.15-8.2,8.07-7.77,18.49-9.1,22.56-9.57,2.59-.3,23.65-2.38,37.59,12.3,2.92,3.07,8.9,9.37,8.89,18.46-.01,8.06-4.75,13.97-6.15,15.72-3.06,3.82-6.23,5.72-11.62,8.89-7.29,4.28-13.7,6.56-17.77,8.2-11.15,4.48-11.85,2.55-27.34,7.52-12.59,4.04-17.41,7.02-21.19,10.25-3.35,2.87-8.56,7.45-8.2,12.99.24,3.66,2.92,5.86,7.52,10.25,0,0,.67.64,15.5,15.97,6.87,7.1,10.27,15.2,17.31,31.19.81,1.85,4.63,10.6,8.2,22.56,3.15,10.54,3.64,15.31,3.42,19.82-.29,6-.6,12.27-4.45,15.35-5.92,4.74-14.37-2.72-31.09-6.47-26.64-5.97-43.9,4.28-50.58-5.47-2.09-3.05-.13-3.67-2.73-12.3-3.42-11.34-9.42-18.95-12.18-22.38-4.56-5.66-3.73-2.37-17.21-15.9-5.19-5.21-8.89-9.34-15.72-12.3-2.07-.9-5.65-2.45-10.25-2.73-5.58-.34-9.24,1.41-23.24,8.89-14.44,7.71-21.66,11.57-23.92,12.99-5.49,3.44-7.62,5.13-12.3,6.84-7.31,2.67-9.28,1.16-14.68,3.98-1.65.86-7.98,4.4-11.29,11.75-1.71,3.79-1.86,6.91-2.05,10.94-.52,10.89,2.83,19.55,4.1,22.56,3.13,7.38,5.25,7.73,4.78,10.25-1.81,9.68-35.08,17.3-45.93,5.69v-.02Z"
          />
        </g>
        <path
          id="collarbone"
          class="Schlùsselbein_left2015_cls3"
          d="M460.14,37.58c3.49.23,5.78,4.43,8.2,8.89,2.02,3.72,5.94,10.9,4.78,20.51-.54,4.49-1.12,9.32-4.78,12.3-4.17,3.39-7.88.78-17.77,2.73-2.8.55-2.31.72-15.04,5.47-8.73,3.25-13.25,4.94-19.14,6.84-10.93,3.52-10.86,2.7-18.46,5.47-9.79,3.57-9.74,4.87-17.09,6.84-3.92,1.05-9.71,2.54-17.09,2.05-1.73-.12-1.81-.22-11.62-2.05-8.58-1.6-7.77-1.37-15.04-2.73-3.89-.73-8.57-1.63-17.77-3.42-12.29-2.38-14.62-2.85-18.46-2.05-2.04.43-1.63.61-10.25,4.1-6.96,2.82-10.43,4.22-14.35,5.47-9.46,3-10.53,1.88-17.09,4.78-4.57,2.03-4.72,2.87-9.57,4.78-2.99,1.18-4.54,1.5-12.3,3.42-9.02,2.23-13.55,3.37-15.04,4.1-5.7,2.83-4.94,4.6-11.62,8.2-2.4,1.29-5.73,2.47-12.3,4.78-3.62,1.27-7.25,2.55-12.3,4.1-12,3.69-12.89,3.05-20.51,5.47-14.71,4.67-13.44,7.69-27.34,11.62-5.28,1.49-8.39,1.88-12.3,4.78-5.48,4.06-4.46,7.06-9.57,10.94-3.56,2.7-8.3,3.62-17.77,5.47-7.03,1.37-10.54,2.05-12.99,1.37-5.83-1.63-8.89-6.7-14.35-15.72-3.91-6.46-2.08-5.34-8.89-18.46-1.73-3.32-3.22-6.04-4.1-10.25-1-4.76-.11-6.15-.68-10.25-1.02-7.31-4.5-7.74-6.84-15.04-.43-1.35-4.29-13.94,2.05-20.51,2.75-2.84,6.33-3.44,10.25-4.1,7.72-1.3,13.34.37,18.46,1.37,8.13,1.58,14,1.28,25.29.68,4.5-.24,10.18-.86,38.96-9.57,21.78-6.59,20.32-6.78,28.71-8.89,6.16-1.55,15.15-3.51,39.65-6.15,21.27-2.29,38.65-3.41,51.27-4.1,19.67-1.08,17.75-.56,55.37-2.05,3.45-.14,21.44-1,57.42-2.73,10.49-.5,20.94-1.78,31.44-2.05,9.67-.25,16.2-.05,25.29-2.73,7.02-2.07,11.13-3.29,15.72-6.84,4.78-3.7,6.35-7.05,9.57-6.84h-.01Z"
        />
        <g id="shoulder_with_upper_arm" data-name="shoulder with upper arm">
          <path
            id="shoulder_with_upper_arm_1"
            data-name="shoulder with upper arm 1"
            class="Schlùsselbein_left2015_cls2"
            d="M268.06,271.35c.22-5.14.5-12.71.68-21.87.34-16.5-.02-18.76,0-44.43.01-21.1.26-25.26-.68-34.86-1.08-11.02-1.62-16.54-3.42-21.87-2.93-8.7-6.47-12.92-6.15-21.19.11-2.85.29-7.44,3.42-11.62,4.78-6.39,14.29-8.92,21.19-6.15,3.54,1.42,5.83,4.61,10.25,10.94,7.27,10.38,6.91,13.76,11.62,19.14,4.14,4.72,8.91,6.79,18.46,10.94,10.45,4.54,18.95,6.39,30.76,8.89,5.89,1.25,14.05,2.8,23.92,4.1"
          />
          <path
            id="shoulder_with_upper_arm_2"
            data-name="shoulder with upper arm 2"
            class="Schlùsselbein_left2015_cls2"
            d="M503.2,250.85c-3.09-5.23-7.78-13.09-13.67-22.56-14.98-24.1-17.68-26.49-19.14-34.18-2.6-13.72,2.36-25.41,7.52-37.59,4.16-9.82,8.62-20.34,19.14-28.71,5.94-4.72,14.21-7.48,30.76-12.99,18.58-6.19,22.07-5.67,31.44-10.94,8.88-4.99,17.24-9.68,19.82-19.14,3.1-11.33-4.25-21.59-5.47-23.24-5.05-6.81-11.61-9.43-17.09-11.62-3.8-1.52-11.86-4.63-22.56-3.42-2.96.33-13.01,1.6-21.87,8.89-5.31,4.37-4.92,6.56-11.62,13.67-5.77,6.12-10.45,9.15-15.72,12.99-16.29,11.84-15.05,14.47-28.71,24.61-15.86,11.77-18.18,8.71-36.91,21.87-9.28,6.52-6.16,5.49-23.92,19.14-21.17,16.27-16.88,11.04-33.49,23.92-6.97,5.4-10.8,8.71-17.77,10.94-8.09,2.59-15.51,2.15-20.51,1.37"
          />
          <path
            id="shoulder_with_upper_arm_3"
            data-name="shoulder with upper arm 3"
            class="Schlùsselbein_left2015_cls2"
            d="M315.91,266.57c-4.09-8.75-5.93-16.45-6.84-21.87-1.47-8.84-2.4-14.89.68-21.19,2.63-5.38,6.84-8.2,10.94-10.94,8.68-5.81,12.7-4.39,24.61-9.57,7.02-3.06,5.38-3.44,19.14-10.94,5.95-3.24,11.26-5.81,21.87-10.94,20.62-9.97,20.67-9.38,26.66-12.99,5.4-3.25,3.02-2.33,20.51-14.35,8.69-5.98,11.27-7.58,17.77-12.3,7.55-5.49,11.33-8.23,15.04-11.62,8.35-7.62,8.55-10.27,14.35-14.35,3.85-2.7,9.51-5.58,17.77-6.84"
          />
          <path
            id="shoulder_with_upper_arm_4"
            data-name="shoulder with upper arm 4"
            class="Schlùsselbein_left2015_cls2"
            d="M538.06,254.95c1.03-5.2,2.03-12.8,1.37-21.87-1.17-16.12-6.89-27.22-15.04-43.06-6.21-12.07-12.88-25.03-23.24-34.18-2.25-1.99-5.98-4.94-10.94-10.25-7.95-8.52-7.07-10.24-10.94-12.3-4.22-2.25-11.01-1.5-24.61,0-7.59.84-9.77,1.52-12.99,0-5.28-2.49-6.4-7.63-10.25-7.52-2.99.09-3.31,3.22-6.84,4.78-5.75,2.56-10.13-3.45-19.14-4.78-7.24-1.07-16.74.99-22.56,7.52-6,6.73-5.61,15.43-5.47,18.46.11,2.39.64,11.08,7.52,18.46,1.19,1.28,9.05,9.44,20.51,8.89,6.74-.33,11.19-3.5,17.77-8.2,6.97-4.98,11.91-8.5,13.67-15.04,2.16-8.02-10.77-22.82-12.3-25.29"
          />
          <path
            id="shoulder_with_upper_arm_5"
            data-name="shoulder with upper arm 5"
            class="Schlùsselbein_left2015_cls2"
            d="M453.3,244.01c-.5-5.31-1.92-13.23-6.15-21.87-1.86-3.8-5.55-11.14-12.99-17.77-5-4.45-7.36-4.73-12.3-9.57-4.8-4.7-14.61-12.27-16.41-15.72"
          />
          <path
            id="shoulder_with_upper_arm_6"
            data-name="shoulder with upper arm 6"
            class="Schlùsselbein_left2015_cls1"
            d="M551.05,259.73c-155.85-95.7-4.1-222.16,45.8-129.19"
          />
          <path
            id="shoulder_with_upper_arm_7"
            data-name="shoulder with upper arm 7"
            class="Schlùsselbein_left2015_cls1"
            d="M560.62,125.49c-3.49-7.6,8.38-17.01,36.23,0"
          />
          <path
            id="shoulder_with_upper_arm_8"
            data-name="shoulder with upper arm 8"
            class="Schlùsselbein_left2015_cls1"
            d="M500.47,69.02c0,17.09,79.36-12.3,73.82,8.89"
          />
        </g>
        <g id="spine_with_ribs" data-name="spine with ribs">
          <path
            id="spine_with_ribs_1"
            data-name="spine with ribs 1"
            class="Schlùsselbein_left2015_cls1"
            d="M217.48,258.36c11.89-6.5,20.79-12.7,26.66-17.09,8.79-6.58,10-8.55,18.46-14.35,12.61-8.66,14.77-7.62,23.24-14.35,8.27-6.58,17.56-13.97,17.09-23.24-.35-6.88-5.93-11.93-11.62-17.09-5.26-4.76-10.11-7.19-17.77-10.94-11.55-5.66-13.72-5.04-21.19-8.89-9.67-4.97-10.02-8.05-21.87-17.09-5.3-4.05-16.45-12.44-31.44-18.46-14.92-5.99-15.91-2.5-30.76-8.89-14.8-6.36-15.47-10.53-25.97-12.3-11.5-1.94-22.75,1.02-26.66,2.05-11.68,3.08-16.19,7.29-22.56,4.78-4.56-1.8-7.76-6.13-8.89-10.25-2.55-9.31,5.01-17.73,7.52-20.51,1.31-1.45,1.98-1.92,18.46-11.62,7.26-4.27,10.95-6.41,13.67-6.15,4.77.45,5.86,4.31,14.35,10.25,5.53,3.87,9.9,6.93,15.04,9.57,10.04,5.15,12.01,3.04,23.24,7.52,6.78,2.71,8.04,4.27,23.92,13.67,7.83,4.64,8.51,4.84,19.14,10.94,43.71,25.07,42.1,26.76,58.78,34.18,10.44,4.64,16.52,6.4,28.03,12.99,7.96,4.56,14.32,8.26,21.19,15.04,5.2,5.13,13.41,13.24,17.09,26.66.96,3.49,3.38,12.76.68,23.92-.7,2.89-2.99,11.08-14.35,23.92-6.53,7.38-17.66,18.1-34.86,27.34"
          />
          <path
            id="spine_with_ribs_2"
            data-name="spine with ribs 2"
            class="Schlùsselbein_left2015_cls1"
            d="M130.67,254.95c5.43-.25,9.98-1.98,15.04-4.78,13.02-7.21,18.59-15.45,30.08-27.34,3.98-4.12.63-.36,23.24-21.19,31.48-29.01,26.7-25.93,31.44-29.39,7.06-5.17,11.47-7.48,14.35-13.67,1.01-2.17,3.59-8.58.68-21.87-2.7-12.36-8.47-23.02-17.77-34.86-9.67-12.32-13.26-13.32-30.76-30.76-2.9-2.89-12.18-12.86-30.76-32.81-8-8.6-24.75-26.61-40.33-30.08-2.31-.52-8.5-1.89-12.3,1.37-1.86,1.59-1.44,2.83-4.1,7.52-2.76,4.85-4,4.93-6.84,9.57-3.59,5.87-2.07,6.54-4.78,10.25-4.72,6.46-11.13,6.95-10.94,9.57.15,2.09,4.36,3.16,9.57,4.78,17.32,5.39,14.38,6.8,25.97,9.57,9.73,2.33,12.47,1.46,22.56,4.78,4.28,1.41,6.41,2.11,8.89,3.42,3.1,1.64,8.58,5.76,20.51,24.61,6.87,10.86,10.31,16.29,10.25,23.92,0,1.44-.15,4.31-4.78,16.41-4.9,12.79-7.36,19.19-10.94,24.61-6.12,9.26-13.24,13.85-24.61,21.19-18.19,11.74-27.28,17.6-38.28,20.51-3.22.85-9.82,2.35-12.3,7.52-1.92,4.01-.54,8.5,0,10.25,1.99,6.44,5.76,7.09,10.25,13.67,5.85,8.58,2.41,11.83,7.52,17.09,6.54,6.72,16.91,6.25,19.14,6.15h0Z"
          />
          <path
            id="spine_with_ribs_3"
            data-name="spine with ribs 3"
            class="Schlùsselbein_left2015_cls1"
            d="M240.03,271.35c-4.97-2.57-11.43-5.7-19.14-8.89-2.48-1.02-12.05-4.93-25.29-8.89-5.72-1.71-15.2-4.51-28.03-6.84-13.61-2.47-20.41-3.7-28.71-3.42-14.88.51-23.95,4.73-26.66.68-1.87-2.8,2-5.52.68-12.3-1.03-5.33-3.84-5.83-6.15-12.99-1.24-3.85-2.64-8.21-.68-10.94,2.03-2.82,6.74-2.65,12.99-2.05,16.15,1.55,28.96,4.7,32.13,5.47,13,3.17,9.23,1.9,23.92,5.47,11.21,2.72,20,4.86,31.44,8.2,10.34,3.02,18.06,5.66,33.49,10.94,11.67,3.99,17.51,5.98,21.19,7.52,8.76,3.65,16.44,7.73,31.44,15.72,6.97,3.71,12.65,6.82,16.41,8.89"
          />
          <path
            id="spine_with_ribs_4"
            data-name="spine with ribs 4"
            class="Schlùsselbein_left2015_cls1"
            d="M345.3,270.67c-4.29-3.57-11.28-9.1-20.51-15.04-10.86-6.99-13.7-7.4-27.34-15.72-14.79-9.02-14.29-10.27-24.61-15.72-10.67-5.64-11.25-4.32-24.61-10.94-16.06-7.95-14.94-9.7-24.61-12.99-11.2-3.8-24.17-5.34-25.29-5.47-7.45-.85-9.45-.42-14.35-2.05-7.99-2.66-8.44-5.73-16.41-8.89-5.33-2.11-6.99-1.48-18.46-3.42-4.6-.78-24.86-3.41-32.81-5.47"
          />
          <path
            id="spine_with_ribs_5"
            data-name="spine with ribs 5"
            class="Schlùsselbein_left2015_cls1"
            d="M375.38,263.15c-6.93-4.82-16.23-11.18-27.34-18.46-8.57-5.61-21.97-14.24-43.75-27.34-30.13-18.14-45.2-27.2-55.37-32.13-36.4-17.62-37.53-10.48-66.99-26.66-9.12-5.01-18.38-8.35-36.91-15.04-9.74-3.52-17.19-5.84-24.61-2.73-4.39,1.84-7.48,5.1-13.67,11.62-4.96,5.23-7.48,7.94-8.89,12.3-2.14,6.63-.41,12.73.68,15.72"
          />
          <path
            id="spine_with_ribs_6"
            data-name="spine with ribs 6"
            class="Schlùsselbein_left2015_cls1"
            d="M69.83,285.02c-1.9-3.02-4.43-7.42-6.84-12.99-6.56-15.17-7.36-27.85-7.52-31.44-.37-8.37-.56-12.55,1.37-17.77,4.95-13.4,17.83-19.04,21.19-20.51,5.99-2.62,12.27-5.37,19.14-2.73,7.14,2.74,11.24,9.9,12.3,15.72.52,2.87-.11,6.24-1.37,12.99-1.39,7.42-2.14,11.2-4.78,14.35-2.72,3.24-5.97,4.5-10.25,6.15-8.6,3.32-12.01,1.52-17.77,4.78-2.38,1.35-9.54,13.98-12.3,18.46"
          />
          <path
            id="spine_with_ribs_7"
            data-name="spine with ribs 7"
            class="Schlùsselbein_left2015_cls1"
            d="M.11,226.24c3.41.77,8.35,1.68,14.35,2.05,9.82.6,16.57,1.01,23.92-2.05,7.49-3.12,8.27-6.8,15.04-7.52,6.01-.64,9.13,1.88,10.94,0,1.89-1.97-.16-6.16-4.1-17.09-3-8.32-4.5-12.48-5.47-16.41-2.53-10.25-4.09-16.57-2.05-23.92.69-2.51,2.1-6.19,2.05-11.62-.02-2.52-.06-6.65-2.05-7.52-1.76-.77-3.54,1.67-8.2,4.78-3.27,2.18-3.02,1.4-16.41,7.52-6.76,3.09-7.3,3.51-9.57,4.1-5.25,1.37-9.88,1.12-12.99.68"
          />
          <path
            id="spine_with_ribs_8"
            data-name="spine with ribs 8"
            class="Schlùsselbein_left2015_cls1"
            d="M58.05,195.48c.89-1.91,6.63-5.83,11.09-8.89,3.33-2.27,6.43-2.99,8.2-3.42,16.09-3.83,21.42-2.9,23.92-6.84,1.35-2.12-.04-2.8,0-10.25.06-11.64,3.48-15.97.68-19.14-.87-.99-2.11-1.6-8.89-2.05-8.84-.59-13.24-1.16-17.77,0-7.5,1.93-18.55,9.71-21.55,12.9"
          />
          <path
            id="spine_with_ribs_9"
            data-name="spine with ribs 9"
            class="Schlùsselbein_left2015_cls1"
            d="M83.5,174.97c.89-2.94,1.95-7.44,2.05-12.99.27-14.33-6.07-24.85-8.89-29.39-4.68-7.56-10.06-12.42-12.99-15.04-3.57-3.19-6.58-3.3-8.89-6.84-3.09-4.75-3.67-11.85-.68-14.35,1.7-1.43,3.35-.36,7.52-1.37,2.73-.66,4.58-1.85,8.2-4.1,2.71-1.69,10.67-4.13,14.63-7.52"
          />
          <path
            id="spine_with_ribs_10"
            data-name="spine with ribs 10"
            class="Schlùsselbein_left2015_cls1"
            d="M49.13,148.52c-49.02,26.45,0,41.28,3.01,26.45"
          />
          <path
            id="spine_with_ribs_11"
            data-name="spine with ribs 11"
            class="Schlùsselbein_left2015_cls1"
            d="M4.21,87.48c6.38,2.37,11.13,2.1,14.35,1.37,7.17-1.63,9.76-6.26,17.09-6.15,1.79.03,5.77.08,8.2,2.73,2.64,2.87,1.25,6.36,1.37,12.99.12,6.99,1.9,12.41,5.47,23.24,3.35,10.19,4.72,10.28,4.1,12.99-1.54,6.72-10.83,10.04-27.34,15.72-7.56,2.6-13.96,4.35-18.46,5.47"
          />
          <path
            id="spine_with_ribs_12"
            data-name="spine with ribs 12"
            class="Schlùsselbein_left2015_cls1"
            d="M44.54,25.28c79.97-66.3,105.27,20.92-4.74,25.29"
          />
          <path
            id="spine_with_ribs_13"
            data-name="spine with ribs 13"
            class="Schlùsselbein_left2015_cls1"
            d="M3.53,81.33c3,.37,7.23.74,12.3.68,9.53-.11,14.29-.16,17.77-3.42,3.47-3.25,4.18-8.16,4.78-12.3.6-4.16-.07-5.2,0-9.57.2-12.71,6.09-16.41,3.42-23.24-.09-.24-2.23-5.5-6.84-6.84-3.67-1.06-6.31,1.12-11.62,2.73-3.46,1.05-8.45,1.99-15.04,1.37"
          />
          <path
            id="spine_with_ribs_14"
            data-name="spine with ribs 14"
            class="Schlùsselbein_left2015_cls1"
            d="M38.33,28.56c-53.94,25.63.66,17.63,1.47,17.63"
          />
          <line
            id="spine_with_ribs_15"
            data-name="spine with ribs 15"
            class="Schlùsselbein_left2015_cls1"
            x1="39.8"
            y1="7.46"
            x2="34.97"
            y2="26.64"
          />
          <path
            id="spine_with_ribs_16"
            data-name="spine with ribs 16"
            class="Schlùsselbein_left2015_cls1"
            d="M35.65,82.69c-24.11,25.59-19.9,37.54,11.03,25.97"
          />
          <path
            id="spine_with_ribs_17"
            data-name="spine with ribs 17"
            class="Schlùsselbein_left2015_cls1"
            d="M49.13,69.02c12.79-13.29,55.84-27.58,61.03-8.89"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="collarbone_overlay"
          data-name="collarbone overlay"
          class="clavicula2015Fill"
          style={{
            fill: props.colors.clavicula2015Fill,
            stroke: props.colors.clavicula2015Fill,
          }}
          d="M460.14,37.58c3.49.23,5.78,4.43,8.2,8.89,2.02,3.72,5.94,10.9,4.78,20.51-.54,4.49-1.12,9.32-4.78,12.3-4.17,3.39-7.88.78-17.77,2.73-2.8.55-2.31.72-15.04,5.47-8.73,3.25-13.25,4.94-19.14,6.84-10.93,3.52-10.86,2.7-18.46,5.47-9.79,3.57-9.74,4.87-17.09,6.84-3.92,1.05-9.71,2.54-17.09,2.05-1.73-.12-1.81-.22-11.62-2.05-8.58-1.6-7.77-1.37-15.04-2.73-3.89-.73-8.57-1.63-17.77-3.42-12.29-2.38-14.62-2.85-18.46-2.05-2.04.43-1.63.61-10.25,4.1-6.96,2.82-10.43,4.22-14.35,5.47-9.46,3-10.53,1.88-17.09,4.78-4.57,2.03-4.72,2.87-9.57,4.78-2.99,1.18-4.54,1.5-12.3,3.42-9.02,2.23-13.55,3.37-15.04,4.1-5.7,2.83-4.94,4.6-11.62,8.2-2.4,1.29-5.73,2.47-12.3,4.78-3.62,1.27-7.25,2.55-12.3,4.1-12,3.69-12.89,3.05-20.51,5.47-14.71,4.67-13.44,7.69-27.34,11.62-5.28,1.49-8.39,1.88-12.3,4.78-5.48,4.06-4.46,7.06-9.57,10.94-3.56,2.7-8.3,3.62-17.77,5.47-7.03,1.37-10.54,2.05-12.99,1.37-5.83-1.63-8.89-6.7-14.35-15.72-3.91-6.46-2.08-5.34-8.89-18.46-1.73-3.32-3.22-6.04-4.1-10.25-1-4.76-.11-6.15-.68-10.25-1.02-7.31-4.5-7.74-6.84-15.04-.43-1.35-4.29-13.94,2.05-20.51,2.75-2.84,6.33-3.44,10.25-4.1,7.72-1.3,13.34.37,18.46,1.37,8.13,1.58,14,1.28,25.29.68,4.5-.24,10.18-.86,38.96-9.57,21.78-6.59,20.32-6.78,28.71-8.89,6.16-1.55,15.15-3.51,39.65-6.15,21.27-2.29,38.65-3.41,51.27-4.1,19.67-1.08,17.75-.56,55.37-2.05,3.45-.14,21.44-1,57.42-2.73,10.49-.5,20.94-1.78,31.44-2.05,9.67-.25,16.2-.05,25.29-2.73,7.02-2.07,11.13-3.29,15.72-6.84,4.78-3.7,6.35-7.05,9.57-6.84h-.01Z"
        />
        <path
          id="AC_joint_overlay"
          data-name="AC joint overlay"
          class="acjoint2015Fill"
          style={{
            fill: props.colors.acjoint2015Fill,
            stroke: props.colors.acjoint2015Fill,
          }}
          d="M462.65,37.57c24.76-2.75,45.54-.25,60.61,9.8-10.31,5.39-18.63,12.12-22.79,21.65-5.25,5.61-13.22,11.6-21.89,17.69-5.62-.82-8.75-3.5-10.23-7.43,11.17-5.82,7.98-33.31-5.69-41.71Z"
        />
        <path
          id="SC_joint_overlay"
          data-name="SC joint overlay"
          class="scjoint2015Fill"
          style={{
            fill: props.colors.scjoint2015Fill,
            stroke: props.colors.scjoint2015Fill,
          }}
          d="M52.75,91.58c-2.66-2.72-5.43-3.03-8.28-1.3-3.01,1.84-4.69,5.26-4.65,8.79.2,20.93,2,40.75,9.31,56.76,5.27,12.25,10.26,23.85,14.8,34.39,11.83,2.7,17.38-.54,18.29-8.15-5.93-6.67-11.65-16.34-17.15-28.97-5.34-4.27-8.19-11.77-7.53-25.98-11.35-17.57-10.43-27.95-4.79-35.55Z"
        />
      </g>
    </svg>
  );
}
