//functional
import { FormGroup, Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  incraseXmStatsShouldSaveCount,
  setXmStatsFilterObj,
  setXmStatsSelectedModality,
} from "../../../Redux/actions";
import moment from "moment/moment";
//ui
import ExamsListModal from "../../ExamsListModal/ExamsListModal";
import BodyPartModal from "../../BodyPartModal/BodyPartModal";
import { useSelector } from "react-redux";

const modalities = ["XRay", "CT", "MG"];
const objPositions = ["AP", "PA", "LAT", "OBL", "AX"];
const ctObjPositions = ["Axial", "Sagital", "Coronal"];

export default function FilteringBox() {
  const { t } = useTranslation(["examStatistics", "common"]);
  const dispatch = useDispatch();

  const changesMade = useSelector((state) => state.xmStats.changesMade);

  const [bodyPartModalShown, setBodyPartModalShown] = useState(false);
  const [examListModalShown, setExamListModalShown] = useState(false);
  // region formData
  const [selectedHarmonizerExamianation, setSelectedHarmonizerExamination] =
    useState(undefined);
  const [selectedModality, setSelectedModality] = useState("XRay");
  const [selectedObjPos, setSelectedObjPos] = useState("AP");
  const [examsFrom, setExamsFrom] = useState(
    moment()
      .set("month", moment().month() - 1)
      .format("YYYY-MM-DD")
  );
  const [examsTo, setExamsTo] = useState(moment().format("YYYY-MM-DD"));
  const [serieDescFilter, setSerieDescFilter] = useState("");
  const [protocolDescFilter, setProtocolDescFilter] = useState("");
  const [selectedBodyPart, setSelectedBodyPart] = useState([]);
  // endregion formData

  const selectBodyPart = (bpart) => {
    let idx = selectedBodyPart.findIndex((b) => b.id === bpart.id);
    if (idx === -1) {
      setSelectedBodyPart([...selectedBodyPart, bpart]);
    } else {
      let bpartsCopy = [...selectedBodyPart];
      bpartsCopy.splice(idx, 1);
      setSelectedBodyPart(bpartsCopy);
    }
  };

  const updateFilter = () => {
    let filter = {};
    let filtersToApply = [];

    let modalityFilter = {
      model: "Study",
      field: "modality",
      op: undefined,
      value: undefined,
    };
    if (selectedModality === "XRay") {
      modalityFilter.op = "in";
      modalityFilter.value = ["CR", "DX"];
    } else {
      modalityFilter.op = "==";
      modalityFilter.value = selectedModality;
    }
    filtersToApply.push(modalityFilter);

    let dateRangeFilter = undefined;
    let exmsFromFilter = undefined;
    let exmsToFilter = undefined;
    if (examsFrom !== "") {
      exmsFromFilter = {
        model: "Study",
        field: "studydate",
        op: ">=",
        value: examsFrom,
      };
    }
    if (examsTo !== "") {
      exmsToFilter = {
        model: "Study",
        field: "studydate",
        op: "<=",
        value: examsTo,
      };
    }

    if (exmsFromFilter && exmsToFilter) {
      dateRangeFilter = {
        and: [exmsFromFilter, exmsToFilter],
      };
      filtersToApply.push(dateRangeFilter);
    }
    if (exmsFromFilter && !exmsToFilter) {
      dateRangeFilter = exmsFromFilter;
      filtersToApply.push(dateRangeFilter);
    }
    if (!exmsFromFilter && exmsToFilter) {
      dateRangeFilter = exmsToFilter;
      filtersToApply.push(dateRangeFilter);
    }

    if (serieDescFilter !== "") {
      filtersToApply.push({
        model: "Serie",
        field: "seriesdescription",
        op: "like",
        value: `%${serieDescFilter}%`,
      });
    }

    if (protocolDescFilter !== "") {
      filtersToApply.push({
        or: [
          {
            model: "Serie",
            field: "protocolname",
            op: "like",
            value: `%${protocolDescFilter}%`,
          },
          {
            model: "MAS_KV",
            field: "diffprotname",
            op: "like",
            value: `%${protocolDescFilter}%`,
          },
        ],
      });
    }

    if (selectedHarmonizerExamianation) {
      filtersToApply.push({
        model: "MAS_KV",
        field: "examid",
        op: "eq",
        value: selectedHarmonizerExamianation.id,
      });
    }

    if (selectedBodyPart.length) {
      let bodypartFilter = {
        model: "Exams",
        field: "anatomy_id",
        op: "in",
        value: selectedBodyPart.map((bp) => bp.id),
      };
      filtersToApply.push(bodypartFilter);
    }

    if (filtersToApply.length === 1) {
      filter = filtersToApply[0];
    } else {
      filter = {
        and: filtersToApply,
      };
    }
    dispatch(setXmStatsFilterObj(filter));
  };

  useEffect(updateFilter, [
    selectedModality,
    examsFrom,
    examsTo,
    serieDescFilter,
    selectedBodyPart,
    protocolDescFilter,
    selectedHarmonizerExamianation,
  ]);

  useEffect(updateFilter, []);

  return (
    <>
      <ExamsListModal
        shown={examListModalShown}
        hideHandler={() => setExamListModalShown(false)}
        selectHandler={setSelectedHarmonizerExamination}
        selected={selectedHarmonizerExamianation}
      />
      <BodyPartModal
        shown={bodyPartModalShown}
        close={() => setBodyPartModalShown(false)}
        selectHandler={(bpart) => selectBodyPart(bpart)}
        selectedBparts={selectedBodyPart}
      />
      <div className="stats-search-box">
        <div className="modalities-btns">
          {modalities.map((modality, key) => (
            <div
              key={`modalityselectedkey${key}`}
              className={`modality border font-weight-bold rounded p-2 text-center modality-btn ${
                selectedModality === modality && "selected"
              }`} // style={{ width: "60px" }}
              onClick={() => {
                if (!changesMade) {
                  setSelectedModality(modality);
                  dispatch(setXmStatsSelectedModality(modality));
                } else dispatch(incraseXmStatsShouldSaveCount());
              }}
            >
              {modality}
            </div>
          ))}
        </div>

        <div className="search-form">
          <FormGroup className="mb-1">
            <Form.Control
              value={examsFrom}
              type="date"
              onChange={(e) => {
                !changesMade
                  ? setExamsFrom(e.target.value)
                  : dispatch(incraseXmStatsShouldSaveCount());
              }}
            />
            <Form.Text className="xsmall input-desc ml-1">
              {t("filters.examsFromFilter")}
            </Form.Text>
          </FormGroup>
          <FormGroup className="mb-1">
            <Form.Control
              value={examsTo}
              type="date"
              placeholder="i.e. Hand"
              onChange={(e) => {
                !changesMade
                  ? setExamsTo(e.target.value)
                  : dispatch(incraseXmStatsShouldSaveCount());
              }}
            />
            <Form.Text className="xsmall input-desc ml-1">
              {t("filters.examsToFilter")}
            </Form.Text>
          </FormGroup>
          <FormGroup className="mb-1">
            <Form.Control
              value={serieDescFilter}
              type="text"
              onChange={(e) => {
                !changesMade
                  ? setSerieDescFilter(e.target.value)
                  : dispatch(incraseXmStatsShouldSaveCount());
              }}
              placeholder={t("filters.seriesDescFilterPlaceholder")}
            />
            <Form.Text className="xsmall input-desc ml-1">
              {t("filters.seriesDescFilter")}
            </Form.Text>
          </FormGroup>
          <FormGroup className="mb-0">
            <Form.Control
              value={protocolDescFilter}
              type="text"
              onChange={(e) => {
                !changesMade
                  ? setProtocolDescFilter(e.target.value)
                  : dispatch(incraseXmStatsShouldSaveCount());
              }}
              placeholder={t("filters.protocolFilterPlaceholder")}
            />
            <Form.Text className="xsmall input-desc ml-1">
              {t("filters.protocolFilter")}
            </Form.Text>
          </FormGroup>

          <div className="d-flex flex-column">
            <div className="d-flex">
              <Button
                className="text-left w-100"
                variant="light"
                onClick={() =>
                  !changesMade
                    ? setExamListModalShown(true)
                    : dispatch(incraseXmStatsShouldSaveCount())
                }
              >
                {selectedHarmonizerExamianation
                  ? selectedHarmonizerExamianation.designationde
                  : t("filters.noExamSelected")}
              </Button>
              {selectedHarmonizerExamianation && (
                <Button
                  onClick={() =>
                    !changesMade
                      ? setSelectedHarmonizerExamination(undefined)
                      : dispatch(incraseXmStatsShouldSaveCount())
                  }
                  size="sm"
                >
                  <i className="fas fa-times fa-sm" />
                </Button>
              )}
            </div>
            <div className="xsmall input-desc ml-1">
              {t("filters.examSelection")}
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex">
              <Button
                className="text-left w-100"
                variant="light"
                onClick={() =>
                  !changesMade
                    ? setBodyPartModalShown(true)
                    : dispatch(incraseXmStatsShouldSaveCount())
                }
              >
                {selectedBodyPart.length === 0 &&
                  t("filters.noBodyPartSelected")}
                {selectedBodyPart.length === 1 &&
                  selectedBodyPart[0]?.designation_de}
                {selectedBodyPart.length > 1 &&
                  `${selectedBodyPart[0]?.designation_de} + ${
                    selectedBodyPart.length - 1
                  }`}
              </Button>
              {selectedBodyPart.length > 0 && (
                <Button
                  onClick={() =>
                    !changesMade
                      ? setSelectedBodyPart([])
                      : dispatch(incraseXmStatsShouldSaveCount())
                  }
                  size="sm"
                >
                  <i className="fas fa-times fa-sm" />
                </Button>
              )}
            </div>
            <div className="xsmall input-desc ml-1">
              {t("filters.bodyPartSelection")}
            </div>
          </div>
        </div>

        <div className="body-part-box mt-auto">
          <div className="mt-auto border-top">
            <div className="text-center py-2 font-weight-bold text-uppercase">
              {t("filters.objectPositionFilter")}
            </div>
            <div className=" d-flex justify-content-between border-top wrapping-flex ">
              {selectedModality !== "CT"
                ? objPositions.map((position, key) => (
                    <div
                      key={`objpositionkey${key}`}
                      className={`flex-item ${
                        key < objPositions.length - 1 && "border-right"
                      } py-2 text-center obj-pos-btn ${
                        selectedObjPos === position && "selected"
                      }`}
                      onClick={() => {
                        !changesMade
                          ? setSelectedObjPos(position)
                          : dispatch(incraseXmStatsShouldSaveCount());
                      }}
                    >
                      {position}
                    </div>
                  ))
                : ctObjPositions.map((position, key) => (
                    <div
                      key={`objpositionkey${key}`}
                      className={`flex-item ${
                        key < objPositions.length - 1 && "border-right"
                      } py-2 text-center obj-pos-btn ${
                        selectedObjPos === position && "selected"
                      }`}
                      onClick={() => {
                        !changesMade
                          ? setSelectedObjPos(position)
                          : dispatch(incraseXmStatsShouldSaveCount());
                      }}
                    >
                      {position}
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
