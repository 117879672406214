import style from "./style.scss";

function LinkeGrosszehe1ZeheDplOblSitzend1405(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 137.82 482.65">
      <g id="big_toe" data-name="big toe">
        <path
          id="big_toe_1"
          data-name="big toe 1"
          class="Großzehe_Zehe1_dpl_obl_links1405_cls5"
          style={{
            fill: props.colors.Großzehe_Zehe1_dpl_obl_links1405_cls5,
            stroke: props.colors.Großzehe_Zehe1_dpl_obl_links1405_cls5,
          }}
          d="M84.63,109.06c11.7,1.29,13.67-6.87,8.53-21.94-9.43-14.34-13.74-28.2-11.58-41.45,2.96-16.78,3.72-31.42-12.19-29.87-4.27,4.52-7.61,10.53-9.14,19.5-3.55,17.72-7.77,34.52-13.41,49.37-3.56,9.37-1.21,17.03,7.98,22.73,2.16,1.34,4.82,1.81,7.24,1.04,6.29-2,14.01-1.45,22.58.61h0Z"
        />
        <path
          id="big_toe_2"
          data-name="big toe 2"
          class="Großzehe_Zehe1_dpl_obl_links1405_cls5"
          style={{
            fill: props.colors.Großzehe_Zehe1_dpl_obl_links1405_cls5,
            stroke: props.colors.Großzehe_Zehe1_dpl_obl_links1405_cls5,
          }}
          d="M101.09,217.56c-16.5-9.28-34.56-6.82-53.63,3.21-5.37,2.82-12.13.17-13.73-5.68-2.84-10.34,0-17.9,8.85-22.52,7.4-8.66,10.97-16.71,12.19-24.38,4.89-17.09,2.62-28.16-4.27-35.35-8.34-11.21-6.48-16.26,5.49-15.24,15.14.96,25.25-1.14,31.69-5.49,11.43-3.23,15.47,1.07,12.8,12.19-.47,26.48,2.17,52.9,8.53,79.24,8.38,15.84,5.53,20.3-7.92,14.02h0Z"
        />
        <path
          id="big_toe_3"
          data-name="big toe 3"
          class="Großzehe_Zehe1_dpl_obl_links1405_cls5"
          style={{
            fill: props.colors.Großzehe_Zehe1_dpl_obl_links1405_cls5,
            stroke: props.colors.Großzehe_Zehe1_dpl_obl_links1405_cls5,
          }}
          d="M114.5,266.93c13.32-17.62,14.24-24.43-1.83-30.48-5.71-14.29-19.68-19.77-39.62-18.89-16.62,4.1-29.55,16.1-40.23,32.91,6.69,6.87,8.94,19.45,6.7,37.79-4.47,15.85-1.63,25.19,8.53,28.04,4.66,33.32-1.06,55.25-11.58,71.92-18.25,18.55-32.33,37.17-25.6,56.08-16.8,34.76-11.61,41.79,4.27,35.35,18.52-5.19,34.31-5.94,46.32-.61,17.68,7.19,27,1.25,32.3-10.97,34.47,4.37,39.06-12.77,29.87-35.96-19.34-31.29-17.44-80.51-10.36-134.09l1.22-31.09h.01Z"
        />
        <path
          id="outline"
          class="Großzehe_Zehe1_dpl_obl_links1405_cls4"
          style={{
            fill: props.colors.Großzehe_Zehe1_dpl_obl_links1405_cls4,
            stroke: props.colors.Großzehe_Zehe1_dpl_obl_links1405_cls4,
          }}
          d="M136.68,221.56c2.01-35.16-.62-70.06-9.28-104.63,1.79-35.14-1.24-64.64-10.54-86.84-3.58-8.56-9.2-16.18-16.63-21.74-15.02-11.23-32.28-11.41-53.1,5.98-1.06.89-2.08,1.83-3.04,2.82-25.43,26.05-34.37,60.22-25.54,103.15-5.22,28.52-3.78,62.99,0,99.56"
        />
        <ellipse
          id="big_toe_4"
          data-name="big toe 4"
          class="Großzehe_Zehe1_dpl_obl_links1405_cls5"
          style={{
            fill: props.colors.Großzehe_Zehe1_dpl_obl_links1405_cls5,
            stroke: props.colors.Großzehe_Zehe1_dpl_obl_links1405_cls5,
          }}
          cx="26.19"
          cy="275.62"
          rx="17.3"
          ry="28.69"
        />
      </g>
      <g id="overlay">
        <g id="big_toe-2" data-name="big toe">
          <path
            id="big_toe_overlay_1"
            data-name="big toe overlay 1"
            class="Bigtoe1405Fill"
            style={{
              fill: props.colors.Bigtoe1405Fill,
              stroke: props.colors.Bigtoe1405Fill,
            }}
            d="M84.63,109.06c11.7,1.29,13.67-6.87,8.53-21.94-9.43-14.34-13.74-28.2-11.58-41.45,2.96-16.78,3.72-31.42-12.19-29.87-4.27,4.52-7.61,10.53-9.14,19.5-3.55,17.72-7.77,34.52-13.41,49.37-3.56,9.37-1.21,17.03,7.98,22.73,2.16,1.34,4.82,1.81,7.24,1.04,6.29-2,14.01-1.45,22.58.61h0Z"
          />
          <path
            id="big_toe_overlay_2"
            data-name="big toe overlay 2"
            class="Bigtoe1405Fill"
            style={{
              fill: props.colors.Bigtoe1405Fill,
              stroke: props.colors.Bigtoe1405Fill,
            }}
            d="M101.09,217.56c-16.5-9.28-34.56-6.82-53.63,3.21-5.37,2.82-12.13.17-13.73-5.68-2.84-10.34,0-17.9,8.85-22.52,7.4-8.66,10.97-16.71,12.19-24.38,4.89-17.09,2.62-28.16-4.27-35.35-8.34-11.21-6.48-16.26,5.49-15.24,15.14.96,25.25-1.14,31.69-5.49,11.43-3.23,15.47,1.07,12.8,12.19-.47,26.48,2.17,52.9,8.53,79.24,8.38,15.84,5.53,20.3-7.92,14.02h0Z"
          />
        </g>
        <path
          id="endphalanx_overlay"
          data-name="endphalanx overlay"
          class="Endphalanx1405Fill"
          style={{
            fill: props.colors.Endphalanx1405Fill,
            stroke: props.colors.Endphalanx1405Fill,
          }}
          d="M84.63,109.06c11.7,1.29,13.67-6.87,8.53-21.94-9.43-14.34-13.74-28.2-11.58-41.45,2.96-16.78,3.72-31.42-12.19-29.87-4.27,4.52-7.61,10.53-9.14,19.5-3.55,17.72-7.77,34.52-13.41,49.37-3.56,9.37-1.21,17.03,7.98,22.73,2.16,1.34,4.82,1.81,7.24,1.04,6.29-2,14.01-1.45,22.58.61h0Z"
        />
        <path
          id="basisphalanx_overlay"
          data-name="basisphalanx overlay"
          class="Grundphalanx1405Fill"
          style={{
            fill: props.colors.Grundphalanx1405Fill,
            stroke: props.colors.Grundphalanx1405Fill,
          }}
          d="M101.09,217.56c-16.5-9.28-34.56-6.82-53.63,3.21-5.37,2.82-12.13.17-13.73-5.68-2.84-10.34,0-17.9,8.85-22.52,7.4-8.66,10.97-16.71,12.19-24.38,4.89-17.09,2.62-28.16-4.27-35.35-8.34-11.21-6.48-16.26,5.49-15.24,15.14.96,25.25-1.14,31.69-5.49,11.43-3.23,15.47,1.07,12.8,12.19-.47,26.48,2.17,52.9,8.53,79.24,8.38,15.84,5.53,20.3-7.92,14.02h0Z"
        />
      </g>
    </svg>
  );
}

export default LinkeGrosszehe1ZeheDplOblSitzend1405;
