import React from "react";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../Redux/mappers/pricelist/pricelistMappers";
import { useTranslation } from "react-i18next";
import { savePrices } from "../../Utilities/PriceLists/PriceListsUtils";
import { NotificationManager } from "react-notifications";

function NewPricesSummary(props) {
  const { t, i18n } = useTranslation(["priceList", "common"]);
  const buildPriceData = (data) => {
    let simpleData = { price_changes: [], affected_ids: [] };
    let id = 0;
    let price = 0;
    for (let key in data) {
      id = parseInt(key);
      price = parseFloat(data[key]);
      simpleData.price_changes.push({ id, price });
      simpleData.affected_ids.push(key);
    }
    return simpleData;
  };

  const save = (data) => {
    savePrices(props.keycloak, Object.keys(props.pricelists.facility), data)
      .then(async (response) => {
        if (response.ok)
          NotificationManager.success(t("summaryView.changeSuccesfully"));
        else NotificationManager.error(response.statusText);
      })
      .catch((error) => {
        NotificationManager.error(
          t("summaryView.changeError") + error + "." + t("summaryView.tryAgain")
        );
      });
  };

  return (
    <Container>
      <h2> {t("summaryView.title")}</h2>
      {Object.entries(props.pricelists.facility).map(([facId, fac]) => (
        <div>
          <h5>
            {t("summaryView.priceFor")} {fac.name}
          </h5>
          <table>
            <thead>
              <tr>
                <td>{t("common:name")}</td>
                <td>{t("summaryView.oldPrice")}</td>
                <td>{t("summaryView.newPrice")}</td>
              </tr>
            </thead>
            <tbody>
              {Object.entries(props.pricelists.newPrices).map(([prId, val]) => {
                const idx = props.pricelists.priceInfo[
                  prId
                ].facilities.findIndex((element) => {
                  return element === parseInt(facId);
                });
                return (
                  idx >= 0 && (
                    <tr>
                      <td>{props.pricelists.priceInfo[prId].name}</td>
                      <td>{props.pricelists.priceInfo[prId].price[idx]}</td>
                      <td>{val}</td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </table>
        </div>
      ))}
      <Button
        onClick={() => {
          let data = buildPriceData(props.pricelists.newPrices);
          save(data);
        }}
      >
        {t("common:buttons.save")}
      </Button>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPricesSummary);
