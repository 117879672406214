import style from "./style.scss";

export default function LinkeHufteAdduktionVdOblLiegend2410(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 270.3 288.8"
    >
      <g id="BG">
        <path
          id="BG4"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls4"
          d="M0,84.7l16.5,14.7c2-5,6.3-16.7,10.3-21.1,3-3.3,3.9-2.9,11-8,5.4-3.9,11.4-8.3,17-15,3.3-3.9,5.9-7.1,8-12,4.3-10.2,1-15.7,6-21,2.8-3.1,10.4-8.2,15-9.2,3.1-.8,4.9.2,7.4,1.9,3.6,2.4,7.3,2.6,11.5,2.3L90.2,0"
        />
        <path
          id="BG3"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls4"
          d="M180.5,71.3s0,0,.2.1c.1,0,.2,0,.3,0-64.8-1.6-56.1,33.4-50.4,66.9-1.9,11-2.8,12.6-8,25-5.5,13.2-16.7,20.2-26,26-10.9,6.8-24.6,15.3-39,11-6-1.8-11-5.4-14.2-8.2,0-.3.1-.6.2-.9,3-24.1,2.6-48.3,2.2-72.6,6.1-2.8,5.7-2.5,13.6-9.7,3.9-4.9,7.5-8.2,10-10.4,2.2-1.9,3.8-3,7.5-6.3,5.2-4.6,6.3-6.2,7.2-7.8.5-1,1.9-3.9,1.9-7.7,0-3.4-1.2-3.5-1.2-6.7,0-4,1.9-4.4,2.2-9.4.1-1.9.2-3.7-.6-5.8-1.1-2.4-2.5-2.8-3.5-4.8-.7-1.5-1.3-3.8,0-7.7,1.3-1.9,3.3-4,6-4.4,4.9-.6,7.1,5.3,11.6,4.7,3.9-.5,6.7-5.4,9-9.5,1.2-2.1,2-4.1,2.5-5.4,21.8-4.3,44.7-2.3,66.7-.6,5.7.4,11.5.9,17.2,1.3-.8,1.1-1.6,2.3-2.4,3.7-5.2,7.9-6.3,11.3-6.3,13.5,0,.2,0,.3.3,3.2,1.3,11.9,1.4,13,1,14.4-.5,2-2.2,5.2-8,8Z"
        />
        <path
          id="BG2"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls4"
          d="M250.8,163.7l.5-46.1,11.6-.3c2.4,1.1,4.5,2.8,5.9,4.9,1.8,2.8,1.7,5.3-.4,20.5-2.2,15.7-2.8,18-3,23-.2,4.9-.9,10.3,1.2,18.6,0,.2-.2.3-.3.1l-15.5-20.7h0Z"
        />
        <path
          id="BG1"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls4"
          d="M132,278.2c1.2-15.9,7-49,10.5-68.4,1.6-8.7,9.1-15,18-15,9.5,0,17.8-6.2,20.5-15.3l.5-1.7c5.3-23.8-3.1-37.1-34.2-33,0,0-8.6-1.7-14.5-5.1-7.1-4.8-11.2-13-13.5-19.2-2.7-7.4-6.8-18.6-2-31,5.1-13.3,17-19.1,21-21,3.4-1.6,13.3-6.2,26-4,8,1.4,13.8,5,16.9,7.2.8,1.7,8.6,0,9,5,1.2,13.3,20.8,31,30.2,29,2.6-.6,5.8,3.3,13,2,23.5,2.9,28,6,28,6,8.4,5.8-9.9,3.3-9.2,6,2,6.9,0,8,1,21,1.1,14.3,3.8,15.7,2,22-1.8,6.1,16.6,17.6,10.2,28-3.5,5.7-5.4,10.3-9,19-4.3,10.4-40.8,53.9-48.2,79.2"
        />
      </g>
      <g id="Underlay">
        <path
          id="Hipjoint"
          class="Hipjoint2410Fill"
          style={{
            fill: props.colors.Hipjoint2410Fill,
            stroke: props.colors.Hipjoint2410Fill,
          }}
          d="M188.3,63.6c0-3.5-13.2-6-15.7-6.4-4.1-.7-9-1.6-15.7-1.3-10.7.5-19.3,3.8-25,6.7-4.1,2.1-10,5.2-15.7,11.5-1.9,2.2-6,6.8-8.7,14.1-2.9,8-2.5,14.7-2.2,18.9.6,8.8,3.2,15.2,4.8,18.9,3.4,8.1,7.3,12.7,10.3,16,5,5.8,6.7,5.6,7.4,5.4,2.4-.7,3.9-5.5,2.9-9.3-1-3.7-3.9-4.3-7.1-8-2.5-2.9-3.6-6.6-5.8-14.1-2.8-9.8-4.3-14.6-3.2-20.5.9-5.3,3.2-9,4.8-11.5,1-1.7,4.7-7.3,11.5-12.2,1.8-1.3,8.8-6,19.2-7.7,7.8-1.2,13.6-.1,17.6.6,6.5,1.2,8.3,2.8,9,3.5,1.9,1.9,2.4,3.9,3.8,3.8,1.2,0,1.6-1.4,4.2-4.5,2.7-3.2,3.5-3.3,3.5-4.2Z"
        />
      </g>
      <g id="Femur">
        <path
          id="Femur5"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          d="M262.6,116.7c2.4,1.1,4.5,2.8,5.9,4.9,1.8,2.8,1.7,5.3-.4,20.5-2.2,15.7-2.8,18-3,23-.2,4.9-.9,10.3,1.2,18.6,0,.2-.2.3-.3.1"
        />
        <path
          id="Femur4"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls2"
          d="M130.8,138.3c1.2-5.9,2.5-11.9,3.7-17.5,11-11,28-18,32.3-34.7,3.7-6.8-.1-4,12.8-13.2,1-1-.3-.6.9-1.6"
        />
        <path
          id="Femur3"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          d="M131.6,277.7c1.2-15.9,7-49,10.5-68.4,1.6-8.7,9.1-15,18-15,9.5,0,17.8-6.2,20.5-15.3l.5-1.7c5.3-23.8-3.1-37.1-34.2-33,0,0-8.6-1.7-14.5-5.1-7.1-4.8-11.2-13-13.5-19.2-2.7-7.4-6.8-18.6-2-31,5.1-13.3,17-19.1,21-21,3.4-1.6,13.3-6.2,26-4,8,1.4,13.8,5,16.9,7.2.8,1.7,8.6,0,9,5,1.2,13.3,20.8,31,30.2,29,2.6-.6,5.8,3.3,13,2,23.5,2.9,28,6,28,6,8.4,5.8-9.9,3.3-9.2,6,2,6.9,0,8,1,21,1.1,14.3,3.8,15.7,2,22-1.8,6.1,16.6,17.6,10.2,28-3.5,5.7-5.4,10.3-9,19-4.3,10.4-40.8,53.9-48.2,79.2"
        />
        <line
          id="Femur2"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          x1="225"
          y1="145.7"
          x2="206"
          y2="157.1"
        />
        <path
          id="Femur1"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          d="M195,180.1c5.4-9,8.9-17.1,11-23,3.5-9.7,5.2-14.5,5-21-.3-8.2-3.3-10.4-2-17,1.5-7.9,7.7-14.7,10.7-13.8,3.6,1.1,0,12.9,6.3,16.8,3.1,1.9,7.1,1,11,0,8.2-2,9.1-6,15-7,3.4-.6,7.2,0,10.5,1.6"
        />
      </g>
      <g id="Hip">
        <path
          id="Hip8"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          d="M48.5,117.8c11-6,15-14.7,28-25,8.5-6.7,14-16,19-26s11-23,14.9-34.7"
        />
        <path
          id="Hip7"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          d="M194.7,30c-4.1,5.6-8.1,11.6-7.1,19.6,1,5,5.7,13.9-6.7,21.7"
        />
        <path
          id="Hip6"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          d="M109.5,33.3h0c-4.7,8.3-8.2,9.9-10.7,9.8-4.2-.2-5.9-5.4-10-5-3.4.3-6.7,4.2-7,8-.4,4.6,3.9,5.8,5,12,.7,4-.3,7.1-2,12-1.1,3.2,3.6,6.7-.3,11.7,7.3,0,18.8-29.8,25-48.5h0Z"
        />
        <path
          id="Hip5"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          d="M16.5,99.4c2-5,6.3-16.7,10.3-21.1,3-3.3,3.9-2.9,11-8,5.4-3.9,11.4-8.3,17-15,3.3-3.9,5.9-7.1,8-12,4.3-10.2,1-15.7,6-21,2.8-3.1,10.4-8.2,15-9.2,3.1-.8,4.9.2,7.4,1.9,3.6,2.4,7.3,2.6,11.5,2.3"
        />
        <path
          id="Hip4"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          d="M84.6,81.8c0,7.7,2.9,8.3,7.3,8.3"
        />
        <path
          id="Hip3"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          d="M188.5,63.3c-4.4-2.5-11.4-5.9-20.7-7.2-14.3-2-25.3,2.3-32,5-6.4,2.6-14.6,5.8-22,14-1.7,1.9-7.8,8.9-11,20-3.2,11.2-2.3,22.9-1,23,1.5.2-21,16,13-68"
        />
        <path
          id="Hip2"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          d="M105.9,131.1c-10.4.4-19,1.8-25,3-20.2,4.1-24.8,9.4-26,11-1.2,1.5-6.4,8.1-4,15,2.1,6,9.2,9.4,15,9,7.9-.6,12.6-8.4,16-14,2.2-3.7,6.7-12.2,9-38,.6-6.4,1.1-15.7,1-27"
        />
        <path
          id="Hip1"
          class="links_Hufte_Adduktion_vd_obl_liegend2410_cls1"
          d="M130.8,138.3c-.8,3.6-1.5,7.1-2.3,10.5-2,7-5,14-9,21-8,13-22,21-35,27-12,5-28,7-38.3-2.6"
        />
      </g>
    </svg>
  );
}
