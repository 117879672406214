import style from "./style.scss";

export default function LinkesSchultergelenkLatStehend2003(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 494.7 428.3"
    >
      <g id="Ebene_1" data-name="Ebene 1">
        <path
          id="background_2"
          data-name="background 2"
          class="Linkes_Schultergelenk_lat_stehend2003_cls11"
          d="M67.2,402.8c-22.6-4.2-50.3-9.3-50.3-9.3L21,52.1c12.9-4,55.7-16.7,64.9-19.1,8.1-2.1,18.7-4.8,33.1-7.5,19.1-3.7,18.6-2.1,36.9-2.3,7.1-1.5,18.3.8,25.4-.4,8.5-1.4,22.3,2.8,25.8,5.9,3.2,2.8,1.1,6.7,1.1,7.5,0,11.5-10.7,10.8-18.8,10.9-3.8,0-7.6,0-11.3.1-3,0-6.1,0-9.1.2-5.3.2-10.6.8-15.7,1.9-5.3,1.1-10.6,3.1-16,3.4-5.1.2-10.3-.9-15.3.6-3.7,1.1-6.8,3.6-10.3,5.3-6.6,3.1-13.5,2.5-20.5,3.4-6.7.8-12.9,3.9-18.4,7.6-3.3,2.2-7,3.8-10.7,5.4,1.2.3,2.3.6,2.3.6l14.9,3.7c33.8-9.5,84.2-17.8,138.8-25.9,7.8-2.5,5.3-4.3-4.7-5.7-7.1.6-1.9-5.2-1.4-13.3,2.5-8.6,23.3-9.5,58-4.6,7.1.8,10.9,4.1,8.8,11.7-2.4,9-14.9,14.2-35.8,16.2-22,4.3-43.4,14-64.3,28.3l6.6,3.1c7,4.4,9.2,19.3-2.4,34.1-10.7,18.9-10.9,33.4-4.1,44.8,10.4,14.6,19.5,28.8,20.6,42.1-22.4,13.3-35.9,40.6-52,74.3-16.5,23.6-31.2,47.2-35.8,70.2-2.3,18.4-7.9,36.2-15.6,53.5,0,0-13.2-2.4-28.8-5.3h0Z"
        />
        <path
          id="background_1"
          data-name="background 1"
          class="Linkes_Schultergelenk_lat_stehend2003_cls11"
          d="M478.8,189.4c-12.6,0-22.9-.5-29.8-.8-14.8-.8-17-1.4-25.9-1.3-14.5.2-14.9,2-28.2,2-9.4,0-8.3-.9-27.5-1.9-9.8-.5-15.5-.8-23.7-.6-13.7.3-12.6,1.4-22.1,1.1-8.8-.3-12.1-1.4-17.7.6-5.2,1.9-5.1,3.7-10.6,5.2-6.4,1.7-8.7-.1-13.3,2.4-1.8,1-1.2,1.1-6.8,5.9-2.8,2.4-3.6,2.9-7.9,6.4-1.1.9-2,1.6-2.8,2.3-5.4,4.4-12.4,6.3-19.2,5-3.9-.7-7.6-1.9-10.6-3.3-5.6-2.5-23.9-10.4-32.9-28.5-2-4.1-1.1-3.8-6.2-16.2-3.7-9.1-5.3-12-5.6-17.2-.1-2.8.6-6.3,2-13.2,2.2-10.5,3.3-15.8,5.5-19.7,3.2-5.7,7.1-9.2,11.6-13,5-4.4,7.5-6.6,11.6-7.9,8.1-2.6,10.8,2,16.9-.6,6.5-2.8,4.9-8.8,13.3-13.4,1.8-1,6.1-3.3,11.1-2.4,4.1.7,6.4,3.1,9.8,5.8,3.1,2.6,7.9,5.2,17.4,10.4,8.6,4.8,10.2,3.8,24.6,9.5,17.5,6.9,16.3,8.7,27.8,12.3,4.2,1.3,4.8,1.2,27.4,5.2,23.5,4.1,23.1,4.3,30.2,5.2,7.5,1,7.1.7,30.2,3,19.9,2,22.2,2.4,31.4,3.1,11.1.8,20.1,1.1,25.9,1.3"
        />
        <path
          id="Collarbone"
          class="Linkes_Schultergelenk_lat_stehend2003_cls1"
          d="M55.3,77.7c5.8-2.6,12.1-4.7,17.3-8.2,5.5-3.7,11.8-6.8,18.4-7.6,7-.9,13.9-.2,20.5-3.4,3.5-1.7,6.6-4.1,10.3-5.3,5-1.5,10.2-.4,15.3-.6,5.4-.3,10.7-2.3,16-3.4,5.2-1.1,10.4-1.6,15.7-1.9,3-.1,6.1-.2,9.1-.2,3.8,0,7.6,0,11.3-.1,8.1,0,18.8.6,18.8-10.9s2.1-4.7-1.1-7.5c-3.5-3.1-17.3-7.3-25.8-5.9-7.2,1.2-18.3-1.1-25.4.4-18.3.2-17.8-1.4-36.9,2.3-14.4,2.8-25,5.5-33.1,7.5-9.3,2.4-52,15-64.9,19.1"
        />
        <g id="shoulder_plate" data-name="shoulder plate">
          <g id="shoulder_plate_1" data-name="shoulder plate 1">
            <path
              class="Linkes_Schultergelenk_lat_stehend2003_cls8"
              d="M95.9,407.7c5.2-13.2,9.4-26.7,12.3-40.6,1-4.6,1.7-9.3,2.7-14,1.8-9.4,5.3-18.4,9.6-26.9,4.4-8.5,9.2-16.7,14.5-24.6,5.5-7.7,10.7-15.6,15-24,6.3-12.7,13-25.3,20.3-37.6,3.7-6.1,7.5-12.3,12.5-17.5,3.1-3.5,7.2-6.4,10.8-9.4,1.8-1.5,3.5-3.1,5.2-4.8l-.3.9c-1.3-8.2-4.2-16.4-8.9-23.2-1.9-2.8-4.4-5.2-6.3-8.1-4.7-7.2-9-15.1-10.1-23.9-.4-3.5-.4-7.1.3-10.6.8-3.5,2.2-6.7,3.6-9.8,2.6-6.2,6.2-12.4,9.9-18,3.2-5.5,4.2-12.8,2.7-18.9-1.2-4.7-5.4-7.9-9.9-9.5-1.5-.6-3-.8-4.7-.8-3.3,0-6.8.2-10.3-.2-3.4-.4-8-1.5-8.8-5.4l1.1-.3c2.2,5.7,13,3.7,18.1,3.8,3.6-.1,7.2,1.2,10.2,3.2,8.4,5.1,8.3,16.1,5.8,24.4-1.6,5-5.2,9.1-7.5,13.6-3,5.8-6.3,12.1-7.9,18.4-.7,3.2-.7,6.5-.3,9.8.8,6.6,3.7,12.8,7,18.6,2.4,4.6,5.8,8.2,8.9,12.4,4.9,7.2,7.8,15.7,9,24.2v.5c0,0-.3.4-.3.4-3.4,3.3-7.2,6.4-10.9,9.3-7.3,5.9-12.5,13.9-17.3,22-4.8,8.1-9.4,16.4-13.8,24.8-6.2,12.8-13.2,25.4-21.6,36.8-10.2,15.7-20.2,32.3-23.8,50.8-3.2,18.7-7.9,37.3-15,54.9l-1.9-.7h0Z"
            />
          </g>
          <path
            id="shoulder_plate_2"
            data-name="shoulder plate 2"
            class="Linkes_Schultergelenk_lat_stehend2003_cls1"
            d="M185.2,89c-2.9,5.5-6.7,12.8-8.5,17.7-2.8,7.2-7.7,21.9-9.4,41.5-1.2,14.2-2,25,3.1,37.1,5,11.7,11.7,15.5,13.2,16.4,5.1,2.8,12.7,7.6,15.7,7.5"
          />
          <line
            id="shoulder_plate_3"
            data-name="shoulder plate 3"
            class="Linkes_Schultergelenk_lat_stehend2003_cls1"
            x1="61.5"
            y1="89.9"
            x2="89.2"
            y2="83.4"
          />
          <path
            id="shoulder_plate_4"
            data-name="shoulder plate 4"
            class="Linkes_Schultergelenk_lat_stehend2003_cls1"
            d="M134.5,105.6c2.6-8.5,10.1-13.5,27.7-13.2,3.9,0,7.1-3.8,8.6-7.6,1.2-2.8,1.6-6.3.5-9.2-1.2-3-4.8-5-7.6-6.1-7-2.8-14.9-1.1-22,.8-4.6,1.2-6.9,1.7-10.7,3.6-.9.4-1.9.7-2.8.7-1.7,0-3.2.3-4.3.3-2,.1-4.1.3-6.8-.1-.8-.1-2.9-.5-5.1-.2-1,.1-1.8.3-1.9.4-.8.2-1.6.5-2.4.8-1.9.9-2.9,1.8-4.3,2.6-1.4.9-2.5,1.3-4.7,2.2-1.7.7-5.9,2.4-9.4,2.5-4.5.2-4.5-2.3-12-5-3.7-1.3-3.8-.7-17-3.8-2.2-.5-4.1-1-4.8-1.1-1.2-.3-2.3-.6-3.3-.9"
          />
        </g>
        <g id="acromion">
          <g id="acromion_1" data-name="acromion 1">
            <path
              class="Linkes_Schultergelenk_lat_stehend2003_cls8"
              d="M134.2,130.2c14.1-14.2,32.1-35.4,48.2-46.9,23.3-17.6,49.4-25.7,77.9-30,5.2-1,10.6-3,14.3-6.9,3.2-3.5,5.6-10,1.7-13.8-1.8-1.6-4.7-1.8-7.5-2.3-2.8-.4-5.5-.8-8.3-1-11-1-22.5-1.2-33.5-1.5-3.5-.2-6.8.3-9.5,2.3-3.2,1.7-5.2,3.7-5.5,7.4-.4,2.1-.9,4.2-1.6,6.2-.2.6-.5,1.1-.4,1.6,0,.9.9,1.7,1.9,1.6,0,0,.2,0,.2,0,2.4.3,7,1,9.3,1.3,2.8.4,2.8,4.5.1,5.1-2.4.5-5.8,1.2-8.3,1.6-21.2,3.7-45,6.8-66.2,10.3-29.2,5-58.7,10.1-86.7,20.3l-.2-.4c27.8-10.9,57.3-16.5,86.6-21.7,23.1-4.2,48.6-7.2,71.6-11.5l2.7-.6c.3,0,.4-.2.5-.3.2-.3,0-.8-.3-1-.2,0-.9-.1-1.2-.2-1.8-.2-6.5-.9-8.3-1.2h.2c-.9,0-1.9-.2-2.6-.9-1.5-1.2-1.5-3.3-.7-4.9.7-1.9,1.2-3.9,1.5-6,.5-5.5,3.8-7.3,8.2-9.8,2.7-1.4,5.8-1.5,8.6-1.4,5.6.3,11.2,0,16.8.5,8.1.5,17.2.9,25.2,2.1,2.9.5,6,.6,8.4,2.7,4.9,4.5,2.5,12.2-1.4,16.5-4.1,4.3-9.7,6.5-15.4,7.7-28.6,4.4-53.9,11.6-77.4,29.1-9.1,6.5-17.2,14.2-25,22.2l-23.6,23.9-.5-.5h0Z"
            />
          </g>
          <path
            id="acromion_2"
            data-name="acromion 2"
            class="Linkes_Schultergelenk_lat_stehend2003_cls7"
            d="M251.7,43.3c-.7,5.1-32,7.4-40.7,4.5"
          />
        </g>
        <g id="ribs">
          <path
            id="rib_1"
            data-name="rib 1"
            class="Linkes_Schultergelenk_lat_stehend2003_cls6"
            d="M14.9,179.1c80.2,16.1,138.3,95.7,84.9,157.9"
          />
          <path
            id="rib_2"
            data-name="rib 2"
            class="Linkes_Schultergelenk_lat_stehend2003_cls6"
            d="M14.9,149.1c73.7,16.6,195.1,112,98.4,213.3"
          />
          <path
            id="rib_3"
            data-name="rib 3"
            class="Linkes_Schultergelenk_lat_stehend2003_cls6"
            d="M14.9,239.2c83.2,16.1,143.3,95.7,88,157.9"
          />
          <path
            id="rib_4"
            data-name="rib 4"
            class="Linkes_Schultergelenk_lat_stehend2003_cls6"
            d="M14.9,209.2c76.4,16.6,202.3,112,102,213.3"
          />
          <path
            id="rib_5"
            data-name="rib 5"
            class="Linkes_Schultergelenk_lat_stehend2003_cls6"
            d="M5.8,304.2c73,13.3,129.3,69.8,113.7,124.1"
          />
          <path
            id="rib_6"
            data-name="rib 6"
            class="Linkes_Schultergelenk_lat_stehend2003_cls6"
            d="M5.8,274.2c64.4,13.1,162.1,76,146.3,152.1"
          />
          <path
            id="rib_7"
            data-name="rib 7"
            class="Linkes_Schultergelenk_lat_stehend2003_cls6"
            d="M0,366.2c41.6,8,77.4,32,95.9,61.6"
          />
          <path
            id="rib_8"
            data-name="rib 8"
            class="Linkes_Schultergelenk_lat_stehend2003_cls6"
            d="M0,336.2c43.5,9.4,103.1,44.5,127.8,91.6"
          />
          <path
            id="rib_9"
            data-name="rib 9"
            class="Linkes_Schultergelenk_lat_stehend2003_cls6"
            d="M19,120.3c73,15.1,125.8,89.7,77.3,148"
          />
          <path
            id="rib_10"
            data-name="rib 10"
            class="Linkes_Schultergelenk_lat_stehend2003_cls6"
            d="M19,92.2c67,15.5,177.5,105,89.5,200"
          />
        </g>
        <g id="outlines">
          <g id="outlines_1" data-name="outlines 1">
            <g>
              <line
                class="Linkes_Schultergelenk_lat_stehend2003_cls4"
                x1="138.4"
                y1="1"
                x2="144.4"
                y2="1.5"
              />
              <path
                class="Linkes_Schultergelenk_lat_stehend2003_cls5"
                d="M156.7,2.4l121.3,9.2c14.5,1.1,28.9,3.9,42.7,8.3,7.4,2.3,14.6,5.6,21.3,9.5,39.5,23.3,87.4,40.8,140.5,51.6"
              />
              <path
                class="Linkes_Schultergelenk_lat_stehend2003_cls4"
                d="M488.6,82.2c2,.4,3.9.8,5.9,1.1"
              />
            </g>
          </g>
          <g id="outlines_2" data-name="outlines 2">
            <g>
              <path
                class="Linkes_Schultergelenk_lat_stehend2003_cls4"
                d="M477.2,213.1c-2,0-4,0-6,0"
              />
              <path
                class="Linkes_Schultergelenk_lat_stehend2003_cls2"
                d="M459,213.4c-58.2,1.3-113.2,5.8-164,14.6"
              />
              <path
                class="Linkes_Schultergelenk_lat_stehend2003_cls4"
                d="M289,229c-2,.4-3.9.7-5.9,1.1"
              />
            </g>
          </g>
        </g>
        <g id="upper_arm" data-name="upper arm">
          <path
            id="upper_arm_1"
            data-name="upper arm 1"
            class="Linkes_Schultergelenk_lat_stehend2003_cls1"
            d="M478.8,189.4c-12.6,0-22.9-.5-29.8-.8-14.8-.8-17-1.4-25.9-1.3-14.5.2-14.9,2-28.2,2-9.4,0-8.3-.9-27.5-1.9-9.8-.5-15.5-.8-23.7-.6-13.7.3-12.6,1.4-22.1,1.1-8.8-.3-12.1-1.4-17.7.6-5.2,1.9-5.1,3.7-10.6,5.2-6.4,1.7-8.7-.1-13.3,2.4-1.8,1-1.2,1.1-6.8,5.9-2.8,2.4-3.6,2.9-7.9,6.4-1.1.9-2,1.6-2.8,2.3-5.4,4.4-12.4,6.3-19.2,5-3.9-.7-7.6-1.9-10.6-3.3-5.6-2.5-23.9-10.4-32.9-28.5-2-4.1-1.1-3.8-6.2-16.2-3.7-9.1-5.3-12-5.6-17.2-.1-2.8.6-6.3,2-13.2,2.2-10.5,3.3-15.8,5.5-19.7,3.2-5.7,7.1-9.2,11.6-13,5-4.4,7.5-6.6,11.6-7.9,8.1-2.6,10.8,2,16.9-.6,6.5-2.8,4.9-8.8,13.3-13.4,1.8-1,6.1-3.3,11.1-2.4,4.1.7,6.4,3.1,9.8,5.8,3.1,2.6,7.9,5.2,17.4,10.4,8.6,4.8,10.2,3.8,24.6,9.5,17.5,6.9,16.3,8.7,27.8,12.3,4.2,1.3,4.8,1.2,27.4,5.2,23.5,4.1,23.1,4.3,30.2,5.2,7.5,1,7.1.7,30.2,3,19.9,2,22.2,2.4,31.4,3.1,11.1.8,20.1,1.1,25.9,1.3"
          />
          <path
            id="upper_arm_2"
            data-name="upper arm 2"
            class="Linkes_Schultergelenk_lat_stehend2003_cls3"
            d="M276.1,119.3c-2.1-5.2-14-7.6-24.3,2.6"
          />
          <path
            id="upper_arm_3"
            data-name="upper arm 3"
            class="Linkes_Schultergelenk_lat_stehend2003_cls7"
            d="M236.7,100c13.3,47.7,26.8,84.8,35.5,94.4"
          />
        </g>
      </g>
      <g id="Ebene_2" data-name="Ebene 2">
        <path
          id="joint_socket_overlay"
          data-name="joint socket overlay"
          class="jointsocket2003Fill"
          style={{
            fill: props.colors.jointsocket2003Fill,
            stroke: props.colors.jointsocket2003Fill,
          }}
          d="M184.8,89.9c3.9,1.7,5.9,6.1,5.4,14.4-.2,3.6-1.2,7.2-2.9,10.4-7.7,14.3-12.9,26.4-13,34.4,1.1,12.5,6.4,24.4,15.6,35.7,6.9,8.9,9.4,16.9,8.7,24.3-11.2-3.5-21.1-9.4-27.5-22.2-5-15.3-5-32.5-2.4-50.7,3.2-16.1,8.5-31.6,16.2-46.3h0Z"
        />
        <path
          id="joint_overlay"
          data-name="joint overlay"
          class="jointgap2003Fill"
          style={{
            fill: props.colors.jointgap2003Fill,
            stroke: props.colors.jointgap2003Fill,
          }}
          d="M193.3,155c1.4,9.2,3.9,16.6,6.6,22.5,6.4,14.5,14.6,22,11.5,28.5-1.2,2.5-4.1,4.9-7.3,5.1-13.2.8-24.9-36.8-28.6-57.4-2.1-11.4,1-18.7,3.8-25.5,2.8-7,3.3-6.9,6.6-15.5s4-18.6,9.4-23.5c4.9-4.5,11.8-4.6,16-.5,3.3,3.3,2.5,8.8,2.3,9.9-1.1,7.6-7.2,10.7-12.2,16.9-3.6,4.5-7.7,12.1-8.5,24.9.2,4.9.3,9.7.5,14.6Z"
        />
      </g>
    </svg>
  );
}
