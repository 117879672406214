import style from "./style.scss";

function RechteMittelhandLatSitzend1336(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.7 500.91">

<g id="fullhand">
  <path id="fingerpart12" class="mittelhand_rechts_lat1336_cls5" d="M24.8,106.51c11.9,20.4,21.4,38.1,24.8,49,11.6,5.7,19.9,11.9,10.5,21.3l-21.4,20.2c0,4.8-.5,9.2-8.6,7.3-11,1.4-15.1-7.8-17.4-20.1h0c-3.4-25.3,7-22.3-12.2-56.2"/>
  <path id="fingerpart11" class="mittelhand_rechts_lat1336_cls5" d="M129,13.41c7.5,16.4,13.2,31.8,17.3,46.2l-2.6,5.8c-6.3-1.6-14.2-2.5-14.8-6.1l-11.1,7.8s-6.2.1-6.2,0h0c-9-5.4-8.6-11.4-2-17.9-3.4-14.2-7.6-29.8-12.8-46.7"/>
  <path id="fingerpart10" class="mittelhand_rechts_lat1336_cls5" d="M133.1,22.91c5.4,12.8,10,25.1,13.4,36.6l-2.6,5.8c5.7,3.5,11.1,7.2,10,13.3,3.4,2.3,6.9,1,10.3-1.9,1.1,3.7,4.4,5.6,9,6.6,2.4-4.5,1.7-10-.2-16.1-7.9-12.1-12.9-25.2-16.2-38"/>
  <path id="fingerpart9" class="mittelhand_rechts_lat1336_cls5" d="M165.9,41.71c3.8,8.6,8.5,21.5,18.9,41.6,2.4,6.6,3.7,14.1,3.9,22.7-1.4-.2-3.2-.5-5.4-.8,2.5-6.2-1.9-13.6-10.1-21.8,2.2-5.5,1.7-10.8-.2-16.1-2.3-.4-7.5-15.1-14.7-32.5"/>
  <g id="forearm">
    <path id="forearmpart2" class="mittelhand_rechts_lat1336_cls5" d="M228.2,500.41l-4.7-16.8c-5.3-19.6-3.1-43,2.2-68.1-3.9-17.4-14.9-16.5-20.9-5l-3.7,3.7"/>
    <path id="forearmpart1" class="mittelhand_rechts_lat1336_cls5" d="M223.8,499.71c-2.8-10.8-5.5-21.3-8.1-48.4l4.6-27c-3.6-6.5-5.9-14.5-19.2-10.2-12.4,12.7-27.8,20-43.4,11.9-3.2-3-7.3-6.8-17.2-8.6-6.2,8.1-8.4,17.8-4.9,29.7-.9,7.7-.7,15.4,9.5,23.5,5.2,6.4,8.8,12.9,10.9,19.7"/>
  </g>
  <g id="middlehandbones">
    <path id="middlehandbones4" class="mittelhand_rechts_lat1336_cls5" d="M135.5,312.21v-.1h0c1.3-3,1.1-6.6-.5-10.7l-4.6-4.5c-5.8-5.8-1.4-14.2,3.8-22.8,11.5-43.1-1.3-139.2-14.8-152.9-8.6-8.8-16.7-17.5-17.3-24.4.6-10.7,2.2-19.8,4.6-27.3,4.4-.2,7.8-1.3,11.2-2.3,4.4-3.9,8.4-6.4,11.2-5.9l14.8,4.3c8.2,4.1,11.5,8.5,10,13.3,8.6,10.2,9.6,21.4,6.6,33l4.5,154.8c9.8,13.5,17.3,27.3,7.2,41.5-3.8,1.3-8.2-1.3-13-5.7-4.3,8-7.8,9.3-11.1,10-3.4-.9-7.4-1.2-12.5-.3h-.1Z"/>
    <path id="middlehandbones3" class="mittelhand_rechts_lat1336_cls5" d="M165,266.51s0,0,0,0h0c9.8,13.8,15.5,27.6,9.4,41.5,3.4,0,13.2-8.5,16.9-7.3,1.6,3.3,4.6,5,8.1,5.7,1.3-5-.7-11.7-3.3-18.7l-11.5-28.1c7.5-41.8-7.1-108.7-4.6-123.9l-.6-13.8c2.7-5.7,4.2-11.3,3.8-16.6,2.3-5.3-1.5-12.8-10.1-21.8-4.2-1-8.5-1.9-9-6.6-3.5,2.3-7,3-10.3,1.9,8.6,11.2,10.3,22.2,6.6,33,5.9,59.9,9,114.5,4.5,154.8l.1-.1Z"/>
    <path id="middlehandbones2" class="mittelhand_rechts_lat1336_cls5" d="M197.3,133.71h0l-1.5,132.9c-3,4.7,5.1,15.2,6.5,19.6-3.4,5.6-3.9,9.2.5,9.4,4.6,5.4,6.9,10.7,5.6,16.1-1.9-4-5.2-5.3-9-5.3,2-3.7.1-10.7-3.3-18.7l-11.5-28.1c7.2-38.8-4.9-98.4-5.2-137.8,3.2-7.9,4.3-13.3,3.8-16.6l5.4.8c6.3,3.4,10.4,10.8,8.9,27.7h-.2Z"/>
    <path id="middlehandbones5" class="mittelhand_rechts_lat1336_cls5" d="M74.8,296.41h0c-12.8-25.8-24.9-49.7-33.6-63.1-14.9-1.6-20.6-15.9-19.1-25.2l8.1-3.7c11,2.3,7.2-5.9,10.6-8.9,6.8-6,13.3-12.1,19.5-18.6,8.5,2.6,14.1,7.9,14.6,18.4-.2,7.5,2.1,16.1,9.7,27.1,8.2,22.9,14.8,40.1,20,53.5,3.6,4.9,7.9,9.2,13.7,12,3.5,6.2,5.4,15.2,6.3,26.1.1,7.7-1.7,15-6.8,21.3-14.1,5.3-26.3,7-35.2,2.2-7.9.1-15.1-10.6-7.5-28.4l-.4-12.7h.1Z"/>
    <path id="middlehandbones1" class="mittelhand_rechts_lat1336_cls5" d="M197.6,133.61h0l.4-5.8c6.9,3.8,10.9,10.9,4.8,28.6,2.4,26.5-.6,62.6.1,104,5.4,11.5,10.8,23.2,4.5,27l-4.4,8.1c-4.8.1-4-3.8-.5-9.4-1.7-6.2-9.1-12.9-6.5-19.6l1.5-132.9h.1Z"/>
  </g>
  <g id="fingerpart3">
    <path id="fingerpart8" class="mittelhand_rechts_lat1336_cls5" d="M115.6,295.01h0c5-2.6,10.9-2.4,19.3,6.3,1.3,4.7.8,10-.2,15.5-1.6,13.9-.8,26.2,7.5,33.2,2.2,14.6-3,26-22.7,27.6-7.1-.2-17.2-6.3-27.7-18.2-1.7-7-2.8-13.7-1.1-18.8,10.2.1,19.9-5.2,29.4-13.1,4.4-9.2,2.3-20.2-4.4-32.4l-.1-.1Z"/>
    <path id="fingerpart7" class="mittelhand_rechts_lat1336_cls5" d="M143,357.61h0c15.4-3.8,26.6-1.5,33.9,6.7,4.7,15.8,13.1,27.3,28.6,31,3.4,6.8-13.4,30.2-39.3,27.3-7.3-.1-13.5-3.2-19.3-7.6-8.6-9-9.3-15.4-7.4-21-6-2.4-11.3-5.2-15.6-8.5-1.4-3.3-1.7-6.3.8-8.7,12.2-4.3,16.9-11.2,18.3-19.3v.1Z"/>
    <path id="fingerpart6" class="mittelhand_rechts_lat1336_cls5" d="M159.3,302.31h0c4,4.2,8.1,7.5,13,5.7,9.5,20,14.3,37.5-2,45.7l-27.2,3.9-.8-7.6c-8.1-8.2-8.1-20.6-7.5-33.2l.8-4.7c6.1-.6,9.2-.1,12.5.3,3.4-.8,7.1-4.8,11.1-10l.1-.1Z"/>
    <path id="fingerpart5" class="mittelhand_rechts_lat1336_cls5" d="M177,364.41h-.2c6.5,19,16.3,30.6,31.7,30.5,6.4-8.1,6.9-18.2,5.1-29.1l-9.3-9.5c-.3-5.2-.8-10.3-2.4-15.2,0-6-1.6-11.5-3.5-16.7,2.7-5.1,6.3-9.2,10.2-12.7-2.6-3.9-5.8-5.6-9.7-5.3-3.2-.2-5.6-2.3-7.5-5.7l-16.9,7.3-1.2,5.6c3.2-.4,18.3,34.2-2.8,40.1l-9.6,1c10.2,3,15.4,6.2,16.2,9.6l-.1.1Z"/>
    <path id="fingerpart4" class="mittelhand_rechts_lat1336_cls5" d="M208.5,311.51h0c.5,7.4,1.4,15.5-.9,18.2,6.9,16.8,6.2,25.8.8,30l-4.1-3.5c.7-5.4-2.6-18.5-6-31.9,1.6-5.1,5.3-9.2,10.2-12.7v-.1Z"/>
  </g>
  <path id="fingerpart1" class="mittelhand_rechts_lat1336_cls3" d="M197.7,127.91h0c6.5-8.9-.7-23.2-10.2-35.2l1.1,13.4c6.5,2.6,8.2,11.8,9.2,21.8h-.1Z"/>
</g>
<g id="middlehandboneshighlights" class="mittelhand_rechts_lat1336_cls1">
  <path id="middlehandboneshighlight5" class="mhk1336fill" style={{
            fill: props.colors.mhk1336fill,
            stroke: props.colors.mhk1336fill,
          }} d="M107.1,69.51c4.4-.2,7.8-1.3,11.2-2.3,4.4-3.9,8.4-6.4,11.2-5.9l14.8,4.3c8.2,4.1,11.5,8.5,10,13.3,8.6,10.2,9.6,21.4,6.6,33l4.5,154.8c9.8,13.5,17.3,27.3,7.2,41.5-3.8,1.3-8.2-1.3-13-5.7-4.3,8-7.8,9.3-11.1,10-3-.79-6.48-1.12-10.76-.56-.18-.91-1.6-.31-2.06-1.1.1-.89,1.17-3.28,1.01-4.16-.13-1.65-.56-3.41-1.29-5.28l-4.6-4.5c-5.8-5.8-1.4-14.2,3.8-22.8,11.5-43.1-1.3-139.2-14.8-152.9-8.6-8.8-16.7-17.5-17.3-24.4.6-10.7,2.2-19.8,4.6-27.3Z"/>
  <path id="middlehandboneshighlight4" class="mhk1336fill" style={{
            fill: props.colors.mhk1336fill,
            stroke: props.colors.mhk1336fill,
          }} d="M165.4,266.51h0c9.8,13.8,15.5,27.6,9.4,41.5,3.4,0,13.2-8.5,16.9-7.3,1.6,3.3,4.6,5,8.1,5.7,1.3-5-.7-11.7-3.3-18.7l-11.5-28.1c7.5-41.8-7.1-108.7-4.6-123.9l-.6-13.8c2.7-5.7,4.2-11.3,3.8-16.6,2.3-5.3-1.5-12.8-10.1-21.8-4.2-1-8.5-1.9-9-6.6-3.5,2.3-7,3-10.3,1.9,8.6,11.2,10.3,22.2,6.6,33,5.9,59.9,9,114.5,4.5,154.8l.1-.1Z"/>
  <path id="middlehandboneshighlight3" class="mhk1336fill" style={{
            fill: props.colors.mhk1336fill,
            stroke: props.colors.mhk1336fill,
          }} d="M197.6,133.71h0l-1.5,132.9c-3,4.7,5.1,15.2,6.5,19.6-3.4,5.6-3.9,9.2.5,9.4,4.6,5.4,6.9,10.7,5.6,16.1-1.9-4-5.2-5.3-9-5.3,2-3.7.1-10.7-3.3-18.7l-11.5-28.1c7.2-38.8-4.9-98.4-5.2-137.8,3.2-7.9,4.3-13.3,3.8-16.6l5.4.8c6.3,3.4,10.4,10.8,8.9,27.7h-.2Z"/>
  <path id="middlehandboneshighlight2" class="mhk1336fill" style={{
            fill: props.colors.mhk1336fill,
            stroke: props.colors.mhk1336fill,
          }} d="M22.5,208.11l8.1-3.7c11,2.3,7.2-5.9,10.6-8.9,6.8-6,13.3-12.1,19.5-18.6,8.5,2.6,14.1,7.9,14.6,18.4-.2,7.5,2.1,16.1,9.7,27.1,8.2,22.9,14.8,40.1,20,53.5,3.6,4.9,7.9,9.2,13.7,12,.93,1.64,1.73,3.5,2.44,5.52-.45.11-.85.29-1.21.52-.28-.11-.6-.19-.97-.19-.06,0-.11.01-.16.01-.05,0-.09-.01-.14-.01-3.01,0-3.2,4.36-.59,4.94.07.37.17.74.32,1.08.16,1.37.72,2.69,1.7,3.66.2.34.43.67.69.96.03.96.27,1.92.7,2.77-.23,1.82.08,3.7.94,5.3-.56.92-.51,2.21.15,3.05-.26.59-.3,1.28-.12,1.9-.17.65-.13,1.35.17,1.95-.16,1-.18,2.02-.02,3.02-.66,1.08-1.05,2.33-1.18,3.61-.34.38-.62.8-.86,1.24-.92.18-1.5.81-1.75,1.57-.51.24-.96.54-1.34.9-1.22.35-2.16,1.06-2.82,1.97-.93.35-1.67.91-2.24,1.61-.19.15-.37.31-.54.47-1.76.18-3.07,1.02-3.91,2.19-.44.16-.85.35-1.21.61-.7.27-1.31.66-1.8,1.14-.71.07-1.35.24-1.91.5-.38.1-.73.23-1.06.4-1.44.06-2.59.53-3.47,1.27-.26-.03-.53-.07-.81-.07-.98,0-1.83.19-2.56.53-4.51-.03-8.59-.89-12.14-2.8-7.9.1-15.1-10.6-7.5-28.4l-.4-12.7h.1c-12.8-25.8-24.9-49.7-33.6-63.1-14.9-1.6-20.6-15.9-19.1-25.2Z"/>
  <path id="middlehandboneshighlight1" class="mhk1336fill" style={{
            fill: props.colors.mhk1336fill,
            stroke: props.colors.mhk1336fill,
          }} d="M197.9,133.61h0l.4-5.8c6.9,3.8,10.9,10.9,4.8,28.6,2.4,26.5-.6,62.6.1,104,5.4,11.5,10.8,23.2,4.5,27l-4.4,8.1c-4.8.1-4-3.8-.5-9.4-1.7-6.2-9.1-12.9-6.5-19.6l1.5-132.9h.1Z"/>
</g>
<g id="outlines">
  <path id="outlinepart3" class="mittelhand_rechts_lat1336_cls4" d="M.5,45.71s38.1,34.3,44.7,56.3c2.9,9.6,14,15.2,23,43.3,10.4,32.3,20,45.1,28.6,47.1,10.6,2.5,11.5.7,9.5-22.3-.6-6.8.5-.2-9.5-26.9s-15.1-72.7-19.2-84.5c-8.4-24.1-4.6-47.4-13.4-58.4"/>
  <path id="outlinepart2" class="mittelhand_rechts_lat1336_cls4" d="M107.3,499.81c0-24-10.6-68.5-12.9-78.4-2.8-11.9-16.4-15.7-28.6-32s-29.5-51.5-37.8-77.7C19.7,285.51,3,233.81.5,208.51"/>
  <path id="outlinepart1" class="mittelhand_rechts_lat1336_cls4" d="M228.1,394.91s-4.5-8-2.4-17.3c2.1-9.3-5.2-54.4-5.2-54.4,1-.4.8-46.6-7-74.6-7.3-26.3-2.1-81.2,1.8-105.2,3.4-20.5-5.2-17.9-2.8-18.7s-21.1-26.9-16.8-26.9"/>
</g>
<g id="OutlineHighlights">
  <path id="outlineparthighlight4" class="thumb1336Fill" style={{
          fill: props.colors.thumb1336Fill,
          stroke: props.colors.thumb1336Fill,
        }} d="M24.86,106.92c11.9,20.4,21.4,38.1,24.8,49,11.6,5.7,19.9,11.9,10.5,21.3l-21.4,20.2c0,4.8-.5,9.2-8.6,7.3-11,1.4-15.1-7.8-17.4-20.1h0c-3.4-25.3,7-22.3-12.2-56.2"/>
  <path id="outlineparthighlight3" class="weichteile1336fill" style={{
          fill: props.colors.weichteile1336fill,
          stroke: props.colors.weichteile1336fill,
        }} d="M.6,45.81c2-6.7,37.3,31,49.6,57.1,10.4,15.1,16.9,28.6,20.2,37.7,4.3,12.9,12.3,34.8,16.6,40.2,4.3,5.4,12.2,10.7,15.6,7.7,3.4-3,.9-18.7,0-20.7-6.4-14.4-18-47.9-21.5-73-1.5-10.4-14-55.6-13.2-62.7,1.3-11.1-1.8-28.6-1.8-28.6,8.7,11,3.1,31.5,11.5,55.6,4.1,11.8,9.3,57.7,19.2,84.5,1.62,4.35,2.92,6.58,4.01,8.02.45,3.44,2.02,6.73,3.54,9.79.02.03.04.05.06.08.06.49.12.97.25,1.45.15.52.37,1.02.6,1.51.12.25.31.45.52.62.17,1.56.34,3.35.52,5.43,1.9,23,1.1,24.8-9.5,22.3-8.6-2-18.3-14.8-28.6-47.1-9-28.1-20.1-33.7-23-43.3C38.6,80.51.5,46.11.5,46.11l.1-.3Z"/>
  <path id="outlineparthighlight2" class="weichteile1336fill" style={{
          fill: props.colors.weichteile1336fill,
          stroke: props.colors.weichteile1336fill,
        }} d="M107.4,500.11c-6.1-33-13.3-73-20.3-84.1-1.7-2.8-3.1-2.2-16.4-14C35.4,356.51,10.1,284.21.6,208.91c2.5,25.3,19.2,77,27.5,103.2,8.3,26.2,25.5,61.4,37.8,77.7,12.3,16.3,25.8,20.1,28.6,32,2.3,9.9,12.9,54.4,12.9,78.4v-.1Z"/>
  <path id="outlineparthighlight1" class="weichteile1336fill" style={{
          fill: props.colors.weichteile1336fill,
          stroke: props.colors.weichteile1336fill,
        }} d="M228.3,395.41c1.4-4.2-2.3-50.9-3.7-61.5-1.1-8-3.6-78.1-6.5-84.9-1.5-3.5-3.3-16.5-3.5-30.5-.7-37.2,1.5-79.3,7.3-86.6,2.9-3.6-26.1-34.9-26.1-33.6-2.9-.2,18.6,26.6,16.8,26.9,0,0,6.2-1.8,2.8,18.7-4,23.9-9.2,78.8-1.8,105.2,7.8,28,7.8,79.7,7,74.6s7.3,45.1,5.2,54.4c-2.1,9.3,2.4,17.3,2.4,17.3h.1Z"/>
</g>
</svg>
  );
}

export default RechteMittelhandLatSitzend1336;
