import { Container, Col, Row } from "react-bootstrap";
import Card from "../card/Card";
import ClipLoader from "react-spinners/ClipLoader";
import "./authScreen.css";

export default function AuthScreen() {
  return (
    <Container className="auth-full pt-5">
      <Row>
        <Col>
          <Card className="my-3 mx-3" header="PergamonMED Exam Manager">
            <div className="text-center my-3">
              <h4>Please Wait</h4>
              <ClipLoader color="#ff880d" loading={true} css={true} size={60} />
              <h4>Authenticating...</h4>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
