import style from "./style.scss";

export default function LinkerOberschenkelMitHufteVdStehend2349(props) {
  return (
    <svg
      id="OSmitHuefte"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 313.5 362.3"
    >
      <g id="upper_leg_with_hip" data-name="upper leg with hip">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls9"
            d="M243.3,138.7c-.8-10,.6-10.4-.7-15.6-.7-2.9-3.6-12.2-12-18-10.8-5.3-26.6-4.9-28-6-1.4.1-3.7.4-6.6-.4-1.8.8-3.1,1.6-9.2-5.4,0,0-10.1-5.9-17.8-12.6-7.4-6.5-14.3-12.4-17.7-22.2-2.2-4.2-2.9-7.3-6-12.5-3.8-2.9-9-3.2-9-5,0-.8,1.2-1.2,2.7-2.3,3.4-2.7,4.3-6.9,4.7-8.8,1.3-6.1-2.9-8.4-3-14,1.9-7.6,8.8-14.8,10.7-17.2-1-8.6-43.1-7.3-89.4-13.1-8.3-1-15.7-2.2-22.4-3.3-17.4-.6-34.8-1.6-51.9,1.4-.2.3-.4.7-.5,1,0,1.5,0,3,0,4.4,1.9-1.2,5.1,0,4.8,2.8-3.6,28.7-4.8,57.7-4.2,86.6.6,9.9.6,19.8-.6,29.7,1.1,13.7,1.8,27.3,0,40.8,1.5,3.1,3.4,5.9,5.8,8.5,2.8,3,10.3,11.2,22.1,13.4,7.6,1.4,13.7-.2,19.1-1.7,15.5-4.2,25.4-13.3,29.7-17.4,5.7-5.4,12.9-12.3,16.9-23.6,4.7-11,2.4-16.9,5.5-20.4,2.8-.7,11.4,5.6,26.7,11.8,13,5,17.4,11.8,21.4,18.7,2.4,4.2,3.5,10.4,5.6,22.7,2.9,16.8,4.3,25.4,2,34.5-.5,1.9-4.9,5.4-8.2,13.9-5.6,14.4-4.1,19.2-2.2,22.6,2.7,4.8,8.4,4.1,12.4,6.9.2,6.8.4,13.5-1.6,19.7-2.6,8.1-.7,6.7-2.6,43-1.6,29.7-3.5,43.3-4.3,50.9,0,3.2.1,6.3.3,9.5.3.8.7,1.6,1.1,2.5.3.5.6,1,.9,1.5,24.7,0,49.4,3.3,74.1,2.8,0-15.8,2.8-31.4,3.4-47.2,1.4-36.1,2.6-29.3,4.3-69.1.9-21.5.7-26.6,3.2-36.9,3.2-13.3,6-25,14-38,4.6-7.5,8.2-10.9,8.9-18.3.7-6.5-1.7-8.4-2.6-20.8h0Z"
          />
        </g>
        <g id="hip">
          <path
            id="hip_1"
            data-name="hip 1"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls9"
            d="M10.2,24.7c3.3-3.9,5.9-7.1,8-12,3-7,2.3-11.8,3.3-15.8H-7.4c0,.2,0,.4,0,.6.5,10.1,0,20.3-.7,30.4.4,4.1.6,8.2.7,12.2.2-.1.4-.3.6-.4,5.4-3.9,11.4-8.3,17-15h0Z"
          />
          <path
            id="hip_2"
            data-name="hip 2"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
            d="M-2.2,160.7c6.9,5.2,13.9,11.2,22.9,11.2,19-1,35-12,49-25,14-15,15-35,20-54,1-2,2-3,3-5,5-5,11-7,17-12,7-5,8-13,12.6-20.1"
          />
          <path
            id="hip_3"
            data-name="hip 3"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
            d="M67.2-.3c-7.5,19.2-14.5,37.2-27.5,53.2-13,15-24,30-42.2,37"
          />
          <path
            id="hip_4"
            data-name="hip 4"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
            d="M-5.5,39.1C8.7,29.9,19.7,14.9,21.9-2.5"
          />
          <path
            id="hip_5"
            data-name="hip 5"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
            d="M61.3,100.8c-10.4.4-19,1.8-25,3-20.2,4.1-24.8,9.4-26,11-1.1,1.5-6.4,8.1-4,15,2.1,6,9.2,9.4,15,9,7.9-.6,12.6-8.4,16-14,2.2-3.7,6.7-12.2,9-38,.6-6.4,1.1-15.7,1-27"
          />
          <path
            id="hip_6"
            data-name="hip 6"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
            d="M144,32.9c-4.4-2.5-11.4-5.9-20.7-7.2-14.3-2-25.3,2.3-32,5-6.4,2.6-14.5,5.8-22,14-1.7,1.9-7.8,8.9-11,20-3.2,11.2-2.4,22.9-1,23,1.5.2-21,16,13-68"
          />
          <path
            id="hip_7"
            data-name="hip 7"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
            d="M40,51.5c0,7.7,2.9,8.3,7.3,8.3"
          />
          <path
            id="hip_8"
            data-name="hip 8"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls11"
            d="M65,2.9c-4.6,8.3-8.2,9.9-10.7,9.8-4.2-.2-5.9-5.4-10-5-3.4.3-6.6,4.2-7,8-.4,4.6,3.9,5.8,5,12,.7,4-.4,7.1-2,12-1.1,3.2,3.6,6.7-.3,11.7,10.6-13.4,18.8-29.7,25-48.5h0Z"
          />
          <path
            id="hip_9"
            data-name="hip 9"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
            d="M-7.2,7.7c0,5.9-.4,11.7-.7,17.5,4.8,0,8.7-4,8.7-8.8S-2.7,8.2-7.2,7.7Z"
          />
          <path
            id="hip_10"
            data-name="hip 10"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
            d="M122.3,55.8c.4-.9.4-1.9,1.4-2.9,7-8,19-12,20-23,1-5-5-9-3-14,3-6,6-12,10.7-17.2"
          />
        </g>
        <g id="upper_leg" data-name="upper leg">
          <path
            id="upper_leg_1"
            data-name="upper leg 1"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
            d="M137,364.7c1.9-49,3.9-85.4,6-115,1.2-15.9,3.2-41.3-1-75-1.4-11.2-3.1-20.4-4.4-26.6-3.2-26.2-44.9-35.8-51.3-40.1-7.1-4.7-9.8-12.1-12-18.2-2.7-7.4-6.8-18.6-2-31,5.1-13.3,17-19.1,21-21,3.4-1.6,13.3-6.2,26-4,8.1,1.4,13.9,4.9,17,7.2.2,2.2,4.8,1.6,9,5,14,35.8,42.5,46.9,44.3,51.1,2.1,1.7,5.8,3.3,13,2,23.5,2.9,28,6,28,6,8.4,5.8,11.2,15.4,12,18,2,6.9,0,8,1,21,1.1,14.3,3.8,15.7,2,22-1.8,6.1-4.5,5.5-11,16-3.5,5.7-5.4,10.3-9,19"
          />
          <path
            id="upper_leg_2"
            data-name="upper leg 2"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
            d="M212,369.7c1.9-3.4,8-155.3,13.5-168.6"
          />
          <path
            id="upper_leg_3"
            data-name="upper leg 3"
            class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
            d="M143.6,190.9c.4,5.8-32.6,40.8.4,48.2"
          />
          <g id="inner_lines_upper_leg" data-name="inner lines upper leg">
            <path
              id="inner_lines_upper_leg_1"
              data-name="inner lines upper leg 1"
              class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
              d="M164.6,171.8c5.4-9,8.9-17.1,11-23,3.5-9.7,5.2-14.5,5-21-.3-8.2-3.3-10.4-2-17,1.5-7.9,7.7-14.7,10.7-13.8,3.6,1.1.1,12.9,6.3,16.8,3.1,1.9,7.1,1,11,0,8.2-2,9.1-6,15-7,5.8-1,13.2,1.5,16.4,6.5,1.8,2.8,1.7,5.4-.4,20.5-2.2,15.7-2.8,18-3,23-.2,5.1.2,12.3,2.4,21"
            />
            <line
              id="inner_lines_upper_leg_2"
              data-name="inner lines upper leg 2"
              class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls10"
              x1="196.3"
              y1="131.7"
              x2="177.3"
              y2="143.1"
            />
          </g>
        </g>
      </g>
      <g id="OSmitHuefte-2" data-name="OSmitHuefte">
        <path
          class="Hip2349Fill"
          style={{
            fill: props.colors.Hip2349Fill,
            stroke: props.colors.Hip2349Fill,
          }}
          d="M152-.3C89.5.7,34.4.3,0,.7c1,51.5-.5,89.3-.4,162.2,13.2,12.7,26.2,12.5,41.2,4.2,17.1-7.3,28.5-17.8,36.5-30,4.6-7.8,7.7-17.9,9.8-29.4-14.2-14.5-19-30.6-15.2-48.2,8-15.3,20.8-25.3,41.9-26.5,10-.3,17.6,2.5,23.3,7.7,3-1.4,5.5-3.7,6.9-8.5,1.5-3.8-.1-8.7-2.9-14,2.7-8.2,6.3-13.5,11-18.5ZM28.9,135.5c-6,6-19.9,2.3-21.9-5.7-4.2-21.8,22.3-25,37.6-27.7-1.1,12.6-6.5,25.1-15.7,33.4Z"
        />
        <path
          id="hip_joint"
          data-name="hip joint"
          class="Hipjoint2349Fill"
          style={{
            fill: props.colors.Hipjoint2349Fill,
            stroke: props.colors.Hipjoint2349Fill,
          }}
          d="M86.5,110c4.5-3.5-24-37.1-2.2-59.5,21.9-22.4,60.4-2.5,53.8-13.7-9.9-16.8-47.4-16.2-63.7.4-42,42.8,1.6,80.9,12,72.8h0Z"
        />
        <path
          id="upper_leg_overlay"
          data-name="upper leg overlay"
          class="Oberschenkel2349Fill"
          style={{
            fill: props.colors.Oberschenkel2349Fill,
            stroke: props.colors.Oberschenkel2349Fill,
          }}
          d="M137,364.7c1.9-49,3.9-85.4,6-115,1.2-15.9,3.2-41.3-1-75-1.4-11.2-3.1-20.4-4.4-26.6-3.2-26.2-44.9-35.8-51.3-40.1-7.1-4.7-9.8-12.1-12-18.2-2.7-7.4-6.8-18.6-2-31,5.1-13.3,17-19.1,21-21,3.4-1.6,13.3-6.2,26-4,8.1,1.4,13.9,4.9,17,7.2.2,2.2,4.8,1.6,9,5,14,35.8,42.5,46.9,44.3,51.1,2.1,1.7,5.8,3.3,13,2,23.5,2.9,28,6,28,6,8.4,5.8,11.2,15.4,12,18,2,6.9,0,8,1,21,1.1,14.3,3.8,15.7,2,22-1.8,6.1-4.5,5.5-11,16-3.5,5.7-5.4,10.3-9,19-5.5,28.5-9.5,99.4-13.6,167.9l-75-4.3h0Z"
        />
        <path
          id="neck_of_the_femur"
          data-name="neck of the femur"
          class="Schenkelhals2349Fill"
          style={{
            fill: props.colors.Schenkelhals2349Fill,
            stroke: props.colors.Schenkelhals2349Fill,
          }}
          d="M145.3,46l-53.1,65.1c23.6,5.6,42.8,20.1,46.2,38.2l46.9-55.8c-19.7-12.6-32.8-27.2-40.1-47.4Z"
        />
        <path
          id="trochanter_major_overlay"
          data-name="trochanter major overlay"
          class="Trochanter_major2349Fill"
          style={{
            fill: props.colors.Trochanter_major2349Fill,
            stroke: props.colors.Trochanter_major2349Fill,
          }}
          d="M202.6,99.1l.4,12c.4,12.1,5.9,23.6,15.1,31.5l27.5,23.6c3.3-4.1-4.4-15.9-2.4-36.4.2-1.8,0-3.7-.5-5.4-2.9-9.6-6.9-16-12-19.2-7.4-3.2-16.9-5.1-28-6h0Z"
        />
        <path
          id="trochantur_minor_overlay"
          data-name="trochantur minor overlay"
          class="Trochanter_minor2349Fill"
          style={{
            fill: props.colors.Trochanter_minor2349Fill,
            stroke: props.colors.Trochanter_minor2349Fill,
          }}
          d="M143.8,193.4c-7.4,10.5-12.7,19.9-14.1,27.2-2.3,9.3,2.5,15.4,14.3,18.5.7-14.7.5-30-.2-45.7h0Z"
        />
      </g>
      <g id="outlines">
        <path
          id="outlinepart2"
          class="Weichteilmantel2349Fill"
          style={{
            fill: props.colors.Weichteilmantel2349Fill,
            stroke: props.colors.Weichteilmantel2349Fill,
          }}
          d="M256.9-1.3c17.2,53.2,20.7,71.7,21.5,90.2,3.2,115.8-6.4,212.4-13.8,279h-3.8c7.3-66.7,16.9-163.2,13.8-279-.8-18.5-4.3-37-21.5-90.2h3.8Z"
        />
        <path
          id="outlinepart1"
          class="Weichteilmantel2349Fill"
          style={{
            fill: props.colors.Weichteilmantel2349Fill,
            stroke: props.colors.Weichteilmantel2349Fill,
          }}
          d="M37.3,369C20.6,315.3,5.8,248.3-.4,208h-3.1c6.2,40.3,21,107.3,37.7,161h3.1Z"
        />
      </g>
      <g id="outlinehighlights">
        <path
          id="outlinehighlight2"
          class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls12"
          d="M-.9,205c6.2,40.3,21.1,107.3,37.8,161"
        />
        <path
          id="outlinehighlight1"
          class="Linker_Oberschenkel_mit_Hufte_vd_stehend2349_cls12"
          d="M253.1-3.3c17.2,53.2,20.7,71.7,21.5,90.2,3.2,115.8-6.4,212.4-13.8,279"
        />
      </g>
    </svg>
  );
}
