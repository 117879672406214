import style from "./style.scss";

export default function GesichtsschadelLatSitzend1912(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 266.19 397.01">
      <g id="skull_overview" data-name="skull overview">
        <g id="background_layer" data-name="background layer">
          <path
            id="background1"
            class="Gesichtsschädel_lat_sitzend1912_cls8"
            d="M2.37,256.4l16.65,9.19c3.25,2.34,5.09,1.28,5.6-3,3.99-12.35,11.28-20.73,7.96-30.7-.61-1.81-2.36-3-4.27-2.83h0c-9.62.88-18.65,1.11-26.57.13"
          />
          <path
            id="background2"
            class="Gesichtsschädel_lat_sitzend1912_cls8"
            d="M19.03,265.59c-.59-2.8-.29-5.35,1.8-7.4.9-2.43.3-4.99-.9-7.61-.34-11.48,2.88-18.08,8.4-21.52"
          />
          <path
            id="background3"
            class="Gesichtsschädel_lat_sitzend1912_cls8"
            d="M2.46,291.56l11.57,7.43,4.73-7.8-1.88-8.87c-.29-1.36-.11-2.75.4-4.05,1.59-4.17-.05-9.52-3.26-15.45-1.74-6.15.46-13.55,3.6-21.24,7.77-12.97,7.68-19.17,1.14-19.81-8.81.65-13.67,4.89-16.93,10.59"
          />
          <path
            id="background4"
            class="Gesichtsschädel_lat_sitzend1912_cls8"
            d="M2.55,278.87c1.17-2.39,3.38-3.98,6.67-4.79,2.55-.63,4.31-3.02,3.89-5.61-.09-.55-.23-1.11-.41-1.66-.27-.81-.84-1.49-1.55-1.97-2.46-1.64-4.67-2.69-6.61-3.12-.7-.15-1.41-.18-2.09-.11"
          />
          <path
            id="background5"
            class="Gesichtsschädel_lat_sitzend1912_cls8"
            d="M2.42,294.6c-.18,10.87-.5,21.76-.76,32.63"
          />
          <path
            id="background6"
            class="Gesichtsschädel_lat_sitzend1912_cls8"
            d="M1.66,327.23l6.18-17.84c4.41-5,4.41-8.75-3-10.4-.32-2.07-1.17-3.48-2.41-4.39"
          />
          <path
            id="background7"
            class="Gesichtsschädel_lat_sitzend1912_cls8"
            d="M7.82,309.4c.07,3,1.06,5.28,5.36,5,4.13,5.24,5.14,9.53,2.84,12.8-1.73.77-3-.41-4.1-2.4-2.27-3.9-5-5.07-8.15-3.68l4.06-11.72h0Z"
          />
          <path
            id="background8"
            class="Gesichtsschädel_lat_sitzend1912_cls8"
            d="M45.63,345.21c-9.22-9.35-17.11-18.73-22.06-28.2-4.26.24-4.67,2.68-3.54,6.01.21,3.51,6.3,9.11,12.8,14.85l6.81,8.95"
          />
          <path
            id="background9"
            class="Gesichtsschädel_lat_sitzend1912_cls8"
            d="M51.43,345.21c-7.88-11.42-13.02-22.26-14.01-32.2-.67-4.52-2.41-4.64-4.87-1.8-1.09,2.79-1.5,5.72-1.13,8.8l15.01,25.21"
          />
          <path
            id="background10"
            class="Gesichtsschädel_lat_sitzend1912_cls8"
            d="M217.35,120.41c6.56-6.63,8.14-13.93,6.58-21.66,3.64-10.49,2.42-22.7-1.21-35.76.34-10.57-1.85-22.66-5.39-35.56-2.57-7.55-5.43-14.46-8.49-20.87-3.98-.55-7.99-.83-12.02-.86-.24,0-.46-.02-.68-.05-3.42.66-9.51.91-13.87.05h-69.42c-10.99.45-21.98.28-32.97-.11-7.8.06-15.61.11-23.4.11-.12,0-.23-.02-.34-.02s-.21.02-.32.02h-8.24c-1.21.12-2.43.2-3.65.26-.06.02-.11.05-.15.07-12.23,4.19-23.36,10.65-33.45,18.54-.52.77-1.26,1.43-2.13,1.89-1.08.57-2.49,1.9-3.36,2.98-.35.42-.67.87-.99,1.31-1.22,3.8-2.09,7.72-2.59,11.62-.02.12-.04.22-.06.33-.21,12.13-.43,24.25-.63,36.38-.76,46.62-.42,93.13.92,139.74,4.87,1.88,10.64-.05,17.32-5.83,4.18-4.91,8.46-9.32,13.21-11.53,2.84,17.3,4.02,35.48,3.91,54.34.42,29.04,6.76,45.62,18.7,50.35,1.28,17.47,12.73,24.36,35.92,33.78l83.3,34.22c5.43.49,9.2-2.27,12.08-6.77l13.17-47.2c4.15-6.78,6.43-13.16,5.19-18.82l4.85-10.86,2.5-4.8c.46,4.58,1.35,7.63,3.33,6.85,3.51-3.97,3.18-12.68,1.46-23.14l1.98.2c-6.98-22.3-11.41-40.45-3.82-48.4-7.47-3.56-9.04-13.22-6.86-26.75l1.23-16.95,2.31,1.69c.81-6.8,3.61-12.39,7.71-17.2.41-6.27,2.34-10.5,5.19-13.48l4.36-.99-11.12-27.14h-.02Z"
          />
        </g>
        <g id="skull">
          <g id="eye_muscle" data-name="eye muscle">
            <path
              id="eye_muscle1"
              data-name="eye muscle1"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M194.06,97.38c-10.48,12.59-21.06,17.59-31.72,17.6-5.62-1.41-10.08,2.68-13.35,12.46-8.44,19.41-12.63,34.47-2.21,34.64-4.92,3.66-3.52,6.81,4.22,9.48,4.69,1.61,9.97-.51,12.04-5.02.02-.04.03-.07.05-.11"
            />
            <path
              id="eye_muscle2"
              data-name="eye muscle2"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M178.93,172.93c-3.56,3.08-8.19,3.81-13.53,2.94-1.03,3.48-3.03,4.23-6.65,2.17-6.99,1.6-10.07-1.91-7.74-6.49"
            />
            <path
              id="eye_muscle3"
              data-name="eye muscle3"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M164.32,170.5c3.99-.92,6.38-3.57,7.18-7.95"
            />
            <path
              id="eye_muscle4"
              data-name="eye muscle4"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M187.01,118.25c-2.15.7-2.96-.21-2.43-2.76-1.34,3.41-2.81,4.13-4.47,1.02-.98,2.54-2.12,2.5-3.38.4-2.07,2.04-3.34,1.85-3.86-.5"
            />
            <path
              id="eye_muscle5"
              data-name="eye muscle5"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M171.6,158.67c.92-3.42.28-4.99-2.07-4.52-1.74,1.03-2.98,2.41-3.78,4.11-1.99-2.82-3.86-3.69-5.53-1.5-1.41,2.29-1.64,4.29.45,5.72-2.73-2.51-4.71-2.47-5.58,1.37-.23-3.28-1.74-4.32-4.59-3.05,1.8-1.21,2.23-2.88.71-5.21-1.76-3.07-3.69-5.64-6.46-5.72-2.28-.17-3.48.95-4.03,2.85"
            />
            <path
              id="eye_muscle6"
              data-name="eye muscle6"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M157.21,152.64c-2.03-.87-4.05.29-6.04,2.42,2.01,2.52-.38,5.18,2.85,5.75l4.74-1.2c1.89-3.2.09-4.82-1.55-6.97h0Z"
            />
            <path
              id="eye_muscle7"
              data-name="eye muscle7"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M143.35,141.8c1.9-1.04,3.69-1.4,5.12.96.48,1.11.73,2.21.66,3.28-.18,2.38-2.43,4.16-4.8,3.85-1.23-.17-2.1-.94-2.71-2.15l1.74-5.94h0Z"
            />
            <path
              id="eye_muscle8"
              data-name="eye muscle8"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M145.24,136.31c-.46,1.1-.55,2.34-.1,3.44.37.89.93,1.43,1.82,1.49,2.68,1.17,4.69.67,6.02-1.58,1.29-1.23,1.52-3.34.6-6.4-.65-2.6-2.18-3.24-4.16-2.9-1.14.19-2.09.98-2.54,2.05l-1.64,3.9h0Z"
            />
            <path
              id="eye_muscle9"
              data-name="eye muscle9"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M151.07,122.29c.26,2.76,1.11,4.61,3.54,4.07,1.61-1.16,3.27-1.43,4.95-.75,2.63-.51,3.5-2.03,3.28-4.24l-.16-6.39"
            />
            <path
              id="eye_muscle10"
              data-name="eye muscle10"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M154.95,129.89c.39-.59.53-1.18.46-1.76-.15-1.32-1.48-2.28-2.8-2.02-.57.11-.97.46-1.16,1.14-.61.99-.8,1.98-.04,2.93.55.7,1.42,1.09,2.69,1.04"
            />
            <path
              id="eye_muscle11"
              data-name="eye muscle11"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M166.78,119.38c1.11.48,1.47,1.43,1.52,2.55-.06-2.45.51-4.28,3.01-4.25,1.67.03,2.37,1.11,2.5,2.81.07,3.33-1.73,4.24-4.67,3.64-1.44,4.4-3.93,3.43-6.9,0"
            />
            <path
              id="eye_muscle12"
              data-name="eye muscle12"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M159.56,136.67c-1.91-.61-3.79-1-5.36.42-.17,1.65-.93,2.89-2.21,3.81,0,3.41,1.52,5.7,4.94,6.54,3.08.41,6.01.02,8.76-1.36"
            />
            <path
              id="eye_muscle13"
              data-name="eye muscle13"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M171.59,124.59c-2,.04-2.73,1-2.6,2.57,1.21,1.27,1.18,2.75.08,4.41.25,2.65,1.63,3.54,4.15,2.66,2.48.28,3.51-.63,3.2-2.63"
            />
            <ellipse
              id="eye_muscle14"
              data-name="eye muscle14"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="167.83"
              cy="129.55"
              rx="1.8"
              ry="2.04"
              transform="translate(-9.67 13.6) rotate(-4.51)"
            />
            <circle
              id="eye_muscle15"
              data-name="eye muscle15"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="172.56"
              cy="136.68"
              r="2.19"
            />
            <circle
              id="eye_muscle16"
              data-name="eye muscle16"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="171.6"
              cy="144.83"
              r="1.02"
            />
            <circle
              id="eye_muscle17"
              data-name="eye muscle17"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="171.66"
              cy="149.6"
              r="1.83"
            />
            <circle
              id="eye_muscle18"
              data-name="eye muscle18"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="174.01"
              cy="126.14"
              r="2.24"
            />
            <ellipse
              id="eye_muscle19"
              data-name="eye muscle19"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="180.55"
              cy="119.88"
              rx="1.7"
              ry="2"
              transform="translate(-8.87 14.57) rotate(-4.51)"
            />
            <path
              id="eye_muscle20"
              data-name="eye muscle20"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M185.8,120.76c.03,2.25,1.06,2.87,3.04,1.98"
            />
            <path
              id="eye_muscle21"
              data-name="eye muscle21"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M166.07,143.04c-1.14,2.75-.49,4.05,1.94,3.9"
            />
            <path
              id="eye_muscle22"
              data-name="eye muscle22"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M158.23,147.73c-1.35.75-1.69,2.39-1.02,4.92"
            />
            <path
              id="eye_muscle23"
              data-name="eye muscle23"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M181.5,123.87c.27,3.27,1.74,4.01,4.39,2.22"
            />
            <path
              id="eye_muscle24"
              data-name="eye muscle24"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M168.65,134.8c.84-.73.99-1.46.65-2.17s-1.03-1.13-1.77-1.29c-2.22-.47-3.67,0-3.71,2.22-.15,2.05.97,2.69,2.75,2.63-1.01.84-1.24,2.08-.26,3.97.69,1.07,1.77.88,3.12-.25"
            />
            <path
              id="eye_muscle25"
              data-name="eye muscle25"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M179.11,128.37c-2.23-.31-2.95.71-2.73,2.55.65,2.09,2.03,2.25,4.17.47"
            />
            <path
              id="eye_muscle26"
              data-name="eye muscle26"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M179.04,126.63c-2.54.99-2.97-.43-2.93-2.39-2.14-1.25-2.46-2.73-.36-4.51,2.37-1.37,3.08.04,2.97,2.83"
            />
            <ellipse
              id="eye_muscle27"
              data-name="eye muscle27"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="172.67"
              cy="140.58"
              rx="1.86"
              ry="1.33"
              transform="translate(-10.52 14.01) rotate(-4.51)"
            />
            <path
              id="eye_muscle28"
              data-name="eye muscle28"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M176.72,135.22c.98,2,1.8,1.94,2.45-.19"
            />
          </g>
          <g id="middle_section" data-name="middle section">
            <path
              id="middle_section1"
              data-name="middle section1"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M202.33,130.15c11.11,1.47,16.98,12.25,26.42,16.99-1.76-8.68-8.09-15.55-10.12-24.14-.48-1.89-1.45-3.82.48-5.74,3.86-3.86,4.82-8.68,5.78-13.49,1.93-16.39,0-30.84-2.89-47.23,0-3.86.96-7.71,0-11.57-2.89-13.49-5.78-25.06-12.27-37.05"
            />
            <path
              id="middle_section2"
              data-name="middle section2"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M197.35,10.86c6.79,15.95,10.62,34.06,7.41,51.34-1.65,8.86-4.89,17.43-7.45,26.04-2.43,8.16-5.95,16.66-7.21,25.1-.66,4.4.4,12.72,6.72,11.35"
            />
            <path
              id="middle_section3"
              data-name="middle section3"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M209.86,66.14c-2.04,7.07-7.03,11.08-13.66,13.39-7.25,6.46-16.33,8.43-25.01,15.35-29.73,18.89-53.06,29.87-63.1,24.38-5.81-2.66-11.94-2-18.35,1.45-4.4,1.99-6.97,4.23-6.41,6.93"
            />
            <path
              id="middle_section4"
              data-name="middle section4"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M182.89,93.96c-12.09-4.04-26.39.28-41.84,8.92-19.42,4.74-32.75,12.47-46.29,13.55-13.6,3.71-17.33,6.79-13.45,9.35l4.26,3.14c-3.89,10.1-8.69,12.77-14.22,9.69-4.76-3.28-2.86-6.7,1.87-10.18,2.79-2.32,3.05-4.31-1.78-5.61-3.75-2.46-4.96,1.92-6.2,6.24-3.33,3.7-4.1,8.92-3.64,14.86-2.56,4.66-8.01,12.03-14.38,20.27"
            />
            <path
              id="middle_section5"
              data-name="middle section5"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M140.51,123.13c-4.35,2.59-10.71,2.51-17.81,1.41-19.54-3.85-30.91-2.22-33,5.65-3.15,11.18-9.27,13.96-18.33,8.42"
            />
            <path
              id="middle_section6"
              data-name="middle section6"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M136.25,106.46c4.5,3.4,6.53,7.82,2.24,14.81,2.13,13.45-1.33,23.98-9.51,32.06-12.12,9.23-19.62,16.31-20.6,20.36"
            />
            <path
              id="middle_section7"
              data-name="middle section7"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M60.18,163.17c9.01-3.29,18.4-6.71,28.61-10.43,14.76-3.3,27.89-9.14,32.57-28.1-3.52-7.17-2.07-12.88,4.3-17.16-7.56-1.95-12.78-4.44-14.29-7.81"
            />
            <path
              id="middle_section8"
              data-name="middle section8"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M224.11,148.54c-3.22,2.12-4.7,7.01-5.19,13.48-3.93,5.77-7.22,11.53-7.71,17.2-2.97-3.2-6.06-4.4-9.3-3.02"
            />
            <path
              id="middle_section9"
              data-name="middle section9"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M114.69,175.86c6.44-6.6,12.95-9.98,19.52-10.37"
            />
            <path
              id="middle_section10"
              data-name="middle section10"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M74.41,160.34c-8.14,1.15-8.48-2.29-8.75-5.78-.42-3.91,4.46-6.69,15.41-8.15,10.46-2.79,13.84-8.13,13.82-14.7,1.75-6.1,4.69-5.65,8.09-2.57,1.69-4.48,4.65-5.13,7.86-2.2,5.35-1.39,5.27,2.91,3.4,9.1l-5.11,13.48c-6.61,7.78-25.59,15.85-34.73,10.84h0Z"
            />
            <path
              id="middle_section11"
              data-name="middle section11"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M118.67,174.92c-5.04,11.13-5.91,21.61-1.58,31.25,3.53,1.47,3.41-1.51,2.24-5.79-2.6-8.37-.73-15.96,4.06-23.07,5.22-.91,9.88-4.3,13.89-10.46"
            />
            <line
              id="middle_section12"
              data-name="middle section12"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              x1="144.18"
              y1="169.67"
              x2="140.87"
              y2="212.59"
            />
            <path
              id="middle_section13"
              data-name="middle section13"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M178.99,193.93c-6.57,17.77-9.86,31.12-4.87,33.93l9.16.24c17.12-22.63,28.63-41.76,24.93-51.39"
            />
            <path
              id="middle_section14"
              data-name="middle section14"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M190.49,125.2c-9.05,9.17-14.87,20.09-14.19,34.55,1.66,11.97,5.98,19,15.01,17.28,5.64-7.61,7.24-17.44,7.7-27.89"
            />
            <path
              id="middle_section15"
              data-name="middle section15"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M198.08,99.98c-2.06-3.79-7.18-2.85-12.44-1.7-23.93,6.68-44.25,15.89-62.95,26.24"
            />
            <path
              id="middle_section16"
              data-name="middle section16"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M198.1,120.58c-1.07-3.44.13-5.32,4.06-5.33,3.07-.16,4.89.78,4.32,3.81,0,3.18-1.03,5.25-3.82,5.46-2.69,0-3.87-1.7-4.56-3.92h0Z"
            />
            <path
              id="middle_section17"
              data-name="middle section17"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M168.68,176.2l7.3,2.21c1.38.41,2.82.47,4.25.27,2.63-.38,5.05-.15,7.22.77,1.3.55,2.73.72,4.12.49,8.16-1.37,15.55-2.16,15.63,2.4"
            />
            <path
              id="middle_section18"
              data-name="middle section18"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M2.39,137.38c24.91,3.98,39.37,26.15,59.61,38.68,3.86,2.89,7.71,4.82,13.87,2.85"
            />
            <path
              id="middle_section19"
              data-name="middle section19"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M214.66,62.56c2.8,1.55,5.06,4.77,6.17,11.69.17,1.09.36,2.19.57,3.27.86,4.27.11,7.31-2.15,9.21.27,13.33-1.81,19.51-6.32,18.26"
            />
            <path
              id="middle_section20"
              data-name="middle section20"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M204.06,78.64c6.88,1.38,11.19,7.61,8.87,26.32-1.08,8-4.23,10.76-8.86,9.79-4.05-1.93-6.08-4.95-7.64-8.25"
            />
            <path
              id="middle_section21"
              data-name="middle section21"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M186.18,70.42c-1.33,7.5-4.82,12.73-12.06,14.06l-17.23,3.11"
            />
            <path
              id="middle_section22"
              data-name="middle section22"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M117.21,99.14c9.48,1.72,18.6,3.22,18.35-1.38,7.13-7.17,14.62-1.22,24.36-13.49"
            />
            <path
              id="middle_section23"
              data-name="middle section23"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M212.24,25.02c5.85,14.7,9.22,28.51,5.27,39.72-4.98,4.67-3.31,15.77-10.19,20.07-3.91,7.84-9.97,19.88-17.29,34.41"
            />
            <path
              id="middle_section24"
              data-name="middle section24"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M150.94,197.89l.69,8.77c6.54,11.66,7.95,21.22-2.51,25.88-16.97.32-29.04-1.55-28.89-8.89l-1.04-13.16"
            />
          </g>
          <g id="ear_section_1." data-name="ear section 1.">
            <ellipse
              id="ear_section_1.1"
              data-name="ear section 1.1"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="31.98"
              cy="164.44"
              rx=".95"
              ry="1.42"
              transform="translate(-12.83 3.02) rotate(-4.51)"
            />
            <circle
              id="ear_section_1.2"
              data-name="ear section 1.2"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="36.63"
              cy="185.99"
              r=".7"
            />
            <ellipse
              id="ear_section_1.3"
              data-name="ear section 1.3"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="12.36"
              cy="149.09"
              rx=".9"
              ry="1.28"
              transform="translate(-11.68 1.43) rotate(-4.51)"
            />
            <ellipse
              id="ear_section_1.4"
              data-name="ear section 1.4"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx=".74"
              cy="164.63"
              rx=".2"
              ry=".5"
              transform="translate(-12.94 .57) rotate(-4.51)"
            />
            <circle
              id="ear_section_1.5"
              data-name="ear section 1.5"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="10.34"
              cy="163.36"
              r="1.38"
            />
            <ellipse
              id="ear_section_1.6"
              data-name="ear section 1.6"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="16.38"
              cy="157.01"
              rx=".78"
              ry="1.43"
              transform="translate(-12.3 1.77) rotate(-4.51)"
            />
            <circle
              id="ear_section_1.7"
              data-name="ear section 1.7"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="13.85"
              cy="168.2"
              r=".9"
            />
            <circle
              id="ear_section_1.8"
              data-name="ear section 1.8"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="25.51"
              cy="161.15"
              r=".38"
            />
            <path
              id="ear_section_1.9"
              data-name="ear section 1.9"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M.6,180.42c1.37-.14,2.37-1.51,2.25-3.1-.12-1.45-1.12-2.56-2.33-2.7.03,1.93.06,3.87.09,5.79h0Z"
            />
            <circle
              id="ear_section_1.10"
              data-name="ear section 1.10"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="25.35"
              cy="178.91"
              r=".41"
            />
            <ellipse
              id="ear_section_1.11"
              data-name="ear section 1.11"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="22.46"
              cy="167.23"
              rx="2.45"
              ry="2.6"
              transform="translate(-13.08 2.28) rotate(-4.51)"
            />
            <ellipse
              id="ear_section_1.12"
              data-name="ear section 1.12"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="16.43"
              cy="182.27"
              rx="1.15"
              ry="1.33"
              transform="translate(-14.28 1.86) rotate(-4.51)"
            />
            <circle
              id="ear_section_1.13"
              data-name="ear section 1.13"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="18.86"
              cy="186.81"
              r=".93"
            />
            <ellipse
              id="ear_section_1.14"
              data-name="ear section 1.14"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="3.75"
              cy="191.37"
              rx="1.7"
              ry="1.12"
              transform="translate(-15.04 .89) rotate(-4.51)"
            />
            <ellipse
              id="ear_section_1.15"
              data-name="ear section 1.15"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="17.43"
              cy="191.71"
              rx="1.7"
              ry="1.41"
              transform="translate(-15.02 1.96) rotate(-4.51)"
            />
            <ellipse
              id="ear_section_1.16"
              data-name="ear section 1.16"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="25.4"
              cy="196.23"
              rx="1.8"
              ry="1.46"
              transform="translate(-15.35 2.6) rotate(-4.51)"
            />
            <circle
              id="ear_section_1.18"
              data-name="ear section 1.18"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="23.99"
              cy="200.68"
              r=".75"
            />
            <circle
              id="ear_section_1.19"
              data-name="ear section 1.19"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="16.08"
              cy="196.43"
              r=".9"
            />
            <ellipse
              id="ear_section_1.20"
              data-name="ear section 1.20"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="19.09"
              cy="201.27"
              rx=".92"
              ry="1.45"
              transform="translate(-15.77 2.12) rotate(-4.51)"
            />
            <ellipse
              id="ear_section_1.21"
              data-name="ear section 1.21"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="13.21"
              cy="199.54"
              rx="1.38"
              ry="1.99"
              transform="translate(-15.65 1.66) rotate(-4.51)"
            />
            <ellipse
              id="ear_section_1.22"
              data-name="ear section 1.22"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="8.08"
              cy="195.91"
              rx=".55"
              ry=".85"
              transform="translate(-15.38 1.24) rotate(-4.51)"
            />
            <circle
              id="ear_section_1.23"
              data-name="ear section 1.23"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="3.75"
              cy="197.15"
              r=".65"
            />
            <circle
              id="ear_section_1.24"
              data-name="ear section 1.24"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="6.63"
              cy="201.48"
              r="1.32"
            />
            <circle
              id="ear_section_1.25"
              data-name="ear section 1.25"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              cx="9.89"
              cy="204.64"
              r=".59"
            />
            <ellipse
              id="ear_section_1.26"
              data-name="ear section 1.26"
              class="Gesichtsschädel_lat_sitzend1912_cls3"
              cx="16.41"
              cy="205.87"
              rx="1.32"
              ry="1.75"
              transform="translate(-16.14 1.93) rotate(-4.51)"
            />
            <path
              id="ear_section_1.27"
              data-name="ear section 1.27"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M27.06,180.83c-.34-3.66.7-4.98,3.47-3.38,2.03,1.9,3.63,4,2.68,7.32-.27,3.35-2.1,4.41-5.19,3.59-2.2-.87-2.15-3.7-.97-7.52h0Z"
            />
          </g>
          <g
            id="skull_seam_"
            data-name="skull seam
"
          >
            <path
              id="skull_seam_1"
              data-name="skull seam
1"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M2.28,109.01c3.6.03.47-4.72-.06-6.03.73.48,2.39,2.06,3.38,1.31.93-.7-.31-1.66.15-2.48.77-1.4.82.25,1.67.66,3.74,1.81,2.6-4.25,1.97-6.21.74,1,2.59,5.26,4.17,3.47,1.26-1.43-1.2-3.41,1.7-.91,1.01.88,2.55,2.28,3.95,1.02.95-.87.31-2.06.7-3.16,1.18-3.24,3.83-.14,4.5,1.8.04-1.65-.55-3.95.71-4.52,1.2.17,2.16,1.14,3.12-2.72,1.24.67.03.93,0,0,1.93.96,3.86,1.93,5.78,1.93,0,0,0,1.93,2.89,1.93v1.93c.96,0,1.93-1.93,2.89-1.93,1.93,0,.96,3.86,1.93,3.86s1.93-1.93,2.89-1.93.96.96,1.93.96c.96-.96,1.93-1.93,2.89-3.86,0,1.57.81,2.82,1.93,3.86.22-1.19.98-2.08,1.52-3.15-.14.23-.27.5-.44.73.13-.25.32-.48.44-.73.27.82.4,1.55.4,2.19,2.89-.96,3.86.96,4.82,2.89,0-1.93.96-2.89,1.93-3.86.96.96.96,1.93.96,2.89l1.93-1.93c0,.96,0,2.89,1.93,1.93.96-.96,1.93-1.93,2.89-1.93v2.89c.96.96,1.93-.96,2.89-1.93-.96,2.89,1.93,2.89,3.86,2.89,0,0,0,1.93-.96,1.93l.96.96,2.89-2.89v4.82h1.93s0,.96.35,1.74c.76-.47.26-4.13,0,0,2.54.18,1.58-2.71,3.51-3.67.96,1.93-1.93,3.86-.96,5.78h1.93c0,.96,0,1.93.96,2.89s1.93-.96,1.48,1.3"
            />
            <path
              id="skull_seam_2"
              data-name="skull seam
2"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M115.67,99.93c-2.38,1.78-1.35.46-1.58-.67-.4-2-.76-1.85-3.14-1.62.56-.61,5.52-2.91,5.42-3.41-.29-1.35-5.08,1-6.02.43-1.58-.95,1.01-.55.85-2.06-.17-1.58-2.5-.08-2.6-1.14s6.86-2.67,5.8-3.46-5.76,1.89-5.38-.81c.09-.61,1.7-1.13,1.53-1.82-.23-1.02-2.73-.52-3.44-.52-5.21,0,.66-2.19,2.54-3.04,5.55-2.5-1.33-.94-3.02-1.42-2.8-.78,1.7-1.22,1.67-2.82-.03-1.93-2.79-.24-3.41-.93-1.05-1.15,2.67-1.4,3.02-1.97,1.13-1.86-2.27-1.55-2.7-2.56-.74-1.76,4.46-3.07,5.45-3.88-2.12-.33-4.27.14-6.4-.14-1.58-.21-3.43-.41-1.3-1.59,1.18-.65,3.07.04,3.93-1.27.99-1.51-.33-2.46,1.92-3.54-2.17-.23-7.86,1.4-9.42-.5-1.25-1.51,1.05-2.37,2.27-2.91,2.15-.95,4.31-1.53,5.11-4.05-.44.24-.48.25-.68.5.13-.09.6-.45.68-.5-4.26,1.36-5.22-3.46-9.08-1.53.96-.96,2.89-1.93,4.82-2.89l.96-1.93c-1.93-2.89-4.82.96-6.75,0l-.96-.96c.96-1.93,3.86-1.93,4.82-2.89v-1.93h-1.93c-1.93-2.89-3.86-4.82-6.68-.74,1.71-1,6.79-5.08,2.82-6.97,2.3.15,2.89-.87,2.89-2.89-2.44.81-4.75,1.88-6.91,3.27.32-.32.58-.59.91-.82-.29.32-.53.51-.91.82,1.39-.88,6.53-4.36,3.24-6.16-1.84-1.01-4.75,1.59-6.93.96l6.75-6.75c-2.07.57-3.31.41-4.89-.95-2.09-1.81-3.21-3.37-5.81-.94,2.29-1.42,5.21-1.74,7.2-3.64-1.02.04-5.69,1.44-5.96.17-.21-1.01,4.76-3.2,5.52-3.88-.85,0-3.78,1.21-4.45.43-.34-.4.63-.77.38-1.24-1.04-2-3.23-.67-4.29-1.95-.98-1.19,2.27-1.28,3.09-1.56,1.35-.44,3.14-2.27.54-2.64"
            />
          </g>
          <g id="ear_section_2." data-name="ear section 2.">
            <path
              id="ear_section_2.1"
              data-name="ear section 2.1"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M2.46,118.54c8.77-2.55,17.74-.8,23.64,6.44s8.66,16.61,6.85,25.54"
            />
            <path
              id="ear_section_2.2"
              data-name="ear section 2.2"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M32.88,151s0,.06-.02.08c-.03.13-.05.26-.08.4"
            />
          </g>
          <g id="ear_section_3." data-name="ear section 3.">
            <path
              id="ear_section_3.1"
              data-name="ear section 3.1"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M25.39,147.72v-.48"
            />
            <path
              id="ear_section_3.2"
              data-name="ear section 3.2"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M25.41,146.26c0-5.87-1.24-12.31-6.01-16.24s-11.54-4.33-17.23-2.66"
            />
          </g>
          <g id="ear_section_4." data-name="ear section 4.">
            <path
              id="ear_section_4.1"
              data-name="ear section 4.1"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M3.42,143.99c.1-.96.23-1.92.41-2.86"
            />
            <path
              id="ear_section_4.2"
              data-name="ear section 4.2"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M5.7,135.23c2.39-4.95,6.98-9.1,15.71-11.26,3.79-.85,7.43-.81,10.94.08,9.16,2.32,15.64,10.34,16.93,19.7.54,3.9.53,8.15.11,12.67"
            />
            <path
              id="ear_section_4.3"
              data-name="ear section 4.3"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M49.04,159.51c-.13.94-.26,1.9-.41,2.86"
            />
          </g>
          <g id="ear_section_5." data-name="ear section 5.">
            <path
              id="ear_section_5.1"
              data-name="ear section 5.1"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M10.82,138.66c.65-.73,1.32-1.43,2.02-2.06"
            />
            <path
              id="ear_section_5.2"
              data-name="ear section 5.2"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M18.14,133.03c4.12-1.86,8.84-2,14.09.46,1.1.52,2.16,1.16,3.07,1.95,4.09,3.52,5.63,8.92,5.58,15.49"
            />
            <path
              id="ear_section_5.3"
              data-name="ear section 5.3"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M40.73,154.14c-.08.93-.17,1.9-.3,2.87"
            />
          </g>
          <g id="jaw_section" data-name="jaw section">
            <path
              id="jaw_section1"
              data-name="jaw section1"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M207.67,194.48c-1.64,13.67-.78,24.31,6.86,26.75-4.28,7.47-5.94,14.59-3.08,21.11l6.9,27.28-21.48,1.7-37.27-9.37c-4.83,1.31-10.57-.9-16.84-5.09-9.41-2.2-11.55-1.58-13.49-4.82l-18.9-8.95c-3.35,3.97-5.98,3.61-7.67-2.34-2.77-7.98-.07-16.29,4.74-24.72,3.73-6.98-4.26-23.48,7.34-50.86"
            />
            <path
              id="jaw_section2"
              data-name="jaw section2"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M31.43,197.74l3.76,31.11.87,33.54c.9,20.86,5.5,37.66,19.13,44.54l40.31,23.81,73.51,41.8c6.88,3.91,15.61.3,17.7-7.34l12.35-45.03c5.58-11.42,9.17-21.23.03-20.8-6.75-2.82-13.37-.99-19.89,4.38-2.51,4.7-7.35,6.77-13.72,7.1-3.99-1.76-8.13-1.85-12.35-.9-8.65-5.94-14.4-12.97-18.12-20.78-9.46-10.92-20.34-18.1-32.72-21.37-15.03-6.89-16.83-19.41-15.86-33.13"
            />
            <path
              id="jaw_section3"
              data-name="jaw section3"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M118.89,286.84l-15.3-18.16c-3.85-4.57-6.08-10.36-5.89-16.33.18-5.61,2.32-11.05,6.75-16.28,7.69-9.97,12.4-18.79,7.88-24.03-1.88-2.84-7.84.06-15.76,5.72-.49.35-.95.73-1.43,1.11-6.19,4.95-11.53,2.94-16.37-3.11-4.54-6-6.44-10.74-4.17-13.48.93-1.14,1.6-2.5,1.63-3.97.09-5.05-3.45-8-10.37-8.98-5.68-1.96-10.18-2.42-13.7-1.65-4.61,1.01-7.92,5.06-8.17,9.77-.35,6.37.86,13.75,4.34,22.65.98,2.51,1.9,5.04,2.6,7.63,4.74,17.49,6.05,37.82,3.84,61.09-2.02,18.52.19,35.21,19.78,44.46l96.9,40.33"
            />
            <path
              id="jaw_section4"
              data-name="jaw section4"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M61.77,239l17.82,44.11c9.7,19.5,11.48,28.53,36.41,46.93l27.85,19.54"
            />
            <path
              id="jaw_section5"
              data-name="jaw section5"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M155.65,226.86l6.66,2.9,3.17-1.96,9.86.1c7.06,4.04,18.03,6.08,19.68,14.01,2.32,4.9,8.35,3.22,11.34,11.41"
            />
            <path
              id="jaw_section6"
              data-name="jaw section6"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M117.77,229.73c-1.28.31-2.13,1.66-2.67,3.74-.55,4.66-2.86,9.02-6.31,13.22-4.82,3.7-5.42,7.86.2,12.69.76.66,2.39,1,4.67,1.14.48.03.94.23,1.28.57,2.46,2.46,4.73,2.63,6.78-.26.82-1.17,1.31-2.54,1.54-3.95l2.95-18.52c1.46-4.72,1.29-7.07-.56-6.95-.6.04-1.14.4-1.43.93-1.97,3.52-3.82,9.3-5.6,16.39,1-6.86,1.11-12.93,1.73-16.42.26-1.5-1.06-2.92-2.54-2.56h-.03Z"
            />
            <path
              id="jaw_section7"
              data-name="jaw section7"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M121.91,263.11c-.12-2.47,1.15-4.7,3.14-6.81,3.27-4.18,5.88-10.47,7.71-19.27,1.05-1.52,1.85-1.64,2.27.42l.94,11.92c.03.39.57.46.7.1,1.2-3.31,1.51-7.42.92-12.36,0-2.11.66-2.71,2.18-1.18,1.76,4.22,2.65,8.71,2.54,13.51l-.2,18.68c.26,3.7-1.54,5.79-6.46,5.39-1.86-.6-2.9-1.7-3.5-3.07-.42-.96-1.35-1.59-2.38-1.74-5.56-.85-8.23-2.7-7.86-5.61h0Z"
            />
            <path
              id="jaw_section8"
              data-name="jaw section8"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M140.9,275.73c-2.62-2.63-2.96-5.49-.35-8.67,2.64-4.11,4.41-8.99,4.72-15.15.28-6.09.69-11.73,2.08-13.82.56-.84,1.85-.59,2.17.37,1.38,4.09,1.93,8.71,1.96,13.67l-.08,6.91c0,.44.6.58.78.17l.97-2.17c1-2.24,1.41-4.7,1.18-7.15-.32-3.31-.42-6.21.09-8.01.37-1.29,2.13-1.47,2.79-.3,1.03,1.85,1.64,5,1.91,9.19l.29,15.23c.74,6.54,1.04,12.54-1.86,14.59-2.01,2.66-4.52,3.17-7.4,1.99-1.61-.4-2.34-1.81-2.73-3.6l-6.51-3.23v-.02h0Z"
            />
            <path
              id="jaw_section9"
              data-name="jaw section9"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M154.79,277.18c-.93,1.04-2.02,1.52-3.5.62-2.1-.94-3.37-.5-3.88,1.18"
            />
            <path
              id="jaw_section10"
              data-name="jaw section10"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M105.59,260.99c2.15-4.42,4.51-4.57,6.95-2.97.56.37.96.93,1.18,1.57.92,2.81,2.75,4.85,6.16,5.55,4.46,2.71,5.55,5.86,1.42,9.7-3.56,1.47-7.29,5.05-11.08,9.53-3.43,3.77-7.4,5.34-11.72,5.49-.43.02-.65-.51-.33-.8,4.04-3.75,7.05-7.75,8.65-12.07.13-.36-.31-.65-.59-.4-4.16,3.8-8.19,6.51-12.03,7.62-.84.24-1.59-.57-1.34-1.41,1.26-4.2,4.73-8.29,9.16-12.33l3.61-9.51h-.02Z"
            />
            <path
              id="jaw_section11"
              data-name="jaw section11"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M110.02,298.04c.26-4.33,2.62-7.68,6.2-10.46,3.57-2.66,6.55-5.61,8.32-9.15,1.55-4.91,4.03-7.86,8.92-5.72,1.63.65,2.68,1.78,2.69,3.79,0,.61.62.98,1.18.74,1.1-.47,2.19-.7,3.28-.65,2.27.11,4.2,1.72,4.92,3.87.4,1.17.44,2.38-.08,3.7-.46,1.18-1.32,2.14-2.33,2.89-4.62,3.4-8.73,6.89-11.27,10.66-.22.33-.49.63-.81.87-5.06,3.79-9.02,5.96-11.23,5.54-.66-.13-1.08-.78-.94-1.44.6-2.84,3.87-7.25,7.14-11.64.13-.16-.07-.39-.25-.28l-7.25,4.14c-1.07.62-2,1.47-2.65,2.53-1.19,1.94-2.44,3.49-3.9,3.81-1.57,0-2.23-1.05-1.92-3.19h0Z"
            />
            <path
              id="jaw_section12"
              data-name="jaw section12"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M132.71,316.05c-2.75,1.13-3.62.25-3.48-1.74.09-1.19.53-2.3,1.18-3.31,1.73-2.66,4.2-5.38,7.17-8.14,1.62-1.5,2.82-3.41,3.52-5.51,2.12-6.37,4.15-11.35,5.91-11.3,2.84-.82,5.41-.1,7.54,3,.38.55,1.02.76,1.69.67.84-.11,1.72.37,2.63,1.44.4.46.94.74,1.55.79,2.6.22,4.77,1.61,5.8,6.05,1.82,3.43-2.37,6.64-9.74,9.74-4.67,5.3-9.33,9.87-13.93,11.8-4.53,1.67-6.38,1.41-5.47-.84l8.46-10.25c.96-1.18,1.73-2.51,2.24-3.93l.02-.06c.16-.46-.41-.84-.77-.48l-8.14,8.16-6.18,3.89h0Z"
            />
            <path
              id="jaw_section13"
              data-name="jaw section13"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M166.31,244.71c-1.97,4.37-2.9,9.28-2.84,14.72-2.8,7.41-4.41,13.95-1.59,17.19,1.7,1.9,3.87,2.55,6.64,1.62,2.9-.68,3.87-3.44,3.33-7.82l-1.14-21.52c-.03-.45-.67-.55-.81-.12-.81,2.45-1.06,6.44-1.19,10.79l-1.65-14.74c-.05-.4-.59-.48-.76-.12h0Z"
            />
            <path
              id="jaw_section14"
              data-name="jaw section14"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M155.48,286.03c0-4.58,1.78-5.82,4.4-5.49l8.37-.66c1.22-.03,2.13.79,2.8,2.27.71,1.57.83,3.35.46,5.05-3.09,14.32-6.68,26.39-10.83,35.7-3.9-7.08-5.45-19.8-5.18-36.85v-.02h0Z"
            />
            <path
              id="jaw_section15"
              data-name="jaw section15"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M178.66,246.57c1.47,3.89,2.59,7.83,3.07,11.83,3.22,11.34,5.56,21.48,1.08,22.12,1.2,7.19.48,9.9-2.34,7.64-3.62-2.23-4.3-5.61-4.99-8.99l-.78-9.85c.1-4.45,1.07-8.55,2.98-12.27-.62-3.25-.44-6.74.31-10.43.07-.34.54-.37.67-.05h0Z"
            />
            <path
              id="jaw_section16"
              data-name="jaw section16"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M170.9,310.03l1.05-21.96c.3-3.5,1.38-4.96,3.3-4.23l7.36.12c1.01.64,1.25,1.91.51,4-2.1,12.15-4.25,23.79-7.08,28.75-1.82,4.47-3.37,7.59-4.31,7.63-2.54.17-2.36-2.47-1.62-5.69l.77-8.63h0Z"
            />
            <path
              id="jaw_section17"
              data-name="jaw section17"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M187.14,255.63c5.17,6.63,8.65,13.22,9.38,19.73.81,2.36,1.27,4.95,1.6,7.63.13,1.06-.65,2.01-1.72,2.12-5.21.52-8.37-.84-8.78-4.67-2.79-12.23-2.18-19.34-.48-24.81h0Z"
            />
            <path
              id="jaw_section18"
              data-name="jaw section18"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M182.75,290.12c.27-1.28,1.08-2.19,2.9-2.37l6.05.43c1.22.5,1.78,1.91,1.56,4.36-.29,7.77-1.79,15.53-4.34,23.29-2.03,5.08-4.38,7.76-7.16,6.79-2.01-.7-1.72-10.32-1.28-20.5l2.26-11.99h0Z"
            />
            <path
              id="jaw_section19"
              data-name="jaw section19"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M186.23,286.94c-.82-4.14.25-6.42,2.94-7.12l5.27-.41c.67-.05,1.29.4,1.43,1.06,1.78,8.5,1.31,17.4-2.2,26.84-3.32,9.01-5.31,11.03-6.64,9.5-.56-6.69,1.52-15.84,4.23-25.56l5.87-6.17"
            />
            <path
              id="jaw_section20"
              data-name="jaw section20"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M197.56,259.62c.29-1.03,1.53-1.42,2.34-.72,2.83,2.41,5.35,8.81,7.09,24.73.34,4.29.42,7.85-.21,9.24-2.15-.26-3.68-1.8-4.9-4.02-.27-.5-.47-1.04-.57-1.6l-4.02-22.27c-.16-2.32-.1-4.14.25-5.38h0Z"
            />
            <path
              id="jaw_section21"
              data-name="jaw section21"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M189.87,320.3c4.51-9.92,6.83-18.09,8.34-25.62.15-.79.52-1.51,1.04-2.13,2.8-3.29,5.37-5.14,7.85-6.44.7-.37,1.57.04,1.74.82.23,1.06.34,2.24.26,3.58-.06.92-.33,1.8-.74,2.61-5.46,10.64-10.07,20.64-12.04,28.64"
            />
            <path
              id="jaw_section22"
              data-name="jaw section22"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M207.34,250.56c5.09,6.9,8.32,14.13,9.6,21.72.87,6.83.89,13.5-1.39,19.74-.27.74-1.33.73-1.61,0l-2.36-6.29-3.63-5.37c-1.59-8.19-2.82-15.93-2.69-21.88.04-1.75.1-3.51,0-5.26-.15-2.76.87-2.92,2.08-2.64h0Z"
            />
            <path
              id="jaw_section23"
              data-name="jaw section23"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M201.32,248.87c2.96,1.78,5.82,4.04,7.58,12.15,2.97,9.7,3.08,19.1,2,28.39-.08.67-1,.82-1.28.19l-3.98-8.93c-3.51-3.18-4.41-9.69-5.05-16.52-.14-5.74.03-10.94.73-15.28h0Z"
            />
            <path
              id="jaw_section24"
              data-name="jaw section24"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M60.09,279.96c.9,8.54-.28,12.52-3.71,11.53-3.77-1.74-5.46-5.32-5.31-10.5.07-2.31.56-4.59,1.31-6.78,3.7-10.79,8.23-20.32,14.39-27.4,12.15-14.91,26.02-24.52,43.47-22.99"
            />
            <path
              id="jaw_section25"
              data-name="jaw section25"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M60.02,260l11.19,24.8c7.07,18.34,18.74,33.67,36.37,45.09,9.68,6.7,27.5,15.44,45.77,24.29"
            />
            <path
              id="jaw_section26"
              data-name="jaw section26"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M116.83,223.31c40.29,4.06,78.79,7.58,83.55.91"
            />
            <path
              id="jaw_section27"
              data-name="jaw section27"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M4.04,219.44c5.91-.96,11.69-2.89,15.55-6.75,6.75-9.64,17.35-12.53,27.95-17.35,3.86-1.93,5.78-5.78,9.48-7.73"
            />
            <path
              id="jaw_section28"
              data-name="jaw section28"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M25.72,273.84c17.57-34.51,40.75-61.08,70.34-78.64"
            />
            <path
              id="jaw_section29"
              data-name="jaw section29"
              class="Gesichtsschädel_lat_sitzend1912_cls2"
              d="M34.79,199.08c4,.64,5.72-2.42,6.3-7.33,1.91-5.11,6.76-7.81,14.19-8.41,9.3-1.11,16.97.19,21.44,6.3,2.72,6.18,9.21,7.97,19.34,5.55,7.45-4.67,13.98-6.66,19.67-6.23"
            />
          </g>
        </g>
        <g id="spine">
          <path
            id="spine1"
            class="Gesichtsschädel_lat_sitzend1912_cls2"
            d="M2.37,256.4l16.65,9.19c3.25,2.34,5.09,1.28,5.6-3,3.99-12.35,11.28-20.73,7.96-30.7-.61-1.81-2.36-3-4.27-2.83h0c-9.62.88-18.65,1.11-26.57.13"
          />
          <path
            id="spine2"
            class="Gesichtsschädel_lat_sitzend1912_cls2"
            d="M19.03,265.59c-.59-2.8-.29-5.35,1.8-7.4.9-2.43.3-4.99-.9-7.61-.34-11.48,2.88-18.08,8.4-21.52"
          />
          <path
            id="spine3"
            class="Gesichtsschädel_lat_sitzend1912_cls2"
            d="M2.46,291.56l11.57,7.43,4.73-7.8-1.88-8.87c-.29-1.36-.11-2.75.4-4.05,1.59-4.17-.05-9.52-3.26-15.45-1.74-6.15.46-13.55,3.6-21.24,7.77-12.97,7.68-19.17,1.14-19.81-8.81.65-13.67,4.89-16.93,10.59"
          />
          <path
            id="spine4"
            class="Gesichtsschädel_lat_sitzend1912_cls2"
            d="M2.55,278.87c1.17-2.39,3.38-3.98,6.67-4.79,2.55-.63,4.31-3.02,3.89-5.61-.09-.55-.23-1.11-.41-1.66-.27-.81-.84-1.49-1.55-1.97-2.46-1.64-4.67-2.69-6.61-3.12-.7-.15-1.41-.18-2.09-.11"
          />
          <path
            id="spine5"
            class="Gesichtsschädel_lat_sitzend1912_cls2"
            d="M4.83,299c-.32-2.07-1.17-3.48-2.41-4.39"
          />
          <path
            id="spine6"
            class="Gesichtsschädel_lat_sitzend1912_cls2"
            d="M1.66,327.23l6.18-17.84c4.41-5,4.41-8.75-3-10.4"
          />
          <path
            id="spine7"
            class="Gesichtsschädel_lat_sitzend1912_cls2"
            d="M7.82,309.4c.07,3,1.06,5.28,5.36,5,4.13,5.24,5.14,9.53,2.84,12.8-1.73.77-3-.41-4.1-2.4-2.27-3.9-5-5.07-8.15-3.68l4.06-11.72h0Z"
          />
          <path
            id="spine8"
            class="Gesichtsschädel_lat_sitzend1912_cls2"
            d="M45.63,345.21c-9.22-9.35-17.11-18.73-22.06-28.2-4.26.24-4.67,2.68-3.54,6.01.21,3.51,6.3,9.11,12.8,14.85l6.81,8.95"
          />
          <path
            id="spine9"
            class="Gesichtsschädel_lat_sitzend1912_cls2"
            d="M51.43,345.21c-7.88-11.42-13.02-22.26-14.01-32.2-.67-4.52-2.41-4.64-4.87-1.8-1.09,2.79-1.5,5.72-1.13,8.8l15.01,25.21"
          />
        </g>
        <path
          id="softparts"
          class="Gesichtsschädel_lat_sitzend1912_cls1"
          d="M228.49,3.81c14.89,45.5,18.87,90.92,11.8,136.26,6.42,28.77,13.36,56.53,24.1,76.69,1.77,3.32,1.75,7.32-.16,10.56-7.38,12.5-17.4,21.74-30.68,27.01.12,22.17-4.24,36.4-13.18,42.47.78,10.74-1.26,20.99-6.82,30.63-14.54,22.13-17.14,66.82-39.55,69.06l-113.99-35.43"
        />
      </g>
      <g id="overlay">
        <path
          id="softparts_overlay"
          data-name="softparts overlay"
          class="Weichteile1912Fill"
          style={{
            fill: props.colors.Weichteile1912Fill,
            stroke: props.colors.Weichteile1912Fill,
          }}
          d="M229.28,5.77c14.25,44.69,17.95,89.29,11.02,133.83,6.42,28.77,13.36,56.53,24.1,76.69,1.77,3.32,1.75,7.32-.16,10.56-7.38,12.5-17.4,21.74-30.68,27.01.12,22.17-4.24,36.4-13.18,42.47.78,10.74-1.26,20.99-6.82,30.63-14.54,22.13-17.14,66.82-39.55,69.06l-110.76-34.43c-2.58-.8-4.51-3.12-4.54-5.82,0-1.11.29-1.98.9-2.62.85-.9,2.21-1.11,3.38-.72l101.06,33.71c9.09,3.03,19.23-.57,24-8.88,1.77-3.08,3.06-6.63,3.86-10.65l16.13-44.71c4.99-4.76,6.99-17.58,7.49-26.09,8.13-10.93,12.82-25.12,14.15-42.47,10.28-8.31,19.59-17.23,26.98-27.36,2.16-2.95,2.45-6.89.65-10.08-11.13-19.82-18.81-46.39-25.34-75.23-.16-.71-.2-1.45-.12-2.18,5.39-46.89,1.76-91.41-12.02-133.27-.5-1.52-.18-3.25,1-4.33s2.75-1.2,4.61-.09,3.17,2.93,3.83,4.98Z"
        />
        <path
          id="facial_skull_overlay"
          data-name="facial skull
 overlay"
          class="Gesichtsschädel1912Fill"
          style={{
            fill: props.colors.Gesichtsschädel1912Fill,
            stroke: props.colors.Gesichtsschädel1912Fill,
          }}
          d="M206,6.62c2.2.43,4.04,1.91,4.99,3.95,5.95,12.82,9.55,24.8,11.26,36.01.1.67.13,1.35.1,2.03l-.35,7.93c4.02,21.01,5.34,39.94.94,54.46-.64,2.11-1.79,4.02-3.33,5.6-2.89,2.96-1.32,4.96.22,9.83,3.21,7.83,8.16,15.53,8.91,20.72l-4.62,1.39c-3.59,2.63-5.04,7.29-4.89,13.45-4.75,5.88-7.72,11.98-7.72,18.44l-2.6-2.9c.88,4.77.19,10.61-1.25,16.96-1.54,13.32-1.03,24.28,6.88,26.73-4.1,6.93-5.99,13.94-3.1,21.13l6.9,27.28-1.9.15c1.66,11.07.82,21.21-1.51,22.78-.83.01-1.96-2.41-3.35-6.82-.24,3.46-.87,4.89-1.98,3.87-.52,2.5-2.89,7.09-5.34,11.77,1.58,4.3-.6,10.84-5.19,18.82l-12.38,45.02c-4.36,10.91-12.96,10.81-23.83,4.84-18.69-7.78-48.55-20.34-93.47-39.61-30.27-18.64-2.62-51.68-18.72-103.56-7.75-19.54-9.14-32.27-3-36.96,3.94-3.64,10.43-3.13,18.17-.59,8.04,1,11.91,7.85,9.4,11.37-2.35,3.5-2.02,7.09.6,10.77,6.5,10.92,13.39,13.33,20.7,6.3,5.27-4.16,10.29-7.01,14.7-6.59l4.62-3.96c.66-.56,1.52-.83,2.38-.77,1.6.12,2.26-.76,1.88-3.63-3.61-8.71-2.09-17.1,2.53-25.28,5.59-1.02,10.5-4.42,14.59-10.67l9.5-4.76c-6.52.14-6.81-5.83-5.35-13.54,6.33-22.77,11.36-35.5,20.9-33.56,9.94-.01,19.58-4.41,28.82-14.3,1.83-1.96,3.28-4.26,4.22-6.78,15.07-40.54,12.05-58.81,2.68-81.35-.48-1.15-.3-2.48.51-3.42,1.85-2.16,4.27-3.12,7.43-2.51Z"
        />
        <path
          id="hard_palate_overlay"
          data-name="hard palate

 overlay"
          class="harterGaumen1912Fill"
          style={{
            fill: props.colors.harterGaumen1912Fill,
            stroke: props.colors.harterGaumen1912Fill,
          }}
          d="M165.52,227.5l-48.22-4.13c-17.56-.95-32.45,4.53-44.57,16.73-6.03-3.89-7.24-8.03-4.88-12.37,1.46-2.69,4.01-4.62,6.91-5.59,15.8-5.32,30.65-7.74,44.44-6.9l44.97,4.03c1.7.15,3.31,1,4.22,2.44,1.37,2.17.31,4.09-2.87,5.8Z"
        />
        <path
          id="jaw_head_overlay"
          data-name="jaw head overlay"
          class="Kieferköpfchen1912Fill"
          style={{
            fill: props.colors.Kieferköpfchen1912Fill,
            stroke: props.colors.Kieferköpfchen1912Fill,
          }}
          d="M47.22,217.28c9.06-1.47,18.01-6.09,26.84-14.12,4.02-6.83,1.41-11.3-7.14-13.68-5.71-1.83-10.49-2.49-14.26-1.91-4.73.74-8.36,4.71-8.84,9.47-.65,6.39.67,13.17,3.41,20.24Z"
        />
        <path
          id="Processus_pterygoideus_overlay"
          data-name="Processus pterygoideus overlay"
          class="Processus_pterygoideus1912Fill"
          style={{
            fill: props.colors.Processus_pterygoideus1912Fill,
            stroke: props.colors.Processus_pterygoideus1912Fill,
          }}
          d="M122.68,177.52c-2.09-2.39-4.78-2.86-7.99-1.66h0c-2.41.51-4.32,2.4-4.8,4.82-1.56,7.98-1.94,16.7-1.56,25.9.09,2.25,1.8,4.12,4.04,4.38s4.56.14,6.82-.46c3.48-12.48-5.94-15.9,3.48-32.98Z"
        />
        <path
          id="cheekbone_overlay"
          data-name="cheekbone overlay"
          class="Jochbein1912Fill"
          style={{
            fill: props.colors.Jochbein1912Fill,
            stroke: props.colors.Jochbein1912Fill,
          }}
          d="M151.17,171.61c-2.03,5.11.07,7.81,7.68,6.2,3.16,1.99,5.68,1.78,7.18-1.95,4.27,1.4,8.54.53,12.83-2.87,3.76,3.88,7.92,5.71,12.69,4.29,7.38,1.55,16.09,1.28,15.64,4.98.51,3.15-.05,6.63-1.28,10.31-5.95,12.7-13.43,24.51-22.51,35.38l-39.22-2.29-2.17-7.72c-1.07-3.81-1.49-7.78-1.28-11.73.8-14.84,3.74-34.18,10.44-34.6Z"
        />
        <path
          id="anterior_cranial_base_overlay"
          data-name="anterior cranial base
 overlay"
          class="Schädelbasis1912Fill"
          style={{
            fill: props.colors.Schädelbasis1912Fill,
            stroke: props.colors.Schädelbasis1912Fill,
          }}
          d="M209.86,66.14c-1.89,5.17-5.94,9.76-13.66,13.39-4.53,4.05-13.31,8.07-22.17,13.01-28.65,19.73-54.4,32.04-65.66,26.66-9.14-3.24-20.81.38-24.15,5.95-.92,1.53-3.19,1.53-3.89-.11-.25-.58-.3-1.18-.17-1.82.27-1.43,1.41-2.54,2.8-2.99l11.16-3.62c10.4-.92,22.59-6.72,37.34-11.17,5.91-1.78,11.66-4.08,17.17-6.85,7.61-3.82,18.74-8.64,30.4-13.6,10.76-4.58,20.47-11.33,28.45-19.87.06-.07.13-.14.19-.21.25-.27.68-.28.94-.02l1.26,1.25Z"
        />
      </g>
    </svg>
  );
}
