import style from "./style.scss";

export default function RechterUnterschenkeMitOberemSprunggelenkLatLiegend2306(
  props
) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303.42 587.24">
      <g
        id="upper_ankle_joint_with_lower_leg"
        data-name="upper ankle joint with lower leg"
      >
        <path
          id="background"
          class="unterschenkel_mit_osg_lat_right2306_cls6"
          d="M299.16,586.23c-39.62-1.46-42.5-1.57-51.54-2.81-26.92-3.67-41.27-8.16-53.1-9.25-1.67-.16-6.84-.57-13.23-2.85-4.21-1.5-6.54-2.95-8.81-4.03-7.9-3.76-15.58-3.29-22.01-3.01-14.9.65-29.82.37-44.73.74-16.18.41-37.76.94-61.28-7.69-13.04-4.78-24.2-8.88-32.8-20.28-8.97-11.91-12.86-28.74-11.08-42.73.7-5.52,2.06-8.81,2.98-18.8,1.01-10.96-.14-12.15,1.18-20.34.88-5.4,2.2-13.15,7.13-21.28,3.28-5.43,4.92-5.65,8.09-10.44,6.17-9.31,6.31-18.87,7.33-23.39,2.99-13.22,10.4-27.21,16.08-51.26,3.65-15.48,8.75-36.8,7.98-80.91C49.19,143.42,10.83,29.32,48.53,5.25c2.19-1.4,1.25-.33,56,0,53.38.32,80.05,0,84,0,22.72,0,29.41,0,34,0,13.9,0,2,32.54-4.99,55.9-3.4,11.33-8.39,37.5-16.37,83.14-4.74,27.11-13.29,65.24-16.63,96.86-.53,5-1.51,14.45-2,27-.4,10.21-.27,18.28,0,34,.28,16.46.43,24.69,1,31,.95,10.45,1.99,21.29,6,35,2.49,8.51,5.99,16.01,13,31,7.07,15.14,13.11,25.93,20,27,.56.09,3.94.55,8,1,5.4.6,6.23.54,8,1,1.56.4,2.83.94,9,5,4.53,2.98,10.57,7.1,15,9,1.61.69,2.85,1.02,3.34,1.13,2.48.56,4.18.22,10.66,1.87,8.6,2.19,15.97,5.44,22.75,9.22"
        />
        <g id="foot">
          <g id="outline">
            <path
              id="outline_1"
              data-name="outline 1"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M51.35,267.9c-.42,25-2.38,59.93-7.98,80.91-8.31,26.3-16.08,51.31-23.41,74.65-10.62,10.26-17.1,26.26-16.4,52.05-7.29,30.34-1.95,53.21,14.87,69.14,16.19,11.79,46.95,21.87,87.31,20.36,35.93-2.05,65.4-1.62,75.55,6.3,25.8,5.71,50.18,10.24,72.03,12.81,11.79.92,27.64,1.98,45.82,2.75"
            />
            <path
              id="outline_1-2"
              data-name="outline 1"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M299.26,454.43c-7.88-3.94-16.31-7.54-25.05-10.95l-11.88-2.87-26.28-13.56c-8.87,1.92-16.28-1.34-22.92-7.46-34.31-53.05-33.28-134.15-30.8-164.95"
            />
          </g>
          <g id="heel_bone" data-name="heel bone">
            <path
              id="heel_bone_3"
              data-name="heel bone 3"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M101.42,477.13c2.93,15.13-5.47,17.43-20.27,12.51"
            />
            <path
              id="heel_bone_2"
              data-name="heel bone 2"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M152.34,498.78c-9.61,3.94-19,4.25-28.35.92.62-6.42,4.19-8.99,9.16-9.84"
            />
            <path
              id="heel_bone_1"
              data-name="heel bone 1"
              class="unterschenkel_mit_osg_lat_right2306_cls8"
              d="M79.09,540.35c-44.92-16.62-46.72-59.86-42.13-82.46"
            />
            <path
              id="heel_bone_4"
              data-name="heel bone 4"
              class="unterschenkel_mit_osg_lat_right2306_cls8"
              d="M79.1,540.35c16.84,4.53,21.33-16.73,41.18-7,19.66,6.82,39.19,11.94,55-9.9-4.01-18.86-1.45-34.18,12.79-43.26-4.37-3.56-13.02-4.9-27.39-6.02"
            />
            <path
              id="heel_bone_5"
              data-name="heel bone 5"
              class="unterschenkel_mit_osg_lat_right2306_cls8"
              d="M36.77,458.51c-.05-4.33.03-10.54.66-17.99,1.12-13.32,2.73-15.73,4.62-16.84,2.44-1.44,4.19,0,11.96,1.44,8.53,1.59,16.26,3.04,23.02-.16,4.5-2.13,3.42-3.88,9.29-7.67,4.26-2.76,10.82-6.98,18.21-5.34,5.53,1.23,11.48,5.14,12.63,10.47,2.34,10.82,7.41,13.68,5.56,12.21-1.54-1.23-.17,15.31,5.38,17.21,4.72,1.61,6.5-1.85,15.06-1.45,5.5.26,13.4-1.01,18.41.09,10.92,2.4,8.37,13.38,10.9,12.27"
            />
            <path
              id="heel_bone_6"
              data-name="heel bone 6"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M102.4,442.44c39.7,53.76,33.19,8.98,32.84,18.56"
            />
          </g>
          <g id="tarsus">
            <path
              id="tarsus_5"
              data-name="tarsus 5"
              class="unterschenkel_mit_osg_lat_right2306_cls8"
              d="M209.42,441.87h0c-7.13,2.07-13.45-2.17-19.51-8.18.11,12.79-3.71,18.02-9.77,23.91-5.59,7.36-12.07,12.73-19.46,16.56,10.78-1.48,21.35.5,31.67,6.29,7.11,1.1,11.45-1.07,10.86-8.75.38-7.63,3.42-14.05,10.17-18.85,6.94-10.1,6.22-14.24-3.95-10.97h-.01Z"
            />
            <path
              id="tarsus_4"
              data-name="tarsus 4"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M178.61,531.01h0l-3.22-12.35c-2.64-20.44.71-35.26,16.96-38.21,3.93.89,7.49,1.3,9.05-1.72,10.36-.27,18.65.3,22.42,2.87,3.11,7.86,7.11,14.63,15.76,15.98,10.59,1.14,10.56,4.59,2.93,9.53-2.43,5.61-5.85,9.39-10.54,10.81-7.93,2.97-15.55,10.07-23.08,18.01-5.22-5.32-11.07-3.67-16.99-.62-17.51,8.05-18.72,4.36-13.28-4.31h-.01Z"
            />
            <path
              id="tarsus_3"
              data-name="tarsus 3"
              class="unterschenkel_mit_osg_lat_right2306_cls8"
              d="M220.45,447.13v.02h0c-8.9,5.37-14.46,12.07-16.79,20.29,2.32,2.42-3.58,10.51-.41,11.09,4.12.8,16.17-.9,20.57,3.08,1.93-5.16,7.52-9.9,18.69-14.07,9.04-9.38,9.74-15.04.6-16.41-6.64-3.41-14.24-4.72-22.67-4h.01Z"
            />
            <path
              id="tarsus_2"
              data-name="tarsus 2"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M246.48,467.32h0c9.54.63,10.83,7.86,14.9,10.06,3.03,1.7,14.22-2.84,17.38.64,5.05-3.18,7.17-8.23,8.43-13.69-8.38-8.89-19.26-10.04-30.48-9.89-8.04,5.67-10.22,9.61-10.24,12.87h0Z"
            />
            <path
              id="tarsus_1"
              data-name="tarsus 1"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M230.18,492.48h.01l16.18,6.85c4.12,3.6,8.25,4.31,12.33,1.1,3.74-7.14,13.28-11.46,24.52-14.87-2.81-4.96-9.86-7.83-21.84-8.17-3.24-6.28-8.81-7.61-15.03-6.52-.51-1.79-3.84-3.31-15.27,2.33-10.82,5.4-.9,19.29-.9,19.29h0Z"
            />
          </g>
          <g id="metatarsus">
            <path
              id="metatarsus_10"
              data-name="metatarsus 10"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M299.26,553.86c-31.2-6.67-41.41-5.35-49.92-3.47l-7.79,4.29c-27.66,5.89-44.94,5.07-31.51-15.9h0c5.28-12.31,14.25-19.63,28.83-19.19,9.14,1.27,17.71,4.57,25.21,11.1l35.06,6.13"
            />
            <path
              id="metatarsus_9"
              data-name="metatarsus 9"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M241.23,542.07c-.83,4.45-7.16,8.41-17.93,11.94"
            />
            <path
              id="metatarsus_8"
              data-name="metatarsus 8"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M303.27,550.39c-1.33-.59-2.71-1.13-4.13-1.62"
            />
            <path
              id="metatarsus_8-2"
              data-name="metatarsus 8"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M299.14,548.77c-12.24-4.22-27.84-4.82-44.57-4.26"
            />
            <path
              id="metatarsus_7"
              data-name="metatarsus 7"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M299.26,520.81c-1.57-.65-3.13-1.31-4.69-1.99-14.05-12.17-24.46-15.82-32.44-14.15-14.81.67-22.83,5.51-23.36,14.92"
            />
            <path
              id="metatarsus_6"
              data-name="metatarsus 6"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M299.26,492.44c-1.76-.4-3.7-.61-5.72-.71-23.01-2.62-30.67,3.26-31.31,12.94"
            />
            <path
              id="metatarsus_5"
              data-name="metatarsus 5"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M299.26,480.11c-1.71-.62-3.36-1-4.97-1.24-4.08-2.2-8.61,2.77-13.3,12.02"
            />
            <path
              id="metatarsus_4"
              data-name="metatarsus 4"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M299.26,472.28c-1.03-.3-2.11-.63-3.23-1-2.82-4.77-7.46-1.95-12.44,2.07-3.64,6.77-3.87,10.59,2.98,8.22"
            />
            <path
              id="metatarsus_3"
              data-name="metatarsus 3"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M299.26,532.24c-8.79-1.64-17.36-2.79-25.65-3.36"
            />
            <path
              id="metatarsus_2"
              data-name="metatarsus 2"
              class="unterschenkel_mit_osg_lat_right2306_cls7"
              d="M299.26,514.58c-2.4-.58-4.83-1.16-7.27-1.73"
            />
          </g>
        </g>
        <path
          id="talus"
          class="unterschenkel_mit_osg_lat_right2306_cls8"
          d="M184.21,421.89v-.02c11.13,9.66,7.66,30.53-1.35,36.98-3.63,2.6-9.26,3.68-10.41,3.9-2.07.39-5.02.34-10.76.33-8.93,0-14.59.14-18.87-3.82-5.77-5.33-3.09-12.18-10.34-18.26-4.21-3.54-6.5-2.38-9.77-6.36-4.13-5.02-1.78-8.41-5.56-12.21-3.56-3.59-1.48-.86-7.78-6.29-4.85-4.18-14.15-3.73-14.73-7.54-.73-4.74,4.78-9.16,11.54-14.59,5.95-4.78,8.92-7.17,13.32-8.52,11.64-3.58,22.5,2.19,27.84,5.02,5.06,2.69,11.8,6.27,15.51,13.58,1.75,3.44,4.59,11.18,4.59,11.18,0,0,11.45,2.01,16.77,6.62h0Z"
        />
        <g id="tibia">
          <path
            id="tibia_1"
            data-name="tibia 1"
            class="unterschenkel_mit_osg_lat_right2306_cls8"
            d="M198.13,41.11c.13,9.88-.04,24.03-1.5,40.97-1.89,21.96-5.08,38.2-9.15,58.7-7.17,36.07-9.45,37.22-14.4,65.52-3.93,22.45-1.61,16.7-6.86,50.78-4.05,26.3-7.6,43.81-6.37,64.81.74,12.7,1.14,19.59,4.97,28.2,3.15,7.08,6.1,9.65,6.41,16.25.11,2.36.27,13.21-6.73,19.77-3.04,2.84-7.36,4.82-10.26,10.61-.31.62-.79,1.59-1.24,2.98-2.06,6.39.59,9.43-1.47,12.95-1.27,2.19-3.83,3.67-6.14,3.78-3.53.17-3.99-2.99-8.81-5.33-6.78-3.29-11.99-.02-16.84-4.62-.29-.28-.83-.8-1.41-1.6-2.43-3.35-1.98-6.79-4.15-10.61-.08-.14-.23-.39-.4-.66-1.3-2.16-2.54-3.6-2.82-3.93-2.07-2.46-7.17-10.46-7.44-10.87-4.96-7.83,2.39-21.7,6.88-45.71,1.28-6.86,2.71-14.47,3-24.88.38-13.29-1.52-18.22-.76-34.22.05-1.03.55-6.64,1.57-17.81,1.69-18.65,2.61-29.22,3.19-34.9,3.32-32.32,4.86-63.47,12.03-107.64,5.51-33.96,8.06-61.14,9.63-71.7,2.09-14.11,4.27-28.04,6.53-41.79"
          />
          <path
            id="tibia_2"
            data-name="tibia 2"
            class="unterschenkel_mit_osg_lat_right2306_cls8"
            d="M202.55,2.26l-4.42,38.86"
          />
        </g>
        <g id="fibula">
          <path
            id="fibula_1"
            data-name="fibula 1"
            class="unterschenkel_mit_osg_lat_right2306_cls8"
            d="M141.91,210.18c1.01-5.02,2.4-12.81,7.15-58.78,3.94-38.1,6.7-68.91,8.1-84.76,2.63-29.88,3.55-42.47,5.32-61.39"
          />
          <path
            id="fibula_2"
            data-name="fibula 2"
            class="unterschenkel_mit_osg_lat_right2306_cls8"
            d="M122.54,411.61c2.15-.41,11.79-2.59,16.16-10.77,3.6-6.73,1.39-14.23.88-17.63-.83-5.63-2.16-5.96-4.49-14.17-.34-1.18-2.43-8.68-3.38-17.13-.53-4.82-.55-8.67.13-20.99"
          />
          <path
            id="fibula_3"
            data-name="fibula 3"
            class="unterschenkel_mit_osg_lat_right2306_cls8"
            d="M153.53,5.25c-.84,8.91-2.18,22.85-4,39.89-9.54,89.58-13.58,91.79-17,138-2.55,34.49-3.19,72.29-7.68,110.48-2.65,22.53-4.06,33.98-8.57,42.72,0,0-7.58,14.7-10.48,29.44-.25,1.29-.81,5.1-.9,8.17-.03,1.06,0,2.01,0,2.09.06,2.16.31,3.75.37,4.15.47,3.22.51,8.69.9,13.82.72,9.34,1.07,14.01,3.41,16.14.96.87,4.43,3.58,12.95,1.47"
          />
          <line
            id="fibula_4"
            data-name="fibula 4"
            class="unterschenkel_mit_osg_lat_right2306_cls8"
            x1="141.91"
            y1="210.18"
            x2="131.86"
            y2="330.92"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="tibia_overlay"
          data-name="tibia overlay"
          class="tibia2306fill"
          style={{
            fill: props.colors.Tibia2306Fill,
            stroke: props.colors.tibia2306Fill,
          }}
          d="M105.23,380.33c.47,9.86,1.1,19.17,2.4,26.12,3.24,9.27,12.1,6.76,21.58,2.97,5.47.16,9.99,2.37,13.68,6.4,9.69.24,9.98-5.95,9.38-12.75,1.59-8.88,6.81-13.86,13.03-17.76,6.45-7.26,7.66-15.89,4.35-25.71-7.7-11.04-11.13-26.67-10.04-47.16.67-22.81,7.69-62.48,13.47-106.13,13.7-59.29,25.09-113.97,25.05-165.18l4.42-34.13h-58.07c-10.26,73.87-20.6,140.33-24.81,190.12l-7.03,76.89c1.61,22.24,1.64,43.59-2.88,62.35-4.8,20.97-12.51,38.51-4.53,43.98Z"
        />
        <path
          id="talus_overlay"
          data-name="talus overlay"
          class="talus2306fill"
          style={{
            fill: props.colors.Talus2306Fill,
            stroke: props.colors.Talus2306Fill,
          }}
          d="M184.21,421.89v-.02c11.13,9.66,7.66,30.53-1.35,36.98-3.63,2.6-9.26,3.68-10.41,3.9-2.07.39-5.02.34-10.76.33-8.93,0-14.59.14-18.87-3.82-5.77-5.33-3.09-12.18-10.34-18.26-4.21-3.54-6.5-2.38-9.77-6.36-4.13-5.02-1.78-8.41-5.56-12.21-3.56-3.59-1.48-.86-7.78-6.29-4.85-4.18-14.15-3.73-14.73-7.54-.73-4.74,4.78-9.16,11.54-14.59,5.95-4.78,8.92-7.17,13.32-8.52,11.64-3.58,22.5,2.19,27.84,5.02,5.06,2.69,11.8,6.27,15.51,13.58,1.75,3.44,4.59,11.18,4.59,11.18,0,0,11.45,2.01,16.77,6.62h0Z"
        />
        <path
          id="fibula_overlay"
          data-name="fibula overlay"
          class="fibula2306fill"
          style={{
            fill: props.colors.Fibula2306Fill,
            stroke: props.colors.Fibula2306Fill,
          }}
          d="M162.48,5.25c-5.51,70.63-12.27,139.12-20.57,204.93l-10.05,120.74c-1.86,16.87.51,32.45,6.35,46.96,2.89,10.65,3.85,20.32-1.68,26.64-5.21,5.95-14.76,5.03-18.81-1.75-1.69-2.82-2.82-6.15-3.93-9.16l-5.51-7.82c-2.1-2.99-3.32-6.54-3.31-10.19.02-11.8,5.01-25.62,11.76-41.17,1.79-4.13,3.11-8.45,3.88-12.88,6.23-35.98,9.33-77.41,10.83-121.65,5.45-72.47,20.76-144.92,22.1-194.64"
        />
        <path
          id="calcaneus_overlay"
          data-name="calcaneus overlay"
          class="calcaneus2306fill"
          style={{
            fill: props.colors.Calcaneus2306Fill,
            stroke: props.colors.Calcaneus2306Fill,
          }}
          d="M97.05,412.1c-6.25,2.08-11.27,5-15.08,8.75-6.26,7.79-18.98,8.07-36.92,2.3-4.28-.96-6.11,3.6-6.88,10.54l-1.4,24.82c-3.78,23.31-.31,43.53,11.83,60.05,18.21,18.26,33.33,26.78,43.64,20.14,9.83-7.59,18.88-10.15,26.87-5.89,24.11,11.31,42.75,8,56.16-9.36-5.22-22.33.34-35.66,12.79-43.26-3.92-3.41-13.99-5.16-27.39-6.02,6.11-3.16,11.45-7.36,16.09-12.49-24.71,4.22-38.14.92-38.51-11.07-1.8-7.15-6.82-11.82-14.28-14.62l-6.84-13.56c-5.74-6.06-12.21-10.14-20.1-10.33Z"
        />
        <path
          id="upper_ankle_joint_overlay"
          data-name="upper ankle joint overlay"
          class="osg2306fill"
          style={{
            fill: props.colors.OSG2306Fill,
            stroke: props.colors.OSG2306Fill,
          }}
          d="M101.78,376.23l.54,26.53c.06,3.09,1.1,6.12,3.13,8.44,3.12,3.56,7.52,5.81,13.67,6.27,2.98-.42,6.15-1.54,9.51-3.32,1.75-.93,3.89-.56,5.31.83,1.69,1.66,3.61,3.09,5.79,4.27,6.71,3.61,14.87-.09,16.73-7.47.5-1.97.81-3.87.96-5.7.44-5.54,2.36-10.87,5.25-15.62l4.2-6.9-.43-.49c-2.1-2.41-5.86-2.39-7.93.05-5.55,6.55-10.41,13.03-12.86,19.27-.78,1.98-2.79,3.17-4.91,2.96s-4.28-.73-6.29-1.55c-3.19-1.3-6.79-1.02-9.86.53-1.77.89-3.77,1.49-6.05,1.72-2.77.28-5.41-1.14-6.68-3.61-2.39-4.63-3.61-12.24-4.39-20.97-.26-2.94-2.73-5.21-5.68-5.21h0Z"
        />
      </g>
    </svg>
  );
}
