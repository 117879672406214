import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const LegLeft = map.LegLeft;

function FrontView({ selected, bpartSetter }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217.5 853.5">
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="foot">
          <path
            id="foot_2_"
            data-name="foot (2)"
            class="lleg-cls-2"
            d="M105.83,758.06c1.5,6.67-1.22,8.24-6.94,6.19-9.5-6.61-18.08-8.8-24.19,.75-3.64,2.14-6.96,3.46-9,1.5-1.72-1.47-2.19-3.63-.19-7.13,2.25-2.04,2.2-6.07,1.69-10.5-1.74-8.08,1.36-10.31,7.69-8.62,3.73,3.71,7.91,4.27,12.56,1.5,4.04,2.82,8.3,3.76,12.75,3.19,4.85,4.15,7.98,8.43,5.62,13.13Z"
          />
          <path
            id="foot_1_"
            data-name="foot (1)"
            class="lleg-cls-2"
            d="M64.02,768.56c-4.66,5.14-4.72,9.82-.38,14.06,2.65-3.6,6.67-6.53,11.25-9.19,1.67-1.13,3.43-1.22,5.25-.56,3.55-5.6,8.32-6.74,14.25-3.56,1.27-1.4,2.81-.56,5.63-1.31,2.06-1.04,1.77-2.37-1.12-3.75-8.56-6.19-16.63-8.53-23.25,.37-3.54,2.49-7.33,4.1-11.63,3.94Z"
          />
          <path
            id="toe_3_3_"
            data-name="toe 3 (3)"
            class="lleg-cls-2"
            d="M116.89,779.63c3.79-.85,3.09-3.19-1.31-6.75-3.97-1.92-7.62-4.15-11.06-6.56-1.85-1.3-2.99-1.02-3.38,.94-.93,1.56-3.06,2.01-6,1.69,3.03,3.18,6.81,5.66,11.44,7.31,4.43,.32,7.65,1.44,10.31,3.38Z"
          />
          <path
            id="toe_2_3_"
            data-name="toe 2 (3)"
            class="lleg-cls-2"
            d="M102.08,775.12l-7.69-5.81c-6.68-3.56-11.21-1.84-14.25,3.56,3.14,.05,4.51,2.1,5.06,5.06,3.8-2.7,7.37-3.46,10.5-.75,1.68-1.21,3.75-1.96,6.38-2.06Z"
          />
          <path
            id="big_toe_4_"
            data-name="big toe (4)"
            class="lleg-cls-2"
            d="M84.08,783l1.13-5.06c-.45-2.6-1.75-4.36-4.31-4.88-2.68-1.44-5.42-1.18-8.25,1.5-3.58,1.78-6.66,4.35-9,8.06-1.81,1.98-2.27,5.03-1.13,9.37,.66,2.66,2.16,3.21,5.44-.75,2.73-9.5,7.18-14.62,16.13-8.25Z"
          />
          <path
            id="toe_4_1_"
            data-name="toe 4 (1)"
            class="lleg-cls-2"
            d="M132.83,792.38c-1.88-3.83-5.3-7.24-9.94-10.31-3.08-2.17-5.1-1.74-4.5,1.69,1.21,2.97,2.83,4.8,4.69,6"
          />
          <path
            id="toe_3_1_"
            data-name="toe 3 (1)"
            class="lleg-cls-2"
            d="M115.96,792c-3.13-1.91-5.66-4.45-7.12-8.06-.34-7.88-7.92-10.84-12.37-6-.01,2.44,.21,5.22,.75,8.44,3.84,2.11,6.65,5,7.5,9.38"
          />
          <path
            id="toe_2_2_"
            data-name="toe 2 (2)"
            class="lleg-cls-2"
            d="M102.46,797.81c-2.15-3.62-4.27-7.81-6.37-12.38,.98-2.24,.95-4.79,.38-7.5-4.78-3.98-8.35-2.8-11.25,1.31-1.82,3.95-.71,8.66,.94,13.5,5.2-.14,7,3.5,7.12,9"
          />
          <path
            id="big_toe_3_"
            data-name="big toe (3)"
            class="lleg-cls-2"
            d="M84.08,803.06c-.63-3.15,.12-5.82,2.63-7.87-1.81-3.99-2.56-8.06-2.62-12.19-5.31-3.75-10.01-3.6-13.88,2.06l-2.25,6.19c-.82,1.82-1.57,3.13-2.44,3.37-.86,1.61-.78,2.94,.37,3.94-.31,3.46,.72,8.74,1.5,13.69,4.35-8.72,10.11-10.84,16.69-9.19Z"
          />
          <path
            id="big_toe_2_"
            data-name="big toe (2)"
            class="lleg-cls-2"
            d="M99.64,814.69c-2.67-1.7-5.28-3.29-7.12-3.56-2.86-2.99-5.67-5.66-8.44-8.06"
          />
          <path
            id="big_toe_1_"
            data-name="big toe (1)"
            class="lleg-cls-2"
            d="M72.08,820.12c-2.92-1.16-4.65-3.6-4.69-7.88"
          />
          <path
            id="toe_3_2_"
            data-name="toe 3 (2)"
            class="lleg-cls-2"
            d="M128.14,809.44c-1.55-.29-3.68-.4-5.25-2.25-1.03-2.95-2.53-5.09-4.31-6.75"
          />
          <path
            id="toe_4_2_"
            data-name="toe 4 (2)"
            class="lleg-cls-2"
            d="M141.27,806.81c-2.82-2.47-6.12-4.27-9.19-6.19"
          />
          <path
            id="toe_2_1_"
            data-name="toe 2  (1)"
            class="lleg-cls-2"
            d="M112.58,813.56c-1.64-3.21-4.27-6-7.31-8.63"
          />
          <path
            id="little_toe_nail"
            data-name="little toe nail"
            class="lleg-cls-2"
            d="M147.46,807.19c1.42,.5,3.05,.23,4.88-.75-.43-2-1.61-3.2-3.75-3.38"
          />
          <path
            id="toe_4_nail"
            data-name="toe 4 nail"
            class="lleg-cls-2"
            d="M137.14,813c2.71,.26,5.19-.13,7.31-1.5,.12-1.3-.47-2.51-2.44-3.56-3.14-.57-5.82-.23-8.06,.94"
          />
          <path
            id="toe_3_nail"
            data-name="toe 3 nail"
            class="lleg-cls-2"
            d="M125.89,814.88c2.47,.34,4.61-.44,6.56-1.87-.18-1.44-.87-2.4-2.81-2.25-2.37-.12-4.74,.05-7.13,.37"
          />
          <path
            id="toe_2_nail"
            data-name="toe 2 nail"
            class="lleg-cls-2"
            d="M110.71,819.19c4.32,.72,8.21,.8,10.88-.94-2.95-3.12-3.43-4.65-4.5-4.13-3.86-.31-7.19,.23-9.94,1.69"
          />
          <path
            id="big_toe_nail"
            data-name="big toe nail"
            class="lleg-cls-2"
            d="M84.83,823.31c5.89,.75,11.38-.29,16.5-3-.32-1.82-2.01-3.76-5.06-5.81-4.46-.75-8.73-.72-12.19,2.44-3.51,4.66-3.23,6.76,.75,6.38Z"
          />
          <path
            id="big_toe"
            data-name="big toe"
            class="lleg-cls-2"
            d="M106.39,828.38c.57-2.41,0-4.85-2.44-7.31"
          />
          <path
            id="toe_2"
            data-name="toe 2"
            class="lleg-cls-2"
            d="M121.96,825.19c1.39-2.26,1.22-4.57-.37-6.94"
          />
          <path
            id="toe_3"
            data-name="toe 3"
            class="lleg-cls-2"
            d="M136.77,820.69c.9-3.01-.15-5.47-4.12-7.13"
          />
          <path
            id="toe_4"
            data-name="toe 4"
            class="lleg-cls-2"
            d="M148.21,815.06c-.8-1.39-1.78-2.76-3.75-3.56"
          />
        </g>
        <g id="lower_leg" data-name="lower leg">
          <path
            id="lower_leg_3"
            data-name="lower leg 3"
            class="lleg-cls-2"
            d="M130.58,443.81l.56-2.44c.35-1.11-3.07-6.3-9-3.75-5.12-.37-9.42-.13-12.38,1.12-3.29-1.95-6.57-3.25-9.75-2.25-2.64,1-4.55,.91-5.25-.94-2.92-2.15-5.34-2.56-7.12-.75-5.26,1-9.05,.48-11.44-1.5-6.15-2.47-9.96-1.93-7.13,7.12-.93,4.48-.71,8.82,1.69,14.06,4.24,4.28,6.55,9.39,7.69,15,2.64,39.83,1.72,84.11-1.5,131.25-1.24,51.14-4.13,99.41-18.19,128.06-2.13,6.7-3.16,12.74-.38,16.5,5.64,13.45,7.88,9.57,9,0-.79-5.6,3.13-5.86,8.81-4.12,3.56,3.15,7.35,2.75,11.25,.56,14.15,6.06,20.43,3.15,16.13-11.81-3.06-29.45-3.24-66.32-2.62-105.19-.43-49.03,1-95.56,4.88-138.75,4.31-23.51,10.93-32.88,19.13-32.62,5.19-1.73,6.56-5.14,5.63-9.56Z"
          />
          <path
            id="lower_leg_2"
            data-name="lower leg 2"
            class="lleg-cls-2"
            d="M76.21,433.31c-2.56,.51-3.94,2.65-3.38,7.5,2.83,11.37,6.48,20.77,12.37,24.75-1.67,16.11,1.57,28,8.25,36.94,.88,17.73,1.48,51.88-.09,61.5,.76,23.32,.91,44.5-1.6,56.06-1.25,20.22-13.2,61.69-24,100.87-3.68,5.61-4.4,11.62,4.5,18.94"
          />
          <path
            id="lower_leg_1"
            data-name="lower leg 1"
            class="lleg-cls-2"
            d="M136.39,471.75c-2.02-4.66-2.51-8.92-2.06-12.94-1.94-3.22-5.3-4.76-9.37-5.44-6.2,.03-10.29,4.74-13.31,11.81,1.75,4.88,4,9.25,6.75,13.13-.95,31.35-2.06,60.97-3.94,81.75-1.62,44.03-4.17,91.37-7.31,140.81l-3.56,29.06c2.85,8.53,1.85,13.65-3.38,15,4.54,3.85,6.91,7.72,6,11.63l.56,4.31c2.36,2.16,5,3.36,7.69,4.5,6.31-4.54,5.15-10.06,2.63-15.75-3.46-16.2-.22-46,2.63-75,6.06-29.32,7.03-72.17,6.75-118.31l3.94-75.37c3.62-1.8,5.55-4.91,6-9.19Z"
          />
        </g>
        <g id="upper_leg" data-name="upper leg">
          <path
            id="upper_leg_5"
            data-name="upper leg 5"
            class="lleg-cls-2"
            d="M131.71,121.13c1.99-6.79-.87-8.74-8.25-6.19-13.27,1.2-16.39,7.53-17.25,15-.62,7.12,4.64,8.44,12,7.69,5.61,.86,10.33,6.22,14.81,12.75,1.52,2.98,1.12,5.57-.56,7.87-4.82,1.67-8.02,5-5.44,14.25,1.17,37.1-.92,74.29-14.25,111.75-9.66,34.41-20,70.76-26.81,96.94-3.36,10.36-8.37,16.53-15.38,17.62-2.8,1.41-3.77,5.54-2.44,13.12-3.96,5.43,.77,10.87,8.81,16.31,3.53,3.92,8.07,2.22,12.94-1.31,6.83,7.84,12.99,7.6,18.56,.19,10.92,9.87,18.27,8.55,23.44,.38-.69-3.71-.19-6.93,1.31-9.75,3.43-5.09,1.34-10.64-6.56-16.69-12.04-37.56,4.98-90.85,14.81-140.25l11.63-76.69c1.55-11.25,5.57-19.45,11.63-25.12,.52-2.82,1.13-4.98,1.88-6,1.59-4.4,.09-7.77-2.25-10.88-5.44-18.11-10.21-20.86-14.62-15.56-6.7,3.62-12.82,2.79-18-5.44Z"
          />
          <path
            id="upper_leg_4"
            data-name="upper leg 4"
            class="lleg-cls-2"
            d="M108.46,427.13c8.85-8.17,8.65-15.66,0-22.5-5.17-2.22-9.91-1.95-14.25,.56-4.48-.85-6.51,1.92-7.13,6.75-3.57,5.31-1.13,10.2,2.81,15"
          />
          <path
            id="upper_leg_3"
            data-name="upper leg 3"
            class="lleg-cls-2"
            d="M154.58,124.31c-2.16,1.71-4.02,3.54-2.63,6.75-1.48,3.1-3.45,4.91-6.38,4.12-3.03,5.03-.92,8.06,3.19,10.31,3.64,6.92,9.27,10.96,15.94,13.5"
          />
          <path
            id="upper_leg_2"
            data-name="upper leg 2"
            class="lleg-cls-2"
            d="M131.71,117.56c-3.7,7.44-7.73,13.63-13.88,12.19-3.84-1.07-7.11-3.27-9.19-1.31l-2.44-.75"
          />
          <path
            id="upper_leg_1"
            data-name="upper leg 1"
            class="lleg-cls-2"
            d="M102.46,413.06c.92,1.66,.98,3.69,.37,6-2.51-.73-4.23-2.07-4.12-3.94,.36-1.82,1.42-2.74,3.75-2.06Z"
          />
        </g>
        <g id="hip">
          <path
            id="hip_15"
            data-name="hip 15"
            class="lleg-cls-2"
            d="M131.42,109.78c-1.64-2.49-1.69-3.66,3.09-7.31-.58-10.9,2.17-17.91,8.44-20.81,2.98-14.45,3.35-27.35-6.75-34.03-3.83-4.41-7.7-8.5-12.09-8.16-2.74-1.4-5.35-1.88-8.16-2.25-20.33-9.36-42.8-4.69-47.53,18.28,3.45,3.32,6.23,7.49,7.31,13.78,4.72,8.93,5,15.16-1.69,17.16-3.79,3.75-7.94,5.65-9.84,18.84-.87,2.12-2.43,3.56-4.22,4.78-2.88,1.64-4.72,3.69-4.78,6.47-1.69,2.91-3.87,3.75-6.47,2.81v9c-1.52,7.26-1.32,13.84,1.41,19.41,4.44,2.73,8.33,6.4,11.53,11.25,2.97,10.11,11.04,11.9,22.5,8.16,10.41-.92,17.71-7.71,21.38-21.37-.19-2.83,.01-6.51,.56-10.97-.5-11.24,3.15-19,14.91-19.97,6.2-.56,10.95-1.72,10.41-5.06Z"
          />
          <path
            id="hip_14"
            data-name="hip 14"
            class="lleg-cls-2"
            d="M31.58,71.25c13.53-1.43,18.22-5.35,10.69-13.22-6.35-1.66-9.35-3.66-4.5-6.47-.03-2.59,2.32-3.19,6.19-2.53,4.41-.54,5.42,1.22,3.37,5.06-3.01,5.79,.3,6.93,7.31,5.34,2.98-2.18,7.68-3.44,13.78-3.94"
          />
          <path
            id="hip_13"
            data-name="hip 13"
            class="lleg-cls-2"
            d="M45.36,67.03c-3.01,2.93-5.83,6.37-10.13,5.62-2.66,1.08-3.57,2.56-2.53,4.5"
          />
          <path
            id="hip_12"
            data-name="hip 12"
            class="lleg-cls-2"
            d="M70.11,75.47c-8.67-2.66-17-3.95-24.47-1.69"
          />
          <path
            id="hip_11"
            data-name="hip 11"
            class="lleg-cls-2"
            d="M51.27,87.84c5.49-2.56,4.26-3.82,0-4.5-2.51-.29-4.42,.49-5.63,2.53"
          />
          <path
            id="hip_10"
            data-name="hip 10"
            class="lleg-cls-2"
            d="M51.27,97.97c2.13-1.1,2.62-2.37,.28-3.94-1.98-1.31-3.71-1.44-5.06,.28-.72,.36-.45,1.05,.56,1.97,1.05,2.46,2.46,2.99,4.22,1.69Z"
          />
          <path
            id="hip_9"
            data-name="hip 9"
            class="lleg-cls-2"
            d="M82.77,80.53c10.03,8.11,13.14,16.27,8.72,24.47"
          />
          <path
            id="hip_8"
            data-name="hip 8"
            class="lleg-cls-2"
            d="M76.86,90.66c9.66,.04,14.57,4.33,14.34,13.22-.85,7.77-8.74,11.64-17.72,14.91-8.89,.87-16.64,5.32-24.75,9.56"
          />
          <path
            id="hip_7"
            data-name="hip 7"
            class="lleg-cls-2"
            d="M113.85,36.23c5.24,2.8,9.21,6.01,11.67,9.7"
          />
          <path
            id="hip_6"
            data-name="hip 6"
            class="lleg-cls-2"
            d="M129.6,50.72c7.17,10.52,9.25,17.95,2.67,20.11-2.5,1.74-1.63,6.25,0,11.39-2.6,2.3-3.9,4.17-3.52,5.48-.16,2.77-.98,4.79-3.09,5.34,.12,4.84-.91,8.5-2.39,11.67"
          />
          <path
            id="hip_5"
            data-name="hip 5"
            class="lleg-cls-2"
            d="M85.16,128.34c3.4,8.82,4.78,17.91-4.36,19.55-10.07,.71-15.81-4.3-14.77-15.33"
          />
          <path
            id="hip_4"
            data-name="hip 4"
            class="lleg-cls-2"
            d="M48.46,103.31c2.16,1.32,2.82,2.56,.56,3.66-1.28,2.13-3.05,1.13-5.06-1.41"
          />
          <path
            id="hip_3"
            data-name="hip 3"
            class="lleg-cls-2"
            d="M106.21,127.69c-15.17,.71-33.77,.42-38.48,4.17-5.16,2.59-9.79,2.98-13.64,.14"
          />
          <path
            id="hip_2"
            data-name="hip 2"
            class="lleg-cls-2"
            d="M107.24,122.16c-3.66,1.32-7.43,2.42-14.63,5.91"
          />
          <path
            id="hip_1"
            data-name="hip 1"
            class="lleg-cls-2"
            d="M44.24,112.59c-2.11,1.26-2.53,2.49-.56,3.66,1.38,1.39,2.54,.53,3.66-.84"
          />
        </g>
        <g id="outlines">
          <path
            id="outline_1"
            data-name="outline 1"
            class="lleg-cls-2"
            d="M59.71,178.41c.92,12.6,.25,23.48-9.84,24.19-.28,36.84,3.08,72.27,11.53,105.75-9.17,40.7-13.44,81.1-3.94,120.66,2.66,17.82,4.82,34.01,5.06,43.88-9.65,40.13-16.34,79.09-3.94,110.53,5.9,45.07,7.74,86.94-.28,120.94l-3.09,15.75-3.75,13.59c-.01,10.3,1.26,20.13,5.44,28.88,1.51,24.61,2.91,49.84,6.75,61.31,.06,10.52,17.31,13.7,15,9.38-.59,4.34,26.62,6.23,27.75-4.87,5.82,4.24,11.04,3.41,15.56-3.19,5.54,2.82,10.4,.76,14.81-4.5,6.24,2.34,9.29-.86,11.44-5.62,5.9,.72,8.49-1.49,6.38-7.87-2.99-6.93-7.87-12.61-16.31-15.94-4.43-5.07-11.13-12.72-18.56-21.19,4.64-7.73,5.89-16.95,1.03-28.87,2.54-28.64,7.49-55.12,14.06-80.16l14.91-70.03c9.38-27.1,13.5-43.25,10.97-57.09-1.96-22.56-7.25-46.06-15.19-70.31-.46-23.35-1.9-45.47-5.62-64.69-3.92-29.48,13.39-77.13,31.78-125.72,12.27-49.7,9.59-93.43-.56-134.16-3.07-11.01-4.67-22.29-5.34-33.75-1.84-10.21-5.57-18.67-11.25-25.31,1.89-13.05,1.06-23.95-2.25-32.91"
          />
          <line
            id="outline_2"
            data-name="outline 2"
            class="lleg-cls-2"
            x1="42.27"
            y1="202.59"
            x2="49.86"
            y2="202.59"
          />
        </g>
      </g>
      <g id="hip_joint" data-name="hip joint">
        <path
          id="hip_joint_overlay"
          data-name="hip joint overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, LegLeft.id, LegLeft.children.hipJoint),
            });
          }}
          class={`lleg-cls-1 ${isSelected(
            selected,
            LegLeft.children.hipJoint
          )}`}
          d="M154.02,124.13c-8.35,6.31-16.31,7.69-23.25-3.75,1.72-3.92,1.13-6.4-2.25-7.13-14.79,1.03-21.35,6.12-21.75,14.25-1.93,6.84,.88,10.81,10.12,10.88,3.44-1.16,7.16,.67,11.25,6.38,6.08,6.08,7.61,10.71,3.37,13.5,8.36,3.7,16.97,6.59,25.12,10.13,2.18-5.1,5.08-8.56,8.63-10.5,2.88-6.29,2.49-11.53-1.12-15.75-3.43-9.83-6.81-16.71-10.12-18Z"
        />
      </g>
      <g id="upper_leg_with_hip_joint" data-name="upper leg with hip joint">
        <path
          id="upper_leg_with_hip_joint_overlay"
          data-name="upper leg with hip joint overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                LegLeft.id,
                LegLeft.children.upperLegWithHipJoint
              ),
            });
          }}
          class={`lleg-cls-1 ${isSelected(
            selected,
            LegLeft.children.upperLegWithHipJoint
          )}`}
          d="M157.02,169.13l-25.87-10.88c-3.76,1.85-5.95,5.46-4.5,13.12,1.29,52.75-5.85,99.45-21.75,139.87l24.75,4.13c9.99-47.24,19.44-100.83,27.38-146.25Z"
        />
      </g>
      <g id="upper_leg_with_knee_jont" data-name="upper leg with knee jont">
        <path
          id="upper_leg_with_knee_joint_overlay"
          data-name="upper leg with knee joint overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                LegLeft.id,
                LegLeft.children.upperLegWithKneeJoint
              ),
            });
          }}
          class={`lleg-cls-1 ${isSelected(
            selected,
            LegLeft.children.upperLegWithKneeJoint
          )}`}
          d="M128.89,315l-24-3.75c-7.67,28.79-15.09,56.04-21.38,76.12l41.62,9.38c-4.76-22.73-3-49.39,3.75-81.75Z"
        />
      </g>
      <g id="knee">
        <path
          id="knee_overlay"
          data-name="knee overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, LegLeft.id, LegLeft.children.knee),
            });
          }}
          class={`lleg-cls-1 ${isSelected(selected, LegLeft.children.knee)}`}
          d="M125.89,397.88l-42.75-10.13c-3.62,6.42-7.54,10.99-12.38,10.12-3.36,4.31-4.62,9.08-2.25,14.62-3.55,4.11-.17,9.38,7.5,15.38l-2.63,4.12c-3.34-.24-5.39,1.46-4.5,7.5-1.69,6.28-1.87,11.54,1.12,14.63,20.26,2.23,39.11,2.53,54.75-1.5,5.01,0,6.59-3.71,6-9.75-.58-4.38-3.43-6.11-7.87-6,3.14-1.32,6.47-3.94,10.13-8.62-3.06-4.86-.97-10.09,1.88-15.37-1.21-6.24-4.28-9.63-8.25-11.63l-.75-3.38Z"
        />
      </g>
      <g id="under_leg_with_knee_joint" data-name="under leg with knee joint">
        <path
          id="under_leg_with_knee_joint_overlay"
          data-name="under leg with knee joint overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                LegLeft.id,
                LegLeft.children.lowerLegWithKneeJoint
              ),
            });
          }}
          class={`lleg-cls-1 ${isSelected(
            selected,
            LegLeft.children.lowerLegWithKneeJoint
          )}`}
          d="M125.89,453.38c-18.29,3.86-36.64,5.38-55.13,1.5,7.64,5.75,9.59,21.83,8.25,43.88,1.96,30.54,1.6,59.86-1.5,87.75l47.25,5.63,4.88-112.5c2.88,1.28,4.87-2.52,6.38-9-2.37-1.61-2.77-6.09-2.25-13.5-2.69-2.58-5.33-4.14-7.87-3.75Z"
        />
      </g>
      <g id="ankle_with_under_leg" data-name="ankle with under leg">
        <path
          id="ankle_with_under_leg_overlay"
          data-name="ankle with under leg overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                LegLeft.id,
                LegLeft.children.ankleWithLowerLeg
              ),
            });
          }}
          class={`lleg-cls-1 ${isSelected(
            selected,
            LegLeft.children.ankleWithLowerLeg
          )}`}
          d="M126.27,593.63l-49.12-6.75c.8,47.3-4.21,89.61-13.5,128.25l52.5,5.63c5.86-48.04,10.33-92.91,10.13-127.12Z"
        />
      </g>
      <g id="ankle_overlay" data-name="ankle overlay">
        <path
          id="ankle_overlay_1"
          data-name="ankle overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, LegLeft.id, LegLeft.children.ankle),
            });
          }}
          class={`lleg-cls-1 ${isSelected(selected, LegLeft.children.ankle)}`}
          d="M115.77,721.5l-51.75-5.63c-5.88,11.9-10.58,23.63-3.75,33.75,2.78,4.72,5.08,6.1,6.37,.38l-1.5,9c-2.19,8.92,.95,9.83,6.75,6.75,8.59-9.42,18.24-8.34,28.5-1.12,2.85,1.25,4.98,.03,6.38-3.75,1.77,2.54,4.19,3.94,7.5,3.75,5.82-1.46,5.08-7.9,3-15.37-2.49-8.48-2.38-17.96-1.5-27.75Z"
        />
      </g>
      <g id="foot_overlay" data-name="foot overlay">
        <path
          id="foot_overlay_1"
          data-name="foot overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, LegLeft.id, LegLeft.children.foot),
            });
          }}
          class={`lleg-cls-1 ${isSelected(selected, LegLeft.children.foot)}`}
          d="M104.52,766.31c-10.54-6.19-20.98-11.64-29.25-2.44-2.3,3.42-6.07,5.01-11.25,4.87-3.74,3.14-4.9,7.28-.75,13.5-1.79,5.95-2.34,10.91,1.87,12l.75,4.88,1.12,12c-.39,5.22,1.42,7.85,4.5,9-.73,7.38,2.51,11.38,7.5,13.88,11.12,4.61,20.99,5.25,27.38-5.25,5.84,3.32,11.24,2.2,16.13-4.12,4.73,2.89,9.61,1.42,14.63-4.12,4.91,1.71,8.86,.82,10.88-5.25,4.56-.26,7.72-1.8,6.75-7.12-2.47-7.22-7.01-13.26-16.5-16.5-5.52-4.93-11.71-12.42-18.37-21.75l-12.37-8.63-3,5.06Z"
        />
      </g>
    </svg>
  );
}

export default FrontView;
