import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const RibsRight = map.RibsRight;

function FrontView({ selected, bpartSetter }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.52 241.86">
      <g id="Himithorax_R" data-name="Himithorax R">
        <g id="rip_1_r" data-name="rip 1 r">
          <path
            id="rip_1_r-2"
            data-name="rip 1 r-2"
            class="rribs-cls-4"
            d="M68.13,5.13c-.62-.51-1.28-.95-2.34-.74-2.87,.14-6.85,4.72-9.42,8.18-4.29,2.11-7.03,5.4-8.72,9.51,2.48,1.18,5.63,1.39,9.14,1.05,.06-2.02,1.08-4.2,3.8-6.66,2.35-2.45,5.51-3.55,9.52-3.23,3.79-.29,5.81,.8,9.09,2.14,1.97-1.26,1.74-3.45,.19-6.18"
          />
          <path
            id="rip_1_r-3"
            data-name="rip 1 r-3"
            class="rribs-cls-4"
            d="M45.29,31.85c-.22,3.56,.76,6.47,3.49,8.45,4.71,2.87,9.53,3.94,14.48,2.95-.99-1.29,.11-4.83,2.25-8.58-3.31,.84-6.16,0-8.54-2.53"
          />
          <path
            id="rip_1_r-4"
            data-name="rip 1 r-4"
            class="rribs-cls-4"
            d="M66.63,34.53l5.77,1.41c1.28,2.78,.16,7.4,0,7.67-2.54,1.3-5.33,1.12-8.27,0-.81-3.91-.45-7.19,2.5-9.08Z"
          />
          <path
            id="rip_1_r_4"
            data-name="rip 1 r 4"
            class="rribs-cls-4"
            d="M57.28,32.45c-1.86-2.18-2.07-5.3-.48-9.32"
          />
          <path
            id="rip_1_r_5"
            data-name="rip 1 r 5"
            class="rribs-cls-4"
            d="M45.28,32.73c-.42-2.39,.5-6.51,2.38-10.65"
          />
        </g>
        <g id="rip_2_r" data-name="rip 2 r">
          <path
            id="rip_2_r-2"
            data-name="rip 2 r-2"
            class="rribs-cls-3"
            d="M79.15,20.65c-.69-.09-2.11-1.1-4.45-2.47-3.64-2.08-7.36-2.63-10.97-2.45-1-.04-1.96,.07-2.84,.44"
          />
          <path
            id="rip_2_r-3"
            data-name="rip 2 r-3"
            class="rribs-cls-3"
            d="M79.59,29.73l-14.08-7.39c-.4-.32-3.28-1.13-3.94-1.38-1.24-.56-2.75,.12-4.61,.88"
          />
          <path
            id="rip_2_r-4"
            data-name="rip 2 r-4"
            class="rribs-cls-3"
            d="M49.49,18.58c-2.58,.34-5.7,1.27-7.39,3.52"
          />
          <path
            id="rib_2_r_5"
            data-name="rib 2 r 5"
            class="rribs-cls-4"
            d="M42.56,21.85c-2.65,2.18-5.52,4.52-7.85,8.04l.53-.7c-5.32,5.57-8.33,11.19-8.69,16.88,.04,6.02,3.44,9.09,8.69,10.55,9.54,5.23,19.17,8.09,28.88,8.72-.46-2.69,0-5.08,1.38-7.17-9.41-2.06-18.79-4.12-22.78-7.03-4.38-2.72-7.01-6.09-7.45-10.27,.53-4.22,2.84-7.15,6.05-9.42,.99-1.04,2.85-2.02,4.14-2.94"
          />
          <path
            id="rip2_r_1"
            data-name="rip2 r 1"
            class="rribs-cls-4"
            d="M79.85,65.58c-.22,.24-.45,.48-.7,.73-4.38-1.26-8.62-1.37-12.66,0-3.52-2.94-2.8-5.85-.55-8.75,4.9,1.55,9.85,1.83,14.87,.17,1.68,2.62,1.35,5.23-.97,7.85h.01Z"
          />
        </g>
        <g id="rip_3_r" data-name="rip 3 r">
          <path
            id="rip_3_r-2"
            data-name="rip 3 r-2"
            class="rribs-cls-2"
            d="M46.03,36.9c-4.63,1.13-7.98,2.49-10.76,3.95"
          />
          <path
            id="rip_3_r-3"
            data-name="rip 3 r-3"
            class="rribs-cls-2"
            d="M50.6,40.79c-4.57,.83-8.88,2.85-12.98,5.87"
          />
          <path
            id="rip_3_r-4"
            data-name="rip 3 r-4"
            class="rribs-cls-4"
            d="M26.56,48.28c-5.11,2.81-7.06,7.57-6.89,13.65,1.23,5.94,4.7,10.62,10.12,14.19,9.47,4.96,18.82,8.85,27.94,10.81,3.19-1.16,4.23-3.68,3.51-7.31-12.45-3.55-23.88-7.74-31.63-14.3-5.37-5.88-5.58-10.59-2.08-14.46"
          />
          <path
            id="rip_3_r-5"
            data-name="rip 3 r-5"
            class="rribs-cls-4"
            d="M79.15,78.27c2.1,2.38,1.79,5.01,0,7.8-5.39,1.13-13.14,1.12-21.42,.86,2.72-1.66,4.51-4.05,4.5-7.88,5.12-.73,10.69-1.03,16.92-.78h0Z"
          />
        </g>
        <g id="rip_4_r" data-name="rip 4 r">
          <path
            id="rip_4_r-2"
            data-name="rip 4 r-2"
            class="rribs-cls-3"
            d="M77.28,46.65c-10.99-.44-22.05,1.32-33.17,5.33"
          />
          <path
            id="rip_4_r-3"
            data-name="rip 4 r-3"
            class="rribs-cls-3"
            d="M80.82,54.86c-8.25-2.64-15.32-1.59-21.62,1.89"
          />
          <path
            id="rip_4_r-4"
            data-name="rip 4 r-4"
            class="rribs-cls-3"
            d="M45.26,60.72c-7.23,1.23-12.74,2.72-15.65,4.61"
          />
          <path
            id="rip_4_r-5"
            data-name="rip 4 r-5"
            class="rribs-cls-3"
            d="M34.43,56.35c-2.15,.28-5.3,.57-7.87,3.2"
          />
          <path
            id="rip_4_r-6"
            data-name="rip 4 r-6"
            class="rribs-cls-4"
            d="M19.65,62.93c-5.85,8.52-3.17,15.92,.95,23.14,10.95,9.19,21.89,14.5,32.81,15.3-1.17,3.71-1.5,6.66-1.12,9-11.18-2.11-22.08-7.11-32.64-15.56-4.46-5.4-7.85-11.2-8.05-18.68-.99-6.48,3.07-11.25,8.05-13.19h0Z"
          />
          <line
            id="rip"
            class="rribs-cls-4"
            x1="23.04"
            y1="69.75"
            x2="16.37"
            y2="74.75"
          />
          <path
            id="rip_4_r-7"
            data-name="rip 4 r-7"
            class="rribs-cls-4"
            d="M53.42,101.37c7.64,.29,14.57-1.73,22.88-5.06,1.29,2.37,2.37,5.25,.99,8.62-7.61,3.27-16.05,4.87-24.99,5.44-.3-2.24-.13-5.2,1.12-9Z"
          />
        </g>
        <g id="rip_5_r" data-name="rip 5 r">
          <path
            id="rip_5_r-2"
            data-name="rip 5 r-2"
            class="rribs-cls-3"
            d="M42.1,71.56c8.83-3.48,22.87-4.55,38.51-4.89,.69,2.85-.48,4.38-1.26,6.18l-.2,.96c-10.35-.06-20.04,.32-25.73,3.43"
          />
          <path
            id="rip_5_r-3"
            data-name="rip 5 r-3"
            class="rribs-cls-3"
            d="M29.79,76.12c-3.48,.41-7.59,2.38-11.82,4.67"
          />
          <path
            id="rip_5_r-4"
            data-name="rip 5 r-4"
            class="rribs-cls-3"
            d="M37.43,79.89c-5.1,1.31-10.31,3.73-15.61,7.19"
          />
          <path
            id="rp_5_r"
            data-name="rp 5 r"
            class="rribs-cls-4"
            d="M13.55,85.15c-5.75,10.77-4.19,18.54,2.82,24.09,8.54,9.22,19.05,15.59,32.17,18.19-2.52,3.62-3.67,6.59-2.51,8.44-13.74-2.31-24.8-9.37-33.49-20.62-10.33-8.84-8.22-16.54-5.62-24.19,1.74-3.61,3.99-5.42,6.63-5.91Z"
          />
          <path
            id="rip_5_r-5"
            data-name="rip 5 r-5"
            class="rribs-cls-4"
            d="M10.06,97.99c2.18-4.45,4.28-7.13,6.3-7.6"
          />
        </g>
        <g id="rip_6_r" data-name="rip 6 r">
          <path
            id="rip_6_r-2"
            data-name="rip 6 r-2"
            class="rribs-cls-3"
            d="M37.62,97.14c12.66-3.68,25.62-6.12,39.24-5.95"
          />
          <path
            id="rip_6_r-3"
            data-name="rip 6 r-3"
            class="rribs-cls-3"
            d="M55.38,86.07c-6.79-.56-15.06,3.58-23.45,8.09"
          />
          <path
            id="rip_6_r-4"
            data-name="rip 6 r-4"
            class="rribs-cls-3"
            d="M23.88,97.99c-3.5,1.65-6.99,4.44-10.49,8.36"
          />
          <path
            id="rip_6_r-5"
            data-name="rip 6 r-5"
            class="rribs-cls-3"
            d="M28.96,101.32c-4.44,2.4-7.89,5.82-10.32,10.26"
          />
          <path
            id="rip_6_r-6"
            data-name="rip 6 r-6"
            class="rribs-cls-4"
            d="M8.06,110.51c-5.24,9.41-6.21,17.62-1.52,24.23,7.69,12.65,19.11,18.33,32.25,20.81-1.07-2.83-.59-5.58,1.76-8.25-9.51-1.75-20.13-8.12-25.39-12.56-6.17-4.82-7.49-13.8-7.11-24.23h0Z"
          />
          <path
            id="rip_6_r-7"
            data-name="rip 6 r-7"
            class="rribs-cls-4"
            d="M9.09,124.45c1.53-3.64,2.97-6.49,4.3-8.13"
          />
        </g>
        <g id="rip_7_r" data-name="rip 7 r">
          <path
            id="rip_7_r-2"
            data-name="rip 7 r-2"
            class="rribs-cls-3"
            d="M9.56,126.17c2.47-3.4,5.04-5.14,7.68-5.49"
          />
          <path
            id="rip_7_r-3"
            data-name="rip 7 r-3"
            class="rribs-cls-3"
            d="M13.21,131.75c2.06-3.48,4.88-5.7,8.06-7.29"
          />
          <path
            id="rip_7_r-4"
            data-name="rip 7 r-4"
            class="rribs-cls-3"
            d="M23.88,116.19c6.06-3.18,13.44-5.44,21.38-7.32"
          />
          <path
            id="rip_7_r-5"
            data-name="rip 7 r-5"
            class="rribs-cls-3"
            d="M31.93,120.68c15.32-5.81,29.61-9.02,44.93-10.08"
          />
          <path
            id="rip_7_r-6"
            data-name="rip 7 r-6"
            class="rribs-cls-4"
            d="M7.87,136.81c-2.39,6.9-2.4,13.06,3.37,17.46,7.37,7.7,14.24,13.24,20.05,14.23"
          />
          <path
            id="rip_7_r-7"
            data-name="rip 7 r-7"
            class="rribs-cls-4"
            d="M29.61,177.12c-8.47,1.46-17.11-3.15-26.09-20.81-4.19-6.34-3-14.98,1.19-24.89"
          />
        </g>
        <g id="rip_8_r" data-name="rip 8 r">
          <path
            id="rip_8_r-2"
            data-name="rip 8 r-2"
            class="rribs-cls-3"
            d="M31.93,131.42c-4.41,1.1-8.51,3.27-12.23,6.72"
          />
          <path
            id="rip_8_r-3"
            data-name="rip 8 r-3"
            class="rribs-cls-3"
            d="M27.81,142.83c5.28-3.79,10.56-6.26,15.84-7.41"
          />
          <path
            id="rip_8_r-4"
            data-name="rip 8 r-4"
            class="rribs-cls-4"
            d="M4.18,157.58c-3.31,5.78-4.36,11.6-3.27,17.48,4.16,13.9,11.86,17.84,19.69,21.38"
          />
          <path
            id="rip_8_r-5"
            data-name="rip 8 r-5"
            class="rribs-cls-4"
            d="M6.36,161.48c-1.94,4.13-2.25,7.85,0,10.95,4.67,7.06,10.4,11.59,16.68,14.81"
          />
          <path
            id="rip_8_r-6"
            data-name="rip 8 r-6"
            class="rribs-cls-3"
            d="M13.39,143.39c-3.05,1.2-5.01,2.3-6.1,5.95"
          />
          <path
            id="rip_8_r-7"
            data-name="rip 8 r-7"
            class="rribs-cls-3"
            d="M19.71,148.39c-3.85,1.96-6.67,3.92-8.47,5.88"
          />
          <path
            id="rip_8_r-8"
            data-name="rip 8 r-8"
            class="rribs-cls-3"
            d="M68.08,127.44c3.86-1.16,7.45-1.89,10.27-1.43"
          />
          <path
            id="rip_8_r-9"
            data-name="rip 8 r-9"
            class="rribs-cls-3"
            d="M42.78,126c7.5-3.49,14.64-5.49,21.27-5.32"
          />
        </g>
        <g id="rip_9_r" data-name="rip 9 r">
          <path
            id="rip_9_r-2"
            data-name="rip 9 r-2"
            class="rribs-cls-3"
            d="M31.93,153.9c-3.92,.57-8.73,2.7-13.11,7.58"
          />
          <path
            id="rip_9_r-3"
            data-name="rip 9 r-3"
            class="rribs-cls-3"
            d="M68.08,142.83c3.53-1.14,6.65-1.97,9.25-2.41"
          />
          <path
            id="rip_9_r-4"
            data-name="rip 9 r-4"
            class="rribs-cls-3"
            d="M61.86,150.78c2.11,.47,4.29-.02,6.52-1.41"
          />
          <line
            id="rip_9_r-5"
            data-name="rip 9 r-5"
            class="rribs-cls-3"
            x1="11.45"
            y1="178.78"
            x2="15.47"
            y2="173.78"
          />
          <line
            id="rip_9_r-6"
            data-name="rip 9 r-6"
            class="rribs-cls-3"
            x1="7.29"
            y1="173.78"
            x2="11.45"
            y2="168.79"
          />
          <path
            id="rip_9_r-7"
            data-name="rip 9 r-7"
            class="rribs-cls-4"
            d="M4.78,184c-1.7-3.97-6.14,6.74,0,21.81,4.11,5.93,8.59,7.84,13.18,8.62-1.04-2.59-.9-5.43,0-8.44-4.83-1.86-9.18-4.98-12.37-11.06,1.54-7.47,.69-9.95-.82-10.93h.01Z"
          />
          <path
            id="rip_9_r-8"
            data-name="rip 9 r-8"
            class="rribs-cls-3"
            d="M41.8,157.41c-6.84,2.24-12.75,4.96-16.43,8.8"
          />
        </g>
        <g id="rip_10_r" data-name="rip 10 r">
          <line
            id="rip_10_r-2"
            data-name="rip 10 r-2"
            class="rribs-cls-3"
            x1="31.07"
            y1="187.25"
            x2="42.41"
            y2="177.86"
          />
          <path
            id="rip_10_r-3"
            data-name="rip 10 r-3"
            class="rribs-cls-3"
            d="M10.55,201.5c-.03-2.31,2.17-4.54,4.92-7.57"
          />
          <path
            id="rip_10_r-4"
            data-name="rip 10 r-4"
            class="rribs-cls-3"
            d="M16.37,205.32c2.28-2.66,4.1-5.68,5.67-8.89"
          />
          <path
            id="rip_10_r-5"
            data-name="rip 10 r-5"
            class="rribs-cls-3"
            d="M10.34,211.5c-1.99,11.09,.59,16.49,6.03,18.31"
          />
          <path
            id="rip_10_r-6"
            data-name="rip 10 r-6"
            class="rribs-cls-3"
            d="M13.91,213.36c-.82,3.94,0,6.93,2.45,8.95"
          />
          <path
            id="rip_10_r-7"
            data-name="rip 10 r-7"
            class="rribs-cls-3"
            d="M67.13,165.39c3.87-2.32,7.5-3.48,10.72-2.62,1.32-3.29,1.01-5.94-.52-8.12-1.23,.47-2.57,.98-4.11,1.57l-6.09,9.16h0Z"
          />
          <path
            id="rip_10_r-2"
            data-name="rip 10 r"
            class="rribs-cls-3"
            d="M21.27,186.29c1.63-3.8,5.07-6.48,8.35-9.18"
          />
        </g>
        <g id="rip_11_r" data-name="rip 11 r">
          <path
            id="rip_11_r-2"
            data-name="rip 11 r-2"
            class="rribs-cls-3"
            d="M53.42,184.25c7.66-2.05,15.05-4.75,21.75-9.06,2.03,2.47,2.2,5.16,1.12,7.98-11.3,3.69-21.08,8.09-29.25,13.26-.14-4.1,1.94-8.17,6.38-12.19h0Z"
          />
          <path
            id="rip_11_r-3"
            data-name="rip 11 r-3"
            class="rribs-cls-3"
            d="M18.82,229.42c-.98,5,.79,7.91,3.21,10.32,.7-4.44,1.4-8.88,2.1-13.31l-5.31,2.99Z"
          />
          <path
            id="rip_11_r-2"
            data-name="rip 11 r"
            class="rribs-cls-3"
            d="M28.96,203.92c1.46-3.52,3.61-6.18,6.27-7.68"
          />
        </g>
        <g id="rip_12_r" data-name="rip 12 r">
          <path
            id="rip_12_r-2"
            data-name="rip 12 r-2"
            class="rribs-cls-3"
            d="M77.48,191.39c-10.39,2.78-21.16,10.24-32.13,20.11-12.86,13.42-13.22,20.72-11.76,28.25,3.44-11.69,11-20.69,20.76-28.25,5.95-5.55,12.73-9.75,21.11-11.32l2.12-2.47c.06-1.15,.05-2.97-.1-6.32h0Z"
          />
        </g>
        <path
          id="costal_cartilage_r"
          data-name="costal cartilage r"
          class="rribs-cls-4"
          d="M77.42,112.81c2.75-.05,1.86,3.44,0,7.88-4.93,1.38-9.34,6.38-13.67,12-1.46,3.65-.33,4.34,1.75,3.94,5.45-1.47,9.78-4.34,12.85-8.81,2.92,.29,2.95,1.94,2.26,3.94l-16.48,14.81-4.93,9.19c2.93,.67,6.02-2.04,9.18-6.38l7.35-5.44,6.39-11.06c2.62-1.75,3.8,.06,3.92,4.5-14.01,19.9-26.69,40.9-32.62,46.88-3.6,3.28-6.23,7.33-7.38,12.56-3.5,3.97-11.27,9.36-14.13,17.32-3.88,10.81-10.32,15.57-15.54,15.68-1.66-1.38-1.55-3.95,0-7.5,4.86-1.16,7.53-4.53,8.74-9.38-2.25,1.87-4.64,2.23-7.13,1.5-2.3-1.25-1.98-4.28,0-8.44,5.52,.21,10.76-1.14,15.57-4.88,2.52-3.35,3.3-5.17,1.69-4.88-5.48,1.49-10.32,1.46-14.62,.19-2.01-3.7-1.19-6.76,2.44-9.19,7.61,.87,14.22-.4,19.05-5.44,.73-3.54,.72-6.12-2.37-4.69-2.91,1.12-6.39,.88-10.12,0-2.15-2.17-1.28-5.13,1.69-8.62,3.9,.92,7.56,.36,10.81-2.81,3.97-4.4,4.05-7.12,1.75-8.81-1.95,.8-3.77,1-5.06-1.31-1.36-2.42-.65-5.19,1.76-8.25,4.59,.48,8.58-.41,11.61-3.56,2.14-2.94,1.41-4.78-.96-6l-5.17-1.88c-.82-3.03,.34-5.8,2.51-8.44,4.62-.04,8.99-1,12.81-3.94,7.4-5.45,14.56-10.67,16.07-10.69h-.02Z"
        />
        <g id="sternum">
          <path
            id="sternum_16"
            data-name="sternum 16"
            class="rribs-cls-4"
            d="M98.13,30.45c.14,2.88,2.46,3.88,5.91,4.92-.96,3.12-.8,5.43,.76,6.71-1.75,3.59-3.35,5.79-4.84,7-1.48,3.22-1.79,8.03-2.74,8.48,.78,.28,1.74,2.21,.56,3.3-1.72,1.58-1.91,3.66,.28,6.37,.1,1.05,.86,2.18,.37,3.03-1.53,2.67,.07,3.11,.75,4.1"
          />
          <path
            id="sternum_14"
            data-name="sternum 14"
            class="rribs-cls-4"
            d="M82.24,56.75l-.37-1.04c-.39-2.47-.96-4.16-1.74-4.87-1.29-2.63-3.06-4.75-5.34-6.33-1.44-1.14-2.31-1.1-2.18-1.69"
          />
          <path
            id="sternum_13"
            data-name="sternum 13"
            class="rribs-cls-4"
            d="M97.78,56.82c-1.67,1.31-4.78,1.76-8.54,1.83-4.04,.21-6.91-.47-7.37-2.94"
          />
          <line
            id="sternum_12"
            data-name="sternum 12"
            class="rribs-cls-4"
            x1="80.82"
            y1="57.73"
            x2="82.61"
            y2="57.19"
          />
          <path
            id="sternum_11"
            data-name="sternum 11"
            class="rribs-cls-4"
            d="M79.15,77.24l-1.73,.95c1.29-.07,3.48-7.25,2.41-7.73"
          />
          <path
            id="sternum_10"
            data-name="sternum 10"
            class="rribs-cls-4"
            d="M77.42,86.07c1.43,1.67,1.25,3.38-.57,5.12,.85,1.85,.93,3.59-.57,5.12"
          />
          <path
            id="sternum_9"
            data-name="sternum 9"
            class="rribs-cls-4"
            d="M77.28,104.93c-1.61,3.12-1.04,5.59,.14,7.88"
          />
          <path
            id="sternum_8"
            data-name="sternum 8"
            class="rribs-cls-4"
            d="M77.42,120.68c-.27,2.13,.17,4.58,.93,7.12"
          />
          <line
            id="sternum_7"
            data-name="sternum 7"
            class="rribs-cls-4"
            x1="80.61"
            y1="131.75"
            x2="82.12"
            y2="132.87"
          />
          <path
            id="sternum_6"
            data-name="sternum 6"
            class="rribs-cls-4"
            d="M94.1,135.87c-.94,0-2.33,1.67-3.37,.94-1.56-.31-3,0-4.69-.94"
          />
          <path
            id="sternum_5"
            data-name="sternum 5"
            class="rribs-cls-4"
            d="M100.81,83.32c-3.4,5.47-2.74,9.75,.09,13.39"
          />
          <path
            id="sternum_4"
            data-name="sternum 4"
            class="rribs-cls-4"
            d="M101.79,103.34c-2.81,4.66-2.52,7.65-.78,9.28"
          />
          <path
            id="sternum_3"
            data-name="sternum 3"
            class="rribs-cls-4"
            d="M87.73,136.99c-4.58,6.37-5.81,13.25-5.06,20.42,1.4,5.71,3.63,8.51,6.56,8.8,2.41-.82,4.49-2.09,4.87-5.78,1.92-7.23,2.68-7.14,3.64-7.78l-1.95-9.26-3.02-6.96-5.05,.56h.01Z"
          />
          <path
            id="sternum_17"
            data-name="sternum 17"
            class="rribs-cls-4"
            d="M98.9,126.39c-1.07,2.03-1.28,3.87-.39,5.49l-1.29,1"
          />
          <path
            id="sternum_1"
            data-name="sternum 1"
            class="rribs-cls-4"
            d="M98.39,127.07c2.66-2.23,3.05-5.89,1.32-9.09"
          />
          <path
            id="sternum_18"
            data-name="sternum 18"
            class="rribs-cls-4"
            d="M99.09,73.89c-1.55,3.65-1.09,6.96,1.22,9.94"
          />
          <path
            id="sternum_19"
            data-name="sternum 19"
            class="rribs-cls-4"
            d="M101.53,103.7c-1.95-1.57-2.08-4.16-.84-7.5"
          />
          <path
            id="sternum_20"
            data-name="sternum 20"
            class="rribs-cls-4"
            d="M100.5,111.95c-1.29,2.32-1.48,4.43-.84,6.38"
          />
          <line
            id="sternum_21"
            data-name="sternum 21"
            class="rribs-cls-4"
            x1="97.69"
            y1="132.48"
            x2="93.73"
            y2="135.96"
          />
          <path
            id="sternum_22"
            data-name="sternum 22"
            class="rribs-cls-4"
            d="M72.4,35.94c2.73-.25,5.14-1.89,7.09-5.65"
          />
        </g>
        <g id="thoracic_vertebrae" data-name="thoracic vertebrae">
          <path
            id="thoracic_vertebrae_12"
            data-name="thoracic vertebrae 12"
            class="rribs-cls-3"
            d="M71.3,3.49c.99-.07,2.44-.02,2.69-.45,.67-1.15,5.04-1.85,3.98-1.61,1.79-.22,3.04,.73,3.99,2.78,.4,.87,.03,.95-.98,.84-1.09-.24-1.81,.17-1.93,1.65,.91,.69,1.11,1.55,.08,2.7-.53-.31-.86-.76-.96-1.4h-4.08c-2.44-.22-4.54-1.05-6.05-2.95-.59-.72-.48-1.28,.84-1.55,0,0,1.22,.07,2.42,0h0Z"
          />
          <path
            id="thoracic_vertebrae_11"
            data-name="thoracic vertebrae 11"
            class="rribs-cls-3"
            d="M105.01,2.84c-.83-.08-2.04-.03-2.25-.5-.56-1.28-4.21-2.05-3.32-1.79-1.5-.25-2.54,.81-3.34,3.09-.34,.97-.02,1.06,.82,.94,.91-.27,1.51,.19,1.62,1.84-.76,.77-.93,1.72-.07,2.99,.44-.34,.72-.85,.8-1.55h3.41c2.04-.25,3.79-1.16,5.05-3.28,.49-.8,.4-1.42-.71-1.72,0,0-1.02,.08-2.02-.02h.01Z"
          />
          <path
            id="thoracic_vertebrae_10"
            data-name="thoracic vertebrae 10"
            class="rribs-cls-3"
            d="M81.77,5.12c2.14,.63,4.46,.86,6.98,.64,2.67-.18,4.85-.59,6.38-1.14l.84-.28"
          />
          <path
            id="thoracic_vertebrae_9"
            data-name="thoracic vertebrae 9"
            class="rribs-cls-3"
            d="M79.99,16.53v2.67l-.84,4.08c1.32,2.3,2.09,4.58,.84,6.77,2.11-.61,4.58,.33,7.17,1.79,2.75,.26,5.12-.55,7.03-2.66,2.5-.42,3.66,.11,3.94,1.27l.98-1.27v-5.9c.51-2.9,.11-4.61-1.55-4.7,1.01-.41,1.57-1.08,1.55-2.06-.41-.91-.89-1.1-1.41-.83-2.19,1.52-5.2,2.28-8.84,2.48-3.15-.22-6.15-.61-8.88-1.65h.01Z"
          />
          <path
            id="thoracic_vertebrae_8"
            data-name="thoracic vertebrae 8"
            class="rribs-cls-3"
            d="M82.12,153.58l-4.79,1.07c1.83,3.34,1.66,7.3,.52,11.56,3.03-1.17,5.75-1.42,8.19-.82"
          />
          <path
            id="thoracic_vertebrae_7"
            data-name="thoracic vertebrae 7"
            class="rribs-cls-3"
            d="M92.62,164.18c2.58-.97,4.67-.29,6.5,1.2-.97-4.78-.84-8.39,.59-10.74l-1.97-2"
          />
          <path
            id="thoracic_vertebrae_6"
            data-name="thoracic vertebrae 6"
            class="rribs-cls-3"
            d="M77.42,150.78c-1.26,1.85-1.29,2.79-.09,2.8,1.9-.72,3.59-1.37,5.13-1.4"
          />
          <path
            id="thoracic_vertebrae_5"
            data-name="thoracic vertebrae 5"
            class="rribs-cls-3"
            d="M97.78,151.61h1.93c.96-.84,1.79-1.82,0-4.73"
          />
          <path
            id="thoracic_vertebrae_4"
            data-name="thoracic vertebrae 4"
            class="rribs-cls-3"
            d="M79.9,166c.67,.53,1.25,1.16,0,3.48,7.13-.24,14.8-.38,17.89-.99-1.39-.86-.93-2.07,0-3.36"
          />
          <path
            id="thoracic_vertebrae_3"
            data-name="thoracic vertebrae 3"
            class="rribs-cls-3"
            d="M76.85,171.31c-3.1,2.85-.22,6.55,1.07,11.86h1.97c6.15-.88,12.81-.77,19.81,0,1.78-5.21,1.14-9.69,0-13.69-9.34-.53-18.16-.72-22.86,1.83h0Z"
          />
          <path
            id="thoracic_vertebrae_2"
            data-name="thoracic vertebrae 2"
            class="rribs-cls-3"
            d="M79.92,186.04c1.21-.96,1.2-1.91-.03-2.87h17.02c-1,.96-1,1.91,0,2.87h-16.99Z"
          />
          <path
            id="thoracic_vertebrae_1"
            data-name="thoracic vertebrae 1"
            class="rribs-cls-3"
            d="M76.86,188.18c.98,4.02,1.36,8.3,0,13.31,8.23-.51,16.79-1.19,21.89,0,2.66,.9,4.02,.13,3.79-2.7-1.84-1.09-2.19-3.85-1.8-7.43,1.11-1.95,1.31-3.81-1.62-5.33-9.42,1.21-19.67-1.56-22.26,2.15Z"
          />
          <path
            id="thoracic_vertebrae_13"
            data-name="thoracic vertebrae 13"
            class="rribs-cls-4"
            d="M99.84,147.29c-2.21-4.45-3.54-9.03-3.19-13.88"
          />
          <path
            id="thoracic_vertebrae_14"
            data-name="thoracic vertebrae 14"
            class="rribs-cls-4"
            d="M98.47,9.41c-1.26,2.07-1.53,4.03-.13,5.79"
          />
        </g>
      </g>
      <g id="UpperRibs_Overlay_R" data-name="UpperRibs Overlay R">
        <path
          id="UpperRibs_Overlay_R_1_"
          data-name="UpperRibs Overlay R (1)"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, RibsRight.id, RibsRight.children.upperRibs),
            });
          }}
          class={`rribs-cls-1 ${isSelected(
            selected,
            RibsRight.children.upperRibs
          )}`}
          d="M79.97,9.2l-1.79-1.2c-4.32,.71-7.77-.14-10.13-2.95-1.87-1.52-4.41-.39-7.2,1.71l-3.94,5.44c-3.25,1.48-6.05,3.81-8.44,6.94-3.46,.57-7.26,3.41-11.25,7.5-6.58,6.25-10.11,13.3-10.5,21.19-5.68,3.39-7.56,8.89-7.31,15.56-8.17,2.42-8.86,10.58-6.38,21.19-9.78,7.75-9.48,16.66-5.06,26.06-3.5,6.25-5.61,12.93-3.38,21-3.52,8.4-5.77,16.87-.38,25.69,6.16,10.85,12.57,21.13,24.38,19.5,4.64,1.67,9,1.31,13.12-.75,12.05-9.54,24.94-14.47,35.25-25.88,2.13-2.3,5.73-7.74,9.56-13.69-.96-3.01-2.29-4.59-4.31-3.56l-1.5-1.31c.65-2.66-.43-3.66-2.06-4.12-1.2-1.21-1.41-4.01-1.31-7.31,2.85-5.93,1.67-7.19,0-7.88-1.23-1.33-1.19-4.01-.38-7.5,1.44-3.51,.99-6.03-.75-7.88,1.13-1.91,1.51-3.85,.94-5.81,1.33-1.4,1.47-3.05,.75-4.88,3.55-2.15,3.26-5.16,.94-8.62,.45-1.65,.83-3.71,1.12-6.38,1.17-1.72,1.08-3.68,0-5.81,2.55-2.53,2.16-5.11,1.12-7.69l1.12-1.31c-1.05-5.94-4.67-10.08-9.56-13.31,.8-2.13,.7-4.6,0-7.31,2.84-.71,5.38-2.39,7.31-6,.98-2.82,.56-4.84-.38-6.56,.42-1.86,.78-3.93,.94-6.75-1.19-.35-1.64-.79-1.5-1.31,2.14-.95,1.98-3.22,.94-6Z"
        />
      </g>
      <g id="UnderRibs_Overlay_R" data-name="UnderRibs Overlay R">
        <path
          id="UnderRibs_Overlay_R_1_"
          data-name="UnderRibs Overlay R (1)"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, RibsRight.id, RibsRight.children.lowerRibs),
            });
          }}
          class={`rribs-cls-1 ${isSelected(
            selected,
            RibsRight.children.lowerRibs
          )}`}
          d="M76.97,191.54c-36.19,15.92-45.1,32.53-43.41,49.41,9.29-19.71,21.77-35.94,43.31-42.28l.38,2.72-.18-13.03v-.47s1.72-.96,1.72-.96l1.46-.82,.19-3.09-1.41-.1-1.31-.09-.31-.93c-.4-2.77-1.14-5.12-2.32-6.95,.03-1.08,.23-2.23,.84-3.47l4.12-2.06c1.02-1.54,.74-2.72,.28-3.84-1.04,.1-2.02,.25-2.72,.66,.33-1.51,.32-2.69,.28-3.56,1.77-3.52-.01-5.48-.38-8.06-1.9,.63-3.73,1.4-5.16,3-7.99,3.56-19.28,10.7-31.41,19.22-4,1.72-8.04,1.84-12.09,.66-10.26,.74-17.86-7.92-24.84-18.75-4.35,7.1-5.07,15.12-.19,24.56-2.69,5.18-3.63,11.74,.56,22.31,1.19,2.14,3.19,4.15,5.62,6.09-.75,7.52-1.07,14.8,5.53,18.19,.82,.45,1.8,.36,2.91-.19-.05,4.82,.94,8.35,3.38,10.12,.98-4.11,1.78-8.49,1.78-13.12,4.31-3.94,7.58-9.22,9.56-16.12,3.51-5.01,7.98-9.57,13.22-13.78l30-13.69,2.72,.19,1.78,1.03c-2.62,2.22-4.25,4.58-3.94,7.22Z"
        />
      </g>
    </svg>
  );
}

export default FrontView;
