import style from "./style.scss";

function SteissbeinVdLiegend1721(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 517 773.9">

<g id="BG">
  <path id="BG4" class="Coccyx_ap1721_cls2" d="M428.6,357.4c-1.9,1.2-10.6,6.2-13.7,9.1-4.2,4-2.9,4.4-8.2,10-6.2,6.6-9.7,8-13.8,14.5-1.4,2.3-1.2,2.5-2.7,4.6-2.5,3.6-4,8.8-9,13-9,7.8-9.8,4.5-10.7,5.5-3.6,4.2-5.4,8.4-6.8,11.4-2,4.6-3.1,8.8-3.7,11.9-6.1,2-9.7,5-11.9,7.3-4.5,4.8-4,8.2-9.1,13.7-3.7,4-7.6,6-10,7.3-8.9,4.7-11.8,2.1-18.2,6.4-.5.4-3.7,3.9-10,10.9-3.3,3.7-6.7,7.3-10,10.9-3.6,4-4.1,4.6-5.5,5.5-3.1,1.9-6,1.8-13.7,1.8-4.3,0-5.8.1-11.9,0-8.7-.2-15.4,0-20,.1h0c-3.1-.8-5.1-1-6.8-.9-1.8,0-2.9.3-4.1.5-4.4.6-8.4,0-10-.4-3.1-.8-6.3-3.9-12.9-10.3-4.4-4.2-6.8-6.7-7.3-7.3l-4.6-4.6c-2.6-2.7-3.1-3.5-4.6-4.6-1.7-1.2-2.9-1.3-5.8-2.5-12.8-5.2-14.8-5.2-19.7-7.5-8.5-3.9-14.5-6.6-17.3-12.8-1.1-2.5-.5-2.9-2.7-9.1-.6-1.7-1-2.6-4.6-10.9-3.1-7.3-3.2-7.5-3.6-8.2-3.2-5.2-4.9-4.1-8.2-9.1-2.6-4-2.2-5.8-5.8-10.6-.8-1.1,1.5,1.5,0,0-4.8-4.8-10.8-8.1-14.3-12.1-2-2.4-3-4.8-3.6-6.4-1.2-2.9-1.6-5.4-1.9-7.3-.5-3.3-.3-4.6-1.1-8.7-.3-1.6-.4-2.4-.7-3.1-2.2-6.1-8-11.4-8-11.4,0,0-1.8-1.7-3-3.2-5-6.4-2.4-9.6-7.3-15.5-3.8-4.6-5.4-2.7-10.9-8.2-5.2-5.2-4.5-7.6-10-12.8-2.5-2.3-5-5.1-9.1-6.4-4.8-1.5-6.6.5-10.8-.7-5.2-1.4-8.1-6-10.4-9.8-5.2-8.4-6.1-16.9-6.4-20.5-1.8-20.6-2.7-31.4-.7-51,1-10,2.4-17.6,5.1-32.8,1.8-10.3,4-21.8,7-35.3,0,0,4-9.7,7-13.9,26.2-37.8,82.4-34.2,220.6-28.2,107.8,4.7,136.1-5.7,155.4,3.4,2.6,1.2,6.1,3.8,10.5,7.6,38.8,32.3,36.4,83.9,32.6,165.9-.2,5.1-.9,11.6-5.5,16.2-3.8,3.8-7.1,3.1-10.6,7.1-3.7,4.1-2.7,7.7-4.5,16-2,8.9-4.6,20.2-12.9,25.4h0Z"/>
  <path id="BG3" class="Coccyx_ap1721_cls2" d="M229.6,645.3c2.1-1.8,3.8-.4,12.8-2.7,3.3-.9,4.5-1.2,4.5-1.2,5.2-1.6,6.4-2.5,8.3-2,1.6.4,1.4,1.2,4.1,3.2,3.8,2.8,5.3,1.9,6.8,3.7,1.9,2.2.4,4.3.5,12.3,0,6.8,1.2,7.8,0,10.9-1,2.4-2.6,3.9-5.9,6.8-5.2,4.6-8.4,7.5-12.8,7.3-2.5,0-4.3-1.2-7.8-3.2-2.2-1.3-5.3-3.1-7.8-6.4-3.1-4-3.5-8.1-4.1-14.1-.8-8-1.3-12.3,1.4-14.6h0Z"/>
  <path id="BG2" class="Coccyx_ap1721_cls2" d="M220.5,614.3c2.2,3.3,6,1,14.1,4.6,5.2,2.3,5.4,4,9.1,4.6,4.3.6,5.1-1.6,10.9-2.3,7.5-.9,9.2,2.4,14.5.6,5.4-1.8,8-6.8,8.3-7.5,1.8-3.6.5-4.9,1.4-11.8,1.2-9.4,4.2-11.8,2.3-15-1.1-1.9-2.8-2.1-9.6-4.6-9.7-3.5-9.1-4-12.8-5-13.4-3.7-25.9,2.7-27.8,3.7-2.6,1.4-13.9,7.3-13.7,14.6,0,2.4,1.3,2.8,2.3,7.3,1.4,6.7-.8,8.4.9,10.9h0Z"/>
  <path id="BG1" class="Coccyx_ap1721_cls2" d="M255.2,564.2c4.5.7,6.2-4.5,13.7-5.9,4.4-.9,5.4.6,9.6.5,9.1-.3,18.7-7.8,22.3-17.3.4-1.1,5.2-14-1.4-19.6-3-2.5-8.5-3.7-12.3-1.8-1.6.8-1.7,1.5-3.6,2.3-.2,0-4.6,1.8-8.7,0-5.1-2.2-4.7-7.5-8.2-8.2-3-.5-3.8,3.1-9.1,5.5-2.8,1.2-7.5,1-16.9.5-9.1-.5-10.4-1.2-11.4-1.8-4.3-2.9-5.1-8-7.3-7.8-1.4.2-1.4,2.3-3.6,4.6-2.5,2.6-5.6,3.2-7.8,3.7-8.4,1.7-12.4-3.2-15.9-.9-3.1,2-3.6,8-2.3,12.3,1.9,6.4,7,6.3,15.5,14.6,8.6,8.5,7.1,12.2,14.1,16,4.5,2.4,12.6,4.9,17.8,1.4,2.9-2,3.1-4.8,5.5-5,3.9-.4,5,6.5,10,7.3h0Z"/>
</g>
<g id="Kreuzbein_Highlights" data-name="Kreuzbein Highlights">
  <path id="Kreuzbein_Highlights3" data-name="Kreuzbein Highlights3" class="Coccyx1721Fill" style= {{
            fill: props.colors.Coccyx1721Fill,
            stroke: props.colors.Coccyx1721Fill,
          }} d="M229.5,646.4c2.1-1.8,3.8-.4,12.8-2.7,3.3-.9,4.5-1.2,4.5-1.2,5.2-1.6,6.4-2.5,8.3-2,1.6.4,1.4,1.2,4.1,3.2,3.8,2.8,5.3,1.9,6.8,3.7,1.9,2.2.4,4.3.5,12.3,0,6.8,1.2,7.8,0,10.9-1,2.4-2.6,3.9-5.9,6.8-5.2,4.6-8.4,7.5-12.8,7.3-2.5,0-4.3-1.2-7.8-3.2-2.2-1.3-5.3-3.1-7.8-6.4-3.1-4-3.5-8.1-4.1-14.1-.8-8-1.3-12.3,1.4-14.6h0Z"/>
  <path id="Kreuzbein_Highlights2" data-name="Kreuzbein Highlights2" class="Coccyx1721Fill" style= {{
            fill: props.colors.Coccyx1721Fill,
            stroke: props.colors.Coccyx1721Fill,
          }} d="M220.4,615.4c2.2,3.3,6,1,14.1,4.6,5.2,2.3,5.4,4,9.1,4.6,4.3.6,5.1-1.6,10.9-2.3,7.5-.9,9.2,2.4,14.5.6,5.4-1.8,8-6.8,8.3-7.5,1.8-3.6.5-4.9,1.4-11.8,1.2-9.4,4.2-11.8,2.3-15-1.1-1.9-2.8-2.1-9.6-4.6-9.7-3.5-9.1-4-12.8-5-13.4-3.7-25.9,2.7-27.8,3.7-2.6,1.4-13.9,7.3-13.7,14.6,0,2.4,1.3,2.8,2.3,7.3,1.4,6.7-.8,8.4.9,10.9h0Z"/>
  <path id="Kreuzbein_Highlights1" data-name="Kreuzbein Highlights1" class="Coccyx1721Fill" style= {{
            fill: props.colors.Coccyx1721Fill,
            stroke: props.colors.Coccyx1721Fill,
          }} d="M255,565.3c4.5.7,6.2-4.5,13.7-5.9,4.4-.9,5.4.6,9.6.5,9.1-.3,18.7-7.8,22.3-17.3.4-1.1,5.2-14-1.4-19.6-3-2.5-8.5-3.7-12.3-1.8-1.6.8-1.7,1.5-3.6,2.3-.2,0-4.6,1.8-8.7,0-5.1-2.2-4.7-7.5-8.2-8.2-3-.5-3.8,3.1-9.1,5.5-2.8,1.2-7.5,1-16.9.5-9.1-.5-10.4-1.2-11.4-1.8-4.3-2.9-5.1-8-7.3-7.8-1.4.2-1.4,2.3-3.6,4.6-2.5,2.6-5.6,3.2-7.8,3.7-8.4,1.7-12.4-3.2-15.9-.9-3.1,2-3.6,8-2.3,12.3,1.9,6.4,7,6.3,15.5,14.6,8.6,8.5,7.1,12.2,14.1,16,4.5,2.4,12.6,4.9,17.8,1.4,2.9-2,3.1-4.8,5.5-5,3.9-.4,5,6.5,10,7.3h0Z"/>
</g>
<g id="Steißbein">
  <g id="Kreuzbein">
    <path id="Kreuzbein3" class="Coccyx_ap1721_cls4" d="M229.6,645.7c2.1-1.8,3.8-.4,12.8-2.7,3.3-.9,4.5-1.2,4.5-1.2,5.2-1.6,6.4-2.5,8.3-2,1.6.4,1.4,1.2,4.1,3.2,3.8,2.8,5.3,1.9,6.8,3.7,1.9,2.2.4,4.3.5,12.3,0,6.8,1.2,7.8,0,10.9-1,2.4-2.6,3.9-5.9,6.8-5.2,4.6-8.4,7.5-12.8,7.3-2.5,0-4.3-1.2-7.8-3.2-2.2-1.3-5.3-3.1-7.8-6.4-3.1-4-3.5-8.1-4.1-14.1-.8-8-1.3-12.3,1.4-14.6h0Z"/>
    <path id="Kreuzbein2" class="Coccyx_ap1721_cls4" d="M220.5,614.7c2.2,3.3,6,1,14.1,4.6,5.2,2.3,5.4,4,9.1,4.6,4.3.6,5.1-1.6,10.9-2.3,7.5-.9,9.2,2.4,14.5.6,5.4-1.8,8-6.8,8.3-7.5,1.8-3.6.5-4.9,1.4-11.8,1.2-9.4,4.2-11.8,2.3-15-1.1-1.9-2.8-2.1-9.6-4.6-9.7-3.5-9.1-4-12.8-5-13.4-3.7-25.9,2.7-27.8,3.7-2.6,1.4-13.9,7.3-13.7,14.6,0,2.4,1.3,2.8,2.3,7.3,1.4,6.7-.8,8.4.9,10.9h0Z"/>
    <path id="Kreuzbein1" class="Coccyx_ap1721_cls4" d="M255.1,564.6c4.5.7,6.2-4.5,13.7-5.9,4.4-.9,5.4.6,9.6.5,9.1-.3,18.7-7.8,22.3-17.3.4-1.1,5.2-14-1.4-19.6-3-2.5-8.5-3.7-12.3-1.8-1.6.8-1.7,1.5-3.6,2.3-.2,0-4.6,1.8-8.7,0-5.1-2.2-4.7-7.5-8.2-8.2-3-.5-3.8,3.1-9.1,5.5-2.8,1.2-7.5,1-16.9.5-9.1-.5-10.4-1.2-11.4-1.8-4.3-2.9-5.1-8-7.3-7.8-1.4.2-1.4,2.3-3.6,4.6-2.5,2.6-5.6,3.2-7.8,3.7-8.4,1.7-12.4-3.2-15.9-.9-3.1,2-3.6,8-2.3,12.3,1.9,6.4,7,6.3,15.5,14.6,8.6,8.5,7.1,12.2,14.1,16,4.5,2.4,12.6,4.9,17.8,1.4,2.9-2,3.1-4.8,5.5-5,3.9-.4,5,6.5,10,7.3h0Z"/>
  </g>
  <path id="Steißbein83" class="Coccyx_ap1721_cls4" d="M31,297.7c2.3-2.5,6.9,15.1,20,10.3"/>
  <path id="Steißbein82" class="Coccyx_ap1721_cls4" d="M51,308c-1,3.2,4.3,2.1,15.5,13.2"/>
  <path id="Steißbein81" class="Coccyx_ap1721_cls4" d="M66.5,321.2c2.8,4.6,8,12.1,15.9,15.5"/>
  <path id="Steißbein80" class="Coccyx_ap1721_cls4" d="M82.4,336.7c3.6,11.6,12.3,22.5,15.9,24.6"/>
  <path id="Steißbein79" class="Coccyx_ap1721_cls4" d="M98.3,361.3c2.9,18.7,9.2,34.5,13.2,35.5"/>
  <path id="Steißbein78" class="Coccyx_ap1721_cls4" d="M111.6,396.9c11.3,8.9,27.1,27.3,31.9,46"/>
  <path id="Steißbein77" class="Coccyx_ap1721_cls4" d="M143.5,442.9c.4,13.2,21.3,24.4,46,31"/>
  <path id="Steißbein76" class="Coccyx_ap1721_cls4" d="M189.5,473.9c4.3,5.5,16.7,12.3,22.8,24.1"/>
  <path id="Steißbein75" class="Coccyx_ap1721_cls4" d="M212.3,498c0,4.3,42.4,6.4,74.7,2.8"/>
  <path id="Steißbein74" class="Coccyx_ap1721_cls4" d="M287,500.8c8.2-14.5,15.2-18.1,25.1-27"/>
  <path id="Steißbein73" class="Coccyx_ap1721_cls4" d="M312.1,473.9c4.5-4.1,19.6-8,30.5-15.5"/>
  <path id="Steißbein72" class="Coccyx_ap1721_cls4" d="M342.6,458.4c1.3-9.8,9.8-18.2,17.3-21"/>
  <path id="Steißbein71" class="Coccyx_ap1721_cls4" d="M359.9,437.4c1.7-11.9,11.3-24.6,21.4-28.7"/>
  <path id="Steißbein70" class="Coccyx_ap1721_cls4" d="M381.3,408.7c3.8-7.4,19.6-25.6,34.6-43.8"/>
  <path id="Steißbein60" class="Coccyx_ap1721_cls4" d="M416,365c3.9-1.8,8.4-5.5,18.2-12.3"/>
  <path id="Steißbein59" class="Coccyx_ap1721_cls4" d="M375.9,382.3c0,10.6-11,19.1-24.6,19.1"/>
  <path id="Steißbein58" class="Coccyx_ap1721_cls4" d="M375.9,382.3c0-5.5-36.9-41.5-68.8-10"/>
  <path id="Steißbein57" class="Coccyx_ap1721_cls4" d="M402.8,340.8c0,6.5-14.4,11.9-32.1,11.9"/>
  <path id="Steißbein56" class="Coccyx_ap1721_cls4" d="M329.4,428.3c-.3,6.2-10.1,13.9-22.3,14.6"/>
  <path id="Steißbein55" class="Coccyx_ap1721_cls4" d="M307.1,440.2c2.9,12.7.3,23.9-4.3,24.6"/>
  <path id="Steißbein54" class="Coccyx_ap1721_cls4" d="M302.8,464.8c-5.5,5-10.6-2.9-13.9-12.3"/>
  <path id="Steißbein53" class="Coccyx_ap1721_cls4" d="M411.9,329c0-1.5-48.2-33.7-97.5-2.7"/>
  <path id="Steißbein52" class="Coccyx_ap1721_cls4" d="M395.9,275.7c-4,10.1-19.3,20.1-32.4,23.7"/>
  <path id="Steißbein51" class="Coccyx_ap1721_cls4" d="M409.1,291.2c-6-43.3-62.5-50-90.9-3.6"/>
  <path id="Steißbein50" class="Coccyx_ap1721_cls4" d="M295.8,250.6c22.3,35.2,32.9,45.1,31.5,68.4"/>
  <path id="Steißbein49" class="Coccyx_ap1721_cls4" d="M299.5,208.7c5.7,11.1,19.8,9.2,39.4,54.2"/>
  <path id="Steißbein48" class="Coccyx_ap1721_cls4" d="M209.1,437.4c0,15.1-5.7,27.3-12.8,27.3"/>
  <path id="Steißbein47" class="Coccyx_ap1721_cls4" d="M189.5,445.2c0,10.8,3.1,19.6,6.8,19.6"/>
  <path id="Steißbein46" class="Coccyx_ap1721_cls4" d="M158.5,391.9c0,17.2,3.6,31.2,8,31.2"/>
  <path id="Steißbein45" class="Coccyx_ap1721_cls4" d="M166.5,423.1c0,7.9,10.3,14.4,23,14.4"/>
  <path id="Steißbein44" class="Coccyx_ap1721_cls4" d="M219.1,339c0,16.1-5.5,29.2-12.3,29.2"/>
  <path id="Steißbein43" class="Coccyx_ap1721_cls4" d="M95.2,334c0,10.3,9.2,18.7,20.5,18.7"/>
  <path id="Steißbein42" class="Coccyx_ap1721_cls4" d="M115.7,310.3c0,6,10.2,10.9,22.8,10.9"/>
  <path id="Steißbein41" class="Coccyx_ap1721_cls4" d="M115.7,258.8c0,4.8,8.8,8.7,19.6,8.7"/>
  <path id="Steißbein40" class="Coccyx_ap1721_cls4" d="M259.7,270.2c27.6,24.9,30.9,60.8,31.4,68.8"/>
  <path id="Steißbein39" class="Coccyx_ap1721_cls4" d="M291.1,339c-22.3-6-11.6,35.4-6.8,47.9"/>
  <path id="Steißbein38" class="Coccyx_ap1721_cls4" d="M284.3,386.9c-7.5,3.5-6.2,12.1-5,14.6"/>
  <path id="Steißbein37" class="Coccyx_ap1721_cls4" d="M279.3,401.4c6.4,13.5,10,35.5,7.8,49.7"/>
  <path id="Steißbein36" class="Coccyx_ap1721_cls4" d="M254.7,270.2c-3.6,1.6-9.3,8.3-12.8,14.6"/>
  <path id="Steißbein35" class="Coccyx_ap1721_cls4" d="M241.9,284.8c-11.8,13.9-24.9,35.2-29.6,47.4"/>
  <path id="Steißbein34" class="Coccyx_ap1721_cls4" d="M221.9,247.4c-28,20.2-35,52.1-38.3,60.6"/>
  <path id="Steißbein33" class="Coccyx_ap1721_cls4" d="M206.8,193.6c-22.2,24.5-30.9,55.3-35.1,62"/>
  <path id="Steißbein32" class="Coccyx_ap1721_cls4" d="M192.9,258.8c-5.2-24.9-58.1-38.1-113.7,14.6"/>
  <path id="Steißbein31" class="Coccyx_ap1721_cls4" d="M409.1,162.2c0-4.5-10-8.2-22.4-8.2"/>
  <path id="Steißbein30" class="Coccyx_ap1721_cls4" d="M386.7,154c-6.6-.6-23.2,4-30.9,21.9"/>
  <path id="Steißbein29" class="Coccyx_ap1721_cls4" d="M355.8,175.9c-4.6,16.4,4.1,32.8,4.1,32.8"/>
  <path id="Steißbein28" class="Coccyx_ap1721_cls4" d="M314.4,130.3c10.7,16.4,36.9,29.6,40.5,62"/>
  <path id="Steißbein27" class="Coccyx_ap1721_cls4" d="M281.8,125.7c0,21.7,4.2,39.2,9.3,39.2"/>
  <path id="Steißbein26" class="Coccyx_ap1721_cls4" d="M265.1,125.7c-3.4,11.7-13.5,24.6-20.5,32.3"/>
  <path id="Steißbein25" class="Coccyx_ap1721_cls4" d="M219.1,123.9c-18.4,19.8-41.7,48.3-47.4,68.3"/>
  <path id="Steißbein24" class="Coccyx_ap1721_cls4" d="M189.3,159.9c5-13.2-24.9-21.9-73.6-5.9"/>
  <path id="Steißbein23" class="Coccyx_ap1721_cls4" d="M115.7,154c-9.8,3.9-19.2,20-25.3,30.5"/>
  <path id="Steißbein22" class="Coccyx_ap1721_cls4" d="M277.4,231.9c4.6-24,0-44.3-5.5-44.7"/>
  <path id="Steißbein21" class="Coccyx_ap1721_cls4" d="M272,187.3c-8.3,10.2-15.1,27.6-21,33.3"/>
  <path id="Steißbein20" class="Coccyx_ap1721_cls4" d="M279.3,196.4c-4.3-13.9-7.2-30.8-9.6-34.2"/>
  <path id="Steißbein19" class="Coccyx_ap1721_cls4" d="M269.7,162.2c-17.2,33.6-21,56.6-21.4,54.2"/>
  <path id="Steißbein18" class="Coccyx_ap1721_cls4" d="M90.4,307c1.5-1.8,19.5-10.9,39-10.4"/>
  <path id="Steißbein17" class="Coccyx_ap1721_cls4" d="M129.3,296.6c.5-.5,20.2-1.9,42.4,2.7"/>
  <path id="Steißbein16" class="Coccyx_ap1721_cls4" d="M171.7,299.4c2.8,2.9,8.5,4.8,6.3,9.6"/>
  <path id="Steißbein15" class="Coccyx_ap1721_cls4" d="M115.7,361.3c2.3-11.2,20.4-20.6,37.1-24.6"/>
  <path id="Steißbein14" class="Coccyx_ap1721_cls4" d="M152.7,336.7c.4-.3,15.6.9,33.6,6.6"/>
  <path id="Steißbein13" class="Coccyx_ap1721_cls4" d="M186.3,343.3c7.1,5.9,9.5,13.6,10,15.5"/>
  <path id="Steißbein12" class="Coccyx_ap1721_cls3" d="M67.1,648.4c.2,1.5,60.9,25.1,135.8,44.2"/>
  <path id="Steißbein11" class="Coccyx_ap1721_cls3" d="M202.9,692.6c-1.5-.7,5.3-.4,21.4-2.7"/>
  <path id="Steißbein10" class="Coccyx_ap1721_cls3" d="M224.4,689.9c2.9.3,13,2.6,20.5,8.2"/>
  <path id="Steißbein9" class="Coccyx_ap1721_cls3" d="M244.9,698.1v15"/>
  <path id="Steißbein8" class="Coccyx_ap1721_cls3" d="M257.6,715.9c-2.2-16.8.4-28.3,5.7-26"/>
  <path id="Steißbein7" class="Coccyx_ap1721_cls3" d="M263.3,689.9h34.9"/>
  <path id="Steißbein6" class="Coccyx_ap1721_cls3" d="M298.2,689.9c8.3,0,24.3-7.8,41.5-12.8"/>
  <path id="Steißbein5" class="Coccyx_ap1721_cls3" d="M339.6,677.1c49.3-1.4,110.3-34.9,162.7-58.3"/>
  <path id="Steißbein4" class="Coccyx_ap1721_cls3" d="M494.2,681.5c-9.9,12-27.3,28.8-44.2,32.3"/>
  <line id="Steißbein3" class="Coccyx_ap1721_cls4" x1="206.8" y1="368.2" x2="202.7" y2="435.6"/>
  <path id="Steißbein2" class="Coccyx_ap1721_cls4" d="M434.2,352.7c5.4-12.6,8.1-23.2,9.6-30.6,2.2-10.9,6.3-7.3,12.8-13.1,4.2-3.7,5.6-16.7,9.1-17.1"/>
  <path id="Steißbein1" class="Coccyx_ap1721_cls3" d="M66.5,692.6c11.6,18.2,41.6,27.3,61.1,29.2"/>
</g>
</svg>
  );
}

export default SteissbeinVdLiegend1721;
