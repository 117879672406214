import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../Redux/mappers/pricelist/pricelistMappers";
import FacilitySelection from "./FacilitySelection";
import { useDispatch } from "react-redux";
import { clearSelectedFacilities } from "../../Redux/actions";

function SelectFacility(props) {
  const dispatch = useDispatch();
  const rowClickHandler = (row) => {
    dispatch(clearSelectedFacilities());
    props.addFacilityID({
      id: row.original.id,
      name:
        row["original"]["name"] +
        " " +
        row["original"]["institutionsbezeichnung"],
    });
  };

  return (
    <Row>
      <Col md={12} xl={12}>
        <FacilitySelection
          headerLess={true}
          keycloak={props.keycloak}
          rowClick={rowClickHandler}
          selectedIds={props.pricelists.facility}
        />
      </Col>
    </Row>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectFacility);
