import React from "react";
import Iframe from "react-iframe";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const ohifViewerConfig = window.conf.OHIF; // or set it here

export function WebViewerStudy() {
  let { study_uid } = useParams();
  let location = useLocation();
  const isWebViewerAvailable = useSelector(
    (state) => state.examManager.viewerAvailable
  );

  let viewerQueryParams = {
    config: JSON.stringify(ohifViewerConfig),
    token: localStorage.getItem("token"),
    StudyInstanceUIDs: study_uid
  };

  let viewerUrl = `${window.conf.VIEWER_URL}`;
  let queryParams = queryString.parse(location.search);
  if (study_uid) {
    viewerUrl = `${viewerUrl}/viewer?${queryString.stringify(
      viewerQueryParams
    )}`;
  } else {
    console.log(queryParams);
  }

  // console.info(viewerUrl);

  if (isWebViewerAvailable) {
    return (
      <div
        className="d-flex"
        style={{ height: "100vh", backgroundColor: "#000" }}
      >
        <Iframe
          className="w-100"
          frameBorder="0"
          src={`${viewerUrl}`}
          crossorigin
        />
      </div>
    );
  }
  return (
    <Iframe
      className="d-flex"
      frameBorder="0"
      style={{ height: "100vh", backgroundColor: "#000" }}
      src={`data:text/html;charset=utf-8, ${escape(`<!DOCTYPE html>
                            <html lang="en">
                              <head>
                                <meta charset="utf-8">
                                <title>title</title>
                                <link rel="stylesheet" href="style.css">
                                <script src="script.js"></script>
                              </head>
                              <body>
                                WebViewer not configured.
                              </body>
                            </html>`)}`}
      crossorigin
    />
  );
}
