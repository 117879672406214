import style from "./style.scss";

export default function LinkesSchultergelenkVdStehend2001(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 343.46 442.38">
      <g id="background_layer" data-name="background layer">
        <path
          id="background_1"
          data-name="background 1"
          class="schultergelenk2001_ap_left_cls10"
          d="M217.61,427.85c-.32-14.42-.18-26.1,0-33.99.39-16.93,1.04-19.46.63-29.58-.67-16.55-2.7-16.92-3.15-32.1-.31-10.69.73-9.5,1.26-31.47.28-11.17.43-17.74,0-27.06-.73-15.62-1.99-14.37-1.89-25.17.09-10.07,1.21-13.82-1.26-20.14-2.28-5.83-4.41-5.68-6.3-11.96-2.15-7.19-.13-9.9-3.15-15.11-1.15-1.98-1.3-1.33-6.92-7.55-2.82-3.11-3.42-3.99-7.55-8.81-1.04-1.22-1.94-2.27-2.73-3.17-5.21-5.97-7.57-13.92-6.35-21.75.69-4.43,1.93-8.7,3.43-12.22,2.72-6.43,11.07-27.66,31.47-38.4,4.64-2.44,4.35-1.42,18.25-7.55,10.23-4.51,13.48-6.43,19.51-6.92,3.14-.26,7.17.45,15.11,1.89,12.05,2.17,18.07,3.27,22.66,5.66,6.59,3.44,10.66,7.86,15.22,12.82,5.15,5.6,7.73,8.4,9.33,12.98,3.2,9.14-1.91,12.38,1.26,19.21,3.43,7.38,10.22,5.38,15.74,14.78,1.19,2.02,3.94,6.84,3.15,12.59-.65,4.72-3.31,7.41-6.3,11.33-2.81,3.67-5.64,9.16-11.33,20.14-5.15,9.94-4.06,11.79-10.07,28.32-7.35,20.22-9.47,18.85-13.21,32.1-1.36,4.82-1.22,5.49-5.04,31.47-3.96,26.93-4.15,26.47-5.04,34.61-.93,8.54-.61,8.08-2.51,34.61-1.63,22.72-2.03,25.46-2.51,35.87-.59,12.65-.65,22.95-.63,29.58"
        />
        <path
          id="background_2"
          data-name="background 2"
          class="schultergelenk2001_ap_left_cls10"
          d="M67.22,416.85c-22.62-4.17-50.34-9.28-50.34-9.28l4.15-341.47c12.92-4.05,55.66-16.67,64.95-19.06,8.07-2.07,18.68-4.79,33.05-7.55,19.09-3.67,18.59-2.1,36.88-2.32,7.13-1.45,18.26.81,25.44-.38,8.52-1.41,22.26,2.76,25.78,5.85,3.18,2.8,1.13,6.71,1.13,7.52,0,11.5-10.69,10.77-18.8,10.86-3.77.04-7.55.09-11.32.13-3.04.04-6.09.07-9.13.21-5.28.25-10.56.82-15.73,1.88-5.26,1.08-10.61,3.1-15.99,3.37-5.09.25-10.32-.87-15.29.64-3.7,1.12-6.81,3.61-10.3,5.26-6.62,3.13-13.5,2.48-20.52,3.38-6.67.85-12.92,3.92-18.42,7.61-3.26,2.19-6.97,3.85-10.7,5.42,1.16.28,2.32.57,2.32.57l14.93,3.66c33.79-9.48,84.19-17.84,138.78-25.93,7.82-2.49,5.27-4.33-4.66-5.71-7.14.59-1.9-5.22-1.42-13.3,2.46-8.56,23.26-9.48,57.96-4.6,7.12.78,10.87,4.08,8.76,11.73-2.41,9.01-14.86,14.23-35.83,16.23-21.97,4.3-43.38,13.96-64.3,28.32l6.64,3.13c7.03,4.42,9.22,19.26-2.38,34.14-10.7,18.93-10.89,33.38-4.11,44.85,10.4,14.55,19.5,28.78,20.62,42.12-22.36,13.3-35.92,40.64-52,74.28-16.47,23.63-31.25,47.18-35.82,70.16-2.28,18.4-7.88,36.17-15.57,53.55,0,0-13.17-2.43-28.76-5.3Z"
        />
      </g>
      <g id="underlays">
        <path
          id="joint_gap_underlay"
          data-name="joint gap underlay"
          class="jointgap2001Fill"
          style={{
            fill: props.colors.jointgap2001Fill,
            stroke: props.colors.jointgap2001Fill,
          }}
          d="M206.9,217.27c2.04-5.74-1.2-11.58-3.15-15.11-2.34-4.22-4.2-5.65-8.42-10.42-8.66-9.77-13.89-15.69-15.3-24.11-.88-5.3.06-9.74.69-12.71,1.2-5.6,3.2-9.18,6.96-15.77,5.18-9.1,8.11-14.23,14.08-19.07,4.42-3.59,4.84-4.92,6.94-6.01-.39-3.71-13.38-7.47-17.72-3.05-2.43,2.47-.41,5.71-2.38,13.4-.94,3.65-2.03,5.39-4.47,10.23-6.11,12.18-9.17,18.27-10.17,24.77-.31,2.03-1.34,9.8,1.75,18.41,3.02,8.42,6.71,8.52,14.69,23.05,2.95,5.38,4.42,8.06,5.57,11.94,1.96,6.68,1.36,10.65,3.42,11.33,2.22.74,6.1-2.87,7.52-6.88h0Z"
        />
      </g>
      <g id="shoulder_joint" data-name="shoulder joint">
        <path
          id="Collarbone"
          class="schultergelenk2001_ap_left_cls4"
          d="M55.27,91.74c5.75-2.6,12.09-4.74,17.29-8.23,5.5-3.7,11.76-6.76,18.42-7.61,7.02-.89,13.9-.25,20.52-3.38,3.49-1.65,6.6-4.14,10.3-5.26,4.98-1.51,10.2-.4,15.29-.64,5.38-.26,10.73-2.29,15.99-3.37,5.18-1.06,10.45-1.63,15.73-1.88,3.04-.14,6.09-.18,9.13-.21,3.77-.04,7.55-.09,11.32-.13,8.11-.1,18.8.64,18.8-10.86,0-.81,2.05-4.72-1.13-7.52-3.52-3.09-17.26-7.26-25.78-5.85-7.18,1.19-18.31-1.06-25.44.38-18.29.23-17.79-1.35-36.88,2.32-14.37,2.76-24.98,5.48-33.05,7.55-9.29,2.39-52.03,15.01-64.95,19.06"
        />
        <g id="upper_arm" data-name="upper arm">
          <path
            id="upper_arm_1"
            data-name="upper arm 1"
            class="schultergelenk2001_ap_left_cls4"
            d="M217.61,427.85c-.32-14.42-.18-26.1,0-33.99.39-16.93,1.04-19.46.63-29.58-.67-16.55-2.7-16.92-3.15-32.1-.31-10.69.73-9.5,1.26-31.47.28-11.17.43-17.74,0-27.06-.73-15.62-1.99-14.37-1.89-25.17.09-10.07,1.21-13.82-1.26-20.14-2.28-5.83-4.41-5.68-6.3-11.96-2.15-7.19-.13-9.9-3.15-15.11-1.15-1.98-1.3-1.33-6.92-7.55-2.82-3.11-3.42-3.99-7.55-8.81-1.04-1.22-1.94-2.27-2.73-3.17-5.21-5.97-7.57-13.92-6.35-21.75.69-4.43,1.93-8.7,3.43-12.22,2.72-6.43,11.07-27.66,31.47-38.4,4.64-2.44,4.35-1.42,18.25-7.55,10.23-4.51,13.48-6.43,19.51-6.92,3.14-.26,7.17.45,15.11,1.89,12.05,2.17,18.07,3.27,22.66,5.66,6.59,3.44,10.66,7.86,15.22,12.82,5.15,5.6,7.73,8.4,9.33,12.98,3.2,9.14-1.91,12.38,1.26,19.21,3.43,7.38,10.22,5.38,15.74,14.78,1.19,2.02,3.94,6.84,3.15,12.59-.65,4.72-3.31,7.41-6.3,11.33-2.81,3.67-5.64,9.16-11.33,20.14-5.15,9.94-4.06,11.79-10.07,28.32-7.35,20.22-9.47,18.85-13.21,32.1-1.36,4.82-1.22,5.49-5.04,31.47-3.96,26.93-4.15,26.47-5.04,34.61-.93,8.54-.61,8.08-2.51,34.61-1.63,22.72-2.03,25.46-2.51,35.87-.59,12.65-.65,22.95-.63,29.58"
          />
          <path
            id="upper_arm_2"
            data-name="upper arm 2"
            class="schultergelenk2001_ap_left_cls7"
            d="M291.26,194.33c5.82-2.53,8.27-16.24-3.78-27.7"
          />
          <path
            id="upper_arm_3"
            data-name="upper arm 3"
            class="schultergelenk2001_ap_left_cls2"
            d="M312.05,148.79c-54.08,16.64-95.94,33.21-106.65,43.42"
          />
        </g>
        <g id="shoulder_plate" data-name="shoulder plate">
          <g id="shoulder_plate_1" data-name="shoulder plate 1">
            <path
              class="schultergelenk2001_ap_left_cls8"
              d="M95.87,421.78c5.22-13.16,9.4-26.73,12.28-40.59.98-4.62,1.73-9.27,2.65-13.96,1.82-9.42,5.29-18.44,9.6-26.94,4.45-8.45,9.15-16.68,14.49-24.59,5.46-7.73,10.67-15.58,15.04-23.95,6.31-12.73,13.03-25.33,20.29-37.55,3.69-6.1,7.49-12.32,12.46-17.51,3.15-3.54,7.18-6.4,10.79-9.38,1.78-1.52,3.51-3.12,5.21-4.75,0,0-.3.87-.3.87-1.3-8.25-4.16-16.35-8.88-23.25-1.95-2.76-4.38-5.23-6.3-8.07-4.71-7.24-8.98-15.15-10.15-23.86-.44-3.51-.43-7.07.3-10.59.75-3.52,2.19-6.73,3.6-9.84,2.64-6.23,6.23-12.41,9.89-18.02,3.16-5.53,4.21-12.75,2.71-18.88-1.25-4.73-5.44-7.89-9.86-9.51-1.51-.55-3.02-.76-4.66-.76-3.34.02-6.82.18-10.31-.22-3.39-.39-7.96-1.54-8.81-5.44,0,0,1.05-.28,1.05-.28,2.2,5.71,12.99,3.67,18.06,3.8,3.61-.14,7.23,1.15,10.24,3.15,8.41,5.1,8.33,16.05,5.84,24.42-1.56,5.01-5.18,9.1-7.5,13.61-2.97,5.84-6.26,12.13-7.86,18.45-.67,3.17-.7,6.52-.3,9.77.82,6.65,3.73,12.77,7.04,18.58,2.37,4.55,5.84,8.22,8.86,12.36,4.85,7.23,7.76,15.68,9.01,24.24,0,0,.07.52.07.52,0,0-.37.35-.37.35-3.36,3.32-7.16,6.42-10.87,9.28-7.31,5.95-12.48,13.91-17.26,21.96-4.82,8.12-9.4,16.42-13.81,24.78-6.24,12.76-13.16,25.4-21.6,36.83-10.17,15.67-20.16,32.26-23.78,50.8-3.15,18.72-7.89,37.27-15.03,54.9,0,0-1.86-.74-1.86-.74h0Z"
            />
          </g>
          <path
            id="shoulder_plate_2"
            data-name="shoulder plate 2"
            class="schultergelenk2001_ap_left_cls4"
            d="M185.24,103.04c-2.9,5.54-6.68,12.79-8.54,17.65-2.77,7.23-7.73,21.88-9.44,41.54-1.23,14.23-2.03,25.01,3.15,37.13,5,11.69,11.68,15.52,13.21,16.36,5.15,2.79,12.72,7.58,15.74,7.54"
          />
          <line
            id="shoulder_plate_3"
            data-name="shoulder plate 3"
            class="schultergelenk2001_ap_left_cls4"
            x1="61.54"
            y1="103.95"
            x2="89.23"
            y2="97.4"
          />
          <path
            id="shoulder_plate_4"
            data-name="shoulder plate 4"
            class="schultergelenk2001_ap_left_cls4"
            d="M134.52,119.66c2.61-8.52,10.1-13.47,27.72-13.15,3.92.07,7.05-3.76,8.64-7.55,1.17-2.78,1.64-6.31.46-9.17-1.25-3.04-4.78-4.98-7.58-6.08-7.04-2.77-14.95-1.08-22,.82-4.6,1.25-6.92,1.71-10.74,3.61-.88.44-1.85.68-2.83.72-1.67.07-3.2.27-4.35.35-1.99.14-4.11.29-6.77-.11-.83-.13-2.95-.5-5.13-.25-.98.11-1.83.33-1.95.36-.85.21-1.62.5-2.37.83-1.9.86-2.95,1.75-4.3,2.61-1.38.88-2.48,1.34-4.66,2.22-1.68.68-5.88,2.39-9.44,2.51-4.49.15-4.54-2.35-11.96-5.04-3.71-1.34-3.75-.74-17-3.78-2.2-.5-4.12-.96-4.75-1.11-1.16-.29-2.26-.6-3.31-.95"
          />
        </g>
        <g id="acromion">
          <g id="acromion_1" data-name="acromion 1">
            <path
              class="schultergelenk2001_ap_left_cls8"
              d="M134.25,144.25c14.1-14.18,32.09-35.38,48.2-46.9,23.26-17.61,49.41-25.71,77.94-29.98,5.21-1.04,10.61-3.05,14.32-6.92,3.2-3.51,5.58-10.03,1.66-13.8-1.82-1.61-4.72-1.84-7.45-2.32-2.75-.43-5.52-.77-8.29-1.02-11-1.02-22.47-1.23-33.52-1.46-3.48-.18-6.75.32-9.52,2.3-3.16,1.66-5.2,3.69-5.47,7.41-.36,2.12-.89,4.21-1.59,6.25-.18.6-.47,1.06-.41,1.64.07.93.93,1.7,1.86,1.6.05-.01.16,0,.21,0,2.4.35,6.99.98,9.34,1.33,2.79.43,2.84,4.54.1,5.06-2.45.5-5.79,1.22-8.26,1.62-21.16,3.65-44.99,6.82-66.16,10.33-29.25,4.98-58.73,10.13-86.68,20.35,0,0-.17-.44-.17-.44,27.78-10.88,57.28-16.48,86.56-21.65,23.06-4.15,48.57-7.16,71.57-11.52,0,0,2.71-.56,2.71-.56.29-.06.38-.16.48-.32.19-.31.06-.85-.32-.97-.25-.09-.88-.14-1.17-.19-1.75-.25-6.48-.92-8.29-1.18,0,0,.21,0,.21,0-.93.07-1.92-.23-2.64-.86-1.47-1.21-1.51-3.28-.74-4.88.67-1.94,1.19-3.93,1.53-5.95.53-5.48,3.83-7.33,8.24-9.76,2.74-1.38,5.82-1.54,8.64-1.43,5.57.29,11.18.09,16.81.47,8.14.45,17.17.87,25.2,2.11,2.87.49,5.96.64,8.37,2.66,4.85,4.45,2.46,12.19-1.39,16.48-4.07,4.31-9.7,6.53-15.36,7.69-28.59,4.37-53.85,11.63-77.45,29.12-9.13,6.49-17.21,14.18-24.97,22.25,0,0-23.65,23.88-23.65,23.88l-.48-.47h0Z"
            />
          </g>
          <path
            id="acromion_2"
            data-name="acromion 2"
            class="schultergelenk2001_ap_left_cls2"
            d="M251.7,57.36c-.68,5.08-32.02,7.44-40.72,4.49"
          />
        </g>
        <g id="ribs">
          <path
            id="rib_1"
            data-name="rib 1"
            class="schultergelenk2001_ap_left_cls3"
            d="M14.93,193.09c80.24,16.09,138.26,95.66,84.91,157.9"
          />
          <path
            id="rib_2"
            data-name="rib 2"
            class="schultergelenk2001_ap_left_cls3"
            d="M14.93,163.15c73.66,16.55,195.11,112.01,98.41,213.3"
          />
          <path
            id="rib_3"
            data-name="rib 3"
            class="schultergelenk2001_ap_left_cls3"
            d="M14.93,253.2c83.19,16.09,143.34,95.66,88.03,157.9"
          />
          <path
            id="rib_4"
            data-name="rib 4"
            class="schultergelenk2001_ap_left_cls3"
            d="M14.93,223.26c76.36,16.55,202.28,112.01,102.03,213.3"
          />
          <path
            id="rib_5"
            data-name="rib 5"
            class="schultergelenk2001_ap_left_cls3"
            d="M5.78,318.21c72.98,13.28,129.28,69.81,113.67,124.14"
          />
          <path
            id="rib_6"
            data-name="rib 6"
            class="schultergelenk2001_ap_left_cls3"
            d="M5.77,288.27c64.44,13.14,162.08,76.01,146.27,152.05"
          />
          <path
            id="rib_7"
            data-name="rib 7"
            class="schultergelenk2001_ap_left_cls3"
            d="M.03,380.21c41.59,8.05,77.43,31.96,95.95,61.64"
          />
          <path
            id="rib_8"
            data-name="rib 8"
            class="schultergelenk2001_ap_left_cls3"
            d="M.03,350.26c43.5,9.42,103.08,44.46,127.77,91.59"
          />
          <path
            id="rib_9"
            data-name="rib 9"
            class="schultergelenk2001_ap_left_cls3"
            d="M18.96,134.37c73,15.09,125.79,89.68,77.26,148.03"
          />
          <path
            id="rib_10"
            data-name="rib 10"
            class="schultergelenk2001_ap_left_cls3"
            d="M18.96,106.29c67.02,15.51,177.52,105.01,89.54,199.97"
          />
        </g>
        <g id="outlines">
          <g>
            <path
              class="schultergelenk2001_ap_left_cls1"
              d="M45.89.49c1.94.42,3.89.84,5.86,1.25"
            />
            <path
              class="schultergelenk2001_ap_left_cls6"
              d="M63.64,4.09c46.74,8.75,101.87,13.58,152.09,8.77,4.99-.05,9.98.23,14.94.8,20.64,2.4,40.21,6.97,56.82,17.67,13.61,7.69,24.28,20.12,33.2,35.35,15.29,26.08,22.71,56.02,22.25,86.25l-1.31,86.47-8.51,170.71"
            />
            <line
              class="schultergelenk2001_ap_left_cls1"
              x1="332.82"
              y1="416.16"
              x2="332.52"
              y2="422.15"
            />
          </g>
        </g>
      </g>
      <g id="overlays">
        <path
          id="cavitas_glenoidalis_overlay"
          data-name="cavitas glenoidalis overlay"
          class="jointsocket2001Fill"
          style={{
            fill: props.colors.jointsocket2001Fill,
            stroke: props.colors.jointsocket2001Fill,
          }}
          d="M184.37,192.22c-8.16-10.4-11.59-22.12-9.7-33.72.3-5.59,2.24-10.96,5.35-15.61,11.63-17.33,13.62-29.59,6.93-37.31h0c-3.43-9.71-20.58,43.99-20.14,62.22-2.07,18.96,1.99,33.53,11.76,44.03,5.68,4.5,12.34,8.27,19.82,11.42-1.48-12.12-6.91-22.05-14.01-31.04Z"
        />
        <path
          id="humerus_head"
          data-name="humerus head"
          class="headofhumerus2001Fill"
          style={{
            fill: props.colors.headofhumerus2001Fill,
            stroke: props.colors.headofhumerus2001Fill,
          }}
          d="M333.23,180.48l-120.13,43.77c-4.99-3.12-7.41-9.8-7.84-19.23-14.14-22.08-30.21-30.24-22.39-53.23,13.22-39.49,37.11-44.32,59.23-53.86,15.56-5.21,44.05-1.15,58.03,11.43,10.97,8.91,16.46,16.14,15.44,31.39-.34,5.04,2.06,9.89,6.17,12.81,16.75,11.92,16.2,18.52,11.48,26.93Z"
        />
      </g>
    </svg>
  );
}
