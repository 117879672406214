import style from "./style.scss";

function DeumenLatRechts1306(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 266.49 479.98">

<g id="Thumb">
  <g id="Background">
    <path id="Background1" class="Daumen_lat_rechts1306_cls8" d="M40.46,476.33h0s225.72,2.43,226.03,3.65-28.1-396.09-28.1-396.09c-14.9,8.03-29.2,15.99-35.1,22.78-16.29,24.21-32.07,56.38-47.69,91.14-17.65.38-33.35,4.65-46.89,13.22-8.97-8.29-12.89-21.44-12.35-38.87,1.46-22.99.25-41.46-4.41-54.09,13.68-14.44,12.67-44.03,4.41-81.11C89.52,14.43,80.48,2.6,69.17,1.87c-11.01-1.61-18.89.34-23.73,5.75-2.54,2.83-3.82,6.56-3.93,10.36-1.1,38.33-6.82,65.8-15.91,85.32-2.86,6.16-4.39,12.85-4.61,19.63-1.34,41.17-8.37,79.01-18.35,115.1-3.85,14.12-2.06,30.17,1.82,46.94,16.41,72.49,13.22,107.88,29.06,163.72,2.01,10.26,4.8,21.14,6.94,27.65h0Z"/>
  </g>
  <path id="partoffinger29" class="Daumen_lat_rechts1306_cls2" d="M55,455.68c.74,1.45,2.35,2.24,3.95,1.95,5.01,5.45,10.31,3.89,15.84-2.86,11.27-12.22,23.33-17.46,36.15-16.1,9.84.4,11.28-4.05,8.51-10.94l-7.29-23.7c-18.41-9.94-29.23-25.35-36.76-43.14-4.76-22.94-8.81-44.36-10.94-61.67-1.85-7.14-1.58-14.43.61-21.87,7.96-4.63,8.88-11.44,3.04-20.35-3.77-12.04-11.04-17.45-20.22-19.28-5.37-1.08-10.94-.42-16.01,1.64-7.92,3.22-14.2,7.37-18.76,12.47-9.12,11.85-.61,24.61,3.34,37.37,2.06,26.24,5.85,50.62,12.15,72.3l9.42,61.37c.66,20.31,5.22,33.3,16.97,32.81h0Z"/>
  <path id="partoffinger28" class="Daumen_lat_rechts1306_cls2" d="M17.51,226.99h-.03c1.12,1.18,1.82,2.69,2.19,4.27.31,1.31.7,2.54,1.21,3.65,1.85,4.07,6.59,6.04,10.82,4.59,6.05-2.08,14.43-3.12,24.13-3.57,3.02-.14,6.02.37,8.88,1.32,4.54,1.51,8.65.21,11.88-6.68,4.96-7.48,4.94-14.77.3-21.88-2.42-3.71-4.51-7.62-5.96-11.8l-4.66-13.41c-5.05-16.62-6.24-31.11-3.31-43.35.81-3.39,2.82-6.32,5.5-8.54,3.75-3.11,5.66-7.36,5.1-13.12-.61-6.9-4.95-11.7-12.15-14.89-9.01-1.99-16.71-.72-22.18,6.08-10.37,6.11-13.61,13.31-10.67,21.45.58,1.61.94,3.3.92,5.02-.38,27.4-2.98,53.47-9.81,77-.29,1.01-.78,1.96-1.42,2.8-3.14,4.13-3.76,7.87-.74,11.06h0Z"/>
  <path id="partoffinger27" class="Daumen_lat_rechts1306_cls2" d="M37.74,102.73h0c9.05-1.56,18.79,1.22,29.16,8.15,4.56-1.26,6.85-4.34,6.68-9.42,2.86-.89,4.7-3.7,4.86-9.72-.76-8.37-3.06-10.87-6.08-10.63,4.33-10.77,3.04-21.59-2.48-32.46-.73-1.44-.98-3.08-.75-4.68,1.38-9.75.07-18.69-6.48-26.06-4.11-5.96-8.12-5.59-12.07-1.62-.25.25-.36.6-.31.95l1.14,8.01c.18,1.28.06,2.58-.4,3.78-1.39,3.62-2.37,7.65-3.06,11.97.1,17.4-1.3,34.5-8.4,50.43-3.33,3.11-4.68,6.7-1.82,11.29h0Z"/>
  <path id="partoffinger26" class="Daumen_lat_rechts1306_cls2" d="M60.2,256.1h0c1.83,4.09,3.97,6.96,6.68,7.59,6.29.05,13.47-6.38,13.47-6.38,0,0,1.59-4.08,1.75-7.94.13-3.1-1.91-5.88-4.86-6.82-6.6-2.11-12.28-1.74-17.03,1.15-3.44,3.09-4.31,6.96,0,12.39h0Z"/>
  <path id="partoffinger25" class="Daumen_lat_rechts1306_cls2" d="M96.35,252.45h0c-.55-5.56-3.27-7.56-6.77-8.24-1.45-.29-2.91-.54-4.36-.82-6.09-1.17-7.39,2.01-6.78,6.93-.36,4.23.15,8.32,5.49,11.54,6.32.9,11.03-1.17,12.43-9.42h0Z"/>
  <path id="partoffinger24" class="Daumen_lat_rechts1306_cls2" d="M151.65,478.46l-5.47-7.29c-4.76-11.04-12.48-13.8-21.87-11.85-6.31,3.62-12.21,4.48-17.32,0-4.1-7.39-1.27-18.34,4.25-30.68-2.33-7.48-.36-15.08,5.77-22.78,14.89-10.07,26.3-36.08,34.94-74.73,8.94-30.91,15.85-74.27,22.18-121.22,5.7-9.22,5.87-15.82,2.43-20.66-1.57-15.36,9.01-25.54,26.43-32.81,13.98-4.74,27.42-3.02,40.41,4.25"/>
  <path id="partoffinger23" class="Daumen_lat_rechts1306_cls2" d="M243.4,150.67c-9.7-4.67-18.75-8.17-25.22-6.99-8.89-6.96-8.92-15.55-4.86-24.91,1.99-5.76,5.51-8.8,11.24-7.9,6.76-.16,12.29-2.95,16.71-8.15"/>
  <path id="partoffinger22" class="Daumen_lat_rechts1306_cls2" d="M200.57,478.48c2.71-5.72,2.74-12.93,1.82-20.66-3.08-6.17-2.52-14.47-.91-23.39-7.35-36.76-7.38-62.63-3.04-82.03,9.28-37.61,22.66-80.11,37.67-124.56,2.59-8.73,6.87-13.1,12.15-14.89"/>
  <path id="partoffinger21" class="Daumen_lat_rechts1306_cls2" d="M176.86,478.47l6.38-12.46c1.69-11.65,9.1-23.4,21.27-35.24,19.5-25.65,36.79-51.86,51.65-78.68"/>
  <path id="partoffinger20" class="Daumen_lat_rechts1306_cls2" d="M155.59,478.47c-3.31-15.28-8.58-29.03-17.62-39.8-6.63-6.58-14.4-10.33-23.09-11.85-10.6,3.51-18.1,9.18-18.53,19.75-7.52,1.66-11.43,7.17-12.15,16.1-4.65,3.65-7.69,8.91-9.11,15.8"/>
  <path id="partoffinger19" class="Daumen_lat_rechts1306_cls2" d="M223.36,158.57h0c.08-4.39-3.97-8.12-10.03-11.54-4.52-1.22-8,.65-10.03,6.76-.2,6.83,2.49,12.23,9.72,15.41,7.61.87,11.65-2.04,10.33-10.63h0Z"/>
  <path id="partoffinger18" class="Daumen_lat_rechts1306_cls3" d="M96.36,446.57c7.38,1.49,17.05,1.57,27.95.91"/>
  <path id="partoffinger17" class="Daumen_lat_rechts1306_cls3" d="M84.21,462.67c3.12,4.16,4.16,8.31,3.12,12.46"/>
  <path id="partoffinger16" class="Daumen_lat_rechts1306_cls3" d="M108.82,478.47c-.62-5.94,1.48-9.5,7.59-9.57,9.65-1.8,19.59-.9,29.77,2.28"/>
  <path id="partoffinger15" class="Daumen_lat_rechts1306_cls3" d="M258.29,380.64c-7.44,15.41-16.96,28.28-28.86,38.28-9.27,6.14-16.6,12.32-21.27,18.53"/>
  <path id="partoffinger14" class="Daumen_lat_rechts1306_cls3" d="M40.47,409.04c-2.01-41-7.04-81.89-16.71-122.58-.82-6.87-3.14-10.61-6.08-13.06"/>
  <path id="partoffinger13" class="Daumen_lat_rechts1306_cls3" d="M102.75,402.22c-26.15-8.85-38.47-29.33-42.85-56.51-3.87-27.71-10.97-51.71-19.44-74.13"/>
  <path id="partoffinger12" class="Daumen_lat_rechts1306_cls3" d="M59.6,268.09c-3.33,4.35-5.44,9.47-5.24,16.06"/>
  <path id="partoffinger11" class="Daumen_lat_rechts1306_cls3" d="M27.47,204.75c4.92-10.15,8.72-22.15,10.37-37.71,3.16-18.53,2.65-31.61-3.08-36.91"/>
  <path id="partoffinger10" class="Daumen_lat_rechts1306_cls3" d="M64.88,194.61c-12.82-19.74-15.63-40.48-9.72-62.09"/>
  <path id="partoffinger9" class="Daumen_lat_rechts1306_cls3" d="M33.62,121.09c-.56-5.45,1.09-9.41,5.62-11.43,6.48-.97,12.46-.26,17.73,2.92,6.12,3.92,6.18,11.32,4.11,19.94"/>
  <path id="partoffinger8" class="Daumen_lat_rechts1306_cls3" d="M55.5,25.31c1.78,32.55-2.69,55.32-13.9,67.56"/>
  <path id="partoffinger7" class="Daumen_lat_rechts1306_cls3" d="M43.98,95.26c8.87-4.35,17.3-3.78,25.18,2.96-.26-1.1-.43-2.4-.51-3.87-.1-1.77-.53-3.5-1.31-5.09-.93-1.91-1-4.16-.8-6.53.32-3,.14-5.03-.36-6.45-.26-.72-.43-1.47-.43-2.23.02-5.82.04-11.61-.51-15.32-.17-1.09-.55-2.13-1.06-3.11-3.34-6.46-4.04-13.34-3.44-20.42.1-1.15.52-2.26,1.21-3.19,1.05-1.41,1.32-3.15.84-5.21"/>
  <path id="partoffinger6" class="Daumen_lat_rechts1306_cls3" d="M73.57,101.45c-1.7-2.18-2.37-4.99-1.78-8.58,1.82-2.28-2.85-11.62.57-11.77"/>
  <path id="partoffinger5" class="Daumen_lat_rechts1306_cls3" d="M60.97,235.73c-2.49-6.06-13.1-7.06-32.81-2.39"/>
  <path id="partoffinger4" class="Daumen_lat_rechts1306_cls3" d="M200.41,180.13c-3.89,2.06-7.05,4.61-7.75,8.81-1.31,5.18-3.53,8.01-6.23,9.57-4.56,1.94-3.95,9.73-1.47,19.64.94,3.77,1.35,7.66,1.1,11.54-3.58,56.06-11.21,84.09-18.92,111.53-4.17,22.04-11.81,39.4-23.54,51.27-9.32,11.37-16.73,21.82-21.11,30.84"/>
  <path id="partoffinger3" class="Daumen_lat_rechts1306_cls3" d="M200.4,162.06c1.3,9.62-10.83,15.7-11.41,20.83-.12,1.07-.56,2.09-1.11,3.01-3.51,5.87-6.73,5.07-9.27,8.14"/>
  <path id="partoffinger2" class="Daumen_lat_rechts1306_cls3" d="M185.68,445.35c-7.99-49.72-6.13-92.73,7.44-127.75,7.39-22.59,19.91-52.06,33.94-83.54l7.83-26.58c3.04-9.51,7.13-14.76,12-16.86"/>
  <path id="partoffinger1" class="Daumen_lat_rechts1306_cls3" d="M238.38,212.95c2.84-7.29,5.67-10.94,8.51-10.94"/>
  <g id="Outlines3">
    <path id="partofoutline13" class="Daumen_lat_rechts1306_cls3" d="M37.98,466.06c.6,1.99,1.2,3.88,1.8,5.72"/>
    <path id="partofoutline12" class="Daumen_lat_rechts1306_cls1" d="M98.35,107.1c6.61-16.15,4.65-41.13-1.99-70.96C89.52,13.61,80.48,1.78,69.17,1.05c-11.01-1.61-18.89.34-23.73,5.75-2.54,2.83-3.82,6.56-3.93,10.36-1.1,38.33-6.82,65.8-15.91,85.32-2.86,6.16-4.39,12.85-4.61,19.63-1.34,41.17-8.37,79.01-18.35,115.1-3.85,14.12-2.06,30.17,1.82,46.94,16.41,72.49,13.22,107.88,29.06,163.72.45,2.3.91,4.44,1.37,6.46"/>
    <path id="partofoutline11" class="Daumen_lat_rechts1306_cls3" d="M93.73,122.98c-.53-2.03-1.12-3.94-1.78-5.73,1.36-1.44,2.57-3.02,3.65-4.75"/>
    <path id="partofoutline10" class="Daumen_lat_rechts1306_cls1" d="M101.54,200.61c-3.88-7.68-5.55-17.49-5.18-29.26.89-13.92.79-26.17-.45-36.48"/>
    <path id="partofoutline9" class="Daumen_lat_rechts1306_cls3" d="M113.9,207.2c-1.77.94-3.49,1.95-5.19,3.02-1.47-1.36-2.8-2.85-4-4.46"/>
    <path id="partofoutline8" class="Daumen_lat_rechts1306_cls1" d="M143.42,197.92c-6.33.85-12.38,2.27-18.13,4.29"/>
    <path id="partofoutline7" class="Daumen_lat_rechts1306_cls3" d="M158.07,191.53c-.82,1.82-1.65,3.64-2.47,5.47-2.03.04-4.03.13-6,.28"/>
    <path id="partofoutline6" class="Daumen_lat_rechts1306_cls1" d="M227.83,88.86c-10.74,6.02-20.06,11.85-24.54,17-13.68,20.34-27.01,46.29-40.18,74.68"/>
    <path id="partofoutline5" class="Daumen_lat_rechts1306_cls3" d="M238.38,83.08c-1.77.95-3.53,1.91-5.27,2.86"/>
  </g>
  <g id="Outlines2">
    <path id="partofoutline4" class="Daumen_lat_rechts1306_cls3" d="M103.45,213.1c1.76-1.06,3.51-2.03,5.26-2.89"/>
    <path id="partofoutline3" class="Daumen_lat_rechts1306_cls3" d="M91.95,221c1.62-1.21,3.24-2.39,4.86-3.53"/>
  </g>
  <g id="Outlines">
    <path id="partofoutline2" class="Daumen_lat_rechts1306_cls3" d="M113.07,221.88c.73,2.02,1.24,3.96,1.52,5.8"/>
    <path id="partofoutline1" class="Daumen_lat_rechts1306_cls3" d="M108.71,212.95c1.09,1.82,2.03,3.58,2.83,5.29"/>
  </g>
</g>
<g id="Highlights">
  <g id="Thumbhighlight">
    <path id="Thumbhighlight5" class="fullfinger1306Fill" style= {{
            fill: props.colors.fullfinger1306Fill,
            stroke: props.colors.fullfinger1306Fill,
            opacity:props.colors.fullfinger1306Fill,
          }} d="M37.41,102.73h0c9.05-1.56,18.79,1.22,29.16,8.15,4.56-1.26,6.85-4.34,6.68-9.42,2.86-.89,4.7-3.7,4.86-9.72-.76-8.37-3.06-10.87-6.08-10.63,4.33-10.77,3.04-21.59-2.48-32.46-.73-1.44-.98-3.08-.75-4.68,1.38-9.75.07-18.69-6.48-26.06-4.11-5.96-8.12-5.59-12.07-1.62-.25.25-.36.6-.31.95l1.14,8.01c.18,1.28.06,2.58-.4,3.78-1.39,3.62-2.37,7.65-3.06,11.97.1,17.4-1.3,34.5-8.4,50.43-3.33,3.11-4.68,6.7-1.82,11.29h0Z"/>
    <path id="Thumbhighlight4" class="fullfinger1306Fill" style= {{
            fill: props.colors.fullfinger1306Fill,
            stroke: props.colors.fullfinger1306Fill,
            opacity:props.colors.fullfinger1306Fill,
          }} d="M17.38,227.49h-.03c1.12,1.18,1.82,2.69,2.19,4.27.31,1.31.7,2.54,1.21,3.65,1.85,4.07,6.59,6.04,10.82,4.59,6.05-2.08,14.43-3.12,24.13-3.57,3.02-.14,6.02.37,8.88,1.32,4.54,1.51,8.65.21,11.88-6.68,4.96-7.48,4.94-14.77.3-21.88-2.42-3.71-4.51-7.62-5.96-11.8l-4.66-13.41c-5.05-16.62-6.24-31.11-3.31-43.35.81-3.39,2.82-6.32,5.5-8.54,3.75-3.11,5.66-7.36,5.1-13.12-.61-6.9-4.95-11.7-12.15-14.89-9.01-1.99-16.71-.72-22.18,6.08-10.37,6.11-13.61,13.31-10.67,21.45.58,1.61.94,3.3.92,5.02-.38,27.4-2.98,53.47-9.81,77-.29,1.01-.78,1.96-1.42,2.8-3.14,4.13-3.76,7.87-.74,11.06h0Z"/>
    <path id="Thumbhighlight3" class="fullfinger1306Fill" style= {{
            fill: props.colors.fullfinger1306Fill,
            stroke: props.colors.fullfinger1306Fill,
            opacity:props.colors.fullfinger1306Fill,
          }} d="M55,455.68c.74,1.45,2.35,2.24,3.95,1.95,5.01,5.45,10.31,3.89,15.84-2.86,11.27-12.22,23.33-17.46,36.15-16.1,9.84.4,11.28-4.05,8.51-10.94l-7.29-23.7c-18.41-9.94-29.23-25.35-36.76-43.14-4.76-22.94-8.81-44.36-10.94-61.67-1.85-7.14-1.58-14.43.61-21.87,7.96-4.63,8.88-11.44,3.04-20.35-3.77-12.04-11.04-17.45-20.22-19.28-5.37-1.08-10.94-.42-16.01,1.64-7.92,3.22-14.2,7.37-18.76,12.47-9.12,11.85-.61,24.61,3.34,37.37,2.06,26.24,5.85,50.62,12.15,72.3l9.42,61.37c.66,20.31,5.22,33.3,16.97,32.81h0Z"/>
    <path id="Thumbhighlight2" class="fullfinger1306Fill" style= {{
            fill: props.colors.fullfinger1306Fill,
            stroke: props.colors.fullfinger1306Fill,
            opacity:props.colors.fullfinger1306Fill,
          }} d="M60.2,256.1h0c1.83,4.09,3.97,6.96,6.68,7.59,6.29.05,13.47-6.38,13.47-6.38,0,0,1.59-4.08,1.75-7.94.13-3.1-1.91-5.88-4.86-6.82-6.6-2.11-12.28-1.74-17.03,1.15-3.44,3.09-4.31,6.96,0,12.39h0Z"/>
    <path id="Thumbhighlight1" class="fullfinger1306Fill" style= {{
            fill: props.colors.fullfinger1306Fill,
            stroke: props.colors.fullfinger1306Fill,
            opacity:props.colors.fullfinger1306Fill,
          }} d="M96.35,252.45h0c-.55-5.56-3.27-7.56-6.77-8.24-1.45-.29-2.91-.54-4.36-.82-6.09-1.17-7.39,2.01-6.78,6.93-.36,4.23.15,8.32,5.49,11.54,6.32.9,11.03-1.17,12.43-9.42h0Z"/>
  </g>
  <ellipse id="Thumbbasejoint_Highlight" data-name="Thumbbasejoint Highlight" class="Daumengrundgelenk1306Fill" style= {{
            fill: props.colors.Daumengrundgelenk1306Fill,
            stroke: props.colors.Daumengrundgelenk1306Fill,
            opacity:props.colors.Daumengrundgelenk1306Fill,
          }} cx="48.75" cy="237.2" rx="13.07" ry="38.64" transform="translate(-192.24 260.93) rotate(-84.01)"/>
  <path id="Basemiddlehand_Bone_highligt" data-name="Basemiddlehand Bone highligt" class="erstenMHK1306Fill" style= {{
            fill: props.colors.erstenMHK1306Fill,
            stroke: props.colors.erstenMHK1306Fill,
            opacity:props.colors.erstenMHK1306Fill,
          }} d="M78.92,450.64s-12.17,16.58-20,7.78c-1.46-.21-2.62-1.92-4.3-2.25-3.19-.62-5.95-1.85-5.95-1.85-13.42-7.76-10.29-38.66-13.42-49.63,20.16-4.92,55.16-18.45,55.16-18.45-.29,1.15,13.13,12.12,21.76,17.78l3.46,11.25c7.37,17.23,8.69,23.19-1.63,23.34-21.35-1.55-32.89,9.95-35.08,12.03h0Z"/>
  <path id="Thumbfingertip_Highlight" data-name="Thumbfingertip Highlight" class="fingertip1306Fill" style= {{
            fill: props.colors.fingertip1306Fill,
            stroke: props.colors.fingertip1306Fill,
            opacity:props.colors.fingertip1306Fill,
          }} d="M51.48,28.79c-.93-3.92-1.59-7.61-1.21-10.4,1.15-3.83,3.77-5.32,7.5-5.03,3.58.62,9.52,8.91,11.39,17.66-4.92-6.43-14.04-12.5-17.67-2.22h0Z"/>
</g>
</svg>
  );
}

export default DeumenLatRechts1306;

