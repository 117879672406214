import { useMemo } from "react";
import { useExamination } from "../../../Utilities/FetchHooks/Ris/RisHooks";
import { examQaMap, qaIdImageMap } from "../imageComponents/consts";

const ERROR_RESULT = {
  data: null,
  isError: true,
  ExmImg: null,
  colors: null,
  qaAnatomyCriteria: null,
  exam: "",
};

const LANG_SELECTOR = "designationde";

function useExamData(examId, dataIndex) {
  const examQuery = useExamination({ examId: examId, enabled: examId != null });
  const { loading, error, data: examData } = examQuery;
  const exam = useMemo(() => {
    if (loading) {
      return "Loading...";
    }
    if (error) {
      return "Error";
    }
    if (examData && examData[LANG_SELECTOR]) {
      return examData[LANG_SELECTOR];
    }
    return "...";
  }, [examData]);

  if (!examId) {
    return ERROR_RESULT;
  }
  const data = Object.keys(examQaMap).includes(`${examId}`)
    ? examQaMap[examId].data
    : null;
  if (data === null) {
    return ERROR_RESULT;
  }
  if (dataIndex > data.length - 1) {
    return ERROR_RESULT;
  }
  return {
    data,
    isError: false,
    ExmImg: qaIdImageMap[data[dataIndex].qaCriteriaId],
    colors: data[dataIndex].colors,
    qaAnatomyCriteriaCount: data.length,
    qaAnatomyCriteria: data[dataIndex].qaAnatomyCriteria,
    exam,
  };
}

export default useExamData;
