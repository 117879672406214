import { useState } from "react";
import { Modal, Row, Col, Button, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { MoveExam } from "../../Utilities/ClientServices/ClientServices";
import { loadPatient } from "../../Utilities/Patients/PatientUtils";
import PatientsTable from "../../Patients/PatientListTable";
import { NotificationManager } from "react-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import SelectedExamsDetails from "../SelectedExamsDetails/SelectedExamsDetails";
import "./stylesheets/MoveExamModal.scss";
import {
  clearSelectedExaminations,
  setReloadExaminationsFlag,
} from "../../Redux/actions";
import { useEffect } from "react";
import MoveStudy from "../../Utilities/DcmTools/MoveStudy";
import useLoggedUser from "../../Utilities/Hooks/useLoggedUser";
import { usePatient } from "../../Utilities/FetchHooks/Patients/PatientsHooks";

export default function MoveExamModal({ shown, headerless = false, close }) {
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [originalPatient, setOriginalPatient] = useState(undefined);
  const [inp, setInp] = useState(false);
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const user = useLoggedUser(false);

  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  const selectedPatients = useSelector(
    (state) => state.examManager.openedPatients
  );

  const originalPatQ = usePatient({
    patientId: selectedPatients[selectedPatients.length - 1],
  });

  function sender(data) {
    setInp(true);
    return MoveStudy(data)
      .then((jsonData) => {
        NotificationManager.success("Move examination success");
        return { success: true, response: jsonData, data };
      })
      .catch((reason) => {
        NotificationManager.error(`Move examination failed`);
        console.warn(reason);
        return { succcess: false, data };
      });
  }

  function handleClosing() {
    setInp(false);
    dispatch(setReloadExaminationsFlag(true));
    dispatch(clearSelectedExaminations());
    close();
  }

  async function send() {
    let updates = selectedExaminations.map((std) => {
      let data = {
        keycloak: keycloak,
        mId: user.tokenData.pmedMid,
        stdId: std.id,
        patNr: originalPatQ?.data.patientid,
        dapatid: selectedPatient[0].id,
        destPatNr: selectedPatient[0].patientid,
        kontoid: std.kontoid,
        oldPatName: `${originalPatQ.data.patientsvorname} ${originalPatQ.data.patientsname}`,
      };
      return sender(data);
    });

    let repeats = [];
    Promise.all(updates).then((results) => {
      results.forEach((result) => {
        if (!result.success) {
          NotificationManager.error(
            "An error occured during moving examination repeating.."
          );
          repeats.push(sender(result.data));
        }
      });
      if (repeats.length === 0) {
        handleClosing();
      }
      Promise.all(repeats).then((results) => {
        results.forEach((result) => {
          if (!result.success) {
            NotificationManager.error(
              "An error occured during moving examination try again later"
            );
          }
          handleClosing();
        });
      });
    });
  }

  const patSelectionHandler = (event, row) => {
    if (selectedPatient[0]?.id === row.original.id) {
      setSelectedPatient([]);
    } else {
      setSelectedPatient([
        {
          patientid: row.original.patientid,
          id: row.original.id,
        },
      ]);
    }
  };

  return (
    <Modal
      className="top-modal"
      show={shown}
      onHide={close}
      size={"lg"}
      onClick={(e) => e.stopPropagation()}
    >
      {!headerless && (
        <Modal.Header className="py-2 bg-light">
          <h4>Select destinantion patient</h4>
          {inp && (
            <ClipLoader color="#a1a1a1" loading={true} css={true} size={30} />
          )}
        </Modal.Header>
      )}
      <Modal.Body className="text-center ">
        {selectedExaminations.length === 0 ? (
          <Container>
            <h5>Select Examination first</h5>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col xs={12}>
                <SelectedExamsDetails />
              </Col>
            </Row>
            <Row>
              <Col>
                <PatientsTable
                  idField="patientid"
                  pageSize={5}
                  selectedIds={selectedPatient.map((pat) => pat.patientid)}
                  rowClick={patSelectionHandler}
                />
              </Col>
            </Row>
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer className="px-0 py-0 bg-light">
        <Button className="mr-auto" onClick={close}>
          Close
        </Button>
        <Button
          className="ml-4"
          disabled={selectedPatient.length === 0}
          onClick={send}
        >
          Move Examination <i className="fas fa-angle-double-right"></i>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
