export function isSelected(selected, id) {
  if (selected?.id === id) {
    return "selected";
  } else return "";
}

export function idToSet(selected, parentId, id) {
  if (selected?.id === id) {
    return parentId;
  } else return id;
}
