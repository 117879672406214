import { MakePostObject, MakePutObject } from "../AuthHeader";
import { REST_URL, MakeUrl } from "../FetchHooks/Helpers/FetchWrapper";
import { getDbDateTimeString } from "../DateTimeUtils/DateTimeUtils";

export default async function MoveStudy({
  keycloak,
  mId,
  stdId,
  patNr,
  destPatNr,
  kontoid,
  dapatid,
  oldPatName = "",
  text = "",
  srcVetPat = 0,
  destVetPat = 0,
}) {
  const protUrl = MakeUrl(`${REST_URL}/pmed/protokol`);
  let protPostObj = {
    befehl: "studymove",
    patnr: patNr,
    destpatnr: destPatNr,
    mid: mId,
    stdid: stdId,
    text: text,
    pmvetiddest: destVetPat,
    pmvetidsrc: srcVetPat,
    datum: getDbDateTimeString(),
  };
  const stdUrl = MakeUrl(`${REST_URL}/ris/studies/${stdId}`);
  let stdPutObj = {
    patientid: destPatNr,
    kontoid: kontoid,
    dapatid: dapatid,
    oldpatientsname: oldPatName,
  };
  const protResult = await fetch(
    protUrl.href,
    MakePostObject(keycloak, protPostObj)
  );
  const stdResult = await fetch(
    stdUrl.href,
    MakePutObject(keycloak, stdPutObj)
  );

  if (
    protResult.ok &&
    protResult.status === 200 &&
    stdResult.ok &&
    stdResult.status === 200
  ) {
    const resultData = await protResult.json();
    return resultData;
  } else {
    throw new Error(`Unable to move study`);
  }
}
