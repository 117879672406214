import style from "./style.scss";

export default function LinkerUnterarmMitEllenbogenLatSitzend2210(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583.23 267">
      <g id="Forearmlat">
        <g id="Backround">
          <path
            id="backround1"
            class="unterarm_mit_ellenbogen_lat_links2210_cls3"
            d="M0,218.65c.55,5.75,1.46,11.47,1.57,17.27.02,1.16-.3,2.12-.82,2.86.54,7.07,1.07,14.15,1.51,21.23,7.94.97,19.87-1.79,35.77-2.98,6.7-.5,6.09-.12,48,2,11.41.58,72.72,3.68,113,5,58.79,1.93,48.21-.74,126,1,43.91.98,62.84,2.18,100,1,46.44-1.47,39.38-4.06,60-3,3.01.15,36.77,1.94,66-5,4.07-.97,11.4-2.9,15-9,3.83-6.49-.37-11.21,0-23,.38-12.06,4.92-11.74,9-32,3.67-18.22.89-22.91-1-25-3.21-3.57-7.44-3.65-8-7-.62-3.74,4.33-5.58,7-12,1.85-4.44,1.31-7.99,1-13-.53-8.66.88-17.33,1-26,.24-17.47,3.31-64.04,7.17-111.03-25.88.29-51.76,1.36-77.64,1.76-.47,3.6-.97,7.89-1.53,13.27-2.72,26.19-4.07,39.29-4,42,.26,9.6,1.32,17.47-3,27-1.44,3.18-4.67,10.3-12,15-6.81,4.37-9.55,1.77-24,7-7.54,2.73-11.39,4.17-15,8-5.05,5.35-5.87,11.27-8,11-1.78-.22-1.67-4.41-5-12-2.29-5.21-3.43-7.82-6-10-3.99-3.39-8.78-3.61-17-4-6.2-.29-9.3-.44-12,1-5.79,3.08-4.47,8.47-10,13-1.06.86-4.27,3.26-17,4-11.16.65-19.79-.54-23-1-19.71-2.82-24.95-4.25-37-6-15.68-2.27-23.52-3.41-34-3-13.91.54-17.14,2.99-35,4-8.49.48-11.78.15-24,0-13.99-.17-26.66.22-52,1-24.1.74-36.15,1.11-48,2-33.61,2.51-29.67,4.8-49,5-23.09.24-32.72.49-52,1-5.94.15-10.11.3-13.4,1.16,2.4,15.99,1.16,31.99.96,48.08,12.37.83,17.45.86,19.44.76,10.51-.52-16.48,1.29,35,0,45.09-1.13,71.69-2.66,101-3,55.61-.64,46.99,3.87,88,2,14.92-.68,39.72-2.36,74,0,5.74.39,35.93,2.47,36,6,.03,1.56-8.31,3.39-25,7-10.81,2.34-16.22,3.51-25,5-16.47,2.8-29.89,4.24-38,5-26.22,2.47-49.9,2.87-69,3-32.23.22-74.74.52-123-2-20.64-1.08-41.59,2.24-62-1-4.98-.79-9.53-1.69-13.46-1.93v24.82c0,1.71-.63,2.94-1.57,3.73h0Z"
          />
        </g>
        <g id="Overview">
          <path
            id="outline3"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M3.59,170.3c3.72,1.11,8.47.29,20.2-.29,10.51-.52-16.48,1.29,35,0,45.09-1.13,71.69-2.66,101-3,55.61-.64,46.99,3.87,88,2,33.23-1.51,49.76-4.97,74,0,13.32,2.73,20.83,6.34,36,6,11.16-.24,18.04-2.43,26.76,2.02,5.19,2.65,13.66,9,15,10,6.51,4.88,8.44,7.07,12,7,3.67-.07,5.66-2.48,12-8,11.87-10.33,11.97-8.02,15-12,8.53-11.22,5.74-26.88,5-31-.69-3.85-2.38-8.58-5.76-18.02-1.11-3.09-2.61-7.11-5-12-2.73-5.59-4.1-8.38-6-10-3.99-3.39-8.78-3.61-17-4-6.2-.29-9.3-.44-12,1-5.79,3.08-4.47,8.47-10,13-1.06.86-4.27,3.26-17,4-11.16.65-19.79-.54-23-1-19.71-2.82-24.95-4.25-37-6-15.68-2.27-23.52-3.41-34-3-13.91.54-17.14,2.99-35,4-8.49.48-11.78.15-24,0-13.99-.17-26.66.22-52,1-24.1.74-36.15,1.11-48,2-33.61,2.51-29.67,4.8-49,5-23.09.24-32.72.49-52,1-6.39.17-10.73.32-14.13,1.37"
          />
          <path
            id="outline2"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M2.25,259.88c7.93,1.26,20.11-1.64,36.54-2.87,6.7-.5,6.09-.12,48,2,11.41.58,72.72,3.68,113,5,58.79,1.93,48.21-.74,126,1,43.91.98,62.84,2.18,100,1,46.44-1.47,39.38-4.06,60-3,3.01.15,36.77,1.94,66-5,4.07-.97,11.4-2.9,15-9,3.83-6.49-.37-11.21,0-23,.38-12.06,4.92-11.74,9-32,3.14-15.62,1.54-21.22-1-25-2.66-3.96-6.52-6.17-8-7-2.02-1.13-9.03-5.05-15.24-2.98-6.13,2.04-5.9,8.06-12,15-6.67,7.59-15.34,9.93-23,12-7.8,2.11-21.76,5.88-35-1-8.9-4.63-12.97-11.73-20-24-5.45-9.51-10.14-17.7-9-28,.89-8.12,4.82-12.27,3-14-2.98-2.83-16.23,5.69-25,13-9.33,7.78-9.07,10.42-19,19-4.79,4.14-11.88,10.18-22,15-7.36,3.5-7.1,1.97-31.76,8.98-12.54,3.57-15.84,5.02-25,7-10.81,2.34-16.22,3.51-25,5-16.47,2.8-29.89,4.24-38,5-26.22,2.47-49.9,2.87-69,3-32.23.22-74.74.52-123-2-20.64-1.08-41.59,2.24-62-1-5.3-.84-10.13-1.8-14.22-1.96"
          />
          <path
            id="outline1"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M505.06,3.8c-1.49,16.23-3.49,32.23-5.49,48.23-1,9,1,19-1,28-1,4-4,7-6,11-7,14-25,12-38,17-10,4-17,13-19,24-2,14,2,26,11,37,18,21,45,30,71,29,16,0,34-6,42-21,4-8,8-15,12-22,2-5,4-9,4-14-1-6-1-11-1-17,2-29,3-57,5-86,1-12,2-23,3.16-35.22"
          />
          <path
            id="partofancon3"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M510.55,40.03c1.78,11.12.96,20.18,0,26-1.07,6.48-2.28,13.41-7,21-.79,1.28-4.61,7.28-12,13-10.02,7.75-14.43,5.57-21,11-11.48,9.5-11.18,27.13-11,31,.15,3.13.95,20.36,15,31,14.24,10.79,31.29,6.63,38,5,7.43-1.81,20.59-5.02,27-17,5.41-10.12,4.28-23.24-1-32-3.01-5-5.6-5.63-18-21-2.89-3.58-5.48-6.93-5-11,.51-4.34,4.2-7.01,8-10,0,0,10.38-8.16,19-17,7.1-7.28,9.73-15.85,15-33,2.01-6.56,4.4-15.72,6-27"
          />
          <path
            id="partofancon2"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M516.32,37.03c1.25-2,2.25-3,3.25-5,2-10,5-19,5.12-28.54"
          />
          <path
            id="partofancon1"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M547.27,154.95c8.97-5.99,15.84-14.91,25.77-26.92"
          />
          <path
            id="radius5"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M432.79,113.01c-6.62-4.98-14.64,26.51,2.33,63.02"
          />
          <path
            id="radius4"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M435.12,176.03c-4.6,0-30.57-36.51-8.33-73.02"
          />
          <path
            id="ulnar3"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M307.55,196.03c42,9,78,2.29,78-3.54"
          />
          <line
            id="ulnar2"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            x1="3.55"
            y1="246.03"
            x2="397.55"
            y2="244.03"
          />
          <path
            id="ulnar1"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M370.55,203.03c-121.98,1-244.98,2-366.96,2.49"
          />
          <path
            id="radius3"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M250.55,118.03c18.33-6.99,32.55-7.11,42-6,3.45.4,15.35,3.61,39,10,16.16,4.37,25.07,6.86,35,3,5.9-2.29,16.45-3.03,19-5.8"
          />
          <path
            id="radius2"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M6.17,133.83c31.4-3.8,62.4-1.8,93.4-3.8,14-1,27-3,41-4,19-1,38-1,57-1,15-1,30-4,43.98-8"
          />
          <path
            id="radius1"
            class="unterarm_mit_ellenbogen_lat_links2210_cls1"
            d="M6.24,157.44c6.33-.41,13.33-.41,19.33-.41,44-2,89,4,133-2,52,0,103,2,155,6,19,2,37,7,54.55,11.15"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="condyleshighlight"
          class="oberarmkondylen2210fill"
          style={{
            fill: props.colors.Oberarmkondylen2210Fill,
            stroke: props.colors.Oberarmkondylen2210Fill,
          }}
          d="M493.46,196.09c-5.76-1.22-18.14-3.95-31.25-12.94-5.7-3.91-13.21-9.18-19.31-19.01-2.21-3.56-8.26-13.57-7.99-25.99.04-2.02.2-7.14,2.53-13.15.72-1.86,1.77-4.52,3.94-7.38,1.38-1.82,4.1-4.9,9.71-7.89,1.47-.78,3.85-1.94,20.93-5.97,7.25-1.71,11.04-2.49,14.76-5.87,1.79-1.62,2.08-2.5,3.64-3.24,4.61-2.17,9.8,1.92,16.48,5.36,13.24,6.81,15.55,2.43,31.43,10.14,4.68,2.27,6.46,3.62,12.01,5.83,5.72,2.28,7.8,2.43,13.49,5.06,9.43,4.37,10.55,7.11,10.82,8.6.22,1.23-.13,1.7.1,4.85.22,3.1.74,5.16.91,6.07.8,4.24-1.15,8.37-4.65,15.57-2.43,5-2.83,4.75-7.18,12.74-4.27,7.86-3.79,7.93-5.76,10.72-4.47,6.34-9.71,9.9-11.63,11.12-5.86,3.73-11.13,4.95-15.67,5.97-6.63,1.47-11.76,1.46-17.19,1.42-3.95-.03-11.16-.13-20.12-2.02Z"
        />
        <path
          id="radialheadhighlight"
          class="radiushead2210fill"
          style={{
            fill: props.colors.RadiusHead2210Fill,
            stroke: props.colors.RadiusHead2210Fill,
          }}
          d="M388.5,146.85c.48-2.59,1.17-4.49,1.25-8.12.04-1.79-.08-3.6-.64-6.94-1.8-10.83-4.5-15.68-2.12-18.17.64-.67,1.3-.81,2.53-2.19.53-.6,1.08-1.32,2.43-3.88,1.99-3.76,2.15-4.73,3.54-6,.89-.82,1.73-1.24,2.32-1.53,2.98-1.49,7.51-1.29,17.5-.69,4.93.3,7.98.89,10.62,3.03,1.45,1.18,2.35,2.6,4.15,5.46,1.93,3.06,3,5.46,4.85,9.61.6,1.35,1.89,4.3,3.35,8.18.53,1.41,1.08,2.95,1.64,4.63,1.82,5.44,2.86,8.54,3.65,12.8.62,3.38,2.38,13.42-.95,23.6-1.83,5.6-4.08,7.88-5.31,8.93-1.15.98-1.69,1.12-4.15,2.83-2.71,1.88-4.53,3.45-7.08,5.61-5.39,4.57-8.08,6.86-8.24,7.03-.74.79-2,2.19-4.1,2.78-1.55.44-2.89.23-3.39.15-.44-.07-1.92-.35-5.26-2.63-.72-.49-1.13-.81-2.83-2.12-5.12-3.97-5.42-4.23-6.57-5.06-3.34-2.41-3.2-1.93-4.3-2.98-.28-.27-2.98-2.91-3.99-6.52-1.06-3.79.71-5.16,1.52-10.92,1.14-8.12-1.9-8.9-.4-16.89Z"
        />
      </g>
    </svg>
  );
}
