import style from "./style.scss";

function Lendenwirbelkorper3VdLiegend1713(props) {
  return (
<svg id="layer1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.14 141.73">

<g id="BG">
  <path id="BG2" class="Lendenwirbelkorper3_ap1713_cls3" d="M37.81,109.57c-.01-.19.77-.23,1.8-.94,1.08-.74,1.64-1.67,1.84-2.02.83-1.41.53-2.28.91-4.33.37-2.01.71-3.81,1.82-4.56,1.65-1.11,4.05.75,7.75,2.05,2.22.78,5.01.99,10.48,1.37,9.36.65,17.64,1.22,28.94.23,8.74-.77,13.1-1.15,16.86-2.51,3.71-1.34,5.18-2.48,7.29-1.82,6.12,1.9,7.91,15.91,5.24,17.32-1.03.54-2.07-1.12-4.78-.91-2.25.17-3.37,1.46-5.24,2.51-2.67,1.5-4.87,1.4-9.11,1.59-11.55.54-10.76,2.04-18.46,1.82-4.41-.12-7.8-.77-14.58-2.05-6.69-1.27-10.04-1.9-13.67-3.42-3.92-1.64-4.87-2.7-8.43-3.42-4.3-.86-8.63-.46-8.66-.91Z"/>
  <path id="BG1" class="Lendenwirbelkorper3_ap1713_cls3" d="M146.49.71c-22.88,0-45.76-.06-68.64-.06h-34.38c-5.71,0-11.42-.02-17.13,0-4.25.02-8.53,0-12.72-.66-.07.22-.14.44-.18.66-.07.42-2.48,6.6.23,6.15,2.73-.46,8.87-1.86,17.32,0,2.82.62,3.18,1.3,7.06,2.05,2.53.49,4.04.52,5.24,1.82,1.3,1.4,1.35,3.32,1.37,3.87.04,1.56-.5,2.73-1.59,5.01-1.51,3.14-2.21,3.4-2.51,5.01-.19,1.03-.11,2.09.46,3.77.83.25,1.53.89,1.75,2.04,1.19,5.99,2.69,11.9,4.94,17.58,11.84,4.9,64.18,6.02,65.54-1.97.43-2.52.03-4.08.29-5.69-.07-.38-.07-.78.03-1.13.28-.99.61-1.97.98-2.93.06-.42.19-.88.57-1.46,0-.03.01-.05.02-.08.2-.78.73-1.4,1.4-1.78.41-.9,1.15-1.39,2-1.48.58-.57,1.01-1.13,1.32-1.63,1.22-1.94,2.18-5.16.91-7.75-.64-1.3-1.12-.97-2.96-3.42-1.43-1.89-2.34-3.13-2.05-4.33.31-1.3,1.91-2,3.42-2.51,4.8-1.62,9.5-1.6,14.81-1.37,6.16.27,7.88.59,10.25-.68.61-.33,3.28-1.76,4.1-4.56,0,0,.54-1.86-.33-4.51-.5,0-1,0-1.5,0Z"/>
</g>
<g id="Anatomy">
  <path id="Anatomy45" class="Lendenwirbelkorper3_ap1713_cls4" d="M41.08,94.74c.18-2.36,11.97-3.41,17.62-3.65,6.49-.27,8.32.37,17.32,0,6.69-.27,5.53-.62,9.11-.61,2.75.01,5.93.23,23.09,4.25,4.43,1.04,7.66,1.83,7.59,2.43-.06.52-2.52.48-6.99,1.22-3.39.56-5.35,1.14-6.99,1.52-1.44.34-5.85,1.27-22.18,1.22-10.04-.03-5.72-.38-18.53-.61-3.25-.06-8.45-.12-14.58-2.43-2.38-.89-5.57-2.09-5.47-3.34h.01Z"/>
  <path id="Anatomy44" class="Lendenwirbelkorper3_ap1713_cls5" d="M101.4,31.47c-2.4-1.97-2.68-6.39-1.22-9.42.94-1.95,2.76-3.02,6.38-5.16,4-2.36,7.1-4.2,8.81-3.04,1.16.79.53,2.18,2.36,5.11,1.07,1.71,1.56,1.69,2.2,2.79,1.72,2.97,1.07,8.25-1.82,10.33-1.11.8-1.74.55-9.11.3-5.79-.19-6.57-.07-7.59-.91h0Z"/>
  <path id="Anatomy43" class="Lendenwirbelkorper3_ap1713_cls5" d="M41.18,29.19c-1.25-1.87-.5-4.5-.15-5.75.96-3.39,2.89-3.69,3.49-6.7.63-3.15-1.12-4.73-.3-5.47,1.55-1.41,9.61,2.75,12.76,9.42,1.31,2.77,1.52,5.46,1.67,7.27.17,2.14.24,3.22-.45,3.97-1,1.07-2.7.54-7.59-.3-7.01-1.2-8.42-.94-9.42-2.43h0Z"/>
  <path id="Anatomy42" class="Lendenwirbelkorper3_ap1713_cls7" d="M78.54,34.36c-8.43-.65-8.08-1.42-11.24-.91-2.75.45-5.24,1.39-6.99,0-1.05-.84-1.72-2.43-1.22-3.34.72-1.31,3.63-.84,6.38-.61,5.63.47,4.46-.58,13.67-.61,9.38-.03,22.2,1.02,22.25,2.58,0,.22.28,1.66-2.29,2.45-6.37,1.94-13.11,1.01-20.57.44h.01Z"/>
  <path id="Anatomy41" class="Lendenwirbelkorper3_ap1713_cls5" d="M83.23,16.67c4.42,14.65-.22,28.02,2.11,28.02"/>
  <path id="Anatomy40" class="Lendenwirbelkorper3_ap1713_cls5" d="M85.35,44.69c-6.48,13.81-8.56,3.11-9.06-1.92-.09-.89.23-1.72.64-2.51,1.28-2.44-3.1-13.95,2.22-23.83"/>
  <path id="Anatomy39" class="Lendenwirbelkorper3_ap1713_cls4" d="M48.93,44.69c4.02-1.36,7.29-1.14,10.98-.91,0,0,3.43.21,19.4.91,16.37.73,24.56,1.09,27.65.91.96-.05,3.5-.22,6.38.91,1.16.45,5.9,2.5,7.08,6.23,1.7,5.39-6.01,8.02-6.17,15.34-.14,6.35,5.61,7.47,7.59,17.32.65,3.21,1.73,8.59-.91,10.94-3.09,2.74-8.75-.9-18.53-3.34-7.67-1.91-13.82-1.88-26.13-1.82-14.72.07-22.07.11-29.47,2.43-3.98,1.25-6.71,2.56-8.2,1.22-3.26-2.94,6.45-12.25,4.86-25.52-1.08-9.02-6.51-12.6-3.95-17.92,2.01-4.18,7.29-5.96,9.42-6.68v-.02Z"/>
  <path id="Anatomy38" class="Lendenwirbelkorper3_ap1713_cls7" d="M57.88,52.89c-.9-.88,1.15-2.56,1.52-7.59.41-5.51-1.71-8.11,0-10.03.89-1,2.42-1.07,5.47-1.22,3.69-.17,7.8-.37,9.11,1.82,1.03,1.72-.12,4.15-.91,5.77-3.92,8.04-13.42,12.97-15.19,11.24h0Z"/>
  <path id="Anatomy37" class="Lendenwirbelkorper3_ap1713_cls7" d="M86.75,40.43c.93,3.29,2.62,5.42,6.38,9.72,6.35,7.25,7.04,7.46,7.59,7.29.79-.24,1.35-1.27,2.08-11.85.63-9.24.39-11.09-.86-11.85-1.5-.91-2.63.84-7.29,1.22-4.53.36-6.65-1.05-7.9.3-1.12,1.21-.48,3.47,0,5.16h0Z"/>
  <path id="Anatomy36" class="Lendenwirbelkorper3_ap1713_cls4" d="M7.15,48.64c-.73.16-4.6,1.02-6.08,4.25-1.07,2.34-.51,5.11.61,6.99,2.29,3.84,7.39,4.62,15.8,5.77,7.54,1.04,7.53-.03,13.06,1.22,5.93,1.34,10.66,1.29,12.03-.06,1.46-1.44-1.88-3.4-3.22-7.23-.98-2.82-1.48-4.23-2.43-5.16-3.17-3.09-7.14.04-14.58-1.22-7.82-1.32-9.54-5.81-15.19-4.56Z"/>
  <path id="Anatomy35" class="Lendenwirbelkorper3_ap1713_cls4" d="M53.33,35.57c-.44-.04-.25,3.14-2.43,5.16-1.5,1.39-3.04,1.25-3.04,1.82,0,1.05,5.16,2.63,6.37,1.07.4-.52.08-1.03-.29-3.81-.37-2.77-.31-4.23-.61-4.25h0Z"/>
  <path id="Anatomy34" class="Lendenwirbelkorper3_ap1713_cls4" d="M106.19,35.57c-.52.13-.55,1.61-.61,4.56-.08,4.54.45,5.21.91,5.47.48.26.75,0,1.82-.03,2.75-.1,4.1,1.4,4.56.94.49-.48-.9-2.3-3.95-7.29-1.63-2.67-2.23-3.78-2.73-3.65Z"/>
  <path id="Anatomy33" class="Lendenwirbelkorper3_ap1713_cls5" d="M102.72,38.31c0,3.31-7.64,3.39-14,6.38"/>
  <path id="Anatomy32" class="Lendenwirbelkorper3_ap1713_cls5" d="M62.84,53.23c3.16,8.43,20.54,15.26,33.4.42"/>
  <path id="Anatomy31" class="Lendenwirbelkorper3_ap1713_cls4" d="M113.88,69.66c1.44,2.81,9.02-.86,22.69-.98,11.24-.1,15.94,2.31,19.44-1.22,2.71-2.72,3.5-7.78,1.52-10.33-1.53-1.96-4.58-1.94-10.63-1.82-8.69.17-8.71,1.69-13.97,1.25-6.74-.56-10.1-3.33-12.87-1.18-.64.49-.84.94-2.08,3.75-4.27,9.68-4.32,10.06-4.09,10.52h0Z"/>
  <path id="Anatomy30" class="Lendenwirbelkorper3_ap1713_cls5" d="M102.54,70.51c-2.39-2.66-1.37-7.16,0-9.72,2.23-4.19,7.45-7.12,9.42-5.77s.31,6.73-.61,9.72c-.97,3.15-2.18,7.07-4.86,7.51-2.05.33-3.79-1.55-3.95-1.73h0Z"/>
  <path id="Anatomy29" class="Lendenwirbelkorper3_ap1713_cls5" d="M48.77,70.51c-.88-.89-.44-1.79.61-9.11.55-3.83.73-5.75,1.82-6.08.68-.2,1.01.43,3.04,1.22,2.51.97,3.17.46,3.95,1.22,1.34,1.29.67,4,0,6.68-.75,3.01-1.15,4.62-2.73,5.77-1.98,1.44-5.24,1.77-6.68.3h-.01Z"/>
  <path id="Anatomy28" class="Lendenwirbelkorper3_ap1713_cls5" d="M54.23,43.63v12.9"/>
  <path id="Anatomy27" class="Lendenwirbelkorper3_ap1713_cls5" d="M43.46,70.72c8.26,0,5.77,7.23,8.21,9.69.52.52.52,1.34-.06,1.79-4.49,3.48-8.39,8.42-5.27,11.39"/>
  <path id="Anatomy26" class="Lendenwirbelkorper3_ap1713_cls5" d="M107.48,94.86c6.21-4.32,5.57-6.79,5.06-7.99-.25-.59-.61-1.13-1.06-1.59-5.77-5.88-4.21-4.93-5.91-5.97-1.2-1.44,2.54-7.01,8.31-9.65"/>
  <path id="Anatomy25" class="Lendenwirbelkorper3_ap1713_cls5" d="M106.49,45.6c0,6.36-.41,11.51-.92,11.51"/>
  <path id="Anatomy24" class="Lendenwirbelkorper3_ap1713_cls5" d="M117.33,50.08c0,.7-8.94,1.27-19.98,1.27"/>
  <path id="Anatomy23" class="Lendenwirbelkorper3_ap1713_cls5" d="M64.02,55.43c0,1.13-3.09,1.96-7.41,1.96"/>
  <path id="Anatomy22" class="Lendenwirbelkorper3_ap1713_cls5" d="M76.72,66.26c-1,.91-.98,2.43-.91,3.65.31,5.91,1.58,6.94,1.52,9.72-.13,5.5-5.22,7.08-4.25,11.56.31,1.41,1.27,3.33,2.73,3.63,2.85.59,7.39-5,8.02-10.81.14-1.3-.08-1.07-.73-6.51-.96-8.12-.7-10.67-2.51-11.66-1.2-.66-2.93-.43-3.87.42Z"/>
  <path id="Anatomy21" class="Lendenwirbelkorper3_ap1713_cls5" d="M57.88,52.89c0,2.69.14,4.86.3,4.86"/>
  <path id="Anatomy20" class="Lendenwirbelkorper3_ap1713_cls6" d="M116.31,129.06c1.34,1.41,3.65-.29,9.49-1.07,9.25-1.23,10.7.68,16.41-1.22,4.13-1.37,9.95-4.56,9.72-7.9-.12-1.71-1.77-2.87-2.43-3.34-4.03-2.85-7.75-.09-15.79-.33-7.59-.23-11.69-2.9-13.32-.94"/>
  <path id="Anatomy19" class="Lendenwirbelkorper3_ap1713_cls5" d="M40.5,95.41c7.8,5.71,44.84,8.6,75.18,1.94"/>
  <path id="Anatomy18" class="Lendenwirbelkorper3_ap1713_cls6" d="M40.88,129.15c-1.52,1.26-5.88-5.95-14.58-7.59-6.16-1.16-8.25,1.64-14.89-.3-3.46-1.02-8.67-3.48-8.51-6.08h0c.13-2.09,3.67-3.76,6.38-4.26,4.25-.8,5.03,1.54,12.46,2.43,5.03.61,7.57.91,10.63-.3,1.56-.62,3.44-.74,4.53-.29"/>
  <path id="Anatomy17" class="Lendenwirbelkorper3_ap1713_cls6" d="M114.74,141.62c-.26-2.57-.23-5.31.26-8.21,1.76-10.28,8.2-15.99,5.77-18.84-2.12-2.49-8.06.65-18.23,2.73,0,0-12.14,2.48-27.65,1.22-22.4-1.83-32.92-12.79-37.06-8.51-3.31,3.42,3.82,9.99,3.34,23.09-.1,2.75-.52,5.31-1.14,7.68"/>
  <path id="Anatomy16" class="Lendenwirbelkorper3_ap1713_cls7" d="M108.01,96.33c-1.65.59.03,5.35-1.52,12.76-1.28,6.12-3.64,8.67-2.51,9.75,1.33,1.28,6.29-.63,8.87-4.15,3.29-4.49,2.02-10.7-.28-14.41-.94-1.52-3.18-4.44-4.56-3.95Z"/>
  <path id="Anatomy15" class="Lendenwirbelkorper3_ap1713_cls7" d="M46.95,115.47c.27,1.48.48,2.56.78,3.41.38.03.76.05,1.14.08,2.37.18,4.72.43,7.08.72.74-2.24.51-5.71,1.33-9.37,2.66-11.8,14.26-16.48,12.75-19.1-.57-.99-2.23-.33-13.96,1.18-7.14.92-9.01,1-10.33,2.73-1.02,1.34-.78,4.44-.3,10.63.18,2.26.62,4.75,1.52,9.72h0Z"/>
  <path id="Anatomy14" class="Lendenwirbelkorper3_ap1713_cls5" d="M43.74,97.27c-2.82,7.24-1.37,11.85-6.15,12.3"/>
  <path id="Anatomy13" class="Lendenwirbelkorper3_ap1713_cls7" d="M47.84,98.18c0,6.82.17,13.1,2.81,19.49"/>
  <path id="Anatomy12" class="Lendenwirbelkorper3_ap1713_cls7" d="M81.39,90.73c15.81,4.97,18.72,20.37,17.48,27.04"/>
  <path id="Anatomy11" class="Lendenwirbelkorper3_ap1713_cls7" d="M112.85,114.69c2.04-6.78-29.54-11.55-73.4-5.67"/>
  <path id="Anatomy10" class="Lendenwirbelkorper3_ap1713_cls5" d="M113.63,3.72c-.52,2.69-1.43,6.76.95,8.86,1.93,1.7,3.3.51,5.32-.28,2.43-.95,5.02-1.26,7.6-1.36,4.69-.18,22.7,1.8,20.71-7.11"/>
  <path id="Anatomy9" class="Lendenwirbelkorper3_ap1713_cls5" d="M43.81,3.23c.68,2.55,3.46,7.56-.52,7.23-4.29-.36-14.58-7.29-29.62-3.65"/>
  <path id="Anatomy8" class="Lendenwirbelkorper3_ap1713_cls5" d="M62.41,1.93c-.05.12-.09.25-.1.4-.2,2.9,8.5,5.7,9.7,6,2.8.9,8.7,2.7,15.8.9,3.3-.8,7.6-1.9,8.5-4.9.15-.46.17-.95.07-1.5"/>
  <path id="Anatomy7" class="Lendenwirbelkorper3_ap1713_cls5" d="M106.71,16.83c4-2.4,7.1-4.2,8.8-3,1.2.8.5,2.2,2.4,5.1"/>
  <path id="Anatomy6" class="Lendenwirbelkorper3_ap1713_cls5" d="M99.96,2.91c-.51,1.62-.66,3.62-.56,5.12.3,3.8,2.4,7.9,2.4,7.9,0,0,2,1,3.4.6,1.7-.5,2.3-3.5,2.7-5.2.5-2.2,1-4.7-.3-7.3-.19-.34-.41-.69-.67-1.04"/>
  <path id="Anatomy5" class="Lendenwirbelkorper3_ap1713_cls5" d="M52.42,2.88c-.51,3.03-1.51,11.7,1.79,13.28"/>
  <path id="Anatomy4" class="Lendenwirbelkorper3_ap1713_cls5" d="M55.13,17.3c6.99-.88,8.11-7.13,7.75-13.22"/>
  <path id="Anatomy3" class="Lendenwirbelkorper3_ap1713_cls5" d="M51.87,2.89c-.71,2.2,1.33,8.26-1.72,8.7-4.11.59-5.85-5.64-6.44-8.36"/>
  <path id="Anatomy2" class="Lendenwirbelkorper3_ap1713_cls5" d="M107.61,4.03c-.55,3.14-4.76,5.49-8.1,5.5"/>
  <path id="Anatomy1" class="Lendenwirbelkorper3_ap1713_cls5" d="M41.01,23.33c1-3.4,2.9-3.7,3.5-6.7.6-3.1-1.1-4.7-.3-5.5,1.6-1.4,9.6,2.8,12.8,9.4"/>
</g>
<g id="Overlays">
  <path id="Overlays5" class="Angrenzende_WK1713Fill" style= {{
fill: props.colors.Angrenzende_WK1713Fill,
stroke: props.colors.Angrenzende_WK1713Fill,
opacity: props.colors.Angrenzende_WK1713Fill,
}} d="M114.73,141.73c-.26-2.57-.23-5.31.26-8.21,1.76-10.28,8.2-15.99,5.77-18.84-2.12-2.49-8.06.65-18.23,2.73,0,0-12.14,2.48-27.65,1.22-22.4-1.83-32.92-12.79-37.06-8.51-3.31,3.42,3.82,9.99,3.34,23.09-.1,2.75-.52,5.31-1.14,7.68"/>
  <path id="Overlays4" class="Full_WK1713Fill" style= {{
fill: props.colors.Full_WK1713Fill,
stroke: props.colors.Full_WK1713Fill,
opacity: props.colors.Full_WK1713Fill,
}} d="M48.76,44.44c4.02-1.36,7.29-1.14,10.98-.91,0,0,3.43.21,19.4.91,16.37.73,24.56,1.09,27.65.91.96-.05,3.5-.22,6.38.91,1.16.45,5.9,2.5,7.08,6.23,1.7,5.39-6.01,8.02-6.17,15.34-.14,6.35,5.61,7.47,7.59,17.32.68,3.4,1.64,8.14-.91,10.94-2.05,2.25-4.75,1.47-13.45,2.32-6.86.67-6.25,1.26-13.44,2.05-6.86.75-12.04.72-16.86.68-5.9-.05-13.97-.15-22.78-1.82-4.81-.91-7.98-1.94-11.85-3.19-2.37-.77-3.56-1.16-3.94-1.56-3.88-4.04,5.84-12.92,4.86-25.52-.7-9.01-6.3-12.46-3.95-17.92,1.66-3.85,5.99-5.72,9.42-6.7Z"/>
  <path id="Overlays3" class="Full_WK1713Fill" style= {{
fill: props.colors.Full_WK1713Fill,
stroke: props.colors.Full_WK1713Fill,
opacity: props.colors.Full_WK1713Fill,
}} d="M7.68,48.4c-.73.16-4.6,1.02-6.08,4.25-1.07,2.34-.51,5.11.61,6.99,2.29,3.84,7.39,4.62,15.8,5.77,7.54,1.04,7.53-.03,13.06,1.22,5.93,1.34,10.66,1.29,12.03-.06,1.46-1.44-1.88-3.4-3.22-7.23-.98-2.82-1.48-4.23-2.43-5.16-3.17-3.09-7.14.04-14.58-1.22-7.82-1.32-9.54-5.81-15.19-4.56Z"/>
  <path id="Overlays2" class="Full_WK1713Fill" style= {{
fill: props.colors.Full_WK1713Fill,
stroke: props.colors.Full_WK1713Fill,
opacity: props.colors.Full_WK1713Fill,
}} d="M114.19,69.68c1.44,2.81,9.02-.86,22.69-.98,11.24-.1,15.94,2.31,19.44-1.22,2.71-2.72,3.5-7.78,1.52-10.33-1.53-1.96-4.58-1.94-10.63-1.82-8.69.17-8.71,1.69-13.97,1.25-6.74-.56-10.1-3.33-12.87-1.18-.64.49-.84.94-2.08,3.75-4.27,9.68-4.32,10.06-4.09,10.52h0Z"/>
  <path id="Overlays1" class="Angrenzende_WK1713Fill" style= {{
fill: props.colors.Angrenzende_WK1713Fill,
stroke: props.colors.Angrenzende_WK1713Fill,
opacity: props.colors.Angrenzende_WK1713Fill,
}} d="M42.37,29.6c1.38.94,2.13.39,5.24.91,3.71.63,3.43,1.55,5.92,1.59,2.59.05,3.81-.93,5.47,0,.95.53.89.97,1.82,1.59,1.39.93,2.96.54,5.01.23,0,0,3.69-.56,8.2-.46,2.22.05,2,.32,6.38.91,5.84.78,9.58.74,11.39.68,4.58-.15,6.06-.76,6.84-1.14,1.16-.57,3.65-2.05,3.65-2.05,0,0,3.38.12,5.24.23,7.16.41,8.14,1.56,10.25.46,2.24-1.17,3.04-3.46,3.19-3.87,1.06-3-.3-5.82-.68-6.61-1.06-2.19-1.63-1.67-3.19-4.56-1-1.85-1-2.56-2.51-5.47-1.12-2.17-1.15-1.83-1.37-2.51-1.18-3.66,2.02-6.43.91-8.2-.9-1.43-3.61-1.11-6.38-.91-4.85.35-9.74-.37-14.58,0h-43.97c-.48.14-2.67-.32-4.56,0-1.04.85-1.29,2.69-.91,4.1.65,2.4,2.94,2.9,2.73,4.33-.18,1.23-1.92,1.22-2.28,2.73-.29,1.22.76,1.59.91,3.42.18,2.17-1.1,3.95-1.37,4.33-1,1.39-1.59,1.13-2.51,2.51-.35.53-1.33,1.96-1.14,3.87.15,1.57,1.05,3.03,2.28,3.87Z"/>
</g>
</svg>
  );
}

export default Lendenwirbelkorper3VdLiegend1713;
