import style from "./style.scss";

function RechtesKahnbeinDaumenRausDpSitzend1348(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 227.81 214.48">
      <g id="Background">
        <path
          id="background_white"
          data-name="background white"
          class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls4"
          d="M.91,6.54c-.25.99-.54,1.92-.91,2.8,8.32,15.51,15.09,34.98,21.02,56.57,15.34,19.24,32.87,38.93,51.95,58.94,2.02,2.4,10.74,9.2,18.53,15.39,7.57,3.97,10.8,12.21,13.06,21.4,3.54,12.57,5.47,31.66,6.87,52.83l113.65-5.11v-55.95c0-2.41.55-4.74,1.65-6.69-.3-5.77-.62-11.54-.65-17.31-.02-2.47.56-4.88,1.73-6.86-.16-2.84-.29-5.69-.41-8.53-2.7-18.1-2.81-36.47-2.76-54.73,0-3.43.02-6.85.03-10.27-.39-.17-.67-.54-.67-1.13V8.21c-.15-2.4-.54-4.68-1.09-6.93-21.73-.55-43.5-.19-65.22-.63C124.43-.02,91.15.05,57.88.69c-.59,1.63-1.22,3.23-1.89,4.76-1.4-1.4-2.8-2.95-4.18-4.63-3.49.08-6.97.15-10.46.24-13.1.33-26.2.78-39.29,1.29-.06.21-.12.42-.18.63-.23.82-.46,1.64-.7,2.46-.01.06-.03.1-.04.15-.09.32-.17.64-.25.96h.02Z"
        />
      </g>
      <g id="handparts">
        <g id="handboneparts">
          <path
            id="bonepart29"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M118.32,104.12h-.02l-10.59,5.24c.04,5.28-2.73,9.02-10.25,10.14-4.86,2.16-10.01-.35-15.38-6.49-13.83-18.64-28.91-34.92-45.91-47.62-5.18-3-7.56-6.22-6.72-9.68.5-11.27,3.39-18.62,9.72-20.35,5.45-3.72,11.37-4.06,17.62-2.13,5.23.98,7.11,6.23,5.93,15.34-.58,4.49.47,9.08,3.22,12.69,7.68,10.08,19.21,16.8,33.08,21.49,6.07,1.93,11.45,4.3,14.89,7.9,5.7,3.92,7.82,8.33,4.41,13.48h0Z"
          />
          <path
            id="bonepart28"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M8.03,2.09c5.46,9.58,9.82,18.81,11.84,27.31,1.5,8.17,3.93,11.45,7.18,10.48,5.82-7.15,12.98-11.68,21.55-13.45,2.27-.47,4.08-2.33,4.2-4.65h0c.13-2.51-1.19-4.88-3.08-7.19-6.85-4.16-12.91-8.55-17.83-13.27"
          />
          <path
            id="bonepart27"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M27.07,39.88c1.74,2.07,4.29,2.42,7.63,1.14,3.94-2.93,4.76-7.11,5.01-11.51,3.86.72,7.51.89,10.48-.68,1.18-.94,1.38-2.24,1.04-3.74"
          />
          <path
            id="bonepart26"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M54.09,40.95c-1.77,1.35-4.49,2.34-8,3.03-3.3-.73-4.76-2.45-2.96-5.92,1.09-1.8,3.22-3.02,6.04-3.87,3.64-1.07,5.71-.46,6.49,1.54.73,1.86,0,4.01-1.57,5.22h0Z"
          />
          <path
            id="bonepart25"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M63.28,26.45h0c.98-.08,2.02,1.04,3.08,2.96.23,1.08.19,2.11-.89,2.97-1.29,1.03-3.19.97-4.46-.09-.67-.56-1.14-1.28-1.37-2.2-.91-2.81.21-4.09,3.65-3.65h0Z"
          />
          <path
            id="bonepart24"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M101.44.2l11.63,27.28c6.66,18.7,8.78,35.86,1.37,49.78-.79,2.72-.53,5.34.57,7.86,4.45,9.78,9.5,13.9,15.2,11.67.94-.37,1.72-1.07,2.26-1.93h0c2.43-3.8,4.91-6.95,7.61-7.93,5.69-2.04,10.69-1.41,14.95,2.15,1.22,1.03,3,1.02,4.26.04,2.59-2.02,3.29-3.66,2.09-4.92-6.29-6.77-10.86-14.77-13.33-24.27-3.4-1.45-6.27-3.24-7.97-5.81-8.45-18.7-13.69-36.61-16.37-53.89"
          />
          <path
            id="bonepart23"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M154.26.59c3.93,15.04,5.71,31.89,1.66,53.64-4.31,7.17-4.41,14.05,1.25,20.51,3.05,6.72,9.03,7.86,16.63,5.92h.02c6.11-1.26,10.61-3.78,14.09-7.12,1.68-1.61,2.34-4.03,1.82-6.3-1.36-5.92-3.32-11.41-5.72-16.6-.37-.8-1-1.42-1.73-1.9-2.88-1.89-5.06-6.28-6.87-12.06-1.29-12.62-2.35-24.72-2.98-35.83"
          />
          <path
            id="bonepart22"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M159.45,72.01l.47-4.08c.14-1.18-.21-2.36-.96-3.28l-.08-.1c-1.22-1.5-1.73-3.49-1.24-5.36.31-1.2.9-2.33,1.64-3.43.61-.9,1.02-1.93,1.12-3.02l.87-9.33"
          />
          <path
            id="bonepart21"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M180.03,46.63c1.52,4.24,2.58,8.36,1.97,12.05"
          />
          <path
            id="bonepart20"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M190.17.92c.9,15.73,1.14,30.25-.28,41.71-4.07,7.09-6.43,16.53-6.29,29.38h-.02c.5,6.23,3.13,9.71,9.46,8.43l22.9-7.75c4.49-1.84,4.32-5.37,1.37-9.91-2.5-6.19-5.51-10.19-8.7-12.29-1.44-.94-2.24-2.6-2.24-4.32l-.1-32.88,1.43-12.27"
          />
          <path
            id="bonepart19"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M206.9,48.5c.54,2.85,1.13,5.84,2.9,7.22"
          />
          <path
            id="bonepart18"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M222.3,31.64c-1.8,5.87-5.12,11.16-8.3,16.36-4.81,7.85-9.79,15.97-10.1,25.44-.11,3.25,0,7.25,3.25,8.92,3.84,1.98,8.63.43,12.3,3-.07-.82-.56-1.35-1.37-1.49.93,1.61,2.85,2.08,4.59,2.03"
          />
          <path
            id="bonepart17"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M123,91.9h0c1.47.43,2.78,1.3,3.79,2.45l6.25,7.16c2.53,3.07,3.99,7.7,4.08,14.35-3.36,4.15-5.96,8.27-7.3,12.35-.38,1.16-1.47,1.92-2.69,1.89-3.49-.09-6.62.39-9.03,2.06-3.41.14-6.21-.74-7.9-3.44-.39-.61-.58-1.31-.65-2.03-.33-3.33-4.35-6.37-2.73-9.23,5.77-4.03,6.89-10.45,6.49-17.66.11-6.7,2.93-9.87,9.7-7.9h0Z"
          />
          <path
            id="bonepart16"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M113.93,106.31c1.63,6.49,3.69,10.81,6.66,10.36,5.55,1.24,7.69-1.05,8.32-4.92.68-3.4-1.59-6.93,0-10.69"
          />
          <path
            id="bonepart15"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M154.46,92.03h0c.66.69.93,1.67.72,2.6-.69,2.95-.83,7.36-.79,12.25.01,1.12-.77,2.13-1.88,2.32-3.94.66-7.85,3.1-11.73,6.6-.92.84-2.32,1-3.36.31-2.03-1.34-3.59-4.08-4.38-9.02-.73-6.86.92-12.94,8.14-17.2,5.38-2.61,9.73-1.62,13.29,2.14h-.01Z"
          />
          <path
            id="bonepart14"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M165.31,124.34h.01c3.87,2.41,6.88,6.49,9.06,12.15,1.43,2.95,2.61,6.1,3.57,9.44.38,1.31.54,2.69.34,4.03-.44,3.01-2.19,5.27-5.52,6.61-1.9.76-3.97,1.05-6.02.98-8.73-.29-17.29-3.39-25.49-12.14-1.07-1.14-2.22-2.23-3.5-3.15-3.51-2.52-4.73-5.29-2.79-8.38.6-.97.83-2.18.47-3.27-.95-2.89,1.31-6.46,6.04-10.56.51-.43.96-.93,1.38-1.44,3.55-4.33,8.73-5.9,15.22-5.25,2.16.04,3.66,2.25,4.43,6.81.29,1.73,1.31,3.24,2.8,4.17h0Z"
          />
          <path
            id="bonepart12"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M157.45,113.22v6.18c.68,3.31,2.51,4.54,5.5,3.68.19-.06.34.17.21.32-2.34,2.77-2.75,5.65.84,8.78,3.21,4.07,7.2,5.85,11.48,6.78"
          />
          <path
            id="bonepart11"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M197.33,115.16h-.03l-4.36-14.33c-.39-1.29-.53-2.64-.42-3.99l.63-7.42c1.9-11.15.67-10.29,0-11.62-2.52-2.33-7.08-1.72-14.94,3.68-1.26.87-2.77,1.34-4.3,1.29-5.14-.17-9.1,1.23-10.16,6.48-.21,1.04-.84,1.94-1.66,2.62-2.64,2.17-4.31,4.81-4.33,8.23-.02,2.5,1.54,4.74,3.8,5.8l.63.3c1.92.9,3.27,2.82,3.22,4.94-.03,1.19-.43,2.29-1.41,3.24-2.78,2.22-2.54,5.28-1.05,8.69.16,3.55,1.65,6.51,5.49,8.4,7,3.11,14.97,2.83,23.47.7,3.36-.41,5.28-2.96,6.07-7.17.62-3.27.32-6.65-.65-9.83h0Z"
          />
          <path
            id="bonepart10"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M224.25,76.56c-.76-.08-2.76-1.08-3.76-1.08-7,1-14,3-21,3-6,0-10,5-10,11,1,11,0,23,5,34,2,2,6,4,8,3,8-7,10-18,20-23,1-1,1-2,1.73-2.97"
          />
          <path
            id="bonepart9"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M212.76,93.6c-3.89,1.95-2.96,5.86-5.86,11.56-2.93,1.69-5.44-.01-7.75-3.32-1.51-2.17-2.41-4.72-2.64-7.35l-.4-4.48c-.12-1.31-.49-2.59-1.08-3.76-.84-1.68-.67-3.11.87-4.22,2.49-2.29,4.87-1.65,7.18,1.37,3.48,2.47,7.02,3.22,10.59,2.85"
          />
          <path
            id="bonepart8"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M224.61,109.87c-7.12.61-13.12,6.61-14.12,13.61-1,5-7,6-10,9,3,6,7,10,12,15,4,3,11,2,14.15-2.14"
          />
          <path
            id="bonepart7"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M227.27,123.59c-.96-1.17-2.12-2.18-3.42-3-4.96-3.13-10.19-5.1-15.74-5.65-1.12-.11-2.27.11-3.21.72-1.49.98-2.47,2.8-2.39,6.36,1.18,4.19,2.62,8.04,4.66,11.11,2.59,3.92,6.82,6.56,11.49,7.08,2.71.31,5.27-.22,7.65-1.72"
          />
          <path
            id="bonepart6"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M181.66,160.19h0l9.23,1.25c3.84.23,8.02-1.05,12.53-3.88,4.9-2.44,4.29-5.57.39-9.11-.62-3.14-1.5-5.89-2.73-8.13-1.33-2.43-3.89-3.93-6.66-3.93-3.17,0-6.16.74-8.89,2.56l-7.01.42c-1.69.11-3.05,1.42-3.24,3.11-.5,4.29-.13,8.07,1.56,11.09-.17,4.3.86,7.17,4.82,6.61h0Z"
          />
          <path
            id="bonepart5"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M178.51,139.38c.64-1.3,1.82-2.54,3.63-3.72,3.12-2.04,7.01-2.67,10.49-1.36,1.74.65,3.43,1.65,5.1,2.85"
          />
          <path
            id="bonepart4"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M183.59,211.79c6.68-8.16,11.1-16.32,13.56-24.49,3.08-15.37-.24-21.17-6.82-22.1l-36.7-7.18c-6.75-2.7-14.74-8.94-23.33-16.9-1.54-1.43-3.93-1.39-5.45.07-3.97,3.82-4.83,9.88-3.36,17.63,3.78,8.77-2.72,15.95-.78,33.15.14,6.61,2.31,13.47,5.01,20.39"
          />
          <path
            id="bonepart3"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M206.9,210.21c.59-8.73-3.41-15.73-4.41-24.73-1-5,0-10,3-13,5-4,11-2,17-4,1,0,1-1,2.36-2.27"
          />
          <path
            id="bonepart2"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M206.9,170.77c6.16,1.26,11.72,4.49,17.92,5.6"
          />
          <path
            id="bonepart1"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M153.64,158.03c9.46,7.4,33.92,6.63,36.7,11.96,4.38,7.44,2.41,16.46-1.5,25.97"
          />
        </g>
        <g id="outlines">
          <path
            id="outlinepart8"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M53.46,2.75c.82.92,1.67,1.83,2.55,2.7.33-.76.65-1.52.96-2.29"
          />
          <path
            id="outlinepart7"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls1"
            d="M110.73,204.57c-1.34-17.03-2.04-35.58-8.83-51.52-4.86-11.42-16.51-17.13-25.43-24.88-11.99-10.41-22.26-23.05-32.62-35.03-9.8-11.33-21.91-22.31-25.99-37.09C13.77,41.26,8.86,26.75,1.93,13.01"
          />
          <path
            id="outlinepart6"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M111.43,214.45c-.11-1.68-.22-3.34-.34-4.99"
          />
          <path
            id="outlinepart5"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M60.91,6.79c-1.52-.65-3.07-1.27-4.64-1.88"
          />
          <path
            id="outlinepart4"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls1"
            d="M93.21,25.64c-7.31-5.76-15.53-10.93-24.72-15.41"
          />
          <path
            id="outlinepart3"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            d="M100.2,31.57c-1.23-1.12-2.48-2.22-3.76-3.3"
          />
          <line
            id="outlinepart2"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            x1="55.94"
            y1="13.8"
            x2="55.94"
            y2="18.8"
          />
          <line
            id="outlinepart1"
            class="Rechtes_Kahnbein_Daumen_raus_dp1348_cls2"
            x1="55.94"
            y1="5.28"
            x2="55.94"
            y2="10.28"
          />
        </g>
      </g>
      <g id="kahnbeinhighlight">
        <path
          id="bonepart14-2"
          data-name="bonepart14"
          class="Kahnbein1348Fill"
          style={{
            fill: props.colors.Kahnbein1348Fill,
            stroke: props.colors.Kahnbein1348Fill,
          }}
          d="M165.31,124.34h.01c3.87,2.41,6.88,6.49,9.06,12.15,1.43,2.95,2.61,6.1,3.57,9.44.38,1.31.54,2.69.34,4.03-.44,3.01-2.19,5.27-5.52,6.61-1.9.76-3.97,1.05-6.02.98-8.73-.29-17.29-3.39-25.49-12.14-1.07-1.14-2.22-2.23-3.5-3.15-3.51-2.52-4.73-5.29-2.79-8.38.6-.97.83-2.18.47-3.27-.95-2.89,1.31-6.46,6.04-10.56.51-.43.96-.93,1.38-1.44,3.55-4.33,8.73-5.9,15.22-5.25,2.16.04,3.66,2.25,4.43,6.81.29,1.73,1.31,3.24,2.8,4.17h0Z"
        />
      </g>
    </svg>
  );
}

export default RechtesKahnbeinDaumenRausDpSitzend1348;
