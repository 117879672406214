import "../styles/RightSideMotionView.scss";
import { isSelected } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const Human = map.Human;

function RightSideMotionView({ bpartSetter, selected }) {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 451.8 633.3"
    >
      <g id="a">
        <path
          id="b"
          class="rsmv-st0"
          d="M163.3,176.1c10.8-17.3,23.4-32,39-42.1c-12.1-4.1-23.3-1.2-33.8,7c-22.5-7-47-1.9-72.4,7.9
		c-3.3,6.5-6.1,39.3,14.5,95.9c-0.2,10.5-6,13.2-8.4,23.4c0.4,2.9-0.2,6.4,0.5,9c0.5,1.8,2,0.6,2.4,5.4c0.1,1.7,3.5,1.8,4.5,2.8
		c-0.5,3.5,10.5,2.8,10.5,2.8s4.5,2.3,7.5,0.4c0,0,4.6,4.9,7.6,2.5c1.9-1.5,2.4-8.8,3.1-13.2c-1.3-11.5-10-19.9-9.6-19.2
		c0,0,0.8-13-0.3-16.1l-7.4-38.3c5.5-12.8,6.8-23.4,4.6-32.2C139.6,179.2,152,180.3,163.3,176.1L163.3,176.1z"
        />
        <path
          id="c"
          class="rsmv-st1"
          d="M138.2,360c-5-33.8-8.3-52.1,28-84.4c0.8-32.9-1.2-60.2-6.7-81.2c1.5-25.1,6.9-41.6,14.1-55.6
		c6.8-4,15.4-14.8,21.7-20.3c0.9-3.9,3.8-10.9-3.8-20.9c-20.2-26.8-8.8-52.7,22.9-55.6c23.7-1.1,34.2,7.7,36,23.2v12.2
		c3.2,2.7,6,5.1,7.9,6.7l-4.3,5.5l1.2,4.9l-1.2,14.6c2,7.8-7.2,6.2-12.2,7.6c-6.1,1.7-4.4,6.9-3,8.2c-0.8,1.8,23.5,15.3,19.5,44.5
		c11.9,7.2,18.6,17.3,14,33.6c9.7-9.5,18.2-21.1,24.4-32.3c-8.4-9.9-7.9-15.4-10.4-21.6l3.7-15.1c3.4-2.3,5.7-1.9,6.7,1.2v4.9
		c4.7-3.6,8.6-3.3,11.6,1.2c7.2,1.1,12.5,6.7,14.6,19.5c-1.1,4.4-3.4,8.7-7.9,12.8c-14.2,35.8-28.2,65-41.5,72
		c-10.2,3.5-12.9-0.6-24.8-14.2c-6.8,24.6-13.5,47.4-19.1,52.7c0.4,12.3,0.5,23.8-1.2,29.3c6.7,9.6,33.9,35.1,77.5,87.2
		c11.9,8.6,15.2,20.4,11,44.5c13.4,43.3,14.6,100.9,28.7,117.8c14.6,16.5,34.9,24.5,61.6,23.2c7,1.3,11.2,4.2,6.1,12.2
		c-16,10.5-61.8,6.7-84.2,13.4c-23.4-0.9-23.3-16.9-17.7-36.6c0.4-11.4,3.9-26.6-10-47.8c-13.4-20.5-28.7-54-22.4-87.5
		c-13.4-8.8-54.9-31.5-79.3-56.3C158.1,378.2,145.1,369.3,138.2,360L138.2,360z"
        />
        <path
          id="d"
          class="rsmv-st1"
          d="M223.3,361.8c-2.7,19.1-12.7,34-12.4,32.2c0,0,5.7,25.7,0.9,50.6c2.7,3.9-38.4,29.2-103.7,66.5
		L62,556.5c-7.8,21-17.9,35.9-15.5,46.4c-8.6,4.5-15.1-5.1-20.6-22.5c-4-5.1,0.5-22.4,10.4-47.5c-1.8-28.3,12.7-31.8,35.2-23
		c32.5-38.4,67.2-73.9,90.9-79c-10.5-25.5-22.2-51.6-23.6-71.1"
        />
        <path
          id="e"
          class="rsmv-st0"
          d="M204.3,188.3c-12.3-0.3-18.1-12.9-21.2-30.9"
        />
        <line
          id="f"
          class="rsmv-st0"
          x1="248.8"
          y1="231.7"
          x2="219.7"
          y2="167.2"
        />
        <path
          id="g"
          class="rsmv-st0"
          d="M202.3,431.2c-0.5,11.5-5.1,19.6-16.9,21.9"
        />
        <path id="h" class="rsmv-st0" d="M310,427.5c-3.5-6.5-7-10.2-10.6-11" />
        <path
          id="i"
          class="rsmv-st0"
          d="M225.4,96.9c-13.8-3.7-15.7-10.3-5.7-19.9"
        />
        <g id="j">
          <path
            id="k"
            class="rsmv-st0"
            d="M227.3,256.4c-9.2-0.2-17.5-5.6-24.8-16.1"
          />
          <path
            id="l"
            class="rsmv-st0"
            d="M234.3,222.7c-9.2,0.7-15.2-7.8-20.9-17.1"
          />
          <path
            id="m"
            class="rsmv-st0"
            d="M229.5,241.1c-13.9-6.1-19.2-13.1-19.6-18.9"
          />
        </g>
      </g>
      <g id="n">
        <path
          onClick={() => {
            bpartSetter({ id: Human.children.leftLeg });
          }}
          class={`rsmv-st2 ${isSelected(selected, Human.children.leftLeg)}`}
          id="o"
          d="M234.3,320.6c6.7,9.6,28.1,28.1,71.7,80.2c11.9,8.6,15.2,20.4,11,44.5c13.4,43.3,14.6,100.9,28.7,117.8
		c14.6,16.5,34.9,24.5,61.6,23.2c7,1.3,11.2,4.2,6.1,12.2c-16,10.5-61.8,6.7-84.2,13.4c-23.4-0.9-23.3-16.9-17.7-36.6
		c0.4-11.4,3.9-26.6-10-47.8c-13.4-20.5-28.7-54-22.4-87.5c-13.4-8.8-40.5-23.6-68.2-46.1C218.4,386.7,225.4,358.4,234.3,320.6
		L234.3,320.6z"
        />
        <path
          id="p"
          onClick={() => {
            bpartSetter({ id: Human.children.rightArm });
          }}
          class={`rsmv-st2 ${isSelected(selected, Human.children.rightArm)}`}
          d="M238.8,125.1c-0.8,1.8,23.5,15.3,19.5,44.5c11.9,7.2,18.6,17.3,14,33.6c9.7-9.5,18.2-21.1,24.4-32.3
		c-8.4-9.9-7.9-15.4-10.4-21.6l3.7-15.1c3.4-2.3,5.7-1.9,6.7,1.2v4.9c4.7-3.6,8.6-3.3,11.6,1.2c7.2,1.1,12.5,6.7,14.6,19.5
		c-1.1,4.4-3.4,8.7-7.9,12.8c-14.2,35.8-28.2,65-41.5,72c-10.2,3.5-12.9-0.6-24.8-14.2l-29.1-64.5
		C216.5,142.2,224.7,130.3,238.8,125.1L238.8,125.1z"
        />
        <path
          id="q"
          onClick={() => {
            bpartSetter({ id: Human.children.head });
          }}
          class={`rsmv-st2 ${isSelected(selected, Human.children.head)}`}
          d="M195.3,118.6c0.9-3.9,3.8-10.9-3.8-20.9c-20.2-26.8-8.8-52.7,22.9-55.6c23.7-1.1,34.2,7.7,36,23.2v12.2
		c3.2,2.7,6,5.1,7.9,6.7l-4.3,5.5l1.2,4.9l-1.2,14.6c2,7.8-7.2,6.2-12.2,7.6c-6.1,1.7-4.4,6.9-3,8.2"
        />
        <path
          id="r"
          onClick={() => {
            bpartSetter({ id: Human.children.leftArm });
          }}
          class={`rsmv-st2 ${isSelected(selected, Human.children.leftArm)}`}
          d="M161.4,176.7c0.8-9.1,7.7-34.1,14.6-39.7c-0.8-0.9-6.6,3-7.5,3.9c-22.5-7-47-1.9-72.4,7.9
		c-3.3,6.5-6.1,39.3,14.5,95.9c-0.2,10.5-6,13.2-8.4,23.4c0.4,2.9-0.2,6.4,0.5,9c0.5,1.8,2,0.6,2.4,5.4c0.1,1.7,3.5,1.8,4.5,2.8
		c-0.5,3.5,10.5,2.8,10.5,2.8s4.5,2.3,7.5,0.4c0,0,4.6,4.9,7.6,2.5c1.9-1.5,2.4-8.8,3.1-13.2c-1.3-11.5-10-19.9-9.6-19.2
		c0,0,0.8-13-0.3-16.1l-7.4-38.3c5.5-12.8,6.8-23.4,4.6-32.2C141.1,180.3,155,179.5,161.4,176.7L161.4,176.7z"
        />
        <path
          id="s"
          onClick={() => {
            bpartSetter({ id: Human.children.rightLeg });
          }}
          class={`rsmv-st2 ${isSelected(selected, Human.children.rightLeg)}`}
          d="M138.4,359.9c1.4,19.4,13.1,45.6,23.6,71.1c-23.7,5.2-58.4,40.7-90.9,79c-22.5-8.8-37-5.3-35.2,23
		C26,558,21.5,575.3,25.5,580.4c5.4,17.5,12,27,20.6,22.5c-2.4-10.5,7.7-25.4,15.5-46.4l46.1-45.4c65.4-37.2,106.4-62.6,103.7-66.5
		c4.8-24.9-0.9-50.6-0.9-50.6c-0.4,1.8,9.7-13.1,12.4-32.2"
        />
        <path
          id="t"
          onClick={() => {
            bpartSetter({ id: Human.children.pelvis });
          }}
          class={`rsmv-st2 ${isSelected(selected, Human.children.pelvis)}`}
          d="M229.7,284.4l-63.5-8.7c-30.3,25.3-37,47.8-27.9,83.9l84.8,2.6c2.8-5.1,7.1-23.4,11.4-41.5
		c-1.9-2.1-4-4.3-6.1-7C229.8,305.4,230.1,295.5,229.7,284.4L229.7,284.4z"
        />
        <path
          id="u"
          onClick={() => {
            bpartSetter({ id: Human.children.mainBody });
          }}
          class={`rsmv-st2 ${isSelected(selected, Human.children.mainBody)}`}
          d="M184.7,277.9l2.3-104.6l8.3-54.7l43.5,6.5c-14.8,4.8-21.5,18.5-19.1,42.1l29.1,64.5
		c-6.8,23.4-13.6,47.9-19.1,52.7L184.7,277.9L184.7,277.9z"
        />
        <path
          id="v"
          class="rsmv-st2"
          d="M184.5,277.8l-18.4-2.1c1.8-27.5-1.1-54.5-6.7-81.2c0.9-20.6,5.7-39.1,14.1-55.6
		c7.1-6.7,14.3-13.4,21.7-20.3L187,175L184.5,277.8L184.5,277.8z"
        />
      </g>
    </svg>
  );
}

export default RightSideMotionView;
