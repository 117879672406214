import style from "./style.scss";

function RechteZeheDplOblSitzend(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.17 186.1">
      <g id="third_toe" data-name="third toe">
        <path
          id="third_toe_1"
          data-name="third toe 1"
          class="a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3"
          style={{
            fill: props.colors.a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3,
            stroke: props.colors.a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3,
          }}
          d="M35.84,185.66c5.86-3.91,5.74-9.03.47-15.24,5.31-14.4,4.6-23.1-9.79-19.11-12.4-1.2-19.7,1.2-15.7,12-1.63,3.33-2.62,6.63-3.08,9.9.44.24.81,12.21,1.25,12.45"
        />
        <path
          id="third_toe_2"
          data-name="third toe 2"
          class="a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3"
          style={{
            fill: props.colors.a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3,
            stroke: props.colors.a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3,
          }}
          d="M33.01,144.52c-4.7-2.7-10.1-1.9-16.2,2.2-9.2,6.2-13.7,4.2-8.2-15,3.5-18.2.5-35.6-7.5-52.5,0-10.4,2.1-14.1,7.1-8.2,3.9,2.1,7.5,1.9,10.9,0,7.1-3,9,.8,6.4,10.5-3.5,20.9-.3,36.5,10.5,46.1,6.3,10.9,8.3,18.7-3,16.9h0Z"
        />
        <path
          id="third_toe_3"
          data-name="third toe 3"
          class="a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3"
          style={{
            fill: props.colors.a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3,
            stroke: props.colors.a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3,
          }}
          d="M15.01,66.92c-9.1,1.6-13-.9-9.8-9,3.8-6.1,5.4-13.8,6-22.1-5.1-6.2,1.6-7.9,12.4-7.9,8.6.8,9.4,5.5,7.1,11.6-3.4,5.3-3.5,10.8-.8,16.5,2.5,8.3,3.1,14.6-7.1,9.8,0,0-7.9,1.1-7.8,1.1Z"
        />
        <path
          id="third_toe_4"
          data-name="third toe 4"
          class="a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3"
          style={{
            fill: props.colors.a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3,
            stroke: props.colors.a1410_Rechte_2Zehe_dpl_obl_sitzend_cls_3,
          }}
          d="M31.11,23.82l-15,.4c-10.9.5-8.3-5,1.1-13.5C13.81,4.92,12.81.52,19.91.92h1.4s6.4-.4,6.4-.4c3.7-.2,7.3,1.1,9.7,3.9,3.6,4.1,1.6,8.2-2.2,12.3,3.7,8.4.4,8.5-4.1,7.1h0Z"
        />
      </g>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="phalangen_overlays" data-name="phalangen overlays">
          <path
            id="phalangen_overlay_1"
            data-name="phalangen overlay 1"
            class="a1410_phalangenFill"
            style={{
              fill: props.colors.a1410_phalangenFill,
              stroke: props.colors.a1410_phalangenFill,
            }}
            d="M33.01,144.52c-4.7-2.7-10.1-1.9-16.2,2.2-9.2,6.2-13.7,4.2-8.2-15,3.5-18.2.5-35.6-7.5-52.5,0-10.4,2.1-14.1,7.1-8.2,3.9,2.1,7.5,1.9,10.9,0,7.1-3,9,.8,6.4,10.5-3.5,20.9-.3,36.5,10.5,46.1,6.3,10.9,8.3,18.7-3,16.9h0Z"
          />
          <path
            id="phalangen_overlay_2"
            data-name="phalangen overlay 2"
            class="a1410_phalangenFill"
            style={{
              fill: props.colors.a1410_phalangenFill,
              stroke: props.colors.a1410_phalangenFill,
            }}
            d="M15.01,66.92c-9.1,1.6-13-.9-9.8-9,3.8-6.1,5.4-13.8,6-22.1-5.1-6.2,1.6-7.9,12.4-7.9,8.6.8,9.4,5.5,7.1,11.6-3.4,5.3-3.5,10.8-.8,16.5,2.5,8.3,3.1,14.6-7.1,9.8,0,0-7.9,1.1-7.8,1.1Z"
          />
          <path
            id="phalangen_overlay_3"
            data-name="phalangen overlay 3"
            class="a1410_phalangenFill"
            style={{
              fill: props.colors.a1410_phalangenFill,
              stroke: props.colors.a1410_phalangenFill,
            }}
            d="M31.11,23.82l-15,.4c-10.9.5-8.3-5,1.1-13.5C13.81,4.92,12.81.52,19.91.92h1.4s6.4-.4,6.4-.4c3.7-.2,7.3,1.1,9.7,3.9,3.6,4.1,1.6,8.2-2.2,12.3,3.7,8.4.4,8.5-4.1,7.1h0Z"
          />
        </g>
        <g id="joint_overlays" data-name="joint overlays">
          <ellipse
            id="joint_overlay_1"
            data-name="joint overlay 1"
            class="a1410_gelenkeFill"
            style={{
              fill: props.colors.a1410_gelenkeFill,
              stroke: props.colors.a1410_gelenkeFill,
            }}
            cx="22.07"
            cy="26.61"
            rx="15.57"
            ry="6.68"
          />
          <ellipse
            id="joint_overlay_2"
            data-name="joint overlay 2"
            class="a1410_gelenkeFill"
            style={{
              fill: props.colors.a1410_gelenkeFill,
              stroke: props.colors.a1410_gelenkeFill,
            }}
            cx="16.24"
            cy="69.22"
            rx="16.24"
            ry="5.39"
          />
          <ellipse
            id="joint_overlay_3"
            data-name="joint overlay 3"
            class="a1410_gelenkeFill"
            style={{
              fill: props.colors.a1410_gelenkeFill,
              stroke: props.colors.a1410_gelenkeFill,
            }}
            cx="24.81"
            cy="147.82"
            rx="19.37"
            ry="7.75"
          />
        </g>
      </g>
    </svg>
  );
}

export default RechteZeheDplOblSitzend;
