import style from "./style.scss";

function HalswirbelsauleMitBewegtemUnterkieferVdStehend1603(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 467.68 579.73">

<path id="BG" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls2" d="M29.44,6.05c32.4,1.1,65.4.3,99.3-3.2,1.9-.2,3.8,0,5.7.6,12.2,4,24.7,1.8,37.3-.8.5-.1,1.1-.2,1.6-.2l21.8-1.7c2.7-.2,5.4.4,7.7,1.7,9.5,5.6,19.4,7.6,29.8,6,3.5-.5,7,.4,9.8,2.5l10.9,8.2c2,1.5,4.4,2.4,6.9,2.6,9.2.6,17.7-2.4,25.3-10.3,2-2.1,4.6-3.5,7.4-3.9l27.6-4.7c1.1-.2,2.2-.2,3.3-.1,32.8,2.8,66.6,2.1,101.3-1.3,5.7-.6,11.2,2.6,13.5,7.9l11,25.1c.8,1.8,1.1,3.6,1.1,5.5-.2,12.1,4.2,23.7,11.8,34.8,1.1,1.7,1.9,3.6,2.1,5.6l2,14.6c.4,2.9-.2,5.8-1.6,8.3-9.2,16.7-14.8,38.4-14.2,68.9v1c-1.2,22.2-5.1,42.6-14.4,59.2-1.1,2-1.7,4.2-1.7,6.5-.2,44.1-4.4,77-17.4,85-.6.3-1.1.7-1.6,1.1l-9.5,8.4c-2.2,1.9-3.7,4.5-4.3,7.3-5,24.1-17.9,46.8-34.8,68.9-2.8,3.7-7.4,5.5-12,5.1-78.2-8.3-163.2-7.8-252.8-1.5-4.6.3-9-1.7-11.7-5.5-20.5-28.5-36.8-58.7-48.8-90.7-.8-2.1-2-3.9-3.7-5.4-12.3-10.7-19.7-24.8-21.8-42.6L.04,37.05c-.3-3.7,1-7.3,3.5-10l15.6-16.8c2.7-2.8,6.4-4.4,10.3-4.2h0Z"/>
<g id="Anatomy">
  <g id="HWS">
    <path id="HWS74" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls3" d="M147.24,151.35c-2.5-2.2,3.4-9.7,4.7-26.9.9-11.1-1.1-15.2,2.1-19.7,3.1-4.4,8-5.2,16.9-6.6,10.7-1.7,18.4.2,20,.6,6.8,1.8,11.4,2.9,14.8,7.8,3.1,4.4,4.7,11.3,2.6,17.3-.5,1.4-2.3,6-6.3,7.8-2.9,1.3-5.6.4-7.4,0-5-1.1-9.7.6-19,4.2-17.8,6.7-25.5,18.1-28.4,15.5h0Z"/>
    <path id="HWS73" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls3" d="M327.44,113.65c.6,2.2-1.6,3.6-3.8,9.7,0,0-1.5,4.2-2.5,11.2-2.1,15.3,4.7,28.7,2.2,30.2-1.8,1.2-5.2-6.5-14.9-12.3-2-1.2-2.6-1.3-15.3-6-14.4-5.3-15.4-5.6-17.4-7.2-4.7-3.8-12.1-12.5-10.5-21.5,1.7-10.2,14-15.8,22.6-16.2,7.1-.3,7.4,3.1,22.1,6.6,10,2.5,16.5,1.9,17.5,5.5h0Z"/>
    <path id="HWS72" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls3" d="M148.84,159.15c2.4-3,6.7-2,10.5-1.8,3.3.1,10.4-2.9,24.8-9,16.9-7.2,12.9-6.6,21.1-9.6,7.6-2.7,11.2-3.2,14.2-7.2,4.6-6,3.8-14.9,3.7-16.7-.7-8.8-4.9-11-3.7-17.3,1-5.2,4.6-8.2,6.1-9.5,5.1-4.3,12.4-5.7,18.7-2.5,5.3,2.7,7.8,7.7,8.4,9,3.5,7.3,2.1,14.9,1.6,17.9-1.1,6.4-3,6.8-3.2,11.4-.2,7.1,4.1,12.7,6.3,15.5,5.4,7,8.6,5.9,21.1,14.9,9.3,6.7,9,8.4,14.8,10.8,7.6,3.2,15.8,4.2,15.8,4.2,6.1.7,10.3.2,12.6,3.6.2.3,2.1,3,1.3,5.5-1.3,4.1-7.8,2-10.5,6.8-1.8,3.2-1.3,8.5.8,11.1.9,1.1,1.5,1,2.9,2.7.7.8,3.4,3.9,2.9,5.7-.7,2.4-7,2.2-14.2,1.8-10-.5-11.2-1.7-15.8-.6-5.2,1.3-8.8,4.2-11.1,6-2.5,2-6.2,4.9-9,10.2-3.9,7.3-2,11.9-5,13.9-3.1,2.1-5.9-2.3-14.5-4.9-7.9-2.5-9,.2-24.8-1.2-3.9-.4-6.2-.7-9.7,0-3.9.8-5.1,2.1-6.6,1.2-2.8-1.7-.6-6.9-4.2-12-2-2.7-3.3-2.2-5.1-5.4-1.9-3.4-1.3-5.8-2.7-6.5-1.9-.9-3.5,3.2-7.5,4-3.3.7-6.3-1.3-7.8-2.3-5.1-3.4-4.7-7.7-9-9-.2-.1-2.1-.1-6-.1-5.3-.1-5.7,0-6.1.1-4.2,1.3-4.7,5.6-6.9,5.4-2.6-.3-5.3-6.9-4.2-12.6.7-3.7,2.5-3.9,4.2-9.6,1.2-3.7,2-6.3,1.1-8.9-1.4-3.9-5.2-3.2-6.6-7.2-.9-2.6-.3-5.9,1.3-7.8h0Z"/>
    <path id="HWS71" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls3" d="M150.34,256.25c1.5-4.9,5.4-7.1,7-8,3.7-2.1,7-2.1,11.5-2.1,9.4,0,13,2.6,15.2-.1,1.3-1.5.3-2.7,1.4-9,.8-4.4,1.9-7.4,2.8-9.8,2-5.5,4.2-11.3,6.6-11.2.9.1,1.7.9,4.4,7.7,4.2,10.4,4.6,13.9,7.7,16.2,2.3,1.7,4.7,1.6,6.8,1.6,8.7-.1,17.5.1,26.2,0,16.9-.1,22.3,4.7,27.6-.6,2-2,3.3-4.8,5.5-9.2,2.6-5.4,2.8-7.8,5.6-12.8,2.6-4.7,5.5-8.2,6.3-7.7s-1.4,4.5.2,7.2c.9,1.5,2.3,1.2,4,2.1,4.1,2.1,5.8,8.6,6.1,12.5.6,7.9-4.3,11.9-2.3,14.9,1.1,1.7,2.9.7,9.1,2.1,4.9,1.1,7.6,1.7,9.8,4.2.4.4,3.9,4.5,3,10.1-.1.6-.8,4.6-3.8,6.9-2.1,1.6-3.9,1.1-9.6,1.1-6.4-.1-10.4-.1-14.5,1.3-3.4,1.2-5.7,2.9-7.5,4.2-6,4.5-6.4,7.9-13.1,14.6-3.1,3.2-4.8,4.8-7.5,6.4-3.8,2.2-7.2,2.8-18,2.9-10.1.1-15.2.2-22-.8-12.9-1.9-15.5-4.7-16.4-5.8-2.3-2.9-1.2-4-4.7-12.2-3-7-4.9-11.3-8.9-13.5-2.1-1.2-4.3-1.3-8.7-1.6-3.2-.2-4,.1-10.8.5-7.6.5-9.6.2-11.7-.3-3.1-.8-5.8-1.5-7.3-4.2-1.4-2.9-.3-6.6,0-7.6h0Z"/>
    <path id="HWS70" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls3" d="M182.34,318.55c2.7-2.9-.2-6.8,2.1-14.9,2.6-9.3,9.9-16.1,12.2-14.9,1.4.8-.1,3.9,1.4,9.9.2.7,1.7,6.7,5.6,11,1.8,2,4.5,4,14.1,6,7.6,1.6,16.7,3.4,29,1.3,7.6-1.3,11.5-1.9,15-4.7,9.4-7.3,9.4-20.4,13.8-20.3,3.3,0,6.1,7.3,7,10.9,2.3,8.7-1.1,13.5,1.9,16.7,1.5,1.6,5.1,1.5,12.2,1.3,8.6-.3,10.6-1.4,13.1.5,3.3,2.5,4.1,7.3,4.5,9.6.3,2.1,1.1,7.2-1.6,11.2-1.8,2.7-4.4,3.5-7.3,4.4-1.2.4-5.1,1.5-14.8.8-6.5-.5-9.3-1.4-10.5.5-1,1.6.2,3.2-.5,6.3-.5,2.4-1.8,3.8-5.6,7.3-6.1,5.7-9.2,8.5-11.2,9.4-2.9,1.2-4.9,1.1-10.3,1,0,0-7-.1-19.9,0-15.2.1-16.9,1.7-22-.8-3.6-1.7-6.4-4.3-10.3-7.8-4.2-3.8-6.3-5.8-7.7-9.1-2-4.8-.4-6.8-2.6-9.6-2.5-3.3-7.4-4.2-11-3.1-2.9.9-2.8,2.4-5.9,3.6-3.3,1.3-8.1,1.5-12.2-1.6-4-3-6.6-8.5-5.9-14.1,0-.3,1-6.8,6.1-10.1,8-4.9,16.9,4,21.3-.7h0Z"/>
    <path id="HWS69" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M279.84,390.75c0,10.2,1.5,18.5,3.3,18.5"/>
    <path id="HWS68" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M288.04,407.15c0-6.6-1-11.9-2.3-11.9"/>
    <path id="HWS67" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M179.14,389.15c0,8.1,1.2,14.6,2.8,14.6"/>
    <path id="HWS66" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M184.54,390.75v13"/>
    <path id="HWS65" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M244.24,410.65c-.9-1.5-2.3-3.5-4.3-6.8-3.3-5.2-4.1-6.7-5.4-6.6-1.7.1-1.6,3-4.9,6.9-2.9,3.4-4.5,2.9-5.6,5.5"/>
    <path id="HWS64" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M181.94,326.95c-2.9,6.4.3,12,4,12.2"/>
    <path id="HWS63" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M189.24,341.05c1.2-6.6-.5-14.3-3.3-16.2"/>
    <path id="HWS62" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M281.44,329.35c0,9.5,2.9,17.3,6.5,17.3"/>
    <path id="HWS61" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M291.24,334.45c0,6.8-1.5,12.2-3.3,12.2"/>
    <path id="HWS60" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M224.54,342.35c-20.6,19.5-10.2,31.1,4.7,19.8"/>
    <path id="HWS59" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M233.74,337.15c20.8,17.2,33.5,30.2,11.8,27.6"/>
    <path id="HWS58" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M226.44,272.75c-6-1.5-11.8,8.2-14.3,19.1"/>
    <path id="HWS57" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M235.14,285.55c0,7-1.2,12.8-2.7,12.8"/>
    <path id="HWS56" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M286.84,256.65c-3.4,4.4-1.2,11,0,11.8"/>
    <path id="HWS55" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M183.04,252.65c6.2,2.3,1.4,11.6,3,11.6"/>
    <path id="HWS54" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M189.94,193.25c0-9.1-1.6-16.5-3.6-16.5"/>
    <path id="HWS53" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M291.94,199.15c-3.2-7.5,0-12.3,4.3-11.7"/>
    <path id="HWS52" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M217.54,221.15c0-12.2,4.6-22.1,10.3-22.1"/>
    <path id="HWS51" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" d="M250.04,221.15c0-11.3-4.8-20.5-10.8-20.5"/>
    <line id="HWS49" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" x1="226.44" y1="193.25" x2="227.84" y2="199.15"/>
    <line id="HWS48" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls7" x1="240.74" y1="193.25" x2="239.34" y2="200.75"/>
    <path id="HWS47" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M117.54,107.65c-.4.1-2.9.6-4.6,3.2-1.8,2.8-1.2,5.9-1.1,7.2.1.4.9,5.2,4.6,7.6,1.8,1.1,3.2,1.1,6,1.2,9.3.4,8.5,1.4,14.4,1.2,6.6-.2,5.9-1.4,10.5-1.2,4.4.2,7.7,1.4,12.6,3.2,2,.7,4.8,2,10.2,4.4,5.9,2.6,8.8,3.9,11.2,5.2,7.3,3.7,8.3,5.2,12.3,7.2,4.2,2.1,7.2,2.5,19.7,3.6,13.9,1.2,20.8,1.8,27.4,2,13.9.4,20.9.7,26-.8,2.1-.6,6.2-2,12.3-2.8,5.3-.7,5.6-.1,9.5-.8,6.5-1.1,6.9-2.9,15.8-5.6,2.2-.7,7-2,13.4-2.8,3.4-.4,5.5-.6,8.4-.4,4.6.4,5.1,1.5,11.2,2.8,5.5,1.2,9.1,1.2,12.3,1.2,6.9,0,8.4-1.1,9.1-2,1.9-2,2.6-5.2,2.1-8-.9-5-5.3-6.9-6-7.2-2.7-1.2-4.4-.3-9.1.4-5.2.8-10.1,1.6-15.5.4-3.9-.8-7.4-1.6-11.2-4.4-1.8-1.3-3.3-2.8-5.6-2.8s-3.2,1.4-5.6,2.4c-2,.9-3.6.8-9.8,0-7.5-1-13-2-13-2-2.9-.5-5.6-1-12.6-2.4-10.4-2-10.6-2.1-12.6-2.4-3.6-.6-4.1-.5-13.7-1.6-12.8-1.4-12.6-1.6-15.1-1.6-3.9,0-9.1,0-15.4,2-6.3,2-7.5,3.9-13,4.4-2.9.2-5.1-.1-9.5-.8-4.9-.8-8.7-1.9-11.6-2.8-4.5-1.4-6.9-2.1-10.2-3.6-5.4-2.4-6.6-3.9-9.5-3.6-2.8.4-2.8,2-6.7,3.6-2,.8-5.1.9-11.2,1.2-4.7.2-7.1.3-9.5,0-2.3-.2-4.7-.5-7.7-1.6-5.1-1.7-6.2-3.7-9.2-3.2h0Z"/>
    <path id="HWS46" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M196.64,100.25c0,.8,42.7-43.6,91.3,1.4"/>
    <path id="HWS45" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M161.84,107.65c0-4.4-25.8-8-57.6-8"/>
    <path id="HWS44" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M313.54,119.65c0-5.1,13.2-9.2,29.5-9.2"/>
    <path id="HWS43" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M161.94,277.15c0,12.8-5.5,23.1-12.3,23.1"/>
    <path id="HWS42" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M141.04,298.25c0-11.7,6.6-21.1,14.8-21.1"/>
    <path id="HWS41" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M139.64,300.25c0-15.3,7.2-27.7,16.2-27.7"/>
    <path id="HWS40" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M320.94,295.45c0-12.6-5.9-22.9-13.2-22.9"/>
    <path id="HWS39" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M298.44,291.85c1.3,12.8,11.7,17.3,22.5,3.6"/>
    <path id="HWS38" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M298.44,284.05c0,6.3,10,11.4,22.5,11.4"/>
    <path id="HWS37" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M167.44,356.85c0,10.2-10.8,18.5-24.1,18.5"/>
    <path id="HWS36" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M143.34,367.65c0-15,5.4-27.2,12.1-27.2"/>
    <path id="HWS35" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M147.14,367.65c0-12.8,4.9-23.2,10.9-23.2"/>
    <path id="HWS34" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M318.04,378.85c0-14-7.1-25.4-15.8-25.4"/>
    <path id="HWS33" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M298.44,362.05c0,9.3,8.8,16.8,19.7,16.8"/>
    <path id="HWS32" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls8" d="M296.24,372.85c0,3.3,9.8,6,21.8,6"/>
    <path id="HWS31" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls1" d="M190.04,360.95c2.1-.2,3.4,3.6,10.9,15.1,3.1,4.7,4.3,6.3,6.5,7.7,3.6,2.3,7.1,2.2,11.4,2.3,15.7.5,14.7,3.2,24.4,2.3,7.6-.7,12.6-1.1,17.2-4.9,4.4-3.6,8.9-10.8,10.5-13.2,2.7-4.2,3.6-6.5,5.3-6.5,2.8,0,5.5,6.2,6.5,10,1.3,4.8.6,7.7,2.8,9.3,1.5,1.1,3.3.7,5.3.5,0,0,6.8-.8,17.4,1.4,5.6,1.2,10,2.1,13,6,3.3,4.3,3.5,10.1,1.9,14.4-.5,1.4-1.5,4-3.9,5.3-2.5,1.4-5,.7-8.8,0-1.9-.4-8.8-1.7-13-2.1-2.4-.2-10.3-.8-18.3,3.2-5.3,2.6-8.6,6.2-9.3,7-4.2,4.6-3.8,7-7.2,9.5-2.9,2.2-6.1,2.5-12.8,2.6-10.1.1-14.7-.5-20.7-.7-17.6-.5-21.3,3.7-27.4-.5-3.5-2.4-7-6.9-9.1-11.1s-1.7-6-4.2-8.8c-3.9-4.4-9.6-5.3-11.1-5.6-3.3-.5-6.1-.2-7.9.2-3.5.8-3.8,1.8-6.7,2.6-1.2.3-6.8,1.8-11.8-1.2-.9-.5-6.2-3.8-6-9.1.1-5.1,5.2-8.2,6.3-8.8,3.7-2.2,5.9-1.4,15.3-2.3,9-.9,13.6-1.4,15.8-4.2,1.9-2.5.7-4.1,2.8-10.9,1.2-4.7,2.7-9.3,4.9-9.5h0Z"/>
    <path id="HWS30" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls1" d="M184.94,424.85c5.1.1,5.3,11.8,14.4,17.2,3.5,2.1,6.5,2.1,13.7,2.3,36.1,1,40.9,2.4,45.3-2.3,1.2-1.3,5.4-6.3,9.1-13.2,1.8-3.4,3.1-6.5,5.3-6.6,1.2-.1,2.5,1.4,5.1,4.3,3,3.3,4.5,5,5.1,7.2.9,3.1-.2,4.7.9,5.6,1.3,1,3.2-.7,7.9-1.9,2.5-.6,5-.6,10-.7,4.3-.1,6.5-.1,7.7.5,4,1.9,6.5,7.3,5.6,12.1-.7,3.7-3.3,5.9-4.9,7.2-4.5,3.7-8.1,3.1-17.4,5.8-5.8,1.7-9.1,2.7-13,5.1-6.5,4.1-10.5,9.7-12.5,12.5-4.6,6.6-4.3,9.2-8.1,11.4-3.3,1.9-6.3,1.5-11.8,1.2,0,0-9-.5-22.5-.2-8,.2-16-.1-23.9.2-1.4.1-3.6.1-6-.9-4.4-1.9-7-6.5-8.1-8.6-2.1-3.8-1.6-5-3.5-6.3-2.8-1.9-5.2-.1-7.2-1.9-2.4-2.1-.1-5.5-2.1-8.4-2-3-6.8-3-13-3-10.7,0-13.3,2.7-17.2.5-4-2.3-5.4-7.6-5.3-11.1.1-5.8,3.9-11.6,8.6-12.8,1.3-.3,1.3.1,5.8.5,7.1.6,7.5-.3,12.8.4,5.1.7,6,1.7,7.7.8,2.5-1.4,1.6-4.5,3.9-9.1.1-.9,3.7-7.9,7.6-7.8h0Z"/>
    <path id="HWS29" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls1" d="M178.24,484.75c-3.5-.4-7.6,4.2-9.1,8.4-1.7,4.8.6,7.2-1.6,11.8-.4.8-2.4,4.9-6,5.8-3.3.9-4.9-1.5-10.2-3.2-1.9-.6-11-3.6-17,.9-.2.1-4.6,3.6-4.4,8.6.1,4.4,3.7,7.4,6.3,9.5,1.3,1,4.2,3.4,8.6,4.4,7.3,1.7,10.1-2.2,15.6-.2,2.5.9,4.3,3.2,8.1,7.9,4.2,5.2,6.9,8.5,8.8,13.7,1.2,3.1,1.6,5.8,4.2,7.4.3.2,2.6,1.6,5.1.9,1.7-.5,2.3-1.5,3.5-2.3,3.1-2,7.8,0,9.8.7,2.2.8,8.4.5,20.7,0,12.4-.5,23.2-1.9,28.8-2.6,12.3-1.5,14.9-2.4,19.3-1.4.8.2,2.4.6,4.2,0,2.9-1,3.9-4,6-9.3,3-7.2,4.4-10.8,6.3-13.2,1.5-2,4.3-5.6,9.1-7.4,3.1-1.2,3.7-.3,8.4-1.2,1.2-.2,7.1-1.4,13.2-5.3,4.5-2.9,7.7-4.9,8.4-8.6.9-4.8-2.9-8.9-3.5-9.5-3.7-4-8.9-4.5-12.3-4.9-5.6-.6-10.9.5-16.3,1.6-5.9,1.2-8.6,2.3-10.7.7-1.8-1.4-1-3.1-3.2-6.5-.4-.6-3.8-5.7-7.4-5.3-1.6.2-2.6,1.4-4.6,3.9-3.4,4.3-3.7,6.5-7.2,10.9-2.4,3-3.7,3.7-4.4,3.9-2.7,1.1-5.2.2-7-.2-.9-.2-5.5-.2-14.9,0-10.8.2-16.2.3-20.7.7-7.6.7-8.5,1.5-11.6.7-6.2-1.5-10.3-6-12.5-8.6-6.6-7-6.3-12.3-9.8-12.7h0Z"/>
    <path id="HWS28" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" d="M240.64,539.75c-2.5,28-2.4,2.3-11.1,7.7-5,3.1-8.1,20.3-13.3-7.9-1.7-9.1,5.9-16.7,13.1-16.7,7.3-.1,12.2,7.7,11.3,16.9h0Z"/>
    <path id="HWS27" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" d="M163.84,519.25c-1.4,5.7,0,12.2,4.9,12.2s8.5-8.2,12.8-12.2c4.6-4.3-1.6-17.3-8.8-10.6-6.2,5.6-8.4,8.3-8.9,10.6h0Z"/>
    <path id="HWS26" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" d="M285.94,513.45c0,7.1-1.7,12.9-3.7,12.9s-3.1-5.8-3.7-12.9c-.6-6.7-.8-11.2,3.7-12.9,1.9-.7,3.7,5.7,3.7,12.9Z"/>
    <ellipse id="HWS25" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" cx="177.64" cy="453.75" rx="3.8" ry="11.1"/>
    <path id="HWS24" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" d="M278.24,441.55c0,11.7,1.7,21.1,3.9,21.1"/>
    <path id="HWS23" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" d="M285.04,462.65c0-11.7-.7-21.1-1.6-21.1"/>
    <path id="HWS22" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" d="M280.14,387.65c0,10.5,1.5,19,3.2,19"/>
    <path id="HWS21" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" d="M288.24,401.35c0-7.6-1-13.7-2.3-13.7"/>
    <path id="HWS20" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" d="M180.24,386.75c0,8.7,1.2,15.8,2.7,15.8"/>
    <path id="HWS19" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" d="M185.64,387.65v14.9"/>
    <path id="HWS18" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" d="M233.94,395.35c-1.7.1-1.5,3-4.9,7-2.9,3.4-4.5,2.9-5.6,5.6-.2.6-.3.9-.5,7.7-.1,3.1-.1,4.7.5,4.9.9.3,1.8-2.4,4.4-3,1.2-.3,2.4.2,4.9,1.2,3.9,1.5,4.2,2.4,6.7,3,1.7.4,4.1,1,5.6-.2,1.9-1.6,1-5.5.5-7.7-.7-3.1-1.8-4.4-6.3-11.6-3.3-5.5-4.1-7-5.3-6.9Z"/>
    <path id="HWS17" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls4" d="M232.74,464.05c-.7-.1-1.2,1.3-3.5,5.6-2.4,4.6-2.7,4.8-3.2,6.5-.6,1.8-.7,3.2-.9,6-.3,2.8-.4,4.3.2,4.9,1.1,1.2,2.8-.2,6.7.5,2.6.5,3,1.3,4.4.9,2.6-.7,3.6-4.5,3.7-4.9.7-2.9-.3-5.5-1.2-7.4-1.7-3.9-3.2-3.9-4.6-7.9-.9-2.5-.9-4.1-1.6-4.2h0Z"/>
    <path id="HWS16" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M298.64,425.55c0,4.6,11.1,8.4,24.8,8.4"/>
    <path id="HWS15" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M307.34,416.45c0,9.6,7.2,17.4,16.1,17.4"/>
    <path id="HWS14" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M323.34,433.85c0-12.8-5.8-23.2-13-23.2"/>
    <path id="HWS13" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M163.24,414.95c-5.1,4-11.6,11.6-20.9,13"/>
    <path id="HWS12" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M156.74,410.65c.1,6.8-6.3,15.4-14.3,17.3"/>
    <path id="HWS11" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M156.74,480.55c-3,9-17.3,16.1-26.8,12.1"/>
    <path id="HWS10" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M152.84,476.35c-6.4,5.3-13.6,14.4-22.9,16.4"/>
    <path id="HWS9" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M298.64,480.55c2,1.6,13.5,4.4,19.5-1.4"/>
    <path id="HWS8" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M305.24,464.85c0,7.9,7.3,14.3,16.3,14.3"/>
    <path id="HWS7" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M319.34,474.65c0-8.3-5.4-15-12-15"/>
    <path id="HWS6" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M323.34,537.25c0-6.9-4.5-12.4-10.1-12.4"/>
    <polyline id="HWS5" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" points="148.04 213.75 139.04 226.95 139.04 242.25 148.04 253.75 148.04 273.15 137.64 295.55 138.24 311.95 144.74 327.95 138.24 345.15 138.24 358.65 142.84 370.25 142.34 381.65 133.14 397.15 133.14 408.65 138.24 421.45 135.54 433.85 134.54 442.65 138.24 452.65"/>
    <path id="HWS4" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M138.24,419.25c0-7,4.4-12.6,9.8-12.6"/>
    <polyline id="HWS3" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" points="319.54 217.65 323.34 225.85 328.24 235.55 327.44 247.15 323.34 261.05 320.94 271.75 323.04 284.45 325.74 299.05 324.14 314.75 320.94 326.15 316.84 338.65 320.94 351.15 325.44 368.05 324.14 377.45 323.34 392.75 328.24 412.95 328.24 431.55 323.34 445.75 323.34 464.05 328.24 474.65 326.44 484.75 324.14 499.35 331.74 513.35 333.84 526.35 333.84 537.25"/>
    <path id="HWS2" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" d="M143.64,463.85l-8.6,10.8-8.8,16v16.1l3.7,6.6-1.3,9.4-5.9,8.6s-5.6,11.8-5.6,13.2v4.2"/>
    <line id="HWS1" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls5" x1="138.24" y1="452.65" x2="138.24" y2="470.55"/>
  </g>
  <g id="Face" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls9">
    <path id="Face50" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M12.14,31.65c12.4-1.1,23.8-.5,26.4,15.2,3.4,10.6,1.2,22.6-3.3,35.2-7.4,21.2-1.9,43.9,13.4,67.7,24.1,22.8,37.7,46.6,39.2,71.4"/>
    <path id="Face49" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M23.34,5.75c6.4,13.1,14.8,24.5,29.7,30.4,9.4,3.8,19.8,4.4,29.8,2.6,36.6-6.7,58.9-17.2,49-36.4"/>
    <path id="Face48" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M70.44,7.05l3,49.8c2,13.2,7.6,22.2,19.2,24.3,11.6,8.8,20.5,20.4,24.3,37.4,10,18,23.5,23.5,40.1,17.6,30-7.1,50-15.9,39.8-29.8-15.7-11-21.6-24.6-19.8-40.4.4-31.4,1-62.8-4.6-63.5"/>
    <path id="Face47" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M.84,31.25c8.4,16.9,18.2,28.1,31,26.8,23.1-2.5,50-1.2,79,2.1,7.6,1.1,14.8,3.7,21.6,8.8,12.5,9.5,24.6,10.4,36.1,3.9,13.2-6.2,26.6-10.1,40.1-11.5,15.4-1.3,31.4,1.9,47.7,9,15,6.3,30.2,5.3,45.6-2.7,9.4-4.4,19.2-7.2,30.1-5.5,16.1-6.4,34.1-9.6,55-7.9,24.9,4.1,35.3-12.1,37.7-39.5"/>
    <path id="Face46" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M199.54.25c-5.3,13.7-9.6,27.7-10.3,42.9-2.1,14-.5,27.8,6.4,41,4.5,7.1,11.4,12,23.4,12.2,10.9,3.5,16.4-5.1,18.5-21.3,3.4-24.3,3.9-47.1,0-67.8"/>
    <path id="Face45" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M256.04,21.15l-9.5,45.5c-3.5,17.6.1,30.5,15.6,35.5,12.6,1,22.8-6.6,29.5-27,7.4-25.3,4.5-47-3.3-66.8"/>
    <path id="Face44" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M320.44,10.35c-6.6,10.1-11.2,19.9-10.9,29.5,1.5,14.2-1.7,28.1-6.4,41.9,9.6,17.8,13,36.2,11.2,55.3,6.7,8.9,18.1,9.5,32.5,4.9,10.3-6.1,21-17.9,31.9-31.9,30.6-20.7,45-38.4,42.8-53.2-2.3-10.2-3-24.4-3-40.1"/>
    <path id="Face43" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M323.54,2.45c-2.8,11-1.9,20.2,8.8,24.9,19.5,11.5,38,16.6,55.3,12.4,20.8-7.9,36.5-21,47.1-39.5"/>
    <path id="Face42" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M21.64,56.35c3.3,10.2,11.4,15.9,23.6,17.8,21.7,2.8,37.9,8.1,46.5,17,13.2,13.7,17.5,29.6,15.2,47.2-1.7,10.7-.1,18.4,7.3,20.2"/>
    <path id="Face41" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M464.14,79.05c-8.1,12.2-18.9,16.2-32.5,11.5-13.8-1.9-27.8,3.9-41.9,16.9-12.4,8-20.4,18.9-19.4,35.7-1.1,15.1-4.7,24.8-12.1,25.8"/>
    <path id="Face40" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M450.84,37.05c-10-3.8-17.3,1.6-23.4,11.6-3.6,9.9-3.7,21.1,0,34,6.9,9.9,9.7,22,7.3,36.8-.6,23.9-33,66.5-76.6,115.7"/>
    <path id="Face39" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M467.24,99.35c-11.7,21.9-18.3,45.9-16.4,73.2-1.8,28.2-7.1,49.3-16.1,62.6-.5,51.3-5.4,85.3-18.2,89l-12,10.9"/>
    <path id="Face38" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M435.84,120.35c4.6-3.2,8.3-3.6,9.5,3.7,0,15.1-6,33.2-14.3,52.6-19.8,53.1-30.7,95.1-28.6,121.2"/>
    <path id="Face37" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M38.54,151.95c5.8,23.2,7.7,46.2,5.5,69.3,3.6,17.1,7.8,36.4,12.2,57.1"/>
    <path id="Face36" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M406.44,310.15c-2.7,26.2-7.6,49.8-17.9,67.1l-26,38"/>
    <path id="Face35" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M16.34,268.85c3.2,18.2,9.4,34.3,24.3,44.7,16,40.5,33.9,74.9,54.4,100"/>
    <path id="Face34" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M366.64,290.75c-2.7-10-2.2-21.3,0-33.4.2-8.3-2-15.1-7.3-20-1.5-1.4-3.7-2-5.7-1.7l-8.9,1.4c-2.8-2.1-5.9-1.3-9.2,2.3-1.5,1.6-2.3,3.7-2.3,5.9v4.2c-1.9,3.4-2.8,7.1-2.1,11.2.7,9.3,5.6,18.9,15.5,28.6"/>
    <path id="Face33" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M333.24,249.45c4.1-1.1,7.9-.8,10.9,2.7,6.1-5.2,11.9-9.3,16.1-1.4"/>
    <path id="Face32" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M355.44,239.15c1.6,2.4,1.6,4.8,0,7.2"/>
    <path id="Face31" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M344.44,246.35c-2.6-2-4.9-2-6.7,0"/>
    <path id="Face30" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M348.74,327.25c4-24.9,8.3-50.1,0-61.4-8.4-6-16.8-6.9-25.2-2.7-8.4,1.8-11.5,8.5-10.6,18.8"/>
    <path id="Face29" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M318.34,268.85c9.1,3.5,16.7,2,22.8-4.6"/>
    <path id="Face28" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M341.14,323.25c2.4-20.9,2.8-37.7-3.3-40.1l-10-2.1c-1.3,6.4-6.7,10-17.6,9.7"/>
    <path id="Face27" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M312.74,285.95c-9.2-.3-12.8,3.6-10.8,11.9"/>
    <path id="Face26" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M322.14,343.35l-1.7-26.7c1.9-4.9,2.5-9.4,0-13.1-.1-5.7-2.2-7.9-7.7-4-4.8,4.4-10.7,2.5-16.8,0-4.9,2.3-2.9,9.5,2.1,18.5,4.8,9.4,4.2,21.1.9,34"/>
    <path id="Face25" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M272.14,362.75l3-27.6c-2.6-6-.3-11.1,7.9-15.2,3-7.9,5.8-9.3,8.5-4.9,2.7,3.7,5.3,5.9,7.6,5.7"/>
    <path id="Face24" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M255.14,365.15c1-12,.8-22-2.1-27.5-1.1-4.6-.3-8.9,3.3-12.8,6-1.5,11.8-2.8,14.2,0-1.6,14.9-2.4,28.8-1.5,40.3"/>
    <path id="Face23" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M245.34,366.45c1-7.9,3-15.8,5.9-23.7,1.5-8,.7-13.3-4.6-13.1-9.9-2.4-11.9,2.3-12.8,8,2.1,10.3,2.8,20.6,1.8,30.9"/>
    <path id="Face22" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M210.84,365.15c5.2-11.1,7.6-21.2,6.7-30.1-.3-7.9.6-13.5,6.4-10.3l10,2.4c4.6,1.5,1.9,8.7-2.1,17-2.2,8.3-4.3,16.1-6.4,23.1"/>
    <path id="Face21" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M193.84,368.55c-.2-24.3-.3-48.5,5.7-48.6h13.3c4.8,4.9,4.5,10,0,15.2-5.5,12.6-8.2,25-8.1,37.4"/>
    <path id="Face20" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M163.74,355.15c3.5-25.1,7.8-45.1,16.4-37.4,2.7,1.8,4.4,1.8,4.2-1.5,1.7-.5,3.7-.7,6.4,0,4.8,6.3,3.1,15.3,0,24.9l-5.5,26.1"/>
    <path id="Face19" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M138.84,354.85l14-13.7c16.1-16.3,21.1-28.2,14.3-35.5-1.6-8.2-6.5-9.9-12.1-10-6-2.7-11.4-3.4-14,4.6-8.4,13.4-14.8,28.1-19.4,44"/>
    <path id="Face18" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M167.64,310.15c-4.9-3.4-8.5-3-11.9-1.8-2.5-5-4.6-3.7-6.4,0-3.6-2.2-5.4-4.9-6.7-7.9"/>
    <path id="Face17" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M154.54,292.15c-.6-6.5-3.5-12.3-8.8-17.2-3.3-2.9-7.8-3.6-14.9,0-17.2,4.3-25.2,26-28.9,55.6-.2,1.6,1.9,2.5,2.9,1.2,4.8-6,9.3-10.3,12.6-8.5,3.8,1.7,4.2,8.9,2.9,19-.1.9,1.1,1.4,1.6.7l31.6-39.5c1.1-3.9,1.4-7.7,1-11.3Z"/>
    <path id="Face16" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M134.94,278.25c3.3-2,5.9,0,7.8,6.1,2.1-1.7,4.3-1.5,6.7,0"/>
    <path id="Face15" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M103.54,307.95c24.2-12.5,42.3-25.9,39.2-42.1-4.3-.8-5.7-3.5-3.9-8.2-3.4-4.6-8.3-7.4-14.6-8.4"/>
    <path id="Face14" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M123.34,258.85c5,1.9,8.8,4.4,7.6,8.7,5,.7,7.7,1.8,8.2,3.2"/>
    <path id="Face13" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M87.74,296.55c4.6-6,8.8-13.9,12.5-25.8,3.5-11.8,9.4-19.6,19.1-21.5,5.9-2.3,6.3-7.4,0-15.9-14.6-13.5-25.1-16.2-31.6-8.2l-13.3,35.7"/>
    <path id="Face12" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M355.44,151.95c4,17.9,2.8,34.8-3.3,50.7-5.2,6-10.8,3.7-16.4,0-10.9,4-14.5-.3-15.5-7.6l-1.8-27.9"/>
    <path id="Face11" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M342.74,196.55c-2,15.6-6.1,24.8-16.3,14.6-16.5,12-15.6-7.6-12-32"/>
    <path id="Face10" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M329.74,188.05c2.9,19.2,4.4,36.1-6.2,33.1-4.5,3.2-7.8-.3-10.8-5.7-14,4.4-7.6-14.3-5.1-28.6"/>
    <path id="Face9" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M310.14,184.75c2.4,14.8,3.8,28.1,1.8,36.9-.9,4.1-5.6,6.1-9.2,4.1-13.9-7.8-15.9-21.3-9.6-38.8"/>
    <path id="Face8" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M286.14,169.35c4.2,3.8,5.4,11.9,5.8,21.2,4.2,16.4,3.8,27.9-4.8,30.2-10.2,3.3-15-2-14.9-15.1,1.7-7.7,1.5-13.7,0-18.8-.9-7.3-.7-12.7.5-16.2.9-2.6,4.6-2.6,5.6,0,1.5,4,2.4,8.7,3,13.8.1.5.8.5.8,0,.2-6.6,1-12.1,3.1-15,.2-.3.7-.3.9-.1h0Z"/>
    <path id="Face7" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M252.54,225.15c-8.6,2.2-9.6-6.3-7.3-19.5l3.6-20.9c-2.1-9.1-.9-17.1,2.7-24.3.3-.6,1.2-.5,1.4.2l3.5,16.7c.2,1,1.6.9,1.7-.1l1.6-16.2c.2-2,2.9-2.5,3.7-.6,2.5,5.8,3.3,12.1,2,18.8.6,8.4,2,14.8,4.5,18.7,1.2,1.9,1.8,4.2,1.8,6.5.1,6.8-.7,12.7-3.4,17.1-1.4,2.3-4,3.7-6.7,3.7l-9.1-.1h0Z"/>
    <path id="Face6" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M236.54,163.25c1.6,6,2.8,16.2,3.7,29.1,3.3,16,5.5,30.3-2.6,29.3h-17.2c-4.8-1.4-5.6-8.5-4.2-18.9,4.6-9.2,7.3-22.2,7.3-40.3,0-.5.7-.6.8-.1l5.8,21.3c.3,1.1,1.8.9,1.9-.1l2.5-20c.2-1.3,1.7-1.4,2-.3h0Z"/>
    <path id="Face5" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M206.84,220.65c-10.3,1-12.1-2.7-12.1-14.3,0-3.4.8-6.9,2.2-10,3.1-7.3,3.8-14.8,2.9-24.6,0-.3.3-.3.4-.1l3.9,14.9c.3,1,1.7,1.1,2,.1l5.2-15.5c.3-.8,1.5-.6,1.5.2v21.1c6.1,19.8,2.7,27.5-6,28.2h0Z"/>
    <path id="Face4" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M184.04,174.05c6.8-13.9,9.6-5,9.7,18.2-.1,13.9-2.5,23.3-9.7,24-8.3,7-13.7,7-14-5.5-3.4-35.3-.9-42.1,5.2-30.4"/>
    <path id="Face3" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M177.34,173.75c3.1,22.7,3.2,39-4.9,38.3l-8.8-2.1c-2,3.4-4.1,6-6.5,7-3.8,1.6-7.9-1.5-7.5-5.6l2.4-28.7"/>
    <path id="Face2" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M163.74,178.05c1.6,23-2.4,33.7-10.1,35.9-1.7.5-3.4.1-4.9-.9-1.7-1.1-3.3-1.3-4.9-.3-1.9,1.2-4.3,1.8-6.4.9-9.1-3.7-5.1-21.1.1-39.8"/>
    <path id="Face1" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls6" d="M149.44,168.95c-1.8,24.6-6.9,35-15.3,31.3-9,3.9-16,2.6-20-5.9-5.5-13.2-3.6-27.5,1.2-42.4"/>
  </g>
</g>
<g id="Overlays">
  <path id="Unterkiefer" class="UK1603Fill" style= {{
            fill: props.colors.UK1603Fill,
            stroke: props.colors.UK1603Fill,
            opacity:props.colors.UK1603Fill,
          }} d="M337.57,220.01c-36.66-.47-73.32-.91-109.98-1.25-71.16-.66-142.32-.96-213.47-.52l2.67,52.95c2.1,17.8,9.5,31.9,21.8,42.6,1.7,1.5,2.9,3.3,3.7,5.4,12,32,28.3,62.2,48.8,90.7,2.7,3.8,7.1,5.8,11.7,5.5,89.6-6.3,174.6-6.8,252.8,1.5,4.6.4,9.2-1.4,12-5.1,16.9-22.1,29.8-44.8,34.8-68.9.6-2.8,2.1-5.4,4.3-7.3l9.5-8.4c.5-.4,1-.8,1.6-1.1,13-8,17.2-40.9,17.4-85,0-2.3.6-4.5,1.7-6.5,2.22-3.97,4.13-8.16,5.77-12.54-35-1.58-70.06-1.59-105.09-2.04Z"/>
  <g id="HWK">
    <path id="HWK8" class="HWK1603Fill" style= {{
            fill: props.colors.HWK1603Fill,
            stroke: props.colors.HWK1603Fill,
            opacity:props.colors.HWK1603Fill,
          }} d="M327.9,113.8c.6,2.2-1.6,3.6-3.8,9.7,0,0-1.5,4.2-2.5,11.2-2.1,15.3,4.7,28.7,2.2,30.2-1.8,1.2-5.2-6.5-14.9-12.3-2-1.2-2.6-1.3-15.3-6-14.4-5.3-15.4-5.6-17.4-7.2-4.7-3.8-12.1-12.5-10.5-21.5,1.7-10.2,14-15.8,22.6-16.2,7.1-.3,7.4,3.1,22.1,6.6,10,2.5,16.5,1.9,17.5,5.5h0Z"/>
    <path id="HWK7" class="HWK1603Fill" style= {{
            fill: props.colors.HWK1603Fill,
            stroke: props.colors.HWK1603Fill,
            opacity:props.colors.HWK1603Fill,
          }} d="M147.7,151.5c-2.5-2.2,3.4-9.7,4.7-26.9.9-11.1-1.1-15.2,2.1-19.7,3.1-4.4,8-5.2,16.9-6.6,10.7-1.7,18.4.2,20,.6,6.8,1.8,11.4,2.9,14.8,7.8,3.1,4.4,4.7,11.3,2.6,17.3-.5,1.4-2.3,6-6.3,7.8-2.9,1.3-5.6.4-7.4,0-5-1.1-9.7.6-19,4.2-17.8,6.7-25.5,18.1-28.4,15.5h0Z"/>
    <path id="HWK6" class="HWK1603Fill" style= {{
            fill: props.colors.HWK1603Fill,
            stroke: props.colors.HWK1603Fill,
            opacity:props.colors.HWK1603Fill,
          }} d="M288.27,164.24c-.02-.7.05-1.35.17-1.95-2.16-1.68-4.21-3.91-10.05-8.12-12.5-9-15.7-7.9-21.1-14.9-2.2-2.8-6.5-8.4-6.3-15.5.2-4.6,2.1-5,3.2-11.4.5-3,1.9-10.6-1.6-17.9-.6-1.3-3.1-6.3-8.4-9-6.3-3.2-13.6-1.8-18.7,2.5-1.5,1.3-5.1,4.3-6.1,9.5-1.2,6.3,3,8.5,3.7,17.3.1,1.8.9,10.7-3.7,16.7-3,4-6.6,4.5-14.2,7.2-8.2,3-4.2,2.4-21.1,9.6-.43.18-.84.36-1.26.53.1.43.18.86.22,1.31,2.1,20.12,6.73,39.92,7.92,60.11,2.48-1.43,3.81-3.87,5.32-3.16,1.4.7.8,3.1,2.7,6.5,1.8,3.2,3.1,2.7,5.1,5.4,3.6,5.1,1.4,10.3,4.2,12,1.5.9,2.7-.4,6.6-1.2,3.5-.7,5.8-.4,9.7,0,15.8,1.4,16.9-1.3,24.8,1.2,8.6,2.6,11.4,7,14.5,4.9,3-2,1.1-6.6,5-13.9,2.8-5.3,6.5-8.2,9-10.2,1.91-1.49,4.73-3.73,8.59-5.2-.02-.28-.04-.57-.04-.87.1-13.85,2.16-27.61,1.82-41.47Z"/>
    <path id="HWK5" class="HWK1603Fill" style= {{
            fill: props.colors.HWK1603Fill,
            stroke: props.colors.HWK1603Fill,
            opacity:props.colors.HWK1603Fill,
          }} d="M295.19,232.97c-.3-3.9-2-10.4-6.1-12.5-1.7-.9-3.1-.6-4-2.1-1.6-2.7.6-6.7-.2-7.2s-3.7,3-6.3,7.7c-2.8,5-3,7.4-5.6,12.8-2.2,4.4-3.5,7.2-5.5,9.2-5.3,5.3-10.7.5-27.6.6-8.7.1-17.5-.1-26.2,0-2.1,0-4.5.1-6.8-1.6-3.1-2.3-3.5-5.8-7.7-16.2-2.7-6.8-3.5-7.6-4.4-7.7-1.27-.05-2.48,1.55-3.64,3.88-.21,1.45-.77,2.65-1.57,3.6-.48,1.21-.94,2.47-1.39,3.72-.9,2.4-2,5.4-2.8,9.8-1.1,6.3-.1,7.5-1.4,9-.17.21-.35.37-.54.52,3.15,7.48,5.21,15.36,7.52,23.16.16.56.26,1.09.32,1.61,2.45,2.46,4.1,6.24,6.4,11.6,3.5,8.2,2.4,9.3,4.7,12.2.9,1.1,3.5,3.9,16.4,5.8,6.8,1,11.9.9,22,.8,10.8-.1,14.2-.7,18-2.9,2.7-1.6,4.4-3.2,7.5-6.4,6.7-6.7,7.1-10.1,13.1-14.6,1.36-.98,3.01-2.19,5.2-3.24,3.53-8.88,5.59-18.36,7.61-27.66.06-.27.13-.53.21-.78.1-2.83,3.27-6.68,2.78-13.12Z"/>
    <path id="HWK4" class="HWK1603Fill" style= {{
            fill: props.colors.HWK1603Fill,
            stroke: props.colors.HWK1603Fill,
            opacity:props.colors.HWK1603Fill,
          }} d="M282.49,302.77c-.9-3.6-3.7-10.9-7-10.9-4.4-.1-4.4,13-13.8,20.3-3.5,2.8-7.4,3.4-15,4.7-12.3,2.1-21.4.3-29-1.3-9.6-2-12.3-4-14.1-6-3.9-4.3-5.4-10.3-5.6-11-1.5-6,0-9.1-1.4-9.9-2.3-1.2-9.6,5.6-12.2,14.9-2,7.03-.08,10.9-1.3,13.69.64,8.19,1.87,16.32,3.05,24.45,1.46.58,2.79,1.49,3.75,2.76,2.2,2.8.6,4.8,2.6,9.6,1.4,3.3,3.5,5.3,7.7,9.1,3.9,3.5,6.7,6.1,10.3,7.8,5.1,2.5,6.8.9,22,.8,12.9-.1,19.9,0,19.9,0,5.4.1,7.4.2,10.3-1,2-.9,5.1-3.7,11.2-9.4,3.8-3.5,5.1-4.9,5.6-7.3.7-3.1-.5-4.7.5-6.3.28-.45.66-.74,1.15-.91.39-9.31,1.48-18.58,2.93-27.8-2.39-3.2.63-7.95-1.58-16.29Z"/>
    <path id="HWK3" class="HWK1603Fill" style= {{
            fill: props.colors.HWK1603Fill,
            stroke: props.colors.HWK1603Fill,
            opacity:props.colors.HWK1603Fill,
          }} d="M285.49,382.97c-2.2-1.6-1.5-4.5-2.8-9.3-1-3.8-3.7-10-6.5-10-1.7,0-2.6,2.3-5.3,6.5-1.6,2.4-6.1,9.6-10.5,13.2-4.6,3.8-9.6,4.2-17.2,4.9-9.7.9-8.7-1.8-24.4-2.3-4.3-.1-7.8,0-11.4-2.3-2.2-1.4-3.4-3-6.5-7.7-7.5-11.5-8.8-15.3-10.9-15.1-2.2.2-3.7,4.8-4.9,9.5-2,6.49-1.01,8.24-2.56,10.56.04.36.07.74.07,1.13-.02,7.8-.23,15.6-.36,23.39,2.03.82,4.29,2.12,6.15,4.22,2.5,2.8,2,4.4,4.2,8.8,2.1,4.2,5.6,8.7,9.1,11.1,6.1,4.2,9.8,0,27.4.5,6,.2,10.6.8,20.7.7,6.7-.1,9.9-.4,12.8-2.6,3.4-2.5,3-4.9,7.2-9.5.7-.8,4-4.4,9.3-7,1.62-.81,3.22-1.42,4.79-1.89,1.4-8.76,1.7-17.66,2.1-26.51-.17-.08-.33-.18-.49-.29Z"/>
    <path id="HWK2" class="HWK1603Fill" style= {{
            fill: props.colors.HWK1603Fill,
            stroke: props.colors.HWK1603Fill,
            opacity:props.colors.HWK1603Fill,
          }} d="M283.79,439.27c-1.1-.9,0-2.5-.9-5.6-.6-2.2-2.1-3.9-5.1-7.2-2.6-2.9-3.9-4.4-5.1-4.3-2.2.1-3.5,3.2-5.3,6.6-3.7,6.9-7.9,11.9-9.1,13.2-4.4,4.7-9.2,3.3-45.3,2.3-7.2-.2-10.2-.2-13.7-2.3-9.1-5.4-9.3-17.1-14.4-17.2-3.9-.1-7.5,6.9-7.6,7.8-2.3,4.6-1.4,7.7-3.9,9.1-.12.07-.24.12-.36.16v23.48c.37.3.69.65.96,1.05,2,2.9-.3,6.3,2.1,8.4,2,1.8,4.4,0,7.2,1.9,1.9,1.3,1.4,2.5,3.5,6.3,1.1,2.1,3.7,6.7,8.1,8.6,2.4,1,4.6,1,6,.9,7.9-.3,15.9,0,23.9-.2,13.5-.3,22.5.2,22.5.2,5.5.3,8.5.7,11.8-1.2,3.8-2.2,3.5-4.8,8.1-11.4,2-2.8,6-8.4,12.5-12.5.71-.44,1.4-.83,2.09-1.19,1.28-8.84,2.45-17.68,2.75-26.62-.27-.03-.52-.12-.75-.29Z"/>
    <path id="HWK1" class="HWK1603Fill" style= {{
            fill: props.colors.HWK1603Fill,
            stroke: props.colors.HWK1603Fill,
            opacity:props.colors.HWK1603Fill,
          }} d="M282.17,499.48c-.11-.45-.18-.88-.22-1.31-.16-.09-.31-.18-.46-.3-1.8-1.4-1-3.1-3.2-6.5-.4-.6-3.8-5.7-7.4-5.3-1.6.2-2.6,1.4-4.6,3.9-3.4,4.3-3.7,6.5-7.2,10.9-2.4,3-3.7,3.7-4.4,3.9-2.7,1.1-5.2.2-7-.2-.9-.2-5.5-.2-14.9,0-10.8.2-16.2.3-20.7.7-7.6.7-8.5,1.5-11.6.7-6.2-1.5-10.3-6-12.5-8.6-6.6-7-6.3-12.3-9.8-12.7-3.5-.4-7.6,4.2-9.1,8.4-1.7,4.8.6,7.2-1.6,11.8-.34.68-1.85,3.77-4.53,5.22-.06,7.32-.16,14.64-.27,21.95,1.57,1.29,3.23,3.35,5.8,6.53,4.2,5.2,6.9,8.5,8.8,13.7,1.2,3.1,1.6,5.8,4.2,7.4.3.2,2.6,1.6,5.1.9,1.7-.5,2.3-1.5,3.5-2.3,3.1-2,7.8,0,9.8.7,2.2.8,8.4.5,20.7,0,12.4-.5,23.2-1.9,28.8-2.6,12.3-1.5,14.9-2.4,19.3-1.4.8.2,2.4.6,4.2,0,2.9-1,3.9-4,6-9.3,3-7.2,4.4-10.8,6.3-13.2.55-.73,1.27-1.68,2.2-2.67-1.53-10.14-2.69-20.36-5.22-30.32Z"/>
  </g>
  <path id="Dens" class="Dens1603Fill" style= {{
            fill: props.colors.Dens1603Fill,
            stroke: props.colors.Dens1603Fill,
            opacity:props.colors.Dens1603Fill,
          }} d="M218.8,132.25c10.86,1.15,24.11-.59,35.01.21-1.24-2.56-2.17-5.6-2.07-8.99.2-4.6,2.1-5,3.2-11.4.5-3,1.9-10.6-1.6-17.9-.6-1.3-3.1-6.3-8.4-9-6.3-3.2-13.6-1.8-18.7,2.5-1.5,1.3-5.1,4.3-6.1,9.5-1.2,6.3,3,8.5,3.7,17.3.09,1.65-1.61,11.9-5.04,17.78Z"/>
  <g id="Processus_spinosi" data-name="Processus spinosi" class="hws_mit_bewegten_unterkiefer_HWS_im_Vordergrund1603_cls16">
    <path id="Processus_spinosi6" data-name="Processus spinosi6" class="Processus_spinosi1603Fill" style= {{
            fill: props.colors.Processus_spinosi1603Fill,
            stroke: props.colors.Processus_spinosi1603Fill,
            opacity:props.colors.Processus_spinosi1603Fill,
          }} d="M234.63,285.54c0-7.68-7.14-16.66-12.36-12.5-2.35,1.87-3.84,3.37-5.51,5.81-.16.85-.49,1.64-1.01,2.27-.13.46-.31.89-.56,1.28-.17.81-.49,1.58-1,2.19-.1.79-.33,1.55-.72,2.2-.07.35-.17.7-.3,1.02,0,.87-.19,1.73-.55,2.48,1.86,4.51,8.84,8.13,13.55,8,8.89-.25,8.46-5.07,8.46-12.75Z"/>
    <path id="Processus_spinosi5" data-name="Processus spinosi5" class="Processus_spinosi1603Fill" style= {{
            fill: props.colors.Processus_spinosi1603Fill,
            stroke: props.colors.Processus_spinosi1603Fill,
            opacity:props.colors.Processus_spinosi1603Fill,
          }} d="M257.02,359.66c-.24-.3-.46-.64-.65-1.05-2.11-4.35-5.31-7.25-8.43-10.53-1.39-.25-2.37-1.14-2.96-2.29-2.02-.23-3.25-1.75-3.69-3.56-.45-.32-.84-.72-1.14-1.19-1.11-.24-1.96-.9-2.53-1.77-.8-.33-3.27-2.06-3.72-2.77-3.82,1.71-5.85,4.43-9.76,5.84-.47.46-1.05.81-1.75,1-.3.45-.62.9-.98,1.34-.43.53-.88,1.04-1.3,1.57-.2.26-.64,1.14-.16.18-.17.34-.38.66-.54,1.01-.46.97-1.19,1.61-2.01,2-.27.52-.62.99-1.05,1.37-.1.12-.19.26-.3.37-1.49,3.31-2.42,6.44-2.42,8.74,0,9.53,8.36,4.42,20.57,4.42,2.14,0,4.22.18,6.21.43.3-.06.62-.1.95-.1.47,0,.91.07,1.31.2.38-.11.78-.2,1.24-.2.48,0,.91.07,1.31.2.64-.45,1.44-.72,2.38-.72s1.7.25,2.34.68c.33.09.64.23.93.39.47-.19,1-.29,1.59-.29.12,0,.23.01.34.02.31-.12.63-.21.99-.25.68-.88,1.67-1.48,2.97-1.57.21-.67.32-1.46.32-2.42,0-.36-.02-.7-.05-1.04Z"/>
    <path id="Processus_spinosi4" data-name="Processus spinosi4" class="Processus_spinosi1603Fill" style= {{
            fill: props.colors.Processus_spinosi1603Fill,
            stroke: props.colors.Processus_spinosi1603Fill,
            opacity:props.colors.Processus_spinosi1603Fill,
          }} d="M248.47,212.63c-.42-2.97-.92-6.54-3.25-9.32-2.62-3.12-7.16-2.64-6.07-5.15.82-1.89,1.08-3.18.69-4.95-4.36-.56-8.73-.47-13.1-.44.14.66.17,1.35.09,2.03.52,1.2,1.28,2.18.91,3.08-.29.7-1.01.74-1.95.9-.43,1.04-1.17,1.89-2.22,2.31-.32.73-.79,1.37-1.43,1.82-.18.73-.5,1.41-.95,1.97-.1.86-.38,1.69-.84,2.37-.08,1.1-.43,2.17-1.07,2.98-.04.6-.17,1.18-.38,1.72.31,1.24.22,2.63-.27,3.8.05.88-.07,1.78-.38,2.58,2.14,5.45,11.19,5.54,15.72,5.59,4.64.05,12.51.12,14.53-4.66.7-1.66.46-3.35,0-6.62Z"/>
    <path id="Processus_spinosi3" data-name="Processus spinosi3" class="Processus_spinosi1603Fill" style= {{
            fill: props.colors.Processus_spinosi1603Fill,
            stroke: props.colors.Processus_spinosi1603Fill,
            opacity:props.colors.Processus_spinosi1603Fill,
          }} d="M233.59,393.89c-1.56.15-1.44,3.2-4.55,7.35-2.73,3.64-4.16,3.07-5.2,5.88-.23.62-.26.91-.44,8.09-.08,3.28-.11,4.91.44,5.15.85.36,1.7-2.56,4.12-3.19,1.13-.29,2.28.21,4.55,1.23,3.61,1.62,3.93,2.54,6.29,3.19,1.56.42,3.85,1.05,5.2-.24,1.78-1.7.92-5.8.44-8.09-.7-3.31-1.67-4.65-5.85-12.26-3.09-5.63-3.84-7.22-4.99-7.11Z"/>
    <path id="Processus_spinosi2" data-name="Processus spinosi2" class="Processus_spinosi1603Fill" style= {{
            fill: props.colors.Processus_spinosi1603Fill,
            stroke: props.colors.Processus_spinosi1603Fill,
            opacity:props.colors.Processus_spinosi1603Fill,
          }} d="M232.5,462.91c-.66-.1-1.16,1.38-3.25,5.88-2.26,4.87-2.54,5.04-3.03,6.87-.51,1.9-.63,3.39-.87,6.38-.24,2.99-.34,4.49.21,5.15,1.06,1.24,2.64-.23,6.29.49,2.46.48,2.83,1.37,4.12.98,2.46-.76,3.38-4.73,3.47-5.15.68-3.1-.31-5.77-1.08-7.84-1.54-4.15-2.95-4.09-4.34-8.34-.86-2.66-.84-4.31-1.52-4.41h.01Z"/>
    <path id="Processus_spinosi1" data-name="Processus spinosi1" class="Processus_spinosi1603Fill" style= {{
            fill: props.colors.Processus_spinosi1603Fill,
            stroke: props.colors.Processus_spinosi1603Fill,
            opacity:props.colors.Processus_spinosi1603Fill,
          }} d="M240,539.41c-2.34,29.52-2.23,2.46-10.41,8.09-4.67,3.22-7.61,21.46-12.4-8.38-1.54-9.6,5.49-17.65,12.25-17.65s11.33,8.23,10.56,17.94h0Z"/>
  </g>
  <g id="Transition">
    <path id="transition2" class="HWS_BWS1603Fill" style= {{
            fill: props.colors.HWS_BWS1603Fill,
            stroke: props.colors.HWS_BWS1603Fill,
            opacity:props.colors.HWS_BWS1603Fill,
          }} d="M356.42,574.26c0,5.03-56.87,5.47-127.59,5.47s-128.51-.43-128.51-5.47,51.41-14.58,122.13-14.58,133.97,9.55,133.97,14.58Z"/>
    <path id="transition1" class="HWS_Skull1603Fill" style= {{
            fill: props.colors.HWS_Skull1603Fill,
            stroke: props.colors.HWS_Skull1603Fill,
            opacity:props.colors.HWS_Skull1603Fill,
          }} d="M366.44,78.11c0,5.03-56.87,5.47-127.59,5.47s-128.51-.43-128.51-5.47,51.41-14.58,122.13-14.58,133.97,9.55,133.97,14.58Z"/>
  </g>
</g>
</svg>
  );
}

export default HalswirbelsauleMitBewegtemUnterkieferVdStehend1603;
