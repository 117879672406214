import Controller from "../Common/Controller";

import FrontView from "./views/FrontView";
import SideView from "./views/SideView";
import BackView from "./views/BackView";

import "./styles/Controller.scss";

function LegLeftController() {
  const availableViews = [FrontView, SideView]; //back view disabled because it doesnt bring any additional functionality
  return <Controller availableViews={availableViews} />;
}

export default LegLeftController;
