import React from "react";

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  addNewPrice,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const onChange = (e) => {
    if (!isNaN(e.target.value)) {
      setValue(e.target.value);
    }
  };
  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    addNewPrice(
      index,
      id,
      e.target.value,
      e.target.parentElement.parentElement.id
    );
    setValue(e.target.value);
  };
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return (
    <React.StrictMode>
      <input
        className="w-100"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </React.StrictMode>
  );
};

export default EditableCell;
