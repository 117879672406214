import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const Skull = map.Skull;

function SideView({ selected, bpartSetter }) {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 520.59 675.58"
    >
      <g id="Ebene_2">
        <g id="outlines">
          <path
            id="outlines_1"
            class="shead-cls-2"
            d="M136.11,90.19c24.43-12.98,48.99-22.32,73.69-28.12,40.35-12.32,79.77-12.98,118.12-.56,29.79,3.8,55.56,13.67,77.91,28.69l25.31,16.03c36.44,34.33,65.18,73.36,73.97,124.59,13.63,41.57,20.55,82.08,7.88,119.53-2.38,13.99-12.12,28.35-24.19,42.84-16.07,29.29-37.7,39-60.75,43.69-5.02,5.48-10.12,9.91-15.37,12.56l-23.81,1.5c-9.69,6.08-19.13,3.58-28.5-1.5-5.56,10.31-16.41,12.26-28.31,12.56-11.69,12.61-20.6,12.2-26.25-3.56l-4.31-20.06c-4.22,11.68-9.28,21.43-16.12,27-6.82,7.55-13.26,14.69-19.12,21.19-.59-8.92,1.19-16.34,7.31-21,5.28-4.67,8.81-10.64,10.69-17.81,2.47-7.57-.07-10.73-4.12-12.56-5.42-2.18-9.5-8.61-13.31-15.94-11.17,26.29-22.01,50.87-30.75,64.12-8.69,15.68-12.88,34.68-14.06,55.88-3.11,30.61-14.63,44.94-32.25,47.44-23.84,.39-46.86,7.05-69.19,18.94-22.85,13.07-52.07,19.57-84.19,23.06-24.95-4.39-26.83-19.4-16.69-39.94,6.31-10,7-21.76,2.06-35.25-5.89-9.99-8.47-11.75-3.94-14.62-2.76-4.85-3.58-11.44-3.38-18.94-3.69,3.3-6.21,.79-7.88-6-2.37-7.45-2.13-15.09,0-22.88-2.15-4.71,.65-12.37,4.12-20.44,1.6-10.72-1.71-17.38-6.19-23.06l-3.56-3.75c13.07-4.75,24.69-10.01,30.75-17.25,1.96-11.12,.04-21.24-4.31-30.75-1.6-2.33-4.04-4.42-6.94-6.38-5.27-.58-5.62-3.28-3.75-6.94,1.09-4.07,1.05-8.25-.38-12.56-5.69-6.44-10.09-13.08-13.5-19.88-3.99-5.27-2.1-10.25,4.12-15,14.99-6.41,23.18-19.28,28.5-34.88l3-4.88,1.88-3.38c-10.43-20.49-5.38-42.23,3.75-64.31,1.34-20.37,5.73-37.95,14.44-51.56,21.03-34.44,46.93-62.78,77.62-85.88v.02Z"
          />
        </g>
        <g id="skull">
          <path
            id="skull_3"
            class="shead-cls-2"
            d="M215.71,60.34c-5.63,3.08-10.33,6.98-14.35,11.47,4.81-1.79,8.81-2.31,11.62-.94-3.05,2.31-5.98,4.16-8.62,4.88-2.31,3.77-3.1,6.59-1.31,8.06l7.31-3.56c-5.59,3.07-10.83,6.03-13.88,8.25-1.71,2.59-1.79,4.68,0,6.19,4.54-1.13,8.28-.74,11.25,1.12-1.32,3.72-4.28,6.05-8.25,7.5,1.05,2.06,3.06,3.39,6.61,3.56-1.56,4.62-8.8,8.76-15.61,12.94l10.88,1.12c-3.34,4.84-10.28,8.12-18,11.06l6.94,2.62c-3.64,3.34-8.62,5.54-16.5,5.25-4.64,.46-5.73,4.37-3.94,11.06,3.28-.46,4.53,1.16,4.12,4.5-6.38,1.77-9.69,6.95-11.62,13.69,3.92,.35,6.21,1.94,6.75,4.88,.71,3.81,.42,8.01-2.44,10.5l-1.5,16.12c-2.45,4.95-5.03,9.39-8.62,12.56-2.23,5.7-1.67,11.25,1.31,16.69,1.6,3.9,1.73,8.8,1.31,14.06,16.99-1.33,32.92-2.18,42,0,3.29-4.15,6.6-7.67,9.94-9.19,1.89,1.16,3.5,2.14,4.6,2.81,1.31-1.98,2.85-3.56,4.78-4.5,2.02,0,4.03,.21,6,.75,2.14-1.08,2.98-3.26,3.38-5.81,2.75,2.47,4.58,5.96,5.62,10.31,5.7-4.5,10.74-6.7,15-6.19l2.62-6c2.96,2.17,5.31,4.82,6.19,8.62,6.32-2.17,12.18-1.66,17.06,4.69,3.01,2.85,6.24,4,9.75,3l3.94-2.06c2.45,0,3.1,1.47,3,3.56l5.62-6c6.17,3.37,11.32,8.41,15.75,14.62,2.01,2.15,2.49,4.47,0,7.12,5.38-.42,10.26-.8,14.44-1.12,5.17,5.45,9.3,10.94,11.25,16.5l-3,1.69c-3.54,3.05-3.47,6,1.31,8.81,4.4,3.39,8.41,6.48,11.44,8.81-3,2.2-4.78,5.61-5.44,10.12-.05,14.82,4.39,18.45,9.94,19.31l.75,6.09c2.67,1.66,4.24,3.98,1.88,8.72v4.88c4.53,5.94,9.3,9.12,14.44,8.06,14.43-6.9,24.69-8.1,28.12,0l16.5,14.25h10.69c6.02-7.18,11.49-10.41,15.94-6.19,4.39,.84,8.15-.46,11.06-4.69l9-1.12c5.36-3.56,8.77-7.5,9.38-12,7.75-1.66,10.05-6.92,9.75-13.88,2.92-4.92,4.83-9.63,4.5-13.88,8.27-.38,10.22-9.12,9.38-21.56,8.13-9.76,9.71-19,5.25-27.75,1.05-7.41,.78-11.93-.94-13.31,2.32-5.77,2.76-10.47,2.25-14.62,2.07-.48,2.66-2.94,2.81-6"
          />
          <path
            id="skull_2"
            class="shead-cls-2"
            d="M159.32,241.49c-6.3,1.94-11.21,5.82-14.21,12.39-10.38-2.15-12.01,12-13.5,26.44-9.29-1.59-12.4,7.17-12.94,20.25-3.01-.96-4.98,2.28-7.87,2.81-3.07,1.57-6.06,1.44-9,0-5.52-.09-8.57,3.09-9.56,9"
          />
          <path
            id="skull_1"
            class="shead-cls-2"
            d="M417.74,365.82c-.06,3.37-.67,6.32-1.88,8.82-3.76,.72-5.16,3.9-5.62,8.81-2.12,3.06-2.12,5.94,0,8.62,1.01,3.97,3.67,7.14,7.5,9.75h-10.88c-6.36,3.39-4.99,8.31-2.25,13.5-3.76,3.3-8.02,4.89-12.75,4.88-.6,8.12-4.26,12.39-10.12,13.88-11.57-.83-18.27,2.4-19.12,10.5l-2.25,4.88"
          />
        </g>
        <g id="skull_base">
          <path
            id="skull_base_2"
            class="shead-cls-2"
            d="M350.68,322.63c-3.91,25.69-13.02,44.93-39.06,43.18-13.75-2.25-27.76,.24-42,6.75-15.89,5.97-40.76,4.71-65.25,3.75l-16.12-3.75c-10.71-8.84-12.78-19.97-11.62-31.97-6.59-7.91-9.76-18.06-5.25-33.28l3.75-6.75c-2.74-2.41-5.82-4.73-7.5-8.62-1.36-26.2,5.57-42.58,20.62-49.31"
          />
          <path
            id="skull_base_1"
            class="shead-cls-2"
            d="M188.24,372.56l-31.12,3.75c-10.15-3.51-13.64-9.22-12.38-16.5,4.02-23.4-9.57-41.74-26.06-59.25"
          />
        </g>
        <g id="jaw_joint">
          <path
            id="jaw_joint_3"
            class="shead-cls-2"
            d="M157.11,376.32c-1.16,7.36,1.91,15.12,11.77,23.53,11.32-3.29,21.39-4.25,27.28,2.67,10.23,.58,16.99,4.15,18.98,11.81,8.5,3.96,16.2,6.1,22.64,5.34-1.13-4.97-.94-9.57,.56-13.78,11.69-18.76,22.83-21.92,33.33-6.61,3.25,7.41,6.85,8.1,11.11-4.22,3.57-11.53,11.04-13.64,22.92-5.06,5.6,4.77,7.43,12.15,7.59,20.67-.94,11.57-3.27,20.65-10.12,25.17-5.59-12.85-13.54-16.79-21.66-20.11-3.49-2.49-4.55-6.47-4.08-11.39"
          />
          <path
            id="jaw_joint_2"
            class="shead-cls-2"
            d="M160.93,410.87c10.61,6.47,16.58,14.66,16.81,24.98,8.15,14.95,19.98,18.18,35.72,9,13.02-6.99,22.2-15.08,24.33-25.17"
          />
          <path
            id="jaw_joint_1"
            class="shead-cls-2"
            d="M266.8,419.25c5.57-5.79,6.47-12.61,4.87-19.97"
          />
        </g>
        <g id="nasal_bone">
          <path
            id="nasal_bone_1"
            class="shead-cls-2"
            d="M16.3,369.94c3.88-25.45,13.56-42.55,28.69-51.38,4.28-1.95,6.44-5.75,7.31-10.69l-12-15.94"
          />
        </g>
        <g id="orbita">
          <path
            id="orbita_3"
            class="shead-cls-2"
            d="M37.17,284.43c2.95-4.6,7.29-8.43,15.13-10.3,1.46,7.89,8.83,15.34,20.25,22.5,8.17,4.7,14.92,9.88,19.69,15.75l9.56,34.31c-6.08-5.3-12.09-9.24-18-11.25-7.63-10.81-16.04-16.42-25.31-16.31-3.83-.98-5.74-4.97-6.19-11.25"
          />
          <path
            id="orbita_2"
            class="shead-cls-2"
            d="M60.31,319.18l-2.57,19.26c-3.41,15.29-3.92,29.79,2.57,42.38,8.23,11.28,17.85,16.98,31.93,4.88,10.06-8.85,12.74-22.16,9.56-39"
          />
          <path
            id="orbita_1"
            class="shead-cls-2"
            d="M69.49,390.02c-5.59,1.49-6.77,5.53-5.09,11.23,18.11,12.06,26.75,26.93,31.22,43.03,6.2,9.81,15.9,9.69,28.97,0,13.57-7.51,25.46-19.22,36.35-33.42,2.71-3.53,5.35-7.21,7.95-11.02"
          />
        </g>
        <g id="jaw">
          <path
            id="jaw_1"
            class="shead-cls-2"
            d="M144.39,429.49c1.9,36.33,2.67,69.73-1.8,89.61-3.14,16.66-12.36,25.57-23.91,31.5"
          />
          <path
            id="teeth_23"
            class="shead-cls-2"
            d="M6.55,491.06c1.61-3.42,3.53-3.58,5.81,0,3.83-6.43,8.36-5.07,13.22,0,4.77-4.83,9.11-4.48,12.94,2.16,9.08-5.99,15.56-6.47,21.66-1.08,4.89-2.45,9.39-2.8,13.5-1.08,7.9-7.16,14.68-6.37,20.81-1.02,6.05-6.54,13.57-10.27,24.19-8.07,7.77-6.74,14.42-8.26,19.69-3.38l7.5-7.32"
          />
          <path
            id="teeth_22"
            class="shead-cls-2"
            d="M17.8,538.88c2.34,3.91,5.04,5.54,8.34,3.56,4.21,5.36,8.12,8.12,10.97,1.41,5.19,5.16,9.85,6.16,13.5-2.48,4.71,5.45,9.52,7.03,14.62-3.7,7.11,4.89,13.18,6.16,16.03-3.66,3.86,3.75,7.49,4.76,10.41-3.09,6.05,2.8,11.32,2.96,14.91-2.53,4.55,2.13,8.58,.02,12.09-6.47,4.51,2.79,8.25,2.85,10.12-3.66,5.12,1.55,8.76,.95,10.41-2.53,.73-.46,2.16-1.35,4.54-2.85"
          />
          <path
            id="teeth_21"
            class="shead-cls-2"
            d="M12.36,491.06c-.69,11.38-.04,21.06,2.06,28.88,3.56-1.1,6.57-3.22,8.62-7.07,5.03,8.03,10.08,9.94,15.19,2.01,6.72,5.17,15.59,8.81,19.48-.56,6.6,5.88,10.54,7.91,16.23-3.09,6.68,7.27,11.2,6.44,13.5-2.81,4.53,6.06,8.75,8.96,11.81,0,4.54,3.36,8.94,5.51,11.81-5.06,3.53,4.92,7.19,3.89,10.97-1.97,3.92,3.87,7.96,2.55,12.09-3.66,4.25,4.12,7.5,1.97,8.44,0l2.86-2.25"
          />
          <path
            id="teeth_20"
            class="shead-cls-2"
            d="M25.58,491.06c-2.57,7.45-3.37,14.72-2.53,21.81"
          />
          <path
            id="teeth_19"
            class="shead-cls-2"
            d="M38.52,493.22c.09,8.11,.04,15.52-.28,21.66"
          />
          <path
            id="teeth_18"
            class="shead-cls-2"
            d="M60.18,492.14c-3.01,7.77-2.33,15.2-2.45,22.17"
          />
          <path
            id="teeth_17"
            class="shead-cls-2"
            d="M73.67,490.82c.07,7.58,.15,14.61,.28,20.16"
          />
          <path
            id="teeth_16"
            class="shead-cls-2"
            d="M94.49,490.04c3.54,4.83,4.71,11.23,4.78,18.37"
          />
          <path
            id="teeth_15"
            class="shead-cls-2"
            d="M118.68,481.97c3.37,5.66,4.5,12.12,3.37,19.41"
          />
          <path
            id="teeth_14"
            class="shead-cls-2"
            d="M138.36,478.6c2.09,2.26,3.27,10.02,4.22,19.12"
          />
          <line
            id="teeth_13"
            class="shead-cls-2"
            x1="132.28"
            y1="490.04"
            x2="134.15"
            y2="497.72"
          />
          <line
            id="teeth_12"
            class="shead-cls-2"
            x1="109.4"
            y1="498"
            x2="110.38"
            y2="505.59"
          />
          <line
            id="teeth_11"
            class="shead-cls-2"
            x1="87.18"
            y1="503.23"
            x2="87.46"
            y2="508.41"
          />
          <path
            id="teeth_10"
            class="shead-cls-2"
            d="M26.15,542.44c-1.84-9.04-2.75-19.02-3.09-29.57"
          />
          <path
            id="teeth_9"
            class="shead-cls-2"
            d="M37.11,543.85c1.64-8.39,1.91-18.15,1.12-28.97"
          />
          <path
            id="teeth_8"
            class="shead-cls-2"
            d="M50.61,541.36c-2.29-7.01-2.61-14.12-1.11-21.31"
          />
          <path
            id="teeth_7"
            class="shead-cls-2"
            d="M65.24,537.66c1.59-6.19,1.5-12.37-.28-18.56"
          />
          <path
            id="teeth_6"
            class="shead-cls-2"
            d="M81.27,534c-.5-6-.66-12-.5-18"
          />
          <path
            id="teeth_5"
            class="shead-cls-2"
            d="M91.68,530.91c2.29-6.01,2.25-12.02-.12-18.04"
          />
          <line
            id="teeth_4"
            class="shead-cls-2"
            x1="106.58"
            y1="528.38"
            x2="105.74"
            y2="521.89"
          />
          <path id="teeth_3" class="shead-cls-2" d="M118.68,521.91v-16.09" />
          <line
            id="teeth_2"
            class="shead-cls-2"
            x1="128.8"
            y1="518.25"
            x2="128.24"
            y2="514.88"
          />
          <path
            id="teeth_1"
            class="shead-cls-2"
            d="M140.47,514.88c.42-5.22,0-10.43-1.27-15.65"
          />
        </g>
      </g>
      <path
        id="Faceskull"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, Skull.id, Skull.children.faceSkull),
          });
        }}
        class={`shead-cls-1 ${isSelected(selected, Skull.children.faceSkull)}`}
        d="M36.39,628.7c31.01-3.82,60.51-9.33,84.19-23.06,18.44-10.12,43.1-17.1,69.19-18.94,30.4-6.11,30.37-35.65,33.83-62.09,1.63-15.98,7.73-30.71,12.48-41.23,10.88-18.54,21.09-40.5,30.84-64.13,5.11-5.85,7.05-12.38,5.29-19.73-16.68-25.22-33.97,5.96-33.97,5.96-1.4,5.03-1.36,10.19-.81,14.17-7.51,.46-15.04-2.04-22.58-5.49-1.16-8.27-10.08-10.67-19.32-12-4.9-6.12-13.91-6.72-26.65-2.32-.81-.94-15.04-11.92-11.79-23.31-11.39-4.88-13.45-10.49-12.35-16.73,3.98-26.44-12.38-42.35-26.29-59.54-1.7-.95-4.64,.68-7.93,3.05-2.58,1.83-5.65,1.43-8.95,0-7.32,.2-8.99,3.92-8.95,8.95-13.63-15.46-38.03-22.58-40.32-38.14-6.06,.51-12.31,6.04-15.41,9.87l3.25,8.14c-2.65,4.19-3.96,6.64-4.72,8.04-6.67,18.8-15.83,29.3-28.5,34.88-6.29,4.28-8.52,9.15-4.12,15,3.52,7.99,9.88,15.3,13.5,19.88,5.13,11.69-6.67,18.6,4.13,19.5,9.91,5.95,12.39,20.05,11.25,37.13-10.56,9.46-20.22,12.62-30.75,17.25,7.04,6.62,11.67,15.7,9.75,26.81-4.47,9.18-5.73,15.89-3.89,20.22-3.69,12.25-1.22,35.8,7.63,29.09-.8,6.27,.27,12.63,3.55,19.11-4.88,1.02-1.63,6.92,3.79,14.46,3.84,13.64,5.16,26.23-2.06,35.25-6.93,16.75-13.81,33.49,16.69,39.94Z"
      />
      <path
        id="brain_skull"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, Skull.id, Skull.children.brainSkull),
          });
        }}
        class={`shead-cls-1 ${isSelected(selected, Skull.children.brainSkull)}`}
        d="M266.92,419.25c2.69,7.59,7.44,12.45,13.22,15.95,8.3,2.88,6.13,17.68-6.57,30.37-6.7,4.02-8.38,11.54-7.31,21l19.12-21.19c6.05-5.17,11.71-12.83,16.12-27,1.93,6.34,3.12,12.98,4.31,20.06,3.86,13.17,11.8,18.5,26.25,3.56,12.17,.28,22.3-2.99,28.52-13.05,9.96,6.58,19.45,7.97,28.29,1.99l23.81-1.5c6.07-2.85,10.99-7.33,15.37-12.56,28.04-4.91,48.08-19.73,60.75-43.69,15.52-19,22.07-32.32,24.19-42.84,15.04-41.66,3.18-80.71-7.8-119.84-7.93-43.96-32.66-86.37-74.05-124.28-19.17-11.83-54.56-39.28-103.22-44.72-42.22-15.67-78.2-9.02-113.78-.93-24.74,4.73-50.63,14.03-78.03,29.59-38.99,28.64-92.99,86.3-92.06,137.44-9.47,19.61-12.81,38.51-7.16,56.39,5.33-5.96,10.47-9.18,15.41-9.87,3.94,17.66,28.95,22.85,40.32,38.14,1.03-5.28,2.91-9.56,8.95-8.95,5.93,4.64,12.64-3.29,16.88-3.05,14.9,14.84,29.32,33.32,26.57,54.98-2.44,13.73,3.65,18.12,12.07,21.29-.43,8.02,2.39,15.86,11.79,23.31,11.53-4.94,22.41-3.16,26.65,2.32,8.36,.54,16,2.35,19.32,12,6.04,3.29,13.5,5.19,22.58,5.49-.68-4.39-.17-9.2,.81-14.17,9.59-13.63,19.54-24.61,33.97-5.96,1.02,7.38-.29,14.08-5.29,19.73Z"
      />
    </svg>
  );
}

export default SideView;
