import style from "./style.scss";

function LinkerMittelfussDplOblLiegend1435(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.98 367.66">

<g id="Ebene_1" data-name="Ebene 1">
  <g id="background_layer" data-name="background layer">
    <path id="background_1" data-name="background 1" class="mittelfuss_obl_links1435_cls_4" d="M70.41,291.53c-15.2,5.28-17.2-4.96-12.24-23.33,4.22-7.6,3.17-14.14-.42-20.16,6.86-75.99,1.79-115.79-10.34-133.83-4.22-5.17-4.64-10.66-2.32-16.25-2.32-9.71-.32-15.62,7.18-16.68,12.56-6.44,18.05-.53,17.42,16.25,6.54,3.48,5.91,9.39.84,16.99l13.51,106.81c6.54,9.6,9.5,20.58,8.65,32.83,8.34,20.79,9.82,33.46-2.74,29.66l-19.53,7.7h-.01Z"/>
    <path id="background_2" data-name="background 2" class="mittelfuss_obl_links1435_cls_4" d="M101.23,273.38c-16.99,3.48-18.05-10.77-11.82-32.83,10.24-41.59,9.71-85.6.42-131.41-2.96-4.86-4.75-9.5-5.49-13.83-5.17-6.12-7.39-12.45-2.32-19-4.43-12.88-3.38-21.43,11.08-19.42,10.55.63,14.25,3.8,9.5,10.66,6.23,7.7,8.13,15.41,3.59,23.33,3.27,50.56,7.6,98.16,15.41,134.99,6.54,7.6,9.92,16.15,8.34,26.18-2.01,10.98-9.29,19.21-28.71,21.32.11,0,0,0,0,0h0Z"/>
    <path id="background_3" data-name="background 3" class="mittelfuss_obl_links1435_cls_4" d="M123.71,363.62c-2.01-7.6,3.38-14.25,13.62-20.16,7.92-12.67,19.84-22.69,45.17-23.75,18.05-4.22,32.19-2.53,36.2,15.41,2.52,7.74,4.35,14.99,5.43,21.7"/>
    <path id="background_4" data-name="background 4" class="mittelfuss_obl_links1435_cls_4" d="M200.34,332.8c-23.54,4.12-32.51-7.7-35.04-26.7-6.44-24.59-6.02-41.06,3.59-46.86,7.49-3.69,11.19-10.77,11.82-20.79,16.15-9.5,29.13-7.7,36.84,13.62,3.91,9.6,3.91,17.84.63,24.91,6.54,34.94,3.06,56.78-17.84,55.83h0Z"/>
    <path id="background_5" data-name="background 5" class="mittelfuss_obl_links1435_cls_4" d="M184.29,253.64c-15.94.63-24.49-3.06-23.33-12.67-6.76-17.42-2.53-33.56,5.91-49.08,11.72-32.3,13.83-61.11,5.17-85.92-7.18-14.78-4.96-27.86,2.32-40-.63-4.22,1.16-7.6,6.33-9.5,16.47-4.22,29.76-1.69,39.58,7.92,5.91,4.33,8.44,9.39,7.18,14.99,3.59,12.67.74,25.01-7.49,37.15-10.45,29.66-10.45,64.38-.42,104.07,7.18,9.39,1.58,19.84-11.5,30.82l-23.75,2.22h0Z"/>
    <path id="background_6" data-name="background 6" class="mittelfuss_obl_links1435_cls_4" d="M155.37,314.75l23.54-4.33c11.19-3.06,13.4-9.6,8.55-19-4.86-8.23-8.55-17.63-11.29-27.86-4.64-9.18-13.83-7.92-24.91-2.22-14.99,4.96-17.84,11.72-11.29,19.84-2.11,5.81-1.48,10.77,4.33,14.25l4.22,15.09c.84,2.96,3.8,4.75,6.86,4.22h-.01Z"/>
  </g>
  <g id="tarsus">
    <path id="tarsus_1" data-name="tarsus 1" class="mittelfuss_obl_links1435_cls_3" d="M127.72,356.76c-1.79-3.38-4.43-6.33-7.6-8.44-19.53-13.3-28.39-28.71-28.29-45.91,2.96-10.24-2.43-10.77-11.82-6.54-20.48,8.44-35.04,19.21-42.75,32.61-6.76,4.86-10.87,9.5-2.32,12.45,6.97,3.59,10.24,12.35,9.5,26.7"/>
    <path id="tarsus_2" data-name="tarsus 2" class="mittelfuss_obl_links1435_cls_2" d="M155.37,314.75l23.54-4.33c11.19-3.06,13.4-9.6,8.55-19-4.86-8.23-8.55-17.63-11.29-27.86-4.64-9.18-13.83-7.92-24.91-2.22-14.99,4.96-17.84,11.72-11.29,19.84-2.11,5.81-1.48,10.77,4.33,14.25l4.22,15.09c.84,2.96,3.8,4.75,6.86,4.22h-.01Z"/>
    <path id="tarsus_3" data-name="tarsus 3" class="mittelfuss_obl_links1435_cls_3" d="M122.65,342.3c7.49,1.9,16.25-4.96,25.86-16.15.95-1.16,1.37-2.74.95-4.22-1.16-4.12-4.12-5.07-6.76-5.17,2.74-11.82-4.01-22.38-12.45-32.61.95-17.52-5.81-25.96-23.11-21.95-14.67,6.54-18.15,15.94-10.66,27.86l14.25,29.66c-3.91,10.45-.11,18.05,11.93,22.59h-.01Z"/>
    <path id="tarsus_4" data-name="tarsus 4" class="mittelfuss_obl_links1435_cls_2" d="M122.29,359.46c.54-5.9,5.66-11.19,13.98-16,7.92-12.67,19.84-22.69,45.17-23.75,18.05-4.22,32.19-2.53,36.2,15.41,1.85,5.68,3.33,11.09,4.41,16.22"/>
    <path id="tarsus_5" data-name="tarsus 5" class="mittelfuss_obl_links1435_cls_2" d="M199.28,332.8c-23.54,4.12-32.51-7.7-35.04-26.7-6.44-24.59-6.02-41.06,3.59-46.86,7.49-3.69,11.19-10.77,11.82-20.79,16.15-9.5,29.13-7.7,36.84,13.62,3.91,9.6,3.91,17.84.63,24.91,6.54,34.94,3.06,56.78-17.84,55.83h0Z"/>
  </g>
  <g id="metatarsus">
    <path id="metatarsus_1" data-name="metatarsus 1" class="mittelfuss_obl_links1435_cls_3" d="M29.67,325.2c-8.44,13.93-16.47,13.83-23.96-2.32-2.53-5.49-2.32-11.82.32-17.2,22.69-45.91,16.68-101.11,2.22-158.95-6.54-5.17-8.97-11.5-7.18-19,2.43-13.93,7.49-22.59,18.15-19.84,4.64-2.01,8.87-.21,12.67,7.49-3.06,5.38-3.38,10.45,2.32,14.67,7.18,30.19,10.98,60.8,9.92,91.83-1.16,19.1,2.11,36.1,14.99,48.34,8.13,6.76,10.66,15.3,6.76,25.96-.84,2.32-2.74,4.01-5.07,4.75-12.56,4.54-22.69,12.98-31.14,24.28h0Z"/>
    <path id="metatarsus_2" data-name="metatarsus 2" class="mittelfuss_obl_links1435_cls_2" d="M70.41,291.95c-15.2,5.28-17.2-4.96-12.24-23.33,4.22-7.6,3.17-14.14-.42-20.16,6.86-75.99,1.79-115.79-10.34-133.83-4.22-5.17-4.64-10.66-2.32-16.25-2.32-9.71-.32-15.62,7.18-16.68,12.56-6.44,18.05-.53,17.42,16.25,6.54,3.48,5.91,9.39.84,16.99l13.51,106.81c6.54,9.6,9.5,20.58,8.65,32.83,8.34,20.79,9.82,33.46-2.74,29.66l-19.53,7.7h-.01Z"/>
    <path id="metatarsus_3" data-name="metatarsus 3" class="mittelfuss_obl_links1435_cls_2" d="M101.33,273.8c-16.99,3.48-18.05-10.77-11.82-32.83,10.24-41.59,9.71-85.6.42-131.41-2.96-4.86-4.75-9.5-5.49-13.83-5.17-6.12-7.39-12.45-2.32-19-4.43-12.88-3.38-21.43,11.08-19.42,10.55.63,14.25,3.8,9.5,10.66,6.23,7.7,8.13,15.41,3.59,23.33,3.27,50.56,7.6,98.16,15.41,134.99,6.54,7.6,9.92,16.15,8.34,26.18-2.11,10.98-9.29,19.32-28.71,21.32h0Z"/>
    <path id="metatarsus_4" data-name="metatarsus 4" class="mittelfuss_obl_links1435_cls_3" d="M145.66,257.12c-11.19,9.82-17.31,9.29-17.42-3.17,8.87-60.16,9.29-116.74-5.17-166.66-9.92-4.33-10.87-10.45-4.33-18.15-5.59-15.2-4.86-24.38,10.34-20.16,13.09-1.27,20.79,1.27,16.57,12.67,4.96,10.13,4.43,20.05.42,29.66.42,12.56,1.58,25.75,3.59,39.58,2.11,29.87,8.02,58.9,17.42,87.08,9.6,13.19,14.35,25.44,6.76,35.25-8.65-.74-18.05.63-28.18,3.91h0Z"/>
    <path id="metatarsus_5" data-name="metatarsus 5" class="mittelfuss_obl_links1435_cls_2" d="M183.24,253.64c-15.94.63-24.49-3.06-23.33-12.67-6.76-17.42-2.53-33.56,5.91-49.08,11.72-32.3,13.83-61.11,5.17-85.92-7.18-14.78-4.96-27.86,2.32-40-.63-4.22,1.16-7.6,6.33-9.5,16.47-4.22,29.76-1.69,39.58,7.92,5.91,4.33,8.44,9.39,7.18,14.99,3.59,12.67.74,25.01-7.49,37.15-10.45,29.66-10.45,64.38-.42,104.07,7.18,9.39,1.58,19.84-11.5,30.82l-23.75,2.22h0Z"/>
    <path id="metatarsus_6" data-name="metatarsus 6" class="mittelfuss_obl_links1435_cls_3" d="M155.9,101.23c-6.97-1.79-7.18-8.87-2.32-19.84,6.23-8.65,12.67-11.82,19.42-6.33,4.43,5.91,4.01,13.4,0,22.16-4.86,9.71-10.45,12.03-17.1,4.01h0Z"/>
  </g>
  <g id="toes">
    <path id="toes_1" data-name="toes 1" class="mittelfuss_obl_links1435_cls_3" d="M9.82,105.56c-8.97-5.28-7.6-13.19,2.32-23.33,6.12-9.82,8.44-21.32,7.18-34.41-4.43-9.5.11-12.35,9.92-11.08,8.34-3.48,16.68-7.18,12.67,4.01-6.12,14.57-10.45,30.29-5.49,51.51.95,18.37-3.38,19.53-10.34,12.67-5.81-2.32-11.29-2.53-16.25.63h-.01Z"/>
    <path id="toes_2" data-name="toes 2" class="mittelfuss_obl_links1435_cls_3" d="M78.75,10.88l-4.01,13.83c-5.17,10.45-5.07,22.8-3.59,35.67,5.17,8.55,5.17,15.09.84,19.84-6.76-10.24-13.93-10.77-21.32-4.33h0c-9.82,2.64-11.5-2.32-7.18-13.09,14.78-17.73,18.47-34.09,11.5-49.08"/>
    <path id="toes_3" data-name="toes 3" class="mittelfuss_obl_links1435_cls_3" d="M107.73,4.84c-3.4,15.94-3.84,28.71,2.26,34.64,2.64,14.67.32,20.05-7.92,14.25-6.12-3.59-10.87-3.59-14.99-1.58h0c-12.56,4.86-12.67-2.22-5.17-16.68,7.8-10.73,11.12-20.71,10.54-30.06"/>
    <path id="toes_4" data-name="toes 4" class="mittelfuss_obl_links1435_cls_3" d="M147.39.07c-1.39,9.22-1.25,18.62.59,28.22,5.81,20.27,1.06,22.38-8.65,15.83-6.44-4.33-12.14-5.17-17.1-2.32h0c-11.93,1.9-9.82-6.33-3.17-17.84,6.4-5.69,10.27-13.37,11.78-22.92"/>
    <path id="toes_5" data-name="toes 5" class="mittelfuss_obl_links1435_cls_3" d="M216.44,1.17c.31,11.29,2.1,22.33,5.95,33.04,7.07,24.91.63,27.55-12.67,19-12.35-3.91-22.38-4.64-30.08-1.9h0c-14.35,4.22-11.72-6.44-8.34-17.84,6.85-10,11.01-20.66,11.24-32.3"/>
    <path id="toes_6" data-name="toes 6" class="mittelfuss_obl_links1435_cls_3" d="M21.75,33.15c-6.33-.21-7.39-2.74-3.59-7.49-.32-5.59,2.32-8.55,7.49-9.08l10.66,3.17c5.49,1.06,5.38,3.38,2.74,6.33,1.37,4.54-.84,6.65-6.33,6.76l-10.98.32h0Z"/>
  </g>
</g>
<g id="Ebene_2" data-name="Ebene 2">
  <g id="metatarsal_bone_overlay" data-name="metatarsal bone overlay" class="mittelfuss_obl_links1435_cls_1">
    <path id="metatarsal_bone_overlay_1" data-name="metatarsal bone overlay 1" class="MFK1435Fill" style= {{
            fill: props.colors.MFK1435Fill,
            stroke: props.colors.MFK1435Fill,
            opacity:props.colors.MFK1435Fill,
          }} d="M30.03,324.8c-8.44,13.93-16.47,13.83-23.96-2.32-2.53-5.49-2.32-11.82.32-17.2,22.69-45.91,16.68-101.11,2.22-158.95-6.54-5.17-8.97-11.5-7.18-19,2.43-13.93,7.49-22.59,18.15-19.84,4.64-2.01,8.87-.21,12.67,7.49-3.06,5.38-3.38,10.45,2.32,14.67,7.18,30.19,10.98,60.8,9.92,91.83-1.16,19.1,2.11,36.1,14.99,48.34,8.13,6.76,10.66,15.3,6.76,25.96-.84,2.32-2.74,4.01-5.07,4.75-12.56,4.54-22.69,12.98-31.14,24.28h0Z"/>
    <path id="metatarsal_bone_overlay_2" data-name="metatarsal bone overlay 2" class="MFK1435Fill" style= {{
            fill: props.colors.MFK1435Fill,
            stroke: props.colors.MFK1435Fill,
            opacity:props.colors.MFK1435Fill,
          }} d="M70.77,291.55c-15.2,5.28-17.2-4.96-12.24-23.33,4.22-7.6,3.17-14.14-.42-20.16,6.86-75.99,1.79-115.79-10.34-133.83-4.22-5.17-4.64-10.66-2.32-16.25-2.32-9.71-.32-15.62,7.18-16.68,12.56-6.44,18.05-.53,17.42,16.25,6.54,3.48,5.91,9.39.84,16.99l13.51,106.81c6.54,9.6,9.5,20.58,8.65,32.83,8.34,20.79,9.82,33.46-2.74,29.66l-19.53,7.7h-.01Z"/>
    <path id="metatarsal_bone_overlay_3" data-name="metatarsal bone overlay 3" class="MFK1435Fill" style= {{
            fill: props.colors.MFK1435Fill,
            stroke: props.colors.MFK1435Fill,
            opacity:props.colors.MFK1435Fill,
          }} d="M101.69,273.4c-16.99,3.48-18.05-10.77-11.82-32.83,10.24-41.59,9.71-85.6.42-131.41-2.96-4.86-4.75-9.5-5.49-13.83-5.17-6.12-7.39-12.45-2.32-19-4.43-12.88-3.38-21.43,11.08-19.42,10.55.63,14.25,3.8,9.5,10.66,6.23,7.7,8.13,15.41,3.59,23.33,3.27,50.56,7.6,98.16,15.41,134.99,6.54,7.6,9.92,16.15,8.34,26.18-2.11,10.98-9.29,19.32-28.71,21.32h0Z"/>
    <path id="metatarsal_bone_overlay_4" data-name="metatarsal bone overlay 4" class="MFK1435Fill" style= {{
            fill: props.colors.MFK1435Fill,
            stroke: props.colors.MFK1435Fill,
            opacity:props.colors.MFK1435Fill,
          }} d="M146.02,256.72c-11.19,9.82-17.31,9.29-17.42-3.17,8.87-60.16,9.29-116.74-5.17-166.66-9.92-4.33-10.87-10.45-4.33-18.15-5.59-15.2-4.86-24.38,10.34-20.16,13.09-1.27,20.79,1.27,16.57,12.67,4.96,10.13,4.43,20.05.42,29.66.42,12.56,1.58,25.75,3.59,39.58,2.11,29.87,8.02,58.9,17.42,87.08,9.6,13.19,14.35,25.44,6.76,35.25-8.65-.74-18.05.63-28.18,3.91h0Z"/>
    <path id="metatarsal_bone_overlay_5" data-name="metatarsal bone overlay 5" class="MFK1435Fill" style= {{
            fill: props.colors.MFK1435Fill,
            stroke: props.colors.MFK1435Fill,
            opacity:props.colors.MFK1435Fill,
          }} d="M183.6,253.24c-15.94.63-24.49-3.06-23.33-12.67-6.76-17.42-2.53-33.56,5.91-49.08,11.72-32.3,13.83-61.11,5.17-85.92-7.18-14.78-4.96-27.86,2.32-40-.63-4.22,1.16-7.6,6.33-9.5,16.47-4.22,29.76-1.69,39.58,7.92,5.91,4.33,8.44,9.39,7.18,14.99,3.59,12.67.74,25.01-7.49,37.15-10.45,29.66-10.45,64.38-.42,104.07,7.18,9.39,1.58,19.84-11.5,30.82l-23.75,2.22h0Z"/>
  </g>
</g>
</svg>
  );
}

export default LinkerMittelfussDplOblLiegend1435;
