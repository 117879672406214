import style from "./style.scss";

function RechterMittelfussDplOblLiegend1436(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.98 367.66">
 
 <g id="Ebene_1" data-name="Ebene 1">
   <g id="background_layer" data-name="background layer">
     <path id="background_1" data-name="background 1" class="mittelfuss_obl_rechts1436_cls_4" d="M158.55,291.52h0l-19.53-7.7c-12.56,3.8-11.08-8.87-2.74-29.66-.85-12.25,2.11-23.23,8.65-32.83l13.51-106.81c-5.07-7.6-5.7-13.51.84-16.99-.63-16.78,4.86-22.69,17.42-16.25,7.5,1.06,9.5,6.97,7.18,16.68,2.32,5.59,1.9,11.08-2.32,16.25-12.13,18.04-17.2,57.84-10.34,133.83-3.59,6.02-4.64,12.56-.42,20.16,4.96,18.37,2.96,28.61-12.24,23.33h-.01Z"/>
     <path id="background_2" data-name="background 2" class="mittelfuss_obl_rechts1436_cls_4" d="M127.74,273.37s-.11,0,0,0c-19.42-2.11-26.7-10.34-28.71-21.32-1.58-10.03,1.8-18.58,8.34-26.18,7.81-36.83,12.14-84.43,15.41-134.99-4.54-7.92-2.64-15.63,3.59-23.33-4.75-6.86-1.05-10.03,9.5-10.66,14.46-2.01,15.51,6.54,11.08,19.42,5.07,6.55,2.85,12.88-2.32,19-.74,4.33-2.53,8.97-5.49,13.83-9.29,45.81-9.82,89.82.42,131.41,6.23,22.06,5.17,36.31-11.82,32.83h0Z"/>
     <path id="background_3" data-name="background 3" class="mittelfuss_obl_rechts1436_cls_4" d="M4.84,356.82c1.08-6.71,2.91-13.96,5.43-21.7,4.01-17.94,18.15-19.63,36.2-15.41,25.33,1.06,37.25,11.08,45.17,23.75,10.24,5.91,15.63,12.56,13.62,20.16"/>
     <path id="background_4" data-name="background 4" class="mittelfuss_obl_rechts1436_cls_4" d="M28.63,332.81c-20.9.95-24.38-20.89-17.84-55.83-3.28-7.07-3.28-15.31.63-24.91,7.71-21.32,20.69-23.12,36.84-13.62.63,10.02,4.33,17.1,11.82,20.79,9.61,5.8,10.03,22.27,3.59,46.86-2.53,19-11.5,30.82-35.04,26.7h0Z"/>
     <path id="background_5" data-name="background 5" class="mittelfuss_obl_rechts1436_cls_4" d="M44.68,253.64h0l-23.75-2.22c-13.08-10.98-18.68-21.43-11.5-30.82,10.03-39.69,10.03-74.41-.42-104.07C.78,104.39-2.07,92.05,1.52,79.38c-1.26-5.6,1.27-10.66,7.18-14.99,9.82-9.61,23.11-12.14,39.58-7.92,5.17,1.9,6.96,5.28,6.33,9.5,7.28,12.14,9.5,25.22,2.32,40-8.66,24.81-6.55,53.62,5.17,85.92,8.44,15.52,12.67,31.66,5.91,49.08,1.16,9.61-7.39,13.3-23.33,12.67h0Z"/>
     <path id="background_6" data-name="background 6" class="mittelfuss_obl_rechts1436_cls_4" d="M73.59,314.74h0c3.06.53,6.02-1.26,6.86-4.22l4.22-15.09c5.81-3.48,6.44-8.44,4.33-14.25,6.55-8.12,3.7-14.88-11.29-19.84-11.08-5.7-20.27-6.96-24.91,2.22-2.74,10.23-6.43,19.63-11.29,27.86-4.85,9.4-2.64,15.94,8.55,19l23.54,4.33h-.01Z"/>
   </g>
   <g id="tarsus">
     <path id="tarsus_1" data-name="tarsus 1" class="mittelfuss_obl_rechts1436_cls_3" d="M184.53,367.63c-.74-14.35,2.53-23.11,9.5-26.7,8.55-2.95,4.44-7.59-2.32-12.45-7.71-13.4-22.27-24.17-42.75-32.61-9.39-4.23-14.78-3.7-11.82,6.54.1,17.2-8.76,32.61-28.29,45.91-3.17,2.11-5.81,5.06-7.6,8.44"/>
     <path id="tarsus_2" data-name="tarsus 2" class="mittelfuss_obl_rechts1436_cls_2" d="M73.59,314.74h0c3.06.53,6.02-1.26,6.86-4.22l4.22-15.09c5.81-3.48,6.44-8.44,4.33-14.25,6.55-8.12,3.7-14.88-11.29-19.84-11.08-5.7-20.27-6.96-24.91,2.22-2.74,10.23-6.43,19.63-11.29,27.86-4.85,9.4-2.64,15.94,8.55,19l23.54,4.33h-.01Z"/>
     <path id="tarsus_3" data-name="tarsus 3" class="mittelfuss_obl_rechts1436_cls_3" d="M106.31,342.31h0c12.04-4.54,15.84-12.14,11.93-22.59l14.25-29.66c7.49-11.92,4.01-21.32-10.66-27.86-17.3-4.01-24.06,4.43-23.11,21.95-8.44,10.23-15.19,20.79-12.45,32.61-2.64.1-5.6,1.05-6.76,5.17-.42,1.48,0,3.06.95,4.22,9.61,11.19,18.37,18.05,25.86,16.15h-.01Z"/>
     <path id="tarsus_4" data-name="tarsus 4" class="mittelfuss_obl_rechts1436_cls_2" d="M6.92,351.34c1.08-5.13,2.56-10.54,4.41-16.22,4.01-17.94,18.15-19.63,36.2-15.41,25.33,1.06,37.25,11.08,45.17,23.75,8.32,4.81,13.44,10.1,13.98,16"/>
     <path id="tarsus_5" data-name="tarsus 5" class="mittelfuss_obl_rechts1436_cls_2" d="M29.69,332.81c-20.9.95-24.38-20.89-17.84-55.83-3.28-7.07-3.28-15.31.63-24.91,7.71-21.32,20.69-23.12,36.84-13.62.63,10.02,4.33,17.1,11.82,20.79,9.61,5.8,10.03,22.27,3.59,46.86-2.53,19-11.5,30.82-35.04,26.7h0Z"/>
   </g>
   <g id="metatarsus">
     <path id="metatarsus_1" data-name="metatarsus 1" class="mittelfuss_obl_rechts1436_cls_3" d="M199.3,325.21h0c-8.45-11.3-18.58-19.74-31.14-24.28-2.33-.74-4.23-2.43-5.07-4.75-3.9-10.66-1.37-19.2,6.76-25.96,12.88-12.24,16.15-29.24,14.99-48.34-1.06-31.03,2.74-61.64,9.92-91.83,5.7-4.22,5.38-9.29,2.32-14.67,3.8-7.7,8.03-9.5,12.67-7.49,10.66-2.75,15.72,5.91,18.15,19.84,1.79,7.5-.64,13.83-7.18,19-14.46,57.84-20.47,113.04,2.22,158.95,2.64,5.38,2.85,11.71.32,17.2-7.49,16.15-15.52,16.25-23.96,2.32h0Z"/>
     <path id="metatarsus_2" data-name="metatarsus 2" class="mittelfuss_obl_rechts1436_cls_2" d="M158.55,291.94h0l-19.53-7.7c-12.56,3.8-11.08-8.87-2.74-29.66-.85-12.25,2.11-23.23,8.65-32.83l13.51-106.81c-5.07-7.6-5.7-13.51.84-16.99-.63-16.78,4.86-22.69,17.42-16.25,7.5,1.06,9.5,6.97,7.18,16.68,2.32,5.59,1.9,11.08-2.32,16.25-12.13,18.04-17.2,57.84-10.34,133.83-3.59,6.02-4.64,12.56-.42,20.16,4.96,18.37,2.96,28.61-12.24,23.33h-.01Z"/>
     <path id="metatarsus_3" data-name="metatarsus 3" class="mittelfuss_obl_rechts1436_cls_2" d="M127.64,273.79h0c-19.42-2-26.6-10.34-28.71-21.32-1.58-10.03,1.8-18.58,8.34-26.18,7.81-36.83,12.14-84.43,15.41-134.99-4.54-7.92-2.64-15.63,3.59-23.33-4.75-6.86-1.05-10.03,9.5-10.66,14.46-2.01,15.51,6.54,11.08,19.42,5.07,6.55,2.85,12.88-2.32,19-.74,4.33-2.53,8.97-5.49,13.83-9.29,45.81-9.82,89.82.42,131.41,6.23,22.06,5.17,36.31-11.82,32.83h0Z"/>
     <path id="metatarsus_4" data-name="metatarsus 4" class="mittelfuss_obl_rechts1436_cls_3" d="M83.31,257.13h0c-10.13-3.28-19.53-4.65-28.18-3.91-7.59-9.81-2.84-22.06,6.76-35.25,9.4-28.18,15.31-57.21,17.42-87.08,2.01-13.83,3.17-27.02,3.59-39.58-4.01-9.61-4.54-19.53.42-29.66-4.22-11.4,3.48-13.94,16.57-12.67,15.2-4.22,15.93,4.96,10.34,20.16,6.54,7.7,5.59,13.82-4.33,18.15-14.46,49.92-14.04,106.5-5.17,166.66-.11,12.46-6.23,12.99-17.42,3.17h0Z"/>
     <path id="metatarsus_5" data-name="metatarsus 5" class="mittelfuss_obl_rechts1436_cls_2" d="M45.73,253.64h0l-23.75-2.22c-13.08-10.98-18.68-21.43-11.5-30.82,10.03-39.69,10.03-74.41-.42-104.07C1.83,104.39-1.02,92.05,2.57,79.38c-1.26-5.6,1.27-10.66,7.18-14.99,9.82-9.61,23.11-12.14,39.58-7.92,5.17,1.9,6.96,5.28,6.33,9.5,7.28,12.14,9.5,25.22,2.32,40-8.66,24.81-6.55,53.62,5.17,85.92,8.44,15.52,12.67,31.66,5.91,49.08,1.16,9.61-7.39,13.3-23.33,12.67h0Z"/>
     <path id="metatarsus_6" data-name="metatarsus 6" class="mittelfuss_obl_rechts1436_cls_3" d="M73.07,101.23h0c-6.65,8.02-12.24,5.7-17.1-4.01-4.01-8.76-4.43-16.25,0-22.16,6.75-5.49,13.19-2.32,19.42,6.33,4.86,10.97,4.65,18.05-2.32,19.84h0Z"/>
   </g>
   <g id="toes">
     <path id="toes_1" data-name="toes 1" class="mittelfuss_obl_rechts1436_cls_3" d="M219.14,105.56h0c-4.96-3.16-10.44-2.95-16.25-.63-6.96,6.86-11.29,5.7-10.34-12.67,4.96-21.22.63-36.94-5.49-51.51-4.01-11.19,4.33-7.49,12.67-4.01,9.81-1.27,14.35,1.58,9.92,11.08-1.26,13.09,1.06,24.59,7.18,34.41,9.92,10.14,11.29,18.05,2.32,23.33h-.01Z"/>
     <path id="toes_2" data-name="toes 2" class="mittelfuss_obl_rechts1436_cls_3" d="M173.98,13.72c-6.97,14.99-3.28,31.35,11.5,49.08,4.32,10.77,2.64,15.73-7.18,13.09h0c-7.39-6.44-14.56-5.91-21.32,4.33-4.33-4.75-4.33-11.29.84-19.84,1.48-12.87,1.58-25.22-3.59-35.67l-4.01-13.83"/>
     <path id="toes_3" data-name="toes 3" class="mittelfuss_obl_rechts1436_cls_3" d="M136.52,5.41c-.58,9.35,2.74,19.33,10.54,30.06,7.5,14.46,7.39,21.54-5.17,16.68h0c-4.12-2.01-8.87-2.01-14.99,1.58-8.24,5.8-10.56.42-7.92-14.25,6.1-5.93,5.66-18.7,2.26-34.64"/>
     <path id="toes_4" data-name="toes 4" class="mittelfuss_obl_rechts1436_cls_3" d="M98.13,1.04c1.51,9.55,5.38,17.23,11.78,22.92,6.65,11.51,8.76,19.74-3.17,17.84h0c-4.96-2.85-10.66-2.01-17.1,2.32-9.71,6.55-14.46,4.44-8.65-15.83,1.84-9.6,1.98-19,.59-28.22"/>
     <path id="toes_5" data-name="toes 5" class="mittelfuss_obl_rechts1436_cls_3" d="M46.43,1.17c.23,11.64,4.39,22.3,11.24,32.3,3.38,11.4,6.01,22.06-8.34,17.84h0c-7.7-2.74-17.73-2.01-30.08,1.9C5.95,61.76-.49,59.12,6.58,34.21,10.43,23.5,12.22,12.46,12.53,1.17"/>
     <path id="toes_6" data-name="toes 6" class="mittelfuss_obl_rechts1436_cls_3" d="M207.23,33.16h0l-10.98-.32c-5.49-.11-7.7-2.22-6.33-6.76-2.64-2.95-2.75-5.27,2.74-6.33l10.66-3.17c5.17.53,7.81,3.49,7.49,9.08,3.8,4.75,2.74,7.28-3.59,7.49h0Z"/>
   </g>
 </g>
 <g id="Ebene_2" data-name="Ebene 2">
   <g id="metatarsal_bone_overlay" data-name="metatarsal bone overlay" class="mittelfuss_obl_rechts1436_cls_1">
     <path id="metatarsal_bone_overlay_1" data-name="metatarsal bone overlay 1" class="MFK1436Fill" style= {{
             fill: props.colors.MFK1436Fill,
             stroke: props.colors.MFK1436Fill,
             opacity:props.colors.MFK1436Fill,
           }} d="M198.94,324.81h0c-8.45-11.3-18.58-19.74-31.14-24.28-2.33-.74-4.23-2.43-5.07-4.75-3.9-10.66-1.37-19.2,6.76-25.96,12.88-12.24,16.15-29.24,14.99-48.34-1.06-31.03,2.74-61.64,9.92-91.83,5.7-4.22,5.38-9.29,2.32-14.67,3.8-7.7,8.03-9.5,12.67-7.49,10.66-2.75,15.72,5.91,18.15,19.84,1.79,7.5-.64,13.83-7.18,19-14.46,57.84-20.47,113.04,2.22,158.95,2.64,5.38,2.85,11.71.32,17.2-7.49,16.15-15.52,16.25-23.96,2.32h0Z"/>
     <path id="metatarsal_bone_overlay_2" data-name="metatarsal bone overlay 2" class="MFK1436Fill" style= {{
             fill: props.colors.MFK1436Fill,
             stroke: props.colors.MFK1436Fill,
             opacity:props.colors.MFK1436Fill,
           }} d="M158.19,291.54h0l-19.53-7.7c-12.56,3.8-11.08-8.87-2.74-29.66-.85-12.25,2.11-23.23,8.65-32.83l13.51-106.81c-5.07-7.6-5.7-13.51.84-16.99-.63-16.78,4.86-22.69,17.42-16.25,7.5,1.06,9.5,6.97,7.18,16.68,2.32,5.59,1.9,11.08-2.32,16.25-12.13,18.04-17.2,57.84-10.34,133.83-3.59,6.02-4.64,12.56-.42,20.16,4.96,18.37,2.96,28.61-12.24,23.33h-.01Z"/>
     <path id="metatarsal_bone_overlay_3" data-name="metatarsal bone overlay 3" class="MFK1436Fill" style= {{
             fill: props.colors.MFK1436Fill,
             stroke: props.colors.MFK1436Fill,
             opacity:props.colors.MFK1436Fill,
           }} d="M127.28,273.39h0c-19.42-2-26.6-10.34-28.71-21.32-1.58-10.03,1.8-18.58,8.34-26.18,7.81-36.83,12.14-84.43,15.41-134.99-4.54-7.92-2.64-15.63,3.59-23.33-4.75-6.86-1.05-10.03,9.5-10.66,14.46-2.01,15.51,6.54,11.08,19.42,5.07,6.55,2.85,12.88-2.32,19-.74,4.33-2.53,8.97-5.49,13.83-9.29,45.81-9.82,89.82.42,131.41,6.23,22.06,5.17,36.31-11.82,32.83h0Z"/>
     <path id="metatarsal_bone_overlay_4" data-name="metatarsal bone overlay 4" class="MFK1436Fill" style= {{
             fill: props.colors.MFK1436Fill,
             stroke: props.colors.MFK1436Fill,
             opacity:props.colors.MFK1436Fill,
           }} d="M82.95,256.73h0c-10.13-3.28-19.53-4.65-28.18-3.91-7.59-9.81-2.84-22.06,6.76-35.25,9.4-28.18,15.31-57.21,17.42-87.08,2.01-13.83,3.17-27.02,3.59-39.58-4.01-9.61-4.54-19.53.42-29.66-4.22-11.4,3.48-13.94,16.57-12.67,15.2-4.22,15.93,4.96,10.34,20.16,6.54,7.7,5.59,13.82-4.33,18.15-14.46,49.92-14.04,106.5-5.17,166.66-.11,12.46-6.23,12.99-17.42,3.17h0Z"/>
     <path id="metatarsal_bone_overlay_5" data-name="metatarsal bone overlay 5" class="MFK1436Fill" style= {{
             fill: props.colors.MFK1436Fill,
             stroke: props.colors.MFK1436Fill,
             opacity:props.colors.MFK1436Fill,
           }} d="M45.37,253.24h0l-23.75-2.22c-13.08-10.98-18.68-21.43-11.5-30.82,10.03-39.69,10.03-74.41-.42-104.07C1.47,103.99-1.38,91.65,2.21,78.98c-1.26-5.6,1.27-10.66,7.18-14.99,9.82-9.61,23.11-12.14,39.58-7.92,5.17,1.9,6.96,5.28,6.33,9.5,7.28,12.14,9.5,25.22,2.32,40-8.66,24.81-6.55,53.62,5.17,85.92,8.44,15.52,12.67,31.66,5.91,49.08,1.16,9.61-7.39,13.3-23.33,12.67h0Z"/>
   </g>
 </g>
</svg>
  );
}

export default RechterMittelfussDplOblLiegend1436;
