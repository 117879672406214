import style from "./style.scss";

function Linke5ZeheLatSitzend1421(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 111.1 437.8"
    >
      <g id="fifth_toe" data-name="fifth toe">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="Linke_5Zehe_lat_sitzend1421_cls2"
            d="M1.2,347.5c.6-3.2,3.7-20.7,17.8-31.2,7.5-5.6,16-7.7,15.5-10.7-.7-3.9-14.5-6.6-10.7-5.2l-11.3,1.8c-4-1.6-6.4-7.4-6.4-7.4,0,0,0-.8.2-2.2,0,0,0,0,.1-.1.7-.7,1.2-1.6,1.6-2.5.4-1,.7-2.1.7-3.2,0-.4,0-.8-.3-1.1-.2-.2-.4-.4-.7-.5v-2.8c0-.4-.3-.8-.6-1-.1-.1-.3-.2-.5-.2,0-.8,0-1.6,0-2.4-.3-7-1.2-7-.7-9.9,1.2-6.5,6.4-10.6,8.3-12.2,10.1-8.4,28-37.9,33-53,2.2-6.7,4.1-21-3-33-3.4-5.7-7-7.6-8-14-1.1-7.1,1.5-16.9,8-21,2.8-1.7,5.1-1.7,5.3-3,.2-1.3-2-2.2-4.9-3.5,0,0,0,0,0-.1,0-.2,0-.4,0-.6,0-.5,0-1.1-.2-1.6-.1-.4-.3-.8-.6-1.1-.2-.2-.3-.4-.5-.6-.1,0-.2-.2-.3-.3-.2-.1-.5-.3-.7-.4-.2,0-.5-.2-.8-.2-.5-.1-1-.1-1.5-.1s-.8.3-1,.6c0,0,0,0,0,.1-1.4-.9-2.8-1.7-4.3-2.3-.5-.6-1-1.2-1.4-1.9-2.6-4.3-2.3-8.9-2-13,.7-8.8,4.2-12.5,14-31,6.5-12.4,7.3-15.3,7-19-.6-6.5-4.1-8-5-15-.9-7.2,1.9-12.8,5-19,1.6-3.2,3.4-6.8,5.7-10.2,0,.2.2.3.3.4.7.6,1.6.3,2.1-.3,2-2.1,3-4.8,4.2-7.3,0,0,0,0,0,0,2-1.6,4.3-2.9,6.9-3.8,1.4-.5,11.9-4.2,18.4,1.3,4.5,3.8,4.8,10.1,5.1,16,.4,7.3-1.2,10-2.9,19.9-1.3,7.7-1.5,13.2-1.9,24.1-.4,11.2-.6,17,.9,24.2.5,2.5,1.6,7.1,4.1,12.8,4.9,11.1,9.6,13.1,9,19-.6,5.9-5.8,8.7-4.3,11.8,1.2,2.3,4.2,1.3,7.1,3.7,1.3,1.1,2.1,2.5,2.7,4.3v.4c0,.3.1.6.3.8.5,2.2.6,4.9.5,8.1-.3,9.2-.5,13.8-4.3,23-10.8,26.2-8.2,64.5-5,81.6.9,4.7,2.6,11.9,3,22.4,0,0,.1,3.3-.2,21-.1.2-2.6,7.5-7.3,9.5-4.4,1.9-10.8-.7-10.8-.7,0,0-10.3.5-10.9,2.9-.5,2.1,7.3,4.3,17.2,11.3,7.5,5.3,12.8,9.1,14,15,1.2,6-2.7,9.2-6.4,18.7-2,5.1-2.7,8.7-4.6,26.3-1.1,10.2-1.9,17.8-2.4,23.5,0,.1,0,.2,0,.3-.9,5.7-2.1,11.3-3,17-.1.8.3,1.3.9,1.5-.1.2-.2.5-.4.7-16.2,16.9-51,19.1-70,10-.4-.2-.4-.7-.5-1v-7.2c0-.4-.2-.7-.5-1,0-.1,0-.3,0-.4.1-.8.3-1.5.5-2.2,0-.4,0-.8-.3-1.1,0-2.9-.1-6.5-1.2-11.1-2.4-10.3-6.1-12-10.7-23-3.3-7.8-9.2-21.6-6.3-38h0,0Z"
          />
        </g>
        <path
          id="fifth_toe_1"
          data-name="fifth toe 1"
          class="Linke_5Zehe_lat_sitzend1421_cls3"
          d="M90.2,422.5c1.4-17.3,3.3-31.8,5-43,2.8-18.7,4.3-28,7-33,2.1-3.8,4.6-7.3,4-12-.7-5.7-5.5-9.3-14-15-18.3-12.2-27.5-18.4-34-19-13.4-1.3-23.8,4.9-29,8-2.8,1.6-23.8,14.6-28,39-2.7,15.6,2.7,29.2,5,35,4.3,10.9,8.8,14.4,12,26,2.7,9.8,2.5,18.4,2,24"
        />
        <path
          id="fifth_toe_2"
          data-name="fifth toe 2"
          class="Linke_5Zehe_lat_sitzend1421_cls3"
          d="M100.2,304.5c2.6-1.9,5.7-6.3,4-29-1.1-15.1-2.8-18.3-4-31-1.3-13.9-.5-24.5,0-32,.9-12.2,1.8-25,6-41,2.8-10.8,6.3-19.9,3-31-.9-3.1-2.3-7.7-6-9-5.5-2-9.7,5.7-19,6-6.1.2-6.4-3-15-5-4.9-1.1-16.4-3.8-25,3-6.4,5.1-9.2,13.8-8,21,1,6.2,4.4,7.2,8,14,7,13.3,4.1,27.8,3,33-2.2,10.9-7.1,18-14,28-8.2,11.9-19,25-19,25-5.4,6.5-9.3,10.9-9,17,.3,4.9,3,6,3,12,0,7-3.8,9.6-2,13,2.2,4.3,10.3,4,11,4,7.1-.3,9.4-5.9,15-7,15-3,21.9,4.9,55,10,6.3,1,10,1.2,13-1Z"
        />
        <path
          id="fifth_toe_3"
          data-name="fifth toe 3"
          class="Linke_5Zehe_lat_sitzend1421_cls3"
          d="M40.2,124.5c-3.8-2.3-5.3-2.1-7-4-2.3-2.5-3-6.5-2-13,1.5-9.8,5.4-14.7,14-31,6.2-11.7,7.2-14.9,7-19-.3-7.1-3.9-8-5-15-1.8-12.1,6.7-22.7,11-28,5.2-6.4,10.9-13.5,20-14,.6,0,10.2-.4,15,6,3.9,5.1,2.1,11.1,0,25-.7,4.6-2.8,18.9-3,32-.3,15.2-.5,23.9,4,34,5.1,11.4,12.1,15.6,10,21-1.5,3.8-5.5,3-16,8-14.1,6.7-15.1,12-23,12-5.4,0-11.5-2.6-15.7-6-3.4-2.7-4.4-5.1-9.3-8Z"
        />
        <path
          id="fifth_toe_4"
          data-name="fifth toe 4"
          class="Linke_5Zehe_lat_sitzend1421_cls3"
          d="M87.2,305.5c0-2.9-29.3,15-63.5-5.2"
        />
        <path
          id="fifth_toe_5"
          data-name="fifth toe 5"
          class="Linke_5Zehe_lat_sitzend1421_cls3"
          d="M44.8,128c29.2-18.5,20.4,10.5,58.4,3.5"
        />
        <path
          id="fifth_toe_6"
          data-name="fifth toe 6"
          class="Linke_5Zehe_lat_sitzend1421_cls3"
          d="M84.2,137.5c0,3.3-7.4,6-16.5,6"
        />
        <path
          id="fifth_toe_7"
          data-name="fifth toe 7"
          class="Linke_5Zehe_lat_sitzend1421_cls3"
          d="M104.2,118.5c0,7.6-3,13.8-6.8,13.8"
        />
      </g>
      <g id="overlay">
        <path
          class="kleinzeh1421Fill"
          style={{
            fill: props.colors.kleinzeh1421Fill,
            stroke: props.colors.kleinzeh1421Fill,
            opacity: props.colors.kleinzeh1421Fill,
          }}
          d="M110.4,143.5c0-.4,0-.7,0-1.1h0c0-.1,0-.1,0-.2-.1-.4-.2-.9-.3-1.3-.3-.5-.5-1-.6-1.6-.2-.5-.4-1.1-.5-1.7-.3-.5-.5-1.1-.6-1.7-.4-.5-.9-.8-1.2-1.3-3.4-5-8.7-1.7-7.1-3.7,2.4-2.8,3.7-5.9,4.3-11.8.6-5.9-2.1-8-9-19s-3.5-10.3-4.1-12.8c-1.5-7.2-1.4-12.9-.9-24.2.4-11,.6-16.4,1.9-24.1,1.7-9.9,2.3-12.6,2.9-19.9s-.6-12.2-5.1-16c-6.5-5.5-17-1.8-18.4-1.3-2.6.9-3.6,2.9-5.6,4.4,0,0-2,1.7-2.1,1.7-1.2,2.5.2.2-1.8,2.3-.5.6-1.4,2.5-2.6,3.2s-1.1,1.2-1.2,1c-2.3,3.4-4.4,5.5-6,8.7-3.1,6.2-5.9,11.8-5,19,.9,7,4.4,8.5,5,15,.3,3.7-.5,6.6-7,19-9.8,18.5-13.3,22.2-14,31-.3,4.1-.6,8.7,2,13,.4.7.9,1.3,1.4,1.9,1.5.6,3,1.4,4.3,2.3,0,0,0,0,0-.1.2-.3.6-.6,1-.6s-.5.2,0,.3c.3,0,.5.4.7.5.3.1.2.3.5.5.1,0,2-.3,2.2-.2.2.2-2.3,0-2.2.2.2.3,1.9,1.2,2.1,1.6.2.5,1.5,1,1.5,1.5,0,.2,0,.4,0,.6,0,0,0,0,0,.1,2.9,1.3,4.9,3.5,4.9,3.5-.2,1.3-2.5,1.2-5.3,3-6.5,4.1-9.1,13.9-8,21,1,6.4,4.6,8.3,8,14,7.1,12,5.2,26.3,3,33-5,15.1-22.9,44.6-33,53-1.9,1.6-4.6,6.2-8.3,12.2-.5,3-.6,5.6.7,9.9,0,.3,0,.6,0,.9,1.2,1.6,1.6,3.7,1.5,5.8.1,0,.2.2.3.3.2.3.3.7.3,1.1,0,1.1-.3,2.2-.7,3.2-.4.9-.9,1.8-1.6,2.5,0,0,0,0-.1.1-.1,1.4-.2,2.2-.2,2.2,0,0-1.2,6.8,6.4,7.4l4.8.6c2.7-.4,5.2-3.5,10-.4,4.8,3.1,6.1,3.7,6.6,3.7-4.1,2.8-7.7,4.9-14.8,10.2-14,10.5-17.2,28-17.8,31.2h0s0,0,0,0c-3,16.3,3,30.2,6.3,38,4.7,11,8.3,12.7,10.7,23,.4,1.7.7,3.3.9,4.8.6,1.4.8,2.9.6,4.4,0,.1,0,.3,0,.4.1.5.2,1,.3,1.5.1.7.2,1.4.2,2.1,0,.1,0,.3,0,.4.2.3.3.5.3.9.6,1.6.5,2,.2,2,.1.9.1,1.8,0,2.7-.1,1-.3,1.9-.6,2.7,0,.7-.1,1.3-.2,2,0,0,.1.1.2.2,19,9.1,53.8,6.9,70-10,.2-.2.3-.5.4-.7-.6-.2-.4-.5-.3-1.3,1-5.7,1.5-11.5,2.4-17.2,0-.1,0-.2.1-.3.5-5.7.7-13.3,2.4-23.5,2.8-17.2,2.6-21.2,4.6-26.3,3.7-9.4,7.6-12.7,6.4-18.7-1.2-5.9-6.5-9.7-14-15-9.9-7-17.7-9.2-17.2-11.3,0,.3,10.9-2.9,10.9-2.9,0,0,8.8,2.1,13.2.3,4.8-2,4.8-8.9,5-9.1.3-17.7.1-21,.1-21-.4-10.6-2.1-17.8-3-22.5-3.2-17.1-3.4-50.5,5-81.5,3.8-9.2,4-13.8,4.3-23,0-.8,0-1.6,0-2.3-.2-.8-.2-1.6-.2-2.4ZM33.9,305.7c0-.2,0-.5.6-.4-.2.1-.4.3-.6.4Z"
        />
      </g>
    </svg>
  );
}

export default Linke5ZeheLatSitzend1421;
