import style from "./style.scss";

function LinkeHandwurzelLatSitzend1353(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.71 348.34">
      <g id="background">
        <path
          id="background_white"
          data-name="background white"
          class="Linke_Handwurzel_lat_sitzend1353_cls4"
          d="M212.34.32c-20.4.2-40.7,0-61.1-.1-7.6,12.7-21.5,26.5-37.4,40.7-14.1,2.5-21.3,8.9-26.8,19.4l-5.9,1.6c2.2-6,2-12.1-.5-18.2-8.2-14.9-13.2-27.6-12.6-39.6.1-.8.2-1.5.3-2.3-.6,0-1.2.1-1.8.1-15.6,1-31.1,1-46.6.9h-2.8c0,3.6-.1,7.3,0,10.8l-5.9,34-2.9,15.7c-1.4,10.2.2,14.7,4.9,12.9v11.1l6.5,12.4c-1.5,5.9-1.1,11.8,1.2,17.5-.8,6.1-.7,11.9,2.4,16.7v3c-4,1.7-7,5.3-9,10.7-1.6,8.4-1.5,16.4,2.7,23.1,1.9,3.7,3.7,6.2,5.5,7.3l-1,4.9-8.4,2.2c-8.4,4-14.5,10.7-12.2,27,9.5,42.9,22.2,106.5,21.9,136.2l53.4-1h0l-2.7-78.1c-.7-11.5,2.1-19.3,7.3-24.5,3.6-7.8,6.3-15,6.7-21,4.9-7.9,5.6-14.7,3.3-20.7,4.3-6.7,1.6-9.2-4.7-9.6,1.3-4.7-2.7-5.9-9.5-5.2-1-2.8-2.4-5.2-5.5-5.3l7.1-6.5c4.4-4.1,5.4-9.5,5.2-15.2,9.6-3.1,16.9-4,21.7-9.8,1.8-3.8,1.4-7.4,0-10.9,1.8-.2,3-4.5,4.2-9.1,7.6-.1,11.6-4.6,14.1-10.9,1.8-5.1-.3-11.2-4.1-17.8,1.7-1.7,3.5-2.5,5.4-2.4,4.9.4,9.7-1.9,14.4-6.3,1.4-1.8,1.4-4.3.9-7.1.5-4.3,2.4-7.8,5.6-10.6,10.6-16.7,26.5-32,44.4-46.8,13.4-6.2,17.1-13.2,15.2-20.6,4-2.8,6.3-5.7,7.1-8.6Z"
        />
      </g>
      <g id="full_Handwurzel" data-name="full Handwurzel">
        <g id="Boneparts">
          <path
            id="boneparts26"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M76.04,348.32l-2.4-75.9c-.5-12.2,2.2-21.1,7.3-27.6,3.5-7,6-14,6.7-21,3.4-7,5.4-13.8,3-20.4,4.1-5.9,2.1-8.6-4.9-8.8-4.4.2-7.5-1.8-9.1-6.4-1-4.1-3.2-5.7-6.4-5.2-6.8,5.9-16.3,8.4-31.3,4-8.5-4.7-17.1-5.2-25.8-1.8-11.3,7-15,17.5-11.2,31.6l7.3,31.3c6.9,37.7,12.6,73.2,13.7,100.2"
          />
          <path
            id="boneparts25"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M68.84,347.22l1.4-76.6c.1-6.5.8-13,2.4-19.4,2-8.3,5-16.2,10.1-23.1,3.4-5,2.6-13,.8-21.9-1.8-6.9-5.2-10.6-10.5-10.3-15.3,3.2-28.4,2.9-38.4-2.1-6.8-1.6-13-1.6-17,5.3-2.8,4.9-2.5,13.2,0,24.1,5,40.5,6.8,76,3.9,104.1"
          />
          <path
            id="boneparts24"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M90.64,203.42c-.6-3.4-2.1-6.3-4.9-8.8,2-4.9-1.3-7.3-9.5-7.7"
          />
          <path
            id="boneparts23"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M46.64,197.52c-5.2-2.4-7.2-21.1-14.6-23.3-7.6-1.3-11.9,4.4-11.1,20.9"
          />
          <path
            id="boneparts22"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M26.44,183.02c6.6-3.4,11.9-10.3,16.7-18.4,1.9-3.1,5-5.4,8.7-5.8,2.8-.3,5.3.5,7.4,2.1,1.6,1.2,2.7,2.8,3.6,4.5,4.2,8.3,5.3,15.2,7.5,17.5l2.1-2c1.7-1.6,3.5-3,5.4-4.3,4.6-3.2,5.7-9,5.7-15.6,0-4.9-.5-9.3-1.9-13.2-1.2-3.3-3.5-6.1-6.7-7.6-2.5-1.2-4.6-1.2-6.2.8-1.4,1.7-2.9,3.4-4.7,4.6-6.6,4.5-13.8,7.3-22.7,5.7-5-.4-10.5-2.2-16.5-5-.9-.4-1.8-.7-2.8-.8-2.4-.1-3.8,1.1-3.8,4.1-.5,12.3,3.1,21,8.7,27.9,4.2,5.2,10.5,8.3,17.2,8.4,4.2.1,8.3-.5,12.5-2.1,3.1-1.2,5.8-3,8.2-5.4,3.7-3.7,10.2-10.2,14.1-14,2-2,4.5-3.5,7.2-4.4l.4-.1c3.2-1,6.5-1.9,9.8-2.6,6.1-1.5,9.4-4.7,10-9.6.3-2,0-4-.6-5.9-2.7-8.3-7.7-16.1-17.4-22.9-6.1-4-12.4-6.1-19.2-5.7-2.3.1-4.3,1.6-5.3,3.8l-4.1,9.4c-1.3,2.9-3.7,5.2-6.7,6.1-5.3,1.6-13.5,1.4-25.1,2.7-4.6,1.5-8.9,3.5-12,8.9-1.5,2.7-2.3,5.8-2.5,9-.8,12.3,2.9,23,16.9,29.9"
          />
          <path
            id="boneparts21"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M71.84,115.72c7.4-13.6,10.7-25.8,3.3-33.9-2.4-4-5.7-5.7-9.5-5.8-1.5,0-3.1-.1-4.6-.3-9.3-1.2-17.9-2.9-24.6-6.1-2.4-1.1-5.1-1.5-7.7-1.2-4.2.5-8.1,2-11.6,4.6-2.5,1.9-4,4.9-4,8.1v2.8c0,2.9.7,5.7,2.1,8.1l6.2,11.1c.7,6.4.6,12.7-.7,18.7-.8,3.5-.3,7.2,1.5,10.4,3.2,5.7,7.1,10.6,12.5,13.6,9.2,4.8,17.5,4.6,24.7-1.6,2.1-1.8,3.7-4.2,4.6-6.8l7.8-21.7h0Z"
          />
          <path
            id="boneparts20"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M61.14,75.72c-6.1,3.3-11.8,5.1-15.8,2.1-4.1-2.2-8.3-3.6-12.9-1l-6.4,1c-4,1-5.6,9.8-6.3,22-1.7,6.3-1,12.6,1.8,18.8l4.5,23.3c.3,1.8.8,3.5,1.6,5.1,2.5,5,7,8.1,12.8,9.8,12.4,1.8,19.3-3,23.6-11,4.7-7.1-.6-42.2,10.6-49.2,5.6-1.6,10.4-1.5,14.9-.4,5.1,1.2,10.5-.3,13.8-4.4.6-.8,1.2-1.6,1.7-2.4,2.1-3.3,2.8-7.2,2.3-11.1-1.1-8.4-4.8-14.3-11.4-17.2-7.9-1.9-15.5-.5-22.5,5.6l-12.3,9h0Z"
          />
          <path
            id="boneparts19"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M39.44,110.42c-4,8-8.9,15-15.8,19.8v10.3c.9,4.5,1.8,9,2.8,13.8,1.3,6.7,7,10.4,15.8,11.8,8.4-.3,15.1-5.4,20.8-13,4.9-8.3,7.1-18.2,7.1-29.2,0-8.7-2.8-14.1-8.2-16.3-7.7-2.8-15.2-2.1-22.5,2.8h0Z"
          />
          <path
            id="boneparts18"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M122.94,109.72c-2.3-6.6-5.7-11.2-11.1-12.8-2.3-.7-4.6-1.7-6.8-2.8-8.3-4.2-15.5-8.7-18.8-13.8-5.2-4.4-11.2-5-17.8-2.4-6.3,3.3-12.5,5.9-18.5,6-3,.1-5.7,2-6.2,4.9-.4,2.3.3,5.4,2.4,9.6,7.2,12.7,17.7,22,31.1,28.4,11.2,5.6,23.2,6.5,35.5,4.1,2-.4,4-1.1,5.7-2.4,2.1-1.5,3.5-3.6,4.4-6,1.7-4.1,1.6-8.7.1-12.8h0Z"
          />
          <path
            id="boneparts17"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M151.64.22c-.1.2-.3.5-.4.7-6.9,10.1-18.6,22.9-35.2,38.4-1.6,1.5-3.6,2.5-5.7,2.9-8.2,1.9-15,6.2-19.6,14-7.8,7.3-9.5,14.8-6.8,22.3,1.8,7,5.5,13,11.8,17.8h0c7.5,7.1,15.2,9.3,22.9,6.9.6-.2,1.1-.7,1.4-1.2.8-1.3,2.3-1.7,4.1-1.9,5.3,1.3,9.9-1.5,14.3-5.1,1.1-.9,1.6-2.4,1.5-3.8-.4-5.3,1.6-9.9,5.2-13.9,11.9-18,26.5-32.9,42.6-46.1,1.5-1.2,3.1-2.2,4.8-3,9.9-4.2,12.8-10.1,12.9-16.6,0-1.3.5-2.6,1.5-3.6,2.9-3.1,4.5-5.6,5.3-7.8"
          />
          <path
            id="boneparts16"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M90.64,56.22c-3.7,6.6-4.1,13-.9,19.1.9,1.7,2.1,3.2,3.5,4.4,6.5,5.7,13.7,9.3,21.2,12,3.1,1.1,6.3,1.6,9.5,1.3,3.9-.4,7-1.9,9.7-3.9"
          />
          <path
            id="boneparts15"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M138.84,82.32c4.6-19.1,31.4-46.2,36-61.8"
          />
          <path
            id="boneparts14"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M161.94,5.62l-35.9,33.3c-5,4.3-10.1,7.5-15.4,8.1-6.6,1.7-12.7,4.9-17.8,11.1"
          />
          <path
            id="boneparts13"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M195.44,18.22c1.2-3,4.1-4.7,6.4-6.7,2.8-2.5,4.4-4.9,5-8.6"
          />
          <path
            id="boneparts12"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M20.04,2.92c-.2,1.1-.5,2.2-.7,3.2-1.1,4.5-2.1,9-3,13.6l-8,43c-1.6,11.7.3,15.5,4.8,13.7,4.5-6.3,10.8-9.3,18.7-9.4l21.9,4.9h0c6,.9,8.7-2.2,8.9-8.3.1-3.7-.8-7.3-2.3-10.7-6.3-13.8-10-30.5-10.9-50"
          />
          <path
            id="boneparts11"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M59.64,4.22c.8,9.8,1.2,21.5,6,30.3,2.8,5.2,6.2,10.2,6.9,16.2.7,6.5-4.6,12.2-2.9,18.7"
          />
          <path
            id="boneparts10"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M49.44,73.82c-7.4,2.5-15.4,1.5-21.4-3.5-6-4-9-11-7-19,5-15,6-30,6.3-46.4"
          />
          <path
            id="boneparts9"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M67.94,4.32c.1,15,7.1,28,13.1,41,3,9,1,19-3,28-7,2-14,2-20.4-1.7"
          />
          <path
            id="boneparts8"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M31.84,66.82c-5.8-3.5-5.8-10.5-2.8-15.5,8-14,11-30,12.3-46"
          />
          <path
            id="boneparts7"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M17.04,5.52c-.1,3.7-.1,7.3-.1,11"
          />
          <path
            id="boneparts6"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M59.14,71.12c3.9,1.2,5.9-2.8,6.9-5.8,0-14-3-27-9-40-2-7-4-14-4-21.4"
          />
          <path
            id="boneparts5"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M11.14,47.72c1.3,12.7,4.5,21.4,12.2,20.6"
          />
          <path
            id="boneparts4"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M27.44,67.22c-2.2-1.7-4.2-3-6.2-3.6-3.4-1-7,.9-8.2,4.4-.9,2.8-.9,5.5.2,8.2"
          />
          <path
            id="boneparts3"
            class="Linke_Handwurzel_lat_sitzend1353_cls5"
            d="M109.44,131.32c2.1-9.6-1.5-16.5-10.2-20.9-7.3-3.7-14-4.1-19.9,1.8-5.1,5-7,12.4-7.2,21.1,0,1.7-.2,3.4-.2,5.1-.4,6.8,2,11.2,7.4,12.9l11.7,2.5c4.2.9,8.5-.8,10.9-4.4,2.7-4.1,5.2-10.4,7.5-18.1h0Z"
          />
          <path
            id="boneparts2"
            class="Linke_Handwurzel_lat_sitzend1353_cls5"
            d="M29.04,108.72c3.2,6.2,7.8,8.2,13.3,7.6,2.7-.3,5.1-1.8,6.5-4.2,3-5,4.1-11.9,5.2-18.9.8-9.7-2.2-15.4-7.4-18.7-9.1-4.5-17-5.8-23.4-3.1-4.9,2.1-5.8,7.2-5.1,13.5.2,1.8.8,3.6,1.6,5.2l9.3,18.6h0Z"
          />
          <path
            id="boneparts1"
            class="Linke_Handwurzel_lat_sitzend1353_cls6"
            d="M32.74,193.52c-5.5-4.4-12-7.4-19.6-8.3"
          />
        </g>
      </g>
      <g id="handwurzel_highlights" data-name="handwurzel highlights">
        <g id="Handwurzel_Highligts" data-name="Handwurzel Highligts">
          <path
            id="hwhighlihgt7"
            class="HWK1353Fill"
            style={{
              fill: props.colors.HWK1353Fill,
              stroke: props.colors.HWK1353Fill,
            }}
            d="M33.74,184.05c6.6-3.4,4.6-11.33,9.4-19.43,1.9-3.1,5-5.4,8.7-5.8,2.8-.3,5.3.5,7.4,2.1,1.6,1.2,2.7,2.8,3.6,4.5,4.2,8.3,5.3,15.2,7.5,17.5l2.1-2c1.7-1.6,3.5-3,5.4-4.3,4.6-3.2,5.7-9,5.7-15.6,0-4.9-.5-9.3-1.9-13.2-1.2-3.3-3.5-6.1-6.7-7.6-2.5-1.2-4.6-1.2-6.2.8-1.4,1.7-2.9,3.4-4.7,4.6-6.6,4.5-13.8,7.3-22.7,5.7-5-.4-10.5-2.2-16.5-5-.9-.4-1.8-.7-2.8-.8-2.4-.1-3.8,1.1-3.8,4.1-.5,12.3,3.1,21,8.7,27.9,4.2,5.2,9.99,9.76,16.69,10.69,5.38,1.41,13.06,1.12,16.26.22,3.1-1.2,8.04-3.11,10.44-5.51,3.7-3.7,4.7-14.7,8.6-18.5,2-2,4.5-3.5,7.2-4.4l.4-.1c3.2-1,6.5-1.9,9.8-2.6,6.1-1.5,9.4-4.7,10-9.6.3-2,0-4-.6-5.9-2.7-8.3-7.7-16.1-17.4-22.9-6.1-4-12.4-6.1-19.2-5.7-2.3.1-4.3,1.6-5.3,3.8l-4.1,9.4c-1.3,2.9-3.7,5.2-6.7,6.1-5.3,1.6-13.5,1.4-25.1,2.7-4.6,1.5-8.9,3.5-12,8.9-1.5,2.7-2.3,5.8-2.5,9-.8,12.3,2.55,23.2,16.55,30.1"
          />
          <path
            id="hwhighlihgt6"
            class="HWK1353Fill"
            style={{
              fill: props.colors.HWK1353Fill,
              stroke: props.colors.HWK1353Fill,
            }}
            d="M72.35,115.72c7.4-13.6,10.7-25.8,3.3-33.9-2.4-4-5.7-5.7-9.5-5.8-1.5,0-3.1-.1-4.6-.3-9.3-1.2-17.9-2.9-24.6-6.1-2.4-1.1-5.1-1.5-7.7-1.2-4.2.5-8.1,2-11.6,4.6-2.5,1.9-4,4.9-4,8.1v2.8c0,2.9.7,5.7,2.1,8.1l6.2,11.1c.7,6.4.6,12.7-.7,18.7-.8,3.5-.3,7.2,1.5,10.4,3.2,5.7,7.1,10.6,12.5,13.6,9.2,4.8,17.5,4.6,24.7-1.6,2.1-1.8,3.7-4.2,4.6-6.8l7.8-21.7h0Z"
          />
          <path
            id="hwhighlihgt5"
            class="HWK1353Fill"
            style={{
              fill: props.colors.HWK1353Fill,
              stroke: props.colors.HWK1353Fill,
            }}
            d="M61.65,75.72c-6.1,3.3-11.8,5.1-15.8,2.1-4.1-2.2-8.3-3.6-12.9-1l-6.4,1c-4,1-5.6,9.8-6.3,22-1.7,6.3-1,12.6,1.8,18.8l4.5,23.3c.3,1.8.8,3.5,1.6,5.1,2.5,5,7,8.1,12.8,9.8,12.4,1.8,19.3-3,23.6-11,4.7-7.1-.6-42.2,10.6-49.2,5.6-1.6,10.4-1.5,14.9-.4,5.1,1.2,10.5-.3,13.8-4.4.6-.8,1.2-1.6,1.7-2.4,2.1-3.3,2.8-7.2,2.3-11.1-1.1-8.4-4.8-14.3-11.4-17.2-7.9-1.9-15.5-.5-22.5,5.6l-12.3,9h0Z"
          />
          <path
            id="hwhighlihgt4"
            class="HWK1353Fill"
            style={{
              fill: props.colors.HWK1353Fill,
              stroke: props.colors.HWK1353Fill,
            }}
            d="M39.95,110.42c-4,8-8.9,15-15.8,19.8v10.3c.9,4.5,1.8,9,2.8,13.8,1.3,6.7,7,10.4,15.8,11.8,8.4-.3,15.1-5.4,20.8-13,4.9-8.3,7.1-18.2,7.1-29.2,0-8.7-2.8-14.1-8.2-16.3-7.7-2.8-15.2-2.1-22.5,2.8h0Z"
          />
          <path
            id="hwhighlihgt3"
            class="HWK1353Fill"
            style={{
              fill: props.colors.HWK1353Fill,
              stroke: props.colors.HWK1353Fill,
            }}
            d="M123.45,109.72c-2.3-6.6-5.7-11.2-11.1-12.8-2.3-.7-4.6-1.7-6.8-2.8-8.3-4.2-15.5-8.7-18.8-13.8-5.2-4.4-11.2-5-17.8-2.4-6.3,3.3-12.5,5.9-18.5,6-3,.1-5.7,2-6.2,4.9-.4,2.3.3,5.4,2.4,9.6,7.2,12.7,17.7,22,31.1,28.4,11.2,5.6,23.2,6.5,35.5,4.1,2-.4,4-1.1,5.7-2.4,2.1-1.5,3.5-3.6,4.4-6,1.7-4.1,1.6-8.7.1-12.8h0Z"
          />
          <path
            id="hwhighlihgt2"
            class="HWK1353Fill"
            style={{
              fill: props.colors.HWK1353Fill,
              stroke: props.colors.HWK1353Fill,
            }}
            d="M109.95,131.32c2.1-9.6-1.5-16.5-10.2-20.9-7.3-3.7-14-4.1-19.9,1.8-5.1,5-7,12.4-7.2,21.1,0,1.7-.2,3.4-.2,5.1-.4,6.8,2,11.2,7.4,12.9l11.7,2.5c4.2.9,8.5-.8,10.9-4.4,2.7-4.1,5.2-10.4,7.5-18.1h0Z"
          />
          <path
            id="hwhighlihgt1"
            class="HWK1353Fill"
            style={{
              fill: props.colors.HWK1353Fill,
              stroke: props.colors.HWK1353Fill,
            }}
            d="M29.55,108.72c3.2,6.2,7.8,8.2,13.3,7.6,2.7-.3,5.1-1.8,6.5-4.2,3-5,4.1-11.9,5.2-18.9.8-9.7-2.2-15.4-7.4-18.7-9.1-4.5-17-5.8-23.4-3.1-4.9,2.1-5.8,7.2-5.1,13.5.2,1.8.8,3.6,1.6,5.2l9.3,18.6h0Z"
          />
        </g>
        <g id="radius_Highlights" data-name="radius Highlights">
          <path
            id="boneparts26-2"
            data-name="boneparts26"
            class="Radius1353Fill"
            style={{
              fill: props.colors.Radius1353Fill,
              stroke: props.colors.Radius1353Fill,
            }}
            d="M75.82,348.32l-2.4-75.9c-.5-12.2,2.2-21.1,7.3-27.6,3.5-7,6-14,6.7-21,3.4-7,5.4-13.8,3-20.4,4.1-5.9,2.1-8.6-4.9-8.8-4.4.2-7.5-1.8-9.1-6.4-1-4.1-3.2-5.7-6.4-5.2-6.8,5.9-16.3,8.4-31.3,4-8.5-4.7-17.1-5.2-25.8-1.8-11.3,7-15,17.5-11.2,31.6l7.3,31.3c6.9,37.7,12.6,73.2,13.7,100.2"
          />
          <path
            id="boneparts24-2"
            data-name="boneparts24"
            class="Radius1353Fill"
            style={{
              fill: props.colors.Radius1353Fill,
              stroke: props.colors.Radius1353Fill,
            }}
            d="M85.52,194.62c-13.4-15.23-10.63.34,0,0,4.13-4.17-6.22-9.52-9.92-7.7"
          />
        </g>
        <path
          id="ulna_Highlight"
          data-name="ulna Highlight"
          class="Ulna1353Fill"
          style={{
            fill: props.colors.Ulna1353Fill,
            stroke: props.colors.Ulna1353Fill,
          }}
          d="M68.6,348.32l1.42-77.7c.1-6.5.8-13,2.4-19.4,2-8.3,5-16.2,10.1-23.1,3.4-5,2.6-13,.8-21.9-1.8-6.9-5.2-10.6-10.5-10.3-15.3,3.2-28.4,2.9-38.4-2.1-6.8-1.6-13-1.6-17,5.3-2.8,4.9-2.5,13.2,0,24.1,7.8,40.15,3.25,99.46,5.71,124.54"
        />
      </g>
    </svg>
  );
}

export default LinkeHandwurzelLatSitzend1353;
