import style from "./style.scss";

function RechtesKniegelenkLatStehend2326(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.4 637.92">
      <g id="underlay">
        <ellipse
          id="joint_gap_underlay"
          data-name="joint gap underlay"
          class="Kniegelenkspalt2326Fill"
          style={{
            fill: props.colors.Kniegelenkspalt2326Fill,
            stroke: props.colors.Kniegelenkspalt2326Fill,
          }}
          cx="134.01"
          cy="273.32"
          rx="52.63"
          ry="123.57"
          transform="translate(-168.57 240.06) rotate(-57.61)"
        />
        <ellipse
          id="retropattial_joint_underlay"
          data-name="retropattial joint underlay"
          class="Retropatellar2326Fill"
          style={{
            fill: props.colors.Retropatellar2326Fill,
            stroke: props.colors.Retropatellar2326Fill,
          }}
          cx="94.11"
          cy="171.77"
          rx="61.05"
          ry="30.75"
          transform="translate(-98.56 206.96) rotate(-71.63)"
        />
      </g>
      <g id="knee_lat" data-name="knee lat">
        <g id="background_layer" data-name="background layer">
          <path
            id="background_knee"
            data-name="background knee"
            class="kniegelenk_lat_rechts_stehend2326_cls2"
            d="M1.37,618.33l109.3,19.2,31.5-133.4,7.8-23.2,2.6,26.1-17.3,120.8,58.3,3.5c-7.5-27.8-9.6-59.2-8.1-93.1,1.6-40,9.3-68.1,22.3-86,10.9-16.1,15.7-38.5,15.1-66.4v-21.8s-7.9-13.7-7.9-13.7l-21-26.3c29.5-4.5,46.5-20.6,47.7-51.5-1.3-31.3,2.9-50.2,11.1-60,44.5-40.6,95.2-74.2,151-102.1l57-28L419.77,1.33l-152.1,70.2-57.7,25.5-70.6,19.7c-21.1,14-37.4,32.5-47.5,56.8-6.6,25.2-7.6,50.5-.5,75.7,5.9,20.2,21,40.3,43.7,60.3-7.3,2.8-16.3-.3-25.9-5.9-20.3-4.6-37.1-.3-49.6,15.3l-9.1,19.9c-5.9,5.7-8.2,12.6-5.8,20.7,3.8,9.5,5.4,17.3-1.4,18.6-6.4-1-11,6.4-14.6,18.9-10.1,7.4-14.5,17.3-13.1,29.7l-.1,30.7c9.3,39.1-.3,97.8-14.1,160.9h0Z"
          />
          <path
            id="background_partella"
            data-name="background partella"
            class="kniegelenk_lat_rechts_stehend2326_cls2"
            d="M36.87,223.03l32.7-15.7c6.8-6.9,12.3-20.7,16.5-41.1l16.4-27.5c2.9-7.2,3.1-13.9,0-19.9-5.1-2.6-20.5,2.7-37.4,9.2-12.7,2.4-22.3,7.2-26.5,16.7-7.7,13.4-13.2,31.3-16.5,53.5-.5,18.8,1.6,32.4,14.8,24.8h0Z"
          />
        </g>
        <path
          id="fibula"
          class="kniegelenk_lat_rechts_stehend2326_cls6"
          d="M135.07,628.63c.9-4.9,2.2-12.2,3.6-21,1.6-9.7,2.1-14.5,4.6-32.8,2.9-21.3,2.9-20.6,4.6-33.7,2.3-18,3.4-27,3.6-31,.4-9.6-.6-20.1-2.7-41-1.9-19.6-2.9-29.4-3.6-34.6-2-14.9-3.1-17.6-1.8-23.7,3.1-14.8,14.6-23.6,17.3-25.5,2-1.4,9.9-6.8,21-8.2,4.5-.6,4.7.1,22.8,1.8,13,1.2,14.5,1,16.4,2.7,1.4,1.2,4.2,4.6.9,27.3-2.3,15.9-3.4,23.9-8.2,33.7-4.8,9.7-7.2,9.4-12.8,20-5.6,10.6-7.6,19.5-10,30.1-3.9,17.3-4.7,30.6-5.5,46.5-.9,17.3-1.6,30.1,0,47.4,1.6,18,5,33.2,8.2,44.7"
        />
        <g id="femur">
          <path
            id="femur_1"
            data-name="femur 1"
            class="kniegelenk_lat_rechts_stehend2326_cls6"
            d="M419.77,1.33c-34.8,18-63.4,31.3-83,40.2-40.2,18.1-42.3,17-72.9,31.9-22.8,11.2-31.7,16.7-51,22.8-9.1,2.9-13.6,3.7-41.9,11.8-18.4,5.3-27.6,7.9-30.1,9.1-26,12.3-39,35.7-42.8,42.8-2.7,5-11.9,23-12.8,48.3-1.4,40.1,19,68,25.5,76.6,13.4,17.7,27.6,26.2,31,28.2,4.6,2.6,11.7,6.7,21.9,9.1,3.5.8,16.8,3.7,33.7,0,9.7-2.1,21.9-4.8,31.9-15.5,2.1-2.3,9.4-10.4,11.9-22.8,1.5-7.2.4-11.2,0-20,0,0-.7-14.7,2.7-32.8,2.1-11.1,8.9-24.3,48.3-53.8,30.2-22.6,54.9-36.5,56.5-37.4,27.7-15.4,65.4-35.4,112.1-57.4"
          />
          <path
            id="femur_2"
            data-name="femur 2"
            class="kniegelenk_lat_rechts_stehend2326_cls6"
            d="M163.67,322.13c-17.2-15-35.8-36.5-42.8-47.4-5.5-8.6-12.8-20.3-18.2-37.4-3.6-11.2-9-28.8-5.5-51,1.4-9.3,4.4-27.6,19.1-42.8,11.5-11.8,24.9-16.5,30.1-18.2,14.1-4.8,20.1-1.4,33.7-6.4,13.2-4.9,26.6-17.9,32.8-22.8"
          />
        </g>
        <g id="patella">
          <path
            id="patella_2"
            data-name="patella 2"
            class="kniegelenk_lat_rechts_stehend2326_cls6"
            d="M28.77,225.53h0c3.3,1.5,5.3-1.5,20-8.3,13.8-6.2,17-5.9,21.9-10.9,3.8-4,5.4-7.9,8.2-14.6,4.9-11.9,2.7-14.6,7.3-25.5,1.8-4.2,4.2-8.3,9.1-16.4,5.3-8.9,8.2-12.5,9.1-19.1.5-3.6,1.3-9.2-1.8-11.9-3.5-3-9.5,0-22.8,4.6-23.6,8.1-25.2,5.7-31.9,10.9-7.4,5.7-10.4,12.3-15.5,23.7-2.8,6.2-8.2,19.8-9.1,38.3-.5,10.9-1.2,26.3,5.5,29.2h0Z"
          />
          <polyline
            id="patella_2-2"
            data-name="patella 2"
            class="kniegelenk_lat_rechts_stehend2326_cls6"
            points="102.57 118.93 88.87 145.33 80.67 162.63 76.17 181.83 70.67 206.43"
          />
        </g>
        <g id="tibia">
          <path
            id="tibia_1"
            data-name="tibia 1"
            class="kniegelenk_lat_rechts_stehend2326_cls6"
            d="M1.47,618.43c4.9-22.3,8.5-41.2,10.9-55.6,2.9-17,4.4-28.4,5.5-39.2,2-19.8,1.8-30.1,1.8-32.8-.2-11.8-1.3-22.3-1.8-26.4-1.2-11.2-.4-22.5-1.8-33.7-.3-2.1-.9-6.8,0-12.8.3-2.6,1-7,3.6-11.6,3.1-5.7,5.2-4.7,9.1-10.3,5.2-7.5,3.2-11.6,8.2-15.5,4.4-3.4,7.1-1.1,9.6-3.9,5.3-5.9-6.2-17.2-1.4-29.8,1.8-4.8,3.7-4.1,8.2-12.8,3.7-7.2,2.9-9,5.5-13.7,5.3-9.7,15.3-13.9,19.1-15.5,2.4-1,12.7-5.1,25.5-2.7,11.6,2.1,12.1,7.2,22.8,8.2,9.3.9,10.3-2.9,21-2.7,9.3.2,16.1,3.1,23.7,6.4,11.6,5,20.4,8.8,23.7,17.3,2.1,5.6-.2,7.9,2.7,11.9,4.6,6.4,12.4,2.8,19.1,8.2,8.4,6.8,6.9,21.4,6.4,27.3-1,13.2-7.8,22.4-10.9,26.4-6.4,8.3-10.3,8.6-21.9,19.1-9.6,8.8-15.8,16.7-19.1,21-3.7,4.8-14.2,18.9-22.8,40.1-2.2,5.5-4.4,11.7-10.9,39.2-6.2,25.7-8.9,39.7-12.8,57.4-2.9,13-7.4,32.1-13.7,55.6"
          />
          <path
            id="tibia_2"
            data-name="tibia 2"
            class="kniegelenk_lat_rechts_stehend2326_cls5"
            d="M164.57,334.93c-43.9,0-79.4-11-79.4-24.6"
          />
          <path
            id="tibia_3"
            data-name="tibia 3"
            class="kniegelenk_lat_rechts_stehend2326_cls5"
            d="M28.67,489.93c18.6-11,39.3-79.9,23.7-109.4"
          />
          <path
            id="tibia_4"
            data-name="tibia 4"
            class="kniegelenk_lat_rechts_stehend2326_cls5"
            d="M40.57,435.13c-4,0-7.3,18-7.3,40.1"
          />
          <path
            id="tibia_5"
            data-name="tibia 5"
            class="kniegelenk_lat_rechts_stehend2326_cls5"
            d="M47.87,399.63c-4,0-7.3,15.9-7.3,35.5"
          />
          <polyline
            id="tibia_6"
            data-name="tibia 6"
            class="kniegelenk_lat_rechts_stehend2326_cls6"
            points="193.97 324.03 216.47 351.33 222.77 364.03 218.27 380.53 209.17 398.73 193.97 410.13 174.57 424.23 159.97 450.63 152.57 475.23"
          />
          <path
            id="tibia_7"
            data-name="tibia 7"
            class="kniegelenk_lat_rechts_stehend2326_cls6"
            d="M15.57,424.33c7.3,0,13.2-12.7,13.2-28.3"
          />
        </g>
      </g>
      <g id="overlay">
        <path
          id="femurcondylen_overlay"
          data-name="femurcondylen overlay"
          class="CondylenRundungen2326Fill"
          style={{
            fill: props.colors.CondylenRundungen2326Fill,
            stroke: props.colors.CondylenRundungen2326Fill,
          }}
          d="M242.69,238.22c-28.6-1.96-56.63-3.49-78.13,0-27.24,8.27-44.45,25.01-47.29,53.89,17.64,22.11,41.83,35.46,80.09,30.02,20.02-.35,35.92-10.97,43.64-41.96-.41-15,.2-28.93,1.69-41.96Z"
        />
      </g>
    </svg>
  );
}

export default RechtesKniegelenkLatStehend2326;
