import { MakePutObject } from "../AuthHeader";
import { REST_URL, MakeUrl } from "../FetchHooks/Helpers/FetchWrapper";

export default async function DeleteImage(keycloak, imgId) {
  console.log(imgId);
  const url = MakeUrl(`${REST_URL}/ris/studies/series/images/${imgId}`);
  if (imgId <= 0) return;
  let putObj = {
    deltag: "t",
  };
  const result = await fetch(url.href, MakePutObject(keycloak, putObj));
  if (result.ok && result.status === 200) {
    const resultData = await result.json();
    return resultData;
  } else {
    throw new Error(
      `Unable to delete image ${result.status}: ${result.statusText} - ${result.text}`
    );
  }
}
