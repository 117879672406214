import style from "./style.scss";

function LinkeGroszeheLinks(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178 491.57">
      <g id="big_toe_recording" data-name="big toe recording">
        <g id="big_toe" data-name="big toe">
          <path
            id="big_toe_1"
            data-name="big toe 1"
            class="a1401_Linke_Großzehe_1Zehe_dp_cls_2"
            style={{
              fill: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_2,
              stroke: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_2,
            }}
            d="M121.07,113.53h0c4.61,11.94,3.31,18.33-3.19,19.86l-35.82,1.66-28.97-3.43c-5.32-5.56-7.09-13.24-3.67-23.88,11.47-22.7,2.36-34.05-12.65-41.97-4.37-7.21-3.19-13.12,5.32-17.62-2.96-13.36,5.08-13.36,13.71-12.53,11.11-5.08,20.22.59,28.49,10.17,7.45,4.97,7.33,9.34,3.67,13.6-2.13,22.35,8.51,35,19.03,47.65,3.9-7.8,8.51-6.86,14.19,6.27v.24h-.12v-.02Z"
          />
          <path
            id="big_toe_2"
            data-name="big toe 2"
            class="a1401_Linke_Großzehe_1Zehe_dp_cls_2"
            style={{
              fill: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_2,
              stroke: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_2,
            }}
            d="M135.02,265.81h0c-11-33.7-15.84-68.93-5.32-107.83-.83-19.51-7.57-30.03-26.37-22.7l-30.03-2.25c-19.74-1.77-25.54,7.09-13.71,29.56,18.21,35.47,10.29,65.26.59,94.82-8.04,28.38-4.85,40.79,14.19,31.21l40.55,9.1c22.11,3.19,29.56-6.74,19.98-31.8h.12v-.12h0Z"
          />
        </g>
        <g id="metatarsus">
          <path
            id="metatarsus_1"
            data-name="metatarsus 1"
            class="a1401_Linke_Großzehe_1Zehe_dp_cls_1"
            style={{
              fill: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_1,
              stroke: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_1,
            }}
            d="M40.19,484.42c17.7-36.2,19.08-79.95,3.2-131.59-24-20.22-23.05-33.58,1.54-40.32,3.31-9.93,8.04-15.61,14.19-15.84,30.86-12.18,52.26,3.67,67.98,5.67,22.58-1.89,21.4,22.35,14.19,53.32h.12c-14.98,44.34-25.17,89.4-29.23,135.41"
          />
          <path
            id="metatarsus_2"
            data-name="metatarsus 2"
            class="a1401_Linke_Großzehe_1Zehe_dp_cls_1"
            style={{
              fill: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_1,
              stroke: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_1,
            }}
            d="M58.88,296.67c.71,4.37,2.84,7.09,6.38,7.92,23.53,12.41,46.23,15.49,67.51,5.67,6.62-2.13,6.62-4.02,0-5.67"
          />
        </g>
        <path
          id="outline"
          class="a1401_Linke_Großzehe_1Zehe_dp_cls_3"
          style={{
            fill: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_3,
            stroke: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_3,
          }}
          d="M13.22,292.99c-2.11-40.36-1.74-79.03,6.69-112.18,6.48-25.49,3.94-52.5-8.2-75.83-3.27-6.28-5.97-12.43-8.05-18.42C-7.72,53.7,12.95,18.38,45.09,5.07,69.17-4.9,88.69.77,113.95,28.65c20.95,24.37,36.4,50.92,46.48,79.6,5.41,15.39,8.42,31.51,9.36,47.79l7.71,133.41"
        />
      </g>
      <g id="overlays">
        <g
          id="big_toe_overlay"
          data-name="big toe overlay"
          class="a1401_Linke_Großzehe_1Zehe_dp_cls_4"
          style={{
            fill: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_4,
            stroke: props.colors.a1401_Linke_Großzehe_1Zehe_dp_cls_4,
          }}
        >
          <path
            id="big_toe_overlay_1"
            data-name="big toe overlay 1"
            class="a1401_phalangenFill"
            style={{
              fill: props.colors.a1401_phalangenFill,
              stroke: props.colors.a1401_phalangenFill,
            }}
            d="M121.35,113.84h0c4.61,11.94,3.31,18.33-3.19,19.86l-35.82,1.66-28.97-3.43c-5.32-5.56-7.09-13.24-3.67-23.88,11.47-22.7,2.36-34.05-12.65-41.97-4.37-7.21-3.19-13.12,5.32-17.62-2.96-13.36,5.08-13.36,13.71-12.53,11.11-5.08,20.22.59,28.49,10.17,7.45,4.97,7.33,9.34,3.67,13.6-2.13,22.35,8.51,35,19.03,47.65,3.9-7.8,8.51-6.86,14.19,6.27v.24h-.12v-.02Z"
          />
          <path
            id="big_toe_overlay_2"
            data-name="big toe overlay 2"
            class="a1401_phalangenFill"
            style={{
              fill: props.colors.a1401_phalangenFill,
              stroke: props.colors.a1401_phalangenFill,
            }}
            d="M135.3,266.12h0c-11-33.7-15.84-68.93-5.32-107.83-.83-19.51-7.57-30.03-26.37-22.7l-30.03-2.25c-19.74-1.77-25.54,7.09-13.71,29.56,18.21,35.47,10.29,65.26.59,94.82-8.04,28.38-4.85,40.79,14.19,31.21l40.55,9.1c22.11,3.19,29.56-6.74,19.98-31.8h.12v-.12h0Z"
          />
        </g>
        <path
          id="basis_overlay"
          data-name="basis overlay"
          class="a1401_basisFill"
          style={{
            fill: props.colors.a1401_basisFill,
            stroke: props.colors.a1401_basisFill,
          }}
          d="M74.65,288.93c-18.62,9.4-21.06-2.8-16.27-24.66,23.74-4.29,49.14-3.75,75.88.71,5.51,13.79,6.65,24.04.91,28.71-5,4.57-13.75,4.66-23.49,3.56l-37.03-8.31Z"
        />
        <path
          id="capcut_overlay"
          data-name="capcut overlay"
          class="a1401_caputFill"
          style={{
            fill: props.colors.a1401_caputFill,
            stroke: props.colors.a1401_caputFill,
          }}
          d="M59.12,296.67c11.4-5.07,24.65-5.47,39.19-2.6l28.79,8.27c8.25-1.3,14.92,1.17,17.34,13.74,2.71,5.93,1.24,16.32-.53,27.04-34.6,10.53-68.23,14.55-100.52,9.71-22.94-19.46-22.66-32.86,1.01-40.17,4.63-10.42,9.54-15.64,14.72-15.99Z"
        />
        <g id="joints" class="1401_Linke_Großzehe_(1.Zehe)_d.p_cls-4">
          <ellipse
            id="joint_1"
            data-name="joint 1"
            class="a1401_gelenkeFill"
            style={{
              fill: props.colors.a1401_gelenkeFill,
              stroke: props.colors.a1401_gelenkeFill,
            }}
            cx="87.97"
            cy="131.94"
            rx="40.31"
            ry="13.92"
          />
          <ellipse
            id="joint_2"
            data-name="joint 2"
            class="a1401_gelenkeFill"
            style={{
              fill: props.colors.a1401_gelenkeFill,
              stroke: props.colors.a1401_gelenkeFill,
            }}
            cx="98.84"
            cy="293.97"
            rx="51.17"
            ry="16.03"
          />
        </g>
      </g>
    </svg>
  );
}

export default LinkeGroszeheLinks;
