import style from "./style.scss";

export default function RechteHufteAbduktionVdOblLiegend2409(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 338.31 255.9">
      <g id="BG">
        <g id="bg">
          <path
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls3"
            d="M220.42,89.94c-2.25-5.53-6.53-10-11.96-12.47-16.82-7.64-31.15-9.7-46.13-3.71-.62.9-5.79-3.58-8-8-2.21-4.42,0-5.72,1.63-14.42,1.38-7.39-5.08-15.22-6.43-16.43-.14-.13-.27-.27-.38-.43-.15-.22-.29-.44-.43-.66-.8-1.22,0-2.86,1.47-2.97,4.65-.35,9.3-.7,13.96-1.06,21.62-1.66,44.16-3.64,65.64.37.65.12,1.2.57,1.43,1.18.72,1.85,1.48,3.33,2.1,4.41.41.71,3.3,5.63,8.77,9.27,1.34.89,2.19,1.28,3.13,1.14,1.83-.27,2.08-2.21,4.27-3.7,1.82-1.24,4.42-1.79,6.55-1.14,2.56.77,4.49,3.27,5.13,5.7,1.84,6.98-7.58,11.08-5.98,18.52.69,3.21,3.09,4.77,2.56,8.55-.29,2.07-1.15,2.77-.85,4.84.29,2.03,1.33,2.85,1.4,5.36.04,1.51-.33,1.43-.15,2.37.31,1.61,1.49,2.33,4.17,4.8.9.83,1.73,1.73,2.63,2.55,10.07,9.21,14.87,14.06,18.5,17.37,7.41,6.75,7.53,6.88,12.49,9.17.69.32,1.13,1.02,1.12,1.78-.36,23.81-.7,47.65,2.26,71.33.04.3.1.58.18.86-3.22,2.82-8.21,6.45-14.17,8.23-14.42,4.31-28.06-4.18-39-11-9.33-5.81-20.46-12.75-26-26-5.07-12.15-6.1-13.93-7.88-24.27-.08-.46.02-.95.27-1.35,10.28-16.43,15.03-32.26,7.7-50.21Z"
          />
          <path
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls3"
            d="M338.31,96.15l-11.95,5.78c-2.03-5-6.27-16.66-10.34-21.09-3.01-3.28-3.91-2.87-11-8-5.41-3.92-11.36-8.27-17-15-3.3-3.94-5.92-7.06-8-12-4.31-10.22-1.05-15.69-6-21-2.85-3.06-10.44-8.16-15.02-9.25-3.12-.78-4.88.21-7.38,1.86-3.58,2.37-7.29,2.59-11.53,2.28L252.41,0"
          />
          <path
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls3"
            d="M.66,213.66c11.16-23.95,36.23-75.79,39.87-86.44,3.05-8.92,4.65-13.58,5.77-20.22,2.05-12.14-.44-13.49,2.29-19.28,2.79-5.95,5.75-5.24,16.01-15.22,9.37-9.11,8.49-11.25,14.6-15.12,2.33-1.47,10.76-6.82,20.89-5.61,0,0,4.38.53,19.62,10.39,2.86,1.85,5.49,4.04,7.94,6.4,3.45,3.32,6.38,4.16,8.53,4.24,8.62,4.35,17.12,4.84,25.45,0,6.96.27,9.92-7.15,17.98-6.84,4.7.17,8.02,2.88,9.86,2.56.76-.13,1.51-.25,2.27-.14,3.94.56,9.61,1.95,15.48,5.51,11.06,6.72,15.43,16.71,16.9,20.16,1.73,4.12,6.87,16.25,1.93,29.64-4.59,12.45-15.01,18.17-21.95,21.98-5.7,3.13-12.58,6.91-21.04,5.82-7.6-.97-8.09-4.8-15.98-6.04-6.35-1.76-13.97-6.75-25.22-4.46-.6-.1-14.65,2.4-23.85,11.66-5.61,5.65-6.88,12.81-7.46,16.08-1.39,7.85,1.01,10.1-.4,15.62-1.69,6.64-30.99,52.67-45,71.41"
          />
        </g>
      </g>
      <g id="Underlays">
        <path
          id="hipjoint"
          class="Hipjoint2409Fill"
          style={{
            fill: props.colors.Hipjoint2409Fill,
            stroke: props.colors.Hipjoint2409Fill,
          }}
          d="M159.9,72.11c-2.84-.84-5.7-3.54-5.27-5.7.21-1.05,1.16-1.81,4.13-3.13,5.59-2.49,10.24-3.36,10.54-3.42,7.18-1.51,10.77-2.26,14.25-2.28,5.75-.02,9.78,1.22,15.95,3.13,5.32,1.64,11.14,3.45,17.95,7.69,5.14,3.2,10.95,6.83,15.1,13.96,2.45,4.21,3.56,8.35,3.99,9.97.35,1.33,1.32,5.26,1.42,10.54.03,1.32.1,7.6-2.28,15.67-.9,3.06-2.88,7.07-6.84,15.1-1.96,3.98-3.83,8.33-7.98,12.54-2.79,2.83-4.55,3.57-5.84,3.13-1.18-.4-1.78-1.74-2.28-2.85-.13-.28-2.2-3.39-.72-5.7,1.28-1.99,4.8-4.35,7.98-7.69,3.8-4,4.83-8.56,5.98-13.68,2.25-9.99.07-18.1-.85-21.37-2.06-7.32-5.39-12.16-6.27-13.39-1.82-2.56-5.23-7.28-11.4-10.83-4.95-2.85-9.84-3.89-11.68-4.27-3.92-.81-5.18-.46-9.69-1.71-3.44-.95-4.13-1.55-6.55-1.71-1.28-.08-4.6-.26-7.98,1.42-3.22,1.61-3.16,3.25-6.27,4.27-.91.3-3.02.99-5.41.28Z"
        />
      </g>
      <g id="Anatomy">
        <g id="Femur">
          <path
            id="Femur4"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls1"
            d="M.45,213.5c11.16-23.95,36.23-75.79,39.87-86.44,3.05-8.92,4.65-13.58,5.77-20.22,2.05-12.14-.44-13.49,2.29-19.28,2.79-5.95,5.75-5.24,16.01-15.22,9.37-9.11,8.49-11.25,14.6-15.12,2.33-1.47,10.76-6.82,20.89-5.61,0,0,4.38.53,19.62,10.39,2.86,1.85,5.49,4.04,7.94,6.4,3.45,3.32,6.38,4.16,8.53,4.24,8.62,4.35,17.12,4.84,25.45,0,6.96.27,9.92-7.15,17.98-6.84,4.7.17,8.02,2.88,9.86,2.56.76-.13,1.51-.25,2.27-.14,3.94.56,9.61,1.95,15.48,5.51,11.06,6.72,15.43,16.71,16.9,20.16,1.73,4.12,6.87,16.25,1.93,29.64-4.59,12.45-15.01,18.17-21.95,21.98-5.7,3.13-12.58,6.91-21.04,5.82-7.6-.97-8.09-4.8-15.98-6.04-6.35-1.76-13.97-6.75-25.22-4.46-.6-.1-14.65,2.4-23.85,11.66-5.61,5.65-6.88,12.81-7.46,16.08-1.39,7.85,1.01,10.1-.4,15.62-1.69,6.64-30.99,52.67-45,71.41"
          />
          <path
            id="Femur3"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls1"
            d="M47.03,102.07c7.48-5.1,12.46-10.28,15.69-14.2,3.19-3.88,4.23-5.98,12.92-19.27,8.38-12.84,10-14.84,13.21-15.72,5.72-1.56,12.88,1.42,16.62,5.98,3.83,4.67,1.84,8.24,6.66,15.16,2.29,3.29,4.65,6.67,8.27,7.26,7.26,1.19,12.46-10.01,15.86-8.47,2.79,1.27,2.99,10.45-1.1,17.43-3.39,5.77-7.04,5.48-12.72,11.46-4.46,4.7-6.34,9.5-10.09,19.08-2.31,5.89-5.05,14.18-6.9,24.54"
          />
          <line
            id="Femur2"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls1"
            x1="112.35"
            y1="120.78"
            x2="105.62"
            y2="99.64"
          />
          <path
            id="Femur1"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls2"
            d="M189.26,68.37c.14,1.35-.47,2.04-.41,3.31,1,8.2,3.21,15.03,1.82,22.68-7.28,14.06-.28,28.76.34,42.74-2.57,4.6-5.37,9.45-8.12,14.25"
          />
        </g>
        <g id="Hip">
          <path
            id="Hip8"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls1"
            d="M232.45,34.61c3.87,11.72,8.87,22.72,14.87,34.72,5,10,10.54,19.26,19,26,13,10.35,17,19,28,25"
          />
          <path
            id="Hip7"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls1"
            d="M228,52.59c34,84,11.5,68.16,13,68,1.35-.14,2.22-11.75-1-23-3.18-11.1-9.27-18.1-11-20-7.45-8.18-15.57-11.43-22-14-6.66-2.66-17.74-6.96-32-5-9.25,1.27-16.32,4.63-20.67,7.17"
          />
          <path
            id="Hip6"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls1"
            d="M233.33,35.77h0c6.12,18.75,17.67,48.54,24.97,48.55-3.9-5.07.78-8.53-.3-11.73-1.65-4.88-2.72-8.04-2-12,1.14-6.24,5.42-7.39,5-12-.35-3.76-3.62-7.67-7-8-4.14-.4-5.8,4.84-10,5-2.43.09-6.02-1.5-10.67-9.83h0Z"
          />
          <path
            id="Hip5"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls1"
            d="M160.46,72.66c-12.27-7.86-6.22-15.51-5.22-20.51,1-8-3-14-7.05-19.63"
          />
          <path
            id="Hip4"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls1"
            d="M326.36,101.93c-2.03-5-6.27-16.66-10.34-21.09-3.01-3.28-3.91-2.87-11-8-5.41-3.92-11.36-8.27-17-15-3.3-3.94-5.92-7.06-8-12-4.31-10.22-1.05-15.69-6-21-2.85-3.06-10.44-8.16-15.02-9.25-3.12-.78-4.88.21-7.38,1.86-3.58,2.37-7.29,2.59-11.53,2.28"
          />
          <path
            id="Hip3"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls1"
            d="M296.64,196.75c-10.32,9.58-26.32,7.58-38.32,2.58-13-6-27-14-35-27-4-7-7-14-9-21-.75-3.39-1.51-6.93-2.26-10.5"
          />
          <path
            id="Hip2"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls1"
            d="M251,92.58c4.42,0,7.3-.53,7.3-8.27"
          />
          <path
            id="Hip1"
            class="rechte_Hufte_Abduktion_vd_obl_liegend2409_cls1"
            d="M251,92.59c-.14,11.3.44,20.57,1,27,2.26,25.83,6.77,34.31,9,38,3.39,5.6,8.09,13.38,16,14,5.8.45,12.88-2.98,15-9,2.41-6.85-2.85-13.54-4-15-1.25-1.6-5.75-6.85-26-11-6.03-1.24-14.56-2.6-25-3"
          />
        </g>
      </g>
    </svg>
  );
}
