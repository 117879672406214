import style from "./style.scss";

export default function ThoraxExspirationLatStehend2106(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 622 715.4"
    >
      <g id="BG">
        <path
          id="bg"
          class="Thorax_exspiration_lat_stehend2106_cls11"
          d="M422,679c7.7.5,13.1-1.1,17-2,30.6-6.9,52.7,10.5,61,1,3.4-3.9,1.6-9.1.3-18.9-1.3-10.7.7-25.8,4.7-56,5.2-39.1,17.2-86.4,22.9-112.2,7.2-32.8,9.9-64.6,15.3-128,5.5-64.3.5-43.9,6.7-165,1.3-24.9,2.8-52.8-4.7-89.2-6.2-29.4-9.2-44.2-19.2-57.7-32.8-44.3-109.2-36.9-181-30-66.8,6.4-92.9,22.8-108,36-7.9,6.9-15.2,16.1-29.7,34.4-6.9,8.7-27.5,35.1-54.4,80.1-18.1,30.2-27.1,45.4-36.9,67.6-15.2,34.5-21.7,61.6-31.9,104.9-7.8,32.9-12.1,57.7-17,86.2-7.2,40.8-11.3,72.9-14.1,94.8-3.4,26.5-5.2,43.9-6.2,54-3.8,39-1.7,32-3.5,41-2.3,11.4-4.8,17.9-1.4,21,5.6,5.2,22.1-3.8,27.9-7,12.1-6.6,19.7-14.1,20.1-14.5,22.3-22,160.7-61,233-.5,28.3,23.7,43.9,33.1,75,52,3.7,2.2,12.3,7.2,24.1,8h0Z"
        />
      </g>
      <g id="underlays">
        <g id="Sternum" class="Thorax_exspiration_lat_stehend2106_cls13">
          <path
            id="Sternum3"
            class="Sternum2106Fill"
            style={{
              fill: props.colors.Sternum2106Fill,
              stroke: props.colors.Sternum2106Fill,
            }}
            d="M164.9,178.9c.7-5,4.9-5.8,14-14,3.3-3,14.8-13.6,22-29,4.6-9.8,2.5-10.8,7-18,3-4.8,11-17.5,23.7-17.4,6.3.1,13.3,3.3,17.3,9.4,5,7.6,3.5,17.1,0,23-2.3,3.7-7.9,6.9-19,13s-11.1,5.2-15,8-7.1,6.1-17,21c-11.9,17.8-12.9,21.5-17,22-7.5.8-17.1-9.8-16-18Z"
          />
          <path
            id="Sternum2"
            class="Sternum2106Fill"
            style={{
              fill: props.colors.Sternum2106Fill,
              stroke: props.colors.Sternum2106Fill,
            }}
            d="M87.9,395.9c-5-10.4,5.9-18.7,15-45,7.1-20.5,2.7-22.1,11-46,.9-2.7,5.3-12.8,14-33,7.4-17.2,9.7-22.2,15-35,6.2-15,9.3-22.4,10-25,3.1-11.4,2.9-17.3,7-19,4.9-2,13.1,2.8,15,9,1.2,4-.6,7.2-4,14s-8.4,17-12,27c-4.5,12.3-2.5,14.3-7,26-3.4,8.8-4.7,8.1-10,19-6.7,13.7-5.5,16.3-15,40-3.2,8-3.9,9.1-6,15-4.2,11.9-6.4,17.9-7,25-1.2,12.9,2.9,16.7-.6,27.3-1.3,3.8-3,9.1-7.4,10.7-5.9,2.1-14.7-3.2-18-10Z"
          />
          <path
            id="Sternum1"
            class="Sternum2106Fill"
            style={{
              fill: props.colors.Sternum2106Fill,
              stroke: props.colors.Sternum2106Fill,
            }}
            d="M87.9,403.9c2.7-1.8,10.9,2.5,13,9,1.3,4.1-.9,5.5-2,14-1.2,9.6,1.2,11.6-1,18-1.7,4.7-5.4,10.5-8,10-3.6-.7-4.6-13.5-4-22,.5-7.4,2-8.4,2-16s-1.8-11.8,0-13Z"
          />
        </g>
        <g id="Spine" class="Thorax_exspiration_lat_stehend2106_cls6">
          <path
            id="Spine11"
            class="Spine2106Fill"
            style={{
              fill: props.colors.Spine2106Fill,
              stroke: props.colors.Spine2106Fill,
            }}
            d="M320.1,611.3c1.9-3.2,4.3-7.9,5-14,1.2-10.3-3-17.1,1-21,1.8-1.8,5-2.5,7.8-3.2,2.7-.7,4.9-.7,6.2-.8,7.9-.2,11.8-.4,17,0,10.5.8,15.8,1.2,21,3,4.9,1.7,14,5,15,11,.5,3.3-1.9,4.6-4,11-1.7,5.2-1.2,7.6-1.8,14.7-1.3,15.6-5.5,23-3.2,24.3,1.7.9,4.2-3.2,13-8,5.6-3.1,8.5-4.7,12-4,6.8,1.3,10.2,9.9,11,12,2.5,6.9-.4,8.9,2,16,.5,1.6,3.2,8.9,10,13,5.3,3.2,6.9.7,18,3,10.1,2.1,12.4,5,21,5s7.4,0,10-2c5.9-4.6,5.7-16.5,1-24-2.4-3.9-6-5.9-13-10-8.4-4.8-11.1-4.6-21-10-6.2-3.4-9.3-5.1-12-8-6.5-7.1-7.7-15.7-8-18-1-7.5,1.5-8.7,1-18-.4-8.3-2.8-17-5-17s-1.8,7.9-8,16c0,0-3.4,4.4-9,8-15.5,9.9-32.8,6-47.7,1.7-5.5-1.6-18.9-7-28.3-11.5"
          />
          <path
            id="Spine10"
            class="Spine2106Fill"
            style={{
              fill: props.colors.Spine2106Fill,
              stroke: props.colors.Spine2106Fill,
            }}
            d="M481.2,614.8c2-.6,3.6-3.2,6.9-8.5,3.2-5.1,4.8-7.7,5-10,.5-6.8-6.6-12.3-10-15-10.4-8.2-15.3-4.3-22-11s-2.6-9.3-10-20c-5.9-8.5-8.2-6.9-11-13-3.2-7.2-.5-10.5-3-24-.9-4.6-2.4-12.8-5-13-2.3,0-2.9,6.2-9,14-3.2,4.1-7.2,9.1-12,9-5,0-6-5.4-13-10-3.9-2.6-5.2-2-22-5-17.1-3.1-21.2-4.7-27-2-1.6.8-5.8,2.7-8,7-2.4,4.7.1,7.3-1,16-.8,6.6-3.1,12-4,14-3,7-5.2,7.7-5,11,.2,3.5,2.8,6.3,11,12,10.8,7.5,16.2,11.2,22,13,1.7.5,25.4,7.5,34-3,4.2-5.1.6-9.3,6-18,2-3.2,6.4-10.3,14-11,6.3-.6,12.1,3.5,15,8,1.6,2.5,2.4,5.3,2,18-.5,17.1-2.3,24.2,1,26,2.2,1.2,3.7-1.5,9-2,6.5-.6,11.9,2.9,21,9s11.5,9.6,15.1,8.5Z"
          />
          <path
            id="Spine9"
            class="Spine2106Fill"
            style={{
              fill: props.colors.Spine2106Fill,
              stroke: props.colors.Spine2106Fill,
            }}
            d="M489.1,563.3c5.9-.6,9.5-8,10-9,3.5-7.2.7-14.3,0-16-1.9-4.7-4.9-7.4-9-11-8-7.1-10.9-5.6-15-11-2.2-2.9-3.5-6.9-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-1.5-2-6.5-9-8-18-1.8-10.9,3-16.8,0-19-3-2.3-11.2,1.7-16,7-4.9,5.3-3.6,8.9-9,14-1.2,1.1-5.1,4.7-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.1,3.5,3,5.1,10,12,5.1,4.9,10,10,15,15,14.3,14.3,16.3,18.5,21,18Z"
          />
          <path
            id="Spine8"
            class="Spine2106Fill"
            style={{
              fill: props.colors.Spine2106Fill,
              stroke: props.colors.Spine2106Fill,
            }}
            d="M497.4,506.5c5.9-.6,9.5-8,10-9,3.5-7.2.7-14.3,0-16-1.9-4.7-4.9-7.4-9-11-8-7.1-10.8-5.6-15-11-2.2-2.9-3.5-6.9-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-1.5-2-6.5-9-8-18-1.8-10.9,3-16.8,0-19-3-2.3-11.2,1.7-16,7-4.9,5.3-3.6,8.9-9,14-1.2,1.1-5.1,4.7-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.1,3.5,3,5.1,10,12,5.1,4.9,10,10,15,15,14.3,14.3,16.3,18.5,21,18Z"
          />
          <path
            id="Spine7"
            class="Spine2106Fill"
            style={{
              fill: props.colors.Spine2106Fill,
              stroke: props.colors.Spine2106Fill,
            }}
            d="M508.1,451.3c4.4-1.2,6.3-8.9,7-12,.5-2.1,1.6-6.8,0-12-2-6.4-5.8-6.2-11-14-.8-1.2-2.8-6-6.8-15.6-3.4-8.2-5.2-12.3-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-2.8-3.8-4.9-8.2-8-18-3.8-12-3.5-18.8-5.7-19s-2.5,6.5-9.5,13.6c-2.7,2.8-5.3,4.5-9.8,7.4-5.4,3.5-8.1,5.4-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.6,5,4.5,6,10,12,7.6,8.4,5.7,11,13.8,22.6,5.3,7.5,13,18.6,19,17Z"
          />
          <path
            id="Spine6"
            class="Spine2106Fill"
            style={{
              fill: props.colors.Spine2106Fill,
              stroke: props.colors.Spine2106Fill,
            }}
            d="M509.1,399.3c5.4.6,12.2-6.7,13-14,.3-2.5,0-6-6-15-6.6-10-10.7-12.2-16-19-6.4-8.1-9-15.5-13-27-4.4-12.5-3.1-14.4-8-30-4.5-14.3-8.2-25.6-14-26-3.5-.2-6.9,3.5-12,9-3.9,4.2-5.9,7.8-11,10-2,.8-4,1.3-4.6,1.4-4.5,1-8,.7-9.4.6-23.1-1.9-15.4-.4-29-2-10.3-1.2-12.7-1.9-15,0-3.1,2.6-1.9,6.5-3,16-1.7,15.3-6.2,17-4,22,2.3,5.2,10.9,7.1,28,11,1,.2,8.7,1.9,19,2,1.3,0,6-.4,12-2,5.6-1.5,8.5-2.3,10-4,4.3-4.8-.4-11,4-17,2.2-3,6.1-5.3,10-5,4.2.3,6.9,3.5,9,6,4.2,5.1,5.3,10.1,8,21,2.5,10,3.7,15,4,16,3.6,10.8,6.8,11.4,16,28,7.2,13,8,17.5,12,18Z"
          />
          <path
            id="Spine5"
            class="Spine2106Fill"
            style={{
              fill: props.colors.Spine2106Fill,
              stroke: props.colors.Spine2106Fill,
            }}
            d="M438.1,281.3c3.4-3.2-.2-8.5,3-17,.9-2.2,2.7-7.2,7-9,5.5-2.3,12,2,15,4,7.4,5,10.5,11,22,34,5.5,11.1,7.3,14.9,11,21,8.3,14,13,21.6,18,21,5.4-.6,10.1-10.7,9-19-.2-1.6-1-5.7-9-15-10.1-11.7-13.3-10.2-19-18-7.3-9.9-3.2-13.8-11-25-7.1-10.3-10.8-7.6-15-16-5.2-10.5-.9-17.8-8-26-2.3-2.6-6.4-6.1-9-5-2.9,1.3-1,7.3-4,14-3.4,7.5-10.8,11-13,12-7.4,3.5-15,2.7-30,1-11.6-1.3-16.4-3-19,0-2.4,2.7-.5,6.3,0,13,1.4,17.7-9.1,26.2-5,31,3.1,3.6,8.5-1.9,26-1,7,.4,14,.5,21,1,5.7.4,8.1.7,10-1Z"
          />
          <path
            id="Spine4"
            class="Spine2106Fill"
            style={{
              fill: props.colors.Spine2106Fill,
              stroke: props.colors.Spine2106Fill,
            }}
            d="M488.1,216.3c4,9.6,9.1,14.9,13,18,8.2,6.5,14.6,6,18,13,2.2,4.5,2.2,10.3,0,15-.8,1.6-3.4,7.3-8,8-6.3,1-12.5-7.7-19-17-3.2-4.6-5.5-8.7-10-17-5.9-10.9-8.9-16.3-11-21-3.7-8.2-4.2-11.1-8-14-.7-.5-7.6-5.7-14-3-6.2,2.6-7.6,10.9-8,13-1.3,7.6,2.1,10.7,0,15-2.1,4.4-7.8,5.8-14,7-27.9,5.6-41.8,8.4-46,2-.4-.6-.6-5.1-1-14-.6-12.6-.5-13.3-1-16-1.7-8.3-4.4-10.2-3-13s5.7-2.8,17-4c16.4-1.8,10.7-2.6,30-5,6-.8,8.4-.9,11-3,4.1-3.4,3.2-7.2,8-14,1.9-2.7,6.2-8.7,10-8,2.7.5,3.2,4,7,10,2.9,4.6,5.9,7.9,8,10"
          />
          <path
            id="Spine3"
            class="Spine2106Fill"
            style={{
              fill: props.colors.Spine2106Fill,
              stroke: props.colors.Spine2106Fill,
            }}
            d="M450.1,119.3c-5.6-12.1-8.5-14.4-10-14-1.9.4-1.4,4.8-4,12-.8,2.2-3.6,9.9-9,15-4.9,4.6-10.5,5.5-19,7-11.9,2-16.8.2-31,3-7.7,1.5-10.1,2.8-11,5-1.5,3.8,3.6,6.5,6,15,2.8,9.7-1.5,14.5,2,18s2.9,2.2,18,0c16.2-2.4,17-4.4,25-4,7.1.3,9.4,2,12,0,4-3.1.7-9,5-17,1.1-2.1,4.1-7.6,10-9,6.6-1.5,12.2,3.3,13,4"
          />
          <path
            id="Spine2"
            class="Spine2106Fill"
            style={{
              fill: props.colors.Spine2106Fill,
              stroke: props.colors.Spine2106Fill,
            }}
            d="M427.1,71.3c-1,3.5-3.3,9.6-9,14-2.2,1.7-5,3.2-12,5-9.6,2.5-14.3,2.4-25,4-20.2,3-21.6,6-22,7-1.3,3.6,3.3,5.4,5,13,2.5,10.8-4.2,18.2-1,21,1.9,1.7,4.8-.3,14-3,5.9-1.7,15-4.4,25-5,11-.6,13.5,1.9,18-1,4.3-2.8,4.1-6.7,9-14,3.8-5.8,8.1-9.6,11-12"
          />
          <path
            id="Spine1"
            class="Spine2106Fill"
            style={{
              fill: props.colors.Spine2106Fill,
              stroke: props.colors.Spine2106Fill,
            }}
            d="M434.1,62.3c-4.7-2.6-8.9-3.6-12-2-3.4,1.8-3.3,5.5-7,12-.9,1.6-4.6,8-10,12-5.8,4.2-9.8,2.9-21,5-18.3,3.4-25.5,10.4-29,7-2.8-2.7,1.2-7.7,0-17-1-7.6-5-13.4-8-17"
          />
        </g>
        <ellipse
          id="Lungenspitze"
          class="Lungenspitze2106Fill"
          style={{
            fill: props.colors.Lungenspitze2106Fill,
            stroke: props.colors.Lungenspitze2106Fill,
          }}
          cx="394.2"
          cy="52.7"
          rx="44.9"
          ry="17.6"
        />
        <path
          id="winkel"
          class="Zwerchfellrippenwinkel2106Fill"
          style={{
            fill: props.colors.Zwerchfellrippenwinkel2106Fill,
            stroke: props.colors.Zwerchfellrippenwinkel2106Fill,
          }}
          d="M391.5,632.4c-.2,1.5,1.4,4.7,3.8,6.3.1,0,.2.1.3.2.9.5,1.7,1.3,2.1,2.3s1.3,3,2.4,4.5c.8,1,1.5,1.5,2.3,3.1.4.8.6,1.4.6,1.5,1,2.1,2.7,4.7,8.7,13.3,13.7,19.8,8.2,11.6,9.4,13.3,5,7.5,6.2,10.1,8.6,10.2,2.3,0,4.1-2.6,7.8-7.8,2.3-3.3,4-6.3,5.5-9.4,2.9-5.8,2.8-6.6,5.5-12.5,3.9-8.6,5.4-9.7,7.8-16.4,2-5.7,3-8.5,2.3-10.2-2.6-6.4-18.1-5.5-41.4-3.9-21.9,1.5-25.6,3.7-25.8,5.5Z"
        />
        <g id="WKhinterkanten">
          <path
            id="WKhinterkanten10"
            class="WKhinterkanten2106Fill"
            style={{
              fill: props.colors.WKhinterkanten2106Fill,
              stroke: props.colors.WKhinterkanten2106Fill,
            }}
            d="M415.5,72.1c-.5-.5-1.3-.5-1.7,0l-2.3,3.2-3,4.6-3.6,3-6.4,2.4-4.6,1.5,3,1.2,2.6-.2c.9,0,1.8-.2,2.6-.5l3.3-1.1,3.9-3,3.9-3.9,2.4-3.5c.8-1.1.7-2.6-.3-3.7h0Z"
          />
          <path
            id="WKhinterkanten9"
            class="WKhinterkanten2106Fill"
            style={{
              fill: props.colors.WKhinterkanten2106Fill,
              stroke: props.colors.WKhinterkanten2106Fill,
            }}
            d="M427.1,112.4c-.7,1.7-1.4,3.4-2.1,5.2l-2.7,4.9-3.3,2.7h-6.4l-6.7.3-2.7.3c-.2,1.5.9,2.8,2.4,2.9h1.2c0,0,4.9,0,4.9,0h8.2l3-1.2,2.4-3,3.6-6.1.9-1.9c.9-.9,1-2.3.3-3.2-.6-.9-1.9-1.3-3-.9Z"
          />
          <path
            id="WKhinterkanten8"
            class="WKhinterkanten2106Fill"
            style={{
              fill: props.colors.WKhinterkanten2106Fill,
              stroke: props.colors.WKhinterkanten2106Fill,
            }}
            d="M435.4,162.8l-.7,3.4-.3,4.6-1.2,4.3-1.8,2.4-3.3,1.5-3.3-.3-3.3-1.2h-4.3l-2.7-.3.3-3,4.9.6,5.2.3,3.3.3,2.4-3.6.3-6.1,1.7-5.4c.2-.6.9-.9,1.5-.6h0c1.1.7,1.7,2,1.4,3.2Z"
          />
          <polygon
            id="WKhinterkanten7"
            class="WKhinterkanten2106Fill"
            style={{
              fill: props.colors.WKhinterkanten2106Fill,
              stroke: props.colors.WKhinterkanten2106Fill,
            }}
            points="439.8 214.2 440.4 220 441.1 223.6 439.2 226.7 436.2 228.8 432.5 229.7 429.5 231.2 430.1 233.4 432.8 233.4 436.2 232.1 439.5 230.6 442.6 228.2 444.7 224.8 443.8 220.3 443.5 215.7 442.3 212.4 439.8 214.2"
          />
          <path
            id="WKhinterkanten6"
            class="WKhinterkanten2106Fill"
            style={{
              fill: props.colors.WKhinterkanten2106Fill,
              stroke: props.colors.WKhinterkanten2106Fill,
            }}
            d="M439,269.7l.2,2.6v4.3l-1.2,2.4-2.7,1.5-4.6-.3h-3.3l-2.7-.3v2.7c3.6,1.5,7.6,1.2,7.6,1.2h4.6l3.3-1.8,2.7-3v-9.1l-2.7-1c-.6-.2-1.2.2-1.2.9Z"
          />
          <path
            id="WKhinterkanten5"
            class="WKhinterkanten2106Fill"
            style={{
              fill: props.colors.WKhinterkanten2106Fill,
              stroke: props.colors.WKhinterkanten2106Fill,
            }}
            d="M446.9,321.3v.8c0,0,.2,4.3.2,4.3l-1.8,3.6-3.9,2.4-6.1,1.5-4.3,1.2h-1.3c-1.4,0-2.6,1.2-2.5,2.7h0c0,.9.8,1.6,1.7,1.6h2.1l4.6-.6,3.9-.6,4.9-2.1,3.9-2.1,2.1-2.7.6-3,.3-3.9-.2-3.4c0-.8-.7-1.5-1.5-1.6h0c-1.4-.2-2.6.8-2.8,2.2Z"
          />
          <path
            id="WKhinterkanten4"
            class="WKhinterkanten2106Fill"
            style={{
              fill: props.colors.WKhinterkanten2106Fill,
              stroke: props.colors.WKhinterkanten2106Fill,
            }}
            d="M440.4,374.9l-3.3,7.3-2.7,4.6-4.3,4.6-5.2,3.6-4.9,2.1h-4.6c-1.2,0-2.3.8-2.5,2h0c-.2,1,.5,2.1,1.5,2.2l2.6.3,6.1-.6,3.8-1.1c.9-.3,1.8-.7,2.4-1.4l3.8-3.6,6.7-7.9,3.3-5.5,2.1-6.4.6-2.8c.2-.7-.2-1.3-.8-1.6h0c-1.6-.6-3.3.2-3.9,1.8l-.8,2.3Z"
          />
          <path
            id="WKhinterkanten3"
            class="WKhinterkanten2106Fill"
            style={{
              fill: props.colors.WKhinterkanten2106Fill,
              stroke: props.colors.WKhinterkanten2106Fill,
            }}
            d="M425.9,438.4l-2.7,5.5-3.6,6.1-4.3,3.3-3.9,3.3-5.2,2.4h-.7c-1.7,0-3.3,1.2-3.6,2.9h0c-.1.5.3,1.1.8,1.1h3.8l4.2-.6c1.4-.2,2.7-.8,3.8-1.8l3-2.8,4.3-2.8c.8-.5,1.5-1.2,2-2l2-3,3.3-6.4,2-4.8c.2-.6,0-1.2-.4-1.6h0c-1.6-1.2-3.9-.6-4.6,1.2h0Z"
          />
          <path
            id="WKhinterkanten2"
            class="WKhinterkanten2106Fill"
            style={{
              fill: props.colors.WKhinterkanten2106Fill,
              stroke: props.colors.WKhinterkanten2106Fill,
            }}
            d="M417.4,496.1l-3,5.8-5.2,6.1-3.9,4.6-4.3,2.4-6.1.6h-1.6c-1.2,0-2.3.8-2.5,2h0c-.3,1.2.5,2.4,1.8,2.6l1.7.3,3.9-.6,6.1-1.5,5.4-3.3c.7-.4,1.2-.9,1.7-1.5l4.1-5.2,3.9-5.2,3-7.3,1.1-3.9c.2-.7-.2-1.5-.9-1.7h0c-1.5-.5-3.1.3-3.7,1.8l-1.7,4.1Z"
          />
          <path
            id="WKhinterkanten1"
            class="WKhinterkanten2106Fill"
            style={{
              fill: props.colors.WKhinterkanten2106Fill,
              stroke: props.colors.WKhinterkanten2106Fill,
            }}
            d="M403.4,557.2l-2.1,5.2-1.2,4.3-.9,5.2-2.4,2.4-2.7,2.7-5.2,1.8-4.6.9h-2.7c-1.2,0-2.2.9-2.3,2.1h0c0,.7.4,1.3,1,1.4l2.9.4,4.3-.3,5.8-.9,3.2-1.3c.9-.3,1.7-.9,2.3-1.6l2.5-2.7c.9-.9,1.5-2.1,1.7-3.4l1.1-6,1.8-6.4,2.1-4.6,2.4-2.7c.9-1.1,1-2.6.2-3.8h0c-.3-.5-1.1-.5-1.4,0l-1.7,2.6-3.9,4.6Z"
          />
        </g>
      </g>
      <g id="Thorax">
        <path
          id="Thorax64"
          class="Thorax_exspiration_lat_stehend2106_cls3"
          d="M313,239c3.7,6,18,30.4,21,60h0c3.2,32.2,3.6,28.9,4.4,35.6,2.4,21.8-3.5,40.7-4.4,43.4-1.7,5.4-4,11-7.7,23.4-.2.8-2.4,8-4.3,15.6-4.8,19.1-4.9,27.5-8,43-3.5,17.6-8.1,30.5-13,44s-9.8,25-14,34"
        />
        <path
          id="Thorax63"
          class="Thorax_exspiration_lat_stehend2106_cls1"
          d="M360,203c-8.6-12.9-15.2-18.9-19-22-9.3-7.6-18-11.4-22-13-3.4-1.4-7.9-3.1-14-4-2.5-.4-9.9-1.3-22,1-15.3,2.9-26.2,8.8-32,12-4.5,2.5-15.4,8.8-27,20-6.9,6.6-12.9,12.5-18,22-5.2,9.8-3.1,11.6-9,26-1.6,3.8-6,14.2-14,26-5.5,8.1-6.6,8-17,22-5.6,7.5-9.8,13.6-12,17-7.9,11.8-13.4,21.2-15,24-6.6,11.5-11.3,19.6-16,31-2.4,5.8-7.5,19-11,37-3.6,18.3-5.3,27.4-3,39,.5,2.7,5.7,13.2,16,34,7.3,14.8,9.9,19.7,16,24,7.1,5,14.5,6.2,20,7,10.6,1.6,18.7,0,29-2,8.8-1.7,15.7-3.1,24-7,12.2-5.7,19.5-13.3,26-20,4.7-4.9,14.7-16,23-33,3-6.2,8.6-17.9,11-34,.5-3,1.3-9.5,0-35-1.1-21.3-1.6-32-3-39-4.1-21.1-9.1-20.8-16-49-2.1-8.6-4.8-21.4-3-37,1.4-11.9,4.3-16.8,7-20,6.2-7.3,14.7-9.2,18-10,3.1-.7,11.6-2.6,20,1,2.7,1.2,7.3,3.8,16,18"
        />
        <path
          id="Thorax62"
          class="Thorax_exspiration_lat_stehend2106_cls3"
          d="M332,575c3.6-8.4,8.5-20,14-34,7-17.8,11.5-29.2,16-44,4.1-13.3,6.7-25.5,12-50,3.5-16.4,3.2-15.9,10-50,7.5-37.5,8.2-39.2,9-48,1.9-21.4.2-38-1-49-1.3-12.1-3.4-30.3-11-53-8-24-17.6-38.9-21-44"
        />
        <path
          id="Thorax61"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M439,677c1.8-3.6,4.2-8.7,7-15,4.5-10.2,6.7-15.3,8-20,3.7-13.7-.4-16.2,3-33,1.3-6.6,1.6-4.5,5-18,3.4-13.6,3.3-16.4,6-23,3.7-9,5.5-7.6,10-17,3.3-6.9,8.3-17.5,7-30-.8-7.5-3.2-10.1-2-17,.9-5.1,3-7.9,6-13,3.4-5.8,8.7-14.8,12-26,2.4-8.3,5-17.2,3-28-1.4-7.6-3.8-9.5-4-17,0-5.4,1.1-7.8,4-18,4.2-14.9,6.3-22.4,6-29-.3-5.8-1.7-11.2-4-20-1.9-7.3-3.2-10.4-3-16,.3-5.7,1.9-7.1,3-13,1.2-6.3.5-10.8-1-21-1.3-9-2.5-18-4-27-2.1-12.2-1.3-10.8-5-40-3.1-24.3-4.6-36.4-7-48-2-9.6-1.9-7.4-9-35-5.3-20.6-8.1-32.8-14-54-5.8-20.7-7.6-25.4-11-31-2.6-4.4-10.3-16.7-24-25-32.1-19.5-72.6-1.7-81,2-13.1,5.7-25.5,14.5-29,17-8.9,6.3-15.3,12.1-22,18-12.8,11.4-19.2,17.1-24,23-6.1,7.5-7.6,11.2-13,13-8.9,3-18-2.8-20-4-.9-.6-10.6-6.9-13-19-.7-3.6-1.8-8.7,1-13,2.7-4.1,6.9-4.2,15-7,8-2.8,13.2-5.9,23-12,4.5-2.8,10.8-7,18-13"
        />
        <path
          id="Thorax60"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M321,611c1.9-3.2,4.3-7.9,5-14,1.2-10.3-3-17.1,1-21,1.8-1.8,5-2.5,7.8-3.2,2.7-.7,4.9-.7,6.2-.8,7.9-.2,11.8-.4,17,0,10.5.8,15.8,1.2,21,3,4.9,1.7,14,5,15,11,.5,3.3-1.9,4.6-4,11-1.7,5.2-1.2,7.6-1.8,14.7-1.3,15.6-5.5,23-3.2,24.3,1.7.9,4.2-3.2,13-8,5.6-3.1,8.5-4.7,12-4,6.8,1.3,10.2,9.9,11,12,2.5,6.9-.4,8.9,2,16,.5,1.6,3.2,8.9,10,13,5.3,3.2,6.9.7,18,3,10.1,2.1,12.4,5,21,5s7.4,0,10-2c5.9-4.6,5.7-16.5,1-24-2.4-3.9-6-5.9-13-10-8.4-4.8-11.1-4.6-21-10-6.2-3.4-9.3-5.1-12-8-6.5-7.1-7.7-15.7-8-18-1-7.5,1.5-8.7,1-18-.4-8.3-2.8-17-5-17s-1.8,7.9-8,16c0,0-3.4,4.4-9,8-15.5,9.9-38.1,3.3-53-1-5.5-1.6-13.6-4.3-23-8.8"
        />
        <path
          id="Thorax59"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M482.1,614.5c2-.6,3.6-3.2,6.9-8.5,3.2-5.1,4.8-7.7,5-10,.5-6.8-6.6-12.3-10-15-10.4-8.2-15.3-4.3-22-11s-2.6-9.3-10-20c-5.9-8.5-8.2-6.9-11-13-3.2-7.2-.5-10.5-3-24-.9-4.6-2.4-12.8-5-13-2.3,0-2.9,6.2-9,14-3.2,4.1-7.2,9.1-12,9-5,0-6-5.4-13-10-3.9-2.6-5.2-2-22-5-17.1-3.1-21.2-4.7-27-2-1.6.8-5.8,2.7-8,7-2.4,4.7.1,7.3-1,16-.8,6.6-3.1,12-4,14-3,7-5.2,7.7-5,11,.2,3.5,2.8,6.3,11,12,10.8,7.5,16.2,11.2,22,13,1.7.5,25.4,7.5,34-3,4.2-5.1.6-9.3,6-18,2-3.2,6.4-10.3,14-11,6.3-.6,12.1,3.5,15,8,1.6,2.5,2.4,5.3,2,18-.5,17.1-2.3,24.2,1,26,2.2,1.2,3.7-1.5,9-2,6.5-.6,11.9,2.9,21,9s11.5,9.6,15.1,8.5Z"
        />
        <path
          id="Thorax58"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M490,563c5.9-.6,9.5-8,10-9,3.5-7.2.7-14.3,0-16-1.9-4.7-4.9-7.4-9-11-8-7.1-10.9-5.6-15-11-2.2-2.9-3.5-6.9-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-1.5-2-6.5-9-8-18-1.8-10.9,3-16.8,0-19-3-2.3-11.2,1.7-16,7-4.9,5.3-3.6,8.9-9,14-1.2,1.1-5.1,4.7-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.1,3.5,3,5.1,10,12,5.1,4.9,10,10,15,15,14.3,14.3,16.3,18.5,21,18Z"
        />
        <path
          id="Thorax57"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M498.3,506.2c5.9-.6,9.5-8,10-9,3.5-7.2.7-14.3,0-16-1.9-4.7-4.9-7.4-9-11-8-7.1-10.8-5.6-15-11-2.2-2.9-3.5-6.9-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-1.5-2-6.5-9-8-18-1.8-10.9,3-16.8,0-19-3-2.3-11.2,1.7-16,7-4.9,5.3-3.6,8.9-9,14-1.2,1.1-5.1,4.7-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.1,3.5,3,5.1,10,12,5.1,4.9,10,10,15,15,14.3,14.3,16.3,18.5,21,18Z"
        />
        <path
          id="Thorax56"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M509,451c4.4-1.2,6.3-8.9,7-12,.5-2.1,1.6-6.8,0-12-2-6.4-5.8-6.2-11-14-.8-1.2-2.8-6-6.8-15.6-3.4-8.2-5.2-12.3-6-15-2.5-8-1.9-9-4-13-2.8-5.4-4.9-5.5-9-11-2.8-3.8-4.9-8.2-8-18-3.8-12-3.5-18.8-5.7-19s-2.5,6.5-9.5,13.6c-2.7,2.8-5.3,4.5-9.8,7.4-5.4,3.5-8.1,5.4-11,6-2.1.5-5,.7-18-3-9.6-2.8-14.4-4.1-18-6-9.8-5.1-12.2-10.2-16-9-3.2,1.1-4.4,5.7-6,12-1.8,7-.7,8.7-2,15-2.2,11-6.6,11.3-6,17,1.1,10.3,16.1,16.7,19,18,1.3.5.2,0,15,4,11.1,3,13.3,3.7,17,3,5.7-1.1,9.5-4.7,12-7,4.4-4,6.6-8.3,8-11,4.4-8.5,3-11.9,7-15,2.9-2.2,7.4-3.3,11-2,8,2.9,8.9,16.2,9,18,.6,8.7-3,10.8-1,17,1.6,5,4.5,6,10,12,7.6,8.4,5.7,11,13.8,22.6,5.3,7.5,13,18.6,19,17Z"
        />
        <path
          id="Thorax55"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M510,399c5.4.6,12.2-6.7,13-14,.3-2.5,0-6-6-15-6.6-10-10.7-12.2-16-19-6.4-8.1-9-15.5-13-27-4.4-12.5-3.1-14.4-8-30-4.5-14.3-8.2-25.6-14-26-3.5-.2-6.9,3.5-12,9-3.9,4.2-5.9,7.8-11,10-2,.8-4,1.3-4.6,1.4-4.5,1-8,.7-9.4.6-23.1-1.9-15.4-.4-29-2-10.3-1.2-12.7-1.9-15,0-3.1,2.6-1.9,6.5-3,16-1.7,15.3-6.2,17-4,22,2.3,5.2,10.9,7.1,28,11,1,.2,8.7,1.9,19,2,1.3,0,6-.4,12-2,5.6-1.5,8.5-2.3,10-4,4.3-4.8-.4-11,4-17,2.2-3,6.1-5.3,10-5,4.2.3,6.9,3.5,9,6,4.2,5.1,5.3,10.1,8,21,2.5,10,3.7,15,4,16,3.6,10.8,6.8,11.4,16,28,7.2,13,8,17.5,12,18Z"
        />
        <path
          id="Thorax54"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M439,281c3.4-3.2-.2-8.5,3-17,.9-2.2,2.7-7.2,7-9,5.5-2.3,12,2,15,4,7.4,5,10.5,11,22,34,5.5,11.1,7.3,14.9,11,21,8.3,14,13,21.6,18,21,5.4-.6,10.1-10.7,9-19-.2-1.6-1-5.7-9-15-10.1-11.7-13.3-10.2-19-18-7.3-9.9-3.2-13.8-11-25-7.1-10.3-10.8-7.6-15-16-5.2-10.5-.9-17.8-8-26-2.3-2.6-6.4-6.1-9-5-2.9,1.3-1,7.3-4,14-3.4,7.5-10.8,11-13,12-7.4,3.5-15,2.7-30,1-11.6-1.3-16.4-3-19,0-2.4,2.7-.5,6.3,0,13,1.4,17.7-9.1,26.2-5,31,3.1,3.6,8.5-1.9,26-1,7,.4,14,.5,21,1,5.7.4,8.1.7,10-1Z"
        />
        <path
          id="Thorax53"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M489,216c4,9.6,9.1,14.9,13,18,8.2,6.5,14.6,6,18,13,2.2,4.5,2.2,10.3,0,15-.8,1.6-3.4,7.3-8,8-6.3,1-12.5-7.7-19-17-3.2-4.6-5.5-8.7-10-17-5.9-10.9-8.9-16.3-11-21-3.7-8.2-4.2-11.1-8-14-.7-.5-7.6-5.7-14-3-6.2,2.6-7.6,10.9-8,13-1.3,7.6,2.1,10.7,0,15-2.1,4.4-7.8,5.8-14,7-27.9,5.6-41.8,8.4-46,2-.4-.6-.6-5.1-1-14-.6-12.6-.5-13.3-1-16-1.7-8.3-4.4-10.2-3-13s5.7-2.8,17-4c16.4-1.8,10.7-2.6,30-5,6-.8,8.4-.9,11-3,4.1-3.4,3.2-7.2,8-14,1.9-2.7,6.2-8.7,10-8,2.7.5,3.2,4,7,10,2.9,4.6,5.9,7.9,8,10"
        />
        <path
          id="Thorax52"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M451,119c-5.6-12.1-8.5-14.4-10-14-1.9.4-1.4,4.8-4,12-.8,2.2-3.6,9.9-9,15-4.9,4.6-10.5,5.5-19,7-11.9,2-16.8.2-31,3-7.7,1.5-10.1,2.8-11,5-1.5,3.8,3.6,6.5,6,15,2.8,9.7-1.5,14.5,2,18s2.9,2.2,18,0c16.2-2.4,17-4.4,25-4,7.1.3,9.4,2,12,0,4-3.1.7-9,5-17,1.1-2.1,4.1-7.6,10-9,6.6-1.5,12.2,3.3,13,4"
        />
        <path
          id="Thorax51"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M428,71c-1,3.5-3.3,9.6-9,14-2.2,1.7-5,3.2-12,5-9.6,2.5-14.3,2.4-25,4-20.2,3-21.6,6-22,7-1.3,3.6,3.3,5.4,5,13,2.5,10.8-4.2,18.2-1,21,1.9,1.7,4.8-.3,14-3,5.9-1.7,15-4.4,25-5,11-.6,13.5,1.9,18-1,4.3-2.8,4.1-6.7,9-14,3.8-5.8,8.1-9.6,11-12"
        />
        <path
          id="Thorax50"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M435,62c-4.7-2.6-8.9-3.6-12-2-3.4,1.8-3.3,5.5-7,12-.9,1.6-4.6,8-10,12-5.8,4.2-9.8,2.9-21,5-18.3,3.4-25.5,10.4-29,7-2.8-2.7,1.2-7.7,0-17-1-7.6-5-13.4-8-17"
        />
        <path
          id="Thorax49"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M101,451c-.7,3.5-1.4,8.3-2,14-1.3,13.7.3,15.4,1,16,3.9,3.3,12.1-.6,15-2,7-3.3,9.5-7.3,15-12,7.1-6.2,12-7.4,21-12,13.6-6.9,20.7-13.5,33-23,29.7-23.1,44.2-28.5,80-55,30.4-22.5,24-21.6,65-54,37.2-29.5,55.9-44.2,78-58,25.9-16.1,45.7-25.4,65-50,8.3-10.5,13.7-20.2,17-27"
        />
        <path
          id="Thorax48"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M72,552c-.2,2.8-.5,7.3-1,13-1,11.2-1.8,14.8,0,16,2.8,1.9,9.6-3.3,13-6,11.6-9,24.1-17.1,35-27,9.5-8.6,25.4-23.1,45-31,21.8-8.8,41.7-7,51-6,9.1.9,28.6,4,80,38,34.8,23,54.7,36.2,78,61,23.7,25.3,39.2,50.3,49,69"
        />
        <path
          id="Thorax47"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M443,634c-1.3,6.8-3.2,14.9-6,15-3.3,0-5.9-11.5-9-22,0,0-2.8-9.6-21-49-13.4-29-42.2-48.2-90-80-37.4-24.9-58.2-38.4-91-45-21.2-4.3-39.5-4-52-3"
        />
        <path
          id="Thorax46"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M132,314c7.9-.8,19.3-2.1,33-4,22.4-3.1,33.7-4.7,44-8,19-6.1,17.7-10.4,38-18,11.5-4.3,27.4-10.3,48-10,11.9.2,25.6,3.5,53,10,6.7,1.6,12.2,3,16,4"
        />
        <path
          id="Thorax45"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M165,179c.7-5,4.9-5.8,14-14,3.3-3,14.8-13.6,22-29,4.6-9.8,2.5-10.8,7-18,3-4.8,11-17.5,23.7-17.4,6.3.1,13.3,3.3,17.3,9.4,5,7.6,3.5,17.1,0,23-2.3,3.7-7.9,6.9-19,13s-11.1,5.2-15,8-7.1,6.1-17,21c-11.9,17.8-12.9,21.5-17,22-7.5.8-17.1-9.8-16-18Z"
        />
        <path
          id="Thorax44"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M88,396c-5-10.4,5.9-18.7,15-45,7.1-20.5,2.7-22.1,11-46,.9-2.7,5.3-12.8,14-33,7.4-17.2,9.7-22.2,15-35,6.2-15,9.3-22.4,10-25,3.1-11.4,2.9-17.3,7-19,4.9-2,13.1,2.8,15,9,1.2,4-.6,7.2-4,14s-8.4,17-12,27c-4.5,12.3-2.5,14.3-7,26-3.4,8.8-4.7,8.1-10,19-6.7,13.7-5.5,16.3-15,40-3.2,8-3.9,9.1-6,15-4.2,11.9-6.4,17.9-7,25-1.2,12.9,2.9,16.7-.6,27.3-1.3,3.8-3,9.1-7.4,10.7-5.9,2.1-14.7-3.2-18-10Z"
        />
        <path
          id="Thorax43"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M88,404c2.7-1.8,10.9,2.5,13,9,1.3,4.1-.9,5.5-2,14-1.2,9.6,1.2,11.6-1,18-1.7,4.7-5.4,10.5-8,10-3.6-.7-4.6-13.5-4-22,.5-7.4,2-8.4,2-16s-1.8-11.8,0-13Z"
        />
        <line
          id="Thorax42"
          class="Thorax_exspiration_lat_stehend2106_cls4"
          x1="272.2"
          y1="419.1"
          x2="303"
          y2="419"
        />
        <line
          id="Thorax41"
          class="Thorax_exspiration_lat_stehend2106_cls4"
          x1="355.3"
          y1="422.6"
          x2="321"
          y2="421.1"
        />
        <path
          id="Thorax40"
          class="Thorax_exspiration_lat_stehend2106_cls5"
          d="M42,641c.6-10.6,1.6-25,3-42,2.7-31.7,5.7-55.4,8-74,2.1-16.8,5.8-46,12-83,5.7-34.3,10.3-62,19-98,11.5-47.6,19.3-80,39-120,8.8-17.9,17.4-32,32-56,12.9-21.2,25.3-41.5,44-66,14.7-19.2,28.1-34.4,38-45"
        />
        <path
          id="Thorax39"
          class="Thorax_exspiration_lat_stehend2106_cls5"
          d="M526,51c4.8,10.2,11.1,25.2,16,44,7.3,28.1,8,50.7,8,80,0,45.6-1.6,80.5-3,110-3,64-4.6,96.4-6,111-2,19.8-5.7,57.2-15,105-9.8,50.7-16.8,66.6-22,110-3.4,28.3-4,51.7-4,67"
        />
        <path
          id="Thorax38"
          class="Thorax_exspiration_lat_stehend2106_cls5"
          d="M506,139c6.3,12.5,12.4,27.5,17,45,2.4,9.3,3.9,21.2,7,45,2.5,19.6,2.7,26.1-1,33-3.9,7.2-9.9,10.8-19,16-14.7,8.5-21.8,8.2-40,15-7.5,2.8-17,7.5-36,17-18.5,9.2-26.7,14-40.2,15-7.7.6-15.5,1.1-20.8-4-9.5-9.2-2.5-29-1.8-31,3.3-9,7.1-10.5,12.8-20,5.6-9.5,6.5-15.9,10.6-33.5,10.2-43.5,12.6-36.9,13.3-50.8.8-15.1-2-24.2,1.1-46.8.7-5.1,1.5-9.2,2-11.9"
        />
        <path
          id="Thorax37"
          class="Thorax_exspiration_lat_stehend2106_cls5"
          d="M407,103c1.2,26.5-1.4,47.6-4,62-3.9,21.2-6.1,18.4-10,41-3.7,21.6-3,31.9-12,41-4.6,4.6-9,6.3-12,13-3.6,8.1-1.9,16.1-1,20,.8,3.8,3.5,15.1,14,23,2.6,2,10.7,8,21,7,2.3-.2,9.1-1.2,23-15,6-6,14.2-15.1,22-28"
        />
        <path
          id="Thorax36"
          class="Thorax_exspiration_lat_stehend2106_cls5"
          d="M326,44c.1,10.7.2,27,0,47-.3,26.1-.7,27.6-1,53-.6,46.6.9,46.8-1,64-2.5,23.3-5.1,41.6-5.1,41.6-1.5,10.4-2.6,17.1-2.6,27.2s.1,15.1,2.8,27.3c2.1,9.3,7,25.5,19.9,43.1"
        />
        <path
          id="Thorax35"
          class="Thorax_exspiration_lat_stehend2106_cls5"
          d="M286,48c.2,5.7.5,14.4,1,25,1.4,30.6,2.3,38.9,3,56,.5,11.7.3,26.4,0,56-.4,35.9-1,42,0,60,.7,12.4.7,4.1,4,40,1.1,12.4,1.6,18.6,5,27,2.5,6.2,4.6,8.8,10,19,4.6,8.7,7.9,16.1,10,21"
        />
        <path
          id="Thorax34"
          class="Thorax_exspiration_lat_stehend2106_cls5"
          d="M294,285c-.8,9.4-1.8,19.1-3,29-1.5,12.1-3.1,23.8-5,35"
        />
        <path
          id="Thorax33"
          class="Thorax_exspiration_lat_stehend2106_cls5"
          d="M322,256c-.1,5.8-.4,13.1-1.2,21.6-.7,6.5-1.9,18.5-5.7,33.3-3.9,15.1-6.3,17.2-9.4,31.2-2.3,10.1-3.2,18.5-3.6,23.8"
        />
        <path
          id="Thorax32"
          class="Thorax_exspiration_lat_stehend2106_cls5"
          d="M131,568.2c5.3,5.8,15.7,3.9,22,3,9.1-1.3,26.4.2,61,3,29.9,2.5,38.8,6.8,56,6,8.2-.4,13.9-1.7,24,0,7,1.2,11.3,3,13,1,2.9-3.5-4.9-14.5-6-16-9.3-13.1-21.5-19.7-29.9-24-25.7-13.2-40.3-20.9-56.1-23-15.2-2.1-34.1-4.7-49.4,5.4-7.9,5.2-9.6,10.2-22.6,17.6-6.7,3.8-11.6,5.5-14,11-2.2,5-1.7,12,2,16Z"
        />
        <path
          id="Thorax31"
          class="Thorax_exspiration_lat_stehend2106_cls4"
          d="M511,183c2,9.6,6,15.5,9,19,7.8,9,15.9,9.3,19,18,1.6,4.5.9,8.5,0,14-1.4,8.6-4.9,15.1-7.3,18.9"
        />
        <path
          id="Thorax30"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M348,496.7c0,6.1,39-7.7,59.4,14.3"
        />
        <path
          id="Thorax29"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M355.3,436.2c17.9-2,57.5-1.5,63,18"
        />
        <path
          id="Thorax28"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M346.9,511c0,7.7,27,14,60.4,14"
        />
        <path
          id="Thorax27"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M377.4,341.5c0,3.8,45.6,15.5,57.8,6.9"
        />
        <path
          id="Thorax26"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M381,188s19.4,9,51.6,0"
        />
        <path
          id="Thorax25"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M429.2,393.8c0-9.1-17.5-11.2-52.6-11.2"
        />
        <path
          id="Thorax24"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M424.3,409.3c0-9.5-24.6-17.1-55-17.1"
        />
        <path
          id="Thorax23"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M418.3,465.1c0-11.2-24.3-16.5-54.3-16.5"
        />
        <path
          id="Thorax22"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M399,576c0-9.6-26.9-17.3-60.1-17.3"
        />
        <path
          id="Thorax21"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M403.7,531c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <path
          id="Thorax20"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M416.5,467.4c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <path
          id="Thorax19"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M425.5,414.1c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <path
          id="Thorax18"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M436.1,353.4c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <path
          id="Thorax17"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M439.6,297.6c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <path
          id="Thorax16"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M438.6,241.2c-6.9,18.2-1.5,40.4-6.9,40.4"
        />
        <line
          id="Thorax15"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          x1="432.6"
          y1="192.5"
          x2="435"
          y2="218"
        />
        <line
          id="Thorax14"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          x1="425.5"
          y1="135.6"
          x2="430"
          y2="176"
        />
        <line
          id="Thorax13"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          x1="416.5"
          y1="96.9"
          x2="421"
          y2="126"
        />
        <line
          id="Thorax12"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          x1="355.3"
          y1="90.6"
          x2="407.4"
          y2="78.1"
        />
        <line
          id="Thorax11"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          x1="364"
          y1="103.3"
          x2="416.5"
          y2="92"
        />
        <path
          id="Thorax10"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M382,235c0-5,26.8-9,60-9"
        />
        <path
          id="Thorax9"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M338,45c-7.6,9.4-15,15.5-20.2,19.4-18.8,13.7-31.4,13.3-37.8,26.6-2.8,5.8-2.4,10-7.4,15s-12.1,8.4-17.6,6c-3.4-1.5-2.6-3.9-8-11-5.6-7.3-8.2-7.1-10-12-1.6-4.4-1.5-10.4,1.7-14,2.5-2.8,5.8-2.8,10.6-3.6,0,0,10.5-1.7,25.7-9.4,4.6-2.3,11-6.2,18-13"
        />
        <path
          id="Thorax8"
          class="Thorax_exspiration_lat_stehend2106_cls2"
          d="M201.5,169.8c5.8-4.6,10.8-7.7,14.5-9.8,7.8-4.4,11-6,11-6,6.4-3.1,7.6-3.1,9-5,2.9-4.1-.5-7.5,2-13,2-4.3,4.6-3.4,8-8,.4-.5,6.9-9.6,3-18-3.2-6.9-11.3-8.5-12.8-8.8-8.3-1.7-15,2.6-17.3,4.1-3.1,2-7.1,5.4-13.6,18.2-5.2,10.2-6,14.6-8.8,20.9-11.3,25.4-37.1,41.4-37.5,41.7h0l-2.3,10.9"
        />
        <polyline
          id="Thorax7"
          class="Thorax_exspiration_lat_stehend2106_cls4"
          points="90 619.5 81 630 69.9 634 66 626.8 60 619.5 64 614.7 69.9 606"
        />
        <path
          id="Thorax6"
          class="Thorax_exspiration_lat_stehend2106_cls4"
          d="M55,620v-19l-9-13.3,4.5-12.7,4.5-16.3,5-16.7v-35.7l9.9-9.6v-42.4c5.6-1.6,11.3-3.2,16.9-4.9-11.9-1.9-14.6-4.4-14.8-6.4-.3-2.3,2.7-3.2,4-8,1.1-3.8-.5-4.6,0-10,.3-3,.7-7.6,4-11,2.6-2.7,5.8-3.5,7.6-3.8"
        />
        <path
          id="Thorax5"
          class="Thorax_exspiration_lat_stehend2106_cls4"
          d="M118,526c-2.3,1.3-4.7,2.7-7,4h0c-2.4,7.5-7.8,11.8-12,11-4.4-.8-6.7-7.2-7-8-1.6-4.5,0-6.3-1-11-1.2-5.8-4.3-6.5-7-12-5-10.1-1.6-22-1-22s1.2,9,1,9c-.3,0-4.2-11.7-2-24,1.4-7.8,4.9-13.8,8-18"
        />
        <path
          id="Thorax4"
          class="Thorax_exspiration_lat_stehend2106_cls4"
          d="M121,558c-2.4.7-6,1.8-10,4-7.1,3.9-8.7,7.4-12,7-3.5-.5-5.6-4.9-9-12-2.9-6.2-4.3-11.6-5-15-1.5-7.3-.5-8-2-16-.6-3-.7-3-4-15-3.7-13.6-3.9-15.1-4-17-.2-5.9,1.1-5.8,1-14,0-6.5-1-8.4,0-12,1.3-4.8,4.6-8,7-10"
        />
        <path
          id="Thorax3"
          class="Thorax_exspiration_lat_stehend2106_cls4"
          d="M72,503c.9,2.9,1.7,7.1,1,12,0,.7-.3,2.3-3,10-3.2,9.4-3.6,9.3-4,12-.6,4.2.3,4.4,0,12-.2,4.7-.3,7-1,10-1.4,5.8-3.6,7.1-6,13-.2.4-2.6,6.4-3,13-.6,9.2,2.9,16.9,6,22"
        />
        <path
          id="Thorax2"
          class="Thorax_exspiration_lat_stehend2106_cls4"
          d="M137.5,250.1c-2.8,2-5.7,4-8.5,5.9h0c.4.8,3.5,8.5-1,16-3.6,6.1-5.8,9.3-7,9.6"
        />
        <path
          id="Thorax1"
          class="Thorax_exspiration_lat_stehend2106_cls4"
          d="M112.3,310.1c-2.4,2.6-5.8,6.9-8.3,12.9-1.2,2.9-2.7,6.4-3,11-.7,9.4,4.1,12,2,17-2.3,5.5-8.8,4.3-11,10-1.5,4,.8,6.7,1.6,11.8.8,4.9,0,12-6.3,21.5"
        />
      </g>
    </svg>
  );
}
