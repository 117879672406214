import { Modal } from "react-bootstrap";
import ExamList from "../ExamsList/ExamList";
import "./styles/ExamsListModal.scss";

function ExamsListModal({
  shown,
  hideHandler,
  selectHandler = () => {},
  selected = undefined,
  dark = false,
}) {
  const selectExam = (exam) => {
    selectHandler(exam);
    hideHandler();
  };

  return (
    <Modal size="lg" show={shown} onHide={hideHandler} className={``}>
      <Modal.Header
        className={`p-2 d-flex align-items-center ${
          dark && "bg-dark text-white"
        }`}
      >
        <h5>Defined Examinations</h5>
        <div className="ml-auto">
          <i
            onClick={hideHandler}
            title="Close"
            className="fas fa-times fa-lg icon-button"
          />
        </div>
      </Modal.Header>
      <Modal.Body className={`${dark && "bg-dark text-white"}`}>
        <ExamList selectHandler={selectExam} selected={selected} dark={dark} />
      </Modal.Body>
    </Modal>
  );
}

export default ExamsListModal;
