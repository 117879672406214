import style from "./style.scss";

export default function RechtesSternoclaviculargelenkDvOblLiegend2033(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 618.31 385.34">
      <g id="underlays">
        <ellipse
          id="sternumcavicular_joint_1"
          data-name="sternumcavicular joint 1"
          class="Sternoclaviculagelenke2033Fill"
          style={{
            fill: props.colors.Sternoclaviculagelenke2033Fill,
            stroke: props.colors.Sternoclaviculagelenke2033Fill,
          }}
          cx="207.72"
          cy="101.84"
          rx="70.4"
          ry="51.63"
          transform="translate(3.3 210.17) rotate(-54.04)"
        />
      </g>
      <g id="sternum_recording" data-name="sternum recording">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls5"
            d="M382.46,7.56c9.86-3.52,20.02-3.1,58,2,20.78,2.79,31.16,3.54,32,8,.61,3.25-4.43,5.39-12,15-4.65,5.9-5.18,8.18-10,16-11.37,18.46-15.29,16.79-25,33-6.79,11.34-7.64,18.03-13,19-5.91,1.06-7.37-6.96-22.17-14.78-.57-.3.08.05-19.83-8.22-7.55-3.14-8.64-3.6-11-4-7.8-1.32-12.52.95-15-2-1.15-1.36-.57-2.37-1-7-.73-7.85-2.75-8.89-3-14-.29-6.03,2.22-10.71,4-14,3.81-7.07,9.12-10.95,12-13,4.66-3.32,6.15-3.04,13-7,6.98-4.03,10.3-8.04,13-9h0Z"
          />
        </g>
        <g id="sternum">
          <path
            id="sternum_1"
            data-name="sternum 1"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls4"
            d="M148.97,185.57c-7.27-10.77-2.93-30.79,7.11-35.98,6.25-3.23,9.75,1.87,22,.07,6.77-.99,7.02-2.74,23.03-9.93,18.17-8.16,22.18-7.85,27.04-13.92,2.68-3.35,4.14-7.23,7.05-14.98,4.12-10.97,3.08-13.22,6.06-19.98,2.39-5.43,7.19-16.31,16.06-18.95,7.79-2.32,10.88,4.06,31.97,10.1,16.09,4.61,21.6,2.99,23.99,2.07,7.34-2.83,6.82-7.31,14.03-10.96,8.39-4.25,17.15-2.25,31,1.1,16.05,3.88,27.47,9.78,29.97,11.09,17.09,8.97,17.29,13.88,32.94,20.1,9.58,3.81,16.89,6.6,24.99,4.08,7.09-2.21,10.05-7.02,15.02-5.95,5.44,1.17,8.87,8.44,10.01,13.19,6.07,25.36-36.64,47.38-29.26,68.75,1.26,3.64,2.77,3.8,8.95,16.03,7.24,14.31,7.17,18.05,6.94,20.02-.87,7.45-6.24,8.05-11.05,16.97-7.06,13.08-4.11,27.71-3.24,32.02,3.53,17.5,13.54,20.17,11.05,29.01-1.4,4.98-4.6,4.16-25.06,19.92-8.12,6.26-9.76,8.03-14.03,8.96-8.64,1.87-12-3.22-21.99-3.07-9.07.14-10.16,4.4-22.02,7.93-10.41,3.1-11.11.27-31.01,1.9-19.88,1.63-24.92,4.93-33,.9-3.36-1.68-3.99-3-11.96-12.04-17.98-20.41-18.77-18.28-28.9-31.09-11.57-14.64-10.21-17.02-16.93-21.05-8.42-5.05-16.06-4.62-18.97-11.06-2.14-4.73.95-7.24-.96-13-1.92-5.79-6.55-7.84-13.96-13.04-9.19-6.44-4.96-5.3-22.94-20.07-10.27-8.44-15.4-12.65-20.95-16.07-14.77-9.09-18.92-7.09-22.96-13.07h-.02Z"
          />
          <path
            id="sternum_2"
            data-name="sternum 2"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls4"
            d="M459.37,384.52c1.04-3.83,1.64-8.72-.96-12-2.27-2.86-4.87-1.84-9.98-5.03-5.9-3.68-8.91-9.08-9.97-11.03-2.97-5.47-1.72-7.23-3.97-10.01-3.9-4.81-11.59-4.38-18.99-4.06-30.6,1.32-21.76-.75-37,.89-17.41,1.87-29.86,4.66-35.02,5.89-23,5.46-28.88,10.06-31.04,11.9-7.46,6.33-11.18,14.01-13.06,18.96"
          />
        </g>
        <g id="collarbone">
          <path
            id="collarbone_1"
            data-name="collarbone 1"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls4"
            d="M40.37,61.24c14.3,5.82,26.15,11.08,34.95,15.11,14.83,6.8,22.41,10.29,29.95,15.09,11.67,7.41,11.86,9.68,23.95,17.07,7.02,4.29,6.49,3.24,25.96,13.08,19.97,10.09,23.06,12.47,29.96,12.09,1.57-.09,12.76-.87,19.03-8.94,3.1-3.99,2.2-6.16,5.06-17.98,2.76-11.42,6.25-20.3,8.08-24.98,4.74-12.11,6.35-12.48,8.06-19.98,1.54-6.72,3.14-13.73.07-21-4.18-9.88-14.25-13.79-14.96-14.05-14.52-5.38-22.9,7.51-46.03,10.86-12.74,1.85-22.49-.48-41.98-5.13-21.08-5.03-36.46-12.48-45.95-17.14-10.46-5.14-18.89-10.19-24.96-14.08"
          />
          <line
            id="collarbone_2"
            data-name="collarbone 2"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls2"
            x1="413.55"
            y1="101.84"
            x2="472.16"
            y2="18.57"
          />
          <path
            id="collarbone_3"
            data-name="collarbone 3"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls2"
            d="M344.48,71.24c-16-45.12,22.02-51,36.49-61.96"
          />
          <path
            id="collarbone_4"
            data-name="collarbone 4"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M413.54,101.84c10.74-34.34,34.7-68.75,47.83-83.31"
          />
          <path
            id="collarbone_5"
            data-name="collarbone 5"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M193.15,122.79c69.15-38.87,22.29-82.98,22.29-82.98"
          />
          <path
            id="collarbone_6"
            data-name="collarbone 6"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M48.54,8.25c55.21,29.33,85.23,51,144.87,31.49"
          />
          <path
            id="collarbone_7"
            data-name="collarbone 7"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M40.41,47.23c28.82,11.04,94.91,37.46,128.57,81.4"
          />
          <path
            id="collarbone_8"
            data-name="collarbone 8"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls3"
            d="M344.48,71.24c8.93,39.28,43.19,61.41,69.07,30.6"
          />
        </g>
        <g id="ribs">
          <path
            id="rib_1"
            data-name="rib 1"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M16.9,214.16c70.15-10.06,437.8,68.48,573.47,170.78"
          />
          <path
            id="rib_2"
            data-name="rib 2"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M565.76,258.86c-33.94-18.11-221.6-84.97-432.13-156.34"
          />
          <path
            id="rib_3"
            data-name="rib 3"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M616.05,167.02c5.29-15.64-155.63-131.57-353.49-165.1"
          />
          <path
            id="rib_4"
            data-name="rib 4"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M462.3,86.54c20.82-19.86,27.39-58.08,35.21-67.89"
          />
          <path
            id="rib_5"
            data-name="rib 5"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M479.42,204.82c61.61-51.16,72.47-151.4,82.06-178.97"
          />
          <path
            id="rib_6"
            data-name="rib 6"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M617.96,201.01c-10.51,10.22-20.16,16.79-27.07,20.92-16.25,9.71-26.42,11.32-33.07,22.9-1.42,2.48-5.58,9.96-4.06,18.99,1.62,9.66,8.94,16.23,10.94,18.03,6.82,6.12,11.11,5.35,13.97,11.04,1.91,3.81.27,4.71,1.96,13.01.95,4.67,2.28,8.12,4.95,15.02,3.93,10.17,4.88,10.14,5.95,15.02,1.3,5.92.04,6.57.95,15,.63,5.85,1.85,17.22,5.94,18.02,1.45.28,2.46-.92,8.02-5.98,3.74-3.4,6.88-6.13,9.02-7.97"
          />
          <path
            id="rib_7"
            data-name="rib 7"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M24.55,165.18c5.03,5.77,28.6,13.39,47.42,25.65"
          />
          <path
            id="rib_8"
            data-name="rib 8"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls3"
            d="M34.26,97.2c9.34,7.68,31.95,19.97,46.9,31.15"
          />
          <path
            id="rib_9"
            data-name="rib 9"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M10.82,239.14c7.75,15.62,51.9,35.29,96.86,46.3"
          />
          <path
            id="rib_10"
            data-name="rib 10"
            class="Rechtes_Sternoclaviculargelenk_dv_obl_liegend2033_cls1"
            d="M.5,340.91c.75,6.61,21.84,17.34,46.92,26.34"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="sternumcavicular_joint_2"
          data-name="sternumcavicular joint 2"
          class="Sternoclaviculagelenke2033Fill"
          style={{
            fill: props.colors.Sternoclaviculagelenke2033Fill,
            stroke: props.colors.Sternoclaviculagelenke2033Fill,
          }}
          d="M421.95,87.93c.08,11.57-3.41,20.01-15.47,20.89-18.35-13.89-39-24.85-61.99-32.82-5.83-4.42-3.79-12.44,0-15.8,24.43,12.39,49.77,23.99,77.47,27.72Z"
        />
      </g>
    </svg>
  );
}
