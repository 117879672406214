import { Button } from "react-bootstrap";
import useWindowSize from "../../../Utilities/Window/windowSize";
import { useDispatch, useSelector } from "react-redux";
import {
  clearExamplannerState,
  clearOpenedPatient,
  clearExamplannerBodypart,
  clearExamplannerDeviceId,
  clearExamplannerExamination,
  clearExamplannerFacilityDocId,
  clearExamplannerFacilityId,
  clearExamplannerIndication,
  clearExamplannerRadiologistId,
  setExamplannerPatientId,
  setOpenedPatient,
} from "../../../Redux/actions.js";
import ResourcesModalitiesModal from "../../ResourcesModalitiesModal/ResourcesModalitiesModal";
import SaveOrderModal from "./SaveOrderModal";
import FacilitiesSelectorModal from "../../FacilitiesSelectorModal/FacilitiesSelectorModal";
import RefferingPhysicianSelector from "../../RefferingPhysicianSelector/RefferingPhysicianSelector";
import SelectPatientModal from "../../SelectPatientModal/SelectPatientModal";
import FindingDoctor from "../../FindingDoctor/FindingDoctor";
import { useState } from "react";
import { useEffect } from "react";

export default function ExamPlannerController(props) {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const [selectPatientModalShown, setSelectPatientModalShown] = useState(false);
  const [resourcesModalShown, setResourcesModalShown] = useState(false);
  const [saveOrderModalShown, setSaveOrderModalShown] = useState(false);
  const [facilitiesModalShown, setFacilitiesModalShown] = useState(false);
  const [facilitiesDocModalShown, setFacilitiesDocModalShown] = useState(false);
  const [findingDocModalShown, setFindingDocModalShown] = useState(false);

  const selectedFacility = useSelector(
    (state) => state.examPlanner.risData.facilityId
  );

  const selectPatientHandler = (patData) => {
    dispatch(clearOpenedPatient());
    dispatch(setExamplannerPatientId(patData.id));
    dispatch(setOpenedPatient(patData.id));
    setSelectPatientModalShown(false);
  };

  const getControllerHeight = () => {
    if (windowSize.width < 1200) {
      return "10vh";
    } else {
      return "7vh";
    }
  };

  const shouldShowDescription = windowSize.width >= 980;

  const clearAll = () => {
    dispatch(clearOpenedPatient());
    dispatch(clearExamplannerState());
  };

  useEffect(() => {
    let actionsOnMount = [
      clearExamplannerBodypart,
      clearExamplannerDeviceId,
      clearExamplannerExamination,
      clearExamplannerFacilityDocId,
      clearExamplannerFacilityId,
      clearExamplannerIndication,
      clearExamplannerRadiologistId,
    ];

    actionsOnMount.forEach((action) => {
      dispatch(action());
    });
  }, []);

  return (
    <>
      <SelectPatientModal
        shown={selectPatientModalShown}
        close={() => {
          setSelectPatientModalShown(false);
        }}
        amount={5}
        rowClickHandler={selectPatientHandler}
      />
      <ResourcesModalitiesModal
        shown={resourcesModalShown}
        close={() => setResourcesModalShown(false)}
      />
      <SaveOrderModal
        shown={saveOrderModalShown}
        close={() => setSaveOrderModalShown(false)}
        resourcesModalShowHandler={() => setResourcesModalShown(true)}
        selectPatientModalShowHandler={() => {
          setSelectPatientModalShown(true);
        }}
      />
      <FacilitiesSelectorModal
        shown={facilitiesModalShown}
        closeHandler={() => {
          setFacilitiesModalShown(false);
        }}
      />
      <RefferingPhysicianSelector
        shown={facilitiesDocModalShown}
        closeHandler={() => {
          setFacilitiesDocModalShown(false);
        }}
      />
      <FindingDoctor
        shown={findingDocModalShown}
        closeHandler={() => {
          setFindingDocModalShown(false);
        }}
      />
      <div
        className="d-flex w-100 bg-white align-items-center rounded mt-1 px-2"
        name="PatientHeader"
        style={{ height: getControllerHeight() }}
      >
        <Button className="mr-1" onClick={clearAll}>
          {shouldShowDescription && "Clear all"}
          <i className={`fa fa-broom ${shouldShowDescription && "ml-2"}`} />
        </Button>
        <div>|</div>
        <Button
          className="ml-1 mr-1"
          onClick={() => setResourcesModalShown(true)}
        >
          {shouldShowDescription && "Select Device"}
          <i
            className={`fa fa-laptop-medical ${
              shouldShowDescription && "ml-2"
            }`}
          />
        </Button>
        <div>|</div>
        <Button
          className="mr-1 ml-1"
          onClick={() => {
            setFacilitiesModalShown(true);
          }}
        >
          {shouldShowDescription && "Ref Facility"}
          <i className={`fa fa-hospital ${shouldShowDescription && "ml-2"}`} />
        </Button>
        <Button
          className="mr-1"
          disabled={selectedFacility === null}
          onClick={() => setFacilitiesDocModalShown(true)}
        >
          {shouldShowDescription && "Ref Physician"}
          <i className={`fa fa-user-md ${shouldShowDescription && "ml-2"}`} />
        </Button>
        <div>|</div>

        <Button
          className="mr-1 ml-1"
          onClick={() => setFindingDocModalShown(true)}
        >
          {shouldShowDescription && "Study Reading"}
          <i
            className={`fa fa-stethoscope ${shouldShowDescription && "ml-2"}`}
          />
        </Button>
        <Button
          className="ml-auto"
          onClick={() => setSaveOrderModalShown(true)}
        >
          {shouldShowDescription && "Save"}
          <i className={`fa fa-save ${shouldShowDescription && "ml-2"}`} />
        </Button>
      </div>
    </>
  );
}
