import style from "./style.scss";

function RechterVorfussLatLiegend1432(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 442.42 171.88">

    <g id="fore_foot" data-name="fore foot">
      <g id="background_layer" data-name="background layer">
        <path id="background_1" data-name="background 1" class="vorfuss_lat_rechts1432_cls_3" d="M442.42,1.83c-8.2-2-13.8-1.1-16.6,3.4-33.3,14.1-44.2,36.4-40,64.3,5.4,30.2,25.5,42.8,55,44.2"/>
        <path id="background_2" data-name="background 2" class="vorfuss_lat_rechts1432_cls_3" d="M381.52,107.83h0c-11.7-19.2-18.8-42.4-12.4-77,5.6-21.5-19.4-16.5-50.3-6.4-55.8,16.3-106.6,26.6-150.3,28.6-19.3-7.6-31.9-2.3-39.9,12-3.8,6.9-4.5,15.3-2.2,22.9,7.6,25.7,17.5,33.9,29.7,23.4,6.5-5.6,14.1-9.8,22.5-12,51-13,104.2-3.8,149.1,22.5,9.4,5.5,19.8,9.1,30.6,9.9,22.7,1.6,31.1-5.9,23.2-23.9h0Z"/>
        <path id="background_3" data-name="background 3" class="vorfuss_lat_rechts1432_cls_3" d="M395.32,126.83c6.3,19.1,20.1,32.4,44,37.7,1.3-40.3,2.2-80.6,2.7-120.9-46.3,19.1-57.3,48.1-46.7,83.2Z"/>
        <path id="background_4" data-name="background 4" class="vorfuss_lat_rechts1432_cls_3" d="M439.12,171.43c.9-25.5,1.6-51,2.1-76.6-15.8,8-27,16.1-31.7,24.3-18.1,21.5-1.4,37.9,29.6,52.3"/>
        <path id="background_5" data-name="background 5" class="vorfuss_lat_rechts1432_cls_3" d="M334.02,82.03h0l-124.3-2.5c-6.8-.2-13.3,3.8-15.4,10.3-3.2,9.8,5.7,17.6,26.8,23.4,50.7-2.9,97.7,10.3,142.3,33.5,22.8,17.3,30.8,4.2,27.2-32.7,17.4-22,2.2-33.8-56.6-32h0Z"/>
        <path id="background_6" data-name="background 6" class="vorfuss_lat_rechts1432_cls_3" d="M186.22,106.13h0l-2.4-14.6c-1.8-16.6-18.3-8.9-39.6,6.7-10.8,9.8-14.7,16.8-18,23.5,4.1,18.1,17.9,15,36.6,1.1,21.1-4.2,37.4-9,23.4-16.7h0Z"/>
        <path id="background_7" data-name="background 7" class="vorfuss_lat_rechts1432_cls_3" d="M323.22,150.43h0c11.4.6,21.3-7.7,22.6-18.9h0c-2.5-7.3-9.9-13.6-29.5-17.9-11.8-2.6-23.4-5.7-34.8-9.5-5.7-1.9-11-3.3-15.9-4.2-28.2-5.1-40.3,34.1-14.2,45.8.6.2,1.1.5,1.7.7l70.1,4h0Z"/>
        <path id="background_8" data-name="background 8" class="vorfuss_lat_rechts1432_cls_3" d="M210.42,127.23h0l-33.2,22.8c-13.6,13.6,3.8,18,47.4,14.6,17.9-5,33.7-11,7.1-36,3.9-25.3-5.1-21.4-21.3-1.4h0Z"/>
        <path id="background_9" data-name="background 9" class="vorfuss_lat_rechts1432_cls_3" d="M66.32,144.13c-1.4-5.1.9-10.4,5.1-13.5,18.9-14.3,37.1-19.8,54.6-16-5.4,11.4-3,19.7,9.6,23.6-11.3,10.9-27.1,17.1-48,18-11.7-1.3-19.3-5.1-21.3-12.1h0Z"/>
      </g>
      <path id="fore_foot_5" data-name="fore foot 5" class="vorfuss_lat_rechts1432_cls_5" d="M436.22.93c-3.4-1.3-6.4.7-9.4,1.7-2,2-4,4-6,5-18,7-32,22-36,41-2,16,0,32,10,45,11,14,28,19,45.4,20.1"/>
      <path id="fore_foot_7" data-name="fore foot 7" class="vorfuss_lat_rechts1432_cls_5" d="M441.42,46.53c-.8-.4-1.5-1.1-1.6-2.2-44.9,19.1-55.5,47.8-45,82.5,6.3,19.1,20.1,32.4,44,37.7"/>
      <path id="fore_foot_10" data-name="fore foot 10" class="vorfuss_lat_rechts1432_cls_5" d="M440.62,94.83c-15.8,8-27,16.1-31.7,24.3-18.1,21.5-1.4,37.9,29.6,52.3"/>
      <path id="fore_foot_1" data-name="fore foot 1" class="vorfuss_lat_rechts1432_cls_6" d="M366.82,21.83c-10.3,15.5-14.9,35.1-12.9,59.8,2.7,18.5,12.4,33,24.8,45.7"/>
      <path id="fore_foot_2" data-name="fore foot 2" class="vorfuss_lat_rechts1432_cls_7" d="M120.72,102.83c-6.1-5.2-9.3-13.6-8.6-26.7.2-3.6-3.7-6.1-6.9-4.4-29.4,15.7-58.6,19.8-87.6,16.3-6.6-.9-13.4,2.4-16.1,8.6-2.8,6.5,2.1,10.5,14.3,12l87.7,19.5c26.3,5.3,35.9-.6,17.2-25.3h0Z"/>
      <path id="fore_foot_6" data-name="fore foot 6" class="vorfuss_lat_rechts1432_cls_6" d="M380.92,107.83h0c-11.7-19.2-18.7-42.4-12.4-77,5.6-21.5-19.4-16.5-50.3-6.4-55.8,16.3-106.6,26.6-150.3,28.6-19.3-7.6-31.9-2.3-39.9,12-3.8,6.9-4.5,15.3-2.2,22.9,7.6,25.7,17.5,33.9,29.7,23.4,6.5-5.6,14.1-9.8,22.5-12,51-13,104.2-3.8,149.1,22.5,9.4,5.5,19.8,9.1,30.6,9.9,22.7,1.6,31.1-5.9,23.2-23.9h0Z"/>
      <path id="fore_foot_11" data-name="fore foot 11" class="vorfuss_lat_rechts1432_cls_5" d="M333.52,82.03h0l-124.3-2.5c-6.8-.2-13.3,3.8-15.4,10.3-3.2,9.8,5.7,17.6,26.8,23.4,50.7-2.9,97.7,10.3,142.3,33.5,22.8,17.3,30.8,4.2,27.2-32.7,17.4-22,2.2-33.8-56.6-32h0Z"/>
      <path id="fore_foot_12" data-name="fore foot 12" class="vorfuss_lat_rechts1432_cls_5" d="M185.72,106.13h0l-2.4-14.6c-1.8-16.6-18.3-8.9-39.6,6.7-10.8,9.8-14.7,16.8-18,23.5,4.1,18.1,17.9,15,36.6,1.1,21.1-4.2,37.4-9,23.4-16.7h0Z"/>
      <path id="fore_foot_13" data-name="fore foot 13" class="vorfuss_lat_rechts1432_cls_5" d="M322.72,150.33h0c11.3.7,21.2-7.6,22.6-18.9h0c-2.5-7.3-9.9-13.6-29.5-17.9-11.8-2.6-23.4-5.7-34.8-9.5-5.7-1.9-11-3.3-15.9-4.2-28.2-5.1-40.3,34.1-14.2,45.8.6.2,1.1.5,1.7.7l70.1,4h0Z"/>
      <path id="fore_foot_14" data-name="fore foot 14" class="vorfuss_lat_rechts1432_cls_5" d="M209.82,127.23h0l-33.2,22.8c-13.6,13.6,3.8,18,47.4,14.6,17.9-5,33.7-11,7.1-36,3.9-25.3-5.1-21.4-21.3-1.4h0Z"/>
      <path id="fore_foot_15" data-name="fore foot 15" class="vorfuss_lat_rechts1432_cls_5" d="M65.72,144.13c-1.4-5.1,1-10.4,5.1-13.5,18.9-14.3,37.1-19.8,54.6-16-5.4,11.4-3,19.7,9.6,23.6-11.3,10.9-27.1,17.1-48,18-11.7-1.3-19.3-5.1-21.3-12.1h0Z"/>
    </g>
    <g id="overlay">
      <g id="Phalangen_overlay" data-name="Phalangen overlay" class="vorfuss_lat_rechts1432_cls_1">
        <path id="Phalangen_overlay_1" data-name="Phalangen overlay 1" class="phalangen1432Fill" style= {{
                fill: props.colors.phalangen1432Fill,
                stroke: props.colors.phalangen1432Fill,
                opacity:props.colors.phalangen1432Fill,
              }} d="M120.22,102.83c-6.1-5.2-9.3-13.6-8.6-26.7.2-3.6-3.7-6.1-6.9-4.4-29.4,15.7-58.6,19.8-87.6,16.3-6.6-.9-13.4,2.4-16.1,8.6-2.8,6.5,2.1,10.5,14.3,12l87.7,19.5c26.3,5.3,35.9-.6,17.2-25.3h0Z"/>
        <path id="Phalangen_overlay_2" data-name="Phalangen overlay 2" class="phalangen1432Fill" style= {{
                fill: props.colors.phalangen1432Fill,
                stroke: props.colors.phalangen1432Fill,
                opacity:props.colors.phalangen1432Fill,
              }} d="M380.42,107.83h0c-11.7-19.2-18.7-42.4-12.4-77,5.6-21.5-19.4-16.5-50.3-6.4-55.8,16.3-106.6,26.6-150.3,28.6-19.3-7.6-31.9-2.3-39.9,12-3.8,6.9-4.5,15.3-2.2,22.9,7.6,25.7,17.5,33.9,29.7,23.4,6.5-5.6,14.1-9.8,22.5-12,51-13,104.2-3.8,149.1,22.5,9.4,5.5,19.8,9.1,30.6,9.9,22.7,1.6,31.1-5.9,23.2-23.9h0Z"/>
        <path id="Phalangen_overlay_3" data-name="Phalangen overlay 3" class="phalangen1432Fill" style= {{
                fill: props.colors.phalangen1432Fill,
                stroke: props.colors.phalangen1432Fill,
                opacity:props.colors.phalangen1432Fill,
              }} d="M333.02,82.03h0l-124.3-2.5c-6.8-.2-13.3,3.8-15.4,10.3-3.2,9.8,5.7,17.6,26.8,23.4,50.7-2.9,97.7,10.3,142.3,33.5,22.8,17.3,30.8,4.2,27.2-32.7,17.4-22,2.2-33.8-56.6-32h0Z"/>
        <path id="Phalangen_overlay_4" data-name="Phalangen overlay 4" class="phalangen1432Fill" style= {{
                fill: props.colors.phalangen1432Fill,
                stroke: props.colors.phalangen1432Fill,
                opacity:props.colors.phalangen1432Fill,
              }} d="M185.22,106.13h0l-2.4-14.6c-1.8-16.6-18.3-8.9-39.6,6.7-10.8,9.8-14.7,16.8-18,23.5,4.1,18.1,17.9,15,36.6,1.1,21.1-4.2,37.4-9,23.4-16.7h0Z"/>
        <path id="Phalangen_overlay_5" data-name="Phalangen overlay 5" class="phalangen1432Fill" style= {{
                fill: props.colors.phalangen1432Fill,
                stroke: props.colors.phalangen1432Fill,
                opacity:props.colors.phalangen1432Fill,
              }} d="M322.22,150.33h0c11.3.7,21.2-7.6,22.6-18.9h0c-2.5-7.3-9.9-13.6-29.5-17.9-11.8-2.6-23.4-5.7-34.8-9.5-5.7-1.9-11-3.3-15.9-4.2-28.2-5.1-40.3,34.1-14.2,45.8.6.2,1.1.5,1.7.7l70.1,4h0Z"/>
        <path id="Phalangen_overlay_6" data-name="Phalangen overlay 6" class="phalangen1432Fill" style= {{
                fill: props.colors.phalangen1432Fill,
                stroke: props.colors.phalangen1432Fill,
                opacity:props.colors.phalangen1432Fill,
              }} d="M209.32,127.23h0l-33.2,22.8c-13.6,13.6,3.8,18,47.4,14.6,17.9-5,33.7-11,7.1-36,3.9-25.3-5.1-21.4-21.3-1.4h0Z"/>
        <path id="Phalangen_overlay_7" data-name="Phalangen overlay 7" class="phalangen1432Fill" style= {{
                fill: props.colors.phalangen1432Fill,
                stroke: props.colors.phalangen1432Fill,
                opacity:props.colors.phalangen1432Fill,
              }} d="M65.22,144.13c-1.4-5.1,1-10.4,5.1-13.5,18.9-14.3,37.1-19.8,54.6-16-5.4,11.4-3,19.7,9.6,23.6-11.3,10.9-27.1,17.1-48,18-11.7-1.3-19.3-5.1-21.3-12.1h0Z"/>
      </g>
      <g id="metatarsalia_overlay" data-name="metatarsalia overlay" class="vorfuss_lat_rechts1432_cls_1">
        <path id="metatarsalia_overlay_1" data-name="metatarsalia overlay 1" class="vorfuss_lat_rechts1432_cls_4" d="M436.22.93c-3.4-1.3-6.4.7-9.4,1.7-2,2-4,4-6,5-18,7-32,22-36,41-2,16,0,32,10,45,11,14,28,19,45.4,20.1"/>
        <path id="metatarsalia_overlay_2" data-name="metatarsalia overlay 2" class="vorfuss_lat_rechts1432_cls_4" d="M441.42,46.53c-.8-.4-1.5-1.1-1.6-2.2-44.9,19.1-55.5,47.8-45,82.5,6.3,19.1,20.1,32.4,44,37.7"/>
        <path id="metatarsalia_overlay_3" data-name="metatarsalia overlay 3" class="vorfuss_lat_rechts1432_cls_4" d="M440.62,94.83c-15.8,8-27,16.1-31.7,24.3-18.1,21.5-1.4,37.9,29.6,52.3"/>
      </g>
    </g>
  </svg>
  );
}

export default RechterVorfussLatLiegend1432;
