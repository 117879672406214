import { Button, Card, Container, Form } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";

import { availableThemes } from "../Components/ThemeSelector/ThemeSelector";
import { defaultRoutes, reportsOnlyRoutes } from "../Consts/routes";
import AuthorizedElement from "../Utilities/AuthorizedElement";

function UserSettings() {
  const { keycloak } = useKeycloak();

  const availableRoutes =
    process.env.REACT_APP_BUILD_TYPE === "reports"
      ? reportsOnlyRoutes
      : defaultRoutes;
  return (
    <Container className="mx-1">
      <Card className="mt-3" style={{ maxWidth: "50%" }}>
        <Card.Header className="py-2">
          <h5>User Preferences</h5>
        </Card.Header>
        <Card.Body className="d-flex flex-column" style={{ height: "80vh" }}>
          <div className="text-center mb-5">
            <i
              className="fa fa-user fa-3x  border rounded-circle d-flex align-items-center justify-content-center mx-auto"
              style={{
                backgroundColor: "#ededed",
                color: "#ff880d",
                width: "90px",
                height: "90px",
              }}
            ></i>
            <div className="font-weight-bold mt-1 text-uppercase">
              {keycloak.tokenParsed.preferred_username}
            </div>
            <div className="font-weight-bold mt-1">
              {keycloak.tokenParsed.name}
            </div>
            <div className="font-weight-bold">{keycloak.tokenParsed.email}</div>
            <div className="mt-3">
              <Button onClick={() => keycloak.logout()}>Logout</Button>
            </div>
          </div>
          <div className="d-flex flex-column mx-auto" style={{ width: "80%" }}>
            <Form>
              <AuthorizedElement roles={["pm_admin", "pm_user"]}>
                <Form.Row>
                  <Form.Label>Preffered Starting Page</Form.Label>
                  <Form.Control as="select">
                    {availableRoutes.map(
                      (route) =>
                        route.isForListing && (
                          <option
                            key={route.name}
                            value={route.path}
                          >{`${route.name}  ("${route.path}")`}</option>
                        )
                    )}
                  </Form.Control>
                </Form.Row>
              </AuthorizedElement>
              <Form.Row className="mt-2">
                <Form.Label>Preffered Theme</Form.Label>
                <Form.Control as="select">
                  {availableThemes.map((theme) => (
                    <option key={theme.name} value={theme.name}>
                      {theme.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Row>
            </Form>
          </div>
        </Card.Body>
        <Card.Footer className="d-flex py-1">
          <Button className="ml-auto">Save</Button>
        </Card.Footer>
      </Card>
    </Container>
  );
}

export default UserSettings;
