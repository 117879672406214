import { useEffect, useState } from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import {
  downloadReport,
  setDownloadedStatus,
  deleteReport,
} from "../../Utilities/ReportUtils/ReportUtils";
import { ClipLoader } from "react-spinners";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

export default function ReportRow({
  dataSetter,
  variantSetter,
  keycloak,
  pdfLoader,
  report_data,
  assignedVariant,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [isCsvAvailable, setIsCsvAvailable] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [csvName, setCsvName] = useState("Report");
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [errorDownloading, setErrorDownloading] = useState(false);
  const { t, i18n } = useTranslation(["reports", "common"]);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const download = () => {
    setIsLoading(true);
    downloadReport(report_data.id, keycloak)
      .then((report) => {
        setData(report);
        variantSetter(assignedVariant);
        dataSetter(report);
        setIsLoading(false);
      })
      .catch((reason) => {
        NotificationManager.error("Unable to load Report");
        console.warn("Unable to load Report", reason);
        setData(null);
        dataSetter(null);
        setErrorDownloading(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    load_csv();
  }, [data]);

  const load_csv = () => {
    var tempData = [];
    var colCount = 0;
    try {
      data.forEach((report) => {
        Object.keys(report).forEach((element) => {
          var cur = report[element];
          Object.keys(cur).forEach((subelement) => {
            tempData.push([element, ""]);
            setCsvName(
              element + formatDate(report_data.creat_timestamp) + ".csv"
            );
            var a = cur[subelement]["rows"];
            var tempData2 = [];
            var count = 0;
            var firstRow = true;
            a.forEach((row) => {
              colCount = row.length;
              tempData2.push(row);
              if (firstRow) {
                firstRow = false;
              } else {
                count += 1;
              }
            });
            tempData.push([subelement, count]);
            tempData.push(...tempData2);
          });
        });
        if (report.sum != 0) {
          let tempArr = [t("archive.sum")];
          for (let i = 1; i < colCount - 1; i++) {
            tempArr.push("");
          }
          tempArr.push(report.sum);
          tempData.push(tempArr);
        }
      });
      setCsvData(tempData);
      setIsCsvAvailable(true);
    } catch {
      setIsCsvAvailable(false);
      try {
        if (data?.length > 0) {
          let temp = [];
          let headerPresent = false;
          data[0].forEach((row) => {
            if (row.type === "row_desc" && !headerPresent) {
              temp.unshift(row.cells.slice(1));
              headerPresent = true;
            }
            if (row.type === "row") {
              temp.push(row.cells.slice(1));
            }
            if (row.type === "head") {
              setCsvName(`${row.cells[0]}.csv`);
            }
          });

          setIsCsvAvailable(true);
          setCsvData(temp);
          return;
        }
      } catch {
        setIsCsvAvailable(false);
      }
    }
  };

  const formatDate = (date_str) => {
    let d = new Date(date_str);
    return d.toLocaleString("de-DE");
  };

  const changeStatus = () => {
    setDownloadedStatus(report_data.id, keycloak);
    report_data.status = 2;
  };

  const ModalResult = (shouldDelete) => {
    if (shouldDelete) {
      setIsDeleted(true);
      deleteReport(report_data.id, keycloak);
      report_data.status = 4;
    }
    setIsDeleteModalShown(false);
  };

  const status_map = {
    0: [t("archive.pending"), "badge badge-secondary"],
    1: [t("archive.new"), "badge badge-success"],
    2: [t("archive.downloaded"), "badge badge-primary"],
    3: [t("archive.error"), "badge badge-warning"],
    4: [t("archive.deleted"), "badge badge-danger"],
    10: ["missing status", "badge badge-danger"],
    11: [t("archive.unknown"), "badge badge-warning"],
  };
  //console.log(report_data)
  return (
    <Row className={isDeleted ? "deleted-report-row" : "border-top py-2"}>
      <Col md={10}>
        <Row>
          <Col md={7}>
            <h5>
              {report_data.reportname +
                " " +
                t("archive.created_on") +
                formatDate(report_data.creat_timestamp)}
            </h5>
          </Col>
          <Col>
            <h5>
              <span className={status_map[report_data.status][1]}>
                {status_map[report_data.status][0]}
              </span>
            </h5>
          </Col>
        </Row>
      </Col>
      <Col className="text-right">
        {isLoading && <ClipLoader size={15} />}
        {errorDownloading && (
          <span
            className="font-weight-bold text-danger my-auto"
            style={{ fontSize: "1.3rem" }}
          >
            !
          </span>
        )}
        {(data == null) & !isDeleted ? (
          <>
            <Button
              onClick={download}
              className="ml-2"
              disabled={errorDownloading}
            >
              <i className="fas fa-arrow-down report-icon"></i>
            </Button>
            <Button
              onClick={() => setIsDeleteModalShown(true)}
              className="ml-2 mr-2"
              variant="danger"
            >
              <i className="fas fa-trash report-icon"></i>
            </Button>
          </>
        ) : (
          ""
        )}
        {(data != null) & !isDeleted ? (
          <Button
            className="ml-2"
            onClick={() => {
              pdfLoader(data, assignedVariant);
              changeStatus();
            }}
            variant="secondary"
          >
            <i className="fas fa-file-pdf report-icon"></i>
          </Button>
        ) : (
          ""
        )}
        {isCsvAvailable & !isDeleted ? (
          <CSVLink
            data={csvData}
            target="_blank"
            filename={csvName}
            onClick={changeStatus}
          >
            <Button className="ml-2" variant="secondary">
              <i className="fas fa-file-csv report-icon"></i>
            </Button>
          </CSVLink>
        ) : (
          ""
        )}
        {(data != null) & !isDeleted ? (
          <Button
            onClick={() => setIsDeleteModalShown(true)}
            className="ml-2 mr-2"
            variant="danger"
          >
            <i className="fas fa-trash report-icon"></i>
          </Button>
        ) : (
          ""
        )}
      </Col>
      <Modal show={isDeleteModalShown} onHide={() => ModalResult(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("archive.deleteReport")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{t("archive.askIfDelete")}</h5>
          <p>
            {report_data.reportname +
              " " +
              t("archive.created_on") +
              formatDate(report_data.creat_timestamp)}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => ModalResult(false)}>
            {t("common:no")}
          </Button>
          <Button variant="primary" onClick={() => ModalResult(true)}>
            {t("common:yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
}
