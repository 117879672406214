import style from "./style.scss";

function Rechte5ZeheDplOblSitzend1424(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.66 309.82">
      <g id="fifth_toe" data-name="fifth toe">
        <path
          id="fifth_toe_1"
          data-name="fifth toe 1"
          class="Rechte_5Zehe_dpl_obl_sitzend1424_cls_3"
          d="M82.78,309.5c7.65-9.21,10.1-19.88,7.19-32-5.04-28.93-15.56-46.91-37.7-41.21-9.64-4.16-18.41-.44-26.3,15.56,6.36,11.18,7.01,21.7-4.82,30.47-.64,2.63-1.23,20.19-1.82,22.82"
        />
        <path
          id="fifth_toe_2"
          data-name="fifth toe 2"
          class="Rechte_5Zehe_dpl_obl_sitzend1424_cls_3"
          d="M71.78,231.47c-10.3-6.58-21.7-6.14-33.76-1.32-14.47,14.25-23.45,11.84-21.48-26.3,10.3-44.06,1.32-76.72-11.4-106.97-8.33-23.23,8.99-15.56,26.3-8.33,20.38-2.63,29.81,3.29,20.6,23.02-2.63,27.18,2.19,51.07,14.91,71.46,20.6,21.04,23.45,37.48,4.82,48.44h0Z"
        />
        <path
          id="fifth_toe_3"
          data-name="fifth toe 3"
          class="Rechte_5Zehe_dpl_obl_sitzend1424_cls_3"
          d="M47.01,81.1h0l-22.8-.66c-11.4-.22-16-4.6-13.15-14.03-5.48-6.14-5.7-10.96,5.7-13.15l22.14-6.58c10.74,1.1,16.22,7.23,15.56,18.85,7.89,9.86,5.7,15.12-7.45,15.56h0Z"
        />
        <path
          id="fifth_toe_4"
          data-name="fifth toe 4"
          class="Rechte_5Zehe_dpl_obl_sitzend1424_cls_3"
          d="M49.42,14.47c1.1,8.11,4.82,9.64,8.33,11.4,11.4,16.88-.66,22.14-28.06,19.73-7.67,1.75-13.59,1.32-16.88-4.16s-.66-12.27,5.04-15.12c5.7-2.85,8.77-5.92,8.77-10.08s1.1-8.33,3.73-11.4c7.67-8.55,13.81-3.95,19.07,9.64h0Z"
        />
      </g>
      <g id="overlays">
        <g id="Phalangen_overlay" data-name="Phalangen overlay">
          <path
            id="Phalangen_overlay_1"
            data-name="Phalangen overlay 1"
            class="phalangenFill1424"
            style={{
              fill: props.colors.phalangenFill1424,
              stroke: props.colors.phalangenFill1424,
              opacity: props.colors.phalangenFill1424,
            }}
            d="M71.78,231.47c-10.3-6.58-21.7-6.14-33.76-1.32-14.47,14.25-23.45,11.84-21.48-26.3,10.3-44.06,1.32-76.72-11.4-106.97-8.33-23.23,8.99-15.56,26.3-8.33,20.38-2.63,29.81,3.29,20.6,23.02-2.63,27.18,2.19,51.07,14.91,71.46,20.6,21.04,23.45,37.48,4.82,48.44h0Z"
          />
          <path
            id="Phalangen_overlay_2"
            data-name="Phalangen overlay 2"
            class="phalangenFill1424"
            style={{
              fill: props.colors.phalangenFill1424,
              stroke: props.colors.phalangenFill1424,
              opacity: props.colors.phalangenFill1424,
            }}
            d="M47.01,81.1h0l-22.8-.66c-11.4-.22-16-4.6-13.15-14.03-5.48-6.14-5.7-10.96,5.7-13.15l22.14-6.58c10.74,1.1,16.22,7.23,15.56,18.85,7.89,9.86,5.7,15.12-7.45,15.56h0Z"
          />
          <path
            id="Phalangen_overlay_3"
            data-name="Phalangen overlay 3"
            class="phalangenFill1424"
            style={{
              fill: props.colors.phalangenFill1424,
              stroke: props.colors.phalangenFill1424,
              opacity: props.colors.phalangenFill1424,
            }}
            d="M49.42,14.47c1.1,8.11,4.82,9.64,8.33,11.4,11.4,16.88-.66,22.14-28.06,19.73-7.67,1.75-13.59,1.32-16.88-4.16-3.29-5.48-.66-12.27,5.04-15.12s8.77-5.92,8.77-10.08,1.1-8.33,3.73-11.4c7.67-8.55,13.81-3.95,19.07,9.64h0Z"
          />
        </g>
        <g id="joint_overlay" data-name="joint overlay">
          <ellipse
            id="joint_overlay_1"
            data-name="joint overlay 1"
            class="gelenkeFill1424"
            style={{
              fill: props.colors.gelenkeFill1424,
              stroke: props.colors.gelenkeFill1424,
              opacity: props.colors.gelenkeFill1424,
            }}
            cx="34.92"
            cy="46.26"
            rx="29.62"
            ry="12.3"
          />
          <ellipse
            id="joint_overlay_2"
            data-name="joint overlay 2"
            class="gelenkeFill1424"
            style={{
              fill: props.colors.gelenkeFill1424,
              stroke: props.colors.gelenkeFill1424,
              opacity: props.colors.gelenkeFill1424,
            }}
            cx="29.52"
            cy="83.17"
            rx="29.52"
            ry="11.85"
          />
          <ellipse
            id="joint_overlay_3"
            data-name="joint overlay 3"
            class="gelenkeFill1424"
            style={{
              fill: props.colors.gelenkeFill1424,
              stroke: props.colors.gelenkeFill1424,
              opacity: props.colors.gelenkeFill1424,
            }}
            cx="48.86"
            cy="232.64"
            rx="35.36"
            ry="14.58"
            transform="translate(-17.21 4.29) rotate(-4.28)"
          />
        </g>
      </g>
    </svg>
  );
}

export default Rechte5ZeheDplOblSitzend1424;
