import style from "./style.scss";

function LinkesFersenbeinAxSitzend1449(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.33 478.65">
  
  <g id="calcaneus">
    <path id="calcaneus1" class="kalkaneus_ax_links1449_cls2" d="M241.84,141.4c-.85-11.98,2.99-23.02,10.36-33.37,7.86-15.01,7.15-26.05-.74-33.75-8.87-8.98-16.49-18.69-20.71-30.38"/>
    <path id="calcaneus2" class="kalkaneus_ax_links1449_cls2" d="M257.75,6.16c-19.44,30.5-28.71,86.92-4.51,134.49,6.66,20.07,12.07,39.49,7.87,54-10.38,33.18-13.5,68.89-10.13,106.87,7.06,26.49,6.01,52.49-9,77.62-3.16,53.16-17.09,92.04-55.13,99-32.72-2.21-62.28-11.08-82.12-40.5-32.59-50.32-37.35-90.64-15.75-121.5,27.76-52.92,13.58-118.97-16.87-190.13-10.42-6.87-21.81-11.53-34.87-12.38-12.56-11.23-21.41-24.27-21.37-41.63C-2.72,52.44-2.79,31.62,6.87,10.12"/>
    <path id="calcaneus3" class="kalkaneus_ax_links1449_cls2" d="M20.37,11.27c-.21,23.33,2.18,39,9,41.63,6.73,43.84,16.04,76.58,33.75,73.13l13.5-20.25"/>
    <path id="calcaneus4" class="kalkaneus_ax_links1449_cls2" d="M300.5.02c1.59,33.82-2.04,60.24-24.75,59.62-17.8-3.42-30.37-23.49-38.25-58.5"/>
    <path id="calcaneus5" class="KALKANEUS_ax_links1449_cls1" d="M112.25,291.02c-1.39,23.45-5.71,46.29-14.25,68.25-5.92,15.66-7.65,34.38-7.13,54.75"/>
    <path id="calcaneus6" class="KALKANEUS_ax_links1449_cls1" d="M242,379.15c-7.97-23.78-7.95-49.71,6.38-79.5"/>
  </g>
  <g id="overlays">
    <path id="calcaneus_overlay" data-name="calcaneus overlay" class="calcaneus1449fill" style= {{
              fill: props.colors.calcaneus1449fill,
              stroke: props.colors.calcaneus1449fill,
              opacity:props.colors.calcaneus1449fill,
            }} d="M237.5,4.09c1.9,8.31,4.83,16.87,9.09,25.75-10.75,31.36-12.2,73.77,6.65,110.81,6.66,20.07,12.07,39.49,7.87,54-10.38,33.18-13.5,68.89-10.13,106.87,7.06,26.49,6.01,52.49-9,77.62-3.16,53.16-17.09,92.04-55.13,99-32.72-2.21-62.28-11.08-82.12-40.5-32.59-50.32-37.35-90.64-15.75-121.5,27.76-52.92,13.58-118.97-16.87-190.13-10.42-6.87-21.81-11.53-34.87-12.38-12.56-11.23-21.41-24.27-21.37-41.63C-2.72,52.44-2.79,31.62,6.87,10.12"/>
  </g>
</svg>
  );
}

export default LinkesFersenbeinAxSitzend1449;
