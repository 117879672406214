import style from "./style.scss";

function Linker4FingerDpOblSitzend1373(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223.9 486.09">
      <g id="Backround">
        <path
          id="backround1"
          class="Linker_4Finger_dp_obl_sitzend1373_cls5"
          d="M72.51,486.09c5.27-35.8,11.4-64.3,16.02-83.7,4.55-19.06,22.36-91.16,26.72-112.85.16-.79,1.11-5.53,2.28-11.46,2.39-12.07,3.6-18.2,4.4-22.68.92-5.18,2.13-12.58,3.29-21.68,1.77-12.22,2.84-22.68,3.51-30.74.37-4.46,2.02-24.95,1.75-52.99-.09-9.99-.41-18.41-.71-24.59,4.31-5.3,9.53-12.28,14.85-20.89,6.14-9.94,9.95-18.05,13.77-26.25,3.03-6.51,6.59-14.68,10.19-24.3,7.88-3.44,14.34-6.88,19.21-9.68,5.48-3.16,12.84-7.44,21.41-14.43,5.55-4.52,9.84-8.74,12.83-11.89.47-.81,1.31-2.52,1.36-4.83.04-1.89-.46-3.36-.81-4.18-1.03-1.59-3.89-5.52-9.17-7.42-2.43-.87-4.62-1.04-6.2-1.02-3.27.29-22.78,2.02-39.11,3.59-20.53,1.96-10.12,1.17-15.37,1.62-5.01.43-7.37.54-10.79,1.35-5.07,1.2-8.98,3.1-11.49,4.53-9.6,7.43-20.76,17.38-31.82,30.34-14.02,16.42-23.38,32.45-29.6,45.21-17.54,56.54-27.96,88.62-34.41,107.87-2.27,6.78-9.39,27.91-17.53,53.66-1.42,4.51-4.22,13.41-7.28,23.19-4.17,13.35-7.56,24.28-9.81,31.55.17,60.72.33,121.44.5,182.16"
        />
      </g>
      <g id="_4Finger" data-name="4Finger">
        <path
          id="partoffinger4"
          class="Linker_4Finger_dp_obl_sitzend1373_cls6"
          d="M169.69,38.16c2.21-.69,3.83-2.64,4.09-4.94.66-5.78,5.9-8.82,16.04-8.82,6.43-2.41,9.82-6.52,9.69-12.46-.04-1.99-1.8-3.51-3.79-3.35-11.38.88-23.09,3.66-35.07,8.08l-18.49-.96c-8.21,4.22-6.29,13.62,1.43,23.35,10.22.69,19.89,1.04,26.08-.9h.02,0Z"
        />
        <path
          id="partoffinger3"
          class="Linker_4Finger_dp_obl_sitzend1373_cls6"
          d="M160.19,25.92h0c-6.91-5.33-18.39-2.51-30.17.82-3.41.96-6.12,3.53-7.3,6.87-5.12,14.48-19.27,30.43-36.34,46.9-3,2.89-4.32,7.12-3.5,11.21,4.96,24.5,21.75,24.29,41.77,17.4,8.13-2.55,12.43-7.73,8.29-18.49,3.74-16.49,7.73-32.51,14.58-41.83,4.3-1.04,8.68-2.15,13.15-3.43,3.11-6.06,6.45-12.19-.48-19.44h0Z"
        />
        <path
          id="partoffinger2"
          class="Linker_4Finger_dp_obl_sitzend1373_cls6"
          d="M111.98,138.26c9.96-7.54,14.1-16.73,13.68-24.42-.42-7.69-6.78-12.78-14.11-12.71-4.62.05-9.82-.66-17.58-4.7,0,0-8.76-2.55-14.58-.96-6.49.82-11.14,6.73-7.81,24.3-5.26,18.8-11.15,47.01-17.05,75.85-1.99,16.97-10.52,36.41-24.3,57.93-3.11,5.82-2.95,11.15,4.38,15.54,26.29-4.86,30.68,9.24,45.26,14.1,15.3,1.43,22.55-7.41,19.92-28.68-5.06-19.13-6.19-36.04-1.43-49.51,2.49-7.06,4.79-14.2,6.35-21.53,3.2-15.09,5.58-30.15,7.19-45.21h.08Z"
        />
        <path
          id="partoffinger1"
          class="Linker_4Finger_dp_obl_sitzend1373_cls6"
          d="M34.62,269.09h0c1.59,2.55,3.51,4.7,7.33,5.5,4.86-.4,10.28.48,16.02,2.55,4.62-.72,7.17-.96,8.37-.72-11.39-9.4-21.59-9.08-31.71-7.33h0Z"
        />
        <path
          id="outline2"
          class="Linker_4Finger_dp_obl_sitzend1373_cls7"
          d="M42.06,458.59c2.22-32.05,7.69-57.87,11.2-81.53,4.86-35.06,27.25-49.64,26.29-53.54l-.48-13.62v.08h-.16c-8.29-44.3-38.8-39.84-61.75-22.39-8.95,6.35-13.06,13.64-14.22,21.39-.38,2.54-1.1,5.01-1.85,7.46-1.89,6.12.97,19.57,5.3,35.26,1.03,3.72,1.52,7.58,1.46,11.44-.47,32.84.04,49.58-1.99,95.33"
        />
        <path
          id="outline1"
          class="Linker_4Finger_dp_obl_sitzend1373_cls6"
          d="M114.02,295.26c13.18-57.67,18.71-114.33,15.75-169.87,16.39-19.19,28.12-44.61,38.81-71.44,23.32-10.77,41.37-22.72,53.44-36,2.37-4.32,1.72-8.44-2.24-12.33-3.96-3.89-9.44-5.57-14.9-5.02l-57.51,5.79c-9.6.97-18.66,4.97-25.75,11.5-20.24,18.62-38.33,40.77-52.59,69.25L9.4,273.32"
        />
      </g>
      <g id="Highlights">
        <g id="Fingerhighlights" class="Linker_4Finger_dp_obl_sitzend1373_cls1">
          <path
            id="fingerhighlight4"
            class="FullFinger1373Fill"
            style={{
              fill: props.colors.FullFinger1373Fill,
              stroke: props.colors.FullFinger1373Fill,
              opacity: props.colors.FullFinger1373Fill,
            }}
            d="M169.69,38.21c2.21-.69,3.83-2.64,4.09-4.94.66-5.78,5.9-8.82,16.04-8.82,6.43-2.41,9.82-6.52,9.69-12.46-.04-1.99-1.8-3.51-3.79-3.35-11.38.88-23.09,3.66-35.07,8.08l-18.49-.96c-8.21,4.22-6.29,13.62,1.43,23.35,10.22.69,19.89,1.04,26.08-.9h.02,0Z"
          />
          <path
            id="fingerhighlight3"
            class="FullFinger1373Fill"
            style={{
              fill: props.colors.FullFinger1373Fill,
              stroke: props.colors.FullFinger1373Fill,
              opacity: props.colors.FullFinger1373Fill,
            }}
            d="M160.19,25.97h0c-6.91-5.33-18.39-2.51-30.17.82-3.41.96-6.12,3.53-7.3,6.87-5.12,14.48-19.27,30.43-36.34,46.9-3,2.89-4.32,7.12-3.5,11.21,4.96,24.5,21.75,24.29,41.77,17.4,8.13-2.55,12.43-7.73,8.29-18.49,3.74-16.49,7.73-32.51,14.58-41.83,4.3-1.04,8.68-2.15,13.15-3.43,3.11-6.06,6.45-12.19-.48-19.44h0Z"
          />
          <path
            id="fingerhighlight2"
            class="FullFinger1373Fill"
            style={{
              fill: props.colors.FullFinger1373Fill,
              stroke: props.colors.FullFinger1373Fill,
              opacity: props.colors.FullFinger1373Fill,
            }}
            d="M111.98,138.31c9.96-7.54,14.1-16.73,13.68-24.42-.42-7.69-6.78-12.78-14.11-12.71-4.62.05-9.82-.66-17.58-4.7,0,0-8.76-2.55-14.58-.96-6.49.82-11.14,6.73-7.81,24.3-5.26,18.8-11.15,47.01-17.05,75.85-1.99,16.97-10.52,36.41-24.3,57.93-3.11,5.82-2.95,11.15,4.38,15.54,26.29-4.86,30.68,9.24,45.26,14.1,15.3,1.43,22.55-7.41,19.92-28.68-5.06-19.13-6.19-36.04-1.43-49.51,2.49-7.06,4.79-14.2,6.35-21.53,3.2-15.09,5.58-30.15,7.19-45.21h.08Z"
          />
          <path
            id="fingerhighlight1"
            class="FullFinger1373Fill"
            style={{
              fill: props.colors.FullFinger1373Fill,
              stroke: props.colors.FullFinger1373Fill,
              opacity: props.colors.FullFinger1373Fill,
            }}
            d="M34.62,269.14h0c1.59,2.55,3.51,4.7,7.33,5.5,4.86-.4,10.28.48,16.02,2.55,4.62-.72,7.17-.96,8.37-.72-11.39-9.4-21.59-9.08-31.71-7.33h0Z"
          />
        </g>
        <path
          id="highlightmetacarpophalangealjoint"
          class="Grundgelenk1373Fill"
          style={{
            fill: props.colors.Grundgelenk1373Fill,
            stroke: props.colors.Grundgelenk1373Fill,
            opacity: props.colors.Grundgelenk1373Fill,
          }}
          d="M84.55,281.84c-11.15-8.21-21.6-11.48-21.6-11.48-9.05-2.83-17.83-5.58-29.66-4.96-1.16.06-18.47,1.07-19.42,6.47-.25,1.42.74,3.1,2.7,6.47,1.69,2.91,2.54,4.36,4.04,5.12,2.64,1.34,4.98-.43,9.97-1.82.5-.14,7.38-2,14.72-1.19,5.59.61,9.66,2.58,12.79,4.09,5.47,2.65,10.5,6.31,12.56,7.82,4.94,3.63,6.94,5.78,10.09,5.4,2.57-.32,4.52-2.15,5.39-2.97.96-.91,3.36-3.16,3.39-5.94.04-3.13-2.94-5.5-4.99-7.01h.02Z"
        />
        <path
          id="highlightfingertip"
          class="Fingerkuppe1373Fill"
          style={{
            fill: props.colors.Fingerkuppe1373Fill,
            stroke: props.colors.Fingerkuppe1373Fill,
            opacity: props.colors.Fingerkuppe1373Fill,
          }}
          d="M182.01,10.5c.67-.14,1.41-.29,2.09-.43,1.65-.33,3.63-.58,7.58-1.14,4.59-.65-.03.06,2.88-.27.62-.07,1.23-.15,2.02,0,.56.1,1,.28,1.29.42.29.19.73.53,1.09,1.1.46.74.51,1.46.56,2.07.03.44.03,1.09-.15,1.88-.07.47-.17.97-.31,1.49-.67,2.34-2.01,4-3.01,5-.83.72-1.61,1.3-2.28,1.75-.38.26-.72.47-.86.56-1.55.93-3.43,1.14-5.14,1.33-3.53.39-3.58.1-4.99.53-1.89.58-2.03,1.18-2.97,1.12-2.1-.13-3.98-3.22-4.38-5.78-.79-5.06,4.11-8.94,4.46-9.21.61-.12,1.35-.27,2.1-.42h.02,0Z"
        />
      </g>
    </svg>
  );
}

export default Linker4FingerDpOblSitzend1373;
