import indImg29 from "./indImg29.svg";
import indImg34 from "./indImg34.svg";
import indImg38 from "./indImg38.svg";
import placeholder from "./placeholder.svg";
import indImg1 from "./indImg1.svg";
import indImg14 from "./indImg14.svg";
import indImg15 from "./indImg15.svg";
import indImg19 from "./indImg19.svg";
import indImg20 from "./indImg20.svg";
import indImg21 from "./indImg21.svg";
import indImg22 from "./indImg22.svg";
import indImg23 from "./indImg23.svg";
import indImg24 from "./indImg24.svg";
import indImg25 from "./indImg25.svg";
import indImg26 from "./indImg26.svg";
import indImg27 from "./indImg27.svg";
import indImg28 from "./indImg28.svg";
import indImg30 from "./indImg30.svg";
import indImg32 from "./indImg32.svg";
import indImg33 from "./indImg33.svg";
import indImg35 from "./indImg35.svg";
import indImg36 from "./indImg36.svg";
import indImg37 from "./indImg37.svg";
import indImg39 from "./indImg39.svg";
import indImg40 from "./indImg40.svg";
import indImg42 from "./indImg42.svg";
import indImg43 from "./indImg43.svg";
import indImg44 from "./indImg44.svg";
import indImg45 from "./indImg45.svg";
import indImg46 from "./indImg46.svg";
import indImg47 from "./indImg47.svg";
import indImg48 from "./indImg48.svg";
import indImg49 from "./indImg49.svg";
import indImg50 from "./indImg50.svg";
import indImg51 from "./indImg51.svg";
import indImg52 from "./indImg52.svg";
import indImg54 from "./indImg54.svg";
import indImg55 from "./indImg55.svg";
import indImg56 from "./indImg56.svg";
import indImg58 from "./indImg58.svg";
import indImg59 from "./indImg59.svg";
import indImg60 from "./indImg60.svg";
import indImg61 from "./indImg61.svg";
import indImg62 from "./indImg62.svg";
import indImg64 from "./indImg64.svg";
import indImg65 from "./indImg65.svg";
import indImg66 from "./indImg66.svg";
import indImg67 from "./indImg67.svg";
import indImg68 from "./indImg68.svg";
import indImg69 from "./indImg69.svg";
import indImg70 from "./indImg70.svg";
import indImg72 from "./indImg72.svg";
import indImg73 from "./indImg73.svg";
import indImg75 from "./indImg75.svg";
import indImg77 from "./indImg77.svg";
import indImg79 from "./indImg79.svg";
import indImg80 from "./indImg80.svg";
import indImg82 from "./indImg82.svg";
import indImg83 from "./indImg83.svg";
import indImg84 from "./indImg84.svg";
import indImg85 from "./indImg85.svg";
import indImg86 from "./indImg86.svg";
import indImg87 from "./indImg87.svg";
import indImg88 from "./indImg88.svg";
import indImg89 from "./indImg89.svg";
import indImg90 from "./indImg90.svg";
import indImg91 from "./indImg91.svg";
import indImg92 from "./indImg92.svg";
import indImg93 from "./indImg93.svg";
import indImg94 from "./indImg94.svg";
import indImg95 from "./indImg95.svg";
import indImg96 from "./indImg96.svg";
import indImg97 from "./indImg97.svg";
import indImg98 from "./indImg98.svg";
import indImg99 from "./indImg99.svg";
import indImg100 from "./indImg100.svg";
import indImg101 from "./indImg101.svg";
import indImg102 from "./indImg102.svg";
import indImg103 from "./indImg103.svg";
import indImg105 from "./indImg105.svg";
import indImg106 from "./indImg106.svg";
import indImg107 from "./indImg107.svg";
import indImg108 from "./indImg108.svg";
import indImg109 from "./indImg109.svg";
import indImg111 from "./indImg111.svg";
import indImg112 from "./indImg112.svg";
import indImg113 from "./indImg113.svg";
import indImg115 from "./indImg115.svg";
import indImg116 from "./indImg116.svg";
import indImg117 from "./indImg117.svg";
import indImg119 from "./indImg119.svg";
import indImg120 from "./indImg120.svg";
import indImg121 from "./indImg121.svg";
import indImg122 from "./indImg122.svg";
import indImg123 from "./indImg123.svg";
import indImg125 from "./indImg125.svg";
import indImg126 from "./indImg126.svg";
import indImg127 from "./indImg127.svg";
import indImg128 from "./indImg128.svg";
import indImg129 from "./indImg129.svg";
import indImg130 from "./indImg130.svg";
import indImg131 from "./indImg131.svg";
import indImg132 from "./indImg132.svg";
import indImg133 from "./indImg133.svg";
import indImg134 from "./indImg134.svg";
import indImg135 from "./indImg135.svg";
import indImg136 from "./indImg136.svg";
import indImg137 from "./indImg137.svg";
import indImg138 from "./indImg138.svg";
import indImg139 from "./indImg139.svg";
import indImg140 from "./indImg140.svg";
import indImg141 from "./indImg141.svg";
import indImg143 from "./indImg143.svg";
import indImg144 from "./indImg144.svg";
import indImg145 from "./indImg145.svg";
import indImg146 from "./indImg146.svg";
import indImg147 from "./indImg147.svg";
import indImg149 from "./indImg149.svg";
import indImg150 from "./indImg150.svg";
import indImg151 from "./indImg151.svg";
import indImg155 from "./indImg155.svg";
import indImg156 from "./indImg156.svg";
import indImg157 from "./indImg157.svg";
import indImg159 from "./indImg159.svg";
import indImg160 from "./indImg160.svg";
import indImg161 from "./indImg161.svg";
import indImg162 from "./indImg162.svg";
import indImg164 from "./indImg164.svg";
import indImg165 from "./indImg165.svg";
import indImg168 from "./indImg168.svg";
import indImg169 from "./indImg169.svg";
import indImg170 from "./indImg170.svg";
import indImg171 from "./indImg171.svg";
import indImg172 from "./indImg172.svg";
import indImg173 from "./indImg173.svg";
import indImg176 from "./indImg176.svg";
import indImg178 from "./indImg178.svg";
import indImg179 from "./indImg179.svg";
import indImg180 from "./indImg180.svg";
import indImg181 from "./indImg181.svg";
import indImg187 from "./indImg187.svg";
import indImg188 from "./indImg188.svg";
import indImg189 from "./indImg189.svg";
import indImg190 from "./indImg190.svg";
import indImg193 from "./indImg193.svg";
import indImg194 from "./indImg194.svg";
import indImg195 from "./indImg195.svg";
import indImg196 from "./indImg196.svg";
import indImg197 from "./indImg197.svg";
import indImg199 from "./indImg199.svg";
import indImg200 from "./indImg200.svg";
import indImg201 from "./indImg201.svg";
import indImg202 from "./indImg202.svg";
import indImg203 from "./indImg203.svg";
import indImg206 from "./indImg206.svg";
import indImg207 from "./indImg207.svg";
import indImg209 from "./indImg209.svg";
import indImg214 from "./indImg214.svg";
import indImg215 from "./indImg215.svg";
import indImg217 from "./indImg217.svg";
import indImg219 from "./indImg219.svg";
import indImg220 from "./indImg220.svg";
import indImg221 from "./indImg221.svg";
import indImg222 from "./indImg222.svg";
import indImg223 from "./indImg223.svg";

export default {
  indImg1,
  indImg14,
  indImg15,
  indImg19,
  indImg20,
  indImg21,
  indImg22,
  indImg23,
  indImg24,
  indImg25,
  indImg26,
  indImg27,
  indImg28,
  indImg29,
  indImg30,
  indImg32,
  indImg33,
  indImg34,
  indImg35,
  indImg36,
  indImg37,
  indImg38,
  indImg39,
  indImg40,
  indImg42,
  indImg43,
  indImg44,
  indImg45,
  indImg46,
  indImg47,
  indImg48,
  indImg49,
  indImg50,
  indImg51,
  indImg52,
  indImg54,
  indImg55,
  indImg56,
  indImg58,
  indImg59,
  indImg60,
  indImg61, 
  indImg62,
  indImg64,
  indImg65,
  indImg66,
  indImg67,
  indImg68,
  indImg69,
  indImg70,
  indImg72,
  indImg73,
  indImg75,
  indImg77,
  indImg79,
  indImg80,
  indImg82,
  indImg83,
  indImg84,
  indImg85,
  indImg86,
  indImg87,
  indImg88,
  indImg89,
  indImg90, 
  indImg91,
  indImg92,
  indImg93,
  indImg94,
  indImg95,
  indImg96,
  indImg97,
  indImg98,
  indImg99,
  indImg100,
  indImg101,
  indImg102,
  indImg103,
  indImg105,
  indImg106,
  indImg107,
  indImg108,
  indImg109,
  indImg111,
  indImg112,
  indImg113,
  indImg115,
  indImg116,
  indImg117,
  indImg119,
  indImg120,
  indImg121,
  indImg122,
  indImg123,
  indImg125,
  indImg126,
  indImg127,
  indImg128,
  indImg129,
  indImg130,
  indImg131,
  indImg132,
  indImg133,
  indImg134,
  indImg135,
  indImg136,
  indImg137,
  indImg138,
  indImg139,
  indImg140,
  indImg141,
  indImg143,
  indImg144,
  indImg145,
  indImg146,
  indImg147,
  indImg149,
  indImg150,
  indImg151,
  indImg155,
  indImg156,
  indImg157,
  indImg159,
  indImg160,
  indImg161,
  indImg162,
  indImg164,
  indImg165,
  indImg168,
  indImg169,
  indImg170,
  indImg171,
  indImg172,
  indImg173,
  indImg176,
  indImg178,
  indImg179,
  indImg180,
  indImg181,
  indImg187,
  indImg188,
  indImg189,
  indImg190,
  indImg193,
  indImg194,
  indImg195,
  indImg196,
  indImg197,
  indImg199,
  indImg200,
  indImg201,
  indImg202,
  indImg203,
  indImg206,
  indImg207,
  indImg209,
  indImg214,
  indImg215,
  indImg217,
  indImg219,
  indImg220,
  indImg222,
  indImg221,
  indImg223,

  placeholder,
};
