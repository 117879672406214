import style from "./style.scss";

export default function LinkeKniescheibeDvLiegend2335(props) {
  return (
    <svg
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 319.24 643.55"
    >
      <g id="knee_joint" data-name="knee joint">
        <path
          id="background_lower_thigh_bone"
          data-name="background lower thigh bone"
          class="kniescheibe_dv_left2335_cls4"
          d="M88.82,627.26c-1.79-4.68.78-6.82,2-19,1.34-13.46-1.76-17.34-2-32-.23-13.65,2-12.48,2-25,0-14.95-3.18-19.15-7-42-3.77-22.56-.81-20.3-4-32-5.47-20.03-21-42-21-42-21.77-31.06-20.24-25.98-26-36-15.19-26.4-16-47-16-47-.43-9.57,1.08-16.08,3-21,2.66-6.82,5.73-9.68,6.58-10.43,4.71-4.18,10.2-5.08,13.42-5.57,25.78-3.9,41-5,41-5,8.79-.64,17.7-1.34,27-5,7.67-3.02,10.13-5.89,11-7,3.29-4.22,2.94-7.86,6-9,.78-.29,1.94-.28,10,3,5.92,2.41,6.81,3.92,10,4,3.72.09,4.61-1.9,10-4,3.41-1.33,9.59-3.74,15-2,5.18,1.67,4.58,5.53,12,12,.78.68,6.9,6,14,8,8.56,2.41,12.76-1.64,27-5,12.01-2.84,23.48-5.55,35-1,10.1,3.99,15.67,11.77,18,15,1.34,1.86,10.83,15.42,9,33-1.34,12.87-8.07,21.47-5,24,2.37,1.95,6.78-3.09,12-1,1.15.46,5.16,2.36,9,15,3.37,11.1,1.55,13.87,4,23,2.89,10.8,6.7,11.66,10,21,1.49,4.21,3.77,12.69,1,30-3.19,19.93-9.5,29.07-20,54-4.09,9.71-15.1,36.07-21,62-5.12,22.49-7.68,33.74-7,39,.27,2.06.81,5.24-1,8-5.63,8.61-29.7,6.44-33-2-1.65-4.22,3.3-6.43,6-17,2.34-9.16-.17-12.24,0-26,.18-14.68,3.06-13.36,3-26-.02-5.16-.2-17.45-6-31-4.55-10.63-7.19-9.45-9-17-1.92-7.99.54-11.34,3-30,1.25-9.47,2.83-21.49,1-22-2.08-.58-8.11,13.84-12,25-4.55,13.07-8.21,27.55-12,57-4,31.07-6,46.6-6,63,0,21.65,2.2,27.47-3,34-7.84,9.85-24,9.04-56,7-28.1-1.79-49.56-3.39-54-15h0Z"
        />
        <g id="fibula_bone" data-name="fibula bone">
          <path
            id="fibula_bone_1"
            data-name="fibula bone 1"
            class="kniescheibe_dv_left2335_cls2"
            d="M267.82,627.26c2.13-20.9,5.6-38.79,9-53,5.1-21.3,8.56-35.72,17-54,8.67-18.78,15.51-26.24,21-47,4.41-16.67,3.46-24.92,3-28-1.89-12.62-6.65-12.62-11-30-2.82-11.26-2.13-16.5-7-27-3.18-6.86-5.4-11.48-10-13-1.54-.51-5.84-1.5-24,11-10.33,7.11-15.5,10.67-20,16-8.27,9.8-11.22,19.78-13,26-5.49,19.23.9,21.73-3,50-1.7,12.31-3.73,17.79-2,28,1.58,9.3,4.74,13.41,8,21,8.01,18.63,7.05,35.93,6,55-.56,10.12-2.22,24-7,40"
          />
          <path
            id="fibula_bone_2"
            data-name="fibula bone 2"
            class="kniescheibe_dv_left2335_cls1"
            d="M238.56,534.65c0-31.17-3.91-56.39-8.74-56.39"
          />
          <path
            id="fibula_bone_3"
            data-name="fibula bone 3"
            class="kniescheibe_dv_left2335_cls1"
            d="M306.82,438.26c-11.17,27.82-8.47,49.35-7,49"
          />
          <path
            id="fibula_bone_4"
            data-name="fibula bone 4"
            class="kniescheibe_dv_left2335_cls1"
            d="M238.56,534.65c20,31.34,12.06,77.81,7.26,84.61"
          />
          <path
            id="fibula_bone_5"
            data-name="fibula bone 5"
            class="kniescheibe_dv_left2335_cls1"
            d="M281.07,547.26c-15.21,40.41-25.54,76.66-27.25,80"
          />
        </g>
        <g id="shin_bone" data-name="shin bone">
          <path
            id="shin_bone_1"
            data-name="shin bone 1"
            class="kniescheibe_dv_left2335_cls2"
            d="M88.82,629.26c1.33-14.55,2.4-33.24,2-55-.22-12.09-.62-30.35-4-54-3.41-23.83-5.42-37.85-13-56-8.27-19.79-19.15-34.86-23-40-9.73-12.98-11.6-11.94-18-22-13.87-21.79-14.86-41.99-15-46-.42-11.98-.88-25.54,8-34,4.13-3.93,12.81-5.67,30-9,12.76-2.47,19.64-3.8,30-5,17.15-1.98,22.33-1.12,28-6,6.35-5.47,5.56-11.49,11-13,6.64-1.84,9.63,6.63,19,7,10.62.42,14.61-10.15,23-8,4.08,1.05,4.4,3.87,11,10,5.32,4.94,12.42,9.9,18,12,11.93,4.5,17.01-3.99,38-6,9.1-.87,25.63-2.45,37,7,1.9,1.58,9.19,8.07,12,25,6.33,38.18-19.07,75.52-33,96-12.78,18.79-20.4,24.78-28,44-4.77,12.05-6.92,22.72-10,38-2.82,13.98-4.87,27.64-7,48-1.94,18.52-3.97,42.9-5,72"
          />
          <path
            id="shin_bone_2"
            data-name="shin bone 2"
            class="kniescheibe_dv_left2335_cls2"
            d="M113.82,302.26c-.3,17.67-37.08,29.36-82,22"
          />
          <path
            id="shin_bone_3"
            data-name="shin bone 3"
            class="kniescheibe_dv_left2335_cls2"
            d="M195.82,310.26c.42,15.62,36.69,23.37,80.74,7.89"
          />
          <path
            id="shin_bone_4"
            data-name="shin bone 4"
            class="kniescheibe_dv_left2335_cls1"
            d="M102.82,634.26c0-93.96-12.97-170-29-170"
          />
          <path
            id="shin_bone_5"
            data-name="shin bone 5"
            class="kniescheibe_dv_left2335_cls1"
            d="M106.82,371.87c-8.45-6.63-33.09-4.91-60,0"
          />
          <path
            id="shin_bone_6"
            data-name="shin bone 6"
            class="kniescheibe_dv_left2335_cls1"
            d="M253.82,363.26c-19.63,8.61-118.5-18.03-118.5-4"
          />
          <path
            id="shin_bone_7"
            data-name="shin bone 7"
            class="kniescheibe_dv_left2335_cls1"
            d="M190.82,314.38c-23.06,20.88-72.23-18.12-77-12.12"
          />
          <path
            id="shin_bone_8"
            data-name="shin bone 8"
            class="kniescheibe_dv_left2335_cls1"
            d="M270.34,398.52c-8.76-22.03.24-48.41,13.41-53.29"
          />
        </g>
        <g id="upper_thigh_bone" data-name="upper thigh bone">
          <path
            id="upper_thigh_bone_1"
            data-name="upper thigh bone 1"
            class="kniescheibe_dv_left2335_cls4"
            d="M82.36,16.04c-1.68,3.09-.55,3.55-3,14-3.32,14.2-7.96,24.32-12,33-7.79,16.75-17.23,38.99-38.19,57.71-6.34,5.67-12.5,9.93-18.53,19.5-2.69,4.28-9.95,16.13-9.62,31.72.05,2.47.3,6.62,6.2,23.72,11.45,33.18,17.34,37.45,15.14,49.35-1.07,5.79-3.41,9.84-3.12,18.38.14,4.18.3,10.9,4.29,16.67,6.64,9.58,19.4,10.93,22.8,11.29,17.31,1.82,31.5-7.85,41.03-14.34,16.46-11.22,18.08-19.11,34.33-25.28,9.85-3.74,16.47-3.58,19.22-3.39,11.73.81,19.91,6.91,23.93,9.67,28.26,19.5,63.73,21.8,71.53,22.11,14.64.59,31.5,1.28,42-10.11,12.38-13.41,9.18-36.25,8.32-42.38-1.5-10.73-4.02-10.54-7.32-26.62-3.29-15.99-1.16-18.13-4-33-2.99-15.64-8.01-27.21-11-34-7.92-17.97-11.71-17.97-17.41-31.84-1.09-2.65-1.95-5.06-2.67-7.48-5.02-16.84-5.92-35.68-5.92-35.68-1.3-30.12-4.66-34.9-8-52-2.68-13.75-21.86-3.36-73-2-42.13,1.12-80.55-13.99-75,11h0Z"
          />
          <path
            id="upper_thigh_bone_2"
            data-name="upper thigh bone 2"
            class="kniescheibe_dv_left2335_cls2"
            d="M81.96,7.04c-3.65,21.38-9.68,46.02-14.6,56-7.76,15.73-27.37,49.02-50,70-2.15,1.99-7.12,6.47-11,14-6.54,12.7-5.46,25.35-5,30,.89,9.09,2.99,10.13,11,32,6.52,17.79,9.77,26.68,10,33,.59,16.63-6.75,22.54-2,33,4.16,9.17,13.61,13.02,16,14,18.55,7.57,38.89-4.4,45-8,12.7-7.48,11.92-12.02,31-24,10.34-6.49,16.33-8.77,23-9,8.48-.29,14.54,2.86,21,6,16.96,8.24,35.79,15.92,49,20,10.08,3.11,23.52,7.13,41,6,12.2-.79,23.68-1.53,32-10,10.3-10.49,9.49-26.44,9-36-.72-14.06-4.7-14.15-8-33-3.56-20.34.39-24.21-4-41-1.9-7.27-4.93-13.51-11-26-13.19-27.14-18.79-30.36-23-46-2.4-8.9-1.32-10.61-3-29-1.15-12.63-3.35-30.44-8-52"
          />
          <path
            id="upper_thigh_bone_3"
            data-name="upper thigh bone 3"
            class="kniescheibe_dv_left2335_cls1"
            d="M279.36,201.04c-21.3,32.72-9.85,52.16-19,52"
          />
          <path
            id="upper_thigh_bone_4"
            data-name="upper thigh bone 4"
            class="kniescheibe_dv_left2335_cls1"
            d="M279.36,201.04c17-17.5,0-13,.01-35"
          />
          <path
            id="upper_thigh_bone_5"
            data-name="upper thigh bone 5"
            class="kniescheibe_dv_left2335_cls1"
            d="M260.36,179.04c0,1.66-15.26-11-48,3"
          />
          <path
            id="upper_thigh_bone_6"
            data-name="upper thigh bone 6"
            class="kniescheibe_dv_left2335_cls1"
            d="M174.36,213.21c-1.02,35.66,10.19,56.95,23,56.54"
          />
          <path
            id="upper_thigh_bone_7"
            data-name="upper thigh bone 7"
            class="kniescheibe_dv_left2335_cls1"
            d="M74.36,173.04c-2.7-.31-29.12-.3-56,1"
          />
          <polyline
            id="upper_thigh_bone_8"
            data-name="upper thigh bone 8"
            class="kniescheibe_dv_left2335_cls1"
            points="169.36 191.04 157.27 187.04 141.86 182.04 120.36 186.54 101.36 182.04 101.36 191.04 101.36 213.21 94.86 227.04 104.36 241.48 106.61 260.84"
          />
          <path
            id="upper_thigh_bone_9"
            data-name="upper thigh bone 9"
            class="kniescheibe_dv_left2335_cls1"
            d="M90.36,7.04c0,50.49-19.68,91.35-44,91.35"
          />
        </g>
        <path
          id="patella"
          class="kniescheibe_dv_left2335_cls2"
          d="M148.36,213.04c-7.54.72-8.46-.72-37-6-22.17-4.1-23.17-3.52-28-6-14.23-7.32-21.02-20.36-25-28-5.13-9.85-11.38-21.86-9-37,3.47-22.07,22.69-34.26,27-37,5.05-3.2,4.74-1.95,28-11,25.15-9.79,25.41-11.22,32-12,20.01-2.38,36.33,8.55,40,11,20.93,14,26.6,35.39,28,41,1.52,6.1,4.68,22.3-2,41-1.88,5.26-7.24,19.63-22,31-14.44,11.12-29.26,12.74-32,13Z"
        />
      </g>
      <g id="overlays_upper_layer_" data-name="overlays upper layer ">
        <path
          id="patella_overlay"
          data-name="patella overlay"
          class="Patella2335Fill"
          style={{
            fill: props.colors.Patella2335Fill,
            stroke: props.colors.Patella2335Fill,
          }}
          d="M148.36,213.04c-7.54.72-8.46-.72-37-6-22.17-4.1-23.17-3.52-28-6-14.23-7.32-21.02-20.36-25-28-5.13-9.85-11.38-21.86-9-37,3.47-22.07,22.69-34.26,27-37,5.05-3.2,4.74-1.95,28-11,25.15-9.79,25.41-11.22,32-12,20.01-2.38,36.33,8.55,40,11,20.93,14,26.6,35.39,28,41,1.52,6.1,4.68,22.3-2,41-1.88,5.26-7.24,19.63-22,31-14.44,11.12-29.26,12.74-32,13Z"
        />
        <g id="Femur_condyles_overlay" data-name="Femur condyles overlay">
          <path
            id="Femur_condyles_1"
            data-name="Femur condyles 1"
            class="Femurkondylen2335Fill"
            style={{
              fill: props.colors.Femurkondylen2335Fill,
              stroke: props.colors.Femurkondylen2335Fill,
            }}
            d="M106.52,260.08l-2.16-18.6-9.5-14.44,6.5-13.83c-24.84-13.27-52.07-19.22-87.47,0,4.72,9.34,7.44,18.97,8.47,28.83.77,14.39-5.84,25.59-2,33,7.61,14.51,22.21,17.51,39.89,15.45,16.37-4.39,31.35-17.91,46.27-30.42h0Z"
          />
          <path
            id="Femur_condyles_2"
            data-name="Femur condyles 2"
            class="Femurkondylen2335Fill"
            style={{
              fill: props.colors.Femurkondylen2335Fill,
              stroke: props.colors.Femurkondylen2335Fill,
            }}
            d="M197.36,270.71c-11.96-3.84-18.82-15.13-21.64-39.42s12.96-44.9,36.05-49.15c.2-.04.39-.07.59-.11,16.02-7.33,32.02-9.2,48-3l17.21,19.93c1.13,1.31,1.81,2.94,2.01,4.66,1.67,14.15,9.54,19.48,7.33,47.99-3.61,21.51-22.4,29.22-50.55,28.53-18.92-2.09-31.35-5.56-39.01-9.44h.01Z"
          />
        </g>
      </g>
    </svg>
  );
}
