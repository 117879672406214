import style from "./style.scss";

export default function LinkeHufteAbduktionVdOblLiegend2408(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 338.3 255.9"
    >
      <g id="BG">
        <g id="bg">
          <path
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls3"
            d="M125.6,140.1c.2.4.3.9.3,1.3-1.8,10.3-2.8,12.1-7.9,24.3-5.5,13.2-16.7,20.2-26,26-10.9,6.8-24.6,15.3-39,11-6-1.8-11-5.4-14.2-8.2,0-.3.1-.6.2-.9,3-23.7,2.6-47.5,2.3-71.3,0-.8.4-1.5,1.1-1.8,5-2.3,5.1-2.4,12.5-9.2,3.6-3.3,8.4-8.2,18.5-17.4.9-.8,1.7-1.7,2.6-2.6,2.7-2.5,3.9-3.2,4.2-4.8.2-.9-.2-.9-.1-2.4,0-2.5,1.1-3.3,1.4-5.4.3-2.1-.6-2.8-.9-4.8-.5-3.8,1.9-5.3,2.6-8.5,1.6-7.4-7.8-11.5-6-18.5.6-2.4,2.6-4.9,5.1-5.7,2.1-.6,4.7-.1,6.6,1.1,2.2,1.5,2.4,3.4,4.3,3.7.9.1,1.8-.2,3.1-1.1,5.5-3.6,8.4-8.6,8.8-9.3.6-1.1,1.4-2.6,2.1-4.4.2-.6.8-1.1,1.4-1.2,21.5-4,44-2,65.6-.4,4.7.4,9.3.7,14,1.1,1.5.1,2.3,1.7,1.5,3-.1.2-.3.4-.4.7-.1.2-.2.3-.4.4-1.3,1.2-7.8,9-6.4,16.4,1.6,8.7,3.8,10,1.6,14.4-2.2,4.4-7.4,8.9-8,8-15-6-29.3-3.9-46.1,3.7-5.4,2.5-9.7,6.9-12,12.5-7.3,18-2.6,33.8,7.7,50.2Z"
          />
          <path
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls3"
            d="M85.9,0l12.3,19.7c-4.2.3-7.9,0-11.5-2.3-2.5-1.6-4.3-2.6-7.4-1.9-4.6,1.1-12.2,6.2-15,9.3-5,5.3-1.7,10.8-6,21-2.1,4.9-4.7,8.1-8,12-5.6,6.7-11.6,11.1-17,15-7.1,5.1-8,4.7-11,8-4.1,4.4-8.3,16.1-10.3,21.1L0,96.1"
          />
          <path
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls3"
            d="M273.2,255.8c-14-18.7-43.3-64.8-45-71.4-1.4-5.5,1-7.8-.4-15.6-.6-3.3-1.9-10.4-7.5-16.1-9.2-9.3-23.2-11.8-23.9-11.7-11.2-2.3-18.9,2.7-25.2,4.5-7.9,1.2-8.4,5.1-16,6-8.5,1.1-15.3-2.7-21-5.8-6.9-3.8-17.4-9.5-21.9-22-4.9-13.4.2-25.5,1.9-29.6,1.5-3.4,5.8-13.4,16.9-20.2,5.9-3.6,11.5-4.9,15.5-5.5.8-.1,1.5,0,2.3.1,1.8.3,5.2-2.4,9.9-2.6,8.1-.3,11,7.1,18,6.8,8.3,4.8,16.8,4.3,25.4,0,2.1,0,5.1-.9,8.5-4.2,2.4-2.4,5.1-4.5,7.9-6.4,15.2-9.9,19.6-10.4,19.6-10.4,10.1-1.2,18.6,4.1,20.9,5.6,6.1,3.9,5.2,6,14.6,15.1,10.3,10,13.2,9.3,16,15.2,2.7,5.8.2,7.1,2.3,19.3,1.1,6.6,2.7,11.3,5.8,20.2,3.6,10.7,28.7,62.5,39.9,86.4"
          />
        </g>
      </g>
      <g id="Underlays">
        <path
          id="hipjoint"
          class="Hipjoint2408Fill"
          style={{
            fill: props.colors.Hipjoint2408Fill,
            stroke: props.colors.Hipjoint2408Fill,
          }}
          d="M178.4,72.1c2.8-.8,5.7-3.5,5.3-5.7-.2-1-1.2-1.8-4.1-3.1-5.6-2.5-10.2-3.4-10.5-3.4-7.2-1.5-10.8-2.3-14.2-2.3-5.7,0-9.8,1.2-16,3.1-5.3,1.6-11.1,3.4-17.9,7.7-5.1,3.2-11,6.8-15.1,14-2.4,4.2-3.6,8.4-4,10-.3,1.3-1.3,5.3-1.4,10.5,0,1.3-.1,7.6,2.3,15.7.9,3.1,2.9,7.1,6.8,15.1,2,4,3.8,8.3,8,12.5,2.8,2.8,4.6,3.6,5.8,3.1,1.2-.4,1.8-1.7,2.3-2.8.1-.3,2.2-3.4.7-5.7s-4.8-4.3-8-7.7c-3.8-4-4.8-8.6-6-13.7-2.3-10,0-18.1.9-21.4,2.1-7.3,5.4-12.2,6.3-13.4,1.8-2.6,5.2-7.3,11.4-10.8,4.9-2.8,9.8-3.9,11.7-4.3,3.9-.8,5.2-.5,9.7-1.7,3.4-1,4.1-1.6,6.6-1.7,1.3,0,4.6-.3,8,1.4,3.2,1.6,3.2,3.3,6.3,4.3.9.3,3,1,5.4.3Z"
        />
      </g>
      <g id="Anatomy">
        <g id="Femur">
          <path
            id="Femur4"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls1"
            d="M273.4,255.6c-14-18.7-43.3-64.8-45-71.4-1.4-5.5,1-7.8-.4-15.6-.6-3.3-1.9-10.4-7.5-16.1-9.2-9.3-23.2-11.8-23.9-11.7-11.2-2.3-18.9,2.7-25.2,4.5-7.9,1.2-8.4,5.1-16,6-8.5,1.1-15.3-2.7-21-5.8-6.9-3.8-17.4-9.5-21.9-22-4.9-13.4.2-25.5,1.9-29.6,1.5-3.4,5.8-13.4,16.9-20.2,5.9-3.6,11.5-4.9,15.5-5.5.8-.1,1.5,0,2.3.1,1.8.3,5.2-2.4,9.9-2.6,8.1-.3,11,7.1,18,6.8,8.3,4.8,16.8,4.3,25.4,0,2.1,0,5.1-.9,8.5-4.2,2.4-2.4,5.1-4.5,7.9-6.4,15.2-9.9,19.6-10.4,19.6-10.4,10.1-1.2,18.6,4.1,20.9,5.6,6.1,3.9,5.2,6,14.6,15.1,10.3,10,13.2,9.3,16,15.2,2.7,5.8.2,7.1,2.3,19.3,1.1,6.6,2.7,11.3,5.8,20.2,3.6,10.7,28.7,62.5,39.9,86.4"
          />
          <path
            id="Femur3"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls1"
            d="M232.9,145.3c-1.9-10.4-4.6-18.7-6.9-24.5-3.8-9.6-5.6-14.4-10.1-19.1-5.7-6-9.3-5.7-12.7-11.5-4.1-7-3.9-16.2-1.1-17.4,3.4-1.5,8.6,9.7,15.9,8.5,3.6-.6,6-4,8.3-7.3,4.8-6.9,2.8-10.5,6.7-15.2,3.7-4.6,10.9-7.5,16.6-6,3.2.9,4.8,2.9,13.2,15.7,8.7,13.3,9.7,15.4,12.9,19.3,3.2,3.9,8.2,9.1,15.7,14.2"
          />
          <line
            id="Femur2"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls1"
            x1="232.7"
            y1="99.6"
            x2="226"
            y2="120.8"
          />
          <path
            id="Femur1"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls2"
            d="M155.4,151.4c-2.8-4.8-5.6-9.6-8.1-14.2.6-14,7.6-28.7.3-42.7-1.4-7.7.8-14.5,1.8-22.7,0-1.3-.6-2-.4-3.3"
          />
        </g>
        <g id="Hip">
          <path
            id="Hip8"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls1"
            d="M44,120.3c11-6,15-14.7,28-25,8.5-6.7,14-16,19-26s11-23,14.9-34.7"
          />
          <path
            id="Hip7"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls1"
            d="M184,65.8c-4.4-2.5-11.4-5.9-20.7-7.2-14.3-2-25.3,2.3-32,5-6.4,2.6-14.6,5.8-22,14-1.7,1.9-7.8,8.9-11,20-3.2,11.2-2.3,22.9-1,23,1.5.2-21,16,13-68"
          />
          <path
            id="Hip6"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls1"
            d="M105,35.8h0c-4.7,8.3-8.2,9.9-10.7,9.8-4.2-.2-5.9-5.4-10-5-3.4.3-6.7,4.2-7,8-.4,4.6,3.9,5.8,5,12,.7,4-.3,7.1-2,12-1.1,3.2,3.6,6.7-.3,11.7,7.3,0,18.8-29.8,25-48.5h0Z"
          />
          <path
            id="Hip5"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls1"
            d="M190.1,32.5c-4.1,5.6-8.1,11.6-7.1,19.6,1,5,7.1,12.7-5.2,20.5"
          />
          <path
            id="Hip4"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls1"
            d="M98.2,19.7c-4.2.3-7.9,0-11.5-2.3-2.5-1.6-4.3-2.6-7.4-1.9-4.6,1.1-12.2,6.2-15,9.3-5,5.3-1.7,10.8-6,21-2.1,4.9-4.7,8.1-8,12-5.6,6.7-11.6,11.1-17,15-7.1,5.1-8,4.7-11,8-4.1,4.4-8.3,16.1-10.3,21.1"
          />
          <path
            id="Hip3"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls1"
            d="M126.2,140.8c-.8,3.6-1.5,7.1-2.3,10.5-2,7-5,14-9,21-8,13-22,21-35,27-12,5-28,7-38.3-2.6"
          />
          <path
            id="Hip2"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls1"
            d="M80,84.3c0,7.7,2.9,8.3,7.3,8.3"
          />
          <path
            id="Hip1"
            class="linke_Hufte_Abduktion_vd_obl_liegend2408_cls1"
            d="M101.3,133.6c-10.4.4-19,1.8-25,3-20.2,4.1-24.8,9.4-26,11-1.2,1.5-6.4,8.1-4,15,2.1,6,9.2,9.4,15,9,7.9-.6,12.6-8.4,16-14,2.2-3.7,6.7-12.2,9-38,.6-6.4,1.1-15.7,1-27"
          />
        </g>
      </g>
    </svg>
  );
}
