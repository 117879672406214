import style from "./style.scss";

function AbdomenInLinksseitenlagePaLiegend1104(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 722.22 659">
      <g id="Abdomenap">
        <g id="Overview">
          <g id="Backround">
            <path
              id="backround"
              class="Abdomen_in_linksseitenlage_pa_liegend1104_cls5"
              d="M24.94,600.15c27.2,5.3,57,6.3,87.8,5.3,32.9,7.8,66.5,5.6,100.5-3.5l54.9-15.9c15.4-4,30.9-9.1,46.8-15.2,15.8-7.3,38.5-2.8,62.7,4.2l96.3,30.7c17,5.4,36.2,7,56.5,6.1,20.8-.4,44.8-3.5,70.5-8.1,21.8-3.9,47.5-9.9,75.4-17.2-8.7-12.8-11-28.2-9.1-45.1,13.5-9.5,24-25.5,25.1-46.2-1.1-5.6-4.7-10.2-11.7-13.4-.3-4.6,2.1-8.8,6.4-12.6l5.4-47.6c5-48.6,70.6-62.1-10.5-146.9l-2.1-36-2.8-16.9c-2-4-1.8-8.4,0-12.7l-10.9-65.5-3.1-14.5-39-10.5c-39.4-11.6-78.2-17.2-116.3-16.2-28.3-1.1-61.6,5.3-96.8,18-16.8,5.9-33.6,10.6-50.5,14.2-26.1,5.9-51.7,5.6-76.5,0-17.3-3.8-33.8-9.5-49.6-16.3-19.1-7.5-40.1-12.3-63.5-13.1-27.5-5-64.6-2.7-104,1.4-21.1,2.4-41.5,7.5-61,14.7,1.3,2.5,1.3,5.7,0,9.8-4.5,37-4.5,80.1-2.2,125.9-1.1,14.8,2,39.9,5,65.3l1.3,4.5c-2.1,16.2-3.5,31.6,0,41.6,3.2,2.2,5.7,7.8,7.8,15.6-9.5,69.5-6.8,137,7.1,202.2,1,5,1.4,9.7.1,13.9h0Z"
            />
          </g>
          <line
            id="symphyse2"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            x1="686.04"
            y1="353.05"
            x2="715.54"
            y2="351.35"
          />
          <line
            id="symphyse1"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            x1="686.04"
            y1="338.05"
            x2="715.54"
            y2="338.05"
          />
          <path
            id="spine40"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M17.84,384.05c1.3-34.8-1-55.4-7.8-57.2"
          />
          <path
            id="spine39"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M24.64,390.55c9.4-3.6,19-5.6,29.3-4.6,9.4,2.2,12-1.1,10.6-7.7l-1.3-44.8c2.9-13,1.7-19.4-9.1-10.9-10.2,8.9-22.1,9.4-35,4,0,.2,5.5,64,5.5,64Z"
          />
          <path
            id="spine38"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M81.54,392.55c-4.6-1.3-8-3.2-5.4-8.5.8-20.9,0-40.6-3.2-58.5,3.6-1.5,7.5-1.7,11.7,1,3.2,2.1,7.3,2.9,10.9,1.8,2.7-.8,5.4-2.2,8.4-4,3.9-4.9,8.2-6,13.1-2.9l4.5,59.6c1.7,10.3-5.2,11.7-14.2,10.9-9.8-3.5-18.6-3.7-25.8.6h0Z"
          />
          <path
            id="spine37"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M47.14,363.95c14.8,2.2,27.1,3.4,30.7,0,.4-8.5-9.1-10.1-26.2-6.6"
          />
          <path
            id="spine36"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M105.64,360.85l17,3.8c7.3,2.8,11.9,2.4,11-3.8-.1-4.6-3.9-6.3-12-4.7l-14.7,2.1"
          />
          <path
            id="spine35"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M142.34,386.55c-6.6,2.7-10.1-.3-10.9-7.8l-2.1-48.7c-.8-6,1.1-8.9,6.4-8.2,12.6,1.7,23,1.4,29.3-2.9,7-5,11.3-3.8,13,3.5-1.5,18.1-.3,35.9,3.6,53.2,1.3,7.7-1.4,11.3-10.3,8.5-11.4-2.5-21.3-2.1-29,2.4h0Z"
          />
          <path
            id="spine34"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M211.44,379.45c-10.9,5.9-17.7,6.8-17.2-1.1-3.8-16.9-5-35.2-3.8-55.1,2-9.1,7.4-11.3,15.6-7.4,8.7,1.3,16.1.4,21.6-3.9,8.9-2.2,14-.4,13.8,6.8-5,19.1-4.6,37.3,0,54.6,1.8,8.9-2.8,9.8-9.9,7.7l-16.1-2.4c-1.2-.2-2.7.1-4,.8h0Z"
          />
          <path
            id="spine33"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M264.94,383.35c-5.9,1.8-9.4,1-9.4-4-6.1-22.6-6.1-44.4,1.3-65.1,2.8-6,7.1-6.4,12.7-1.7,9.9,5,18.7,6.4,25.4,0,9.2-6.3,12.7-4.6,9.5,6.3-4.2,20.2-5.2,39.9-2.5,59.2,1.4,12.3-2.5,14.4-10.9,8.1-7.8-6.7-16.5-8.5-26.1-2.8Z"
          />
          <path
            id="spine32"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M229.74,353.35c14.2,1.8,26.4,4.7,30.6,11.7,3.6,9.1,2,15.4-6.1,18-6,1.5-14.9-1.4-24.4-5"
          />
          <path
            id="spine31"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M234.64,349.45l16.6-8.1c8.9-4.2,14.5-9.1,11-15.5-1.8-4.6-6.4-5.3-12.3-4l-16.8,2.9"
          />
          <path
            id="spine30"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M225.04,358.65l10.3-4.5c4.9-2.1,10.3-2.8,15.6-1.8,4.7,1,7.1,3.1,6.7,6.3.8,5.9-2.8,8.1-10.8,6.4-.7-.1-1.4-.4-2-.8-6.7-3.9-13.3-4.5-20-2"
          />
          <path
            id="spine28"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M175.64,381.95c8.4,3.8,16.3,6.7,20,2.1,4-5.3,4-9.6,1.3-13.1-4.7-4.2-13.3-7.5-24.2-10.1"
          />
          <path
            id="spine27"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M174.74,365.45c5.2,1.1,10.2.8,14.8-1.5,4.6-2.4,3.9-9.1-.8-10.6h-.1s-17.6,0-17.6,0"
          />
          <path
            id="spine26"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M172.64,347.55c9.1-1.5,16.6-5.7,22.1-13.5,1.5-4,2.4-7.8,0-10.3-6.4-2.4-14.5-1.5-23.7,2"
          />
          <path
            id="spine24"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M332.84,387.55c-9.4,5.6-14,3.9-14.4-4.2-3.4-23.7-4-45.5,0-63.8,1.5-7.4,4.9-10.6,11-6.8,9.2,4.2,17.9,4.6,25.8-1.8,6.7-3.8,11-2.2,11.9,6.8l-1.3,66.2c.3,10.6-3.6,13.4-13.1,6.6-5.3-4.6-11.8-5.5-19.9-3h0Z"
          />
          <path
            id="spine23"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M285.34,379.15c4.3-2.2,9.2-2.2,14.2,0,6.6,3.5,12.4,5.4,17.6,4.9,3.6-.4,6.4-3.6,6.7-7.4.3-5.3-1.3-9.4-5.3-11.2-6.6-3.6-14.7-6.7-25-8.7"
          />
          <path
            id="spine22"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M296.04,351.65c15.4-3.6,27.2-8.4,29.7-16.8,1.5-9.2-4-13-14-13.5-7.3,1-14.1,1.4-20.5,1"
          />
          <path
            id="spine21"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M392.84,392.55c-7.1,4.2-10.6,2.9-11-2.9-.7-7.8-.1-16.5,2-26.2,2.2-17.3,2.9-33.6-2-46.5.7-5.9,2.2-10.3,7.7-8,9.5,6.6,18.7,9.6,27.4,3.1,10.5-7.4,14.8-6.6,14.2,1.1-5.9,18.6-7.1,36.2-2.9,52.4,7,19.5,8.4,31-1.4,26.8-2.5-1.1-5-2-7.7-2.2-10.2-1.2-19.2-.5-26.3,2.4h0Z"
          />
          <path
            id="spine20"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M358.64,385.25c10.8,4.7,19.1,4.9,24.2-1.3,4-10.3,2.1-17.6-8.2-20.4-5.7-.3-11-1.1-15.9-2.9"
          />
          <path
            id="spine19"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M342.34,354.15c9.8-2.2,20.5-.1,31.4,2.8,4.3,2.4,3.9,4.6.8,6.8-5.7,1-12-.3-18.7-2.9-3.6-2.2-7.5-2.9-11.7-2.1"
          />
          <path
            id="spine18"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M362.64,353.35c9.9-2.5,18.8-5.7,21.2-12.7,2.1-7.3,1.1-14.1-2.4-20.8-3.4-5.4-7.7-5-12.4-2.8-4.9,2.2-9.6,3.9-14.2,4.3"
          />
          <path
            id="spine17"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M487.74,404.45c-4.2,11-12.4,9.1-21.6,3.8-8.4-7.5-16.6-10.5-24.4-8.7-6.7,1.3-10.9.1-10.6-5.2,9.5-21.8,7-49.3,0-78.9l6.6-9.5c5.4-4,10.3-4.3,14.8,0,3.8.8,7.4-.3,11-4,1.1-5.3,6.3-6.8,13.5-6.4,8.9,3.4,8.5,14.4,4,28.6,8.9,14.7,5.7,23.9-4.6,29.9,6.1,3.8,9.5,10.2,8.5,20.7,5.2,4.1,5.4,15.1,2.8,29.7h0Z"
          />
          <path
            id="spine16"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M415.84,364.05c12.4,2.7,20.1,7.3,22.6,14.1,2.5,7.5-2.2,10.8-8.5,13.1"
          />
          <path
            id="spine15"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M416.74,349.25l19-9.5c5.7-4.9,6.8-10.3,1.4-16.8-3.8-5.9-7.1-9.6-10.2-9.4"
          />
          <path
            id="spine14"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M392.84,360.85c11.4,1.5,22.1,2.2,24.9-5.2,1.3-6.8-7-8.2-24.9-3.8"
          />
          <path
            id="spine13"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M485.14,374.85c-6.7,6.6-14,6.3-21.6,0-9.6-9.5-12.6-20.7-7-33.8,5.9-12.3,14.5-16.8,24.9-16.8"
          />
          <path
            id="spine12"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M449.84,336.45c-1.8,6.3-5.4,10.5-10.5,12.8"
          />
          <path
            id="spine11"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M439.34,362.85c5,2.8,8.9,6.8,11.6,12"
          />
          <path
            id="spine10"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M208.84,387.55l-1.5,17c.6,10.5,3.9,15.5,9.6,16.2,2.9.4,5.4-2.1,5.2-5-.1-1.1-.6-2.4-1.1-3.8-2.1-5.2-1.4-11.2,2.5-15.4q.1-.1.3-.3c.8-.8,1.3-2,1.3-3.2v-6.7"
          />
          <path
            id="spine9"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M211.74,320.05l1.7-8.2c.8-3.9.8-7.7-1-11.2-.8-1.5-1.3-3.2-1.1-5,.3-2.8,1.7-5,4.3-6.7,1.5-1,3.4-1.5,5.2-1.4,3.6.3,5.7,2.8,6.4,7.5v6.3c0,1.4-.1,2.9-.7,4.2-2.5,6.8-2,13.3,1.3,19.3"
          />
          <path
            id="spine8"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M268.84,384.75c4.6,3.6,4.6,9.5,1.5,16.6-1.5,4.3-2.2,8-2.4,10.9-.1,5.3,4.3,9.9,9.6,9.6,3.4-.1,5.4-2.2,5.4-7.8-.1-9.9.8-18.8,3.8-26.4"
          />
          <path
            id="spine7"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M270.54,312.95l1.5-7.4c1.1-5.6.8-10.1-.8-13.5-1.8-2.9-2.4-5.4-1.3-7.7,1-1.8,2.9-2.9,4.9-2.7,2.5.3,4.6,2.7,6.3,6.1.8,1.7,1.1,3.5.8,5.4l-.7,6c-.7,6.7,1,12.4,4.9,17.5"
          />
          <path
            id="spine6"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M332.24,388.75c.6,3.1-.4,6-2.4,8.9-3.8,6.8-5,14-4.9,21.2,0,3.1.8,6.1,2.7,8.7,2.1,2.9,4.7,4.3,7.8,4.6,4,0,5.9-2.5,5-8.4-1-9.1,1-17.9,5.4-26.2.6-1.1.8-2.2.8-3.4v-3.4"
          />
          <path
            id="spine5"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M329.14,316.75v-10.1c0-1,.1-2,.6-2.9,2-4.7,1.8-9.2-.1-13.4-.3-.6-.6-1.1-.6-1.8-1.5-6.7-.7-11.7,5.3-13,2.2-.4,4.3.1,6,1.8,2.4,2.2,3.2,5.7,2.7,8.9-1.8,10.5-1.1,19.3,1.8,26.2"
          />
          <path
            id="spine4"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M387.14,392.65c1.5,3.9,1.4,8-.4,12.4-.3.7-.4,1.4-.4,2.1-.1,6.7.3,11.9,1.4,15.4,1.5,4.9,8.2,6.3,11.2,2.1,1.1-1.5,1.5-3.4,1.1-5.6-.7-5.2,2.1-11.2,6.4-17.6.7-1.1,1-2.4.7-3.8l-2.7-14.5"
          />
          <path
            id="spine3"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M386.74,316.75l2.4-8.5c.7-4.6.4-8.8-1.1-12.7-.6-1.4-.8-2.8-1-4.3-.6-4.9-.7-9.5,2.8-11.2,4.2-1.4,6.8,1.1,7.7,7.4-.4,8.2.7,15.2,4,20.2,1.1,1.7,1.7,3.8,1.7,5.7v10.1"
          />
          <path
            id="spine2"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M467.54,406.05c.1,8-2.2,14.2-9.5,16.9-4.3,2.1-6.7,5.3-7.7,9.1-.4,1.4-1.4,2.7-2.8,3.1-1.5.6-3.1,0-4.5-1.5-2.2-2.4-2.8-5.7-1.7-8.8,3.5-10.9,5.2-20.8.4-27.6"
          />
          <path
            id="spine1"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M437.54,310.35c2.2-6.8,2.8-12.7.1-17.2-1.1-2-2.4-3.9-3.2-6-2.2-5.4-3.4-10.1-2.4-13.3.6-2,2.2-3.2,4.3-3.4,2.7-.1,4.9,1.5,7,4,4.6,6.1,8.4,13,11.4,20.5,1.3,4.6,4.6,8.4,8.5,12"
          />
          <path
            id="air16"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M260.24,283.75c-4.5-3.2-5.3-7.5-1-13.3,4.6-6.8,10.1-9.6,16.8-7.3,8,2.9,8.5,8.5,6.8,15.1-6.2,10.7-13.8,11.3-22.6,5.5h0Z"
          />
          <path
            id="air15"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M216.24,253.75c-3.6-6-4.2-10.1.1-11,1-.1,2-.6,2.8-.8,6.7-2.9,13.8-3.6,21.4-2.7,7,1.4,12.1,3.5,15.9,6.3,4.3,3.2,6.1,8.8,4.9,14.1-1.5,6.3-4.9,10.6-9.9,13.3-3.4,1.8-7.4,2.5-11.2,2.2-9.8-.7-16.9-6.6-21.8-17.3-.7-1.4-1.5-2.8-2.2-4.1h0Z"
          />
          <path
            id="air14"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M269.04,224.85c1.5,2,1.3,4.7-.7,6.3-9.6,7.7-20.4,9.1-31.8,7-13.1-2.1-17.6-6.8-13-14.2,3.2-6.7,9.1-9.5,17-8.9,15.3,1.2,24.3,4.6,28.5,9.8h0Z"
          />
          <path
            id="air13"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M234.34,185.45c-2.2-.1-4.6.1-6.7.8-6.1,2.4-7.7,7.4-6.7,13.7-.1,9.1,4.7,12.8,10.2,15.9,10.6-2.5,20.9-.3,31,3.6,4.5-1.4,6.8-4.2,6-9.4.3-2.7-.1-5.4-1.1-8.1-2.2-6.8-8-11.9-14.8-13.8-5.4-1.3-11.4-2.2-17.9-2.7h0Z"
          />
          <path
            id="air12"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M227.24,185.45c-2.5-3.4-3.4-7.3-1.1-12.1,3.1-4.2,7-7.7,14-7.7,8.1.4,15.2,3.5,20.7,10.6,8.4,8.9,10.1,15.2,2.4,17.9-10.6-6.7-22.3-9.5-35.6-7.7"
          />
          <path
            id="air11"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M140.14,267.85c-6.6,3.8-12.8,1.5-19-2.1-2.4-1.4-4.9-2.5-7.5-3.2-15.9-4.2-29.6-11-39.5-22.2-6.4-8-7.1-16.3-2.1-25.4.6-1,1.1-2,1.5-2.9,4.2-8.2,9.8-15.6,17.9-21.6,4.7-3.5,10.5-5.4,16.3-5.6,6.4-.1,11.7.8,14.9,3.5,1,.8,2.1,1.7,3.2,2.4,9.9,5.7,15.9,14.1,18,25l.8,29.3c.1,3.2.4,6.3.8,9.5.7,5.1,0,10-5.3,13.3h0Z"
          />
          <path
            id="air10"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M73.84,164.65c-1.1-2.2-1.5-4.5-1.3-6.7.3-2.5,1.7-4.9,3.6-6.7,4.3-3.8,9.1-7.4,14.1-10.9,2.5-1.7,5.3-3.4,8.1-4.6,7-3.2,12.6-4.2,15.5-1,4.2,4.2,7.4,10.9,9.8,19.4,1,3.5,1.1,7.3.1,10.8-1.7,5.9-5.9,8.8-12,9.1-9.6.7-18.6.6-26.2-1.1-4.9-.9-9.3-3.8-11.7-8.3h0Z"
          />
          <path
            id="air9"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M287.14,414.65c-6-4.7-12.8-3.5-20.2,1-5.4,5.6-7.1,12-6.6,19.3,2.4,6.6,6.7,10.3,11.7,12.8,4.6,2.1,8.1.4,10.2-6.1.1-6,2-10.6,4.7-14.4,3.1-5,4.4-9.6.2-12.6h0Z"
          />
          <path
            id="air8"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M291.24,458.75c-3.1,3.6-2.2,7.8,1,12.4l9.1,2.5c2.8,2.8,5.3,2.1,7.4-3.4,1.4-4.5-.1-8.4-4.2-11.6-2.7-1.9-6.9-2.2-13.3.1Z"
          />
          <path
            id="air7"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M322.04,424.75c-.7,4.5,1.5,7.1,6.3,8.2,7.3,2.1,14.2,2.7,20.4-2.2,8.1-5,12.3-9.6,9.2-13.3-.4-4.9-5.9-7.8-13.3-10.2-8.7-2.8-15.6,1.5-21.9,8.8l-.7,8.7h0Z"
          />
          <path
            id="air6"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M387.44,466.25c-2.9,4.2-5.3,4-7.1.7-2.7-2.5-2.2-6-1-9.6,2.4-1.7,4.6-1.8,6.8,0,1.5,2.8,1.7,5.8,1.3,8.9h0Z"
          />
          <path
            id="air5"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M309.04,251.65c.7,2.5,2.2,3.4,4,3.1,3.5.1,5.4-.8,4.2-3.8-1.3-4.2-2.7-5.7-4-5.9-5.3.8-6,3.1-4.2,6.6h0Z"
          />
          <path
            id="air4"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M311.44,240.05c.1,3.9-2.8,5-7,4.9-3.6.8-7.3-.1-11-2.5-2.9-2.5-3.4-5.6-1.5-9.4,2.7-2.4,6-3.1,9.8-2.7,9.1.8,11.2,4.4,9.7,9.7h0Z"
          />
          <path
            id="air3"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M283.54,220.65c-2.9-5.3-2.8-9.5,3.6-11.6,11.4-7,18.6-7.8,19.5,0,3.4,6,2.1,11.6-.8,17-2.5,3.9-8.8,3.2-15.9,1.7-2.4-1.5-4.5-3.7-6.4-7.1h0Z"
          />
          <path
            id="air2"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M329.44,271.65c-3.4,1.4-5.6.7-5.7-4.2l5.7-13.7c-.8-2.2.6-4.5,4-6.8,5.4-4.6,11.3-4.5,17.5-1.1,4.9,3.5,7,7.3,5.3,11.9-4.5,8-9.5,7.8-14.8,3.4-1.1,5.9-5.7,9-12,10.5h0Z"
          />
          <path
            id="air1"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M375.14,267.35c-.7,2.8-2.5,4.3-5.4,4.2-3.2-.1-6.1-2.2-7.4-5.2-.8-2-1.1-4.3-1-6.8.1-1.5.7-3.1,1.7-4.3l3.1-4c.6-.7,1-1.5,1.3-2.5,1.1-3.4,3.5-5.9,7.8-7.3,2.2-.7,4-2.2,5-4.3,2.2-4,6-6.3,11.9-5.7,1.3.1,2.1-1,2-2.1-1.1-5.3,0-9.2,5.6-10.5,6.4-1.1,12-.8,15.4,2.7.6.6.7,1.5.3,2.2-1.3,2.8-1.7,5.4-1.1,8.2.3,1.4,0,2.8-.8,3.9l-3.2,4.7c-1.3,1.8-2.4,3.9-2.8,6.1-.7,3.4-5.6,6.7-12.3,9.9-.6.3-1.3.4-2,.4-3.1-.3-5.6,1.3-7.5,4-1.1,1.5-2.7,2.7-4.5,3.1-2.6.3-4.6,1.4-6.1,3.3h0Z"
          />
          <path
            id="psoasedge"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M134.44,313.35c56.8-4.5,111.7-9.5,139.3-24.3l186.5-56.7"
          />
          <path
            id="liverandpsoasedges"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M369.94,569.05c-11.9-15.9-28.3-31.7-48-47.5-56.8-52.1-109.3-99.1-144.9-126.6,29,9.2,88.7,27.9,159.4,50.3,29.2,4.3,74.7,13,122,22.2"
          />
          <path
            id="diaphragmaticcoupola2"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M10.04,368.35c35.9-7.7,67.4-3.5,92.8,18-22.6,30.7-39.1,60.6-46.5,88.9-5.6,52.8,11.6,92.7,50.8,120.3-27.8,3.2-55-.8-82-9.9"
          />
          <path
            id="diaphragmaticcoupola1"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M8.74,322.15c27.5-.3,55.4-3.9,83.6-11.9C37.34,255.85,13.84,194.95,58.54,119.95c-17.7,1.1-35.2,4.9-52.8,10.9"
          />
          <path
            id="ribs16"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M50.74,243.45c29.6,3.5,57,2.1,79.6-11,26.4-14,49.6-30.7,65.9-53.8,29.5-25.4,39.8-46.9,17-61.7"
          />
          <path
            id="ribs15"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M28.14,392.55c-8.4,17.5-13,35.2-12.1,53.3.3,36,7.8,64.6,23.9,84,11.5,17.9,36.8,33.6,71.6,47.7,29.5,10.6,60,17.6,91.6,20.7"
          />
          <path
            id="ribs14"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M50.74,389.65c-10.6,17.2-17.5,34.8-18.7,53.1-1.8,25.8,4.3,48.7,15.2,69.7,9.5,17.2,32.8,33.2,62.3,49,32.1,14.8,73.9,22.8,120.2,27.4"
          />
          <path
            id="ribs13"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M83.44,394.35c-5.7,5.6-10.9,13.1-14.8,26.2-3.4,9.5-2.4,20.5,1.3,32.4,7.1,23.5,22.3,43.6,42.2,57.9,14.6,10.7,31.4,19.8,49.6,27.7,26.9,13.5,57.5,22.6,90.7,28.3,3.1.6,6.4-1.1,7.4-4.2.7-2,.1-3.9-2.2-5.7-49.8-19.4-98.3-38.7-124.1-56-27.1-18.7-45-40.1-45.4-66.6-.1-8.9,2.4-17.9,6.8-25.7l6.3-11c1.1-2,.3-4.6-2-5.4-5.3-1.8-10.8-1.5-15.8,2.1h0Z"
          />
          <path
            id="ribs12"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M105.64,588.75c22.9-1.7,44.3-8.7,64.1-21.5l50-33.8c10.5-5.9,16.1-4.3,17.9,2.8-8.5,10.2-24.6,21.2-44.8,32.8-20,13.1-41.2,20.9-63.4,25.1"
          />
          <path
            id="ribs11"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M112.74,596.75c12.7,2.8,25,5.2,35.3,5,21.6-1.3,41.9-3.6,57.8-9.1,21.1-7.5,40.6-15.9,51.1-28.9.3-2.8-1.3-4-4-4.2l-69.2,28c-10.6,4.3-25.3,7.5-41,10.5"
          />
          <path
            id="ribs10"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M148.64,396.45c-5.3,3.1-4,8.1-2,13.3l16.7,51.8c2.7,8.4,9.5,13.8,19.3,17.5,3.8,1.4,7.4-2.2,6.1-6-1.1-3.4-3.5-7.3-7.1-11.7-12.7-23-20.1-44-16.6-60.7,2.9-5.2.4-8-8.1-7.8l-8.3,3.6h0Z"
          />
          <path
            id="ribs9"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M152.54,401.05c-2.7.4-4.2,0-4.9-1.4"
          />
          <path
            id="ribs8"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M158.44,399.55c2.9-1.1,5.2-2.4,6-3.8"
          />
          <path
            id="ribs7"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M145.74,316.75c-4-1.7-5.7-4.2-4.2-7.7,5.7-18.4,15.6-39,27.8-60.3,12.7-20.8,28.6-37.7,48.6-49.8,11.9-10.3,16.9-10.3,17.6-3.8.4,6.3-11.7,15.6-22.2,24.6-20.5,19.5-38.4,39.5-47.2,60.6-2.9,8.7-4.3,17.9-4.6,27.4,3.5,5.6,2.2,7.8-2,8.1l-13.8.9h0Z"
          />
          <path
            id="ribs6"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M83.04,322.15c-12.3-12.6-13.3-29.9-8.1-50,4.2-24.3,18.3-46.9,36.3-68.8,18.7-16.8,37.6-29.9,56.5-39.8,2.8-1.5,5.6-3.1,8.4-4.9,17.9-11.2,34.8-19.1,49.1-19.4,2.1,0,4.3,1,5.3,2.9,2.8,5.2-4.7,10.5-19.1,15.8-34.8,17.7-63.7,37.8-88.1,59.6-16.2,16.3-25.3,35.2-28.9,56.1-2.1,11.6-.7,23.5,3.8,34.3l5.7,14.1"
          />
          <path
            id="ribs5"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M42.04,328.05c2-5.4-2.9-9.8-11.4-13.5-12.7-17.2-18.1-36.2-13.5-57.5,7.4-26.2,24.3-49.4,50.5-69.5,41-36.6,93.1-57.5,152.2-69"
          />
          <path
            id="ribs4"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M19.14,324.85c-6.3-11.2-10.3-21.9-13.3-32.4-3.1-15.5-2.8-31.8,0-48.9,3.2-18.3,15.6-36.7,32.1-55.4,22.5-19.5,45.2-37,69.2-49.6,30-16.2,65.5-23.9,104.2-26.5"
          />
          <path
            id="ribs3"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M68.64,147.55c17.6,5.3,33.5,12,46.1,21.8,7.5,8.2,15.4,12.7,23.5,12,2.1-.1,4-1.3,5-3.1,1.8-3.8-1.1-8-9.5-12.7-22.2-13.3-43-23-61-26.1"
          />
          <path
            id="ribs2"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M76.24,128.15c17.6,1.7,35.2,4,53.1,7.3,17.7,2.5,34.3,9.9,50.7,20.1,4,2.7,8.5,4.7,13.1,6.3,4.3,1.4,8.7,2.5,12.8,3.2,3.1.6,5.6-2.8,4-5.6-1.7-3.5-5.2-6.8-11.2-10.3-39-18.7-78.6-33.2-120.9-33.4"
          />
          <path
            id="ribs1"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M64.84,138.05c20.5-12.1,40.8-21.2,60.5-22.3,21.2-1,44.3,2,70.9,13.1,5.6,3.6,13.1,4.9,21.8,4.5,2.7-.1,3.6-3.5,1.5-5-4.9-3.6-12.8-6.8-22.3-9.9-17.6-8-38.5-12.4-62-14.1-4.3-.3-8.7-.1-12.8.6-15.2,2.4-33.9,7.5-53.8,13.5"
          />
          <path
            id="outline2"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M5.84,121.15c21.5-7.1,42.6-13.7,61-14.7,5.2-.3,10.5-.7,15.6-1.4,24.3-3.2,55.7-2.1,88.5.1,21.5,1.5,43,4.2,63.5,13.1,9.1,3.9,18.4,7.7,27.9,10.5,7.3,2.2,14.5,4.2,21.8,5.9,25.1,5.9,51.4,5.6,76.5,0,18.1-4,35.2-8.7,50.5-14.2,11.9-4.3,23.7-8.1,36-10.9,19-4.3,37.1-7.1,53.5-7,2.4,0,4.9,0,7.3-.1,31.7-1.4,64.8,2.7,99,11.6l56.1,15.1"
          />
          <path
            id="outline1"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M24.94,600.15c25.4,4.7,53.8,6.6,85.4,5.3,2.8-.1,5.6.1,8.4.6,32.8,6,64.5,6.4,94.4-4.2l72.6-21.1c9.2-2.7,18.1-5.9,27.1-9.4,17.2-6.6,36.9-5.6,58.9,1.7l96.5,31c13.3,4.2,26.8,6.8,40.6,7.5,46.5,2.5,104.7-8.4,168.1-25.3"
          />
          <path
            id="pelvis33"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M680.54,481.85c-15.2-3.1-26,4.3-33.1,20.2-4,13.5-1.3,25.4,5.4,36.3-7.5,3.2-14.9,1.5-22.3-2.8-32.7-.4-59.8,13.5-84.6,33.4-14.9,17.9-28.9,29.9-42,35.6-13.4,7.1-22.5,2.9-29.9-5-20-11-31-32.1-34.8-61.4-4.5-25.4,1.4-48,19.3-67.4l30.7-25.4c5.3-5.6,12-6.7,20.1-4.2l20.7-5.6c14.8,8.9,33.6,11.9,55.3,10.8,3.9,16.1,11,26,22.9,26.7,16.1,2.4,31.4-5.7,46.5-21.9,12.6-15.9,20-31.7,22.3-47.6,6.6-20.1,5.9-42.9,1.3-66.9-5.3-37.8-16.9-63.9-32.8-81.8-9.5-4.2-19.7-11.3-30.2-20.4-14.1-8.8-26.8-6.3-38.5,3.5,2,5-.4,10.3-6,15.8,2.5,10.8-5.6,14-19.1,13.4-10.2-6.1-20.4-5.9-30.6-2.9-7.3,2.1-15.1-1-22.9-6.3-31.8-9.9-50.1-32.4-61.7-61.2-9.8-26.8-11.2-50.4,0-69.4,6.8-10.3,17.2-16.9,29.2-21.6,8.9,0,15.1,5.4,19.3,14.8,22.1,26.5,56,45,108.3,51.1,3.9.4,7.8-.4,11-2.7,7.7-5.2,13.8-7.1,17.3-3.9-9.8,18.3-5.4,32.7,9.5,44.1,3.4,17.5,21.6,18.3,45.8,12.7"
          />
          <line
            id="pelvis32"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            x1="495.74"
            y1="577.25"
            x2="592.54"
            y2="525.05"
          />
          <path
            id="pelvis31"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M469.34,495.25c21.1-9.2,41.5-11.9,61.2-6.1"
          />
          <line
            id="pelvis30"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            x1="465.84"
            y1="473.55"
            x2="487.54"
            y2="469.45"
          />
          <path
            id="pelvis29"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M575.34,446.45c-20.4,9.8-49,14.8-79.6,18.6-14.8-6.7-21.9-15.5-20.9-26.4,11.4-10.1,16.9-20.5,12.7-31.8"
          />
          <path
            id="pelvis28"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M477.14,295.65c-.8-7.5-3.6-15.2-7.8-23.3-7.1-8.7-8.2-15.1-2-18.6,12.8-9.2,30.4-11.9,51.8-8.8,8.9.6,15.2,3.6,19.4,8.8,18.1,2.9,36.4,5.7,44.3,18.7,4.7,9.4,12.7,12.6,23.9,9.5,9.2-.3,14.2,4.3,17.5,11.2,2.7,5,10.5,6.6,20.9,6.4"
          />
          <path
            id="pelvis27"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M657.44,389.65c-12,4-20.7,13.1-24.4,28.9-18.3,5.3-33.5,15.9-46.8,30.2"
          />
          <path
            id="pelvis26"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M667.14,541.65c-11.4-7.8-18-17-15.2-28.9,3.9-13,9.4-17,15.2-18.7,7-5.2,15.8-3.2,25.1,1.5"
          />
          <path
            id="pelvis25"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M692.34,421.85c-.6,6.6-2.4,11.3-5.3,14.5-2.4,2.5-4.5,5.4-5.7,8.7-3.1,8.7-1.8,16.9,5.6,24.4"
          />
          <path
            id="pelvis23"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M681.94,274.85c-11.7-16.1-20.2-31-2.1-36"
          />
          <path
            id="pelvis22"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M462.14,202.55c13.1,5.4,26.8,8.5,42,5.3"
          />
          <path
            id="pelvis21"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M559.44,177.35c-21.4-4.5-39-13.4-53.1-26.7-11-9.9-26.8-16.1-46.1-19.7"
          />
          <path
            id="pelvis20"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M666.34,143.65c-1.3,14-8.4,18-19,16.5-4.2-.6-8.4.6-11.6,3.2-16.6,13.3-15.1,26.7,4.2,40.2,11.9,13.7,24.4,14.7,37.3,5.7"
          />
          <path
            id="pelvis19"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M526.64,423.25c-10.3-10.2-9.8-20.4,2-30.7"
          />
          <path
            id="pelvis18"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M506.24,316.75c-3.9-10.5-3.6-19,3.5-24.2"
          />
          <path
            id="pelvis17"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M489.34,355.55l20.8-3.4c.8-.1,1.1-1.3.4-1.8-3.9-3.2-11-4.2-21.2-2.9"
          />
          <path
            id="pelvis16"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M526.34,354.05h15.9c1.4,0,2.1-1.7,1.1-2.7-3.4-3.4-8.1-5-14.7-4.7"
          />
          <path
            id="pelvis15"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M557.14,354.05h9.1c1.4,0,2.2-1.7,1.3-2.8-3.4-3.6-9.2-4.9-17-4.7"
          />
          <path
            id="pelvis14"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M577.84,358.75c4.7-.7,8.9-1.7,12.1-2.9,2.2-.8,2.2-4,0-4.9-3.4-1.3-7.4-1.8-12.1-1.3"
          />
          <path
            id="pelvis13"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M508.44,383.05c6.1.4,11.6,2,15.5,6.6"
          />
          <path
            id="pelvis12"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M538.34,384.75c10.5,4.6,20.9,2.5,31-7.1,4.3,2.4,11.4,2.7,21.2.8l6.1-3.8c8,2.9,15.2,3.1,20.5-2.2,9.2.4,18.1.8,23.2-2.4"
          />
          <path
            id="pelvis11"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M531.94,321.15c10.2-4,20.5-2.2,31,2.9,8.9-8,20.1-5.9,32.3,0,10.5-6,20.2-5.9,29.2,1.5l17.7-2.2"
          />
          <path
            id="pelvis10"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M540.44,386.35c-.6,5.9,2.4,12.6,7.1,19.8,2.5,3.6,5.4,7,8.9,9.8,11.6,9.4,20.8,18.4,26.2,26.7"
          />
          <path
            id="pelvis9"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M591.64,435.45c-19.5-14-31-30.7-31.4-51.2"
          />
          <path
            id="pelvis8"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M538.34,324.25c-5.6-9.9-9.8-20-3.4-30.7,5-7.1,13.8-13,23-18.6"
          />
          <path
            id="pelvis7"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M564.54,327.65c-10.3-14.1-13-28.5-3.1-42.9"
          />
          <path
            id="pelvis6"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M616.74,355.55c10.1,4.9,19.7,7,28.5,5.3"
          />
          <path
            id="pelvis5"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M620.04,340.75c7.1-4,14.4-6.8,22.1-6.4"
          />
          <path
            id="pelvis4"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M650.74,361.45c3.1,0,6.3-1.3,9.4-4.5"
          />
          <path
            id="pelvis3"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M649.14,333.05c5.2.6,9.8,1.1,10.9,2.4"
          />
          <path
            id="pelvis2"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M647.74,370.25c3.8.8,7.5,0,11.2-2.4"
          />
          <path
            id="pelvis1"
            class="Abdomen_in_linksseitenlage_pa_liegend1104_cls6"
            d="M646.64,324.25c5.2-.6,10.2-.1,15.1,1.5"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="symphysehighlight"
          class="Symphyse1104Fill"
          style={{
            fill: props.colors.Symphyse1104Fill,
            stroke: props.colors.Symphyse1104Fill,
          }}
          d="M682.64,349.75v-8.58c0-3.17,2.5-5.72,5.7-5.72h24.9c3.1,0,5.7,2.55,5.7,5.72v7.05c0,3.06-2.3,5.62-5.3,5.72l-24.9,1.63c-3.4.1-6.1-2.55-6.1-5.82h0Z"
        />
        <path
          id="diaphragmrighthighlight"
          class="Zwerchfell_freieLuft1104Fill"
          style={{
            fill: props.colors.Zwerchfell_freieLuft1104Fill,
            stroke: props.colors.Zwerchfell_freieLuft1104Fill,
          }}
          d="M304.74,546.51c-14.77,10.61-52.69-11.42-75.41-32.32-25.09-23.08-46.14-58.13-36.82-67.24,4.29-4.2,13.74-1.65,25.19,1.42,10.12,2.72,27.8,8.92,66.43,44.34,22.05,20.22,28.39,29.3,27.03,40.32-.37,2.98-1.2,9.73-6.42,13.48Z"
        />
        <path
          id="diaphragmaticcoupolahighlight2"
          class="Zwerchfellkuppen1104Fill"
          style={{
            fill: props.colors.Zwerchfellkuppen1104Fill,
            stroke: props.colors.Zwerchfellkuppen1104Fill,
          }}
          d="M97.54,592c.32-1.81-2.87-2.94-8.36-7.01-15-11.11-21.92-25.42-27.51-36.94-19.07-39.39-6.78-83.64-5.93-86.56,4.13-14.14,13.65-36.78,29.39-58.52,5.83-8.06,9.47-11.78,8.36-16.72-4.41-19.58-74.68-19.61-82.52-15.1-.49.28-3.4,2.11-4.85,1.08-.89-.64-1-2.19-.81-2.97.49-1.96,4.13-3.97,26.46-4.11,16.52-.11,24.99-.11,35.18,2.67,4.38,1.2,8.26,2.81,16.02,6.03,18.49,7.67,19.76,10.75,19.86,12.5.07,1.29-1.67,3.66-5.16,8.36-3.25,4.37-5.7,7.33-6.83,8.74-1.06,1.32-3.46,4.41-9.22,14.56-10.09,17.78-16.8,33.11-18.6,37.27-4.3,9.94-6.47,15.02-7.37,20.27-.51,3.02-.99,7.27-.3,22.58.54,11.97.81,17.95,1.46,22.14,3.04,19.46,11.67,33.44,15.06,38.84,5.83,9.26,11.63,15.3,20.24,24.27,4.26,4.44,6.39,6.66,10,9.16,2.96,2.05,4.24,2.46,4.15,3.1-.34,2.52-20.56,2.33-39.2.4-28.2-2.91-46.6-9.13-47-13.82-.02-.2-.2-2.28.62-2.63,1.02-.44,2.97,2.05,3.26,2.39,5.91,7.04,72.14,18.11,73.59,10.01Z"
        />
        <path
          id="diaphragmaticcoupolahighlight1"
          class="Zwerchfellkuppen1104Fill"
          style={{
            fill: props.colors.Zwerchfellkuppen1104Fill,
            stroke: props.colors.Zwerchfellkuppen1104Fill,
          }}
          d="M92.34,310.25c2.39-6-10.71-12.21-27.02-32.4-17.82-22.06-23.57-41.11-25.29-47.5-.31-1.16-1.68-6.28-2.76-13.36-1.49-9.8-5.28-36.7,5.68-64.31,7.8-19.65,18.88-29.12,15.44-33.16-1.81-2.13-4.9.5-20.17,2.6-10.61,1.46-21,4.24-31.6,5.82-.79.12-3.14.46-4.81,2.27-1.11,1.2-2.27,3.44-1.62,4.45,1.48,2.29,11.51-2.89,26.7-7.28,14.24-4.12,21.56-6.23,24.27-3.24,4.26,4.71-7.55,16.88-14.56,36.4-11.57,32.2-2.39,62.88-.25,70.02,7.34,24.51,22.48,44.04,28.97,51.73,12.31,14.61,22.42,21.04,20.22,26.7-.68,1.76-2.57,3.46-40.76,6.74-23.3,2-34.96,3.01-35.28,2.96-1.98-.25-8.48-1.19-9.3.81-.44,1.07.85,2.74,2.02,3.64,2.54,1.95,5.99,1.37,7,1.2,10.2-1.68,20.62-1.97,30.76-4,13.6-2.73,14-.76,34.59-4.36,15.47-2.71,17.14-4.15,17.77-5.74Z"
        />
        <path
          id="sinusphrenico-costalishighlight"
          class="Sinus_phrenico_costalis1104Fill"
          style={{
            fill: props.colors.Sinus_phrenico_costalis1104Fill,
            stroke: props.colors.Sinus_phrenico_costalis1104Fill,
          }}
          d="M72.95,562.78c1.37.2,1.79,1.48,4.09,4.96,0,0,4.4,6.64,13.48,15.37,9.46,9.08,17.18,10.3,16.61,12.45-.42,1.58-7.7,1.49-22.27,1.31-2.63-.03-7.33-.38-16.72-1.08-6.41-.48-7.23-.61-8.04-1.39-6.18-5.97,6.21-32.59,12.85-31.61Z"
        />
      </g>
    </svg>
  );
}

export default AbdomenInLinksseitenlagePaLiegend1104;
