import style from "./style.scss";

function RechteFusswurzelDplSitzend1452(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 235.35 320.7">
  
  <g id="foot">
    <g id="metatarsus">
      <path id="metatarsus_1" data-name="metatarsus 1" class="fußwurzel1452_dp_links_cls4" d="M59.04.75c3.8,7.6,8.7,14.8,14.5,21.6,13.5,5,14.8,15.6-1.2,34.2-21-7-34.2.7-47.1,9.1-18,10.8-22.5.7-20.5-19,.3-12.5-.1-24.8-1-37.1"/>
      <path id="metatarsus_2" data-name="metatarsus 2" class="fußwurzel1452_dp_links_cls4" d="M115.24,8.25c1.5,14,3.2,28.2,4.9,42.3,6.8,5.6,10.2,13.3,6.8,25.5,3.5,17.8-2.4,27.4-12.4,33.3-4.9-17.6-19.1-21.1-35.8-20.7,2-11.9-.9-21.1-6-29,2-4,7.9-11.1,11.6-21.6,1.6-7.8,2.6-18.7,3.2-31.6"/>
      <path id="metatarsus_3" data-name="metatarsus 3" class="fußwurzel1452_dp_links_cls4" d="M159.04,16.95c1.4,13,3,26.5,4.6,40.1,9.9,30.7,5,44-6,50.2-3.7-21.5-14.7-20.2-26.9-16-3.3-10-1.5-24,2.4-39.8,1.6-8.8,2.3-22.7,2.6-39.7"/>
      <path id="metatarsus_4" data-name="metatarsus 4" class="fußwurzel1452_dp_links_cls4" d="M190.94,25.55l6.8,67.1c3.9,10,6.2,20.3,1.2,32-1.3,10.5-4.2,16.9-8,20.7-4.7-15.5-16.3-23.3-32.5-26,9.2-20.8,12.3-45.2,10.8-72.3-.6-8.2-1-16.5-1.1-24.7"/>
      <path id="metatarsus_5" data-name="metatarsus 5" class="fußwurzel1452_dp_links_cls4" d="M224.24,34.45c-.1,5.8,0,11.8.4,17.8l6,81.8c9.2,32.9,1.7,50.2-15.7,58.4-5.9,10.1-10.3,10.6-12.4-2.6,1.5-11.3-3.7-26.3-10.8-42.4,7-15,11.7-29.8,8.5-43.7l.8-58,2.7-18.2"/>
    </g>
    <g id="tarsus">
      <path id="tarsus_5" data-name="tarsus 5" class="fußwurzel1452_dp_links_cls4" d="M30.94,158.75h0c-20.2,11.3-24.6-4.8-22.1-32.9-.7-28.2,5.9-46.9,16.5-60.1,30.1-13.3,55.4-19.3,50.3,21.6-16.2,19.8-26.1,40.3-23.3,61.8l-21.4,9.6h0Z"/>
      <path id="tarsus_4" data-name="tarsus 4" class="fußwurzel1452_dp_links_cls4" d="M58.64,150.05h0c-7.1-14.4-2.8-33.8,15.2-59.3,19.7-3.8,32.7,1.1,37.4,16.4,2.6,17.9-2.5,35.5-11.6,52.8-10.4-11.8-24.7-13.7-41-9.9h0Z"/>
      <path id="tarsus_3" data-name="tarsus 3" class="fußwurzel1452_dp_links_cls4" d="M104.44,160.45h0c8.3-17.3,13.7-35.4,15.2-54.6,18.8-21.4,30.9-19.2,37.8,1.3,2,33.2-.5,59.5-13.2,70.1-5.5,6-12,8.9-19.3,9.6-1.9-17.4-11-22.3-20.5-26.4h0Z"/>
      <path id="tarsus_2" data-name="tarsus 2" class="fußwurzel1452_dp_links_cls4" d="M174.84,230.55h0c-41.4,17.4-54.3,4.2-65-11.3,9.4-10.2,14.1-19.9,14.5-29,29.4-8.7,37.6-30.9,34.2-60.6,14.7-4.1,26.5,1.9,32.5,28.6,9.3,11.2,9.3,24,6.8,37.2-6.6,13.5-14.4,24.9-23,35.1h0Z"/>
      <path id="tarsus_1" data-name="tarsus 1" class="fußwurzel1452_dp_links_cls4" d="M11.94,238.35h0c-11-5-14.6-16.6-7.6-37.6,2.4-22.1,8.5-37.5,22.1-39.4,29.7-14.8,54.4-16.8,71.1.9,32.1,8.4,26,30.4,8.8,56.2-33.8-31.4-64.8-17.4-94.4,19.9h0Z"/>
    </g>
    <g id="ankle_bone" data-name="ankle bone">
      <path id="ankle_bone_3" data-name="ankle bone 3" class="fußwurzel1452_dp_links_cls4" d="M173.54,319.85c-1.8-12.1-12-21.7-31.6-28.3-8.2-4.9-19.2-11.6-31.4-19-8-5.3-10.2-17.4-8-34.6-.7-35.8-23.9-37.5-52.6-31.2-24.3,10.8-40.3,36.3-37,65.3h-.1s0,.1,0,.1c13.9,8.5,20.8,23,18.9,44.5.1.4.3.9.4,1.3"/>
      <path id="ankle_bone_2" data-name="ankle bone 2" class="fußwurzel1452_dp_links_cls4" d="M167.34,319.85c-1.2-5.2-4-10.5-8.4-15.9l-1.6-5.2-37.3-20.2c-8.4,3.5-21.1,4-35.3,3.4-20.4-11.5-32.2-4.1-38.2,16.4-2.5,8-2.5,14.5.3,19.6"/>
      <path id="ankle_bone_1" data-name="ankle bone 1" class="fußwurzel1452_dp_links_cls4" d="M25.64,226.55c38.6-19.7,63.9-17.5,75.2,7.8"/>
    </g>
    <path id="calcaneus" class="fußwurzel1452_dp_links_cls4" d="M191.14,319.95c-2.4-18.1-7.7-34.3-15.8-48.8,7.6-15.5,7.3-28.1-.5-37.9-38.3,18.5-57.5-.7-76.8-19.7,6.6,13.5,4.1,42.1,10.5,51.5,7.9,11.8,10.5,4.6,36.1,24.4,18.5,6.6,29.3,16.7,31,30.4"/>
  </g>
  <g id="overlays">
    <path id="calcaneus_overlay" data-name="calcaneus overlay" class="calcaneus1452fill" style= {{
              fill: props.colors.calcaneus1452fill,
              stroke: props.colors.calcaneus1452fill,
              opacity:props.colors.calcaneus1452fill,
            }} d="M191.14,319.95c-2.4-18.1-7.7-34.3-15.8-48.8,7.6-15.5,7.3-28.1-.5-37.9-38.3,18.5-57.5-.7-76.8-19.7,6.6,13.5,4.1,42.1,10.5,51.5,7.9,11.8,10.5,4.6,36.1,24.4,18.5,6.6,29.3,16.7,31,30.4"/>
    <path id="talus_overlay" data-name="talus overlay" class="talus1452fill" style= {{
              fill: props.colors.talus1452fill,
              stroke: props.colors.talus1452fill,
              opacity:props.colors.talus1452fill,
            }} d="M173.54,319.85c-1.84-9.88-8.07-17.47-18.99-22.61-29.83-17.82-58.08-20.55-52.43-55.4.78-9.05-.82-18.09-4.07-27.13-8.57-9.14-18.93-12.22-30.97-9.57-12.02-.95-22.85,1.94-31.84,10.74-16.19,15.36-24.92,33.58-22.3,56.17,13.88,7.9,20.35,23.14,19.2,45.9l141.4,1.9Z"/>
    <path id="Carpus_overlay" data-name="Carpus overlay" class="fw1452fill" style= {{
              fill: props.colors.fw1452fill,
              stroke: props.colors.fw1452fill,
              opacity:props.colors.fw1452fill,
            }} d="M197.84,195.45c3.91-14.22.7-26.34-6.8-37.2-4.99-23.05-15.83-32.57-32.5-28.6l-.9-22.4c-4.8-12.36-10.72-18.61-18.23-16.17-7.29,2.32-15.78,9.16-24.87,18.27-5.63-15.13-16.66-22.71-35.16-19.83l-4.51,1.04.77-3.21c1.12-13.82-1.12-23.45-6.65-28.97-8.89-5.09-24.79-.83-43.65,7.37-13.02,15.79-16.92,37.18-16.59,61.16-2.24,26.58,2.65,35.95,11.62,35.48-9.23,7.5-14.79,20.31-16.26,39.08-6.48,19.77-3.44,31.72,7.84,36.88,12.08-16.82,25.51-28.8,40.79-34.13,22.68-8.27,38.75,1.34,53.61,14.23l3.5.8c9.74,15.63,20.42,18.88,31.28,19.55,14.44-1.7,25.71-4.44,33.72-8.25,9.51-11.29,17.12-23,23-35.1Z"/>
  </g>
</svg>
  );
}

export default RechteFusswurzelDplSitzend1452;
