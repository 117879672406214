import style from "./style.scss";

function Rechte4ZaheDplSitzendLinks1416(props) {
  return (
    <svg
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 106.63 382.12"
    >
      <g id="forth_toe" data-name="forth toe">
        <path
          id="forth_toe_1"
          data-name="forth toe 1"
          class="a1416_Rechte_4Zehe_dpl_sitzend_cls_2"
          style={{
            fill: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_2,
            stroke: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_2,
          }}
          d="M54.67,24.33h.02c10.99-13.43,19.53-13.43,23.2,2.44,17.09,10.99,14.65,15.87,1.22,17.09-2.44,6.1-2.44,10.99,3.66,15.87,6.1,6.1,4.88,10.99-2.44,15.87h-32.98c-6.1-4.88-10.99-10.99-1.22-14.65,7.33-3.66,8.55-8.55,3.66-15.87-7.33-6.1-4.88-12.21,4.88-20.76h0Z"
        />
        <path
          id="forth_toe2"
          data-name="forth toe2"
          class="a1416_Rechte_4Zehe_dpl_sitzend_cls_2"
          style={{
            fill: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_2,
            stroke: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_2,
          }}
          d="M38.82,97.59c-1.22-13.43,0-23.2,13.43-15.87,4.88-2.44,9.77-2.44,17.09-1.22,19.53-6.1,18.31,2.44,3.66,20.76-4.88,14.65-6.1,25.64-3.66,34.19,8.55,12.21,4.88,15.87-4.88,15.87l-23.2-3.66c-10.99,3.66-17.09,0-9.77-18.31,7.33-6.1,10.99-15.87,7.33-31.74h0s0-.02,0-.02Z"
        />
        <path
          id="forth_toe_3"
          data-name="forth toe 3"
          class="a1416_Rechte_4Zehe_dpl_sitzend_cls_2"
          style={{
            fill: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_2,
            stroke: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_2,
          }}
          d="M27.83,175.73h0c-2.44-15.87,2.44-25.64,17.09-24.42,3.66,3.66,7.33,3.66,10.99,0,14.65,2.44,23.2,9.77,14.65,26.86-15.87,37.85-13.43,63.49-4.88,84.24,8.55,21.98,4.88,26.86-7.33,17.09-10.99-15.87-21.98-17.09-31.74-6.1-21.98,9.77-23.2,2.44-8.55-17.09,9.77-14.65,14.65-41.51,9.77-80.58h0Z"
        />
        <path
          id="forth_toe_4"
          data-name="forth toe 4"
          class="a1416_Rechte_4Zehe_dpl_sitzend_cls_1"
          style={{
            fill: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_1,
            stroke: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_1,
          }}
          d="M28.17,381.62c-.11-7.18-.22-14.38-.34-21.55-20.76-19.53-19.53-32.96-15.87-45.17,2.44-9.77,3.66-19.53,4.88-28.08h0c15.87-17.09,31.74-18.31,47.62-1.22,6.1,4.88,7.33,12.21,4.88,20.76,9.77,10.99,7.33,26.86-3.66,43.95-1.89,9.79-3.25,19.84-4.17,30.11"
        />
        <path
          id="forth_toe_5"
          data-name="forth toe 5"
          class="a1416_Rechte_4Zehe_dpl_sitzend_cls_1"
          style={{
            fill: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_1,
            stroke: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_1,
          }}
          d="M19.28,288.05c10.99,8.55,21.98,14.65,34.19,8.55l14.65-7.33"
        />
        <path
          id="outline"
          class="a1416_Rechte_4Zehe_dpl_sitzend_cls_3"
          style={{
            fill: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_3,
            stroke: props.colors.a1416_Rechte_4Zehe_dpl_sitzend_cls_3,
          }}
          d="M.5,203.3c13.02-1.33,18.76-8.15,18-19.92-4.3-30.76,1.04-61.62,13-92.59-2.56-29.3,2.64-54.01,14.96-74.5.68-1.13,1.43-2.22,2.24-3.22C58.41,1.03,68.52-2.63,79.08,3.26c8.92,4.97,15.04,15.05,16.59,26.49,3.14,23.32,2.13,47.64-2.17,72.77,1.9,24.7.06,49.89-5.13,75.54-.69,3.45-.27,7.04,1.35,10.15,3.31,6.36,9.41,12.1,16.41,13.05"
        />
      </g>
      <g id="overlays">
        <g id="phalangen_overlays" data-name="phalangen overlays">
          <path
            id="phalangen_overlay_1"
            data-name="phalangen overlay 1"
            class="a1416_phalangenFill"
            style={{
              fill: props.colors.a1416_phalangenFill,
              stroke: props.colors.a1416_phalangenFill,
            }}
            d="M54.67,24.33h.02c10.99-13.43,19.53-13.43,23.2,2.44,17.09,10.99,14.65,15.87,1.22,17.09-2.44,6.1-2.44,10.99,3.66,15.87,6.1,6.1,4.88,10.99-2.44,15.87h-32.98c-6.1-4.88-10.99-10.99-1.22-14.65,7.33-3.66,8.55-8.55,3.66-15.87-7.33-6.1-4.88-12.21,4.88-20.76h0Z"
          />
          <path
            id="phalangen_overlay_2"
            data-name="phalangen overlay 2"
            class="a1416_phalangenFill"
            style={{
              fill: props.colors.a1416_phalangenFill,
              stroke: props.colors.a1416_phalangenFill,
            }}
            d="M38.82,97.59c-1.22-13.43,0-23.2,13.43-15.87,4.88-2.44,9.77-2.44,17.09-1.22,19.53-6.1,18.31,2.44,3.66,20.76-4.88,14.65-6.1,25.64-3.66,34.19,8.55,12.21,4.88,15.87-4.88,15.87l-23.2-3.66c-10.99,3.66-17.09,0-9.77-18.31,7.33-6.1,10.99-15.87,7.33-31.74h0s0-.02,0-.02Z"
          />
          <path
            id="phalangen_overlay_3"
            data-name="phalangen overlay 3"
            class="a1416_phalangenFill"
            style={{
              fill: props.colors.a1416_phalangenFill,
              stroke: props.colors.a1416_phalangenFill,
            }}
            d="M27.83,175.73h0c-2.44-15.87,2.44-25.64,17.09-24.42,3.66,3.66,7.33,3.66,10.99,0,14.65,2.44,23.2,9.77,14.65,26.86-15.87,37.85-13.43,63.49-4.88,84.24,8.55,21.98,4.88,26.86-7.33,17.09-10.99-15.87-21.98-17.09-31.74-6.1-21.98,9.77-23.2,2.44-8.55-17.09,9.77-14.65,14.65-41.51,9.77-80.58h0Z"
          />
        </g>
        <g id="joint_overlays" data-name="joint overlays">
          <ellipse
            id="joint_overlay_1"
            data-name="joint overlay 1"
            class="a1416_gelenkeFill"
            style={{
              fill: props.colors.a1416_gelenkeFill,
              stroke: props.colors.a1416_gelenkeFill,
            }}
            cx="62.55"
            cy="75.3"
            rx="23.74"
            ry="10.43"
          />
          <ellipse
            id="joint_overlay_2"
            data-name="joint overlay 2"
            class="a1416_gelenkeFill"
            style={{
              fill: props.colors.a1416_gelenkeFill,
              stroke: props.colors.a1416_gelenkeFill,
            }}
            cx="50.45"
            cy="151.5"
            rx="27.19"
            ry="9.42"
          />
          <ellipse
            id="joint_overlay_3"
            data-name="joint overlay 3"
            class="a1416_gelenkeFill"
            style={{
              fill: props.colors.a1416_gelenkeFill,
              stroke: props.colors.a1416_gelenkeFill,
            }}
            a
            cx="40.58"
            cy="277.82"
            rx="32.81"
            ry="14.52"
          />
        </g>
      </g>
    </svg>
  );
}

export default Rechte4ZaheDplSitzendLinks1416;
