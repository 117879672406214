import style from "./style.scss";

function Linker2FingerDpSitzend1315(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 183.9 474.2"
    >
      <g id="secondFinger">
        <g id="background">
          <path
            id="background_white"
            data-name="background white"
            class="linker_finger2_dp_sitzend1315_cls8"
            d="M183.9,470.6L0,473.8l17.4-230.7c3.4,39.4,5.5,69.6,16.2,76.2,9.1,5.8,22.6-1.8,23.8-23.6-.2-36.8,1.5-55.2,6-83.2,5.9-29.6,9.1-59.5,10-89.6l3.3-98.1C78.8,4,90.7-1.2,108.4,1c19.7.2,31.5,11.5,36.9,31.9,6,24.7,7.6,88.3,9.7,147.6.6,43.5-3.9,91.1-7.9,140.9-15.4,89.9,19.2,137.9,30.8,139.8l6.1,9.4h0Z"
          />
        </g>
        <g id="secondFinger1">
          <path
            id="fingerpart14"
            class="linker_finger2_dp_sitzend1315_cls2"
            d="M49.3,472.2c-.3-15.5,1.4-26.5,6.3-39.7,2.8-7.5,9.6-13,17.6-13.9,5.1-.6,10.6,0,16.1,1,11.9,1.7,20.4,10,24.6,27.3,1.8,11.3,3.4,16.6,3.3,23.6"
          />
          <path
            id="fingerpart15"
            class="linker_finger2_dp_sitzend1315_cls2"
            d="M106.1,414.1c-13.3-6.5-28.9-5.7-45.9,0-4.6,2.1-8,1.6-7.9-6.7-3.9-6.4-4.2-13.5,0-21.6,15.8-18.4,22.8-51.3,24.6-92.4.6-10,1-18.5,1.4-25.3,0-1.2.3-2.3.6-3.4,1.9-7.3,1.9-14.1-.5-20.2-2.2-3.5-1.4-6.6.9-9.5,1.1-1.4,1.5-3.1,1.3-4.8-.5-4.6.3-9.1,2.5-13.6.6-1.3,1.7-2.4,3.1-3,6-2.7,13.9-1,22.5,2.5,2.7,1.1,5.7,1.5,8.6,1.3l11.1-1.1c4.9.8,8,5.4,6.1,21.5-.2,1.6-.2,3.2,0,4.8.5,4.9-.4,8.3-2.7,10.3-1.1,1-2,2.2-2.5,3.7-3.6,10.9-4.7,21.1-4,30.9,0,.7,0,1.4,0,2.1-6.3,39.8-8.7,75.1.2,97.7,7,10.3,9.2,19.6,0,26.7-4.2,6.5-10.1,8.1-19.4,0h0Z"
          />
          <path
            id="fingerpart13"
            class="linker_finger2_dp_sitzend1315_cls2"
            d="M140.6,194.7c.8,1.4,1,3,.8,4.5-.5,4.4-2.1,7.9-6.2,8.9-6.3-.3-13.3,1.1-20.8,3.6-1.3.4-2.6.4-3.9,0-9.2-3.1-17.9-5.6-24.5-5.1-1.8.1-3.5-.8-4.4-2.3-3.1-5.6-3.6-10.3-.3-13.8,1.5-1.6,2.5-3.7,3.1-5.8,5.6-21.6,8.9-42.4,9-62.1.3-2.2,0-4.5-1-6.5-3-6.2-2.7-10.5,1.1-15.3,1.2-1.5,1.7-3.3,1.5-5.2-.6-7.7,4.6-10.7,12.8-6.7,4.5,1.1,8.6,1.9,12.6.6,10.1-2,12.5.6,13.2,8.1.1,1.2.5,2.3,1.1,3.4,1,1.8,1.4,4.5,1.8,8,.3,2.6-.3,5.4-2,7.4-5.9,7.4-6.8,19.3-3.1,35.5.7,2.9,1.1,5.8,1.2,8.8.6,13.2,2.9,24.9,7.9,34.1h0s0,0,0,0Z"
          />
          <path
            id="fingerpart12"
            class="linker_finger2_dp_sitzend1315_cls2"
            d="M133.7,83c-3.1,4.8-5.5.3-15,2.3-5.2,1.3-10.3.8-15.4-1.7-5.5.3-9.4-1.1-9.9-5.9-3.4-2.8-2.8-5.7.5-8.5,9.7-8.6,5.8-28.2,4.2-27.5-3.8-6.5-3.8-14.5,0-23.9,1.8-2.8,12.2-4.9,18.3-.5,6.2,4.8,9.9,9.9,9.5,15.5.3,4.2.3,8-.9,10.2-.5.9-.7,2-.7,3,0,12.1,4.9,21.3,12.6,29.7,1,3,.1,5.5-3.1,7.3h0Z"
          />
          <path
            id="fingerpart11"
            class="linker_finger2_dp_sitzend1315_cls4"
            d="M111.9,470.6c.5-5.3-2.3-10-6.3-14.5-4.4-3.7-5.8-10.2-3.5-20"
          />
          <path
            id="fingerpart10"
            class="linker_finger2_dp_sitzend1315_cls4"
            d="M64.5,412.8c5.7.2,10.8,1.4,15.7,3.2,7.9,2.9,16.4,3.7,24.7,2.4,2-.3,3.7-.7,5.2-1.2"
          />
          <path
            id="fingerpart9"
            class="linker_finger2_dp_sitzend1315_cls4"
            d="M38.5,473.8c1.6-11.9,6.2-16.5,11.8-18.6"
          />
          <path
            id="fingerpart8"
            class="linker_finger2_dp_sitzend1315_cls4"
            d="M116.1,249.6c-5.3,2.5-7.9,10.9-7.7,24.9,0,1.1.2,2.2.6,3.3,2.8,8.3,2.2,20.8.2,34.8l4.1,45.2c2.4,17.7,6,27.2,10.5,31.2"
          />
          <path
            id="fingerpart7"
            class="linker_finger2_dp_sitzend1315_cls4"
            d="M97.3,254.4c-1.4,3.4-2.2,9-2.2,17.4s-.6,6.2-2,8.9c-3.1,6.1-4.4,15.9-4.8,27.7,0,.9-.2,1.7-.6,2.5-3,6.5-5.2,13.5-6.4,20.8-1,11-7.4,23.6-15.6,36.8"
          />
          <path
            id="fingerpart6"
            class="linker_finger2_dp_sitzend1315_cls4"
            d="M129.4,223.5c-.1,10.4,1.4,15.6,4.6,15.8"
          />
          <path
            id="fingerpart5"
            class="linker_finger2_dp_sitzend1315_cls4"
            d="M87.5,221c-1.5,6.7-3.6,10.6-6.8,10.4"
          />
          <path
            id="fingerpart4"
            class="linker_finger2_dp_sitzend1315_cls4"
            d="M88.4,200.7c7,.3,13.3,1.1,17.4,3.6,2.1,1.4,4.3,2,6.6,1.9,1.7,0,3.4-.5,4.9-1.2,5.5-2.5,10.8-3.2,15.9-2"
          />
          <path
            id="fingerpart3"
            class="linker_finger2_dp_sitzend1315_cls4"
            d="M119.3,108.2c-4.3,5.6-5.5,14.2-3.9,25.5,2.9,7.4,5,14.5,5.6,20.9,3.3,12.5,7.2,23.8,11.6,33.6,2.2,3.7,3.7,8.4,4.6,13.6"
          />
          <path
            id="fingerpart2"
            class="linker_finger2_dp_sitzend1315_cls4"
            d="M85.9,194.3c8.4-16.4,13.7-33.8,16.8-51.9.5-2.9.7-5.8.6-8.6-.3-7.3.2-15,1.1-22.9-.4-2.2-1-4.3-1.9-6.3"
          />
          <path
            id="fingerpart1"
            class="linker_finger2_dp_sitzend1315_cls4"
            d="M99.8,68.3c4.2.5,6.5-4.6,7.2-14.6.2-3.9.2-7.8-.2-11.7-.5-6.7,1.3-9.6,5.3-9,3.4,0,4.8,4.4,4.9,11.3,0,1.9.2,3.8.6,5.7,1.3,7.1,4.8,13.4,9.1,19.2,3.5,2.8,5.3,5.4,4.9,7.8"
          />
          <g id="outline">
            <path
              id="outlinepart2"
              class="linker_finger2_dp_sitzend1315_cls1"
              d="M18.5,255c5,52.2,9.7,65,20,66.2,11,2.3,17.5-14,18.6-35.9.2-36.2,1.7-44.9,6.2-72.9,5.9-29.6,9.1-59.5,10-89.6l3.3-98.1C78.8,4,90.7-1.2,108.4,1c19.7.2,31.5,11.5,36.9,31.9,6,24.7,7.6,88.3,9.7,147.6,0,1.2,0,2.3,0,3.5.2,33.3-2.3,66.7-4.8,99.9-1.4,18.2-3,36.4-4.8,54.5-1.8,17.4-5.3,34.2-2.3,51.7,2.8,16.3,9.3,31.9,16.3,46.6,2.8,5.8,10,24.5,18.5,24.5"
            />
            <path
              id="outlinepart1"
              class="linker_finger2_dp_sitzend1315_cls4"
              d="M17.4,243.1c.2,2,.4,4,.5,6"
            />
          </g>
          <path
            id="secondFingerpart2"
            class="linker_finger2_dp_sitzend1315_cls3"
            d="M1,447.1c5.3,3.2,10.5,4.7,15.7,3.9,2-.3,3.6-1.6,4.2-3.5.6-2.1,1.9-3.9,3.8-5.4,1.6-1.2,2.7-2.9,3-4.9,1.2-7.6.8-15.9-.3-24.5-.2-1.6-1-3.1-2.3-4.1l-2.5-1.9c-1.7-1.4-3.1-3.1-4-5.1-5.1-11.6-8.6-26.2-11-42.5"
          />
          <path
            id="secondFingerpart1"
            class="linker_finger2_dp_sitzend1315_cls3"
            d="M24.5,473.8c-4.9-8.8-12.2-15.2-22.6-18.6"
          />
        </g>
      </g>
      <g id="highlights">
        <path
          id="highlights2"
          class="fullfinger1315fill"
          style={{
            fill: props.colors.fullfinger1315fill,
            stroke: props.colors.fullfinger1315fill,
          }}
          d="M140.6,194.7c.8,1.4,1,3,.8,4.5-.5,4.4-2.1,7.9-6.2,8.9-6.3-.3-13.3,1.1-20.8,3.6-1.3.4-2.6.4-3.9,0-9.2-3.1-17.9-5.6-24.5-5.1-1.8.1-3.5-.8-4.4-2.3-3.1-5.6-3.6-10.3-.3-13.8,1.5-1.6,2.5-3.7,3.1-5.8,5.6-21.6,8.9-42.4,9-62.1.3-2.2,0-4.5-1-6.5-3-6.2-2.7-10.5,1.1-15.3,1.2-1.5,1.7-3.3,1.5-5.2-.6-7.7,4.6-10.7,12.8-6.7,4.5,1.1,8.6,1.9,12.6.6,10.1-2,12.5.6,13.2,8.1.1,1.2.5,2.3,1.1,3.4,1,1.8,1.4,4.5,1.8,8,.3,2.6-.3,5.4-2,7.4-5.9,7.4-6.8,19.3-3.1,35.5.7,2.9,1.1,5.8,1.2,8.8.6,13.2,2.9,24.9,7.9,34.1h0s0,0,0,0Z"
        />
        <path
          id="highlights1"
          class="fullfinger1315fill"
          style={{
            fill: props.colors.fullfinger1315fill,
            stroke: props.colors.fullfinger1315fill,
          }}
          d="M106.1,414.8c-13.3-6.5-28.9-5.7-45.9,0-4.6,2.1-8,1.6-7.9-6.7-3.9-6.4-4.2-13.5,0-21.6,15.8-18.4,22.8-51.3,24.6-92.4.6-10,1-18.5,1.4-25.3,0-1.2.3-2.3.6-3.4,1.9-7.3,1.9-14.1-.5-20.2-2.2-3.5-1.4-6.6.9-9.5,1.1-1.4,1.5-3.1,1.3-4.8-.5-4.6.3-9.1,2.5-13.6.6-1.3,1.7-2.4,3.1-3,6-2.7,13.9-1,22.5,2.5,2.7,1.1,5.7,1.5,8.6,1.3l11.1-1.1c4.9.8,8,5.4,6.1,21.5-.2,1.6-.2,3.2,0,4.8.5,4.9-.4,8.3-2.7,10.3-1.1,1-2,2.2-2.5,3.7-3.6,10.9-4.7,21.1-4,30.9,0,.7,0,1.4,0,2.1-6.3,39.8-8.7,75.1.2,97.7,7,10.3,9.2,19.6,0,26.7-4.2,6.5-10.1,8.1-19.4,0h0Z"
        />
        <path
          id="highlights5"
          class="fullfinger1315fill"
          style={{
            fill: props.colors.fullfinger1315fill,
            stroke: props.colors.fullfinger1315fill,
          }}
          d="M133.7,83c-3.1,4.8-5.5.3-15,2.3-5.2,1.3-10.3.8-15.4-1.7-5.5.3-9.4-1.1-9.9-5.9-3.4-2.8-2.8-5.7.5-8.5,9.7-8.6,5.8-28.2,4.2-27.5-3.8-6.5-3.8-14.5,0-23.9,1.8-2.8,12.2-4.9,18.3-.5,6.2,4.8,9.9,9.9,9.5,15.5.3,4.2.3,8-.9,10.2-.5.9-.7,2-.7,3,0,12.1,4.9,21.3,12.6,29.7,1,3,.1,5.5-3.1,7.3h0Z"
        />
        <path
          id="highlights3"
          class="endglied1315fill"
          style={{
            fill: props.colors.endglied1315fill,
            stroke: props.colors.endglied1315fill,
          }}
          d="M133.7,83c-3.1,4.8-5.5.3-15,2.3-5.2,1.3-10.3.8-15.4-1.7-5.5.3-9.4-1.1-9.9-5.9-3.4-2.8-2.8-5.7.5-8.5,9.7-8.6,5.8-28.2,4.2-27.5-3.8-6.5-3.8-14.5,0-23.9,1.8-2.8,12.2-4.9,18.3-.5,6.2,4.8,9.9,9.9,9.5,15.5.3,4.2.3,8-.9,10.2-.5.9-.7,2-.7,3,0,12.1,4.9,21.3,12.6,29.7,1,3,.1,5.5-3.1,7.3h0Z"
        />
        <ellipse
          id="highlights4"
          class="grundgelenk1315fill"
          style={{
            fill: props.colors.grundgelenk1315fill,
            stroke: props.colors.grundgelenk1315fill,
          }}
          cx="88.4"
          cy="420.3"
          rx="16.2"
          ry="44.8"
          transform="translate(-340.5 442.4) rotate(-81.1)"
        />
      </g>
    </svg>
  );
}

export default Linker2FingerDpSitzend1315;
