import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import AuthorizedElement from "../Utilities/AuthorizedElement";
import { useKeycloak } from "@react-keycloak/web";
import "./Menu.scss";
import { useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import ThemeSelector from "./ThemeSelector/ThemeSelector";
import { MakeGetObject } from "../Utilities/AuthHeader";
import useWindowSize from "../Utilities/Window/windowSize";

const Menu = ({ navToggled, setToggled }) => {
  const { keycloak, initialized } = useKeycloak();
  const [selectedItem, setSelectedItem] = useState(null);
  const [reportCount, setReportCount] = useState(null);
  const { t } = useTranslation(["menu", "common"]);
  const history = useHistory();
  const [curLocation, setCurLocation] = useState("");
  const { width } = useWindowSize();

  const defaultItems = [
    {
      menu: t("journal.name"),
      roles: ["pm_admin", "pm_user", "pm_refphys", "pm_facility", "pm_patient"],
      items: [
        {
          caption: t("journal.patientJournal"),
          id: "patientJournal",
          icon: "fas fa-fw fa-hospital-user",
          route: "/PatientJournal",
          roles: [
            "pm_admin",
            "pm_user",
            "pm_refphys",
            "pm_facility",
            "pm_patient",
          ],
        },
        {
          caption: t("journal.examReferral"),
          id: "examReferral",
          icon: "fas fa-fw fa-notes-medical",
          route: "/ExamReferral",
          roles: ["pm_admin", "pm_user"],
        },
        {
          caption: t("journal.examPlanner"),
          id: "examPlanner",
          icon: "fas fa-fw fa-laptop-medical",
          route: "/ExamPlanner",
          roles: ["pm_admin", "pm_user"],
        },
        {
          caption: t("journal.archive"),
          id: "journalArchive",
          icon: "fas fa-boxes",
          route: "/ExamArchive",
          roles: ["pm_admin", "pm_user"],
        },
        {
          caption: t("journal.examHelper"),
          id: "examHelper",
          icon: "fas fa-fw fa-ambulance",
          route: "/ExamHelper/systemPreparation",
          roles: ["pm_admin", "pm_user"],
        },
      ],
    },
    {
      menu: t("statistics.name"),
      roles: ["pm_admin", "pm_user"],
      items: [
        {
          caption: "Exam Statistics",
          id: "statisticsUser",
          icon: "fas fa-x-ray",
          route: "/ExamStats",
          roles: ["pm_admin", "pm_user"],
        },
      ],
    },
    {
      menu: t("reports.name"),
      roles: ["pm_admin", "pm_user"],
      items: [
        {
          caption: t("reports.generator"),
          id: "reportsGenerator",
          icon: "fas fa-fw fa-file-medical",
          route: "/ReportGenerator",
          roles: ["pm_admin", "pm_user"],
        },
        {
          caption: t("reports.archive"),
          id: "reportsArchive",
          icon: "fas fa-fw fa-archive",
          route: "/ReportArchive",
          roles: ["pm_admin", "pm_user"],
        },
      ],
    },
    {
      menu: t("priceLists.name"),
      roles: ["pm_admin", "pm_user"],
      items: [
        {
          caption: t("priceLists.priceLists"),
          id: "priceLists",
          icon: "fas fa-fw fa-hand-holding-usd",
          route: "/PriceLists",
          roles: ["pm_admin", "pm_user"],
        },
      ],
    },
    {
      menu: "Admin Tools",
      roles: ["pm_admin"],
      items: [
        {
          caption: "Admin Tools",
          id: "adminTools",
          icon: "fas fa-screwdriver",
          route: "/AdminPage",
          roles: ["pm_admin"],
        },
      ],
    },
  ];

  const reportsMenu = [
    {
      menu: t("statistics.name"),
      roles: ["pm_admin", "pm_user"],
      items: [
        {
          caption: "Exam Statistics",
          id: "statisticsUser",
          icon: "fas fa-x-ray",
          route: "/ExamStats",
          roles: ["pm_admin", "pm_user"],
        },
      ],
    },
    {
      menu: t("reports.name"),
      roles: ["pm_admin", "pm_user"],
      items: [
        {
          caption: t("reports.generator"),
          id: "reportsGenerator",
          icon: "fas fa-fw fa-file-medical",
          route: "/ReportGenerator",
          roles: ["pm_admin", "pm_user"],
        },
        {
          caption: t("reports.archive"),
          id: "reportsArchive",
          icon: "fas fa-fw fa-archive",
          route: "/ReportArchive",
          roles: ["pm_admin", "pm_user"],
        },
      ],
    },
    {
      menu: t("priceLists.name"),
      roles: ["pm_admin", "pm_user"],
      items: [
        {
          caption: t("priceLists.priceLists"),
          id: "priceLists",
          icon: "fas fa-fw fa-hand-holding-usd",
          route: "/PriceLists",
          roles: ["pm_admin", "pm_user"],
        },
      ],
    },
  ];

  const menuItems =
    process.env.REACT_APP_BUILD_TYPE === "reports" ? reportsMenu : defaultItems;

  useEffect(() => {
    return history.listen((location) => {
      setCurLocation(location.pathname);
    });
  }, [history]);

  useEffect(() => {
    initialized &&
      fetch(
        `${window.conf.SERVER_CONFIG.BASE_URL}/download/reportcount`,
        MakeGetObject(keycloak)
      )
        .then((response) => response.json())
        .then((rdata) => setReportCount(rdata));
  }, [selectedItem, initialized]);

  return (
    <div className="navbar-cont">
      <ul className={`navbar-nav  ${navToggled ? "toggled" : ""}`}>
        <a
          className="brand"
          href="https://pergamonmed.com/services"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={process.env.PUBLIC_URL + "/static/images/pm_logo.png"}
            alt="logo"
          />
          <div className="brand-text ">PergamonMED</div>
        </a>

        <div className="nav-item-box">
          {menuItems.map((menuItem, key) => {
            return (
              <AuthorizedElement
                roles={menuItem.roles}
                key={`menuItemKey${key}`}
              >
                <hr className="sidebar-divider" />
                <div className="sidebar-heading">{menuItem.menu}</div>

                <li className="nav-item">
                  {menuItem.items.map((item, skey) => {
                    return (
                      <AuthorizedElement
                        roles={item.roles}
                        key={`submenuitemkey${skey}`}
                      >
                        <Link
                          className={`nav-link collapsed ${
                            item.route === curLocation && "selected"
                          }`}
                          to={item.route}
                          aria-expanded="true"
                          onClick={(event) => {
                            if (width < 1199) {
                              setToggled(true);
                            }
                            setSelectedItem(item.id);
                          }}
                        >
                          <i className={item.icon}></i>
                          <span>
                            {item.caption}{" "}
                            {item.id == "reportsArchive" && reportCount > 0 && (
                              <> {reportCount}</>
                            )}
                          </span>
                        </Link>
                      </AuthorizedElement>
                    );
                  })}
                </li>
              </AuthorizedElement>
            );
          })}
          {initialized ? (
            keycloak.authenticated && (
              <AuthorizedElement
                roles={[
                  "pm_admin",
                  "pm_user",
                  "pm_refphys",
                  "pm_facility",
                  "pm_patient",
                ]}
              >
                <hr className="sidebar-divider" />
                <div className="sidebar-heading">User</div>
                <li id="users-stats" className="nav-item">
                  <Link
                    className="nav-link collapsed"
                    to="/UserSettings"
                    aria-expanded="true"
                  >
                    <i className="fas fa-fw fa-user"></i>
                    <span>{keycloak.tokenParsed.name}</span>
                  </Link>
                </li>
              </AuthorizedElement>
            )
          ) : (
            <></>
          )}
          <hr className="sidebar-divider d-none d-md-block" />
        </div>
        <div className="text-center  d-md-inline">
          <button
            className="rounded-circle border-0 round-toggle"
            style={{ width: "40px", height: "40px" }}
            onClick={() => {
              setToggled(!navToggled);
            }}
            id="sidebarToggle"
          >
            <i
              className={`fas fa-lg fa-${
                navToggled ? "chevron-right" : "chevron-left"
              }`}
            />
          </button>
        </div>
        {/* <ThemeSelector isToggled={navToggled} /> */}
      </ul>
    </div>
  );
};

export default Menu;
