import style from "./style.scss";

function Rechter4FingerDpSitzend1324(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 190.51 474.24"
    >
      <g id="_4th_finger_left" data-name=" 4th finger left">
        <g id="Background_layer" data-name="Background layer">
          <path
            id="Background_layer_1"
            data-name="Background layer 1"
            class="Rechter_Finger4_dp_sitzend1324_Rechter_Finger4_dp_sitzend1324_cls-10"
            d="M187.52,467.85L0,473.77l17.39-230.66c5.09,59.36,12.62,95.03,23.7,96.3,10.97,2.3,15.59-10.96,16.71-32.81-2.17-36.45.96-66.2,5.47-94.22,5.89-29.64,9.06-59.51,10.03-89.58l3.34-98.13C78.8,3.99,90.67-1.24,108.41.97c19.68.24,31.52,11.52,36.88,31.94,5.97,24.73,7.61,88.26,9.72,147.6.62,43.51-3.1,90.7-9.72,140.35-3.1,18.89,1.97,27.73,14.28,27.65,10.44,2.81,20.34-15.03,30.08-38.58l-2.13,157.92Z"
          />
        </g>
        <g id="middle_hand_bone" data-name="middle hand bone">
          <path
            id="middle_hand_bone_1"
            data-name="middle hand bone 1"
            class="Rechter_Finger4_dp_sitzend1324_cls-8"
            d="M49.3,472.2c-.27-15.46,1.39-26.48,6.34-39.71,2.82-7.55,9.63-12.95,17.63-13.89,5.08-.6,10.55.04,16.13,1.01,11.86,1.7,20.44,10.04,24.61,27.33,1.85,11.29,3.37,16.59,3.34,23.62"
          />
          <path
            id="middle_hand_bone_2"
            data-name="middle hand bone 2"
            class="cls-7"
            d="M111.88,470.56c.52-5.32-2.35-10-6.27-14.47-4.38-3.72-5.81-10.17-3.53-20.05"
          />
          <path
            id="middle_hand_bone_3"
            data-name="middle hand bone 3"
            class="cls-7"
            d="M38.51,473.75c1.62-11.87,6.2-16.46,11.76-18.64"
          />
        </g>
        <g id="fingerbone_1" data-name="fingerbone 1">
          <path
            id="finger_bone_1.1"
            data-name="finger bone 1.1"
            class="Rechter_Finger4_dp_sitzend1324_cls-8"
            d="M106.11,414.13l.02-.02c-13.35-6.48-28.88-5.67-45.87,0-4.63,2.12-8.04,1.57-7.9-6.68-3.86-6.35-4.24-13.46,0-21.57,15.83-18.41,22.75-51.28,24.61-92.35.56-9.98,1.04-18.52,1.42-25.29.07-1.16.28-2.31.57-3.44,1.88-7.29,1.87-14.06-.47-20.18-2.23-3.47-1.4-6.59.91-9.53,1.05-1.35,1.53-3.08,1.34-4.78-.51-4.56.35-9.1,2.54-13.61.64-1.32,1.72-2.41,3.07-3,6.04-2.66,13.88-1.03,22.5,2.48,2.73,1.11,5.7,1.54,8.64,1.26l11.11-1.07c4.86.82,8.03,5.43,6.1,21.49-.2,1.6-.18,3.22,0,4.83.54,4.91-.37,8.34-2.69,10.32-1.15.99-2.04,2.24-2.51,3.68-3.57,10.9-4.67,21.14-4.03,30.92.05.72.02,1.43-.09,2.14-6.35,39.83-8.68,75.1.17,97.67,6.99,10.29,9.22,19.64,0,26.73-4.18,6.53-10.12,8.06-19.44,0h0Z"
          />
          <path
            id="fingerbone_1.2"
            data-name="fingerbone 1.2"
            class="cls-7"
            d="M64.5,412.81c5.66.25,10.84,1.41,15.68,3.18,7.89,2.89,16.43,3.71,24.72,2.35,2-.33,3.72-.74,5.16-1.23"
          />
          <path
            id="fingerbone_1.3"
            data-name="fingerbone 1.3"
            class="cls-7"
            d="M116.09,249.55c-5.35,2.48-7.86,10.89-7.67,24.91.02,1.11.21,2.21.57,3.26,2.85,8.34,2.2,20.77.16,34.82l4.1,45.23c2.37,17.74,6.01,27.22,10.48,31.22"
          />
          <path
            id="fingerbone_1.4"
            data-name="fingerbone 1.4"
            class="cls-7"
            d="M97.3,254.45c-1.45,3.42-2.25,9.04-2.25,17.36,0,3.08-.64,6.16-2.04,8.91-3.08,6.06-4.39,15.9-4.78,27.69-.03.85-.23,1.69-.58,2.46-2.98,6.55-5.18,13.47-6.41,20.82-1.03,11.03-7.37,23.63-15.61,36.8"
          />
          <path
            id="fingerbone_1.5"
            data-name="fingerbone 1.5"
            class="cls-7"
            d="M129.42,223.46c-.15,10.37,1.37,15.65,4.56,15.84"
          />
          <path
            id="fingerbone_1.6"
            data-name="fingerbone 1.6"
            class="cls-7"
            d="M87.5,220.96c-1.47,6.66-3.61,10.56-6.75,10.42"
          />
        </g>
        <g id="fingerbone_2" data-name="fingerbone 2">
          <path
            id="fingerbone_2.1"
            data-name="fingerbone 2.1"
            class="Rechter_Finger4_dp_sitzend1324_cls-8"
            d="M140.6,194.76l-.02-.03c.75,1.38,1.03,2.97.84,4.53-.54,4.44-2.05,7.93-6.17,8.87-6.26-.32-13.3,1.06-20.84,3.55-1.26.43-2.62.43-3.88,0-9.21-3.15-17.91-5.59-24.54-5.13-1.79.12-3.5-.77-4.38-2.33-3.14-5.57-3.55-10.28-.26-13.84,1.51-1.62,2.5-3.66,3.06-5.81,5.62-21.63,8.88-42.42,9.04-62.11.34-2.21-.03-4.48-1-6.5-2.96-6.16-2.74-10.51,1.05-15.28,1.18-1.48,1.7-3.34,1.54-5.22-.64-7.67,4.62-10.68,12.75-6.67,4.51,1.14,8.64,1.86,12.61.57,10.08-1.97,12.53.55,13.22,8.12.1,1.2.5,2.33,1.07,3.38.97,1.79,1.41,4.53,1.84,8.02.32,2.64-.32,5.37-1.98,7.45-5.88,7.37-6.82,19.3-3.09,35.54.66,2.89,1.09,5.82,1.23,8.77.62,13.18,2.92,24.85,7.91,34.12h0Z"
          />
          <path
            id="fingerbone_2.2"
            data-name="fingerbone 2.2"
            class="cls-7"
            d="M88.41,200.68c7.01.27,13.27,1.12,17.43,3.59,2.06,1.42,4.28,1.97,6.63,1.88,1.69-.06,3.36-.49,4.9-1.2,5.49-2.51,10.81-3.23,15.93-1.99"
          />
          <path
            id="fingerbone_2.3"
            data-name="fingerbone 2.3"
            class="cls-7"
            d="M119.28,108.16c-4.29,5.62-5.46,14.25-3.87,25.52,2.91,7.41,5.03,14.47,5.58,20.85,3.33,12.53,7.2,23.76,11.62,33.61,2.23,3.74,3.69,8.35,4.62,13.56"
          />
          <path
            id="fingerbone_2.4"
            data-name="fingerbone 2.4"
            class="cls-7"
            d="M85.91,194.31c8.41-16.39,13.73-33.77,16.85-51.86.49-2.86.69-5.75.58-8.65-.27-7.34.16-14.99,1.13-22.89-.38-2.21-.97-4.31-1.86-6.27"
          />
        </g>
        <g id="fingerbone_3" data-name="fingerbone 3">
          <path
            id="fingerbone_3.1"
            data-name="fingerbone 3.1"
            class="Rechter_Finger4_dp_sitzend1324_cls-8"
            d="M133.75,82.99h0c-3.13,4.78-5.47.34-15.04,2.28-5.18,1.33-10.31.76-15.38-1.71-5.51.32-9.39-1.05-9.91-5.92-3.38-2.83-2.8-5.68.46-8.54,9.66-8.62,5.76-28.15,4.22-27.46-3.82-6.54-3.77-14.54,0-23.92,1.82-2.8,12.18-4.89,18.34-.46,6.23,4.8,9.94,9.9,9.46,15.49.33,4.22.27,7.95-.95,10.23-.5.92-.7,1.96-.7,3-.03,12.06,4.93,21.31,12.59,29.72,1.02,3.02.13,5.48-3.08,7.29h0Z"
          />
          <path
            id="fingerbone_3.2"
            data-name="fingerbone 3.2"
            class="cls-7"
            d="M99.81,68.3c4.21.46,6.53-4.58,7.15-14.56.25-3.91.15-7.84-.15-11.74-.52-6.7,1.31-9.6,5.3-9.02,3.45.04,4.77,4.44,4.89,11.3.03,1.91.2,3.82.55,5.7,1.33,7.09,4.77,13.35,9.14,19.23,3.49,2.84,5.27,5.44,4.9,7.75"
          />
        </g>
        <g id="outline">
          <path
            id="outline_1"
            data-name="outline 1"
            class="cls-7"
            d="M187.31,315.46c.78-1.8,1.56-3.65,2.34-5.53"
          />
          <path
            id="outline_2"
            data-name="outline 2"
            class="Rechter_Finger4_dp_sitzend1324_cls-6"
            d="M18.48,254.99c5.05,52.21,12.29,83.24,22.61,84.42,10.97,2.3,15.59-10.96,16.71-32.81-2.17-36.45.96-66.2,5.47-94.22,5.89-29.64,9.06-59.51,10.03-89.58l3.34-98.13C78.8,3.99,90.67-1.24,108.41.97c19.68.24,31.52,11.52,36.89,31.94,5.97,24.73,7.61,88.26,9.72,147.6.62,43.51-3.1,90.7-9.72,140.35-3.1,18.89,1.97,27.73,14.28,27.65,7.84,2.11,15.37-7.42,22.76-22.27"
          />
          <path
            id="outline_3"
            data-name="outline 3"
            class="cls-7"
            d="M17.4,243.09c.18,2.02.35,4.02.53,5.98"
          />
        </g>
        <g id="next_right_finger" data-name="next right finger">
          <path
            id="next_right_finger_1"
            data-name="next right finger 1"
            class="Rechter_Finger4_dp_sitzend1324_cls-3"
            d="M1,447.09c5.29,3.17,10.52,4.71,15.67,3.91,1.95-.3,3.64-1.64,4.22-3.52.64-2.11,1.94-3.89,3.84-5.37,1.57-1.23,2.72-2.93,3.03-4.89,1.19-7.6.83-15.87-.28-24.47-.21-1.62-1.04-3.1-2.33-4.11l-2.46-1.92c-1.73-1.36-3.13-3.11-4.02-5.12-5.14-11.57-8.55-26.16-10.99-42.45"
          />
          <path
            id="next_right_finger_2"
            data-name="next right finger 2"
            class="Rechter_Finger4_dp_sitzend1324_cls-3"
            d="M24.54,473.75c-4.92-8.77-12.21-15.23-22.63-18.64"
          />
        </g>
        <g id="next_left_finger" data-name="next left finger">
          <path
            id="next_left_finger_1"
            data-name="next left finger 1"
            class="Rechter_Finger4_dp_sitzend1324_cls-3"
            d="M187.52,414.58c-16.44.2-27.85,9.48-31.14,33.42-5.08,3.52-6.43,8.77-2.58,16.43l-1.06,5.45"
          />
          <path
            id="next_left_finger_2"
            data-name="next left finger 2"
            class="Rechter_Finger4_dp_sitzend1324_cls-3"
            d="M188.28,408.83c-3.39-.87-6.64-1.15-9.57,0-7.04,2.89-11.76,2.25-10.94-6.84-.44-5.82.09-10.69,1.5-14.71,1.08-3.07,3.24-5.6,5.95-7.41,5.33-3.57,9.99-10,14.43-17.38"
          />
          <path
            id="next_left_finger_3"
            data-name="next left finger 3"
            class="Rechter_Finger4_dp_sitzend1324_cls-4"
            d="M156.39,448.01c3.1-1.08,5.06-3.23,6.48-5.9.54-1.01.8-2.15.69-3.29-.28-2.92,1.69-6.9,4.07-11.09"
          />
        </g>
      </g>
      <g id="Overlays">
        <g
          id="Overlay_Finger_Complete"
          data-name="Overlay Finger Complete"
          class="Rechter_Finger4_dp_sitzend1324_cls-1"
        >
          <g id="overlay_fingerbone_1" data-name="overlay fingerbone 1">
            <path
              id="finger_bone_1.1-2"
              data-name="finger bone 1.1-2"
              class="FullFinger1324Fill"
              style={{
                fill: props.colors.FullFinger1324Fill,
                stroke: props.colors.FullFinger1324Fill,
                opacity: props.colors.FullFinger1324Fill,
              }}
              d="M105.97,414.13l.02-.02c-13.35-6.48-28.88-5.67-45.87,0-4.63,2.12-8.04,1.57-7.9-6.68-3.86-6.35-4.24-13.46,0-21.57,15.83-18.41,22.75-51.28,24.61-92.35.56-9.98,1.04-18.52,1.42-25.29.07-1.16.28-2.31.57-3.44,1.88-7.29,1.87-14.06-.47-20.18-2.23-3.47-1.4-6.59.91-9.53,1.05-1.35,1.53-3.08,1.34-4.78-.51-4.56.35-9.1,2.54-13.61.64-1.32,1.72-2.41,3.07-3,6.04-2.66,13.88-1.03,22.5,2.48,2.73,1.11,5.7,1.54,8.64,1.26l11.11-1.07c4.86.82,8.03,5.43,6.1,21.49-.2,1.6-.18,3.22,0,4.83.54,4.91-.37,8.34-2.69,10.32-1.15.99-2.04,2.24-2.51,3.68-3.57,10.9-4.67,21.14-4.03,30.92.05.72.02,1.43-.09,2.14-6.35,39.83-8.68,75.1.17,97.67,6.99,10.29,9.22,19.64,0,26.73-4.18,6.53-10.12,8.06-19.44,0h0Z"
            />
          </g>
          <g id="overlay_fingerbone_2" data-name="overlay fingerbone 2">
            <path
              id="fingerbone_2.1-2"
              data-name="fingerbone 2.1-2"
              class="FullFinger1324Fill"
              style={{
                fill: props.colors.FullFinger1324Fill,
                stroke: props.colors.FullFinger1324Fill,
                opacity: props.colors.FullFinger1324Fill,
              }}
              d="M140.46,194.76l-.02-.03c.75,1.38,1.03,2.97.84,4.53-.54,4.44-2.05,7.93-6.17,8.87-6.26-.32-13.3,1.06-20.84,3.55-1.26.43-2.62.43-3.88,0-9.21-3.15-17.91-5.59-24.54-5.13-1.79.12-3.5-.77-4.38-2.33-3.14-5.57-3.55-10.28-.26-13.84,1.51-1.62,2.5-3.66,3.06-5.81,5.62-21.63,8.88-42.42,9.04-62.11.34-2.21-.03-4.48-1-6.5-2.96-6.16-2.74-10.51,1.05-15.28,1.18-1.48,1.7-3.34,1.54-5.22-.64-7.67,4.62-10.68,12.75-6.67,4.51,1.14,8.64,1.86,12.61.57,10.08-1.97,12.53.55,13.22,8.12.1,1.2.5,2.33,1.07,3.38.97,1.79,1.41,4.53,1.84,8.02.32,2.64-.32,5.37-1.98,7.45-5.88,7.37-6.82,19.3-3.09,35.54.66,2.89,1.09,5.82,1.23,8.77.62,13.18,2.92,24.85,7.91,34.12h0Z"
            />
          </g>
          <g id="overlay_fingerbone_3" data-name="overlay fingerbone 3">
            <path
              id="fingerbone_3.1-2"
              data-name="fingerbone 3.1-2"
              class="FullFinger1324Fill"
              style={{
                fill: props.colors.FullFinger1324Fill,
                stroke: props.colors.FullFinger1324Fill,
                opacity: props.colors.FullFinger1324Fill,
              }}
              d="M133.61,82.99h0c-3.13,4.78-5.47.34-15.04,2.28-5.18,1.33-10.31.76-15.38-1.71-5.51.32-9.39-1.05-9.91-5.92-3.38-2.83-2.8-5.68.46-8.54,9.66-8.62,5.76-28.15,4.22-27.46-3.82-6.54-3.77-14.54,0-23.92,1.82-2.8,12.18-4.89,18.34-.46,6.23,4.8,9.94,9.9,9.46,15.49.33,4.22.27,7.95-.95,10.23-.5.92-.7,1.96-.7,3-.03,12.06,4.93,21.31,12.59,29.72,1.02,3.02.13,5.48-3.08,7.29h0Z"
            />
          </g>
        </g>
        <ellipse
          id="Overlay_finger_base_joint"
          data-name="Overlay finger base joint"
          class="Grundgelenk1324Fill"
          style={{
            fill: props.colors.Grundgelenk1324Fill,
            stroke: props.colors.Grundgelenk1324Fill,
            opacity: props.colors.Grundgelenk1324Fill,
          }}
          cx="88.13"
          cy="417.84"
          rx="37.86"
          ry="24.39"
        />
        <path
          id="Overlay_fingertip"
          data-name="Overlay fingertip"
          class="Fingerkuppe1324Fill"
          style={{
            fill: props.colors.Fingerkuppe1324Fill,
            stroke: props.colors.Fingerkuppe1324Fill,
            opacity: props.colors.Fingerkuppe1324Fill,
          }}
          d="M115.72,16.79c-7.29-2.98-13.3-2.98-17.63.92-3.39,9.24-4.11,17.48,0,23.92,2.47-11.8,7.71-16.2,14.6-16.17,5.33-.19,9.43,6.85,13.29,15.27,1.61-10.05-1.05-18.34-10.26-23.94Z"
        />
      </g>
    </svg>
  );
}

export default Rechter4FingerDpSitzend1324;
