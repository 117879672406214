import style from "./style.scss";

function Brustwirbelkorper9VdLiegend1523(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 315.9 191.23">

<g id="BG">
  <path id="BG_white" data-name="BG white" class="Brustwirbelsaule9_ap1523_cls4" d="M26.06,70.75l-2.73,33.96c2.79.14,6.34-1.74,10.61-5.53,8.58-5.84,16.39-9.55,23.55-11.45,13.16-3.85,25.77-6.72,36.21-5.75-1.51,5.2-2.3,10.04-.65,13.67,3.86,7.15,8.72,10.32,14.28,10.73,11.73.2,20.95-.65,26.6,1.02l4.23-2.17c-2.02,5.28-5.29,9.69-3.63,13.81,1.48,3.76,3.76,5.33,6.52,5.55l-2.06,5.06c-4.69-2.55-6.73-1.52-5.89,3.41-1.83,1.62-3.77,1.15-5.95-1.19-12.41-10.57-22.82-10.28-33.27-10.17-15.01,1.26-29.95,9.63-44.85,20.45-16.24,12.67-32.56,27.45-48.94,43.72,34.35-22.1,67.32-34.8,99.02-38.86-5.21,4.36-7.04,10.64-7.05,17.95.68,5.58,6.12,7.26,15.26,7.01,3.95-1.47,8.7,1.01,14,6.16,8.96,7.17,18.86,10.3,30.06,7.74l11.39.04h31.36c4.6-.5,8.8-.59,11.94.42l21.73,4.52c4.78,1.37,6.5-2.99,6.07-11.35-2.9-6.19-1.5-7.64,1.5-7.33,10.4.16,19.04-3.5,23.87-15.45,16.57-10.65,35.62-17.7,56.64-21.88-39.8-5-63.75-11.9-73.41-20.51-3.45.41-6.8,2.42-10.09,5.42-3.69-5.08-6.47-10.09-8.52-15.06,3.27,4.62,5.86,5.09,7.64.55,1.91-2.17,4.2-3.03,7.05-1.9,7.53.27,14.55-.91,20.54-5.05,5.84-6.07,3.84-13.41-1.55-21.32,6.83-.76,14.07.89,21.64,4.37,17.04,5.54,28.69,7.76,36.7,7.73l-39.29-32.83c-8.52-1.12-18.46-6.01-28.92-12.29-4.16-2.5-9.01-3.67-13.85-3.26-3.24.28-6.33.89-9.3,1.81-6.24-11.77-7.59-20.09-5.71-26.15l5.01-8.66c1.67-5.07,1.25-7.97-2.93-6.98l-5.27,1.51c-5.61.14-11.41,1.13-17.34,2.74h-40.53c-6.57,1.05-12.22.62-16.72-1.65-4.4-2.82-5.89-.12-5.57,6.04-1.44-2.83-4.21-3.24-7.44-2.84-5.27.14-9.96-.79-14.49-1.99-10.27-1.61-17.83,1.65-22.79,9.56-2.35,4.71-1.57,9.41,2.53,14.1,4.05,2.94,9.5,4.22,15.7,4.58,6.34.22,11.52,3,15.71,7.97,4.56,5.76,9.03,10.9,13,12.54,2.15,4.01,1.81,8.08.35,12.18l-6.28-5.98c-10.92-7.92-24.08-11.73-39.73-10.98-17.92,2.79-41.31,11.79-65.99,22.26h.03Z"/>
</g>
<g id="BWK9ap">
  <g id="BWK9ap_Parts" data-name="BWK9ap Parts">
    <path id="BWK9ap_Part23" data-name="BWK9ap Part23" class="Brustwirbelsaule9_ap1523_cls6" d="M137.71,53.27c1.73,3.17,7.78,2.65,20.96,1.82,13.66-.86,20.49-1.29,24.61-1.37,16.45-.3,24.67-.45,30.08.91,4.36,1.09,8.98,2.76,11.39.46,2.56-2.45,1.26-8.08.91-9.57-.82-3.55-1.96-3.71-4.1-8.66-2.61-6.02-3.09-10.77-3.19-11.85-.33-3.62-.53-5.89.46-8.66,1.4-3.93,3.59-4.33,5.01-8.66.26-.78,1.87-5.9,0-7.29-1.33-.99-3.04.94-8.2,1.82-3.06.52-3.56.03-7.29.46-3.99.45-6.73,1.39-8.2,1.82-5.46,1.6-8.32.63-26.89.46-9.04-.09-13.56-.13-15.49,0-6.02.4-8.28,1.03-12.3,0-5.16-1.32-7.01-3.77-8.66-2.73-2.35,1.48-1.19,8.08.46,12.3,1.28,3.28,2.07,3.1,3.65,6.84.98,2.32,2.75,6.52,2.28,11.39-.3,3.04-1.15,3.13-3.19,9.11-2.17,6.39-3.26,9.59-2.28,11.39h-.02Z"/>
    <path id="BWK9ap_Part22" data-name="BWK9ap Part22" class="Brustwirbelsaule9_ap1523_cls6" d="M98.06,6.33c-1.45.8-5.34,3.02-7.29,7.75-.42,1.03-2.52,6.11,0,10.94,1.88,3.6,5.3,5.07,7.29,5.92,3.58,1.53,4.62.66,10.94,1.82,4.79.88,8.05,1.48,11.39,3.65,2.08,1.35,2.26,2.06,7.29,7.75,8.22,9.3,9.45,9.33,10.03,9.11,1.63-.62.44-4.21,2.28-11.39,1.49-5.81,2.78-5.46,3.19-9.11.3-2.64-.25-3.95-4.55-15.5-2.91-7.81-3.34-9.03-5.02-10.02-2.49-1.48-4.11-.32-10.48-.91-5.14-.48-4.88-1.3-9.57-1.82-4.6-.51-10.15-1.12-15.49,1.82h-.01Z"/>
    <path id="BWK9ap_Part21" data-name="BWK9ap Part21" class="Brustwirbelsaule9_ap1523_cls5" d="M135.43,65.57c1.78-1.24,3.91.84,10.03,2.28,4.45,1.05,7.76,1,13.22.91,6.86-.11,7.32-1.01,15.95-1.82,9.84-.93,10.56.11,21.87-.91,5.73-.52,8.6-.78,12.3-1.37,9.32-1.48,12.78-3.07,15.49-.91,2.33,1.85,2.54,5.22,2.73,8.2.32,4.98-1.25,7.08-2.73,13.22-1.52,6.27-2.66,10.96-1.37,16.41,1.3,5.49,4.94,11.21,5.92,12.76,2.54,3.99,4.26,5.52,3.65,6.84-.8,1.72-4.79,1.32-12.76.46-17.04-1.85-20.61-2.41-25.97-2.28-5.46.14-3.52.76-14.13,1.82-17.18,1.73-16.76-.46-26.89,1.37-8,1.44-12.18,3.52-15.49.91-2.84-2.23-3.15-6.4-3.19-7.29-.19-3.97,1.9-5.54,4.1-10.94,1.39-3.4,3.38-8.3,2.73-13.67-.66-5.45-3.36-5.76-5.47-13.22-.61-2.15-3.02-10.66,0-12.76h.01Z"/>
    <path id="BWK9ap_Part20" data-name="BWK9ap Part20" class="Brustwirbelsaule9_ap1523_cls5" d="M134.25,73.21c-2.79-2.86-6.39-.16-16.59-1.26-5.68-.61-7.05-1.72-10.94-.91-4.36.91-7.25,3.26-8.2,4.1-3.74,3.29-5,7.16-5.47,8.66-.56,1.8-1.93,6.44,0,11.85.38,1.07,2.53,6.76,8.2,9.57,2.31,1.14,5.79,1.22,12.76,1.37,7.09.15,7.29-.38,12.3,0,6.01.46,6.72,1.29,8.66.46,4.21-1.81,6.42-8.13,6.04-13.2-.35-4.61-2.59-4.9-4.68-12.77-1.45-5.47-.64-6.37-2.09-7.85v-.02h0Z"/>
    <path id="BWK9ap_Part19" data-name="BWK9ap Part19" class="Brustwirbelsaule9_ap1523_cls5" d="M150.01,89.72c-3.62-4.65-4.36-8.9-4.56-10.94-.2-2.1.02-3.68.46-6.84.73-5.29,1.1-7.94,3.19-10.03,1.45-1.44,4.3-3.21,6.84-2.28,2.66.97,3.48,4.35,3.65,5.01.92,3.76-1.3,4.84-3.19,12.76-.98,4.12-.9,6-2.73,8.66-1.21,1.76-2.62,2.93-3.65,3.65h-.01Z"/>
    <path id="BWK9ap_Part18" data-name="BWK9ap Part18" class="Brustwirbelsaule9_ap1523_cls5" d="M211.53,90.63c-2.78.17-5.25-8.78-5.47-9.57-1.68-6.24-.61-11.08,0-13.67.71-3.03,1.41-5.99,3.19-6.38,2.16-.47,4.76,3.09,5.92,5.47,1.96,4,1.47,7.86.91,11.85-.35,2.46-1.7,12.13-4.56,12.3h0Z"/>
    <path id="BWK9ap_Part17" data-name="BWK9ap Part17" class="Brustwirbelsaule9_ap1523_cls5" d="M241.15,103.39c1.81-.02,6.54-.11,11.39-1.82,3.25-1.15,5.8-2.05,7.75-4.56,3.51-4.52,2.14-10.48,1.82-11.85-.94-4.08-3.2-6.68-4.56-8.2-1.26-1.42-4.66-5.13-10.03-6.38-1.2-.28-4.16-.19-10.03,0-4.54.15-6.81.23-7.29.46-3.39,1.63-4.62,7.26-6.94,18.46-.97,4.68-1.07,6.21-.9,7.98.52,5.32,3.42,11.02,6.01,10.93,1.82-.07,1.82-2.93,5.01-4.56,2.16-1.1,3.38-.41,7.75-.46h.02Z"/>
    <path id="BWK9ap_Part16" data-name="BWK9ap Part16" class="Brustwirbelsaule9_ap1523_cls6" d="M134.06,185.87c5.49,2.03,9.01.78,17.32,0,10.19-.96,7.97.64,26.89.46,19.56-.19,20.33-1.88,27.8,0,2.67.67,6.64,1.51,14.58,3.19,7.51,1.59,10.11,2.05,11.85.46,2.93-2.7,1.55-9.61,1.37-10.48-.75-3.55-2.22-5.06-1.37-6.38.99-1.52,3.73-.7,7.29-.91,7.59-.45,12.96-5.27,13.67-5.92,5.34-4.93,6.32-11.24,6.84-14.58.47-3.03,1.02-6.58-.91-8.2-1.45-1.22-3.21-.42-8.66,0-1.71.13-5.81.44-10.03,0-5.68-.59-9.5-.98-11.39-3.65-2.67-3.77,1.11-8.39-1.37-10.94-1.88-1.93-5.15-.42-11.85.46-7.14.93-6.92-.33-18.68,0-8.86.25-8.13.94-17.77,1.37-12.27.54-10.82-.69-20.51,0-9.79.7-12.66,2.06-17.77,0-3.29-1.33-5.66-3.32-7.29-2.28-2.21,1.41.36,6.21-2.28,9.11-1.57,1.73-3.61,1.26-9.57,1.82-2.69.25-7.98.75-12.76,2.28-3.36,1.07-8.31,2.66-12.3,7.29-.81.94-4.85,5.8-5.01,12.76-.06,2.36-.14,6.03,2.28,8.2.56.5,1.67,1.3,7.75,1.82,6.19.53,6.85-.14,9.57.46,3.34.74,4.39,2.2,9.57,5.92,4.72,3.39,8.71,6.25,12.76,7.75h-.02Z"/>
    <path id="BWK9ap_Part15" data-name="BWK9ap Part15" class="Brustwirbelsaule9_ap1523_cls6" d="M151.38,156.71c2.79.13,5.4-5.29,6.38-9.11,1.06-4.17-.24-4.79,0-12.76.19-6.18,1.04-8.16-.46-9.57-1.85-1.74-5.75-1.17-8.2.46-3.86,2.56-4.24,7.79-4.56,12.3-.18,2.55-.15,6.97,1.82,11.85,1.18,2.91,2.72,6.73,5.01,6.84h0Z"/>
    <path id="BWK9ap_Part14" data-name="BWK9ap Part14" class="Brustwirbelsaule9_ap1523_cls6" d="M213.35,158.53c2.76.32,5.59-2.8,6.84-5.47,1.53-3.28.34-5.18-.91-14.13-1.28-9.19-.69-11.95-3.19-13.67-2.1-1.44-5.35-1.45-7.29,0-1.07.8-1.89,2.14-2.28,9.57-.35,6.69-.53,10.04.46,13.67.8,2.96,2.61,9.59,6.38,10.03h-.01Z"/>
    <path id="BWK9ap_Part13" data-name="BWK9ap Part13" class="Brustwirbelsaule9_ap1523_cls6" d="M184.62,70.75c3.99-28.08,11.44-54.85,15.9-58.59"/>
    <path id="BWK9ap_Part12" data-name="BWK9ap Part12" class="Brustwirbelsaule9_ap1523_cls6" d="M177.32,74.55c.13,1.32-5.68-57.89-12.71-58.66"/>
    <path id="BWK9ap_Part11" data-name="BWK9ap Part11" class="Brustwirbelsaule9_ap1523_cls6" d="M315.89,89.07c-27.57,0-42.45-13.61-58.33-12.11"/>
    <path id="BWK9ap_Part10" data-name="BWK9ap Part10" class="Brustwirbelsaule9_ap1523_cls6" d="M229.88,81.98c0-2.77,12.38-5.01,27.67-5.01"/>
    <path id="BWK9ap_Part9" data-name="BWK9ap Part9" class="Brustwirbelsaule9_ap1523_cls6" d="M229.88,81.98c-12.54-6.63-8.46-32.13-5.34-39.48"/>
    <path id="BWK9ap_Part8" data-name="BWK9ap Part8" class="Brustwirbelsaule9_ap1523_cls6" d="M224.55,42.49c26.77-6.85,23.42,8.15,52.05,13.75"/>
    <path id="BWK9ap_Part7" data-name="BWK9ap Part7" class="Brustwirbelsaule9_ap1523_cls6" d="M242.48,114.33c2.6,5.36,32.64,16.68,73.41,20.51"/>
    <path id="BWK9ap_Part6" data-name="BWK9ap Part6" class="Brustwirbelsaule9_ap1523_cls6" d="M222.31,149.42c0-19.39,10.87-35.09,20.17-35.09"/>
    <path id="BWK9ap_Part5" data-name="BWK9ap Part5" class="Brustwirbelsaule9_ap1523_cls6" d="M127.15,131.87C84.97,98.89,36.09,152.05.09,185.87"/>
    <path id="BWK9ap_Part4" data-name="BWK9ap Part4" class="Brustwirbelsaule9_ap1523_cls5" d="M162.77,74.58c18.29,17.3,13.1,69.34,18.28,60.26"/>
    <path id="BWK9ap_Part3" data-name="BWK9ap Part3" class="Brustwirbelsaule9_ap1523_cls5" d="M200.52,74.55c-14.44,4.83-7.23,55.67-19.47,60.28"/>
    <path id="BWK9ap_Part2" data-name="BWK9ap Part2" class="Brustwirbelsaule9_ap1523_cls6" d="M131.78,59.47c-35.65-24.01-64.46-3.28-105.72,11.28"/>
    <path id="BWK9ap_Part172" data-name="BWK9ap Part172" class="Brustwirbelsaule9_ap1523_cls6" d="M235.43,40.21c1.81-.02,6.54-.11,11.39-1.82,3.25-1.15,5.8-2.05,7.75-4.56,3.51-4.52,6.5-11.72,6.18-13.09-.94-4.08-4.61-7.06-5.97-8.58-1.26-1.42-7.61-3.51-12.98-4.76-1.2-.28-4.16-.19-10.03,0-4.54.15-6.81.23-7.29.46-3.39,1.63-3.81,3.38-6.13,14.58-.97,4.68,3.03,12.65,3.2,14.42.52,5.32.39,5.73,2.98,5.64,1.82-.07-.07-.2,3.12-1.83,2.16-1.1,3.38-.41,7.75-.46h.02Z"/>
    <path id="BWK9ap_Part1" data-name="BWK9ap Part1" class="Brustwirbelsaule9_ap1523_cls6" d="M130.38,79.47c-95.78-.27-97.23,27.29-107.05,25.24"/>
  </g>
</g>
<g id="angrenzende_BWK_Highlights" data-name="angrenzende BWK Highlights">
  <path id="angrenzende_BWK_Highlight4" data-name="angrenzende BWK Highlight4" class="BWK1523Fill" style= {{
            fill: props.colors.BWK1523Fill,
            stroke: props.colors.BWK1523Fill,
            opacity:props.colors.BWK1523Fill,
          }} d="M137.71,53.53c1.73,3.17,7.78,2.65,20.96,1.82,13.66-.86,20.49-1.29,24.61-1.37,16.45-.3,24.67-.45,30.08.91,4.36,1.09,8.98,2.76,11.39.46,2.56-2.45,1.26-8.08.91-9.57-.82-3.55-1.96-3.71-4.1-8.66-2.61-6.02-3.09-10.77-3.19-11.85-.33-3.62-.53-5.89.46-8.66,1.4-3.93,3.59-4.33,5.01-8.66.26-.78,1.87-5.9,0-7.29-1.33-.99-3.04.94-8.2,1.82-3.06.52-3.56.03-7.29.46-3.99.45-6.73,1.39-8.2,1.82-5.46,1.6-8.32.63-26.89.46-9.04-.09-13.56-.13-15.49,0-6.02.4-8.28,1.03-12.3,0-5.16-1.32-7.01-3.77-8.66-2.73-2.35,1.48-1.19,8.08.46,12.3,1.28,3.28,2.07,3.1,3.65,6.84.98,2.32,2.75,6.52,2.28,11.39-.3,3.04-1.15,3.13-3.19,9.11-2.17,6.39-3.26,9.59-2.28,11.39h-.02Z"/>
  <path id="angrenzende_BWK_Highlight3" data-name="angrenzende BWK Highlight3" class="BWK1523Fill" style= {{
            fill: props.colors.BWK1523Fill,
            stroke: props.colors.BWK1523Fill,
            opacity:props.colors.BWK1523Fill,
          }} d="M98.06,6.59c-1.45.8-5.34,3.02-7.29,7.75-.42,1.03-2.52,6.11,0,10.94,1.88,3.6,5.3,5.07,7.29,5.92,3.58,1.53,4.62.66,10.94,1.82,4.79.88,8.05,1.48,11.39,3.65,2.08,1.35,2.26,2.06,7.29,7.75,8.22,9.3,9.45,9.33,10.03,9.11,1.63-.62.44-4.21,2.28-11.39,1.49-5.81,2.78-5.46,3.19-9.11.3-2.64-.25-3.95-4.55-15.5-2.91-7.81-3.34-9.03-5.02-10.02-2.49-1.48-4.11-.32-10.48-.91-5.14-.48-4.88-1.3-9.57-1.82-4.6-.51-10.15-1.12-15.49,1.82h-.01Z"/>
  <path id="angrenzende_BWK_Highlight2" data-name="angrenzende BWK Highlight2" class="BWK1523Fill" style= {{
            fill: props.colors.BWK1523Fill,
            stroke: props.colors.BWK1523Fill,
            opacity:props.colors.BWK1523Fill,
          }} d="M134.06,186.13c5.49,2.03,9.01.78,17.32,0,10.19-.96,7.97.64,26.89.46,19.56-.19,20.33-1.88,27.8,0,2.67.67,6.64,1.51,14.58,3.19,7.51,1.59,10.11,2.05,11.85.46,2.93-2.7,1.55-9.61,1.37-10.48-.75-3.55-2.22-5.06-1.37-6.38.99-1.52,3.73-.7,7.29-.91,7.59-.45,12.96-5.27,13.67-5.92,5.34-4.93,6.32-11.24,6.84-14.58.47-3.03,1.02-6.58-.91-8.2-1.45-1.22-3.21-.42-8.66,0-1.71.13-5.81.44-10.03,0-5.68-.59-9.5-.98-11.39-3.65-2.67-3.77,1.11-8.39-1.37-10.94-1.88-1.93-5.15-.42-11.85.46-7.14.93-6.92-.33-18.68,0-8.86.25-8.13.94-17.77,1.37-12.27.54-10.82-.69-20.51,0-9.79.7-12.66,2.06-17.77,0-3.29-1.33-5.66-3.32-7.29-2.28-2.21,1.41.36,6.21-2.28,9.11-1.57,1.73-3.61,1.26-9.57,1.82-2.69.25-7.98.75-12.76,2.28-3.36,1.07-8.31,2.66-12.3,7.29-.81.94-4.85,5.8-5.01,12.76-.06,2.36-.14,6.03,2.28,8.2.56.5,1.67,1.3,7.75,1.82,6.19.53,6.85-.14,9.57.46,3.34.74,4.39,2.2,9.57,5.92,4.72,3.39,8.71,6.25,12.76,7.75h-.02Z"/>
  <path id="angrenzende_BWK_Highlight1" data-name="angrenzende BWK Highlight1" class="BWK1523Fill" style= {{
            fill: props.colors.BWK1523Fill,
            stroke: props.colors.BWK1523Fill,
            opacity:props.colors.BWK1523Fill,
          }} d="M235.43,40.47c1.81-.02,6.54-.11,11.39-1.82,3.25-1.15,5.8-2.05,7.75-4.56,3.51-4.52,6.5-11.72,6.18-13.09-.94-4.08-4.61-7.06-5.97-8.58-1.26-1.42-7.61-3.51-12.98-4.76-1.2-.28-4.16-.19-10.03,0-4.54.15-6.81.23-7.29.46-3.39,1.63-3.81,3.38-6.13,14.58-.97,4.68,3.03,12.65,3.2,14.42.52,5.32.39,5.73,2.98,5.64,1.82-.07-.07-.2,3.12-1.83,2.16-1.1,3.38-.41,7.75-.46h.02Z"/>
</g>
<g id="BWK9_highlights" data-name="BWK9 highlights">
  <path id="BWK9_highlight3" data-name="BWK9 highlight3" class="Wirbelkorper_Querfortsatze1523Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1523Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1523Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1523Fill,
          }} d="M135.4,65.57c1.78-1.24,3.91.84,10.03,2.28,4.45,1.05,7.76,1,13.22.91,6.86-.11,7.32-1.01,15.95-1.82,9.84-.93,10.56.11,21.87-.91,5.73-.52,8.6-.78,12.3-1.37,9.32-1.48,12.78-3.07,15.49-.91,2.33,1.85,2.54,5.22,2.73,8.2.32,4.98-1.25,7.08-2.73,13.22-1.52,6.27-2.66,10.96-1.37,16.41,1.3,5.49,4.94,11.21,5.92,12.76,2.54,3.99,4.26,5.52,3.65,6.84-.8,1.72-4.79,1.32-12.76.46-17.04-1.85-20.61-2.41-25.97-2.28-5.46.14-3.52.76-14.13,1.82-17.18,1.73-16.76-.46-26.89,1.37-8,1.44-12.18,3.52-15.49.91-2.84-2.23-3.15-6.4-3.19-7.29-.19-3.97,1.9-5.54,4.1-10.94,1.39-3.4,3.38-8.3,2.73-13.67-.66-5.45-3.36-5.76-5.47-13.22-.61-2.15-3.02-10.66,0-12.76h.01Z"/>
  <path id="BWK9_highlight2" data-name="BWK9 highlight2" class="Wirbelkorper_Querfortsatze1523Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1523Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1523Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1523Fill,
          }} d="M134.22,73.21c-2.79-2.86-6.39-.16-16.59-1.26-5.68-.61-7.05-1.72-10.94-.91-4.36.91-7.25,3.26-8.2,4.1-3.74,3.29-5,7.16-5.47,8.66-.56,1.8-1.93,6.44,0,11.85.38,1.07,2.53,6.76,8.2,9.57,2.31,1.14,5.79,1.22,12.76,1.37,7.09.15,7.29-.38,12.3,0,6.01.46,6.72,1.29,8.66.46,4.21-1.81,6.42-8.13,6.04-13.2-.35-4.61-2.59-4.9-4.68-12.77-1.45-5.47-.64-6.37-2.09-7.85v-.02h0Z"/>
  <path id="BWK9_highlight1" data-name="BWK9 highlight1" class="Wirbelkorper_Querfortsatze1523Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1523Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1523Fill,
            opacity:props.colors.Wirbelkorper_Querfortsatze1523Fill,
          }} d="M241.12,103.39c1.81-.02,6.54-.11,11.39-1.82,3.25-1.15,5.8-2.05,7.75-4.56,3.51-4.52,2.14-10.48,1.82-11.85-.94-4.08-3.2-6.68-4.56-8.2-1.26-1.42-4.66-5.13-10.03-6.38-1.2-.28-4.16-.19-10.03,0-4.54.15-6.81.23-7.29.46-3.39,1.63-4.62,7.26-6.94,18.46-.97,4.68-1.07,6.21-.9,7.98.52,5.32,3.42,11.02,6.01,10.93,1.82-.07,1.82-2.93,5.01-4.56,2.16-1.1,3.38-.41,7.75-.46h.02Z"/>
</g>
<g id="Grund_u_Deckplatten_Highlights" data-name="Grund u Deckplatten Highlights">
  <path id="Grund_u_Deckplatten_Highlight4" data-name="Grund u Deckplatten Highlight4" class="Grund_Deckplatten1523Fill" style= {{
            fill: props.colors.Grund_Deckplatten1523Fill,
            stroke: props.colors.Grund_Deckplatten1523Fill,
            opacity:props.colors.Grund_Deckplatten1523Fill,
          }} d="M222.61,52.05c-2.78,2-8.18-.5-11.27-.98-2.92-.46-5.85-.7-8.8-.73-7.16-.08-14.35.35-21.5.56-6.56.2-13.14.26-19.68.7-7.31.5-14.55,1.63-21.71-.54-3.7-1.12-5.29,4.67-1.6,5.79,7.12,2.16,14.25,1.46,21.56.89,7.76-.6,15.57-.65,23.35-.89,7.93-.24,16.06-1.01,23.98-.32,5.66.49,13.53,4.43,18.71.71,3.11-2.23.11-7.44-3.03-5.18Z"/>
  <path id="Grund_u_Deckplatten_Highlight3" data-name="Grund u Deckplatten Highlight3" class="Grund_Deckplatten1523Fill" style= {{
            fill: props.colors.Grund_Deckplatten1523Fill,
            stroke: props.colors.Grund_Deckplatten1523Fill,
            opacity:props.colors.Grund_Deckplatten1523Fill,
          }} d="M224.67,60.97c-7.73-.8-15.18.71-22.78,1.96-7.68,1.26-15.04.38-22.7,1.01-7.29.6-14.39,2.47-21.73,2.62-3.84.08-7.67-.2-11.39-1.2-3.51-.94-6.63-2.34-10.31-2.59-3.85-.26-3.84,5.75,0,6,3.87.26,7.48,2.22,11.28,2.95,3.43.66,6.93.91,10.42.83,7.35-.17,14.44-2.01,21.73-2.62,6.91-.57,13.68.36,20.62-.64,8.32-1.2,16.39-3.2,24.85-2.32,3.84.4,3.81-5.61,0-6Z"/>
  <path id="Grund_u_Deckplatten_Highlight2" data-name="Grund u Deckplatten Highlight2" class="Grund_Deckplatten1523Fill" style= {{
            fill: props.colors.Grund_Deckplatten1523Fill,
            stroke: props.colors.Grund_Deckplatten1523Fill,
            opacity:props.colors.Grund_Deckplatten1523Fill,
          }} d="M228.58,119.2c-.07.05-.12.1-.18.15-14.45-1.99-29.05-3.59-43.54-1.59-7.96,1.1-15.96-.27-23.93.53-7.48.75-15.73,5.08-23.1,1.94-3.51-1.5-6.57,3.67-3.03,5.18,6.21,2.64,12.12,1.9,18.54.41,8.41-1.96,16.28-1.44,24.82-1.57,8.22-.13,16.04-1.84,24.33-1.39,8.9.48,17.77,1.36,26.6,2.6,1.58.22,3.25-.36,3.69-2.1.01.03.03.05.04.08,3.05-2.36-1.22-6.58-4.24-4.24Z"/>
  <path id="Grund_u_Deckplatten_Highlight1" data-name="Grund u Deckplatten Highlight1" class="Grund_Deckplatten1523Fill" style= {{
            fill: props.colors.Grund_Deckplatten1523Fill,
            stroke: props.colors.Grund_Deckplatten1523Fill,
            opacity:props.colors.Grund_Deckplatten1523Fill,
          }} d="M226.38,126.44c-8.13,1.31-15.97.42-24.13.32-7.33-.1-14.58,1.2-21.89,1.45-15.15.51-30.74,1.38-45.65-1.64-3.77-.76-5.38,5.02-1.6,5.79,15.5,3.14,31.5,2.41,47.25,1.86,7.62-.27,15.18-1.6,22.81-1.44,8.44.18,16.41.81,24.8-.54,3.8-.61,2.19-6.4-1.6-5.79Z"/>
</g>
</svg>
  );
}

export default Brustwirbelkorper9VdLiegend1523;
