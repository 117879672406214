import style from "./style.scss";

function RechtesOberesSprunggelenkStressaufnahmeVdLiegend1466(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 423.03 474.85">
  
  <g id="upper_ankle" data-name="upper ankle">
    <g id="background_layer" data-name="background layer">
      <path id="background" class="oberes_Sprunggelenk1466_Stressaufnahme_vd_rechts_cls3" d="M312.72,23.52c26.13,115.78,59.61,219.95,103.52,307.67,14.14,28.24,5.64,62.75-20.48,80.52-5.92,4.02-12.5,7.57-19.82,10.59-11.45,4.72-17.29,17.5-13.36,29.24l1.4,4.18c3.11,9.27-3.79,18.86-13.57,18.86h-215.23c-23.58,0-46.65-6.88-66.35-19.84-1.71-1.12-3.4-2.27-5.08-3.45-34.23-23.98-51.07-65.92-43.15-106.96l18.75-97.14c3.37-17.49,4.36-35.37,2.81-53.11C36.75,132.19,22.55,75.72,1.28,23.61-3.32,12.33,5.03,0,17.21,0l266.29.17c14,0,26.14,9.7,29.22,23.35Z"/>
    </g>
    <path id="ulnar" class="oberes_Sprunggelenk1466_Stressaufnahme_vd_rechts_cls5" d="M213.03,9.18c13.4,36.4,24.3,80.8,26.2,154.6-3.6,27.3,2.8,62.5,13.6,101.1,20.4,48.2,42.8,87.9,68.2,115.9,14.2,14.8,30.7,19.8,48.5,19.4,19-.4,34.1-16.7,32.5-35.7-1.4-16.7-11.4-36.2-25.4-57-44-70-77.7-174.3-105.1-299.4"/>
    <path id="radius1" class="oberes_Sprunggelenk1466_Stressaufnahme_vd_rechts_cls5" d="M28.93,5.78c34.6,114.4,46.5,228.8,22.7,343.2-4.8,54.6,13.4,75.8,43.2,80.1,13.1,10.9,22.1,6.9,29-5.1.2-28.2,6.1-49.4,17.6-63.6,17.8-19.2,41.1-20.4,65.9-17,31.7,5.5,56-.8,74.4-16.5,26.2-50.1,15-99.8-35.8-148.9C207.63,127.88,180.33,67.08,159.53.18"/>
    <path id="tasus" class="oberes_Sprunggelenk1466_Stressaufnahme_vd_rechts_cls5" d="M127.13,458.68c-1.6-28.2-1.2-53.4,11.4-61.4l8-19.3c1.7-17,10.3-25.6,23.9-27.8,36.5-1.8,75.4,1.3,115.9,8,20.6,8.7,26.7,28.6,22.7,56.2-4.2,21.7-1.3,41.9,10.2,60.2"/>
    <path id="radius2" class="oberes_Sprunggelenk1466_Stressaufnahme_vd_rechts_cls5" d="M117.53,378.48c3.4-10.9,7.3-20.2,12.1-27.2,4.6-6.5,11.9-10.5,19.8-11.1,13.3-.9,25.8-4.9,37-15.8,3.6-3.5,6.3-7.6,8.9-11.8,3.3-5.3,12.5-8.3,26.2-9.7,32.3-9,24.9-30.5,15.3-52.8-18.2-34.4-49.8-82.6-65.4-139.6"/>
  </g>
  <g id="overlay">
    <path id="tibia_overlay" data-name="tibia overlay" class="Tibia1466Fill" style= {{
              fill: props.colors.Tibia1466Fill,
              stroke: props.colors.Tibia1466Fill,
              opacity:props.colors.Tibia1466Fill,
            }} d="M29.43,5.73c34.6,114.4,46.5,228.8,22.7,343.2-4.8,54.6,13.4,75.8,43.2,80.1,13.1,10.9,22.1,6.9,29-5.1.2-28.2,6.1-49.4,17.6-63.6,17.8-19.2,41.1-20.4,65.9-17,31.7,5.5,56-.8,74.4-16.5,26.2-50.1,15-99.8-35.8-148.9C208.13,127.83,180.83,67.03,160.03.13"/>
    <path id="talus_overlay" data-name="talus overlay" class="Talus1466Fill" style= {{
              fill: props.colors.Talus1466Fill,
              stroke: props.colors.Talus1466Fill,
              opacity:props.colors.Talus1466Fill,
            }} d="M127.13,458.68c-1.6-28.2-1.2-53.4,11.4-61.4l8-19.3c1.7-17,10.3-25.6,23.9-27.8,36.5-1.8,75.4,1.3,115.9,8,20.6,8.7,26.7,28.6,22.7,56.2-4.2,21.7-1.3,41.9,10.2,60.2"/>
    <path id="Opening_angle_overlay" data-name="Opening angle overlay" class="OpeningAngle1466Fill" style= {{
              fill: props.colors.OpeningAngle1466Fill,
              stroke: props.colors.OpeningAngle1466Fill,
              opacity:props.colors.OpeningAngle1466Fill,
            }} d="M309.03,414.38c21.35,1.35,39.16-4.44,55-14.18-40.22-3.96-55.71-31.3-81.8-73.38-17.15,15.85-41.71,21.67-74.4,16.5-37.53-5.03-61.37,4.44-72.62,27.28-9.41,15.18-11.9,33.47-10.88,53.32l2.13,16.37c-.78-18.82,1.8-34.5,12.07-43.02l8-19.3c1.19-17.94,11.24-24.54,23.9-27.8,39.01-1.61,77.65.96,115.9,8,21.29,10.24,27.19,30,22.7,56.2Z"/>
  </g>
</svg>
  );
}

export default RechtesOberesSprunggelenkStressaufnahmeVdLiegend1466;
