import style from "./style.scss";

function RechteMittelhandDpOblSitzend1334(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 395.3 505.23">

<g id="background_layer" data-name="background layer">
  <path id="background" class="mittelhand_rechts_dp_obl1334_cls2" d="M377.27,500.81c-.5-.4-.5-3.4-.5-3.4.2-19.4,4.1-103.4-13.2-133-7.53-20.89-.84-48.43-.6-72,.61-51.62,3.71-97.93,9.7-118.3l14.42-81.68,7.94-40.16c1.37-6.92-2.55-13.79-9.19-16.15l-19.47-6.9c-5.6-1.98-11.83-.14-15.44,4.57l-2.49,3.24c-2.26,2.94-6.96,1.58-7.29-2.11l-1.7-18.64c-.67-7.37-7.02-12.92-14.41-12.61l-29.88,1.28c-5.68.24-10.26,4.73-10.62,10.41h0c-.27,4.3-5.65,6.06-8.42,2.76l-9.03-10.77c-4.79-5.72-12.3-8.4-19.63-7l-27.5,5.23c-9.14,1.74-15.76,9.73-15.76,19.04h0c0,2.99-3.4,4.7-5.8,2.94l-13.48-9.91c-11.28-8.29-26.38-9.19-38.56-2.3l-32.69,18.48c-1.77,1-2.67,3.05-2.2,5.03v.05c6.21,21.1,3.31,69.6,3.31,69.6,0,0-1.1,14.9-4.1,27.5-1.2,3.9-3.7,11-6,10s-40-16-51-34c-1-17-2-35-8-51-1-3-3-5-7.1-6.2l-21.61,5.47c-8.01,2.03-13.67,9.18-13.81,17.44C.13,95.6-.65,113.99.52,132.82c.59,9.49,4,18.62,9.81,26.15,44.97,58.25,51.57,137.31,131.84,213.24,26.6,23.7,32.2,133.4,28.9,129.1"/>
</g>
<g id="hand_overview" data-name="hand overview">
  <g id="full_hand" data-name="full hand">
    <g id="hand">
      <g id="_5._finger" data-name=" 5. finger">
        <path id="_5._finger_2" data-name=" 5. finger 2" class="mittelhand_rechts_dp_obl1334_cls5" d="M387.09,47.71c-1.4,4.8-2.8,10.1-4.2,15.7,4.7,15.4.1,26.8-9.4,36.1-4.7-7.8-29.7-13.6-31.4-10.6-6.1-6.9-7-16.8-1.2-20.8,4.7-7.1,19.6-17.6,18.9-22.7,1.1-1,2.4-2.6,3.6-4.5"/>
        <path id="_5._finger_1" data-name=" 5. finger 1" class="mittelhand_rechts_dp_obl1334_cls5" d="M353.39,96.81c-1.3-3.6-5-6.3-11.3-7.8,9.5-2,19.4.8,29.8,9l-1.2,4.3c-5.4-5.5-11-9.1-17.3-5.5h0Z"/>
      </g>
      <g id="_4._finger" data-name=" 4. finger">
        <path id="_4._finger_3" data-name=" 4. finger 3" class="mittelhand_rechts_dp_obl1334_cls5" d="M297.49,17.71c-3.5,20.2-9.3,30-16.3,34.3h0c-.8,10.6,2.9,18.1,11.8,22.4,10.1-6.5,22.3-5.8,35.7-1.2,6.2-2.6,8.1-6.2,7.8-10.2l-6.3-19.6c-.2-8.7-.2-18,0-27.2"/>
        <path id="_4._finger_2" data-name=" 4. finger 2" class="mittelhand_rechts_dp_obl1334_cls5" d="M292.99,74.41c7-4.9,17.2-6.6,33.4-2.4l-5.1,3.9-28.3-1.5h0Z"/>
      </g>
      <g id="_3._finger" data-name=" 3. finger">
        <path id="_3._finger_2" data-name=" 3. finger 2" class="mittelhand_rechts_dp_obl1334_cls5" d="M218.39,19.81c-.5,5.9-1.6,12.3-3.4,18.9-2.1,17.1,3.8,24.3,16.1,23.1,11.8-3.9,15.3-15.3,36.5-11.4,5.9-3.5,6-7.8,3.5-12.5-4.3-6.7-8-13.2-11-19.4"/>
        <path id="_3._finger_1" data-name=" 3. finger 1" class="mittelhand_rechts_dp_obl1334_cls5" d="M267.59,50.41c-8.2-1.4-16.4-1.6-25.6,5.9,1-.2,3.1,0,6.8.6,4.6-1.7,9-2.4,12.9-2.1,3.1-.6,4.6-2.3,5.9-4.4h0Z"/>
      </g>
      <g id="_2._finger" data-name=" 2. finger">
        <path id="_2._finger_1" data-name=" 2. finger 1" class="mittelhand_rechts_dp_obl1334_cls5" d="M164.09,22.81c2.2,2.2,4.5,4.5,7,6.8,9.6,3.4,16.6,10.4,20,22.4-8.2,20.6-24.4,29.3-46.7,26.3-7.8-.7-9.5-4-9.8-7.9h0c1.5-8.6-.9-19.3-6.3-31.7"/>
      </g>
      <g id="thumb">
        <path id="thumb_3" data-name="thumb 3" class="mittelhand_rechts_dp_obl1334_cls5" d="M31.49,81.01c1.1,2.3,2.4,4.6,3.9,6.7,5,6.4,6.8,12.8-2.4,19.2-8.5-4.8-17.1-5.6-25.5,5.5-3.4-5-2.1-11,3.9-18l.3-14.4"/>
        <path id="thumb_2" data-name="thumb 2" class="mittelhand_rechts_dp_obl1334_cls5" d="M8.29,119.51h0c-2.6-2.6-2.6-4.9-.8-7,8.8-8.7,17.3-11.9,25.5-5.5-.4,8.1,4,17.8-.8,15.7-3.9,2.6-12.1,1.3-23.9-3.2h0Z"/>
        <path id="thumb_1" data-name="thumb 1" class="mittelhand_rechts_dp_obl1334_cls5" d="M16.09,143.41h0c9.6,10,23.1,22.7,33.3,40.8,1.4,7.3,2.5,11.4,5.5,14.9l11.4,3.1c18.3-4.1,25.2-15.9,27-31,1.6-5.5-1.3-10.2-7.8-14.1-20.5-8.7-42.4-17.3-47.5-27.8,1.3-13.2-1-16.8-4.3-17.7,1.9,4.3,3.7,8.6-1.5,13.4-17.6-2.5-21.3-4-23.9-5.5l-4.7,11c-1.3,7.1,3.8,11,12.5,12.9h0Z"/>
      </g>
      <g id="middle_hand" data-name="middle hand">
        <g>
          <path id="middle_hand_5-2" data-name="middle hand 5-2" class="mittelhand_rechts_dp_obl1334_cls5" d="M302.79,270.11h0c-.7-8.7,6.8-23.4,19.7-41.9,11.7-31.7,21.5-62.5,16.1-85.5l-4.7-13.8c-3.4-5-4.8-11.4-4.3-18.8,7.4-19.2,40.4-20.4,47.1-1.3-.9,5.2-.2,15,1.2,26.7l-6.7,9.4c-1.3,22.4-10.4,54.7-20.4,90.6.8,23.2-3.5,37.4-13.4,41.6l-20.8,2.8-13.8-9.8h0Z"/>
          <path id="middle_hand_4-2" data-name="middle hand 4-2" class="mittelhand_rechts_dp_obl1334_cls5" d="M286.79,110.11h0c5.8,6.1,11,12.1,15.8,18.2,3.1,41.5,2.2,83.4-6.3,104.3-6.9,7.7-11.6,17.3-10.6,31.8,5.4,13.8,15.6,13.7,27,10.2,5.6,1.7,13.9-.5,22.7-3.5,1.9-15.3-4.4-24.6-11-43.6l5.9-65.9c1.6-17,4.4-32,7.5-46.7,2-6.9-3.2-17.3-2.4-31.8-18.2-10.3-33.7-9.4-47.5-.8-2.3,9.4-4.2,18.9-1.1,27.8h0Z"/>
          <path id="middle_hand_3-2" data-name="middle hand 3-2" class="mittelhand_rechts_dp_obl1334_cls5" d="M231.69,83.41h0l-.3,11.1c-.8,3.2,17.3,14.9,21.2,43.2,3.6,24.7,9.8,52.2,9.8,89.8-2.3,8.3-5.4,16.1-10.2,23.1l-.4,11.8c-1.7,8.5-3.4,17-.4,21.2,5.3-3.3,11.9-2.9,18.8-1.2,9.5-1,18.3-2.4,25.1-5.1,5.8,0,11.5-.6,17.3-2.8-2-9.8-4.1-17.9-7.1-22.7l-12.9-26.7c-1.7-39.2-3.2-76.7-3.5-103.6,4.8-16.9,8.1-31.4,4.3-34.9-4.7-5.9-8.8-12.8-11.8-21.2-18.5-14.1-43.1-17.7-49.8,18h-.1s0,0,0,0Z"/>
        </g>
        <path id="middle_hand_2" data-name="middle hand 2" class="mittelhand_rechts_dp_obl1334_cls5" d="M224.79,90.51h0c-3.2,6.3-2.8,17.1-2.7,31.3,11.5,48.3,5.6,93.4,30.3,138.1,9.1,11.4,21.6,22.2,18.1,35.7-6.5,3.1-16.4,1.2-27.1-2-9.2,10.3-16.3,15.8-21.6,16.5-16.6-4.2-19.8-20.7-14.5-45.1,8.2-41.6-6.5-102.3-30.6-145.9-6.9-3.5-13.6-7.1-16.9-12.9l-1.2-15.3c6.6-25.2,20.7-32,38.4-29.4l15.3,11c.7,8.1,6.1,13.3,12.5,18h0Z"/>
        <path id="middle_hand_1" data-name="middle hand 1" class="mittelhand_rechts_dp_obl1334_cls5" d="M66.69,215.21h0c23,14,48.8,55,74.1,93,2.2-1.3,7.3,1.1,14.1,5.5,15.4-.4,21.6-5.1,23.9-11.8,7.1-3.9,10-8,12.1-12.1,5.1-2.5,6.4-5.7,7.1-9-5.2-5.9-10.1-10-14.5-9.4-.3-2.1-1.5-4.2-4.7-6.3l-22.7-4.3c-20.7-17.2-34.3-34.5-41.6-52.2,2.5-8.3,2.6-15.3,1.2-21.6-10.6-9.3-22.3-15.7-35.7-5.9-5.9,4.3-10.6,10-14.1,17.7,1.4,5.1,1.6,10.6.8,16.4h0Z"/>
      </g>
      <path id="forearm_2-2" data-name="forearm 2-2" class="mittelhand_rechts_dp_obl1334_cls6" d="M307.39,504.71c.1-14.1-.2-28.2-.7-42.3v-10.2c-6.5-13.5-9.3-24.9-3.9-31.8,4.8-5.7,13.4-6.7,22-7.5,13.6,7.5,18.1,1,20.4-9,13.7-5.9,19.2,4.8,14.5,22.7,7.3,5.3,5.2,18.3-1.5,35.3-2.7,14-5.1,28.4-7.2,43"/>
      <path id="forearm_1-2" data-name="forearm 1-2" class="mittelhand_rechts_dp_obl1334_cls6" d="M287.99,505.11c3.8-15.2,8.1-29.8,12.9-43.9,3.9-6.7,13.3-14.1,12.5-16.5l-.8-28.4c-6.4-5.8-14.6-8.6-23.5-10.2l-14.9-9.4c-18.7.1-23.2-4.3-27.1-9.4-12.1-7-27.1-29.4-34.5-23.1-7.1,3.1-12.6,6.7-14.5,11.8l-5.1,40c-3.9,5.5-4.2,11-3.9,14.5,2.2,10.5,2.2,20.9,5.1,31.8,1.3,10.1,2.5,20.2,3.4,30.3"/>
      <g id="wrist-2">
        <path id="wrist_part_8-2" data-name="wrist part 8-2" class="mittelhand_rechts_dp_obl1334_cls5" d="M315.19,388.41h0c-17.1,2.9-22.8-29-2.2-43.3,7.8-13.8,16.5-19,26.7-9.8,3.2,6.1,6.7,10.1,11.4,12.1.2,3.7-4.1,13.8-3.5,23.9-11.1,23.9-21.7,25.6-32.4,17.1h0Z"/>
        <path id="wrist_part_7-2" data-name="wrist part 7-2" class="mittelhand_rechts_dp_obl1334_cls6" d="M220.09,376.01c-1-3.6-2.8-7.6-5-11.8-11.6-1.2-15.2-7.8-16.9-15.7,30.6-29.5,51.3-28.8,55.7-16.1,1.4,25.3,24.1,9.3,31,32.6-1.4,15.7-.4,30.6-7.1,41.2-21.2,1.6-37.4-5.4-46.3-24.7-2.5-2.6-5.8-4.8-11.4-5.5h0Z"/>
        <path id="wrist_part_6-2" data-name="wrist part 6-2" class="mittelhand_rechts_dp_obl1334_cls5" d="M194.59,342.31h0c1.4,3.6,3.6,3.9,6.3,2.6,1.1-6.7,2.7-13.3,17.3-18.8,8.8-6.4,11.5-12.1.4-16.5-7.9-8.5-15-15.6-17.7-15.7-8,.3-15.4,2.9-22,8.2-3.2,9.4-10.9,12.4-13.9,8.8-1.7-2.1-.9,3.2-4.5,3.3-2.1,3.3-1.1,7.4,2.4,12.1,2.7,7.6,7.5,13.9,15.3,18,3.4,1.2,9.3-1.8,16.4-2h0Z"/>
        <path id="wrist_part_5-2" data-name="wrist part 5-2" class="mittelhand_rechts_dp_obl1334_cls6" d="M268.29,410.51h0c-9.2-19.1-13.6-35.6-1.9-43,9.9-2.7,20.4-.3,31.3,7.8,21.1,3.4,22.2,14.3,18.1,27-13,13.7-29.5,14.1-47.5,8.2h0Z"/>
        <path id="wrist_part_4-2" data-name="wrist part 4-2" class="mittelhand_rechts_dp_obl1334_cls5" d="M250.29,287.41h0c-4.5,5.1-5.1,13.5,10.8,41.4-3.9,9.1-6,16.6-6.5,24.1,7.5,20.5,23,23.4,41.6,19.6,3.1-.1,9.3-6,15.9-12.5,2.8-2.8,10.1-6.7,14.3-17l11.6-8.4c3-8.7,4.2-16.4,3-22.9,4.2-5.1,7.9-10.2.8-16.1,1-8.6,1.9-17.1-4.3-18.4-4.2,2.7-8.6,5.1-15.3,3.9l-22-1.2c-4.5,1-10.5,3-17.1,5.3-17.2-.1-29.3-1.4-32.8,2.2h0Z"/>
        <path id="wrist_part_3-2" data-name="wrist part 3-2" class="mittelhand_rechts_dp_obl1334_cls5" d="M296.49,316.81h0c-5.5,1.4-10.2,4.4-12.7,12.1-.4,12.6,6.7,25,16.1,37.3,9.5,4,18.9,5.7,28.3.4,11-13.8,14.1-27.5-9-41.2l-22.7-8.6h0Z"/>
        <path id="wrist_part_2-2" data-name="wrist part 2-2" class="mittelhand_rechts_dp_obl1334_cls5" d="M294.29,358.01l-2.8-5.4,1.2-23.9c-5.3-7.9-8.3-16.1-6.3-24.7-11.8,1.2-12.9-18.8-15.3-28.6,7.5-2.1,13.9-2.6,19.6-2,5,2,8.7,2.9,12.1,3.5,11.8,5.5,18.4,9.4,11,28.6-1.1,19.6,2.8,46.7-4.1,54.7-9.3.9-13.2,0-15.4-2.2h0Z"/>
        <path id="wrist_part_1-2" data-name="wrist part 1-2" class="mittelhand_rechts_dp_obl1334_cls5" d="M253.79,332.51c-19.2,5.8-31.7,17.7-31.7.7l-.4-23.1c-1.5-10,.6-16.7,11.8-14.9,18.1,5.8,28.7,5.7,39.6,5.9,1.5,4.8,5.6,9.2,1.5,17.3-5.6,5.4-12.9,10-20.8,14.1h0Z"/>
      </g>
      <path id="_Überlagerung2" data-name=" Überlagerung2" class="mittelhand_rechts_dp_obl1334_cls5" d="M162.79,78.91c13.9-3.4,18.3-6.1,23.7-17.8"/>
      <path id="_Überlagerung1" data-name=" Überlagerung1" class="mittelhand_rechts_dp_obl1334_cls5" d="M66.29,202.21c14.9,0,27-13.9,27-31"/>
    </g>
  </g>
  <g id="overlays">
    <g id="outlines_highlights" data-name="outlines highlights">
      <path id="outlineshighlights3" class="weichteile1334Fill" style= {{
            fill: props.colors.weichteile1334Fill,
            stroke: props.colors.weichteile1334Fill,
          }} d="M142.44,372.17c29.4,26.2,33.2,157,27.6,123.9-2.3-13.6-5.1-59.7-15.6-94.1-3.1-15.3-20.8-34.4-28.8-42.1-8.7-8.4-37.9-41.2-55.7-76.4-28.9-57.3-47.5-105-60.6-120.1-3.4-4-8.8-8.7-7.1-14.6,52.8,59,55.2,142.9,140.2,223.3v.1Z"/>
      <path id="outlineshighlights2" class="weichteile1334Fill" style= {{
            fill: props.colors.weichteile1334Fill,
            stroke: props.colors.weichteile1334Fill,
          }} d="M363.14,292.67c-.3,23.5-6.9,50.9.6,71.7,17.3,29.6,13.4,113.6,13.2,133,0,0,0,9.5,2.9-3.3,3.4-14.6.4-67.5-1-94-1.5-29.7-12.7-37.1-13.8-51.1-.3-4.9,4.9-147.1,7.9-160.4,2.6-11.6,0-14.6,0-14.6-6,20.4-9.1,66.8-9.7,118.5"/>
      <path id="outlineshighlights7" class="weichteile1334Fill" style= {{
            fill: props.colors.weichteile1334Fill,
            stroke: props.colors.weichteile1334Fill,
          }} d="M58.54,110.17c-.1,5.2,42.5,36,47.3,27.7,7.3-12.7,7.7-68.3,2.7-88.6-2.8-11.6,1.3-17.8,3.2-10.5,6.2,21.1,3.3,69.6,3.3,69.6,0,0-1.1,14.9-4.1,27.5-1.2,3.9-3.7,11-6,10s-40-16-51-34c-1-17-2-35-8-51-1-3-3-5-7.1-6.2,11.5-17,20,38.8,19.7,55.5Z"/>
      <g>
        <path id="outlines3" class="mittelhand_rechts_dp_obl1334_cls4" d="M2.54,148.77c52.8,59,55.2,142.9,140.2,223.3,26.6,23.7,32.2,133.4,28.9,129.1"/>
        <path id="outlines2" class="mittelhand_rechts_dp_obl1334_cls4" d="M363.54,292.57c-.3,23.5-6.9,50.9.6,71.7,17.3,29.6,13.4,113.6,13.2,133,0,0,0,3,.5,3.4"/>
        <path id="outlines2-2" class="mittelhand_rechts_dp_obl1334_cls4" d="M373.24,173.97c-6,20.4-9.1,66.8-9.7,118.5"/>
        <path id="outlines7" class="mittelhand_rechts_dp_obl1334_cls4" d="M110.54,35.77c.5.3,1.1,1.3,1.5,3,6.2,21.1,3.3,69.6,3.3,69.6,0,0-1.1,14.9-4.1,27.5-1.2,3.9-3.7,11-6,10s-40-16-51-34c-1-17-2-35-8-51-1-3-3-5-7.1-6.2"/>
      </g>
    </g>
    <g id="MiddlehandbonesHL">
      <g id="Highlights">
        <path id="Highlight5" class="MHK1334Fill" style= {{
            fill: props.colors.MHK1334Fill,
            stroke: props.colors.MHK1334Fill,
          }} d="M302.54,269.87h0c-.7-8.7,6.8-23.4,19.7-41.9,11.7-31.7,21.5-62.5,16.1-85.5l-4.7-13.8c-3.4-5-4.8-11.4-4.3-18.8,7.4-19.2,40.4-20.4,47.1-1.3-.9,5.2-.2,15,1.2,26.7l-6.7,9.4c-1.3,22.4-10.4,54.7-20.4,90.6.8,23.2-3.5,37.4-13.4,41.6l-20.8,2.8-13.8-9.8h0Z"/>
        <path id="Highlight4" class="MHK1334Fill" style= {{
            fill: props.colors.MHK1334Fill,
            stroke: props.colors.MHK1334Fill,
          }} d="M286.54,109.87h0c5.8,6.1,11,12.1,15.8,18.2,3.1,41.5,2.2,83.4-6.3,104.3-6.9,7.7-11.6,17.3-10.6,31.8,5.4,13.8,15.6,13.7,27,10.2,5.6,1.7,13.9-.5,22.7-3.5,1.9-15.3-4.4-24.6-11-43.6l5.9-65.9c1.6-17,4.4-32,7.5-46.7,2-6.9-3.2-17.3-2.4-31.8-18.2-10.3-33.7-9.4-47.5-.8-2.3,9.4-4.2,18.9-1.1,27.8h0Z"/>
        <path id="Highlight3" class="MHK1334Fill" style= {{
            fill: props.colors.MHK1334Fill,
            stroke: props.colors.MHK1334Fill,
          }} d="M231.44,83.17h0l-.3,11.1c-.8,3.2,17.3,14.9,21.2,43.2,3.6,24.7,9.8,52.2,9.8,89.8-2.3,8.3-5.4,16.1-10.2,23.1l-.4,11.8c-1.7,8.5-3.4,17-.4,21.2,5.3-3.3,11.9-2.9,18.8-1.2,9.5-1,18.3-2.4,25.1-5.1,5.8,0,11.5-.6,17.3-2.8-2-9.8-4.1-17.9-7.1-22.7l-12.9-26.7c-1.7-39.2-3.2-76.7-3.5-103.6,4.8-16.9,8.1-31.4,4.3-34.9-4.7-5.9-8.8-12.8-11.8-21.2-18.5-14.1-43.1-17.7-49.8,18h-.1s0,0,0,0Z"/>
        <path id="Highlight2" class="MHK1334Fill" style= {{
            fill: props.colors.MHK1334Fill,
            stroke: props.colors.MHK1334Fill,
          }} d="M224.54,90.27h0c-3.2,6.3-2.8,17.1-2.7,31.3,11.5,48.3,5.6,93.4,30.3,138.1,9.1,11.4,21.6,22.2,18.1,35.7-6.5,3.1-16.4,1.2-27.1-2-9.2,10.3-16.3,15.8-21.6,16.5-16.6-4.2-19.8-20.7-14.5-45.1,8.2-41.6-6.5-102.3-30.6-145.9-6.9-3.5-13.6-7.1-16.9-12.9l-1.2-15.3c6.6-25.2,20.7-32,38.4-29.4l15.3,11c.7,8.1,6.1,13.3,12.5,18h0Z"/>
        <path id="Highlight1" class="MHK1334Fill" style= {{
            fill: props.colors.MHK1334Fill,
            stroke: props.colors.MHK1334Fill,
          }} d="M66.44,214.97h0c23,14,48.8,55,74.1,93,2.2-1.3,7.3,1.1,14.1,5.5,15.4-.4,21.6-5.1,23.9-11.8,7.1-3.9,10-8,12.1-12.1,5.1-2.5,6.4-5.7,7.1-9-5.2-5.9-10.1-10-14.5-9.4-.3-2.1-1.5-4.2-4.7-6.3l-22.7-4.3c-20.7-17.2-34.3-34.5-41.6-52.2,2.5-8.3,2.6-15.3,1.2-21.6-10.6-9.3-22.3-15.7-35.7-5.9-5.9,4.3-10.6,10-14.1,17.7,1.4,5.1,1.6,10.6.8,16.4h0Z"/>
      </g>
    </g>
  </g>
</g>
</svg>
  );
}

export default RechteMittelhandDpOblSitzend1334;
