import { MakePutObject } from "../AuthHeader";

export async function updateImageEntry(keycloak, imageId, updateObj) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/studies/series/images/${imageId}`;

  return fetch(url, MakePutObject(keycloak, updateObj)).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      throw new Error(resp.status);
    }
  });
}
