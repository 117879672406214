import style from "./style.scss";

export default function RechtesSchultergelenkSchwedenstatusElevationVdStehend2010(
  props
) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.68 428.34">
      <g id="Ebene_1" data-name="Ebene 1">
        <path
          id="background_2"
          data-name="background 2"
          class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls3"
          d="M398.7,408.11c-7.7-17.38-13.29-35.15-15.57-53.55-4.57-22.99-19.35-46.53-35.82-70.16-16.08-33.64-29.64-60.98-52-74.28,1.12-13.34,10.22-27.57,20.62-42.12,6.78-11.48,6.59-25.93-4.11-44.85-11.6-14.87-9.42-29.71-2.38-34.14l6.64-3.13c-20.92-14.36-42.32-24.03-64.3-28.32-20.97-2-33.42-7.22-35.83-16.23-2.11-7.66,1.64-10.95,8.76-11.73,34.7-4.88,55.5-3.96,57.96,4.6.48,8.08,5.72,13.89-1.42,13.3-9.93,1.38-12.48,3.22-4.66,5.71,54.59,8.09,104.99,16.45,138.78,25.93l14.93-3.66s1.16-.29,2.32-.57c-3.73-1.57-7.44-3.23-10.7-5.42-5.5-3.7-11.76-6.76-18.42-7.61-7.02-.89-13.9-.25-20.52-3.38-3.49-1.65-6.6-4.14-10.3-5.26-4.98-1.51-10.2-.4-15.29-.64-5.38-.26-10.73-2.29-15.99-3.37-5.18-1.06-10.45-1.63-15.73-1.88-3.04-.14-6.09-.18-9.13-.21-3.77-.04-7.55-.09-11.32-.13-8.11-.1-18.8.64-18.8-10.86,0-.81-2.05-4.72,1.13-7.52,3.52-3.09,17.26-7.26,25.78-5.85,7.18,1.19,18.31-1.06,25.44.38,18.29.23,17.79-1.35,36.88,2.32,14.37,2.76,24.98,5.48,33.05,7.55,9.29,2.39,52.03,15.01,64.95,19.06l4.15,341.47s-27.72,5.11-50.34,9.28c-15.59,2.87-28.76,5.3-28.76,5.3Z"
        />
        <path
          id="Collarbone"
          class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls11"
          d="M473.86,52.07c-12.92-4.05-55.66-16.67-64.95-19.06-8.07-2.07-18.68-4.79-33.05-7.55-19.09-3.67-18.59-2.1-36.88-2.32-7.13-1.45-18.26.81-25.44-.38-8.52-1.41-22.26,2.76-25.78,5.85-3.18,2.8-1.13,6.71-1.13,7.52,0,11.5,10.69,10.77,18.8,10.86,3.77.04,7.55.09,11.32.13,3.04.04,6.09.07,9.13.21,5.28.25,10.56.82,15.73,1.88,5.26,1.08,10.61,3.1,15.99,3.37,5.09.25,10.32-.87,15.29.64,3.7,1.12,6.81,3.61,10.3,5.26,6.62,3.13,13.5,2.48,20.52,3.38s12.92,3.92,18.42,7.61c5.2,3.49,11.54,5.63,17.29,8.23"
        />
        <g id="shoulder_plate" data-name="shoulder plate">
          <g id="shoulder_plate_1" data-name="shoulder plate 1">
            <path
              class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls2"
              d="M339.2,81.07c-1.02,4.02-5.72,5.12-9.24,5.44-5.11.67-10.17-.8-15.02.75-4.48,1.58-8.73,4.8-9.99,9.57-1.52,6.14-.46,13.49,2.71,19.02,3.66,5.64,7.22,11.78,9.86,18.02,7.3,14,4.23,26.68-3.44,39.68-3.13,6.11-8.5,10.68-11.57,16.8-3.14,5.92-5.19,12.41-6.17,19.04,0,0-.31-.91-.31-.91,4.97,5.11,11.19,8.85,16.02,14.11,4.98,5.19,8.8,11.42,12.49,17.51,4.86,8.17,9.45,16.46,13.89,24.87,2.22,4.2,4.29,8.5,6.43,12.68,4.37,8.36,9.58,16.2,15.04,23.93,7.82,11.96,15.25,24.31,20.24,37.75,3.44,8.91,4.65,18.52,6.5,27.8,2.82,13.89,6.88,27.47,11.74,40.79,0,0-1.01.4-1.01.4-3.75-8.73-6.9-17.72-9.51-26.87-2.6-9.11-4.4-18.54-6.09-27.83-1.77-9.2-5.17-18.02-9.41-26.39-4.37-8.3-9.09-16.57-14.35-24.35-2.68-3.86-5.46-7.77-7.98-11.81-5.21-7.93-9.3-16.63-13.63-25.03-4.4-8.36-8.97-16.66-13.77-24.79-3.65-6.04-7.3-12.06-12.16-17.17-4.95-5.35-11.25-9.18-16.3-14.45,1.52-10.42,5.44-20.8,12.43-28.8,2.2-2.58,4.08-5.3,5.72-8.25,3.33-5.82,6.26-11.96,7.09-18.64,1.05-6.75-.74-13.16-3.73-19.23-1.41-3.11-2.81-6.18-4.45-9.12-2.34-4.51-5.94-8.57-7.51-13.57-3.22-11.24-1.91-22.31,10.54-26.63,5.71-2.48,20.47,2.16,22.98-4.81,0,0,1.93.51,1.93.51h0Z"
            />
          </g>
          <path
            id="shoulder_plate_2"
            data-name="shoulder plate 2"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls11"
            d="M295.31,209.22c3.03.04,10.6-4.75,15.74-7.54,1.54-.84,8.21-4.67,13.21-16.36,5.18-12.13,4.38-22.91,3.15-37.13-1.7-19.66-6.67-34.31-9.44-41.54-1.86-4.85-5.63-12.11-8.54-17.65"
          />
          <line
            id="shoulder_plate_3"
            data-name="shoulder plate 3"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls11"
            x1="405.46"
            y1="83.36"
            x2="433.15"
            y2="89.91"
          />
          <path
            id="shoulder_plate_4"
            data-name="shoulder plate 4"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls11"
            d="M360.17,105.62c-2.61-8.52-10.1-13.47-27.72-13.15-3.92.07-7.05-3.76-8.64-7.55-1.17-2.78-1.64-6.31-.46-9.17,1.25-3.04,4.78-4.98,7.58-6.08,7.04-2.77,14.95-1.08,22,.82,4.6,1.25,6.92,1.71,10.74,3.61.88.44,1.85.68,2.83.72,1.67.07,3.2.27,4.35.35,1.99.14,4.11.29,6.77-.11.83-.13,2.95-.5,5.13-.25.98.11,1.83.33,1.95.36.85.21,1.62.5,2.37.83,1.9.86,2.95,1.75,4.3,2.61,1.38.88,2.48,1.34,4.66,2.22,1.68.68,5.88,2.39,9.44,2.51,4.49.15,4.54-2.35,11.96-5.04,3.71-1.34,3.75-.74,17-3.78,2.2-.5,4.12-.96,4.75-1.11,1.16-.29,2.26-.6,3.31-.95"
          />
        </g>
        <g id="acromion">
          <g id="acromion_1" data-name="acromion 1">
            <path
              class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls2"
              d="M359.95,130.68c-8.58-8.48-27.09-27.77-35.61-35.63-27.17-24.41-54.95-34.29-90.45-39.62-8.36-1.59-17.7-6.42-19.27-15.61-.58-2.99.07-6.49,2.52-8.57,2.4-2.02,5.49-2.16,8.37-2.66,5.57-.79,11.18-1.35,16.79-1.63,8.15-.6,17.14-.73,25.22-.94,3.16-.14,6.75.15,9.63,1.98,1.53.96,3.16,1.77,4.5,3.04,2.9,2.62,2.55,6.75,3.67,10.16.29,1.05.62,1.92.98,2.98.44,1.35-.01,2.93-1.1,3.85-.71.63-1.7.94-2.64.86h.21c-1.83.25-6.52.92-8.29,1.18-.28.05-.91.1-1.17.19-.59.23-.54,1.19.16,1.29,1.35.28,4.08.84,5.43,1.11,37.31,6.35,75.83,10.9,112.67,19.68,14.49,3.42,28.9,7.41,42.75,12.93l-.17.44c-13.92-5.22-28.37-8.86-42.86-12.11-37.85-8.49-77.34-12.67-115.49-19.62,0,0-2.75-.57-2.75-.57-.63-.11-1.37-.58-1.72-1.25-.96-1.68.28-3.81,2.19-3.87,2.26-.3,6.88-1.02,9.18-1.28.93.11,1.79-.67,1.86-1.6.06-.58-.22-1.05-.41-1.64-.97-2.62-1.44-5.52-1.96-8.22-.77-3.37-4.13-4.87-6.85-6.47-2.31-1.18-5.03-1.36-7.76-1.27-5.62.27-11.23.06-16.79.41-8.07.45-17.07.75-25.02,2.07-6.06.73-10.39,2.27-9.29,9.19,1.53,8.36,10.21,12.41,17.83,13.85,42.45,6.17,73.63,20.76,102.88,52.61,0,0,23.26,24.26,23.26,24.26,0,0-.48.47-.48.47h0Z"
            />
          </g>
          <path
            id="acromion_2"
            data-name="acromion 2"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls8"
            d="M242.99,43.32c.68,5.08,32.02,7.44,40.72,4.49"
          />
        </g>
        <g id="ribs">
          <path
            id="rib_1"
            data-name="rib 1"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls9"
            d="M394.84,336.95c-53.34-62.24,4.68-141.81,84.91-157.9"
          />
          <path
            id="rib_2"
            data-name="rib 2"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls9"
            d="M381.34,362.41c-96.7-101.29,24.75-196.76,98.41-213.3"
          />
          <path
            id="rib_3"
            data-name="rib 3"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls9"
            d="M391.72,397.06c-55.31-62.24,4.85-141.81,88.03-157.9"
          />
          <path
            id="rib_4"
            data-name="rib 4"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls9"
            d="M377.72,422.52c-100.26-101.29,25.66-196.76,102.03-213.3"
          />
          <path
            id="rib_5"
            data-name="rib 5"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls9"
            d="M375.24,428.31c-15.62-54.33,40.69-110.86,113.67-124.14"
          />
          <path
            id="rib_6"
            data-name="rib 6"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls9"
            d="M342.64,426.28c-15.81-76.04,81.83-138.92,146.27-152.05"
          />
          <path
            id="rib_7"
            data-name="rib 7"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls9"
            d="M398.7,427.81c18.52-29.68,54.36-53.6,95.95-61.64"
          />
          <path
            id="rib_8"
            data-name="rib 8"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls9"
            d="M366.88,427.81c24.69-47.13,84.27-82.16,127.77-91.59"
          />
          <path
            id="rib_9"
            data-name="rib 9"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls9"
            d="M398.47,268.35c-48.54-58.35,4.25-132.94,77.26-148.03"
          />
          <path
            id="rib_10"
            data-name="rib 10"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls9"
            d="M386.19,292.22c-87.98-94.96,22.52-184.46,89.54-199.97"
          />
        </g>
        <g id="upper_arm" data-name="upper arm">
          <path
            id="upper_arm_1"
            data-name="upper arm 1"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls12"
            d="M31.67,169.83c16.1-.73,32.2-1.46,48.31-2.19,17.03-.77,34.08-1.84,51.11-2.49,16.22-.62,32.05,3.28,47.83,6.44,3.15.63,6.32,1.01,9.5,1.08,16.6.37,31.99,5.09,44.7,19.35,17.1,10.86,34.27,10.22,51.51-1.21,11.3-4.13,18.86-12.88,23.81-24.86,5.25-15.11.29-32.67-8.63-51.17l-7.98-11.91c-1.48-2.21-2.66-4.64-3.25-7.23-2.38-10.37-14.1-14.29-31.56-14.25-8.91-.12-15.94.59-19.65,2.75-2.64,1.54-5.71,2.19-8.76,2.25-3.6.07-7.42,1.39-11.38,3.46l-69.81,17.71c-18.14,4.6-36.67,7.45-55.35,8.52l-65.29,3.74"
          />
          <path
            id="upper_arm_2"
            data-name="upper arm 2"
            class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls12"
            d="M166.05,130.45c27.34-6.41,53.64-10.64,69.28-20.88,5.71-3.85,11.01-6.52,15.96-8.2,8.88-2.27,17.64-3.99,23.59,5.83l5.48.7c2.67.34,5.22,1.5,7.29,3.34,5.44,4.87,6.5,9.77,5.24,14.63"
          />
          <path
            id="upper_arm_3"
            data-name="upper arm 3"
            class="Weichteile2010Fill3"
            d="M233.12,192.02c-11.07-14.25-22.41-23.94-39.73-30.92"
          />
        </g>
        <g id="outlines">
          <g id="outlines_1" data-name="outlines 1">
            <g>
              <line
                class="Weichteile2010Fill3"
                x1="356.23"
                y1="1"
                x2="350.25"
                y2="1.45"
              />
              <path
                class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls7"
                d="M337.96,2.39l-121.3,9.24c-14.52,1.11-28.85,3.88-42.72,8.25-7.44,2.34-14.57,5.57-21.29,9.53-39.52,23.3-87.45,40.75-140.53,51.62"
              />
              <path
                class="Weichteile2010Fill3"
                d="M6.08,82.25c-1.96.38-3.92.75-5.9,1.11"
              />
            </g>
          </g>
          <g id="outlines_2" data-name="outlines 2">
            <g>
              <path
                class="Weichteile2010Fill3"
                d="M17.5,213.05c2,.02,4,.05,6,.08"
              />
              <path
                class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls10"
                d="M35.71,213.36c58.16,1.34,113.22,5.8,164.01,14.55"
              />
              <path
                class="Weichteile2010Fill3"
                d="M205.72,228.97c1.97.36,3.94.72,5.9,1.09"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Ebene_2" data-name="Ebene 2">
        <g
          id="soft_parts_overlay"
          data-name="soft parts overlay"
          class="Rechtes_Schultergelenk_Schwedenstatus_Elevation_vd_stehend2010_cls6"
        >
          <path
            id="soft_parts_overlay_1"
            data-name="soft parts overlay 1"
            class="Weichteile2010Fill"
            style={{
              fill: props.colors.Weichteile2010Fill,
              stroke: props.colors.Weichteile2010Fill,
            }}
            d="M.18,83.36c57.83-10.63,110.01-28.91,152.47-53.94,6.72-3.96,13.85-7.19,21.29-9.53,13.86-4.37,28.19-7.14,42.72-8.25L356.23,1l-.09.75c-.65,5.55-5.18,9.84-10.75,10.21l-130.06,8.49c-24.64,3.9-46.76,10.61-63.94,22.85-3.76,2.68-7.58,5.27-11.59,7.55-32.45,18.47-75.5,31.17-122.33,41.81-7.28,1.65-14.67-2.3-17.29-9.29h0Z"
          />
          <path
            id="soft_parts_overlay_2"
            data-name="soft parts overlay 2"
            class="Weichteile2010Fill"
            style={{
              fill: props.colors.Weichteile2010Fill,
              stroke: props.colors.Weichteile2010Fill,
            }}
            d="M17.5,213.05c69.52.83,134.87,5.86,194.13,17.01h0c1.99-6.84-2.4-13.93-9.43-15.11-54.78-9.25-113.11-12.28-174.1-10.67-5.14.14-9.52,3.75-10.6,8.77h0Z"
          />
        </g>
        <path
          id="joint_socket_overlay"
          data-name="joint socket overlay"
          class="cavitas2010Fill"
          style={{
            fill: props.colors.cavitas2010Fill,
            stroke: props.colors.cavitas2010Fill,
          }}
          d="M309.92,89.91c-3.86,1.65-5.88,6.14-5.43,14.43.2,3.64,1.22,7.2,2.95,10.41,7.71,14.27,12.87,26.41,13.04,34.36-1.08,12.49-6.38,24.37-15.59,35.69-6.88,8.9-9.41,16.93-8.66,24.32,11.18-3.47,21.09-9.44,27.51-22.19,4.96-15.34,5.04-32.53,2.42-50.74-3.25-16.09-8.54-31.55-16.24-46.28Z"
        />
        <path
          id="joint_overlay"
          data-name="joint overlay"
          class="jointgap2010Fill"
          style={{
            fill: props.colors.jointgap2010Fill,
            stroke: props.colors.jointgap2010Fill,
          }}
          d="M292.36,146.97c5.7,33.72-21.16,55.12,1.01,62.14,12.42,3.93,31.4-16.18,31.4-51.66s-16.63-70.82-29.47-70.82-8.86,25.35-2.95,60.34Z"
        />
      </g>
    </svg>
  );
}
