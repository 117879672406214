import style from "./style.scss";

function LinkesKniegelenkInnenrotationVdoblLiegend2327(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308.01 697.61">
      <g id="underlay">
        <ellipse
          id="close_to_the_joint"
          data-name="close to the joint"
          class="gelenknahen2327Fill"
          style={{
            fill: props.colors.gelenknahen2327Fill,
            stroke: props.colors.gelenknahen2327Fill,
          }}
          cx="155.69"
          cy="356.76"
          rx="28.01"
          ry="112.08"
          transform="translate(-217.7 466.73) rotate(-82.83)"
        />
      </g>
      <g id="knee_innenrotation" data-name="knee innenrotation">
        <g id="background_layer" data-name="background layer">
          <path
            id="femur_background"
            data-name="femur background"
            class="knie_innenrotation_left2327_cls7"
            d="M176.37,0c4.01,8.09.77,13.42.62,37.58-.2,32.67,5.67,32.83,14.49,97.08,5.17,37.73,4.38,46.57,13.04,62.31,4.55,8.24,12.58,22.84,28.98,33.33,16.97,10.85,26.42,6.78,34.78,17.39,6.56,8.33,14.59,12.24,17.39,40.57,3.19,32.31-2.35,40.93-8.69,52.16-5.88,10.43-16.9,14.52-24.63,17.39-13.5,5.01-27.05,3.41-53.61,0-26.97-3.46-40.44-5.19-50.71-11.59-7.98-4.98-12.49-10.13-22-11.17-10.26-1.13-14.36,3.84-25.82,6.82-13.3,3.46-34.34,3.69-47.82-7.24-7.1-5.75-15.92-1.42-23.75-21.73-1.16-3-1.17-21.98,0-36.22l30.99-113.02c0-9.9-.75-18.88,0-28.98,2.29-30.56,2.01-49.08,2.9-66.65.57-11.43.75-5.03,4.35-44.92,1.01-11.23,1.56-18.05,2.02-22.44"
          />
          <path
            id="patella_background"
            data-name="patella background"
            class="knie_innenrotation_left2327_cls7"
            d="M59.62,163.64c-6.03,3.97-19.87-3.85-30.43-1.45-8.52,1.94-19.62,7.49-24.63,15.94-3.81,6.42-8.38,13.52,8.69,52.16,24.33,97.89,18.55,7.71,15.37,46.37"
          />
        </g>
        <g id="lower_leg" data-name="lower leg">
          <path
            id="fibula"
            class="knie_innenrotation_left2327_cls5"
            d="M269.67,697.59c.22-4.87-.85-11.29-.56-19.68.61-17.66-.59-16.14-2.27-42.24-.72-11.26-.48-25.39,0-53.61.41-23.81.88-27.95-1.88-35.86-2.35-6.71-3.81-6.93-15.5-29.34-7.13-13.66-8.81-17.87-10.14-21.73-4.06-11.82-7.36-21.49-2.9-28.98,3.68-6.17,13.04-11.59,13.04-11.59,0,0,3.19-10.01,8.69-17.39,4.45-5.96,6.03-4.33,13.04-11.59,8.58-8.88,8.62-13.79,13.04-14.49,7.22-1.13,14.59,10.84,15.94,13.04,6.85,11.13,7.68,22.97,7.24,34.78-.83,22.65-5.55,25.75-8.69,49.27-1.64,12.21-.23,10.45-1.45,53.61-.81,28.88-1.32,25.47-1.45,40.57-.25,29.08-.38,43.63,1.45,62.31,1.59,16.26,3.56,25.66,2.07,32.33"
          />
          <path
            id="tibia_1"
            data-name="tibia 1"
            class="knie_innenrotation_left2327_cls5"
            d="M78.7,697.15c.44-11.66,4.48-28.78,5.55-60.03,1.54-44.86-5.77-39.27-8.69-117.37-1.61-42.95.39-50.57-5.8-69.55-4.09-12.53-10.62-26.65-17.39-53.61-5.25-20.91-6.23-32.1,0-39.12,4.45-5,10.78-5.9,20.29-7.24,20.26-2.87,26.81,4.23,42.02,0,7.39-2.06,5.38-3.59,18.84-10.14,15.81-7.68,27.37-13.3,40.57-11.59,16.24,2.1,27.7,14.37,31.88,18.84,11.43,12.24,10.85,21.2,21.73,26.08,9.07,4.07,12.3-.87,21.73,2.9,11.5,4.61,21.78,18,21.73,33.33-.03,11.5-5.85,21.58-13.04,27.53-8.88,7.36-14.81,4.26-24.63,10.14-5.94,3.55-10.65,8.81-21.73,34.78-10.51,24.62-15.77,36.93-18.84,52.16-2.7,13.32-1.29,14.5-2.9,53.61-1.2,29.36-1.68,21.24-4.35,68.1-.97,17.16-1.45,27.77-1.95,34.75"
          />
          <path
            id="tibia_2"
            data-name="tibia 2"
            class="knie_innenrotation_left2327_cls2"
            d="M249.44,376.31c-16.78,11.22-27.04,11.01-33.33,8.69-7.65-2.81-7.93-8.22-20.29-17.39-5.84-4.35-15.2-11.3-26.98-13.04-12.64-1.87-14.63,4.23-33.88,7.24-15.72,2.46-28.37.58-42.02-1.45-13.19-1.97-24.2-5.03-32.54-7.82"
          />
          <path
            id="tibia_3"
            data-name="tibia 3"
            class="knie_innenrotation_left2327_cls3"
            d="M133.52,340.08c43.8-24.2,71-7.24,82.26,20.91"
          />
        </g>
        <g id="femur_and_patella" data-name="femur and patella">
          <path
            id="femur_1"
            data-name="femur 1"
            class="knie_innenrotation_left2327_cls6"
            d="M59.62,163.64c-5.13,6.23-5.51,11.37-8.95,24.92-3.94,15.49-5.93,23.23-9.26,28.05-3.29,4.74-6.69,7.67-9.48,14.81-.57,1.46-2.2,5.64-2.74,11.91"
          />
          <path
            id="patella_1"
            data-name="patella 1"
            class="knie_innenrotation_left2327_cls6"
            d="M29.2,243.34c-.83,9.72-2.35,27.69,8.69,39.12,4.55,4.72,13.66,7.49,31.88,13.04,22.21,6.77,24.56,5.16,30.43,10.14,10.35,8.8,9.49,19.26,18.84,21.73l14.49,11.59c14.42-4.58,27.4-21.75,31.36-24.63,11.95-8.72,6.4-24.01,7.77-43.47,1.32-18.89,2-28.59-4.35-37.67-4.43-6.35-13.1-11.48-30.43-21.73-8.59-5.09-9.77-5.17-14.49-8.69h0c-4.69-3.49-13.85-10.32-20.29-21.73-7.23-12.81-3.52-18.14-10.14-23.18-6.93-5.27-17.24-5.13-24.63-1.45-4.54,2.26-7.17,5.39-8.69,7.24"
          />
          <path
            id="patella_2"
            data-name="patella 2"
            class="knie_innenrotation_left2327_cls4"
            d="M59.62,164.76c-6.03,3.97-19.87-3.85-30.43-1.45-8.52,1.94-19.62,7.49-24.63,15.94-3.81,6.42-8.38,13.52,8.69,52.16,6.29,14.21,1.87,28.76,15.37,46.37"
          />
          <path
            id="femur_2"
            data-name="femur 2"
            class="knie_innenrotation_left2327_cls2"
            d="M59.62,164.76c0-9.9-.75-18.88,0-28.98,2.29-30.56,2.01-49.08,2.9-66.65.57-11.43.75-5.03,4.35-44.92,1.01-11.23,1.56-18.05,2.02-22.44"
          />
          <path
            id="femur_3"
            data-name="femur 3"
            class="knie_innenrotation_left2327_cls2"
            d="M176.37,1.11c4.01,8.09.77,13.42.62,37.58-.2,32.67,5.67,32.83,14.49,97.08,5.17,37.73,4.38,46.57,13.04,62.31,4.55,8.24,12.58,22.84,28.98,33.33,16.97,10.85,26.42,6.78,34.78,17.39,6.56,8.33,14.59,12.24,17.39,40.57,3.19,32.31-2.35,40.93-8.69,52.16-5.88,10.43-16.9,14.52-24.63,17.39-13.5,5.01-27.05,3.41-53.61,0-26.97-3.46-40.44-5.19-50.71-11.59-7.98-4.98-12.49-10.13-22-11.17-10.26-1.13-14.36,3.84-25.82,6.82-13.3,3.46-34.34,3.69-47.82-7.24-7.1-5.75-15.92-1.42-23.75-21.73-1.16-3-1.17-21.98,0-36.22"
          />
          <path
            id="femur_4"
            data-name="femur 4"
            class="knie_innenrotation_left2327_cls2"
            d="M59.62,164.76c-5.13,6.23-5.51,11.37-8.95,24.92-3.94,15.49-5.93,23.23-9.26,28.05-3.29,4.74-6.69,7.67-9.48,14.81-.57,1.46-2.2,5.64-2.74,11.91"
          />
          <path
            id="patella_3"
            data-name="patella 3"
            class="knie_innenrotation_left2327_cls4"
            d="M28.63,277.78c1.32,2.75,7.01,3.46,9.26,5.8,4.55,4.72,13.66,7.49,31.88,13.04,22.21,6.77,24.56,5.16,30.43,10.14,10.35,8.8,9.49,19.26,18.84,21.73l14.49,11.59c14.42-4.58,27.4-21.75,31.36-24.63,11.95-8.72,6.4-24.01,7.77-43.47,1.32-18.89,2-28.59-4.35-37.67-4.43-6.35-13.1-11.48-30.43-21.73-8.59-5.09-9.77-5.17-14.49-8.69h0c-4.69-3.49-13.85-10.32-20.29-21.73-7.23-12.81-3.52-18.14-10.14-23.18-6.93-5.27-17.24-5.13-24.63-1.45-4.54,2.26-7.17,5.39-8.69,7.24"
          />
          <path
            id="femur_5"
            data-name="femur 5"
            class="knie_innenrotation_left2327_cls2"
            d="M28.63,277.78c2.8-13.3-11.59-73.17,30.99-113.02"
          />
          <path
            id="femur_6"
            data-name="femur 6"
            class="knie_innenrotation_left2327_cls6"
            d="M29.2,243.34c2.8-13.3-24.63-44.92,30.43-79.69"
          />
        </g>
      </g>
      <g id="overlay">
        <path
          id="tibia_head"
          data-name="tibia head"
          class="Tibiakopffraktur2327Fill"
          style={{
            fill: props.colors.Tibiakopffraktur2327Fill,
            stroke: props.colors.Tibiakopffraktur2327Fill,
          }}
          d="M60.4,352.54c11.25-3.36,24.22-4.02,39.37-1.24,10.95.34,19.86-1.35,25.21-6.56l10.84-5.5c8.01,6.43,16.75,11.42,26.76,13.94l21.09,3.76,32.76,5.16c4.82,9.74,9.14,14.14,31.57,13.69-8.75,7.81-18.84,12.25-31.88,9.2-10.89-9.07-28.68-25.2-40.36-27.9-11.14-4.67-23.38-2.55-36.31,3.89-25.8,4.39-52.28-.15-79.04-8.46Z"
        />
      </g>
    </svg>
  );
}

export default LinkesKniegelenkInnenrotationVdoblLiegend2327;
