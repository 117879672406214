import style from "./style.scss";

function RechteGroszeheLinks(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178 491.57">
      <g id="big_toe_recording" data-name="big toe recording">
        <g id="big_toe" data-name="big toe">
          <path
            id="big_toe_1"
            data-name="big toe 1"
            class="a1402_Rechte_Großzehe_1Zehe_dp_cls-2"
            style={{
              fill: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-2,
              stroke: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-2,
            }}
            d="M56.94,113.55h-.12v-.24c5.68-13.13,10.29-14.07,14.19-6.27,10.52-12.65,21.16-25.3,19.03-47.65-3.66-4.26-3.78-8.63,3.67-13.6,8.27-9.58,17.38-15.25,28.49-10.17,8.63-.83,16.67-.83,13.71,12.53,8.51,4.5,9.69,10.41,5.32,17.62-15.01,7.92-24.12,19.27-12.65,41.97,3.42,10.64,1.65,18.32-3.67,23.88l-28.97,3.43-35.82-1.66c-6.5-1.53-7.8-7.92-3.19-19.86h0v.02Z"
          />
          <path
            id="big_toe_2"
            data-name="big toe 2"
            class="a1402_Rechte_Großzehe_1Zehe_dp_cls-2"
            style={{
              fill: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-2,
              stroke: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-2,
            }}
            d="M42.98,265.8v.12s.12,0,.12,0c-9.58,25.06-2.13,34.99,19.98,31.8l40.55-9.1c19.04,9.58,22.23-2.83,14.19-31.21-9.7-29.56-17.62-59.35.59-94.82,11.83-22.47,6.03-31.33-13.71-29.56l-30.03,2.25c-18.8-7.33-25.54,3.19-26.37,22.7,10.52,38.9,5.68,74.13-5.32,107.83h0Z"
          />
        </g>
        <g id="metatarsus">
          <path
            id="metatarsus_1"
            data-name="metatarsus 1"
            class="a1402_Rechte_Großzehe_1Zehe_dp_cls-1"
            style={{
              fill: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-1,
              stroke: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-1,
            }}
            d="M65.82,491.07c-4.06-46.01-14.25-91.07-29.23-135.41h.12c-7.21-30.97-8.39-55.21,14.19-53.32,15.72-2,37.12-17.85,67.98-5.67,6.15.23,10.88,5.91,14.19,15.84,24.59,6.74,25.54,20.1,1.54,40.32-15.88,51.64-14.5,95.39,3.2,131.59"
          />
          <path
            id="metatarsus_2"
            data-name="metatarsus 2"
            class="a1402_Rechte_Großzehe_1Zehe_dp_cls-1"
            style={{
              fill: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-1,
              stroke: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-1,
            }}
            d="M45.23,304.59c-6.62,1.65-6.62,3.54,0,5.67,21.28,9.82,43.98,6.74,67.51-5.67,3.54-.83,5.67-3.55,6.38-7.92"
          />
        </g>
        <path
          id="outline"
          class="a1402_Rechte_Großzehe_1Zehe_dp_cls-3"
          style={{
            fill: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-3,
            stroke: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-3,
          }}
          d="M.5,289.45l7.71-133.41c.94-16.28,3.95-32.4,9.36-47.79,10.08-28.68,25.53-55.23,46.48-79.6C89.31.77,108.83-4.9,132.91,5.07c32.14,13.31,52.81,48.63,41.43,81.49-2.08,5.99-4.78,12.14-8.05,18.42-12.14,23.33-14.68,50.34-8.2,75.83,8.43,33.15,8.8,71.82,6.69,112.18"
        />
      </g>
      <g id="overlays">
        <g
          id="big_toe_overlay"
          data-name="big toe overlay"
          class="a1402_Rechte_Großzehe_1Zehe_dp_cls-4"
          style={{
            fill: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-4,
            stroke: props.colors.a1402_Rechte_Großzehe_1Zehe_dp_cls-4,
          }}
        >
          <path
            id="big_toe_overlay_1"
            data-name="big toe overlay 1"
            class="a1402_phalangenFill"
            style={{
              fill: props.colors.a1402_phalangenFill,
              stroke: props.colors.a1402_phalangenFill,
            }}
            d="M56.66,113.86h-.12v-.24c5.68-13.13,10.29-14.07,14.19-6.27,10.52-12.65,21.16-25.3,19.03-47.65-3.66-4.26-3.78-8.63,3.67-13.6,8.27-9.58,17.38-15.25,28.49-10.17,8.63-.83,16.67-.83,13.71,12.53,8.51,4.5,9.69,10.41,5.32,17.62-15.01,7.92-24.12,19.27-12.65,41.97,3.42,10.64,1.65,18.32-3.67,23.88l-28.97,3.43-35.82-1.66c-6.5-1.53-7.8-7.92-3.19-19.86h0v.02Z"
          />
          <path
            id="big_toe_overlay_2"
            data-name="big toe overlay 2"
            class="a1402_phalangenFill"
            style={{
              fill: props.colors.a1402_phalangenFill,
              stroke: props.colors.a1402_phalangenFill,
            }}
            d="M42.7,266.11v.12s.12,0,.12,0c-9.58,25.06-2.13,34.99,19.98,31.8l40.55-9.1c19.04,9.58,22.23-2.83,14.19-31.21-9.7-29.56-17.62-59.35.59-94.82,11.83-22.47,6.03-31.33-13.71-29.56l-30.03,2.25c-18.8-7.33-25.54,3.19-26.37,22.7,10.52,38.9,5.68,74.13-5.32,107.83h0Z"
          />
        </g>
        <path
          id="basis_overlay"
          data-name="basis overlay"
          class="a1402_basisFill"
          style={{
            fill: props.colors.a1402_basisFill,
            stroke: props.colors.a1402_basisFill,
          }}
          d="M103.35,288.93c18.62,9.4,21.06-2.8,16.27-24.66-23.74-4.29-49.14-3.75-75.88.71-5.51,13.79-6.65,24.04-.91,28.71,5,4.57,13.75,4.66,23.49,3.56l37.03-8.31Z"
        />
        <path
          id="capcut_overlay"
          data-name="capcut overlay"
          class="a1402_caputFill"
          style={{
            fill: props.colors.a1402_caputFill,
            stroke: props.colors.a1402_caputFill,
          }}
          d="M118.88,296.67c-11.4-5.07-24.65-5.47-39.19-2.6l-28.79,8.27c-8.25-1.3-14.92,1.17-17.34,13.74-2.71,5.93-1.24,16.32.53,27.04,34.6,10.53,68.23,14.55,100.52,9.71,22.94-19.46,22.66-32.86-1.01-40.17-4.63-10.42-9.54-15.64-14.72-15.99Z"
        />
        <g id="joints" class="a1402_Rechte_Großzehe_1Zehe_dp_cls-4">
          <ellipse
            id="joint_1"
            data-name="joint 1"
            class="a1402_gelenkeFill"
            style={{
              fill: props.colors.a1402_gelenkeFill,
              stroke: props.colors.a1402_gelenkeFill,
            }}
            cx="90.02"
            cy="131.94"
            rx="40.31"
            ry="13.92"
          />
          <ellipse
            id="joint_2"
            data-name="joint 2"
            class="a1402_gelenkeFill"
            style={{
              fill: props.colors.a1402_gelenkeFill,
              stroke: props.colors.a1402_gelenkeFill,
            }}
            cx="79.16"
            cy="293.97"
            rx="51.17"
            ry="16.03"
          />
        </g>
      </g>
    </svg>
  );
}

export default RechteGroszeheLinks;
