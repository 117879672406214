import {
  ADD_FACILITY_SELECTED_ID,
  CLEAR_SELECTED_FACILITIES,
  ADD_NEW_PRICE,
  REPLACE_NEW_PRICES,
  ADD_PRICE_INFO,
  ADD_DEFAULT_PRICES,
  EMPTY_NEW_PRICES,
  SET_CHANGED_PRICES,
} from "../actionTypes";

const initialState = {
  facility: {},
  priceInfo: {},
  entirePriceInfoLoaded: false,
  newPrices: {},
  defaultPrices: [],
  changedPrices: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_FACILITY_SELECTED_ID: {
      const { content } = action.payload;
      let facilities = { ...state.facility };
      try {
        if (facilities.hasOwnProperty(content.id)) {
          delete facilities[content.id];
          return {
            ...state,
            facility: facilities,
          };
        } else {
          facilities[content.id] = { name: content.name };
          return {
            ...state,
            facility: facilities,
          };
        }
      } catch {
        return {
          ...state,
        };
      }
    }

    case CLEAR_SELECTED_FACILITIES: {
      return {
        ...state,
        facility: {},
      };
    }

    case ADD_NEW_PRICE: {
      const { content } = action.payload;
      let newPrices = { ...state.newPrices };
      newPrices[content.id] = content.newPrice;
      return {
        ...state,
        newPrices,
      };
    }
    case REPLACE_NEW_PRICES: {
      const { content } = action.payload;
      let newPrices = { ...state.newPrices };
      for (let i = 0; i < Object.keys(content).length; i++) {
        newPrices[Object.keys(content)[i]] = content[Object.keys(content)[i]];
      }
      return {
        ...state,
        newPrices: newPrices,
      };
    }
    case ADD_PRICE_INFO: {
      const { content } = action.payload;
      let priceInfo = { ...state.priceInfo };
      let entirePriceInfoLoaded = content.entirePriceInfo;
      priceInfo[content.id] = {
        name: content.name,
        price: content.price,
        facilities: content.facility,
      };
      return {
        ...state,
        priceInfo,
        entirePriceInfoLoaded,
      };
    }
    case ADD_DEFAULT_PRICES: {
      const { content } = action.payload;
      return { ...state, defaultPrices: content };
    }
    case EMPTY_NEW_PRICES: {
      return {
        ...state,
        newPrices: {},
        priceInfo: {},
        entirePriceInfoLoaded: false,
      };
    }
    case SET_CHANGED_PRICES: {
      const { content } = action.payload;
      return { ...state, changedPrices: content };
    }

    default:
      return state;
  }
}
