import moment from "moment";

export default function DebugLogger(level, msg) {
  const level_factory = (lvl) => {
    let log = console.log.bind(window.console);
    if (lvl === "debug") {
      return {
        method: console.log,
        prefix: "DEBUG",
        style: "color:white; background-color:#000",
      };
    }
    if (lvl === "info") {
      return { method: log, prefix: "INFO", style: "color:green" };
    }
    if (lvl === "warn") {
      return { method: log, prefix: "WARN", style: "color:yellow" };
    }
    if (lvl === "error") {
      return { method: log, prefix: "ERROR", style: "color:red" };
    }
    return { method: log, prefix: "LOG", style: "color:blue" };
  };

  if (window.conf.DEBUG_MODE) {
    let { method, prefix, style } = level_factory(level);
    method(
      `%c${prefix} | ${msg} | ${moment().format("MM/DD/YYYY HH:mm:ss.SS")}`,
      style
    );
  }
}
