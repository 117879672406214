import style from "./style.scss";

function LinkesDaumenSattelGelenkDpSitzend1311(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 569.8 810"
    >
      <g id="thaumbsattle">
        <g id="Thumbbone">
          <path
            id="Thumbboneparts3"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M458.9,359c-5.8,4.2-14,2.6-22,1-10.6-2.1-17.8-6.7-23-10-10.9-7-19.9-12.8-21-22-.7-5.5,1.7-9.9,6-18,4-7.5,7.6-11.4,12-17,7-8.9,11-15.9,18-28,8.4-14.6,12.6-21.9,15-31,2.7-10.5,1.2-13.6,4-28,3.9-19.9,5-23.6,6.5-24.7,0-.1.2-.2.5-.3,8.4-4.1,18.8,6.4,34,14,17.2,8.6,27.3,6.9,28,12,.7,4.6-7.6,6.5-17,17-7.4,8.3-10.6,16-16,29-1.4,3.4-6.8,16.7-11,37-4.7,22.8-7,44-7,44-1.5,14.3-1.5,21.1-7,25Z"
          />
          <path
            id="Thumbboneparts2"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M440.9,391c-5.3,5.6-9.9,7.3-14,10-12.2,7.8-14.1,19.4-23,40-3.2,7.5-12.3,27.7-26,48-14.8,21.9-25.6,29.9-23,42,2.1,9.8,11,13.5,9,20-2.3,7.3-15.8,9.2-22,10-6.1.8-21.7,2.7-37-7-3.6-2.3-20.4-12.8-20-27,.2-6.5,3.9-9.8,11-20,4.7-6.8,1.2-2.7,19-34,19.1-33.7,18.2-29.7,25-43,10.5-20.7,15.8-31,19-45,5.2-22.6,1.8-36.5,13-47,2.5-2.4,9-9.2,16-8,10.5,1.8,6.5,22.4,22,29,12.6,5.3,36.8,1,41,10,3.5,7.5-8.4,20.4-10,22Z"
          />
          <path
            id="Thumbboneparts1"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M520.9,196c2.4-1.8,2.9-7.2,4-18,1.2-12.5,1.8-18.8,0-24-2.4-6.8-5.5-7.7-5-12,.8-6,7.2-6.2,11-14,.5-1.1,4.7-10.1,0-18-3.5-5.8-11-9.8-17-8-7.5,2.2-10.2,13-12,20-2.1,8.3-.3,9.8-3,14-2.9,4.5-5.5,3.5-16,11-6.9,4.9-6.6,6-11,8-5.9,2.8-8.9,2.1-11,5-2.3,3.2-2,8.4,0,12,3.1,5.6,8.6,4.5,20,10,9.8,4.7,8.9,7,18,11,6.6,2.9,16.5,7.2,22,3Z"
          />
        </g>
        <g id="forearm">
          <path
            id="forearmparts4"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M270.8,723.3c-26.9,44.9-53.9,89.8-80.9,134.7-8.4,1.3-61.8,9.3-67-5-3.5-9.5,15.2-27.5,25-37,12.2-11.8,15.8-11.2,26-23,7.9-9.2,8.7-13.1,19-26,6.9-8.7,14-16.2,18-20,6.9-6.4,24.3-20.1,59.9-23.7Z"
          />
          <path
            id="forearmparts3"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M283.9,516c-.9-2.3-4.1-10.5-12-13-9.1-2.8-17.3,4.4-18,5-8.1,7.2-7.2,18-7,20,.3,3.3,3.2,8.9,9,20,5.1,9.6,7,12.5,8,14,5.2,7.5,7.8,11.3,12,13,6.7,2.7,9.2-2,18,0,7.8,1.8,7.5,5.8,16,8,4.4,1.1,8.6.8,17,0,6.1-.5,10.2-1.4,18-3,15.8-3.3,17.3-4.9,18-6,3.9-6.1,1.2-16.8-3.1-18.1-2.4-.7-4.2,1.9-7.9,4.1-8.8,5.3-19.1,3.1-28,1.1-15-3.3-24.3-11.7-28-15.1-4.1-3.7-6.9-6.3-8.9-11-3.2-7.6,0-11.2-3.1-19h0Z"
          />
          <path
            id="forearmparts2"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M294.9,682c2.4-6.7,6.7-5.4,11-13,4.1-7.2,5.9-18.5,1-26-3.8-5.8-8.9-5.1-11-10-1.6-3.8.7-7,7-20,4.7-9.7,7.3-13.8,8-21,.3-3.3.5-6.1-1-9-3.5-6.6-12.9-7.7-16-8-6.3-.7-6.6,1.1-13,1-3.1,0-5.9-.5-25-8-11.1-4.4-12-4.9-15-5-6.4-.2-8.8,2.3-15,1-5.9-1.2-7.8-4.2-10-3-2.8,1.5-2.8,8.1-1,13,2,5.7,5.4,6.2,8,12,1.8,3.9,1.7,6.7,2,12,1.3,20.3,5.7,23.2,3,30-2.5,6.2-7.8,8.1-16,13,0,0-4.6,2.7-33,28-24.1,21.5-23.5,39.1-49,72-30.8,39.7-52.3,40.5-53,62-.8,23.5,24,48.3,40,46,7.2-1,8.3-6.9,26-26,16.4-17.8,17.2-14.6,30-29,20.1-22.6,17.2-29.2,33-41,8.5-6.3,17.2-10.2,30-16,14.5-6.6,21.8-9.8,29-12,9-2.7,14.8-3.5,21-9,2.2-2,7-6.2,9-13,2.7-9.2-2.8-13,0-21Z"
          />
          <path
            id="forearmparts1"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M344.9,580.1c5,2.5,5.9,9.8,6,11,.1.6.8,7.8-4,13-3.3,3.6-6.5,3.3-16,6-11.3,3.2-16.9,4.7-20,8-7.2,7.6-4.1,19.2-9,20.3-2.2.5-4.8-1.3-6-3.3-1.7-2.8-.7-6.5,7-22,6.5-13,8.5-15.7,8-21-.4-4.7-2.5-7-1-9,2.1-2.8,7.3.7,16.7,0,10.3-.7,13.5-5.5,18.3-3Z"
          />
        </g>
        <g id="fingerboneparts">
          <path
            id="fingerboneparts6"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M283.9,516.1c1.8-1-.9-12.4-8.1-24-6.8-11.2-12.5-13.8-20-23-7.4-9-10.6-17-16-31-18-46.4-20-93-20-93-1.2-28.5-2.2-52.4,8-80,2.6-6.9,7.7-19,13-38,9.9-35.3,6-42.3,4-45-7.7-10.4-26.4-12.8-38-7-4.5,2.2-3.9,3.6-19,19-12.3,12.5-13.4,12.2-15,16-4.8,11.1,3,16.2,1,32-1.8,13.8-8.5,15.1-11,29-2.2,12.5,1.3,22.3,4,31,6.7,21.5,8.7,44.1,14,66,28.7,118.3,18.2,82.2,21,92,5.4,18.8,10.6,34.3,6,53-1.4,5.8-3.4,10.3-3,18,.8,13.7,8.8,29.9,21,33,5.7,1.5,6.6-1.4,15-1,16.2.7,25.8,11.8,27.7,9.8,2.1-2.2-10.4-13.2-17.2-33.6-2.8-8.5-6-18.7-.9-27.2,3-5,9.2-10,15.7-9.6,10.3.7,15.8,14.7,17.8,13.6h0Z"
          />
          <path
            id="fingerboneparts5"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M294.9,703c-17-41-23.7-82-48.1-82"
          />
          <path
            id="fingerboneparts4"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M246.8,621s-8.5,7-18.9,7"
          />
          <path
            id="fingerboneparts3"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M263.6,571c0,19.9-14,37.9-37.5,37.9"
          />
          <path
            id="fingerboneparts2"
            class="daumensattelgelenk_dp_left1311_cls1"
            d="M247.3,564.6c0,18.5-10,39.4-22.4,39.4"
          />
          <path
            id="fingerboneparts1"
            class="daumensattelgelenk_dp_left1311_cls3"
            d="M162.9,280c0,5,33.8-34,60.4,0"
          />
        </g>
        <path
          id="outlines"
          class="daumensattelgelenk_dp_left1311_cls2"
          d="M70.6,863c-36.1-40.5-35.9-109.1-10.5-142,3.2-4.2,12.6-13.4,31.5-32,27.3-26.8,31.4-28.7,44-43,20.1-22.8,31.5-42.5,34-47,9.4-16.6,18.7-33,23-56,3.9-21,1.7-36.9-3-68-4.1-27.3-6.9-31.7-15-70-13.3-62.9-6.7-55.2-16-88-3.4-12-12.4-41.6-15-75-.5-6.1-1-15.9,3-27,4.8-13.3,13.1-19.9,23-30,3.2-3.3,13.5-16.5,34-43,19.6-25.3,29.4-37.9,37-50,10.3-16.3,17-29.7,33-47,9.3-10.1,14.5-13.6,20-16,12.1-5.2,20.6-2.5,40-2,17.4.4,13.1-1.7,44-3,23.5-1,35.6-1.4,52,1,13.2,1.9,23.5,3.4,36,9,10.7,4.7,14.4,8.6,29,14,10.5,3.9,12.8,3.5,14,6,5.2,11-23,55-56,46-11.3-3.1-16.8-6.9-32-5-8.4,1.1-8.7,3.2-25,7-11.9,2.8-17.9,4.1-26,4-13-.2-16.5-4-22-1-5.9,3.2-4.8,8.9-10,21-2.9,6.8-11,23.1-26,34-11.5,8.4-19.3,7.7-20,13-.9,6.4,10.1,9,24,24,6.7,7.3,10.8,14.2,19,28,7.8,13.2,11.7,19.8,13,26,2.2,10.7-.1,17.4,5,22,5,4.5,12.7,3.2,14,3,10.9-1.8,14.1-10.9,34-39,12.5-17.7,16.8-22,20-33,2.8-9.6.8-11.1,3-22,1.8-8.9,4.6-15.7,20-42,12-20.6,18-30.9,24-39,12.5-16.9,18.9-25.5,30-31,5.2-2.6,20.7-10.3,38-4,1.7.6,17,6.4,24,22,3.9,8.6,3.9,17.3-3,45-5.8,23.3-9,36.3-18,59-2.3,5.8-5.3,13-25,54-22,45.8-24,48.6-29,62-8.1,21.6-12.4,39.2-16,54-5.1,20.9-7.7,36.3-11,57-6.1,37.6-5.1,46.2-11,77-5.6,28.9-9,46.7-19,69-9.3,20.8-12.6,19.4-31,53-17.7,32.4-14.9,34.2-29,58-16.5,27.9-20,24.6-38,55-17,28.5-12.8,29.6-28,52-7.5,11-15.3,20.9-22,38-4.8,12.3-5.9,20.3-7,24.1-10.6,36.2-140.9,86.3-202,17.9Z"
        />
        <path
          id="thaumbsattlepart9"
          class="daumensattelgelenk_dp_left1311_cls4"
          d="M252.6,242c17.7,5.1,32,8.7,42,11,9.8,2.3,17.1,3.8,28,7,11.6,3.4,20.9,6.7,27,9"
        />
        <path
          id="thaumbsattlepart8"
          class="daumensattelgelenk_dp_left1311_cls4"
          d="M301.6,248c-5.6-10.2-9.6-17.5-12-22-3.4-6.2-5.3-9.8-9-14-4.3-4.9-8.8-8.1-12-10"
        />
        <path
          id="thaumbsattlepart7"
          class="daumensattelgelenk_dp_left1311_cls4"
          d="M291.6,300c12.6,1.8,23,1,30,0,2.6-.4,9-1.4,17-4,6.4-2.1,12.8-4.1,20-9,3.5-2.4,8.3-6.2,13-12"
        />
        <path
          id="thaumbsattlepart6"
          class="daumensattelgelenk_dp_left1311_cls4"
          d="M557.6,87c3.3,15.7,1.7,27.8,0,35-1.7,7.4-4.1,18-11,20-2.8.8-5.2-.1-15-6-26.2-15.6-23-17-33-21-4.6-1.8-8.8-2.9-11-7-2.3-4.3-1.3-9.4,0-13"
        />
        <path
          id="thaumbsattlepart5"
          class="daumensattelgelenk_dp_left1311_cls3"
          d="M451.5,329.9c-2.9-32.9,11.5-46.8,31.2-96.3"
        />
        <path
          id="thaumbsattlepart4"
          class="daumensattelgelenk_dp_left1311_cls3"
          d="M411.7,306c15,0,32.1-39.7,49.2-81"
        />
        <path
          id="thaumbsattlepart3"
          class="daumensattelgelenk_dp_left1311_cls3"
          d="M402.6,329.9c19.6,0,35.5,9.9,35.5,22.1"
        />
        <path
          id="thaumbsattlepart2"
          class="daumensattelgelenk_dp_left1311_cls3"
          d="M346.6,533c.3-14.6,3.3-26.5,7-36,4.5-11.5,6.8-11.2,21-37,10.5-19.2,15.8-28.7,17-33,2.2-7.8,3.2-15.4,10-21,3.8-3.1,6.4-3.2,12-7,6.3-4.3,10.5-9.4,13-13"
        />
        <path
          id="thaumbsattlepart1"
          class="daumensattelgelenk_dp_left1311_cls3"
          d="M305.6,509c6.3-5.1,15-13.2,23-25,5.5-8.1,8.6-14.9,14-27,9.3-20.7,7.5-21.7,14-34,7.8-14.7,11.8-15.8,19-30,5.6-10.9,8.4-20.6,10-27"
        />
      </g>
      <g id="Highlights">
        <path
          id="Highlights1"
          class="daumensattelgelenk1311fill"
          style={{
            fill: props.colors.daumensattelgelenk1311fill,
            stroke: props.colors.daumensattelgelenk1311fill,
          }}
          d="M354.9,531c7.2,33.7-51.4,27-67.4-12.7-50.1,58,124.3,78.9,67.4,12.7Z"
        />
      </g>
    </svg>
  );
}

export default LinkesDaumenSattelGelenkDpSitzend1311;
