import { useState, useRef } from "react";
import { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import { useExaminations } from "../../Utilities/FetchHooks/Ris/RisHooks";

import "./styles/ExamList.scss";
import { ExamPlannerExaminationImage } from "../ExamPlannerV3/Components/ExamPlannerImage";

function ExamList({
  selectHandler,
  selected,
  pageSize = 10,
  dark = false,
  showImages = true,
}) {
  const [pageNr, setPageNr] = useState(1);
  const [examsFilter, setExamsFilter] = useState(undefined);
  const examsQuery = useExaminations({ pageSize, pageNr, filter: examsFilter });
  const examsFromDb = examsQuery.data;

  const designationIputRef = useRef(null);
  const updateFilter = (fromFirstPage = false) => {
    function capitalizeFirstLetter(string) {
      return string[0].toUpperCase() + string.slice(1);
    }

    fromFirstPage && setPageNr(1);
    let filter = undefined;
    if (designationIputRef.current && designationIputRef.current.value !== "") {
      filter = {
        or: [
          {
            field: "designationde",
            op: "like",
            value: `%${designationIputRef.current.value}%`,
          },
          {
            field: "designationde",
            op: "like",
            value: `%${designationIputRef.current.value.toLowerCase()}%`,
          },
          {
            field: "designationde",
            op: "like",
            value: `%${designationIputRef.current.value.toUpperCase()}%`,
          },
          {
            field: "designationde",
            op: "like",
            value: `%${capitalizeFirstLetter(
              designationIputRef.current.value
            )}%`,
          },
        ],
      };
    }
    setExamsFilter(filter);
  };

  useEffect(() => {
    if (selected) {
      setFilterValue(selected.designationde);
    }
  }, []);

  const [filterValue, setFilterValue] = useState("");

  return (
    <>
      <div className="mb-2 d-flex flex-column">
        <div className="d-flex">
          <input
            className="exam-filter-input"
            type="text"
            ref={designationIputRef}
            placeholder="i.e. Hand"
            onChange={(e) => {
              if (e.target.value === "") {
                updateFilter(true);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateFilter(true);
              }
            }}
            defaultValue={filterValue}
          />
          <Button
            size="xs"
            onClick={() => {
              updateFilter(true);
            }}
          >
            <i className="fas fa-search fa-sm" />
          </Button>
          <Button onClick={() => examsQuery.refetch()} className="ml-auto">
            <i title="Close" className="fas fa-redo fa-sm" />
          </Button>
        </div>
        <div className="input-label">Search by description</div>
      </div>
      {examsFromDb?.examinations?.length > 0 && (
        <>
          <div className="d-flex flex-column exam-h-list">
            {examsFromDb.examinations.map((exam, key) => (
              <div
                onClick={() => {
                  selectHandler(exam);
                }}
                className={`exam-h-row mb-1 ${
                  selected && selected.id === exam.id && "selected"
                } ${dark && "dark"} ${showImages && "extended"}`}
                key={`harmonizerExaminationKey${key}`}
              >
                <div className="element border-right">
                  <div>{exam.designationde}</div>
                  <div className="info-text">Examination description</div>
                </div>
                <div className="element border-right">
                  <div>{exam.cgyqm}</div>
                  <div className="info-text">Optimal dose value (uGy)</div>
                </div>
                <div className="element border-right">
                  <div>{`from: ${exam.kvfrom} to: ${exam.kvtill}`}</div>
                  <div className="info-text">Optimal kV range</div>
                </div>
                <div className={`element ${showImages && "border-right"}`}>
                  <div>{`from: ${exam.masfrom} to: ${exam.mastill}`}</div>
                  <div className="info-text">Optimal mAs range</div>
                </div>
                {showImages && (
                  <div className="border-right d-flex justify-content-center align-items-center">
                    <ExamPlannerExaminationImage
                      examId={exam.id}
                      width="50px"
                      style={{ maxHeight: "70px" }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-between mt-2">
            <div>
              <Button
                className="mr-1"
                disabled={pageNr === 1}
                onClick={() => setPageNr(1)}
              >
                <i className="fas fa-angle-double-left fa-sm" />
              </Button>
              <Button
                disabled={pageNr === 1}
                onClick={() => setPageNr(pageNr - 1)}
              >
                <i className="fas fa-angle-left fa-sm" />
              </Button>
            </div>
            <div className="border rounded p-1 bg-secondary text-white">
              {pageNr} / {Math.ceil(examsFromDb.total_count / pageSize)}
            </div>
            <div>
              <Button
                className="mr-1"
                disabled={examsFromDb.total_count < pageSize * pageNr}
                onClick={() => setPageNr(pageNr + 1)}
              >
                <i className="fas fa-angle-right fa-sm" />
              </Button>
              <Button
                disabled={
                  examsFromDb.total_count < pageSize * pageNr ||
                  pageNr === Math.ceil(examsFromDb.total_count / pageSize)
                }
                onClick={() =>
                  setPageNr(Math.ceil(examsFromDb.total_count / pageSize))
                }
              >
                <i className="fas fa-angle-double-right fa-sm" />
              </Button>
            </div>
          </div>
        </>
      )}
      {examsQuery.status === "loading" &&
        examsFromDb?.examinations.length === 0 && (
          <div className="d-flex flex-column justify-items-center align-items-center">
            <ClipLoader size={60} />
            <div>Loading Examinations</div>
          </div>
        )}
      {examsQuery.status === "success" && !examsFromDb && (
        <div className="d-flex flex-column justify-items-center align-items-center">
          No Examinations found
        </div>
      )}
    </>
  );
}

export default ExamList;
