import style from "./style.scss";

export default function LinkesHuftlochVdoblLiegend2406(props) {
  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 378.8 515.2">

  <path id="BG" class="Linkes_Huftloch_vd_obl_liegend2406_cls6" d="M100.4,5.2c4.4,2.4,6.5,6.3,13,23,11.1,28.6,16.6,42.9,21,61,3.7,15.1,13.4,55,30,91,4.9,10.6,19.7,41.1,48,74,8.9,10.4,32.4,36.3,69,60,24.4,15.8,37.9,18.2,54,25,26,11,35-2,40,3s3.1,17.2,0,31c-.7,3.1-3.6,15.3-12,29-5.8,9.4-12,15.9-15,19-5.8,6-7.2,6.2-13,12-9.6,9.6-11.2,14.5-17,21-5.2,5.9-11.2,10-29,18-25.8,11.6-41.5,18.7-66,24-18.1,3.9-38.4,8.3-65,5-19.3-2.4-39.1-4.8-51-20-2.1-2.7-5.7-12.5-13-32-6.4-17.1-7.4-22.1-6-28,1.3-5.5,3.8-8.7,5-17,.8-5.5-.3-6.3,0-14,.4-12,2.7-13.4,2-19-1.1-8.8-7.6-12.2-14-20-6.3-7.6-8.8-16.7-14-35-6-21.4-10.8-38.2-8-59,2.7-20.5,9.4-19.6,12-39,2.2-16.1-1.9-30.1-10-58-5.5-18.9-11.7-39.6-26-64-10.1-17.2-15.7-16.7-17-25"/>
  <g id="underlay" class="Linkes_Huftloch_vd_obl_liegend2406_cls7">
    <path id="Beckenschaufel_overlay" data-name="Beckenschaufel overlay" class="Beckenschaufel2406Fill" style= {{
              fill: props.colors.Beckenschaufel2406Fill,
              stroke: props.colors.Beckenschaufel2406Fill,
              opacity:props.colors.Beckenschaufel2406Fill,
            }} d="M61.2,259c-1.2-.8-.8-4,3.7-17.2,3.1-8.9,4.7-13.4,8.2-21.6,2.2-5.1,5-15.3-17.2-80.6-7.5-22-10.2-27.8-13.4-33.6-6.2-10.9-13.2-19.8-22.4-31.3C3.1,53.2.4,53.4,0,48.5-2.1,21.3,79.9-11.2,100,3.8c5,3.7,9,9,9,9,6.5,8.6,8.2,17.2,9,20.9,2.6,11.9.4-6,14.9,44,12.9,44.3,9.2,39.8,16.4,61.2,13.1,38.9,25.2,56.8,30.6,68.7,2.2,2.1,7.9,7.1,8.2,14.2.2,5.6-3.1,10-9.7,18.7-6.3,8.3-9.5,12.4-13.4,13.4-7.4,1.9-11.7-4.3-24.6-9-10-1.7-19.3-3.7-26.1-3.7-9.2,0-25.6,0-40.3,9.7-6.8,4.5-10.9,9.4-12.7,8.2h0Z"/>
    <path id="Foramen_highlight" data-name="Foramen highlight" class="Foramen_obturatum2406Fill" style= {{
              fill: props.colors.Foramen_obturatum2406Fill,
              stroke: props.colors.Foramen_obturatum2406Fill,
              opacity:props.colors.Foramen_obturatum2406Fill,
            }} d="M184.4,383.6c6.6-10.4,9.9-10.7,12.7-18.7,1.4-3.9,1.7-6.7,4.5-9,3.2-2.5,7-2.3,10.4-2.2,8.6,0,14.9-1.2,27.6-3.7,3-.6,8-1.9,17.9-4.5,12.4-3.2,13.8-3.8,16.4-3,7.3,2.3,11.9,8.6,16.5,11.4,4.2-.4,2.4,1.4,8.8,2.8,7.3,1.6,11.3,2.5,12.7,5.2,1.4,2.8-1,5.7-6.7,15.7-2.2,3.9-6.8,11.9-10.5,20.1-4.9,10.9-3.6,12.2-7.5,17.9-5.3,7.9-12,12.1-14.2,13.4-5.7,3.3-8.2,3-23.9,7.5-13,3.7-11.1,3.8-17.2,5.2-11.1,2.5-20.6,4.7-31.3,1.5-3.6-1.1-15-4.5-21.6-15.7-7.1-11.9-3.8-24.5-3-27.6,1.5-5.9,4.2-10.1,8.2-16.4h0Z"/>
  </g>
  <g id="Left_Hip" data-name="Left Hip">
    <path id="l_hip_16" data-name="l hip 16" class="Linkes_Huftloch_vd_obl_liegend2406_cls3" d="M377.1,373.2c-.7,3.1-3.6,15.3-12,29-5.8,9.4-12,15.9-15,19-5.8,6-7.2,6.2-13,12-9.6,9.6-11.2,14.5-17,21-5.2,5.9-11.2,10-29,18-25.8,11.6-41.5,18.7-66,24-18.1,3.9-38.4,8.3-65,5-19.3-2.4-39.1-4.8-51-20-2.1-2.7-5.7-12.5-13-32-6.4-17.1-7.4-22.1-6-28,1.3-5.5,3.8-8.7,5-17,.8-5.5-.3-6.3,0-14,.4-12,2.7-13.4,2-19-1.1-8.8-7.6-12.2-14-20-6.3-7.6-8.8-16.7-14-35-6-21.4-10.8-38.2-8-59,2.7-20.5,9.4-19.6,12-39,2.2-16.1-1.9-30.1-10-58-5.5-18.9-11.7-39.6-26-64C27,79,4.3,59.1,3,50.8"/>
    <path id="l_hip_15" data-name="l hip 15" class="Linkes_Huftloch_vd_obl_liegend2406_cls3" d="M102.1,5.2c4.4,2.4,6.5,6.3,13,23,11.1,28.6,16.6,42.9,21,61,3.7,15.1,13.4,55,30,91,4.9,10.6,19.7,41.1,48,74,8.9,10.4,32.4,36.3,69,60,24.4,15.8,37.9,18.2,54,25,26,11,35-2,40,3"/>
    <path id="l_hip_14" data-name="l hip 14" class="Linkes_Huftloch_vd_obl_liegend2406_cls3" d="M73.6,515.2c0-8,.4-19.9,3-34,6.4-35.1,21.1-58.7,29-71,8.2-12.8,13.9-21.6,25-29,5.8-3.8,12.5-7,23-12,16.9-8,18.7-7.4,23-11,11.5-9.8,12.5-26.2,13-34,1.9-31-18.6-52.6-20-54-20.4-20.7-46.3-21.2-54-21-4.6.1-25.1,1.1-44,16-5.8,4.6-19,15-23,32-3.2,13.6-1,25-1,25,1.2,6.1,2.7,8.2,2,13-1.5,10.8-11.4,16.7-12,17-2.7,1.5-7.2,4.2-13,3-7.9-1.6-11.5-9-12-10"/>
    <path id="l_hip_13" data-name="l hip 13" class="Linkes_Huftloch_vd_obl_liegend2406_cls3" d="M314.6,354.2c-2.8,13.4-7.3,22.3-11,28-2,3.1-4,5.6-7,11-4.4,7.9-4.9,11.1-8,17-.6,1.1-5.2,9.7-12,16-7.2,6.7-15.2,8.9-31,13-16.2,4.2-24.3,6.3-35,5-8-1-18.8-2.3-27-11-8.7-9.2-11.8-23.9-8-35,.9-2.8,6.3-10.5,17-26,2.9-4.1,4.7-6.7,6-11,2.1-7,.4-10.7,0-20-.2-5.6.4-3.9,2-25,1.3-17,1-19.3,0-22-2.2-6-5.8-8.6-14-16-18.4-16.8-14.7-17.4-23-23-12.5-8.5-25.2-10.1-41-12-11.9-1.4-19.8-2.4-30,0-16.1,3.8-26.8,13.5-32,19"/>
    <polyline id="l_hip_12" data-name="l hip 12" class="Linkes_Huftloch_vd_obl_liegend2406_cls3" points="262.6 332.2 286.6 351.2 303.6 358.2 333.6 370.2 355.6 370.2 374.1 366.5"/>
    <polyline id="l_hip_11" data-name="l hip 11" class="Linkes_Huftloch_vd_obl_liegend2406_cls3" points="294.6 343.2 262.6 343.2 233.6 351.2 199.6 355.8"/>
    <polyline id="l_hip_10" data-name="l hip 10" class="Linkes_Huftloch_vd_obl_liegend2406_cls3" points="286.6 351.2 294.6 365.2 303.6 382.2"/>
    <polyline id="l_hip_9" data-name="l hip 9" class="Linkes_Huftloch_vd_obl_liegend2406_cls3" points="82.6 249.2 107.6 260.2 138.6 270.2 164.6 281.2 176.6 298.2 182.6 315.7 198.6 341.2"/>
    <path id="l_hip_8" data-name="l hip 8" class="Linkes_Huftloch_vd_obl_liegend2406_cls3" d="M92.6,371.2c0,8.8,8.5,16,19,16"/>
    <path id="l_hip_7" data-name="l hip 7" class="Linkes_Huftloch_vd_obl_liegend2406_cls3" d="M97.6,349.5c0,12-2.2,21.8-5,21.8"/>
    <path id="l_hip_6" data-name="l hip 6" class="Linkes_Huftloch_vd_obl_liegend2406_cls3" d="M23.6,466.2c18.1-23.7,38.7-10.3,45,32"/>
    <path id="l_hip_5" data-name="l hip 5" class="Linkes_Huftloch_vd_obl_liegend2406_cls2" d="M11.6,38.2c8.2,8,19,19,31,33,8.6,10,16.9,19.7,26,33,12.9,18.9,14.3,26.2,26,46,6.3,10.7,16.3,26.2,31,44"/>
    <path id="l_hip_4" data-name="l hip 4" class="Linkes_Huftloch_vd_obl_liegend2406_cls2" d="M73.6,10.2c1.9,22.1,2.2,39.9,2,52-.1,10.9.5,25.8,1.7,55.8.3,8.5,1.1,22.4,10.3,32.2,5.1,5.4,9.7,6.3,10.9,6.4,6.5,1,11.8-2.7,17.1-6.4,6.3-4.5,9.3-8.4,19-20,3.4-4,7.9-9.4,12-14,11.7-13.2,13.5-13.1,30-30,11.2-11.5,12.3-13.5,17-15,7.6-2.4,17.9-1.2,26,3,7.4,3.8,9.6,8.2,16,8,.3,0,7.3-.3,10-5,2.3-4-.8-7.3-1-14-.2-8.4,4.4-14.8,8-20,6-8.5,9.9-8.3,16-16,5.2-6.7,7.7-13.7,9-19"/>
    <path id="l_hip_3" data-name="l hip 3" class="Linkes_Huftloch_vd_obl_liegend2406_cls2" d="M288.6,9.2c-2.2,4.7-5.7,12-10,21-4.2,8.7-7,14.2-10,22-4.8,12.3-8.2,21.2-5,24,2.3,2,6.8-.3,12-2,7.9-2.6,19.6-4.2,36,0"/>
    <path id="l_hip_2" data-name="l hip 2" class="Linkes_Huftloch_vd_obl_liegend2406_cls2" d="M118.6,1.2c1.9,6.9,4,17.3,4,30,0,15-3.1,26-7,40-1.9,6.8-5.8,19.6-13,35-3.6,7.7-7.1,14.1-10,19"/>
    <polyline id="l_hip_1" data-name="l hip 1" class="Linkes_Huftloch_vd_obl_liegend2406_cls1" points="111.6 418.2 117.6 426.2 125.6 426.2 125.6 433.2 133.6 433.2 133.6 444.6 133.6 454.6 140.6 464.2 140.6 476.4 145.6 489.2"/>
  </g>
</svg>
  );
}
