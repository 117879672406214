import style from "./style.scss";

function Lendenwirbelkorper4VdLiegend1715(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 183.7 122.2">

<g id="BG">
  <path id="BG2" class="Lendenwirbelsaule4_ap1715_cls2" d="M49.2,88.7c.8-7.3,24.6-10.2,30.8-10.9,9-1.1,18.7-2.2,30.8.7,12.1,2.9,26.2,8.1,25.4,12.9-.2,1-7.7,7.9-31.4,8.8-.1,0-.2,0-.4,0-26.8,3-30.8-1.9-44.3-6.5-7.7-1.1-12.2-1.9-10.9-5.1Z"/>
  <path id="BG1" class="Lendenwirbelsaule4_ap1715_cls8" d="M55.6,36.2c2.1-5.6,4.2-15.7,5.5-16.8,10-8.9,29.9-9,39.6-2.3,3.7,2.5,10.5-.3,15.9,0,12.3.7,10.2-1.8,12.7.2,3.6,2.9-2.9,20.5-7.5,26.3-4.3,3.4-28.9,2.6-66.2-7.5h0Z"/>
</g>
<g id="Anatomy">
  <path id="Anatomy32" class="Lendenwirbelsaule4_ap1715_cls5" d="M60.3.2c-.6,10.9-7.9,18.5-5,21.1,1.5,1.3,4.2,0,8.2-1.2,7.4-2.3,14.8-2.4,29.5-2.4,12.3-.1,18.5-.1,26.1,1.8,9.8,2.4,15.4,6.1,18.5,3.3,2.6-2.3,1.6-7.7.9-10.9-1-5.2-3.2-8-4.9-10.4"/>
  <path id="Anatomy31" class="Lendenwirbelsaule4_ap1715_cls5" d="M63.4.2l4.4,4.8c-.3-1.4-.6-3.2-1.3-4.7-1.1,0-2.1,0-3.1-.1Z"/>
  <path id="Anatomy30" class="Lendenwirbelsaule4_ap1715_cls7" d="M63.5,20.1c-3.4-3.3,1.7-9,6.7-12.5"/>
  <path id="Anatomy29" class="Lendenwirbelsaule4_ap1715_cls7" d="M129.4,12.6c-3.8-3-5-5.7-6.7-6.8"/>
  <path id="Anatomy28" class="Lendenwirbelsaule4_ap1715_cls5" d="M124.4,1.4c-1.6,2-2.3,3.8-1.7,4.5l3.8-4.5s-2.1,0-2.1,0Z"/>
  <path id="Anatomy27" class="Lendenwirbelsaule4_ap1715_cls7" d="M124.6,21.4c6.3-6.1,2.3-8.8,4.8-8.8"/>
  <path id="Anatomy26" class="Lendenwirbelsaule4_ap1715_cls5" d="M99.9.9c-2.1,0-4.3-.1-6.4-.1.5,2.5,1.1,3.5,1,5.5,0,5.5-5.2,7.1-4.2,11.6.3,1.4,1.3,3.3,2.7,3.6,2.9.6,7.4-5,8-10.8,0-1.3,0-1.1-.7-6.5-.2-1.3-.3-2.4-.4-3.3Z"/>
  <path id="Anatomy25" class="Lendenwirbelsaule4_ap1715_cls3" d="M20,41.8c.1-2.1,3.7-3.8,6.4-4.2,4.2-.8,5,1.5,12.5,2.4,5,.6,7.6.9,10.6-.3,1.6-.6,2.6-1.4,3.7-.9,1.6.7,1.6,3.2,2.4,7.3,1.4,6.8,3.3,9,2.4,9.7-1.5,1.3-5.9-6-14.6-7.6-6.2-1.2-8.2,1.6-14.9-.3-3.4-1.1-8.6-3.5-8.5-6.1h0Z"/>
  <path id="Anatomy24" class="Lendenwirbelsaule4_ap1715_cls3" d="M133.4,55.7c.6,1.2,4.2,0,10-.9,9.2-1.2,10.7.7,16.4-1.2,4.1-1.4,9.9-4.6,9.7-7.9-.1-1.7-1.8-2.9-2.4-3.3-4-2.8-7.8,0-15.8-.3-7.7-.2-11.8-3-13.4-.9-1,1.3.5,2.7,0,6.1-.8,5-5.2,7.1-4.5,8.4h0Z"/>
  <path id="Anatomy23" class="Lendenwirbelsaule4_ap1715_cls3" d="M50,85c.2-.5.4-1,.7-1.5,2.4-4.4,1.1-2.3,3.1-5.5,4.4-7.4,4-4.8,4.5-18.4.5-13.1-6.7-19.7-3.3-23.1,4.1-4.3,14.7,6.7,37.1,8.5,15.5,1.3,27.6-1.2,27.6-1.2,10.2-2.1,16.1-5.2,18.2-2.7,2.4,2.9-4,8.6-5.8,18.8-2.8,16.5,9.3,28,5.8,31.3-3.6,3.4-14.1-10.2-34.5-13-14.8-2-29.4,2.5-32.9,3.5-10.7,3.4-17.1,7.6-20.3,7.7s-1.5-.9-1.2-1.8l1-2.6Z"/>
  <path id="Anatomy22" class="Lendenwirbelsaule4_ap1715_cls1" d="M71.1,91.2c-4.3-4-.7-12.2-.3-13.1.9-1.9,2.7-3.8,6.4-7.6,4.7-4.8,7-7.2,8.5-6.7,2.7,1,1.2,8.8,3.7,9.4,1.4.4,3.3-1.9,4.2-3,2.7-3.3,1.9-5.4,3.7-6.4,2.6-1.4,6.9,1.9,10.9,4.9,3.6,2.7,5.5,4.1,6.7,6.7,1.7,3.6,1.8,8.4-.3,12.2-4.8,8.3-19.2,8.1-22.8,3.3-1.6-2.1-1.2-5.4-3.7-6.4-1.1-.5-2.6-.3-3.7.3-2.6,1.5-1.4,5-3.7,7-2.1,2-7,1.8-9.6-.6h0Z"/>
  <path id="Anatomy21" class="Lendenwirbelsaule4_ap1715_cls3" d="M86.9,59.7c0-5.2,0-5.2,0-7,.2-3.8.5-10.5,4.2-12.2,1.9-.9,4.7-.3,6.1,1.2,1.5,1.6.9,3.7.3,10-.7,7.6,0,6.8-.6,9.4-1.4,6.3-5.4,12.7-7.6,12.2-1.9-.4-2.3-6-2.5-8.1,0-1.3,0-1.7,0-5.5h0Z"/>
  <path id="Anatomy20" class="Lendenwirbelsaule4_ap1715_cls5" d="M58.3,93.1c10.8,4.9,20.2,6.8,26.4,7.6,5.9.7,9.6.6,14.9.3,13.2-.6,15.2-3.2,15.8-4.2,1.4-2.4,1.2-6.1-.3-7-1.9-1.1-4.3,2.9-10.3,4.2-5.2,1.2-11.7,0-13.1-3-.8-1.7.5-3-.6-4.6-1.2-1.9-4.6-2.7-6.7-1.5-2.6,1.5-1.1,4.9-3.7,7-2.4,1.9-6.9,1.3-9.7-.6-3.3-2.2-1.6-9-2.8-8.7-1,.2-2.7,5.9-4.5,8.1-1.3,1.6-3.5,2.2-5.4,2.4h0Z"/>
  <path id="Anatomy19" class="Lendenwirbelsaule4_ap1715_cls4" d="M48.6,88.8c0,2.8,5.1,5,11.5,5"/>
  <path id="Anatomy17" class="Lendenwirbelsaule4_ap1715_cls3" d="M63.5,43.8c-.5,0-.3,2-.6,5.2-.4,4.7-1.2,6.1-.2,7.1s3.4.6,3.8.5c3.9-1.1,6.2-6.7,4.9-8.2-.9-1.1-3,.5-5.2-.9-2-1.3-2.1-3.8-2.7-3.7Z"/>
  <path id="Anatomy16" class="Lendenwirbelsaule4_ap1715_cls3" d="M125.6,24.2c-1.6.6-.4,4-1.9,11.5-1.3,6.1-3.6,8.7-2.5,9.8,1.3,1.3,6.3-.6,8.9-4.2,3.3-4.5,2-10.7-.3-14.4-1-1.5-2.8-3.2-4.2-2.7Z"/>
  <path id="Anatomy15" class="Lendenwirbelsaule4_ap1715_cls3" d="M62.9,21.6c-1,1.3-.8,4.4-.3,10.6.2,2.3.6,4.8,1.5,9.7.5,3,.9,4.4,2.1,5.5,1.3,1.1,3.6,1.7,5.2.9,2.9-1.4,1.8-6.3,3-11.5,2.7-11.8,14.3-16.5,12.8-19.1-.6-1-2.2-.3-14,1.2-7.1.9-9,1-10.3,2.7h0Z"/>
  <path id="Anatomy14" class="Lendenwirbelsaule4_ap1715_cls1" d="M57.7,21.9c0,1.1,33.9,6.2,75.2,2"/>
  <path id="Anatomy13" class="Lendenwirbelsaule4_ap1715_cls1" d="M61.1,22.9c-1.7,4.5-3.7,12.9-7.9,15.9"/>
  <path id="Anatomy12" class="Lendenwirbelsaule4_ap1715_cls3" d="M65,23.9c0,13.6,2.8,24.5,6.4,24.5"/>
  <path id="Anatomy11" class="Lendenwirbelsaule4_ap1715_cls1" d="M98.6,17.2c15.8,5,19.1,19.9,17.9,26.6"/>
  <path id="Anatomy10" class="Lendenwirbelsaule4_ap1715_cls1" d="M130,41.2c2-6.8-29.5-11.6-73.4-5.7"/>
  <path id="Anatomy9" class="Lendenwirbelsaule4_ap1715_cls5" d="M138,121.9c.8-2.5,2.7-9.6,3-14.6.3-5,.7-12.8-2.9-14.6-2.7-1.3-4.3,2.3-11.7,3.1-4.9.5-6.7-.8-10.9.8-2.8,1-2.5,1.8-5.5,3-1.3.5-3.1,1-10.3,1.3-4.6,0-7,.2-10.6,0-5.1-.3-8.9-1-11.8-1.6-5.8-1.1-8.7-1.7-12.2-3.3-2.5-1.1-2.8-1.6-4.9-2.3-3.9-1.2-5.4-.3-8.6-1.7-2.4-1-1.2-1.4-4.4-2.7-3.5-.8-8.3,3.3-10.6,7.6-1.7,6,.8,17.1,1.9,22.9"/>
  <path id="Anatomy8" class="Lendenwirbelsaule4_ap1715_cls6" d="M88.1,112.4c-2.3,0-3.8-5.2-4.6-7.9-1.1-3.8-1-6.6-.9-12.1,0-5.3.7-6.8,1.8-7.6,1-.7,2.5-.9,3.7-.3,1.9.9,2,3.4,3,9.7,1.4,8.5,2.3,10,1.2,13.1-.6,2-2.3,5.2-4.2,5.1h0Z"/>
  <path id="Anatomy7" class="Lendenwirbelsaule4_ap1715_cls6" d="M82.5,117.6c.2-.5.4-1.1.8-1.7,1-1.6,1.5-1.5,1.8-2.4.8-2.4-1.6-6.2-4.6-7.6-4.8-2.2-11.1,2.3-14,5.2-1.5,1.5-2.9,3.4-4,5.4"/>
  <path id="Anatomy6" class="Lendenwirbelsaule4_ap1715_cls6" d="M111,117.7c-.7-.6-1.4-1.2-2.2-1.8-10.5-8.5-12.3-8.9-14-8.5h0c-2.8.7-5.2,3.5-5.2,6.1s1.6,2.7,2.8,4.2"/>
  <path id="Anatomy5" class="Lendenwirbelsaule4_ap1715_cls5" d="M174.4,111.2c2.7-1.5,6.2-3.4,7.9-7.6,1.5-3.6,1.8-9.2-1.3-12-2.4-2.2-6.4-1.8-14.5-.7-8.6,1.1-8.9,2.4-14.6,2.4s-8.7-1.7-10.6,0h0c-2.5,2.2.1,6.2-.3,14-.2,4.2-1.1,7.6-2,10.2"/>
  <path id="Anatomy4" class="Lendenwirbelsaule4_ap1715_cls5" d="M36.3,110.9c-1.1-10.1-.9-11.8-2.5-13-2.8-2.1-5,1.1-10.9-.6-3.4-1-4.4-2.6-10.3-4.6-1-.3-2.9-.9-5.2-.6-.9,0-2.4.3-3.9,1.5h0c-1.5,1.1-2.1,2.5-2.4,3.3-.5,1.1-1.3,3.1-.6,4.9.6,1.5,1.9,1.8,4,3,3.2,1.9,3.4,3.1,6.1,5.2,1.1.9,2.1,1.4,3.1,2,1.7,0,3.3,0,4.8,0"/>
  <path id="Anatomy3" class="Lendenwirbelsaule4_ap1715_cls6" d="M48.6,106.9c2,1.6,10.4-.6,17.7-8.6"/>
  <path id="Anatomy2" class="Lendenwirbelsaule4_ap1715_cls6" d="M125.6,115.7c4.4-1.8,7.5-6.6,9.9-10.4"/>
  <path id="Anatomy1" class="Lendenwirbelsaule4_ap1715_cls6" d="M118.4,108.8c0,2.6.9,5.7,3.4,6.8"/>
  <path id="Anatomy18" class="Lendenwirbelsaule4_ap1715_cls3" d="M129.9,43.7c-.7,0-.9,1.1-2.4,2.1-.2.2-1.2.6-3.3,1.4-1.3.6-1.4.6-1.6.6-1.3,0-1.6-1.4-3.4-2-.5-.1-1.3-.4-1.8,0-.5.3-.6.9-.7,1.8-.1,1.8.4,2.8,1,5.1.8,2.9.5,3.4,1.3,4.4.7,1,1.7,1.5,2.5,2,1.1.6,1.8,1,2.7,1,1.5,0,2.6-1.1,3-1.4.3-.3.9-.9,2-4.1.6-1.7.9-2.9,1.3-4.2.8-3.1.9-4.1.6-5.1-.1-.4-.5-1.5-1.1-1.6Z"/>
</g>
<g id="Overlays">
  <path id="Overlays6" class="Full_WK1715Fill" style= {{
fill: props.colors.Full_WK1715Fill,
stroke: props.colors.Full_WK1715Fill,
opacity: props.colors.Full_WK1715Fill,
}} d="M20.2,42c.1-2.1,3.7-3.8,6.4-4.2,4.2-.8,5,1.5,12.5,2.4,5,.6,7.6.9,10.6-.3,1.6-.6,2.6-1.4,3.7-.9,1.6.7,1.6,3.2,2.4,7.3,1.4,6.8,3.3,9,2.4,9.7-1.5,1.3-5.9-6-14.6-7.6-6.2-1.2-8.2,1.6-14.9-.3-3.4-1.1-8.6-3.5-8.5-6.1h0Z"/>
  <path id="Overlays5" class="Full_WK1715Fill" style= {{
fill: props.colors.Full_WK1715Fill,
stroke: props.colors.Full_WK1715Fill,
opacity: props.colors.Full_WK1715Fill,
}} d="M133.6,55.9c.6,1.2,4.2,0,10-.9,9.2-1.2,10.7.7,16.4-1.2,4.1-1.4,9.9-4.6,9.7-7.9-.1-1.7-1.8-2.9-2.4-3.3-4-2.8-7.8,0-15.8-.3-7.7-.2-11.8-3-13.4-.9-1,1.3.5,2.7,0,6.1-.8,5-5.2,7.1-4.5,8.4h0Z"/>
  <path id="Overlays4" class="Full_WK1715Fill" style= {{
fill: props.colors.Full_WK1715Fill,
stroke: props.colors.Full_WK1715Fill,
opacity: props.colors.Full_WK1715Fill,
}} d="M48.7,89.4c-2.9-3.1,9.1-12.7,9.7-29.2.4-11.8-7.1-18.8-3.3-23.1.7-.8,1.6-1.3,13.4-2.6,6.3-.7,9.5-1,14.4-1.3,4.8-.3,11.3-.5,19-.5,6.3.6,12.1,1.3,17.4,2,3.3.5,5.7.8,7.9,2.6,2,1.5,2.2,4.5,4.4,3.8,3.3-1,5.2-.5,6.4.5,2.3,1.9-4.3,10.1-5.8,18.8-2.8,16.5,9.3,28,5.8,31.3-3.6,3.4-14.1-10.2-34.5-13-14.8-2-29.4,2.5-32.9,3.5-13,4.1-19.7,9.5-21.9,7.1Z"/>
  <path id="Overlays3" class="Full_WK1715Fill" style= {{
fill: props.colors.Full_WK1715Fill,
stroke: props.colors.Full_WK1715Fill,
opacity: props.colors.Full_WK1715Fill,
}} d="M48.9,88.6c8.6-2.1,23.7-8.8,30.8-10.9,9-1.1,18.7-2.2,30.8.7,11.9,5.3,24.2,11.7,25.4,12.9-2,1.9-4.1,3-5.8,3.6-6.2,2.3-9.2-.9-14.6,1.8-2.2,1.1-3.3,2.4-6.4,3.1-1.6.3-4.5,1-6.6,1-4.1.2-4.5,0-6.5.2-5.2.7-13.6.3-21.9-1.8-4-1-9.6-2.8-15.7-6.3-.5.1-3.8,1-6.7-1-1.7-1.2-2.4-2.7-2.7-3.4h0Z"/>
  <path id="Overlays2" class="Angrenzende_WK1715Fill"
  style= {{
fill: props.colors.Angrenzende_WK1715Fill,
stroke: props.colors.Angrenzende_WK1715Fill,
opacity: props.colors.Angrenzende_WK1715Fill,
}} d="M138,92.9c-2.7-1.3-4.3,2.3-11.7,3.1-4.9.5-6.7-.8-10.9.8-2.8,1-2.5,1.8-5.5,3-1.3.5-3.1,1-10.3,1.3-4.6,0-7,.2-10.6,0-5.1-.3-8.9-1-11.8-1.6-5.8-1.1-8.7-1.7-12.2-3.3-2.5-1.1-2.8-1.6-4.9-2.3-3.9-1.2-5.4-.3-8.6-1.7-2.8-1.2-2.7-2.4-4.4-2.7-3.5-.8-8.3,3.3-10.6,7.6-.4.7-.7,1.6-.9,2.6.1.8,0,1.7-.1,2.6.2.6.2,1.2.2,1.8.4.8.5,1.8.4,2.7.2.7.2,1.4,0,2.1.2.6.2,1.2.2,1.8.2.4.3.9.4,1.4.5,1.1.6,2.4.3,3.6.6,2.5,1.1,4.7,1.4,6.4l99.4.2c.7-2.2,1.3-4.5,2.1-6.7.1-.4.3-.8.6-1.1.2-2.3.3-4.8.4-6.8.3-5,.7-12.8-2.9-14.6Z"/>
  <path id="Overlays1" class="Angrenzende_WK1715Fill"
  style= {{
fill: props.colors.Angrenzende_WK1715Fill,
stroke: props.colors.Angrenzende_WK1715Fill,
opacity: props.colors.Angrenzende_WK1715Fill,
}} d="M60.5.2c0,5-1.1,8.1-2.2,10.1-.5.9-1.5,2.6-2.6,5.1-1,2.5-1.5,3.8-1.3,4.6.7,2.8,7.3,3.4,18.5,4.1,11.9.8,17.8,1.2,21.8,1.3,3.2,0,9,0,20.5-.3,5.9-.1,11.8-1.8,17.7-1.4,1.4,0,3.4.3,4.9-1,2.6-2.3,1.5-7.9.9-10.9-1-5.2-4.8-9.3-6.5-11.7"/>
</g>
</svg>
  );
}

export default Lendenwirbelkorper4VdLiegend1715;
