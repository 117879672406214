import style from "./style.scss";

export default function RechtesKniegelenkNachRosenbergDvStehend2334(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 379.18 717.75">
      <g id="underlay">
        <ellipse
          class="Gelenkspalt2334Fill"
          style={{
            fill: props.colors.Gelenkspalt2334Fill,
            stroke: props.colors.Gelenkspalt2334Fill,
          }}
          cx="190.29"
          cy="349.21"
          rx="52.07"
          ry="170.01"
          transform="translate(-178.48 498.08) rotate(-83.4)"
        />
      </g>
      <g id="knee_tunnel" data-name="knee tunnel">
        <g id="background_layer" data-name="background layer">
          <path
            id="background_upper_leg"
            data-name="background upper leg"
            class="kniegelenk_nach_rosenberg_dv_right2334_cls3"
            d="M97.29,15.9c2.7-17.3,29.3-10.7,85.6-12.8,60.6-2.2,80.5-8.2,93,6.4,6.4,7.4,1.9,10.1,13.7,35.5,2.3,4.9,9.6,20.3,22.8,40.1,11.5,17.3,14.3,18.2,24.6,32.8,3,4.1,24.4,35,36.5,77.5,5.2,18.3,5.5,27.4,1.8,35.5-3.4,7.4-7.9,9.3-10.9,19.1-2.7,9,0,11.3-1.8,22.8-1.7,10.7-4.2,10.2-8.2,24.6-2.3,8.2-4.8,17.5-4.6,29.2.3,13.8,4.1,17.1.9,23.7-3.7,7.9-12.6,10.9-18.2,12.8-11.5,3.9-21.3,2.5-31.9.9-22.1-3.4-36.8-11.8-40.1-13.7-18.2-10.4-23.1-20.2-24.6-23.7-1.3-3.1-3.7-8.8-1.8-14.6,1.8-5.4,5.6-5.9,9.1-10.9,5.2-7.5,3.6-16.8,1.8-28.2-1.2-7.6-3.3-13.4-6.1-18.4-2.5-4.5-5.4-9.7-9.4-9.8-3.9-.1-7.3,2.9-13.7,6.4-3.8,2.1-5.2,4.1-13.7,4.6s-12.8.8-17.3-.9c-7.9-2.9-10.6-4.4-13.1-3.3-3.5,1.5,1.7,7.5-2.4,20.7-1.5,4.9-4.6,9.9-8.2,16.4-4.5,8.2-9.3,6.2-11.4,11-2.3,5.4,2.1,7,.5,10.9-3,7.3-16.3,4.3-35.5,10-16.6,4.9-15.3,9.6-29.2,11.9-5.9,1-19.3,3-31.9-4.6-3.4-2.1-9.4-5.7-12.8-12.8-2.3-4.7-2.5-9.5,2.7-39.2,2.1-11.7,2.4-12.6,2.2-15.6-.7-11.6-6.3-14.8-11.3-27.3-1.1-2.8-7.9-20.2-3.6-38.3,2-8.8,5.6-13.3,10.9-21.9,3.1-5,9.7-19.1,22.8-47.4,17.6-37.8,25.3-57.3,29.2-72,5.5-20.9,1-20.4,3.6-37.4h0Z"
          />
          <path
            id="background_lower_leg"
            data-name="background lower leg"
            class="kniegelenk_nach_rosenberg_dv_right2334_cls3"
            d="M15.19,705.8c5.6,7.8,21.8,10.2,31,3.6,9.9-7,4.4-19.2,9.1-56.5.4-2.7.2-.8,12.8-61.1,4.1-19.5,5.5-26.5,7.3-26.4,3.3.1,4.8,24.6,6.4,50.1,1.7,28.1,2.5,42.2,1.8,48.3-1.9,15.9-1,24.3.9,41,.3,2.5.7,5.6,3,8.3,5.7,6.8,17.2,4.4,33.5,3.5,11.5-.6,23,.6,34.6.9,0,0,51.6,1.2,67.4-7.3.4-.2.9-.4,1.3-.8,1.8-1.9,1.2-6.3,3.3-20.1,1.8-12.4,4.5-24.7,6.4-37.1,5.9-38.3,29.2-82.2,35.5-94.1,14.8-28,22.2-42,35.5-53.8,8.2-7.2,16.9-12.7,28.3-26.4,8.3-9.9,15.8-19.2,18.2-32.8.8-4.6-.3-2.3,0-25.5.2-14.4.7-18.4-1.8-23.7-1.6-3.5-4.8-8.3-15.5-14.6-8-4.7-23.1-12-45.6-13.7-15.6-2-27-7.2-33.7-10.9-11.5-6.4-20.5-11.3-24.6-21.5-3-7.5-1.6-13.4-5.5-15-4.5-1.9-11.7,4-17.1,8.5-1.9,1.6-5.3,4.6-11.2,9.8-3.9,3.4-5.5,4.9-8.2,5.2-3.8.4-7.5-1.5-9.3-2.4-.4-.2-2-1-3.9-2.5-2.6-2-3.8-3.8-6-6.6-3.5-4.6-6.8-8.7-7.3-8.4-.3.2,0,1.6,2.8,7.5-.6-1.2-4.2-8.6-10.1-9.1-3.7-.3-6.4,2.2-11.5,6.8-8.1,7.4-7.3,10-13.1,14.2-3,2.2-7.2,3.6-15.5,6.4-7.8,2.6-14,3.8-16.4,4.3-2.3.4-4.7.8-7.3,1.1-12.7,1.5-21.6.4-24.6,0-7.7-1.1-11.5-1.7-15.5-4.6-5.8-4.2-5.8-9.7-10.9-10.9-3.8-.9-7.3,1.1-10,2.7-5.5,3.3-8.7,7.9-10.9,11.9-4,7.1-10.4,18.2-10.9,34.6-.1,3.6.2,1.2,2.7,26.4,1.4,14.1,1.4,16.6.9,20-1.6,10.8-5.9,11.8-9.1,22.8-2.2,7.8-1.8,14.9-.9,29.2.4,7,1.4,16.7,3.6,28.2,0,0,4.9,25.5,9.1,58.3,3.3,25.3,5.4,46.5,4.6,73.8-.1,3.4-.5,12.4-2.3,28.8-2.5,23.3-3.9,25.9-1.3,29.6h0Z"
          />
        </g>
        <path
          id="tibia_outlines"
          data-name="tibia outlines"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls5"
          d="M223.09,710.1c4.8-30.6,11.1-55.9,16.4-74.7,7.3-26,13.9-48.7,28.2-76.6,11.7-23,19.3-37.8,35.5-52.9,11-10.2,16.5-11.8,27.2-24.5,8.3-9.7,19.3-23,21.9-41.9.7-5.3.5-11.7,0-24.6-.4-10.5-1-13.8-2.7-17.3-3.1-6.3-8.2-10-14.6-13.7-13.2-7.6-24.6-10.6-35.5-13.7-22.9-6.5-34.3-9.7-45.6-14.6-18.7-8.1-19.1-11.6-31-13.7-14.9-2.6-28.8.5-34.6,1.8-7.5,1.8-8.6,2.9-19.1,6.4-17.8,6-35.8,12-56.4,10.7-8.8-.5-5.9-1.6-25.6-4.3-19.6-2.7-23.9-1.3-31.9-5.5-11.3-6-13.4-13.1-20-12.8-12.5.5-21.6,27-24.6,41-4.6,21.3-.8,38.4,2.7,53.8,3.9,17.3,8.7,24.9,11.9,29.2,5.8,7.8,10.5,10,16.4,15.5,10.7,9.9,14.7,20.7,21.9,41,9.5,26.6,13.5,50.4,15.5,67.4,1.4,11.9,1.5,18.3,2.7,58.3.8,27.7,1.5,49.2,1.8,60.2"
        />
        <path
          id="femur_outlines"
          data-name="femur outlines"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls5"
          d="M276.99,9.4c1.9,7.9,5.2,19.1,10.9,31.9,6.4,14.7,12.5,23.7,26.4,44.7,19.5,29.4,29.3,44.2,32.8,50.1,16.2,27.4,22,42.5,25.5,54.7,3.7,12.6,6.2,21.7,4.6,33.7-1.9,13.7-10.5,16.9-13.7,35.5-1.3,7.5-.6,9.8-2.7,18.2-2.8,11-5.5,12.5-8.2,21.9-2.5,8.7-2.6,15.4-2.7,28.2-.2,18.3,2.4,22-.9,27.3-4.9,8-16.5,9.1-27.3,10,1.4-.2-55.7-6.2-72-21-4.4-4-19.8-18-16.4-29.2,1.7-5.6,7-6.8,10.9-15.5,3.2-7.2,2.9-13.8,2.7-18.2-.1-3.6-1.1-23.8-18.2-37.4-1.9-1.4-17.4-13.5-36.5-10-6.3,1.1-11.2,4.1-21,10-14.4,8.8-21.6,13.1-24.6,21-3.8,9.8-.6,21.6.9,27.3,2.3,8.7,4.7,10.1,3.6,13.7-1.9,6.8-12,7.9-32.8,13.7-27.5,7.6-27.4,10.6-39.2,10.9-7.6.2-26.7.7-34.6-10.9-3.6-5.4-3.3-11.5-2.7-23.7,1-19.7,6.6-25.4,3.6-38.3-1.6-6.7-3.3-5.6-7.3-15.5-1-2.3-9-22.4-6.4-42.8,1.8-14,8-22.3,15.5-34.6,0,0,12.2-20,31.9-69.3,7-17.6,15.3-43.6,19.1-78.4"
        />
        <path
          id="fibula_outlines"
          data-name="fibula outlines"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls5"
          d="M48.89,710.1c3.5-30.3,7.5-55.8,10.9-75.7,3.1-18.1,5.2-30.3,9.1-47.4,3.9-16.9,5.7-21.9,11.9-48.3,1.8-7.6,5.2-22.4,8.2-41.9,3.3-21.3,3.2-29.7-.9-38.3-4-8.4-10.6-14.4-12.8-16.4-4-3.7-5.8-4.4-19.1-12.8-15.9-10-15.8-10.7-18.2-10.9-12.6-1.3-25.9,15.2-31.9,29.2-5.9,13.7-5.3,25.8-2.7,48.3,4.2,37.1,7.5,48,10.9,73.8,4.8,36.4,4.8,64.5,4.6,76.6-.5,24.1-2.6,44.2-4.6,58.3"
        />
        <path
          id="femur_1"
          data-name="femur 1"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls5"
          d="M149.69,299.3c11.8,0,21.4-24.6,21.4-55"
        />
        <path
          id="femur_2"
          data-name="femur 2"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls5"
          d="M45.29,258c-2,0,1.2-13.6-3.6-27.3"
        />
        <path
          id="fibula_1"
          data-name="fibula 1"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls4"
          d="M14.29,475.9c34.7,16.4,69.3-2.9,69.3-6.4"
        />
        <path
          id="femur_3"
          data-name="femur 3"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls4"
          d="M322.39,230.7c10.5,0,19.1,3.7,19.1,8.2"
        />
        <path
          id="femur_4"
          data-name="femur 4"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls4"
          d="M113.69,222.5c14.6,0,26.4,12.2,26.4,27.3"
        />
        <path
          id="femur_5"
          data-name="femur 5"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls4"
          d="M59.79,230.7c17.4,0,31.5-2.1,31.5-4.6"
        />
        <path
          id="femur_6"
          data-name="femur 6"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls4"
          d="M255.19,279.9c-6.5-27.2,10.3-54.3,37.1-49.2"
        />
        <path
          id="patella"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls6"
          d="M262.19,196.9c-.1,30.6-24.2,47.9-57.8,58.8-32.1,10.5-82.5-43.6-82.5-83.4,0-39.8,41-48.3,74.7-48.3,33.7,0,65.7,33.1,65.6,72.9h0Z"
        />
        <path
          id="femur_7"
          data-name="femur 7"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls5"
          d="M363.29,259.8c1.3-6.5,2.2-19,2.6-27.1.8-14.7-.5-27.5-2.3-37.8"
        />
        <polyline
          id="tibia_1"
          data-name="tibia 1"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls4"
          points="48.99 439.4 75.59 424.8 98.19 424.8 109.99 424.8 153.79 424.8 168.69 425.7 176.09 433.9 184.69 438.5 196.59 439.4 204.29 433.9 222.09 433.9 234.89 438.5 253.79 443.9 272.99 451.2 284.99 448.5 297.79 454 315.09 454"
        />
        <path
          id="tibia_2"
          data-name="tibia 2"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls7"
          d="M160.39,506.9l-10.7-13.7-9.6-14.6,4.6-16.4-12.8-7.3,8.2-15.5-13.2-26.4,13.2-5.5,4.6-15.8,24.1-6.1,23.3,6.1,12.3-6.1,13.2,14.6,18.2-8.5,5.5,13,8.2,20v29.2s-10.9,21.9-10.9,21.9l-21.9,32.8s-13.7,11.9-20.1,15.5"
        />
        <polyline
          id="tibia_3"
          data-name="tibia 3"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls7"
          points="126.89 519.6 114.59 509.6 107.29 489.5 107.29 469.5 107.29 449.4 121.89 442.1"
        />
        <polyline
          id="tibia_4"
          data-name="tibia 4"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls7"
          points="132.79 424.8 132.79 424.8 143.69 412.9 153.79 409.3 168.69 412.9"
        />
        <path
          id="tibia_5"
          data-name="tibia 5"
          class="kniegelenk_nach_rosenberg_dv_right2334_cls5"
          d="M334.99,383.8c-3.4,2.8-18.8,14.6-40.1,13-13.6-1-22.8-7.4-26.4-10-4.9-3.5-8.3-7.5-14.8-15.5-14.1-17.2-19.8-26.1-23.5-36.1-2.4-6.7-1.6-13.4-5.5-15-2.4-1-5.1.5-9.1,2.7-5.3,2.9-8,5.7-12.8,10-7.6,6.9-11.5,10.3-14.5,10.7-8,1-15.6-7.1-16.4-7.9-4.6-4.9-4.7-9.2-10.1-12-.6-.3-3.6-1.8-7.2-1.6-7.1.4-11.2,7-16.7,12.6-7.1,7.3-14.3,10.7-23.3,14.8-6.9,3.1-18.6,7.1-35.4,6.2"
        />
      </g>
      <g id="overlay">
        <path
          id="patella_overlay"
          data-name="patella overlay"
          class="Patella2334Fill"
          style={{
            fill: props.colors.Patella2334Fill,
            stroke: props.colors.Patella2334Fill,
          }}
          d="M262.19,196.9c-.1,30.6-24.2,47.9-57.8,58.8-32.1,10.5-82.5-43.6-82.5-83.4,0-39.8,41-48.3,74.7-48.3,33.7,0,65.7,33.1,65.6,72.9h0Z"
        />
      </g>
    </svg>
  );
}
