import style from "./style.scss";

export default function RechterKronenfortsatzMLsitzend2224(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303.8 440.12">
      <g id="Backround">
        <path
          id="backround"
          class="Kronenfortsatz_right2224_cls2"
          d="M0,436.9c.9,1,1.2,1.4,2.1,2.4h104.1c.6,0,1.2-.4,1.4-.9,2.4-5.2,5.7-12.6,13.3-24.6,10.2-16.1,23.6-37,47-55.5,6.3-5,11-7.8,17.8-10.9-.5-1.4-.1-3,1.7-3.5,3.1-1,6.2-1.2,9.4-1.2,2.4-.9,5.1-2,8.1-3.2,3.5-1.4,7.8-3.4,11.9-5.7.3-.5.7-1.1,1.1-1.6.6-1.7,1-3.3,1.2-5-1-.5-1.8-1.6-1.6-3,.2-1.7.2-3.3-.1-4.8-.2-.1-.5-.3-.7-.5-1.2-1.2-2.8-1.8-4-3-.6-.6-1.1-1.5-1.1-2.4-2.1-.6-4.3-1.4-6.6-2.3-13.4-5.2-19.8-6.4-21.3-8.4-6.1-8.1-4.5-22.8-1.5-24.4,1.8-1,4.2,2.7,14.2,10,5.4,3.9,12.2,8.9,21.4,12.8,0,0,7.1,3,14.5,4.8,2.6.7,5.4,1.2,5.4,1.2,1.6.3,2.8.5,3.2.6.8.1,2.2.3,3.8.5h.2c23.9,2.8,32-2.3,32.7-2.8l.1-.1c10.1-7.9,7.5-26.8,7.1-29.9-1.4-9.9-5-10.6-4.3-17.1,1.2-11,11.7-11.8,18.5-25.6.9-2,7.3-15.3,2.8-28.5-1.8-5.3-4.8-9.1-18.4-20.4-.7.7-1.8,1-3,.4-8.2-4.3-13.9-11.4-20.7-17.4-.7-.6-1-1.4-1-2.1-.9-.7-1.8-1.4-2.8-2.3-.8-.4-1.7-.8-2.5-1.2-1.3-.6-1.8-1.7-1.8-2.8-4.6-4.6-8.8-10-13.5-17-1.1.3-2.2.1-3-1.2-2.1-3.6-4.1-7.2-6.1-10.8-2.8-4.4-5.6-8.8-7.9-13.4-.4-.2-.7-.5-1-.9-2.6-3.6-4.4-7.6-5.6-11.8-.7-1-1.3-2.1-1.8-3-.6-.1-1.2-.5-1.6-1.2-3.8-6.4-6-13.4-7.4-20.7-1.7-3-2.2-6.2-2.2-9.7,0-.5.1-1,.3-1.5-.8-4.6-1.9-10.2-4-18.4-3.9-15.2-5.2-15.4-7.1-25.6-2.3-12.1-1.7-18.6-1.6-23.3-39.4.8-78.8.9-118.2.9-1.8,4.5-.6,6.4-1.3,18.1-.6,9.9-2.3,24.3-15.7,62.7-11.9,34.1-13,30-24.2,62.7-8.6,25.3-1,7.6-14.2,54.1-3.6,12.9-6.2,21.3-2.8,31.3,3.7,10.9,12.6,18.1,18.5,22.8,7.2,5.7,13.5,10.6,21.4,12.8,1.2.4,4.3,1.1,7.5,3.5,1.3,1,5.3,3.9,6.8,9,1.7,5.6.3,13.1-3.4,14.6-1.4.5-2.4,0-4.2-.9-1.3,1.6-3.5,2.2-5.5.4-2.5-2.3-6-3.3-9.3-4.3-1.6-.1-3.5-.3-6.1-.9-10.3-2.3-10-6.2-15.7-5.7-7.4.5-15.1,7.2-15.7,14.2-.4,3.8,1.9,4.9,8.5,17.1,5.4,10.1,7.2,12.1,8.5,17.1,2,7.6-.1,10.8-1.4,22.8-2,18.6,2.4,19,0,31.3-1.9,9.7-4.7,10.2-12.8,28.5-5.2,11.7-8.6,19.4-9.8,24.8Z"
        />
      </g>
      <g id="Processuscoronoideusml">
        <g id="Overview">
          <path
            id="partofancon2"
            class="Kronenfortsatz_right2224_cls4"
            d="M196.4,220.3c-6.4,0,82.1-2.7,0-58.3"
          />
          <path
            id="partofancon1"
            class="Kronenfortsatz_right2224_cls4"
            d="M267.2,201.9c-2.4,0,24.9,40.5-4.3,59.8"
          />
          <path
            id="ulna3"
            class="Kronenfortsatz_right2224_cls4"
            d="M80.5,298.8c-10.2,0-17.6.6-17.6-5.7"
          />
          <path
            id="ulna2"
            class="Kronenfortsatz_right2224_cls3"
            d="M180.2,289.2c14.8,0,24.7,9.4,24.7,21.8"
          />
          <ellipse
            id="ulna1"
            class="Kronenfortsatz_right2224_cls2"
            cx="186.35"
            cy="310.46"
            rx="13.4"
            ry="24.8"
            transform="translate(-152.95 184.02) rotate(-38.59)"
          />
          <path
            id="outline3"
            class="Kronenfortsatz_right2224_cls4"
            d="M97.5,439.5c-1.2-7-4-12.7-4-19.8,0-15.7,0-29.9-2.8-45.6-2.9-21.4,10-39.9,29.9-49.9,7.1-2.9,9.9-12.9,8.5-21.4-1.4-7.1-7.1-7.1-12.8-5.7-11.4,2.9-21.4,4.3-32.8,4.3-1.4,0-2.8-1.4-2.8-2.8-1.4-2.8,1.5-5.7,4.3-7.1,7.1-2.8,14.3-8.5,22.8-8.5,21.4,0,41.3,4.3,62.7,0,5.7-1.4,11.4-1.4,14.2-7.1,7.1-17-1.4-37,1.4-55.5,1.4-8.6,11.4-10,18.5-15.7,8.5-5.7,2.8-18.6-5.7-21.4-12.9-4.2-25.7-1.4-38.5-2.8-14.2-2.9-28.5-2.9-42.7,2.8-2.9,1.5-7.2,2.9-10,4.3-12.9,5.7-21.4,18.5-27.1,31.3-4.3,10-4.3,21.4-5.7,32.8,0,12.9,0,27.1-8.5,38.5-25.7,38.4-39.9,78.3-51.3,123.9-1.5,5.7-1.5,11.4-5.6,17.6"
          />
          <path
            id="outline2"
            class="Kronenfortsatz_right2224_cls4"
            d="M70.2,1.8c-2.5,46.3-18.2,89-36.7,130.3-8.6,20-11.4,41.3-17.1,62.7-2.9,5.7-4.3,11.4-5.7,17.1-5.6,31.3,25.7,47,49.9,58.4,7.1,4.3,8.5,11.4,7.1,18.5-1.4,1.5-2.9,4.3-5.7,4.3-12.9-1.4-25.7-8.6-38.5-11.4-11.4-2.8-19.9,12.8-14.2,22.8l2.8,2.8c4.3,7.1,5.7,14.2,11.4,19.9,0,19.9,2.9,39.9-2.8,59.8-2.8,15.7-12.8,28.5-19.4,43.1"
          />
          <path
            id="outline1"
            class="Kronenfortsatz_right2224_cls4"
            d="M105.8,439.9c32-66.2,55.7-74.2,76.4-90.9,4.5-3.6,9.8-6.2,15.4-7.5,8.4-1.9,19.3-5.5,21.1-12.8,1.6-6.3-4.2-12.8-4.8-13.5-8.4-9.5-24-3.2-30.2-12.3-3.4-5-4.6-12.2-2.3-22.7,2.9-.8,1.8-.5,4.7-1.3,18.7,21.5,46.3,31.4,73.9,31.4,7.1,0,15.6-1.4,19.9-7.1,8.5-11.4,4.3-25.7,0-38.5-1.4-5.7,0-11.4,4.3-15.7,14.2-10,21.3-25.6,18.5-41.3-1.5-2.9-2.9-7.2-4.3-10-17.1-17.1-38.4-27.1-54.1-45.6-17.1-22.8-31.3-45.6-41.3-71.2-1.4-21.4-9.9-41.3-14.2-62.7,0-4.3,0-10-.3-13.7"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="processuscoronoideushighlight"
          class="Kronenfortsatz2224Fill"
          style={{
            fill: props.colors.Kronenfortsatz2224Fill,
            stroke: props.colors.Kronenfortsatz2224Fill,
          }}
          d="M186.29,305.54c-.36-.55-.84-1.16-1.29-1.08-1.05.19-1.02,3.99-1.27,7.35-.65,8.94-2.75,8.93-3.44,18.1-.37,4.97.25,4.92-.3,9.78-.52,4.56-1.3,6.57-.19,7.46,1.59,1.29,5.78-.65,8.54-2.18,1.13-.63,2.84-1.5,5-2.32,3.93-1.48,5.73-1.41,9.1-2.22,3.77-.92,9.78-2.37,13.55-7.1,1.8-2.25,2.58-4.62,2.96-6.23-.06-1.27-.28-3.08-.98-5.13-1.33-3.92-3.58-6.28-4.07-6.78-2.28-2.33-4.64-3.39-5.79-3.89-3.4-1.48-6.04-1.59-8.46-1.89-3.22-.39-7.79-1.34-13.35-3.88Z"
        />
      </g>
    </svg>
  );
}
