import style from "./style.scss";

export default function LinkerOberarmMitEllenbogenVdSitzend2229(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 392.16 1155.54">
      <g id="Backround">
        <path
          id="backround"
          class="oberarm_mit_ellegnbogen_vd_links2229_cls4"
          d="M380.67,954.74c-1.89-11.77-7.81-20.59-6.07-38.43,1.23-12.6,5.44-21.04,7.82-26.63,12.49-29.28,12.62-68.63,2.34-95.42-9.83-25.61-20.86-18.08-38.47-51.48-8.39-15.91-9.06-23.62-22.09-68.7-9.58-33.13-15.12-49.38-17.76-58.39-18.62-63.61-4.96-150.77-2.93-270.57,1.25-74.26-1.85-167.58,1.79-223.1,2.37-36.05,3.55-54.08,6.02-67.31,4.69-25.19,8.36-29.46,4.59-36.33-10.53-19.15-46.35.68-114.15-13.93-16.04-3.46-28.12-7.6-36.35-.79-13.24,10.95-5.05,40.73-2.48,50.95,1.24,4.94-3.09-11.62-9.34,363.53-.85,50.87-.88,58.06-2.52,73.87-6.47,62.47-13.89,134.16-58.86,193.49-40.29,53.17-85.5,63.14-91.34,113.92-.82,7.13-2.68,23.24,4.27,41.18,14.16,36.58,46.21,32.43,64.49,67.22,15.39,29.29-1.07,44.1,18.61,74.43,12.48,19.22,19.19,14.66,32.36,33.57,18.77,26.95,18.66,61.8,18.61,81.3-.08,27.56-5.78,44.68,4.67,53.01,6.57,5.24,15.61,3.87,20.41,3.22,33.75-4.58,117.53-8.74,171.51.4,9.06,1.53,21.35,4.13,28.53-2.73,7.15-6.83,5.06-19.95-1.33-47.37-13.44-57.71-16.71-52.09-16.28-63.56,1.48-40.27,39.58-50.39,33.98-85.35Z"
        />
      </g>
      <g id="Upperarmvd">
        <g id="Outlines">
          <path
            id="outline4"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls4"
            d="M147.22,1049.23c3.02,24.09,6.45,27.45,10.94,56.05,1.28,8.15,3.29,21.14,5.02,37.83,28.27-.24,56.53-.91,84.78-1.63.08-.22.19-.55.28-.97.89-4.18-2.49-8.12-3.34-9.22-2.71-3.49-3.1-9-3.89-20.02-.6-8.43,1.39-14.61,11.19-39.69,4.35-11.14,9.26-27.54,12.19-34.8.84,3.35.95,15.69,1.89,22.44,1.65,11.72.73,15.27.99,46.01.11,13.63.31,14.91.16,22.97-.19,10.15-.56,11.04-.02,12.44,2.59,6.71,20.3,10.38,83.99-1.32-6.22-22.49-8.9-32.89-12.78-50.44-2.86-12.93-7.62-35.53-5.47-64.25,2.14-28.68,6.41-37.01,9.57-41.01,8.32-10.52,18.18-12.06,19.88-22.26,1.44-8.64-4.01-17.24-7.57-22.86-7.25-11.44-14.03-13.06-13.67-19.14.32-5.4,5.76-5.75,13.67-15.04,8.27-9.71,11.43-20.04,13.67-27.34,1.16-3.78,3.34-11.06,3.83-20.6.64-12.54-2.34-15.37-5.19-34.09-3.42-22.44-.28-25.92-5.47-34.18-5.7-9.07-12.01-8.92-20.51-16.41-7.99-7.04-10.76-14.53-19.14-45.11-12.22-44.59-11.74-48.38-19.14-71.09-3.22-9.86-5.2-14.95-10.94-32.81-9.14-28.42-10.3-34.75-10.94-39.65-1.89-14.45.97-14.38,1.37-39.65.48-30.51-3.65-32.9-1.37-54.68,2.32-22.16,14.27-464.89,11.23-471.51-18.08-8-65.8-16.32-108,0-4.83,7.18-8,461.15-16.7,481.08,0,0-3.13,32.95-9.57,73.82-7.06,44.79-16.43,67.13-34.18,105.27-10.82,23.25-20.06,42.58-38.28,56.05-3.99,2.95-8.67,5.86-19.14,13.67-18.92,14.12-28.39,21.18-32.81,27.34-10.88,15.15-14.4,37.01-8.2,56.05,1.96,6.03,7.44,22.84,21.87,30.08,5.77,2.89,9.68,2.6,21.87,8.2,9.18,4.21,13.77,6.32,16.41,9.57,7.07,8.71.79,15.32,8.2,28.71,3.6,6.49,7.04,8.47,6.84,13.67-.21,5.33-3.98,7.04-6.84,12.3-5.89,10.83-2.28,26.35,4.1,35.54,3.47,5,5.01,4.17,20.51,16.41,13.79,10.89,15.58,11.72,17.77,15.04,2.8,4.23,3.87,7.99,6.84,24.61,2.27,12.74,3.41,19.11,4.1,24.61h0Z"
          />
          <path
            id="outline3"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M292.43,17.21c-.58,7.32-8,456.26-9,463.26-4,15-2,29-1,43,3,29-4,57,5,85,16,48,31,94,42,145,2,6,6,11,10,16,7,5,14,9,21,15,4,4,5,9,6,14,1,14,4,27,6,41,2,12,2,24-1,36,0,1-2,2-2,3-5,18-15,36-32,43-14,7-28,9-43,6-12-2-24-6-36-7-12,0-25-1-37-2-10-1-19-6-29-10-5-1-10,2-14,5-16,17-44,36-66,20-6-10-4-23-5-34,0-8,2-20-9-23-4-1-9,4-13,1-2-1-4-5-4-8-1-15,0-29-2-44,0-3,1-5,1-8,1-2,2-4,3-7,12-26,42-37,69-33,15,2,29,6,44,9,11,1,22,3,32.81.28"
          />
          <path
            id="outline2"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M163.46,1137.67c-3.04-32.2-13.04-62.2-17.04-94.2,0-4-1-9-1-13-1-10-3-20-8-29-4-9-15-13-24-20-7-5-14-10-18-17-1-2-2-5-3-7-1-6-3-13,0-19,1-2,2-4,3-7,1-2,4-4,5-6,2-3,2-7,0-10-3-5-7-10-9-15-2-10,1-22-10-27-5-2-10-5-15-7-9-3-20-5-26-12-21-22-25-58-5-82,17-19,41-29,60-46,17-17,26-39,36-60,1-2,0-3,1-5,28-59,30-123,39-187,1-5,8.68-451.8,13-457.26"
          />
          <path
            id="outline1"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M355.89,938.48c.54.99,1.54,1.99,2.54,2.99,1,4,3,7,5,11,2,6,0,13-5,18-8,7-17,13-20,24-13,50,0,97,13.58,143.93"
          />
        </g>
        <g id="Overview">
          <path
            id="radius6"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M248.24,1140.51c-3.82-8.04-4.82-16.04-6.82-25.04-3-21,8-38,17-56l3-9c3-9,4-19,3-28-1-13-11-20-22-26-1-1-3-3-3-5,0-16,2-32,7-47,3-7,10-8,17-10,10-2,17,2,26,5h8c19-5,40-11,57.57-.98"
          />
          <path
            id="ulna6"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M229.23,786.74c.2,32.73-4.8,63.73-10.8,96.73-1,7,0,14,3,21,16,32,39,61,45,97,3,46,0,93,.98,139.18"
          />
          <path
            id="radius5"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M337.75,1138.54c-4.33-16.07-6.33-32.07-10.1-46.94"
          />
          <path
            id="radius4"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M335.26,1005.59c-24.07,39.48-13.15,82.74-7.59,86.01"
          />
          <path
            id="ulna5"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M182.8,1139.45c-3.38-29.98-8.38-57.98-15.38-87.98-4-18-8-35-18-51-2-3-4-5-7.69-4.56"
          />
          <path
            id="partofancon9"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M341.33,890.64c26.03-22.3,26.43-53.94,13.67-64.25"
          />
          <path
            id="partofancon8"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M296.22,753.93c15.72,20.51,14.06,41.01,31.44,41.01"
          />
          <path
            id="partofancon7"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M169.76,837.32c0-18.14-12.53-32.81-28.03-32.81"
          />
          <path
            id="partofancon6"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M217.95,715.65c15.83,38.55,40.92,83.63,56.39,88.86"
          />
          <path
            id="partofancon5"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M274.34,19.21c-26.37,21.56-16.08,621.05.01,649.95"
          />
          <path
            id="partofancon4"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M63.81,819.55c-2.23-.62-16.37-4.8-23.24-19.14-5.69-11.87-4.32-26.34,2.75-35.52,8.2-10.65,18.84-7.39,39.63-16.43,17.63-7.67,28.67-18.15,36.91-25.97,11.28-10.71,22.39-25.05,35.54-54.68,7.62-17.17,19.17-46.75,30.08-106.63,4.42-24.27,25.98-503.04,28.95-541.97"
          />
          <path
            id="partofancon3"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M190.95,727.95c-1.27,5.81-4.05,14.06-10.94,20.51-7.91,7.4-14.03,5.7-20.51,12.3-8.56,8.74-9.09,23.19-6.84,32.81,4.48,19.07,22.72,27.33,28.71,30.08,14.83,6.8,43.95,12.7,57.42-4.1,13.4-16.72,1-43.06,0-45.11-8.03-16.48-22.42-23.75-27.34-25.97"
          />
          <path
            id="partofancon2"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M262.2,794.94c-36.77,22.2-37.42,84.36-12.46,124.95"
          />
          <path
            id="radius3"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M355,938.48c0,1.51-43.06,25.97-106.63,2.73"
          />
          <path
            id="ulna4"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M223.76,1000c3.23-3.94,6.64-9.55,6.84-16.41.19-6.74-2.91-8.56-4.1-17.77-1.46-11.27,2.54-13.43,0-19.14-3.69-8.3-15.26-10.67-23.24-12.3-6.06-1.24-16.06-3.29-25.97,1.37-7.37,3.46-6.37,6.72-15.04,12.3-11.11,7.15-22.36,7.98-25.97,8.2-6.47.4-16.95,1.05-27.34-5.47-9.16-5.74-13.39-14-15.04-17.77"
          />
          <path
            id="ulna3"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M93.89,933.02c0,23.82,14.06,43.09,31.44,43.09"
          />
          <path
            id="ulna2"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M93.89,907.04c23.37,4.06,34.45,55.58,38.47,88.8"
          />
          <line
            id="ulna1"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            x1="109.61"
            y1="858.77"
            x2="107.56"
            y2="893.37"
          />
          <line
            id="partofancon1"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            x1="341.33"
            y1="804.51"
            x2="367.31"
            y2="822.28"
          />
          <path
            id="radius2"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            d="M280.41,1125.78l-3.46,14.84"
          />
          <line
            id="radius1"
            class="oberarm_mit_ellegnbogen_vd_links2229_cls5"
            x1="267.56"
            y1="1048.6"
            x2="286.48"
            y2="1099.8"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="radialheadhighlight"
          class="RadiusHead2229Fill"
          style={{
            fill: props.colors.RadiusHead2229Fill,
            stroke: props.colors.RadiusHead2229Fill,
          }}
          d="M247.59,988.18c3.48,1.1,5.79-.6,9.44-1.35,6.24-1.28,7.37,2.08,15.91,2.7,7.67.55,7.72-2.09,16.45-1.89,7.48.17,7.99,2.13,14.83,2.16,7.69.03,7.78-2.44,16.52-3.44,12.44-1.42,16.59,3.07,21.97-.84,3.27-2.37,2.45-4.55,7.78-8.8,2.46-1.97,4.11-2.64,7-5.39,2.41-2.29,3.71-3.52,4.73-5.33,4.43-7.83-.82-18.41-2.02-20.83-.92-1.85-1.67-3.37-3.24-5.06-2.81-3.03-6.6-4.81-13.75-6.07-6.14-1.08-14.35-2.53-24.83-.24-9.94,2.17-18.56,5.1-18.56,5.1-2.47.84-5.31,1.88-9,1.41-1.16-.15-2.28-.43-8.49-2.83-6.07-2.35-5.53-2.28-6.67-2.63-6.34-1.95-12.1-.68-14.32-.16-3.23.75-8.17,1.9-12.01,5.83-2.83,2.9-4.05,8.84-6.37,20.6-.88,4.44-2.76,9.67-2.49,16.2.1,2.3.43,4.96,2.26,7.35.6.78,2.23,2.68,4.85,3.51Z"
        />
        <path
          id="epicondylehighlight1"
          class="Epicondylen2229Fill"
          style={{
            fill: props.colors.Epicondylen2229Fill,
            stroke: props.colors.Epicondylen2229Fill,
          }}
          d="M72.57,819.24c1.49,9,6.51,10.96,4.93,18.2-1.25,5.77-5.18,7.94-4.25,13.35.57,3.32,2.63,5.83,1.37,9.04,0,0-.62,1.59-2.18,2.89-4.19,3.51-14.47,2.19-22.57-1.74-14.46-7.01-20.43-21.58-22.87-27.53-5.02-12.23-5.3-23.62-4.65-31.34,1.05-7.87,3.36-13.82,5.19-17.66,0,0,6.19-12.99,19.36-23.52,2.21-1.77,3.13-2.13,6.32-4.8,3.85-3.22,4.67-4.51,6.67-4.65,2.61-.19,5.51,1.68,6.47,4.04.91,2.24-.63,3.21.24,5.59,1.02,2.81,3.6,2.66,4.81,5.34,1.07,2.34-.1,4.21-.38,6.65-.66,5.82,4.47,8.64,6.85,13.78,4.83,10.42-7.9,16.92-5.34,32.36Z"
        />
        <path
          id="epicondylehighlight2"
          class="Epicondylen2229Fill"
          style={{
            fill: props.colors.Epicondylen2229Fill,
            stroke: props.colors.Epicondylen2229Fill,
          }}
          d="M347.36,799.33c-.98.14-4.75.72-8.25,3.46-3.2,2.5-3.64,4.81-6.83,9.54-3.33,4.93-4.15,4.34-7.83,9.2-1.9,2.52-6.2,8.29-8.36,15.91-1.74,6.14-.05,6.26-1.35,15.37-1.67,11.78-4.65,12.67-4.23,20.11.45,7.95,3.91,7.86,4.23,15.22.45,10.29-6.2,13.18-4.85,21.03,1.11,6.49,6.93,12.01,12.67,14.02,14.46,5.06,29.74-11.7,31.62-13.75,8.19-8.98,12.91-21.96,13.55-25.48.11-.61.38-2.52,1.42-4.85.88-1.97,1.54-2.53,2.37-4.35.98-2.14,1.24-3.93,1.45-5.45,1.95-13.66.15-26.61.15-26.61-4.47-32.17-6.06-34.57-8.22-36.67-1.07-1.03-8.44-7.99-17.54-6.69Z"
        />
        <path
          id="jointlinehighlight"
          class="Gelenkspalt2229Fill"
          style={{
            fill: props.colors.Gelenkspalt2229Fill,
            stroke: props.colors.Gelenkspalt2229Fill,
          }}
          d="M95.94,907.52c4.56,4.91,6.94,10.63,5.86,14.38-.59,2.04-2.96,6.19-4.38,7.99-.03.04-.13.16-.26.33-.8,1.04-2.34,3.03-2.66,4.08-.48,1.59,1.11,3.36,3.44,5.88,4.97,5.38,7.46,8.07,7.96,8.46,1.8,1.4,6.04,4.42,12.26,6.3,4.95,1.49,8.81,1.55,12.74,1.62,3.18.05,9.23.1,16.79-2.02,3.83-1.08,9.08-2.55,14.56-6.5,5.73-4.12,6.09-6.89,12.74-10.9,2.32-1.4,4.94-2.95,8.7-3.84,4.45-1.06,7.87-.56,14.54.49,4.84.76,10.03,1.58,15.8,3.76,1.64.62,5.31,2.09,10.59,3.28.84.19,1.68.39,2.82.57,9.04,1.42,16.39-.29,18.29-.76,1.34-.33,2.32-.65,3.15-.96,2.14-.81,3.66-1.54,4.1-1.76,2.24-1.12,8.27-3.57,14.04-4.01,3.25-.25,5.81.31,10.92,1.42,8.91,1.93,9.8,3.45,14.36,4.25,6.89,1.2,7.02-1.89,22.65-4.65,3.34-.59,12.46-2.06,20.77-1.17,2.87.31,5.46.58,8.7,1.62.53.17,2.61.86,6.07,2.02,3.3,1.11,3.55,1.2,4.04,1.13,3.21-.44,6.28-6.39,5.67-11.65-.79-6.82-7.87-12.59-12.74-11.73-1.91.34-2.23,1.48-6.09,4.21,0,0-3.44,2.43-9.22,5.05-9.03,4.09-18.42,4.37-22.45,4.45-5.66.12-10.51-.68-19.98-2.27-15.94-2.68-17.73-4.27-28.61-5.44-4.98-.53-2.31.05-19.37-.79-19.44-.95-23.84-1.74-28.57-3.33-8.35-2.81-12.13-4.58-12.13-4.58-5.73-2.68-7.15-4.14-10.46-4.18-3.76-.04-6.54,1.79-10.85,4.71-5.34,3.62-5.63,5.16-11.64,10.18-6.46,5.39-11.88,8.43-13.37,9.24-3.34,1.83-7.28,3.95-12.95,5.46-5.53,1.47-9.52,1.51-13.09,1.51-5.54,0-7.33-.8-8.54-1.76-2.01-1.6-2.42-3.63-3.28-6.05-2.22-6.23-5.77-10.33-9.41-14.54-2.28-2.63-5.93-6.21-11.51-9.5Z"
        />
      </g>
    </svg>
  );
}
