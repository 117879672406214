import React, { useEffect, useState } from "react";
import { Container, Row, Col, ListGroup, Card, Button } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../Redux/mappers/reports/reportsMappers";
import {
  loadFacilityInfo,
  loadRefferingPhysicianInfo,
} from "../../../Utilities/Facilities/FacilityLoaders";
import styles from "./stylesheets/ReportSummary.module.scss";
import { MakePostObject } from "../../../Utilities/AuthHeader";
import { useTranslation } from "react-i18next";

function ReportSummary(props) {
  const [selected, setSelected] = useState([]);
  const [nameSelectorPrim, setNameSelectorPrim] = useState("");
  const [nameSelectorSec, setNameSelectorSec] = useState(null);
  const [reportType, setReportType] = useState(props.reports.repType);
  const [isStaticType, setIsStaticType] = useState(false);
  const { t, i18n } = useTranslation(["reports", "common"]);

  useEffect(() => {
    switch (props.reports.repType) {
      case "FacReport": {
        setNameSelectorPrim("name");
        setReportType(t("summary.refFacility"));
        var fac_infos = [];
        props.reports.repSelectedIds.forEach((id) => {
          fac_infos.push(loadFacilityInfo(id, props.keycloak));
        });
        Promise.all(fac_infos).then((values) => {
          setSelected(values);
        });
        break;
      }
      case "RefPhysReport": {
        setNameSelectorPrim("name");
        setNameSelectorSec("vorname");
        setReportType(t("summary.refPhysician"));
        var fac_infos = [];
        props.reports.repSelectedIds.forEach((id) => {
          fac_infos.push(loadRefferingPhysicianInfo(id, props.keycloak));
        });
        Promise.all(fac_infos).then((values) => {
          setSelected(values);
        });
        break;
      }
      case "RadiologistReport": {
        setReportType(t("summary.radiologist"));
        setIsStaticType(true);
        break;
      }
      case "DemographicReport": {
        setReportType(t("summary.demographic"));
        setIsStaticType(true);
        break;
      }
      case "DemographicDoseReport": {
        setReportType(t("summary.demographicDose"));
        setIsStaticType(true);
        break;
      }
      case "PatDoseReport": {
        setReportType(t("summary.patDose"));
        setIsStaticType(true);
        break;
      }
      case "PatReport": {
        setReportType(t("summary.patRecord"));
        setIsStaticType(true);
        break;
      }
      case "ExternalOrdersReport": {
        setReportType(t("summary.externalOrdersReport"));
        setIsStaticType(true);
        break;
      }
      default: {
        setReportType(t("summary.defRepType"));
        setIsStaticType(true);
        break;
      }
    }
  }, [props.reports.repSelectedIds, props.reports.repType]);

  const postData = () => {
    if (
      props.configuration !== undefined &&
      props.reports.configuration === null
    ) {
      alert(t("summary.chooseConfig"));
    } else {
      fetch(
        `${window.conf.SERVER_CONFIG.BASE_URL}/tasks/report`,
        MakePostObject(props.keycloak, props.reports)
      ).then((response) => {
        if (response.ok) {
          alert(t("summary.generationMessage"));
        } else {
          alert(t("summary.generationErrorMessage"));
        }
      });
    }
  };

  const reportPackage = (value) => {
    props.setReportIsPackage(value);
  };

  const addRemoveVariant = (value) => {
    props.addRemoveReportVariant(value);
  };

  const toggleConfiguration = (value) => {
    props.toggleConfiguration(value);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={8}>
          {isStaticType ? (
            <h3>
              {t("summary.youAreCreating")} {reportType}{" "}
            </h3>
          ) : (
            <h3>
              {t("summary.youAreCreating")} {reportType}{" "}
              {t("summary.reportFor")}
            </h3>
          )}
          <ul>
            {(selected.length === 0) & !isStaticType ? (
              <ClipLoader />
            ) : (
              selected.map((one, i) => (
                <li key={i}>
                  {one[nameSelectorPrim] + " "}{" "}
                  {nameSelectorSec != null && one[nameSelectorSec]}
                </li>
              ))
            )}
          </ul>
          <h3>{t("summary.inDateRange")}</h3>
          <ul>
            <li>
              <span>
                <h5>{t("common:from")}</h5>{" "}
                {props.reports.repDateRange.from.toLocaleDateString()}
              </span>
            </li>
            <li>
              <span>
                <h5>{t("common:to")}</h5>{" "}
                {props.reports.repDateRange.to.toLocaleDateString()}
              </span>
            </li>
          </ul>
        </Col>
        <Col md={4}>
          <Card className={styles.sum_card + " text-center mb-2"}>
            {(Object.keys(props?.additionalSwitches || {}).length > 0 ||
              Object.keys(props?.configuration || {}).length > 0) && (
              <h5 className="my-2">{t("summary.additionalOptions")}</h5>
            )}
            <ListGroup variant="flush" className={styles.typeList}>
              {Object.keys(props.additionalSwitches).map((key) => (
                <ListGroup.Item
                  className={
                    props.reports.variants.indexOf(key) > -1
                      ? styles.typeSelector_active
                      : styles.typeSelector
                  }
                  onClick={() => addRemoveVariant(key)}
                >
                  {props.additionalSwitches[key]}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
          {props.configuration !== undefined && (
            <Card className={styles.sum_card + " text-center"}>
              <h5 className="my-2">{t("summary.columnConfig")}</h5>
              {Object.keys(props.configuration).map((key) => (
                <ListGroup.Item
                  className={
                    props.reports.configuration == key
                      ? styles.typeSelector_active
                      : styles.typeSelector
                  }
                  onClick={() => toggleConfiguration(key)}
                >
                  {props.configuration[key]}
                </ListGroup.Item>
              ))}
            </Card>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12} className="text-center">
          <Button onClick={postData}>
            {t("common:buttons.saveIcon")}
            <i className="fas fa-save" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportSummary);
