import icons from "./static";

const sizes = {
  xxs: "20px",
  xs: "30px",
  sm: "40px",
  md: "50px",
  lg: "60px",
  xl: "70px",
  xxl: "80px",
};

function PmIcon({ iconName, size = "md", customSize = undefined }) {
  return (
    <img
      src={icons[iconName] === undefined ? icons.atom : icons[iconName]}
      alt={`icon ${iconName}`}
      width={customSize ? customSize : sizes[size]}
      height={customSize ? customSize : sizes[size]} //width and height the same to keep icons squared
    />
  );
}

export default PmIcon;
