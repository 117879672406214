import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useKeycloak } from "@react-keycloak/web";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { loadPatient } from "../../../Utilities/Patients/PatientUtils";
import {
  InsertPatientWorklistEntry,
  InsertWorklistEntryOrder,
} from "../../../Utilities/Worklist/WorklistUtils";

import {
  clearExamplannerExamination,
  clearExamplannerPatientId,
  clearExamplannerState,
  clearOpenedPatient,
  clearExamplannerIndication,
  removeExamplannerExamination,
  clearExamplannerBodyPartHistory,
  removeExamplannerIndication,
  clearExamplannerDeviceId,
  clearExamplannerFacilityDocId,
  clearExamplannerFacilityId,
} from "../../../Redux/actions";

function SaveOrderModal({
  shown,
  close,
  resourcesModalShowHandler = undefined,
  selectPatientModalShowHandler = undefined,
}) {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const history = useHistory();

  const [patientDetails, setPatientDetails] = useState(undefined);

  const currentOrderState = useSelector((state) => state.examPlanner);

  const selectedExaminations = useSelector(
    (state) => state.examPlanner.risData.examinations
  );

  const selectedIndications = useSelector(
    (state) => state.examPlanner.risData.indications
  );

  const selectedPatient = useSelector(
    (state) => state.examPlanner.risData.patientId
  );

  var searchParams = new URLSearchParams(window.location.search);

  const validateOrder = () => {
    let output = true;

    if (selectedPatient === null) {
      NotificationManager.warning("Patient not selected");
      output = false;
    }

    if (selectedExaminations.length === 0) {
      NotificationManager.warning("No Examination selected");
      output = false;
    }

    if (currentOrderState.risData.deviceId === null) {
      NotificationManager.warning("Destination device not selected");
      output = false;
    }

    if (
      window.conf.EXAM_PLANNER.FORCE_INDICATIONS &&
      selectedIndications.length === 0
    ) {
      NotificationManager.warning("No Indication selected");
      output = false;
    }

    if (
      window.conf.EXAM_PLANNER.FORCE_FACILITY_SELECT &&
      currentOrderState.risData.facilityId === null
    ) {
      NotificationManager.warning("Facility not selected");
      output = false;
    }

    if (
      window.conf.EXAM_PLANNER.FORCE_REF_PHYS_SELECT &&
      currentOrderState.risData.facilityDocId === null
    ) {
      NotificationManager.warning("Reffering doctor not selected");
      output = false;
    }

    if (
      window.conf.EXAM_PLANNER.FORCE_RADIOLOGIST_SELECT &&
      currentOrderState.risData.radiologistId === null
    ) {
      NotificationManager.warning("Radiologist not selected");
      output = false;
    }

    return output;
  };

  const saveOrderInDb = () => {
    let orderValid = validateOrder();
    if (!orderValid) {
      NotificationManager.error("Can not save order");
      return;
    }
    let orders = [];
    let kontoId = searchParams.get("kontoid");

    InsertPatientWorklistEntry(keycloak, selectedPatient, {
      befmid:
        currentOrderState.risData.radiologistId !== null
          ? currentOrderState.risData.radiologistId
          : 0,
      mid: keycloak.idTokenParsed.pmed_mid,
      aid:
        currentOrderState.risData.facilityId !== null
          ? currentOrderState.risData.facilityId
          : 0,
      arefid:
        currentOrderState.risData.facilityDocId !== null
          ? currentOrderState.risData.facilityDocId
          : 0,
      kontoid: kontoId !== null ? kontoId : 1,
      flag: 1,
    }).then((res) => {
      currentOrderState.risData.examinations.forEach((exam) => {
        orders.push(
          InsertWorklistEntryOrder(keycloak, res, {
            worklist_qmaftraege: {
              resid: currentOrderState.risData.deviceId,
              utyp: currentOrderState.viewControll.selectedDeviceType,
              studydescription: exam.designationde,
              infotext: "",
              indications: currentOrderState.risData.indications,
              exid: exam.id,
              flag: 1,
            },
            worklist_details: {
              info: "",
            },
          })
        );
      });
    });

    Promise.all(orders)
      .then(() => {
        NotificationManager.success("Order Created !");
        afterSuccessSaveActions();
      })
      .catch((reason) => {
        NotificationManager.error("Unable to Create order");
        console.warn("Unable to Create order", reason);
      });
  };

  const afterSuccessSaveActions = () => {
    let { EXAM_PLANNER } = window.conf;

    dispatch(clearExamplannerExamination());
    dispatch(clearExamplannerIndication());
    dispatch(clearExamplannerDeviceId());
    dispatch(clearExamplannerBodyPartHistory());
    dispatch(clearExamplannerFacilityDocId());
    dispatch(clearExamplannerFacilityId());

    // to clear up search params
    history.push("/ExamPlanner");

    if (EXAM_PLANNER.AFTER_ORDER_GO_BACK_TO_JOURNAL) {
      dispatch(clearExamplannerState());
      history.push("/PatientJournal");
      return;
    }
    if (EXAM_PLANNER.AUTO_CLEAR_PAT_AFTER_ORDER) {
      dispatch(clearExamplannerPatientId());
      dispatch(clearOpenedPatient());
    }
    if (EXAM_PLANNER.AUTO_CLOSE_AFTER_SAVE_SUCCESS) close();
  };

  useEffect(() => {
    if (
      !selectedPatient ||
      selectedPatient === null ||
      selectedPatient === undefined
    ) {
      setPatientDetails(undefined);
      return;
    }

    loadPatient(keycloak, selectedPatient)
      .then((data) => {
        setPatientDetails(data);
      })
      .catch((reason) => {
        NotificationManager.error("Patient Loading failed");
        console.warn("Patient Loading failed savemodal", reason);
      });
  }, [selectedPatient]);

  useEffect(() => {
    if (
      shown === true &&
      currentOrderState.risData.deviceId === null &&
      resourcesModalShowHandler
    ) {
      resourcesModalShowHandler();
    }

    if (
      shown === true &&
      selectedPatient === null &&
      selectPatientModalShowHandler
    ) {
      selectPatientModalShowHandler();
    }
  }, [shown]);

  return (
    <Modal show={shown} onHide={close} size="md">
      <Modal.Header closeButton className="p-2">
        <h5>New Order</h5>
      </Modal.Header>
      <Modal.Body>
        <div
          className="d-flex flex-column align-items-center"
          style={{ gap: "5px" }}
        >
          {patientDetails ? (
            <div
              className="border-bottom text-center d-flex flex-column"
              style={{ width: "80%" }}
            >
              <p className="m-0 small text-muted">Patient:</p>
              <h4>
                {`${patientDetails.patientsname} ${patientDetails.patientsvorname}`}
              </h4>
            </div>
          ) : (
            <div
              className="border-bottom text-center d-flex flex-column"
              style={{ width: "80%" }}
            >
              <p className="m-0 small text-muted">Patient:</p>
              <h4 className="text-danger">Patient not selected !</h4>
            </div>
          )}
          <div className="border-bottom" style={{ width: "80%" }}>
            <div className="m-0 my-2 small text-muted text-center">
              Examinations:
            </div>
            <ul className="d-flex flex-column px-0">
              {selectedExaminations.map((examination, key) => (
                <li
                  key={`selexmkey${key}`}
                  className="d-flex border rounded p-1 mb-1"
                >
                  {`${key + 1}. ${examination.designationde}`}
                  <Button
                    size="sm"
                    className="ml-auto"
                    onClick={() =>
                      dispatch(removeExamplannerExamination(examination))
                    }
                  >
                    <i className="fas fa-trash fa-xs" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
          <div style={{ width: "80%" }}>
            <div className="m-0 my-2 small text-muted text-center">
              Indications:
            </div>
            <ul className="d-flex flex-column px-0">
              {selectedIndications.map((indications, key) => (
                <li
                  key={`selindickey${key}`}
                  className="d-flex border rounded p-1 mb-1"
                >
                  {`${key + 1}. ${indications.designationde}`}
                  <Button
                    size="sm"
                    className="ml-auto"
                    onClick={() =>
                      dispatch(removeExamplannerIndication(indications))
                    }
                  >
                    <i className="fas fa-trash fa-xs" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between p-1">
        <Button onClick={close} variant="danger">
          <i className="fas fa-ban fa-sm mr-1" />
          Reject
        </Button>
        <Button onClick={saveOrderInDb}>
          <i className="fas fa-save fa-sm mr-1" />
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SaveOrderModal;
