import styles from "./stylesheets/patform.module.css";
import CSSModules from "react-css-modules";

import { Modal, Button, Form, Col, Row, Container } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { setOpenedPatient } from "../Redux/actions";
import { useDispatch } from "react-redux";
import { PeselDecode } from "../Utilities/Patients/PatientUtils";

function AddPatientModal({ onHide, show }) {
  const [detailsShown, SetDetailsShown] = useState(false);
  const [birthDate, setBirthDate] = useState(undefined);
  const [sex, setSex] = useState(undefined);
  const [decodedPESEL, setDecodedPESEL] = useState(undefined);

  const { register, handleSubmit, reset } = useForm();
  const { keycloak } = useKeycloak();
  const [isAddingInProgress, SetAddingInProgress] = useState(false);
  const { t } = useTranslation(["addpatientform", "common"]);
  const dispatch = useDispatch();

  const polishVer = window.conf.LANG === "PL";

  const onAddNewPatient = (data) => {
    SetAddingInProgress(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ' + ${keycloak.token}`,
      },
      body: JSON.stringify({
        kontoid: keycloak.tokenParsed.pmed_department_id
          ? keycloak.tokenParsed.pmed_department_id
          : 1,
        ...data,
      }),
    };
    fetch(
      `${window.conf.SERVER_CONFIG.BASE_URL}/ris/patients`,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        SetAddingInProgress(false);
        onHide();
        reset();
        dispatch(setOpenedPatient(data.id));
        NotificationManager.success(`${t("notification.addSuccess")}`);

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        NotificationManager.error(`${t("notification.addError")} ${error}`);
      });
  };

  const getStyle = () => {
    if (detailsShown) {
      return "shown";
    } else {
      return "hidden";
    }
  };

  function dateFromPESEL(pesel) {
    if (!polishVer) return;
    let decoded = PeselDecode(pesel);
    if (decoded) {
      setBirthDate(decoded.formatedDate);
      setSex(decoded.sex);
    }
    setDecodedPESEL(decoded);
  }

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="add-patient-modal"
    >
      <Modal.Header className="py-2" closeButton={!isAddingInProgress}>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("header.addPatient")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onAddNewPatient)}>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridID">
              <Form.Label>{t("patBasics.idLabel")}</Form.Label>
              <Form.Control
                {...register("id", {
                  required: true,
                })}
                type="text"
                className={`${
                  decodedPESEL && polishVer && !decodedPESEL?.valid
                    ? "is-invalid"
                    : ""
                }`}
                onChange={(e) => {
                  dateFromPESEL(e.target.value);
                }}
                placeholder={t("patBasics.idPlaceHold")}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridFName">
              <Form.Label>{t("patBasics.nameLabel")}</Form.Label>
              <Form.Control
                {...register("patientsvorname", {
                  required: true,
                })}
                type="text"
                placeholder={t("patBasics.namePlaceHold")}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formLName">
              <Form.Label>{t("patBasics.surnameLabel")}</Form.Label>
              <Form.Control
                {...register("patientsname", {
                  required: true,
                })}
                type="text"
                placeholder={t("patBasics.surnamePlaceHold")}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridDateBirth">
              <Form.Label>{t("patBasics.birthLabel")}</Form.Label>
              <Form.Control
                {...register("gebdat", {
                  required: true,
                })}
                type="date"
                value={birthDate}
                placeholder={t("patBasics.birthPlaceHold")}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridSex">
              <Form.Label>{t("patBasics.sexLabel")}</Form.Label>
              <Form.Control
                {...register("patientssex")}
                as="select"
                value={sex}
                defaultValue="Choose..."
              >
                <option value="">{t("patBasics.valueC")}</option>
                <option value="M">{t("patBasics.valueM")}</option>
                <option value="F">{t("patBasics.valueF")}</option>
                <option value="O">{t("patBasics.valueO")}</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              ld={12}
              className="text-center border my-2"
              styleName="details-btn"
              onClick={() => SetDetailsShown(!detailsShown)}
            >
              <p className="mx-0 px-0 my-0 py-0">
                {t("button.details")}{" "}
                <span
                  className={`fas mx-0 px-0 my-0 py-0 ${
                    detailsShown ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                />
              </p>
            </Col>
          </Row>
          <Container
            className={styles.details + " mx-0 px-0"}
            styleName={getStyle()}
            fluid
          >
            <Form.Row>
              <Form.Group as={Col} md="8" controlId="formStreet">
                <Form.Label>{t("patDetails.streetLabel")}</Form.Label>
                <Form.Control
                  {...register("street")}
                  type="text"
                  placeholder={t("patDetails.streetPlaceHold")}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="8" controlId="formCity">
                <Form.Label>{t("patDetails.cityLabel")}</Form.Label>
                <Form.Control
                  {...register("city")}
                  type="text"
                  placeholder={t("patDetails.cityPlaceHold")}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formZip">
                <Form.Label>{t("patDetails.zipLabel")}</Form.Label>
                <Form.Control
                  {...register("zip")}
                  type="text"
                  placeholder={t("patDetails.zipPlaceHold")}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formPhone">
                <Form.Label>{t("patDetails.phoneLabel")}</Form.Label>
                <Form.Control
                  {...register("phone_nr")}
                  type="text"
                  placeholder={t("patDetails.phonePlaceHold")}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formMail">
                <Form.Label>{t("patDetails.mailLabel")}</Form.Label>
                <Form.Control
                  {...register("e-mail")}
                  type="text"
                  placeholder={t("patDetails.mailPlaceHold")}
                />
              </Form.Group>
            </Form.Row>
          </Container>
          <div className="d-flex flex-row justify-content-between">
            <Button
              variant="secondary"
              onClick={onHide}
              disabled={isAddingInProgress}
            >
              {t("common:buttons.cancel")}
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isAddingInProgress}
            >
              {t("common:buttons.save")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
export default CSSModules(AddPatientModal, styles);
