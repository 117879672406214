import { Modal } from "react-bootstrap";
import PmDateRangePicker from "./PmDateRangePicker";

function DateRangeSelectorModal({
  dateRange,
  dateRangeSetter,
  shown = false,
  hideHandler,
}) {
  return (
    <Modal show={shown} onHide={hideHandler} size="lg">
      <Modal.Header className="p-2" closeButton>
        <h5>Select Date Range</h5>
      </Modal.Header>
      <Modal.Body>
        <PmDateRangePicker
          dateRange={dateRange}
          dateRangeSetter={dateRangeSetter}
        />
      </Modal.Body>
    </Modal>
  );
}

export default DateRangeSelectorModal;
