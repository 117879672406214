import style from "./style.scss";

function AbdomenApStehend1101(props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 527.2 731.6">

  <g id="abdomen">
    <g id="abdomen_layout" data-name="abdomen layout">
      <path id="BG" class="Abdomen_ap_stehend1101_cls7" d="M23.6,28.2c-1,20.5-2.7,49.2-5.3,85.4-.3,4.4-.2,6.1-.6,8.4-6,32.8-6.4,64.5,4.2,94.4l21.1,72.6c2.7,9.2,5.9,18.1,9.4,27.1,6.6,17.2,5.6,36.9-1.7,58.9l-31,96.5c-3.2,12.5-8.3,26-7.5,40.6,4.1,77.9,12.8,126.3,25.3,168.1,41.1,12.9,75.6,20.4,99.4,24.9,110.6,20.6,177.4,10.2,294-8,16.8-2.6,46.3-7.7,63.7-30.8,5.1-6.8,8.4-23.2,15.1-56.1,4.1-20.2,11.7-60.8,11.6-99,0-1.5,0-4,0-7.3-.3-11.8-1.6-30.5-7-53.5-2.7-11.8-3.6-11.9-10.9-36-8.3-27.2-12.4-40.7-14.2-50.5-1.3-6.9-7.5-42.3,0-76.5.9-4,2.8-12.1,5.9-21.8,4.6-14.4,9-24.5,10.5-27.9,8.1-19,10.4-39.6,13.1-63.5,3.9-34.9,1.9-62.6,0-88.5-.5-6.5-.8-10-1.4-15.6-3.7-36.2-5.7-54.4-14.7-61-6.3-4.6-12.3-3-30.1-1.6-21.6,1.7-43.3-.5-65,0-7,.2-32.7,0-84,0-62,0-124,.3-186,0-6.3,0-13.1,0-22.6.5-39,2.5-70.2,12-91.3,20.2Z"/>
      <g id="abdomen_layout-2" data-name="abdomen layout">
        <path id="outlines_right" data-name="outlines right" class="Abdomen1101Fill" style= {{
              fill: props.colors.Abdomen1101Fill,
              stroke: props.colors.Abdomen1101Fill,
              opacity:props.colors.Abdomen1101Fill,
            }} d="M25.2,29.2c-1,20.5-2.7,49.2-5.3,85.4-.3,4.4-.2,6.1-.6,8.4-6,32.8-6.4,64.5,4.2,94.4l21.1,72.6c2.7,9.2,5.9,18.1,9.4,27.1,6.6,17.2,5.6,36.9-1.7,58.9l-31,96.5c-3.2,12.5-6.9,26-7.5,40.6-2.7,67.4,14.1,124.4,25.3,168.1,41.1,12.9,75.6,20.4,99.4,24.9,110.6,20.6,177.4,10.2,294-8,16.8-2.6,46.3-7.7,63.7-30.8,5.1-6.8,8.4-23.2,15.1-56.1,4.1-20.2,11.7-60.8,11.6-99,0-1.5,0-4,0-7.3-.3-11.8-1.6-30.5-7-53.5-2.7-11.8-3.6-11.9-10.9-36-8.3-27.2-12.4-40.7-14.2-50.5-1.3-6.9-7.5-42.3,0-76.5.9-4,2.8-12.1,5.9-21.8,4.6-14.4,9-24.5,10.5-27.9,8.1-19,10.4-39.6,13.1-63.5,3.9-34.9,1.9-62.6,0-88.5-.5-6.5-.8-10-1.4-15.6-3.7-36.2-5.7-54.4-14.7-61-6.3-4.6-12.3-3-30.1-1.6-21.6,1.7-43.3-.5-65,0-7,.2-32.7.1-84,0-62-.1-124,.3-186,0-6.3,0-13.1-.1-22.6.5-39,2.4-70.3,12-91.3,20.2Z"/>
      </g>
    </g>
    <g id="spine">
      <path id="spine_1" data-name="spine 1" class="Abdomen_ap_stehend1101_cls8" d="M240.4,25.2c34.8,1.3,55.4-1,57.2-7.8"/>
      <path id="spine_2" data-name="spine 2" class="Abdomen_ap_stehend1101_cls8" d="M233.9,32c3.6,9.4,5.6,19,4.6,29.3-2.2,9.4,1.1,12,7.7,10.6l44.8-1.3c13,2.9,19.4,1.7,10.9-9.1-8.9-10.2-9.4-22.1-4-35-.2,0-64,5.5-64,5.5h0Z"/>
      <path id="spine_3" data-name="spine 3" class="Abdomen_ap_stehend1101_cls8" d="M231.9,88.9c1.3-4.6,3.2-8,8.5-5.4,20.9.8,40.6,0,58.5-3.2,1.5,3.6,1.7,7.5-1,11.7-2.1,3.2-2.9,7.3-1.8,10.9.8,2.7,2.2,5.4,4,8.4,4.9,3.9,6,8.2,2.9,13.1l-59.6,4.5c-10.3,1.7-11.7-5.2-10.9-14.2,3.5-9.8,3.7-18.6-.6-25.8h0Z"/>
      <path id="spine_4" data-name="spine 4" class="Abdomen_ap_stehend1101_cls8" d="M260.5,54.5c-2.2,14.8-3.4,27.1,0,30.7,8.5.4,10.1-9.1,6.6-26.2"/>
      <path id="spine_5" data-name="spine 5" class="Abdomen_ap_stehend1101_cls8" d="M263.6,113l-3.8,17c-2.8,7.3-2.4,11.9,3.8,11,4.6-.1,6.3-3.9,4.7-12l-2.1-14.7"/>
      <path id="spine_6" data-name="spine 6" class="Abdomen_ap_stehend1101_cls8" d="M237.9,149.7c-2.7-6.6.3-10.1,7.8-10.9l48.7-2.1c6-.8,8.9,1.1,8.2,6.4-1.7,12.6-1.4,23,2.9,29.3,5,7,3.8,11.3-3.5,13-18.1-1.5-35.9-.3-53.2,3.6-7.7,1.3-11.3-1.4-8.5-10.3,2.5-11.4,2.1-21.3-2.4-29h0Z"/>
      <path id="spine_7" data-name="spine 7" class="Abdomen_ap_stehend1101_cls8" d="M245,218.8c-5.9-10.9-6.8-17.7,1.1-17.2,16.9-3.8,35.2-5,55.1-3.8,9.1,2,11.3,7.4,7.4,15.6-1.3,8.7-.4,16.1,3.9,21.6,2.2,8.9.4,14-6.8,13.8-19.1-5-37.3-4.6-54.6,0-8.9,1.8-9.8-2.8-7.7-9.9l2.4-16.1c.2-1.2-.1-2.7-.8-4h0Z"/>
      <path id="spine_8" data-name="spine 8" class="Abdomen_ap_stehend1101_cls8" d="M241.1,272.3c-1.8-5.9-1-9.4,4-9.4,22.6-6.1,44.4-6.1,65.1,1.3,6,2.8,6.4,7.1,1.7,12.7-5,9.9-6.4,18.7,0,25.4,6.3,9.2,4.6,12.7-6.3,9.5-20.2-4.2-39.9-5.2-59.2-2.5-12.3,1.4-14.4-2.5-8.1-10.9,6.7-7.8,8.5-16.5,2.8-26.1h0Z"/>
      <path id="spine_9" data-name="spine 9" class="Abdomen_ap_stehend1101_cls8" d="M271.1,237.1c-1.8,14.2-4.7,26.4-11.7,30.6-9.1,3.6-15.4,2-18-6.1-1.5-6,1.4-14.9,5-24.4"/>
      <path id="spine_10" data-name="spine 10" class="Abdomen_ap_stehend1101_cls8" d="M275,242l8.1,16.6c4.2,8.9,9.1,14.5,15.5,11,4.6-1.8,5.3-6.4,4-12.3l-2.9-16.8"/>
      <path id="spine_11" data-name="spine 11" class="Abdomen_ap_stehend1101_cls8" d="M265.8,232.4l4.5,10.3c2.1,4.9,2.8,10.3,1.8,15.6-1,4.7-3.1,7.1-6.3,6.7-5.9.8-8.1-2.8-6.4-10.8.1-.7.4-1.4.8-2,3.9-6.7,4.5-13.3,2-20"/>
      <path id="spine_12" data-name="spine 12" class="Abdomen_ap_stehend1101_cls8" d="M242.5,183c-3.8,8.4-6.7,16.3-2.1,20,5.3,4,9.6,4,13.1,1.3,4.2-4.7,7.5-13.3,10.1-24.2"/>
      <path id="spine_13" data-name="spine 13" class="Abdomen_ap_stehend1101_cls8" d="M259,182.1c-1.1,5.2-.8,10.2,1.5,14.8,2.4,4.6,9.1,3.9,10.6-.8v-17.7"/>
      <path id="spine_14" data-name="spine 14" class="Abdomen_ap_stehend1101_cls8" d="M276.9,180c1.5,9.1,5.7,16.6,13.5,22.1,4,1.5,7.8,2.4,10.3,0,2.4-6.4,1.5-14.5-2-23.7"/>
      <path id="spine_15" data-name="spine 15" class="Abdomen_ap_stehend1101_cls8" d="M236.9,340.2c-5.6-9.4-3.9-14,4.2-14.4,23.7-3.4,45.5-4,63.8,0,7.4,1.5,10.6,4.9,6.8,11-4.2,9.2-4.6,17.9,1.8,25.8,3.8,6.7,2.2,11-6.8,11.9l-66.2-1.3c-10.6.3-13.4-3.6-6.6-13.1,4.6-5.3,5.5-11.8,3-19.9h0Z"/>
      <path id="spine_16" data-name="spine 16" class="Abdomen_ap_stehend1101_cls8" d="M245.3,292.7c2.2,4.3,2.2,9.2,0,14.2-3.5,6.6-5.4,12.4-4.9,17.6.4,3.6,3.6,6.4,7.4,6.7,5.3.3,9.4-1.3,11.2-5.3,3.6-6.6,6.7-14.7,8.7-25"/>
      <path id="spine_17" data-name="spine 17" class="Abdomen_ap_stehend1101_cls8" d="M272.8,303.4c3.6,15.4,8.4,27.2,16.8,29.7,9.2,1.5,13-4,13.5-14-1-7.3-1.4-14.1-1-20.5"/>
      <path id="spine_18" data-name="spine 18" class="Abdomen_ap_stehend1101_cls8" d="M231.9,400.2c-4.2-7.1-2.9-10.6,2.9-11,7.8-.7,16.5-.1,26.2,2,17.3,2.2,33.6,2.9,46.5-2,5.9.7,10.3,2.2,8,7.7-6.6,9.5-9.6,18.7-3.1,27.4,7.4,10.5,6.6,14.8-1.1,14.2-18.6-5.9-36.2-7.1-52.4-2.9-19.5,7-31,8.4-26.8-1.4,1.1-2.5,2-5,2.2-7.7,1.2-10.2.5-19.2-2.4-26.3h0Z"/>
      <path id="spine_19" data-name="spine 19" class="Abdomen_ap_stehend1101_cls8" d="M239.2,366c-4.7,10.8-4.9,19.1,1.3,24.2,10.3,4,17.6,2.1,20.4-8.2.3-5.7,1.1-11,2.9-15.9"/>
      <path id="spine_20" data-name="spine 20" class="Abdomen_ap_stehend1101_cls8" d="M270.3,349.7c2.2,9.8.1,20.5-2.8,31.4-2.4,4.3-4.6,3.9-6.8.8-1-5.7.3-12,2.9-18.7,2.2-3.6,2.9-7.5,2.1-11.7"/>
      <path id="spine_21" data-name="spine 21" class="Abdomen_ap_stehend1101_cls8" d="M271.1,370c2.5,9.9,5.7,18.8,12.7,21.2,7.3,2.1,14.1,1.1,20.8-2.4,5.4-3.4,5-7.7,2.8-12.4-2.2-4.9-3.9-9.6-4.3-14.2"/>
      <path id="spine_22" data-name="spine 22" class="Abdomen_ap_stehend1101_cls8" d="M220,495.1c-11-4.2-9.1-12.4-3.8-21.6,7.5-8.4,10.5-16.6,8.7-24.4-1.3-6.7-.1-10.9,5.2-10.6,21.8,9.5,49.3,7,78.9,0l9.5,6.6c4,5.4,4.3,10.3,0,14.8-.8,3.8.3,7.4,4,11,5.3,1.1,6.8,6.3,6.4,13.5-3.4,8.9-14.4,8.5-28.6,4-14.7,8.9-23.9,5.7-29.9-4.6-3.8,6.1-10.2,9.5-20.7,8.5-4.1,5.2-15.1,5.4-29.7,2.8h0Z"/>
      <path id="spine_23" data-name="spine 23" class="Abdomen_ap_stehend1101_cls8" d="M275.2,424.1l9.5,19c4.9,5.7,10.3,6.8,16.8,1.4,5.9-3.8,9.6-7.1,9.4-10.2"/>
      <path id="spine_24" data-name="spine 24" class="Abdomen_ap_stehend1101_cls8" d="M263.6,400.2c-1.5,11.4-2.2,22.1,5.2,24.9,6.8,1.3,8.2-7,3.8-24.9"/>
      <path id="spine_25" data-name="spine 25" class="Abdomen_ap_stehend1101_cls8" d="M249.6,492.5c-6.6-6.7-6.3-14,0-21.6,9.5-9.6,20.7-12.6,33.8-7,12.3,5.9,16.8,14.5,16.8,24.9"/>
      <path id="spine_26" data-name="spine 26" class="Abdomen_ap_stehend1101_cls8" d="M288,457.2c-6.3-1.8-10.5-5.4-12.8-10.5"/>
      <path id="spine_27" data-name="spine 27" class="Abdomen_ap_stehend1101_cls8" d="M261.6,446.7c-2.8,5-6.8,8.9-12,11.6"/>
      <path id="spine_28" data-name="spine 28" class="Abdomen_ap_stehend1101_cls8" d="M260.4,423.2c-2.7,12.4-7.3,20.1-14.1,22.6-7.5,2.5-10.8-2.2-13.1-8.5"/>
      <path id="spine_29" data-name="spine 29" class="Abdomen_ap_stehend1101_cls8" d="M236.9,216.2l-17-1.5c-10.5.6-15.5,3.9-16.2,9.6-.4,2.9,2.1,5.4,5,5.2,1.1-.1,2.4-.6,3.8-1.1,5.2-2.1,11.2-1.4,15.4,2.5l.3.3c.8.8,2,1.3,3.2,1.3h6.7"/>
      <path id="spine_30" data-name="spine 30" class="Abdomen_ap_stehend1101_cls8" d="M304.4,219.1l8.2,1.7c3.9.8,7.7.8,11.2-1,1.5-.8,3.2-1.3,5-1.1,2.8.3,5,1.7,6.7,4.3,1,1.5,1.5,3.4,1.4,5.2-.3,3.6-2.8,5.7-7.5,6.4h-6.3c-1.4,0-2.9-.1-4.2-.7-6.8-2.5-13.3-2-19.3,1.3"/>
      <path id="spine_31" data-name="spine 31" class="Abdomen_ap_stehend1101_cls8" d="M239.7,276.2c-3.6,4.6-9.5,4.6-16.6,1.5-4.3-1.5-8-2.2-10.9-2.4-5.3-.1-9.9,4.3-9.6,9.6.1,3.4,2.2,5.4,7.8,5.4,9.9-.1,18.8.8,26.4,3.8"/>
      <path id="spine_32" data-name="spine 32" class="Abdomen_ap_stehend1101_cls8" d="M311.5,277.9l7.4,1.5c5.6,1.1,10.1.8,13.5-.8,2.9-1.8,5.4-2.4,7.7-1.3,1.8,1,2.9,2.9,2.7,4.9-.3,2.5-2.7,4.6-6.1,6.3-1.7.8-3.5,1.1-5.4.8l-6-.7c-6.7-.7-12.4,1-17.5,4.9"/>
      <path id="spine_33" data-name="spine 33" class="Abdomen_ap_stehend1101_cls8" d="M235.7,339.6c-3.1.6-6-.4-8.9-2.4-6.8-3.8-14-5-21.2-4.9-3.1,0-6.1.8-8.7,2.7-2.9,2.1-4.3,4.7-4.6,7.8,0,4,2.5,5.9,8.4,5,9.1-1,17.9,1,26.2,5.4,1.1.6,2.2.8,3.4.8h3.4"/>
      <path id="spine_34" data-name="spine 34" class="Abdomen_ap_stehend1101_cls8" d="M307.7,336.5h10.1c1,0,2,.1,2.9.6,4.7,2,9.2,1.8,13.4-.1.6-.3,1.1-.6,1.8-.6,6.7-1.5,11.7-.7,13,5.3.4,2.2-.1,4.3-1.8,6-2.2,2.4-5.7,3.2-8.9,2.7-10.5-1.8-19.3-1.1-26.2,1.8"/>
      <path id="spine_35" data-name="spine 35" class="Abdomen_ap_stehend1101_cls8" d="M231.8,394.5c-3.9,1.5-8,1.4-12.4-.4-.7-.3-1.4-.4-2.1-.4-6.7-.1-11.9.3-15.4,1.4-4.9,1.5-6.3,8.2-2.1,11.2,1.5,1.1,3.4,1.5,5.6,1.1,5.2-.7,11.2,2.1,17.6,6.4,1.1.7,2.4,1,3.8.7l14.5-2.7"/>
      <path id="spine_36" data-name="spine 36" class="Abdomen_ap_stehend1101_cls8" d="M307.7,394.1l8.5,2.4c4.6.7,8.8.4,12.7-1.1,1.4-.6,2.8-.8,4.3-1,4.9-.6,9.5-.7,11.2,2.8,1.4,4.2-1.1,6.8-7.4,7.7-8.2-.4-15.2.7-20.2,4-1.7,1.1-3.8,1.7-5.7,1.7h-10.1"/>
      <path id="spine_37" data-name="spine 37" class="Abdomen_ap_stehend1101_cls8" d="M218.4,474.9c-8,.1-14.2-2.2-16.9-9.5-2.1-4.3-5.3-6.7-9.1-7.7-1.4-.4-2.7-1.4-3.1-2.8-.6-1.5,0-3.1,1.5-4.5,2.4-2.2,5.7-2.8,8.8-1.7,10.9,3.5,20.8,5.2,27.6.4"/>
      <path id="spine_38" data-name="spine 38" class="Abdomen_ap_stehend1101_cls8" d="M314.1,444.9c6.8,2.2,12.7,2.8,17.2.1,2-1.1,3.9-2.4,6-3.2,5.4-2.2,10.1-3.4,13.3-2.4,2,.6,3.2,2.2,3.4,4.3.1,2.7-1.5,4.9-4,7-6.1,4.6-13,8.4-20.5,11.4-4.6,1.3-8.4,4.6-12,8.5"/>
    </g>
    <path id="psoas_edge_left" data-name="psoas edge left" class="Abdomen_ap_stehend1101_cls8" d="M311.1,141.8c4.5,56.8,9.5,111.7,24.3,139.3l56.7,186.5"/>
    <path id="psoas_edge_right" data-name="psoas edge right" class="Abdomen_ap_stehend1101_cls8" d="M229.5,184.4c-9.2,29-27.9,88.7-50.3,159.4-4.3,29.2-13,74.7-22.2,122"/>
    <path id="lower_liver_edge" data-name="lower liver edge" class="Abdomen_ap_stehend1101_cls8" d="M55.4,377.3c15.9-11.9,31.7-28.3,47.5-48,52.1-56.8,99.1-109.3,126.6-144.9"/>
    <path id="diaphragm_right" data-name="diaphragm right" class="Abdomen_ap_stehend1101_cls8" d="M256.1,17.4c7.7,35.9,3.5,67.4-18,92.8-30.7-22.6-60.6-39.1-88.9-46.5-52.8-5.6-92.7,11.6-120.3,50.8-3.2-27.8.8-55,9.9-82"/>
    <path id="diaphragm_left" data-name="diaphragm left" class="Abdomen_ap_stehend1101_cls8" d="M302.3,16.1c.3,27.5,3.9,55.4,11.9,83.6,54.4-55,115.3-78.5,190.3-33.8-1.1-17.7-4.9-35.2-10.9-52.8"/>
    <g id="air">
      <path id="air_1" data-name="air 1" class="Abdomen_ap_stehend1101_cls8" d="M340.7,267.6c3.2-4.5,7.5-5.3,13.3-1,6.8,4.6,9.6,10.1,7.3,16.8-2.9,8-8.5,8.5-15.1,6.8-10.7-6.2-11.3-13.8-5.5-22.6h0Z"/>
      <path id="air_2" data-name="air 2" class="Abdomen_ap_stehend1101_cls8" d="M370.7,223.6c6-3.6,10.1-4.2,11,.1.1,1,.6,2,.8,2.8,2.9,6.7,3.6,13.8,2.7,21.4-1.4,7-3.5,12.1-6.3,15.9-3.2,4.3-8.8,6.1-14.1,4.9-6.3-1.5-10.6-4.9-13.3-9.9-1.8-3.4-2.5-7.4-2.2-11.2.7-9.8,6.6-16.9,17.3-21.8,1.4-.7,2.8-1.5,4.1-2.2h0Z"/>
      <path id="air_3" data-name="air 3" class="Abdomen_ap_stehend1101_cls8" d="M399.6,276.4c-2,1.5-4.7,1.3-6.3-.7-7.7-9.6-9.1-20.4-7-31.8,2.1-13.1,6.8-17.6,14.2-13,6.7,3.2,9.5,9.1,8.9,17-1.2,15.3-4.6,24.3-9.8,28.5h0Z"/>
      <path id="air_4" data-name="air 4" class="Abdomen_ap_stehend1101_cls8" d="M439,241.7c.1-2.2-.1-4.6-.8-6.7-2.4-6.1-7.4-7.7-13.7-6.7-9.1-.1-12.8,4.7-15.9,10.2,2.5,10.6.3,20.9-3.6,31,1.4,4.5,4.2,6.8,9.4,6,2.7.3,5.4-.1,8.1-1.1,6.8-2.2,11.9-8,13.8-14.8,1.3-5.4,2.2-11.4,2.7-17.9h0Z"/>
      <path id="air_5" data-name="air 5" class="Abdomen_ap_stehend1101_cls8" d="M439,234.6c3.4-2.5,7.3-3.4,12.1-1.1,4.2,3.1,7.7,7,7.7,14-.4,8.1-3.5,15.2-10.6,20.7-8.9,8.4-15.2,10.1-17.9,2.4,6.7-10.6,9.5-22.3,7.7-35.6"/>
      <path id="air_6" data-name="air 6" class="Abdomen_ap_stehend1101_cls8" d="M356.6,147.5c-3.8-6.6-1.5-12.8,2.1-19,1.4-2.4,2.5-4.9,3.2-7.5,4.2-15.9,11-29.6,22.2-39.5,8-6.4,16.3-7.1,25.4-2.1,1,.6,2,1.1,2.9,1.5,8.2,4.2,15.6,9.8,21.6,17.9,3.5,4.7,5.4,10.5,5.6,16.3.1,6.4-.8,11.7-3.5,14.9-.8,1-1.7,2.1-2.4,3.2-5.7,9.9-14.1,15.9-25,18l-29.3.8c-3.2.1-6.3.4-9.5.8-5.1.7-10,0-13.3-5.3h0Z"/>
      <path id="air_7" data-name="air 7" class="Abdomen_ap_stehend1101_cls8" d="M459.8,81.2c2.2-1.1,4.5-1.5,6.7-1.3,2.5.3,4.9,1.7,6.7,3.6,3.8,4.3,7.4,9.1,10.9,14.1,1.7,2.5,3.4,5.3,4.6,8.1,3.2,7,4.2,12.6,1,15.5-4.2,4.2-10.9,7.4-19.4,9.8-3.5,1-7.3,1.1-10.8.1-5.9-1.7-8.8-5.9-9.1-12-.7-9.6-.6-18.6,1.1-26.2.9-4.9,3.8-9.3,8.3-11.7h0Z"/>
      <path id="air_8" data-name="air 8" class="Abdomen_ap_stehend1101_cls8" d="M209.8,294.5c4.7-6,3.5-12.8-1-20.2-5.6-5.4-12-7.1-19.3-6.6-6.6,2.4-10.3,6.7-12.8,11.7-2.1,4.6-.4,8.1,6.1,10.2,6,.1,10.6,2,14.4,4.7,5,3.1,9.6,4.4,12.6.2h0Z"/>
      <path id="air_9" data-name="air 9" class="Abdomen_ap_stehend1101_cls8" d="M165.7,298.6c-3.6-3.1-7.8-2.2-12.4,1l-2.5,9.1c-2.8,2.8-2.1,5.3,3.4,7.4,4.5,1.4,8.4-.1,11.6-4.2,1.9-2.7,2.2-6.9-.1-13.3Z"/>
      <path id="air_10" data-name="air 10" class="Abdomen_ap_stehend1101_cls8" d="M199.7,329.4c-4.5-.7-7.1,1.5-8.2,6.3-2.1,7.3-2.7,14.2,2.2,20.4,5,8.1,9.6,12.3,13.3,9.2,4.9-.4,7.8-5.9,10.2-13.3,2.8-8.7-1.5-15.6-8.8-21.9l-8.7-.7h0Z"/>
      <path id="air_11" data-name="air 11" class="Abdomen_ap_stehend1101_cls8" d="M158.2,394.8c-4.2-2.9-4-5.3-.7-7.1,2.5-2.7,6-2.2,9.6-1,1.7,2.4,1.8,4.6,0,6.8-2.8,1.5-5.8,1.7-8.9,1.3h0Z"/>
      <path id="air_12" data-name="air 12" class="Abdomen_ap_stehend1101_cls8" d="M372.8,316.4c-2.5.7-3.4,2.2-3.1,4-.1,3.5.8,5.4,3.8,4.2,4.2-1.3,5.7-2.7,5.9-4-.8-5.3-3.1-6-6.6-4.2h0Z"/>
      <path id="air_13" data-name="air 13" class="Abdomen_ap_stehend1101_cls8" d="M384.4,318.8c-3.9.1-5-2.8-4.9-7-.8-3.6.1-7.3,2.5-11,2.5-2.9,5.6-3.4,9.4-1.5,2.4,2.7,3.1,6,2.7,9.8-.8,9.1-4.4,11.2-9.7,9.7h0Z"/>
      <path id="air_14" data-name="air 14" class="Abdomen_ap_stehend1101_cls8" d="M403.8,290.9c5.3-2.9,9.5-2.8,11.6,3.6,7,11.4,7.8,18.6,0,19.5-6,3.4-11.6,2.1-17-.8-3.9-2.5-3.2-8.8-1.7-15.9,1.5-2.4,3.7-4.5,7.1-6.4h0Z"/>
      <path id="air_15" data-name="air 15" class="Abdomen_ap_stehend1101_cls8" d="M352.8,336.8c-1.4-3.4-.7-5.6,4.2-5.7l13.7,5.7c2.2-.8,4.5.6,6.8,4,4.6,5.4,4.5,11.3,1.1,17.5-3.5,4.9-7.3,7-11.9,5.3-8-4.5-7.8-9.5-3.4-14.8-5.9-1.1-9-5.7-10.5-12h0Z"/>
      <path id="air_16" data-name="air 16" class="Abdomen_ap_stehend1101_cls8" d="M357.1,382.5c-2.8-.7-4.3-2.5-4.2-5.4.1-3.2,2.2-6.1,5.2-7.4,2-.8,4.3-1.1,6.8-1,1.5.1,3.1.7,4.3,1.7l4,3.1c.7.6,1.5,1,2.5,1.3,3.4,1.1,5.9,3.5,7.3,7.8.7,2.2,2.2,4,4.3,5,4,2.2,6.3,6,5.7,11.9-.1,1.3,1,2.1,2.1,2,5.3-1.1,9.2,0,10.5,5.6,1.1,6.4.8,12-2.7,15.4-.6.6-1.5.7-2.2.3-2.8-1.3-5.4-1.7-8.2-1.1-1.4.3-2.8,0-3.9-.8l-4.7-3.2c-1.8-1.3-3.9-2.4-6.1-2.8-3.4-.7-6.7-5.6-9.9-12.3-.3-.6-.4-1.3-.4-2,.3-3.1-1.3-5.6-4-7.5-1.5-1.1-2.7-2.7-3.1-4.5-.3-2.6-1.4-4.6-3.3-6.1h0Z"/>
      <path id="air_17" data-name="air 17" class="Abdomen_ap_stehend1101_cls8" d="M381,58.1c-3.5,29.6-2.1,57,11,79.6,14,26.4,30.7,49.6,53.8,65.9,25.4,29.5,46.9,39.8,61.7,17"/>
    </g>
    <g id="ribs">
      <g id="ribs_right" data-name="ribs right">
        <path id="ribs_right_1" data-name="ribs right 1" class="Abdomen_ap_stehend1101_cls8" d="M231.9,35.5c-17.5-8.4-35.2-13-53.3-12.1-36,.3-64.6,7.8-84,23.9-17.9,11.5-33.6,36.8-47.7,71.6-10.6,29.5-17.6,60-20.7,91.6"/>
        <path id="ribs_right_2" data-name="ribs right 2" class="Abdomen_ap_stehend1101_cls8" d="M234.8,58.1c-17.2-10.6-34.8-17.5-53.1-18.7-25.8-1.8-48.7,4.3-69.7,15.2-17.2,9.5-33.2,32.8-49,62.3-14.8,32.1-22.8,73.9-27.4,120.2"/>
        <path id="ribs_right_3" data-name="ribs right 3" class="Abdomen_ap_stehend1101_cls8" d="M230.1,90.8c-5.6-5.7-13.1-10.9-26.2-14.8-9.5-3.4-20.5-2.4-32.4,1.3-23.5,7.1-43.6,22.3-57.9,42.2-10.7,14.6-19.8,31.4-27.7,49.6-13.5,26.9-22.6,57.5-28.3,90.7-.6,3.1,1.1,6.4,4.2,7.4,2,.7,3.9.1,5.7-2.2,19.4-49.8,38.7-98.3,56-124.1,18.7-27.1,40.1-45,66.6-45.4,8.9,0,17.9,2.4,25.7,6.8l11,6.3c2,1.1,4.6.3,5.4-2,1.8-5.3,1.5-10.8-2.1-15.8h0Z"/>
        <path id="ribs_right_4" data-name="ribs right 4" class="Abdomen_ap_stehend1101_cls8" d="M35.7,113c1.7,22.9,8.7,44.3,21.5,64.1l33.8,50c5.9,10.5,4.3,16.1-2.8,17.9-10.2-8.5-21.2-24.6-32.8-44.8-13.1-20-20.9-41.2-25.1-63.4"/>
        <path id="ribs_right_5" data-name="ribs right 5" class="Abdomen_ap_stehend1101_cls8" d="M27.7,120.1c-2.8,12.7-5.2,25-5,35.3,1.3,21.6,3.6,41.9,9.1,57.8,7.5,21.1,15.9,40.6,28.9,51.1,2.8.3,4-1.3,4.2-4l-28-69.2c-4.3-10.6-7.5-25.3-10.5-41"/>
        <path id="ribs_right_6" data-name="ribs right 6" class="Abdomen_ap_stehend1101_cls8" d="M228,156c-3.1-5.3-8.1-4-13.3-2l-51.8,16.7c-8.4,2.7-13.8,9.5-17.5,19.3-1.4,3.8,2.2,7.4,6,6.1,3.4-1.1,7.3-3.5,11.7-7.1,23-12.7,44-20.1,60.7-16.6,5.2,2.9,8,.4,7.8-8.1l-3.6-8.3h0Z"/>
        <path id="ribs_right_7" data-name="ribs right 7" class="Abdomen_ap_stehend1101_cls8" d="M223.4,159.9c-.4-2.7,0-4.2,1.4-4.9"/>
        <path id="ribs_right_8" data-name="ribs right 8" class="Abdomen_ap_stehend1101_cls8" d="M224.9,165.8c1.1,2.9,2.4,5.2,3.8,6"/>
      </g>
      <g id="ribs_left" data-name="ribs left">
        <path id="ribs_left_1" data-name="ribs left 1" class="Abdomen_ap_stehend1101_cls8" d="M307.7,153.1c1.7-4,4.2-5.7,7.7-4.2,18.4,5.7,39,15.6,60.3,27.8,20.8,12.7,37.7,28.6,49.8,48.6,10.3,11.9,10.3,16.9,3.8,17.6-6.3.4-15.6-11.7-24.6-22.2-19.5-20.5-39.5-38.4-60.6-47.2-8.7-2.9-17.9-4.3-27.4-4.6-5.6,3.5-7.8,2.2-8.1-2l-.9-13.8h0Z"/>
        <path id="ribs_left_2" data-name="ribs left 2" class="Abdomen_ap_stehend1101_cls8" d="M302.3,90.4c12.6-12.3,29.9-13.3,50-8.1,24.3,4.2,46.9,18.3,68.8,36.3,16.8,18.7,29.9,37.6,39.8,56.5,1.5,2.8,3.1,5.6,4.9,8.4,11.2,17.9,19.1,34.8,19.4,49.1,0,2.1-1,4.3-2.9,5.3-5.2,2.8-10.5-4.7-15.8-19.1-17.7-34.8-37.8-63.7-59.6-88.1-16.3-16.2-35.2-25.3-56.1-28.9-11.6-2.1-23.5-.7-34.3,3.8l-14.1,5.7"/>
        <path id="ribs_left_3" data-name="ribs left 3" class="Abdomen_ap_stehend1101_cls8" d="M296.4,49.4c5.4,2,9.8-2.9,13.5-11.4,17.2-12.7,36.2-18.1,57.5-13.5,26.2,7.4,49.4,24.3,69.5,50.5,36.6,41,57.5,93.1,69,152.2"/>
        <path id="ribs_left_4" data-name="ribs left 4" class="Abdomen_ap_stehend1101_cls8" d="M299.6,26.5c11.2-6.3,21.9-10.3,32.4-13.3,15.5-3.1,31.8-2.8,48.9,0,18.3,3.2,36.7,15.6,55.4,32.1,19.5,22.5,37,45.2,49.6,69.2,16.2,30,23.9,65.5,26.5,104.2"/>
        <path id="ribs_left_5" data-name="ribs left 5" class="Abdomen_ap_stehend1101_cls8" d="M476.9,76c-5.3,17.6-12,33.5-21.8,46.1-8.2,7.5-12.7,15.4-12,23.5.1,2.1,1.3,4,3.1,5,3.8,1.8,8-1.1,12.7-9.5,13.3-22.2,23-43,26.1-61"/>
        <path id="ribs_left_6" data-name="ribs left 6" class="Abdomen_ap_stehend1101_cls8" d="M496.3,83.6c-1.7,17.6-4,35.2-7.3,53.1-2.5,17.7-9.9,34.3-20.1,50.7-2.7,4-4.7,8.5-6.3,13.1-1.4,4.3-2.5,8.7-3.2,12.8-.6,3.1,2.8,5.6,5.6,4,3.5-1.7,6.8-5.2,10.3-11.2,18.7-39,33.2-78.6,33.4-120.9"/>
        <path id="ribs_left_7" data-name="ribs left 7" class="Abdomen_ap_stehend1101_cls8" d="M486.4,72.2c12.1,20.5,21.2,40.8,22.3,60.5,1,21.2-2,44.3-13.1,70.9-3.6,5.6-4.9,13.1-4.5,21.8.1,2.7,3.5,3.6,5,1.5,3.6-4.9,6.8-12.8,9.9-22.3,8-17.6,12.4-38.5,14.1-62,.3-4.3,0-8.7-.6-12.8-2.4-15.2-7.5-33.9-13.5-53.8"/>
      </g>
    </g>
    <g id="outlines">
      <path id="outlines_left" data-name="outlines left" class="Abdomen_ap_stehend1101_cls8" d="M503.3,13.2c7.1,21.5,13.7,42.6,14.7,61,.3,5.2.7,10.5,1.4,15.6,3.2,24.3,2.1,55.7,0,88.5-1.5,21.5-4.2,43-13.1,63.5-3.9,9.1-7.7,18.4-10.5,27.9-2.2,7.3-4.2,14.5-5.9,21.8-5.9,25.1-5.6,51.4,0,76.5,4,18.1,8.7,35.2,14.2,50.5,4.3,11.9,8.1,23.7,10.9,36,4.3,19,7.1,37.1,7,53.5,0,2.4,0,4.9,0,7.3,1.4,31.7-2.7,64.8-11.6,99l-15.1,56.1"/>
      <path id="outlines_right-2" data-name="outlines right" class="Abdomen_ap_stehend1101_cls8" d="M24.3,32.3c-4.7,25.4-6.6,53.8-5.3,85.4.1,2.8-.1,5.6-.6,8.4-6,32.8-6.4,64.5,4.2,94.4l21.1,72.6c2.7,9.2,5.9,18.1,9.4,27.1,6.6,17.2,5.6,36.9-1.7,58.9l-31,96.5c-4.2,13.3-6.8,26.8-7.5,40.6-2.5,46.5,8.4,104.7,25.3,168.1"/>
    </g>
    <g id="pelvis">
      <path id="pelvis_1" data-name="pelvis 1" class="Abdomen_ap_stehend1101_cls8" d="M142.6,687.9c3.1-15.2-4.3-26-20.2-33.1-13.5-4-25.4-1.3-36.3,5.4-3.2-7.5-1.5-14.9,2.8-22.3.4-32.7-13.5-59.8-33.4-84.6-17.9-14.9-29.9-28.9-35.6-42-7.1-13.4-2.9-22.5,5-29.9,11-20,32.1-31,61.4-34.8,25.4-4.5,48,1.4,67.4,19.3l25.4,30.7c5.6,5.3,6.7,12,4.2,20.1l5.6,20.7c-8.9,14.8-11.9,33.6-10.8,55.3-16.1,3.9-26,11-26.7,22.9-2.4,16.1,5.7,31.4,21.9,46.5,15.9,12.6,31.7,20,47.6,22.3,20.1,6.6,42.9,5.9,66.9,1.3,37.8-5.3,63.9-16.9,81.8-32.8,4.2-9.5,11.3-19.7,20.4-30.2,8.8-14.1,6.3-26.8-3.5-38.5-5,2-10.3-.4-15.8-6-10.8,2.5-14-5.6-13.4-19.1,6.1-10.2,5.9-20.4,2.9-30.6-2.1-7.3,1-15.1,6.3-22.9,9.9-31.8,32.4-50.1,61.2-61.7,26.8-9.8,50.4-11.2,69.4,0,10.3,6.8,16.9,17.2,21.6,29.2,0,8.9-5.4,15.1-14.8,19.3-26.5,22.1-45,56-51.1,108.3-.4,3.9.4,7.8,2.7,11,5.2,7.7,7.1,13.8,3.9,17.3-18.3-9.8-32.7-5.4-44.1,9.5-17.5,3.4-18.3,21.6-12.7,45.8"/>
      <line id="pelvis_2" data-name="pelvis 2" class="Abdomen_ap_stehend1101_cls8" x1="47.2" y1="503.1" x2="99.4" y2="599.9"/>
      <path id="pelvis_3" data-name="pelvis 3" class="Abdomen_ap_stehend1101_cls8" d="M129.2,476.7c9.2,21.1,11.9,41.5,6.1,61.2"/>
      <path id="pelvis_4" data-name="pelvis 4" class="Abdomen_ap_stehend1101_cls8" d="M178,582.7c-9.8-20.4-14.8-49-18.6-79.6,6.7-14.8,15.5-21.9,26.4-20.9,10.1,11.4,20.5,16.9,31.8,12.7"/>
      <path id="pelvis_5" data-name="pelvis 5" class="Abdomen_ap_stehend1101_cls8" d="M328.8,484.5c7.5-.8,15.2-3.6,23.3-7.8,8.7-7.1,15.1-8.2,18.6-2,9.2,12.8,11.9,30.4,8.8,51.8-.6,8.9-3.6,15.2-8.8,19.4-2.9,18.1-5.7,36.4-18.7,44.3-9.4,4.7-12.6,12.7-9.5,23.9.3,9.2-4.3,14.2-11.2,17.5-5,2.7-6.6,10.5-6.4,20.9"/>
      <path id="pelvis_6" data-name="pelvis 6" class="Abdomen_ap_stehend1101_cls8" d="M234.8,664.8c-4-12-13.1-20.7-28.9-24.4-5.3-18.3-15.9-33.5-30.2-46.8"/>
      <path id="pelvis_7" data-name="pelvis 7" class="Abdomen_ap_stehend1101_cls8" d="M82.8,674.5c7.8-11.4,17-18,28.9-15.2,13,3.9,17,9.4,18.7,15.2,5.2,7,3.2,15.8-1.5,25.1"/>
      <path id="_pelvis_8" data-name=" pelvis 8" class="Abdomen_ap_stehend1101_cls8" d="M421.9,469.5c-5.4,13.1-8.5,26.8-5.3,42"/>
      <path id="pelvis_9" data-name="pelvis 9" class="Abdomen_ap_stehend1101_cls8" d="M447.1,566.8c4.5-21.4,13.4-39,26.7-53.1,9.9-11,16.1-26.8,19.7-46.1"/>
      <path id="pelvis_10" data-name="pelvis 10" class="Abdomen_ap_stehend1101_cls8" d="M480.8,673.7c-14-1.3-18-8.4-16.5-19,.6-4.2-.6-8.4-3.2-11.6-13.3-16.6-26.7-15.1-40.2,4.2-13.7,11.9-14.7,24.4-5.7,37.3"/>
      <path id="pelvis_11" data-name="pelvis 11" class="Abdomen_ap_stehend1101_cls8" d="M201.2,534c10.2-10.3,20.4-9.8,30.7,2"/>
      <path id="pelvis_1_2" data-name="pelvis 1 2" class="Abdomen_ap_stehend1101_cls8" d="M307.7,513.6c10.5-3.9,19-3.6,24.2,3.5"/>
      <path id="pelvis_13" data-name="pelvis 13" class="Abdomen_ap_stehend1101_cls8" d="M268.9,496.7l3.4,20.8c.1.8,1.3,1.1,1.8.4,3.2-3.9,4.2-11,2.9-21.2"/>
      <path id="pelvis_14" data-name="pelvis 14" class="Abdomen_ap_stehend1101_cls8" d="M270.4,533.7v15.9c0,1.4,1.7,2.1,2.7,1.1,3.4-3.4,5-8.1,4.7-14.7"/>
      <path id="pelvis_15" data-name="pelvis 15" class="Abdomen_ap_stehend1101_cls8" d="M270.4,564.5v9.1c0,1.4,1.7,2.2,2.8,1.3,3.6-3.4,4.9-9.2,4.7-17"/>
      <path id="pelvis_16" data-name="pelvis 16" class="Abdomen_ap_stehend1101_cls8" d="M265.7,585.2c.7,4.7,1.7,8.9,2.9,12.1.8,2.2,4,2.2,4.9,0,1.3-3.4,1.8-7.4,1.3-12.1"/>
      <path id="pelvis_17" data-name="pelvis 17" class="Abdomen_ap_stehend1101_cls8" d="M241.4,515.8c-.4,6.1-2,11.6-6.6,15.5"/>
      <path id="pelvis_18" data-name="pelvis 18" class="Abdomen_ap_stehend1101_cls8" d="M239.7,545.7c-4.6,10.5-2.5,20.9,7.1,31-2.4,4.3-2.7,11.4-.8,21.2l3.8,6.1c-2.9,8-3.1,15.2,2.2,20.5-.4,9.2-.8,18.1,2.4,23.2"/>
      <path id="pelvis_19" data-name="pelvis 19" class="Abdomen_ap_stehend1101_cls8" d="M303.3,539.3c4,10.2,2.2,20.5-2.9,31,8,8.9,5.9,20.1,0,32.3,6,10.5,5.9,20.2-1.5,29.2l2.2,17.7"/>
      <path id="pelvis_20" data-name="pelvis 20" class="Abdomen_ap_stehend1101_cls8" d="M238.1,547.8c-5.9-.6-12.6,2.4-19.8,7.1-3.6,2.5-7,5.4-9.8,8.9-9.4,11.6-18.4,20.8-26.7,26.2"/>
      <path id="pelvis_21" data-name="pelvis 21" class="Abdomen_ap_stehend1101_cls8" d="M189,599c14-19.5,30.7-31,51.2-31.4"/>
      <path id="pelvis_22" data-name="pelvis 22" class="Abdomen_ap_stehend1101_cls8" d="M300.2,545.7c9.9-5.6,20-9.8,30.7-3.4,7.1,5,13,13.8,18.6,23"/>
      <path id="pelvis_23" data-name="pelvis 23" class="Abdomen_ap_stehend1101_cls8" d="M296.8,571.9c14.1-10.3,28.5-13,42.9-3.1"/>
      <path id="pelvis_24" data-name="pelvis 24" class="Abdomen_ap_stehend1101_cls8" d="M268.9,624.1c-4.9,10.1-7,19.7-5.3,28.5"/>
      <path id="pelvis_25" data-name="pelvis 25" class="Abdomen_ap_stehend1101_cls8" d="M283.7,627.4c4,7.1,6.8,14.4,6.4,22.1"/>
      <path id="pelvis_26" data-name="pelvis 26" class="Abdomen_ap_stehend1101_cls8" d="M263,658.1c0,3.1,1.3,6.3,4.5,9.4"/>
      <path id="pelvis_27" data-name="pelvis 27" class="Abdomen_ap_stehend1101_cls8" d="M291.4,656.5c-.6,5.2-1.1,9.8-2.4,10.9"/>
      <path id="pelvis_28" data-name="pelvis 28" class="Abdomen_ap_stehend1101_cls8" d="M254.2,655.1c-.8,3.8,0,7.5,2.4,11.2"/>
      <path id="pelvis_29" data-name="pelvis 29" class="Abdomen_ap_stehend1101_cls8" d="M300.2,654c.6,5.2.1,10.2-1.5,15.1"/>
      <path id="pelvis_30" data-name="pelvis 30" class="Abdomen_ap_stehend1101_cls8" d="M349.6,689.3c16.1-11.7,31-20.2,36-2.1"/>
      <path id="pelvis_31" data-name="pelvis 31" class="Abdomen_ap_stehend1101_cls8" d="M202.6,699.7c-6.6-.6-11.3-2.4-14.5-5.3-2.5-2.4-5.4-4.5-8.7-5.7-8.7-3.1-16.9-1.8-24.4,5.6"/>
      <line id="pelvis_32" data-name="pelvis 32" class="Abdomen_ap_stehend1101_cls8" x1="150.9" y1="473.2" x2="155" y2="494.9"/>
      <line id="symphyse_1" data-name="symphyse 1" class="Abdomen_ap_stehend1101_cls8" x1="271.4" y1="693.4" x2="273.1" y2="722.9"/>
      <line id="symphyse_2" data-name="symphyse 2" class="Abdomen_ap_stehend1101_cls8" x1="286.4" y1="693.4" x2="286.4" y2="722.9"/>
    </g>
  </g>
  <g id="Layer_2" data-name="Layer 2">
    <g id="overlay">
      <g id="kidney_overlay" data-name="kidney overlay">
        <path id="kidney_overlay_left" data-name="kidney overlay left" class="Nierenkonturen1101Fill" style= {{
              fill: props.colors.Nierenkonturen1101Fill,
              stroke: props.colors.Nierenkonturen1101Fill,
              opacity:props.colors.Nierenkonturen1101Fill,
            }} d="M361.7,232.7c28.2-.6,53.8,3.2,77.7,10.3,11.1,3.3,17.8,14.5,15.4,25.9-21,99.4-40.5,173.9-54.9,168.1-17.5-5.7-30.7-33.3-42-71.5-4.8-16.3-7.9-33.1-9.4-50.1-2.1-24.4-2.2-47.6-.6-69.6.6-7.3,6.5-13,13.8-13.1h0Z"/>
        <path id="kidney_overlay_right" data-name="kidney overlay right" class="Nierenkonturen1101Fill" style= {{
              fill: props.colors.Nierenkonturen1101Fill,
              stroke: props.colors.Nierenkonturen1101Fill,
              opacity:props.colors.Nierenkonturen1101Fill,
            }} d="M209.3,241.6c-28.2-.6-53.8,3.2-77.7,10.3-11.1,3.3-17.8,14.5-15.4,25.9,21,99.4,40.5,173.9,54.8,168.1,17.5-5.7,30.7-33.3,42-71.5,4.8-16.3,7.9-33.1,9.4-50.1,2.1-24.4,2.2-47.6.6-69.6-.5-7.3-6.5-12.9-13.7-13.1h0Z"/>
      </g>
      <g id="diaphragm_overlay" data-name="diaphragm overlay">
        <path id="diaphragm_overlay_right-2" data-name="diaphragm overlay right-2" class="Zwerchfelles1101Fill" style= {{
              fill: props.colors.Zwerchfelles1101Fill,
              stroke: props.colors.Zwerchfelles1101Fill,
              opacity:props.colors.Zwerchfelles1101Fill,
            }} d="M238.1,112.2c-19.8-30.9-43.6-48.9-72.4-58.3-28.9-9.4-60.4-7.6-87.3,6.6-21.8,11.5-38.7,29.4-49.6,56,10.5-8.9,21.2-16.9,32-23.8,34.6-21.7,77.3-25.7,115.4-11.4,20.6,7.6,41.2,18.6,61.9,30.9h0Z"/>
        <path id="diaphragm_overlay_right" data-name="diaphragm overlay right" class="Zwerchfelles1101Fill" style= {{
              fill: props.colors.Zwerchfelles1101Fill,
              stroke: props.colors.Zwerchfelles1101Fill,
              opacity:props.colors.Zwerchfelles1101Fill,
            }} d="M314.2,102.6c21.9-31.7,42.6-49.8,63.9-61.2,23.6-12.6,51.3-16.2,76.9-8.2,19.1,5.9,36,17.5,49.5,36.1-29.4-11-58.1-16.9-86.1-16.3-29.5,2.5-66.1,24-104.2,49.6Z"/>
      </g>
      <path id="liver_edge_overlay" data-name="liver edge overlay" class="unteren_Leberrandes1101Fill" style= {{
              fill: props.colors.unteren_Leberrandes1101Fill,
              stroke: props.colors.unteren_Leberrandes1101Fill,
              opacity:props.colors.unteren_Leberrandes1101Fill,
            }} d="M229.5,186.4c-54,76.3-151.9,185.1-174,192.9,43.5-50.6,106.7-119.8,174-192.9h0Z"/>
      <g id="psoas_edge_overlay" data-name="psoas edge overlay">
        <path id="psoas_edge_overlay_right" data-name="psoas edge overlay right" class="Psoasschatten1101Fill" style= {{
              fill: props.colors.Psoasschatten1101Fill,
              stroke: props.colors.Psoasschatten1101Fill,
              opacity:props.colors.Psoasschatten1101Fill,
            }} d="M229.5,186.4c-44.2,92.8-70.4,186.5-72.5,281.4,31.5-98.8,56.6-193.3,72.5-281.4h0Z"/>
        <path id="psoas_edge_overlay_left" data-name="psoas edge overlay left" class="Psoasschatten1101Fill" style= {{
              fill: props.colors.Psoasschatten1101Fill,
              stroke: props.colors.Psoasschatten1101Fill,
              opacity:props.colors.Psoasschatten1101Fill,
            }} d="M313.4,172.6c.8,95.4,30.8,194.9,78.7,297-16.8-75.7-46.5-183.3-78.7-297h0Z"/>
      </g>
      <path id="symphyse_overlay" data-name="symphyse overlay" class="Symphyse1101Fill" style= {{
              fill: props.colors.Symphyse1101Fill,
              stroke: props.colors.Symphyse1101Fill,
              opacity:props.colors.Symphyse1101Fill,
            }} d="M274.9,691.3h8.4c3.1,0,5.6,2.5,5.6,5.7v24.9c0,3.1-2.5,5.7-5.6,5.7h-6.9c-3,0-5.5-2.3-5.6-5.3l-1.6-24.9c0-3.4,2.5-6.1,5.7-6.1h0Z"/>
    </g>
  </g>
</svg>
  );
}

export default AbdomenApStehend1101;
