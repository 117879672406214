import { useState } from "react";
import { Modal, Row, Col, Button, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { DeleteExam } from "../../Utilities/ClientServices/ClientServices";
import { NotificationManager } from "react-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import { setReloadExaminationsFlag } from "../../Redux/actions";
import SelectedExamsDetails from "../SelectedExamsDetails/SelectedExamsDetails";
import "./stylesheets/DeleteExaminationModal.scss";

export default function DeleteExaminationModal({
  shown,
  headerless = false,
  close,
}) {
  //const [selectedPatient, setSelectedPatient] = useState([]);
  const [inp, setInp] = useState(false);
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  const deleteStudies = () => {
    let promises = [];
    selectedExaminations.forEach((exam) => {
      promises.push(DeleteExam(keycloak, exam.id));
    });
    Promise.all(promises)
      .then(() => {
        NotificationManager.success("Examinations deleted succesfully");
        close();
      })
      .catch((reason) => {
        NotificationManager.error("Unable to delete examinations. Try again");
        console.warn("Unavle to delete examiations", reason);
      });
  };

  return (
    <Modal
      className="top-modal"
      show={shown}
      onHide={close}
      size={"md"}
      onClick={(e) => e.stopPropagation()}
    >
      {!headerless && (
        <Modal.Header className="py-2 bg-light">
          <h4>Confirm deleting</h4>
          {inp && (
            <ClipLoader color="#a1a1a1" loading={true} css={true} size={30} />
          )}
        </Modal.Header>
      )}
      <Modal.Body className="text-center ">
        {selectedExaminations.length === 0 ? (
          <Container>
            <h5>Select Examination first</h5>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col xs={12}>
                <SelectedExamsDetails />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={close}>Cancel</Button>
              </Col>
              <Col>
                <Button
                  variant="danger"
                  disabled={selectedExaminations.length === 0}
                  onClick={deleteStudies}
                >
                  Delete
                  <i className="fas fa-trash ml-2"></i>
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </Modal.Body>
    </Modal>
  );
}
