//functional
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useStudies,
  useStudyDose,
} from "../../../Utilities/FetchHooks/Studies/StudiesHooks";
import { getHHMM_DDMMYYY } from "../../../Utilities/DateTimeUtils/DateTimeUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  setXmStatsSelectedExam,
  setXmStatsReloadExamFlag,
} from "../../../Redux/actions.js";
//ui
import { Button } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import DoseModal from "../../DoseModal/DoseModal";
import { useEffect } from "react";
import ExportStatsModal from "./ExportStatsModal";

function ExamDoseCol({ studyId }) {
  const dose = useStudyDose(studyId);
  return (
    <div
      style={{
        width: "20%",
      }}
      className="p-1 break-word"
    >
      {dose.status === "loading" ? (
        <>
          <ClipLoader />
          Loading dose...
        </>
      ) : dose?.data?.ugy ? (
        `${dose?.data?.ugy} uGy`
      ) : (
        "No Info"
      )}
    </div>
  );
}

export default function ExamsSelectionBox() {
  const { t } = useTranslation(["examStatistics", "common"]);
  const dispatch = useDispatch();
  const examFilter = useSelector((state) => state.xmStats.filterObj);
  const selectedExamination = useSelector(
    (state) => state.xmStats.selectedExam
  );

  const [doseEditModalShown, setDoseEditModalShown] = useState(false);
  const [exportModalShown, setExportModalShown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const examinationsQuery = useStudies({
    pageSize,
    pageNr: currentPage,
    filter: examFilter,
    xmask:
      "total_count,studies{id, patient, studydate, modality, studydescription, patientsname, myg, avgmyg, patientssize, patientsweight}",
    keepPreviousData: true,
  });
  const examinations = examinationsQuery.data;

  useEffect(() => {
    setCurrentPage(1);
  }, [examFilter]);

  const isSelected = (studyid) => {
    if (!selectedExamination) {
      return "";
    }
    if (selectedExamination.id === studyid) {
      return "selected";
    } else {
      return "";
    }
  };

  const shouldReload = useSelector((state) => state.xmStats?.reloadExamFlag);
  useEffect(() => {
    if (shouldReload) {
      examinationsQuery.refetch();
      dispatch(setXmStatsReloadExamFlag(false));
    }
  }, [shouldReload]);

  useEffect(() => {
    if (
      !examinations ||
      !examinations.studies ||
      examinations.studies.length === 0 ||
      !selectedExamination
    ) {
      return;
    }
    let selIdx = examinations.studies.findIndex(
      (study) => study.id === selectedExamination.id
    );
    if (selIdx > -1) {
      dispatch(setXmStatsSelectedExam(examinations.studies[selIdx]));
    }
  }, [examinations]);

  return (
    <>
      <DoseModal
        shown={doseEditModalShown}
        onHide={() => {
          setDoseEditModalShown(false);
        }}
        exam_data={selectedExamination}
      />
      <ExportStatsModal
        shown={exportModalShown}
        onHide={() => setExportModalShown(false)}
      />
      <div name="exams" className="exams">
        <div className="d-flex pl-2 font-weight-bold py-2 border-bottom text-uppercase bg-header rounded-top">
          <div> {t("examsImagesBox.examsHeader")}</div>
          <div className="ml-auto mr-2">
            {examinationsQuery.isRefetching && <ClipLoader size={25} />}
          </div>
          <Button
            onClick={() => setDoseEditModalShown(true)}
            size="xs"
            className="mr-2 py-1 px-2"
            disabled={selectedExamination === undefined}
          >
            <i className="fas fa-radiation fa-sm" />
          </Button>
          <Button
            onClick={() => setExportModalShown(true)}
            size="xs"
            className="mr-2 py-1 px-2"
          >
            <i className="fas fa-print fa-sm" />
          </Button>
          <Button
            onClick={() => examinationsQuery.refetch()}
            size="xs"
            className="mr-2 py-1 px-2"
          >
            <i className="fas fa-redo fa-sm" />
          </Button>
        </div>
        <div className="exam-list">
          {examinationsQuery.status === "loading" && (
            <div className="p-2 text-center bg-white">
              <div className="small text-muted">
                {t("examsImagesBox.examsLoadingMessage")}
              </div>
              <ClipLoader size={20} />
            </div>
          )}
          {examinationsQuery.status === "error" && (
            <div className="p-2 text-center">
              <div className="small text-muted">
                {t("examsImagesBox.examsLoadingFailedMessage")}
              </div>
            </div>
          )}
          {examinationsQuery.status === "success" &&
            examinations?.studies?.length > 0 &&
            examinations.studies.map((study, key) => (
              <div
                onClick={() => dispatch(setXmStatsSelectedExam(study))}
                key={`examinationskey${key}`}
                className={`d-flex border-bottom examination-row ${isSelected(
                  study.id
                )}`}
              >
                <div style={{ width: "30%" }} className="p-1 border-right">
                  {getHHMM_DDMMYYY(study.studydate)}
                </div>
                <div
                  style={{ width: "50%" }}
                  className="p-1 border-right break-word"
                >
                  {study.studydescription !== ""
                    ? study.studydescription
                    : "No description"}
                </div>
                <ExamDoseCol studyId={study.id} />
              </div>
            ))}
        </div>
        {examinations?.total_count > pageSize && (
          <div className="text-center px-2 py-1 d-flex justify-content-between bg-white">
            <div>
              <Button
                className="mr-1"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(1)}
              >
                <i className="fas fa-angle-double-left fa-sm" />
              </Button>
              <Button
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <i className="fas fa-angle-left fa-sm" />
              </Button>
            </div>
            <div className="border rounded p-2 bg-secondary text-white">
              {currentPage} / {Math.ceil(examinations?.total_count / pageSize)}
            </div>
            <div>
              <Button
                className="mr-1"
                disabled={examinations?.total_count < pageSize * currentPage}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <i className="fas fa-angle-right fa-sm" />
              </Button>
              <Button
                disabled={
                  examinations?.total_count < pageSize * currentPage ||
                  currentPage ===
                    Math.ceil(examinations?.total_count / pageSize)
                }
                onClick={() =>
                  setCurrentPage(
                    Math.ceil(examinations?.total_count / pageSize)
                  )
                }
              >
                <i className="fas fa-angle-double-right fa-sm" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
