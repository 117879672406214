import style from "./style.scss";

function ScapulaApLeft(props) {
  return (
    <svg
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 415.17 554.82"
    >
      <g id="shoulder_plate" data-name="shoulder plate">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            className="scapulta_ap_left-cls-6"
            d="M285.92,497.7l.02.03c7.71-6.83,10.27-15.41,12.3-22.56,3.11-10.93.7-11.76,3.42-24.61,1.9-9,3.4-10.11,8.89-26.66,0,0,3.83-11.56,7.52-25.29,4.78-17.78,4.43-21.15,8.2-36.23,2.54-10.13,5.57-19.06,11.62-36.91,9.6-28.3,11.04-28.6,15.04-43.75,3.74-14.19,3.17-16.55,6.84-25.29,5.1-12.15,9.86-16.29,17.09-28.03,2.15-3.49,10.45-17.28,15.72-34.18,1.37-4.4,9.34-31.03,2.05-62.2-4.94-21.14-12.01-23.49-13.67-23.92-3.93-1.04-7.41.7-11.62,2.73-10.57,5.09-21.67,9.05-32.13,14.35-10.85,5.51-24.87,7.37-52.63,11.62-4.24.65-11.03.66-24.61.68-6.55.01-10.61-.12-12.3-2.73-1.98-3.06.88-7.08,2.73-12.99,3.48-11.09,1.46-22-.68-27.34-.52-1.3-7.32-17.5-24.61-23.24-16.58-5.5-31.38,2.41-39.65,6.84-8.71,4.66-11.09,13.34-16.41,12.3-4.04-.79-4.15-5.43-10.94-10.94-6.38-5.18-14.17-7.45-15.04-6.15-1,1.49,7.96,6.5,14.35,17.77,3.84,6.78,4.93,12.62,6.15,19.14,1.71,9.16,4.53,24.25-1.37,41.01-3.89,11.06-7.24,20.57-15.72,26.66-1.73,1.24-6.77,4.57-6.15,7.52.45,2.15,3.49,2.1,5.47,4.78,2.67,3.64.52,8.39,0,12.99-.59,5.2,1.04,9.06,7.52,21.19,8.15,15.25,12.68,22.45,15.72,28.03,5.5,10.09,5.63,13.99,13.67,41.01,3.95,13.29,1.06,2.72,14.35,45.11,13.02,41.5,15.19,49.72,17.09,60.15,2.82,15.51,4.23,23.27,4.1,29.39-.13,6.31-.92,11.4,1.37,18.46,1.21,3.74,2.07,4.26,8.2,14.35,3.92,6.46,7.15,12.13,12.3,21.19,6.54,11.49,6.45,11.85,8.2,13.67,1.58,1.64,10.46,10.86,22.56,9.57,7.45-.79,12.39-5.17,15.04-7.52h0Z"
          />
        </g>
        <path
          id="collarbone"
          className="scapulta_ap_left-cls-2"
          d="M414.41,153.42c-29.39-17.09-58.78-34.18-88.18-51.27-24.53-20.3-45.84-31.31-60.84-37.59-1.91-.8-12.67-4.86-34.18-12.99-32.29-12.19-33.85-12.57-36.91-12.99-4.78-.65-16.84-1.87-31.44-6.84-3.23-1.1-6.47-2.34-8.89-5.47-6.13-7.94-2.93-22.8,2.73-24.61,2.29-.73,3.62,1.1,11.62,5.47,0,0,6.76,3.69,16.41,7.52,7.6,3.02,11.53,3.34,20.51,5.47,17.04,4.03,15.53,5.85,30.76,9.57,13.6,3.32,15.09,1.94,33.49,6.15,9.63,2.2,14.44,3.3,19.82,5.47,6.49,2.61,11.43,5.54,26.66,16.41,17.59,12.55,17.27,13.12,25.29,18.46,4.84,3.22,10.11,6.44,30.76,17.09,10.49,5.41,24.38,12.44,41.01,20.51"
        />
        <g id="upper_arm" data-name="upper arm">
          <path
            id="upper_arm_1"
            data-name="upper arm 1"
            className="scapulta_ap_left-cls-2"
            d="M1.55,162.31c5.29-2.33,13.2-6.6,20.51-14.35,5.16-5.48,8-10.53,11.62-17.09,7.66-13.87,6.11-16.77,12.99-28.71,6.59-11.45,13.5-18.32,15.04-19.82,8.08-7.89,15.67-12.1,19.82-14.35,9.38-5.1,14.32-7.79,21.19-7.52,3.74.15,3.28.98,17.09,5.47,12.25,3.98,13.5,3.61,17.77,6.15,4.59,2.73,7.31,5.62,12.3,10.94,5.52,5.87,8.56,9.11,10.94,14.35,1.91,4.21,2.44,8.56,3.42,17.09,1.2,10.38,1.79,15.57,1.37,21.19-.99,13.05-5.48,22.56-6.84,25.29-3.41,6.85-4.02,5.02-12.3,17.77-8.12,12.5-8.99,16.49-13.67,17.77s-6.45-1.99-16.41-4.78c-9.99-2.81-18.28-2.36-23.92-2.05-9.26.5-15.86,2.43-23.92,4.78-10.52,3.07-18.14,6.54-23.24,8.89-8.69,4-14.89,7.63-20.51,10.94-6.42,3.79-14.66,8.97-23.92,15.72"
          />
          <path
            id="upper_arm_2"
            data-name="upper arm 2"
            className="scapulta_ap_left-cls-3"
            d="M123.22,153.42c-3.42,12.3-36.23,0-101.85,58.1"
          />
        </g>
        <g id="scapula">
          <path
            id="scapula_1"
            data-name="scapula 1"
            className="scapulta_ap_left-cls-2"
            d="M358.36,174.61c-10.49-6.5-19.19-12.66-25.97-17.77-5.85-4.41-14.08-10.64-23.92-19.82-7.19-6.7-11.94-11.89-19.82-20.51-15.65-17.11-15.34-18.61-23.92-26.66-7.41-6.95-14.4-13.51-25.29-19.14-7.98-4.13-8.28-2.7-33.49-11.62-13.59-4.81-20.27-7.61-23.24-8.89-1.52-.65-11.14-4.8-23.24-11.62-8.15-4.59-12.25-6.93-13.67-9.57-5.21-9.64,3.69-21.21-.68-24.61-2.04-1.59-4.32.67-12.99,2.05-8.8,1.4-9.62-.43-17.77.68-4.86.67-12.2,1.67-19.14,6.84-2.95,2.2-8.37,6.24-10.25,13.67-2.02,8,1.36,14.73,2.73,17.09,4.05,6.92,10.65,9.97,14.35,11.62,34.14,15.23,43.26,15.56,56.73,25.29,10.85,7.84,17.78,16.89,20.51,20.51,1.36,1.8,7.51,10.07,12.99,22.56,4.5,10.24,6.88,19.52,8.2,26.66"
          />
          <path
            id="scapula_2"
            data-name="scapula 2"
            className="scapulta_ap_left-cls-1"
            d="M271.55,504.76c3.62-.72,9.29-2.41,14.35-6.84,4.04-3.53,7.4-8.54,11.62-23.92,3.25-11.85,3.68-17.73,5.47-26.66,2.72-13.61,5.69-19.84,10.25-33.49,9.13-27.32,5.8-28.03,15.04-59.47,7.5-25.54,9.04-22.85,18.46-54,8-26.47,7.04-28.91,12.99-42.38,6.18-13.99,8.29-13.79,21.87-40.33,10.7-20.91,12.99-28.08,14.35-35.54,1.84-10.08,1.6-18.7,1.37-27.34-.28-10.35-.52-17.33-3.42-25.97-2.37-7.08-6.48-19.35-15.04-21.19-3.03-.65-7.3,1.38-15.72,5.47-19.59,9.51-20.02,9.79-22.56,10.94-6.99,3.17-16.05,7.28-28.03,9.57-6.13,1.17-3.96.14-22.56,2.05-9.61.99-15.8,1.85-25.29,2.05-16.13.35-17.88-1.69-18.46-2.73-2.15-3.88,4.22-7.91,6.15-17.77,2.22-11.32-3.21-21.18-5.47-25.29-2.15-3.9-8.61-15.66-21.87-19.82-7.43-2.33-13.38-1.1-25.29,1.37-7.88,1.63-15.76,3.36-23.24,9.57-3.19,2.65-7.35,6.2-8.89,12.3-.3,1.18-2.42,9.63,2.73,15.04,3.55,3.73,8.63,3.98,10.94,4.1,4.12.21,5.17-.99,15.04-3.42,5.68-1.4,8.52-2.1,10.94-2.05,3.48.07,8.76,1.1,15.04,6.15"
          />
          <path
            id="scapula_3"
            data-name="scapula 3"
            className="scapulta_ap_left-cls-1"
            d="M191.57,93.95c-2.96.15-7.41.7-12.3,2.73-2.12.88-9.85,4.34-21.19,19.82-8.04,10.98-5.65,11.28-13.67,21.87-6.02,7.95-9.08,10.06-12.99,17.77-2.8,5.53-5.35,10.75-4.1,17.09,1.35,6.85,6.38,11.24,6.84,11.62,5.32,4.51,9.97,3.38,12.3,7.52,1.84,3.26.54,6.75,0,9.57-2.06,10.81,5.54,19.71,13.67,33.49,6.58,11.15,10.07,19.67,15.72,33.49,6.67,16.3,5.64,19.54,14.35,49.9,7.14,24.87,7.63,22.01,14.35,45.8,7.57,26.78,11.36,40.17,12.3,51.27,1.45,17.01-.62,24.81,5.47,36.91,3.34,6.64,5.88,8.1,12.3,20.51,4.47,8.63,5.12,11.55,9.57,17.77,7.22,10.11,12.83,12.67,15.72,13.67,3.27,1.13,13.03,0,15.04,0"
          />
          <path
            id="scapula_4"
            data-name="scapula 4"
            className="scapulta_ap_left-cls-1"
            d="M171.54,99.82c23.98,29.6-4.82,85.28-25.09,90.78"
          />
          <path
            id="scapula_5"
            data-name="scapula 5"
            className="scapulta_ap_left-cls-3"
            d="M173,89.24c51.41,50.89,54.12,76.49,185.36,85.37"
          />
          <path
            id="scapula_6"
            data-name="scapula 6"
            className="scapulta_ap_left-cls-3"
            d="M197.04,83.02c0,15.31,11.35,27.7,20.51,27.7"
          />
        </g>
        <g id="ribs">
          <path
            id="ribs_1"
            data-name="ribs 1"
            className="scapulta_ap_left-cls-3"
            d="M241.47,554.66c23.54-28.45,91.28-62.88,156.01-74.51"
          />
          <path
            id="ribs_2"
            data-name="ribs 2"
            className="scapulta_ap_left-cls-3"
            d="M207.98,550.56c18.36-31.88,100.65-74.36,184.56-94.33"
          />
          <path
            id="ribs_3"
            data-name="ribs 3"
            className="scapulta_ap_left-cls-3"
            d="M180.63,550.56c28.48-72.11,120.76-140.21,215.32-149.01"
          />
          <path
            id="ribs_4"
            data-name="ribs 4"
            className="scapulta_ap_left-cls-3"
            d="M156.71,554.66c19.19-84.54,124.93-163.25,233.09-178.41"
          />
          <path
            id="ribs_5"
            data-name="ribs 5"
            className="scapulta_ap_left-cls-3"
            d="M166.96,554.66c-1.04-103.7,98.28-197.43,222.84-224.89"
          />
          <path
            id="ribs_6"
            data-name="ribs 6"
            className="scapulta_ap_left-cls-3"
            d="M150.65,554.66c-1.05-115.66,107.93-218.93,244.62-252.23"
          />
          <path
            id="ribs_7"
            data-name="ribs 7"
            className="scapulta_ap_left-cls-3"
            d="M195.67,554.66c-58.76-126.14,45.85-265.96,207.8-295.97"
          />
          <path
            id="ribs_8"
            data-name="ribs 8"
            className="scapulta_ap_left-cls-3"
            d="M171.75,554.66c-36.33-161.56,65.71-289.62,225.73-314.43"
          />
          <path
            id="ribs_9"
            data-name="ribs 9"
            className="scapulta_ap_left-cls-3"
            d="M262.66,499.98c-162.42-93.7-21.96-261.94,147.65-301.44"
          />
          <path
            id="ribs_10"
            data-name="ribs 10"
            className="scapulta_ap_left-cls-3"
            d="M211.39,510.23c-112.16-141.34,42.22-316.22,195.49-333.57"
          />
          <path
            id="ribs_11"
            data-name="ribs 11"
            className="scapulta_ap_left-cls-3"
            d="M272.96,376.26c-156.07-83.87,31.92-199.59,122.31-231.05"
          />
          <path
            id="ribs_12"
            data-name="ribs 12"
            className="scapulta_ap_left-cls-3"
            d="M241.47,415.9c-123.85-125.65,8.2-236.51,168.84-293.24"
          />
          <path
            id="ribs_13"
            data-name="ribs 13"
            className="scapulta_ap_left-cls-3"
            d="M365.88,329.78c-218.79-123.66-92.92-179.01,48.53-255.65"
          />
          <path
            id="ribs_14"
            data-name="ribs 14"
            className="scapulta_ap_left-cls-3"
            d="M382.28,281.25c-169.21-69.04-109.56-140.8,28.03-187.29"
          />
          <path
            id="ribs_15"
            data-name="ribs 15"
            className="scapulta_ap_left-cls-3"
            d="M388.1,204.49c-109.99-45.54-59.24-110.17,26.31-130.36"
          />
          <path
            id="ribs_16"
            data-name="ribs 16"
            className="scapulta_ap_left-cls-3"
            d="M408.6,77.47c-53.54,34.41-21.65,89.26,3.42,97.75"
          />
          <path
            id="ribs_17"
            data-name="ribs 17"
            className="scapulta_ap_left-cls-3"
            d="M412.02,37.9c-32.56,21.74-67.39,61.92-71.06,72.81"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="scapula_overlay"
          data-name="scapula overlay"
          className="scapulaFill"
          style={{
            fill: props.colors.scapulaFill,
            stroke: props.colors.scapulaFill,
          }}
          d="M132.89,153.42c-6.86,14.11-10.12,26.94,7.44,32.4.45,2.15,3.49,2.1,5.47,4.78,2.67,3.64.52,8.39,0,12.99-.59,5.2,1.04,9.06,7.52,21.19,8.15,15.25,12.68,22.45,15.72,28.03,5.5,10.09,5.63,13.99,13.67,41.01,3.95,13.29,1.06,2.72,14.35,45.11,13.02,41.5,15.19,49.72,17.09,60.15,2.82,15.51,4.23,23.27,4.1,29.39-.13,6.31-.92,11.4,1.37,18.46,1.21,3.74,2.07,4.26,8.2,14.35,3.92,6.46,7.15,12.13,12.3,21.19,6.54,11.49,6.45,11.85,8.2,13.67,1.58,1.64,10.46,10.86,22.56,9.57,7.45-.79,12.39-5.17,15.04-7.52l.02.03c7.71-6.83,10.27-15.41,12.3-22.56,3.11-10.93.7-11.76,3.42-24.61,1.9-9,3.4-10.11,8.89-26.66,0,0,3.83-11.56,7.52-25.29,4.78-17.78,4.43-21.15,8.2-36.23,2.54-10.13,5.57-19.06,11.62-36.91,9.6-28.3,11.04-28.6,15.04-43.75,3.74-14.19,3.17-16.55,6.84-25.29,5.1-12.15,9.86-16.29,17.09-28.03,2.15-3.49,10.45-17.28,15.72-34.18,1.37-4.4,9.34-31.03,2.05-62.2-4.94-21.14-12.01-23.49-13.67-23.92-3.93-1.04-7.41.7-11.62,2.73-10.57,5.09-21.67,9.05-32.13,14.35-10.85,5.51-24.87,7.37-52.63,11.62-4.24.65-11.03.66-24.61.68-6.55.01-10.61-.12-12.3-2.73-1.98-3.06.88-7.08,2.73-12.99,3.48-11.09,1.46-22-.68-27.34-.52-1.3-7.32-17.5-24.61-23.24-16.58-5.5-31.38,2.41-39.65,6.84-1.09.58-2.08,1.23-2.99,1.91-12.29,4.29-16.58,14.08-16.73,26.72l-32.85,46.28h0Z"
        />
        <g id="rib_overlay" data-name="rib overlay">
          <path
            id="rib_overlay_1"
            data-name="rib overlay 1"
            className="ripsFill"
            style={{
              fill: props.colors.ripsFill,
              stroke: props.colors.ripsFill,
            }}
            d="M393.59,127.13l-65.62,31.9c-3.88-12.91-4.35-23.99,3.06-30.88l49.93-19.58c-3.15,22.6.57,42.43,16.7,57.27-.11,11.27-4.18,23.94-10.51,38.92-19.17-8.05-37.87-20.28-50.98-33.43l-55.23,34c2.41,6.67,5.2,12.17,8.99,14.64l-31.23,23.82c-2.04-1.64-4.68-7.31-7.44-13.81-23.18,24.2-33.8,43.58-36.31,59.84,47.06-61.08,107.67-97.51,181.52-113.17l-9.32,28.12c-25.24,6.48-52.21,19.33-79.69,34.47,6.38,6.39,15.41,11.88,25.97,16.86-15.42,5.7-30.24,13.04-44.59,21.7l-15.59-14.7c-24.77,19.97-43.71,39.51-56.48,58.62l4.57,11.42c33.48-38.53,79.12-69.6,143.07-86.61l-9.16,22.74-13.62,46.52-34.63-23.24c-32,17.16-54.03,36.49-71.99,56.7,8.36,7.3,16.89,14.97,22.78,17.13,21.64-16.63,50.09-32.59,83.17-48.09l-12.16,30.96c-43.89,18.88-81.36,44.62-110.55,79.21l-3.3-23.8,9.11-8.2c-11.26-12.66-18.74-27.98-23.1-45.5l-6.98-26.5c-4.84-36.29,12.44-73.58,53.65-111.93-1.62-23.15,16.8-48.78,57.54-77.18l22.98-6.1c-24.98,16.01-42.92,34.61-48.95,57.59,26.85-18.65,69.14-39.01,114.38-59.7h0Z"
          />
          <path
            id="rib_overlay_2"
            data-name="rib overlay 2"
            className="ripsFill"
            style={{
              fill: props.colors.ripsFill,
              stroke: props.colors.ripsFill,
            }}
            d="M223.84,452.04l11.83,22.6c21.39-19.32,45.63-35.87,73.61-48.8l10.19-30.91c-35.46,14.1-67.34,33.13-95.63,57.12h0Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default ScapulaApLeft;
