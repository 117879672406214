import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../Redux/mappers/reports/reportsMappers";
import { useTranslation } from "react-i18next";

import { Button, Row, Col, Container, Card } from "react-bootstrap";
import SelectReportType from "./Components/SelectReportType";
import SelectFacility from "./Components/SelectFacility";
import SelectDateRange from "./Components/SelectDateRange";
import SelectRefPhysician from "./Components/SelectRefPhysician";
import ReportSummary from "./Components/ReportSummary";
import SelectPatient from "./Components/SelectPatient";

import {
  clearReportDetails,
  setInitialReportsState,
} from "../../Redux/actions";

function ReportsGen(props) {
  const { t } = useTranslation(["reports", "common"]);
  const stages = {
    SelectReportType: {
      component: SelectReportType,
      description: t("generator.selectReportType"),
      validator: () => {
        return true;
      },
    },
    SelectFacility: {
      component: SelectFacility,
      description: t("generator.selectFacility"),
      validator: () => {
        if (props.reports.repSelectedIds.length > 0) {
          return true;
        } else {
          return t("generator.noFacility");
        }
      },
    },
    SelectDateRange: {
      component: SelectDateRange,
      description: t("generator.selectDateRange"),
      validator: () => {
        if (
          props.reports.repDateRange.from != null &&
          props.reports.repDateRange.to != null
        ) {
          return true;
        } else {
          return t("generator.noDateRange");
        }
      },
    },
    SelectRefPhysician: {
      component: SelectRefPhysician,
      description: t("generator.selectRefPhysician"),
      validator: () => {
        if (props.reports.repSelectedIds.length > 0) {
          return true;
        } else {
          return t("generator.noRefPhysician");
        }
      },
    },
    SelectPatient: {
      component: SelectPatient,
      description: t("common:selectPatient"),
      validator: () => {
        if (props.reports.repSelectedIds.length > 0) {
          return true;
        } else {
          return t("noPatient");
        }
      },
    },
    Summary: {
      component: ReportSummary,
      description: t("generator.summary"),
      validator: () => {
        return true;
      },
    },
  };

  const reportTypes = {
    FacReport: {
      reportType: "FacReport",
      reportName: t("reportType.facility"),
      reportDescription: t("reportType.facilityDesc"),
      nextStages: [
        stages.SelectReportType,
        stages.SelectFacility,
        stages.SelectDateRange,
        stages.Summary,
      ],
      additionalSwitches: {
        ref_fac_summary: t("reportType.refFacSummary"),
        ref_fac_detailed_summary: t("reportType.refFacDetailedSummary"),
        study_type_summary: t("reportType.studyTypeSummary"),
        inc_prc: t("reportType.includePrice"),
        one_report: t("reportType.oneReport"),
      },
    },
    RefPhysReport: {
      reportType: "RefPhysReport",
      reportName: t("reportType.refPhysician"),
      reportDescription: t("reportType.refPhysicianDesc"),
      nextStages: [
        stages.SelectReportType,
        stages.SelectRefPhysician,
        stages.SelectDateRange,
        stages.Summary,
      ],
      additionalSwitches: {
        detailed_summary: t("reportType.refFacDetailedSummary"),
        base_summary: t("reportType.baseSummary"),
        modality_type_summary: t("reportType.modalityTypeSummary"),
        inc_prc: t("reportType.includePrice"),
        one_report: t("reportType.oneReport"),
      },
    },
    // RefDeptReport: {
    //   reportType: "RefDeptReport",
    //   reportName: "Reffering Department Report",
    //   reportDescription:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //   nextStages: [
    //     stages.SelectReportType,
    //     stages.SelectDateRange,
    //     stages.Summary,
    //   ],
    //   additionalSwitches: {
    //     inc_prc: "Include prices"
    //   },
    // },
    RadiologistReport: {
      reportType: "RadiologistReport",
      reportName: t("reportType.radiologist"),
      reportDescription: t("reportType.radiologistDesc"),
      nextStages: [
        stages.SelectReportType,
        stages.SelectDateRange,
        stages.Summary,
      ],
      additionalSwitches: {
        basic_summary: t("reportType.basicSummary"),
        detailed_summary: t("reportType.detailedSummary"),
      },
    },
    DemographicReport: {
      reportType: "DemographicReport",
      reportName: t("reportType.demographic"),
      reportDescription: t("reportType.demographicDesc"),
      nextStages: [
        stages.SelectReportType,
        stages.SelectDateRange,
        stages.Summary,
      ],
      additionalSwitches: {
        local_orders: t("reportType.localOrders"),
        // external_orders: t("reportType.externalOrders"),
      },
    },
    DemographicDoseReport: {
      reportType: "DemographicDoseReport",
      reportName: t("reportType.demographicDose"),
      reportDescription: t("reportType.demographicDoseDesc"),
      nextStages: [
        stages.SelectReportType,
        stages.SelectDateRange,
        stages.Summary,
      ],
      additionalSwitches: {
        local_orders: t("reportType.doseReport"),
        // external_orders: t("reportType.externalOrders"),
      },
      configuration: {
        get_kv_mas_values: t("reportType.get_kv_mas_values"),
        get_dose_values: t("reportType.get_dose_values"),
        get_all_columns: t("reportType.get_all_columns"),
      },
    },
    ExternalOrdersReport: {
      reportType: "ExternalOrdersReport",
      reportName: t("reportType.externalOrdersReport"),
      reportDescription: t("reportType.externalOrdersReportDesc"),
      nextStages: [
        stages.SelectReportType,
        stages.SelectDateRange,
        stages.Summary,
      ],
      additionalSwitches: {
        // local_orders: t("reportType.doseReport"),
        // external_orders: t("reportType.externalOrders"),
      },
    },
  };

  const dispatch = useDispatch();
  const selectedReportType = useSelector((state) => state.reports.repType);
  const [stageNo, setStageNo] = useState(0);
  const currentStage = reportTypes[selectedReportType].nextStages[stageNo];

  useEffect(() => {
    dispatch(setInitialReportsState());
  }, []);

  return (
    <Container fluid className="mx-0 px-3 my-0 py-2">
      <Row className="mb-4 justify-content-center">
        <Col md={10} sm={12}>
          <Card>
            <Card.Header>
              <h4>{currentStage.description}</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={1} className="my-auto text-left">
                  {stageNo > 0 && (
                    <Button
                      onClick={() => {
                        if (stageNo === 1) {
                          dispatch(clearReportDetails(null));
                        }
                        setStageNo(stageNo - 1);
                      }}
                    >
                      <i className="fas fa-arrow-left"></i>
                    </Button>
                  )}
                </Col>
                <Col md={10} className="my-auto">
                  {stageNo === 0 ? (
                    <currentStage.component
                      keycloak={props.keycloak}
                      reportTypes={reportTypes}
                    />
                  ) : stageNo ===
                    reportTypes[selectedReportType].nextStages.length - 1 ? (
                    <currentStage.component
                      keycloak={props.keycloak}
                      additionalSwitches={
                        reportTypes[selectedReportType].additionalSwitches
                      }
                      configuration={
                        reportTypes[selectedReportType].configuration
                      }
                    />
                  ) : (
                    <currentStage.component keycloak={props.keycloak} />
                  )}
                </Col>
                <Col md={1} className="my-auto text-right">
                  {stageNo !==
                    reportTypes[selectedReportType].nextStages.length - 1 && (
                    <Button
                      onClick={() => {
                        let valid = currentStage.validator();
                        if (valid === true) {
                          setStageNo(stageNo + 1);
                        } else {
                          alert(valid);
                        }
                      }}
                    >
                      <i className="fas fa-arrow-right"></i>
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsGen);
