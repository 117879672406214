import {
  SET_RELOAD_EXAMINATIONS_FLAG,
  SET_RELOAD_PATIENTLIST_FLAG,
  SET_SELECT_ALL_EXAMINATIONS_FLAG,
  SET_EXPAND_ALL_EXAMINATIONS,
  SET_RELOAD_PATIENTDATA_FLAG,
} from "../actionTypes";

const initialState = {
  reloadExaminations: false,
  reloadPatientList: false,
  reloadPatientData: false,
  selectAllExaminations: false,
  expandAllExaminations: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_RELOAD_EXAMINATIONS_FLAG: {
      const { content } = action.payload;
      return { ...state, reloadExaminations: content };
    }

    case SET_RELOAD_PATIENTLIST_FLAG: {
      const { content } = action.payload;
      return { ...state, reloadPatientList: content };
    }

    case SET_SELECT_ALL_EXAMINATIONS_FLAG: {
      const { content } = action.payload;
      return { ...state, selectAllExaminations: content };
    }

    case SET_EXPAND_ALL_EXAMINATIONS: {
      const { content } = action.payload;
      return { ...state, expandAllExaminations: content };
    }

    case SET_RELOAD_PATIENTDATA_FLAG: {
      const { content } = action.payload;
      return { ...state, reloadPatientData: content };
    }

    default:
      return state;
  }
}
