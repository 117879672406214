import style from "./style.scss";

function LinkesDaumenSattelGelenkLatSitzend1313(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 165.8 512.8"
    >
      <g id="Thumb">
        <g id="Background">
          <path
            id="Background_white"
            data-name="Background white"
            class="daumensattelgelenk_links1313_cls5"
            d="M135.8,511.6l-2.1-33.5c5.7-31.1,4.6-56.9-3.1-77.6,0-17.2-3.2-29.3-14.2-29.6l-18.6,22.8-3.5-.5c12-13.2,19-24.8,12.3-31.8l-.9-5.2h6.1c5.1,2.3,21.6,1.9,25.8-9.6-1.4-5.5,5.8-9.9-1.6-14.4-5.1-1.8-8.2-6.2-9.4-13.1,7.1-2.4,10.8-9.2,13.7-17.1,4.5-15.1,7.9-30.6,10.7-46.3l8.9-62.5c4.4-14.1,5.8-25.2,4.9-34.1l-11.8-60.5c0-14.9-1.6-26.9-5.4-34.7l-7.9-33.6c.8-19.8-.7-28.7-10.2-29.7-8.9-1.7-16,1.9-20.5,12.4-7.4,12.4-8.9,28.8-8.3,46.7.7,6.2,3.4,12.4,6.1,15.6,0,0-3.8,22.4-3.4,35.1,1.2,9.8-1.2,17.9-7.4,24.3-9.5-5.1-19.5-8.1-30-8.4C46.4,79.2,29.8,56.2,13.2,53.3l-1.2,9.7c-1.6,10.1-2.1,21.5-2,33.5v6.4l-3.1,33.4c4.1,1.1,6.8,5.2,8.5,11.8s3.5,13.1,5.9,19.5c14.4,38.3,24.4,74.2,16,108.3-12.8-12.9-24.4-30.6-35.4-51.7l-.7,20.6,13.5,65.6,16.2,61.7c-14.2,19.9-16.2,32.1-8.2,38.1,4.2,2.8,8.5,4.1,13,4.3-4.1,10.1-1.9,20.3,3.5,30.6,17.8,16.4,29.5,41.1,34.7,67.2l62.1-.9h0Z"
          />
        </g>
        <g id="Thumb1">
          <g id="ThumbandFinger">
            <path
              id="fingerpart25"
              class="daumensattelgelenk_links1313_cls2"
              d="M130.5,291.6c-.5.9-1.5,1.4-2.5,1.2-3.2,3.5-6.6,2.5-10.1-1.8-7.2-7.8-14.9-11.2-23.1-10.3-6.3.3-7.2-2.6-5.4-7l4.7-15.2c11.8-6.4,18.7-16.2,23.5-27.6,3-14.7,5.6-28.4,7-39.4,1.2-4.6,1-9.2-.4-14-5.1-3-5.7-7.3-1.9-13,2.4-7.7,7.1-11.2,12.9-12.3,3.4-.7,7-.3,10.2,1.1,5.1,2.1,9.1,4.7,12,8,5.8,7.6.4,15.7-2.1,23.9-1.3,16.8-3.7,32.4-7.8,46.2l-6,39.2c-.4,13-3.3,21.3-10.9,21h0Z"
            />
            <path
              id="fingerpart24"
              class="daumensattelgelenk_links1313_cls2"
              d="M154.4,145.4c-.7.8-1.2,1.7-1.4,2.7-.2.8-.4,1.6-.8,2.3-1.2,2.6-4.2,3.9-6.9,2.9-3.9-1.3-9.2-2-15.4-2.3-1.9,0-3.8.2-5.7.8-2.9,1-5.5.1-7.6-4.3-3.2-4.8-3.2-9.4-.2-14,1.6-2.4,2.9-4.9,3.8-7.6l3-8.6c3.2-10.6,4-19.9,2.1-27.7-.5-2.2-1.8-4-3.5-5.5-2.4-2-3.6-4.7-3.3-8.4.4-4.4,3.2-7.5,7.8-9.5,5.8-1.3,10.7-.5,14.2,3.9,6.6,3.9,8.7,8.5,6.8,13.7-.4,1-.6,2.1-.6,3.2.2,17.5,1.9,34.2,6.3,49.2.2.6.5,1.2.9,1.8,2,2.6,2.4,5,.5,7.1h0,0Z"
            />
            <path
              id="fingerpart23"
              class="daumensattelgelenk_links1313_cls2"
              d="M141.5,65.9c-5.8-1-12,.8-18.7,5.2-2.9-.8-4.4-2.8-4.3-6-1.8-.6-3-2.4-3.1-6.2.5-5.3,2-7,3.9-6.8-2.8-6.9-1.9-13.8,1.6-20.8.5-.9.6-2,.5-3-.9-6.2,0-12,4.1-16.7,2.6-3.8,5.2-3.6,7.7-1,.2.2.2.4.2.6l-.7,5.1c-.1.8,0,1.6.3,2.4.9,2.3,1.5,4.9,2,7.7,0,11.1.8,22.1,5.4,32.3,2.1,2,3,4.3,1.2,7.2h0Z"
            />
            <path
              id="fingerpart22"
              class="daumensattelgelenk_links1313_cls2"
              d="M127.1,164c-1.2,2.6-2.5,4.4-4.3,4.9-4,0-8.6-4.1-8.6-4.1,0,0-1-2.6-1.1-5.1,0-2,1.2-3.8,3.1-4.4,4.2-1.4,7.8-1.1,10.9.7,2.2,2,2.8,4.4,0,7.9h0Z"
            />
            <path
              id="fingerpart21"
              class="daumensattelgelenk_links1313_cls2"
              d="M104,161.6c.3-3.6,2.1-4.8,4.3-5.3.9-.2,1.9-.4,2.8-.5,3.9-.8,4.7,1.3,4.3,4.4.2,2.7,0,5.3-3.5,7.4-4,.6-7.1-.8-7.9-6h0Z"
            />
            <path
              id="fingerpart20"
              class="daumensattelgelenk_links1313_cls2"
              d="M68.7,306.2l3.5-4.7c3-7.1,8-8.8,14-7.6,4,2.3,7.8,2.9,11.1,0,2.6-4.7.8-11.7-2.7-19.6,1.5-4.8.2-9.6-3.7-14.6-9.5-6.4-16.8-23.1-22.3-47.8-5.7-19.8-10.1-47.5-14.2-77.5-3.7-5.9-3.8-10.1-1.5-13.2,1-9.8-5.8-16.3-16.9-21-8.9-3-17.5-1.9-25.8,2.7"
            />
            <path
              id="fingerpart19"
              class="daumensattelgelenk_links1313_cls2"
              d="M10,96.5c6.2-3,12-5.2,16.1-4.5,5.7-4.4,5.7-9.9,3.1-15.9-1.3-3.7-3.5-5.6-7.2-5.1-4.3,0-7.9-1.9-10.7-5.2"
            />
            <path
              id="fingerpart18"
              class="daumensattelgelenk_links1313_cls2"
              d="M37.4,306.2c-1.7-3.7-1.8-8.3-1.2-13.2,2-4,1.6-9.2.6-15,4.7-23.5,4.7-40.1,1.9-52.5-5.9-24.1-14.5-51.2-24.1-79.7-1.7-5.6-4.4-8.4-7.8-9.5"
            />
            <path
              id="fingerpart17"
              class="daumensattelgelenk_links1313_cls2"
              d="M52.5,306.2l-4.1-8c-1.1-7.5-5.8-15-13.6-22.5-12.5-16.4-23.5-33.2-33-50.3"
            />
            <path
              id="fingerpart16"
              class="daumensattelgelenk_links1313_cls2"
              d="M22.8,101.6c0-2.8,2.5-5.2,6.4-7.4,2.9-.8,5.1.4,6.4,4.3.1,4.4-1.6,7.8-6.2,9.9-4.9.6-7.4-1.3-6.6-6.8h0Z"
            />
            <path
              id="fingerpart15"
              class="daumensattelgelenk_links1313_cls3"
              d="M.4,243.6c4.8,9.9,10.9,18.1,18.5,24.5,5.9,3.9,10.6,7.9,13.6,11.9"
            />
            <path
              id="fingerpart14"
              class="daumensattelgelenk_links1313_cls3"
              d="M139.8,261.8c1.3-26.2,4.5-52.4,10.7-78.4.5-4.4,2-6.8,3.9-8.4"
            />
            <path
              id="fingerpart13"
              class="daumensattelgelenk_links1313_cls3"
              d="M99.9,257.4c16.7-5.7,24.6-18.8,27.4-36.1,2.5-17.7,7-33.1,12.4-47.4"
            />
            <path
              id="fingerpart12"
              class="daumensattelgelenk_links1313_cls3"
              d="M127.5,171.6c2.1,2.8,3.5,6.1,3.3,10.3"
            />
            <path
              id="fingerpart11"
              class="daumensattelgelenk_links1313_cls3"
              d="M148.1,131.1c-3.1-6.5-5.6-14.2-6.6-24.1-2-11.8-1.7-20.2,2-23.6"
            />
            <path
              id="fingerpart10"
              class="daumensattelgelenk_links1313_cls3"
              d="M124.1,124.6c8.2-12.6,10-25.9,6.2-39.7"
            />
            <path
              id="fingerpart9"
              class="daumensattelgelenk_links1313_cls3"
              d="M144.1,77.6c.4-3.5-.7-6-3.6-7.3-4.1-.6-8-.2-11.3,1.9-3.9,2.5-3.9,7.2-2.6,12.8"
            />
            <path
              id="fingerpart8"
              class="daumensattelgelenk_links1313_cls3"
              d="M130.1,16.4c-1.1,20.8,1.7,35.4,8.9,43.2"
            />
            <path
              id="fingerpart7"
              class="daumensattelgelenk_links1313_cls3"
              d="M137.5,61.1c-5.7-2.8-11.1-2.4-16.1,1.9.2-.7.3-1.5.3-2.5,0-1.1.3-2.2.8-3.3.6-1.2.6-2.7.5-4.2-.2-1.9,0-3.2.2-4.1.2-.5.3-.9.3-1.4,0-3.7,0-7.4.3-9.8.1-.7.3-1.4.7-2,2.1-4.1,2.6-8.5,2.2-13.1,0-.7-.3-1.5-.8-2-.7-.9-.8-2-.5-3.3"
            />
            <path
              id="fingerpart6"
              class="daumensattelgelenk_links1313_cls3"
              d="M118.6,65.1c1.1-1.4,1.5-3.2,1.1-5.5-1.2-1.5,1.8-7.4-.4-7.5"
            />
            <path
              id="fingerpart5"
              class="daumensattelgelenk_links1313_cls3"
              d="M126.6,150.9c1.6-3.9,8.4-4.5,21-1.5"
            />
            <path
              id="fingerpart4"
              class="daumensattelgelenk_links1313_cls3"
              d="M37.5,115.4c2.5,1.3,4.5,2.9,5,5.6.8,3.3,2.3,5.1,4,6.1,2.9,1.2,2.5,6.2.9,12.6-.6,2.4-.9,4.9-.7,7.4,2.3,35.9,7.2,53.8,12.1,71.3,2.7,14.1,7.6,25.2,15.1,32.8,6,7.3,10.7,14,13.5,19.7"
            />
            <path
              id="fingerpart3"
              class="daumensattelgelenk_links1313_cls3"
              d="M37.5,103.8c-.8,6.2,6.9,10,7.3,13.3,0,.7.4,1.3.7,1.9,2.2,3.8,4.3,3.2,5.9,5.2"
            />
            <path
              id="fingerpart2"
              class="daumensattelgelenk_links1313_cls3"
              d="M46.9,285c5.1-31.8,3.9-59.3-4.8-81.7-4.7-14.4-12.7-33.3-21.7-53.4l-5-17c-1.9-6.1-4.6-9.4-7.7-10.8"
            />
            <path
              id="fingerpart1"
              class="daumensattelgelenk_links1313_cls3"
              d="M13.2,136.4c-1.8-4.7-3.6-7-5.4-7"
            />
            <g id="outlines3">
              <path
                class="daumensattelgelenk_links1313_cls3"
                d="M141.4,298.2c-.4,1.3-.8,2.5-1.1,3.7"
              />
              <path
                class="daumensattelgelenk_links1313_cls1"
                d="M102.7,68.7c-4.2-10.3-3-26.3,1.3-45.4,4.4-14.4,10.2-22,17.4-22.4,7-1,12.1.2,15.2,3.7,1.6,1.8,2.4,4.2,2.5,6.6.7,24.5,4.4,42.1,10.2,54.6,1.8,3.9,2.8,8.2,2.9,12.6.9,26.3,5.4,50.5,11.7,73.6,2.5,9,1.3,19.3-1.2,30-10.5,46.4-8.4,69-18.6,104.7-.3,1.5-.6,2.8-.9,4.1"
              />
              <path
                class="daumensattelgelenk_links1313_cls3"
                d="M105.7,78.8c.3-1.3.7-2.5,1.1-3.7-.9-.9-1.6-1.9-2.3-3"
              />
              <path
                class="daumensattelgelenk_links1313_cls1"
                d="M100.7,128.5c2.5-4.9,3.6-11.2,3.3-18.7-.6-8.9-.5-16.7.3-23.3"
              />
              <path
                class="daumensattelgelenk_links1313_cls3"
                d="M92.8,132.7c1.1.6,2.2,1.2,3.3,1.9.9-.9,1.8-1.8,2.6-2.9"
              />
              <path
                class="daumensattelgelenk_links1313_cls1"
                d="M73.9,126.8c4.1.5,7.9,1.5,11.6,2.7"
              />
              <path
                class="daumensattelgelenk_links1313_cls3"
                d="M64.5,122.7c.5,1.2,1.1,2.3,1.6,3.5,1.3,0,2.6,0,3.8.2"
              />
              <path
                class="daumensattelgelenk_links1313_cls1"
                d="M19.9,57c6.9,3.8,12.8,7.6,15.7,10.9,8.8,13,17.3,29.6,25.7,47.8"
              />
              <path
                class="daumensattelgelenk_links1313_cls3"
                d="M13.2,53.3c1.1.6,2.3,1.2,3.4,1.8"
              />
            </g>
            <g id="outlines2">
              <path
                id="outlinespart4"
                class="daumensattelgelenk_links1313_cls3"
                d="M99.5,136.5c-1.1-.7-2.2-1.3-3.4-1.9"
              />
              <path
                id="outlinespart3"
                class="daumensattelgelenk_links1313_cls3"
                d="M106.8,141.5c-1-.8-2.1-1.5-3.1-2.3"
              />
            </g>
            <g id="outlines">
              <path
                id="outlinespart2"
                class="daumensattelgelenk_links1313_cls3"
                d="M93.3,142.1c-.5,1.3-.8,2.5-1,3.7"
              />
              <path
                id="outlinespart1"
                class="daumensattelgelenk_links1313_cls3"
                d="M96.1,136.4c-.7,1.2-1.3,2.3-1.8,3.4"
              />
            </g>
          </g>
          <path
            id="bonepart16"
            class="daumensattelgelenk_links1313_cls2"
            d="M73.7,512.5c-4.9-19.3-10.5-36.2-17.6-46.5l-18.5-22.4c-5.2-12.5-6.6-23,.3-29,3.9-2.8,11.4-5.9,24.2-9.6,8.7-8.8,21.4-11.9,36.2-11.9l18.1-22.3c11.5,2.3,14.3,14.3,14.1,29.6,9.5,23.6,7,50.5,3.1,77.6.2,12,1,23.5,2.3,34.5"
          />
          <path
            id="bonepart15"
            class="daumensattelgelenk_links1313_cls2"
            d="M18.1,397.3c-1.5,7.2,3.1,14.3,10.3,16.1,4.5,1.1,9,1.7,13.7,1.6,14.8,1.2,16.3-6.4,15.6-15.4,3.7-9.6,4.2-16.9-1.5-19.7l-9.4-7.4c-3.7-2.9-8.9-3.1-12.8-.3s-7.7,7.7-11.5,13.9c-2.2,4.1-3.7,7.9-4.4,11.3h0Z"
          />
          <path
            id="bonepart14"
            class="daumensattelgelenk_links1313_cls2"
            d="M68.2,404c11.7,0,22.7-7,33.1-20.1,9.1-10.1,9.3-17.1,5.3-22.6-2.5-11.1-10.1-16.2-19.8-18.5-8.4-3.9-13.2-2.1-15.9,2.8l-7.1,13.9c-5.7,8.1-8.3,18.3-9.2,29.7.9,9.8,5,15.2,13.6,14.8h0Z"
          />
          <path
            id="bonepart13"
            class="daumensattelgelenk_links1313_cls2"
            d="M137.6,344.5c.1-1.6.5-3.2,1.1-4.7,1.3-3.3.2-6-2.7-7.8-2.9-1.8-5.5-4.1-7-7.2-.9-1.8-2.4-5.8-2.4-5.8,0,0-.6-10.7-4.2-14.7-6.3-4.6-10.7-3.9-14.4-.8-1.9,1.7-3.4,3.9-4.3,6.3-2.3,5.6-5,10.2-8.3,13.1-1.9,1.7-3.6,3.6-5.1,5.7-2.9,4.3-4,8-.5,9.9,8.7,3.2,15.8,8.6,21.1,16.4,1.1,1.5,2.8,2.5,4.7,2.5,12.3-.2,21.5-3,22.1-12.9h0Z"
          />
          <path
            id="bonepart12"
            class="daumensattelgelenk_links1313_cls2"
            d="M71.1,361.3l-.5-13.7c0-2.1-1.3-4-3.1-5.1-4.2-2.5-2.3-7.3.6-12.5,3-6.9-.6-11.5-7.6-15-4.9-11.1-13.1-13.2-22.9-11.2-4.7-4.7-10.1-5-16-2.3-6.4,5.3-8.8,9.6-5.6,12.8l11.7,48c1.6,9.6,6.9,15.1,18.8,12.8,11.1,1.2,19.9-2.2,24.6-13.8h0Z"
          />
          <path
            id="bonepart11"
            class="daumensattelgelenk_links1313_cls2"
            d="M80.5,337.5c8.5.7,15-.2,16.6-4.9l3-8.7c5.2-5.3,4-10.7-2.8-16.4-4.9-5.3-9.9-7.2-15.3-4.1l-9.9,1.7c-13.2,4.1-4.5,7.6,1,22.3-.6,7.5,1.2,11.9,7.6,10h0Z"
          />
          <path
            id="bonepart10"
            class="daumensattelgelenk_links1313_cls2"
            d="M44.8,444.3l-4-11.9c-3.6-8.3,2.3-14.4,22.3-17.4,14.8-5.8,26.6-13.1,35.3-21.9"
          />
          <path
            id="bonepart9"
            class="daumensattelgelenk_links1313_cls3"
            d="M69.3,412.4c19.5-1.8,34.9-10.2,43.5-29.7"
          />
          <path
            id="bonepart8"
            class="daumensattelgelenk_links1313_cls3"
            d="M52.8,377.3c-2.2,2.3-5.5,3.6-10.5,3.5-1.1,0-2.1-.3-3-.9-4.1-2.3-7.7-2.3-12.7,0"
          />
          <path
            id="bonepart7"
            class="daumensattelgelenk_links1313_cls3"
            d="M64.8,375.2c12.8-11.2,14.7-22.6,10.4-34"
          />
          <path
            id="bonepart6"
            class="daumensattelgelenk_links1313_cls2"
            d="M111.8,356c-11.4,1.5-17.7-4.4-19.8-16.5"
          />
          <path
            id="bonepart5"
            class="daumensattelgelenk_links1313_cls2"
            d="M63.8,359.5c2.4-1.9,4.7-3.9,7.1-5.8"
          />
          <path
            id="bonepart4"
            class="daumensattelgelenk_links1313_cls3"
            d="M100.7,313.1c-10.9-8.6-22-5.4-33,2.8"
          />
          <path
            id="bonepart3"
            class="daumensattelgelenk_links1313_cls2"
            d="M65.6,335.7c-1.7,2.7-2.6,5.8,0,10.8"
          />
          <path
            id="bonepart2"
            class="daumensattelgelenk_links1313_cls2"
            d="M60.6,315c-3.7-3.3-7.2-5.7-10.3-5.7-.7,0-1.3.3-1.8.7-2.2,1.6-4.8,2.4-7.6,2.1"
          />
          <path
            id="bonepart1"
            class="daumensattelgelenk_links1313_cls3"
            d="M26.8,358.4c1.3-22.7-3.2-35.4-10.2-41.7"
          />
        </g>
      </g>
      <g id="highlights">
        <ellipse
          id="highlights1"
          class="daumensattelgelenk1313fill"
          style={{
            fill: props.colors.daumensattelgelenk1313fill,
            stroke: props.colors.daumensattelgelenk1313fill,
          }}
          cx="111.8"
          cy="292.7"
          rx="12.3"
          ry="33.4"
          transform="translate(-200.8 313.6) rotate(-72.9)"
        />
      </g>
    </svg>
  );
}

export default LinkesDaumenSattelGelenkLatSitzend1313;
