import style from "./style.scss";

function Linke5zeheDplSitzendLinks1419(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.96 492.58">
      <g id="fifth_toe" data-name="fifth toe">
        <path
          id="fifth_toe_1"
          data-name="fifth toe 1"
          class="a1419_5Zehe_links_cls_2"
          style={{
            fill: props.colors.a1419_5Zehe_links_cls_2,
            stroke: props.colors.a1419_5Zehe_links_cls_2,
          }}
          d="M45.73,33.87h0c9.77,8.55,12.21,14.65,4.88,20.76-4.88,7.33-3.66,12.21,3.66,15.87,9.77,3.66,4.88,9.77-1.22,14.65H20.09c-7.33-4.88-8.55-9.77-2.44-15.87,6.1-4.88,6.1-9.77,3.66-15.87-13.43-1.22-15.87-6.1,1.22-17.09,3.66-15.87,12.21-15.87,23.2-2.44h0Z"
        />
        <path
          id="fifth_toe_2"
          data-name="fifth toe 2"
          class="a1419_5Zehe_links_cls_2"
          style={{
            fill: props.colors.a1419_5Zehe_links_cls_2,
            stroke: props.colors.a1419_5Zehe_links_cls_2,
          }}
          d="M61.6,107.12h0c-3.66,15.87,0,25.64,7.33,31.74,7.33,18.31,1.22,21.98-9.77,18.31l-23.2,3.66c-9.77,0-13.43-3.66-4.88-15.87,2.44-8.55,1.22-19.53-3.66-34.19-14.65-18.31-15.87-26.86,3.66-20.76,7.33-1.22,12.21-1.22,17.09,1.22,13.43-7.33,14.65,2.44,13.43,15.87h0v.02Z"
        />
        <path
          id="fifth_toe_3"
          data-name="fifth toe 3"
          class="a1419_5Zehe_links_cls_2"
          style={{
            fill: props.colors.a1419_5Zehe_links_cls_2,
            stroke: props.colors.a1419_5Zehe_links_cls_2,
          }}
          d="M72.58,185.26h0c-4.88,39.07,0,65.93,9.77,80.58,14.65,19.53,13.43,26.86-8.55,17.09-9.77-10.99-20.76-9.77-31.74,6.1-12.21,9.77-15.87,4.88-7.33-17.09,8.55-20.76,10.99-46.39-4.88-84.24-8.55-17.09,0-24.42,14.65-26.86,3.66,3.66,7.33,3.66,10.99,0,14.65-1.22,19.53,8.55,17.09,24.42h0Z"
        />
        <path
          id="fifth_toe_4"
          data-name="fifth toe 4"
          class="a1419_5Zehe_links_cls_1"
          style={{
            fill: props.colors.a1419_5Zehe_links_cls_1,
            stroke: props.colors.a1419_5Zehe_links_cls_1,
          }}
          d="M37.28,492.08c4.09-46.65,5.26-91.68-2.54-132.23-10.99-17.09-13.43-32.96-3.66-43.95-2.44-8.55-1.22-15.87,4.88-20.76,15.87-17.09,31.74-15.87,47.62,1.22h0c1.22,8.55,2.44,18.31,4.88,28.08,3.66,12.21,4.88,25.64-15.87,45.17-.65,39.79-1.3,80.27-.65,114.57"
        />
        <path
          id="fifth_toe_5"
          data-name="fifth toe 5"
          class="a1419_5Zehe_links_cls_1"
          style={{
            fill: props.colors.a1419_5Zehe_links_cls_1,
            stroke: props.colors.a1419_5Zehe_links_cls_1,
          }}
          d="M32.3,298.81l14.65,7.33c12.21,6.1,23.2,0,34.19-8.55"
        />
        <path
          id="outline"
          class="a1419_5Zehe_links_cls_3"
          style={{
            fill: props.colors.a1419_5Zehe_links_cls_3,
            stroke: props.colors.a1419_5Zehe_links_cls_3,
          }}
          d="M97.46,186.9c-10.77-6.44-15.98-12.66-17.33-18.72-.64-29.71-3.31-58.22-9.92-84.42,3.62-27.2-1.1-50.3-13.47-69.65C47.11,1.71,36.75-2.5,25.56,2.65c-9.08,4.19-15.53,13.1-17.45,23.41-3.92,21.02-3.71,43.12-.24,66.06-2.86,23.53-1.59,47.71,3.36,72.48C-1.45,210.04-1.66,256.38,4.23,306.34"
        />
      </g>
      <g id="overlays">
        <g id="Phalangen_overlay" data-name="Phalangen overlay">
          <path
            id="Phalangen_overlay_1"
            data-name="Phalangen overlay 1"
            class="a1419_phalangenFill"
            style={{
              fill: props.colors.a1419_phalangenFill,
              stroke: props.colors.a1419_phalangenFill,
            }}
            d="M45.45,33.33h0c9.77,8.55,12.21,14.65,4.88,20.76-4.88,7.33-3.66,12.21,3.66,15.87,9.77,3.66,4.88,9.77-1.22,14.65H19.81c-7.33-4.88-8.55-9.77-2.44-15.87,6.1-4.88,6.1-9.77,3.66-15.87-13.43-1.22-15.87-6.1,1.22-17.09,3.66-15.87,12.21-15.87,23.2-2.44h0Z"
          />
          <path
            id="Phalangen_overlay_2"
            data-name="Phalangen overlay 2"
            class="a1419_phalangenFill"
            style={{
              fill: props.colors.a1419_phalangenFill,
              stroke: props.colors.a1419_phalangenFill,
            }}
            d="M61.32,106.58h0c-3.66,15.87,0,25.64,7.33,31.74,7.33,18.31,1.22,21.98-9.77,18.31l-23.2,3.66c-9.77,0-13.43-3.66-4.88-15.87,2.44-8.55,1.22-19.53-3.66-34.19-14.65-18.31-15.87-26.86,3.66-20.76,7.33-1.22,12.21-1.22,17.09,1.22,13.43-7.33,14.65,2.44,13.43,15.87h0v.02Z"
          />
          <path
            id="Phalangen_overlay_3"
            data-name="Phalangen overlay 3"
            class="a1419_phalangenFill"
            style={{
              fill: props.colors.a1419_phalangenFill,
              stroke: props.colors.a1419_phalangenFill,
            }}
            d="M72.3,184.72h0c-4.88,39.07,0,65.93,9.77,80.58,14.65,19.53,13.43,26.86-8.55,17.09-9.77-10.99-20.76-9.77-31.74,6.1-12.21,9.77-15.87,4.88-7.33-17.09,8.55-20.76,10.99-46.39-4.88-84.24-8.55-17.09,0-24.42,14.65-26.86,3.66,3.66,7.33,3.66,10.99,0,14.65-1.22,19.53,8.55,17.09,24.42h0Z"
          />
        </g>
        <g id="joint_overlay" data-name="joint overlay">
          <ellipse
            id="joint_overlay_1"
            data-name="joint overlay 1"
            class="a1419_gelenkeFill"
            style={{
              fill: props.colors.a1419_gelenkeFill,
              stroke: props.colors.a1419_gelenkeFill,
            }}
            cx="36.61"
            cy="84.61"
            rx="25.75"
            ry="11.49"
          />
          <ellipse
            id="joint_overlay_2"
            data-name="joint overlay 2"
            class="a1419_gelenkeFill"
            style={{
              fill: props.colors.a1419_gelenkeFill,
              stroke: props.colors.a1419_gelenkeFill,
            }}
            cx="50.49"
            cy="158.42"
            rx="25.53"
            ry="11.62"
          />
          <ellipse
            id="joint_overlay_3"
            data-name="joint overlay 3"
            class="a1419_gelenkeFill"
            style={{
              fill: props.colors.a1419_gelenkeFill,
              stroke: props.colors.a1419_gelenkeFill,
            }}
            cx="59.86"
            cy="287.38"
            rx="37.6"
            ry="15.72"
          />
        </g>
      </g>
    </svg>
  );
}

export default Linke5zeheDplSitzendLinks1419;
