import style from "./style.scss";

function Lendenwirbelkorper5LatLiegend1718(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 357.65 304.06">

<g id="Anatomy">
  <path id="Anatomy17" class="Lendenwirbelkorper5_lat1718_cls9" d="M19.39,69.39c4.73-5.42,29.6-9.2,53.3-8.9,28.9.4,61.7,7.3,61.5,13-.2,5.6-32.5,10.2-60.2,8.9-25.3-1.2-54.8-7.6-54.6-13h0Z"/>
  <path id="Anatomy16" class="Lendenwirbelkorper5_lat1718_cls5" d="M23.79.39c-.1,2.6-.2,5.3-.4,8.2-2.5,34-14.3,53.8-6.8,58.8,3.3,2.2,6.7-.8,17.7-4,20.79-3.3,33.7-2.31,46.67-3.28,1.83-.14,3.67-.06,5.49.23,10.54,1.67,22.74,3.47,36.04,6.74,12,4.5,16.6,10,21.3,6.5s-.9-13.1-1.4-34.9c-.1-3-.4-18.4,3.9-37.2"/>
  <path id="Anatomy15" class="Lendenwirbelkorper5_lat1718_cls6" d="M13.49,129.89c.4-2.2,1.3-5.6,3.8-8.6,4.3-5.2,10.8-6.2,15.7-6.8,22.3-3.1,33.5-4.6,47.2-4.8,7.3-.1,10.9.1,17.1-.9,10.5-1.8,19-5.5,20.2-6,11.7-5.2,14.8-9.9,20-8.3,5,1.6,6.9,7.4,7,7.8,1.7,5.3-1.3,9.3-3.4,16.4-2,6.7-2,11.7-2.1,20.5,0,13,0,23.6,6.1,33.5,2.8,4.5,6.2,7.6,7.5,14.4.8,3.8.8,8.2,0,11.1-3.5,12.3-26.8,8.1-61.5,22.4-12.4,5.1-25.5,8.6-37.6,14.4-6,2.8-13.1,6.5-17.8,3.4-3.8-2.5-4.3-8.3-4.8-13.7-.7-7.9,1.1-10.7,1.4-17.8.2-4.9-1.2-10.6-4.1-21.9-1.6-6.3-1.6-5.5-7.5-26-1.8-6.2-2.9-10-4.1-15-1.4-5.8-2.4-10.6-3.1-14.1h0Z"/>
  <path id="Anatomy14" class="Lendenwirbelkorper5_lat1718_cls8" d="M145.99,1.39c-.2.9-.3,1.7-.4,2.5,0,0-.6,2.8-2,24.3-.2,5-.3,7.8,1.5,9.6,3.6,3.6,9.7-1.7,19.6-.5,12.7,1.6,20.4,12.6,21.4,14.1,1.7,2.5,5.7,8.5,5,16-.5,5.3-3.1,7.5-5,11.9-4.3,9.6-1.1,19.7,1.8,28.7,5.1,15.9,17.3,34.7,24.5,32.7,1.5-.4,2.6-1.7,7.5-15.7,4.7-13.4,7.1-20.1,7.6-23.4,2.3-15-1.4-27.3-3.5-34.1-2.7-8.7-3.7-7.4-8.2-19.1-1.5-4-3.3-9.2-9.7-35.9-.9-3.8-1.7-7.2-2.4-10.3"/>
  <path id="Anatomy13" class="Lendenwirbelkorper5_lat1718_cls8" d="M355.39,52.19c-1.2-1.8-4-4.5-13.4-11.2-11.5-8.2-17.3-12.3-25.5-16.4-3-1.5-11.4-4.5-28.2-10.5-7-2.5-10.9-3.8-16.4-4.1-6.7-.4-8.3,1.1-16,1.5-11.5.6-13.1-2.5-21.4-2-1.6.1-18.6,1.3-23.4,10.9-3.9,7.9,5,12.2,10.9,37.4,4.2,17.8,6.3,26.6,6.4,29.2.2,4.3.1,9.7,3.2,11.4,2.8,1.5,5.8-1.1,11.9-3.2.8-.3,13.5-4.5,23.7-.9,7.2,2.5,5.4,6.1,15.5,13.2,12.3,8.7,24,9.5,26.4,9.6,3.6.2,8.5.4,14.1-1.8,5-2,4.9-3.7,9.6-5.5,8-3.1,11.1.5,16.4-2.5,3.1-1.8,4.9-4.7,6.2-7.8"/>
  <path id="Anatomy12" class="Lendenwirbelkorper5_lat1718_cls6" d="M143.29,120.69c3.4-2.5,6.6.8,11.4-1.4,3-1.4,4.7-4,10.5-19.1,4.6-11.9,5.9-16.5,10-17.8,2-.6,4.6-.4,6.4.9,3.5,2.5,2.7,7.9,3.5,14.4.6,4.6,2.4,9.1,6.1,18,5.4,13,10.4,15,21.4,31.4,7.8,11.6,12.4,21.2,14.6,26,7.4,15.8,11.1,23.6,11.4,33.7.3,12.3-4.1,33.6-18.2,38.3-11.9,3.9-26.1-6.2-33.3-15-6.4-7.9-9.3-19.4-15-42.4-3.5-13.9-4.9-22.5-10.5-24.1-6.1-1.8-11,7.5-15.9,5.5-2.7-1.1-3.1-4.5-4.6-28.2-.8-12.9-1.1-17.8,2.2-20.2h0Z"/>
  <path id="Anatomy11" class="Lendenwirbelkorper5_lat1718_cls7" d="M175.19,125.29c23.7,15.5.4,31,.9,31"/>
  <path id="Anatomy10" class="Lendenwirbelkorper5_lat1718_cls7" d="M196.19,131.19c6.1,42,34.2,64.7,32.2,85.7"/>
  <path id="Anatomy9" class="Lendenwirbelkorper5_lat1718_cls6" d="M216.19,143.89c1.6-1.3,4.8,2.9,12.3,4.1,8.4,1.4,15.1-2.2,18.7-3.6,12.1-4.9,18.8,2.2,57.9,11.4,23.7,5.6,37.4,6.8,39.6,15,1,3.6-1.1,5.9,0,13.2,1.2,8.6,4.7,10.4,5.5,15.5,2.2,14.7-21.9,36-37.8,32.8-3.7-.7-5.5-2.5-10.9-2.7-8.6-.4-10.5,4.4-17.3,4.1-11.6-.5-14-10-25.1-10.5-9.2-.4-13.4,7.9-17.3,5.5-2.5-1.6-2.7-7.1-3.2-18.2-.2-4.3,0-4.4-.2-6.9-.8-8.3-3.7-14.4-5-17.1-16.7-34.3-18.9-41.1-17.2-42.6h0Z"/>
  <path id="Anatomy8" class="Lendenwirbelkorper5_lat1718_cls10" d="M243.59,150.29c17.8-2.1,34.8,2.9,51.8,8.9,22,7,44,17,62.1,32"/>
  <path id="Anatomy7" class="Lendenwirbelkorper5_lat1718_cls10" d="M32.39,206.79c-9.9,9.8-23.5,23.6-32.2,34.4"/>
  <path id="Anatomy6" class="Lendenwirbelkorper5_lat1718_cls10" d="M73.99,270.59c13.7,1,58.7-51.2,83.4-51.9"/>
  <path id="Anatomy5" class="Lendenwirbelkorper5_lat1718_cls10" d="M157.39,218.69c13.5,23.6,29.7,45.6,46.7,66.8,4.4,5.5,8.9,11,13.5,16.4"/>
  <path id="Anatomy4" class="Lendenwirbelkorper5_lat1718_cls10" d="M251.29,229.59c30.1,24.6,64.1,45.6,91.3,74.3"/>
  <path id="Anatomy3" class="Lendenwirbelkorper5_lat1718_cls10" d="M143.59,149.89c0-3.4,29.8-6.2,66.7-6.2"/>
  <path id="Anatomy2" class="Lendenwirbelkorper5_lat1718_cls11" d="M136.19,149.89c-21.3,7.5-78.4,23.9-103.8,56.9"/>
  <line id="Anatomy1" class="Lendenwirbelkorper5_lat1718_cls11" x1="159.79" y1="221.79" x2="174.09" y2="195.89"/>
</g>
<g id="Overlays">
  <path id="Highlight4" class="WK1718Fill" style= {{
            fill: props.colors.WK1718Fill,
            stroke: props.colors.WK1718Fill,
            opacity:props.colors.WK1718Fill,
          }} d="M14.44,128.75c.4-2.2,1.3-5.6,3.8-8.6,4.3-5.2,10.8-6.2,15.7-6.8,22.3-3.1,33.5-4.6,47.2-4.8,7.3-.1,10.9.1,17.1-.9,10.5-1.8,19-5.5,20.2-6,11.7-5.2,14.8-9.9,20-8.3,5,1.6,6.9,7.4,7,7.8,1.7,5.3-1.3,9.3-3.4,16.4-2,6.7-2,11.7-2.1,20.5,0,13,0,23.6,6.1,33.5,2.8,4.5,6.2,7.6,7.5,14.4.8,3.8.8,8.2,0,11.1-3.5,12.3-26.8,8.1-61.5,22.4-12.4,5.1-25.5,8.6-37.6,14.4-6,2.8-13.1,6.5-17.8,3.4-3.8-2.5-4.3-8.3-4.8-13.7-.7-7.9,1.1-10.7,1.4-17.8.2-4.9-1.2-10.6-4.1-21.9-1.6-6.3-1.6-5.5-7.5-26-1.8-6.2-2.9-10-4.1-15-1.4-5.8-2.4-10.6-3.1-14.1h0Z"/>
  <path id="Highlight3" class="Dornfortsatz1718Fill" style= {{
            fill: props.colors.Dornfortsatz1718Fill,
            stroke: props.colors.Dornfortsatz1718Fill,
            opacity:props.colors.Dornfortsatz1718Fill,
          }} d="M216.54,143.86c1.6-1.3,4.8,2.9,12.3,4.1,8.4,1.4,15.1-2.2,18.7-3.6,12.1-4.9,18.8,2.2,57.9,11.4,23.7,5.6,37.4,6.8,39.6,15,1,3.6-1.1,5.9,0,13.2,1.2,8.6,4.7,10.4,5.5,15.5,2.2,14.7-21.9,36-37.8,32.8-3.7-.7-5.5-2.5-10.9-2.7-8.6-.4-10.5,4.4-17.3,4.1-11.6-.5-14-10-25.1-10.5-9.2-.4-13.4,7.9-17.3,5.5-2.5-1.6-2.7-7.1-3.2-18.2-.2-4.3,0-4.4-.2-6.9-.8-8.3-3.7-14.4-5-17.1-16.7-34.3-18.9-41.1-17.2-42.6h0Z"/>
  <path id="Highlight2" class="Querfortsatz1718Fill" style= {{
            fill: props.colors.Querfortsatz1718Fill,
            stroke: props.colors.Querfortsatz1718Fill,
            opacity:props.colors.Querfortsatz1718Fill,
          }} d="M174.64,124.99c23.7,15.5.4,31,.9,31"/>
  <path id="Highlight1" class="Angrenzende_WK1718Fill" style= {{
            fill: props.colors.Angrenzende_WK1718Fill,
            stroke: props.colors.Angrenzende_WK1718Fill,
            opacity:props.colors.Angrenzende_WK1718Fill,
          }} d="M19.41,69c4.73-5.42,29.6-9.2,53.3-8.9,28.9.4,61.7,7.3,61.5,13-.2,5.6-32.5,10.2-60.2,8.9-25.3-1.2-54.8-7.6-54.6-13h0Z"/>
  <path id="Highlight" class="Angrenzende_WK1718Fill" style= {{
            fill: props.colors.Angrenzende_WK1718Fill,
            stroke: props.colors.Angrenzende_WK1718Fill,
            opacity:props.colors.Angrenzende_WK1718Fill,
          }} d="M23.81,0c-.1,2.6-.2,5.3-.4,8.2-2.5,34-14.3,53.8-6.8,58.8,3.3,2.2,6.7-.8,17.7-4,20.79-3.3,33.7-2.31,46.67-3.28,1.83-.14,3.67-.06,5.49.23,10.54,1.67,22.74,3.47,36.04,6.74,12,4.5,16.6,10,21.3,6.5s-.9-13.1-1.4-34.9c-.1-3-.4-18.4,3.9-37.2"/>
</g>
</svg>
  );
}

export default Lendenwirbelkorper5LatLiegend1718;
