import style from "./style.scss";

function LinkesOberesSprunggelenkVdLiegend1457(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 268.8 489.5">
  
  <g id="upper_ankle" data-name="upper ankle">
    <g id="background">
      <path id="background_1" data-name="background 1" class="oberes_sprunkgelenk1457_vd_links_cls4" d="M47.9,0c-3.9,0-7.2,3.1-7.4,7-5.2,97.2-16.4,189.5-36.4,274.4-7,28-5.6,54.6,8.4,79.2,1.8,3.2,2.6,6.8,2.6,10.4-.2,13.9,4.7,26.8,15,38.9,3.1,3.6,4.4,8.4,3.5,13-4.4,24.8.1,45.2,22.1,56.9l21.5,4.9c18.4,4.2,37.4,5.7,56.2,4.4l110.4-7.4c15.3-1,26.6-14.7,24.7-29.9l-3.7-29.6c-1.9-15.3-6.5-30.2-13.5-44l-17.5-34.6c-5-9.8-5.8-21.1-2.5-31.6,3.9-12.5,6.9-24.8,8.5-37,1.8-13.8-.4-27.9-6.4-40.5-29.9-62.7-33.8-139.3-25.7-222.2C208.4,5.7,203.2,0,196.6,0H47.9Z"/>
    </g>
    <path id="tarsus1" class="oberes_sprunkgelenk1457_vd_links_cls8" d="M198.4,477.5c-.5-19.1-13.2-27.6-41.6-22.5-24.2,2.2-46.9,4.7-66.9,7.9-2,7.8.6,16.3,6.2,25.3"/>
    <path id="tarsus2" class="oberes_sprunkgelenk1457_vd_links_cls8" d="M240,479.8c1.8-8.3,1.5-17.6-.1-27.5-1.1-6.8-8.2-10.9-14.7-8.5l-23.5,9c-3.8,1.4-5.6,5.8-3.9,9.5,2.3,5.2,4.2,10.9,5.6,16.9"/>
    <path id="tarsus3" class="oberes_sprunkgelenk1457_vd_links_cls8" d="M244.5,476.4c2.2-8,2.2-14.6.4-19.9.1-4.8,3.5-8.9,8.2-10l12.2-2.7"/>
    <path id="ulnar1" class="oberes_sprunkgelenk1457_vd_links_cls8" d="M76.3,308.8c-20.6.4-33.2,15-41.1,38.2"/>
    <path id="tarsus4" class="oberes_sprunkgelenk1457_vd_links_cls8" d="M261.9,440.4c-14.3,1.3-23.7,7.4-31.3,15s-1,.9-1.5,1.3c-12.7,9.3-25.9,8.4-39.7-3.2-2-1.7-4.5-2.7-7.1-3l-14.1-1.7c-10.3-1.3-15.5-13-9.6-21.5l17.9-25.5c2.1-3,5.4-5.1,9-5.7l38.4-6.3c.3,0,.5-.1.7-.1,13.3-3,25.8-3,32.8,16.4"/>
    <path id="talus" class="oberes_sprunkgelenk1457_vd_links_cls8" d="M153.4,423.5c31.1,7,57.4.5,73.7-33.8,3.2-9.5.7-17.3-7.3-23.6.6-6,1.2-12-6.2-15.8-1.9-2.2-2.9-5.5-3.3-9.3-.6-6.2-3.9-11.8-8.7-15.8-7.6-6.4-12.2-15.7-14.5-27.3-7.1-16.1-21.8-25.1-49.5-21.9-36.6-3.5-60.3,4.4-55.1,37.1l3,23.4c1,7.8,11.6,23.4,15.5,27.7l51.1,57.5,1.3,1.8h0Z"/>
    <path id="tarsus5" class="oberes_sprunkgelenk1457_vd_links_cls8" d="M123.5,485.3c21.7.5,32.3-8.3,27.6-29.7.8-7.1,5.1-11.6,10.7-15.2,7.6-1.3,9-8.6,7.3-19.1,17.6-21.3,23.6-33.6,21.9-39.9.6-20.9-5.1-30.6-19.1-25.9-12,5.6-27.3-1.2-45-16.9-22.5-21.9-40-28.7-54-24.8-17.7,3.1-25.6,15.1-27.6,32.6-6.6,27.7-7.4,52.2,9,66.9-6.1,28.3-3.9,48,16.9,48.4,15-.9,28.7,4.9,40.9,18.3,3,3.2,7.1,5.2,11.4,5.3h0Z"/>
    <path id="ulnar2" class="oberes_sprunkgelenk1457_vd_links_cls8" d="M51,0c-4.1,111.8-12,204.8-23.6,279-8.6,22.6-14,43.5-11.2,60.2,8.2,18.1,14.9,23.4,19.1,7.9,20.6-2.9,34.4-15.6,41.1-38.2,4.7-16.5,2.9-45.5,0-76.5-4.6-67.4.8-145.3,9-226.1"/>
    <path id="radius" class="oberes_sprunkgelenk1457_vd_links_cls8" d="M114.5,2.8c-8.2,97.1-18.8,176.6-34.9,214.3-16.2,21.4-19.3,41.5,5.1,58.5,6.8-3.2,14-5.1,21.9-4.5,45.4-8.3,75.8-7.1,79.3,10.7,5.6,11.3,11.4,20.2,17.4,20.8,2.9,10.7,7.9,14,16.3,5.1,8.8-17.4,8.6-34.3-2.2-50.6-37.2-55.7-37.3-152.2-37.1-249.3"/>
  </g>
  <g id="overlays">
    <path id="upper_ankle_joint_overlay" data-name="upper ankle joint overlay" class="OSG1457Fill" style= {{
              fill: props.colors.OSG1457Fill,
              stroke: props.colors.OSG1457Fill,
              opacity:props.colors.OSG1457Fill,
            }} d="M187.1,297.9c4.8.7,4.1-5-1.2-16.1-4.3-17.3-34.9-18.3-79.3-10.7-7.5-.6-14.8.9-21.9,4.5l-5.6-4.4c.9,25.1-.7,40.7-6.2,42.5,3.6-1,7-1.5,9.6-.7-2.3-11.6-.5-21.3,6.7-28.3,9.3-9,26.2-11.3,48.4-8.8,24.9-3,41.7,4,49.5,21.9h0Z"/>
    <path id="fibula_overlay" data-name="fibula overlay" class="distalFibula1457Fill" style= {{
              fill: props.colors.distalFibula1457Fill,
              stroke: props.colors.distalFibula1457Fill,
              opacity:props.colors.distalFibula1457Fill,
            }} d="M34.7,274.1c11.4-6.4,22.8-7.1,34.3-2.2,6.2,2.7,10.3,8.6,10.4,15.4.3,19.3-5.9,35.5-18.6,48.4-7.6,7-16.2,10.5-25.5,11.3-2.6,11.1-7,12.5-13.5,2.6l-5.6-11.1c-2.5-16.1,1.8-33.4,8.9-52.9,1.8-4.9,5.2-9,9.8-11.5Z"/>
    <g id="beide_Malleolen" data-name="beide Malleolen">
      <path id="tibialmalleoli_overlay" data-name="tibialmalleoli overlay" class="TibialMalleolus1457Fill" style= {{
              fill: props.colors.TibialMalleolus1457Fill,
              stroke: props.colors.TibialMalleolus1457Fill,
              opacity:props.colors.TibialMalleolus1457Fill,
            }} d="M203.3,302.6c4.3-11.5,11.4-18.8,22.6-20.1,0,8.9-2,17.3-6.3,25.2-8.4,9.1-13.2,5.4-16.3-5.1Z"/>
      <path id="fibialmalleoli_overlay" data-name="fibialmalleoli overlay" class="beideMalleolen1457Fill" style= {{
              fill: props.colors.beideMalleolen1457Fill,
              stroke: props.colors.beideMalleolen1457Fill,
              opacity:props.colors.beideMalleolen1457Fill,
            }} d="M18.2,337.6c5.5-3.7,12.3-3.9,20.6,0l-3.7,9.4c-3,11.9-7.7,11.9-13.5,2.6l-4.4-8.3c-.7-1.3-.2-2.9,1-3.7h0Z"/>
    </g>
    <path id="tibialmalleoli_overlay-2" data-name="tibialmalleoli overlay" class="TibialMalleolus1457Fill" style= {{
              fill: props.colors.TibialMalleolus1457Fill,
              stroke: props.colors.TibialMalleolus1457Fill,
              opacity:props.colors.TibialMalleolus1457Fill,
            }} d="M202.7,303.1c4.3-11.5,11.4-18.8,22.6-20.1,0,8.9-2,17.3-6.3,25.2-8.4,9.1-13.2,5.4-16.3-5.1Z"/>
    <path id="talus_overlay" data-name="talus overlay" class="Talus1457Fill" style= {{
              fill: props.colors.Talus1457Fill,
              stroke: props.colors.Talus1457Fill,
              opacity:props.colors.Talus1457Fill,
            }} d="M153.4,423.5c31.1,7,57.4.5,73.7-33.8,3.2-9.5.7-17.3-7.3-23.6.6-6,1.2-12-6.2-15.8-1.9-2.2-2.9-5.5-3.3-9.3-.6-6.2-3.9-11.8-8.7-15.8-7.6-6.4-12.2-15.7-14.5-27.3-7.1-16.1-21.8-25.1-49.5-21.9-36.6-3.5-60.3,4.4-55.1,37.1l3,23.4c1,7.8,11.6,23.4,15.5,27.7l51.1,57.5,1.3,1.8h0Z"/>
    <path id="clear_joint_view_overlay" data-name="clear joint view overlay" class="Gelenksicht1457Fill" style= {{
              fill: props.colors.Gelenksicht1457Fill,
              stroke: props.colors.Gelenksicht1457Fill,
              opacity:props.colors.Gelenksicht1457Fill,
            }} d="M185.9,281.8c4.7,10.9,10.1,19,17.4,20.8,3,11.8,8.5,13,16.3,5.1-.5,13.4-4,22.4-16.3,19-9.3-8.1-14.5-17.8-16.2-28.9-2.9-6.4-6.3-11.3-10.4-14,0,0,1-8.9,9.2-2.1h0Z"/>
  </g>
</svg>
  );
}

export default LinkesOberesSprunggelenkVdLiegend1457;
