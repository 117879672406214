import style from "./style.scss";

function Linker5FingerDpSitzend1327(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 155.6 474.2"
      
    >
      <g id="highlights">
        <g id="finger">
          <path
            id="basephalanx_00000086662248931081211750000005616135440204488583_"
            class="FullFinger1327Fill"
            style={{
              fill: props.colors.FullFinger1327Fill,
              stroke: props.colors.FullFinger1327Fill,
              opacity: props.colors.FullFinger1327Fill,
            }}
            d="M49.6,414.9c-9.3,8.1-15.3,6.5-19.4,0
			c-9.2-7.1-7-16.4,0-26.7c8.9-22.6,6.5-57.8,0.2-97.7c-0.1-0.7-0.1-1.4-0.1-2.1c0.6-9.8-0.5-20-4-30.9c-0.5-1.4-1.4-2.7-2.5-3.7
			c-2.3-2-3.2-5.4-2.7-10.3c0.2-1.6,0.2-3.2,0-4.8c-1.9-16.1,1.2-20.7,6.1-21.5l11.1,1.1c2.9,0.3,5.9-0.1,8.6-1.3
			c8.6-3.5,16.5-5.1,22.5-2.5c1.3,0.6,2.4,1.7,3.1,3c2.2,4.5,3.1,9.1,2.5,13.6c-0.2,1.7,0.3,3.4,1.3,4.8c2.3,2.9,3.1,6.1,0.9,9.5
			c-2.3,6.1-2.3,12.9-0.5,20.2c0.3,1.1,0.5,2.3,0.6,3.4c0.4,6.8,0.9,15.3,1.4,25.3c1.9,41.1,8.8,73.9,24.6,92.4
			c4.2,8.1,3.9,15.2,0,21.6c0.1,8.2-3.3,8.8-7.9,6.7C78.4,409.2,62.9,408.4,49.6,414.9L49.6,414.9L49.6,414.9L49.6,414.9z"
          />
          <path
            id="middlephalanx_00000168102899289477492530000002109010150837291183_"
            class="FullFinger1327Fill"
            style={{
              fill: props.colors.FullFinger1327Fill,
              stroke: props.colors.FullFinger1327Fill,
              opacity: props.colors.FullFinger1327Fill,
            }}
            d="M15.1,195.6
			c5-9.3,7.3-20.9,7.9-34.1c0.1-2.9,0.6-5.9,1.2-8.8c3.7-16.2,2.8-28.2-3.1-35.5c-1.7-2.1-2.3-4.8-2-7.4c0.4-3.5,0.9-6.2,1.8-8
			c0.6-1.1,1-2.2,1.1-3.4c0.7-7.6,3.1-10.1,13.2-8.1c4,1.3,8.1,0.6,12.6-0.6c8.1-4,13.4-1,12.8,6.7c-0.2,1.9,0.4,3.7,1.5,5.2
			c3.8,4.8,4,9.1,1,15.3c-1,2-1.3,4.3-1,6.5c0.2,19.7,3.4,40.5,9,62.1c0.6,2.1,1.6,4.2,3.1,5.8c3.3,3.6,2.9,8.3-0.3,13.8
			c-0.9,1.6-2.6,2.4-4.4,2.3c-6.6-0.5-15.3,2-24.5,5.1c-1.3,0.4-2.6,0.4-3.9,0c-7.5-2.5-14.6-3.9-20.8-3.6c-4.1-0.9-5.6-4.4-6.2-8.9
			C14.1,198.5,14.4,196.9,15.1,195.6L15.1,195.6L15.1,195.6L15.1,195.6z"
          />
          <path
            id="endphalanx_00000183936672798517203280000003247154166551942036_"
            class="FullFinger1327Fill"
            style={{
              fill: props.colors.FullFinger1327Fill,
              stroke: props.colors.FullFinger1327Fill,
              opacity: props.colors.FullFinger1327Fill,
            }}
            d="M21.9,83.8c-3.2-1.8-4.1-4.3-3.1-7.3
			c7.7-8.4,12.6-17.7,12.6-29.7c0-1-0.2-2.1-0.7-3c-1.2-2.3-1.3-6-1-10.2C29.3,28,33,22.9,39.3,18.1c6.2-4.4,16.5-2.3,18.3,0.5
			c3.8,9.4,3.8,17.4,0,23.9c-1.5-0.7-5.4,18.8,4.2,27.5c3.3,2.9,3.8,5.7,0.5,8.5c-0.5,4.9-4.4,6.2-9.9,5.9c-5.1,2.5-10.2,3-15.4,1.7
			C27.4,84.1,25.1,88.6,21.9,83.8C21.9,83.8,21.9,83.8,21.9,83.8L21.9,83.8z"
          />
        </g>
        <g id="basejointhighlight">
          <ellipse
            class="Grundgelenk1327Fill"
            style={{
              fill: props.colors.Grundgelenk1327Fill,
              stroke: props.colors.Grundgelenk1327Fill,
              opacity: props.colors.Grundgelenk1327Fill,
            }}
            cx="68.3"
            cy="414.7"
            rx="48"
            ry="11.8"
          />
        </g>
        <g id="fingerendjointhighlight">
          <ellipse
            class="Fingerendgelenk1327Fill"
            style={{
              fill: props.colors.Fingerendgelenk1327Fill,
              stroke: props.colors.Fingerendgelenk1327Fill,
              opacity: props.colors.Fingerendgelenk1327Fill,
            }}
            cx="41.2"
            cy="86.5"
            rx="27.1"
            ry="7.6"
          />
        </g>
        <path
          id="fingertiphighlight"
          class="Fingerkuppe1327Fill"
          style={{
            fill: props.colors.Fingerkuppe1327Fill,
            stroke: props.colors.Fingerkuppe1327Fill,
            opacity: props.colors.Fingerkuppe1327Fill,
          }}
          d="M29.7,31.8c12-9.1,22.3-10.2,30.6-2.7c-0.6-7.9-2.3-13.5-7.8-13.9
		c-3.8-1.3-8.5-0.6-14,2.6C33.5,21,30.7,25.8,29.7,31.8z"
        />
      </g>
      <g id="linkerkleiner_Finger">
        <g id="fingerdetails">
          <path
            id="middlehand2"
            class="KleinerFinger1327_links_st4"
            d="M105.3,455.1c5.6,2.2,10.1,6.8,11.8,18.6"
          />
          <path
            id="middlehand1"
            class="KleinerFinger1327_links_st4"
            d="M53.5,436c2.3,9.9,0.8,16.3-3.5,20c-3.9,4.5-6.8,9.1-6.3,14.5"
          />
          <path
            id="middlehand"
            class="KleinerFinger1327_links_st5"
            d="M38.2,470.6c0-7,1.5-12.3,3.3-23.6c4.2-17.3,12.8-25.6,24.6-27.3c5.6-1,11.1-1.6,16.1-1
			c8,0.9,14.8,6.3,17.6,13.9c4.9,13.2,6.6,24.2,6.3,39.7"
          />
          <path
            id="basejoint"
            class="KleinerFinger1327_links_st4"
            d="M45.5,417.1c1.4,0.5,3.2,0.9,5.2,1.2c8.3,1.4,16.8,0.5,24.7-2.4c4.8-1.8,10-2.9,15.7-3.2"
          />
          <path
            id="basephalanx"
            class="KleinerFinger1327_links_st5"
            d="M49.5,414.1c-9.3,8.1-15.3,6.5-19.4,0c-9.2-7.1-7-16.4,0-26.7c8.9-22.6,6.5-57.8,0.2-97.7
			c-0.1-0.7-0.1-1.4-0.1-2.1c0.6-9.8-0.5-20-4-30.9c-0.5-1.4-1.4-2.7-2.5-3.7c-2.3-2-3.2-5.4-2.7-10.3c0.2-1.6,0.2-3.2,0-4.8
			c-1.9-16.1,1.2-20.7,6.1-21.5l11.1,1.1c2.9,0.3,5.9-0.1,8.6-1.3c8.6-3.5,16.5-5.1,22.5-2.5c1.3,0.6,2.4,1.7,3.1,3
			c2.2,4.5,3.1,9.1,2.5,13.6c-0.2,1.7,0.3,3.4,1.3,4.8c2.3,2.9,3.1,6.1,0.9,9.5c-2.3,6.1-2.3,12.9-0.5,20.2c0.3,1.1,0.5,2.3,0.6,3.4
			c0.4,6.8,0.9,15.3,1.4,25.3c1.9,41.1,8.8,73.9,24.6,92.4c4.2,8.1,3.9,15.2,0,21.6c0.1,8.2-3.3,8.8-7.9,6.7
			C78.3,408.4,62.8,407.6,49.5,414.1L49.5,414.1L49.5,414.1L49.5,414.1z"
          />
          <path
            id="middlephalanx"
            class="KleinerFinger1327_links_st5"
            d="M15,194.8c5-9.3,7.3-20.9,7.9-34.1c0.1-2.9,0.6-5.9,1.2-8.8c3.7-16.2,2.8-28.2-3.1-35.5
			c-1.7-2.1-2.3-4.8-2-7.4c0.4-3.5,0.9-6.2,1.8-8c0.6-1.1,1-2.2,1.1-3.4c0.7-7.6,3.1-10.1,13.2-8.1c4,1.3,8.1,0.6,12.6-0.6
			c8.1-4,13.4-1,12.8,6.7c-0.2,1.9,0.4,3.7,1.5,5.2c3.8,4.8,4,9.1,1,15.3c-1,2-1.3,4.3-1,6.5c0.2,19.7,3.4,40.5,9,62.1
			c0.6,2.1,1.6,4.2,3.1,5.8c3.3,3.6,2.9,8.3-0.3,13.8c-0.9,1.6-2.6,2.4-4.4,2.3c-6.6-0.5-15.3,2-24.5,5.1c-1.3,0.4-2.6,0.4-3.9,0
			c-7.5-2.5-14.6-3.9-20.8-3.6c-4.1-0.9-5.6-4.4-6.2-8.9C14,197.7,14.3,196.1,15,194.8L15,194.8L15,194.8L15,194.8z"
          />
          <path
            id="basephalanx1"
            class="KleinerFinger1327_links_st4"
            d="M31.9,389c4.5-4,8.1-13.5,10.5-31.2l4.1-45.2c-2-14-2.7-26.5,0.2-34.8
			c0.4-1,0.5-2.1,0.6-3.3c0.2-14-2.3-22.4-7.7-24.9"
          />
          <path
            id="basephalanx2"
            class="KleinerFinger1327_links_st4"
            d="M90,368.5c-8.2-13.2-14.6-25.8-15.6-36.8c-1.2-7.4-3.4-14.3-6.4-20.8
			c-0.3-0.8-0.6-1.6-0.6-2.5c-0.4-11.8-1.7-21.6-4.8-27.7c-1.4-2.8-2-5.8-2-8.9c0-8.3-0.8-13.9-2.2-17.4"
          />
          <path
            id="basephalanx3"
            class="KleinerFinger1327_links_st4"
            d="M21.6,239.3c3.2-0.2,4.7-5.5,4.6-15.8"
          />
          <path
            id="basephalanx4"
            class="KleinerFinger1327_links_st4"
            d="M74.8,231.4c-3.1,0.1-5.3-3.8-6.8-10.4"
          />
          <path
            id="middlephalanx1"
            class="KleinerFinger1327_links_st4"
            d="M22.3,203c5.1-1.2,10.4-0.5,15.9,2c1.5,0.7,3.2,1.1,4.9,1.2c2.3,0.1,4.6-0.5,6.6-1.9
			c4.2-2.5,10.4-3.3,17.4-3.6"
          />
          <path
            id="middlephalanx2"
            class="KleinerFinger1327_links_st4"
            d="M18.4,201.7c0.9-5.2,2.4-9.8,4.6-13.6c4.4-9.9,8.3-21.1,11.6-33.6
			c0.5-6.4,2.7-13.4,5.6-20.9c1.6-11.3,0.4-19.9-3.9-25.5"
          />
          <path
            id="middlephalanx3"
            class="KleinerFinger1327_links_st4"
            d="M53,104.6c-0.9,2-1.5,4.1-1.9,6.3c1,7.9,1.4,15.6,1.1,22.9c-0.1,2.9,0.1,5.8,0.6,8.6
			c3.1,18.1,8.4,35.5,16.8,51.9"
          />
          <path
            id="endphalanx1"
            class="KleinerFinger1327_links_st4"
            d="M24,77c-0.4-2.3,1.4-4.9,4.9-7.8c4.4-5.9,7.8-12.1,9.1-19.2c0.3-1.9,0.5-3.8,0.5-5.7
			C38.7,37.4,40,33,43.5,33c4-0.6,5.8,2.3,5.3,9c-0.3,3.9-0.4,7.8-0.2,11.7c0.6,10,2.9,15,7.2,14.6"
          />
          <path
            id="endphalanx"
            class="KleinerFinger1327_links_st5"
            d="M21.8,83c-3.2-1.8-4.1-4.3-3.1-7.3C26.4,67.3,31.4,58,31.4,46c0-1-0.2-2.1-0.7-3
			c-1.2-2.3-1.3-6-1-10.2c-0.5-5.6,3.2-10.7,9.5-15.5c6.2-4.4,16.5-2.3,18.3,0.5c3.8,9.4,3.8,17.4,0,23.9
			c-1.5-0.7-5.4,18.8,4.2,27.5c3.3,2.9,3.8,5.7,0.5,8.5c-0.5,4.9-4.4,6.2-9.9,5.9c-5.1,2.5-10.2,3-15.4,1.7
			C27.3,83.3,25,87.8,21.8,83C21.8,83,21.8,83,21.8,83L21.8,83z"
          />
          <g id="softpart">
            <path
              id="softpart2"
              class="KleinerFinger1327_links_st6"
              d="M11.1,332.4c0.1-3.3-0.1-7.1-0.9-11.5C3.7,271.2-0.1,224,0.6,180.5
				c2.1-59.3,3.8-122.9,9.7-147.6C15.7,12.5,27.5,1.2,47.2,1c17.7-2.2,29.6,3,31.8,23.7l3.3,98.1c1,30.1,4.1,59.9,10,89.6
				c4.5,28,7.6,57.8,5.5,94.2c1.1,21.9,5.7,35.1,16.7,32.8c10.3-1.2,17.6-32.2,22.6-84.4"
            />
            <path
              id="softpart1"
              class="KleinerFinger1327_links_st4"
              d="M137.7,249.1c0.2-2,0.4-4,0.5-6"
            />
          </g>
          <path
            id="offpart2"
            class="KleinerFinger1327_links_st7"
            d="M147.9,359.1c-2.4,16.3-5.9,30.9-11,42.5c-0.9,2-2.3,3.8-4,5.1l-2.5,1.9
			c-1.3,1-2.1,2.5-2.3,4.1c-1.1,8.6-1.5,16.9-0.3,24.5c0.3,2,1.5,3.7,3,4.9c1.9,1.5,3.2,3.3,3.8,5.4c0.6,1.9,2.3,3.2,4.2,3.5
			c5.1,0.8,10.4-0.7,15.7-3.9"
          />
          <path
            id="Offpart1"
            class="KleinerFinger1327_links_st7"
            d="M153.7,455.1c-10.4,3.4-17.7,9.9-22.6,18.6"
          />
        </g>
      </g>
    </svg>
  );
}

export default Linker5FingerDpSitzend1327;
