import style from "./style.scss";

function Rechter4FingerDpOblSitzend1374(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223.9 486.09">

  <g id="Backround">
    <path id="backround1" class="rechter_4Finger_dp_obl_sitzend1374_cls5" d="M223.4,485.58c.17-60.72.33-121.44.5-182.16-2.25-7.27-5.64-18.2-9.81-31.55-3.06-9.78-5.86-18.68-7.28-23.19-8.14-25.75-15.26-46.88-17.53-53.66-6.45-19.25-16.87-51.33-34.41-107.87-6.22-12.76-15.58-28.79-29.6-45.21-11.06-12.96-22.22-22.91-31.82-30.34-2.51-1.43-6.42-3.33-11.49-4.53-3.42-.81-5.78-.92-10.79-1.35-5.25-.45,5.16.34-15.37-1.62C39.47,2.53,19.96.8,16.69.51c-1.58-.02-3.77.15-6.2,1.02C5.21,3.43,2.35,7.36,1.32,8.95c-.35.82-.85,2.29-.81,4.18.05,2.31.89,4.02,1.36,4.83,2.99,3.15,7.28,7.37,12.83,11.89,8.57,6.99,15.93,11.27,21.41,14.43,4.87,2.8,11.33,6.24,19.21,9.68,3.6,9.62,7.16,17.79,10.19,24.3,3.82,8.2,7.63,16.31,13.77,26.25,5.32,8.61,10.54,15.59,14.85,20.89-.3,6.18-.62,14.6-.71,24.59-.27,28.04,1.38,48.53,1.75,52.99.67,8.06,1.74,18.52,3.51,30.74,1.16,9.1,2.37,16.5,3.29,21.68.8,4.48,2.01,10.61,4.4,22.68,1.17,5.93,2.12,10.67,2.28,11.46,4.36,21.69,22.17,93.79,26.72,112.85,4.62,19.4,10.75,47.9,16.02,83.7"/>
  </g>
  <g id="_4Finger" data-name="4Finger">
    <path id="partoffinger4" class="rechter_4Finger_dp_obl_sitzend1374_cls6" d="M54.21,38.16h.02c6.19,1.94,15.86,1.59,26.08.9,7.72-9.73,9.64-19.13,1.43-23.35l-18.49.96c-11.98-4.42-23.69-7.2-35.07-8.08-1.99-.16-3.75,1.36-3.79,3.35-.13,5.94,3.26,10.05,9.69,12.46,10.14,0,15.38,3.04,16.04,8.82.26,2.3,1.88,4.25,4.09,4.94h0Z"/>
    <path id="partoffinger3" class="rechter_4Finger_dp_obl_sitzend1374_cls6" d="M63.71,25.93h0c-6.93,7.25-3.59,13.38-.48,19.44,4.47,1.28,8.85,2.39,13.15,3.43,6.85,9.32,10.84,25.34,14.58,41.83-4.14,10.76.16,15.94,8.29,18.49,20.02,6.89,36.81,7.1,41.77-17.4.82-4.09-.5-8.32-3.5-11.21-17.07-16.47-31.22-32.42-36.34-46.9-1.18-3.34-3.89-5.91-7.3-6.87-11.78-3.33-23.26-6.15-30.17-.82h0Z"/>
    <path id="partoffinger2" class="rechter_4Finger_dp_obl_sitzend1374_cls6" d="M111.92,138.26h.08c1.61,15.06,3.99,30.12,7.19,45.21,1.56,7.33,3.86,14.47,6.35,21.53,4.76,13.47,3.63,30.38-1.43,49.51-2.63,21.27,4.62,30.11,19.92,28.68,14.58-4.86,18.97-18.96,45.26-14.1,7.33-4.39,7.49-9.72,4.38-15.54-13.78-21.52-22.31-40.96-24.3-57.93-5.9-28.84-11.79-57.05-17.05-75.85,3.33-17.57-1.32-23.48-7.81-24.3-5.82-1.59-14.58.96-14.58.96-7.76,4.04-12.96,4.75-17.58,4.7-7.33-.07-13.69,5.02-14.11,12.71-.42,7.69,3.72,16.88,13.68,24.42h0Z"/>
    <path id="partoffinger1" class="rechter_4Finger_dp_obl_sitzend1374_cls6" d="M189.27,269.09h0c-10.12-1.75-20.32-2.07-31.71,7.33,1.2-.24,3.75,0,8.37.72,5.74-2.07,11.16-2.95,16.02-2.55,3.82-.8,5.74-2.95,7.33-5.5h0Z"/>
    <path id="outline2" class="rechter_4Finger_dp_obl_sitzend1374_cls7" d="M218.04,458.47c-2.03-45.75-1.52-62.49-1.99-95.33-.06-3.86.43-7.72,1.46-11.44,4.33-15.69,7.19-29.14,5.3-35.26-.75-2.45-1.47-4.92-1.85-7.46-1.16-7.75-5.27-15.04-14.22-21.39-22.95-17.45-53.46-21.91-61.75,22.39h-.16v-.08s-.48,13.62-.48,13.62c-.96,3.9,21.43,18.48,26.29,53.54,3.51,23.66,8.98,49.48,11.2,81.53"/>
    <path id="outline1" class="rechter_4Finger_dp_obl_sitzend1374_cls6" d="M214.5,273.32l-59.63-186.18c-14.26-28.48-32.35-50.63-52.59-69.25-7.09-6.53-16.15-10.53-25.75-11.5L19.02.6C13.56.05,8.08,1.73,4.12,5.62.16,9.51-.49,13.63,1.88,17.95c12.07,13.28,30.12,25.23,53.44,36,10.69,26.83,22.42,52.25,38.81,71.44-2.96,55.54,2.57,112.2,15.75,169.87"/>
  </g>
  <g id="Highlights">
    <g id="Fingerhighlights" class="rechter_4Finger_dp_obl_sitzend1374_cls1">
      <path id="fingerhighlight4" class="FullFinger1374Fill" style= {{
              fill: props.colors.FullFinger1374Fill,
              stroke: props.colors.FullFinger1374Fill,
            }} d="M54.21,38.21h.02c6.19,1.94,15.86,1.59,26.08.9,7.72-9.73,9.64-19.13,1.43-23.35l-18.49.96c-11.98-4.42-23.69-7.2-35.07-8.08-1.99-.16-3.75,1.36-3.79,3.35-.13,5.94,3.26,10.05,9.69,12.46,10.14,0,15.38,3.04,16.04,8.82.26,2.3,1.88,4.25,4.09,4.94h0Z"/>
      <path id="fingerhighlight3" class="FullFinger1374Fill" style= {{
              fill: props.colors.FullFinger1374Fill,
              stroke: props.colors.FullFinger1374Fill,
            }} d="M63.71,25.98h0c-6.93,7.25-3.59,13.38-.48,19.44,4.47,1.28,8.85,2.39,13.15,3.43,6.85,9.32,10.84,25.34,14.58,41.83-4.14,10.76.16,15.94,8.29,18.49,20.02,6.89,36.81,7.1,41.77-17.4.82-4.09-.5-8.32-3.5-11.21-17.07-16.47-31.22-32.42-36.34-46.9-1.18-3.34-3.89-5.91-7.3-6.87-11.78-3.33-23.26-6.15-30.17-.82h0Z"/>
      <path id="fingerhighlight2" class="FullFinger1374Fill" style= {{
              fill: props.colors.FullFinger1374Fill,
              stroke: props.colors.FullFinger1374Fill,
            }} d="M111.92,138.31h.08c1.61,15.06,3.99,30.12,7.19,45.21,1.56,7.33,3.86,14.47,6.35,21.53,4.76,13.47,3.63,30.38-1.43,49.51-2.63,21.27,4.62,30.11,19.92,28.68,14.58-4.86,18.97-18.96,45.26-14.1,7.33-4.39,7.49-9.72,4.38-15.54-13.78-21.52-22.31-40.96-24.3-57.93-5.9-28.84-11.79-57.05-17.05-75.85,3.33-17.57-1.32-23.48-7.81-24.3-5.82-1.59-14.58.96-14.58.96-7.76,4.04-12.96,4.75-17.58,4.7-7.33-.07-13.69,5.02-14.11,12.71-.42,7.69,3.72,16.88,13.68,24.42h0Z"/>
      <path id="fingerhighlight1" class="FullFinger1374Fill" style= {{
              fill: props.colors.FullFinger1374Fill,
              stroke: props.colors.FullFinger1374Fill,
            }} d="M189.27,269.14h0c-10.12-1.75-20.32-2.07-31.71,7.33,1.2-.24,3.75,0,8.37.72,5.74-2.07,11.16-2.95,16.02-2.55,3.82-.8,5.74-2.95,7.33-5.5h0Z"/>
    </g>
    <path id="highlightmetacarpophalangealjoint" class="Grundgelenk1374Fill" style= {{
              fill: props.colors.Grundgelenk1374Fill,
              stroke: props.colors.Grundgelenk1374Fill,
            }} d="M139.35,281.84h.02c-2.05,1.51-5.03,3.88-4.99,7.01.03,2.78,2.43,5.03,3.39,5.94.87.82,2.82,2.65,5.39,2.97,3.15.38,5.15-1.77,10.09-5.4,2.06-1.51,7.09-5.17,12.56-7.82,3.13-1.51,7.2-3.48,12.79-4.09,7.34-.81,14.22,1.05,14.72,1.19,4.99,1.39,7.33,3.16,9.97,1.82,1.5-.76,2.35-2.21,4.04-5.12,1.96-3.37,2.95-5.05,2.7-6.47-.95-5.4-18.26-6.41-19.42-6.47-11.83-.62-20.61,2.13-29.66,4.96,0,0-10.45,3.27-21.6,11.48h0Z"/>
    <path id="highlightfingertip" class="Fingerkuppe1374Fill" style= {{
              fill: props.colors.Fingerkuppe1374Fill,
              stroke: props.colors.Fingerkuppe1374Fill,
            }} d="M41.89,10.5h.02c.75.15,1.49.3,2.1.42.35.27,5.25,4.15,4.46,9.21-.4,2.56-2.28,5.65-4.38,5.78-.94.06-1.08-.54-2.97-1.12-1.41-.43-1.46-.14-4.99-.53-1.71-.19-3.59-.4-5.14-1.33-.14-.09-.48-.3-.86-.56-.67-.45-1.45-1.03-2.28-1.75-1-1-2.34-2.66-3.01-5-.14-.52-.24-1.02-.31-1.49-.18-.79-.18-1.44-.15-1.88.05-.61.1-1.33.56-2.07.36-.57.8-.91,1.09-1.1.29-.14.73-.32,1.29-.42.79-.15,1.4-.07,2.02,0,2.91.33-1.71-.38,2.88.27,3.95.56,5.93.81,7.58,1.14.68.14,1.42.29,2.09.43h0Z"/>
  </g>
</svg>
  );
}

export default Rechter4FingerDpOblSitzend1374;
