import style from "./style.scss";

export default function LinkesHuftgelenkTEPvdLiegend2412(props) {
  return (
    <svg
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 216.2 331.4"
    >
      <g id="BG">
        <path
          id="bg"
          class="TEP_Hufte_links2412_cls6"
          d="M210.6,106.8c-3.3-6.7-7.7-11.8-15.1-12.6l-15.8,3.5c-6.2-5.3-12.2-9.1-17.4-9.2l-7.9,10.5-10.8,7.1-13.5-5.8-.7-20.9,5-29.6c.6.9,5.6-3.5,7.7-7.7,2.1-4.3-3.5-7.1-3.9-13.5-.2-3,.9-4.5,8.7-16.4,1.2-1.8,2.3-3.5,3.2-4.9L70.4,0c-.5,3.4-1.7,8.1-4.7,13-2.3,3.8-5.9,9.6-10.3,9.5-4.1,0-5.3-5.2-9.7-4.8-2.7.2-5,2.4-6.1,4.5-1.4,2.7-.9,5.3-.7,6.2,1,4,3.9,3.9,5.1,7.3,1.5,4-1.9,5.6-2.2,12.9-.2,5.2,1.5,5.8.7,9.4-.8,3.9-3.4,6.1-7.3,9.9-9.1,8.8-6.4,7.8-13,13.7-4.9,4.4-12.3,12-22.2,15.5l25.3,78.4c10.2-.7,19.8-6.7,27.8-11.7,9-5.6,19.8-12.3,25.1-25.1,5-12,5.9-13.5,7.7-24.2l1.7-8.4,19.8,3.3,3.9,11.9c.6,5.4-.6,10.8-3.9,16.2-13.9,21-13.7,34.4-1.3,40.9,4.4,32.8,7.1,138.6,4.1,151.8-.1.5-.2,1-.3,1.5,16.4-1.1,32.8-.5,49.3.1,6.8,0,13.6.1,20.5.1,5-.5,10-.7,14.8-.6-6.4-42.5-10.5-82.4-6.6-113.7,2.7-24,7.6-45.1,18.3-58.1,11.4-19,12-36.4,4.2-52.7Z"
        />
      </g>
      <g id="Underlays">
        <path
          id="Femur"
          class="Schenkel2412Fill"
          style={{
            fill: props.colors.Schenkel2412Fill,
            stroke: props.colors.Schenkel2412Fill,
          }}
          d="M136.3,101.2c2,.5,6.2,1.4,11.4,0,1.6-.4,5.9-1.7,9.5-5.5,3.4-3.6,2.5-5.7,4.8-6.6,3.7-1.4,9.7,2.7,13.9,5.5,1.6,1.1,2.6,1.9,4.4,2.2,2.9.4,4.7-1.2,7.7-2.2,0,0,4.9-1.7,10.3-.4,7.5,1.8,11.4,9.6,13.2,13.2,2.5,5,3.1,9.2,3.7,13.6.8,5.8,2.2,16.8-2.6,28.2-3,7.1-5.2,6.7-10.3,15.4-1.2,2.1-5.5,9.5-9.2,24.2-6.1,24.3-5.6,48.4-5.1,67,.2,5.9.6,24.8,2.9,41.4,1.1,7.7,1.4,15.5,2.9,23.1,1.4,7.1,2.1,8.4,1.1,10.3-3.3,6.1-16-.3-41.4,1.5-10.8.8-21.8-.4-32.6.7-2,.2-8.4.9-10.3-2.2-.7-1.2-.4-2.5,0-5.9.4-3.3.3-6.6.7-9.9,1.1-9,.5-32.9.4-39.9-.4-14.4-.9-24.7-1.8-44.3-1.3-26.1-1.4-22-1.5-27.1,0-5.9-1.7-11.7-1.1-17.6.2-1.6.6-4.5-1.1-7-1.5-2.3-3.5-2.2-5.5-4-2.9-2.6-3.3-6.5-3.7-10.6-.8-8.7,2.9-15.3,4-17.2,1.3-2.1,1.2-1.4,4-5.5,3.7-5.4,5.5-8.1,6.2-11.7.7-3.4.2-6.2-.4-9.2-1.1-6.2-2.9-7-2.9-9.9,0-8.7,16.9-19.3,24.5-14.7,1.3.8,2.7,2.3,3.7,5.1Z"
        />
        <path
          id="Hipjoint"
          class="Hipjoint2412Fill"
          style={{
            fill: props.colors.Hipjoint2412Fill,
            stroke: props.colors.Hipjoint2412Fill,
          }}
          d="M85,116.6c-2.6,1-6.7-3.6-8.8-7-2.2-3.5-3-7.3-3.3-12.5-.3-5.7-.8-15,4-26,1.9-4.2,5.7-12.8,15-19.4,2.5-1.8,8.8-6.2,17.9-7.3,0,0,7.8-1,17.9,1.8,2.3.7,2.6,1.1,5.4,1.8,1.5.4,2.4.5,2.6,1.1.4.9-.6,2.5-1.8,2.9-1.5.5-2.9-1-4.8-2.2-2.6-1.6-5-1.8-9.5-2.2-6.3-.5-11.1,1-13.6,1.8-10.4,3.4-16.6,10.4-18.7,12.8-1,1.1-8.7,10.3-10.6,23.8-.7,5.1-.3,8.9,0,11,.8,4.9,1.5,9,4.8,11.7,2.3,1.9,4.7,2.1,5.1,4,.3,1.4-.6,3.2-1.8,3.7Z"
        />
        <path
          id="Tminor"
          class="TrochanterMinor2412Fill"
          style={{
            fill: props.colors.TrochanterMinor2412Fill,
            stroke: props.colors.TrochanterMinor2412Fill,
          }}
          d="M106.3,139.3h0c-2.6,3.4-9,12.5-8.9,23.5,0,3,.1,7.2,3,11,2,2.6,4.5,4,6.1,4.6.8-6.1,1.6-12.3,3.9-35.3.2-2.3-1.8-4.2-4-3.8Z"
        />
        <path
          id="Tmajor"
          class="TrochanterMajor2412Fill"
          style={{
            fill: props.colors.TrochanterMajor2412Fill,
            stroke: props.colors.TrochanterMajor2412Fill,
          }}
          d="M215.8,128.5c0,19.5-9.4,35.3-21.1,35.3s-13.7-17.1-13.7-36.6,2.1-34.1,13.7-34.1,21.1,15.8,21.1,35.3Z"
        />
      </g>
      <g id="Hip">
        <path
          id="Hip11"
          class="TEP_Hufte_links2412_cls1"
          d="M121.1,64c-.3-2.5,19.7-17.4,21.2-22,1.6-4.5-3.5-7.2-3.9-13.5-.2-3,.9-4.5,8.7-16.4,1.1-1.6,2-3.1,2.9-4.4"
        />
        <path
          id="Hip10"
          class="TEP_Hufte_links2412_cls1"
          d="M25.3,175.5c10.3-.6,20-6.6,28.1-11.7,9-5.6,19.8-12.3,25.1-25.1,2.2-5.3,5.8-14.2,7.7-24.2,1.6-8.4,1.3-12.5,4.8-17.6,3.8-5.6,7.9-6.5,13.5-10.6,5-3.7,11.4-10.2,16.4-22.2"
        />
        <path
          id="Hip9"
          class="TEP_Hufte_links2412_cls1"
          d="M70.7,0c0,3-2.2,7.6-4.7,13-10.9,23.6-7,19-12.5,29-12.5,22.5-27.1,36.2-30.9,39.6-9.3,8.5-14,12.8-22.2,15.5"
        />
        <path
          id="Hip8"
          class="TEP_Hufte_links2412_cls1"
          d="M15.9,143.7c1.5.3,6.2.6,7.6.5,7.6-.6,12.2-8.1,15.5-13.5,2.2-3.6,6.5-11.8,8.7-36.7.5-6.2,1.1-15.2,1-26.1"
        />
        <path
          id="Hip7"
          class="TEP_Hufte_links2412_cls1"
          d="M62.2,107.5c-10.1.4-18.3,1.7-24.2,2.9-14,2.9-25.7,9.3-28.6,11.6"
        />
        <path
          id="Hip6"
          class="TEP_Hufte_links2412_cls1"
          d="M142,42c-4.2-2.5-11-5.7-20-6.9-13.8-1.9-24.5,2.3-30.9,4.8-6.2,2.5-14.1,5.6-21.3,13.5-1.7,1.8-7.6,8.6-10.6,19.3-3.1,10.9-2.3,22.1-1,22.2,1.4.2-20.3,15.5,12.6-65.7"
        />
        <path
          id="Hip5"
          class="TEP_Hufte_links2412_cls1"
          d="M41.6,59.9c0,7.5,2.8,8,7,8"
        />
        <path
          id="Hip4"
          class="TEP_Hufte_links2412_cls1"
          d="M65.7,13c-4.5,8.1-8,9.6-10.3,9.5-4.1-.1-5.7-5.2-9.7-4.8-3.3.3-6.4,4.1-6.8,7.7-.4,4.4,3.7,5.6,4.8,11.6.7,3.8-.3,6.9-1.9,11.6-1,3.1,3.5,6.4-.3,11.3,10.3-13,18.2-28.8,24.1-46.9h0s0,0,0,0Z"
        />
        <path
          id="Hip3"
          class="TEP_Hufte_links2412_cls1"
          d="M102.3,94.4c1.8,6.7,4.3,13.1,7,19.5,2.4,5.6,2.5,12.2,1.1,17.5-2,7.3-5.8,7.5-9.3,15-1.9,4.1-3.6,9.9-3.8,15.6-.2,8.9,3.1,14,8.9,16.4,4.4,35.5,7.3,110.3,4.1,151.8"
        />
        <path
          id="Hip2"
          class="TEP_Hufte_links2412_cls1"
          d="M194.6,331.3c-8.1-55.9-12.1-108.1-.6-145.5.9-3.9,2.1-8.9,5.4-15.7,5.6-11.5,8.7-10.5,12.6-20.2.9-2.3,5.5-14.3,2.9-29.8-1.6-9.7-4.8-14.7-8.5-20.3"
        />
        <path
          id="Hip1"
          class="TEP_Hufte_links2412_cls1"
          d="M205.3,143.9c-1.2-3.8-2.4-9.3-1.2-15.5,1.8-10,7.8-12.4,7.1-19.4-.7-7-7.7-13.4-14.3-14.7-3.4-.7-7.1.3-14.6,2.2-6.7,1.7-8.8,3.1-12.7,2.3-.7-.1-7.7-.8-10.6-4.8,1.8-2.6,20.5,3.2,20.6,3.2,0,0-16.4-12.9-19.1-7.5-.5.9-.9,1.9-1.4,2.8l-3.1,5.3"
        />
      </g>
      <g id="TEP">
        <path
          id="TEP2"
          class="TEP_Hufte_links2412_cls2"
          d="M81.2,132c-3,.2,0,0,0,0-.2.1-.6,0-.8,0-15.6-4-22.8-11-25-23.8-.7-3.8.2-7.8,2.3-11.1,4.2-6.7,6-14.5,6.8-22.9-1.3-9.7,2.2-18.3,11.4-25.5,17.8-8.4,35.1-14.5,50.8-13l14.9,5.1c.9,2.5.4,4.4-1.1,5.9-2.1,2-5.2,2.4-7.9,1.3-15.4-6.4-30.5-4.3-45.4,8-15.3,16.8-23.2,49.5-1.1,60.7,6,3,2,14.8-4.7,15.2h0Z"
        />
        <path
          id="TEP1"
          class="TEP_Hufte_links2412_cls2"
          d="M123.9,47.4c-2.7-.4-5.5-.5-8.4-.3-5.7.4-11.1,2.4-15.9,5.3-11.7,7-18.9,16.4-22.3,27.6,0,.2-.2.5-.2.8-3.7,18.4,1.5,27.9,11.1,33,1,.5,1.9,1.2,2.8,1.9,6,4.5,16.8-5.5,16.8-5.5,3.2,10.4,6.4,19.7,9.9,27,.4,4.9.6,9.9-.9,14.8l9.4,154.7c.1,2,1.1,3.9,2.8,5,3,2.1,6,2,9.1-1.1,1.1-1.1,1.7-2.5,1.9-4l18.9-207.1c.1-1.5-1.3-2.7-2.8-2.2-3.3,3.7-8.2,5.3-14.6,5-9.1.3-11.7-14.3-7.7-18.2,11.6-8.2,13.1-17.8,9-24,0,0,0,0,0-.1-4-6.8-10.8-11.5-18.7-12.7h0Z"
        />
      </g>
    </svg>
  );
}
