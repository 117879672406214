import style from "./style.scss";

export default function RechterOberschenkelMitKnieLatLiegend2345(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 651.8 904.5"
    >
      <g id="fullknee">
        <g id="knee_with_upper_leg" data-name="knee with upper leg">
          <g id="background_layer" data-name="background layer">
            <path
              id="lower_leg_background"
              data-name="lower leg background"
              class="oberschenkel_mit_knie_lat_left2345_cls7"
              d="M377,769.2l-7.8-23.2-2.6,26.1,17.3,120.8-58.3,3.5c7.5-27.8,9.6-59.2,8.1-93.1-1.6-40-9.3-68.1-22.3-86-10.9-16.1-15.7-38.5-15.1-66.4v-21.8l7.9-13.7,19.5-24.4c1,1.1,2.5,1.5,4,.4,1.7-1.4,3.4-2.8,5.1-4.2,2.7-1.9,5.4-3.8,8.2-5.5.8.3,1.8.3,2.7-.3,11.2-6.9,23.7-7.4,36.4-7.4.2.5.6,1,1,1.5,1.7,1.6,4.2,2.5,6.4,2.6,1.5,0,2.9-.8,3.3-2.3,5.9-.2,12.4-2.9,19.3-6.9,20.3-4.6,37.1-.3,49.6,15.3l9.1,19.9c5.9,5.7,8.2,12.6,5.8,20.7-3.8,9.5-5.4,17.3,1.4,18.6,6.4-1,11,6.4,14.6,18.9,10.1,7.4,14.5,17.3,13.1,29.7v30.7c-9.2,39.1.4,97.8,14.2,160.9l-109.3,19.2-31.5-133.4h0Z"
            />
            <path
              id="upper_leg_background"
              data-name="upper leg background"
              class="oberschenkel_mit_knie_lat_left2345_cls7"
              d="M268.8,7c1,2.6,41.4,337.5,50.8,373.9,12.2,47.7,9.2,62.2,3.9,72.2-8.7,16.2-18.5,27.2-18.5,27.2-5.8,6.6-9.2,9-12.5,15.6-5.8,11.2-5.2,22.1-5,25.2,1.1,14.6,8.9,24.4,15.1,32.1,10.8,13.5,23,19.6,26.2,21.1,9.5,4.5,17.6,5.8,22.8,6.6,3.9.6,20.3,2.9,41.8-2.5,10.5-2.6,43.8-11.6,67.9-43.7,15.2-20.3,19.3-40.1,20.3-45.6,1.5-7.9,6-34.3-6.5-60.2-1.2-2.5-6.7-10.3-17.7-26-17-24-20-27.5-25.2-35.5-11.2-16.9-55.9-335.5-55.9-360.5"
            />
            <path
              id="patella_background"
              data-name="patella background"
              class="oberschenkel_mit_knie_lat_left2345_cls7"
              d="M536.7,500.7h0c-1.6-5.2-3-5.2-9.5-15.8-11.6-18.8-12.5-27.9-19.8-29.3-4.9-.9-9.3,2.3-9.7,2.7-2,1.6-4.1,4.1-6.3,15-2,9.5-1.2,11.5-2.7,19.5-1.5,7.9-3.8,13.8-4.9,17-4.2,10.9-7.2,13.5-9,22.7-.6,3.1-1.2,6.1-1,10,.6,9.1,4.8,11.9,12.3,26.6,5.6,11,5.4,13.7,8.3,14.5,7.1,2,16.6-11,22.6-19.3,2.4-3.2,11.2-15.8,16.9-35.5,3.3-11.3,5.5-18.9,2.8-28h0Z"
            />
          </g>
          <g id="lower_leg" data-name="lower leg">
            <path
              id="fibula"
              class="oberschenkel_mit_knie_lat_left2345_cls3"
              d="M325.3,897.9c3.2-11.5,6.6-26.7,8.2-44.7,1.6-17.3.9-30.1,0-47.4-.8-15.9-1.6-29.2-5.5-46.5-2.4-10.6-4.4-19.5-10-30.1-5.6-10.6-8-10.3-12.8-20-4.8-9.8-5.9-17.8-8.2-33.7-3.3-22.7-.5-26.1.9-27.3,1.9-1.7,3.4-1.5,16.4-2.7,18.1-1.7,18.3-2.4,22.8-1.8,11.1,1.4,19,6.8,21,8.2,2.7,1.9,14.2,10.7,17.3,25.5,1.3,6.1.2,8.8-1.8,23.7-.7,5.2-1.7,15-3.6,34.6-2.1,20.9-3.1,31.4-2.7,41,.2,4,1.3,13,3.6,31,1.7,13.1,1.7,12.4,4.6,33.7,2.5,18.3,3,23.1,4.6,32.8,1.4,8.8,2.7,16.1,3.6,21"
            />
            <path
              id="tibia_2"
              data-name="tibia 2"
              class="oberschenkel_mit_knie_lat_left2345_cls3"
              d="M408,904.1c-6.3-23.5-10.8-42.6-13.7-55.6-3.9-17.7-6.6-31.7-12.8-57.4-6.5-27.5-8.7-33.7-10.9-39.2-8.6-21.2-19.1-35.3-22.8-40.1-3.3-4.3-9.5-12.2-19.1-21-11.6-10.5-15.5-10.8-21.9-19.1-3.1-4-9.9-13.2-10.9-26.4-.5-5.9-2-20.5,6.4-27.3,6.7-5.4,14.5-1.8,19.1-8.2,2.9-4,.6-6.3,2.7-11.9,3.3-8.5,12.1-12.3,23.7-17.3,7.6-3.3,14.4-6.2,23.7-6.4,10.7-.2,11.7,3.6,21,2.7,10.7-1,11.2-6.1,22.8-8.2,12.8-2.4,23.1,1.7,25.5,2.7,3.8,1.6,13.8,5.8,19.1,15.5,2.6,4.7,1.8,6.5,5.5,13.7,4.5,8.7,6.4,8,8.2,12.8,4.8,12.6-6.7,23.9-1.4,29.8,2.5,2.8,5.2.5,9.6,3.9,5,3.9,3,8,8.2,15.5,3.9,5.6,6,4.6,9.1,10.3,2.6,4.6,3.3,9,3.6,11.6.9,6,.3,10.7,0,12.8-1.4,11.2-.6,22.5-1.8,33.7-.5,4.1-1.6,14.6-1.8,26.4,0,2.7-.2,13,1.8,32.8,1.1,10.8,2.6,22.2,5.5,39.2,2.4,14.4,6,33.3,10.9,55.6"
            />
            <path
              id="tibia_1"
              data-name="tibia 1"
              class="oberschenkel_mit_knie_lat_left2345_cls3"
              d="M490,662.6c0,15.6,5.9,28.3,13.2,28.3"
            />
            <polyline
              id="tibia_3"
              data-name="tibia 3"
              class="oberschenkel_mit_knie_lat_left2345_cls3"
              points="366.2 741.8 358.8 717.2 344.2 690.8 324.8 676.7 309.6 665.3 300.5 647.1 296 630.6 302.3 617.9 324.8 590.6"
            />
            <path
              id="tibia_4"
              data-name="tibia 4"
              class="Oberschenkel_mit_Knie_lat_left2345_cls2"
              d="M466.4,647.1c-15.6,29.5,5.1,98.4,23.7,109.4"
            />
            <path
              id="tibia_5"
              data-name="tibia 5"
              class="Oberschenkel_mit_Knie_lat_left2345_cls2"
              d="M485.5,741.8c0-22.1-3.3-40.1-7.3-40.1"
            />
            <path
              id="tibia_6"
              data-name="tibia 6"
              class="Oberschenkel_mit_Knie_lat_left2345_cls2"
              d="M478.2,701.7c0-19.6-3.3-35.5-7.3-35.5"
            />
            <path
              id="tibia_7"
              data-name="tibia 7"
              class="Oberschenkel_mit_Knie_lat_left2345_cls2"
              d="M433.6,576.9c0,13.6-35.5,24.6-79.4,24.6"
            />
          </g>
          <g id="upper_leg" data-name="upper leg">
            <path
              id="tibia_1-2"
              data-name="tibia 1-2"
              class="oberschenkel_mit_knie_lat_left2345_cls3"
              d="M268.8,7c1,2.6,41.4,337.5,50.8,373.9,12.2,47.7,9.2,62.2,3.9,72.2-8.7,16.2-18.5,27.2-18.5,27.2-5.8,6.6-9.2,9-12.5,15.6-5.8,11.2-5.2,22.1-5,25.2,1.1,14.6,8.9,24.4,15.1,32.1,10.8,13.5,23,19.6,26.2,21.1,9.5,4.5,17.6,5.8,22.8,6.6,3.9.6,20.3,2.9,41.8-2.5,10.5-2.6,43.8-11.6,67.9-43.7,15.2-20.3,19.3-40.1,20.3-45.6,1.5-7.9,6-34.3-6.5-60.2-1.2-2.5-6.7-10.3-17.7-26-17-24-20-27.5-25.2-35.5-11.2-16.9-55.9-342.5-55.9-367.5"
            />
            <path
              id="tibia_2-2"
              data-name="tibia 2-2"
              class="oberschenkel_mit_knie_lat_left2345_cls3"
              d="M432.2,367.5c1.8,7.7,4.1,26.2,11.3,38.3,7.5,12.4,14.3,13.5,22.2,26.1,3,4.6,10.5,16.6,12,33,1.9,21.1-7.2,37.2-12,45.3-11.2,19.5-26.4,29.8-36.3,36.3-14.9,9.9-27.9,14.5-37.6,17.7-12.3,4.1-40.3,9.2-63.1,10.1"
            />
            <path
              id="partella_1"
              data-name="partella 1"
              class="oberschenkel_mit_knie_lat_left2345_cls3"
              d="M494.5,583.7h0c7,1.9,16.1-10.5,22.6-19.3,10.9-15,15.2-29,16.9-35.5,3.2-12.1,5-19.1,2.8-28.2-2-8.2-4.7-7.4-18-28.5-7.5-11.9-10.3-18-14.9-17.9-4.1.2-7,5-8.9,8.1-3.4,5.7-3.4,10.3-4.9,20.6-1.3,9.4-2,14.1-3.2,18.5-3.2,11.4-6.7,12.1-10.3,24.4-2,7-3.2,11-2.8,16.5.7,7,3.4,8.7,10.2,22.2,7.2,14.5,6.9,18.1,10.4,19h0s0,0,0,0Z"
            />
            <polyline
              id="patella_2"
              data-name="patella 2"
              class="oberschenkel_mit_knie_lat_left2345_cls3"
              points="473.8 542.5 484.9 519.9 493.5 502.1 497.9 483.5 503.8 454.4"
            />
          </g>
        </g>
        <g id="overlays">
          <path
            id="partella_overlay"
            data-name="partella overlay"
            class="Patella2345Fill"
            style={{
              fill: props.colors.Patella2345Fill,
              stroke: props.colors.Patella2345Fill,
            }}
            d="M494.5,584.2h0c7,1.9,16.1-10.5,22.6-19.3,10.9-15,15.2-29,16.9-35.5,3.2-12.1,5-19.1,2.8-28.2-2-8.3-4.7-7.4-18-28.5-7.5-11.9-10.3-18-14.9-17.9-4.1.2-7,5-8.9,8.1-3.4,5.7-3.4,10.3-4.9,20.6-1.3,9.4-2,14.1-3.2,18.5-3.2,11.4-6.7,12.1-10.3,24.4-2,7-3.2,11-2.8,16.5.7,7,3.4,8.7,10.2,22.2,7.2,14.5,6.9,18.1,10.4,19h0Z"
          />
          <path
            id="upper_leg_shaft_overlay"
            data-name="upper leg shaft overlay"
            class="Oberschenkelschaft2345Fill"
            style={{
              fill: props.colors.Oberschenkelschaft2345Fill,
              stroke: props.colors.Oberschenkelschaft2345Fill,
            }}
            d="M432.1,367.5l-107.7,26.5c-12-56-35.3-209.3-55.6-387L376.2,0c12.9,123.3,31.7,245.8,55.9,367.5Z"
          />
          <ellipse
            id="knee_joint_overlay"
            data-name="knee joint overlay"
            class="Kniegelenk2345Fill"
            style={{
              fill: props.colors.Kniegelenk2345Fill,
              stroke: props.colors.Kniegelenk2345Fill,
            }}
            cx="398.2"
            cy="566.9"
            rx="92"
            ry="39"
            transform="translate(-83.6 69.1) rotate(-9)"
          />
        </g>
      </g>
      <g id="outlines">
        <path
          id="outlinepart2"
          class="Oberschenkel_mit_Knie_lat_left2345_cls1"
          d="M34.4,0c43.9,136.9,96.2,253,159.2,383,25.9,100.7,16.7,194.6,1.5,231.1-10.7,51.6-56.8,182.4-47.5,288.5"
        />
        <path
          id="outlinepart1"
          class="Oberschenkel_mit_Knie_lat_left2345_cls1"
          d="M581.4,7.6c-21.6,157.9-48.8,278.5-25.7,364.1,22.1,81.6,44.7,163.7-.2,259.9-36.7,78.6-5.1,219.1-5.5,271.6"
        />
      </g>
      <g id="outlinehighlights">
        <path
          id="outlinehighlight2"
          class="Weichteilmantel2345Fill"
          style={{
            fill: props.colors.Weichteilmantel2345Fill,
            stroke: props.colors.Weichteilmantel2345Fill,
          }}
          d="M33.6-.3c43.9,136.9,96.2,253,159.2,383,25.9,100.7,16.7,194.6,1.5,231.1-10.7,51.6-55.4,185.7-46.1,291.8h-9.8c-9.3-106.2,36.7-237,47.5-288.5,15.3-36.4,24.5-130.4-1.5-231.1C121.3,256,69.1,136.9,25.2,0l8.4-.3Z"
        />
        <path
          id="outlinehighlight1"
          class="Weichteilmantel2345Fill"
          style={{
            fill: props.colors.Weichteilmantel2345Fill,
            stroke: props.colors.Weichteilmantel2345Fill,
          }}
          d="M557.2,904.5c.4-52.5-31.5-195.4,5.2-274.1,44.9-96.2,22.3-178.3.2-259.9-23.1-85.6,4-209.7,25.7-367.5h-6.9c-21.6,157.9-49.6,282.8-26.4,368.4,22.1,81.6,44.7,163.7-.2,259.9-36.7,78.6-5.1,219.1-5.5,271.6l8,1.6Z"
        />
      </g>
    </svg>
  );
}
