import style from "./style.scss";

export default function LinkesSchultergelenkYAufnahmeDvOblStehend2011(props) {
  return (
    <svg version="1.1" viewBox="0 0 249.4 449.4">
      <g id="y-shoulder">
        <g id="background_layer">
          <path
            id="background"
            class="L_Schultergelenk_Y_obl_st0"
            d="M105.1,439.6c6.6-7.5,3.1-15.4,4-45c0.6-21.6,2.5-18.7,5-51c3.1-40.1-0.1-40.7,3-70
			c1.6-14.9,3.7-33.9,11-58c1.5-4.8,7.6-24.8,20-49c5.3-10.4,11.6-18.2,18-30c13.1-24,20.8-21.6,33.1-34c6-6-10.4-6.1-7.1-13
			c1.3-2.6,3.3-4.6,17-12c14.8-8,14.6-6.8,17-9c10.1-9,13.8-28.2,6-38c-3.2-4-7.8-5.3-17-8c-17.1-5-23.1-0.9-31-7
			c-6.2-4.9-4.9-9.4-11-12c-6.1-2.6-8.9,1.3-19,2c-16.2,1.1-24.2-8.1-31-3c-3.6,2.7-2,5.8-6,9c-5.8,4.6-13.7,1.6-22,1
			c-8.1-0.5-15.8,2.6-31,9c0,0-25.7,10.7-51,35c-2.3,2.2-7.3,7.1-10,15c-1.2,3.4-4.1,11.8,0,18c2.3,3.5,5.9,5,11,7
			c9.1,3.6,12.6,1.7,16,5c3.7,3.6,1,7.4,3,16c2.9,12.3,12.3,20.8,18,26c7.2,6.6,9.5,5.9,13,11c5.8,8.4,5.2,18.1,5,22
			c-1.2,21.6,0.6,43.5-2,65c-3.5,29.6-0.1,18.3-5,77c-3.2,39-4.9,58.5-7,68c-6.7,29.8-14.1,39.2-8,49
			C56.9,451.4,92.5,453.9,105.1,439.6z"
          />
        </g>
        <g id="upper_arm_with_shoulder">
          <path
            id="upper_arm_with_shoulder_1"
            class="L_Schultergelenk_Y_obl_st1"
            d="M105.1,439.6c6.6-7.5,3.1-15.4,4-45c0.6-21.6,2.5-18.7,5-51
			c3.1-40.1-0.1-40.7,3-70c1.6-14.9,3.7-33.9,11-58c1.5-4.8,7.6-24.8,20-49c5.3-10.4,9.9-19.3,18-30c11.5-15.2,14.1-18.4,27-28
			c17.5-13-4.3-12.1-1-19c1.3-2.6,3.3-4.6,17-12c14.8-8,14.6-6.8,17-9"
          />
          <path
            id="upper_arm_with_shoulder_2"
            class="L_Schultergelenk_Y_obl_st1"
            d="M232.1,30.6c-3.2-4-7.8-5.3-17-8c-17.1-4.9-23.1-0.9-31-7
			c-6.2-4.9-4.9-9.4-11-12c-6.1-2.6-8.9,1.3-19,2c-16.2,1.1-24.2-8.1-31-3c-3.6,2.7-2,5.8-6,9c-5.8,4.6-13.7,1.6-22,1
			c-8.1-0.5-15.8,2.6-31,9c0,0-25.7,10.7-51,35c-2.3,2.2-7.3,7.1-10,15c-1.2,3.4-4.1,11.8,0,18c2.3,3.5,5.9,5,11,7
			c9.1,3.6,12.6,1.7,16,5c3.7,3.6,1,7.4,3,16c2.9,12.3,12.3,20.8,18,26c7.2,6.6,9.5,5.9,13,11c5.8,8.4,5.2,18.1,5,22
			c-1.2,21.6,0.6,43.5-2,65c-3.5,29.6-0.1,18.3-5,77c-3.2,39-4.9,58.5-7,68c-6.7,29.8-14.1,39.2-8,49"
          />
          <line
            id="upper_arm_with_shoulder_3"
            class="L_Schultergelenk_Y_obl_st2"
            x1="68.4"
            y1="223.1"
            x2="68.6"
            y2="225.6"
          />
          <line
            id="upper_arm_with_shoulder_4"
            class="L_Schultergelenk_Y_obl_st3"
            x1="69"
            y1="230.5"
            x2="83.7"
            y2="416.6"
          />
          <line
            id="upper_arm_with_shoulder_5"
            class="L_Schultergelenk_Y_obl_st2"
            x1="83.9"
            y1="419.1"
            x2="84.1"
            y2="421.6"
          />
          <line
            id="upper_arm_with_shoulder_6"
            class="L_Schultergelenk_Y_obl_st4"
            x1="116.6"
            y1="279.1"
            x2="89.1"
            y2="413.6"
          />
          <line
            id="upper_arm_with_shoulder_7"
            class="L_Schultergelenk_Y_obl_st1"
            x1="226.1"
            y1="68.6"
            x2="123.1"
            y2="122.6"
          />
          <line
            id="upper_arm_with_shoulder_8"
            class="L_Schultergelenk_Y_obl_st1"
            x1="76.2"
            y1="87.6"
            x2="116.6"
            y2="117.6"
          />
          <line
            id="upper_arm_with_shoulder_9"
            class="L_Schultergelenk_Y_obl_st1"
            x1="60.1"
            y1="68.6"
            x2="81.1"
            y2="82.6"
          />
          <path
            id="upper_arm_with_shoulder_10"
            class="L_Schultergelenk_Y_obl_st1"
            d="M117.1,11.6c13,14.5,0.7,29,1.5,29"
          />
          <path
            id="upper_arm_with_shoulder_11"
            class="L_Schultergelenk_Y_obl_st1"
            d="M130.1,40.6c12.5-3.6,47.2-1.2,71,10"
          />
          <path
            id="upper_arm_with_shoulder_12"
            class="L_Schultergelenk_Y_obl_st1"
            d="M60.1,68.6c0-15.5,20.1-28,45-28"
          />
          <path
            id="upper_arm_with_shoulder_13"
            class="L_Schultergelenk_Y_obl_st1"
            d="M96.4,59.6c-6.5-3.3-21.5-0.5-31.3,9"
          />
          <path
            id="upper_arm_with_shoulder_14"
            class="L_Schultergelenk_Y_obl_st1"
            d="M110.1,59.6c26.2,6.4,40.6,29.1,44,43"
          />
          <path
            id="upper_arm_with_shoulder_15"
            class="L_Schultergelenk_Y_obl_st5"
            d="M154.1,102.6c6,35-31,56-22,100.9"
          />
          <path
            id="upper_arm_with_shoulder_16"
            class="L_Schultergelenk_Y_obl_st1"
            d="M146.1,68.6c0,6.9,28.5,0,39,12.5"
          />
          <line
            id="upper_arm_with_shoulder_17"
            class="L_Schultergelenk_Y_obl_st1"
            x1="184.1"
            y1="15.6"
            x2="165.6"
            y2="13.6"
          />
          <line
            id="upper_arm_with_shoulder_18"
            class="L_Schultergelenk_Y_obl_st1"
            x1="226.1"
            y1="61.6"
            x2="195.1"
            y2="57.6"
          />
        </g>
        <g id="ribs">
          <path
            id="ribs_1"
            class="L_Schultergelenk_Y_obl_st2"
            d="M236.3,134.6c-54.1,20.5-40.8,69.9,5.8,96"
          />
          <path
            id="ribs_2"
            class="L_Schultergelenk_Y_obl_st2"
            d="M239.2,102.6c-99.5,69-47.1,144.5,0,151.5"
          />
          <path
            id="ribs_3"
            class="L_Schultergelenk_Y_obl_st2"
            d="M236.3,190.6c-84.6,29.4-53.2,116.3,12.8,155.8"
          />
          <path
            id="ribs_4"
            class="L_Schultergelenk_Y_obl_st2"
            d="M242.7,159.6c-145.8,50.6-69.4,184.1,6.4,219"
          />
          <path
            id="ribs_5"
            class="L_Schultergelenk_Y_obl_st2"
            d="M239.2,254.1c-118.6,12.5-78.1,122.7,2.9,181.5"
          />
          <path
            id="ribs_6"
            class="L_Schultergelenk_Y_obl_st2"
            d="M215.1,230.6c-125.2,33.7-61.4,175.8,0,218.5"
          />
          <path
            id="ribs_7"
            class="L_Schultergelenk_Y_obl_st2"
            d="M148.8,356.6c-21.2,21.9-6.9,73.4-0.5,79"
          />
          <path
            id="ribs_8"
            class="L_Schultergelenk_Y_obl_st2"
            d="M165.3,390.6c-13.2,18.8-2.5,42.7,5.5,45"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="ribs_overlay"
          class="ripsFill"
          style={{
            fill: props.colors.rips2011Fill,
            stroke: props.colors.rips2011Fill,
          }}
          d="M239.2,102.6l-2.9,32c-27.7,11.3-37.6,30.1-34.9,45.3c11.2-8.1,25.3-14.6,41.3-20.3l-6.4,31
		c-8.1,2.4-16.6,6.7-25.7,12.9c9,11.3,19.3,20.6,31.5,27.1l-2.9,23.5c-19.1,1.6-36.3,7.3-51.6,17c10.6,32.6,30.5,58.1,61.5,75.3
		v32.2c-38.1-18.6-63-48.3-80.1-84.5c-17.4,49,23,102,73.1,141.5l-27,13.5c-22.8-18.1-39.6-37.5-49.8-58.5c-8.6,17.6-7,32.6,5.5,45
		h-22.4c-17.6-32.6-11.6-66.4,0.5-79c-11.5-42.3-5-71.8,11.9-93.9c-1.6-27.7,4.9-51.3,23.4-68.6C175.7,163.3,204.6,127,239.2,102.6
		L239.2,102.6z"
        />
        <path
          id="upper_arm_shaft_overlay"
          class="upperarmFill"
          style={{
            fill: props.colors.upperarm2011Fill,
            stroke: props.colors.upperarm2011Fill,
          }}
          d="M47.1,435.6c-7-10.8,5.5-30.2,8.3-50.6c6.6-45.9,10.4-101.7,13-161.9
		c1.8-40.5,2.4-69.3-9.7-73.7c-18.8-13.2-27.5-26.9-27.1-45.7C37.2,91,44.9,78.8,65.1,68.5c10.8-7.9,24.8-11.9,45-9
		c16.6,3.9,32,14,44,41.7c2.7,15.2-4.2,34.6-16.5,56.2c-6.6,13.3-10.3,27.9-5.5,46.1c-11.1,32.8-17.5,69.3-16.6,111.9l-6.6,86
		c1.6,19.2,2.1,35.8-3.9,38.2L47.1,435.6L47.1,435.6z"
        />
        <path
          id="scapula_overlay_00000033346662842114268300000016787342287500697229_"
          class="scapulaFill"
          style={{
            fill: props.colors.scapula2011Fill,
            stroke: props.colors.scapula2011Fill,
          }}
          d="M31.6,103.7
		c-0.4-0.8-1.3-2-2.8-3.1C26.4,99,23.9,99,23.1,99C1.8,95.6-5.1,85,6.1,65.2c27.4-28.8,55.9-46.8,87-51.6c13.5,2,24.4,1.6,27-6.3
		C122.2,0,129-1,140.3,4.1c8.7,1.6,16.5,1.5,23.3-0.3c7.5-4.8,13.5,1,18.9,10.4c3.6,4.6,9.4,6.3,17,5.6c10.2,0.5,20.8,3.3,32.6,10.8
		v0l-6,38l-32.9,17.2c-1.8,4.6-1.9,8.4,3,9.8c11.9,4.1-3.1,13-21.6,30c-20.5,23.1-33.3,49.7-42.5,77.9c-3.2,10.6-7.6,26.5-12.1,45.9
		c-6.2,27.4-9,46.8-11.4,63c-7.8,50.8-16.7,109.4-22.7,109c-5.4-0.3-2.8-49.4-12.2-130.9c-1.1-9.8-3.9-32.7-4.8-60.1
		c-0.8-25.5-1.2-38.3-0.2-44.9c0.1-0.6,1-6.7,0.4-14.6c-0.5-6.8-1.8-10-2.2-11c-2.3-5.5-6.1-8.8-8.1-10.5
		c-5.7-5-13.1-12.4-23.4-23.9"
        />
      </g>
    </svg>
  );
}
