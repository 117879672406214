import style from "./style.scss";

function RechtesOberesSprunggelenkInnenrotationDvOblLiegend1464(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378.93 518.73">
  
  <g id="upper_ankle" data-name="upper ankle">
    <g id="background_layer" data-name="background layer">
      <path id="background" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls2" d="M242.12,14.92l44.37,157.94c3.83,13.62,5.32,27.84,3.93,41.91-1.67,16.93-6.94,31.64-16.62,43.63-7.07,8.76-7.43,21.17-1.26,30.57l103.52,157.61c5.79,8.81,2.49,20.71-7.02,25.28l-75.14,36.1c-16.36,7.86-34.43,11.52-52.56,10.65l-200.35-9.67C18.09,494.35,2.33,475.08,0,447.01v-31.08c-.07-37.3,5.43-72.52,20.57-104.11,2.63-5.49,1.91-12.11-2.05-16.73-8-9.33-11.24-24.43-8.87-46.31.68-6.3,1.22-12.62,1.44-18.95L18.65,19.57C19.04,8.69,28.01.1,38.9.17l185.57,1.27c8.23.06,15.43,5.55,17.65,13.47Z"/>
    </g>
    <path id="talus" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls4" d="M88.4,233.54c4.5,7.8,4.1,16-.7,24.6-9.8,17.7-9.9,28.1,1.9,29.5,3.8.5,7.5,1.9,10.7,4.1,11.1,7.7,23.2,14.2,36.5,19.2,4,1.5,8.5,1.9,12.7,1,11.1-2.2,20.6,3.4,26.7,26.3,1.1,4,1.5,8.1,1.6,12.2.2,9.4,3.5,17.6,11.4,24.2,19.4,6.9,35.3,5.3,47.2-6,12.7-3.9,20.7-20.6,23.6-51.8,2-7.9-.4-13.6-5.5-18.2-3.4-3.1-6.1-6.9-8.1-11l-27.7-56.7c-9.9-22.6-29.1-27.5-57.4-14.6-15.1-1.2-27.6-3.6-37.9-7.1-26-7.7-36.7-.9-37.3,15.5-.1,3.1.8,6.1,2.3,8.8h0Z"/>
    <path id="calcaneus" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls4" d="M58.8,305.74c5.8-9.5,12.2-15.5,19.6-15.8,11.9,1.7,36.8,14.5,62.2,27.8,17.7.1,26.6,6.2,16.4,25.4-3.1,5.9-1.6,13.1,3.5,17.3,13,10.7,14.2,23.8-.6,40.2-1.2,1.4-2.1,3.1-2.3,4.9-1.4,12.1-9,20.6-25.6,24.2-2.6.5-4.3,3-3.8,5.6,2.3,13.1-4.2,17.9-15.7,18.1-23.4-3.7-47.7-2.8-72.5,1.7-5.2.9-10.4.9-15.5-.3-15.7-3.7-16.6-15.1-7.1-31.9,1.5-2.8,1.4-6.2-.3-8.8-8.6-13.1-7.6-31.6-2.5-52.6,4.1-21.8,14.6-36.6,31.5-44.5,5.2-2.4,9.7-6.4,12.7-11.3h0Z"/>
    <path id="toe1" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls4" d="M313.2,496.94c8.1-5.3,10.8-7.2,16-19.2,5.2-27.3,1.3-44-7.5-55.1l-9-30.4c8.6,6.2,18.8,16.2,30.8,30.4,10.6,13.4,19,27.1,25.1,40.9"/>
    <path id="tarsus1" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls4" d="M177,393.24c2.1.8,4.1,1.9,6.1,3.1,26.4,16.4,56,28.4,91,32.7,17.6,5,28.4-1.6,30.4-23.6,4.1-19.8-4.6-40.2-24.8-61.1-8.9-15.3-19-26.9-30.3-34.5-13.6-9.2-30.9-11-46.2-4.8-11.2,4.6-21.9,12.9-31.8,27.3-10.3,7.9-13.1,18.8-10.1,32,.4,1.9.6,3.8.5,5.8-.4,11.5,5.1,18.9,15.2,23.1h0Z"/>
    <path id="tarsus2" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls4" d="M260.1,493.74c-2.7-3-5.2-6.6-7.4-10.6-6.3-11.9-5.5-26.3,3-39.8,6.2-10.8,8.1-23.7,6.8-38.2l-4.9-21c-3.3-29,1.7-41.7,19.9-28.5,23.5,15,38.8,36.9,44.2,67.1,10.5,15.9,12.4,34.5,7.5,55.1-2.6,8.9-8.3,14.9-16,19.2"/>
    <path id="toe2" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls4" d="M248.1,496.64c-13.7-34.1-29.5-64.5-49.9-87-10.3-9.4-8.2-19.9,1.9-31,1.4-1.5,3.2-2.7,5.1-3.4l34.3-12.8c16.5-5.5,17.7,6,9.7,29.5-2,6-1.8,12.6.7,18.5l9.9,23.5,25.5,64.9"/>
    <path id="ulnar" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls4" d="M67.8,1.54s9,172.5,13.9,193.5c2.5,10.8,1.8,21.9-1.1,33.2-.5,1.9-.5,3.8-.2,5.7,2.5,13.8,1.5,26.7-4.7,38-8,24.2-21.7,32-40.5,25.5-15.9-8.3-20.6-22.4-10.6-44.1,2.5-5.4,4.4-10.9,5.4-16.7C39.9,180.84,41.7,7.14,37.5,1.54"/>
    <path id="radius" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls4" d="M210.7.04c2.9,38.2,19.1,95.9,47.7,171.6,2,5.2,4.3,10.3,6.6,15.4,7.9,17.3,4.2,37.8-12.7,62-1.6,2.3-3.6,4.3-6,5.6-10.9,6-18.3-5.3-23.4-27.8-.8-3.2-1.8-6.3-3.4-9.1-13-23.6-35.3-24.5-62.4-14.1-10.7,3.9-24.1,5.2-42.4,1.9-9.5-1.7-18.5-5.8-25.6-12.3-14.5-13.1-16.7-30.4-10-50.7,1.9-5.7,3.5-11.6,4.9-17.5,8.8-36.7,14.3-77.2,17.6-120.1"/>
    <path id="toe3" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls4" d="M223.1,501.04c-11.1-23.7-19.7-46.6-22.9-67.8-.7-4.6-2.2-9-4.7-13-9.6-15.3-19.4-14.5-29.4-5.1-9.2,2.3-13.7,7.5-15.2,14.5-1,4.7.2,9.5,3,13.4,12.1,16.5,20.7,35.1,27.2,55"/>
    <path id="toe4" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls4" d="M127.1,498.14c1.8-1.8-.9-6.4-5.6-12.4-5.3-4.2-6-9.8-4.9-15.8-.1-7.9,5.7-15.3,21.4-22.1,10.8-4.3,16.5,8.7,20.2,28.1,3.4,9.9,7.5,18.2,13.1,23.6"/>
    <path id="toe5" class="oberes_Sprunggelenk_Innenrotation1464_vd_obl_rechts_cls4" d="M119.5,496.54c-1.6-11.2-7.2-20.8-17.2-28.5-5.3-3-11.4-4.6-18.2-4.8-8.7-.2-17.3,3.2-23.1,9.7-5.8,6.5-8.8,14.3-10.8,22.5"/>
  </g>
  <g id="overlays">
    <path id="upper_ankle_joint_overlay" data-name="upper ankle joint overlay" class="SG1464Fill" style= {{
              fill: props.colors.SG1464Fill,
              stroke: props.colors.SG1464Fill,
              opacity:props.colors.SG1464Fill,
            }} d="M242.07,278.77c9.72-5.86,11.5-13.51,2.91-23.5-7.12,4.53-13.32-.73-18.6-15.96l-5.62-19.04c-7.13-17.07-21.14-23.25-39.34-22.77-7.74.48-17.61,3.49-28.25,7.4-14.09,3.2-28.11,3.19-42.06-.14-12.54-2.24-22.82-10.53-31.34-23.46l1.94,13.72c2.35,10.74,1.99,22.37-1.43,35.01,1.94,16.01,2.35,28.37-4.57,41.89-8.84,25.42-22.73,32.52-40.5,25.5-2.55,8.8-.96,16.08,7.76,20.74,8.79-3.8,14.42-8.81,17.38-14.84,6.53-9.61,13.24-14.42,20.2-13l4.31-4.05c-6.98-3-5.28-12.98,2.85-28.14,5.11-7.91,5.01-16.13.7-24.6-4.79-13.82-1.07-21.99,8.53-26.26,9.63-1.99,20.16-.36,31.24,3.45,9.6,2.86,20.9,4.55,33.13,5.61,13.46-6.64,25.77-8.86,36.69-5.7,10.43,1.96,18.62,13.42,25.11,29.31l18.97,38.83Z"/>
    <g id="Outer_ankle_overlay" data-name="Outer ankle overlay">
      <path id="Outer_ankle_overlay_1" data-name="Outer ankle overlay 1" class="OuterAnkles1464Fill" style= {{
              fill: props.colors.OuterAnkles1464Fill,
              stroke: props.colors.OuterAnkles1464Fill,
              opacity:props.colors.OuterAnkles1464Fill,
            }} d="M26.16,249.75c22.12-3.28,38.75,3.9,49.54,22.19-5.13,14.94-12.68,25.81-25.5,27.87-15.18-.15-25.72-6.15-30.13-19.89-1.92-11.04,1.23-20.82,6.08-30.18Z"/>
      <path id="Outer_ankle_overlay_2" data-name="Outer ankle overlay 2" class="OuterAnkles1464Fill" style= {{
              fill: props.colors.OuterAnkles1464Fill,
              stroke: props.colors.OuterAnkles1464Fill,
              opacity:props.colors.OuterAnkles1464Fill,
            }} d="M220.76,220.28c13.37-16.72,29.23-24.43,47.16-24.56,2.43,10.77,1.65,21.08-3.25,30.81-9.27,19.67-18.27,34.09-26.31,29.34-7.62-1.12-12.78-17.32-17.6-35.59Z"/>
    </g>
  </g>
</svg>
  );
}

export default RechtesOberesSprunggelenkInnenrotationDvOblLiegend1464;
