import style from "./style.scss";

function Lendenwirbelkorper2LatLiegend1712(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.03 309.92">

<g id="Anatomy">
  <path id="Highlights13" class="Lendenwirbelkorper2_lat1712_cls2" d="M56.58,170.33c-5.61-1.63-12.55-6.23-11.96-14.81.3-3.4,1.6-7,5.5-14.4,4.5-8.4,5.8-8.1,8.2-13.7,1.4-3.4,2.3-8.2,4.1-17.8,1.5-8.5,1.4-9.5,2.7-13.7,1.3-4,2.2-5.1,4.1-10.9,1-3,2.7-9.6,2.7-9.6,0,0-.1-10.8,1.4-17,.8-3.4,2.5-10.7,7.5-13,6.3-3,15.2,2.9,32.9,15,7.4,5.1,10.2,7.9,17.1,10.2,5.3,1.7,6.5,1,18.5,3.4,10.8,2.1,10.6,2.9,16.4,3.4,1.1.1,1.9.2,2.7.1,3.2-.2,6.39-1.13,7.1-1.3,5.38-1.33,5.99-5.18,10.39-4.98,0-.2,3.51.08,3.71.08,8.14-2.35,14.5,4.97,12.3,11.6.4,3.9-.8,4.72-3.7,13.62-1.9,5.7-2.3,6.28-3.8,10.98-2.7,8.5-4.41,9.58-8.51,18.18-3.7,7.9-2.69,7.62-7.19,19.42-4.2,11.1-2.5,15.2-2.5,15.2,0,0-2,15.2-5.1,20.4-3.6,5.8-12.2,6.6-14.4,6.8-11,1-15.1-5.9-30.8-11.6-7.6-2.7-18.1-6.5-31.4-6.8-16.1-.3-22.2-5.9-31.4,1.4-.88.7-3.51.69-6.54-.19Z"/>
  <path id="Highlights12" class="Lendenwirbelkorper2_lat1712_cls6" d="M99.55.15c-5.5,7-8.7,7.9-9.1,12.3-1.2,10.3,11.6,19.6,15.7,22.6,5.7,4.1,11.3,6.8,45.1,15,27.4,6.7,28.6,6.1,34.9,9.6,9.8,5.4,16.2,12,21.2,9.6,3.6-1.8,3.7-7.2,4.8-13.7,2-12.5,7.1-22,17.1-41,1.5-2.8,2.9-5.5,4.3-8"/>
  <path id="Highlights11" class="Lendenwirbelkorper2_lat1712_cls6" d="M131.35,309.75c-.9-2-2-4-2.6-6.7-1.6-6.3.5-10.3,4.8-23.2,5.5-16.3,8.7-33.9,17.8-48.5,1.6-2.6,5-10,6.6-14.2.7-2-1.3-6.5-4.9-7.3-1.6-.3-4.1.4-6.4,0-4.5-.9-51-.5-52.9-.4-22.6-5-19.2-2.2-51.7-9.5-4.5-1-12.3-3.9-17.8,0-4.4,3.1-5.2,9-5.5,11.6-3.3,25.4-6,36.2-7.5,43.8-2.7,13.6-4.1,20.4-6.8,27.3,0,0-3.6,10.7-4.1,17.8-.1.9-.1,1.7-.1,2.5"/>
  <path id="Highlights10" class="Lendenwirbelkorper2_lat1712_cls5" d="M368.75,30.55c-3-2.5-5.6-4.2-15.5-9-18.9-9.3-21.3-9.2-23-8.9-6.3,1.3-7.2,6-13.4,6.1-6.8.1-9-5.6-12.6-4.8-7,1.7-6.6,25.2-6.1,44.8.8,30.7,4.1,34.3,6.8,35.7,6.3,3.1,11.9-4.7,28.3-3.7,6.4.4,6.6,1.6,13.7,1.8,7.7.2,15.6.5,22.3-4.1,1.4-.9,2.5-2,3.6-3.1"/>
  <path id="Highlights9" class="Lendenwirbelkorper2_lat1712_cls3" d="M254.05,148.55c2-.5,3.4,1,7.5,4.3,7.5,5.9,22.6,18,39,22.1,10.2,2.6,12.1-.2,20.5,3.6,5.2,2.4,9.1,5.7,16.9,12.3,6.8,5.8,10.2,8.7,11.4,11.9,1,2.6.9,4.2-.9,19.6-1.4,12.4-1.5,13.3-2.3,15-2.4,5.9-8.3,14.2-15.5,14.6-5.4.2-7.2-4.1-15-9.6-.7-.5-16.6-11.2-32.8-10-16.1,1.2-20.8,13.4-27.8,10.5-3.6-1.5-6.6-6.4-6.8-45.6-.4-44.3,3.4-48.1,5.8-48.7h0Z"/>
  <path id="Highlights8" class="Lendenwirbelkorper2_lat1712_cls5" d="M289.95,308.45c-1.7-.9-3.5-1.8-5.4-2.7-9.8-4.7-5.6-1.9-32.8-13.2-9.8-4.1-20.1-6.7-30.1-10.3-7.3-2.6-13-4.7-16.4-2-2.5,2-2.6,5.7-2.3,13.2.3,5.9.7,11.2,1.1,15.6"/>
  <path id="Highlights7" class="Lendenwirbelkorper2_lat1712_cls5" d="M203.85,309.05c0-1.1-.1-2.2,0-3.3,0-4.8.5-8.8.1-16.8-.3-6.6-.9-8.7-1.4-10-1.9-5.7-4.6-7.2-6.8-11.4-3-5.6-2.5-11-1.8-17.8,1.8-19.3,12-30.1,8.2-33.7-1.5-1.4-4.2-.9-6.2-.5-1.2.2-4.8,1-9.7,6.4-5.2,5.8-3.7,7.6-8.3,12.2-1.4,1.4-5.1,5-10.8,6.1-1.9.4-4.9,1-7.9-.3-4.2-1.9-4.3-5.9-6.3-6.2-4.1-.6-9.2,15.2-14.1,28.7-11.2,30.5-11.9,36-10.7,36.5,1.9.9,6.2-11.6,14.6-12.3,8.6-.7,16,11.3,17.8,14.1,1.7,2.7,2.9,5.5,3.9,8.6"/>
  <path id="Highlights6" class="Lendenwirbelkorper2_lat1712_cls3" d="M202.55,87.95c-1.2-.3-5.8,12.3-9,20.4-8.7,22.2-10.5,21.9-14.7,35-6.2,19.1-6.7,33.3-5.9,33.5h0"/>
  <path id="Highlights5" class="Lendenwirbelkorper2_lat1712_cls3" d="M172.95,176.85c.7.1,1.1-13.2,9-16.9,6.5-3,15.6,1.8,20.6,6.4,2.1,1.9,10.6,10.1,11.4,31,1.2,33.5-19.6,44.9-12.3,64.2,3.9,10.2,14.3,19.2,24.1,18.7,10.1-.5,17.2-10.9,21-16.4,2.5-3.6,6.2-9.3,6.4-16.9.1-4.9-1.4-7.1-2.9-13-1.2-4.8-1.4-10.6-1.9-22,0,0-.7-18.9.3-42.5.4-9.9.8-11.9,1.3-14.1,1.3-5.4,2.8-7.4,2.3-11.4-.6-4.1-2.6-5.5-4.8-9.6-2.7-5.2-4.2-11.9-3.4-17.3.9-5.9,8.7-12.5,10.5-16.3.3-.7.6-1.4.6-1.4,0,0,.8-2.3.7-4.6-.1-2.6-2.1-7.3-4.9-8.3-8-2.8-16.6,19.8-37,23.4-6.9,1.2-9.4-.8-10-1.4-5.9-5.3-.1-20.1-1.4-20.5"/>
  <path id="Highlights4" class="Lendenwirbelkorper2_lat1712_cls1" d="M259.05,106.25c2.4,1.3,1.7,6.6.5,15.9-1.4,10.2-2.1,15.3-3.6,15.5-3.4.4-12.5-18.2-5.5-27.3,2.2-3,6.4-5.4,8.6-4.1Z"/>
  <path id="Highlights3" class="Lendenwirbelkorper2_lat1712_cls5" d="M233.45,6.65c-2.52,4.73-5.04,9.54-7.55,14.42-2.29,4.45-4.53,8.87-6.72,13.26-1.13,2.57-2.4,5.71-3.65,9.34-1.94,5.67-2.9,8.51-3.38,11.88-.61,4.29-.24,6.63-2.09,9.99-1.32,2.42-2.82,3.6-2.71,3.71.22.22,6.88-4.53,10.8-11.9,5.1-9.6,4.4-10.6,6.8-13.2,6.8-7.2,19-5.8,21.4-5.5,2.8.4,10.5,1.4,16.8,7.3,10.6,9.9,10.6,27.3,6,38.3-3,7-5.8,6.6-7.8,13.7-2.6,9.2,1.4,12.4.6,23.6-1.1,12.6-6.6,15.8-4.1,20.1,3.7,6.5,20.1,7.3,31,.5,14.1-8.8,16.7-28.7,15-40.6-.5-3.2-.7-2.1-2.3-9.6-3.2-14.8-3.9-26.3-4.1-31-.6-13.2-.9-20.4,1-28.3,1-4.1,1.4-6.8,2.2-12.2,1-6.7,1-8.5.5-10.4-.2-.9-.5-1.6-.8-2.3"/>
  <path id="Highlights2" class="Lendenwirbelkorper2_lat1712_cls7" d="M169.25,258.45c34.2,24.7-1.6,31.9-3.6,31.9"/>
  <path id="Highlights1" class="Lendenwirbelkorper2_lat1712_cls4" d="M228.95,137.65c8.1,23.5-.2,30-12.8,22.3"/>
  <path id="LWK" class="Lendenwirbelkorper2_lat1712_cls7" d="M279.55,19.15c15,21-5.1,22.8-11.4,22.8"/>
</g>
<g id="Highlights">
  <path id="Highlights5-2" data-name="Highlights5" class="Querfortsatz1712Fill" style= {{
            fill: props.colors.Querfortsatz1712Fill,
            stroke: props.colors.Querfortsatz1712Fill,
          }} d="M228.95,137.98c8.1,23.5-.2,30-12.8,22.3"/>
  <path id="Highlights4-2" data-name="Highlights4" class="Dornfortsatz1712Fill" style= {{
            fill: props.colors.Dornfortsatz1712Fill,
            stroke: props.colors.Dornfortsatz1712Fill,
          }} d="M254.33,148.09c2-.5,3.4,1,7.5,4.3,7.5,5.9,22.6,18,39,22.1,10.2,2.6,12.1-.2,20.5,3.6,5.2,2.4,9.1,5.7,16.9,12.3,6.8,5.8,10.2,8.7,11.4,11.9,1,2.6.9,4.2-.9,19.6-1.4,12.4-1.5,13.3-2.3,15-2.4,5.9-8.3,14.2-15.5,14.6-5.4.2-7.2-4.1-15-9.6-.7-.5-16.6-11.2-32.8-10-16.1,1.2-20.8,13.4-27.8,10.5-3.6-1.5-6.6-6.4-6.8-45.6-.4-44.3,3.4-48.1,5.8-48.7h0Z"/>
  <path id="Highlights3-2" data-name="Highlights3" class="WK1712Fill" style= {{
            fill: props.colors.WK1712Fill,
            stroke: props.colors.WK1712Fill,
          }} d="M56.21,170.26c-5.61-1.63-12.55-6.23-11.96-14.81.3-3.4,1.6-7,5.5-14.4,4.5-8.4,5.8-8.1,8.2-13.7,1.4-3.4,2.3-8.2,4.1-17.8,1.5-8.5,1.4-9.5,2.7-13.7,1.3-4,2.2-5.1,4.1-10.9,1-3,2.7-9.6,2.7-9.6,0,0-.1-10.8,1.4-17,.8-3.4,2.5-10.7,7.5-13,6.3-3,15.2,2.9,32.9,15,7.4,5.1,10.2,7.9,17.1,10.2,5.3,1.7,6.5,1,18.5,3.4,10.8,2.1,10.6,2.9,16.4,3.4,1.1.1,1.9.2,2.7.1,3.2-.2,6.39-1.13,7.1-1.3,5.38-1.33,5.99-5.18,10.39-4.98,0-.2,3.51.08,3.71.08,8.14-2.35,14.5,4.97,12.3,11.6.4,3.9-.21,3.88-3.11,12.78-1.9,5.7-2.89,7.12-4.39,11.82-2.7,8.5-4.04,9.65-8.14,18.25-3.7,7.9-3.06,7.55-7.56,19.35-4.2,11.1-7.02,28.27-7.02,28.27,0,0,2.52,2.13-.58,7.33-3.6,5.8-12.2,6.6-14.4,6.8-11,1-15.1-5.9-30.8-11.6-7.6-2.7-18.1-6.5-31.4-6.8-16.1-.3-22.2-5.9-31.4,1.4-.88.7-3.51.69-6.54-.19Z"/>
  <path id="Highlights2-2" data-name="Highlights2" class="Angrenzende_WK1712Fill" style= {{
            fill: props.colors.Angrenzende_WK1712Fill,
            stroke: props.colors.Angrenzende_WK1712Fill,
          }} d="M100.22.32c-5.5,7-8.7,7.9-9.1,12.3-1.2,10.3,11.6,19.6,15.7,22.6,5.7,4.1,11.3,6.8,45.1,15,27.4,6.7,28.6,6.1,34.9,9.6,9.8,5.4,16.2,12,21.2,9.6,3.6-1.8,3.7-7.2,4.8-13.7,2-12.5,7.1-22,17.1-41,1.5-2.8,2.9-5.5,4.3-8"/>
  <path id="Highlights1-2" data-name="Highlights1" class="Angrenzende_WK1712Fill" style= {{
            fill: props.colors.Angrenzende_WK1712Fill,
            stroke: props.colors.Angrenzende_WK1712Fill,
          }} d="M132.02,309.92c-.9-2-2-4-2.6-6.7-1.6-6.3.5-10.3,4.8-23.2,5.5-16.3,8.7-33.9,17.8-48.5,1.6-2.6,5-10,6.6-14.2.7-2-1.3-6.5-4.9-7.3-1.6-.3-4.1.4-6.4,0-4.5-.9-51-.5-52.9-.4-22.6-5-19.2-2.2-51.7-9.5-4.5-1-12.3-3.9-17.8,0-4.4,3.1-5.2,9-5.5,11.6-3.3,25.4-6,36.2-7.5,43.8-2.7,13.6-4.1,20.4-6.8,27.3,0,0-3.6,10.7-4.1,17.8-.1.9-.1,1.7-.1,2.5"/>
</g>
</svg>
  );
}

export default Lendenwirbelkorper2LatLiegend1712;
