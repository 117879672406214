import { Modal, Button } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

function UniversalModal({
  shown = false,
  size = "md",
  onHide,
  headerTxt = "",
  variant = "light",
  children,
}) {
  return (
    <Modal show={shown} size={size}>
      <ModalHeader
        closeButton
        className={`p-2 ${variant === "dark" && "bg-dark text-white"}`}
      >
        <h4>{headerTxt}</h4>
      </ModalHeader>
      <Modal.Body
        className={`p-1 ${variant === "dark" && "bg-dark text-white"}`}
      >
        {children}
      </Modal.Body>
      <Modal.Footer
        className={`p-0 ${variant === "dark" && "bg-dark text-white"}`}
      >
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UniversalModal;
