import style from "./style.scss";

export default function LinkeRippenDvOblStehend1811(props) {
  return (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.83 784.15">

    <g id="backgrounds">
      <path class="linke_rippen_dv_obl_stehend_links1811_cls7" d="M54.55,30.47c-26.5,13.2-32,43.8-32.7,47.6-1,4.8-2.6,15.1,2.7,40.4,7.1,33.6,14.4,58.1,23.6,84.1,11,31.2,11.7,50.8,11.2,64-3.2,78.9-5.7,62.7-6.7,123.3,0,0-.8,42.4,0,74,2.5,105.8,10,229.6,94.5,286,24.8,16.5,57.2,31,124.7,32.9,63.9,1.8,94.1,2.6,108.6-2.4,46.6-16.2,51.6-93.1,42.8-196.4-23-269.8,6.6-341.2-3.7-471.5-3.6-45.4-32.5-61.7-53.6-69.9-55.5-21.6-103.8,15.1-171.3,25.2-32.3,4.8-31.8-23.4-64.4-37.4-25.6-10.9-57.5-9-75.7.1Z"/>
    </g>
    <g id="Highlightedrips" class="linke_rippen_dv_obl_stehend_links1811_cls4">
      <path id="rip12l" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M178.05,693.47c-2.3-1.7.4-9,3.2-16.9,2.4-6.5,4.2-11.4,8.5-16.9,3.4-4.4,4.8-4.4,12.4-11,4.8-4.2,9-8.6,17.6-17.6,7-7.4,10.7-11.1,14.9-16.3,10.2-12.1,7.9-11.7,14.3-18.2,4.2-4.3,7.1-6.4,8.5-11,1-3.4.4-6.1,2.6-7.8,2.4-1.9,6-1,7.1-.7,4.5,1.1,6.8,4.7,7.1,5.2,2,3.1,1.8,6.5,1.3,13-.5,5.9-.8,9.6-3.2,13.6-.5.9-2.2,3.4-5.2,5.9-4,3.1-6.8,3.2-11,5.2-3,1.4-5.7,4-11,9.1-4,3.8-8.4,8.3-14.3,14.9-6.4,7.1-9.9,11-14.3,16.9-6.9,9.1-7.1,11-12.4,16.3-4.5,4.5-8.4,7-12.4,9.7-7.4,5-11.8,8-13.7,6.6h0Z"/>
      <path id="rip11lhighlight" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M175.55,731.17c-10.9-11-15.9-22-18.2-28.6-4.3-11.9-4.4-21.8-4.6-32.5-.1-9.9-.3-19.5,3.2-31.2,2.8-9.3,7.2-16.3,16.3-30.5,6.5-10.2,10.7-16.9,18.2-25.4,6.2-6.9,13.7-15.3,26-22,1.6-1,8.7-4.8,18.8-7.1,6.7-1.5,10.8-2.5,15.6-.7,5.5,2.1,9.9,7.2,10.4,7.8,3.1,3.6,3.4,5.8,5.8,6.5,3,.9,7.1-1.4,9.1-4.6,2.5-4.1-.5-6.8,0-15.6.4-6.2,2-6.4,2.6-13.6.6-6.4,1-11.7-1.9-14.3-2.6-2.3-6.5-1.3-15.6.7-23.6,5.1-20.1,3.7-24.7,5.2-12.7,4.2-21.5,9.5-27.3,13-12.9,7.9-21.4,15.9-27.3,21.5-7.4,7-15,14.3-22.7,25.9-8,12.2-12.2,23.3-14.3,29.8-4.3,13.2-5,23.6-5.9,33.7-.9,11.1-1.6,20.9.7,33.7s6.4,22.2,8.5,26.6c6.4,13.3,14.5,22.9,20.8,29.3"/>
      <path id="rip10lhighlight" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M202.15,710.37c-3.8-1.3-13.4-9.1-32.5-24.7-16.9-13.8-19.4-16-22.7-19.5-4.8-4.9-10.9-11.4-16.9-21.5-4-6.7-8.3-13.9-10.4-24.7-2.2-11.2-.8-20.3,0-25.4.6-3.6,1.7-10.5,5.2-18.2,2.9-6.5,5.8-9.9,22.7-27.9,17-18.2,19-20,21.5-22,4.6-3.9,10.4-8,22.1-16.3,11-7.8,13.5-8.9,16.9-9.7,3.4-.8,7.1-1,13-3.2,4.8-1.9,4.4-2.6,7.1-3.2,4.4-1,6.6.3,16.9,2.6,6.3,1.4,9.5,2.1,13,1.9,4.4-.2,9.7-.4,12.4-3.9,2.2-2.9,1.2-7.1-.7-15.6-1.7-7.6-2.6-11.4-5.2-13.7-5-4.3-12.6-2.9-18.2-1.9-6.8,1.2-10.5,3.4-19.5,7.8-12.8,6.2-10.5,4.2-23.4,10.4-13.1,6.3-23,12-28,14.9-12.4,7.3-18.5,10.9-25.4,16.9-2,1.7-10,8.8-18.2,20.1-6.9,9.5-10.7,17.5-14.9,26.6-5.8,12.4-8.7,18.4-9.7,25.4-1.9,12.3.7,22,3.9,33.7.8,2.9,4,14.5,11.7,29.3,7.5,14.3,15.2,23.8,22.7,33.2,8.6,10.5,15.7,17.7,21.5,23.4,7.7,7.6,13.6,12.7,20.2,17.6,6.8,5,14.1,9.4,23.4,10.4,6.8.7,8.8-1,9.7-1.9,2.7-2.9,1.9-8.3,0-11.7-3.4-6.1-10.2-6.4-18.2-9.2h0Z"/>
      <path id="rip9lhighlight" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M234.65,693.47c-23.5-13.8-41.7-27.3-54.6-37.7-16.2-13-24.9-22-28.6-25.9-13.4-14.3-21.6-26.1-24.1-29.8-10.2-15.3-15.6-23.4-18.2-36.4-2.9-14.6-.5-26.6.7-31.2,3.1-12.9,9.1-21.5,12.4-25.9,6.5-9.1,14.7-15.4,31.2-27.9,19.1-14.5,17.4-10.1,31.2-20.8,3.8-2.9,7.7-6.2,13.7-7.1,5.3-.9,7.6.9,13.7-.7,4.9-1.2,4.3-2.5,10.4-4.6,3.7-1.2,8.9-2.9,14.9-1.9.9.1.6.1,8.5,2.6,8.5,2.7,9.2,2.8,10.4,2.6,4.2-.6,8.7-4.2,9.7-9.1,1.2-6.1-3.6-10.7-4.6-11.7-3.3-3.1-7.2-3.8-20.2-4.6-13.4-.8-20.2-1.1-23.4-.7-5.9.9-10.6,2.6-25.4,10.4-12.9,6.8-19.2,10.6-34.4,19.5-21.9,12.6-24.4,13.7-29.9,18.8-9.1,8.7-14.1,17.5-16.9,22.7-3.4,6.3-9.4,18.7-11.7,35.7-2.5,18.1.5,32,1.3,35.7,3.2,14.1,8.8,23.9,13,31.2,5.3,9.3,10.5,15.7,15.6,22,7.3,9,13.3,15.2,20.2,22,12.9,13,23.7,22.2,27.3,25.4,12.5,10.5,29.7,23.9,52,37.7"/>
      <path id="rip8lhighlight" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M241.15,633.07c-1.1-5.2-12.6-9.1-18.8-11.7-6.5-2.7-6.5-4.1-23.4-16.9-26.8-20.3-28.5-18.2-42.2-29.8-17.6-15-27.8-29.7-35.8-41-15.5-21.9-18.4-33.2-19.5-38.3-1.5-7.4-3.9-18.5.7-30.5,3.4-9.1,10.6-15.1,24.7-26.6,8.3-6.7,20.7-16.9,39.7-27.3,11.1-6.2,19.6-9.5,36.4-16.3,1.9-.8,11.6-4.6,24.1-6.5,8.9-1.4,12.2-.7,14.3,0,4.8,1.5,6.2,3.9,10.4,3.9,3.3,0,7.1-1.4,9.7-4.6,2.9-3.6,4.2-9.2,2-13-1.7-2.9-4.6-3.1-14.3-5.8-11.9-3.3-11.2-3.9-14.9-4.6-8.7-1.4-15.2,1.3-26.6,5.8-34.6,13.8-30.3,10.3-42.2,16.3-10.2,5.1-17.2,9.7-31.2,18.8-16.2,10.5-24.3,16-30.5,23.4-10.4,12.4-14.1,24.9-14.9,27.9-5.9,20.9-.6,38.8,2.6,48.8,4.6,14.6,11.3,24,24.7,42.9,5,7.1,13.7,19.2,27.3,33.2,15.4,15.9,29.3,25.9,41,34.4,17.5,12.6,14.1,8,25.4,16.9,9,7,16.2,14,21.5,11.7"/>
      <path id="rip7lhighlight" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M257.95,584.57c-19.7-7.2-40.1-16.6-51.9-22.3-13.4-6.6-22.1-10.7-33.2-18.2-17.3-11.6-29.2-23.1-35.1-29.3-11.5-12-19-22.6-22.5-27.8-7.9-11.9-13.2-19.8-16.5-31.8-1.5-5.8-4.9-17.6-2-31.2,4.2-20.1,18.6-32.4,33.2-44.9,14-12,27.4-18.6,35.8-22.7,10.2-5,17.5-7.4,20.8-8.5,14.4-4.5,24.9-5.1,29.3-5.2,5-.1,7.8-.2,11.7.7,6.9,1.5,8.6,4.1,13.7,3.9,1.3,0,8.4-.6,11-5.8,1.5-3,1.7-7.6-.7-10.4-3.8-4.4-10.2.1-17.6-4.6-4.3-2.7-3.4-5-7.8-7.1-1.5-.8-5.3-2.3-13.7-.7-9.2,1.8-15.8,5.8-16.9,6.5-11.4,7-10.1,3-44.9,18.2-16.9,7.4-25.4,11.1-29.3,13.7-22.4,14.9-32.4,35.4-34.4,39.6-3.8,8-12.6,27-8.5,50,1.8,10.2,7,19.1,17.6,37.1,12.6,21.6,19.3,33,31.9,45.4,5.6,5.6,18.3,14.2,43.6,31.2,28.4,19.2,37.7,24.2,41,25.9,15.8,8.6,27,8.8,36.4,12.4"/>
      <path id="rip6lhighlight" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M250.85,529.07c-20.4-7.1-40.8-10.7-53.9-15.6-28.4-10.5-34.2-13-41.7-17.6-4.9-2.9-21.4-13.5-37.1-33.2-9.2-11.5-20-25-24.7-46.1-1.8-8.1-5.7-26.6,2-47.4,6.1-16.5,16.4-26.1,28-37.1,15-14.1,29.3-21.4,37.1-25.4,11.8-6,6.7-1.6,37.1-13.7,11-4.4,18.4-7.6,26-4.6,3.4,1.4,5.4,3.9,11,5.2,3.1.8,8.8,2,13-1.3,3.3-2.8,3.8-6.9,3.9-7.8.8-7.6-5.9-13.2-6.5-13.7-4.1-3.4-8.8-3.9-22.1-3.2-13.7.7-20.5,1-28.6,2.6-21.1,4.2-36.7,11.7-42.9,14.9-22.3,11.6-35.9,25.5-39,28.6-12.3,12.7-19.4,25-22.7,31.2-3.5,6.7-8.6,16.3-11.7,29.8-6.1,26.6,0,48.3,2.6,57.1,3.4,11.8,7.8,19.6,9.1,21.9,3.9,6.8,7.5,11,14.3,19,9.3,10.9,17.2,20,29.9,28.6,6.1,4.1,11.7,7.1,14.3,8.5,7.9,4.1,13.9,6.5,18.8,8.5,19.3,7.7,21.6,9.3,31.2,13,11.4,4.4,30.2,10.6,57.8,15.6"/>
      <path id="rip5lhighlight" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M251.45,480.97c-14.4-1.2-31.5-4.2-50-10.4-5.8-2-26-9-49.4-24-13.5-8.6-20.3-13-27.3-20.8-18.7-20.7-22.1-45.5-23.4-56.5-1.2-10.5-4.1-36.1,9.7-63,9.8-19,23.2-29.5,37.1-40.3,12.3-9.6,22.2-17.1,37.1-20.1,17.3-3.5,31.2-1.3,31.2-1.3,6.3,1,12.6,1,18.8,2,4.6.7,8.4,1.4,12.4-.7,1.1-.6,4.4-2.6,5.8-6.5,1.7-4.7.1-10.3-3.2-13-3.4-2.9-7-1.3-16.3-1.3-13,0-12.8-3.1-23.4-2.6-5.5.3-5.1,1-21.5,4.6-14.2,3-14.6,2.5-19.5,3.9-2.9.9-10.1,3.2-25.4,14.9-14.2,10.8-24.9,19-35.1,33.7-8.2,11.9-12.3,22.4-14.3,27.9-5.6,15-7.2,27.4-7.8,32.5-1.9,18.1.5,31.9,1.9,40.3,3.2,18.1,6.9,38.7,22.7,56.5,4.9,5.6,12,10.5,26,20.1,4.4,2.9,21.1,14.4,46.1,26.6,10.2,4.9,15.6,7.6,23.4,10.4,19.7,7,37.1,8.7,48.8,9.1"/>
      <path id="rip4lhighlight" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M245.65,418.67c-8.7.9-21.1,1-35.8-1.9-18.5-3.8-31.3-11-43.6-18.2-17-9.9-28.2-16.3-38.3-29.8-11.1-14.9-14.8-29.8-16.9-39-1.7-7.5-4.6-20.1-2.6-36.4,2.5-21,11.5-35.5,16.9-44.2,9-14.4,18.3-22.3,34.4-36.4,10.3-8.9,16.4-12.7,23.4-15.6,10.5-4.2,20.2-5,22.7-5.2,3.9-.3,7-.2,13,0,8.5.3,12.7.4,16.3,1.3,5.9,1.6,7.5,3.4,11,2.6,3-.8,6.1-3.1,7.1-6.5,1.6-5-2.3-9.7-3.9-11.7-3.4-4.2-7.9-5.9-9.7-6.5-9.6-3-13.4,2.8-26.6,2.6-10.4-.1-10.8-3.7-18.8-2.6-6.8,1-10.4,4.2-19.5,10.4-16.7,11.4-16.1,8.4-26.6,16.3-13.9,10.4-22.3,21.3-25.4,25.4-9.8,13.1-14.4,24.9-17.6,33.2-6.1,15.8-8,28.4-8.5,31.2-2.4,15.8-1.3,27.9-.7,35.7,1.3,15,2.4,26.9,9.1,41,7.1,15,17,24.4,22.1,29.3,7.6,7.2,14.6,11.6,22.7,16.9,11.1,7.1,20.4,11.6,24.7,13.7,3.6,1.7,18.3,8.4,39,13.7,10.5,2.7,23.9,5.2,39.7,6.5"/>
      <path id="rip3lhighlight" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M205.35,273.17c-2.9-2.1-9.9-3.7-15.6-6.5-7.3-3.5-10.7-3.8-16.3-6.5-7.4-3.5-11.9-8-16.9-13s-12.9-13-18.2-25.9c-2.9-7.1-7.4-18.3-4.6-31.8,2.6-12.3,9.9-20,16.9-27.3,10.1-10.6,20.8-16.2,28.6-20.1,4.8-2.5,9.2-4.3,18.2-7.8,16.4-6.5,20.5-6.8,23.4-6.7,2.2,0,7.1,1.4,16.9,4.2,12.6,3.5,15.4,4.9,17.6,7.8,2.6,3.5,4.2,8.9,2,11.7-1.8,2.2-5.2,1.7-11,1.3-7.1-.6-14.3-.3-21.5-.7-.9,0-13-.6-25.4,1.9-13.8,2.9-23.4,8.6-30.5,13-9.4,5.8-19.8,12.2-28.6,24.7-10.7,15.1-12.6,30.2-13.6,39-1,9-2.6,22.4,3.2,38.3,1.8,4.9,7.3,19.4,21.5,30.5,6.6,5.1,11.6,6.8,30.5,13.7,8.1,2.9,22.4,8,41.7,14.9"/>
      <path id="rip2lhighlight" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M197.05,244.17c-4.8-.9-19.8-7.2-30.1-17.9-8.3-8.6-11-17.9-12.4-22.7-4.9-17.4-.3-32.1,2-39,1.3-4,6.5-18.5,20.2-31.8,1.4-1.3,23.6-22.4,41-20.8,5.6.6,11.3.2,16.9.7,5.5.4,6.8-.1,8.5-.7,6.8-2.4,12.9-10.4,11-15.6-1-2.9-4.5-4.3-11.4-6.9-3.6-1.4-6.8-2.7-11.3-2.9-5.7-.3-10.1,1.3-13.7,2.6-6.8,2.5-6.6,4.2-13,6.5-6.4,2.2-7.9,1-13.7,3.2-1.2.5-4.4,1.8-10.4,6.5-2.7,2.1-8.1,6.5-17.8,17.3-6.6,7.4-9.9,11.1-12.7,15.8-3.6,6-4.6,10.2-7.8,17.6-10.9,24.7-10.9,24.6-12.4,27.9-7.4,17.3-11,25.9-13.6,35.7-2.1,7.9-5.8,22.3-4.6,41,.7,10,2.2,33,18.2,53.3,6.8,8.6,13.7,13.5,26,22,5.1,3.6,18.7,12.9,38.3,21.5,6.3,2.8,14.5,6.4,24.3,10"/>
      <path id="rip1lhighlight" class="ribs1811Fill"     style={{
              fill: props.colors.ribs1811Fill,
              stroke: props.colors.ribs1811Fill,
            }} d="M247.05,210.17c-6.3,0-18.5-1-31.2-8.5-18.9-11.2-25.4-29.3-28-36.4-8.7-24.3-2.3-45.5,0-52.7,4.9-15.3,12.6-24.2,21.5-34.4,4.3-4.9,8.5-8.8,14.3-9.7,2.9-.5,3,.4,13.7,1.9,9.6,1.4,11.6,1.1,14.9,3.2,1.3.9,7,4.7,6.5,9.1-.4,2.9-3.2,4.7-5.2,5.9-5.6,3.4-10.2,3-15.6,3.9-2.3.4-12.8,2.1-20.2,9.1-7.4,7.1-8.6,16.7-9.1,20.8-.4,3.1-1.3,12.1,3.2,21.5,3.6,7.3,9,11.6,14.9,16.3,3.8,2.9,10.5,9.6,13,11"/>
      <path class="soft1811Fill"     style={{
              fill: props.colors.soft1811Fill,
              stroke: props.colors.soft1811Fill,
            }} d="M60.15,776.97c-3.37-.04-5.59-36.78-7-60-1.45-24.03-1.33-37.24-2-60-.76-25.93-1.85-40.73-4-75-6.04-96.25-5.05-100.65-10-174-4.53-67.12-6.79-100.68-10-127C16.05,189.97,10.51,144.47,7.15,131.97c0,0-14.66-54.64-2-96,2.42-7.9,6.31-16,14-21,5.55-3.61,10.53-3.95,31-4,37.03-.09,37.48.98,44-1,14.64-4.45,21.99-12.81,28-9,3.42,2.17,4.74,7.22,4,11-1.84,9.41-16.78,12.99-21,14-16.71,4-21.73-3.17-44-3-11.21.08-26.19.19-35,10-5.97,6.65-6.69,15.07-7,20-2.6,40.82,6.38,88.55,10,111,.31,1.89,2.2,17.93,6,50,2.32,19.56,8.3,70.14,12,109,1.97,20.7,4.34,63.41,9,148,5.35,97.12,4.34,86.63,6,110,.03.41,2.7,37.97,5,87,.83,17.66,1.05,24.92,1,34-.19,36.09-4.52,75.04-8,75Z"/>
    </g>
    <g id="basic_drawing" data-name="basic drawing">
      <path id="rip12l-2" data-name="rip12l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M178.05,693.77c-2.3-1.7.4-9,3.2-16.9,2.4-6.5,4.2-11.4,8.5-16.9,3.4-4.4,4.8-4.4,12.4-11,4.8-4.2,9-8.6,17.6-17.6,7-7.4,10.7-11.1,14.9-16.3,10.2-12.1,7.9-11.7,14.3-18.2,4.2-4.3,7.1-6.4,8.5-11,1-3.4.4-6.1,2.6-7.8,2.4-1.9,6-1,7.1-.7,4.5,1.1,6.8,4.7,7.1,5.2,2,3.1,1.8,6.5,1.3,13-.5,5.9-.8,9.6-3.2,13.6-.5.9-2.2,3.4-5.2,5.9-4,3.1-6.8,3.2-11,5.2-3,1.4-5.7,4-11,9.1-4,3.8-8.4,8.3-14.3,14.9-6.4,7.1-9.9,11-14.3,16.9-6.9,9.1-7.1,11-12.4,16.3-4.5,4.5-8.4,7-12.4,9.7-7.4,5-11.8,8-13.7,6.6h0Z"/>
      <path id="vertebralbody7" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M316.55,125.97c-8.7,1.3-16.7,2.4-24.1,3.2-14.7,1.7-18.4,1.6-22.1-.7-5.7-3.5-9.9-11.2-8.5-18.8.9-4.6,3.8-9.5,8.5-11.7,3.5-1.7,6.3-.9,11-.7,0,0,10.7.5,21.5-3.2,8.8-3.1,8.7-6.5,22.1-13,3.3-1.6,11.7-5.7,13-3.9,1.2,1.7-6.2,6.7-9.1,16.3-2,6.4-1.1,12.4,0,16.9"/>
      <path id="rip11l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M175.45,731.47c-10.9-11-15.9-22-18.2-28.6-4.3-11.9-4.4-21.8-4.6-32.5-.1-9.9-.3-19.5,3.2-31.2,2.8-9.3,7.2-16.3,16.3-30.5,6.5-10.2,10.7-16.9,18.2-25.4,6.2-6.9,13.7-15.3,26-22,1.6-1,8.7-4.8,18.8-7.1,6.7-1.5,10.8-2.5,15.6-.7,5.5,2.1,9.9,7.2,10.4,7.8,3.1,3.6,3.4,5.8,5.8,6.5,3,.9,7.1-1.4,9.1-4.6,2.5-4.1-.5-6.8,0-15.6.4-6.2,2-6.4,2.6-13.6.6-6.4,1-11.7-1.9-14.3-2.6-2.3-6.5-1.3-15.6.7-23.6,5.1-20.1,3.7-24.7,5.2-12.7,4.2-21.5,9.5-27.3,13-12.9,7.9-21.4,15.9-27.3,21.5-7.4,7-15,14.3-22.7,25.9-8,12.2-12.2,23.3-14.3,29.8-4.3,13.2-5,23.6-5.9,33.7-.9,11.1-1.6,20.9.7,33.7s6.4,22.2,8.5,26.6c6.4,13.3,14.5,22.9,20.8,29.3"/>
      <path id="rip10l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M202.15,710.67c-3.8-1.3-13.4-9.1-32.5-24.7-16.9-13.8-19.4-16-22.7-19.5-4.8-4.9-10.9-11.4-16.9-21.5-4-6.7-8.3-13.9-10.4-24.7-2.2-11.2-.8-20.3,0-25.4.6-3.6,1.7-10.5,5.2-18.2,2.9-6.5,5.8-9.9,22.7-27.9,17-18.2,19-20,21.5-22,4.6-3.9,10.4-8,22.1-16.3,11-7.8,13.5-8.9,16.9-9.7,3.4-.8,7.1-1,13-3.2,4.8-1.9,4.4-2.6,7.1-3.2,4.4-1,6.6.3,16.9,2.6,6.3,1.4,9.5,2.1,13,1.9,4.4-.2,9.7-.4,12.4-3.9,2.2-2.9,1.2-7.1-.7-15.6-1.7-7.6-2.6-11.4-5.2-13.7-5-4.3-12.6-2.9-18.2-1.9-6.8,1.2-10.5,3.4-19.5,7.8-12.8,6.2-10.5,4.2-23.4,10.4-13.1,6.3-23,12-28,14.9-12.4,7.3-18.5,10.9-25.4,16.9-2,1.7-10,8.8-18.2,20.1-6.9,9.5-10.7,17.5-14.9,26.6-5.8,12.4-8.7,18.4-9.7,25.4-1.9,12.3.7,22,3.9,33.7.8,2.9,4,14.5,11.7,29.3,7.5,14.3,15.2,23.8,22.7,33.2,8.6,10.5,15.7,17.7,21.5,23.4,7.7,7.6,13.6,12.7,20.2,17.6,6.8,5,14.1,9.4,23.4,10.4,6.8.7,8.8-1,9.7-1.9,2.7-2.9,1.9-8.3,0-11.7-3.4-6.1-10.3-6.4-18.2-9.2h0Z"/>
      <path id="rip9l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M234.65,693.67c-23.5-13.7-41.7-27.2-54.6-37.7-16.2-13-24.9-22-28.6-25.9-13.4-14.3-21.6-26.1-24.1-29.8-10.2-15.3-15.6-23.4-18.2-36.4-2.9-14.6-.5-26.6.7-31.2,3.1-12.9,9.1-21.5,12.4-25.9,6.5-9.1,14.7-15.4,31.2-27.9,19.1-14.5,17.4-10.1,31.2-20.8,3.8-2.9,7.7-6.2,13.7-7.1,5.3-.9,7.6.9,13.7-.7,4.9-1.2,4.3-2.5,10.4-4.6,3.7-1.2,8.9-2.9,14.9-1.9.9.1.6.1,8.5,2.6,8.5,2.7,9.2,2.8,10.4,2.6,4.2-.6,8.7-4.2,9.7-9.1,1.2-6.1-3.6-10.7-4.6-11.7-3.3-3.1-7.2-3.8-20.2-4.6-13.4-.8-20.2-1.1-23.4-.7-5.9.9-10.6,2.6-25.4,10.4-12.9,6.8-19.2,10.6-34.4,19.5-21.9,12.6-24.4,13.7-29.9,18.8-9.1,8.7-14.1,17.5-16.9,22.7-3.4,6.3-9.4,18.7-11.7,35.7-2.5,18.1.5,32,1.3,35.7,3.2,14.1,8.8,23.9,13,31.2,5.3,9.3,10.5,15.7,15.6,22,7.3,9,13.3,15.2,20.2,22,12.9,13,23.7,22.2,27.3,25.4,12.5,10.5,29.7,23.9,52,37.7"/>
      <path id="rip8l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M241.05,633.37c-1.1-5.2-12.6-9.1-18.8-11.7-6.5-2.7-6.5-4.1-23.4-16.9-26.8-20.3-28.5-18.2-42.2-29.8-17.6-15-27.8-29.7-35.8-41-15.5-21.9-18.4-33.2-19.5-38.3-1.5-7.4-3.9-18.5.7-30.5,3.4-9.1,10.6-15.1,24.7-26.6,8.3-6.7,20.7-16.9,39.7-27.3,11.1-6.2,19.6-9.5,36.4-16.3,1.9-.8,11.6-4.6,24.1-6.5,8.9-1.4,12.2-.7,14.3,0,4.8,1.5,6.2,3.9,10.4,3.9,3.3,0,7.1-1.4,9.7-4.6,2.9-3.6,4.2-9.2,2-13-1.7-2.9-4.6-3.1-14.3-5.8-11.9-3.3-11.2-3.9-14.9-4.6-8.7-1.4-15.2,1.3-26.6,5.8-34.6,13.8-30.3,10.3-42.2,16.3-10.2,5.1-17.2,9.7-31.2,18.8-16.2,10.5-24.3,16-30.5,23.4-10.4,12.4-14.1,24.9-14.9,27.9-5.9,20.9-.6,38.8,2.6,48.8,4.6,14.6,11.3,24,24.7,42.9,5,7.1,13.7,19.2,27.3,33.2,15.4,15.9,29.3,25.9,41,34.4,17.5,12.6,14.1,8,25.4,16.9,9,7,16.2,14,21.5,11.7"/>
      <path id="rip7l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M257.95,584.87c-19.7-7.2-40.1-16.6-51.9-22.3-13.4-6.6-22.1-10.7-33.2-18.2-17.3-11.6-29.2-23.1-35.1-29.3-11.5-12-19-22.6-22.5-27.8-7.9-11.9-13.2-19.8-16.5-31.8-1.5-5.8-4.9-17.6-2-31.2,4.2-20.1,18.6-32.4,33.2-44.9,14-12,27.4-18.6,35.8-22.7,10.2-5,17.5-7.4,20.8-8.5,14.4-4.5,24.9-5.1,29.3-5.2,5-.1,7.8-.2,11.7.7,6.9,1.5,8.6,4.1,13.7,3.9,1.3,0,8.4-.6,11-5.8,1.5-3,1.7-7.6-.7-10.4-3.8-4.4-10.2.1-17.6-4.6-4.3-2.7-3.4-5-7.8-7.1-1.5-.8-5.3-2.3-13.7-.7-9.2,1.8-15.8,5.8-16.9,6.5-11.4,7-10.1,3-44.9,18.2-16.9,7.4-25.4,11.1-29.3,13.7-22.4,14.9-32.4,35.4-34.4,39.6-3.8,8-12.6,27-8.5,50,1.8,10.2,7,19.1,17.6,37.1,12.6,21.6,19.3,33,31.9,45.4,5.6,5.6,18.3,14.2,43.6,31.2,28.4,19.2,37.7,24.2,41,25.9,15.8,8.6,27,8.8,36.4,12.4"/>
      <path id="rip6l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M250.75,529.37c-20.4-7.1-40.8-10.7-53.9-15.6-28.4-10.5-34.2-13-41.7-17.6-4.9-2.9-21.4-13.5-37.1-33.2-9.2-11.5-20-25-24.7-46.1-1.8-8.1-5.7-26.6,2-47.4,6.1-16.5,16.4-26.1,28-37.1,15-14.1,29.3-21.4,37.1-25.4,11.8-6,6.7-1.6,37.1-13.7,11-4.4,18.4-7.6,26-4.6,3.4,1.4,5.4,3.9,11,5.2,3.1.8,8.8,2,13-1.3,3.3-2.8,3.8-6.9,3.9-7.8.8-7.6-5.9-13.2-6.5-13.7-4.1-3.4-8.8-3.9-22.1-3.2-13.7.7-20.5,1-28.6,2.6-21.1,4.2-36.7,11.7-42.9,14.9-22.3,11.6-35.9,25.5-39,28.6-12.3,12.7-19.4,25-22.7,31.2-3.5,6.8-8.5,16.4-11.7,30-6.1,26.6,0,48.3,2.6,57.1,3.4,11.8,7.8,19.6,9.1,21.9,3.9,6.8,7.5,11,14.3,19,9.3,10.9,17.2,20,29.9,28.6,6.1,4.1,11.7,7.1,14.3,8.5,7.9,4.1,13.9,6.5,18.8,8.5,19.3,7.7,21.6,9.3,31.2,13,11.4,4.4,30.2,10.6,57.8,15.6"/>
      <path id="rip5l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M251.45,481.27c-14.4-1.2-31.5-4.2-50-10.4-5.8-2-26-9-49.4-24-13.5-8.6-20.3-13-27.3-20.8-18.7-20.7-22.1-45.5-23.4-56.5-1.2-10.5-4.1-36.1,9.7-63,9.8-19,23.2-29.5,37.1-40.3,12.3-9.6,22.2-17.1,37.1-20.1,17.3-3.5,31.2-1.3,31.2-1.3,6.3,1,12.6,1,18.8,2,4.6.7,8.4,1.4,12.4-.7,1.1-.6,4.4-2.6,5.8-6.5,1.7-4.7.1-10.3-3.2-13-3.4-2.9-7-1.3-16.3-1.3-13,0-12.8-3.1-23.4-2.6-5.5.3-5.1,1-21.5,4.6-14.2,3-14.6,2.5-19.5,3.9-2.9.9-10.1,3.2-25.4,14.9-14.2,10.8-24.9,19-35.1,33.7-8.2,11.9-12.3,22.4-14.3,27.9-5.6,15-7.2,27.4-7.8,32.5-1.9,18.1.5,31.9,1.9,40.3,3.2,18.1,6.9,38.7,22.7,56.5,4.9,5.6,12,10.5,26,20.1,4.4,2.9,21.1,14.4,46.1,26.6,10.2,4.9,15.6,7.6,23.4,10.4,19.7,7,37.1,8.7,48.8,9.1"/>
      <path id="rip4l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M245.65,418.97c-8.7.9-21.1,1-35.8-1.9-18.5-3.8-31.3-11-43.6-18.2-17-9.9-28.2-16.3-38.3-29.8-11.1-14.9-14.8-29.8-16.9-39-1.7-7.5-4.6-20.1-2.6-36.4,2.5-21,11.5-35.5,16.9-44.2,9-14.4,18.3-22.3,34.4-36.4,10.3-8.9,16.4-12.7,23.4-15.6,10.5-4.2,20.2-5,22.7-5.2,3.9-.3,7-.2,13,0,8.5.3,12.7.4,16.3,1.3,5.9,1.6,7.5,3.4,11,2.6,3-.8,6.1-3.1,7.1-6.5,1.6-5-2.3-9.7-3.9-11.7-3.4-4.2-7.9-5.9-9.7-6.5-9.6-3-13.4,2.8-26.6,2.6-10.4-.1-10.8-3.7-18.8-2.6-6.8,1-10.4,4.2-19.5,10.4-16.7,11.4-16.1,8.4-26.6,16.3-13.9,10.4-22.3,21.3-25.4,25.4-9.8,13.1-14.4,24.9-17.6,33.2-6.1,15.8-8,28.4-8.5,31.2-2.4,15.8-1.3,27.9-.7,35.7,1.3,15,2.4,26.9,9.1,41,7.1,15,17,24.4,22.1,29.3,7.6,7.2,14.6,11.6,22.7,16.9,11.1,7.1,20.4,11.6,24.7,13.7,3.6,1.7,18.3,8.4,39,13.7,10.5,2.7,23.9,5.2,39.7,6.5"/>
      <path id="partofrip3l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M237.85,275.97c-8.8-.9-23.6-1.7-32.5-2.6"/>
      <path id="rip3l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M205.35,273.47c-2.9-2.1-9.9-3.7-15.6-6.5-7.3-3.5-10.7-3.8-16.3-6.5-7.4-3.5-11.9-8-16.9-13s-12.9-13-18.2-25.9c-2.9-7.1-7.4-18.3-4.6-31.8,2.6-12.3,9.9-20,16.9-27.3,10.1-10.6,20.8-16.2,28.6-20.1,4.8-2.5,9.2-4.3,18.2-7.8,16.4-6.5,20.5-6.8,23.4-6.7,2.2,0,7.1,1.4,16.9,4.2,12.6,3.5,15.4,4.9,17.6,7.8,2.6,3.5,4.2,8.9,2,11.7-1.8,2.2-5.2,1.7-11,1.3-7.1-.6-14.3-.3-21.5-.7-.9,0-13-.6-25.4,1.9-13.8,2.9-23.4,8.6-30.5,13-9.4,5.8-19.8,12.2-28.6,24.7-10.7,15.1-12.6,30.2-13.6,39-1,9-2.6,22.4,3.2,38.3,1.8,4.9,7.3,19.4,21.5,30.5,6.6,5.1,11.6,6.8,30.5,13.7,8.1,2.9,22.4,8,41.7,14.9"/>
      <path id="rip2l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M197.05,244.47c-4.8-.9-19.8-7.2-30.1-17.9-8.3-8.6-11-17.9-12.4-22.7-4.9-17.4-.3-32.1,2-39,1.3-4,6.5-18.5,20.2-31.8,1.4-1.3,23.6-22.4,41-20.8,5.6.6,11.3.2,16.9.7,5.5.4,6.8-.1,8.5-.7,6.8-2.4,12.9-10.4,11-15.6-1-2.9-4.5-4.3-11.4-6.9-3.6-1.4-6.8-2.7-11.3-2.9-5.7-.3-10.1,1.3-13.7,2.6-6.8,2.5-6.6,4.2-13,6.5-6.4,2.2-7.9,1-13.7,3.2-1.2.5-4.4,1.8-10.4,6.5-2.7,2.1-8.1,6.5-17.8,17.3-6.6,7.4-9.9,11.1-12.7,15.8-3.6,6-4.6,10.2-7.8,17.6-10.9,24.7-10.9,24.6-12.4,27.9-7.4,17.3-11,25.9-13.6,35.7-2.1,7.9-5.8,22.3-4.6,41,.7,10,2.2,33,18.2,53.3,6.8,8.6,13.7,13.5,26,22,5.1,3.6,18.7,12.9,38.3,21.5,6.3,2.8,14.5,6.4,24.3,10"/>
      <path id="rip1l" class="linke_rippen_dv_obl_stehend_links1811_cls1" d="M246.95,210.47c-6.3,0-18.5-1-31.2-8.5-18.9-11.2-25.4-29.3-28-36.4-8.7-24.3-2.3-45.5,0-52.7,4.9-15.3,12.6-24.2,21.5-34.4,4.3-4.9,8.5-8.8,14.3-9.7,2.9-.5,3,.4,13.7,1.9,9.6,1.4,11.6,1.1,14.9,3.2,1.3.9,7,4.7,6.5,9.1-.4,2.9-3.2,4.7-5.2,5.9-5.6,3.4-10.2,3-15.6,3.9-2.3.4-12.8,2.1-20.2,9.1-7.4,7.1-8.6,16.7-9.1,20.8-.4,3.1-1.3,12.1,3.2,21.5,3.6,7.3,9,11.6,14.9,16.3,3.8,2.9,10.5,9.6,13,11"/>
      <path id="partofscapula8" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M77.85,188.97c2.1-7.2,4.9-12.3,8.5-18.8,1.7-3,.8-1,12.4-18.8,0,0,1.2-1.9,8.5-11,3.4-4.4,5.1-6.4,7.8-6.5,2.5-.1,4.7,1.5,5.9,3.2,1.7,2.5,1.3,5.3.7,9.1-.6,3.6-1.8,7.3-4.6,12.4-2.5,4.6-4.2,6.6-5.9,9.1-5.4,8.3-6.5,16.7-7.1,21.5-1.1,8.1.6,7.5-.3,19.5-.6,8.1-1.3,8.3-2.3,18.8s-.2,10.6-1.2,18.3c-1,8.5-2.2,9.7-4,20.1-1.3,7.5-1.5,9.9-2.6,20.8-1.3,12.8-2.7,19-3.9,27.3-4,27.9-.6,45.1-5.9,81.2-1.1,7.5-3.1,19.6-10.4,22-.4.1-4.3,1.4-7.1-.7-4.7-3.3-2-12.3-.7-29.3.5-6.7.3-13.4.7-20.1,2.3-41.1,1.6-41,3.9-74,2.7-39.7,3.7-40.3,3.9-55.9.1-5,.5-12.3,1.3-26.6.5-11.9.9-16,2.4-21.6h0Z"/>
      <path id="vertebralbody6" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M328.85,116.17c-1.1-1.8-6.4-1.3-10.4.7-4.9,2.4-5.8,6-9.7,9.7-4.9,4.8-10.8,6-16.3,7.1-14.7,3.1-23-3.3-28,2-1,1.1-1.6,3.6-2.6,8.5-2.3,10.9-3.4,16.3-1.3,19.5,3,4.5,8.7.9,27.3,3.2,10,1.3,14.7,3.1,24.1,2,5.6-.8,9.2-1.1,11.7-3.9,2.5-2.8,2.7-6.2,3-11.4.3-4.7-.2-8.5-1-15.9-1-7.8-1.4-8.3-1-10.7,1.2-6.3,5.4-8.9,4.2-10.8h0Z"/>
      <path id="clavicula" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M51.95,61.07c-3.5-4.2-.1-12,0-12.4,2.4-5.3,7-7.5,11.7-9.7,3.2-1.5,8-3.7,14.3-3.2,2.1.2,6.2.8,10.3,3.2,4,2.4,4.6,4.4,7.8,7.1,4.2,3.6,7.7,4,16.3,6.5,6.4,1.9,12.6,4.5,18.8,6.5,16.7,5.3,39.8,18.2,54.6,26.6,58.1,32.9,54.2,32.1,68.9,39,10.2,4.8,24,10.6,47.5,20.8,8.2,3.5,12.4,5.3,14.3,5.9,9,2.5,15,1.9,17.5,6.3.8,1.2.8,3,.8,6.7,0,4.6,0,9.3-1.9,14.9-1.4,4.1-3.2,9.2-8.5,13-1,.8-6.2,4.5-12.4,3.9-6.5-.6-10.2-5.5-16.9-13,0,0-7.7-8.6-22.1-22-8.7-8.2-16.1-13.1-25.4-19.5-8.2-5.5-12.8-8.1-33.2-19.5-37.7-21.1-36.9-20.9-41-22.7-19.9-9.1-19.4-5.8-42.2-16.3-12.8-5.9-18.6-9.5-32.5-14.3-12.1-4.2-18.1-6.3-25.4-6.5-11-.4-17.9,2.7-21.3-1.3h0Z"/>
      <path id="partofscapula7" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M74.65,285.17c.9-7.6,2.4-19.1,4.6-33.2,2.5-16.1,3.6-20.1,5.2-32.5,2.7-20.7,4-31.1,1.9-39.6-2.5-10-8.4-22.8-11-28.6-4-8.6-6.3-12.3-5.9-18.2.6-7.9,5.5-13.4,10.4-18.8,5.1-5.8,11.1-12.4,19.5-13,4.4-.3,6.3,1.2,11,0,3.9-1,6-2.9,10.4-6.5,10-8.1,15-12.2,19.5-13.6,2.9-1,10.4-3.3,14.9.7,4.4,3.7,4.2,11.3,2,16.3-1.9,4.2-4,3.7-10.4,9.7-7.3,6.9-6.7,9.6-14.3,16.3-2.6,2.2-5.1,4.5-9.1,6.5-6.6,3.3-10.7,2.9-16.9,4.6-4.5,1.1-10.9,3.5-18.2,9.1"/>
      <line id="partofscapula6" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="125.65" y1="167.47" x2="103.85" y2="203.87"/>
      <line id="partofscapula5" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="107.15" y1="153.27" x2="114.75" y2="162.17"/>
      <line id="partofscapula4" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="118.45" y1="216.17" x2="100.15" y2="245.57"/>
      <line id="partofscapula3" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="136.35" y1="157.67" x2="136.05" y2="170.57"/>
      <path id="partofscapula2" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M114.75,162.17c0-2.5,10.9-8.3,21.7-4.5"/>
      <path id="partofscapula1" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M73.75,246.97c-1.7-16.6-5-30.3-8.1-40.4-1.4-4.7-5.1-16.3-11.7-30.5-8.1-17.6-9.2-15.4-14.3-27.9-1-2.6-6.1-15.1-9.1-32.5-.8-4.3-1.9-12.2-1.9-22,0-13.2,2.1-17.5,3.2-19.5,3.6-6.3,9-9,11.7-10.4,4.6-2.3,6.8-3.4,8.5-2.6,4.1,2,3,12,1.9,21.5-1.3,12.3-3,14.2-3.9,21.5-1.3,11.9,2.4,13.1,5.8,35.7,2.1,13.7,1.4,18.6,2.8,24.9.3,1.5.9,3.6,1.8,6.3,3.2,9.4,12.4,20.9,16.1,25.4"/>
      <path id="upperarm1" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M125.35,35.67c-4.9,10.9-5.9,19.9-5.8,25.9.1,6.7,1.3,12.7,3.9,24.7,2.8,12.8,4.1,15,3.9,21.5-.2,4-.5,11.5-5.2,18.8-6.2,9.7-16,13-21.5,14.9-3.9,1.3-12.8,4.3-24.1,2-3.4-.8-10.8-2.4-17.6-8.5-2.3-2.1-6.3-5.7-8.5-11.7-2.3-6.5-.4-10.1-3.9-14.9-2.4-3.3-4.3-2.9-5.8-5.9-2.2-4.1-.8-9,1.3-14.3,7-18.4,10.6-27.7,20.2-35.1,3.7-2.9,7.3-4.8,10.4-9.7,2.9-4.8,3.7-9.6,3.9-13"/>
      <path id="vertebralbody5" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M274.25,752.17c-3.3-3.2-.7-8.6.9-20.8,2.8-21.1-1.5-32.8,3.7-35.2,2.5-1.1,3.8,1.3,11.6,2.4,5.8.8,9,0,14.4-.4,10.3-.7,12.6,1.3,21.4,1.6,20.1.6,29.9-9.5,34.4-4.8,2.4,2.4-.2,5.1-.3,16.3-.1,7.7,1.1,14,3.5,26.6,2.2,11.4,3.4,14.4,1.3,16.9-1.7,2-4.9,3-16.3,1.9-14.7-1.5-16.5-4-27.3-5.2-5.3-.6-13.5-.2-29.9.7-10.6.6-14.6,2.7-17.4,0h0Z"/>
      <line id="partofspine53" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="272.65" y1="628.67" x2="275.95" y2="688.57"/>
      <line id="partofspine52" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="357.45" y1="637.47" x2="361.35" y2="690.47"/>
      <line id="partofspine51" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="352.95" y1="582.57" x2="353.85" y2="601.17"/>
      <line id="partofspine50" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="366.35" y1="762.57" x2="366.35" y2="782.77"/>
      <line id="partofspine49" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="279.25" y1="762.57" x2="274.25" y2="782.77"/>
      <path id="partofspine48" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M279.25,762.57s37.7-5.4,87.1,0"/>
      <path id="partofspine47" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M284.85,752.77c0,2.2,26.5,7.4,64.2,3.9"/>
      <path id="partofspine46" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M353.85,693.77c6.1-1.8-34.1-8-78-5.2"/>
      <path id="partofspine45" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M295.65,698.97c0-4.3,21.4-7.8,47.7-7.8"/>
      <path id="partofspine44" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M272.65,628.67c0,4.8,37.9,8.7,84.8,8.7"/>
      <path id="partofspine43" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M357.45,628.67s-20.2-6.5-76.8,0"/>
      <path id="connectiontorip21" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M271.65,746.37c-38.4-25.7,1.1-39.6,2.7-39.6"/>
      <path id="connectiontorip20" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M264.35,746.37c-87.2-19.8-3.7-32.5-8.3-32.5"/>
      <path id="connectiontorip19" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M249.55,658.67c-22,5.3-26.3,22,12.6,23.9"/>
      <path id="connectiontorip18" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M271.65,646.77c-53.9,20.9,1.9,41.8,4.3,41.8"/>
      <path id="rip12r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M413.95,668.47c-71.8-105-109.8-62-9.1,22.4"/>
      <path id="rip11r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M409.45,594.27c-99.5-115-102.7-35.3-4.1,25.9"/>
      <path id="partofspine42" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M280.65,616.47c0,5.2,29.9,9.4,66.7,9.4"/>
      <path id="partofspine41" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M343.55,621.07c0-2.7-28.2-4.8-62.9-4.8"/>
      <path id="partofspine40" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M274.25,578.07c0,2.2,32.5,8.5,74.7,3.9"/>
      <path id="partofspine39" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M347.35,578.87c0-2.6-24.5-7.3-68.1-4.8"/>
      <path id="partofspine38" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M284.85,563.37c0,1.7,24.6,3,55.1,3"/>
      <path id="partofspine37" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M336.65,554.07c0,2.6-22.3,1.5-51.8,9.3"/>
      <path id="partofspine36" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M284.85,524.17c0,2.5,31.3,8.5,62.5,4.6"/>
      <path id="partofspine35" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M284.85,524.17c0-1.4,37.9-5.9,62.5-2.6"/>
      <line id="partofspine34" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="353.45" y1="618.77" x2="353.45" y2="609.87"/>
      <path id="rip10r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M406.15,559.87c-7.7-9.4-14.7-16.8-20.2-22,0,0-11.8-11.6-27.3-21.5-6-3.8-9.1-5.1-13-5.2-4.6-.1-7.4,1.7-9.1,0-1-1-.8-4,0-9.7.7-5.3,1-4.8,1.1-7.8.3-4.8-.5-6.2.9-7.8,1.4-1.9,4.4-2.3,6.5-1.9,3.6.6,5.4,3.6,7.8,6.5,2.8,3.3,2.2,1.7,13,11,8.7,7.6,12.8,11.9,18.2,16.9,6,5.6,14.9,13.2,27.3,21.5"/>
      <path id="rip9r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M395.15,490.47c-7.9-5.3-13.9-10.1-17.9-13.4-4.9-4.2-7.1-6.5-16-13.9-10.3-8.7-12.7-10.2-16.3-11-3.7-.9-6.1-.5-10.4-2.6-2.7-1.3-6.6-3.2-7.8-7.1-.9-2.9.1-5.3,1.9-9.7,1.8-4.4,3-7.4,5.8-8.5,2.9-1,6.2.9,9.7,3.2,8.7,5.8,14.3,11.3,17.6,14.3,5.3,4.8,4.9,3.4,22.7,16.3,7.5,5.3,13.5,9.9,17.6,13"/>
      <path id="rip8r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M393.85,429.27c-9.6-8.2-18.9-15.8-28-22.7-13.7-10.5-18-13.1-24.1-13.7-4.5-.4-7.9.5-12.4-1.9-7.2-3.9-9-11.9-9.1-12.4-.4-1.7-1.9-9.2,2.6-13,3.6-3,8.9-1.8,11-1.3,5,1.1,7.9,4,11.7,7.1,2.9,2.4-.6-.8,17.6,11.7,10.6,7.2,12.6,8.8,21.5,14.9,5.8,3.9,10.6,7.1,13.7,9.1"/>
      <path id="rip7r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M399.75,374.07c-14.7-10-25.7-18.5-33.2-24.7-8.7-7.2-13.5-11.9-22.1-14.3-16.2-4.6-16.3-4.6-16.3-4.6-4.8-.9-7.7-1-9.1-3.2-1.7-2.8-.3-7.1,1.9-9.7,2.9-3.4,7.4-3.7,10.4-3.9,5.3-.3,10.4,1,17.6,4.6,6.7,3.2,7.1,4.5,20.2,13,7.8,5.1,9.7,6.1,18.2,11.7,4.5,2.9,8.1,5.5,10.4,7.1"/>
      <path id="rip6r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M396.45,322.77c-13.9-10.5-24.3-18.5-31.2-24-16.2-12.8-17-8.5-24.1-14.3-1.8-1.5-4.8-4.1-9.1-4.6-4.9-.5-6.7,2.3-9.7,1.3-5.7-1.7-9.9-14.2-5.2-19.5,2.6-2.9,6.9-2.8,11.7-2.6,8.9.4,15.8,3.4,19.5,5.2,2.3,1.1,8.5,4.3,24.1,16.9,9.5,7.7,10.9,9.5,18.8,15.6,6.5,4.9,11.9,8.6,15.6,11"/>
      <path id="rip5r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M404.85,273.47c-9.4-6.8-18-12.9-25.4-18.2-34.2-24.2-13.6-8.5-23.4-15.6-5.3-3.9-8.1-6.1-13-7.1-5.5-1.1-7.7.6-15.6-.7-5.8-.9-9.5-1.4-11.7-4.6-2.6-3.7-2.1-9.4.7-13,2.6-3.3,6.5-3.9,11-4.6,8.7-1.2,15.8,1,18.2,2,6.1,2.3,8.6,4.8,16.3,10.4,1.4,1,8.6,5.7,22.7,14.9,5.7,3.7,14.2,9.1,24.7,15.6"/>
      <path id="rip4r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M400.35,226.57c-8-8.4-15.5-14.4-21.5-18.8-10-7.4-16.1-11.8-25.4-14.9-13.5-4.6-17.6-5.2-17.6-5.2-5.8-1-8.9-.7-11-3.2-3.3-4.3-.3-11.7,0-12.4.7-1.6,2.1-4.8,5.2-6.5,4.5-2.3,9.8.1,14.9,2.6,18.9,9.2,24.7,13.7,24.7,13.7,13.1,10.1,25.3,19.7,37.1,29.3"/>
      <path id="rip3r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M400.95,175.97c-10.4-9.4-19.6-16.7-26.6-22-14.6-10.9-19.4-13-24.7-12.4-8.1,1-12.6,7.5-16.3,5.2-2.4-1.4-.9-4.2-3.9-13-2-5.7-3.5-7.3-2.6-9.7,1.5-4,7.6-5,8.5-5.2,6.3-1,11.7,2.3,16.9,5.9,10.1,6.8,15.1,10.3,22.1,16.9,7,6.7,17.5,15.6,32.5,24.7"/>
      <path id="rip2r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M396.45,141.57c-10.2-5.8-16-12.4-19.5-17.6-4.8-6.9-6.5-13.3-13-15.6-5-1.7-10.4-.4-11.7,0-7.1,2-8.9,6.7-13,5.9-3.4-.7-6.4-4.8-5.8-7.8.5-2.9,3.8-2.5,6.5-6.5,2.6-3.8.8-5.9,3.2-10.4.6-1.1,3.2-5.9,7.1-6.5,3.7-.6,6.4,3,13,9.7,0,0,8.2,8.3,20.2,17.6,3,2.4,7.9,5.9,14.3,9.7"/>
      <path id="rip1r" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M407.55,116.17c-13.7-10.3-20.1-20.4-23.4-27.9-3.6-8.1-7.5-16-11-24-3-7-5.2-12.3-8.5-12.4-2.2,0-2.8,2.4-8.5,8.5-5.2,5.6-6.4,5.2-9.1,9.1-2.3,3.1-6.3,8.7-4.6,13,2.3,5.4,13.1,5.7,13,6.5-.1.6-5.9.1-5.8,0,0-.2,11.8-2.1,22.1,3.2,3.3,1.7,5.7,4,10.4,8.5,5.9,5.6,10.3,10.7,13,14.3"/>
      <path id="partofrip9" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M396.45,141.97c-26.2,10.5-14.9,43.7,13,31.4"/>
      <path id="vertebralbody4" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M268.35,85.67c-1.4-2.7,1.3-4.8,2-11,.7-6.8-2.4-8.8-.7-12.4,2.1-4.5,8.8-5.3,12.4-5.8,7.6-1,9.4,1.6,20.2,1.9,1.3,0,.6,0,13.7-.7,12-.6,15-.7,16.3,1.3,1.3,2.2-1.5,4-1.9,9.7-.6,7.3,3.7,10.4,1.9,14.3-.4.8-1.6,3.2-9.7,5.2-8.7,2.1-11.7.4-23.4.7-7.1.2-12.6,1-22.1,0-4.3-.4-7.4-.9-8.7-3.2h0Z"/>
      <path id="connectiontorip17" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M262.05,598.87c7,7.6,0,15.5,0,15.5"/>
      <path id="connectiontorip16" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M267.05,579.37c-32.5,37.7,3.2,41.7,7.1,41.7"/>
      <path id="connectiontorip15" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M283.15,527.07c10,16.4-3.3,32.8-2.5,32.8"/>
      <path id="connectiontorip14" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M279.15,530.07c-37.9-15.6-60.8,48-7.5,29.9"/>
      <path id="partofspine33" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M274.25,606.57c0,5.5,2.9,9.9,6.4,9.9"/>
      <path id="vertebralbody3" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M332.75,470.27c-4.6-.4-11.3-.7-19.5,0-11,1-12.6,2.7-20.8,2.6-11.4-.1-18.9-3.7-20.2-1.3-.8,1.5,2,3,3.2,7.8,1,4,.1,7.4-1.3,12.4-3,10.5-6.7,12.7-5.2,16.3,1.7,4.1,8.2,4.9,9.7,5.2,4.9.7,7.5-1.1,14.3-2.6,3.8-.9,7.3-1,14.3-1.3,6.3-.3,14.5-.3,24.1.7"/>
      <path id="partofspine32" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M287.85,472.67c0,1.9,21.5,3.5,48,3.5"/>
      <path id="partofspine31" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M287.85,511.87c0,3.5,24.3,6.4,54.1,6.4"/>
      <line id="partofspine30" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="337.65" y1="466.27" x2="342.05" y2="483.77"/>
      <path id="partofspine29" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M281.55,462.77c4.6,6.6,34.8,8.7,53.8-5.1"/>
      <path id="partofspine28" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M283.85,422.57c0-5.6,19.1-10.2,42.8-10.2"/>
      <path id="vertebralbody2" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M266.45,425.77c2.3-3.2,10.8-3.7,28-4.6,13.3-.7,15.2,0,23.4-1.3,5.2-.9,10.3-2.8,11-1.3.8,1.3-3.2,3.3-5.1,8.1-3.3,8.2,2.2,17.8,3.1,19.5,4.1,7.2,9.3,9.5,8.5,11.4-.9,1.9-6.4.1-14.3,0-10.6-.1-19.1,2.2-29.3,4.8-14.3,3.8-18.3,6.8-21.5,4.3-2.9-2.4-1.1-6.2-2-18.2-.9-15.6-4.6-18.6-1.8-22.7h0Z"/>
      <line id="partofspine27" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="326.55" y1="382.57" x2="326.55" y2="407.77"/>
      <line id="partofspine26" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="263.65" y1="413.37" x2="300.25" y2="414.57"/>
      <line id="partofspine25" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="259.65" y1="389.87" x2="263.65" y2="413.37"/>
      <line id="partofspine24" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="320.15" y1="370.77" x2="263.15" y2="376.77"/>
      <line id="partofspine23" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="259.45" y1="365.97" x2="323.15" y2="360.47"/>
      <line id="partofspine22" class="linke_rippen_dv_obl_stehend_links1811_cls3" x1="257.85" y1="327.07" x2="318.35" y2="322.97"/>
      <path id="partofspine21" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M257.85,327.07c-3.5,22.5-1.3,39.5,1.8,38.9"/>
      <path id="connectiontorip13" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M307.35,310.47c-6.1,23.6.8,34,16,24.7"/>
      <path id="connectiontorip12" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M323.35,335.17c-.7,14.4,1.1,22.1,3.2,20.8"/>
      <path id="connectiontorip11" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M237.15,473.27c10.3,14.5-1.9,20.8-4.3,20.8"/>
      <path id="connectiontorip10" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M228.05,495.07c-14.9-4.7-.6-22.7-1.3-22.7"/>
      <path id="connectiontorip9" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M217.65,422.87c-11.4,17.6-3,29.7,20.2,26.6"/>
      <path id="partofspine20" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M259.65,226.57c0,22.8-1.6,41.3-3.6,41.3"/>
      <path id="partofspine19" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M261.65,222.67c0,4.6,16.5,15.7,56.7,8.2"/>
      <path id="partofspine18" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M256.05,267.97h60"/>
      <path id="partofspine17" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M318.35,230.87c0,17-.6,30.8-1.1,30.8"/>
      <path id="partofspine16" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M261.65,222.67c0-1.8,21.5,0,52.7-3.2"/>
      <path id="partofspine15" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M261.65,175.97c0,21.6-2.5,39-5.6,39"/>
      <path id="partofspine14" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M256.05,214.87c0,2.5,26.1,4.6,58.3,4.6"/>
      <path id="partofspine13" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M323.25,176.17c0,21.1-3,38.2-6.8,38.2"/>
      <path id="partofspine12" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M265.45,175.97c0,.1,25.9.1,57.8.1"/>
      <path id="partofspine11" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M266.05,170.57c0-6.4,26.3-11.5,58.9-11.5"/>
      <path id="connectiontorip8" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M258.65,415.07c-6.9-3.5-12.7-4.4-16.9-4.6-9.8-.3-13.6,3.5-21.5,1.3-2-.6-9-2.6-11.7-9.1-2.5-6-.2-13.7,4.6-16.9,2.4-1.6,6.4-1.5,14.3-1.3,8.7.3,10.2,1.1,14.3.7,3.6-.4,8.8-1.8,14.9-6.5"/>
      <path id="connectiontorip7" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M317.75,365.97c-27.8-2.9-15.7,30.9,4,16.6"/>
      <path id="connectiontorip6" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M251.45,355.97c-2.6-1.8-6.8-4.3-12.4-5.2-4.3-.7-6.8-.1-19.5,1.3-11.4,1.3-13.1,1.2-14.9,0-4.7-3.1-6.6-11.6-3.2-16.9,2.1-3.3,5.5-4.5,7.8-5.2,2.9-1,5.9-1.2,18.8.7,3.5.5,6.5,1,8.5,1.3"/>
      <path id="partofspine10" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M271.65,325.07c0-4.1,22.1-7.4,49.5-7.4"/>
      <path id="partofspine9" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M256.05,267.97c-9.3-13.2-2.7-36.9,3.6-41.3"/>
      <path id="vertebralbody1" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M312.65,314.97c-9.1-.1-16.5.3-21.5.7-13.2,1-21.5,1.9-21.5,1.9-12.7,1.4-16,2.3-18.2,0-1.8-1.9-1-4,0-15.6,1.3-14.6.7-17.8,3.9-21.5.5-.5,3.8-4.3,9.1-5.2,5.3-1,7.6,1.9,14.9,3.2,3.4.7,7,.5,14.3,0,13.6-.8,18.4-2.9,20.8,0,1.6,1.8.8,4,.7,9.1-.2,6.9,1,13,2.6,18.2"/>
      <path id="partofspine8" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M268.05,275.27c0-4.1,21.5-7.3,48.1-7.3"/>
      <path id="partofrip8" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M322.35,281.27c-38.2,13-17.1-22.1-6.3-13.2"/>
      <path id="partofrip7" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M221.05,274.67c0,7.5-3.7,13.7-8.4,13.7"/>
      <path id="partofrip6" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M205.35,273.47c-22,17.5,7.3,26.6,29.3,20.8"/>
      <path id="partofspine5" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M261.65,175.97c-10.2,16.7-3.5,34.4-7.8,34.4"/>
      <path id="partofspine4" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M317.15,209.37c0-18.4,2.8-33.3,6.1-33.3"/>
      <path id="partofspine3" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M332.45,51.97c42.2,4.3,1,16.7,2.2,16.7"/>
      <path id="partofspine2" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M315.85,57.77c0,8.4,4.9,15,10.7,15"/>
      <path id="partofspine1" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M324.95,81.07c-13.6,16.3-1.8,22.5-3.9,22.5"/>
      <path id="partofrip5" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M237.15,70.77c0,11.5,4.4,20.8,9.9,20.8"/>
      <path id="connectiontorip5" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M250.45,89.37c0,4.4,6.9,8,15.6,8"/>
      <path id="connectiontorip4" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M252.15,73.97c0-1.8,8.7-3.2,19.5-3.2"/>
      <path id="partofrip4" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M231.25,133.77c-31.6,5.8-.6,18.3-1.3,18.3"/>
      <path id="partofrip3" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M209.95,192.07c-12.4-22.8,6.2-34.4,20.1-27.9"/>
      <path id="partofrip2" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M216.85,241.47c8.8-3.7,3.9-14.7,8.8-14.7"/>
      <path id="partofrip1" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M200.75,241.47c-3.2-7.4,0-27.1,9.8-18.6"/>
      <path id="connectiontorip3" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M242.15,195.47c0,4,5.2,7.1,11.7,7.1"/>
      <path id="connectiontorip2" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M259.65,122.77c0,6.6-5.6,11.9-12.6,11.9"/>
      <path id="connectiontorip1" class="linke_rippen_dv_obl_stehend_links1811_cls3" d="M247.05,110.27c0-.3,6.7-.6,14.8-.6"/>
      <path id="soft" class="linke_rippen_dv_obl_stehend_links1811_cls2" d="M112.15,11.97c-15,12-51.98,3.39-63,5-13.65,1.99-23.26,3.39-30,9-10.66,8.86-10.37,25.08-8,59,2.69,38.43,4.04,57.65,6,69,7,40.46,11.23,81.35,17,122,4.1,28.93,6.74,69.62,12,151,6.55,101.23,3.96,91.62,10,185,1.03,15.89,3.35,50.92,4,98,.29,21.19.16,38.59,0,50"/>
    </g>
  </svg>
  );
}
