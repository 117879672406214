import style from "./style.scss";

function Rechter4FingerLatSitzend1326(props) {
  return (
    <svg
      id="rechterer4fingerlat"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 232.43 489.18"
    >
      <g id="Highlights">
        <g id="white_Background" data-name="white Background">
          <path
            class="Rechter_Finger4_lat_sitzend1326_cls4"
            d="M231.95,487.95h0c-5.69-16.57-14.13-30.78-25.96-42.1-4.16-3.98-8.04-8.25-11.45-12.89-13.95-19.05-25.29-39.86-31.55-64.1-12.1-55.77-23.31-99.7-32.35-114.53-1.48-35.43-11.11-73.44-22.33-111.95-9.41-35.83-14.33-78.68-16.23-126.26-.17-4.18-2.12-8.12-5.44-10.66C81.29,1.36,73.96,0,65.16.66c-19.68,3.74-30.01,20.24-34.63,44.51l-5.77,77.92c-4.11,26.48-2.81,55.92,3.8,88.25-1.29,50.72,1.18,94.88,10.03,127.87L1.46,313.76l.16,29.81,1.14,19.82-2.51,19.82c28.15,18.08,55.04,42.49,79.29,80.14,8.87,12.56,17.24,19.38,24.96,18.89l17.62-1.44,85.22,8.2,24.61-1.06h0Z"
          />
        </g>
        <g id="finger">
          <path
            id="basephalanx"
            class="FullFinger1326Fill"
            style={{
              fill: props.colors.FullFinger1326Fill,
              stroke: props.colors.FullFinger1326Fill,
              opacity: props.colors.FullFinger1326Fill,
            }}
            d="M97.06,368.96c-1.48-24.74-6.18-46.85-14.58-65.92-3.47-9.66-9.24-18.04-19.75-24.61-8.64-8.28-9.99-18.21,0-30.68,10.23-7.82,22.01-11.7,36.46-8.81,9.72.87,15.16,10.99,14.89,33.42,11.46,28.47,21.92,60.32,31.59,94.78,3.03,8.55,9.28,26.15,14.58,41.09,6.89,14.74,6.81,25.84-3.46,31.64-1.63.92-3.52,1.24-5.4,1.15-8.96-.46-16.58,3.85-23.73,8.22-17.64,5.88-30.14,3.79-31.97-14.81,2.23-23.52,3.32-45.95,1.37-65.47h0Z"
          />
          <path
            id="middlephalanx"
            class="FullFinger1326Fill"
            style={{
              fill: props.colors.FullFinger1326Fill,
              stroke: props.colors.FullFinger1326Fill,
              opacity: props.colors.FullFinger1326Fill,
            }}
            d="M113.76,234.99h.01c6.23-1.7,3.92-8.83-2.43-18.53-7.9-36.91-15-76.7-25.52-98.73.2-8.43-3.53-14.73-11.85-18.53-7.52-1.7-14.5-.38-20.05,9.11-4.67,6.43-2.18,12.6,7.29,18.53,5.22,9.1,8.68,20.72,10.33,34.94-2.09,6.38-2.79,18-1.82,35.85-.45,8.91-2.63,16.94-8.51,23.09.5,14.06,4.75,19.62,11.54,19.46,11.74-5.99,23.29-6.2,34.63,0,4.18,2.79,5.92.21,6.38-5.18h0Z"
          />
          <path
            id="endphalanx"
            class="FullFinger1326Fill"
            style={{
              fill: props.colors.FullFinger1326Fill,
              stroke: props.colors.FullFinger1326Fill,
              opacity: props.colors.FullFinger1326Fill,
            }}
            d="M77.31,94.48h0c4.76,5.61,7.85,5.27,9.57,0,1.8-4.06.19-8.64-2.35-13.37V23.76c0-1.29-.46-2.56-1.36-3.49-2.27-2.34-5.33-2.96-9.29-1.59-1.37.47-2.56,1.36-3.43,2.51-5.25,6.97-8.28,14.11-4.23,21.8-2.4,10.13-5.9,19.6-11.54,27.8-4.64,6.58-6.71,12.52-2.29,16.86.66,7.58,2.39,10.84,5.94,6.84,5.95-3.12,12.3-2.96,18.99,0h-.01Z"
          />
        </g>
        <g id="groundjointhighlight">
          <ellipse
            class="Grundgelenk1326Fill"
            style={{
              fill: props.colors.Grundgelenk1326Fill,
              stroke: props.colors.Grundgelenk1326Fill,
              opacity: props.colors.Grundgelenk1326Fill,
            }}
            cx="137.9"
            cy="453.93"
            rx="61.33"
            ry="15.15"
            transform="translate(-186.42 107.69) rotate(-26.23)"
          />
        </g>
        <g id="basejointhighlight">
          <ellipse
            class="Gelenkspalte1326Fill"
            style={{
              fill: props.colors.Gelenkspalte1326Fill,
              stroke: props.colors.Gelenkspalte1326Fill,
              opacity: props.colors.Gelenkspalte1326Fill,
            }}
            cx="89.19"
            cy="241.22"
            rx="35.38"
            ry="8.61"
          />
        </g>
        <g id="middlejointhighlight">
          <ellipse
            class="Gelenkspalte1326Fill"
            style={{
              fill: props.colors.Gelenkspalte1326Fill,
              stroke: props.colors.Gelenkspalte1326Fill,
              opacity: props.colors.Gelenkspalte1326Fill,
            }}
            cx="69.3"
            cy="96.07"
            rx="20.85"
            ry="6.85"
          />
        </g>
        <path
          id="fingertiphighlight"
          class="Fingerkuppe1326Fill"
          style={{
            fill: props.colors.Fingerkuppe1326Fill,
            stroke: props.colors.Fingerkuppe1326Fill,
            opacity: props.colors.Fingerkuppe1326Fill,
          }}
          d="M65.17,36.71c.07-5.63,3.98-16.9,9.96-18.34,3.08-.78,5.21-.62,6.62.27,2.42,1.07,3.28,3.9,3.4,6.39-3.52-5.19-12.3.1-19.99,11.68h.01Z"
        />
      </g>
      <g id="rechterer4fingerlat1">
        <line
          id="basephalanx5"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          x1="151.29"
          y1="433.19"
          x2="118.04"
          y2="442.99"
        />
        <path
          id="basephalanx4"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M148.1,426.35c-9.11.15-18.23,2.43-27.34,6.84"
        />
        <path
          id="basephalanx3"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M86.96,280.61c12.3,21.05,21.45,50.67,27.27,89.32,2.2,19.28.68,37.52-6.68,54.15"
        />
        <path
          id="basephalanx2"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M109.52,270.43c2.74,26.65,10.74,59.74,22.33,97.22,6.26,18.98,15.94,32.45,29.01,40.48"
        />
        <path
          id="basephalanx1"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M109.52,257.67c-1.27-8.28-5.44-14.46-16.71-15.49-10.32-.18-18.9,3.33-22.94,16.41-2.78,9.43.29,15.65,8.35,19.14,4.46,2.03,6.53,5.16,6.92,10.48"
        />
        <path
          id="basephalanx-2"
          data-name="basephalanx"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M97.67,368.86c-1.48-24.74-6.18-46.85-14.58-65.92-3.47-9.66-9.24-18.04-19.75-24.61-8.64-8.28-9.99-18.21,0-30.68,10.23-7.82,22.01-11.7,36.46-8.81,9.72.87,15.16,10.99,14.89,33.42,11.46,28.47,21.92,60.32,31.59,94.78,3.03,8.55,9.28,26.15,14.58,41.09,6.89,14.74,6.81,25.84-3.46,31.64-1.63.92-3.52,1.24-5.4,1.15-8.96-.46-16.58,3.85-23.73,8.22-17.64,5.88-30.14,3.79-31.97-14.81,2.23-23.52,3.32-45.95,1.37-65.47h0Z"
        />
        <path
          id="middlephalanx5"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M104.5,226.08c-9.77.82-19,1.21-27.34.91"
        />
        <path
          id="middlephalanx4"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M102.38,233.06c-11.03-2.55-21.43-3.19-30.84-.76"
        />
        <path
          id="middlephalanx3"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M111.04,218.94c-8.58-7.32-17.2-29.5-25.89-81.87-1.34-7.72-3.57-27.49-3.57-27.49"
        />
        <path
          id="middlephalanx2"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M56.34,109.57c.66,5.37,4.27,9.66,11.55,12.61,8.29,11.04,12.69,25.37,14.28,42.08,2.6,20.39-1.97,37.13-10.63,51.8"
        />
        <path
          id="middlephalanx1"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M74.88,196.3c.9-12.86.27-24.6-2.73-34.63"
        />
        <path
          id="middlephalanx-2"
          data-name="middlephalanx"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M114.37,234.89h.01c6.23-1.7,3.92-8.83-2.43-18.53-7.9-36.91-15-76.7-25.52-98.73.2-8.43-3.53-14.73-11.85-18.53-7.52-1.7-14.5-.38-20.05,9.11-4.67,6.43-2.18,12.6,7.29,18.53,5.22,9.1,8.68,20.72,10.33,34.94-2.09,6.38-2.79,18-1.82,35.85-.45,8.91-2.63,16.94-8.51,23.09.5,14.06,4.75,19.62,11.54,19.46,11.74-5.99,23.29-6.2,34.63,0,4.18,2.79,5.92.21,6.38-5.18h0Z"
        />
        <path
          id="endphalanx4"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M77.16,90.13c-5.77-2.43-11.54-2.43-17.32,0"
        />
        <path
          id="endphalanx3"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M85.14,81.01c-4.51-2.38-7.49-9.73-5.7-32.51,1.32-6.51-.53-14.73,1.52-22.48"
        />
        <line
          id="endphalanx1"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          x1="70.33"
          y1="34.38"
          x2="66.83"
          y2="42.89"
        />
        <path
          id="endphalanx2"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M73.97,35.14c-.04,17.31-5.07,32.42-17.63,44.2"
        />
        <path
          id="endphalanx-2"
          data-name="endphalanx"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M77.92,94.38h0c4.76,5.61,7.85,5.27,9.57,0,1.8-4.06.19-8.64-2.35-13.37V23.66c0-1.29-.46-2.56-1.36-3.49-2.27-2.34-5.33-2.96-9.29-1.59-1.37.47-2.56,1.36-3.43,2.51-5.25,6.97-8.28,14.11-4.23,21.8-2.4,10.13-5.9,19.6-11.54,27.8-4.64,6.58-6.71,12.52-2.29,16.86.66,7.58,2.39,10.84,5.94,6.84,5.95-3.12,12.3-2.96,18.99,0h-.01Z"
        />
        <g id="softpart">
          <path
            id="softpart3"
            class="Rechter_Finger4_lat_sitzend1326_cls7"
            d="M50.55,369.92c-.96-1.71-1.88-3.48-2.77-5.32"
          />
          <path
            id="softpart2"
            class="Rechter_Finger4_lat_sitzend1326_cls6"
            d="M43.16,353.71c-12.58-33.97-16.09-83.79-14.61-142.36-6.6-32.33-7.91-61.77-3.8-88.25l5.77-77.92C35.15,20.9,45.47,4.4,65.16.66,73.96,0,81.29,1.36,86.63,5.46c3.32,2.54,5.27,6.48,5.44,10.66,1.9,47.58,6.81,90.43,16.23,126.26,11.21,38.51,20.85,76.52,22.33,111.95,9.04,14.83,20.26,58.76,32.35,114.53,6.27,24.24,17.61,45.05,31.55,64.1,3.4,4.65,7.28,8.91,11.45,12.89,9.1,8.7,16.2,19.12,21.58,31"
          />
          <path
            id="softpart1"
            class="Rechter_Finger4_lat_sitzend1326_cls7"
            d="M229.87,482.32c.73,1.85,1.42,3.72,2.08,5.63"
          />
        </g>
        <path
          id="nextfinger2"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M2.76,372.74c25.2,18.96,49.11,37.42,61.97,51.72,9.52,10.17,14.1,23.36,14.81,38.9"
        />
        <path
          id="nextfinger1"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M2.76,363.39c30.12,20.1,59.04,38.97,76.78,46.48,11.5,5.68,22.61,5.99,33.59,4.56"
        />
        <path
          id="nextfinger"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M1.62,343.57l79.26,48.6,48.79,22.94c7.95,5.66,9.28,13.71,6.46,23.24-.52,1.75-1.5,3.34-2.86,4.55-7.54,6.68-12.05,14.46-13.16,23.5-.26,2.09-.95,4.1-2.08,5.87-9.5,14.76-21.27,12.68-34.9-3.15-22.62-37.36-50.35-65.82-82.87-85.9"
        />
        <g id="softpart_next_finger" data-name="softpart next finger">
          <path
            id="softpart_next_finger3"
            data-name="softpart next finger3"
            class="Rechter_Finger4_lat_sitzend1326_cls7"
            d="M88.89,379.46c-1.49-1.33-2.99-2.65-4.5-3.96"
          />
          <path
            id="softpart_next_finger2"
            data-name="softpart next finger2"
            class="Rechter_Finger4_lat_sitzend1326_cls6"
            d="M76.16,368.46c-20.38-17.1-42.25-33.13-65.16-48.42"
          />
          <path
            id="softpart_next_finger1"
            data-name="softpart next finger1"
            class="Rechter_Finger4_lat_sitzend1326_cls7"
            d="M6.48,317.04c-1.67-1.1-3.34-2.19-5.02-3.29"
          />
        </g>
        <path
          id="middlehand"
          class="Rechter_Finger4_lat_sitzend1326_cls7"
          d="M207.34,489.01c2.88-7.95.2-17.03-12.99-28.25-16.23-13.79-33.52-19.02-52.41-11.62-11.45,6.84-19.03,16.65-19.82,31.67"
        />
      </g>
      <g id="basejointhighlight-2" data-name="basejointhighlight">
        <ellipse
          class="Gelenkspalte1326Fill"
          style={{
            fill: props.colors.Gelenkspalte1326Fill,
            stroke: props.colors.Gelenkspalte1326Fill,
            opacity: props.colors.Gelenkspalte1326Fill,
          }}
          cx="137.83"
          cy="448.55"
          rx="35.45"
          ry="8.38"
          transform="translate(-176.12 99.8) rotate(-24.91)"
        />
      </g>
    </svg>
  );
}

export default Rechter4FingerLatSitzend1326;
