import { useHistory } from "react-router-dom";
import { EXAM_QA_SINGLE } from "../../../../Pages/Consts/ExamHelperRoutes";

import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import ExamsSection from "../ExamsSection";
import SingleQaSection from "../ExamQa/SingleQaSection";

function ExamHelperApp(props) {
  const { push } = useHistory();
  const selectedExam = Number(props.match.params.examId) || null;
  const setSelectedExam = (examId) => {
    push(`${EXAM_QA_SINGLE.link}/${examId}`);
  };
  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection
          selectedExam={selectedExam}
          selectedExamSetter={setSelectedExam}
        />
      }
      rightComponent={<SingleQaSection selectedExamId={selectedExam} />}
    />
  );
}

export default ExamHelperApp;
