import React from "react";
import { MakeGetObject } from "../Utilities/AuthHeader";
import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import ClipLoader from "react-spinners/ClipLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Container,
  Col,
  Row,
  Button,
  Tooltip,
  OverlayTrigger,
  DropdownButton,
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { setOpenedPatient, clearOpenedPatient } from "../Redux/actions";
import "./worklistBox.css";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import useWindowSize from "../Utilities/Window/windowSize";

export default function WorklistBox(props) {
  const { keycloak } = useKeycloak();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [worklist, setWorklist] = useState([]);
  const [loadedElements, setLoadedElements] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loadCount, setLoadCount] = useState(1);
  const [selectedWorklistEntry, setSelectedWorklistEntry] = useState(null);
  const [wlStatusSelected, setWlStatusSelected] = useState(undefined);
  const [wlSubStatusSelected, setWlSubStatusSelected] = useState(undefined);
  const [worklistFilter, setWorklistFilter] = useState(undefined);
  const [clearLoad, setClearLoad] = useState(false);

  useEffect(() => {
    if (!dataLoaded) {
      fetchData();
    }
  }, [props]);

  const fetchData = () => {
    const paginate = () => {
      let res = "";
      if (clearLoad) {
        res = "?page_size=30&page_number=" + 1;
        setLoadCount(2);
      } else {
        res = "?page_size=30&page_number=" + loadCount;
        setLoadCount(loadCount + 1);
      }
      return res;
    };

    const filter = () => {
      if (worklistFilter) {
        return `&search=${encodeURIComponent(JSON.stringify(worklistFilter))}`;
      } else {
        return "";
      }
    };

    setDataLoading(true);

    let url = `${
      window.conf.SERVER_CONFIG.BASE_URL
    }/ris/worklist${paginate()}${filter()}`;

    fetch(url, MakeGetObject(keycloak))
      .then((response) => response.json())
      .then((data) => {
        setDataLoading(false);
        if (clearLoad) {
          setClearLoad(false);
          if (data.worklist_entries) {
            setWorklist([...data.worklist_entries]);
            setLoadedElements(data.worklist_entries.length);
          } else {
            setWorklist([]);
            setLoadedElements(0);
          }
        } else {
          if (data.worklist_entries) {
            setWorklist(worklist.concat(data.worklist_entries));
            setLoadedElements(worklist.length);
          } else {
            setHasMore(false);
          }
        }
        setDataLoaded(true);
      });
  };

  const dispatch = useDispatch();
  const selectedPatients = useSelector(
    (state) => state.examManager.openedPatients
  );

  const selectEntry = (event, wl_entry) => {
    dispatch(clearOpenedPatient());
    setSelectedWorklistEntry(null);
    if (!selectedPatients.includes(wl_entry.patient.id)) {
      dispatch(setOpenedPatient(wl_entry.patient.id));
      setSelectedWorklistEntry(wl_entry.id);
    }
  };

  const getWorklistDescription = (wl_entry) => {
    try {
      return wl_entry.worklistqmauftraege_collection[0].studydescription;
    } catch {
      return "No Description";
    }
  };

  const getWorklistQmId = (wl_entry) => {
    try {
      return wl_entry.worklistqmauftraege_collection[0].id;
    } catch {
      return;
    }
  };

  const validateEntry = (wl_entry) => {
    //return true
    return (
      wl_entry.worklistqmauftraege_collection.length > 0 &&
      wl_entry.workliststudy_collection.length > 0
    );
  };

  const loadWorklistEntryToPmView = (wl_entry) => {
    let ids = [];
    wl_entry["workliststudy_collection"].forEach((element) => {
      ids.push(element.study.studyinstanceuid);
    });
    if (ids.length > 0) {
      try {
        fetch(
          `http://127.0.0.1:8989/api/ViewControl/openStdById/${ids.join(",")}`,
          MakeGetObject(keycloak)
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(`Unable to load image to pmView, ${response}`);
            }
          })
          .then((data) => {
            console.info(
              `Examination with stdUids ${ids.join(",")} loaded in pmView`
            );
          })
          .catch((error) => {
            console.warn(error);
          });
      } catch (exception) {
        console.warn(exception);
      }
    } else {
      console.warn("No ids to load study");
    }
  };

  const loadStdEntryToPmView = (wl_study) => {
    if (wl_study.id) {
      try {
        fetch(
          `http://127.0.0.1:8989/api/ViewControl/openStdById/${wl_study.studyinstanceuid}`,
          MakeGetObject(keycloak)
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(`Unable to load image to pmView, ${response}`);
            }
          })
          .then((data) => {
            console.info(
              `Examination with stdUid ${wl_study.studyinstanceuid} loaded in pmView`
            );
          })
          .catch((error) => {
            console.warn(error);
          });
      } catch (exception) {
        console.warn(exception);
      }
    } else {
      console.warn(`Failed to load study ${wl_study.studyinstanceuid}`);
    }
  };

  const worklistStatuses = [
    {
      status: "Order",
      subStatuses: [
        {
          status: "To do on AQS",
          filterValue: {
            model: "Worklist",
            field: "untersuchung",
            op: "eq",
            value: 0,
          },
        },
        {
          status: "In progress on AQS",
          filterValue: {
            model: "Worklist",
            field: "untersuchung",
            op: "eq",
            value: 1,
          },
        },
        {
          status: "Recieved from AQS",
          filterValue: {
            model: "Worklist",
            field: "untersuchung",
            op: "eq",
            value: 10,
          },
        },
      ],
    },
    {
      status: "Finding",
      filterValue: "",
      subStatuses: [
        {
          status: "No finding",
          filterValue: {
            model: "Worklist",
            field: "befunden",
            op: "eq",
            value: -1,
          },
        },
        {
          status: "Ready to perform finding",
          filterValue: {
            model: "Worklist",
            field: "befunden",
            op: "eq",
            value: 0,
          },
        },
        {
          status: "Finding in progress",
          filterValue: {
            model: "Worklist",
            field: "befunden",
            op: "eq",
            value: 1,
          },
        },
        {
          status: "Finding finished",
          filterValue: {
            model: "Worklist",
            field: "befunden",
            op: "eq",
            value: 10,
          },
        },
      ],
    },
    {
      status: "Verification",
      subStatuses: [
        {
          status: "No verification",
          filterValue: {
            model: "Worklist",
            field: "befschreiben",
            op: "eq",
            value: -1,
          },
        },
        {
          status: "To Verification",
          filterValue: {
            model: "Worklist",
            field: "befschreiben",
            op: "eq",
            value: 0,
          },
        },
        {
          status: "Needs corrections",
          filterValue: {
            model: "Worklist",
            field: "befschreiben",
            op: "eq",
            value: 3,
          },
        },
        {
          status: "Verified",
          filterValue: {
            model: "Worklist",
            field: "befschreiben",
            op: "eq",
            value: 10,
          },
        },
      ],
    },
    {
      status: "Distribution",
      subStatuses: [
        {
          status: "No distribution",
          filterValue: {
            and: [
              {
                model: "Worklist",
                field: "untersfolienprint",
                op: "eq",
                value: -1,
              },
              {
                model: "Worklist",
                field: "befdrucken",
                op: "eq",
                value: -1,
              },
              {
                model: "Worklist",
                field: "brennen",
                op: "eq",
                value: -1,
              },
            ],
          },
        },
        {
          status: "CD to burn",
          filterValue: {
            model: "Worklist",
            field: "brennen",
            op: "eq",
            value: 0,
          },
        },
        {
          status: "CD burned",
          filterValue: {
            model: "Worklist",
            field: "brennen",
            op: "eq",
            value: 10,
          },
        },
        {
          status: "To foil print",
          filterValue: {
            model: "Worklist",
            field: "untersfolienprint",
            op: "eq",
            value: 0,
          },
        },
        {
          status: "Foil printed",
          filterValue: {
            model: "Worklist",
            field: "untersfolienprint",
            op: "eq",
            value: 10,
          },
        },
        {
          status: "To print",
          filterValue: {
            model: "Worklist",
            field: "befdrucken",
            op: "eq",
            value: 0,
          },
        },
        {
          status: "Printed",
          filterValue: {
            model: "Worklist",
            field: "befdrucken",
            op: "eq",
            value: 10,
          },
        },
      ],
    },
    {
      status: "Archive",
      subStatuses: [
        {
          status: "Exam + Finding",
          filterValue: {
            and: [
              {
                model: "Worklist",
                field: "untersuchung",
                op: "eq",
                value: 10,
              },
              {
                model: "Worklist",
                field: "befunden",
                op: "eq",
                value: 10,
              },
            ],
          },
        },
      ],
    },
  ];

  useEffect(() => {
    fetchData();
  }, [worklistFilter]);

  const subStatusClicked = (parentStatus, subStatus) => {
    // debugger;
    if (subStatus.filterValue && wlSubStatusSelected !== subStatus.status) {
      setWlStatusSelected(parentStatus.status);
      setWlSubStatusSelected(subStatus.status);
      setWorklistFilter(subStatus.filterValue);
      setClearLoad(true);
    } else {
      setWlStatusSelected(undefined);
      setWlSubStatusSelected(undefined);
      setWorklistFilter(undefined);
      setClearLoad(true);
    }
  };

  const { width } = useWindowSize();

  return (
    <div
      className="d-flex flex-column bg-light rounded"
      style={{
        flex: 1,
        maxHeight: width > 1199 ? "99vh" : "89vh",
        overflowY: "auto",
      }}
    >
      <Row className="pt-3 pb-2 mx-auto border-bottom ">
        <div className="mx-0 ml-2 px-0 d-flex align-items-center flex-wrap">
          {worklistStatuses.map((status, i) => (
            <React.Fragment key={`wrapper${i}`}>
              {status.subStatuses.length > 0 ? (
                <DropdownButton
                  className="flex-child mr-1"
                  key={`ststuskey${i}`}
                  title={status.status}
                  size="sm"
                  variant={
                    status.status === wlStatusSelected ? "primary" : "secondary"
                  }
                >
                  {status.subStatuses.map((subStatus, j) => (
                    <DropdownItem
                      key={`substatus${j}`}
                      onClick={() => subStatusClicked(status, subStatus)}
                    >
                      {subStatus.status === wlSubStatusSelected
                        ? `${subStatus.status} ✓`
                        : `${subStatus.status}`}
                    </DropdownItem>
                  ))}
                </DropdownButton>
              ) : (
                <Button
                  className="flex-child mr-1"
                  key={i}
                  size="sm"
                  variant="secondary"
                >
                  {status.status}
                </Button>
              )}
              {i + 1 !== worklistStatuses.length && (
                <i className="fas fa-arrow-right fa-sm mr-1"></i>
              )}
            </React.Fragment>
          ))}
        </div>
      </Row>
      <div
        className="mx-0 pr-1 big-scrollable-area container"
        id="worklistScroll"
      >
        {!dataLoaded
          ? dataLoading && (
              <div className="text-center">
                <h4 className="text-muted">Loading Worklist ..</h4>
                <ClipLoader
                  color="#ff880d"
                  loading={true}
                  css={true}
                  size={100}
                />
              </div>
            )
          : worklist.length === 0 && (
              <div className="text-center">
                <h4 className="text-muted">No Worklist data</h4>
              </div>
            )}
        {dataLoaded && (
          <InfiniteScroll
            dataLength={loadedElements}
            scrollableTarget="worklistScroll"
            scrollThreshold={0.75}
            next={fetchData}
            hasMore={hasMore}
            endMessage={<h5 className="text-muted">Nothing more to load</h5>}
            loader={
              <div className="text-center">
                <ClipLoader
                  color="#ff880d"
                  loading={true}
                  css={true}
                  size={30}
                />
              </div>
            }
          >
            {worklist.map(
              (wl_entry, index) =>
                validateEntry(wl_entry) && (
                  <Container
                    fluid
                    className={`bordered-row mb-1 mx-0 my-0 px-0 py-0 mt-1 ${
                      selectedWorklistEntry === wl_entry.id
                        ? "active-wl-entry"
                        : ""
                    }`}
                    key={`wlindex${index}`}
                  >
                    <Row
                      className="mx-0 my-0 px-0 py-0"
                      onClick={(event) => selectEntry(event, wl_entry)}
                    >
                      <Col xs={1} sm={1} md={1} lg={1} className="my-auto">
                        <i className="fas fa-file-medical fa-lg"></i>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} className="my-auto">
                        {getWorklistDescription(wl_entry)}
                      </Col>
                      <Col xs={4} sm={4} md={4} lg={4}>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          {wl_entry.patient.patientsname}
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          {wl_entry.patient.patientsvorname}
                        </Col>
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1} className="my-auto pl-0">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 400, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Load examination to image viewer
                            </Tooltip>
                          }
                        >
                          <Button
                            onClick={() => loadWorklistEntryToPmView(wl_entry)}
                            size="sm"
                            className="py-0"
                            variant="outline-secondary"
                          >
                            <i className="fas fa-chevron-right"></i>
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                    <Container
                      fluid
                      className={`mx-0 my-0 px-0 py-0 border-top ${
                        selectedWorklistEntry === wl_entry.id
                          ? "shown-subrow"
                          : "hidden-subrow"
                      }`}
                      key={getWorklistQmId}
                    >
                      {wl_entry.workliststudy_collection.map((wl_std, key) => {
                        return (
                          <Row
                            className={"subrow ml-3 "}
                            key={`wlentrykey${key}`}
                            onClick={() => loadStdEntryToPmView(wl_std.study)}
                          >
                            <Col
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              className="my-auto unselectable"
                            >
                              <i className="fas fa-x-ray fa-lg"></i>
                            </Col>
                            <Col
                              xs={11}
                              sm={11}
                              md={11}
                              lg={11}
                              className="my-auto unselectable"
                            >
                              {wl_std.study.studydescription}
                            </Col>
                          </Row>
                        );
                      })}
                    </Container>
                  </Container>
                )
            )}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
}
