import style from "./style.scss";

function LinkeZeheDplOblSitzend1413(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.74 176.57">
      <g id="third_toe" data-name="third toe">
        <path
          id="third_toe_1"
          data-name="third toe 1"
          class="a1413_Linke_3Zehe_dpl_obl_sitzend_cls_3"
          style={{
            fill: props.colors.a1413_Linke_3Zehe_dpl_obl_sitzend_cls_3,
            stroke: props.colors.a1413_Linke_3Zehe_dpl_obl_sitzend_cls_3,
          }}
          d="M10.79,173.24c.1.58.22,1.16.36,1.75.76-.33,1.62-.51,2.57-.51,6.2,0,12.16-.3,18-1.89-.08-1.18-.16-2.36-.23-3.54,4.3-7.5,2.5-14.8-3.4-22.1,4.5-6.5,1-9.5-9-10.1-13.7-1.9-14.7,6.2-10.5,18.4-4.8,6.2-2.7,12.2,2.2,18h0Z"
        />
        <path
          id="third_toe_2"
          data-name="third toe 2"
          class="a1413_Linke_3Zehe_dpl_obl_sitzend_cls_1"
          style={{
            fill: props.colors.a1413_Linke_3Zehe_dpl_obl_sitzend_cls_1,
            stroke: props.colors.a1413_Linke_3Zehe_dpl_obl_sitzend_cls_1,
          }}
          d="M31.25,174.04c-.07-1.04-.14-2.08-.2-3.12,4.3-7.5,2.5-14.8-3.4-22.1,4.5-6.5,1-9.5-9-10.1-13.7-1.9-14.7,6.2-10.5,18.4-4.8,6.2-2.7,12.2,2.2,18,.08.44.17.89.27,1.34"
        />
        <path
          id="third_toe_3"
          data-name="third toe 3"
          class="a1413_Linke_3Zehe_dpl_obl_sitzend_cls_2"
          style={{
            fill: props.colors.a1413_Linke_3Zehe_dpl_obl_sitzend_cls_2,
            stroke: props.colors.a1413_Linke_3Zehe_dpl_obl_sitzend_cls_2,
          }}
          d="M12.85,133.82c-11.9,4.6-12-2.1-4.9-15.8,10.4-14.3,12.4-27.2,7.5-39-4.9-9.5-4.7-16.2,7.1-16.5,9.3-3,17.8-4.7,13.9,10.5-6.3,22.3-9.6,41.3-1.9,48.8,2.5,13.9.3,19-7.5,13.5-5.8-3.4-10.3-3.4-14.2-1.5h0Z"
        />
        <path
          id="third_toe_4"
          data-name="third toe 4"
          class="a1413_Linke_3Zehe_dpl_obl_sitzend_cls_2"
          style={{
            fill: props.colors.a1413_Linke_3Zehe_dpl_obl_sitzend_cls_2,
            stroke: props.colors.a1413_Linke_3Zehe_dpl_obl_sitzend_cls_2,
          }}
          d="M18.45,61.02c-9.4,2.7-10.4-2.9-7.9-12-4.9-11.2-4.4-20,8.6-23.2,9.4-.3,14.3,2.1,11.6,9.4,0,5.1.9,10.7,3,16.9,2.3,7.1-.9,8.6-7.1,7.1l-8.2,1.8h0Z"
        />
        <path
          id="third_toe_5"
          data-name="third toe 5"
          class="a1413_Linke_3Zehe_dpl_obl_sitzend_cls_2"
          style={{
            fill: props.colors.a1413_Linke_3Zehe_dpl_obl_sitzend_cls_2,
            stroke: props.colors.a1413_Linke_3Zehe_dpl_obl_sitzend_cls_2,
          }}
          d="M5.65,13.02C-.05,6.32-.05,1.92,12.45,2.52c5.4-5.3,7.8.5,9.4,9.4,6.5,5.5,12.3,10.8,1.9,10.5l-15.8,3.8c-7.6,1.7-6.2-4.6-2.3-13.2h0Z"
        />
      </g>
      <g id="overlays">
        <g id="Phalangen_overlay" data-name="Phalangen overlay">
          <path
            id="Phalangen_overlay_1"
            data-name="Phalangen overlay 1"
            class="a1413_phalangenFill"
            style={{
              fill: props.colors.a1413_phalangenFill,
              stroke: props.colors.a1413_phalangenFill,
            }}
            d="M13.12,133.82c-11.38,4.6-11.47-2.1-4.68-15.8,9.94-14.3,11.85-27.2,7.17-39-4.68-9.5-4.49-16.2,6.79-16.5,8.89-3,17.02-4.7,13.29,10.5-6.02,22.3-9.18,41.3-1.82,48.8,2.39,13.9.29,19-7.17,13.5-5.54-3.4-9.85-3.4-13.57-1.5h0Z"
          />
          <path
            id="Phalangen_overlay_2"
            data-name="Phalangen overlay 2"
            class="a1413_phalangenFill"
            style={{
              fill: props.colors.a1413_phalangenFill,
              stroke: props.colors.a1413_phalangenFill,
            }}
            d="M18.48,61.02c-8.99,2.7-9.94-2.9-7.55-12-4.68-11.2-4.21-20,8.22-23.2,8.99-.3,13.67,2.1,11.09,9.4,0,5.1.86,10.7,2.87,16.9,2.2,7.1-.86,8.6-6.79,7.1l-7.84,1.8h0Z"
          />
          <path
            id="Phalangen_overlay_3"
            data-name="Phalangen overlay 3"
            class="a1413_phalangenFill"
            style={{
              fill: props.colors.a1413_phalangenFill,
              stroke: props.colors.a1413_phalangenFill,
            }}
            d="M6.24,13.02C.79,6.32.79,1.92,12.74,2.52c5.16-5.3,7.46.5,8.99,9.4,6.21,5.5,11.76,10.8,1.82,10.5l-15.1,3.8c-7.27,1.7-5.93-4.6-2.2-13.2h0Z"
          />
        </g>
        <g id="joint_overlay" data-name="joint overlay">
          <ellipse
            id="joint_overlay_1"
            data-name="joint overlay 1"
            class="a1413_gelenkeFill"
            style={{
              fill: props.colors.a1413_gelenkeFill,
              stroke: props.colors.a1413_gelenkeFilll,
            }}
            cx="17.7"
            cy="25.97"
            rx="15.72"
            ry="6.49"
            transform="translate(-4.26 3.49) rotate(-10.05)"
          />
          <ellipse
            id="joint_overlay_2"
            data-name="joint overlay 2"
            class="a1413_gelenkeFill"
            style={{
              fill: props.colors.a1413_gelenkeFill,
              stroke: props.colors.a1413_gelenkeFilll,
            }}
            cx="24.28"
            cy="63.08"
            rx="16.59"
            ry="5.97"
            transform="translate(-8.09 3.74) rotate(-7.56)"
          />
          <ellipse
            id="joint_overlay_2-2"
            data-name="joint overlay 2"
            class="a1413_gelenkeFill"
            style={{
              fill: props.colors.a1413_gelenkeFill,
              stroke: props.colors.a1413_gelenkeFilll,
            }}
            cx="17.7"
            cy="137.31"
            rx="6.84"
            ry="17.72"
            transform="translate(-120.34 147.88) rotate(-87.04)"
          />
        </g>
      </g>
    </svg>
  );
}

export default LinkeZeheDplOblSitzend1413;
