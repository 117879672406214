import style from "./style.scss";

export default function RechterOberschenkelMitKnieLatLiegend2346(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 651.8 904.5"
    >
      <g id="fullknee">
        <g id="knee_with_upper_leg" data-name="knee with upper leg">
          <g id="background_layer" data-name="background layer">
            <path
              id="lower_leg_background"
              data-name="lower leg background"
              class="oberschenkel_mit_knie_lat_right2346_cls7"
              d="M286.9,769.2h0l-31.5,133.4-109.3-19.2c13.8-63.1,23.4-121.8,14.1-160.9v-30.7c-1.3-12.4,3.1-22.3,13.2-29.7,3.6-12.5,8.2-19.9,14.6-18.9,6.8-1.3,5.2-9.1,1.4-18.6-2.4-8.1,0-15,5.8-20.7l9.1-19.9c12.5-15.6,29.3-19.9,49.6-15.3,6.9,4,13.4,6.7,19.3,6.9.3,1.5,1.7,2.4,3.3,2.3,2.3,0,4.8-1,6.4-2.6.4-.4.8-.9,1-1.5,12.7,0,25.2.5,36.4,7.4.9.6,1.9.6,2.7.3,2.8,1.8,5.5,3.7,8.2,5.5,1.7,1.4,3.4,2.8,5.1,4.2,1.4,1.1,3,.7,4-.4l19.5,24.4,7.9,13.7v21.8c.6,27.9-4.2,50.3-15.1,66.4-13,17.9-20.7,46-22.3,86-1.5,33.9.6,65.3,8.1,93.1l-58.3-3.5,17.3-120.8-2.6-26.1-7.8,23.2h0Z"
            />
            <path
              id="upper_leg_background"
              data-name="upper leg background"
              class="oberschenkel_mit_knie_lat_right2346_cls7"
              d="M287.7,7c0,25-44.7,343.7-55.9,360.5-5.3,8-8.3,11.4-25.2,35.5-11,15.7-16.6,23.5-17.7,26-12.5,25.9-8,52.3-6.5,60.2,1,5.6,5.1,25.4,20.3,45.6,24,32.1,57.4,41.1,67.9,43.7,21.5,5.4,37.9,3.1,41.8,2.5,5.2-.9,13.3-2.1,22.8-6.6,3.2-1.6,15.4-7.7,26.3-21.1,6.2-7.7,14.1-17.5,15.1-32.1.2-3.1.8-14-5-25.2-3.4-6.5-6.7-9-12.5-15.6,0,0-9.8-11-18.5-27.3-5.3-10-8.3-24.5,3.9-72.2,9.3-36.5,49.7-371.3,50.8-373.9"
            />
            <path
              id="patella_background"
              data-name="patella background"
              class="oberschenkel_mit_knie_lat_right2346_cls7"
              d="M127.2,500.7h0c-2.7,9.1-.5,16.7,2.8,28,5.8,19.8,14.6,32.3,16.9,35.5,6,8.3,15.5,21.3,22.6,19.3,2.9-.8,2.7-3.5,8.3-14.5,7.5-14.8,11.7-17.5,12.3-26.6.2-3.9-.4-6.9-1-10-1.8-9.2-4.8-11.8-9-22.7-1.1-3.2-3.4-9-4.9-17-1.6-8-.8-10-2.7-19.5-2.2-10.9-4.3-13.5-6.3-15-.5-.4-4.8-3.6-9.7-2.7-7.3,1.4-8.3,10.5-19.8,29.3-6.5,10.6-7.9,10.6-9.5,15.8h0Z"
            />
          </g>
          <g id="lower_leg" data-name="lower leg">
            <path
              id="fibula"
              class="oberschenkel_mit_knie_lat_right2346_cls3"
              d="M280.2,895.2c.9-4.9,2.2-12.2,3.6-21,1.6-9.7,2.1-14.5,4.6-32.8,2.9-21.3,2.9-20.6,4.6-33.7,2.3-18,3.4-27,3.6-31,.4-9.6-.6-20.1-2.7-41-1.9-19.6-2.9-29.4-3.6-34.6-2-14.9-3.1-17.6-1.8-23.7,3.1-14.8,14.6-23.6,17.3-25.5,2-1.4,9.9-6.8,21-8.2,4.5-.6,4.7,0,22.8,1.8,13,1.2,14.5,1,16.4,2.7,1.4,1.2,4.2,4.6.9,27.3-2.3,15.9-3.4,23.9-8.2,33.7-4.8,9.7-7.2,9.4-12.8,20-5.6,10.6-7.6,19.5-10,30.1-3.9,17.3-4.7,30.6-5.5,46.5-.9,17.3-1.6,30.1,0,47.4,1.6,18,5,33.2,8.2,44.7"
            />
            <path
              id="tibia_2"
              data-name="tibia 2"
              class="oberschenkel_mit_knie_lat_right2346_cls3"
              d="M146.6,885c4.9-22.3,8.5-41.2,10.9-55.6,2.9-17,4.4-28.4,5.5-39.2,2-19.8,1.8-30.1,1.8-32.8-.2-11.8-1.3-22.3-1.8-26.4-1.2-11.2-.4-22.5-1.8-33.7-.3-2.1-.9-6.8,0-12.8.3-2.6,1-7,3.6-11.6,3.1-5.7,5.2-4.7,9.1-10.3,5.2-7.5,3.2-11.6,8.2-15.5,4.4-3.4,7.1-1.1,9.6-3.9,5.3-5.9-6.2-17.2-1.4-29.8,1.8-4.8,3.7-4.1,8.2-12.8,3.7-7.2,2.9-9,5.5-13.7,5.3-9.7,15.3-13.9,19.1-15.5,2.4-1,12.7-5.1,25.5-2.7,11.6,2.1,12.1,7.2,22.8,8.2,9.3.9,10.3-2.9,21-2.7,9.3.2,16.1,3.1,23.7,6.4,11.6,5,20.4,8.8,23.7,17.3,2.1,5.6-.2,7.9,2.7,11.9,4.6,6.4,12.4,2.8,19.1,8.2,8.4,6.8,6.9,21.4,6.4,27.3-1,13.2-7.8,22.4-10.9,26.4-6.4,8.3-10.3,8.6-21.9,19.1-9.6,8.8-15.8,16.7-19.1,21-3.7,4.8-14.2,18.9-22.8,40.1-2.2,5.5-4.4,11.7-10.9,39.2-6.2,25.7-8.9,39.7-12.8,57.4-2.9,13-7.4,32.1-13.7,55.6"
            />
            <path
              id="tibia_1"
              data-name="tibia 1"
              class="oberschenkel_mit_knie_lat_right2346_cls3"
              d="M160.7,690.9c7.3,0,13.2-12.7,13.2-28.3"
            />
            <polyline
              id="tibia_3"
              data-name="tibia 3"
              class="oberschenkel_mit_knie_lat_right2346_cls3"
              points="339.1 590.6 361.6 617.9 367.9 630.6 363.4 647.1 354.3 665.3 339.1 676.7 319.7 690.8 305.1 717.2 297.7 741.8"
            />
            <path
              id="tibia_4"
              data-name="tibia 4"
              class="oberschenkel_mit_knie_lat_right2346_cls2"
              d="M173.8,756.5c18.6-11,39.3-79.9,23.7-109.4"
            />
            <path
              id="tibia_5"
              data-name="tibia 5"
              class="oberschenkel_mit_knie_lat_right2346_cls2"
              d="M185.7,701.7c-4,0-7.3,18-7.3,40.1"
            />
            <path
              id="tibia_6"
              data-name="tibia 6"
              class="oberschenkel_mit_knie_lat_right2346_cls2"
              d="M193,666.2c-4,0-7.3,15.9-7.3,35.5"
            />
            <path
              id="tibia_7"
              data-name="tibia 7"
              class="oberschenkel_mit_knie_lat_right2346_cls2"
              d="M309.7,601.5c-43.9,0-79.4-11-79.4-24.6"
            />
          </g>
          <g id="upper_leg" data-name="upper leg">
            <path
              id="tibia_1-2"
              data-name="tibia 1-2"
              class="oberschenkel_mit_knie_lat_right2346_cls3"
              d="M287.7,0c0,25-44.7,350.7-55.9,367.5-5.3,8-8.3,11.4-25.2,35.5-11,15.7-16.6,23.5-17.7,26-12.5,25.9-8,52.3-6.5,60.2,1,5.6,5.1,25.4,20.3,45.6,24,32.1,57.4,41.1,67.9,43.7,21.5,5.4,37.9,3.1,41.8,2.5,5.2-.9,13.3-2.1,22.8-6.6,3.2-1.6,15.4-7.7,26.3-21.1,6.2-7.7,14.1-17.5,15.1-32.1.2-3.1.8-14-5-25.2-3.4-6.5-6.7-9-12.5-15.6,0,0-9.8-11-18.5-27.3-5.3-10-8.3-24.5,3.9-72.2,9.3-36.5,49.7-371.3,50.8-373.9"
            />
            <path
              id="tibia_2-2"
              data-name="tibia 2-2"
              class="oberschenkel_mit_knie_lat_right2346_cls3"
              d="M335.1,574.4c-22.8-.9-50.8-6-63.1-10.1-9.7-3.3-22.7-7.8-37.6-17.7-9.8-6.5-25.1-16.8-36.3-36.3-4.7-8.1-13.9-24.3-12-45.3,1.6-16.4,9-28.5,12-33,8-12.6,14.8-13.7,22.2-26.1,7.2-12.1,9.5-30.6,11.3-38.3"
            />
            <path
              id="partella_1"
              data-name="partella 1"
              class="oberschenkel_mit_knie_lat_right2346_cls3"
              d="M169.4,583.7h0c3.5-.9,3.2-4.5,10.4-19,6.9-13.5,9.5-15.2,10.2-22.2.4-5.5-.7-9.5-2.8-16.5-3.6-12.3-7.1-13.1-10.3-24.4-1.2-4.4-1.9-9.1-3.2-18.5-1.4-10.3-1.4-14.9-4.9-20.6-1.9-3.1-4.8-8-8.9-8.1-4.6-.1-7.4,6-14.9,17.9-13.3,21.1-16.1,20.2-18,28.5-2.2,9.1-.4,16.1,2.8,28.2,1.7,6.6,6,20.6,16.9,35.5,6.4,8.8,15.6,21.2,22.6,19.3h0Z"
            />
            <polyline
              id="patella_2"
              data-name="patella 2"
              class="oberschenkel_mit_knie_lat_right2346_cls3"
              points="160.1 454.4 166 483.5 170.4 502.1 179 519.9 190.1 542.5"
            />
          </g>
        </g>
        <g id="overlays">
          <path
            id="partella_overlay"
            data-name="partella overlay"
            class="Patella2346Fill"
            style={{
              fill: props.colors.Patella2346Fill,
              stroke: props.colors.Patella2346Fill,
            }}
            d="M169.4,584.2h0c3.5-.9,3.2-4.5,10.4-19,6.9-13.5,9.5-15.2,10.2-22.2.4-5.5-.7-9.5-2.8-16.5-3.6-12.3-7.1-13.1-10.3-24.4-1.2-4.4-1.9-9.1-3.2-18.5-1.4-10.3-1.4-14.9-4.9-20.6-1.9-3.1-4.8-8-8.9-8.1-4.6-.1-7.4,6-14.9,17.9-13.3,21.1-16.1,20.2-18,28.5-2.2,9.1-.4,16.1,2.8,28.2,1.7,6.6,6,20.6,16.9,35.5,6.4,8.8,15.6,21.2,22.6,19.3h0Z"
          />
          <path
            id="upper_leg_shaft_overlay"
            data-name="upper leg shaft overlay"
            class="Oberschenkelschaft2346Fill"
            style={{
              fill: props.colors.Oberschenkelschaft2346Fill,
              stroke: props.colors.Oberschenkelschaft2346Fill,
            }}
            d="M287.7,0l107.4,7c-20.3,177.7-43.6,331-55.6,387l-107.7-26.5C256,245.8,274.8,123.3,287.7,0Z"
          />
          <ellipse
            id="knee_joint_overlay"
            data-name="knee joint overlay"
            class="Kniegelenk2346Fill"
            style={{
              fill: props.colors.Kniegelenk2346Fill,
              stroke: props.colors.Kniegelenk2346Fill,
            }}
            cx="265.7"
            cy="566.9"
            rx="39"
            ry="92"
            transform="translate(-335.8 740.9) rotate(-81)"
          />
        </g>
      </g>
      <g id="outlines">
        <path
          id="outlinepart2"
          class="oberschenkel_mit_knie_lat_right2346_cls1"
          d="M629.5,0c-43.9,136.9-96.2,253-159.2,383-25.9,100.7-16.7,194.6-1.5,231.1,10.7,51.6,56.8,182.4,47.5,288.5"
        />
        <path
          id="outlinepart1"
          class="oberschenkel_mit_knie_lat_right2346_cls1"
          d="M82.5,7.6c21.6,157.9,48.8,278.5,25.7,364.1-22.1,81.6-44.7,163.7.2,259.9,36.7,78.6,5.1,219.1,5.5,271.6"
        />
      </g>
      <g id="outlinehighlights">
        <path
          id="outlinehighlight2"
          class="Weichteilmantel2346Fill"
          style={{
            fill: props.colors.Weichteilmantel2346Fill,
            stroke: props.colors.Weichteilmantel2346Fill,
          }}
          d="M630.3-.3c-43.9,136.9-96.2,253-159.2,383-25.9,100.7-16.7,194.6-1.5,231.1,10.7,51.6,55.4,185.7,46.1,291.8h9.8c9.3-106.2-36.7-237-47.5-288.5-15.3-36.4-24.5-130.4,1.5-231.1C542.5,256,594.8,136.9,638.7,0l-8.4-.3Z"
        />
        <path
          id="outlinehighlight1"
          class="Weichteilmantel2346Fill"
          style={{
            fill: props.colors.Weichteilmantel2346Fill,
            stroke: props.colors.Weichteilmantel2346Fill,
          }}
          d="M106.7,904.5c-.4-52.5,31.5-195.4-5.2-274.1-44.9-96.2-22.3-178.3-.2-259.9,23.1-85.6-4-209.7-25.7-367.5h6.9c21.6,157.9,49.6,282.8,26.4,368.4-22.1,81.6-44.7,163.7.2,259.9,36.7,78.6,5.1,219.1,5.5,271.6l-8,1.6Z"
        />
      </g>
    </svg>
  );
}
