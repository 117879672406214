import { useMemo, useRef } from "react";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";
import {
  exportComponentAsJPEG,
  exportComponentAsPNG,
} from "react-component-export-image";
import { useHistory } from "react-router-dom";

import styles from "./SingleQaSection.module.scss";
import useExamData from "../../Hooks/useExamData";
import { EXAM_QA_SINGLE } from "../../../../Pages/Consts/ExamHelperRoutes";
import ExamHelperContentTab from "../../../../Blueprints/ExamHelper/ImageSwitchingTab/ImageSwitchingTab";

export default function CompleteQaSection({ selectedExamId }) {
  const { push } = useHistory();
  const { data, ExmImg, isError, exam } = useExamData(selectedExamId, 0);
  const colors = useMemo(() => {
    if (!data) return {};
    const colors = {};
    data.forEach((qaEntry) => {
      if (qaEntry.altColors) {
        Object.assign(colors, qaEntry.altColors);
      } else {
        Object.assign(colors, qaEntry.colors);
      }
    });
    return colors;
  }, [data]);
  const toExportRef = useRef();
  const exportName = `${exam} QaCriteria`;
  const getExportSettings = (extension) => {
    console.log(toExportRef.current);
    return {
      pdfOptions: { w: 1200, h: 900, unit: "px", pdfFormat: "a4" },
      html2CanvasOptions: {
        logging: false,
        scale: 1,
        ignoreElements: (element) => {
          if (element.dataset.noprint) {
            return true;
          }
          return false;
        },
      },
      fileName: exportName + extension,
    };
  };

  const TitleBar = ({ title }) => (
    <div className={styles.Heading}>
      <DropdownButton
        data-noprint={true}
        size="sm"
        title="Download"
        id="bg-vertical-dropdown-3"
        className={styles.dropdown}
      >
        <Dropdown.Item
          onClick={() =>
            exportComponentAsJPEG(toExportRef, getExportSettings(".jpeg"))
          }
        >
          JPEG
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            exportComponentAsPNG(toExportRef, getExportSettings(".png"))
          }
        >
          PNG
        </Dropdown.Item>
      </DropdownButton>
      <h3>{title}</h3>
      {data?.length > 1 && (
        <Button
          data-noprint={true}
          onClick={() => push(`${EXAM_QA_SINGLE.link}/${selectedExamId}`)}
          size="sm"
        >
          Schritt für Schritt <i className="ml-2 fas fa-shoe-prints" />
        </Button>
      )}
    </div>
  );

  const LegendSection = () => {
    return (
      <div className="bg-white rounded" style={{ color: "black" }}>
        {data.map((entry, index) => (
          <div
            key={`entry_${index}_${entry.examid}`}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 5fr",
              justifyItems: "left",
              alignItems: "center",
              borderBottom:
                index !== data.length - 1 ? "1px solid #e0e0e0" : "none",
              padding: "5px",
            }}
          >
            <div className="d-flex">
              {Object.values(
                entry.altColors ? entry.altColors : entry.colors
              ).map((color) => (
                <div
                  key={`color_${color}`}
                  style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: color,
                  }}
                />
              ))}
            </div>
            <div>{entry.qaAnatomyCriteria}</div>
          </div>
        ))}
      </div>
    );
  };

  const { title, clrs, legend } = useMemo(() => {
    let res = {
      title: <h3>Please select an examination</h3>,
      clrs: null,
      legend: <></>,
    };
    if (!isError && selectedExamId > 0 && data.length > 0) {
      res = {
        title: <TitleBar title={exam} />,
        clrs: <ExmImg colors={colors} />,
        legend: <LegendSection />,
      };
    }
    return res;
  }, [selectedExamId, exam, data]);

  return (
    <div ref={toExportRef}>
      <ExamHelperContentTab title={title} image={clrs} description={legend} />
    </div>
  );
}
