import { useMemo } from "react";

function XrayIcon({ variant = "dark" }) {
  const color = useMemo(() => {
    switch (variant) {
      case "dark":
        return "#1D1D1B";
      case "light":
        return "#e0e0e0";
      case "danger":
        return "#ff0000";
      case "warning":
        return "#ff9900";
      case "success":
        return "#009900";
      default:
        return "#1D1D1B";
    }
  }, [variant]);

  const styles = {
    svg: {
      enableBackground: "new 0 0 100 120",
    },
    st0: {
      fill: null,
      stroke: color,
      strokeWidth: 2.5,
      strokeMiterlimit: 10,
    },
    st1: {
      fill: color,
      stroke: color,
      strokeWidth: 2,
      strokeMiterlimit: 10,
    },
    st2: {
      fill: null,
      stroke: color,
      strokeWidth: 2,
      strokeMiterlimit: 10,
    },
    st3: {
      fill: null,
      stroke: color,
      strokeWidth: 3,
      strokeMiterlimit: 10,
    },
  };

  return (
    <svg
      version="1.1"
      id="radiation_sign"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 120"
      style={styles.svg}
    >
      <circle style={styles.st0} cx="47" cy="39.1" r="28.5" />
      <circle style={styles.st1} cx="46.8" cy="39.6" r="4" />
      <path
        style={styles.st1}
        d="M42.7,32.4c-2.5,1.5-3.4,4.1-3.8,7.4c-4.6,0-9.9,0.1-15.2-0.4c1-9.3,3.2-17.5,11.9-19.7
    C38.1,23.5,40.4,27.4,42.7,32.4L42.7,32.4z"
      />
      <path
        style={styles.st1}
        d="M50.8,32.7c2.5,1.5,3.4,4.1,3.8,7.4c4.6,0,9.9,0.1,15.2-0.4c-1-9.3-3.2-17.5-11.9-19.7
    C55.4,23.8,53.1,27.8,50.8,32.7L50.8,32.7z"
      />
      <path
        style={styles.st1}
        d="M50.7,46.7c-2.5,1.4-5.3,1-8.3-0.4c-2.2,4-5,8.6-7.1,13.4c8.6,3.7,16.8,5.8,23-0.6
    C56.2,55.1,53.9,51.2,50.7,46.7z"
      />
      <path
        style={styles.st2}
        d="M29.7,61.3l-3,20.5h41.1l-4.3-19.6C51.3,68.5,39.7,70.3,29.7,61.3L29.7,61.3z"
      />
      <line style={styles.st3} x1="47.1" y1="106.3" x2="47.1" y2="87.2" />
      <line style={styles.st3} x1="72.2" y1="102.7" x2="66.3" y2="86.4" />
      <line style={styles.st3} x1="29.1" y1="86.5" x2="23.2" y2="102.6" />
    </svg>
  );
}

export default XrayIcon;
