import {
  MakeGetObject,
  MakePostObject,
  MakeDeleteObject,
  MakePutObject,
} from "../AuthHeader";

export function DicomSend(keycloak, postObj) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/tasks/dicomsend`;
  return fetch(url, MakePostObject(keycloak, postObj)).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      throw new Error(resp.status);
    }
  });
}

export function MoveExam(keycloak, postObj) {
  const url = new URL(
    `${window.conf.SERVER_CONFIG.BASE_URL}/ris/remoteupdates`
  );
  return fetch(url.href, MakePostObject(keycloak, postObj)).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      throw new Error(resp.status);
    }
  });
}

export function DeleteExamCS(keycloak, postObj) {
  const url = `http://localhost:${window.conf.CLIENT_SERVICES.SERVER_PORT}/api/ClientServices/DeleteStudiesSeriesImages`;
  return fetch(url, MakeDeleteObject(keycloak, postObj)).then((resp) => {
    if (resp.ok) {
      return true;
    } else {
      throw new Error(resp.status);
    }
  });
}

export function DeleteExam(keycloak, studyId) {
  const url = new URL(
    `${window.conf.SERVER_CONFIG.BASE_URL}/ris/studies/${studyId}`
  );
  return fetch(url.href, MakeDeleteObject(keycloak)).then((resp) => {
    if (resp.ok) {
      return resp;
    } else {
      throw new Error(resp.text);
    }
  });
}

export function BurnExamCD(keycloak, postObj) {
  const url = `http://localhost:${window.conf.CLIENT_SERVICES.SERVER_PORT}/api/ClientServices/BurnMedia`;
  return fetch(url, MakePostObject(keycloak, postObj)).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      throw new Error(resp.status);
    }
  });
}

export function BurnCD(stdIDs) {
  const url = `pmburn://local?StdIds=${stdIDs.join(",")}`;
  window.location.href = url;
}

export function GetImportDrives(keycloak) {
  const url = `http://localhost:${window.conf.CLIENT_SERVICES.SERVER_PORT}/api/ClientServices/GetOptiacalDevices`;
  return fetch(url, MakeGetObject(keycloak)).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      throw new Error(resp.status);
    }
  });
}

export function StartImportingJob(keycloak, driveLetter, importMode = "impCD") {
  const url = `http://localhost:${window.conf.CLIENT_SERVICES.SERVER_PORT}/api/ClientServices/ImportCDStartRead/${driveLetter}/${importMode}`;
  return fetch(url, MakePostObject(keycloak, {})).then((resp) => {
    if (resp.ok) {
      return resp.json();
    } else {
      throw new Error(resp.status);
    }
  });
}

export function OpenExamInPmView(keycloak, stdUID) {
  const url = `pmview://local?studyuid=${stdUID}&singleinstance=true`;
  window.location.href = url;
}

export function OpenExaminationsInPmView(keycloak, body) {
  const url = `http://localhost:${window.conf.CLIENT_SERVICES.SERVER_PORT}/api/ClientServices/ViewStudiesSeriesImages`;
  return fetch(url, MakePostObject(keycloak, body)).then((resp) => {
    if (resp.ok) {
      return true;
    } else {
      throw new Error(resp.status);
    }
  });
}

export function getWorkIdStatus(keycloak, workId) {
  const url = `http://localhost:${window.conf.CLIENT_SERVICES.POLLING_SERVER_PORT}/api/Condition/ToolStates/${workId}`;
  return fetch(url, MakeGetObject(keycloak))
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      } else {
        throw new Error(resp.status);
      }
    })
    .catch((reason) => {
      throw new Error(reason);
    });
}

export function getWorkIdReadData(keycloak, workId) {
  const url = `http://localhost:${window.conf.CLIENT_SERVICES.SERVER_PORT}/api/ClientServices/ImportCDGetReadData/${workId}`;
  return fetch(url, MakeGetObject(keycloak))
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      } else {
        throw new Error(resp.status);
      }
    })
    .catch((reason) => {
      throw new Error(reason);
    });
}

export function DeleteCdImportTask(keycloak, workID) {
  const url = `http://localhost:${window.conf.CLIENT_SERVICES.SERVER_PORT}/api/ClientServices/ImportCDAbortWork/${workID}`;
  return fetch(url, MakeDeleteObject(keycloak))
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      } else {
        throw new Error(resp.status);
      }
    })
    .catch((reason) => {
      console.warn(reason);
    });
}

export function ImportCDPushData(keycloak, putObj) {
  const url = `http://localhost:${window.conf.CLIENT_SERVICES.SERVER_PORT}/api/ClientServices/ImportCDPushSelectedData`;
  return fetch(url, MakePutObject(keycloak, putObj))
    .then((resp) => {
      if (resp.status === 200) {
        return true;
      } else {
        throw new Error(resp.status);
      }
    })
    .catch((reason) => {
      console.warn(reason);
    });
}

export function RestoreExaminations(keycloak, putObj) {
  const url = `http://localhost:${window.conf.CLIENT_SERVICES.SERVER_PORT}/api/ClientServices/RestoreStudiesSeriesImages`;
  return fetch(url, MakePutObject(keycloak, putObj))
    .then((resp) => {
      if (resp.status === 200) {
        return true;
      } else {
        throw new Error(resp.status);
      }
    })
    .catch((reason) => {
      console.warn(reason);
    });
}
