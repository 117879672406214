import { useKeycloak } from "@react-keycloak/web";
import { useState, useEffect } from "react";
import { MakeGetObject } from "../../Utilities/AuthHeader";

import fallbackImage from "./defExmImg.svg";

function PreviewImage({ image, width = "50px" }) {
  const { keycloak } = useKeycloak();
  const [img, setImg] = useState(undefined);

  useEffect(() => {
    if (!image?.preview_url) {
      setImg(fallbackImage);
      return;
    }
    fetch(image.preview_url, MakeGetObject(keycloak))
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error("Unable to load preview");
        }
      })
      .then((data) => {
        setImg(URL.createObjectURL(data));
      })
      .catch((err) => {
        console.error(err);
        setImg(fallbackImage);
      });
  }, []);

  return (
    <img
      src={img ? img : fallbackImage}
      alt={`Preview-id-${image?.image_id ? image.image_id : "none"} `}
      style={{ width: width, height: "auto", transition: "all ease .5s" }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = fallbackImage;
      }}
    />
  );
}

export default PreviewImage;
