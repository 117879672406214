import style from "./style.scss";

export default function LinkesAcromioklavikulargelenkDvStehend2019(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 433.93 286.9">
      <g id="acromial_joint" data-name="acromial joint">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="akromioklavikulargelenk_left2019_cls6"
            d="M1.04,187.67c.3-.8.5-.9,15-8,13.1-6.5,22.9-11.3,40-19,5.7-2.5,12.5-2.8,20-7,1.3-.7,5-2.8,9.5-5.7,2.4-1.5,3.1-2.1,8.1-5.3,8.8-5.8,8.4-5.4,9.7-6.4,5.6-4.2,9.1-8.6,10.6-10.6,4.5-5.8,3.7-7.8,9-16,5.6-8.6,8.3-12.9,11-15,5.7-4.5,7.8-2.5,21-8,11.4-4.7,11.1-6.8,18-8,7.6-1.4,9.1.9,17-1,7.6-1.8,12.1-5.4,14-7,3.6-3.1,8.4-8.8,7-11-1.6-2.6-10,1.8-12-1-1.5-2.1,2.1-5.7,7-15,5.9-11.1,5.6-14.9,10-17,3.6-1.7,5.2.2,12,0,7.2-.2,10.1-2.5,20-6,11.8-4.2,17.7-6.3,23-6,9.9.6,21.2,6.6,27,16,4.9,8,3.1,13.9,9,18,4.7,3.2,10,2.4,15,2,6.4-.5,16.9,1,38,4,20.1,2.9,32,4.6,42,13,12.4,10.4,15.2,25.4,19,46,4.2,22.3.8,27,3,52,3.8,43.6,9,49,9,64,0,29.4-62.5,39.1-82,23-6.9-5.7-5.9-11.7-16-25-14.1-18.5-26.6-24.5-30-26-9.1-4.2-22.7-7.6-26-3-2.5,3.5,3.2,8.7,1,16-1.8,5.8-6.7,6.7-13,12-9.4,7.9-8.3,14.4-18,31,0,0-7.2,12.4-16,22C201.04,314.77-7.66,211.97,1.04,187.67Z"
          />
        </g>
        <g id="cavicula">
          <path
            id="cavicula_1"
            data-name="cavicula 1"
            class="akromioklavikulargelenk_left2019_cls4"
            d="M7.94,103.27c3.1-3.4,7.3-8.1,12-14,3.2-4.1,6.1-7.6,9-12,7.8-11.7,7.2-15.6,12-22,1.5-1.9,5.4-6.8,21-14,22.7-10.5,30.8-7.8,57-18,8.7-3.4,6.5-3.1,23-10C171.24,1.07,178.34.37,184.94,1.27c9.2,1.3,21.8,6.3,24,15,.5,1.9-1,6.3-4,15-4.2,12.2-6.3,18.4-11,26-4.2,6.9-6.3,8-8,8-4-.1-5-6.4-10-7-3.8-.4-4.5,3-10,4s-6.9-2.1-13-2c-5.6.1-6.4,2.7-14,5-8.8,2.7-10.2-.1-17,2-8,2.5-7.1,6.6-18,14s-14.9,5.5-20,12c-3.5,4.5-2.8,7.1-6,16-3.2,9.1-7,15.1-12,23-5.8,9.3-7.3,9.2-22,27-11.1,13.5-12.4,16.2-24,30-5.5,6.5-10.1,11.7-13,15"
          />
          <path
            id="cavicula_2"
            data-name="cavicula 2"
            class="akromioklavikulargelenk_left2019_cls1"
            d="M6.94,122.77c9.3-8-2.3,1.6,3-3.5,10.4-10,15.6-15.1,21-22,5.7-7.4,4.9-8,15-23,7.8-11.5,11.7-17.3,16-21,3.9-3.4,8.1-5.8,26-12,19.3-6.7,28.9-8.8,45-14,7-2.3,16.6-5.5,28-10"
          />
          <path
            id="cavicula_3"
            data-name="cavicula 3"
            class="akromioklavikulargelenk_left2019_cls1"
            d="M103.94,63.27c-3.3,4.7-6.5,7.9-9,10-4.4,3.8-6.9,4.7-9,8-2.5,4-2.5,8.6-2,12"
          />
          <path
            id="cavicula_4"
            data-name="cavicula 4"
            class="akromioklavikulargelenk_left2019_cls1"
            d="M83.94,93.27c-13.8-5-22.7,3.2-22,16"
          />
          <line
            id="cavicula_5"
            data-name="cavicula 5"
            class="akromioklavikulargelenk_left2019_cls1"
            x1="173.94"
            y1="10.27"
            x2="137.24"
            y2="19.77"
          />
        </g>
        <g id="shoulder_plate" data-name="shoulder plate">
          <path
            id="shoulder_plate_1"
            data-name="shoulder plate 1"
            class="akromioklavikulargelenk_left2019_cls3"
            d="M.94,188.27c.3-.8.5-.9,15-8,13.2-6.5,22.9-11.3,40-19,13.4-6,15.9-6.9,20-7,3.8-.1,11.8.3,21,3,6.1,1.8,8.7,3.4,12,2,5-2.1,6.6-9,7-11,2.2-9.9-3.6-12.8-2-22,.5-2.9,3.3-7.2,9-16,5.6-8.6,8.3-12.9,11-15,5.7-4.5,7.8-2.6,21-8,11.4-4.7,11.1-6.8,18-8,7.6-1.3,9.1.9,17-1,7.6-1.8,12.1-5.4,14-7,3.6-3.1,8.4-8.8,7-11-1.6-2.6-10,1.8-12-1-1.5-2.1,2.1-5.7,7-15,5.9-11.1,5.6-14.9,10-17,3.6-1.7,5.2.2,12,0,7.2-.2,10.1-2.5,20-6,11.8-4.2,17.7-6.3,23-6,9.9.6,21.2,6.6,27,16,4.9,8,3.1,13.9,9,18,1.18.8,2.39,1.35,3.62,1.72"
          />
          <path
            id="shoulder_plate_2"
            data-name="shoulder plate 2"
            class="akromioklavikulargelenk_left2019_cls3"
            d="M306.94,49.27c1.3,2.1,4.7,8.1,4,16,0,.5-1.2,11.2-10,18-6.1,4.7-8.5,1.9-18,7-8.1,4.4-8.1,7.3-16,11-5.3,2.5-8.4,2.5-15,4-9.9,2.2-16.8,5.1-26,9-13.5,5.7-23.1,9.8-33,19-10.8,10.1-15.1,19.9-16,22-2.3,5.5-3.4,10.4-4,14"
          />
          <path
            id="shoulder_plate_3"
            data-name="shoulder plate 3"
            class="akromioklavikulargelenk_left2019_cls3"
            d="M179.94,137.27c.6-3.7,2.1-8.6,6-12,4.2-3.6,7.7-2.7,16-5,6.9-1.9,15.9-4.5,21-12,.8-1.1,5.7-8.7,3-17-2.3-7.1-8.7-10.3-12-12-8-4.1-15.8-3.2-19-2.6"
          />
          <line
            id="shoulder_plate_4"
            data-name="shoulder plate 4"
            class="akromioklavikulargelenk_left2019_cls3"
            x1="194.94"
            y1="76.67"
            x2=".64"
            y2="204.27"
          />
          <path
            id="shoulder_plate_5"
            data-name="shoulder plate 5"
            class="akromioklavikulargelenk_left2019_cls3"
            d="M222.94,108.27c-5.2,53.5,22.6,107,57,107"
          />
          <path
            id="shoulder_plate_6"
            data-name="shoulder plate 6"
            class="akromioklavikulargelenk_left2019_cls3"
            d="M190.94,94.27c.9,2.3-3.3,5.9,0,8.4,4.9,3.6,14.4,1.6,21,3.6,11.3,3.5,17.1,13.4,18,15,3.1,5.5,1.7,7.2,4,14,3.8,11,9.3,11.7,20,26,4.6,6.1,8,12.4,15,25,4.6,8.3,8.2,15.5,10.8,21.1"
          />
          <line
            id="shoulder_plate_7"
            data-name="shoulder plate 7"
            class="akromioklavikulargelenk_left2019_cls3"
            x1="226.74"
            y1="51.67"
            x2="194.94"
            y2="76.67"
          />
        </g>
        <g id="upper_arm" data-name="upper arm">
          <path
            id="upper_arm_1"
            data-name="upper arm 1"
            class="akromioklavikulargelenk_left2019_cls3"
            d="M310.56,50.99c3.71,1.11,7.63.58,11.38.28,6.4-.5,16.9,1,38,4,20.1,2.9,32,4.6,42,13,12.4,10.4,15.2,25.4,19,46,4.2,22.3.9,27,3,52,3.8,43.6,7.8,47.7,9,64"
          />
          <path
            id="upper_arm_2"
            data-name="upper arm 2"
            class="akromioklavikulargelenk_left2019_cls2"
            d="M354.94,70.27c-2.1,3.7-5.2,8.9-9,15-4.5,7.2-6.8,10.8-10,15-3.3,4.3-4.4,5.1-17,18-9.3,9.5-14,14.4-16,17-5.8,7.7-5.9,10.2-12,17-6.2,7-8.3,6.8-11,11-2.9,4.5-5.5,12.2-2,26"
          />
          <path
            id="upper_arm_3"
            data-name="upper arm 3"
            class="akromioklavikulargelenk_left2019_cls2"
            d="M346.94,106.67c11.7,14.5,24.8,50.8,33,73.1"
          />
          <path
            id="upper_arm_4"
            data-name="upper arm 4"
            class="akromioklavikulargelenk_left2019_cls2"
            d="M289.94,190.27c6,.7,14.7,2.4,24,7,12.1,5.9,19.4,13.9,28,24"
          />
          <path
            id="upper_arm_5"
            data-name="upper arm 5"
            class="akromioklavikulargelenk_left2019_cls2"
            d="M422.64,126.07c-1.6,12.7-2.9,22.8-3.7,29.2-3.5,27.9-4.2,33.7-4,42,.2,5.7.7,12.1,6,35,3.1,13.5-10-39-3.7-17"
          />
          <path
            id="upper_arm_6"
            data-name="upper arm 6"
            class="akromioklavikulargelenk_left2019_cls3"
            d="M315.94,51.67c-6.6.7-16.3,2.5-27,7.6-3.8,1.8-22.6,11.2-36,33-4.6,7.5-15.6,25.4-13,50,.5,4.7,3.2,26.3,21,44,6.6,6.5,13.2,10.6,18,13"
          />
        </g>
      </g>
      <g id="Ebene_2" data-name="Ebene 2">
        <path
          id="cavicula_overlay"
          data-name="cavicula overlay"
          class="Clavicula2019Fill"
          style={{
            fill: props.colors.Clavicula2019Fill,
            stroke: props.colors.Clavicula2019Fill,
          }}
          d="M7.94,103.27c3.1-3.4,7.3-8.1,12-14,3.2-4.1,6.1-7.6,9-12,7.8-11.7,7.2-15.6,12-22,1.5-1.9,5.4-6.8,21-14,22.7-10.5,30.8-7.8,57-18,8.7-3.4,6.5-3.1,23-10C171.24,1.07,178.34.37,184.94,1.27c9.2,1.3,21.8,6.3,24,15,.5,1.9-1,6.3-4,15-4.2,12.2-6.3,18.4-11,26-4.2,6.9-6.3,8-8,8-4-.1-5-6.4-10-7-3.8-.4-4.5,3-10,4s-6.9-2.1-13-2c-5.6.1-6.4,2.7-14,5-8.8,2.7-10.2-.1-17,2-8,2.5-7.1,6.6-18,14s-14.9,5.5-20,12c-3.5,4.5-2.8,7.1-6,16-3.2,9.1-7,15.1-12,23-5.8,9.3-7.3,9.2-22,27-11.1,13.5-12.4,16.2-24,30-5.5,6.5-10.1,11.7-13,15"
        />
        <path
          id="acromion_overlay"
          data-name="acromion overlay"
          class="Acromion2019Fill"
          style={{
            fill: props.colors.Acromion2019Fill,
            stroke: props.colors.Acromion2019Fill,
          }}
          d="M171.4,167.45c.65.65,1.75.31,1.91-.6,2.66-15.03,11.69-28.99,30.03-41.38,15.67-9.67,31.55-16.89,47.78-20.01,11.27-1.32,20.48-5.16,27.08-12.19,5.14-4,11.94-6.62,19.96-8.22,14.01-10.46,17.52-22.7,6.98-37.32-5.61-7.99-4.99-25.75-30.69-31.75-3.4-.79-6.92-.79-10.31.03-7.03,1.7-15.9,5.05-25.1,8.68-3.36,2.15-10.98,3.02-18.47,2-3.57-.49-7.04,1.34-8.59,4.59l-13.04,27.38c.92,3.07,17.92-2.86,10.09,5.5-24.07,20.74-99.33,69.59-179.61,121.2,89.75-28.05,129.76-30.21,141.98-17.92Z"
        />
      </g>
    </svg>
  );
}
