import style from "./style.scss";

function LinkesBeckenAlaNachJudetVdOblLiegend1208(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 725.7 725.7"
    >
      <g id="BG">
        <path
          id="Bg"
          class="becken_ala_left1208_cls2"
          d="M478.2,711.9c-4.6,5.2-10,6.4-47.8,8.9-45.5,3-52.6,2.2-56.6-3.3-5.9-8,2.4-15.3-2.2-36.7-3-13.9-6-32.6-16.7-28.9-2.4.8-3.1,2.9-7.8,10-9,13.7-13.4,13.8-17.8,22.2-6.5,12.6.3,18-4.4,25.5-12.6,20.3-76.2,5.8-100-5.6-3.3-1.6-10-5.1-21.1-10-15.3-6.8-16.5-6.2-22.2-10-3.8-2.5-9.2-8-20-18.9-3.9-3.9-5.6-5.8-6.7-8.9-2.8-7.8,1.4-13.4-1.1-15.6-3.4-2.9-11.8,6.8-17.8,4.4-8.5-3.4-11.9-30.7,0-41.3,8.1-7.2,17.3-1.3,34.4-9.7,2.7-1.3,4.1-2.3,16.7-12.2,33.5-26.5,37-30.2,38.9-36.7.9-2.9,4.5-15.3-1.1-26.7-2.2-4.4-5.7-7.9-8.9-11.1-8.4-8.5-11.6-7.8-14.6-12.4-5.7-8.7-2.1-23.2,4.6-32,6.5-8.7,16.2-12.1,25.5-15.6,11.5-4.2,15.4-2.8,22.2-7.8,3.1-2.3,6.9-6,12.2-16.7,5.7-11.4,12-24.3,12.2-42.2.3-22.4-9.1-38.6-13.3-45.5-11.9-19.8-27.9-29.7-39.3-36.7-9.1-5.6-15-9.2-24-11-10.5-2.1-14.7.4-18.7-3.4-6.7-6.4-3.9-22.1-1.3-29.9,2.5-7.7,5.7-10.8,4.4-16.7-1.3-6-6-9.8-7.8-11.1-3.2-2.3-5.3-2.3-14.4-4.4-17-3.9-19.7-6.2-21.1-7.8-3.2-3.6-3.9-7.8-4.4-11.1-1.6-9.6,1.8-17.5,3.3-21.1,19.5-45.8,18.3-44.5,22.2-51.1,3-5,11.8-19.2,26.7-36.7,12-14.1,28.1-32.7,54.4-50,9.2-6,33.1-20.8,67.7-30,41.8-11.1,75.7-8,86.6-6.7,33.7,4,57.8,14.6,72.2,21.1,17.3,7.8,39.9,18,63.3,40,2.6,2.4,36.7,35,48.9,72.2,2.3,7.1,4.2,15.4,10,31.1,5.8,15.5,8,18.4,8.9,26.7,1.1,10.5-1.9,11.7-4.4,31.1-2.6,19.9,0,22.1-3.3,36.7-2.2,9.2-3,6.8-8.9,24.4-6.5,19.7-6.5,25.5-12.2,28.9-6,3.5-10-.6-22.2-1.1-17.2-.7-30.7,6.8-44.4,14.4-9.6,5.3-18.2,10.1-26.7,20-5.9,6.8-17.2,19.2-16.7,35.5.3,7.5,3,11.8,0,17.8-2.8,5.6-7,5.4-18.9,14.4-7.8,5.9-11.8,9-13.3,13.3-2.5,7.1,1.8,10.2-1.1,17.8-2.7,7.1-7.7,7.4-7.8,12.2-.1,5.6,6.5,6.1,10,14.4s-.7,13.3,3.3,17.8c2.9,3.1,6.5,2.3,13.3,3.3,4,.6,5.3,1.3,27.8,12.2,18.8,9.1,20.7,10,23.3,13.3,1.2,1.5,7.8,9.8,6.7,20-.9,7.9-5.6,9.4-7.8,17.8-2.1,8.1.5,14.1,2.2,21.1,5.1,20.6.7,39.6-3.3,56.6-3.5,14.8-5.2,11.7-10,30-6.9,27.5-3,33.8-9.8,41.5Z"
        />
      </g>
      <g id="underlays">
        <path
          id="Pfannerande"
          class="Pfannerande1208Fill"
          style={{
            fill: props.colors.Pfannerande1208Fill,
            stroke: props.colors.Pfannerande1208Fill,
          }}
          d="M282,371c-4.1,5.5,0,8.8-6,30-4.4,15.6-9.3,32.3-23,40-6.1,3.4-6.8,1-18,6-11.9,5.3-19.5,11.7-23,15-8.5,7.9-13.6,12.6-14,20-.5,9.2,6.6,16.5,9,19,6.1,6.3,8.7,4.2,13,9,7.3,8,1.5,15.6,7,37,2.6,10,4.4,10.5,5,18,.9,11-2.3,17.6,1,20,2.2,1.6,4.8-.9,13-3,14.2-3.6,18.2-1.7,22-5,6.9-6.1,3-20.9,2-25-1.6-6.5-3.2-6.8-4-12-1.2-8.1,2-12,5-22,5.3-17.9.2-23.4,7-39,2.3-5.3,4.6-8.5,7-11,5.4-5.6,8.5-4.7,13-9,8-7.6,3.4-15.2,10-21,3.4-3,6-2.1,24-3,11.6-.6,5.2-.7,28-2"
        />
        <path
          id="hipjointspace"
          class="Hipjointspace1208Fill"
          style={{
            fill: props.colors.Hipjointspace1208Fill,
            stroke: props.colors.Hipjointspace1208Fill,
          }}
          d="M271,578c-1.8-3.1,0-4.9,0-12,0-9.1-3-11.5-4-20-.6-5,.2-6.1,2-19,2.1-15.5,1.6-17.5,4-35,1.8-13.4,2.5-16.4,5-20,4.8-6.9,9.1-5.5,16-13,8.3-9.1,5.4-14.3,12-20,3.8-3.3,6.7-3.2,30-5,24.8-1.9,26.6-2.3,34-2,13.3.5,22.6,2.5,25,3,13.7,3,31.3,6.8,33,16,.9,4.8-2.6,10.8-7,12-6,1.7-11.7-5.9-18-11-20.3-16.4-55.5-14-78-2-13.8,7.4-22.2,18.1-26,23-3.7,4.8-9.7,13.6-14,26-2.3,6.6-8.8,26.1-3,49,1.8,7.1,3.7,14.8,10,22,8,9.2,17.7,11.5,17,14-1,3.4-20,.2-21,0-13.8-2.4-16.1-4.4-17-6Z"
        />
        <path
          id="Darmbeinschlaufel"
          class="Darmbeinschaufel1208Fill"
          style={{
            fill: props.colors.Darmbeinschaufel1208Fill,
            stroke: props.colors.Darmbeinschaufel1208Fill,
          }}
          d="M136,214c-7.1-8.2-4.2-20.8-3-26,2.4-10.3,6.7-15,12-24,11.3-19.1,7.2-23.9,16-41,2.6-5.1,5.7-9.8,33-38,20.9-21.5,31.3-32.2,36-36,23.6-18.7,46.1-27.6,60-33,11.3-4.4,28.1-10.7,51-14,37-5.3,66.3,1,89,6,15.2,3.4,31.2,6.9,51,16,9.5,4.4,43,20.5,74,56,13.5,15.5,35.3,40.3,45,79,6.5,25.9,5.1,47.6,3,79-.6,9.1-1.5,25.2-7,44-1.1,3.7-3.1,8.8-7,19-7.4,19.1-10.2,23.7-14,24-2.4.2-4.8-1.2-6-3-3.2-4.8,1.7-12.6,6-22,3-6.7,6.6-16.4,9-29,3.6-13,7.4-32,7-55-.9-52.3-22.9-90.5-34-107-21.3-31.8-46.1-48.9-58-57-43.5-29.5-85.1-35.6-97-37-40.2-4.9-71.9,2.8-86,7-32.4,9.6-54.4,24.6-65,32-15.7,10.9-26.5,21.3-48,42-14.7,14.1-22.2,21.4-29,31-5.7,8-11.8,22.3-24,51-4.6,10.8-7.6,18.5-5,27,2.6,8.5,9.5,12.9,8,15-1.6,2.2-11.5.4-17-6Z"
        />
      </g>
      <g id="Becken">
        <path
          id="Becken17"
          class="becken_ala_left1208_cls6"
          d="M327.1,711.6c.4-2.2,1-5.7,1.7-10,1.6-11.4,1.3-14.4,3.3-19.2.8-1.8,1.5-2.9,8.3-10.8,13.3-15.4,13.3-14.6,15-17.5,1.9-3.3,3.2-10,5.8-23.3,2.5-12.6,2.2-14.1,4.2-20.8,1.4-4.8,3.1-10.6,6.7-17.5,4.7-9,7.1-8.9,11.7-17.5,2-3.8,3.6-8.7,6.7-18.3,3.9-12.2,5.8-18.3,6.7-24.2,1.6-11.4-.5-13.6,2.5-19.2,3.7-6.9,10.5-10,14.2-11.7,8.1-3.7,15.5-3.5,20.8-3.3,6.9.3,11.7,1.7,20,4.2,13.2,4,22.6,8.7,26.7,10.8,10.4,5.5,15.9,8.4,20.8,15,2.5,3.3,6.8,9.1,5.8,15.8-.7,4.8-3.4,6.1-5.8,10.8-3.7,7.4-1.9,14-.8,23.3,1.2,9.7.8,18.4,0,35.8-.3,7.3-.9,19.4-4.2,34.2-3.4,15.4-6.5,19-11.7,37.5-3.5,12.6-5.5,23.1-6.7,30"
        />
        <path
          id="Becken16"
          class="becken_ala_left1208_cls6"
          d="M471.2,693.3c5.4-34.3-8.4-120.1-35.9-152.6"
        />
        <path
          id="Becken15"
          class="becken_ala_left1208_cls6"
          d="M462.1,534.2c-11.5,14.2-43.2,3.7-59.4-15.8"
        />
        <path
          id="Becken14"
          class="becken_ala_left1208_cls6"
          d="M439.6,515.9c0,42.8-36,77.5-80.4,77.5s-87-14.9-79.7-81.7c3.3-30.8,37.7-69.1,79.7-73.3,44.2-4.4,80.4,34.7,80.4,77.5h0Z"
        />
        <path
          id="Becken13"
          class="becken_ala_left1208_cls6"
          d="M327.1,570.9c16,5.8,32,31.7,32,71"
        />
        <path
          id="Becken12"
          class="becken_ala_left1208_cls6"
          d="M357.6,648.2c9.4,0,17,30.2,17,67.6"
        />
        <path
          id="Becken11"
          class="becken_ala_left1208_cls6"
          d="M185.5,605c-7.5,4.6-17.8,14.2-25.8,28.3-4.6,8-6.3,20.3-2.7,26.9,3.2,5.8,8,3.4,11.9,4,17.8,2.5,32.4-9.5,35-11.7,12.1-10,16.4-22.3,20.8-35,5.9-17,6.4-31.1,6.7-40,.3-12.6-1.4-7.3-4.2-40.8-1.2-15.1-1.3-21.6-6.7-26.7-5.3-5-10.3-3.5-15.8-10-5.9-7-5.8-15.5-5.8-16.7.2-9.2,5.8-15.4,9.2-19.2,6-6.7,12.7-9.7,20.8-13.3,12-5.3,14-3.5,20.8-7.5,8.7-5.1,12.9-12.6,18.3-22.5,5.5-10.1,11.8-21.6,12.5-37.5.9-20.8-8.5-36.1-15-46.7-11.6-18.9-26.1-29.1-33.3-34.2-11.2-7.8-18.5-12.8-30-15-11.7-2.2-17.9.5-21.7-4.2-4.4-5.6-1.1-16.3,2.5-28.3,3.1-10.3,5.5-12.7,4.2-17.5-2-7-9.8-10.6-15.8-13.3-14.4-6.6-22.4-2.9-28.3-10-3.4-4.1-3.8-8.8-4.2-13.3-.9-11.8,4-20.6,8.3-29.2,12.7-25,7.2-21.4,17.5-40,11.1-20,22.6-33.2,30-41.7,18.9-21.5,36.5-34.8,42.5-39.2,20.8-15.2,38.6-23.3,42.5-25,7.5-3.4,30.5-13.1,62.5-17.5,35-4.8,62.1-.5,70,.8,31.7,5.5,54,16.5,65.8,22.5,12.5,6.3,34.1,17.4,56.6,39.2,25,24,37.5,48,44.2,60.8,12.8,25,17.1,42.7,18.3,48.3,1.7,7.8,3.6,17.4,3.3,30-.2,10.2-1.7,11.1-3.3,29.2-1.6,17.6-.4,18.9-2.5,27.5-2,8.3-3.4,8.3-9.2,23.3-8.4,22-6.7,25.2-11.7,29.2-8.2,6.4-15.4-.2-34.3,2.7-14.7,2.2-24.9,8.4-34,13.9-11.7,7-25,15.1-33.3,30.8-3.4,6.3-7.3,13.8-8.3,24.2-.7,7.3.5,11.1-2.5,16.7-2.6,4.9-6.7,7.7-12.5,11.7-9.2,6.4-12,4.8-15,9.2-5,7.4.8,15.5-4.2,20-2.9,2.6-6.3,1.2-12.5,1.7-6.8.5-13,3.9-25,10.8-11.8,6.8-19.5,11.2-29.2,19.2-8.5,7-17,14.1-24.2,25-6.8,10.3-9.8,22.7-15.8,47.5-6.3,25.7-5.7,32.3-13.3,39.2-5.3,4.7-11.1,6.7-15.8,15-2.1,3.7-3,7.7-5,15.8-3.2,13.5-2.4,16.2-5,23.3-2.3,6.2-5,9.9-10,16.7-8.6,11.7-17,19-25.8,26.7-5.4,4.7-12.9,10.8-22.5,17.5"
        />
        <path
          id="Becken10"
          class="becken_ala_left1208_cls6"
          d="M138.7,600.8c90.9-2.8,194.5-178.1,126.6-270.7"
        />
        <path
          id="Becken9"
          class="becken_ala_left1208_cls6"
          d="M268.8,577.5c3.9,1.6,27.5,9.9,34.2,8.3,9-2.1,13.9-9,22.5-21.7,10-14.6,16-23.5,17.5-36.7.8-7.6-.2-13.4,4.2-20,3.5-5.3,8.6-8.3,11.7-10,6.8-3.9,11.3-4.2,16.7-5.8,11.3-3.5,10.8-8.2,28.3-20,15.7-10.6,24.4-12.4,24.2-17.5-.2-3.3-4.1-5.6-11.7-10-8.9-5.2-16.7-7.4-20-8.3s-14.1-3.6-29.2-3.3c-13.4.2-12.7,2.6-26.7,2.5-5.5,0-10.6-.4-18.3.8-5.8.9-9.5,1.6-13.3,4.2-5.4,3.7-5.6,7.7-10,14.2-6.9,10.1-12.2,8.9-18.3,16.7-3.7,4.6-4.3,8.3-7.5,29.2-4,26.2-5.9,39.3-5.8,41.7.8,19,7.4,31.1,1.5,35.7-1.6,1.3-2.6.7-9.2,1.7,0,0-8.7,1.2-20,5-7,2.3-12.5,5.4-23.3,11.7-11.9,6.8-17.9,10.2-20.8,14.2-5.6,7.4-3.5,11.1-10,18.3-2,2.3-7,7.8-15,10-7.3,2-10.4-.7-19.2.8-5.9,1-10.5,3.3-13.3,5"
        />
        <path
          id="Becken8"
          class="becken_ala_left1208_cls5"
          d="M122.2,141c2.8-.1,9.5-.9,13.3-5.8,2.3-2.9,2.6-6.1,3.3-12.5,1-8.9-.5-10.2.8-15.8.7-2.7,2.1-8.6,6.7-12.5,4.6-3.9,8-2.2,18.3-5,6.4-1.7,10.7-3.9,19.2-8.3,10-5.2,15-7.8,16.7-11.7,1.7-3.9-.4-4.4,0-17.5.2-7.4.9-9.5-.8-11.7-2.9-3.6-9.4-2.9-13.3-2.5-8.3.9-9.8,3.8-20,6.7-7.1,2-12,3.4-17.5,1.7-7.7-2.4-11.7-9.3-13.3-12.5"
        />
        <path
          id="Becken7"
          class="becken_ala_left1208_cls5"
          d="M122.2,360.9c8.3-42.4,38.7-75.8,58.3-77.5"
        />
        <path
          id="Becken6"
          class="becken_ala_left1208_cls5"
          d="M118.8,251.8c11,0,19.9,6,19.9,13.3"
        />
        <path
          id="Becken5"
          class="becken_ala_left1208_cls5"
          d="M118.9,214.3c9.9,0,30.8,15.9,29.2,20.8"
        />
        <path
          id="Becken4"
          class="becken_ala_left1208_cls5"
          d="M106.3,273.5h22.4"
        />
        <path
          id="Becken3"
          class="becken_ala_left1208_cls5"
          d="M122.2,286.8c3.7,0,6.6,2.6,6.6,5.8"
        />
        <path
          id="Becken2"
          class="becken_ala_left1208_cls5"
          d="M133.9,101.1c3,2.7,8.2,6.5,15,6.7,4.1,0,6.9-1.1,15.8-5,5.8-2.5,11.7-4.9,17.5-7.5,21.3-9.4,24.7-10,26.7-8.3,1.2,1,1,2,1.7,11.7.6,9.1,1,13.7,1.7,15.8,3,9.5,11.9,16,13.3,15,1.2-.8-2.4-7.1-1.7-7.5.8-.4,7,7.2,10.8,17.5,1.2,3.2,2,6.2,4.2,19.2,1.7,10.4,2.6,15.6,3.3,21.7,1.7,14.2,2,24.4,2.5,39.2.5,17,0,20.7-.3,22.6-1.8,11.3-5.8,19.8-8,24.1-8.9,16.9-21,24.6-19.3,26.9,0,0,3.6,1.3,4.2,2.4.4.8,5.1-1.9,5.1-1.9-.6-.6,3.3-5.1,9.4-12.4,6.4-7.6,6.6-7.2,8.1-9.3,3.2-4.6,3.4-9.3,5-23.3.8-7.3,1.9-17.2.8-30-1.2-14.8-4-17.7-3.3-29.2.3-4.7.9-7.9,0-13.3-1-5.9-2.8-8-1.7-11.7,1.4-4.5,5.2-5.2,5-8.3-.2-3.2-4.2-3.2-5.8-7.5-1.7-4.5,1.7-6.9,0-10.8-1.2-2.7-3.4-2.8-8.3-5.8-5.9-3.7-9.6-7.7-11.7-10-3.9-4.3-7.1-7.8-8.3-13.3-1.2-5.3,0-9.9.8-12.5"
        />
        <path
          id="Becken1"
          class="becken_ala_left1208_cls5"
          d="M180.5,283.5c-2.1-2.3-8.1-2.6-9.2-7.5-1-4.4-.1-8.6,1.7-16.7,1.1-5.1,1.6-6,3.3-13.3,2.4-10.4,2.1-11.6,1.7-12.5-2.1-4.5-8.7-5.9-16.7-7.5-7.3-1.5-9.2-.4-13.3-2.5-5.8-2.9-8.8-7.3-10-9.2"
        />
      </g>
    </svg>
  );
}

export default LinkesBeckenAlaNachJudetVdOblLiegend1208;
