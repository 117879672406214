import { Modal, Row, Col, Button, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { NotificationManager } from "react-notifications";
import "./stylesheets/DeletePatientModal.scss";
import { DeletePatient } from "../../Utilities/Patients/PatientUtils";
import { clearOpenedPatient, clearSelectedExaminations, setReloadPatientListFlag } from "../../Redux/actions";

export default function DeletePatientModal({
  shown,
  headerless = false,
  close,
}) {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const selectedPatient = useSelector(
    (state) => state.patientView.selectedPatient
  );

  const send = () => {
    let data = selectedPatient;
    DeletePatient(keycloak, data)
      .then((data) => {
        NotificationManager.success("Deleting patient success");
        dispatch(clearOpenedPatient());
        dispatch(clearSelectedExaminations());

        // waiting for integration
        dispatch(setReloadPatientListFlag(true));
        
        close();
      })
      .catch((reason) => {
        NotificationManager.error("Deleting patient failed");
        console.warn(reason);
      });
  };

  return (
    <Modal
      className="top-modal"
      show={shown}
      onHide={close}
      size={"md"}
      onClick={(e) => e.stopPropagation()}
    >
      {!headerless && (
        <Modal.Header className="py-2 bg-light">
          <h4>Are you sure?</h4>
        </Modal.Header>
      )}
      <Modal.Body className="text-center ">
          <Container>
            <p>Do you really want to <strong>delete</strong> this patient? <br />This action can <strong>not</strong> be undone.</p>
            <Row>
              <Col>
                <Button onClick={close}>Cancel</Button>
              </Col>
              <Col>
                <Button variant="danger" onClick={send}>
                  Delete
                  <i className="fas fa-trash ml-2"></i>
                </Button>
              </Col>
            </Row>
          </Container>
      </Modal.Body>
    </Modal>
  );
}
