import { useCallback, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import {
  loadExamination,
  loadExamImagePreview,
} from "../../../Utilities/Examinations/ExaminationsUtils";
import {
  loadMasKv,
  updateMasKvEntry,
} from "../../../Utilities/Examinations/maskv";
import { getDDMMYYY_HHMM } from "../../../Utilities/DateTimeUtils/DateTimeUtils";
import "./style/DoseModal.scss";

export default function DoseModalExtended({ exam_data }) {
  const [totalDosis, setTotalDosis] = useState(0);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [selectedImageCopy, setSelectedImageCopy] = useState(undefined);
  const [loadingState, setLoadingState] = useState(0); // 0-not loaded, 1-loading, 2-loaded , 3-failed
  const [examDoseData, setExamDoseData] = useState(undefined);
  const [imagePreview, setImagePreview] = useState(undefined);
  const [doseToChange, setDoseToChange] = useState(undefined);

  const [dosesBefore, setDosesBefore] = useState(undefined);
  const [dosesAfter, setDosesAfter] = useState(undefined);

  const [doseToAssign, setDoseToAssign] = useState(undefined);

  const { keycloak } = useKeycloak();
  const delta = 5;

  const loadExamDoseData = () => {
    setLoadingState(1);
    loadExamination(
      keycloak,
      exam_data?.id,
      "patientsname, studydate, modality,studydescription,id,studyinstanceuid,serie_collection"
    )
      .then((data) => {
        setExamDoseData(data);
        setSelectedImage({
          id: data.serie_collection[0].image_collection[0].image_id,
          dosis:
            data.serie_collection[0].image_collection[0].mas_kv_collection[0],
          previewUrl: data.serie_collection[0].image_collection[0].preview_url,
          serieDateTime: data.serie_collection[0].seriesdatetime,
        });
        setLoadingState(2);
      })
      .catch((reason) => {
        setLoadingState(3);
        console.warn("Unable to load examination dose informations: ", reason);
        NotificationManager.error(
          "Unable to load examination dose informations"
        );
      });
  };

  const loadUnasignedDosesBefore = () => {
    let filter = {
      and: [
        { field: "imageid", op: "==", value: 0 },
        {
          field: "zeitstempel",
          op: "<=",
          value: getDDMMYYY_HHMM(selectedImage?.serieDateTime),
        },
      ],
    };
    let sort = { field: "zeitstempel", direction: "asc" };
    loadMasKv(keycloak, delta, 1, filter, sort)
      .then((data) => {
        setDosesBefore(data);
      })
      .catch((reason) => {
        console.warn("Unable to load doses before: ", reason);
      });
  };

  const loadUnasignedDosesAfter = () => {
    let filter = {
      and: [
        { field: "imageid", op: "==", value: 0 },
        {
          field: "zeitstempel",
          op: ">=",
          value: getDDMMYYY_HHMM(selectedImage?.serieDateTime),
        },
      ],
    };
    let sort = { field: "zeitstempel", direction: "asc" };
    loadMasKv(keycloak, delta, 1, filter, sort)
      .then((data) => {
        setDosesAfter(data);
      })
      .catch((reason) => {
        console.warn("Unable to load doses after: ", reason);
      });
  };

  function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }

  const loadPreviewImage = () => {
    if (!selectedImage?.previewUrl) {
      setImagePreview(null);
      return;
    }
    setImagePreview(undefined);
    loadExamImagePreview(keycloak, selectedImage.previewUrl)
      .then((data) => setImagePreview(URL.createObjectURL(data)))
      .catch((reason) => {
        setImagePreview(undefined);
        console.warn("Unable to load image previev: ", reason);
      });
  };

  const countTotalDosis = useCallback(() => {
    let totDosis = 0;
    examDoseData?.serie_collection?.forEach((serie) => {
      serie?.image_collection.forEach((image) => {
        if (image?.mas_kv_collection?.length > 0) {
          totDosis = totDosis + image.mas_kv_collection[0].dosis;
        }
      });
    });
    setTotalDosis(roundToTwo(totDosis));
  }, [examDoseData]);

  const setInitState = () => {
    setTotalDosis(0);
    setLoadingState(0);
    setSelectedImage(undefined);
    setExamDoseData(undefined);
    setImagePreview(undefined);
    setDosesAfter(undefined);
    setDosesBefore(undefined);
    setDoseToAssign(undefined);
  };

  const saveAssignedDose = () => {
    let obj = {
      stdid: examDoseData.id,
      imageid: selectedImage.id,
    };
    updateMasKvEntry(keycloak, doseToAssign.id, obj)
      .then(() => {
        NotificationManager.success("Dose assigned successfully");
        setInitState(); //clear state
        loadExamDoseData(); //reload data
      })
      .catch((reason) => {
        console.warn("Unable to update maskv entry", reason);
        NotificationManager.error("Unable to update maskv entry");
      });
  };

  const saveDoseChange = () => {
    let obj = {
      mas: selectedImageCopy?.dosis?.mas,
      kv: selectedImageCopy?.dosis?.kv,
      dosis: selectedImageCopy?.dosis?.dosis,
    };
    updateMasKvEntry(keycloak, selectedImageCopy.dosis.id, obj)
      .then(() => {
        NotificationManager.success("Dose updated successfully");
        setInitState(); //clear state
        loadExamDoseData(); //reload data
      })
      .catch((reason) => {
        console.warn("Unable to update maskv entry", reason);
        NotificationManager.error("Unable to update dose informations");
      });
  };

  useEffect(() => {
    loadExamDoseData();
  }, []);

  useEffect(() => {
    setSelectedImageCopy(selectedImage);
    loadPreviewImage();
    if (selectedImage) {
      loadUnasignedDosesAfter();
      loadUnasignedDosesBefore();
    }
    setDoseToAssign(undefined);
  }, [selectedImage]);

  useEffect(() => {
    if (loadingState === 2) countTotalDosis();
  }, [loadingState]);

  return (
    <>
      {loadingState === 1 ? (
        <ClipLoader color="#a1a1a1" loading={true} css={true} size={30} />
      ) : loadingState === 3 ? (
        <h5>Unable to load dosis informations</h5>
      ) : (
        <div>
          <div className="d-flex flex-column align-items-center justify-content-center w-100 pb-3">
            <h2>{examDoseData?.patientsname.replace("^", " ")}</h2>
            <h3>
              {examDoseData?.modality} | {examDoseData?.studydescription} |{" "}
              {getDDMMYYY_HHMM(examDoseData?.studydate)}
            </h3>
            <h6>{examDoseData?.studyinstanceuid}</h6>
          </div>
          <div
            className="d-flex"
            style={{ width: "100%", gap: "10px", maxHeight: "70vh" }}
          >
            <div
              className="d-flex flex-column border rounded"
              style={{
                minWidth: "20vh",
                overflow: "auto",
              }}
            >
              {examDoseData?.serie_collection?.map((serie, serieKey) => (
                <>
                  <div
                    className={`border-bottom p-2 bg-light font-weight-bold ${
                      serieKey > 0 && "border-top"
                    }`}
                    key={`seriedoseinfokey${serieKey}`}
                  >
                    <div>{serie.seriesdescription}</div>
                    <div>{getDDMMYYY_HHMM(serie?.seriesdatetime)}</div>
                  </div>
                  {serie?.image_collection?.map((image, imageKey) =>
                    image?.imgdetails_collection.length > 0 ? (
                      image?.imgdetails_collection.map((imgDet, imgDetKey) => (
                        <div
                          onClick={() =>
                            setSelectedImage({
                              id: image?.image_id,
                              dosis: image?.mas_kv_collection[0],
                              previewUrl: image?.preview_url,
                              serieDateTime: serie?.seriesdatetime,
                            })
                          }
                          className={`p-2 pl-3 border-bottom image-desc ${
                            image?.image_id === selectedImage?.id && "selected"
                          } ${
                            image?.mas_kv_collection[0]?.dosis === 0
                              ? "bg-warning"
                              : "bg-success text-white"
                          }`}
                        >
                          {imgDet?.imagedescription
                            ? imgDet?.imagedescription
                            : serie?.seriesdescription}
                        </div>
                      ))
                    ) : (
                      <div
                        onClick={() =>
                          setSelectedImage({
                            id: image.image_id,
                            dosis: image.mas_kv_collection[0],
                            previewUrl: image.preview_url,
                          })
                        }
                        className={`p-2 pl-3 border-bottom image-desc ${
                          image.image_id === selectedImage.id && "selected"
                        }`}
                      >
                        {serie.seriesdescription}
                      </div>
                    )
                  )}
                </>
              ))}
            </div>
            <div
              className="d-flex flex-column align-items-center"
              style={{ gap: "5px" }}
            >
              <div className="font-weight-bold border rounded p-2 bg-light w-100">
                Dose Info
              </div>
              <InputGroup className="">
                <FormControl
                  value={selectedImageCopy?.dosis?.dosis}
                  type="number"
                  onChange={(e) =>
                    setSelectedImageCopy({
                      ...selectedImageCopy,
                      dosis: {
                        ...selectedImageCopy.dosis,
                        dosis: Number(e.target.value),
                      },
                    })
                  }
                />
                <InputGroup.Append>
                  <InputGroup.Text>μGy</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>

              <InputGroup className="">
                <FormControl
                  type="number"
                  value={selectedImageCopy?.dosis?.mas}
                  onChange={(e) =>
                    setSelectedImageCopy({
                      ...selectedImageCopy,
                      dosis: {
                        ...selectedImageCopy.dosis,
                        mas: Number(e.target.value),
                      },
                    })
                  }
                />
                <InputGroup.Append>
                  <InputGroup.Text>mAs</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>

              <InputGroup className="">
                <FormControl
                  type="number"
                  value={selectedImageCopy?.dosis?.kv}
                  onChange={(e) =>
                    setSelectedImageCopy({
                      ...selectedImageCopy,
                      dosis: {
                        ...selectedImageCopy.dosis,
                        kv: Number(e.target.value),
                      },
                    })
                  }
                />
                <InputGroup.Append>
                  <InputGroup.Text>kV </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>

              <div className="mt-auto font-weight-bold border rounded p-2 bg-light w-100 align-self-end">
                Total: {totalDosis} μGy
              </div>

              <Button
                style={{ width: "100%" }}
                disabled={selectedImage === selectedImageCopy}
                onClick={saveDoseChange}
              >
                <i className="fas fa-save ml-1" /> Save
              </Button>
            </div>
            <div className="border rounded p-2 d-flex flex-column w-100">
              <div className="d-flex align-items-center justify-content-center ">
                <h5 className="border rounded p-2 bg-light w-100 text-center">
                  Please choose unasigned dose
                </h5>
              </div>
              <div
                className="d-flex mb-2"
                style={{ height: "50vh", gap: "10px" }}
              >
                <div
                  className="d-flex flex-column"
                  style={{ minWidth: "35vh", gap: "5px" }}
                >
                  <div className="font-weight-bold border rounded p-2 bg-light w-100 mb-3">
                    Unasigned Doses
                  </div>
                  {dosesBefore?.maskv?.map((dose) => (
                    <div
                      className={`text-center border rounded p-1 image-desc ${
                        doseToAssign?.id === dose.id && "selected"
                      }`}
                      onClick={() => setDoseToAssign(dose)}
                    >{`${dose.mas} mas | ${dose.kv} kV | ${
                      dose.dosis
                    } μGy | ${getDDMMYYY_HHMM(dose?.zeitstempel)}`}</div>
                  ))}
                  <div className="bg-success rounded text-white font-weight-bold text-center p-1">
                    ** Examination{" "}
                    {getDDMMYYY_HHMM(selectedImage?.serieDateTime)} **
                  </div>
                  {dosesAfter?.maskv?.map((dose) => (
                    <div
                      className={`text-center border rounded p-1 image-desc ${
                        doseToAssign?.id === dose.id && "selected"
                      }`}
                      onClick={() => setDoseToAssign(dose)}
                    >{`${dose.mas} mas | ${dose.kv} kV | ${
                      dose.dosis
                    } μGy | ${getDDMMYYY_HHMM(dose.zeitstempel)}`}</div>
                  ))}
                </div>
                <div
                  name="imagebox"
                  className="py-4 bg-dark text-white rounded d-flex align-items-center justify-content-center w-100"
                >
                  {imagePreview === undefined && (
                    <ClipLoader
                      color="#a1a1a1"
                      loading={true}
                      css={true}
                      size={30}
                    />
                  )}
                  {imagePreview === null && "Unable to load preview image"}
                  <img src={imagePreview} alt="exam preview" />
                </div>
              </div>
              <div className="mt-auto d-flex justify-content-between">
                <Button
                  style={{ width: "120px" }}
                  variant="secondary"
                  disabled={doseToAssign === undefined}
                  onClick={() => setDoseToAssign(undefined)}
                >
                  <i className="fas fa-ban mr-1" />
                  Reject
                </Button>
                <Button
                  style={{ width: "120px" }}
                  disabled={doseToAssign === undefined}
                  onClick={saveAssignedDose}
                >
                  <i className="fas fa-save mr-1" /> Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
