import { MakePostObject } from "../AuthHeader";

export async function InsertPatientWorklistEntry(keycloak, pat_id, body = {}) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/patients/${pat_id}/worklist`;
  return fetch(url, MakePostObject(keycloak, body)).then((data) => data.json());
}

export async function InsertWorklistEntryOrder(keycloak, wl_id, body = {}) {
  const url = `${window.conf.SERVER_CONFIG.BASE_URL}/ris/worklist/${wl_id}/order`;
  return fetch(url, MakePostObject(keycloak, body)).then((data) => data.json());
}

export function GenerateAccNo() {
  let id = Math.floor(Math.random() * 100);
  let curDate = new Date();
  return `${id}_${curDate.getHours()}_${curDate.getMinutes()}_${curDate.getDay()}_${curDate.getMonth()}_${curDate.getFullYear()}`
}