import style from "./style.scss";

function Brustwirbelkorper4VdLiegend1513(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 237.4 178.3">

<g id="BG">
  <path id="BG3" class="Brustwirbelsule_ap41513_cls3" d="M237.7,54.6c0-1.7,0-3.3,0-5l-64.8-21.8,2.3,24.9-5.6,2.2-6.8,1.2c1-1.5.4-3.8-.5-6.2-3.1-4.8-4.8-9.3-4.1-13.2l1.7-29.5c-.9-5.5-7.7.9-15.6,3-15.2,2.5-41.2.5-51.3-3.3-5-3.6-9.8-4.7-14.6-2.8-3.9.8-2,7.9-.4,16.4,1.7,9.3-.6,20.8-3.5,32.7-2.6.5-5.1.3-7.5-.7-3.9-1.7-8-3-12.3-3.5l-2.4-.3c6.2-11.1,7.3-21.1,0-29.4C40.8,6.9,21.5,4.2.2-.8c.2,17.4,1.8,34.8-.4,52.1.3.3.6.5.8.8l.9,24.5c11.4-2.2,22.7-3.6,33.7-3.5.8,1.8,2.3,3.6,4.5,5.5,10.6,5.2,20.7,8.1,30.2,8.5l5.6,3c3,1.3,4.8-.3,6.3-2.5.8,5.9,0,10.8-2.6,14.4-1.7,2.3-2.6,5.1-2.5,8,.1,2.5,1,4.8,3.2,6.8-1.1,2.3-1.3,5.4-1.4,8.7l-.5-.7c0,.2-.1.3-.2.4.5.6.7,1.4.6,2.2.3.5.4,1.1.3,1.6.1.3.3.6.3.9l.6,1.5c1.6,6.8,4.2,12.6,1.6,19.4l-2.8,11.8c-2.2,8.5.1,10.1,7.1,10.6,16.7,1.1,33,1.4,49,.6l22.3-1.4c7-.6,7.6-5.9,6-12.8-.7-3.7-2.5-7.6-5.4-11.9-3.7-3.3,4.5-9.5,5.6-14.5,1.5-4,.6-9.2-2-11.8-1.4-.9-3.6.7-8.4,3.3-1.1-2.1-1.1-4.1.7-6,5.3-2.1,7.6-7,6.4-15l.3-13.7,3.4,1,11.7-5.3c3.4,0,6.6,0,11.8-2.7l49.5,1.7v-24.3c-1.4-.2-2.7-.5-4.1-.7l5.4-5Z"/>
  <path id="BG2" class="Brustwirbelsule_ap41513_cls3" d="M75.8,121.3s0,0,.1,0l-.5-.8c-.2-5.4-3-8.2-7-9.9-9.2-4.7-27.6-1.7-47.6,2.6-7.9,2.1-15.1,4.2-21.3,6.2,0,0,0,0,0,0,.8.4,1.3,1.3,1.3,2.2v4.4c27.1-5.2,52.2-7.5,74-4.6.3-.1.6-.2.9-.2Z"/>
  <path id="BG1" class="Brustwirbelsule_ap41513_cls3" d="M235.5,115.8c-6-1.3-11.3-2.7-15.7-4.1-4.6,2.4-11.9,3.2-20.4,3.2-6.5-3.1-15-5-25-6.4-7.7.2-11.9,4.7-13.3,12.8,21.8-1.6,47.4-2.1,74.3-1.8,0-1.2.1-2.4.2-3.7Z"/>
</g>
<g id="Underlays">
  <g id="BWK">
    <path id="BWK2" class="BWK1513Fill" style= {{
            fill: props.colors.BWK1513Fill,
            stroke: props.colors.BWK1513Fill,
          }} d="M76.7,5.4c2-2.5,6.1-2.3,7.3-2.3,4.1.2,5.9,2.3,10,4.1,1.9.8,3.6,1.3,12.3,2.3,13.4,1.5,20.1,2.2,26.4,1.8,7.7-.5,11.6-.8,16.4-2.7,5.3-2.2,7.7-4.7,9.6-3.7,1.9,1.1,1.4,4.6.5,16-.8,10.2-1.2,15.3-.9,17.3,1.5,9.8,6.7,12.9,4.6,17.8-1.3,3.1-4.5,4.4-8.2,5.9-5.4,2.2-9.3,1.9-17.3,2.3-7.6.3-4.6.6-16.4,1.4-13.2.8-19.9,1.2-26.9.5-7.1-.8-18.3-2.1-20.1-7.8-.4-1.4,0-2,1.4-7.3.2-.7,2-8.1,2.7-14.6.3-2.7.9-8.6,0-16-1.1-8.7-3.6-12.3-1.4-15h0Z"/>
    <path id="BWK1" class="BWK1513Fill" style= {{
            fill: props.colors.BWK1513Fill,
            stroke: props.colors.BWK1513Fill,
          }} d="M78,170.4c-.9-1.4-.3-4.5.9-10.5,2.1-10.3,3.3-10.7,3.2-15-.1-3.6-.9-3.4-2.3-12.3-1.1-7.5-.7-8.6,0-9.1,2.1-1.7,6.1,1.6,12.8,3.6,3.9,1.2,4.2.6,19.1,1.4,16.4.8,18.9,1.7,26,.5,4.1-.7,7.1-1.2,10.9-2.7,7.3-2.9,9.8-6.2,12.3-5,2.7,1.3,3.1,6.9,2.3,10.9-1.5,7.1-6.9,8.2-6.5,13.2.2,2.9,2.1,2.7,4.6,9.6,1.4,3.7,1.7,6,1.8,7.3.2,2.8.5,6.1-1.4,8.2-1.8,1.9-4.6,1.8-6.4,1.8-7,0-14,1.3-21,1.4-10.4,0-8.4.4-18.2.5-4.2,0-9.1-.1-18.7-.5-14.9-.5-18.1-.8-19.6-3.2h0s0,0,0,0Z"/>
  </g>
  <g id="WK">
    <path id="WK3" class="Wirbelkorper_Querfortsatze1513Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1513Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1513Fill,
          }} d="M78.9,115.9c-2.8-2.9-1.2-9.1-.9-10,1-3.8,2.4-3.8,3.6-7.8,1.1-3.5.8-6.4.5-10.5-.4-4.9-.7-7.5-2.3-10.5-1.1-2.1-2.3-3.2-1.8-4.6.3-.8,1-1.3,5-2.7,2.8-1,4.2-1.5,5.5-1.8,8.5-2.1,18.1,0,19.6.5,6.8,1.6,5.5,2.7,10,3.2,4.8.6,8.8-.4,16.9-2.3,3.7-.9,5.2-1.4,9.6-2.3,6.2-1.3,8.6-1.3,10.9-.5,1.1.4,4.1,1.4,5.5,4.1,1.1,2,.6,3.8,0,8.2,0,0-.4,2.9-.9,10-.1,2,0,2.2,0,13.2,0,7.2,0,10.9-1.8,13.2-.3.4-1.6,1.9-4.6,3.2-6.2,2.6-11.5.6-18.7-.5-8.9-1.3-7.2.9-20.5,0-13.1-.9-12.7-2.8-21-2.3-8,.5-12.1,2.6-14.6,0h0,0Z"/>
    <path id="WK2" class="Wirbelkorper_Querfortsatze1513Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1513Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1513Fill,
          }} d="M166.4,55.7c5.4-.6,5.6-2.2,11.9-3.7,2.6-.6,9.1-2,16.4,0,3.9,1.1,8.4,2.3,10.5,6.4,2.3,4.4.1,9.3,0,9.6-.7,1.5-1.7,2.8-10.9,8.7-4.7,3-5,5.3-7,6.3-5.3,2.6-8.1.9-14.9,3.7-6.6,2.7-7.9,4.2-10.5,3.7-3.6-.7-6.3-4.2-7.3-7.3-1.3-4,.9-5.5,1.4-12.3.7-8.7-2.4-12.3,0-14.6,1.9-1.8,4.2.2,10.5-.5h0Z"/>
    <path id="WK1" class="Wirbelkorper_Querfortsatze1513Fill" style= {{
            fill: props.colors.Wirbelkorper_Querfortsatze1513Fill,
            stroke: props.colors.Wirbelkorper_Querfortsatze1513Fill,
          }} d="M78.9,90.8c-2.1.6-3.2-1.1-7.3-2.7-4.1-1.7-4.5-.7-11.9-2.3-4.1-.9-6.7-1.8-11.8-3.7-6.1-2.2-7.5-3.1-8.7-4.1-.5-.5-3.1-2.8-4.6-6.8-.5-1.4-2.4-7.1.5-13.2,2.5-5.4,7.5-8.6,11.8-9.6,4.4-1,8.2,0,14.1,1.8,5.8,1.7,6.1,2.9,10,3.2,5.5.4,6.6-1.8,10.9-.9,6.1,1.2,9.4,6.6,9.6,6.8,2.3,3.9,1.9,7.6,1.4,12.8-.3,3.2-.6,5.5-2.3,7.8-1.7,2.3-2.9,1.8-5.9,4.6-3.8,3.5-3.5,5.8-5.9,6.4h0Z"/>
  </g>
  <g id="Platte">
    <path id="Platte4" class="Grund_Deckplatten1513Fill" style= {{
            fill: props.colors.Grund_Deckplatten1513Fill,
            stroke: props.colors.Grund_Deckplatten1513Fill,
          }} d="M80.7,125c.3.6.9,1.1,1.6,1.2l4.4,1.2,8.8,2.7c.8.3,1.7.4,2.6.4h11.1l24.7,1.2c.5,0,1.1,0,1.6-.1l8.6-1.7,9.1-2.1,6.1-3.9.9-.6c.9-.6.9-1.9,0-2.4h0c-.8-.6-1.9-.5-2.6.2l-2.3,1.6c-.4.4-1.1.9-1.6,1.1l-4.6,1.9c-.5.2-2.3.8-2.8.8l-12,2.3-32.7-1.1-7.2-.7-11.3-4.9h-1.2c-1.2,0-2,1.3-1.4,2.4l.3.6Z"/>
    <path id="Platte3" class="Grund_Deckplatten1513Fill" style= {{
            fill: props.colors.Grund_Deckplatten1513Fill,
            stroke: props.colors.Grund_Deckplatten1513Fill,
          }} d="M79,114.5c1-.1,1.4.8,2.7,1.4,1.4.6,2.5.1,4.6-.3,0,0,2.5-.5,6.7-.8,2.7-.2,4.7,0,7.2.3,3.4.4,4.5.8,7.3,1.3,6.2,1,11.4.8,14.4.7,5-.2,5.1-.7,8.7-.6,3.4.2,6.8.8,8.8,1.1,4.1.7,4.8,1.2,7.2,1.1,2.4,0,4.4,0,6.4-1.3,2.1-1.3,3.8-2.6,3.8-2.6.9-.7,1.7-1.4,2.3-1.1.5.2.7.8.7,1.4,0,.8-.7,1.3-1.9,2.2-1.8,1.3-2.1,1.8-3.3,2.3-2.4,1-4.4,1.5-5.7,1.6-.8,0-4.3.2-7.7-.5-1.8-.3-3.6-.6-5.5-1-.9-.2-.6-.4-8.2-.5-2.4,0-4.1,0-4.6,0-3.8,0-3.9.1-5.7.1-2.8,0-5-.3-8.2-.7-1.5-.2-2.9-.4-5.7-.8-3.3-.5-4.5-.7-6.6-.8-2,0-3.4,0-3.9,0-2.3.1-4.2.5-4.8.6-2.3.4-3,.6-4.4.8-1.5.2-2.3,0-3.9-.5-1.1-.4-2.2-.7-2.3-1.5-.1-.8.8-1.8,1.8-1.9Z"/>
    <path id="Platte2" class="Grund_Deckplatten1513Fill" style= {{
            fill: props.colors.Grund_Deckplatten1513Fill,
            stroke: props.colors.Grund_Deckplatten1513Fill,
          }} d="M79.6,72.1c.6.2,1,0,2.6-.7,1.6-.7,2.4-1.1,2.8-1.3,1.3-.6,2.4-.8,4-1.1,2.9-.6,5.2-.7,5.7-.7,1.9,0,3.3.2,6.2.6,3.4.5,5.9,1.1,6.6,1.3,1.3.3,1.2.3,5.4,1.5,3.1.9,4.6,1.3,5.1,1.4,2.2.4,4.1.2,8.2-.6,5.7-1.1,10.7-2.4,12.8-3,1.2-.3,4.7-1.3,9.2-1.9,3.1-.5,4.1-.4,5.2,0,2,.7,2.9,2.2,4.4,1.8.8-.2,1.7-.8,1.7-1.5,0-1-1.6-1.7-2.5-2.2-2.9-1.4-5.5-1.4-6.2-1.4-2.2,0-2.9.4-8,1.5-2.3.5-4.4.9-7.1,1.4-4.9.8-4.6.9-7.2,1.7-2.7.8-3.7.9-5.9,1.3-3.1.5-5.5,0-6-.1-2.5-.6-2.6-1.3-5.5-2.3-1.3-.4-3.2-.7-6.8-1.1-2.7-.3-5-.6-8.1-.8-3.6-.2-5,0-6.8.1-2.7.2-2.1.2-4.9,1.3s-2.8,1-4.6,1.7c-1.6.6-1.8,1.1-1.8,1.4-.1.7.6,1.6,1.4,1.8Z"/>
    <path id="Platte1" class="Grund_Deckplatten1513Fill" style= {{
            fill: props.colors.Grund_Deckplatten1513Fill,
            stroke: props.colors.Grund_Deckplatten1513Fill,
          }} d="M74.9,57.6c1.5.5,1.6,2.1,2.8,3.4,1.1,1.2,2.4,1.4,5.1,1.9,9.9,1.9,9.1,1.9,9.9,1.9,2.6.3,4.4.3,11.5.1,7.5-.2,11.2-.3,15.4-.5,3.4-.2,7.9-.4,13.8-.9,7.4-.7,5.6-.8,10.7-1.1,4.7-.3,6.3-.3,8.8-1.3,3.1-1.2,4.6-1.8,5.8-3,.8-.8,1.6-1.8,2.5-1.6.7.1,1.4.9,1.4,1.6,0,.8-.8,1.5-3,2.6-2.6,1.4-3.9,2.1-6.5,2.7-2.9.8-5.1.9-9.2,1.1-3.7.2-4,0-7.2.2-4.3.2-6.3.7-10.3,1.1-4.3.5-7.3.7-12.6.9,0,0-13.4.6-28.1-.9,0,0-2.2-.2-5-1-3.2-.9-4.2-1.6-4.7-2.1-.7-.5-1.2-1.3-2.3-2.7-1.2-1.6-1.3-2-1.1-2.3.3-.6,1.4-.7,2.3-.5Z"/>
  </g>
</g>
<g id="BWS_4">
  <path id="BWS30" class="cls-5" d="M146.5,90.8c-3,0-5.4-7.1-6.4-10-.3-.8-1.4-4.1-1.8-9.6-.6-7-1.1-12.6,2.3-15.5,1.4-1.2,3.7-2.1,5.5-1.4,1.5.7,2,2.3,2.7,4.6,3.1,9.1,3.5,7.4,4.1,10.9.3,1.6.9,6.5-.9,12.3-.7,2.4-2.7,8.6-5.5,8.7h0Z"/>
  <path id="BWS29" class="cls-5" d="M78.6,115.8c-2.8-2.9-1.2-9.1-.9-10,1-3.8,2.4-3.8,3.6-7.8,1.1-3.5.8-6.4.5-10.5-.4-4.9-.7-7.5-2.3-10.5-1.1-2.1-2.3-3.2-1.8-4.6.3-.8,1-1.3,5-2.7,2.8-1,4.2-1.5,5.5-1.8,8.5-2.1,18.1,0,19.6.5,6.8,1.6,5.5,2.7,10,3.2,4.8.6,8.8-.4,16.9-2.3,3.7-.9,5.2-1.4,9.6-2.3,6.2-1.3,8.6-1.3,10.9-.5,1.1.4,4.1,1.4,5.5,4.1,1.1,2,.6,3.8,0,8.2,0,0-.4,2.9-.9,10-.1,2,0,2.2,0,13.2,0,7.2,0,10.9-1.8,13.2-.3.4-1.6,1.9-4.6,3.2-6.2,2.6-11.5.6-18.7-.5-8.9-1.3-7.2.9-20.5,0-13.1-.9-12.7-2.8-21-2.3-8,.5-12.1,2.6-14.6,0h0,0Z"/>
  <path id="BWS28" class="cls-5" d="M166,55.7c5.4-.6,5.6-2.2,11.9-3.7,2.6-.6,9.1-2,16.4,0,3.9,1.1,8.4,2.3,10.5,6.4,2.3,4.4.1,9.3,0,9.6-.7,1.5-1.7,2.8-10.9,8.7-4.7,3-5,5.3-7,6.3-5.3,2.6-8.1.9-14.9,3.7-6.6,2.7-7.9,4.2-10.5,3.7-3.6-.7-6.3-4.2-7.3-7.3-1.3-4,.9-5.5,1.4-12.3.7-8.7-2.4-12.3,0-14.6,1.9-1.8,4.2.2,10.5-.5h0Z"/>
  <path id="BWS27" class="cls-5" d="M78.6,90.8c-2.1.6-3.2-1.1-7.3-2.7-4.1-1.7-4.5-.7-11.9-2.3-4.1-.9-6.7-1.8-11.8-3.7-6.1-2.2-7.5-3.1-8.7-4.1-.5-.5-3.1-2.8-4.6-6.8-.5-1.4-2.4-7.1.5-13.2,2.5-5.4,7.5-8.6,11.8-9.6,4.4-1,8.2,0,14.1,1.8,5.8,1.7,6.1,2.9,10,3.2,5.5.4,6.6-1.8,10.9-.9,6.1,1.2,9.4,6.6,9.6,6.8,2.3,3.9,1.9,7.6,1.4,12.8-.3,3.2-.6,5.5-2.3,7.8-1.7,2.3-2.9,1.8-5.9,4.6-3.8,3.5-3.5,5.8-5.9,6.4h0Z"/>
  <path id="BWS26" class="cls-5" d="M150.6,142.3c-2.3,0-4.1-4.3-4.6-5.5-1.6-3.9-.6-5.4-1.4-10-1.2-7.4-4.8-9.7-3.2-12.3,1.2-2,4.4-2.5,6.4-2.3.5,0,3.9.4,5,2.7,1.2,2.3-1.3,3.7-.9,7.3.4,4,3.6,4.3,4.1,8.7.3,2.6-.7,4.8-1.4,6.4-.9,1.9-2.2,5-4.1,5h0Z"/>
  <path id="BWS25" class="Brustwirbelsule_ap41513_cls6" d="M76.7,5.5c2-2.5,6.1-2.3,7.3-2.3,4.1.2,5.9,2.3,10,4.1,1.9.8,3.6,1.3,12.3,2.3,13.4,1.5,20.1,2.2,26.4,1.8,7.7-.5,11.6-.8,16.4-2.7,5.3-2.2,7.7-4.7,9.6-3.7,1.9,1.1,1.4,4.6.5,16-.8,10.2-1.2,15.3-.9,17.3,1.5,9.8,6.7,12.9,4.6,17.8-1.3,3.1-4.5,4.4-8.2,5.9-5.4,2.2-9.3,1.9-17.3,2.3-7.6.3-4.6.6-16.4,1.4-13.2.8-19.9,1.2-26.9.5-7.1-.8-18.3-2.1-20.1-7.8-.4-1.4,0-2,1.4-7.3.2-.7,2-8.1,2.7-14.6.3-2.7.9-8.6,0-16-1.1-8.7-3.6-12.3-1.4-15h0Z"/>
  <path id="BWS24" class="Brustwirbelsule_ap41513_cls6" d="M78.1,170.5c-.9-1.4-.3-4.5.9-10.5,2.1-10.3,3.3-10.7,3.2-15-.1-3.6-.9-3.4-2.3-12.3-1.1-7.5-.7-8.6,0-9.1,2.1-1.7,6.1,1.6,12.8,3.6,3.9,1.2,4.2.6,19.1,1.4,16.4.8,18.9,1.7,26,.5,4.1-.7,7.1-1.2,10.9-2.7,7.3-2.9,9.8-6.2,12.3-5,2.7,1.3,3.1,6.9,2.3,10.9-1.5,7.1-6.9,8.2-6.5,13.2.2,2.9,2.1,2.7,4.6,9.6,1.4,3.7,1.7,6,1.8,7.3.2,2.8.5,6.1-1.4,8.2-1.8,1.9-4.6,1.8-6.4,1.8-7,0-14,1.3-21,1.4-10.4,0-8.4.4-18.2.5-4.2,0-9.1-.1-18.7-.5-14.9-.5-18.1-.8-19.6-3.2h0s0,0,0,0Z"/>
  <path id="BWS23" class="Brustwirbelsule_ap41513_cls6" d="M235.4,60.1c-22.9-3.5-46.2-4.4-69.4-4.4"/>
  <path id="BWS22" class="Brustwirbelsule_ap41513_cls6" d="M236.9,55.2c-12.1,11.7-27.9,26.4-46.2,23.4"/>
  <path id="BWS21" class="Brustwirbelsule_ap41513_cls6" d="M172.9,27.9c0,28.1,8,50.7,17.8,50.7"/>
  <path id="BWS20" class="Brustwirbelsule_ap41513_cls6" d="M235.1,84.7c-16.1.4-32.1-2.4-48.2-1.8"/>
  <path id="BWS19" class="cls-5" d="M168.1,72.4c0,6.8-3.6,12.3-8.1,12.3"/>
  <path id="BWS18" class="Brustwirbelsule_ap41513_cls6" d="M118.6,60.7c3.4-4.4,9.3-9.5,15.6-12.7"/>
  <path id="BWS17" class="Brustwirbelsule_ap41513_cls6" d="M118.6,60.7c4.6-8.3.5-18.8-.9-21.4"/>
  <path id="BWS16" class="Brustwirbelsule_ap41513_cls6" d="M97.7,41.5c0,3.6,4.2.5,10.9,6.4"/>
  <path id="BWS15" class="Brustwirbelsule_ap41513_cls6" d="M111.7,39.3c-4.6,11.3-.7,23.9,2.8,25.7"/>
  <path id="BWS14" class="Brustwirbelsule_ap41513_cls6" d="M161,121.3c0-7.1,6-12.8,13.3-12.8"/>
  <path id="BWS13" class="Brustwirbelsule_ap41513_cls6" d="M199.4,114.9c-3.2-2-14-5.1-25-6.4"/>
  <path id="BWS12" class="Brustwirbelsule_ap41513_cls6" d="M199.4,114.9c1.3-.4,11,0,20.4-3.2"/>
  <path id="BWS11" class="Brustwirbelsule_ap41513_cls6" d="M234.6,115.6c-5-1.1-10-2.3-14.8-3.9"/>
  <path id="BWS10" class="cls-5" d="M118.6,133.1c6.6-10.4,1.3-35.2-3.3-40.2"/>
  <path id="BWS9" class="cls-5" d="M118.6,133.1c-4.6-18.1-9.1-28.4-15.4-26.9"/>
  <path id="BWS8" class="Brustwirbelsule_ap41513_cls6" d="M.3,119c12.8-4.3,26.1-7.7,39.5-9.6s21.9-3,30.5,2.2,5.5,5.7,5.2,9.9"/>
  <path id="BWS7" class="cls-5" d="M87.2,138.2c-24-14,9.8-53.1,5-5.6"/>
  <path id="BWS6" class="Brustwirbelsule_ap41513_cls6" d="M.6,52.2c6.9,6.7,15.7,16,26.1,16.2s18.6-9.8,24.5-17.5"/>
  <path id="BWS5" class="Brustwirbelsule_ap41513_cls6" d="M1.2-.4c19,5.7,43,5.7,54,24.7,5,9,1,19-4,26.7"/>
  <path id="BWS4" class="Brustwirbelsule_ap41513_cls6" d="M81.3,85.3c0,4.4-21.6-21.3-79.8-8.7"/>
  <path id="BWS3" class="Brustwirbelsule_ap41513_cls6" d="M82.7,69.8c-5-7.4-46.3-32.9-82-17.7"/>
  <path id="BWS2" class="cls-5" d="M71.3,88c-5.9-2.2-.6-14.9-1.4-14.9"/>
  <path id="BWS1" class="cls-5" d="M77.6,72.5c-1.1-1.4,40.1,17.9,82.9-1.8"/>
</g>
</svg>
  );
}

export default Brustwirbelkorper4VdLiegend1513;
