import style from "./style.scss";

function Rechter2FingerLatSitzend1318(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 232.4 489.2"
    >
      <g id="secondfinger">
        <g id="background">
          <path
            id="background_white"
            data-name="background white"
            class="rechter_finger2_lat_sitzend1318_cls7"
            d="M232.2,487.9l-24.6,1.1-85.2-8.2-17.6,1.4c-7.7.5-16.1-6.3-25-18.9-24.2-37.7-51.1-62.1-79.3-80.1l2.5-19.8-1.1-19.8-.2-29.8,37.1,25.5c-8.9-33-11.3-77.1-10-127.9-6.6-32.3-7.9-61.8-3.8-88.3l5.8-77.9C35.4,20.9,45.7,4.4,65.4.7,74.2,0,81.5,1.4,86.9,5.5c3.3,2.5,5.3,6.5,5.4,10.7,1.9,47.6,6.8,90.4,16.2,126.3,11.2,38.5,20.8,76.5,22.3,111.9,9,14.8,20.2,58.8,32.3,114.5,6.3,24.2,17.6,45.1,31.5,64.1,3.4,4.6,7.3,8.9,11.4,12.9,11.8,11.3,20.3,25.5,26,42.1h0Z"
          />
        </g>
        <g id="secondfinger1">
          <path
            id="fingerpart20"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M97.9,368.9c2,19.5.9,42-1.4,65.5,1.8,18.6,14.3,20.7,32,14.8,7.1-4.4,14.8-8.7,23.7-8.2,1.9,0,3.8-.2,5.4-1.1,10.3-5.8,10.3-16.9,3.5-31.6-5.3-14.9-11.6-32.5-14.6-41.1-9.7-34.5-20.1-66.3-31.6-94.8.3-22.4-5.2-32.5-14.9-33.4-14.5-2.9-26.2,1-36.5,8.8-10,12.5-8.6,22.4,0,30.7,10.5,6.6,16.3,14.9,19.7,24.6,8.4,19.1,13.1,41.2,14.6,65.9h0Z"
          />
          <path
            id="fingerpart19"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M114.6,234.9c-.5,5.4-2.2,8-6.4,5.2-11.3-6.2-22.9-6-34.6,0-6.8.2-11-5.4-11.5-19.5,5.9-6.1,8.1-14.2,8.5-23.1-1-17.9-.3-29.5,1.8-35.9-1.6-14.2-5.1-25.8-10.3-34.9-9.5-5.9-12-12.1-7.3-18.5,5.6-9.5,12.5-10.8,20.1-9.1,8.3,3.8,12.1,10.1,11.9,18.5,10.5,22,17.6,61.8,25.5,98.7,6.3,9.7,8.7,16.8,2.4,18.5h0Z"
          />
          <path
            id="fingerpart18"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M78.2,94.4c-6.7-3-13-3.1-19,0-3.6,4-5.3.7-5.9-6.8-4.4-4.3-2.4-10.3,2.3-16.9,5.6-8.2,9.1-17.7,11.5-27.8-4.1-7.7-1-14.8,4.2-21.8.9-1.1,2.1-2,3.4-2.5,4-1.4,7-.7,9.3,1.6.9.9,1.4,2.2,1.4,3.5v57.3c2.5,4.7,4.2,9.3,2.4,13.4-1.7,5.3-4.8,5.6-9.6,0h0Z"
          />
          <path
            id="fingerpart17"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M85.4,288.2c-.4-5.3-2.5-8.5-6.9-10.5-8.1-3.5-11.1-9.7-8.4-19.1,4-13.1,12.6-16.6,22.9-16.4,11.3,1,15.4,7.2,16.7,15.5"
          />
          <path
            id="fingerpart16"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M77.4,227c8.3.3,17.6,0,27.3-.9"
          />
          <path
            id="fingerpart15"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M71.8,232.3c9.4-2.4,19.8-1.8,30.8.8"
          />
          <path
            id="fingerpart14"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M81.8,109.6s2.2,19.8,3.6,27.5c8.7,52.4,17.3,74.5,25.9,81.9"
          />
          <path
            id="fingerpart13"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M71.8,216.1c8.7-14.7,13.2-31.4,10.6-51.8-1.6-16.7-6-31-14.3-42.1-7.3-3-10.9-7.2-11.6-12.6"
          />
          <path
            id="fingerpart12"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M60.1,90.1c5.8-2.4,11.5-2.4,17.3,0"
          />
          <path
            id="fingerpart11"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M81.2,26c-2.1,7.7-.2,16-1.5,22.5-1.8,22.8,1.2,30.1,5.7,32.5"
          />
          <line
            id="fingerpart10"
            class="rechter_finger2_lat_sitzend1318_cls3"
            x1="67.1"
            y1="42.9"
            x2="70.6"
            y2="34.4"
          />
          <path
            id="fingerpart9"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M56.6,79.3c12.6-11.8,17.6-26.9,17.6-44.2"
          />
          <path
            id="fingerpart8"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M72.4,161.7c3,10,3.6,21.8,2.7,34.6"
          />
          <g id="outlines2">
            <path
              id="outlinespart6"
              class="rechter_finger2_lat_sitzend1318_cls3"
              d="M48,364.6c.9,1.8,1.8,3.6,2.8,5.3"
            />
            <path
              id="outlinespart5"
              class="rechter_finger2_lat_sitzend1318_cls2"
              d="M227.8,476.9c-5.4-11.9-12.5-22.3-21.6-31-4.2-4-8-8.2-11.5-12.9-13.9-19.1-25.3-39.9-31.5-64.1-12.1-55.8-23.3-99.7-32.3-114.5-1.5-35.4-11.1-73.4-22.3-111.9-9.4-35.8-14.3-78.7-16.2-126.3-.2-4.2-2.1-8.1-5.4-10.7C81.5,1.4,74.2,0,65.4.7c-19.7,3.7-30,20.2-34.6,44.5l-5.8,77.9c-4.1,26.5-2.8,55.9,3.8,88.3-1.5,58.6,2,108.4,14.6,142.4"
            />
            <path
              id="outlinespart4"
              class="rechter_finger2_lat_sitzend1318_cls3"
              d="M232.2,488c-.7-1.9-1.3-3.8-2.1-5.6"
            />
          </g>
          <path
            id="fingerpart7"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M161.1,408.1c-13.1-8-22.7-21.5-29-40.5-11.6-37.5-19.6-70.6-22.3-97.2"
          />
          <path
            id="fingerpart6"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M107.8,424.1c7.4-16.6,8.9-34.9,6.7-54.1-5.8-38.7-15-68.3-27.3-89.3"
          />
          <path
            id="fingerpart5"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M121,433.2c9.1-4.4,18.2-6.7,27.3-6.8"
          />
          <line
            id="fingerpart4"
            class="rechter_finger2_lat_sitzend1318_cls3"
            x1="118.3"
            y1="443"
            x2="151.5"
            y2="433.2"
          />
          <path
            id="fingerpart3"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M.5,383.2c32.5,20.1,60.2,48.5,82.9,85.9,13.6,15.8,25.4,17.9,34.9,3.1,1.1-1.8,1.8-3.8,2.1-5.9,1.1-9,5.6-16.8,13.2-23.5,1.4-1.2,2.3-2.8,2.9-4.5,2.8-9.5,1.5-17.6-6.5-23.2l-48.8-22.9L1.8,343.6"
          />
          <path
            id="fingerpart2"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M113.3,414.4c-11,1.4-22.1,1.1-33.6-4.6-17.7-7.5-46.7-26.4-76.8-46.5"
          />
          <path
            id="fingerpart1"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M79.8,463.4c-.7-15.5-5.3-28.7-14.8-38.9-12.9-14.3-36.8-32.8-62-51.7"
          />
          <g id="outlines">
            <path
              id="outlinespart3"
              class="rechter_finger2_lat_sitzend1318_cls3"
              d="M84.6,375.5c1.5,1.3,3,2.6,4.5,4"
            />
            <path
              id="outlinespart2"
              class="rechter_finger2_lat_sitzend1318_cls1"
              d="M11.2,320c22.9,15.3,44.8,31.3,65.2,48.4"
            />
            <path
              id="outlinespart1"
              class="rechter_finger2_lat_sitzend1318_cls3"
              d="M1.7,313.7c1.7,1.1,3.4,2.2,5,3.3"
            />
          </g>
          <path
            id="fingerpart22"
            class="rechter_finger2_lat_sitzend1318_cls3"
            d="M122.3,480.8c.8-15,8.4-24.8,19.8-31.7,18.9-7.4,36.2-2.2,52.4,11.6,13.2,11.2,15.9,20.3,13,28.3"
          />
        </g>
      </g>
      <g id="highlights">
        <path
          id="highlights3"
          class="fullfinger1318fill"
          style={{
            fill: props.colors.fullfinger1318fill,
            stroke: props.colors.fullfinger1318fill,
          }}
          d="M78.2,94.4c-6.7-3-13-3.1-19,0-3.6,4-5.3.7-5.9-6.8-4.4-4.3-2.4-10.3,2.3-16.9,5.6-8.2,9.1-17.7,11.5-27.8-4.1-7.7-1-14.8,4.2-21.8.9-1.1,2.1-2,3.4-2.5,4-1.4,7-.7,9.3,1.6.9.9,1.4,2.2,1.4,3.5v57.3c2.5,4.7,4.2,9.3,2.4,13.4-1.7,5.3-4.8,5.6-9.6,0h0Z"
        />
        <path
          id="highlights2"
          class="fullfinger1318fill"
          style={{
            fill: props.colors.fullfinger1318fill,
            stroke: props.colors.fullfinger1318fill,
          }}
          d="M114.6,234.9c-.5,5.4-2.2,8-6.4,5.2-11.3-6.2-22.9-6-34.6,0-6.8.2-11-5.4-11.5-19.5,5.9-6.1,8.1-14.2,8.5-23.1-1-17.9-.3-29.5,1.8-35.9-1.6-14.2-5.1-25.8-10.3-34.9-9.5-5.9-12-12.1-7.3-18.5,5.6-9.5,12.5-10.8,20.1-9.1,8.3,3.8,12.1,10.1,11.9,18.5,10.5,22,17.6,61.8,25.5,98.7,6.3,9.7,8.7,16.8,2.4,18.5h0Z"
        />
        <path
          id="highlights1"
          class="fullfinger1318fill"
          style={{
            fill: props.colors.fullfinger1318fill,
            stroke: props.colors.fullfinger1318fill,
          }}
          d="M97.9,368.1c2,19.5.9,42-1.4,65.5,1.8,18.6,14.3,20.7,32,14.8,7.1-4.4,14.8-8.7,23.7-8.2,1.9,0,3.8-.2,5.4-1.1,10.3-5.8,10.3-16.9,3.5-31.6-5.3-14.9-11.6-32.5-14.6-41.1-9.7-34.5-20.1-66.3-31.6-94.8.3-22.4-5.2-32.5-14.9-33.4-14.5-2.9-26.2,1-36.5,8.8-10,12.5-8.6,22.4,0,30.7,10.5,6.6,16.3,14.9,19.7,24.6,8.4,19.1,13.1,41.2,14.6,65.9h0Z"
        />
        <path
          id="highlight5"
          class="fingerspitze1318fill"
          style={{
            fill: props.colors.fingerspitze1318fill,
            stroke: props.colors.fingerspitze1318fill,
          }}
          d="M85.4,32.2c-6-10.9-12.6-11-19.7-.4.9-4,2.8-7.8,6.2-11.3,4.4-4.1,10.7-4,13.2,1.5.6,2.7.5,6.4.3,10.3Z"
        />
        <ellipse
          id="highlight4"
          class="grundgelenk1318fill"
          style={{
            fill: props.colors.grundgelenk1318fill,
            stroke: props.colors.grundgelenk1318fill,
          }}
          cx="140.1"
          cy="446.9"
          rx="46.8"
          ry="20.5"
          transform="translate(-115.4 54.4) rotate(-15.7)"
        />
      </g>
    </svg>
  );
}

export default Rechter2FingerLatSitzend1318;
