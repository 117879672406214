import styles from "./TwoPartsDynamic.module.scss";

function TwoPartsDynamic({ leftComponent, rightComponent }) {
  return (
    <div className={styles.Controller}>
      {leftComponent}
      <div className={styles.content}>{rightComponent}</div>
    </div>
  );
}

export default TwoPartsDynamic;
