import style from "./style.scss";

function RechtesFersenbeinLatLiegend1448(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 516.1 363.3">

<g id="heel_bone" data-name="heel bone">
  <g id="tarsus">
    <path id="tarsus1" class="Kalkaneus_seitlich_rechts1448_cls5" d="M4.7,243.5c25.9,15.4,52.6,30.9,50,44.3-.6,11.3-23,19.6-54.5,26.7"/>
    <path id="tarsus2" class="Kalkaneus_seitlich_rechts1448_cls5" d="M65.4,27.5c.7,28.6,5.6,53.9,23.9,68.8,20.8,15.4,42.6,16.3,65.3,5.7,19-5.6,33.8-14.8,44.9-27.3,10.2-11.1,21.6-15.7,35.2-7.4l51.1,27.3c16.5,4.6,30.3-2.7,38.6-33,11-15.7-2.5-38.3-18.2-61.4"/>
    <path id="tarsus3" class="Kalkaneus_seitlich_rechts1448_cls5" d="M6.4,233.8l51.7,32.4c24,9.5,37.3,6.6,35.8-13.6l11.4-13.1c-6.9-10.2-10.2-21.5-11.4-33.5,3.3-28.7,2.8-60.6,0-94.3l-34.7-1.7-21-6.2c-8,23.2-17.3,40.5-28.4,50.6"/>
    <path id="tarsus4" class="Kalkaneus_seitlich_rechts1448_cls5" d="M3.5,123l19.4,26.8c2.7,3.7,7.4,5.3,11.8,4.1l19.4-5.4c28.1-6.7,44.9-29.9,48.3-72.7,4.8-6.5,2.8-12.5-7.9-17.6-15.6-3.5-28.6-11.2-38.6-23.3"/>
    <path id="tarsus5" class="Kalkaneus_seitlich_rechts1448_cls5" d="M190.4,44.6c8,1.6,15,8.2,18.8,29,4,17.3,9.7,29.8,22.2,22.2,23.9-20.7,52.9-31.4,89.2-27.8"/>
    <path id="tarsus6" class="Kalkaneus_seitlich_rechts1448_cls5" d="M56.4,28.7l1.1,29.5c4.2,20.7,15.9,38.7,36.4,53.4"/>
    <path id="tarsus7" class="Kalkaneus_seitlich_rechts1448_cls5" d="M348.4,55.4h0c5.9,2.6,11.1,6.8,14.2,14.8-2,10.6-7.3,13.7-15.3,10.8-8.8-4.3-13.5-9.7-11.4-16.5,2.9-6.1,6.8-9.6,12.5-9.1h0Z"/>
  </g>
  <g id="calcaneus">
    <path id="calcaneus1" class="Kalkaneus_seitlich_rechts1448_cls5" d="M387,332.7h0c22.7-5.9,45.9-24,69.3-47.7,5.3-4.3,7.1-9.5,2.8-16.5,1.4-12.5,3.6-20.8,6.8-23.9,2.4-4.3,3.2-8.1-1.7-9.7-2.2-14.4,1.5-30.9,9.1-48.9,1.9-17.3-9.4-23.3-28.4-22.7-38.1-10-68-28.2-91.5-52.8-15.7-28.4-34.3-39-55.1-35.8l-28.4,11.9c-17.8-12.1-33.3-18-45.4-15.3-18.1,15.1-36.9,27.2-56.2,36.9l-54.6-21.6c-11.5-11.6-20.8-14.5-27.8-7.4-6.3,10.8-8.5,20.5,4.6,26.7l2.8,14.8c11.6,7.1,19.1,17.7,18.8,34.7,1.5,21.9-3.2,28.6-10.2,29.6l4.6,15.3c-2,14.1-1.8,24.3,8.5,16.5,10.4-3.1,20.8,4.3,31.2,15.3,27.1,14.9,84.7,19.3,142.1,36.9,7.6,5.7,9,14.9,5.1,27.3-2.3,16,8.3,28.3,28.4,38.1,19.5,3,41.6,2,65.3-1.7h0Z"/>
    <path id="calcaneus2" class="Kalkaneus_seitlich_rechts1448_cls4" d="M314.3,77.6c-10.2,6.7-26.2,8.8-44.3,9.1-20.4,4.4-38.4,12.4-55.1,22.2-8.4,13.4-20,17.8-36.4,8.5l-12.5-9.1c-22.8,2.5-38.8-7.3-52.3-21.6"/>
    <path id="calcaneus3" class="Kalkaneus_seitlich_rechts1448_cls6" d="M136.5,110.5c-2.3,5.6-.9,11.1,4.6,16.5,6.1,1.5,7.7,4.4,6.8,7.9,5.1,6.3,10,5.6,14.8,1.1,14.3-3.8,27.8-4.6,40.3-2.3,13.6-3.3,27.7-12.3,42.1-25.6"/>
    <path id="calcaneus4" class="Kalkaneus_seitlich_rechts1448_cls6" d="M288.2,269c33.4,10.6,57.9,21.5,63.1,33"/>
    <line id="calcaneus5" class="Kalkaneus_seitlich_rechts1448_cls6" x1="132.5" y1="206" x2="137" y2="224.1"/>
  </g>
  <path id="outlines" class="Kalkaneus_seitlich_rechts1448_cls6" d="M36,337l106.7-31.5c16.4-4.8,33.9-3.9,49.6,2.7l65.1,27.4c19.6,8.2,40.4,13.1,61.6,14.3l28.7,1.7c38.2,2.2,76-9.5,106.2-32.9l3.5-2.8c26.9-20.8,42.7-52.7,43.1-86.6h0c.3-25.4-8-50.2-23.4-70.5l-30-39.4c-2.8-3.6-5.1-7.5-6.9-11.7l-41.3-94.7"/>
</g>
<g id="overlays">
  <path id="calcaneus_overlay" data-name="calcaneus overlay" class="Calcaneus1448Fill" style= {{
            fill: props.colors.Calcaneus1448Fill,
            stroke: props.colors.Calcaneus1448Fill,
            opacity:props.colors.Calcaneus1448Fill,
          }} d="M387,332.7h0c22.7-5.9,45.9-24,69.3-47.7,5.3-4.3,7.1-9.5,2.8-16.5,1.4-12.5,3.6-20.8,6.8-23.9,2.4-4.3,3.2-8.1-1.7-9.7-2.2-14.4,1.5-30.9,9.1-48.9,1.9-17.3-9.4-23.3-28.4-22.7-38.1-10-68-28.2-91.5-52.8-15.7-28.4-34.3-39-55.1-35.8l-28.4,11.9c-17.8-12.1-33.3-18-45.4-15.3-18.1,15.1-36.9,27.2-56.2,36.9l-54.6-21.6c-11.5-11.6-20.8-14.5-27.8-7.4-6.3,10.8-8.5,20.5,4.6,26.7l2.8,14.8c11.6,7.1,19.1,17.7,18.8,34.7,1.5,21.9-3.2,28.6-10.2,29.6l4.6,15.3c-2,14.1-1.8,24.3,8.5,16.5,10.4-3.1,20.8,4.3,31.2,15.3,27.1,14.9,84.7,19.3,142.1,36.9,7.6,5.7,9,14.9,5.1,27.3-2.3,16,8.3,28.3,28.4,38.1,19.5,3,41.6,2,65.3-1.7h0Z"/>
  <path id="lower_ankle_joint_overlay" data-name="lower ankle joint overlay" class="USG1448Fill" style= {{
            fill: props.colors.USG1448Fill,
            stroke: props.colors.USG1448Fill,
            opacity:props.colors.USG1448Fill,
          }} d="M322.4,68.7c-21-2.9-42-1-66.9,10.6l-38.1,1.8c-4,.4-7.8,1.7-11.1,3.9l-24.2,15.5c-3.4,2.2-7.5,3.1-11.6,2.5-21.3-3.1-41.7-13.1-61.3-28.1-4.4-3.3-9.5-5.6-14.9-6.3s-10.6.3-14.6,5c-3.3,3.1-4.6,7.5-4.5,12.8.1,6.2,2.3,12.1,5.9,17.1l2.7,3.8c2.1,2.9,3.6,6.2,4.5,9.6l1.1,4c.2.9.5,1.8.8,2.6l3.9,10c.9,2.4,1.4,5,1.4,7.6v44.1c-3.2,19-.2,37.1,9.7,54.2l6.5-20.7c-5.9,3.8-7.4-3.1-5.4-18.5l-4.6-15.3c7.9.1,11.5-9.7,10.2-30.9-.3-16.4-7.5-26.6-18.8-33.3l-2.8-14.8c-10.1-5-13.2-13.1-4.6-26.7,9.8-6.7,19-2.9,27.8,6.4,13.6,16.4,31.4,24.7,54.6,22.5,18.8,18.3,34,17.3,46.2.8,19.6-11.2,38.3-19.2,55.5-22.4,18.7.8,35.2-2.7,49.7-9.9l2.7-8.1h0Z"/>
  <path id="softpart" class="Weichteile1448Fill" style= {{
            fill: props.colors.Weichteile1448Fill,
            stroke: props.colors.Weichteile1448Fill,
            opacity:props.colors.Weichteile1448Fill,
          }} d="M393,15c-1.5,3.2,2.9,6.9,8,15,5.5,8.8,3.6,9.7,11,27,5.8,13.6,6.7,12.3,13,27,5.3,12.4,4.6,13.1,9,22,.9,1.9,5.4,10.9,13,22,5.7,8.3,9.7,12.7,14,18,7.5,9.2,18,22.2,25,38,1.8,4,9.5,22.2,9,47-.1,7.4-.6,22.5-8,40-8,18.9-19.8,30.2-27,37-19.8,18.7-40.3,26.2-51,30-29.5,10.5-54.2,9.6-72,9-15.9-.6-39.1-1.6-66-12-20.8-8-22.3-13.4-52-25-27-10.6-39.9-11.7-45-12-11.9-.7-21.6,1.2-41,5-27.7,5.4-27.6,8.2-60,16-29.1,7-43.5,8.3-45,16-.8,4.1,1.9,11.8,6,11s11.4-2.4,25-8c3.6-1.5,11.7-3.7,28-8,21.1-5.6,20.6-4.6,31-8,14.7-4.8,15.5-6.7,26-9,4.5-1,13.9-2.9,25-2,10.5.9,16.9,3.9,31,10,15.2,6.6,30.7,12.6,46,19,22.1,9.2,33.1,13.8,38,15,10.4,2.5,16.1,2.5,54,3,45.2.5,45.8.6,50,0,26.7-4.1,45.7-15,56-21,18.2-10.6,36.7-21.5,50-44,15.1-25.6,14.4-51,14-65-.3-10.4-1.3-35.7-16-61-5.7-9.8-7.3-9-22-30-9.2-13.1-17.1-24.4-25-39-15.3-28.4-23-42.6-27-62-1.7-8.3-5-14-11-23s-12.4-1.3-14,2Z"/>
</g>
</svg>
  );
}

export default RechtesFersenbeinLatLiegend1448;
