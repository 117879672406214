import image11 from "./11xx_patprep.svg";
import image12 from "./12xx_patprep.svg";
import image14 from "./14xx_patprep.svg";
import image15 from "./15xx_patprep.svg";
import image17 from "./17xx_patprep.svg";
import image18 from "./18xx_patprep.svg";
import image20 from "./20xx_patprep.svg";
import image21 from "./21xx_patprep.svg";
import image23 from "./23xx_patprep.svg";
import image24 from "./24xx_patprep.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  image11,
  image12,
  image14,
  image15,
  image17,
  image18,
  image20,
  image21,
  image23,
  image24,
};
