import {
  SET_XMSTATS_FILTER_OBJ,
  RESET_XMSTATS_FILTER_OBJ,
  SET_XMSTATS_SELECTED_EXAM,
  SET_XMSTATS_SELECTED_SERIE,
  SET_XMSTATS_SELECTED_IMAGE,
  SET_XMSTATS_CHANGES_MADE,
  SET_XMSTATS_RELOAD_FLAG,
  SET_XMSTATS_SELECTED_SUBSTANTIATION,
  RESET_XMSTATS_STATE,
  INCRASE_XMSTATS_SHOULD_SAVE_COUNT,
  SET_XMSTATS_SELECTED_MODALITY,
  SET_XMSTATS_RELOAD_EXAM_FLAG,
} from "../actionTypes";

const initialState = {
  filterObj: undefined,
  selectedModality: "Xray",
  selectedExam: undefined,
  selectedSerie: undefined,
  selectedImage: undefined,
  changesMade: false,
  reloadFlag: false,
  reloadExamFlag: false,
  shouldSaveFlag: false,
  shouldSaveCount: 0,
  selectedSubstantiation: undefined,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_XMSTATS_FILTER_OBJ: {
      const { content } = action.payload;
      if (state.changesMade) {
        return {
          ...state,
          shouldSaveFlag: true,
          shouldSaveCount: state.shouldSaveCount + 1,
        };
      }
      return {
        ...state,
        filterObj: content,
      };
    }
    case SET_XMSTATS_SELECTED_MODALITY: {
      const { content } = action.payload;
      if (state.changesMade) {
        return {
          ...state,
          shouldSaveFlag: true,
          shouldSaveCount: state.shouldSaveCount + 1,
        };
      }
      return {
        ...state,
        selectedExam: undefined,
        selectedSerie: undefined,
        selectedImage: undefined,
        selectedModality: content,
      };
    }
    case RESET_XMSTATS_FILTER_OBJ: {
      if (state.changesMade) {
        return {
          ...state,
          shouldSaveFlag: true,
          shouldSaveCount: state.shouldSaveCount + 1,
        };
      }
      return {
        ...state,
        filterObj: initialState.filterObj,
      };
    }
    case SET_XMSTATS_SELECTED_EXAM: {
      const { content } = action.payload;
      if (state.changesMade) {
        return {
          ...state,
          shouldSaveFlag: true,
          shouldSaveCount: state.shouldSaveCount + 1,
        };
      }
      return {
        ...state,
        selectedImage: initialState.selectedImage,
        selectedSerie: initialState.selectedSerie,
        selectedExam: content,
      };
    }
    case SET_XMSTATS_SELECTED_SERIE: {
      const { content } = action.payload;
      if (state.changesMade) {
        return {
          ...state,
          shouldSaveFlag: true,
          shouldSaveCount: state.shouldSaveCount + 1,
        };
      }
      return {
        ...state,
        selectedSerie: content,
      };
    }
    case SET_XMSTATS_SELECTED_IMAGE: {
      const { content } = action.payload;
      if (state.changesMade) {
        return {
          ...state,
          shouldSaveFlag: true,
          shouldSaveCount: state.shouldSaveCount + 1,
        };
      }
      return {
        ...state,
        selectedImage: content,
      };
    }
    case SET_XMSTATS_CHANGES_MADE: {
      const { content } = action.payload;
      return {
        ...state,
        shouldSaveFlag: !content ? false : true,
        shouldSaveCount: !content ? 0 : state.shouldSaveCount,
        changesMade: content,
      };
    }
    case SET_XMSTATS_RELOAD_FLAG: {
      const { content } = action.payload;
      return {
        ...state,
        reloadFlag: content,
      };
    }
    case SET_XMSTATS_RELOAD_EXAM_FLAG: {
      const { content } = action.payload;
      return {
        ...state,
        reloadExamFlag: content,
      };
    }
    case SET_XMSTATS_SELECTED_SUBSTANTIATION: {
      const { content } = action.payload;
      return {
        ...state,
        selectedSubstantiation: content,
      };
    }
    case RESET_XMSTATS_STATE: {
      const { content } = action.payload; //conent = skip Filter reset
      if (state.changesMade) {
        return {
          ...state,
          shouldSaveFlag: true,
          shouldSaveCount: state.shouldSaveCount + 1,
        };
      }
      if (content) {
        return {
          filterObj: state.filterObj,
          selectedModality: initialState.selectedModality,
          selectedExam: initialState.selectedExam,
          selectedSerie: initialState.selectedSerie,
          selectedImage: initialState.selectedImage,
          changesMade: initialState.changesMade,
          reloadFlag: initialState.reloadFlag,
          reloadExamFlag: initialState.reloadExamFlag,
          shouldSaveFlag: initialState.shouldSaveFlag,
          shouldSaveCount: initialState.shouldSaveCount,
          selectedSubstantiation: initialState.selectedSubstantiation,
        };
      } else {
        return initialState;
      }
    }
    case INCRASE_XMSTATS_SHOULD_SAVE_COUNT: {
      return {
        ...state,
        shouldSaveCount: state.shouldSaveCount + 1,
      };
    }
    default: {
      return state;
    }
  }
}
