import style from "./style.scss";

export default function BrustbeinLatStehend2029(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 106 612.82"
    >
      <g id="Sternum">
        <path
          id="corpus_BG"
          data-name="corpus BG"
          class="sternum_lat2029_cls8"
          style={{
            fill: props.colors.sternum_lat2029_cls8,
            stroke: props.colors.sternum_lat2029_cls8,
          }}
          d="M25.56,509c1.98-.76,3.37-2.79,6.15-6.84,3.58-5.2,3.12-6.37,5.47-8.2,3.15-2.46,4.98-1.13,8.2-3.42,2.7-1.92,3.34-4.23,5.47-8.89,2.98-6.51,2.81-4.31,7.52-13.67,4.13-8.2,6.2-12.31,7.52-17.09,1.81-6.57-.36-10.44,1.93-20.51,1.06-4.67,3.23-5.35,6.27-10.94,2.78-5.12,4.07-5.39,5.48-9.09,1.95-5.12,1.28-9.36.67-14.84-1.4-12.51-1.99-20.51-1.37-28.03.74-8.96.54-1.32,4.78-19.82,3.17-13.81,3.34-16.19,2.75-19.34-.67-3.59-2.11-6.99-2.75-13.47-.32-3.25-.33-6.18-.33-6.83,0-2.01,0-4.24.33-6.84.6-4.6,1.52-4.46,2.05-8.2.75-5.26-.98-6.16.01-11.14.37-1.84.54-1.41,2.31-6.5,1.66-4.77,2.51-7.23,3.03-10.3.88-5.19,1.4-7.82.12-10.33-1.86-3.65-5.8-5.13-7.52-10.25-.94-2.81-.41-4.32,0-9.57.83-10.58-.91-14.21.68-22.56.69-3.59,1.34-5.02,2.05-6.15,2.17-3.45,5.89-3.35,6.15-6.84.93-12.3.89-5.57.68-6.84-.8-4.84-4.36-9.41-8.89-10.94-3.63-1.23-4.49.62-10.94,0-5.81-.56-6.07-2.14-10.25-2.05-4.32.09-10.26,1.92-12.3,6.15-1.23,2.56-.48,4.83.68,9.57,2.65,10.78,3.88,17.11,4.1,23.92.16,5.07-.55,3.92-.67,12.78-.1,7.43.39,9.72,0,15.92-.3,4.68-.66,4.71-1.43,11.45-.25,2.15-.26,3.75-1.31,19.31-.59,8.82-.88,14.57-1.04,18.56-.12,3.03-.12,5.8-.47,10.02-.1,1.27-.48,5.81-1.21,10.18-1.53,9.18-3.36,10.04-4.8,17.29-1.47,7.41-1.04,13.88-.68,19.14,0,0,.58,9.6-.71,16.5-.16.86-.37,1.7-.65,2.64-2.27,7.6-4.72,8.68-6.14,15.52-1.4,6.73.71,6.86-.7,14.56-.45,2.47-1.67,5.55-4.1,11.62-5.62,14.05-5.21,12.52-6.44,18-.61,2.72-2.4,9.79-2.45,18.91-.08,15.95,1,20.75-2.73,24.61-2.56,2.65-4.5,1.86-7.52,5.47-3.91,4.69-4.09,10.14-4.1,10.94-.04,2.18.27,7.94,4.78,12.3,2.67,2.58,7.94,5.78,12.3,4.1l.03.03h-.01Z"
        />
        <path
          id="manubrium"
          class="sternum_lat2029_cls4"
          style={{
            fill: props.colors.sternum_lat2029_cls4,
            stroke: props.colors.sternum_lat2029_cls4,
          }}
          d="M46.29,176.78c-2.4-2.57-2.23-5.72-1.37-17.77,1.58-22.02,1.95-24.49,2.05-30.08.23-12.78.35-19.17-2.13-25.88-3.27-8.86-7.72-11.91-10.17-23.34-2.09-9.77.13-12.39.68-12.99,1.08-1.15,5.74-1.69,15.04-2.73,2.51-.28,5.83-.64,10.25-.68,6.66-.06,8.04.68,8.89,1.37,2.58,2.1,2.69,5.88,2.73,8.89.1,6.38-1.97,9.12-3.42,15.04-1.28,5.21-1.08,9.4-.68,17.77.31,6.49,1.12,10.93,2.73,19.82,1.68,9.25,2.53,13.88,4.1,17.77,2.46,6.07,3.77,6.14,7.52,15.04,1.68,3.98,2.48,6.76,4.1,12.3,1.95,6.7,1.76,7.56,1.37,8.2-1.8,2.91-7.66.98-20.51,0-14.65-1.12-18.14.53-21.19-2.73h.01Z"
        />
        <path
          class="sternum_lat2029_cls1"
          style={{
            fill: props.colors.sternum_lat2029_cls1,
            stroke: props.colors.sternum_lat2029_cls1,
          }}
          d="M38.09.42c3.9,2.46,8.42,5.8,12.99,10.25,4.23,4.13,7.64,7.46,10.25,12.99,1.97,4.18,4.66,9.88,2.73,16.41-1.85,6.26-6.87,9.59-8.89,10.94-2.46,1.64-5.8,3.86-10.25,3.42-4.39-.43-7.28-3.19-8.2-4.1-3.09-3.03-3.32-5.9-4.78-8.89-1.71-3.47-5.41-7.77-14.35-10.94"
        />
        <path
          id="Schwertfortsatz"
          class="sternum_lat2029_cls4"
          style={{
            fill: props.colors.sternum_lat2029_cls4,
            stroke: props.colors.sternum_lat2029_cls4,
          }}
          d="M20.32,612.19c.79-.18,1.33-1.5,2.05-17.09.53-11.51.26-11.37.68-17.09.82-11.13,1.86-11.95,1.37-17.77-.59-6.97-2.17-6.75-2.73-13.67-.34-4.15.22-8.75,1.37-17.77,1.34-10.62,2.02-12.29.68-15.04-2.49-5.11-9.4-8.73-13.67-6.84-2.25,1-3.35,4.1-5.47,10.25-1.81,5.27-3.1,9.01-3.42,14.35-.4,6.66.94,11.71,2.05,15.72,2.21,8.02,3.97,8.16,6.84,17.09,1.37,4.27,3.06,9.53,3.42,16.41.48,9.17-1.96,10.74-.68,17.77,1.33,7.32,5.53,14.12,7.52,13.67h-.01Z"
        />
        <g id="ribs">
          <path
            class="sternum_lat2029_cls1"
            style={{
              fill: props.colors.sternum_lat2029_cls1,
              stroke: props.colors.sternum_lat2029_cls1,
            }}
            d="M38.09,252.65c3.96,4.81,6.95,8.88,8.89,11.62,6.63,9.36,8.66,13.99,15.04,19.14,6.09,4.92,9.46,4.93,10.94,4.78,3.36-.34,5.62-2.38,8.2-4.78,2.17-2.02,7.73-7.34,9.57-16.41.65-3.2,2.29-11.3-2.05-17.77-2.37-3.54-4.53-3.6-12.3-8.89-5.16-3.51-8.55-6.43-12.99-10.25-4.68-4.03-10.99-10.04-17.77-18.46"
          />
          <path
            class="sternum_lat2029_cls1"
            style={{
              fill: props.colors.sternum_lat2029_cls1,
              stroke: props.colors.sternum_lat2029_cls1,
            }}
            d="M36.72,287.51c3.34,4.07,6.6,7.46,9.57,10.25,5.94,5.59,8.27,6.5,16.41,13.67,6.63,5.84,5.16,5.3,10.25,9.57,7.98,6.68,10.96,7.48,12.3,11.62,1.85,5.7-1.47,11.32-2.05,12.3-2.58,4.37-6.42,6.28-10.25,8.2-.87.43-6.52,3.25-10.94,3.42-8.86.34-18.68-9.74-23.92-24.61"
          />
          <path
            class="sternum_lat2029_cls1"
            style={{
              fill: props.colors.sternum_lat2029_cls1,
              stroke: props.colors.sternum_lat2029_cls1,
            }}
            d="M38.77,368.85c4.24,4.43,8.22,8.06,11.62,10.94,4.32,3.65,7.25,6.13,11.62,8.89,8.35,5.27,10.91,4.61,12.99,8.2,3.51,6.08-.41,13.84-.68,14.35-3.24,6.18-9.24,8.26-11.62,8.89-6.79,1.78-12.5-.9-16.41-2.73-5.81-2.73-9.35-6.4-15.04-12.3-3.64-3.78-6.37-7.16-8.2-9.57"
          />
          <path
            class="sternum_lat2029_cls1"
            style={{
              fill: props.colors.sternum_lat2029_cls1,
              stroke: props.colors.sternum_lat2029_cls1,
            }}
            d="M22.64,428.32c5.09,3.87,9.59,6.8,12.99,8.89,5.97,3.66,9.06,5.52,13.67,6.84,5.36,1.53,9.64,2.42,12.3,1.37,14.1-5.58,1.78-20.85-6.15-21.19"
          />
          <path
            class="sternum_lat2029_cls1"
            style={{
              fill: props.colors.sternum_lat2029_cls1,
              stroke: props.colors.sternum_lat2029_cls1,
            }}
            d="M.5,450.19c1.05,7.66,3.09,13.68,4.78,17.77,3.12,7.54,5.92,14.31,12.99,19.14,2.16,1.47,7.32,4.54,14.35,4.78,3.48.12,10.82.37,15.04-4.78,3.21-3.92,2.88-8.85,2.73-10.94-.66-9.84-8.59-15.88-10.25-17.09"
          />
          <path
            class="sternum_lat2029_cls1"
            style={{
              fill: props.colors.sternum_lat2029_cls1,
              stroke: props.colors.sternum_lat2029_cls1,
            }}
            d="M17.58,63.31c37.92,14.73,66.98,13.75,38.96-19.82"
          />
        </g>
        <g id="corpus">
          <path
            class="sternum_lat2029_cls1"
            style={{
              fill: props.colors.sternum_lat2029_cls1,
              stroke: props.colors.sternum_lat2029_cls1,
            }}
            d="M24.42,508.98c2.43-.46,4.42-2.18,8.89-9.57,5.2-8.62,7.15-13.98,10.25-20.51,6.36-13.36,7.94-11.26,12.3-21.87,3.54-8.62,2.12-9.07,6.84-21.19,3.04-7.82,4.45-9.72,5.47-15.04,1.34-6.98.27-10.73,0-17.09-.39-9.04,1.41-9.79,5.47-32.13,1.76-9.67,2.75-16.83,4.1-26.66,2.3-16.64,1.64-15.67,3.42-28.03,3.52-24.48,5.75-25.86,4.78-36.23-.69-7.41-2.06-9.26-2.73-19.82-.44-6.85-.25-12.27,0-19.82.47-13.89,1.28-11,1.37-20.51.07-8.19-.52-11.63,2.05-15.72,2.19-3.49,4.18-3.48,5.47-6.84,1.67-4.36.51-10.11-2.73-13.67-3.32-3.64-7.8-3.9-15.78-4.21-10.51-.42-19.09-.76-22.5,4.21-2.51,3.67-.1,7.12,2.05,19.14,1.26,7.04,1.52,13.81,2.05,27.34.44,11.37-.25,9.4,0,28.03.14,10.71.39,13.57.58,28.02.17,13.03.23,13.97.1,15.73-.81,10.71-2.74,11.12-4.78,25.29-1.04,7.19-1.57,14.25-2.05,20.51-.46,6.12-.35,6.26-.68,10.25-.74,8.69-1.96,15.03-3.42,23.24-1.66,9.34-3.41,16.68-5.47,25.29-2.62,10.98-2.68,9.95-4.78,19.14-2.82,12.32-2.64,13.86-4.78,20.51-3.1,9.61-4.2,8.67-5.47,15.04-1.39,6.97-.68,11.14-4.1,14.35-2.56,2.4-4.17,1.22-6.84,3.42-4.76,3.93-6.04,13-2.73,19.82,2.45,5.06,8.26,10.59,13.67,9.57h-.02,0Z"
          />
          <path
            class="sternum_lat2029_cls1"
            style={{
              fill: props.colors.sternum_lat2029_cls1,
              stroke: props.colors.sternum_lat2029_cls1,
            }}
            d="M88.25,274.17c-6.24,17-5.34,35.17-3.67,43.41.86,4.27,1.61,5.28,2.05,9.57,1.33,12.97-4.13,17.5-6.84,32.13-2.07,11.2-1.23,21.36-.68,28.03.99,12.07,2.98,15.28.68,21.87-2.37,6.8-6.14,8.15-9.57,16.41-2.57,6.19-3.12,11.88-3.19,15.69"
          />
          <path
            class="sternum_lat2029_cls1"
            style={{
              fill: props.colors.sternum_lat2029_cls1,
              stroke: props.colors.sternum_lat2029_cls1,
            }}
            d="M20.32,476.17c1.09-8.38,1.69-15.57,2.05-21.19.68-10.78.23-12.33,1.37-17.77.44-2.12.78-3.16,6.84-19.82,4.25-11.7,4.51-12.32,4.78-14.35.88-6.42-.39-9.06,1.37-15.72,1.04-3.95,1.6-3.43,3.42-8.89.71-2.12,2.69-8.4,3.42-16.41.56-6.18-.33-5.69,0-12.99.34-7.61,1.55-13.5,2.73-19.14,1.65-7.88,2.33-8.3,3.42-13.67,1.9-9.41,1.54-16.67,1.37-21.87-.41-12.25,3.61-43.09,3.97-67.01"
          />
          <path
            class="sternum_lat2029_cls1"
            style={{
              fill: props.colors.sternum_lat2029_cls1,
              stroke: props.colors.sternum_lat2029_cls1,
            }}
            d="M47.66,487.11c7.38-16.04,18.78-30.79,19.36-45.83"
          />
        </g>
      </g>
      <g id="overlays">
        <g
          id="overlay_sternum"
          data-name="overlay sternum"
          class="sternum_lat2029_cls5"
          style={{
            fill: props.colors.sternum_lat2029_cls5,
            stroke: props.colors.sternum_lat2029_cls5,
          }}
        >
          <path
            id="Schwertfortsatz-2"
            class="sternum2029Fill"
            style={{
              fill: props.colors.sternum2029Fill,
              stroke: props.colors.sternum2029Fill,
            }}
            d="M20.32,612.19c.79-.18,1.33-1.5,2.05-17.09.53-11.51.26-11.37.68-17.09.82-11.13,1.86-11.95,1.37-17.77-.59-6.97-2.17-6.75-2.73-13.67-.34-4.15.22-8.75,1.37-17.77,1.34-10.62,2.02-12.29.68-15.04-2.49-5.11-9.4-8.73-13.67-6.84-2.25,1-3.35,4.1-5.47,10.25-1.81,5.27-3.1,9.01-3.42,14.35-.4,6.66.94,11.71,2.05,15.72,2.21,8.02,3.97,8.16,6.84,17.09,1.37,4.27,3.06,9.53,3.42,16.41.48,9.17-1.96,10.74-.68,17.77,1.33,7.32,5.53,14.12,7.52,13.67h-.01Z"
          />
          <path
            id="manubrium-2"
            class="sternum2029Fill"
            style={{
              fill: props.colors.sternum2029Fill,
              stroke: props.colors.sternum2029Fill,
            }}
            d="M46.54,177.2c-2.4-2.57-2.23-5.72-1.37-17.77,1.58-22.02,1.95-24.49,2.05-30.08.23-12.78.35-19.17-2.13-25.88-3.27-8.86-7.72-11.91-10.17-23.34-2.09-9.77.13-12.39.68-12.99,1.08-1.15,5.74-1.69,15.04-2.73,2.51-.28,5.83-.64,10.25-.68,6.66-.06,8.04.68,8.89,1.37,2.58,2.1,2.69,5.88,2.73,8.89.1,6.38-1.97,9.12-3.42,15.04-1.28,5.21-1.08,9.4-.68,17.77.31,6.49,1.12,10.93,2.73,19.82,1.68,9.25,2.53,13.88,4.1,17.77,2.46,6.07,3.77,6.14,7.52,15.04,1.68,3.98,2.48,6.76,4.1,12.3,1.95,6.7,1.76,7.56,1.37,8.2-1.8,2.91-7.66.98-20.51,0-14.65-1.12-18.14.53-21.19-2.73h.01Z"
          />
          <path
            class="sternum2029Fill"
            style={{
              fill: props.colors.sternum2029Fill,
              stroke: props.colors.sternum2029Fill,
            }}
            d="M25.77,507.23c1.98-.76,3.37-2.79,6.15-6.84,3.58-5.2,3.12-6.37,5.47-8.2,3.15-2.46,4.98-1.13,8.2-3.42,2.7-1.92,3.34-4.23,5.47-8.89,2.98-6.51,2.81-4.31,7.52-13.67,4.13-8.2,6.2-12.31,7.52-17.09,1.81-6.57-.36-10.44,1.93-20.51,1.06-4.67,3.23-5.35,6.27-10.94,2.78-5.12,4.07-5.39,5.48-9.09,1.95-5.12,1.28-9.36.67-14.84-1.4-12.51-1.99-20.51-1.37-28.03.74-8.96.54-1.32,4.78-19.82,3.17-13.81,3.34-16.19,2.75-19.34-.67-3.59-2.11-6.99-2.75-13.47-.32-3.25-.33-6.18-.33-6.83,0-2.01,0-4.24.33-6.84.6-4.6,1.52-4.46,2.05-8.2.75-5.26-.98-6.16.01-11.14.37-1.84.54-1.41,2.31-6.5,1.66-4.77,2.51-7.23,3.03-10.3.88-5.19,1.4-7.82.12-10.33-1.86-3.65-5.8-5.13-7.52-10.25-.94-2.81-.41-4.32,0-9.57.83-10.58-.91-14.21.68-22.56.69-3.59,1.34-5.02,2.05-6.15,2.17-3.45,5.89-3.35,6.15-6.84.93-12.3.89-5.57.68-6.84-.8-4.84-4.36-9.41-8.89-10.94-3.63-1.23-4.49.62-10.94,0-5.81-.56-6.07-2.14-10.25-2.05-4.32.09-10.26,1.92-12.3,6.15-1.23,2.56-.48,4.83.68,9.57,2.65,10.78,3.88,17.11,4.1,23.92.16,5.07-.55,3.92-.67,12.78-.1,7.43.39,9.72,0,15.92-.3,4.68-.66,4.71-1.43,11.45-.25,2.15-.26,3.75-1.31,19.31-.59,8.82-.88,14.57-1.04,18.56-.12,3.03-.12,5.8-.47,10.02-.1,1.27-.48,5.81-1.21,10.18-1.53,9.18-3.36,10.04-4.8,17.29-1.47,7.41-1.04,13.88-.68,19.14,0,0,.58,9.6-.71,16.5-.16.86-.37,1.7-.65,2.64-2.27,7.6-4.72,8.68-6.14,15.52-1.4,6.73.71,6.86-.7,14.56-.45,2.47-1.67,5.55-4.1,11.62-5.62,14.05-5.21,12.52-6.44,18-.61,2.72-2.4,9.79-2.45,18.91-.08,15.95,1,20.75-2.73,24.61-2.56,2.65-4.5,1.86-7.52,5.47-3.91,4.69-4.09,10.14-4.1,10.94-.04,2.18.27,7.94,4.78,12.3,2.67,2.58,7.94,5.78,12.3,4.1l.03.03h-.01Z"
          />
        </g>
        <path
          id="overlay_Gelenk"
          data-name="overlay Gelenk"
          class="Gelenk2029Fill"
          style={{
            fill: props.colors.Gelenk2029Fill,
            stroke: props.colors.Gelenk2029Fill,
          }}
          d="M106,181.92c0,8.01-14.84,14.5-35.6,14.5s-37.6-6.49-37.6-14.5,16.84-14.5,37.6-14.5,35.6,6.49,35.6,14.5Z"
        />
        <path
          id="overlay_manubrium"
          data-name="overlay manubrium"
          class="Manubrium2029Fill"
          style={{
            fill: props.colors.Manubrium2029Fill,
            stroke: props.colors.Manubrium2029Fill,
          }}
          d="M45.91,177.02c-2.4-2.57-2.23-5.72-1.37-17.77,1.58-22.02,1.95-24.49,2.05-30.08.23-12.78.35-19.17-2.13-25.88-3.27-8.86-7.72-11.91-10.17-23.34-2.09-9.77.13-12.39.68-12.99,1.08-1.15,5.74-1.69,15.04-2.73,2.51-.28,5.83-.64,10.25-.68,6.66-.06,8.04.68,8.89,1.37,2.58,2.1,2.69,5.88,2.73,8.89.1,6.38-1.97,9.12-3.42,15.04-1.28,5.21-1.08,9.4-.68,17.77.31,6.49,1.12,10.93,2.73,19.82,1.68,9.25,2.53,13.88,4.1,17.77,2.46,6.07,3.77,6.14,7.52,15.04,1.68,3.98,2.48,6.76,4.1,12.3,1.95,6.7,1.76,7.56,1.37,8.2-1.8,2.91-7.66.98-20.51,0-14.65-1.12-18.14.53-21.19-2.73h0Z"
        />
        <path
          id="overlay_corpus"
          data-name="overlay corpus"
          class="corpus2029Fill"
          style={{
            fill: props.colors.corpus2029Fill,
            stroke: props.colors.corpus2029Fill,
          }}
          d="M25.14,508.05c1.98-.76,3.37-2.79,6.15-6.84,3.58-5.2,3.12-6.37,5.47-8.2,3.15-2.46,4.98-1.13,8.2-3.42,2.7-1.92,3.34-4.23,5.47-8.89,2.98-6.51,2.81-4.31,7.52-13.67,4.13-8.2,6.2-12.31,7.52-17.09,1.81-6.57-.36-10.44,1.93-20.51,1.06-4.67,3.23-5.35,6.27-10.94,2.78-5.12,4.07-5.39,5.48-9.09,1.95-5.12,1.28-9.36.67-14.84-1.4-12.51-1.99-20.51-1.37-28.03.74-8.96.54-1.32,4.78-19.82,3.17-13.81,3.34-16.19,2.75-19.34-.67-3.59-2.11-6.99-2.75-13.47-.32-3.25-.33-6.18-.33-6.83,0-2.01,0-4.24.33-6.84.6-4.6,1.52-4.46,2.05-8.2.75-5.26-.98-6.16.01-11.14.37-1.84.54-1.41,2.31-6.5,1.66-4.77,2.51-7.23,3.03-10.3.88-5.19,1.4-7.82.12-10.33-1.86-3.65-5.8-5.13-7.52-10.25-.94-2.81-.41-4.32,0-9.57.83-10.58-.91-14.21.68-22.56.69-3.59,1.34-5.02,2.05-6.15,2.17-3.45,5.89-3.35,6.15-6.84.93-12.3.89-5.57.68-6.84-.8-4.84-4.36-9.41-8.89-10.94-3.63-1.23-4.49.62-10.94,0-5.81-.56-6.07-2.14-10.25-2.05-4.32.09-10.26,1.92-12.3,6.15-1.23,2.56-.48,4.83.68,9.57,2.65,10.78,3.88,17.11,4.1,23.92.16,5.07-.55,3.92-.67,12.78-.1,7.43.39,9.72,0,15.92-.3,4.68-.66,4.71-1.43,11.45-.25,2.15-.26,3.75-1.31,19.31-.59,8.82-.88,14.57-1.04,18.56-.12,3.03-.12,5.8-.47,10.02-.1,1.27-.48,5.81-1.21,10.18-1.53,9.18-3.36,10.04-4.8,17.29-1.47,7.41-1.04,13.88-.68,19.14,0,0,.58,9.6-.71,16.5-.16.86-.37,1.7-.65,2.64-2.27,7.6-4.72,8.68-6.14,15.52-1.4,6.73.71,6.86-.7,14.56-.45,2.47-1.67,5.55-4.1,11.62-5.62,14.05-5.21,12.52-6.44,18-.61,2.72-2.4,9.79-2.45,18.91-.08,15.95,1,20.75-2.73,24.61-2.56,2.65-4.5,1.86-7.52,5.47-3.91,4.69-4.09,10.14-4.1,10.94-.04,2.18.27,7.94,4.78,12.3,2.67,2.58,7.94,5.78,12.3,4.1l.03.03h-.01Z"
        />
      </g>
    </svg>
  );
}
