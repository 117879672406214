import style from "./style.scss";

function SchadelPaSitzend1902(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 432.76 679.32">

    <g id="skull_overview" data-name="skull overview">
      <path id="background" class="schadel_pa_sitzend_links1902_cls6" d="M133.43,621.71c-7.9-6.6-11.9-9.8-16-15-10.2-12.7-8.3-18.8-18-29-9.2-9.7-13.3-6.8-19-15s-4.5-15.4-6-32c-1.7-19.5-3.9-16.2-6-36-3.2-30.8,1.4-44.7-9-54-2.8-2.5-4.4-2.7-9-7-2.1-2-9.2-9-14-20-4.8-11.1-4.9-20.8-5-34-.1-14.2,1.9-16.8,0-27-1.4-7.6-3.2-9.3-6-19-2.9-9.8-3.7-16.7-5-27-2.9-22.5-5.1-39.1-8-54-5.2-26.5-7.7-21.4-10-39-2.2-16.4.2-19.6,0-58-.1-23.2-.9-22.5,0-29,3.5-24.1,16.1-41,23-50,17.6-23,37.6-34.2,57-45,15.8-8.8,42.5-23.4,79-28,5.8-.7.7.2,59-2,23.6-.9,28.7-1.2,38,0,5.9.8,17.2,2.6,50,16,26.5,10.8,39.8,16.2,52,24,13.4,8.5,30.9,19.6,45,40,15.7,22.6,19,44.6,23,72,4.8,32.7,2.3,59,1,70-3.8,31.2-5.7,46.8-13,63-3.9,8.7-9.9,19.2-8,33,1.1,8.1,4,10.6,4,20s-2.8,11-5,24c-2.6,14.8.6,14.8-2,24-3.2,11.2-8.4,12.9-15,26-2.2,4.4-4.3,9.5-8,25-5.9,24.7-11.4,47.4-13,78-.5,9.3-.8,22.1-6,37-2.4,6.8-5.4,13-10,19-4.5,5.8-7.6,7.8-12,12-11,10.7-9.9,17.1-18,28-11.8,15.9-28.8,22.3-36,25-5.1,1.9-14.8,3.3-34,6-19.8,2.8-30,4.2-44,5-17.9,1-26.8,1.5-33,0-15-3.7-25.4-12.4-43-27h0Z"/>
      <path id="outlinke_skull" data-name="outlinke skull" class="schadel_pa_sitzend_links1902_cls2" d="M133.43,622.29c-7.9-6.6-11.9-9.8-16-15-10.2-12.7-8.3-18.8-18-29-9.2-9.7-13.3-6.8-19-15s-4.5-15.4-6-32c-1.7-19.5-3.9-16.2-6-36-3.2-30.8,1.4-44.7-9-54-2.8-2.5-4.4-2.7-9-7-2.1-2-9.2-9-14-20-4.8-11.1-4.9-20.8-5-34-.1-14.2,1.9-16.8,0-27-1.4-7.6-3.2-9.3-6-19-2.9-9.8-3.7-16.7-5-27-2.9-22.5-5.1-39.1-8-54-5.2-26.5-7.7-21.4-10-39-2.2-16.4.2-19.6,0-58-.1-23.2-.9-22.5,0-29,3.5-24.1,16.1-41,23-50,17.6-23,37.6-34.2,57-45,15.8-8.8,42.5-23.4,79-28,5.8-.7.7.2,59-2,23.6-.9,28.7-1.2,38,0,5.9.8,17.2,2.6,50,16,26.5,10.8,39.8,16.2,52,24,13.4,8.5,30.9,19.6,45,40,15.7,22.6,19,44.6,23,72,4.8,32.7,2.3,59,1,70-3.8,31.2-5.7,46.8-13,63-3.9,8.7-9.9,19.2-8,33,1.1,8.1,4,10.6,4,20s-2.8,11-5,24c-2.6,14.8.6,14.8-2,24-3.2,11.2-8.4,12.9-15,26-2.2,4.4-4.3,9.5-8,25-5.9,24.7-11.4,47.4-13,78-.5,9.3-.8,22.1-6,37-2.4,6.8-5.4,13-10,19-4.5,5.8-7.6,7.8-12,12-11,10.7-9.9,17.1-18,28-11.8,15.9-28.8,22.3-36,25-5.1,1.9-14.8,3.3-34,6-19.8,2.8-30,4.2-44,5-17.9,1-26.8,1.5-33,0-15-3.7-25.4-12.4-43-27h0Z"/>
      <path id="spine_1" data-name="spine 1" class="schadel_pa_sitzend_links1902_cls1" d="M310.43,676.29c.3-4.5.5-11.5,0-20-.5-9.1-1.3-10.5-2-20-1-13.5-1.5-20.2,0-29,1.9-11,4.9-13,4-21-.9-7.6-3.9-8.3-4-15-.1-7.1,3.1-9.7,7-20,.7-2,3.9-10.7,5-21,1.3-12.1-1.7-13.6-1-29,.3-6.5.6-12.5,3-20,2.2-6.8,4.2-8.9,9-18,2.1-3.9,7.8-14.7,12-27,2.5-7.4,2.1-8,7-28,4.1-16.7,6.2-25.1,7-25,1.3.2.9,17-6,87"/>
      <path id="spine_2" data-name="spine 2" class="schadel_pa_sitzend_links1902_cls1" d="M148.43,679.29c-.2-3-.3-7.5,0-13,.5-9.1,1.6-10,2-17,.5-10.4-1.4-19.8-2-23-1.3-6.2-2.4-8.8-2-14,.4-4.8,1.8-6.7,3-11,1.8-6.3,1.2-11.6,0-22-.7-6.2-2.1-14.4-5-24"/>
      <g id="skull_seams" data-name="skull seams">
        <path id="skull_seams1" data-name="skull seams1" class="schadel_pa_sitzend_links1902_cls1" d="M178.43,316.29c-15.5-.9-27.8-3.2-36-5-16.5-3.6-19.4-6.3-38-10-5.7-1.1-13.8-2.4-30-5-17.1-2.7-21.2-3.1-28-6-7-2.9-15.6-6.5-21-15-2.3-3.7-3.6-9.8-6-22-3.4-17-2-16.4-4-22-5.1-14-12.2-14.2-13.8-24.8-.9-6.5,1.5-8.6,4.8-22.2,1.2-5.2,2.2-12.5,4-27,4.6-36.6,3.8-40.6,8-50,1.1-2.4,4.6-9.6,15-21,9.9-10.8,27-26.3,67-45,28.9-13.5,46-21.5,72-26,30.2-5.2,54.5-2.5,77,0,16.9,1.9,32.7,3.6,53,10,42.6,13.4,69.1,35.9,77,43,13.4,12,26.4,23.9,34,44,3.6,9.6,4.8,17.8,7,34,1.8,13.3,2.2,24.9,3,48,.7,20.4.2,25.5,0,28-1.5,14.9-4,15.6-4,28,0,5.9.5,10.1-2,15-2.7,5.2-7.3,8.2-11,10"/>
        <path id="skull_seams2" data-name="skull seams2" class="schadel_pa_sitzend_links1902_cls1" d="M22.43,267.29c1.7-3,3.5-7.5,4-13,.1-1.5.3-3.9-.1-7.1-.9-6-3-6.4-2.9-9.9.2-5.5,5.7-10.5,6-11h0c7.7.4,10.1-1.4,11-3,2.2-4.1-4.1-11.1-2-13,.2-.2.9-.7,4.2.3,7.4,2.4,10.6,7.5,11.8,6.7s-.9-6.3-1.6-8.3c-2.1-5.7-4.1-7.8-3.4-8.7,1.6-1.9,12.7,5.6,14,4,1-1.3-5.7-5.8-7-14-.8-4.7.4-9.7,2-10,1.9-.3,2.8,5.9,9,11,3.4,2.8,8.6,5.4,10,4,2.5-2.4-6.9-16.8-7-17h0c2.9-.9,6.2-2.6,8-6,.1-.1.1-.3.1-.3,2.5-5.3-1.8-11.4-1.1-11.7.5-.3,2.1,2.3,2.1,2.3,6.6,10.4,9.2,14.9,9.9,14.7,1.2-.4-6.5-19-2-22,2.4-1.6,7.9,1.7,11,5,5,5.3,5.4,12.1,6,12,.4-.1.3-3-.8-10-1.7-10.9-3.3-15.5-2.2-16,2-1,9.9,14.2,13,13,1.3-.5,1-3.6,1-15,0-10.6.3-12.9,1-13,1.8-.3,3.9,15.7,9,16,3.4.2,6.8-6.6,8-9,2.9-5.8,2.8-9.9,4-10,2.2-.1,3.9,15.1,6,15,1.3-.1,1.8-5.9,5-18,2.1-7.9,3.1-10.1,4-10s1.4,2.3,2.1,5.4c.6,2.6.9,5.1,1.2,8.6.4,5.3.2,6.9.6,7,1.6.4,5.3-19.1,10-19,3.5.1,5.1,11.2,8,11,3.4-.2,3.6-15.4,8-16,3.4-.5,5.6,8.2,10,8,5-.2,7.9-11.7,11-11,.4.1.8.4,1.4,1.6,3.2,6.1.9,13.2,1.6,13.4,1.2.4,7.4-19.5,11-19,2.9.4,2.8,13.9,5,14,2.4.1,4.6-15.1,7-15,2.1.1,1.1,12,6,14,5.2,2.1,12.6-8.9,16-7,3.9,2.2-2.8,18.6,0,20,3,1.5,13.8-16.2,16-15,2.1,1.1-5.6,17.4-3,19s12.9-12.4,15-11c2.2,1.4-6.9,16.7-4,19,3.1,2.4,16.9-11.8,19-10,2.1,1.9-10.4,19-9,20,1.4,1.1,15.8-17,19-15,1.1.7.5,3.5-.1,6.5-1.7,8.2-5.7,12.7-4.9,13.5,1.4,1.4,12.9-9.7,15-8,2.2,1.8-7.1,15.3-5,17,2.2,1.8,14.3-11.1,17-9,2.4,1.8-5,13.4-2,16,2.6,2.2,9.8-4.4,13-2,3.2,2.5-1.3,11.7,1,13,2.9,1.6,12.4-11.1,14-10,1.8,1.2-9.2,19.4-7,21,1.9,1.4,11.8-10.9,15-9,3.4,2-3.7,17.6-1,19,2.8,1.5,13.3-14.1,15-13,1.8,1.1-8.5,18.1-5,21,2.7,2.3,11.8-6,14-4,1.9,1.7-2.4,9.6-7,17"/>
        <path id="skull_seams3" data-name="skull seams3" class="schadel_pa_sitzend_links1902_cls1" d="M86.53,100.79c1.2-1.3,2.5-3.2,2.9-5.5.1-.6.2-1.6-.1-3-.6-2.6-2.2-2.7-2.1-4.2.2-2.4,4.1-4.4,4.3-4.7h0c5.5.1,7.3-.6,7.9-1.3,1.6-1.7-2.9-4.7-1.4-5.5.1-.1.6-.3,3,.1,5.3,1,7.6,3.2,8.5,2.8.9-.3-.6-2.7-1.1-3.5-1.5-2.4-3-3.3-2.4-3.7,1.1-.8,9.1,2.4,10.1,1.7.7-.5-4.1-2.4-5-5.9-.5-2,.3-4.1,1.4-4.2,1.4-.1,2,2.5,6.5,4.7,2.5,1.2,6.2,2.3,7.2,1.7,1.8-1-5-7.1-5-7.2h0c2.1-.4,4.5-1.1,5.8-2.5l.1-.1c1.8-2.2-1.3-4.8-.8-4.9.3-.1,1.5,1,1.5,1,4.8,4.4,6.6,6.3,7.1,6.2.9-.2-4.6-8.1-1.4-9.3,1.7-.7,5.6.7,7.9,2.1,3.6,2.2,3.9,5.1,4.3,5.1.3,0,.2-1.2-.6-4.2-1.2-4.6-2.3-6.6-1.6-6.8,1.4-.4,7.1,6,9.3,5.5.9-.2.7-1.5.7-6.3,0-4.5.2-5.4.7-5.5,1.3-.1,2.8,6.6,6.5,6.8,2.4.1,4.9-2.8,5.8-3.8,2.1-2.4,2-4.2,2.9-4.2,1.6-.1,2.8,6.4,4.3,6.3.9,0,1.3-2.5,3.6-7.6,1.5-3.3,2.2-4.3,2.9-4.2.6,0,1,1,1.5,2.3.4,1.1.7,2.2.9,3.6.3,2.3.1,2.9.5,3,1.1.2,3.8-8.1,7.2-8,2.5,0,3.6,4.7,5.8,4.7,2.4-.1,2.6-6.5,5.8-6.8,2.5-.2,4,3.5,7.2,3.4,3.6-.1,5.7-5,7.9-4.7.3,0,.6.2,1,.7,2.3,2.6.6,5.6,1.1,5.7.9.2,5.3-8.3,7.9-8,2.1.2,2,5.9,3.6,5.9,1.7.1,3.3-6.4,5-6.3,1.5.1.8,5.1,4.3,5.9,3.8.9,9.1-3.8,11.5-3,2.8.9-2,7.9,0,8.5,2.2.6,9.9-6.9,11.5-6.3,1.5.5-4.1,7.4-2.2,8,1.9.7,9.2-5.2,10.8-4.7,1.6.6-5,7.1-2.9,8,2.2,1,12.1-5,13.6-4.2s-7.4,8-6.5,8.5c1,.5,11.4-7.2,13.6-6.3.8.3.4,1.5-.1,2.8-1.2,3.5-4.1,5.4-3.5,5.7,1,.6,9.2-4.1,10.8-3.4,1.5.7-5.1,6.5-3.6,7.2,1.6.8,10.3-4.7,12.2-3.8,1.7.8-3.6,5.7-1.4,6.8,1.9.9,7.1-1.9,9.3-.9,2.3,1.1-.9,4.9.7,5.5,2,.7,8.9-4.7,10.1-4.2,1.3.5-6.6,8.2-5,8.9,1.4.6,8.5-4.6,10.8-3.8,2.4.9-2.6,7.4-.7,8,2,.6,9.5-5.9,10.8-5.5,1.3.5-6.1,7.7-3.6,8.9,2,1,8.5-2.5,10.1-1.7,1.4.7-1.7,4.1-5,7.2"/>
        <path id="skull_seams4" data-name="skull seams4" class="schadel_pa_sitzend_links1902_cls1" d="M340.93,83.89c.4-.5.9-1.3,1.5-2.2,2.7-3.9,2.8-5,3.5-5,1.8-.2,1.8,6.2,6.5,10.6,2.7,2.5,3.7,1.4,10,5,4.3,2.5,6.5,3.7,8,6,3.2,4.6.6,7.8,4,11,1.8,1.7,4.8,3,7.7,3.1,2.6.1,3.6-.8,4.3-.1,1.7,1.8-1.9,10-4.7,12.4-.1.1-.4.3-.3.6.1,1.6,9.4.7,11,4,1.6,3.5-6.5,8.9-5,12,1.5,3,10.7.1,12,3,1.3,2.8-6.2,7.1-5,11,1,3.3,6.9,2.1,9,6,1.8,3.3-1.1,7-3,13-1.4,4.3-2.4,10.6-1,19"/>
        <path id="skull_seams5" data-name="skull seams5" class="schadel_pa_sitzend_links1902_cls1" d="M89.43,95.29c-.3-.1-4-1.4-7,1-2.5,2-2.9,5.2-2.4,7.4.4,2.2,1.6,2.4,1.4,3.6-.6,3.6-12,5-12,5h0c.7,3.5.8,7.8-1,12-1.8,4.1-3.8,4.3-6,9-2.5,5.5-.5,7-3,12-2.7,5.5-6.2,5.9-6,9,.2,3.9,5.9,4.7,7,9,.6,2.4-.1,6.2-6,12"/>
        <path id="skull_seams6" data-name="skull seams6" class="schadel_pa_sitzend_links1902_cls1" d="M216.43,2.49c-6,3.9-12.6,8.6-12,9.9.3.5,1.6.3,4.1,0,8.2-1,13.2-1.6,16,.6,2.9,2.3,4,8,1.9,10.4-3.7,4.2-14.6-4.1-19,0-3.6,3.3-1.6,13.7,4,20,6,6.7,13.4,5.8,14,10,.6,4.4-7,9.1-11,11-7.3,3.5-14,3.1-14,4,0,1.4,12.2,14,19,7,2.7-2.8,2.8,7.5,2,11-1.2,5-4.7,5.8-6,10-.9,2.9-.7,7.4,4.5,14.5"/>
        <polyline id="skull_seams7" data-name="skull seams7" class="schadel_pa_sitzend_links1902_cls1" points="237.83 42.29 237.83 51.29 234.53 56.59 241.33 61.29 234.53 66.29 241.33 66.29 234.53 76.69 245.43 76.69 234.53 82.29 239.93 86.29 234.53 94.89 241.33 94.89 234.53 100.79 239.93 104.29 234.53 110.79 239.93 117.69 235.43 118.29 234.53 125.29 239.93 130.29 234.53 135.09 237.83 144.29 234.53 151.29 237.83 156.29 231.43 164.29 241.33 164.29 234.53 171.29 237.63 181.29 237.63 187.29 245.43 193.29 234.53 193.29 241.33 201.29 234.53 207.29 241.33 207.29 238.43 217.29"/>
        <path id="skull_seams8" data-name="skull seams8" class="schadel_pa_sitzend_links1902_cls1" d="M386.23,187.29c10.2,17-1.3,40.3-6.4,48"/>
      </g>
      <g id="nose_section" data-name="nose section">
        <path id="nose_section1" data-name="nose section1" class="schadel_pa_sitzend_links1902_cls1" d="M231.43,424.29c2.4,1.4,6.6,3.4,12,4,1.6.2,14.9,1.4,22-7,1.8-2.1,2.1-3.5,5-22,2.4-14.9,2.5-16.3,2-19-.8-4.1-3.5-8.8-9-18-6-10.1-6.8-10-9-15-2.7-6.3-1.9-7.6-5-17-2.2-6.8-5.7-17.3-8-17-2.4.3-4.6,12.4-2,24,2.2,10.1,6.2,11.6,8,23,.7,4.6,1.6,9.8,0,16-2.1,8.6-6.2,8.9-8,17-.9,4-.3,8.7,1,18,1,7.2,2.1,13,3,17"/>
        <path id="nose_section2" data-name="nose section2" class="schadel_pa_sitzend_links1902_cls1" d="M226.43,425.29c-2,1.3-5.8,3.4-11,4-2.4.3-7.5.8-13-2-5.1-2.6-7.6-6.6-9-9-.6-1.1-3.7-6.3-4-14-.2-4.3.6-5.6,4-18,3.8-13.9,3.5-15.4,6-19,2.4-3.4,3.1-2.9,11-10,8.1-7.4,12.2-11.1,14-15,1-2.3.9-2.9,3-20,1.4-11.3,1.5-11.9,2-12,2.7-.4,13,19.9,6,33-2.3,4.3-5.1,5.3-9,13-2,4-3.9,7.9-4,13-.1,5.8,2.3,6.9,4,14,.9,3.7,3.1,12.8-1,21-3.6,7.2,1,21-17,22"/>
        <line id="nose_section3" data-name="nose section3" class="schadel_pa_sitzend_links1902_cls1" x1="231.43" y1="352.29" x2="237.63" y2="481.29"/>
        <path id="nose_section4" data-name="nose section4" class="schadel_pa_sitzend_links1902_cls1" d="M171.33,332.49s63.2,25.8,111.4,0"/>
        <path id="nose_section5" data-name="nose section5" class="schadel_pa_sitzend_links1902_cls1" d="M225.53,322.39c0,13.3-3.3,24-7.5,24s-7.5-10.9-9.5-24c-2.5-16.4,3.5-26.4,9.5-24-4.1-1.7,7.5,13.8,7.5,24Z"/>
        <ellipse id="nose_section6" data-name="nose section6" class="schadel_pa_sitzend_links1902_cls1" cx="252.93" cy="313.79" rx="7.5" ry="20.4"/>
        <ellipse id="nose_section7" data-name="nose section7" class="schadel_pa_sitzend_links1902_cls1" cx="200.43" cy="324.29" rx="1.4" ry=".7"/>
        <ellipse id="nose_section8" data-name="nose section8" class="schadel_pa_sitzend_links1902_cls1" cx="192.63" cy="322.29" rx="5" ry="4"/>
        <circle id="nose_section9" data-name="nose section9" class="schadel_pa_sitzend_links1902_cls1" cx="196.73" cy="332.49" r="4.4"/>
        <circle id="nose_section10" data-name="nose section10" class="schadel_pa_sitzend_links1902_cls1" cx="203.13" cy="316.29" r="4.4"/>
        <circle id="nose_section11" data-name="nose section11" class="schadel_pa_sitzend_links1902_cls1" cx="256.73" cy="324.29" r="3.9"/>
        <path id="nose_section12" data-name="nose section12" class="schadel_pa_sitzend_links1902_cls1" d="M189.43,404.29c.8,4.8,4.4,8,8,8,2.7,0,6-1.9,6.3-4.3.6-4.4-9.2-5.6-12.5-13.6-2.8-6.7,5-18.9,5-18.9,4.1-1.5,10.3,1.3,11.2,4.8,1,3.9-4.9,6.7-4,12,.4,2.5,2.2,5,4.3,5.2,3.2.3,7-5,7.9-10.2.9-5-1.5-7.1-.2-11,1.2-3.5,4.4-5.7,7-7"/>
        <path id="nose_section13" data-name="nose section13" class="schadel_pa_sitzend_links1902_cls1" d="M244.73,382.89c4.8-7.9,9.2-14.8,9.7-14.6v.1c.2,1.5-2.7,9.3-2.9,9.9-1.2,3.4-1.7,8.6.9,12,2.4,3,7,4.2,8,3,1.2-1.4-3.2-4.6-4-11-.6-4.6.8-10.9,4-12s8.5,2.8,12,10"/>
        <circle id="nose_section14" data-name="nose section14" class="schadel_pa_sitzend_links1902_cls1" cx="207.93" cy="303.59" r="4.8"/>
        <circle id="nose_section15" data-name="nose section15" class="schadel_pa_sitzend_links1902_cls1" cx="263.23" cy="300.79" r="2.9"/>
        <circle id="nose_section16" data-name="nose section16" class="schadel_pa_sitzend_links1902_cls1" cx="262.83" cy="311.09" r="2.6"/>
        <circle id="nose_section17" data-name="nose section17" class="schadel_pa_sitzend_links1902_cls1" cx="266.13" cy="325.89" r="2.3"/>
      </g>
      <g id="middle_section" data-name="middle section">
        <path id="middle_section1" data-name="middle section1" class="schadel_pa_sitzend_links1902_cls3" d="M411.93,346.39c-4.6,14.8-9.5,26.9-13.5,35.9-7.4,16.5-11.1,21.3-16,25-4.8,3.6-9.6,5.4-19,9-13.7,5.2-14.5,3.4-21,7-4.3,2.4-15.4,9.6-19,22-.8,2.7-.9,8.1-1,19-.1,7.1,0,12.9,0,17"/>
        <path id="middle_section2" data-name="middle section2" class="schadel_pa_sitzend_links1902_cls3" d="M69.43,390.29c1,3.2,3.3,9.4,9,15,1.6,1.6,7.2,6.8,16,9,8.6,2.2,10-1,18,1,9.8,2.5,15.8,9.5,18,12,2.5,2.9,6.7,7.8,8,15,.2,1.4.3,2.5-1,15-1.2,11.5-1.7,13.9-1,18,.8,4.8,2.6,8.6,4,11"/>
        <path id="middle_section3" data-name="middle section3" class="schadel_pa_sitzend_links1902_cls3" d="M41.43,342.29c-2.4,2.2-7,7-9.4,14.7-1,3.1-3.3,10.5-.5,18.1,2.3,6.3,6,6.8,10.9,14.1,3.1,4.6,4.4,8.4,7,17,2.9,9.6,6.3,21.3,10,35"/>
        <line id="middle_section4" data-name="middle section4" class="schadel_pa_sitzend_links1902_cls1" x1="72.33" y1="397.29" x2="57.03" y2="432.59"/>
        <path id="middle_section5" data-name="middle section5" class="schadel_pa_sitzend_links1902_cls1" d="M103.43,503.29c-1.1-19.7-3.7-35.2-6-46-3-14.2-5.2-19.4-6-32-.8-12.4.8-15.6-2-24-1.6-4.7-3.8-8.9-3-15,.6-4.6,2.9-10.1,5-10,3.1.1,4.7,11.4,7.1,24,0,0,4.5,23.5,11.9,50,3.5,12.6,10.4,30.5,25,51"/>
        <path id="middle_section6" data-name="middle section6" class="schadel_pa_sitzend_links1902_cls1" d="M411.53,332.49c-.3,1.7-1.3,7.2-6.1,10.8-4.5,3.3-9.6,3.1-12,3-7.3-.3-9.1-3.9-14-3-4.2.7-5.7,3.8-10,8-5.7,5.5-11.1,8-20,12-9.5,4.3-17,7.7-27,7-5.7-.4-10.8-2-17-4-5.5-1.7-9.9-3.6-13-5"/>
        <path id="middle_section7" data-name="middle section7" class="schadel_pa_sitzend_links1902_cls1" d="M21.03,312.19c1.2,4.6,4,11.6,10.4,17.1,6.1,5.2,12.8,6.6,19,8,8.7,1.9,9.6.2,18,2,8.3,1.8,14.3,4.9,20,8,10.5,5.6,9.8,7.6,20,13,4.6,2.4,11.4,6,21,8,5.2,1.1,10.8,2.2,18,1,7.9-1.3,13.7-4.7,17-7"/>
        <path id="middle_section8" data-name="middle section8" class="schadel_pa_sitzend_links1902_cls1" d="M280.43,397.29c1.4,18.8,1.8,15.3,2,23,.2,10.7-.7,14.1,2,19,.9,1.6,4.3,7.2,11,9,9.5,2.6,17.4-4.4,18-5,3.3-3,2.7-4.6,8-15,4.1-8,6.2-12,10-16,5.8-6.1,8.1-4.4,13-10,1.5-1.7,9.1-10.3,7-20-1.2-5.6-5.1-9.2-7-11-5-4.7-7.5-3.3-13-8-5.5-4.8-3.9-7-9-11-2.4-1.9-6,3-16-6-6.8-6.1-12.8-4.6-15-2.6-1.4,1.3-.6,2.9-2.2,4.2-2.8,2.2-6.4-1.2-9.7.4-4.5,2.2-4.1,11.5-4,14,.2,5.4,1.6,7.7,3,13,1.2,5,.7,5.6,1.9,22Z"/>
        <path id="middle_section9" data-name="middle section9" class="schadel_pa_sitzend_links1902_cls1" d="M189.43,345.29c1.1,1.6,1.2,3.1-2,20-3,15.7-3.4,15.8-4,21-1.2,11.7.8,12.7-1,22-.8,4.3-2.2,11-7,18-3.1,4.5-6.7,9.7-13,11-5.8,1.2-10.8-1.7-15-4-6.2-3.5-6.2-5.8-13-11-9.3-7.1-11.8-4.6-17-10-7.6-7.8-7.9-18.5-8-21-.1-3.8-.4-12.3,5-19,1.9-2.4,7.7-5.3,19-11,6.8-3.4,9-4.3,13-7,6-4.1,7-6.1,10.4-8.6,10.1-7.2,27.8-7.5,32.6-.4Z"/>
        <line id="middle_section10" data-name="middle section10" class="schadel_pa_sitzend_links1902_cls1" x1="69.43" y1="414.99" x2="81.43" y2="464.29"/>
        <path id="middle_section11" data-name="middle section11" class="schadel_pa_sitzend_links1902_cls1" d="M184.53,379.29c-2.6,1.4-11.5,3.3-16.2,5-3,1.1-5.4,1.7-10,3-6.4,1.8-9.7,2.6-13,3-1.9.2-6.4.6-12,0-5.9-.6-8.2-1.9-13-3-6.1-1.4-9.5-1-19-1-6.1,0-15-.2-26-1"/>
        <path id="middle_section12" data-name="middle section12" class="schadel_pa_sitzend_links1902_cls1" d="M399.43,352.29c-2.8,3.1-6.8,7.4-12,12-8,7-12,10.6-18,13-5.6,2.3-12.1,2.5-25,3-10.4.4-12.2-.3-18,1-8.4,1.9-10,4.7-16,6-5.1,1.1-12.9,1.1-24-5"/>
        <path id="middle_section13" data-name="middle section13" class="schadel_pa_sitzend_links1902_cls1" d="M369.43,370.39c6,14.6,9.2,44.6,20.7,44.6"/>
        <path id="middle_section14" data-name="middle section14" class="schadel_pa_sitzend_links1902_cls1" d="M351.43,426.29c4.1-2.4,6.6-4.4,14.2-10.8,1.6-1.4,7.1-7.1,12.5-16.1,5-8.3,7.4-12.5,6.3-17.1s-4.6-5.2-5.5-10.7c-.1-.6,2.5-10.3,2.5-10.3,17,4,13.5-1.2,17,4,3,4.4,1,9.3-2.2,21.9-1.7,6.7-4,16.1-6.1,27.7"/>
        <path id="middle_section15" data-name="middle section15" class="schadel_pa_sitzend_links1902_cls1" d="M409.33,325.09c-5,3.6-9.6,5.8-13,7.3-4.8,2-7.1,3-10.4,3-6.6,0-7.8-3.9-13.6-4-4.8-.2-6.8,2.4-14,6-3.9,1.9-9.9,4.4-18,6"/>
        <path id="middle_section16" data-name="middle section16" class="schadel_pa_sitzend_links1902_cls1" d="M69.43,414.99c-4.5-6.3-5.2-11.3-5-14.6.3-5,2.6-6.5,5-15,1.7-6,3.7-12.8,1-15-2.6-2.1-6.5,3-14,2-6.3-.9-8.2-5.1-13-4-3.9.9-5.8,4.6-6,5-1.8,3.7-1.1,8.3,1.9,11.9"/>
        <path id="middle_section17" data-name="middle section17" class="schadel_pa_sitzend_links1902_cls1" d="M322.53,458.29c-5.4-13-7.8-15.3-9.1-15-1.9.4-2.2,6.9-2.3,9.2-.4,7.2,1.5,11,.3,11.8-.9.6-3.7-1-12-11"/>
        <path id="middle_section18" data-name="middle section18" class="schadel_pa_sitzend_links1902_cls1" d="M299.43,453.29c0,13.3-7.1,4-11,24"/>
      </g>
      <g id="eye_section" data-name="eye section">
        <path id="eye_section1" data-name="eye section1" class="schadel_pa_sitzend_links1902_cls3" d="M111.43,341.29c-3.8-2.4-12.7-8.7-16-20-2.2-7.6-.9-14,1-23,2.3-10.9,3.9-18.5,10-25,9.2-9.8,22.5-10.5,31-11,7.4-.4,22.3-1.2,35,9,2.5,2,12.4,9.9,14,22,.6,4.7-.7,4.9-2,20-1.1,12.1-.4,13.5-2,17-3.5,7.4-11.2,10.4-18,13-15.5,5.9-30.6,5.7-42,4"/>
        <path id="eye_section2" data-name="eye section2" class="schadel_pa_sitzend_links1902_cls3" d="M304.43,341.29c-1.6.6-16.2,6-27-2-6.8-5.1-8.5-12.8-10-20-1-4.8-4-19.1,4-34,7.9-14.6,21.1-20.1,26-22,4.4-1.7,17.4-6.2,33-2,5.3,1.4,16,4.4,23,14,3.4,4.7,4.6,10.2,7,21,2.6,11.7,3.8,17.5,2,23-3.1,9.3-11.6,14-17,17-12,6.6-23.9,6.5-30,6"/>
        <path id="eye_section3" data-name="eye section3" class="schadel_pa_sitzend_links1902_cls1" d="M413.43,253.29c-1.6,3.4-4.1,8-8,13-2.8,3.6-6.5,8.4-12,12-1.6,1-5.8,3.6-24,6-15,2-16.2.9-26,3-9.6,2.1-16.9,5.1-19,6-9.7,4.1-9.6,5.8-18,9s-15.5,4.2-21,5c-7.6,1.1-14.2,1.2-19.4,1.1"/>
        <path id="eye_section4" data-name="eye section4" class="schadel_pa_sitzend_links1902_cls1" d="M385.43,341.29c.7-4.9,1.3-12.3,0-21-.8-5.6-2-8.9-7-24-4.5-13.8-6.8-20.7-7-22-2.6-17.8,5.5-30.8,3-32-2.9-1.4-9.6,19.3-28.1,25.9-3,1.1-10,1.8-23.9,3.1-12.5,1.2-15.3.9-23,2-6.2.9-14.4,2.5-23.9,5.7"/>
        <path id="eye_section5" data-name="eye section5" class="schadel_pa_sitzend_links1902_cls1" d="M59.43,217.29c2.5,1.8,6.2,4.7,10,9,1.1,1.2,4.1,4.8,12,18,5.9,10,5.7,10.5,8,13,3.9,4.1,8,6.1,16,10,10.2,4.9,15.2,7.3,22,8,9.8,1,10.6-2.5,19-1,7,1.2,9.2,4.2,20,10,4.5,2.4,11.2,5.8,20,9"/>
        <path id="eye_section6" data-name="eye section6" class="schadel_pa_sitzend_links1902_cls1" d="M396.43,214.29c-2.1,4.3-5.3,10.9-9,19-5.4,12-5.1,12.6-8,18-5.7,10.8-8,10.8-15,23-4.2,7.5-6.9,12-9,19-2.3,7.5-2,11.1-5,18-2.5,5.9-5.7,10.3-8,13"/>
        <path id="eye_section7" data-name="eye section7" class="schadel_pa_sitzend_links1902_cls1" d="M80.43,259.29c.2,4.4,9,8.4,7,15-1.4,4.6-2.9,7.1-8,17-3.3,6.5-6.5,12.9-8,16-2.8,5.9-6.6,14.6-10,26"/>
        <path id="eye_section8" data-name="eye section8" class="schadel_pa_sitzend_links1902_cls1" d="M89.43,257.29c7.6,7.5,11.5,14.8,13.5,20.6,1.1,3.2,1.9,7.7,3.5,16.4,2.7,15.1,2.3,17.5,4,24,1.3,5.2,3.9,12.5,9,21"/>
        <path id="eye_section9" data-name="eye section9" class="schadel_pa_sitzend_links1902_cls1" d="M134.23,275.19c3.3,2.3,7.5,5.3,12.1,9.1,2.6,2.1,8.2,6.8,14,13,5,5.4,11.7,13.6,18,25"/>
        <path id="eye_section10" data-name="eye section10" class="schadel_pa_sitzend_links1902_cls1" d="M318.33,271.69c-3.5,1.5-9,3.9-16,6.6-5.5,2.1-8.5,3.2-16,6-4.1,1.5-10.2,3.8-17.6,6.7"/>
        <path id="eye_section11" data-name="eye section11" class="schadel_pa_sitzend_links1902_cls1" d="M275.43,312.29c3-3.2,7.1-7.3,12-12s7.4-7.1,10-9c6.9-5.1,9.5-4.8,15-9,2.7-2.1,6.4-5.5,10-11"/>
        <path id="eye_section12" data-name="eye section12" class="schadel_pa_sitzend_links1902_cls1" d="M242.43,290.29c-1.8-2.1-1.1-4.5,0-14,1.5-12.9.8-12.9,2-15,3.1-5.5,6.8-3.7,15-11,6.4-5.7,6.4-8.8,11-10,3.3-.8,6.2.1,12,2,5.5,1.8,8.7,2.8,11,6,.2.3,4,5.7,2,10-.7,1.4-2,2.8-12,6-9.7,3.1-11.1,2.8-14,5-1.1.9-1.8,1.6-7,10-6.3,10.1-7.2,12-10,13-3.3,1.2-7.8.6-10-2Z"/>
        <path id="eye_section13" data-name="eye section13" class="schadel_pa_sitzend_links1902_cls1" d="M217.43,293.29c-6.5-.7-10.8-2-23-6-11.8-3.9-16-5.3-22-8-10.5-4.7-12.2-6.9-13-8-4.6-6.5-4.8-17,0-22,3-3.1,5.6-1.5,12-6,4.3-3,3.8-4.2,8-7,1.6-1,6.4-4.2,13-5,6.2-.8,7.1,1.3,12,0,5.4-1.4,5.4-4.1,10-5,5.7-1.1,13,1.9,14,6,1.1,4.4-5.5,8.2-4,11,1.4,2.6,7.5-.4,11,3,3,3,2.9,9.7,0,14-3.5,5.1-9.5,4.6-10,8-.4,2.8,3.5,3.7,6,9,1.9,4,3,10.5,0,14-.7.8-3,3.1-14,2Z"/>
        <path id="eye_section14" data-name="eye section14" class="schadel_pa_sitzend_links1902_cls1" d="M266.03,308.39c0,13.4,9.6,24.1,21.5,24.1"/>
        <path id="eye_section15" data-name="eye section15" class="schadel_pa_sitzend_links1902_cls1" d="M295.43,258.29c4.7-5.6,10.9-7.1,14-5,3.5,2.4,2.9,9.6,0,14-3.7,5.5-10,4.8-20,7.8-11.8,3.6-10.4,6.7-28,15.2-15.1,7.3-26.1,12.6-32,8-6-4.7-3.2-17.3-3-18,1.2-5,4.4-11.4,7-11,2.3.3,3.7,5.9,4,13"/>
        <path id="eye_section16" data-name="eye section16" class="schadel_pa_sitzend_links1902_cls1" d="M147.23,262.19c.2-2.4.9-7.5,4.1-8.9,2.4-1,4.8.5,10,3,11.6,5.5,11.8,3.5,17,7,1.5,1,3.4,2.5,12,13,4.3,5.3,7.9,9.8,10.3,13.1"/>
      </g>
      <g id="teeth">
        <path id="teeth1" class="schadel_pa_sitzend_links1902_cls1" d="M320.33,518.49c1.8,8.5,1.3,11.3.1,11.9-2.7,1.2-10.4-9.5-11-9-.4.3,4.3,5,3,9-.8,2.4-3.8,4.5-6,4-5.7-1.4-4.7-21-6-21-1,0-1.5,12.5-4,29-1.1,7.3-2,13-3,13-.9,0-2.1-5-3.3-26.8"/>
        <path id="teeth2" class="schadel_pa_sitzend_links1902_cls1" d="M290.03,528.49c1.1,2.7,3.6,9.9,1.4,18.6-1.1,4.3-4,11.5-6.1,11.2s-3.8-8.7-2-44"/>
        <path id="teeth3" class="schadel_pa_sitzend_links1902_cls1" d="M178.43,560.29c-1.6-7.8-3.9-14.2-6-19-3.3-7.6-5.7-10.4-5-15,1-6.2,7.2-13,13-12,4.2.7,7.1,5.4,8,9,1,3.9-.5,6-1,11-.4,3.4-.3,8.5,2,15"/>
        <path id="teeth4" class="schadel_pa_sitzend_links1902_cls1" d="M141.43,503.29c-.4-1.4-1.1-2.7,0-12,.9-7.6,1.4-11.4,2-13.5,1.5-5.5,4.8-13.9,7-13.5,2.4.4,1.5,10.1,5,11,3.2.9,6.3-6.8,10-6,2.9.6,4.1,5.9,5,10,2.1,9.5-.2,16.8-1,19-1.5,4.2-3,8.5-6,9-3.7.6-5.2-5.1-11-6,0,0-8,12-11,2h0Z"/>
        <path id="teeth5" class="schadel_pa_sitzend_links1902_cls1" d="M148.43,534.29c-1.4,1.8-3.9,5-7,9-6.2,8-7.1,9.3-8,9-2.3-.7-2.5-11.2,1-20,.7-1.7.3-.3,6-11,2.9-5.3,3.7-7.1,6-9,1-.8,6.4-5.2,10-3,2.5,1.5,3.4,5.7,2,10"/>
        <path id="teeth6" class="schadel_pa_sitzend_links1902_cls1" d="M272.43,508.29c-1.7-5.5,3.9-7.9,5.9-17,1.4-6.4-1-7.3.1-17,.1-1,1.5-12.9,4-13,1.7-.1,3.3,5.7,6,16,.6,2.1,1.3,4.9,3,13,3.3,15.6,3.6,19,2,20s-3.3-1.6-7-1c-4.3.7-5.2,4.8-8.3,4.6-2.5-.1-4.9-2.9-5.7-5.6Z"/>
        <path id="teeth7" class="schadel_pa_sitzend_links1902_cls1" d="M235.43,523.29c3.1,4.6,13.5,5.5,18,1,1.6-1.5,2.3-3.6,1-21-1-14.2-1.9-18.9-3-23-1.2-4.8-3.6-14.2-6-14-2.2.2-3.4,8.8-5,20-1.5,10.8-.7,10.8-2,18-2.3,12.7-5.2,15.7-3,19Z"/>
        <path id="teeth8" class="schadel_pa_sitzend_links1902_cls1" d="M211.43,523.29c-2.3-4.3,3.4-7.5,5-19,1.2-8.7-1.6-10.6,0-20,1-6.2,3.7-14.1,6-14,1.1.1,1.8,2.1,5,16,3.2,14,3.7,17.1,4,20,1.3,10.8,1.9,16.2-1,19-4.8,4.7-16.3,3.1-19-2Z"/>
        <path id="teeth9" class="schadel_pa_sitzend_links1902_cls1" d="M243.43,567.29c-1.1-.6,1.9-5.3,3-16,.7-6.8.3-12.5,0-16-1-12.8-3.5-16.4-1-19,3.3-3.4,12.2-1.9,14,2,1.3,2.7-1.7,4.8-3,11-1.4,6.7,1,9.3,1,15,0,13-12.2,24-14,23Z"/>
        <path id="teeth10" class="schadel_pa_sitzend_links1902_cls1" d="M222.43,566.29c1.9,0,3.3-9.8,4-15,.9-6.1.4-6.9,1-16,1-13.5,2.4-17,0-19-2.7-2.2-8.4-1.1-11,2-2.8,3.3-1.3,8.3,0,14,5.1,21.8,3.9,34,6,34Z"/>
        <path id="teeth11" class="schadel_pa_sitzend_links1902_cls1" d="M212.43,567.29c.6-.1.9-2.5,1-20,.2-27-.4-32.1-4-34-3.2-1.7-8-.6-10,2-2.8,3.8,1.1,9.8,5,20,7,18.6,6.6,32.2,8,32Z"/>
        <path id="teeth12" class="schadel_pa_sitzend_links1902_cls1" d="M192.33,540.89c-7.8-37.6,19-17.6,8,26.5"/>
        <path id="teeth13" class="schadel_pa_sitzend_links1902_cls1" d="M259.43,518.29c3,2.8,10.8,1.3,13.5-3,2.5-3.9-.5-8-2.5-17-3.9-17.3,1.5-28.7-2-30-2.4-.8-6.9,3.6-9,8.5-3.2,7.4,1.3,11,1,23.5-.3,10.9-3.9,15.4-1,18Z"/>
        <path id="teeth14" class="schadel_pa_sitzend_links1902_cls1" d="M156.43,509.29c2.2,1.7,5.3,4.1,9,7,8.2,6.6,8.9,7.7,9,9,.2,2.6-2.2,4.2-13,13-6.9,5.6-10.3,8.4-12,10-6.9,6.5-9.4,10.7-11,10-1-.4-1.7-2.6-.3-10.8"/>
        <path id="teeth15" class="schadel_pa_sitzend_links1902_cls1" d="M136.33,468.09c1.6-3.2,3.8-7.9,6.1-13.8,4.8-12.1,6.4-19.8,7.4-19.7,2,.3-1.9,29.2.6,29.7s7.7-27.4,12-27c3.5.3,4.2,18.8,3,32-1,11-1.7,18.2-7,21-6.9,3.7-16.6-3-18-4"/>
        <path id="teeth16" class="schadel_pa_sitzend_links1902_cls1" d="M186.03,518.29c-3.9-.9-5.1-13.2-3.7-23,.9-6.2,2.2-6.9,6-19,4.3-13.7,4.5-19,6-19,2.6,0,4.6,14.9,5,25,.7,15.6-2.2,22.6-4,26-.5,1.2-5.6,10.9-9.3,10Z"/>
        <path id="teeth17" class="schadel_pa_sitzend_links1902_cls1" d="M169.43,508.29c-1.1-2,5.1-4.3,7-12,1.4-5.3-.5-8.6-1-21,0-.7-.1-2.7,0-5.4.1-2.9.5-5.3.9-7.4v-.2c.8-4.1,3.6-13.2,5-13,1.3.2.5,8.5,1.5,20,1.9,21.8,8.3,28.7,3.5,35-4.5,6.1-15.5,6.5-16.9,4h0Z"/>
        <path id="teeth18" class="schadel_pa_sitzend_links1902_cls1" d="M181.43,449.29c-14.1-8-11.2,60-25,60"/>
        <path id="teeth19" class="schadel_pa_sitzend_links1902_cls1" d="M319.43,510.39c-.7-9.5-3.9-14.4-7-17.1-4.4-3.8-6.6-7-6.6-7l-9.8,6.2c-2.1,3.9-.9,1.6-3,5.5"/>
        <path id="teeth20" class="schadel_pa_sitzend_links1902_cls1" d="M286.43,509.29c3,1.7,7.9,3.9,14,4,3.5.1,8.8.2,11-3,1.5-2.2.7-4.5-1-12-1.5-6.6-2.6-13.4-4-20-2.8-12.7-3.1-15-4-15-1.2-.1-3,3.5-6,21"/>
        <path id="teeth21" class="schadel_pa_sitzend_links1902_cls1" d="M320.33,490.59c1.6,7,.6,9.7-.9,10.7-3.2,2.3-8.8-2.9-14.1-.1-3.4,1.8-3.6,5.3-4.9,5.1-2.2-.4-.2-9.5-4-22-1-3.2-2.1-5.9-3-11-.7-3.8-.9-6.9-1-9"/>
        <path id="teeth22" class="schadel_pa_sitzend_links1902_cls1" d="M192.13,513.69c3.2-2.6,7.6-7,10.2-13.4,1-2.5,1.5-4.6,3-14,2.3-14.4,2.5-18,3-18,.8,0,2,9.8,3.7,48.9"/>
        <line id="teeth23" class="schadel_pa_sitzend_links1902_cls1" x1="197.63" y1="506.29" x2="195.33" y2="521.39"/>
        <line id="teeth24" class="schadel_pa_sitzend_links1902_cls1" x1="269.43" y1="492.69" x2="278.23" y2="491.29"/>
        <line id="teeth25" class="schadel_pa_sitzend_links1902_cls1" x1="253.73" y1="492.69" x2="258.33" y2="492.69"/>
        <line id="teeth26" class="schadel_pa_sitzend_links1902_cls1" x1="228.73" y1="492.19" x2="239.63" y2="493.19"/>
        <line id="teeth27" class="schadel_pa_sitzend_links1902_cls1" x1="210.93" y1="492.49" x2="216.43" y2="492.69"/>
        <line id="teeth31" class="schadel_pa_sitzend_links1902_cls1" x1="229.43" y1="515.69" x2="246.13" y2="515.69"/>
        <path id="teeth33" class="schadel_pa_sitzend_links1902_cls1" d="M246.13,515.69c0,31.8-8.5,38.4-11.8,55.5"/>
        <path id="teeth34" class="schadel_pa_sitzend_links1902_cls1" d="M234.43,571.19c-1.3-13.1-5-55.5-5-55.5"/>
        <path id="teeth35" class="schadel_pa_sitzend_links1902_cls1" d="M283.43,514.29c0-.6,9-11,17-1"/>
        <path id="teeth36" class="schadel_pa_sitzend_links1902_cls1" d="M259.43,518.29c0-5.5,5.8-10,13-10"/>
        <path id="teeth37" class="schadel_pa_sitzend_links1902_cls1" d="M259.43,518.29c0,22.1,4,40,9,40"/>
        <path id="teeth38" class="schadel_pa_sitzend_links1902_cls1" d="M272.43,508.29c0,27.6-1.8,50-4,50"/>
        <path id="teeth39" class="schadel_pa_sitzend_links1902_cls1" d="M272.43,508.29c0,27.6,1.3,50,3,50"/>
        <path id="teeth40" class="schadel_pa_sitzend_links1902_cls1" d="M283.43,514.29c0,24.3-3.6,44-8,44"/>
        <path id="teeth41" class="schadel_pa_sitzend_links1902_cls1" d="M283.43,514.29c0-.6-4-8-11-6"/>
        <path id="teeth42" class="schadel_pa_sitzend_links1902_cls1" d="M181.43,449.29s-8.3-19.8-14.1,4.5"/>
        <path id="teeth43" class="schadel_pa_sitzend_links1902_cls1" d="M134.23,519.69c-33.9,7.5,1.1-12.3,1.1-12.3"/>
        <path id="teeth44" class="schadel_pa_sitzend_links1902_cls1" d="M140.83,498.39c-.7,3.5-5.8,7.8-5.4,14,.8,10.9-6.7,11.7-8,14-.9,1.5-.1,8,7,6"/>
      </g>
    </g>
    <g id="overlays">
      <path id="skull_overlay" data-name="skull overlay" class="Schadel1902Fill" style={{
                fill: props.colors.Schadel1902Fill,
                stroke: props.colors.Schadel1902Fill,
              }} d="M133.8,621.71c-7.9-6.6-11.9-9.8-16-15-10.2-12.7-8.3-18.8-18-29-9.2-9.7-13.3-6.8-19-15s-4.5-15.4-6-32c-1.7-19.5-3.9-16.2-6-36-3.2-30.8,1.4-44.7-9-54-2.8-2.5-4.4-2.7-9-7-2.1-2-9.2-9-14-20-4.8-11.1-4.9-20.8-5-34-.1-14.2,1.9-16.8,0-27-1.4-7.6-3.2-9.3-6-19-2.9-9.8-3.7-16.7-5-27-2.9-22.5-5.1-39.1-8-54-5.2-26.5-7.7-21.4-10-39-2.2-16.4.2-19.6,0-58-.1-23.2-.9-22.5,0-29,3.5-24.1,16.1-41,23-50,17.6-23,37.6-34.2,57-45,15.8-8.8,42.5-23.4,79-28,5.8-.7.7.2,59-2,23.6-.9,28.7-1.2,38,0,5.9.8,17.2,2.6,50,16,26.5,10.8,39.8,16.2,52,24,13.4,8.5,30.9,19.6,45,40,15.7,22.6,19,44.6,23,72,4.8,32.7,2.3,59,1,70-3.8,31.2-5.7,46.8-13,63-3.9,8.7-9.9,19.2-8,33,1.1,8.1,4,10.6,4,20s-2.8,11-5,24c-2.6,14.8.6,14.8-2,24-3.2,11.2-8.4,12.9-15,26-2.2,4.4-4.3,9.5-8,25-5.9,24.7-11.4,47.4-13,78-.5,9.3-.8,22.1-6,37-2.4,6.8-5.4,13-10,19-4.5,5.8-7.6,7.8-12,12-11,10.7-9.9,17.1-18,28-11.8,15.9-28.8,22.3-36,25-5.1,1.9-14.8,3.3-34,6-19.8,2.8-30,4.2-44,5-17.9,1-26.8,1.5-33,0-15-3.7-25.4-12.4-43-27h0Z"/>
      <g id="orbita_overlay" data-name="orbita overlay">
        <path id="orbita_overlay2" data-name="orbita overlay2" class="Orbita1902Fill" style={{
                fill: props.colors.Orbita1902Fill,
                stroke: props.colors.Orbita1902Fill,
              }} d="M111.43,341.29c-3.8-2.4-12.7-8.7-16-20-2.2-7.6-.9-14,1-23,2.3-10.9,3.9-18.5,10-25,9.2-9.8,22.5-10.5,31-11,7.4-.4,22.3-1.2,35,9,2.5,2,12.4,9.9,14,22,.6,4.7-.7,4.9-2,20-1.1,12.1-.4,13.5-2,17-3.5,7.4-11.2,10.4-18,13-15.5,5.9-30.6,5.7-42,4"/>
        <path id="orbita_overlay1" data-name="orbita overlay1" class="Orbita1902Fill" style={{
                fill: props.colors.Orbita1902Fill,
                stroke: props.colors.Orbita1902Fill,
              }} d="M304.43,341.49c-1.6.6-16.2,6-27-2-6.8-5.1-8.5-12.8-10-20-1-4.8-4-19.1,4-34,7.9-14.6,21.1-20.1,26-22,4.4-1.7,17.4-6.2,33-2,5.3,1.4,16,4.4,23,14,3.4,4.7,4.6,10.2,7,21,2.6,11.7,3.8,17.5,2,23-3.1,9.3-11.6,14-17,17-12,6.6-23.9,6.5-30,6"/>
      </g>
      <g id="rock_leg_upper_edge_overlay" data-name="rock leg upper edge overlay">
        <path id="rock_leg_upper_edge_overlay1" data-name="rock leg upper edge overlay1" class="Felsenbeinoberkante1902Fill" style={{
                fill: props.colors.Felsenbeinoberkante1902Fill,
                stroke: props.colors.Felsenbeinoberkante1902Fill,
              }} d="M97.09,295.23c30.05,3.56,52.68,17.55,87.35,18.06l-.65,8.92c-36.28-1.82-70.54-16.62-88.43-18.62l1.73-8.36Z"/>
        <path id="rock_leg_upper_edge_overlay2" data-name="rock leg upper edge overlay2" class="Felsenbeinoberkante1902Fill" style={{
                fill: props.colors.Felsenbeinoberkante1902Fill,
                stroke: props.colors.Felsenbeinoberkante1902Fill,
              }} d="M266.13,305.21c32.8,2.01,53.3-21.92,90.26-24.35l2.48,8.54c-31.85,5.84-50.31,26.9-92.23,25.34l-.5-9.53Z"/>
      </g>
      <rect id="nasal_septum_overlay" data-name="nasal septum overlay" class="Nasenscheidewand1902Fill" style={{
                fill: props.colors.Nasenscheidewand1902Fill,
                stroke: props.colors.Nasenscheidewand1902Fill,
              }} x="227.37" y="307.36" width="13.62" height="109.27" rx="6.81" ry="6.81" transform="translate(23.26 -14.03) rotate(3.61)"/>
    </g>
  </svg>
  );
}

export default SchadelPaSitzend1902;
