import style from "./style.scss";

export default function RechterOberschenkelMitHufteVdLiegend2348(props) {
  return (
    <svg
      id="OSmitHuefte"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 291.77 388.14"
    >
      <g id="upper_leg_with_hip" data-name="upper leg with hip">
        <g id="background_layer" data-name="background layer">
          <path
            id="background"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls8"
            d="M35.63,156.91h0c-.97,12.45-3.3,14.32-2.63,20.82.75,7.36,4.34,10.8,8.95,18.27,8.04,13.01,10.84,24.68,14.04,38,2.47,10.29,2.26,15.41,3.18,36.87,1.72,39.84,2.92,33,4.33,69.1.62,15.76,3.39,31.43,3.45,47.2,24.74.43,49.44-2.92,74.14-2.83.29-.49.59-.96.86-1.46.49-.92.87-1.73,1.15-2.54.19-3.17.28-6.33.32-9.5-.78-7.56-2.72-21.21-4.27-50.87-1.89-36.27.03-34.93-2.56-43-2-6.23-1.84-12.89-1.64-19.68,3.96-2.81,9.69-2.15,12.41-6.9,1.95-3.42,3.48-8.23-2.17-22.62-3.35-8.52-7.75-11.97-8.24-13.88-2.34-9.16-.94-17.74,1.96-34.51,2.14-12.36,3.21-18.54,5.62-22.72,4-6.95,8.44-13.69,21.42-18.7,15.31-6.18,23.92-12.44,26.7-11.76,3.05,3.47.79,9.42,5.5,20.38,3.92,11.31,11.22,18.26,16.87,23.63,4.31,4.1,14.18,13.27,29.71,17.44,5.34,1.43,11.5,3.09,19.08,1.68,11.88-2.2,19.38-10.41,22.15-13.44,2.37-2.59,4.28-5.43,5.81-8.53-1.75-13.5-1.09-27.16,0-40.83-1.14-9.93-1.15-19.82-.57-29.68.54-28.91-.59-57.9-4.23-86.59-.37-2.88,2.89-4.01,4.75-2.78.01-1.48.03-2.96.05-4.44-.16-.34-.35-.67-.52-1.01-17.14-3-34.57-1.94-51.94-1.39-6.67,1.11-14.09,2.24-22.37,3.26-46.39,5.73-88.45,4.42-89.44,13.05,1.97,2.32,8.81,9.53,10.74,17.16-.1,5.56-4.32,7.87-3,14,.39,1.83,1.3,6.02,4.72,8.75,1.46,1.16,2.68,1.5,2.68,2.35.02,1.77-5.17,2.07-9,5-3.11,5.26-3.73,8.31-5.98,12.53-3.39,9.73-10.27,15.7-17.71,22.17-7.75,6.73-17.79,12.59-17.81,12.6-6.14,6.96-7.44,6.14-9.2,5.39-2.88.83-5.21.53-6.6.41-1.41,1.07-17.19.71-28,6-8.41,5.76-11.27,15.15-12,18-1.32,5.17.06,5.59-.72,15.6h.01Z"
          />
        </g>
        <g id="hip">
          <path
            id="hip_1"
            data-name="hip 1"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls8"
            d="M268.74,42.9h0c5.6,6.7,11.6,11.1,17,15,.2.15.38.28.57.41.07-4.09.25-8.18.68-12.25-.63-10.11-1.18-20.22-.72-30.36.01-.22.04-.43.09-.62h-28.9c.95,4.03.32,8.82,3.28,15.83,2.1,4.9,4.7,8.1,8,12h0Z"
          />
          <path
            id="hip_2"
            data-name="hip 2"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
            d="M156.64,74.01c4.6,7.1,5.6,15.1,12.6,20.1,6,5,12,7,17,12,1,2,2,3,3,5,5,19,6,39,20,54,14,13,30,24,49,25,9,0,16-6,22.93-11.16"
          />
          <path
            id="hip_3"
            data-name="hip 3"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
            d="M281.47,108.13c-18.23-7.02-29.23-22.02-42.23-37.02-13-16-20-34-27.48-53.18"
          />
          <path
            id="hip_4"
            data-name="hip 4"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
            d="M257.02,15.73c2.22,17.38,13.22,32.38,27.46,41.57"
          />
          <path
            id="hip_5"
            data-name="hip 5"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
            d="M231.64,78.01c-.1,11.3.4,20.6,1,27,2.3,25.8,6.8,34.3,9,38,3.4,5.6,8.1,13.4,16,14,5.8.4,12.9-3,15-9,2.4-6.9-2.9-13.5-4-15-1.2-1.6-5.8-6.9-26-11-6-1.2-14.6-2.6-25-3"
          />
          <path
            id="hip_6"
            data-name="hip 6"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
            d="M208.64,37.91c34,84,11.5,68.2,13,68,1.4-.1,2.2-11.8-1-23-3.2-11.1-9.3-18.1-11-20-7.5-8.2-15.6-11.4-22-14-6.7-2.7-17.7-7-32-5-9.3,1.3-16.3,4.7-20.7,7.2"
          />
          <path
            id="hip_7"
            data-name="hip 7"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
            d="M231.64,78.01c4.4,0,7.3-.6,7.3-8.3"
          />
          <path
            id="hip_8"
            data-name="hip 8"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls10"
            d="M213.94,21.11c6.2,18.8,14.4,35.1,25,48.5-3.9-5,.8-8.5-.3-11.7-1.6-4.9-2.7-8-2-12,1.1-6.2,5.4-7.4,5-12-.4-3.8-3.6-7.7-7-8-4.1-.4-5.8,4.8-10,5-2.5.1-6.1-1.5-10.7-9.8h0Z"
          />
          <path
            id="hip_9"
            data-name="hip 9"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
            d="M278.14,34.71c0,4.82,3.88,8.73,8.69,8.79-.36-5.84-.65-11.69-.72-17.55-4.47.42-7.97,3.94-7.97,8.76Z"
          />
          <path
            id="hip_10"
            data-name="hip 10"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
            d="M127.5,16.95c4.74,5.16,7.74,11.16,10.74,17.16,2,5-4,9-3,14,1,11,13,15,20,23,1,1,1,2,1.4,2.9"
          />
        </g>
        <g id="upper_leg" data-name="upper leg">
          <path
            id="upper_leg_1"
            data-name="upper leg 1"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
            d="M53.34,219.31c-3.6-8.7-5.5-13.3-9-19-6.5-10.5-9.2-9.9-11-16-1.8-6.3.9-7.7,2-22,1-13-1-14.1,1-21,.8-2.6,3.6-12.2,12-18,0,0,4.5-3.1,28-6,7.2,1.3,10.9-.3,13-2,1.8-4.2,30.3-15.3,44.3-51.1,4.2-3.4,8.8-2.8,9-5,3.1-2.3,8.9-5.8,17-7.2,12.7-2.2,22.6,2.4,26,4,4,1.9,15.9,7.7,21,21,4.8,12.4.7,23.6-2,31-2.2,6.1-4.9,13.5-12,18.2-6.4,4.3-48.1,13.9-51.3,40.1-1.29,6.24-3.01,15.42-4.4,26.59-4.19,33.68-2.16,59.1-1,75,2.15,29.64,4.13,66.04,6,115"
          />
          <path
            id="upper_leg_2"
            data-name="upper leg 2"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
            d="M53.44,219.31c5.5,13.3,11.6,165.19,13.5,168.59"
          />
          <path
            id="upper_leg_3"
            data-name="upper leg 3"
            class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
            d="M134.94,257.31c33-7.41,0-42.32.38-48.16"
          />
          <g id="inner_lines_upper_leg" data-name="inner lines upper leg">
            <path
              id="inner_lines_upper_leg_1"
              data-name="inner lines upper leg 1"
              class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
              d="M41.94,196.01c2.2-8.7,2.6-15.9,2.4-21-.2-5-.8-7.3-3-23-2.1-15.1-2.2-17.7-.4-20.5,3.2-5,10.6-7.5,16.4-6.5,5.9,1,6.8,5,15,7,3.9,1,7.9,1.9,11,0,6.2-3.9,2.7-15.7,6.3-16.8,3-.9,9.2,5.9,10.7,13.8,1.3,6.6-1.7,8.8-2,17-.2,6.5,1.5,11.3,5,21,2.1,5.9,5.6,14,11,23"
            />
            <line
              id="inner_lines_upper_leg_2"
              data-name="inner lines upper leg 2"
              class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls9"
              x1="101.64"
              y1="161.31"
              x2="82.64"
              y2="149.91"
            />
          </g>
        </g>
      </g>
      <g id="OSmitHuefte-2" data-name="OSmitHuefte">
        <path
          class="Hip2348Fill"
          style={{
            fill: props.colors.Hip2348Fill,
            stroke: props.colors.Hip2348Fill,
          }}
          d="M137.9,36.5c-2.77,5.28-4.44,10.17-2.92,13.99,1.39,4.8,3.94,7.13,6.93,8.53,5.76-5.25,13.36-8.05,23.32-7.7,21.14,1.16,33.97,11.22,41.92,26.5,3.74,17.6-1.02,33.72-15.24,48.2,2.11,11.47,5.18,21.52,9.76,29.36,8.04,12.23,19.41,22.66,36.54,29.98,14.93,8.27,27.95,8.5,41.17-4.21.12-72.86-1.4-110.66-.43-162.2-34.43-.41-89.52-.05-152.03-1,4.64,5.07,8.26,10.37,10.98,18.55ZM234.35,120.26c15.34,2.67,41.8,5.91,37.63,27.69-2,8-15.91,11.74-21.91,5.74-9.22-8.3-14.61-20.78-15.72-33.43Z"
        />
        <path
          id="hip_joint"
          data-name="hip joint"
          class="Hipjoint2348Fill"
          style={{
            fill: props.colors.Hipjoint2348Fill,
            stroke: props.colors.Hipjoint2348Fill,
          }}
          d="M192.46,128.2c10.42,8.13,54.06-30.01,12.04-72.77-16.25-16.53-53.74-17.13-63.66-.36-6.6,11.17,31.91-8.75,53.77,13.66,21.85,22.42-6.61,56-2.15,59.48h0Z"
        />
        <path
          id="upper_leg_overlay"
          data-name="upper leg overlay"
          class="Oberschenkel2348Fill"
          style={{
            fill: props.colors.Oberschenkel2348Fill,
            stroke: props.colors.Oberschenkel2348Fill,
          }}
          d="M141.94,382.9l-75,4.28c-4.08-68.52-8.08-139.36-13.6-167.87-3.6-8.7-5.5-13.3-9-19-6.5-10.5-9.2-9.9-11-16-1.8-6.3.9-7.7,2-22,1-13-1-14.1,1-21,.8-2.6,3.6-12.2,12-18,0,0,4.5-3.1,28-6,7.2,1.3,10.9-.3,13-2,1.8-4.2,30.3-15.3,44.3-51.1,4.2-3.4,8.8-2.8,9-5,3.1-2.3,8.9-5.8,17-7.2,12.7-2.2,22.6,2.4,26,4,4,1.9,15.9,7.7,21,21,4.8,12.4.7,23.6-2,31-2.2,6.1-4.9,13.5-12,18.2-6.4,4.3-48.1,13.9-51.3,40.1-1.29,6.24-3.01,15.42-4.4,26.59-4.19,33.68-2.16,59.1-1,75,2.15,29.64,4.13,66.04,6,115h0Z"
        />
        <path
          id="neck_of_the_femur"
          data-name="neck of the femur"
          class="Schenkelhals2348Fill"
          style={{
            fill: props.colors.Schenkelhals2348Fill,
            stroke: props.colors.Schenkelhals2348Fill,
          }}
          d="M93.5,111.66l46.94,55.82c3.45-18.02,22.65-32.54,46.25-38.17l-53.05-65.1c-7.37,20.25-20.45,34.9-40.14,47.45Z"
        />
        <path
          id="trochanter_major_overlay"
          data-name="trochanter major overlay"
          class="Trochanter_major2348Fill"
          style={{
            fill: props.colors.Trochanter_major2348Fill,
            stroke: props.colors.Trochanter_major2348Fill,
          }}
          d="M76.34,117.32c-11.09.93-20.62,2.81-28,6-5.08,3.24-9.09,9.64-12.01,19.23-.54,1.74-.71,3.59-.54,5.4,1.94,20.5-5.72,32.24-2.45,36.37l27.46-23.55c9.22-7.92,14.71-19.34,15.13-31.49l.41-11.97h0Z"
        />
        <path
          id="trochantur_minor_overlay"
          data-name="trochantur minor overlay"
          class="Trochanter_minor2348Fill"
          style={{
            fill: props.colors.Trochanter_minor2348Fill,
            stroke: props.colors.Trochanter_minor2348Fill,
          }}
          d="M135.15,211.58c-.73,15.73-.87,31.03-.21,45.72,11.79-3.05,16.54-9.21,14.27-18.48-1.33-7.32-6.63-16.72-14.06-27.24h0Z"
        />
      </g>
      <g id="outlines">
        <path
          id="outlinepart2"
          class="Weichteilmantel2348Fill"
          style={{
            fill: props.colors.Weichteilmantel2348Fill,
            stroke: props.colors.Weichteilmantel2348Fill,
          }}
          d="M22.1,16.93C4.91,70.16,1.41,88.65.62,107.13c-3.17,115.8,6.41,212.37,13.76,279.05h3.76C10.79,319.5,1.22,222.93,4.38,107.13c.79-18.48,4.29-36.97,21.47-90.2h-3.76Z"
        />
        <path
          id="outlinepart1"
          class="Weichteilmantel2348Fill"
          style={{
            fill: props.colors.Weichteilmantel2348Fill,
            stroke: props.colors.Weichteilmantel2348Fill,
          }}
          d="M241.66,387.21c16.71-53.75,31.49-120.72,37.72-161.02h3.1c-6.23,40.3-21.01,107.28-37.72,161.02h-3.1Z"
        />
      </g>
      <g id="outlinehighlights">
        <path
          id="outlinehighlight2"
          class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls11"
          d="M279.88,223.17c-6.24,40.3-21.05,107.28-37.79,161.02"
        />
        <path
          id="outlinehighlight1"
          class="Rechter_Oberschenkel_mit_Hufte_vd_liegend2348_cls11"
          d="M25.9,14.92C8.68,68.15,5.17,86.63,4.38,105.12c-3.17,115.8,6.42,212.37,13.78,279.05"
        />
      </g>
    </svg>
  );
}
