import { MakeGetObject } from "../AuthHeader";

export async function loadExaminations(
  keycloak,
  filter = undefined,
  pageSize = undefined,
  pageNr = undefined,
  indid = undefined,
  sort = undefined
) {
  const url = new URL(
    `${window.conf.SERVER_CONFIG.BASE_URL}/ris/examinations`
  );
  if (filter) {
    url.searchParams.append("search", JSON.stringify(filter));
  }
  if (pageNr && pageSize) {
    url.searchParams.append("page_size", pageSize);
    url.searchParams.append("page_number", pageNr);
  }
  if (sort) {
    url.searchParams.append("sort", JSON.stringify(sort));
  }
  if (indid) {
    url.searchParams.append("indid", JSON.stringify(indid));
  }

  return fetch(url.href, MakeGetObject(keycloak)).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error(response.statusText);
    }
  });
}

export async function loadIndications(
  keycloak,
  filter = undefined,
  pageSize = undefined,
  pageNr = undefined,
  anatomy = undefined,
  sort = undefined
) {
  const url = new URL(
    `${window.conf.SERVER_CONFIG.BASE_URL}/ris/indications`
  );
  if (filter) {
    url.searchParams.append("search", JSON.stringify(filter));
  }
  if (pageNr && pageSize) {
    url.searchParams.append("page_size", pageSize);
    url.searchParams.append("page_number", pageNr);
  }
  if (sort) {
    url.searchParams.append("sort", JSON.stringify(sort));
  }
  if (anatomy) {
    url.searchParams.append("anatomy", JSON.stringify(anatomy));
  }

  return fetch(url.href, MakeGetObject(keycloak)).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error(response.statusText);
    }
  });
}
