import style from "./style.scss";

function LendenwirbelsauleLatStehend1703(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.18 949.32">

<g id="Backround">
  <path id="backround1" class="Lendenwirbelsaule_lat_stehend1703_cls5" d="M485.61,927.91c7.73-14.46,3.12-30.31,0-42.07-55.27-208.29,1.68-471.73,7.28-496.72,6.97-31.1,10.43-37.38,16.99-72,14.84-78.4,29.7-162.63-11.41-227.26-13-20.44-28.48-34.02-32.28-37.27-7.15-6.13-30.88-25.43-80.9-38.83-19.56-5.24-78.9-21.15-119.73-9.71-70.88,19.86-96.58,125.07-141.57,319.55-25.15,108.72-44.64,226.18-60.67,322.79-14.75,88.9-15.66,106.14-28.31,149.66-23.23,79.89-44.29,104.74-30.74,127.82,18.51,31.53,74.78,14.07,197.39,13.75,169.02-.44,261.54,32.21,283.96-9.71Z"/>
</g>
<g id="Lumbarvertebralat">
  <path id="coccyx10" class="Lendenwirbelsaule_lat_stehend1703_cls8" d="M248.41,896.55c14.29-16.61,34.06-27.91,45.7-46.84.48-.79,1.08-1.35,1.73-1.73-4.35-6.37-9.12-14.42-15.19-24.45-34.94-57.76-40.22-60.58-45.11-61.52-7.62-1.46-11.25,2.24-62.89,43.75-24.31,19.54-34.86,27.84-32.81,35.54,2.09,7.88,15.96,9.87,25.97,12.3,21.35,5.19,49.67,18.38,80.66,53.32.92-.96,1.82-1.88,2.73-2.82-2.05-1.7-3.03-4.95-.79-7.55h0Z"/>
  <path id="coccyx9" class="Lendenwirbelsaule_lat_stehend1703_cls8" d="M351.74,826.26c-.33-.23-.69-.43-1.07-.6,1.23,2.15,1.02,4.94-1.87,6.28-12.13,5.61-22.04,14.9-34.18,20.51-3.5,1.62-6.74-.88-7.47-3.7-1.19,1-2.4,2.03-3.65,3.1.08.91-.13,1.9-.75,2.91-.26.43-.55.84-.83,1.26,5.12,5.93,10.24,9.52,17.02,11.25,9.61,2.47,22.52,1.23,30.08-6.84,9.37-9.99,9.58-29.44,2.73-34.18h-.01Z"/>
  <path id="spine8" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M427.58,242.31c6.29,3.09,11.87-4.71,28.33-3.7,6.35.39,6.63,1.62,13.67,1.82,7.66.22,15.64.46,22.33-4.1,8.41-5.74,10.2-15.95,11.39-22.78.81-4.64,2.71-15.48-3.19-25.52-2.67-4.55-6.06-7.42-8.69-9.65-2.95-2.51-5.63-4.18-15.47-9.03-18.92-9.33-21.31-9.2-23.05-8.85-6.34,1.27-7.25,6-13.41,6.11-6.82.12-8.97-5.64-12.57-4.75-6.97,1.72-6.57,25.2-6.11,44.85.71,30.65,4.03,34.26,6.76,35.6h0Z"/>
  <path id="spine31" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M275.45,27.62c-2.15,7.45-5.05,14.02-6.84,14.58"/>
  <path id="spine30" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M268.62,42.2c-5.47,10.94,24.63,8.2,72,21.87"/>
  <path id="spine29" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M340.62,64.07c10.48,6.65,22.78,27.34,36.07,27.34"/>
  <path id="spine28" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M373.88,91.42c12.03-1.89,12.53-14.87,25.06-17.32"/>
  <path id="spine27" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M375.25,98.25c-.82,4.65.75,8.4,3.77,15.61,2.68,6.4,4.02,9.6,6.71,10.82,4.8,2.17,10.64-1.84,19.91-8.21,9.18-6.31,16.05-11.03,15.26-15.89-.64-3.95-5.6-3.35-11.02-9.62-6.84-7.92-4.62-15.47-9.11-16.86-3.58-1.11-7.67,2.86-15.04,10.03-6.43,6.25-9.64,9.37-10.48,14.13h0Z"/>
  <path id="spine26" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M426.74,159.77c.57-.1,2.07-.66,4.56-13.22,2.13-10.75,3.2-16.13,2.28-22.33-.27-1.82-2.36-15.94-8.66-16.86-3.92-.57-7.53,4.26-7.75,4.56-2.03,2.78-2.33,5.76-2.28,11.39.09,10.75.13,16.12,2.11,21.92,1.88,5.51,6.36,15.13,9.74,14.54h0Z"/>
  <path id="spine25" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M455.45,145.19c-12.08,26.29-15.98,13.92-28.71,14.58"/>
  <path id="coccyx8" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M121.19,743.52c-40.37,40.22-97.04,120.2-110.95,166.33"/>
  <path id="coccyx7" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M248.61,909.85c-17.6-28.55-63.54-53.61-102.57-56.05"/>
  <path id="coccyx6" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M146.03,853.79c-6.44-24.15,4.28-38.28,9.57-38.28"/>
  <path id="coccyx5" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M155.6,815.52c13.69.96,58.65-51.16,83.39-51.95"/>
  <path id="coccyx4" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M239,763.57c22.63,39.4,60.23,84.55,79.29,104.84"/>
  <path id="coccyx3" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M318.29,868.41c23.29,4.03,43.88-7.73,34.63-38.77"/>
  <path id="coccyx2" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M364.59,903.47c0-8.06,21.74,33.72,43.47-14.58"/>
  <path id="coccyx1" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M332.87,774.5c43.75,36.46,113.48,71.81,133.97,148.1"/>
  <path id="spine4" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M402.14,167.06c15.04,20.96-5.1,22.78-11.39,22.78"/>
  <path id="spine15" class="Lendenwirbelsaule_lat_stehend1703_cls2" d="M251.98,68.86c-3.98,2.03-6.79,6.13-7.52,10.25-.84,4.77,1.68,7.42,2.73,12.3.48,2.22.99,6.42-4.78,20.51-3.94,9.63-8.63,21.06-19.14,34.86-6.28,8.24-9.72,8.92-10.25,13.67-1.17,10.33,11.65,19.61,15.72,22.56,5.66,4.1,11.34,6.78,45.11,15.04,27.37,6.69,28.58,6.09,34.86,9.57,9.75,5.4,16.24,12.02,21.19,9.57,3.65-1.8,3.73-7.18,4.78-13.67,2.03-12.54,7.05-22.03,17.09-41.01,10.19-19.26,17.77-30.76,17.77-30.76,3.05-4.62,5.93-8.69,6.84-15.04,1.16-8.11-1.06-18.77-7.52-21.87-3.92-1.88-7.56-.09-12.99,1.37,0,0-17.12,3.81-34.86,0-6.72-1.44-4.67-2.14-23.92-8.2-7.59-2.39-8.78-2.55-11.62-4.1-9.09-4.95-8.99-9.84-17.09-13.67-2.71-1.28-9.9-4.69-16.41-1.37h.01Z"/>
  <path id="spine14" class="Lendenwirbelsaule_lat_stehend1703_cls2" d="M202.09,196c-4.96,2.35-6.7,9.59-7.52,12.99-1.49,6.21-1.37,17.09-1.37,17.09,0,0-1.74,6.59-2.73,9.57-1.91,5.76-2.79,6.91-4.1,10.94-1.35,4.15-1.16,5.17-2.73,13.67-1.78,9.62-2.68,14.43-4.1,17.77-2.39,5.6-3.73,5.32-8.2,13.67-3.94,7.36-5.23,11.01-5.47,14.35-.93,13.18,15.97,17,18.46,15.04,9.24-7.29,15.29-1.72,31.44-1.37,13.3.29,23.76,4.06,31.44,6.84,15.68,5.66,19.76,12.63,30.76,11.62,2.24-.21,10.84-.99,14.35-6.84,3.14-5.24.68-19.14.68-19.14,0,0,2.64-5.36,6.84-16.41,4.47-11.77,2.43-11.92,6.15-19.82,4.07-8.62,6.89-9.27,9.57-17.77,1.48-4.7.84-5.22,2.73-10.94,2.93-8.86,5.18-9.78,4.78-13.67-.64-6.34-7.47-12.43-12.3-11.62-.2.03-.75.14-.76.31-.03.61,6.54,1.6,6.6,1.33.04-.18-2.69-1.5-5.84-1.64-4.38-.19-5.5,2.1-14.06,4.89-.68.22-3.87,1.11-7.15,1.32-.8.05-1.64.03-2.71-.06-5.77-.51-5.62-1.26-16.41-3.42-12.03-2.41-13.22-1.68-18.46-3.42-6.88-2.28-9.66-5.14-17.09-10.25-17.6-12.12-26.55-18-32.81-15.04h0Z"/>
  <path id="spine13" class="Lendenwirbelsaule_lat_stehend1703_cls2" d="M133.73,403.11c1.51-7.55,4.24-18.44,7.52-43.75.33-2.56,1.07-8.46,5.47-11.62,5.47-3.93,13.32-1.01,17.77,0,32.73,7.42,29.29,4.63,51.95,9.57,9.98,2.17,20.32,5.92,20.32,5.92,12.21-1.86,14.63-1.76,12.21-1.86-3.08-.13,15.6-4.55,20.25-3.68,2.26.42,4.77-.34,6.38,0,3.59.76,5.63,5.29,4.86,7.29-1.6,4.17-5.01,11.65-6.61,14.2-9.11,14.62-12.26,32.21-17.77,48.53-4.34,12.85-6.36,16.89-4.78,23.24,1.49,6.02,4.87,8.71,4.1,14.35-.73,5.35-4.72,10.02-8.89,12.3-8.04,4.41-17.03.11-28.03-3.42-14.83-4.76-26.02-5.75-45.8-7.52-2.84-.25-5.08-.38-8.34-.58-21.33-1.33-36.52-1.11-39.51-6.94-.17-.32-.81-1.67-1.32-3.5-.33-1.18-.96-3.83-.73-7.44.46-7.13,4.1-17.77,4.1-17.77,2.74-6.87,4.1-13.69,6.84-27.34v.02Z"/>
  <path id="spine12" class="Lendenwirbelsaule_lat_stehend1703_cls2" d="M100.92,614.33c.2-5.3,29.61-9.22,53.32-8.89,28.87.41,61.71,7.29,61.52,12.99-.19,5.61-32.46,10.18-60.15,8.89-25.35-1.18-54.89-7.64-54.68-12.99h0Z"/>
  <path id="spine11" class="Lendenwirbelsaule_lat_stehend1703_cls2" d="M107.76,499.49c11.18-8.99,22.32,2.66,49.9,3.42,40.3,1.11,69.3-7.07,65.62-6.15s14-3.76,17.09,0c2.8,3.41-.7,9.54-6.15,26.66,0,0-3.09,9.7-5.47,19.14-5.16,20.47-4.86,37.78-4.78,41.01.52,21.81,6.15,31.44,1.37,34.86s-9.29-1.96-21.29-6.49c-14.35-5.43-25.96-6.26-38.86-7.18-13.9-.99-30.09-2.15-49.32,3.5-11.05,3.25-14.41,6.19-17.67,4.02-7.53-5.01,4.29-24.8,6.84-58.78,2.48-33.15-6.7-46.42,2.73-54h-.01Z"/>
  <path id="spine10" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M95.06,674.83c.38-2.21,1.3-5.56,3.81-8.55,4.33-5.16,10.81-6.15,15.72-6.84,22.31-3.09,33.47-4.64,47.16-4.78,7.31-.08,10.94.09,17.07-.94,10.51-1.77,18.97-5.48,20.16-6.01,11.7-5.22,14.82-9.95,20-8.32,4.97,1.57,6.93,7.45,7.03,7.76,1.68,5.3-1.26,9.3-3.42,16.41-2.02,6.66-2.03,11.68-2.05,20.51-.02,12.97-.04,23.59,6.15,33.49,2.82,4.5,6.16,7.65,7.52,14.35.77,3.8.8,8.22,0,11.06-3.53,12.3-26.79,8.12-61.51,22.43-12.4,5.11-25.46,8.64-37.59,14.35-5.99,2.82-13.13,6.47-17.77,3.42-3.77-2.48-4.3-8.33-4.78-13.67-.72-7.9,1.07-10.71,1.37-17.77.21-4.93-1.23-10.57-4.1-21.87-1.59-6.26-1.63-5.52-7.52-25.97-1.79-6.2-2.85-9.97-4.1-15.04-1.43-5.77-2.45-10.61-3.13-14v-.02Z"/>
  <path id="spine9" class="Lendenwirbelsaule_lat_stehend1703_cls2" d="M210.29,461.9c-25.87-2.62-48.3-2.73-48.3-2.73-6.65-.03-10.06.09-15.49-.91-13.53-2.49-21.58-8.61-23.63-6.2-1.25,1.47.59,5.14,1.99,7.11,3.93,5.56,10.73,6.69,20.73,8.2,6.03.91,13.53,2.05,22.78.91,2.85-.35,5.98-.93,10.94-.91,5.21.02,9.3.68,11.31,1.06,3.35.63,4.94,1.21,9.65,2.59,0,0,3.35.98,13.67,3.65,10.13,2.62,31.22,6.69,32.58,2.96.25-.69-.04-1.94-3.42-4.78-9.65-8.11-21.47-9.79-32.81-10.94h0Z"/>
  <path id="spine35" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M376.62,296.48c1.95-.48,3.41,1.04,7.53,4.33,7.45,5.93,22.6,17.99,38.95,22.1,10.22,2.57,12.13-.18,20.51,3.65,5.19,2.37,9.08,5.68,16.86,12.3,6.77,5.76,10.18,8.71,11.39,11.85,1.01,2.61.86,4.19-.91,19.59-1.43,12.42-1.55,13.26-2.28,15.04-2.4,5.86-8.27,14.25-15.49,14.58-5.37.25-7.21-4.15-15.04-9.57-.68-.47-16.59-11.23-32.81-10.03-16.15,1.2-20.8,13.37-27.8,10.48-3.57-1.47-6.58-6.39-6.84-45.57-.29-44.37,3.51-48.17,5.92-48.76h0Z"/>
  <path id="spine34" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M327.86,428.17c-2.54,2.02-2.64,5.73-2.28,13.22,1.03,21.27,3.65,33.72,3.65,33.72,3.69,17.97,4.84,15.78,5.47,24.15.7,9.34-.53,14.73,3.19,17.77,3.43,2.81,7.53.7,14.58.91,6.4.2,10.92,2.14,23.24,8.66,30.92,16.36,34.22,20.9,45.11,21.42,4.17.2,14.23.76,20.09-5.58,1.54-1.67,3.36-4.42,5.87-20.03,1.19-7.42,1.88-13.67,2.3-18.14.13-2.65.07-6.48-.91-10.94-1.77-8.05-5.52-13.45-7.29-15.95-2.56-3.61-9.24-11.89-33.72-23.7-9.78-4.72-5.58-1.88-32.81-13.22-9.8-4.08-20.14-6.74-30.14-10.31-7.28-2.6-12.93-4.7-16.35-1.99h0Z"/>
  <path id="spine33" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M226.69,582.66c3.65,3.6,9.72-1.69,19.59-.46,12.72,1.59,20.38,12.59,21.42,14.13,1.69,2.5,5.73,8.49,5.01,15.95-.51,5.32-3.08,7.53-5.01,11.85-4.29,9.57-1.05,19.7,1.82,28.71,5.08,15.91,17.28,34.66,24.49,32.74,1.54-.41,2.6-1.69,7.54-15.67,4.74-13.42,7.11-20.13,7.61-23.45,2.28-14.99-1.38-27.31-3.47-34.09-2.68-8.69-3.71-7.4-8.2-19.1-1.53-3.98-3.34-9.17-9.71-35.94-4.13-17.35-6.19-26.09-5.97-29.36,1.02-15.3,8.86-25.91,4.56-29.62-2.18-1.88-6.01-.71-6.84-.46-5.94,1.82-6.8,8.11-11.96,15.17-6.02,8.25-16.24,15.26-20.34,12.96-2.7-1.52-2.68-7.02-2.66-15.36.02-7.23,1.13-12.35-.13-12.77-.92-.3-2.49,2.07-3.19,3.19-7.69,12.28-11.75,35.42-13.96,47.7,0,0-.57,2.84-1.99,24.3-.33,5.06-.46,7.77,1.37,9.57h.02Z"/>
  <path id="spine32" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M250.78,446.87c1.91.89,6.17-11.64,14.65-12.31,8.6-.68,15.99,11.26,17.77,14.13,5.94,9.59,6.7,20.22,8.2,41.47.45,6.34.83,11.7.55,19.14-.45,12.09-2.05,14.34-1.47,21.41.16,1.89,1.88,20.71,10.48,23.7,10.22,3.55,31.87-14.66,35.09-35.54.61-3.93-2.04-18.08-7.32-46.4-1.5-8.03-2.36-12.36-2.33-18.84.02-4.75.51-8.82.09-16.79-.34-6.59-.94-8.72-1.37-10.03-1.88-5.66-4.62-7.25-6.84-11.39-2.96-5.55-2.46-10.97-1.82-17.77,1.8-19.33,12.01-30.13,8.2-33.72-1.46-1.37-4.16-.89-6.23-.52-1.18.21-4.8,1.03-9.72,6.45-5.23,5.77-3.68,7.65-8.31,12.25-1.45,1.44-5.11,4.98-10.8,6.13-1.94.39-4.9.99-7.87-.34-4.25-1.92-4.28-5.91-6.29-6.19-4.07-.57-9.18,15.16-14.12,28.71-11.11,30.44-11.75,35.91-10.55,36.46h0Z"/>
  <path id="spine24" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M316.01,554.4c8.33-.52,9.87,2.55,21.36,1.97,7.69-.39,9.31-1.88,16-1.51,5.54.3,9.36,1.6,16.41,4.1,16.83,5.99,25.25,8.99,28.25,10.48,8.18,4.07,13.96,8.18,25.52,16.41,11.58,8.24,13.09,10.39,14.13,12.3,2.55,4.72,2.61,11.35,2.73,24.61.1,10.29-.64,13.58-1.37,15.95-1.47,4.82-3.24,10.61-8.22,13.46-5.3,3.03-8.44-.59-16.38,2.49-4.72,1.83-4.63,3.5-9.57,5.47-5.58,2.22-10.52,2.01-14.13,1.82-2.41-.13-14.1-.93-26.43-9.57-10.06-7.05-8.27-10.71-15.49-13.22-10.23-3.55-22.89.64-23.7.91-6.09,2.08-9.07,4.73-11.85,3.19-3.12-1.73-3-7.07-3.19-11.39-.11-2.58-2.2-11.44-6.38-29.16-5.95-25.25-14.81-29.5-10.94-37.37,4.73-9.6,21.67-10.84,23.24-10.94h.01Z"/>
  <path id="spine23" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M295.5,324.73c.7.15,1.14-13.22,8.98-16.87,6.51-3.03,15.57,1.83,20.64,6.39,2.12,1.91,10.64,10.14,11.39,30.99,1.21,33.52-19.65,44.95-12.3,64.25,3.87,10.16,14.29,19.18,24.15,18.68,10.11-.51,17.2-10.89,20.96-16.41,2.48-3.63,6.23-9.28,6.38-16.86.1-4.89-1.37-7.09-2.89-13.01-1.22-4.79-1.45-10.6-1.86-22.02,0,0-.67-18.9.29-42.48.4-9.85.75-11.95,1.27-14.08,1.3-5.39,2.82-7.41,2.28-11.39-.55-4.07-2.61-5.46-4.78-9.56-2.73-5.15-4.22-11.91-3.42-17.32.86-5.86,8.7-12.48,10.54-16.34.34-.72.59-1.44.59-1.44,0,0,.77-2.27.72-4.55-.06-2.6-2.09-7.35-4.9-8.32-8.04-2.77-16.62,19.84-37.02,23.36-6.86,1.18-9.35-.77-10.03-1.37-5.95-5.27-.09-20.13-1.37-20.51-1.18-.35-5.84,12.26-9.02,20.41-8.66,22.18-10.48,21.89-14.69,35.03-6.12,19.07-6.63,33.26-5.91,33.42h0Z"/>
  <path id="spine22" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M381.63,254.1c2.39,1.33,1.72,6.61.46,15.95-1.38,10.21-2.08,15.31-3.65,15.49-3.42.4-12.47-18.19-5.47-27.34,2.25-2.94,6.47-5.32,8.66-4.1Z"/>
  <path id="spine21" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M383.91,245.9c1.97-7.09,4.78-6.65,7.75-13.67,4.65-11,4.61-28.38-5.95-38.29-6.32-5.93-14.02-6.94-16.84-7.28-2.44-.29-14.64-1.73-21.42,5.47-2.43,2.58-1.69,3.64-6.84,13.22-2.59,4.82-5.56,10.34-6.38,10.03-1.27-.48,2.46-14.88,7.29-28.25,7.29-20.17,17.99-40.5,25.1-50.73,2.47-3.55,4.83-6.35,7.24-11.71,2.87-6.36,3.26-10.64,5.15-10.8,2.93-.25,3.67,9.95,9.43,11.41,1.85.47,3.42-.17,11.43-4.91,11.43-6.76,13.1-8.34,14.57-7.53,2.73,1.5-.73,8.22.74,22.43.59,5.7.94,9.13,3.06,13.23,2.26,4.38,4.34,5.31,5.35,9.52.46,1.91.51,3.74-.49,10.4-.82,5.4-1.22,8.1-2.22,12.25-1.91,7.94-1.58,15.12-.97,28.31.22,4.71.94,16.25,4.1,30.99,1.61,7.5,1.83,6.43,2.28,9.57,1.68,11.92-.91,31.81-15.04,40.56-10.93,6.77-27.34,5.99-30.99-.46-2.46-4.34,3-7.5,4.1-20.05,1-11.4-2.99-14.56-.46-23.7h.01Z"/>
  <path id="spine20" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M224.87,665.59c3.42-2.48,6.55.85,11.39-1.37,3.02-1.38,4.68-4,10.48-19.14,4.57-11.92,5.92-16.52,10.03-17.77,2.01-.61,4.56-.39,6.38.91,3.53,2.52,2.7,7.89,3.47,14.37.56,4.62,2.4,9.08,6.1,17.98,5.4,13.02,10.4,14.96,21.42,31.44,7.78,11.64,12.35,21.24,14.58,25.97,7.42,15.75,11.14,23.63,11.39,33.72.31,12.31-4.05,33.63-18.23,38.28-11.85,3.89-26.12-6.18-33.27-15.04-6.37-7.9-9.26-19.39-15.04-42.38-3.51-13.95-4.88-22.53-10.48-24.15-6.14-1.77-11.02,7.49-15.95,5.47-2.66-1.09-3.13-4.53-4.56-28.25-.77-12.75-.99-17.68,2.28-20.05h0Z"/>
  <path id="spine19" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M256.77,670.22c23.7,15.49.41,30.99.91,30.99"/>
  <path id="spine18" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M277.73,676.07c6.1,42.04,34.18,64.71,32.17,85.69"/>
  <path id="spine17" class="Lendenwirbelsaule_lat_stehend1703_cls1" d="M297.78,688.83c1.56-1.28,4.83,2.89,12.3,4.1,8.44,1.37,15.08-2.18,18.68-3.65,12.14-4.92,18.78,2.17,57.87,11.39,23.74,5.6,37.43,6.79,39.63,15,.97,3.63-1.06,5.85.02,13.25,1.25,8.65,4.72,10.44,5.47,15.49,2.18,14.73-21.89,36.03-37.82,32.81-3.66-.74-5.53-2.5-10.94-2.73-8.62-.38-10.5,4.41-17.32,4.1-11.57-.52-14.04-9.97-25.06-10.48-9.17-.43-13.45,7.95-17.32,5.47-2.48-1.6-2.72-7.14-3.19-18.23-.18-4.33,0-4.45-.21-6.86-.76-8.28-3.72-14.4-5.01-17.07-16.59-34.35-18.86-41.16-17.1-42.6h0Z"/>
  <path id="spine16" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M325.12,695.21c11.09-2.19,71.97,4.16,123.04,48.3"/>
  <path id="spine7" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M265.88,548.13c27.36,20.86.69,35.89,1.54,35.89"/>
  <path id="spine6" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M291.82,406.3c34.19,24.71-1.61,31.9-3.61,31.9"/>
  <path id="spine5" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M351.55,285.55c8.05,23.54-.2,29.99-12.76,22.32"/>
  <path id="spine3" class="Lendenwirbelsaule_lat_stehend1703_cls4" d="M225.12,694.84c0-3.42,29.83-6.19,66.7-6.19"/>
  <path id="spine2" class="Lendenwirbelsaule_lat_stehend1703_cls3" d="M225.12,694.84c-21.3,7.51-85.85,23.95-111.22,56.88"/>
  <line id="spine1" class="Lendenwirbelsaule_lat_stehend1703_cls3" x1="241.35" y1="766.73" x2="255.66" y2="740.83"/>
</g>
<g id="Highlights">
  <path id="processusarticularishighlight5" class="Querfortsatze1703Fill" style= {{
            fill: props.colors.Querfortsatze1703Fill,
            stroke: props.colors.Querfortsatze1703Fill,
          }} d="M227.14,713.88c-2.32-.93-3.33-4.03-4.45-15.72-1.87-19.51-2.71-29.33,2.17-32.58,3.82-2.55,6.33,1.08,11.4-1.36,5.81-2.8,8.27-10.33,12.72-23.91,1.91-5.83,3.31-11.38,7.79-13,.18-.06,2.09-.74,3.94-.34,7.86,1.66,4.77,20.34,14.83,39.53,5.39,10.28,8.26,8.7,19.96,27.21,2.05,3.25,5.73,9.26,9.71,16.99,9.17,17.84,13.75,26.75,14.68,37.79,1.09,12.87-2.13,35.58-16.46,40.89-13.81,5.12-29.66-9.02-30.42-9.72-5.74-5.26-8.25-10.83-10.84-16.57-5.64-12.52-4.56-19.35-11.24-41.14-2.58-8.43-4.33-12.59-7.85-13.54-5.91-1.61-11.23,7.36-15.95,5.47Z"/>
  <path id="processusarticularishighlight4" class="Querfortsatze1703Fill" style= {{
            fill: props.colors.Querfortsatze1703Fill,
            stroke: props.colors.Querfortsatze1703Fill,
          }} d="M226.67,582.65c-2.05-1.9-1.75-5.15-1.37-9.57,2.19-25.24,1.38-20.86,1.99-24.3,2.78-15.68,4.17-23.52,7.36-32.44,2.97-8.3,7.77-18.99,9.79-18.45,2.91.78-4.14,24.55,2.79,28.13,4.8,2.48,13.95-5.97,14.92-6.87,9.67-8.94,9.12-18.76,17.38-21.26.82-.25,4.68-1.42,6.84.46,3.57,3.11-1.38,11.89-3.41,21.42-1.95,9.15.17,18,4.41,35.71,4.14,17.3,9.7,32.08,12.98,40.74,3.63,9.57,7.51,18.7,8.99,32.13.83,7.51.46,12.31.3,14.02-.4,4.38-1.34,9.38-7,24.32-4.68,12.35-7.02,18.53-8.63,18.88-10.17,2.23-36.86-38.99-26.31-61.45,1.96-4.17,6.02-9.45,5.15-16.99-.87-7.45-6.05-12.57-8.51-15-1.45-1.43-9.86-9.74-21.83-10.08-8.21-.24-12.77,3.42-15.84.58Z"/>
  <path id="processusarticularishighlight3" class="Querfortsatze1703Fill" style= {{
            fill: props.colors.Querfortsatze1703Fill,
            stroke: props.colors.Querfortsatze1703Fill,
          }} d="M250.78,446.87c-1.85-.88,1.31-11.26,5.89-24.98,10.9-32.66,15.32-40.61,18.77-40.18,2.36.29,2.15,4.25,6.29,6.19,5.99,2.81,13.76-2.04,14.98-2.82,9.19-5.92,7.39-13.79,16.64-19.16,3.35-1.94,9.09-4.1,11.31-1.99,1.74,1.65-.13,5.11-3.68,16.29-2.93,9.21-4.4,13.93-4.77,19.46-.35,5.17-.7,10.38,2.07,15.74,2.17,4.2,4.31,4.73,6.34,9.3,1.75,3.92,1.82,7.23,1.87,12.12.26,23.84.2,24.49.56,27.11.47,3.43.48,1.68,3.64,16.99,1.03,4.97,1.75,8.46,2.58,12.94,2.41,13.02,3.59,19.64,2.78,24.98-.9,5.97-3.11,10.06-5.36,14.25-2.92,5.43-10.69,19.85-23.06,21.44-1.63.21-5.67.67-9.23-1.65-3.16-2.06-4.5-5.36-5.33-8.06-3.23-10.46-1.37-19.47-.81-30.34.23-4.47-.05-9.83-.6-20.54-1.19-23.04-1.92-34.78-8.46-45.29-1.8-2.89-9.27-14.89-17.77-14.13-8.29.74-12.7,13.23-14.65,12.31Z"/>
  <path id="processusarticularishighlight2" class="Querfortsatze1703Fill" style= {{
            fill: props.colors.Querfortsatze1703Fill,
            stroke: props.colors.Querfortsatze1703Fill,
          }} d="M295.9,311.87c2.52-13.32,6.57-24.4,11.73-36.81,5.85-14.09,5.46-11.42,10.14-23.11,2.95-7.38,6.28-16.37,7.34-16.08,1.36.37-4.5,15.13,1.37,20.51,3.61,3.31,10.12,1.64,13.1.88,19.16-4.91,26.36-25.39,33.95-22.87,2.86.95,4.16,4.65,4.38,5.25,1.7,4.84-1.29,9.91-2.73,12.36-2.44,4.14-3.61,3.51-5.66,6.88-3.09,5.07-3.72,11.88-2.02,17.39,1.88,6.11,5.21,6.1,6.88,11.73,1.76,5.96-.48,11.02-1.86,15.31-1.09,3.37-1.32,13.05-1.78,32.42-.57,24.39-.86,36.58,1.21,42.47,1.81,5.15,4.26,9.67,3.75,16.7-.51,7.12-3.74,12.48-6.38,16.86-3.3,5.47-6.35,10.54-12.7,13.69-1.82.9-6.4,3.11-12.18,2.51-12.23-1.27-21.36-14.24-21.84-25.48-.07-1.69.09-2.88.4-5.26,1.51-11.27,6.12-15.62,10.11-27.91,1.56-4.8,2.3-8.58,2.51-9.69,1.43-7.6,4.19-22.27-3.23-35.72-1.57-2.86-9.08-15.59-20.72-16.47-1.49-.11-5.66-.43-9.3,2.02-5.64,3.8-6.35,11.79-7.07,11.63-1.1-.24.6-9.21.6-9.21Z"/>
  <path id="processusarticularishighlight1" class="Querfortsatze1703Fill" style= {{
            fill: props.colors.Querfortsatze1703Fill,
            stroke: props.colors.Querfortsatze1703Fill,
          }} d="M334.23,215.38c-1.51-.6,2.96-16.16,7.29-28.25,3.68-10.27,9.66-26.97,20.32-43.13,4-6.06,6.51-8.8,9.71-15.64,3.92-8.38,4.88-14.4,7.48-14.5,3.34-.13,3.63,9.71,9.42,11.44,3.51,1.05,6.24-1.73,17.2-8.83,15.03-9.74,17.7-9.59,19.28-9.11,6.49,1.98,8.2,13.73,8.66,16.86,2.19,14.96-3.44,25.96-6.84,35.55-10.58,29.89-5.43,62.92-2.73,80.22,1.71,10.98,4.86,24.52-1.87,37.69-3.75,7.34-8.77,11.04-10.89,12.44-1.83,1.21-11.77,7.47-22.85,4.23-3.36-.98-6.44-1.89-8.14-4.69-3.2-5.29,2.42-10.91,3.87-22.71,1.18-9.61-2.1-9.48-1.26-18.32,1.37-14.39,10.24-16.5,11.66-29.1.08-.67,1.52-15.53-8.82-25.59-10.72-10.43-29.69-11.4-36.82-4.1-3.02,3.1-2.32,6.06-9.17,17.8-2.41,4.14-4.7,8.06-5.49,7.74Z"/>
  <path id="corpusvertebraehighlight5" class="Wirbelkorper1703Fill" style= {{
            fill: props.colors.Wirbelkorper1703Fill,
            stroke: props.colors.Wirbelkorper1703Fill,
          }} d="M95.93,671.5c.26-1.37.48-2.55,1.09-3.81,2.98-6.15,12.01-7.67,20.49-8.9,13.47-1.94,20.2-2.91,28.36-3.29,17.72-.82,21.52.49,32.94-1.77,5.53-1.1,9.63-1.91,15.02-4.1,13.05-5.3,18.53-12.54,25.14-10.23,3.28,1.15,4.98,3.99,5.33,4.56,3.16,5.27.7,11.95-.41,15.41-7.86,24.29-1.35,49.2,1.76,55.24,1.59,3.09,3.53,6.61,3.53,6.61,3.33,6.07,3.43,5.75,4.11,7.39.95,2.29,3.49,8.38.91,14.37-1.4,3.25-3.71,5.1-4.57,5.77-3.94,3.09-8.37,3.48-9.64,3.62-11.82,1.27-48.82,12.74-68.49,20.76-18.37,7.49-28.26,14.68-34.17,10.05-3.45-2.71-4.14-8.34-4.78-13.67-1.04-8.6,1.17-12.6,1.47-20.11.08-2.05.04-5.01-4.87-22.11-3.91-13.63-6.51-27.68-11.52-40.94-.75-2-3.02-7.83-1.7-14.85Z"/>
  <path id="corpusvertebraehighlight4" class="Wirbelkorper1703Fill" style= {{
            fill: props.colors.Wirbelkorper1703Fill,
            stroke: props.colors.Wirbelkorper1703Fill,
          }} d="M132.83,499.16s3.26.78,10.34,2.28c9.67,2.05,17.4,1.85,28.02,1.51,6.13-.19,15.96-.73,29.93-2.72,25.51-3.64,36.22-8,39.25-3.46,1.42,2.13,0,4.5-2.85,12.36-4.73,13.03-6.85,23.04-9.49,35.59-2.76,13.07-3.48,22.68-3.72,26.44-.47,7.49-.73,11.73-.1,17.79.85,8.15,2.08,16.8,2.08,16.8.79,5.55,1.63,10.82-.95,12.7-.61.44-1.82,1-3.2,1.08-2.16.13-2.48-1-4.29-.95-1.87.05-2.2,1.29-6.22,3.28,0,0-.73.36-2.43,1.08-15.92,6.72-49.74,4.54-49.74,4.54-14.43-.93-21.64-1.4-29-2.92-19.12-3.96-28.67-5.94-33.35-13.24-6.89-10.76,5.69-18.79,7.39-51.97,1-19.51,1.5-29.26.19-37.09-1.26-7.59-3.62-16.42,1.23-21.75,2.89-3.18,7.26-3.92,9.22-4.25,4.6-.78,7.34.45,17.68,2.91Z"/>
  <path id="corpusvertebraehighlight3" class="Wirbelkorper1703Fill" style= {{
            fill: props.colors.Wirbelkorper1703Fill,
            stroke: props.colors.Wirbelkorper1703Fill,
          }} d="M146.72,347.74c3.42-2.25,7.57-1.78,14.76-.95,5.71.65,8.36,1.75,14.02,3.24,9.54,2.5,9.47,1.34,30.29,5.16,6.52,1.2,9.87,1.82,14.21,2.93,10.66,2.72,10.73,3.96,15.64,4.58,8.23,1.04,9.69-2.22,23.19-4.04,9.56-1.29,17.68-2.39,20.37,1.51,2.11,3.06-.26,7.57-5.34,19.02-3.64,8.19-6.93,16.53-10.44,24.77-9.63,22.64-14.45,33.96-12.38,44.5,1.75,8.91,6.32,13.75,3.49,20.76-2.69,6.66-9.68,9.4-10.61,9.75-3.77,1.42-7.1,1.27-20.94-2.23-15.28-3.86-18.96-5.64-29.4-7.53-5.98-1.08-9.32-1.69-14.26-1.83-10.81-.32-13.04,2.03-23.77,1.57-1.14-.05-6.24-.93-16.45-2.7-4.51-.78-9.12-1.64-12.77-5.35,0,0-.82-.83-1.49-1.76-5.15-7.14,2.16-26.13,6.71-45.21,2.21-9.27,3.75-18.47,4.85-25.08.62-3.68,1.61-9.26,3.61-20.4,2.71-15.11,3.42-18.54,6.71-20.71Z"/>
  <path id="corpusvertebraehighlight2" class="Wirbelkorper1703Fill" style= {{
            fill: props.colors.Wirbelkorper1703Fill,
            stroke: props.colors.Wirbelkorper1703Fill,
          }} d="M204.33,195.33c6.12-1.68,11.5,2.4,32.17,16.82,7.76,5.41,9.24,6.4,12.08,7.62,6.07,2.61,9.64,2.55,20.22,4.31,18.03,3.01,15.73,4.54,20.76,4.09,12.47-1.1,18.98-9.83,25.35-6.25,1.42.8,3.34,2.5,4.87,4.53.75,1,2.78,3.69,3.29,7.13.54,3.72-.93,6.67-2.22,9.38-6.83,14.32-4.29,13.09-13.42,30.71-1.32,2.54-1.41,2.65-1.95,3.8-3.42,7.31-3.68,11.78-5.64,18.31-2.1,7.03-2.99,5.85-4.81,11.92-4.19,14,.72,19.8-4.2,25.54-4.95,5.78-14.39,5.1-16.08,4.98-5.19-.37-6.84-2.32-16.19-6.83-6.37-3.07-13.35-6.43-22.38-8.63-10.28-2.5-20.4-3.07-20.4-3.07-2.54-.14-5.82-.22-10.61-.98-6.06-.96-5.93-1.66-8.63-1.62-6.49.09-7.45,4.1-13.76,4.43-6.23.32-10.73-3.31-11.26-3.75-.63-.52-4.7-4.01-5.64-9.8-1-6.18,2.14-11.38,8.01-21.07,2.39-3.94,2.61-3.76,4.04-6.2,5.72-9.77,4.71-17.2,6.74-28.58,2.85-15.94,6.84-12.57,8.53-26.05,1.55-12.37-1.12-20.79,5.22-27.07.99-.98,2.88-2.85,5.91-3.68Z"/>
  <path id="corpusvertebraehighlight1" class="Wirbelkorper1703Fill" style= {{
            fill: props.colors.Wirbelkorper1703Fill,
            stroke: props.colors.Wirbelkorper1703Fill,
          }} d="M259.9,67.42s.16,0,.35.02c9.8.71,14.76,10.61,25.21,16.47.42.23,4.16,1.53,11.62,4.1,15.23,5.25,22.84,7.88,23.92,8.2,25.52,7.61,42.23-8.2,51.34,1.41,6.06,6.39,3.99,19,3.78,20.22-1.03,5.86-3.72,9.88-4.85,11.6-5.87,8.88-22.54,34.07-29.93,53.93-7.47,20.07-4.32,31.57-11.44,33.82-5.7,1.8-9.45-5.01-25.77-11.44-4.25-1.67-4.88-1.56-23.62-6.09-19.68-4.76-29.65-7.19-36.52-9.82-12.2-4.67-20.46-9.64-26.7-17.53-2.74-3.46-4.7-6.02-4.85-9.71-.32-7.58,7.37-11.25,15.97-23.06,4.56-6.26,6.92-11.42,9.65-17.39,6.27-13.73,10.62-23.24,8.09-33.71-1-4.15-2.47-5.9-1.69-9.34,1.36-5.99,8.39-12,15.44-11.7Z"/>
  <path id="oscoccygisjunctionhighlight" class="LWS_Kreuzbein1703Fill" style= {{
            fill: props.colors.LWS_Kreuzbein1703Fill,
            stroke: props.colors.LWS_Kreuzbein1703Fill,
          }} d="M228.58,853.78c-76.82-6.53-168.98-39.47-166.69-66.7,2.66-31.65,132.92-55.24,246.24-39.05,9.47,1.35,134.64,19.87,133.39,50.52-1.16,28.34-110.26,63.95-212.94,55.23Z"/>
  <path id="thoracicandlumbarvertebrajunctionhighlight" class="BWS_LWS1703Fill" style= {{
            fill: props.colors.BWS_LWS1703Fill,
            stroke: props.colors.BWS_LWS1703Fill,
          }} d="M261.32,47.89c-3.54-7.5-5.76-20.22.31-27.8,28.7-35.82,229.45,60.1,221.66,90.61-2.15,8.42-19.87,10.65-26.05,11.42-34.11,4.29-47.83-21.07-91.25-39.74-54.87-23.59-92.51-8.72-104.67-34.5Z"/>
  <path id="intervertebralspacehighlight4" class="Intervertebral_spaces1703Fill" style= {{
            fill: props.colors.Intervertebral_spaces1703Fill,
            stroke: props.colors.Intervertebral_spaces1703Fill,
          }} d="M213.02,639.61c-1.79,2.58-4.12,3.69-8.53,5.56-10.99,4.64-16.48,6.96-21.52,8.1-11.05,2.5-12.73.84-37.09,2.23-26.32,1.5-15.43,2.92-28.36,3.29-5.15.15-13.62.12-17.93,5.65-1.35,1.73-1.68,3.3-2.57,3.25-2.6-.15-3.74-13.73-4.58-23.73-.79-9.43-.48-11.99-.21-13.62,1.3-7.8,5.31-16.23,8.69-16,1.75.12,1.46,2.37,4.87,4.55,2.77,1.77,5.15,1.93,9.25,2.62,5.98,1.01,5.79,1.48,11.78,2.64,25.41,4.91,48.94,3.83,48.94,3.83,13.87-.64,20.81-.96,26.3-3.42,4.15-1.87,6.97-3.97,9.57-2.7,4.23,2.07,5.46,11.9,1.38,17.77Z"/>
  <path id="intervertebralspacehighlight3" class="Intervertebral_spaces1703Fill" style= {{
            fill: props.colors.Intervertebral_spaces1703Fill,
            stroke: props.colors.Intervertebral_spaces1703Fill,
          }} d="M236.98,495.37c-2.76,2.37-6.35.62-12.4.85-4.85.18-7.63,1.5-11.31,2.65-10.51,3.27-35.54,7.31-59.08,4.06-10.09-1.39-15.83-2.19-21.37-3.76-5.4-1.54-8.07-3.1-12.88-2.71-1.36.11-2.51.35-3.44-.38-1.89-1.48-.54-5.13.47-12.03,1.34-9.11.05-9.95,1.89-14.56.45-1.13,3.57-8.7,7.45-8.58,1.82.06,2.26,1.75,5.49,3.46,2.64,1.39,4.91,1.61,7.28,1.89,10.45,1.24,10.63,2.3,17.53,2.7,12.34.72,14.09-2.52,25.97-1.64,2.99.22,6.16.88,12.49,2.19,23.39,4.84,23.35,8.65,33.57,8.31,2.89-.1,6.25-.51,8.59,1.66,3.32,3.1,2.31,9.35,2.16,10.25-.37,2.1-.74,4.22-2.43,5.66Z"/>
  <path id="intervertebralspacehighlight2" class="Intervertebral_spaces1703Fill" style= {{
            fill: props.colors.Intervertebral_spaces1703Fill,
            stroke: props.colors.Intervertebral_spaces1703Fill,
          }} d="M278.65,358.52c-1.03.21-1.44-.38-4.31-.94,0,0-2.97-.58-7.28-.4-7.28.3-20.94,4.87-29.12,5.72-7.54.78-5.14-2.17-25.21-6.4-17.44-3.68-22.22-2.08-35.6-6.05-4.22-1.25-7.37-2.49-12.94-3.24-7.81-1.06-10.02.22-12.27-1.75-4.75-4.17-1.32-15.5,1.21-21.03,3.15-6.88,9.14-14.05,12.54-12.94,1.73.56,1.19,2.76,4.12,5.86.31.33,3.97,4.1,8.82,4.52,3.92.34,5.79-1.74,9.57-3.24,4.83-1.91,7.03-.58,21.03.94,14.35,1.56,13.48.32,21.03,1.89,7.49,1.56,13.72,3.89,16.97,5.12,3.57,1.35,6.95,2.63,11.34,4.85,7.82,3.95,9.01,5.9,13.58,6.55,5.75.81,7.64-1.74,9.95-.08,5.02,3.61,1.48,19.66-3.44,20.64Z"/>
  <path id="intervertebralspacehighlight1" class="Intervertebral_spaces1703Fill" style= {{
            fill: props.colors.Intervertebral_spaces1703Fill,
            stroke: props.colors.Intervertebral_spaces1703Fill,
          }} d="M321.39,226.52c-2.31-.08-2.46-2.38-5.46-3.84-4.59-2.24-10.31.21-12.48,1.14-2.57,1.1-2.45,1.64-5.12,2.71-2.74,1.09-5.54,1.58-8.77,1.66-8.59.22-18.13-2.44-26.62-4.7-14.47-3.85-12.79-3.05-14.36-3.71-12.25-5.13-21.11-14.82-30.94-20.56-3.23-1.88-5.2-3-8.09-3.24-2.43-.2-3.79.37-5.22-.65-2.2-1.58-2.09-5.19-2.06-6.02.1-3.13,1.42-4.04,3.64-8.7,3.01-6.31,3.88-11.57,6.66-12.43.23-.07.9-.28,1.66-.08,1.47.39,2.2,2,2.6,2.8,1.1,2.19,3.1,3.81,4.49,5.83,3.49,5.06,13.23,9.64,14.32,10.14,10.26,4.76,23.17,8.36,44.88,12.78,6.8,1.39,11.98,2.35,19.64,4.61.65.19,11.19,3.4,21.64,11.12,2.86,2.12,5.07,4.1,4.85,6.47-.22,2.4-2.89,4.73-5.26,4.65Z"/>
  <path id="spinousprocesshighlight5" class="Dornfortsatze1703Fill" style= {{
            fill: props.colors.Dornfortsatze1703Fill,
            stroke: props.colors.Dornfortsatze1703Fill,
          }} d="M297.78,688.83c.92-.64,2.65,1.28,7.02,2.85,0,0,3.94,1.42,9.3,1.62,7.22.27,12.06-3.19,16.18-4.85,7.74-3.14,11.79.49,38.83,7.69,6.14,1.63,17.25,4.46,29.13,7.25,16.75,3.94,27.12,5.95,28.71,12.16.68,2.65-1.03,3.01-.81,8.9.25,6.88,2.81,12.8,3.24,13.75,1.62,3.66,2.48,4.02,2.83,6.47.32,2.24.04,4.95-2.1,9.27-3.78,7.62-9.41,12.32-11.25,13.79-4.32,3.43-12.03,9.56-22.25,9.71-8.06.12-9.55-3.61-18.2-2.83-8.08.73-7.82,4.07-13.75,4.04-11.19-.05-15.26-11.97-25.53-10.53-1.61.22-4.61.95-7.63,2.04-6.18,2.22-6.93,3.97-8.49,3.64-5.15-1.09.86-20.56-7.28-41.26-1.89-4.8-3.98-8.25-15.76-34.07-.54-1.18-3.84-8.49-2.18-9.64Z"/>
  <path id="spinousprocesshighlight4" class="Dornfortsatze1703Fill" style= {{
            fill: props.colors.Dornfortsatze1703Fill,
            stroke: props.colors.Dornfortsatze1703Fill,
          }} d="M297.72,559.42c6.6-5.23,14.51-5.28,18.4-5.26,7.25.05,6.81,2.02,14.57,2.25,7.61.23,7.75-1.67,16.98-1.65,6.81.02,11.84,1.07,14.16,1.62,3.96.94,6.79,2.05,8.42,2.69,23.16,9.04,23.92,8.11,32.03,12.48,1.71.92,6.92,4.45,17.35,11.51,12.88,8.72,16.15,11.33,18.49,15.97,2.12,4.22,2.24,8.07,2.39,15.59.28,13.96.63,31.66-8.89,37.03-4.36,2.46-5.52-.64-14.17,2-8.35,2.55-10.07,6.3-17.79,7.94-4.81,1.02-8.54.5-12.55-.05-3.96-.55-16.48-2.29-26.09-11.53-4.73-4.55-5.43-7.73-10.86-10.25-4.35-2.02-8.39-2.08-10.78-2.09-2.66,0-12.41-.04-19.18,4.37-.93.61-3.22,2.21-5.65,1.62-.41-.1-1.51-.43-2.69-1.7-3.8-4.1-1.41-10.55-3.63-21.49,0-.02-.39-1.57-1.17-4.66-3.98-15.81-4.15-16.43-4.53-17.66-.99-3.21-.74-1.68-6.23-15.09-4.22-10.31-5.27-13.4-4.04-16.99,1.1-3.22,3.44-5.07,5.46-6.67Z"/>
  <path id="spinousprocesshighlight3" class="Dornfortsatze1703Fill" style= {{
            fill: props.colors.Dornfortsatze1703Fill,
            stroke: props.colors.Dornfortsatze1703Fill,
          }} d="M327.86,428.17c1.43-1.23,3.48-1.22,5.54-1.19,4.53.08,7.9,1.64,8.21,1.78,11.37,5.24,23.84,7.73,35.39,12.54,28.31,11.77,29.77,11.68,38.8,16.64,9.6,5.26,14.51,8.01,19.85,13.7,3.25,3.46,12.65,13.77,13.75,28.92.12,1.7-.25,5.31-1.01,12.54-2.37,22.64-5.64,28.52-9.75,31.33-1.73,1.18-3.85,2.04-3.85,2.04-3.96,1.6-7.53,1.64-11.9,1.64-2.61,0-4.66-.16-5.24-.22-10.08-.98-25.34-13.08-41.66-21.44-11.53-5.9-17.29-8.85-25.27-8.51-5.4.23-8.63,1.77-11.96-.32-4.56-2.85-4.17-9.27-5.24-19.89-1.79-17.87-4.48-15.66-6.73-36.83-2.15-20.28,1.22-13.81-.3-24.06-.3-2.01-1.07-6.56,1.37-8.67Z"/>
  <path id="spinousprocesshighlight2" class="Dornfortsatze1703Fill" style= {{
            fill: props.colors.Dornfortsatze1703Fill,
            stroke: props.colors.Dornfortsatze1703Fill,
          }} d="M376.61,296.47c1.78-.43,3.55,1.09,4.32,1.75,0,0,9.05,7.7,23.78,17.02,5.29,3.34,12.98,8.21,23.19,8.76,3.46.19,4.7-.25,8.31.41,6.55,1.2,10.95,4.22,16.86,8.27,4.41,3.02,1.86,1.9,12.17,10.2,2.78,2.24,4.92,3.89,6.2,6.95,1.35,3.22.99,6.18.81,7.75-2.88,24.34-2.09,26.23-5.26,31.28-2.4,3.82-6.32,10.07-12.58,10.95-7.25,1.01-9.73-6.27-21.26-12.43-8.51-4.54-27.89-11.65-39.37-3.91-4.03,2.72-10.23,9.39-14.7,8.09-1.14-.33-2.12-1.02-2.12-1.02-2.62-1.84-3.35-4.98-3.68-6.67-5.69-29.32-1.55-73.82-1.55-73.82.41-4.4,1.25-12.69,4.86-13.57Z"/>
  <path id="spinousprocesshighlight1" class="Dornfortsatze1703Fill" style= {{
            fill: props.colors.Dornfortsatze1703Fill,
            stroke: props.colors.Dornfortsatze1703Fill,
          }} d="M438.29,166.79c5.97.6,7.57-5.04,14.62-6.29,4.23-.75,9.96,2.08,21.25,7.77,7.66,3.86,11.57,5.86,15.83,9.26,4.69,3.75,9.44,7.56,12.22,14.2,2.19,5.24,2.05,9.63,1.89,14.97-.15,4.8-.63,20.29-10.55,28.41-1.96,1.6-3.94,2.63-4.41,2.87-5.63,2.88-10.95,2.9-14.29,2.83-13.96-.29-16.06-2.71-24.4-2.43-12.98.45-15.24,6.56-20.76,4.31-9.08-3.68-9.19-22.68-9.3-42.34-.03-4.41-.07-38.33,7.41-39.52,3.16-.5,4.6,5.35,10.52,5.94Z"/>
  <path id="rearwardvertebraledgeshighlight5" class="Wirbelkanten1703Fill" style= {{
            fill: props.colors.Wirbelkanten1703Fill,
            stroke: props.colors.Wirbelkanten1703Fill,
          }} d="M240.06,730c-7.8-9.51-9.46-13.25-11.25-12.64-1.79.61-1.85,5-1.86,5.5-.1,7.85,6.7,10.23,6.14,15.45-.64,6.02-10.44,9.88-14.36,11-4.91,1.4-10.41,2.19-10.41,2.19-4.68.68-6.33.51-7.28,1.96-1.71,2.62.23,8.47,3.81,10.26,1.96.98,3.93.52,5.21.19,7.65-1.99,13.57-5.34,16-6.65,12.3-6.59,18.32-5.07,20.13-10.52.91-2.76.21-5.68-.14-7.13-.88-3.66-2.69-5.55-6.01-9.6Z"/>
  <path id="rearwardvertebraledgeshighlight4" class="Wirbelkanten1703Fill" style= {{
            fill: props.colors.Wirbelkanten1703Fill,
            stroke: props.colors.Wirbelkanten1703Fill,
          }} d="M230.52,601.57c-5.47-9.87-6.08-16.03-7.91-15.79-1.39.18-2.81,3.97-3.08,6.86,0,0,0,.06-.01.13-.69,8.14,5.13,11.33,4.24,16.69-.82,4.94-7.89,9.22-13.49,9.9-4.76.58-9.88-1.22-10.65.53-.11.24-.14.56.13,1.31,1.3,3.63,5.38,5.36,6.55,5.9,2.26,1.02,5.1,1.72,9.83,1.3,5.88-.53,15.84-1.44,18.54-8.8,1.02-2.77.62-5.87.42-7.41-.55-4.28-2.33-6.55-4.57-10.6Z"/>
  <path id="rearwardvertebraledgeshighlight3" class="Wirbelkanten1703Fill" style= {{
            fill: props.colors.Wirbelkanten1703Fill,
            stroke: props.colors.Wirbelkanten1703Fill,
          }} d="M258.84,460.52c-3.64-10.81-3.14-17.05-5-17.14-1.41-.07-3.5,3.45-4.29,6.28,0,0-.02.06-.04.13-2.15,7.98,3.04,12.2,1.2,17.37-2.18,6.11-13.85,7.33-15.15,7.44-6.18.5-10.1-1.6-10.76-.06-.65,1.53,2.57,5.06,5.43,7.05,2.05,1.42,4.75,2.63,9.51,3.05,5.92.53,15.95,1.41,19.95-5.43,1.51-2.58,1.67-5.74,1.75-7.31.23-4.36-1.13-6.94-2.62-11.38Z"/>
  <path id="rearwardvertebraledgeshighlight2" class="Wirbelkanten1703Fill" style= {{
            fill: props.colors.Wirbelkanten1703Fill,
            stroke: props.colors.Wirbelkanten1703Fill,
          }} d="M298.28,330.01c-.18-9.71,1.85-14.63.36-15.2-1.13-.43-3.75,1.87-5.12,3.96,0,0-.03.05-.06.1-3.99,6.16-1.2,10.94-3.52,14.38-3.02,4.48-12.35,3.26-13.65,3.09-5.4-.7-8.76-3.33-9.58-2.26-.92,1.19,2.6,5.25,2.78,5.45,2.28,2.61,4.89,4.09,6.97,4.98,4.7,1.99,12.66,5.34,17.7.86,1.9-1.69,2.85-4.2,3.32-5.45,1.31-3.47.88-5.92.81-9.9Z"/>
  <path id="rearwardvertebraledgeshighlight1" class="Wirbelkanten1703Fill" style= {{
            fill: props.colors.Wirbelkanten1703Fill,
            stroke: props.colors.Wirbelkanten1703Fill,
          }} d="M340.69,214.26c.5-9.4,2.97-13.73,1.45-14.59-1.16-.65-4.06,1.02-5.65,2.75,0,0-.04.04-.07.08-4.45,4.93-2.11,10.5-4.71,13.14-2.81,2.86-9.6.49-11.74-.25-7.27-2.53-11.1-8.05-12.54-6.98-.55.41-.38,1.5-.12,3.19.45,2.92,1.86,4.97,2.52,5.84,1.98,2.58,4.39,3.77,8.52,5.64,13.61,6.15,15.52,5.86,16.92,5.13,2.05-1.06,3.07-3.06,3.87-4.57,1.62-3.07,1.34-5.53,1.55-9.38Z"/>
  <path id="baseandupperplateshighlight8" class="Grund_Deckplatten1703Fill" style= {{
            fill: props.colors.Grund_Deckplatten1703Fill,
            stroke: props.colors.Grund_Deckplatten1703Fill,
          }} d="M92.55,678.06c-1.35-2.23-.68-5.42-.4-6.77,1.57-7.48,8.54-11.49,10.21-12.42,10.66-5.92,38.11-5.94,40.04-5.94,13.17.01,14.44.14,20.43-.08,11.63-.43,17.53-.68,21.7-2.33,12.24-4.83,20.42-12.53,20.42-12.53,0,0,4.65-5.1,9.32-4.48,1.61.21,2.89,1.31,3,1.4.23.2,2.21,1.82,2.06,3.34-.21,2.17-4.66,3.35-6.35,3.88-8.38,2.65-11.66,9.38-16.56,13.69-7.44,6.55-19.69,6.5-44.19,5.74-16.12-.51-23.52-2.38-34.58.75-1.76.5-17.21,5.02-19.36,13.38-.19.73-.92,4-2.65,4.36-1.11.23-2.36-.81-3.08-1.99Z"/>
  <path id="baseandupperplateshighlight7" class="Grund_Deckplatten1703Fill" style= {{
            fill: props.colors.Grund_Deckplatten1703Fill,
            stroke: props.colors.Grund_Deckplatten1703Fill,
          }} d="M95.56,602.26c.48-1.03,1.19-1.36,1.39-1.46,2.24-1.02,4.12,3.13,6.55,6.35,0,0,3.15,3.56,7.62,6.22,5.23,3.12,10.46,3.78,13.25,4.14,4.65.59,5.99-.07,10.78,1.02,2.7.62,3.38,1.11,6.5,2.01,2.49.72,5.96,1.53,11.76,2.38,14,2.06,21,3.08,26.85.51,5.01-2.2,10.37-2,15.06-6.08.98-.85,3.66-3.32,5.28-1.7,1.05,1.05,1.51,3.66,1.76,5.08.31,1.76.46,3.8.38,4.49-1,8.27-49.18,7-49.19,7,0,0-15.28-.4-44.23-9.65,0,0-11.59-3.7-13.78-13.86-.26-1.22-.95-4.42.02-6.47Z"/>
  <path id="baseandupperplateshighlight6" class="Grund_Deckplatten1703Fill" style= {{
            fill: props.colors.Grund_Deckplatten1703Fill,
            stroke: props.colors.Grund_Deckplatten1703Fill,
          }} d="M107.62,495.42c.24-.83,1.47-2.98,9.61-2.88,10.53.13,18.39,3.9,18.45,3.93,10.29,4.93,20.34,4.68,35.49,4.31,22.34-.55,54.55-10.33,58.25-11.47,3.51-1.08,10.77-3.64,12.77.39,1.54,3.11.52,9.64-1.75,11.28-1.63,1.18-3.83-.54-4.37-.89-7.92-5.23-30.07,2.81-43.42,6.19-14.54,3.68-34.17,8.66-49.92,2.51-6.69-2.61-16.63-9.34-24.73-5.3-.97.48-4.11,2.22-6.88.28-2.38-1.66-4.23-5.84-3.51-8.36Z"/>
  <path id="baseandupperplateshighlight5" class="Grund_Deckplatten1703Fill" style= {{
            fill: props.colors.Grund_Deckplatten1703Fill,
            stroke: props.colors.Grund_Deckplatten1703Fill,
          }} d="M225.77,475.24c-1.5-1.2-3.24-.66-3.62-.55-5.94,1.64-23.08-9.43-40.65-11.42-10.96-1.24-13.5,2.02-30.46,1.49-2.95-.09-7.31-1.02-16.02-2.78-3.55-.71-5.73-1.23-8.09-3.36-3.5-3.16-4.08-6.89-5.86-6.58-2.17.38-4.3,6.32-3.27,10.73.52,2.24,1.73,3.57,2.31,4.15,8.47,8.49,20.86,7.58,20.86,7.58,23.17-1.71,50.48-3.72,62.11,5.1,3.7,2.81,5.75,3.59,9.85,5.15,5.14,1.95,8.55,2.16,11.58.29,1.05-.65,2.99-1.85,3.34-4.29.31-2.19-.78-4.48-2.06-5.51Z"/>
  <path id="baseandupperplateshighlight4" class="Grund_Deckplatten1703Fill" style= {{
            fill: props.colors.Grund_Deckplatten1703Fill,
            stroke: props.colors.Grund_Deckplatten1703Fill,
          }} d="M144.31,342.18c1.91-2.11,5.28.16,12.51,2.12,6.05,1.64,7.4,1.06,16.87,2.28,3.99.52,8.56,1.24,27.06,5.44,37.22,8.44,36.95,9.63,42.47,8.47,3.02-.64,11.42-2.74,25.19-5.49,7.14-1.43,11.74-2.23,13.09.5,1.24,2.49-.4,7.57-3.1,9.45-1.59,1.11-3.01.74-4.86.65-6.04-.29-7.96,2.83-16.28,5.12-3.81,1.05-6.53,1.26-10.73,1.57-11.8.9-21.52-2.17-26.91-3.85-55.4-17.29-60.6-15.96-63.73-14.66-1.76.73-6.69,3.06-9.85.45-3.15-2.61-3.92-9.63-1.73-12.05Z"/>
  <path id="baseandupperplateshighlight3" class="Grund_Deckplatten1703Fill" style= {{
            fill: props.colors.Grund_Deckplatten1703Fill,
            stroke: props.colors.Grund_Deckplatten1703Fill,
          }} d="M269.94,335.25c-2.04-2.6-4.61-2.81-7.78-3.62-2.73-.7-8.18-3.52-19.1-9.15-1.31-.67-3.38-1.76-6.32-2.77-11.47-3.92-9.97.21-30.32-3.82-7.82-1.55-11.88-1.19-15.85.02-5.17,1.58-6.6,3.89-10.35,3.21-4.05-.74-6.83-4.26-8.02-5.78-4.3-5.45-3.96-10.59-5.82-10.52-2.17.09-4.92,6.66-4.05,12.03.29,1.82.97,3.28,2.02,4.86,4.97,7.42,12.65,8.75,14.14,8.98,6.74,1.05,12.09-1.03,14.24-1.79,19.96-6.95,47.39,2.13,58.55,10.81,5.61,4.37,8.73,4.7,14.98,5.37,2.48.26,4.66.17,5.37-1.44.8-1.81-.33-4.68-1.68-6.39Z"/>
  <path id="baseandupperplateshighlight2" class="Grund_Deckplatten1703Fill" style= {{
            fill: props.colors.Grund_Deckplatten1703Fill,
            stroke: props.colors.Grund_Deckplatten1703Fill,
          }} d="M209.05,195.48c1.88-1.92,6.61.67,11.93,3.64,6.21,3.47,7.66,5.39,13.72,9.63,5.18,3.62,11.19,7.75,19.58,10.53,4.37,1.45,2.92.38,12.49,2.76,12.69,3.16,15.33,5.05,21.44,4.01,4.74-.81,9.52-3.05,10.2-3.37,6.55-3.1,9.46-6.45,11.83-5.16,1.67.92,2.29,3.72,1.78,5.87-.32,1.37-1.49,4.17-8.05,6.95-8.89,3.77-18.83,3.75-33.02,1.42-10.98-1.8-17.5-2.87-26.22-6.32-16.08-6.35-14.39-10.8-26.11-14.27-1.39-.41-5.27-1.49-7.86-5.25-2.31-3.36-3.46-8.65-1.71-10.44Z"/>
  <path id="baseandupperplateshighlight1" class="Grund_Deckplatten1703Fill" style= {{
            fill: props.colors.Grund_Deckplatten1703Fill,
            stroke: props.colors.Grund_Deckplatten1703Fill,
          }} d="M307.25,203.36c-.9-1.54-3.1-1.73-4.47-1.92-5.76-.79-7.09-2.89-12.94-4.85-11.88-3.98-25.25-1.62-44.09-7.41-9.56-2.94-14.61-4.57-18.47-9.58-4.41-5.74-6.76-15.26-9.17-14.83-.71.13-2,1.22-2.16,1.35,0,0-1.01.86-1.72,1.99-.93,1.46-.86,6.54,3.07,12.58,6.89,10.58,19.62,14.84,43.21,22.74.82.27,2.53.84,4.85,1.29,2.41.47,3.68.25,20.5,4.02,10.45,2.34,17.57,4.13,20.41.2,1.12-1.55,1.9-4.01.98-5.57Z"/>
</g>
</svg>
  );
}

export default LendenwirbelsauleLatStehend1703;
