import React from "react";
import { Row, Col } from "react-bootstrap";
import FacilitiesTable from "../../Facilities/FacilitiesList";
import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../Redux/mappers/reports/reportsMappers";

function SelectFacility(props) {
  const rowClickHandler = (event, row) => {
    props.addRemoveReportSelectedId(row.original.id);
  };

  return (
    <Row>
      <Col md={12} xl={12}>
        <FacilitiesTable
          headerLess={true}
          keycloak={props.keycloak}
          rowClick={rowClickHandler}
          selectedIds={props.reports.repSelectedIds}
        ></FacilitiesTable>
      </Col>
    </Row>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectFacility);
