import style from "./style.scss";

export default function RechterOberschenkelMitKnieVdLiegend2344(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 904.7 1088.2"
    >
      <g id="fullknee">
        <g id="underlay">
          <ellipse
            id="joint_gap"
            data-name="joint gap"
            class="Gelenkspalt2344Fill"
            style={{
              fill: props.colors.Gelenkspalt2344Fill,
              stroke: props.colors.Gelenkspalt2344Fill,
            }}
            cx="483.4"
            cy="824"
            rx="55.5"
            ry="138.8"
            transform="translate(-352.2 1287.1) rotate(-88.6)"
          />
        </g>
        <g id="knee_with_upper_leg" data-name="knee with upper leg">
          <path
            id="background_lower_leg"
            data-name="background lower leg"
            class="Oberschenkel_mit_Knie_vd_right2344_cls10"
            d="M549.2,1042.2c3.8-22.6.9-20.3,4.1-32,5.5-20,21-42,21-42,21.8-31.1,20.2-26,26-36,15.2-26.4,16-47,16-47,.4-9.6-1.1-16.1-3-21-2.7-6.8-5.7-9.6-6.6-10.4-4.7-4.2-10.2-5.1-13.4-5.6-25.8-3.9-41-5-41-5-8.8-.6-17.7-1.3-27-5-7.7-3-10.1-5.9-11-7-3.3-4.2-2.9-7.9-6-9-.8-.3-1.9-.3-10,3-5.9,2.4-6.8,3.9-10,4-3.7,0-4.6-1.9-10-4-3.4-1.3-9.6-3.7-15-2-5.2,1.7-4.6,5.5-12,12-.8.7-6.9,6-14,8-8.6,2.4-12.8-1.6-27-5-12-2.8-23.5-5.5-35-1-10.1,4-15.7,11.8-18,15-1.3,1.9-10.8,15.4-9,33,1.3,12.9,8.1,21.5,5,24-2.4,2-6.8-3.1-12-1-1.2.5-5.2,2.4-9,15-3.4,11.1-1.5,13.9-4,23-2.9,10.8-6.7,11.7-10,21-1.5,4.2-3.8,12.7-1,30,3.2,19.9,9.5,29.1,20,54,2.5,6,7.6,18.3,12.6,32.9,11.4,0,22.9.2,34.3.8,2.1,0,4.1.2,6.2.3.2-6.2,1.3-16.2,5.9-27,4.5-10.6,7.2-9.4,9-17,1.9-8-.5-11.3-3-30-1.2-9.5-2.8-21.5-1-22,2.1-.6,8.1,13.8,12,25,4.5,13.1,8.2,27.6,12,57,.7,5.7,1.4,10.9,2,15.7,21.2.8,42.5,1.3,63.9,1.3s34.1,0,51.1-.2v-3.8c0-14.9,3.2-19.2,7-42h0Z"
          />
          <path
            id="tibia_outines"
            data-name="tibia outines"
            class="Oberschenkel_mit_Knie_vd_right2344_cls4"
            d="M543,1086.3c2.2-39.4,7.2-78.4,28.2-113.4l3-3c2-3,2-6,4-8s3-5,5-7c15-15,28-33,30-54,3-15,6-34-6-46-21-10-45-11-69-14-7-1-15-2-21-7-1-1-1-2-1-3-1-1-2-1-2-2-1-5-5-7-8-7-5,1-9,5-13,7-7,2-14-2-20-7-3-2-7-2-9,0-12,12-25,27-43,21-19-7-41-12-59,1-28,34-6,79,15,113,12,19,27,34,35,54,9,24,12,48,14.8,73.3"
          />
          <path
            id="fibula_outlines"
            data-name="fibula outlines"
            class="Oberschenkel_mit_Knie_vd_right2344_cls4"
            d="M349.8,1081.8c-5.6-21-16.6-39-25.6-59-7-16-14-35-7-52,7-15,10-30,14-45,3-7,5-16,14-18,27,9,55,31,57,62,1,20,0,39,3,59,2,12-4,22-9,33-3,7-3,14-4.9,21.1"
          />
          <path
            id="tibia_1"
            data-name="tibia 1"
            class="Oberschenkel_mit_Knie_vd_right2344_cls4"
            d="M519.3,835.2c.3,17.7,37.1,29.4,82,22"
          />
          <path
            id="tibia_2"
            data-name="tibia 2"
            class="Oberschenkel_mit_Knie_vd_right2344_cls4"
            d="M437.2,843.2c-.4,15.6-36.7,23.4-80.7,7.9"
          />
          <path
            id="fibula_1"
            data-name="fibula 1"
            class="Oberschenkel_mit_Knie_vd_right2344_cls3"
            d="M394.5,1067.6c0-31.2,3.9-56.4,8.7-56.4"
          />
          <path
            id="fibula_2"
            data-name="fibula 2"
            class="Oberschenkel_mit_Knie_vd_right2344_cls3"
            d="M326.2,971.2c11.2,27.8,8.5,49.3,7,49"
          />
          <path
            id="fibula_3"
            data-name="fibula 3"
            class="Oberschenkel_mit_Knie_vd_right2344_cls3"
            d="M394.5,1067.6c-2.8,4.3-5,8.9-6.8,13.7"
          />
          <path
            id="tibia_3"
            data-name="tibia 3"
            class="Oberschenkel_mit_Knie_vd_right2344_cls3"
            d="M533.8,1085.9c1-10.8,2.3-21.6,3.9-32.3,2.2-13.8,4.7-28,9.6-41.2,1.8-4.8,5.4-15.2,12-15.2"
          />
          <path
            id="tibia_4"
            data-name="tibia 4"
            class="Oberschenkel_mit_Knie_vd_right2344_cls3"
            d="M362.7,931.4c8.8-22-.2-48.4-13.4-53.3"
          />
          <path
            id="tibia_5"
            data-name="tibia 5"
            class="Oberschenkel_mit_Knie_vd_right2344_cls3"
            d="M526.3,904.8c8.4-6.6,33.1-4.9,60,0"
          />
          <path
            id="tibia_6"
            data-name="tibia 6"
            class="Oberschenkel_mit_Knie_vd_right2344_cls3"
            d="M379.3,896.2c19.6,8.6,118.5-18,118.5-4"
          />
          <path
            id="tibia_7"
            data-name="tibia 7"
            class="Oberschenkel_mit_Knie_vd_right2344_cls3"
            d="M442.3,847.3c23.1,20.9,72.2-18.1,77-12.1"
          />
          <g id="upper_leg" data-name="upper leg">
            <path
              id="upper_leg_background"
              data-name="upper leg background"
              class="Oberschenkel_mit_Knie_vd_right2344_cls10"
              d="M389.2,393.5c6.1,73.3,4.1,72.9,8.8,87.2,4.6,15.4,13.2,36.7,7.7,59.2-.9,4-3.1,11.8-5.2,23.7-1.3,6.6-2.2,15-2.8,28.3,0,0-.9,18.9-5.9,35.7-.7,2.4-1.6,4.9-2.7,7.5-5.7,13.8-9.5,13.8-17.4,31.8-3,6.8-8,18.4-11,34-2.8,14.9-.7,17-4,33-3.3,16.1-5.8,15.9-7.3,26.6-.9,6.2-4.1,29,8.3,42.4,10.5,11.4,27.4,10.7,42,10.1,7.8-.3,43.2-2.6,71.5-22.1,4-2.8,12.2-8.9,23.9-9.7,2.7-.2,9.4-.3,19.2,3.4,16.2,6.2,17.8,14.1,34.3,25.3,9.5,6.4,23.7,16.1,41,14.3,3.4-.4,16.2-1.7,22.8-11.3,4-5.8,4.2-12.5,4.3-16.7.3-8.6-2-12.6-3.1-18.4-2.2-11.9,3.6-16.1,15.1-49.3,5.9-17.1,6.2-21.2,6.2-23.7.4-15.6-6.9-27.4-9.6-31.7-6-9.6-12.2-13.8-18.5-19.5-21-18.7-30.4-40.9-38.2-57.7-4-8.7-8.7-18.8-12-33-2.5-10.5-1.3-10.9-3-14-5.8-18.4-13.6-43.9-22-74.6h0c-22.5-81.6-34.6-145-43.8-193.8,0,0-15.6-82.4-29.4-178-3.5-23.9-9.4-47.5-11.9-71.5-1.7-16.3-1.9-24.9-3.7-31-12.5.4-25,.5-37.5.5-17.9.6-35.9,1.2-53.8,1.3-3.3.2-6.7.2-10-.1h0c-3.6,8.3.2,13.5,5.8,40,.6,2.6,3.2,21.4,8.3,59,8.1,59.4,12.2,89.1,14,103.2,10.8,84.4,17.2,160.3,19.6,189.6h0Z"
            />
            <path
              id="femur_outlines"
              data-name="femur outlines"
              class="Oberschenkel_mit_Knie_vd_right2344_cls4"
              d="M553.4,549c3.7,21.4,10.1,37,15,47,7.8,15.7,27.4,49,50,70,2.2,2,7.1,6.5,11,14,6.5,12.7,5.5,25.3,5,30-.9,9.1-3,10.1-11,32-6.5,17.8-9.8,26.7-10,33-.6,16.6,6.8,22.5,2,33-4.2,9.2-13.6,13-16,14-18.6,7.6-38.9-4.4-45-8-12.7-7.5-11.9-12-31-24-10.3-6.5-16.3-8.8-23-9-8.5-.3-14.5,2.9-21,6-17,8.2-35.8,15.9-49,20-10.1,3.1-23.5,7.1-41,6-12.2-.8-23.7-1.5-32-10-10.3-10.5-9.5-26.4-9-36,.7-14.1,4.7-14.2,8-33,3.6-20.3-.4-24.2,4-41,1.9-7.3,4.9-13.5,11-26,13.2-27.1,18.8-30.4,23-46,2.4-8.9,1.3-10.6,3-29,1.1-12.6,3.4-30.4,8-52"
            />
            <path
              id="patella"
              class="Oberschenkel_mit_Knie_vd_right2344_cls4"
              d="M487.4,746c7.5.7,8.5-.7,37-6,22.2-4.1,23.2-3.5,28-6,14.2-7.3,21-20.4,25-28,5.1-9.8,11.4-21.9,9-37-3.5-22.1-22.7-34.3-27-37-5.1-3.2-4.7-2-28-11-25.1-9.8-25.4-11.2-32-12-20-2.4-36.3,8.5-40,11-20.9,14-26.6,35.4-28,41-1.5,6.1-4.7,22.3,2,41,1.9,5.3,7.2,19.6,22,31,14.5,11.1,29.3,12.7,32,13h0Z"
            />
            <path
              id="femur_1"
              data-name="femur 1"
              class="Oberschenkel_mit_Knie_vd_right2344_cls3"
              d="M356.4,734c21.3,32.7,9.9,52.2,19,52"
            />
            <path
              id="femur_2"
              data-name="femur 2"
              class="Oberschenkel_mit_Knie_vd_right2344_cls3"
              d="M356.4,734c-17-17.5,0-13,0-35"
            />
            <path
              id="femur_3"
              data-name="femur 3"
              class="Oberschenkel_mit_Knie_vd_right2344_cls3"
              d="M375.4,712c0,1.7,15.3-11,48,3"
            />
            <path
              id="femur_4"
              data-name="femur 4"
              class="Oberschenkel_mit_Knie_vd_right2344_cls3"
              d="M461.4,746.2c1,35.6-10.2,56.9-23,56.5"
            />
            <path
              id="femur_5"
              data-name="femur 5"
              class="Oberschenkel_mit_Knie_vd_right2344_cls3"
              d="M561.4,706c2.7-.3,29.1-.3,56,1"
            />
            <polyline
              id="femur_6"
              data-name="femur 6"
              class="Oberschenkel_mit_Knie_vd_right2344_cls3"
              points="466.4 724 478.5 720 493.9 715 515.4 719.5 534.4 715 534.4 724 534.4 746.1 540.9 760 531.4 774.4 529.2 793.8"
            />
            <path
              id="femur_7"
              data-name="femur 7"
              class="Oberschenkel_mit_Knie_vd_right2344_cls3"
              d="M545.4,540c0,50.4,19.7,91.3,44,91.3"
            />
            <path
              id="upper_leg_1"
              data-name="upper leg 1"
              class="Oberschenkel_mit_Knie_vd_right2344_cls4"
              d="M553.5,548.9c-1.5-4.3-2.8-8.7-4.2-13.1-10.8-35.1-19.8-70.9-28.9-106.5-4.5-17.7-9.1-35.4-14-53-5-17.7-8.5-35.5-11.8-53.5-6.6-35.6-12.3-71.3-17.9-107-5.7-36.3-11.1-72.6-17.1-108.8-1.5-8.9-3.4-17.8-5.1-26.8-1.6-8.6-1.8-17.7-3.7-26.3-1.8-8.2-4.2-16.2-4.6-24.7-.5-8.9-3.2-17.6-5.3-26.2"
            />
            <path
              id="upper_leg_2"
              data-name="upper leg 2"
              class="Oberschenkel_mit_Knie_vd_right2344_cls4"
              d="M405.7,539.8c-1.5-6.9.5-13.9-.5-20.9-4-23-10-45-12-68-2-32-1-64-5-96-12-119-30-234-46.5-351.1"
            />
            <polyline
              id="upper_leg_3"
              data-name="upper leg 3"
              class="Oberschenkel_mit_Knie_vd_right2344_cls1"
              points="397.7 480.7 406.9 431.1 401.8 391.5 356.4 10.8"
            />
            <polyline
              id="upper_leg_4"
              data-name="upper leg 4"
              class="Oberschenkel_mit_Knie_vd_right2344_cls1"
              points="534.8 522 525.6 466.7 503.1 393.9 432.4 10.8"
            />
          </g>
        </g>
        <g id="overlays">
          <path
            id="patella_overlays"
            data-name="patella overlays"
            class="Patella2344Fill"
            style={{
              fill: props.colors.Patella2344Fill,
              stroke: props.colors.Patella2344Fill,
            }}
            d="M487.4,746c7.5.7,8.5-.7,37-6,22.2-4.1,23.2-3.5,28-6,14.2-7.3,21-20.4,25-28,5.1-9.8,11.4-21.9,9-37-3.5-22.1-22.7-34.3-27-37-5.1-3.2-4.7-2-28-11-25.1-9.8-25.4-11.2-32-12-20-2.4-36.3,8.5-40,11-20.9,14-26.6,35.4-28,41-1.5,6.1-4.7,22.3,2,41,1.9,5.3,7.2,19.6,22,31,14.5,11.1,29.3,12.7,32,13h0Z"
          />
          <g id="femurcondylen">
            <path
              id="femurcondylen_1"
              data-name="femurcondylen 1"
              class="Femurkondylen2344Fill"
              style={{
                fill: props.colors.Femurkondylen2344Fill,
                stroke: props.colors.Femurkondylen2344Fill,
              }}
              d="M442.2,802.1c-28.2,11-53.6,15.6-74.1,7.6-8.9-3.4-15.5-12-18.2-21.1-3.6-12.2-2-27.2,4.2-44.5,3.7-14.1,10.1-25.5,21.3-32.2,16.3-5.5,30.9-4.3,44.5,1.4,2.3,1,4.6,1.8,6.9,2.6,13,4.3,22.9,13.6,31.4,25.5,2.1,3,3.2,6.5,3.1,10.2-.8,26.3-6.5,44.1-19,50.5h0Z"
            />
            <path
              id="femurcondylen_2"
              data-name="femurcondylen 2"
              class="Femurkondylen2344Fill"
              style={{
                fill: props.colors.Femurkondylen2344Fill,
                stroke: props.colors.Femurkondylen2344Fill,
              }}
              d="M617.4,756c-4.9,10.8-5.7,20.7-3.2,30,3.7,14.3,2,26.5-11.3,34.4-19.6,8-38.6,1.3-57.5-12.8l-16.2-13.8,2.2-19.4,9.5-14.4c.7-8.1,4.1-14.2,9.1-19,8.9-8.7,21.7-11.9,34-9.7,14.4,2.6,25.6,10.8,33.4,24.8h0Z"
            />
          </g>
          <path
            id="upper_leg_shaft_overlay"
            data-name="upper leg shaft overlay"
            class="Oberschenkelschaft2344Fill"
            style={{
              fill: props.colors.Oberschenkelschaft2344Fill,
              stroke: props.colors.Oberschenkelschaft2344Fill,
            }}
            d="M549.3,535.8l-143.9,4.2c-6.1-44.5-12.1-88.7-14.3-119.6-2.8-87.8-20.4-230.8-49.4-416.6l99.2-.8c33.3,196.6,60.7,384.3,108.4,532.8h0Z"
          />
        </g>
      </g>
      <g id="fullkneehighlight">
        <path
          id="fullkneehighlight1"
          class="Kniegelenk2344Fill"
          style={{
            fill: props.colors.Kniegelenk2344Fill,
            stroke: props.colors.Kniegelenk2344Fill,
          }}
          d="M280.1,770.1c0,84.3,105.4,132.6,194.3,132.6s212.7-54.3,212.7-138.6-114.7-178.4-203.5-178.4-203.5,100.1-203.5,184.4h0Z"
        />
      </g>
      <g id="weichteile">
        <path
          id="weichteile2"
          class="Oberschenkel_mit_Knie_vd_right2344_cls2"
          d="M764.3,0c-16,144.8-5.8,369.6-53.3,596.5,9.5,67.6,2.3,168.4-42.3,249.6,22.2,120.5-.9,165.6-9.4,240.3"
        />
        <path
          id="weichteile1"
          class="Oberschenkel_mit_Knie_vd_right2344_cls2"
          d="M142.5,0c51.9,285.9,146.6,452.7,160,544.6,9.7,51.8,7.4,139.7-21,202.9-23.2,45.1-22.5,68.6-24.2,77-15.6,78.4,19.6,204.4,24.5,271.5"
        />
      </g>
      <g id="Weichteilhighlight">
        <path
          id="Weichteilhighlight2"
          class="Weichteilmantel2344Fill"
          style={{
            fill: props.colors.Weichteilmantel2344Fill,
            stroke: props.colors.Weichteilmantel2344Fill,
          }}
          d="M659.4,1088.2c8.5-74.7,31.6-121.7,9.4-242.2,44.6-81.2,51.8-181.9,42.3-249.6,47.4-226.8,37.2-451.6,53.2-596.4h7.2c-16,144.8-5.8,369.6-53.3,596.5,9.5,67.6,2.3,168.4-42.3,249.6,22.2,120.5-.9,167.3-9.3,242l-7.3.2h0Z"
        />
        <path
          id="Weichteilhighlight1"
          class="Weichteilmantel2344Fill"
          style={{
            fill: props.colors.Weichteilmantel2344Fill,
            stroke: props.colors.Weichteilmantel2344Fill,
          }}
          d="M272.5,1086.3c-4.8-67.1-35.4-185.6-22.1-265.9,1.4-8.5.3-24.6,23.5-69.8,28.4-63.1,29.5-155.7,19.8-207.5C280.2,451.2,187.5,285.9,135.6,0h6.9c51.9,285.9,146.6,452.7,160,544.6,9.7,51.8,7.4,139.7-21,202.9-23.2,45.1-22.5,68.6-24.2,77-15.6,78.4,19,196.6,23.8,263.7l-8.7-1.9h.1Z"
        />
      </g>
    </svg>
  );
}
