import { useState } from "react";
import MainImage from "./MainImage";
import FootView from "./FootView";
import TopView from "./TopView";
import RechteSchoulderFocus from "./RechteSchoulderFocus";
import { Button } from "react-bootstrap";

export const MAIN_IMAGE = "MAIN_IMAGE";
export const FOOT_VIEW = "FOOT_VIEW";
export const TOP_VIEW = "TOP_VIEW";
export const RECHTE_SCHOULDER_FOCUS = "RECHTE_SCHOULDER_FOCUS";

export const VIEWS_MAP = {
  MAIN_IMAGE: {
    label: "Main View",
    Image: MainImage,
    isMainView: true,
  },
  FOOT_VIEW: {
    label: "Foot View",
    Image: FootView,
    isMainView: false,
  },
  TOP_VIEW: {
    label: "Top View",
    Image: TopView,
    isMainView: false,
  },
  RECHTE_SCHOULDER_FOCUS: {
    label: "Right Schoulder Focus",
    Image: RechteSchoulderFocus,
    isMainView: false,
  },
};

function Controller() {
  const [activeViewKey, setActiveViewKey] = useState(MAIN_IMAGE);
  const { label, Image, isMainView } = VIEWS_MAP[activeViewKey];
  return (
    <div className="d-flex flex-column">
      {!isMainView && (
        <Button onClick={() => setActiveViewKey(MAIN_IMAGE)}>
          <i className="fas fa-chevron-left" />
        </Button>
      )}
      <Image viewKeySetter={setActiveViewKey} />
    </div>
  );
}

export default Controller;
