import style from "./style.scss";

export default function LinkeRippenDvStehend1807(props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.89 578.69">

    <path id="whitebackground" class="linke_rippen_dv_stehend_links1807_cls1" d="M.39,46.16c-2,0,4.3,443.7,7.1,442.9,13.8.6,21.1,1,29.2-12.5,5.4,7.9,17,7.7,21,11.2,8.6,8.2,16.4,18.6,25,23.6,11.1,6.4,25.3,7.3,35.9,14.4,8.1,5.4,13.9,13.1,20.2,20.2,10.3,11.7,25.4,20.7,33.5,27.4l11.8-5.3c12.7-5.8,17.3-16.3,18.6-28.5,7.8,1.6,12-12,19-25.8,5-9.9,5.5-20.9,2.9-31.2,14.1-19.8,17.2-39.4,8.5-58.8,14.3-16.1,12.9-35.2,3.8-57.7,11.3-16.4,10.2-33.9-6.2-52.9,5.2-11.8,1.5-26.5-10-41.6,12-13.1,11.1-35.1-8-57,8.3-13.8-2.4-33.4-18.9-50.2l-2.6-11.2c2.8-3.8,4.8-8.1,5.1-13.3l-.8-7.2c-.4-1.3,1.5-2,4.3-2.4,2.3,3.6,4.5,6.3,6.6,6.9,11.5-5,22.8-5.6,34.1-2.5l2.5-71.9c-3.8-18-7.8-35.7-20.5-40l-24.2-3.6-8.8-1.4c-5.4,5.7-14.9,7.2-29.9,11.5-14.2,4.1-35.6,9.9-38.2,9.6-9,.3-24.8,9.1-29.7,14.5-1.2,1.4-4.8-9.4-14.8-10-12,3.1-23.2,3.5-33.1.1-.1-5.4-3.2-8.9-12.5-8.6"/>
    <g id="highlightesrips" class="linke_rippen_dv_stehend_links1807_cls8">
      <path id="_12thriphighlight" data-name="12thriphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M38.59,479.76c3.5,5.4,9.9,2.1,19,8,6,3.9,4.6,6.2,14,15,3.7,3.5,7.4,6.9,13,10,7,3.9,10,3.3,20,7,5.1,1.9,11.1,4.1,17,8,9.2,6.1,7.7,9.2,21,22,2.9,2.8.5.3,18,15,8.1,6.8,10.3,8.8,12,8,3.1-1.5,2.6-12.5-2-21-3.4-6.2-7.2-8-21-18-1.4-1-12.1-8.9-22-17-16.7-13.8-14.4-14.8-23-20-9.8-6-11.5-3.9-29-13-7.6-4-11.5-6-16-9-11.3-7.5-14.5-12.3-18-11-4.1,1.5-6.3,10.7-3,16Z"/>
      <path id="_11thriphighlight" data-name="11thriphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M37.59,443.76c2.3,2.4,5.8-.3,13,0,7.3.3,9.8,3.2,23,9,10.6,4.7,8.9,2.8,18,7,6.2,2.8,8.4,4.3,26,14,6.6,3.6,8.9,4.8,11,6,39.7,21.8,59.5,32.7,61,48,.9,9.3-4.3,26.7-5,29-1.7,5.8-3.5,9.9-2,11,2.1,1.6,10.4-4.1,15-11,9.6-14.3,3.2-33.6,2-37-4.5-12.7-14.3-21.1-34-38-13.7-11.7-20.5-17.6-31-23-7.1-3.7-18.7-7.5-42-15-12.3-4-18.5-6-23-7-6.4-1.4-12.7-3.1-19-5-4.9-1.5-7.5-2.4-10-1-4.2,2.4-5.7,10.1-3,13Z"/>
      <path id="connectiontolowerripshighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M11.59,338.76c-1.2,6.1,6.7,10.2,21,24,6.3,6,16.7,16.2,26,30,8.2,12.2,6,13.2,13,22,8.7,11,13.5,11.4,27,24,11.4,10.7,18.7,20.5,22,25,10.6,14.5,11,19.6,21,34,8.3,11.9,15.7,19.5,22,26,14.1,14.6,27,21,32,24,3.7,2.2,4.7-10.2,2-16-2.5-5.2-7.9-3.6-17-9-6.9-4.1-15.5-11.8-14-16,1.4-3.8,10.6-3.9,15-4,11.1-.2,14.8,3.5,18,1,5.2-4,3.1-19.7-4-23-3.8-1.8-7.2,1-15,3-8,2.1-16.3,3.8-24,1-8.9-3.3-15.9-12.6-14-16,2.8-5.1,23.9,7.9,34-1,7.5-6.5,6.6-22.8,1-26-2.9-1.7-5.3,1.1-13,2-5.7.6-10.4-.6-20-3-12.7-3.2-19.1-4.9-23-10-3.1-4-5-10-3-12s6.7,1.4,16,4c8.9,2.5,19.1,5.4,24,1,6.7-6.1,2.1-25-5-27-3.5-1-5.2,2.8-13,5-6.1,1.7-10.9,1-18,0-10.8-1.5-17.9-2.5-24-8-6.4-5.8-10.9-15.6-8-19,1.8-2.1,5.6-.8,20,2,20.9,4,23.4,3.2,25,2,7.7-5.7,5.3-25.8-1-28-3.1-1.1-4.9,3-12,5-6.9,1.9-9.3-.9-23-3-14.7-2.2-18.1,0-24-4-4.3-3-4.4-5.4-9-12-8-11.4-23-25.4-31-22-3.5,1.5-5.7,6.3-5,10,1.1,5.8,8.8,6,17,13,9.4,8.1,14,20.3,12,22-2.1,1.8-12.9-6.2-20-13-9.9-9.4-12.1-16.1-18-16-4.3,0-9.2,3.8-10,8Z"/>
      <path id="_10thriphighlight" data-name="10thriphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M36.69,381.76c4.7-3,13,2.5,18.1,5.6,37.9,23.2,55,10.2,111.8,41.4,13.7,7.5,24.6,14.3,36,25,12,11.2,20.5,19.5,23,33,2.7,14.9-3.6,27.3-8,36-3.6,7.2-8.9,17.6-14.9,16.9-5.8-.7-11.2-11.9-8.1-17.9,2.7-5.3,9.9-2.7,16-9,7-7.3,5.2-19.1,5-20-1-5.9-4.3-10.9-19-23-15.4-12.7-23.3-19.2-36.9-25.9-14.8-7.3-14.9-4.3-38.1-13.1-33.8-12.7-36.2-22.2-54.2-22.2-14.4,0-21.2,5.7-27.9.5-8.2-6.4-8.8-23.4-2.8-27.3h0Z"/>
      <path id="_8thriphighlight" data-name="8thriphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M199.59,503.76c-3.8,8.2,23.6-19.1,25-21.1,5-7.3,12.1-17.9,13-32.9,1.4-25-15.7-42.6-19-46-11-11.4-24-15.9-50-25-11.6-4.1-19.7-6.1-28.5-8.5,0,0-8.9-2.4-110-34,0,0-15.6,12.9-13.4,18.5,1.5,4,10.6,1.3,25,4,14.3,2.6,20.2,6,36,11,17.2,5.5,14.6,2.4,33,7,13,3.2,11.9,5.3,36,15,27,10.9,30.8,9.3,46,18,21.6,12.3,26.6,22.7,28,26,1.9,4.4,6.4,14.9,2,24-5.8,12.1-34.5,9.9-27,21,6.1,9.2,7.1,16,3.9,23h0Z"/>
      <path id="_6thriphighlight" data-name="6thriphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M134.59,350.76c-.1,2.3,3.7,3.5,5.3,9,1.3,4.5-.4,6.4.5,7.9,3.6,5.5,37.4-6.6,68.2-30.8,20.7-16.3,23.2-24.8,24-29,2.9-15.9-7.4-30.1-11-35-7.3-10-15.5-14.9-26-21-17.5-10.2-32.4-14.4-56-21-16.1-4.5-11.7-2.4-38-9-36.5-9.2-49.5-14.3-58-7-3.9,3.4-6.7,9.1-5,12,2.2,3.9,10.8.6,24,1,5.3.2,8.3.8,33,8,19.2,5.6,28.8,8.4,34,10,42.8,13.5,31.4,12.9,56,20,9.1,2.6,19.1,5.1,27,14,2.4,2.7,7.3,8.3,7.1,15.5-.1,5.4-3.1,10.5-12.1,18.5s-22.4,20-46,27c-13,3.8-26.8,5.5-27,9.9h0Z"/>
      <path id="connectionto5highlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M48.59,283.76c2.9-1,5.5,4.8,12,10,10.4,8.4,23.5,9.5,30,10,12.3,1,15-2.2,20,1,8.9,5.7,11.1,23.1,5,28-.7.6-2.3,1.5-15,0-11-1.3-16.4-2-21-4-9.8-4.3-8-8.6-17-13-12.5-6.1-23.2-1.4-25-6-1.2-3,3.4-5,7-15,2.4-6.8,1.9-10.3,4-11Z"/>
      <path id="connectionto4highlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M44.59,237.76c-3.8,1.6-5.4,5.6-6,7-2,4.9-1.5,11.3,2,15,2.8,3,6,2.7,13,4,4.9.9,4.7-1.4,19,6,5.8,3,28.5,14.7,37,8,6.2-4.9,5.5-19.9-1-25-3.3-2.6-6.3-1.1-18-2-4.9-.4-11.8-.9-19-3-15.3-4.5-20.2-12.8-27-10Z"/>
      <path id="connectionto3highlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M39.59,196.76c2.4-2,4.7-1.6,16-1,13.9.8,16.2.6,19,3,4.2,3.6,4.9,8.9,5,10,.2,1.2,1.1,8.4-3,12-4.2,3.7-9-.7-22,0-9.6.5-12.5,3.2-15.8.7-4.6-3.5-3.5-12.4-3.2-14.7.5-3.4,1-7.5,4-10Z"/>
      <path id="connectionto2highlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M30.59,152.76c1.9-2,4-1.1,15-1,14.3.1,16.4-1.4,20,1,6.4,4.1,9.2,14.4,6,18-2.4,2.7-6.3-.4-17,0-11.5.5-14.4,4.4-19,2-6.5-3.4-9.2-15.7-5-20Z"/>
      <path id="connectionto1highlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M49.59,106.76c.8-1.7,3.5-1,8-2,6.4-1.5,7.1-4.3,10-4,6.2.8,11.8,15,8,19-2.1,2.2-5.4-.7-13,1-7.1,1.5-10.5,5.3-12,4-1.6-1.5,2.7-5.9,1-12-.9-3.2-2.7-4.5-2-6Z"/>
      <path id="_5thriphighlight" data-name="5thriphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M115.59,332.76c2.1,2.8,16.8-4.6,27-10,25.6-13.4,38.4-20.1,48-27,22.7-16.3,34.1-24.4,37-37,4.8-20.4-10.3-38.8-13-42-4.8-5.6-11.5-11.3-44-23-27.3-9.8-48-15.3-61-18-5.9-1.2-16.6-2.5-38-5-18-2.1-26.5-2.8-32.1,2.7-4.3,4.2-6.3,11.5-3.9,14.3,2.6,3,10.4-.4,16-2,11.3-3.3,23.7-.6,48,5,40.2,9.3,34.6,14.1,67,21,19.8,4.2,34.4,5.1,42,17,.9,1.4,4.8,7.8,4,16-1.1,11.4-10.7,18.5-24,28-17.7,12.6-30.6,21.9-50,25-9.1,1.5-31.7,6.1-24.7,6.1,0,0,6.8,9.9,5.1,18.8-1.1,6.2-4.4,8.7-3.4,10.1Z"/>
      <path id="_8thriphighlight-2" data-name="8thriphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M181.69,457.66c2,2.2,13.4-3.7,26.9-13.9,19.2-14.5,28.8-21.7,33-34,6.6-19-1.3-36.5-3-40-7.2-15.1-19.5-22.4-29-28-15.9-9.4-19.9-6.5-43-16-28.3-11.6-48.6-26.3-52.7-21.9-2,2.1,3.6,10.3,5.1,18.8.6,3.4-4.5,10.1-3.4,10,7.4-.8,18.5,5.1,36.1,10.6,9,2.8,15.6,5.4,20.9,7.4,5.1,2,15.8,6.2,29,13,16.1,8.2,24.2,12.5,27,19,4.4,10,0,20.6-1,23-3.8,9.1-10.6,14.1-15.9,17.9-16.6,11.8-34.1,12.4-34.1,18.2,0,2.6,3.5,3.6,4.3,9,.5,4-1.1,5.9-.2,6.9h0Z"/>
      <path id="_7thriphighlight" data-name="7thriphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M161.39,418.46c2.8,2.6,12.9-4.2,24.9-12.1,32.4-21.4,34.4-22.3,39.6-27.8,9.4-9.9,15.9-16.7,17.7-27.9,3.1-19.4-10.7-35-12.9-37.4-5.5-6.2-13.4-10-29.1-17.6-30.9-14.9-30.5-8.4-48-19-6.6-4-13.7-9.2-21-7-4.1,1.2-6,4.1-13,6-6.2,1.6-9.6.7-10,2-1,3.2,17.8,11.5,19,12,10.5,4.6,16.6,5.9,26,9,12.2,4,10.4,4.5,30,12,26.9,10.4,33.7,10.7,39,19,1,1.5,6.2,9.9,4,19-1.5,6.2-6.8,10.7-17.3,19.5-7.5,6.3-13.7,10.2-15.7,11.5-8.6,5.4-13.5,8.5-21,11-8.1,2.7-20,6-20,6-1.2,2.9,7.1,4.6,7.7,11.9.4,6.5-1.2,8.7.1,9.9h0Z"/>
      <path id="_4thriphighlight" data-name="4thriphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M110.29,277.16c1.5,2,9.1-1.1,22.4-7.4,13.3-6.4,20-9.5,27-13.6,6.3-3.6,16.6-9.7,29-19.4,16-12.5,24-18.8,26-27,3.5-14.4-6.8-28.1-12-35-11.3-15-25.8-21.4-39-27-7.2-3.1-10.6-3.9-47-12-34.8-7.8-52.6-11.5-67-5-5.7,2.6-13.8,7.6-13,10,.7,2.3,9.1,1.2,18,1,10.3-.2,21.2,1.8,43,6,24.1,4.6,42,9.8,46,11,19.8,5.8,36.4,12.2,50,25,4.1,3.8,7.9,8.3,8,14,.1,7.4-6.1,13.1-12.4,18.8-2.6,2.3-7.8,6.7-20.6,13.2-5.4,2.8-12.6,6.4-23,10-22.2,7.7-36,7.2-37,13-.6,3.7,4.9,5.3,5.2,12.1.3,6.7-5,10.5-3.6,12.3h0Z"/>
      <path id="_3rdriphighlight" data-name="3rdriphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M76.59,220.76c2.3,4.1,18.6-1,34-6,23.8-7.8,35.7-11.6,45-16,22.5-10.5,33.8-15.8,37-26,4.9-15.6-8.4-32.1-10-34-7.8-9.7-19.3-15.3-29.3-20.2-3.1-1.5-5.3-2.4-8.7-3.8-7.6-3.2-23.9-9.5-48-15-11.1-2.5-16.6-3.8-21.8-4.2-16.5-1.3-38.8.4-40.2,7.2-.5,2.5,1.9,4.9,3,6,7.5,7.5,19.2,3.4,38,3,5.2-.1,21.5,0,40,5,1.5.4,11,4.3,30,12,14.5,5.9,19.1,7.9,24,13,2.4,2.6,11.2,11.8,9,21-.6,2.6-2.8,7.9-24,17-9.8,4.2-18.8,8-34,11-25.3,4.9-45.1,3-46,8-.4,2.2,3.5,3.1,4.7,8.2,1.6,6.8-4.2,11.1-2.7,13.8Z"/>
      <path id="_2ndtriphighlight" data-name="2ndtriphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M71.59,170.76c2.4,4,20.4-3.6,36-9,31.2-10.7,41.4-6.1,56-19,5.4-4.8,12.6-11.1,13-20,.7-15.9-20.4-28.4-35-37-12.3-7.3-22.4-10.5-40-16-14.7-4.6-22.1-6.9-28.9-5.5-10.5,2.2-15.5,8.7-27.1,7.5-4.3-.5-7-1.7-10,0-5.5,3.1-7.3,13.3-4,16,2.7,2.2,7.5-1.6,15-4,7.8-2.5,13.2-1.8,33,1,39.4,5.6,44.4,6.7,52,10,9.6,4.1,19.5,8.5,21,17,1.4,7.9-5.3,15.2-7,17-6.4,6.9-13.9,8.9-35,13-23.4,4.6-42.1,8.2-42.3,13.3-.1,1.8,2.3,2.5,3.3,6.7,1.2,4.9-1,7.4,0,9Z"/>
      <path id="_1striphighlight" data-name="1striphighlight" class="ribs1807Fill" style={{
              fill: props.colors.ribs1807Fill,
              stroke: props.colors.ribs1807Fill,
            }} d="M75.59,119.76c2.7,4.9,17.2,3.4,28-1,7.4-3,18.1-7.3,22-18,4.7-12.9-3.1-27.8-12-35-6.5-5.2-9.8-3.2-20-11-8.3-6.3-8.4-9.4-14-11-7-2-9.2,2.2-21,2-8.1-.1-8.2-2.1-16-2-4.5.1-17.4.2-20,7-1.5,3.9.5,9.7,4,11,4,1.6,7.3-3.9,15-7,10.7-4.4,21.5-.8,27,1,6.2,2,21.2,7,25,20,.5,1.7,3,11.1-2,17-6.9,8.2-23.2,4-24,8-.5,2.6,6.4,4.3,8,11,1,4.2-1.2,5.8,0,8Z"/>
      <path id="softpart" class="soft1807Fill" style={{
              fill: props.colors.soft1807Fill,
              stroke: props.colors.soft1807Fill,
            }} d="M263.52,575.8l-.63-11.39c-.27-6.86-.6-17.19-.63-29.75-.07-25.75,1.12-43.16,1.9-57.59,1.41-26.09,1.57-51.99,1.9-103.8.1-16.57.01-22.12.22-56.86.13-21.33.2-32.76.41-48.2.54-40.35,1.11-40.36,1.27-65.82.29-46.4-1.61-43.51,0-62.66,2.57-30.7,3.86-46.04,9.49-62.03,1.61-4.57,3.64-17.39,7.59-43.04.41-2.65,1.18-8.13-1.9-12.66-2.24-3.31-6.18-5.59-13.92-6.96-11.81-2.1-16.37.78-28.48,1.27-18.57.75-32.63-5-40.51-8.23-5.22-2.14-13.74-6.12-13.29-7.59.38-1.25,6.96.04,25.95,2.53,12.44,1.63,18.65,2.44,21.52,2.53,15.19.46,17.26-2.9,31.65-1.9,8.15.56,14.57,1.09,20.89,5.7,1.56,1.13,7.79,5.8,10.13,13.92,2.21,7.67-.43,13.64-3.16,22.78-3.08,10.31-4.09,21.12-6.33,31.65-5.96,28.08-9.15,42.08-10.13,59.49-.52,9.26.26,6.01.63,38.61.18,16.02.11,27.64,0,45.57-.16,25.32-.63,44.33-.63,55.7,0,0-.74,39.97-1.27,87.97-.1,8.7-.27,40.82-.63,105.06-.12,21.1-.99,42.2-.63,63.29.13,7.81-.72,15.61-.63,23.42.14,12.32.98,19.77-2.53,21.52-2.29,1.14-5.56-.6-8.23-2.53Z"/>
    </g>
    <g id="rips">
      <path id="_12thrip" data-name="12thrip" class="linke_rippen_dv_stehend_links1807_cls3" d="M38.49,479.56c3.5,5.4,9.9,2.1,19,8,6,3.9,4.6,6.2,14,15,3.7,3.5,7.4,6.9,13,10,7,3.9,10,3.3,20,7,5.1,1.9,11.1,4.1,17,8,9.2,6.1,7.7,9.2,21,22,2.9,2.8.5.3,18,15,8.1,6.8,10.3,8.8,12,8,3.1-1.5,2.6-12.5-2-21-3.4-6.2-7.2-8-21-18-1.4-1-12.1-8.9-22-17-16.7-13.8-14.4-14.8-23-20-9.8-6-11.5-3.9-29-13-7.6-4-11.5-6-16-9-11.3-7.5-14.5-12.3-18-11-4.1,1.5-6.3,10.7-3,16Z"/>
      <path id="_11thrip" data-name="11thrip" class="linke_rippen_dv_stehend_links1807_cls6" d="M37.49,443.56c2.3,2.4,5.8-.3,13,0,7.3.3,9.8,3.2,23,9,10.6,4.7,8.9,2.8,18,7,6.2,2.8,8.4,4.3,26,14,6.6,3.6,8.9,4.8,11,6,39.7,21.8,59.5,32.7,61,48,.9,9.3-4.3,26.7-5,29-1.7,5.8-3.5,9.9-2,11,2.1,1.6,10.4-4.1,15-11,9.6-14.3,3.2-33.6,2-37-4.5-12.7-14.3-21.1-34-38-13.7-11.7-20.5-17.6-31-23-7.1-3.7-18.7-7.5-42-15-12.3-4-18.5-6-23-7-6.4-1.4-12.7-3.1-19-5-4.9-1.5-7.5-2.4-10-1-4.2,2.4-5.7,10.1-3,13Z"/>
      <path id="connectiontolowerrips" class="linke_rippen_dv_stehend_links1807_cls3" d="M11.49,338.56c-1.2,6.1,6.7,10.2,21,24,6.3,6,16.7,16.2,26,30,8.2,12.2,6,13.2,13,22,8.7,11,13.5,11.4,27,24,11.4,10.7,18.7,20.5,22,25,10.6,14.5,11,19.6,21,34,8.3,11.9,15.7,19.5,22,26,14.1,14.6,27,21,32,24,3.7,2.2,4.7-10.2,2-16-2.5-5.2-7.9-3.6-17-9-6.9-4.1-15.5-11.8-14-16,1.4-3.8,10.6-3.9,15-4,11.1-.2,14.8,3.5,18,1,5.2-4,3.1-19.7-4-23-3.8-1.8-7.2,1-15,3-8,2.1-16.3,3.8-24,1-8.9-3.3-15.9-12.6-14-16,2.8-5.1,23.9,7.9,34-1,7.5-6.5,6.6-22.8,1-26-2.9-1.7-5.3,1.1-13,2-5.7.6-10.4-.6-20-3-12.7-3.2-19.1-4.9-23-10-3.1-4-5-10-3-12s6.7,1.4,16,4c8.9,2.5,19.1,5.4,24,1,6.7-6.1,2.1-25-5-27-3.5-1-5.2,2.8-13,5-6.1,1.7-10.9,1-18,0-10.8-1.5-17.9-2.5-24-8-6.4-5.8-10.9-15.6-8-19,1.8-2.1,5.6-.8,20,2,20.9,4,23.4,3.2,25,2,7.7-5.7,5.3-25.8-1-28-3.1-1.1-4.9,3-12,5-6.9,1.9-9.3-.9-23-3-14.7-2.2-18.1,0-24-4-4.3-3-4.4-5.4-9-12-8-11.4-23-25.4-31-22-3.5,1.5-5.7,6.3-5,10,1.1,5.8,8.8,6,17,13,9.4,8.1,14,20.3,12,22-2.1,1.8-12.9-6.2-20-13-9.9-9.4-12.1-16.1-18-16-4.3,0-9.2,3.8-10,8Z"/>
      <path id="_10thrip" data-name="10thrip" class="linke_rippen_dv_stehend_links1807_cls6" d="M36.59,381.56c4.7-3,13,2.5,18.1,5.6,37.9,23.2,55,10.2,111.8,41.4,13.7,7.5,24.6,14.3,36,25,12,11.2,20.5,19.5,23,33,4.7,16.5-3.2,27.7-8,36-3.6,7.2-8.9,17.6-14.9,16.9-5.8-.7-11.2-11.9-8.1-17.9,2.7-5.3,9.9-2.7,16-9,7-7.3,5.2-19.1,5-20-1-5.9-4.3-10.9-19-23-15.4-12.7-23.3-19.2-36.9-25.9-14.8-7.3-14.9-4.3-38.1-13.1-33.8-12.7-36.2-22.2-54.2-22.2-14.4,0-21.2,5.7-27.9.5-8.2-6.4-8.8-23.4-2.8-27.3h0Z"/>
      <path id="_8thrip" data-name="8thrip" class="linke_rippen_dv_stehend_links1807_cls6" d="M199.49,503.56c-3.8,8.2,23.6-19.1,25-21.1,5-7.3,12.1-17.9,13-32.9,1.4-25-15.7-42.6-19-46-11-11.4-24-15.9-50-25-11.6-4.1-19.7-6.1-28.5-8.5,0,0-8.9-2.4-110-34,0,0-15.6,12.9-13.4,18.5,1.5,4,10.6,1.3,25,4,14.3,2.6,20.2,6,36,11,17.2,5.5,14.6,2.4,33,7,13,3.2,11.9,5.3,36,15,27,10.9,30.8,9.3,46,18,21.6,12.3,26.6,22.7,28,26,1.9,4.4,6.4,14.9,2,24-5.8,12.1-34.5,9.9-27,21,6.1,9.2,7.1,16,3.9,23h0Z"/>
      <path id="_6thrip" data-name="6thrip" class="linke_rippen_dv_stehend_links1807_cls6" d="M134.49,350.56c-.1,2.3,3.7,3.5,5.3,9,1.3,4.5-.4,6.4.5,7.9,3.6,5.5,37.4-6.6,68.2-30.8,20.7-16.3,23.2-24.8,24-29,2.9-15.9-7.4-30.1-11-35-7.3-10-15.5-14.9-26-21-17.5-10.2-32.4-14.4-56-21-16.1-4.5-11.7-2.4-38-9-36.5-9.2-49.5-14.3-58-7-3.9,3.4-6.7,9.1-5,12,2.2,3.9,10.8.6,24,1,5.3.2,8.3.8,33,8,19.2,5.6,28.8,8.4,34,10,42.8,13.5,31.4,12.9,56,20,9.1,2.6,19.1,5.1,27,14,2.4,2.7,7.3,8.3,7.1,15.5-.1,5.4-3.1,10.5-12.1,18.5s-22.4,20-46,27c-13,3.8-26.8,5.5-27,9.9h0Z"/>
      <path id="connectionto5" class="linke_rippen_dv_stehend_links1807_cls3" d="M48.49,283.56c2.9-1,5.5,4.8,12,10,10.4,8.4,23.5,9.5,30,10,12.3,1,15-2.2,20,1,8.9,5.7,11.1,23.1,5,28-.7.6-2.3,1.5-15,0-11-1.3-16.4-2-21-4-9.8-4.3-8-8.6-17-13-12.5-6.1-23.2-1.4-25-6-1.2-3,3.4-5,7-15,2.4-6.8,1.9-10.3,4-11Z"/>
      <path id="connectionto4" class="linke_rippen_dv_stehend_links1807_cls3" d="M44.49,237.56c-3.8,1.6-5.4,5.6-6,7-2,4.9-1.5,11.3,2,15,2.8,3,6,2.7,13,4,4.9.9,4.7-1.4,19,6,5.8,3,28.5,14.7,37,8,6.2-4.9,5.5-19.9-1-25-3.3-2.6-6.3-1.1-18-2-4.9-.4-11.8-.9-19-3-15.3-4.5-20.2-12.8-27-10Z"/>
      <path id="connectionto3" class="linke_rippen_dv_stehend_links1807_cls3" d="M39.49,196.56c2.4-2,4.7-1.6,16-1,13.9.8,16.2.6,19,3,4.2,3.6,4.9,8.9,5,10,.2,1.2,1.1,8.4-3,12-4.2,3.7-9-.7-22,0-9.6.5-12.5,3.2-15.8.7-4.6-3.5-3.5-12.4-3.2-14.7.5-3.4,1-7.5,4-10Z"/>
      <path id="connectionto2" class="linke_rippen_dv_stehend_links1807_cls3" d="M30.49,152.56c1.9-2,4-1.1,15-1,14.3.1,16.4-1.4,20,1,6.4,4.1,9.2,14.4,6,18-2.4,2.7-6.3-.4-17,0-11.5.5-14.4,4.4-19,2-6.5-3.4-9.2-15.7-5-20Z"/>
      <path id="connectionto1" class="linke_rippen_dv_stehend_links1807_cls3" d="M49.49,106.56c.8-1.7,3.5-1,8-2,6.4-1.5,7.1-4.3,10-4,6.2.8,11.8,15,8,19-2.1,2.2-5.4-.7-13,1-7.1,1.5-10.5,5.3-12,4-1.6-1.5,2.7-5.9,1-12-.9-3.2-2.7-4.5-2-6Z"/>
      <path id="_5thrip" data-name="5thrip" class="linke_rippen_dv_stehend_links1807_cls6" d="M115.49,332.56c2.1,2.8,16.8-4.6,27-10,25.6-13.4,38.4-20.1,48-27,22.7-16.3,34.1-24.4,37-37,4.8-20.4-10.3-38.8-13-42-4.8-5.6-11.5-11.3-44-23-27.3-9.8-48-15.3-61-18-5.9-1.2-16.6-2.5-38-5-18-2.1-26.5-2.8-32.1,2.7-4.3,4.2-6.3,11.5-3.9,14.3,2.6,3,10.4-.4,16-2,11.3-3.3,23.7-.6,48,5,40.2,9.3,34.6,14.1,67,21,19.8,4.2,34.4,5.1,42,17,.9,1.4,4.8,7.8,4,16-1.1,11.4-10.7,18.5-24,28-17.7,12.6-30.6,21.9-50,25-9.1,1.5-31.7,6.1-24.7,6.1,0,0,6.8,9.9,5.1,18.8-1.1,6.2-4.4,8.7-3.4,10.1Z"/>
      <path id="_8thrip-2" data-name="8thrip" class="linke_rippen_dv_stehend_links1807_cls6" d="M181.59,457.46c2,2.2,13.4-3.7,26.9-13.9,19.2-14.5,28.8-21.7,33-34,6.6-19-1.3-36.5-3-40-7.2-15.1-19.5-22.4-29-28-15.9-9.4-19.9-6.5-43-16-28.3-11.6-48.6-26.3-52.7-21.9-2,2.1,3.6,10.3,5.1,18.8.6,3.4-4.5,10.1-3.4,10,7.4-.8,18.5,5.1,36.1,10.6,9,2.8,15.6,5.4,20.9,7.4,5.1,2,15.8,6.2,29,13,16.1,8.2,24.2,12.5,27,19,4.4,10,0,20.6-1,23-3.8,9.1-10.6,14.1-15.9,17.9-16.6,11.8-34.1,12.4-34.1,18.2,0,2.6,3.5,3.6,4.3,9,.5,4-1.1,5.9-.2,6.9h0Z"/>
      <path id="_7thrip" data-name="7thrip" class="linke_rippen_dv_stehend_links1807_cls6" d="M161.29,418.26c2.8,2.6,12.9-4.2,24.9-12.1,32.4-21.4,34.4-22.3,39.6-27.8,9.4-9.9,15.9-16.7,17.7-27.9,3.1-19.4-10.7-35-12.9-37.4-5.5-6.2-13.4-10-29.1-17.6-30.9-14.9-30.5-8.4-48-19-6.6-4-13.7-9.2-21-7-4.1,1.2-6,4.1-13,6-6.2,1.6-9.6.7-10,2-1,3.2,17.8,11.5,19,12,10.5,4.6,16.6,5.9,26,9,12.2,4,10.4,4.5,30,12,26.9,10.4,33.7,10.7,39,19,1,1.5,6.2,9.9,4,19-1.5,6.2-6.8,10.7-17.3,19.5-7.5,6.3-13.7,10.2-15.7,11.5-8.6,5.4-13.5,8.5-21,11-8.1,2.7-20,6-20,6-1.2,2.9,7.1,4.6,7.7,11.9.4,6.5-1.2,8.7.1,9.9h0Z"/>
      <path id="_4thrip" data-name="4thrip" class="linke_rippen_dv_stehend_links1807_cls6" d="M110.19,276.96c1.5,2,9.1-1.1,22.4-7.4,13.3-6.4,20-9.5,27-13.6,6.3-3.6,16.6-9.7,29-19.4,16-12.5,24-18.8,26-27,3.5-14.4-6.8-28.1-12-35-11.3-15-25.8-21.4-39-27-7.2-3.1-10.6-3.9-47-12-34.8-7.8-52.6-11.5-67-5-5.7,2.6-13.8,7.6-13,10,.7,2.3,9.1,1.2,18,1,10.3-.2,21.2,1.8,43,6,24.1,4.6,42,9.8,46,11,19.8,5.8,36.4,12.2,50,25,4.1,3.8,7.9,8.3,8,14,.1,7.4-6.1,13.1-12.4,18.8-2.6,2.3-7.8,6.7-20.6,13.2-5.4,2.8-12.6,6.4-23,10-22.2,7.7-36,7.2-37,13-.6,3.7,4.9,5.3,5.2,12.1.3,6.7-5,10.5-3.6,12.3h0Z"/>
      <path id="_3rdrip" data-name="3rdrip" class="linke_rippen_dv_stehend_links1807_cls6" d="M76.49,220.56c2.3,4.1,18.6-1,34-6,23.8-7.8,35.7-11.6,45-16,22.5-10.5,33.8-15.8,37-26,4.9-15.6-8.4-32.1-10-34-7.8-9.7-19.3-15.3-29.3-20.2-3.1-1.5-5.3-2.4-8.7-3.8-7.6-3.2-23.9-9.5-48-15-11.1-2.5-16.6-3.8-21.8-4.2-16.5-1.3-38.8.4-40.2,7.2-.5,2.5,1.9,4.9,3,6,7.5,7.5,19.2,3.4,38,3,5.2-.1,21.5,0,40,5,1.5.4,11,4.3,30,12,14.5,5.9,19.1,7.9,24,13,2.4,2.6,11.2,11.8,9,21-.6,2.6-2.8,7.9-24,17-9.8,4.2-18.8,8-34,11-25.3,4.9-45.1,3-46,8-.4,2.2,3.5,3.1,4.7,8.2,1.6,6.8-4.2,11.1-2.7,13.8Z"/>
      <path id="_2ndtrip" data-name="2ndtrip" class="linke_rippen_dv_stehend_links1807_cls6" d="M71.49,170.56c2.4,4,20.4-3.6,36-9,31.2-10.7,41.4-6.1,56-19,5.4-4.8,12.6-11.1,13-20,.7-15.9-20.4-28.4-35-37-12.3-7.3-22.4-10.5-40-16-14.7-4.6-22.1-6.9-28.9-5.5-10.5,2.2-15.5,8.7-27.1,7.5-4.3-.5-7-1.7-10,0-5.5,3.1-7.3,13.3-4,16,2.7,2.2,7.5-1.6,15-4,7.8-2.5,13.2-1.8,33,1,39.4,5.6,44.4,6.7,52,10,9.6,4.1,19.5,8.5,21,17,1.4,7.9-5.3,15.2-7,17-6.4,6.9-13.9,8.9-35,13-23.4,4.6-42.1,8.2-42.3,13.3-.1,1.8,2.3,2.5,3.3,6.7,1.2,4.9-1,7.4,0,9Z"/>
      <path id="_1strip" data-name="1strip" class="linke_rippen_dv_stehend_links1807_cls6" d="M75.49,119.56c2.7,4.9,17.2,3.4,28-1,7.4-3,18.1-7.3,22-18,4.7-12.9-3.1-27.8-12-35-6.5-5.2-9.8-3.2-20-11-8.3-6.3-8.4-9.4-14-11-7-2-9.2,2.2-21,2-8.1-.1-8.2-2.1-16-2-4.5.1-17.4.2-20,7-1.5,3.9.5,9.7,4,11,4,1.6,7.3-3.9,15-7,10.7-4.4,21.5-.8,27,1,6.2,2,21.2,7,25,20,.5,1.7,3,11.1-2,17-6.9,8.2-23.2,4-24,8-.5,2.6,6.4,4.3,8,11,1,4.2-1.2,5.8,0,8Z"/>
      <path id="soft" class="linke_rippen_dv_stehend_links1807_cls4" d="M268.58,568.2c.13-10.44.34-26.03.63-44.94.77-49.89,1.33-70.08,1.9-102.53.22-12.46.57-47.72,1.27-117.72.36-36.29,1.99-72.58,1.27-108.86-.85-42.8-2.77-55.26,1.9-80.38q10.11-54.37,10.13-54.43c5.41-23.42,9.18-33.61,3.16-41.14-6.75-8.46-21.94-8.67-35.44-8.86-11.7-.16-14.66,1.86-25.32,1.27-12.37-.69-22.21-4.2-28.48-6.96"/>
    </g>
    <g id="Sternum_and_Spine" data-name="Sternum and Spine">
      <path id="parttoclavicula" class="linke_rippen_dv_stehend_links1807_cls6" d="M32.79,34.56c6.1,0,11.1,3,10.8,7-.3,3.9-4.8,7-10.8,7s-10.8-3.1-10.8-7,4.8-7,10.8-7Z"/>
      <path id="partofspine7" class="linke_rippen_dv_stehend_links1807_cls6" d="M4.49,62.06c9.8-.2,17.6-2.6,17.6-5.6s-7.4-5.4-16.9-5.6"/>
      <path id="middlepartsternum" class="linke_rippen_dv_stehend_links1807_cls3" d="M3.89,334.56c7.6,0,16.6-3,22.6-8,1-2,4-3,5-5,8-16,25-34,12-53-1-1,0-3-1-4-3-5-6-10-6-15s4-10,3-15c-1-8-8-14-6-22,4-14,5-28-2-41-9-17,5-32,17-43,4-4,6-12,2-17-3-2-7,0-10-1-6-2-4-10-9-13-8-5-17,1-25.1-.5"/>
      <line id="partofspine6" class="linke_rippen_dv_stehend_links1807_cls3" x1="40.09" y1="86.06" x2="34.49" y2="102.56"/>
      <path id="partofspine5" class="linke_rippen_dv_stehend_links1807_cls3" d="M7.39,488.26c11.6,1.8,23.6,1.5,28.6-10.8,4.5-10.8,3.7-24.1-5.8-32.1-5.7-4.8-13.2-5-20.2-4.4"/>
      <path id="partofspine4" class="linke_rippen_dv_stehend_links1807_cls3" d="M10.09,424.56c9.4,1,15.4,9,24.4,9,9-6,7-20,0-27-7-6-15-11-25.2-11.3"/>
      <path id="partofspine3" class="linke_rippen_dv_stehend_links1807_cls3" d="M9.29,382.96c7.2,3.6,14.2,7.6,21.2,4.6,7-2,10-12,7-19-5-13-22-13-33.7-15.5"/>
      <line id="partofspine2" class="linke_rippen_dv_stehend_links1807_cls3" x1="34.49" y1="433.76" x2="36.49" y2="453.56"/>
      <line id="partofspine1" class="linke_rippen_dv_stehend_links1807_cls3" x1="31.79" y1="404.26" x2="30.49" y2="387.56"/>
      <path id="lastpartsternum" class="linke_rippen_dv_stehend_links1807_cls3" d="M3.69,371.86c.6.4,1.2.7,1.8.7,3.4,0,6-7.9,7-11,1.5-4.7.9-5.9,2.2-15.4,1.3-9.4,2.5-12.3.8-13.5-2-1.5-5,2-11.7,1.9"/>
      <path id="firstpartsternum" class="linke_rippen_dv_stehend_links1807_cls3" d="M6.39,86.66c5.4.1,10.9.3,16.3-.1,2.2-.2,4.5-.4,6.4-1.7,11.1-7.5,7.6-24.1-1.7-31.1-6.1-4.6-13.4-7.4-21.1-7.6"/>
    </g>
    <g id="Scapula_shoulder_clavicula" data-name="Scapula,shoulder,clavicula">
      <path id="upperarm" class="linke_rippen_dv_stehend_links1807_cls4" d="M240.29,134.36c-3-.8-6-1.4-9.8-1.6-3.4-.2-8.4-.5-14.4,1.2-9.1,1.2-8.5,3.4-9.9,2.9-2.7-1.1-3.3-3.2-8.2-10.7-5-7.7-5.4-6.6-7.4-10.7-.8-1.6-3.5-7.4-4.1-15.2-.3-3.4.1-6.5.8-12.8.6-5.1.9-7.8,2.1-10.3,1.4-3.2,1-4.8,4.3-8.3,3-3.2,5.1-4.1,7.8-5.7,2.6-1.5,3.1-1.8,10.5-4.2,8.3-2.7,10.2-2.3,12.4-2.4,4.1-.2,7-.2,12.7,2.9,1.3.7,3,1.7,5.1,3"/>
      <path id="scapula3" class="linke_rippen_dv_stehend_links1807_cls4" d="M163.49,107.66c.8-4.3,2.2-9.9,4.9-16.1,3.3-7.5,7-12.5,7.8-13.6,1.6-2.2,5.8-7.6,12.4-12.4,8.1-5.9,13.6-6.1,34.2-15.2,2.2-1,6.2-2.8,8.6-7,.8-1.4,2.9-5.5,1.6-10.3-1.1-4.5-4.4-6.9-6.2-8.2-4.2-3.1-8.6-3.7-11.5-4.1-4.9-.7-5.4.4-10.7-.4-5.2-.8-6.6-2.2-7.8-1.2-2.6,2,2.7,9-.4,14.8-.9,1.6-3.3,3-8.2,5.8-7.3,4.1-13.1,6.6-14,7-1.8.8-5.8,2.5-14,5.4-15.2,5.4-15.4,4.5-20.2,7-6.6,3.4-10.8,7.3-15.2,11.5-5.2,4.9-5,5.8-14.4,16.1-4.7,5.2-7.6,8.3-11.9,12.4-5.9,5.5-10.9,9.3-14.4,11.9-4.1,3.1-3.2,5.5-9.6,9.4"/>
      <path id="clavicula" class="linke_rippen_dv_stehend_links1807_cls4" d="M29.09,84.86c10-4.9,24.7-8.9,31-12.1,12.4-6.4,15.6-8.4,18.5-10.3,4.8-3.2,4.6-3.6,15.2-11.1,9.2-6.5,12.2-8.3,16.1-9.9,3.2-1.3,6.1-2,11.9-3.3,11.1-2.5,12-1.7,20.2-3.7,9.2-2.2,8.3-3.3,18.5-5.8,2.9-.7,5-1.1,6.9-1.6,1.7-.4,3.3-.9,5.4-1.7,5.8-2.3,9.9-4.5,9.9-4.5,4.8-2.6,5.6-3.7,7-3.3,3.4,1.1,5.3,10,1.6,14.8-1.5,1.9-3.4,2.6-5.4,3.3-8.8,3-16.1,3.7-18.9,4.1-1.8.3-2.8.5-22.2,7.8-13,4.9-19.4,7.3-20.6,7.8-9,3.8-21.9,10.4-36.7,22.6-17.5,10.4-32.6,19.2-50.3,29.5"/>
      <path id="scapula2" class="linke_rippen_dv_stehend_links1807_cls4" d="M150.29,85.86c3.8-3,7-3.7,9.1-3.7,1.5,0,3.2.4,6.6,1.2,5.9,1.5,6.6,2.2,9.1,2.1,1.4-.1,4.5-.2,6.6-2.5,3.1-3.3,1.8-8.4,1.6-9.1-.9-3.7-3.4-5.8-5.4-7.4-4.5-3.7-9.3-4.8-14-5.8-7.2-1.5-10.8-2.2-15.2-.8-8,2.5-11.9,9.6-13.2,11.9-1.4,2.5-4.6,8.4-3.3,15.2,1.2,5.9,5,8.4,3.7,10.7-.3.6-1.4,1.9-11.1,1.6-5.7-.1-9.4-.6-15.2-1.2-11.2-1.2-9.9-.5-13.6-1.2-7.2-1.4-12.7-3.9-16.9-5.8-1.5-.7-1.8-.9-13.6-6.6-5.1-2.5-7.6-3.7-9.5-3.3-5.2,1.1-7.6,8.5-9.1,12.8-1.7,5.2-1.9,9.4-2.1,15.6-.1,5.2-.3,10.4.8,16.5.8,4.5,2.2,8.8,8.6,21.4,8.2,16,9.5,15.9,13.2,24.3,3.6,8.1,3,9.6,7.8,25.5,5.7,18.8,6.6,17.1,11.1,32.5,5.6,18.9,3.6,19.4,9.1,35.8,2.7,8.2,4.5,12,6.2,20.2,1.1,5.4,1.3,8.9,3.3,16.1,2.5,9.3,4.6,12.3,7,14.4,3,2.7,6.5,3.7,8.6,4.1"/>
      <path id="scapula1" class="linke_rippen_dv_stehend_links1807_cls4" d="M118.59,320.66c1.2,0,7.1.7,9.1,0,1.7-.6,5.1-2.1,9.5-8.2,2.7-3.7,3.1-5.5,5.8-10.7,3.9-7.5,5.4-8.4,7.4-12.4,3.7-7.3,2.4-12,3.3-22.2.6-6.7,2.8-14.8,7.4-30.9,4-14.3,4.3-12.6,8.6-27.6,5.2-18.3,4.6-20.2,8.6-30.1,3.4-8.3,5.5-13.5,9.5-20.2,4.9-8.3,9.5-13.7,8.2-20.2-.3-1.7-1.1-3.8,0-5.8,1.4-2.5,4.2-1.8,7.4-4.5.3-.2,3.3-2.9,4.1-7,.8-3.8-.8-7-2.5-10.3-2.4-4.6-4.2-5.9-7.8-10.7-4.8-6.4-3.4-6.6-8.2-13.2-6.8-9.3-11.5-11.4-12.8-11.9-2.9-1.2-5.6-1.6-7.4-1.6"/>
      <path id="borderjointsocket" class="linke_rippen_dv_stehend_links1807_cls4" d="M195.99,132.36c-12.2-3.3-29.7-37-15.3-54.8"/>
    </g>
  </svg>
  );
}
