import { useExaminations } from "../../Utilities/FetchHooks/Ris/RisHooks";

import ExamTableRow from "./ExamTableRow";

import "./stylesheets/BodyPartExams.scss";

function BodyPartExams({ selectedBodyPart, dateRange }) {
  const examsQ = useExaminations({
    filter: { field: "anatomy_id", value: selectedBodyPart?.id, op: "eq" },
    enabled: selectedBodyPart !== undefined,
  });
  const { status, data } = examsQ;
  return (
    <>
      {status === "success" && (
        <div style={{ overflowY: "auto", height: "82vh" }}>
          <div className="bpart-exam-grid bold-items">
            <div>Exam</div>
            <div>kV from</div>
            <div>kV till</div>
            <div>mAs from</div>
            <div>mAs till</div>
            <div>target dose</div>
            <div>exams count</div>
            <div>avg kV</div>
            <div>avg mAs</div>
            <div>avg uGy</div>
          </div>
          {data?.examinations?.map((exam) => (
            <ExamTableRow key={exam.id} exam={exam} dateRange={dateRange} />
          ))}
        </div>
      )}
      {status === "loading" && <div>Loading ... </div>}
    </>
  );
}

export default BodyPartExams;
