import style from "./style.scss";

export default function RechtesKniegelenkStressaufnahmeVdLiegend2356(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319.24 643.55">
      <g id="underlay">
        <ellipse
          id="joint_gap_underlay"
          data-name="joint gap underlay"
          class="Gelenkspalt2356Fill"
          style={{
            fill: props.colors.Gelenkspalt2356Fill,
            stroke: props.colors.Gelenkspalt2356Fill,
          }}
          cx="168.51"
          cy="294.11"
          rx="47.85"
          ry="135.72"
          transform="translate(-134.5 446.03) rotate(-86.82)"
        />
      </g>
      <g id="knee_stress" data-name="knee stress">
        <path
          id="background_lower_leg"
          data-name="background lower leg"
          class="knie_stress_vd_rechts2356_cls7"
          d="M230.42,627.26c-4.44,11.61-25.9,13.21-54,15-32,2.04-48.16,2.85-56-7-5.2-6.53-3-12.35-3-34,0-16.4-2-31.93-6-63-3.79-29.45-7.45-43.93-12-57-3.89-11.16-9.92-25.58-12-25-1.83.51-.25,12.53,1,22,2.46,18.66,4.92,22.01,3,30-1.81,7.55-4.45,6.37-9,17-5.8,13.55-5.98,25.84-6,31-.06,12.64,2.82,11.32,3,26,.17,13.76-2.34,16.84,0,26,2.7,10.57,7.65,12.78,6,17-3.3,8.44-27.37,10.61-33,2-1.81-2.76-1.27-5.94-1-8,.68-5.26-1.88-16.51-7-39-5.9-25.93-16.91-52.29-21-62-10.5-24.93-16.81-34.07-20-54-2.77-17.31-.49-25.79,1-30,3.3-9.34,7.11-10.2,10-21,2.45-9.13.63-11.9,4-23,3.84-12.64,7.85-14.54,9-15,5.22-2.09,9.63,2.95,12,1,3.07-2.53-3.66-11.13-5-24-1.83-17.58,7.66-31.14,9-33,2.33-3.23,7.9-11.01,18-15,11.52-4.55,22.99-1.84,35,1,14.24,3.36,18.44,7.41,27,5,7.1-2,13.22-7.32,14-8,7.42-6.47,6.82-10.33,12-12,5.41-1.74,11.59.67,15,2,5.39,2.1,6.28,4.09,10,4,3.19-.08,4.08-1.59,10-4,8.06-3.28,9.22-3.29,10-3,3.06,1.14,2.71,4.78,6,9,.87,1.11,3.33,3.98,11,7,9.3,3.66,18.21,4.36,27,5,0,0,15.22,1.1,41,5,3.22.49,8.71,1.39,13.42,5.57.85.75,3.92,3.61,6.58,10.43,1.92,4.92,3.43,11.43,3,21,0,0-.81,20.6-16,47-5.76,10.02-4.23,4.94-26,36,0,0-15.53,21.97-21,42-3.19,11.7-.23,9.44-4,32-3.82,22.85-7,27.05-7,42,0,12.52,2.23,11.35,2,25-.24,14.66-3.34,18.54-2,32,1.22,12.18,3.79,14.32,2,19h0Z"
        />
        <g id="fibula">
          <path
            id="fibula_1"
            data-name="fibula 1"
            class="knie_stress_vd_rechts2356_cls3"
            d="M84.42,622.26c-4.78-16-6.44-29.88-7-40-1.05-19.07-2.01-36.37,6-55,3.26-7.59,6.42-11.7,8-21,1.73-10.21-.3-15.69-2-28-3.9-28.27,2.49-30.77-3-50-1.78-6.22-4.73-16.2-13-26-4.5-5.33-9.67-8.89-20-16-18.16-12.5-22.46-11.51-24-11-4.6,1.52-6.82,6.14-10,13-4.87,10.5-4.18,15.74-7,27-4.35,17.38-9.11,17.38-11,30-.46,3.08-1.41,11.33,3,28,5.49,20.76,12.33,28.22,21,47,8.44,18.28,11.9,32.7,17,54,3.4,14.21,6.87,32.1,9,53"
          />
          <path
            id="fibula_2"
            data-name="fibula 2"
            class="knie_stress_vd_rechts2356_cls1"
            d="M89.42,478.26c-4.83,0-8.74,25.22-8.74,56.39"
          />
          <path
            id="fibula_3"
            data-name="fibula 3"
            class="knie_stress_vd_rechts2356_cls1"
            d="M19.42,487.26c1.47.35,4.17-21.18-7-49"
          />
          <path
            id="fibula_4"
            data-name="fibula 4"
            class="knie_stress_vd_rechts2356_cls1"
            d="M73.42,619.26c-4.8-6.8-12.74-53.27,7.26-84.61"
          />
          <path
            id="fibula_5"
            data-name="fibula 5"
            class="knie_stress_vd_rechts2356_cls1"
            d="M65.42,627.26c-1.71-3.34-12.04-39.59-27.25-80"
          />
        </g>
        <g id="tibia">
          <path
            id="tibia_1"
            data-name="tibia 1"
            class="knie_stress_vd_rechts2356_cls3"
            d="M119.42,634.26c-1.03-29.1-3.06-53.48-5-72-2.13-20.36-4.18-34.02-7-48-3.08-15.28-5.23-25.95-10-38-7.6-19.22-15.22-25.21-28-44-13.93-20.48-39.33-57.82-33-96,2.81-16.93,10.1-23.42,12-25,11.37-9.45,27.9-7.87,37-7,20.99,2.01,26.07,10.5,38,6,5.58-2.1,12.68-7.06,18-12,6.6-6.13,6.92-8.95,11-10,8.39-2.15,12.38,8.42,23,8,9.37-.37,12.36-8.84,19-7,5.44,1.51,4.65,7.53,11,13,5.67,4.88,10.85,4.02,28,6,10.36,1.2,17.24,2.53,30,5,17.19,3.33,25.87,5.07,30,9,8.88,8.46,8.42,22.02,8,34-.14,4.01-1.13,24.21-15,46-6.4,10.06-8.27,9.02-18,22-3.85,5.14-14.73,20.21-23,40-7.58,18.15-9.59,32.17-13,56-3.38,23.65-3.78,41.91-4,54-.4,21.76.67,40.45,2,55"
          />
          <path
            id="tibia_2"
            data-name="tibia 2"
            class="knie_stress_vd_rechts2356_cls3"
            d="M287.42,324.26c-44.92,7.36-81.7-4.33-82-22"
          />
          <path
            id="tibia_3"
            data-name="tibia 3"
            class="knie_stress_vd_rechts2356_cls3"
            d="M42.68,318.15c44.05,15.48,80.32,7.73,80.74-7.89"
          />
          <path
            id="tibia_4"
            data-name="tibia 4"
            class="knie_stress_vd_rechts2356_cls1"
            d="M245.42,464.26c-16.03,0-29,76.04-29,170"
          />
          <path
            id="tibia_5"
            data-name="tibia 5"
            class="knie_stress_vd_rechts2356_cls1"
            d="M272.42,371.87c-26.91-4.91-51.55-6.63-60,0"
          />
          <path
            id="tibia_6"
            data-name="tibia 6"
            class="knie_stress_vd_rechts2356_cls1"
            d="M183.92,359.26c0-14.03-98.87,12.61-118.5,4"
          />
          <path
            id="tibia_7"
            data-name="tibia 7"
            class="knie_stress_vd_rechts2356_cls1"
            d="M205.42,302.26c-4.77-6-53.94,33-77,12.12"
          />
          <path
            id="tibia_8"
            data-name="tibia 8"
            class="knie_stress_vd_rechts2356_cls1"
            d="M35.49,345.23c13.17,4.88,22.17,31.26,13.41,53.29"
          />
        </g>
        <g id="fermur">
          <path
            id="fermur_1"
            data-name="fermur 1"
            class="knie_stress_vd_rechts2356_cls7"
            d="M236.87,16.04h0c5.55-24.99-32.87-9.88-75-11-51.14-1.36-70.32-11.75-73,2-3.34,17.1-6.7,21.88-8,52,0,0-.9,18.84-5.92,35.68-.72,2.42-1.58,4.83-2.67,7.48-5.7,13.87-9.49,13.87-17.41,31.84-2.99,6.79-8.01,18.36-11,34-2.84,14.87-.71,17.01-4,33-3.3,16.08-5.82,15.89-7.32,26.62-.86,6.13-4.06,28.97,8.32,42.38,10.5,11.39,27.36,10.7,42,10.11,7.8-.31,43.27-2.61,71.53-22.11,4.02-2.76,12.2-8.86,23.93-9.67,2.75-.19,9.37-.35,19.22,3.39,16.25,6.17,17.87,14.06,34.33,25.28,9.53,6.49,23.72,16.16,41.03,14.34,3.4-.36,16.16-1.71,22.8-11.29,3.99-5.77,4.15-12.49,4.29-16.67.29-8.54-2.05-12.59-3.12-18.38-2.2-11.9,3.69-16.17,15.14-49.35,5.9-17.1,6.15-21.25,6.2-23.72.33-15.59-6.93-27.44-9.62-31.72-6.03-9.57-12.19-13.83-18.53-19.5-20.96-18.72-30.4-40.96-38.19-57.71-4.04-8.68-8.68-18.8-12-33-2.45-10.45-1.32-10.91-3-14h0Z"
          />
          <path
            id="fermur_2"
            data-name="fermur 2"
            class="knie_stress_vd_rechts2356_cls3"
            d="M88.88,7.04c-4.65,21.56-6.85,39.37-8,52-1.68,18.39-.6,20.1-3,29-4.21,15.64-9.81,18.86-23,46-6.07,12.49-9.1,18.73-11,26-4.39,16.79-.44,20.66-4,41-3.3,18.85-7.28,18.94-8,33-.49,9.56-1.3,25.51,9,36,8.32,8.47,19.8,9.21,32,10,17.48,1.13,30.92-2.89,41-6,13.21-4.08,32.04-11.76,49-20,6.46-3.14,12.52-6.29,21-6,6.67.23,12.66,2.51,23,9,19.08,11.98,18.3,16.52,31,24,6.11,3.6,26.45,15.57,45,8,2.39-.98,11.84-4.83,16-14,4.75-10.46-2.59-16.37-2-33,.23-6.32,3.48-15.21,10-33,8.01-21.87,10.11-22.91,11-32,.46-4.65,1.54-17.3-5-30-3.88-7.53-8.85-12.01-11-14-22.63-20.98-42.24-54.27-50-70-4.92-9.98-10.95-34.62-14.6-56"
          />
          <path
            id="fermur_3"
            data-name="fermur 3"
            class="knie_stress_vd_rechts2356_cls1"
            d="M58.88,253.04c-9.15.16,2.3-19.28-19-52"
          />
          <path
            id="fermur_4"
            data-name="fermur 4"
            class="knie_stress_vd_rechts2356_cls1"
            d="M39.87,166.04c.01,22-16.99,17.5.01,35"
          />
          <path
            id="fermur_5"
            data-name="fermur 5"
            class="knie_stress_vd_rechts2356_cls1"
            d="M106.88,182.04c-32.74-14-48-1.34-48-3"
          />
          <path
            id="fermur_6"
            data-name="fermur 6"
            class="knie_stress_vd_rechts2356_cls1"
            d="M121.88,269.75c12.81.41,24.02-20.88,23-56.54"
          />
          <path
            id="fermur_7"
            data-name="fermur 7"
            class="knie_stress_vd_rechts2356_cls1"
            d="M300.88,174.04c-26.88-1.3-53.3-1.31-56-1"
          />
          <polyline
            id="fermur_8"
            data-name="fermur 8"
            class="knie_stress_vd_rechts2356_cls1"
            points="212.63 260.84 214.88 241.48 224.38 227.04 217.88 213.21 217.88 191.04 217.88 182.04 198.88 186.54 177.38 182.04 161.97 187.04 149.88 191.04"
          />
          <path
            id="fermur_9"
            data-name="fermur 9"
            class="knie_stress_vd_rechts2356_cls1"
            d="M272.88,98.39c-24.32,0-44-40.86-44-91.35"
          />
        </g>
        <path
          id="patella"
          class="knie_stress_vd_rechts2356_cls3"
          d="M138.88,200.04c-14.76-11.37-20.12-25.74-22-31-6.68-18.7-3.52-34.9-2-41,1.4-5.61,7.07-27,28-41,3.67-2.45,19.99-13.38,40-11,6.59.78,6.85,2.21,32,12,23.26,9.05,22.95,7.8,28,11,4.31,2.74,23.53,14.93,27,37,2.38,15.14-3.87,27.15-9,37-3.98,7.64-10.77,20.68-25,28-4.83,2.48-5.83,1.9-28,6-28.54,5.28-29.46,6.72-37,6-2.74-.26-17.56-1.88-32-13Z"
        />
      </g>
      <g id="overlays">
        <g id="joint_surfaces" data-name="joint surfaces">
          <path
            id="joint_surfaces_overlay_1"
            data-name="joint surfaces overlay 1"
            class="Tibiaplateau2356Fill"
            style={{
              fill: props.colors.Tibiaplateau2356Fill,
              stroke: props.colors.Tibiaplateau2356Fill,
            }}
            d="M43.12,317.61l5.29-6.35c7.98-7.88,23.28-9.04,42.96-6.18,5.94.86,11.71,2.6,17.21,4.99,4.94,2.14,10.17,2.3,15.72.29-3.78,7.78-10.98,12.85-21.83,15.02-18.84,2.82-38.96-.5-59.8-7.23l.45-.54Z"
          />
          <path
            id="joint_surfaces_overlay_2"
            data-name="joint surfaces overlay 2"
            class="Tibiaplateau2356Fill"
            style={{
              fill: props.colors.Tibiaplateau2356Fill,
              stroke: props.colors.Tibiaplateau2356Fill,
            }}
            d="M207.86,305.87c-6.64-6.16-8.92-22.24-23.44-13.61-6.85,5.25-13.51,4.93-20,0-6.25-6.4-13.6-6.28-23,6-6.04,6.19-16.1,12.57-21.51,14.51"
          />
          <path
            id="joint_surfaces_overlay_3"
            data-name="joint surfaces overlay 3"
            class="Tibiaplateau2356Fill"
            style={{
              fill: props.colors.Tibiaplateau2356Fill,
              stroke: props.colors.Tibiaplateau2356Fill,
            }}
            d="M291.9,322.73c.53-.15,1.03-.31,1.51-.47-5.73-6.24-35.84-11.83-82-17-4.03-.74-6.44-3.33-8.4-6.22,5.46,18.55,25.19,27.63,58.84,27.45,12.91-.67,23.32-1.85,30.04-3.77Z"
          />
        </g>
        <circle
          id="midial_joint_gap"
          data-name="midial joint gap"
          class="medialenGelenkspalt2356Fill"
          style={{
            fill: props.colors.medialenGelenkspalt2356Fill,
            stroke: props.colors.medialenGelenkspalt2356Fill,
          }}
          cx="169.43"
          cy="283.68"
          r="56.64"
        />
      </g>
    </svg>
  );
}
