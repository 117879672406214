import style from "./style.scss";

function LinkeZeheDplOblSitzendLinks(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.17 186.1">
      <g id="third_toe" data-name="third toe">
        <path
          id="third_toe_1"
          data-name="third toe 1"
          class="a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3"
          style={{
            fill: props.colors.a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3,
            stroke: props.colors.a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3,
          }}
          d="M35.19,185.66c.44-.24.81-12.21,1.25-12.45-.46-3.27-1.45-6.57-3.08-9.9,4-10.8-3.3-13.2-15.7-12-14.39-3.99-15.1,4.71-9.79,19.11-5.27,6.21-5.39,11.33.47,15.24"
        />
        <path
          id="third_toe_2"
          data-name="third toe 2"
          class="a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3"
          style={{
            fill: props.colors.a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3,
            stroke: props.colors.a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3,
          }}
          d="M11.17,144.52c-11.3,1.8-9.3-6-3-16.9,10.8-9.6,14-25.2,10.5-46.1-2.6-9.7-.7-13.5,6.4-10.5,3.4,1.9,7,2.1,10.9,0,5-5.9,7.1-2.2,7.1,8.2-8,16.9-11,34.3-7.5,52.5,5.5,19.2,1,21.2-8.2,15-6.1-4.1-11.5-4.9-16.2-2.2h0Z"
        />
        <path
          id="third_toe_3"
          data-name="third toe 3"
          class="a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3"
          style={{
            fill: props.colors.a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3,
            stroke: props.colors.a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3,
          }}
          d="M21.37,65.82c-10.2,4.8-9.6-1.5-7.1-9.8,2.7-5.7,2.6-11.2-.8-16.5-2.3-6.1-1.5-10.8,7.1-11.6,10.8,0,17.5,1.7,12.4,7.9.6,8.3,2.2,16,6,22.1,3.2,8.1-.7,10.6-9.8,9,.1,0-7.8-1.1-7.8-1.1Z"
        />
        <path
          id="third_toe_4"
          data-name="third toe 4"
          class="a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3"
          style={{
            fill: props.colors.a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3,
            stroke: props.colors.a1409_Linke_2Zehe_dpl_obl_sitzend_cls_3,
          }}
          d="M13.07,23.82c-4.5,1.4-7.8,1.3-4.1-7.1-3.8-4.1-5.8-8.2-2.2-12.3C9.17,1.62,12.77.32,16.47.52l6.4.4h1.4c7.1-.4,6.1,4,2.7,9.8,9.4,8.5,12,14,1.1,13.5l-15-.4h0Z"
        />
      </g>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="phalangen_overlays" data-name="phalangen overlays">
          <path
            id="phalangen_overlay_1"
            data-name="phalangen overlay 1"
            class="a1409_phalangenFill"
            style={{
              fill: props.colors.a1409_phalangenFill,
              stroke: props.colors.a1409_phalangenFill,
            }}
            d="M11.17,144.52c-11.3,1.8-9.3-6-3-16.9,10.8-9.6,14-25.2,10.5-46.1-2.6-9.7-.7-13.5,6.4-10.5,3.4,1.9,7,2.1,10.9,0,5-5.9,7.1-2.2,7.1,8.2-8,16.9-11,34.3-7.5,52.5,5.5,19.2,1,21.2-8.2,15-6.1-4.1-11.5-4.9-16.2-2.2h0Z"
          />
          <path
            id="phalangen_overlay_2"
            data-name="phalangen overlay 2"
            class="a1409_phalangenFill"
            style={{
              fill: props.colors.a1409_phalangenFill,
              stroke: props.colors.a1409_phalangenFill,
            }}
            d="M21.37,65.82c-10.2,4.8-9.6-1.5-7.1-9.8,2.7-5.7,2.6-11.2-.8-16.5-2.3-6.1-1.5-10.8,7.1-11.6,10.8,0,17.5,1.7,12.4,7.9.6,8.3,2.2,16,6,22.1,3.2,8.1-.7,10.6-9.8,9,.1,0-7.8-1.1-7.8-1.1Z"
          />
          <path
            id="phalangen_overlay_3"
            data-name="phalangen overlay 3"
            class="a1409_phalangenFill"
            style={{
              fill: props.colors.a1409_phalangenFill,
              stroke: props.colors.a1409_phalangenFill,
            }}
            d="M13.07,23.82c-4.5,1.4-7.8,1.3-4.1-7.1-3.8-4.1-5.8-8.2-2.2-12.3C9.17,1.62,12.77.32,16.47.52l6.4.4h1.4c7.1-.4,6.1,4,2.7,9.8,9.4,8.5,12,14,1.1,13.5l-15-.4h0Z"
          />
        </g>
        <g id="joint_overlays" data-name="joint overlays">
          <ellipse
            id="joint_overlay_1"
            data-name="joint overlay 1"
            class="a1409_gelenkeFill"
            style={{
              fill: props.colors.a1409_gelenkeFill,
              stroke: props.colors.a1409_gelenkeFill,
            }}
            cx="22.1"
            cy="26.61"
            rx="15.57"
            ry="6.68"
          />
          <ellipse
            id="joint_overlay_2"
            data-name="joint overlay 2"
            class="a1409_gelenkeFill"
            style={{
              fill: props.colors.a1409_gelenkeFill,
              stroke: props.colors.a1409_gelenkeFill,
            }}
            cx="27.94"
            cy="69.22"
            rx="16.24"
            ry="5.39"
          />
          <ellipse
            id="joint_overlay_3"
            data-name="joint overlay 3"
            class="a1409_gelenkeFill"
            style={{
              fill: props.colors.a1409_gelenkeFill,
              stroke: props.colors.a1409_gelenkeFill,
            }}
            cx="19.37"
            cy="147.82"
            rx="19.37"
            ry="7.75"
          />
        </g>
      </g>
    </svg>
  );
}

export default LinkeZeheDplOblSitzendLinks;
