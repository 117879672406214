import style from "./style.scss";

export default function LinkerUnterschenkelMitOberemSprunggelenkLatLiegend2305(
  props
) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303.42 587.24">
      <g
        id="upper_ankle_joint_with_lower_leg"
        data-name="upper ankle joint with lower leg"
      >
        <path
          id="background"
          class="unterschenkel_mit_osg_lat_left2305_cls6"
          d="M4.13,454.37c6.78-3.78,14.16-7.03,22.75-9.22,6.48-1.65,8.18-1.31,10.66-1.87.49-.11,1.73-.44,3.34-1.13,4.43-1.9,10.47-6.02,15-9,6.17-4.06,7.44-4.6,9-5,1.77-.46,2.6-.4,8-1,4.06-.45,7.44-.91,8-1,6.89-1.07,12.93-11.86,20-27,7.01-14.99,10.51-22.49,13-31,4.01-13.71,5.05-24.55,6-35,.57-6.31.72-14.54,1-31,.27-15.72.4-23.79,0-34-.49-12.55-1.47-22-2-27-3.34-31.62-11.89-69.75-16.63-96.86-7.98-45.64-12.97-71.81-16.37-83.14-6.99-23.36-18.89-55.9-4.99-55.9h34c3.95,0,30.62.32,84,0,54.75-.33,53.81-1.4,56,0,37.7,24.07-.66,138.17-2.83,262.65-.77,44.11,4.33,65.43,7.98,80.91,5.68,24.05,13.09,38.04,16.08,51.26,1.02,4.52,1.16,14.08,7.33,23.39,3.17,4.79,4.81,5.01,8.09,10.44,4.93,8.13,6.25,15.88,7.13,21.28,1.32,8.19.17,9.38,1.18,20.34.92,9.99,2.28,13.28,2.98,18.8,1.78,13.99-2.11,30.82-11.08,42.73-8.6,11.4-19.76,15.5-32.8,20.28-23.52,8.63-45.1,8.1-61.28,7.69-14.91-.37-29.83-.09-44.73-.74-6.43-.28-14.11-.75-22.01,3.01-2.27,1.08-4.6,2.53-8.81,4.03-6.39,2.28-11.56,2.69-13.23,2.85-11.83,1.09-26.18,5.58-53.1,9.25-9.04,1.23-11.91,1.34-51.54,2.81"
        />
        <g id="foot">
          <g id="outline">
            <path
              id="outline_1"
              data-name="outline 1"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M4.28,586.87c18.18-.77,34.03-1.83,45.82-2.75,21.85-2.57,46.23-7.1,72.03-12.81,10.15-7.92,39.62-8.35,75.55-6.3,40.36,1.51,71.12-8.57,87.31-20.36,16.82-15.93,22.16-38.8,14.87-69.14.7-25.79-5.78-41.79-16.4-52.05-7.33-23.34-15.1-48.35-23.41-74.65-5.6-20.98-7.56-55.91-7.98-80.91"
            />
            <path
              id="outline_1-2"
              data-name="outline 1"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M121.09,254.64c2.48,30.8,3.51,111.9-30.8,164.95-6.64,6.12-14.05,9.38-22.92,7.46l-26.28,13.56-11.88,2.87c-8.74,3.41-17.17,7.01-25.05,10.95"
            />
          </g>
          <g id="heel_bone" data-name="heel bone">
            <path
              id="heel_bone_3"
              data-name="heel bone 3"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M222.27,489.64c-14.8,4.92-23.2,2.62-20.27-12.51"
            />
            <path
              id="heel_bone_2"
              data-name="heel bone 2"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M170.27,489.86c4.97.85,8.54,3.42,9.16,9.84-9.35,3.33-18.74,3.02-28.35-.92"
            />
            <path
              id="heel_bone_1"
              data-name="heel bone 1"
              class="unterschenkel_mit_osg_lat_left2305_cls8"
              d="M266.46,457.89c4.59,22.6,2.79,65.84-42.13,82.46"
            />
            <path
              id="heel_bone_4"
              data-name="heel bone 4"
              class="unterschenkel_mit_osg_lat_left2305_cls8"
              d="M142.74,474.17c-14.37,1.12-23.02,2.46-27.39,6.02,14.24,9.08,16.8,24.4,12.79,43.26,15.81,21.84,35.34,16.72,55,9.9,19.85-9.73,24.34,11.53,41.18,7"
            />
            <path
              id="heel_bone_5"
              data-name="heel bone 5"
              class="unterschenkel_mit_osg_lat_left2305_cls8"
              d="M130.95,462.75c2.53,1.11-.02-9.87,10.9-12.27,5.01-1.1,12.91.17,18.41-.09,8.56-.4,10.34,3.06,15.06,1.45,5.55-1.9,6.92-18.44,5.38-17.21-1.85,1.47,3.22-1.39,5.56-12.21,1.15-5.33,7.1-9.24,12.63-10.47,7.39-1.64,13.95,2.58,18.21,5.34,5.87,3.79,4.79,5.54,9.29,7.67,6.76,3.2,14.49,1.75,23.02.16,7.77-1.44,9.52-2.88,11.96-1.44,1.89,1.11,3.5,3.52,4.62,16.84.63,7.45.71,13.66.66,17.99"
            />
            <path
              id="heel_bone_6"
              data-name="heel bone 6"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M168.18,461c-.35-9.58-6.86,35.2,32.84-18.56"
            />
          </g>
          <g id="tarsus">
            <path
              id="tarsus_5"
              data-name="tarsus 5"
              class="unterschenkel_mit_osg_lat_left2305_cls8"
              d="M93.99,441.88h0c-10.17-3.27-10.89.87-3.95,10.97,6.75,4.8,9.79,11.22,10.17,18.85-.59,7.68,3.75,9.85,10.86,8.75,10.32-5.79,20.89-7.77,31.67-6.29-7.39-3.83-13.87-9.2-19.46-16.56-6.06-5.89-9.88-11.12-9.77-23.91-6.06,6.01-12.38,10.25-19.51,8.18h-.01Z"
            />
            <path
              id="tarsus_4"
              data-name="tarsus 4"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M124.8,531h0c5.44,8.67,4.23,12.36-13.28,4.31-5.92-3.05-11.77-4.7-16.99.62-7.53-7.94-15.15-15.04-23.08-18.01-4.69-1.42-8.11-5.2-10.54-10.81-7.63-4.94-7.66-8.39,2.93-9.53,8.65-1.35,12.65-8.12,15.76-15.98,3.77-2.57,12.06-3.14,22.42-2.87,1.56,3.02,5.12,2.61,9.05,1.72,16.25,2.95,19.6,17.77,16.96,38.21l-3.22,12.35h-.01Z"
            />
            <path
              id="tarsus_3"
              data-name="tarsus 3"
              class="unterschenkel_mit_osg_lat_left2305_cls8"
              d="M82.98,447.13h0c-8.43-.72-16.03.59-22.67,4-9.14,1.37-8.44,7.03.6,16.41,11.17,4.17,16.76,8.91,18.69,14.07,4.4-3.98,16.45-2.28,20.57-3.08,3.17-.58-2.73-8.67-.41-11.09-2.33-8.22-7.89-14.92-16.79-20.29h0v-.02h.01Z"
            />
            <path
              id="tarsus_2"
              data-name="tarsus 2"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M56.94,467.31h0c-.02-3.26-2.2-7.2-10.24-12.87-11.22-.15-22.1,1-30.48,9.89,1.26,5.46,3.38,10.51,8.43,13.69,3.16-3.48,14.35,1.06,17.38-.64,4.07-2.2,5.36-9.43,14.9-10.06h0Z"
            />
            <path
              id="tarsus_1"
              data-name="tarsus 1"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M73.24,492.49h0s9.92-13.89-.9-19.29c-11.43-5.64-14.76-4.12-15.27-2.33-6.22-1.09-11.79.24-15.03,6.52-11.98.34-19.03,3.21-21.84,8.17,11.24,3.41,20.78,7.73,24.52,14.87,4.08,3.21,8.21,2.5,12.33-1.1l16.18-6.85h.01Z"
            />
          </g>
          <g id="metatarsus">
            <path
              id="metatarsus_10"
              data-name="metatarsus 10"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M4.28,536.82l35.06-6.13c7.5-6.53,16.07-9.83,25.21-11.1,14.58-.44,23.55,6.88,28.83,19.2h0c13.43,20.96-3.85,21.78-31.51,15.89l-7.79-4.29c-8.51-1.88-18.72-3.2-49.92,3.47"
            />
            <path
              id="metatarsus_9"
              data-name="metatarsus 9"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M80.12,554.01c-10.77-3.53-17.1-7.49-17.93-11.94"
            />
            <path
              id="metatarsus_8"
              data-name="metatarsus 8"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M4.28,548.77c-1.42.49-2.8,1.03-4.13,1.62"
            />
            <path
              id="metatarsus_8-2"
              data-name="metatarsus 8"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M48.85,544.51c-16.73-.56-32.34.03-44.57,4.26"
            />
            <path
              id="metatarsus_7"
              data-name="metatarsus 7"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M64.65,519.59c-.53-9.41-8.55-14.25-23.36-14.92-7.98-1.67-18.39,1.98-32.44,14.15-1.56.68-3.12,1.34-4.69,1.99"
            />
            <path
              id="metatarsus_6"
              data-name="metatarsus 6"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M41.19,504.67c-.64-9.68-8.3-15.56-31.31-12.94-2.02.1-3.96.31-5.72.71"
            />
            <path
              id="metatarsus_5"
              data-name="metatarsus 5"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M22.43,490.89c-4.69-9.25-9.22-14.22-13.3-12.02-1.61.24-3.26.62-4.97,1.24"
            />
            <path
              id="metatarsus_4"
              data-name="metatarsus 4"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M16.85,481.57c6.85,2.37,6.62-1.45,2.98-8.22-4.98-4.02-9.62-6.84-12.44-2.07-1.12.37-2.2.7-3.23,1"
            />
            <path
              id="metatarsus_3"
              data-name="metatarsus 3"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M29.81,528.88c-8.29.56-16.86,1.72-25.65,3.36"
            />
            <path
              id="metatarsus_2"
              data-name="metatarsus 2"
              class="unterschenkel_mit_osg_lat_left2305_cls7"
              d="M11.43,512.85c-2.44.57-4.87,1.15-7.27,1.73"
            />
          </g>
        </g>
        <path
          id="talus"
          class="unterschenkel_mit_osg_lat_left2305_cls8"
          d="M119.21,421.89c5.32-4.61,16.77-6.62,16.77-6.62,0,0,2.84-7.74,4.59-11.18,3.71-7.31,10.45-10.89,15.51-13.58,5.34-2.83,16.2-8.6,27.84-5.02,4.4,1.35,7.37,3.74,13.32,8.52,6.76,5.43,12.27,9.85,11.54,14.59-.58,3.81-9.88,3.36-14.73,7.54-6.3,5.43-4.22,2.7-7.78,6.29-3.78,3.8-1.43,7.19-5.56,12.21-3.27,3.98-5.56,2.82-9.77,6.36-7.25,6.08-4.57,12.93-10.34,18.26-4.28,3.96-9.94,3.82-18.87,3.82-5.74.01-8.69.06-10.76-.33-1.15-.22-6.78-1.3-10.41-3.9-9.01-6.45-12.48-27.32-1.35-36.98v.02h0Z"
        />
        <g id="tibia">
          <path
            id="tibia_1"
            data-name="tibia 1"
            class="unterschenkel_mit_osg_lat_left2305_cls8"
            d="M157.83.16c2.26,13.75,4.44,27.68,6.53,41.79,1.57,10.56,4.12,37.74,9.63,71.7,7.17,44.17,8.71,75.32,12.03,107.64.58,5.68,1.5,16.25,3.19,34.9,1.02,11.17,1.52,16.78,1.57,17.81.76,16-1.14,20.93-.76,34.22.29,10.41,1.72,18.02,3,24.88,4.49,24.01,11.84,37.88,6.88,45.71-.27.41-5.37,8.41-7.44,10.87-.28.33-1.52,1.77-2.82,3.93-.17.27-.32.52-.4.66-2.17,3.82-1.72,7.26-4.15,10.61-.58.8-1.12,1.32-1.41,1.6-4.85,4.6-10.06,1.33-16.84,4.62-4.82,2.34-5.28,5.5-8.81,5.33-2.31-.11-4.87-1.59-6.14-3.78-2.06-3.52.59-6.56-1.47-12.95-.45-1.39-.93-2.36-1.24-2.98-2.9-5.79-7.22-7.77-10.26-10.61-7-6.56-6.84-17.41-6.73-19.77.31-6.6,3.26-9.17,6.41-16.25,3.83-8.61,4.23-15.5,4.97-28.2,1.23-21-2.32-38.51-6.37-64.81-5.25-34.08-2.93-28.33-6.86-50.78-4.95-28.3-7.23-29.45-14.4-65.52-4.07-20.5-7.26-36.74-9.15-58.7-1.46-16.94-1.63-31.09-1.5-40.97"
          />
          <path
            id="tibia_2"
            data-name="tibia 2"
            class="unterschenkel_mit_osg_lat_left2305_cls8"
            d="M105.29,41.12l-4.42-38.86"
          />
        </g>
        <g id="fibula">
          <path
            id="fibula_1"
            data-name="fibula 1"
            class="unterschenkel_mit_osg_lat_left2305_cls8"
            d="M140.94,5.25c1.77,18.92,2.69,31.51,5.32,61.39,1.4,15.85,4.16,46.66,8.1,84.76,4.75,45.97,6.14,53.76,7.15,58.78"
          />
          <path
            id="fibula_2"
            data-name="fibula 2"
            class="unterschenkel_mit_osg_lat_left2305_cls8"
            d="M171.58,330.92c.68,12.32.66,16.17.13,20.99-.95,8.45-3.04,15.95-3.38,17.13-2.33,8.21-3.66,8.54-4.49,14.17-.51,3.4-2.72,10.9.88,17.63,4.37,8.18,14.01,10.36,16.16,10.77"
          />
          <path
            id="fibula_3"
            data-name="fibula 3"
            class="unterschenkel_mit_osg_lat_left2305_cls8"
            d="M180.89,411.62c8.52,2.11,11.99-.6,12.95-1.47,2.34-2.13,2.69-6.8,3.41-16.14.39-5.13.43-10.6.9-13.82.06-.4.31-1.99.37-4.15,0-.08.03-1.03,0-2.09-.09-3.07-.65-6.88-.9-8.17-2.9-14.74-10.48-29.44-10.48-29.44-4.51-8.74-5.92-20.19-8.57-42.72-4.49-38.19-5.13-75.99-7.68-110.48-3.42-46.21-7.46-48.42-17-138-1.82-17.04-3.16-30.98-4-39.89"
          />
          <line
            id="fibula_4"
            data-name="fibula 4"
            class="unterschenkel_mit_osg_lat_left2305_cls8"
            x1="171.56"
            y1="330.92"
            x2="161.51"
            y2="210.18"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="tibia_overlay"
          data-name="tibia overlay"
          class="tibia2305fill"
          style={{
            fill: props.colors.Tibia2305Fill,
            stroke: props.colors.Tibia2305Fill,
          }}
          d="M198.19,380.33c-.47,9.86-1.1,19.17-2.4,26.12-3.24,9.27-12.1,6.76-21.58,2.97-5.47.16-9.99,2.37-13.68,6.4-9.69.24-9.98-5.95-9.38-12.75-1.59-8.88-6.81-13.86-13.03-17.76-6.45-7.26-7.66-15.89-4.35-25.71,7.7-11.04,11.13-26.67,10.04-47.16-.67-22.81-7.69-62.48-13.47-106.13-13.7-59.29-25.09-113.97-25.05-165.18l-4.42-34.13h58.07c10.26,73.87,20.6,140.33,24.81,190.12l7.03,76.89c-1.61,22.24-1.64,43.59,2.88,62.35,4.8,20.97,12.51,38.51,4.53,43.98Z"
        />
        <path
          id="talus_overlay"
          data-name="talus overlay"
          class="talus2305fill"
          style={{
            fill: props.colors.Talus2305Fill,
            stroke: props.colors.Talus2305Fill,
          }}
          d="M119.21,421.89c5.32-4.61,16.77-6.62,16.77-6.62,0,0,2.84-7.74,4.59-11.18,3.71-7.31,10.45-10.89,15.51-13.58,5.34-2.83,16.2-8.6,27.84-5.02,4.4,1.35,7.37,3.74,13.32,8.52,6.76,5.43,12.27,9.85,11.54,14.59-.58,3.81-9.88,3.36-14.73,7.54-6.3,5.43-4.22,2.7-7.78,6.29-3.78,3.8-1.43,7.19-5.56,12.21-3.27,3.98-5.56,2.82-9.77,6.36-7.25,6.08-4.57,12.93-10.34,18.26-4.28,3.96-9.94,3.82-18.87,3.82-5.74.01-8.69.06-10.76-.33-1.15-.22-6.78-1.3-10.41-3.9-9.01-6.45-12.48-27.32-1.35-36.98v.02h0Z"
        />
        <path
          id="fibula_overlay"
          data-name="fibula overlay"
          class="fibula2305fill"
          style={{
            fill: props.colors.Fibula2305Fill,
            stroke: props.colors.Fibula2305Fill,
          }}
          d="M140.94,5.25c5.51,70.63,12.27,139.12,20.57,204.93l10.05,120.74c1.86,16.87-.51,32.45-6.35,46.96-2.89,10.65-3.85,20.32,1.68,26.64,5.21,5.95,14.76,5.03,18.81-1.75,1.69-2.82,2.82-6.15,3.93-9.16l5.51-7.82c2.1-2.99,3.32-6.54,3.31-10.19-.02-11.8-5.01-25.62-11.76-41.17-1.79-4.13-3.11-8.45-3.88-12.88-6.23-35.98-9.33-77.41-10.83-121.65-5.45-72.47-20.76-144.92-22.1-194.64"
        />
        <path
          id="calcaneus_overlay"
          data-name="calcaneus overlay"
          class="calcaneus2305fill"
          style={{
            fill: props.colors.Calcaneus2305Fill,
            stroke: props.colors.Calcaneus2305FSill,
          }}
          d="M206.37,412.1c6.25,2.08,11.27,5,15.08,8.75,6.26,7.79,18.98,8.07,36.92,2.3,4.28-.96,6.11,3.6,6.88,10.54l1.4,24.82c3.78,23.31.31,43.53-11.83,60.05-18.21,18.26-33.33,26.78-43.64,20.14-9.83-7.59-18.88-10.15-26.87-5.89-24.11,11.31-42.75,8-56.16-9.36,5.22-22.33-.34-35.66-12.79-43.26,3.92-3.41,13.99-5.16,27.39-6.02-6.11-3.16-11.45-7.36-16.09-12.49,24.71,4.22,38.14.92,38.51-11.07,1.8-7.15,6.82-11.82,14.28-14.62l6.84-13.56c5.74-6.06,12.21-10.14,20.1-10.33Z"
        />
        <path
          id="upper_ankle_joint_overlay"
          data-name="upper ankle joint overlay"
          class="osg2305fill"
          style={{
            fill: props.colors.OSG2305Fill,
            stroke: props.colors.OSG2305Fill,
          }}
          d="M201.64,376.23l-.54,26.53c-.06,3.09-1.1,6.12-3.13,8.44-3.12,3.56-7.52,5.81-13.67,6.27-2.98-.42-6.15-1.54-9.51-3.32-1.75-.93-3.89-.56-5.31.83-1.69,1.66-3.61,3.09-5.79,4.27-6.71,3.61-14.87-.09-16.73-7.47-.5-1.97-.81-3.87-.96-5.7-.44-5.54-2.36-10.87-5.25-15.62l-4.2-6.9.43-.49c2.1-2.41,5.86-2.39,7.93.05,5.55,6.55,10.41,13.03,12.86,19.27.78,1.98,2.79,3.17,4.91,2.96s4.28-.73,6.29-1.55c3.19-1.3,6.79-1.02,9.86.53,1.77.89,3.77,1.49,6.05,1.72,2.77.28,5.41-1.14,6.68-3.61,2.39-4.63,3.61-12.24,4.39-20.97.26-2.94,2.73-5.21,5.68-5.21h0Z"
        />
      </g>
    </svg>
  );
}
