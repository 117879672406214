import style from "./style.scss";

function RechteGrosszehe1ZeheDplOblSitzend1406(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 137.82 482.65">
      <g id="big_toe" data-name="big toe">
        <path
          id="big_toe_1"
          data-name="big toe 1"
          class="Großzehe_Zehe1dpl_obl_rechts1406_cls5"
          style={{
            fill: props.colors.Großzehe_Zehe1dpl_obl_rechts1406_cls5,
            stroke: props.colors.Großzehe_Zehe1dpl_obl_rechts1406_cls5,
          }}
          d="M53.18,109.05c8.57-2.06,16.29-2.61,22.58-.61,2.42.77,5.08.3,7.24-1.04,9.19-5.7,11.54-13.36,7.98-22.73-5.64-14.85-9.86-31.65-13.41-49.37-1.53-8.97-4.87-14.98-9.14-19.5-15.91-1.55-15.15,13.09-12.19,29.87,2.16,13.25-2.15,27.11-11.58,41.45-5.14,15.07-3.17,23.23,8.53,21.94h0Z"
        />
        <path
          id="big_toe_2"
          data-name="big toe 2"
          class="Großzehe_Zehe1dpl_obl_rechts1406_cls5"
          style={{
            fill: props.colors.Großzehe_Zehe1dpl_obl_rechts1406_cls5,
            stroke: props.colors.Großzehe_Zehe1dpl_obl_rechts1406_cls5,
          }}
          d="M36.73,217.56c-13.45,6.28-16.3,1.82-7.92-14.02,6.36-26.34,9-52.76,8.53-79.24-2.67-11.12,1.37-15.42,12.8-12.19,6.44,4.35,16.55,6.45,31.69,5.49,11.97-1.02,13.83,4.03,5.49,15.24-6.89,7.19-9.16,18.26-4.27,35.35,1.22,7.67,4.79,15.72,12.19,24.38,8.85,4.62,11.69,12.18,8.85,22.52-1.6,5.85-8.36,8.5-13.73,5.68-19.07-10.03-37.13-12.49-53.63-3.21h0Z"
        />
        <path
          id="big_toe_3"
          data-name="big toe 3"
          class="Großzehe_Zehe1dpl_obl_rechts1406_cls5"
          style={{
            fill: props.colors.Großzehe_Zehe1dpl_obl_rechts1406_cls5,
            stroke: props.colors.Großzehe_Zehe1dpl_obl_rechts1406_cls5,
          }}
          d="M23.33,266.93l1.22,31.09c7.08,53.58,8.98,102.8-10.36,134.09-9.19,23.19-4.6,40.33,29.87,35.96,5.3,12.22,14.62,18.16,32.3,10.97,12.01-5.33,27.8-4.58,46.32.61,15.88,6.44,21.07-.59,4.27-35.35,6.73-18.91-7.35-37.53-25.6-56.08-10.52-16.67-16.24-38.6-11.58-71.92,10.16-2.85,13-12.19,8.53-28.04-2.24-18.34,0-30.92,6.7-37.79-10.68-16.81-23.61-28.81-40.23-32.91-19.94-.88-33.91,4.6-39.62,18.89-16.07,6.05-15.15,12.86-1.83,30.48h.01Z"
        />
        <path
          id="outline"
          class="Großzehe_Zehe1dpl_obl_rechts1406_cls4"
          style={{
            fill: props.colors.Großzehe_Zehe1dpl_obl_rechts1406_cls4,
            stroke: props.colors.Großzehe_Zehe1dpl_obl_rechts1406_cls4,
          }}
          d="M119.27,219.86c3.78-36.57,5.22-71.04,0-99.56,8.83-42.93-.11-77.1-25.54-103.15-.96-.99-1.98-1.93-3.04-2.82C69.87-3.06,52.61-2.88,37.59,8.35c-7.43,5.56-13.05,13.18-16.63,21.74-9.3,22.2-12.33,51.7-10.54,86.84C1.76,151.5-.87,186.4,1.14,221.56"
        />
        <ellipse
          id="big_toe_4"
          data-name="big toe 4"
          class="Großzehe_Zehe1dpl_obl_rechts1406_cls5"
          style={{
            fill: props.colors.Großzehe_Zehe1dpl_obl_rechts1406_cls5,
            stroke: props.colors.Großzehe_Zehe1dpl_obl_rechts1406_cls5,
          }}
          cx="111.63"
          cy="275.62"
          rx="17.3"
          ry="28.69"
        />
      </g>
      <g id="overlay">
        <g id="big_toe-2" data-name="big toe">
          <path
            id="big_toe_overlay_1"
            data-name="big toe overlay 1"
            class="Bigtoe1406Fill"
            style={{
              fill: props.colors.Bigtoe1406Fill,
              stroke: props.colors.Bigtoe1406Fill,
            }}
            d="M53.18,109.05c8.57-2.06,16.29-2.61,22.58-.61,2.42.77,5.08.3,7.24-1.04,9.19-5.7,11.54-13.36,7.98-22.73-5.64-14.85-9.86-31.65-13.41-49.37-1.53-8.97-4.87-14.98-9.14-19.5-15.91-1.55-15.15,13.09-12.19,29.87,2.16,13.25-2.15,27.11-11.58,41.45-5.14,15.07-3.17,23.23,8.53,21.94h0Z"
          />
          <path
            id="big_toe_overlay_2"
            data-name="big toe overlay 2"
            class="Bigtoe1406Fill"
            style={{
              fill: props.colors.Bigtoe1406Fill,
              stroke: props.colors.Bigtoe1406Fill,
            }}
            d="M36.73,217.56c-13.45,6.28-16.3,1.82-7.92-14.02,6.36-26.34,9-52.76,8.53-79.24-2.67-11.12,1.37-15.42,12.8-12.19,6.44,4.35,16.55,6.45,31.69,5.49,11.97-1.02,13.83,4.03,5.49,15.24-6.89,7.19-9.16,18.26-4.27,35.35,1.22,7.67,4.79,15.72,12.19,24.38,8.85,4.62,11.69,12.18,8.85,22.52-1.6,5.85-8.36,8.5-13.73,5.68-19.07-10.03-37.13-12.49-53.63-3.21h0Z"
          />
        </g>
        <path
          id="endphalanx_overlay"
          data-name="endphalanx overlay"
          class="Endphalanx1406Fill"
          style={{
            fill: props.colors.Endphalanx1406Fill,
            stroke: props.colors.Endphalanx1406Fill,
          }}
          d="M53.18,109.05c8.57-2.06,16.29-2.61,22.58-.61,2.42.77,5.08.3,7.24-1.04,9.19-5.7,11.54-13.36,7.98-22.73-5.64-14.85-9.86-31.65-13.41-49.37-1.53-8.97-4.87-14.98-9.14-19.5-15.91-1.55-15.15,13.09-12.19,29.87,2.16,13.25-2.15,27.11-11.58,41.45-5.14,15.07-3.17,23.23,8.53,21.94h0Z"
        />
        <path
          id="basisphalanx_overlay"
          data-name="basisphalanx overlay"
          class="Grundphalanx1406Fill"
          style={{
            fill: props.colors.Grundphalanx1406Fill,
            stroke: props.colors.Grundphalanx1406Fill,
          }}
          d="M36.73,217.56c-13.45,6.28-16.3,1.82-7.92-14.02,6.36-26.34,9-52.76,8.53-79.24-2.67-11.12,1.37-15.42,12.8-12.19,6.44,4.35,16.55,6.45,31.69,5.49,11.97-1.02,13.83,4.03,5.49,15.24-6.89,7.19-9.16,18.26-4.27,35.35,1.22,7.67,4.79,15.72,12.19,24.38,8.85,4.62,11.69,12.18,8.85,22.52-1.6,5.85-8.36,8.5-13.73,5.68-19.07-10.03-37.13-12.49-53.63-3.21h0Z"
        />
      </g>
    </svg>
  );
}

export default RechteGrosszehe1ZeheDplOblSitzend1406;
