import { useState } from "react";
import "./stylesheets/ExaminationsStatistics.scss";
import ExamQA2 from "../Components/ExamsQA2/ExamQA2";
// import ExamQA from "../Components/ExamQA/ExamQA";
import ExamStatistics from "../Components/ExamStatistics/ExamStatistics";
import VerifiedExaminationsStatistics from "../Components/VerifiedExaminationsStats/VerifiedExaminationsStats";
import BodyPartsStatistics from "../Components/BodyPartsStatistics/BodyPartsStatistics";

function ExaminationsStatistics() {
  const availableViews = {
    ExamQA: {
      comp: ExamQA2,
      name: "Exam QA",
      icon: "fas fa-x-ray",
    },
    ExamStatistics: {
      comp: ExamStatistics,
      name: "Exam Statistics",
      icon: "fas fa-chart-bar",
    },
    VerifiedExams: {
      comp: VerifiedExaminationsStatistics,
      name: "Verified  Examinations",
      icon: "fas fa-briefcase-medical",
    },
    BodyPartsStatistics: {
      comp: BodyPartsStatistics,
      name: "Body Part Statistics",
      icon: "fas fa-bone",
    },
    // OLD examqa replaced with ExamQA2 still here for implementation examples also exam statistics using the same structure
    // ExamQA_OLD: {
    //   comp: ExamQA,
    //   name: "Exam QA (old)",
    //   icon: "fas fa-x-ray",
    // },
  };

  const [selectedView, setSelectedView] = useState("ExamQA");

  const CurrentView = availableViews[selectedView].comp;
  return (
    <div className="d-flex flex-column">
      <div className="stats-nav d-flex">
        {Object.keys(availableViews).map((viewKey, key) => (
          <div
            onClick={() => setSelectedView(viewKey)}
            key={`viewkey${key}`}
            className={`nav-element p-2 border-right ${
              viewKey === selectedView && "selected"
            }`}
          >
            <div className="d-flex align-items-center">
              <i className={`mr-1 ${availableViews[viewKey].icon}`} />
              <div>{availableViews[viewKey].name}</div>
            </div>
          </div>
        ))}
      </div>
      <CurrentView />
    </div>
  );
}

export default ExaminationsStatistics;
