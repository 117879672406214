import style from "./style.scss";

function RechtesKniegelenkTEPlatLiegendLinks2320(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 526.61 738.34">
      <g id="knee_TEP" data-name="knee TEP">
        <path
          id="femur"
          class="knee_lat_tep_right2320_cls5"
          d="M258.97.54c-2.5,5.9,7.6,24.4,11,45.1,2.3,14.2,1.5,27.8,0,55-.7,12.6-1.5,19.7-2,24-3.8,31.5-9.3,38.2-12,41-.1.2-3.8,3.4-11,10-10.3,9.4-10.2,9.2-11,10-6.6,6.5-8.6,8.8-14.3,19.2,1.8-.2,4.8,0,9.3.8,1.9,5.4,4.3,10,7,10,5,0,5.9-16.2,12-17,6.2-.8,14.2,14.7,18,25,5.1,13.8,3.2,19.8,8,23,6.5,4.4,16.4-2.6,31.6-10,20.1-9.8,48.4-17.3,86.6-27.5,7.2-1.9,18-.3,23.9-7.5,4.3-5.3,4.2-12.2,3.4-21-2.8-32-10.8-62.9-10.8-62.9-3.1-11.9-6.2-23.1-.6-33.1,2.5-4.5,4.1-3.9,6-8,4.1-8.8-.3-17.4-8-39-6.6-18.6-8.9-35.4-10-37.1"
        />
        <path
          id="tibia"
          class="knee_lat_tep_right2320_cls5"
          d="M208.37,737.94c2.9-6.8,6.9-17.2,16.6-36.3,16.7-33.1,32.1-56.4,43-73,32.6-49.5,34.8-38.7,61-80,21.1-33.3,30.5-57.5,49-105,.5-1.3,11.4-21.5,7-25-2-1.6-5.7,1.8-14,3-7.4,1.1-13.1-.3-19.3-1.8-16.4-4-19-10.3-25.7-9.2-10.2,1.6-15.3,18.1-18,26-11.6,34.2-40.2,92.1-67.8,93.7-10,.6-20.2-6.1-24.2-13.7-4.2-7.9-.5-14.5,3.8-38.9,3.7-21.1,1.2-18.1,4.4-33.1,4.7-22.4,9.8-27.6,9.8-45,0-14.6-3.6-20.2-6-23-3-3.4-4.6-6.2-17-9-23.1-5.2-26.7-13.3-29-11-1.5,1.5.6,4,3,13,7.2,27.1,9.3,32.3,11,36,5.1,11.1,6.3,9,8,15,3.2,11.4-.9,20.1-6,36-7.3,23-4,21.3-10,39-6.2,18.3-11.9,26.9-22,46-13,24.7-8.6,20.5-33,73-12.3,26.4-12.4,25.3-29,61-24.6,52.8-15.9,37-23,51"
        />
        <path
          id="fibula_2"
          data-name="fibula 2"
          class="knee_lat_tep_right2320_cls5"
          d="M40.27,722.44c2.6-6.6,5-14,10.7-24.7,2.9-5.5,8-19.4,18-47,16.8-46.1,14-40.8,20-56,2.5-6.3,13.7-29,36-74,6.9-13.8,12.6-25.1,25-33,1.4-.9,2.1-1.2,22-11,13.9-6.8,20.7-10.4,23.7-12.5,11.5-7.8,28.2-25.5,27.3-45.5-.2-5-7.8-16.4-23-39-14.4-21.4-20.3-29.1-30.3-30.8-6.9-1.2-12.9.4-16.7,1.8-15.6,10.9-23.3,23-27,30-5.7,10.9-7.8,21-10,32-3,14.6-2,18.8-4,30-2.5,14-6.9,23.2-15,40-16.3,33.7-27.9,57.5-33,68-11.2,23.3-19.4,42.2-25,55-14.7,33.8-20.2,41.1-29,66-2.4,6.7-5.7,20.8-9,33.1"
        />
        <path
          id="patella"
          class="knee_lat_tep_right2320_cls6"
          d="M474.67,331.14c-2.8-1.2-2.3-3.8-6.7-15.4-5.8-15.5-9.8-18.7-9.3-27.9.2-3.9,1.1-6.8,2-9.8,2.8-8.9,6.1-11.1,11.4-21.5,1.5-3,4.4-8.6,6.8-16.3,2.4-7.8,1.9-9.9,4.8-19.1,3.4-10.6,5.8-13,7.9-14.3.6-.4,5.2-3.1,10-1.6,7.1,2.2,7,11.3,16.4,31.2,5.3,11.2,6.7,11.3,7.7,16.7,1.6,9.3-1.3,16.7-5.9,27.7-7.9,19-18,30.5-20.7,33.5-6.7,7.7-17.6,19.7-24.4,16.8Z"
        />
        <path
          id="fibula_1"
          data-name="fibula 1"
          class="knee_lat_tep_right2320_cls4"
          d="M181.32,355.11c2.4,20.2,3.26,42.49,14.64,50.54,7.7,6.24,7.98,9.06,8.22,17.76-2.43,25.22-8.33,28.79-9.12,43.06"
        />
        <g id="TEP">
          <path
            id="TEP_upper_leg"
            data-name="TEP upper leg"
            class="knee_lat_tep_right2320_cls7"
            d="M415.97,201.64c-5.5,6.9-10.6,3.3-43,12-13.4,3.6-25.1,7.6-35,11-8.5,2.9-15.7,5.4-25,9-28.2,11-33.6,16.3-39,13-6.9-4.2-1.3-14.9-11-33-1.4-2.6-8.7-16.1-15-15-6.5,1.1-8.3,17.4-12,17-2.4-.3-1.9-6.9-7-10-2.8-1.7-6.6-1.9-9-1-9.9,3.7-8.5,30.1-8,39,1.1,17.4,1.8,29.3,9,43,11.8,22.3,32.7,32.1,47,39,8.9,4.3,17,6.8,21,8,5.9,1.7,15.5,4.4,28,5,3.3.1,8.7.2,26-2,15.9-2,23.9-3.1,32-5,29.9-7.1,50.7-23.3,54-26,19.8-15.9,30.1-33.6,34-41,15.1-28.6,15-54.9,15-70,0-11-.1-28.6-7-48-3-8.4-5.6-12.4-10-26-2.6-8.3-3.8-13.4-8-21-3.5-6.4-7.2-12.8-14-15-3.2-1-3.9-.1-13,0-13.8.2-15.5-1.8-18,0-4.9,3.6-6.7,5.1-2,29,1.4,6.9,9.2,30.7,11,45,2.9,24.2,4.4,36.2-1,43Z"
          />
          <path
            id="TEP_lower_leg"
            data-name="TEP lower leg"
            class="knee_lat_tep_right2320_cls7"
            d="M389.56,420.59c-6.13,5.41-14.86,4.39-24.94,3.06-24-3.17-30.86-12.87-40.52-9.19-6.34,2.45-7.9,8.37-14.55,24.51-1.04,2.55-12.88,31.04-25.98,53.1-8.52,14.3-18.29,27.37-33.35,37.07-8.42,5.41-14.44,7.25-20.37,5.92-11.01-2.35-15.69-13.99-15.9-14.5-3.12-7.97-.73-15.32,1.04-21.45,5.51-19.91-.21-11.95,7.27-46.98,4.57-21.34,8.31-30.02,9.35-49.02.73-13.89-.83-20.63-5.2-25.53-1.77-1.94-3.53-3.17-16.63-8.17-16.42-6.33-21.92-7.56-31.17-12.25-14.76-7.45-16.21-11.85-16.63-13.28-1.66-5.92.94-13.58,6.23-16.34,1.77-.92,4.47-1.63,14.55,1.02,9.77,2.66,16.63,6.03,19.74,7.15,6.86,2.45,11.22,3.27,28.06,9.19,10.81,3.78,20.89,5.31,32.21,8.17,8.42,2.04,29.3,9.29,70.66,23.49,10.39,3.57,20.57,8.17,31.17,11.23,17.35,5.11,25.56,5.51,29.09,12.25,3.22,6.43,1.25,15.73-4.16,20.53h0Z"
          />
        </g>
      </g>
      <g id="overlays">
        <g
          id="TEP_overlays"
          data-name="TEP overlays"
          class="knee_lat_tep_right2320_cls1"
        >
          <path
            id="TEP_upper_leg_overlay"
            data-name="TEP upper leg overlay"
            class="UpTEP2320Fill"
            style={{
              fill: props.colors.UpTEP2320Fill,
              stroke: props.colors.UpTEP2320Fill,
            }}
            d="M416.02,201.64c-5.5,6.9-10.6,3.3-43,12-13.4,3.6-25.1,7.6-35,11-8.5,2.9-15.7,5.4-25,9-28.2,11-33.6,16.3-39,13-6.9-4.2-1.3-14.9-11-33-1.4-2.6-8.7-16.1-15-15-6.5,1.1-8.3,17.4-12,17-2.4-.3-1.9-6.9-7-10-2.8-1.7-6.6-1.9-9-1-9.9,3.7-8.5,30.1-8,39,1.1,17.4,1.8,29.3,9,43,11.8,22.3,32.7,32.1,47,39,8.9,4.3,17,6.8,21,8,5.9,1.7,15.5,4.4,28,5,3.3.1,8.7.2,26-2,15.9-2,23.9-3.1,32-5,29.9-7.1,50.7-23.3,54-26,19.8-15.9,30.1-33.6,34-41,15.1-28.6,15-54.9,15-70,0-11-.1-28.6-7-48-3-8.4-5.6-12.4-10-26-2.6-8.3-3.8-13.4-8-21-3.5-6.4-7.2-12.8-14-15-3.2-1-3.9-.1-13,0-13.8.2-15.5-1.8-18,0-4.9,3.6-6.7,5.1-2,29,1.4,6.9,9.2,30.7,11,45,2.9,24.2,4.4,36.2-1,43Z"
          />
          <path
            id="TEP_lower_leg_overlay"
            data-name="TEP lower leg overlay"
            class="TEP2320Fill"
            style={{
              fill: props.colors.TEP2320Fill,
              stroke: props.colors.TEP2320Fill,
            }}
            d="M389.61,420.59c-6.13,5.41-14.86,4.39-24.94,3.06-24-3.17-30.86-12.87-40.52-9.19-6.34,2.45-7.9,8.37-14.55,24.51-1.04,2.55-12.88,31.04-25.98,53.1-8.52,14.3-18.29,27.37-33.35,37.07-8.42,5.41-14.44,7.25-20.37,5.92-11.01-2.35-15.69-13.99-15.9-14.5-3.12-7.97-.73-15.32,1.04-21.45,5.51-19.91-.21-11.95,7.27-46.98,4.57-21.34,8.31-30.02,9.35-49.02.73-13.89-.83-20.63-5.2-25.53-1.77-1.94-3.53-3.17-16.63-8.17-16.42-6.33-21.92-7.56-31.17-12.25-14.76-7.45-16.21-11.85-16.63-13.28-1.66-5.92.94-13.58,6.23-16.34,1.77-.92,4.47-1.63,14.55,1.02,9.77,2.66,16.63,6.03,19.74,7.15,6.86,2.45,11.22,3.27,28.06,9.19,10.81,3.78,20.89,5.31,32.21,8.17,8.42,2.04,29.3,9.29,70.66,23.49,10.39,3.57,20.57,8.17,31.17,11.23,17.35,5.11,25.56,5.51,29.09,12.25,3.22,6.43,1.25,15.73-4.16,20.53h0Z"
          />
        </g>
        <path
          id="patella_overlay"
          data-name="patella overlay"
          class="Patella2320Fill"
          style={{
            fill: props.colors.Patella2320Fill,
            stroke: props.colors.Patella2320Fill,
          }}
          d="M474.67,331.14c-2.8-1.2-2.3-3.8-6.7-15.4-5.8-15.5-9.8-18.7-9.3-27.9.2-3.9,1.1-6.8,2-9.8,2.8-8.9,6.1-11.1,11.4-21.5,1.5-3,4.4-8.6,6.8-16.3,2.4-7.8,1.9-9.9,4.8-19.1,3.4-10.6,5.8-13,7.9-14.3.6-.4,5.2-3.1,10-1.6,7.1,2.2,7,11.3,16.4,31.2,5.3,11.2,6.7,11.3,7.7,16.7,1.6,9.3-1.3,16.7-5.9,27.7-7.9,19-18,30.5-20.7,33.5-6.7,7.7-17.6,19.7-24.4,16.8Z"
        />
      </g>
    </svg>
  );
}

export default RechtesKniegelenkTEPlatLiegendLinks2320;
