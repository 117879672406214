import { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useSelector, useDispatch } from "react-redux";
import { MakePostObject } from "../../Utilities/AuthHeader";
import { useKeycloak } from "@react-keycloak/web/lib/useKeycloak";
import { NotificationManager } from "react-notifications";
import { setChangedPrices } from "../../Redux/actions";

const currencyMap = {
  PL: "zł",
  pl: "zł",
  DE: "€",
  de: "€",
  EN: "£",
  en: "£",
  base: "$",
};

const currentLanguage = window.conf.LANG;
const currency = currencyMap[currentLanguage];
const baseUrl = `${window.conf.SERVER_CONFIG.BASE_URL}`;

function PriceListSummary() {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const alteredPrices = useSelector((state) => state.pricelists.changedPrices);

  const [formattedAlteredPrices, setFormattedAlteredPrices] = useState({});
  const [deleteConfirmModalShown, setDeleteConfirmModalShown] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(undefined);

  const saveChanges = () => {
    async function pushUpdates() {
      if (updates.length <= 0) {
        return Promise.resolve(true);
      }
      fetch(
        `${baseUrl}/price_lists/updatePrices`,
        MakePostObject(keycloak, { updates })
      )
        .then((res) => {
          if (res.status === 200) {
            return Promise.resolve(true);
          } else {
            return Promise.reject();
          }
        })
        .catch(() => {
          return Promise.reject();
        });
    }

    async function pushInserts() {
      if (inserts.length <= 0) {
        return Promise.resolve(true);
      }
      fetch(
        `${baseUrl}/price_lists/insertPrices`,
        MakePostObject(keycloak, { inserts })
      )
        .then((res) => {
          if (res.status === 200) {
            return Promise.resolve(true);
          } else {
            return Promise.reject();
          }
        })
        .catch(() => {
          return Promise.reject();
        });
    }

    //collecting data part
    let updates = [];
    let inserts = [];
    Object.keys(formattedAlteredPrices).forEach((jwProcChanges) => {
      formattedAlteredPrices[jwProcChanges].forEach((priceChange) => {
        if (priceChange.id < 1000000) {
          updates.push({
            id: priceChange.id,
            a_id: priceChange.a_id,
            jw_procedure_id: priceChange.jwProcId,
            price: priceChange.price,
          });
        } else {
          inserts.push({
            id: priceChange.id,
            a_id: priceChange.a_id,
            jw_procedure_id: priceChange.jwProcId,
            price: priceChange.price,
          });
        }
      });
    });

    //pushing data part
    let updatesPromise = pushUpdates();
    let insertPromise = pushInserts();
    Promise.all([updatesPromise, insertPromise])
      .then((vals) => {
        dispatch(setChangedPrices({}));
        NotificationManager.success("Saving Pricelists succeded");
      })
      .catch(() => {
        NotificationManager.console.error("Saving Pricelists failed");
      });
  };

  const formatData = () => {
    let gruppedPriceChanges = {};
    Object.values(alteredPrices).forEach((alteredPrice) => {
      if (
        Object.keys(gruppedPriceChanges).includes(
          alteredPrice.jwProcId.toString()
        )
      ) {
        gruppedPriceChanges[alteredPrice.jwProcId].push(alteredPrice);
      } else {
        gruppedPriceChanges[alteredPrice.jwProcId] = [alteredPrice];
      }
    });
    setFormattedAlteredPrices(gruppedPriceChanges);
  };

  useEffect(() => {
    formatData();
  }, []);

  useEffect(() => {
    formatData();
  }, [alteredPrices]);

  return (
    <>
      <Modal
        size="sm"
        show={deleteConfirmModalShown}
        onHide={() => setDeleteConfirmModalShown(false)}
      >
        <ModalHeader className="p-2" closeButton>
          <h5>Confirm Deleting</h5>
        </ModalHeader>
        <ModalBody className="d-flex flex-column">
          {dataToDelete && (
            <>
              <div className="border rounded p-2 d-flex">
                <div className="d-flex flex-column">
                  <div className="text-muted small">
                    {dataToDelete.priceChange.facName}
                  </div>
                  <div className="d-flex align-items-center">
                    <h5 className="text-danger">
                      {dataToDelete.priceChange.oriPrice} {currency}
                    </h5>
                    <i className="fas fa-arrow-right fa-xs mx-2" />
                    <h5 className="text-success">
                      {dataToDelete.priceChange.price} {currency}
                    </h5>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <Button onClick={() => setDeleteConfirmModalShown(false)}>
                  <i className="fas fa-undo fa-sm mr-2" />
                  Go Back
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    let formattedAlteredPricesCopy = {
                      ...formattedAlteredPrices,
                    };
                    formattedAlteredPricesCopy[
                      dataToDelete.jwProcChangesKey
                    ].splice(dataToDelete.changeKey, 1);

                    if (
                      formattedAlteredPricesCopy[dataToDelete.jwProcChangesKey]
                        .length === 0
                    ) {
                      delete formattedAlteredPricesCopy[
                        dataToDelete.jwProcChangesKey
                      ];
                    }

                    setFormattedAlteredPrices(formattedAlteredPricesCopy);
                    setDataToDelete(undefined);
                    setDeleteConfirmModalShown(false);
                  }}
                >
                  <i className="fas fa-trash fa-sm mr-2" />
                  Confirm
                </Button>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
      <div className="d-flex flex-column">
        <h5>Price Changes Summary</h5>
        {Object.keys(formattedAlteredPrices).length === 0 && (
          <h5 className="mt-2 text-danger">Nothing to save</h5>
        )}
        <div
          className="d-flex flex-column"
          style={{ height: "75vh", overflowY: "auto" }}
        >
          {Object.keys(formattedAlteredPrices).map((jwProcChangesKey, key) => (
            <div
              className="d-flex border rounded mb-1"
              key={`jwProcPriceChanges${key}`}
            >
              <div className="border-right p-2" style={{ width: "15%" }}>
                <div className="text-muted small">Procedure Id</div>
                <h5>{formattedAlteredPrices[jwProcChangesKey][0].jwProcId}</h5>
              </div>
              <div className="border-right p-2" style={{ width: "30%" }}>
                <div className="text-muted small">Procedure Description</div>
                <h5>
                  {formattedAlteredPrices[jwProcChangesKey][0].description}
                </h5>
              </div>
              {formattedAlteredPrices[jwProcChangesKey].map(
                (priceChange, changeKey) => (
                  <div
                    className="border-right p-2 d-flex"
                    key={`priceChangeKey${changeKey}`}
                    style={{
                      width: `${
                        55 / formattedAlteredPrices[jwProcChangesKey].length
                      }%`,
                    }}
                  >
                    <div className="d-flex flex-column">
                      <div className="text-muted small">
                        {priceChange.facName}
                      </div>
                      <div className="d-flex align-items-center">
                        <h5 className="text-danger">
                          {priceChange.oriPrice} {currency}
                        </h5>
                        <i className="fas fa-arrow-right fa-xs mx-2" />
                        <h5 className="text-success">
                          {priceChange.price} {currency}
                        </h5>
                      </div>
                    </div>
                    <Button
                      className="ml-auto"
                      onClick={() => {
                        setDataToDelete({
                          jwProcChangesKey,
                          priceChange,
                          changeKey,
                        });
                        setDeleteConfirmModalShown(true);
                      }}
                    >
                      <i className="fas fa-trash fa-sm" />
                    </Button>
                  </div>
                )
              )}
            </div>
          ))}
        </div>
        <Button
          className="mt-2"
          onClick={saveChanges}
          disabled={Object.keys(formattedAlteredPrices).length === 0}
        >
          <i className="fas fa-save fa-sm" /> Save PriceLists Changes
        </Button>
      </div>
    </>
  );
}

export default PriceListSummary;
