import React from "react";
import PatientSearchBox from "../Patients/SearchBox";
import PatRecordBox from "../Patients/PatientRecordBox";
import WorklistBox from "../Worklist/worklistBox";
import AuthorizedElement from "../Utilities/AuthorizedElement";
import keycloak from "../keycloak";
import useWindowSize from "../Utilities/Window/windowSize";
import { useState } from "react";

import "./stylesheets/PatientJournal.scss";
import AuthorizedFunction from "../Utilities/AuthorizedFunction";

const availableViews = [
  {
    hidden: false,
    name: "Search Patient",
    comp: <PatientSearchBox />,
    roles: ["pm_admin", "pm_user", "pm_facility"],
  },
  {
    hidden: false,
    name: "Patient File",
    comp: <PatRecordBox />,
    roles: ["pm_admin", "pm_user", "pm_refphys", "pm_facility", "pm_patient"],
  },
  {
    hidden: window.conf.PATIENT_JOURNAL.WORKLIST_HIDDEN,
    name: "Worklist",
    comp: <WorklistBox />,
    roles: ["pm_admin", "pm_user", "pm_refphys", "pm_facility"],
  },
];

function PatientJournal(props) {
  const { width } = useWindowSize();
  const [selectedMobileView, setSelectedMobileView] = useState(0);

  const DesktopView = (
    <div className="d-flex p-1 " style={{ gap: "5px", height: "100vh" }}>
      <AuthorizedElement style={{ flex: 1 }} roles={availableViews[0].roles}>
        <PatientSearchBox />
      </AuthorizedElement>
      <AuthorizedElement roles={availableViews[1].roles}>
        <PatRecordBox keycloak={keycloak} />
      </AuthorizedElement>
      {!window.conf.PATIENT_JOURNAL.WORKLIST_HIDDEN && (
        <AuthorizedElement roles={availableViews[2].roles}>
          <WorklistBox />
        </AuthorizedElement>
      )}
    </div>
  );

  const allowed_views = availableViews.filter((view) =>
    AuthorizedFunction(view.roles)
  );

  const current_view = allowed_views[selectedMobileView];

  const MobileView = (
    <>
      {allowed_views.length > 1 && (
        <div className="d-flex pt-1 px-1" style={{ gap: "5px" }}>
          {allowed_views.map(
            (view, key) =>
              !view.hidden && (
                <div
                  key={`journaltabskey${key}`}
                  className={`${
                    key === selectedMobileView ? "selected" : ""
                  } tab-selector rounded p-1`}
                  onClick={() => setSelectedMobileView(key)}
                >
                  {view.name}
                </div>
              )
          )}
        </div>
      )}
      <div
        className="d-flex p-1"
        style={{ gap: "5px", flexWrap: "wrap", height: "95vh" }}
      >
        <AuthorizedElement roles={current_view.roles}>
          {current_view.comp}
        </AuthorizedElement>
      </div>
    </>
  );

  return width <= 1199 ? MobileView : DesktopView;
}

export default PatientJournal;
