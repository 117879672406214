import style from "./style.scss";

export default function RechteRippenVdStehend1810(props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.89 578.69">

    <path id="whitebackground" class="rechte_rippen_vd_stehend_links1810_cls1" d="M266.6,34.86c-9.3-.3-12.4,3.2-12.5,8.6-9.9,3.4-21.1,3-33.1-.1-10,.6-13.6,11.4-14.8,10-4.9-5.4-20.7-14.2-29.7-14.5-2.6.3-24-5.5-38.2-9.6-15-4.3-24.5-5.8-29.9-11.5l-8.8,1.4-24.2,3.6c-12.7,4.3-16.7,22-20.5,40l2.5,71.9c11.3-3.1,22.6-2.5,34.1,2.5,2.1-.6,4.3-3.3,6.6-6.9,2.8.4,4.7,1.1,4.3,2.4l-.8,7.2c.3,5.2,2.3,9.5,5.1,13.3l-2.6,11.2c-16.5,16.8-27.2,36.4-18.9,50.2-19.1,21.9-20,43.9-8,57-11.5,15.1-15.2,29.8-10,41.6-16.4,19-17.5,36.5-6.2,52.9-9.1,22.5-10.5,41.6,3.8,57.7-8.7,19.4-5.6,39,8.5,58.8-2.6,10.3-2.1,21.3,2.9,31.2,7,13.8,11.2,27.4,19,25.8,1.3,12.2,5.9,22.7,18.6,28.5l11.8,5.3c8.1-6.7,23.2-15.7,33.5-27.4,6.3-7.1,12.1-14.8,20.2-20.2,10.6-7.1,24.8-8,35.9-14.4,8.6-5,16.4-15.4,25-23.6,4-3.5,15.6-3.3,21-11.2,8.1,13.5,15.4,13.1,29.2,12.5,2.8.8,9.1-442.9,7.1-442.9"/>
    <g id="highlightesrips" class="rechte_rippen_vd_stehend_links1810_cls8">
      <path id="_12thriphighlight" data-name="12thriphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M256.3,463.76c-3.5-1.3-6.7,3.5-18,11-4.5,3-8.4,5-16,9-17.5,9.1-19.2,7-29,13-8.6,5.2-6.3,6.2-23,20-9.9,8.1-20.6,16-22,17-13.8,10-17.6,11.8-21,18-4.6,8.5-5.1,19.5-2,21,1.7.8,3.9-1.2,12-8,17.5-14.7,15.1-12.2,18-15,13.3-12.8,11.8-15.9,21-22,5.9-3.9,11.9-6.1,17-8,10-3.7,13-3.1,20-7,5.6-3.1,9.3-6.5,13-10,9.4-8.8,8-11.1,14-15,9.1-5.9,15.5-2.6,19-8,3.3-5.3,1.1-14.5-3-16Z"/>
      <path id="_11thriphighlight" data-name="11thriphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M257.3,430.76c-2.5-1.4-5.1-.5-10,1-6.3,1.9-12.6,3.6-19,5-4.5,1-10.7,3-23,7-23.3,7.5-34.9,11.3-42,15-10.5,5.4-17.3,11.3-31,23-19.7,16.9-29.5,25.3-34,38-1.2,3.4-7.6,22.7,2,37,4.6,6.9,12.9,12.6,15,11,1.5-1.1-.3-5.2-2-11-.7-2.3-5.9-19.7-5-29,1.5-15.3,21.3-26.2,61-48,2.1-1.2,4.4-2.4,11-6,17.6-9.7,19.8-11.2,26-14,9.1-4.2,7.4-2.3,18-7,13.2-5.8,15.7-8.7,23-9,7.2-.3,10.7,2.4,13,0,2.7-2.9,1.2-10.6-3-13Z"/>
      <path id="connectiontolowerripshighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M276.3,330.76c-5.9-.1-8.1,6.6-18,16-7.1,6.8-17.9,14.8-20,13-2-1.7,2.6-13.9,12-22,8.2-7,15.9-7.2,17-13,.7-3.7-1.5-8.5-5-10-8-3.4-23,10.6-31,22-4.6,6.6-4.7,9-9,12-5.9,4-9.3,1.8-24,4-13.7,2.1-16.1,4.9-23,3-7.1-2-8.9-6.1-12-5-6.3,2.2-8.7,22.3-1,28,1.6,1.2,4.1,2,25-2,14.4-2.8,18.2-4.1,20-2,2.9,3.4-1.6,13.2-8,19-6.1,5.5-13.2,6.5-24,8-7.1,1-11.9,1.7-18,0-7.8-2.2-9.5-6-13-5-7.1,2-11.7,20.9-5,27,4.9,4.4,15.1,1.5,24-1,9.3-2.6,14-6,16-4s.1,8-3,12c-3.9,5.1-10.3,6.8-23,10-9.6,2.4-14.3,3.6-20,3-7.7-.9-10.1-3.7-13-2-5.6,3.2-6.5,19.5,1,26,10.1,8.9,31.2-4.1,34,1,1.9,3.4-5.1,12.7-14,16-7.7,2.8-16,1.1-24-1-7.8-2-11.2-4.8-15-3-7.1,3.3-9.2,19-4,23,3.2,2.5,6.9-1.2,18-1,4.4.1,13.6.2,15,4,1.5,4.2-7.1,11.9-14,16-9.1,5.4-14.5,3.8-17,9-2.7,5.8-1.7,18.2,2,16,5-3,17.9-9.4,32-24,6.3-6.5,13.7-14.1,22-26,10-14.4,10.4-19.5,21-34,3.3-4.5,10.6-14.3,22-25,13.5-12.6,18.3-13,27-24,7-8.8,4.8-9.8,13-22,9.3-13.8,19.7-24,26-30,14.3-13.8,22.2-17.9,21-24-.8-4.2-5.7-8-10-8Z"/>
      <path id="_10thriphighlight" data-name="10thriphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M261.2,381.76c6,3.9,5.4,20.9-2.8,27.3-6.7,5.2-13.5-.5-27.9-.5-18,0-20.4,9.5-54.2,22.2-23.2,8.8-23.3,5.8-38.1,13.1-13.6,6.7-21.5,13.2-36.9,25.9-14.7,12.1-18,17.1-19,23-.2.9-2,12.7,5,20,6.1,6.3,13.3,3.7,16,9,3.1,6-2.3,17.2-8.1,17.9-6,.7-11.3-9.7-14.9-16.9-4.4-8.7-10.7-21.1-8-36,2.5-13.5,11-21.8,23-33,11.4-10.7,22.3-17.5,36-25,56.8-31.2,73.9-18.2,111.8-41.4,5.1-3.1,13.4-8.6,18.1-5.6h0Z"/>
      <path id="_8thriphighlight" data-name="8thriphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M98.3,503.76c-3.2-7-2.2-13.8,3.9-23,7.5-11.1-21.2-8.9-27-21-4.4-9.1.1-19.6,2-24,1.4-3.3,6.4-13.7,28-26,15.2-8.7,19-7.1,46-18,24.1-9.7,23-11.8,36-15,18.4-4.6,15.8-1.5,33-7,15.8-5,21.7-8.4,36-11,14.4-2.7,23.5,0,25-4,2.2-5.6-13.4-18.5-13.4-18.5-101.1,31.6-110,34-110,34-8.8,2.4-16.9,4.4-28.5,8.5-26,9.1-39,13.6-50,25-3.3,3.4-20.4,21-19,46,.9,15,8,25.6,13,32.9,1.4,2,28.8,29.3,25,21.1h0Z"/>
      <path id="_6thriphighlight" data-name="6thriphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M163.3,350.76c-.2-4.4-14-6.1-27-9.9-23.6-7-37-19-46-27-9-8-12-13.1-12.1-18.5-.2-7.2,4.7-12.8,7.1-15.5,7.9-8.9,17.9-11.4,27-14,24.6-7.1,13.2-6.5,56-20,5.2-1.6,14.8-4.4,34-10,24.7-7.2,27.7-7.8,33-8,13.2-.4,21.8,2.9,24-1,1.7-2.9-1.1-8.6-5-12-8.5-7.3-21.5-2.2-58,7-26.3,6.6-21.9,4.5-38,9-23.6,6.6-38.5,10.8-56,21-10.5,6.1-18.7,11-26,21-3.6,4.9-13.9,19.1-11,35,.8,4.2,3.3,12.7,24,29,30.8,24.2,64.6,36.3,68.2,30.8.9-1.5-.8-3.4.5-7.9,1.6-5.5,5.4-6.7,5.3-9h0Z"/>
      <path id="connectionto5highlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M253.3,294.76c3.6,10,8.2,12,7,15-1.8,4.6-12.5-.1-25,6-9,4.4-7.2,8.7-17,13-4.6,2-10,2.7-21,4-12.7,1.5-14.3.6-15,0-6.1-4.9-3.9-22.3,5-28,5-3.2,7.7,0,20-1,6.5-.5,19.6-1.6,30-10,6.5-5.2,9.1-11,12-10,2.1.7,1.6,4.2,4,11Z"/>
      <path id="connectionto4highlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M226.3,247.76c-7.2,2.1-14.1,2.6-19,3-11.7.9-14.7-.6-18,2-6.5,5.1-7.2,20.1-1,25,8.5,6.7,31.2-5,37-8,14.3-7.4,14.1-5.1,19-6,7-1.3,10.2-1,13-4,3.5-3.7,4-10.1,2-15-.6-1.4-2.2-5.4-6-7-6.8-2.8-11.7,5.5-27,10Z"/>
      <path id="connectionto3highlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M262.3,206.76c.3,2.3,1.4,11.2-3.2,14.7-3.3,2.5-6.2-.2-15.8-.7-13-.7-17.8,3.7-22,0-4.1-3.6-3.2-10.8-3-12,.1-1.1.8-6.4,5-10,2.8-2.4,5.1-2.2,19-3,11.3-.6,13.6-1,16,1,3,2.5,3.5,6.6,4,10Z"/>
      <path id="connectionto2highlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M262.3,172.76c-4.6,2.4-7.5-1.5-19-2-10.7-.4-14.6,2.7-17,0-3.2-3.6-.4-13.9,6-18,3.6-2.4,5.7-.9,20-1,11-.1,13.1-1,15,1,4.2,4.3,1.5,16.6-5,20Z"/>
      <path id="connectionto1highlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M246.3,112.76c-1.7,6.1,2.6,10.5,1,12-1.5,1.3-4.9-2.5-12-4-7.6-1.7-10.9,1.2-13-1-3.8-4,1.8-18.2,8-19,2.9-.3,3.6,2.5,10,4,4.5,1,7.2.3,8,2,.7,1.5-1.1,2.8-2,6Z"/>
      <path id="_5thriphighlight" data-name="5thriphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M178.9,322.66c-1.7-8.9,5.1-18.8,5.1-18.8,7,0-15.6-4.6-24.7-6.1-19.4-3.1-32.3-12.4-50-25-13.3-9.5-22.9-16.6-24-28-.8-8.2,3.1-14.6,4-16,7.6-11.9,22.2-12.8,42-17,32.4-6.9,26.8-11.7,67-21,24.3-5.6,36.7-8.3,48-5,5.6,1.6,13.4,5,16,2,2.4-2.8.4-10.1-3.9-14.3-5.6-5.5-14.1-4.8-32.1-2.7-21.4,2.5-32.1,3.8-38,5-13,2.7-33.7,8.2-61,18-32.5,11.7-39.2,17.4-44,23-2.7,3.2-17.8,21.6-13,42,2.9,12.6,14.3,20.7,37,37,9.6,6.9,22.4,13.6,48,27,10.2,5.4,24.9,12.8,27,10,1-1.4-2.3-3.9-3.4-10.1Z"/>
      <path id="_8thriphighlight-2" data-name="8thriphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M116.2,457.66c.9-1-.7-2.9-.2-6.9.8-5.4,4.3-6.4,4.3-9,0-5.8-17.5-6.4-34.1-18.2-5.3-3.8-12.1-8.8-15.9-17.9-1-2.4-5.4-13-1-23,2.8-6.5,10.9-10.8,27-19,13.2-6.8,23.9-11,29-13,5.3-2,11.9-4.6,20.9-7.4,17.6-5.5,28.7-11.4,36.1-10.6,1.1.1-4-6.6-3.4-10,1.5-8.5,7.1-16.7,5.1-18.8-4.1-4.4-24.4,10.3-52.7,21.9-23.1,9.5-27.1,6.6-43,16-9.5,5.6-21.8,12.9-29,28-1.7,3.5-9.6,21-3,40,4.2,12.3,13.8,19.5,33,34,13.5,10.2,24.9,16.1,26.9,13.9h0Z"/>
      <path id="_7thriphighlight" data-name="7thriphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M136.5,418.46c1.3-1.2-.3-3.4.1-9.9.6-7.3,8.9-9,7.7-11.9,0,0-11.9-3.3-20-6-7.5-2.5-12.4-5.6-21-11-2-1.3-8.2-5.2-15.7-11.5-10.5-8.8-15.8-13.3-17.3-19.5-2.2-9.1,3-17.5,4-19,5.3-8.3,12.1-8.6,39-19,19.6-7.5,17.8-8,30-12,9.4-3.1,15.5-4.4,26-9,1.2-.5,20-8.8,19-12-.4-1.3-3.8-.4-10-2-7-1.9-8.9-4.8-13-6-7.3-2.2-14.4,3-21,7-17.5,10.6-17.1,4.1-48,19-15.7,7.6-23.6,11.4-29.1,17.6-2.2,2.4-16,18-12.9,37.4,1.8,11.2,8.3,18,17.7,27.9,5.2,5.5,7.2,6.4,39.6,27.8,12,7.9,22.1,14.7,24.9,12.1h0Z"/>
      <path id="_4thriphighlight" data-name="4thriphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M187.6,277.16c1.4-1.8-3.9-5.6-3.6-12.3.3-6.8,5.8-8.4,5.2-12.1-1-5.8-14.8-5.3-37-13-10.4-3.6-17.6-7.2-23-10-12.8-6.5-18-10.9-20.6-13.2-6.3-5.7-12.5-11.4-12.4-18.8.1-5.7,3.9-10.2,8-14,13.6-12.8,30.2-19.2,50-25,4-1.2,21.9-6.4,46-11,21.8-4.2,32.7-6.2,43-6,8.9.2,17.3,1.3,18-1,.8-2.4-7.3-7.4-13-10-14.4-6.5-32.2-2.8-67,5-36.4,8.1-39.8,8.9-47,12-13.2,5.6-27.7,12-39,27-5.2,6.9-15.5,20.6-12,35,2,8.2,10,14.5,26,27,12.4,9.7,22.7,15.8,29,19.4,7,4.1,13.7,7.2,27,13.6,13.3,6.3,20.9,9.4,22.4,7.4h0Z"/>
      <path id="_3rdriphighlight" data-name="3rdriphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M218.6,206.96c1.2-5.1,5.1-6,4.7-8.2-.9-5-20.7-3.1-46-8-15.2-3-24.2-6.8-34-11-21.2-9.1-23.4-14.4-24-17-2.2-9.2,6.6-18.4,9-21,4.9-5.1,9.5-7.1,24-13,19-7.7,28.5-11.6,30-12,18.5-5,34.8-5.1,40-5,18.8.4,30.5,4.5,38-3,1.1-1.1,3.5-3.5,3-6-1.4-6.8-23.7-8.5-40.2-7.2-5.2.4-10.7,1.7-21.8,4.2-24.1,5.5-40.4,11.8-48,15-3.4,1.4-5.6,2.3-8.7,3.8-10,4.9-21.5,10.5-29.3,20.2-1.6,1.9-14.9,18.4-10,34,3.2,10.2,14.5,15.5,37,26,9.3,4.4,21.2,8.2,45,16,15.4,5,31.7,10.1,34,6,1.5-2.7-4.3-7-2.7-13.8Z"/>
      <path id="_2ndtriphighlight" data-name="2ndtriphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M226.3,161.76c1-4.2,3.4-4.9,3.3-6.7-.2-5.1-18.9-8.7-42.3-13.3-21.1-4.1-28.6-6.1-35-13-1.7-1.8-8.4-9.1-7-17,1.5-8.5,11.4-12.9,21-17,7.6-3.3,12.6-4.4,52-10,19.8-2.8,25.2-3.5,33-1,7.5,2.4,12.3,6.2,15,4,3.3-2.7,1.5-12.9-4-16-3-1.7-5.7-.5-10,0-11.6,1.2-16.6-5.3-27.1-7.5-6.8-1.4-14.2.9-28.9,5.5-17.6,5.5-27.7,8.7-40,16-14.6,8.6-35.7,21.1-35,37,.4,8.9,7.6,15.2,13,20,14.6,12.9,24.8,8.3,56,19,15.6,5.4,33.6,13,36,9,1-1.6-1.2-4.1,0-9Z"/>
      <path id="_1striphighlight" data-name="1striphighlight" class="ribs1810Fill"  style={{
              fill: props.colors.ribs1810Fill,
              stroke: props.colors.ribs1810Fill,
            }} d="M222.3,111.76c1.6-6.7,8.5-8.4,8-11-.8-4-17.1.2-24-8-5-5.9-2.5-15.3-2-17,3.8-13,18.8-18,25-20,5.5-1.8,16.3-5.4,27-1,7.7,3.1,11,8.6,15,7,3.5-1.3,5.5-7.1,4-11-2.6-6.8-15.5-6.9-20-7-7.8-.1-7.9,1.9-16,2-11.8.2-14-4-21-2-5.6,1.6-5.7,4.7-14,11-10.2,7.8-13.5,5.8-20,11-8.9,7.2-16.7,22.1-12,35,3.9,10.7,14.6,15,22,18,10.8,4.4,25.3,5.9,28,1,1.2-2.2-1-3.8,0-8Z"/>
      <path id="softpart" class="soft1810Fill"  style={{
              fill: props.colors.soft1810Fill,
              stroke: props.colors.soft1810Fill,
            }} d="M34.37,575.8l.63-11.39c.27-6.86.6-17.19.63-29.75.07-25.75-1.12-43.16-1.9-57.59-1.41-26.09-1.57-51.99-1.9-103.8-.1-16.57-.01-22.12-.22-56.86-.13-21.33-.2-32.76-.41-48.2-.54-40.35-1.11-40.36-1.27-65.82-.29-46.4,1.61-43.51,0-62.66-2.57-30.7-3.86-46.04-9.49-62.03-1.61-4.57-3.64-17.39-7.59-43.04-.41-2.65-1.18-8.13,1.9-12.66,2.24-3.31,6.18-5.59,13.92-6.96,11.81-2.1,16.37.78,28.48,1.27,18.57.75,32.63-5,40.51-8.23,5.22-2.14,13.74-6.12,13.29-7.59-.38-1.25-6.96.04-25.95,2.53-12.44,1.63-18.65,2.44-21.52,2.53-15.19.46-17.26-2.9-31.65-1.9-8.15.56-14.57,1.09-20.89,5.7-1.56,1.13-7.79,5.8-10.13,13.92-2.21,7.67.43,13.64,3.16,22.78,3.08,10.31,4.09,21.12,6.33,31.65,5.96,28.08,9.15,42.08,10.13,59.49.52,9.26-.26,6.01-.63,38.61-.18,16.02-.11,27.64,0,45.57.16,25.32.63,44.33.63,55.7,0,0,.74,39.97,1.27,87.97.1,8.7.27,40.82.63,105.06.12,21.1.99,42.2.63,63.29-.13,7.81.72,15.61.63,23.42-.14,12.32-.98,19.77,2.53,21.52,2.29,1.14,5.56-.6,8.23-2.53Z"/>
    </g>
    <g id="rips">
      <path id="_12thrip" data-name="12thrip" class="rechte_rippen_vd_stehend_links1810_cls3" d="M256.4,463.56c-3.5-1.3-6.7,3.5-18,11-4.5,3-8.4,5-16,9-17.5,9.1-19.2,7-29,13-8.6,5.2-6.3,6.2-23,20-9.9,8.1-20.6,16-22,17-13.8,10-17.6,11.8-21,18-4.6,8.5-5.1,19.5-2,21,1.7.8,3.9-1.2,12-8,17.5-14.7,15.1-12.2,18-15,13.3-12.8,11.8-15.9,21-22,5.9-3.9,11.9-6.1,17-8,10-3.7,13-3.1,20-7,5.6-3.1,9.3-6.5,13-10,9.4-8.8,8-11.1,14-15,9.1-5.9,15.5-2.6,19-8,3.3-5.3,1.1-14.5-3-16Z"/>
      <path id="_11thrip" data-name="11thrip" class="rechte_rippen_vd_stehend_links1810_cls6" d="M257.4,430.56c-2.5-1.4-5.1-.5-10,1-6.3,1.9-12.6,3.6-19,5-4.5,1-10.7,3-23,7-23.3,7.5-34.9,11.3-42,15-10.5,5.4-17.3,11.3-31,23-19.7,16.9-29.5,25.3-34,38-1.2,3.4-7.6,22.7,2,37,4.6,6.9,12.9,12.6,15,11,1.5-1.1-.3-5.2-2-11-.7-2.3-5.9-19.7-5-29,1.5-15.3,21.3-26.2,61-48,2.1-1.2,4.4-2.4,11-6,17.6-9.7,19.8-11.2,26-14,9.1-4.2,7.4-2.3,18-7,13.2-5.8,15.7-8.7,23-9,7.2-.3,10.7,2.4,13,0,2.7-2.9,1.2-10.6-3-13Z"/>
      <path id="connectiontolowerrips" class="rechte_rippen_vd_stehend_links1810_cls3" d="M276.4,330.56c-5.9-.1-8.1,6.6-18,16-7.1,6.8-17.9,14.8-20,13-2-1.7,2.6-13.9,12-22,8.2-7,15.9-7.2,17-13,.7-3.7-1.5-8.5-5-10-8-3.4-23,10.6-31,22-4.6,6.6-4.7,9-9,12-5.9,4-9.3,1.8-24,4-13.7,2.1-16.1,4.9-23,3-7.1-2-8.9-6.1-12-5-6.3,2.2-8.7,22.3-1,28,1.6,1.2,4.1,2,25-2,14.4-2.8,18.2-4.1,20-2,2.9,3.4-1.6,13.2-8,19-6.1,5.5-13.2,6.5-24,8-7.1,1-11.9,1.7-18,0-7.8-2.2-9.5-6-13-5-7.1,2-11.7,20.9-5,27,4.9,4.4,15.1,1.5,24-1,9.3-2.6,14-6,16-4s.1,8-3,12c-3.9,5.1-10.3,6.8-23,10-9.6,2.4-14.3,3.6-20,3-7.7-.9-10.1-3.7-13-2-5.6,3.2-6.5,19.5,1,26,10.1,8.9,31.2-4.1,34,1,1.9,3.4-5.1,12.7-14,16-7.7,2.8-16,1.1-24-1-7.8-2-11.2-4.8-15-3-7.1,3.3-9.2,19-4,23,3.2,2.5,6.9-1.2,18-1,4.4.1,13.6.2,15,4,1.5,4.2-7.1,11.9-14,16-9.1,5.4-14.5,3.8-17,9-2.7,5.8-1.7,18.2,2,16,5-3,17.9-9.4,32-24,6.3-6.5,13.7-14.1,22-26,10-14.4,10.4-19.5,21-34,3.3-4.5,10.6-14.3,22-25,13.5-12.6,18.3-13,27-24,7-8.8,4.8-9.8,13-22,9.3-13.8,19.7-24,26-30,14.3-13.8,22.2-17.9,21-24-.8-4.2-5.7-8-10-8Z"/>
      <path id="_10thrip" data-name="10thrip" class="rechte_rippen_vd_stehend_links1810_cls6" d="M261.3,381.56c6,3.9,5.4,20.9-2.8,27.3-6.7,5.2-13.5-.5-27.9-.5-18,0-20.4,9.5-54.2,22.2-23.2,8.8-23.3,5.8-38.1,13.1-13.6,6.7-21.5,13.2-36.9,25.9-14.7,12.1-18,17.1-19,23-.2.9-2,12.7,5,20,6.1,6.3,13.3,3.7,16,9,3.1,6-2.3,17.2-8.1,17.9-6,.7-11.3-9.7-14.9-16.9-4.8-8.3-12.7-19.5-8-36,2.5-13.5,11-21.8,23-33,11.4-10.7,22.3-17.5,36-25,56.8-31.2,73.9-18.2,111.8-41.4,5.1-3.1,13.4-8.6,18.1-5.6h0Z"/>
      <path id="_8thrip" data-name="8thrip" class="rechte_rippen_vd_stehend_links1810_cls6" d="M98.4,503.56c-3.2-7-2.2-13.8,3.9-23,7.5-11.1-21.2-8.9-27-21-4.4-9.1.1-19.6,2-24,1.4-3.3,6.4-13.7,28-26,15.2-8.7,19-7.1,46-18,24.1-9.7,23-11.8,36-15,18.4-4.6,15.8-1.5,33-7,15.8-5,21.7-8.4,36-11,14.4-2.7,23.5,0,25-4,2.2-5.6-13.4-18.5-13.4-18.5-101.1,31.6-110,34-110,34-8.8,2.4-16.9,4.4-28.5,8.5-26,9.1-39,13.6-50,25-3.3,3.4-20.4,21-19,46,.9,15,8,25.6,13,32.9,1.4,2,28.8,29.3,25,21.1h0Z"/>
      <path id="_6thrip" data-name="6thrip" class="rechte_rippen_vd_stehend_links1810_cls6" d="M163.4,350.56c-.2-4.4-14-6.1-27-9.9-23.6-7-37-19-46-27-9-8-12-13.1-12.1-18.5-.2-7.2,4.7-12.8,7.1-15.5,7.9-8.9,17.9-11.4,27-14,24.6-7.1,13.2-6.5,56-20,5.2-1.6,14.8-4.4,34-10,24.7-7.2,27.7-7.8,33-8,13.2-.4,21.8,2.9,24-1,1.7-2.9-1.1-8.6-5-12-8.5-7.3-21.5-2.2-58,7-26.3,6.6-21.9,4.5-38,9-23.6,6.6-38.5,10.8-56,21-10.5,6.1-18.7,11-26,21-3.6,4.9-13.9,19.1-11,35,.8,4.2,3.3,12.7,24,29,30.8,24.2,64.6,36.3,68.2,30.8.9-1.5-.8-3.4.5-7.9,1.6-5.5,5.4-6.7,5.3-9h0Z"/>
      <path id="connectionto5" class="rechte_rippen_vd_stehend_links1810_cls3" d="M253.4,294.56c3.6,10,8.2,12,7,15-1.8,4.6-12.5-.1-25,6-9,4.4-7.2,8.7-17,13-4.6,2-10,2.7-21,4-12.7,1.5-14.3.6-15,0-6.1-4.9-3.9-22.3,5-28,5-3.2,7.7,0,20-1,6.5-.5,19.6-1.6,30-10,6.5-5.2,9.1-11,12-10,2.1.7,1.6,4.2,4,11Z"/>
      <path id="connectionto4" class="rechte_rippen_vd_stehend_links1810_cls3" d="M226.4,247.56c-7.2,2.1-14.1,2.6-19,3-11.7.9-14.7-.6-18,2-6.5,5.1-7.2,20.1-1,25,8.5,6.7,31.2-5,37-8,14.3-7.4,14.1-5.1,19-6,7-1.3,10.2-1,13-4,3.5-3.7,4-10.1,2-15-.6-1.4-2.2-5.4-6-7-6.8-2.8-11.7,5.5-27,10Z"/>
      <path id="connectionto3" class="rechte_rippen_vd_stehend_links1810_cls3" d="M262.4,206.56c.3,2.3,1.4,11.2-3.2,14.7-3.3,2.5-6.2-.2-15.8-.7-13-.7-17.8,3.7-22,0-4.1-3.6-3.2-10.8-3-12,.1-1.1.8-6.4,5-10,2.8-2.4,5.1-2.2,19-3,11.3-.6,13.6-1,16,1,3,2.5,3.5,6.6,4,10Z"/>
      <path id="connectionto2" class="rechte_rippen_vd_stehend_links1810_cls3" d="M262.4,172.56c-4.6,2.4-7.5-1.5-19-2-10.7-.4-14.6,2.7-17,0-3.2-3.6-.4-13.9,6-18,3.6-2.4,5.7-.9,20-1,11-.1,13.1-1,15,1,4.2,4.3,1.5,16.6-5,20Z"/>
      <path id="connectionto1" class="rechte_rippen_vd_stehend_links1810_cls3" d="M246.4,112.56c-1.7,6.1,2.6,10.5,1,12-1.5,1.3-4.9-2.5-12-4-7.6-1.7-10.9,1.2-13-1-3.8-4,1.8-18.2,8-19,2.9-.3,3.6,2.5,10,4,4.5,1,7.2.3,8,2,.7,1.5-1.1,2.8-2,6Z"/>
      <path id="_5thrip" data-name="5thrip" class="rechte_rippen_vd_stehend_links1810_cls6" d="M179,322.46c-1.7-8.9,5.1-18.8,5.1-18.8,7,0-15.6-4.6-24.7-6.1-19.4-3.1-32.3-12.4-50-25-13.3-9.5-22.9-16.6-24-28-.8-8.2,3.1-14.6,4-16,7.6-11.9,22.2-12.8,42-17,32.4-6.9,26.8-11.7,67-21,24.3-5.6,36.7-8.3,48-5,5.6,1.6,13.4,5,16,2,2.4-2.8.4-10.1-3.9-14.3-5.6-5.5-14.1-4.8-32.1-2.7-21.4,2.5-32.1,3.8-38,5-13,2.7-33.7,8.2-61,18-32.5,11.7-39.2,17.4-44,23-2.7,3.2-17.8,21.6-13,42,2.9,12.6,14.3,20.7,37,37,9.6,6.9,22.4,13.6,48,27,10.2,5.4,24.9,12.8,27,10,1-1.4-2.3-3.9-3.4-10.1Z"/>
      <path id="_8thrip-2" data-name="8thrip" class="rechte_rippen_vd_stehend_links1810_cls6" d="M116.3,457.46c.9-1-.7-2.9-.2-6.9.8-5.4,4.3-6.4,4.3-9,0-5.8-17.5-6.4-34.1-18.2-5.3-3.8-12.1-8.8-15.9-17.9-1-2.4-5.4-13-1-23,2.8-6.5,10.9-10.8,27-19,13.2-6.8,23.9-11,29-13,5.3-2,11.9-4.6,20.9-7.4,17.6-5.5,28.7-11.4,36.1-10.6,1.1.1-4-6.6-3.4-10,1.5-8.5,7.1-16.7,5.1-18.8-4.1-4.4-24.4,10.3-52.7,21.9-23.1,9.5-27.1,6.6-43,16-9.5,5.6-21.8,12.9-29,28-1.7,3.5-9.6,21-3,40,4.2,12.3,13.8,19.5,33,34,13.5,10.2,24.9,16.1,26.9,13.9h0Z"/>
      <path id="_7thrip" data-name="7thrip" class="rechte_rippen_vd_stehend_links1810_cls6" d="M136.6,418.26c1.3-1.2-.3-3.4.1-9.9.6-7.3,8.9-9,7.7-11.9,0,0-11.9-3.3-20-6-7.5-2.5-12.4-5.6-21-11-2-1.3-8.2-5.2-15.7-11.5-10.5-8.8-15.8-13.3-17.3-19.5-2.2-9.1,3-17.5,4-19,5.3-8.3,12.1-8.6,39-19,19.6-7.5,17.8-8,30-12,9.4-3.1,15.5-4.4,26-9,1.2-.5,20-8.8,19-12-.4-1.3-3.8-.4-10-2-7-1.9-8.9-4.8-13-6-7.3-2.2-14.4,3-21,7-17.5,10.6-17.1,4.1-48,19-15.7,7.6-23.6,11.4-29.1,17.6-2.2,2.4-16,18-12.9,37.4,1.8,11.2,8.3,18,17.7,27.9,5.2,5.5,7.2,6.4,39.6,27.8,12,7.9,22.1,14.7,24.9,12.1h0Z"/>
      <path id="_4thrip" data-name="4thrip" class="rechte_rippen_vd_stehend_links1810_cls6" d="M187.7,276.96c1.4-1.8-3.9-5.6-3.6-12.3.3-6.8,5.8-8.4,5.2-12.1-1-5.8-14.8-5.3-37-13-10.4-3.6-17.6-7.2-23-10-12.8-6.5-18-10.9-20.6-13.2-6.3-5.7-12.5-11.4-12.4-18.8.1-5.7,3.9-10.2,8-14,13.6-12.8,30.2-19.2,50-25,4-1.2,21.9-6.4,46-11,21.8-4.2,32.7-6.2,43-6,8.9.2,17.3,1.3,18-1,.8-2.4-7.3-7.4-13-10-14.4-6.5-32.2-2.8-67,5-36.4,8.1-39.8,8.9-47,12-13.2,5.6-27.7,12-39,27-5.2,6.9-15.5,20.6-12,35,2,8.2,10,14.5,26,27,12.4,9.7,22.7,15.8,29,19.4,7,4.1,13.7,7.2,27,13.6,13.3,6.3,20.9,9.4,22.4,7.4h0Z"/>
      <path id="_3rdrip" data-name="3rdrip" class="rechte_rippen_vd_stehend_links1810_cls6" d="M218.7,206.76c1.2-5.1,5.1-6,4.7-8.2-.9-5-20.7-3.1-46-8-15.2-3-24.2-6.8-34-11-21.2-9.1-23.4-14.4-24-17-2.2-9.2,6.6-18.4,9-21,4.9-5.1,9.5-7.1,24-13,19-7.7,28.5-11.6,30-12,18.5-5,34.8-5.1,40-5,18.8.4,30.5,4.5,38-3,1.1-1.1,3.5-3.5,3-6-1.4-6.8-23.7-8.5-40.2-7.2-5.2.4-10.7,1.7-21.8,4.2-24.1,5.5-40.4,11.8-48,15-3.4,1.4-5.6,2.3-8.7,3.8-10,4.9-21.5,10.5-29.3,20.2-1.6,1.9-14.9,18.4-10,34,3.2,10.2,14.5,15.5,37,26,9.3,4.4,21.2,8.2,45,16,15.4,5,31.7,10.1,34,6,1.5-2.7-4.3-7-2.7-13.8Z"/>
      <path id="_2ndtrip" data-name="2ndtrip" class="rechte_rippen_vd_stehend_links1810_cls6" d="M226.4,161.56c1-4.2,3.4-4.9,3.3-6.7-.2-5.1-18.9-8.7-42.3-13.3-21.1-4.1-28.6-6.1-35-13-1.7-1.8-8.4-9.1-7-17,1.5-8.5,11.4-12.9,21-17,7.6-3.3,12.6-4.4,52-10,19.8-2.8,25.2-3.5,33-1,7.5,2.4,12.3,6.2,15,4,3.3-2.7,1.5-12.9-4-16-3-1.7-5.7-.5-10,0-11.6,1.2-16.6-5.3-27.1-7.5-6.8-1.4-14.2.9-28.9,5.5-17.6,5.5-27.7,8.7-40,16-14.6,8.6-35.7,21.1-35,37,.4,8.9,7.6,15.2,13,20,14.6,12.9,24.8,8.3,56,19,15.6,5.4,33.6,13,36,9,1-1.6-1.2-4.1,0-9Z"/>
      <path id="_1strip" data-name="1strip" class="rechte_rippen_vd_stehend_links1810_cls6" d="M222.4,111.56c1.6-6.7,8.5-8.4,8-11-.8-4-17.1.2-24-8-5-5.9-2.5-15.3-2-17,3.8-13,18.8-18,25-20,5.5-1.8,16.3-5.4,27-1,7.7,3.1,11,8.6,15,7,3.5-1.3,5.5-7.1,4-11-2.6-6.8-15.5-6.9-20-7-7.8-.1-7.9,1.9-16,2-11.8.2-14-4-21-2-5.6,1.6-5.7,4.7-14,11-10.2,7.8-13.5,5.8-20,11-8.9,7.2-16.7,22.1-12,35,3.9,10.7,14.6,15,22,18,10.8,4.4,25.3,5.9,28,1,1.2-2.2-1-3.8,0-8Z"/>
      <path id="soft" class="rechte_rippen_vd_stehend_links1810_cls4" d="M29.31,568.2c-.13-10.44-.34-26.03-.63-44.94-.77-49.89-1.33-70.08-1.9-102.53-.22-12.46-.57-47.72-1.27-117.72-.36-36.29-1.99-72.58-1.27-108.86.85-42.8,2.77-55.26-1.9-80.38q-10.11-54.37-10.13-54.43C6.8,35.93,3.04,25.74,9.05,18.2c6.75-8.46,21.94-8.67,35.44-8.86,11.7-.16,14.66,1.86,25.32,1.27,12.37-.69,22.21-4.2,28.48-6.96"/>
    </g>
    <g id="Sternum_and_Spine" data-name="Sternum and Spine">
      <path id="parttoclavicula" class="rechte_rippen_vd_stehend_links1810_cls6" d="M275.9,41.56c0,3.9-4.8,7-10.8,7s-10.5-3.1-10.8-7c-.3-4,4.7-7,10.8-7s10.8,3.1,10.8,7Z"/>
      <path id="partofspine7" class="rechte_rippen_vd_stehend_links1810_cls6" d="M292.7,50.86c-9.5.2-16.9,2.7-16.9,5.6s7.8,5.4,17.6,5.6"/>
      <path id="middlepartsternum" class="rechte_rippen_vd_stehend_links1810_cls3" d="M291.5,97.06c-8.1,1.5-17.1-4.5-25.1.5-5,3-3,11-9,13-3,1-7-1-10,1-4,5-2,13,2,17,12,11,26,26,17,43-7,13-6,27-2,41,2,8-5,14-6,22-1,5,3,10,3,15s-3,10-6,15c-1,1,0,3-1,4-13,19,4,37,12,53,1,2,4,3,5,5,6,5,15,8,22.6,8"/>
      <line id="partofspine6" class="rechte_rippen_vd_stehend_links1810_cls3" x1="263.4" y1="102.56" x2="257.8" y2="86.06"/>
      <path id="partofspine5" class="rechte_rippen_vd_stehend_links1810_cls3" d="M287.9,440.96c-7-.6-14.5-.4-20.2,4.4-9.5,8-10.3,21.3-5.8,32.1,5,12.3,17,12.6,28.6,10.8"/>
      <path id="partofspine4" class="rechte_rippen_vd_stehend_links1810_cls3" d="M288.6,395.26c-10.2.3-18.2,5.3-25.2,11.3-7,7-9,21,0,27,9,0,15-8,24.4-9"/>
      <path id="partofspine3" class="rechte_rippen_vd_stehend_links1810_cls3" d="M294.1,353.06c-11.7,2.5-28.7,2.5-33.7,15.5-3,7,0,17,7,19,7,3,14-1,21.2-4.6"/>
      <line id="partofspine2" class="rechte_rippen_vd_stehend_links1810_cls3" x1="261.4" y1="453.56" x2="263.4" y2="433.76"/>
      <line id="partofspine1" class="rechte_rippen_vd_stehend_links1810_cls3" x1="267.4" y1="387.56" x2="266.1" y2="404.26"/>
      <path id="lastpartsternum" class="rechte_rippen_vd_stehend_links1810_cls3" d="M294.1,334.56c-6.7.1-9.7-3.4-11.7-1.9-1.7,1.2-.5,4.1.8,13.5,1.3,9.5.7,10.7,2.2,15.4,1,3.1,3.6,11,7,11,.6,0,1.2-.3,1.8-.7"/>
      <path id="firstpartsternum" class="rechte_rippen_vd_stehend_links1810_cls3" d="M291.6,46.16c-7.7.2-15,3-21.1,7.6-9.3,7-12.8,23.6-1.7,31.1,1.9,1.3,4.2,1.5,6.4,1.7,5.4.4,10.9.2,16.3.1"/>
    </g>
    <g id="Scapula_shoulder_clavicula" data-name="Scapula,shoulder,clavicula">
      <path id="upperarm" class="rechte_rippen_vd_stehend_links1810_cls4" d="M55.7,62.46c2.1-1.3,3.8-2.3,5.1-3,5.7-3.1,8.6-3.1,12.7-2.9,2.2.1,4.1-.3,12.4,2.4,7.4,2.4,7.9,2.7,10.5,4.2,2.7,1.6,4.8,2.5,7.8,5.7,3.3,3.5,2.9,5.1,4.3,8.3,1.2,2.5,1.5,5.2,2.1,10.3.7,6.3,1.1,9.4.8,12.8-.6,7.8-3.3,13.6-4.1,15.2-2,4.1-2.4,3-7.4,10.7-4.9,7.5-5.5,9.6-8.2,10.7-1.4.5-.8-1.7-9.9-2.9-6-1.7-11-1.4-14.4-1.2-3.8.2-6.8.8-9.8,1.6"/>
      <path id="scapula3" class="rechte_rippen_vd_stehend_links1810_cls4" d="M223.4,120.46c-6.4-3.9-5.5-6.3-9.6-9.4-3.5-2.6-8.5-6.4-14.4-11.9-4.3-4.1-7.2-7.2-11.9-12.4-9.4-10.3-9.2-11.2-14.4-16.1-4.4-4.2-8.6-8.1-15.2-11.5-4.8-2.5-5-1.6-20.2-7-8.2-2.9-12.2-4.6-14-5.4-.9-.4-6.7-2.9-14-7-4.9-2.8-7.3-4.2-8.2-5.8-3.1-5.8,2.2-12.8-.4-14.8-1.2-1-2.6.4-7.8,1.2-5.3.8-5.8-.3-10.7.4-2.9.4-7.3,1-11.5,4.1-1.8,1.3-5.1,3.7-6.2,8.2-1.3,4.8.8,8.9,1.6,10.3,2.4,4.2,6.4,6,8.6,7,20.6,9.1,26.1,9.3,34.2,15.2,6.6,4.8,10.8,10.2,12.4,12.4.8,1.1,4.5,6.1,7.8,13.6,2.7,6.2,4.1,11.8,4.9,16.1"/>
      <path id="clavicula" class="rechte_rippen_vd_stehend_links1810_cls4" d="M260.7,107.46c-17.7-10.3-32.8-19.1-50.3-29.5-14.8-12.2-27.7-18.8-36.7-22.6-1.2-.5-7.6-2.9-20.6-7.8-19.4-7.3-20.4-7.5-22.2-7.8-2.8-.4-10.1-1.1-18.9-4.1-2-.7-3.9-1.4-5.4-3.3-3.7-4.8-1.8-13.7,1.6-14.8,1.4-.4,2.2.7,7,3.3,0,0,4.1,2.2,9.9,4.5,2.1.8,3.7,1.3,5.4,1.7,1.9.5,4,.9,6.9,1.6,10.2,2.5,9.3,3.6,18.5,5.8,8.2,2,9.1,1.2,20.2,3.7,5.8,1.3,8.7,2,11.9,3.3,3.9,1.6,6.9,3.4,16.1,9.9,10.6,7.5,10.4,7.9,15.2,11.1,2.9,1.9,6.1,3.9,18.5,10.3,6.3,3.2,21,7.2,31,12.1"/>
      <path id="scapula2" class="rechte_rippen_vd_stehend_links1810_cls4" d="M177.4,320.36c2.1-.4,5.6-1.4,8.6-4.1,2.4-2.1,4.5-5.1,7-14.4,2-7.2,2.2-10.7,3.3-16.1,1.7-8.2,3.5-12,6.2-20.2,5.5-16.4,3.5-16.9,9.1-35.8,4.5-15.4,5.4-13.7,11.1-32.5,4.8-15.9,4.2-17.4,7.8-25.5,3.7-8.4,5-8.3,13.2-24.3,6.4-12.6,7.8-16.9,8.6-21.4,1.1-6.1.9-11.3.8-16.5-.2-6.2-.4-10.4-2.1-15.6-1.5-4.3-3.9-11.7-9.1-12.8-1.9-.4-4.4.8-9.5,3.3-11.8,5.7-12.1,5.9-13.6,6.6-4.2,1.9-9.7,4.4-16.9,5.8-3.7.7-2.4,0-13.6,1.2-5.8.6-9.5,1.1-15.2,1.2-9.7.3-10.8-1-11.1-1.6-1.3-2.3,2.5-4.8,3.7-10.7,1.3-6.8-1.9-12.7-3.3-15.2-1.3-2.3-5.2-9.4-13.2-11.9-4.4-1.4-8-.7-15.2.8-4.7,1-9.5,2.1-14,5.8-2,1.6-4.5,3.7-5.4,7.4-.2.7-1.5,5.8,1.6,9.1,2.1,2.3,5.2,2.4,6.6,2.5,2.5.1,3.2-.6,9.1-2.1,3.4-.8,5.1-1.2,6.6-1.2,2.1,0,5.3.7,9.1,3.7"/>
      <path id="scapula1" class="rechte_rippen_vd_stehend_links1810_cls4" d="M129.1,73.16c-1.8,0-4.5.4-7.4,1.6-1.3.5-6,2.6-12.8,11.9-4.8,6.6-3.4,6.8-8.2,13.2-3.6,4.8-5.4,6.1-7.8,10.7-1.7,3.3-3.3,6.5-2.5,10.3.8,4.1,3.8,6.8,4.1,7,3.2,2.7,6,2,7.4,4.5,1.1,2,.3,4.1,0,5.8-1.3,6.5,3.3,11.9,8.2,20.2,4,6.7,6.1,11.9,9.5,20.2,4,9.9,3.4,11.8,8.6,30.1,4.3,15,4.6,13.3,8.6,27.6,4.6,16.1,6.8,24.2,7.4,30.9.9,10.2-.4,14.9,3.3,22.2,2,4,3.5,4.9,7.4,12.4,2.7,5.2,3.1,7,5.8,10.7,4.4,6.1,7.8,7.6,9.5,8.2,2,.7,7.9,0,9.1,0"/>
      <path id="borderjointsocket" class="rechte_rippen_vd_stehend_links1810_cls4" d="M117.2,77.56c14.4,17.8-3.1,51.5-15.3,54.8"/>
    </g>
  </svg>
  );
}
