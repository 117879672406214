import style from "./style.scss";

function HalswirbelsauleMitAngestellterKassetteLatLiegend1616(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 314.81 426.92">

<g id="Backround">
  <path id="backround1" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls6" d="M149.12,422.22c-22.64-7.6-25.67-36.48-45.84-97.62-22.67-68.71-27.22-57.59-34.52-93.84-5.73-28.44-6.36-52.31-20.49-57.71-13.67-5.22-24.43,12.76-36.35,7.96C-1.36,175.66,2.87,149.68.81,74.35-.26,35.44-1.94,22.03,8.36,11.38,22.73-3.49,48.02-.13,62.83,1.55c116.3,13.14,205.5-7.82,208.18,9.83,1.09,7.16-13.73,9.59-36.67,39.79-11.89,15.64-15.43,24.89-16.72,33.44-2.11,14,2.07,24.73,4.31,31.82,10.89,34.35-3.96,44.79,1.62,79.28,8.48,52.4,54.05,98.11,63.64,107.33,6.21,5.97,14.39,13.03,20.41,25.89,9.37,20.01,7.44,41.12,6.02,56.63-1.66,18.14-2.49,27.21-6.02,31.82-17.07,22.33-57.98-2.65-137.98,5.39-8.82.89-13.72,1.73-20.49-.54h-.01Z"/>
</g>
<g id="Cervicalspinelat">
  <g id="Overview">
    <path id="outlines1" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M149.96,415.92c-7.35-5.93-8.33-12.48-6.33-19.33l-13.97-39.8c-6.7-2.13-8.98-8.47-7.98-17.91l-7.36-29.17c-9.26-5.46-11.12-12.1-8.54-19.44l-1.33-30.97c-11.08-1.47-12.89-8.93-11.85-18.24l-2.25-26.47c-4.46-6.45-5.61-15.16-4.21-25.61.89-19.7-13.59-29.11-6.11-48.78l3.16-27.95-3.16-6.68c-7.43-13.58-7.13-25.75-3-37.23l55.98,10.8c2,8.05,1.02,16.05-2.03,24l-11.97,29.47c2.82,6.22,2.93,12.34,0,18.34v30.57c4.1,5.71,4.67,12.4,2.68,17.77l5.85,28.71c3.23,2.08,4.81,9.32,5.47,19.41l9.11,27.38c5.95-.19,10.29,2.59,11.54,11.03l9.27,39.71c4.75,2.56,6.2,6.78,2.96,13.38l18.95,38.27c4.7,4.74,7.85,9.59,8.93,14.58l.66,1.66"/>
    <path id="partofskull2" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M263.5,11.82l-27.29,14.62c-2.86,1.53-7.43,4.21-10.12,6.01-10.95,7.32-22.76,10.98-35.62,10.04-1.97-.14-3.95.06-5.86.56-4.67,1.22-9.61,1.88-14.96,1.72-1.67-.05-3.34.2-4.93.72-4.74,1.54-9.56,2.58-14.6,2.45-2.48-.07-4.95.44-7.24,1.42-6.54,2.78-12.96,5.16-18.53,4.7-3.2.1-6.74-2.59-10.41-6.4-2.15-2.24-5.13-3.43-8.24-3.41-5.42.03-10.02-1.43-13.1-5.68-1.76-2.36-3.05-4.85-3.63-7.52-.51-2.36-1.99-4.37-4.09-5.57-2.75-1.57-4.61-3.51-6.11-5.59"/>
    <path id="partofskull1" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M6.79,173.44c7.14-5.87,14.38-10.86,21.72-15.04,13.77-7.3,25.55-17.33,28.1-39.95,1.37-19.05,3.91-36.77,8.96-51.65,5.16-22.78,13.97-31.14,10.78-47.09"/>
    <path id="partofspine22" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M168.07,408.43c1.8-8.13,4.7-16.18,20.09-23.24,6.2-.71,9.69-3.28,7.44-9.79-1.06-3.08-2.05-6.19-2.64-9.39-1.57-8.49-.48-12.53,4.45-10.14,1.85.9,3.69,1.8,5.6,2.55l13.52,5.34,10.37,18.91-58.83,25.75h0Z"/>
    <path id="partofspine21" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M155.35,344.99c.46-4.11,2.93-7.96,7.6-11.52,5.62-1.83,7-4.77,6.53-8.2-2.41-4.17-3.92-7.76-4.16-10.53-.21-2.41,2.62-3.85,4.46-2.28,7.57,6.46,14.65,11.18,21.12,13.73l-6.04,9.11-29.5,9.7h-.01Z"/>
    <path id="partofspine20" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M139.69,351.01c1.92-7.47,3.97-14.28,7.09-15.27,6.12-2.55,11.17,3.12,16.16,9.19l-23.25,6.08Z"/>
    <path id="partofspine19" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M148.8,346.69c.52-4.83,1.49-9.14,3.6-12.09,1.53-2.13,4.01-3.47,6.63-3.53,3.24-.08,6.62,1.63,10.21,6.28l-20.44,9.34h0Z"/>
    <path id="partofspine18" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M121.17,304.14c1.01-7.52,4.31-11.67,9.8-12.59l9.84,9.65-19.63,2.95h-.01Z"/>
    <path id="partofspine17" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M126.63,303.32c-1.32-5.94-.57-10.42,1.81-13.77,1.97-2.78,5.42-4.15,8.79-3.67,3.91.56,7.77,2.41,11.58,5.66l-22.18,11.77h0Z"/>
    <path id="partofspine16" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M106.59,259.28c-2.05-5.39-.2-9.1,6.15-10.87l1.59,5.44-7.75,5.44h.01Z"/>
    <path id="partofspine15" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M115.24,256.23c-.34-.23-.67-.46-1-.7-7.38-5.4-3.51-17.07,5.64-16.99,3.23.03,9,1.19,13.13,2.42v7.41l-17.77,7.85h0Z"/>
    <ellipse id="partofspine14" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" cx="94.62" cy="203.82" rx="3.53" ry="4.56"/>
    <path id="partofspine13" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M98.99,207.47c-4.1-3.41-5.45-5.96-3.59-9.59.77-1.51,1.96-2.76,3.3-3.8,3.63-2.8,7.74-4.06,12.01-4.79,1.32-.22,2.66-.37,3.99-.33,5.01.16,9.18,2.24,12.84,5.48l-7.75,9.38-20.81,3.65h0Z"/>
    <circle id="partofspine12" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" cx="90.39" cy="156.41" r="4.24"/>
    <path id="partofspine11" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M115.24,149.14c-3.97-3.51-8.24-6.56-14.13-7.06-2.95-.32-5.86.27-8.66,3.7-1.78,2.18-2.53,5.02-1.86,7.75.56,2.3,1.85,4.42,4.03,6.32l20.62-10.71h0Z"/>
    <path id="partofspine10" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M133.95,83.52c-.04,2.42-.78,4.45-3,5.69s-5.1,1.06-7.27-.36c-3.75-2.46-7.41-3.98-10.95-4.41l-25.48,4.25c-5.82-.19-7.75-4.98-7.1-12.82l3.42-4.87c18.21.92,34.88,5.38,50.38,12.53h0Z"/>
    <path id="partofspine9" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M131.19,341.9c-8.74,4.89-13.58,3.83-15.19-2.13l-4.25-21.27c-3.85-6.4-1.77-10.88,3.65-14.28,9.54-2.63,20.17-7.93,31.59-15.19,1.55-2.17,3.95-3.61,7.29-4.25-8.2-15.92-9.94-23.7-3.34-20.96,6.91,5.81,13.4,10.68,17.12,9.42,0-6.09,1.59-8.15,6.27-2.43,7.64,4.5,15.04,8.47,19.14,4.56,4.68-2,9.98-.75,16.1,4.86,12.49,8.44,25.34,10.55,38.58,6.08,6.08-2.63,10.21-1.99,11.54,3.34,1.31,9.7-3.29,13.51-10.03,15.19l-13.67,2.13c-4.38,3.44-10.91,3.33-18.4,1.64-2.66-.6-5.45-.06-7.66,1.53l-4.92,3.51-10.94,4.25c-1.88,2.49-6.53,1.43-13.37-2.43l-11.24-10.03c-5.88-2.92-9.03-1.98-10.28,1.62-.8,2.29-.41,4.82.79,6.92,5.73,9.97,7.85,16.51.68,14.24-8.39-2.11-18.55,3.99-29.47,13.67h.01Z"/>
    <path id="partofspine8" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M155.8,398.1c-11.15,10.55-17.64,7.6-20.96-4.56l-5.77-22.78c-5-9.54-4.81-16.73,6.68-18.84l23.39-12.15c3.19-4.21,6.83-5.67,10.94-4.25,9.01,1.66,13.05-2.75,12.76-12.46-6.68-14.18-7.29-21.47-1.82-21.87,8.1,5.25,13.92,10.99,17.01,17.32,8.48-1.11,16.37.05,23.7,3.34,24.27,6.04,46.7,8.89,66.23,6.68,4.9-1.93,9.85-3.34,15.49,2.73,3.72,5.08,5.22,9.79,1.52,13.67l-31.29,10.03c-11.81,7.09-25.47,10.47-41.32,9.42-13.59-8.44-26.39-15.74-33.42-14.73-9.87,3.17-13.3,7.54-13.06,12.61,4.48,9.03,3.59,15.47-2.43,19.44-.6,3.96-6.25,5.64-13.97,6.38l-13.67,10.03h0Z"/>
    <path id="partofspine7" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M148.93,419c.59-3.7,3.01-6.27,6.55-8.14l28.25-17.01c2.58-3.48,8.28-4.12,15.19-3.65,12.48-7.3,13.55-16.22,9.72-25.82-5.11-11.35-7.28-19.29-2.73-19.44,8.71,2.97,15.47,9.11,20.96,17.32,5.22,9.17,13.32,13.45,24,13.37,13.74.58,32.27,2.25,52.25,4.25,1.4.24,2.72.43,3.98.58"/>
    <path id="partofspine6" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M111.45,290.86c-7.42,7.26-12.43,6.91-12.46-9.11v-13.67c.23-7.2,4.12-11.53,10.78-13.67l20.51-10.94c.78-4.98,2.76-7.6,6.68-6.38-2.98-10.43-1.9-13.35,2.73-9.72-4.36-9.71-4.18-14.12,1.22-12.46,5.41,3.32,10.79,6.8,15.19,14.58,4.65,5.34,9.53,6.44,14.58,4.25,9.58-2.34,17.44-2.06,21.57,3.95,11.36,6.35,21.95,9.27,31.59,7.9,3.89,3.57,4.21,7.03,0,10.33-6.52,1.34-11.7,3.87-15.8,7.36-2.38,3.11-6.12,2.99-10.33,1.76-4.34,2.44-8.64,2.38-12.88-1.76-1.55-3.69-3.55-4.83-5.96-3.63,1.03,7.76-3.9,6.59-12.46,0-12.19-5.06-21.8-6.75-25.22-.38l2.13,10.62c4.09,7.72-1.41,11.41-12.46,12.76-6.16-.29-12.64,2.49-19.44,8.2h.03Z"/>
    <path id="partofspine5" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M95.95,244.08c-5.89,7.68-8.85,4.94-9.34-6.62-.08-1.98.18-3.96.78-5.84,1.14-3.53.77-6.86-.86-10.02-3.26-5.47-1.26-9.72,4.71-13.06l25.67-13.37c2.32-1.54,4.64-1.67,6.99,1.52,2.06-1.85,2.73-4.49,2.04-7.9-.07-10.52.3-19.57,4.64-15.19l15.19,13.67c6.65,4.92,12.77,7.89,17.17,4.56,5.1-3.93,10.94-2.86,17.77,4.86,7.96,6.16,15.63,11.08,21.87,10.03,4.42,2.79,7.2,6.5,5.47,12.76-1.16,5.53-3.18,8.41-6.99,5.77-4.42-3.1-10-2.57-16.22,0-12.69-4.04-21.71-4.31-25.71.61-8.74-11.15-18.52-17.75-29.16-20.66-3.68,3.69-5.24,8.12,0,14.89,2.97,10.04,1.61,14.18-4.03,12.46-12.74-1.7-22.46,2.7-29.99,11.54h0Z"/>
    <path id="partofspine4" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M103.4,130.76c-4.82,3.25-9.22,1.1-13.22-6.08-3.02-4.05-7.07-7.41-11.54-10.48-4.55-6.33-1.86-11.24,4.25-15.49,3.35-6.47,8.36-9.28,15.34-7.75,2.88,2.35,5.53,2.87,7.9,1.22,6.19-2.82,12.08-2.29,17.62,2.13,5,.2,9.52,2.12,13.37,6.38,8.89,7.5,16.69,11.26,23.09,10.18,4.32-.81,8.96-2.66,13.67-4.71,16.92-5.73,26.5-3.91,29.47,4.71-.38,3.95-.52,7.69.76,10.18,2.01,3.93,1.34,7.88-1.67,11.85-.17,7.89-3.55,8.93-8.2,7.29-7.9-2.55-15.93-.69-24,2.89-7.52,1.93-14.64,3.57-19.29,3.34-4.42.78-7.91,2.69-11.12,4.93-1.53,1.07-3.57,1.12-5.12.07-2.65-1.82-6.16-5.49-10.19-10.31l-12.91-10.94c-2.15-3.85-5.01-2.73-8.2.61v-.02h0Z"/>
    <path id="partofspine3" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M81.99,192.29l-1.83-17.18c.43-8.33,3.57-12.93,8.2-15.49l23.7-10.33c1.84-4.86,3.99-8.69,9.11-2.73.66-8.66,2.15-15.07,7.29-11.54l11.85,17.32c5.28,4.3,10.19,5.77,14.28.91,4.45-6.74,11.27-7.25,20.35-1.82,16.16,8.9,28.21,17.21,23.09,23.09-.69,2.51-2.99,2.8-6.68,1.22-6.06-.85-11.02,1.58-15.8,4.56-4.42-3.55-8.57-2.86-12.61-.61l-6.84,6.08c-3.53,5-13.92-5.13-26.13-19.29-5.55-3.79-8.21.52-8.51,11.39.53,6.17-.85,9.09-4.34,8.43s-7.33-.52-10.96,0c-8.97,1.26-15.83,3.72-20.2,7.58-1.46,1.29-3.77.39-3.98-1.55v-.03h.01Z"/>
    <path id="partofspine2" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M86.14,75.52c-1.34-.22-2.7-.13-4.03.12-5.33,1.03-8.69-1.63-10.45-7.32-1.17-4.53,1.3-9.15,6.15-13.82,6.3-7.85,13.08-12.39,21.34-6.53,4.89,3.04,11.66,5.93,24.76,8.35,7.03,1.08,13.53.76,19.3-1.49,1.81-.71,3.71-1.17,5.65-1.21,5.3-.11,9.07.36,9.08,2.29,0,.75-.6,1.36-1.33,1.54-7.67,1.9-12.86,4.05-10.83,6.93,3.06,5.15,8.03,7.98,15.65,7.59,7.2-.27,15.04-2.92,23.39-7.44,4.44-1.2,8.35.21,11.39,5.92.15.87.25,1.71.31,2.53.48,7.12-4.8,13.34-11.85,14.42l-7.95,1.22c-1.25.19-2.47.56-3.57,1.17-5.11,2.8-15.57,1.64-30.26-2.62-11.1-.96-18.84-2.69-22.59-5.32-.97-.68-2.11-1.07-3.27-1.27l-30.89-5.06h0Z"/>
    <path id="partofspine1" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls7" d="M80.27,149.23c-4.74,3.17-6.29-2.24-5.81-13.09.11-2.54.51-5.07,1.23-7.51,1.07-3.64,2.3-7,3.64-10.14s2.14-6.21,2.51-9.47c.72-6.35,1.88-11.48,3.61-15.02,1.62-3.33,2.36-7.02,2.42-10.72.31-18.03,3.86-27.97,9.31-33.18,4.46-3.09,8.91-3.39,13.37,0l7.59,10.07c.78,1.03,1.06,2.35.79,3.61-2.59,12.05-4.36,23.39-2.37,31.47.42,1.72.74,3.48.82,5.25,1.14,26.05-.22,41.26-5.46,39.67-10.97-5.28-20.89-2.55-29.86,7.44-.53.6-1.11,1.16-1.78,1.61h-.01Z"/>
  </g>
  <g id="softparts">
    <path id="softparts6" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls8" d="M128.09,413.59c-.51-1.96-1.01-3.89-1.52-5.81"/>
    <path id="softparts5" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls8" d="M123.48,396.28c-7.72-28.11-15.1-50.92-20.74-67.37-17.61-51.39-32.59-82.78-36.13-111.1-.45-3.56-1.76-15.42-7.01-30.2-1.68-4.74-3.49-8.97-5.21-12.62"/>
    <path id="softparts4" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls8" d="M51.72,169.66c-1.07-2.03-2.06-3.78-2.91-5.24"/>
    <path id="softparts3" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls8" d="M201.44,57.1c.63,1.78,1.28,3.68,1.93,5.68"/>
    <path id="softparts2" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls8" d="M207.01,74.79c3.77,13.54,7.31,30.32,8.99,49.72,1.65,19.12-.2,21.26,1.65,40.47,2.47,25.67,4.98,51.65,19.92,77.1,8.77,14.93,13.37,15.31,33.44,45.3,3.51,5.24,6.6,10.07,9.26,14.32"/>
    <path id="softparts1" class="Halswirbelsaule_mit_angestellter_Kassette_lat_liegend1616_cls8" d="M283.56,307.06c1.15,1.89,2.18,3.61,3.09,5.14"/>
  </g>
</g>
<g id="Highlights">
  <path id="processusspinosusof7thcorpusvertebraehighlight" class="Processus_spinosus_1616Fill" style= {{
            fill: props.colors.Processus_spinosus_1616Fill,
            stroke: props.colors.Processus_spinosus_1616Fill,
            opacity:props.colors.Processus_spinosus_1616Fill,
          }} d="M232.58,364.38c2.16.62,3.94.51,5.26.4,8.39-.64,12.59-.96,17.76-2.29,4.36-1.12,8.36-2.15,13.11-4.72,2.48-1.34,3.13-2.01,5.8-3.24,3.27-1.51,4.8-1.66,10.68-3.27,6.32-1.72,3.63-1.29,13.18-4.15,5.16-1.54,6.46-1.83,7.5-3.32,2.5-3.61-.17-9.08-.49-9.75-1.72-3.54-5.34-6.92-9.6-7.3-2.33-.21-2.86.66-6.99,1.64-3.5.83-6.33.87-12,.94-2.76.04-6.98.01-13.64-.47-4.33-.32-10.58-.79-18.59-2.09-11.85-1.92-17.77-2.89-23.73-5.26-.08-.03-7.32-2.91-12.81-3.24-3.7-.22-4.72-.13-4.72-.13-2.51.2-3.58.74-4.85.13-1.34-.64-1.8-2.01-2.29-3.1-1.08-2.39-2.97-4.16-6.74-7.69-2.76-2.58-7.65-7.15-10.25-5.8-1.52.79-1.66,3.29-1.75,4.85-.07,1.24-.02,3.52,3.78,12.4.97,2.27,1.61,3.13,1.62,4.72,0,2.4-.38,4.27-.76,5.37-.65,1.89-1.58,4.56-3.96,5.79-1.77.91-2.81.24-3.26.98-.92,1.5,3.55,3.87,6.7,10.31,1.48,3.02,3.61,5.71,4.85,8.84.13.33.54,1.39,1.24,1.47.75.08,1.24-1.05,2.2-2.17,1.49-1.75,3.26-2.46,5.66-3.44,2.33-.94,3.5-1.42,4.96-1.21,1.38.19,2.42.84,2.87.37.4-.42-.2-1.17,0-2.43.17-1.04.83-1.85,1.21-2.16,2.04-1.6,7.85,2.16,10.92,4.58,3.22,2.55,6.15,5.78,7.15,6.88,1.52,1.68,2.17,2.56,3.78,3.91,1.42,1.19,3.4,2.83,6.2,3.64h0Z"/>
  <path id="corpusvertebraelateralhighlight7" class="HWK_1616Fill" style= {{
            fill: props.colors.HWK_1616Fill,
            stroke: props.colors.HWK_1616Fill,
            opacity:props.colors.HWK_1616Fill,
          }} d="M126.64,357.38c.16-.33.66-1.3,1.71-2.31,1.87-1.78,4-2.29,5.39-2.7.54-.16,3.02-1.27,7.97-3.48,5.79-2.6,10.14-5.18,12.8-6.76,3.12-1.86,4.68-2.8,5.53-3.51,1.58-1.32,2.36-2.31,4.18-2.97,1.36-.49,2.52-.52,3.78-.54,1.27-.02,3.35-.06,5.26.74,2.26.95,3.46,2.75,4.63,4.53,8.15,12.51,9.91,15.87,8.62,18.49-.24.49-.54.85-.71,1.62-.51,2.3,1.14,3.52,2.16,6.71.23.71.96,3.23.54,6.34-.15,1.06-.41,2.88-1.62,4.72-.84,1.27-1.33,1.35-2.95,3.1-2.38,2.59-1.75,2.91-3.12,4.04-2.07,1.71-5.44,2.58-8.09,2.43-.69-.04-1.89-.18-3.24.4-1.05.45-1.68,1.13-1.89,1.35-.86.9-.48.18-13.08,9.57-5.13,3.82-6.89,5.2-9.84,5.26-.7.01-2.19.03-3.78-.67-1.76-.78-3.94-2.58-7.01-13.08-1.13-3.86-2.62-9.72-3.78-15.64-.44-2.25-.56-3.13-1.08-4.72-1.37-4.21-2.85-5.17-3.1-8.49-.18-2.41.45-3.88.71-4.43h0Z"/>
  <path id="corpusvertebraelateralhighlight6" class="HWK_1616Fill" style= {{
            fill: props.colors.HWK_1616Fill,
            stroke: props.colors.HWK_1616Fill,
            opacity:props.colors.HWK_1616Fill,
          }} d="M132,297.5c2.58-1.34,5.07-2.77,8.19-4.55,5.74-3.28,5.78-3.49,6.47-3.44,4.91.33,5.15,10.83,12.94,22.85.08.13,1.41,2.91,4.07,8.48,1.07,2.23,2.43,5.17,1.23,6.94-.08.12-.25.37-.55.59-1.46,1.08-3.57-.11-5.8-.54-1.88-.36-3.33-.04-6.24.61-3.88.86-6.56,2.51-9.54,4.38-2.38,1.49-4.11,2.84-6.61,4.85-3.49,2.83-4.16,3.82-6.74,5.26-2.21,1.24-4.83,2.65-7.9,2.03-.55-.11-2.17-.46-3.56-1.76-1.51-1.41-1.9-3.15-2.41-5.61-2.03-9.79-3.04-14.68-3.2-16.3-.06-.61-.15-1.68-.59-3.03-.63-1.93-1.26-2.26-1.72-3.68-.75-2.33-.03-4.53.24-5.22.96-2.53,2.92-3.82,3.91-4.45,1.99-1.27,3.69-1.48,5.36-1.85,3.06-.68,6.19-2.3,12.44-5.56h0Z"/>
  <path id="corpusvertebraelateralhighlight5" class="HWK_1616Fill" style= {{
            fill: props.colors.HWK_1616Fill,
            stroke: props.colors.HWK_1616Fill,
            opacity:props.colors.HWK_1616Fill,
          }} d="M111.02,253.51c1.66-.75,3.01-1.41,10.92-5.67,5.87-3.16,5.78-3.15,5.97-3.19.41-.09,1.38-.27,2.02-1.06.59-.72.44-1.36.67-2.42.36-1.69,1.5-3.69,3.07-4.3,1.21-.47,2.42-.02,3.04.21,6.82,2.61,10.63,17.21,8.17,18.83-.29.19-1.1.48-2.06,1.33-.07.06-.82.74-1.21,1.37-.81,1.3-.19,2.64.93,7.38.76,3.19.55,2.88.92,4,.73,2.19,1.38,2.97,1.32,4.52-.05,1.42-.67,2.48-.92,2.9-1.41,2.36-3.98,3.21-6.34,3.98-1.56.51-2.74.71-4.18.94-4.14.68-5.32.24-8.01.8-2.19.46-3.61,1.21-5.94,2.44-3.16,1.67-6.31,3.33-9.37,6.47-1.27,1.3-2.52,2.86-4.72,3.24-.96.16-1.99.09-2.67-.15-3.92-1.4-3.91-11.31-3.81-21.28.1-10.11,1.12-12.33,2.49-13.89,1.05-1.19,3.66-3.52,7.21-5.33.84-.43.81-.36,2.48-1.11h.02Z"/>
  <path id="corpusvertebraelateralhighlight4" class="HWK_1616Fill" style= {{
            fill: props.colors.HWK_1616Fill,
            stroke: props.colors.HWK_1616Fill,
            opacity:props.colors.HWK_1616Fill,
          }} d="M100.45,203.76c1.66-.82,10.35-5.22,16.18-8.49.83-.47,2.93-1.65,4.85-.91.93.36,1.5,1.04,2.33,2.33,2.39,3.7,4.5,6.96,4.04,11.12-.21,1.88-.67,1.52-.91,3.34-.61,4.63,2.48,6.36,4.04,12.92.61,2.55,1.42,5.97-.29,7.67-1.34,1.34-3.56.94-5.57.74-6.37-.63-13.31.51-17.9,2.63-4.02,1.86-9.44,5.97-11.22,9-.17.28-.99,1.73-2.63,2.93-.95.7-2.05,1.51-3.04,1.35-2.39-.38-3.75-6.4-3.83-11.26-.12-6.57,2.16-6.68,1.42-11.43-.81-5.14-3.84-7.32-2.63-11.12.28-.88.8-1.87,2.38-3.48,3.87-3.93,7.26-4.6,12.79-7.34h-.01Z"/>
  <path id="corpusvertebraelateralhighlight3" class="HWK_1616Fill" style= {{
            fill: props.colors.HWK_1616Fill,
            stroke: props.colors.HWK_1616Fill,
            opacity:props.colors.HWK_1616Fill,
          }} d="M95.49,156.43c4.11-1.87,8.65-2.94,12.39-5.46.6-.4,2.07-1.44,4.15-1.57.2,0,1.85-.1,3.49.66,1.08.5,2.57,1.55,6.22,10.87,1.56,3.99,1.78,5.05,1.62,6.37-.21,1.76-.86,2.22-1.42,5.1-.08.43-.43,2.32-.46,4.81-.03,2.89.41,3.8-.1,5.87-.3,1.21-.52,2.1-1.26,2.73-1.57,1.33-4.16.39-6.22-.05-3.49-.74-6.46-.06-12.39,1.31-6.87,1.59-10.31,2.38-12.97,4.87-.79.74-2.95,2.96-4.91,2.41-.45-.12-.75-.35-.88-.45-1.47-1.15-1.2-3.46-1.26-6.32-.05-2.49-.55-4.96-.81-7.44-.45-4.24-.57-5.02-.45-6.57.18-2.36.73-4.13.97-4.87.22-.65,1.38-4.02,3.87-6.76,1.35-1.49,2.13-1.73,10.42-5.51h0Z"/>
  <path id="corpusvertebraelateralhighlight2" class="HWK_1616Fill" style= {{
            fill: props.colors.HWK_1616Fill,
            stroke: props.colors.HWK_1616Fill,
            opacity:props.colors.HWK_1616Fill,
          }} d="M93.67,85.24c8.39,2.8,17.18,4.24,25.69,6.67,10.42,2.99,14.23,3.54,16.44,7.43.77,1.35,1.19,2.83,1.38,5.97.98,15.89-6.56,29.83-9.4,29.29-.53-.1-1.23-.78-2.24-.55-.91.21-1.13.93-1.86,1.25-1.47.64-3.5-1.01-4.02-1.42-1.44-1.14-17.72-14.08-27.43-14.04-1.49,0-3.65.32-5.68-1.01-1.49-.98-2.1-2.31-4.3-3.15-1.3-.5-1.42-.16-2.34-.59-2.28-1.08-3.39-4.09-3.52-6.36-.15-2.41.78-4.22,1.81-6.19,1.49-2.83,2.55-3.32,4.04-5.46,4.02-5.77,2.02-10.42,5.16-12.24,1.91-1.1,4.21-.28,6.27.4Z"/>
  <path id="corpusvertebraelateralhighlight1" class="HWK_1616Fill" style= {{
            fill: props.colors.HWK_1616Fill,
            stroke: props.colors.HWK_1616Fill,
            opacity:props.colors.HWK_1616Fill,
          }} d="M111.04,50.75c-.52-.49-2.01-1.83-4.32-2.68-1.1-.4-1.87-.68-2.94-.65-2.03.06-2.52,1.15-4.04,1.01-1.37-.13-1.63-1.08-3.28-2.04-2.3-1.34-4.72-1.19-5.72-1.13-3.31.2-5.59,1.92-7.38,3.27-1.05.79-1.66,1.4-4.85,5.06-5.93,6.78-6.23,7.42-6.47,8.19-1.39,4.38.35,8.34.81,9.3.45.93,1.06,2.19,2.43,3.19,1.79,1.31,3.8,1.41,4.75,1.46,1.9.09,2.24-.39,4.55-.4,1.76,0,3.03.27,3.74.4,21.12,4.13,32.93,6.43,37.01,1.82,4.99-5.64-1.67-21.51-6.98-22.35-.93-.15-2.95,0-4.76-1.41-1.22-.95-.96-1.53-2.55-3.04Z"/>
  <path id="baseandupperplateshighlight14" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M129.68,133.87c1.07-1.16,1.68-3.07,1.06-3.86-.52-.64-1.66-.25-4.06.14-1.88.31-3.77.36-5.65.55-2.79.28-4.36-.94-10.78-4.23-7.53-3.86-11.29-5.78-15.78-7.27-.73-.24-1.72-.55-2.95-1.07-.71-.3-1.23-.55-1.53-.7-5.93-2.9-8.9-4.36-10.66-6.09-1.23-1.21-1.77-2.1-2.22-1.92-.7.28-.64,2.98.41,4.94,1.48,2.76,4.71,3.67,6.19,4.14,2.53.79,13.91,4.36,21.96,9.52,3.5,2.25,5.72,4.38,10.59,6.2.35.13,3.46,1.22,7.7,1.24,3.77.02,5.05-.83,5.75-1.58h-.03Z"/>
  <path id="baseandupperplateshighlight13" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M84.5,86.84c-.24.84.03,1.97.72,2.36.93.53,2.39-.43,2.76-.65,4.87-2.92,24.09,1.41,35.6,6.43,5.34,2.33,8.01,3.49,9.44,4.92.9.9,2.29,2.52,3.3,2.16.86-.31,1.08-1.9,1.08-2.49,0-3.07-5.92-5.68-20.37-10.68,0,0-19.34-6.69-28.44-4.55-1.07.25-3.6.85-4.08,2.51h-.01Z"/>
  <path id="baseandupperplateshighlight12" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M126.56,77.32c.7-1.41.74-3.43-.09-4-.68-.47-1.66.23-3.85,1.29-1.71.83-3.51,1.42-5.26,2.14-2.59,1.07-4.44.34-11.54-.98-8.31-1.55-12.47-2.32-17.2-2.47-1.07-.03-2.64-.05-4.8-.42-3.04-.52-5.21-.88-7.37-2.11-1.92-1.1-2.47-2.1-3.17-1.8-.8.34-1.06,2.04-.67,3.3.73,2.36,3.8,3.41,6.13,4.04,9.84,2.7,13.02-.05,21.79,1.96,5.04,1.16,7.27,2.82,13.24,3.21.44.03,3.74.16,7.73-1,3.62-1.06,4.61-2.24,5.06-3.16Z"/>
  <path id="baseandupperplateshighlight11" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M82.51,48.96c.66-2.68,4.44-3.7,5.5-3.98,2.87-.77,5.3-.25,6.58.04,3.01.68,2.85,1.54,5.68,2.1,2.86.56,3.6-.2,6.44.41.66.14,1.75.42,4.65,1.88,5.33,2.68,10.48,5.26,10.17,7.79-.06.51-.37,1.14-.9,1.47-2.38,1.47-5.81-5.24-13.6-7.91-3.26-1.12-3.09-.09-8.35-1.57-3.68-1.03-5.4-2-8.08-1.35-2.36.57-4.19,1.63-4.19,1.63-1.89,1.1-2.84,2.23-3.5,1.85-.54-.31-.61-1.49-.4-2.34v-.02Z"/>
  <path id="baseandupperplateshighlight10" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M140.13,404.38c-.95-.9-1.89-2.54-1.77-3.73.2-1.99,3.28-2.04,4.31-2.1,9.86-.5,24.59-10.63,26.03-11.56,10.14-6.53,13.46-14.32,17.26-12.36,1.49.76,2.47,2.72,2.53,4.25.05,1.14-.34,3.62-12.29,10.16-2.36,1.29-2.76,1.42-5.12,2.66-9.43,4.97-12.15,7.7-19.9,11.08-6.92,3.02-9.57,3.04-11.07,1.61h.02Z"/>
  <path id="baseandupperplateshighlight9" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M170.25,335.58c-.19-1.1-.95-2.1-1.62-2.64-5.46-4.41-17.35,11.89-30.11,16.6-1.63.6-3.22,1.31-4.86,1.89-6.52,2.32-9.47,2.48-9.6,4.59-.07,1.2.79,2.65,1.7,3.29,1.77,1.23,3.9-.45,6.53-2.13,2.97-1.9,6.4-2.94,9.39-4.81,13.55-8.5,29.58-10.95,28.56-16.79h0Z"/>
  <path id="baseandupperplateshighlight8" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M119.07,345.09c-.88-.78-1.71-2.24-1.53-3.33.29-1.83,3.29-2.03,4.29-2.13,8.63-.85,20.99-8.76,25.84-11.88,2.86-1.83,7.63-5.21,13.14-4.72,1.17.11,3.41.34,4.36,1.96.59,1.02.71,2.7-.14,3.67-1.8,2.07-5.98-1.46-10.18.59-.81.39-4.4,2.33-5.1,2.69-.73.38-.69.35-1.16.62-5.38,3.08-11.22,6.88-18.7,10.51-6,2.91-8.92,3.71-10.82,2.02Z"/>
  <path id="baseandupperplateshighlight7" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M109.25,309.43c-.86-.78-1.7-2.23-1.58-3.31.21-1.79,3.05-1.93,4-2,8.17-.64,19.62-8.15,24.12-11.1,2.65-1.74,7.06-4.96,12.31-4.35,1.12.13,3.25.41,4.21,2.02.6,1.01.77,2.67,0,3.61-1.64,2-5.74-1.57-9.65.35-.75.37-4.09,2.19-4.74,2.53-.68.36-.64.33-1.08.59-5,2.91-10.41,6.51-17.38,9.91-5.59,2.73-8.34,3.45-10.21,1.75h0Z"/>
  <path id="baseandupperplateshighlight6" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M145.71,272.96c-.11-1.46-.75-3.5-1.82-3.84-1.59-.51-3.18,3.03-4.72,5.24-3.88,5.54-8.44,4.04-23.09,9.27-13.82,4.93-14.21,7.85-14.19,8.85.04,1.9,1.72,4.08,3.31,4.42,2.37.51,3.53-3.05,8.13-6.11.5-.33,1.84-1.19,6.1-2.7,12.63-4.49,17.34-2.89,22.7-7.82,1.06-.97,3.87-3.64,3.59-7.3h0Z"/>
  <path id="baseandupperplateshighlight5" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M135.85,240.6c-.18-1.1-.89-2.55-1.78-3.04-1.5-.81-3.02,1.33-3.55,2.03-4.57,6.04-16.1,10.88-20.64,12.79-2.67,1.12-7.37,2.81-9.51,7.25-.46.94-1.3,2.77-.53,4.4.49,1.02,1.69,2.06,2.8,1.98,2.37-.19,1.64-5.34,5.08-7.35.66-.39,3.74-2,4.33-2.33.62-.34.58-.32,1-.53,4.75-2.32,10.24-4.58,16.36-8.17,4.9-2.88,6.84-4.64,6.44-7.03h0Z"/>
  <path id="baseandupperplateshighlight4" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M86.5,212.97c-1.24-.73-1.86-2.93-1.2-4.17.65-1.23,2.41-1.26,3.62-1.37,1.82-.17,4.93-1.76,11.14-4.93,3.57-1.83,2.56-1.63,9.61-5.62,4.98-2.82,7.74-4.22,11.16-3.6,1.04.19,3.04.58,4.01,2.23.61,1.03.86,2.68.21,3.55-1.29,1.73-4.74-.17-8.92-.19-3.71-.02-6.86,2.07-13.17,6.24-1.73,1.14-5.51,3.21-13.06,7.35-.75.41-2.23,1.19-3.4.51h0Z"/>
  <path id="baseandupperplateshighlight3" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M80.16,163.91c.09-1.78,2.5-2.76,3.78-3.3,6.25-2.64,12.11-6.06,18.19-9.06,4.91-2.43,7.62-4.22,11.27-3.39,1.07.24,3.13.74,4.19,2.53.67,1.12.99,2.88.35,3.79-1.17,1.67-4.44-.4-9.13-.56-5.33-.18-9.37,2.19-14.37,5.11-4.41,2.58-6.61,3.88-8.13,5.52-1.37,1.49-2.41,3.06-3.78,2.79-1.28-.25-2.45-2.01-2.37-3.44h0Z"/>
  <path id="baseandupperplateshighlight2" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M82.69,191.83c.37-1.24,1.97-1.5,2.59-1.61,7.3-1.31,10.78-5.75,27.52-7.31,2.78-.26,6.4-.48,7.71,1.6.35.56.62,1.41.38,2.04-.59,1.58-3.99.94-6.44.65-1.52-.18-9.78-1.15-16.81,1.69-3.8,1.54-6.16,2.41-8.58,4.8-1.47,1.44-2.58,3.04-4,2.49-1.44-.57-2.78-2.91-2.36-4.35h0Z"/>
  <path id="baseandupperplateshighlight1" class="Platten_1616Fill" style= {{
            fill: props.colors.Platten_1616Fill,
            stroke: props.colors.Platten_1616Fill,
            opacity:props.colors.Platten_1616Fill,
          }} d="M89.39,246.46c.08-.85,1-1.19,1.3-1.31,3.53-1.43,4.85-5.68,10.92-9.87,1.57-1.08,6.36-4.31,13.38-5.22,6.81-.88,8.51,1.34,12.95.26.45-.11,1.68-.44,2.5.34,1.03.98,1.23,3.51.41,4.67-1.35,1.91-5.12-.99-10.53-1.72-9.03-1.22-15.72,3.91-16.58,4.62-5.92,4.87-7.78,8.08-7.78,8.08-1.02,1.75-1.92,3.83-3.5,3.62-1.44-.19-3.17-2.24-3.05-3.48h-.02Z"/>
  <path id="mandibulahighlight2" class="Mandibula_1616Fill" style= {{
            fill: props.colors.Mandibula_1616Fill,
            stroke: props.colors.Mandibula_1616Fill,
            opacity:props.colors.Mandibula_1616Fill,
          }} d="M7.01,173.59c6.78-5.88,12.85-9.69,17.26-12.13,5.29-2.93,9.84-4.79,15.54-9.53,3.51-2.92,6.04-5.75,7.65-7.73,1.04-1.44,2.53-3.66,4-6.54,3.65-7.16,4.73-13.62,5.83-22.94,1.73-14.55.84-13.47,2.03-21.48,1.52-10.21,3.86-17.98,6.31-26.11,3.25-10.79,4.88-16.18,7.18-21.89,2.24-5.56,5.2-12.07,4.49-20.75-.16-1.95-.47-3.54-.71-4.59"/>
  <g id="Weichteile">
    <path id="Weichteile2" class="Weichteile_1616Fill" style= {{
            fill: props.colors.Weichteile_1616Fill,
            stroke: props.colors.Weichteile_1616Fill,
            opacity:props.colors.Weichteile_1616Fill,
          }} d="M43.67,167.12l6.84,12.3,10.48,32.81,10.48,41.01,15.95,47.39,17.32,47.85,15.49,51.04,4.56,15.95,6.84-1.82-12.3-41.92-19.14-57.87-21.87-64.71-10.94-39.65-5.19-25.93c-.49-2.45-1.37-4.79-2.62-6.96l-8.6-14.96-7.29,5.47Z"/>
    <path id="Weichteile1" class="Weichteile_1616Fill" style= {{
            fill: props.colors.Weichteile_1616Fill,
            stroke: props.colors.Weichteile_1616Fill,
            opacity:props.colors.Weichteile_1616Fill,
          }} d="M206.81,55.93c2.28,4.56,3.14,8.98,5.01,15.95,3.19,11.85,3.26,12.19,6.38,31.44.94,5.82,1.33,9.8,2.73,41.47,1.28,28.85,1.27,32.62,2.73,41.47,2.51,15.19,6.16,25.92,7.29,29.16,2.13,6.09,6.12,17.28,14.13,30.08,5.36,8.57,5.18,6.13,19.14,25.06,15.25,20.7,11.13,17.73,17.32,24.61,6.13,6.81,11.95,11.7,10.48,15.95-.69,2-2.9,3.59-5.01,3.65-4.67.11-7.86-7.36-11.85-14.58-.04-.08-6.71-9.16-20.05-27.34-15.03-20.48-14.94-17.52-20.51-26.43-10.14-16.22-13.72-31.3-15.95-41.01-2.14-9.32-2.94-22.16-4.56-47.85-1.15-18.3-.94-21.75-2.28-37.82-1.53-18.33-2.29-27.5-4.1-34.18-4.44-16.38-11.39-26.94-7.75-30.08,1.67-1.43,5.81-1.59,6.84.46Z"/>
  </g>
</g>
</svg>
  );
}

export default HalswirbelsauleMitAngestellterKassetteLatLiegend1616;
