import Controller from "../Common/Controller";

import "./styles/HumanBody.css";

import FrontView from "./views/FrontView";
import LeftSideView from "./views/LeftSideView";
import BackSideView from "./views/BackSideView";
import RightSideView from "./views/RightSideView";
import RightSideMotionView from "./views/RightSideMotionView";

function HumanBody() {
  const availableViews = [
    FrontView,
    LeftSideView,
    BackSideView,
    // RightSideView,
    RightSideMotionView,
  ];

  return <Controller availableViews={availableViews} />;
}

export default HumanBody;
