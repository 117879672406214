import style from "./style.scss";

export default function RechterOberarmMitEllenbogenVdSitzend2232(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 392.16 1155.54">
      <g id="Backround">
        <path
          id="backround"
          class="oberarm_mit_ellegnbogen_vd_rechts2232_cls4"
          d="M11.49,954.74c1.89-11.77,7.81-20.59,6.07-38.43-1.23-12.6-5.44-21.04-7.82-26.63-12.49-29.28-12.62-68.63-2.34-95.42,9.83-25.61,20.86-18.08,38.47-51.48,8.39-15.91,9.06-23.62,22.09-68.7,9.58-33.13,15.12-49.38,17.76-58.39,18.62-63.61,4.96-150.77,2.93-270.57-1.25-74.26,1.85-167.58-1.79-223.1-2.37-36.05-3.55-54.08-6.02-67.31-4.69-25.19-8.36-29.46-4.59-36.33,10.53-19.15,46.35.68,114.15-13.93,16.04-3.46,28.12-7.6,36.35-.79,13.24,10.95,5.05,40.73,2.48,50.95-1.24,4.94,3.09-11.62,9.34,363.53.85,50.87.88,58.06,2.52,73.87,6.47,62.47,13.89,134.16,58.86,193.49,40.29,53.17,85.5,63.14,91.34,113.92.82,7.13,2.68,23.24-4.27,41.18-14.16,36.58-46.21,32.43-64.49,67.22-15.39,29.29,1.07,44.1-18.61,74.43-12.48,19.22-19.19,14.66-32.36,33.57-18.77,26.95-18.66,61.8-18.61,81.3.08,27.56,5.78,44.68-4.67,53.01-6.57,5.24-15.61,3.87-20.41,3.22-33.75-4.58-117.53-8.74-171.51.4-9.06,1.53-21.35,4.13-28.53-2.73-7.15-6.83-5.06-19.95,1.33-47.37,13.44-57.71,16.71-52.09,16.28-63.56-1.48-40.27-39.58-50.39-33.98-85.35Z"
        />
      </g>
      <g id="Upperarmvd">
        <g id="Outlines">
          <path
            id="outline4"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls4"
            d="M244.94,1049.22c.69-5.5,1.83-11.87,4.1-24.61,2.97-16.62,4.04-20.38,6.84-24.61,2.19-3.32,3.98-4.15,17.77-15.04,15.5-12.24,17.04-11.41,20.51-16.41,6.38-9.19,9.99-24.71,4.1-35.54-2.86-5.26-6.63-6.97-6.84-12.3-.2-5.2,3.24-7.18,6.84-13.67,7.41-13.39,1.13-20,8.2-28.71,2.64-3.25,7.23-5.36,16.41-9.57,12.19-5.6,16.1-5.31,21.87-8.2,14.43-7.24,19.91-24.05,21.87-30.08,6.2-19.04,2.68-40.9-8.2-56.05-4.42-6.16-13.89-13.22-32.81-27.34-10.47-7.81-15.15-10.72-19.14-13.67-18.22-13.47-27.46-32.8-38.28-56.05-17.75-38.14-27.12-60.48-34.18-105.27-6.44-40.87-9.57-73.82-9.57-73.82-8.7-19.93-11.87-473.9-16.7-481.08-42.2-16.32-89.92-8-108,0-3.04,6.62,8.91,449.35,11.23,471.51,2.28,21.78-1.85,24.17-1.37,54.68.4,25.27,3.26,25.2,1.37,39.65-.64,4.9-1.8,11.23-10.94,39.65-5.74,17.86-7.72,22.95-10.94,32.81-7.4,22.71-6.92,26.5-19.14,71.09-8.38,30.58-11.15,38.07-19.14,45.11-8.5,7.49-14.81,7.34-20.51,16.41-5.19,8.26-2.05,11.74-5.47,34.18-2.85,18.72-5.83,21.55-5.19,34.09.49,9.54,2.67,16.82,3.83,20.6,2.24,7.3,5.4,17.63,13.67,27.34,7.91,9.29,13.35,9.64,13.67,15.04.36,6.08-6.42,7.7-13.67,19.14-3.56,5.62-9.01,14.22-7.57,22.86,1.7,10.2,11.56,11.74,19.88,22.26,3.16,4,7.43,12.33,9.57,41.01,2.15,28.72-2.61,51.32-5.47,64.25-3.88,17.55-6.56,27.95-12.78,50.44,63.69,11.7,81.4,8.03,83.99,1.32.54-1.4.17-2.29-.02-12.44-.15-8.06.05-9.34.16-22.97.26-30.74-.66-34.29.99-46.01.94-6.75,1.05-19.09,1.89-22.44,2.93,7.26,7.84,23.66,12.19,34.8,9.8,25.08,11.79,31.26,11.19,39.69-.79,11.02-1.18,16.53-3.89,20.02-.85,1.1-4.23,5.04-3.34,9.22.09.42.2.75.28.97,28.25.72,56.51,1.39,84.78,1.63,1.73-16.69,3.74-29.68,5.02-37.83,4.49-28.6,7.92-31.96,10.94-56.05h0Z"
          />
          <path
            id="outline3"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M162.92,786.75c10.81,2.72,21.81.72,32.81-.28,15-3,29-7,44-9,27-4,57,7,69,33,1,3,2,5,3,7,0,3,1,5,1,8-2,15-1,29-2,44,0,3-2,7-4,8-4,3-9-2-13-1-11,3-9,15-9,23-1,11,1,24-5,34-22,16-50-3-66-20-4-3-9-6-14-5-10,4-19,9-29,10-12,1-25,2-37,2-12,1-24,5-36,7-15,3-29,1-43-6-17-7-27-25-32-43,0-1-2-2-2-3-3-12-3-24-1-36,2-14,5-27,6-41,1-5,2-10,6-14,7-6,14-10,21-15,4-5,8-10,10-16,11-51,26-97,42-145,9-28,2-56,5-85,1-14,3-28-1-43-1-7-8.42-455.94-9-463.26"
          />
          <path
            id="outline2"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M207.74,17.21c4.32,5.46,12,452.26,13,457.26,9,64,11,128,39,187,1,2,0,3,1,5,10,21,19,43,36,60,19,17,43,27,60,46,20,24,16,60-5,82-6,7-17,9-26,12-5,2-10,5-15,7-11,5-8,17-10,27-2,5-6,10-9,15-2,3-2,7,0,10,1,2,4,4,5,6,1,3,2,5,3,7,3,6,1,13,0,19-1,2-2,5-3,7-4,7-11,12-18,17-9,7-20,11-24,20-5,9-7,19-8,29,0,4-1,9-1,13-4,32-14,62-17.04,94.2"
          />
          <path
            id="outline1"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M40.15,1138.4c13.58-46.93,26.58-93.93,13.58-143.93-3-11-12-17-20-24-5-5-7-12-5-18,2-4,4-7,5-11,1-1,2-2,2.54-2.99"
          />
        </g>
        <g id="Overview">
          <path
            id="radius6"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M37.17,938.49c17.57-10.02,38.57-4.02,57.57.98h8c9-3,16-7,26-5,7,2,14,3,17,10,5,15,7,31,7,47,0,2-2,4-3,5-11,6-21,13-22,26-1,9,0,19,3,28l3,9c9,18,20,35,17,56-2,9-3,17-6.82,25.04"
          />
          <path
            id="ulna6"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M124.75,1140.65c.98-46.18-2.02-93.18.98-139.18,6-36,29-65,45-97,3-7,4-14,3-21-6-33-11-64-10.8-96.73"
          />
          <path
            id="radius5"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M64.51,1091.6c-3.77,14.87-5.77,30.87-10.1,46.94"
          />
          <path
            id="radius4"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M64.49,1091.6c5.56-3.27,16.48-46.53-7.59-86.01"
          />
          <path
            id="ulna5"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M250.43,995.91c-3.69-.44-5.69,1.56-7.69,4.56-10,16-14,33-18,51-7,30-12,58-15.38,87.98"
          />
          <path
            id="partofancon9"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M37.16,826.39c-12.76,10.31-12.36,41.95,13.67,64.25"
          />
          <path
            id="partofancon8"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M64.5,794.94c17.38,0,15.72-20.5,31.44-41.01"
          />
          <path
            id="partofancon7"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M250.43,804.51c-15.5,0-28.03,14.67-28.03,32.81"
          />
          <path
            id="partofancon6"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M117.82,804.51c15.47-5.23,40.56-50.31,56.39-88.86"
          />
          <path
            id="partofancon5"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M117.81,669.16c16.09-28.9,26.38-628.39.01-649.95"
          />
          <path
            id="partofancon4"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M177.73,19.21c2.97,38.93,24.53,517.7,28.95,541.97,10.91,59.88,22.46,89.46,30.08,106.63,13.15,29.63,24.26,43.97,35.54,54.68,8.24,7.82,19.28,18.3,36.91,25.97,20.79,9.04,31.43,5.78,39.63,16.43,7.07,9.18,8.44,23.65,2.75,35.52-6.87,14.34-21.01,18.52-23.24,19.14"
          />
          <path
            id="partofancon3"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M180.71,748.47c-4.92,2.22-19.31,9.49-27.34,25.97-1,2.05-13.4,28.39,0,45.11,13.47,16.8,42.59,10.9,57.42,4.1,5.99-2.75,24.23-11.01,28.71-30.08,2.25-9.62,1.72-24.07-6.84-32.81-6.48-6.6-12.6-4.9-20.51-12.3-6.89-6.45-9.67-14.7-10.94-20.51"
          />
          <path
            id="partofancon2"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M142.42,919.89c24.96-40.59,24.31-102.75-12.46-124.95"
          />
          <path
            id="radius3"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M143.79,941.21c-63.57,23.24-106.63-1.22-106.63-2.73"
          />
          <path
            id="ulna4"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M298.26,933.01c-1.65,3.77-5.88,12.03-15.04,17.77-10.39,6.52-20.87,5.87-27.34,5.47-3.61-.22-14.86-1.05-25.97-8.2-8.67-5.58-7.67-8.84-15.04-12.3-9.91-4.66-19.91-2.61-25.97-1.37-7.98,1.63-19.55,4-23.24,12.3-2.54,5.71,1.46,7.87,0,19.14-1.19,9.21-4.29,11.03-4.1,17.77.2,6.86,3.61,12.47,6.84,16.41"
          />
          <path
            id="ulna3"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M266.83,976.11c17.38,0,31.44-19.27,31.44-43.09"
          />
          <path
            id="ulna2"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M259.8,995.84c4.02-33.22,15.1-84.74,38.47-88.8"
          />
          <line
            id="ulna1"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            x1="284.6"
            y1="893.37"
            x2="282.55"
            y2="858.77"
          />
          <line
            id="partofancon1"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            x1="24.85"
            y1="822.28"
            x2="50.83"
            y2="804.51"
          />
          <path
            id="radius2"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            d="M115.21,1140.62l-3.46-14.84"
          />
          <line
            id="radius1"
            class="oberarm_mit_ellegnbogen_vd_rechts2232_cls5"
            x1="105.68"
            y1="1099.8"
            x2="124.6"
            y2="1048.6"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="radialheadhighlight"
          class="RadiusHead2232Fill"
          style={{
            fill: props.colors.RadiusHead2232Fill,
            stroke: props.colors.RadiusHead2232Fill,
          }}
          d="M149.42,984.68c1.83-2.39,2.17-5.05,2.26-7.35.27-6.53-1.61-11.76-2.49-16.2-2.32-11.76-3.54-17.7-6.37-20.6-3.84-3.93-8.78-5.08-12.01-5.83-2.22-.52-7.98-1.79-14.32.16-1.14.35-.6.28-6.67,2.63-6.21,2.4-7.33,2.68-8.49,2.83-3.69.47-6.53-.57-9-1.41,0,0-8.62-2.93-18.56-5.1-10.48-2.29-18.69-.84-24.83.24-7.15,1.26-10.94,3.04-13.75,6.07-1.57,1.69-2.32,3.21-3.24,5.06-1.2,2.42-6.45,13-2.02,20.83,1.02,1.81,2.32,3.04,4.73,5.33,2.89,2.75,4.54,3.42,7,5.39,5.33,4.25,4.51,6.43,7.78,8.8,5.38,3.91,9.54-.58,21.97.84,8.74,1,8.83,3.47,16.52,3.44,6.85-.02,7.35-1.98,14.83-2.16,8.73-.2,8.78,2.44,16.45,1.89,8.54-.62,9.67-3.98,15.91-2.7,3.65.75,5.96,2.45,9.44,1.35,2.62-.83,4.25-2.72,4.85-3.51Z"
        />
        <path
          id="epicondylehighlight1"
          class="Epicondylen2232Fill"
          style={{
            fill: props.colors.Epicondylen2232Fill,
            stroke: props.colors.Epicondylen2232Fill,
          }}
          d="M319.59,819.24c-1.49,9-6.51,10.96-4.93,18.2,1.25,5.77,5.18,7.94,4.25,13.35-.57,3.32-2.63,5.83-1.37,9.04,0,0,.62,1.59,2.18,2.89,4.19,3.51,14.47,2.19,22.57-1.74,14.46-7.01,20.43-21.58,22.87-27.53,5.02-12.23,5.3-23.62,4.65-31.34-1.05-7.87-3.36-13.82-5.19-17.66,0,0-6.19-12.99-19.36-23.52-2.21-1.77-3.13-2.13-6.32-4.8-3.85-3.22-4.67-4.51-6.67-4.65-2.61-.19-5.51,1.68-6.47,4.04-.91,2.24.63,3.21-.24,5.59-1.02,2.81-3.6,2.66-4.81,5.34-1.07,2.34.1,4.21.38,6.65.66,5.82-4.47,8.64-6.85,13.78-4.83,10.42,7.9,16.92,5.34,32.36Z"
        />
        <path
          id="epicondylehighlight2"
          class="Epicondylen2232Fill"
          style={{
            fill: props.colors.Epicondylen2232Fill,
            stroke: props.colors.Epicondylen2232Fill,
          }}
          d="M44.8,799.33c.98.14,4.75.72,8.25,3.46,3.2,2.5,3.64,4.81,6.83,9.54,3.33,4.93,4.15,4.34,7.83,9.2,1.9,2.52,6.2,8.29,8.36,15.91,1.74,6.14.05,6.26,1.35,15.37,1.67,11.78,4.65,12.67,4.23,20.11-.45,7.95-3.91,7.86-4.23,15.22-.45,10.29,6.2,13.18,4.85,21.03-1.11,6.49-6.93,12.01-12.67,14.02-14.46,5.06-29.74-11.7-31.62-13.75-8.19-8.98-12.91-21.96-13.55-25.48-.11-.61-.38-2.52-1.42-4.85-.88-1.97-1.54-2.53-2.37-4.35-.98-2.14-1.24-3.93-1.45-5.45-1.95-13.66-.15-26.61-.15-26.61,4.47-32.17,6.06-34.57,8.22-36.67,1.07-1.03,8.44-7.99,17.54-6.69Z"
        />
        <path
          id="jointlinehighlight"
          class="Gelenkspalt2232Fill"
          style={{
            fill: props.colors.Gelenkspalt2232Fill,
            stroke: props.colors.Gelenkspalt2232Fill,
          }}
          d="M284.72,917.03c-3.65,4.21-7.19,8.31-9.41,14.54-.86,2.42-1.27,4.45-3.28,6.05-1.21.96-2.99,1.76-8.54,1.76-3.57,0-7.56-.04-13.09-1.51-5.66-1.51-9.6-3.63-12.95-5.46-1.49-.81-6.92-3.85-13.37-9.24-6.01-5.02-6.3-6.56-11.64-10.18-4.31-2.92-7.08-4.75-10.85-4.71-3.32.04-4.73,1.49-10.46,4.18,0,0-3.78,1.77-12.13,4.58-4.73,1.59-9.13,2.39-28.57,3.33-17.06.83-14.39.25-19.37.79-10.88,1.17-12.67,2.75-28.61,5.44-9.47,1.59-14.32,2.39-19.98,2.27-4.03-.08-13.42-.36-22.45-4.45-5.79-2.62-9.22-5.05-9.22-5.05-3.86-2.73-4.18-3.87-6.09-4.21-4.87-.86-11.95,4.91-12.74,11.73-.61,5.26,2.46,11.21,5.67,11.65.49.07.74-.02,4.04-1.13,3.46-1.16,5.54-1.85,6.07-2.02,3.24-1.04,5.83-1.31,8.7-1.62,8.31-.89,17.43.58,20.77,1.17,15.63,2.76,15.76,5.86,22.65,4.65,4.56-.8,5.45-2.31,14.36-4.25,5.11-1.11,7.67-1.67,10.92-1.42,5.77.44,11.8,2.89,14.04,4.01.44.22,1.96.95,4.1,1.76.83.31,1.81.63,3.15.96,1.9.47,9.25,2.18,18.29.76,1.14-.18,1.98-.38,2.82-.57,5.29-1.19,8.96-2.66,10.59-3.28,5.77-2.18,10.96-2.99,15.8-3.76,6.66-1.05,10.09-1.55,14.54-.49,3.76.9,6.37,2.44,8.7,3.84,6.65,4.01,7.01,6.77,12.74,10.9,5.49,3.95,10.73,5.42,14.56,6.5,7.56,2.12,13.61,2.07,16.79,2.02,3.93-.06,7.79-.12,12.74-1.62,6.23-1.88,10.47-4.91,12.26-6.3.5-.39,2.99-3.08,7.96-8.46,2.32-2.51,3.92-4.29,3.44-5.88-.32-1.05-1.86-3.04-2.66-4.08-.13-.17-.23-.29-.26-.33-1.41-1.79-3.79-5.95-4.38-7.99-1.08-3.76,1.3-9.48,5.86-14.38-5.58,3.3-9.23,6.87-11.51,9.5Z"
        />
      </g>
    </svg>
  );
}
