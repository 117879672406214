import React from "react";
import "./App.scss";
import keycloak from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import RisNet from "./RisNet";
import ThemeProvider from "./Utilities/ThemeProvider";

function setTokens(token, id_token, refresh_token) {
  localStorage.setItem("token", token);
  localStorage.setItem("id_token", id_token);
  localStorage.setItem("refresh_token", refresh_token);
}

function getTokens(token, id_token, refresh_token) {
  return [localStorage.getItem("token"), localStorage.getItem("refresh_token")];
}

const queryClient = new QueryClient();

function App() {
  const [token, refreshToken] = getTokens();

  return (
    <Provider store={store}>
      <ReactKeycloakProvider
        authClient={keycloak}
        onTokens={(tokens) =>
          setTokens(
            tokens.token ?? "",
            tokens.idToken ?? "",
            tokens.refreshToken ?? ""
          )
        }
        initOptions={{ onLoad: "login-required", token, refreshToken }}
      >
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <RisNet />
            {window.conf.DEBUG_MODE && <ReactQueryDevtools />}
          </QueryClientProvider>
        </ThemeProvider>
      </ReactKeycloakProvider>
    </Provider>
  );
}

export default App;
