import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { ClipLoader } from "react-spinners";
import { RestoreExaminations } from "../../Utilities/ClientServices/ClientServices";
import { loadDeletedExaminations } from "../../Utilities/Examinations/DeletedExaminationsUtils";
import SimplifiedExamRow from "../SimplifiedExamRow/SimplifiedExamRow";

function RemovedExaminations() {
  const [deletedExaminations, setDeletedExaminations] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [deletingInp, setDeletingInp] = useState(false);
  const [selectedExaminations, setSelectedExaminations] = useState([]);
  const { keycloak } = useKeycloak();

  const loadExaminations = () => {
    setLoading(true);
    loadDeletedExaminations(keycloak, 10, 1)
      .then((data) => {
        setDeletedExaminations(data);
        setLoading(false);
      })
      .catch((reason) => {
        setDeletedExaminations(undefined);
        NotificationManager.error("Unable to load deleted examinations");
        console.warn("Unable to load deleted examinations: ", reason);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadExaminations();
  }, []);

  const selectHandler = (examData) => {
    let selectedCopy = [...selectedExaminations];
    let exIdx = selectedCopy.findIndex((exam) => exam.id === examData.id);
    if (exIdx > -1) {
      selectedCopy.splice(exIdx, 1);
    } else {
      selectedCopy.push(examData);
    }
    setSelectedExaminations(selectedCopy);
  };

  const isExamSelected = (examDataId) => {
    let exam = selectedExaminations.find((exam) => exam.id === examDataId);
    if (exam) {
      return true;
    } else {
      return false;
    }
  };

  const restoreExam = () => {
    setDeletingInp(true);
    let selected_exams = [];
    selectedExaminations.forEach((exam) => {
      let exTransformed = {
        id: exam.id,
        series: [],
      };
      exam.serie_collection.forEach((serie) => {
        let serTransformed = {
          id: serie.id,
          images: [],
        };
        serie.image_collection.forEach((image) => {
          serTransformed.images.push(image.image_id);
        });
        exTransformed.series.push(serTransformed);
      });
      selected_exams.push(exTransformed);
    });
    let body = {
      Version: window.conf.CLIENT_SERVICES.API_VERSION,
      ExamsSelIDsList: {
        selected_exams,
        is_for_viewer: true,
      },
    };
    RestoreExaminations(keycloak, body)
      .then((resp) => {
        NotificationManager.success("Restoring Examinations success");
        setSelectedExaminations([]);
        setDeletedExaminations(undefined);
        loadExaminations();
        setDeletingInp(false);
      })
      .catch((reason) => {
        NotificationManager.error("Restoring examinations failed");
        console.warn("Restoring examinations failed :", reason);
        setDeletingInp(false);
      });
  };

  return (
    <Container fluid className="pl-1">
      <Row className="mx-2">
        <Col
          md={6}
          sm={12}
          className="pr-4 text-center d-flex flex-column border-right"
          style={{ height: "100vh" }}
        >
          <h4 className="py-3">Deleted Examinations</h4>
          <div className="mb-2">
            <Row className="border rounded bg-light py-2">
              <Col
                xs={1}
                className="d-flex align-items-center justify-content-center border-right"
              >
                <h6>#</h6>
              </Col>
              <Col
                xs={1}
                className="d-flex align-items-center justify-content-center border-right"
              >
                <h6>Mod</h6>
              </Col>
              <Col
                xs={3}
                className="d-flex align-items-center justify-content-center border-right"
              >
                <h6>Patient</h6>
              </Col>
              <Col
                xs={4}
                className="d-flex align-items-center justify-content-center border-right"
              >
                <h6>Exam desctription</h6>
              </Col>
              <Col
                xs={3}
                className="d-flex align-items-center justify-content-center"
              >
                <h6>Exam Date</h6>
              </Col>
            </Row>
          </div>
          {loading && <ClipLoader size={50} />}
          {deletedExaminations?.studies?.length > 0 ? (
            deletedExaminations.studies.map((exam, key) => (
              <SimplifiedExamRow
                exam_data={exam}
                isSelected={isExamSelected(exam?.id)}
                selectHandler={selectHandler}
                key={`deletedExamKey${key}`}
              />
            ))
          ) : (
            <h5>Deleted Examinatons not found</h5>
          )}
        </Col>
        <Col className=" mt-3 text-center">
          <Button
            className="w-100 text-uppercase"
            onClick={restoreExam}
            disabled={selectedExaminations.length === 0 || deletingInp}
          >
            <span className="mx-2">Restore Selected Examinations</span>
            {deletingInp && <ClipLoader size={20} />}
          </Button>
          <Button
            className="w-100 mt-2 text-uppercase"
            variant="secondary"
            disabled={selectedExaminations.length === 0}
            onClick={() => setSelectedExaminations([])}
          >
            Unselect all
          </Button>
          <Button
            className="w-100 mt-2 text-uppercase"
            variant="info"
            disabled={deletingInp}
            onClick={() => {
              setSelectedExaminations([]);
              setDeletedExaminations(undefined);
              loadExaminations();
            }}
          >
            Refresh List
            <i className="fas fa-redo fa-sm ml-1" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default RemovedExaminations;
