import { useMasKvs } from "../../Utilities/FetchHooks/Ris/RisHooks";
import { ClipLoader } from "react-spinners";
import { useMemo } from "react";
import { getDDMMYYY_HHMM } from "../../Utilities/DateTimeUtils/DateTimeUtils";

function ExamTableRow({ exam, dateRange }) {
  const masKvsQ = useMasKvs({
    filter: {
      and: [
        { field: "examid", op: "eq", value: exam.id },
        {
          field: "zeitstempel",
          op: ">=",
          value: getDDMMYYY_HHMM(dateRange.startDate),
        },
        {
          field: "zeitstempel",
          op: "<=",
          value: getDDMMYYY_HHMM(dateRange.endDate),
        },
      ],
    },
  });

  const { count, avgKV, avgMAS, avgUGY } = useMemo(() => {
    if (masKvsQ.status !== "success" || masKvsQ.data.maskv === null) {
      return {};
    }
    let count = masKvsQ.data.maskv.length;
    let sumKV = 0;
    let sumMAS = 0;
    let sumUGY = 0;

    masKvsQ.data.maskv.forEach((entry) => {
      sumKV = sumKV + entry.kv;
      sumMAS = sumMAS + entry.mas;
      sumUGY = sumUGY + entry.dosis;
    });

    return {
      count,
      avgKV: sumKV / count,
      avgMAS: sumMAS / count,
      avgUGY: sumUGY / count,
    };
  }, [masKvsQ.data]);

  return (
    <div className="bpart-exam-grid">
      <div>{exam?.designationde}</div>
      <div>{exam?.kvfrom}</div>
      <div>{exam?.kvtill}</div>
      <div>{exam?.masfrom}</div>
      <div>{exam?.mastill}</div>
      <div>{exam?.cgyqm}</div>
      <div>
        {masKvsQ.status !== "success" ? (
          <ClipLoader />
        ) : (
          count !== undefined && count
        )}
      </div>
      <div>
        {masKvsQ.status !== "success" ? (
          <ClipLoader />
        ) : (
          avgKV !== undefined && Math.round(avgKV * 100) / 100
        )}
      </div>
      <div>
        {masKvsQ.status !== "success" ? (
          <ClipLoader />
        ) : (
          avgMAS !== undefined && Math.round(avgMAS * 100) / 100
        )}
      </div>
      <div>
        {masKvsQ.status !== "success" ? (
          <ClipLoader />
        ) : (
          avgUGY !== undefined && Math.round(avgUGY * 100) / 100
        )}
      </div>
    </div>
  );
}

export default ExamTableRow;
