import style from "./style.scss";

function LinkerKarpaltunnelHandAufliegendAxSitzend1363(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 465.2 492.88">

<g id="Carpaltunnel">
  <path id="partoffinger17" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M434.82,173.25c-16.3-9-27.8-20.6-30.3-37.3-4-11-9.8-20.8-21.5-27.1-20.4-10.7-40.4-13.5-59.7-4.7-21.9,9.9-30.8,19.5-11.7,28.5,14.5,7,29.1,19,43.9,37.8l39.2,52.7c14.9,23.6,30.6,33.6,47.1,30.3,24.1-13,20.2-40.6-7-80.2h0Z"/>
  <path id="carpalbone11" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M332.62,225.55c33.1-8.1,36.3-28.2,30.8-51.8-4.9-25-11.8-46.6-22.4-61.6-6.8-9.8-12.5-20-14.5-31.3-8.4-25-19.1-40-34.1-36.4-6,7.6-5.4,18.4.5,31.7,9.5,20.9,12.2,36.3-3.3,37.3-12,5.1-16.7,13.8-14,28.5,1.5,34.9,9.6,55.8,21,70,10.6,10.9,22.4,16.1,36,13.6h0Z"/>
  <path id="carpalbone10" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M237.92,225.05c17.6,3.6,28.9-5.7,36.9-21.5,3.8-16.2-.1-29.5-7.5-41.5.9-25.9-8.2-45.6-34.1-55.1-17.5-5-29.2.8-33.6,20.5-6.2,13.1-9.8,26.8-9.3,41.5-11,16.3-10.5,29.4.9,39.7,12.2,9.4,28.2,14.3,46.7,16.4h0Z"/>
  <path id="carpalbone9" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M218.52,185.95c-2.4-7-7.3-14-13.8-21.1-11.9-15.5-19.7-35.7-22.6-61.4-.2-2.1-.9-4.2-2-5.9-8.9-14.6-26.1-21.9-51.5-21.8-21.6,4.4-28.1,16.2-22.9,33.6,2.3,24.3,11.7,45,27.5,62.5,17.3,29.9,37.5,45.9,60.8,46.5,17.5.4,30.2-16.1,24.5-32.4h0Z"/>
  <path id="carpalbone8" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M133.92,55.15c-4.7-18.9-11.4-28.5-21.9-21.5l-18.7,23.3-34.5,16.3c-11.3,12.5-8.6,26.3,4.7,41.1,11.8,11.7,20,25.7,16.3,47.6-1.2,24.7,1.9,41.6,14,42.5,12.7,2.3,21.4-14.8,28.5-40.1-.6-19.7,5.1-33.7,16.8-42.5,18-18.7,18.1-33.7,3.2-45.7-5.9-7.2-9.7-14.3-8.4-21h0Z"/>
  <path id="partoffinger16" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M376.02,190.55c28.8-4.6,30.9-21.9,24.3-43.4-4.4-6.2-4.1-12.6-1.4-19.1,1.8-31.4-5.3-46.9-23.3-42.9-18.1-5.6-33.9-7.8-37.8,7.5-13.8,17.1-19.3,35.4-17.3,54.6,1.3,22.2,11.5,36.5,31.3,42.5l24.2.8h0Z"/>
  <path id="partoffinger15" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M445.52,182.55c-7.4-20.8-17-36.3-31.3-40.6-9.6.6-17.2,8.7-21.9,28-7.3,28.1.7,48.7,14,66.7,21.2,24.9,35.7,21.4,47.6,7,6.4-16.2.1-38.1-8.4-61.1h0Z"/>
  <path id="partoffinger14" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M465.12,488.65l-14-153.5c-2.9-26.4-.9-50.5,7-71.9,7-29.3-.3-45.6-24.7-46.2l-27.5,5.6c-29.5,3.9-37.3,19.6-26.1,45.7,3.4,13.6,10.1,23.5,18.7,31.3,19.1,26.7,26.9,59.2,25.2,96.6l6.5,93.3"/>
  <path id="partoffinger13" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M417.12,489.55c8.9-25.3,13.4-54.9,15.9-86.3-.7-24.4,5.4-53.1,14.9-84,7.5-35.5-3-56.2-33.6-60.7-25.8-6.6-50.3-9.5-66.2,11.2-16.9,22.5-10.3,49.7,6.5,78.9,9.5,37.1,3.2,84.7-14.9,140"/>
  <path id="partoffinger12" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M345.22,484.95l-6.5-92.9c.4-14.9,4.8-27.7,13.5-38.3,7.6-19.7,6.6-35.2-2.2-46.8-7.1-9.4-18.4-14.7-30.2-14.9l-17.1-.2c-6.5-.1-12.9,1.3-18.6,4.3-13.9,7.4-16.5,20.2-11.2,36.7-2.1,12.9-3.3,25.4,2.3,35,15.1,33.2,17.3,76.6,13.1,125"/>
  <path id="carpalbone7" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M161.42,186.35c13.6,8.2,17.8-1.2,18.7-16.8,6.4-23,10.5-44.3-9.8-48.1-13.4-4.7-23.1.6-30.3,12.1-12.8,21.5-15.6,36.6-11.7,47.6,4.1,4,9,3.4,14,1.9,7.3-.3,13.4,1.1,19.1,3.3h0Z"/>
  <path id="partoffinger11" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls7" d="M89.22,488.15c-.7-18,4.6-37.2,15.4-57.4,12.3-24.9,9.5-40-2.7-48.8-4.8-3.4-10.4-5.4-16.2-6-22.9-2.4-41.7-1.8-52.6,4.4-1.5.9-3,1.7-4.6,2.4-16.3,7.5-17.3,24.8-8.8,48,13.5,22.5,22.7,43,22.9,59.7"/>
  <path id="partoffinger10" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls1" d="M.12,197.55c9.4-3.3,15.1-9.2,18.7-16.8,33.4,5.5,48.8-11,56.5-36.9-19.7-23.5-26.3-40.7-23.3-53.2,12.6-16.8,11.5-27.2-4.7-30.3-11.3-1.6-25,1.9-41.1,10.3"/>
  <path id="ospisiforme" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls3" d="M337.92,66.85c-.1-8.7-4.6-15.8-13.1-21.5-8.8-10.6-2.3-17.8,5.6-24.7,12.9-23.9,24.8-23,36.4-13.1l23.8,41.6c3.7,6,2.9,12.3-1.4,18.7-3.8,7-9.7,6.4-16.8.9-10.4-5.8-22-6.1-34.5-1.9Z"/>
  <path id="carpalbone6" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls3" d="M225.02,168.15c6.5,28.4,19.9,39.8,42,30.3,24.9,3.2,37.6-4.8,39.2-22.9,4.1-20.7-1.6-37.2-22.9-47.1l-47.1-14.5c-19.9-6.1-31.3-1.5-25.2,25.7l14,28.5h0Z"/>
  <path id="partoffinger9" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls6" d="M434.52,173.25c-16.3-9-27.8-20.6-30.3-37.3-4-11-9.8-20.8-21.5-27.1-20.4-10.7-40.4-13.5-59.7-4.7-21.9,9.9-30.8,19.5-11.7,28.5,14.5,7,29.1,19,43.9,37.8l39.2,52.7c14.9,23.6,30.6,33.6,47.1,30.3,24.1-13,20.2-40.6-7-80.2h0Z"/>
  <path id="carpalbone5" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls4" d="M332.32,225.55c33.1-8.1,36.3-28.2,30.8-51.8-4.9-25-11.8-46.6-22.4-61.6-6.8-9.8-12.5-20-14.5-31.3-8.4-25-19.1-40-34.1-36.4-6,7.6-5.4,18.4.5,31.7,9.5,20.9,12.2,36.3-3.3,37.3-12,5.1-16.7,13.8-14,28.5,1.5,34.9,9.6,55.8,21,70,10.6,10.9,22.4,16.1,36,13.6h0Z"/>
  <path id="carpalbone4" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls4" d="M237.62,225.05c17.6,3.6,28.9-5.7,36.9-21.5,3.8-16.2-.1-29.5-7.5-41.5.9-25.9-8.2-45.6-34.1-55.1-17.5-5-29.2.8-33.6,20.5-6.2,13.1-9.8,26.8-9.3,41.5-11,16.3-10.5,29.4.9,39.7,12.1,9.4,28.2,14.3,46.7,16.4h0Z"/>
  <path id="carpalbone3" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls4" d="M218.22,185.95c-2.4-7-7.3-14-13.8-21.1-11.9-15.5-19.7-35.7-22.6-61.4-.2-2.1-.9-4.2-2-5.9-8.9-14.6-26.1-21.9-51.5-21.8-21.6,4.4-28.1,16.2-22.9,33.6,2.3,24.3,11.7,45,27.5,62.5,17.3,29.9,37.5,45.9,60.8,46.5,17.5.4,30.2-16.1,24.5-32.4h0Z"/>
  <path id="carpalbone2" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls4" d="M133.52,55.15c-4.7-18.9-11.4-28.5-21.9-21.5l-18.7,23.3-34.5,16.4c-11.3,12.5-8.6,26.3,4.7,41.1,11.8,11.7,20,25.7,16.3,47.6-1.2,24.7,1.9,41.6,14,42.5,12.7,2.3,21.4-14.8,28.5-40.1-.6-19.7,5.1-33.7,16.8-42.5,18.1-18.8,18.2-33.8,3.2-45.8-5.9-7.2-9.6-14.3-8.4-21h0Z"/>
  <path id="partoffinger8" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls5" d="M375.72,190.55c28.8-4.6,30.9-21.9,24.3-43.4-4.4-6.2-4.1-12.6-1.4-19.1,1.8-31.4-5.3-46.9-23.3-42.9-18.1-5.6-33.9-7.8-37.8,7.5-13.8,17.1-19.3,35.4-17.3,54.6,1.3,22.2,11.5,36.5,31.3,42.5l24.2.8h0Z"/>
  <path id="partoffinger7" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls6" d="M445.22,182.55c-7.4-20.8-17-36.3-31.3-40.6-9.6.6-17.2,8.7-21.9,28-7.3,28.1.7,48.7,14,66.7,21.2,24.9,35.7,21.4,47.6,7,6.4-16.2.1-38.1-8.4-61.1h0Z"/>
  <path id="partoffinger6" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls6" d="M464.82,488.65l-14-153.5c-2.9-26.4-.9-50.5,7-71.9,7-29.3-.3-45.6-24.7-46.2l-27.5,5.6c-29.5,3.9-37.3,19.6-26.1,45.7,3.4,13.6,10.1,23.5,18.7,31.3,19.1,26.7,26.9,59.2,25.2,96.6l6.5,93.3"/>
  <path id="partoffinger5" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls6" d="M416.82,489.55c8.9-25.3,13.4-54.9,15.9-86.3-.7-24.4,5.4-53.1,14.9-84,7.5-35.5-3-56.2-33.6-60.7-25.8-6.6-50.3-9.5-66.2,11.2-16.9,22.5-10.3,49.7,6.5,78.9,9.5,37.1,3.2,84.7-14.9,140"/>
  <path id="partoffinger4" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls6" d="M344.92,484.95l-6.5-92.9c.4-14.9,4.8-27.7,13.5-38.3,7.6-19.7,6.6-35.2-2.2-46.8-7.1-9.4-18.4-14.7-30.2-14.9l-17.1-.2c-6.5-.1-12.9,1.3-18.6,4.3-13.9,7.4-16.5,20.2-11.2,36.7-2.1,12.9-3.3,25.4,2.3,35,15.1,33.2,17.3,76.6,13.1,125"/>
  <path id="carpalbone1" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls4" d="M161.12,186.35c13.6,8.2,17.8-1.2,18.7-16.8,6.4-23,10.5-44.3-9.8-48.1-13.4-4.7-23.1.6-30.3,12.1-12.7,21.4-15.5,36.6-11.7,47.6,4.1,4,9,3.4,14,1.9,7.3-.3,13.4,1.1,19.1,3.3h0Z"/>
  <path id="partoffinger3" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls1" d="M221.32,489.55l21.9-109.2c13-34,8.2-59.5-14.9-76-20.9-6.6-42.6-9.2-65.8-3.3-34.2,8.3-39.2,29-31.3,55.1l33.1,133.9"/>
  <path id="partoffinger2" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls1" d="M137.32,207.75c-9-13.6-19.5-14-31.7.5-19.1,5.7-29.6,14.3-28.9,26.7.2,4,1.9,7.8,4.3,11,8.9,11.9,12.7,25.4,9.2,41.2-14,39.1-28.7,46.7-43.9,26.4-.6-.8-1.5-1.3-2.5-1.3-10.4-.6-20.3,4.4-28.3,26.7-1.7,32.4,6.1,50.6,18.2,62.5,9.2,26.6,29.9,31.9,56.5,26.1,17.3-12.5,26.6-27.5,27.1-45.3,1.6-55,10.9-83.8,27.5-88.6,26-12.9,34.4-31.9,29.9-55.5-7.9-16.7-20.8-26.2-37.4-30.4h0Z"/>
  <path id="partoffinger1" class="Linker_Karpaltunnel_Hand_aufliegend_ax_sitzend1363_cls2" d="M89.22,488.15c-.7-18,4.6-37.2,15.4-57.4,12.3-24.9,9.5-40-2.7-48.8-4.8-3.4-10.4-5.4-16.2-6-22.9-2.4-41.7-1.8-52.6,4.4-1.5.9-3,1.7-4.6,2.4-16.3,7.5-17.3,24.8-8.8,48,13.5,22.5,22.7,43,22.9,59.7"/>
</g>
<g id="Highlights">
  <path id="carpaltunnelhighlight" class="Karpaltunnel1363Fill" style= {{
            fill: props.colors.Karpaltunnel1363Fill,
            stroke: props.colors.Karpaltunnel1363Fill,
            opacity:props.colors.Karpaltunnel1363Fill,
          }} d="M126.6,35.05c.31-3.79.99-12.07,4.75-13.05,6.66-1.73,11.79,23.76,34.41,44.96,21.13,19.79,46.17,25.33,56.72,27.66,18.29,4.05,39.25,8.68,49.08-1.01,15.26-15.04-5.45-55.6,1.14-57.86,1.45-.5,3.38,1.14,4.75,2.47,10.77,10.47,31.98,46.1,16.23,68.46-19.31,27.41-78.68,12.92-101.69,4.54-27.2-9.9-41.74-25.06-47.12-31.39-6.47-7.62-19.97-23.99-18.26-44.79Z"/>
</g>
</svg>
  );
}

export default LinkerKarpaltunnelHandAufliegendAxSitzend1363;
