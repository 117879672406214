import style from "./style.scss";

function Rechte4zeheDplOblSitzend1418(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.86 155.12">
      <g id="forth_toe" data-name="forth toe">
        <path
          id="forth_toe_1"
          data-name="forth toe 1"
          class="a1418_Rechte_4Zehe_dplobl_sitzend_cls_3"
          style={{
            fill: props.colors.a1418_Rechte_4Zehe_dplobl_sitzend_cls_3,
            stroke: props.colors.a1418_Rechte_4Zehe_dplobl_sitzend_cls_3,
          }}
          d="M30.34,154.88c.55-.99,1.12-1.93,1.71-2.81,4-4.9,4.4-10.1,2.2-15.4,2.2-9.2.3-14.8-6.8-15.8-11.9-6.1-17.1-.5-16.5,15.4-6.1,3.25-5.61,8.73-1.02,15.76"
        />
        <path
          id="forth_toe_2"
          data-name="forth toe 2"
          class="a1418_Rechte_4Zehe_dplobl_sitzend_cls_3"
          style={{
            fill: props.colors.a1418_Rechte_4Zehe_dplobl_sitzend_cls_3,
            stroke: props.colors.a1418_Rechte_4Zehe_dplobl_sitzend_cls_3,
          }}
          d="M28.95,115.37c-7-6.1-13.8-5.6-20.2,4.1-4.1-4.5-4.1-10.7.8-18.8,1.4-12.2,1.5-23.9-3.4-33.8l-3.8-13.1c-2.7-9.8.4-13.6,15-5.2,8.8-3.1,11.5-.6,7.5,7.9-6.6,14.2-3.1,29.7,10.9,46.5,4.1,10.2,2.5,14.9-6.8,12.4h0Z"
        />
        <path
          id="forth_toe_3"
          data-name="forth toe 3"
          class="a1418_Rechte_4Zehe_dplobl_sitzend_cls_3"
          style={{
            fill: props.colors.a1418_Rechte_4Zehe_dplobl_sitzend_cls_3,
            stroke: props.colors.a1418_Rechte_4Zehe_dplobl_sitzend_cls_3,
          }}
          d="M24.45,42.97l-10.9,1.6c-8-.5-10-5.1-6.4-13.5-1.2-6.1,3.1-8.5,13.9-6.8,7.5,1.6,9,5.6,6.8,11.2,3.5,5.4,4.4,9.3-3.4,7.5h0Z"
        />
        <path
          id="forth_toe4"
          data-name="forth toe4"
          class="a1418_Rechte_4Zehe_dplobl_sitzend_cls_3"
          style={{
            fill: props.colors.a1418_Rechte_4Zehe_dplobl_sitzend_cls_3,
            stroke: props.colors.a1418_Rechte_4Zehe_dplobl_sitzend_cls_3,
          }}
          d="M29.85,7.67c.3,4,2.2,4.8,3.9,5.9,5.5,8.6-.9,10.7-15.1,8.6-3.9.6-7.2.2-8.6-2.6s.2-6,3.2-7.2,4.6-2.6,4.8-4.6c.1-2,.8-4,2.3-5.4,4.1-3.9,7.2-1.5,9.5,5.3h0Z"
        />
      </g>
      <g id="overlays">
        <g id="Phalangen_overlay" data-name="Phalangen overlay">
          <path
            id="Phalangen_overlay_1"
            data-name="Phalangen overlay 1"
            class="a1418_phalangenFill"
            style={{
              fill: props.colors.a1418_phalangenFill,
              stroke: props.colors.a1418_phalangenFill,
            }}
            d="M28.95,115.37c-7-6.1-13.8-5.6-20.2,4.1-4.1-4.5-4.1-10.7.8-18.8,1.4-12.2,1.5-23.9-3.4-33.8l-3.8-13.1c-2.7-9.8.4-13.6,15-5.2,8.8-3.1,11.5-.6,7.5,7.9-6.6,14.2-3.1,29.7,10.9,46.5,4.1,10.2,2.5,14.9-6.8,12.4h0Z"
          />
          <path
            id="Phalangen_overlay_2"
            data-name="Phalangen overlay 2"
            class="a1418_phalangenFill"
            style={{
              fill: props.colors.a1418_phalangenFill,
              stroke: props.colors.a1418_phalangenFill,
            }}
            d="M24.45,42.97l-10.9,1.6c-8-.5-10-5.1-6.4-13.5-1.2-6.1,3.1-8.5,13.9-6.8,7.5,1.6,9,5.6,6.8,11.2,3.5,5.4,4.4,9.3-3.4,7.5h0Z"
          />
          <path
            id="Phalangen_overlay_3"
            data-name="Phalangen overlay 3"
            class="a1418_phalangenFill"
            style={{
              fill: props.colors.a1418_phalangenFill,
              stroke: props.colors.a1418_phalangenFill,
            }}
            d="M29.85,7.67c.3,4,2.2,4.8,3.9,5.9,5.5,8.6-.9,10.7-15.1,8.6-3.9.6-7.2.2-8.6-2.6s.2-6,3.2-7.2,4.6-2.6,4.8-4.6c.1-2,.8-4,2.3-5.4,4.1-3.9,7.2-1.5,9.5,5.3h0Z"
          />
        </g>
        <g id="joint_overlay" data-name="joint overlay">
          <ellipse
            id="joint_overlay_1"
            data-name="joint overlay 1"
            class="a1418_gelenkeFill"
            style={{
              fill: props.colors.a1418_gelenkeFill,
              stroke: props.colors.a1418_gelenkeFill,
            }}
            cx="21"
            cy="22.93"
            rx="6.58"
            ry="15.46"
            transform="translate(-4.66 40.48) rotate(-81.87)"
          />
          <ellipse
            id="joint_overlay_2"
            data-name="joint overlay 2"
            class="a1418_gelenkeFill"
            style={{
              fill: props.colors.a1418_gelenkeFill,
              stroke: props.colors.a1418_gelenkeFill,
            }}
            cx="15.91"
            cy="45"
            rx="6.08"
            ry="16.05"
            transform="translate(-30.89 54.39) rotate(-81.87)"
          />
          <ellipse
            id="joint_overlay_3"
            data-name="joint overlay 3"
            class="a1418_gelenkeFill"
            style={{
              fill: props.colors.a1418_gelenkeFill,
              stroke: props.colors.a1418_gelenkeFill,
            }}
            cx="22.17"
            cy="116.35"
            rx="18.68"
            ry="7.9"
          />
        </g>
      </g>
    </svg>
  );
}

export default Rechte4zeheDplOblSitzend1418;
