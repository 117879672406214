import style from "./style.scss";

function LinkesDaumenGrundGelenkDpSitzend1307(props) {
  return (
    <svg
      id="Thumb"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 244 412.3"
    >
      <g id="Background">
        <g id="whitebackground">
          <path
            id="whitebackground-2"
            class="linkes_daumengrundgelenk_dp_sitzend1307_cls2"
            d="M1.3,411.8c14-56.7,29.3-106.3,49.2-132.4,5.2-6.8,9.1-14.5,11.2-22.8,11.8-47.4,15.7-93.1,6.2-135.9-5.5-47.2,2.8-84,24-111.2,13.6-9.9,29.8-12.5,50.9-2.4,13.4,4.9,23.4,19.5,30,43.7,2.1,7.7,4.7,15.2,7.4,22.6,5.1,14.5,6.3,30.9,3.9,49.1-1.2,9.3-1.9,18.6-2.2,28-1.7,52.8,7.8,93.7,46.8,103.2l15.3,124"
          />
        </g>
      </g>
      <g id="Thumb1">
        <g id="thumbbones">
          <g id="Daumen">
            <path
              id="middlehandbone3"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls3"
              d="M113.9,396.2c-2.7-26.1-6.3-50.7-11.2-73-.7-3-2.3-5.7-4.6-7.7-4.2-3.6-6.1-8-6.6-12.7,0-23.2,3.6-37.2,10.3-42.8,21.6-8.5,41.9-11.5,59.7-4.6,1.4.5,2.2,1.8,2.2,3.3v1.2c0,1,.3,2.1.8,3,5.7,10.1,8.8,20.3,7.2,30.7-.5,3.4-1.7,6.7-3.3,9.7-4.4,8.3-6.3,18.5-2.7,31.5.7,2.6,1.4,5.2,1.8,7.8l7.8,45.7"
            />
            <path
              id="Basalphalanx4"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls3"
              d="M96.8,253.2c-5.2,1.6-10.6-1.8-11.1-7.1-.3-3.2.6-6.8,2-10.5l6.2-15.5c11.8-20.4,12.8-42.4,7.8-65.3-.9-4.3-2.3-8.5-4-12.5-3.7-9.1-5.6-16.3-4.8-20.8.4-2.3,2.7-3.8,5-3.6,17.3,1.6,32.6.5,44.8-5.3,6.7-2.1,10.5,5.9,12.6,19.5.6,3.5.2,7-1.1,10.3l-4.6,11.8c-1.6,4.2-2.4,8.7-2.3,13.2.4,16.1,3.2,31.5,8.9,45.9,1.8,4.5,4.3,8.7,7.3,12.5,2.6,3.4,3.9,7.1,2.4,11.6-.4,1.2-.8,2.5-1.1,3.8-2.8,10.9-9.2,15-17.3,15.6,0,0-21.9-4.5-20.4-4.2,1.5.3-21.3-2.1-30.3.6h0Z"
            />
            <path
              id="Thumbendphalanx4"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls3"
              d="M99.5,58.9c0-1.1-.6-2.2-1.4-3-5.7-5.4-5.7-11.2-1.3-17.6,1.3-1.3,2-3,2.4-5,.2-1.2.8-2.2,1.7-3.1,4.7-4.3,9.8-7.4,16.1-6.8,2,.2,4,.1,5.9-.1,7.3-1,13.6,1.4,17.5,12.3,1.1,2.6,1.8,5.2,1.9,7.7.3,3.4-.8,6.8-2.8,9.5-2.4,3.1-3.4,6.8-2,11.4,1.5,7.6,3.8,14.8,8.2,21.1,2.9,3.3,5.9,5.3,9,6,1.2.3,2.2,1.3,2.5,2.5,1.2,5.8-1.5,10.7-8.3,14.8-14.8,7.9-32,9.9-51,7.4-6.7-1.1-10-4.4-11.1-9.1-.6-2.3.3-4.8,2.2-6.3,8.3-6.8,11.5-21.2,10.5-41.7h0Z"
            />
            <path
              id="middlehandbone2"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls5"
              d="M98.8,294.5c.6,4.1,2.6,7.6,8.1,9.6,5.7,1.2,9.9,4.5,10.8,12.8,5.4,23.8,6.5,50,4.9,77.8"
            />
            <path
              id="middlehandbone1"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls5"
              d="M164,261.1c-2.7,5.6-2.6,15.8,0,30.5.2,4.8-1.5,9.6-5.2,14.4-2.6,3.4-4.3,7.4-4.7,11.7-1.6,18.1.1,39.7,5.4,65"
            />
            <path
              id="Basalphalanx3"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls5"
              d="M153.5,245.9c-26.8-3.6-43.3-.9-52.2,6.2"
            />
            <path
              id="Basalphalanx2"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls5"
              d="M113.7,144.1c2.6,44.1-4.9,73.6-19.3,92.8"
            />
            <path
              id="Basalphalanx1"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls5"
              d="M138,141.1c-1.8,13.2-2.3,25.7.8,36,3.3,18,9.6,35.5,18.7,52.6"
            />
            <path
              id="Thumbendphalanx3"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls5"
              d="M98.2,110.4c5.5-2,14.2-1.7,25,0,6.6,1.8,22-8.3,25.9-6.2"
            />
            <path
              id="Thumbendphalanx2"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls5"
              d="M101.3,87.9c4.5-9.1,6.7-20.3,5.4-34.6-1.5-8.5.8-15.5,6.1-21.3"
            />
            <path
              id="Thumbendphalanx1"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls5"
              d="M118.4,31.4c5.9,3.8,9.2,10.8,8.3,22.8,2.3,13.6,7.7,24,15.7,31.8"
            />
            <path
              id="sasamoid2"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls5"
              d="M138.7,275.3c-4.9-6.4-3-10.1,1.4-12.9,4.8-2.6,10.3-2.6,17.4,3.3,4,2.7,6.6,6.3,3.1,14-1.9.3-3.1,2-3.9,4.9-3.1,6.5-10.1.1-18-9.3h0Z"
            />
            <path
              id="sasamoid1"
              class="linkes_daumengrundgelenk_dp_sitzend1307_cls5"
              d="M117.2,264c7.5,4.6,7.7,9.9,3.2,15.5-6.1,7-14.6,3.6-24.2-3.6-4.9-4.3-5.8-8.1-3.7-11.6,1.5-2.6,4.2-4.3,7.2-4.6,4.6-.6,10.5,1,17.5,4.3h0Z"
            />
            <g id="Skinline">
              <path
                id="Skinline3"
                class="linkes_daumengrundgelenk_dp_sitzend1307_cls3"
                d="M1,412c.5-2,1-3.9,1.5-5.8"
              />
              <path
                id="Skinline2"
                class="linkes_daumengrundgelenk_dp_sitzend1307_cls4"
                d="M5.4,394.6c12.8-49.2,26.9-91.4,44.7-114.7,5.2-6.8,9.1-14.5,11.2-22.8,11.8-47.4,15.7-93.1,6.2-135.9-5.5-47.1,2.8-84,24-111.2C105.1.1,121.3-2.5,142.4,7.6c13.4,4.9,23.4,19.5,30,43.7,2.1,7.7,4.7,15.2,7.4,22.6,5.1,14.5,6.3,30.9,3.9,49.1-1.2,9.3-1.9,18.6-2.2,28-1.5,47.2,5.9,84.9,35.4,99.2"
              />
              <path
                id="Skinline1"
                class="linkes_daumengrundgelenk_dp_sitzend1307_cls3"
                d="M222.5,252.4"
              />
            </g>
            <g id="Nailline">
              <path
                id="Nailline2"
                class="linkes_daumengrundgelenk_dp_sitzend1307_cls3"
                d="M81.5,32c-.3,2.1-.5,4-.6,6"
              />
              <path
                id="Nailline1"
                class="linkes_daumengrundgelenk_dp_sitzend1307_cls4"
                d="M81.3,48.9c2.1,11.5,9.9,18.2,27.8,16.5l18.3-2.1c6.8-.8,13.2-3.7,17.8-8.7,6.1-6.5,8.7-15.4,7.7-26.9"
              />
            </g>
          </g>
        </g>
        <g id="Highlights">
          <ellipse
            id="basejointhighlight"
            class="daumengrundgelenk1307fill"
            style={{
              fill: props.colors.daumengrundgelenk1307fill,
              stroke: props.colors.daumengrundgelenk1307fill,
            }}
            cx="127.4"
            cy="254.1"
            rx="42.5"
            ry="11.6"
          />
        </g>
      </g>
    </svg>
  );
}

export default LinkesDaumenGrundGelenkDpSitzend1307;
