import style from "./style.scss";

function LinkeHandDpSitzend1337(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 355.1 493.3"
    >
      <g id="fullhand">
        <g id="outlines13">
          <g id="outlines14">
            <path
              id="outlines16"
              class="linkeHand_dp1337_cls4"
              d="M60.7,487.1c2.2-9,0-19.6.7-28.4,1.2-33.3,6.6-67.2-.7-73.8-12.1-27.1-17.4-67.6-20.9-111.5-3.5-5.9-3.1-20.5-2.3-36.1,1.5-14.5-10.1-45.1-15.9-56.1-2.7-11.3-7.9-27.1-13.8-44-5.7-15.5-9-28.7-6.7-35.7-.8-5.7,2-9.4,7.1-11.9,5.1-1.4,9.7-1.8,13.3,0,11.6-2.5,19.4,38.1,36.7,72.6,11.7,29.2,21,61.4,26.5,53.3,5.7-6.8,6.5-26.2-4.9-76.6l-6.8-60.7c-2.7-26-4.9-51.3,3-56.7,1.8-4.8,5.3-8,11.7-8.4,7.3-1,13.3.4,16.9,6.4,6.1,10.2,9.9,27.1,9.1,56.7l9.9,46c.7,31.6,1.8,61.4,9.4,65.9,3.9-10.9,4-32.7-.3-67.4,1.3-18.4.5-41.1-1.1-65.6-.9-18.7-1.6-37.1.6-45,2.5-5.6,7.1-9.2,15.4-9.4,6.1-.4,11.3,1.3,14.4,7.8,4,.9,8.4,22.5,13.1,53.2-.3,16.4.3,32,3.1,46-.2,27.8.3,53.6,2.1,74.8.7,11.9,3.8,20,12.5,18.8,4.2-13.8,6.6-39.6,8.8-65.8,3.2-8.9,4.6-21.4,5.5-34.6-.2-29.8,2.8-51,11.2-57.2,3-4.2,7.1-6.7,12.7-6.5,4.9,0,9.5,2.1,13.8,7.3,7.3,9.7,7.9,48.8,4.7,104.2l-2.9,15.6c-2.8,44.8-3.9,91.1,8.8,120,10-9.9,19.5-27.3,28.9-46.6,8.9-15.7,24.6-30.3,42.7-44.5,9.3-6,17.4-5.1,24.5.2,2.5,1.9,3.5,5.2,2.6,8.1-3.1,9.2-9.6,19.6-16.3,30.1-3.8,15.4-7.9,29-13.4,37.5-14.7,16.7-25.3,44-34,75.8-15.3,19.2-32.9,38.9-51.9,58.9-2,2.4-10.7,9.2-18.5,15.4-7.6,4-10.8,12.2-13.1,21.4-3.5,12.6-5.5,31.7-6.9,52.8"
            />
            <g id="outlines15">
              <path
                id="outlinepart51"
                class="linkehand_dp1337_cls11"
                d="M60.7,487.1c.4-1.6.6-3.2.8-4.9"
              />
              <path
                id="outlinepart50"
                class="linkehand_dp1337_cls16"
                d="M61.6,472.2c-.2-4.7-.5-9.3-.2-13.5,1.2-33.3,6.6-67.2-.7-73.8-12.1-27.1-17.4-67.6-20.9-111.5-3.5-5.9-3.1-20.5-2.3-36.1,1.5-14.5-10.1-45.1-15.9-56.1-2.7-11.3-7.9-27.1-13.8-44-5.7-15.5-9-28.7-6.7-35.7-.8-5.7,2-9.4,7.1-11.9,5.1-1.4,9.7-1.8,13.3,0,11.6-2.5,19.4,38.1,36.7,72.6,11.7,29.2,21,61.4,26.5,53.3,5.7-6.8,6.5-26.2-4.9-76.6l-6.8-60.7c-2.7-26-4.9-51.3,3-56.7,1.8-4.8,5.3-8,11.7-8.4,7.3-1,13.3.4,16.9,6.4,6.1,10.2,9.9,27.1,9.1,56.7l9.9,46c.5,23.2,1.2,45.4,4.6,57.4"
              />
              <path
                id="outlinepart49"
                class="linkehand_dp1337_cls11"
                d="M130.2,184.3c.9,1.8,1.9,3.1,3.1,3.8.5-1.4.9-3,1.3-4.8"
              />
              <path
                id="outlinepart48"
                class="linkehand_dp1337_cls10"
                d="M135.8,173.6c.9-12.3,0-29.7-2.9-52.9,1.3-18.4.5-41.1-1.1-65.6-.9-18.7-1.6-37.1.6-45,2.5-5.6,7.1-9.2,15.4-9.4,2.7-.2,5.2,0,7.5,1"
              />
              <path
                id="outlinepart47"
                class="linkehand_dp1337_cls11"
                d="M159.4,4.3c1.1,1.1,2,2.4,2.8,4.1.9.2,1.9,1.6,2.9,3.9"
              />
              <path
                id="outlinepart46"
                class="linkeHand_dp1337_cls5"
                d="M168.1,22c2.3,9.4,4.7,23.2,7.2,39.6-.3,16.4.3,32,3.1,46-.2,27.8.3,53.6,2.1,74.8.4,6.4,1.5,11.8,3.7,15.1"
              />
              <path
                id="outlinepart45"
                class="linkehand_dp1337_cls11"
                d="M188,200.7c1.4.6,3,.8,4.9.5.4-1.5.9-3.1,1.3-4.8"
              />
              <path
                id="outlinepart44"
                class="linkehand_dp1337_cls14"
                d="M196.2,186.7c2.4-14.1,4-32.6,5.6-51.3,3.2-8.9,4.6-21.4,5.5-34.6-.2-29.8,2.8-51,11.2-57.2,3-4.2,7.1-6.7,12.7-6.5,4.9,0,9.5,2.1,13.8,7.3,7.3,9.7,7.9,48.8,4.7,104.2l-2.9,15.6c-2.5,40.2-3.7,81.7,5.3,110.7"
              />
              <path
                id="outlinepart43"
                class="linkehand_dp1337_cls11"
                d="M253.6,279.7c.6,1.6,1.2,3.1,1.9,4.6,1.1-1.1,2.3-2.4,3.4-3.7"
              />
              <path
                id="outlinepart42"
                class="linkehand_dp1337_cls12"
                d="M264.8,272.8c6.6-9.6,13.1-21.9,19.5-35.1,8.9-15.7,24.6-30.3,42.7-44.5,9.3-6,17.4-5.1,24.5.2,2.5,1.9,3.5,5.2,2.6,8.1-3.1,9.2-9.6,19.6-16.3,30.1-3.8,15.4-7.9,29-13.4,37.5-14.7,16.7-25.3,44-34,75.8-15.3,19.2-32.9,38.9-51.9,58.9-2,2.4-10.7,9.2-18.5,15.4-7.6,4-10.8,12.2-13.1,21.4-3,10.6-4.8,25.8-6.2,42.9"
              />
              <path
                id="outlinepart41"
                class="linkehand_dp1337_cls11"
                d="M200.4,488.3c-.1,1.6-.2,3.3-.3,5"
              />
            </g>
          </g>
        </g>
        <g id="fullbones">
          <path
            id="bonebonepart77"
            class="linkehand_dp1337_cls11"
            d="M193.2,383c-3.4-5.1-1.3-9.6,4.4-13.5,3.4-3.6,8.8-6,14.9-7.9,13.9-4.7,25.4-11.4,33.1-21.5,2.8-3.6,3.8-8.2,3.2-12.7-1.2-9.1.7-14.4,5.9-15.3,6.3-1.9,12.2-1.6,17.6,2.1,6.3,1.7,9.2,9.1,9.7,20.4.8,3.5-1.5,6.7-6.7,9.7-17,12.7-32.1,29-45.9,47.6-5.4,6.1-10.5,8.6-15.4,6.5-7.5-1.1-10.3-4.9-10.2-10.1l-10.6-5.2h0Z"
          />
          <path
            id="bonebonepart76"
            class="linkehand_dp1337_cls11"
            d="M258.7,300.6c-.1-2.5,1.2-4.9,3.1-7.2,13.4-8.2,23.9-17.2,28.5-27.9,1.1-2.5,1.3-5.4.7-8.1-1.3-6.1.2-10.4,5-12.8,5.7-1.6,9.8,1,12.3,7.6.5,1.4,1.7,2.5,3.1,3,4.4,1.6,5.8,4.1,2.9,8-10.1,15.8-19.4,31.4-22.7,45-1.5,8.2-3.9,11.5-7.2,10.5-5.8-7.1-13-11.7-21.5-13.5-2.3-.5-4.1-2.3-4.2-4.6h0Z"
          />
          <path
            id="fingertipbonepart75"
            class="linkehand_dp1337_cls11"
            d="M310.3,250.3c-1-5.1-3.9-8.7-9.2-10.7-1.4-6.1,1.5-8.9,7.3-9.5l14.9-13.2c-1.2-5.4,3.8-9.5,15.5-12.2,3.6-.6,6.5-.2,5,6.7-1.7,6.1-3.8,9-6.3,8.9-7.2,8.9-12.8,19.4-16.4,31.7-.4,1.4-2.1,2-3.3,1.2-2.8-1.9-5.4-3-7.5-3h0Z"
          />
          <path
            id="fingertipbonepart74"
            class="linkehand_dp1337_cls11"
            d="M301.1,239.6c-.7,4-.4,7.6,2,9.8,2.2,1.4,4.6,1.9,7.2.9-.4,1.2-.4,2.1,0,2.6,2.6,2.3,5.1,2.2,7.5.3"
          />
          <path
            id="bonepart73"
            class="linkehand_dp1337_cls11"
            d="M260.3,304c-.3,1.5-.1,2.8,1,3.7,3,1.6,6.6,1.4,10.5.7.2,4.4,1.1,8.6,5,11.5,3.3,1.3,5.9.9,7.6-1.1"
          />
          <path
            id="bonepart72"
            class="linkehand_dp1337_cls11"
            d="M257.4,319.8c-1.6-1.2-2.3-3.4-1.6-5.2.8-2,2.9-2.6,6.5-1.5,2.8.9,5,2.1,6,3.9,1.8,3.5.3,5.2-3,5.9-3.5-.7-6.2-1.7-8-3Z"
          />
          <path
            id="bonepart71"
            class="linkehand_dp1337_cls11"
            d="M248.2,305.3c3.4-.4,4.6.8,3.6,3.6-.2.9-.7,1.6-1.4,2.2-1.3,1.1-3.2,1.1-4.5,0-1.1-.9-1.1-1.9-.9-3,1.1-1.9,2.1-3,3.1-3h0Z"
          />
          <path
            id="bonebonepart70"
            class="linkehand_dp1337_cls11"
            d="M179,373.7c.5.9,1.3,1.5,2.3,1.9,5.7,2.2,10.8-1.9,15.2-11.7,1.1-2.5,1.4-5.1.6-7.9-7.4-13.9-5.3-31.1,1.4-49.8l15.4-36.2c1.1-2.7,3.2-4.8,5.8-6.1h0c2-1,3.4-2.8,3.8-5,2.5-13.8-.2-22.4-6.6-27.6-6.4-4.6-12.1-2.5-17.2,3.1-2.7,0-4.5,1.9-5.6,5l-3.3,3.6c-.6.7-.9,1.6-.9,2.5.8,27.4-4.5,56.3-18.5,87.3-1.7,2.6-4.6,4.4-8,5.8-2.5,9.5-7,17.5-13.3,24.3-1.2,1.3-.5,2.9,2.1,4.9,1.3,1,3,1,4.3,0,4.3-3.6,9.3-4.2,14.9-2.1,2.7,1,5.2,4.1,7.6,7.9h0Z"
          />
          <path
            id="bonepart69"
            class="linkehand_dp1337_cls11"
            d="M199.7,234.6l-1.1,5-1.8,3.5c0,2.6-.7,5-2.7,7.2-.2,3,0,6.1.6,9.5"
          />
          <path
            id="bonepart68"
            class="linkehand_dp1337_cls11"
            d="M218,249.1c-.7,1.7-.5,3.2,1,4.5,2.4,3.4,0,8.5-3.6,13.8"
          />
          <path
            id="bonebonepart67"
            class="linkehand_dp1337_cls11"
            d="M224.4,233.3c5.4,1.1,8.2-2,8.4-9.5l-3.7-22.2c-.3-1.8-.3-3.7,0-5.5,1.4-8.8,1.9-17.9,1.8-27.1,0-1.5.4-2.9,1-4.2,1.9-3.7,2.9-7.5,3.1-11.5.6-8.5-2.6-10.3-7.6-8.7-3,.6-6,0-9-1.2-3.7-1.8-6.3-1-8,2.1-2,4.8-3.2,9.3-1.8,12.7.4.9.6,1.9.5,2.8l-2.5,24.9c-.7,9.6-4.1,19.1-9,28.5-2.1,7.5-2.7,13.6,2.5,14.7,3.8-3.8,8-5.1,12.6-4.3,5.8,1,9.1,4.3,11.6,8.5h0Z"
          />
          <path
            id="bonepart66"
            class="linkehand_dp1337_cls11"
            d="M200.1,229.1l8.2,2.4c5.7,2,11.1,2.7,16,1.8"
          />
          <path
            id="bonepart65"
            class="linkehand_dp1337_cls11"
            d="M208.1,152.9c2.4-.9,3.7-2.2,4.1-3.8l-1-4.9"
          />
          <path
            id="bonepart64"
            class="linkehand_dp1337_cls11"
            d="M234.4,157.5c-1.3-.6-2.6-3.1-3.2-5.6,0-2.2.6-4.4,2.4-6.4"
          />
          <path
            id="bonebonepart63"
            class="linkehand_dp1337_cls11"
            d="M212.2,138.7c-3.5-2.3-2.1-5.9.8-10,5.1-5.8,6.9-13.2,5.9-22.1-1.8-3-1.5-6.1,0-9.3-.4-4.8,1-6.3,3.5-5.5,3.9.9,7.5,1.2,10.4,0,4.1-1.6,5.4.7,5.1,5.2,1.9,2.8.7,5.8-2.9,8.9-1,9.9,0,19.2,2.9,28-.2,4.3-2,6.3-5.1,6.5-5.1,3-9.5,2.7-13.3-1-2.5-1.8-5-2.2-7.2-.7h0Z"
          />
          <path
            id="bonepart62"
            class="linkehand_dp1337_cls11"
            d="M234.4,140.2c-.6,1-1.7,2-3,2.8-4.7,2.9-10.7,2.5-15.3-.6-1.7-1.1-2.9-2.3-3.9-3.6"
          />
          <path
            id="fingertipbonepart61"
            class="linkehand_dp1337_cls11"
            d="M223.3,88.7c-7.1-.1-7.3-2.8-4.6-6.5,3.8-3.5,4.6-10.6,4.6-18.8-3.8-2.4-2.2-6.4,0-10.6.7-4.6,3.2-6.7,7.9-5.2,3.1,1.6,5,3.9,5.4,7,2.6,5.3,1.5,8.1-1.5,9.8l2.6,19.1c.5,1.4.7,2.8-.3,4-.9,1.1-2.4,1.7-3.8,1.6l-10.1-.4h0Z"
          />
          <line
            id="bonepart60"
            class="linkehand_dp1337_cls11"
            x1="223.3"
            y1="56.4"
            x2="223.3"
            y2="63.4"
          />
          <line
            id="bonepart59"
            class="linkehand_dp1337_cls11"
            x1="234.4"
            y1="58.7"
            x2="235.1"
            y2="64.3"
          />
          <path
            id="bonepart58"
            class="linkehand_dp1337_cls11"
            d="M237.3,87.5c-4.6-1.6-9.3-2.2-13.9-1.6"
          />
          <path
            id="bonebonepart57"
            class="linkehand_dp1337_cls11"
            d="M137.7,359.5c7.6,1.9,13.6.8,16.6-5.9,5.7-6.5,5.6-13.3,1.2-20.5-4.3-22.9-2.1-40.3,2.3-55.9,1.4-5.1,3.2-9.4,5.6-12.8,2.4-1.2,3.3-3.4,3.3-6.1,3.3-.6,5.4-2.5,6.6-5.4v-17.1c.3-4.9-2.3-8.1-6.4-10.4-7.8-2.8-14.5-1.6-20.3,2.7-.2,2.2-1.8,4.8-4,7.6-3.9,3.6-4.5,10.2-3.1,18.8.4,16.7-1.2,38-3.5,60.9-1.8,5.8-4,10.2-6.9,12.1-.7.5-1.4,1.1-1.7,1.9-2.4,5.2-4.4,10.7-5.7,16.6-.5,2.3.1,4.7,1.8,6.3,3.5,3.3,8,5.9,14.1,7.1h0Z"
          />
          <path
            id="bonepart56"
            class="linkehand_dp1337_cls11"
            d="M150.2,322.3l.9,9.3c.1,1.1.5,2.1,1.1,3,.7,1.1,1.3,2.2,1.6,3.4.5,1.9,0,3.9-1.2,5.4h0c-.8,1-1.1,2.2-1,3.4l.5,4.1"
          />
          <path
            id="bonepart55"
            class="linkehand_dp1337_cls11"
            d="M167.4,229l-.8,7.4c-.1,1,.2,2,.9,2.7,2.8,3,3.8,6.8,1.8,11.9-3.1,3.4-5.4,10.1-6.9,15.1"
          />
          <path
            id="bonepart54"
            class="linkehand_dp1337_cls11"
            d="M148.7,229.9c-.1,1.8-.7,3.6-1.8,5.2-3.9,6.9-4.6,13.4-.6,19.5"
          />
          <path
            id="bonepart53"
            class="linkehand_dp1337_cls11"
            d="M129.5,337.5c-.6-3.7.4-7.8,2-12"
          />
          <path
            id="bonebonepart52"
            class="linkehand_dp1337_cls11"
            d="M142,223.2c-5.2-4.8-2.9-14.4,1.6-25.5,1.7-7.9,1.6-18,1-28.6-1.7-6.6-2-14.1-1-22.2.6-2.9-.2-6.4-1.4-10-.6-6-1.4-12.2,3.9-13.4l10.3.8c4.9-2.7,8.7-3.4,9.8.6,2.4,2.8,4,6,2.8,10.8-1.8,4.9-2.7,9.6-1.4,13.9-1.1,20.5-1.4,39.2,2.5,49.2,6.4,8.9,9,16.7,5.1,22.8-1.1,1.8-3.3,2.5-5.4,2-8.2-2-16.5-1.8-24.9.4-1.1.3-2.4,0-3.2-.8h0Z"
          />
          <path
            id="bonepart51"
            class="linkehand_dp1337_cls11"
            d="M142.2,136.3c.8-.6,1.5-1.3,2.1-2.1,1.9-2.6,2.1-6.2.3-8.8-.3-.4-.6-.8-1-1.2"
          />
          <path
            id="bonepart50"
            class="linkehand_dp1337_cls11"
            d="M168.7,137c-.9-.7-1.7-1.6-2.6-2.9-.9-1.3-1.4-2.9-1.3-4.5s.7-3.1,2.1-4.1"
          />
          <path
            id="bonepart49"
            class="linkehand_dp1337_cls11"
            d="M147.9,220.4c7.1-2.9,14.2-2.9,21.3,0"
          />
          <path
            id="bonebonepart48"
            class="linkehand_dp1337_cls11"
            d="M141.1,117.4c-.8-2.8-.1-6.1,1.1-9.6,3-15.5,4.1-28.1,0-32.8-2.1-2.5-1.8-5.4,0-8.5-.4-3.3-.4-6.1,2.7-5.5,3.3.4,6.9-.5,10.6-2.3,6.3-1.3,9.5.6,9.3,6.3.6,3.3-.2,5.6-1.9,7.2-.7,11.4,1.6,22.6,6.4,33.6,2.1,3.7,2.6,7.2.6,10.4-.8,1.3-2.2,2.1-3.7,2.1-2.4,0-4.9.7-7.4,1.8-1.2.6-2.6.7-3.9.4-3.7-1-7-1.3-9.8-.7-1.7.4-3.6-.6-4-2.3h0Z"
          />
          <path
            id="fingertipbonepart47"
            class="linkehand_dp1337_cls11"
            d="M143.5,24.8c.4,13.4-.6,22.7-3.5,26.8-.7,1-.7,2.3-.1,3.3,1.1,1.8,2.8,2.5,5.2,2.3l11.7-.7c1.2,0,2.4-.2,3.5-.6,2.7-.8,3.6-2,2.8-3.6-3-.7-5.2-11.1-7.2-24.1"
          />
          <path
            id="fingertipbonepart46"
            class="linkehand_dp1337_cls11"
            d="M144,33.2c-3.4.2-5.2-2.3-4.6-8.9,1.2-5.8,3.2-10.6,8.6-11.1,3.8-1,7,.5,9.3,6.9,2.6,6.8,1.8,10.3-.8,12.3"
          />
          <path
            id="bonepart45"
            class="linkehand_dp1337_cls11"
            d="M127.9,350.9c-.5,6.2-3.1,9.7-9.5,8.4l-22.9-7.8c-4.5-1.8-4.3-5.4-1.4-9.9,2.5-6.2,5.5-10.2,8.7-12.3,1.4-.9,2.2-2.6,2.2-4.3v-32.9c0,0-3.8-33.3-3.8-33.3-3.4-3.9-3.2-8.5,0-13.7.2-8.2,3.1-12.8,9.7-12.2,5.9-1.2,10,.1,12.8,3.4,2.3,5.5,3.6,10.4,4.1,14.7-.9,7.8-3.2,15.8-6.3,24-1.1,17.5-1.6,33.8,0,46.4,4.1,7.1,6.4,16.5,6.3,29.4h0Z"
          />
          <path
            id="bonepart44"
            class="linkehand_dp1337_cls11"
            d="M107.6,260.9c-3.1-1.2-4.7-3.7-4-8l.9-6.3c.4-2.9-.7-5.3-2.4-7.5"
          />
          <path
            id="bonepart43"
            class="linkehand_dp1337_cls11"
            d="M121.6,239.6c.7,4.1,2.8,7.9,6.3,11.5"
          />
          <path
            id="bonepart42"
            class="linkehand_dp1337_cls11"
            d="M101.7,334.6c1.8-1.4,2.4-4.4,2.9-7.2"
          />
          <path
            id="bonepart41"
            class="linkehand_dp1337_cls11"
            d="M95.7,230.7c-1.1-1.5-1.5-3.8-1.5-6.6,0-1.1.2-2.2.6-3.3l2.8-7c.4-1,.7-2.1.8-3.2,1.6-12.5-.2-25.1-2.2-37.6-1.1-4.1-1-8.7-.8-13.4,0-1.5-.8-3.2-2.2-5-1.6-6.5-2.3-12.6-1.2-17.7,2.6-2.4,5.6-3,8.8-2,7.3-3,11.8-3.8,12.3-1.1,2.8,1.9,4.2,5.5,4.3,10.3l-1.3,7.8c-.2,1.3-.1,2.6.2,3.9,1.3,4.7,2,9.3,1,13.5.1,19,2,36.3,10.4,47.5,1.8,3.9,2.1,7.7.3,11.1-.8,1.6-2.7,2.4-4.5,2-8-1.9-15.7-1.4-22.8,1.9-1.8.8-4,.4-5.2-1.2h0Z"
          />
          <path
            id="bonepart40"
            class="linkehand_dp1337_cls11"
            d="M113.2,133.8c-.8,2.1-1,4.4,0,7.3,1.4,2.8,2.8,4.1,4.2,3.8"
          />
          <path
            id="bonepart39"
            class="linkehand_dp1337_cls11"
            d="M92.1,137c1.9,2.2,2.7,4.6,2.5,7.2.5,3.8,0,7-1.4,9.7"
          />
          <path
            id="bonepart38"
            class="linkehand_dp1337_cls11"
            d="M99.5,229c6.8-3.3,14.6-3.9,23.5-2.1"
          />
          <path
            id="bonepart37"
            class="linkehand_dp1337_cls11"
            d="M102.9,231.4c2.2,1.7,5.7,1.8,9.6,1.3,3.9-.4,6.6-1.6,8.6-3.2"
          />
          <path
            id="bonepart36"
            class="linkehand_dp1337_cls11"
            d="M93.4,132.2c-4.5,2.4-5.8-1.2-5.9-7.4l1.5-24.6c1.6-6.4-4.4-8.1-4.9-15.6-.6-6.5,0-10.9,4.7-9,4.4.3,8.9-.1,13.5-1.4,3-1.2,3.7.6,3.4,3.7,1.3,2.6,1.5,5.2.7,7.8-2.1,6.1-2.1,11.7,1.4,16.4,2.8,12.2,11.4,25.2,6.4,25.9-3.6-.4-7.3,1.1-11.1,3.9-3.7-.9-6.9-.7-9.6.4h0Z"
          />
          <path
            id="fingertipbonepart35"
            class="linkehand_dp1337_cls11"
            d="M83.8,69.9c-4.1-.8-3.3-3.4-1.2-6.5,3.4-2.8,4.7-9.5,4-19.9-3.4,0-3.7-4-2.8-9.2.1-6.3,3.3-8.1,7.7-8.3,5.4.6,7.7,2.5,8.2,5.2,2.7,7.3,2.4,11.5-1,12.3-.3,7.2.8,13.6,4.7,18.6,3.2,2.9,3.9,5.3-.9,7,.2,1.3-1.8,2.1-5.7,2.5-5.5.5-10.1.2-12.9-1.6h0Z"
          />
          <path
            id="fingertipbonepart20"
            class="linkehand_dp1337_cls11"
            d="M21.6,137.6c-5.1,3-5.7-3.4-4.9-12.7l-2.3-8.5c-4.4-1.8-5.5-4.8-4.7-8.5.2-.9.2-1.8,0-2.6-.7-2.3.6-4,3.5-5.1,2.7-1.1,5.9-.4,7.8,1.8,1.8,2.1,3,5.2,3.8,9.4l-.4.8c-.5,1.1-.5,2.3-.1,3.3,1.4,4,3.8,7.3,7.3,10,3.6,4.4,3.2,7.5-2.2,8.9-2.8.2-5.4,1.5-7.8,3.2h0Z"
          />
          <line
            id="bonepart34"
            class="linkehand_dp1337_cls11"
            x1="86.4"
            y1="37"
            x2="86.7"
            y2="43.5"
          />
          <line
            id="bonepart33"
            class="linkehand_dp1337_cls11"
            x1="97.7"
            y1="37"
            x2="98.7"
            y2="43.5"
          />
          <path
            id="bonepart32"
            class="linkehand_dp1337_cls11"
            d="M88,68.9c1.8,1.4,4.1,1.5,6.7.7,3.8-1,6.5-1.2,7.8-.6"
          />
          <path
            id="bonepart31"
            class="linkehand_dp1337_cls11"
            d="M144.6,55c4.3-1.7,9.1-2,14.4-1.5"
          />
          <path
            id="bonebonepart30"
            class="linkehand_dp1337_cls11"
            d="M92.1,364.2l-.6-.3c-6.3,1.2-11.3,0-12.6-8-3.3-3.3-3.9-8.8-3.9-14.8l1.4-8.2c-2.1-26.4-18.6-51.3-17.9-65.5-1.9-5-2.1-9.8,0-14.4,3.5-3.1,7.8-5.2,13.6-5.5,4.8.6,8.3,4.4,10.6,11.2.2.6.2,1.4.1,2-1.7,10-1.8,19.4-.2,28.2,5.1,18.1,10.3,35.4,17.5,44.1,2.9,3,3.6,6.3,2.7,9.8,5,6,6.4,11.7,2.4,16.7-2.4,3.4-8.7.8-13.3,4.6h0Z"
          />
          <path
            id="bonepart29"
            class="linkehand_dp1337_cls11"
            d="M58.5,266.8c3-3.3,3-7.9,0-13.8"
          />
          <path
            id="bonepart28"
            class="linkehand_dp1337_cls11"
            d="M76.3,249.1c0,6,1.2,11.6,5.9,15.9"
          />
          <path
            id="bonebonepart27"
            class="linkehand_dp1337_cls11"
            d="M55.1,250.7c-3,1.6-5.3.7-6.5-3.7-.2-.7-.3-1.4-.2-2.1l.9-16.7c0-1.4-.2-2.9-.7-4.2-2.6-6.2-4.1-12.3-4.6-18.4-.2-2.4-.9-4.6-2.2-6.6-3.5-5.2-6.5-10.3-6.8-14.8-.1-1.6.7-3.1,2-3.9s3.3-1.3,5.7-1.7c1.3-.2,2.4-.8,3.3-1.7,2.9-3.2,5.2-2.9,7.1-.2,1.7,3.2,2.6,6.5,2.6,10.1,0,1.7.4,3.4,1.2,5,6,12.7,9.2,26.7,14,34.2,5.4,5.1,8.8,10.1,8,14.8-.4,2.1-2.3,3.4-4.4,3.3-7.2-.3-13.1,1.3-17.6,5.3-.6.5-1.2,1-1.8,1.4h0Z"
          />
          <path
            id="bonepart26"
            class="linkehand_dp1337_cls11"
            d="M55.4,247.1c5-4.7,10.9-6.5,17.9-5.4"
          />
          <path
            id="bonepart25"
            class="linkehand_dp1337_cls11"
            d="M57,249.3c2.5,1.6,5.7,1.4,9.6-.2,4.1-.7,7.2-2.2,7.9-5.2"
          />
          <path
            id="bonepart24"
            class="linkehand_dp1337_cls11"
            d="M38,193c1.8-1.4,2.3-3.5,1.7-6.3-.7-2.3-2.2-3.7-4.4-4.4"
          />
          <path
            id="bonepart23"
            class="linkehand_dp1337_cls11"
            d="M55.4,183.1c-2-.2-3.2-1.4-3.7-3.8-.7-1.3-.7-2.6.3-3.8"
          />
          <path
            id="bonebonepart22"
            class="linkehand_dp1337_cls11"
            d="M35,176.6c-4.4,2.3-6.8.5-7.2-5-.5-4.8-1.1-9.7-1.7-14.7-.2-1.8-1.1-3.4-2.4-4.5-3-2.5-4.6-5.3-4.4-8.2,0-1.7,1.4-3.1,3.1-3.5,3.5-.8,6.6-2.1,9.1-4,1.1-.9,2.7-1,3.8-.1,1.8,1.3,3.2,3.8,3.9,7.7-1.5,4.1,1.5,10.6,7.8,18.9,3.8,4.3,3.2,7,0,8.8-3.7,2.8-7.6,4.7-11.9,4.7h0Z"
          />
          <path
            id="bonepart21"
            class="linkehand_dp1337_cls11"
            d="M33.9,177.1c-1.5,2.7-3,2.9-4.5-.4"
          />
          <line
            id="bonepart19"
            class="linkehand_dp1337_cls11"
            x1="12.6"
            y1="109.9"
            x2="14.6"
            y2="116.9"
          />
          <path
            id="bonepart18"
            class="linkehand_dp1337_cls11"
            d="M212.2,242.8c1.8-2.4,3.2-1.4,4.4,1.2.4,1.2,0,2.4-1.1,3.6-1.6,1.6-3,1.1-4.2-1.7-.3-1.1,0-2.1.8-3.1h0Z"
          />
          <path
            id="bonepart17"
            class="linkehand_dp1337_cls11"
            d="M66.6,257.1c1.4-1.1,2.4-.8,3.1,1.2,1,2.9.4,4-1.5,3.5-1.2.7-1.9-.1-2.4-2l.9-2.6Z"
          />
          <path
            id="bonebonepart16"
            class="linkehand_dp1337_cls11"
            d="M188.5,370.8c6.8-2,9.6,1.2,9.7,7.9-.4,7.2.7,13.6,6.5,17.7,1.6,2.9-2.4,5.9-2.7,9.2,0,.7-.3,1.4-.6,2-1.7,2.7-4.5,3.6-7.9,3.4-2.4-1.7-5.5-2.1-9-2.1-1.2,0-2.3-.7-2.7-1.9-1.3-4.1-3.9-8.2-7.3-12.4,0-6.6,1.6-11.3,4.1-14.4l6.2-7.2c1-1.1,2.3-2,3.8-2.5h0Z"
          />
          <path
            id="bonepart15"
            class="linkehand_dp1337_cls11"
            d="M182.6,379.9c1.6,3.8-.7,7.3,0,10.7.6,3.9,2.8,6.2,8.3,4.9,3,.5,5-3.9,6.7-10.4"
          />
          <path
            id="bonebonepart14"
            class="linkehand_dp1337_cls11"
            d="M157,370.9c3.6-3.8,7.9-4.8,13.3-2.1,7.2,4.3,8.9,10.3,8.1,17.2-.8,4.9-2.4,7.7-4.4,9-1,.7-2.4.5-3.4-.3-3.9-3.5-7.8-5.9-11.7-6.6-1.1-.2-1.9-1.2-1.9-2.3,0-4.9-.1-9.3-.8-12.2-.2-.9,0-1.9.7-2.6h0Z"
          />
          <path
            id="bonebonepart13"
            class="linkehand_dp1337_cls11"
            d="M146.2,403.2c1.5-.9,2.5-2.4,2.8-4.2.8-4.6,2.3-6.8,4.4-6.8,6.5-.6,11.7.9,15.2,5.2.4.5.9,1,1.4,1.4,4.7,4.1,7,7.7,6,10.6-.4,1.1-.1,2.3.5,3.3,1.9,3.1.7,5.9-2.8,8.4-1.3.9-2.4,2-3.5,3.1-8.2,8.8-16.8,11.9-25.5,12.1-2.1,0-4.1-.2-6-1-3.3-1.3-5.1-3.6-5.5-6.6-.2-1.3,0-2.7.3-4,1-3.3,2.1-6.5,3.6-9.4,2.2-5.7,5.2-9.7,9.1-12.1h0Z"
          />
          <path
            id="bonepart12"
            class="linkehand_dp1337_cls11"
            d="M136,417.8c4.3-.9,8.3-2.7,11.5-6.8,3.6-3.1,3.2-6,.8-8.8-.1-.1,0-.4.2-.3,3,.9,4.8-.4,5.5-3.7v-6.2"
          />
          <path
            id="bonebonepart11"
            class="linkehand_dp1337_cls11"
            d="M114.2,394c-1,3.2-1.3,6.6-.7,9.8.8,4.2,2.7,6.8,6.1,7.2,8.5,2.1,16.5,2.4,23.5-.7,3.8-1.9,5.3-4.9,5.5-8.4,1.5-3.4,1.7-6.5-1.1-8.7-1-1-1.4-2-1.4-3.2,0-2.1,1.3-4,3.2-4.9l.6-.3c2.3-1.1,3.8-3.3,3.8-5.8,0-3.4-1.7-6.1-4.3-8.2-.8-.7-1.4-1.6-1.7-2.6-1.1-5.2-5-6.6-10.2-6.5-1.5,0-3-.4-4.3-1.3-7.9-5.4-12.4-6-14.9-3.7-.7,1.3-1.9.5,0,11.6l.6,7.4c.1,1.4,0,2.7-.4,4l-4.4,14.3h0Z"
          />
          <path
            id="bonebonepart10"
            class="linkehand_dp1337_cls11"
            d="M85.7,356.8c-1.5,1.9-2.7,3.9-3.4,5.9,0,7.8,4,11.9,4.9,16.7.2,1.3,1,2.4,2.1,3,6.9,4,12.8,9.8,16.4,19.1.5,1.2,1.3,2.3,2.4,3,4,2.5,6.9.7,9.2-4,.2-.4.3-.8.5-1.2,1.8-6.1,2.7-13.1,2.9-20.7,1.4-13.2-.5-18.7-4.2-20l-25.4-4c-2.1-.3-4.2.5-5.5,2.1h0Z"
          />
          <path
            id="bonepart9"
            class="linkehand_dp1337_cls11"
            d="M97.8,365.1c3.6.4,7.1-.4,10.6-2.9,2.3-3,4.7-3.7,7.2-1.4,1.5,1.1,1.7,2.5.9,4.2-.6,1.2-1,2.5-1.1,3.8l-.4,4.5c-.2,2.6-1.1,5.2-2.6,7.4-2.3,3.3-4.8,5-7.8,3.3-2.9-5.7-2-9.6-5.9-11.6"
          />
          <path
            id="bonebonepart8"
            class="linkehand_dp1337_cls11"
            d="M78.4,411.9c-.4-2-1.2-3.9-2.5-5.5-2.5-3.1-2.1-6.6.2-10.5,2.1-4.4,5.7-6.9,11.4-7.2,5.3.3,9.5,3,11.7,9.8,2.2,5,4.9,9.7,10.8,12,2.1.9,1.2,3-2.3,6.1-2.1,1-3.9,3.1-5.4,6.3-1.2,2.7-3.8,4.5-6.7,4.8-3.5.3-6.6-.2-9-2-4.9-4-7.2-8.7-8.2-13.9h0Z"
          />
          <path
            id="bonebonepart7"
            class="linkehand_dp1337_cls11"
            d="M81.3,411.1c0-1.9.3-3.7,1-5.5,1-2.6,3-4.7,5.4-6.2,5-3.1,10.2-5.1,15.7-5.6,1.1-.1,2.3.1,3.2.7,1.5,1,2.5,2.8,2.4,6.4-1.2,4.2-2.6,8-4.7,11.1-2.6,3.9-6.8,6.6-11.5,7.1-3.3.4-6.3-.5-9.1-2.7-1.6-1.3-2.4-3.2-2.5-5.2h0Z"
          />
          <path
            id="bonebonepart6"
            class="linkehand_dp1337_cls11"
            d="M129.8,439c4,.6,5-2.3,4.8-6.6,1.7-3,2.1-6.8,1.6-11.1-.2-1.7-1.6-3-3.2-3.1l-7-.4c-2.7-1.8-5.7-2.6-8.9-2.6s-5.3,1.5-6.7,3.9c-1.2,2.2-2.1,5-2.7,8.1-3.9,3.5-4.5,6.7.4,9.1,4.5,2.8,8.7,4.1,12.5,3.9l9.2-1.2h0Z"
          />
          <path
            id="bonepart5"
            class="linkehand_dp1337_cls11"
            d="M113.8,416c1.7-1.2,3.4-2.2,5.1-2.9,3.5-1.3,7.4-.7,10.5,1.4,1.8,1.2,3,2.4,3.6,3.7"
          />
          <path
            id="bonepart4"
            class="linkehand_dp1337_cls11"
            d="M185.8,491.2c2.7-6.9,4.9-13.8,5-20.4,1.9-17.2-4.6-24.4-.8-33.1,1.5-7.8.6-13.8-3.4-17.6-1.5-1.5-3.9-1.5-5.4,0-8.6,8-16.6,14.2-23.3,16.9l-36.7,7.2c-6.6.9-9.9,6.7-6.8,22.1,2.5,8.2,6.9,16.3,13.6,24.5"
          />
          <path
            id="bonepart3"
            class="linkehand_dp1337_cls11"
            d="M75.2,488l-.3-19.1c-3.3-8.7-4.6-15.9-2.8-21,.5-7.9,2.3-12.6,5.7-13.7,4.2-2.1,5.7,3.5,9.2,10.4,1.3,2.5,3.9,3.9,6.7,3.8,4.6-.2,8.7.1,11.7,1.4,2.3,1,3.8,3.4,3.8,5.9v8.5c0,2.1-.4,4.1-1.2,6-2.2,5.6-3.2,11.8-3.2,18.7"
          />
          <path
            id="bonepart2"
            class="linkehand_dp1337_cls11"
            d="M74.1,485.9c-3.6-9.1-7-18-5.5-24.4.4-5,3.3-7.6,9.9-6.4,6.4,3,14.9-2.8,26-5.5"
          />
          <path
            id="bonepart1"
            class="linkehand_dp1337_cls11"
            d="M122.7,474.8c-3.9-9.5-5.9-18.5-1.5-26,2.8-5.3,27.2-4.6,36.7-12"
          />
          <g id="outlines12">
            <path
              id="outlinepart40"
              class="linkehand_dp1337_cls11"
              d="M255.2,283.8c-1.6.6-3.1,1.2-4.6,1.9"
            />
            <path
              id="outlinepart39"
              class="linkehand_dp1337_cls15"
              d="M243,289.1c-9.2,4.5-17.4,9.6-24.7,15.4"
            />
            <path
              id="outlinepart38"
              class="linkehand_dp1337_cls11"
              d="M215.1,307.1c-1.3,1.1-2.5,2.2-3.8,3.3"
            />
          </g>
          <g id="outlines11">
            <path
              id="outlinepart37"
              class="linkehand_dp1337_cls11"
              d="M60.8,486.9c.4-1.6.6-3.2.8-4.9"
            />
            <path
              id="outlinepart36"
              class="linkehand_dp1337_cls16"
              d="M61.7,472c-.2-4.7-.5-9.3-.2-13.5,1.2-33.3,6.6-67.2-.7-73.8-12.1-27.1-17.4-67.5-20.9-111.5-3.5-5.9-3.1-20.5-2.3-36.1,1.5-14.5-10.1-45.1-15.9-56.1-2.7-11.3-7.9-27.1-13.8-44-5.7-15.5-9-28.7-6.7-35.7-.8-5.7,2-9.4,7.1-11.9,5.1-1.4,9.7-1.8,13.3,0,11.6-2.5,19.4,38.1,36.7,72.6,11.7,29.2,21,61.4,26.5,53.3,5.7-6.8,6.5-26.2-4.9-76.6l-6.8-60.7c-2.7-26-4.9-51.3,3-56.7,1.8-4.8,5.3-8,11.7-8.4,7.3-1,13.3.4,16.9,6.4,6.1,10.2,9.9,27.1,9.1,56.7l9.9,46c.5,23.2,1.2,45.4,4.6,57.4"
            />
            <path
              id="outlinepart35"
              class="linkehand_dp1337_cls11"
              d="M130.2,184.1c.9,1.8,1.9,3.1,3.1,3.8.5-1.4.9-3,1.3-4.8"
            />
            <path
              id="outlinepart34"
              class="linkehand_dp1337_cls10"
              d="M135.9,173.5c.9-12.3,0-29.7-2.9-52.9,1.3-18.4.5-41.1-1.1-65.6-.9-18.7-1.6-37.1.6-45,2.5-5.6,7.1-9.2,15.4-9.4,2.7-.2,5.2,0,7.5,1"
            />
            <path
              id="outlinepart33"
              class="linkehand_dp1337_cls11"
              d="M159.5,4.2c1.1,1.1,2,2.4,2.8,4.1.9.2,1.9,1.6,2.9,3.9"
            />
            <path
              id="outlinepart32"
              class="linkeHand_dp1337_cls5"
              d="M168.2,21.9c2.3,9.4,4.7,23.2,7.2,39.6-.3,16.4.3,32,3.1,46-.2,27.8.3,53.6,2.1,74.8.4,6.4,1.5,11.8,3.7,15.1"
            />
            <path
              id="outlinepart31"
              class="linkehand_dp1337_cls11"
              d="M188.1,200.6c1.4.6,3,.8,4.9.5.4-1.5.9-3.1,1.3-4.8"
            />
            <path
              id="outlinepart30"
              class="linkehand_dp1337_cls14"
              d="M196.2,186.5c2.4-14.1,4-32.6,5.6-51.3,3.2-8.9,4.6-21.4,5.5-34.6-.2-29.8,2.8-51,11.2-57.2,3-4.2,7.1-6.7,12.7-6.5,4.9,0,9.5,2.1,13.8,7.3,7.3,9.7,7.9,48.8,4.7,104.2l-2.9,15.6c-2.5,40.2-3.7,81.7,5.3,110.7"
            />
            <path
              id="outlinepart29"
              class="linkehand_dp1337_cls11"
              d="M253.7,279.5c.6,1.6,1.2,3.1,1.9,4.6,1.1-1.1,2.3-2.4,3.4-3.7"
            />
            <path
              id="outlinepart28"
              class="linkehand_dp1337_cls12"
              d="M264.9,272.6c6.6-9.6,13.1-21.9,19.5-35.1,8.9-15.7,24.6-30.3,42.7-44.5,9.3-6,17.4-5.1,24.5.2,2.5,1.9,3.5,5.2,2.6,8.1-3.1,9.2-9.6,19.6-16.3,30.1-3.8,15.4-7.9,29-13.4,37.5-14.7,16.7-25.3,44-34,75.8-15.3,19.2-32.9,38.9-51.9,58.9-2,2.4-10.7,9.2-18.5,15.4-7.6,4-10.8,12.2-13.1,21.4-3,10.6-4.8,25.8-6.2,42.9"
            />
            <path
              id="outlinepart27"
              class="linkehand_dp1337_cls11"
              d="M200.5,488.2c-.1,1.6-.2,3.3-.3,5"
            />
          </g>
          <g id="outlines10">
            <line
              id="outlinepart26"
              class="linkehand_dp1337_cls11"
              x1="255.6"
              y1="297.7"
              x2="255.6"
              y2="292.7"
            />
            <line
              id="outlinepart25"
              class="linkehand_dp1337_cls11"
              x1="255.6"
              y1="289.1"
              x2="255.6"
              y2="284.1"
            />
          </g>
          <g id="outlines9">
            <path
              id="outlinepart24"
              class="linkehand_dp1337_cls11"
              d="M76.3,21.4c0,1.7,0,3.4,0,5"
            />
            <path
              id="outlinepart23"
              class="linkehand_dp1337_cls11"
              d="M78.4,39.2c.7,1.8,1.5,3.2,2.5,4.3"
            />
          </g>
          <g id="outlines8">
            <path
              id="outlinepart22"
              class="linkehand_dp1337_cls11"
              d="M103.6,17.6c.3,1.7.6,3.4.8,4.9"
            />
            <path
              id="outlinepart21"
              class="linkehand_dp1337_cls13"
              d="M104.8,27.5c.1,2.8,0,5.3-.3,7.6"
            />
            <path
              id="outlinepart20"
              class="linkehand_dp1337_cls11"
              d="M104,37.6c-.4,1.7-.9,3.3-1.5,4.8"
            />
          </g>
          <g id="outlines7">
            <path
              id="outlinepart19"
              class="linkehand_dp1337_cls11"
              d="M21.8,89.4c.8,1.5,1.5,3,2.1,4.5"
            />
            <path
              id="outlinepart18"
              class="linkehand_dp1337_cls11"
              d="M26.8,105.8c.2,1.7.2,3.3.2,5"
            />
          </g>
          <g id="outlines6">
            <path
              id="outlinepart17"
              class="linkehand_dp1337_cls11"
              d="M1.3,101.3c.5,1.7,1.1,3.2,1.6,4.7"
            />
            <path
              id="outlinepart16"
              class="linkehand_dp1337_cls11"
              d="M9.1,118.6c1.1,1.5,2.3,2.7,3.5,3.5"
            />
          </g>
          <g id="outlines5">
            <path
              id="outlinepart15"
              class="linkehand_dp1337_cls11"
              d="M134.9,6.1c-.1,1.8-.2,3.4-.3,5"
            />
            <path
              id="outlinepart14"
              class="linkehand_dp1337_cls8"
              d="M134.7,17.4c.2,3.6.8,6.7,1.6,9.2"
            />
            <path
              id="outlinepart13"
              class="linkehand_dp1337_cls11"
              d="M137.6,29.4c.9,1.6,1.9,2.9,3.2,3.8"
            />
          </g>
          <g id="outlines4">
            <path
              id="outlinepart12"
              class="linkehand_dp1337_cls11"
              d="M162.3,8.3c.4,1.7.6,3.4.9,4.9"
            />
            <path
              id="outlinepart11"
              class="linkeHand_dp1337_cls6"
              d="M163.7,18.5c.1,2.9-.1,5.6-.6,7.9"
            />
            <path
              id="outlinepart10"
              class="linkehand_dp1337_cls11"
              d="M162.3,28.9c-.6,1.6-1.4,3.1-2.4,4.4"
            />
          </g>
          <g id="outlines3">
            <path
              id="outlinepart9"
              class="linkehand_dp1337_cls11"
              d="M218.5,43.5c-.2,1.7-.5,3.3-.7,5"
            />
            <path
              id="outlinepart8"
              class="linkehand_dp1337_cls7"
              d="M217.2,54.2c-.2,3.1-.2,6.1.3,8.6"
            />
            <path
              id="outlinepart7"
              class="linkehand_dp1337_cls11"
              d="M218.4,65.5c.7,1.5,1.7,2.8,3.1,3.9"
            />
          </g>
          <g id="outlines2">
            <path
              id="outlinepart6"
              class="linkehand_dp1337_cls11"
              d="M244.3,43.5c0,1.8-.2,3.4-.3,5"
            />
            <path
              id="outlinepart5"
              class="linkehand_dp1337_cls9"
              d="M243.5,54.3c-.4,3.4-.9,6.3-1.5,8.6"
            />
            <path
              id="outlinepart4"
              class="linkehand_dp1337_cls11"
              d="M241.1,65.6c-.9,2.1-1.9,3.4-3.1,3.8"
            />
          </g>
          <g id="outlines1">
            <path
              id="outlinepart3"
              class="linkehand_dp1337_cls11"
              d="M349.9,192c-1.8-.1-3.4,0-5,.2"
            />
            <path
              id="outlinepart2"
              class="linkehand_dp1337_cls11"
              d="M334.1,200c-.8,1.3-1.6,2.8-2.2,4.5"
            />
          </g>
          <line
            id="outlinepart1"
            class="linkehand_dp1337_cls11"
            x1="337.1"
            y1="234.6"
            x2="332.6"
            y2="231.4"
          />
        </g>
      </g>
      <g id="Highlithts3">
        <path
          id="wristjoint"
          class="Handgelenk1337Fill"
          style={{
            fill: props.colors.Handgelenk1337Fill,
            stroke: props.colors.Handgelenk1337Fill,
          }}
          d="M70.8,416.4c5.3-6.9,43.2,5.5,58.5,8.5,20.1,3.9,47.6-28.3,61.3-27.8,16.4.6,5.4,29.4-15.2,43.5-13.8,9.5-66.8,16.9-87,9.5-21.7-7.9-20.8-29.7-17.7-33.8Z"
        />
      </g>
      <g id="Highlights2">
        <path
          id="boneparthighlight28"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M193.6,382.2c-3.4-5.1-1.3-9.6,4.4-13.5,3.4-3.6,8.8-6,14.9-7.9,13.9-4.7,25.4-11.4,33.1-21.5,2.8-3.6,3.8-8.2,3.2-12.7-1.2-9.1.7-14.4,5.9-15.3,6.3-1.9,12.2-1.6,17.6,2.1,6.3,1.7,9.2,9.1,9.7,20.4.8,3.5-1.5,6.7-6.7,9.7-17,12.7-32.1,29-45.9,47.6-5.4,6.1-10.5,8.6-15.4,6.5-7.5-1.1-10.3-4.9-10.2-10.1l-10.6-5.2h0Z"
        />
        <path
          id="boneparthighlight27"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M259.1,299.8c-.1-2.5,1.2-4.9,3.1-7.2,13.4-8.2,23.9-17.2,28.5-27.9,1.1-2.5,1.3-5.4.7-8.1-1.3-6.1.2-10.4,5-12.8,5.7-1.6,9.8,1,12.3,7.6.5,1.4,1.7,2.5,3.1,3,4.4,1.6,5.8,4.1,2.9,8-10.1,15.8-19.4,31.4-22.7,45-1.5,8.2-3.9,11.5-7.2,10.5-5.8-7.1-13-11.7-21.5-13.5-2.3-.5-4.1-2.3-4.2-4.6h0Z"
        />
        <path
          id="boneparthighlight26"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M310.7,249.5c-1-5.1-3.9-8.7-9.2-10.7-1.4-6.1,1.5-8.9,7.3-9.5l14.9-13.2c-1.2-5.4,3.8-9.5,15.5-12.2,3.6-.6,6.5-.2,5,6.7-1.7,6.1-3.8,9-6.3,8.9-7.2,8.9-12.8,19.4-16.4,31.7-.4,1.4-2.1,2-3.3,1.2-2.8-1.9-5.4-3-7.5-3h0Z"
        />
        <path
          id="boneparthighlight25"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M179.4,372.9c.5.9,1.3,1.5,2.3,1.9,5.7,2.2,10.8-1.9,15.2-11.7,1.1-2.5,1.4-5.1.6-7.9-7.4-13.9-5.3-31.1,1.4-49.8l15.4-36.2c1.1-2.7,3.2-4.8,5.8-6.1h0c2-1,3.4-2.8,3.8-5,2.5-13.8-.2-22.4-6.6-27.6-6.4-4.6-12.1-2.5-17.2,3.1-2.7,0-4.5,1.9-5.6,5l-3.3,3.6c-.6.7-.9,1.6-.9,2.5.8,27.4-4.5,56.3-18.5,87.3-1.7,2.6-4.6,4.4-8,5.8-2.5,9.5-7,17.5-13.3,24.3-1.2,1.3-.5,2.9,2.1,4.9,1.3,1,3,1,4.3,0,4.3-3.6,9.3-4.2,14.9-2.1,2.7,1,5.2,4.1,7.6,7.9h0Z"
        />
        <path
          id="boneparthighlight24"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M224.8,232.5c5.4,1.1,8.2-2,8.4-9.5l-3.7-22.2c-.3-1.8-.3-3.7,0-5.5,1.4-8.8,1.9-17.9,1.8-27.1,0-1.5.4-2.9,1-4.2,1.9-3.7,2.9-7.5,3.1-11.5.6-8.5-2.6-10.3-7.6-8.7-3,.6-6,0-9-1.2-3.7-1.8-6.3-1-8,2.1-2,4.8-3.2,9.3-1.8,12.7.4.9.6,1.9.5,2.8l-2.5,24.9c-.7,9.6-4.1,19.1-9,28.5-2.1,7.5-2.7,13.6,2.5,14.7,3.8-3.8,8-5.1,12.6-4.3,5.8,1,9.1,4.3,11.6,8.5h0Z"
        />
        <path
          id="boneparthighlight23"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M212.7,137.9c-3.5-2.3-2.1-5.9.8-10,5.1-5.8,6.9-13.2,5.9-22.1-1.8-3-1.5-6.1,0-9.3-.4-4.8,1-6.3,3.5-5.5,3.9.9,7.5,1.2,10.4,0,4.1-1.6,5.4.7,5.1,5.2,1.9,2.8.7,5.8-2.9,8.9-1,9.9,0,19.2,2.9,28-.2,4.3-2,6.3-5.1,6.5-5.1,3-9.5,2.7-13.3-1-2.5-1.8-5-2.2-7.2-.7h0Z"
        />
        <path
          id="boneparthighlight22"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M223.7,87.9c-7.1-.1-7.3-2.8-4.6-6.5,3.8-3.5,4.6-10.6,4.6-18.8-3.8-2.4-2.2-6.4,0-10.6.7-4.6,3.2-6.7,7.9-5.2,3.1,1.6,5,3.9,5.4,7,2.6,5.3,1.5,8.1-1.5,9.8l2.6,19.1c.5,1.4.7,2.8-.3,4-.9,1.1-2.4,1.7-3.8,1.6l-10.1-.4h0Z"
        />
        <path
          id="boneparthighlight21"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M138.1,358.7c7.6,1.9,13.6.8,16.6-5.9,5.7-6.5,5.6-13.3,1.2-20.5-4.3-22.9-2.1-40.3,2.3-55.9,1.4-5.1,3.2-9.4,5.6-12.8,2.4-1.2,3.3-3.4,3.3-6.1,3.3-.6,5.4-2.5,6.6-5.4v-17.1c.3-4.9-2.3-8.1-6.4-10.4-7.8-2.8-14.5-1.6-20.3,2.7-.2,2.2-1.8,4.8-4,7.6-3.9,3.6-4.5,10.2-3.1,18.8.4,16.7-1.2,38-3.5,60.9-1.8,5.8-4,10.2-6.9,12.1-.7.5-1.4,1.1-1.7,1.9-2.4,5.2-4.4,10.7-5.7,16.6-.5,2.3.1,4.7,1.8,6.3,3.5,3.3,8,5.9,14.1,7.1h0Z"
        />
        <path
          id="boneparthighlight20"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M142.4,222.4c-5.2-4.8-2.9-14.4,1.6-25.5,1.7-7.9,1.6-18,1-28.6-1.7-6.6-2-14.1-1-22.2.6-2.9-.2-6.4-1.4-10-.6-6-1.4-12.2,3.9-13.4l10.3.8c4.9-2.7,8.7-3.4,9.8.6,2.4,2.8,4,6,2.8,10.8-1.8,4.9-2.7,9.6-1.4,13.9-1.1,20.5-1.4,39.2,2.5,49.2,6.4,8.9,9,16.7,5.1,22.8-1.1,1.8-3.3,2.5-5.4,2-8.2-2-16.5-1.8-24.9.4-1.1.3-2.4,0-3.2-.8h0Z"
        />
        <path
          id="boneparthighlight19"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M141.5,116.6c-.8-2.8-.1-6.1,1.1-9.6,3-15.5,4.1-28.1,0-32.8-2.1-2.5-1.8-5.4,0-8.5-.4-3.3-.4-6.1,2.7-5.5,3.3.4,6.9-.5,10.6-2.3,6.3-1.3,9.5.6,9.3,6.3.6,3.3-.2,5.6-1.9,7.2-.7,11.4,1.6,22.6,6.4,33.6,2.1,3.7,2.6,7.2.6,10.4-.8,1.3-2.2,2.1-3.7,2.1-2.4,0-4.9.7-7.4,1.8-1.2.6-2.6.7-3.9.4-3.7-1-7-1.3-9.8-.7-1.7.4-3.6-.6-4-2.3h0Z"
        />
        <path
          id="boneparthighlight17"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M144.3,33.2c-3.4.2-5.2-2.3-4.6-8.9,1-4.8,2.5-8.9,6.1-10.4s1.6-.5,2.5-.6c3.8-1,7,.5,9.3,6.9.3.7.5,1.3.7,1.9,1.8,5.6.8,8.6-1.5,10.4l-.7-4.4c1.9,13,4.1,23.5,7.2,24.1.7,1.6-.2,2.8-2.8,3.6-1.1.3-2.3.5-3.5.6l-11.7.7c-2.4.3-4.1-.5-5.2-2.3-.6-1-.6-2.3.1-3.3,2.2-3.1,3.3-9.3,3.5-18s0-5.6,0-8.8v8.8c-1.6,0-3-.8-3.8-2.4-1-2.1-.8-5.2,0-8.9"
        />
        <path
          id="boneparthighlight16"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M128.3,350.1c-.5,6.2-3.1,9.7-9.5,8.4l-22.9-7.8c-4.5-1.8-4.3-5.4-1.4-9.9,2.5-6.2,5.5-10.2,8.7-12.3,1.4-.9,2.2-2.6,2.2-4.3v-32.9c0,0-3.8-33.3-3.8-33.3-3.4-3.9-3.2-8.5,0-13.7.2-8.2,3.1-12.8,9.7-12.2,5.9-1.2,10,.1,12.8,3.4,2.3,5.5,3.6,10.4,4.1,14.7-.9,7.8-3.2,15.8-6.3,24-1.1,17.5-1.6,33.8,0,46.4,4.1,7.1,6.4,16.5,6.3,29.4h0Z"
        />
        <path
          id="boneparthighlight15"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M96.1,229.9c-1.1-1.5-1.5-3.8-1.5-6.6,0-1.1.2-2.2.6-3.3l2.8-7c.4-1,.7-2.1.8-3.2,1.6-12.5-.2-25.1-2.2-37.6-1.1-4.1-1-8.7-.8-13.4,0-1.5-.8-3.2-2.2-5-1.6-6.5-2.3-12.6-1.2-17.7,2.6-2.4,5.6-3,8.8-2,7.3-3,11.8-3.8,12.3-1.1,2.8,1.9,4.2,5.5,4.3,10.3l-1.3,7.8c-.2,1.3-.1,2.6.2,3.9,1.3,4.7,2,9.3,1,13.5.1,19,2,36.3,10.4,47.5,1.8,3.9,2.1,7.7.3,11.1-.8,1.6-2.7,2.4-4.5,2-8-1.9-15.7-1.4-22.8,1.9-1.8.8-4,.4-5.2-1.2h0Z"
        />
        <path
          id="boneparthighlight14"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M93.8,131.4c-4.5,2.4-5.8-1.2-5.9-7.4l1.5-24.6c1.6-6.4-4.4-8.1-4.9-15.6-.6-6.5,0-10.9,4.7-9,4.4.3,8.9-.1,13.5-1.4,3-1.2,3.7.6,3.4,3.7,1.3,2.6,1.5,5.2.7,7.8-2.1,6.1-2.1,11.7,1.4,16.4,2.8,12.2,11.4,25.2,6.4,25.9-3.6-.4-7.3,1.1-11.1,3.9-3.7-.9-6.9-.7-9.6.4h0Z"
        />
        <path
          id="boneparthighlight13"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M84.3,69.1c-4.1-.8-3.3-3.4-1.2-6.5,3.4-2.8,4.7-9.5,4-19.9-3.4,0-3.7-4-2.8-9.2.1-6.3,3.3-8.1,7.7-8.3,5.4.6,7.7,2.5,8.2,5.2,2.7,7.3,2.4,11.5-1,12.3-.3,7.2.8,13.6,4.7,18.6,3.2,2.9,3.9,5.3-.9,7,.2,1.3-1.8,2.1-5.7,2.5-5.5.5-10.1.2-12.9-1.6h0Z"
        />
        <path
          id="boneparthighlight12"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M22,136.8c-5.1,3-5.7-3.4-4.9-12.7l-2.3-8.5c-4.4-1.8-5.5-4.8-4.7-8.5.2-.9.2-1.8,0-2.6-.7-2.3.6-4,3.5-5.1,2.7-1.1,5.9-.4,7.8,1.8,1.8,2.1,3,5.2,3.8,9.4l-.4.8c-.5,1.1-.5,2.3-.1,3.3,1.4,4,3.8,7.3,7.3,10,3.6,4.4,3.2,7.5-2.2,8.9-2.8.2-5.4,1.5-7.8,3.2h0Z"
        />
        <path
          id="boneparthighlight11"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M92.5,363.4l-.6-.3c-6.3,1.2-11.3,0-12.6-8-3.3-3.3-3.9-8.8-3.9-14.8l1.4-8.2c-2.1-26.4-18.6-51.3-17.9-65.5-1.9-5-2.1-9.8,0-14.4,3.5-3.1,7.8-5.2,13.6-5.5,4.8.6,8.3,4.4,10.6,11.2.2.6.2,1.4.1,2-1.7,10-1.8,19.4-.2,28.2,5.1,18.1,10.3,35.4,17.5,44.1,2.9,3,3.6,6.3,2.7,9.8,5,6,6.4,11.7,2.4,16.7-2.4,3.4-8.7.8-13.3,4.6h0Z"
        />
        <path
          id="boneparthighlight10"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M55.5,249.9c-3,1.6-5.3.7-6.5-3.7-.2-.7-.3-1.4-.2-2.1l.9-16.7c0-1.4-.2-2.9-.7-4.2-2.6-6.2-4.1-12.3-4.6-18.4-.2-2.4-.9-4.6-2.2-6.6-3.5-5.2-6.5-10.3-6.8-14.8-.1-1.6.7-3.1,2-3.9s3.3-1.3,5.7-1.7c1.3-.2,2.4-.8,3.3-1.7,2.9-3.2,5.2-2.9,7.1-.2,1.7,3.2,2.6,6.5,2.6,10.1,0,1.7.4,3.4,1.2,5,6,12.7,9.2,26.7,14,34.2,5.4,5.1,8.8,10.1,8,14.8-.4,2.1-2.3,3.4-4.4,3.3-7.2-.3-13.1,1.3-17.6,5.3-.6.5-1.2,1-1.8,1.4h0Z"
        />
        <path
          id="boneparthighlight9"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M35.4,175.8c-4.4,2.3-6.8.5-7.2-5-.5-4.8-1.1-9.7-1.7-14.7-.2-1.8-1.1-3.4-2.4-4.5-3-2.5-4.6-5.3-4.4-8.2,0-1.7,1.4-3.1,3.1-3.5,3.5-.8,6.6-2.1,9.1-4,1.1-.9,2.7-1,3.8-.1,1.8,1.3,3.2,3.8,3.9,7.7-1.5,4.1,1.5,10.6,7.8,18.9,3.8,4.3,3.2,7,0,8.8-3.7,2.8-7.6,4.7-11.9,4.7h0Z"
        />
        <path
          id="boneparthighlight8"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M188.9,370c6.8-2,9.6,1.2,9.7,7.9-.4,7.2.7,13.6,6.5,17.7,1.6,2.9-2.4,5.9-2.7,9.2,0,.7-.3,1.4-.6,2-1.7,2.7-4.5,3.6-7.9,3.4-2.4-1.7-5.5-2.1-9-2.1-1.2,0-2.3-.7-2.7-1.9-1.3-4.1-3.9-8.2-7.3-12.4,0-6.6,1.6-11.3,4.1-14.4l6.2-7.2c1-1.1,2.3-2,3.8-2.5h0Z"
        />
        <path
          id="boneparthighlight7"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M157.4,370.1c3.6-3.8,7.9-4.8,13.3-2.1,7.2,4.3,8.9,10.3,8.1,17.2-.8,4.9-2.4,7.7-4.4,9-1,.7-2.4.5-3.4-.3-3.9-3.5-7.8-5.9-11.7-6.6-1.1-.2-1.9-1.2-1.9-2.3,0-4.9-.1-9.3-.8-12.2-.2-.9,0-1.9.7-2.6h0Z"
        />
        <path
          id="boneparthighlight6"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M146.6,402.4c1.5-.9,2.5-2.4,2.8-4.2.8-4.6,2.3-6.8,4.4-6.8,6.5-.6,11.7.9,15.2,5.2.4.5.9,1,1.4,1.4,4.7,4.1,7,7.7,6,10.6-.4,1.1-.1,2.3.5,3.3,1.9,3.1.7,5.9-2.8,8.4-1.3.9-2.4,2-3.5,3.1-8.2,8.8-16.8,11.9-25.5,12.1-2.1,0-4.1-.2-6-1-3.3-1.3-5.1-3.6-5.5-6.6-.2-1.3,0-2.7.3-4,1-3.3,2.1-6.5,3.6-9.4,2.2-5.7,5.2-9.7,9.1-12.1h0Z"
        />
        <path
          id="boneparthighlight5"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M114.6,393.2c-1,3.2-1.3,6.6-.7,9.8.8,4.2,2.7,6.8,6.1,7.2,8.5,2.1,16.5,2.4,23.5-.7,3.8-1.9,5.3-4.9,5.5-8.4,1.5-3.4,1.7-6.5-1.1-8.7-1-1-1.4-2-1.4-3.2,0-2.1,1.3-4,3.2-4.9l.6-.3c2.3-1.1,3.8-3.3,3.8-5.8,0-3.4-1.7-6.1-4.3-8.2-.8-.7-1.4-1.6-1.7-2.6-1.1-5.2-5-6.6-10.2-6.5-1.5,0-3-.4-4.3-1.3-7.9-5.4-12.4-6-14.9-3.7-.7,1.3-1.9.5,0,11.6l.6,7.4c.1,1.4,0,2.7-.4,4l-4.4,14.3h0Z"
        />
        <path
          id="boneparthighlight4"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M86.1,356c-1.5,1.9-2.7,3.9-3.4,5.9,0,7.8,4,11.9,4.9,16.7.2,1.3,1,2.4,2.1,3,6.9,4,12.8,9.8,16.4,19.1.5,1.2,1.3,2.3,2.4,3,4,2.5,6.9.7,9.2-4,.2-.4.3-.8.5-1.2,1.8-6.1,2.7-13.1,2.9-20.7,1.4-13.2-.5-18.7-4.2-20l-25.4-4c-2.1-.3-4.2.5-5.5,2.1h0Z"
        />
        <path
          id="boneparthighlight3"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M78.8,411.1c-.4-2-1.2-3.9-2.5-5.5-2.5-3.1-2.1-6.6.2-10.5,2.1-4.4,5.7-6.9,11.4-7.2,5.3.3,9.5,3,11.7,9.8,2.2,5,4.9,9.7,10.8,12,2.1.9,1.2,3-2.3,6.1-2.1,1-3.9,3.1-5.4,6.3-1.2,2.7-3.8,4.5-6.7,4.8-3.5.3-6.6-.2-9-2-4.9-4-7.2-8.7-8.2-13.9h0Z"
        />
        <path
          id="boneparthighlight2"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M81.7,410.3c0-1.9.3-3.7,1-5.5,1-2.6,3-4.7,5.4-6.2,5-3.1,10.2-5.1,15.7-5.6,1.1-.1,2.3.1,3.2.7,1.5,1,2.5,2.8,2.4,6.4-1.2,4.2-2.6,8-4.7,11.1-2.6,3.9-6.8,6.6-11.5,7.1-3.3.4-6.3-.5-9.1-2.7-1.6-1.3-2.4-3.2-2.5-5.2h0Z"
        />
        <path
          id="boneparthighlight1"
          class="FullHand1337Fill"
          style={{
            fill: props.colors.FullHand1337Fill,
            stroke: props.colors.FullHand1337Fill,
          }}
          d="M130.2,438.2c4,.6,5-2.3,4.8-6.6,1.7-3,2.1-6.8,1.6-11.1-.2-1.7-1.6-3-3.2-3.1l-7-.4c-2.7-1.8-5.7-2.6-8.9-2.6s-5.3,1.5-6.7,3.9c-1.2,2.2-2.1,5-2.7,8.1-3.9,3.5-4.5,6.7.4,9.1,4.5,2.8,8.7,4.1,12.5,3.9l9.2-1.2h0Z"
        />
      </g>
      <g id="Highlights">
        <path
          id="Highlightpart5"
          class="Fingerkuppe1337Fill"
          style={{
            fill: props.colors.Fingerkuppe1337Fill,
            stroke: props.colors.Fingerkuppe1337Fill,
          }}
          d="M310.6,249.9c-1-5.1-3.9-8.7-9.2-10.7-1.4-6.1,1.5-8.9,7.3-9.5l14.9-13.2c-1.2-5.4,3.8-9.5,15.5-12.2,3.6-.6,6.5-.2,5,6.7-1.7,6.1-3.8,9-6.3,8.9-7.2,8.9-12.8,19.4-16.4,31.7-.4,1.4-2.1,2-3.3,1.2-2.8-1.9-5.4-3-7.5-3h0Z"
        />
        <path
          id="Highlightpart4"
          class="Fingerkuppe1337Fill"
          style={{
            fill: props.colors.Fingerkuppe1337Fill,
            stroke: props.colors.Fingerkuppe1337Fill,
          }}
          d="M223.7,88.4c-7.1-.1-7.3-2.8-4.6-6.5,3.8-3.5,4.6-10.6,4.6-18.8-3.8-2.4-2.2-6.4,0-10.6.7-4.6,3.2-6.7,7.9-5.2,3.1,1.6,5,3.9,5.4,7,2.6,5.3,1.5,8.1-1.5,9.8l2.6,19.1c.5,1.4.7,2.8-.3,4-.9,1.1-2.4,1.7-3.8,1.6l-10.1-.4h0Z"
        />
        <path
          id="Highlightpart3"
          class="Fingerkuppe1337Fill"
          style={{
            fill: props.colors.Fingerkuppe1337Fill,
            stroke: props.colors.Fingerkuppe1337Fill,
          }}
          d="M84.2,69.6c-4.1-.8-3.3-3.4-1.2-6.5,3.4-2.8,4.7-9.5,4-19.9-3.4,0-3.7-4-2.8-9.2.1-6.3,3.3-8.1,7.7-8.3,5.4.6,7.7,2.5,8.2,5.2,2.7,7.3,2.4,11.5-1,12.3-.3,7.2.8,13.6,4.7,18.6,3.2,2.9,3.9,5.3-.9,7,.2,1.3-1.8,2.1-5.7,2.5-5.5.5-10.1.2-12.9-1.6h0Z"
        />
        <path
          id="Highlightpart2"
          class="Fingerkuppe1337Fill"
          style={{
            fill: props.colors.Fingerkuppe1337Fill,
            stroke: props.colors.Fingerkuppe1337Fill,
          }}
          d="M21.9,137.3c-5.1,3-5.7-3.4-4.9-12.7l-2.3-8.5c-4.4-1.8-5.5-4.8-4.7-8.5.2-.9.2-1.8,0-2.6-.7-2.3.6-4,3.5-5.1,2.7-1.1,5.9-.4,7.8,1.8,1.8,2.1,3,5.2,3.8,9.4l-.4.8c-.5,1.1-.5,2.3-.1,3.3,1.4,4,3.8,7.3,7.3,10,3.6,4.4,3.2,7.5-2.2,8.9-2.8.2-5.4,1.5-7.8,3.2h0Z"
        />
        <path
          id="Highlightpart1"
          class="Fingerkuppe1337Fill"
          style={{
            fill: props.colors.Fingerkuppe1337Fill,
            stroke: props.colors.Fingerkuppe1337Fill,
          }}
          d="M144.4,32.9c-3.4.2-5.2-2.3-4.6-8.9,1-4.8,2.5-8.9,6.1-10.4s1.6-.5,2.5-.6c3.8-1,7,.5,9.3,6.9.3.7.5,1.3.7,1.9,1.8,5.6.8,8.6-1.5,10.4l-.7-4.4c1.9,13,4.1,23.5,7.2,24.1.7,1.6-.2,2.8-2.8,3.6-1.1.3-2.3.5-3.5.6l-11.7.7c-2.4.3-4.1-.5-5.2-2.3-.6-1-.6-2.3.1-3.3,2.2-3.1,3.3-9.3,3.5-18s0-5.6,0-8.8v8.8c-1.6,0-3-.8-3.8-2.4-1-2.1-.8-5.2,0-8.9"
        />
      </g>
    </svg>
  );
}

export default LinkeHandDpSitzend1337;
