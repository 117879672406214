import style from "./style.scss";

function LinkeFusswurzelDplOblSitzend1453(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 279.57 359.21">
  
  <g id="foot">
    <path id="background" class="fusswurzel1453_dpl_obl_links_cls2" d="M276.07,231.73l-63.79-27.68c-4.15-1.8-7.17-5.51-8.1-9.94-6.72-32.15-11.51-62.12-10.95-86.09,0-.42,0-.83-.03-1.25l-4.59-63.87c-.06-.86-.04-1.72.05-2.57l3.63-31.93c.51-4.46-2.98-8.37-7.47-8.37H24.15c-6.01,0-11.37,3.84-13.23,9.56C1.48,38.53-2.16,69.23,2.64,102.48c.1.72.26,1.44.47,2.14,3.88,12.66,11.65,25.34,23.44,38.04,2.25,2.42,3.52,5.6,3.64,8.9,1.05,28.39-1.01,55.04-10.75,77.38-.2.46-.43.9-.67,1.33C-1.92,266.81-1.68,291.06,1.74,313.45c3.87,27.92,21.43,42.51,52.1,44.33.26.02.53.04.79.07,26.91,3.53,60.71-.23,97.21-6.86,3.6-.65,6.81-2.69,8.92-5.68l12.23-17.33c3.8-5.38,10.84-7.37,16.89-4.76l65.63,28.23c1.75.75,3.73-.33,4.06-2.2l19.34-112.24c.38-2.21-.8-4.39-2.85-5.28Z"/>
    <path id="radius_1" data-name="radius 1" class="fusswurzel1453_dpl_obl_links_cls1" d="M155.11,298.34c35.86,13.02,72.31,28.75,106.44,47.01"/>
    <path id="radius_2" data-name="radius 2" class="fusswurzel1453_dpl_obl_links_cls1" d="M279.43,245.45c-32.78-9.73-51.62-20.83-79.62-34.8"/>
    <g id="forefoot">
      <path id="forefoot1" class="fusswurzel1453_dpl_obl_links_cls1" d="M103.47,148.46c14.97,3.93,15.22-3.76,7.78-17.64-1.43-2.67-3.51-5.02-6.02-6.69-15.46-10.53-22.48-22.73-22.4-36.36,2.34-8.11-1.92-8.53-9.36-5.18-16.22,6.69-27.76,15.22-33.86,25.83-5.35,3.85-8.61,7.53-1.83,9.86,5.51,2.84,8.11,9.78,7.53,21.15.67,10.03,7.19,15.96,22.57,15.54,9.78,4.52,18.23,4.6,24.91-.92l10.7-5.6h-.02Z"/>
      <path id="forefoot2" class="fusswurzel1453_dpl_obl_links_cls1" d="M133.15,97.55l18.65-3.43c8.86-2.42,10.62-7.61,6.77-15.05-3.85-6.52-6.77-13.96-8.94-22.07-3.68-7.27-10.95-6.27-19.73-1.75-11.88,3.93-14.13,9.28-8.94,15.71-1.68,4.6-1.17,8.53,3.43,11.29l3.34,11.95c.67,2.34,3.01,3.76,5.43,3.34h0Z"/>
      <path id="forefoot3" class="fusswurzel1453_dpl_obl_links_cls1" d="M107.24,119.37c5.93,1.51,12.87-3.93,20.48-12.79.75-.92,1.09-2.17.75-3.34-.92-3.26-3.26-4.01-5.35-4.1,2.17-9.36-3.18-17.72-9.86-25.83.75-13.88-4.6-20.56-18.31-17.39-11.62,5.18-14.38,12.62-8.45,22.07l11.29,23.49c-3.09,8.28-.08,14.3,9.44,17.89h0Z"/>
      <path id="forefoot4" class="fusswurzel1453_dpl_obl_links_cls1" d="M45.13,11.23c.08,4.25.05,8.49-.09,12.76-.92,15.13,1.68,28.59,11.88,38.29,6.44,5.35,8.45,12.12,5.35,20.56-.67,1.83-2.17,3.18-4.01,3.76-9.95,3.6-17.98,10.28-24.66,19.22h0c-6.69,11.04-13.04,10.95-18.97-1.83-2-4.35-1.83-9.36.25-13.63,11.43-23.12,13.66-49.2,10.83-76.9"/>
      <path id="forefoot5" class="fusswurzel1453_dpl_obl_links_cls1" d="M74.75,8.96l1.7,13.51c.12.95.47,1.84.98,2.65,4.32,6.83,6.41,14.51,6.15,22.98-.04,1.19.14,2.38.58,3.49,5.32,13.68,6.52,22.5.07,22.33-2.39-.07-4.78.2-7,1.09l-11.37,4.49h0c-12.03,4.18-13.63-3.93-9.7-18.48,2.99-5.38,2.64-10.1.49-14.44-.53-1.08-.76-2.3-.65-3.5,1.02-11.71,1.7-22.32,2.02-31.9"/>
      <path id="forefoot6" class="fusswurzel1453_dpl_obl_links_cls1" d="M103.07,8.95c.89,5.61,1.86,11,2.91,16.13.31,1.51.97,2.92,1.9,4.14,4.26,5.58,6.34,11.82,5.21,19.01-1.68,8.69-7.36,15.3-22.73,16.89h0c-13.46,2.76-14.3-8.53-9.36-25.99,2.47-10.03,4.14-20.25,5.09-30.62"/>
      <path id="forefoot7" class="fusswurzel1453_dpl_obl_links_cls1" d="M137.37,4.03c1.51,5.66,3.19,11.29,5.06,16.87,7.61,10.45,11.37,20.15,5.35,27.92-6.85-.59-14.3.5-22.32,3.09h0c-8.86,7.78-13.71,7.36-13.79-2.51,2.1-14.28,3.6-28.3,4.38-42"/>
      <path id="forefoot8" class="fusswurzel1453_dpl_obl_links_cls1" d="M125.12,141.94c-6.69,6.69-12.7,5.51-17.89-5.68-1.59-6.02,2.67-11.29,10.79-15.96,6.27-10.03,15.71-17.98,35.78-18.8,14.3-3.34,25.5-2,28.68,12.2,6.18,18.97,7.11,34.19,1.43,44.64-5.77,11.04-10.79,8.86-15.54,1.43-4.68-24.24-19.98-28.51-43.22-17.81h-.02Z"/>
      <path id="forefoot9" class="fusswurzel1453_dpl_obl_links_cls1" d="M167.93,111.85c-18.65,3.26-25.74-6.1-27.76-21.15-5.1-19.48-4.77-32.51,2.84-37.12,5.41-2.66,8.33-7.56,9.17-14.43.17-1.34.98-2.52,2.18-3.15,11.87-6.22,21.4-4.11,27.19,11.89,2.16,5.32,2.81,10.11,2.12,14.42-.8,5.02-.69,10.13-.07,15.18,2.72,21.84-1.2,35-15.68,34.35h0Z"/>
      <path id="forefoot10" class="fusswurzel1453_dpl_obl_links_cls1" d="M178.67.15c.99,7.37,2.48,14.98,4.46,22.84,5.13,6.72,2.02,14.11-6.26,21.88-1.72,1.62-3.94,2.63-6.29,2.85l-15.38,1.43h0c-12.62.5-19.39-2.42-18.48-10.03-5.35-13.79-2-26.58,4.68-38.88"/>
    </g>
    <path id="ulnar" class="fusswurzel1453_dpl_obl_links_cls1" d="M265.02,313.45c-32.42-16.62-56.92-31.03-82.74-37.69-20.64,1.17-35.78-2.2-44.12-11.2-14.18-10.61-18-22.07-15.94-34.01,4.79-11.75,12.12-16.97,21.97-15.8,19.87-6.45,33.7.24,45.25,11.92,23.31,23.6,53.1,41.28,82.37,54.3"/>
    <g id="heel">
      <path id="heel1" class="fusswurzel1453_dpl_obl_links_cls1" d="M149.28,263.01c-5.56-15.5-7.21-30.59,3.87-44.35,2.27-2.82,3.91-6.17,4.27-9.77.47-4.68-1.01-8.93-3.96-12.84-.9-1.19-1.97-2.24-3.12-3.17-3.78-3.09-13.55-11.06-16.59-13.55-.88-.72-1.52-1.77-1.56-2.96-.03-.68.12-1.31.44-1.92,1.29-2.49,1.38-7.42-1.06-8.48-3.17-1.37-7.97-3.61-12.04-6.09-6.81-4.8-12.92-7.95-18.07-8.5-4.22-.43-9.62,2.4-11.33,6.92-1.16,3.05-7.55,3.24-10.61,2.77-7.28-1.14-13.43-5.07-18.26-5.25-4.88.92-6.62,8.53-5.94,15.68.12,1.28-.17,2.58-.8,3.72-8.08,14.42-12.55,29.93-12.59,46.78,0,1.71-.45,3.41-1.36,4.87-7.24,11.64-12.35,22.4-10.56,30.28.42,1.87.23,3.85-.58,5.62-3.64,7.88-4.78,15.67-3.86,23.36.23,1.98,1.02,3.87,2.19,5.5l20.37,27.92c1.18,1.62,2.65,3.04,4.33,4.14,19.54,12.69,37.96,19.94,54.37,17.28,5.23-.85,10.13-3.05,14.42-6.18,11.15-8.18,22.44-20.41,33.86-36.41,1.86-2.61,2.46-5.92,1.62-9.02l-5.2-19.18c-.65-2.41-1.4-4.8-2.24-7.14h0Z"/>
      <path id="heel2" class="fusswurzel1453_dpl_obl_links_cls1" d="M30.58,293.05c-3.54-7.7-2.64-17.14,1.33-27.76,5.72-18.39,22.09-32.86,36.04-32"/>
      <path id="heel3" class="fusswurzel1453_dpl_obl_links_cls1" d="M82.57,215.93c1.4-8.41,5.58-15.08,11.77-20.48,4.54-3.88,9.9-6.72,16.04-8.57,1.97-.59,3.83-1.54,5.5-2.81l3.63-2.79c2.93-2.25,6.17-4.07,9.56-5.4,1.26-.49,2.44-.97,3.57-1.43"/>
      <path id="heel4" class="fusswurzel1453_dpl_obl_links_cls1" d="M170.25,260.03c20.7-11.99,31.69-30.69,29.17-58.75-9.77-11.41-3.77-23.4-9.23-30.33,2.41-6.8-4.72-12.42-2.92-18.81,1.53-4.52-4.15-9.31-4.37-14.3-6.01-4.31-12.05-5.54-18.12-4.57-11.65-.18-34.37,5.43-41.72,13.41-5.7,6.77-5.77,9.57-2.19,10.79,8.25,1.29,22.89,6.01,22.21,14.49,1.57,13.19-3.69,19.11-11.85,21.97-21.93,8.2-36.52,16.58-42.32,25.18-2.51,7.73-3.87,15.73-9.84,20.56-6.1,5.87-9.02,12.65-8.46,20.42-.76,7.62,6.99,13.98,24.83,18.87,23.71,5.27,46.07.47,67.1-14.1,2.5-1.72,5.09-3.32,7.71-4.83h-.02Z"/>
      <path id="heel5" class="fusswurzel1453_dpl_obl_links_cls1" d="M123.24,271.13l12.25-25.02c3.31-3.86,1.04-11.89,5.83-14.84.85-6.59,4.01-9.01,8.45-9.13,5.86-.52,7.8-3.32,6.25-8.11l-5.72-18.47c-4.9-1.76-6.39-4.76-6.68-8.19-.2-3.23-5.6-6.09-5.9-9.54-.08-1.02-.24-2.05-.74-2.94-1.79-3.28-1.57-10.52-5.93-14.63"/>
      <path id="heel6" class="fusswurzel1453_dpl_obl_links_cls1" d="M179.51,135.74c-1.4,2.82-5.45,3.33-10.2,3.22l-18.35-1.16c-2.9-.19-5.73,1.36-7.27,3.99-3.8,6.48-6.26,13.43-7.15,20.91"/>
      <path id="heel7" class="fusswurzel1453_dpl_obl_links_cls1" d="M157.06,269.01c-4.07-10.01-5-19.01-2.66-26.96,1.93-6.56,6.58-11.95,12.51-15,7.58-3.91,16.6-6.37,26.34-8.13"/>
      <path id="heel8" class="fusswurzel1453_dpl_obl_links_cls1" d="M117.87,275.2l17.56-47.68c.48-1.31.44-2.78-.19-3.99-1.74-3.35-4.24-5.86-7.52-7.5-3.82-1.91-8.25-2.25-12.47-1.25-16.55,3.94-25.78.08-27.98-11.26"/>
      <path id="heel9" class="fusswurzel1453_dpl_obl_links_cls1" d="M83.51,165.76c5.75,4.33,11.94,2.41,20.7-8.29"/>
    </g>
  </g>
  <g id="overlays">
    <path id="tarsal_bone_overlay" data-name="tarsal bone overlay" class="fwk1453fill" style= {{
              fill: props.colors.fwk1453fill,
              stroke: props.colors.fwk1453fill,
              opacity:props.colors.fwk1453fill,
            }} d="M155.11,298.34c-22.8,31.98-43.56,48.75-61.25,42.78-16.94-2.91-31.6-10.12-44.71-20.29-15.26-19.57-24.62-34.04-23.91-39.77.16-7.36,1.79-13.72,4.54-19.3l-.16-8.07c.17-8.67,3.75-16.85,9.62-24.72,2.2-3.34,2.91-8.81,3.03-15.14,1.09-13.26,5.26-25.47,11.34-37.02,1.34-1.77,1.79-3.55,1.68-5.32-1.11-5.78.48-11.41,5.26-16.87-10.88-3.26-15.52-7.44-14.88-12.41-.07-13.37-2.58-21.58-7.88-23.92-6.29-3.95-3.49-7.03,1.83-9.86,5.52-10.3,16.88-18.03,30.95-24.59l9.04-3.23,4.41-7.98c-1.37-7.85,2.25-13.43,10.94-16.7,4.58-1.52,9.17-1.19,13.79,1.59l9.59,4.08c3.52-3.1,8.03-5.22,12.88-7,5.86-2.57,10.42-3.69,12.94-2.52,4.69-3.4,6.86-7.94,8.04-12.92,2.29-4.19,7-5.88,12.86-6.37,8.37.33,13.89,6.09,17,16.42,2.59,6.24,2.41,12.74,0,19.46,3.87,19.51,3.42,32.69-2.54,37.8,6.72,14.7,10.18,29.36,7.26,43.96.72,7.05,3.9,12.8,3.42,20.52,4.7,6.85,1.06,22.8,9.23,30.33,4.71,34.38-16.06,55.25-48.67,69.85,5.78,12.92,7.8,22.5,4.35,27.21Z"/>
  </g>
</svg>
  );
}

export default LinkeFusswurzelDplOblSitzend1453;
