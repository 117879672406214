import React, { useState } from "react";
import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../Redux/mappers/pricelist/pricelistMappers";
import { useTranslation } from "react-i18next";

import { Button, Row, Col, Container, Card } from "react-bootstrap";
import ChangePrices from "../Components/PriceLists/ChangePrices";
import NewPricesSummary from "../Components/PriceLists/NewPricesSummary";
import SelectFacility from "../Components/PriceLists/FacilitySelectionWrapper";

import PriceListEditor from "../Components/PriceLists/PriceListEditor";
import PriceListSummary from "../Components/PriceLists/PriceListSummary";

function PriceLists(props) {
  const { t, i18n } = useTranslation(["priceList"]);

  const getFacilityNames = (facilities) => {
    let result = "";
    for (const [id, name] of Object.entries(facilities)) {
      result += name.name + ", ";
    }
    result = result.slice(0, -2);
    return result;
  };

  const stages = {
    SelectFacility: {
      component: SelectFacility,
      description: t("selectFacility"),
      validator: () => {
        if (Object.keys(props.pricelists.facility).length > 0) {
          return true;
        }
        return t("selectFacilityView.noFacility");
      },
    },
    ChangePrices: {
      component: PriceListEditor,
      description:
        t("changePrices") + getFacilityNames(props.pricelists.facility),
      validator: () => {
        if (Object.keys(props.pricelists.changedPrices).length > 0) {
          return true;
        }
        return t("changePricesView.noPriceChanges");
      },
    },
    Summary: {
      component: PriceListSummary,
      description: t("summary"),
      validator: () => {
        return true;
      },
    },
  };

  const sequence = {
    nextStages: [stages.SelectFacility, stages.ChangePrices, stages.Summary],
  };

  const [stageNo, setStageNo] = useState(0);

  const currentStage = sequence.nextStages[stageNo];

  const goNextStage = (skipValidation = false) => {
    if (skipValidation) {
      setStageNo(stageNo + 1);
      return;
    }
    let valid = currentStage.validator();
    if (valid === true) {
      setStageNo(stageNo + 1);
    } else {
      alert(valid);
    }
  };

  const goPrevStage = () => {
    setStageNo(stageNo - 1);
  };

  return (
    <Container fluid className="mx-0 px-0 my-0 py-0">
      <Row className="mb-4 justify-content-center">
        <Col md={10} sm={12}>
          <Card>
            <Card.Header>
              <h4 id="ChangePricesHeader">{currentStage.description}</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={1} className="my-auto text-left">
                  {stageNo > 0 && (
                    <Button onClick={goPrevStage}>
                      <i className="fas fa-arrow-left"></i>
                    </Button>
                  )}
                </Col>
                <Col md={10} className="my-auto">
                  <currentStage.component
                    keycloak={props.keycloak}
                    multiple="true"
                    nextStage={goNextStage}
                    prevStage={goPrevStage}
                  />
                </Col>
                <Col md={1} className="my-auto text-right">
                  {stageNo != sequence.nextStages.length - 1 && (
                    <Button onClick={goNextStage}>
                      <i className="fas fa-arrow-right"></i>
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceLists);
