import style from "./style.scss";

function LinkesKahnbeinDaumenReinDpSitzend1349(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 435 302">
      <g id="background">
        <path
          id="background_white"
          data-name="background white"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls2"
          d="M317.6,60.4c-.3-4.7-.8-9.3-1.6-13.6h-17.8c-.2,0-.3,0-.5-.1-7,.5-14.1.7-21.1,1-.2,0-.5,0-.7,0h-10.9c-.3,0-.7-.2-.9-.5-24,.3-47.9.6-71.9.9-38.6.5-77.2.8-115.8,1-1.3,0-2.2,1.4-1.5,2.6,0,0,0,0,0,0,.1.2.2.4.2.7,3.5,43.6,8.7,83.8,20.7,110.8,0,.2.2.4.4.5,7,7.1,1.6,40.7.4,73.6-.6,8.2,1.2,17.9-.3,26.5-.2,1,.6,2,1.6,2l135.6,6.1c.9,0,1.8-.7,1.8-1.6,1.4-20.5,3.3-38.9,6.8-51.1,2.2-9.1,5.4-17.3,12.9-21.3,0,0,.2,0,.2-.1,7.8-6.2,16.4-12.9,18.4-15.3,6.8-7.1,13.4-14.3,19.8-21.3.7-1.5,1.6-3,2.5-4.4,1-1.8,2-3.6,3.1-5.5,0,0,0-.2.2-.4,0,0,0,0,.1,0,2.1-3.7,4.3-7.4,6.3-11.1,0-.2.2-.3.3-.4-.1-.4-.1-.7,0-.9,4.3-7.6,5.6-13.5,7.5-22,0-.4,1.8-9.8,2.2-10.2,0-.2,1.1-25.4,1.2-25.6.1-.7.3-1.2.4-1.4-.2-.3-.3-.7-.3-1.1v-16.4c0-.5.3-.8.7-1.1h0Z"
        />
      </g>
      <g id="handparts">
        <g id="outlines">
          <path
            id="outlinepart7"
            class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
            d="M95.8,265.7c.4-1.6.8-13.2.9-14.9"
          />
          <path
            id="outlinepart6"
            class="Linkes_Kahnbein_Daumen_rein_dp1349_cls3"
            d="M96.7,250.8c-1.6-23.8,3.3-48.8,3.4-72.7,0,0,0-.2,0-.3-1-7.9-5-15.9-7.9-22.8,0,0,0,0,0-.1-11-32-14-64.9-16-97.9,0-2-1-4-1.6-5.8"
          />
          <path
            id="outlinepart5"
            class="Linkes_Kahnbein_Daumen_rein_dp1349_cls3"
            d="M291.3,163.3c-11.7,14.4-29.3,28.7-43.3,39.7-10,18-9,39-12.2,59.1"
          />
          <path
            id="outlinepart4"
            class="Linkes_Kahnbein_Daumen_rein_dp1349_cls3"
            d="M305.9,139.1c-1.5,4.6-17.6,30.7-17.4,26.6"
          />
          <path
            id="outlinepart3"
            class="Linkes_Kahnbein_Daumen_rein_dp1349_cls3"
            d="M321.1,48.5c2.3,15.7,2.6,61.1-15.5,91"
          />
          <path
            id="outlinepart2"
            class="Linkes_Kahnbein_Daumen_rein_dp1349_cls3"
            d="M315.2,48.3"
          />
          <path
            id="outlinepart1"
            class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
            d="M236.4,258.3c-.1,1.6-1.2,12-1.3,13.7"
          />
        </g>
        <path
          id="bonepart25"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M224.1,47.7c-2,20.4-7.6,41.6-17.6,63.9-1.7,2.6-4.6,4.4-8,5.8-2.5,9.5-7,17.5-13.3,24.3-1.2,1.3-.5,2.9,2.1,4.9,1.3,1,3,1,4.3,0,4.3-3.6,9.3-4.2,14.9-2.1,2.7,1,5.2,4.1,7.6,7.9h0c.5.9,1.3,1.6,2.3,1.9,5.7,2.2,10.8-1.9,15.2-11.7,1.1-2.5,1.4-5.1.6-7.9-7.4-13.9-5.3-31.1,1.4-49.8l15.4-36.2c.2-.5.4-.9.7-1.4"
        />
        <path
          id="bonepart24"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M174.6,48.3c-.4,13.7-1.7,29.3-3.4,45.9-1.8,5.8-4,10.2-6.9,12.1-.7.5-1.4,1.1-1.7,1.9-2.4,5.2-4.4,10.7-5.7,16.6-.5,2.3.1,4.7,1.8,6.3,3.5,3.3,8,5.9,14.1,7.1h0c7.6,1.9,13.6.8,16.6-5.9,5.7-6.5,5.6-13.3,1.2-20.5-4.3-22.9-2.1-40.3,2.3-55.9.8-2.8,1.7-5.4,2.8-7.7"
        />
        <path
          id="bonepart23"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M185.3,100.9l.9,9.3c.1,1.1.5,2.1,1.1,3,.7,1.1,1.3,2.2,1.6,3.4.5,1.9,0,3.9-1.2,5.4h0c-.8,1-1.1,2.2-1,3.4l.5,4.1"
        />
        <path
          id="bonepart22"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M164.5,116.2c-.6-3.7.4-7.8,2-12.1"
        />
        <path
          id="bonepart21"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M137.7,48.7l2.6,22.1v32.9c-.1,1.7-.9,3.4-2.3,4.3-3.2,2.1-6.2,6.1-8.7,12.3-2.9,4.5-3.1,8.1,1.4,9.9l22.9,7.8c6.3,1.3,9-2.2,9.5-8.4h0c.1-12.8-2.2-22.3-6.3-29.4-1.6-12.6-1.1-28.9,0-46.4.7-1.8,1.3-3.5,1.9-5.3"
        />
        <path
          id="bonepart20"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M136.7,113.2c1.8-1.4,2.4-4.4,2.9-7.2"
        />
        <path
          id="bonepart19"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M93.7,49c1.4,14.5,15.8,37.9,17.8,62.5l-1.4,8.2c0,6,.6,11.5,3.9,14.8,1.3,8,6.3,9.2,12.6,8l.6.3h0c4.6-3.8,10.9-1.2,13.3-4.6,4.1-5,2.6-10.6-2.4-16.7.9-3.5.2-6.8-2.7-9.8-7.2-8.7-12.5-26-17.5-44.1-1.1-6-1.4-12.2-.9-18.8"
        />
        <path
          id="bonepart18"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M223.5,149.4c6.8-2,9.6,1.2,9.7,7.9-.4,7.2.7,13.6,6.5,17.7,1.6,2.9-2.4,5.9-2.7,9.2,0,.7-.3,1.4-.6,2-1.7,2.7-4.5,3.6-7.9,3.4-2.4-1.7-5.5-2.1-9-2.1-1.2,0-2.3-.7-2.7-1.9-1.3-4.1-3.9-8.2-7.3-12.4,0-6.6,1.6-11.3,4.1-14.4l6.2-7.2c1-1.1,2.3-2,3.8-2.4h0Z"
        />
        <path
          id="bonepart17"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M217.6,158.5c1.6,3.8-.7,7.3,0,10.7.6,3.9,2.8,6.2,8.3,4.9,3,.4,5-3.9,6.7-10.4"
        />
        <path
          id="bonepart16"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M192.1,149.5c3.6-3.8,7.9-4.8,13.3-2.1,7.2,4.3,8.9,10.3,8.1,17.2-.8,4.9-2.4,7.7-4.4,9-1,.7-2.4.5-3.4-.3-3.9-3.5-7.8-5.9-11.7-6.6-1.1-.2-1.9-1.2-1.9-2.3,0-4.9-.1-9.3-.8-12.2-.2-.9,0-1.9.7-2.6h0Z"
        />
        <path
          id="bonepart15"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M181.2,181.8c1.5-.9,2.5-2.4,2.8-4.2.8-4.6,2.3-6.8,4.4-6.8,6.5-.6,11.7.9,15.2,5.2.4.5.9,1,1.4,1.4,4.7,4.1,7,7.7,6,10.6-.4,1.1-.1,2.3.5,3.3,1.9,3.1.7,5.9-2.8,8.4-1.3.9-2.4,2-3.5,3.1-8.2,8.8-16.8,11.9-25.5,12.1-2.1,0-4.1-.2-6-1-3.3-1.3-5.1-3.6-5.5-6.6-.2-1.3,0-2.7.3-4,1-3.3,2.1-6.5,3.6-9.4,2.2-5.7,5.2-9.7,9.1-12.1h0Z"
        />
        <path
          id="bonepart14"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M171.1,196.4c4.3-.9,8.3-2.7,11.5-6.8,3.6-3.1,3.2-6,.8-8.8-.1-.1,0-.4.2-.3,3,.9,4.8-.4,5.5-3.7v-6.2"
        />
        <path
          id="bonepart13"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M149.2,172.6c-1,3.2-1.3,6.6-.6,9.8.8,4.2,2.7,6.8,6.1,7.2,8.5,2.1,16.5,2.4,23.5-.7,3.8-1.9,5.3-4.9,5.5-8.4,1.5-3.4,1.7-6.5-1.1-8.7-1-.9-1.4-2.1-1.4-3.2,0-2.1,1.3-4,3.2-4.9l.6-.3c2.3-1.1,3.8-3.3,3.8-5.8,0-3.4-1.7-6.1-4.3-8.2-.8-.7-1.4-1.6-1.7-2.6-1.1-5.2-5-6.6-10.2-6.5-1.5,0-3-.4-4.3-1.3-7.9-5.4-12.4-6-14.9-3.7-.7,1.3-1.9.5,0,11.6l.6,7.4c.1,1.4,0,2.7-.4,4l-4.4,14.3h0Z"
        />
        <path
          id="bonepart12"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M120.8,135.4c-1.5,1.9-2.7,3.9-3.4,5.9,0,7.8,4,11.9,4.9,16.7.2,1.3,1,2.4,2.1,3,6.9,4,12.8,9.8,16.4,19.1.5,1.2,1.3,2.3,2.4,3,4,2.5,6.9.7,9.2-4,.2-.4.3-.8.5-1.2,1.8-6.1,2.6-13.1,2.9-20.7,1.4-13.2-.5-18.7-4.2-20l-25.4-4c-2.1-.3-4.2.5-5.5,2.1h0Z"
        />
        <path
          id="bonepart11"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M132.9,143.7c3.6.4,7.1-.4,10.6-2.9,2.3-3,4.7-3.7,7.2-1.4,1.5,1.1,1.7,2.5.9,4.2-.6,1.2-1,2.4-1.1,3.8l-.4,4.5c-.2,2.6-1.1,5.2-2.6,7.4-2.3,3.3-4.8,5-7.8,3.3-2.9-5.7-2-9.6-5.9-11.6"
        />
        <path
          id="bonepart10"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M113.4,190.5c-.4-2-1.2-3.9-2.5-5.5-2.5-3.1-2.1-6.6.2-10.5,2.1-4.4,5.7-6.9,11.4-7.2,5.3.3,9.5,3,11.7,9.8,2.2,5,4.9,9.7,10.8,12,2.1.9,1.2,3-2.3,6.1-2.1,1.1-3.9,3.1-5.4,6.3-1.2,2.7-3.8,4.5-6.7,4.8-3.5.3-6.6-.2-9-2-4.9-4-7.2-8.7-8.2-13.9h0Z"
        />
        <path
          id="bonepart9"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M116.3,189.7c0-1.9.3-3.7,1-5.5,1-2.6,3-4.7,5.4-6.2,5-3.1,10.2-5.1,15.7-5.6,1.1-.1,2.3.1,3.2.7,1.5,1,2.5,2.8,2.4,6.4-1.2,4.2-2.6,8-4.7,11.1-2.6,3.9-6.8,6.6-11.5,7.1-3.3.4-6.3-.5-9.1-2.7-1.6-1.3-2.4-3.2-2.5-5.2,0,0,0,0,0,0Z"
        />
        <path
          id="bonepart8"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M164.9,217.7c4,.6,5-2.3,4.8-6.6,1.7-3,2.1-6.8,1.6-11.1-.2-1.7-1.6-3-3.2-3.1l-7-.4c-2.7-1.8-5.7-2.6-8.9-2.6s-5.3,1.5-6.7,3.9c-1.2,2.2-2.1,5-2.7,8.1-3.9,3.5-4.5,6.7.4,9.1,4.5,2.8,8.7,4.1,12.5,3.9l9.2-1.2h0Z"
        />
        <path
          id="bonepart7"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M148.8,194.6c1.7-1.2,3.4-2.2,5.1-2.9,3.5-1.3,7.4-.7,10.5,1.4,1.8,1.2,3,2.4,3.6,3.7"
        />
        <path
          id="bonepart6"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M220.8,269.8c2.7-6.9,4.9-13.8,5-20.4,1.9-17.2-4.6-24.4-.8-33.2,1.5-7.8.6-13.8-3.4-17.6-1.5-1.5-3.9-1.5-5.4,0-8.6,8-16.6,14.2-23.3,16.9l-36.7,7.2c-6.6.9-9.9,6.7-6.8,22.1,2.5,8.2,6.9,16.3,13.6,24.5"
        />
        <path
          id="bonepart5"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M110.2,266.6l-.3-19.1c-3.3-8.7-4.6-15.9-2.8-21,.5-7.9,2.3-12.6,5.7-13.7,4.2-2.1,5.7,3.5,9.2,10.4,1.3,2.5,3.9,3.9,6.6,3.8,4.6-.2,8.7.1,11.7,1.4,2.3,1,3.8,3.4,3.8,5.9v8.5c0,2.1-.4,4.1-1.2,6-2.2,5.6-3.2,11.8-3.2,18.7"
        />
        <path
          id="bonepart4"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M109.2,264.5c-3.6-9.1-7-18-5.5-24.4.4-5,3.3-7.6,9.9-6.4,6.4,3,14.9-2.8,26-5.4"
        />
        <path
          id="bonepart3"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M157.7,253.4c-3.9-9.5-5.9-18.5-1.5-26,2.8-5.3,27.2-4.6,36.7-12"
        />
        <path
          id="bonepart2"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M228.1,169.1c-4.5-4.2-3.5-9,1.1-14.1,2.5-4.3,7.2-7.9,12.6-11.2,12.4-7.8,22-17.1,27.1-28.7,1.8-4.2,1.8-8.9.1-13.1-3.3-8.6-2.7-14.1,2.1-16.3,5.6-3.3,11.5-4.4,17.6-2.1,6.6.2,11.1,6.6,14.3,17.5,1.6,3.2,0,6.8-4.2,11-13.5,16.4-24.3,35.8-33.3,57.1-3.8,7.2-8.2,10.9-13.4,9.9-7.6.7-11.1-2.3-12.4-7.4l-11.5-2.6h0Z"
        />
        <path
          id="bonepart1"
          class="Linkes_Kahnbein_Daumen_rein_dp1349_cls4"
          d="M273.2,49.7c1.7,10.2-1.9,20.5-5.1,30.7-.7,2.1-.7,4.3,0,6.4s2.2,3.9,6,3.2c9.4-1.8,17.2-6.3,27.3-4.1,1-.2.9-1.2,1.9-1.4,1.7-2.4.1-5.2-.3-7.2-7.6-12.6-8.5-22.1-10.7-31.7"
        />
      </g>
      <g id="kahnbeinhighlight">
        <path
          class="Kahnbein1349Fill"
          style={{
            fill: props.colors.Kahnbein1349Fill,
            stroke: props.colors.Kahnbein1349Fill,
          }}
          d="M181.6,181.8c1.5-.9,2.5-2.4,2.8-4.2.8-4.6,2.3-6.8,4.4-6.8,6.5-.6,11.7.9,15.2,5.2.4.5.9,1,1.4,1.4,4.7,4.1,7,7.7,6,10.6-.4,1.1-.1,2.3.5,3.3,1.9,3.1.7,5.9-2.8,8.4-1.3.9-2.4,2-3.5,3.1-8.2,8.8-16.8,11.9-25.5,12.1-2.1,0-4.1-.2-6-1-3.3-1.3-5.1-3.6-5.5-6.6-.2-1.3,0-2.7.3-4,1-3.3,2.1-6.5,3.6-9.4,2.2-5.7,5.2-9.7,9.1-12.1h0Z"
        />
      </g>
    </svg>
  );
}

export default LinkesKahnbeinDaumenReinDpSitzend1349;
