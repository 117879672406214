import style from "./style.scss";

function ThoracolumbalerUbergangLatLiegend1533(props) {
  return (
<svg id="layer1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.96 538.54">

<g id="Anatomy">
  <path id="thoracicspine13" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls4" d="M167.95,113.64c.9-.1,2.3,1.6,5,5,1.8,2.2,1.8,2.5,3.2,4.1,2.4,2.7,3.9,3.5,3.6,4.1-.4.8-3.6-1-7.3,0-4.3,1.2-5.6,5.1-7.3,4.6-1.5-.5-1.4-4.1-1.4-5.9.1-4.8,1.7-11.7,4.2-11.9h0Z"/>
  <path id="thoracicspine12" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M89.25,48.04c-.5-.8.2-3.2,1.5-7.8,1.3-4.7,1.9-5.3,1.5-6.9-.7-2.9-3.1-2.9-4-6-.7-2.2-.3-5.4,1.5-6.5,1.8-1,3.4.8,7.4.9,3.8.1,4.2-1.4,9.4-2.3,5.6-1,5.6.6,10.9-.5,4.8-1,5.5-2.4,9.4-2.3,2.3.1,3.4.6,5.4,0,1.6-.5,4.2-1.8,4.5-3.7.3-2.4-3.4-3.6-3-5.5.4-1.8,4.9-4.9,6.4-2.8,8.9,12.1,7.8,12.4,6.9,7.9-.1-.5.1-.5,1-3.7.8-3.1,3.5-7.9,7.9-8.3,4.6-.5,8,3.9,8.9,5.1,1,1.3,3.4,4.4,2.5,7.4-.4,1.2-1.1,1.6-5.9,5.5-4.3,3.5-4.7,4-4.9,4.6-1.4,3.1.5,6.6,2,9.2,4.4,7.9,10.9,10.4,9.9,12.9-.4,1-1.5.6-5.4,2.8-3.8,2.1-5.2,3.7-5.9,3.2-1.3-.7,2-5.1,0-7.8-1.6-2.1-4.5-.8-6.9-3.2-1.8-1.8-.8-3.1-2.5-6.5-.4-.8-3.2-6.5-6.9-6.5-3,0-5.7,3.7-6.4,6.9-1.1,4.9,2.7,7.7,1,10.2-1.2,1.8-4.2,1.6-10.9,1.8-.3,0-5.5.5-15.8,1.4-13.8,1.3-18.2,2.6-19.5.5h0Z"/>
  <path id="thoracicspine11" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M88.75,54.94c-2.3,2.6.8,5.4.5,13.4-.3,8.3-3.8,12.2-1.5,14.3,1.4,1.4,3.5.5,9.4,0,10-.8,9.8,1.3,18.3.5s9.8-3,15.4-1.8c3.3.7,5.3,2,6.9.9,2.1-1.3.4-4.3,2.5-8.3,1.1-2.1,3.7-5.6,6.9-5.5,3.8.1,6.5,5.4,7.4,7.8.6,1.6.3,1.6,1.3,4.6.3,1,1.2,2.9,3.1,6.7.6,1.3,1.4,2.9,3.2,3.8l.4.2c2.2.7,4-3.3,6.9-4.2,4.1-1.1,8.1,4.4,9.2,3.6,1.1-.8-1.4-6.4-5.3-11-.3-.4-2.4-2.6-6.4-6.9-3-3.2-4.4-4.8-4-5.5.6-.9,3.9,1.1,6.4-.5.6-.4.5-.6,1.5-1.5,1.8-1.6,3.4-2.2,4.4-2.7,3-1.5,4.7-5.3,4.9-8.3.1-.6.4-5.7-3.5-8.3-3.2-2.2-7.4-1.3-8.9-.9-6.7,1.5-10.1,6.8-11.4,6-1.2-.8,2-5,0-7.8-1.3-1.8-3.3-.9-6.9-3.2-3.4-2.2-3.9-4.4-5.4-4.2-1.6.2-2.7,3.1-3,5.1-.8,5.4,3.4,8.2,2,10.2-1.2,1.6-4.5.3-11.9.5-3.4.1-3.4.4-9.4.9-7.6.7-14,.8-18.3.9-11.1.1-13.1-.5-14.7,1.2h0Z"/>
  <path id="thoracicspine10" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M89.65,89.14c-2.2,1.3-1.7,5.5-1.5,6.9.4,3.8,2,4,2.5,7.4.4,2.9-.3,5.6-1.5,9.7-1,3.4-1.7,4.6-1,6,.3.7,1.3,1.9,5.4,2.8,5.1,1,8.5.1,13.9-.5,2.1-.2,6.8-.7,11.9-.5,10.1.5,13.3,3.6,16.4,1.4.6-.4,1.9-3.4,4.5-9.2,1.8-4.1,2.3-5.3,3.5-5.5,2.6-.5,5.6,4.5,5.9,5.1.6,1.1,2.4,4,2,7.8-.3,3.4-2,3.9-1.5,6,.9,3.7,6.7,5.2,7.3,5.4,1.6.4,5,1.2,6.6-.3,2-1.9-1.2-5.1,0-10.6.9-4.1,3.7-7,4.9-8.3.7-.8,2.6-2.6,5.4-4.2,4.3-2.4,5.9-1.6,7.9-3.7,1.7-1.8,2.6-4.5,2-6.9-.5-2-1.7-3.1-4-5.1-3.7-3.2-6.9-6.1-10.4-5.5-3.8.6-4.5,4.6-6.9,4.2-2-.3-3.3-3.2-5.4-7.8-1.6-3.5-1.6-4.6-2.6-4.8-2.2-.4-6,4.7-7.9,9.4-.9,2.4-.8,3.4-2,4.2-2.4,1.5-5.8-1-10.4-2.3-3.8-1.1-4-.1-18.3.9-10.3.7-15.6,1.1-18.8,0-3.5-1.3-6-3.1-7.9-2h0Z"/>
  <path id="thoracicspine9" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M86.25,160.24c-1.1-1.7-.5-3.9.5-7.9,1.4-5.4,3.2-6,3-9.2-.2-2.7-1.5-2.8-2.5-6.9-.7-2.8-1.5-6.2,0-7.9,1.8-2.1,5.7-.4,13.9.6,9.5,1.2,16.5,2,24.3,0,4.7-1.2,8.5-3.2,10.9-1.4,1.8,1.3,1.3,3.5,4,5.5,1.8,1.4,4.6,2.2,5.4,1.4,1.1-1-1.8-3.5-1.3-7,.5-4.2,5.6-7.7,7.3-6.9,1.8.8-1.4,5.8,1.4,9.1,2.2,2.6,7.4,3.1,11,2s3.6-3.1,7.4-4.2c.9-.2,4.8-1.4,8.4.5,4.3,2.2,5.1,7.2,5.4,9.2.4,2.3,1,6-1.5,8.8-3.4,3.8-10.1,2.9-11.4,2.8-6.6-.8-8.5-4.8-11.9-3.7-3.9,1.2-2.9,6.8-6.9,8.3-4.7,1.8-9.6-4.1-12.4-2.3-3,1.9,2.1,9.6-1.5,13.4-1.5,1.6-3.9,1.8-16.8.5-18.6-1.9-16.1-2.6-22.8-2.8-7.8-.2-12.2.7-13.9-1.9h0Z"/>
  <path id="thoracicspine8" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M77.35,197.24l2.5-15.2,2.4-16.2,13.9,3.3,16.8.9,15.3.9,8,1.4,3.5,6.9,5,2.3,3.4-4.1v-6c0-3.4,2.1-6.5,5.4-7.8,1.5,1.3,3.3,3.2,4.9,5.5.9,1.3,1.6,2.6,2.2,3.7.8-.2,1.5-.4,2.3-.7l11.4,1.1,6.5,2.3c.8.8,1.9,2.1,2.7,3.9,1,2.3,1.1,4.3,1,5.5-.1.9-.5,2.4-1.5,4-.7,1.1-1.6,1.9-2.2,2.4-2.6,1.1-5.3,2.2-8,3.2l-9.9-.9,10.4,12,7.9,17.6c1.1,4,2.1,7.9,3.2,12l1.1,11.1,3.6,7.9c.2.3,1.4,2.5.5,5.1-1,2.6-3.4,3.6-3.8,3.8-.6.4-2.1,1.3-4.1,1.3-2.3,0-4.2-1-4.9-2.3-1.5-2.6-3-5.2-4.5-7.9l-6.4-8.8-5.3-8.3h7.8c1.1.1,2.8.1,4.7-.2,2-.4,3.5-1,4.5-1.5.4-.6,4-7.2.6-13.6-3.1-5.8-10.7-8.9-18.4-7.2l-3.4,1.7-7.4-3.5-2-7.2c.2-3.6.4-7.2.6-10.8-1.8-1.5-3.7-2.9-5.5-4.4l-6.6,2.8c-.1,3.5-.2,7-.3,10.5-.9,1.1-2.1,2.2-3.4,2.1-1-.1-1.3-.9-2.5-1.5-.9-.5-2.3-.9-4.6-.5-7.8-1.1-15.5-2.2-23.3-3.2l-24.1-3.4h0Z"/>
  <path id="thoracicspine7" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M67.85,235.54c-1.1-1.8,0-3.4,2.5-11.1,1.3-4,1.9-6,2.5-8.3,1.7-7.2.8-9.4,3-11.1,2.2-1.7,5.3-1.3,8.4-.9,6.4.8,7.2,3.5,13.4,4.6,5.1.9,5-.8,11.4,0,6.6.9,6.7,2.7,11.9,2.8,5.5,0,8-2.1,10.4-.5,2.1,1.4,1.4,3.9,4,5.1,1.5.7,3.5.7,4.9,0,3.6-1.8,3.2-7.3,4.5-7.4,1.8-.1,2.4,12,5.9,12.5,1.8.2,2.5-2.8,5.9-5.1,4.6-2.9,12.6-3.5,17.9.4,6.7,5.1,6.6,15.9,2.4,18.9-1.9,1.4-5.1,1.4-8.3,1.4-3.4,0-4.8-.6-7.8,0-2.3.4-2.9,1.7-4.6,1.4-1.5-.2-1.6-1.1-3.8-1.9-1.1-.4-2.6-.9-4.1-.4-1.7.5-2.6,2-3.3,3.2-1,1.8-.8,2.5-1.6,4.6s-2.5,5.3-3.5,5.1c-1.3-.2.7-6.2-2.5-9.2-2.9-2.8-9.5-2.7-11.9,0-2.6,3,1.3,7.5-1,9.7-1.3,1.3-4.1,1-15.8-2.8-13.1-4.2-12.1-4.6-16.8-5.5-3-.6-6.7-1-16.3-2.8-4.8-.7-6.7-1.1-7.7-2.7h0Z"/>
  <path id="thoracicspine6" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M52.05,276.24c-.1-1.9,2.5-2.2,5-6,1.6-2.5,1-3.2,3-9.7,1.5-5.1,1.8-4.2,3.5-9.7,1.8-5.8,1.9-7.9,3.5-8.3,1.5-.4,2.6,1.4,5.9,3.2,2.3,1.2,3.9,1.6,9.4,2.8,8.6,1.8,12.8,2.7,15.8,3.2,4.6.9,9.2,1.9,13.9,2.8,4.8.8,7.3,1.2,9.4,3.2,2.2,2.1,1.3,3.5,3.5,6,2.3,2.6,6.5,4.6,10.4,4.2,4.2-.5,4.4-3.3,9.9-5.1.9-.3,7.9-2.4,10.9.5.5.5,1,3,2,7.9.3,1.7.6,3.3,1,5.5.5,3.2.7,4.8.5,5.5-1,2.9-5.1,4.8-8.4,5.1-2.1.2-3.5-.2-10.9-3.2-9.4-3.8-10.3-4.2-12.4-4.2-3.9.2-7.9,2.2-10.4,5.1-3.5,4.1-2,7.7-5.4,9.7-2.4,1.4-5.3.9-7.4.5-5.3-1-6.4-3.5-11.4-6-2.8-1.4-2.8-.8-17.8-3.7-6.8-1.3-9.1-1.9-11.9-2.8-5.4-1.7-11.4-3.7-11.6-6.5h0Z"/>
  <path id="thoracicspine5" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M40.25,316.94c-.4-1-.2-1.9,4-9.2,4.2-7.3,4.8-7.8,5-9.7.5-4.8-2.6-7.1-1-9.2,1.8-2.4,7.1-1.6,8.4-1.4,4.5.7,6.3,2.6,10.9,5.1,7.9,4.3,15.7,5.8,21.3,6.9,4.8.9,8.9,1.4,8.9,1.4,7,.8,7.2,0,9.4.9,5.4,2.1,4.5,6.5,9.9,8.3.4.1,5.2,1.7,8.9-.5,2.5-1.5,2.6-3.4,4.5-3.7,3.1-.4,6.2,4.6,6.4,5.1.6,1,3.4,6,1,10.2-2,3.4-6,3.5-5.9,5.5,0,1.3,2,2.4,5.9,4.6,4,2.2,7.3,3.5,7.9,3.7,5.2,2,5.7,1.4,7.9,2.8,2.3,1.4,3.5,3.1,5.9,6.5,2.4,3.2,3.5,4.9,3.5,6.5-.1,3.6-4.1,6.3-6.9,7.4-3.6,1.4-7.8,1-10.9-.5-4.2-2-3.5-4.6-7.9-6.9-3.9-2-5.1-.3-8.9-2.3-2.6-1.3-3.4-1.8-4.3-2.2-1.5-.7-1.4-.5-3.3-1.3-2.9-1.2-3.2-1.7-5.8-2.9-3.1-1.3-3.2-.8-3.9-1.5-2.4-2.1.5-5.7-1.5-8.8-2.2-3.4-8.3-5-11.9-3.2-2.9,1.4-2.2,4.1-4.9,5.5-2.5,1.3-4.9.1-12.4-2.3-11.3-3.7-10.7-2.7-16.3-5.1-7.2-3-6.4-3.7-11.9-5.5-7.3-2.5-11-1.9-12-4.2h0Z"/>
  <path id="thoracicspine30" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M167.95,324.24c1.8-1.9.4-3.3,2-6.9,1.9-4.2,5.1-5.2,4.5-6.9,0,0-.3-.7-5.9-2.8,0,0-4.2-1-6.9-2.8-.9-.6-2-1.5-5.9-8.3-3.3-5.7-2.9-5.7-4-6.9-2-2.3-3.3-1.8-11.4-5.5-4.8-2.2-6.4-3.3-10-3.9-4.8-.8-6.4.3-6.8.7-1.2,1-2,2.9-1.5,4.6.7,2.5,3.4,2.5,7.4,6.5,1.9,1.9,3,3,3,4.6,0,2.5-2.4,2.9-2.5,5.1,0,2.8,4,2.9,6.8,7.9,1.3,2.4,2.1,4,2,6-.1,1.8-.1,3.5.4,3.6s1-1.4,3-2.5c.7-.4,1.4-.6,2.7-1,2.4-.7,3.7-1,4.5-.7,1.7.6,1.2,2.8,3,5.5.6.9,3,4.6,7.4,5.5,2.3.6,6.2.4,8.2-1.8h0Z"/>
  <path id="thoracicspine29" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M176.95,297.44c1.7-.4,5.8-3.8,7.6-7.9.3-.7.4-.9.5-.9,0,0-.4,1.2-.5,1.2,0,0,.6-1.6.9-2.4,1.2-4-1.9-8.5-6.5-15-1-1.5-1.7-2.3-2.5-3.1-2.2-2.4-3.9-3.2-7.4-7.9-1.4-1.9-3.8-5.1-5.9-9.7-2.9-6.2-2.2-8.1-4.9-11.1-.4-.4-5.5-5.8-9.9-4.6-4.3,1.1-3.5,7.3-10.4,16.6-1.4,1.8-2.6,3.8-4,5.5-2.9,3.6-4.3,4.8-4,6.5.4,1.7,2.3,2.9,3.9,3.3,2.1.5,3.9-.3,4.7-.7,1.9-.9,2.6-2.1,3.6-3,3.3-3.2,11.1-3.8,14.1-1,1.3,1.2,1.7,3.9,2.5,9.3.5,3.2.2,2.9.5,4.1,1.3,4.7,5.3,4.4,7.9,9.7,1.6,3.4,1.7,5.6,4.5,8.3.8,1.1,3.1,3.3,5.3,2.8h0Z"/>
  <path id="thoracicspine28" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M172.05,195.84c-.2.7.2,1.5.9,3,.3.6.6,1.2,2.4,3.5.9,1.1,1.4,1.7,2.2,2.7,2.1,2.4,4.1,4.4,7.7,8,4.9,4.9,5.6,5.9,7.4,6,3.2.2,6.5-2.3,7.9-5.1,1.6-3.2.5-6.2-1.5-11.6-.8-2.2-2.7-6.7-6.9-12.5-3-4-3.4-3.6-6.4-7.4-6.4-8.1-4.6-10.1-10.4-17.1-4.3-5.2-6.3-5.2-8.9-10.2-2.3-4.3-2.8-6.2-4-7.4-.6-.6-1.3-.9-2.1-.9-2.1-.1-3.2,3.9-5.4,11.6-1.1,3.9-1.2,5.1-.6,6.3.9,1.9,2.6,1.8,4.1,4.4,1.1,1.9,1.1,3.5,2.2,3.7.7.1,1-.4,2.3-.7,1.4-.3,2.4.1,3.2.3,3.2.9,4.3.1,8.2.8,2.7.5,4.6.9,6.5,2.3,2.8,2.2,4.2,5.9,3.7,9.3v.3c-.6,3.4-3.1,5.7-3.7,6.1-3.8,3.4-8.2,2.3-8.8,4.6h0Z"/>
  <path id="thoracicspine27" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M168.95,112.74c2.3,3.9,7.4,10.2,10.9,15.2"/>
  <path id="thoracicspine26" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M184.35,97.94c43.1,45.2-3.4,34.2-9.9,10.6"/>
  <path id="thoracicspine25" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M193.25,90.04c4.1-.5,6.2-5.5,6.4-6,1.9-4.7-.5-9.2-1-10.2-2.2-4.1-4.5-3.3-9.9-9.2-3.5-3.8-4.2-6-6.4-6-1.7,0-2.1,1.3-6.4,4.2-4.2,2.9-5.7,2.9-5.9,4.2-.3,2.1,3.4,2.9,7.4,7.4,3.4,3.7,2.3,4.9,5.8,9.1,2.5,2.9,6,7,10,6.5h0Z"/>
  <path id="thoracicspine24" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M186.25,40.14c5.4,2.4,8.3-.5,5.6-12-.7-3.1-4.9-.9-8.4-3.7-3-2.4-1.9-4.3-5.4-7.9-1.2-1.3-4.5-4.7-7.9-4.2-1.1.2-2.4,1.1-4.9,3.1-1.4,1-2.4,1.7-3.5,2.9-1,1.1-1.1,1.4-1.9,2-.8.6-1.6.8-2.4,1.7-.3.4-.6.6-.7.9-.8,2.2,5.1,5.6,8.9,8.8,2.4,2,3.9.4,7.4,1.8,3.6,1.5,5.7,1,6.9,4.2.9,2.7.7-.1,6.3,2.4h0Z"/>
  <path id="thoracicspine23" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M193.25,174.14c2.3.6,5.4.2,6.4-1.4,1-1.5-.5-2.8-1-6.9-.5-4.3.8-4.8,0-7.4-1-3.1-3.1-2.8-6.4-6.9-3.6-4.5-4-8.5-5.7-8.4-.9,0-.8,1.2-2.7,2.9-3.2,2.8-7.4,2.9-7.8,3-2.3.1-3.3-.6-3.6-.2-.6.9,4.1,3.6,8.4,9.2,1.5,1.9.9,1.6,4.9,7.9,4.2,6.3,5.5,7.6,7.5,8.2h0Z"/>
  <ellipse id="thoracicspine22" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" cx="168.55" cy="55.54" rx="6.3" ry="5.9"/>
  <path id="thoracicspine21" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M179.65,98.84c0-3.7-3.2-6.6-7.1-6.6s-9.6,2-9.6,5.7,5.6,7.6,9.6,7.6,7.1-3,7.1-6.7h0Z"/>
  <ellipse id="thoracicspine20" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" cx="173.15" cy="137.64" rx="7.7" ry="5.5"/>
  <path id="thoracicspine19" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M174.95,182.94c0-3.1-2.7-5.5-5.9-5.5s-4.3,2.9-5.9,5.5c-3.4,5.5,2.7,5.5,5.9,5.5s5.9-2.5,5.9-5.5Z"/>
  <ellipse id="thoracicspine18" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" cx="166.05" cy="226.14" rx="6.4" ry="7.1"/>
  <path id="thoracicspine17" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M121.35,265.14c-1.9-3.7-2.4-7.4-5.3-7.4s-5.3,3.3-5.3,7.4,2.6,5.9,5.3,7.4c5.9,3.3,10.7,2.6,5.3-7.4Z"/>
  <ellipse id="thoracicspine16" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" cx="128.75" cy="221.34" rx="7.5" ry="4.7"/>
  <path id="thoracicspine15" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M109.35,313.54c.4-3-4.8-5.5-10.7-5.5s-6.5,2.5-6.5,5.5,1.8,2,6.5,5.5c8.1,6,9.4,5.8,10.7-5.5Z"/>
  <path id="thoracicspine14" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M109.15,315.54c39.5,1.7,6.8,24.3.4,15.6"/>
  <path id="thoracicspine4" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M28.45,361.54c-.7-1.8,1.1-3.1,3.2-7.8,2.1-4.7,2.8-8.7,3.2-10.9.8-4.5.2-5.6,1.4-10.5.8-3.6,1.3-5.4,2.7-6.4,3-2,7.9.4,10,1.4,3.5,1.7,5,3.5,6.8,5,2.1,1.7,4.2,2.7,20.5,5.5,13.4,2.3,16.5,2.4,18.7,5.5,2.2,3,1.7,6.5,4.6,7.8.5.2,2,.7,6.4-.9,5.4-2,5.8-4.6,9.6-5.5,2.9-.7,5.2.2,8.7,1.4,3,1.1,7.3,2.6,9.6,6.8.6,1.1,1.7,3.1,1.4,5.5-.4,3-2.7,4-2.7,6.4s2.4,4.2,4.1,5.5c4.9,3.6,7.8,2.3,14.1,5.5,3.3,1.7,5.6,2.8,7.3,5.5,3,4.7,1.6,10.5.5,15-.9,3.7-1.4,5.7-3.2,6.8-3.2,2.1-8.6.4-11.9-2.3-4.6-3.7-3.3-7.7-7.8-10-3.3-1.8-8-1.6-9.6.5-1,1.3.2,2.7-.9,5-.6,1.3-2.2,3.3-4,3.5-2.9.2-3.4-4.5-8.8-8.9-4.4-3.6-7.7-3.4-8.1-6-.4-2.4,2.3-3.2,2.7-6.8.3-2.8-1-5.8-3.2-7.8-2.6-2.3-6.8-3.5-9.6-1.8-1.7,1-2.7,3.7-4.6,9.1-1.4,3.9-1.4,5.1-2.7,5.9-1.6,1-3.6.8-7.8-.5-7.6-2.4-12.3-5-12.3-5-10-5.7-8.2-6.9-13.7-9.1-3.5-1.4-8-2.3-16.9-4.1-4.2-.9-6.9-1.4-7.7-3.3h0Z"/>
  <path id="thoracicspine3" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M12.05,410.84c-.6-1.8.9-2.5,3.2-9.1,1.4-3.8,2-6.8,2.7-10,2.3-10.5.9-12.6,3.6-16.4.5-.7,3.8-5.1,8.2-5,3.2.1,4.6,2.6,8.7,5.5,1.2.9,2.3,1.5,13.2,5.5,12.4,4.5,18.6,6.8,20.5,6.8,5.7.2,10.7-1.1,12.8,1.8,1.2,1.7.3,3.4,1.8,4.6,2.1,1.6,6.4.5,9.1-1.8,3-2.5,2.2-4.8,4.1-5.9,5.5-3.1,21.2,10.2,19.1,16-.7,2-3.4,2.6-3.2,4.1.2,1.8,4.1,2.7,6.4,3.2,6.5,1.4,8.2,0,12.8.9,5.6,1.1,9.3,4.5,11.9,6.8,3.6,3.3,7.6,7,7.3,11.4-.2,3.8-3.3,4-4.6,9.1-1.3,5.3,1.5,7.7-.5,10.5-2.4,3.5-8.9,2.8-9.6,2.7-2.1-.3-4.2-1-12.8-6.8-6.4-4.4-9.2-6.4-10.5-5.5-1.5,1.1.8,4.9-1.4,7.3-1.3,1.5-4.1,2-5.5.9-1.9-1.4.3-4.6-1.4-8.7-1.5-3.8-5.6-6.4-9.1-7.3-4.4-1.2-7.7,1-8.7-.5-.7-1,.8-2.3,1.8-5.5.4-1.2,1.7-5.9,0-10.5-.5-1.2-2.3-6.2-6.4-6.8-3.3-.5-6.6,2-8.2,4.6-3.1,4.8.2,9.1-2.7,12.8-2.2,2.7-5.9,3-9.1,3.2-5.2.4-9.3-1.3-17.3-4.6-15.5-6.4-15-6.2-15.5-6.4-12.4-4.3-19.5-3.1-20.7-6.9h0Z"/>
  <path id="thoracicspine2" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M1.55,461.34c-.6-2.9,2.6-4.3,4.6-10,1.4-4.1.4-5.1.9-12.3.6-8,1.1-15,5-16.9,2.1-1,3.7.1,10.5,2.3,7.1,2.3,4.9,1,15.5,4.1,16.7,4.9,15.1,6.1,20.5,6.4,5,.2,10-.6,11.8,2.3,1.1,1.7,0,3.1,1.4,4.6,2.2,2.3,7.1.9,10,0,2.2-.6,3.5-1,4.6-2.3,1.7-2,.8-3.8,1.8-5.5,2.1-3.3,10.2-4.2,14.9-.8,5.4,4,6.5,14,6.5,14,0,0-4.4,4.6-3.7,5.9.8,1.4,6.2-2.5,11.8-.9,3.8,1,3.5,3.3,9.1,8.2,6.9,6,10.1,5,14.1,10.5,1.5,2,3.6,5.1,3.2,9.1-.1.5-.6,5-4.1,7.3-5.4,3.5-13.5-.9-15.9-2.3-4.7-2.6-4.4-4.2-10-7.3-4-2.2-8.1-4.4-11.4-2.7-3.4,1.7-4.1,6.5-4.1,6.8-.6,4.5,2.4,6.1,1.4,9.6-.8,2.7-3.7,5.3-6.3,5.2-2.7-.2-3.1-3.2-8.7-10.6-3.9-5.2-6.1-6.6-5.5-8.7.7-2.6,4.6-1.8,6.4-5,1.7-3.1.1-7.5-1.8-10-.7-.9-4.1-5.2-9.1-4.6-4.8.6-7.2,5.2-7.3,5.5-1.4,3-.1,4.5-1.4,6.4-1.8,2.8-6.6,2.6-13.7,2.3-12.5-.6-20.8-1.4-21-1.4-10.3-.9-13.8-1-19.6-2.7-4.7-1.6-9.6-3.1-10.4-6.5h0Z"/>
  <path id="thoracicspine1" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls7" d="M4.35,505.14c.7-7.5,1.8-10,0-13.7-1.6-3.3-3.8-4.4-3.7-7.3.2-2.6,2.2-5.1,4.6-5.9,2.8-1,5.5.4,6.8.9,2.9,1.2,8.8,1.1,20.5.9,21.2-.4,29.2-4.5,31.9-.5,2.5,3.8-3.1,9.7,0,13.2,2.2,2.5,7.8,2.5,10.9,0,3.9-3.1,2.2-8.8,4.6-9.6,3.8-1.3,8.1,13.4,18.2,15,5.3.8,7-2.8,13.2-1.4,4.7,1.1,4.5,3.4,11.4,6.8,7.6,3.8,10.4,2.3,13.2,5.9,2.9,3.8,2.4,8.9,2.3,10.5-.2,2.3-.6,6.1-3.2,8.2-2.9,2.4-7.1,1.1-16.4-1.4-19-5-21.3-4.8-22.3-3.7-2.9,3.4,4.8,14.9,4.6,15-.2.1-3.1-9.5-10-11.8-5.5-1.8-11.2,1.7-11.8.5-.6-1.3,6.4-4.1,6.4-8.7,0-3.2-3.4-5.8-5.9-6.8-5.4-2.2-12.4.4-14.1,4.6-1.3,3.1,1.2,5,0,6.8-2.3,3.5-11.6-2.2-26-1.8-1.5,0-7.2.8-18.7,2.3-10.4,1.4-13,1.8-15,0-2.9-2.3-2.4-7.5-1.5-18h0Z"/>
</g>
<g id="Highlights">
  <g id="Unteren_BWS" data-name="Unteren BWS" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls2">
    <path id="thoracicspine13-2" data-name="thoracicspine13" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M167.56,113.47c.9-.1,2.3,1.6,5,5,1.8,2.2,1.8,2.5,3.2,4.1,2.4,2.7,3.9,3.5,3.6,4.1-.4.8-3.6-1-7.3,0-4.3,1.2-5.6,5.1-7.3,4.6-1.5-.5-1.4-4.1-1.4-5.9.1-4.8,1.7-11.7,4.2-11.9h0Z"/>
    <path id="thoracicspine12-2" data-name="thoracicspine12" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M88.86,47.87c-.5-.8.2-3.2,1.5-7.8,1.3-4.7,1.9-5.3,1.5-6.9-.7-2.9-3.1-2.9-4-6-.7-2.2-.3-5.4,1.5-6.5,1.8-1,3.4.8,7.4.9,3.8.1,4.2-1.4,9.4-2.3,5.6-1,5.6.6,10.9-.5,4.8-1,5.5-2.4,9.4-2.3,2.3.1,3.4.6,5.4,0,1.6-.5,4.2-1.8,4.5-3.7.3-2.4-3.4-3.6-3-5.5.4-1.8,4.9-4.9,6.4-2.8,8.9,12.1,7.8,12.4,6.9,7.9-.1-.5.1-.5,1-3.7.8-3.1,3.5-7.9,7.9-8.3,4.6-.5,8,3.9,8.9,5.1,1,1.3,3.4,4.4,2.5,7.4-.4,1.2-1.1,1.6-5.9,5.5-4.3,3.5-4.7,4-4.9,4.6-1.4,3.1.5,6.6,2,9.2,4.4,7.9,10.9,10.4,9.9,12.9-.4,1-1.5.6-5.4,2.8-3.8,2.1-5.2,3.7-5.9,3.2-1.3-.7,2-5.1,0-7.8-1.6-2.1-4.5-.8-6.9-3.2-1.8-1.8-.8-3.1-2.5-6.5-.4-.8-3.2-6.5-6.9-6.5-3,0-5.7,3.7-6.4,6.9-1.1,4.9,2.7,7.7,1,10.2-1.2,1.8-4.2,1.6-10.9,1.8-.3,0-5.5.5-15.8,1.4-13.8,1.3-18.2,2.6-19.5.5h0Z"/>
    <path id="thoracicspine11-2" data-name="thoracicspine11" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M88.36,54.77c-2.3,2.6.8,5.4.5,13.4-.3,8.3-3.8,12.2-1.5,14.3,1.4,1.4,3.5.5,9.4,0,10-.8,9.8,1.3,18.3.5s9.8-3,15.4-1.8c3.3.7,5.3,2,6.9.9,2.1-1.3.4-4.3,2.5-8.3,1.1-2.1,3.7-5.6,6.9-5.5,3.8.1,6.5,5.4,7.4,7.8.6,1.6.3,1.6,1.3,4.6.3,1,1.2,2.9,3.1,6.7.6,1.3,1.4,2.9,3.2,3.8l.4.2c2.2.7,4-3.3,6.9-4.2,4.1-1.1,8.1,4.4,9.2,3.6,1.1-.8-1.4-6.4-5.3-11-.3-.4-2.4-2.6-6.4-6.9-3-3.2-4.4-4.8-4-5.5.6-.9,3.9,1.1,6.4-.5.6-.4.5-.6,1.5-1.5,1.8-1.6,3.4-2.2,4.4-2.7,3-1.5,4.7-5.3,4.9-8.3.1-.6.4-5.7-3.5-8.3-3.2-2.2-7.4-1.3-8.9-.9-6.7,1.5-10.1,6.8-11.4,6-1.2-.8,2-5,0-7.8-1.3-1.8-3.3-.9-6.9-3.2-3.4-2.2-3.9-4.4-5.4-4.2-1.6.2-2.7,3.1-3,5.1-.8,5.4,3.4,8.2,2,10.2-1.2,1.6-4.5.3-11.9.5-3.4.1-3.4.4-9.4.9-7.6.7-14,.8-18.3.9-11.1.1-13.1-.5-14.7,1.2h0Z"/>
    <path id="thoracicspine10-2" data-name="thoracicspine10" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M89.26,88.97c-2.2,1.3-1.7,5.5-1.5,6.9.4,3.8,2,4,2.5,7.4.4,2.9-.3,5.6-1.5,9.7-1,3.4-1.7,4.6-1,6,.3.7,1.3,1.9,5.4,2.8,5.1,1,8.5.1,13.9-.5,2.1-.2,6.8-.7,11.9-.5,10.1.5,13.3,3.6,16.4,1.4.6-.4,1.9-3.4,4.5-9.2,1.8-4.1,2.3-5.3,3.5-5.5,2.6-.5,5.6,4.5,5.9,5.1.6,1.1,2.4,4,2,7.8-.3,3.4-2,3.9-1.5,6,.9,3.7,6.7,5.2,7.3,5.4,1.6.4,5,1.2,6.6-.3,2-1.9-1.2-5.1,0-10.6.9-4.1,3.7-7,4.9-8.3.7-.8,2.6-2.6,5.4-4.2,4.3-2.4,5.9-1.6,7.9-3.7,1.7-1.8,2.6-4.5,2-6.9-.5-2-1.7-3.1-4-5.1-3.7-3.2-6.9-6.1-10.4-5.5-3.8.6-4.5,4.6-6.9,4.2-2-.3-3.3-3.2-5.4-7.8-1.6-3.5-1.6-4.6-2.6-4.8-2.2-.4-6,4.7-7.9,9.4-.9,2.4-.8,3.4-2,4.2-2.4,1.5-5.8-1-10.4-2.3-3.8-1.1-4-.1-18.3.9-10.3.7-15.6,1.1-18.8,0-3.5-1.3-6-3.1-7.9-2h0Z"/>
    <path id="thoracicspine9-2" data-name="thoracicspine9" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M85.86,160.07c-1.1-1.7-.5-3.9.5-7.9,1.4-5.4,3.2-6,3-9.2-.2-2.7-1.5-2.8-2.5-6.9-.7-2.8-1.5-6.2,0-7.9,1.8-2.1,5.7-.4,13.9.6,9.5,1.2,16.5,2,24.3,0,4.7-1.2,8.5-3.2,10.9-1.4,1.8,1.3,1.3,3.5,4,5.5,1.8,1.4,4.6,2.2,5.4,1.4,1.1-1-1.8-3.5-1.3-7,.5-4.2,5.6-7.7,7.3-6.9,1.8.8-1.4,5.8,1.4,9.1,2.2,2.6,7.4,3.1,11,2s3.6-3.1,7.4-4.2c.9-.2,4.8-1.4,8.4.5,4.3,2.2,5.1,7.2,5.4,9.2.4,2.3,1,6-1.5,8.8-3.4,3.8-10.1,2.9-11.4,2.8-6.6-.8-8.5-4.8-11.9-3.7-3.9,1.2-2.9,6.8-6.9,8.3-4.7,1.8-9.6-4.1-12.4-2.3-3,1.9,2.1,9.6-1.5,13.4-1.5,1.6-3.9,1.8-16.8.5-18.6-1.9-16.1-2.6-22.8-2.8-7.8-.2-12.2.7-13.9-1.9h0Z"/>
    <path id="thoracicspine8-2" data-name="thoracicspine8" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M76.96,197.07l2.5-15.2,2.4-16.2,13.9,3.3,16.8.9,15.3.9,8,1.4,3.5,6.9,5,2.3,3.4-4.1v-6c0-3.4,2.1-6.5,5.4-7.8,1.5,1.3,3.3,3.2,4.9,5.5.9,1.3,1.6,2.6,2.2,3.7.8-.2,1.5-.4,2.3-.7l11.4,1.1,6.5,2.3c.8.8,1.9,2.1,2.7,3.9,1,2.3,1.1,4.3,1,5.5-.1.9-.5,2.4-1.5,4-.7,1.1-1.6,1.9-2.2,2.4-2.6,1.1-5.3,2.2-8,3.2l-9.9-.9,10.4,12,7.9,17.6c1.1,4,2.1,7.9,3.2,12l1.1,11.1,3.6,7.9c.2.3,1.4,2.5.5,5.1-1,2.6-3.4,3.6-3.8,3.8-.6.4-2.1,1.3-4.1,1.3-2.3,0-4.2-1-4.9-2.3-1.5-2.6-3-5.2-4.5-7.9l-6.4-8.8-5.3-8.3h7.8c1.1.1,2.8.1,4.7-.2,2-.4,3.5-1,4.5-1.5.4-.6,4-7.2.6-13.6-3.1-5.8-10.7-8.9-18.4-7.2l-3.4,1.7-7.4-3.5-2-7.2c.2-3.6.4-7.2.6-10.8-1.8-1.5-3.7-2.9-5.5-4.4l-6.6,2.8c-.1,3.5-.2,7-.3,10.5-.9,1.1-2.1,2.2-3.4,2.1-1-.1-1.3-.9-2.5-1.5-.9-.5-2.3-.9-4.6-.5-7.8-1.1-15.5-2.2-23.3-3.2l-24.1-3.4h0Z"/>
    <path id="thoracicspine7-2" data-name="thoracicspine7" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M67.46,235.37c-1.1-1.8,0-3.4,2.5-11.1,1.3-4,1.9-6,2.5-8.3,1.7-7.2.8-9.4,3-11.1,2.2-1.7,5.3-1.3,8.4-.9,6.4.8,7.2,3.5,13.4,4.6,5.1.9,5-.8,11.4,0,6.6.9,6.7,2.7,11.9,2.8,5.5,0,8-2.1,10.4-.5,2.1,1.4,1.4,3.9,4,5.1,1.5.7,3.5.7,4.9,0,3.6-1.8,3.2-7.3,4.5-7.4,1.8-.1,2.4,12,5.9,12.5,1.8.2,2.5-2.8,5.9-5.1,4.6-2.9,12.6-3.5,17.9.4,6.7,5.1,6.6,15.9,2.4,18.9-1.9,1.4-5.1,1.4-8.3,1.4-3.4,0-4.8-.6-7.8,0-2.3.4-2.9,1.7-4.6,1.4-1.5-.2-1.6-1.1-3.8-1.9-1.1-.4-2.6-.9-4.1-.4-1.7.5-2.6,2-3.3,3.2-1,1.8-.8,2.5-1.6,4.6s-2.5,5.3-3.5,5.1c-1.3-.2.7-6.2-2.5-9.2-2.9-2.8-9.5-2.7-11.9,0-2.6,3,1.3,7.5-1,9.7-1.3,1.3-4.1,1-15.8-2.8-13.1-4.2-12.1-4.6-16.8-5.5-3-.6-6.7-1-16.3-2.8-4.8-.7-6.7-1.1-7.7-2.7h0Z"/>
    <path id="thoracicspine6-2" data-name="thoracicspine6" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M51.66,276.07c-.1-1.9,2.5-2.2,5-6,1.6-2.5,1-3.2,3-9.7,1.5-5.1,1.8-4.2,3.5-9.7,1.8-5.8,1.9-7.9,3.5-8.3,1.5-.4,2.6,1.4,5.9,3.2,2.3,1.2,3.9,1.6,9.4,2.8,8.6,1.8,12.8,2.7,15.8,3.2,4.6.9,9.2,1.9,13.9,2.8,4.8.8,7.3,1.2,9.4,3.2,2.2,2.1,1.3,3.5,3.5,6,2.3,2.6,6.5,4.6,10.4,4.2,4.2-.5,4.4-3.3,9.9-5.1.9-.3,7.9-2.4,10.9.5.5.5,1,3,2,7.9.3,1.7.6,3.3,1,5.5.5,3.2.7,4.8.5,5.5-1,2.9-5.1,4.8-8.4,5.1-2.1.2-3.5-.2-10.9-3.2-9.4-3.8-10.3-4.2-12.4-4.2-3.9.2-7.9,2.2-10.4,5.1-3.5,4.1-2,7.7-5.4,9.7-2.4,1.4-5.3.9-7.4.5-5.3-1-6.4-3.5-11.4-6-2.8-1.4-2.8-.8-17.8-3.7-6.8-1.3-9.1-1.9-11.9-2.8-5.4-1.7-11.4-3.7-11.6-6.5h0Z"/>
    <path id="thoracicspine5-2" data-name="thoracicspine5" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M39.86,316.77c-.4-1-.2-1.9,4-9.2,4.2-7.3,4.8-7.8,5-9.7.5-4.8-2.6-7.1-1-9.2,1.8-2.4,7.1-1.6,8.4-1.4,4.5.7,6.3,2.6,10.9,5.1,7.9,4.3,15.7,5.8,21.3,6.9,4.8.9,8.9,1.4,8.9,1.4,7,.8,7.2,0,9.4.9,5.4,2.1,4.5,6.5,9.9,8.3.4.1,5.2,1.7,8.9-.5,2.5-1.5,2.6-3.4,4.5-3.7,3.1-.4,6.2,4.6,6.4,5.1.6,1,3.4,6,1,10.2-2,3.4-6,3.5-5.9,5.5,0,1.3,2,2.4,5.9,4.6,4,2.2,7.3,3.5,7.9,3.7,5.2,2,5.7,1.4,7.9,2.8,2.3,1.4,3.5,3.1,5.9,6.5,2.4,3.2,3.5,4.9,3.5,6.5-.1,3.6-4.1,6.3-6.9,7.4-3.6,1.4-7.8,1-10.9-.5-4.2-2-3.5-4.6-7.9-6.9-3.9-2-5.1-.3-8.9-2.3-2.6-1.3-3.4-1.8-4.3-2.2-1.5-.7-1.4-.5-3.3-1.3-2.9-1.2-3.2-1.7-5.8-2.9-3.1-1.3-3.2-.8-3.9-1.5-2.4-2.1.5-5.7-1.5-8.8-2.2-3.4-8.3-5-11.9-3.2-2.9,1.4-2.2,4.1-4.9,5.5-2.5,1.3-4.9.1-12.4-2.3-11.3-3.7-10.7-2.7-16.3-5.1-7.2-3-6.4-3.7-11.9-5.5-7.3-2.5-11-1.9-12-4.2h0Z"/>
    <path id="thoracicspine30-2" data-name="thoracicspine30" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M167.56,324.07c1.8-1.9.4-3.3,2-6.9,1.9-4.2,5.1-5.2,4.5-6.9,0,0-.3-.7-5.9-2.8,0,0-4.2-1-6.9-2.8-.9-.6-2-1.5-5.9-8.3-3.3-5.7-2.9-5.7-4-6.9-2-2.3-3.3-1.8-11.4-5.5-4.8-2.2-6.4-3.3-10-3.9-4.8-.8-6.4.3-6.8.7-1.2,1-2,2.9-1.5,4.6.7,2.5,3.4,2.5,7.4,6.5,1.9,1.9,3,3,3,4.6,0,2.5-2.4,2.9-2.5,5.1,0,2.8,4,2.9,6.8,7.9,1.3,2.4,2.1,4,2,6-.1,1.8-.1,3.5.4,3.6s1-1.4,3-2.5c.7-.4,1.4-.6,2.7-1,2.4-.7,3.7-1,4.5-.7,1.7.6,1.2,2.8,3,5.5.6.9,3,4.6,7.4,5.5,2.3.6,6.2.4,8.2-1.8h0Z"/>
    <path id="thoracicspine29-2" data-name="thoracicspine29" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M176.56,297.27c1.7-.4,5.8-3.8,7.6-7.9.3-.7.4-.9.5-.9,0,0-.4,1.2-.5,1.2,0,0,.6-1.6.9-2.4,1.2-4-1.9-8.5-6.5-15-1-1.5-1.7-2.3-2.5-3.1-2.2-2.4-3.9-3.2-7.4-7.9-1.4-1.9-3.8-5.1-5.9-9.7-2.9-6.2-2.2-8.1-4.9-11.1-.4-.4-5.5-5.8-9.9-4.6-4.3,1.1-3.5,7.3-10.4,16.6-1.4,1.8-2.6,3.8-4,5.5-2.9,3.6-4.3,4.8-4,6.5.4,1.7,2.3,2.9,3.9,3.3,2.1.5,3.9-.3,4.7-.7,1.9-.9,2.6-2.1,3.6-3,3.3-3.2,11.1-3.8,14.1-1,1.3,1.2,1.7,3.9,2.5,9.3.5,3.2.2,2.9.5,4.1,1.3,4.7,5.3,4.4,7.9,9.7,1.6,3.4,1.7,5.6,4.5,8.3.8,1.1,3.1,3.3,5.3,2.8h0Z"/>
    <path id="thoracicspine28-2" data-name="thoracicspine28" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M171.66,195.67c-.2.7.2,1.5.9,3,.3.6.6,1.2,2.4,3.5.9,1.1,1.4,1.7,2.2,2.7,2.1,2.4,4.1,4.4,7.7,8,4.9,4.9,5.6,5.9,7.4,6,3.2.2,6.5-2.3,7.9-5.1,1.6-3.2.5-6.2-1.5-11.6-.8-2.2-2.7-6.7-6.9-12.5-3-4-3.4-3.6-6.4-7.4-6.4-8.1-4.6-10.1-10.4-17.1-4.3-5.2-6.3-5.2-8.9-10.2-2.3-4.3-2.8-6.2-4-7.4-.6-.6-1.3-.9-2.1-.9-2.1-.1-3.2,3.9-5.4,11.6-1.1,3.9-1.2,5.1-.6,6.3.9,1.9,2.6,1.8,4.1,4.4,1.1,1.9,1.1,3.5,2.2,3.7.7.1,1-.4,2.3-.7,1.4-.3,2.4.1,3.2.3,3.2.9,4.3.1,8.2.8,2.7.5,4.6.9,6.5,2.3,2.8,2.2,4.2,5.9,3.7,9.3v.3c-.6,3.4-3.1,5.7-3.7,6.1-3.8,3.4-8.2,2.3-8.8,4.6h0Z"/>
    <path id="thoracicspine27-2" data-name="thoracicspine27" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M168.56,112.57c2.3,3.9,7.4,10.2,10.9,15.2"/>
    <path id="thoracicspine26-2" data-name="thoracicspine26" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M183.96,97.77c43.1,45.2-3.4,34.2-9.9,10.6"/>
    <path id="thoracicspine25-2" data-name="thoracicspine25" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M192.86,89.87c4.1-.5,6.2-5.5,6.4-6,1.9-4.7-.5-9.2-1-10.2-2.2-4.1-4.5-3.3-9.9-9.2-3.5-3.8-4.2-6-6.4-6-1.7,0-2.1,1.3-6.4,4.2-4.2,2.9-5.7,2.9-5.9,4.2-.3,2.1,3.4,2.9,7.4,7.4,3.4,3.7,2.3,4.9,5.8,9.1,2.5,2.9,6,7,10,6.5h0Z"/>
    <path id="thoracicspine24-2" data-name="thoracicspine24" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M185.86,39.97c5.4,2.4,8.3-.5,5.6-12-.7-3.1-4.9-.9-8.4-3.7-3-2.4-1.9-4.3-5.4-7.9-1.2-1.3-4.5-4.7-7.9-4.2-1.1.2-2.4,1.1-4.9,3.1-1.4,1-2.4,1.7-3.5,2.9-1,1.1-1.1,1.4-1.9,2-.8.6-1.6.8-2.4,1.7-.3.4-.6.6-.7.9-.8,2.2,5.1,5.6,8.9,8.8,2.4,2,3.9.4,7.4,1.8,3.6,1.5,5.7,1,6.9,4.2.9,2.7.7-.1,6.3,2.4h0Z"/>
    <path id="thoracicspine23-2" data-name="thoracicspine23" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M192.86,173.97c2.3.6,5.4.2,6.4-1.4,1-1.5-.5-2.8-1-6.9-.5-4.3.8-4.8,0-7.4-1-3.1-3.1-2.8-6.4-6.9-3.6-4.5-4-8.5-5.7-8.4-.9,0-.8,1.2-2.7,2.9-3.2,2.8-7.4,2.9-7.8,3-2.3.1-3.3-.6-3.6-.2-.6.9,4.1,3.6,8.4,9.2,1.5,1.9.9,1.6,4.9,7.9,4.2,6.3,5.5,7.6,7.5,8.2h0Z"/>
    <ellipse id="thoracicspine22-2" data-name="thoracicspine22" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} cx="168.16" cy="55.37" rx="6.3" ry="5.9"/>
    <path id="thoracicspine21-2" data-name="thoracicspine21" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M179.26,98.67c0-3.7-3.2-6.6-7.1-6.6s-9.6,2-9.6,5.7,5.6,7.6,9.6,7.6,7.1-3,7.1-6.7h0Z"/>
    <ellipse id="thoracicspine20-2" data-name="thoracicspine20" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} cx="172.76" cy="137.47" rx="7.7" ry="5.5"/>
    <path id="thoracicspine19-2" data-name="thoracicspine19" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M174.56,182.77c0-3.1-2.7-5.5-5.9-5.5s-4.3,2.9-5.9,5.5c-3.4,5.5,2.7,5.5,5.9,5.5s5.9-2.5,5.9-5.5Z"/>
    <ellipse id="thoracicspine18-2" data-name="thoracicspine18" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} cx="165.66" cy="225.97" rx="6.4" ry="7.1"/>
    <path id="thoracicspine17-2" data-name="thoracicspine17" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M120.96,264.97c-1.9-3.7-2.4-7.4-5.3-7.4s-5.3,3.3-5.3,7.4,2.6,5.9,5.3,7.4c5.9,3.3,10.7,2.6,5.3-7.4Z"/>
    <ellipse id="thoracicspine16-2" data-name="thoracicspine16" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} cx="128.36" cy="221.17" rx="7.5" ry="4.7"/>
    <path id="thoracicspine15-2" data-name="thoracicspine15" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M108.96,313.37c.4-3-4.8-5.5-10.7-5.5s-6.5,2.5-6.5,5.5,1.8,2,6.5,5.5c8.1,6,9.4,5.8,10.7-5.5Z"/>
    <path id="thoracicspine14-2" data-name="thoracicspine14" class="BWS1533Fill" style= {{
            fill: props.colors.BWS1533Fill,
            stroke: props.colors.BWS1533Fill,
            opacity:props.colors.BWS1533Fill,
          }} d="M108.76,315.37c39.5,1.7,6.8,24.3.4,15.6"/>
  </g>
  <g id="Oberen_LWS" data-name="Oberen LWS" class="Thoracolumbaler_Ubergang_lat_liegend1533_cls2">
    <path id="thoracicspine4-2" data-name="thoracicspine4" class="LWS1533Fill" style= {{
            fill: props.colors.LWS1533Fill,
            stroke: props.colors.LWS1533Fill,
            opacity:props.colors.LWS1533Fill,
          }} d="M28.06,361.37c-.7-1.8,1.1-3.1,3.2-7.8,2.1-4.7,2.8-8.7,3.2-10.9.8-4.5.2-5.6,1.4-10.5.8-3.6,1.3-5.4,2.7-6.4,3-2,7.9.4,10,1.4,3.5,1.7,5,3.5,6.8,5,2.1,1.7,4.2,2.7,20.5,5.5,13.4,2.3,16.5,2.4,18.7,5.5,2.2,3,1.7,6.5,4.6,7.8.5.2,2,.7,6.4-.9,5.4-2,5.8-4.6,9.6-5.5,2.9-.7,5.2.2,8.7,1.4,3,1.1,7.3,2.6,9.6,6.8.6,1.1,1.7,3.1,1.4,5.5-.4,3-2.7,4-2.7,6.4s2.4,4.2,4.1,5.5c4.9,3.6,7.8,2.3,14.1,5.5,3.3,1.7,5.6,2.8,7.3,5.5,3,4.7,1.6,10.5.5,15-.9,3.7-1.4,5.7-3.2,6.8-3.2,2.1-8.6.4-11.9-2.3-4.6-3.7-3.3-7.7-7.8-10-3.3-1.8-8-1.6-9.6.5-1,1.3.2,2.7-.9,5-.6,1.3-2.2,3.3-4,3.5-2.9.2-3.4-4.5-8.8-8.9-4.4-3.6-7.7-3.4-8.1-6-.4-2.4,2.3-3.2,2.7-6.8.3-2.8-1-5.8-3.2-7.8-2.6-2.3-6.8-3.5-9.6-1.8-1.7,1-2.7,3.7-4.6,9.1-1.4,3.9-1.4,5.1-2.7,5.9-1.6,1-3.6.8-7.8-.5-7.6-2.4-12.3-5-12.3-5-10-5.7-8.2-6.9-13.7-9.1-3.5-1.4-8-2.3-16.9-4.1-4.2-.9-6.9-1.4-7.7-3.3h0Z"/>
    <path id="thoracicspine3-2" data-name="thoracicspine3" class="LWS1533Fill" style= {{
            fill: props.colors.LWS1533Fill,
            stroke: props.colors.LWS1533Fill,
            opacity:props.colors.LWS1533Fill,
          }} d="M11.66,410.67c-.6-1.8.9-2.5,3.2-9.1,1.4-3.8,2-6.8,2.7-10,2.3-10.5.9-12.6,3.6-16.4.5-.7,3.8-5.1,8.2-5,3.2.1,4.6,2.6,8.7,5.5,1.2.9,2.3,1.5,13.2,5.5,12.4,4.5,18.6,6.8,20.5,6.8,5.7.2,10.7-1.1,12.8,1.8,1.2,1.7.3,3.4,1.8,4.6,2.1,1.6,6.4.5,9.1-1.8,3-2.5,2.2-4.8,4.1-5.9,5.5-3.1,21.2,10.2,19.1,16-.7,2-3.4,2.6-3.2,4.1.2,1.8,4.1,2.7,6.4,3.2,6.5,1.4,8.2,0,12.8.9,5.6,1.1,9.3,4.5,11.9,6.8,3.6,3.3,7.6,7,7.3,11.4-.2,3.8-3.3,4-4.6,9.1-1.3,5.3,1.5,7.7-.5,10.5-2.4,3.5-8.9,2.8-9.6,2.7-2.1-.3-4.2-1-12.8-6.8-6.4-4.4-9.2-6.4-10.5-5.5-1.5,1.1.8,4.9-1.4,7.3-1.3,1.5-4.1,2-5.5.9-1.9-1.4.3-4.6-1.4-8.7-1.5-3.8-5.6-6.4-9.1-7.3-4.4-1.2-7.7,1-8.7-.5-.7-1,.8-2.3,1.8-5.5.4-1.2,1.7-5.9,0-10.5-.5-1.2-2.3-6.2-6.4-6.8-3.3-.5-6.6,2-8.2,4.6-3.1,4.8.2,9.1-2.7,12.8-2.2,2.7-5.9,3-9.1,3.2-5.2.4-9.3-1.3-17.3-4.6-15.5-6.4-15-6.2-15.5-6.4-12.4-4.3-19.5-3.1-20.7-6.9h0Z"/>
    <path id="thoracicspine2-2" data-name="thoracicspine2" class="LWS1533Fill" style= {{
            fill: props.colors.LWS1533Fill,
            stroke: props.colors.LWS1533Fill,
            opacity:props.colors.LWS1533Fill,
          }} d="M1.16,461.17c-.6-2.9,2.6-4.3,4.6-10,1.4-4.1.4-5.1.9-12.3.6-8,1.1-15,5-16.9,2.1-1,3.7.1,10.5,2.3,7.1,2.3,4.9,1,15.5,4.1,16.7,4.9,15.1,6.1,20.5,6.4,5,.2,10-.6,11.8,2.3,1.1,1.7,0,3.1,1.4,4.6,2.2,2.3,7.1.9,10,0,2.2-.6,3.5-1,4.6-2.3,1.7-2,.8-3.8,1.8-5.5,2.1-3.3,10.2-4.2,14.9-.8,5.4,4,6.5,14,6.5,14,0,0-4.4,4.6-3.7,5.9.8,1.4,6.2-2.5,11.8-.9,3.8,1,3.5,3.3,9.1,8.2,6.9,6,10.1,5,14.1,10.5,1.5,2,3.6,5.1,3.2,9.1-.1.5-.6,5-4.1,7.3-5.4,3.5-13.5-.9-15.9-2.3-4.7-2.6-4.4-4.2-10-7.3-4-2.2-8.1-4.4-11.4-2.7-3.4,1.7-4.1,6.5-4.1,6.8-.6,4.5,2.4,6.1,1.4,9.6-.8,2.7-3.7,5.3-6.3,5.2-2.7-.2-3.1-3.2-8.7-10.6-3.9-5.2-6.1-6.6-5.5-8.7.7-2.6,4.6-1.8,6.4-5,1.7-3.1.1-7.5-1.8-10-.7-.9-4.1-5.2-9.1-4.6-4.8.6-7.2,5.2-7.3,5.5-1.4,3-.1,4.5-1.4,6.4-1.8,2.8-6.6,2.6-13.7,2.3-12.5-.6-20.8-1.4-21-1.4-10.3-.9-13.8-1-19.6-2.7-4.7-1.6-9.6-3.1-10.4-6.5h0Z"/>
    <path id="thoracicspine1-2" data-name="thoracicspine1" class="LWS1533Fill" style= {{
            fill: props.colors.LWS1533Fill,
            stroke: props.colors.LWS1533Fill,
            opacity:props.colors.LWS1533Fill,
          }} d="M3.96,504.97c.7-7.5,1.8-10,0-13.7-1.6-3.3-3.8-4.4-3.7-7.3.2-2.6,2.2-5.1,4.6-5.9,2.8-1,5.5.4,6.8.9,2.9,1.2,8.8,1.1,20.5.9,21.2-.4,29.2-4.5,31.9-.5,2.5,3.8-3.1,9.7,0,13.2,2.2,2.5,7.8,2.5,10.9,0,3.9-3.1,2.2-8.8,4.6-9.6,3.8-1.3,8.1,13.4,18.2,15,5.3.8,7-2.8,13.2-1.4,4.7,1.1,4.5,3.4,11.4,6.8,7.6,3.8,10.4,2.3,13.2,5.9,2.9,3.8,2.4,8.9,2.3,10.5-.2,2.3-.6,6.1-3.2,8.2-2.9,2.4-7.1,1.1-16.4-1.4-19-5-21.3-4.8-22.3-3.7-2.9,3.4,4.8,14.9,4.6,15-.2.1-3.1-9.5-10-11.8-5.5-1.8-11.2,1.7-11.8.5-.6-1.3,6.4-4.1,6.4-8.7,0-3.2-3.4-5.8-5.9-6.8-5.4-2.2-12.4.4-14.1,4.6-1.3,3.1,1.2,5,0,6.8-2.3,3.5-11.6-2.2-26-1.8-1.5,0-7.2.8-18.7,2.3-10.4,1.4-13,1.8-15,0-2.9-2.3-2.4-7.5-1.5-18h0Z"/>
  </g>
  <g id="Body">
    <path id="corpusvertebraehighlight12" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M.54,483.74c.26-2.31,2.05-3.9,2.33-4.15,1.73-1.5,3.7-1.69,4.45-1.72,2.24-.08,2.99.97,5.97,1.72,1.51.38,2.74.47,5.25.51,13.84.19,19.52-.3,19.52-.3,13.36-1.16,24.5-3.38,26.29-.3.23.4.57,1.22.75,2.15.55,2.93-.85,5.29-1.39,6.54-1.68,3.92,1.43,5.55,2.22,13.53.84,8.53-2.45,9.36-.77,16.2.4,1.61,1.16,3.94,0,5.16-.7.74-1.85.8-4.42.53-4.21-.44-6.91-1.32-9.24-1.85-3.09-.71-5.38-.79-8.6-.91-1.13-.04-3.89-.1-13.04,1.11-7.33.97-9.15,1.49-14.16,1.82-5.57.37-8.39.53-9.7-.51-3.09-2.45-2.56-8.18-1.52-19.58.65-7.1,1.46-9.01,0-12.07-1.58-3.28-4.3-4.79-3.95-7.89h0Z"/>
    <path id="corpusvertebraehighlight11" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M8.47,427.38c.61-1.74,1.78-5.1,4.31-5.8.99-.27,1.52.04,5.46,1.42,0,0,2.57.9,6.34,2.09,2.98.94,4.27,1.12,7.75,1.96,5.58,1.34,9.81,2.7,13.35,3.84,8.6,2.77,7.09,2.96,10.25,3.57,7.37,1.44,12.54-.56,14.36,2.36.21.33.31.63.54,2.43.54,4.25.88,6.96.61,10.31-.47,5.77-2.09,5.29-3.78,13.68-.87,4.32-.68,5.66-2.08,7.08-2.22,2.26-5.72,1.86-9.71,1.74-9.25-.26-15.57-.89-20.23-1.26-6.07-.48-10.31-.59-17.93-1.57-1.41-.18-5.79-.78-10.76-3.21-3.29-1.61-4.94-2.41-5.45-4.08-1.05-3.42,3.98-5.58,5.22-12.6.16-.92-.06-.11.19-6.4.08-2.1.24-5.54.65-9.75.42-4.24.69-5.17.92-5.82h-.01Z"/>
    <path id="corpusvertebraehighlight0" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M22.34,374.13c.6-.7,3.56-4.18,7.09-3.83,1.89.18,3.75,1.44,5.26,2.47,1.54,1.05,2.54,2.02,2.83,2.29,2.69,2.54,6.19,3.32,13.35,5.93,5.55,2.03,8.38,3.36,14.83,5.53,2.04.69,3.98,1.3,6.61,1.48,2.3.16,3.06-.15,5.53,0,3.58.22,5.4.29,6.61,1.37,2.31,2.08,1.22,6.76.74,8.81-2,8.52-6.87,8.86-8.77,16.48-1.41,5.67.67,7.94-1.76,10.87-2.66,3.2-7.47,3.27-10.3,3.32-5.48.11-8.21-1.58-17.93-5.53-10.36-4.21-15.55-6.3-20.9-7.69-8.1-2.1-12.41-2.09-13.42-4.98-.83-2.38,1.54-3.94,3.85-10.66.73-2.12,1.23-4.83,2.25-10.25,1.95-10.42,1.67-12.72,4.14-15.62h0Z"/>
    <path id="corpusvertebraehighlight9" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M38.95,325.94c1.64-1.01,3.54-.67,5.49-.31,1.32.24,3.4.82,8.56,4.29,4.28,2.89,3.55,2.96,5.55,4.01,1.99,1.04,4.82,1.57,10.4,2.59,5.69,1.04,8.54,1.55,8.61,1.56,3.8.57,7.57,1.38,11.36,1.99,1.21.19,2.94.47,4.56,1.76.32.26,1.43,1.18,2.49,3.33,1.54,3.12,1.62,6.08,1.62,7.13,0,6.71-3.22,10.48-6.56,21.01-2.21,6.96-1.95,8.95-4.2,10.23-3.08,1.76-7.19.11-14.14-2.68-4.92-1.97-8.49-4.2-10.11-5.26-5.05-3.31-4.41-4.02-7.82-5.93-2.65-1.49-4.19-1.7-15.22-4.14-9.33-2.06-10.09-2.32-10.65-3.32-2.11-3.76,4.55-7.57,5.79-18.7.19-1.69.36-4.56,1.1-9.17.91-5.7,1.62-7.46,3.15-8.41l.02.02h0Z"/>
    <path id="corpusvertebraehighlight8" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M48.25,288.84c.7-1.02,1.93-1.28,3.36-1.59,2.93-.63,5.53.04,7.65.81,3.85,1.4,4.71,2.58,8.53,4.65,4.59,2.48,8.57,3.58,13.15,4.85,3.82,1.06,6.95,1.63,8.69,1.95,1.68.3,3.85.6,5.77.88,2.53.37,3.79.55,4.35.61,4.34.43,5.19-.1,7.4.73,1.81.68,2.86,1.65,3.21,2,.82.81,2.34,2.29,2.12,4.25-.12,1.08-.79,2.42-1.92,2.83-1.18.43-2.23-.36-3.83-1.14-3.92-1.89-8.3-2.28-11.44-1.19-1.49.52-2.1,1.17-2.43,1.62-.86,1.2-.95,2.87-.53,4.15.93,2.86,3.87,2.15,8.02,5.96.94.87,1.99,2,3.74,2.33.92.17,1.38,0,1.86.39,1.13.92.99,3.83-.35,4.76-1,.7-1.93-.22-4.55,0-.24.02-1.89.17-3.31.84-3.08,1.44-2.65,4.13-4.9,5.5-1.52.93-4.58.05-10.65-1.73-4.44-1.3-4.59-1.61-8.88-2.78-3.1-.85-3.73-.88-6.03-1.66-.75-.25-3.32-1.14-6.67-2.74-3.56-1.7-3.24-1.94-5.72-3.01-2.17-.94-2.76-.94-7.82-2.45-5.4-1.61-6.34-1.72-6.84-2.73-.8-1.6.6-3.25,5.19-11.48,3.48-6.24,3.73-7.18,3.74-8.39.05-3.85-2.31-6.21-.93-8.22h.02,0Z"/>
    <path id="corpusvertebraehighlight7" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M67,242.56c1.76-.7,3.41,1.79,7.47,3.88,2.71,1.4,4.62,1.65,9.4,2.49,10.54,1.87,15.73,3.18,16.89,3.34,5.17.69,8.56.81,10.38,3.34.32.45,1.57,2.18,1.25,4.15-.21,1.29-.93,1.66-1.42,3.13,0,0-.7,2.13,0,4.45,1.1,3.63,5.54,5.96,8.7,6.74,1.65.41,3.03.42,4.35,1.65.34.32,1.86,1.74,1.6,3.23-.37,2.12-3.88,1.75-7.16,5.16-1.45,1.51-2.29,3.17-2.73,4.04-1.61,3.2-1.2,4.65-2.83,6.07-1.42,1.24-3.19,1.43-4.25,1.52-2.45.2-4.36-.51-5.21-.84-6.67-2.59-7.42-4.93-12.39-6.54-1.68-.54-1.02-.09-8.8-1.52,0,0-3.31-.61-6.7-1.31-7.47-1.55-23.3-4.84-23.43-9.21-.05-1.8,2.6-1.88,4.96-6.27.37-.69.49-1.04,2.2-6.81,1.77-5.99,1.92-6.57,2.45-8.06,1.09-3.06,1.16-2.63,2.43-6.07,1.85-5.01,1.77-6.15,2.85-6.57h0Z"/>
    <path id="corpusvertebraehighlight6" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M80.94,203.73c8.23.27,10.08,4.15,17.6,5.16,5.13.69,6.57-1.4,11.7.02,2.32.64,2.98,1.28,6.03,1.94,5.01,1.08,6.45.06,7.82,1.35,2.18,2.06-1.02,5.06.54,10.65.91,3.25,2.89,5.47,4.58,10.79.26.81.74,2.42-.05,3.49-.54.73-1.18.53-2.51,1.5-.62.45-1.71,1.25-2.07,2.53-.14.51-.03,1.19.15,2.53.27,2.05.7,2.55.48,3.73-.05.29-.3,1.66-1.39,2.38-.92.6-2,.45-2.53.37-5.91-.95-14.06-4.15-14.06-4.15-3.52-1.38-6.15-2.21-9.71-3.34-5.2-1.64-6.99-1.98-7.79-2.12-2.5-.44-3.39-.37-7.18-1.01-3.47-.59-2.84-.67-5.16-1.01-4-.59-6.2-.39-8.38-1.84-.72-.48-1.4-1.11-1.63-2-.24-.92.09-1.74.2-2.02.84-2.12,4.47-12.57,5.66-18.71.97-4.95.42-7.42,2.63-9.1,1.21-.92,2.69-1.19,5.06-1.11v-.03h0Z"/>
    <path id="corpusvertebraehighlight5" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M90.75,167.83c.62.17,2.45.68,4.15,1.01,2.97.59,5.03.56,7.58.61,3.62.07,7.22.42,10.83.62,6.22.33,9.33.5,10.4.6,7.38.68,11.1,1.05,12.84,2.83,3.09,3.16,2.97,8.85,2.01,12.26-1,3.55-2.67,3.82-3.01,7.49-.39,4.15-.04,7.2-.04,7.2.13,1.04.34,2.15-.26,3.3-.63,1.22-2.07,2.26-3.4,2.1-1.01-.12-1.25-.86-2.37-1.51-1.83-1.05-3.85-.62-4.73-.49-3.35.47-7.31-1.14-17.62-2.34-9.5-1.1-18.89-3.21-28.42-4.04-.22-.02-.83-.07-1.21-.51-.82-.93.03-2.85.53-4.24.95-2.63.95-5.51,1.42-8.26.72-4.18,1.09-6.28,1.27-8.3.23-2.58,1.04-5.13.92-7.71-.04-.76-.14-2.11.61-2.59.57-.37,1.31-.03,2.43.37,1.97.71,4.05,1.06,6.07,1.62v-.02h0Z"/>
    <path id="corpusvertebraehighlight4" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M92.13,127.51c13.9,2.16,20.85,3.24,26.7,2.43,4.76-.66,7.14-.99,8.63-1.62,1.43-.6,3.54-1.7,6.34-1.5.83.06,1.69.15,2.55.71,1.37.89,1.65,2.28,2.63,5.1,1.09,3.14,1.4,3.3,1.91,4.88,2.46,7.69-2.39,11.35-.36,18.58.36,1.28,1.43,4.42-.04,7.27-.1.2-.26.51-.5.82-1.94,2.47-6.71,1.82-10.66,1.34-11.11-1.35-8.43-.44-15.77-1.61-6.2-.99-7.21-1.5-11.06-1.62-4.66-.14-7-.22-7.82-.13-1.98.19-4.06.58-6.33-.46-.77-.35-1.52-.7-2.11-1.46-.67-.88-1.36-2.65.75-8.8,1.97-5.73,2.85-5.01,2.97-7.2.22-4.01-2.82-5.09-3.51-11.33-.33-3.01.28-4.05.79-4.57,1.35-1.38,3.79-1,4.88-.83h0Z"/>
    <path id="corpusvertebraehighlight3" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M99.28,91.78c1.36.3,2.43.26,3.38.21,13.15-.69,19.73-1.03,24.26-1.96.98-.2,3.15-.67,5.93-.27,1.5.21,3.3.41,4.9,1.55,1.89,1.34,2.5,3.25,3.46,6.34.98,3.2,2.04,6.63,1.08,10.72-.57,2.4-1.09,1.57-2.83,6.2-1.73,4.59-1.93,7.19-4.04,8.09-.99.42-2.12.35-3.37.27-2.01-.13-3.45-.71-3.81-.84-3.8-1.42-15-1.87-25.17-.24-2.35.38-5.13.94-8.78.22-2.58-.51-5.14-1.02-6.12-2.93-1.09-2.12.88-3.84,2.17-11.49.34-2.04.4-3.08.19-4.27-.34-1.93-1.05-2.4-1.89-5.26-.46-1.59-.74-2.54-.72-3.84.01-.7.02-4.3,2.01-5.27.93-.45,2-.09,4.15.65,2.61.9,3.14,1.66,5.22,2.12h-.02Z"/>
    <path id="corpusvertebraehighlight2" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M92.77,53.66c3.37.36,13.37.14,19.62,0,1.6-.04,6.52-.5,16.37-1.44.14,0,3.19-.3,7.3-.28,2.94,0,3.86-.21,4.95.3,3.04,1.42,3.8,5.67,3.85,5.97,1.06,6.42-4.65,13.14-5.26,17.39-.47,3.24-.4,3.74-.4,3.74.06.5.19,1.09-.1,1.72-.38.83-1.23,1.19-1.52,1.31-2.3.99-5.83-1.09-9.92-1.33-2.48-.15-1.8.58-8.89,1.64-2.79.42-4.63.69-7.08.71-4.29.03-4.03-.78-8.19-.87-2.61-.06-8.27-.18-11.63.77-.43.12-1.66.5-2.94,0,0,0-.89-.35-1.51-1.11-1.32-1.61.28-4.53,1.01-7.99,1.04-4.93.54-12.04-.4-15.37-.15-.53-.64-2.14.13-3.39.44-.72,1.16-1.09,1.5-1.26,1.39-.7,2.78-.54,3.12-.5h-.01Z"/>
    <path id="corpusvertebraehighlight1" class="WK1533Fill" style= {{
            fill: props.colors.WK1533Fill,
            stroke: props.colors.WK1533Fill,
            opacity:props.colors.WK1533Fill,
          }} d="M90.34,20.59c.51-.18.72-.21,2.93.51.15.05,2.45.77,4.75.61.51-.04.84-.1,3.84-1.01,1.68-.51,2.52-.76,3.94-1.21,4.16-1.32,5.95.37,11.31-.38,5.43-.76,5.85-2.82,9.73-2.46,1.25.12,3.05.5,5.36-.19,1.25-.38,1.73-.97,2.53-.81,1.87.39,2.57,4.04,2.63,4.35.89,4.86-2.36,6.91-2.91,14.16-.09,1.21-.18,2.33-.02,3.84.4,3.8,1.9,5.03,1.2,6.28-.93,1.64-3.54,1.63-8.08,1.81,0,0-3.69.15-13.65.91-3.35.26-3.52.4-14.36,1.52-7.12.73-9.39.91-10.29-.46-.23-.35-.55-1.08,1.7-8.49,1.3-4.3,1.73-5.12,1.32-6.52-.75-2.56-2.87-2.44-3.84-5.16-1.04-2.9.11-6.63,1.92-7.28v-.02h0Z"/>
  </g>
  <g id="Platten">
    <path id="baseandupperplateshighlight22" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M65.34,481.12c-1.38,1.13-3.38-.48-4.04-.97-3.13-2.29-6.18-1.27-15.1.19-10.87,1.79-16.31,2.68-19.14,2.4-8.75-.87-15.84-1.57-19.42-3.37-.67-.33-2.26-1.23-3.91-.39-1.62.83-2.13,2.75-2.8,2.45-.87-.39-1.4-4.22-.35-6.06,1.6-2.79,6.56-.37,8.97.67,11.77,5.07,23.3,3.2,28.43,2.36,7.34-1.19,21.85-5.83,26.29-3.67.54.26,1.92.94,2.25,2.53.28,1.37-.29,3.13-1.17,3.84v.02Z"/>
    <path id="baseandupperplateshighlight21" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M72.05,441c-1.64.69-3.11-1.41-3.61-2.07-2.59-3.37-6.83-3.01-14.54-4.07-10.28-1.41-15.42-2.11-18.87-3.56-1.21-.51-12.59-5.36-17.86-8.23-.86-.47-2.69-1.5-4.59-.97-1.35.38-1.91,1.31-2.42,1.06-.86-.42-.86-3.73.66-4.95,2.09-1.68,6.67.9,9.12,2.21,0,0,12.01,6.38,25.1,9.27,11.01,2.43,24.26,1.24,27.77,4.89.36.37,1.58,1.51,1.44,3.06-.12,1.39-1.16,2.92-2.2,3.36h0Z"/>
    <path id="baseandupperplateshighlight20" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M85.89,389.45c-.61.71-2.49-.15-4.04-.61-3.91-1.15-6.41,0-9.51.2-3.41.21-3.96-.98-13.25-4.55-12.85-4.94-16.28-4.38-20.22-7.99-2.05-1.87-3.25-2.63-5.66-4.15-2.8-1.76-3.87-2.04-4.85-2.02-2.9.05-4.64,2.51-5.06,2.12-.41-.39.83-3.85,3.43-4.78,3.12-1.11,6.02,2.21,13.06,6.19,2,1.13,4.77,2.34,10.31,4.75,15.6,6.79,21.3,7.25,23.56,7.28,1.31.02,2.61.12,3.91.16,5.18.15,7.07-.02,8.02,1.26.49.66.69,1.67.3,2.12v.02Z"/>
    <path id="baseandupperplateshighlight19" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M96.01,345.75c-1.16-.14-1.82-1.64-2.13-2.21-2.51-4.64-11.94-3.67-25.69-6.67-5.47-1.19-8.21-1.79-11.12-3.44-2.35-1.33-4.07-2.79-9.3-5.76-3.07-1.75-5.32-2.86-7.28-2.02-1.65.7-2.18,2.38-2.63,2.19-.67-.29-.54-4.57,1.62-5.84,2.03-1.19,5.16.68,10.82,4.16,3.45,2.12,5.7,3.82,6.57,4.45,8.09,5.78,14.72,2.49,32.46,7.38,8.02,2.21,9.67,3.71,9.71,4.96.05,1.32-1.69,2.98-3.03,2.81h0Z"/>
    <path id="baseandupperplateshighlight18" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M111.54,303.93c-.93,0-1.34-1.03-2.29-1.89-2.27-2.03-5.55-1.13-9.5-1.04-1.62.04-4.33-.45-9.73-1.42-6.64-1.19-11.49-2.07-16.9-4.54-4.38-2-11.61-6.19-16.45-7.96-1.61-.59-3.17-.98-4.85-.54-1.65.43-2.43,1.41-2.9,1.08-.71-.49-.26-3.5,1.39-4.55,2.16-1.37,5.78,1.01,8.6,2.63,5.75,3.32,12.39,4.79,18.54,7.31,19,7.8,36.25,3.86,36.76,7.75.16,1.24-1.39,3.16-2.65,3.17h-.02Z"/>
    <path id="baseandupperplateshighlight17" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M113.32,257.71c-1.2.09-2.11-1.16-2.76-1.85-2.62-2.79-6.79-2.6-10.89-2.89-9.76-.68-24.41-4.44-28.07-8.27-.45-.47-2.01-2.28-3.89-2.11-1.1.1-1.78.84-2.16.54-.49-.39-.14-2.27.94-2.97,1.32-.85,3.29.32,5.72,1.48,3.01,1.44,6.31,2.2,9.38,3.51,8.41,3.57,17.42,3.43,30.1,6.91,1.4.39,3.73,1.06,4.02,2.53.25,1.29-1.08,3.02-2.4,3.12h.01Z"/>
    <path id="baseandupperplateshighlight16" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M125.56,210.75c.42.73-.31,2.16-1.35,2.83-1.45.93-3.3.26-5.14-.36-2.75-.92-7.66-2.57-11.18-3.14-4.66-.75-4.37.81-9.03.04-1.08-.18-4.07-1.24-10.06-3.37-1.18-.42-4.56-1.62-7.62-1.67-1.66-.03-2.61-.04-3.75.46-.91.4-1.37.87-1.85.67-.69-.29-1.03-1.83-.55-2.7.94-1.72,5.16-1.11,7.65-.72,8.15,1.27,9.18,4.08,15.37,4.58,5.15.42,4.93-1.49,9.44-.67,5.5.99,6.59,3.97,11.87,4.04,3.23.05,5.62-1.03,6.2,0h0Z"/>
    <path id="baseandupperplateshighlight15" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M138.1,173.95c-1.26.92-3.47-.48-5.66-1.35-3.47-1.38-5.48-.85-11.66-.86-9.42,0-7.79-1.22-19.35-1.57,0,0-11.91-.35-14.76-2.51-.3-.23-1.12-.95-2.36-1.13-1.35-.2-2.03.41-2.56,0-.77-.59-.64-2.83.41-3.51,1.22-.8,3.13.87,5.8,2.16,3.96,1.92,5.77,1.15,14.7,2.16,6.41.72,6,1.18,11.02,1.73,14.8,1.64,23.64-1.74,25.17,1.55.51,1.09.11,2.71-.73,3.32h-.02Z"/>
    <path id="baseandupperplateshighlight14" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M139.22,132.04c-1.16,1.35-2.85-.57-3.41-1.15-.59-.61-1.18-1.03-1.82-1.28-2.66-1.06-8.67.77-10.9,1.51-7.26,2.4-14.21.89-17.35.21-8.49-1.84-12.74-2.76-13.62-3.1-.67-.26-2.35-.95-3.91-.27-1.41.62-1.76,1.95-2.36,1.81-.85-.2-1.52-3.09-.4-4.31,1.28-1.38,4.52-.18,6.76.53,8.43,2.69,17.82,3.43,23.95,2.82,5.14-.51,6-1.77,14.25-2.61,6.53-.66,9.06-.18,9.78,1.25.63,1.25-.02,3.48-.98,4.59h.01Z"/>
    <path id="baseandupperplateshighlight13" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M139.27,92.28c-1.1.93-2.7-.39-3.23-.79-2.5-1.88-4.93-1.05-12.04.16-8.67,1.47-13,2.21-15.26,1.97-6.98-.71-12.63-1.29-15.48-2.77-.53-.27-1.8-1.01-3.12-.32-1.29.68-1.69,2.26-2.23,2.01-.69-.32-1.12-3.47-.28-4.98,1.27-2.3,5.23-.3,7.15.55,9.39,4.17,18.58,2.63,22.67,1.94,5.85-.98,17.42-4.8,20.96-3.02.43.22,1.53.77,1.79,2.08.22,1.13-.23,2.57-.93,3.16h0Z"/>
    <path id="baseandupperplateshighlight12" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M141.74,54.63c-2.01,1.1-4.47-.73-7.01-1.75-3.65-1.47-4.52-.09-18.61,1.48-5.31.59-10.28,1.14-14.83.94-2.2-.09-4.44.14-6.61-.27-1.8-.34-3.09-.82-4.49-.16-1.56.74-1.84,2.22-2.46,2.08-.91-.2-1.7-3.7-.31-5.16,1.57-1.66,5.43-.16,7.89.57,7.46,2.21,8.82-.69,32-2.33,12.57-.89,15.62-.28,16.16,1.22.39,1.09-.53,2.71-1.73,3.37h0Z"/>
    <path id="baseandupperplateshighlight11" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M1.97,459.95c.78-.34,1.37,1.17,3.06,2.85,1.57,1.56,3.11,2.17,6.2,3.38,3.25,1.27,5.97,1.83,7.75,2.14,5.65.97,7.85.47,15.89.96,4.61.28,3.42.42,7.89.68,10.65.63,15.98.95,18.08-.14.72-.37,2.97-1.65,4.23-.42.06.06.69.68.58,1.3-.24,1.36-3.73,1.64-5.01,1.74-25.5,2.09-20.31,3.72-56.94-5.24-.54-.13-1.88-.48-2.51-1.89-.83-1.84-.22-4.94.77-5.37h0Z"/>
    <path id="baseandupperplateshighlight10" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M12.9,406.13c.9.06.69,2.08,1.74,3.99.87,1.59,2.2,2.22,6.88,3.74,3.33,1.08,4.99,1.62,6.14,1.87,10.71,2.28,34.08,15.49,38.55,12.13,2.87-2.16,5.51-2.31,5.51-2.31h0c1.13-.79,2.31-1.85,3.23-1.38.44.23.89.84.8,1.49-.2,1.36-2.79,2.26-6.81,3.63-.56.19-3.25,1.1-4.61,1.39-6.94,1.47-15.98-5.57-28.78-9.28-5.3-1.54-10.27-4.43-15.78-5.06-.39-.04-3.84-.43-6.79-3.18-.95-.89-2.37-2.26-2.15-4.14.17-1.44,1.28-2.94,2.07-2.89h0Z"/>
    <path id="baseandupperplateshighlight9" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M28.53,357.66c.84.16.36,2.67,1.47,4.41.97,1.52,2.56,1.54,4.87,1.74,3.07.26,6.08.92,9.14,1.33,5.97.81,7.58,1.42,8.52,1.83,1.75.76,2.87,1.53,4.76,2.84,5.04,3.48,5.65,5.15,9.17,7.42.82.53,3.3,1.49,8.25,3.41,7.27,2.82,9.66,3.32,11.53,1.83.56-.44,1.65-1.53,2.21-1.12.39.29.46,1.26.15,1.9-.19.38-.48.59-1.35.96-.29.13-1.61.7-2.23.82-6.32,1.18-12.52-2.26-12.52-2.26-13.8-7.64-16.45-11.8-24.72-13.81-4.28-1.04-6.12-1.31-6.12-1.31-6.91-1-9.71.15-12.53-2.61-.45-.44-2.92-2.95-2.29-5.4.27-1.07,1.13-2.12,1.7-2.01v.03Z"/>
    <path id="baseandupperplateshighlight8" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M39.19,315.72c.77-.59,2.1.33,3.85,1.21,4.25,2.16,4.82,1.01,9.83,3.01,3.89,1.55,3.62,2.27,8.33,4.45.68.32,1.27.57,1.84.8,2.94,1.19,5.97,2.1,5.97,2.1,17.91,5.31,18.45,6,21.26,5.65.98-.12,2.92-.45,3.61.51.63.89.1,2.75-1.05,3.47-1.13.71-2.63.17-3.4-.1-6.8-2.38-6.95-.64-15.48-3.76-2.14-.79-3.93-1.44-6.33-2.12-2.08-.59-2.57-.57-5.65-1.87-10.77-4.54-13.19-8.33-18.56-8.25-.78.01-2.94.12-4.04-1.3-.9-1.17-.96-3.2-.17-3.81h-.01Z"/>
    <path id="baseandupperplateshighlight7" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M53.16,274.4c.12.01.35.08,1.48,1.99.35.59.59,1.03.6,1.04.71.92,1.93,1.64,4.6,2.77,4.89,2.06,8.62,2.97,8.62,2.97,2.73.66,5.04,1.1,6.62,1.38.77.13,1.15.2,1.65.27,4.92.73,7.65.23,10.89.84,1.85.35,3.33.97,3.61,1.09,0,0,.31.13.63.28,3.34,1.58,7.72,6.41,12.95,7.46,1.48.3,2.52.5,3.86.19,1.56-.36,2.38-1.16,3.22-.73.64.32,1.03,1.22.88,1.89-.29,1.3-2.67,1.91-3.86,2.22-.01,0-.04.01-.06.02-2.63.66-4.83-.57-6.04-1.27-10.67-6.19-10.07-5.93-10.79-6.28-8.01-3.97-16.52-3.11-19.34-3.48,0,0-5.92-.25-14.32-3.23-2.37-.84-3.38-2.32-4.73-3.67-1.77-1.78-2.08-2.68-2.03-3.48.08-1.16.95-2.31,1.58-2.25l-.02-.02Z"/>
    <path id="baseandupperplateshighlight6" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M67.69,232.33c.66,0,.61,1.12,1.8,2.23,1.11,1.05,2.46,1.27,4.74,1.66,1.19.2,2.05.25,2.63.29,0,0,1.36.09,6.97.9,4.25.61,11.11,3.14,16.88,5.34,3.62,1.38,19.97,7.64,23.02,4.5.18-.18.84-.94,1.61-.85,1.03.12,1.99,1.78,1.65,2.78-.47,1.38-3.42,1.49-5.08,1.53-12.21.33-27.75-6.72-27.75-6.72,0,0-10.64-4.83-18.38-5.02h-.11c-3.45-.1-5.75-.19-7.84-1.47-.75-.46-1.47-.92-1.7-1.81-.38-1.45.71-3.35,1.56-3.36h0Z"/>
    <path id="baseandupperplateshighlight5" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M77.53,193.46c.66-.1.73,1.17,2.03,2.24,1.22,1.01,2.58,1.07,4.9,1.17,1.2.05,2.07-.02,2.65-.06,0,0,1.61-.11,7.03,0,5.88.12,9.02,3.35,18.47,4.85,4.61.73,5.01.14,10.51,1.11,3.51.61,4.49,1.05,6.54.61,2.35-.51,3.99-1.72,5.14-.66.61.56.99,1.69.71,2.42-.32.81-1.39.99-2.05,1.06-5.16.59-16.66-1.28-20.15-1.71-1.03-.13-4.18-.47-8.19-1.8h0s-11.11-3.87-18.84-2.96c-.04,0-.06,0-.1,0-3.45.39-5.75.63-7.96-.51-.8-.41-1.56-.82-1.89-1.78-.54-1.57.35-3.85,1.2-3.99h0Z"/>
    <path id="baseandupperplateshighlight4" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M84.98,156.21c.78-.61,2.53,1.09,3.37,1.8,3.91,3.28,8.65,2.13,14.71,2.21,11.64.14,21.79,4.7,28.4,4.4,2.24-.1,3.37-.13,4.66-.62,2.03-.78,3.35-2.03,4.11-1.28.5.5.63,1.63.43,2.29-1.06,3.54-13.53,1.93-29.54-.23-13.49-1.83-12.99-2.6-15.56-2,0,0-4.54,1.05-8.42-.86-.43-.21-1.35-.72-1.95-1.84-.72-1.35-.86-3.35-.21-3.86h0Z"/>
    <path id="baseandupperplateshighlight3" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M87.62,116.03c.88-.4,2.19,1.26,2.65,1.78,2.77,3.11,7.82,1.8,14.42,1.25,9.57-.8,19.66.21,23.4,1.45,1.08.35,2.29.95,4.16.91,2.15-.05,3.39-.9,4.15-.06.57.63.68,2.01.24,2.76-1.09,1.87-5.57-.15-9.13-1.22-9.93-2.98-20.92-1-28.04.28,0,0-5.61,1.01-10.35-1.72-1.35-.78-2-1.63-2.22-2.57-.26-1.11.03-2.55.7-2.86h.02Z"/>
    <path id="baseandupperplateshighlight2" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M86.74,79.69c.54-.3,1.09.87,2.44,1.54,1.19.59,2.17.37,3.56.15,0,0,1.42-.22,6.08-.56,2.48-.18,3.22.27,7.34.84,4.2.58,6.39.89,9.06.71,5.97-.39,6.96-2.7,12.55-2.26,1.91.15,1.99.44,4.39.59,3.82.24,5.87-.34,6.86,1.23.41.65.72,1.6.45,2.19-.32.71-1.39.7-3.56.36-2.39-.38-3.58-.56-5.3-1.16-3.5-1.21-6.31.23-9.9,1.2-9.42,2.56-13.85-3.43-25.11-.37,0,0-5.98,1.62-8.07-.27-.62-.56-.94-1.36-.94-1.36-.45-1.11-.37-2.55.14-2.83h.01Z"/>
    <path id="baseandupperplateshighlight1" class="Grund_Deckplatten1533Fill" style= {{
            fill: props.colors.Grund_Deckplatten1533Fill,
            stroke: props.colors.Grund_Deckplatten1533Fill,
            opacity:props.colors.Grund_Deckplatten1533Fill,
          }} d="M88.37,45.39c.53-.29,1.46.44,1.88.8,1.79,1.53,4.19,1.56,8.89,1.1,7.63-.75,9.29-1.61,14.04-1.88,5.22-.3,17.44-.09,19.56-1.26.11-.06.7-.31,1.88-.81,1.07-.45,1.33-.55,1.63-.34.71.48,1.23,2.31,1.05,3.34-.4,2.33-4.5,1.73-7.74,1.45-7.85-.68-15.05.14-19.22.98-4.69.94-7.56.9-13.19,1.34h0c-2.55.44-5.16.9-7.22-.31-1.07-.63-1.48-1.4-1.57-1.58-.52-1.04-.56-2.49,0-2.81v-.02Z"/>
  </g>
</g>
</svg>
  );
}

export default ThoracolumbalerUbergangLatLiegend1533;
