import { Button, Modal } from "react-bootstrap";
import BodyPartsList from "../BodyPartsList/BodyPartsList";

function BodyPartModal({
  shown,
  close,
  selectHandler = undefined,
  selectedBparts = undefined,
}) {
  return (
    <Modal show={shown} onHide={close} size="sm">
      <Modal.Header closeButton className="p-2">
        <h5>Select Bodypart</h5>
      </Modal.Header>
      <Modal.Body>
        <BodyPartsList
          selectHandler={selectHandler}
          selected={selectedBparts}
        />
      </Modal.Body>
    </Modal>
  );
}

export default BodyPartModal;
