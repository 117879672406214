import style from "./style.scss";

function LumbosacralerUbergangLatLiegend1725(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 279.23 502.84">

<g id="Anatomy">
  <path id="Anatomy23" class="Lumbosacraler_Ubergang_lat_liegend1725_cls5" d="M159.82,59.02c.03-.05.04-.11.07-.16-.02-1.47-.21-3.05-.74-4.67-2.29-7.06-8.02-7.14-21.19-17.09-9.91-7.49-13.05-12.27-16.41-10.94-3.45,1.37-3.87,7.92-4.1,11.62-.72,11.48,4.42,14.27,6.15,28.03.28,2.19.39,4.07.45,5.73,1.22,1.15,1.62,2.98,1.23,4.6.31,1.28.12,2.7-.58,3.81.32.87.82,1.61,1.63,2.27,3.69,3,9.7,1.19,13.67,0,2.67-.8,14.49-4.35,18.46-15.72.16-.47.38-1.09.59-1.82-.28-.68-.39-1.42-.35-2.16-.08-1.26.28-2.55,1.11-3.49Z"/>
  <path id="Anatomy22" class="Lumbosacraler_Ubergang_lat_liegend1725_cls3" d="M122.24,22.22c1.88,7.41,14.43,12.26,31.63-7.58,28.83,1.47,29.58,22.05,18.27,35.54-9.67,4.12-16.06,13.47-21.25,17.09"/>
  <path id="Anatomy21" class="Lumbosacraler_Ubergang_lat_liegend1725_cls5" d="M156.97,241.12c-1.82,5.79-2.94,9.51-1.37,13.67.34.9,1.9,5.03,4.78,5.47,4.37.67,10.62-7.34,10.94-15.04.12-2.97-.75-3.22-3.42-12.99-2.33-8.53-2.35-10.82-3.42-10.94-.82-.09-1.63,1.16-7.52,19.82h.01Z"/>
  <path id="Anatomy20" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M21.76,74.11c-3.19-3.26-3.21-10.46,0-12.99,3.22-2.53,7.76,1.16,21.19,5.47,13.57,4.35,13.93,2.19,27.34,6.84,8.33,2.89,19.14,7.52,19.14,7.52h0s4,4.12,3.42,6.15c-.9,3.14-12.93,2.28-15.72,2.05-8.98-.75-16.43-2.88-21.87-4.78-13.1-4.59-13.03-7.32-21.19-8.2-6.34-.69-9.71.6-12.3-2.05h-.01Z"/>
  <path id="Anatomy19" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M129.08,192.02c-2.13-1.35,8.12-12.28,5.47-25.63-2.09-10.52-11.79-20.55-19.14-19.82-2.95.29-4.38,2.26-6.84,1.37-3.59-1.31-2.66-6.29-6.84-10.94-2.86-3.18-8.21-6.32-11.62-4.78-3.93,1.77-4.74,9.51-5.47,16.41-.51,4.82-.14,7.11-2.05,9.57-1.18,1.52-3.27,3.17-8.2,4.1-8.69,1.65-10.38-2.87-21.63-2.32-8.17.4-11.31,3.61-20.75,2.32-13.26-1.81-24.61,1.54-29.39-4.78-3.45-4.56-2.13-10.92,0-21.19,2.9-13.99,6.92-13.97,7.52-23.92.59-9.68-3.04-12.58-.68-21.87.95-3.75,2.28-9.03,6.84-11.62,1.43-.82,4.07-1.61,17.77,2.73,8.96,2.84,10.2,3.95,17.77,6.15,6.23,1.82,9.87,2.87,15.04,3.42,11.13,1.17,14.36-1.63,21.19,1.37,4.98,2.19,4.39,4.17,8.89,5.47,5.67,1.64,13.13.36,17.77-4.1,4.62-4.43,2.28-7.98,6.84-12.3,6.42-6.09,16.54-4.21,17.09-4.1,7.55,1.52,11.71,6.86,14.35,10.25,2.49,3.2,6.61,8.49,6.15,15.04-.24,3.41-1.54,4.67-8.89,15.04-15.11,21.33-10.22,16.9-12.99,19.82,0,0-5.68,6-5.01,10.51.08.55.23,1.11.23,1.11.59,2.19,1.8,3.6,2.33,4.23,0,0,.39.46,3.83,5.34,4.29,6.08,3.94,10.46,3.22,15.59-.13.95-.54,3.39-1.85,6.29-3.07,6.81-9.37,12.27-10.94,11.28v-.04Z"/>
  <path id="Anatomy18" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M8.77,184.84c-.6-.63-1.09-3.85-2.05-10.25-.71-4.72-.78-5.93,0-6.84,1.74-2,6.15-.66,8.89,0,1.58.38,9.26.26,24.61,0,14.79-.25,22.19-.37,25.29-1.37,5.21-1.68,9.23-4.04,12.99-2.05,2.65,1.4,4.72,4.73,4.1,7.52-.58,2.6-3.35,4.13-6.84,5.47-11.33,4.37-18.24,2.16-32.81,4.1-3.08.41-7.3,1.19-15.72,2.73-11.73,2.16-16.07,3.22-18.46.68h0Z"/>
  <path id="Anatomy17" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M150.95,128.11c.79,1.53,8.91-.12,16.41-4.1,8.19-4.34,8.08-7.46,14.35-9.57,8.32-2.8,16.46,0,18.46.68,9.01,3.1,9.44,7.83,15.72,8.2,6.57.39,13.35-4.36,16.41-10.25,3.16-6.1,1.63-12.08,0-18.46-1.09-4.27-2.56-10.03-7.52-15.04-6.13-6.19-13.86-7.45-18.46-8.2-8.86-1.45-8.92,1.33-19.82,0-6.37-.78-11.76-1.43-18.46-4.1-5.21-2.07-7.4-3.85-9.57-2.73-3.94,2.03-2.99,11.17-2.73,13.67.88,8.5,3.97,9.87,6.15,19.82,1.32,6.03,2.33,10.64.68,15.72-3.02,9.33-12.5,12.65-11.62,14.35h0Z"/>
  <path id="Anatomy16" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M139.33,189.63c2.13,1.68,7.39-1.09,10.25-3.42,4.88-3.96,3.72-7.37,8.2-11.62,5.39-5.11,12.6-5.44,14.35-5.47,6.49-.1,11.31,2.91,14.35,4.78,6.17,3.8,5.9,6.3,10.94,8.2,1.18.45,7.27,2.74,13.1-.12,4.77-2.34,6.67-6.8,8.09-10.14.51-1.19,5.04-12.32-.45-23.82-1.06-2.23-3.17-6.52-7.76-9.66-2.06-1.41-3.72-1.97-13.67-4.11-7.82-1.68-11.74-2.52-17.09-3.42-7.76-1.3-11.64-1.95-15.04-2.05-7.31-.22-13.14-.4-19.14,2.73-5.8,3.03-13.46,10.06-12.99,17.77.43,7,7.1,7.33,9.57,15.72,3.42,11.62-6.15,21.91-2.73,24.61l.02.02Z"/>
  <path id="Anatomy15" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M29.96,257.3c-4.55-2.05-6.87-7.4-7.52-8.89-2.33-5.35-.39-7.67-1.37-15.72-.98-8-3.96-14.49-5.47-17.77-3.02-6.57-5.42-9-5.47-14.35-.02-1.97-.05-5.46,2.05-8.2,3.35-4.36,9.79-3.81,18.46-4.1,5.86-.2,13.02-1.27,27.34-3.42,15.19-2.28,22.78-3.42,26.66-6.84,2.29-2.02,6.31-7.72,14.35-19.14,1.3-1.84,3.04-4.34,6.15-6.84,2.43-1.95,7.45-5.97,13.11-5.23,5.07.66,8.18,4.8,10.57,7.99.74.99,11.47,15.69,5.55,28.26-1.76,3.74-5.22,6.51-12.14,12.05-4.84,3.87-7.78,6.23-12.3,8.2-9.67,4.22-17.06,2.57-17.77,5.47-.66,2.67,5.5,4.62,5.47,8.89-.03,3.55-4.31,6.12-8.89,8.89-7.09,4.29-13.82,9.21-21.19,12.99-24.91,12.75-30.39,21.02-37.59,17.77h0Z"/>
  <path id="Anatomy14" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M126.06,192.02c-1.51.04-2.5,1.42-3.82,3.08-5.89,7.41-8.75,8.21-8.2,10.25.64,2.36,4.5,1.37,6.84,4.78,2.29,3.36.07,6.41,2.05,8.2,2.53,2.29,6.78-2.15,12.99-.68,5.12,1.2,8.23,5.62,9.57,7.52,3.29,4.66,4.4,10.36,4.78,12.3.87,4.43.52,6.8,2.05,7.52,2.1.98,5.54-2.21,6.84-3.42,5.14-4.78,4.28-8.09,8.2-12.3,5.08-5.45,12.29-6.1,12.99-6.15,1.49-.11,3.46-.07,15.04,4.1,9.92,3.57,11.22,4.52,14.35,4.1.56-.07,7.78-1.15,10.94-7.52,1.63-3.29,2.28-8.21,0-11.62-1.85-2.76-4.01-2.12-5.47-4.78-2.05-3.74,1.22-6.79,0-10.25-.82-2.33-3.4-4.09-17.77-6.84-9.88-1.89-17.8-2.8-24.61-3.42-11.9-1.09-13.92-1.19-16.41-.68-6.55,1.34-7.61,3.74-13.58,3.66-3.82-.05-7.35-1.08-9.38,1.07"/>
  <path id="Anatomy13" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M37.48,262.77c1.45-3.94,5.51-3.39,17.09-8.89,3.48-1.65,11.74-5.63,20.51-12.3,4.28-3.26,8.87-6.11,12.99-9.57,3.3-2.77,5.62-5,8.89-4.78,2.47.17,5.32,1.72,6.15,4.1.49,1.41.61,4.05-6.15,11.62-6.38,7.14-8.99,7.59-15.04,13.67-2.19,2.21-5.34,6.26-11.62,14.35-7.44,9.58-9.14,12.39-12.99,12.99-4.57.71-8.5-2.26-10.94-4.1-4.87-3.68-10.98-11.39-8.89-17.09Z"/>
  <path id="Anatomy12" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M224.09,370.77c-3.78,2.89-8.3,2.77-9.57,2.73-5.66-.15-8.67-3.14-13.02-5.41-5.53-2.87-11.87-2.85-24.57-2.79-9.54.04-11.38.85-26.66,2.05-16.12,1.27-24.18,1.91-28.71.68-1.61-.44-18.78-5.35-26.1-21.18-2.52-5.45-2.78-9.82-3.29-18.47-.8-13.63,1.62-23.62,4.1-33.49,4.41-17.55,8.56-21.23,10.25-22.56,1.25-.98,7.29-5.72,13.67-4.1,5.11,1.3,7.78,5.99,11.62,12.99,5.49,9.99,4.48,13.28,8.2,19.82,5.19,9.12,13.33,13.59,18.46,16.41,7.31,4.01,9.43,3.04,34.86,8.89,16.08,3.7,21.4,5.5,26.66,10.25,2.1,1.9,7.18,6.62,8.89,14.35.26,1.18,2.9,13.95-4.78,19.82h0Z"/>
  <path id="Anatomy11" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M57.3,292.84c-.48-3.2,3.39-5.45,10.94-12.3,0,0,5.96-5.41,16.41-17.09,11.56-12.92,15.95-22.06,17.77-21.19,1.75.83-2.95,9.01-1.37,21.87.82,6.7,2.4,6.92,2.24,11.91-.25,7.76-4.14,9.32-7.02,18.85-3.3,10.9-.4,16.06-3.6,17.72-3.42,1.78-7.01-3.94-20.32-10.89-9.79-5.11-14.53-5.5-15.04-8.89h0Z"/>
  <path id="Anatomy10" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M107.89,233.37c3.06-6.1,6.16-8.73,8.89-10.25,1.36-.76,6.57-3.43,12.99-2.05,6.48,1.39,10.08,6.03,12.3,8.89,5.3,6.82,5.98,14.28,6.15,16.41.38,4.84-.77,5.01,0,8.2,1.46,6.05,7.17,11.98,13.67,12.99,4.71.73,5.4-1.81,12.99-2.05,4.34-.14,9.41-.3,12.99,2.73,4.31,3.65,1.52,7.76,5.47,12.99,3.27,4.33,6.06,2.67,17.77,8.89,6.28,3.33,9.93,5.32,12.99,9.57,2.83,3.93,1.68,4.97,4.78,9.57,3.78,5.6,6.09,4.95,10.94,10.94,3.86,4.77,2.63,5.47,6.84,10.94,4.48,5.82,5.88,5.01,9.57,10.25,1.99,2.82,5.2,7.49,5.47,13.67.14,3.28-.66,4.32-1.37,8.2-1.69,9.21,1.47,10.85,1.37,23.24-.06,7.75-1.37,16.41-1.37,16.41h0c.81-8.18-1.34,16.85,1.37,24.61,1.29,3.68,1.7,2.9,3.42,7.52,1.09,2.93,3.63,9.93,3.42,17.77-.11,4.2-2.13,9.46-6.15,19.82-3.71,9.55-5.62,14.4-8.2,17.77-5.92,7.73-14.98,13.38-17.09,11.62-1.69-1.41,1.18-7.53,3.42-12.3,4.68-9.98,7.23-9.58,9.57-15.72,2.34-6.15.08-7.35.68-25.29.32-9.63.97-9.24.68-14.35-.51-9.13-2.57-10.16-4.78-21.87-2.45-12.93-.63-15.3-4.1-23.92-2.04-5.07-3.82-9.32-8.2-12.3-4.86-3.31-9.56-2.76-10.25-5.47-.68-2.66,3.55-4.36,5.47-8.89,2.95-6.94-1.82-15.15-3.05-17.26-6.2-10.67-18.53-14.25-33.18-18.28-15.13-4.17-15.99-1.56-26.12-5.55-5.99-2.36-19-7.49-27.2-19.74-8.88-13.26-4.89-24.04-14.7-29.75-1.32-.77-7.44-4.33-13.16-2.29-3.53,1.25-4.09,3.64-5.64,3.33-4.48-.88-6.95-22.35,1.37-38.96l-.02-.04Z"/>
  <path id="Anatomy9" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M169.4,236.79c3.94-.34,4.2,3.54,11.62,6.15,8.45,2.97,17.69,1.31,21.19.68,4.87-.88,6.47-1.89,10.25-1.37,3.54.49,9.34,2.38,10.25,6.15.24.98.13,2.04-4.1,9.57-3.53,6.28-4.33,7.1-4.1,8.89.43,3.39,3.98,5.68,6.84,7.52,2.99,1.93,5.31,2.61,7.52,3.42,6.55,2.4,10.95,5.47,17.77,10.25,5.68,3.98,8.52,5.97,10.94,8.89,2.49,3.01,8.54,9.36,8.29,16.84-.05,1.5-.18,4.13-2.13,5.72-1.46,1.18-3.24,1.24-4.1,1.2-.64-.54-2.47-7.66-6.23-6.39-1.94.66-3.64,2.43-3.34,3.14.32.74,2.55-.42,4.1.68,1.82,1.29.5,4.1,1.56,7.66.87,2.92,2.33,2.37,6.64,8.06,3.98,5.24,5.46,8.24,5.96,9.3.52,1.09,1.42,3.11,2.24,5.74.69,2.22,1.64,6.33,2.05,15.72.18,4.04.02,6.02,0,8.89-.04,5.68.5,8.27,2.73,23.24,3.54,23.7-.1,1.91,2.73,24.61.75,5.99,1.4,10.68-1.37,12.99-2.1,1.76-5.4,1.51-7.52.68-4.1-1.59-5.67-6.07-7.05-10.21-1.6-4.8-1.87-8.95-1.88-13.72-.04-9.8,1-11.51,1.2-19.2.3-11.45-1.9-11.53-1.64-23.17.26-11.75,2.5-12.04,1.17-17.78-.86-3.71-2.22-5.43-14.35-19.82-9.7-11.51-11.61-13.71-14.61-18.13-6.41-9.45-4.43-9.32-7.95-13.31-10.88-12.36-24.4-7.45-30.76-18.46-3.32-5.75-1.09-9.61-5.47-12.99-3.76-2.9-8.89-2.75-15.6-2.54-9.48.29-11.53,2.41-14.19.66-6.54-4.3-1.68-22.04-2.25-22.12-.48-.06-3,12.6-1.44,13.05,1.97.57,6.14-19.73,15.04-20.51v.02Z"/>
  <path id="Anatomy8" class="Lumbosacraler_Ubergang_lat_liegend1725_cls4" d="M124.97,72.62c-2.18.27-2.64-6.78-8.2-9.54-4.66-2.31-11.76-1.03-14.35,2.72-2.01,2.9-.23,5.7-2.73,8.85-1.9,2.39-4.74,3.07-6.15,3.41-11.64,2.77-25.63-13.36-49.22-18.39-9.55-2.04-17.32-1.55-19.82-6.81-1.71-3.58-.05-7.89,1.37-11.58,3-7.82,6.48-7.66,11.62-16.35,3.95-6.68,3.15-8.89,8.2-16.35,2.89-4.26,4.38-6.42,6.84-7.49,5.09-2.23,9.96,1.08,19.82,5.45,3.83,1.7,15.31,6.78,27.34,9.54,13.41,3.08,20.65,1.7,22.56,6.13,1.93,4.49-4.27,8.73-2.05,12.94,2.31,4.4,11.77,4.9,18.46,3.41,7.49-1.67,9.97-5.48,12.3-4.09,3.45,2.06,3.18,13.46-2.73,18.39-5.53,4.62-11.81.07-17.09,4.77-5.62,5-3.57,14.67-6.15,14.99h-.02Z"/>
  <path id="Anatomy7" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M102.39,109.65c0-3.78.73,9.23,26.68-6.84"/>
  <path id="Anatomy6" class="Lumbosacraler_Ubergang_lat_liegend1725_cls1" d="M129.08,131.53c11.8-4.43,5.22-28.71,0-28.71"/>
  <path id="Anatomy5" class="Lumbosacraler_Ubergang_lat_liegend1725_cls2" d="M115.73,163.58c-7.31,8.56-18.78,17.01-27.26,18.53"/>
  <path id="Anatomy4" class="Lumbosacraler_Ubergang_lat_liegend1725_cls2" d="M115.73,163.58c7.43,3.47,7.94,22.97,10.33,28.44"/>
  <line id="Anatomy3" class="Lumbosacraler_Ubergang_lat_liegend1725_cls2" x1="124.97" y1="72.62" x2="126.06" y2="78.39"/>
  <line id="Anatomy2" class="Lumbosacraler_Ubergang_lat_liegend1725_cls2" x1="158.42" y1="60.29" x2="158.47" y2="64.54"/>
  <line id="Anatomy1" class="Lumbosacraler_Ubergang_lat_liegend1725_cls2" x1="167.35" y1="229.27" x2="169.4" y2="236.79"/>
</g>
<g id="Overlays">
  <path id="Overlays16" class="UnterenLWS1725Fill" style= {{
            fill: props.colors.UnterenLWS1725Fill,
            stroke: props.colors.UnterenLWS1725Fill,
            opacity:props.colors.UnterenLWS1725Fill,
          }} d="M21.9,74.42c-3.19-3.26-3.21-10.46,0-12.99,3.22-2.53,7.76,1.16,21.19,5.47,13.57,4.35,13.93,2.19,27.34,6.84,8.33,2.89,19.14,7.52,19.14,7.52h0s4,4.12,3.42,6.15c-.9,3.14-12.93,2.28-15.72,2.05-8.98-.75-16.43-2.88-21.87-4.78-13.1-4.59-13.03-7.32-21.19-8.2-6.34-.69-9.71.6-12.3-2.05h-.01Z"/>
  <path id="Overlays15" class="UnterenLWS1725Fill" style= {{
            fill: props.colors.UnterenLWS1725Fill,
            stroke: props.colors.UnterenLWS1725Fill,
            opacity:props.colors.UnterenLWS1725Fill,
          }} d="M129.22,192.33c-2.13-1.35,8.12-12.28,5.47-25.63-2.09-10.52-11.79-20.55-19.14-19.82-2.95.29-4.38,2.26-6.84,1.37-3.59-1.31-2.66-6.29-6.84-10.94-2.86-3.18-8.21-6.32-11.62-4.78-3.93,1.77-4.74,9.51-5.47,16.41-.51,4.82-.14,7.11-2.05,9.57-1.18,1.52-3.27,3.17-8.2,4.1-8.69,1.65-10.38-2.87-21.63-2.32-8.17.4-11.31,3.61-20.75,2.32-13.26-1.81-24.61,1.54-29.39-4.78-3.45-4.56-2.13-10.92,0-21.19,2.9-13.99,6.92-13.97,7.52-23.92.59-9.68-3.04-12.58-.68-21.87.95-3.75,2.28-9.03,6.84-11.62,1.43-.82,4.07-1.61,17.77,2.73,8.96,2.84,10.2,3.95,17.77,6.15,6.23,1.82,9.87,2.87,15.04,3.42,11.13,1.17,14.36-1.63,21.19,1.37,4.98,2.19,4.39,4.17,8.89,5.47,5.67,1.64,13.13.36,17.77-4.1,4.62-4.43,2.28-7.98,6.84-12.3,6.42-6.09,16.54-4.21,17.09-4.1,7.55,1.52,11.71,6.86,14.35,10.25,2.49,3.2,6.61,8.49,6.15,15.04-.24,3.41-1.54,4.67-8.89,15.04-15.11,21.33-10.22,16.9-12.99,19.82,0,0-5.68,6-5.01,10.51.08.55.23,1.11.23,1.11.59,2.19,1.8,3.6,2.33,4.23,0,0,.39.46,3.83,5.34,4.29,6.08,3.94,10.46,3.22,15.59-.13.95-.54,3.39-1.85,6.29-3.07,6.81-9.37,12.27-10.94,11.28v-.04Z"/>
  <path id="Overlays14" class="UnterenLWS1725Fill" style= {{
            fill: props.colors.UnterenLWS1725Fill,
            stroke: props.colors.UnterenLWS1725Fill,
            opacity:props.colors.UnterenLWS1725Fill,
          }} d="M8.91,185.15c-.6-.63-1.09-3.85-2.05-10.25-.71-4.72-.78-5.93,0-6.84,1.74-2,6.15-.66,8.89,0,1.58.38,9.26.26,24.61,0,14.79-.25,22.19-.37,25.29-1.37,5.21-1.68,9.23-4.04,12.99-2.05,2.65,1.4,4.72,4.73,4.1,7.52-.58,2.6-3.35,4.13-6.84,5.47-11.33,4.37-18.24,2.16-32.81,4.1-3.08.41-7.3,1.19-15.72,2.73-11.73,2.16-16.07,3.22-18.46.68h0Z"/>
  <path id="Overlays13" class="UnterenLWS1725Fill" style= {{
            fill: props.colors.UnterenLWS1725Fill,
            stroke: props.colors.UnterenLWS1725Fill,
            opacity:props.colors.UnterenLWS1725Fill,
          }} d="M151.09,128.42c.79,1.53,8.91-.12,16.41-4.1,8.19-4.34,8.08-7.46,14.35-9.57,8.32-2.8,16.46,0,18.46.68,9.01,3.1,9.44,7.83,15.72,8.2,6.57.39,13.35-4.36,16.41-10.25,3.16-6.1,1.63-12.08,0-18.46-1.09-4.27-2.56-10.03-7.52-15.04-6.13-6.19-13.86-7.45-18.46-8.2-8.86-1.45-8.92,1.33-19.82,0-6.37-.78-11.76-1.43-18.46-4.1-5.21-2.07-7.4-3.85-9.57-2.73-3.94,2.03-2.99,11.17-2.73,13.67.88,8.5,3.97,9.87,6.15,19.82,1.32,6.03,2.33,10.64.68,15.72-3.02,9.33-12.5,12.65-11.62,14.35h0Z"/>
  <path id="Overlays12" class="UnterenLWS1725Fill" style= {{
            fill: props.colors.UnterenLWS1725Fill,
            stroke: props.colors.UnterenLWS1725Fill,
            opacity:props.colors.UnterenLWS1725Fill,
          }} d="M139.47,189.94c2.13,1.68,7.39-1.09,10.25-3.42,4.88-3.96,3.72-7.37,8.2-11.62,5.39-5.11,12.6-5.44,14.35-5.47,6.49-.1,11.31,2.91,14.35,4.78,6.17,3.8,5.9,6.3,10.94,8.2,1.18.45,7.27,2.74,13.1-.12,4.77-2.34,6.67-6.8,8.09-10.14.51-1.19,5.04-12.32-.45-23.82-1.06-2.23-3.17-6.52-7.76-9.66-2.06-1.41-3.72-1.97-13.67-4.11-7.82-1.68-11.74-2.52-17.09-3.42-7.76-1.3-11.64-1.95-15.04-2.05-7.31-.22-13.14-.4-19.14,2.73-5.8,3.03-13.46,10.06-12.99,17.77.43,7,7.1,7.33,9.57,15.72,3.42,11.62-6.15,21.91-2.73,24.61l.02.02Z"/>
  <path id="Overlays11" class="UnterenLWS1725Fill" style= {{
            fill: props.colors.UnterenLWS1725Fill,
            stroke: props.colors.UnterenLWS1725Fill,
            opacity:props.colors.UnterenLWS1725Fill,
          }} d="M30.1,257.61c-4.55-2.05-6.87-7.4-7.52-8.89-2.33-5.35-.39-7.67-1.37-15.72-.98-8-3.96-14.49-5.47-17.77-3.02-6.57-5.42-9-5.47-14.35-.02-1.97-.05-5.46,2.05-8.2,3.35-4.36,9.79-3.81,18.46-4.1,5.86-.2,13.02-1.27,27.34-3.42,15.19-2.28,22.78-3.42,26.66-6.84,2.29-2.02,6.31-7.72,14.35-19.14,1.3-1.84,3.04-4.34,6.15-6.84,2.43-1.95,7.45-5.97,13.11-5.23,5.07.66,8.18,4.8,10.57,7.99.74.99,11.47,15.69,5.55,28.26-1.76,3.74-5.22,6.51-12.14,12.05-4.84,3.87-7.78,6.23-12.3,8.2-9.67,4.22-17.06,2.57-17.77,5.47-.66,2.67,5.5,4.62,5.47,8.89-.03,3.55-4.31,6.12-8.89,8.89-7.09,4.29-13.82,9.21-21.19,12.99-24.91,12.75-30.39,21.02-37.59,17.77h0Z"/>
  <path id="Overlays10" class="UnterenLWS1725Fill" style= {{
            fill: props.colors.UnterenLWS1725Fill,
            stroke: props.colors.UnterenLWS1725Fill,
            opacity:props.colors.UnterenLWS1725Fill,
          }} d="M126.2,192.33c-1.51.04-2.5,1.42-3.82,3.08-5.89,7.41-8.75,8.21-8.2,10.25.64,2.36,4.5,1.37,6.84,4.78,2.29,3.36.07,6.41,2.05,8.2,2.53,2.29,6.78-2.15,12.99-.68,5.12,1.2,8.23,5.62,9.57,7.52,3.29,4.66,4.4,10.36,4.78,12.3.87,4.43.52,6.8,2.05,7.52,2.1.98,5.54-2.21,6.84-3.42,5.14-4.78,4.28-8.09,8.2-12.3,5.08-5.45,12.29-6.1,12.99-6.15,1.49-.11,3.46-.07,15.04,4.1,9.92,3.57,11.22,4.52,14.35,4.1.56-.07,7.78-1.15,10.94-7.52,1.63-3.29,2.28-8.21,0-11.62-1.85-2.76-4.01-2.12-5.47-4.78-2.05-3.74,1.22-6.79,0-10.25-.82-2.33-3.4-4.09-17.77-6.84-9.88-1.89-17.8-2.8-24.61-3.42-11.9-1.09-13.92-1.19-16.41-.68-6.55,1.34-7.61,3.74-13.58,3.66-3.82-.05-7.35-1.08-9.38,1.07"/>
  <path id="Overlays9" class="UnterenLWS1725Fill" style= {{
            fill: props.colors.UnterenLWS1725Fill,
            stroke: props.colors.UnterenLWS1725Fill,
            opacity:props.colors.UnterenLWS1725Fill,
          }} d="M37.62,263.08c1.45-3.94,5.51-3.39,17.09-8.89,3.48-1.65,11.74-5.63,20.51-12.3,4.28-3.26,8.87-6.11,12.99-9.57,3.3-2.77,5.62-5,8.89-4.78,2.47.17,5.32,1.72,6.15,4.1.49,1.41.61,4.05-6.15,11.62-6.38,7.14-8.99,7.59-15.04,13.67-2.19,2.21-5.34,6.26-11.62,14.35-7.44,9.58-9.14,12.39-12.99,12.99-4.57.71-8.5-2.26-10.94-4.1-4.87-3.68-10.98-11.39-8.89-17.09Z"/>
  <path id="Overlays8" class="UnterenLWS1725Fill" style= {{
            fill: props.colors.UnterenLWS1725Fill,
            stroke: props.colors.UnterenLWS1725Fill,
            opacity:props.colors.UnterenLWS1725Fill,
          }} d="M57.44,293.15c-.48-3.2,3.39-5.45,10.94-12.3,0,0,5.96-5.41,16.41-17.09,11.56-12.92,15.95-22.06,17.77-21.19,1.75.83-2.95,9.01-1.37,21.87.82,6.7,2.4,6.92,2.24,11.91-.25,7.76-4.14,9.32-7.02,18.85-3.3,10.9-.4,16.06-3.6,17.72-3.42,1.78-7.01-3.94-20.32-10.89-9.79-5.11-14.53-5.5-15.04-8.89h0Z"/>
  <path id="Overlays7" class="Coccyx1725Fill" style= {{
            fill: props.colors.Coccyx1725Fill,
            stroke: props.colors.Coccyx1725Fill,
            opacity:props.colors.Coccyx1725Fill,
          }} d="M236.85,501.91c-1.39-1.26.98-6.03,3.19-10.48,2.98-6,4.35-6.48,7.75-13.22,3.44-6.82,3.62-9.45,3.65-10.48.11-3.6-1.03-4.98,0-6.38,2.3-3.13,12.07-1.81,13.67,2.28.75,1.92-.82,3.11-3.65,9.57-3.05,6.99-2.08,7.58-4.56,12.3-2.97,5.66-6.63,9.11-8.2,10.48-3.73,3.25-10.18,7.45-11.85,5.92Z"/>
  <path id="Overlays6" class="Grund_Deckplatten1725Fill" style= {{
            fill: props.colors.Grund_Deckplatten1725Fill,
            stroke: props.colors.Grund_Deckplatten1725Fill,
            opacity:props.colors.Grund_Deckplatten1725Fill,
          }} d="M24.49,47.28c1.16-.33,1.94,1.03,4.86,2.73,2.26,1.32,4.04,1.82,7.29,2.73,10.95,3.08,16.42,4.62,18.23,5.16,6.73,2.02,11.51,4.29,19.14,7.9,10.68,5.05,12.09,6.87,16.71,6.68,2.63-.1,5.96-.85,8.2,1.22,1.15,1.06,2.06,2.9,1.52,4.25-1.13,2.8-8.07,2.24-9.42,2.13-6.54-.53-10.03-3.2-16.71-6.68-4.14-2.16-12.55-6.56-23.09-9.11-9.1-2.21-16.1-3.34-16.1-3.34-7.1-1.15-10.44-1.26-12.15-3.95-2.16-3.39-.6-9.12,1.52-9.72Z"/>
  <path id="Overlays5" class="Grund_Deckplatten1725Fill" style= {{
            fill: props.colors.Grund_Deckplatten1725Fill,
            stroke: props.colors.Grund_Deckplatten1725Fill,
            opacity:props.colors.Grund_Deckplatten1725Fill,
          }} d="M17.51,74.62c2.09-.79,3.59,1.15,8.2,2.73,4.35,1.49,5.82.72,10.33,1.52,5.93,1.06,7.27,3.1,13.67,5.77,7.86,3.29,14.77,3.95,18.23,4.25,17.39,1.53,20.66,1.52,20.66,1.52,1.55,0,4.51-.04,5.77,1.82,1.18,1.75.67,4.91-.91,5.77-1.92,1.05-3.99-2.08-8.81-2.73-3.07-.42-5.27.45-6.99.91-2.94.79-5.71.76-27.65-5.16-9.88-2.67-14.82-4-16.1-4.56-4.8-2.09-7.41-3.99-12.15-3.95-3.75.03-5.86,1.25-6.99,0-1.55-1.72-.08-6.83,2.73-7.9Z"/>
  <path id="Overlays4" class="Grund_Deckplatten1725Fill" style= {{
            fill: props.colors.Grund_Deckplatten1725Fill,
            stroke: props.colors.Grund_Deckplatten1725Fill,
            opacity:props.colors.Grund_Deckplatten1725Fill,
          }} d="M4.75,154.91c1.8-.07,4.55-.13,7.9,0,7.36.28,11.97,1.25,15.8,1.82,14.76,2.2,14.54-2.01,27.65.3,8.61,1.52,12.64,4.04,17.32,1.52,1.78-.96,4.32-3,7.9-2.73.66.05,3.44.33,3.95,1.82.69,2.04-3.37,4.97-3.65,5.16-5.1,3.61-11.66,2.87-15.19,2.43-4.39-.55-4.08-1.34-8.81-1.82-4.72-.49-8.73-.09-11.24.3-5.09.79-4.79,1.65-8.81,2.13-4.06.48-4.65-.37-12.46-.91-6-.42-9-.62-11.85-.3-4.21.47-6.46,1.4-9.11,0-.46-.24-3.14-1.66-3.34-4.25-.17-2.14,1.39-4.36,3.95-5.47Z"/>
  <path id="Overlays3" class="Grund_Deckplatten1725Fill" style= {{
            fill: props.colors.Grund_Deckplatten1725Fill,
            stroke: props.colors.Grund_Deckplatten1725Fill,
            opacity:props.colors.Grund_Deckplatten1725Fill,
          }} d="M10.52,188.55c1.04-2.16,4.98-1.76,11.54-2.13,5.7-.32,2.41-.61,16.41-2.13,3.41-.37,7.54-.75,15.8-1.52,11.88-1.1,12.1-1.07,13.37-1.52,6.75-2.39,8.91-6.3,13.67-5.47,2.21.39,4.76,1.76,4.86,3.34.16,2.51-5.94,4.75-7.9,5.47-6.28,2.3-9.71,1.61-20.66,2.73-4.7.48-3.28.53-11.54,1.52-13.78,1.65-12.47.89-17.32,1.82-9.93,1.91-14.32,4.89-17.01,2.43-1.26-1.14-1.86-3.21-1.22-4.56Z"/>
  <path id="Overlays2" class="Grund_Deckplatten1725Fill" style= {{
            fill: props.colors.Grund_Deckplatten1725Fill,
            stroke: props.colors.Grund_Deckplatten1725Fill,
            opacity:props.colors.Grund_Deckplatten1725Fill,
          }} d="M31.48,252.65c2.53-1.06,6.1-2.6,10.33-4.56,5.38-2.49,13.58-6.3,23.7-12.15,9.25-5.36,13.88-8.04,16.41-10.33,5.43-4.93,8.57-9.84,13.37-9.42,1.87.16,4.15,1.16,4.56,2.73.55,2.12-2.41,4.67-7.29,8.2-7.95,5.75-14.37,9.31-18.84,11.85-7.12,4.05-11.73,6.68-17.92,10.63-5.01,3.2-10.5,5.59-15.49,8.81-2.82,1.82-5.58,3.73-8.81,3.04-2.29-.49-5.41-2.41-5.16-4.86.19-1.87,2.29-3.65,5.16-3.95Z"/>
  <path id="Overlays1" class="Grund_Deckplatten1725Fill" style= {{
            fill: props.colors.Grund_Deckplatten1725Fill,
            stroke: props.colors.Grund_Deckplatten1725Fill,
            opacity:props.colors.Grund_Deckplatten1725Fill,
          }} d="M54.57,291.84c-.64-4.31,5.59-6.81,14.28-15.19.28-.27,4.54-5.24,13.06-15.19,3.87-4.52,7.99-8.83,11.85-13.37,5.38-6.32,7.68-9.41,9.11-8.81,1.41.59,1.31,4.48.61,7.29-1.29,5.18-5.03,8.31-6.99,10.03-7.48,6.56-2.72,4.48-18.84,20.96-13.31,13.62-11.22,9.58-13.97,13.37-1.46,2-3.52,5.17-5.77,4.86-1.58-.21-3.07-2.1-3.34-3.95Z"/>
</g>
</svg>
  );
}

export default LumbosacralerUbergangLatLiegend1725;
