export default function isAutherized(keycloak, req_roles) {
  const isAllowed = () => {
    if (keycloak && req_roles) {
      return req_roles.some((r) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };

  return isAllowed();
}
