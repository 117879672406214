import { SET_ACTIVE_THEME } from "../actionTypes";

const initialState = {
  activeTheme: "classic",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_THEME: {
      const { content } = action.payload;
      return {
        activeTheme: content,
      };
    }
    default:
      return state;
  }
}
