import style from "./style.scss";

function HalswirbelsauleLinksAnliegendLatStehend1604(props) {
  return (
<svg id="HWSlianl_full" data-name="HWSlianl full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.13 407.7">

<g id="BG">
  <polygon id="BG_white" data-name="BG white" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls2" points="300.81 166.04 300.81 155.08 300.81 .44 76.8 .44 39.75 0 36.1 2.73 51.23 10.56 96.1 33.93 138.36 40.05 142.58 51.93 125.32 46.04 102.34 48.29 99.03 69.14 127.66 85.2 96.1 85.2 94.53 124.82 111.05 138.75 122.76 138.75 96.1 149.27 96.1 167.6 122.76 177.92 85.67 187.94 79.59 214.82 116.7 220.3 69.17 233.34 86.71 260.53 37.14 273.56 47.69 307.88 0 307.88 .54 341.5 27.75 353.15 .54 355.43 .54 407.7 137.8 407.62 161.58 407.7 173.32 401.86 197.24 353.15 216.51 287.43 233.47 181.95 238.53 148.47 236.62 104.58 240.62 82.68 254.71 138.75 298.01 165.52 300.81 166.04"/>
</g>
<g id="HWSlatanl">
  <g id="Intervertebralgelenke">
    <path id="Intervertebralgelenk_all" data-name="Intervertebralgelenk all" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M113.17,382.04l.66-1.66c1.08-4.99,4.23-9.84,8.93-14.58l18.95-38.27c-3.24-6.6-1.79-10.82,2.96-13.38l9.27-39.71c1.25-8.44,5.59-11.22,11.54-11.03l9.11-27.38c.66-10.09,2.24-17.33,5.47-19.41l5.85-28.71c-1.99-5.37-1.42-12.06,2.68-17.77v-30.57c-2.93-6-2.82-12.12,0-18.34l-11.97-29.47c-3.05-7.95-4.03-15.95-2.03-24l55.98-10.8c4.13,11.48,4.43,23.65-3,37.23l-3.16,6.68,3.16,27.95c7.48,19.67-7,29.08-6.11,48.78,1.4,10.45.25,19.16-4.21,25.61l-2.25,26.47c1.04,9.31-.77,16.77-11.85,18.24l-1.33,30.97c2.58,7.34.72,13.98-8.54,19.44l-7.36,29.17c1,9.44-1.28,15.78-7.98,17.91l-13.97,39.8c2,6.85,1.02,13.4-6.33,19.33"/>
  </g>
  <g id="Skull_dornfortsätze_Parts" data-name="Skull dornfortsätze Parts">
    <path id="Skull_dornfortsätze_Part15" data-name="Skull dornfortsätze Part15" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M228.83,8.51c-1.5,2.08-3.36,4.02-6.11,5.59-2.1,1.2-3.58,3.21-4.09,5.57-.58,2.67-1.87,5.16-3.63,7.52-3.08,4.25-7.68,5.71-13.1,5.68-3.11-.02-6.09,1.17-8.24,3.41-3.67,3.81-7.21,6.5-10.41,6.4-5.57.46-11.99-1.92-18.53-4.7-2.29-.98-4.76-1.49-7.24-1.42-5.04.13-9.86-.91-14.6-2.45-1.59-.52-3.26-.77-4.93-.72-5.35.16-10.29-.5-14.96-1.72-1.91-.5-3.89-.7-5.86-.56-12.86.94-24.67-2.72-35.62-10.04-2.69-1.8-7.26-4.48-10.12-6.01L44.1.44"/>
    <path id="Skull_dornfortsätze_Part14" data-name="Skull dornfortsätze Part14" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M231.25,8.33c-3.19,15.95,5.62,24.31,10.78,47.09,5.05,14.88,7.59,32.6,8.96,51.65,2.55,22.62,14.33,32.65,28.1,39.95,7.34,4.18,14.58,9.17,21.72,15.04"/>
    <path id="Skull_dornfortsätze_Part13" data-name="Skull dornfortsätze Part13" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M139.53,397.04h0l-58.83-25.75,10.37-18.91,13.52-5.34c1.91-.75,3.75-1.65,5.6-2.55,4.93-2.39,6.02,1.65,4.45,10.14-.59,3.2-1.58,6.31-2.64,9.39-2.25,6.51,1.24,9.08,7.44,9.79,15.39,7.06,18.29,15.11,20.09,23.24h0Z"/>
    <path id="Skull_dornfortsätze_Part12" data-name="Skull dornfortsätze Part12" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M152.25,333.62h0s-29.5-9.7-29.5-9.7l-6.04-9.11c6.47-2.55,13.55-7.27,21.12-13.73,1.84-1.57,4.67-.13,4.46,2.28-.24,2.77-1.75,6.36-4.16,10.53-.47,3.43.91,6.37,6.53,8.2,4.67,3.56,7.14,7.41,7.6,11.52h0Z"/>
    <path id="Skull_dornfortsätze_Part11" data-name="Skull dornfortsätze Part11" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M144.66,333.55c4.99-6.07,10.04-11.74,16.16-9.19,3.12.99,5.17,7.8,7.09,15.27l-23.25-6.08Z"/>
    <path id="Skull_dornfortsätze_Part10" data-name="Skull dornfortsätze Part10" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M158.8,335.31h0l-20.44-9.34c3.59-4.65,6.97-6.36,10.21-6.28,2.62.06,5.1,1.4,6.63,3.53,2.11,2.95,3.08,7.26,3.6,12.09h0Z"/>
    <path id="Skull_dornfortsätze_Part9" data-name="Skull dornfortsätze Part9" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M186.43,292.77h-.01l-19.63-2.95,9.84-9.65c5.49.92,8.79,5.07,9.8,12.59h0Z"/>
    <path id="Skull_dornfortsätze_Part8" data-name="Skull dornfortsätze Part8" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M180.97,291.93h0l-22.18-11.77c3.81-3.25,7.67-5.1,11.58-5.66,3.37-.48,6.82.89,8.79,3.67,2.38,3.35,3.13,7.83,1.81,13.77h0Z"/>
    <path id="Skull_dornfortsätze_Part7" data-name="Skull dornfortsätze Part7" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M201.01,247.91h.01l-7.75-5.44,1.59-5.44c6.35,1.77,8.2,5.48,6.15,10.87h0Z"/>
    <path id="Skull_dornfortsätze_Part6" data-name="Skull dornfortsätze Part6" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M192.36,244.84h0l-17.77-7.85v-7.41c4.13-1.23,9.9-2.39,13.13-2.42,9.15-.08,13.02,11.59,5.64,16.99-.33.24-.66.47-1,.7h0Z"/>
    <ellipse id="Skull_dornfortsätze_Part5" data-name="Skull dornfortsätze Part5" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" cx="212.98" cy="192.44" rx="3.53" ry="4.56"/>
    <path id="Skull_dornfortsätze_Part4" data-name="Skull dornfortsätze Part4" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M208.62,196.09h0l-20.81-3.65-7.75-9.38c3.66-3.24,7.83-5.32,12.84-5.48,1.33-.04,2.67.11,3.99.33,4.27.73,8.38,1.99,12.01,4.79,1.34,1.04,2.53,2.29,3.3,3.8,1.86,3.63.51,6.18-3.59,9.59h0Z"/>
    <circle id="Skull_dornfortsätze_Part3" data-name="Skull dornfortsätze Part3" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" cx="217.21" cy="145.03" r="4.24"/>
    <path id="Skull_dornfortsätze_Part2" data-name="Skull dornfortsätze Part2" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M192.36,137.76l20.62,10.71c2.18-1.9,3.47-4.02,4.03-6.32.67-2.73-.08-5.57-1.86-7.75-2.8-3.43-5.71-4.02-8.66-3.7-5.89.5-10.16,3.55-14.13,7.06h0Z"/>
    <path id="Skull_dornfortsätze_Part1" data-name="Skull dornfortsätze Part1" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M173.65,72.15h0c15.5-7.15,32.17-11.61,50.38-12.53l3.42,4.87c.65,7.84-1.28,12.63-7.1,12.82l-25.48-4.25c-3.54.43-7.2,1.95-10.95,4.41-2.17,1.42-5.05,1.6-7.27.36s-2.96-3.27-3-5.69h0Z"/>
  </g>
  <g id="HWS_Bodys" data-name="HWS Bodys">
    <path id="HWS_Body9" data-name="HWS Body9" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M176.41,330.51h.01c-10.92-9.68-21.08-15.78-29.47-13.67-7.17,2.27-5.05-4.27.68-14.24,1.2-2.1,1.59-4.63.79-6.92-1.25-3.6-4.4-4.54-10.28-1.62l-11.24,10.03c-6.84,3.86-11.49,4.92-13.37,2.43l-10.94-4.25-4.92-3.51c-2.21-1.59-5-2.13-7.66-1.53-7.49,1.69-14.02,1.8-18.4-1.64l-13.67-2.13c-6.74-1.68-11.34-5.49-10.03-15.19,1.33-5.33,5.46-5.97,11.54-3.34,13.24,4.47,26.09,2.36,38.58-6.08,6.12-5.61,11.42-6.86,16.1-4.86,4.1,3.91,11.5-.06,19.14-4.56,4.68-5.72,6.27-3.66,6.27,2.43,3.72,1.26,10.21-3.61,17.12-9.42,6.6-2.74,4.86,5.04-3.34,20.96,3.34.64,5.74,2.08,7.29,4.25,11.42,7.26,22.05,12.56,31.59,15.19,5.42,3.4,7.5,7.88,3.65,14.28l-4.25,21.27c-1.61,5.96-6.45,7.02-15.19,2.13h0Z"/>
    <path id="HWS_Body8" data-name="HWS Body8" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M151.79,386.73h0l-13.67-10.03c-7.72-.74-13.37-2.42-13.97-6.38-6.02-3.97-6.91-10.41-2.43-19.44.24-5.07-3.19-9.44-13.06-12.61-7.03-1.01-19.83,6.29-33.42,14.73-15.85,1.05-29.51-2.33-41.32-9.42l-31.29-10.03c-3.7-3.88-2.2-8.59,1.52-13.67,5.64-6.07,10.59-4.66,15.49-2.73,19.53,2.21,41.96-.64,66.23-6.68,7.33-3.29,15.22-4.45,23.7-3.34,3.09-6.33,8.91-12.07,17.01-17.32,5.47.4,4.86,7.69-1.82,21.87-.29,9.71,3.75,14.12,12.76,12.46,4.11-1.42,7.75.04,10.94,4.25l23.39,12.15c11.49,2.11,11.68,9.3,6.68,18.84l-5.77,22.78c-3.32,12.16-9.81,15.11-20.96,4.56h0Z"/>
    <path id="HWS_Body7" data-name="HWS Body7" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M.5,369.08c1.26-.15,2.58-.34,3.98-.58,19.98-2,38.51-3.67,52.25-4.25,10.68.08,18.78-4.2,24-13.37,5.49-8.21,12.25-14.35,20.96-17.32,4.55.15,2.38,8.09-2.73,19.44-3.83,9.6-2.76,18.52,9.72,25.82,6.91-.47,12.61.17,15.19,3.65l28.25,17.01c3.54,1.87,5.96,4.44,6.55,8.14"/>
    <path id="HWS_Body6" data-name="HWS Body6" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M196.15,279.47h.03c-6.8-5.71-13.28-8.49-19.44-8.2-11.05-1.35-16.55-5.04-12.46-12.76l2.13-10.62c-3.42-6.37-13.03-4.68-25.22.38-8.56,6.59-13.49,7.76-12.46,0-2.41-1.2-4.41-.06-5.96,3.63-4.24,4.14-8.54,4.2-12.88,1.76-4.21,1.23-7.95,1.35-10.33-1.76-4.1-3.49-9.28-6.02-15.8-7.36-4.21-3.3-3.89-6.76,0-10.33,9.64,1.37,20.23-1.55,31.59-7.9,4.13-6.01,11.99-6.29,21.57-3.95,5.05,2.19,9.93,1.09,14.58-4.25,4.4-7.78,9.78-11.26,15.19-14.58,5.4-1.66,5.58,2.75,1.22,12.46,4.63-3.63,5.71-.71,2.73,9.72,3.92-1.22,5.9,1.4,6.68,6.38l20.51,10.94c6.66,2.14,10.55,6.47,10.78,13.67v13.67c-.03,16.02-5.04,16.37-12.46,9.11h0Z"/>
    <path id="HWS_Body5" data-name="HWS Body5" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M211.65,232.71h0c-7.53-8.84-17.25-13.24-29.99-11.54-5.64,1.72-7-2.42-4.03-12.46,5.24-6.77,3.68-11.2,0-14.89-10.64,2.91-20.42,9.51-29.16,20.66-4-4.92-13.02-4.65-25.71-.61-6.22-2.57-11.8-3.1-16.22,0-3.81,2.64-5.83-.24-6.99-5.77-1.73-6.26,1.05-9.97,5.47-12.76,6.24,1.05,13.91-3.87,21.87-10.03,6.83-7.72,12.67-8.79,17.77-4.86,4.4,3.33,10.52.36,17.17-4.56l15.19-13.67c4.34-4.38,4.71,4.67,4.64,15.19-.69,3.41-.02,6.05,2.04,7.9,2.35-3.19,4.67-3.06,6.99-1.52l25.67,13.37c5.97,3.34,7.97,7.59,4.71,13.06-1.63,3.16-2,6.49-.86,10.02.6,1.88.86,3.86.78,5.84-.49,11.56-3.45,14.3-9.34,6.62h0Z"/>
    <path id="HWS_Body4" data-name="HWS Body4" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M204.19,119.38h0v.02c-3.19-3.34-6.05-4.46-8.2-.61l-12.91,10.94c-4.03,4.82-7.54,8.49-10.19,10.31-1.55,1.05-3.59,1-5.12-.07-3.21-2.24-6.7-4.15-11.12-4.93-4.65.23-11.77-1.41-19.29-3.34-8.07-3.58-16.1-5.44-24-2.89-4.65,1.64-8.03.6-8.2-7.29-3.01-3.97-3.68-7.92-1.67-11.85,1.28-2.49,1.14-6.23.76-10.18,2.97-8.62,12.55-10.44,29.47-4.71,4.71,2.05,9.35,3.9,13.67,4.71,6.4,1.08,14.2-2.68,23.09-10.18,3.85-4.26,8.37-6.18,13.37-6.38,5.54-4.42,11.43-4.95,17.62-2.13,2.37,1.65,5.02,1.13,7.9-1.22,6.98-1.53,11.99,1.28,15.34,7.75,6.11,4.25,8.8,9.16,4.25,15.49-4.47,3.07-8.52,6.43-11.54,10.48-4,7.18-8.4,9.33-13.22,6.08h0Z"/>
    <path id="HWS_Body3" data-name="HWS Body3" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M225.62,180.92h0v.03c-.21,1.94-2.52,2.84-3.98,1.55-4.37-3.86-11.23-6.32-20.2-7.58-3.63-.52-7.47-.66-10.96,0s-4.87-2.26-4.34-8.43c-.3-10.87-2.96-15.18-8.51-11.39-12.21,14.16-22.6,24.29-26.13,19.29l-6.84-6.08c-4.04-2.25-8.19-2.94-12.61.61-4.78-2.98-9.74-5.41-15.8-4.56-3.69,1.58-5.99,1.29-6.68-1.22-5.12-5.88,6.93-14.19,23.09-23.09,9.08-5.43,15.9-4.92,20.35,1.82,4.09,4.86,9,3.39,14.28-.91l11.85-17.32c5.14-3.53,6.63,2.88,7.29,11.54,5.12-5.96,7.27-2.13,9.11,2.73l23.7,10.33c4.63,2.56,7.77,7.16,8.2,15.49l-1.83,17.18h0Z"/>
    <path id="HWS_Body2" data-name="HWS Body2" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M221.46,64.14l-30.89,5.06c-1.16.2-2.3.59-3.27,1.27-3.75,2.63-11.49,4.36-22.59,5.32-14.69,4.26-25.15,5.42-30.26,2.62-1.1-.61-2.32-.98-3.57-1.17l-7.95-1.22c-7.05-1.08-12.33-7.3-11.85-14.42.06-.82.16-1.66.31-2.53,3.04-5.71,6.95-7.12,11.39-5.92,8.35,4.52,16.19,7.17,23.39,7.44,7.62.39,12.59-2.44,15.65-7.59,2.03-2.88-3.16-5.03-10.83-6.93-.73-.18-1.33-.79-1.33-1.54.01-1.93,3.78-2.4,9.08-2.29,1.94.04,3.84.5,5.65,1.21,5.77,2.25,12.27,2.57,19.3,1.49,13.1-2.42,19.87-5.31,24.76-8.35,8.26-5.86,15.04-1.32,21.34,6.53,4.85,4.67,7.32,9.29,6.15,13.82-1.76,5.69-5.12,8.35-10.45,7.32-1.33-.25-2.69-.34-4.03-.12h0Z"/>
    <path id="HWS_Body1" data-name="HWS Body1" class="Halswirbelsaule_links_anliegend_lat_stehend1604_cls1" d="M227.33,137.84h-.01c-.67-.45-1.25-1.01-1.78-1.61-8.97-9.99-18.89-12.72-29.86-7.44-5.24,1.59-6.6-13.62-5.46-39.67.08-1.77.4-3.53.82-5.25,1.99-8.08.22-19.42-2.37-31.47-.27-1.26.01-2.58.79-3.61l7.59-10.07c4.46-3.39,8.91-3.09,13.37,0,5.45,5.21,9,15.15,9.31,33.18.06,3.7.8,7.39,2.42,10.72,1.73,3.54,2.89,8.67,3.61,15.02.37,3.26,1.17,6.33,2.51,9.47s2.57,6.5,3.64,10.14c.72,2.44,1.12,4.97,1.23,7.51.48,10.85-1.07,16.26-5.81,13.09h0Z"/>
  </g>
</g>
<g id="wirbelkörper_highlights_one" data-name="wirbelkörper highlights one">
  <path id="wirbelkörper_highlight7" data-name="wirbelkörper highlight7" class="HWK1604Fill" style= {{
            fill: props.colors.HWK1604Fill,
            stroke: props.colors.HWK1604Fill,
            opacity:props.colors.HWK1604Fill,
          }} d="M176.6,330.67h.01c-10.92-9.68-21.08-15.78-29.47-13.67-7.17,2.27-5.05-4.27.68-14.24,1.2-2.1,2.23-7.24-1.1-9.59,1.34-3.35,2.42-12.82,6.79-19.61,3.34.64,5.74,2.08,7.29,4.25,11.42,7.26,22.05,12.56,31.59,15.19,5.42,3.4,7.5,7.88,3.65,14.28l-4.25,21.27c-1.61,5.96-6.45,7.02-15.19,2.13h0Z"/>
  <path id="wirbelkörper_highlight6" data-name="wirbelkörper highlight6" class="HWK1604Fill" style= {{
            fill: props.colors.HWK1604Fill,
            stroke: props.colors.HWK1604Fill,
            opacity:props.colors.HWK1604Fill,
          }} d="M152.19,386.89h0l-13.67-10.03c-7.72-.74-13.37-2.42-13.97-6.38-6.02-3.97-6.91-10.41-2.43-19.44,3.4-4.41,13.31-18.63,15.8-26.74,4.11-1.42,7.75.04,10.94,4.25l23.39,12.15c11.49,2.11,11.68,9.3,6.68,18.84l-5.77,22.78c-3.32,12.16-9.81,15.11-20.96,4.56h-.01Z"/>
  <path id="wirbelkörper_highlight5" data-name="wirbelkörper highlight5" class="HWK1604Fill" style= {{
            fill: props.colors.HWK1604Fill,
            stroke: props.colors.HWK1604Fill,
            opacity:props.colors.HWK1604Fill,
          }} d="M196.55,279.63h.03c-6.8-5.71-13.28-8.49-19.44-8.2-11.05-1.35-16.55-5.04-12.46-12.76l1.74-11.01c2.35-6.39,6.64-18.08,8.13-21.6,1.09.58,1.9,1.1,3.17,6.19l20.51,10.94c6.66,2.14,10.55,6.47,10.78,13.67v13.67c-.03,16.02-5.04,16.37-12.46,9.11h0Z"/>
  <path id="wirbelkörper_highlight4" data-name="wirbelkörper highlight4" class="HWK1604Fill" style= {{
            fill: props.colors.HWK1604Fill,
            stroke: props.colors.HWK1604Fill,
            opacity:props.colors.HWK1604Fill,
          }} d="M212.05,232.87h0c-7.53-8.84-17.25-13.24-29.99-11.54-5.64,1.72-7-2.42-4.03-12.46,5.24-6.77,3.33-11.4-.35-15.09,2.29-3.35,3.47-6.66,6.07-8.4,2.35-3.19,5.02-2.97,7.34-1.43l25.67,13.37c5.97,3.34,7.97,7.59,4.71,13.06-1.63,3.16-2,6.49-.86,10.02.6,1.88.86,3.86.78,5.84-.49,11.56-3.45,14.3-9.34,6.62h0Z"/>
  <path id="wirbelkörper_highlight3" data-name="wirbelkörper highlight3" class="HWK1604Fill" style= {{
            fill: props.colors.HWK1604Fill,
            stroke: props.colors.HWK1604Fill,
            opacity:props.colors.HWK1604Fill,
          }} d="M204.59,119.54h0v.02c-3.19-3.34-6.05-4.46-8.2-.61l-12.91,10.94c-7.91,1.95-4.98-47.88.77-46.8,5.54-4.42,11.43-4.95,17.62-2.13,2.37,1.65,5.02,1.13,7.9-1.22,6.98-1.53,11.99,1.28,15.34,7.75,6.11,4.25,8.8,9.16,4.25,15.49-4.47,3.07-8.52,6.43-11.54,10.48-4,7.18-8.4,9.33-13.22,6.08h-.01Z"/>
  <path id="wirbelkörper_highlight2" data-name="wirbelkörper highlight2" class="HWK1604Fill" style= {{
            fill: props.colors.HWK1604Fill,
            stroke: props.colors.HWK1604Fill,
            opacity:props.colors.HWK1604Fill,
          }} d="M226.02,181.08h0v.03c-.21,1.94-2.52,2.84-3.98,1.55-4.37-3.86-11.23-6.32-20.2-7.58-3.63-.52-7.47-.66-10.96,0s-4.87-2.26-4.34-8.43c-1.1-10.9-2.79-14.4-6.78-12.65,3.12-10.54,3.38-12.58,7.07-18.66,5.12-5.96,7.27-2.13,9.11,2.73l23.7,10.33c4.63,2.56,7.77,7.16,8.2,15.49l-1.83,17.18h.01Z"/>
  <path id="wirbelkörper_highlight1" data-name="wirbelkörper highlight1" class="HWK1604Fill" style= {{
            fill: props.colors.HWK1604Fill,
            stroke: props.colors.HWK1604Fill,
            opacity:props.colors.HWK1604Fill,
          }} d="M221.86,64.3l-33.28,5.48c-1.01-5.51-4.01-12.84-4.49-24.68,13.1-2.42,19.87-5.31,24.76-8.35,8.26-5.86,15.04-1.32,21.34,6.53,4.85,4.67,7.32,9.29,6.15,13.82-1.76,5.69-5.12,8.35-10.45,7.32-1.33-.25-2.69-.34-4.03-.12h0Z"/>
</g>
<g id="Dornfortsätze_Highlights" data-name="Dornfortsätze Highlights">
  <path id="Dornfortsätze_Highlight7" data-name="Dornfortsätze Highlight7" class="Dornfortsatz1604Fill" style= {{
            fill: props.colors.Dornfortsatz1604Fill,
            stroke: props.colors.Dornfortsatz1604Fill,
            opacity:props.colors.Dornfortsatz1604Fill,
          }} d="M137.57,324.1c2.55.61-16.75,30.61-15.8,26.74.24-5.07-3.19-9.44-13.06-12.61-7.03-1.01-19.83,6.29-33.42,14.73-15.85,1.05-29.51-2.33-41.32-9.42l-31.29-10.03c-3.7-3.88-2.2-8.59,1.52-13.67,5.64-6.07,10.59-4.66,15.49-2.73,19.53,2.21,41.96-.64,66.23-6.68,7.33-3.29,15.22-4.45,23.7-3.34,3.09-6.33,8.91-12.07,17.01-17.32,5.47.4,4.86,7.69-1.82,21.87-.29,9.71,3.75,14.12,12.76,12.46h0Z"/>
  <path id="Dornfortsätze_Highlight6" data-name="Dornfortsätze Highlight6" class="Dornfortsatz1604Fill" style= {{
            fill: props.colors.Dornfortsatz1604Fill,
            stroke: props.colors.Dornfortsatz1604Fill,
            opacity:props.colors.Dornfortsatz1604Fill,
          }} d="M153.72,273.54c-4.4,4.81-6.59,21.65-7.39,19.36-3.06-.53-2.41-1-8.29,1.92l-10.06,9.42c-6.84,3.86-12.52,4.73-14.4,2.24l-10.94-4.25-4.92-3.51c-2.21-1.59-5-2.13-7.66-1.53-7.49,1.69-14.02,1.8-18.4-1.64l-13.67-2.13c-6.74-1.68-11.34-5.49-10.03-15.19,1.33-5.33,5.46-5.97,11.54-3.34,13.24,4.47,26.09,2.36,38.58-6.08,6.12-5.61,11.42-6.86,16.1-4.86,4.1,3.91,11.5-.06,19.14-4.56,4.68-5.72,6.27-3.66,6.27,2.43,3.72,1.26,9.79-3.67,16.7-9.48,6.35-3.31,6.79,3.47-2.57,21.22v-.02Z"/>
  <path id="Dornfortsätze_Highlight5" data-name="Dornfortsätze Highlight5" class="Dornfortsatz1604Fill" style= {{
            fill: props.colors.Dornfortsatz1604Fill,
            stroke: props.colors.Dornfortsatz1604Fill,
            opacity:props.colors.Dornfortsatz1604Fill,
          }} d="M174.55,225.9l-8.13,21.76c-3.38-6.18-12.99-4.49-25.18.57-8.56,6.59-13.49,7.76-12.46,0-2.41-1.2-4.41-.06-5.96,3.63-4.24,4.14-8.54,4.2-12.88,1.76-4.21,1.23-7.95,1.35-10.33-1.76-4.1-3.49-9.28-6.02-15.8-7.36-4.21-3.3-3.89-6.76,0-10.33,9.64,1.37,20.23-1.55,31.59-7.9,4.13-6.01,11.99-6.29,21.57-3.95,5.05,2.19,9.93,1.09,14.58-4.25,4.4-7.78,9.78-11.26,15.19-14.58,5.4-1.66,5.58,2.75,1.22,12.46,4.63-3.63,5.71-.71,2.73,9.72,3.92-1.22,3.38.5,3.86.23h0Z"/>
  <path id="Dornfortsätze_Highlight4" data-name="Dornfortsätze Highlight4" class="Dornfortsatz1604Fill" style= {{
            fill: props.colors.Dornfortsatz1604Fill,
            stroke: props.colors.Dornfortsatz1604Fill,
            opacity:props.colors.Dornfortsatz1604Fill,
          }} d="M183.75,185.27c-2.02,1.56-2.36,2.67-6.07,8.51-12.06,3.95-20.42,9.51-29.16,20.66-4-4.92-13.02-4.65-25.71-.61-6.22-2.57-11.8-3.1-16.22,0-3.81,2.64-5.83-.24-6.99-5.77-1.73-6.26,1.05-9.97,5.47-12.76,6.24,1.05,13.91-3.87,21.87-10.03,6.83-7.72,12.67-8.79,17.77-4.86,4.4,3.33,10.52.36,17.17-4.56l15.19-13.67c4.34-4.38,4.71,4.67,4.64,15.19-.69,3.41-.02,6.05,2.04,7.9h0Z"/>
  <path id="Dornfortsätze_Highlight3" data-name="Dornfortsätze Highlight3" class="Dornfortsatz1604Fill" style= {{
            fill: props.colors.Dornfortsatz1604Fill,
            stroke: props.colors.Dornfortsatz1604Fill,
            opacity:props.colors.Dornfortsatz1604Fill,
          }} d="M186.48,135.14c-2.73,5.81-5.3,13.06-6.72,18.85-15.34,16.55-24.68,25.36-28.21,20.36l-6.84-6.08c-4.04-2.25-8.19-2.94-12.61.61-4.78-2.98-9.74-5.41-15.8-4.56-3.69,1.58-5.99,1.29-6.68-1.22-5.12-5.88,6.93-14.19,23.09-23.09,9.08-5.43,15.9-4.92,20.35,1.82,4.09,4.86,9,3.39,14.28-.91l11.85-17.32c5.14-3.53,6.63,2.88,7.29,11.54h0Z"/>
  <path id="Dornfortsätze_Highlight2" data-name="Dornfortsätze Highlight2" class="Dornfortsatz1604Fill" style= {{
            fill: props.colors.Dornfortsatz1604Fill,
            stroke: props.colors.Dornfortsatz1604Fill,
            opacity:props.colors.Dornfortsatz1604Fill,
          }} d="M183.9,82.89c-3.88-3.9-8.85,47.25-.77,46.8-4.03,4.82-7.54,8.49-10.19,10.31-1.55,1.05-3.59,1-5.12-.07-3.21-2.24-6.7-4.15-11.12-4.93-4.65.23-11.77-1.41-19.29-3.34-8.07-3.58-16.1-5.44-24-2.89-4.65,1.64-8.03.6-8.2-7.29-3.01-3.97-3.68-7.92-1.67-11.85,1.28-2.49,1.14-6.23.76-10.18,2.97-8.62,12.55-10.44,29.47-4.71,4.71,2.05,9.35,3.9,13.67,4.71,6.4,1.08,14.2-2.68,23.09-10.18,3.85-4.26,8.37-6.18,13.37-6.38h0Z"/>
  <path id="Dornfortsätze_Highlight1" data-name="Dornfortsätze Highlight1" class="Dornfortsatz1604Fill" style= {{
            fill: props.colors.Dornfortsatz1604Fill,
            stroke: props.colors.Dornfortsatz1604Fill,
            opacity:props.colors.Dornfortsatz1604Fill,
          }} d="M183.74,44.9c1.15,12.04,4.83,24.88,4.83,24.88-1.16.2-.25-.03-1.22.65-3.75,2.63-11.49,4.36-22.59,5.32-14.69,4.26-25.15,5.42-30.26,2.62-1.1-.61-2.32-.98-3.57-1.17l-7.95-1.22c-7.05-1.08-12.33-7.3-11.85-14.42.06-.82.16-1.66.31-2.53,3.04-5.71,6.95-7.12,11.39-5.92,8.35,4.52,16.19,7.17,23.39,7.44,7.62.39,12.59-2.44,15.65-7.59,2.03-2.88-3.16-5.03-10.83-6.93-.73-.18-1.33-.79-1.33-1.54,0-1.93,3.78-2.4,9.08-2.29,1.94.04,3.84.5,5.65,1.21,5.77,2.25,12.27,2.57,19.3,1.49h0Z"/>
</g>
<g id="_7HWK_Highlights" data-name=" 7HWK Highlights">
  <path id="_7HWK_Highlight1" data-name=" 7HWK Highlight1" class="HWK7th_1602Fill" style= {{
            fill: props.colors.HWK7th_1602Fill,
            stroke: props.colors.HWK7th_1602Fill,
            opacity:props.colors.HWK7th_1602Fill,
          }} d="M137.57,324.25c2.55.61-16.75,30.61-15.8,26.74.24-5.07-3.19-9.44-13.06-12.61-7.03-1.01-19.83,6.29-33.42,14.73-15.85,1.05-29.51-2.33-41.32-9.42l-31.29-10.03c-3.7-3.88-2.2-8.59,1.52-13.67,5.64-6.07,10.59-4.66,15.49-2.73,19.53,2.21,41.96-.64,66.23-6.68,7.33-3.29,15.22-4.45,23.7-3.34,3.09-6.33,8.91-12.07,17.01-17.32,5.47.4,4.86,7.69-1.82,21.87-.29,9.71,3.75,14.12,12.76,12.46h0Z"/>
</g>
<g id="Deck_u_Bodenplatten_Highlights" data-name="Deck u Bodenplatten Highlights">
  <path id="Deck_u_Bodenplatten_Highlight14" data-name="Deck u Bodenplatten Highlight14" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M190.09,66.71c-1.08.11-2,.83-2,2,0,1,.92,2.11,2,2,8-.8,15.96-2.12,23.78-4.01,1.91-.46,3.87-1.17,5.84-1.28,1.86-.11,3.65.51,5.5.53,3.39.04,6.73-1.47,8.98-3.99,1.71-1.92-1.11-4.75-2.83-2.83-2.2,2.46-5.03,3.09-8.2,2.59-1.85-.29-3.55-.39-5.41-.06-1.82.31-3.6.86-5.4,1.29-7.33,1.75-14.76,3-22.25,3.76h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight13" data-name="Deck u Bodenplatten Highlight13" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M183.68,83.75c-1.29,2.24,2.17,4.25,3.45,2.02.64-1.12,1.77-1.71,2.94-2.17,1.38-.55,2.91-1.12,4.39-1.32,3.02-.4,5.91,1.49,8.87,1.86,1.56.2,3.07,0,4.49-.7,1.3-.64,2.35-1.59,3.78-1.96,2.4-.62,5.06.02,6.88,1.7,1.89,1.74,4.72-1.08,2.83-2.83-2.48-2.3-5.84-3.42-9.2-3.01-1.68.2-3.24.74-4.67,1.64-1.48.94-2.55,1.48-4.33,1.09-1.69-.38-3.29-1.07-4.98-1.47-1.88-.45-3.72-.46-5.61,0-3.25.79-7.08,2.08-8.84,5.14h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight12" data-name="Deck u Bodenplatten Highlight12" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M182.86,130.09c4.29.29,7.65-2.77,10.35-5.72,2-2.19,5.11-5.93,8.51-4.85,1.68.53,3.06,1.8,4.72,2.42,1.78.66,3.67.51,5.38-.26,3.75-1.68,5.16-5.55,7.34-8.72,1.93-2.82,4.85-4.96,8.06-6.08,2.42-.85,1.38-4.71-1.06-3.86-3.95,1.38-7.36,3.8-9.89,7.14-1.93,2.55-3.26,7.22-6.71,8.12-1.59.41-2.96-.69-4.25-1.45-1.57-.92-3.29-1.6-5.14-1.48-3.78.24-6.84,2.93-9.27,5.61-2.11,2.32-4.59,5.36-8.04,5.13-2.57-.17-2.56,3.83,0,4Z"/>
  <path id="Deck_u_Bodenplatten_Highlight11" data-name="Deck u Bodenplatten Highlight11" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M185.35,134.77c-.42,1-.31,2.14.72,2.74.85.49,2.32.27,2.74-.72.26-.6.92-2.63,1.85-2.31.53.19.99,1.22,1.27,1.66.46.72.94,1.44,1.53,2.08,2.63,2.85,6.97,4.01,10.45,5.44,4.08,1.68,8.21,3.29,12.26,5.06,3.26,1.43,6.73,3.4,7.41,7.21.19,1.07,1.49,1.67,2.46,1.4,1.11-.3,1.59-1.4,1.4-2.46-.71-3.98-3.88-6.9-7.32-8.69-3.41-1.78-7.11-3.07-10.66-4.53-3.55-1.46-7.27-2.72-10.68-4.48-1.33-.69-2.4-1.51-3.22-2.77-.8-1.22-1.57-2.54-2.84-3.33-3.28-2.03-6.14.82-7.37,3.7h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight10" data-name="Deck u Bodenplatten Highlight10" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M185.59,171.48c-.83.83-.67,1.98,0,2.83,2.15,2.74,6.61,1.92,9.65,2.08,3.62.19,7.2.7,10.7,1.66,3.49.95,6.78,2.35,9.83,4.28,3.04,1.91,6.52,3.75,10.02,1.75,2.24-1.27.22-4.73-2.02-3.45-2.54,1.46-5.9-1.8-7.99-2.94-2.88-1.58-6-2.77-9.18-3.61-3.29-.86-6.67-1.36-10.06-1.61-1.57-.12-3.14-.18-4.72-.18-1.16,0-2.6.2-3.39-.81-.67-.85-2.14-.69-2.83,0h-.01Z"/>
  <path id="Deck_u_Bodenplatten_Highlight9" data-name="Deck u Bodenplatten Highlight9" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M186.01,182.05c-1.9.3-3.44,1.57-3.47,3.6-.04,2.44,3.53,2.56,3.95.39.16-.03.34-.09.5-.09.76-.03,1.55.27,2.26.5,1.73.55,3.38,1.33,5,2.15,3.54,1.78,7.05,3.61,10.57,5.41,3.64,1.86,7.27,3.72,10.9,5.58,1.32.68,2.8,1.33,3.63,2.62.86,1.34.92,3.01.87,4.54-.08,2.58,3.92,2.57,4,0,.07-2.18-.2-4.47-1.31-6.39-1.05-1.82-2.77-2.98-4.59-3.93-4.4-2.3-8.83-4.53-13.25-6.79-4.29-2.2-8.53-4.66-13.02-6.44-1.89-.76-3.97-1.5-6.03-1.17v.02Z"/>
  <path id="Deck_u_Bodenplatten_Highlight8" data-name="Deck u Bodenplatten Highlight8" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M175.26,217.41c-.82.82-.68,1.99,0,2.83,2.35,2.92,6.37,3.05,9.82,3.01,7.09-.08,13.86,1.74,19.76,5.73,1.47,1,2.85,2.12,4.13,3.35s2.38,2.65,3.79,3.75c2.56,2,6.81,2.34,8.57-.92,1.23-2.27-2.23-4.29-3.45-2.02-.76,1.4-2.73-.2-3.4-.86-1.45-1.43-2.77-2.96-4.33-4.27-3.16-2.66-6.69-4.74-10.53-6.26-3.78-1.5-7.84-2.34-11.9-2.5-3.03-.12-7.46.85-9.64-1.85-.68-.85-2.13-.7-2.83,0h.01Z"/>
  <path id="Deck_u_Bodenplatten_Highlight7" data-name="Deck u Bodenplatten Highlight7" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M175.8,225.44c-2.52-.5-3.58,3.36-1.06,3.86.88.17,1.14,1.63,1.43,2.36.58,1.43,1.3,2.66,2.49,3.67,2.75,2.34,6.35,3.93,9.55,5.55,3.48,1.76,7.03,3.35,10.64,4.82,2.67,1.08,6.91,2.56,7.36,5.94.14,1.08.8,2,2,2,.98,0,2.14-.93,2-2-.56-4.21-3.62-6.9-7.34-8.57-3.79-1.69-7.66-3.18-11.39-5.02-1.95-.96-3.87-1.97-5.77-3.02-1.5-.83-3.22-1.62-4.51-2.77-2.27-2.02-1.79-6.1-5.4-6.81h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight6" data-name="Deck u Bodenplatten Highlight6" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M165.52,261.94c-.68-2.49-4.54-1.43-3.86,1.06,1.02,3.73,3.95,6.57,7.38,8.21,3.59,1.71,7.52,2.18,11.39,2.92,3.75.72,7.36,1.91,10.75,3.68,1.71.9,3.36,1.91,4.94,3.03,1.53,1.09,2.91,2.46,4.52,3.44,2.83,1.71,7.19,1.31,8.19-2.35.68-2.49-3.18-3.54-3.86-1.06-.35,1.3-2.6-.2-3.08-.56-1.67-1.26-3.24-2.62-4.99-3.77-3.48-2.29-7.32-4.09-11.32-5.28-4.28-1.27-8.82-1.46-13.06-2.91-3.07-1.04-6.11-3.13-7-6.4h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight5" data-name="Deck u Bodenplatten Highlight5" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M154.13,272c-2.57-.16-2.56,3.84,0,4,1.88.12,3.58,1.84,5.04,2.87,1.77,1.25,3.58,2.43,5.42,3.57,3.8,2.34,7.68,4.58,11.61,6.69,1.89,1.01,3.82,1.95,5.84,2.68,2.06.75,4.22,1.17,6.32,1.79,2.84.84,7.33,3.02,6.46,6.75-.58,2.5,3.27,3.57,3.86,1.06.91-3.9-1.32-7.54-4.53-9.65-3.05-2-6.8-2.41-10.19-3.51-3.67-1.19-7.01-3.25-10.35-5.15-3.36-1.91-6.7-3.85-9.93-5.98-2.96-1.95-5.82-4.88-9.54-5.11h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight4" data-name="Deck u Bodenplatten Highlight4" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M143.23,313.45c-1.08-.12-2,1.01-2,2,0,1.17.93,1.88,2,2,8.8,1,17.36,3.98,24.93,8.58,1.91,1.16,3.77,2.42,5.54,3.79,1.6,1.23,3.14,2.58,4.92,3.56,1.96,1.08,4.17,1.73,6.43,1.49,2.48-.27,4.72-1.26,6.95-2.3.99-.46,1.23-1.87.72-2.74-.58-.99-1.76-1.18-2.74-.72-2.04.95-4.38,2.09-6.7,1.76-1.86-.27-3.49-1.41-4.96-2.5-3.38-2.52-6.65-5.05-10.35-7.1-7.66-4.24-16.07-6.86-24.75-7.84v.02Z"/>
  <path id="Deck_u_Bodenplatten_Highlight3" data-name="Deck u Bodenplatten Highlight3" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M139.13,321.73c-2.43-.87-3.46,3-1.06,3.86,7.67,2.76,14.72,6.91,21.78,10.92,3.55,2.02,7.12,4.01,10.8,5.8,1.77.86,3.57,1.62,5.36,2.42,1.21.55,2.92,1.24,3.43,2.59.38,1.02,1.34,1.71,2.46,1.4.98-.27,1.78-1.45,1.4-2.46-1.48-3.94-5.57-5.03-9.05-6.65-3.97-1.85-7.81-3.96-11.62-6.12-7.63-4.32-15.22-8.77-23.5-11.75h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight2" data-name="Deck u Bodenplatten Highlight2" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M121.16,360.65c0-2.58-4-2.57-4,0,.02,5.03,3.09,9.09,6.22,12.75,1.43,1.67,2.9,3.12,5.01,3.88,1.88.68,3.9.72,5.84,1.16,4.03.92,7.47,3.37,10.62,5.95,6.43,5.27,13.52,12.14,22.58,11.02,1.08-.13,2-.81,2-2,0-.99-.93-2.13-2-2-4.56.56-8.65-1.34-12.33-3.87-6.36-4.36-11.5-10.77-19.23-12.81-1.8-.48-3.68-.55-5.49-1.02-2-.52-3.16-1.93-4.45-3.45-2.31-2.73-4.77-5.86-4.78-9.61h.01Z"/>
  <path id="Deck_u_Bodenplatten_Highlight1" data-name="Deck u Bodenplatten Highlight1" class="Platten1604Fill" style= {{
            fill: props.colors.Platten1604Fill,
            stroke: props.colors.Platten1604Fill,
            opacity:props.colors.Platten1604Fill,
          }} d="M184.4,45.79c-.47,2.52,3.38,3.59,3.86,1.06.2-1.06,1.84-1.22,2.72-1.4,1.76-.36,3.52-.67,5.28-1.03,3.52-.72,7-1.66,10.24-3.24,3.07-1.5,5.78-3.9,9.06-4.94,2.34-.74,5.11-.7,6.8,1.29,1.67,1.96,4.49-.88,2.83-2.83-2.33-2.75-6.15-3.3-9.52-2.62-3.87.78-6.99,3.43-10.4,5.24-3.74,1.98-7.95,2.84-12.06,3.63-3.09.59-8.07.94-8.81,4.83h0Z"/>
</g>
</svg>
  );
}

export default HalswirbelsauleLinksAnliegendLatStehend1604;
