import style from "./style.scss";

function RechteMittelhandDpSitzend1332(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 386.5 482.8">

<g id="hand">
  <g id="fore_arm_hand_joints" data-name="fore arm hand joints">
    <path id="fore_arm_hand_joints_r" data-name="fore arm hand joints r" class="mittelhand1332_links_dp_cls-4" d="M237.7,480.8l1.2-9.3c.7-5.1.2-10.3-1.6-15.2-2.7-7.5-6.1-13.9-10.4-19-2.8-3.3-4-7.7-3.6-12,1-10.1,5-17.9,12.1-23.3,1.5-1.1,3.3-1.6,5.2-1.5,6,.5,13.6,2.4,21.6,4.7,2.4.7,4.9-.5,5.8-2.8,2.2-5.2,3.7-6.6,5.1-7.1,6.5.9,10.9,4.5,10.7,13.9,0,2.1-.6,4.1-1.4,6-4.7,11.1-7.8,23.6-9.3,37.6l-.6,27.8"/>
    <path id="fore_arm_hand_joints_l" data-name="fore arm hand joints l" class="mittelhand1332_links_dp_cls-4" d="M126.4,482.7c1.2-26.7-3.4-44.2-11.2-56.9-2.2-3.5-3.3-7.7-3.2-11.8.3-10.4,1.4-19.5,3.9-26,2.1-5.4,8.6-7.6,13.7-4.9,4.2,2.2,9.8,6,21.5,10.2,2.2.8,4.6,1.1,7,.9,18.6-1.9,37.7-2.5,57.1-2.2,2.4,0,4.7,1.1,6.2,2.9,4.6,5.7,6.5,12.6.8,23-13.5,18.9-16.8,41.6-18.7,64.8"/>
  </g>
  <g id="wrist_bones" data-name="wrist bones">
    <path id="wrist_bones_8" data-name="wrist bones 8" class="mittelhand1332_links_dp_cls-4" d="M257.6,351.3h0c6.5-6.4,12.8-12.5,14.8-15.5,2.8-4.2,2.4-11.9,1.6-20.2,9,3.8,15.9,9.3,7,28.1-9.7,16-16.9,13.5-23.4,7.6h0Z"/>
    <path id="wrist_bones_7" data-name="wrist bones 7" class="mittelhand1332_links_dp_cls-4" d="M176,380h0c4.2-4.8,4.8-13.6,5.3-22.4,4.7-9.7,14.4-17,37.8-11.8,6.8,2.2,11.8,6.7,17.5,17.5-8.5,8.9-17.2,16.7-26.3,22.3-1.3.8-2.8,1.3-4.3,1.5-12.6,1.3-30.8-3.8-37,1.2-4.3,3.5,5.3-7.4,7-8.2h0,0Z"/>
    <path id="wrist_bones_6" data-name="wrist bones 6" class="mittelhand1332_links_dp_cls-4" d="M136.9,319.3h0c-13.9-8.4-14.2-20.6-7-35.1,12.9-18,23.4-18,31.9-3.5,6.5,2.6,11.7,2.8,14.2-1.9-4.1,10.8-5,23.9-13.3,31.9,0,5.1-12.9,6.8-25.9,8.5h0q0,0,0,0Z"/>
    <path id="wrist_bones_5" data-name="wrist bones 5" class="mittelhand1332_links_dp_cls-4" d="M171.6,387h0c-1.5,1.6-3.7,2.5-5.9,2.6-19.9.4-36-5.7-40.6-30.7-17.2-3.8-21.3-12.8-1.3-30.9,0-3.1,9.9-6.7,20.2-9.6,6.1-1.8,12.3-3.5,18.7-4.9-4.8,19.1-8.8,37.8,19.8,41.8,1,11.8-2.4,22.4-10.9,31.6h0Z"/>
    <path id="wrist_bones_4" data-name="wrist bones 4" class="mittelhand1332_links_dp_cls-4" d="M214.2,343.6c-12.3-16.8-12.8-40.5,15.9-68.8,5.3-6.6,50.1-2.3,49,23.7-.2,13.2-54.5,46.1-64.9,45.1h0Z"/>
    <path id="wrist_bones_3" data-name="wrist bones 3" class="mittelhand1332_links_dp_cls-4" d="M239.7,364.9c-2.7-9.7-17.1-17.3-15.8-22.1,20.6-6.8,36.9-28.2,51.8-27.1,8.2,16.6-9.3,25.8-16.5,34.7-9,10.8-14.5,17.5-19.5,14.6h0Z"/>
    <path id="wrist_bones_2" data-name="wrist bones 2" class="mittelhand1332_links_dp_cls-4" d="M137.8,320h0c-18.4,7.1-33.7,18-53.1,6.4-4-14-3.2-24.9,5-31,12.2-1,16.7-16.2,14.5-19.9,0,0,6.4-7.6,10.1-2.5,3.1,7.1,7.8,11.8,15.5,11.4-5.3,11.5-10.2,23.6,7.9,35.7h0Z"/>
    <path id="wrist_bones_1" data-name="wrist bones 1" class="mittelhand1332_links_dp_cls-4" d="M181.5,355.3h0c-24.8-2.8-25.3-20.1-19.6-41l.9-3.5c5.3-4,9.6-16.3,13.3-31.9,6.4-6.8,13-11.1,19.9-13.6l15.1,6.7c3.9-.7,6.4-3.6,7.9-7.9,2.5,5.9,5.9,9.7,10.4,11.5-25.5,24.1-28.8,46.6-15.1,68-13.5.6-22.5.4-32.7,11.8h0Z"/>
  </g>
  <g id="thumb_bones" data-name="thumb bones">
    <path id="thumb_bones_2" data-name="thumb bones 2" class="mittelhand1332_links_dp_cls-4" d="M.9,76.2c1.7,6.3,4,12.6,6.6,19.2,6,20.9,10.2,39.9,8.5,53.1-3.4,9.5-2.8,17.3,9.8,20.2,5.6-9.6,12.2-15.7,21.2-12.9h0c5.4-3.1,9-7.6,8.9-14.9-1.3-6.3-10.5-12.9-13-18.1-5.5-13.7-8.5-31.7-10.7-51-.2-1.7.2-3.4,1.2-4.8,4.2-5.8,3.5-10.9.5-15.5"/>
  </g>
  <g id="ringer_finger_bones" data-name="ringer finger bones">
    <path id="ringer_finger_bones_3" data-name="ringer finger bones 3" class="mittelhand1332_links_dp_cls-4" d="M313.7,15.6c-1.8,12.9-4.6,24.5-8.8,34.1-.7,1.7-.9,3.5-.4,5.3,2.7,10,4.6,18.8,2.7,20.9-1.8,6.8-4.2,10.4-7.6,8.9-10.1-7.8-21.3-8.5-33.8-1.9-6.5,1-10.3-3-10.5-13.5,0-3.9,1.8-7.6,5.1-9.5,0,0,0,0,0,0,1.8-1,3.2-2.6,3.5-4.6,1.8-10.2,9.1-23.9,15.1-44.7"/>
  </g>
  <g id="middlefinger_bones" data-name="middlefinger bones">
    <path id="middlefinger_bones_3" data-name="middlefinger bones 3" class="mittelhand1332_links_dp_cls-4" d="M198.3.2c-2.8,13-7.2,25.4-13.6,37.1,0,11.7,3.5,16,10.1,13.6,12.9-7.2,23.7-8.6,31.6-2.5,6,4.8,10.4,5.1,12.9.6,3-7.7-1.7-18.7-8.2-30.4l.4-18.5"/>
  </g>
  <g id="index_finger_bones" data-name="index finger bones">
    <path id="index_finger_bones_3" data-name="index finger bones 3" class="mittelhand1332_links_dp_cls-4" d="M142.4,5.8c1.5,11.3,4,21.4,8.4,29.1.7,10.2-1.3,17.2-7,19.6-10.3-3.6-21.5-3.5-34.8,5.7h0c-9.9-5-7.2-22.4,1.3-45.4V5.1"/>
  </g>
  <g id="small_finger_bones" data-name="small finger bones">
    <path id="small_finger_bones_3" data-name="small finger bones 3" class="mittelhand1332_links_dp_cls-6" d="M385.8,49.4c-8.7,20.3-17.2,44.7-21.7,53.1-1.4,2.5-2.1,5.3-2.2,8.2,0,9-1.9,19.1-3.6,20.1-2,2.1-11.9,1.9-10.4-3.1h0c-4.2-5.5-9.7-11.2-14.5-7.6-.5.4-1.1.6-1.8.7-3.9.6-6.9-3.4-5.5-7.1,5.8-15,34-39.5,40.1-71.9"/>
  </g>
  <g id="middle_hand_bones" data-name="middle hand bones">
    <path id="middle_hand_bones_9" data-name="middle hand bones 9" class="mittelhand1332_links_dp_cls-4" d="M89.8,295.3h0c-24,.6-28.6-7.9-32.6-24.6l-12.3-47.7c-7.7-7.7-14-22.9-20.2-38.8-3.3-8.3-1.5-18,5-24.1s15.9-7.7,23.5-2.6c5.6,3.7,10.8,7.8,15.1,12.2,1.7,1.7,2.5,4,2.3,6.4-.2,1.8-.7,3.8-1.8,6.4-.9,2.1-1.2,4.3-.8,6.6,4.5,25.4,13.4,45.1,28.4,59.5,7.3-1.7,12.9,1.9,17.7,8.2-.2,2.4-.7,8.6.3,16.1-1.4-2-4.8-3.4-10.1,2.8,1.6,14.7-6.4,17.1-14.5,19.6h0c0,0,0,0,0,0Z"/>
    <path id="middle_hand_bones_8" data-name="middle hand bones 8" class="mittelhand1332_links_dp_cls-4" d="M265.4,254.5h0c.3,9.7-7.8,17.7-17.5,17.5-8.8-.1-18,.5-17.7,2.7.4,3.9-11.9-8.3-11.1-10.8l-.2-9c0-1.4,0-2.8.4-4.2.4-1.9.6-3.3.5-4.3-.6-5.9,2.2-11.7,7-15.1,0,0,.1-.1.2-.2,3-2.1,5.3-4.9,6.6-8.3,7.6-20,14.9-39.2,16.5-49.6,2-15.8,3.9-30.5,4.9-40,0-.5,0-1,.1-1.5l.6-24.2c0-1.6.3-3.2.8-4.7,1.5-5,3.5-9.8,5.9-14.3,2.1-4,5.7-7.1,10-8.4,7.2-2.2,13.7-2.3,19.3-.3,5,1.8,8.9,5.9,10.5,11,1.4,4.4,2,8.9,1.3,13.4,0,.5-.1,1.1-.2,1.6-.7,9.6-6.2,26.2-16.7,49.8-6.2,15.8-12.7,34.2-20,57.6-.2.6-.3,1.2-.5,1.8-2.2,10.8-2.2,21.6-1.4,32.6.2,2.3.4,4.6.5,6.9h0Z"/>
    <path id="middle_hand_bones_7" data-name="middle hand bones 7" class="mittelhand1332_links_dp_cls-4" d="M255.5,105.5c19.8-19.7,35.2-22.2,48-.4"/>
    <path id="middle_hand_bones_6" data-name="middle hand bones 6" class="mittelhand1332_links_dp_cls-4" d="M211,271.9h0c-4.6-2.4-9.6-4.5-15.1-6.7-5.3,1.5-11.6,5.2-19.9,13.6-7.1-11.8-8.9-24.9-7-38.8,2.2-6.3,5.8-11.6,10.5-16.2,1.8-1.8,2.9-4.1,3.2-6.7,3.1-27.2,6.3-53.5,7.6-82,.1-3.2,0-6.4-.5-9.6-.8-5.8-.7-11.6,0-17.5.3-2.3-.7-4.5-2.5-5.9-2.6-2-4.7-5.7-6.4-10.7-.5-1.4-.4-3,0-4.4,5.2-14.3-1.6-21.4,14.6-37.4,11.8-6.1,22.5-7.5,31.6-2.5,5.6,6.5,10,13.9,12.6,22.8,2.9,16.6-13.9,67-26.5,110.2-3.7,22.3-2.8,41.4,3.8,56.6l-.2,2c-.2,2.2.4,4.4,1.4,6.4,1.8,3.5.4,14,.6,18.8.3,4.7-2.8,7-7.9,7.9h0c0,0,0,0,0,0Z"/>
    <path id="middle_hand_bones_5" data-name="middle hand bones 5" class="mittelhand1332_links_dp_cls-4" d="M176.1,278.9h0c-3.8,3.6-8.6,4.2-14.2,1.9-8.7-12.9-18.7-16.9-31.9,3.5-14.4,0-16.6-14.5-15.8-27.5.7-.6,1.3-1.2,1.9-1.8,4.6-4.5,6.6-10.9,5.8-17.2-2.8-21.2-3.6-45.7-2.6-73.9-2.2-31.3-5.5-51.1-8.9-67.3-.5-2.4-2.6-4.1-5-4.1-2.3,0-3.9-2.2-4.8-6.9,2.1-10.7,3.9-22.2,8.6-25.3,11.9-8.3,23.5-10,34.8-5.7,3.8,5.2,9.5,11.4,5.6,13,9.7,1.1,7.1,16.8.9,36.7l-.3,111.2c0,2.2.9,4.2,2.5,5.8,8.1,7.9,16.7,22.8,15.9,23.7,0,18.9,2.4,26.8,7.6,34.1h0c0,0,0,0,0,0Z"/>
    <path id="middle_hand_bones_4" data-name="middle hand bones 4" class="mittelhand1332_links_dp_cls-4" d="M284.2,291.2v-.2c-7.3,0-12.5-6.3-17.5-12.9-2.2-2.9-3.3-6.5-3-10l.9-14.8c.2-3.1,2.1-5.7,5-6.8,21.6-8.5,32.9-48.9,44.3-93.8-1.7-8.4-1.2-15.8,4.1-20.9,1.9-7.6,4.6-14.1,11.1-15.2,9,.2,16.4,3.1,19.9,12.5,1,2.6,3.5,4.1,6.2,4.1,2.5,0,4.1,2.5,3.9,10.1-.4,2.6-.4,5.3-.2,8.1.1,1.9-.5,3.7-1.8,5-9.2,9.6-16.6,23.8-22.2,40.6-6.1,19.5-18,37-31,55.6,1.3,10.8.9,20.7-1.3,29.4-5.3,7.5-11.3,11.3-18.4,9.2h0Z"/>
    <path id="middle_hand_bones_3" data-name="middle hand bones 3" class="mittelhand1332_links_dp_cls-5" d="M317.8,132.3c10-5.1,20.2,4,31.9,7.6,2.6-1.1,6.4-3.3,7.9-6.2"/>
    <path id="middle_hand_bones_2" data-name="middle hand bones 2" class="mittelhand1332_links_dp_cls-4" d="M180.4,89.2c5.9-3.5,10.7-15.4,17.3-26.1,1.7-2.8,4.9-4.4,8.2-3.8,4.1.7,7.2,2.7,10.1,4.1,2.1,1.1,4.6.9,6.6-.5,3-2,8.2-1,12.9-3.1"/>
    <path id="middle_hand_bones_1" data-name="middle hand bones 1" class="mittelhand1332_links_dp_cls-4" d="M100.8,85.7c8-6.8,8.8-16.9,15.6-18.5,3.9-1.1,12.1.5,21.6,2.8,1.8.4,3.7,0,5.3-1.1,1.8-1.2,4.5-1.7,7.6-1.8"/>
  </g>
  <g id="wiechteilen">
    <path class="mittelhand1332_links_dp_cls-3" d="M92.2,476.1c2.3-14.7,3.8-36.4-2-61-5.1-21.9-12.9-33-27-59-13.6-25.1-32.8-60.6-46-102C10.9,234.3,3.6,205.9.4,170.1"/>
    <path class="mittelhand1332_links_dp_cls-3" d="M43.2,43.1c2.3,7.1,5.8,17.5,10,30,15.9,47.1,19.2,54.3,22,54,3-.3,4.2-9.4,7-35,2.6-23.7,4.6-43.8,6-59"/>
    <path class="mittelhand1332_links_dp_cls-3" d="M313.2,475.1c2.4-19.2,2.5-35.2,2-47-1-23.8-4.5-32.5-4-53,.4-15.5,2.7-26.2,7-45,4.2-18.4,11.4-49.3,29-87,8.6-18.5,21.1-41.5,39-66"/>
    <path class="mittelhand1332_links_dp_cls-3" d="M189.1.2c-9.1,18.1-12.4,44.6-14.8,56-2.1,9.8-2.9,17.9-5,18-2.2,0-4.1-8.8-9-35-2.9-15.9-5.3-29.2-7-39"/>
    <path class="mittelhand1332_links_dp_cls-3" d="M370.2,21.1c-4,6.3-22,36-29,48-18.1,31.3-24.7,48-28,47-4.1-1.2.7-28,2-35,2.6-14.5,5.5-23.2,15-57,2.2-7.7,3.9-14,5-18"/>
    <path class="mittelhand1332_links_dp_cls-3" d="M271.2,11.1c-4.9,13.7-8.6,24.1-11,31-1.2,3.5-1.8,5.2-2.1,6.1-6.2,18.1-6.9,23-7.9,22.9-.9,0-1.9-4.4-2-47,0-6.6,0-12.2,0-16"/>
  </g>
</g>
<g id="overlays">
  <g id="middle_hand_bones_overlay" data-name="middle hand bones overlay" class="mittelhand1332_links_dp_cls-1">
    <path id="middle_hand_bones_9_overlay" data-name="middle hand bones 9 overlay" class="MHK1332Fill" style={{
          fill: props.colors.MHK1332Fill,
          stroke: props.colors.MHK1332Fill,
        }} d="M89.6,294.9h0c-24,.6-28.6-7.9-32.6-24.6l-12.3-47.7c-7.7-7.7-14-22.9-20.2-38.8-3.3-8.3-1.5-18,5-24.1s15.9-7.7,23.5-2.6c5.6,3.7,10.8,7.8,15.1,12.2,1.7,1.7,2.5,4,2.3,6.4-.2,1.8-.7,3.8-1.8,6.4-.9,2.1-1.2,4.3-.8,6.6,4.5,25.4,13.4,45.1,28.4,59.5,7.3-1.7,12.9,1.9,17.7,8.2-.2,2.4-.7,8.6.3,16.1-1.4-2-4.8-3.4-10.1,2.8,1.6,14.7-6.4,17.1-14.5,19.6h0c0,0,0,0,0,0h0Z"/>
    <path id="middle_hand_bones_8_overlay" data-name="middle hand bones 8 overlay" class="MHK1332Fill" style={{
          fill: props.colors.MHK1332Fill,
          stroke: props.colors.MHK1332Fill,
        }} d="M265.2,254.1h0c.3,9.7-7.8,17.7-17.5,17.5-8.8-.1-18,.5-17.7,2.7.4,3.9-11.9-8.3-11.1-10.8l-.2-9c0-1.4,0-2.8.4-4.2.4-1.9.6-3.3.5-4.3-.6-5.9,2.2-11.7,7-15.1,0,0,.1-.1.2-.2,3-2.1,5.3-4.9,6.6-8.3,7.6-20,14.9-39.2,16.5-49.6,2-15.8,3.9-30.5,4.9-40,0-.5,0-1,.1-1.5l.6-24.2c0-1.6.3-3.2.8-4.7,1.5-5,3.5-9.8,5.9-14.3,2.1-4,5.7-7.1,10-8.4,7.2-2.2,13.7-2.3,19.3-.3,5,1.8,8.9,5.9,10.5,11,1.4,4.4,2,8.9,1.3,13.4,0,.5-.1,1.1-.2,1.6-.7,9.6-6.2,26.2-16.7,49.8-6.2,15.8-12.7,34.2-20,57.6-.2.6-.3,1.2-.5,1.8-2.2,10.8-2.2,21.6-1.4,32.6.2,2.3.4,4.6.5,6.9h0Z"/>
    <path id="middle_hand_bones_7_overlay" data-name="middle hand bones 7 overlay" class="MHK1332Fill" style={{
          fill: props.colors.MHK1332Fill,
          stroke: props.colors.MHK1332Fill,
        }} d="M255.3,105.1c19.8-19.7,35.2-22.2,48-.4"/>
    <path id="middle_hand_bones_6_overlay" data-name="middle hand bones 6 overlay" class="MHK1332Fill" style={{
          fill: props.colors.MHK1332Fill,
          stroke: props.colors.MHK1332Fill,
        }} d="M210.8,271.5h0c-4.6-2.4-9.6-4.5-15.1-6.7-5.3,1.5-11.6,5.2-19.9,13.6-7.1-11.8-8.9-24.9-7-38.8,2.2-6.3,5.8-11.6,10.5-16.2,1.8-1.8,2.9-4.1,3.2-6.7,3.1-27.2,6.3-53.5,7.6-82,.1-3.2,0-6.4-.5-9.6-.8-5.8-.7-11.6,0-17.5.3-2.3-.7-4.5-2.5-5.9-2.6-2-4.7-5.7-6.4-10.7-.5-1.4-.4-3,0-4.4,5.2-14.3-1.6-21.4,14.6-37.4,11.8-6.1,22.5-7.5,31.6-2.5,5.6,6.5,10,13.9,12.6,22.8,2.9,16.6-13.9,67-26.5,110.2-3.7,22.3-2.8,41.4,3.8,56.6l-.2,2c-.2,2.2.4,4.4,1.4,6.4,1.8,3.5.4,14,.6,18.8.3,4.7-2.8,7-7.9,7.9h0c0,0,0,0,0,0Z"/>
    <path id="middle_hand_bones_5_overlay" data-name="middle hand bones 5 overlay" class="MHK1332Fill" style={{
          fill: props.colors.MHK1332Fill,
          stroke: props.colors.MHK1332Fill,
        }} d="M175.9,278.5h0c-3.8,3.6-8.6,4.2-14.2,1.9-8.7-12.9-18.7-16.9-31.9,3.5-14.4,0-16.6-14.5-15.8-27.5.7-.6,1.3-1.2,1.9-1.8,4.6-4.5,6.6-10.9,5.8-17.2-2.8-21.2-3.6-45.7-2.6-73.9-2.2-31.3-5.5-51.1-8.9-67.3-.5-2.4-2.6-4.1-5-4.1-2.3,0-3.9-2.2-4.8-6.9,2.1-10.7,3.9-22.2,8.6-25.3,11.9-8.3,23.5-10,34.8-5.7,3.8,5.2,9.5,11.4,5.6,13,9.7,1.1,7.1,16.8.9,36.7l-.3,111.2c0,2.2.9,4.2,2.5,5.8,8.1,7.9,16.7,22.8,15.9,23.7,0,18.9,2.4,26.8,7.6,34.1h0c0,0,0,0,0,0Z"/>
    <path id="middle_hand_bones_4_overlay" data-name="middle hand bones 4 overlay" class="MHK1332Fill" style={{
          fill: props.colors.MHK1332Fill,
          stroke: props.colors.MHK1332Fill,
        }} d="M284,290.7v-.2c-7.3,0-12.5-6.3-17.5-12.9-2.2-2.9-3.3-6.5-3-10l.9-14.8c.2-3.1,2.1-5.7,5-6.8,21.6-8.5,32.9-48.9,44.3-93.8-1.7-8.4-1.2-15.8,4.1-20.9,1.9-7.6,4.6-14.1,11.1-15.2,9,.2,16.4,3.1,19.9,12.5,1,2.6,3.5,4.1,6.2,4.1,2.5,0,4.1,2.5,3.9,10.1-.4,2.6-.4,5.3-.2,8.1.1,1.9-.5,3.7-1.8,5-9.2,9.6-16.6,23.8-22.2,40.6-6.1,19.5-18,37-31,55.6,1.3,10.8.9,20.7-1.3,29.4-5.3,7.5-11.3,11.3-18.4,9.2h0Z"/>
    <path id="middle_hand_bones_3_overlay" data-name="middle hand bones 3 overlay" class="MHK1332Fill" style={{
          fill: props.colors.MHK1332Fill,
          stroke: props.colors.MHK1332Fill,
        }} d="M317.6,131.9c10-5.1,20.2,4,31.9,7.6,2.6-1.1,6.4-3.3,7.9-6.2"/>
    <path id="middle_hand_bones_2_overlay" data-name="middle hand bones 2 overlay" class="MHK1332Fill" style={{
          fill: props.colors.MHK1332Fill,
          stroke: props.colors.MHK1332Fill,
        }} d="M180.2,88.7c5.9-3.5,10.7-15.4,17.3-26.1,1.7-2.8,4.9-4.4,8.2-3.8,4.1.7,7.2,2.7,10.1,4.1,2.1,1.1,4.6.9,6.6-.5,3-2,8.2-1,12.9-3.1"/>
    <path id="middle_hand_bones_1_overlay" data-name="middle hand bones 1 overlay" class="MHK1332Fill" style={{
          fill: props.colors.MHK1332Fill,
          stroke: props.colors.MHK1332Fill,
        }} d="M100.7,85.3c8-6.8,8.8-16.9,15.6-18.5,3.9-1.1,12.1.5,21.6,2.8,1.8.4,3.7,0,5.3-1.1,1.8-1.2,4.5-1.7,7.6-1.8"/>
  </g>
  <g id="skeletal_soft_tissue_overlay" data-name="skeletal soft tissue overlay">
    <path id="skeletal_soft_tissue_1" data-name="skeletal soft tissue 1" class="gelenknahen1332KnochenFill" style={{
          fill: props.colors.gelenknahen1332KnochenFilll,
          stroke: props.colors.gelenknahen1332KnochenFilll,
        }} d="M77.6,292.1c-2.2,0-2.5,3.1-.4,3.6,3.7.8,7.8,1.1,12.4.9,0,0,.2,0,.2,0,5.5.6,10.4-.3,14.5-3.6,0,0,.1-.1.2-.1,2.7-2.6,4.7-5.6,5.5-9.2.3-1.5,2.2-1.9,3.1-.7,1.6,2,3.4,3.8,5.7,5.2,3.4,2.7,7.4,3.7,12.3,1.7.2,0,.4-.2.5-.3,5.8-4.9,11.5-9.9,14.9-10,5.1-1.2,9.8.7,13.9,6.7.2.3.4.5.7.6,4.4,2,8.6,2.2,12.5-.4,0,0,.2-.1.2-.2,7.7-6.7,14.9-12.2,21.2-14.6.4-.2.9-.1,1.3,0,8.1,3.5,15.6,5.2,21.3,2.9.6-.2,1.2-.2,1.7.2l5.8,4.1c.4.3,1,.4,1.6.2,11.9-4.1,23.4-4.9,34.4,1.3l16.5,10.5c.1,0,.3.2.5.2,10.5,3.1,16.9,0,23-6.9,1.1-1.3,1.6-3,1-4.6-.2-.5-.4-.9-.6-1.2-.8-.9-2.2-.7-2.8.4-4.5,8.5-12.1,9.6-21.6,6.2-.1,0-.2,0-.3-.1-7.8-4.3-10.3-13.1-9.8-24.5,0-.3,0-.7-.2-1-.9-1.5-2.1-2.2-4.2-1.1-.2.1-.4.3-.5.4-6.5,7.7-18,11.3-33.4,11.8-3.9.3-6.2-2-8-5.3-.7-1.3-2.4-1.2-3.1,0-1.2,2.3-3,3.1-5.1,3.2-.5,0-1.1-.2-1.4-.6-4-4.8-9-7.8-15.1-8.4-.2,0-.4,0-.7,0-7,1.8-13.4,6.5-19.1,13.8,0,0-.1.2-.2.3-2.3,4.2-5.4,6.2-11.2.4h0c-7.4-6.5-14.9-11.1-22.4-9.9-.1,0-.2,0-.4,0-6.2,2.1-10.5,7.5-13.6,14.9-1.8,3.9-6.8-.4-14.4-11.6-.1-.2-.3-.4-.5-.5-4-2.7-8-1.5-12.2,2.9-.4.5-.6,1.1-.4,1.7,3.5,13.8-3.8,20.9-23.2,20.5h0s0,0,0,0Z"/>
    <path id="skeletal_soft_tissue_2" data-name="skeletal soft tissue 2" class="gelenknahen1332KnochenFill" style={{
          fill: props.colors.gelenknahen1332KnochenFilll,
          stroke: props.colors.gelenknahen1332KnochenFilll,
        }} d="M24,168.3c3.2-9.4,14-15.4,23.9-13.2,3-1.8,5.9-5.1,7.3-9.9.2-.8-.5-1.6-1.3-1.4-13.3,2.8-26.4,10.9-37.1,18.3-.6.4-.7,1.3-.2,1.8.5,1.2,4,3.9,7.3,4.3h0Z"/>
    <path id="skeletal_soft_tissue_3" data-name="skeletal soft tissue 3" class="gelenknahen1332KnochenFill" style={{
          fill: props.colors.gelenknahen1332KnochenFilll,
          stroke: props.colors.gelenknahen1332KnochenFilll,
        }} d="M107.3,59c1,.7,2.3.8,3.3.1,10.3-6.4,20.9-8.3,31.9-5.3.8.2,1.6.1,2.3-.2,2.2-1.1,3.7-2.8,4.6-5,.8-1.9-.4-4.1-2.5-4.3-13.4-1.5-27.1-.2-41.5,6.2-1.3.6-2.1,2.1-1.7,3.5.5,1.8,1.5,3.5,3.5,5h0Z"/>
    <path id="skeletal_soft_tissue_4" data-name="skeletal soft tissue 4" class="gelenknahen1332KnochenFill" style={{
          fill: props.colors.gelenknahen1332KnochenFilll,
          stroke: props.colors.gelenknahen1332KnochenFilll,
        }} d="M192.4,51.4c.6,0,1.2-.2,1.7-.5,13.3-8.6,23.9-8.5,33.1-3.9.5.3.9.7,1.2,1.2l1.1,1.8c.4.6.9,1,1.6,1.3,7.3,2.8,9.3-1.9,8.8-10.2,0-1.3-1-2.5-2.3-2.8-17.6-4.9-34.2-4-50.1,2.5-1.4.6-2.2,2-2,3.5.7,4.3,2.5,7.3,6.8,7.2h0Z"/>
    <path id="skeletal_soft_tissue_5" data-name="skeletal soft tissue 5" class="gelenknahen1332KnochenFill" style={{
          fill: props.colors.gelenknahen1332KnochenFilll,
          stroke: props.colors.gelenknahen1332KnochenFilll,
        }} d="M265.3,82.8c.3,0,.6,0,.9-.2,15.6-7.6,26.2-4.3,33.1,1.8.2.2.4.3.7.4,3,1,5-1.9,6.5-6.7.4-1.2-.3-2.6-1.5-2.9-16.3-4.7-32.1-6-47.3-2.1-1.3.3-2.1,1.6-1.7,2.9,1.2,3.9,3.3,7,9.2,6.8h0Z"/>
    <path id="skeletal_soft_tissue_6" data-name="skeletal soft tissue 6" class="gelenknahen1332KnochenFill" style={{
          fill: props.colors.gelenknahen1332KnochenFilll,
          stroke: props.colors.gelenknahen1332KnochenFilll,
        }} d="M349.5,129.2c.5,1.5,1.5,2.6,3.2,3.2,4.2-.2,6.5-1.6,7.2-4.1.2-.9,0-1.8-.8-2.4-9.2-8.3-18.9-14-29.4-16.6-.9-.2-1.8.2-2.2.9-1.4,2.3-2.1,4.7-1.6,6.9,11.4-1.5,19.2,2.7,23.6,12.1h0Z"/>
  </g>
  <g id="Softparts_overlays" class="mittelhand1332_links_dp_cls_7">
    <path id="Softparts_overlays6" class="weichteilen1332Fill" style={{
          fill: props.colors.weichteilen1332Fill,
          stroke: props.colors.weichteilen1332Fill,
        }} d="M310.5,474.7c-1.4-6-.4-11,0-13,.4-2.3.3-10.9,0-28,0-2.9-.2-10.5-.7-19.1-1.1-19.6-2.9-24.5-3.4-34.9-.2-5.1-.2-12.6,4.8-36.9,5-24.2,9.1-44.6,19.1-71.8,2.4-6.4,10.2-27.2,23.9-52.6,4.2-7.8,24.1-44.6,29.4-42.4,2.2.9,1.7,8.4,0,13.7-1.9,5.8-5.4,9.1-8.2,12.3-2.7,3.1-6.1,9.6-13,22.6-14.9,28.2-24.6,54.7-24.6,54.7-12.8,35-17.4,61.8-18.5,68.4-2.5,15.4-2.7,23.9-2.7,27.3-.1,12.6,1.4,14.9,2.7,36.2.2,3.3,1,15.9.7,32.1,0,.5-.2,10.3-2.1,23.2-2.1,14.5-3.6,15-4.1,15-1.8,0-3.3-6.3-3.4-6.8Z"/>
    <path id="Softparts_overlays5" class="weichteilen1332Fill" style={{
          fill: props.colors.weichteilen1332Fill,
          stroke: props.colors.weichteilen1332Fill,
        }} d="M88.3,473.3c-1.2-7.9-.2-14.1,0-15.7,1-6.6.2-13.3-1.4-26.7-1.2-10.7-4.1-20-10.3-34.9-8.9-21.6-14-28.5-28.7-57.4-9.9-19.5-15.3-31.4-24.6-51.9-2.8-6.1-7.4-16.6-12.3-30.8-2.4-7-4.8-14-6.8-23.2-1.2-5.6-3.1-15.3-4.1-31.4-1.3-22.6-.1-45.8,1.4-45.8,1.3,0,1.3,16.9,6.2,43.7.6,3.2,3.7,20.2,9.6,40.3,9.1,31,20.1,53.7,30.1,73.8,8.9,18.1,20.7,39.8,27.3,51.9,11,20.2,14.1,25.2,17.8,36.2,6.1,18.4,7.3,34.7,8.2,46.5.6,8-.3,3.5,0,17.1.1,5.2.3,9.1-2.1,13-.2.3-3.4,5.4-6.2,4.8-2.6-.6-3.5-5.9-4.1-9.6Z"/>
    <path id="Softparts_overlays4" class="weichteilen1332Fill" style={{
          fill: props.colors.weichteilen1332Fill,
          stroke: props.colors.weichteilen1332Fill,
        }} d="M39.8,46.1c1.8,6.9,2.7,10.3,4.1,13,4.6,9.1,6.8,19.1,10.3,28.7,3,8.2,7.1,16.1,8.9,24.6.6,2.8,1.9,9.8,6.8,17.1,3.8,5.6,5.7,5.5,6.2,5.5,2.8-.3,4.4-5.7,6.2-13.7,3.9-17.2,4.8-27.3,4.8-27.3,3.3-36.8,1.7-33.2,3.4-41,.7-3.1,1.6-13.5,2.7-22.6.6-5,1.1-9.3-.7-10.3-1.6-.8-4.5,1.2-6.2,3.4-2.7,3.5-2.1,7.6-2.1,13,0,5.5-1,10.9-1.4,16.4-.9,15.2-2.5,27.4-3.4,34.9-1.6,12.7-1.7,10.2-2.7,19.1-1,8.7-.9,12.1-2.1,12.3-1.6.3-4.5-8-10.3-24.6-9.1-26.4-13.7-39.6-13.7-39.6-1.2-7.6-1.8-16.6-6.2-17.8-1.5-.4-3.7,0-4.8,1.4-1.4,1.7-.9,4.3,0,7.5Z"/>
    <path id="Softparts_overlays3" class="weichteilen1332Fill" style={{
          fill: props.colors.weichteilen1332Fill,
          stroke: props.colors.weichteilen1332Fill,
        }} d="M148.5,1c-1,2.6.6,5.3,1.4,6.8,0,.2,1.7,7.4,4.8,21.9,2.2,10.1,3.7,18.4,4.1,20.5,0,.5,1.5,8.3,3.4,17.8,0,0,.4,2.2,2.7,10.9,1.7,6.5,2.1,7.4,2.7,7.5,2.9.4,7.6-16.2,8.2-18.5,3.3-11.9,2.3-13.9,5.5-30.1,3.2-16.5,4.8-24.7,8.2-30.1,2.7-4.3,6.2-8.3,4.8-10.9-1.3-2.3-5.8-2.9-8.9-1.4-3.2,1.6-4.5,5.2-6.2,12.3-2,8.7-2.6,14.3-3.4,20.5-1.6,11.4-1.9,8.9-4.8,26.7-1.2,7.3-1.4,9.6-2.1,9.6-2.3,0-5.7-29.7-6.8-39-2.1-18.2-2.6-27.7-6.8-28.7-2.4-.6-5.8,1.5-6.8,4.1Z"/>
    <path id="Softparts_overlays2" class="weichteilen1332Fill" style={{
          fill: props.colors.weichteilen1332Fill,
          stroke: props.colors.weichteilen1332Fill,
        }} d="M242.8,7.1c-1.5,4.4-.6,8.1,0,11.6,0,0,.2,6.2.7,18.5.6,15.8,0,15.4.7,21.2.9,6.6,3.3,18.5,6.2,18.5,1.3,0,2.1-2.3,5.5-11.6,4.2-11.7,4.3-11.6,5.5-15,5.1-14.9,7.7-22.4,10.9-30.1,3.1-7.3,5.3-11.6,3.4-13.7-1.5-1.7-5.1-1.4-7.5,0-3.3,1.9-3.6,5.6-6.2,15-.4,1.5,2.4-9.2-4.1,13.7-3.8,13.4-5,17.9-5.5,17.8-.8-.2,3.5-16,.7-36.2-.5-3.5-2.1-15.5-5.5-15.7-2-.2-4,3.8-4.8,6.2Z"/>
    <path id="Softparts_overlays1" class="weichteilen1332Fill" style={{
          fill: props.colors.weichteilen1332Fill,
          stroke: props.colors.weichteilen1332Fill,
        }} d="M331,5.1c-4.2,3.1-4.2,8.8-4.8,14.4-1.1,11.1-6,21.4-8.9,32.1-5.8,21.5-6.4,22.6-7.5,29.4-2.1,12.8,0,9.2-2.1,22.6-1.1,7.3-2.5,13,.7,15.7,2,1.7,4.8,1.4,5.5,1.4,6.6-.8,9.9-9.4,16.4-21.9.6-1.1,4.3-7.6,11.6-20.5,14.6-25.6,21.9-38.4,27.3-45.8,3.8-5.2,9-11.7,6.8-15.7-1.1-2-3.9-3.2-6.2-2.7-4.1.8-5.3,6.8-8.2,13.7-.4.8-.2.3-11.6,19.1-15.6,25.5-23.3,38.3-26,43.1-6.3,11.4-8.7,16.7-9.6,16.4-1.1-.4,2.1-10.2,10.9-41.7,11.3-40.2,9.4-36,10.9-39.6,4.1-9.7,8.4-17.3,5.5-20.5-2.2-2.3-7.6-1.8-10.9.7Z"/>
  </g>
</g>
</svg>
  );
}

export default RechteMittelhandDpSitzend1332;
