import style from "./style.scss";

function Rechte3ZeheDplOblSitzend1414(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.74 176.57">
 
 <g id="third_toe" data-name="third toe">
   <path id="third_toe_1" data-name="third toe 1" class="Rechte_3Zehe_dpl_obl_sitzend1414_cls3" d="M29.95,173.25c4.9-5.8,7-11.8,2.2-18,4.2-12.2,3.2-20.3-10.5-18.4-10,.6-13.5,3.6-9,10.1-5.9,7.3-7.7,14.6-3.4,22.1-.07,1.18-.15,2.36-.23,3.54,5.84,1.59,11.8,1.89,18,1.89.95,0,1.81.18,2.57.51.14-.59.26-1.17.36-1.75h0Z"/>
   <path id="third_toe_2" data-name="third toe 2" class="Rechte_3Zehe_dpl_obl_sitzend1414_cls1" d="M30.12,176.46c.1-.45.19-.9.27-1.34,4.9-5.8,7-11.8,2.2-18,4.2-12.2,3.2-20.3-10.5-18.4-10,.6-13.5,3.6-9,10.1-5.9,7.3-7.7,14.6-3.4,22.1-.06,1.04-.13,2.08-.2,3.12"/>
   <path id="third_toe_3" data-name="third toe 3" class="Rechte_3Zehe_dpl_obl_sitzend1414_cls2" d="M27.89,133.82c-3.9-1.9-8.4-1.9-14.2,1.5-7.8,5.5-10,.4-7.5-13.5,7.7-7.5,4.4-26.5-1.9-48.8-3.9-15.2,4.6-13.5,13.9-10.5,11.8.3,12,7,7.1,16.5-4.9,11.8-2.9,24.7,7.5,39,7.1,13.7,7,20.4-4.9,15.8h0Z"/>
   <path id="third_toe_4" data-name="third toe 4" class="Rechte_3Zehe_dpl_obl_sitzend1414_cls2" d="M22.29,61.02l-8.2-1.8c-6.2,1.5-9.4,0-7.1-7.1,2.1-6.2,3-11.8,3-16.9-2.7-7.3,2.2-9.7,11.6-9.4,13,3.2,13.5,12,8.6,23.2,2.5,9.1,1.5,14.7-7.9,12h0Z"/>
   <path id="third_toe_5" data-name="third toe 5" class="Rechte_3Zehe_dpl_obl_sitzend1414_cls2" d="M35.09,13.02c3.9,8.6,5.3,14.9-2.3,13.2l-15.8-3.8c-10.4.3-4.6-5,1.9-10.5,1.6-8.9,4-14.7,9.4-9.4,12.5-.6,12.5,3.8,6.8,10.5h0Z"/>
 </g>
 <g id="overlays">
   <g id="Phalangen_overlay" data-name="Phalangen overlay">
     <path id="Phalangen_overlay_1" data-name="Phalangen overlay 1" class="a1414_phalangenFill" style= {{
             fill: props.colors.a1414_phalangenFill,
             stroke: props.colors.a1414_phalangenFill,
             opacity:props.colors.a1414_phalangenFill,
           }} d="M27.62,133.82c-3.73-1.9-8.03-1.9-13.57,1.5-7.46,5.5-9.56.4-7.17-13.5,7.36-7.5,4.21-26.5-1.82-48.8-3.73-15.2,4.4-13.5,13.29-10.5,11.28.3,11.47,7,6.79,16.5-4.68,11.8-2.77,24.7,7.17,39,6.79,13.7,6.69,20.4-4.68,15.8h0Z"/>
     <path id="Phalangen_overlay_2" data-name="Phalangen overlay 2" class="a1414_phalangenFill" style= {{
             fill: props.colors.a1414_phalangenFill,
             stroke: props.colors.a1414_phalangenFill,
             opacity:props.colors.a1414_phalangenFill,
           }} d="M22.26,61.02l-7.84-1.8c-5.93,1.5-8.99,0-6.79-7.1,2.01-6.2,2.87-11.8,2.87-16.9-2.58-7.3,2.1-9.7,11.09-9.4,12.43,3.2,12.91,12,8.22,23.2,2.39,9.1,1.43,14.7-7.55,12h0Z"/>
     <path id="Phalangen_overlay_3" data-name="Phalangen overlay 3" class="a1414_phalangenFill" style= {{
             fill: props.colors.a1414_phalangenFill,
             stroke: props.colors.a1414_phalangenFill,
             opacity:props.colors.a1414_phalangenFill,
           }} d="M34.5,13.02c3.73,8.6,5.07,14.9-2.2,13.2l-15.1-3.8c-9.94.3-4.4-5,1.82-10.5,1.53-8.9,3.82-14.7,8.99-9.4,11.95-.6,11.95,3.8,6.5,10.5h0Z"/>
   </g>
   <g id="joint_overlay" data-name="joint overlay">
     <ellipse id="joint_overlay_1" data-name="joint overlay 1" class="a1414_gelenkeFill" style= {{
             fill: props.colors.a1414_gelenkeFill,
             stroke: props.colors.a1414_gelenkeFill,
             opacity:props.colors.a1414_gelenkeFill,
           }} cx="23.04" cy="25.97" rx="6.49" ry="15.72" transform="translate(-6.55 44.12) rotate(-79.95)"/>
     <ellipse id="joint_overlay_2" data-name="joint overlay 2" class="a1414_gelenkeFill" style= {{
             fill: props.colors.a1414_gelenkeFill,
             stroke: props.colors.a1414_gelenkeFill,
             opacity:props.colors.a1414_gelenkeFill,
           }} cx="16.46" cy="63.08" rx="5.97" ry="16.59" transform="translate(-48.24 71.1) rotate(-82.44)"/>
     <ellipse id="joint_overlay_2-2" data-name="joint overlay 2" class="a1414_gelenkeFill" style= {{
             fill: props.colors.a1414_gelenkeFill,
             stroke: props.colors.a1414_gelenkeFill,
             opacity:props.colors.a1414_gelenkeFill,
           }} cx="23.04" cy="137.31" rx="17.72" ry="6.84" transform="translate(-7.07 1.38) rotate(-2.96)"/>
   </g>
 </g>
</svg>
  );
}

export default Rechte3ZeheDplOblSitzend1414;
