import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";

function PregnacyCheck({ shown, close, yesHandler, noHandler }) {
  const [continueDisplayed, setContinueDisplayed] = useState(false);

  useEffect(() => {
    setContinueDisplayed(false);
  }, [shown, close]);

  return (
    <Modal
      size="sm"
      show={shown}
      onHide={close}
      backdrop="static"
      keyboard="false"
    >
      <Modal.Header className={"p-2"}>
        <h5>Check Pregnacy</h5>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
        {!continueDisplayed ? (
          <>
            <h5>Is Patient Pregnant?</h5>
            <div className="d-flex" style={{ gap: "10px" }}>
              <Button onClick={() => setContinueDisplayed(true)}>Yes</Button>
              <Button onClick={close}>No</Button>
            </div>
          </>
        ) : (
          <>
            <h5>Are you sure you can perform examination ?</h5>
            <div className="d-flex" style={{ gap: "10px" }}>
              <Button onClick={yesHandler}>Yes</Button>
              <Button onClick={noHandler}>No</Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default PregnacyCheck;
