import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const Pelvis = map.Pelvis;

function BackView({ selected, bpartSetter }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 582.98 654.43">
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="upper_leg_r" data-name="upper leg r">
          <path
            id="upper_leg_9_r"
            data-name="upper leg 9 r"
            class="pel-cls-2"
            d="M473.48,334.9c4.82,5.59,7.51,15.38,5.25,34.88,11.02,18.28,18.3,21.09,21.38,6.5,8.52-16.22,15.98-15.51,22.5,0,16.73,18.27,18.16,44.21,7.5,76.22-7.36,22.79-15.77,48.88-24.38,75.53-3.74,12.76-4.69,49.26-5.62,85.88"
          />
          <path
            id="upper_leg_8_r"
            data-name="upper leg 8 r"
            class="pel-cls-2"
            d="M473.48,381.77c3.75-3.33,5.5-7.33,5.25-12"
          />
          <path
            id="upper_leg_7_r"
            data-name="upper leg 7 r"
            class="pel-cls-2"
            d="M513.05,387.2c2.12,5.29,2.24,10.58,.38,15.87"
          />
          <path
            id="upper_leg_6_r"
            data-name="upper leg 6 r"
            class="pel-cls-2"
            d="M496.35,389.88c1.5,10.51-.02,20.95-4.5,31.32"
          />
          <path
            id="upper_leg_5_r"
            data-name="upper leg 5 r"
            class="pel-cls-2"
            d="M489.23,428.82c-2.44,16.29-10.85,27.56-23.25,35.46"
          />
          <path
            id="upper_leg_4_r"
            data-name="upper leg 4 r"
            class="pel-cls-2"
            d="M518.1,432.77c-.27,10.6-3.54,21.1-9.75,31.51"
          />
          <path
            id="upper_leg_3_r"
            data-name="upper leg 3 r"
            class="pel-cls-2"
            d="M440.38,515.27c7.71,8.73,15.33,9.89,20.74-3.75"
          />
          <path
            id="upper_leg_2_r"
            data-name="upper leg 2 r"
            class="pel-cls-2"
            d="M461.13,410.65c-1.52,10.94-6.53,17-15.02,18.17"
          />
          <path
            id="dupper_leg_1_r"
            data-name="dupper leg 1 r"
            class="pel-cls-2"
            d="M433.8,414.14c-3.17,9.15,1.28,13.84,12.3,14.68v41.3c-15.29,14.09-16.16,29.84-4.5,47.03,9.36,21.1,8.32,47.19,4.5,74.62"
          />
        </g>
        <g id="upper_leg_l" data-name="upper leg l">
          <path
            id="upper_leg_l_7"
            data-name="upper leg l 7"
            class="pel-cls-2"
            d="M100.81,346.39c-7.28,2.39-11.48,11.94-12.08,29.88-10.16,14.58-20.17,15.08-30,0-17.32-1.75-27.36,8.45-29.62,31.46-8.72,15.76-6.87,36.32,7.12,62.38l34.12,121.65"
          />
          <path
            id="upper_leg_l_6"
            data-name="upper leg l 6"
            class="pel-cls-2"
            d="M70.35,434.65c-14.2-5.86-15.32-28.95-11.62-58.37"
          />
          <path
            id="upper_leg_l_5"
            data-name="upper leg l 5"
            class="pel-cls-2"
            d="M75.23,399.7c-4.88-1.14-6.5-5.4-4.88-12.8"
          />
          <path
            id="upper_leg_l_4"
            data-name="upper leg l 4"
            class="pel-cls-2"
            d="M95.85,455.28c9.78,11.6,18.74,18.12,26.62,17.99"
          />
          <path
            id="upper_leg_l_3"
            data-name="upper leg l 3"
            class="pel-cls-2"
            d="M141.06,415.8l-12.96,6.47c-15.44,25.35-16.05,41.57-5.62,51,8.02,11.56,5.85,25.67-5.25,42l-4.12,66.75"
          />
          <path
            id="upper_leg_l_2"
            data-name="upper leg l 2"
            class="pel-cls-2"
            d="M114.98,412.15c4.5,6.64,10,10.65,16.5,8.44"
          />
          <path
            id="upper_leg_l_1"
            data-name="upper leg l 1"
            class="pel-cls-2"
            d="M103.73,534.77c6.07-8.77,11.25-16.25,13.5-19.5"
          />
        </g>
        <g id="iliac_crest_l" data-name="iliac crest l">
          <path
            id="iliac_crest_l_4"
            data-name="iliac crest l 4"
            class="pel-cls-2"
            d="M221.85,160.15c-16.13-44.43-41.42-71.97-81.38-72.53-54.83,8.68-80.98,40.5-95.25,81.91-6.19,22.92-3.32,49.11,11.25,79.5,8.38,13.42,18.33,20.29,30.75,16.88,13.67,9.3,20.78,26.22,19.5,52.88-7.9,3.77-10.02,11.07-6,22.12-.55,12.94,3.76,21.78,12.38,27,12.76-19.09,25.71-37.09,42-37.12,8.44,2.35,16.33,2.04,23.62-1.12-3.72-15.68-5.59-33.11-3.38-54.38,1.83-17.89,7.59-13.85,14.62-2.62,11.29,26.55,15.32,13.07,19.5,.38-.99-18.64,1.56-36.25,7.5-52.88,7.36-15.09,9.42-34.43,4.88-60Z"
          />
          <path
            id="iliac_crest_l_3"
            data-name="iliac crest l 3"
            class="pel-cls-2"
            d="M191.06,164.27c6.73,39.61,.53,71.07-20.21,93.38"
          />
          <path
            id="iliac_crest_l_2"
            data-name="iliac crest l 2"
            class="pel-cls-2"
            d="M87.23,265.9c-10.35-50.7-29.97-79.87-30.3-91.67-.51-26.18,17.61-38.98,38.93-49.5"
          />
          <path
            id="iliac_crest_l_1"
            data-name="iliac crest l 1"
            class="pel-cls-2"
            d="M196.57,284.93c-7.96,11.32-7.29,26.6,2.04,45.84,14.83,27.61,33.73,46.68,57,56.63,3.72,0,6.75,3,11.62,0-4.48-6.28-2.95-9.51,1.5-15.94-42.61-14.18-37.14-30.85-40.5-47.06-6.18-7.26-7.5-17.51-3.97-30.75l2.1-13.12c-6.61-9.53-11.93-9.83-16.88-7.5-2.44,13.36-6.6,18.07-12.91,11.91Z"
          />
        </g>
        <g id="iliac_crest_r" data-name="iliac crest r">
          <path
            id="iliac_crest_r_4"
            data-name="iliac crest r 4"
            class="pel-cls-2"
            d="M359.85,154.9c21.76-38.6,52.03-59.96,95.62-54.38,8.39,6.06,14.83,8.35,16.88,9,32.72,17.85,50.02,45.94,54,82.88,5.15,10.31,4.85,27.69,0,51-.42,14.62-11.75,18.95-25.88,20.62-21.35,6.58-26.28,20.84-28.5,36.38-4.57,8.55-7.09,15.34-7.88,20.62,5.34,3.93,8.72,7.62,8.62,10.88-10.01,18.99-22.75,17.93-37.12,4.88-9.78,.64-19.44-.91-28.88,1.88-11.31,11.33-14.07,5.24-11.62-11.43,8.09-23.2,9.6-44.85,8.25-65.82l-9.75,15c-13.49,19.21-22.29,9.93-30.38-3.75-1.62-20.77-5.99-38.47-13.12-53.06-7.28-9.29-7.72-21.89,0-38.44l9.75-26.25Z"
          />
          <path
            id="iliac_crest_r_3"
            data-name="iliac crest r 3"
            class="pel-cls-2"
            d="M402.6,142.8c-24.22,13.58-37.93,36.65-34.12,75.55,8.12,25.39,5.43,40.66-4.25,49.41"
          />
          <path
            id="iliac_crest_r_2"
            data-name="iliac crest r 2"
            class="pel-cls-2"
            d="M513.05,178.9c5.13,25.35,2.91,53.08-12.57,85.12"
          />
          <path
            id="iliac_crest_r_1"
            data-name="iliac crest r 1"
            class="pel-cls-2"
            d="M318.23,373.52c-5.62-3.79-5.38-7.67,0-11.62,21.24-7.64,36.11-28.15,42.75-65.25,4.32-10.71,9.42-14.77,15.58-9.74l8.11-1.28c6.01-3.79,8.87-1.38,8.19,8.02-.64,25.41-19.95,49.82-39,74.25-10.33,17.28-26.17,23.15-44.25,24.38-5.52-2.28-5.13-4.79,0-7.5,8.84-5.03,10.6-9,8.62-11.25Z"
          />
        </g>
        <g id="lumbar_spine" data-name="lumbar spine">
          <path
            id="lumbar_spine_9"
            data-name="lumbar spine 9"
            class="pel-cls-2"
            d="M243.98,44.65c-5.39-5.16-10.97-8.28-16.88-7.88h-10.88c-9.64,3.72-10.89,9.3-4.88,16.5,9.23,4.41,17.84,6.89,24.38,3,6.45-.89,6.86,1.99,4.88,6.38-6.11,10.7-4.9,18.61,0,25.12,1.2,4.62-.67,5.64-3.75,5.25h-18.38c-7.52,4.88-6.69,9.89,.75,15,8.54,.97,16.8,2.46,24.75,4.5,3.94,4.62,1.89,10.54-3.75,17.25-11.5-4.94-18.19-2.79-20.62,5.62-.38,14.25,1.62,20.03,8.06,10.12,5.42,.65,8.6,4.1,10.31,9.38,5.09-1.35,21.16-4.12,8.62-12.38,4.74,1.32,5.43,5.08,1.62,8.92l-9.75,4.12c-3.93-.47-10.09,.79-16.62,4.58,3.9,5.77,9.7,10.25,21.75,10.5,9.02,6.17,14.58,4.39,16.88-4.88,6.22-8.3,11.74-9.7,16.12,0,3.5,5.71,7.9,4.7,13.01-1.5,8.24,4.84,27.11,6.75,31.99-5.62,2.34-7.92,9.75-8.25,9.75-8.25,0,0,16.15-3.7,18-14.25-.28-16-5.32-15.05-12-8.25-2.99,4.42-8.11,6.66-13.5,8.62,2.59-9.84,2.11-16.01-3.75-15.75,.19-12.96,3.94-19.33,12.38-17.06,6.8-.22,12.65-1.02,16.88-2.81,10.89-9.72,8.65-13.83-2.25-14.25-6.32,1.58-12.82,3.21-19.5,4.88,2.4-8.85,1.02-15.33-2.25-20.62l-6.75-1.88c1.75-10.8,7.96-14.99,19.5-11.25,9.67,2.18,18.12,2.34,24.38-1.12,9.61-6.85,6.81-13.43-2.25-19.88-7.96,.71-18.69,1.66-29.62,2.62l-1.5-12.38c-5.4-9.97-11.08-8.64-16.88-2.62,.85,13.95-4.61,19.94-17.25,16.88-4.98,4.61-11.61,4.19-18.75,2.25-10.62,2.91-15.24,.6-15.38-5.62-1.85-3.02-3.52-5.46-5.02-7.39-6.18-7.92-9.52-6.94-11.11-.49l-.75,14.62Z"
          />
          <path
            id="lumbar_spine_8"
            data-name="lumbar spine 8"
            class="pel-cls-2"
            d="M312.29,28.44c-17.74-3.73-36.67-2.58-56.45,2.07"
          />
          <path
            id="lumbar_spine_7"
            data-name="lumbar spine 7"
            class="pel-cls-2"
            d="M281.1,55.52c3.49-7.94,6.95-8.06,10.39,0l1.61,19.5c-6.33,19.54-10.29,13.77-13.5,0-.5-5.48,0-11.97,1.5-19.5Z"
          />
          <path
            id="lumbar_spine_6"
            data-name="lumbar spine 6"
            class="pel-cls-2"
            d="M247.35,75.02c-2.25,2.69-1.75,5.91,0,9.38,8.93,10.89,15.48,12.11,18.75,0,3.5-6.38,8-9.5,13.5-9.38"
          />
          <path
            id="lumbar_spine_5"
            data-name="lumbar spine 5"
            class="pel-cls-2"
            d="M322.28,70.04l-2.92,9.67c-4.9,11.39-10.35,9.66-16.12,0-.53-5.33-4.41-6.22-10.12-4.69"
          />
          <path
            id="lumbar_spine_4"
            data-name="lumbar spine 4"
            class="pel-cls-2"
            d="M273.98,109.15c-1.91,2.91-2.09,8.28,0,16.88,2.7,10.33,6.5,12.67,12.38,0,2.25-4.91,2.27-16.19,1.73-29.04,3.6,7.94,6.49,16.33,5.02,27.54-2.21,6.39,8.64,10.43,20.62,1.5,2.69-1.55,4.79-3.31,6.38-5.25"
          />
          <path
            id="lumbar_spine_3"
            data-name="lumbar spine 3"
            class="pel-cls-2"
            d="M289.62,164.27c4.65-9.88,4.65-19.75,0-29.62"
          />
          <path
            id="lumbar_spine_2"
            data-name="lumbar spine 2"
            class="pel-cls-2"
            d="M259.73,126.77c-9.5-3.5-16-2.5-19.5,3-2.01,8.47-1.54,14.41,6.38,12.75"
          />
          <path
            id="lumbar_spine_1"
            data-name="lumbar spine 1"
            class="pel-cls-2"
            d="M325.52,152.71c.88,10.56,7.65,17.27,18.96,5.94,4.17-6.9,9.44-7.27,15.38-3.75"
          />
        </g>
        <g id="hip_bone_l" data-name="hip bone l">
          <path
            id="hip_bone_l_5"
            data-name="hip bone l 5"
            class="pel-cls-2"
            d="M113.1,367.9c.98,21.99,6.96,39.33,26.25,44.25,20.99-1.19,37.57-11.44,51.75-26.62,10.07-10.15,12.94-20.73,0-32.25l-16.78-22.08c-5.6,2.3-12.17,1.67-19.22-.42-16.64-.42-29.41,17.92-42,37.12Z"
          />
          <path
            id="hip_bone_l_4"
            data-name="hip bone l 4"
            class="pel-cls-2"
            d="M197.85,410.27c2.09-10.19,8.34-13.7,20.62-7.5l21.75,20.62c2.61,10.88-.62,18.26-7.88,23.25-10.45,5.99-18.49,5.01-25.12,0-8.37-13.48-10.19-25.27-9.38-36.38Z"
          />
          <path
            id="hip_bone_l_3"
            data-name="hip bone l 3"
            class="pel-cls-2"
            d="M139.35,412.15c7.77,16.78,14.39,29.11,17.08,26.25-8.02,17.27-5.03,26.77,5.79,30.75,10.07,11.48,19.8,19.86,28.83,21.75,15.2,11.36,28.83,9.35,41.29-2.62,16.74-7.89,32.22-20.97,46.88-37.49"
          />
          <path
            id="hip_bone_l_2"
            data-name="hip bone l 2"
            class="pel-cls-2"
            d="M186.82,389.88c3.57,11.79,11.94,23.31,10.86,35.36-11.27,11.34-21.06,28.6-14.45,46.91,15.54,14.93,29.53,17.86,41.03,1.5,6.49-1.03,11.21-9.57,12.06-15.85"
          />
          <path
            id="hip_bone_l_1"
            data-name="hip bone l 1"
            class="pel-cls-2"
            d="M229.9,447.95c7.74,9.32,12.35,20.62,9.35,36.74"
          />
        </g>
        <g id="hip_bone_r" data-name="hip bone r">
          <path
            id="hip_bone_r_6"
            data-name="hip bone r 6"
            class="pel-cls-2"
            d="M461.1,346.39c.61,16.36-11.14,39.39-22.12,62-5.74,9.33-12.54,8.98-19.88,3.75-15.28-6.34-25.72-21.93-34.5-40.69-4.27-16.1-.08-25.19,12.81-27.06,3.44-.14,6.59-1.85,9.32-5.75,8.68-1.58,18.47-2.04,28.88-1.88,7.82,7.34,16.26,10.91,25.5,9.62Z"
          />
          <path
            id="hip_bone_r_5"
            data-name="hip bone r 5"
            class="pel-cls-2"
            d="M352.73,405.25c8.56-.51,16.26,.45,18.75,10.21,1.61,4.18,2,9.15,1.5,14.68-3.57,9.12-10.51,15.86-20.25,20.62-16.62,4.37-25.26,.52-24.37-13.12,3.19-16.81,8.54-30.97,24.37-32.39Z"
          />
          <path
            id="hip_bone_r_3"
            data-name="hip bone r 3"
            class="pel-cls-2"
            d="M294.53,451.52c9.35,20.61,21.55,34.54,39.08,36,27.25,9.83,48.57,7.03,62.99-10.5,32.39-32.07,36.04-46.18,25.46-50.25v-12.78"
          />
          <path
            id="hip_bone_r_2"
            data-name="hip bone r 2"
            class="pel-cls-2"
            d="M382.99,364.37c-4.98,4.87-14.31,2.6-9.81,23.02-1.68,9.67-4.6,16.18-8.95,19.05"
          />
          <path
            id="hip_bone_r_1"
            data-name="hip bone r 1"
            class="pel-cls-2"
            d="M335.73,488.26c-3.75-12.54-1.18-24.71,11.39-36.28"
          />
        </g>
        <g id="sacrum">
          <path
            id="sacrum_12"
            data-name="sacrum 12"
            class="pel-cls-2"
            d="M247.35,208.9c-2.54,2.5-2.54,5,0,7.5,3.15,1.66,6.33,3.41,7.88,0,5.21-2.5,5.21-5,0-7.5-2.62-3.5-5.25-3.5-7.88,0Z"
          />
          <path
            id="sacrum_11"
            data-name="sacrum 11"
            class="pel-cls-2"
            d="M249.48,233.65c3.88-7.79,8.22-7.47,12.88-1.88,5.19,3.96,4.81,8.1,0,12.38-3.73,4.18-7.93,4.82-12.88,0-4.45-1.01-6.22-3.51,0-10.5Z"
          />
          <path
            id="sacrum_10"
            data-name="sacrum 10"
            class="pel-cls-2"
            d="M317.85,233.65c4.12-4,8.25-4,12.38,0,4.12,4.48,3.88,9.11,0,13.88-3.36,4.07-7.7,2.93-12.38,0-7.37,.69-7.63-3.75,0-13.88Z"
          />
          <path
            id="sacrum_9"
            data-name="sacrum 9"
            class="pel-cls-2"
            d="M249.48,271.81h1.82c3.12-5.51,6.31-5.27,9.56,0,1.99,3.19,2.01,6.59,0,10.21-3.26,2.46-6.45,2.57-9.56,0-4.18-3.62-4.73-7.02-1.82-10.21Z"
          />
          <path
            id="sacrum_8"
            data-name="sacrum 8"
            class="pel-cls-2"
            d="M255.92,300.4c-5.81,6.37-4.32,8.65,1.94,8.25,4.34,9.26,6.65,6.73,8.25,0v-11.62c-4.93-5.53-8.39-4.68-10.19,3.38Z"
          />
          <path
            id="sacrum_7"
            data-name="sacrum 7"
            class="pel-cls-2"
            d="M262.35,334.9c-4.35-.04-6.5,2.46-6.44,7.5,1.68,5.39,3.69,8.44,6.44,6.38"
          />
          <path
            id="sacrum_6"
            data-name="sacrum 6"
            class="pel-cls-2"
            d="M317.85,271.81c-4.08,7.64-5.15,13.02,2.25,12.1,4.03,3.37,8.47,1.97,13.12-1.88,1-6.28,.09-10.38-3-12-3.36-4.06-7.7-2.16-12.38,1.79Z"
          />
          <path
            id="sacrum_5"
            data-name="sacrum 5"
            class="pel-cls-2"
            d="M288.09,294.4c-8,8.4-4.67,11.63,0,14.25,3.77,2.36,8.17,1.89,12.89,0,6.51-5.94,6.98-11.7,0-17.25-4.72-3.64-9.12-3.81-12.89,3Z"
          />
          <path
            id="sacrum_4"
            data-name="sacrum 4"
            class="pel-cls-2"
            d="M283.35,183.77c3.48-11.92,8.35-12.67,14.62-2.25v19.12c1.15,7.66-.06,11.56-3.45,12"
          />
          <path
            id="sacrum_3"
            data-name="sacrum 3"
            class="pel-cls-2"
            d="M285.6,252.77v-25.02c3.55-11.97,7.14-11.75,10.69,0l-1.76,25.02c-3.16,6.06-6.17,6.94-8.93,0Z"
          />
          <path
            id="sacrum_2"
            data-name="sacrum 2"
            class="pel-cls-2"
            d="M268.73,291.4c.49,1.44-.36,31.28,0,31.88,1.76,9.85,1.35,20,0,29.62,2.55,11.25,7.37,12.1,14.62,1.88v-24.01c6.72-11.82,13.35-12.16,19.88,0v22.51c2.7,8.07,6.41,9.09,11.62-.38,3.5-15,5.52-29.1,0-38.62-4.71-5.49-2.37-21.38,0-37.31"
          />
          <path
            id="sacrum_1"
            data-name="sacrum 1"
            class="pel-cls-2"
            d="M314.85,314.27c4.3,1.85,8.36,.65,12-5.62,1.92-.26,2.08-2.93,0-8.62-1.92-2.83-4.25-2.17-6.75,0"
          />
        </g>
        <g id="Coccxy">
          <path
            id="Coccxy_6"
            data-name="Coccxy 6"
            class="pel-cls-2"
            d="M268.73,371.46h3.75c3.47-9.21,7.12-8.74,10.88-2.43,5.27,9.14,10.32,10.19,15.05,0,3.33-5.9,7.08-6.08,11.2-1.12h4.5c4.26,5.83,6.24,9.71,.55,13.54-9.65,4.57-11.21,9.21-5.06,10.83-1.95,7.8-4.61,12.98-8.62,13.12-.71,12.96-4.35,20.3-9.49,24.75-7.54-2.08-11.77-8.96-14.51-18-3.64-1.1-5.07-4.43-4.88-9.38-4-4-4.86-9.49-4.88-15.38-4.12-3.49-3.31-8.92,1.5-15.94Z"
          />
          <path
            id="Coccxy_5"
            data-name="Coccxy 5"
            class="pel-cls-2"
            d="M267.23,387.4c12.2,5.46,25.1,6.59,38.81,2.56"
          />
          <path
            id="Coccxy_4"
            data-name="Coccxy 4"
            class="pel-cls-2"
            d="M272.1,402.77c10.11,5.28,19.79,6.65,28.88,2.62"
          />
          <path
            id="Coccxy_3"
            data-name="Coccxy 3"
            class="pel-cls-2"
            d="M276.98,412.15c7.31,6.33,15.6,9.28,22.56,3.66"
          />
          <path
            id="Coccxy_2"
            data-name="Coccxy 2"
            class="pel-cls-2"
            d="M279.23,450.78c5.65,1.76,10.9,2.42,15.3,.74"
          />
          <path
            id="Coccxy_1"
            data-name="Coccxy 1"
            class="pel-cls-2"
            d="M279.23,450.78c-2.98-6.3-3.02-12.55,0-18.76,3.55-1.88,7.82-3.13,17.06,0,3.5,6.35,2.62,12.86-1.76,19.5-4.44,1.68-9.68,1.01-15.3-.74Z"
          />
        </g>
      </g>
      <g id="lumbar_spine_overlay" data-name="lumbar spine overlay">
        <path
          id="lumbar_spine_overlay_1"
          data-name="lumbar spine overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Pelvis.id, Pelvis.children.lumbarSpine),
            });
          }}
          class={`pel-cls-1 ${isSelected(
            selected,
            Pelvis.children.lumbarSpine
          )}`}
          d="M211.35,53.27c-6.21-7.09-4.65-12.6,4.88-16.5,12.1-.35,22.14,1.44,27.75,7.88l1.87-14.14c-.26-8.59,4.19-5.91,9.99,0,18.82-4.83,37.63-5.52,56.45-2.07l-.06-4.04c6.24-5.97,12.05-7.11,16.88,2.62l1.5,12.38,29.62-2.62c12.26,8.58,8.76,15.1,0,20.92-4.88,2.26-13.39,1.05-24.15-.51-13.51-1.86-16.53,3.53-15.98,11.38l5.25,2.33c3.68,5.83,4.39,12.72,2.25,20.62l19.5-4.88c10.81,.67,11.99,5.02,3.61,12.99-5.3,3.05-11.47,4.22-18.24,4.07-8.05-3.17-11.53,3.97-12.38,17.06,4.75-.42,6.84,3.47,3.75,15.75,6.49-2.14,11.82-5.35,15.25-10.3,7.05-5.23,10.71-2.49,10.25,9.92-2,7.88-9.19,11.7-18,14.25l-5.83,2.31-3.92,5.94c-9.66,11.48-20.49,11.82-31.99,5.62-3.79,5.64-7.79,6.23-11.95,3-4.59-11.81-11.39-10.88-17.19-1.5-1.83,9.35-7.6,10.68-16.88,4.88-11.73,1.72-17.04-4.03-21.75-10.5,5.58-3.37,11.12-4.77,16.62-4.58-2.81-7.98-6.48-10.98-10.81-10.04-7.11,9.1-8.97,3.39-8.06-10.12,4.54-8.99,11.42-10.85,20.62-5.62,6.41-8.02,7.44-13.68,3.75-17.25l-24.75-4.5c-8.77-7.8-7.06-12.16-.75-15l20.74-.08,1.38-5.17c-4.5-3.46-5.42-10.84-1.38-23.62,3.63-7.97,1.94-10.01-3.49-7.88-7.8,3.15-15.95,1.84-24.38-3Z"
        />
      </g>
      <g id="crista_iliaca_overlay_l" data-name="crista iliaca overlay l">
        <path
          id="crista_iliaca_overlay_l_1"
          data-name="crista iliaca overlay l 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Pelvis.id, Pelvis.children.christaIliaca),
            });
          }}
          class={`pel-cls-1 ${isSelected(
            selected,
            Pelvis.children.christaIliaca
          )}`}
          d="M221.85,160.15c-7.95-24.75-21.6-44.88-40.77-60.51-9.48-6.39-20.63-10.05-33.1-11.59-6.75,60.33-6.68,118.56,3.36,173.72,10.48,2.54,20.4,3.01,29.73,1.33,7.02,2.8,6.29,6.76,8.89,9.55,10.21,19.58,16.73,19.8,19.5,.38,7.16-2.09,12.9,.09,16.88,7.5,4.39,6.73,8.2,7.36,11.25,0,5.37-39.35,7.97-76.46,6-109.88-11.84,.71-17.41-4.33-21.75-10.5Z"
        />
      </g>
      <g id="crista_iliaca_overlay_r" data-name="crista iliaca overlay r">
        <path
          id="crista_iliaca_overlay_r_1"
          data-name="crista iliaca overlay r 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Pelvis.id, Pelvis.children.christaIliaca),
            });
          }}
          class={`pel-cls-1 ${isSelected(
            selected,
            Pelvis.children.christaIliaca
          )}`}
          d="M335.9,164.27c5.06-2.18,5.54-3.17,8.58-7.12,5.98-4.71,11.26-6.14,15.38-2.25,21-40.66,53.22-58.07,95.62-54.38,4.37,59.91,5.02,116.42-7.5,160.88-14.47,3.36-29.32,3.64-44.62,0-19.74,28.79-23.03,29.2-31.72,22.51-9.2,6.22-18.56,12.36-28.95,5-4.21-34.27-9.69-87.35-6.79-124.63Z"
        />
      </g>
      <g id="sacrum_and_coccxy_overlay" data-name="sacrum and coccxy overlay">
        <path
          id="sacrum_and_coccxy_overlay_1"
          data-name="sacrum and coccxy overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Pelvis.id, Pelvis.children.coccyxAndSacrum),
            });
          }}
          class={`pel-cls-1 ${isSelected(
            selected,
            Pelvis.children.coccyxAndSacrum
          )}`}
          d="M243.6,170.65c9.28,6.35,15.05,5.05,16.88-4.88,5.34-8.76,10.71-9.04,16.12,0,4.4,5.16,8.74,4.84,13.01-1.5,10.74,3.49,21.5,9.02,31.99-5.62l3.92-5.94c2.02,9.61,5.44,13.64,10.38,11.56-1.35,47.22-.73,91.35,6.79,124.63,5.79,6.78,13.38,5.32,21.55,1.21l-3.26,6.53c-7.99,39.83-24.57,61.57-46.87,71.25,7.24,5.3,7.36,11.47-7.6,19.5-11.76,7.11-25.08,5.89-39.27,0-4.25-4.7-2.84-10.22,0-15.94-26.51-7.32-38-22.8-38.5-44.24-7.73-8.95-6.24-25.28-2.38-46.69,5.55,7.39,10.17,8.45,12.87-3.56,4.37-39.64,6.79-76.46,4.38-106.32Z"
        />
      </g>
      <g id="symphysis">
        <path
          id="symphysis_overlay"
          data-name="symphysis overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Pelvis.id, Pelvis.children.coccyx),
            });
          }}
          class={`pel-cls-1 ${isSelected(selected, Pelvis.children.coccyx)}`}
          d="M267.23,387.4c.01,5.87,.87,11.34,4.88,15.38-1.03,3.43,1.2,7.22,5.47,11.22,1.88,6.9,2.54,13.3,0,18.41-1.35,6.02-.74,12.15,1.66,18.38,4.96,2.11,10.07,2.24,15.3,.74,4.05-5,3.85-11.84,1.76-19.5-1.06,.53,.45-6.05,3.25-16.22,1.45-3.45,1.92-6.92,1.44-10.41,4.93-2.87,8.02-7.09,8.62-13.12l-3.56-2.32c-10.81,4.51-24.29,2.29-38.81-2.56Z"
        />
      </g>
      <g id="hip_joint_l" data-name="hip joint l">
        <path
          id="hip_joint_l_overlay"
          data-name="hip joint l overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Pelvis.id, Pelvis.children.hipJointL),
            });
          }}
          class={`pel-cls-1 ${isSelected(selected, Pelvis.children.hipJointL)}`}
          d="M106.73,318.77c22.84-12.1,45.6-16.23,68.26-12.17l3.74,23.05-4.4,1.54,14.83,19.52c14.08,13.55,14.52,26.65-2.34,39.18-13.11,14.09-29.57,19.94-47.46,22.26l1.71,3.66-10.81,5.4c-16,15.22-16.83,32.95-9.27,52.07-40.7-23.34-58.7-56.91-62.25-97,9.67,13.08,19.58,16.6,30,0,.08-15.37,4.31-25.05,12.08-29.88v-8.31c-3.59-9.45-1.52-15.84,5.92-19.32Z"
        />
      </g>
      <g id="upper_leg_with_hip_joint_l" data-name="upper leg with hip joint l">
        <path
          id="upper_leg_with_hip_joint_l_overlay"
          data-name="upper leg with hip joint l overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                Pelvis.id,
                Pelvis.children.upperLegWithHipJointL
              ),
            });
          }}
          class={`pel-cls-1 ${isSelected(
            selected,
            Pelvis.children.upperLegWithHipJointL
          )}`}
          d="M58.73,377.01c-13.74-1.04-25.88,2.68-29.62,31.46-7.86,19.29-6.58,39.92,7.12,62.38l34.12,121.65,42.75-9.75,3.18-65.39c12.1-17.92,14.5-32.48,6.2-43.36-41.17-18.52-60.32-52.31-63.75-97Z"
        />
      </g>
      <g id="hip_joint_r" data-name="hip joint r">
        <path
          id="hip_joint_r_overlay"
          data-name="hip joint r overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, Pelvis.id, Pelvis.children.hipJointR),
            });
          }}
          class={`pel-cls-1 ${isSelected(selected, Pelvis.children.hipJointR)}`}
          d="M382.99,364.37c-.09-11.34,4.09-18.45,13.72-20.19-4.13-3.82-2.45-13.57,1-25.15,19.49-3.85,38.38-8.7,66.4,1.99l9.38,9.38c5.27,12.25,7.03,26,5.27,41.25,10.87,14.24,19.35,20.37,21.35,4.63,8.86-16.22,16.3-15.51,22.5,0-25.75,43.13-51.17,70.46-76.5,93.85v-41.3c-10.29-.1-14.55-4.94-13.3-14.09-26.72-.94-39.41-23.86-49.81-50.35Z"
        />
      </g>
      <g id="upper_leg_with_hip_joint_r" data-name="upper leg with hip joint r">
        <path
          id="upper_leg_with_hip_joint_r_overlay"
          data-name="upper leg with hip joint r overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                Pelvis.id,
                Pelvis.children.upperLegWithHipJointR
              ),
            });
          }}
          class={`pel-cls-1 ${isSelected(
            selected,
            Pelvis.children.upperLegWithHipJointR
          )}`}
          d="M446.1,470.12c30.41-25.41,56.19-56.35,76.5-93.85,12.29,14.87,21.85,32.96,5.93,81.09l-23.34,72.74c-4.73,20.79-5.43,50.01-5.03,81.57l-54.06-19.91c3.79-24.78,3.18-49.64-4.5-74.62-11-16.49-11.52-32.3,4.5-47.03Z"
        />
      </g>
    </svg>
  );
}

export default BackView;
