import style from "./style.scss";

function HalswirbelsauleRechtsAnliegendLatStehend1614(props) {
  return (
<svg id="HWSlianl_full" data-name="HWSlianl full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.13 407.7">

<g id="BG">
  <polygon id="BG_white" data-name="BG white" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls4" points="3.12 165.52 46.42 138.75 60.51 82.68 64.51 104.58 62.6 148.47 67.66 181.95 84.62 287.43 103.89 353.15 127.81 401.86 139.55 407.7 163.33 407.62 300.59 407.7 300.59 355.43 273.38 353.15 300.59 341.5 301.13 307.88 253.44 307.88 263.99 273.56 214.42 260.53 231.96 233.34 184.43 220.3 221.54 214.82 215.46 187.94 178.37 177.92 205.03 167.6 205.03 149.27 178.37 138.75 190.08 138.75 206.6 124.82 205.03 85.2 173.47 85.2 202.1 69.14 198.79 48.29 175.81 46.04 158.55 51.93 162.77 40.05 205.03 33.93 249.9 10.56 265.03 2.73 261.38 0 224.33 .44 .32 .44 .32 155.08 .32 166.04 3.12 165.52"/>
</g>
<g id="HWSlatanl">
  <g id="Intervertebralgelenke">
    <path id="Intervertebralgelenk_all" data-name="Intervertebralgelenk all" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M143.49,404.54c-7.35-5.93-8.33-12.48-6.33-19.33l-13.97-39.8c-6.7-2.13-8.98-8.47-7.98-17.91l-7.36-29.17c-9.26-5.46-11.12-12.1-8.54-19.44l-1.33-30.97c-11.08-1.47-12.89-8.93-11.85-18.24l-2.25-26.47c-4.46-6.45-5.61-15.16-4.21-25.61.89-19.7-13.59-29.11-6.11-48.78l3.16-27.95-3.16-6.68c-7.43-13.58-7.13-25.75-3-37.23l55.98,10.8c2,8.05,1.02,16.05-2.03,24l-11.97,29.47c2.82,6.22,2.93,12.34,0,18.34v30.57c4.1,5.71,4.67,12.4,2.68,17.77l5.85,28.71c3.23,2.08,4.81,9.32,5.47,19.41l9.11,27.38c5.95-.19,10.29,2.59,11.54,11.03l9.27,39.71c4.75,2.56,6.2,6.78,2.96,13.38l18.95,38.27c4.7,4.74,7.85,9.59,8.93,14.58l.66,1.66"/>
  </g>
  <g id="Skull_dornfortsätze_Parts" data-name="Skull dornfortsätze Parts">
    <path id="Skull_dornfortsätze_Part15" data-name="Skull dornfortsätze Part15" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M257.03.44l-27.29,14.62c-2.86,1.53-7.43,4.21-10.12,6.01-10.95,7.32-22.76,10.98-35.62,10.04-1.97-.14-3.95.06-5.86.56-4.67,1.22-9.61,1.88-14.96,1.72-1.67-.05-3.34.2-4.93.72-4.74,1.54-9.56,2.58-14.6,2.45-2.48-.07-4.95.44-7.24,1.42-6.54,2.78-12.96,5.16-18.53,4.7-3.2.1-6.74-2.59-10.41-6.4-2.15-2.24-5.13-3.43-8.24-3.41-5.42.03-10.02-1.43-13.1-5.68-1.76-2.36-3.05-4.85-3.63-7.52-.51-2.36-1.99-4.37-4.09-5.57-2.75-1.57-4.61-3.51-6.11-5.59"/>
    <path id="Skull_dornfortsätze_Part14" data-name="Skull dornfortsätze Part14" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M.32,162.06c7.14-5.87,14.38-10.86,21.72-15.04,13.77-7.3,25.55-17.33,28.1-39.95,1.37-19.05,3.91-36.77,8.96-51.65,5.16-22.78,13.97-31.14,10.78-47.09"/>
    <path id="Skull_dornfortsätze_Part13" data-name="Skull dornfortsätze Part13" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M161.6,397.05h0c1.8-8.13,4.7-16.18,20.09-23.24,6.2-.71,9.69-3.28,7.44-9.79-1.06-3.08-2.05-6.19-2.64-9.39-1.57-8.49-.48-12.53,4.45-10.14,1.85.9,3.69,1.8,5.6,2.55l13.52,5.34,10.37,18.91-58.83,25.75h0Z"/>
    <path id="Skull_dornfortsätze_Part12" data-name="Skull dornfortsätze Part12" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M148.87,333.61h0c.46-4.11,2.93-7.96,7.6-11.52,5.62-1.83,7-4.77,6.53-8.2-2.41-4.17-3.92-7.76-4.16-10.53-.21-2.41,2.62-3.85,4.46-2.28,7.57,6.46,14.65,11.18,21.12,13.73l-6.04,9.11-29.5,9.7h-.01Z"/>
    <path id="Skull_dornfortsätze_Part11" data-name="Skull dornfortsätze Part11" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M133.22,339.63c1.92-7.47,3.97-14.28,7.09-15.27,6.12-2.55,11.17,3.12,16.16,9.19l-23.25,6.08Z"/>
    <path id="Skull_dornfortsätze_Part10" data-name="Skull dornfortsätze Part10" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M142.33,335.31h0c.52-4.83,1.49-9.14,3.6-12.09,1.53-2.13,4.01-3.47,6.63-3.53,3.24-.08,6.62,1.63,10.21,6.28l-20.44,9.34h0Z"/>
    <path id="Skull_dornfortsätze_Part9" data-name="Skull dornfortsätze Part9" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M114.7,292.76h0c1.01-7.52,4.31-11.67,9.8-12.59l9.84,9.65-19.63,2.95h0,0Z"/>
    <path id="Skull_dornfortsätze_Part8" data-name="Skull dornfortsätze Part8" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M120.16,291.94h0c-1.32-5.94-.57-10.42,1.81-13.77,1.97-2.78,5.42-4.15,8.79-3.67,3.91.56,7.77,2.41,11.58,5.66l-22.18,11.77h0Z"/>
    <path id="Skull_dornfortsätze_Part7" data-name="Skull dornfortsätze Part7" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M100.12,247.9h0c-2.05-5.39-.2-9.1,6.15-10.87l1.59,5.44-7.75,5.44h.01Z"/>
    <path id="Skull_dornfortsätze_Part6" data-name="Skull dornfortsätze Part6" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M108.77,244.85h0c-.34-.23-.67-.46-1-.7-7.38-5.4-3.51-17.07,5.64-16.99,3.23.03,9,1.19,13.13,2.42v7.41s-17.77,7.85-17.77,7.85h0Z"/>
    <ellipse id="Skull_dornfortsätze_Part5" data-name="Skull dornfortsätze Part5" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" cx="88.15" cy="192.44" rx="3.53" ry="4.56"/>
    <path id="Skull_dornfortsätze_Part4" data-name="Skull dornfortsätze Part4" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M92.52,196.09h0c-4.1-3.41-5.45-5.96-3.59-9.59.77-1.51,1.96-2.76,3.3-3.8,3.63-2.8,7.74-4.06,12.01-4.79,1.32-.22,2.66-.37,3.99-.33,5.01.16,9.18,2.24,12.84,5.48l-7.75,9.38-20.81,3.65h0Z"/>
    <circle id="Skull_dornfortsätze_Part3" data-name="Skull dornfortsätze Part3" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" cx="83.92" cy="145.03" r="4.24"/>
    <path id="Skull_dornfortsätze_Part2" data-name="Skull dornfortsätze Part2" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M108.77,137.76h0c-3.97-3.51-8.24-6.56-14.13-7.06-2.95-.32-5.86.27-8.66,3.7-1.78,2.18-2.53,5.02-1.86,7.75.56,2.3,1.85,4.42,4.03,6.32l20.62-10.71h0Z"/>
    <path id="Skull_dornfortsätze_Part1" data-name="Skull dornfortsätze Part1" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M127.48,72.14h0c-.04,2.42-.78,4.45-3,5.69s-5.1,1.06-7.27-.36c-3.75-2.46-7.41-3.98-10.95-4.41l-25.48,4.25c-5.82-.19-7.75-4.98-7.1-12.82l3.42-4.87c18.21.92,34.88,5.38,50.38,12.53h0Z"/>
  </g>
  <g id="HWS_Bodys" data-name="HWS Bodys">
    <path id="HWS_Body9" data-name="HWS Body9" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M124.72,330.52h0c-8.74,4.89-13.58,3.83-15.19-2.13l-4.25-21.27c-3.85-6.4-1.77-10.88,3.65-14.28,9.54-2.63,20.17-7.93,31.59-15.19,1.55-2.17,3.95-3.61,7.29-4.25-8.2-15.92-9.94-23.7-3.34-20.96,6.91,5.81,13.4,10.68,17.12,9.42,0-6.09,1.59-8.15,6.27-2.43,7.64,4.5,15.04,8.47,19.14,4.56,4.68-2,9.98-.75,16.1,4.86,12.49,8.44,25.34,10.55,38.58,6.08,6.08-2.63,10.21-1.99,11.54,3.34,1.31,9.7-3.29,13.51-10.03,15.19l-13.67,2.13c-4.38,3.44-10.91,3.33-18.4,1.64-2.66-.6-5.45-.06-7.66,1.53l-4.92,3.51-10.94,4.25c-1.88,2.49-6.53,1.43-13.37-2.43l-11.24-10.03c-5.88-2.92-9.03-1.98-10.28,1.62-.8,2.29-.41,4.82.79,6.92,5.73,9.97,7.85,16.51.68,14.24-8.39-2.11-18.55,3.99-29.47,13.67h.01Z"/>
    <path id="HWS_Body8" data-name="HWS Body8" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M149.33,386.72h0c-11.15,10.55-17.64,7.6-20.96-4.56l-5.77-22.78c-5-9.54-4.81-16.73,6.68-18.84l23.39-12.15c3.19-4.21,6.83-5.67,10.94-4.25,9.01,1.66,13.05-2.75,12.76-12.46-6.68-14.18-7.29-21.47-1.82-21.87,8.1,5.25,13.92,10.99,17.01,17.32,8.48-1.11,16.37.05,23.7,3.34,24.27,6.04,46.7,8.89,66.23,6.68,4.9-1.93,9.85-3.34,15.49,2.73,3.72,5.08,5.22,9.79,1.52,13.67l-31.29,10.03c-11.81,7.09-25.47,10.47-41.32,9.42-13.59-8.44-26.39-15.74-33.42-14.73-9.87,3.17-13.3,7.54-13.06,12.61,4.48,9.03,3.59,15.47-2.43,19.44-.6,3.96-6.25,5.64-13.97,6.38l-13.67,10.03h0Z"/>
    <path id="HWS_Body7" data-name="HWS Body7" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M142.46,407.62c.59-3.7,3.01-6.27,6.55-8.14l28.25-17.01c2.58-3.48,8.28-4.12,15.19-3.65,12.48-7.3,13.55-16.22,9.72-25.82-5.11-11.35-7.28-19.29-2.73-19.44,8.71,2.97,15.47,9.11,20.96,17.32,5.22,9.17,13.32,13.45,24,13.37,13.74.58,32.27,2.25,52.25,4.25,1.4.24,2.72.43,3.98.58"/>
    <path id="HWS_Body6" data-name="HWS Body6" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M104.98,279.48h0c-7.42,7.26-12.43,6.91-12.46-9.11v-13.67c.23-7.2,4.12-11.53,10.78-13.67l20.51-10.94c.78-4.98,2.76-7.6,6.68-6.38-2.98-10.43-1.9-13.35,2.73-9.72-4.36-9.71-4.18-14.12,1.22-12.46,5.41,3.32,10.79,6.8,15.19,14.58,4.65,5.34,9.53,6.44,14.58,4.25,9.58-2.34,17.44-2.06,21.57,3.95,11.36,6.35,21.95,9.27,31.59,7.9,3.89,3.57,4.21,7.03,0,10.33-6.52,1.34-11.7,3.87-15.8,7.36-2.38,3.11-6.12,2.99-10.33,1.76-4.34,2.44-8.64,2.38-12.88-1.76-1.55-3.69-3.55-4.83-5.96-3.63,1.03,7.76-3.9,6.59-12.46,0-12.19-5.06-21.8-6.75-25.22-.38l2.13,10.62c4.09,7.72-1.41,11.41-12.46,12.76-6.16-.29-12.64,2.49-19.44,8.2h.03Z"/>
    <path id="HWS_Body5" data-name="HWS Body5" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M89.48,232.7h0c-5.89,7.68-8.85,4.94-9.34-6.62-.08-1.98.18-3.96.78-5.84,1.14-3.53.77-6.86-.86-10.02-3.26-5.47-1.26-9.72,4.71-13.06l25.67-13.37c2.32-1.54,4.64-1.67,6.99,1.52,2.06-1.85,2.73-4.49,2.04-7.9-.07-10.52.3-19.57,4.64-15.19l15.19,13.67c6.65,4.92,12.77,7.89,17.17,4.56,5.1-3.93,10.94-2.86,17.77,4.86,7.96,6.16,15.63,11.08,21.87,10.03,4.42,2.79,7.2,6.5,5.47,12.76-1.16,5.53-3.18,8.41-6.99,5.77-4.42-3.1-10-2.57-16.22,0-12.69-4.04-21.71-4.31-25.71.61-8.74-11.15-18.52-17.75-29.16-20.66-3.68,3.69-5.24,8.12,0,14.89,2.97,10.04,1.61,14.18-4.03,12.46-12.74-1.7-22.46,2.7-29.99,11.54h0Z"/>
    <path id="HWS_Body4" data-name="HWS Body4" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M96.93,119.38h0c-4.82,3.25-9.22,1.1-13.22-6.08-3.02-4.05-7.07-7.41-11.54-10.48-4.55-6.33-1.86-11.24,4.25-15.49,3.35-6.47,8.36-9.28,15.34-7.75,2.88,2.35,5.53,2.87,7.9,1.22,6.19-2.82,12.08-2.29,17.62,2.13,5,.2,9.52,2.12,13.37,6.38,8.89,7.5,16.69,11.26,23.09,10.18,4.32-.81,8.96-2.66,13.67-4.71,16.92-5.73,26.5-3.91,29.47,4.71-.38,3.95-.52,7.69.76,10.18,2.01,3.93,1.34,7.88-1.67,11.85-.17,7.89-3.55,8.93-8.2,7.29-7.9-2.55-15.93-.69-24,2.89-7.52,1.93-14.64,3.57-19.29,3.34-4.42.78-7.91,2.69-11.12,4.93-1.53,1.07-3.57,1.12-5.12.07-2.65-1.82-6.16-5.49-10.19-10.31l-12.91-10.94c-2.15-3.85-5.01-2.73-8.2.61v-.02h0Z"/>
    <path id="HWS_Body3" data-name="HWS Body3" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M75.52,180.91h0l-1.83-17.18c.43-8.33,3.57-12.93,8.2-15.49l23.7-10.33c1.84-4.86,3.99-8.69,9.11-2.73.66-8.66,2.15-15.07,7.29-11.54l11.85,17.32c5.28,4.3,10.19,5.77,14.28.91,4.45-6.74,11.27-7.25,20.35-1.82,16.16,8.9,28.21,17.21,23.09,23.09-.69,2.51-2.99,2.8-6.68,1.22-6.06-.85-11.02,1.58-15.8,4.56-4.42-3.55-8.57-2.86-12.61-.61l-6.84,6.08c-3.53,5-13.92-5.13-26.13-19.29-5.55-3.79-8.21.52-8.51,11.39.53,6.17-.85,9.09-4.34,8.43s-7.33-.52-10.96,0c-8.97,1.26-15.83,3.72-20.2,7.58-1.46,1.29-3.77.39-3.98-1.55v-.03h0Z"/>
    <path id="HWS_Body2" data-name="HWS Body2" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M79.67,64.14h0c-1.34-.22-2.7-.13-4.03.12-5.33,1.03-8.69-1.63-10.45-7.32-1.17-4.53,1.3-9.15,6.15-13.82,6.3-7.85,13.08-12.39,21.34-6.53,4.89,3.04,11.66,5.93,24.76,8.35,7.03,1.08,13.53.76,19.3-1.49,1.81-.71,3.71-1.17,5.65-1.21,5.3-.11,9.07.36,9.08,2.29,0,.75-.6,1.36-1.33,1.54-7.67,1.9-12.86,4.05-10.83,6.93,3.06,5.15,8.03,7.98,15.65,7.59,7.2-.27,15.04-2.92,23.39-7.44,4.44-1.2,8.35.21,11.39,5.92.15.87.25,1.71.31,2.53.48,7.12-4.8,13.34-11.85,14.42l-7.95,1.22c-1.25.19-2.47.56-3.57,1.17-5.11,2.8-15.57,1.64-30.26-2.62-11.1-.96-18.84-2.69-22.59-5.32-.97-.68-2.11-1.07-3.27-1.27l-30.89-5.06h0Z"/>
    <path id="HWS_Body1" data-name="HWS Body1" class="Halswirbelsaule_rechts_anliegend_lat_stehend1614_cls6" d="M73.8,137.85h0c-4.74,3.17-6.29-2.24-5.81-13.09.11-2.54.51-5.07,1.23-7.51,1.07-3.64,2.3-7,3.64-10.14s2.14-6.21,2.51-9.47c.72-6.35,1.88-11.48,3.61-15.02,1.62-3.33,2.36-7.02,2.42-10.72.31-18.03,3.86-27.97,9.31-33.18,4.46-3.09,8.91-3.39,13.37,0l7.59,10.07c.78,1.03,1.06,2.35.79,3.61-2.59,12.05-4.36,23.39-2.37,31.47.42,1.72.74,3.48.82,5.25,1.14,26.05-.22,41.26-5.46,39.67-10.97-5.28-20.89-2.55-29.86,7.44-.53.6-1.11,1.16-1.78,1.61h0,0Z"/>
  </g>
</g>
<g id="wirbelkörper_highlights_one" data-name="wirbelkörper highlights one">
  <path id="wirbelkörper_highlight7" data-name="wirbelkörper highlight7" class="HWK1614Fill" style= {{
            fill: props.colors.HWK1614Fill,
            stroke: props.colors.HWK1614Fill,
            opacity:props.colors.HWK1614Fill,
          }} d="M124.53,330.68h0c-8.74,4.89-13.58,3.83-15.19-2.13l-4.25-21.27c-3.85-6.4-1.77-10.88,3.65-14.28,9.54-2.63,20.17-7.93,31.59-15.19,1.55-2.17,3.95-3.61,7.29-4.25,4.37,6.79,5.45,16.26,6.79,19.61-3.33,2.35-2.3,7.49-1.1,9.59,5.73,9.97,7.85,16.51.68,14.24-8.39-2.11-18.55,3.99-29.47,13.67h.01Z"/>
  <path id="wirbelkörper_highlight6" data-name="wirbelkörper highlight6" class="HWK1614Fill" style= {{
            fill: props.colors.HWK1614Fill,
            stroke: props.colors.HWK1614Fill,
            opacity:props.colors.HWK1614Fill,
          }} d="M148.94,386.88h0c-11.15,10.55-17.64,7.6-20.96-4.56l-5.77-22.78c-5-9.54-4.81-16.73,6.68-18.84l23.39-12.15c3.19-4.21,6.83-5.67,10.94-4.25,2.49,8.11,12.4,22.33,15.8,26.74,4.48,9.03,3.59,15.47-2.43,19.44-.6,3.96-6.25,5.64-13.97,6.38l-13.67,10.03h0Z"/>
  <path id="wirbelkörper_highlight5" data-name="wirbelkörper highlight5" class="HWK1614Fill" style= {{
            fill: props.colors.HWK1614Fill,
            stroke: props.colors.HWK1614Fill,
            opacity:props.colors.HWK1614Fill,
          }} d="M104.58,279.64h0c-7.42,7.26-12.43,6.91-12.46-9.11v-13.67c.23-7.2,4.12-11.53,10.78-13.67l20.51-10.94c1.27-5.09,2.08-5.61,3.17-6.19,1.49,3.52,5.78,15.21,8.13,21.6l1.74,11.01c4.09,7.72-1.41,11.41-12.46,12.76-6.16-.29-12.64,2.49-19.44,8.2h.03Z"/>
  <path id="wirbelkörper_highlight4" data-name="wirbelkörper highlight4" class="HWK1614Fill" style= {{
            fill: props.colors.HWK1614Fill,
            stroke: props.colors.HWK1614Fill,
            opacity:props.colors.HWK1614Fill,
          }} d="M89.08,232.86h0c-5.89,7.68-8.85,4.94-9.34-6.62-.08-1.98.18-3.96.78-5.84,1.14-3.53.77-6.86-.86-10.02-3.26-5.47-1.26-9.72,4.71-13.06l25.67-13.37c2.32-1.54,4.99-1.76,7.34,1.43,2.6,1.74,3.78,5.05,6.07,8.4-3.68,3.69-5.59,8.32-.35,15.09,2.97,10.04,1.61,14.18-4.03,12.46-12.74-1.7-22.46,2.7-29.99,11.54h0Z"/>
  <path id="wirbelkörper_highlight3" data-name="wirbelkörper highlight3" class="HWK1614Fill" style= {{
            fill: props.colors.HWK1614Fill,
            stroke: props.colors.HWK1614Fill,
            opacity:props.colors.HWK1614Fill,
          }} d="M96.53,119.54c-4.82,3.25-9.22,1.1-13.22-6.08-3.02-4.05-7.07-7.41-11.54-10.48-4.55-6.33-1.86-11.24,4.25-15.49,3.35-6.47,8.36-9.28,15.34-7.75,2.88,2.35,5.53,2.87,7.9,1.22,6.19-2.82,12.08-2.29,17.62,2.13,5.75-1.08,8.68,48.75.77,46.8l-12.91-10.94c-2.15-3.85-5.01-2.73-8.2.61v-.02h0s0,0,0,0Z"/>
  <path id="wirbelkörper_highlight2" data-name="wirbelkörper highlight2" class="HWK1614Fill" style= {{
            fill: props.colors.HWK1614Fill,
            stroke: props.colors.HWK1614Fill,
            opacity:props.colors.HWK1614Fill,
          }} d="M75.11,181.07h0l-1.83-17.18c.43-8.33,3.57-12.93,8.2-15.49l23.7-10.33c1.84-4.86,3.99-8.69,9.11-2.73,3.69,6.08,3.95,8.12,7.07,18.66-3.99-1.75-5.68,1.75-6.78,12.65.53,6.17-.85,9.09-4.34,8.43s-7.33-.52-10.96,0c-8.97,1.26-15.83,3.72-20.2,7.58-1.46,1.29-3.77.39-3.98-1.55v-.03h0Z"/>
  <path id="wirbelkörper_highlight1" data-name="wirbelkörper highlight1" class="HWK1614Fill" style= {{
            fill: props.colors.HWK1614Fill,
            stroke: props.colors.HWK1614Fill,
            opacity:props.colors.HWK1614Fill,
          }} d="M79.27,64.3h0c-1.34-.22-2.7-.13-4.03.12-5.33,1.03-8.69-1.63-10.45-7.32-1.17-4.53,1.3-9.15,6.15-13.82,6.3-7.85,13.08-12.39,21.34-6.53,4.89,3.04,11.66,5.93,24.76,8.35-.48,11.84-3.48,19.17-4.49,24.68l-33.28-5.48h0Z"/>
</g>
<g id="Dornfortsätze_Highlights" data-name="Dornfortsätze Highlights">
  <path id="Dornfortsätze_Highlight7" data-name="Dornfortsätze Highlight7" class="Dornfortsatz1614Fill" style= {{
            fill: props.colors.Dornfortsatz1614Fill,
            stroke: props.colors.Dornfortsatz1614Fill,
            opacity:props.colors.Dornfortsatz1614Fill,
          }} d="M163.56,324.1h0c9.01,1.66,13.05-2.75,12.76-12.46-6.68-14.18-7.29-21.47-1.82-21.87,8.1,5.25,13.92,10.99,17.01,17.32,8.48-1.11,16.37.05,23.7,3.34,24.27,6.04,46.7,8.89,66.23,6.68,4.9-1.93,9.85-3.34,15.49,2.73,3.72,5.08,5.22,9.79,1.52,13.67l-31.29,10.03c-11.81,7.09-25.47,10.47-41.32,9.42-13.59-8.44-26.39-15.74-33.42-14.73-9.87,3.17-13.3,7.54-13.06,12.61.95,3.87-18.35-26.13-15.8-26.74h0Z"/>
  <path id="Dornfortsätze_Highlight6" data-name="Dornfortsätze Highlight6" class="Dornfortsatz1614Fill" style= {{
            fill: props.colors.Dornfortsatz1614Fill,
            stroke: props.colors.Dornfortsatz1614Fill,
            opacity:props.colors.Dornfortsatz1614Fill,
          }} d="M147.41,273.54v.02c-9.36-17.75-8.92-24.53-2.57-21.22,6.91,5.81,12.98,10.74,16.7,9.48,0-6.09,1.59-8.15,6.27-2.43,7.64,4.5,15.04,8.47,19.14,4.56,4.68-2,9.98-.75,16.1,4.86,12.49,8.44,25.34,10.55,38.58,6.08,6.08-2.63,10.21-1.99,11.54,3.34,1.31,9.7-3.29,13.51-10.03,15.19l-13.67,2.13c-4.38,3.44-10.91,3.33-18.4,1.64-2.66-.6-5.45-.06-7.66,1.53l-4.92,3.51-10.94,4.25c-1.88,2.49-7.56,1.62-14.4-2.24l-10.06-9.42c-5.88-2.92-5.23-2.45-8.29-1.92-.8,2.29-2.99-14.55-7.39-19.36h0Z"/>
  <path id="Dornfortsätze_Highlight5" data-name="Dornfortsätze Highlight5" class="Dornfortsatz1614Fill" style= {{
            fill: props.colors.Dornfortsatz1614Fill,
            stroke: props.colors.Dornfortsatz1614Fill,
            opacity:props.colors.Dornfortsatz1614Fill,
          }} d="M126.58,225.9h0c.48.27-.06-1.45,3.86-.23-2.98-10.43-1.9-13.35,2.73-9.72-4.36-9.71-4.18-14.12,1.22-12.46,5.41,3.32,10.79,6.8,15.19,14.58,4.65,5.34,9.53,6.44,14.58,4.25,9.58-2.34,17.44-2.06,21.57,3.95,11.36,6.35,21.95,9.27,31.59,7.9,3.89,3.57,4.21,7.03,0,10.33-6.52,1.34-11.7,3.87-15.8,7.36-2.38,3.11-6.12,2.99-10.33,1.76-4.34,2.44-8.64,2.38-12.88-1.76-1.55-3.69-3.55-4.83-5.96-3.63,1.03,7.76-3.9,6.59-12.46,0-12.19-5.06-21.8-6.75-25.18-.57l-8.13-21.76h0Z"/>
  <path id="Dornfortsätze_Highlight4" data-name="Dornfortsätze Highlight4" class="Dornfortsatz1614Fill" style= {{
            fill: props.colors.Dornfortsatz1614Fill,
            stroke: props.colors.Dornfortsatz1614Fill,
            opacity:props.colors.Dornfortsatz1614Fill,
          }} d="M117.38,185.27h0c2.06-1.85,2.73-4.49,2.04-7.9-.07-10.52.3-19.57,4.64-15.19l15.19,13.67c6.65,4.92,12.77,7.89,17.17,4.56,5.1-3.93,10.94-2.86,17.77,4.86,7.96,6.16,15.63,11.08,21.87,10.03,4.42,2.79,7.2,6.5,5.47,12.76-1.16,5.53-3.18,8.41-6.99,5.77-4.42-3.1-10-2.57-16.22,0-12.69-4.04-21.71-4.31-25.71.61-8.74-11.15-17.1-16.71-29.16-20.66-3.71-5.84-4.05-6.95-6.07-8.51h0Z"/>
  <path id="Dornfortsätze_Highlight3" data-name="Dornfortsätze Highlight3" class="Dornfortsatz1614Fill" style= {{
            fill: props.colors.Dornfortsatz1614Fill,
            stroke: props.colors.Dornfortsatz1614Fill,
            opacity:props.colors.Dornfortsatz1614Fill,
          }} d="M114.65,135.14h0c.66-8.66,2.15-15.07,7.29-11.54l11.85,17.32c5.28,4.3,10.19,5.77,14.28.91,4.45-6.74,11.27-7.25,20.35-1.82,16.16,8.9,28.21,17.21,23.09,23.09-.69,2.51-2.99,2.8-6.68,1.22-6.06-.85-11.02,1.58-15.8,4.56-4.42-3.55-8.57-2.86-12.61-.61l-6.84,6.08c-3.53,5-12.87-3.81-28.21-20.36-1.42-5.79-3.99-13.04-6.72-18.85h0Z"/>
  <path id="Dornfortsätze_Highlight2" data-name="Dornfortsätze Highlight2" class="Dornfortsatz1614Fill" style= {{
            fill: props.colors.Dornfortsatz1614Fill,
            stroke: props.colors.Dornfortsatz1614Fill,
            opacity:props.colors.Dornfortsatz1614Fill,
          }} d="M117.23,82.89c5,.2,9.52,2.12,13.37,6.38,8.89,7.5,16.69,11.26,23.09,10.18,4.32-.81,8.96-2.66,13.67-4.71,16.92-5.73,26.5-3.91,29.47,4.71-.38,3.95-.52,7.69.76,10.18,2.01,3.93,1.34,7.88-1.67,11.85-.17,7.89-3.55,8.93-8.2,7.29-7.9-2.55-15.93-.69-24,2.89-7.52,1.93-14.64,3.57-19.29,3.34-4.42.78-7.91,2.69-11.12,4.93-1.53,1.07-3.57,1.12-5.12.07-2.65-1.82-6.16-5.49-10.19-10.31,8.08.45,3.11-50.7-.77-46.8h0Z"/>
  <path id="Dornfortsätze_Highlight1" data-name="Dornfortsätze Highlight1" class="Dornfortsatz1614Fill" style= {{
            fill: props.colors.Dornfortsatz1614Fill,
            stroke: props.colors.Dornfortsatz1614Fill,
            opacity:props.colors.Dornfortsatz1614Fill,
          }} d="M117.39,44.9h0c7.03,1.08,13.53.76,19.3-1.49,1.81-.71,3.71-1.17,5.65-1.21,5.3-.11,9.08.36,9.08,2.29,0,.75-.6,1.36-1.33,1.54-7.67,1.9-12.86,4.05-10.83,6.93,3.06,5.15,8.03,7.98,15.65,7.59,7.2-.27,15.04-2.92,23.39-7.44,4.44-1.2,8.35.21,11.39,5.92.15.87.25,1.71.31,2.53.48,7.12-4.8,13.34-11.85,14.42l-7.95,1.22c-1.25.19-2.47.56-3.57,1.17-5.11,2.8-15.57,1.64-30.26-2.62-11.1-.96-18.84-2.69-22.59-5.32-.97-.68-.06-.45-1.22-.65,0,0,3.68-12.84,4.83-24.88h0Z"/>
</g>
<g id="_7HWK_Highlights" data-name=" 7HWK Highlights">
  <path id="_7HWK_Highlight1" data-name=" 7HWK Highlight1" class="HWK7th_1614Fill" style= {{
            fill: props.colors.HWK7th_1614Fill,
            stroke: props.colors.HWK7th_1614Fill,
            opacity:props.colors.HWK7th_1614Fill,
          }} d="M163.56,324.25h0c9.01,1.66,13.05-2.75,12.76-12.46-6.68-14.18-7.29-21.47-1.82-21.87,8.1,5.25,13.92,10.99,17.01,17.32,8.48-1.11,16.37.05,23.7,3.34,24.27,6.04,46.7,8.89,66.23,6.68,4.9-1.93,9.85-3.34,15.49,2.73,3.72,5.08,5.22,9.79,1.52,13.67l-31.29,10.03c-11.81,7.09-25.47,10.47-41.32,9.42-13.59-8.44-26.39-15.74-33.42-14.73-9.87,3.17-13.3,7.54-13.06,12.61.95,3.87-18.35-26.13-15.8-26.74h0Z"/>
</g>
<g id="Deck_u_Bodenplatten_Highlights" data-name="Deck u Bodenplatten Highlights">
  <path id="Deck_u_Bodenplatten_Highlight14" data-name="Deck u Bodenplatten Highlight14" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M111.03,66.71h0c-7.49-.76-14.92-2.01-22.25-3.76-1.8-.43-3.58-.98-5.4-1.29-1.86-.33-3.56-.23-5.41.06-3.17.5-6-.13-8.2-2.59-1.72-1.92-4.54.91-2.83,2.83,2.25,2.52,5.59,4.03,8.98,3.99,1.85-.02,3.64-.64,5.5-.53,1.97.11,3.93.82,5.84,1.28,7.82,1.89,15.78,3.21,23.78,4.01,1.08.11,2-1,2-2,0-1.17-.92-1.89-2-2h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight13" data-name="Deck u Bodenplatten Highlight13" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M117.45,83.74h0c-1.76-3.06-5.59-4.35-8.84-5.14-1.89-.46-3.73-.45-5.61,0-1.69.4-3.29,1.09-4.98,1.47-1.78.39-2.85-.15-4.33-1.09-1.43-.9-2.99-1.44-4.67-1.64-3.36-.41-6.72.71-9.2,3.01-1.89,1.75.94,4.57,2.83,2.83,1.82-1.68,4.48-2.32,6.88-1.7,1.43.37,2.48,1.32,3.78,1.96,1.42.7,2.93.9,4.49.7,2.96-.37,5.85-2.26,8.87-1.86,1.48.2,3.01.77,4.39,1.32,1.17.46,2.3,1.05,2.94,2.17,1.28,2.23,4.74.22,3.45-2.02h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight12" data-name="Deck u Bodenplatten Highlight12" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M118.27,126.09c-3.45.23-5.93-2.81-8.04-5.13-2.43-2.68-5.49-5.37-9.27-5.61-1.85-.12-3.57.56-5.14,1.48-1.29.76-2.66,1.86-4.25,1.45-3.45-.9-4.78-5.57-6.71-8.12-2.53-3.34-5.94-5.76-9.89-7.14-2.44-.85-3.48,3.01-1.06,3.86,3.21,1.12,6.13,3.26,8.06,6.08,2.18,3.17,3.59,7.04,7.34,8.72,1.71.77,3.6.92,5.38.26,1.66-.62,3.04-1.89,4.72-2.42,3.4-1.08,6.51,2.66,8.51,4.85,2.7,2.95,6.06,6.01,10.35,5.72,2.56-.17,2.57-4.17,0-4Z"/>
  <path id="Deck_u_Bodenplatten_Highlight11" data-name="Deck u Bodenplatten Highlight11" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M115.78,134.77c-1.23-2.88-4.09-5.73-7.37-3.7-1.27.79-2.04,2.11-2.84,3.33-.82,1.26-1.89,2.08-3.22,2.77-3.41,1.76-7.13,3.02-10.68,4.48-3.55,1.46-7.25,2.75-10.66,4.53-3.44,1.79-6.61,4.71-7.32,8.69-.19,1.06.29,2.16,1.4,2.46.97.27,2.27-.33,2.46-1.4.68-3.81,4.15-5.78,7.41-7.21,4.05-1.77,8.18-3.38,12.26-5.06,3.48-1.43,7.82-2.59,10.45-5.44.59-.64,1.07-1.36,1.53-2.08.28-.44.74-1.47,1.27-1.66.93-.32,1.59,1.71,1.85,2.31.42.99,1.89,1.21,2.74.72,1.03-.6,1.14-1.74.72-2.74h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight10" data-name="Deck u Bodenplatten Highlight10" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M115.54,171.48h0c-.69-.69-2.16-.85-2.83,0-.79,1.01-2.23.81-3.39.81-1.58,0-3.15.06-4.72.18-3.39.25-6.77.75-10.06,1.61-3.18.84-6.3,2.03-9.18,3.61-2.09,1.14-5.45,4.4-7.99,2.94-2.24-1.28-4.26,2.18-2.02,3.45,3.5,2,6.98.16,10.02-1.75,3.05-1.93,6.34-3.33,9.83-4.28,3.5-.96,7.08-1.47,10.7-1.66,3.04-.16,7.5.66,9.65-2.08.67-.85.83-2,0-2.83h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight9" data-name="Deck u Bodenplatten Highlight9" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M115.11,182.05v-.02c-2.06-.33-4.14.41-6.03,1.17-4.49,1.78-8.73,4.24-13.02,6.44-4.42,2.26-8.85,4.49-13.25,6.79-1.82.95-3.54,2.11-4.59,3.93-1.11,1.92-1.38,4.21-1.31,6.39.08,2.57,4.08,2.58,4,0-.05-1.53,0-3.2.87-4.54.83-1.29,2.31-1.94,3.63-2.62,3.63-1.86,7.26-3.72,10.9-5.58,3.52-1.8,7.03-3.63,10.57-5.41,1.62-.82,3.27-1.6,5-2.15.71-.23,1.5-.53,2.26-.5.16,0,.34.06.5.09.42,2.17,3.99,2.05,3.95-.39-.03-2.03-1.57-3.3-3.47-3.6h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight8" data-name="Deck u Bodenplatten Highlight8" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M125.87,217.4h0c-.7-.7-2.15-.85-2.83,0-2.18,2.7-6.61,1.73-9.64,1.85-4.06.16-8.12,1-11.9,2.5-3.84,1.52-7.37,3.6-10.53,6.26-1.56,1.31-2.88,2.84-4.33,4.27-.67.66-2.64,2.26-3.4.86-1.22-2.27-4.68-.25-3.45,2.02,1.76,3.26,6.01,2.92,8.57.92,1.41-1.1,2.51-2.52,3.79-3.75s2.66-2.35,4.13-3.35c5.9-3.99,12.67-5.81,19.76-5.73,3.45.04,7.47-.09,9.82-3.01.68-.84.82-2.01,0-2.83h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight7" data-name="Deck u Bodenplatten Highlight7" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M125.33,225.45h0c-3.61.71-3.13,4.79-5.4,6.81-1.29,1.15-3.01,1.94-4.51,2.77-1.9,1.05-3.82,2.06-5.77,3.02-3.73,1.84-7.6,3.33-11.39,5.02-3.72,1.67-6.78,4.36-7.34,8.57-.14,1.07,1.02,2,2,2,1.2,0,1.86-.92,2-2,.45-3.38,4.69-4.86,7.36-5.94,3.61-1.47,7.16-3.06,10.64-4.82,3.2-1.62,6.8-3.21,9.55-5.55,1.19-1.01,1.91-2.24,2.49-3.67.29-.73.55-2.19,1.43-2.36,2.52-.5,1.46-4.36-1.06-3.86h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight6" data-name="Deck u Bodenplatten Highlight6" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M135.61,261.95h0c-.89,3.27-3.93,5.36-7,6.4-4.24,1.45-8.78,1.64-13.06,2.91-4,1.19-7.84,2.99-11.32,5.28-1.75,1.15-3.32,2.51-4.99,3.77-.48.36-2.73,1.86-3.08.56-.68-2.48-4.54-1.43-3.86,1.06,1,3.66,5.36,4.06,8.19,2.35,1.61-.98,2.99-2.35,4.52-3.44,1.58-1.12,3.23-2.13,4.94-3.03,3.39-1.77,7-2.96,10.75-3.68,3.87-.74,7.8-1.21,11.39-2.92,3.43-1.64,6.36-4.48,7.38-8.21.68-2.49-3.18-3.55-3.86-1.06h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight5" data-name="Deck u Bodenplatten Highlight5" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M146.99,272.01h0c-3.72.23-6.58,3.16-9.54,5.11-3.23,2.13-6.57,4.07-9.93,5.98-3.34,1.9-6.68,3.96-10.35,5.15-3.39,1.1-7.14,1.51-10.19,3.51-3.21,2.11-5.44,5.75-4.53,9.65.59,2.51,4.44,1.44,3.86-1.06-.87-3.73,3.62-5.91,6.46-6.75,2.1-.62,4.26-1.04,6.32-1.79,2.02-.73,3.95-1.67,5.84-2.68,3.93-2.11,7.81-4.35,11.61-6.69,1.84-1.14,3.65-2.32,5.42-3.57,1.46-1.03,3.16-2.75,5.04-2.87,2.56-.16,2.57-4.16,0-4h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight4" data-name="Deck u Bodenplatten Highlight4" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M157.91,313.45v-.02c-8.68.98-17.09,3.6-24.75,7.84-3.7,2.05-6.97,4.58-10.35,7.1-1.47,1.09-3.1,2.23-4.96,2.5-2.32.33-4.66-.81-6.7-1.76-.98-.46-2.16-.27-2.74.72-.51.87-.27,2.28.72,2.74,2.23,1.04,4.47,2.03,6.95,2.3,2.26.24,4.47-.41,6.43-1.49,1.78-.98,3.32-2.33,4.92-3.56,1.77-1.37,3.63-2.63,5.54-3.79,7.57-4.6,16.13-7.58,24.93-8.58,1.07-.12,2-.83,2-2,0-.99-.92-2.12-2-2h.01Z"/>
  <path id="Deck_u_Bodenplatten_Highlight3" data-name="Deck u Bodenplatten Highlight3" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M162,321.74h0c-8.28,2.98-15.87,7.43-23.5,11.75-3.81,2.16-7.65,4.27-11.62,6.12-3.48,1.62-7.57,2.71-9.05,6.65-.38,1.01.42,2.19,1.4,2.46,1.12.31,2.08-.38,2.46-1.4.51-1.35,2.22-2.04,3.43-2.59,1.79-.8,3.59-1.56,5.36-2.42,3.68-1.79,7.25-3.78,10.8-5.8,7.06-4.01,14.11-8.16,21.78-10.92,2.4-.86,1.37-4.73-1.06-3.86h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight2" data-name="Deck u Bodenplatten Highlight2" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M179.97,360.65h.01c-.01,3.75-2.47,6.88-4.78,9.61-1.29,1.52-2.45,2.93-4.45,3.45-1.81.47-3.69.54-5.49,1.02-7.73,2.04-12.87,8.45-19.23,12.81-3.68,2.53-7.77,4.43-12.33,3.87-1.07-.13-2,1.01-2,2,0,1.19.92,1.87,2,2,9.06,1.12,16.15-5.75,22.58-11.02,3.15-2.58,6.59-5.03,10.62-5.95,1.94-.44,3.96-.48,5.84-1.16,2.11-.76,3.58-2.21,5.01-3.88,3.13-3.66,6.2-7.72,6.22-12.75,0-2.57-4-2.58-4,0h0Z"/>
  <path id="Deck_u_Bodenplatten_Highlight1" data-name="Deck u Bodenplatten Highlight1" class="Platten1614Fill" style= {{
            fill: props.colors.Platten1614Fill,
            stroke: props.colors.Platten1614Fill,
            opacity:props.colors.Platten1614Fill,
          }} d="M116.73,45.78h0c-.74-3.89-5.72-4.24-8.81-4.83-4.11-.79-8.32-1.65-12.06-3.63-3.41-1.81-6.53-4.46-10.4-5.24-3.37-.68-7.19-.13-9.52,2.62-1.66,1.95,1.16,4.79,2.83,2.83,1.69-1.99,4.46-2.03,6.8-1.29,3.28,1.04,5.99,3.44,9.06,4.94,3.24,1.58,6.72,2.52,10.24,3.24,1.76.36,3.52.67,5.28,1.03.88.18,2.52.34,2.72,1.4.48,2.53,4.33,1.46,3.86-1.06h0Z"/>
</g>
</svg>
  );
}

export default HalswirbelsauleRechtsAnliegendLatStehend1614;
