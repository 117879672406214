import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addRemoveExamArchiveModality,
  clearEamArchiveFilter,
  clearSelectedExaminations,
  setExamArchiveFilter,
  setExamArchiveFromDate,
  setExamArchivePatientSearch,
  setExamArchiveToDate,
} from "../Redux/actions";
import ExamRow from "../Components/PatientStudies/ExamRow";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { OpenExaminationsInPmView } from "../Utilities/ClientServices/ClientServices";
import { useStudies } from "../Utilities/FetchHooks/Studies/StudiesHooks";

//stylesheets
import "react-day-picker/lib/style.css";
import "./stylesheets/ExamArchive.scss";

function ExamArchiveFiltering() {
  const dispatch = useDispatch();
  const { patientSearch, modalities, fromDate, toDate, filter } = useSelector(
    (state) => state.examArchive
  );

  useEffect(() => {
    dispatch(setExamArchiveFilter());
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        dispatch(setExamArchiveFilter());
      }}
    >
      <div
        className="d-flex rounded py-2"
        style={{
          height: "10vh",
          backgroundColor: "#3D3D3D",
          color: "#fff",
          gap: "15px",
        }}
      >
        <div className="d-flex flex-column ml-3">
          <div>Patient Name</div>
          <input
            value={patientSearch}
            onChange={(e) => {
              dispatch(setExamArchivePatientSearch(e.target.value));
            }}
            type="text"
            placeholder="Patient ..."
            className="pl-1"
            style={{
              width: "350px",
              fontSize: "1.3rem",
              borderRadius: "6px",
            }}
          />
        </div>
        <div className="d-flex flex-column ml-3">
          <div title="if none checked all modalities displayed">Modalities</div>
          <Form.Row>
            <Form.Check
              style={{
                width: "80px",
              }}
              type="switch"
              id="XRay-switch"
              label="XRay"
              checked={modalities.includes("xray")}
              onChange={() => dispatch(addRemoveExamArchiveModality("xray"))}
            />
            <Form.Check
              style={{
                width: "80px",
              }}
              type="switch"
              id="CT-switch"
              label="CT"
              checked={modalities.includes("ct")}
              onChange={() => dispatch(addRemoveExamArchiveModality("ct"))}
            />
            <Form.Check
              style={{
                width: "80px",
              }}
              type="switch"
              id="MRI-switch"
              label="MR"
              checked={modalities.includes("mr")}
              onChange={() => dispatch(addRemoveExamArchiveModality("mr"))}
            />
          </Form.Row>
          <Form.Row>
            <Form.Check
              style={{
                width: "80px",
              }}
              type="switch"
              id="MG-switch"
              label="MG"
              checked={modalities.includes("mg")}
              onChange={() => dispatch(addRemoveExamArchiveModality("mg"))}
            />
            <Form.Check
              style={{
                width: "80px",
              }}
              type="switch"
              id="USG-switch"
              label="US"
              checked={modalities.includes("us")}
              onChange={() => dispatch(addRemoveExamArchiveModality("us"))}
            />
          </Form.Row>
        </div>
        <div className="d-flex ml-3 flex-column">
          <div>From:</div>
          <input
            style={{
              fontSize: "1.3rem",
              borderRadius: "6px",
            }}
            value={fromDate}
            onChange={(e) => dispatch(setExamArchiveFromDate(e.target.value))}
            type="date"
          />
        </div>
        <div className="d-flex ml-3 flex-column">
          <div>To:</div>
          <input
            style={{
              fontSize: "1.3rem",
              borderRadius: "6px",
            }}
            value={toDate}
            onChange={(e) => dispatch(setExamArchiveToDate(e.target.value))}
            type="date"
          />
        </div>
        <div className="d-flex ml-3 ml-auto mr-2" style={{ gap: "10px" }}>
          <Button
            size="lg"
            variant="danger"
            disabled={filter === null}
            style={{ borderRadius: "16px" }}
            onClick={() => dispatch(clearEamArchiveFilter())}
          >
            <i className="fas fa-times fa-sm" />
            <div>Clear</div>
          </Button>
          <Button
            onClick={() => dispatch(setExamArchiveFilter())}
            size="lg"
            variant="dark"
            style={{ borderRadius: "16px" }}
          >
            <i className="fas fa-search fa-sm" />
            <div>Search</div>
          </Button>
        </div>
      </div>
    </form>
  );
}

function BottomControll(props) {
  return (
    <div
      className="d-flex rounded mb-1 p-2"
      style={{
        height: "8vh",
        backgroundColor: "#3D3D3D",
        color: "#fff",
      }}
    >
      <Button
        className="mr-3 text-center"
        onClick={props.clearExaminations} // style={{ width: "140px" }}
        variant="dark"
        style={{ borderRadius: "16px" }}
      >
        Clear Selected Examinations
      </Button>
      <Button
        className="mr-3 text-center"
        onClick={props.openSelectedExams} // style={{ width: "140px" }}
        variant="dark"
        style={{ borderRadius: "16px" }}
      >
        Show Selected Examinations
      </Button>
    </div>
  );
}

function PaginationControlls(props) {
  return (
    <div
      className="d-flex justify-content-center mt-auto rounded my-1 py-1"
      style={{
        gap: "5px",
        backgroundColor: "#3D3D3D",
        color: "#fff",
      }}
    >
      <Button
        onClick={() => {
          props.setCurrentPage(1);
        }}
        disabled={props.currentPage === 1}
        variant="dark"
        style={{ borderRadius: "16px" }}
      >
        <i className="fas fa-chevron-left fa-sm" />
        <i className="fas fa-chevron-left fa-sm" />
      </Button>
      <Button
        onClick={() => {
          props.setCurrentPage(props.currentPage - 1);
        }}
        disabled={props.currentPage === 1}
        variant="dark"
        style={{ borderRadius: "16px" }}
      >
        <i className="fas fa-chevron-left fa-sm" />
      </Button>
      <div className="border rounded d-flex align-items-center justify-content-center px-3">
        {props.currentPage}/
        {Math.ceil(props.ExamsQuery?.data?.total_count / props.pageSize)}
      </div>
      <Button
        onClick={() => {
          props.setCurrentPage(props.currentPage + 1);
        }}
        disabled={
          props.currentPage ===
          Math.ceil(props.ExamsQuery?.data?.total_count / props.pageSize)
        }
        variant="dark"
        style={{ borderRadius: "16px" }}
      >
        <i className="fas fa-chevron-right fa-sm" />
      </Button>
      <Button
        disabled={
          props.currentPage ===
          Math.ceil(props.ExamsQuery?.data?.total_count / props.pageSize)
        }
        onClick={() => {
          props.setCurrentPage(
            Math.ceil(props.ExamsQuery?.data?.total_count / props.pageSize)
          );
        }}
        variant="dark"
        style={{ borderRadius: "16px" }}
      >
        <i className="fas fa-chevron-right fa-sm" />
        <i className="fas fa-chevron-right fa-sm" />
      </Button>
    </div>
  );
}

function SortController({ sorting, setSorting }) {
  const handleSortSelection = (sortKey) => {
    if (!sorting) {
      setSorting({ field: sortKey, direction: "asc" });
      return;
    }
    if (sorting.field === sortKey) {
      if (sorting.direction === "asc") {
        setSorting({ field: sortKey, direction: "desc" });
      } else {
        setSorting(null);
      }
      return;
    } else {
      setSorting({ field: sortKey, direction: "asc" });
    }
  };

  const getIcon = (sortKey) => {
    if (sorting && sortKey === sorting.field) {
      if (sorting.direction === "asc") {
        return <i className="fas fa-chevron-up ml-1" />;
      } else {
        return <i className="fas fa-chevron-down ml-1" />;
      }
    }

    return <></>;
  };

  return (
    <div
      className="sort-bar"
      style={{
        backgroundColor: "#3D3D3D",
        color: "#fff",
      }}
    >
      <div style={{ cursor: "default" }}>Select</div>
      <div onClick={() => handleSortSelection("modality")} className="sort-btn">
        Modality
        {getIcon("modality")}
      </div>
      <div
        onClick={() => handleSortSelection("patientsname")}
        className="sort-btn"
      >
        Patient
        {getIcon("patientsname")}
      </div>
      <div style={{ cursor: "default" }}>Id</div>
      <div
        onClick={() => handleSortSelection("studydescription")}
        className="sort-btn"
      >
        Description
        {getIcon("studydescription")}
      </div>
      <div
        onClick={() => handleSortSelection("studydate")}
        className="sort-btn"
      >
        Date
        {getIcon("studydate")}
      </div>
      <div style={{ cursor: "default" }} className="sort-btn">
        Dosis
      </div>
      <div style={{ cursor: "default" }} className="sort-btn">
        Open
      </div>
      <div style={{ cursor: "default" }} className="sort-btn">
        Show
      </div>
    </div>
  );
}

function ExamArchive() {
  const { keycloak } = useKeycloak();
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );
  const [sort, setSort] = useState(window.conf.EXAM_ARCHIVE.defaultSort);
  const { filter } = useSelector((state) => state.examArchive);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter, sort]);

  const ExamsQuery = useStudies({
    pageSize,
    pageNr: currentPage,
    keepPreviousData: false,
    sort: sort,
    filter: filter,
    xmask:
      "total_count,studies{id,patient,dapatid,patientsname,accessionnumber,patientssize,patientsweight,studydate,modality,studydescription,studyinstanceuid,myg,avgmyg}",
  });

  const openSelectedExams = () => {
    if (selectedExaminations.length === 0) {
      NotificationManager.warning("Select examinations first");
      return;
    }
    let body = {
      Version: window.conf.CLIENT_SERVICES.API_VERSION,
      ExamsSelIDsList: {
        selected_exams: selectedExaminations,
        is_for_viewer: true,
      },
    };
    OpenExaminationsInPmView(keycloak, body)
      .then((resp) => {
        NotificationManager.success("Examinations opened in PmView");
      })
      .catch((reason) => {
        console.warn("Unable to open exam in pmView : ", reason);
        NotificationManager.error("Unable to open examinations in PmView");
      });
  };

  const clearExaminations = () => {
    if (selectedExaminations.length > 0) {
      dispatch(clearSelectedExaminations());
    } else {
      NotificationManager.warning("No examinations selected");
    }
  };

  useEffect(() => {
    dispatch(clearSelectedExaminations());
  }, []);

  return (
    <div
      className="px-2 py-2 d-flex flex-column"
      style={{ height: "100vh", backgroundColor: "#292929" }}
    >
      <ExamArchiveFiltering />
      <SortController sorting={sort} setSorting={setSort} />
      <div
        className="d-flex flex-column bg-red rounded my-1 p-1"
        style={{
          height: "80vh",
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: "#3D3D3D",
          // color: "#fff",
        }}
      >
        {ExamsQuery.status === "loading" ? (
          <>Loading</>
        ) : (
          ExamsQuery?.data?.studies?.map((exam, key) => (
            <ExamRow
              exam_data={exam}
              openPatient={true}
              showPatient={true}
              key={`examArchiveRowKey${key}`}
              dark={true}
            />
          ))
        )}
      </div>
      <PaginationControlls
        pageSize={pageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        ExamsQuery={ExamsQuery}
      />
      {/* <BottomControll
        openSelectedExams={openSelectedExams}
        clearExaminations={clearExaminations}
      /> */}
    </div>
  );
}

export default ExamArchive;
