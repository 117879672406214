import style from "./style.scss";

function Linker3FingerLatSitzend1321(props) {
  return (
    <svg
      id="thirdFinger"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 232.4 489.2"
    >
      <g id="secondfinger">
        <g id="background">
          <path
            id="background_white"
            data-name="background white"
            class="linker_finger3_lat_sitzend1321_cls7"
            d="M.5,488c5.7-16.6,14.1-30.8,26-42.1,4.2-4,8-8.2,11.4-12.9,14-19,25.3-39.9,31.5-64.1,12.1-55.8,23.3-99.7,32.3-114.5,1.5-35.4,11.1-73.4,22.3-111.9,9.4-35.8,14.3-78.7,16.2-126.3.2-4.2,2.1-8.1,5.4-10.7,5.4-4.1,12.7-5.5,21.5-4.8,19.7,3.7,30,20.2,34.6,44.5l5.8,77.9c4.1,26.5,2.8,55.9-3.8,88.2,1.3,50.7-1.2,94.9-10,127.9l37.1-25.5-.2,29.8-1.1,19.8,2.5,19.8c-28.1,18.1-55,42.5-79.3,80.1-8.9,12.6-17.2,19.4-25,18.9l-17.6-1.4-85.2,8.2-24.6-1.1h0l.2.2Z"
          />
        </g>
        <g id="secondfinger1">
          <path
            id="fingerpart20"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M134.8,368.9c1.5-24.7,6.2-46.9,14.6-65.9,3.5-9.7,9.2-18,19.8-24.6,8.6-8.3,10-18.2,0-30.7-10.2-7.8-22-11.7-36.5-8.8-9.7.9-15.2,11-14.9,33.4-11.5,28.5-21.9,60.3-31.6,94.8-3,8.5-9.3,26.1-14.6,41.1-6.9,14.7-6.8,25.8,3.5,31.6,1.6.9,3.5,1.2,5.4,1.1,9-.5,16.6,3.9,23.7,8.2,17.6,5.9,30.1,3.8,32-14.8-2.2-23.5-3.3-46-1.4-65.5h0Z"
          />
          <path
            id="fingerpart19"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M118,234.9c-6.2-1.7-3.9-8.8,2.4-18.5,7.9-36.9,15-76.7,25.5-98.7-.2-8.4,3.5-14.7,11.9-18.5,7.5-1.7,14.5-.4,20.1,9.1,4.7,6.4,2.2,12.6-7.3,18.5-5.2,9.1-8.7,20.7-10.3,34.9,2.1,6.4,2.8,18,1.8,35.9.4,8.9,2.6,16.9,8.5,23.1-.5,14.1-4.8,19.6-11.5,19.5-11.7-6-23.3-6.2-34.6,0-4.2,2.8-5.9.2-6.4-5.2h0Z"
          />
          <path
            id="fingerpart18"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M154.5,94.4c-4.8,5.6-7.9,5.3-9.6,0-1.8-4.1-.2-8.6,2.4-13.4V23.7c0-1.3.5-2.6,1.4-3.5,2.3-2.3,5.3-3,9.3-1.6,1.4.5,2.6,1.4,3.4,2.5,5.2,7,8.3,14.1,4.2,21.8,2.4,10.1,5.9,19.6,11.5,27.8,4.6,6.6,6.7,12.5,2.3,16.9-.7,7.6-2.4,10.8-5.9,6.8-5.9-3.1-12.3-3-19,0h0Z"
          />
          <path
            id="fingerpart17"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M122.9,257.7c1.3-8.3,5.4-14.5,16.7-15.5,10.3-.2,18.9,3.3,22.9,16.4,2.8,9.4-.3,15.6-8.4,19.1-4.5,2-6.5,5.2-6.9,10.5"
          />
          <path
            id="fingerpart16"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M127.9,226.1c9.8.8,19,1.2,27.3.9"
          />
          <path
            id="fingerpart15"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M130,233.1c11-2.6,21.4-3.2,30.8-.8"
          />
          <path
            id="fingerpart14"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M121.4,218.9c8.6-7.3,17.2-29.5,25.9-81.9,1.3-7.7,3.6-27.5,3.6-27.5"
          />
          <path
            id="fingerpart13"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M176.1,109.6c-.7,5.4-4.3,9.7-11.6,12.6-8.3,11-12.7,25.4-14.3,42.1-2.6,20.4,2,37.1,10.6,51.8"
          />
          <path
            id="fingerpart12"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M155.3,90.1c5.8-2.4,11.5-2.4,17.3,0"
          />
          <path
            id="fingerpart11"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M147.3,81c4.5-2.4,7.5-9.7,5.7-32.5-1.3-6.5.5-14.7-1.5-22.5"
          />
          <line
            id="fingerpart10"
            class="linker_finger3_lat_sitzend1321_cls2"
            x1="162.1"
            y1="34.4"
            x2="165.6"
            y2="42.9"
          />
          <path
            id="fingerpart9"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M158.4,35.1c0,17.3,5.1,32.4,17.6,44.2"
          />
          <path
            id="fingerpart8"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M157.5,196.3c-.9-12.9-.3-24.6,2.7-34.6"
          />
          <g id="outlines2">
            <path
              id="outlinespart6"
              class="linker_finger3_lat_sitzend1321_cls2"
              d="M181.9,369.9c1-1.7,1.9-3.5,2.8-5.3"
            />
            <path
              id="outlinespart5"
              class="linker_finger3_lat_sitzend1321_cls1"
              d="M189.3,353.7c12.6-34,16.1-83.8,14.6-142.4,6.6-32.3,7.9-61.8,3.8-88.2l-5.8-77.9c-4.6-24.3-14.9-40.8-34.6-44.5-8.8-.7-16.1.7-21.5,4.8-3.3,2.5-5.3,6.5-5.4,10.7-1.9,47.6-6.8,90.4-16.2,126.3-11.2,38.5-20.8,76.5-22.3,111.9-9,14.8-20.3,58.8-32.3,114.5-6.3,24.2-17.6,45-31.5,64.1-3.4,4.6-7.3,8.9-11.5,12.9-9.1,8.7-16.2,19.1-21.6,31"
            />
            <path
              id="outlinespart4"
              class="linker_finger3_lat_sitzend1321_cls2"
              d="M2.5,482.3c-.7,1.9-1.4,3.7-2.1,5.6"
            />
          </g>
          <path
            id="fingerpart7"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M122.9,270.4c-2.7,26.6-10.7,59.7-22.3,97.2-6.3,19-15.9,32.5-29,40.5"
          />
          <path
            id="fingerpart6"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M145.5,280.6c-12.3,21-21.4,50.7-27.3,89.3-2.2,19.3-.7,37.5,6.7,54.1"
          />
          <path
            id="fingerpart5"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M84.3,426.4c9.1.1,18.2,2.4,27.3,6.8"
          />
          <line
            id="fingerpart4"
            class="linker_finger3_lat_sitzend1321_cls2"
            x1="81.1"
            y1="433.2"
            x2="114.4"
            y2="443"
          />
          <path
            id="fingerpart3"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M230.8,343.6l-79.3,48.6-48.8,22.9c-7.9,5.7-9.3,13.7-6.5,23.2.5,1.8,1.5,3.3,2.9,4.5,7.5,6.7,12.1,14.5,13.2,23.5.3,2.1.9,4.1,2.1,5.9,9.5,14.8,21.3,12.7,34.9-3.1,22.6-37.4,50.4-65.8,82.9-85.9"
          />
          <path
            id="fingerpart2"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M229.7,363.4c-30.1,20.1-59,39-76.8,46.5-11.5,5.7-22.6,6-33.6,4.6"
          />
          <path
            id="fingerpart1"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M229.7,372.7c-25.2,19-49.1,37.4-62,51.7-9.5,10.2-14.1,23.4-14.8,38.9"
          />
          <g id="outlines">
            <path
              id="outlinespart3"
              class="linker_finger3_lat_sitzend1321_cls2"
              d="M143.5,379.5c1.5-1.3,3-2.6,4.5-4"
            />
            <path
              id="outlinespart2"
              class="linker_finger3_lat_sitzend1321_cls1"
              d="M156.3,368.5c20.4-17.1,42.2-33.1,65.2-48.4"
            />
            <path
              id="outlinespart1"
              class="linker_finger3_lat_sitzend1321_cls2"
              d="M225.9,317c1.7-1.1,3.3-2.2,5-3.3"
            />
          </g>
          <path
            id="fingerpart22"
            class="linker_finger3_lat_sitzend1321_cls2"
            d="M25.1,489c-2.9-8-.2-17,13-28.2,16.2-13.8,33.5-19,52.4-11.6,11.4,6.8,19,16.6,19.8,31.7"
          />
        </g>
      </g>
      <g id="highlights">
        <path
          id="highlights3"
          class="fullfinger1321fill"
          style={{
            fill: props.colors.fullfinger1321fill,
            stroke: props.colors.fullfinger1321fill,
          }}
          d="M154.5,94.4c-4.8,5.6-7.9,5.3-9.6,0-1.8-4.1-.2-8.6,2.4-13.4V23.7c0-1.3.5-2.6,1.4-3.5,2.3-2.3,5.3-3,9.3-1.6,1.4.5,2.6,1.4,3.4,2.5,5.2,7,8.3,14.1,4.2,21.8,2.4,10.1,5.9,19.6,11.5,27.8,4.6,6.6,6.7,12.5,2.3,16.9-.7,7.6-2.4,10.8-5.9,6.8-5.9-3.1-12.3-3-19,0h0Z"
        />
        <path
          id="highlights2"
          class="fullfinger1321fill"
          style={{
            fill: props.colors.fullfinger1321fill,
            stroke: props.colors.fullfinger1321fill,
          }}
          d="M118,234.9c-6.2-1.7-3.9-8.8,2.4-18.5,7.9-36.9,15-76.7,25.5-98.7-.2-8.4,3.5-14.7,11.9-18.5,7.5-1.7,14.5-.4,20.1,9.1,4.7,6.4,2.2,12.6-7.3,18.5-5.2,9.1-8.7,20.7-10.3,34.9,2.1,6.4,2.8,18,1.8,35.9.4,8.9,2.6,16.9,8.5,23.1-.5,14.1-4.8,19.6-11.5,19.5-11.7-6-23.3-6.2-34.6,0-4.2,2.8-5.9.2-6.4-5.2h0Z"
        />
        <path
          id="highlights1"
          class="fullfinger1321fill"
          style={{
            fill: props.colors.fullfinger1321fill,
            stroke: props.colors.fullfinger1321fill,
          }}
          d="M134.8,368.1c1.5-24.7,6.2-46.9,14.6-65.9,3.5-9.7,9.2-18,19.8-24.6,8.6-8.3,10-18.2,0-30.7-10.2-7.8-22-11.7-36.5-8.8-9.7.9-15.2,11-14.9,33.4-11.5,28.5-21.9,60.3-31.6,94.8-3,8.5-9.3,26.1-14.6,41.1-6.9,14.7-6.8,25.8,3.5,31.6,1.6.9,3.5,1.2,5.4,1.1,9-.5,16.6,3.9,23.7,8.2,17.6,5.9,30.1,3.8,32-14.8-2.2-23.5-3.3-46-1.4-65.5h0Z"
        />
        <path
          id="highlight5"
          class="fingerspitze1321fill"
          style={{
            fill: props.colors.fingerspitze1321fill,
            stroke: props.colors.fingerspitze1321fill,
          }}
          d="M147.3,32.2c6-10.9,12.6-11,19.7-.4-.9-4-2.8-7.8-6.2-11.3-4.4-4.1-10.7-4-13.2,1.5-.6,2.7-.5,6.4-.3,10.3h0Z"
        />
        <ellipse
          id="highlights7"
          class="gelenkspalte1321fill"
          style={{
            fill: props.colors.gelenkspalte1321fill,
            stroke: props.colors.gelenkspalte1321fill,
          }}
          cx="145.4"
          cy="237.9"
          rx="36.3"
          ry="12.5"
        />
        <ellipse
          id="highlights6"
          class="gelenkspalte1321fill"
          style={{
            fill: props.colors.gelenkspalte1321fill,
            stroke: props.colors.gelenkspalte1321fill,
          }}
          cx="162.6"
          cy="95.9"
          rx="28.5"
          ry="9.7"
        />
        <ellipse
          id="highlight4"
          class="grundgelenk1321fill"
          style={{
            fill: props.colors.grundgelenk1321fill,
            stroke: props.colors.grundgelenk1321fill,
          }}
          cx="92.6"
          cy="447.3"
          rx="20.5"
          ry="46.8"
          transform="translate(-363.1 415.4) rotate(-74.3)"
        />
      </g>
    </svg>
  );
}

export default Linker3FingerLatSitzend1321;
