import Controller from "../Common/Controller";

import FrontView from "./views/FrontView";

import "./styles/Controller.scss";

function HandLeftController() {
  const availableViews = [FrontView];
  return <Controller availableViews={availableViews} />;
}

export default HandLeftController;
