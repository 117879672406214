import Controller from "../Common/Controller";

import TopView from "./views/TopView";
import SideView from "./views/SideView";

import "./styles/Controller.scss";

function FootRightController() {
  const availableViews = [TopView, SideView];
  return <Controller availableViews={availableViews} />;
}

export default FootRightController;
