import style from "./style.scss";

export default function RechteHufteVdOblLiegend2405(props) {
  return (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 498.95 353.88">

    <g id="Ebene_3" data-name="Ebene 3">
      <path id="BG" class="Rechte_Hufte_vd_obl_liegend2405_cls6" d="M332.97,352.72c-13.4-21.4-21.7-44.4-26.1-68.6-1.6-9.2.6-16.2,4.5-22.2l-3.2-7.2c-3.9,2.2-7.4,2.4-10.6,0-3.4-1.8-4.8-5.1-5.5-8.9-17,14.7-36.1,19.5-57.1,16.1-8.6-3.4-17.5-8.5-26.4-14.4-21.6,27-44.4,47.4-68.3,62.4-1.2,8.8-3.3,16.8-6.9,23.5-5.1,9.6-24.6,13-35.4,19.3L.17,351.02v-72.5c27.7-17.1,53-38.6,75.6-65.3,5.6-12.9,14-22.2,25.2-27.6,2.8-5,6.9-8.1,11.9-9.7,7.4-23.3,22-30.5,42.8-23.4,11.8-8.3,23.7-15,36-17.4l8.2-8.4,15.8-9c-1.9-4.3-6.3-7.5-14.5-9.2-7.3-.8-11.1-7.2-13-16.6l-3.6-11.7-6.9-13.5-11-26.8c-1-4.8-3.3-8.7-6.5-12L137.67.32l352.9,6.1.9,29.5v13.4l2.6,29.5v49.8l1.1,105.3c-9.5-1.3-15.6.6-16.3,7.4-.5,19.1-.9,38,1.2,50.1,2.8,13.1,8.1,24,18.5,30.8-8.9,16.9-29.5,24-55,27h-31.6l-79,3.5h0Z"/>
    </g>
    <g id="Ebene_2" data-name="Ebene 2">
      <path id="partoffemur6" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M98.17,352.72c11.5-6.7,28.5-9.4,32.8-15.9.7-1,1.4-2,2.2-3,3.5-4.1,6.2-13.2,7.2-24,24.2-11.9,60.1-53.2,81.7-78.1,9.4-1.9,15.6-6.9,16.7-16.9,4-7.6,5.3-15.2,4-22.9-19.3-24.6-40.4-37.6-62.9-40.7-5.6-1.3-13.6-3-22.2,1.8-19.5-7-35.6-3.4-44.7,22.8-5.4,2.4-9.6,5.5-11.9,9.7-11.4,6.1-20.2,14.9-25.2,27.6-20.8,25.5-46.6,46.7-75.6,65.3"/>
      <path id="partoffemur5" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M188.97,225.42c6.9,12.5,18.5,24.9,46,36.5,24.5,5.2,48.2-3.8,70.9-29.8,12.8-25.7,15.6-49.9,6.4-72.3-11.6-22.3-28.2-38.1-52.9-43.1-22.1-5.9-41.7-1.8-59.5,10l-20,24.6c-6.7,5.5-14.2,5.4-22.2,1.8"/>
      <path id="partoffemur4" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M193.37,134.82c-13.2,2.6-25.5,8.6-36.9,17.9-14.1,14.2-28.6,24.9-43.4,32"/>
      <path id="partoffemur3" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M179.57,206.62c8.8-12,12.6-24.2,11.5-36.5-7.9-12.4-19.9-14.8-33.4-11.9-12.2,4.6-20.1,14.2-25,27.3-2.7,7.3-6.9,13.9-12.4,19.4-6.5,6.6-14.8,13.9-22.8,22.2"/>
      <path id="partoffemur2" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M173.17,263.32l18.1-13c18-9.1,34-5.3,49,3.9"/>
      <path id="partoffemur1" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M177.77,215.12c10.9,4.5,12.2,15.5,4.8,32.2-3.5,7.9-8.3,15.3-14,21.9-10.6,12.2-19.7,26-28.1,40.7l-3,2.1c-3.5,2.5-5.7,6.4-5.8,10.6-.1,3.6.5,7.3,1.6,11.2"/>
      <path id="partofpelvis14" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M137.67.32l22,27c3.1,3.7,5.4,8,7,12.5,4.7,13.5,10.6,26.8,17.6,39.6,1.7,3.1,2.9,6.3,3.5,9.8.7,4.2,2,8.2,4.1,12.1,1.8,3.3,4.7,5.8,8.3,6.8,6.3,1.9,11.9,4.9,16.1,10.2,7.5,9.1,15.4,15.8,24.3,17.2,2.1.3,4.1,1.3,5.7,2.8,8.6,7.9,15.7,18.8,20.1,34.9.7,2.6,2,5,3.9,6.9l13.2,14c6.4,9.2,9.2,21.7,6.4,39.4-.2,1.3.2,2.7,1,3.7,2.3,2.8,2.8,5.7,1.1,8.5,2.5,9.9,7.7,13.9,16.9,8.4l2.4,7.7c-5.1,8-5.6,16.9-3.9,26.1,4.7,24.3,12.8,46.1,25.5,64.7"/>
      <path id="partofjoint4" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M188.67,71.42c.4,5,2,9.4,5.7,12.8,1.6,1.4,2.4,3.5,2.5,5.6.1,2,.5,3.8,1.1,5.3,1.5,4.1,5.6,6.7,10,6.7h1.7c1,0,2,.2,2.9.6,15.9,7,26.4,18.2,30.8,34,8.7-.7,17.5,4.7,26.2,17.2,10,12.5,7,22.7,0,32.3,0,7.4,4.7,19.9,8.5,20.9,2.6.7,4.8,2.2,6.6,4.2,5.1,5.7,8.4,12.9,11.5,20.2,4,4.4,6.9,8.4,7.3,11.5,0,5.6,1.4,9.8,5.6,11.5l2.4,7.7c4.3,7.9,6.7,20.8,9,34.2"/>
      <path id="partofjoint3" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M211.37,102.02c34.6-6.7,69.2,0,93.5,24.8"/>
      <path id="partofjoint2" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M308.97,254.22c4.4,1.6,9.8-1.4,15.3-5.4"/>
      <path id="partofjoint1" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M320.37,247.02c12-11.2,22.1-27.3,22.8-67.3-.9-36.3-19.1-61.1-51.9-76.2"/>
      <path id="partofpelvis13" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M332.97,230.82c2.2,4.3,6,5.4,10.6,4.7,2.4-.4,4.9.2,6.9,1.6,8.6,5.8,16.5,11.7,23.5,17.9,2.2,1.9,4.6,3.5,7.2,4.9,4.9,2.6,8.3,6.1,9.9,10.5,3.4,6.2,7.3,11.6,11.9,15.9,1.4,1.3,2.4,3.1,2.8,5,.8,4.3.5,9.5-.9,15.5-3.5,12.3-12.4,14.6-22,15.6,5.9.4,10.5,2.8,13.4,7.8"/>
      <path id="partofpelvis12" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M443.57,349.22c2.7-10.6,6.9-18.7,13.1-23.5,4.4-4.1,4.9-8.3,4-12.5,3-3.3,5-7.2,6.5-11.4,4.8-25.7,4.1-36.4,2.1-43.9-4.4-20.5-17.1-26.4-33.3-26.1-24.3,1.7-42-8.5-56.2-25.4-11.6-14.4-23.6-36.9-35.7-60.8,4.4,19.2,2.1,32.5-1.4,44.8"/>
      <path id="partofpelvis11" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M344.07,145.72c-4.9-15.2-8.9-40.2-12.9-66.1"/>
      <path id="partofpelvis10" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M457.07,316.02c-18.2-8.5-39-31.3-65.6-50.6"/>
      <path id="partofpelvis9" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M418.37,5.52l.8,3c.4,1.5-.8,3-2.4,2.8-6.6-.8-12.3-.2-17.3,1.9-2.6,1.1-5.6.9-8.1-.4-13.9-7.4-27.4-6.1-40.5,5.5-12.4,14.4-13.4,35.4-2.7,63.2,11.8,31.4,30.1,57.6,55,78.3,3.9,3,7,8,8.8,15.6,1.2,5.2-1.4,10.5-6.4,12.6l-8.4,3.5c-10,4.1-16.9,13.5-17.6,24.2-.2,3.7,0,7.8.6,12.3.3,1.9.2,3.8-.3,5.6-2.2,8.3-3.5,16.2-3.1,23.5,4.3,42.2,18.3,69.3,35.2,92.1"/>
      <path id="partofpelvis8" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M340.87,39.82c-2.7,8.2-3.3,19.5-.7,35.4"/>
      <path id="partofpelvis7" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M495.07,233.82c-5.2-.4-9.8.3-13.8,2.4-1.5.8-2.4,2.3-2.4,4v38.2c.5,20,6.7,34.9,19.8,43.8"/>
      <path id="partofpelvis6" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M493.97,128.52l-20.8-10.5c-8.6-4.3-15.4-11.5-19.1-20.3-3-7.3-5-16.3-6.2-26.3-.6-5.1-.8-10.2-.9-15.3-.1-7.6-1.3-13.5-3.1-18.5-1.1-2.9-2.6-5.6-4.8-7.9-3-3.2-5.3-6.5-6.6-9.9-1.6-3-3.5-4.8-5.5-6-1.2-.7-1.9-2-1.9-3.3v-3.9"/>
      <ellipse id="partofpelvis5" class="Rechte_Hufte_vd_obl_liegend2405_cls8" cx="282.97" cy="84.16" rx="6.2" ry="7.6" transform="translate(109.59 323.11) rotate(-70.6)"/>
      <path id="partofpelvis4" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M493.97,93.92c-4.7-1.2-7.7-3.5-9.5-6.5-1.4-2.2-1.9-4.8-1.1-7.3,1.2-3.9,4.7-4.4,10.6-1.4"/>
      <path id="partofpelvis3" class="Rechte_Hufte_vd_obl_liegend2405_cls9" d="M491.37,35.92c-1.6-1.6-3.2-2-4.8-1.2-2.1,1-3,3.4-2.4,5.6.9,3.6,2.9,6.8,7.3,8.9"/>
      <path id="partofpelvis2" class="Rechte_Hufte_vd_obl_liegend2405_cls7" d="M466.77,236.62c0-.8,0-1.5.1-2.3.5-6.5-.2-13.4-2.2-20.7-2.7-9.9-8.5-15.3-16.2-18-1.6-.6-3.2-1.5-4.3-2.8-2.3-2.4-1.9-4.5.7-6.4.7-.5,1.5-.9,2.3-1.2,8-3.1,15.6-7.3,21.6-17.1,3.3-4.2,3.6-9.2,1.1-15-.6-1.5-1.3-3-1.9-4.5-3.4-8.1-9.7-12.6-18.6-13.8-.7,0-1.5-.1-2.2,0-4.1.8-7,5-8.6,12.6-.6,3-2.3,5.6-4.9,7.2-2.4,1.5-4.4,1.2-5.9-.8-.9-1.1-1.3-2.5-1.3-3.9-.5-10.8-4-18.8-10.1-24.6-.7-.7-1.6-1.3-2.4-1.9-2.4-1.7-1.5-3.8.8-6.1,1.3-1.3,2.8-2.3,4.4-3.2,9.6-5.2,18.6-11,26.9-17.8,3.4-2.7,6.1-6.2,7.8-10.2,2.2-4.9,5.3-12,7.2-16.2,1-2.1,2.2-4.1,3.7-5.9,4-5.1,5.5-12.6,4-22.8-.3-1.9-.4-3.8-.2-5.7.6-6.2-2.1-11.2-7.8-14.9-1.4-.9-2.4-2.4-2.9-4-1.5-4.6-4.7-7.1-9.8-7.4-.1,0-.2,0-.3-.1-10.1-3.5-21.3,1.6-25.6,11.4-2.8,6.5-4.5,12-4.7,16.3,0,1.9.6,3.9,2,5.2,1.8,1.7,4.2,2,7.1,1.4,1.8-.4,3.7.4,4.5,2,.9,1.7.9,3.9-.3,6.5-.9,1.9-2.1,3.5-3.6,5l-15.6,15c-2.2,2.1-4.1,4.6-5.3,7.5-2.3,5.4-2.4,11.3-.3,17.7.4,1.1.7,2.2.8,3.4,1,8.5-4.3,14.7-16.8,18.4-1,.3-1.9.6-2.9,1-10,4.5-17,12.8-20.1,26-.5,1.9-.7,3.9-.6,5.9.4,8.1,5.5,16,22.1,23.5l34.8,12.1c1.1.4,2.2.9,3.1,1.8,2.6,2.4,2.8,5.6,1.1,9.6-4.2,10.4-5,20.6-1.2,30.5.7,1.8,1.6,3.4,2.6,5,6.6,10.5,14.5,17,24.8,15.9h.7c8.3-.2,12.3-4.9,12.7-13.4h0l-.3-.2Z"/>
      <path id="partofpelvis1" class="Rechte_Hufte_vd_obl_liegend2405_cls7" d="M366.47,69.82c.9,2.3,3.2,3.9,5.7,3.9s3.5-.4,5.5-1.2c10.5-6.2,14.6-15.1,15.7-25.5.6-7.1,6.4-12,5.2-16-1-3.4-3.8-4.7-7.4-5.3-1.1-.2-2.1-.6-3-1.2l-5.8-3.9c-3.9-2.6-9.2-1.7-11.9,2s-5.1,9.3-7.3,15.7c-2.2,5.1-2.5,10.3.2,15.6,1.2,2.3,1.9,4.9,1.8,7.5,0,2.9.4,5.7,1.4,8.3h0l-.1.1Z"/>
    </g>
    <path id="femurhighlight" class="Oberschenkel2405Fill"   style={{
            fill: props.colors.Oberschenkel2405Fill,
            stroke: props.colors.Oberschenkel2405Fill,
          }} d="M98.17,352.72c12.4-5.7,30.6-9.8,33.8-17.7,4.7-6,7.7-14.2,8.4-25.2,24.7-15.1,45.8-37,68.2-62.4,9.5,6.5,19.4,11.9,29.9,15.3,21.2,1.5,39.6-3.4,54-17.5,5.4-4.2,11.4-7.8,13.6-13.3,15.5-38.2,15.6-52.5,2.6-78.7-24.4-37.9-59.3-44.5-92.4-34.8-5.3,2.8-10.7,5.4-16,8.3-4,2.2-4.3,7.6-6.9,8.2-11,2.4-25.5,6.7-36.9,17.9-26.4-8.7-36.2,5.5-43.2,23.6-6.4,2-10,5.3-12,9.4-13.4,6.8-20.6,16.8-25.3,28.2-20.8,24.8-46.6,45.9-75.6,64.8v72.5l97.8,1.6v-.2Z"/>
    <path id="Pfahne" class="hipjoint2405Fill"   style={{
            fill: props.colors.hipjoint2405Fill,
            stroke: props.colors.hipjoint2405Fill,
          }} d="M213.77,102.52h0c-4.51,1.24-6.35,5.33-5.84,9.04.04,0,.07,0,.11,0,2.73,0,4.29,1.79,4.71,3.85.16.17.28.35.41.54.5.32.91.73,1.24,1.2.62.28,1.14.68,1.55,1.16.26-.02.45-.09.72-.09,82.2-24.3,132,69.9,77.8,125.2-1.53,1.61-2.38,3.55-2.15,5.81,1.3.78,2.04,2.16,2.22,3.62.43.48.77,1.04.98,1.66.21.14.4.29.57.46,1.47.14,2.56.84,3.27,1.81.82.03,1.53.23,2.13.54.74-.53,1.69-.86,2.84-.86.04,0,.07,0,.1,0,.75-.56,1.72-.91,2.91-.92.68-.38,1.49-.61,2.46-.61.11,0,.21,0,.31.02.54-.2,1.14-.32,1.82-.32.53,0,1.02.07,1.46.2,57.97-50.02,34.42-188.72-99.63-152.32Z"/>
    <path id="femoralheadhighlight" class="Femoralhead2405Fill"   style={{
            fill: props.colors.Femoralhead2405Fill,
            stroke: props.colors.Femoralhead2405Fill,
          }} d="M176.97,153.12c10.3-8.9,15.6-21,24.7-27.1,16-10.8,26.5-11.7,39-11.2,71.7-2.9,100,85.6,56.2,126.1-27.7,26.1-47.6,24.3-66.5,18.8l-19.1-10.5c-26.1-26.3-37.5-58.4-34.2-96.1h-.1Z"/>
    <path id="trochantormajorhighlight" class="TrochanterMajor2405Fill"   style={{
            fill: props.colors.TrochanterMajor2405Fill,
            stroke: props.colors.TrochanterMajor2405Fill,
          }} d="M154.97,152.22c6.52-1.38,19.09-3.16,34.14.61,15.35,3.85,25.74,11.57,30.82,15.92,8.68,6.04,12.48,10.16,14.31,12.88,1.14,1.69,2.18,3.8,4.79,6.32,1.93,1.86,2.99,2.28,3.74,3.98.65,1.47.45,2.53.04,6.44-.78,7.43-.4,6.78-.85,8.56-.94,3.67-1.77,3.32-3.18,7.9-1.22,3.96-.77,4.78-2.05,7.21-1.38,2.6-3.22,4.14-4.42,5.13-3.77,3.09-7.82,4.16-10.23,4.57-5.42,6.2-12.47,14.27-23.7,27.1,0,0-8.2,9.38-28.33,29.42-.58.58-2.78,2.75-6.05,5.12-2.21,1.6-3.31,2.4-3.95,2.52-5.33.97-12.52-18.02-19.96-32.54-4.01-7.83-10.37-18.81-20.23-31.13-4.06-6.3-7.22-11.91-9.6-16.44-2.59-4.93-3.38-6.9-5.38-10.16-3.82-6.2-8.25-10.88-12.24-14.36,4.3-1.72,6.84-3.96,8.34-5.64,2.31-2.58,2.89-4.64,5.64-6.79,2.32-1.81,4.75-2.56,6.36-2.91.29-1.51,3.43-16.44,17.76-23,11.48-5.26,22.13-1.49,24.24-.7Z"/>
    <path id="trochanorminorhighlight" class="TrochanterMinor2405Fill"   style={{
            fill: props.colors.TrochanterMinor2405Fill,
            stroke: props.colors.TrochanterMinor2405Fill,
          }} d="M138.97,310.12c-8.4-.9-16.9,2.2-25.6,9.4-1.8,1.5-3.4,3.3-4.7,5.3-7.5,11.4-13.6,21.8-10.9,26.7s.9.7,1.5.5c5.6-1.9,25.7-10.5,25.7-10.5,0,2,15.21-11.3,15.2-30.2,0-1.4-.5-1.1-1.1-1.2h-.1Z"/>
    <path id="femoralneckhighlight" class="Schenkelhals2405Fill"   style={{
            fill: props.colors.Schenkelhals2405Fill,
            stroke: props.colors.Schenkelhals2405Fill,
          }} d="M189.67,138.72l40.3,86.8c.7,1.5,0,3.2-1.6,3.8l-6,2.3c-.2,0-.4.2-.5.4l-23.5,26.8c-.6.7-1.6.6-2.1,0l-64-83.7c-.5-.6-.3-1.5.3-2,7.5-5.3,15.6-12.4,24.2-20.6h0c8.2-6.8,17.4-12.3,28.7-15.5s3.4.3,4.1,1.9l.1-.2Z"/>
  </svg>
  );
}
