import { combineReducers } from "redux";

import reports from "./reports";
import examManager from "./examManager";
import themes from "./themes";
import examSelector from "./examSelector";
import importJobs from "./importJobs";
import priceLists from "./priceLists";
import journalFlags from "./journalFlags";
import examPlanner from "./examPlanner";
import patientView from "./patientView";
import xmStats from "./examStatistics";
import xmQA from "./examsQa";
import examArchive from "./examArchive";

export default combineReducers({
  reports,
  pricelists: priceLists,
  examManager,
  themes,
  examSelector,
  importJobs,
  journalFlags,
  examPlanner,
  patientView,
  xmStats,
  xmQA,
  examArchive,
});
