import { Row, Col } from "react-bootstrap";
import { getDDMMYYY_HHMM } from "../../Utilities/DateTimeUtils/DateTimeUtils";
import "./stylesheets/SimplifiedExamRow.scss";

function SimplifiedExamRow({
  exam_data,
  isSelected,
  selectHandler = () => {},
}) {
  return (
    <div className="my-1">
      <Row className="border rounded py-2">
        <Col xs={1} className="centered-content-col border-right">
          {isSelected ? (
            <i
              className="fas fa-check-square exam-button-like fa-lg "
              onClick={() => selectHandler(exam_data)}
            ></i>
          ) : (
            <i
              onClick={() => selectHandler(exam_data)}
              className="far fa-square fa-lg exam-button-like "
            ></i>
          )}
        </Col>
        <Col xs={1} className="centered-content-col border-right">
          {exam_data?.modality}
        </Col>
        <Col xs={3} className="centered-content-col border-right">
          {exam_data?.patientsname}
        </Col>
        <Col xs={4} className="centered-content-col border-right">
          {exam_data?.studydescription}
        </Col>
        <Col xs={3} className="centered-content-col">
          {getDDMMYYY_HHMM(exam_data?.studydate)}
        </Col>
      </Row>
    </div>
  );
}

export default SimplifiedExamRow;
