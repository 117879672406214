import ExamHelperApp from "../../Components/ExamHelperApp/Components/ExamHelper/ExamHelperApp";
import ExamHelperOverviewApp from "../../Components/ExamHelperApp/Components/ExamHelper/ExamHelperOverviewApp";
import {
  ExamGuideSS,
  ExamGuideNW,
} from "../../Components/ExamHelperApp/Components/ExamGuide/ExamGuide";
import WorkInProgress from "../../Components/WorkInProgress/WorkInProgress";
import PatientPreparation from "../../Components/ExamHelperApp/Components/PatientPreparation/PatientPreparation";
import BreathCommand from "../../Components/ExamHelperApp/Components/BreathCommand/BreathCommand";
import SettingTechnique from "../../Components/ExamHelperApp/Components/SettingTechnique/SettingTechnique";

const EXAM_HELPER_BASE_PATH = "/ExamHelper/";

export const EXAM_GUIDE_SYSTEM_PREPARATION = {
  id: "EXAM_GUIDE_SYSTEM_PREPARATION",
  path: EXAM_HELPER_BASE_PATH + "systemPreparation/:examId?",
  link: EXAM_HELPER_BASE_PATH + "systemPreparation",
  component: WorkInProgress,
  roles: ["pm_user", "pm_admin"],
  label: (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <i className="fas fa-tools fa-sm" />
      <div>System Preparation</div>
    </div>
  ),
};

export const EXAM_GUIDE_SETTING_TECHNIQUE = {
  id: "EXAM_GUIDE_SETTING_TECHNIQUE",
  path: EXAM_HELPER_BASE_PATH + "settingTechnique/:examId?",
  link: EXAM_HELPER_BASE_PATH + "settingTechnique",
  component: SettingTechnique,
  roles: ["pm_user", "pm_admin"],
  label: (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <i className="fas fa-laptop fa-sm" />
      <div>Setting Technique</div>
    </div>
  ),
};

export const EXAM_GUIDE_PATIENT_PREP = {
  id: "EXAM_GUIDE_PATIENT_PREP",
  path: EXAM_HELPER_BASE_PATH + "patientPrep/:examId?",
  link: EXAM_HELPER_BASE_PATH + "patientPrep",
  component: PatientPreparation,
  roles: ["pm_user", "pm_admin"],
  label: (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <i className="fas fa-socks fa-sm" />
      <div>Patient preparation</div>
    </div>
  ),
};

export const EXAM_GUIDE_XRAY_PROTECTION = {
  id: "EXAM_GUIDE_XRAY_PROTECTION",
  path: EXAM_HELPER_BASE_PATH + "xreayProtect/:examId?",
  link: EXAM_HELPER_BASE_PATH + "xreayProtect",
  component: WorkInProgress,
  roles: ["pm_user", "pm_admin"],
  label: (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <i className="fas fa-socks fa-sm" />
      <div>Xray protection</div>
    </div>
  ),
};

export const EXAM_GUIDE_STEP_BY_STEP = {
  id: "EXAM_GUIDE_STEP_BY_STEP",
  path: EXAM_HELPER_BASE_PATH + "examGuide/:examId?",
  link: EXAM_HELPER_BASE_PATH + "examGuide",
  component: ExamGuideSS,
  roles: ["pm_user", "pm_admin"],
  label: (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <i className="fas fa-comment-medical fa-sm" />
      <div>Step by step</div>
    </div>
  ),
};

export const EXAM_GUIDE_NO_WORDS = {
  id: "EXAM_GUIDE_NO_WORDS",
  path: EXAM_HELPER_BASE_PATH + "examGuideNoWords/:examId?",
  link: EXAM_HELPER_BASE_PATH + "examGuideNoWords",
  component: ExamGuideNW,
  roles: ["pm_user", "pm_admin"],
  label: (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <i className="fas fa-comment-medical fa-sm" />
      <div>No words</div>
    </div>
  ),
};

export const EXAM_GUIDE_POSITIONING = {
  id: "EXAM_GUIDE_POSITIONING",
  label: (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <i className="fas fa-bone fa-sm" />
      <div>
        Positioning
        <i className="fas fa-caret-down fa-sm ml-2" />
      </div>
    </div>
  ),
  subMenuItems: [EXAM_GUIDE_STEP_BY_STEP, EXAM_GUIDE_NO_WORDS],
};

export const EXAM_GUIDE_BREATH_COMMAND = {
  id: "EXAM_GUIDE_BREATH_COMMAND",
  path: EXAM_HELPER_BASE_PATH + "breathCommand/:examId?",
  link: EXAM_HELPER_BASE_PATH + "breathCommand",
  component: BreathCommand,
  roles: ["pm_user", "pm_admin"],
  label: (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <i className="fas fa-comment-medical fa-sm" />
      <div>Breath Command & Exposition</div>
    </div>
  ),
};

export const EXAM_QA_SINGLE = {
  id: "EXAM_QA_SINGLE",
  path: EXAM_HELPER_BASE_PATH + "examQa/:examId?",
  link: EXAM_HELPER_BASE_PATH + "examQa",
  component: ExamHelperApp,
  roles: ["pm_user", "pm_admin"],
  label: (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <i className="fas fa-comment-medical fa-sm" />
      <div>Exam QA</div>
    </div>
  ),
};

export const EXAM_QA_OVERVIEW = {
  id: "EXAM_QA_OVERVIEW",
  path: EXAM_HELPER_BASE_PATH + "examQaOverview/:examId?",
  link: EXAM_HELPER_BASE_PATH + "examQaOverview",
  component: ExamHelperOverviewApp,
  roles: ["pm_user", "pm_admin"],
  label: (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <i className="fas fa-comment-medical fa-sm" />
      <div>Exam QA Overview</div>
    </div>
  ),
};

export const EXAM_QA = {
  id: "EXAM_QA",
  label: (
    <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <i className="fas fa-book fa-sm" />
      <div>
        Quality Assurance
        <i className="fas fa-caret-down fa-sm ml-2" />
      </div>
    </div>
  ),
  subMenuItems: [EXAM_QA_SINGLE, EXAM_QA_OVERVIEW],
};

export const EXAM_HELPER_ROUTES = [
  EXAM_GUIDE_SYSTEM_PREPARATION,
  EXAM_GUIDE_SETTING_TECHNIQUE,
  EXAM_GUIDE_PATIENT_PREP,
  EXAM_GUIDE_POSITIONING,
  EXAM_GUIDE_BREATH_COMMAND,
  EXAM_QA,
];
