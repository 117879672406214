import style from "./style.scss";

function LinkesOberesSprunggelenkAussenrotationVdOblLiegend1469(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.8 485.28">
 
 <g id="upper_ankle" data-name="upper ankle">
   <g id="background_layer" data-name="background layer">
     <path id="background1" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls2" d="M34.37,1.82l-5.1,151.9c-1.9,17.3-5.2,33.7-14.3,46.4-13.8,19.7-17.5,39.7-5.2,60,2.5,4.1,7.9,5.2,11.8,2.3,14-10.4,26.8-14.9,38.2-13.4,19,1.3,35.3,3.1,47.5,5.7,6.3.8,12.4-3.1,14.3-9.2,3-9.5,1.7-20-2-31.1-13.4-24.6-16.2-68.3-15.2-119L100.97.02"/>
     <path id="background2" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls2" d="M17.57,323.72c.2,4.6,1,8.8,2.4,12.4,4,10,16.5,13.4,25.4,7.3,19.4-13.2,41.8-12,65.7-3.9,3.3,1.1,6.8,1.4,10.2.6,13.4-3.2,22.2-13.3,27-29.4,4.1-29.2-1.7-41.1-12.7-43.9-13.5-5.1-27.3-9.8-45.1-7.1-4.1.6-8.3.1-12-1.6-18-8.2-34.6-6.7-49.2,7.9-16.9,16-22.1,33-13.5,50.8,1.1,2.1,1.7,4.5,1.8,6.9h0Z"/>
     <path id="background3" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls2" d="M132.37,277.52c7.7-4.3,15.8-7.9,26.8-4.1,7,2.4,12.4,8,14.6,15,3.1,9.9,2.7,22.1-1,36.9-.9,3.8-2.9,7.2-5.8,9.8-6.6,5.9-15.3,11-26.1,15.3-2.1,1-4,1.8-5.9,2.3-6.2,1.8-12.6-2.7-13.1-9.1-1.8-23.2-.2-42.6,4.6-58.7,1-3.2,3-5.9,5.9-7.4h0Z"/>
     <path id="background4" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls2" d="M206.37,316.22c-4.9-3.8-11.2-5.5-17.4-5.2-9.7.5-18.4,2.8-25.7,7.8-3.1,2.1-5.7,4.8-7.9,7.8-4.9,6.6-9,12.9-11,18.2-1.1,2.9,1.3,5.8,4.4,5.4,2.1-.3,4.1-.5,6-.5,7.1-.3,14,2.2,19.4,6.7,7.1,5.9,11,12.4,9.8,19.6-.2,1.1,0,2.2.6,3.2,1.4,2.1,4.3,2.2,9.7-1.1,2.3-1.4,3.6-4,3.5-6.7-.4-7.9,4-10.3,10.6-10.3,3.7,0,6.9-2.5,8-6,1.4-4.6,1.9-9.1,1.6-13.6"/>
     <path id="background5" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls2" d="M199.37,252.42c-4.7-2.8-9-6-13.1-9.8-3.5-3.2-7.8-5.3-12.5-6-8.7-1.2-16.5,2.4-22.1,16.5-3.2,9.4,2.2,17.1,7.1,23.1,1.4,1.7,2.1,4,1.8,6.2-3,26.4.9,46.3,15.8,46.4,11.2-2.6,20.1-7.8,27.6-14.4"/>
     <path id="background6" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls2" d="M73.77,1.92c2.3,81.1,4.2,156.7,4.1,210.8-5.7,16.4-10.6,32.3-12.6,46.2-1.1,7.6.7,15.3,5,21.6,5.8,8.5,12.6,14.3,20.4,17.7,9.5,5.2,18.7,5.8,27.4-2.2,5.1-5.2,8.2-11.4,9.6-18.3,1.9-9.2.3-18.8-3.9-27.2l-9.1-17.9c-13.4-60.8-18.1-137.8-19.6-220.9"/>
     <path id="background7" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls2" d="M3.17,427.92c-7.4-21.2,1.5-31.6,15.2-38.8,12.9-31.3,32.3-51.2,63.3-50.6,16.6,3.5,30.8,9.1,41.3,17.7,23.8-7.5,41.4-4.7,52.3,9.3,9.1,10.9,9.6,21.4-1.7,31.2-4.7,11.9-11.9,18.9-21.9,20.2-18.9,10-36,23.4-49.8,43-3.1,11.4-11,18.6-27.8,17.7-28.8,12.9-48.1,7.7-60.8-10.1-5.8-6.3-9.1-19.8-10.1-39.6Z"/>
     <path id="background8" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls2" d="M239.47,377.32c-7,6.1-14.4,11.8-24.8,15-14.6.2-18.8-6.6-10.4-22-3.3-19.6-5.1-37.3,12.2-30.2l2.3.4"/>
   </g>
   <path id="calcaneus" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls1" d="M3.17,427.92c-7.4-21.2,1.5-31.6,15.2-38.8,12.9-31.3,32.3-51.2,63.3-50.6,16.6,3.5,30.8,9.1,41.3,17.7,23.8-7.5,41.4-4.7,52.3,9.3,9.1,10.9,9.6,21.4-1.7,31.2-4.7,11.9-11.9,18.9-21.9,20.2-18.9,10-36,23.4-49.8,43-3.1,11.4-11,18.6-27.8,17.7-28.8,12.9-48.1,7.7-60.8-10.1-5.8-6.3-9.1-19.8-10.1-39.6Z"/>
   <path id="tarsus1" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls1" d="M239.47,377.32c-7,6.1-14.4,11.8-24.8,15-14.6.2-18.8-6.6-10.4-22-3.3-19.6-5.1-37.3,12.2-30.2l2.3.4"/>
   <path id="tibia" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls1" d="M34.37,1.82l-5.1,151.9c-1.9,17.3-5.2,33.7-14.3,46.4-13.8,19.7-17.5,39.7-5.2,60,2.5,4.1,7.9,5.2,11.8,2.3,14-10.4,26.8-14.9,38.2-13.4,19,1.3,35.3,3.1,47.5,5.7,6.3.8,12.4-3.1,14.3-9.2,3-9.5,1.7-20-2-31.1-13.4-24.6-16.2-68.3-15.2-119L100.97.02"/>
   <path id="talus" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls1" d="M17.57,323.72c.2,4.6,1,8.8,2.4,12.4,4,10,16.5,13.4,25.4,7.3,19.4-13.2,41.8-12,65.7-3.9,3.3,1.1,6.8,1.4,10.2.6,13.4-3.2,22.2-13.3,27-29.4,4.1-29.2-1.7-41.1-12.7-43.9-13.5-5.1-27.3-9.8-45.1-7.1-4.1.6-8.3.1-12-1.6-18-8.2-34.6-6.7-49.2,7.9-16.9,16-22.1,33-13.5,50.8,1.1,2.1,1.7,4.5,1.8,6.9h0Z"/>
   <path id="tarsus2" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls1" d="M132.37,277.52c7.7-4.3,15.8-7.9,26.8-4.1,7,2.4,12.4,8,14.6,15,3.1,9.9,2.7,22.1-1,36.9-.9,3.8-2.9,7.2-5.8,9.8-6.6,5.9-15.3,11-26.1,15.3-2.1,1-4,1.8-5.9,2.3-6.2,1.8-12.6-2.7-13.1-9.1-1.8-23.2-.2-42.6,4.6-58.7,1-3.2,3-5.9,5.9-7.4h0Z"/>
   <path id="tarsus3" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls1" d="M206.37,316.22c-4.9-3.8-11.2-5.5-17.4-5.2-9.7.5-18.4,2.8-25.7,7.8-3.1,2.1-5.7,4.8-7.9,7.8-4.9,6.6-9,12.9-11,18.2-1.1,2.9,1.3,5.8,4.4,5.4,2.1-.3,4.1-.5,6-.5,7.1-.3,14,2.2,19.4,6.7,7.1,5.9,11,12.4,9.8,19.6-.2,1.1,0,2.2.6,3.2,1.4,2.1,4.3,2.2,9.7-1.1,2.3-1.4,3.6-4,3.5-6.7-.4-7.9,4-10.3,10.6-10.3,3.7,0,6.9-2.5,8-6,1.4-4.6,1.9-9.1,1.6-13.6"/>
   <path id="tarsus4" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls1" d="M199.37,252.42c-4.7-2.8-9-6-13.1-9.8-3.5-3.2-7.8-5.3-12.5-6-8.7-1.2-16.5,2.4-22.1,16.5-3.2,9.4,2.2,17.1,7.1,23.1,1.4,1.7,2.1,4,1.8,6.2-3,26.4.9,46.3,15.8,46.4,11.2-2.6,20.1-7.8,27.6-14.4"/>
   <path id="fibia" class="oberes_Sprunggelenk1469_Außenrotation_vd_obl_links_cls1" d="M73.77,1.92c2.3,81.1,4.2,156.7,4.1,210.8-5.7,16.4-10.6,32.3-12.6,46.2-1.1,7.6.7,15.3,5,21.6,5.8,8.5,12.6,14.3,20.4,17.7,9.5,5.2,18.7,5.8,27.4-2.2,5.1-5.2,8.2-11.4,9.6-18.3,1.9-9.2.3-18.8-3.9-27.2l-9.1-17.9c-13.4-60.8-18.1-137.8-19.6-220.9"/>
 </g>
 <g id="overlay">
   <path id="posterior_edge_of_tibia_overlay" data-name="posterior edge of tibia overlay" class="Tibiahinterkante1469Fill" style= {{
             fill: props.colors.Tibiahinterkante1469Fill,
             stroke: props.colors.Tibiahinterkante1469Fill,
             opacity:props.colors.Tibiahinterkante1469Fill,
           }} d="M28.99,156.18c-1.95,19.33-6.55,33.68-13.62,43.37-9.7,13.62-14.31,27.19-13.07,40.7,1.35,7.87,3.78,14.56,7.47,19.87,5.3,6.81,11.31,3.79,17.5-1.65,7.31-4.95,15.08-7.99,23.26-9.39-6.53,1.57-12.98,3.05-18.79,3.72-12.6,1.46-23.09-9.64-20.67-22.09,1.69-8.68,6.29-18.27,11.5-28.05,5.45-8.61,9.64-17.8,12.21-27.73s2.07-20.13-1.12-29.73c-3.34-10.06-3.94-4.92-4.68,10.98Z"/>
 </g>
</svg>
  );
}

export default LinkesOberesSprunggelenkAussenrotationVdOblLiegend1469;
