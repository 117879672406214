import style from "./style.scss";

export default function LinkeHufteVdLiegend2401(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256.1 223.07"
    >
      <g id="Hip">
        <path
          id="whitebackground"
          class="Linke_Hufte_vd_liegend2401_cls4"
          d="M2.4.07l-.9,5.4c.3,2.5-.7,7.3-.7,9.9l-.8,12.9,1.2,110c1.5,23.1,10.8,27.8,21.1,34.7,7.6,3.3,17.5,1,28.2-3.1,28.7-14.2,42.1-34.6,45.1-59.3,36.2,13.8,51.1,27.1,51.3,40.1,10.8,8.3,14.6,17.5,12.5,27.6,0,5-3.8,10.6-14.5,22.4-1.9,8-1.6,15.5.7,22.4l82.2-.4c3.4-9.6,5.9-19.8,11.1-28.8,3.2-5.6,5.8-11.4,10.3-16.9,2.4-3,4.9-5.9,6.4-10l-1.7-17c-1.7-9.5-1-21.2-2.7-26.8-1.6-5.5-5.5-10.8-11.9-15.8-8.7-2.2-17.9-4.9-27.3-5.5-1.9-.1-1.9,1.2-9.7-.3-3.3-.5-4.4-4-7.3-5.6-12.7-7.1-29.4-23-38.4-42.5-.6-1.1-.7-2.5-1.5-3.7-1.5-2-4.5-3.8-8.5-5.1s6.3-6.8,6.7-13.5c-1.3-3.1-3.7-6.1-3.8-10.1-.1-4.7,6.7-12.3,11.3-19.6L2.4.07"
        />
        <path
          id="partofpelvis6"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M131.7,58.37c.4-.9.4-1.9,1.4-2.9,7-8,19-12,20-23,1-5-5-9-3-14,3-6,6-12,10.7-17.2"
        />
        <path
          id="partofpelvis5"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M7.2,161.77c6.9,5.2,13.9,12.7,22.9,12.7,19-1,35-12,49-25,14-15,15-35,20-54,1-2,2-3,3-5,5-5,11-7,17-12,7-5,8-13,12.6-20.1"
        />
        <path
          id="partofpelvis4"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M76.6,2.27c-7.5,19.2-14.5,37.2-27.5,53.2-13,15-24,30-42.2,37"
        />
        <path
          id="partofpelvis3"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M3.9,41.67C18.1,32.47,29.1,17.47,31.4.07"
        />
        <path
          id="partofupperleg5"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M147,150.67c.5.3,12.2,8,13,21,.5,7.9-3.3,14.2-5,17-4.1,6.8-7.4,6.9-10,12-2,8.7-1.5,17.2,1,22"
        />
        <path
          id="partofupperleg4"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M196.2,96.97c-1.8-4.2-27.5-12.6-41.5-48.4-4.2-3.4-8.8-2.8-9-5-3.1-2.3-8.9-5.8-17-7.2-12.7-2.2-22.6,2.4-26,4-4,1.9-15.9,7.7-21,21-4.8,12.4-.7,23.6,2,31,2.2,6.1,4.9,13.5,12,18.2,6.4,4.3,48.1,13.9,51.3,40.1"
        />
        <path
          id="partofupperleg3"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M227.9,222.67c2-8.6,11.1-32.2,16.1-38,6.5-10.5,9.2-9.9,11-16,1.8-6.3-.9-7.7-2-22-1-13,1-14.1-1-21-.8-2.6-3.6-12.2-12-18,0,0-4.5-3.1-28-6-7.2,1.3-10.9-.3-13-2-1.4-1.1-2.2-1.3-2.8-2.7"
        />
        <path
          id="partofupperleg2"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M174,174.37c5.4-9,8.9-17.1,11-23,3.5-9.7,5.2-14.5,5-21-.3-8.2-3.3-10.4-2-17,1.5-7.9,7.7-14.7,10.7-13.8,3.6,1.1.1,12.9,6.3,16.8,3.1,1.9,7.1,1,11,0,8.2-2,9.1-6,15-7,5.8-1,13.2,1.5,16.4,6.5,1.8,2.8,1.7,5.4-.4,20.5-2.2,15.7-2.8,18-3,23-.2,5.1.2,12.3,2.4,21"
        />
        <line
          id="partofupperleg1"
          class="Linke_Hufte_vd_liegend2401_cls6"
          x1="205.7"
          y1="134.27"
          x2="186.7"
          y2="145.67"
        />
        <path
          id="foramen1"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M70.7,103.37c-10.4.4-19,1.8-25,3-20.2,4.1-24.8,9.4-26,11-1.1,1.5-6.4,8.1-4,15,2.1,6,9.2,9.4,15,9,7.9-.6,12.6-8.4,16-14,2.2-3.7,6.7-12.2,9-38,.6-6.4,1.1-15.7,1-27"
        />
        <path
          id="partofjoint1"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M152.6,35.07c-4.4-2.5-10.6-5.5-19.9-6.8-14.3-2-25.3,2.3-32,5-6.4,2.6-14.5,5.8-22,14-1.7,1.9-7.8,8.9-11,20-3.2,11.2-2.4,22.9-1,23,1.5.2-21,16,13-68"
        />
        <path
          id="partofpelvis2"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M49.4,54.07c0,7.7,2.9,8.3,7.3,8.3"
        />
        <path
          id="partofpelvis1"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M74.4,5.47c-4.6,8.3-8.2,9.9-10.7,9.8-4.2-.2-5.9-5.4-10-5-3.4.3-6.6,4.2-7,8-.4,4.6,3.9,5.8,5,12,.7,4-.4,7.1-2,12-1.1,3.2,3.6,6.7-.3,11.7,10.6-13.4,18.8-29.7,25-48.5h0Z"
        />
        <path
          id="halfcircleinsidepelvis"
          class="Linke_Hufte_vd_liegend2401_cls6"
          d="M2.2,10.27c0,5.9-.4,11.7-.7,17.5,4.8,0,8.7-4,8.7-8.8S6.7,10.67,2.2,10.17v.1Z"
        />
      </g>
      <g id="overlays">
        <path
          id="jointhighlight"
          class="hipjoint2401Fill"
          style={{
            fill: props.colors.hipjoint2401Fill,
            stroke: props.colors.hipjoint2401Fill,
          }}
          d="M152.6,35.07c-16.2-8.7-34.9-10.2-55.3-.4-29.7,12.6-34.3,42-30.7,55.6,7.7,14.7,16.9,23.6,27,28.9l1.7-8.4c-3.5-3.1-6.9-7.5-10.4-15-6-13.9-7.2-25.5-3.3-34.5,6.2-12.1,13.9-19,22.7-21.8,17.5-7.2,31-3.9,42.3,4.9,2.4-3.5,5.5-6.4,6-9.4v.1Z"
        />
        <path
          id="femoralneckhighlight"
          class="femoralneck2401Fill"
          style={{
            fill: props.colors.femoralneck2401Fill,
            stroke: props.colors.femoralneck2401Fill,
          }}
          d="M156.1,53.87c-21.5,18.7-39.2,38.6-51.6,60.2,27.7,10.3,40.2,22.5,42.4,36,19-17,34.8-35.2,46.4-55.1s0,0,0-.1c-14.6-9.5-26.8-22.9-36.1-40.7-.2-.4-.7-.5-1-.2l-.1-.1Z"
        />
        <path
          id="trochanterminorhighlight"
          class="Trochanterminor2401Fill"
          style={{
            fill: props.colors.Trochanterminor2401Fill,
            stroke: props.colors.Trochanterminor2401Fill,
          }}
          d="M153.3,191.17c-5.1,4.9-9.2,9.5-8.9,13.2-.8,8.9-.3,14.9,1.6,18.2,6.1-11.6,13.8-23.6,7.3-31.4h0Z"
        />
        <path
          id="trochantermajorhighlight"
          class="Trochantermajor2401Fill"
          style={{
            fill: props.colors.Trochantermajor2401Fill,
            stroke: props.colors.Trochantermajor2401Fill,
          }}
          d="M222.2,102.57c8.7,27.6,17.5,54.9,22.3,80.6,4.8-5.2,8.5-10.3,10.6-15,1.1-2.5-.6-11.6-2.2-22.5-.9-6.2-.2-13.3-.8-19.4-1.2-7-5.8-13.1-12.5-18.5-8-3.2-6.7-4.4-17.5-5.2h.1Z"
        />
      </g>
    </svg>
  );
}
