import style from "./style.scss";

export default function RechteHufteVdLiegend2402(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256.1 223.07"
    >
      <g id="Hip">
        <path
          id="whitebackground"
          class="Rechte_Hufte_vd_liegend2402_cls4"
          d="M253.7.07L95.3,1.47c4.6,7.3,11.4,14.9,11.3,19.6-.1,4-2.5,7-3.8,10.1.4,6.7,10.7,12.2,6.7,13.5s-7,3.1-8.5,5.1c-.8,1.2-.9,2.6-1.5,3.7-9,19.5-25.7,35.4-38.4,42.5-2.9,1.6-4,5.1-7.3,5.6-7.8,1.5-7.8.2-9.7.3-9.4.6-18.6,3.3-27.3,5.5-6.4,5-10.3,10.3-11.9,15.8-1.7,5.6-1,17.3-2.7,26.8l-1.7,17c1.5,4.1,4,7,6.4,10,4.5,5.5,7.1,11.3,10.3,16.9,5.2,9,7.7,19.2,11.1,28.8l82.2.4c2.3-6.9,2.6-14.4.7-22.4-10.7-11.8-14.5-17.4-14.5-22.4-2.1-10.1,1.7-19.3,12.5-27.6.2-13,15.1-26.3,51.3-40.1,3,24.7,16.4,45.1,45.1,59.3,10.7,4.1,20.6,6.4,28.2,3.1,10.3-6.9,19.6-11.6,21.1-34.7l1.2-110-.8-12.9c0-2.6-1-7.4-.7-9.9l-.9-5.4"
        />
        <path
          id="partofpelvis6"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M95.3,1.27c4.7,5.2,7.7,11.2,10.7,17.2,2,5-4,9-3,14,1,11,13,15,20,23,1,1,1,2,1.4,2.9"
        />
        <path
          id="partofpelvis5"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M124.4,58.37c4.6,7.1,5.6,15.1,12.6,20.1,6,5,12,7,17,12,1,2,2,3,3,5,5,19,6,39,20,54,14,13,30,24,49,25,9,0,16-7.5,22.9-12.7"
        />
        <path
          id="partofpelvis4"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M249.2,92.47c-18.2-7-29.2-22-42.2-37-13-16-20-34-27.5-53.2"
        />
        <path
          id="partofpelvis3"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M224.7.07c2.3,17.4,13.3,32.4,27.5,41.6"
        />
        <path
          id="partofupperleg5"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M110.1,222.67c2.5-4.8,3-13.3,1-22-2.6-5.1-5.9-5.2-10-12-1.7-2.8-5.5-9.1-5-17,.8-13,12.5-20.7,13-21"
        />
        <path
          id="partofupperleg4"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M109.1,150.67c3.2-26.2,44.9-35.8,51.3-40.1,7.1-4.7,9.8-12.1,12-18.2,2.7-7.4,6.8-18.6,2-31-5.1-13.3-17-19.1-21-21-3.4-1.6-13.3-6.2-26-4-8.1,1.4-13.9,4.9-17,7.2-.2,2.2-4.8,1.6-9,5-14,35.8-39.7,44.2-41.5,48.4"
        />
        <path
          id="partofupperleg3"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M59.9,96.97c-.6,1.4-1.4,1.6-2.8,2.7-2.1,1.7-5.8,3.3-13,2-23.5,2.9-28,6-28,6-8.4,5.8-11.2,15.4-12,18-2,6.9,0,8-1,21-1.1,14.3-3.8,15.7-2,22,1.8,6.1,4.5,5.5,11,16,5,5.8,14.1,29.4,16.1,38"
        />
        <path
          id="partofupperleg2"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M9.7,180.37c2.2-8.7,2.6-15.9,2.4-21-.2-5-.8-7.3-3-23-2.1-15.1-2.2-17.7-.4-20.5,3.2-5,10.6-7.5,16.4-6.5,5.9,1,6.8,5,15,7,3.9,1,7.9,1.9,11,0,6.2-3.9,2.7-15.7,6.3-16.8,3-.9,9.2,5.9,10.7,13.8,1.3,6.6-1.7,8.8-2,17-.2,6.5,1.5,11.3,5,21,2.1,5.9,5.6,14,11,23"
        />
        <line
          id="partofupperleg1"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          x1="69.4"
          y1="145.67"
          x2="50.4"
          y2="134.27"
        />
        <path
          id="foramen1"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M199.4,62.37c-.1,11.3.4,20.6,1,27,2.3,25.8,6.8,34.3,9,38,3.4,5.6,8.1,13.4,16,14,5.8.4,12.9-3,15-9,2.4-6.9-2.9-13.5-4-15-1.2-1.6-5.8-6.9-26-11-6-1.2-14.6-2.6-25-3"
        />
        <path
          id="partofjoint1"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M176.4,22.27c34,84,11.5,68.2,13,68,1.4-.1,2.2-11.8-1-23-3.2-11.1-9.3-18.1-11-20-7.5-8.2-15.6-11.4-22-14-6.7-2.7-17.7-7-32-5-9.3,1.3-15.5,4.3-19.9,6.8"
        />
        <path
          id="partofpelvis2"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M199.4,62.37c4.4,0,7.3-.6,7.3-8.3"
        />
        <path
          id="partofpelvis1"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M181.7,5.47c6.2,18.8,14.4,35.1,25,48.5-3.9-5,.8-8.5-.3-11.7-1.6-4.9-2.7-8-2-12,1.1-6.2,5.4-7.4,5-12-.4-3.8-3.6-7.7-7-8-4.1-.4-5.8,4.8-10,5-2.5.1-6.1-1.5-10.7-9.8h0Z"
        />
        <path
          id="halfcircleinsidepelvis"
          class="Rechte_Hufte_vd_liegend2402_cls6"
          d="M253.9,10.17c-4.5.5-8,4-8,8.8s3.9,8.8,8.7,8.8c-.3-5.8-.7-11.6-.7-17.5v-.1Z"
        />
      </g>
      <g id="overlays">
        <path
          id="jointhighlight"
          class="hipjoint2402Fill"
          style={{
            fill: props.colors.hipjoint2402Fill,
            stroke: props.colors.hipjoint2402Fill,
          }}
          d="M103.5,34.97c.5,3,3.6,5.9,6,9.4,11.3-8.8,24.8-12.1,42.3-4.9,8.8,2.8,16.5,9.7,22.7,21.8,3.9,9,2.7,20.6-3.3,34.5-3.5,7.5-6.9,11.9-10.4,15l1.7,8.4c10.1-5.3,19.3-14.2,27-28.9,3.6-13.6-1-43-30.7-55.6-20.4-9.8-39.1-8.3-55.3.4v-.1Z"
        />
        <path
          id="femoralneckhighlight"
          class="femoralneck2402Fill"
          style={{
            fill: props.colors.femoralneck2402Fill,
            stroke: props.colors.femoralneck2402Fill,
          }}
          d="M99.9,53.97c-.3-.3-.8-.2-1,.2-9.3,17.8-21.5,31.2-36.1,40.7,0,.1-11.6-19.8,0,.1,11.6,19.9,27.4,38.1,46.4,55.1,2.2-13.5,14.7-25.7,42.4-36-12.4-21.6-30.1-41.5-51.6-60.2l-.1.1Z"
        />
        <path
          id="trochanterminorhighlight"
          class="Trochanterminor2402Fill"
          style={{
            fill: props.colors.Trochanterminor2402Fill,
            stroke: props.colors.Trochanterminor2402Fill,
          }}
          d="M102.8,191.17c-6.5,7.8,1.2,19.8,7.3,31.4,1.9-3.3,2.4-9.3,1.6-18.2.3-3.7-3.8-8.3-8.9-13.2h0Z"
        />
        <path
          id="trochantermajorhighlight"
          class="Trochantermajor2402Fill"
          style={{
            fill: props.colors.Trochantermajor2402Fill,
            stroke: props.colors.Trochantermajor2402Fill,
          }}
          d="M34,102.57c-10.8.8-9.5,2-17.5,5.2-6.7,5.4-11.3,11.5-12.5,18.5-.6,6.1.1,13.2-.8,19.4-1.6,10.9-3.3,20-2.2,22.5,2.1,4.7,5.8,9.8,10.6,15,4.8-25.7,13.6-53,22.3-80.6h.1Z"
        />
      </g>
    </svg>
  );
}
