//functional built-in
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
//functional private
import { getDDMMYYY_HHMM } from "../../../Utilities/DateTimeUtils/DateTimeUtils";
import { useStudy } from "../../../Utilities/FetchHooks/Studies/StudiesHooks";
import { updateMasKvEntry } from "../../../Utilities/Examinations/maskv";
import { xrayTypes } from "./statsConsts";
import {
  setXmStatsSelectedSerie,
  setXmStatsSelectedImage,
  setXmStatsReloadFlag,
  setXmStatsSelectedExam,
} from "../../../Redux/actions";
//ui
import { ClipLoader } from "react-spinners";
import ExamsListModal from "../../ExamsListModal/ExamsListModal";
import CheckBox from "../../CheckBox/CheckBox";

export default function ImageSerieListSelectionBox() {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation(["examStatistics", "common"]);
  const [harminizeImageModalShown, setHarminizeImageModalShown] =
    useState(false);

  const selectedModality = useSelector(
    (state) => state.xmStats?.selectedModality
  );

  const config = window.conf.EXAMS_STATS;
  const dispatch = useDispatch();
  const selectedExamination = useSelector(
    (state) => state.xmStats?.selectedExam
  );
  const shouldReload = useSelector((state) => state.xmStats?.reloadFlag);
  const selectedImage = useSelector((state) => state.xmStats?.selectedImage);
  const selectedSerie = useSelector((state) => state.xmStats?.selectedSerie);
  const studyDetailsQuery = useStudy({
    studyId: selectedExamination?.id,
    xmask:
      selectedModality !== "CT"
        ? "{*}"
        : "{serie_collection{bodypartexamined, imgcount, seriesdatetime, seriesdescription, myg, avgmyg}, patient, studydate, modality, studydescription, patientsname, myg, avgmyg, patientssize, patientsweight}",
  });
  const studyDetails = studyDetailsQuery.data;

  const getCellColors = (given, target) => {
    if (given > 0 && target > 0) {
      let proc = Math.round((given / target) * 100, 0);

      if (proc <= config.GREEN_VALUE) {
        return "green";
      }
      if (proc > config.GREEN_VALUE && proc <= config.H_YELLOW_VALUE) {
        return "yellow";
      }
      if (proc > config.H_YELLOW_VALUE && proc <= config.H_ORANGE_VALUE) {
        return "orange";
      }
      if (proc > config.H_ORANGE_VALUE) {
        return "red";
      }
      return "";
    } else return "";
  };

  useEffect(() => {
    if (shouldReload) {
      studyDetailsQuery.refetch();
      dispatch(setXmStatsReloadFlag(false));
    }
  }, [shouldReload]);

  useEffect(() => {
    //updates selected study/serie/image afer refetch
    //ugly no idea for now on how to replace it
    if (!selectedImage) {
      return;
    }
    dispatch(setXmStatsSelectedExam(studyDetails));
    let done = false;
    if (studyDetails?.serie_collection?.length > 0) {
      for (const serie of studyDetails.serie_collection) {
        for (const image of serie.image_collection) {
          if (image.image_id === selectedImage.image_id) {
            dispatch(setXmStatsSelectedImage(image));
            done = true;
            return;
          }
        }
        if (done) {
          dispatch(setXmStatsSelectedSerie(serie));
          return;
        }
      }
    }
  }, [studyDetails]);

  const harmonizeImage = (harmoExam) => {
    let maskvObj = {
      id: selectedImage.mas_kv_collection[0].id,
      examid: harmoExam.id,
    };
    updateMasKvEntry(
      keycloak,
      selectedImage.mas_kv_collection[0].id,
      maskvObj
    ).then(() => {
      dispatch(setXmStatsReloadFlag(true));
    });
  };

  return (
    <div name="images" className="images">
      <ExamsListModal
        shown={harminizeImageModalShown}
        hideHandler={() => setHarminizeImageModalShown(false)}
        selectHandler={harmonizeImage}
      />
      <div className="pl-2 font-weight-bold py-2 border-bottom text-uppercase bg-header rounded-top">
        {xrayTypes.includes(selectedModality)
          ? t("examsImagesBox.imagesHeader")
          : t("examsImagesBox.seriesHeader")}
      </div>
      <div className="images-list">
        {studyDetailsQuery.status === "loading" ? (
          <div className="d-flex bg-light align-items-center justify-content-center">
            <ClipLoader size={15} />
            Loading...
          </div>
        ) : (
          studyDetails?.serie_collection?.map((serie, key) =>
            xrayTypes.includes(selectedModality) ? (
              serie?.image_collection.map((image, key) => (
                <div
                  onClick={() => {
                    dispatch(setXmStatsSelectedImage(image));
                    dispatch(setXmStatsSelectedSerie(serie));
                  }}
                  key={`imageskey${key}`}
                  className={`d-flex border-bottom examination-row
                        ${getCellColors(
                          image.mas_kv_collection[0]?.dosis,
                          image.mas_kv_collection[0]?.exams.cgyqm
                        )}`}
                >
                  <div
                    style={{
                      width: "5%",
                    }}
                    className="p-1 border-right break-word "
                  >
                    <CheckBox selected={selectedImage === image} size="fa-lg" />
                  </div>
                  <div
                    style={{
                      width: "10%",
                    }}
                    className="p-1 border-right break-word "
                  >
                    {serie.bodypartExamined
                      ? serie.bodypartExamined
                      : "Unknown"}
                  </div>
                  <div
                    style={{
                      width: "20%",
                    }}
                    className="p-1 border-right break-word"
                  >
                    {serie.seriesdescription}
                  </div>
                  <div
                    style={{
                      width: "20%",
                    }}
                    className="p-1 border-right break-word"
                  >
                    {image.imgdetails_collection[0]?.imagedescription}
                  </div>
                  <div
                    style={{
                      width: "10%",
                    }}
                    className={`p-1 border-right cell ${getCellColors(
                      image.mas_kv_collection[0]?.mas,
                      image.mas_kv_collection[0]?.exams?.mastill
                    )}`}
                  >
                    {image.mas_kv_collection[0]?.mas}{" "}
                    <span className="xsmall">mAs</span>
                  </div>
                  <div
                    style={{
                      width: "10%",
                    }}
                    className={`p-1 border-right cell ${getCellColors(
                      image.mas_kv_collection[0]?.kv,
                      image.mas_kv_collection[0]?.exams?.kvtill
                    )}`}
                  >
                    {image.mas_kv_collection[0]?.kv}{" "}
                    <span className="xsmall">kV</span>
                  </div>
                  <div
                    style={{
                      width: "12%",
                    }}
                    className={`p-1 border-right cell ${getCellColors(
                      image.mas_kv_collection[0]?.dosis,
                      image.mas_kv_collection[0]?.exams?.cgyqm
                    )}`}
                  >
                    {image.mas_kv_collection[0]?.dosis}{" "}
                    <span className="xsmall">uGy</span>
                  </div>
                  <div
                    style={{
                      width: "15%",
                    }}
                    className="p-1 small"
                  >
                    {getDDMMYYY_HHMM(image.mas_kv_collection[0]?.zeitstempel)}
                  </div>
                  <div
                    style={{
                      width: "20%",
                    }}
                    className="p-1 small"
                  >
                    <button
                      disabled=""
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        setHarminizeImageModalShown(true);
                      }}
                    >
                      {image.mas_kv_collection[0]?.exams.id
                        ? image.mas_kv_collection[0]?.exams?.designationde
                        : "Harmonize"}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div
                onClick={() => {
                  dispatch(setXmStatsSelectedSerie(serie));
                }}
                key={`imageskey${key}`}
                className={`d-flex border-bottom examination-row
                        `}
              >
                <CheckBox selected={selectedSerie === serie} size="fa-lg" />
                <div>{serie.seriesdescription}</div>
              </div>
            )
          )
        )}
      </div>
    </div>
  );
}
