import style from "./style.scss";

export default function RechterUnterarmMitEllenbogenLatSitzend2209(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583.23 267">
      <g id="Forearmlat">
        <g id="Backround">
          <path
            id="backround1"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls3"
            d="M583.23,218.65c-.94-.79-1.57-2.02-1.57-3.73v-24.82c-3.93.24-8.48,1.14-13.46,1.93-20.41,3.24-41.36-.08-62,1-48.26,2.52-90.77,2.22-123,2-19.1-.13-42.78-.53-69-3-8.11-.76-21.53-2.2-38-5-8.78-1.49-14.19-2.66-25-5-16.69-3.61-25.03-5.44-25-7,.07-3.53,30.26-5.61,36-6,34.28-2.36,59.08-.68,74,0,41.01,1.87,32.39-2.64,88-2,29.31.34,55.91,1.87,101,3,51.48,1.29,24.49-.52,35,0,1.99.1,7.07.07,19.44-.76-.2-16.09-1.44-32.09.96-48.08-3.29-.86-7.46-1.01-13.4-1.16-19.28-.51-28.91-.76-52-1-19.33-.2-15.39-2.49-49-5-11.85-.89-23.9-1.26-48-2-25.34-.78-38.01-1.17-52-1-12.22.15-15.51.48-24,0-17.86-1.01-21.09-3.46-35-4-10.48-.41-18.32.73-34,3-12.05,1.75-17.29,3.18-37,6-3.21.46-11.84,1.65-23,1-12.73-.74-15.94-3.14-17-4-5.53-4.53-4.21-9.92-10-13-2.7-1.44-5.8-1.29-12-1-8.22.39-13.01.61-17,4-2.57,2.18-3.71,4.79-6,10-3.33,7.59-3.22,11.78-5,12-2.13.27-2.95-5.65-8-11-3.61-3.83-7.46-5.27-15-8-14.45-5.23-17.19-2.63-24-7-7.33-4.7-10.56-11.82-12-15-4.32-9.53-3.26-17.4-3-27,.07-2.71-1.28-15.81-4-42-.56-5.38-1.06-9.67-1.53-13.27C52.79,1.36,26.91.29,1.03,0c3.86,46.99,6.93,93.56,7.17,111.03.12,8.67,1.53,17.34,1,26-.31,5.01-.85,8.56,1,13,2.67,6.42,7.62,8.26,7,12-.56,3.35-4.79,3.43-8,7-1.89,2.09-4.67,6.78-1,25,4.08,20.26,8.62,19.94,9,32,.37,11.79-3.83,16.51,0,23,3.6,6.1,10.93,8.03,15,9,29.23,6.94,62.99,5.15,66,5,20.62-1.06,13.56,1.53,60,3,37.16,1.18,56.09-.02,100-1,77.79-1.74,67.21.93,126-1,40.28-1.32,101.59-4.42,113-5,41.91-2.12,41.3-2.5,48-2,15.9,1.19,27.83,3.95,35.77,2.98.44-7.08.97-14.16,1.51-21.23-.52-.74-.84-1.7-.82-2.86.11-5.8,1.02-11.52,1.57-17.27h0Z"
          />
        </g>
        <g id="Overview">
          <path
            id="outline3"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M580.57,121.38c-3.4-1.05-7.74-1.2-14.13-1.37-19.28-.51-28.91-.76-52-1-19.33-.2-15.39-2.49-49-5-11.85-.89-23.9-1.26-48-2-25.34-.78-38.01-1.17-52-1-12.22.15-15.51.48-24,0-17.86-1.01-21.09-3.46-35-4-10.48-.41-18.32.73-34,3-12.05,1.75-17.29,3.18-37,6-3.21.46-11.84,1.65-23,1-12.73-.74-15.94-3.14-17-4-5.53-4.53-4.21-9.92-10-13-2.7-1.44-5.8-1.29-12-1-8.22.39-13.01.61-17,4-1.9,1.62-3.27,4.41-6,10-2.39,4.89-3.89,8.91-5,12-3.38,9.44-5.07,14.17-5.76,18.02-.74,4.12-3.53,19.78,5,31,3.03,3.98,3.13,1.67,15,12,6.34,5.52,8.33,7.93,12,8,3.56.07,5.49-2.12,12-7,1.34-1,9.81-7.35,15-10,8.72-4.45,15.6-2.26,26.76-2.02,15.17.34,22.68-3.27,36-6,24.24-4.97,40.77-1.51,74,0,41.01,1.87,32.39-2.64,88-2,29.31.34,55.91,1.87,101,3,51.48,1.29,24.49-.52,35,0,11.73.58,16.48,1.4,20.2.29"
          />
          <path
            id="outline2"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M581.66,190.05c-4.09.16-8.92,1.12-14.22,1.96-20.41,3.24-41.36-.08-62,1-48.26,2.52-90.77,2.22-123,2-19.1-.13-42.78-.53-69-3-8.11-.76-21.53-2.2-38-5-8.78-1.49-14.19-2.66-25-5-9.16-1.98-12.46-3.43-25-7-24.66-7.01-24.4-5.48-31.76-8.98-10.12-4.82-17.21-10.86-22-15-9.93-8.58-9.67-11.22-19-19-8.77-7.31-22.02-15.83-25-13-1.82,1.73,2.11,5.88,3,14,1.14,10.3-3.55,18.49-9,28-7.03,12.27-11.1,19.37-20,24-13.24,6.88-27.2,3.11-35,1-7.66-2.07-16.33-4.41-23-12-6.1-6.94-5.87-12.96-12-15-6.21-2.07-13.22,1.85-15.24,2.98-1.48.83-5.34,3.04-8,7-2.54,3.78-4.14,9.38-1,25,4.08,20.26,8.62,19.94,9,32,.37,11.79-3.83,16.51,0,23,3.6,6.1,10.93,8.03,15,9,29.23,6.94,62.99,5.15,66,5,20.62-1.06,13.56,1.53,60,3,37.16,1.18,56.09-.02,100-1,77.79-1.74,67.21.93,126-1,40.28-1.32,101.59-4.42,113-5,41.91-2.12,41.3-2.5,48-2,16.43,1.23,28.61,4.13,36.54,2.87"
          />
          <path
            id="outline1"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M.5,2.81c1.16,12.22,2.16,23.22,3.16,35.22,2,29,3,57,5,86,0,6,0,11-1,17,0,5,2,9,4,14,4,7,8,14,12,22,8,15,26,21,42,21,26,1,53-8,71-29,9-11,13-23,11-37-2-11-9-20-19-24-13-5-31-3-38-17-2-4-5-7-6-11-2-9,0-19-1-28-2-16-4-32-5.49-48.23"
          />
          <path
            id="partofancon3"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M19.68,10.03c1.6,11.28,3.99,20.44,6,27,5.27,17.15,7.9,25.72,15,33,8.62,8.84,19,17,19,17,3.8,2.99,7.49,5.66,8,10,.48,4.07-2.11,7.42-5,11-12.4,15.37-14.99,16-18,21-5.28,8.76-6.41,21.88-1,32,6.41,11.98,19.57,15.19,27,17,6.71,1.63,23.76,5.79,38-5,14.05-10.64,14.85-27.87,15-31,.18-3.87.48-21.5-11-31-6.57-5.43-10.98-3.25-21-11-7.39-5.72-11.21-11.72-12-13-4.72-7.59-5.93-14.52-7-21-.96-5.82-1.78-14.88,0-26"
          />
          <path
            id="partofancon2"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M58.54,3.49c.12,9.54,3.12,18.54,5.12,28.54,1,2,2,3,3.25,5"
          />
          <path
            id="partofancon1"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M10.19,128.03c9.93,12.01,16.8,20.93,25.77,26.92"
          />
          <path
            id="radius5"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M148.11,176.03c16.97-36.51,8.95-68,2.33-63.02"
          />
          <path
            id="radius4"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M156.44,103.01c22.24,36.51-3.73,73.02-8.33,73.02"
          />
          <path
            id="ulnar3"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M197.68,192.49c0,5.83,36,12.54,78,3.54"
          />
          <line
            id="ulnar2"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            x1="185.68"
            y1="244.03"
            x2="579.68"
            y2="246.03"
          />
          <path
            id="ulnar1"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M579.64,205.52c-121.98-.49-244.98-1.49-366.96-2.49"
          />
          <path
            id="radius3"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M197.68,119.23c2.55,2.77,13.1,3.51,19,5.8,9.93,3.86,18.84,1.37,35-3,23.65-6.39,35.55-9.6,39-10,9.45-1.11,23.67-.99,42,6"
          />
          <path
            id="radius2"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M341.68,117.03c13.98,4,28.98,7,43.98,8,19,0,38,0,57,1,14,1,27,3,41,4,31,2,62,0,93.4,3.8"
          />
          <path
            id="radius1"
            class="unterarm_mit_ellenbogen_lat_rechts2209_cls1"
            d="M215.11,172.18c17.55-4.15,35.55-9.15,54.55-11.15,52-4,103-6,155-6,44,6,89,0,133,2,6,0,13,0,19.33.41"
          />
        </g>
      </g>
      <g id="Highlights">
        <path
          id="condyleshighlight"
          class="oberarmkondylen2209fill"
          style={{
            fill: props.colors.Oberarmkondylen2209Fill,
            stroke: props.colors.Oberarmkondylen2209Fill,
          }}
          d="M89.76,196.09c5.76-1.22,18.14-3.95,31.25-12.94,5.7-3.91,13.21-9.18,19.31-19.01,2.21-3.56,8.26-13.57,7.99-25.99-.04-2.02-.2-7.14-2.53-13.15-.72-1.86-1.77-4.52-3.94-7.38-1.38-1.82-4.1-4.9-9.71-7.89-1.47-.78-3.85-1.94-20.93-5.97-7.25-1.71-11.04-2.49-14.76-5.87-1.79-1.62-2.08-2.5-3.64-3.24-4.61-2.17-9.8,1.92-16.48,5.36-13.24,6.81-15.55,2.43-31.43,10.14-4.68,2.27-6.46,3.62-12.01,5.83-5.72,2.28-7.8,2.43-13.49,5.06-9.43,4.37-10.55,7.11-10.82,8.6-.22,1.23.13,1.7-.1,4.85-.22,3.1-.74,5.16-.91,6.07-.8,4.24,1.15,8.37,4.65,15.57,2.43,5,2.83,4.75,7.18,12.74,4.27,7.86,3.79,7.93,5.76,10.72,4.47,6.34,9.71,9.9,11.63,11.12,5.86,3.73,11.13,4.95,15.67,5.97,6.63,1.47,11.76,1.46,17.19,1.42,3.95-.03,11.16-.13,20.12-2.02Z"
        />
        <path
          id="radialheadhighlight"
          class="radiushead2209fill"
          style={{
            fill: props.colors.RadiusHead2209Fill,
            stroke: props.colors.RadiusHead2209Fill,
          }}
          d="M194.73,146.85c-.48-2.59-1.17-4.49-1.25-8.12-.04-1.79.08-3.6.64-6.94,1.8-10.83,4.5-15.68,2.12-18.17-.64-.67-1.3-.81-2.53-2.19-.53-.6-1.08-1.32-2.43-3.88-1.99-3.76-2.15-4.73-3.54-6-.89-.82-1.73-1.24-2.32-1.53-2.98-1.49-7.51-1.29-17.5-.69-4.93.3-7.98.89-10.62,3.03-1.45,1.18-2.35,2.6-4.15,5.46-1.93,3.06-3,5.46-4.85,9.61-.6,1.35-1.89,4.3-3.35,8.18-.53,1.41-1.08,2.95-1.64,4.63-1.82,5.44-2.86,8.54-3.65,12.8-.62,3.38-2.38,13.42.95,23.6,1.83,5.6,4.08,7.88,5.31,8.93,1.15.98,1.69,1.12,4.15,2.83,2.71,1.88,4.53,3.45,7.08,5.61,5.39,4.57,8.08,6.86,8.24,7.03.74.79,2,2.19,4.1,2.78,1.55.44,2.89.23,3.39.15.44-.07,1.92-.35,5.26-2.63.72-.49,1.13-.81,2.83-2.12,5.12-3.97,5.42-4.23,6.57-5.06,3.34-2.41,3.2-1.93,4.3-2.98.28-.27,2.98-2.91,3.99-6.52,1.06-3.79-.71-5.16-1.52-10.92-1.14-8.12,1.9-8.9.4-16.89Z"
        />
      </g>
    </svg>
  );
}
