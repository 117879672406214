import {
  SET_REPORT_TYPE,
  ADD_REPORT_SELECTED_ID,
  REMOVE_REPORT_SELECTED_ID,
  ADD_REMOVE_REPORT_SELECTED_ID,
  SET_REPORT_DATE_RANGE,
  ADD_REPORT_FORMAT,
  REMOVE_REPORT_FORMAT,
  SET_INITIAL_REPORT_STATE,
  SET_REPORT_IS_PACKAGE,
  ADD_REMOVE_REPORT_VARIANT,
  CLEAR_REPORT_DETAILS,
  TOGGLE_CONFIGURATION
} from "../actionTypes";

const initialState = {
  repType: "FacReport",
  repSelectedIds: [],
  repDateRange: {
    from: null,
    to: null,
  },
  repFormat: [],
  variants: [],
  configuration: null,
  package: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_REPORT_STATE: {
      state = initialState;
      return {
        ...state,
      };
    }
    case CLEAR_REPORT_DETAILS: {
      return {
        ...state,
        repSelectedIds: initialState.repSelectedIds,
        repDateRange: initialState.repDateRange,
        repFormat: initialState.repFormat,
        variants: initialState.variants,
        package: initialState.package,
      };
    }
    case SET_REPORT_TYPE: {
      const { content } = action.payload;
      return {
        ...state,
        repType: content,
      };
    }
    case ADD_REPORT_SELECTED_ID: {
      const { content } = action.payload;
      return {
        ...state,
        repSelectedIds: [...state.repSelectedIds, content],
      };
    }
    case REMOVE_REPORT_SELECTED_ID: {
      const { content } = action.payload;
      const index = state.repSelectedIds.indexOf(content);
      try {
        let copy = state.repSelectedIds.slice();
        let newIds = copy.splice(index, 1);
        return {
          ...state,
          repSelectedIds: newIds,
        };
      } catch {
        return {
          ...state,
        };
      }
    }
    case ADD_REMOVE_REPORT_SELECTED_ID: {
      const { content } = action.payload;
      const index = state.repSelectedIds.indexOf(content);
      try {
        if (index > -1) {
          let copy = state.repSelectedIds.slice();
          copy.splice(index, 1);
          return {
            ...state,
            repSelectedIds: copy,
          };
        } else {
          return {
            ...state,
            repSelectedIds: [...state.repSelectedIds, content],
          };
        }
      } catch {
        return {
          ...state,
        };
      }
    }
    case TOGGLE_CONFIGURATION: {
      const { content } = action.payload;
      return {
        ...state,
        configuration: content
      }
    }
    case SET_REPORT_DATE_RANGE: {
      const { content } = action.payload;
      return {
        ...state,
        repDateRange: {
          from: content.from,
          to: content.to,
        },
      };
    }
    case ADD_REPORT_FORMAT: {
      const { content } = action.payload;
      return {
        ...state,
        repFormat: [...state.repFormat, content],
      };
    }
    case REMOVE_REPORT_FORMAT: {
      const { content } = action.payload;
      const index = state.repFormat.indexOf(content);
      try {
        let newIds = state.repFormat.splice(index, 1);
        return {
          ...state,
          repFormat: [...newIds],
        };
      } catch {
        return {
          ...state,
        };
      }
    }
    case SET_REPORT_IS_PACKAGE: {
      const { content } = action.payload;
      return {
        ...state,
        package: content,
      };
    }
    case ADD_REMOVE_REPORT_VARIANT: {
      const { content } = action.payload;
      const index = state.variants.indexOf(content);
      try {
        if (index > -1) {
          let copy = state.variants.slice();
          copy.splice(index, 1);
          return {
            ...state,
            variants: copy,
          };
        } else {
          return {
            ...state,
            variants: [...state.variants, content],
          };
        }
      } catch {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
}
