import { Button, Dropdown, Modal } from "react-bootstrap";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import {
  usePatientWebAccessCredentials,
  usePatients,
} from "../../Utilities/FetchHooks/Patients/PatientsHooks";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { ClipLoader } from "react-spinners";

function PatientWebAccessModal({ shown, close }) {
  const printRef = useRef(null);

  const availablePatients = useSelector(
    (state) => state.examManager.openedPatients
  );
  const { data } = usePatients({
    filter: { field: "id", op: "in", value: availablePatients },
    enabled: availablePatients.length > 0,
  });
  const patients = data?.patients;

  const [selectedPatient, setSelectedPatient] = useState(patients?.at(-1));

  const selectedPatientCredentials = usePatientWebAccessCredentials({
    patientId: selectedPatient?.id,
    enabled: selectedPatient != undefined,
  });

  console.log(selectedPatientCredentials);

  const hanlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${selectedPatient?.patientsname} ${selectedPatient?.patientsvorname} Web Access`,
    bodyClass: "m-4",
  });

  useEffect(() => {
    if (patients) {
      setSelectedPatient(patients.at(-1));
    }
  }, [patients]);

  return (
    <Modal
      show={shown}
      onHide={close}
      backdrop="static"
      className="top-modal"
      onClick={(e) => e.stopPropagation()}
    >
      <Modal.Header closeButton className="p-1 d-flex align-items-center">
        Patient Web Access
      </Modal.Header>
      {patients?.length > 0 ? (
        <Modal.Body className="d-flex flex-column align-items-center p-0 m-0">
          <div className="p-1 d-flex w-100 justify-content-between">
            {patients?.length > 1 && (
              <Dropdown>
                <Dropdown.Toggle>Select Patient</Dropdown.Toggle>
                <Dropdown.Menu>
                  {patients?.map((patient) => (
                    <Dropdown.Item
                      onClick={() => setSelectedPatient(patient)}
                      key={patient.id}
                    >{`${patient.patientsname} ${patient.patientsvorname}`}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
            <Button
              size="sm"
              className="p-1 mr-2"
              disabled={patients.length < 1}
              onClick={hanlePrint}
            >
              <i className="fas fa-print fa-sm mr-2" />
              Print
            </Button>
          </div>

          <div className="border-top w-100"></div>
          <div
            className="d-flex flex-column align-items-center"
            style={{ gap: "10px" }}
            ref={printRef}
          >
            <h3>{`${selectedPatient?.patientsname} ${selectedPatient?.patientsvorname}`}</h3>
            <h4>Access pmPatients app via this link</h4>
            <QRCode value={window.conf.PATIENT_JOURNAL.PATIENT_LOGIN.DOMAIN} />
            <h5>{window.conf.PATIENT_JOURNAL.PATIENT_LOGIN.DOMAIN}</h5>
            <h5>Use credentials below</h5>

            <div className="d-flex flex-column align-items-center border rounded p-3">
              <div className="text-center border-bottom w-100 mb-2 pb-2">
                <h5 className="p-0 m-0">
                  {window.conf.LANG === "PL" ? (
                    "Twój PESEL"
                  ) : selectedPatientCredentials.status === "success" ? (
                    selectedPatientCredentials.data.username
                  ) : (
                    <ClipLoader size="10" />
                  )}
                </h5>
                <p className="small muted p-0 m-0">login</p>
              </div>
              <div className="text-center">
                <h5 className="p-0 m-0">
                  {selectedPatientCredentials.status === "success" ? (
                    selectedPatientCredentials.data.initial_password
                  ) : (
                    <ClipLoader size="10" />
                  )}
                </h5>
                <p className="small muted p-0 m-0">password</p>
              </div>
            </div>

            <p className="small muted mt-3 p-1">
              * After first login you will be prompted to change your single use
              password and provide e-mail address
            </p>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body
          className="d-flex flex-column align-items-center p-0 m-0"
          style={{ gap: "10px" }}
        >
          <h4>Select Patient first</h4>
        </Modal.Body>
      )}
    </Modal>
  );
}

export default PatientWebAccessModal;
