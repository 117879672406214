import style from "./style.scss";

function BeideVorfusseDplOblLiegend1474(props) {
  return (
<svg id="both_feet" data-name="both feet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.33 493.88">
  
  <g id="foot_overview_right" data-name="foot overview right">
    <g id="fore_foot_right" data-name="fore foot right">
      <g id="background_layer_right" data-name="background layer right">
        <path id="background_1right" data-name="background 1right" class="beide_vorfusse1474_obl_cls3" d="M410.58,389.53h0l-18.5-7.3c-11.9,3.6-10.5-8.4-2.6-28.1-.8-11.6,2-22,8.2-31.1l12.8-101.2c-4.8-7.2-5.4-12.8.8-16.1-.6-15.9,4.6-21.5,16.5-15.4,7.1,1,9,6.6,6.8,15.8,2.2,5.3,1.8,10.5-2.2,15.4-11.5,17.1-16.3,54.8-9.8,126.8-3.4,5.7-4.4,11.9-.4,19.1,4.7,17.4,2.8,27.1-11.6,22.1h0Z"/>
        <path id="background_2right" data-name="background 2right" class="beide_vorfusse1474_obl_cls3" d="M381.38,372.33c-18.4-2-25.3-9.8-27.2-20.2-1.5-9.5,1.7-17.6,7.9-24.8,7.4-34.9,11.5-80,14.6-127.9-4.3-7.5-2.5-14.8,3.4-22.1-4.5-6.5-1-9.5,9-10.1,13.7-1.9,14.7,6.2,10.5,18.4,4.8,6.2,2.7,12.2-2.2,18-.7,4.1-2.4,8.5-5.2,13.1-8.8,43.4-9.3,85.1.4,124.5,5.9,20.9,4.9,34.4-11.2,31.1,0,0-.1,0,0,0Z"/>
        <path id="background_3right" data-name="background 3right" class="beide_vorfusse1474_obl_cls3" d="M286.98,485.93c-5.7,8.9-11.7,11.5-18.6-1.7-6.8-12.5-5.7-30.7,1.7-53.4,3.8-17,17.2-18.6,34.3-14.6,24,1,35.3,10.5,42.8,22.5,9.7,5.6,14.8,11.9,12.9,19.1-6.2,13.4-13.4,14.8-21.4,6.8-27.8-12.8-46.1-7.7-51.7,21.3h0Z"/>
        <path id="background_4right" data-name="background 4right" class="beide_vorfusse1474_obl_cls3" d="M270.58,375.74c-3.1-6.7-3.1-14.5.6-23.6,7.3-20.2,19.6-21.9,34.9-12.9.6,9.5,4.1,16.2,11.2,19.7,9.1,5.5,9.5,21.1,3.4,44.4-2.4,18-10.9,29.2-33.2,25.3-19.8.9-23.1-19.8-16.9-52.9Z"/>
        <path id="background_5right" data-name="background 5right" class="beide_vorfusse1474_obl_cls3" d="M302.68,353.64h0l-22.5-2.1c-12.4-10.4-17.7-20.3-10.9-29.2,9.5-37.6,9.5-70.5-.4-98.6-7.8-11.5-10.5-23.2-7.1-35.2-1.2-5.3,1.2-10.1,6.8-14.2,9.3-9.1,21.9-11.5,37.5-7.5,4.9,1.8,6.6,5,6,9,6.9,11.5,9,23.9,2.2,37.9-8.2,23.5-6.2,50.8,4.9,81.4,8,14.7,12,30,5.6,46.5,1.1,9.1-7,12.6-22.1,12h0Z"/>
        <path id="background_6right" data-name="background 6right" class="beide_vorfusse1474_obl_cls3" d="M330.08,411.53h0c2.9.5,5.7-1.2,6.5-4l4-14.3c5.5-3.3,6.1-8,4.1-13.5,6.2-7.7,3.5-14.1-10.7-18.8-10.5-5.4-19.2-6.6-23.6,2.1-2.6,9.7-6.1,18.6-10.7,26.4-4.6,8.9-2.5,15.1,8.1,18l22.3,4.1h0Z"/>
      </g>
      <g id="tarsusright">
        <path id="tarsus_1right" data-name="tarsus 1right" class="beide_vorfusse1474_obl_cls5" d="M365.58,472.43h0l12.8,6.7c8,6.6,18.1,6.5,29.8,1.1,18.4.5,26.2-6.6,27-18.6-.7-13.6,2.4-21.9,9-25.3,8.1-2.8,4.2-7.2-2.2-11.8-7.3-12.7-21.1-22.9-40.5-30.9-8.9-4-14-3.5-11.2,6.2.1,16.3-8.3,30.9-26.8,43.5-3,2-5.5,4.8-7.2,8-8.9,16.6-8.6,25.8,9.3,21.1h0Z"/>
        <path id="tarsus_2right" data-name="tarsus 2right" class="beide_vorfusse1474_obl_cls4" d="M330.08,411.53h0c2.9.5,5.7-1.2,6.5-4l4-14.3c5.5-3.3,6.1-8,4.1-13.5,6.2-7.7,3.5-14.1-10.7-18.8-10.5-5.4-19.2-6.6-23.6,2.1-2.6,9.7-6.1,18.6-10.7,26.4-4.6,8.9-2.5,15.1,8.1,18l22.3,4.1h0Z"/>
        <path id="tarsus_3right" data-name="tarsus 3right" class="beide_vorfusse1474_obl_cls5" d="M361.08,437.64h0c11.4-4.3,15-11.5,11.3-21.4l13.5-28.1c7.1-11.3,3.8-20.2-10.1-26.4-16.4-3.8-22.8,4.2-21.9,20.8-8,9.7-14.4,19.7-11.8,30.9-2.5.1-5.3,1-6.4,4.9-.4,1.4,0,2.9.9,4,9.1,10.6,17.4,17.1,24.5,15.3h0Z"/>
        <path id="tarsus_4right" data-name="tarsus 4right" class="beide_vorfusse1474_obl_cls4" d="M287.98,485.93c-5.7,8.9-11.7,11.5-18.6-1.7-6.8-12.5-5.7-30.7,1.7-53.4,3.8-17,17.2-18.6,34.3-14.6,24,1,35.3,10.5,42.8,22.5,9.7,5.6,14.8,11.9,12.9,19.1-6.2,13.4-13.4,14.8-21.4,6.8-27.8-12.8-46.1-7.7-51.7,21.3h0Z"/>
        <path id="tarsus_5right" data-name="tarsus 5right" class="beide_vorfusse1474_obl_cls4" d="M271.58,375.74c-3.1-6.7-3.1-14.5.6-23.6,7.3-20.2,19.6-21.9,34.9-12.9.6,9.5,4.1,16.2,11.2,19.7,9.1,5.5,9.5,21.1,3.4,44.4-2.4,18-10.9,29.2-33.2,25.3-19.8.9-23.1-19.8-16.9-52.9Z"/>
      </g>
      <g id="metatarsus_right" data-name="metatarsus right">
        <path id="metatarsus_1right" data-name="metatarsus 1right" class="beide_vorfusse1474_obl_cls5" d="M449.18,421.43h0c-8-10.7-17.6-18.7-29.5-23-2.2-.7-4-2.3-4.8-4.5-3.7-10.1-1.3-18.2,6.4-24.6,12.2-11.6,15.3-27.7,14.2-45.8-1-29.4,2.6-58.4,9.4-87,5.4-4,5.1-8.8,2.2-13.9,3.6-7.3,7.6-9,12-7.1,10.1-2.6,14.9,5.6,17.2,18.8,1.7,7.1-.6,13.1-6.8,18-13.7,54.8-19.4,107.1,2.1,150.6,2.5,5.1,2.7,11.1.3,16.3-7.1,15.3-14.7,15.4-22.7,2.2h0Z"/>
        <path id="metatarsus_2right" data-name="metatarsus 2right" class="beide_vorfusse1474_obl_cls4" d="M410.58,389.93h0l-18.5-7.3c-11.9,3.6-10.5-8.4-2.6-28.1-.8-11.6,2-22,8.2-31.1l12.8-101.2c-4.8-7.2-5.4-12.8.8-16.1-.6-15.9,4.6-21.5,16.5-15.4,7.1,1,9,6.6,6.8,15.8,2.2,5.3,1.8,10.5-2.2,15.4-11.5,17.1-16.3,54.8-9.8,126.8-3.4,5.7-4.4,11.9-.4,19.1,4.7,17.4,2.8,27.1-11.6,22.1h0Z"/>
        <path id="metatarsus_3right" data-name="metatarsus 3right" class="beide_vorfusse1474_obl_cls4" d="M381.28,372.73c-18.4-1.9-25.2-9.8-27.2-20.2-1.5-9.5,1.7-17.6,7.9-24.8,7.4-34.9,11.5-80,14.6-127.9-4.3-7.5-2.5-14.8,3.4-22.1-4.5-6.5-1-9.5,9-10.1,13.7-1.9,14.7,6.2,10.5,18.4,4.8,6.2,2.7,12.2-2.2,18-.7,4.1-2.4,8.5-5.2,13.1-8.8,43.4-9.3,85.1.4,124.5,5.9,20.9,4.9,34.4-11.2,31.1h0Z"/>
        <path id="metatarsus_4right" data-name="metatarsus 4right" class="beide_vorfusse1474_obl_cls5" d="M339.28,356.93h0c-9.6-3.1-18.5-4.4-26.7-3.7-7.2-9.3-2.7-20.9,6.4-33.4,8.9-26.7,14.5-54.2,16.5-82.5,1.9-13.1,3-25.6,3.4-37.5-3.8-9.1-4.3-18.5.4-28.1-4-10.8,3.3-13.2,15.7-12,14.4-4,15.1,4.7,9.8,19.1,6.2,7.3,5.3,13.1-4.1,17.2-13.7,47.3-13.3,100.9-4.9,157.9-.1,11.8-5.9,12.3-16.5,3h0Z"/>
        <path id="metatarsus_5right" data-name="metatarsus 5right" class="beide_vorfusse1474_obl_cls4" d="M303.68,353.64h0l-22.5-2.1c-12.4-10.4-17.7-20.3-10.9-29.2,9.5-37.6,9.5-70.5-.4-98.6-7.8-11.5-10.5-23.2-7.1-35.2-1.2-5.3,1.2-10.1,6.8-14.2,9.3-9.1,21.9-11.5,37.5-7.5,4.9,1.8,6.6,5,6,9,6.9,11.5,9,23.9,2.2,37.9-8.2,23.5-6.2,50.8,4.9,81.4,8,14.7,12,30,5.6,46.5,1.1,9.1-7,12.6-22.1,12h0Z"/>
        <path id="metatarsus_6right" data-name="metatarsus 6right" class="beide_vorfusse1474_obl_cls5" d="M329.58,209.23h0c-6.3,7.6-11.6,5.4-16.2-3.8-3.8-8.3-4.2-15.4,0-21,6.4-5.2,12.5-2.2,18.4,6,4.6,10.4,4.4,17.1-2.2,18.8h0Z"/>
      </g>
      <g id="toes_right" data-name="toes right">
        <g id="big_toe_right" data-name="big toe right">
          <path id="big_toe_1right" data-name="big toe 1right" class="beide_vorfusse1474_obl_cls5" d="M307.08,161.92h0c-7.3-2.6-16.8-1.9-28.5,1.8-12.6,8.1-18.7,5.6-12-18,6.7-18.6,6.8-38.3,3.8-58.5-3-12.8-2.9-21.3,5.2-18.8,8.3,1.7,16.3,1.2,24-2.2,15.4-.3,17.9,8.3,9.8,24.4-9,20.8-5.3,38.5,5.6,54.4,3.2,10.8,5.7,20.9-7.9,16.9h0Z"/>
          <path id="big_toe_2right" data-name="big toe 2right" class="beide_vorfusse1474_obl_cls5" d="M305.98,60.73h0l-18.3,4.5c-10-.7-15.7-5.2-15.4-15-1.8-9,.2-13.4,8.2-10.5,7.6-11.8,9.6-19.5,10.9-26.6-.8-7.3,2.8-11.4,10.7-12.5,3.9-.5,7.9.5,10.9,3,5.7,4.8,6.9,11.3,2,20-5.1,7.5-4.5,15,1.9,22.5,7.1,12.3,5.9,18.8-10.9,14.6h0Z"/>
        </g>
        <g id="second_toe_right" data-name="second toe right">
          <path id="second_toe_1right" data-name="second toe 1right" class="beide_vorfusse1474_obl_cls5" d="M361.48,152.92c-4.7-2.7-10.1-1.9-16.2,2.2-9.2,6.2-13.7,4.2-8.2-15,3.5-18.2.5-35.6-7.5-52.5,0-10.4,2.1-14.1,7.1-8.2,3.9,2.1,7.5,1.9,10.9,0,7.1-3,9,.8,6.4,10.5-3.5,20.9-.3,36.5,10.5,46.1,6.3,10.9,8.3,18.7-3,16.9h0Z"/>
          <path id="second_toe_2right" data-name="second toe 2right" class="beide_vorfusse1474_obl_cls5" d="M343.48,75.32c-9.1,1.6-13-.9-9.8-9,3.8-6.1,5.4-13.8,6-22.1-5.1-6.2,1.6-7.9,12.4-7.9,8.6.8,9.4,5.5,7.1,11.6-3.4,5.3-3.5,10.8-.8,16.5,2.5,8.3,3.1,14.6-7.1,9.8,0,0-7.9,1.1-7.8,1.1Z"/>
          <path id="second_toe_3right" data-name="second toe 3right" class="beide_vorfusse1474_obl_cls5" d="M359.58,32.23h0l-15,.4c-10.9.5-8.3-5,1.1-13.5-3.4-5.8-4.4-10.2,2.7-9.8h1.4l6.4-.4c3.7-.2,7.3,1.1,9.7,3.9,3.6,4.1,1.6,8.2-2.2,12.3,3.7,8.4.4,8.5-4.1,7.1h0Z"/>
        </g>
        <g id="third_toe_right" data-name="third toe right">
          <path id="third_toe_1_right" data-name="third toe 1 right" class="beide_vorfusse1474_obl_cls5" d="M394.78,162.73c-3.9-1.9-8.4-1.9-14.2,1.5-7.8,5.5-10,.4-7.5-13.5,7.7-7.5,4.4-26.5-1.9-48.8-3.9-15.2,4.6-13.5,13.9-10.5,11.8.3,12,7,7.1,16.5-4.9,11.8-2.9,24.7,7.5,39,7.1,13.7,7,20.4-4.9,15.8h0Z"/>
          <path id="third_toe_2right" data-name="third toe 2right" class="beide_vorfusse1474_obl_cls5" d="M389.18,89.92h0l-8.2-1.8c-6.2,1.5-9.4,0-7.1-7.1,2.1-6.2,3-11.8,3-16.9-2.7-7.3,2.2-9.7,11.6-9.4,13,3.2,13.5,12,8.6,23.2,2.5,9.1,1.5,14.7-7.9,12h0Z"/>
          <path id="third_toe_3right" data-name="third toe 3right" class="beide_vorfusse1474_obl_cls5" d="M401.98,41.92h0c3.9,8.6,5.3,14.9-2.3,13.2l-15.8-3.8c-10.4.3-4.6-5,1.9-10.5,1.6-8.9,4-14.7,9.4-9.4,12.5-.6,12.5,3.8,6.8,10.5h0Z"/>
        </g>
        <g id="fourth_toe" data-name="fourth toe">
          <path id="fourth_toe_1_right" data-name="fourth toe 1 right" class="beide_vorfusse1474_obl_cls5" d="M429.28,185.23h0c-7-6.1-13.8-5.6-20.2,4.1-4.1-4.5-4.1-10.7.8-18.8,1.4-12.2,1.5-23.9-3.4-33.8l-3.8-13.1c-2.7-9.8.4-13.6,15-5.2,8.8-3.1,11.5-.6,7.5,7.9-6.6,14.2-3.1,29.7,10.9,46.5,4.1,10.2,2.5,14.9-6.8,12.4h0Z"/>
          <path id="fourth_toe_2_right" data-name="fourth toe 2 right" class="beide_vorfusse1474_obl_cls5" d="M424.78,112.82l-10.9,1.6c-8-.5-10-5.1-6.4-13.5-1.2-6.1,3.1-8.5,13.9-6.8,7.5,1.6,9,5.6,6.8,11.2,3.5,5.4,4.4,9.3-3.4,7.5h0Z"/>
          <path id="fourth_toe_3_right" data-name="fourth toe 3 right" class="beide_vorfusse1474_obl_cls5" d="M430.18,77.52c.3,4,2.2,4.8,3.9,5.9,5.5,8.6-.9,10.7-15.1,8.6-3.9.6-7.2.2-8.6-2.6-1.4-2.8.2-6,3.2-7.2s4.6-2.6,4.8-4.6c.1-2,.8-4,2.3-5.4,4.1-3.9,7.2-1.5,9.5,5.3h0Z"/>
        </g>
        <g id="fifth_toe" data-name="fifth toe">
          <path id="fifth_toe_1_right" data-name="fifth toe 1 right" class="beide_vorfusse1474_obl_cls5" d="M467.98,213.33h0c-4.7-3-9.9-2.8-15.4-.6-6.6,6.5-10.7,5.4-9.8-12,4.7-20.1.6-35-5.2-48.8-3.8-10.6,4.1-7.1,12-3.8,9.3-1.2,13.6,1.5,9.4,10.5-1.2,12.4,1,23.3,6.8,32.6,9.4,9.6,10.7,17.1,2.2,22.1h0Z"/>
          <path id="fifth_toe_2_right" data-name="fifth toe 2 right" class="beide_vorfusse1474_obl_cls5" d="M456.68,144.73h0l-10.4-.3c-5.2-.1-7.3-2.1-6-6.4-2.5-2.8-2.6-5,2.6-6l10.1-3c4.9.5,7.4,3.3,7.1,8.6,3.6,4.5,2.6,6.9-3.4,7.1h0Z"/>
          <path id="fifth_toe_3_right" data-name="fifth toe 3 right" class="beide_vorfusse1474_obl_cls5" d="M457.78,114.32c.5,3.7,2.2,4.4,3.8,5.2,5.2,7.7-.3,10.1-12.8,9-3.5.8-6.2.6-7.7-1.9s-.3-5.6,2.3-6.9,4-2.7,4-4.6.5-3.8,1.7-5.2c3.5-3.9,6.3-1.8,8.7,4.4h0Z"/>
        </g>
      </g>
    </g>
    <g id="overlay_right" data-name="overlay right">
      <g id="Phalangen_overlay_right" data-name="Phalangen overlay right" class="beide_vorfusse1474_obl_cls1">
        <g id="Phalangen_overlay_1right" data-name="Phalangen overlay 1right">
          <path id="Phalangen_overlay_1.1right" data-name="Phalangen overlay 1.1right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M307.07,162.45h0c-7.3-2.6-16.8-1.9-28.5,1.8-12.6,8.1-18.7,5.6-12-18,6.7-18.6,6.8-38.3,3.8-58.5-3-12.8-2.9-21.3,5.2-18.8,8.3,1.7,16.3,1.2,24-2.2,15.4-.3,17.9,8.3,9.8,24.4-9,20.8-5.3,38.5,5.6,54.4,3.2,10.8,5.7,20.9-7.9,16.9h0Z"/>
          <path id="Phalangen_overlay_1.2right" data-name="Phalangen overlay 1.2right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M305.97,61.25h0l-18.3,4.5c-10-.7-15.7-5.2-15.4-15-1.8-9,.2-13.4,8.2-10.5,7.6-11.8,9.6-19.5,10.9-26.6-.8-7.3,2.8-11.4,10.7-12.5,3.9-.5,7.9.5,10.9,3,5.7,4.8,6.9,11.3,2,20-5.1,7.5-4.5,15,1.9,22.5,7.1,12.3,5.9,18.8-10.9,14.6h0Z"/>
        </g>
        <g id="Phalangen_overlay_2right" data-name="Phalangen overlay 2right">
          <path id="Phalangen_overlay_2.1right" data-name="Phalangen overlay 2.1right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M361.47,153.45c-4.7-2.7-10.1-1.9-16.2,2.2-9.2,6.2-13.7,4.2-8.2-15,3.5-18.2.5-35.6-7.5-52.5,0-10.4,2.1-14.1,7.1-8.2,3.9,2.1,7.5,1.9,10.9,0,7.1-3,9,.8,6.4,10.5-3.5,20.9-.3,36.5,10.5,46.1,6.3,10.9,8.3,18.7-3,16.9h0Z"/>
          <path id="Phalangen_overlay_2.2right" data-name="Phalangen overlay 2.2right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M343.47,75.84c-9.1,1.6-13-.9-9.8-9,3.8-6.1,5.4-13.8,6-22.1-5.1-6.2,1.6-7.9,12.4-7.9,8.6.8,9.4,5.5,7.1,11.6-3.4,5.3-3.5,10.8-.8,16.5,2.5,8.3,3.1,14.6-7.1,9.8,0,0-7.9,1.1-7.8,1.1Z"/>
          <path id="Phalangen_overlay_2.3right" data-name="Phalangen overlay 2.3right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M359.57,32.75h0l-15,.4c-10.9.5-8.3-5,1.1-13.5-3.4-5.8-4.4-10.2,2.7-9.8h1.4l6.4-.4c3.7-.2,7.3,1.1,9.7,3.9,3.6,4.1,1.6,8.2-2.2,12.3,3.7,8.4.4,8.5-4.1,7.1h0Z"/>
        </g>
        <g id="Phalangen_overlay_3right" data-name="Phalangen overlay 3right">
          <path id="Phalangen_overlay_3.1right" data-name="Phalangen overlay 3.1right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M394.77,163.25c-3.9-1.9-8.4-1.9-14.2,1.5-7.8,5.5-10,.4-7.5-13.5,7.7-7.5,4.4-26.5-1.9-48.8-3.9-15.2,4.6-13.5,13.9-10.5,11.8.3,12,7,7.1,16.5-4.9,11.8-2.9,24.7,7.5,39,7.1,13.7,7,20.4-4.9,15.8h0Z"/>
          <path id="Phalangen_overlay_3.2right" data-name="Phalangen overlay 3.2right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M389.17,90.45h0l-8.2-1.8c-6.2,1.5-9.4,0-7.1-7.1,2.1-6.2,3-11.8,3-16.9-2.7-7.3,2.2-9.7,11.6-9.4,13,3.2,13.5,12,8.6,23.2,2.5,9.1,1.5,14.7-7.9,12h0Z"/>
          <path id="Phalangen_overlay_3.3right" data-name="Phalangen overlay 3.3right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M401.97,42.44h0c3.9,8.6,5.3,14.9-2.3,13.2l-15.8-3.8c-10.4.3-4.6-5,1.9-10.5,1.6-8.9,4-14.7,9.4-9.4,12.5-.6,12.5,3.8,6.8,10.5h0Z"/>
        </g>
        <g id="Phalangen_overlay_4right" data-name="Phalangen overlay 4right">
          <path id="Phalangen_overlay_4.1right" data-name="Phalangen overlay 4.1right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M429.27,185.75h0c-7-6.1-13.8-5.6-20.2,4.1-4.1-4.5-4.1-10.7.8-18.8,1.4-12.2,1.5-23.9-3.4-33.8l-3.8-13.1c-2.7-9.8.4-13.6,15-5.2,8.8-3.1,11.5-.6,7.5,7.9-6.6,14.2-3.1,29.7,10.9,46.5,4.1,10.2,2.5,14.9-6.8,12.4h0Z"/>
          <path id="Phalangen_overlay_4.2right" data-name="Phalangen overlay 4.2right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M424.77,113.34l-10.9,1.6c-8-.5-10-5.1-6.4-13.5-1.2-6.1,3.1-8.5,13.9-6.8,7.5,1.6,9,5.6,6.8,11.2,3.5,5.4,4.4,9.3-3.4,7.5h0Z"/>
          <path id="Phalangen_overlay_4.3right" data-name="Phalangen overlay 4.3right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M430.17,78.05c.3,4,2.2,4.8,3.9,5.9,5.5,8.6-.9,10.7-15.1,8.6-3.9.6-7.2.2-8.6-2.6s.2-6,3.2-7.2,4.6-2.6,4.8-4.6c.1-2,.8-4,2.3-5.4,4.1-3.9,7.2-1.5,9.5,5.3h0Z"/>
        </g>
        <g id="Phalangen_overlay_5right" data-name="Phalangen overlay 5right">
          <path id="Phalangen_overlay_5.1right" data-name="Phalangen overlay 5.1right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M467.97,213.84h0c-4.7-3-9.9-2.8-15.4-.6-6.6,6.5-10.7,5.4-9.8-12,4.7-20.1.6-35-5.2-48.8-3.8-10.6,4.1-7.1,12-3.8,9.3-1.2,13.6,1.5,9.4,10.5-1.2,12.4,1,23.3,6.8,32.6,9.4,9.6,10.7,17.1,2.2,22.1h0Z"/>
          <path id="Phalangen_overlay_5.2right" data-name="Phalangen overlay 5.2right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M456.67,145.25h0l-10.4-.3c-5.2-.1-7.3-2.1-6-6.4-2.5-2.8-2.6-5,2.6-6l10.1-3c4.9.5,7.4,3.3,7.1,8.6,3.6,4.5,2.6,6.9-3.4,7.1h0Z"/>
          <path id="Phalangen_overlay_5.3right" data-name="Phalangen overlay 5.3right" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M457.77,114.84c.5,3.7,2.2,4.4,3.8,5.2,5.2,7.7-.3,10.1-12.8,9-3.5.8-6.2.6-7.7-1.9s-.3-5.6,2.3-6.9,4-2.7,4-4.6.5-3.8,1.7-5.2c3.5-3.9,6.3-1.8,8.7,4.4h0Z"/>
        </g>
      </g>
    </g>
  </g>
  <g id="foot_overview_left" data-name="foot overview left">
    <g id="fore_foot" data-name="fore foot">
      <g id="background_layer" data-name="background layer">
        <path id="background_1" data-name="background 1" class="beide_vorfusse1474_obl_cls3" d="M66.74,389.54h0c-14.4,5-16.3-4.7-11.6-22.1,4-7.2,3-13.4-.4-19.1,6.5-72,1.7-109.7-9.8-126.8-4-4.9-4.4-10.1-2.2-15.4-2.2-9.2-.3-14.8,6.8-15.8,11.9-6.1,17.1-.5,16.5,15.4,6.2,3.3,5.6,8.9.8,16.1l12.8,101.2c6.2,9.1,9,19.5,8.2,31.1,7.9,19.7,9.3,31.7-2.6,28.1l-18.5,7.3h0Z"/>
        <path id="background_2" data-name="background 2" class="beide_vorfusse1474_obl_cls3" d="M95.94,372.33c-16.1,3.3-17.1-10.2-11.2-31.1,9.7-39.4,9.2-81.1.4-124.5-2.8-4.6-4.5-9-5.2-13.1-4.9-5.8-7-11.8-2.2-18-4.2-12.2-3.2-20.3,10.5-18.4,10,.6,13.5,3.6,9,10.1,5.9,7.3,7.7,14.6,3.4,22.1,3.1,47.9,7.2,93,14.6,127.9,6.2,7.2,9.4,15.3,7.9,24.8-1.9,10.4-8.8,18.2-27.2,20.2.1,0,0,0,0,0Z"/>
        <path id="background_3" data-name="background 3" class="beide_vorfusse1474_obl_cls3" d="M190.34,485.94c-5.6-29-23.9-34.1-51.7-21.3-8,8-15.2,6.6-21.4-6.8-1.9-7.2,3.2-13.5,12.9-19.1,7.5-12,18.8-21.5,42.8-22.5,17.1-4,30.5-2.4,34.3,14.6,7.4,22.7,8.5,40.9,1.7,53.4-6.9,13.2-12.9,10.6-18.6,1.7h0Z"/>
        <path id="background_4" data-name="background 4" class="beide_vorfusse1474_obl_cls3" d="M189.84,428.64c-22.3,3.9-30.8-7.3-33.2-25.3-6.1-23.3-5.7-38.9,3.4-44.4,7.1-3.5,10.6-10.2,11.2-19.7,15.3-9,27.6-7.3,34.9,12.9,3.7,9.1,3.7,16.9.6,23.6,6.2,33.1,2.9,53.8-16.9,52.9Z"/>
        <path id="background_5" data-name="background 5" class="beide_vorfusse1474_obl_cls3" d="M174.64,353.64h0c-15.1.6-23.2-2.9-22.1-12-6.4-16.5-2.4-31.8,5.6-46.5,11.1-30.6,13.1-57.9,4.9-81.4-6.8-14-4.7-26.4,2.2-37.9-.6-4,1.1-7.2,6-9,15.6-4,28.2-1.6,37.5,7.5,5.6,4.1,8,8.9,6.8,14.2,3.4,12,.7,23.7-7.1,35.2-9.9,28.1-9.9,61-.4,98.6,6.8,8.9,1.5,18.8-10.9,29.2l-22.5,2.1h0Z"/>
        <path id="background_6" data-name="background 6" class="beide_vorfusse1474_obl_cls3" d="M147.24,411.54h0l22.3-4.1c10.6-2.9,12.7-9.1,8.1-18-4.6-7.8-8.1-16.7-10.7-26.4-4.4-8.7-13.1-7.5-23.6-2.1-14.2,4.7-16.9,11.1-10.7,18.8-2,5.5-1.4,10.2,4.1,13.5l4,14.3c.8,2.8,3.6,4.5,6.5,4h0Z"/>
      </g>
      <g id="tarsus">
        <path id="tarsus_1" data-name="tarsus 1" class="beide_vorfusse1474_obl_cls5" d="M111.74,472.43h0c17.9,4.7,18.2-4.5,9.3-21.1-1.7-3.2-4.2-6-7.2-8-18.5-12.6-26.9-27.2-26.8-43.5,2.8-9.7-2.3-10.2-11.2-6.2-19.4,8-33.2,18.2-40.5,30.9-6.4,4.6-10.3,9-2.2,11.8,6.6,3.4,9.7,11.7,9,25.3.8,12,8.6,19.1,27,18.6,11.7,5.4,21.8,5.5,29.8-1.1l12.8-6.7h0Z"/>
        <path id="tarsus_2" data-name="tarsus 2" class="beide_vorfusse1474_obl_cls4" d="M147.24,411.54h0l22.3-4.1c10.6-2.9,12.7-9.1,8.1-18-4.6-7.8-8.1-16.7-10.7-26.4-4.4-8.7-13.1-7.5-23.6-2.1-14.2,4.7-16.9,11.1-10.7,18.8-2,5.5-1.4,10.2,4.1,13.5l4,14.3c.8,2.8,3.6,4.5,6.5,4h0Z"/>
        <path id="tarsus_3" data-name="tarsus 3" class="beide_vorfusse1474_obl_cls5" d="M116.24,437.64h0c7.1,1.8,15.4-4.7,24.5-15.3.9-1.1,1.3-2.6.9-4-1.1-3.9-3.9-4.8-6.4-4.9,2.6-11.2-3.8-21.2-11.8-30.9.9-16.6-5.5-24.6-21.9-20.8-13.9,6.2-17.2,15.1-10.1,26.4l13.5,28.1c-3.7,9.9-.1,17.1,11.3,21.4h0Z"/>
        <path id="tarsus_4" data-name="tarsus 4" class="beide_vorfusse1474_obl_cls4" d="M189.34,485.94c-5.6-29-23.9-34.1-51.7-21.3-8,8-15.2,6.6-21.4-6.8-1.9-7.2,3.2-13.5,12.9-19.1,7.5-12,18.8-21.5,42.8-22.5,17.1-4,30.5-2.4,34.3,14.6,7.4,22.7,8.5,40.9,1.7,53.4-6.9,13.2-12.9,10.6-18.6,1.7h0Z"/>
        <path id="tarsus_5" data-name="tarsus 5" class="beide_vorfusse1474_obl_cls4" d="M188.84,428.64c-22.3,3.9-30.8-7.3-33.2-25.3-6.1-23.3-5.7-38.9,3.4-44.4,7.1-3.5,10.6-10.2,11.2-19.7,15.3-9,27.6-7.3,34.9,12.9,3.7,9.1,3.7,16.9.6,23.6,6.2,33.1,2.9,53.8-16.9,52.9Z"/>
      </g>
      <g id="metatarsus">
        <path id="metatarsus_1" data-name="metatarsus 1" class="beide_vorfusse1474_obl_cls5" d="M28.14,421.43h0c-8,13.2-15.6,13.1-22.7-2.2-2.4-5.2-2.2-11.2.3-16.3,21.5-43.5,15.8-95.8,2.1-150.6-6.2-4.9-8.5-10.9-6.8-18,2.3-13.2,7.1-21.4,17.2-18.8,4.4-1.9,8.4-.2,12,7.1-2.9,5.1-3.2,9.9,2.2,13.9,6.8,28.6,10.4,57.6,9.4,87-1.1,18.1,2,34.2,14.2,45.8,7.7,6.4,10.1,14.5,6.4,24.6-.8,2.2-2.6,3.8-4.8,4.5-11.9,4.3-21.5,12.3-29.5,23h0Z"/>
        <path id="metatarsus_2" data-name="metatarsus 2" class="beide_vorfusse1474_obl_cls4" d="M66.74,389.93h0c-14.4,5-16.3-4.7-11.6-22.1,4-7.2,3-13.4-.4-19.1,6.5-72,1.7-109.7-9.8-126.8-4-4.9-4.4-10.1-2.2-15.4-2.2-9.2-.3-14.8,6.8-15.8,11.9-6.1,17.1-.5,16.5,15.4,6.2,3.3,5.6,8.9.8,16.1l12.8,101.2c6.2,9.1,9,19.5,8.2,31.1,7.9,19.7,9.3,31.7-2.6,28.1l-18.5,7.3h0Z"/>
        <path id="metatarsus_3" data-name="metatarsus 3" class="beide_vorfusse1474_obl_cls4" d="M96.04,372.73h0c-16.1,3.3-17.1-10.2-11.2-31.1,9.7-39.4,9.2-81.1.4-124.5-2.8-4.6-4.5-9-5.2-13.1-4.9-5.8-7-11.8-2.2-18-4.2-12.2-3.2-20.3,10.5-18.4,10,.6,13.5,3.6,9,10.1,5.9,7.3,7.7,14.6,3.4,22.1,3.1,47.9,7.2,93,14.6,127.9,6.2,7.2,9.4,15.3,7.9,24.8-2,10.4-8.8,18.3-27.2,20.2h0Z"/>
        <path id="metatarsus_4" data-name="metatarsus 4" class="beide_vorfusse1474_obl_cls5" d="M138.04,356.93h0c-10.6,9.3-16.4,8.8-16.5-3,8.4-57,8.8-110.6-4.9-157.9-9.4-4.1-10.3-9.9-4.1-17.2-5.3-14.4-4.6-23.1,9.8-19.1,12.4-1.2,19.7,1.2,15.7,12,4.7,9.6,4.2,19,.4,28.1.4,11.9,1.5,24.4,3.4,37.5,2,28.3,7.6,55.8,16.5,82.5,9.1,12.5,13.6,24.1,6.4,33.4-8.2-.7-17.1.6-26.7,3.7h0Z"/>
        <path id="metatarsus_5" data-name="metatarsus 5" class="beide_vorfusse1474_obl_cls4" d="M173.64,353.64h0c-15.1.6-23.2-2.9-22.1-12-6.4-16.5-2.4-31.8,5.6-46.5,11.1-30.6,13.1-57.9,4.9-81.4-6.8-14-4.7-26.4,2.2-37.9-.6-4,1.1-7.2,6-9,15.6-4,28.2-1.6,37.5,7.5,5.6,4.1,8,8.9,6.8,14.2,3.4,12,.7,23.7-7.1,35.2-9.9,28.1-9.9,61-.4,98.6,6.8,8.9,1.5,18.8-10.9,29.2l-22.5,2.1h0Z"/>
        <path id="metatarsus_6" data-name="metatarsus 6" class="beide_vorfusse1474_obl_cls5" d="M147.74,209.23c-6.6-1.7-6.8-8.4-2.2-18.8,5.9-8.2,12-11.2,18.4-6,4.2,5.6,3.8,12.7,0,21-4.6,9.2-9.9,11.4-16.2,3.8h0Z"/>
      </g>
      <g id="toes">
        <g id="big_toe" data-name="big toe">
          <path id="big_toe_1" data-name="big toe 1" class="beide_vorfusse1474_obl_cls5" d="M170.24,161.93c-13.6,4-11.1-6.1-7.9-16.9,10.9-15.9,14.6-33.6,5.6-54.4-8.1-16.1-5.6-24.7,9.8-24.4,7.7,3.4,15.7,3.9,24,2.2,8.1-2.5,8.2,6,5.2,18.8-3,20.2-2.9,39.9,3.8,58.5,6.7,23.6.6,26.1-12,18-11.7-3.7-21.2-4.4-28.5-1.8h0Z"/>
          <path id="big_toe_2" data-name="big toe 2" class="beide_vorfusse1474_obl_cls5" d="M171.34,60.73c-16.8,4.2-18-2.3-10.9-14.6,6.4-7.5,7-15,1.9-22.5-4.9-8.7-3.7-15.2,2-20,3-2.5,7-3.5,10.9-3,7.9,1.1,11.5,5.2,10.7,12.5,1.3,7.1,3.3,14.8,10.9,26.6,8-2.9,10,1.5,8.2,10.5.3,9.8-5.4,14.3-15.4,15l-18.3-4.5h0Z"/>
        </g>
        <g id="second_toe" data-name="second toe">
          <path id="second_toe_1" data-name="second toe 1" class="beide_vorfusse1474_obl_cls5" d="M115.84,152.93h0c-11.3,1.8-9.3-6-3-16.9,10.8-9.6,14-25.2,10.5-46.1-2.6-9.7-.7-13.5,6.4-10.5,3.4,1.9,7,2.1,10.9,0,5-5.9,7.1-2.2,7.1,8.2-8,16.9-11,34.3-7.5,52.5,5.5,19.2,1,21.2-8.2,15-6.1-4.1-11.5-4.9-16.2-2.2h0Z"/>
          <path id="second_toe_2" data-name="second toe 2" class="beide_vorfusse1474_obl_cls5" d="M126.04,74.23c-10.2,4.8-9.6-1.5-7.1-9.8,2.7-5.7,2.6-11.2-.8-16.5-2.3-6.1-1.5-10.8,7.1-11.6,10.8,0,17.5,1.7,12.4,7.9.6,8.3,2.2,16,6,22.1,3.2,8.1-.7,10.6-9.8,9,.1,0-7.8-1.1-7.8-1.1Z"/>
          <path id="second_toe_3" data-name="second toe 3" class="beide_vorfusse1474_obl_cls5" d="M117.74,32.23c-4.5,1.4-7.8,1.3-4.1-7.1-3.8-4.1-5.8-8.2-2.2-12.3,2.4-2.8,6-4.1,9.7-3.9l6.4.4h1.4c7.1-.4,6.1,4,2.7,9.8,9.4,8.5,12,14,1.1,13.5l-15-.4h0Z"/>
        </g>
        <g id="third_toe" data-name="third toe">
          <path id="third_toe_1" data-name="third toe 1" class="beide_vorfusse1474_obl_cls5" d="M82.54,162.73h0c-11.9,4.6-12-2.1-4.9-15.8,10.4-14.3,12.4-27.2,7.5-39-4.9-9.5-4.7-16.2,7.1-16.5,9.3-3,17.8-4.7,13.9,10.5-6.3,22.3-9.6,41.3-1.9,48.8,2.5,13.9.3,19-7.5,13.5-5.8-3.4-10.3-3.4-14.2-1.5h0Z"/>
          <path id="third_toe_2" data-name="third toe 2" class="beide_vorfusse1474_obl_cls5" d="M88.14,89.93h0c-9.4,2.7-10.4-2.9-7.9-12-4.9-11.2-4.4-20,8.6-23.2,9.4-.3,14.3,2.1,11.6,9.4,0,5.1.9,10.7,3,16.9,2.3,7.1-.9,8.6-7.1,7.1l-8.2,1.8h0Z"/>
          <path id="third_toe_3" data-name="third toe 3" class="beide_vorfusse1474_obl_cls5" d="M75.34,41.93c-5.7-6.7-5.7-11.1,6.8-10.5,5.4-5.3,7.8.5,9.4,9.4,6.5,5.5,12.3,10.8,1.9,10.5l-15.8,3.8c-7.6,1.7-6.2-4.6-2.3-13.2h0Z"/>
        </g>
        <g id="fourth_toe-2" data-name="fourth toe">
          <path id="fourth_toe_1" data-name="fourth toe 1" class="beide_vorfusse1474_obl_cls5" d="M48.04,185.23c-9.3,2.5-10.9-2.2-6.8-12.4,14-16.8,17.5-32.3,10.9-46.5-4-8.5-1.3-11,7.5-7.9,14.6-8.4,17.7-4.6,15,5.2l-3.8,13.1c-4.9,9.9-4.8,21.6-3.4,33.8,4.9,8.1,4.9,14.3.8,18.8-6.4-9.7-13.2-10.2-20.2-4.1h0Z"/>
          <path id="fourth_toe_2" data-name="fourth toe 2" class="beide_vorfusse1474_obl_cls5" d="M52.54,112.83h0c-7.8,1.8-6.9-2.1-3.4-7.5-2.2-5.6-.7-9.6,6.8-11.2,10.8-1.7,15.1.7,13.9,6.8,3.6,8.4,1.6,13-6.4,13.5l-10.9-1.6h0Z"/>
          <path id="fourth_toe_3" data-name="fourth toe 3" class="beide_vorfusse1474_obl_cls5" d="M47.14,77.53h0c2.3-6.8,5.4-9.2,9.5-5.3,1.5,1.4,2.2,3.4,2.3,5.4.2,2,1.8,3.4,4.8,4.6s4.6,4.4,3.2,7.2c-1.4,2.8-4.7,3.2-8.6,2.6-14.2,2.1-20.6,0-15.1-8.6,1.7-1.1,3.6-1.9,3.9-5.9h0Z"/>
        </g>
        <g id="fifth_toe-2" data-name="fifth toe">
          <path id="fifth_toe_1" data-name="fifth toe 1" class="beide_vorfusse1474_obl_cls5" d="M9.34,213.33c-8.5-5-7.2-12.5,2.2-22.1,5.8-9.3,8-20.2,6.8-32.6-4.2-9,.1-11.7,9.4-10.5,7.9-3.3,15.8-6.8,12,3.8-5.8,13.8-9.9,28.7-5.2,48.8.9,17.4-3.2,18.5-9.8,12-5.5-2.2-10.7-2.4-15.4.6h0Z"/>
          <path id="fifth_toe_2" data-name="fifth toe 2" class="beide_vorfusse1474_obl_cls5" d="M20.64,144.73h0c-6-.2-7-2.6-3.4-7.1-.3-5.3,2.2-8.1,7.1-8.6l10.1,3c5.2,1,5.1,3.2,2.6,6,1.3,4.3-.8,6.3-6,6.4l-10.4.3h0Z"/>
          <path id="fifth_toe_3" data-name="fifth toe 3" class="beide_vorfusse1474_obl_cls5" d="M19.54,114.33c2.4-6.2,5.2-8.3,8.7-4.4,1.2,1.4,1.7,3.3,1.7,5.2s1.4,3.3,4,4.6,3.8,4.4,2.3,6.9-4.2,2.7-7.7,1.9c-12.5,1.1-18-1.3-12.8-9,1.6-.8,3.3-1.5,3.8-5.2h0Z"/>
        </g>
      </g>
    </g>
    <g id="overlay">
      <g id="Phalangen_overlay" data-name="Phalangen overlay" class="beide_vorfusse1474_obl_cls1">
        <g id="Phalangen_overlay_1" data-name="Phalangen overlay 1">
          <path id="Phalangen_overlay_1.1" data-name="Phalangen overlay 1.1" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M170.25,162.45c-13.6,4-11.1-6.1-7.9-16.9,10.9-15.9,14.6-33.6,5.6-54.4-8.1-16.1-5.6-24.7,9.8-24.4,7.7,3.4,15.7,3.9,24,2.2,8.1-2.5,8.2,6,5.2,18.8-3,20.2-2.9,39.9,3.8,58.5,6.7,23.6.6,26.1-12,18-11.7-3.7-21.2-4.4-28.5-1.8h0Z"/>
          <path id="Phalangen_overlay_1.2" data-name="Phalangen overlay 1.2" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M171.35,61.25c-16.8,4.2-18-2.3-10.9-14.6,6.4-7.5,7-15,1.9-22.5-4.9-8.7-3.7-15.2,2-20,3-2.5,7-3.5,10.9-3,7.9,1.1,11.5,5.2,10.7,12.5,1.3,7.1,3.3,14.8,10.9,26.6,8-2.9,10,1.5,8.2,10.5.3,9.8-5.4,14.3-15.4,15l-18.3-4.5h0Z"/>
        </g>
        <g id="Phalangen_overlay_2" data-name="Phalangen overlay 2">
          <path id="Phalangen_overlay_2.1" data-name="Phalangen overlay 2.1" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M115.85,153.45c-11.3,1.8-9.3-6-3-16.9,10.8-9.6,14-25.2,10.5-46.1-2.6-9.7-.7-13.5,6.4-10.5,3.4,1.9,7,2.1,10.9,0,5-5.9,7.1-2.2,7.1,8.2-8,16.9-11,34.3-7.5,52.5,5.5,19.2,1,21.2-8.2,15-6.1-4.1-11.5-4.9-16.2-2.2h0Z"/>
          <path id="Phalangen_overlay_2.2" data-name="Phalangen overlay 2.2" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M126.05,74.75c-10.2,4.8-9.6-1.5-7.1-9.8,2.7-5.7,2.6-11.2-.8-16.5-2.3-6.1-1.5-10.8,7.1-11.6,10.8,0,17.5,1.7,12.4,7.9.6,8.3,2.2,16,6,22.1,3.2,8.1-.7,10.6-9.8,9,.1,0-7.8-1.1-7.8-1.1Z"/>
          <path id="Phalangen_overlay_2.3" data-name="Phalangen overlay 2.3" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M117.75,32.75c-4.5,1.4-7.8,1.3-4.1-7.1-3.8-4.1-5.8-8.2-2.2-12.3,2.4-2.8,6-4.1,9.7-3.9l6.4.4h1.4c7.1-.4,6.1,4,2.7,9.8,9.4,8.5,12,14,1.1,13.5l-15-.4h0Z"/>
        </g>
        <g id="Phalangen_overlay_3" data-name="Phalangen overlay 3">
          <path id="Phalangen_overlay_3.1" data-name="Phalangen overlay 3.1" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M82.55,163.25h0c-11.9,4.6-12-2.1-4.9-15.8,10.4-14.3,12.4-27.2,7.5-39-4.9-9.5-4.7-16.2,7.1-16.5,9.3-3,17.8-4.7,13.9,10.5-6.3,22.3-9.6,41.3-1.9,48.8,2.5,13.9.3,19-7.5,13.5-5.8-3.4-10.3-3.4-14.2-1.5h0Z"/>
          <path id="Phalangen_overlay_3.2" data-name="Phalangen overlay 3.2" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M88.15,90.45h0c-9.4,2.7-10.4-2.9-7.9-12-4.9-11.2-4.4-20,8.6-23.2,9.4-.3,14.3,2.1,11.6,9.4,0,5.1.9,10.7,3,16.9,2.3,7.1-.9,8.6-7.1,7.1l-8.2,1.8h0Z"/>
          <path id="Phalangen_overlay_3.3" data-name="Phalangen overlay 3.3" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M75.35,42.45c-5.7-6.7-5.7-11.1,6.8-10.5,5.4-5.3,7.8.5,9.4,9.4,6.5,5.5,12.3,10.8,1.9,10.5l-15.8,3.8c-7.6,1.7-6.2-4.6-2.3-13.2h0Z"/>
        </g>
        <g id="Phalangen_overlay_4" data-name="Phalangen overlay 4">
          <path id="Phalangen_overlay_4.1" data-name="Phalangen overlay 4.1" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M48.05,185.75c-9.3,2.5-10.9-2.2-6.8-12.4,14-16.8,17.5-32.3,10.9-46.5-4-8.5-1.3-11,7.5-7.9,14.6-8.4,17.7-4.6,15,5.2l-3.8,13.1c-4.9,9.9-4.8,21.6-3.4,33.8,4.9,8.1,4.9,14.3.8,18.8-6.4-9.7-13.2-10.2-20.2-4.1h0Z"/>
          <path id="Phalangen_overlay_4.2" data-name="Phalangen overlay 4.2" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M52.55,113.35h0c-7.8,1.8-6.9-2.1-3.4-7.5-2.2-5.6-.7-9.6,6.8-11.2,10.8-1.7,15.1.7,13.9,6.8,3.6,8.4,1.6,13-6.4,13.5l-10.9-1.6h0Z"/>
          <path id="Phalangen_overlay_4.3" data-name="Phalangen overlay 4.3" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M47.15,78.05h0c2.3-6.8,5.4-9.2,9.5-5.3,1.5,1.4,2.2,3.4,2.3,5.4.2,2,1.8,3.4,4.8,4.6s4.6,4.4,3.2,7.2-4.7,3.2-8.6,2.6c-14.2,2.1-20.6,0-15.1-8.6,1.7-1.1,3.6-1.9,3.9-5.9h0Z"/>
        </g>
        <g id="Phalangen_overlay_5" data-name="Phalangen overlay 5">
          <path id="Phalangen_overlay_5.1" data-name="Phalangen overlay 5.1" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M9.35,213.85c-8.5-5-7.2-12.5,2.2-22.1,5.8-9.3,8-20.2,6.8-32.6-4.2-9,.1-11.7,9.4-10.5,7.9-3.3,15.8-6.8,12,3.8-5.8,13.8-9.9,28.7-5.2,48.8.9,17.4-3.2,18.5-9.8,12-5.5-2.2-10.7-2.4-15.4.6h0Z"/>
          <path id="Phalangen_overlay_5.2" data-name="Phalangen overlay 5.2" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M20.65,145.25h0c-6-.2-7-2.6-3.4-7.1-.3-5.3,2.2-8.1,7.1-8.6l10.1,3c5.2,1,5.1,3.2,2.6,6,1.3,4.3-.8,6.3-6,6.4l-10.4.3h0Z"/>
          <path id="Phalangen_overlay_5.3" data-name="Phalangen overlay 5.3" class="Phalangen1474Fill" style= {{
              fill: props.colors.Phalangen1474Fill,
              stroke: props.colors.Phalangen1474Fill,
              opacity:props.colors.Phalangen1474Fill,
            }} d="M19.55,114.85c2.4-6.2,5.2-8.3,8.7-4.4,1.2,1.4,1.7,3.3,1.7,5.2s1.4,3.3,4,4.6,3.8,4.4,2.3,6.9-4.2,2.7-7.7,1.9c-12.5,1.1-18-1.3-12.8-9,1.6-.8,3.3-1.5,3.8-5.2h0Z"/>
        </g>
      </g>
    </g>
  </g>
</svg>
  );
}

export default BeideVorfusseDplOblLiegend1474;
