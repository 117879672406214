import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";

const AnkleRight = map.AnkleRight;

function FrontView({ selected, bpartSetter }) {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 165.3 296.4"
    >
      <g id="ankle">
        <path
          id="Tibia"
          class="rankle-cls-2"
          d="M103.11,4.9c.5,29.5-.7,65.2,2,90.2,5.7,31.1,11.7,57,18.3,75.2-.6,9.4-3.3,19.2-13.4,30.5-13.5,3-25.8,2-38.6,0-12-4.8-21.9-2.7-28.1,11.8-.2,3.1-1.9,5-4.5,6.1-8.9-1.2-25.2-13-14.6-35.4,10.5-18.3,18-40.9,24.4-65.1,5.1-35.7,7.1-78.1,6.9-113.5"
        />
        <path
          id="Fibula"
          class="rankle-cls-2"
          d="M134.11,4.7c.2,40.1,3.2,85.4,5.6,135.1,1,32,11,65.8,12.3,90.6-2,8.1-8.4,17.8-14.9,11.2-2.3-3.4-3.6-4.9-5.9-5-7.2,1.6-18-26.4-16.4-41,5.4-9.8,8.7-19.5,8.6-25.4l-4.1-15c2.2-59.3-.6-121.4-.9-150.4"
        />
        <path
          id="Talus"
          class="rankle-cls-2"
          d="M100.21,262.2c-10.8-1.2-11.1,6.8-10.1,12.6-9.7,3.4-25.6,4.8-44.3,3.7-6.6-2.4-12-6.8-14.5-10.2-9.9-8.3,6.7-14.5,8-22-2.6-4.8-4.3-10.1-3.8-14.8,1.4-4.2,10.7-12,14.3-27.1,3.3-5.8,12.5-4.7,21.6-3.7l11.9,3.7c6-3.7,14.8-1.4,22.9-.3,6.6,3.4,8,7.1,9.1,11.7,.7,8.9,1.9,16.1,1.3,23.1,4.5,5,7.9,9.9,9.2,14.8-4.8,11.9-11.6,19.3-24.8,13,.6-1.7,.3-3.2-.8-4.5h0Z"
        />
        <path
          id="dark_spot"
          class="rankle-cls-1"
          d="M86.61,275.9l3.5-1.1c-1.5-9,2.1-13.1,10.2-12.6,.5,1.2,.7,2.7,.8,4.5-.9,4-2.1,7.6-4.1,10l-1.3,5c-2-3.3-4.8-5.5-9.1-5.8h0Z"
        />
        <g id="scaphoid">
          <path
            id="scaphoid_2"
            class="rankle-cls-2"
            d="M20.51,292.1c-3.1-11.2-2.6-21.3,4.5-24.6,3.5-2.9,14.4,9.7,20.8,11,12.8,.4,25.8,.8,32.1-1.3,17.8-2.7,17.3,2.3,17.8,15"
          />
          <path
            id="scaphoid_1"
            class="rankle-cls-2"
            d="M128.81,292.3c-.7-1.4-1.2-2.7-.4-3.7,0-6.2-2.4-10.4-5.3-17.1-.1-1.5-.4-5.3,.7-10.6,1.5-2.8,1.8-5.1,2.1-7.3-4.9,11-10.8,19.9-24.8,13-1.2,4.4-1.9,8.5-4.1,10-1.4,1.7-1.3,9.7-1.3,15.6"
          />
          <path
            id="scaphoid_inner_line"
            class="rankle-cls-2"
            d="M96.41,286.5c12.8-5.5,22-.8,31.9,2.2"
          />
        </g>
        <path
          id="talus_inner_line_1"
          class="rankle-cls-2"
          d="M56.71,202.9c.8,7.5,.9,18.1-2,25.6-8.7,10.8-13.1,8.1-17.9,1.6"
        />
        <path
          id="talus_inner_line_2"
          class="rankle-cls-2"
          d="M51.51,248.7c-3.2-.1-7.4,.7-9.9,2-3.2,1.7-3.8,6.7-3.1,10.2,7.1,6.8,10.4,7.2,16.6,1.7"
        />
      </g>
      <g id="selections">
        <path
          id="down_ankle_selection"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                AnkleRight.id,
                AnkleRight.children.midAnkle
              ),
            });
          }}
          class={`rankle-cls-3 ${isSelected(
            selected,
            AnkleRight.children.midAnkle
          )}`}
          d="M43.31,212.6c3-6.5,5.3-8.4,7-10-1.5,4-2.7,9.1-5.3,13.6l70.2-.3c-.3-5.6-3.9-9-8.8-11.9l3.5-3.2c2.7-2.3,4.1-3.9,4.8-5.1-.7,16.9,7.6,41.7,16.4,41,2.7,.7,4.6,2.4,5.9,5,5.8,6.9,13.9-3.3,14.9-11.2,.2-11.5-6.3-38.6-10.3-67.6H34.31s-10.1,20.4-10.1,20.4c-7.4,12.6-4.3,30.9,14.6,35.4,3.3-1.7,5-3.3,4.5-6.1h0Z"
        />
        <path
          id="middle_ankle_selection"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                AnkleRight.id,
                AnkleRight.children.lowerAnkle
              ),
            });
          }}
          class={`rankle-cls-3 ${isSelected(
            selected,
            AnkleRight.children.lowerAnkle
          )}`}
          d="M115.31,216s-70.2,.2-70.1,.2c-1.5,5.1-8.3,11.9-9.7,15.4-.6,6.1,1.1,10.6,3.8,14.8-2,7.3-18.2,14.6-8,22-3-1.3-5.4-2-6.2-.8-6.4,3.4-8,9.3-4.6,24.4h108.6c-1.5-.9-1.4-2.1-.8-3.5,.4-4.5-.8-9.9-5.2-16.8-.2-3.7,0-7.2,.7-10.6,1.7-2.1,2.1-4.6,2.1-7.3,.9-2.4-7.3-13.6-9.4-14.9,1.1-6.1,.1-14.4-1.2-22.9h0Z"
        />
        <path
          id="upper_ankle_selection"
          onClick={() => {
            bpartSetter({
              id: idToSet(
                selected,
                AnkleRight.id,
                AnkleRight.children.upperAnkle
              ),
            });
          }}
          class={`rankle-cls-3 ${isSelected(
            selected,
            AnkleRight.children.upperAnkle
          )}`}
          d="M118.41,4.8c1.2,53.9,2.4,105.2,.6,150-5.5-19.3-10.5-39.4-13.9-59.7-2.7-28.9-1.5-59.7-1.9-90.2l-47.8,.2c.1,33.1-1.7,77.6-7.3,114.1-3.8,14.7-8.1,28.7-14,43.9h107.4c-4-54.5-6.6-107.3-7.6-158.3h-15.5Z"
        />
      </g>
    </svg>
  );
}

export default FrontView;
