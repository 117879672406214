import { isSelected, idToSet } from "../../Common/SelectHelper";
import map from "../../../../bodypartDbIdMapping.json";
const HandLeft = map.HandLeft;

function FrontView({ selected, bpartSetter }) {
  return (
    <svg
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 449.22 758.03"
    >
      <g id="Ebene_1-2" data-name="Ebene 1">
        <g id="Hand">
          <path
            id="hand_silohuette"
            data-name="hand silohuette"
            class="lhnd-cls-2"
            d="M119.55,757.53c-1.66-9.46-1.76-19.16,0-29.17-6.17-18.84-5.69-35.99-1.04-52.08,10.54-15.08-.45-66.94-15.62-78.12-32.37-56.92-54.02-121.75-58.45-199.33-4.68-16.27-8.01-34.17-16.07-50.03-5.69-5.77-22.69-80.93-26.85-106.37,1.1-14.9,.18-30.07-1.02-46.37,10.61-27.66,35.15-17.02,43.93,30.92,7.12,23.39,16.21,45.87,26.16,65.99,6.46,24.91,12.74,44.08,18.75,54.17,6.3-.84,10.79-7.17,14.58-15.62-1.06-20.26-6.18-41.19-13.54-62.5-9.55-19.78-10.15-61.75-15.62-102.08-4.51-18.11-5.52-46.2-5.71-76.62-1.02-28.47,33.35-29.9,42.71-5.49,9.93,23.85,16.99,44.58,14.64,55.12,11.8,42.71,23.3,96.37,32.73,156.16,4.74,3.61,9.42,2.19,14.05-3.01-.21-10.07-.37-24.02-2.59-41.78-6.01-32.51-6.06-78.76-5.21-127.08-2.66-46.59-5.47-83.66-4.17-102.08,3.42-24.38,36.98-31.7,46.54-3.43,2.65,7.33,6.31,38.85,13.88,47.18,2.76,41.2,6.91,81.49,12.5,120.83,3.47,25.25,4.17,63.89,4.34,105.14,5.01,3.4,9.85,8.36,16.07,0,.17-25.78-1.18-49.25,2.51-54.1,.11-21.61,.4-45.04,2.08-69.79,.14-18.52,1.04-35.71,4.17-48.96-.3-17.56-.08-34.24,1.61-48.44,6.11-46.17,48.21-39.46,52.56-.52,5.04,47.57,9.19,91.59,11.46,128.12,1.54,98.26,1.9,206.54,16.67,192.71,11.25-24.58,13.22-55.37,28.12-77.08-2.85-12.16,19.63-34.59,47.92-59.38,13.41-.37,23.44,3.29,27.08,14.58-.65,20.39-1.36,22.85-13.77,36.48,2.1,3.41,.64,12.11-1.37,21.63-11.52,23.17-15.3,70.91-21.61,121.15-18.58,50.45-56.95,165.35-68.34,165.15-6.14,5.87-10.43,14.21-12.61,25.38,.11,41.88-1.26,82.79-13.54,116.67"
          />
          <g id="fore_arm_hand_joints" data-name="fore arm hand joints">
            <path
              id="fore_arm_hand_joints_r"
              data-name="fore arm hand joints r"
              class="lhnd-cls-2"
              d="M149.5,752.86l-.61-26.85c-1.56-14.51-4.94-27.41-10.07-38.75-1.41-11.59,3.04-15.81,10.07-16.78,1.57,.59,3.4,2.51,6.41,10.68,10.46-3.01,20.72-5.84,27.76-5.8,10.1,6.26,14.68,16.92,14.03,31.73-7.47,6.77-12.66,17.05-16.17,29.9l2.14,15.86"
            />
            <path
              id="fore_arm_hand_joints_l"
              data-name="fore arm hand joints l"
              class="lhnd-cls-2"
              d="M216,754.68c-1.75-22.38-4.94-44.24-18-62.54-6.41-11.86-2.78-19.02,3.36-25.02,21.09-.42,41.77,.28,61.93,2.44,23.8-7.93,24.8-14.83,33.56-12.51,6.12,6.43,7.66,20.91,7.63,38.14-9.5,12.41-15.42,30.34-14.03,59.49"
            />
          </g>
          <g id="wrist_bones" data-name="wrist bones">
            <path
              id="wrist_bones_8"
              data-name="wrist bones 8"
              class="lhnd-cls-2"
              d="M163.84,627.76c-6.25,5.72-13.19,8.15-22.58-7.32-8.62-18.12-2.01-23.51,6.71-27.15-.75,8.01-1.09,15.53,1.53,19.53,2.02,2.93,8.05,8.76,14.34,14.95h0Z"
            />
            <path
              id="wrist_bones_7"
              data-name="wrist bones 7"
              class="lhnd-cls-2"
              d="M242.55,655.54c1.6,.81,10.86,11.26,6.71,7.93-6.41-5.14-26.1,.83-38.14-1.53-9.4-5.43-18.38-13.4-27.15-22.58,5.49-10.41,10.37-14.74,16.88-16.88,22.6-5.08,31.97,1.96,36.51,11.39,.55,8.55,1.11,17.07,5.19,21.66h0Z"
            />
            <path
              id="wrist_bones_6"
              data-name="wrist bones 6"
              class="lhnd-cls-2"
              d="M280.38,596.96c-12.57-1.65-25.12-3.31-25.02-8.24-7.97-7.7-8.84-20.44-12.81-30.81,2.47,4.53,7.46,4.35,13.73,1.83,8.2-14.07,18.33-14.05,30.81,3.36,6.98,13.92,6.7,25.76-6.71,33.86h0Z"
            />
            <path
              id="wrist_bones_5"
              data-name="wrist bones 5"
              class="lhnd-cls-2"
              d="M250.17,664.07c-9.92-9.27-13.91-20.13-12.81-32.34,27.34-3.82,23.51-21.57,18.92-39.66,6.1,1.24,12.02,2.97,17.89,4.61,9.83,2.75,19.43,6.16,19.33,9.12,19.14,17.19,15.28,25.64-1.22,29.29-4.59,25-21.48,30.02-42.1,28.98h0Z"
            />
            <path
              id="wrist_bones_4"
              data-name="wrist bones 4"
              class="lhnd-cls-2"
              d="M205.73,620.35c-10.06,1.01-62.44-30.82-62.64-43.53-1.14-25.08,42.17-29.23,47.29-22.88,27.68,27.35,27.26,50.19,15.36,66.41h-.01Z"
            />
            <path
              id="wrist_bones_3"
              data-name="wrist bones 3"
              class="lhnd-cls-2"
              d="M182.75,640.88c-4.88,2.85-10.17-3.66-18.87-14.08-6.9-8.6-23.84-17.44-15.91-33.51,14.4-1,30.2,19.73,50.03,26.24,1.28,4.64-12.69,12-15.25,21.36h0Z"
            />
            <path
              id="wrist_bones_2"
              data-name="wrist bones 2"
              class="lhnd-cls-2"
              d="M279.46,597.56c17.48-11.65,12.73-23.31,7.63-34.47,7.45,.37,11.91-4.15,14.95-10.98,3.57-4.89,9.76,2.44,9.76,2.44-2.11,3.51,2.2,18.26,14.03,19.22,8.01,5.89,8.75,16.43,4.88,29.9-18.71,11.29-33.45,.71-51.25-6.1h0Z"
            />
            <path
              id="wrist_bones_1"
              data-name="wrist bones 1"
              class="lhnd-cls-2"
              d="M237.36,631.73c-9.85-11.03-18.61-10.78-31.63-11.39,13.15-20.66,9.97-42.45-14.64-65.69,4.25-1.71,7.58-5.39,9.97-11.08,1.52,4.16,3.9,6.95,7.63,7.63l14.64-6.41c6.67,2.36,13.09,6.63,19.22,13.12,3.59,15.08,7.64,26.92,12.81,30.81l.92,3.36c5.5,20.21,5.04,36.9-18.92,39.66h0Z"
            />
          </g>
          <g id="thumb_bones" data-name="thumb bones">
            <path
              id="thumb_bones_1"
              data-name="thumb bones 1"
              class="lhnd-cls-2"
              d="M388.68,329.4c-.31-6.6-1.61-13.46,8.54-17.39,10.85-5.39,14.38-16.7,15.56-29.9,4.61-9.5,11.62-10.57,19.53-8.54-1.65,14.63-.4,28.93-13.42,44.85,.28,7.92-.51,14.59-.61,23.19-3.06,2.31-7.6,2.07-13.73-.92-5.19-6.33-10.47-10.35-15.86-11.29h-.01Z"
            />
            <path
              id="thumb_bones_2"
              data-name="thumb bones 2"
              class="lhnd-cls-2"
              d="M359.4,423.06c1.31-6.03,10.16-12.49,12.51-17.39,5.3-13.38,8.22-30.88,10.37-49.73-10.01-10.88-3.14-18.93,6.41-26.54,5.95,.97,11.01,5.68,15.86,11.29,5.06,2.36,9.86,4.56,11.29,1.22-1.05,13.01-4.74,24.6-9.76,37.22-5.79,20.11-9.86,38.51-8.24,51.25,3.32,9.13,2.73,16.68-9.46,19.53-5.4-9.36-11.77-15.15-20.44-12.51-5.21-2.96-8.62-7.3-8.54-14.34h0Z"
            />
          </g>
          <g id="ringer_finger_bones" data-name="ringer finger bones">
            <path
              id="ringer_finger_bones_1"
              data-name="ringer finger bones 1"
              class="lhnd-cls-2"
              d="M97.03,140.85c-13.73,5.49-23.49-2.44-8.24-10.98l-1.22-23.19c.38-1.05-2.98-3.79-8.24-4.88-1.83-15,3.47-19.05,12.51-17.39,11.9,.61,18.02,19.75,8.24,23.19,6.1,25.01,7.28,18.61,15.25,25.32,1.36,8.38-6.35,9.78-18.31,7.93h0Z"
            />
            <path
              id="ringer_finger_bones_2"
              data-name="ringer finger bones 2"
              class="lhnd-cls-2"
              d="M112.28,140.24c-4.62,2.87-9.77,2.69-15.25,.61-3.27,3.15-6.1,1.22-10.68,3.36l.31,14.64c.34,5.78,3.09,11.58,8.54,17.39,3.63,13.27,4.58,25.95,1.83,37.83-6.6,12.72,5.14,14.48,22.58,12.81,13.31,2.96,14.98,.22,15.56-3.05-5.74-7.42-10.47-15.13-10.68-24.1-5.33-15.18-8.81-29.67-10.07-43.32,1.22-5.8,1.2-9.76-2.14-16.17h0Z"
            />
            <path
              id="ringer_finger_bones_3"
              data-name="ringer finger bones 3"
              class="lhnd-cls-2"
              d="M114.71,228.1l19.22-.61c4.77,6.1,4.62,17.14,1.22,22.88,1.13,6.75,1.63,13.05,1.53,18.92,5.8,40.89,20.75,61.94,21.36,75.97,3.28,.51,5.88,2.66,7.93,6.1,.77,13.14-2.99,18.11-10.07,17.08-12.04-6.34-22.9-5.64-32.64,1.83-3.27,1.51-5.59-1.95-7.32-8.54-2-2.2,.44-12.12,3.36-22.88-6.36-13.33-9.75-30.95-11.29-51.25l-2.14-31.73c-9.76-.61-9.46-24.41-3.66-28.68l12.51,.92h0Z"
            />
          </g>
          <g id="middlefinger_bones" data-name="middlefinger bones">
            <path
              id="middlefinger_bones_1"
              data-name="middlefinger bones 1"
              class="lhnd-cls-2"
              d="M172.38,69.78c6.97,1.19,8.22-8.89,7.02-23.8-12.3-1.21-3.97-10.68-1.53-27.15,5.06-3.28,8.19-3.31,10.37-1.22,12.74,18.91,13.33,24.3,6.41,22.27,2.26,22.87,4.75,30.37,15.56,28.98-.11,3.95,.2,7.92-2.14,10.37-6.1,6.41-21.88,9.61-31.42,1.22-2.28-2.01-4.22-6.6-4.27-10.68h0Z"
            />
            <path
              id="middlefinger_bones_2"
              data-name="middlefinger bones 2"
              class="lhnd-cls-2"
              d="M207.17,80.74c5.19,13.73-6.1,32.95-1.22,39.36l8.24,36.92c5.29,13.55,10.16,23.79,4.27,24.71-9.98,13.75-21.45,10.63-33.56,.31-3.45-4.23-7.02-13.12,.61-20.14l-.92-33.56c-6.53-23.4-15.05-41.5-7.63-46.98,10.72,5.6,20.96,6.9,30.2-.61h0Z"
            />
            <path
              id="middlefinger_bones_3"
              data-name="middlefinger bones 3"
              class="lhnd-cls-2"
              d="M220.58,190.28c-7.52-3.52-25.8,2.04-33.86-5.19-6.57,8.05-7.16,19-.31,33.56l2.14,86.95c-6.23,11.26-10.84,21.84-7.93,29.29,2.45,4.34,6.67,3.99,12.51-.61,7.63-5.89,17.99-4.56,30.51,2.44,6.36,2.37,9.71-1.8,9.76-13.12-9.46-17.26-14.52-36.2-16.17-56.44l-.31-48.51c5.36-7.81,6.49-17.3,3.66-28.37h0Z"
            />
          </g>
          <g id="index_finger_bones" data-name="index finger bones">
            <path
              id="index_finger_bones_1"
              data-name="index finger bones 1"
              class="lhnd-cls-2"
              d="M284.04,103.02c.71-7.38,.09-13.85-2.14-19.22-3.24-2.23-5.24-6.92-3.97-18,2.46-7.24,6.27-11.5,11.29-13.12,5.06-.34,9.9,6.22,14.64,15.56-.58,3.61-2.32,7.1-6.1,10.37,.3,10.67,3.66,12.5,6.1,30.81,5.45,3.67,7.77,6.9,5.19,9.46-5.84,3.91-13.02,4.48-20.75,3.66-5.29-2.11-10.17-4.7-12.81-9.76-2.17-1.36-1.19-3.57,.92-6.1,.89,2.61,4.12-.21,7.63-3.66h0Z"
            />
            <path
              id="index_finger_bones_2"
              data-name="index finger bones 2"
              class="lhnd-cls-2"
              d="M273.99,201.89c-3.19-1.23-2.57-5.48-1.53-10.07,4.1-6.23,6.8-14.6,8.54-24.41-2.71-10.87-1.47-17.6-.31-24.41-6.46-13.7-2.43-17.64,1.83-21.36l21.36,.61c4.02,5.74,6.4,12.27,.31,22.88-.98,9.12-1.32,17.6-.31,24.71,.86,18.84,5.7,26.35,9.15,32.95-2.45,8.35-10.74,7.77-20.14,5.49-6.93-4.07-13.61-7.34-18.92-6.41l.02,.02Z"
            />
            <path
              id="index_finger_bones_3"
              data-name="index finger bones 3"
              class="lhnd-cls-2"
              d="M307.24,346.79c-12.81-8.87-23.61-9.01-33.56-5.49-5.52-2.34-7.4-9.08-6.71-18.92,6.16-10.64,8.4-25.96,9.46-42.71l.31-50.95c-5.22-3.83-5.68-12.73-1.53-26.54,5.01,.16,11.02,2.43,17.69,6.1l14.64,1.83c5.45,13.9,5.61,27.28-1.22,39.97l-.31,52.78c8.19,22.27,10.73,39,1.22,43.93h0Z"
            />
          </g>
          <g id="small_finger_bones" data-name="small finger bones">
            <path
              id="small_finger_bones_1"
              data-name="small finger bones 1"
              class="lhnd-cls-2"
              d="M7.33,238.79c.08-3.18,2.36-6.04,7.02-8.54-1.04-11.54-2.56-22.32-6.1-29.9-.37-3.69,2.79-5.56,7.93-6.41,8.27,11.46,15.02,17.27,9.46,21.05,1.64,10.6,5.68,17.67,10.37,23.8-1.83,8.24-19.87,8.19-28.68,0h0Z"
            />
            <path
              id="small_finger_bones_2"
              data-name="small finger bones 2"
              class="lhnd-cls-2"
              d="M35.1,243.05c5.24,2.63,6.1,10.69,4.88,21.36,6.09,9.82,11.36,21.8,16.47,34.17-3.64-.68-10.56-2.33-9.76,3.05-6.35,1.51-12.63,3.49-19.53,0-3.66-4.57-2.31-33.81-7.93-47.59-5.04-2.48-6.51-5.75-4.58-9.76,5.34,1.84,12.63,.7,20.44-1.22h.01Z"
            />
            <path
              id="small_finger_bones_3"
              data-name="small finger bones 3"
              class="lhnd-cls-2"
              d="M75.97,407.5c1.44,4.83-8.15,5.08-10.07,3.05-1.97-1.17-4.21-14.85-3.36-24.41-3.17-.2-15.25-36.92-26.54-61.63-8.97-9.8-6.16-15.19-4.58-21.05,7.01-2.13,11.17,2.47,12.98-.82,3.34-6.07,4.98-4.98,6.24-5.28,3.35,1.41,5.96,4.59,8.24,8.54,1.13,5.2,.59,11.65-.61,18.61,6.71,35.39,40.83,61.15,39.66,74.75-2.42,1.37-6.09,2.29-7.96,.85-4.61-3.54-10.01,2.03-14,7.39h0Z"
            />
          </g>
          <g id="middle_hand_bones" data-name="middle hand bones">
            <path
              id="middle_hand_bones_9"
              data-name="middle hand bones 9"
              class="lhnd-cls-2"
              d="M311.81,554.85c-5.11-6.02-8.38-4.73-9.76-2.75,1.01-7.22,.57-13.26,.31-15.56,4.6-6.13,10.03-9.61,17.08-7.93,15.21-14.69,23.98-34.81,28.07-61.02-3.45-6.41-3.61-10.18-2.75-13.12,4.61-5.06,10.28-9.53,16.47-13.73,12.15-8.35,29.29,1.53,29.9,19.53-6.82,17.43-13.58,35.2-21.97,43.63l-11.9,46.07c-3.79,16.18-8.23,24.41-31.42,23.8-7.78-2.37-15.57-4.74-14.03-18.92h0Z"
            />
            <path
              id="middle_hand_bones_8"
              data-name="middle hand bones 8"
              class="lhnd-cls-2"
              d="M158.38,552.71c-2.1-7.77-2.41-16.18-1.53-25.02,.84-10.86,.73-21.67-1.53-32.34-7.07-22.93-13.48-41.02-19.53-56.44-10.38-23.34-15.83-39.7-16.17-48.81-1.19-6.75,.54-13.33,3.66-19.83,8.37-7.17,19.28-7.69,32.64-1.83,4.34,6.68,7.63,13.98,9.76,21.97l.61,26.24c1.03,9.18,2.88,23.76,4.88,39.36,1.7,10.96,9.96,32.19,18,53.39,5.99,2.72,10.92,7.43,14.34,14.95-2.46-.27-4.29,.53-2.14,8.24l-.31,10.98c.8,2.36-11.09,14.15-10.68,10.37,.44-4.02-30.91-2.63-32.03-1.22h.03Z"
            />
            <path
              id="middle_hand_bones_7"
              data-name="middle hand bones 7"
              class="lhnd-cls-2"
              d="M119.5,390.1c12.32-21.01,27.18-18.61,46.37,.41"
            />
            <path
              id="middle_hand_bones_6"
              data-name="middle hand bones 6"
              class="lhnd-cls-2"
              d="M208.7,551.19c-4.98-.86-7.94-3.11-7.63-7.63,.3-5.8-1.81-20.04,2.44-19.22l-.61-7.02c6.35-14.63,7.24-33.06,3.66-54.61-12.21-41.79-28.42-90.52-25.63-106.47,2.56-8.57,6.85-15.71,12.2-21.97l30.51,2.44c16.48,16.17,8.54,22.89,14.95,38.14-2.38,7.92-5.6,13.04-10.07,14.03,1.61,8.51,1.64,16.95,0,25.32,1.08,30.84,4.7,58.95,7.93,88.17,5.87,4.93,10.29,10.84,12.81,18,1.84,13.55,.14,26.19-6.71,37.53-7.97-8.11-14.03-11.66-19.22-13.12-5.33,2.03-10.28,4.15-14.64,6.41h.01Z"
            />
            <path
              id="middle_hand_bones_5"
              data-name="middle hand bones 5"
              class="lhnd-cls-2"
              d="M242.55,557.92c4.96-7.09,7.31-14.66,7.32-32.95-.84-.95,9.35-18.36,17.69-25.02l-.31-110.75c-5.92-19.17-8.45-34.33,.92-35.39-3.71-1.53,1.87-7.49,5.49-12.51,10.88-4.24,22.06-2.58,33.56,5.49,4.49,2.96,6.23,14.08,8.24,24.41-1.17,6.83-4.1,8.07-8.24,5.49-3.8,16.67-7.34,36.56-9.76,70.17,1.11,29.98-.08,55.68-3.36,77.49,.76,4.23,3.76,8.28,8.24,12.2,.79,12.5-1.41,26.61-15.25,26.54-12.81-19.65-22.39-15.76-30.81-3.36-5.49,2.22-10.08,1.67-13.73-1.83v.02Z"
            />
            <path
              id="middle_hand_bones_4"
              data-name="middle hand bones 4"
              class="lhnd-cls-2"
              d="M138.19,569.49c-6.76,2.05-12.55-1.55-17.69-8.85-2.08-8.4-2.42-17.9-1.22-28.37-12.53-17.92-23.97-34.83-29.9-53.69-5.86-17.42-13.75-31.93-23.49-41.19,.38-3.75,.43-7.34,0-10.68-.31-11.01,3.31-10.98,8.24-7.93,2.31-13.58,10.44-17.45,20.75-17.69,6.24,1.05,8.83,7.32,10.68,14.64,5.12,4.92,5.61,12.02,3.97,20.14,11.73,46.35,23.39,87.83,47.29,91.83l1.53,25.02c-6.01,7.66-11.54,16.73-20.14,16.78h-.02Z"
            />
            <path
              id="middle_hand_bones_3"
              data-name="middle hand bones 3"
              class="lhnd-cls-2"
              d="M67.43,417.77c1.32,2.79,5.02,4.87,7.55,5.97,11.21-3.43,21.05-12.28,30.74-7.32"
            />
            <path
              id="middle_hand_bones_2"
              data-name="middle hand bones 2"
              class="lhnd-cls-2"
              d="M185.12,346.4c5.64,2.59,12.16,.43,14.11,5.06,5.9-.79,10.34-6.88,19.93-5.69,7.67,11.16,12.8,25.13,19.19,28.93"
            />
            <path
              id="middle_hand_bones_1"
              data-name="middle hand bones 1"
              class="lhnd-cls-2"
              d="M266.75,353.5c4.26,.18,7.85,.92,8.95,3.66,10.69-2.77,20.13-4.78,24.41-3.66,6.6,1.52,7.32,11.39,15.05,17.9"
            />
          </g>
        </g>
      </g>
      <g id="finger_1overlay" data-name="finger 1overlay">
        <path
          id="finger_1_overlay_1"
          data-name="finger 1 overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandLeft.id, HandLeft.children.finger1),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(
            selected,
            HandLeft.children.finger1
          )}`}
          d="M432.31,273.57c-1.15,20.06-4.54,36.66-13.42,44.85-.1,8.36-.28,16.21-.61,23.19l-2.44,.3c-6.92,33.01-20.27,66.62-17.26,90.71,.86,7.66,1.44,15.2-10.2,17.29-5.56-8.48-11.51-15.6-20.44-12.51-5.44-2.44-9.05-6.54-8.54-14.34,.86-3.87,6.48-10.51,12.51-17.39,5.52-15.6,8.76-32.28,10.37-49.73-8.48-8.62-5.84-17.48,6.4-26.54-1.49-9.45,1.4-15.21,8.54-17.39,8.5-2.35,13.18-13.47,15.56-29.9,5.44-9.29,12.08-11.36,19.53-8.54h0Z"
        />
      </g>
      <g id="finger_2_overlay" data-name="finger 2 overlay">
        <path
          id="finger_2_overlay_1"
          data-name="finger 2 overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandLeft.id, HandLeft.children.finger2),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(
            selected,
            HandLeft.children.finger2
          )}`}
          d="M303.86,68.24c-10.93-20.47-19.13-19.92-25.33-4.06-2.32,8.92-.73,15.14,3.37,19.62,2.21,7.87,3.18,14.53,2.14,19.22-2.95,3.32-5.73,5.71-7.63,3.66-1.96,2.36-3.19,4.57-.92,6.1l9.21,8.18-2.18,.68c-4.92,5.78-6.27,12.67-1.83,21.36-2.15,9.23-2.49,17.59,.31,24.41l-8.54,24.41c-1.72,5.1-2.23,9.44,2.75,10.36-3.37,10.22-4.85,19.77,1.53,26.54,2.31,42.52-.58,74.49-9.77,93.66-.68,12.69,2.42,17.11,6.69,18.92,9.13-5.29,21.07-.91,33.58,5.49,10.42-9.5,5.07-26.49-1.23-43.93l.31-52.78c6.96-11.87,5.45-25.62,1.22-39.97l5.49-7.32c-7.98-18.09-11.99-37.07-8.84-57.66,5.6-8.78,4.22-16.15-.31-22.88,9.07-3.1,6.98-7.64-.02-12.83l-6.1-30.81c3.31-1.41,5.27-4.99,6.1-10.37Z"
        />
      </g>
      <g id="finger_3_overlay" data-name="finger 3 overlay">
        <path
          id="finger_3_overlay_1"
          data-name="finger 3 overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandLeft.id, HandLeft.children.finger3),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(
            selected,
            HandLeft.children.finger3
          )}`}
          d="M188.24,17.61c-3.75-2.15-7.25-2.2-10.37,1.22-5.52,15.65-8.4,28.4,1.53,27.15,.55,14.24-.2,25.63-7.02,23.8l4.27,10.67c-4.56,5.29-1.32,22.13,7.94,47.89l.92,33.56c-5.87,6.72-7.86,14.07,1.21,23.19-5.91,9.23-7.56,19.89-.31,33.56l2.14,86.95c-10.32,24.71-12.44,38.92,4.58,28.67,7.13-6.57,17.22-3.33,28.02,2.24,6.51,3.26,10.95,.05,12.25-12.91-13.62-26.11-20.39-59.71-16.48-104.95,5.54-7.65,5.79-17.51,3.66-28.37l-8.84-1.3,6.73-7.25c4.76-.84,2.59-9.39-3.18-21.94l-9.33-39.69c-1.92-4.75-1.26-10.37,.61-16.41,2.53-7.76,2.81-15.41,.61-22.95,2.52-1.2,3.48-5.27,3.04-11.88-11.51,2.49-14.91-10.62-15.56-28.98,8.67,3.35,3.29-7.31-6.41-22.27h-.01Z"
        />
      </g>
      <g id="finger_4_overlay" data-name="finger 4 overlay">
        <path
          id="finger_4_overlay_1"
          data-name="finger 4 overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandLeft.id, HandLeft.children.finger4),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(
            selected,
            HandLeft.children.finger4
          )}`}
          d="M165.97,351.36c-2.28-3.46-4.84-5.83-7.93-6.1-9.39-25.6-16.96-50.98-21.36-75.97l-1.53-18.92c3.76-4.82,3-12.69-1.22-22.88l1.24-3.66c-5.87-6.66-10.02-14.35-10.68-24.1-4.57-11.77-7.91-26.24-10.07-43.32,2.28-6.47,.93-11.63-2.14-16.17,2.72-.14,4.26-1.87,3.05-7.32l-8.8-6.39-6.45-18.93c8.35-10.42,4.12-17.79-8.24-23.19-8.81-1.7-14.26,2.02-12.51,17.39l8.24,4.88,1.22,23.19c-8.06,5.79-11.24,10.52-2.44,12.64-.73,14.84,.61,27.66,8.85,33.73,3.24,14.26,4.86,27.51,1.83,37.83-3,8.84-.25,12.25,5.18,13.12-5.42,10.81-6.74,20.85,3.66,28.68,1.01,35.36,4.71,64.73,13.43,82.98-3.17,8.67-5.13,16.73-3.36,22.88,.49,8.53,3.71,9.1,7.32,8.54,8.36-7.9,19.68-7.29,32.66-1.83,9.01-.29,11.35-6.95,10.05-17.08h0Z"
        />
      </g>
      <g id="finger_5_overlay" data-name="finger 5 overlay">
        <path
          id="finger_5_overlay_1"
          data-name="finger 5 overlay 1"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandLeft.id, HandLeft.children.finger5),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(
            selected,
            HandLeft.children.finger5
          )}`}
          d="M97.93,399.26c2.59-3.23-1.67-9.47-7.42-16.37-14.19-15.96-25.43-34.92-32.24-58.38,1.78-6.78,2.01-12.99,.61-18.61-1.48-3.17-4.17-5.73-7.27-8.07l4.84,.75-16.47-34.17c1.4-8.46,1.4-16.3-4.89-21.35l.92-4.27c-4.62-5.65-8.47-12.83-10.37-23.8,5.52-.89,.68-9.1-9.46-21.05-4.94-.34-8.47,.84-7.93,6.41,5.28,9.65,7.43,19.6,6.1,29.9-3.72,1.34-6.01,4.25-7.02,8.54l7.32,5.49c-1.87,4.84-.18,8.02,4.58,9.76,2.14,7.55,4.33,17.97,6.79,44.87l5.4,4.55c-3.64,8.25-2.1,15.3,4.58,21.05l26.54,61.63c-.43,9.15,.14,17.65,3.36,24.41,2.77,1.73,5.83,1.47,9.07,0,4.02-6.33,13.07-9.11,22.96-11.29h0Z"
        />
      </g>
      <g id="wrist">
        <path
          id="wrist_overlay"
          data-name="wrist overlay"
          onClick={() => {
            bpartSetter({
              id: idToSet(selected, HandLeft.id, HandLeft.children.wrist),
            });
          }}
          class={`lhnd-cls-1 ${isSelected(selected, HandLeft.children.wrist)}`}
          d="M330.71,603.67c4.17-12.73,3.12-22.97-4.87-29.9-8.55-2.79-13.86-8.62-14.04-19.22-4.69-5.12-9.14-4.99-13.19,3.35l-11.52,5.2c-10.57-13.37-20.99-20.7-30.81-3.36-10.94,2.61-14.53-1.78-16.82-5.06l-16.13-9.89c-10.6,5.31-19.91,8.29-22.27-1.22l-10.68,10.37-32-1.23-13.34,14.7c-3.6,8.08-2.44,16.51,11.3,25.88,16.74,12.94,33.46,25.75,47.17,24.11l33.85,14.33c18.37,4.5,63.13,7.48,67.64-2.83,2.76-6.3-3.34-21.71-11.5-23.1-16.94-2.9,24.64,8.45,37.21-2.13h0Z"
        />
      </g>
      <path
        id="middlehand"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, HandLeft.id, HandLeft.children.middleHand),
          });
        }}
        class={`lhnd-cls-4 ${isSelected(
          selected,
          HandLeft.children.middleHand
        )}`}
        d="M74,416.65c4.2-10.78,11.82-16.1,22.5-16.5,4.43,3.86,7.89,8.59,8.62,15.75,4.7,3.55,6.01,9.88,3.75,19.12,11.88,46.88,25.32,87.17,46.88,93,4.7-22.28-4.6-55.86-22.5-96.38-7.37-15.43-12.19-30.03-13.5-43.5-.99-5.15,.3-11.56,3-18.75,12.01-6.63,23.01-7,33-1.12,5.42,8.46,8.52,16.08,9.38,22.88,1.08,45.09,8.39,85.03,24,118.12,6.21,3.49,11.04,8.29,13.88,15l-.75-7.5c6.87-15.25,7.91-33.48,4.31-54.07-14.37-44.75-23.63-81.45-25.68-106.81l12.38-22.5c8.63-3.64,18.47-3.82,30.75,3,6.08,7.7,10.44,15.35,10.5,22.88l4.12,16.12c-3.35,8.68-6.73,12.64-10.12,12.75,2.05,9.73,2.76,18.51,.38,25.12l7.5,88.5c5.69,5.32,10.86,11.21,13.5,19.88,7.2-10.82,13.27-18.72,17.62-22.12v-111.38c-7.42-19.52-6.49-30.3-.38-35.62l6.75-12c14.55-3.17,25.37-.35,33.75,6.38,9.08,22.65,8.92,32.05,0,28.88-7.94,31.63-10.99,62.64-9,93-.52,20.92-1.62,40.55-4.88,55.5,1.46,4.53,3.55,8.68,8.25,11.25,6.04-5.11,12.05-8.39,18-6.38,16.34-14.77,24.35-36.54,28.12-61.88-8.86-11.75,1.13-21.01,18.75-29.25,13.35-.54,22.18,5.41,23.25,22.5-4.66,16.15-12.09,30.87-21.38,44.62-7.88,35.25-12.28,73.06-41.25,69-11.66-.05-15.83-7.5-15.38-19.5-3.59-5.08-7.09-5.97-10.5-3-1.89,5.98-6.9,9.41-13.88,11.25-13.97-19.27-23.95-17.23-31.5-2.25-7.12,.87-11.57-.04-13.5-2.62-5.26-7.65-12-11.69-19.5-13.88-2.78,1.71-7.74,4.11-14.62,7.12-4.16-.61-6.46-3.21-7.88-6.75-3.03,4.91-6.37,8.5-10.5,8.62-7.28-4.81-19.52-3.42-31.88-1.88-4.78,9.83-11.71,15.6-20.62,17.62-8.14,1.61-13.19-3.23-17.62-9.38-2.85-10.43-2.89-19.23-1.12-27-15.18-21.18-26.36-42.68-33.38-64.5-5.22-12.43-11.77-22.86-19.5-31.5-1.09-15.81,1.68-22.08,7.88-19.88Z"
      />
      <path
        id="hand_joint"
        data-name="hand joint"
        onClick={() => {
          bpartSetter({
            id: idToSet(selected, HandLeft.id, HandLeft.children.handJoint),
          });
        }}
        class={`lhnd-cls-4 ${isSelected(
          selected,
          HandLeft.children.handJoint
        )}`}
        d="M147.88,592.9c8.88,.94,29.73,13.95,49.5,25.88,37.47,13.83,72.5,24.06,95.25,16.12-2.9,11.42-8.53,20.03-16.88,25.88l.75,2.62c7.91-4.24,15.62-8.16,19.88-6.38,6.48,8.74,9.02,21.33,7.88,37.5-38.05,3.64-74.31,2.15-108-6.75-16.31,3.71-36.16,2.5-57.75-1.12,.22-9.85,3.55-15.73,10.88-16.5,2.8,2.63,5.05,5.7,5.62,10.12l27-4.88c4.86,1.51,8.78,4.31,10.5,10.12l2.62,.38c-2.1-7.19,.64-13.44,6.38-19.12h12c4.15-1.34,2.02-3.22-1.88-5.25-10.49-4.29-19.19-12.8-27.38-22.5-2.47,6.5-9.59,1.39-19.88-11.62-8.26,8.75-16.38,5.08-24.38-9.38-5.12-13.11-2.51-21.49,7.88-25.12Z"
      />
      <path
        id="hand_joint_wth_lower_arm"
        data-name="hand joint wth lower arm"
        onClick={() => {
          bpartSetter({
            id: idToSet(
              selected,
              HandLeft.id,
              HandLeft.children.handJointWLowerArm
            ),
          });
        }}
        class={`lhnd-cls-3 ${isSelected(
          selected,
          HandLeft.children.handJointWLowerArm
        )}`}
        d="M138.88,687.78c5.83,10.77,8.98,22.88,9.38,36.38l1.12,28.87h33.75l-1.88-14.63c2.45-12.45,7.83-22.99,15.75-31.87,1.04-5.3,.22-10.08-1.5-14.62h2.25c11.42,17.17,18.47,36.91,17.25,61.5l75.38,1.5c-1.98-17.52,1.73-37.37,13.12-60.37-38.21,6.01-75.79,2.73-106.88-6.38l-3,.75c-19.72,4.1-38.59,2.11-54.75-1.12Z"
      />
    </svg>
  );
}

export default FrontView;
