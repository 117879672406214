import style from "./style.scss";

function Brustwirbelsaule45VdOblLiegend1536(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 237.63 579.26">

<g id="Thoracicvertebra45_vdobl" data-name="Thoracicvertebra45 vdobl">
  <g id="Intervertebraljoints">
    <path id="intervertebraljoint11" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M33.19,505.18c.28-11.24,1.9-19.36,5.48-22.93h76.51c3,1.9,5.41,7.75,6.73,20.84l-22.65,25.5-53.53-5.44-12.54-17.97h0Z"/>
    <path id="intervertebraljoint10" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M41.13,441.62c-1.21-5.09-1.58-9.07,0-10.45-.41-4.82,0-8.05,1.25-9.62l4.53-1.9,49.19-1.24,21.35,5.02c.76,5.9-.07,11.97-2.28,18.2l-19.48,8.57-38.83-2.3-15.73-6.28h0Z"/>
    <path id="intervertebraljoint9" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M121.89,388.09l1.82-7.1c.96-3.22-.21-6.66-2.93-10.24l-61.88-8.78c-3.41,1.79-5.27,4.21-4.39,7.73-.7,3.83-.81,7.43,2.35,9.83l65.03,8.57h0Z"/>
    <path id="intervertebraljoint8" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M59.31,334.79c-1.47-3.45-1.87-6.69,0-9.41.22-3.65,2.78-5.85,6.8-7.1l57.93,2.93,3.56,6.69c1.07,4.24,1.49,8.35,1.11,12.33l-25.09-3.76-44.32-1.68h.01Z"/>
    <path id="intervertebraljoint7" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M63.32,291.72c-1.53-2.6-2.31-5.73-2.28-9.41,2.44-5.33,5.63-7.68,9.36-7.94h32.19l19.31,6.48c2.82,7.06,4.34,13.69,3.28,19.45l-61.85-8.57h0Z"/>
    <path id="intervertebraljoint6" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M67.27,253.05c-4.6-2.06-5.22-5.26-3.95-8.99,1.11-3.55,4.78-6.59,9.59-9.41l35.34-.84,13.64,8.15c1.51,5.33,1.64,10.24,0,14.63l-54.64-3.55h.02Z"/>
    <path id="intervertebraljoint5" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M68.93,214.78c-.84-5.85,0-11.71,2.5-17.56h35l17.6,3.55c.32,5.15.07,9.87-.96,14.01h-54.14Z"/>
    <path id="intervertebraljoint4" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M64.75,174.22c-1.22-2.39-1.9-4.97-2.01-7.73.44-3.33,2.68-5.77,5.88-7.73l22.27-2.71,24.29,5.85c2.64,5.93,3.48,11.6,1.72,16.93l-15.13-6.07-37.01,1.46h-.01Z"/>
    <path id="intervertebraljoint3" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M64.75,134.5c-6.65-3.44-8.97-7.9-7.89-13.17l11.75-9.19,37.82,3.14c2.93,8.56,5.06,16.47,4.93,22.59l-7.53-1.05-27.6-8.78-11.49,6.48h0v-.02h0Z"/>
    <path id="intervertebraljoint2" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M61.57,108.71c-2.34-5.79-4.67-12.94-6.99-21.46-1.65-4.49-.81-9.18,2.02-14.01l16.3-9.62,29.48,6.48,4.06,7.53c.73,5.67.01,11.31-2.17,16.93l-6.69-1.05-36,15.18h0v.02h-.01Z"/>
    <path id="intervertebraljoint1" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M57.88,63.63c-4.83-9.17-8.51-17.82-8.18-24.66.85-5.27,3.28-9.43,6.91-12.76l22.57-5.44,13.74,5.64,4.87,5.85c2.75,3.41,4.07,7.8,3.07,13.8l-5.37,5.85-37.6,11.71h0Z"/>
  </g>
  <g id="Thoracicspine">
    <path id="thoracicspine12" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M37.15,562.46c-7.6-9.1-5.15-18.81,2.5-28.85l-1.25-14.22c-9.63-5.71-7.32-12.07-5.85-18.4,2.77,7.61,8.62,13.58,20.49,16.31,16.89,3.77,32.92,4.21,47.66-.41,13.63-5.33,20.85-12.68,22.59-21.75,4.71,1.13,5.37,6.77,2.93,15.88-2.06,7.23-2.79,14.33-2.09,21.32,5.73,12.11,4.73,23.32-10.03,32.62-6.81,8.02-18.65,12.44-34.71,13.8l-19.65-3.76c-11.29.96-18.68-3.39-22.59-12.54h0Z"/>
    <path id="thoracicspine11" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M39.52,483.76c-2.09-3.08-2.3-6.12.72-9.08,2.66-2.6,4.49-5.99,5.01-9.68.72-5.04-.04-10.32-2.24-15.86-6.18-7.25-6.15-9.8-2.95-9.94,1.58-.07,3.11.56,4.36,1.54,5.6,4.36,13.53,5.57,26.12,5.37,5.26-.08,10.5.19,15.75.63,11.26.95,21.43-1.28,30.31-7.21,6.45-.29,11.11,1.42,11,8.37v3.92c0,3.48-1.35,6.9-3.94,9.24-2.33,2.09-4.01,4.72-5.22,7.75-.96,2.41-.95,5.09-.06,7.54,1.65,4.53.63,8.29-3.06,11.29-8.09,4.72-16.5,7.45-25.5,6.32-3.23-.4-6.5-.59-9.76-.36-11.07.78-22.26-.54-33.61-4.62-2.78-1-5.3-2.74-6.95-5.19v-.03h.02Z"/>
    <path id="thoracicspine10" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M49.32,420.15c-6.29-.73-5.75-3.66-1.83-7.69,1.1-1.13,2.16-2.33,3.07-3.62,4.47-6.36,6-13.65,5.95-21.44,0-1.9-.5-3.74-1.16-5.53-.73-1.95-.36-3.37.76-4.4,1.22-1.13,3.01-1.4,4.62-.99,5.53,1.39,11.18,2.02,16.97,1.6,11.8-.69,22.85.69,32.95,4.78,2.57,1.05,5.27,1.79,8.05,2,3.55.26,6.52,1.06,8.89,2.44l28.45,8.29c.18.06.37.1.55.14,6.99,1.46,11.55,5.2,13.46,11.45.96,3.18.78,6.69-.89,9.57-1.78,3.06-4.18,3.56-7.1,2.05-1.32-.69-2.68-1.29-4.14-1.64-11.62-2.68-18.53-.07-20.85,7.76-1.91,2.28-3.44,1.67-4.69-.95-.83-1.72-1.1-3.66-.96-5.57.51-7.61-.77-10.45-3.83-8.53-2.24,1.71-3.11,6.21-3.9,10.87-.28,4.27-3.56,5.44-9.19,4.18-9.79-2.05-18.91-2.39-27.35-.94-3.96.69-8.02.81-12.03.51-7.61-.56-14.66-1.57-20.8-3.26-1.65-.45-3.32-.85-5.01-1.06h0v-.02h.01Z"/>
    <path id="thoracicspine9" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M59.22,365.05c-2.78.25-2.88-1.43-1.42-4.24,2.82-3.18,4.05-7.82,3.48-14.07-.28-3.03-1.24-5.99-2.95-8.49-2.59-3.77-1.67-5.74,1.98-6.3l10.5.63c12.46-1.75,26.4-.67,41.55,2.67,6.47,2.74,12.5,4.49,16.93,2.67l10.91,4.5c5.51,2.27,11.27,3.88,17.19,4.57,4.58.54,8.52,1.4,11.37,2.82,3.44,1.69,5.38,5.63,4.51,9.36-.52,2.26-1.84,3.95-4.03,5.01-1.35.65-2.86.83-4.35.74-6.06-.34-11.33.63-15.54,3.39-1.8,1.17-2.89,3.25-2.78,5.4.04.85.14,1.72.29,2.59-1.43,2.05-2.79,2.27-4.07,0-1.09-9.55-2.66-17.99-7.53-18.76-4.49-.5-6.01,2.67-5.96,7.85-1.16,5.63-3.61,8.7-7.53,8.78l-19.13-3.76-27.6-2.97c-5.3.78-10.57-.1-15.84-2.35v-.03h.02Z"/>
    <path id="thoracicspine8" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M65.64,320.36c-2.12.47-2.77-.04-2.19-1.35.8-1.83,1.39-3.76,1.42-5.77.06-5.09-.5-10.2-1.57-15.3-3.1-5.34-3.28-8.89,1.17-9.57,7.14-2.86,16.21-2.77,26.11-1.42,13.79.29,25.35,2.84,33.71,8.63,3.25.32,5.96,2.34,7.53,8.08l15.58,1.29c2.42.21,4.84.18,7.28.1,5.15-.15,10.24,1,15.25,3.85,3.32,2.97,3.91,5.77.74,8.31l-18.68,2.71c-1.69.25-2.99,1.65-3.08,3.36l-.15,2.7c-.56,1.61-1.61,1.72-2.93.96-.81-.48-1.4-1.25-1.76-2.13-2-4.91-4.45-7.45-7.23-8.23-1.64-.8-3.21-.87-4.71,0-1.39,1.42-1.11,3.39,0,5.63,2.38,5.18,1.35,7.93-3.45,8.01-10.2-6.11-23.44-8.49-38.26-8.93l-24.77-.94h-.01Z"/>
    <path id="thoracicspine7" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M69.4,277.09c-3.23.26-4.69-.74-2.67-4.24,1.27-6.06.28-10.78-2.5-14.42-1.95-4.69-.74-8.05,3.29-10.2,6.55-2.45,17.55-2.46,29.63-1.89,8.6.61,16.3,2.3,21.86,6.58,1.31,1,2.92,1.61,4.57,1.56,1.57-.06,2.85-.37,3.67-1.07.58-.5,1.39-.59,2.13-.41l24.54,6.21c4.71,1.28,9.3,2,13.74,2,2.57,0,5.11,1.03,6.72,3.06,1.49,1.87,1.67,3.69.72,5.46-1.24,2.3-3.14,4.21-6.26,5.4-2,.74-4.14,1-6.28.92-8.67-.36-14.38,1.98-14.49,9.47-.03,1.57-1.06,3.07-2.6,3.33h-.06c-1.45.22-2.77-.85-3.11-2.27-1.27-5.09-3-9.41-5.77-12.14-4.27-2.97-6.14-1.61-6.74,2.04.52,4.94.25,8.77-1.32,10.79-1.13,1.46-3.32,1.58-4.75.43-5.05-4.02-11.59-6.51-19.01-8.08-13.06-2.48-25.2-3.78-35.28-2.5h-.03v-.03Z"/>
    <path id="thoracicspine6" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M73.96,237.11c-4.53,1.98-5.4-.18-3.61-5.33,1.2-4.1-.17-7.96-2.04-11.75-2.84-4.34-3.55-7.86,1.72-9.1,5.33-2.16,12-2.82,19.28-2.82l10.97-.78c7.38-.26,13.6,1.06,18.02,4.86,1.35,1.17,3.33,1.07,4.61-.17,1.47-1.43,2.81-2.04,4.03-2.04l20.36,1.17c2.84.17,5.67.43,8.48.78l9.47,1.21c1.28.17,2.57.19,3.87.19,3.92-.03,7.2,1.31,8.48,6.83.62,6.26-2.34,10.14-9.41,11.29-9.74-.76-16.75,1.56-18.97,9.25-2.67,5.86-4.61,4.13-6.74,0-2.82-5-5.75-9.15-9.25-9.72-1.95-.54-2.78.33-3.17,1.75-.25.91-.15,1.86.17,2.75,1.82,5.19,1.75,9.03-1.54,10.56l-5.73,2.35c-.94-2.46-3.19-4.57-6.83-6.28-13.46-6.08-27.58-7.27-42.18-5.02h.01v.02h0Z"/>
    <path id="thoracicspine5" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M74.81,200.26c-.98.08-1.9.37-2.82.72-2.85,1.06-3.55-1.38-3.37-5.27-.06-7.65-1.43-12.95-4.38-15.47-.96-.83-1.72-1.91-2-3.15-.48-2.24.69-3.83,2.77-5.04,5.19-3.1,10.8-3.99,16.93-2.35,12.57-1.6,23.41-1.25,29.48,4.56,1.4,1.34,3.36,1.94,5.27,1.61l2.74-.47c3.32-.56,6.3-.26,9.06.8l6.66,2.59c2.6,1,5.53,1.05,8.08-.08,2.09-.92,4.01-2.06,5.82-3.34,1.32-.92,2.75-1.68,4.25-2.22,8.53-3.07,14.73-3.63,16.27.74.4,1.14.78,2.28,1.32,3.36,2.27,4.51,3.03,8.08-1,8.6-2.59,1.24-5.73,2.74-9.35,4.47-1.06.51-2.23.78-3.41.72-5.29-.32-10.82.78-16.56,2.89-1.36.5-2.86.25-4.09-.55-4.29-2.82-8.07-2.48-11.3.94-.81,1.46-1.24,2.74-.84,3.63.5,1.11,1.03,2.23,1.14,3.45.33,3.37-.89,4.29-3.63,2.79-3.87-2.46-9.48-3.22-15.46-3.61-11.42-.84-22.16-1.1-31.63-.3h.04v-.02h0Z"/>
    <path id="thoracicspine4" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M69.25,161.05c-5.55,2.77-6.44-.62-3.12-9.57-4.51-5.44-5.27-11.18-3.98-17.09.73-3.39,2.89-6.1,7.07-7.83,2.6-1.09,5.46-1.42,8.27-1.22,10.21.73,19.02,2.82,25.54,7.17,1.35.89,2.97,1.31,4.58,1.13l3.37-.37c1.5-.17,3,.18,4.32.92,4.6,2.59,10.24,3.37,16.32,3.36,6.22.37,11.74-1.54,16.93-4.56,2.17-1.25,4.97-1,6.73.77,1.38,1.39,2.52,3.47,3.45,6.12,1.05,3.7.59,6.96-1.35,9.8-1.31,1.93-3.76,2.7-6.01,2.16-2.56-.61-5.23-.52-8,.26-3.66,1.43-4.12,4.56-2.19,8.93-.21,3.14-1.68,3.14-4.39,0-2.79-3.54-5.97-6-9.88-6.43-6.15-.1-9.11,1.64-9.48,4.86,3.26,5.42,2.17,7.78,0,9.37-3.06-4.84-6.55-6.43-10.27-6.39-15.33-3.45-27.76-3.67-37.95-1.42h.01l.03.03h0Z"/>
    <path id="thoracicspine3" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M61.28,121.7c-2.68,2.26-4.78,2.93-6.07,1.51-.7-.76-.77-1.9-.39-2.85.73-1.83,1.83-3.56,3.33-5.16,3.33-3.55,4.54-8.59,3.34-13.31-.78-3.08-1.6-6.32-2.45-9.68-1.18-6.48,1.72-9.83,7.07-11.29,4.84-.56,10.03-.32,15.51.62,5.77.99,11.29,3.32,15.76,7.09.88.74,1.68,1.5,2.34,2.26,1.06,1.2,2.86,1.42,4.2.55.83-.54,1.79-.85,2.89-.94,1.9-.14,3.47,1.43,3.33,3.32-.39,5.23.06,10.17,1.9,14.62.85,2.05,1.47,4.21,1.67,6.43.88,10.43-1,13.75-5.46,10.39-5.42-4.76-11.7-8.29-19.57-9.54-5.78-2.01-13.36-.41-18.65,1.24-3.19,1-6.21,2.56-8.77,4.72h0v.02h.02Z"/>
    <path id="thoracicspine2" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M57.8,75.79c-2.68,2.26-4.78,2.93-6.07,1.51-.7-.76-.77-1.9-.39-2.85.73-1.83,1.83-3.56,3.33-5.16,3.33-3.55,4.54-8.59,3.34-13.31-.78-3.08-1.6-6.32-2.45-9.68-1.18-6.48,1.72-9.83,7.07-11.29,4.84-.56,10.03-.32,15.51.62,5.77.99,11.29,3.32,15.76,7.09.88.74,1.68,1.5,2.34,2.26,1.06,1.2,2.86,1.42,4.2.55.83-.54,1.79-.85,2.89-.94,1.9-.14,3.47,1.43,3.33,3.32-.39,5.23.06,10.17,1.9,14.62.85,2.05,1.47,4.21,1.67,6.43.88,10.43-1,13.75-5.46,10.39-5.42-4.76-11.7-8.29-19.57-9.54-5.78-2.01-13.36-.41-18.65,1.24-3.19,1-6.21,2.56-8.77,4.72h0v.02h.02Z"/>
    <path id="thoracicspine1" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M53.65,31.11c-2.41,1.68-4.28,2.19-5.45,1.13-.62-.56-.69-1.42-.34-2.12.66-1.36,1.64-2.66,2.99-3.85,2.99-2.64,4.06-6.4,3-9.92-.7-2.3-1.43-4.71-2.19-7.21-1.06-4.83,1.54-7.32,6.33-8.42,4.35-.41,8.99-.23,13.9.47,5.17.74,10.12,2.46,14.12,5.29.8.56,1.5,1.11,2.11,1.68.95.89,2.57,1.06,3.77.41.74-.4,1.6-.63,2.6-.7,1.71-.1,3.11,1.06,2.99,2.48-.34,3.9.06,7.58,1.69,10.9.76,1.53,1.32,3.14,1.49,4.79.78,7.78-.89,10.25-4.9,7.75-4.86-3.55-10.47-6.18-17.53-7.12-5.17-1.5-11.97-.3-16.71.92-2.86.74-5.56,1.91-7.86,3.52,0,0,0,0-.01,0Z"/>
  </g>
  <g id="Ribs" class="Brustwirbelsaule45_vd_obl_liegend1536_cls4">
    <path id="ribs25" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M135.41,477.35c-1.8,5.41-.25,8.9,5.44,10.06l26.55-3.55c19.48.4,38.4,6.61,56.65,19.86,4.05,1.22,7.09.58,8.15-3.76.3-3.17-.26-6.22-1.42-9.22-2.53-6.54-7.79-11.64-14.22-14.44l-4.89-2.13c-11.81-5.15-24.36-8.42-37.2-9.41-6.55-.51-12.37-.61-17.2-.17-7.09,5.11-14.33,9.62-21.87,12.77h0Z"/>
    <path id="ribs24" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M237.21,446.59c-3.85-1.79-17.04-4.52-21.08-5.61l-63.35-15.26c-4.46-.89-8.42-.17-12.04,1.73-2.01,1.06-4.6.55-5.88-1.32-1.9-2.78-1.54-6.14-.69-9.61h0c1.4-5.66,5.77-7.43,11.08-7.94l31.57,5.23,60.45,7.06"/>
    <path id="ribs23" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M232.27,378.44l-27.87-6.28-22.13,12.87h-.03l25.15,10.93,24.89,9.15"/>
    <path id="ribs22" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M237.21,441.09c-11.32,17.87-29.15,39.78-46.81,61.58l-30.32,37.01c-.98,4.13-.12,8.37,2.93,12.76,20.37-7.72,39.29-25.42,56.87-52.69l14.55-23.08"/>
    <path id="ribs21" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M222,306.77c-12.02-4.05-25.51-7.36-40.61-9.85l29.32-13.49h0l11.83,3.9"/>
    <path id="ribs20" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M232.28,397.54c-10.09,10.59-20.16,20.44-30.21,29.44-21.58,20.64-42.58,37.67-62.65,48.9-1.76.98-3.72,1.57-5.74,1.68-5.24.3-8.67-1.2-10.6-4.17l-2.09-11.92c-1.25-4.94.29-8.11,3.76-10.03,37.16-17.6,72.99-43.52,107.27-79.29"/>
    <path id="ribs19" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M217.02,79.25c-12.08-4.02-27.2-10.33-43.96-11.32-18.33-2.41-35.74-4.05-50-3-1.38.1-2.74.19-4.12.18-11.16-.19-22.39-3.81-33.68-10.03-5.33-2.56-10.07-7.24-14.42-13.49h-.03c-3.56-6.5-2.11-11.18,3.34-14.44,2.38-1.43,5.08-2.23,7.83-2.57l7.02-.88c5-.62,10.06-.65,15.07-.06l7.49.88c4.2.5,8.35,1.31,12.41,2.45l52.22,14.6c14.18,4.54,28.48,8.19,42.9,10.97"/>
    <path id="ribs18" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M237.21,464.31c-24.4,23.41-56.02,43.46-87.13,58.18-2.67,1.27-5.93.41-7.49-2.09-.78-1.27-2.16-4.98-2.16-4.98l-1.07-6.98c-.14-.88-.11-1.8.11-2.67.72-2.73,2.16-4.05,3.96-4.69,3.96-1.45,7.68-3.51,11.35-5.59,25.89-14.66,49.84-30.31,70.37-47.73,2.49-2.11,9.8-6.22,12.05-8.55"/>
    <path id="ribs17" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M228.79,346.29c-17-7.02-32.77-9.89-53.68-9.66-5.11.06-10.14-1.07-14.89-2.95s-10.01-2.92-15.54-3.55c-2.11-.25-3.73-1.93-3.9-4.03l-.34-4.56h0c1.68-3.17,5.35-5.35,10.03-7.03l53.73,1.4c6.11.16,12.17.83,18.13,2"/>
    <path id="ribs16" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M222,263.34c-10.02-3.18-21.63-6.53-34.96-10.09-17.64-2.44-34.06-4.24-47.58-4.56-2.56-.06-4.67-2.01-4.91-4.57h.03l-.76-7.9,17.16-7.32c14.36.77,27.95,2.12,38.69,5.67,3,.99,6.03,1.93,9.08,2.71,7.86,2,15.61,4.07,23.25,6.2"/>
    <path id="ribs15" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M228.79,353.46c-29.78,21.95-61.42,40.54-99.04,57.65-5.9.99-10.02-1.45-11.71-8.57l-1.68-15.47c-.85-5.9,3.66-9.58,9.41-12.76,34.79-18.18,72.71-40.48,101.45-58.79"/>
    <path id="ribs14" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M79.17,104.61c-1.97-3-1.53-5.93,1.89-8.78l36.17-17.15c11.67-4.4,25.35-6.72,41.37-6.99,5.86-.1,11.73.73,17.3,2.53,11.07,3.56,22.17,7.89,33.31,13.03l-39.1-3.14c-10.93-1.2-22.61-1-35.3,1.02-6.15.98-12.03,3.22-17.36,6.43l-24.69,14.92c-6.72,3.77-10.72,2.09-13.58-1.89h0v.02h0Z"/>
    <path id="ribs13" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M217.1,119.61l-5.37-.56c-32.33-5.24-62.83-5.17-89.68,5.44-19.08,9.33-38.41,18.86-47.46,21.32-3.37-.8-5-3.78-5.71-7.94h-.01c-1.6-4.87.56-8.04,4.87-10.25l46.2-21.32c26.23-10.75,52.51-14.49,78.82-10.87l18.26,2.4"/>
    <path id="ribs12" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M217.1,151.43c-22.15-.35-40.87,1.12-53.05,6.04l-59.17,23.62c-3.78,1.4-7.38,2.49-10.42,2.68s-5.97-1.61-7.28-4.42l-1.73-3.7v.03c-2.38-4.76-1.62-9,2.09-12.76l32.19-17.15c28.76-15,61.49-20.15,97.29-17.67"/>
    <path id="ribs11" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M219.58,174.21c-20.18-4.49-55.44-4.42-88.36-3.61l30.02-11.99,31.88,1.38,26,2.1"/>
    <path id="ribs10" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M222,209.83c-13.98,1.46-17.32,2.15-30.56,4.84-9.25,1.89-18.26,4.71-26.93,8.41l-61.27,26.16c-3.43.7-6.51.15-9.4-1.21-1.79-.85-4.14-4.06-4.14-4.06,0,0-1.62-3.18-2.39-4.69-1.46-2.86-1.65-6.26-.32-9.18v.03c.89-1.94,2.24-3.72,4.12-5.31,25.12-11.38,49.26-21.64,72.19-30.49,9.21-3.55,18.76-6.19,28.5-7.73,8.55-1.35,20.84-2.38,29.17-2.81"/>
    <path id="ribs9" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M222,222.79l-35-7.1,35-5.86"/>
    <path id="ribs8" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M222,279.29c-33.24,15.42-65.33,29.41-96,41.65-3.53,1.4-7.59.28-9.86-2.73l-4.73-6.25c-4.74-6.26-3.85-15.18,2.29-20.15h-.01c1.56-1.27,3.38-2.41,5.44-3.44,33.26-13.52,67.36-27.39,102.19-41.56"/>
    <path id="ribs7" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M217.15,210.64l4.84-.81"/>
    <path id="ribs6" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M6.77,272.46c17.59,8.39,53.39,17.31,84.45,26.3,5.66,2,11.34,2.92,17.05,2.19-.43,5.68,2.62,11.46,7.71,17.29,1.45,5.44-2.24,8.23-12.22,7.8l-62.4-14.42c-12.1-2-15.53,3.95-15.06,13.49,0,8.49,5.34,16.64,15.36,24.46,13.36,14.84,40.35,23.47,71.34,30.28,1.93.43,3.32,2.11,3.36,4.09l.06,3.17,1.68,15.47c2.35,7.56,6.4,9.99,11.71,8.57-3.95.54-7.93.84-11.91.91-20.22.39-40.04-5.77-56.94-16.87l-24.03-15.77c-1.21-.8-2.64-1.25-4.09-1.13-1.67.14-2.15.92-1.49,2.28,19.74,45.2,55.01,59.24,91.72,70.4.51.15.56.84.08,1.07-2.56,1.27-3.34,4.14-2.11,9.48l1.54,8.82c.44,2.55,2.02,4.82,4.35,5.93,2.02.98,4.31,1.39,6.79,1.34l-6.91,2.92c-4.65,1.97-9.81,2.44-14.73,1.28-11.23-2.66-21.28-8.59-30.25-17.47-1.79-.89-2.95-.51-3.47,1.16-.44,1.42-.07,2.97.84,4.14,5.66,7.31,12.32,14.31,20.36,20.93,6.1,5.01,13.28,8.55,21,10.2s15.75,2.04,22.9.36c-3.44,1.11-3.87,3.78-4.07,7.36.25,4.64,1.2,8.73,3.23,11.95,1.78,2.62,4.4,2.95,7.49,2.09-7.85,2.57-15.5,3.18-22.89,1.05-1.31-.37-2.68-.61-4.03-.41-2.16.32-3.25,1.38-3.25,3.21,0,1.67.96,3.18,2.31,4.16,11.66,8.49,24.5,10.24,37.85,9.18-1.47,4.64-.59,8.9,2.93,12.76v-.03c-7.97,5.01-16.93,7.47-27.09,6.83-10.87-.69-21.11-5.2-29.52-12.14-28.88-23.84-51.42-59.46-72.3-98.17-13.05-19.46-26.1-40.35-33.63-71.02"/>
    <path id="ribs5" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M12.99,223.84c8.67,2.45,21.43,4.59,30.74,6.51,2.37.5,4.79.76,7.21.83,15.39.47,29.15-1.17,40.15-6.33-4.82,4.82-6.54,9.63-3.8,14.49,2.31,3.23,4.58,6.36,6.54,8.75.06,3.22-1.78,5.53-5.29,7.06-2.86,1.24-6.04,1.56-9.14,1.2l-45.27-5.26c-8.3-.36-18.09.26-23.92,2.01"/>
    <path id="ribs4" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M14.61,176.9c15.84-1.69,36.93-8.24,55.11-17.12,10.5-4.1,20.37-5.6,29.26-2.96l-11.44,6.1c-3.99,4.4-4.18,8.63-2.09,12.76,2.3,6.15,5.4,8.44,9.03,8.12-1.51,6.47-6.66,11.18-16.47,13.61-15.21,1.07-30.11,2.12-44.66,3.14-7.04-.24-13.86-.17-20.35.34"/>
    <path id="ribs3" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M16.49,134.49c3.62,2.71,11.36,3.81,15.74,2.74,8.59-2.11,16.3-5.6,23.22-10.36,6.79-3.19,12.19-2.9,15.51,2.56-2.16,2.15-2.79,4.65-2.09,7.47.5,5.08,2.37,8.09,5.71,8.89.52,4.58-.98,8.05-5.71,9.73l-26.74,5.68c-9.01,1.26-21.38,1.94-27.2,1.4"/>
    <path id="ribs2" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M28.8,109.32c.62-7.2,3.83-11.12,10.67-10.5,7.76,4.73,16.46,4.35,26.03-.94,4.49-3.17,8.77-5.02,12.52-3.8l2.28,2.45c-2.38,2.57-3.17,5.24-1.11,8.08,2.3,2.52,4.6,3.92,6.9,3.99.84,4.34-1.97,7.39-10.09,8.55-10.05.19-21.03,2.45-32.65,6.11-7.78,3.48-12.03,1.87-13.3-4.07-.39-3.08-.81-6.41-1.25-9.88h0Z"/>
    <path id="ribs1" class="Brustwirbelsaule45_vd_obl_liegend1536_cls5" d="M18.76,49.42c6.62-4.76,13.1-7.07,19.45-6.58,9.9,3.39,20.8,2.86,32.62-1.25,5.53,8.12,12.51,13.78,21.03,16.82-11.27,5.4-18,12.83-17.16,23.63l-35.22,16.78c-10.31.18-11.96,7.71-9.63,18.61-1.22,6.3-7.22,12.17-13.36,17.07"/>
  </g>
</g>
<g id="Highlights">
  <g id="Body" class="Brustwirbelsaule45_vd_obl_liegend1536_cls1">
    <path id="Body12" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M36.84,562.73c-7.6-9.1-5.15-18.81,2.5-28.85l-1.25-14.22c-9.63-5.71-7.32-12.07-5.85-18.4,2.77,7.61,8.62,13.58,20.49,16.31,16.89,3.77,32.92,4.21,47.66-.41,13.63-5.33,20.85-12.68,22.59-21.75,4.71,1.13,5.37,6.77,2.93,15.88-2.06,7.23-2.79,14.33-2.09,21.32,5.73,12.11,4.73,23.32-10.03,32.62-6.81,8.02-18.65,12.44-34.71,13.8l-19.65-3.76c-11.29.96-18.68-3.39-22.59-12.54h0Z"/>
    <path id="Body11" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M39.21,484.03c-2.09-3.08-2.3-6.12.72-9.08,2.66-2.6,4.49-5.99,5.01-9.68.72-5.04-.04-10.32-2.24-15.86-6.18-7.25-6.15-9.8-2.95-9.94,1.58-.07,3.11.56,4.36,1.54,5.6,4.36,13.53,5.57,26.12,5.37,5.26-.08,10.5.19,15.75.63,11.26.95,21.43-1.28,30.31-7.21,6.45-.29,11.11,1.42,11,8.37v3.92c0,3.48-1.35,6.9-3.94,9.24-2.33,2.09-4.01,4.72-5.22,7.75-.96,2.41-.95,5.09-.06,7.54,1.65,4.53.63,8.29-3.06,11.29-8.09,4.72-16.5,7.45-25.5,6.32-3.23-.4-6.5-.59-9.76-.36-11.07.78-22.26-.54-33.61-4.62-2.78-1-5.3-2.74-6.95-5.19v-.03h.02Z"/>
    <path id="Body10" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M124.06,386.2c-1.69-.54-3.57-.91-5.67-1.07-2.78-.21-5.48-.95-8.05-2-10.1-4.09-21.15-5.47-32.95-4.78-5.79.42-11.44-.21-16.97-1.6-1.61-.41-3.4-.14-4.62.99-1.12,1.03-1.49,2.45-.76,4.4.66,1.79,1.16,3.63,1.16,5.53.05,7.79-1.48,15.08-5.95,21.44-.91,1.29-1.97,2.49-3.07,3.62-3.92,4.03-4.46,6.96,1.83,7.69h-.01v.02c1.69.21,3.36.61,5.01,1.06,6.14,1.69,13.19,2.7,20.8,3.26,4.01.3,8.07.18,12.03-.51,8.44-1.45,17.56-1.11,27.35.94,5.63,1.26,8.91.09,9.19-4.18.62-3.68,1.3-7.26,2.66-9.45-.91-8.43-1.55-16.89-1.98-25.37Z"/>
    <path id="Body9" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M129.39,338.36l-.41-.17c-4.43,1.82-10.46.07-16.93-2.67-15.15-3.34-29.09-4.42-41.55-2.67l-10.5-.63c-3.65.56-4.57,2.53-1.98,6.3,1.71,2.5,2.67,5.46,2.95,8.49.57,6.25-.66,10.89-3.48,14.07-1.46,2.81-1.36,4.49,1.42,4.24h0s-.02.01-.02.01v.03c5.27,2.25,10.54,3.13,15.84,2.35l27.6,2.97,19.13,3.76c3.92-.08,6.37-3.15,7.53-8.78-.03-2.82.42-5.03,1.54-6.39-.68-6.95-1.06-13.91-1.15-20.91Z"/>
    <path id="Body8" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M134.8,316.29c-.87-4.1-1.89-8.17-2.64-12.3l-.65-.05c-1.57-5.74-4.28-7.76-7.53-8.08-8.36-5.79-19.92-8.34-33.71-8.63-9.9-1.35-18.97-1.44-26.11,1.42-4.45.68-4.27,4.23-1.17,9.57,1.07,5.1,1.63,10.21,1.57,15.3-.03,2.01-.62,3.94-1.42,5.77-.58,1.31.07,1.82,2.19,1.35h0s.01-.01.01-.01l24.77.94c14.82.44,28.06,2.82,38.26,8.93,4.8-.08,5.83-2.83,3.45-8.01-1.11-2.24-1.39-4.21,0-5.63.96-.56,1.96-.73,2.98-.56Z"/>
    <path id="Body7" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M131.18,273.4c-1.39-5.59-2.71-11.2-3.58-16.91-.16-1.06-.18-2.13-.05-3.15-.22.08-.43.18-.61.34-.82.7-2.1,1.01-3.67,1.07-1.65.05-3.26-.56-4.57-1.56-5.56-4.28-13.26-5.97-21.86-6.58-12.08-.57-23.08-.56-29.63,1.89-4.03,2.15-5.24,5.51-3.29,10.2,2.78,3.64,3.77,8.36,2.5,14.42-2.02,3.5-.56,4.5,2.67,4.24v.03h.03c10.08-1.28,22.22.02,35.28,2.5,7.42,1.57,13.96,4.06,19.01,8.08,1.43,1.15,3.62,1.03,4.75-.43,1.57-2.02,1.84-5.85,1.32-10.79.27-1.65.81-2.82,1.7-3.35Z"/>
    <path id="Body6" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M132.86,231.24c-1.78-5.67-3.16-11.5-5.9-16.78-.42-.63-.84-1.25-1.29-1.86-.43-.58-.72-1.22-.91-1.89-.69.33-1.41.84-2.16,1.58-1.28,1.24-3.26,1.34-4.61.17-4.42-3.8-10.64-5.12-18.02-4.86l-10.97.78c-7.28,0-13.95.66-19.28,2.82-5.27,1.24-4.56,4.76-1.72,9.1,1.87,3.79,3.24,7.65,2.04,11.75-1.79,5.15-.92,7.31,3.61,5.33h0v-.02h-.01c14.6-2.25,28.72-1.06,42.18,5.02,3.64,1.71,5.89,3.82,6.83,6.28l5.73-2.35c3.29-1.53,3.36-5.37,1.54-10.56-.32-.89-.42-1.84-.17-2.75.39-1.4,1.21-2.27,3.11-1.76Z"/>
    <path id="Body5" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M124.03,198.22c-.4-.89.03-2.17.84-3.63.52-.55,1.06-1.01,1.6-1.4-1.97-5.68-3.55-11.46-5.03-17.36-.03-.14-.05-.27-.08-.4-.73.04-1.48.12-2.24.25l-2.74.47c-1.91.33-3.87-.27-5.27-1.61-6.07-5.81-16.91-6.16-29.48-4.56-6.13-1.64-11.74-.75-16.93,2.35-2.08,1.21-3.25,2.8-2.77,5.04.28,1.24,1.04,2.32,2,3.15,2.95,2.52,4.32,7.82,4.38,15.47-.18,3.89.52,6.33,3.37,5.27.92-.35,1.83-.64,2.81-.72v.02c9.46-.8,20.19-.54,31.59.3,5.98.39,11.59,1.15,15.46,3.61,2.74,1.5,3.96.58,3.63-2.79-.11-1.22-.64-2.34-1.14-3.45Z"/>
    <path id="Body4" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M119.53,156.16c-2.33-6.58-4.62-13.17-6.29-19.97-.22-.88-.29-1.71-.25-2.49-.76-.18-1.54-.24-2.33-.15l-3.37.37c-1.61.18-3.23-.24-4.58-1.13-6.52-4.35-15.33-6.44-25.54-7.17-2.81-.2-5.67.13-8.27,1.22-4.18,1.73-6.34,4.44-7.07,7.83-1.29,5.91-.53,11.65,3.98,17.09-3.32,8.95-2.43,12.34,3.12,9.57l-.03-.03h-.01c10.19-2.25,22.62-2.03,37.95,1.42,3.72-.04,7.21,1.55,10.27,6.39,2.17-1.59,3.26-3.95,0-9.37.18-1.55.96-2.75,2.41-3.58Z"/>
    <path id="Body3" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M60.97,121.97c-2.68,2.26-4.78,2.93-6.07,1.51-.7-.76-.77-1.9-.39-2.85.73-1.83,1.83-3.56,3.33-5.16,3.33-3.55,4.54-8.59,3.34-13.31-.78-3.08-1.6-6.32-2.45-9.68-1.18-6.48,1.72-9.83,7.07-11.29,4.84-.56,10.03-.32,15.51.62,5.77.99,11.29,3.32,15.76,7.09.88.74,1.68,1.5,2.34,2.26,1.06,1.2,2.86,1.42,4.2.55.83-.54,1.79-.85,2.89-.94,1.9-.14,3.47,1.43,3.33,3.32-.39,5.23.06,10.17,1.9,14.62.85,2.05,1.47,4.21,1.67,6.43.88,10.43-1,13.75-5.46,10.39-5.42-4.76-11.7-8.29-19.57-9.54-5.78-2.01-13.36-.41-18.65,1.24-3.19,1-6.21,2.56-8.77,4.72h0v.02h.02Z"/>
    <path id="Body2" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M57.49,76.06c-2.68,2.26-4.78,2.93-6.07,1.51-.7-.76-.77-1.9-.39-2.85.73-1.83,1.83-3.56,3.33-5.16,3.33-3.55,4.54-8.59,3.34-13.31-.78-3.08-1.6-6.32-2.45-9.68-1.18-6.48,1.72-9.83,7.07-11.29,4.84-.56,10.03-.32,15.51.62,5.77.99,11.29,3.32,15.76,7.09.88.74,1.68,1.5,2.34,2.26,1.06,1.2,2.86,1.42,4.2.55.83-.54,1.79-.85,2.89-.94,1.9-.14,3.47,1.43,3.33,3.32-.39,5.23.06,10.17,1.9,14.62.85,2.05,1.47,4.21,1.67,6.43.88,10.43-1,13.75-5.46,10.39-5.42-4.76-11.7-8.29-19.57-9.54-5.78-2.01-13.36-.41-18.65,1.24-3.19,1-6.21,2.56-8.77,4.72h0v.02h.02Z"/>
    <path id="Body1" class="WK1536Fill" style= {{
            fill: props.colors.WK1536Fill,
            stroke: props.colors.WK1536Fill,
            opacity:props.colors.WK1536Fill,
          }} d="M53.34,31.38c-2.41,1.68-4.28,2.19-5.45,1.13-.62-.56-.69-1.42-.34-2.12.66-1.36,1.64-2.66,2.99-3.85,2.99-2.64,4.06-6.4,3-9.92-.7-2.3-1.43-4.71-2.19-7.21-1.06-4.83,1.54-7.32,6.33-8.42,4.35-.41,8.99-.23,13.9.47,5.17.74,10.12,2.46,14.12,5.29.8.56,1.5,1.11,2.11,1.68.95.89,2.57,1.06,3.77.41.74-.4,1.6-.63,2.6-.7,1.71-.1,3.11,1.06,2.99,2.48-.34,3.9.06,7.58,1.69,10.9.76,1.53,1.32,3.14,1.49,4.79.78,7.78-.89,10.25-4.9,7.75-4.86-3.55-10.47-6.18-17.53-7.12-5.17-1.5-11.97-.3-16.71.92-2.86.74-5.56,1.91-7.86,3.52,0,0,0,0-.01,0Z"/>
  </g>
  <g id="Verbindungen">
    <path id="articulatiocostotransversariahighlight10" class="Rippen_Verbindungen1536Fill" style= {{
            fill: props.colors.Rippen_Verbindungen1536Fill,
            stroke: props.colors.Rippen_Verbindungen1536Fill,
            opacity:props.colors.Rippen_Verbindungen1536Fill,
          }} d="M171.4,548.6c-.27.42-1.94,1.14-5.26,2.56-2.03.87-2.25.91-2.57.87-2.81-.38-4.58-6.27-4-10.08.09-.58.28-1.78,1.11-3.06.32-.49,1.16-1.79,2.09-2.09,3.19-1.02,10.68,8.58,8.63,11.8h0Z"/>
    <path id="articulatiocostotransversariahighlight9" class="Rippen_Verbindungen1536Fill" style= {{
            fill: props.colors.Rippen_Verbindungen1536Fill,
            stroke: props.colors.Rippen_Verbindungen1536Fill,
            opacity:props.colors.Rippen_Verbindungen1536Fill,
          }} d="M144.92,501.09c3.43-.11,11.48,15.07,8.85,19.04-.39.59-.47.1-4.35,1.96-1.06.51-2.13,1.06-3.57.59,0,0-.64-.2-1.28-.65-2.66-1.84-4.5-7.68-4.94-12.46-.22-2.36-.43-5.06.86-6.45.65-.7,1.84-1.23,2.12-1.36.92-.42,1.9-.67,2.31-.68h0Z"/>
    <path id="articulatiocostotransversariahighlight8" class="Rippen_Verbindungen1536Fill" style= {{
            fill: props.colors.Rippen_Verbindungen1536Fill,
            stroke: props.colors.Rippen_Verbindungen1536Fill,
            opacity:props.colors.Rippen_Verbindungen1536Fill,
          }} d="M126.29,450.93c4.42.07,15.02,19.98,10.58,25.3-1.34,1.6-3.95,1.42-5.02,1.4-5.35-.11-8.46-4.95-8.62-5.2-.52-.84-.42-.95-1.72-7.36-1.2-5.94-1.83-8.52-.53-10.79.69-1.2,1.73-2,1.73-2,0,0,1.56-1.39,3.57-1.36h.01Z"/>
    <path id="articulatiocostotransversariahighlight7" class="Rippen_Verbindungen1536Fill" style= {{
            fill: props.colors.Rippen_Verbindungen1536Fill,
            stroke: props.colors.Rippen_Verbindungen1536Fill,
            opacity:props.colors.Rippen_Verbindungen1536Fill,
          }} d="M124.43,375.98c6.29,1.09,13.82,29.27,8.33,33.53-.72.56-2.1,1.05-3.12,1.3-1.71.42-4.36,1.07-6.83-.48-2.86-1.8-4.01-5.65-4.73-8.03-.72-2.39-.31-2.44-1.07-9.42-.65-5.95-1.07-7.09-.5-9.27.69-2.63,2.15-4.17,2.82-4.86.71-.73,3.05-3.13,5.08-2.78h.02Z"/>
    <path id="articulatiocostotransversariahighlight6" class="Rippen_Verbindungen1536Fill" style= {{
            fill: props.colors.Rippen_Verbindungen1536Fill,
            stroke: props.colors.Rippen_Verbindungen1536Fill,
            opacity:props.colors.Rippen_Verbindungen1536Fill,
          }} d="M108.42,304.59c-.14-1.12-.53-4.42,1.02-7.88.6-1.35,2.18-4.88,4.52-5.21,6.82-.98,18.85,25.4,15.38,28.51-.47.42-1.57.69-3.01,1.12-1.52.46-3.24.73-4.2.71-4.42-.05-8.36-6.08-10.03-8.64-1.53-2.34-3.19-4.87-3.67-8.62h-.01Z"/>
    <path id="articulatiocostotransversariahighlight5" class="Rippen_Verbindungen1536Fill" style= {{
            fill: props.colors.Rippen_Verbindungen1536Fill,
            stroke: props.colors.Rippen_Verbindungen1536Fill,
            opacity:props.colors.Rippen_Verbindungen1536Fill,
          }} d="M86.22,236.68c-1.12-3.94,1.37-7.76,1.93-8.62,1.24-1.9,2.04-3.12,3.27-3.39,5.83-1.25,17.38,19.84,13.44,23.86-.86.87-2.53,1.02-3.91,1.15-.8.07-3.68.29-6.4-1.42-1.05-.66-2.03-1.9-3.98-4.38-1.72-2.19-2.6-3.7-2.94-4.33-.79-1.42-1.19-2.12-1.4-2.88h0Z"/>
    <path id="articulatiocostotransversariahighlight4" class="Rippen_Verbindungen1536Fill" style= {{
            fill: props.colors.Rippen_Verbindungen1536Fill,
            stroke: props.colors.Rippen_Verbindungen1536Fill,
            opacity:props.colors.Rippen_Verbindungen1536Fill,
          }} d="M84,171.32c-.24-2.6.45-4.44.66-4.97.27-.68.78-1.9,1.96-2.86.13-.1,1.13-.9,1.82-.97,3.55-.32,11.7,17.84,8.51,20.88-.79.75-2.93,1.18-4.02.91-.68-.17-1.3-.53-1.3-.53,0,0-.43-.25-.86-.6-2.52-2.11-4.28-5.21-4.28-5.21-1.15-2.03-2.24-3.95-2.49-6.64h0Z"/>
    <path id="articulatiocostotransversariahighlight3" class="Rippen_Verbindungen1536Fill" style= {{
            fill: props.colors.Rippen_Verbindungen1536Fill,
            stroke: props.colors.Rippen_Verbindungen1536Fill,
            opacity:props.colors.Rippen_Verbindungen1536Fill,
          }} d="M69.19,139.33c-.39-1.27-1.81-5.93.8-9.24.65-.83,1.49-1.89,2.63-1.89,4.22.02,8.36,14.57,5.8,16.34-.36.25-1.49.68-2.67.9-.12.02-1.12.21-1.7.18-2.19-.13-4.09-3.78-4.86-6.3h0Z"/>
    <path id="articulatiocostotransversariahighlight2" class="Rippen_Verbindungen1536Fill" style= {{
            fill: props.colors.Rippen_Verbindungen1536Fill,
            stroke: props.colors.Rippen_Verbindungen1536Fill,
            opacity:props.colors.Rippen_Verbindungen1536Fill,
          }} d="M78.18,102.55c-.53-2.4.59-4.39.73-4.62.75-1.3,1.8-1.99,2.01-2.12.2-.13,1.1-.7,1.7-.75,2.94-.21,7.73,11.49,5.16,13.49-.87.68-2.38.08-4.02-.58-1.16-.46-4.81-1.91-5.58-5.42Z"/>
    <path id="articulatiocostotransversariahighlight1" class="Rippen_Verbindungen1536Fill" style= {{
            fill: props.colors.Rippen_Verbindungen1536Fill,
            stroke: props.colors.Rippen_Verbindungen1536Fill,
            opacity:props.colors.Rippen_Verbindungen1536Fill,
          }} d="M70.07,40.11c-.67-1.62-1.9-4.57-.81-7.77.76-2.25,2.84-5.16,4.85-4.96,2.9.3,4.19,6.88,4.65,9.2,1.18,5.97.58,12.85-1.07,13.12-.38.06-.68-.25-2.43-2.47-3.17-4.04-4.76-6.08-5.2-7.12h0Z"/>
  </g>
</g>
</svg>
  );
}

export default Brustwirbelsaule45VdOblLiegend1536;
